import { defineStore } from 'pinia';
import { STORES } from '@/modules/core/stores/store-constants';
import { onMounted, ref, watch } from 'vue';
import { state } from '@swimm/shared';

export const DEFAULT_SIDEBAR_SIZES = {
  MIN: 240,
  DEFAULT: 320,
  MAX: 480,
} as const;

export const useWorkspaceSidebarStore = defineStore(STORES.WORKSPACE_SIDEBAR, () => {
  const showAddRepoModal = ref(false);
  const addFolderInFolderId = ref<string>(null);
  const sidebarExpandedWidthPx = ref<number>(DEFAULT_SIDEBAR_SIZES.DEFAULT);
  // Currently the sidebar is not reactive for adding repos, we increase this counter to let it know.
  const workspaceReposUpdatedCounterTrigger = ref(0);

  watch(sidebarExpandedWidthPx, (sidebarExpandedWidthPx) => {
    state.set({ key: 'doc_sidebar_width_px', value: sidebarExpandedWidthPx }).then();
  });

  onMounted(() => {
    state.get({ key: 'doc_sidebar_width_px' }).then((sidebarExpandedWidthPxFromState) => {
      sidebarExpandedWidthPx.value = sidebarExpandedWidthPxFromState ?? DEFAULT_SIDEBAR_SIZES.DEFAULT;
    });
  });

  function workspaceReposUpdated() {
    workspaceReposUpdatedCounterTrigger.value += 1;
  }

  return {
    showAddRepoModal,
    addFolderInFolderId,
    sidebarExpandedWidthPx,
    DEFAULT_SIDEBAR_SIZES,
    workspaceReposUpdated,
    workspaceReposUpdatedCounterTrigger,
  };
});
