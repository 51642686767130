import { DraftInvalidType } from '@/modules/batch-commit/draftTypes';
import { addDraftsInfoToAutosync, convertSwimmDocumentToAutosyncInput, isDocEmpty } from '@swimm/swmd';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useDrafts3Store } from './drafts3';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { ApplicabilityStatus, gitwrapper } from '@swimm/shared';
import { type Draft, DraftType } from '../db';
import type { VerifyDocResult } from '@/verify-types';
import { verifyDraftWorker } from '@/workers';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';

export const useDrafts3ValidationStore = defineStore('drafts3Validation', () => {
  const store = useStore();
  const drafts3Store = useDrafts3Store();
  const { repos } = storeToRefs(useReposStore());
  const verifying = ref(false);
  const commit = ref<string>();
  const draftsVerification = ref(new Map<string, VerifyDocResult>());
  const { id: workspaceId } = storeToRefs(useWorkspaceStore());

  const db_getWorkspaceRepos = computed(() => store.getters['database/db_getWorkspaceRepoIds'](workspaceId.value));
  const reposInWorkspace = computed(() =>
    repos.value.filter((repo) => db_getWorkspaceRepos.value.some((id) => id === repo.id))
  );

  async function verifyDrafts() {
    verifying.value = true;
    try {
      const newDraftsVerification = new Map<string, VerifyDocResult>();
      const branchInfo = await gitwrapper.getBranch({ repoId: drafts3Store.repoId, branchName: drafts3Store.branch });
      commit.value = branchInfo.sha;

      for (const [id, draft] of (drafts3Store.drafts ?? new Map<string, Draft>()).entries()) {
        if (draft.type === DraftType.DOC) {
          const autosyncInput = convertSwimmDocumentToAutosyncInput(
            draft.content,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            drafts3Store.repoId!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            drafts3Store.branch!,
            reposInWorkspace.value
          );

          addDraftsInfoToAutosync(autosyncInput, (unitId) => drafts3Store.drafts.get(unitId) != null);

          const verifyResult = await verifyDraftWorker.verifyDoc({
            id,
            repoId: draft.repoId,
            workspaceId: draft.workspaceId,
            branch: draft.branch,
            autosyncInput,
            isDraft: true,
          });
          newDraftsVerification.set(id, verifyResult);
        }
      }

      draftsVerification.value = newDraftsVerification;
    } finally {
      verifying.value = false;
    }
  }

  async function isVerifyUpToDate() {
    const branchInfo = await gitwrapper.getBranch({ repoId: drafts3Store.repoId, branchName: drafts3Store.branch });
    return commit.value === branchInfo.sha;
  }

  // TODO: implement
  function hasInvalidSteps(draft): boolean {
    draft;
    return false;
    // return draft.sequence.some(
    //   (step) =>
    //     step.type !== PlaylistSequenceStepTypes.EXTERNAL_LINK &&
    //     [ApplicabilityStatus.Unavailable, ApplicabilityStatus.Invalid].includes(getStepStatus(step))
    // );
  }

  function isVerified(draft: Draft): boolean {
    return draftsVerification.value.get(draft.id)?.applicabilityStatus === ApplicabilityStatus.Verified;
  }

  const invalidDrafts = computed(() => {
    if (drafts3Store.drafts == null) {
      return new Map<string, DraftInvalidType>();
    }

    return [...drafts3Store.drafts.values()].reduce((accumulator, draft) => {
      let reason: DraftInvalidType;
      if (draft.type === 'playlist') {
        // draft.content.name can be undefined
        if (!draft.content.name?.trim()) {
          reason = DraftInvalidType.EMPTY_TITLE;
        } else if (hasInvalidSteps(draft)) {
          reason = DraftInvalidType.INVALID_STEPS;
        }
      } else if (draft.type === 'doc') {
        if (!isVerified(draft)) {
          reason = DraftInvalidType.NEEDS_REVIEW;
        } else if ((draft.content.title?.trim() ?? '') === '') {
          reason = DraftInvalidType.EMPTY_TITLE;
        } else if (isDocEmpty(draft.content.content)) {
          reason = DraftInvalidType.EMPTY_CONTENT;
        }
      }

      if (reason) {
        accumulator.set(draft.id, reason);
      }

      return accumulator;
    }, new Map<string, DraftInvalidType>());
  });

  return { verifying, commit, draftsVerification, verifyDrafts, isVerifyUpToDate, invalidDrafts, isVerified };
});
