<script setup lang="ts">
import { useBatchImportStore } from '@/modules/batch-import/stores/batch-import';
import { storeToRefs } from 'pinia';
import { BATCH_IMPORT_SCREENS, type BatchImportFile } from '@/modules/batch-import/constants';
import { computed } from 'vue';
import { useNavigate } from '@/common/composables/navigate';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { BATCH_IMPORT_EVENTS } from '../constants/analytics-events';
import { Divider } from '@swimm/ui';
import { type Draft } from '@/modules/drafts3/db';

const analytics = useAnalytics();
const batchImportStore = useBatchImportStore();
const { setCurrentModalScreen } = batchImportStore;
const { filesConverted, conversionType, importRepoId } = storeToRefs(batchImportStore);
const { getRepoPath } = useNavigate();

const docBaseUrl = computed(() => `${getRepoPath(importRepoId.value)}/docs/`);

function allowFixDraft(convertedFile: BatchImportFile) {
  return !!(convertedFile.swm as Draft).id;
}

function composeDocUrl(convertedFile: BatchImportFile) {
  return `${docBaseUrl.value}${(convertedFile.swm as Draft).id}/edit`;
}

const errorFilesConverted = computed(() => {
  return filesConverted.value.map((fileConverted) => ({
    ...fileConverted,
    issues: fileConverted.issues.filter((issue) => issue.type === 'ERROR'),
  }));
});
const hasErrorFiles = computed(() => errorFilesConverted.value.some((fileConverted) => fileConverted.issues.length));

const warningFilesConverted = computed(() => {
  return filesConverted.value.map((fileConverted) => ({
    ...fileConverted,
    issues: fileConverted.issues.filter((issue) => issue.type === 'WARNING'),
  }));
});
const hasWarningFiles = computed(() => errorFilesConverted.value.some((fileConverted) => fileConverted.issues.length));

function reportClickedFix(title) {
  analytics.track(BATCH_IMPORT_EVENTS.CLICKED_FIX, {
    docTitle: title,
    errorType: 'WARNING',
    'Import Type': conversionType.value,
  });
}

const handleNextClicked = () => {
  setCurrentModalScreen(BATCH_IMPORT_SCREENS.IMPORT_SUMMARY);
};
</script>

<template>
  <div class="import-report-container" data-testid="import-report-container">
    <SwText class="report-description" variant="body-S" weight="light">
      There are several issues with the following files. You can fix them now or skip this step and do it later.
    </SwText>
    <div class="failures-container">
      <SwText class="files-type-label" data-testid="files-requiring-review-label" variant="subtitle-L" weight="bolder">
        Files requiring review
      </SwText>
      <div class="warnings-container">
        <template v-for="(warningConvertedFile, index) in warningFilesConverted" :key="index">
          <div v-if="warningConvertedFile.issues.length" class="conversion-fail">
            <div class="failure-header">
              <div class="failure-file">
                <Icon class="failure-icon WARNING" name="warning-fill" />
                <SwText variant="subtitle-L" weight="bold" class="file-name" data-testid="failing-file-name">{{
                  warningConvertedFile.name
                }}</SwText>
              </div>
              <SwText v-if="allowFixDraft(warningConvertedFile)" variant="subtitle-S">
                <a
                  class="fix-button"
                  :href="composeDocUrl(warningConvertedFile)"
                  target="_blank"
                  @click="() => reportClickedFix(warningConvertedFile.name)"
                >
                  Fix
                </a>
              </SwText>
            </div>
            <ul
              v-for="(issue, warningIndex) in warningConvertedFile.issues"
              :key="warningIndex"
              class="issue-container"
            >
              <li>
                <SwText variant="body-S" class="failure-reason">
                  {{ issue.reason }}
                </SwText>
              </li>
            </ul>
          </div>
        </template>
      </div>
      <Divider v-if="hasWarningFiles && hasErrorFiles" />
      <SwText v-if="hasErrorFiles" class="files-type-label" variant="subtitle-L" weight="bolder">
        Files failed to import
      </SwText>
      <div class="errors-container">
        <template v-for="(convertedFile, index) in errorFilesConverted" :key="index">
          <div v-if="convertedFile.issues.length" class="conversion-fail">
            <div class="failure-header">
              <div class="failure-file">
                <Icon class="failure-icon ERROR" name="warning-fill" />
                <SwText variant="subtitle-L" weight="bold" class="file-name">{{ convertedFile.name }}</SwText>
              </div>
            </div>
            <ul v-for="(issue, fileIndex) in convertedFile.issues" :key="fileIndex" class="issue-container">
              <li>
                <SwText variant="body-S" class="failure-reason">
                  {{ issue.reason }}
                </SwText>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
    <div class="actions">
      <Action :disabled="false" @click="handleNextClicked" data-testid="report-next-button">Next</Action>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.import-report-container {
  .report-description {
    line-height: 1.5rem;
  }

  .failures-container {
    .files-type-label {
      margin: var(--space-sm) 0;
    }

    .warnings-container,
    .errors-container {
      overflow-y: scroll;
      max-height: 250px;

      .conversion-fail {
        margin: var(--space-base) 0;

        .failure-header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .failure-file {
            display: flex;
            align-items: center;

            .failure-icon {
              font-size: var(--headline2);

              &.WARNING {
                color: var(--text-color-warning);
              }

              &.ERROR {
                color: var(--text-color-error);
              }
            }
          }

          .fix-button {
            margin-right: var(--space-sm);
            color: var(--text-color-link);
          }
        }

        .issue-container {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: var(--space-sm);

    & .button:first-of-type {
      margin-right: var(--space-base);
    }
  }
}
</style>
