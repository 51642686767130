import Dexie, { type Table } from 'dexie';

export interface DismissedSuggestion {
  repoId: string;
  docId: string;
  // TODO: Remove `isTempDocId` after finishing transition to SWMD v3.
  isTempDocId: number; // Boolean can't be indexed in dexie
  text: string;
}

class SwimmportLocalDB extends Dexie {
  dismissedSuggestions: Table<DismissedSuggestion>;

  constructor() {
    super('swimmport');
    this.version(5)
      .stores({
        dismissedSuggestions: '[repoId+docId+text], [repoId+docId+isTempDocId], [repoId+docId+isTempDocId+text]',
      })
      .upgrade((transaction) => {
        transaction
          .table('dismissedSuggestions')
          .toCollection()
          .modify((dismissedSuggestion) => {
            dismissedSuggestion.isTempDocId = 1;
          });
      });
    this.version(4).stores({
      dismissedSuggestions: '[repoId+docId+text], [repoId+docId]',
    });
  }
}

const swimmportLocalDB = new SwimmportLocalDB();

export async function saveDismissedSuggestion(dismissedSuggestion: DismissedSuggestion) {
  await swimmportLocalDB.dismissedSuggestions.put(dismissedSuggestion);
}

export async function getDismissedSuggestionsForDocument(
  repoId: string,
  docId: string,
  isTempDocId: number
): Promise<Set<string>> {
  const dismissedSuggestions = new Set<string>();
  for (const dismissedSuggestion of await swimmportLocalDB.dismissedSuggestions
    .where({ repoId, docId, isTempDocId })
    .toArray()) {
    dismissedSuggestions.add(dismissedSuggestion.text);
  }
  return dismissedSuggestions;
}

export async function clearDismissedSuggestionsForDocument(repoId: string, docId: string, isTempDocId: number) {
  await swimmportLocalDB.dismissedSuggestions.where({ repoId, docId, isTempDocId }).delete();
}

export async function clearDismissedSuggestionsForTempDocument(repoId: string, docId: string) {
  clearDismissedSuggestionsForDocument(repoId, docId, 0);
}
