import TurndownService from 'turndown';
import { stringUtils, symbolsUtils } from '@swimm/shared';

export interface IMarkdownSupport {
  smartPath: boolean;
  swimmLink: boolean;
  mention: boolean;
}

const EmptyCodeBlock = '\n\n```\n```\n\n';
const EmptyListItem = '*    \n';

function nodeHasAttribute(node: HTMLElement, attr: string) {
  const nodeToFilter: HTMLParagraphElement = node as HTMLParagraphElement;
  return !!(nodeToFilter.nodeName === 'SPAN' && nodeToFilter.getAttribute(attr));
}

function blankReplacement(content: string, node: Node): string {
  if (node.nodeName === 'P') {
    // We encode empty paragraphs like so:
    // Initial HTML: ----
    // <p>aaaa</p>
    // <p></p>
    // <p>bbbb</p>
    // ------------------
    // Markdown: --------
    // aaaa
    //
    //
    // <br/>
    //
    //
    // aaaa
    // ------------------
    return '<br/>\n\n';
  } else if (node.nodeName === 'LI') {
    return EmptyListItem;
  } else if (node.nodeName === 'PRE' && node.firstChild?.nodeName === 'CODE') {
    return EmptyCodeBlock;
  }
  return '';
}

export function useHTMLToMarkdown(support: IMarkdownSupport) {
  const turndown = new TurndownService({
    codeBlockStyle: 'fenced',
    headingStyle: 'atx',
    blankReplacement,
    br: '<br/>',
  });
  turndown.addRule('normalATag', {
    filter: function (content) {
      const el = content as HTMLElement;
      return el.classList?.contains('tiptap-link');
    },
    replacement: function (content, node): string {
      const el = node as HTMLElement;
      // Triggers unexpected escaping that grows with every autoSave
      let escapedContent = content.replace(/\\`/g, '');
      // Triggers 'Mention extension' unexpected behaviour
      escapedContent = escapedContent.replace(/@/g, '');
      const src = el.getAttribute('href');
      return `[${escapedContent}](${src})`;
    },
  });

  turndown.addRule('genericText', {
    filter: function (node) {
      return nodeHasAttribute(node, 'sym-text-id');
    },
    replacement: function (content, node) {
      const el = node as HTMLElement;
      const text = content.split('\\').join('');
      const symbolId = el.getAttribute('sym-text-id');
      return symbolsUtils.composeSymbolText(symbolId || '', text);
    },
  });

  turndown.addRule('text-placeholder', {
    filter: function (node) {
      return !!(node.nodeName === 'INPUT' && (node as HTMLElement).getAttribute('text-placeholder-id'));
    },
    replacement: function (content, node) {
      const el = node as HTMLElement;
      const value = el.getAttribute('value');
      return value
        ? value
        : `[${el.getAttribute('text')}](#text-placeholder-id-${el.getAttribute('text-placeholder-id')})`;
    },
  });

  if (support.swimmLink) {
    turndown.addRule('swm-link', {
      filter: function (node) {
        return nodeHasAttribute(node, 'sym-link-id');
      },
      replacement: function (content: string, node: Node): string {
        const el = node as HTMLElement;
        const name = content.split('\\').join('');
        return `[[sym-link:(${el.getAttribute('sym-link-id')})${name}]]`;
      },
    });
  }

  if (support.mention) {
    turndown.addRule('mention', {
      filter: function (node: HTMLElement): boolean {
        return nodeHasAttribute(node, 'sym-mention-id');
      },
      replacement: function (content: string, node: Node): string {
        const el = node as HTMLElement;
        const name = content.split('\\').join('');
        return `[[sym-mention:(${el.getAttribute('sym-mention-id')}|${el.getAttribute('external-uid')})${name}]]`;
      },
    });
  }

  if (support.smartPath) {
    turndown.addRule('path', {
      filter: function (node: HTMLElement): boolean {
        return nodeHasAttribute(node, 'sym-path-id');
      },
      replacement: function (content: string, node: Node): string {
        const el = node as HTMLElement;
        const path = content.split('\\').join('');
        return `[[sym:./${path}(${el.getAttribute('sym-path-id')})]]`;
      },
    });

    turndown.addRule('text-placeholder', {
      filter: function (node: HTMLElement): boolean {
        const nodeToFilter: HTMLParagraphElement = node as HTMLParagraphElement;
        return !!(nodeToFilter.nodeName === 'INPUT' && nodeToFilter.getAttribute('text-placeholder-id'));
      },
      replacement: function (content: string, node: Node): string {
        const el = node as HTMLElement;
        const value = el.getAttribute('value');
        return value
          ? value
          : `[${el.getAttribute('text')}](#text-placeholder-id-${el.getAttribute('text-placeholder-id')})`;
      },
    });

    turndown.addRule('paragraphed-list-item', {
      filter: function (node: HTMLElement): boolean {
        return node.nodeName === 'OL' || node.nodeName === 'UL';
      },
      replacement: function (content: string): string {
        return content.replaceAll(/^\s+$/gm, ''); // Empty lines that only have spaces in them
      },
    });
  }

  return {
    convertToMarkdown: (html: string) => {
      const md = turndown.turndown(html);
      // Turndown breaks markdown by adding a new line after a line of only `<br/>`, so we forcibly fix it here.
      return stringUtils.removeNewLineAfterHtmlLineBreakTag(md);
    },
  };
}
