<template>
  <div class="api-key-section">
    <div class="headline3 preference-header"><b>API Keys</b></div>

    <SwText variant="body-S">
      Use API keys to integrate Swimm into your CI pipeline. <br />
      <a class="link" href="mailto:info@swimm.io">Contact us</a> for help setting this up.
    </SwText>
    <div v-if="apiKeyDetails" class="gapped-base">
      <div class="active-keys-title">Active API keys</div>
      <div class="centered">
        <TextField class="api-key-textfield" placeholder="••••••••••••••••••••••••••••••••••" disabled />
        <Icon name="security-shield" class="api-key-icon body-L" />
      </div>
      <div class="generated-on">Generated on {{ formattedGeneratedOn }}</div>
      <Action variant="danger" @click="revoke" class="revoke-button">Revoke API Key</Action>
    </div>
    <div v-else class="gapped-sm">
      <div class="active-keys-title">Active keys: 0</div>
      <SwText variant="body-S">No keys have been generated yet.</SwText>
      <Action @click="generate">Generate API Key</Action>
    </div>
    <SwModal heading="Copy your API key" :show-modal="generatedKey" @close="generatedKey = undefined">
      <div class="copy-modal">
        <SwText>Your API key has been generated - copy it now, <b>you will not be able to see it again.</b></SwText>
        <div class="modal-textbox-container">
          <TextField select-on-focus readonly :model-value="generatedKey" class="api-key-text" />
          <CopyButton no-padding :content="generatedKey" variant="primary" class="copy-button" />
        </div>
      </div>
    </SwModal>
  </div>
</template>

<script>
import { useAnalytics } from '@/common/composables/useAnalytics';
import CopyButton from '@/common/components/atoms/CopyButton.vue';
import { mapGetters, mapState } from 'vuex';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';
import { generateNewApiKey, getApiKeyDetails, revokeCurrentApiKey } from '@/modules/api-keys/services/settings';
import { SwModal, SwText, TextField } from '@swimm/ui';
import { datetimeUtils } from '@swimm/shared';
import swal from 'sweetalert';

export default {
  components: { TextField, SwModal, CopyButton, SwText },
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const { setTheme } = useUserConfigStore();
    const analytics = useAnalytics();

    return { user, analytics, setTheme };
  },
  data() {
    return {
      apiKeyDetails: undefined,
      loading: true,
      generatedKey: undefined,
    };
  },
  computed: {
    ...mapState('swimmport', ['enabled']),
    ...mapGetters('database', ['db_isWorkspaceAdmin']),
    workspaceId() {
      return this.$route.params.workspaceId;
    },
    repoId() {
      return this.$route.params.repoId;
    },
    isAdmin() {
      return this.db_isWorkspaceAdmin(this.workspaceId, this.user.uid);
    },
    formattedGeneratedOn() {
      if (!this.apiKeyDetails) {
        return;
      }

      return datetimeUtils.extendDateTime(this.apiKeyDetails.generated_on);
    },
  },
  async created() {
    await this.loadData();
  },
  methods: {
    async revoke() {
      const shouldRevoke = await swal({
        title: 'Revoke API key?',
        text: 'Any integration using this key will stop working.\nThis cannot be undone.',
        dangerMode: true,
        buttons: {
          cancel: true,
          confirm: { text: 'Revoke', visible: true },
        },
      });

      if (!shouldRevoke) {
        return false;
      }

      await revokeCurrentApiKey(this.workspaceId);
      await this.loadData();
    },
    async generate() {
      const generatedKey = await generateNewApiKey(this.workspaceId);
      this.generatedKey = generatedKey;
      await this.loadData();
    },
    async loadData() {
      try {
        const apiKeyDetails = await getApiKeyDetails(this.workspaceId);
        this.apiKeyDetails = apiKeyDetails;
      } catch (err) {
        this.$logger.error(`Failed to read getApiKeyDetails for user ${this.user.uid} ${err}`);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.active-keys-title {
  font-size: var(--subtitle-L);
  font-weight: 700;
}

.api-key-text:deep(input) {
  color: var(--text-color-primary) !important;
}

.api-key-textfield {
  width: 320px;
}

.explanation {
  line-height: var(--space-md);
}

.generated-on {
  color: var(--cool-slate);
  font-size: var(--body-XS);
}

.api-key-icon {
  color: var(--success-green-600);
}

.api-key-section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--space-sm);
}

.link {
  cursor: pointer;
  color: var(--text-color-link);
}

.gapped-base {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: var(--space-base);
}

.gapped-sm {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: var(--space-sm);
}

.centered {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.copy-modal {
  display: flex;
  flex-direction: column;
  gap: var(--space-base);
  width: 500px;
}

.copy-button {
  width: 100px;
}

.modal-textbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-base);
}

.revoke-button {
  margin-top: var(--space-base);
}
</style>
