import type { Timestamp } from 'firebase/firestore';
import type { ContributionDBItemInterface, ContributorDbItemInterface } from './contributors/types';
import type { WatchersDbItemInterface } from './watchers/types';
import type { AssignmentDbItemInterface } from './assignments/types';
import type { ThanksDbItemInterface } from './thanks/types';

export interface DocDbItemInterface {
  app_version: string;
  name: string;
  created: Timestamp;
  creator: string;
  creator_name: string;
  file_version: string;
  hunks_count?: number;
  tokens_count?: number;
  paths_count?: number;
  files_referenced?: number;
  id: string;
  modified: Timestamp;
  modifier: string;
  modifier_name: string;
  play_mode: 'walkthrough' | 'handson';
  type: 'unit' | 'external_link';
  views?: number;
  exported_cloud_doc_id?: string;
}

export interface DocSubCollectionInterface {
  contributors: ContributorDbItemInterface[];
  contributions: ContributionDBItemInterface[];
  assignments: AssignmentDbItemInterface[];
  watchers: WatchersDbItemInterface[];
  thanks: ThanksDbItemInterface[];
}

// TODO: when allowing TS usage inside mixins, replace this with the interfaces above
export const DocSubCollectionsArray = ['contributors', 'contributions', 'assignments', 'watchers', 'thanks'];
