<template>
  <div class="box-wrapper">
    <div class="container">
      <div class="form-sticky-header">
        <slot />
        <div class="actions">
          <Action v-if="showCancel" class="small" secondary @click="routeBack">Cancel</Action>
          <Action v-if="showDiscard" class="small" secondary @click="discard">Discard</Action>
          <slot name="actions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useRouting } from '@/common/composables/routing';

export default {
  props: {
    sticky: { type: Boolean, default: false },
    showCancel: { type: Boolean, default: true },
    showDiscard: { type: Boolean, default: false },
  },
  emits: ['discard'],
  setup() {
    const { routeBackFromDoc } = useRouting();
    return { routeBackFromDoc };
  },
  methods: {
    async discard() {
      this.$emit('discard', (discarded: boolean) => {
        if (discarded) {
          this.routeBackFromDoc();
        }
      });
    },
    routeBack() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.box-wrapper {
  position: fixed;
  top: var(--top-header-height);
  right: 0;
  z-index: 201;
  margin: 0;
  padding: 15px;
  background: var(--color-status-info);
  border-bottom-left-radius: 10px;
  box-sizing: border-box;
}

.small {
  max-height: 36px;
}

.container {
  margin: auto;
  max-width: var(--narrow-content-width);
}

.form-sticky-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: var(--narrow-content-width);
}

.form-sticky-header.shadow {
  position: initial;
  visibility: hidden;
}

.actions > * {
  margin-left: 17px;
}

.actions {
  display: flex;
}
</style>
