<script setup lang="ts">
import { StiggFeatures, config, encodeString, encryptMessage, productEvents } from '@swimm/shared';
import { Action } from '@swimm/ui';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { computed, onMounted, ref } from 'vue';
import { SLACK_APP_INSTALLATION_LINK } from '@/config';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';
import PaywallModal, { PaywallEntity } from '@/common/components/modals/PaywallModal.vue';
import { useStigg } from '@/common/composables/useStigg';

const props = defineProps({
  repoId: { type: String, required: true },
  workspaceId: { type: String, required: true },
  description: { type: String, required: true },
  origin: { type: String, required: true },
});

const emit = defineEmits(['close']);

const analytics = useAnalytics();
const { user } = storeToRefs(useAuthStore());
const { theme } = storeToRefs(useUserConfigStore());
const { stiggClient } = useStigg();

let canInstall = null;

const showPaywall = ref(false);

const slackAppInstallationUrl = computed(
  () =>
    `${SLACK_APP_INSTALLATION_LINK}_${encodeString(
      encryptMessage(user.value.uid, config.SLACK_APP_INSTALLATION_STATE)
    )}_${encodeString(encryptMessage(props.repoId, config.SLACK_APP_INSTALLATION_STATE))}`
);

async function canInstallSlackApp() {
  const slackEntitlement = await stiggClient.getBooleanEntitlement({
    featureId: StiggFeatures.SLACK_INTEGRATION,
  });

  return slackEntitlement.hasAccess;
}

async function install() {
  if (await canInstall) {
    reportInstallationClicked();
    window.open(slackAppInstallationUrl.value, '_blank');
  } else {
    showPaywall.value = true;
  }
}

function reportInstallationClicked() {
  analytics.track(productEvents.SLACK_INSTALL_EVENT, {
    'Workspace ID': props.workspaceId,
    'Repo ID': props.repoId,
    Origin: props.origin,
    Context: 'Slack',
  });
}

const imageUrl = computed(() => {
  return new URL(`../assets/install-slack-${theme.value}.png`, import.meta.url).href;
});

function closePaywallModal({ isRedirecting }) {
  showPaywall.value = false;
  if (isRedirecting) {
    emit('close');
  }
}

onMounted(async () => {
  canInstall = canInstallSlackApp();
});
</script>

<template>
  <div class="content-wrapper">
    <div class="img-wrapper">
      <img class="slack-img" :src="imageUrl" alt="Install Slack App" />
    </div>
    <div class="wrap-text">{{ description }}</div>
    <div class="btn-wrapper">
      <Action @click="install" class="btn-wrapper">Install Slack App</Action>
    </div>
    <PaywallModal :show="showPaywall" :entity="PaywallEntity.SLACK" @close="closePaywallModal" />
  </div>
</template>

<style scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-wrapper {
  padding-bottom: 24px;
}

.slack-img {
  height: 90px;
}

.wrap-text {
  max-width: 320px;
  font-size: var(--fontsize-xs);
  text-align: center;
  line-height: 16px;
}

.btn-wrapper {
  padding-top: 24px;
  flex-shrink: 0;
}
</style>
