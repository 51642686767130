<script setup lang="ts">
import type { Editor, Range } from '@tiptap/core';
import { computed, ref, toRef, watch } from 'vue';

import { SwmSelectionContentTokenPreview, SwmSelectionContentTokenSimple } from '@swimm/reefui';
import { type TokenSuggestion, productEvents } from '@swimm/shared';

import { getFocsuedSnippetInfo } from '@/composables/focusedSnippet';
import { useTokenSelectionPreview } from '@/composables/tokenSelection/preview';
import { useTokenSuggestionsState } from '@/composables/tokenSelection/suggestionsState';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';
import type { ComponentExposed } from 'vue-component-type-helpers';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  editor: Editor;
  range: Range;
  query: string;
  text: string;
  command: (props: TokenSuggestion) => boolean;
  decorationNode: Element | null;
  clientRect?: (() => DOMRect | null) | null;
}>();

const tokens = ref<ComponentExposed<typeof SwmSelectionContentTokenSimple>>();
const onKeyDown = computed(() => tokens.value?.onKeyDown);

const swimmEditorServices = getSwimmEditorServices(props.editor);

const state = useTokenSuggestionsState(
  toRef(() => props.query),
  computed(() => !swimmEditorServices.showSwmTokenSelectionAdvancedModal.value),
  swimmEditorServices.external.tokenSuggestionsService,
  swimmEditorServices.sourceFiles.sourceFiles,
  swimmEditorServices.repoId,
  swimmEditorServices.repoId,
  swimmEditorServices.swimmSmartElements,
  getFocsuedSnippetInfo(props.editor)
);

const tokenSelectionPreview = useTokenSelectionPreview(props.editor);

watch(() => props.query, tokenSelectionPreview.reset);

function setPreview(token: TokenSuggestion) {
  tokenSelectionPreview.set(token);
}

async function onShowAdvanced() {
  props.editor.chain().deleteRange(props.range).selectAndInsertSwmTokenWithAdvancedMode(props.query).run();
  tokenSelectionPreview.reset();
}

function onSelectToken(token: TokenSuggestion): boolean {
  const result = props.command(token);
  swimmEditorServices.external.trackEvent(productEvents.SMART_TEXT_TOKEN_ADDED, {
    Context: 'Inline View',
    Global: token.static,
  });
  return result;
}

defineExpose({ onKeyDown });
</script>

<template>
  <SwmSelectionContentTokenSimple
    ref="tokens"
    :query="query"
    :loading="state.loading"
    :no-results="state.noResults"
    :token-suggestions="state.tokenSuggestions"
    :command="onSelectToken"
    @select-suggestion="onSelectToken"
    @focused-suggestion="setPreview"
    @hovered-suggestion="setPreview"
    @show-advanced="onShowAdvanced"
  >
    <template #preview="{ cachedHighlighters }">
      <SwmSelectionContentTokenPreview
        v-if="tokenSelectionPreview.state.value.show"
        :code="tokenSelectionPreview.state.value.code"
        :token-suggestion="tokenSelectionPreview.state.value.token"
        :max-rows="5"
        :cached-highlighters="cachedHighlighters"
      />
    </template>
  </SwmSelectionContentTokenSimple>
</template>
