<script setup>
import { PageRoutesNames } from '@/common/consts';
import YourWorkspaces from '@/common/pages/JoinWorkspace/YourWorkspaces.vue';
import BaseBackgroundLayout from '@/modules/onboarding/layouts/BaseBackgroundLayout.vue';
import { BackgroundTheme } from '@/modules/onboarding/consts';
import { useJoinWorkspace } from '@/modules/workspace/composables/joinWorkspace';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getLoggerNew } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

const props = defineProps({
  workspaceId: { type: String, default: '' },
  repoWorkspacesToShow: { type: String, default: '' },
});

const router = useRouter();
const route = useRoute();
const joinedWorkspaces = ref([]);

const loading = ref(true);

const {
  userWorkspaces,
  initializeJoinWorkspace,
  shouldShowRequestInvitePage,
  isWorkspaceAutoJoinable,
  hasWorkspacesToJoin,
  invites,
  companyWorkspaces,
  hasWorkspaces,
  repoWorkspaces,
  autoJoinableWorkspaces,
} = useJoinWorkspace();

async function redirectIfNeeded() {
  if (shouldShowRequestInvitePage.value && !isWorkspaceAutoJoinable.value) {
    await router.replace({ name: PageRoutesNames.REQUEST_INVITE_PAGE, query: route.query });
    return;
  }
  if (!hasWorkspacesToJoin.value) {
    if (hasWorkspaces.value) {
      await router.replace(`/workspaces`);
    } else {
      await router.replace({ path: `/workspaces/create`, query: route.query });
    }
  }
  if (props.repoWorkspacesToShow) {
    for (const userWorkspace of Object.values(userWorkspaces.value)) {
      if (userWorkspace.repositories.includes(props.repoWorkspacesToShow)) {
        logger.info(
          `User wants to join ${props.repoWorkspacesToShow}, but he is already member of ${userWorkspace.id}`
        );
        await router.push(`/workspaces/${userWorkspace.id}/repos/${props.repoWorkspacesToShow}`);
      }
    }
  }
}

onMounted(async () => {
  await initializeJoinWorkspace(props.workspaceId, props.repoWorkspacesToShow);
  await redirectIfNeeded();
  loading.value = false;
});

function handleJoined(workspaceId) {
  joinedWorkspaces.value.push(workspaceId);
}
</script>

<template>
  <BaseBackgroundLayout :theme="BackgroundTheme.BLURRED_APP">
    <YourWorkspaces
      :loading-workspaces="loading"
      :workspace-id="workspaceId"
      :invites="invites"
      :company-workspaces="companyWorkspaces"
      :auto-joinable-workspaces="autoJoinableWorkspaces"
      :repo-workspaces="repoWorkspaces"
      @joined="handleJoined"
    />
  </BaseBackgroundLayout>
</template>
