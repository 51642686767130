<script setup>
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useFiltersStore } from '@/modules/core/filters-row/useFiltersStore';
import { useRouteData } from '@/common/composables/useRouteData';
import DropdownFilter from '@/modules/core/filters-row/DropdownFilter.vue';
import { productEvents } from '@swimm/shared';
import { MultiCheckbox, SwText } from '@swimm/ui';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { storeToRefs } from 'pinia';
import { compareTagNames } from '@/modules/doc-sidebar/services/tag-utils';

const props = defineProps({
  repoId: { type: String, required: true },
});

const store = useStore();
const { workspaceId } = useRouteData();
const analytics = useAnalytics();

const workspaceTags = computed(() => {
  return store.getters['database/db_getAllWorkspaceTags'](workspaceId).sort(compareTagNames);
});

const filtersStore = useFiltersStore();
const { tagFilters, isFiltersActive, typeFilter } = storeToRefs(filtersStore);
const typeOptions = Object.values(filtersStore.TypesIncludingFolderOptions);
const selectedTypes = ref([]);

function onTypeChange(types) {
  typeFilter.value = types;
  trackFilterSelection('Type', types);
}

function onTagFilterChange(tags) {
  tagFilters.value = tags;
  trackFilterSelection(
    'Tags',
    tags.map((tag) => tag.name)
  );
}

function reset() {
  filtersStore.resetFilters();
  selectedTypes.value = [];
  const analyticsData = prepareFilterAnalyticsData();
  analytics.track(productEvents.CLICKED_RESET_ALL_FILTERS, analyticsData);
  store.dispatch('filesystem/setSelectedFolderTreePath', { path: '', repoId: props.repoId });
}

function prepareFilterAnalyticsData() {
  const analyticsData = {
    'Workspace ID': workspaceId,
    Origin: props.repoId ? 'View Repo' : 'View Everything',
    Context: 'Documentation Filters',
  };
  if (props.repoId) {
    analyticsData['Repo ID'] = props.repoId;
  }
  return analyticsData;
}

function trackFilterSelection(filterName, filterValue) {
  const analyticsData = { ...prepareFilterAnalyticsData(), 'Filter Name': filterName, 'Filter Value': filterValue };
  analytics.track(productEvents.SELECTED_FILTER, analyticsData);
}
</script>

<template>
  <div class="filters">
    <div></div>
    <SwText class="filter-heading">
      <Icon name="filter" />
      Filters:
    </SwText>
    <DropdownFilter name="Type" :is-selected="Boolean(typeFilter.length)">
      <MultiCheckbox
        :show-search="false"
        :options="typeOptions"
        :model-value="typeFilter"
        :display="(type) => type.label"
        :identity="(type) => type.key"
        @update:model-value="onTypeChange"
      />
    </DropdownFilter>
    <DropdownFilter name="Tag" :is-selected="Boolean(tagFilters.length)">
      <MultiCheckbox
        :show-search="false"
        :options="Object.values(workspaceTags)"
        empty-state-text="No tags available"
        :display="(tag) => tag.name"
        :identity="(tag) => tag.id"
        :model-value="tagFilters"
        @update:model-value="onTagFilterChange"
      />
    </DropdownFilter>
    <span v-if="isFiltersActive" class="clear-button" @click="reset">clear all</span>
  </div>
</template>

<style scoped lang="postcss">
.filters {
  display: flex;
  align-items: center;
  gap: var(--space-xs);

  .filter-heading {
    display: flex;
    align-items: center;
    color: var(--text-color-secondary);
  }

  .clear-button {
    color: var(--text-color-link);
    font-size: var(--fontsize-xs);
    cursor: pointer;
  }
}
</style>
