<script setup>
import { capitalize } from 'lodash-es';

const props = defineProps({
  productSKU: { type: String, required: true },
});

const emit = defineEmits(['back']);

function back() {
  emit('back');
}
</script>

<template>
  <div class="container">
    <Icon name="check" class="circle item" />
    <SwText variant="headline1" class="item">Upgrade successful</SwText>
    <SwText>Your workspace has been upgraded to a {{ capitalize(props.productSKU) }} plan.</SwText>
    <SwText class="item">Enjoy knowledge sharing with your team on unlimited repositories.</SwText>
    <Action @click="back" data-testid="back-to-plan-button">Back to plan details</Action>
  </div>
</template>

<style scoped lang="postcss">
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  text-align: center;
  flex-direction: column;
}

.item {
  margin-bottom: 20px;
  padding: 0;
}

.circle {
  width: 57px;
  height: 57px;
  font-size: var(--fontsize-xl);
  font-weight: 800;
  border-radius: 50%;
  text-align: center;
  border: 4px solid var(--coastal-shore);
  color: var(--coastal-shore);
  line-height: 57px;
}
</style>
