<template>
  <ListViewItem
    v-if="available"
    :title="computeDraftDisplayName(unit.name)"
    :filter-text="filterText"
    :tags="tags"
    :stage-tag="stageTag"
    :applicability-status="applicabilityStatus"
    :creator="unit.creator_name"
    :creator-profile-img="unit.creator_profile_url"
    :branch="showBranchName"
    :is-highlighted="isHighlightedItem"
    :doc-id="unit.id"
    :draft-id="unit.draftId"
    :diff-state="unit.diffState"
    :repo-id="repoId"
    :show-doc-tags="true"
    :has-variations="hasVariations"
    :doc-link="getResourceLink({ resource: unit, repoId, branch, type: DocumentationTypes.DOC, editMode: isDraft })"
    :type="DocumentationTypes.DOC"
    :path="unit.path"
    @variations-click="onVariationsClick"
    @link-click="clearNewBranchData"
    :folder-id="docFolderId"
    :selected="unit.selected"
    @select="(ev) => $emit('select', ev)"
    :show-folder-path="showFolderPath"
    :selectable="selectable"
  >
    <template #options>
      <EllipsisOption
        name="Get shareable link"
        icon="link"
        :close-after-action="true"
        :handler="openShareModal"
        @click="reportClick('Share')"
      />
      <EllipsisOption
        v-if="allowDuplicate"
        name="Duplicate"
        icon="duplicate"
        :handler="() => duplicateResource({ repoId, branch, resource: unit, type: DocumentationTypes.DOC })"
        :close-after-action="false"
        @click="reportClick('Duplicate')"
      />
      <EllipsisOption
        v-if="isDraft"
        name="Discard draft"
        icon="discard"
        :handler="shouldDiscardDraft"
        :close-after-action="false"
        @click="reportClick('Discard')"
      />
      <EllipsisOption
        v-if="canEdit"
        name="Edit"
        icon="edit-outline"
        :handler="navigateToEdit"
        :close-after-action="false"
        @click="reportClick('Edit')"
      />
      <EllipsisOption
        v-if="canDeleteResource"
        name="Delete"
        icon="trash-outline"
        :handler="() => $emit('delete')"
        :close-after-action="false"
        @click="reportClick('Delete')"
      />
      <EllipsisOption
        name="Move to folder..."
        icon="move"
        :handler="() => $emit('move')"
        :close-after-action="false"
        @click="reportClick('Move')"
      />
      <EllipsisOption
        name="Export to PDF"
        icon="upload"
        :handler="onClickExportPDF"
        :close-after-action="true"
        @click="reportClick('Export to PDF')"
      />
    </template>
    <template #activity>
      <SwDate v-if="lastModifiedDate" :date="lastModifiedDate" />
    </template>
  </ListViewItem>
  <div v-else>
    <ListViewItem
      v-tooltip="inactiveUnitTooltip"
      :title="unit.name"
      :filter-text="filterText"
      icon="doc"
      :creator="unit.creator_name"
      :creator-profile-img="unit.creator_profile_url"
      :tags="tags"
      :stage-tag="stageTag"
      :applicability-status="applicabilityStatus"
      :inactive="true"
      :branch="showBranchName"
      :is-highlighted="isHighlightedItem"
      :show-doc-tags="true"
      :doc-id="unit.id"
      :draft-id="unit.draftId"
      :repo-id="repoId"
      :has-variations="hasVariations"
      :type="DocumentationTypes.DOC"
      @variations-click="onVariationsClick"
      :path="unit.path"
    >
      <template v-if="!isPending" #options>
        <EllipsisOption name="Delete" icon="trash-outline" :handler="() => $emit('delete')" />
      </template>
      <template v-if="!isPending" #info>
        <Icon class="status-icon" name="window-unavailable" />
        <span class="unavailable-text"> {{ inactiveUnitText }}</span>
      </template>
    </ListViewItem>
  </div>
</template>

<script setup lang="ts">
import EllipsisOption from '@/common/components/atoms/EllipsisOption.vue';
import { useDemoStore } from '@/modules/demo/demo';
import { confirmDraftDeletion } from '@/modules/drafts3/discard-draft-confirmations';
import { storeToRefs } from 'pinia';
import { useStore } from 'vuex';
import { ApplicabilityStatus } from '@swimm/shared';
import { clickedOnInternalCardElement } from '@/common/utils/card-utils';
import { useSwimmResource } from '@/common/composables/swimmResource';
import ListViewItem from '@/modules/core/components/ListViewItem.vue';
import { DocumentationTypes } from '@/common/consts';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { SwDate } from '@swimm/ui';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { useResourceActions } from '@/modules/resources/composables/resource-actions';
import { computeDraftDisplayName } from '@/common/utils/draft-utils';
import { SWIMM_DOC_SITE_EXPORT_TO_PDF } from '@/config';

const store = useStore();

const tagsTypes = {
  Example: 'Example',
  Deleted: 'Deleted',
  Draft: 'Local draft',
  LocalDraft: 'Local draft',
  Pending: 'PENDING',
  Committed: 'COMMITTED',
  Plans: 'PLANS',
  Warning: 'WARNING',
  Updated: 'UPDATED',
  Autosync: 'sync',
};
const props = defineProps({
  unit: { type: Object, default: () => ({}) },
  available: { type: Boolean, default: true },
  applicableStatus: { type: String, default: ApplicabilityStatus.Verified },
  unitType: { type: String, required: true },
  repoId: { type: String, required: true },
  branch: { type: String, required: false, default: '' },
  filterText: { type: String, required: false, default: '' },
  hasVariations: { type: Boolean, default: false },
  showFolderPath: { type: Boolean, default: false },
  selectable: { type: Boolean, default: false },
});

const emit = defineEmits([
  'discard',
  'delete',
  'move',
  'select',
  'open-commit',
  'open-share-modal',
  'report-click',
  'click',
]);
const route = useRoute();
const router = useRouter();
const { duplicateResource, getResourceContainerRoute, getResourceLink } = useResourceActions();
const { isResourceEditable } = useSwimmResource();
const { isOnDummyRepoPage } = storeToRefs(useDemoStore());
const { getItemParentFolder } = useFoldersStore();

const isDraft = computed(() => {
  return !!props.unit.draftId;
});

const canEdit = computed(() => {
  return isDraft.value || isResourceEditable(props.repoId, props.unit.id, 'unit');
});
const isDraftOfUnsavedUnit = computed(() => {
  return isDraft.value && !props.unit.id;
});

const allowDuplicate = computed(() => {
  return !isDraft.value && !isPending.value && !isWorkspacePage.value && !isOnDummyRepoPage.value;
});

const inactiveUnitTooltip = computed(() => {
  if (isPending.value) {
    return '';
  }

  return !props.available ? inactiveUnitStatuses.value['not-found'].tooltip : '';
});
const inactiveUnitText = computed(() => {
  return !props.available ? inactiveUnitStatuses.value['not-found'].text : '';
});
const draftTagTooltip = computed(() => {
  const stateText = props.unit.id && !props.unit.isNew ? 'changed' : 'created';
  return `This file has been ${stateText} locally, but not yet committed to the branch ${props.branch}`;
});
const stageTag = computed(() => {
  if (isDraft.value) {
    return {
      text: tagsTypes.LocalDraft,
      isWarning: true,
      tooltip: draftTagTooltip.value,
      handler: (event) => {
        event.preventDefault();
        emit('open-commit');
      },
    };
  } else if (isPending.value) {
    return { text: tagsTypes.Pending };
  } else {
    return { text: tagsTypes.Committed };
  }
});
const tags = computed(() => {
  const tags = [];

  if (props.unit.runningAutosync) {
    tags.push({ text: tagsTypes.Autosync, isIcon: true, tooltip: 'Verifying your documentation' });
  }

  if (isPending.value && props.unit.isPendingDeletion) {
    tags.push({ text: tagsTypes.Deleted, isIcon: true, tooltip: 'Deleted' });
  }

  return tags;
});
const applicabilityStatus = computed(() => {
  if (isPending.value) {
    return null;
  }
  // Currently there is no autosync pending swimms - the status in the swimm card is form the current branch and not the PR branch {
  if (
    [ApplicabilityStatus.Outdated, ApplicabilityStatus.Autosyncable].includes(
      props.applicableStatus as ApplicabilityStatus
    )
  ) {
    return { text: tagsTypes.Warning, isIcon: true, tooltip: 'Doc is out-of-date' };
  } else if (ApplicabilityStatus.Verified) {
    return { text: tagsTypes.Updated, isIcon: true, tooltip: 'Up to date' };
  }
  return null;
});
const inactiveUnitStatuses = computed(() => {
  return {
    [ApplicabilityStatus.Unavailable]: {
      text: `Unavailable document`,
      tooltip: `Either you don’t have the updated repo locally or this document file hasn’t been pushed by the author. Pull the latest repo version or ask the repo admin to verify its status.`,
    },
    newer: {
      text: 'Created on a newer version',
      tooltip: 'This swimm file was created or edited with a newer Swimm version. Click to upgrade Swimm.',
    },
    [ApplicabilityStatus.Invalid]: {
      text: `Invalid document`,
      tooltip: `The document's local file structure is invalid and could not be loaded. Please pull the latest repo version or ask the repo admin to verify its status.`,
    },
  };
});

const canDeleteResource = computed(() => {
  // If card is in workspacePage- don't allow to remove it
  if (isWorkspacePage.value) {
    return false;
  }
  if (isOnDummyRepoPage.value) {
    return false;
  }
  // If the card is of another branch (received as a prop) - Don't allow to remove it.
  // TODO: remove this after supporting removal of docs from another branch
  if (isPending.value) {
    return false;
  }
  if (isDraftOfUnsavedUnit.value) {
    return false;
  }
  return isResourceEditable(props.repoId, props.unit.id, 'unit');
});
const isPending = computed(() => {
  return props.unit.pending;
});
const isWorkspacePage = computed(() => {
  return !route?.params?.repoId;
});
const showBranchName = computed(() => {
  return isPending.value && !isWorkspacePage.value && props.branch;
});
const isHighlightedItem = computed(() => {
  if (isDraft.value) {
    return false;
  }
  return route.query.highlightedUnitId === props.unit.id;
});

const lastModifiedDate = computed(() => {
  return props.unit?.modified?.toDate();
});

const docFolderId = computed(() => {
  if (props.unit.folderId) {
    return props.unit.folderId;
  }
  if (props.unit.id) {
    const folder = getItemParentFolder(props.unit.id, props.repoId);
    return folder?.id;
  }
  return null;
});

const cleanLoadedRepoData = async (args?) => await store.dispatch('filesystem/cleanLoadedRepoData', args);

async function shouldDiscardDraft() {
  if (await confirmDraftDeletion()) {
    emit('discard');
  }
}
function clearNewBranchData(event) {
  if (clickedOnInternalCardElement(event)) {
    return;
  }
  if (props.branch !== route.params.branch) {
    cleanLoadedRepoData(props.repoId);
  }
  emit('click');
}
function navigateToEdit() {
  router.push(
    getResourceLink({
      resource: props.unit,
      repoId: props.repoId,
      branch: props.branch,
      type: DocumentationTypes.DOC,
      editMode: true,
    })
  );
}
function onVariationsClick() {
  const docId = props.unit.id;
  const docUrl = `${getResourceContainerRoute({
    repoId: props.repoId,
    branch: props.branch,
    type: DocumentationTypes.DOC,
  })}/${docId}/comitted`;
  router.push(docUrl);
}

function openShareModal() {
  emit('open-share-modal', {
    entity: 'DOC',
    entityId: props.unit.id ?? null,
    entityName: props.unit.name,
    entityLink: `${location.origin}${getResourceLink({
      resource: props.unit,
      repoId: props.repoId,
      branch: props.branch,
      type: DocumentationTypes.DOC,
    })}`,
    repoId: props.repoId,
  });
}
function reportClick(actionName) {
  emit('report-click', { type: 'Doc', id: props.unit.id, action: actionName });
}
const onClickExportPDF = () => {
  window.open(SWIMM_DOC_SITE_EXPORT_TO_PDF, '_blank').focus();
};
</script>

<style scoped>
.unavailable-text {
  font-weight: bold;
}

.status-icon {
  font-size: var(--fontsize-m);
}
</style>
