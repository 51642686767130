/**
 * Validate click performed on outer part of card and not on internal clickables
 */
export function clickedOnInternalCardElement(event) {
  return (
    event.target.classList.contains('ellipsis') ||
    event.target.classList.contains('card-footer') ||
    event.target.classList.contains('ellipsis-option') ||
    event.target.classList.contains('menu-icon')
  );
}
