import type { CTagKind, CTagLanguage } from './ctags-consts';
import type { SwmFile, SwmSymbolGenericText } from '../types';
import type { StatusCodes } from 'http-status-codes';
import * as iter from '../utils/iter';
import type { SmartContentSuggestion } from '../content-suggestions';
import type { GitDriverBase } from '../git-utils';

export interface RepoTarget {
  cloneUrl: string;
  languages: CTagLanguage[];
  branchOrTag: string;
  commitSha?: string;
}

export interface Library {
  name: string;
  websiteURL: string;
  searchTerms?: string[];
}

export interface JSLibrary extends Library {
  packageName: string;
}

export type EntityKind = CTagKind | 'file' | 'directory' | 'mention' | 'vue-component';

export type SGDSubOperation = 'discover' | 'generate';

/**
 * A raw JSON required to encode the information about an entity; useful for passing around as plain JSON.
 */
export interface EntityInfo {
  readonly name?: string;
  readonly kind: EntityKind;
  // Which keywords to look for when searching for references of this entity (useful for Vue components for example).
  readonly referenceKeywords?: string[];
  readonly path: string;
  readonly startLine?: number;
  readonly endLine?: number;
  readonly language?: CTagLanguage;
  readonly uiFramework?: Library;
}

export interface MentionInfo extends Omit<EntityInfo, 'startLine'> {
  readonly entity: EntityInfo;
  readonly startLine: number;
  readonly lineText: string;
}

export interface SemgrepMetavarInfo {
  readonly abstractContent: string;
  readonly startLine: number;
  readonly endLine: number;
}

export interface SemgrepResultInfo {
  readonly rule: string;
  readonly language: CTagLanguage;
  readonly lines: string;
  readonly startLine: number;
  readonly endLine: number;
  readonly path: string;
  readonly metavars: Record<string, SemgrepMetavarInfo>;
}

export interface TypeAnchorInfo {
  readonly type: EntityInfo;
  readonly mentions: MentionInfo[];
}

export interface PytestFixtureAnchorInfo {
  readonly fixture: EntityInfo;
  readonly usages: SemgrepResultInfo[];
}

export interface LogicalComponentInfo {
  readonly path: string;
  readonly types: TypeAnchorInfo[];
  readonly subComponents: LogicalComponentInfo[];
}

// When adding a document type here, make sure to update orderDocuments below.
export enum SGDocumentType {
  ADDING_A = 'adding-a',
  PYTEST_FIXTURES = 'pytest-fixtures',
  COMPONENT_OVERVIEW = 'component-overview',
  FS_PATTERN = 'fs-pattern',
}

/**
 * Order documents of different types in the way that they should be shown to the user.
 */
export function orderDocuments<TMetadata extends DocumentMetadata>(
  documentMetadatasByType: Map<SGDocumentType, TMetadata[]>
): TMetadata[] {
  const documentMetadatasByTypeCopy = new Map(
    iter.map(documentMetadatasByType.entries(), ([type, metadatas]) => [type, [...metadatas]])
  );
  const popDocs = (type: SGDocumentType, count?: number) =>
    // Note - we can't just pass count === undefined to splice as it is not equivalent to omitting count.
    documentMetadatasByTypeCopy.get(type)?.splice(0, count ?? 10000000) || [];
  return [
    // ADDING_A proved itself to be very successful with clients, so we start with them.
    ...popDocs(SGDocumentType.ADDING_A, 2),
    // FS_PATTERNS are similar in nature to ADDING_A, so we show them next.
    ...popDocs(SGDocumentType.FS_PATTERN, 2),
    // PYTEST_FIXTURES is just one doc and provides good value, so we promote it.
    ...popDocs(SGDocumentType.PYTEST_FIXTURES, 1),
    // Top 2 components are also probably important to promote
    ...popDocs(SGDocumentType.COMPONENT_OVERVIEW, 2),
    // We've seen that the first 10 ADDING_A documents are often very good, so we list the rest of the 10 here (first 2
    // Already listed above).
    ...popDocs(SGDocumentType.ADDING_A, 8),
    // Same with FS_PATTERNS.
    ...popDocs(SGDocumentType.FS_PATTERN, 8),
    // There are seldom more than 4-5 COMPONENT_OVERVIEW docs, so we list them here before the more numerous ADDING_A.
    ...popDocs(SGDocumentType.COMPONENT_OVERVIEW),
    // Finally, list the rest of the ADDING_A/FS_PATTERN documents.
    ...popDocs(SGDocumentType.ADDING_A),
    ...popDocs(SGDocumentType.FS_PATTERN),
  ];
}

export interface DocumentMetadataBase {
  documentType: SGDocumentType;
  documentId: string;
  documentName: string;
  firstFewWords: string;
  relevantCodePath?: string;
  score?: number;
}
export type DocumentId = DocumentMetadataBase['documentId'];

interface DocumentWithExampleMetadata extends DocumentMetadataBase {
  selectedExample?: EntityInfo;
}

export interface AddingAMetadata extends DocumentWithExampleMetadata {
  documentType: SGDocumentType.ADDING_A;
  class: EntityInfo;
}

export interface PytestFixturesMetadata extends DocumentMetadataBase {
  documentType: SGDocumentType.PYTEST_FIXTURES;
  demonstratedFixtures: PytestFixtureAnchorInfo[];
  moduleScopeFixtures: EntityInfo[];
  autoUseFixtures: EntityInfo[];
}

export interface ComponentOverviewMetadata extends DocumentMetadataBase {
  documentType: SGDocumentType.COMPONENT_OVERVIEW;
  component: LogicalComponentInfo;
}

export interface FSPatternMetadata extends DocumentWithExampleMetadata {
  documentType: SGDocumentType.FS_PATTERN;
  parentPath: string;
  anchorName: string;
}

export type DocumentMetadata = AddingAMetadata | PytestFixturesMetadata | ComponentOverviewMetadata | FSPatternMetadata;

export function isDocumentMetadata(obj: unknown): obj is DocumentMetadata {
  const isObj = typeof obj === 'object';
  const areSimpleFieldsCorrectType =
    isObj &&
    typeof obj['documentId'] === 'string' &&
    typeof obj['documentName'] === 'string' &&
    typeof obj['firstFewWords'] === 'string';
  const isDocumentTypeCorrect = isObj && Object.values(SGDocumentType).includes(obj['documentType']);
  return isObj && areSimpleFieldsCorrectType && isDocumentTypeCorrect;
}

export function isDocumentId(value: unknown): value is DocumentId {
  return typeof value === 'string';
}

export interface GenerationInfo {
  generatedSections: string[];
}

export interface ExampleDocumentGenerationInfo extends GenerationInfo {
  selectedExample: EntityInfo;
  availableExamples?: EntityInfo[];
}

export interface GeneratedDocument {
  metadata: DocumentMetadata;
  generationInfo?: GenerationInfo;
  swm: SwmFile;
  contentSuggestions?: SmartContentSuggestion[];
}

export enum CodeAnalysisAction {
  SGD_DISCOVER = 'sgd-discover',
  SGD_GENERATE = 'sgd-generate',
  SGD_DISCOVER_AND_GENERATE = 'sgd-discover-and-generate',
  FIND_DEFINITION_TOKENS = 'find-definition-tokens',
  GET_REPO_TREE = 'get-repo-tree',
}

export interface CodeAnalysisRequestData {
  repoTarget: RepoTarget;
  cloneToken: string;
  repoId: string;
  noCache?: boolean;
}

export interface SGDGenerateRequestData extends CodeAnalysisRequestData {
  documentMetadatas: DocumentMetadata[];
}

export interface SGDDiscoverAndGenerateRequestData extends CodeAnalysisRequestData {
  ignoreDocumentIds: string[];
  fromDocumentIndex?: number;
  toDocumentIndex?: number;
}

export type CodeAnalysisRequest =
  | ({
      action: CodeAnalysisAction.SGD_DISCOVER;
    } & CodeAnalysisRequestData)
  | ({
      action: CodeAnalysisAction.SGD_GENERATE;
    } & SGDGenerateRequestData)
  | ({
      action: CodeAnalysisAction.SGD_DISCOVER_AND_GENERATE;
    } & SGDDiscoverAndGenerateRequestData)
  | ({
      action: CodeAnalysisAction.FIND_DEFINITION_TOKENS;
    } & CodeAnalysisRequestData)
  | ({ action: CodeAnalysisAction.GET_REPO_TREE } & CodeAnalysisRequestData);

export type CodeAnalysisErrorResponse = {
  status: 'error';
  code: StatusCodes;
  error?: Error;
};

export type CodeAnalysisSuccessResponse = {
  status: 'success';
};

export type CodeAnalysisDiscoveredDocsResponse = CodeAnalysisSuccessResponse & {
  discoveredDocs: DocumentMetadata[];
};

export type CodeAnalysisGeneratedDocsResponse = CodeAnalysisSuccessResponse & {
  generatedDocs: GeneratedDocument[];
};

export type CodeAnalysisTokensResponse = CodeAnalysisSuccessResponse & {
  tokens: SwmSymbolGenericText[];
};

export type CodeAnalysisGetRepoTreeResponse = CodeAnalysisSuccessResponse & {
  tree: Awaited<ReturnType<GitDriverBase['getRepoTree']>>;
};

export type CodeAnalysisResponse =
  | CodeAnalysisErrorResponse
  | CodeAnalysisDiscoveredDocsResponse
  | CodeAnalysisGeneratedDocsResponse
  | CodeAnalysisTokensResponse
  | CodeAnalysisGetRepoTreeResponse;

export type CodeAnalysisResponseMap = {
  [CodeAnalysisAction.SGD_DISCOVER]: CodeAnalysisDiscoveredDocsResponse;
  [CodeAnalysisAction.SGD_GENERATE]: CodeAnalysisGeneratedDocsResponse;
  [CodeAnalysisAction.SGD_DISCOVER_AND_GENERATE]: CodeAnalysisGeneratedDocsResponse;
  [CodeAnalysisAction.FIND_DEFINITION_TOKENS]: CodeAnalysisTokensResponse;
  [CodeAnalysisAction.GET_REPO_TREE]: CodeAnalysisGetRepoTreeResponse;
};

export type CodeAnalysisLiveRequest = {
  action: CodeAnalysisAction.SGD_DISCOVER_AND_GENERATE;
} & CodeAnalysisRequestData;

export type CodeAnalysisLiveErrorData = { error?: string };

export type CodeAnalysisLiveStartedResponse = {
  type: 'started';
};

export type CodeAnalysisLiveClonedResponse = {
  type: 'cloned';
  wasAlreadyCached: boolean;
};

export type CodeAnalysisLiveCTagsGeneratedResponse = {
  type: 'ctags-generated';
  wasAlreadyCached: boolean;
};

export type CodeAnalysisLiveDocumentTypesResponse = {
  type: 'document-types';
  documentTypes: SGDocumentType[];
};

export type CodeAnalysisLiveDiscoveredDocsResponse = {
  type: 'discovered-docs';
  documentType: SGDocumentType;
} & (
  | {
      discoveredDocs: DocumentMetadata[];
    }
  | CodeAnalysisLiveErrorData
);

export type CodeAnalysisLiveGeneratedDocResponse =
  | { type: 'generated-doc' } & (
      | {
          generatedDocument: GeneratedDocument;
        }
      | (CodeAnalysisLiveErrorData & {
          documentMetadata: DocumentMetadata;
        })
    );

export type CodeAnalysisLiveTokensResponse = {
  type: 'found-tokens';
  tokens: SwmSymbolGenericText[];
};

export type CodeAnalysisLiveErrorResponse = {
  type: 'error';
  code: number; // From 'websocket-event-codes'
  error: string;
};

// Sent at the end of the operation to indicate that it had finished successfully (useful when results are streaming).
export type CodeAnalysisLiveSubOperationFinishedResponse<Operations extends string> = {
  type: 'finished';
  operation: Operations;
};

export type SGDDiscoverAndGenerateLiveResponse =
  | CodeAnalysisLiveStartedResponse
  | CodeAnalysisLiveClonedResponse
  | CodeAnalysisLiveCTagsGeneratedResponse
  | CodeAnalysisLiveDocumentTypesResponse
  | CodeAnalysisLiveDiscoveredDocsResponse
  | CodeAnalysisLiveGeneratedDocResponse
  | CodeAnalysisLiveSubOperationFinishedResponse<SGDSubOperation>;

export type CodeAnalysisLiveResponseMap = {
  [CodeAnalysisAction.SGD_DISCOVER_AND_GENERATE]: SGDDiscoverAndGenerateLiveResponse;
};

export interface RepoEntity {
  name: string;
  paths: string[];
  subEntities: RepoEntity[];
  docIds: string[];
  summary?: string;
  folderId?: string;
  loading?: boolean;
  enabled?: boolean;
  isFlow?: boolean;
  aggregator?: boolean;
}
