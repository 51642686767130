<template>
  <div v-if="!loading" class="doc-toc" data-testid="doc-toc">
    <template v-if="root && root.children.length">
      <TocNode
        v-for="(heading, index) of root.children"
        :key="index"
        :heading="heading"
        :top-level="topLevel"
        :doc="doc"
        :test-path="`${index}`"
        :visible-id="visibleId"
        @heading-clicked="headingClicked"
      />
    </template>
    <SwText v-else variant="body-S" class="empty-toc" data-testid="doc-toc-empty">Headings will be listed here</SwText>
  </div>
</template>

<script lang="ts">
import TocNode from '@/common/components/DocSidebar/TocNode.vue';
import { buildDocToc } from '@/modules/doc-sidebar/services/doc-toc-utils';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { getLoggerNew, productEvents } from '@swimm/shared';
import type { PropType } from 'vue';

const logger = getLoggerNew(__modulename);

export default {
  components: {
    TocNode,
  },
  props: {
    doc: { type: Object, required: true },
    headings: { type: Object as PropType<NodeListOf<Element>>, default: null },
  },
  setup() {
    const analytics = useAnalytics();
    return { analytics };
  },
  data() {
    return {
      loading: true,
      root: null,
      visibleId: '',
      allHeadings: [],
      intersectionObserver: null,
    };
  },
  computed: {
    topLevel() {
      if (this.root.children.length) {
        return this.root.children.map((c) => c.level).reduce((l1, l2) => Math.min(l1, l2), 1000);
      }
      return 0;
    },
  },
  watch: {
    headings: {
      handler(value) {
        if (value) {
          this.buildToc();
        }
      },
      immediate: true,
    },
  },
  beforeUnmount() {
    this.stopObserve();
  },
  methods: {
    headingClicked(heading) {
      this.analytics.track(productEvents.CLICKED_DOC_TOC_ITEM, {
        'Workspace ID': this.$route.params.workspaceId,
        'Repo ID': this.$route.params.repoId,
        'Document ID': this.doc.id,
        Context: 'View Doc',
        'Item Type': `H${heading.level}`,
      });
      heading.$element.scrollIntoView();
    },
    buildToc() {
      try {
        const { root, allHeadings } = buildDocToc(this.headings);
        this.root = root;
        this.allHeadings = allHeadings;
        this.visibleId = '';
        this.startObserve();
      } catch (err) {
        logger.error({ err }, `Failed to build toc ${err}`);
      }
      this.loading = false;
    },
    startObserve() {
      this.stopObserve();
      if (this.allHeadings.length === 0) {
        return;
      }
      const allHeadingsByElement = new Map();
      for (const heading of this.allHeadings) {
        allHeadingsByElement.set(heading.$element, heading);
      }
      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            const heading = allHeadingsByElement.get(entry.target);
            if (heading) {
              heading.ratio = entry.intersectionRatio;
            }
          }
          // Assume the current visible header is the
          // first one with ratio > 0.9
          for (const heading of this.allHeadings) {
            if (heading.ratio > 0.9) {
              this.visibleId = heading.id;
              return;
            }
          }
        },
        {
          threshold: [0, 0.9, 0.92, 0.95, 1],
        }
      );
      for (const heading of this.allHeadings) {
        heading.ratio = 0;
        this.intersectionObserver.observe(heading.$element);
      }
    },
    stopObserve() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
        this.intersectionObserver = null;
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.doc-toc {
  .empty-toc {
    color: var(--text-color-secondary);
  }
}
</style>
