import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { STORES } from '@/modules/core/stores/store-constants';
import { DocumentationTypes } from '@/common/consts';

export const TypeOptions = {
  DOC: { key: DocumentationTypes.DOC, label: 'Docs' },
  PLAYLIST: { key: DocumentationTypes.PLAYLIST, label: 'Playlists' },
};

export const TypesIncludingExercisesOptions = {
  ...TypeOptions,
  EXERCISE: { key: 'exercise', label: 'Exercise' },
};

export const TypesIncludingFolderOptions = {
  ...TypeOptions,
  FOLDER: { key: DocumentationTypes.FOLDER, label: 'Folders' },
};

export const useFiltersStore = defineStore(STORES.DOCUMENTATION_FILTERS, () => {
  const typeFilter = ref([]);
  const tagFilters = ref([]);
  const treeFilter = ref('');

  function resetFilters() {
    typeFilter.value = [];
    tagFilters.value = [];
    treeFilter.value = '';
  }

  const isFiltersActive = computed(() => {
    return Boolean(typeFilter.value.length) || Boolean(tagFilters.value.length) || Boolean(treeFilter.value);
  });

  return {
    TypeOptions,
    TypesIncludingExercisesOptions,
    TypesIncludingFolderOptions,
    typeFilter,
    resetFilters,
    tagFilters,
    isFiltersActive,
    treeFilter,
  };
});
