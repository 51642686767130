import {
  type GenerateTextModifiersRequest,
  GenerativeAiRequestType,
  type LlmCallGenericRequest,
  type Prompt,
} from '@swimm/shared';
import { v4 as uuidv4 } from 'uuid';

export const getGenerateTextModifiersPrompt = (request: GenerateTextModifiersRequest): Prompt => {
  return {
    messages: [
      {
        role: 'system',
        content: `The user asked to rewrite some text in their document according to some guideline.
Rules:
1. Never invent specific details that are not written in the original text.
2. Never add a period at the end if it wasn't there originally.

Reply in JSON format:
{error?: string; generatedText?: string}`,
      },
      {
        role: 'user',
        content: `The guideline is "${request.modifier}".
The text to rewrite is \`\`\`${request.textInput}\`\`\`.`,
      },
    ],
    options: { temperature: 0.1 },
    workspaceId: request.workspaceId,
  };
};

export function getGenerateTextModifiersRequestParams(request: GenerateTextModifiersRequest): LlmCallGenericRequest {
  return {
    prompt: getGenerateTextModifiersPrompt(request),
    type: GenerativeAiRequestType.GENERATE_TEXT_MODIFIER,
    workspaceId: request.workspaceId,
    requestId: `${GenerativeAiRequestType.GENERATE_TEXT_MODIFIER}-${uuidv4()}`,
  };
}
