import { SELECT_MARKER, SwmCellSnippet } from './types';
import { sha256 } from 'js-sha256';

export interface SnippetMeat {
  lines: string[];
  firstLine: number;
}

export function extractMeatFromSnippet(snippet: SwmCellSnippet): SnippetMeat {
  const lines = [];
  let firstLine = -1;

  for (const [i, line] of snippet.lines.entries()) {
    if (line.startsWith(SELECT_MARKER)) {
      if (firstLine === -1) {
        firstLine = snippet.firstLineNumber + i;
      }
      lines.push(line.slice(1));
    }
  }

  return { lines, firstLine };
}

/* does some white space trimming and squeezing and return checksum */
export function normalizedChecksum(s: string): string {
  const normalizedString = s.trim().replace(/\s+/g, ' ');
  return sha256(`salt-for-checksum${normalizedString}`);
}

export function getPathChecksum(p: string): string {
  return sha256(`salt-for-path${p}`);
}
