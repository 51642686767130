<script setup lang="ts">
import { useFoldersStore } from '@/modules/folders/store/folders';
import { computed, ref, watch } from 'vue';
import SidebarRepoLineFolderContent from './SidebarRepoLineFolderContent.vue';
import { Loader } from '@swimm/ui';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const props = defineProps({
  repoId: { type: String, required: true },
  filter: { type: String, default: '' },
});

const route = useRoute();
const store = useStore();
const initialPath = ref('');

const { getRepoRootFolder, getItemParentFolder, getFolderPath, getFilteredFolders } = useFoldersStore();

const repoFolder = computed(() => getRepoRootFolder(props.repoId));

const filteredFolders = computed(() => {
  if (!props.filter) {
    return [];
  }
  return getFilteredFolders(props.filter, props.repoId);
});

watch(
  () => route.params,
  (newVal) => {
    const documentationId = newVal.unitId || newVal.playlistId;
    const parentFolder = getItemParentFolder(documentationId as string, props.repoId);
    if (parentFolder) {
      let documentation;
      if (!newVal.playlistId) {
        documentation = store.getters['database/db_getSwimm'](props.repoId, documentationId);
      } else {
        documentation = store.getters['database/db_getPlaylist'](props.repoId, documentationId);
      }
      if (documentation) {
        initialPath.value = `${getFolderPath(parentFolder.id, props.repoId)}/${documentation.name}`;
      } else {
        initialPath.value = getFolderPath(parentFolder.id, props.repoId);
      }
    }
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <div class="repo-content">
    <SidebarRepoLineFolderContent
      v-if="repoFolder"
      :repo-id="repoId"
      :folder="repoFolder"
      :depth="1"
      :path="initialPath"
      :filter="filter"
      :filtered-folders="filteredFolders"
    />
    <Loader secondary v-else />
  </div>
</template>

<style scoped lang="postcss">
.repo-content {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}
</style>
