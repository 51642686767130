<script setup lang="ts">
import { useFoldersStore } from '@/modules/folders/store/folders';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';

const props = defineProps({
  folderId: { type: String, required: true },
  repoId: { type: String, required: true },
});

const emit = defineEmits(['add-folder', 'add-doc', 'add-playlist', 'add-template']);

const analytics = useAnalytics();
const { getFolderPath } = useFoldersStore();

function reportClick(item) {
  analytics.track(productEvents.SELECTED_FOLDER_ADD_MENU_ITEM, {
    'Folder ID': props.folderId,
    'Folder Path': getFolderPath(props.folderId, props.repoId),
    Item: item,
  });
}
</script>

<template>
  <EllipsisOption name="Folder" icon="folder" :handler="() => emit('add-folder')" @click="reportClick('Subfolder')" />
  <EllipsisOption name="Doc (blank)" icon="doc" :handler="() => emit('add-doc')" @click="reportClick('Blank Doc')" />
  <EllipsisOption
    name="Playlist"
    icon="playlist"
    :handler="() => $emit('add-playlist')"
    @click="reportClick('Playlist')"
  />
  <EllipsisOption
    name="Templates & more"
    icon="template"
    :handler="() => $emit('add-template')"
    @click="reportClick('Creation Hub')"
  />
</template>
