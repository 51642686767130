/**
 * Regex to match the exact hunk header.
 * To get a more detailed info on this regex syntax, copy the regex to https://regex101.com/
 */

export const GIT_HUNK_HEADER_REGEX = '@@\\s-(\\d+),?(\\d+)?\\s\\+(\\d+),?(\\d+)?\\s@@(?:(?!\n).)*';
export const GIT_FILE_REGEX = `(?=^diff\\s--git\\s(?:(?!\n).)*)`;
export const GIT_HUNK_UNGROUPED_HEADER_REGEX = '(?=^@@\\s-\\d+,?\\d+?\\s\\+\\d+,?\\d+?\\s@@(?:(?!\n).)*)';
export const DEFAULT_FILE_MODE = 100644;
export const MAX_FILE_SIZE = 999999999; // Adding even one more `9` results in a bug in git version 2.20.1.windows.1

// TODO: replace the regex here
export const FILE_NAMES_REGEX = `a/.*(?=["']? ["']?b/)|b/.*$`;

export function fileNameRegexFromMarkersLine(marker) {
  return new RegExp(`(?:${escapeRegex(marker).repeat(3)} )(?=/dev/null).*[^\\n|$]`, 'gm'); // Captures "+++ /dev/null"
}

type SplitByRegexArgs = { text: string; pattern: string; flags?: string };
export function splitStringByRegex({ text, pattern, flags = 'gm' }: SplitByRegexArgs): string[] {
  const matches = text.matchAll(new RegExp(pattern, flags));
  if (!matches) {
    return [];
  }
  return Array.from(matches).map((match, matchArrayIndex, allMatches) => {
    const matchEnding = allMatches[matchArrayIndex + 1] ? allMatches[matchArrayIndex + 1].index - 1 : text.length;
    return text.slice(match.index, matchEnding);
  });
}

function escapeRegex(string) {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function isEOFLineChange(line: string) {
  return line === '\\ No newline at end of file';
}

/**
 * Returns the line number integer if the argument is a valid number.
 * Note - checking isNaN is needed (a simple !lineNumber check will return false negative on the lineNumber 0)
 * @param stringifiedNumber - the number to test or undefined if doesn't exist (undefined input might be provided
 */
export function parseIntOrNull(stringifiedNumber: string): number | null {
  const lineNumber = parseInt(stringifiedNumber);
  return isNaN(lineNumber) ? null : lineNumber;
}
