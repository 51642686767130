import { defineStore } from 'pinia';
import { useEditorAnimation } from '@/composables/useEditorAnimations';
import { ref, shallowRef, watch } from 'vue';
import type { Editor } from '@tiptap/vue-3';
import type { NeedsReviewLength, NodesThatNeedsReview } from '@/types/ReviewAutosyncTypes';

export const useEditorStore = defineStore('editorStore', () => {
  // Animations
  const nodeAnimationsSet = ref<Set<string>>(new Set());
  const { shouldAnimateNode, pushIdToNodeAnimationSet } = useEditorAnimation(nodeAnimationsSet);

  // General
  const editorInstance = shallowRef<Editor | null>(null);
  const editorId = ref<string>();
  const swmId = ref<string>();
  // Review autosync
  const nodesThatNeedsReview = ref<NodesThatNeedsReview>();
  // TODO: consider computing needsReviewLength from nodesThatNeedsReview instead of maintaining 2 sources of truth
  const zeroLengthReview = { autosyncableLength: 0, outdatedLength: 0 };
  const needsReviewLength = ref<NeedsReviewLength>(zeroLengthReview);

  watch(
    swmId,
    () => {
      nodesThatNeedsReview.value = { autosynced: {}, outdated: {} };
      needsReviewLength.value = zeroLengthReview;
    },
    { immediate: true, flush: 'sync' } // Make sure reset applies before the review autosync component is triggered
  );

  // Generated ai
  const hasUserGeneratedAI = ref<boolean>(false);
  return {
    shouldAnimateNode,
    pushIdToNodeAnimationSet,
    editorId,
    swmId,
    editorInstance,
    hasUserGeneratedAI,
    nodesThatNeedsReview,
    needsReviewLength,
  };
});
