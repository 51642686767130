import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core';

function transformSwmSnippet(state: StateCore): void {
  const tokens = state.tokens;

  for (let open = 0; open < tokens.length; open++) {
    if (tokens[open].type !== 'swm_block_open' || tokens[open].tag !== 'SwmSnippet') {
      continue;
    }

    let close;
    for (close = open + 1; close < tokens.length; close++) {
      if (tokens[close].type === 'swm_block_close' && tokens[open].tag === 'SwmSnippet') {
        break;
      }
    }

    if (close === tokens.length) {
      continue;
    }

    // The element should have the appropriate horizonal rules and end with a fence
    if (tokens[open + 1].type !== 'hr' || tokens[close - 2].type !== 'fence' || tokens[close - 1].type !== 'hr') {
      continue;
    }

    tokens[open].type = 'swm_snippet_open';
    tokens[open].attrSet('snippet', tokens[close - 2].content);
    tokens[open].attrSet('language', tokens[close - 2].info);
    if (!tokens[open].attrGet('repo-id')) {
      tokens[open].attrSet('repo-id', state.env?.swimm?.repoId);
      tokens[open].attrSet('repo-name', state.env?.swimm?.repoName);
    }

    tokens[close].type = 'swm_snippet_close';

    tokens.splice(close - 2, 2);
    tokens.splice(open + 1, 1);
  }
}

export default function swm_snippet(md: MarkdownIt): void {
  md.core.ruler.after('block', 'swm_snippet', transformSwmSnippet);
}
