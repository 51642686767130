import type { Editor } from '@tiptap/core';
import { VueRenderer } from '@tiptap/vue-3';
import type { Instance } from 'tippy.js';
import tippy from 'tippy.js';
import type { Component } from 'vue';

export async function showEditorPopover(
  editor: Editor,
  component: Component,
  props: Record<string, unknown> = {}
): Promise<void> {
  return new Promise<void>((resolve) => {
    let popup: Instance | null = null;

    const renderer = new VueRenderer(component, {
      props: { ...props, editor, hide: () => popup?.hide() },
      editor,
    });

    const handleUpdate = () => {
      popup?.hide();
    };

    popup = tippy(editor.view.dom, {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      getReferenceClientRect: () => {
        const coords = editor.view.coordsAtPos(editor.state.selection.head);
        return new DOMRect(coords.left, coords.top, coords.right - coords.left, coords.bottom - coords.top);
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      appendTo: editor.view.dom.parentElement!,
      content: renderer.element,
      showOnCreate: true,
      interactive: true,
      trigger: 'manual',
      placement: 'bottom-start',
      theme: 'none',
      role: 'menu',
      maxWidth: 'none',
      onHidden: (instance) => {
        instance.destroy();
        renderer.destroy();
        editor.off('update', handleUpdate);
        resolve();
      },
    });

    // TODO Should we also do this on selectionUpdate?
    editor.on('update', handleUpdate);
  });
}
