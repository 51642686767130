import { type Ref, inject, onMounted, onUnmounted, provide, ref } from 'vue';

const WINDOW_WIDTH_INJECT = 'windowWidth';

export function useWindowWidth(): Ref<number> {
  const alreadyProvidedWindowWidth: Ref<number> | null = inject(WINDOW_WIDTH_INJECT, null);

  if (alreadyProvidedWindowWidth) {
    return alreadyProvidedWindowWidth;
  }

  const windowWidth: Ref<number> = ref(window.innerWidth);

  provide(WINDOW_WIDTH_INJECT, windowWidth);

  function updateWindowWidth() {
    windowWidth.value = window.innerWidth;
  }

  onMounted(() => {
    window.addEventListener('resize', updateWindowWidth);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateWindowWidth);
  });

  return windowWidth;
}
