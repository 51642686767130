<script setup lang="ts">
import OverviewCard from './OverviewCard.vue';
import { useUserStore } from '@/modules/core/stores/user';
import { storeToRefs } from 'pinia';

defineProps<{
  repoCount?: number;
  documentationCount?: number | 'disabled';
  userCount?: number;
  loading: boolean;
  sidebarOpen?: boolean;
}>();

defineEmits<{
  (event: 'invite'): void;
  (event: 'create'): void;
  (event: 'add-repo'): void;
  (event: 'view-all-click'): void;
}>();

const { isUserWorkspaceAdmin } = storeToRefs(useUserStore());
</script>

<template>
  <div class="cards">
    <OverviewCard
      icon="branch"
      data-testid="repositories-overview-card"
      :title="repoCount === 1 ? 'Repository' : 'Repositories'"
      :value="repoCount"
      link-text="Add"
      @link-click="$emit('add-repo')"
    />
    <OverviewCard
      v-if="documentationCount !== 'disabled'"
      icon="doc"
      data-testid="docs-overview-card"
      :title="documentationCount === 1 ? 'Doc/playlist' : 'Docs & playlists'"
      :value="documentationCount"
      :loading="loading"
      link-text="Create"
      @link-click="$emit('create')"
    >
      <SwText v-if="!sidebarOpen" variant="body-S" class="view-all-link" @click="$emit('view-all-click')">
        View all
      </SwText>
    </OverviewCard>
    <OverviewCard
      icon="team"
      data-testid="users-overview-card"
      :title="userCount === 1 ? 'User' : 'Users'"
      :value="userCount"
      :link-text="isUserWorkspaceAdmin ? 'Add users' : ''"
      @link-click="$emit('invite')"
    />
  </div>
</template>

<style scoped>
.cards {
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: var(--space-sm);
}

.view-all-link {
  cursor: pointer;
  color: var(--text-color-link);
  padding-left: var(--space-base);
  border-left: 2px solid var(--color-border-default-subtle);
}
</style>
