<script setup lang="ts">
import { BaseButton, BaseIcon } from '@swimm/reefui';
import type { GenerateMermaidWithAiDebugData } from './mermaidTypes';
import { useClipboard } from '@vueuse/core';

const props = defineProps<{
  debugData: GenerateMermaidWithAiDebugData;
}>();
const { copy, copied, isSupported } = useClipboard();
function copyAction() {
  copy(JSON.stringify(props.debugData, null, 2));
}
</script>

<template>
  <div :class="{ copied }">
    <BaseButton :disabled="!isSupported" v-if="!copied" @click="copyAction" v-tooltip="'Copy debug data'">
      <BaseIcon name="copy"></BaseIcon>
    </BaseButton>
    <BaseIcon v-if="copied" name="check" v-tooltip="'Copied'"></BaseIcon>
  </div>
</template>

<style scoped lang="postcss">
.copied {
  color: var(--text-color-success);
}
</style>
