<template>
  <div class="diff-files-links" data-testid="doc-referenced-files">
    <template v-if="hasFiles">
      <div v-for="(repoFiles, index) in repoFilesArray" :key="repoFiles.repoId" :data-testid="repoFiles.repoId">
        <MonacoViewFileModal
          v-if="!!fileToShow"
          :show="!!fileToShow"
          :heading="fileToShow.path"
          :monaco-options="monacoOptions"
          :file-content="fileToShow?.content"
          :file-type="fileToShow.type"
          :show-error="fileToShow.showError"
          :loading="fileToShow.loading"
          @close="closeMonacoModal"
        />
        <DocRepoFiles
          :files="repoFiles.files"
          :show-repo-names="showRepoNames"
          :repo-id="repoFiles.repoId"
          :no-access="repoFiles.noAccessToRepo"
          :show-help-tooltip="isPr && index === 0"
          :can-remove="canRemove"
          :is-authorized="isAuthorized"
          @open-file="openFile"
          @remove-file="removeFile"
        />
      </div>
    </template>
    <SwText v-else variant="body-S" class="empty-files" data-testid="doc-referenced-files-empty">
      Referenced files will be listed here
    </SwText>
  </div>
</template>

<script>
import { LangExtensionUtils, gitwrapper } from '@swimm/shared';
import { mapGetters } from 'vuex';
import DocRepoFiles from '@/modules/doc-sidebar/components/DocRepoFiles.vue';
import MonacoViewFileModal from '@/common/components/modals/MonacoViewFileModal.vue';
import { useRouting } from '@/common/composables/routing';
import { storeToRefs } from 'pinia';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';

export default {
  components: { DocRepoFiles, MonacoViewFileModal },
  props: {
    pathsByRepo: {
      type: Object,
      required: true,
    },
    repoId: { type: String, required: true },
    canRemove: { type: Boolean, required: false, default: false },
    isPr: { type: Boolean, required: false, default: false },
    removeFile: { type: Function, required: false, default: null },
  },
  setup() {
    const { getCurrentOrDefaultBranch } = useRouting();
    const { isCurrentRepoAuthorized } = storeToRefs(useGitAuthorizationStore());

    return {
      getCurrentOrDefaultBranch,
      isCurrentRepoAuthorized,
    };
  },
  data() {
    return {
      fileExistsCache: {},
      repoFilesArray: [],
      monacoOptions: {
        readOnly: true,
        contextmenu: false,
        scrollBeyondLastLine: false,
        minimap: { renderCharacters: false },
        automaticLayout: true,
        wordWrap: 'on',
      },
      fileToShow: null,
    };
  },
  watch: {
    pathsByRepo: {
      handler: function () {
        this.buildRepoFilesArray();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('database', ['db_getRepoMetadata']),
    hasFiles() {
      return Object.keys(this.pathsByRepo).length > 0;
    },
    showRepoNames() {
      return this.repoFilesArray.length > 1 || this.repoFilesArray[0].repoId !== this.repoId;
    },
    isAuthorized() {
      return this.isCurrentRepoAuthorized;
    },
  },
  methods: {
    closeMonacoModal() {
      this.fileToShow = null;
    },
    async buildRepoFilesArray() {
      // Put the repoIds first
      const repoIds = [
        ...(this.pathsByRepo[this.repoId] ? [this.repoId] : []),
        ...Object.keys(this.pathsByRepo).filter((repoId) => repoId !== this.repoId),
      ];
      this.repoFilesArray = await Promise.all(repoIds.map((repoId) => this.buildRepoFiles(repoId, { quick: true })));
      this.repoFilesArray = await Promise.all(repoIds.map((repoId) => this.buildRepoFiles(repoId, { quick: false })));
    },
    async buildRepoFiles(repoId, { quick }) {
      // In quick mode - we don't compute branch and exists
      const paths = this.pathsByRepo[repoId];
      const branch = quick ? null : (await this.getCurrentOrDefaultBranch(repoId)) || null;
      return {
        repoId,
        noAccessToRepo: this.isAuthorized && !branch,
        files: await Promise.all(
          paths.map(async (path) => {
            const exists = quick || !branch ? false : await this.isFileExists(path, repoId, branch);
            return {
              repoId,
              path,
              exists,
              branch,
            };
          })
        ),
      };
    },
    async isFileExists(path, repoId, branch) {
      if (!this.isAuthorized) {
        return false;
      }
      // Avoid accessing the gitwrapper everytime something refreshes the sidebar
      const key = `${path}:${repoId}:${branch}`;
      if (this.fileExistsCache[key] === undefined) {
        this.fileExistsCache[key] = await gitwrapper.isFileExistsOnRevision({
          filePath: path,
          repoId,
          revision: branch,
        });
      }
      return this.fileExistsCache[key];
    },
    async openFile(file) {
      if (!file.exists) {
        return;
      }
      const { path, repoId, branch } = file;
      try {
        this.fileToShow = {
          loading: true,
          path,
        };
        const content = await gitwrapper.getFileContentFromRevision({
          filePath: path,
          repoId,
          revision: branch,
        });
        const fileType = LangExtensionUtils.getFileProgrammingLanguageByPath(path);
        this.fileToShow = {
          loading: false,
          path,
          content,
          type: fileType,
        };
      } catch (err) {
        this.$logger.error(`Failed to get content for repoID=${repoId} path=${path} branch=${branch} error=${err}`, {
          module: 'DocFiles',
        });
        this.fileToShow = {
          path,
          loading: false,
          showError: true,
        };
      }
    },
  },
};
</script>

<style scoped>
.empty-files {
  color: var(--text-color-secondary);
}
</style>
