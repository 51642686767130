<script setup lang="ts">
import BaseTooltip from '../../components/BaseTooltip/BaseTooltip.vue';
import { computed } from 'vue';

const props = defineProps<{
  name: string;
  email: string;
  unavailable: boolean;
  unavailableMessage: string;
}>();

const tooltip = computed(() => {
  return props.unavailable ? props.unavailableMessage : props.email;
});

const computedClasses = computed(() => ({
  'swm-node-view-item-mention--unavailable': props.unavailable,
}));
</script>

<template>
  <div class="swm-node-view-item-mention" :class="computedClasses" data-testid="swm-mention-node">
    <BaseTooltip :content="tooltip">
      <span class="swm-node-view-item-mention__name" variant="link">@{{ name }}</span>
    </BaseTooltip>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-node-view-item-mention {
  $self: &;

  display: inline-block;

  &__name {
    color: var(--color-text-brand);
  }

  &--unavailable {
    #{$self}__name {
      background-color: var(--color-bg-danger-subtle);
      border-radius: var(--space-xxxsmall);
      color: var(--color-text-danger);
    }
  }
}
</style>
