// Gets dbItem of a document and return list of contributors
import * as firestoreWrapper from '@/adapters-common/firestore-wrapper';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { config, getLoggerNew } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export enum slackNotificationTypes {
  NEW_DOC = 'new-doc',
}

export interface SlackIntegrations {
  conversation_id: string;
  conversation_name: string;
  team_id: string;
  team_name: string;
  type: slackNotificationTypes;
}

/**
 * Return entire collection for slack integrations
 */
function getSlackIntegrationsCollection(repoId) {
  return firestoreWrapper.getSubCollection(
    firestoreWrapper.collectionNames.REPOSITORIES,
    repoId,
    firestoreWrapper.collectionNames.SLACK_INTEGRATIONS
  );
}

export async function getSlackChannelList({ repoId, workspaceId }) {
  try {
    const response = await CloudFunctions.getSlackChannelList({
      repoId,
      workspaceId,
    });

    if (response.data.status !== 'success') {
      logger.error(`Couldn't get slack channel list`);
    }

    return response.data.slackChannels;
  } catch (err) {
    logger.error({ err }, `Failed to call cloud function with error ${err}`);
  }
}

export async function changeSlackChannel({ repoId, workspaceId, channelId, channelName }) {
  try {
    const response = await CloudFunctions.changeSlackChannelToNotify({
      repoId,
      workspaceId,
      channelId,
      channelName,
    });

    if (response.data.status !== 'success') {
      logger.error(`Couldn't change Slack channel`);
      return false;
    }

    return true;
  } catch (err) {
    logger.error({ err }, `Failed to call cloud function with error ${err}`);
    return false;
  }
}

export async function doSlackIntegrationsExist(repoId: string): Promise<object> {
  try {
    const assignmentsCollectionSnapshot = await getSlackIntegrationsCollection(repoId);
    if (assignmentsCollectionSnapshot.code === config.SUCCESS_RETURN_CODE) {
      const result = {};
      assignmentsCollectionSnapshot.data.forEach(function (doc) {
        const docData = doc.data();
        result[docData.type] = true;
      });
      return result;
    }

    logger.error(
      `Failed to fetch Slack integrations for repoId=${repoId} with error: ${assignmentsCollectionSnapshot.errorMessage}`
    );
    return {};
  } catch (err) {
    logger.error({ err }, `Failed to fetch Slack integrations for repoId=${repoId} with error: ${err}`);
    return {};
  }
}

export async function changeSlackIntegration({ repoId, slackConfig, integrationType, addIntegration }) {
  try {
    if (addIntegration) {
      const integrationToAdd: SlackIntegrations = {
        conversation_id: slackConfig.conversation_id,
        conversation_name: slackConfig.conversation_name,
        team_id: slackConfig.team_id,
        team_name: slackConfig.team_name,
        type: integrationType,
      };
      await firestoreWrapper.addDocToSubCollection(
        firestoreWrapper.collectionNames.REPOSITORIES,
        repoId,
        firestoreWrapper.collectionNames.SLACK_INTEGRATIONS,
        integrationToAdd
      );
    } else {
      const integrationToDelete = await firestoreWrapper.getDocsRefFromSubCollectionWithWhereClause(
        firestoreWrapper.collectionNames.REPOSITORIES,
        repoId,
        firestoreWrapper.collectionNames.SLACK_INTEGRATIONS,
        ['conversation_id', '==', slackConfig.conversation_id]
      );
      integrationToDelete.forEach((doc) => doc.ref.delete());
    }

    return true;
  } catch (err) {
    logger.error({ err }, `Failed to update Slack integrations for repoId=${repoId} with error: ${err}`);
    return false;
  }
}

export async function connectUserToSlack({ userUid, slackTeamId, slackUserId }) {
  try {
    const response = await CloudFunctions.slackUserAuth({
      swimmUserId: userUid,
      slackTeamId,
      slackUserId,
    });

    if (response.data.status !== 'success') {
      logger.error(`Failed to connect Slack user to Swimm user=${userUid}`);
      return false;
    }

    return true;
  } catch (err) {
    logger.error({ err }, `Failed to connect Slack user to Swimm user=${userUid} with error: ${err}`);
    return false;
  }
}
