<template>
  <Multipane
    class="side-layout"
    layout="vertical"
    @mouseup="isResizing && endResizing()"
    :style="sideMenuWidthVariable"
  >
    <div
      ref="menu"
      class="side-menu"
      :style="sideMenuWidth"
      :class="{ 'side-menu-big': !isSmall, 'side-menu-small': isSmall, 'show-border': showSideMenuBorder, scrollable }"
    >
      <slot name="sideBar"></slot>
    </div>
    <MultipaneResizer class="resizer" name="side-menu-resizer" @mousedown="startResizing" :is-resizing="isResizing" />
    <div id="main" class="main">
      <slot name="content"></slot>
    </div>
  </Multipane>
</template>

<script>
import { debounce } from 'lodash-es';
import { state } from '@swimm/shared';
import Multipane from '@/common/components/Multipane/Multipane.vue';
import MultipaneResizer from '@/common/components/Multipane/MultipaneResizer.vue';

const DEFAULT_MENU_WIDTH = 300;

export default {
  components: {
    Multipane,
    MultipaneResizer,
  },
  props: {
    sideMenuScreenPercentage: { type: Number, default: 20 },
    sideMenuMaxWidth: { type: Number, default: null },
    showSideMenuBorder: { type: Boolean, default: false },
    saveWidth: { type: Boolean, default: true },
    scrollable: { type: Boolean, default: true },
    isSmall: { type: Boolean, default: false },
  },
  data() {
    return {
      menuWidth: DEFAULT_MENU_WIDTH,
      isResizing: false,
    };
  },
  computed: {
    sideMenuWidth() {
      return `width: ${this.menuWidth}px;`;
    },
    sideMenuWidthVariable() {
      return { '--sidemenu-width': `${this.menuWidth}px` };
    },
  },
  async mounted() {
    const menuWidth = await state.get({ key: 'side_menu_width' });
    if (this.saveWidth && menuWidth) {
      this.menuWidth = menuWidth;
    }
  },
  methods: {
    startResizing() {
      this.isResizing = true;
    },
    async endResizing() {
      if (this.saveWidth) {
        await state.set({ key: 'side_menu_width', value: this.$refs?.menu?.clientWidth ?? DEFAULT_MENU_WIDTH });
      }
      this.menuWidth = this.$refs?.menu?.clientWidth ?? DEFAULT_MENU_WIDTH;
      this.isResizing = false;
    },
    stopScroll() {
      this.showScrollBar = false;
    },
    startScroll() {
      this.showScrollBar = true;
      debounce(() => this.stopScroll(), 1000)();
    },
  },
};
</script>

<style scoped>
.side-layout {
  display: flex;
  overflow: hidden;
  height: 100vh;
}

.side-menu {
  position: relative;

  /* Forcing multipane resizer not to resize height */
  height: 100% !important;
  scroll-behavior: smooth;
  border-right: 2px solid var(--color-border-default-subtle);
}

.side-menu-big {
  min-width: 250px;
  max-width: 500px;
}

.side-menu-small {
  min-width: 250px;
  max-width: 350px;
}

.side-menu.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
}

.show-border {
  border-right: 2px solid var(--color-border-default-subtle);
  border-left: 2px solid var(--color-border-default-subtle);
}

.main {
  position: relative;
  overflow: auto;
  flex: 1;
}

.spaced-bottom .main {
  padding-bottom: 50px;
}

.resizer {
  height: initial;
  cursor: col-resize;
  z-index: var(--layer-dropdown);
}
</style>
