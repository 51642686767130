<script setup lang="ts">
import { computed, ref } from 'vue';
import { type TokenSuggestion } from '@swimm/shared';

import BaseButton from '../../components/BaseButton/BaseButton.vue';
import BaseKeyCommand from '../../components/BaseKeyCommand/BaseKeyCommand.vue';
import BaseLayoutGap from '../../components/BaseLayoutGap/BaseLayoutGap.vue';
import BaseProse from '../../components/BaseProse/BaseProse.vue';

import SwmInlineSelectionContent from '../SwmInlineSelectionContent/SwmInlineSelectionContent.vue';
import SwmSelectionContentTokenSimpleHeader from '../SwmSelectionContentTokenSimpleHeader/SwmSelectionContentTokenSimpleHeader.vue';
import SwmSelectionFooter from '../SwmSelectionFooter/SwmSelectionFooter.vue';
import MenuItemsToken from '../MenuItemsToken/MenuItemsToken.vue';
import type { ComponentExposed } from 'vue-component-type-helpers';
import type { CachedHighlighters } from '../..';

const props = withDefaults(
  defineProps<{
    query?: string;
    tokenSuggestions: TokenSuggestion[];
    command: (suggestion: TokenSuggestion) => boolean;
    loading?: boolean;
    noResults?: boolean;
  }>(),
  {
    query: '',
  }
);

const emit = defineEmits<{
  selectSuggestion: [suggestion: TokenSuggestion];
  focusedSuggestion: [suggestion: TokenSuggestion];
  hoveredSuggestion: [suggestion: TokenSuggestion];
  showAdvanced: [];
}>();

const selectionInlineContent = ref<ComponentExposed<typeof SwmInlineSelectionContent>>();
const cachedHighlighters = ref<CachedHighlighters>({});
const isCaching = ref(false);
const isCachingMore = ref(false);
const isLoading = computed(() => props.loading || isCaching.value);
const isLoadingMore = computed(() => !isLoading.value && !!props.tokenSuggestions.length && isCachingMore.value);
const onKeyDown = computed(() => selectionInlineContent.value?.onKeyDown);

function onSelectSuggestion(suggestion: TokenSuggestion) {
  emit('selectSuggestion', suggestion);
}

function onFocusedSuggestion(suggestion: TokenSuggestion) {
  emit('focusedSuggestion', suggestion);
}

function onHoveredSuggestion(suggestion: TokenSuggestion) {
  emit('hoveredSuggestion', suggestion);
}

function onShowAdvanced() {
  emit('showAdvanced');
}

function onCaching(caching: boolean) {
  isCaching.value = caching;
}

function onCachingMore(cachingMore: boolean) {
  isCachingMore.value = cachingMore;
}

function onHighlightersCreated(highlighters: CachedHighlighters) {
  cachedHighlighters.value = highlighters;
}

defineExpose({ onKeyDown });
</script>

<template>
  <SwmInlineSelectionContent
    ref="selectionInlineContent"
    data-testid="swm-selection-content-token-simple"
    :query="query"
    :items="tokenSuggestions"
    :command="command"
    :loading="isLoading"
    :loading-more="isLoadingMore"
    :no-results="noResults"
    @focused-item="onFocusedSuggestion"
  >
    <template #header>
      <SwmSelectionContentTokenSimpleHeader @advanced="onShowAdvanced" />
    </template>

    <template #prompt>
      <BaseProse size="small">Start typing your search term</BaseProse>
    </template>

    <template v-if="noResults" #no-results>
      <BaseLayoutGap direction="column" alignment="center">
        <BaseProse size="small">No match found. Know where your token is located?</BaseProse>
        <BaseButton variant="secondary" size="small" @click.stop="onShowAdvanced">Use advanced search</BaseButton>
      </BaseLayoutGap>
    </template>

    <template #items="{ setItemRef, paginatedItems, isFocused }">
      <MenuItemsToken
        :query="query"
        :token-suggestions="paginatedItems"
        :loading="loading"
        :is-focused="isFocused"
        :set-item-ref="setItemRef"
        @select-suggestion="onSelectSuggestion"
        @hovered-suggestion="onHoveredSuggestion"
        @caching="onCaching"
        @caching-more="onCachingMore"
        @highlighters-created="onHighlightersCreated"
      />
    </template>

    <template #footer>
      <slot name="preview" :cached-highlighters="cachedHighlighters" />

      <SwmSelectionFooter>
        <span>Navigate <BaseKeyCommand :keys="['↑']" /><BaseKeyCommand :keys="['↓']" /></span>
        <span>Accept <BaseKeyCommand :keys="['↵']" /></span>
        <span>Advanced search <BaseKeyCommand :keys="['Ctrl', 'Shift', 'K']" /></span>
        <span>Close <BaseKeyCommand :keys="['Escape']" /></span>
      </SwmSelectionFooter>
    </template>
  </SwmInlineSelectionContent>
</template>
../SwmInlineSelectionContent/SwmInlineSelectionContent.vue
