<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';

const props = defineProps<{
  modelValue?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  placeholder?: string;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: string];
}>();

const textarea = ref<HTMLTextAreaElement | null>(null);
const text = ref<HTMLElement | null>(null);
const value = ref(props.modelValue || '');

const computedTextClasses = computed(() => ({
  'inline-editable__text--editing': !props.disabled,
  'inline-editable__text--placeholder': !value.value,
}));

async function syncSize() {
  await nextTick();
  if (textarea.value && text.value) {
    let clientRect = text.value.getClientRects()?.item?.(0);
    if (clientRect && clientRect.width === 0) {
      // in Firefox, the width of the "fake" text element is 0 (I think since it is in absolute position)
      // and therefore, the height computation of the client rect is wrong
      // to fix this, we set the width of the text based on the width of the textarea
      text.value.style.width = textarea.value.clientWidth + 'px';
      clientRect = text.value.getClientRects()?.item?.(0);
    }
    const height = clientRect?.height || 0;
    textarea.value.style.height = height + 'px';
  }
}

function onKeyDown(event: KeyboardEvent) {
  // Prevent newlines in the textarea
  if (event.key === 'Enter') {
    event.preventDefault();
  }
}

async function onInput() {
  await syncSize();
  emit('update:modelValue', value.value);
}

onMounted(async () => {
  await nextTick();

  if (props.autoFocus && textarea.value) {
    textarea.value.focus();
  }

  await syncSize();
});

watch(
  () => props.modelValue,
  async (newValue) => {
    if (newValue) {
      if (newValue === value.value) {
        return;
      }

      value.value = newValue;
      await syncSize();
    }
  }
);

watch(
  () => value.value,
  async () => {
    await syncSize();
  }
);

defineExpose({
  focus() {
    if (textarea.value) {
      textarea.value.focus();
    }
  },
  select() {
    if (textarea.value) {
      textarea.value.select();
    }
  },
});
</script>

<template>
  <span class="inline-editable">
    <textarea
      v-if="!disabled"
      ref="textarea"
      v-model="value"
      class="inline-editable__input"
      :rows="1"
      :placeholder="placeholder"
      :aria-label="placeholder"
      data-testid="inline-editable-textarea"
      @input="onInput"
      @keyup="onInput"
      @keydown="onKeyDown"
    />
    <span
      ref="text"
      class="inline-editable__text"
      :class="computedTextClasses"
      data-testid="inline-editable-text"
      v-text="value || placeholder"
    />
  </span>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.inline-editable {
  position: relative;

  &__input {
    background: transparent;
    border: none;
    box-sizing: border-box;
    color: inherit;
    display: block;
    font: inherit;
    line-height: inherit;
    min-height: auto;
    min-width: 0;
    outline: none;
    overflow: hidden;
    padding: 0;
    resize: none;
    width: 100%;

    &::placeholder {
      color: var(--color-text-disabled);
      font: inherit;
    }
  }

  &__text {
    display: inline-block;
    overflow-wrap: break-word; // Ensure long words don't extend the span
    white-space: pre-wrap; // To mimic the wrapping of the textarea
    width: 100%;
    word-wrap: break-word; // Break words to mimic textarea

    &--editing {
      left: -9999px;
      position: absolute;
      top: 0;
      z-index: 0;
    }

    &--placeholder {
      color: var(--color-text-disabled);
    }
  }
}
</style>
