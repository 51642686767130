<template>
  <div class="users-popup-content" :class="{ 'users-popup-content-long': isLong }">
    <div
      v-for="user in users"
      :key="`user-${user.id}`"
      data-testid="user-line"
      class="user-line"
      :class="[lineClass(user)]"
    >
      <SwAvatar :text="userName(user)" />
      <span>&nbsp;</span>
      <span>{{ shortName(user) }}</span>
      <span>&nbsp;</span>
      <span class="user-title">{{ titleForUser(user) }}</span>
    </div>
  </div>
</template>

<script>
import { SwAvatar } from '@swimm/ui';
import { UNKNOWN_USER_NAME } from '@/common/consts';

const MAX_NAME_LENGTH = 17;
/**
 * Users is an array of objects, should already be sorted as you want to show them.
 * The objects must include a name property
 */
export default {
  components: {
    SwAvatar,
  },
  props: {
    users: { type: Array, required: true },
  },
  computed: {
    isLong() {
      return this.users.length > 5;
    },
  },
  methods: {
    lineClass(user) {
      return user.is_creator ? 'user-creator-line' : 'user-non-creator-line';
    },
    userName(user) {
      return user.name || UNKNOWN_USER_NAME;
    },
    shortName(user) {
      const n = this.userName(user);
      if (n.length > MAX_NAME_LENGTH) {
        return n.substring(0, MAX_NAME_LENGTH) + '...';
      }
      return n;
    },
    titleForUser(user) {
      if (user.is_creator) {
        return ' (creator)';
      }
      return '';
    },
  },
};
</script>

<style scoped>
.users-popup-content-long {
  overflow: auto;
  max-height: 200px;
}

.user-line {
  display: flex;
  align-items: center;
  padding: 8px 16px;
}

.user-creator-line {
  font-size: var(--body-S);
  line-height: 16px;
}

.user-non-creator-line {
  font-size: var(--body-L);
  line-height: 24px;
}

.user-title {
  color: var(--text-color-secondary);
}
</style>
