<script setup lang="ts">
import { useNotificationsStore } from './notifications';
import { Icon, Loader, SwText } from '@swimm/ui';

const props = defineProps({
  id: { type: String, required: true },
  text: { type: String, required: true },
  icon: { type: String, default: '' },
  link: { type: Object, default: null },
  image: { type: String, default: '' },
  loader: { type: Boolean, default: false },
  closeButtonText: { type: String, default: 'CLOSE' },
  onCloseClick: {
    type: Function,
    default: () => {
      return;
    },
  },
  actionButtonText: { type: String, default: '' },
  onActionClick: {
    type: Function,
    default: () => {
      return;
    },
  },
});

const store = useNotificationsStore();

function handleCloseClick(id: string) {
  store.removeNotification(id);
  props.onCloseClick();
}
</script>

<template>
  <div class="toast-notification">
    <div class="main-section">
      <Icon v-if="icon" :name="icon" class="icon" />
      <Loader v-else-if="loader" secondary class="loader" />
      <img v-if="image" :src="image" class="image" />
      <SwText v-if="text" component="span" variant="body-L" class="text"
        >{{ text }}
        <a v-if="link" target="_blank" class="link" :href="link.url"> &nbsp;{{ link.text }}</a>
      </SwText>
    </div>
    <div class="actions">
      <span @click="handleCloseClick(id)">{{ closeButtonText }}</span>
      <span v-if="actionButtonText" @click="onActionClick()">{{ actionButtonText }}</span>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.toast-notification {
  border: 1px solid var(--color-border-default);
  border-radius: 6px;
  padding: 16px;
  color: var(--text-color-invert);
  background: var(--color-invert);
  box-shadow: var(--box-shadow-small);
  margin: 8px 0;
  display: flex;
  align-items: center;
  font-size: var(--body-S);
  gap: 16px;

  .main-section {
    min-width: 240px;
    max-width: 380px;
    display: flex;
    align-items: center;
    flex: 1;
    gap: var(--space-sm);

    .icon {
      padding: 0;
      font-size: var(--system-headline);

      &.icon-warning {
        color: var(--text-color-error);
      }

      &.icon-check-fill {
        color: var(--text-color-success-strong);
      }
    }

    .image {
      width: 35px;
      height: 35px;
    }

    .text {
      max-width: 40vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .link {
      color: var(--text-color-link);
    }
  }

  .actions {
    font-weight: 800;
    font-size: var(--subtitle-L);
    cursor: pointer;
    display: flex;
    gap: var(--space-md);
    margin-right: var(--space-base);

    &:hover {
      color: var(--color-hover);
    }
  }

  .loader {
    --loader-size: var(--loader-size-small);
  }
}
</style>
