import { defineStore } from 'pinia';
import { ref } from 'vue';
import * as firestore from '@/adapters-common/firestore-wrapper';
import { config, eventLogger, getLoggerNew } from '@swimm/shared';
import { STORES } from '@/modules/core/stores/store-constants';

const logger = getLoggerNew(__modulename);

const eventCodeToRecentlyViewedType = {
  [eventLogger.SWIMM_EVENTS.DOC_VIEWED.code]: 'doc',
  [eventLogger.SWIMM_EVENTS.PLAYLIST_VIEWED.code]: 'playlist',
  [eventLogger.SWIMM_EVENTS.CLOUD_DOC_VIEWED.code]: 'doc',
};

export const useEventsStore = defineStore(STORES.EVENTS, () => {
  const recentlyViewedFiles = ref([]);
  const allRecentEvents = ref([]);

  const fetchViewedDocs = async ({ workspaceId, userId, codes }) => {
    const response = await firestore.getDocsRefWithWhereClauses(
      firestore.collectionNames.EVENT_LOGS,
      [
        ['workspaceId', '==', workspaceId],
        ['userId', '==', userId],
        ['swimmEventCode', 'in', codes],
      ],
      ['created', 'desc'],
      30
    );
    if (response.code === config.SUCCESS_RETURN_CODE) {
      const lastWeekDate = firestore.firestoreTimestampFromDate(new Date(Date.now() - 1000 * 60 * 60 * 24 * 7));
      const unfilteredEvents = response.data.docs.map((event) => {
        const data = event.data();
        return { type: eventCodeToRecentlyViewedType[data.swimmEventCode] ?? 'unknown', ...data };
      });
      const filteredEvents = [];
      for (const unfilteredEvent of unfilteredEvents) {
        if (
          !filteredEvents.some((event) => event.srcId === unfilteredEvent['srcId']) &&
          unfilteredEvent['created'] > lastWeekDate
        ) {
          filteredEvents.push(unfilteredEvent);
        }
      }
      recentlyViewedFiles.value = filteredEvents;
    } else {
      logger.debug(`Error getting viewed docs: ${response.errorMessage}`, { module: 'events' });
      // We do not throw here because there might be permission errors when it's not an admin
    }
  };

  const fetchAllRecentEvents = async ({ workspaceId }) => {
    const response = await firestore.getDocsRefWithWhereClauses(
      firestore.collectionNames.EVENT_LOGS,
      [['workspaceId', '==', workspaceId]],
      ['created', 'desc'],
      null
    );
    if (response.code === config.SUCCESS_RETURN_CODE) {
      const lastTwoWeekDate = firestore.firestoreTimestampFromDate(new Date(Date.now() - 1000 * 60 * 60 * 24 * 14));
      const unfilteredEvents = response.data.docs.map((event) => event.data());
      const filteredEvents = [];
      for (const unfilteredEvent of unfilteredEvents) {
        if (unfilteredEvent.created > lastTwoWeekDate) {
          filteredEvents.push(unfilteredEvent);
        }
      }
      allRecentEvents.value = filteredEvents;
    } else {
      logger.debug(`Error getting viewed docs: ${response.errorMessage}`, { module: 'events' });
      // We do not throw here because there might be permission errors when it's not an admin
    }
  };

  return {
    recentlyViewedFiles,
    allRecentEvents,
    fetchViewedDocs,
    fetchAllRecentEvents,
  };
});
