<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps<{ step: number }>();
const animate = ref(false);

watch(
  () => props.step,
  () => {
    setTimeout(() => {
      animate.value = false;
    }, 1800);
    animate.value = true;
  },
  { immediate: true }
);
</script>

<template>
  <svg id="duck" width="34" height="34" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="body"
      d="M19.6226 14.7295C14.6329 13.9627 13.1442 15.3336 11.8221 15.7815C10.5 16.2295 6.04847 16.7404 3.07513 13.7704L2.60899 13.2878C2.02479 12.6824 1 13.0957 1 13.9358V22.1818C1 28.5253 6.14827 33.6676 12.499 33.6676H19.6226C25.0679 33.6676 29.4781 29.2716 29.4781 23.8326C29.4781 19.232 26.4075 17.3151 26.4075 17.3151C26.4075 17.3151 24.6124 15.4963 19.6226 14.7295Z"
      fill="#FDF150"
    />
    <path
      id="wing"
      :class="{ animate }"
      d="M23.337 24.8048C23.337 20.5109 19.7547 19.9486 17.9635 20.2042C12.5899 20.971 2.2266 18.6706 6.06486 25.1882C10.9765 33.5285 23.337 30.1722 23.337 24.8048Z"
      fill="#E9D900"
    />
    <g id="head-group" :class="{ animate }">
      <path
        id="beak"
        d="M27.2208 13.4268C27.1854 13.9696 27.1011 14.5018 26.9734 15.0177L27.4285 14.9696C30.0258 14.6947 31.9086 12.3683 31.6335 9.77395C31.5941 9.40281 31.2607 9.13247 30.8879 9.17193L26.6397 9.62151L27.0056 11.1207C27.1895 11.8738 27.2707 12.6513 27.2207 13.4255L27.2208 13.4268Z"
        fill="#FFAF04"
      />
      <path
        id="head"
        d="M18.6531 4.29994C12.5181 4.94919 9.16964 11.8798 12.381 16.8757C14.2645 19.8024 17.9259 19.8573 20.8971 19.4779C24.6729 18.9958 27.649 17.2389 27.9391 13.028C28.3394 7.16194 24.5388 3.67706 18.6531 4.29994Z"
        fill="#FDF150"
      />
      <path
        id="eye"
        d="M23.8213 11.4189C24.388 11.3589 24.7942 10.8083 24.7285 10.1891C24.6628 9.56993 24.1502 9.11659 23.5835 9.17656C23.0168 9.23654 22.6106 9.78712 22.6763 10.4063C22.742 11.0255 23.2546 11.4789 23.8213 11.4189Z"
        fill="#161721"
      />
    </g>
  </svg>
</template>

<style scoped>
@keyframes headMoved {
  0% {
    transform: none;
  }

  50% {
    transform: rotate(-3deg) translateX(1px);
  }

  100% {
    transform: none;
  }
}

@keyframes wingMoved {
  0% {
    transform: none;
  }

  50% {
    transform: translateX(40%) translateY(-20%) rotate(30deg);
  }

  100% {
    transform: none;
  }
}

#wing.animate {
  animation: wingMoved 1s ease 2 forwards;
}

#head-group.animate {
  animation: headMoved 1s ease 2 forwards;
}
</style>
