import { type Editor, Extension } from '@tiptap/core';
import { pasteMarkdown, pastePlainText } from '@/services/pasteSpecialService';
import {
  type extensionsWithNestedContentType,
  getNodeDepth,
  getParentNodeForPosition,
  liftNestedListItems,
  nodeHasNestedContent,
} from '@/components/common/tiptapUtils';

function insertTab(editor: Editor) {
  const cursorPosition = editor.state.selection.$from.pos;
  editor
    .chain()
    .insertContentAt(cursorPosition, '\t')
    .focus(cursorPosition + 1)
    .run();
}

function undoTab(editor: Editor) {
  const cursorPosition = editor.state.selection.$from.pos;

  if (cursorPosition - 1 > 0 && editor.state.doc.cut(cursorPosition - 1, cursorPosition).textContent === '\t') {
    editor
      .chain()
      .deleteRange({ from: cursorPosition - 1, to: cursorPosition })
      .run();
  }
}

function shouldUseDefault(editor: Editor) {
  const useDefault = editor.isActive('tableItem') || editor.isActive('image');

  return useDefault;
}

export const CustomKeyboardShortcuts = Extension.create({
  name: 'CustomKeyboardShortcuts',
  addKeyboardShortcuts() {
    return {
      Tab: ({ editor }) => {
        if (!editor) {
          return false;
        }
        if (editor.isActive('orderedList') || editor.isActive('bulletList')) {
          editor.commands.sinkListItem('listItem');
          return true;
        }

        if (shouldUseDefault(editor)) {
          return false;
        }

        insertTab(editor);
        return true;
      },
      'Shift-Tab': ({ editor }) => {
        if (!editor) {
          return false;
        }

        if (shouldUseDefault(editor)) {
          return false;
        }
        undoTab(editor);
        return true;
      },
      Backspace: ({ editor }) => {
        if (editor.isActive('orderedList') || editor.isActive('bulletList')) {
          return liftNestedListItems(editor);
        }
        return false;
      },
      'Control-Alt-v': (state) => {
        state.editor.commands.pasteWithLoader(state.editor, pasteMarkdown, true);
        return true;
      },
      'Mod-Alt-v': (state) => {
        state.editor.commands.pasteWithLoader(state.editor, pasteMarkdown, true);
        return true;
      },
      'Control-Shift-v': (state) => {
        state.editor.commands.pasteWithLoader(state.editor, pastePlainText);
        return true;
      },
      'Mod-Shift-v': (state) => {
        state.editor.commands.pasteWithLoader(state.editor, pastePlainText);
        return true;
      },
      'Mod-a': ({ editor }) => {
        const position = editor.state.selection.from;
        const parentNode = getParentNodeForPosition(position, editor.state);
        if (parentNode) {
          const nodeParentName = parentNode.type.name;
          if (nodeHasNestedContent(nodeParentName)) {
            editor.commands.selectCellContent(getNodeDepth(nodeParentName as extensionsWithNestedContentType));
            return true;
          }
        }
        return false;
      },
    };
  },
});
