<script setup>
import RepoPullRequestTableHeader from '@/modules/repo/pull-requests/components/RepoPullRequestTableHeader.vue';
import ChangedDocumentationIcon from '@/modules/core/components/ChangedDocumentationIcon.vue';
import { Icon, SwText } from '@swimm/ui';
import { DocumentationTypes, PageRoutesNames } from '@/common/consts';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { ref } from 'vue';

const store = useStore();
const route = useRoute();

const props = defineProps({
  repoUrl: { type: String, required: true },
  headBranch: { type: String, required: true },
  baseBranch: { type: String, required: true },
  items: { type: Array, required: true },
  selectedPrNum: { type: Number, default: null },
});

const markPr = ref(false);
if (props.items[0]) {
  markPr.value = props.selectedPrNum === props.items[0].prId;
  if (markPr.value) {
    setTimeout(() => {
      markPr.value = false;
    }, 2000);
  }
}

function getRouteForItem({ type, id }) {
  switch (type) {
    case DocumentationTypes.PLAYLIST: {
      return { name: PageRoutesNames.PLAYLIST_VIEW, params: { branch: props.headBranch, playlistId: id } };
    }
    case DocumentationTypes.DOC: {
      return { name: PageRoutesNames.DOC_VIEW, params: { branch: props.headBranch, unitId: id } };
    }
  }
  return '';
}

function getIcon(type) {
  switch (type) {
    case DocumentationTypes.PLAYLIST: {
      return 'playlist';
    }
    case DocumentationTypes.DOC: {
      return 'doc';
    }
  }
  return '';
}

function clearNewBranchData() {
  if (props.headBranch !== route.params.branch) {
    store.dispatch('filesystem/cleanLoadedRepoData', route.params.repoId);
  }
}

function getTooltipForItem({ type, isAdded, isDelete }) {
  let tooltip = type === DocumentationTypes.PLAYLIST ? 'Playlist' : 'Doc';
  tooltip += ' was ';
  if (isAdded) {
    tooltip += 'added in this PR';
  } else if (isDelete) {
    tooltip += 'deleted in this PR';
  } else {
    tooltip += 'modified in this PR';
  }
  return tooltip;
}
</script>

<template>
  <div :class="{ wrapper: true, 'mark-pr': markPr }" data-testid="repo-pull-request-item">
    <RepoPullRequestTableHeader
      :repo-url="repoUrl"
      :pr-id="items[0].prId"
      :pr-title="items[0].prTitle"
      :base-branch="baseBranch"
      :head-branch="headBranch"
    />
    <div class="content">
      <table>
        <tr v-for="item in items" :key="item.id">
          <td>
            <component
              :is="item.isDelete ? 'span' : 'router-link'"
              :to="item.isDelete ? undefined : getRouteForItem({ type: item.type, id: item.id })"
              :class="['doc-name', { inactive: item.isDelete }]"
              v-tooltip="getTooltipForItem(item)"
              data-testid="pr-item-link"
              @click="clearNewBranchData"
            >
              <span class="cell-text">
                <Icon v-if="item.isDelete" :name="getIcon(item.type)" no-padding class="type-icon" />
                <ChangedDocumentationIcon
                  v-else
                  class="type-icon"
                  :type="item.type"
                  :state="item.isAdded ? 'added' : 'modified'"
                />
                <SwText variant="body-S" data-testid="pr-item-name">{{ item.name }}</SwText>
              </span>
            </component>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.wrapper {
  --table-border: 2px solid var(--color-border-default-subtle);
  border: var(--table-border);
  border-radius: 8px;
  margin-bottom: var(--space-md);
  overflow: hidden;

  .content {
    text-align: center;
    padding: var(--space-base) 0;

    table {
      width: 100%;
      border-collapse: collapse;

      th {
        text-transform: uppercase;
        color: var(--text-color-secondary);
        border-bottom: var(--table-border);
        padding: var(--space-base);

        &:first-of-type {
          text-align: left;
          width: 100%;
        }
      }

      tr:hover {
        background: var(--color-hover);
      }

      td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 0; /* magic - do not touch ;) https://stackoverflow.com/a/11877033/7363120 */
        padding: var(--space-base) var(--space-sm);
        vertical-align: middle;

        .doc-name {
          display: inline-block;

          &.inactive {
            opacity: 0.3;
            cursor: default;
            font-style: italic;
            text-decoration: line-through;
          }

          &:not(.inactive):hover {
            color: var(--color-brand-hover);
          }

          .cell-text {
            display: flex;
            align-items: center;
            gap: var(--space-xs);

            .type-icon {
              pointer-events: none; /* This prevent there being 2 tooltips - pass the hover event to the PR item */
              font-size: var(--headline2);
            }
          }
        }

        &:first-of-type {
          text-align: left;
        }
      }
    }
  }
}

.mark-pr {
  border: solid 2px var(--color-brand);
}
</style>
