<template>
  <TopMenuLayout>
    <template #content>
      <section class="container">
        <ResetPasswordForm v-if="mode === HandlerType.RESET_PASSWORD" :verification-code="verificationCode" />
      </section>
    </template>
  </TopMenuLayout>
</template>

<script>
import TopMenuLayout from '@/common/layouts/TopMenuLayout.vue';
import ResetPasswordForm from '@/common/components/Auth/ResetPasswordForm.vue';

const HandlerType = {
  RESET_PASSWORD: 'resetPassword',
};

export default {
  components: { TopMenuLayout, ResetPasswordForm },
  setup() {
    return { HandlerType };
  },
  data() {
    return {
      mode: HandlerType.RESET_PASSWORD,
      verificationCode: '',
    };
  },
  created() {
    this.mode = this.$route.query.mode;
    this.verificationCode = this.$route.query.oobCode;
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  flex-direction: column;
}
</style>
