import {
  loadProcessedDraftById as commonLoadProcessedDraftById,
  getSwmPath,
  loadAndProcessSWMFile,
} from '@/adapters-common/load_common';
import { PJSON_VERSION } from '@/config';
import type { LoadAndProcessSwmFileResult, LoadFileParameters, LoadFileResult } from '@/load-types';
import {
  ApplicabilityStatus,
  type SwmFile,
  config,
  convertSWMDtoSWM,
  convertSWMStructure,
  getLoggerNew,
  gitwrapper,
  objectUtils,
} from '@swimm/shared';
import { StatusCodes } from 'http-status-codes';

const logger = getLoggerNew(__modulename);

export async function loadProcessedDraftById({
  draftId,
  repoId,
  branch,
  type,
  workspaceId,
}): Promise<LoadAndProcessSwmFileResult> {
  return await commonLoadProcessedDraftById({ draftId, repoId, branch, type, workspaceId });
}

export async function load({
  swmId,
  shouldAutoSync = false,
  shouldGetApplicabilityStatus = true,
  repoId,
  type,
  currentBranch,
  workspaceId,
  path,
}: {
  swmId: string;
  shouldAutoSync?: boolean;
  shouldGetApplicabilityStatus?: boolean;
  repoId: string;
  type: (typeof config.SWIMM_FILE_TYPES)[keyof typeof config.SWIMM_FILE_TYPES];
  currentBranch: string;
  workspaceId: string;
  path: string;
}): Promise<{
  code: number;
  unit?: SwmFile;
  applicabilityStatus?: string;
  errorMessage?: string;
  originalSwmFile?: SwmFile;
  unitId: string;
  type: string;
}> {
  const filePath = getSwmPath({ path, swmId, type });
  return await loadFile({
    swmId,
    shouldAutoSync,
    shouldGetApplicabilityStatus,
    repoId,
    type,
    currentBranch,
    filePath,
    workspaceId,
  });
}

export async function loadFile({
  swmId,
  shouldAutoSync = false,
  shouldGetApplicabilityStatus = true,
  repoId,
  type,
  currentBranch,
  filePath,
  content,
  workspaceId,
}: LoadFileParameters): Promise<LoadFileResult> {
  let fileContent: string = content;
  try {
    if (!fileContent) {
      logger.debug(`Loading SWM ${swmId} from Git provider`, { service: 'load' });
      fileContent = await gitwrapper.getFileContentFromRevision({ filePath, repoId, revision: currentBranch });
    }

    const swmFile = getSWMdataFromRawContent({
      fileContent,
      repoId,
      type,
    });

    const originalSwmFile = objectUtils.deepClone(swmFile);
    if (!shouldAutoSync && !shouldGetApplicabilityStatus) {
      return {
        code: config.SUCCESS_RETURN_CODE,
        repoId,
        shouldAutoSync,
        unitId: swmId,
        unit: swmFile,
        originalSwmFile: originalSwmFile,
        type,
        path: filePath,
        fileContent,
      };
    }
    return {
      ...(await loadAndProcessSWMFile({
        swmFile,
        originalSwmFile,
        shouldAutoSync,
        repoId,
        unitId: swmId,
        currentBranch,
        workspaceId,
        path: filePath,
      })),
      type,
      fileContent,
    };
  } catch (err) {
    logger.error({ err }, `failed to load unit: ${swmId} on branch: ${currentBranch} Details: ${err.toString()}`);
    if (err.status === StatusCodes.NOT_FOUND || err.message.includes('Not Found')) {
      return {
        code: config.ERROR_RETURN_CODE,
        repoId,
        shouldAutoSync,
        unitId: swmId,
        applicabilityStatus: ApplicabilityStatus.Unavailable,
        errorMessage: `Could not load the file. Error details: ${err.toString()}`,
        type,
        path: filePath,
        fileContent,
      };
    }
    return {
      code: config.ERROR_RETURN_CODE,
      unitId: swmId,
      repoId,
      shouldAutoSync,
      applicabilityStatus: ApplicabilityStatus.Invalid,
      errorMessage: 'The requested file could not be parsed.',
      type,
      path: filePath,
      fileContent,
    };
  }
}

function getSWMdataFromRawContent({
  fileContent,
  repoId,
  type,
}: {
  fileContent: string;
  repoId: string;
  type: (typeof config.SWIMM_FILE_TYPES)[keyof typeof config.SWIMM_FILE_TYPES];
}): SwmFile {
  if (type === config.SWIMM_FILE_TYPES.PLAYLIST || type === config.SWIMM_FILE_TYPES.SWMD) {
    const swmResult = convertSWMDtoSWM(fileContent, repoId);
    if (swmResult.code !== config.SUCCESS_RETURN_CODE) {
      throw new Error(swmResult.errorMessage);
    }
    return swmResult.swm;
  } else {
    return convertSWMStructure(JSON.parse(fileContent), repoId, PJSON_VERSION);
  }
}
