<script setup lang="ts">
import { Action, Icon, SwText } from '@swimm/ui';
import { computed, onMounted, ref } from 'vue';
import BaseBackgroundLayout from '@/modules/onboarding/layouts/BaseBackgroundLayout.vue';
import { useRoute, useRouter } from 'vue-router';
import { useJoinWorkspace } from '@/modules/workspace/composables/joinWorkspace';
import {
  BackgroundTheme,
  ONBOARDING_VIDEOS_BASE_BUCKET_URL,
  OnboardingOrigin,
  ProductTourOrigin,
} from '@/modules/onboarding/consts';
import { PageRoutesNames } from '@/common/consts';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { IdeOptions, productEvents } from '@swimm/shared';
import InteractiveOnboardingContent from '@/modules/onboarding/components/InteractiveOnboardingContent.vue';

const { initializeJoinWorkspace, hasWorkspacesToJoin } = useJoinWorkspace();
onMounted(async () => {
  await initializeJoinWorkspace();
});

const videosOrigin = computed(() => {
  if (Object.values(IdeOptions).includes(route.query?.platform as string)) {
    return OnboardingOrigin.IDE;
  }
  return OnboardingOrigin.WEBAPP;
});

const stepIndex = ref<number>(0);

const analytics = useAnalytics();

const route = useRoute();
const router = useRouter();

function onSkipClick() {
  analytics.track(productEvents.ONBOARDING_SKIPPED, { Origin: 'Onboarding', Step: stepIndex.value + 1 });
  redirectToCreateWorkspace();
}

function onNextStep(step) {
  stepIndex.value = step;
}

function redirectToCreateWorkspace() {
  if (route.query.redirect) {
    router.push(route.query.redirect as string);
    return;
  }
  router.push({
    name: hasWorkspacesToJoin.value ? PageRoutesNames.JOIN_WORKSPACE : PageRoutesNames.CREATE_WORKSPACE,
    query: route.query,
  });
}
</script>
<template>
  <BaseBackgroundLayout :theme="BackgroundTheme.WAVE">
    <template #top-bar>
      <SwText variant="body-S" class="skip-section"
        >I’ll explore on my own.
        <Action class="skip-button" type="a" @click="onSkipClick" no-padding
          >Skip<Icon name="arrow-right" no-padding /></Action
      ></SwText>
    </template>
    <div class="container-onboarding-steps">
      <InteractiveOnboardingContent
        :step-index="stepIndex"
        :videos-source="`${ONBOARDING_VIDEOS_BASE_BUCKET_URL}/${videosOrigin}`"
        :context="ProductTourOrigin.ONBOARDING"
        @next-step="onNextStep"
        @next-screen="redirectToCreateWorkspace"
      />
    </div>
  </BaseBackgroundLayout>
</template>

<style scoped lang="postcss">
.skip-section .skip-button.button {
  cursor: pointer;
  color: var(--text-color-link);
}

.container-onboarding-steps {
  margin: auto;
  width: 55vw;
  max-width: 1440px;
}

@media screen and (max-width: 800px) {
  .container-onboarding-steps {
    width: 95vw;
  }
}
</style>
