import {
  AutosyncedStoreData,
  SmartElementWithApplicability,
  SmartSymbol,
  Snippet,
} from '../types/autosync/autosync-types';
import { SwmCellType } from '../types/swimm-patch-common';
import { SwmCell, SwmSymbols } from '../types/versions/swimm-types_2_0_0';
import { isSmartElementWithNewInfo } from './hunk-utils';
import { filterSwmCells } from './swm-utils';
import { uniqBy } from 'lodash-es';

function removeNullAndDedup(input: Array<{ repoId: string; path: string } | null>): { repoId: string; path: string }[] {
  return uniqBy(input.filter(Boolean), ({ repoId, path }) => `${repoId}###${path}`);
}

export function getFilesReferencedInAutosyncedDocument(
  autosyncedSmartElements: SmartElementWithApplicability<Snippet | SmartSymbol>[],
  includeOutdatedPaths = false
): { repoId: string; path: string }[] {
  const referencedFiles = autosyncedSmartElements.map((autosyncedSmartElement) => {
    // We do not want to reference text files
    if (autosyncedSmartElement.filePath.endsWith('.md') || autosyncedSmartElement.filePath.endsWith('.txt')) {
      return null;
    }

    if (!isSmartElementWithNewInfo(autosyncedSmartElement) || autosyncedSmartElement.newInfo.gitInfo == null) {
      if (includeOutdatedPaths && autosyncedSmartElement.gitInfo != null) {
        return {
          repoId: autosyncedSmartElement.gitInfo.repoId,
          path: autosyncedSmartElement.filePath,
        };
      }
      return null;
    }

    return {
      repoId: autosyncedSmartElement.newInfo.gitInfo.repoId,
      path: autosyncedSmartElement.newInfo.filePath,
    };
  });
  return removeNullAndDedup(referencedFiles);
}

// Please use this function only when we're missing autosync info.
export function getFilesReferencedInDocument(
  smartElements: (Snippet | SmartSymbol)[]
): { repoId: string; path: string }[] {
  const referencedFiles = smartElements.map((smartElement) => {
    if (smartElement.gitInfo == null) {
      return null;
    }
    return {
      repoId: smartElement.gitInfo.repoId,
      path: smartElement.filePath,
    };
  });
  return removeNullAndDedup(referencedFiles);
}

export function getAutosyncedSmartElementsFromSwm(
  swm: {
    content: SwmCell[]; // TODO: Get only snippet cells instead (to avoid calling on every editor change)?
    symbols?: SwmSymbols;
  },
  autosyncStoreData: AutosyncedStoreData
): SmartElementWithApplicability<Snippet | SmartSymbol>[] {
  const snippets = filterSwmCells(swm, SwmCellType.Snippet);
  const autosyncedSnippets = snippets
    .map((snippet) => autosyncStoreData.hunks[snippet.id])
    .filter((autosyncedSnippet) => autosyncedSnippet != null) as SmartElementWithApplicability<Snippet>[];
  const autosyncedSymbols = swm.symbols
    ? (Object.keys(swm.symbols)
        .map((symbolId) => autosyncStoreData.symbols[symbolId])
        .filter((autosyncedSymbol) => autosyncedSymbol != null) as SmartElementWithApplicability<SmartSymbol>[])
    : [];
  return [...autosyncedSnippets, ...autosyncedSymbols];
}
