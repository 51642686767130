import { useAsyncState } from '@vueuse/core';
import { onBeforeMount } from 'vue';

let shiki: typeof import('shiki') | null = null;

export function useShiki() {
  const { state, isReady, isLoading } = useAsyncState(async () => {
    return new Promise<typeof import('shiki')>((resolve) => {
      onBeforeMount(async () => {
        if (shiki != null) {
          resolve(shiki);
        }
        shiki = await import('shiki');
        shiki.setCDN(calculateCDN());

        resolve(shiki);
      });
    });
  }, null);

  return {
    shiki: state,
    shikiIsReady: isReady,
    shikiIsLoading: isLoading,
  };
}

function calculateCDN() {
  if (window.VSCODE || window.INTELLIJ) {
    return new URL(`shiki/`, document.baseURI).toString();
  }
  return `/shiki/`;
}
