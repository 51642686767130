import type {
  ContributorDbItemInterface,
  NotificationDbItemInterface,
  SayThanksOption,
  ThanksDbItemInterface,
} from '@swimm/shared';
import { NOTIFICATION_TYPES, config, defaultNotificationValues, getLoggerNew } from '@swimm/shared';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import * as _ from 'lodash-es';
import * as firestoreWrapper from '@/adapters-common/firestore-wrapper';

const logger = getLoggerNew(__modulename);

export const SAY_THANKS_OPTIONS: Array<SayThanksOption> = [
  {
    code: 'GREAT_DOC',
    name: '👍 Great doc',
    eventName: 'Great Doc',
    emoji: '👍',
    order: 10,
  },
  {
    code: 'TIME_SAVER',
    name: '⏳ Time saver',
    emoji: '⏳',
    eventName: 'Time Saver',
    order: 20,
  },
  {
    code: 'VALUABLE_INSIGHTS',
    name: '💡 Valuable insights',
    emoji: '💡',
    eventName: 'Valuable Insights',
    order: 30,
  },
];

const optionByCode = Object.fromEntries(SAY_THANKS_OPTIONS.map((o) => [o.code, o]));

/**
 * Calls the cloud function
 * return true if success
 */
export async function sayThanks({
  repoId,
  docId,
  userName,
  option,
}: {
  repoId: string;
  docId: string;
  userName: string;
  option: SayThanksOption;
}) {
  logger.info(`Saying thanks for repo=${repoId} docId=${docId} code=${option.code}`);
  try {
    const response = await CloudFunctions.sayDocThanks({ repoId, docId, userName, thanksCode: option.code });
    if (response.data.status === 'success') {
      return true;
    }
    throw new Error(response.data.code + ' ' + (response.data.error || ''));
  } catch (err) {
    logger.error({ err }, `Failed in sayThanks for ${repoId} ${docId} with ${err}`);
  }
  return false;
}

export function sortThanks(thanks: Array<ThanksDbItemInterface>) {
  return _.sortBy(thanks, (thank) => codeOrder(thank.code));
}

export function getEmoji(code: string) {
  return optionByCode[code]?.emoji ?? '👍';
}

function codeOrder(code) {
  return optionByCode[code]?.order ?? 100;
}

export async function createThanksNotification({
  docId,
  contributors,
  docTitle,
  docUrl,
  user,
}: {
  docId: string;
  contributors: ContributorDbItemInterface[];
  docTitle: string;
  docUrl: string;
  user: { uid: string; nickname: string };
}) {
  try {
    const templateData = {
      nickname: user.nickname,
      btn_url: docUrl,
      doc_name: docTitle,
    };

    for (const contributor of contributors) {
      if (contributor.user_id === user.uid) {
        continue;
      }

      const notification: NotificationDbItemInterface = {
        ...defaultNotificationValues,
        created_at: firestoreWrapper.firestoreTimestamp(),
        action_url: docUrl,
        notifier_id: user.uid,
        notifier_name: user.nickname,
        notifier_type: 'user',
        recipient_email: '',
        recipient_id: contributor.id,
        topic_id: docId,
        topic_name: docTitle,
        topic_type: 'doc',
        type: NOTIFICATION_TYPES.THANKS,
        reason: '',
        note: '',
        email_template_data: templateData,
      };
      const notificationRef = await firestoreWrapper.addDocToCollection(
        firestoreWrapper.collectionNames.NOTIFICATIONS,
        notification
      );
      if (notificationRef.code === config.SUCCESS_RETURN_CODE) {
        logger.debug(`Notification ${notificationRef.data.id} created`);
      }
    }
  } catch (err) {
    logger.error({ err }, `Failed to notify about thanks docId=${docId} with error ${err}`);
  }
}
