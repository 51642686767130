import type { ERROR_RETURN_CODE, SUCCESS_RETURN_CODE } from '../config';

/**
 * A type designed for functions that return the `code` parameter to indicate success or error / failure.
 *  This type uses TS generics so the usage is thus:
 *  * The `Success` generic type will be extended by a {code: SUCCESS} object.
 *  * If you need to return something in addition to {code: ERROR} on failure, pass it as the generic type `Error`.
 * */
export type ResultWithReturnCode<Success, Error = null> =
  // eslint-disable-next-line @typescript-eslint/ban-types
  | ({ code: typeof ERROR_RETURN_CODE } & (Error extends null ? {} : Error))
  | ({ code: typeof SUCCESS_RETURN_CODE } & Success);

/**
 * Both types copied from StackOverflow: https://stackoverflow.com/a/53229567
 *  Basically, they allow us to use XOR in our type system.
 */
export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
// eslint-disable-next-line @typescript-eslint/ban-types
export type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

/**
 * Remove the `readonly` modifier from each field of a type.
 */
export type Mutable<T> = {
  -readonly [key in keyof T]: T[key];
};

/**
 * Copied from StackOverflow: https://stackoverflow.com/a/49889856
 *  Basically, this type allows us to "unwrap" a `Promise<T>` type and get the `T` inside.
 *  This also works recursively: `UnwrapPromiseRecursive<Promise<Promise<Promise<T>>>>` will yield `T`.
 */
export type UnwrapPromiseRecursive<T> = T extends PromiseLike<infer U> ? UnwrapPromiseRecursive<U> : T;

export interface ErrorWithCustomerMessage extends Error {
  customerMessage: string;
}

/**
 * A file representing a resource used within a SWM file, e.g an image file.
 */
interface SwmResourceFileWithContent {
  path: string;
  content: string;
  isBase64Encoded: boolean;
}

export enum SwmResourceState {
  Created = 'created',
  Updated = 'updated',
  Renamed = 'renamed',
  Deleted = 'deleted',
}

interface CreatedSwmResourceFile extends SwmResourceFileWithContent {
  state: SwmResourceState.Created;
}

interface UpdatedSwmResourceFile extends SwmResourceFileWithContent {
  state: SwmResourceState.Updated;
}

export interface RenamedSwmResourceFile extends SwmResourceFileWithContent {
  state: SwmResourceState.Renamed;
  oldPath: string;
}

interface DeletedSwmResourceFile {
  state: SwmResourceState.Deleted;
  path: string;
}

export type SwmResourceFile =
  | CreatedSwmResourceFile
  | UpdatedSwmResourceFile
  | DeletedSwmResourceFile
  | RenamedSwmResourceFile;
