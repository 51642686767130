import Table, { type TableOptions } from '@tiptap/extension-table';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import TableNodeView from '../nodeViews/TableNodeView.vue';
import { type EditorState, Plugin, PluginKey, type Transaction } from '@tiptap/pm/state';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { changedDescendants } from '../utils';
import { TableMap, tableNodeTypes } from '@tiptap/pm/tables';

export const FIX_TABLE_HEADERS_PLUGIN_KEY = new PluginKey('fix-table-headers');

export interface ExtendedTableOptions extends TableOptions {
  keepMarks: boolean;
}

export default Table.extend<ExtendedTableOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      keepMarks: true,
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(TableNodeView);
  },

  addKeyboardShortcuts() {
    return {
      ...this.parent?.(),
      Enter: ({ editor }) => {
        return editor.commands.command(({ chain, editor, state }) => {
          const { selection, storedMarks } = state;

          if (!['tableHeader', 'tableCell'].includes(selection.$from.parent.type.name)) {
            return false;
          }

          const { keepMarks } = this.options;
          const { splittableMarks } = editor.extensionManager;
          const marks = storedMarks || (selection.$to.parentOffset && selection.$from.marks());

          return chain()
            .insertContent({ type: 'hardBreak' })
            .command(({ tr, dispatch }) => {
              if (dispatch && marks && keepMarks) {
                const filteredMarks = marks.filter((mark) => splittableMarks.includes(mark.type.name));

                tr.ensureMarks(filteredMarks);
              }

              return true;
            })
            .run();
        });
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      ...(this.parent?.() ?? []),
      new Plugin({
        key: FIX_TABLE_HEADERS_PLUGIN_KEY,
        appendTransaction(transactions, oldState, state) {
          return fixAllTablesHeaders(state, oldState);
        },
      }),
    ];
  },
});

/**
 * Inspect all tables in the given state's document and return a
 * transaction that fixes them, if necessary. If `oldState` was
 * provided, that is assumed to hold a previous, known-good state,
 * which will be used to avoid re-scanning unchanged parts of the
 * document.
 *
 * @see https://github.com/ProseMirror/prosemirror-tables/blob/b569c2f9f63cb27eed6ada0bfd51ff434a03213b/src/fixtables.ts#L58
 */
function fixAllTablesHeaders(state: EditorState, oldState?: EditorState): Transaction | undefined {
  let tr: Transaction | undefined;
  const check = (node: ProseMirrorNode, pos: number) => {
    if (node.type.spec.tableRole === 'table') {
      tr = fixTableHeaders(state, node, pos, tr);
    }
  };
  if (!oldState) {
    state.doc.descendants(check);
  } else if (oldState.doc !== state.doc) {
    changedDescendants(oldState.doc, state.doc, 0, check);
  }
  return tr;
}

/**
 * Fix the given table's headers, if necessary. Will append to the transaction
 * it was given, if non-null, or create a new one if necessary.
 *
 * @see https://github.com/ProseMirror/prosemirror-tables/blob/b569c2f9f63cb27eed6ada0bfd51ff434a03213b/src/fixtables.ts#L75
 */
export function fixTableHeaders(
  state: EditorState,
  table: ProseMirrorNode,
  tablePos: number,
  tr: Transaction | undefined
): Transaction | undefined {
  const map = TableMap.get(table);
  if (!tr) {
    tr = state.tr;
  }

  for (let col = 0; col < map.width; col++) {
    const cellPos = map.map[col];
    const cell = table.nodeAt(cellPos);
    if (!cell) {
      continue;
    }

    if (cell.type.spec.tableRole !== 'header_cell') {
      const nodeType = tableNodeTypes(state.schema)['header_cell'];

      tr.setNodeMarkup(tr.mapping.map(tablePos + 1 + cellPos), nodeType);
    }
  }

  for (let row = 1; row < map.height; row++) {
    for (let col = 0; col < map.width; col++) {
      const cellPos = map.map[row * map.width + col];
      const cell = table.nodeAt(cellPos);
      if (!cell) {
        continue;
      }

      if (cell.type.spec.tableRole !== 'cell') {
        const nodeType = tableNodeTypes(state.schema)['cell'];

        tr.setNodeMarkup(tr.mapping.map(tablePos + 1 + cellPos), nodeType);
      }
    }
  }

  return tr.setMeta(FIX_TABLE_HEADERS_PLUGIN_KEY, { fixTableHeaders: true });
}
