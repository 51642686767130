<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { fileUtils } from '@swimm/shared';
import HelpTooltip from '@/modules/core/components/HelpTooltip.vue';
import { getGitProviderIconName } from '@swimm/shared';

const props = defineProps({
  showRepoNames: { type: Boolean, required: true },
  repoId: { type: String, required: true },
  noAccess: { type: Boolean, required: true },
  files: { type: Array, required: true },
  showHelpTooltip: { type: Boolean, default: false },
  canRemove: { type: Boolean, default: false },
  isAuthorized: { type: Boolean, default: true },
});

const emit = defineEmits(['open-file', 'remove-file']);

const store = useStore();

const openFile = (file) => {
  emit('open-file', file);
};

const removeFile = (file) => {
  emit('remove-file', file);
};

const repoMetadata = computed(() => store.getters['database/db_getRepoMetadata'](props.repoId));

const repoIcon = computed(() => {
  if (props.noAccess) {
    return 'not-allowed';
  }
  return getGitProviderIconName(repoMetadata.value?.provider);
});
const repoName = computed(() => repoMetadata.value?.name || 'Unknown repo');

const fileTooltip = (file) => {
  if (!props.isAuthorized) {
    return '';
  }

  let prefix = '';
  if (props.noAccess) {
    prefix = 'The repo is not accessible - ';
  } else if (!file.exists) {
    prefix = 'The file no longer exists - ';
  }
  return `${prefix}${file.path}`;
};

const fileName = (path) => fileUtils.pathBasename(path);
const expanded = ref(true);

const arrowIconName = computed(() => (expanded.value ? 'expand-arrow' : 'collapse-arrow'));

const arrowIconTooltip = computed(() => (expanded.value ? 'Collapse' : 'Expand'));
</script>

<template>
  <SwText
    v-if="showRepoNames"
    :data-testid="`name-${repoId}`"
    class="repo-title clickable"
    @click="expanded = !expanded"
  >
    <Icon :name="repoIcon" />
    <VTooltip :disabled="!!repoMetadata">
      <SwText variant="subtitle-L">
        {{ repoName }}
      </SwText>
      <template #popper> This repo was removed from your workspace </template>
    </VTooltip>
    <Icon v-tooltip="arrowIconTooltip" class="clickable collapse-toggle" :name="arrowIconName" />
  </SwText>
  <template v-if="expanded">
    <div
      v-for="(file, index) in files"
      :key="file.path"
      class="file-link"
      data-testid="file-link"
      :class="{ 'can-open-file': file.exists, 'nested-file-name': showRepoNames }"
      @click="openFile(file)"
    >
      <HelpTooltip
        v-if="showHelpTooltip && index === 0"
        @click.prevent.stop
        placement="right"
        tooltip-key="pr-files-tips"
        title="PR file tips"
        class="hotspot"
      >
        <template #subtitle>
          We imported all of the changes from your PR. You can delete all snippets relevant to a specific file by
          clicking the trash can.
        </template>
      </HelpTooltip>
      <Icon class="headline3" name="file" />
      <div class="file-link-text text-ellipsis text-reverse-ellipsis" :data-testid="`file-link-text-${index}`">
        <span
          v-tooltip="{
            content: fileTooltip(file),
            classes: ['long-tooltip'],
          }"
          class="body-L text-reverse-ellipsis-inner data-hj-suppress"
          :class="{ 'file-outdated': !file.exists }"
          >{{ fileName(file.path) }}</span
        >
      </div>
      <div
        v-if="canRemove"
        v-tooltip="'By removing this file, all refrenrces to it will also be removed from this doc.'"
      >
        <Icon
          class="clickable delete-file"
          name="trash-outline"
          @click.stop="removeFile(file)"
          data-testid="remove-file-icon"
        />
      </div>
    </div>
  </template>
</template>

<style scoped lang="postcss">
.file-link {
  display: flex;
  align-items: center;
  line-height: 150%;
  margin-right: var(--space-md);
  max-width: 100%;

  .delete-file {
    margin-left: var(--space-xs);
    opacity: 0;
    transition: opacity 0.1s;

    .icon.icon-trash-outline {
      background: var(--color-hover);
      border-radius: 4px;
    }
  }

  &:hover {
    .delete-file {
      opacity: 1;
    }
  }
}

.file-link-text {
  flex-grow: 1;
  color: var(--text-color-link);
  background: var(--color-bg);
}

.can-open-file {
  cursor: pointer;
}

.nested-file-name {
  padding-left: var(--space-base);
}

.file-outdated {
  color: var(--text-color-secondary);
}

.hotspot {
  position: absolute;
  left: 8px;
}

.repo-title {
  display: flex;
  align-items: center;
}

.collapse-toggle {
  /* Needs to have smaller icon than the sizes we have */
  font-size: calc(0.8 * var(--body-XS));
  margin-top: var(--space-xs);
}
</style>
