export const sourceOf = (regex: RegExp | string) => (regex instanceof RegExp ? regex.source : regex);

// Consume all the whitespace before the word, and make sure it has a non-word character before it (or is at the beginning of the string).
// This way we can concatenate multiple words with this pattern and not have to worry about the whitespace between them.
export const preword = /(?:\s*(?<=\W|^))/.source;

export const embeddable = (regex: RegExp | string) => `(?:${sourceOf(regex)})`;

export const opt = (regex: RegExp | string) => `(?:${sourceOf(regex)})?`;

export const lazyOpt = (regex: RegExp | string) => `(?:${sourceOf(regex)})??`;

export const repeat = (regex: RegExp | string) => `(?:${sourceOf(regex)})*`;

export const lazyRepeat = (regex: RegExp | string) => `(?:${sourceOf(regex)})*?`;

export const repeatAtLeastOnce = (regex: RegExp | string) => `(?:${sourceOf(regex)})+`;

export const lazyRepeatAtLeastOnce = (regex: RegExp | string) => `(?:${sourceOf(regex)})+?`;

export const word = (regex: RegExp | string) => new RegExp(`(?:${preword}${embeddable(regex)})`).source;

export const or = (...regexes: (RegExp | string)[]) => `(?:${regexes.map((r) => sourceOf(r)).join('|')})`;

// Concat the given regexes, such that at least one of them must be present, and the rest are optional.
export const concatAtLeastOne = (
  // Since we embed each regex multiple times, we make them factories so that we don't get duplicate group names.
  ...regexes: (() => RegExp | string)[]
) =>
  or(
    ...regexes.map((mandatoryRegex, i) =>
      concat(mandatoryRegex(), ...regexes.slice(i + 1).map((optionalRegex) => opt(optionalRegex())))
    )
  );

export const concat = (...regexes: (RegExp | string)[]) => `(?:${regexes.map((r) => sourceOf(r)).join('')})`;

export const namedGroup = (name: string, regex: RegExp | string) => `(?<${name}>${sourceOf(regex)})`;

let nextGroupId = 0;

export const randomizeGroupName = (prefix: string) => `${prefix}${++nextGroupId}`;

export const quoteGroupNamePrefix = '__quote_';

export const optionalQuote = (regex: string | RegExp) => {
  const name = randomizeGroupName(quoteGroupNamePrefix);
  return new RegExp(`(?:\\s*(?<${name}>['"\`]?)${sourceOf(regex)}\\k<${name}>)`).source;
};
