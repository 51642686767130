import * as config from '../config';
import type { SwmFile } from '../types';

export function isTaskEmpty(swm: SwmFile): boolean {
  return !(swm.task && ((swm.task.dod && swm.task.dod.length > 0) || (swm.task.tests && swm.task.tests.length > 0)));
}

/**
 * Check if a provided swm file is actually a doc by its  type or its play mode.
 * This function is extremely important after the big "exercise / doc split" since we have some old rogue parameters in our DB that may be outdated.
 * In order to overcome them, we must always check wether a unit is really an exercise or a doc until we'll decide we stop supporting them.
 * @param swm - the swm file to assert
 * @param swimmTypeFromDb - the swimm type as stored in our database
 * @param playMode - the playmode of the swimm (previously stored as "walkthrough" for current docs)
 */
export function isSwmDoc({
  swm,
  swimmTypeFromDb,
  playMode,
}: {
  swm: SwmFile & { play_mode?: string; type?: string };
  swimmTypeFromDb?: string;
  playMode?: string;
}): boolean {
  const swimmPlayMode = playMode ? playMode : swm.play_mode;
  const swimmType = swimmTypeFromDb ? swimmTypeFromDb : swm.type;
  return swimmType === 'unit' && swimmPlayMode === config.UNIT_PLAY_MODES.WALKTHROUGH;
}

/**
 * Check if a provided swm file is actually an exercise by its task content, its type or its play mode.
 * This function is extremely important after the big "exercise / doc split" since we have some old rogue parameters in our DB that may be outdated.
 * In order to overcome them, we must always check wether a unit is really an exercise or a doc until we'll decide we stop supporting them.
 * @param swm - the swm file to assert
 * @param swimmTypeFromDb - the swimm type as stored in our database
 * @param playMode - the playmode of the swimm (previously stored as "hands-on" or "all" for current exercises)
 */
export function isSwmExercise({
  swm,
  swimmTypeFromDb,
  playMode,
}: {
  swm: SwmFile & { play_mode?: string; type?: string };
  swimmTypeFromDb?: string;
  playMode?: string;
}): boolean {
  const swimmPlayMode = playMode ? playMode : swm.play_mode;
  const swimmType = swimmTypeFromDb ? swimmTypeFromDb : swm.type;
  return (swimmType === 'unit' && swimmPlayMode !== config.UNIT_PLAY_MODES.WALKTHROUGH) || !isTaskEmpty(swm);
}
