<template>
  <div
    :class="classnames"
    :style="{ cursor, userSelect }"
    @mousedown="onMouseDown"
    @mouseup="$emit('mouseup')"
    ref="parent"
  >
    <slot></slot>
  </div>
</template>

<script>
import { clamp } from 'lodash-es';

const LAYOUT_HORIZONTAL = 'horizontal';
const LAYOUT_VERTICAL = 'vertical';

export default {
  props: {
    layout: {
      type: String,
      default: LAYOUT_VERTICAL,
    },
    clampSize: {
      type: Object,
      default: undefined,
    },
  },
  emits: ['mousedown', 'mouseup', 'paneResizeStart', 'paneResize', 'paneResizeStop'],
  data() {
    return {
      isResizing: false,
    };
  },
  computed: {
    classnames() {
      return ['multipane', 'layout-' + this.layout.slice(0, 1), this.isResizing ? 'is-resizing' : ''];
    },
    cursor() {
      return this.isResizing ? (this.layout === LAYOUT_VERTICAL ? 'col-resize' : 'row-resize') : '';
    },
    userSelect() {
      return this.isResizing ? 'none' : '';
    },
  },

  methods: {
    onMouseDown({ target: resizer, pageX: initialPageX, pageY: initialPageY }) {
      if (
        resizer.className &&
        resizer.className.match?.('multipane-resizer') &&
        resizer.parentNode === this.$refs.parent
      ) {
        const { $el: container, layout, clampSize } = this;

        const pane = resizer.previousElementSibling;
        const { offsetWidth: initialPaneWidth, offsetHeight: initialPaneHeight } = pane;

        const usePercentage = !!(pane.style.width + '').match('%');

        const { addEventListener, removeEventListener } = window;

        const resize = (initialSize, offset = 0) => {
          if (layout === LAYOUT_VERTICAL) {
            const containerWidth = container.clientWidth;
            const calculatedWidth = initialSize + offset;
            const paneWidth =
              clampSize && initialSize ? clamp(calculatedWidth, clampSize.min, clampSize.max) : calculatedWidth;

            return (pane.style.width = usePercentage ? (paneWidth / containerWidth) * 100 + '%' : paneWidth + 'px');
          }

          if (layout === LAYOUT_HORIZONTAL) {
            const containerHeight = container.clientHeight;
            const paneHeight = initialSize + offset;

            return (pane.style.height = usePercentage ? (paneHeight / containerHeight) * 100 + '%' : paneHeight + 'px');
          }
        };

        // This adds is-resizing class to container
        this.isResizing = true;

        // Resize once to get current computed size
        let size = resize();

        // Trigger paneResizeStart event
        this.$emit('paneResizeStart', pane, resizer, size);

        const onMouseMove = ({ pageX, pageY }) => {
          size =
            layout === LAYOUT_VERTICAL
              ? resize(initialPaneWidth, pageX - initialPageX)
              : resize(initialPaneHeight, pageY - initialPageY);

          this.$emit('paneResize', pane, resizer, size);
        };

        const onMouseUp = () => {
          // Run resize one more time to set computed width/height.
          size = layout === LAYOUT_VERTICAL ? resize(pane.clientWidth) : resize(pane.clientHeight);

          // This removes is-resizing class to container
          this.isResizing = false;

          removeEventListener('mousemove', onMouseMove);
          removeEventListener('mouseup', onMouseUp);

          this.$emit('paneResizeStop', pane, resizer, size);
        };

        addEventListener('mousemove', onMouseMove);
        addEventListener('mouseup', onMouseUp);
      }
    },
  },
};
</script>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="postcss">
.multipane {
  display: flex;

  &.layout-h {
    flex-direction: column;
  }

  &.layout-v {
    flex-direction: row;
  }
}

.multipane > div {
  position: relative;
}

.multipane-resizer {
  position: relative;
  display: block;
}

.layout-h > .multipane-resizer {
  top: 5px;
  margin-top: -10px;
  width: 100%;
  height: 10px;
  cursor: row-resize;
}

.layout-v > .multipane-resizer {
  --resizer-width: 8px;
  left: 0;
  /* We hang the resizer to the right so that it doesn't block the scrollbar of the left pane. */
  width: var(--resizer-width);
  /* We don't want there to be a gap between the panes - this makes the draggable resizer overlap the right pane and not
     push it to the right */
  margin-right: calc(-1 * var(--resizer-width));
  height: 100%;
  cursor: col-resize;
}
</style>
