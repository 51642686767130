export type MermaidLayoutType = 'side-by-side' | 'top-down';

export const MERMAID_MIN_IMAGE_WIDTH = 25;
export const MERMAID_MAX_IMAGE_WIDTH = 100;
export const MERMAID_DIAGRAMS_WITHOUT_TOKEN_SUPPORT = ['gitGraph'];

export type GenerateMermaidWithAiDebugData = object;
export type GenerateMermaidWithAiResult = {
  status: 'stop' | 'regenerate' | 'error' | 'success';
  debugData: GenerateMermaidWithAiDebugData;
};
