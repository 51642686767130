<script setup lang="ts">
import { Checkbox, Icon } from '@swimm/ui';
import { pluralize } from '../../../common/utils/helpers';
import { computed } from 'vue';

const props = defineProps({
  isAnySelected: { type: Boolean, default: false },
  isAllSelected: { type: Boolean, default: false },
  numSelectedItems: { type: Number, default: 0 },
  isDummyRepo: { type: Boolean, default: false },
});

const emit = defineEmits(['move-click', 'select-all-toggle', 'delete-click']);

const deleteTooltip = computed(() => {
  return props.isDummyRepo
    ? 'Cannot delete docs in the demo repo. Connect your own repo to create and organize your documentation.'
    : '';
});

function onDelete() {
  if (!props.isDummyRepo) {
    emit('delete-click');
  }
}
</script>

<template>
  <div class="selected-list-header">
    <div class="main-section">
      <Checkbox
        :class="{ visible: isAnySelected }"
        size="small"
        @update:model-value="$emit('select-all-toggle', isAnySelected, isAllSelected)"
        :model-value="isAnySelected"
        :indeterminate="isAnySelected && !isAllSelected"
      />
      <SwText variant="body-S" v-if="isAnySelected">
        {{ numSelectedItems }} {{ pluralize({ word: 'item', count: numSelectedItems }) }} selected</SwText
      >
    </div>
    <div class="divider" />
    <SwText variant="subtitle-L" class="batch-action" @click="$emit('move-click')">
      <Icon name="move" no-padding />Move to folder...
    </SwText>
    <div class="divider" />
    <SwText
      variant="subtitle-L"
      class="batch-action"
      :class="{ disabled: isDummyRepo }"
      @click="onDelete"
      v-tooltip="deleteTooltip"
    >
      <Icon name="discard" no-padding />Delete
    </SwText>
  </div>
</template>

<style scoped lang="postcss">
.selected-list-header {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  gap: var(--space-base);
  padding: var(--space-base);

  .main-section {
    display: flex;
    align-items: center;
    padding: var(--space-xs) 0;
    padding-right: var(--space-base);
  }

  .divider {
    background-color: var(--text-color-disable);
    width: 1px;
    align-self: stretch;
    margin: var(--space-xs) 0;
  }

  .batch-action {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    gap: var(--space-xs);
    padding: 0 var(--space-base);

    &:hover {
      background-color: var(--color-hover);
    }

    &.disabled {
      color: var(--text-color-disable);
    }
  }
}
</style>
