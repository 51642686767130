export enum Licenses {
  FREE = 'free',
  STANDARD = 'standard',
  PRO = 'pro',
  TEAM = 'team',
  TEAM10 = 'team10',
  SMALL_TEAM = 'small-teams',
  ENTERPRISE_STARTER = 'enterprise-starter',
  ENTERPRISE = 'enterprise',
}

export enum PLANS {
  FREE = 'FREE',
  STANDARD = 'STANDARD',
  PRO = 'PRO',
  TEAM = 'TEAM',
  TEAM10 = 'TEAM10',
  SMALL_TEAM = 'SMALL_TEAM',
  ENTERPRISE_STARTER = 'enterprise-starter',
  ENTERPRISE = 'ENTERPRISE',
}

export enum TieredFeatures {
  REPO = 'REPO',
  USER = 'USER',
}

// Custom Limits are values that are defined in the DB to bypass plan limit for specific workspace/customer
export const TieredFeaturesCustomLimits = {
  [TieredFeatures.REPO]: 'private_repos_limit',
  [TieredFeatures.USER]: 'users_limit',
};

export const LicenseToPlan = {
  [Licenses.FREE]: PLANS.FREE,
  [Licenses.STANDARD]: PLANS.STANDARD,
  [Licenses.TEAM]: PLANS.TEAM,
  [Licenses.TEAM10]: PLANS.TEAM10,
  [Licenses.SMALL_TEAM]: PLANS.SMALL_TEAM,
  [Licenses.PRO]: PLANS.PRO,
  [Licenses.ENTERPRISE]: PLANS.ENTERPRISE,
  [Licenses.ENTERPRISE_STARTER]: PLANS.ENTERPRISE_STARTER,
};

export const PlansLimits = {
  [PLANS.FREE]: {
    [TieredFeatures.REPO]: 1,
    [TieredFeatures.USER]: 5,
  },
  [PLANS.TEAM10]: {
    [TieredFeatures.REPO]: Infinity,
    [TieredFeatures.USER]: 10,
  },
  [PLANS.STANDARD]: {
    [TieredFeatures.REPO]: Infinity,
    [TieredFeatures.USER]: 25,
  },
  [PLANS.TEAM]: {
    [TieredFeatures.REPO]: Infinity,
    [TieredFeatures.USER]: 25,
  },
  [PLANS.SMALL_TEAM]: {
    [TieredFeatures.REPO]: Infinity,
    [TieredFeatures.USER]: 30,
  },
  [PLANS.PRO]: {
    [TieredFeatures.REPO]: Infinity,
    [TieredFeatures.USER]: Infinity,
  },
  [PLANS.ENTERPRISE]: {
    [TieredFeatures.REPO]: Infinity,
    [TieredFeatures.USER]: Infinity,
  },
  [PLANS.ENTERPRISE_STARTER]: {
    [TieredFeatures.REPO]: Infinity,
    [TieredFeatures.USER]: Infinity,
  },
};

export const PlansDescriptions = {
  [PLANS.FREE]: 'Start with a small project and try out Swimm with your team.',
  [PLANS.STANDARD]: 'For growing teams who want to collaborate on documentation.',
  [PLANS.PRO]: 'Scale collaboration across multiple teams and repositories.',
  [PLANS.ENTERPRISE]: 'AI-powered contextualized code understanding.',
  [PLANS.ENTERPRISE_STARTER]: 'AI-powered contextualized code understanding.',
  [PLANS.TEAM]: 'AI-powered contextualized code understanding.',
  [PLANS.TEAM10]: 'AI-powered contextualized code understanding.',
  [PLANS.SMALL_TEAM]: 'AI-powered contextualized code understanding.',
};

export const FREE_TRIAL_LENGTH = 14;
export const EXPIRING_TRIAL_LENGTH = 5;
