import type { JSONContent } from '@tiptap/core';
import { forAllSwimmNodes, getSwimmNodeId } from '@/swmd/swimm_node';
import {
  type Link,
  type Path,
  type SmartElement,
  type SmartElementWithApplicability,
  type Snippet,
  type Token,
} from '@swimm/shared';
import { Editor, Extension, type FocusPosition } from '@tiptap/core';
import type { SwimmEditorServices } from '../editorServices';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    swimmEditorServices: {
      applyAutosync: ((
        smartElement: SmartElementWithApplicability<SmartElement>,
        userRequested?: boolean
      ) => ReturnType) &
        ((smartElements: SmartElementWithApplicability<SmartElement>[], userRequested?: boolean) => ReturnType) &
        ((
          smartElements: Map<string, SmartElementWithApplicability<SmartElement>>,
          userRequested?: boolean
        ) => ReturnType);

      applyAllAutosync: (userRequested?: boolean) => ReturnType;

      deleteSwimmNode: (smartElement: SmartElementWithApplicability<SmartElement>) => ReturnType;

      deleteSwimmNodeByPath: (repoId: string, path: string) => ReturnType;

      focusAndCenter: (position?: FocusPosition) => ReturnType;

      addContentToDoc: (content: JSONContent[]) => ReturnType;

      setSourceExternal: () => ReturnType;
    };
  }
}

export interface SwimmOptions {
  swimmEditorServices?: SwimmEditorServices;
}

export interface SwimmStorage {
  editorServices: SwimmEditorServices;
}

export const SOURCE_EXTERNAL = 'external';

export default Extension.create<SwimmOptions, SwimmStorage>({
  name: 'swimm',

  onBeforeCreate() {
    if (this.options.swimmEditorServices == null) {
      throw new Error('swimmEditorServices not initialized');
    }
  },

  addOptions() {
    return {};
  },

  addStorage() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { editorServices: this.options.swimmEditorServices! };
  },

  addCommands() {
    return {
      applyAutosync:
        (smartElements, userRequested = true) =>
        ({ chain, state }) => {
          let smartElementsMap: Map<string, SmartElementWithApplicability<SmartElement>>;
          if (smartElements instanceof Map) {
            smartElementsMap = smartElements;
          } else if (Array.isArray(smartElements)) {
            smartElementsMap = new Map();
            for (const smartElement of smartElements) {
              smartElementsMap.set(smartElement.id, smartElement);
            }
          } else {
            smartElementsMap = new Map();
            smartElementsMap.set(smartElements.id, smartElements);
          }

          const ch = chain();
          forAllSwimmNodes(state.doc, (node, pos) => {
            const swimmId = getSwimmNodeId(node);
            const smartElement = smartElementsMap.get(swimmId);
            if (smartElement != null) {
              switch (node.type.name) {
                case 'swmSnippet':
                  ch.applySwmSnippetAutosync(
                    pos,
                    smartElement as SmartElementWithApplicability<Snippet>,
                    userRequested
                  );
                  break;
                case 'swmPath':
                  ch.applySwmPathAutosync(pos, smartElement as SmartElementWithApplicability<Path>, userRequested);
                  break;
                case 'swmLink':
                  ch.applySwmLinkAutosync(pos, smartElement as SmartElementWithApplicability<Link>, userRequested);
                  break;
                case 'swmToken':
                  ch.applySwmTokenAutosync(pos, smartElement as SmartElementWithApplicability<Token>, userRequested);
                  break;
              }
            }

            return true;
          });

          ch.setSourceExternal().run();

          return true;
        },

      applyAllAutosync:
        (userRequested = true) =>
        ({ commands, editor }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);
          return commands.applyAutosync(swimmEditorServices.autosyncOutput.value.smartElements, userRequested);
        },

      deleteSwimmNode:
        (smartElement) =>
        ({ chain, state, tr }) => {
          const ch = chain();
          forAllSwimmNodes(state.doc, (node, pos) => {
            const swimmId = getSwimmNodeId(node);
            if (smartElement.id !== swimmId) {
              return true;
            }

            switch (node.type.name) {
              case 'swmPath':
                ch.convertSwmPathToCode(tr.mapping.map(pos));
                break;
              case 'swmToken':
                ch.convertSwmTokenToCode(tr.mapping.map(pos));
                break;
              case 'swmSnippet':
                ch.deleteRange({ from: tr.mapping.map(pos), to: tr.mapping.map(pos) + node.nodeSize });
                break;
              case 'swmLink':
                ch.deleteRange({ from: tr.mapping.map(pos), to: tr.mapping.map(pos) + node.nodeSize });
                break;
            }

            return true;
          });

          ch.run();

          return true;
        },

      deleteSwimmNodeByPath:
        (repoId, path) =>
        ({ chain, state, tr }) => {
          const ch = chain();
          forAllSwimmNodes(state.doc, (node, pos) => {
            switch (node.type.name) {
              case 'swmPath':
                if (node.attrs.href === path && node.attrs.repoId === repoId) {
                  ch.convertSwmPathToCode(tr.mapping.map(pos));
                }
                break;
              case 'swmToken':
                if (node.attrs.path === path && node.attrs.repoId === repoId) {
                  ch.convertSwmTokenToCode(tr.mapping.map(pos));
                }
                break;
              case 'swmSnippet':
                if (node.attrs.path === path && node.attrs.repoId === repoId) {
                  ch.deleteRange({ from: tr.mapping.map(pos), to: tr.mapping.map(pos) + node.nodeSize });
                }
                break;
            }

            return true;
          });

          ch.run();

          return true;
        },

      focusAndCenter:
        (position?) =>
        ({ commands, dispatch, view, tr }) => {
          commands.focus(position);

          if (dispatch) {
            requestAnimationFrame(() => {
              const dom = view.domAtPos(tr.selection.from);
              if (dom.node.nodeType === Node.ELEMENT_NODE) {
                (dom.node as Element).scrollIntoView({ block: 'center', behavior: 'smooth' });
              }
            });
          }

          return true;
        },

      addContentToDoc:
        (content) =>
        ({ chain, tr }) => {
          const ch = chain();
          for (const contentItem of content) {
            if (contentItem.content) {
              ch.insertContentAt(tr.doc.content.size, contentItem.content);
            }
          }

          ch.run();

          return true;
        },

      setSourceExternal:
        () =>
        ({ tr }) => {
          tr.setMeta(SOURCE_EXTERNAL, true);
          return true;
        },
    };
  },
});

export function getSwimmEditorServices(editor: Editor): SwimmEditorServices {
  return editor.storage.swimm.editorServices;
}
