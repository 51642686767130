/**
 * The prefixes in the `ALLOWED_PREFIXES` array are joined by tiptap's suggestion class and tested against the input
 * The final regex looks something like:
 *
 *     /^[ .,?!:;=\-+~$%#^&\(\)\{\}\[\]\|\`\_]?$/
 *
 * From `tiptap/suggestion` src:
 * > JavaScript doesn't have lookbehinds. This hacks a check that first character
 * > is a space or the start of the line
 * > ```js
 * > const matchPrefix = match.input.slice(Math.max(0, match.index - 1), match.index)
 * > const matchPrefixIsAllowed = new RegExp(`^[${allowedPrefixes?.join('')}\0]?$`).test(matchPrefix)
 * > ```
 * >
 * > NOTE: Double escaping (\\) is required for it to apply in the regex match
 */
export const ALLOWED_PREFIXES = [
  '\n',
  ' ',
  '.',
  ',',
  '?',
  '!',
  ':',
  ';',
  '=',
  '\\-',
  '+',
  '~',
  '$',
  '%',
  '#',
  '^',
  '&',
  '\\(',
  '\\)',
  '\\{',
  '\\}',
  '\\[',
  '\\]',
  '\\|',
  '\\',
  '\\_',
];
