<template>
  <SwModalWrapper
    :show="showModal"
    :blur-background="blurBackground"
    :align-to-top="alignToTop"
    :close-on-backdrop-click="closeOnBackdropClick"
    @close="closeModal"
  >
    <div :class="['modal-inner', { bordered: showBorder }]" ref="modal" tabindex="0" v-bind="$attrs">
      <template v-if="heading || $slots.header">
        <header :class="['header', { 'header-border': headerBorder, [headerTheme]: headerTheme }]" data-testid="header">
          <slot name="header">
            <span class="title" data-testid="title" :class="{ 'data-hj-suppress': suppressHeading }">{{
              heading
            }}</span>
          </slot>
          <IconButton v-if="showCloseButton" name="close" data-testid="close" @click="closeModal" />
        </header>
      </template>
      <div :class="['content', { padded }]">
        <slot />
      </div>
    </div>
  </SwModalWrapper>
</template>

<script setup lang="ts">
import { type Ref, nextTick, ref, watch } from 'vue';

import SwModalWrapper from './SwModalWrapper.vue';
import IconButton from './IconButton.vue';

const props = defineProps({
  heading: { type: String, default: '' },
  showModal: { type: Boolean, required: true },
  padded: { type: Boolean, default: true },
  headerBorder: { type: Boolean, default: true },
  blurBackground: { type: Boolean, default: false },
  alignToTop: { type: Boolean, default: false },
  closeOnBackdropClick: { type: Boolean, default: true },
  showCloseButton: { type: Boolean, default: true },
  showBorder: { type: Boolean, default: false },
  headerTheme: { type: String, default: null },
  suppressHeading: { type: Boolean, default: false },
});
const emit = defineEmits(['close']);

const modal: Ref<HTMLDivElement | null> = ref(null);

watch(
  () => props.showModal,
  () => {
    nextTick(() => {
      if (props.showModal && modal.value) {
        modal.value.focus();
      }
    });
  },
  { immediate: true }
);

function closeModal() {
  emit('close', false);
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style scoped lang="postcss">
.modal-inner {
  z-index: 10000;
  width: auto;
  border-radius: 8px;
  background: var(--color-bg);
  box-shadow: var(--box-shadow-big);
  border: 2px solid var(--color-border-default-subtle);
  margin: 5%;
  overflow: auto;

  &:focus {
    outline: none;
  }

  &.bordered {
    border: 1px solid var(--color-border-default);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 24px;

  &.help-modal {
    background: var(--swimm-shades);
    color: var(--text-color-on-dark);
    border-radius: 8px 8px 0 0;
  }
}

.header:not(.header-border) {
  padding-bottom: 0;
}

.header-border {
  border-bottom: 1px solid var(--color-border-default);
}

.content {
  display: flex;
  width: auto;
  max-height: 80vh;
  box-sizing: border-box;
  word-break: break-word;
  overflow-x: hidden;
  overflow-y: visible; /* If content exceeds max-height of container we need to scroll */
}

.content.padded {
  padding: 24px;
}

.title {
  overflow: hidden;
  flex-grow: 1;
  font-size: var(--headline3);
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
