<template>
  <SwModal :show-modal="show" :heading="header" :padded="false" blur-background @close="onClose">
    <div class="content">
      <img v-if="steps[currentStep].image" class="banner" :src="getImageUrl(steps[currentStep].image)" />
      <iframe
        v-else-if="steps[currentStep].videoProvider === 'youtube'"
        title="YouTube video player"
        :src="`https://www.youtube.com/embed/${steps[currentStep].videoId}`"
        width="640"
        height="360"
        frameborder="0"
        allowfullscreen
      ></iframe>
      <iframe
        v-else-if="steps[currentStep].videoProvider === 'vimeo'"
        title="vimeo-player"
        :src="`https://player.vimeo.com/video/${steps[currentStep].videoId}`"
        width="640"
        height="360"
        frameborder="0"
        allowfullscreen
      ></iframe>
      <div class="step-details">
        <div class="title-wrapper">
          <h2 class="step-title">{{ steps[currentStep].title }}</h2>
          <slot name="title" :current-step="steps[currentStep]" />
        </div>
        <p :key="steps[currentStep].title" class="step-description">
          {{ steps[currentStep].description }}
          <a v-if="steps[currentStep].learnMore" :href="steps[currentStep].learnMore" target="_blank" class="link"
            >Learn more</a
          >
        </p>
        <div class="footer">
          <StepProgressDots v-if="steps.length > 1" :current="currentStep" :total="steps.length" @click="onStepClick" />
          <div class="buttons">
            <Action
              v-if="showBackButton"
              secondary
              size="small"
              button-type="button"
              class="navigation-button"
              @click="onPrevStep"
            >
              Back
            </Action>
            <Action size="small" button-type="button" @click="onNextStep">{{ nextButtonText }}</Action>
          </div>
        </div>
      </div>
    </div>
  </SwModal>
</template>

<script>
import StepProgressDots from '@/common/components/Workspace/Modals/StepProgressDots.vue';
import { Action, SwModal } from '@swimm/ui';

export default {
  components: { SwModal, StepProgressDots, Action },
  emits: ['close'],
  props: {
    show: { type: Boolean, required: true },
    header: { type: String, required: true },
    steps: {
      type: Array,
      required: true,
      validator: (value) => {
        return (
          value
            // eslint-disable-next-line no-prototype-builtins
            .map((item) => item.hasOwnProperty('title') && item.hasOwnProperty('description'))
            .every((value) => value)
        );
      },
    },
  },
  data() {
    return {
      currentStep: 0,
    };
  },
  computed: {
    showBackButton() {
      return this.currentStep > 0;
    },
    nextButtonText() {
      if (this.isLastStep) {
        return 'Jump in!';
      }
      return 'Next';
    },
    isLastStep() {
      return this.currentStep === this.steps.length - 1;
    },
  },
  methods: {
    onNextStep() {
      if (this.isLastStep) {
        return this.onClose();
      }
      this.currentStep = this.currentStep + 1;
    },
    onPrevStep() {
      this.currentStep = this.currentStep - 1;
    },
    onClose() {
      const lastStep = this.currentStep;
      this.currentStep = 0;
      this.$emit('close', { lastStep });
    },
    onStepClick(step) {
      this.currentStep = step;
    },
    getImageUrl(image) {
      return new URL(`./assets/${image}`, import.meta.url).href;
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
}

.step-details {
  padding: 24px;
  max-width: 676px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.banner {
  height: 360px;
}

.step-title {
  display: inline;
  margin-right: 8px;
  font-size: var(--headline3);
  font-weight: 700;
}

.step-description {
  margin-top: 8px;
  margin-bottom: 24px;
  height: 60px;
  white-space: pre-wrap;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation-button {
  margin-right: 8px;
}

.title-wrapper {
  display: inline;
  align-items: center;
}

.link {
  color: var(--text-color-link);
}
</style>
