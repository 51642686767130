<script setup lang="ts">
import BaseLayoutIcons from '../../components/BaseLayoutIcons/BaseLayoutIcons.vue';
import BaseLayoutGap from '../../components/BaseLayoutGap/BaseLayoutGap.vue';
import { computed, useSlots } from 'vue';

withDefaults(
  defineProps<{
    wrapper?: string;
  }>(),
  {
    wrapper: 'li',
  }
);

const slots = useSlots();

const hasLeftIcon = computed(() => !!slots.leftIcon);
</script>

<template>
  <component :is="wrapper" class="list-item">
    <BaseLayoutGap wrapper="span" direction="row" size="small" alignment="stretch">
      <BaseLayoutIcons class="list-item__wrapper">
        <template v-if="hasLeftIcon" #leftIcon><slot name="leftIcon" /></template>
        <template #default>
          <slot />
        </template>
      </BaseLayoutIcons>
      <slot name="additional" />
    </BaseLayoutGap>
  </component>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.list-item {
  @include defaults;

  color: var(--color-text-default);
  border-radius: var(--space-xxsmall);
  font-size: var(--font-size-small);
  list-style: none;
  padding: var(--space-xxsmall) 0;
  white-space: nowrap;

  &__wrapper {
    align-items: center;
  }
}
</style>
