<script setup lang="ts">
import InstanceNotification from '@/modules/notifications-center/components/InstanceNotification.vue';
import { computed } from 'vue';

const props = defineProps({
  notification: { type: Object, required: true },
});

const action = computed(() => props.notification.topic_name);
</script>

<template>
  <InstanceNotification
    :action="action"
    title="is the first to read your doc: "
    :add-notifier-to-title="true"
    :notification="notification"
  />
</template>
