import { computed, inject } from 'vue';

export function useShouldShowAnchor() {
  const isTempSwmId = inject('isTempSwmId', false);
  const isPlaylist = inject('isPlaylist', false);
  const isPreview = inject('isPreview', false);
  const shouldAllowSectionIdCopying = computed(() => {
    if (isPlaylist) {
      return false;
    }
    if (isPreview) {
      return false;
    }
    if (!isTempSwmId) {
      return false;
    }
    return true;
  });
  return { shouldAllowSectionIdCopying };
}
