import { DocumentationTypes } from '@/common/consts';
import type { DbFolder } from '@swimm/shared';
import { FolderItemType } from '@swimm/shared';

export interface Folder extends DbFolder {
  id: string;
}

export const DocumentationTypeToFolderItemType = {
  [DocumentationTypes.FOLDER]: FolderItemType.FOLDER,
  [DocumentationTypes.DOC]: FolderItemType.DOC,
  [DocumentationTypes.PLAYLIST]: FolderItemType.PLAYLIST,
} as const;

export const FolderItemTypeToEntityType = {
  [FolderItemType.DOC]: 'Doc',
  [FolderItemType.PLAYLIST]: 'Playlist',
  [FolderItemType.FOLDER]: 'Folder',
};

export interface TreeNode {
  id: string;
  name: string;
  children: TreeNode[];
  type: string;
  path: string;
}

export interface ToggleableTreeNode extends TreeNode {
  children: ToggleableTreeNode[];
  closed: boolean;
}
