import type { JSONContent } from '@tiptap/core';
import { Fragment as ProseMirrorFragment, Node as ProseMirrorNode } from '@tiptap/pm/model';
import { forAllSwimmNodes, parseSwmdContent, schema } from '..';
import {
  type SnippetInfo,
  concat,
  exhaustiveTypeException,
  lazyOpt,
  lazyRepeat,
  lazyRepeatAtLeastOnce,
  namedGroup,
  opt,
  or,
  repeat,
  splitLongSnippets,
} from '@swimm/shared';
import { Transform } from '@tiptap/pm/transform';

const SNIPPET_DELIMITER = '~~~';
const INNER_SNIPPET_DELIMITER = ':::';

export const swmdSnippetsToText = (swimmDocumentContent: JSONContent) => {
  const rootNode = ProseMirrorNode.fromJSON(schema, swimmDocumentContent);
  const snippets: string[] = [];
  forAllSwimmNodes(rootNode, (node: ProseMirrorNode, pos: number) => {
    if (node.type.name === 'swmSnippet') {
      snippets.push(
        `${SNIPPET_DELIMITER}${pos}${SNIPPET_DELIMITER} \n ${node.attrs.path.substr(1)} \n ${node.attrs.snippet
          .split('\n')
          .filter((line: string) => !!line)
          .join('\n')}`
      );
    }
    return true;
  });
  return snippets.join('\n\n');
};

export function splitSnippets(swimmDocumentContent: JSONContent, repoId: string): JSONContent {
  const rootNode = ProseMirrorNode.fromJSON(schema, swimmDocumentContent);
  const snippets: SnippetInfo[] = [];
  forAllSwimmNodes(rootNode, (node: ProseMirrorNode) => {
    if (node.type.name === 'swmSnippet') {
      snippets.push({
        filePath: node.attrs.path,
        content: node.attrs.snippet,
        startLine: node.attrs.line,
        endLine: node.attrs.line + node.attrs.snippet.split('\n').length,
      });
    }
    return true;
  });
  const splitedSnippets = splitLongSnippets(snippets);
  return buildSwimmDocumentFromSnippets(splitedSnippets, repoId);
}

export function buildSwimmDocumentFromSnippets(snippets: SnippetInfo[], repoId: string): JSONContent {
  const document = schema.topNodeType.create();
  const tr = new Transform(document);
  for (const snippet of snippets) {
    const node = schema.node(
      'swmSnippet',
      {
        snippet: snippet.content,
        path: snippet.filePath,
        line: snippet.startLine,
        repoId,
      },
      [schema.node('paragraph')]
    );
    tr.insert(tr.doc.content.size, node);
  }
  return tr.doc.toJSON();
}

// ~~~<number>~~~
const snippetPlaceholder = concat(SNIPPET_DELIMITER, namedGroup('snippetNumber', /[0-9]+/), SNIPPET_DELIMITER);

// ~~~<name>:::<file-path>:::<number>~~~
const snippetPlaceholderWithInnerDelimiter = concat(
  SNIPPET_DELIMITER,
  namedGroup('snippetFilePath', /.+?/),
  INNER_SNIPPET_DELIMITER,
  namedGroup('snippetName', /.+/),
  INNER_SNIPPET_DELIMITER,
  namedGroup('snippetNumber', /[0-9]+/),
  SNIPPET_DELIMITER
);

const SNIPPET_REGEXES = {
  snippetPlaceholder,
  snippetPlaceholderWithInnerDelimiter,
};

type SnippetRegexKind = keyof typeof SNIPPET_REGEXES;

// Any line that isn't empty and doesn't start with a #.
const nonHeadingParagraphLine = /^[^#\n].*\n/;

const textUpToOneParagraph = or(
  // If we have multiple placeholders in the same line:
  // ~~~1~~~ [text here] ~~~2~~~
  // Then we try to consume these non-newline characters here.
  lazyRepeatAtLeastOnce(/[^\n]/),
  // Otherwise, we handle the case where there are multiple lines between the last snippet placeholder and the next
  // one.
  // For example:
  // ~~~1~~~ text A
  // text B
  // text B
  // text C ~~~2~~~
  concat(
    // This captures text A in the example above (up until the newline character)
    opt(/[^#\n]*?\n/),
    // This captures text B in the example above (complete lines that are not headings or empty).
    lazyRepeat(nonHeadingParagraphLine),
    // This captures text C in the example above (text before the snippet placeholder on the same line).
    opt(/^[^#\n].*?/)
  )
);

const anyLineOfText = /.*\n/;

const textUpToSnippetPlaceholder = (snippetRegexType: SnippetRegexKind) =>
  concat(
    // This is lazy since we first want to try to match the line as a snippet comment/snippet placeholder, and only if it
    // fails consider it as a previous line.
    lazyOpt(
      namedGroup(
        'previousLines',
        // Lazy repeat since otherwise it would 'eat up' the snippet comment lines as well.
        lazyRepeat(anyLineOfText)
      )
    ),
    // Discard empty lines between the previous lines and the snippet comment lines.
    repeat(/\n/),
    // This is lazy since we first want to try to match the line as a snippet placeholder, and only if it fails then
    // consider it as a snippet comment.
    lazyOpt(namedGroup('snippetCommentLines', textUpToOneParagraph)),
    // Completion often contains some text, followed by 2 empty lines before the actual snippet placeholder.
    // e.g. ([] marks this regex's match)
    // Here we handle the authentication[
    //
    // ]~~~111~~~
    opt(/\n\n/),
    SNIPPET_REGEXES[snippetRegexType],
    // Discard trailing punctuation after the snippet number - happens when the placeholders are used in a sentence
    // e.g. "~~~1~~~, ~~~2~~~."
    opt(/[,.:]/),
    // Discard empty lines after the snippet placeholder.
    repeat(/\n/)
  );

const anyText = /(?:.|\n)+/;

const openAIResponseSwmdRegex = (snippetRegexType: SnippetRegexKind) =>
  new RegExp(
    or(
      // We try to parse snippet placeholders in a loop, grouping each placeholder with the paragraph that precedes it
      // and the previous lines before that (since the last match of the regex, which ended at the last snippet
      // placeholder).
      textUpToSnippetPlaceholder(snippetRegexType),
      // If we get here, it means that the previous option failed, meaning there's no ~~~#~~~ in the rest of the text, so
      // we just consume the rest of the lines so that we can output them as-is.
      namedGroup('textAfterAllPlaceholders', anyText)
    ),
    'gm'
  );

type ResponseSWMDMarkdownElement = { type: 'markdown'; markdown: string };
type ResponseSWMDSnippetElement = { type: 'snippet'; number: number; snippetCommentMarkdown?: string };
type ResolvedResponseSWMDSnippetElement = {
  type: 'snippet';
  snippetNode: ProseMirrorNode;
  snippetCommentMarkdown?: string;
};

export type ResolvedResponseSWMDSnippetElementWithMetadata = ResponseSWMDSnippetElement & {
  snippetName: string;
  snippetFilePath: string;
};

export type ParsedOpenAIResponseSwmdElement =
  | ResponseSWMDMarkdownElement
  | ResponseSWMDSnippetElement
  | ResolvedResponseSWMDSnippetElementWithMetadata;

export function* parseOpenAIResponseSwmd(
  response: string,
  snippetRegexType: SnippetRegexKind = 'snippetPlaceholder'
): Iterable<ParsedOpenAIResponseSwmdElement> {
  for (const match of response.matchAll(openAIResponseSwmdRegex(snippetRegexType))) {
    if (!match.groups) {
      continue;
    }
    const {
      previousLines,
      snippetCommentLines,
      snippetNumber,
      textAfterAllPlaceholders,
      snippetName,
      snippetFilePath,
    } = match.groups;
    if (textAfterAllPlaceholders) {
      yield { type: 'markdown', markdown: textAfterAllPlaceholders };
      continue;
    }
    if (previousLines) {
      yield { type: 'markdown', markdown: previousLines };
    }
    if (!snippetNumber) {
      if (snippetCommentLines) {
        yield { type: 'markdown', markdown: snippetCommentLines };
      }
      continue;
    }
    const parsedPos = parseInt(snippetNumber);
    if (Number.isNaN(parsedPos)) {
      if (snippetCommentLines) {
        yield { type: 'markdown', markdown: snippetCommentLines };
      }
      continue;
    }
    yield {
      type: 'snippet',
      number: parsedPos,
      ...(snippetName ? { snippetName } : {}),
      ...(snippetFilePath ? { snippetFilePath } : {}),
      ...(snippetCommentLines ? { snippetCommentMarkdown: snippetCommentLines } : {}),
    };
  }
}

type ResolvedParsedOpenAIResponseSwmdElement = ResponseSWMDMarkdownElement | ResolvedResponseSWMDSnippetElement;

// Given the parsed AI response - replaces the snippet numbers with the actual snippet node elements from the original
// documents.
export function* resolveSnippets(
  parsedResponse: Iterable<ParsedOpenAIResponseSwmdElement>,
  swimmDocumentContent: JSONContent
): Iterable<ResolvedParsedOpenAIResponseSwmdElement> {
  const originalRootNode = ProseMirrorNode.fromJSON(schema, swimmDocumentContent);
  for (const element of parsedResponse) {
    const type = element.type;
    switch (type) {
      case 'markdown':
        yield element;
        break;
      case 'snippet': {
        const { number, snippetCommentMarkdown } = element;
        if (number >= originalRootNode.nodeSize || originalRootNode.nodeAt(number)?.type?.name !== 'swmSnippet') {
          if (snippetCommentMarkdown) {
            yield { type: 'markdown', markdown: snippetCommentMarkdown };
          }
          continue;
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const currentSnippetNode = originalRootNode.nodeAt(number)!;
        yield {
          type: 'snippet',
          snippetNode: currentSnippetNode,
          ...(snippetCommentMarkdown ? { snippetCommentMarkdown } : {}),
        };
        break;
      }
      default:
        exhaustiveTypeException(type);
    }
  }
}

// Given the resolved snippets, merges consecutive snippets that have the same path, are adjacent to each other and have
// no comment between them.
export function* mergeAdjacentSnippets(
  resolvedSnippets: Iterable<ResolvedParsedOpenAIResponseSwmdElement>
): Iterable<ResolvedParsedOpenAIResponseSwmdElement> {
  let previousSnippet: ResolvedResponseSWMDSnippetElement | null = null;
  for (const element of resolvedSnippets) {
    if (element.type === 'markdown') {
      if (previousSnippet) {
        yield previousSnippet;
        previousSnippet = null;
      }
      yield element;
      continue;
    }
    if (previousSnippet) {
      if (
        previousSnippet.snippetNode.attrs.path === element.snippetNode.attrs.path &&
        previousSnippet.snippetNode.attrs.line + previousSnippet.snippetNode.attrs.snippet.split('\n').length ===
          element.snippetNode.attrs.line &&
        !element.snippetCommentMarkdown
      ) {
        const mergedSnippetContent = `${previousSnippet.snippetNode.attrs.snippet}\n${element.snippetNode.attrs.snippet}`;
        const mergedSnippet: ProseMirrorNode = ProseMirrorNode.fromJSON(schema, {
          type: 'swmSnippet',
          attrs: {
            snippet: mergedSnippetContent,
            line: previousSnippet.snippetNode.attrs.line,
            path: previousSnippet.snippetNode.attrs.path,
            repoId: previousSnippet.snippetNode.attrs.repoId,
          },
        }).copy(previousSnippet.snippetNode.content);
        previousSnippet = {
          type: 'snippet',
          snippetNode: mergedSnippet,
          snippetCommentMarkdown: previousSnippet.snippetCommentMarkdown,
        };
        continue;
      }
      yield previousSnippet;
    }
    previousSnippet = element;
  }
  if (previousSnippet) {
    yield previousSnippet;
  }
}

export function processOpenAIResponseSwmd(response: string, swimmDocumentContent: JSONContent): JSONContent {
  let orderedContent = ProseMirrorFragment.empty;
  // Parse the response that contains snippet placeholders into elements of markdown and snippets.
  const parsedResponse = parseOpenAIResponseSwmd(response);
  // Given the snippet numbers, resolve them into actual snippets from the original document.
  const resolvedSnippets = resolveSnippets(parsedResponse, swimmDocumentContent);
  // Merge adjacent snippets that continue each other into one so that there's no long streak of consecutive snippets
  // without any comments.
  const withMergedSnippets = mergeAdjacentSnippets(resolvedSnippets);
  for (const element of withMergedSnippets) {
    const type = element.type;
    switch (type) {
      case 'markdown':
        orderedContent = orderedContent.append(
          ProseMirrorFragment.from(ProseMirrorNode.fromJSON(schema, parseSwmdContent(element.markdown, {})).content)
        );
        break;
      case 'snippet': {
        const { snippetNode, snippetCommentMarkdown } = element;
        const parsedContext = snippetCommentMarkdown
          ? ProseMirrorNode.fromJSON(schema, parseSwmdContent(snippetCommentMarkdown, {})).content
          : null;
        const updatedSnippet: ProseMirrorNode = snippetNode.copy(parsedContext);
        orderedContent = orderedContent.addToEnd(updatedSnippet);
        break;
      }
      default:
        exhaustiveTypeException(type);
    }
  }
  // at the end we return the content of the newly constructed ProseMirror document to be sent back to the editor
  return orderedContent;
}
