import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useStore } from 'vuex';
import { type User, eventLogger } from '@swimm/shared';
import { useRouteData } from '@/common/composables/useRouteData';
import { config } from '@swimm/shared';
import { isAutomation, isTest, PJSON_VERSION as version } from '@/config';
import { getLoggerNew } from '@swimm/shared';
import { collectionNames } from '@/adapters-common/firestore-wrapper';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '../stores/auth-store';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';

const logger = getLoggerNew(__modulename);

interface Options {
  uniqueUser?: boolean;
}

export function useSwimmEventLogs() {
  const store = useStore();
  const routeData = useRouteData();
  const workspaceStore = useWorkspaceStore();
  const { name: workspaceName, id: workspaceId } = storeToRefs(workspaceStore);

  function getEvents(workspaceId: string, events: eventLogger.SwimmEvent[], options?: Options) {
    const workspaceEvents = store.getters['database/db_getWorkspaceEvents'](workspaceId);
    const eventCodes = events.map((e) => e.code);
    const data = (workspaceEvents || []).filter((e) => eventCodes.includes(e.swimmEventCode));
    return options?.uniqueUser ? getUniqueEventsByProperty(data, 'userId') : data;
  }

  async function logEvent(
    swimmEvent: eventLogger.SwimmEvent,
    payload?: { [x: string]: string },
    userDetails?: User
  ): Promise<typeof config.SUCCESS_RETURN_CODE | typeof config.ERROR_RETURN_CODE> {
    const logMessage = {
      ...(workspaceId.value ? { workspaceId: workspaceId.value } : {}),
      ...(workspaceName.value ? { workspaceName: workspaceName.value } : {}),
      ...formatRouteData(routeData),
      ...(payload || {}),
      swimmEventCode: swimmEvent.code,
    };
    // When called from store modules/sign-in/sign-up, auth will be undefined.
    const { user } = storeToRefs(useAuthStore());
    const userData: User = userDetails || user.value;

    try {
      if (!swimmEvent.code) {
        throw new Error('Event does not have a proper code');
      }

      if (!userData.uid) {
        throw new Error('No user uid');
      }

      const { code, eventMessage, error } = eventLogger.prepareEventLogMessage({ logMessage, userData, version });
      if (code === config.SUCCESS_RETURN_CODE) {
        await sendEventLog(eventMessage);
        return code;
      }
      throw new Error(`prepareEventLogMessage experienced error: ${error.toString()}`);
    } catch (err) {
      logger.error({ err }, `Could not send event log ${swimmEvent.code}. Details: ${err.toString()}`);
      return config.ERROR_RETURN_CODE;
    }
  }

  return {
    getEvents,
    logEvent,
  };
}

function getUniqueEventsByProperty(data, property: string) {
  // As Array<unknown> because we currently do not have ts schema for event logs
  return Object.values(groupByDay(data)).reduce(
    (acc, currGroup) => [...(acc as Array<unknown>), ...unique(currGroup, property)],
    []
  );
}

function groupByDay(data) {
  return data.reduce((acc, curr) => {
    const date = new Date(curr.created.seconds * 1000).toDateString();
    const dateData = acc[date] || [];
    return { ...acc, [date]: [...dateData, curr] };
  }, {});
}

function unique(data, property: string) {
  return data.filter(
    (e1, index, self) => e1[property] && self.findIndex((e2) => e1[property] === e2[property]) === index
  );
}

async function sendEventLog(eventMessage: eventLogger.EventLogMessage): Promise<void> {
  if (isAutomation || isTest) {
    logger.debug(`Skip log swimm event in testing env. Log message: ${eventMessage}`);
  } else {
    await firebase
      .firestore()
      .collection(collectionNames.EVENT_LOGS)
      .add({ ...eventMessage, created: firebase.firestore.FieldValue.serverTimestamp() });
  }
}

const ELIGIBLE_ROUTE_DATA_VARS = [
  'workspaceId',
  'workspaceName',
  'repoId',
  'repoName',
  'branch',
  'unitId',
  'playlistId',
  'playlistName',
] as const;

function formatRouteData(routeData) {
  return ELIGIBLE_ROUTE_DATA_VARS.reduce((accRouteData, currRouteKey) => {
    const routeValue = routeData[currRouteKey];
    return routeValue ? { ...accRouteData, [currRouteKey]: routeValue } : accRouteData;
  }, {});
}
