<script setup lang="ts">
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { computed, ref, toRef, watchEffect } from 'vue';
import { getSwimmEditorServices } from '../extensions/Swimm';
import { SwmNodeViewItemLink } from '@swimm/reefui';
import {
  ApplicabilityStatus,
  type Link,
  type SmartElementWithApplicability,
  config,
  parseSwmFilename,
} from '@swimm/shared';
import type * as _model from '@tiptap/pm/model';
import { getSwimmNodeId } from '@/swmd/swimm_node';
import { useTiptapIsSelected } from '@/composables/tiptapIsSelected';

const props = defineProps(nodeViewProps);

const swimmEditorServices = getSwimmEditorServices(props.editor);

const { selected } = useTiptapIsSelected(toRef(props, 'editor'), toRef(props, 'node'), toRef(props, 'getPos'));

// TODO Copied from SwmPathNodeView, can we refactor this logic?
const crossRepo = computed(() => props.node.attrs.repoId !== swimmEditorServices.repoId.value);

const crossRepoInIde = computed(() => crossRepo.value && swimmEditorServices.isIde);

const autosyncedElement = computed(() => {
  return swimmEditorServices.autosyncOutput.value?.smartElements.get(getSwimmNodeId(props.node)) as
    | SmartElementWithApplicability<Link>
    | undefined;
});

const unavailable = computed(() => {
  // in the ide, cross repo links are always as available
  // since we cannot check this anyway, and it will be checked in web when link opened
  if (crossRepoInIde.value) {
    return false;
  }
  return autosyncedElement.value?.applicability === ApplicabilityStatus.Unavailable;
});

// Dummy support for `short` & `custom`
const text = computed(() => {
  if (props.node.attrs.customDisplayText) {
    return props.node.attrs.customDisplayText;
  } else {
    const autosyncedText =
      autosyncedElement.value?.applicability === ApplicabilityStatus.Verified
        ? autosyncedElement.value.newInfo.docTitle
        : null;

    if (props.node.attrs.repoId !== swimmEditorServices.repoId.value) {
      return `(${props.node.attrs.repoName}) ${autosyncedText || props.node.attrs.docTitle}`;
    } else {
      return autosyncedText || props.node.attrs.docTitle;
    }
  }
});

const inactive = computed(() => {
  // outdated docs should not be clickable
  if (ApplicabilityStatus.Outdated === autosyncedElement.value?.applicability) {
    return true;
  }
  return swimmEditorServices.editable.value && !swimmEditorServices.isIde;
});

// TODO This can likely be moved down into the item component?
const tooltip = computed(() => {
  if (autosyncedElement.value == null) {
    return 'Verifying status.';
  }
  if (crossRepoInIde.value) {
    return '';
  }
  // TODO Need to wire this logic?
  // if (isUnauthorizeLink) {
  //   return `To access this ${swmType.value}, ask someone with code access to enable sharing for it.`;
  // }

  if (autosyncedElement.value.applicability !== ApplicabilityStatus.Verified) {
    return `This ${swmType.value} is unavailable.`;
    // TODO What to do about: return isNotInDB ? 'This resource was deleted.';
  }

  if (inactive.value) {
    return 'This link will become active when you leave edit mode.';
  }

  return '';
});

// TODO Copied from SwmPathNodeView, can we refactor this logic?
const branch = ref<string>();
watchEffect(async () => {
  if (!swimmEditorServices.isAuthorized.value) {
    branch.value = undefined;
    return;
  }

  if (!crossRepo.value) {
    branch.value = swimmEditorServices.branch.value;
    return;
  }

  branch.value = swimmEditorServices.getRepo(props.node.attrs.repoId)?.defaultBranch;
});

const swmType = computed(() => {
  const parsedSwmFileName = parseSwmFilename(props.node.attrs.path);
  if (!parsedSwmFileName) {
    // TODO corrupted
    return 'doc';
  }

  // TODO This should really be done in a shared place and not implemented inline here
  switch (`.${parsedSwmFileName.extension}`) {
    case config.SWMD_FILE_EXTENSION:
      return 'doc';
    case config.SWMD_PLAYLIST_EXTENSION:
      return 'playlist';
    default:
      return 'doc'; // TODO
  }
});

async function handleClick(cmdClick = false) {
  // in ide - we ignore the branch anyway, so we can always handle
  if (branch.value || swimmEditorServices.isIde) {
    const path =
      autosyncedElement.value?.applicability === ApplicabilityStatus.Verified
        ? autosyncedElement.value.newInfo.filePath
        : props.node.attrs.path;
    await swimmEditorServices.external.openSwimmResource({
      repoId: props.node.attrs.repoId,
      path,
      branch: branch.value ?? '',
      newTab: cmdClick,
    });
  }
}
</script>

<template>
  <NodeViewWrapper data-swm-link as="span">
    <!-- TODO loading and unavailable should really be part of the variant or applicability -->
    <SwmNodeViewItemLink
      :variant="autosyncedElement?.applicability"
      :tooltip="tooltip"
      :type="swmType"
      :name="text"
      :draft="autosyncedElement?.isDraft"
      :loading="autosyncedElement == null"
      :unavailable="unavailable"
      :selected="selected"
      :inactive="inactive"
      @click="handleClick"
      @cmd-click="handleClick(true)"
  /></NodeViewWrapper>
</template>
