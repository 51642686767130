import { getLoggerNew } from '@swimm/shared';
import * as firestoreWrapper from '@/adapters-common/firestore-wrapper';
import { UNKNOWN_USER_NAME } from '@/common/consts';
import type { ContributionDBItemInterface, ContributorDbItemInterface } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

/**
 * Return collection ref for contributions
 */
function getContributionsCollectionRef(repoId, docId) {
  return firestoreWrapper.getSubCollectionRefRecursive(
    [
      firestoreWrapper.collectionNames.REPOSITORIES,
      firestoreWrapper.collectionNames.SWIMMS,
      firestoreWrapper.collectionNames.CONTRIBUTIONS,
    ],
    [repoId, docId]
  );
}

/**
 * Creates doc contribution for document id in
 * repositories/<repo-id>/swimms/<doc-id>/contributions/
 * contribution consits of creator, creator_name (taken from user),
 * created timestamp and method (e.g. push) for saving
 * errors are ignored silently
 */
export async function addDocContribution({ user, repoId, docId, method, branch }: ContributionDBItemInterface) {
  try {
    const creator = user.uid;
    const contributionsCollectionRef = getContributionsCollectionRef(repoId, docId);
    const contribution = {
      created: firestoreWrapper.firestoreTimestamp(),
      creator,
      creator_name: user.nickname,
      method,
      branch,
    };
    await contributionsCollectionRef.add(contribution);
  } catch (err) {
    logger.error(
      { err },
      `Failed to addContribution for repoId=${repoId} docId=${docId} user.uid=${user.uid} with ${err}`
    );
  }
}

/*
Return list of contributors (from store based on DB sub collection)
return object consists of
  * creator (might be undefined),
  * others sorted new to old
  * title (for the headers)
  * creatorAndOthersArray: combined array (creator first if exists, then new to old) for the popup
 */
export function getCreatorAndOthers(contributors: Array<ContributorDbItemInterface>): {
  creator: ContributorDbItemInterface | undefined;
  others: Array<ContributorDbItemInterface>;
  title: string;
  creatorAndOthersArray: Array<ContributorDbItemInterface>;
} {
  const creator = contributors.find((contributor) => contributor.is_creator);
  const others = contributors.filter((contributor) => !contributor.is_creator);
  others.sort((c1, c2) => c2.modified.toMillis() - c1.modified.toMillis());
  let title = '';
  if (creator) {
    title += creator.name || UNKNOWN_USER_NAME;
    if (others.length) {
      title += ' & ';
    }
  }
  if (others.length) {
    title += `${others.length} ${others.length === 1 ? 'other' : 'others'}`;
  }
  const creatorAndOthersArray = creator ? [creator, ...others] : [...others];
  return { creator, others, title, creatorAndOthersArray };
}
