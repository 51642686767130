import type { Editor } from '@tiptap/vue-3';
import { findChildren } from '@tiptap/core';
import { NODES_WITH_APPLICABILITY } from '../nodesConsts';
import { isHunkEqualToQuery } from '@swimm/shared';
export function goToAnchor(editor: Editor, anchorId: string) {
  const nodes = findChildren(editor.state.doc, (node) => {
    return NODES_WITH_APPLICABILITY.includes(node.type.name);
  });

  if (!nodes?.length) {
    return;
  }

  for (const node of nodes) {
    const jsonHunk = node.node.toJSON()?.attrs?.hunk;
    if (!jsonHunk) {
      continue;
    }
    const hunk = JSON.parse(jsonHunk);
    if (hunk) {
      const nodeId = hunk.id;
      if (isHunkEqualToQuery(anchorId, hunk)) {
        const hunkDom = document.getElementById(`hunk-${nodeId}`);
        if (hunkDom) {
          // setting timeout of 0 seconds is equievelant to setImmediate, meaning this will call after the callstack will end
          // it fixes a bug caused by snippets being rerendered after autosync (rerendering them stops the scrollIntoView)
          setTimeout(() => {
            hunkDom.scrollIntoView({ block: 'center', behavior: 'smooth' });
          }, 0);
          hunkDom.classList.add('has-focus');
          setTimeout(() => {
            hunkDom.classList.remove('has-focus');
          }, 1500);
        }
        break;
      }
    }
  }
}
