import { Extension, type JSONContent } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    aiContentSuggestions: {
      insertSuggestedContent: (content: JSONContent) => ReturnType;
    };
  }
}

export default Extension.create({
  name: 'aiContentSuggestions',

  addCommands() {
    return {
      insertSuggestedContent:
        (content) =>
        ({ chain }) => {
          // Adding a new line after the added content and focusing it.
          // This will allow the content suggestion modal to still be visible for extra content adding.
          return chain().insertContent(content).insertContent({ type: 'paragraph' }).focus().run();
        },
    };
  },
});
