<script setup lang="ts">
import { toRef, watchEffect } from 'vue';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { useNavigate } from '@/common/composables/navigate';
import { useTemplatesStore } from '@/modules/core/stores/templates-store';
import { type SwimmDocument, config, getLoggerNew } from '@swimm/shared';
import { parseSwmd } from '@swimm/swmd';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useDoc } from '@/modules/drafts3/composables/doc';
import { BaseLoading } from '@swimm/reefui';
import { useDocReport } from '@/modules/editor3/composables/doc-report';

const logger = getLoggerNew(__modulename);

const props = defineProps({
  workspaceId: { type: String, required: true },
  repoId: { type: String, required: true },
  branch: { type: String, required: true },
  folderId: { type: String, default: null },
  template: { type: String, default: null },
  unitToDuplicateId: { type: String, default: null },
});

const store = useStore();
const drafts3Store = useDrafts3Store();
const route = useRoute();
const { navigateToPageAndTerminateWorker, getRepoPath } = useNavigate();
const templatesStore = useTemplatesStore();
const { loadDoc, parseDoc } = useDoc(
  toRef(() => props.workspaceId),
  toRef(() => props.repoId)
);
const docReport = useDocReport();

// TODO This should be handled at a higher level by some other store...
watchEffect(() => {
  drafts3Store.workspaceId = props.workspaceId;
});
watchEffect(() => {
  drafts3Store.repoId = props.repoId;
});
watchEffect(() => {
  drafts3Store.branch = props.branch;
});

(async () => {
  let doc: SwimmDocument | undefined;

  if (props.unitToDuplicateId) {
    // Duplicate an existing doc.
    try {
      doc = await parseDoc(await loadDoc(props.unitToDuplicateId));
      if (doc != null) {
        doc.title = (doc.title ?? 'Untitled') + ' (Copy)';
      }
    } catch (err) {
      logger.error(err, 'Error duplicating existing doc');
    }
  } else if (props.template) {
    // Create from a template
    await templatesStore.listDocTemplatesIfNeeded();
    await templatesStore.loadDocTemplate(templatesStore.docTemplates[props.template]);

    const repo = store.getters['database/db_getRepoMetadata'](props.repoId);
    const rawContent = templatesStore.docTemplates[props.template].rawFileContent;
    if (rawContent != null) {
      doc = parseSwmd(rawContent, {
        legacy: {
          baseUrl: config.BASE_URL,
          workspaceId: props.workspaceId,
          repoId: props.repoId,
          repoName: repo.name,
          repos: [
            {
              ...repo,
              branch: props.branch,
            },
          ],
        },
      });
    }
  }

  const docId = await drafts3Store.newDoc(doc);
  if (props.folderId != null) {
    drafts3Store.updateAttrs(docId, { folderId: props.folderId });
  }

  docReport.sendNewDocEvent({ workspaceId: props.workspaceId, repoId: props.repoId, id: docId });

  // TODO Figure out if we can call router.push with a route props object instead of
  // navigateToPageAndTerminateWorker, where the worker is handled transparently
  // by the router or layout component.
  return navigateToPageAndTerminateWorker({
    newRoute: `${getRepoPath()}/docs/${docId}/edit`,
    query: { ...route.query, folderId: undefined, template: undefined, unitToDuplicateId: undefined },
    replace: true,
  });
})();
</script>

<template>
  <div>
    <BaseLoading class="loader" size="large" />
  </div>
</template>

<style scoped>
.loader {
  height: 100vh;
}
</style>
