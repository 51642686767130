export const BATCH_IMPORT_ORIGINS = {
  CREATION_HUB: 'Creation Hub',
};

export const BATCH_IMPORT_EVENTS = {
  CLOSED_IMPORT_MODAL: 'Batch Import Wizard - Exited Wizard',
  VIEW_STEP: (stepName: string) => `Batch Import Wizard - Viewed "${stepName}" step`,
  EXTERNAL_TOOL_SOURCE_SELECTED: 'Batch Import Wizard - Import from another platform selected',
  FILES_SELECTED: 'Batch Import Wizard - Files Selected',
  IMPORT_FLOW_STARTED: 'Batch Import Wizard - Imported Process Started',
  FILE_PICKER_OPENED: 'Import File picker - opened',
  FILE_PICKER_CANCELED: 'Import File picker - canceled',
  CLICKED_FIX: 'Batch Import Wizard - Clicked "Fix"',
};
