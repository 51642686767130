<template>
  <ul class="resource-header-meta">
    <slot />
  </ul>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.resource-header-meta {
  $self: &;

  @include basic-resets;

  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
