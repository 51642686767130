import * as _ from 'lodash-es';

// Using a symbol look like: [[(sym|sym-text):this/is/path.js(SYMBOL_ID)]]
export function getSymbolPathRegex(symbol: string): RegExp {
  return new RegExp(`\\[\\[sym:\\S+?\\(${symbol}\\)\\]\\]`, 'gm');
}

export function getSymbolTextRegex(symbol: string, symbolText?: string): RegExp {
  return new RegExp(`\\[\\[sym-text:${_.escapeRegExp(symbolText) || '\\S+?'}\\(${symbol}\\)\\]\\]`, 'gm');
}

export function composeSymbolText(symbolId: string, symbolText: string): string {
  return `[[sym-text:${symbolText}(${symbolId})]]`;
}

export function getSymbolLinkRegex(symbol: string): RegExp {
  return new RegExp(`\\[\\[sym-link:\\(${symbol}\\).*?\\]\\]`, 'g');
}

export function getMentionRegex(symbol: string): RegExp {
  return new RegExp(`\\[\\[sym-mention:\\(${symbol}\\|.*?\\).*?\\]\\]`, 'g');
}

export function getTextPlaceholderRegex(text: string, symbol: string): RegExp {
  return new RegExp(`\\[${escapeRegex(text)}\\]\\(#text-placeholder-id-${symbol}\\)`, 'g');
}

export function escapeRegex(string) {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export const TOKEN_CONTEXT_LINES = 2;
