import { loadFile } from '@/remote-adapters/load';
import { config, getLoggerNew, parseSwmFilename } from '@swimm/shared';
import type { SwmCell, SwmFile } from '@swimm/shared';
import { getLocalSwmsList } from '@/remote-adapters/local_swms';

const logger = getLoggerNew(__modulename);

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { STORES } from '@/modules/core/stores/store-constants';

export interface Template extends SwmFile {
  name: string;
  path: string;
  fileName: string;
  content: SwmCell[] | null;
  rawFileContent: string | null;
}

export const useTemplatesStore = defineStore(STORES.TEMPLATES_STORE, () => {
  const docTemplates = ref<{ [key: string]: Template }>({});
  const listTemplatePromise = ref(null);

  const swmTemplates = computed<Record<string, Template>>(() =>
    Object.keys(docTemplates.value).reduce((accDocTemplates, templateId) => {
      const swmTemplate: Record<string, Template> = {};

      for (const prop of ['name', 'symbols', 'content', 'meta']) {
        const partialSwmFile = docTemplates.value[templateId];
        const value = partialSwmFile[prop];
        if (value !== null && value !== undefined) {
          swmTemplate[prop] = value;
        }
      }

      return { ...accDocTemplates, [templateId]: swmTemplate };
    }, {})
  );

  function resetState() {
    docTemplates.value = {};
  }

  async function listDocTemplatesIfNeeded() {
    if (listTemplatePromise.value) {
      return listTemplatePromise.value;
    }
    listTemplatePromise.value = listDocTemplates();
    await listTemplatePromise.value;
  }

  async function listDocTemplates() {
    if (Object.keys(docTemplates.value).length) {
      return;
    }
    const templateRepoData = config.SWIMM_TEMPLATES_REPO_DATA;
    const remoteTemplatesList = await getLocalSwmsList(templateRepoData.repoId, templateRepoData.branch);
    if (!remoteTemplatesList.length) {
      return;
    }
    remoteTemplatesList.map((fileInfo) => {
      const parsedResourceNames = parseSwmFilename(`${fileInfo.path}/${fileInfo.name}${config.SWMD_FILE_EXTENSION}`);
      docTemplates.value[parsedResourceNames.swmId] = {
        ...fileInfo,
        name: parsedResourceNames.title,
        fileName: fileInfo.name,
        content: null,
      } as Template;
    });
    listTemplatePromise.value = null;
  }

  async function loadDocTemplate({ fileName }: Template) {
    const resourceFileName = fileName;
    const filePath = `${config.SWMD_TEMPLATES_FOLDER}/${resourceFileName}${config.SWMD_TEMPLATE_FILE_EXTENSION}`;
    const { swmId: resourceId } = parseSwmFilename(filePath);

    if (docTemplates.value[resourceId].content) {
      return;
    }
    // Load the swm if not loaded or load and autosync if not already attempted to autosync before or if reload is specifically set
    try {
      const templatesRepoData = config.SWIMM_TEMPLATES_REPO_DATA;
      const response = await loadFile({
        swmId: resourceId,
        shouldAutoSync: false,
        repoId: templatesRepoData.repoId,
        currentBranch: templatesRepoData.branch,
        type: config.SWIMM_FILE_TYPES.SWMD,
        filePath,
      });
      if (response.code === config.SUCCESS_RETURN_CODE) {
        docTemplates.value[resourceId] = { ...response.unit, fileName, rawFileContent: response.fileContent };
      } else {
        logger.error(`Couldn't not load template. ${(response.errorMessage && response.errorMessage) || ''}`);
      }
    } catch (err) {
      logger.error({ err }, `Error getting remote template files: ${err}`);
    }
  }

  return {
    docTemplates,
    swmTemplates,
    resetState,
    loadDocTemplate,
    listDocTemplates,
    listDocTemplatesIfNeeded,
  };
});
