<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { type MermaidSampleOption, mermaidSamplesOptions } from './mermaidSamples';
import { Icon, SwText, Tag } from '@swimm/ui';
import VSelect from 'vue-select';
import { v4 as uuidv4 } from 'uuid';

const props = defineProps<{
  withAiOption: boolean;
  startOpen: boolean;
}>();

const AI_LABEL = 'AI';

type MermaidDropdownOption =
  | {
      label: typeof AI_LABEL;
    }
  | MermaidSampleOption;

const emit = defineEmits<{
  (e: 'select', option: MermaidSampleOption): void;
  (e: 'open-ai-panel'): void;
}>();

const selectOpen = ref(props.startOpen);

function optionSelected(selectedOption: MermaidDropdownOption) {
  selectOpen.value = false;
  if (selectedOption.label === AI_LABEL) {
    emit('open-ai-panel');
  } else {
    emit('select', selectedOption as MermaidSampleOption);
  }
}

const dropdownOptions = computed(() => {
  const aiOption = props.withAiOption
    ? [
        {
          label: 'AI',
        },
      ]
    : [];

  return [...aiOption, ...mermaidSamplesOptions];
});

// see comment in reefui BaseDropdown.vue about focus
const dropdownInputId = `mermaid-sample-${uuidv4()}`;

onMounted(() => {
  if (props.startOpen) {
    const elem = document.getElementById(dropdownInputId) as HTMLElement;
    if (elem) {
      elem.focus();
    }
  }
});
</script>

<template>
  <VSelect
    model-value="Start with..."
    class="sample-select"
    :options="dropdownOptions"
    :reduce="(selectedSample: MermaidDropdownOption) => selectedSample.label"
    :clearable="false"
    :searchable="false"
    @option:selected="optionSelected"
    :dropdown-should-open="() => selectOpen"
    @click.stop="selectOpen = !selectOpen"
    data-testid="mermaid-templates"
    :input-id="dropdownInputId"
    v-click-outside="() => (selectOpen = false)"
  >
    <template #open-indicator>
      <Icon no-padding name="arrow-down" class="arrow-icon" />
    </template>
    <template #option="{ label, icon }">
      <span class="option-line">
        <template v-if="label === AI_LABEL">
          <SwText component="span" variant="body-S"><Icon no-padding name="magic" class="ai-icon" /></SwText>
          <SwText component="span" variant="body-XS" class="label" data-testid="mermaid-ai-option">{{ label }}</SwText>
          <Tag class="ai-tag" tag-style="beta">Beta</Tag>
        </template>
        <template v-else>
          <SwText component="span" variant="body-S"><Icon no-padding :name="icon" /></SwText>
          <SwText component="span" variant="body-XS" class="label">{{ label }}</SwText>
        </template>
      </span>
    </template>
  </VSelect>
</template>

<style scoped lang="postcss">
.sample-select {
  width: 105px;
  font-size: var(--body-XS);

  .option-line {
    display: inline-flex;
    align-items: center;

    .label {
      margin-left: var(--space-xs);
    }

    .ai-tag {
      margin-left: var(--space-xs);
    }

    .ai-icon {
      color: var(--text-color-magic);
    }
  }

  :deep(.vs__search) {
    padding: 0;
  }

  :deep(.vs__dropdown-toggle) {
    background-color: var(--color-surface);
    cursor: pointer;

    &:hover {
      background-color: var(--color-hover);
    }
  }

  :deep(&.v-select.v-select:focus-within .vs__dropdown-toggle) {
    border-color: var(--color-border-default);
  }

  :deep(.vs__dropdown-option--selected) {
    background-color: var(--color-selected);
  }

  :deep(.vs__dropdown-menu) {
    border: 1px solid var(--color-border-default-subtle);
  }
}
</style>
