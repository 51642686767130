import { findLastIndex } from 'lodash-es';

import gitwrapper from 'GitUtils/gitwrapper';
import { getLoggerNew } from '#logger';
import type { DynamicChangeLine, DynamicHunkContainer, DynamicPatchFile } from '../types';
import { FileDiffType, HunkChangeLineType } from '../types';
import { escapeRegExpCharacters, matchAllWithoutRegex } from './string-utils';

const logger = getLoggerNew("packages/shared/src/utils/apply-utils.ts");

export function getMeatMatchesInFile({
  fileData,
  hunk,
  filePath,
  repoId,
}: {
  fileData: string;
  hunk: DynamicHunkContainer;
  filePath: string;
  repoId: string;
}): { matches: RegExpMatchArray[]; matchPair: RegexAndRawPair } {
  const matchPair = buildHunkRegex(hunk);
  try {
    return { matches: [...fileData.matchAll(matchPair.regex)], matchPair };
  } catch (err) {
    logger.warn(
      `Failed to create regex due to length or complexity. Falling back to string matching. repoId: "${repoId}".`
    );
    logger.debug({ err }, `Failed to match hunk in file "${filePath}", repoId: "${repoId}".`); // Do not log error as it includes filepath
    const matches = matchAllWithoutRegex(fileData, matchPair.raw);
    return { matches, matchPair };
  }
}

export async function isFilePatchApplicable({
  dynamicPatchFile,
  filePath,
  repoId,
  branch,
}: {
  dynamicPatchFile: DynamicPatchFile;
  filePath: string;
  repoId?: string;
  branch: string;
}): Promise<boolean> {
  if (dynamicPatchFile.diffType === FileDiffType.Renamed) {
    const oldFileExists = await gitwrapper.isFileExistsOnRevision({
      filePath: dynamicPatchFile.fileNameA,
      revision: branch,
      repoId,
    });
    const newFileExists = await gitwrapper.isFileExistsOnRevision({
      filePath: dynamicPatchFile.fileNameB,
      revision: branch,
      repoId,
    });
    if (!oldFileExists || newFileExists) {
      return false;
    }
  } else if (dynamicPatchFile.diffType === FileDiffType.Added) {
    return !(await gitwrapper.isFileExistsOnRevision({ filePath, revision: branch, repoId }));
  }
  let fileData: string;
  try {
    fileData = await gitwrapper.getFileContentFromRevision({ filePath, repoId, revision: branch });
  } catch (error) {
    // Can't get file content == file doesn't exist, which means the patch isn't applicable.
    return false;
  }
  if (dynamicPatchFile.diffType === FileDiffType.Deleted) {
    // Deleting an existing file should always work.
    return true;
  }
  for (const hunkContainer of dynamicPatchFile.hunkContainers) {
    let matches: Array<Array<string>> = [];
    let matchPair;
    try {
      const regexResult = getMeatMatchesInFile({ fileData, hunk: hunkContainer, filePath, repoId });
      matches = regexResult.matches;
      matchPair = regexResult.matchPair;

      if (matches.length === 0) {
        return false;
      }
      // We want to know how common it is to have cases where there's more than one potential match.
      if (matches.length > 1) {
        logger.warn(`Encountered more than one (${matches.length}) match when checking for applicability.`);
      }
    } catch (err) {
      // This _very weird_ error is due to constraints from V8 regarding RegExp length.
      // We're hoping using the dumb `indexOf` option will be enough for these edge cases.
      logger.error(
        `Regex too long when matching ${hunkContainer.changes.length} lines in file on branch "${branch}", repoId: "${repoId}".`
      );
      return fileData.indexOf(matchPair.raw) >= 0;
    }
  }
  return true;
}

interface RegexAndRawPair {
  regex: RegExp;
  raw: string;
}

/**
 * Create a regex that matches the lines of the given hunk.
 * @param hunkContainer HunkContainer to create a regex out of.
 * @returns regex matching the given hunk's lines and the complementary raw string that created it.
 */
function buildHunkRegex(hunkContainer: DynamicHunkContainer): RegexAndRawPair {
  const changes: string[] = [];
  const meatChangeLines = getMeatChangeLines(hunkContainer);
  for (const changeLine of meatChangeLines) {
    if (changeLine.changeType === HunkChangeLineType.Context) {
      logger.warn('buildHunkRegex treating a context line as a meat line'); // This can happen in cases as described here: https://app.swimm.io/workspaces/aRvMqc0yWAVcJlLN944D/repos/veezvxCuzpPrRLLXWD2E/docs/YCleN
    }
    if (changeLine.fileA) {
      changes.push(changeLine.fileA.data);
    }
  }
  const changesString = changes.join('\n');
  return { regex: new RegExp(`^${escapeRegExpCharacters(changesString)}$`, 'gm'), raw: changesString };
}

/**
 * Extract the changed (non-context) lines from the given hunk.
 * Note that inter-hunk context lines are extracted as part of the meat.
 * @param hunkContainer HunkContainer to extract the "meat" (changed lines) from.
 * @returns array of changed lines within the given hunk (including inter-hunk context).
 */
function getMeatChangeLines(hunkContainer: DynamicHunkContainer): DynamicChangeLine[] {
  const nonContextTestFunc = (changeLine: DynamicChangeLine) => changeLine.changeType !== HunkChangeLineType.Context;
  const meatStartIndex = hunkContainer.changes.findIndex(nonContextTestFunc);
  const meatEndIndex = findLastIndex(hunkContainer.changes, nonContextTestFunc);
  return hunkContainer.changes.slice(meatStartIndex, meatEndIndex + 1);
}
