<script setup lang="ts">
import { extensions } from '@/swmd/extensions';
import { forAllSwimmNodes, getSwimmNodeId, isAutosyncableSwimmNode } from '@/swmd/swimm_node';
import {
  type NormalizedAutosyncOutput,
  addDraftsInfoToAutosync,
  convertSwimmContentToAutosyncInput,
  isSmartElementModified,
  normalizeAutosyncOutput,
} from '@/swmd/autosync';
import TopArrowUp from '@/tiptap/extensions/TopArrowUp';
import Swimm from '@/tiptap/extensions/Swimm';
import type { EditorEnvKind } from '@swimm/editor';
import {
  ApplicabilityStatus,
  type SmartElement,
  type SmartElementWithApplicability,
  type SmartSymbol,
  type Snippet,
  config,
} from '@swimm/shared';
import { autosyncUnit } from '@swimm/swimmagic';
import type { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { type Editor, EditorContent, type EditorEvents, type JSONContent, useEditor } from '@tiptap/vue-3';
import { until } from '@vueuse/core';
import { isEqual } from 'lodash-es';
import debounce from 'lodash-es/debounce';
import {
  type InjectionKey,
  type ShallowRef,
  computed,
  inject,
  onBeforeUnmount,
  provide,
  ref,
  toRaw,
  toRef,
  watch,
} from 'vue';
import {
  type ExtensionConfiguration,
  type Repos,
  type SwimmEditorExternalServices,
  SwimmEditorServices,
  type SwimmNodeItem,
  populateIdWithFilePath,
  populateIdsToPositions,
} from '../tiptap/editorServices';
import GiphySelectionModal from './GiphySelectionModal.vue';
import PasteCodeWarningModal from './PasteCodeWarningModal.vue';
import SwmPathSelectionModal from './SwmPathSelectionModal.vue';
import SwmdBubbleMenu from './SwmdBubbleMenu.vue';
import SwmdFloatingMenu from './SwmdFloatingMenu.vue';
import AIGenerationDisabledModal from './AIGenerationDisabledModal.vue';
import SwmTokenSelectionAdvancedModal from './SwmTokenSelectionAdvancedModal.vue';
import { type Observable, Subject } from 'rxjs';
import Swimmport, { getSwimmportStorage } from '@/tiptap/extensions/Swimmport';

const props = defineProps<{
  editable: boolean;
  envKind: EditorEnvKind;
  externalServices: SwimmEditorExternalServices;
  baseUrl: string;
  repos: Repos;
  isAuthorized: boolean;
  workspaceId: string;
  repoId: string;
  branch: string;
  unitId: string;
  hash?: string;
  preSetAutosyncOutput?: NormalizedAutosyncOutput;
  modelValue: JSONContent;
  isAirGap?: boolean;
  extensionConfiguration?: ExtensionConfiguration;
  noPadding?: boolean;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: JSONContent];
  willUpdate: [];
  topArrowUp: [];
}>();

const updated = ref(false);

const actualAutosyncUnit = inject(AUTOSYNC_UNIT, autosyncUnit);

// Only used to prevent update loops
let currentContent: JSONContent | undefined;
const autosyncPromise = ref<Promise<void> | null>(null);
let pendingAutosync: ProseMirrorNode | null = null;

const autosyncing = computed(() => {
  return autosyncPromise.value != null;
});

const onUpdateDebounced = debounce(({ editor }: EditorEvents['update']) => {
  currentContent = editor.getJSON();
  emit('update:modelValue', currentContent);

  indexAndReactOnSwimmNodes(editor as Editor);
  swimmEditorServices.headings.value = editor.view.dom.querySelectorAll('.heading-1, .heading-2, .heading-3');
}, 500);

function autosyncAndApplyListener(editor: Editor, items: Observable<SwimmNodeItem>): void {
  let needsAutosync = false;
  const needsApply = new Map<string, SmartElementWithApplicability<SmartElement>>();

  items.subscribe({
    next(item) {
      if (!isAutosyncableSwimmNode(item.node)) {
        return;
      }

      if (!needsAutosync) {
        const smartElement = swimmEditorServices.autosyncOutput.value?.smartElements.get(item.swimmNodeId);
        if (!smartElement) {
          // We don't have autosync information for this node, trigger autosync.
          needsAutosync = true;
        } else if (
          // If the document has been modified at least once and autosync is done, then run apply on each verified and modified smart element
          updated.value &&
          autosyncPromise.value == null &&
          smartElement.applicability === ApplicabilityStatus.Verified &&
          isSmartElementModified(
            (smartElement as SmartElementWithApplicability<SmartSymbol | Snippet>) ||
              item.node.attrs.repoName !== smartElement.newInfo.gitInfo?.repoName
          )
        ) {
          needsApply.set(item.swimmNodeId, smartElement);
        }
      }
    },
    complete() {
      if (needsAutosync) {
        triggerAutosync(editor.state.doc);
      }

      if (needsApply.size > 0) {
        editor.chain().setMeta('addToHistory', false).applyAutosync(needsApply, false).run();
      }
    },
  });
}

function swimmNodeIdsListener(editor: Editor, items: Observable<SwimmNodeItem>): void {
  const swimmNodeIds = new Set<string>();
  const nodeIdsToPositions = new Map<string, Set<number>>();
  const nodesIdWithFilePath = new Set<string>();

  items.subscribe({
    next(item) {
      if (!isAutosyncableSwimmNode(item.node)) {
        return;
      }
      swimmNodeIds.add(item.swimmNodeId);
      populateIdsToPositions(item, nodeIdsToPositions);
      populateIdWithFilePath(item, nodesIdWithFilePath);
    },

    complete() {
      // set the value only if changed, to avoid invoking all computed
      const allNodesPositions = new Set();
      for (const positionSet of nodeIdsToPositions.values()) {
        allNodesPositions.add([...positionSet]);
      }
      if (
        !isEqual(swimmNodeIds, swimmEditorServices.swimmNodeIdsToPositions.value.keys()) ||
        !isEqual(allNodesPositions, swimmEditorServices.positionSet)
      ) {
        swimmEditorServices.swimmNodeIdsToPositions.value = nodeIdsToPositions;
        swimmEditorServices.nodesIdWithFilePath.value = nodesIdWithFilePath;
      }
    },
  });
}

function swimmMentionsListener(editor: Editor, items: Observable<SwimmNodeItem>): void {
  const mentions = new Map<string, { uid: string; email: string; pos: number; name: string }>();

  items.subscribe({
    next(item) {
      const { node, pos } = item;
      if (node.type.name === 'swmMention') {
        if (!mentions.get(node.attrs.uid)) {
          mentions.set(node.attrs.uid, { pos, uid: node.attrs.uid, email: node.attrs.email, name: node.attrs.name });
        }
      }
    },

    complete() {
      swimmEditorServices.mentions.value = mentions;
    },
  });
}

// TODO Refactor me to be something less specific for AI, e.g. listen for swimmSmartElements, or something similar
function aiListener(editor: Editor, items: Observable<SwimmNodeItem>): void {
  const snippets: { file: string; lines: string[] }[] = [];

  items.subscribe({
    next(item) {
      if (item.node.type.name === 'swmSnippet') {
        snippets.push({ file: item.node.attrs.path, lines: item.node.attrs.snippet.split('\n') });
      }
    },

    complete() {
      swimmEditorServices.external.aiContentSuggestionsService.updateContentSuggestionsSnippets(snippets);
    },
  });
}

const SWIMM_NODE_LISTENERS: ((editor: Editor, items: Observable<SwimmNodeItem>) => void)[] = [
  autosyncAndApplyListener,
  swimmNodeIdsListener,
  swimmMentionsListener,
  aiListener,
];

function indexAndReactOnSwimmNodes(editor: Editor): void {
  const items = new Subject<SwimmNodeItem>();

  for (const listener of SWIMM_NODE_LISTENERS) {
    listener(editor, items);
  }

  forAllSwimmNodes(editor.state.doc, (node, pos, parent, index) => {
    items.next({ node, pos, parent, index, swimmNodeId: getSwimmNodeId(node) });
    return true;
  });

  items.complete();
}

function triggerAutosync(content: ProseMirrorNode): void {
  if (autosyncPromise.value != null) {
    pendingAutosync = content;
    return;
  }

  autosyncPromise.value = doAutosync(content).then(() => {
    if (pendingAutosync) {
      autosyncPromise.value = doAutosync(pendingAutosync);
      pendingAutosync = null;
    }
  });
}

async function doAutosync(content: ProseMirrorNode): Promise<void> {
  await until(() => swimmEditorServices.repos.value.loading).not.toBeTruthy();
  // for shared doc, we get the autosync output from the parent component
  // and just set it
  if (props.preSetAutosyncOutput) {
    swimmEditorServices.autosyncOutput.value = props.preSetAutosyncOutput;
    autosyncPromise.value = null;
    return;
  }
  const autosyncInput = convertSwimmContentToAutosyncInput(
    content,
    swimmEditorServices.repoId.value,
    swimmEditorServices.branch.value,
    swimmEditorServices.repos.value.repos
  );
  addDraftsInfoToAutosync(autosyncInput, swimmEditorServices.external.isDraft.bind(swimmEditorServices.external));

  // TODO Why error boolean... Why not throw an error...
  const autosyncResult = await actualAutosyncUnit(autosyncInput);
  if (autosyncResult.autosyncSuccess) {
    swimmEditorServices.autosyncOutput.value = normalizeAutosyncOutput(autosyncResult.autosyncOutput);
  }

  autosyncPromise.value = null;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  indexAndReactOnSwimmNodes(editor.value!);
}

const swimmEditorServices = new SwimmEditorServices(
  toRef(props, 'editable'),
  props.envKind,
  props.externalServices,
  config.BASE_URL,
  toRef(props, 'repos'),
  toRef(props, 'isAuthorized'),
  toRef(props, 'workspaceId'),
  toRef(props, 'repoId'),
  toRef(props, 'branch'),
  toRef(props, 'unitId'),
  props.isAirGap ?? false,
  props.extensionConfiguration
);

const editor = useEditor({
  content: toRaw(props.modelValue),
  extensions: [
    TopArrowUp.configure({
      topArrowUp: () => {
        emit('topArrowUp');
      },
    }),
    Swimm.configure({ swimmEditorServices: swimmEditorServices }),
    ...extensions,
    Swimmport.configure({
      storage: getSwimmportStorage(),
    }),
  ],
  onBeforeCreate: ({ editor }) => {
    swimmEditorServices.editor = new WeakRef(editor as Editor);
  },
  onCreate: ({ editor }) => {
    indexAndReactOnSwimmNodes(editor as Editor);
    swimmEditorServices.headings.value = editor.view.dom.querySelectorAll('.heading-1, .heading-2, .heading-3');

    // TODO For one reason or another, scroll anchoring
    // (https://developer.mozilla.org/en-US/docs/Web/CSS/overflow-anchor/Guide_to_scroll_anchoring),
    // is not working inside our editor, so image loads or Mermaid renders cause
    // the scroll position to shift, so we delay the focus as a kludge to
    // workaround that, but that's not perfect and can cause us to focus at the
    // wrong time, we should circle back to this at some point
    setTimeout(() => {
      if (props.hash) {
        scrollToHash(editor as Editor, props.hash);
      }
    }, 500);
  },
  onUpdate: (props) => {
    updated.value = true;
    emit('willUpdate');
    onUpdateDebounced(props);
  },
  editable: props.editable,
});

provide(SWMD_EDITOR, editor);

function scrollToHash(editor: Editor, hash: string): void {
  const id = hash.slice(1);
  const escapedId = CSS.escape(id);
  const node =
    editor.view.dom.querySelector(`#${escapedId}`) || editor.view.dom.querySelector(`[data-ext-id="${escapedId}"]`);
  if (node) {
    requestAnimationFrame(async () => {
      editor.commands.focus(editor.view.posAtDOM(node, 0), { scrollIntoView: false });
      // TODO center won't work in Safari, needs polyfill
      node.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
      setTimeout(() => {
        swimmEditorServices.animationsBus.emit({ type: 'focus', id });
      }, 500);
    });
  }
}

watch(
  () => props.modelValue,
  (newVal) => {
    // TODO Is there a better way to do this? Document this, and probably test it as well
    if (toRaw(newVal) !== currentContent) {
      editor.value?.commands.setContent(toRaw(newVal), false);
    }
  }
);

watch(
  () => props.editable,
  (value) => {
    editor.value?.setEditable(value, false);
  }
);

watch(
  () => props.hash,
  (value) => {
    if (value) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      scrollToHash(editor.value!, value);
    }
  },
  { flush: 'post' }
);

onBeforeUnmount(() => {
  onUpdateDebounced.flush();
});

function focus() {
  editor.value?.commands.focus();
}

const proseMirrorPadding = computed(() => {
  return props.noPadding ? '0' : 'var(--narrow-content-width-horizontal-padding)';
});

defineExpose({
  editor,
  flush: onUpdateDebounced.flush,
  focus,
  autosyncing,
  triggerAutosync: () => editor.value && triggerAutosync(editor.value.state.doc),
  applyAutosync: () => {
    if (editor.value) {
      updated.value = true;
      indexAndReactOnSwimmNodes(editor.value);
    }
  },
});
</script>

<script lang="ts">
export const SWMD_EDITOR: InjectionKey<ShallowRef<Editor | undefined>> = Symbol('SWMD_EDITOR');
export const AUTOSYNC_UNIT: InjectionKey<typeof autosyncUnit> = Symbol('AUTOSYNC_UNIT');
</script>

<template>
  <div class="swmd-editor" data-testid="swmd-editor" :data-autosyncing="autosyncing">
    <SwmdBubbleMenu :editor="editor" />
    <SwmdFloatingMenu :editor="editor" class="swmd-editor__floating-menu" />
    <EditorContent :editor="editor" class="swmd-editor__content" data-testid="editor-content" />
    <SwmPathSelectionModal v-if="editor" :editor="editor" />
    <GiphySelectionModal
      v-model="swimmEditorServices.showGiphySelectionModal.value"
      :giphy-client="externalServices.giphyClient"
      @selected="(url) => (swimmEditorServices.giphySelection.value = url)"
    />
    <PasteCodeWarningModal v-if="editor" :editor="editor" />
    <AIGenerationDisabledModal v-if="editor" :editor="editor" />
    <SwmTokenSelectionAdvancedModal v-if="editor" :editor="editor" />
  </div>
</template>

<style scoped lang="scss">
.swmd-editor {
  height: 100%;

  &__floating-menu {
    position: relative;
    left: calc(var(--space-small) * -1);
  }

  &__content {
    height: 100%;
    margin-left: calc(var(--space-medium) * -1);
    padding-left: var(--space-medium);

    // Override native styling for TipTap editor.
    // -----------------------------------------------------
    // General rule of thumb should be that this is the only
    // place this occurs.

    ::selection {
      background-color: var(--color-selection);
      color: var(--text-color-selection);
    }

    // eslint-disable vue-scoped-css/no-unused-selector
    :deep(.ProseMirror) {
      height: 100%;
      padding-left: v-bind(proseMirrorPadding);
      padding-right: v-bind(proseMirrorPadding);

      // Remove focus outline.
      &:focus-visible {
        outline: none;
      }

      // Add spacing between node view wrapper elements.
      [data-node-view-wrapper] {
        margin-bottom: var(--space-medium);
      }

      // Text elements.
      p,
      th,
      td {
        font-size: var(--font-size-default);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-default);
      }

      strong {
        font-weight: var(--font-weight-bolder);
      }

      // Headings.
      h1,
      .heading-1 {
        font-size: var(--font-size-xlarge);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-heading);
      }

      h2,
      .heading-2 {
        font-size: var(--font-size-large);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-heading);
      }

      h3,
      .heading-3 {
        font-size: var(--font-size-medium);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-heading);
      }

      h4,
      .heading-4 {
        font-size: var(--font-size-default);
        font-weight: var(--font-weight-bolder);
        line-height: var(--line-height-heading);
      }

      h5,
      .heading-5 {
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-bolder);
        line-height: var(--line-height-heading);
      }

      h6,
      .heading-6 {
        font-size: var(--font-size-xsmall);
        font-weight: var(--font-weight-bolder);
        line-height: var(--line-height-heading);
      }

      // Lists.
      ul:not(.v-select ul),
      ol:not(.v-select ol) {
        padding-left: var(--space-medium);
        line-height: var(--line-height-default);
      }

      // Task Item
      // Based on https://tiptap.dev/docs/editor/api/nodes/task-item
      ul:has(li[data-type='taskItem']) {
        p {
          margin: 0;
        }

        li[data-type='taskItem'] {
          display: flex;
          // Align task items with bullet lists visually
          // Since task item is * [] under the hood, ver(--space-medium) is the same as the padding-left of the ul
          // * -1 moves the task item back to be on the same vertical line with the bullet list
          // Then we add 6px to align the middle of a checkbox with the middle of a bullet list item
          // To see what it does - create a list that mixes a bullet and a task list
          margin-left: calc(-1 * var(--space-medium) + 6px);

          > label {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
            user-select: none;
          }

          > div {
            flex: 1 1 auto;
          }

          ul li,
          ol li {
            display: list-item;
          }

          ul > li[data-type='taskItem'] {
            display: flex;
          }
        }
      }

      // Tight lists.
      ul[data-tight] li > p,
      ol[data-tight] li > p {
        margin-top: 0;
        margin-bottom: 0;

        + p {
          margin-top: 1em;
        }
      }

      // Blockquotes.
      blockquote {
        border-left: var(--space-xxsmall) solid var(--color-border-default-strong);
        margin: 0 0 0 var(--space-medium);
        padding-left: var(--space-medium);
      }

      // Normal link, <a>.
      a.link {
        cursor: pointer;
        color: var(--color-text-brand);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      // Inline code span, <code>, backticks
      .code {
        background-color: var(--color-bg-surface);
        border-radius: var(--space-xxsmall);
        color: var(--text-color-primary);
        display: inline-block;
        font-family: var(--font-family-mono);
        line-height: var(--line-height-mono);
        padding: 0 var(--space-xxsmall);
      }

      // Code block, <pre><code>, triple backticks
      .code-block {
        padding: var(--space-xsmall);
        border-radius: var(--space-xxsmall);
        background-color: var(--color-bg-surface);
        line-height: initial;

        code {
          font-family: var(--font-family-mono);
          font-size: var(--font-size-small);
          line-height: var(--line-height-mono);
          background: inherit;
        }
      }

      // Tables https://tiptap.dev/api/nodes/table
      table {
        border-spacing: 0;
        table-layout: inherit;
        width: 100%;
        position: relative;

        th {
          font-weight: bold;
          background-color: var(--color-bg-surface);
        }

        th,
        td {
          min-width: 8em;
          padding: var(--space-small);
          vertical-align: top;
          box-sizing: border-box;
          position: relative;
          text-align: left;

          > * {
            margin-bottom: 0;
          }
        }

        tr {
          th,
          td {
            border-top: 1px solid var(--color-border-default);
            border-right: 1px solid var(--color-border-default);
          }

          th:first-child,
          td:first-child {
            border-left: 1px solid var(--color-border-default);
          }
        }

        tr:last-child {
          th,
          td {
            border-bottom: 1px solid var(--color-border-default);
          }

          th:first-child,
          td:first-child {
            border-bottom-left-radius: var(--border-radius);
          }

          th:last-child,
          td:last-child {
            border-bottom-right-radius: var(--border-radius);
          }
        }

        tr:first-child {
          th:first-child,
          td:first-child {
            border-top-left-radius: var(--border-radius);
          }

          th:last-child,
          td:last-child {
            border-top-right-radius: var(--border-radius);
          }
        }

        .selectedCell::after {
          z-index: 2;
          position: absolute;
          content: '';
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(200, 200, 255, 0.4);
          pointer-events: none;
        }
      }

      .table-wrapper {
        margin: var(--space-medium) 0;
        width: fit-content;
      }

      // Placeholder
      p.is-editor-empty:first-child::before {
        color: var(--text-color-disable);
        content: attr(data-placeholder);
        /* stylelint-disable-next-line property-disallowed-list */
        float: left;
        height: 0;
        pointer-events: none;
      }

      // Insertion placeholder
      .insertion-placeholder {
        display: inline-block;
        animation: spin 0.5s linear infinite;
      }

      .text-completion {
        white-space: pre;
        text-wrap: pretty;
        color: var(--color-text-disabled);
        font-style: italic;
        font-size: var(--font-size-default);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-default);
        position: relative;
        z-index: calc(var(--layer-tippy) + 1); // Ensure completion text is displayed above Tippy popovers
      }
    }

    :deep(.ProseMirror-focused) {
      p.is-editor-empty:first-child::before {
        content: none;
      }
    }

    // Tiptap/ProseMirror NodeSelection (e.g. Selecting an entire code block or block quote)
    :deep(.ProseMirror-selectednode) {
      outline: 1px solid var(--color-border-info);
      border-radius: 2px;
    }

    // Tiptap/ProseMirror drag handle trigger area (e.g. extending each cell's hover area so drag handle appears more naturally)
    :deep(.ProseMirror > *) {
      position: relative;

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        height: 100%;
        margin-left: (calc(-2 * (var(--space-large))));
        padding-left: calc(2 * (var(--space-large)));
      }
    }

    // Gap cursor styles.
    :deep(.ProseMirror-gapcursor) {
      position: relative;
      top: calc((var(--space-xsmall) * -1) - 3px);

      /* Bubble text */
      &:before {
        box-sizing: border-box;
        color: var(--color-text-disabled);
        content: 'Type anything to insert a new line here...';
        display: inline-block;
        font-size: var(--font-size-xsmall);
        left: var(--space-medium);
        position: absolute;
        top: -10px;
        width: auto;
      }

      /* Blinking cursor */
      &:after {
        border: none;
        border-top: 1px solid var(--color-text-default);
        position: absolute;
        width: var(--space-small);
      }
    }
  }

  :deep([data-youtube-video]) {
    iframe {
      border: none;
      display: flex;
      margin: 0 auto var(--space-medium);
      width: 100%;
    }
  }
}
</style>
