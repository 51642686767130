import { Extension, findParentNode } from '@tiptap/core';
import { TextSelection } from '@tiptap/pm/state';
import { CellSelection, TableMap } from '@tiptap/pm/tables';

const SELECT_ALL_CONTENT_NODE_TYPES = ['codeBlock', 'blockquote', 'swmSnippet', 'mermaid'];

export default Extension.create({
  name: 'keyboardShortcuts',

  addKeyboardShortcuts() {
    return {
      /** Indent, indentation is represented as 4 spaces (Non-breaking spaces on serialization) */
      Tab: ({ editor }) =>
        editor.commands.first([
          ({ commands }) => {
            return commands.insertContent('    ');
          },
        ]),

      /** Unindent, indentation is represented as 4 spaces (Non-breaking spaces on serialization) */
      'Shift-Tab': ({ editor }) =>
        editor.commands.first([
          ({ commands, state }) => {
            if (state.doc.textBetween(state.selection.head - 4, state.selection.head) === '    ') {
              return commands.deleteRange({ from: state.selection.head - 4, to: state.selection.head });
            }

            return true;
          },
        ]),

      /**
       * Keybinding for Ctrl/Cmd-a.
       *
       * The first keybinding that runs takes effect:
       * 1. Select the current table cell if it's not already selected.
       * 2. If we selected cells in a table, select the entire table.
       * 4. Select the content of some types of nodes, or if already selected, the node itself.
       * 5. Otherwise falls back to the default behavior which selects the document.
       */
      'Mod-a': ({ editor }) =>
        editor.commands.first([
          /**
           * Select a single table cell if it's not already selected.
           */
          ({ dispatch, state, tr }) => {
            if (!['cell', 'header_cell'].includes(state.selection.$head.parent.type.spec.tableRole)) {
              return false;
            }

            const selection = CellSelection.create(
              state.doc,
              state.selection.$head.before(),
              state.selection.$head.before()
            );

            // If the selection is a cell selection that is larger than or equal to a selection of the cell
            if (
              state.selection instanceof CellSelection &&
              state.selection.from <= selection.from &&
              selection.to <= state.selection.to
            ) {
              return false;
            }

            if (dispatch) {
              tr.setSelection(selection);
            }

            return true;
          },

          /**
           * Select the entire table if not already selected.
           */
          ({ dispatch, state, tr }) => {
            // If the current selection is a table cell selection
            if (!(state.selection instanceof CellSelection)) {
              return false;
            }

            const table = state.selection.$head.node(-2);
            const tableStart = state.selection.$head.start(-2);
            const map = TableMap.get(table);

            const selection = CellSelection.create(
              state.doc,
              tableStart + map.map[0],
              tableStart + map.map[map.map.length - 1]
            );

            if (selection.eq(state.selection)) {
              return false;
            }

            if (dispatch) {
              tr.setSelection(selection);
            }

            return true;
          },

          /**
           * Select the content of some types of nodes, or if already selected, the node itself.
           */
          ({ commands, editor, state }) => {
            if (SELECT_ALL_CONTENT_NODE_TYPES.some((type) => editor.isActive(type))) {
              const node = findParentNode((node) =>
                SELECT_ALL_CONTENT_NODE_TYPES.some((type) => node.type.name === type)
              )(state.selection);
              if (!node) {
                return false;
              }

              const contentSelection = TextSelection.create(state.doc, node.start, node.start + node.node.content.size);

              if (state.selection.eq(contentSelection)) {
                return commands.setNodeSelection(node.pos);
              }

              return commands.command(({ dispatch, tr }) => {
                if (dispatch) {
                  tr.setSelection(contentSelection);
                }

                return true;
              });
            }

            return false;
          },
        ]),
    };
  },
});
