<template>
  <section v-if="isLoading || presentedEvents.length">
    <div class="user-activity-title subtitle-XL bold">Recent events (Admin view)</div>
    <div class="view-wrapper">
      <div class="list-titles system-body">
        <span class="user-column-title">USER</span>
        <span class="name-column-title">EVENT</span>
        <span class="date-column-title">DATE</span>
      </div>
      <div class="view list-view">
        <template v-if="isLoading">
          <div v-for="i in maxEvents" :key="i" class="item-wrapper">
            <div class="user-column">
              <SwAvatar text=" " />
            </div>
            <div class="name-column">
              <TextSkeleton variant="body-S" />
            </div>
            <div class="date-column"></div>
          </div>
        </template>

        <div v-else v-for="(event, key) in presentedEvents" :key="`event-${key}`" class="item-wrapper">
          <div class="user-column">
            <SwAvatar :text="event.userName" />
          </div>
          <div class="name-column">
            <component
              :is="sourceURL(event) ? 'router-link' : 'span'"
              :to="sourceURL(event)"
              class="name"
              :class="{ clickable: ClickableEvents.includes(event.swimmEventCode) }"
            >
              <SwText variant="body-S">{{ formatLogMessage(event) }}</SwText>
            </component>
          </div>
          <div class="date-column">
            <span class="date">
              <SwText variant="body-S">{{ getLogDate(event) }}</SwText>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import { eventLogger } from '@swimm/shared';
import { PageRoutesNames } from '@/common/consts';
import { SwAvatar, TextSkeleton } from '@swimm/ui';

const ClickableEvents = ['DOC_CREATED', 'DOC_UPDATED', 'CLOUD_DOC_VIEW', 'PLAYLIST_CREATED', 'PLAYLIST_UPDATED'];

export default {
  components: { TextSkeleton, SwAvatar },
  props: {
    workspaceId: { type: String, required: true },
    maxEvents: { type: Number, default: 10 },
  },
  setup() {
    const eventLogTypes = [
      eventLogger.SWIMM_EVENTS.CLOUD_DOC_CREATED.code,
      eventLogger.SWIMM_EVENTS.DOC_CREATED.code,
      eventLogger.SWIMM_EVENTS.DOC_UPDATED.code,
      eventLogger.SWIMM_EVENTS.DOC_ASSIGNED.code,
      eventLogger.SWIMM_EVENTS.DOC_REQUEST_CREATED.code,
      eventLogger.SWIMM_EVENTS.TEMPLATE_USED.code,
      eventLogger.SWIMM_EVENTS.USER_INVITED.code,
      eventLogger.SWIMM_EVENTS.WORKSPACE_USER_JOINED_WORKSPACE.code,
      eventLogger.SWIMM_EVENTS.PLAYLIST_CREATED.code,
      eventLogger.SWIMM_EVENTS.PLAYLIST_UPDATED.code,
    ];
    return {
      eventLogTypes,
      ClickableEvents,
    };
  },
  computed: {
    ...mapGetters('database', ['db_getWorkspaceEvents']),
    isLoading() {
      return !this.events;
    },
    events() {
      return this.db_getWorkspaceEvents(this.workspaceId);
    },
    presentedEvents() {
      // We sort after we filter the event to improve performance and avoid cases where certain event types create issues.
      return this.events
        ? this.events
            .filter((event) => this.eventLogTypes.includes(event.swimmEventCode))
            .slice(0, this.maxEvents)
            .sort((a, b) => (a.created.seconds > b.created.seconds ? -1 : 1))
        : [];
    },
  },
  methods: {
    sourceURL(swimmEvent) {
      const routeData = this.eventToRouteDataMapping(swimmEvent);
      if (!routeData) {
        return null;
      }
      return this.$router.resolve(routeData).href;
    },
    validateEventKeys(eventKeys, expectedKeys) {
      const filteredArray = expectedKeys.filter((value) => eventKeys.includes(value));
      return filteredArray.length === expectedKeys.length;
    },
    eventToRouteDataMapping(swimmEvent) {
      if (
        ['DOC_CREATED', 'DOC_UPDATED'].includes(swimmEvent.swimmEventCode) &&
        this.validateEventKeys(Object.keys(swimmEvent), ['workspaceId', 'repoId', 'branch', 'srcId'])
      ) {
        return {
          name: PageRoutesNames.DOC_VIEW,
          params: {
            workspaceId: swimmEvent.workspaceId,
            repoId: swimmEvent.repoId,
            branch: swimmEvent.branch,
            unitId: swimmEvent.srcId,
          },
        };
      } else if (
        swimmEvent.swimmEventCode === 'CLOUD_DOC_VIEW' &&
        this.validateEventKeys(Object.keys(swimmEvent), ['workspaceId', 'srcId'])
      ) {
        return {
          name: PageRoutesNames.CLOUD_DOC_VIEW,
          params: {
            workspaceId: swimmEvent.workspaceId,
            unitId: swimmEvent.srcId,
          },
        };
      } else if (
        ['PLAYLIST_CREATED', 'PLAYLIST_UPDATED'].includes(swimmEvent.swimmEventCode) &&
        this.validateEventKeys(Object.keys(swimmEvent), ['workspaceId', 'repoId', 'branch', 'srcId'])
      ) {
        return {
          name: PageRoutesNames.PLAYLIST_VIEW,
          params: {
            workspaceId: swimmEvent.workspaceId,
            repoId: swimmEvent.repoId,
            branch: swimmEvent.branch,
            playlistId: swimmEvent.srcId,
          },
        };
      }
      this.$logger.warn('Missing fields on event log, details:', { event: swimmEvent });

      return null;
    },
    formatLogMessage(event) {
      switch (event.swimmEventCode) {
        case 'DOC_ASSIGNED':
          return `${event.userName} assigned doc '${event.srcName}' to ${event.targetName}`;
        case 'CLOUD_DOC_CREATED':
          return `${event.userName} created a cloud doc`;
        case 'DOC_CREATED':
          return `${event.userName} created doc '${event.srcName}'`;
        case 'DOC_REQUEST_CREATED':
          return `${event.userName} requested doc '${event.srcName}' from ${event.targetName}`;
        case 'DOC_UPDATED':
          return `${event.userName} updated doc '${event.srcName}'`;
        case 'PLAYLIST_CREATED':
          return `${event.userName} created playlist '${event.srcName}'`;
        case 'PLAYLIST_UPDATED':
          return `${event.userName} updated playlist '${event.srcName}'`;
        case 'USER_INVITED':
          return `${event.userName} invited ${event.targetName} to the workspace`;
        case 'WORKSPACE_USER_JOINED_WORKSPACE':
          return `${event.userName} join the workspace`;
        case 'TEMPLATE_USED':
          return `${event.userName} used template '${event.srcName}'`;
        default:
          return event.actionName;
      }
    },
    getLogDate(event) {
      return new Date(event.created.seconds * 1000).toDateString();
    },
  },
};
</script>

<style scoped lang="postcss">
.user-activity-title {
  padding-bottom: 10px;
}

.view-wrapper {
  .list-view {
    position: relative;
  }

  .list-titles {
    display: flex;
    margin-bottom: 8px;
    text-align: center;
    color: var(--text-color-secondary);
    letter-spacing: 0.5px;
    font-family: var(--fontfamily-secondary);

    .name-column-title {
      flex: 1;
      text-align: left;
    }

    .user-column-title {
      margin-right: 8px;
    }

    .date-column-title {
      width: 240px;
    }
  }
}

.item-wrapper {
  display: flex;
  align-items: center;
  margin: var(--space-base) 0;
  width: 100%;
  box-sizing: border-box;

  .name-column {
    overflow: hidden;
    flex: 1;

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      word-break: break-word;

      &.clickable:hover {
        color: var(--text-color-link);
      }
    }
  }

  .user-column {
    display: flex;
    justify-content: center;
    margin-right: 16px;
  }

  .date-column {
    display: flex;
    justify-content: flex-start;
    width: 140px;
  }
}
</style>
