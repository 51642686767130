<template>
  <SideMenuLayout :save-width="false" :is-small="true">
    <template #sideBar>
      <StepsProgressBar
        type-name="Playlist"
        :steps="progressBarSteps"
        :active-step-id="activeStepId"
        :steps-container="progressBarStepsContainer"
        :repos-statuses="reposLoadingStatuses"
        :loading="loadingPlaylistData || reposLoading"
        :disabled="playlistUnavailable"
        @select-step="selectStep"
      >
        <Status :status="playlistStatus" />
        <template v-if="playlistUnavailable" #extra>
          <div class="steps-unavailable">Steps unavailable</div>
        </template>
      </StepsProgressBar>
    </template>
    <template #content>
      <TopMenuLayout :loading="loadingPlaylistData">
        <template #topBar>
          <DummyRepoBanner v-if="!loadingPlaylistData && isOnDummyRepoPage" />
          <TopBar
            :loading="loadingPlaylistData || activeStepIsLoading || reposLoading"
            :show-workspace-breadcrumb="false"
          >
            <template v-if="canEditPlaylist">
              <DocumentationOptionsMenu
                :show-go-to-committed="false"
                :show-discard="false"
                @change-folder="changeFolder"
              />
              <ShareButton entity="PLAYLIST" :repo-id="repoId" :entity-id="playlistId" />
              <div
                v-if="isPlaylistEditable"
                class="edit-playlist-button"
                @click="
                  navigateToPageAndTerminateWorker({
                    newRoute: `${getRepoPath()}/playlists/${playlistId}/edit`,
                    query: { step: currentStepIndex },
                  })
                "
              >
                Edit playlist
              </div>
            </template>
          </TopBar>
        </template>
        <template v-if="playlistUnavailable" #content>
          <ContentErrorState type="playlist" swimm-status="Not found" :repo-id="repoId" />
        </template>
        <template v-else-if="!activeStepIsLoading" #content>
          <section class="active-step">
            <div v-if="!shouldHideHeader" class="step-header">
              <ResourceHeadline
                v-if="isSwimmStep"
                :resource-name="activeStepTitle"
                :resource-type="resourceHeadlineType"
                :resource-status="activeStepStatus || ''"
                :resource-creator="activeStep.creator_name"
                :resource-repo="activeStep.repoDetails"
                :repo-id="activeStep.repoId"
                :resource-id="activeStep.id"
              />
              <ResourceHeadline
                v-else
                :resource-name="currentPlaylist.name"
                :resource-type="resourceHeadlineType"
                :resource-creator="currentPlaylist.creator_name"
              >
                <ResourceFolder :resource="currentPlaylist" :type="DocumentationTypes.PLAYLIST" :repo-id="repoId" />
              </ResourceHeadline>
            </div>
            <SequenceIntroSummary
              v-if="isIntro || isSummary"
              :is-intro="isIntro"
              :repo-id="repoId"
              :playlist="currentPlaylist"
            />
            <router-view v-else :key="$route.fullPath" />
          </section>
        </template>
      </TopMenuLayout>
      <MoveFolderModal
        v-if="showMoveFolderModal"
        :show="showMoveFolderModal"
        :repo-id="repoId"
        @close="showMoveFolderModal = false"
        :items="resourceToMove"
      />
    </template>
  </SideMenuLayout>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import {
  ApplicabilityStatus,
  type SwimmResourceUserStatus,
  type SwmFile,
  config,
  getGitProviderIconName,
  isSwmDoc,
  objectUtils,
  pageEvents,
} from '@swimm/shared';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useStore } from 'vuex';
import StepsProgressBar from '@/common/components/organisms/StepsProgressBar.vue';
import TopBar from '@/common/components/TopBar/TopBar.vue';
import TopMenuLayout from '@/common/layouts/TopMenuLayout.vue';
import SideMenuLayout from '@/common/layouts/SideMenuLayout.vue';
import { getResourceHeadlineType } from '@/common/utils/playlist-utils';
import SequenceIntroSummary from '@/common/components/organisms/SequenceIntroSummary.vue';
import { useDemoStore } from '@/modules/demo/demo';
import { storeToRefs } from 'pinia';
import { useSwimmResource } from '@/common/composables/swimmResource';
import { useNavigate } from '../composables/navigate';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import ContentErrorState from '@/common/components/organisms/unavailable-doc-states/ContentErrorState.vue';
import ShareButton from '@/common/components/TopBar/ShareButton.vue';
import { PlaylistSequenceStepTypes } from '@/modules/playlists/types';
import { usePlaylist } from '@/modules/playlists/composables/playlist';
import { usePlaylistStore } from '@/modules/playlists/store';
import { usePageTitle } from '../composables/pageTitle';
import { onBeforeRouteUpdate, useRoute } from 'vue-router';
import { useScroll } from '@swimm/editor';
import DummyRepoBanner from '@/modules/demo/components/DummyRepoBanner.vue';
import { useRouting } from '@/common/composables/routing';
import ResourceFolder from '@/modules/folders/components/ResourceFolder.vue';
import { DocumentationTypes } from '@/common/consts';
import DocumentationOptionsMenu from '@/common/components/organisms/DocumentationOptionsMenu.vue';
import MoveFolderModal from '@/modules/folders/components/MoveFolderModal.vue';

interface PlaylistSequenceActiveStep extends SwmFile {
  repoId: string;
  isAvailable: boolean;
  repoDetails: {
    id: string;
    name: string;
    imgSrc: string;
    icon: string;
  };
}

onBeforeRouteUpdate((to, from, next) => {
  if (to.path === '/404') {
    next();
    return;
  }
  scrollToElementTop('main');
  next();
});

const props = defineProps({
  repoId: {
    type: String,
    required: true,
  },
  workspaceId: {
    type: String,
    required: true,
  },
  branch: {
    type: String,
    required: true,
  },
});

const store = useStore();
const route = useRoute();
const analytics = useAnalytics();
const { user } = storeToRefs(useAuthStore());
const { pageTitle } = usePageTitle();
const db_getSwimm = computed(() => store.getters['database/db_getSwimm']);
const db_getSwimmStatus = computed(() => store.getters['database/db_getSwimmStatus']);
const db_getRepoMetadata = computed(() => store.getters['database/db_getRepoMetadata']);
const db_getPlaylist = computed(() => store.getters['database/db_getPlaylist']);
const { isOnDummyRepoPage } = storeToRefs(useDemoStore());
const { isResourceEditable } = useSwimmResource();

const { navigateToPageAndTerminateWorker, getRepoPath } = useNavigate();
const { scrollToElementTop } = useScroll();

const playlistStore = usePlaylistStore();
const {
  playlistUnavailable,
  reposLoadingStatuses,
  loadingPlaylistData,
  currentPlaylist,
  progressBarSteps,
  currentStepIndex,
} = storeToRefs(playlistStore);

watch(
  () => currentPlaylist.value?.name,
  (playlistName) => {
    pageTitle.value = playlistName ?? 'Untitled Playlist';
  },
  {
    immediate: true,
  }
);

const { playlistId, selectStep } = usePlaylist();

const { assertRouting } = useRouting();
await assertRouting();

const showMoveFolderModal = ref(false);
const resourceToMove = ref();

const canEditPlaylist = computed<boolean>(() => !playlistUnavailable.value);

const isPlaylistEditable = computed<boolean>(() => {
  return (
    !loadingPlaylistData.value &&
    currentPlaylist.value &&
    isResourceEditable(props.repoId, playlistId.value, 'playlist')
  );
});

const isSwimmStep = computed<boolean>(() => {
  return !isIntro.value && !isSummary.value;
});

const isSummary = computed<boolean>(() => {
  return route.path.endsWith(`/summary`);
});
const isIntro = computed<boolean>(() => {
  return !route.params.stepIndex && !isSummary.value;
});

const resourceHeadlineType = computed(() => {
  return getResourceHeadlineType({
    isSwimmStep: isSwimmStep.value,
    activeStep: activeStep.value,
    isSummary: isSummary.value,
  });
});

const activeStep = computed<PlaylistSequenceActiveStep>(() => {
  if (loadingPlaylistData.value) {
    return {} as PlaylistSequenceActiveStep;
  }
  if (!isSwimmStep.value) {
    return { isAvailable: false } as PlaylistSequenceActiveStep;
  }
  const playlistStep = currentPlaylist.value.sequence
    ? currentPlaylist.value.sequence[route.params.stepIndex as string]
    : undefined;
  if (!playlistStep) {
    return { isAvailable: false } as PlaylistSequenceActiveStep;
  }

  const activeStepRepoMetadata = db_getRepoMetadata.value(playlistStep.repoId);
  if (!activeStepRepoMetadata) {
    return { ...playlistStep, isAvailable: false };
  }
  const repoDetails = {
    id: playlistStep.repoId,
    name: activeStepRepoMetadata.name,
    imgSrc: activeStepRepoMetadata.logo,
    icon: activeStepRepoMetadata.provider && getGitProviderIconName(activeStepRepoMetadata.provider),
  };

  let stepResource: SwmFile = null;
  if (playlistStep.type === PlaylistSequenceStepTypes.EXTERNAL_LINK) {
    stepResource = playlistStep;
  } else if (playlistStep.type === PlaylistSequenceStepTypes.PLAYLIST) {
    stepResource = { ...db_getPlaylist.value(playlistStep.repoId, playlistStep.id), type: playlistStep.type };
  } else {
    stepResource = db_getSwimm.value(playlistStep.repoId, playlistStep.id);
  }

  if (!stepResource || objectUtils.isEmpty(stepResource)) {
    return { ...playlistStep, repoDetails, isAvailable: false };
  }
  return { ...stepResource, repoId: playlistStep.repoId, repoDetails, isAvailable: true };
});

const activeStepId = computed(() => activeStep.value.id || '');

const activeStepTitle = computed(() => {
  return activeStep.value.name;
});

const activeStepStatus = computed<SwimmResourceUserStatus>(() => {
  return (
    !activeStepIsLoading.value &&
    !isIntro.value &&
    !isSummary.value &&
    db_getSwimmStatus.value(activeStep.value.repoId, user.value.uid, activeStep.value.id)
  );
});

const activeStepIsLoading = computed(() => {
  return (
    loadingPlaylistData.value ||
    (isSwimmStep.value &&
      (!reposLoadingStatuses.value[activeStep.value.repoId] ||
        reposLoadingStatuses.value[activeStep.value.repoId].loading))
  );
});

const playlistStatus = computed<string>(() => {
  if (playlistUnavailable.value) {
    return ApplicabilityStatus.Unavailable;
  }
  const statusFromDb = db_getSwimmStatus.value(props.repoId, user.value.uid, currentPlaylist.value.id);
  return statusFromDb || config.SWIMMER_STATUSES.NOT_STARTED;
});

const shouldHideHeader = computed(() => {
  return isStepDoc.value && activeStep.value && activeStep.value.isAvailable && !loadingPlaylistData.value;
});

const progressBarStepsContainer = computed(() => {
  return { name: currentPlaylist.value.name, iconName: 'playlist', type: 'Playlist' };
});

const isStepDoc = computed(() => {
  return isSwimmStep.value && !objectUtils.isEmpty(activeStep.value) && isSwmDoc({ swm: activeStep.value });
});
const reposLoading = computed(() => {
  return Object.values(reposLoadingStatuses.value).some((repo) => repo.loading);
});

function reportPageViewed() {
  const payload = {
    'Page Name': 'Playlist Page',
  };
  analytics.cloudPageViewed({
    identity: user.value.uid,
    event: pageEvents.LOADED_A_PAGE,
    payload,
  });
  analytics.track(pageEvents.LOADED_A_PAGE_MARKETING, payload);
}

function changeFolder() {
  showMoveFolderModal.value = true;
  const playlist = store.getters['database/db_getPlaylist'](props.repoId, playlistId.value);
  resourceToMove.value = [{ ...playlist, documentationType: DocumentationTypes.PLAYLIST }];
}

onMounted(() => {
  reportPageViewed();
});
</script>

<style scoped lang="postcss">
.active-step {
  padding: inherit;
  height: 100%;
  overflow-y: auto;
}

.step-header {
  display: flex;
  justify-content: space-between;
  padding-top: var(--space-xl);
  width: 100%;
  box-sizing: border-box;
}

.steps-unavailable {
  padding: 30px 0;
  text-align: center;
  color: var(--text-color-secondary);
}

.edit-playlist-button {
  cursor: pointer;
  padding: var(--space-base);
  border-radius: 4px;

  &:hover {
    background-color: var(--color-hover);
  }
}
</style>
