import matter from 'gray-matter';

/* parses swmd markdown (new or legacy) and returns the title */
export function parseSwmdTitleOnly(text: string): string | undefined {
  const file = matter(text, {
    // Only allow yaml
    engines: {
      yaml: matter.engines.yaml,
    },
  });
  // name was used in legacy swms in the past
  return file.data.title ?? file.data.name;
}
