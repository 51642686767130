<template>
  <div class="wrapper" data-testid="google-auth-button">
    <Action button-type="button" :loading="isLoading" class="button" secondary @click="onButtonClick">
      <GoogleIconSvg v-if="!isLoading" class="icon" />
      <slot />
    </Action>
    <ErrorBox v-if="error" class="error">{{ error }}</ErrorBox>
  </div>
</template>

<script>
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { connectUserToSlack } from '@/modules/slack-app/services/slack-app-utils';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { startCase } from 'lodash-es';
import GoogleIconSvg from './GoogleIconSvg.vue';
import { mapActions } from 'vuex';
import { config, getLoggerNew, productEvents } from '@swimm/shared';
import { MOBILE_MAX_WIDTH, PageRoutesNames } from '@/common/consts';
import { getSystemTheme } from '@/common/utils/theme-utils';
import { getSourcePropertiesFromLocalStorage } from '@/common/utils/helpers';
import { eventLogger } from '@swimm/shared';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { storeToRefs } from 'pinia';
import { ErrorBox } from '@swimm/ui';
import { setUserCreatedFieldInDb } from '@/common/utils/user-utils';
import { fetchSSOSetup } from '@/modules/core/composables/sso';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import { GitProviderName } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export default {
  components: { GoogleIconSvg, ErrorBox },
  emits: ['click'],
  setup() {
    const analytics = useAnalytics();
    const { logEvent } = useSwimmEventLogs();
    const authStore = useAuthStore();
    const { user } = storeToRefs(authStore);
    const { setUser } = authStore;
    const { authorizeProviderWithGit } = useGitAuthorizationStore();

    return { analytics, logEvent, user, setUser, authorizeProviderWithGit };
  },
  data() {
    return {
      isLoading: false,
      error: '',
    };
  },
  computed: {
    isIdeLogin() {
      const foundIndex = this.$route.query?.redirect?.indexOf('ide-login') ?? -1;
      return foundIndex !== -1;
    },
    isFromGHMarketplace() {
      return this.$route.query?.source === config.GH_MARKETPLACE_INDICATOR;
    },
    ghEmail() {
      return this.$route.query?.email ?? null;
    },
  },
  methods: {
    ...mapActions('database', ['fetchWorkspaceInvites']),
    async onButtonClick() {
      this.isLoading = true;
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      await firebase
        .auth()
        .signInWithPopup(googleProvider)
        .then(async (userDetails) => {
          const isNewUser = userDetails.additionalUserInfo.isNewUser;
          if (await fetchSSOSetup(userDetails.user.email)) {
            await firebase.auth().signOut();
            return this.$router.push({
              name: PageRoutesNames.SSO,
              query: { ...(this.$route.query || {}), email: userDetails.user.email },
              register: isNewUser,
            });
          }
          if (isNewUser) {
            await this.handeUserSignup();
            return this.$router.push({ name: PageRoutesNames.ONBOARDING_WELCOME, query: this.$route.query.redirect });
          } else {
            const user = await this.handleUserLogin();
            if (this.$route.query.source === config.SLACK_INDICATOR) {
              const success = await connectUserToSlack({
                userUid: user.uid,
                slackTeamId: this.$route.query.team,
                slackUserId: this.$route.query.user,
              });
              if (!success) {
                this.$route.query['error'] = config.SLACK_INDICATOR;
              }
            }
            let routeParams;
            if (this.isIdeLogin) {
              routeParams = this.$route.query.redirect;
            } else {
              const { redirect, ...rest } = this.$route.query;
              routeParams = { path: redirect || '/', query: rest };
            }
            return this.$router.push(routeParams).catch(() => {
              return;
            });
          }
        })
        .then(() => {
          if (this.isFromGHMarketplace) {
            this.authorizeProviderWithGit({ provider: GitProviderName.GitHub, origin: 'Google login' });
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.error = 'Unable to get account information from Google, please try again';
        });
      this.$emit('click');
    },
    async handeUserSignup() {
      const user = firebase.auth().currentUser;
      const userInvites = await this.fetchWorkspaceInvites();
      const isInvited = !!userInvites.length;
      this.setUser({
        ...user,
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        isRegistrationEvent: true,
        query: this.$route.query,
        isInvited: isInvited,
      });
      await setUserCreatedFieldInDb(user.uid);
      this.logEvent(
        eventLogger.SWIMM_EVENTS.USER_SIGN_UP,
        { srcId: user.uid, srcName: user.displayName, isJoining: isInvited },
        { uid: user.uid, nickname: user.displayName, email: user.email }
      );
      this.analytics.initAnalytics({
        uid: user.uid,
        nickname: user.displayName,
        email: user.email,
        creationTime: user.metadata.creationTime,
        lastSignInTime: user.metadata.lastSignInTime,
        browserSystemTheme: getSystemTheme(),
        sourceProps: getSourcePropertiesFromLocalStorage({ keyFormatCallback: startCase, valueFallback: '' }),
      });
      const payload = {
        Origin: 'Signup page',
        'Origin URL': this.$route.fullPath,
        'Signup Method': 'Google',
        'Signup Type': 'Direct', // User cannot sign up with Google when registering via invite link
        'Is Invited': userInvites.length ? 'True' : 'False',
        'Is From GitHub Marketplace': this.isFromGHMarketplace,
        'GitHub Email': this.ghEmail,
        'User Email': user.email,
        ...getSourcePropertiesFromLocalStorage({ keyFormatCallback: startCase, valueFallback: '' }),
      };
      this.analytics.cloudTrack({
        identity: user.uid,
        event: productEvents.USER_SIGNED_UP,
        payload,
      });
      this.analytics.track(productEvents.USER_SIGNED_UP_MARKETING, { ...payload, user_id: user.uid });
      this.analytics.reportHubspotSignUp({
        email: user.email,
        name: user.displayName,
      });

      this.sendWelcomeEmail();
    },
    async sendWelcomeEmail() {
      try {
        const isMobile = screen.width <= MOBILE_MAX_WIDTH;
        logger.debug(`Sending welcome email, uid: ${this.user.uid}`);
        await CloudFunctions.sendWelcomeEmail({
          isWeb: true,
          isMobile,
        });
      } catch (err) {
        logger.error({ err }, `Failed to send welcome email, uid: ${this.user.uid}, error: ${err}`);
      }
    },
    async handleUserLogin() {
      const user = firebase.auth().currentUser;
      this.logEvent(
        eventLogger.SWIMM_EVENTS.USER_SIGN_IN,
        { srcId: user.uid, srcName: user.displayName },
        { uid: user.uid, nickname: user.displayName }
      );
      this.analytics.initAnalytics({
        uid: user.uid,
        nickname: user.displayName,
        email: user.email,
        creationTime: user.metadata.creationTime,
        lastSignInTime: user.metadata.lastSignInTime,
        browserSystemTheme: getSystemTheme(),
      });
      this.analytics.cloudTrack({
        identity: user.uid,
        event: productEvents.USER_LOGGED_IN,
        payload: {
          'Login Method': 'Google',
        },
      });

      this.analytics.reportHubspotSignIn({
        email: user.email,
      });

      return user;
    },
  },
};
</script>

<style scoped>
.wrapper .button {
  padding: 8px 0;
  width: 100%;
}

.icon {
  margin-right: 8px;
}

.error {
  margin-top: 8px;
  text-align: left;
}
</style>
