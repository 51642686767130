<template>
  <div class="field">
    <BaseLabel v-if="label" :required="required" :html-for="label">{{ label }}</BaseLabel>
    <slot name="label"></slot>
    <div
      class="field-input"
      :class="{ border, error, disabled, headline, 'focused-border': focusedBorder, 'has-button': hasButton }"
    >
      <slot name="left"></slot>
      <input
        :id="inputId"
        data-testid="text-field-input"
        ref="field"
        v-tooltip="tooltip"
        :maxlength="maxlength"
        :readonly="readonly"
        :type="type"
        :value="modelValue"
        :class="{ headline }"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        @input="onInput"
        @keyup="onKeyUp"
        @keyup.enter="onEnter"
        @keydown="onKeyDown"
        @focus="onFocus"
        @blur="$emit('blur')"
      />
      <slot></slot>
      <slot name="right"></slot>
    </div>
    <ErrorBox v-if="error && error.trim()" class="error-message" data-testid="error">{{ error }}</ErrorBox>
    <div v-if="description" class="description">{{ description }}</div>
  </div>
</template>

<script>
import { shortUuid } from '@swimm/shared';
import BaseLabel from './BaseLabel.vue';
import ErrorBox from './ErrorBox.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { BaseLabel, ErrorBox },
  props: {
    id: { type: String, default: '' },
    label: { type: String, default: '' },
    tooltip: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    focusFirst: { type: Boolean, required: false },
    selectOnFocus: { type: Boolean, default: false },
    type: {
      type: String,
      default: 'text',
      validator: (typeValue) => ['text', 'email', 'password', 'number'].includes(typeValue),
    },
    modelValue: {
      type: [String, Number],
      default: (props) => (props.type === 'number' ? 0 : ''),
    },
    maxlength: { type: Number, default: 524288 },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, required: false },
    headline: { type: Boolean, default: false },
    border: { type: Boolean, default: true },
    focusedBorder: { type: Boolean, default: true },
    onEnter: {
      type: Function,
      required: false,
      default: () => {
        return () => {
          return;
        };
      },
    },
    error: { type: String, default: '' },
    description: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    hasButton: { type: Boolean, default: false },
  },
  emits: ['input', 'key-up', 'key-down', 'key-esc', 'update:modelValue', 'focus', 'blur'],
  computed: {
    inputId() {
      if (this.id) {
        return this.id;
      } else if (this.label) {
        return `${this.label.replace(/[ ]/g, '-').toLowerCase()}-${shortUuid()}`;
      }
      return null;
    },
  },
  mounted() {
    if (this.focusFirst) {
      this.$nextTick(() => {
        this.focus();
      });
    }
  },
  methods: {
    focus() {
      this.$refs.field.focus();
    },
    select() {
      this.$refs.field.select();
    },
    onFocus() {
      this.$emit('focus');
      if (this.selectOnFocus) {
        this.select();
      }
    },
    onKeyDown(event) {
      this.$emit('key-down', event);
    },
    onKeyUp(event) {
      if (event.key === 'Escape') {
        this.$emit('key-esc', event);
      }
      this.$emit('key-up', event);
    },
    onInput($event) {
      const value = $event.target.value;
      if (String(value).length <= this.maxlength) {
        this.$emit('update:modelValue', value);
      }
    },
  },
});
</script>

<style scoped>
input {
  margin-right: var(--space-sm);
  padding: 5px 0;
  width: 100%;
  font-size: inherit;
  font-weight: inherit;
  font-family: var(--fontfamily-main);
  border: none;
  color: var(--text-color-primary);
  caret-color: var(--text-color-primary);
  background-color: transparent;
  outline: none;
}

input[readonly] {
  color: var(--text-color-disable);
  cursor: default;
}

input::placeholder {
  color: var(--text-color-primary);
  opacity: 0.3;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px var(--color-surface) inset;
  box-shadow: 0 0 0 50px var(--color-surface) inset;
  -webkit-text-fill-color: var(--text-color-primary);
  color: var(--text-color-primary);
  padding-left: var(--space-sm);
  margin-right: 0;
  border-radius: 4px;
}

input[type='number']::-webkit-inner-spin-button {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  margin: 0;
}

.field {
  text-align: left;
  font-size: var(--body-L);
  flex: 1;
}

.field-input {
  display: flex;
  align-items: center;
  padding-left: var(--space-sm);
  width: 100%;
  height: inherit;
  border-radius: 4px;
  background-color: var(--color-bg);
  box-sizing: border-box;
  border: 1px solid var(--color-border-default);
}

.field-input:hover {
  border: 1px solid var(--color-border-default-strong);
}

.border {
  border: 1px solid var(--color-border-default);
}

.border:focus-within {
  border-color: var(--color-border-default);
}

.focused-border:focus-within {
  border-color: var(--color-border-brand);
}

.field-input.border.error,
.field-input.border.error:focus-within {
  border-color: var(--color-border-danger);
}

.field-input:has(input:-webkit-autofill) {
  padding-left: 0;
}

.error-message {
  margin-top: 8px;
}

.disabled {
  border-color: var(--color-disable);
  color: var(--text-color-secondary);
  background-color: var(--color-disable);
}

.headline {
  padding-left: 0px;
  font-size: var(--fontsize-xl);
  font-weight: bold;
  border: none;
  background: transparent;
  outline: none;
}

.description {
  margin-top: 8px;
  margin-left: 5px;
  font-size: var(--body-XS);
  color: var(--text-color-secondary);
}

.has-button {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border-right: none;
}
</style>
