<script setup>
import FolderPath from '@/modules/folders/components/FolderPath.vue';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { computed, ref } from 'vue';
import { Icon, SwText } from '@swimm/ui';
import EllipsisOption from '@/common/components/atoms/EllipsisOption.vue';
import { copyToClipboardImpl } from '@swimm/editor';
import { GitProviderIcons, unifyMatchesByValueAndSplit } from '@swimm/shared';
import { useRouter } from 'vue-router';

const { getItemParentFolder } = useFoldersStore();

const props = defineProps({
  result: { type: Object, required: true },
  query: { type: String, required: true },
  focused: { type: Boolean, default: false },
  repoId: { type: String, default: null },
});
const emit = defineEmits(['select']);

const router = useRouter();

const shouldShowSettings = ref(false);
const copiedToClipboard = ref(false);

const swmName = computed(() => props.result.item.name);
const swmRepo = computed(() => props.result.item.repoName);
const swmType = computed(() => props.result.item.type);
const swmLink = computed(() => props.result.item.link);
const swmRepoId = computed(() => props.repoId);
const swmRepoIcon = computed(() => GitProviderIcons[props.result.repoProvider]);
const swmEditLink = computed(() => `${swmLink.value}/edit`);
const folderId = computed(() => getItemParentFolder(props.result.item.id, swmRepoId.value)?.id ?? '');
const matchOnlyTitle = computed(() => props.result.matches.every((match) => match.key === 'name'));
const markedTitleHtml = computed(() => {
  const nameMatches = unifyMatchesByValueAndSplit(
    props.result.matches.filter((match) => match.key === 'name'),
    { truncate: false }
  );
  if (nameMatches.length) {
    return nameMatches[0];
  }
  return [{ text: swmName.value, highlighted: false }];
});

const markedOccurrencesHtml = computed(() => {
  return unifyMatchesByValueAndSplit(
    props.result.matches.filter((match) => match.key === 'content'),
    { truncate: true }
  );
});

function closeSettingsMenu() {
  shouldShowSettings.value = false;
  copiedToClipboard.value = false;
}

function copyLink() {
  copyToClipboardImpl(`${window.location.origin}${swmLink.value}`);
  if (shouldShowSettings.value) {
    copiedToClipboard.value = true;
    setTimeout(() => (copiedToClipboard.value = false), 2000);
  }
}

function onEditClick() {
  emit('select');
  router.push(swmEditLink.value);
}
</script>

<template>
  <router-link :to="swmLink" @click="emit('select')">
    <div :class="['result', { focused: focused }]">
      <Icon :name="swmType" no-padding class="type-icon" />
      <SwText variant="body-L" class="title" data-testid="search-result-title">
        <span
          v-for="(span, index) in markedTitleHtml"
          :class="{ highlight: span.highlighted }"
          :key="`${span.text}:${index}`"
          >{{ span.text }}</span
        >
      </SwText>
      <div v-if="!matchOnlyTitle" class="result-matches">
        <SwText variant="body-S" v-for="(match, index) in markedOccurrencesHtml" :key="index">
          <span
            v-for="(span, index1) in match"
            :class="{ 'highlight-content': span.highlighted }"
            :key="`${span.text}:${index1}`"
            >{{ span.text }}</span
          >
          <span v-if="index !== markedOccurrencesHtml.length - 1">...</span></SwText
        >
      </div>
      <FolderPath
        v-if="swmRepo"
        :folder-id="folderId"
        :repo-icon="swmRepoIcon"
        :repo-name="swmRepo"
        :repo-id="swmRepoId"
        class="result-folder"
      />
      <VMenu
        class="ellipsis"
        theme="menu-no-arrow"
        placement="bottom-end"
        :triggers="[]"
        :delay="0"
        :shown="shouldShowSettings"
        @hide="shouldShowSettings = false"
        :auto-hide="false"
      >
        <Icon class="icon" name="more" @click.stop.prevent="shouldShowSettings = true" />
        <template #popper>
          <div @mouseleave="closeSettingsMenu">
            <EllipsisOption
              class="option"
              @click="onEditClick"
              name="Edit"
              icon="edit-outline"
              large-content
            ></EllipsisOption>
            <EllipsisOption
              class="option"
              v-tooltip.bottom="{ content: 'Link copied', shown: copiedToClipboard, triggers: ['manual'] }"
              name="Copy link"
              icon="link"
              :handler="copyLink"
              large-content
            ></EllipsisOption>
          </div>
        </template>
      </VMenu>
    </div>
  </router-link>
</template>

<style scoped lang="postcss">
.result {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'icon title menu'
    '. content content'
    '. repo repo';
  grid-gap: var(--space-base) var(--space-base);
  align-items: center;
  padding: var(--space-base);
  margin-bottom: var(--space-base);
  border-radius: 8px;

  &:hover {
    background-color: var(--color-surface);
  }

  &.focused {
    background-color: var(--color-surface);
  }

  .type-icon {
    grid-area: icon;
    font-size: var(--fontsize-s);
    color: var(--text-color-secondary);
  }

  .title {
    grid-area: title;

    .highlight {
      color: var(--text-color-link);
    }
  }

  .result-matches {
    grid-area: content;
    overflow: hidden;
    max-height: 4ex;
    white-space: break-spaces;

    .highlight-content {
      color: var(--text-color-link);
    }
  }

  .result-folder {
    grid-area: repo;
    color: var(--text-color-secondary);
  }

  .ellipsis {
    opacity: 0;
    grid-area: menu;

    .option {
      padding: var(--space-base);
    }
  }

  &:hover .ellipsis {
    opacity: 1;
  }

  .repo-icon {
    color: var(--text-color-primary);
    font-size: var(--fontsize-s);
  }
}
</style>
