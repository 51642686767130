<template>
  <div
    :class="['inbox', { new: unseenCount }, { showing: showMessages }]"
    :data-unseen-count="unseenCount"
    data-testid="notifications-button"
    v-click-outside="
      () => {
        showMessages = false;
      }
    "
    @click="toggleMessages"
  >
    <SidebarButton
      v-tooltip="{
        content: notificationTooltip,
        placement: 'right',
      }"
    >
      <Icon name="bell" class="notification-icon" data-testid="notifications-bell" />
    </SidebarButton>
  </div>
  <div
    v-if="showMessages"
    class="dropdown shadowed"
    data-testid="notifications-dropdown"
    ref="container"
    tabindex="0"
    @keydown.esc="showMessages = false"
  >
    <div class="header">
      Notifications
      <IconButton name="close" data-testid="notifications-close" @click="closeMessages" />
    </div>
    <div class="notifications" data-testid="notifications-list">
      <div v-for="(notification, notificationIndex) in notifications" :key="notification.id" class="notification">
        <component
          :is="getNotificationComponent(notification.type)"
          :notification="notification"
          :data-testid="`notifications-component-${notificationIndex}`"
          @invite="openAddUsersModal"
          @report-click="reportClick(notification)"
        />
      </div>
      <div v-if="!notifications.length" class="empty" data-testid="notifications-empty">There are no notifications</div>
    </div>
  </div>
</template>

<script>
import { getLoggerNew, productEvents, workspaceSettingsTabs } from '@swimm/shared';
import { IconButton } from '@swimm/ui';
import { mapActions, mapGetters } from 'vuex';
import { nextTick, ref } from 'vue';
import { getComponentFromNotificationType, getNotificationComponents } from '../services/notifications';
import { subscribeToUserNotifications } from '@/modules/notifications-center/services/subscribe';
import { useAnalytics } from '@/common/composables/useAnalytics';
import SidebarButton from '@/common/components/organisms/SidebarButton.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useWorkspaceSettingsModalStore } from '@/modules/workspace/modals/settings/store/workspace-settings';
import { pluralize } from '@/common/utils/helpers';

const logger = getLoggerNew(__modulename);

export default {
  components: { ...getNotificationComponents(), SidebarButton, IconButton },
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const { openWorkspaceSettingsModal } = useWorkspaceSettingsModalStore();
    const analytics = useAnalytics();
    const container = ref();

    return { user, analytics, workspaceSettingsTabs, container, openWorkspaceSettingsModal };
  },
  data() {
    return {
      showMessages: false,
      fetchOnClose: false,
    };
  },
  computed: {
    ...mapGetters('database', ['db_getNotifications']),
    unseen() {
      return this.notifications.filter((notification) => !notification.seen);
    },
    unseenCount() {
      return this.unseen.length > 99 ? '99+' : this.unseen.length;
    },
    notifications() {
      return this.db_getNotifications().sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
    },
    notificationTooltip() {
      return this.unseenCount
        ? `${this.unseenCount} unread ${pluralize({ word: 'notification', count: this.unseenCount })}`
        : 'No unread notifications';
    },
  },
  watch: {
    showMessages() {
      if (this.showMessages) {
        nextTick(() => {
          if (this.container) {
            this.container.focus();
          }
        });
      }
    },
  },
  mounted() {
    this.fetchUserNotifications();
    this.unsubscribeArray = subscribeToUserNotifications({
      user: this.user,
      callback: this.fetchUserNotificationsIfClosed,
    });
    logger.info(`Start listen to user notifications x ${this.unsubscribeArray.length}`);
  },
  beforeUnmount() {
    if (this.unsubscribeArray) {
      logger.info(`Unsubscribe from listen to user notifications x ${this.unsubscribeArray.length}`);
      for (const unsubscribe of this.unsubscribeArray) {
        try {
          unsubscribe();
        } catch (err) {
          logger.error({ err }`Failed to unsubscribe for user notification subscription`);
        }
      }
    }
    this.unsubscribeArray = null;
  },
  methods: {
    ...mapActions('database', ['fetchUserNotifications']),
    reportClick(notification) {
      this.analytics.track(productEvents.CLICKED_IN_APP_NOTIFICATION, {
        Type: 'In-App',
        Origin: 'Notifications Menu',
        Context: notification.type,
        'Notification ID': notification.id,
        'Workspace ID': this.$route.params.workspaceId,
        'Repo ID': this.$route.params.repoId,
      });
    },
    toggleMessages() {
      this.showMessages = !this.showMessages;
      if (!this.showMessages) {
        this.closeMessages();
      }
    },
    closeMessages() {
      this.showMessages = false;
      // We fetch the notifications so red dots will be updated
      // for the next time
      if (this.fetchOnClose) {
        this.fetchUserNotifications();
        this.fetchOnClose = false;
      }
    },
    getNotificationComponent(notificationType) {
      return getComponentFromNotificationType(notificationType);
    },
    fetchUserNotificationsIfClosed() {
      if (!this.showMessages) {
        this.fetchUserNotifications();
      } else {
        this.fetchOnClose = true;
      }
    },
    openAddUsersModal() {
      this.openWorkspaceSettingsModal({
        initialTabCode: workspaceSettingsTabs.MEMBERS,
      });
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  font-size: var(--fontsize-m);
  font-weight: 700;
  color: var(--text-color-primary);
}

.notifications {
  overflow-y: auto;
  max-height: 56vh; /* 4 notification items */
}

.inbox.new::before {
  position: absolute;
  top: 12px;
  right: 16px;
  z-index: 1;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 8px;
  height: 16px;
  font-size: var(--body-XXS);
  border-radius: 50px;
  border: 1px solid var(--clear-white);
  text-align: center;
  color: var(--text-color-on-dark);
  background-color: var(--color-border-danger);
  content: attr(data-unseen-count) ' ';
  transform: translateX(50%) translateY(-50%);
  cursor: pointer;
  font-weight: bold;
}

.inbox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
}

.notification-icon {
  position: relative;
  color: var(--text-color-on-dark);
  font-size: var(--fontsize-m);
  padding: 0;
}

.dropdown {
  outline: none;
  position: fixed;
  bottom: 88px;
  left: 54px;
  z-index: 100;
  display: flex;
  margin-right: 60px;
  width: 355px;
  max-height: 70vh;
  border: 1px solid var(--color-border-default);
  border-radius: 10px;
  background-color: var(--color-bg);
  flex-direction: column;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12), 0px 8px 32px rgba(0, 0, 0, 0.16), 0px 0px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.dropdown .header {
  padding: 15px;
  font-family: var(--fontfamily-main);
  border-bottom: 1px solid var(--color-border-default);
}

.empty {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  height: 65px;
  line-height: 65px;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}
</style>
