<template>
  <Action
    :variant="variant"
    size="small"
    :style="style"
    class="copy-button"
    :class="{ 'no-padding': props.noPadding }"
    @click="copyToClipboard(content)"
  >
    <template v-if="copiedToClipboard">
      <Icon name="check" no-padding class="headline3 icon" />
      Copied
    </template>
    <template v-else>
      <Icon name="copy" no-padding class="headline3 icon" />
      Copy
    </template>
  </Action>
</template>

<script setup lang="ts">
import { useCopyToClipboard } from '@swimm/editor';
import { ActionVariants } from '@swimm/ui';
import type { PropType } from 'vue';

const props = defineProps({
  content: { type: String, required: true },
  style: { type: String, default: undefined, required: false },
  variant: {
    type: String as PropType<ActionVariants>,
    default: 'secondary',
  },
  noPadding: { type: Boolean, required: false, default: false },
});

const { copiedToClipboard, copyToClipboard } = useCopyToClipboard();
</script>

<style scoped>
.icon {
  padding-right: 0;
  font-weight: normal;
}

.no-padding {
  padding: 0;
}

.copy-button {
  gap: var(--space-xs);
}
</style>
