import { SwmSymbolType, symbolsUtils } from '@swimm/shared';

export function updateSymbols(unit) {
  // Remove symbols that are not used anymore
  const symbols = Object.keys(unit.symbols);
  if (symbols.length > 0) {
    symbols.forEach((symbol) => updateSymbol(unit, symbol, stringifySymbols(unit)));
  }
}

function stringifySymbols(unit) {
  let swmCommentsString = JSON.stringify(
    unit.content.map((content) => content.comments || content.text || content.content)
  );
  const tableTokens = [];
  unit.content.forEach((cell) => {
    if (cell.type === 'table') {
      tableTokens.push(cell.headers);
      if (cell.table.length > 0) {
        tableTokens.push(cell.table);
      }
    }
  });
  swmCommentsString = swmCommentsString + JSON.stringify(tableTokens.flat());
  return swmCommentsString;
}

function updateSymbol(unit, symbol, swmCommentsString) {
  if (!symbol || !unit.symbols || !unit.symbols[symbol]) {
    return;
  }

  const finalSwmString = swmCommentsString || stringifySymbols(unit);
  const symbolObj = unit.symbols[symbol];
  let symbolRegex = null;
  switch (symbolObj.type) {
    case SwmSymbolType.PATH:
      symbolRegex = symbolsUtils.getSymbolPathRegex(symbol);
      break;
    case SwmSymbolType.GENERIC_TEXT:
      symbolRegex = symbolsUtils.getSymbolTextRegex(symbol); // Use a fuzzy fallback regex to validate token is missing / existing and not the exact match to protect against smart text rename
      break;
    case SwmSymbolType.LINK:
      symbolRegex = symbolsUtils.getSymbolLinkRegex(symbol);
      break;
    case SwmSymbolType.MENTION:
      symbolRegex = symbolsUtils.getMentionRegex(symbol);
      break;
    case SwmSymbolType.TEXT_PLACEHOLDER: {
      // JSON.stringify so that text matches the finalSwmString (escaping is added)
      const stringifiedText = JSON.stringify(symbolObj.text);
      // Slice to remove the quotations ("") the JSON.stringify added
      const symbolText = stringifiedText.slice(1, -1);
      symbolRegex = symbolsUtils.getTextPlaceholderRegex(symbolText, symbol);
      break;
    }
  }
  if (!finalSwmString.match(symbolRegex)) {
    // This.tempDeletedSymbols[symbol] = unit.symbols[symbol];
    delete unit.symbols[symbol];
  }
}
