<template>
  <div>
    <input
      ref="imageInput"
      data-testid="fileUploadInput"
      class="hidden-picker"
      type="file"
      :directory="directory"
      :webkitdirectory="directory"
      :accept="accept"
      :multiple="multiple"
      @change="handleFilesPicked"
    />
    <slot />
  </div>
</template>

<script lang="ts">
import { asyncSleep } from '../common/utils';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    multiple: { type: Boolean, default: false },
    directory: { type: Boolean, default: false },
    accept: { type: String, default: 'image/*' },
  },
  data(): { pickResolve: ((value: unknown) => void) | null } {
    return {
      pickResolve: null,
    };
  },
  emits: ['file-picked'],
  methods: {
    openPicker() {
      return new Promise((resolve) => {
        this.pickResolve = resolve;
        (this.$refs.imageInput as HTMLInputElement).click();
        document.body.onfocus = this.checkForCancel;
      });
    },
    async checkForCancel() {
      if (!(this.$refs.imageInput as HTMLInputElement).value.length) {
        // Wait for the file picker to finish picking and then check for cancel
        await asyncSleep(500);
        (this.$refs.imageInput as HTMLInputElement).dispatchEvent(new Event('change'));
      }
      document.body.onfocus = null;
    },
    async handleFilesPicked(event: Event) {
      const filesArray = Array.from((event.target as HTMLInputElement).files ?? []);
      /**
       * You can either await the openPicker function or listen for the following emit
       */
      if (this.pickResolve) {
        this.pickResolve(filesArray);
      }
      this.$emit('file-picked', filesArray);
      document.body.onfocus = null;
      /**
       * Notice - we await pickResolve for the pick to happen before this reset of the inputs value
       */
      (this.$refs.imageInput as HTMLInputElement).value = '';
    },
  },
});
</script>

<style scoped>
.hidden-picker {
  display: none;
}
</style>
