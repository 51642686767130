import { UrlUtils, decodeString, encodeString, getLoggerNew } from '@swimm/shared';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { type PlaylistProgressBarStep, PlaylistSequenceStepTypes } from '@/modules/playlists/types';
import type { Playlist, PlaylistSequenceStep } from '@/modules/playlists/types';
import { config } from '@swimm/shared';
import type { SwimmResourceUserStatus } from '@swimm/shared';
import { useNavigate } from '@/common/composables/navigate';
import { useInitData } from '@/common/composables/initData';
import { useSwimmResource } from '@/common/composables/swimmResource';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';

export function usePlaylist() {
  const store = useStore();
  const route = useRoute();
  const drafts3Store = useDrafts3Store();
  const playlistId = computed(() => route.params.playlistId as string);
  const { navigateToPageAndTerminateWorker, getRepoPath } = useNavigate();
  const { setPlaylistData } = useInitData();
  const { getResourceIconByState } = useSwimmResource();
  const { user } = storeToRefs(useAuthStore());
  const db_getPlaylist = computed(() => store.getters['database/db_getPlaylist']);
  const db_getSwimm = computed(() => store.getters['database/db_getSwimm']);
  const db_getSwimmStatus = computed(() => store.getters['database/db_getSwimmStatus']);
  const fs_isUnitFileInRepoPath = computed(() => store.getters['filesystem/fs_isUnitFileInRepoPath']);

  const logger = getLoggerNew(__modulename);

  async function parseDBPlaylist(repoId = <string>route.params.repoId, playlistId = <string>route.params.playlistId) {
    // This function handle the migration
    const dbPlaylist = db_getPlaylist.value(repoId, playlistId);

    // Load all docs so that we can find our steps.
    // FIXME: Load only the required docs, and don't fetch if we've already fetched.
    await store.dispatch('database/fetchRepoChildren', { repoId: repoId, children: ['swimms', 'playlists'] });

    // For playlists DAC we don't save the sequence to the DB,
    // therefore we skip them.
    // TODO: handle unavailable playlist
    if (!dbPlaylist || !dbPlaylist.sequence) {
      return null;
    }

    const playlist: Playlist = {
      name: dbPlaylist.name,
      id: dbPlaylist.id,
      sequence: [],
    };
    if (dbPlaylist.description) {
      playlist.description = decodeString(dbPlaylist.description);
    }
    if (dbPlaylist.summary) {
      playlist.summary = decodeString(dbPlaylist.summary);
    }
    if (dbPlaylist.sequence.length > 0) {
      const sequenceNewStructure: PlaylistSequenceStep[] = [];
      dbPlaylist.sequence.forEach((step) => {
        // Before the playlist DAC changes and the support of cross repo playlist sequence hold list of swimm id (including external links)
        // all only from the same current repo.
        // it is also possible the playlists already created with its new structure but it is not in the repo (this branch)
        if (typeof step === 'string') {
          // The step is a swimm id
          const swimm = db_getSwimm.value(repoId, step);
          if (swimm.type === PlaylistSequenceStepTypes.EXTERNAL_LINK) {
            sequenceNewStructure.push({
              id: step,
              repoId,
              name: swimm.name,
              url: swimm.url,
              type: PlaylistSequenceStepTypes.EXTERNAL_LINK,
            });
          } else {
            sequenceNewStructure.push({
              id: step,
              repoId,
              name: swimm.name,
              type: PlaylistSequenceStepTypes.SWIMM,
            });
          }
        } else if (typeof step === 'object') {
          if (step.url) {
            sequenceNewStructure.push({
              id: encodeString(step.url),
              url: step.url,
              name: step.name,
              type: PlaylistSequenceStepTypes.EXTERNAL_LINK,
              repoId,
            });
          } else {
            // Its a doc
            sequenceNewStructure.push({
              id: step.id,
              repoId: step.repoId,
              name: step.name,
              type: PlaylistSequenceStepTypes.SWIMM,
            });
          }
        }
      });
      playlist.sequence = sequenceNewStructure;
    }
    return playlist;
  }

  async function loadStepsInBackground({ sequence }: Playlist) {
    const swmsToLoad = sequence.filter((step) => {
      return (
        step.type && (step.type === PlaylistSequenceStepTypes.SWIMM || step.type === PlaylistSequenceStepTypes.PLAYLIST)
      );
    });
    for (const swm of swmsToLoad) {
      if (swm.type === PlaylistSequenceStepTypes.PLAYLIST) {
        try {
          setPlaylistData({
            repoId: swm.repoId,
            playlistId: swm.id,
          });
        } catch (err) {
          logger.warn(`Failed in setPlaylistData: ${err}`);
        }
      }
    }
  }

  function getStepIcon({
    stepResource,
    isAvailable = true,
    isEdit,
  }: {
    stepResource: PlaylistSequenceStep;
    isAvailable: boolean;
    isEdit: boolean;
  }) {
    return getResourceIconByState({
      resource: stepResource,
      repoId: stepResource.repoId,
      status: getStepStatus({ resource: stepResource, isEdit }),
      isPlaylist: true,
      isExplicitlyUnavailable: !isAvailable,
    });
  }

  function getStepBreadcrumbIcon(step: PlaylistSequenceStep) {
    switch (step.type) {
      case 'swimm':
      case 'unit':
        return 'doc';
      case 'external_link':
        return step.url ? UrlUtils.getIconName(step.url) : 'link';
      case 'playlist':
        return 'playlist';
    }
    return undefined;
  }

  function getStepStatus({
    resource,
    isEdit,
  }: {
    isEdit: boolean;
    resource: PlaylistSequenceStep;
  }): SwimmResourceUserStatus {
    const swimmStatus = db_getSwimmStatus.value(resource.repoId, user.value.uid, resource.id);
    // On edit more we ignore the users progress
    if (isEdit || !swimmStatus) {
      return config.SWIMMER_STATUSES.NOT_STARTED;
    }
    return swimmStatus.split(' ').join('-');
  }

  function getStepLink({
    playlistId,
    index,
    isEdit,
    repoId,
  }: {
    playlistId: string;
    isEdit: boolean;
    index: number;
    repoId?: string;
  }) {
    let playlistLink = '';
    if (isEdit) {
      playlistLink = `${playlistId}/edit`;
    } else {
      playlistLink = `${playlistId}/steps/${index}`;
    }
    return `${repoId ? getRepoPath(repoId) : getRepoPath()}/playlists/${playlistLink}`;
  }

  function selectStep(step: PlaylistSequenceStep | { id: 'intro'; link?: null }) {
    let linkToStep = step.link;
    if (!linkToStep) {
      const linkToPlaylist = `${getRepoPath()}/playlists/${playlistId.value}`;
      linkToStep = `${linkToPlaylist}`;
      if (step.id === 'summary') {
        linkToStep = `${linkToPlaylist}/summary`;
      }
    }
    if (route.path !== linkToStep) {
      navigateToPageAndTerminateWorker({ newRoute: linkToStep });
      return;
    }
  }

  function parseSequenceStepsForList({
    playlist,
    isEdit,
    repoId,
  }: {
    playlist: Playlist;
    isEdit: boolean;
    repoId?: string;
  }): PlaylistProgressBarStep[] {
    return playlist.sequence.map((step, stepIndex) => {
      if (step.type === PlaylistSequenceStepTypes.EXTERNAL_LINK) {
        return getStepForList({
          playlistId: playlist.id,
          isEdit,
          step,
          stepIndex,
          isAvailable: true,
        });
      }
      if (isEdit) {
        const stepDraft = drafts3Store.drafts?.get(step.id);
        if (stepDraft) {
          return getStepForList({
            playlistId: playlist.id,
            isEdit,
            step: { ...step, name: drafts3Store.getDraftTitle(stepDraft), isDraftLink: true },
            stepIndex,
            isAvailable: true,
          });
        }
      }

      let resourceFromDb;
      if (step.type === PlaylistSequenceStepTypes.PLAYLIST) {
        resourceFromDb = db_getPlaylist.value(step.repoId, step.id);
      } else {
        resourceFromDb = db_getSwimm.value(step.repoId, step.id);
      }
      if (resourceFromDb) {
        const isAvailable = fs_isUnitFileInRepoPath.value(step.id, step.repoId);
        return getStepForList({
          playlistId: playlist.id,
          isEdit,
          step: {
            ...resourceFromDb,
            repoId: step.repoId,
            name: step.name,
            type: resourceFromDb.type || step.type,
          },
          stepIndex,
          isAvailable,
          repoId,
        });
      } else {
        return getStepForList({
          playlistId: playlist.id,
          isEdit,
          step,
          stepIndex,
          repoId,
          isAvailable: false,
        });
      }
    });
  }

  function getStepForList({
    playlistId,
    step,
    stepIndex,
    isAvailable,
    isEdit,
    repoId,
  }: {
    playlistId: string;
    step: PlaylistSequenceStep;
    stepIndex: number;
    isAvailable: boolean;
    isEdit: boolean;
    repoId?: string;
  }): PlaylistProgressBarStep {
    return {
      resource: step,
      status: getStepStatus({ resource: step, isEdit }),
      repoId: step.repoId,
      link: getStepLink({ playlistId, index: stepIndex, isEdit, repoId }),
      iconName: getStepIcon({ stepResource: { ...step }, isAvailable, isEdit }),
      isAvailable,
    };
  }

  return {
    playlistId,
    parseSequenceStepsForList,
    parseDBPlaylist,
    loadStepsInBackground,
    getStepIcon,
    getStepBreadcrumbIcon,
    getStepStatus,
    selectStep,
  };
}
