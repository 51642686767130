<script setup lang="ts">
import { Icon } from '..';

const props = defineProps({
  name: { type: String, required: true },
  noPadding: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
});

const emits = defineEmits(['click']);

function onClick(event: Event) {
  if (props.disabled) {
    return;
  }

  emits('click', event);
}
</script>

<template>
  <Icon :name="name" :no-padding="noPadding" :class="{ disabled }" class="icon-button" @click="onClick" />
</template>

<style scoped lang="postcss">
.icon-button {
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: var(--color-hover);
  }

  &.disabled {
    cursor: not-allowed;
    background-color: inherit;
  }
}
</style>
