<script setup lang="ts">
import _ from 'lodash-es';
import { type PropType, computed, ref } from 'vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useCreationOptions } from '@/modules/core/workspace/home/composables/creationOptions';
import {
  type RepoCreationOption,
  RepoCreationOptions,
} from '@/modules/core/workspace/home/composables/creationOptions';
import { gitProviderUtils, isRepoIdDummyRepo, productEvents } from '@swimm/shared';
import assertNever from 'assert-never';
import { IconButton, Tag } from '@swimm/ui';
import { useRoute } from 'vue-router';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { storeToRefs } from 'pinia';
import { useReposStore } from '@/modules/repo/stores/repos-store';

type CreationEntity = 'FOLDER' | 'REPO';

const props = defineProps({
  repoId: { type: String, required: true },
  folderId: { type: String, default: null },
  entity: { type: String as PropType<CreationEntity>, required: true },
});
const emit = defineEmits(['expand-parent', 'open-menu', 'close-menu']);

const showCreateOptions = ref(false);
const analytics = useAnalytics();
const { handleRepoOptionClick } = useCreationOptions();
const route = useRoute();
const { isGenAIDisabledForWorkspace } = storeToRefs(useWorkspaceStore());

const handleClickShow = () => {
  emit('open-menu');
  showCreateOptions.value = true;
};

const handleClickClose = () => {
  emit('close-menu');
  showCreateOptions.value = false;
};

const handleOptionClick = async ({
  option,
  repoId,
  folderId,
}: {
  option: RepoCreationOption;
  repoId: string;
  folderId: string;
}) => {
  const entityId = getEntityId(props.entity);
  analytics.track(productEvents.SELECTED_SIDEBAR_ADD_MENU_OPTION, {
    'Entity Type': props.entity,
    'Entity Id': entityId,
    Option: option.label,
    // TODO: Fishman 'Entity Path':
  });
  await handleRepoOptionClick({ option, repoId, folderId });
};

const handleMenuOpened = () => {
  const entityId = getEntityId(props.entity);
  analytics.track(productEvents.OPENED_SIDEBAR_ADD_MENU, {
    'Entity Type': props.entity,
    'Entity Id': entityId,
    'Is Current Repo': props.repoId === route.params.repoId,
    // TODO: Fishman 'Entity Path':
  });
};

const getEntityId = (entity: CreationEntity) => {
  switch (entity) {
    case 'FOLDER':
      return props.folderId;
    case 'REPO':
      return props.repoId;
    default:
      assertNever(entity);
  }
};

function expandParent() {
  emit('expand-parent');
}

const isDummyRepo = computed(() => isRepoIdDummyRepo(props.repoId));

const { reposStateData } = storeToRefs(useReposStore());
const repoData = computed(() => reposStateData.value[props.repoId]);
const isPrToDocVisible = computed(() => gitProviderUtils.doesProviderSupportPRToDoc(repoData.value?.provider));

const createOptions = computed(() => {
  let options: Partial<typeof RepoCreationOptions> = RepoCreationOptions;
  if (isDummyRepo.value) {
    options = _.omit(options, 'FOLDER');
  }
  if (isGenAIDisabledForWorkspace.value) {
    options = _.omit(options, 'Snippets2Doc');
  }
  if (!isPrToDocVisible.value) {
    options = _.omit(options, 'PR2Doc');
  }
  return options;
});
</script>

<template>
  <VDropdown
    class="repo-create-popper"
    theme="menu-no-arrow"
    placement="bottom-start"
    :triggers="['click']"
    :delay="0"
    :shown="showCreateOptions"
    @show="handleMenuOpened"
    @close="handleClickClose"
  >
    <IconButton class="icon" name="add" no-padding @click.stop="handleClickShow" data-testid="create-button" />
    <template #popper>
      <div>
        <EllipsisOption
          v-for="(option, index) of createOptions"
          :key="index"
          v-close-popper
          :name="option.label"
          :icon="option.icon"
          :handler="
            async () => {
              expandParent();
              await handleOptionClick({ option, folderId, repoId });
            }
          "
        >
          <template #appendix v-if="!isGenAIDisabledForWorkspace && 'ai' in option && option.ai">
            <Tag text="AI" tag-style="beta" class="ai-tag" />
          </template>
        </EllipsisOption>
      </div>
    </template>
  </VDropdown>
</template>

<style scoped lang="postcss">
.repo-create-popper {
  .icon {
    font-size: var(--body-L);
    border-radius: 2px;
    padding: 2px;
  }
}

.ai-tag {
  margin-left: var(--space-base);
  margin-top: -3px;
  margin-bottom: -3px;
}
</style>
