<template>
  <div>
    <template v-if="showDocAssignees">
      <AssignDocModal
        v-if="shouldShowModal"
        :show="shouldShowModal"
        :repo-id="repoId"
        :branch="branch"
        :doc-id="docId"
        @close="closeAssignModal"
      />
      <DocSidebarActionRow
        :action-name="actions.assign.enableName"
        :name="actions.assign.name"
        :icon="actions.assign.iconName"
        :number="numAssignees"
        :show-action="!shouldDisableActions"
        test-id-prefix="assign-"
        @action-clicked="showAssignModal"
      >
        <AssigneePopup :repo-id="repoId" :doc-id="docId" :assignments="assignments" :origin="origin" />
      </DocSidebarActionRow>
    </template>
    <DocSidebarActionRow
      :action-name="isUserWatching ? actions.watch.disableName : actions.watch.enableName"
      :name="actions.watch.name"
      :icon="actions.watch.iconName"
      :number="numWatchers"
      :show-action="!shouldDisableActions"
      test-id-prefix="watch-"
      @action-clicked="watchClicked"
    >
      <UsersPopup :users="watchers" />
    </DocSidebarActionRow>
  </div>
</template>

<script>
import { collectionNames } from '@/adapters-common/firestore-wrapper';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { useNotificationsStore } from '@swimm/editor';
import AssigneePopup from '@/modules/doc-assignments/components/AssigneePopup.vue';
import AssignDocModal from '@/modules/doc-assignments/modals/AssignDocModal.vue';
import { productEvents } from '@swimm/shared';
import { mapActions, mapGetters } from 'vuex';
import DocSidebarActionRow from './DocSidebarActionRow.vue';
import UsersPopup from '@/common/components/organisms/UsersPopup.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';

export default {
  components: { AssignDocModal, AssigneePopup, DocSidebarActionRow, UsersPopup },
  props: {
    doc: { type: Object, required: true },
    repoId: { type: String, required: true },
    shouldDisableActions: { type: Boolean, default: false },
  },
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const { addNotification } = useNotificationsStore();

    return { user, analytics, addNotification };
  },
  data() {
    return {
      shouldShowModal: false,
      showDocAssignees: false, // TODO: Assignees feature flag is currently disabled for all users
      updatingValueTimer: null,
      actions: {
        assign: {
          name: 'Assignees',
          iconName: 'reviewer',
          enableName: 'Assign',
        },
        watch: {
          name: 'Watchers',
          iconName: 'eye',
          enableName: 'Watch',
          disableName: 'Unwatch',
        },
      },
    };
  },
  computed: {
    ...mapGetters('database', ['db_getWatchers', 'db_getAssignments']),
    isInPlaylist() {
      return this.$route.path.includes('/playlists');
    },
    origin() {
      if (this.isInPlaylist) {
        return 'View Playlist Doc';
      }
      return this.$route.path.endsWith('/edit') ? 'Edit Document' : 'View Document';
    },
    docId() {
      return this.doc.id;
    },
    watchers() {
      return this.db_getWatchers(this.repoId, this.docId);
    },
    numWatchers() {
      return this.watchers?.length || 0;
    },
    isUserWatching() {
      return this.watchers.some((w) => w.id === this.user.uid);
    },
    assignments() {
      return this.db_getAssignments(this.repoId, this.docId);
    },
    numAssignees() {
      return this.assignments.length;
    },
    branch() {
      return this.$route.params.branch;
    },
  },
  methods: {
    ...mapActions('database', ['setDocSubCollectionData', 'removeItemFromDocSubCollection']),
    showAssignModal() {
      this.shouldShowModal = true;
      this.analytics.track(productEvents.CLICKED_ASSIGN, {
        Origin: this.origin,
        Context: 'Header',
        'Workspace ID': this.$route.params.workspaceId,
        'Repo ID': this.repoId,
        'Document ID': this.docId,
      });
    },
    closeAssignModal() {
      this.shouldShowModal = false;
    },
    watchClicked() {
      // Call this function with a debounce since we don't want it sent immediately
      if (this.updatingValueTimer) {
        clearTimeout(this.updatingValueTimer);
      }
      this.updatingValueTimer = setTimeout(this.toggleWatcher, 3000);

      const data = {
        repoId: this.repoId,
        unitId: this.docId,
        data: { created: Date.now(), id: this.user.uid, name: this.user.nickname },
        collection: collectionNames.WATCHERS,
      };
      if (this.isUserWatching) {
        this.removeItemFromDocSubCollection(data);
      } else {
        this.setDocSubCollectionData(data);
      }
    },
    async toggleWatcher() {
      const watching = this.isUserWatching;
      try {
        const response = await CloudFunctions.toggleWatchForUser({
          repoId: this.repoId,
          docId: this.docId,
          userName: this.user.nickname,
          watch: watching,
        });
        if (response.data.status !== 'success') {
          throw new Error(response.data.error);
        }
        const isEdit = this.$route.path.endsWith('/edit');
        const origin = this.$route.params.playlistId
          ? 'View Playlist Document'
          : isEdit
          ? 'Edit Document'
          : 'View Document';
        await this.analytics.track(!watching ? productEvents.CLICKED_UNWATCH : productEvents.CLICKED_WATCH, {
          Context: 'Doc Sidebar',
          Origin: origin,
          'Document ID': this.docId,
          'Repo ID': this.repoId,
          'Workspace ID': this.$route.params.workspaceId,
        });
      } catch (err) {
        this.$logger.error(`Failed to toggle watch for user ${this.user.uid} on doc ${this.docId} with err: ${err}`);
        this.addNotification(`Failed to ${watching ? 'watch' : 'unwatch'} doc`, {
          icon: 'warning',
        });
      }
    },
  },
};
</script>
