import type { SlashCommand, SlashCommandsCategory } from '../types';
import { StylingCommands, SwimmCommands } from './commands';

export const orderedCommands: readonly SlashCommandsCategory[] = [
  {
    category: 'Swimm commands',
    commands: Object.values(SwimmCommands).map((command): Readonly<SlashCommand> => Object.freeze(command)),
  },
  {
    category: 'Styling',
    commands: Object.values(StylingCommands).map((command): Readonly<SlashCommand> => Object.freeze(command)),
  },
];

export const markdownModeSuggestions: SlashCommand[] = Object.values(StylingCommands);
