import { removeSuffix } from '../utils/string-utils';
import { PLAYLIST_SUFFIX, SWMD_SUFFIX } from './consts';

export function extractResourceIdFromPath(path: string): string {
  let pathNoSuffix = path;
  if (!path.endsWith(PLAYLIST_SUFFIX) && !path.endsWith(SWMD_SUFFIX)) {
    throw new Error('Not a legal playlist or swmd path');
  }
  pathNoSuffix = removeSuffix(pathNoSuffix, PLAYLIST_SUFFIX);
  pathNoSuffix = removeSuffix(pathNoSuffix, SWMD_SUFFIX);
  if (pathNoSuffix.includes('.')) {
    return pathNoSuffix.split('.').pop() as string;
  }
  throw new Error('Not a legal playlist or swmd path');
}
