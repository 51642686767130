import { defineStore, storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { STORES } from '@/modules/core/stores/store-constants';
import { useRoute } from 'vue-router';
import { useStigg } from '@/common/composables/useStigg';
import { eventLogger, getLoggerNew, productEvents } from '@swimm/shared';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useStore } from 'vuex';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';

const logger = getLoggerNew(__modulename);

export const useBillingStore = defineStore(STORES.BILLING, () => {
  const route = useRoute();
  const store = useStore();
  const { user } = storeToRefs(useAuthStore());
  const analytics = useAnalytics();
  const { logEvent } = useSwimmEventLogs();
  const { getCurrentSeats, getTrialDaysLeft, initStiggForCustomer, isEligibleForTrial, isOnTrial, stiggClient } =
    useStigg();

  const showBillingPlans = ref(false);
  const workspaceId = ref('');
  const isOnTrialPlan = ref(false);
  const isEligibleForTrialPlan = ref(false);
  const hasCheckedEligibleForTrialPlan = ref(false);
  const trialDaysLeft = ref(null);
  const currentSeats = ref(null);

  const setShowBillingPlans = function (show: boolean) {
    showBillingPlans.value = show;
  };

  const setIsOnTrialPlan = function (onTrial: boolean) {
    isOnTrialPlan.value = onTrial;
    if (onTrial) {
      setIsEligibleForTrialPlan(false);
    }
  };

  const setIsEligibleForTrialPlan = function (eligibleForTrialPlan: boolean) {
    isEligibleForTrialPlan.value = eligibleForTrialPlan;
  };

  const setHasCheckedEligibleForTrialPlan = function (hasChecked: boolean) {
    hasCheckedEligibleForTrialPlan.value = hasChecked;
  };

  const setTrialDaysLeft = function (trialDays: null | number) {
    trialDaysLeft.value = trialDays;
  };

  const setCurrentSeats = function (currentSeatsVal: null | number) {
    currentSeats.value = currentSeatsVal;
  };

  function clean() {
    setIsOnTrialPlan(false);
    setIsEligibleForTrialPlan(false);
    setHasCheckedEligibleForTrialPlan(false);
    setTrialDaysLeft(null);
    setCurrentSeats(null);
  }

  async function fetchBillingData() {
    try {
      await stiggClient.refresh();
      setIsOnTrialPlan(await isOnTrial());
      setTrialDaysLeft(await getTrialDaysLeft());
      setCurrentSeats(await getCurrentSeats());
      if (!isOnTrialPlan.value) {
        setIsEligibleForTrialPlan(await isEligibleForTrial());
      }
      setHasCheckedEligibleForTrialPlan(true);
    } catch (err) {
      logger.error({ err }, `Failed fetching billing data ${err.message}`);
    }
  }

  async function trialSuccess(planForTrial, workspaceName) {
    await store.dispatch('database/refreshWorkspaceLicense', { workspaceId: workspaceId.value });
    await fetchBillingData();
    const traits = {
      Plan: planForTrial,
      'Plan Modified Date': new Date().toISOString(),
    };
    await analytics.cloudWorkspaceGroup({
      workspaceId: workspaceId.value,
      traits,
      userId: user.value.uid,
    });
    analytics.cloudTrack({
      identity: user.value.uid,
      event: productEvents.SUCCESSFUL_TRIAL,
      payload: {
        'User Id': user.value.uid,
        'User Email': user.value.email,
        'Plan Modified Date': new Date().toISOString(),
        'Workspace ID': workspaceId.value,
        'Workspace Name': workspaceName,
        'Plan Type': planForTrial,
        Context: 'Billing',
      },
    });
    logEvent(eventLogger.SWIMM_EVENTS.WORKSPACE_STARTED_TRIAL, {
      workspaceId: workspaceId.value,
      srcName: workspaceName,
      srcType: 'workspace',
    });
  }

  function trialFailure(error) {
    logger.error(`Failed creating subscription. Details: ${error.errorMessage || error}`);
    analytics.track(productEvents.FAILED_UPGRADE, {
      'Upgrade Attempt Date': new Date().toISOString(),
      'Workspace ID': workspaceId.value,
    });
  }

  watch(
    route,
    async (to) => {
      const workspaceIdParam = to ? (to.params.workspaceId as string) : null;
      if (workspaceIdParam !== workspaceId.value) {
        clean();
        if (workspaceIdParam) {
          await initStiggForCustomer(workspaceIdParam);
          workspaceId.value = workspaceIdParam;
          await fetchBillingData();
        }
      }
    },
    { immediate: true }
  );

  return {
    showBillingPlans,
    setShowBillingPlans,
    isOnTrialPlan,
    isEligibleForTrialPlan,
    hasCheckedEligibleForTrialPlan,
    trialDaysLeft,
    currentSeats,
    fetchBillingData,
    trialSuccess,
    trialFailure,
  };
});
