<template>
  <InstanceNotification :title="title" :notification="notification" />
</template>

<script>
import InstanceNotification from '@/modules/notifications-center/components/InstanceNotification.vue';

export default {
  components: { InstanceNotification },
  props: {
    notification: { type: Object, default: () => ({}) },
  },
  computed: {
    title() {
      return 'Swimm’s GitHub App has been suspended - this can make Swimm Verify on PRs appear stuck. Contact your GitHub admin to unsuspend it.';
    },
  },
};
</script>
