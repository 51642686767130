<template>
  <SwModal :show-modal="show" :padded="false" @close="close" :heading="heading" suppress-heading>
    <div class="content">
      <Loader v-if="loading" />
      <div v-else-if="showError" class="error">
        <Icon name="warning" />
        <SwText variant="body-L">Failed to read file contents</SwText>
      </div>
      <Suspense v-else>
        <MonacoEditor
          :value="fileContent"
          :language="fileType"
          :theme="editorTheme"
          :options="monacoOptions"
          @editor-did-mount="editorDidMount"
          class="snippet-container data-hj-suppress"
        />
        <template #fallback>
          <Loader class="loader" />
        </template>
      </Suspense>
    </div>
  </SwModal>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { Loader, SwModal } from '@swimm/ui';
import { ThemeOptions } from '@swimm/shared';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';
import { storeToRefs } from 'pinia';

const MonacoEditor = defineAsyncComponent(() => import('@/common/components/organisms/MonacoEditor.vue'));

export default {
  components: { SwModal, MonacoEditor, Loader },
  props: {
    show: { type: Boolean, required: true },
    heading: { type: String, required: true },
    monacoOptions: { type: Object, default: null },
    fileType: { type: String, default: '' },
    fileContent: { type: String, required: false, default: '' },
    // Use loading to indicate loading in progress
    loading: { type: Boolean, required: false, default: false },
    showError: { type: Boolean, required: false, default: false },
    // Put this line in the center
    revealLine: { type: Number, required: false, default: 0 },
  },
  setup() {
    const { theme } = storeToRefs(useUserConfigStore());

    return {
      theme,
    };
  },
  emits: ['close'],
  computed: {
    editorTheme() {
      if (this.theme === ThemeOptions.DARK) {
        return 'vs-dark';
      }
      return '';
    },
  },
  methods: {
    editorDidMount(editor) {
      if (this.revealLine) {
        editor.revealLineInCenter(this.revealLine);
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.content {
  width: 75vw;
  height: 75vh;
}

.error {
  padding-top: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.snippet-container {
  overflow: hidden;
  width: 75vw;
  max-width: inherit;
  height: calc(75vh - 51px); /* 51px is modal-header's height */
  max-height: inherit;
  flex-direction: column;
  box-sizing: border-box;
}

/* Force hiding the monaco 'suggest widget' */

.snippet-container :deep(.suggest-widget) {
  display: none;
}

.loader {
  width: 100%;
  height: 100%;
}
</style>
