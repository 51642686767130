<template>
  <div class="help-center">
    <div class="headline-container">
      <div class="title">
        <div class="headline3 bold">{{ title }}</div>
      </div>
      <IconButton name="close" @click="closeEllipsis" />
    </div>
    <SwText variant="subtitle-XL" class="subtitle-text">Swimm tutorials</SwText>
    <template v-for="(item, index) in videos" :key="index">
      <HelpCenterVideoItem
        :close-after-action="closeAfterAction"
        :name="item.name"
        class="video-option"
        :url="item.url"
        :handler="() => handleVideoClick(item)"
        :thumbnail="item.thumbnail"
        data-testid="video-option"
        @close-ellipsis="closeEllipsis"
      >
      </HelpCenterVideoItem>
    </template>
    <SwText variant="subtitle-XL" class="subtitle-text">Resources</SwText>
    <template v-for="(item, index) in resources" :key="index">
      <HelpCenterMenuItem
        :close-after-action="closeAfterAction"
        :name="item.name"
        :url="item.url"
        class="kb-option"
        :icon="item.icon"
        :handler="() => handleClick(item)"
        data-testid="kb-option"
        @close-ellipsis="closeEllipsis"
      >
      </HelpCenterMenuItem>
    </template>
  </div>
</template>
<script>
import HelpCenterMenuItem from '@/modules/help-center/components/HelpCenterMenuItem.vue';
import HelpCenterVideoItem from '@/modules/help-center/components/HelpCenterVideoItem.vue';
import { IconButton, SwText } from '@swimm/ui';

export default {
  components: { HelpCenterMenuItem, HelpCenterVideoItem, IconButton, SwText },
  props: {
    title: { type: String, required: true },
    resources: { type: Array, required: true },
    videos: { type: Array, required: true },
    closeAfterAction: { type: Boolean, default: true },
  },
  emits: ['close', 'item-clicked', 'video-clicked'],
  methods: {
    async handleClick(item) {
      this.$emit('item-clicked', item);
    },
    async handleVideoClick(item) {
      this.$emit('video-clicked', item);
    },
    closeEllipsis() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="postcss">
.help-center {
  user-select: none;

  .headline-container {
    display: flex;
    justify-content: space-between;
    padding: var(--space-base) var(--space-sm);

    .title {
      display: flex;
      align-items: center;
    }
  }
}

.video-option {
  padding: var(--space-xs) var(--space-base) var(--space-base) var(--space-sm);
  min-width: 280px;
  font-size: var(--body-L);
  line-height: 1.5rem;
  font-weight: normal;
}

.kb-option {
  padding: var(--space-base) var(--space-sm);
  min-width: 280px;
  font-size: var(--body-L);
  line-height: 1.5rem;
  font-weight: normal;

  & :deep(.icon) {
    padding-right: var(--space-base);
    font-size: var(--headline2);
  }
}

.subtitle-text {
  padding: var(--space-sm) var(--space-sm);
}
</style>
