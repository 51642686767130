import { toHtml } from 'hast-util-to-html';
import { convertTabsToSpaces, getLogger } from '@swimm/shared';
import { lowlight } from '@/services/lowlight';

const logger = getLogger(__modulename);

export function calculateLineWithLeastPrefixSpaces(lines: string[]): number {
  // ignoring empty strings, makes sure an array with empty strings and non-empty strings will not return 0
  const linesWithoutEmptyStrings = lines.filter((line) => line.length > 0);
  if (!linesWithoutEmptyStrings.length) {
    return 0;
  }
  return linesWithoutEmptyStrings.reduce((minSpacing: number, str: string) => {
    // line that contains only whitespaces
    const strWithSpaces = convertTabsToSpaces(str);
    if (strWithSpaces.trim().length === 0) {
      return Math.min(minSpacing, strWithSpaces.length);
    }
    // search returns index of first non-whitespace char on str (which is the amount of spaces on it)
    return Math.min(minSpacing, strWithSpaces.search(/\S/));
    // Infinity is initial value to ensure that the first element is properly evaluated
    // (so returned value from 1st reduce iteration will never be initial value)
  }, Infinity);
}

export function highlightCodeLine(code: string, language: string): string {
  try {
    const tree = lowlight.highlight(language, code);
    return toHtml(tree) + '&#xa;';
  } catch (error) {
    // Fallback to plaintext
    logger.warn(`Falling back to automatic/guessed text highlighting for: ${language} (${error})`);
    const tree = lowlight.highlightAuto(code);
    return toHtml(tree) + '&#xa;';
  }
}
