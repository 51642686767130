import type { SwimmEditorServices } from '@/tiptap/editorServices';
import { Transform } from '@tiptap/pm/transform';
import type { Editor } from '@tiptap/vue-3';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { schema } from '../../swmd/extensions';
import { SwimmDocumentSuggestionSorter } from './SwimmDocumentSuggestionSorter';
import { useTextSuggestions } from './suggest';
import { SwimmportDecorationsHandler } from './docTraverse';
import { removePrefix } from '@swimm/shared';

export function swimmify(swimmEditorServices: SwimmEditorServices, editor: Editor) {
  const suggestionSorter = new SwimmDocumentSuggestionSorter(swimmEditorServices);
  const textSuggestionsService = useTextSuggestions(
    swimmEditorServices,
    () => triggerApplySuggestion(decorationsHandler, editor),
    true
  );

  const decorationsHandler = new SwimmportDecorationsHandler(
    textSuggestionsService.getSuggestionsForText,
    suggestionSorter
  );
}

let applyingSuggestions = false;
let pendingApplySuggestion = false;

function triggerApplySuggestion(decorationsHandler: SwimmportDecorationsHandler, editor: Editor) {
  if (applyingSuggestions) {
    pendingApplySuggestion = true;
    return;
  }
  applyingSuggestions = true;
  doApplySuggestions(decorationsHandler, editor).then(() => {
    applyingSuggestions = false;
    if (pendingApplySuggestion) {
      pendingApplySuggestion = false;
      triggerApplySuggestion(decorationsHandler, editor);
    }
  });
}

async function doApplySuggestions(decorationsHandler: SwimmportDecorationsHandler, editor: Editor) {
  const transform = new Transform(ProseMirrorNode.fromJSON(schema, editor.view.state.doc.toJSON()));

  const suggestions = await decorationsHandler.calculateSuggestions(transform.doc);
  const chain = editor.chain();
  chain.focus().setSourceExternal();

  for (const suggestion of suggestions) {
    const bestMatch = suggestion.result;

    if (bestMatch) {
      // Do not swimmify a single punctuation mark
      if (bestMatch.type === 'token') {
        if (bestMatch.suggestions[0].token.match(/^[\s.,;:]$/)) {
          return;
        }
        const tokenSuggestion = bestMatch.suggestions[0];

        chain
          .command(({ tr, commands }) => {
            return commands.deleteRange({ from: tr.mapping.map(suggestion.from), to: tr.mapping.map(suggestion.to) });
          })
          .command(({ tr, commands }) => {
            return commands.focus(tr.mapping.map(suggestion.from));
          })
          .insertSwmToken(
            tokenSuggestion.token,
            `/${removePrefix(tokenSuggestion.position.path, '/')}`,
            tokenSuggestion.position,
            tokenSuggestion.lineData,
            tokenSuggestion.repoId
          );
      } else {
        const pathSuggestion = bestMatch.suggestions[0];

        if (pathSuggestion.repoId) {
          chain
            .command(({ tr, commands }) => {
              return commands.deleteRange({ from: tr.mapping.map(suggestion.from), to: tr.mapping.map(suggestion.to) });
            })
            .command(({ tr, commands }) => {
              return commands.focus(tr.mapping.map(suggestion.from));
            })
            .insertSwmPath(pathSuggestion.path, pathSuggestion.repoId, pathSuggestion.type);
        }
      }
    }
  }
  chain.run();
}
