import { serializeSwmTokenPos } from '@/markdownit/plugins/swm_token';
import { parseSwmFilename, removePrefix } from '@swimm/shared';
import { getText } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { sha256 } from 'js-sha256';
import { memoize } from 'lodash-es';

// TODO We can route this via the ProseMirror schema using Tiptap's extendNodeSchema
export function isSwimmNode(node: ProseMirrorNode): boolean {
  return ['swmSnippet', 'swmPath', 'swmLink', 'swmToken', 'swmMention'].includes(node.type.name);
}

export function isAutosyncableSwimmNode(node: ProseMirrorNode): boolean {
  return ['swmSnippet', 'swmPath', 'swmLink', 'swmToken'].includes(node.type.name);
}

/** Used as the maximum memoize cache size. */
const MAX_MEMOIZE_SIZE = 500;

/**
 * Memoized (Capped) hash function used by {@link getSwimmNodeId}.
 *
 * Based on https://github.com/lodash/lodash/blob/2da024c3b4f9947a48517639de7560457cd4ec6c/.internal/memoizeCapped.js
 */
const hash = memoize(
  (data: string): string => {
    return sha256(data);
  },
  (key) => {
    const cache = hash.cache as Map<string, string>;
    if (cache.size === MAX_MEMOIZE_SIZE) {
      cache.clear();
    }
    return key;
  }
);

export function getSwimmNodeId(node: ProseMirrorNode): string {
  switch (node.type.name) {
    case 'swmSnippet':
      return `swmSnippet-${node.attrs.repoId}-${node.attrs.path}-${node.attrs.line}-${hash(node.attrs.snippet)}`;
    case 'swmPath':
      return `swmPath-${node.attrs.repoId}-${node.attrs.href}`;
    case 'swmLink':
      return `swmLink-${node.attrs.repoId}-${node.attrs.path}`;
    case 'swmToken':
      return `swmToken-${node.attrs.repoId}-${node.attrs.token}-${node.attrs.path}-${serializeSwmTokenPos(
        node.attrs.pos
      )}-${hash(node.attrs.lineData)}`;
    case 'swmMention':
      return `swmMention-${node.attrs.uid}`;
    default:
      throw new Error(`Unknown node type ${node.type.name}`);
  }
}

// external ids are saved in DB for cross repo docs
// so we cannot expose path and other sensitive data in it
// used only for ide cross links
export function getSwimmNodeExternalId(node: ProseMirrorNode): string {
  const nodeId = getSwimmNodeId(node);
  return `${node.type.name}-${hash(nodeId)}`;
}

export function forAllSwimmNodes(
  content: ProseMirrorNode,
  cb: (node: ProseMirrorNode, pos: number, parent: ProseMirrorNode | null, index: number) => boolean
): void {
  let continue_ = true;
  content.descendants((node, pos, parent, index) => {
    if (continue_ && isSwimmNode(node)) {
      continue_ &&= cb(node, pos, parent, index);
    }

    return continue_;
  });
}

export function buildSwimmLink(baseUrl: string, repoId: string, path: string) {
  const { swmId } = parseSwmFilename(path) ?? { swmId: 'ERROR' };
  return `${baseUrl}/repos/${encodeURIComponent(repoId)}/docs/${encodeURIComponent(swmId)}`;
}

// return the node non empty text lines
export function getNodeTextLines(node: ProseMirrorNode): string[] {
  // we removed the serializers since this does not work without swmd editor
  // but it will return text without any tokens, snippets etc
  // used to have getText(node, { textSerializers: getTextSerializersFromSchema(node.type.schema) })
  return getText(node)
    .split('\n')
    .filter((line) => !!line.trim()); // remove empty lines;
}

// return the snippet comment as text
export function getSnippetCommentText(node: ProseMirrorNode) {
  if (node.type.name !== 'swmSnippet') {
    throw new Error(`should be called with snippet but got ${node.type.name}`);
  }
  return getNodeTextRecursive(node, { excludeSnippetCode: true }).trim();
}

type NodeToTextOptions = {
  excludeSnippetCode?: boolean;
};

// return text for the given node and its decendants nodes
// currently not exported and used only for the comment
function getNodeTextRecursive(rootNode: ProseMirrorNode, options?: NodeToTextOptions): string {
  let result = nodeToText(rootNode) ?? '';
  rootNode.forEach((childNode) => {
    result += getNodeTextRecursive(childNode, options);
  });
  // for snippet, we insert the snippet code after the comment
  if (rootNode.type.name === 'swmSnippet' && !options?.excludeSnippetCode) {
    result += rootNode.attrs.snippet;
  }
  // if this is block, and there is text so far, add a new line if there is no one
  if (rootNode.isBlock && result.length > 0 && result[result.length - 1] !== '\n') {
    result += '\n';
  }
  return result;
}

function nodeToText(node: ProseMirrorNode): string | null {
  switch (node.type.name) {
    case 'text':
      return node.text ?? '';
    case 'swmToken':
      return node.attrs.token ?? '';
    case 'swmPath':
      return removePrefix(node.attrs.href ?? '', '/');
    case 'swmLink':
      return node.attrs.docTitle ?? '';
    case 'swmMention':
      return node.attrs.name ?? '';
    default:
      break;
  }
  return null;
}

export function listImageNodes(doc: ProseMirrorNode): { node: ProseMirrorNode; pos: number }[] {
  const imageNodes: { node: ProseMirrorNode; pos: number }[] = [];

  doc.descendants((node, pos) => {
    if (node.type.name === 'image' || node.type.name === 'blockImage') {
      imageNodes.push({ node, pos });
    }
  });

  return imageNodes;
}
