<template>
  <div class="skeleton" />
</template>

<style scoped lang="postcss">
.skeleton {
  border-radius: 8px;
  animation: skeleton 0.7s linear infinite alternate;
}
</style>
