import Ajv from 'ajv';
import { FromSchema } from 'json-schema-to-ts';
import { RuleInfoSchema, RulesConfigSchema } from './types';
import { getLoggerNew } from '#logger';

type RulesConfig = FromSchema<typeof RulesConfigSchema>;
type RuleInfo = FromSchema<typeof RuleInfoSchema>;

const logger = getLoggerNew("packages/shared/src/rules/rules-json.ts");

export function rulesConfigToJson(rulesConfig: RulesConfig): string {
  return JSON.stringify(rulesConfig, null, 4);
}

export function rulesJsonToConfig(rulesJson: string): RulesConfig | null {
  try {
    const rulesData = JSON.parse(rulesJson);
    const ajv = new Ajv();
    if (!ajv.validate(RulesConfigSchema, rulesData)) {
      logger.error(ajv.errors, 'Rules data validation failed, going to fallback');
      return rulesDataToConfigFallback(rulesData);
    }
    return rulesData as RulesConfig;
  } catch (err) {
    logger.error({ err }, 'Error thrown while parsing rules data, continuing with no rules');
    return null;
  }
}

function rulesDataToConfigFallback(rulesData: unknown): RulesConfig | null {
  if (rulesData == null || typeof rulesData !== 'object') {
    logger.warn('Rules data is not an object');
    return null;
  }
  if (!('rules' in rulesData) || rulesData.rules == null || typeof rulesData.rules !== 'object') {
    logger.warn('Missing field "rules" of type object in rules data');
    return null;
  }

  const rulesConfig: RulesConfig = { rules: {}, view_order: [] };
  populateRulesInConfig(rulesConfig, rulesData.rules);
  orderRulesInConfig(rulesConfig, rulesData);
  return rulesConfig;
}

function populateRulesInConfig(rulesConfig: RulesConfig, rules: object) {
  const ajv = new Ajv();
  Object.entries(rules).forEach(([ruleId, ruleInfo]) => {
    if (ajv.validate(RuleInfoSchema, ruleInfo)) {
      rulesConfig.rules[ruleId] = ruleInfo as RuleInfo;
    } else {
      logger.warn(ajv.errors, `Rule info validation failed for rule id ${ruleId}, ignoring it`);
    }
  });
}

function orderRulesInConfig(rulesConfig: RulesConfig, rulesData: object & Record<'rules', unknown>) {
  if (!('view_order' in rulesData) || !Array.isArray(rulesData.view_order)) {
    logger.warn('Missing field "view_order" of type array in rules data');
    rulesConfig.view_order = Object.keys(rulesConfig.rules);
    return;
  }

  rulesConfig.view_order = rulesConfig.view_order.filter((ruleId) => ruleId in rulesConfig.rules);
  for (const ruleId in rulesConfig.rules) {
    if (!rulesConfig.view_order.includes(ruleId)) {
      rulesConfig.view_order.push(ruleId);
    }
  }
}
