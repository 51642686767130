import { config, getLoggerNew, removePrefix } from '@swimm/shared';
import path from 'path-browserify';
import * as uuid from 'uuid';

const logger = getLoggerNew(__modulename);

export const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
export const ALLOWED_IMAGE_TITLES = ALLOWED_IMAGE_TYPES.map((type) => type.split('/')[1].toUpperCase());

const MAX_SIZE_BASE64 = 1_000_000 * 0.95;

class ResizeImageError extends Error {}
class ResizeGifError extends ResizeImageError {}
class DisallowedImageError extends Error {}

export function getUploadImageErrorMessage(err: Error): string {
  if (err instanceof ResizeGifError) {
    return 'GIFs above 0.7MB are not supported.';
  }
  if (err instanceof ResizeImageError) {
    return 'Image is too big and we could not resize it automatically. Please upload an image up to 0.7MB in size';
  }
  if (err instanceof DisallowedImageError) {
    const titles =
      ALLOWED_IMAGE_TITLES.slice(0, -1).join(', ') + ' or ' + ALLOWED_IMAGE_TITLES[ALLOWED_IMAGE_TITLES.length - 1];
    return `Image type is not supported. Please upload a ${titles} image.`;
  }
  return 'Failed to upload image, please contact support';
}

export function fileExtensionToImageType(extension: string): string | undefined {
  switch (extension.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'gif':
      return 'image/gif';
    default:
      return undefined;
  }
}

export async function pickImage(): Promise<File | undefined> {
  return new Promise<File | undefined>((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = ALLOWED_IMAGE_TYPES.join(',');
    input.click();

    input.addEventListener('change', function (event) {
      const target = event.target as HTMLInputElement;
      const file = target.files?.[0];
      if (file != null && !isImageFileSupported(file)) {
        reject(new DisallowedImageError(`Disallowed file type: ${file.type}`));
      }

      resolve(file);
    });
  });
}

export async function imageFileToBase64(file: File): Promise<string> {
  const buffer = await file.arrayBuffer();
  if (buffer) {
    const content = Buffer.from(buffer);
    return content.toString('base64');
  } else {
    throw new Error('Failed to convert image file to base64');
  }
}

export function getRepoFilePathForImageFile(file: File): string {
  const { ext, name } = path.parse(file.name);

  const date = new Date(Date.now());
  // dateFormat = 'yyyy-MM-dd HH-mm-ss-ms';
  const timestamp = `${date.getUTCFullYear()}-${date.getUTCMonth()}-${date.getUTCDate()}-${date.getUTCHours()}-${date.getUTCMinutes()}-${date.getUTCSeconds()}-${date.getUTCMilliseconds()}`;

  return `/${config.SWM_FOLDER_IN_REPO}/${config.SWM_IMAGES_FOLDER_NAME}/${name}-${timestamp}.${removePrefix(
    ext,
    '.'
  )}`;
}

export async function base64ImageToFile(src: string, image: string): Promise<File> {
  const fileExtension = removePrefix(path.extname(src), '.');
  const fileType: string | undefined = fileExtensionToImageType(fileExtension);

  if (!fileType || !ALLOWED_IMAGE_TYPES.includes(fileType)) {
    throw new Error(`disallowed file type: ${fileType}`);
  }

  const buff = Buffer.from(image, 'base64');
  return new File([buff], 'src', { type: fileType });
}

export function generateImageFilename(name: string): string {
  const id = uuid.v4();

  // Firebase storage upload fails for uppercase extensions (JPG instead of jpg)
  const i = name.lastIndexOf('.');
  const ext = i !== -1 ? name.slice(i + 1).toLowerCase() : '';

  return `${id}.${ext}`;
}

export function isImageFileSupported(file: File) {
  return ALLOWED_IMAGE_TYPES.includes(file.type);
}

async function fileToDataUrl(file: File): Promise<string> {
  const promise = new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (ev) {
      const target = ev.target as FileReader;
      resolve(target.result as string);
    };
    reader.onerror = function (ev) {
      reject(ev.target?.error);
    };
    reader.readAsDataURL(file);
  });
  return promise;
}

const B64_RATIO_SIZE = 1.333;

/**
 *
 * @param file File to be resized
 * @returns if the file b64 size is > 1MB, then the file is resized as jpeg.
 * resizing is done by doing several iterations and reducing its size until it is small enough
 */
export async function resizeImageFile(file: File): Promise<{ file: File; width: number; height: number }> {
  try {
    const b64Size = file.size * B64_RATIO_SIZE;
    const initialSize = { b64: b64Size, bytes: file.size };
    if (b64Size < MAX_SIZE_BASE64) {
      return { file, ...(await getImageSize(file)) };
    }
    // we cannot resize gifs this way
    if (file.type === 'image/gif') {
      throw new ResizeGifError('This image is too big to upload. Please use a smaller image.');
    }
    let prevFile = file;
    const maxIters = 50;
    for (let i = 0; i < maxIters; i++) {
      const scaledImage = await resizeImageFileByScale(prevFile, 0.75);
      const base64Size = scaledImage.file.size * B64_RATIO_SIZE;
      if (base64Size < MAX_SIZE_BASE64) {
        const finalSize = { b64: base64Size, bytes: scaledImage.file.size };
        logger.info(
          `Image was reduced from ${JSON.stringify(initialSize)} to ${JSON.stringify(finalSize)}, after ${
            i + 1
          } iterations`
        );
        return { file: scaledImage.file, width: scaledImage.width, height: scaledImage.height };
      }
      prevFile = scaledImage.file;
    }
    throw new Error(`Failed to resize image file after ${maxIters} iterations`);
  } catch (err) {
    if (err instanceof ResizeImageError) {
      throw err;
    }
    throw new ResizeImageError((err as Error).toString());
  }
}

export async function getImageSize(file: File): Promise<{ width: number; height: number }> {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
      URL.revokeObjectURL(img.src);
    });
    img.addEventListener('error', (event) => {
      reject(event.error);
      URL.revokeObjectURL(img.src);
    });
    img.src = URL.createObjectURL(file);
  });
}

/**
 * Resize an image using a canvas
 *
 * @returns Resized jpeg file
 */
async function resizeImageFileByScale(
  file: File,
  scaleFactor: number
): Promise<{ file: File; width: number; height: number }> {
  const dataURL = await fileToDataUrl(file);
  return new Promise<{ file: File; width: number; height: number }>((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width * scaleFactor;
      canvas.height = img.height * scaleFactor;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const newName = path.parse(file.name).name + '.jpg';
      ctx.canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Failed to upload image'));
          return;
        }
        resolve({ file: new File([blob], newName, { type: blob.type }), width: canvas.width, height: canvas.height });
      }, 'image/jpeg');
    });
    img.addEventListener('error', (event) => {
      reject(event.error);
    });
    img.src = dataURL;
  });
}
