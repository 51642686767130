import { distance } from 'fastest-levenshtein';
import { sha256 } from 'js-sha256';

/**
 * Strips a string with CRLF ("Carriage Return, Line Feed") from a string.
 * Sometimes in our tests, a string's result on a windows machine might include (\r) where the mock includes no such special character.
 * if it was introduced in OSX/Linux developer workstation
 * @param {string} str - a string including the CRLF characters to remove.
 * @return {string} the same string without carriage return (\r).
 */
export function removeCRLFCharacters(str: string): string {
  return str.replace(/\r/g, '');
}

export function delint(text: string): string {
  // Remove all whitespaces
  text = text.replace(/\s/g, '');
  // Remove all ';'
  text = text.replace(/;/g, '');
  // Replace all ' or ` with "
  text = text.replace(/['`]/g, '"');
  return text;
}

export function compareDelintedStrings(stringA: string, stringB: string, caseSensitive = true): number {
  let delintedA = delint(stringA);
  let delintedB = delint(stringB);
  if (!caseSensitive) {
    delintedA = delintedA.toLowerCase();
    delintedB = delintedB.toLowerCase();
  }
  return normalizedLevenshteinDistance(delintedA, delintedB);
}

export function normalizedLevenshteinDistance(stringA: string, stringB: string): number {
  const maxLength = Math.max(stringA.length, stringB.length);
  if (maxLength === 0) {
    return 1;
  }
  return (maxLength - distance(stringA, stringB)) / maxLength;
}

/* Used primarily to avoid markdown not formatting lines that come directly after a line of only `<br/>`.
For example:
<br/>
<br/>
<br/>
aaa
Becomes:
<br/><br/><br/>aaa */
export function removeNewLineAfterHtmlLineBreakTag(text: string): string {
  let newText = removeNewLineAfterLastHtmlLineBreakTag(text);
  // Each time we call `removeNewLineAfterLastHtmlLineBreakTag`
  // we remove only the new line after the last line break tag, and not all of them.
  // Thus, we run the function as long as there are any newly removed new lines.
  while (newText !== text) {
    text = newText;
    newText = removeNewLineAfterLastHtmlLineBreakTag(text);
  }
  return newText;
}

/* This function removes the new line after the last br tag in a sequence of br tag lines.
For example:
<br/>
<br/>
<br/>
aaa
Becomes:
<br/>
<br/>
<br/>aaa` */
function removeNewLineAfterLastHtmlLineBreakTag(text: string): string {
  return text.replace(/^((?:<br\/>\n)*)<br\/>\n([^\n])/gm, '$1<br/>$2');
}

export function generateCodeVerifier(length): string {
  const codeVerifier = Array.from({ length }, () => Math.floor(Math.random() * 256))
    .map((byte) => String.fromCharCode(byte))
    .join('');
  const base64CodeVerifier = btoa(codeVerifier);
  return base64CodeVerifier.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}
export function generateCodeChallenge(codeVerifier: string): string {
  const hash = sha256.array(codeVerifier);
  const base64 = btoa(String.fromCharCode.apply(null, hash));
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}
