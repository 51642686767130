<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watchEffect } from 'vue';

import BaseDivider from '../../components/BaseDivider/BaseDivider.vue';
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';
import BaseInput from '../../components/BaseInput/BaseInput.vue';
import BaseLayoutIcons from '../../components/BaseLayoutIcons/BaseLayoutIcons.vue';
import BaseLoading from '../../components/BaseLoading/BaseLoading.vue';
import BaseProse from '../../components/BaseProse/BaseProse.vue';

import UtilFocusItems from '../../components/UtilFocusItems/UtilFocusItems.vue';

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    modelValue: string;
    loading?: boolean;
    error?: string;
    noResults?: boolean;
    disableAutoFocus?: boolean;
    disableHeader?: boolean;
  }>(),
  {
    modelValue: undefined,
    placeholder: 'Search…',
    error: undefined,
    disableAutoFocus: undefined,
  }
);

const emit = defineEmits<{
  'update:modelValue': [value: string];
  close: [];
}>();

const q = ref(props.modelValue || undefined);
const search = ref<{ field: HTMLInputElement } | null>(null);
const focusItems = ref<InstanceType<typeof UtilFocusItems> | null>(null);
const delayFocus = ref(false);

const computedClasses = computed(() => ({
  'swm-selection-content--no-header': props.disableHeader,
}));

function onUpdateModelValue(value: string) {
  emit('update:modelValue', value);
  q.value = value;
}

async function resetItemFocus() {
  if (focusItems.value) {
    await nextTick();
    focusItems.value.reset();
  }
}

async function setSearchFocus() {
  if (search.value && search.value.field) {
    await nextTick();
    search.value.field.focus();
  }
}

async function setItemFocus(index: number) {
  if (focusItems.value) {
    await nextTick();
    focusItems.value.focusItem(index);
  }
}

async function forceUpdateFocusItems() {
  if (focusItems.value) {
    await nextTick();
    await focusItems.value.forceUpdateFocusItems();
  }
}

onMounted(async () => {
  if (!props.disableAutoFocus) {
    // We want to focus in the search input on mount but if loading
    // is true we can't focus because the input is disabled, so we
    // set delayFocus to true.
    if (props.loading) {
      delayFocus.value = true;
    } else {
      await setSearchFocus();
    }
  }
});

watchEffect(() => {
  q.value = props.modelValue;
});

watchEffect(async () => {
  // We watch for loading to become false and if delayFocus was set
  // to true we focus on the search input.
  if (!props.disableAutoFocus && !props.loading && delayFocus.value) {
    await setSearchFocus();

    // We set delayFocus back to false to ensure this watcher
    // has no further effect.
    delayFocus.value = false;
  }
});

defineExpose({
  setSearchFocus,
  setItemFocus,
  resetItemFocus,
  forceUpdateFocusItems,
});
</script>

<template>
  <div class="swm-selection-content" :class="computedClasses">
    <UtilFocusItems
      ref="focusItems"
      :disable-auto-focus="disableAutoFocus"
      class="swm-selection-content__container"
      @close="() => emit('close')"
    >
      <template v-if="!disableHeader">
        <slot name="header">
          <header class="swm-selection-content__header">
            <slot name="header-prefix" />

            <BaseInput
              ref="search"
              v-model="q"
              type="search"
              :placeholder="placeholder"
              data-testid="swimm-selection-search"
              @update:model-value="onUpdateModelValue"
            />
            <slot name="header-additional" />
          </header>
        </slot>
        <BaseDivider />
      </template>

      <div class="swm-selection-content__content">
        <BaseLoading v-if="loading" variant="secondary" class="swm-selection-content__loading" />

        <BaseProse v-else-if="error" class="swm-selection-content__error-state" variant="danger" size="small">
          <BaseLayoutIcons>
            <template #leftIcon>
              <BaseIcon name="warning" />
            </template>
            {{ error }}
          </BaseLayoutIcons>
        </BaseProse>

        <div v-else-if="noResults" class="swm-selection-content__empty-state" data-testid="swimm-selection-empty-state">
          <slot name="no-results">
            <BaseProse size="small">No results found.</BaseProse>
          </slot>
        </div>

        <template v-else>
          <slot />
        </template>
      </div>

      <slot name="footer" />
    </UtilFocusItems>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-selection-content {
  $self: &;

  @include defaults;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  max-height: 400px;

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    gap: var(--space-small);
    padding: var(--space-xsmall);
  }

  &__loading {
    margin-bottom: var(--space-small);
  }

  &__empty-state,
  &__error-state {
    margin: var(--space-small);
    text-align: center;
  }

  &__content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &--no-header {
    padding-top: var(--space-xsmall);
  }
}
</style>
