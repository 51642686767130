/* eslint-disable no-console */
// / <reference lib="DOM" />
import { datadogLogs } from '@datadog/browser-logs';
import axios from 'axios';
import { isWebWorker } from 'browser-or-node';
import pino from 'pino';
import { LoggerOptions, loggerRedactedPaths } from './types';
import fastRedact from 'fast-redact';

let globalOptions: LoggerOptions = {};
const globalContext: pino.Bindings = {};
let write: (o: object) => void;

interface LogProps {
  level: number;
  msg?: string;
  [key: string]: unknown;
  err?: Error;
}

let redact: fastRedact.redactFnNoSerialize | null = null;
function getRedact() {
  if (redact == null) {
    redact = fastRedact({ paths: loggerRedactedPaths, serialize: false });
  }

  return redact;
}

// XXX DataDog sux https://github.com/DataDog/browser-sdk/issues/432
if (isWebWorker) {
  write = function write(o) {
    if (!globalOptions.datadogBrowser) {
      return;
    }

    const props = o as LogProps;

    if (props.level < logger.levels.values[logger.level]) {
      return;
    }

    const context = {
      ddsource: 'browser',
      ddtags: `env:${globalOptions.datadogBrowser.env || 'development'}`,
      message: props.msg ?? '',
      service: globalOptions.datadogBrowser.service,
      ...{ ...globalContext, ...props, msg: undefined },
    };
    const params = new URLSearchParams({
      'dd-api-key': globalOptions.datadogBrowser.clientToken,
    });
    axios
      .post(
        `${globalOptions.datadogBrowser.proxy}`,
        [globalOptions.noRedact ? context : (getRedact()(context) as object)],
        {
          params: {
            ddforward: `/api/v2/logs?${params.toString()}`,
          },
        }
      )
      .catch((reason) => console.error(reason));
  };
} else {
  write = function write(o) {
    if (globalOptions.datadogBrowser == null) {
      return;
    }

    const props = o as LogProps;

    let logMethod: (message: string, messageContext?: object, error?: Error) => void;
    const level = pino.levels.labels[props.level] as pino.Level;

    if (props.level < logger.levels.values[logger.level]) {
      return;
    }

    switch (level) {
      case 'trace':
      case 'debug':
        logMethod = datadogLogs.logger.debug.bind(datadogLogs.logger);
        break;
      case 'info':
        logMethod = datadogLogs.logger.info.bind(datadogLogs.logger);
        break;
      case 'warn':
        logMethod = datadogLogs.logger.warn.bind(datadogLogs.logger);
        break;
      case 'error':
      case 'fatal':
        logMethod = datadogLogs.logger.error.bind(datadogLogs.logger);
        break;
      default: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _exhaustiveCheck: never = level;
        // TODO Breaks due to not strict
        // Return _exhaustiveCheck;
      }
    }

    const context = {
      ...globalContext,
      ...o,
      msg: undefined, // given in a paramerter
      err: undefined, // given in a paramerter
    };
    logMethod(props.msg ?? '', globalOptions.noRedact ? context : (getRedact()(context) as object), props.err);
  };
}

const children: Array<pino.Logger> = [];

// XXX https://github.com/pinojs/pino/issues/591
// XXX https://github.com/pinojs/pino/issues/1556
const logger: pino.Logger = pino({
  browser: {
    transmit: {
      send(level, logEvent) {
        if (!globalOptions.console) {
          return;
        }

        const consoleLevel = level === 'fatal' ? 'error' : level;

        const bindings = Object.assign({ ...globalContext }, ...logEvent.bindings);

        if (logEvent.messages[0] !== null && typeof logEvent.messages[0] === 'object') {
          Object.assign(bindings, logEvent.messages.shift());
        }

        console[consoleLevel](
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          ...logEvent.messages,
          bindings
        );
      },
    },
    write,
  },
  onChild(child: pino.Logger) {
    children.push(child);
  },
});

export function initializeLogger(options?: LoggerOptions) {
  try {
    globalOptions = options ?? {};

    if (options?.datadogBrowser) {
      if (!isWebWorker) {
        datadogLogs.init(options.datadogBrowser);
      }
    }
  } catch (error) {
    console.error('Failed to initialize logger: %o', error);
  }
}

export function setLogLevel(level: string) {
  for (const child of children) {
    child.level = level;
  }
  logger.level = level;
}

export function getLoggerNew(name: string) {
  return logger.child({ name });
}
export type LoggerNew = pino.Logger;

export function setGlobalContext(context: pino.Bindings): void {
  Object.assign(globalContext, context);
}

export function unsetGlobalContext(keys: string[]): void {
  for (const key of keys) {
    delete globalContext[key];
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withLogContext<R, TArgs extends any[]>(
  context: pino.Bindings,
  cb: (...args: TArgs) => R,
  ...args: TArgs
): R {
  // Noop, no async context support in browser, can be done with Zone.js, but its risky
  return cb(...args);
}
