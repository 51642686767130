<script setup lang="ts">
import { NodeViewContent } from '@tiptap/vue-3';
import { computed } from 'vue';
const props = defineProps<{
  isEditMode: boolean;
  isEmpty: boolean;
  error: string | null;
  svg: string | null;
  shouldShowMermaidEmptyState: boolean;
  editorOverlapped: boolean;
  placeholder: string | null;
  isSideBySideView: boolean;
  showEditor: boolean;
}>();

const mainDirection = computed(() => (props.isSideBySideView && props.showEditor ? 'row-reverse' : 'column'));
</script>
<template>
  <div class="mermaid-panels">
    <div
      :contenteditable="false"
      :class="[
        'container',
        {
          clickable: isEditMode,
          'side-container': isSideBySideView && showEditor,
          'top-container': !isSideBySideView && showEditor,
        },
      ]"
    >
      <div
        v-if="svg"
        :contenteditable="false"
        class="diagram"
        :class="{ 'invalid-diagram': !!error, 'empty-state': shouldShowMermaidEmptyState }"
        data-testid="mermaid-diagram"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <pre class="mermaid" v-html="svg"></pre>
      </div>
      <div v-if="shouldShowMermaidEmptyState" class="mermaid-edit-empty-state"></div>
    </div>
    <div
      class="editor"
      :class="{
        'editor-overlapped': editorOverlapped,
        'hide-editor': !showEditor,
        'side-editor': isSideBySideView && showEditor,
      }"
    >
      <NodeViewContent
        id="mermaid-content"
        as="pre"
        data-testid="mermaid-content"
        :data-placeholder="placeholder"
        :contenteditable="isEditMode"
        class="mermaid content"
        :class="{ 'is-empty': isEmpty && showEditor }"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.mermaid-panels {
  > * {
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: v-bind(mainDirection);

  .container {
    &.clickable {
      cursor: pointer;
    }

    .diagram {
      overflow: hidden;
      padding: var(--space-base);
      text-align: center;
    }

    .invalid-diagram {
      opacity: 0.2;
    }

    .mermaid-edit-empty-state {
      height: 140px;
      padding: var(--space-sm) var(--space-base);
    }

    &.side-container {
      border-left: 1px solid var(--color-border-default);
      flex-shrink: 0;
      flex-basis: 60%;
      border-radius: 0 4px 4px 0;

      .diagram {
        max-height: 45vh;
        min-height: 25vh; /* Edit mode needs to be fixed in hight */
        overflow: auto;
      }
    }

    &.top-container {
      border-bottom: 1px solid var(--color-border-default);
    }
  }

  .editor {
    top: 100%;
    width: 100%;
    z-index: 2;
    overflow: hidden;

    // Visually hidden CSS trick, https://css-tricks.com/inclusively-hidden/, so that the content can still be navigated to with the keyboard
    &.hide-editor {
      position: absolute;
      width: 1px;
      height: 1px;
      clip-path: inset(50%);
      overflow: hidden;
      white-space: nowrap;
    }

    &.editor-overlapped {
      pointer-events: none;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--text-color-secondary);
    }

    .content {
      padding: var(--space-sm) var(--space-base);
      font-family: var(--fontfamily-secondary);
      font-size: var(--body-S);
      line-height: var(--space-md);
      height: 140px;
      overflow: auto;
      background-color: var(--color-surface);

      &:focus-visible {
        outline: none;
      }

      &.is-empty::before {
        // TODO Proper color
        /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
        color: #adb5bd;
        content: attr(data-placeholder);
        /* stylelint-disable-next-line property-disallowed-list */
        float: left;
        height: 0;
        pointer-events: none;
      }
    }

    &.side-editor {
      display: flex;
      flex-direction: column;
      transform: scaleY(1);
      pointer-events: auto;
      flex-shrink: 0;
      flex-basis: 40%;

      &.editor-overlapped {
        pointer-events: none;
      }

      .content {
        flex: 1;
        max-height: 45vh;
        min-height: 25vh;
        font-size: var(--body-XS);
        overflow: auto;
        margin: 0;
      }
    }
  }
}
</style>
