<script setup lang="ts">
import OnboardingProgressBar from '@/common/components/organisms/OnboardingProgressBar.vue';
import { Action, Icon, SwText } from '@swimm/ui';
import OnboardingVideoItem from '@/common/components/organisms/OnboardingVideoItem.vue';
import OnboardingVideoButtons from '@/common/components/organisms/OnboardingVideoButtons.vue';
import { computed, onMounted, ref } from 'vue';
import {
  OnboardingSteps,
  ProductTourOrigin,
  WELCOME_VIDEO_ANALYTICS_STEP_NAME,
  WELCOME_VIDEO_FILE_NAME,
} from '@/modules/onboarding/consts';
import { productEvents } from '@swimm/shared';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { generateStepsVideosArray } from '@/modules/onboarding/utils';

enum Step {
  NEXT = 1,
  BACK = -1,
}

const props = defineProps({
  stepIndex: { type: Number, required: true },
  videosSource: { type: String, required: true },
  context: { type: String, required: true },
});

const emit = defineEmits(['next-step', 'next-screen']);

const analytics = useAnalytics();

const disableSwitchingStep = ref<boolean>(false);
const showBackButton = computed<boolean>(() => props.stepIndex > 0);

const currentVideoStep = ref<number>(0);
const isOnLastStep = computed<boolean>(() => props.stepIndex === OnboardingSteps.length - 1);

const videoControllerRefs = ref<(typeof OnboardingVideoItem)[]>([]);
const videoControllersRefsSkippedUnwrap = { videoControllerRefs }; // https://github.com/vuejs/core/issues/5525#issuecomment-1062336844

const allVideos = computed<string[]>(() => {
  const interactiveStepsAmount = OnboardingSteps.length - 1;
  return [
    `${props.videosSource}/${WELCOME_VIDEO_FILE_NAME}`,
    ...generateStepsVideosArray(interactiveStepsAmount, props.videosSource),
  ];
});

const nextButtonString = computed<string>(() => {
  if (isOnLastStep.value) {
    return props.context === ProductTourOrigin.WORKSPACE ? 'Close' : 'Jump in';
  }
  return 'Next';
});

onMounted(() => {
  reportStepViewed(WELCOME_VIDEO_ANALYTICS_STEP_NAME);
});

function isWelcomeVideo(index: number) {
  return index === 0;
}

function changeStep(amount: Step | number) {
  const newStep = props.stepIndex + amount;
  disableSwitchingStep.value = true;
  setTimeout(() => {
    disableSwitchingStep.value = false;
  }, 1000);
  videoControllerRefs.value[currentVideoStep.value].pause();
  currentVideoStep.value = OnboardingSteps[newStep].initialVideoIndex;
  if (isWelcomeVideo(newStep)) {
    reportStepViewed(WELCOME_VIDEO_ANALYTICS_STEP_NAME);
  } else {
    videoControllerRefs.value[currentVideoStep.value].play();
    reportStepViewed(newStep);
  }
  emit('next-step', newStep);
}

function reportStepViewed(step: number | string) {
  analytics.track(productEvents.ONBOARDING_STEP_VIEWED(step));
}

async function onNextStepClick() {
  if (isOnLastStep.value) {
    emit('next-screen');
  } else {
    changeStep(Step.NEXT);
  }
}

function onVideoPlay() {
  if (isWelcomeVideo(props.stepIndex)) {
    analytics.track(productEvents.ONBOARDING_STEP_INTERACTION(WELCOME_VIDEO_ANALYTICS_STEP_NAME));
  }
}

function onVideoFeatureActionClick(index: number) {
  const videoIndex = isWelcomeVideo(props.stepIndex) ? 0 : index + 1;
  currentVideoStep.value = OnboardingSteps[props.stepIndex].initialVideoIndex + videoIndex;
  videoControllerRefs.value[currentVideoStep.value].play();
  analytics.track(productEvents.ONBOARDING_STEP_INTERACTION(props.stepIndex), {
    'Interaction Index': videoIndex,
  });
}
</script>

<template>
  <div class="video-frame">
    <OnboardingVideoItem
      v-for="(videoSrc, index) in allVideos"
      v-show="currentVideoStep === index"
      :key="videoSrc"
      class="video-item"
      :ref="videoControllersRefsSkippedUnwrap.videoControllerRefs"
      :src="videoSrc"
      :muted="!isWelcomeVideo(index)"
      :show-controls="isWelcomeVideo(index)"
      @play="onVideoPlay"
    />
    <div class="video-footer">
      <SwText variant="headline3" class="footer-title">{{ OnboardingSteps[stepIndex].title }}</SwText>
      <OnboardingVideoButtons
        v-if="OnboardingSteps[stepIndex].buttons"
        class="video-buttons"
        :step="stepIndex"
        :buttons="OnboardingSteps[stepIndex].buttons"
        @button-action="onVideoFeatureActionClick"
      />
    </div>
  </div>
  <div class="button-container">
    <Action
      class="step-button back-button"
      :class="{ show: showBackButton, background: context === ProductTourOrigin.WORKSPACE }"
      :type="context === ProductTourOrigin.WORKSPACE ? 'button' : 'a'"
      :secondary="context === ProductTourOrigin.WORKSPACE"
      @click="!disableSwitchingStep && changeStep(Step.BACK)"
      :disabled="disableSwitchingStep"
    >
      <Icon name="back-browsing" no-padding /> Back
    </Action>
    <Action class="step-button next-button show" @click="onNextStepClick" :disabled="disableSwitchingStep">
      {{ nextButtonString }} <Icon v-if="!isOnLastStep" no-padding name="next-browsing" />
    </Action>
  </div>
  <OnboardingProgressBar :steps-amount="OnboardingSteps.length" :current-step="stepIndex" @step-click="changeStep" />
</template>

<style scoped lang="postcss">
.video-frame {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  border: 4px solid var(--color-status-info);
  border-radius: 16px;
  background: var(--color-bg);

  .video-item {
    max-width: 100%;
    box-shadow: 0 0 0 4px var(--color-status-info);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    z-index: 1; /* for box-shadow border to overcome footer background */
  }

  .video-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--space-sm);
    padding: var(--space-sm);

    .footer-title {
      display: flex;
      align-items: center;
      gap: var(--space-sm);
    }

    .video-buttons {
      max-width: 50%;
    }
  }
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-lg);
  margin-bottom: var(--space-md);
}

.step-button {
  visibility: hidden;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: var(--space-base);

  &.show {
    visibility: visible;

    &.back-button {
      cursor: pointer;
      display: flex; /* override link-button unset default */

      &.background {
        background: var(--color-bg);
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .video-frame .video-footer {
    .duck {
      display: none;
    }

    .video-buttons {
      max-width: 90%;
    }
  }
}
</style>
