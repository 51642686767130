/* eslint-disable @typescript-eslint/no-unused-vars */
import { DriverOptions } from './git-provider-base';
import { GitLabDriver } from './gitlab-driver';

export class TestingDriver extends GitLabDriver {
  public constructor(_driverOptions: DriverOptions) {
    super({
      baseUrl: 'http://e2e-git-server',
      authToken: 'ni84kcCsFZFuKCMXyAx3',
    });
  }
}
