import { useStore } from 'vuex';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { getLoggerNew, objectUtils, shortUuid } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export function useUnitIdGenerator() {
  const store = useStore();
  const drafts3Store = useDrafts3Store();
  const db_getSwimm = store.getters['database/db_getSwimm'];

  const isUnitIdFree = function ({ repoId, unitId }) {
    const swimm = db_getSwimm(repoId, unitId);
    if (swimm && !objectUtils.isEmpty(swimm)) {
      return false;
    }
    if (drafts3Store.drafts?.has(unitId)) {
      return false;
    }

    return true;
  };

  function generateNewUnitId({ repoId }) {
    let newId = shortUuid({ length: 8 });
    while (!isUnitIdFree({ repoId, unitId: newId })) {
      logger.info(`Generated unit id ${newId} already exists for repoId=${repoId}`);
      newId = shortUuid({ length: 8 });
    }
    return newId;
  }

  return { generateNewUnitId };
}
