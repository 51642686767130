import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Subscriber } from 'rxjs';
import type { SwmCellSnippet } from '@swimm/shared';

export const useSnippetStore = defineStore('snippetState', () => {
  // should find a better solution for this
  const editedSnippetIndex = ref<number>();
  let selectedSnippets: Subscriber<SwmCellSnippet | null> | undefined;
  const snippetToEdit = ref<SwmCellSnippet>();
  const isPlaceholder = ref<boolean>(false);
  const resetStore = () => {
    selectedSnippets = undefined;
    snippetToEdit.value = undefined;
    editedSnippetIndex.value = undefined;
    isPlaceholder.value = false;
  };

  return {
    selectedSnippets,
    isPlaceholder,
    resetStore,
    snippetToEdit,
    editedSnippetIndex,
  };
});
