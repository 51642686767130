import { debounce } from 'lodash-es';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { state as localState } from '@swimm/shared';

export const useLocalStateStatusStore = defineStore('localStateStatus', () => {
  const localStateDataReady = ref<Set<string>>(new Set());

  const setRepoLocalStateDataReadyDebounced = debounce(async (repoId) => {
    const repoData = await localState.getRepoFromLocalState(repoId);
    if (repoData && !!repoData.provider) {
      localStateDataReady.value.add(repoId);
    }
  }, 500);

  async function setIsRepoLocalStateDataReady(repoId: string) {
    // This function is called numerous times with conflicting data
    // We debounce it to make sure it's only called with the latest data
    setRepoLocalStateDataReadyDebounced(repoId);
  }

  return {
    localStateDataReady,
    setIsRepoLocalStateDataReady,
  };
});
