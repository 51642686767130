<template>
  <div>
    <div v-if="isLoading" class="item-wrapper loading" :class="{ inactive, highlighted: isHighlighted }">
      <div
        v-for="columnClass of ['name-column', 'status-column', 'user-column']"
        :key="columnClass"
        :class="[columnClass, 'skeleton-column']"
      >
        <TextSkeleton variant="body-S" class="text-skeleton" />
      </div>
      <div class="menu-column skeleton-column"></div>
    </div>
    <div
      v-else
      class="item-wrapper"
      :class="{ inactive, highlighted: isHighlighted, selected, selectable }"
      data-testid="list-item"
      :data-folder="type === DocumentationTypes.FOLDER"
    >
      <Icon name="drag" class="drag-handler" />
      <Checkbox
        v-if="selectable"
        class="checkbox"
        size="small"
        :model-value="selected"
        @click="
          $emit('select', $event);
          $event.preventDefault();
        "
      />
      <div class="name-column" @click="$emit('click', $event)">
        <component
          :is="docLink ? 'router-link' : 'div'"
          class="clickable name-link"
          :class="{ padded: !(showFolderPath && folderId) }"
          @click="routerLinkClicked"
          :to="docLink"
          :data-testid="isHighlighted ? 'highlighted' : ''"
          draggable="false"
        >
          <Icon v-if="collapseIcon" :name="collapseIcon" />
          <slot>
            <Icon v-if="shouldShowIcon" no-padding class="headline2 documentation-icon" :name="icon" />
            <ChangedDocumentationIcon v-else :type="type" :state="diffState" />
            <div class="item-data">
              <div class="name-line">
                <slot name="name">
                  <FilteredText v-if="filterText" :text="title" :filter-text="filterText" class="name" />
                  <SwText v-else class="name" variant="body-S" data-testid="item-name">
                    <EllipsisTooltip class="text-ellipsis" :content="title" :length="80">{{ title }}</EllipsisTooltip>
                  </SwText>
                </slot>
                <TagHandler
                  class="stage-tag"
                  v-if="IsDraftPendingTag"
                  :tag="stageTag"
                  :tag-style="tagStyle(stageTag.text)"
                />
                <Icon
                  v-if="hasVariations"
                  name="plan"
                  no-padding
                  class="clickable variations-icon"
                  @click.prevent="$emit('variations-click')"
                  v-tooltip="'Go to committed version'"
                />
                <div v-if="!hasVariations">
                  <TagHandler
                    v-for="(tag, index) in tags"
                    :key="`${tag.text}-${index}`"
                    :tag="tag"
                    :tag-style="tagStyle(tag.text)"
                  />
                </div>

                <BranchLabel v-if="branch" :branch="branch" class="section-chip body-S data-hj-suppress" />
                <DocTagLabel
                  v-if="showDocTags"
                  class="section-chip"
                  :doc-id="docId"
                  :draft-id="draftId"
                  :repo-id="repoId"
                />
              </div>
              <FolderPath v-if="showFolderPath && folderId" :folder-id="folderId" :repo-id="repoId" />
            </div>
          </slot>
        </component>
      </div>
      <div class="status-column">
        <TagHandler
          v-if="applicabilityStatus"
          data-testid="status"
          :data-applicability="applicabilityStatus.text"
          :tag="applicabilityStatus"
          :tag-style="tagStyle(applicabilityStatus.text)"
          class="status"
        />
      </div>
      <div class="user-column">
        <slot name="user-avatar">
          <BaseTooltip :content="creator">
            <BaseAvatar v-if="creator" :name="creator" :alt="creator" size="large" :url="creatorProfileImg" />
          </BaseTooltip>
        </slot>
      </div>
      <div class="activity-column">
        <slot name="activity" />
      </div>
      <slot name="info" />
      <div class="menu-column">
        <VDropdown
          v-if="hasOptionsSlot"
          class="menu"
          :class="{ 'show-menu-icon': showMenuIcon }"
          :triggers="['click']"
          theme="menu-no-arrow"
          @update:shown="menuVisibilityChanged($event, true)"
        >
          <template #default>
            <Icon no-padding data-testid="open-ellipsis" name="more" class="menu-icon" />
          </template>
          <template #popper>
            <slot name="options"></slot>
          </template>
        </VDropdown>
        <VDropdown
          v-if="hasCreateSlot"
          class="menu"
          :class="{ 'show-menu-icon': showMenuIcon }"
          :triggers="['click']"
          theme="menu-no-arrow"
          @update:shown="menuVisibilityChanged($event, false)"
        >
          <template #default>
            <Icon v-tooltip="'Create'" no-padding name="add" class="menu-icon create-menu-icon" />
          </template>
          <template #popper>
            <slot name="create"></slot>
          </template>
        </VDropdown>
      </div>
    </div>
  </div>
</template>

<script>
import TagHandler from '@/common/components/atoms/TagHandler.vue';
import BranchLabel from '@/modules/core/components/BranchLabel.vue';
import FilteredText from '@/modules/core/components/FilteredText.vue';
import DocTagLabel from '@/modules/core/components/DocTagLabel.vue';
import { DocumentationTypes } from '@/common/consts';
import { useRoute } from 'vue-router';
import { Checkbox, TextSkeleton } from '@swimm/ui';
import ChangedDocumentationIcon from '@/modules/core/components/ChangedDocumentationIcon.vue';
import FolderPath from '@/modules/folders/components/FolderPath.vue';
import EllipsisTooltip from '@/common/components/organisms/EllipsisTooltip.vue';
import { productEvents } from '@swimm/shared';
import { capitalize } from 'lodash-es';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { BaseAvatar, BaseTooltip } from '@swimm/reefui';

const PossiblePages = {
  REPO: 'Repo',
  EDITOR: 'Editor',
  PLAYLIST: 'Playlist',
  DOC: 'Doc',
};

export default {
  components: {
    EllipsisTooltip,
    TextSkeleton,
    TagHandler,
    BranchLabel,
    FilteredText,
    DocTagLabel,
    ChangedDocumentationIcon,
    FolderPath,
    Checkbox,
    BaseAvatar,
    BaseTooltip,
  },
  props: {
    isLoading: { type: Boolean, default: false },
    tags: { type: Array, default: () => [] },
    stageTag: { type: Object, default: null },
    icon: { type: String, default: null },
    title: { type: String, default: '' },
    filterText: { type: String, default: '' },
    branch: { type: String, default: '' },
    creator: { type: String, default: '' },
    inactive: { type: Boolean, default: false },
    applicabilityStatus: { type: Object, default: null },
    isHighlighted: { type: Boolean, default: false },
    docId: { type: String, default: '' },
    playlistId: { type: String, default: '' },
    draftId: { type: String, default: null },
    repoId: { type: String, default: '' },
    showDocTags: { type: Boolean, default: false },
    docLink: { type: String, default: '' },
    hasVariations: { type: Boolean, default: false },
    type: { type: String, default: DocumentationTypes.DOC },
    path: { type: String, default: '' },
    collapseIcon: { type: String, default: '' },
    diffState: { type: String, default: '' },
    folderId: { type: String, default: '' },
    selected: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    showFolderPath: { type: Boolean, default: false },
    creatorProfileImg: { type: String, default: '' },
  },
  emits: ['link-click', 'click', 'select', 'variations-click'],
  data() {
    return { showMenuIcon: false };
  },
  setup() {
    const route = useRoute();
    const analytics = useAnalytics();
    const { getFolderPath } = useFoldersStore();

    return { analytics, route, getFolderPath, DocumentationTypes };
  },
  computed: {
    shouldShowIcon() {
      return !!this.icon;
    },
    isInDocPage() {
      return this.route.fullPath.includes(`/docs`);
    },
    isInPlaylistPage() {
      return this.route.fullPath.includes(`/playlists`);
    },
    isInEditPage() {
      return this.route.fullPath.includes(`/playlists`);
    },
    currentPageContext() {
      if (this.isInEditPage) {
        return PossiblePages.EDITOR;
      } else if (this.isInPlaylistPage) {
        return PossiblePages.PLAYLIST;
      } else if (this.isInDocPage) {
        return PossiblePages.DOC;
      } else {
        return PossiblePages.REPO;
      }
    },
    hasOptionsSlot() {
      return !!this.$slots['options'];
    },
    hasCreateSlot() {
      return !!this.$slots['create'];
    },
    IsDraftPendingTag() {
      return this.stageTag && (this.stageTag.text === 'Local draft' || this.stageTag.text === 'PENDING');
    },
  },
  methods: {
    tagStyle(tag) {
      return tag.replace(/[ ]/, '-').toLowerCase();
    },
    menuVisibilityChanged(isOpen, isEllipsis) {
      this.showMenuIcon = isOpen;
      if (this.showMenuIcon) {
        if (isEllipsis) {
          this.analytics.track(productEvents.CLICKED_REPO_PAGE_ITEM_ELLIPSIS, {
            Type: capitalize(this.type),
            'Entity ID': this.docId || this.playlistId || this.folderId,
          });
        } else {
          this.analytics.track(productEvents.OPENED_FOLDER_ADD_MENU_ITEM, {
            'Folder ID': this.folderId,
            'Folder Path': this.getFolderPath(this.folderId, this.repoId),
            'In Breadcrumbs': false,
          });
        }
      }
    },
    routerLinkClicked(event) {
      this.$emit('link-click', event);
    },
  },
};
</script>

<style scoped lang="postcss">
.item-wrapper {
  display: flex;
  align-items: center;
  padding: 0 var(--space-base);
  height: 42px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;

  .skeleton-column {
    padding-right: var(--space-base);
    min-height: 26px;
    padding-top: 4px;
    padding-bottom: 4px;
    box-sizing: border-box;

    .text-skeleton {
      flex: 1;
    }
  }

  .drag-handler {
    cursor: grab;
    position: absolute;
    visibility: hidden;
    left: calc(var(--space-md) * -1);
    color: var(--text-color-disable);
  }

  &:not(.selectable) {
    padding-left: 32px;
  }

  .menu {
    visibility: hidden;

    &.show-menu-icon {
      visibility: visible;
    }

    &:hover :deep(.ellipsis-wrapper) {
      height: initial;
      border-radius: 4px;
      background-color: var(--color-hover);
    }

    :deep(.ellipsis-wrapper > .icon) {
      padding: 0;
    }
  }

  .checkbox {
    visibility: hidden;
  }

  .documentation-icon {
    margin-right: var(--space-base);
  }

  .menu-icon {
    opacity: var(--opacity-medium);
    cursor: pointer;

    &:hover {
      opacity: var(--opacity-full);
    }

    &.create-menu-icon {
      margin-left: var(--space-base);
    }
  }

  &.selected {
    background-color: var(--color-hover);

    .checkbox {
      visibility: visible;
    }
  }

  &:hover,
  &.selected {
    .drag-handler {
      visibility: visible;
    }
  }

  &:hover:not(.selected) {
    background-color: var(--color-hover);

    :deep(.doc-tags-icon) {
      display: block;
      padding: 0;
      height: 22px; /* Otherwise it makes the name line resize when it appears. */
    }

    .menu {
      visibility: visible;
    }

    .checkbox {
      visibility: visible;
    }
  }

  .name-column {
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 1;

    .name-link {
      display: flex;
      width: -webkit-fill-available;
      height: 100%;
      align-items: center;

      &.padded {
        padding: var(--space-base) 0;
      }

      .item-data {
        flex: 1;
        overflow: hidden;
      }

      .name-line {
        display: flex;
        flex: 1;
        height: 100%;
        align-items: center;
      }

      &:hover {
        color: var(--color-brand-hover);
      }

      :deep(.section-chip) {
        margin-right: var(--space-xs);
      }

      :deep(.name) {
        margin-right: var(--space-xs);
      }

      :deep(.variations-icon) {
        margin-right: var(--space-xs);
      }
    }

    .variations-icon {
      color: var(--text-color-primary);
      align-self: center;

      &:hover {
        background-color: var(--color-hover);
      }
    }

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .section-chip {
      color: var(--text-color-primary);

      :deep(.icon) {
        align-self: center;
      }

      :deep(.v-popper) {
        margin-left: var(--space-xs);
        display: flex;
        flex-direction: row;
        align-items: center;

        &:only-child {
          .icon-tag {
            padding: 0;
          }
        }
      }
    }
  }

  .status-column {
    display: flex;
    justify-content: center;
    width: 84px;
    box-sizing: border-box;
  }

  .user-column {
    display: flex;
    justify-content: center;
    width: 92px;
    padding-right: var(--space-base);
    box-sizing: border-box;
  }

  .activity-column {
    display: flex;
    justify-content: center;
    width: 160px;
    box-sizing: border-box;
    padding-right: var(--space-base);
    font-size: var(--fontsize-xs);

    :deep(.views-icon) {
      font-size: var(--fontsize-s);
    }
  }

  .menu-column {
    display: flex;
    position: relative;
    width: 54px;
    text-align: right;
  }

  .create-section {
    margin-right: var(--space-sm);
  }

  /* Incatice state on the internal columns only */
  &.inactive [class$='column'] {
    opacity: 0.3;
  }

  &.highlighted {
    box-shadow: inset 0 0 2px var(--color-brand);
  }

  .stage-tag {
    margin-right: var(--space-xs);
    font-size: var(--body-XS);
    /* Prevent tag from being squashed when doc name is long. */
    flex-shrink: 0;
  }
}
</style>
