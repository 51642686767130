import { useNotificationsStore } from '@swimm/editor';
import { PJSON_VERSION } from '@/config';
import { getLoggerNew } from '@swimm/shared';
import { useRouter } from 'vue-router';

const logger = getLoggerNew(__modulename);

const CHECK_INTERVAL = 1000 * 60;

export function useCheckSwimmVersion() {
  const { addNotification } = useNotificationsStore();
  const router = useRouter();

  async function fetchVersion() {
    try {
      const response = await fetch('/version.json', {
        method: 'GET',
      });
      if (response.ok) {
        return response.json();
      }
    } catch (error) {
      logger.debug('Failed to fetch swimm version file');
    }
  }

  function checkSwimmVersion() {
    setTimeout(async () => {
      const remoteVersionJson = await fetchVersion();
      if (remoteVersionJson && PJSON_VERSION !== remoteVersionJson.version) {
        addNotification('A new version of the app is available.', {
          autoClose: false,
          closeButtonText: 'Refresh',
          onCloseClick: () => router.go(0),
        });
      } else {
        checkSwimmVersion();
      }
    }, CHECK_INTERVAL);
  }

  return { checkSwimmVersion };
}
