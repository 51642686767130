<template>
  <InstallSlackApp
    class="align-content"
    v-if="!isSlackInstalled"
    :repo-id="repoId"
    :workspace-id="workspaceId"
    description="Connect to a Slack channel to automatically receive messages when relevant actions are performed in
  Swimm."
    origin="Integrations Page"
    @close="$emit('close')"
  />
  <SlackAppRepoSettings v-else :repo-id="repoId" :workspace-id="workspaceId" />
</template>

<script>
import InstallSlackApp from './InstallSlackApp.vue';
import SlackAppRepoSettings from './SlackAppRepoSettings.vue';

export default {
  components: { SlackAppRepoSettings, InstallSlackApp },
  props: {
    repoId: { type: String, default: null },
    workspaceId: { type: String, required: true },
    isSlackInstalled: { type: Boolean, required: true },
  },
  emits: ['close'],
};
</script>

<style scoped>
.align-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
