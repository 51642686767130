/**
 * swmPath node.
 *
 * Based on https://github.com/ueberdosis/tiptap/blob/164eebf07ced16e29e38f13909322ce301575355/packages/extension-link/src/link.ts
 *
 * @module
 */

import { applySwmPathAutosync, convertSwmPathToSmartElement } from '@/swmd/autosync';
import { type JSONContent, Node, VueNodeViewRenderer, mergeAttributes } from '@tiptap/vue-3';
import path from 'path-browserify';
import SwmPathNodeView from '../nodeViews/SwmPathNodeView.vue';
import { getSwimmEditorServices } from './Swimm';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import {
  ApplicabilityStatus,
  type Path,
  type SmartElementWithApplicability,
  isSmartElementWithNewInfo,
  productEvents,
} from '@swimm/shared';
import { isParentOfType } from '../utils';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    swmPath: {
      insertSwmPath: (value: string, repoId: string, type: 'file' | 'directory') => ReturnType;
      selectAndInsertSwmPath: () => ReturnType;
      applySwmPathAutosync: (
        pos: number,
        path: SmartElementWithApplicability<Path>,
        userRequested?: boolean
      ) => ReturnType;
      convertSwmPathToCode: (pos: number) => ReturnType;
    };
  }
}

export interface SwmPathOptions {
  /**
   * Custom HTML attributes that should be added to the rendered HTML tag.
   */
  HTMLAttributes: Record<string, unknown>;
}

export default Node.create<SwmPathOptions>({
  name: 'swmPath',

  group: 'inline',

  inline: true,

  addOptions() {
    return {
      HTMLAttributes: {
        target: '_blank',
        class: null,
      },
    };
  },

  addAttributes() {
    return {
      href: {
        isRequired: true,
      },
      title: {
        default: null,
      },
      repoId: {
        isRequired: true,
        parseHTML: (element) => {
          return element.getAttribute('data-repo-id');
        },
        renderHTML: (attributes) => {
          return { 'data-repo-id': attributes.repoId };
        },
      },
      repoName: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute('data-repo-name');
        },
        renderHTML: (attributes) => {
          return { 'data-repo-name': attributes.repoName };
        },
      },
      short: {
        default: false,
        parseHTML: (element) => {
          return element.hasAttribute('data-short');
        },
        renderHTML: (attributes) => {
          return attributes.short ? { 'data-short': '' } : null;
        },
      },
      customDisplayText: {
        default: null,
        parseHTML: (element) => {
          return element.hasAttribute('data-custom') ? element.textContent : null;
        },
        renderHTML: (attributes) => {
          return attributes.customDisplayText ? { 'data-custom': '' } : null;
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        priority: 51,
        // Based on https://github.com/ueberdosis/tiptap/blob/164eebf07ced16e29e38f13909322ce301575355/packages/extension-link/src/link.ts#L117
        tag: 'a[href]:not([href *= "javascript:" i])',
        getAttrs: (node) => (node as HTMLElement).hasAttribute('data-swm-path') && null,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    let text;
    if (node.attrs.customDisplayText) {
      text = node.attrs.customDisplayText;
    } else {
      if (node.attrs.short) {
        text = decodeURI(path.basename(node.attrs.href));
      } else {
        text = decodeURI(node.attrs.href);
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (node.attrs.repoId !== getSwimmEditorServices(this.editor!).repoId.value) {
        text = `(${node.attrs.repoName}) ${text}`;
      }
    }

    return [
      'a',
      mergeAttributes(
        {
          'data-swm-path': '',
        },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      text,
    ];
  },

  renderText({ node }) {
    let text;
    if (node.attrs.customDisplayText) {
      text = node.attrs.customDisplayText;
    } else {
      if (node.attrs.short) {
        text = decodeURI(path.basename(node.attrs.href));
      } else {
        text = decodeURI(node.attrs.href);
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (node.attrs.repoId !== getSwimmEditorServices(this.editor!).repoId.value) {
        text = `(${node.attrs.repoName}) ${text}`;
      }
    }

    return text;
  },

  addNodeView() {
    return VueNodeViewRenderer(SwmPathNodeView);
  },

  addCommands() {
    return {
      insertSwmPath:
        (value, repoId, type) =>
        ({ commands, dispatch, editor }) => {
          if (value) {
            value = type === 'directory' ? `/${value}/` : `/${value}`;
          }
          const swimmEditorServices = getSwimmEditorServices(editor);

          const node: JSONContent = {
            type: this.name,
            attrs: {
              href: encodeURI(value),
              repoId,
              repoName: swimmEditorServices.getRepoName(repoId),
            },
          };

          if (dispatch) {
            const smartElementInfo = convertSwmPathToSmartElement(
              ProseMirrorNode.fromJSON(editor.schema, node),
              swimmEditorServices.repoId.value,
              swimmEditorServices.branch.value,
              swimmEditorServices.repos.value.repos
            );

            const smartElement: SmartElementWithApplicability<Path> = {
              ...smartElementInfo,
              applicability: ApplicabilityStatus.Verified,
              newInfo: smartElementInfo,
            };
            swimmEditorServices.autosyncOutput.value.smartElements.set(smartElement.id, smartElement);
            void swimmEditorServices.external.setSmartElementCountInDB(
              swimmEditorServices.unitId.value,
              swimmEditorServices.repoId.value,
              swimmEditorServices.autosyncOutput.value
            );
          }

          return commands.insertContent(node);
        },

      selectAndInsertSwmPath:
        () =>
        ({ chain, dispatch, editor, state }) => {
          if (
            !isParentOfType(state.doc, state.selection.head, [
              'paragraph',
              'tableHeader',
              'tableCell',
              'mermaid',
              'heading',
            ])
          ) {
            return false;
          }

          const swimmEditorServices = getSwimmEditorServices(editor);

          if (!dispatch) {
            return chain().focus().insertSwmPath('', swimmEditorServices.repoId.value, 'file').run();
          }

          setTimeout(async () => {
            const value = await swimmEditorServices.selectPath();
            if (value != null) {
              editor.chain().focus().insertSwmPath(value.path, value.repoId, value.type).run();
              swimmEditorServices.external.trackEvent(productEvents.SMART_PATH_ADDED, {});
            } else {
              editor.chain().focus().run();
            }
          }, 0);

          return true;
        },

      applySwmPathAutosync:
        (pos, path, userRequested = true) =>
        ({ chain, editor }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);

          if (!isSmartElementWithNewInfo(path)) {
            return false;
          }

          const node = editor.state.doc.nodeAt(pos);
          if (!node) {
            return false;
          }

          if (node.type !== this.type) {
            return false;
          }

          return (
            chain()
              .command(({ dispatch, tr }) => {
                if (dispatch) {
                  applySwmPathAutosync(tr, node, pos, path);
                }

                return true;
              })
              // The state is the state after the previous command has ran when actually running the commandss
              .command(({ dispatch, state }) => {
                if (dispatch) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  const newNode = state.doc.nodeAt(pos)!;
                  const smartElementInfo = convertSwmPathToSmartElement(
                    newNode,
                    swimmEditorServices.repoId.value,
                    swimmEditorServices.branch.value,
                    swimmEditorServices.repos.value.repos
                  );

                  const smartElement: SmartElementWithApplicability<Path> = {
                    ...smartElementInfo,
                    applicability: ApplicabilityStatus.Verified,
                    newInfo: smartElementInfo,
                  };
                  swimmEditorServices.autosyncOutput.value.smartElements.set(smartElement.id, smartElement);

                  if (userRequested) {
                    swimmEditorServices.animations.animateNodeById(smartElement.id);
                  }
                }

                return true;
              })
              .run()
          );
        },

      convertSwmPathToCode:
        (pos) =>
        ({ commands, state }) => {
          const $pos = state.doc.resolve(pos);
          const node = $pos.nodeAfter;

          if (node?.type !== this.type) {
            return false;
          }

          return commands.insertContentAt(
            {
              from: pos,
              to: pos + node.nodeSize,
            },
            {
              type: 'text',
              text: node.attrs.href.replace(/^\//, ''),
              marks: [{ type: 'code' }],
            }
          );
        },
    };
  },
});
