import { useSnippetStore } from '@swimm/editor';
import type { EditorSnippet, SnippetSelection } from '@swimm/swmd';
import { type SwmCellSnippet, removePrefix } from '@swimm/shared';
import { Observable, Subscriber, map } from 'rxjs';

export class WebSnippetSelection implements SnippetSelection {
  constructor(private snippetStore: ReturnType<typeof useSnippetStore>) {}

  static fixForV3(snippet) {
    snippet.lines = snippet.lines.map((line) => removePrefix(line, '*'));
    return snippet;
  }

  editSnippet(initialSnippet?: SwmCellSnippet | null) {
    return this.selectImpl(initialSnippet);
  }

  selectSnippets(): Observable<EditorSnippet> {
    return this.selectImpl();
  }

  private selectImpl(initialSnippet?: SwmCellSnippet) {
    return new Observable((subscriber: Subscriber<SwmCellSnippet | null>) => {
      this.snippetStore.selectedSnippets = subscriber;

      if (initialSnippet) {
        this.snippetStore.snippetToEdit = initialSnippet;
      }
    }).pipe(
      map((selectedSnippet) => {
        if (selectedSnippet == null) {
          return null;
        }
        return {
          snippet: WebSnippetSelection.fixForV3(selectedSnippet).lines.join('\n'),
          path: selectedSnippet.path,
          line: selectedSnippet.firstLineNumber,
          repoId: selectedSnippet.repoId,
        };
      })
    );
  }
}
