import type { config } from '@swimm/shared';
import type { RouteLocationNamedRaw, RouteLocationPathRaw } from 'vue-router';

export enum DraftInvalidType {
  NEEDS_REVIEW = 'Fix outdated',
  EMPTY_TITLE = 'Add title',
  EMPTY_CONTENT = 'Add content',
  INVALID_STEPS = 'Fix invalid steps',
}

export type DraftType =
  (typeof config.SWIMM_FILE_TYPES)[keyof typeof config.SWIMM_FILE_TYPES] /* TODO Barf, can we use human TypeScript? */;

export interface ModalDraft {
  draftId: string /* TODO This changes in the new swmd */;
  type: DraftType;
  title: string;
  isNew?: boolean;
  branch: string;
  created: number;
  location: RouteLocationPathRaw | RouteLocationNamedRaw;
}
