<template>
  <div class="container" :class="{ completed, disabled }">
    <div v-if="icon || completed" class="icon-container">
      <Icon class="step-icon" :name="stepIcon" />
    </div>
    <div class="headline">
      <SwText variant="headline1" class="title system-subtitle">{{ headline }}</SwText>
      <div v-if="!completed" class="subtitle-secondary">
        {{ description }}
        <span v-if="!!learnMoreRoute" class="learn-link" @click="learnMoreLink()">Learn more</span>
      </div>
    </div>
    <div class="buttons-container">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { SwText } from '@swimm/ui';

const props = defineProps({
  headline: { type: String, required: true },
  disabled: { type: Boolean, default: false },
  completed: { type: Boolean, default: false },
  description: { type: String, required: true },
  icon: { type: String, default: 'hourglass' },
  learnMoreRoute: { type: String, default: undefined },
});
const emit = defineEmits(['learn-more-clicked']);

const stepIcon = computed(() => {
  if (props.completed) {
    return 'check';
  }
  if (props.disabled) {
    return 'locked';
  }

  return props.icon;
});

function learnMoreLink() {
  emit('learn-more-clicked');
  window.open(props.learnMoreRoute);
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border: 1px solid var(--color-border-default);
  background-color: var(--color-surface);
}

.subtitle-secondary {
  font-size: var(--body-S);
  font-family: var(--fontfamily-main);
  line-height: 1rem;
}

.headline {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 25%;
}

.title {
  padding: 0;
}

.icon-container {
  display: flex;
  align-items: center;
  margin-right: 25px;
  border: 2px solid var(--color-border-default);
  border-radius: 50%;
  color: var(--color-border-default);
  background-color: var(--color-bg);
}

.icon-container .step-icon {
  font-size: var(--headline3);
}

.container.disabled .icon-container {
  color: var(--text-color-on-dark);
  background-color: var(--text-color-disable);
}

.buttons-container {
  display: flex;
  flex-direction: row;
}

.learn-link {
  font-weight: bold;
  color: var(--text-color-link);
  cursor: pointer;
}

.container.completed {
  padding: 10px 24px;
}

.container.completed .icon-container {
  border-color: var(--text-color-success);
  color: var(--text-color-success);
  background-color: var(--color-status-success);
}
</style>
