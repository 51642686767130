import { Mark } from '@tiptap/core';
import Suggestion from '@tiptap/suggestion';
import { PluginKey } from '@tiptap/pm/state';
import { getParentNodeForPosition } from '../../common/tiptapUtils';
import { ALLOWED_PREFIXES } from '../../../consts';
import { pluginKeys } from '../pluginKeys';

export const CommandSuggestion = Mark.create({
  name: 'CommandSuggestion',
  addOptions: {
    isActive: true,
    matcher: {
      char: '/',
    },

    schema() {
      return {
        attrs: {
          label: {},
        },
        group: 'inline',
        inline: true,
        content: 'text*',
        selectable: false,
        atom: true,
        toDOM: (node) => `${this.options.suggestions.matcher.char}${node.attrs.label}`,
      };
    },
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        pluginKey: new PluginKey(this.name),
        editor: this.editor,
        command: ({ editor, props }) => {
          editor
            .chain()
            .setTextSelection(props.range)
            .command(({ tr }) => {
              props.props.label.replaceAll(' ', '');
              const node = editor.state.schema.text(props.props.label);
              tr.replaceSelectionWith(node);
              return true;
            })
            .run();
        },
        allow: ({ editor, state, range }) => {
          const nodeType = getParentNodeForPosition(range.from, state)?.type.name;
          if (nodeType === 'codeBlock') {
            return false;
          }
          if (pluginKeys.swmTokenSelection.getState(editor.state)?.active) {
            return false;
          }
          return true;
        },
        char: this.options.matcher.char,
        allowSpaces: true,
        allowedPrefixes: ALLOWED_PREFIXES,
        startOfLine: false,

        render: () => {
          return {
            onStart: (props) => {
              /* When we use / command the focus is sometimes on the dropdown and not on the actual text row
                 we want to store the editor row where the / command was fired from in order to modify the content in the right position
               */
              const row = getSelection().anchorNode?.parentElement;
              /* We check if the parent element is the row, if it is not we get the closest .draggable-item instead
              if the user clicks on the dropdown the element we get might be one of the childrens of draggable-item
              */
              if (row) {
                this.editor.selectedEl = row.matches('.draggable-item') ? row : row.closest('.draggable-item');
              }
              this.options.isActive = true;
              return this.options.onStart(props);
            },
            onUpdate: (props) => {
              return this.options.isActive && this.options.onUpdate(props);
            },
            onExit: (props) => {
              setTimeout(() => (this.editor.selectedEl = null), 0);
              return this.options.onExit(props);
            },
            onKeyDown: (props) => {
              if (props.event.key === 'Escape') {
                this.options.isActive = false;
                this.options.onExit(props);
                return true;
              }
              if (this.options.isActive) {
                return this.options.onKeyDown(props);
              }
              return false;
            },
          };
        },
        items: ({ query }) => {
          let commands = this.options.commands;

          if (query) {
            commands = commands
              .filter(({ title, aliases }) => filterCommandsByQuery([...(aliases || []), title], query.toLowerCase()))
              .sort((c1, c2) =>
                sortCommandsByQuery(c1.title.toLowerCase(), c2.title.toLowerCase(), query.toLowerCase())
              );
          }

          return commands?.map((command) => command.title);
        },
      }),
    ];
  },
});

function filterCommandsByQuery(commandMatchers, query) {
  return commandMatchers.some(
    (commandMatcher) => commandMatcher.toLowerCase().includes(query) && commandMatcher.toLowerCase() + ' ' !== query
  );
}

function sortCommandsByQuery(c1, c2, query) {
  // if both displayName contains query - sort alphabetically with higher priority for command starts with query
  if (c1.includes(query) && c2.includes(query)) {
    return c2.startsWith(query) - c1.startsWith(query) || c1.localeCompare(c2);
  }
  // if only one of commands' displayName contains query
  return c1.includes(query) ? -1 : 1;
}
