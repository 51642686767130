<script setup lang="ts">
import { Icon, TextField } from '@swimm/ui';
import { computed, ref, watch } from 'vue';

const props = defineProps({
  modelValue: { type: String, default: '' },
  placeholder: { type: String, default: 'Search' },
  disabled: { type: Boolean, default: false },
});
const emit = defineEmits(['update:modelValue']);

const input = ref<InstanceType<typeof TextField> | null>(null);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

watch(
  () => [props.disabled, input.value],
  () => {
    if (input.value && !props.disabled) {
      input.value.focus();
    }
  },
  { immediate: true, flush: 'post' }
);
</script>

<template>
  <div class="search-box">
    <TextField ref="input" v-model="value" :placeholder="placeholder" :disabled="disabled">
      <template #left>
        <Icon class="search-icon" name="search" />
      </template>
    </TextField>
  </div>
</template>

<style scoped>
.search-box {
  display: flex;
  padding: 0 10px 0 10px;
  background: var(--color-bg);

  .search-icon {
    color: var(--color-border-default);
    padding-left: 0;
  }
}
</style>
