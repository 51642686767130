<script setup lang="ts">
import { Icon, SwText } from '@swimm/ui';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { computed } from 'vue';

const props = defineProps<{
  folderId: string;
  repoId: string;
  showFolderIcon?: boolean;
  repoIcon?: string;
  repoName?: string;
}>();

const { getFolderBreadCrumbs } = useFoldersStore();

const folderCrumbs = computed(() => getFolderBreadCrumbs(props.folderId, props.repoId));

const folderShortPath = computed(() => {
  let path = props.repoName ?? '';
  if (folderCrumbs.value.length <= 1) {
    // Root folder
    return path;
  }
  const folder = folderCrumbs.value[folderCrumbs.value.length - 1];
  if (folderCrumbs.value.length === 2) {
    // Under root folder
    path = path ? `${path} / ` : '';
    return path + folder.name;
  }
  if (props.repoName) {
    return folderCrumbs.value.length === 3
      ? `${props.repoName} / ${folderCrumbs.value
          .slice(-2)
          .map((folder) => folder.name)
          .join(' / ')}`
      : `${props.repoName} / ... / ${folder.name}`;
  }
  return ` ... / ${folder.name}`;
});

const folderPath = computed(() => {
  if (props.repoName) {
    return folderShortPath.value;
  } else {
    const crumbsNames = folderCrumbs.value.map((folder) => folder.name);
    return crumbsNames.join(' / ');
  }
});
</script>

<template>
  <div v-if="folderPath" class="folder-path-container" data-testid="folder-path" v-tooltip="folderPath">
    <Icon v-if="repoIcon" :name="repoIcon" class="repo-icon" />
    <Icon v-if="showFolderIcon" name="folder" />
    <SwText variant="body-XS" class="folder-path">{{ folderShortPath }}</SwText>
  </div>
</template>

<style scoped lang="postcss">
.folder-path-container {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;

  .folder-path {
    color: var(--text-color-secondary);
  }

  .repo-icon {
    color: var(--text-color-primary);
    font-size: var(--fontsize-s);
  }
}
</style>
