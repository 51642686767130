export function isEmpty(obj: unknown): boolean {
  return typeof obj === 'object' && Object.keys(obj).length === 0;
}

export function isObject(testedObj: unknown): boolean {
  return typeof testedObj === 'object' && testedObj !== null;
}

export function deepClone<T>(obj: T): T {
  // Clone nested objects without references (for shallow copies, simply use "..." spread syntax)
  // Limitations: doesn't handle Dates, functions, undefined, Infinity, RegExps, Maps, Sets, Blobs, FileLists, ImageData objects, sparse Arrays, Typed Arrays or other complex types within your object
  return JSON.parse(JSON.stringify(obj));
}

export function filter(obj: unknown, predicate: (arg0: unknown) => boolean): unknown {
  // Function to filter Object values with a predicate function, similar to Array prototype filter method
  const clonedObj = deepClone(obj);
  return Object.keys(clonedObj)
    .filter((key) => predicate(clonedObj[key]))
    .reduce((res, key) => ((res[key] = clonedObj[key]), res), {});
}

export function deleteObjectKeys(object, keys) {
  keys.forEach((k) => delete object[k]);
}

export function clearUndefineds(object) {
  const keys = Object.keys(object).filter((key) => object[key] === undefined);
  deleteObjectKeys(object, keys);
}

export function formatObjectShallow(object): string {
  try {
    const toPrint = [];
    for (const k of Object.keys(object)) {
      const v = object[k];
      toPrint.push(`${k}: ${v}`);
    }
    return '{' + toPrint.join(', ') + '}';
  } catch (err) {
    return String(object);
  }
}

export function isIterable(obj: unknown) {
  return !!obj && typeof obj[Symbol.iterator] === 'function';
}

type JSONValue = string | number | boolean | { [x: string]: JSONValue } | Array<JSONValue>;

function isSimple(v) {
  return typeof v === 'string' || typeof v === 'number' || !v;
}

// return keys1 - keys2
function diffKeys(keys1: string[], keys2: string[]) {
  const s2 = new Set(keys2);
  return keys1.filter((k) => !s2.has(k));
}

function debugJsonDiffRec(diffs: Array<string>, path: string, v1: JSONValue, v2: JSONValue) {
  const isNull1 = v1 === null;
  const isNull2 = v2 === null;
  const isArray1 = Array.isArray(v1);
  const isArray2 = Array.isArray(v2);
  if (typeof v1 !== typeof v2 || isNull1 !== isNull2 || isArray1 !== isArray2) {
    diffs.push(`${path}: ${v1} <--> ${v2}`);
  }
  if (isSimple(v1) || isSimple(v2)) {
    if (v1 !== v2) {
      diffs.push(`${path} ${v1} <--> ${v2}`);
    }
    return;
  }
  if (isArray1 && isArray2) {
    if (v1.length !== v2.length) {
      diffs.push(`${path} length=${v1.length} <--> length=${v2.length}`);
      return;
    }
    for (let i = 0; i < v1.length; i++) {
      debugJsonDiffRec(diffs, `${path}[${i}]`, v1[i], v2[i]);
    }
    return;
  } else if (typeof v1 === 'object' && typeof v2 === 'object') {
    const keys = Object.keys({ ...v1, ...v2 });
    const keys1 = Object.keys(v1);
    const keys2 = Object.keys(v2);
    const keysIn1 = diffKeys(keys1, keys2);
    const keysIn2 = diffKeys(keys2, keys1);
    if (JSON.stringify(keys1) !== JSON.stringify(keys2)) {
      diffs.push(`${path} has keys [${keysIn1.join(',')}] <--> hasKeys [${keysIn2.join(',')}]`);
    }
    for (const key of keys) {
      if (!keysIn1.includes(key) && !keysIn2.includes(key)) {
        debugJsonDiffRec(diffs, `${path}.${key}`, v1[key], v2[key]);
      }
    }
  }
}

/* helper debug function to print diffs for json-able objects */

export function debugJsonDiff(v1: JSONValue | object, v2: JSONValue | object): string[] {
  const diffs = [];
  const j1 = JSON.parse(JSON.stringify(v1));
  const j2 = JSON.parse(JSON.stringify(v2));
  debugJsonDiffRec(diffs, '', j1, j2);
  return diffs;
}

/**
 * @param err that can be Error or something else (usually simple object)
 * @returns sting
 * tries to json stringify if this is not an Error
 */
export function formatError(err: unknown): string {
  const result = `${err}`;
  try {
    if (!(err instanceof Error)) {
      return JSON.stringify(err);
    }
  } catch {
    return result;
  }
  return result;
}

export function replaceUndefinedValues(obj: object, replaceWith: unknown = null): object {
  return replaceUndefinedValuesImpl(obj, replaceWith) as object;
}

/* convert all undefined to replaceWith - default to null */
function replaceUndefinedValuesImpl(obj: unknown, replaceWith: unknown = null) {
  if (Array.isArray(obj)) {
    // If the object is an array, iterate over its elements
    for (let i = 0; i < obj.length; i++) {
      obj[i] = replaceUndefinedValuesImpl(obj[i], replaceWith); // Recursively convert undefined values to null
    }
  } else if (typeof obj === 'object' && obj !== null) {
    // If the object is a non-null object, iterate over its properties
    for (const key in obj) {
      obj[key] = replaceUndefinedValuesImpl(obj[key], replaceWith); // Recursively convert undefined values to null
    }
  } else if (typeof obj === 'undefined') {
    // If the value is undefined, convert it to null
    obj = replaceWith;
  }
  return obj;
}
