<script setup lang="ts">
import { type Spacing } from '../../types';
import { computed, ref } from 'vue';

type GapDirection = 'row' | 'column';
type GapAlignment = 'center' | 'left' | 'right' | 'top' | 'bottom' | 'stretch';

const props = withDefaults(
  defineProps<{
    /**
     * The size of the gap.
     */
    size?: Spacing;
    /**
     * The direction of the elements.
     */
    direction?: GapDirection;
    /**
     * The alignment of the elements.
     */
    alignment?: GapAlignment;
    /**
     * Should the elements wrap the container?
     */
    wrap?: boolean;
    /**
     * The root element defaults to a `div`, however you can
     * use this component for spacing lists of elements so this
     * prop allows you to change the root element to a `ul` or `ol`.
     */
    wrapper?: string;
  }>(),
  {
    size: 'xsmall',
    direction: 'row',
    alignment: 'left',
    wrapper: 'div',
  }
);

const computedClasses = computed(() => {
  return {
    [`layout-gap--${props.size}`]: !!props.size,
    [`layout-gap--${props.direction}`]: !!props.direction,
    [`layout-gap--${props.alignment}`]: !!props.alignment,
    [`layout-gap--wrap`]: props.wrap,
  };
});

const baseLayoutGap = ref<HTMLElement | null>(null);

defineExpose({
  ref: baseLayoutGap,
});
</script>

<template>
  <component :is="wrapper" ref="baseLayoutGap" class="layout-gap" :class="computedClasses">
    <!-- @slot Any elements supplied through this slot will be evenly spaced based on the `size` prop. -->
    <slot />
  </component>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.layout-gap {
  $self: &;

  @include basic-resets;

  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
  align-items: center;
  display: flex;

  &--xxsmall {
    gap: var(--space-xxsmall);
  }

  &--xsmall {
    gap: var(--space-xsmall);
  }

  &--small {
    gap: var(--space-small);
  }

  &--medium {
    gap: var(--space-medium);
  }

  &--large {
    gap: var(--space-large);
  }

  &--xlarge {
    gap: var(--space-xlarge);
  }

  &--row {
    flex-direction: row;

    &#{$self}--center {
      justify-content: center;
    }

    &#{$self}--stretch {
      justify-content: space-between;
      width: 100%;
    }

    &#{$self}--left {
      justify-content: flex-start;
    }

    &#{$self}--top {
      align-items: flex-start;
      justify-content: flex-start;
    }

    &#{$self}--right {
      justify-content: flex-end;
    }

    &#{$self}--bottom {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  &--column {
    flex-direction: column;

    &#{$self}--center {
      align-items: center;
    }

    &#{$self}--stretch {
      align-items: stretch;
      width: 100%;
    }

    &#{$self}--left,
    &#{$self}--top {
      align-items: flex-start;
    }

    &#{$self}--right,
    &#{$self}--bottom {
      align-items: flex-end;
    }
  }

  &--wrap {
    flex-wrap: wrap;
  }
}
</style>
