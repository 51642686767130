<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { storeToRefs } from 'pinia';

import { SwimmResourceUserStatus, config, getLoggerNew } from '@swimm/shared';

import { Action } from '@swimm/ui';

import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useNotificationsStore } from '@swimm/editor';

type UpdateSwimmStatusType = {
  repoId: string;
  resourceId: string;
  userId: string;
  status: SwimmResourceUserStatus;
};

const logger = getLoggerNew(__modulename);

const { addNotification } = useNotificationsStore();

const store = useStore();
const { user } = storeToRefs(useAuthStore());

const props = withDefaults(
  defineProps<{
    repoId: string;
    resourceId: string;
    isVideo?: boolean;
  }>(),
  {
    isVideo: false,
  }
);

const emit = defineEmits<{
  (e: 'toggle-mark', status: SwimmResourceUserStatus);
}>();

const markingAsDone = ref(false);

const swimmStatus = computed(() =>
  store.getters['database/db_getSwimmStatus'](props.repoId, user.value.uid, props.resourceId)
);
const updateSwimmerStatus = (params: UpdateSwimmStatusType) => store.dispatch('database/updateSwimmerStatus', params);

const swimmStatusIsDone = computed(() => swimmStatus.value === config.SWIMMER_STATUSES.DONE);

const markButtonText = computed(() => {
  if (props.isVideo) {
    return swimmStatusIsDone.value ? 'Unmark as watched' : 'Mark as watched';
  } else {
    return swimmStatusIsDone.value ? 'Mark as unread' : 'Mark as read';
  }
});

async function toggleMarkAsRead() {
  markingAsDone.value = true;
  const wasDone = swimmStatusIsDone.value;
  const newStatus = wasDone ? config.SWIMMER_STATUSES.NOT_STARTED : config.SWIMMER_STATUSES.DONE;
  try {
    await updateSwimmerStatus({
      repoId: props.repoId,
      userId: user.value.uid,
      resourceId: props.resourceId,
      status: newStatus,
    });
    emit('toggle-mark', newStatus);
  } catch (e) {
    // if there is an error (which should not happen)
    // we just show notification and do nothing
    // we don't change the state/color of the button
    logger.error(`Could not mark as read: ${e}`, { service: 'mark-doc-done' });
    const errMsg = wasDone ? 'Failed to mark the document as unread' : 'Failed to mark the document as read';
    addNotification(errMsg, {
      icon: 'warning',
    });
  }
  markingAsDone.value = false;
}
</script>
<template>
  <Action
    v-tooltip="`Marking will help you track your progress mainly when going through a playlist`"
    :loading="markingAsDone"
    :variant="swimmStatusIsDone ? 'success' : undefined"
    class="done-btn"
    :trailing-icon="swimmStatusIsDone ? 'check' : undefined"
    size="small"
    @click="toggleMarkAsRead"
  >
    {{ markButtonText }}
  </Action>
</template>

<style scoped>
.done-btn {
  max-width: 200px;
}
</style>
