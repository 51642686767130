import { type SwimmDocumentEntry, listSwimmDocuments } from '../docs';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { gitwrapper } from '@swimm/shared';

export const useDocsStore = defineStore('docs', () => {
  const workspaceId = ref<string>();
  const repoId = ref<string>();
  const branch = ref<string>();

  const docs = ref<Record<string, SwimmDocumentEntry>>();
  const loadingDocs = ref(true);

  async function fetchDocs(newWorkspaceId: string, newRepoId: string, newBranch: string) {
    loadingDocs.value = true;
    docs.value = undefined;
    workspaceId.value = newWorkspaceId;
    repoId.value = newRepoId;
    branch.value = newBranch;

    docs.value = await listSwimmDocuments(repoId.value, branch.value);
    loadingDocs.value = false;
  }

  async function loadCrossRepoDoc({
    crossRepoId,
    id,
    defaultBranch,
  }: {
    crossRepoId: string;
    id: string;
    defaultBranch: string;
  }): Promise<string | null> {
    if (!defaultBranch) {
      throw new Error(`got empty defaultBranch`);
    }
    const crossRepoDocs = await listSwimmDocuments(crossRepoId, defaultBranch);
    const docInfo = crossRepoDocs[id];
    if (!docInfo) {
      throw new Error(`id is not found in listSwimmDocuments`);
    }
    return gitwrapper.getFileContentFromRevision({
      filePath: docInfo.path,
      repoId: crossRepoId,
      revision: defaultBranch,
    });
  }

  async function loadDoc(unitId: string) {
    const docInfo = docs.value[unitId];
    if (docInfo == null) {
      throw new Error('TODO Not found');
    }

    return gitwrapper.getFileContentFromRevision({
      filePath: docInfo.path,
      repoId: repoId.value,
      revision: branch.value,
    });
  }

  return {
    workspaceId,
    repoId,
    docs,
    fetchDocs,
    loadDoc,
    loadCrossRepoDoc,
    loadingDocs,
  };
});
