<script setup lang="ts">
import { useMultiPopupWorkaroundStore } from '@/components/EditorComponents/multiPopupWorkaroundStore';
import { watch } from 'vue';

const props = defineProps<{
  hide: () => void;
  shown: boolean;
}>();

const store = useMultiPopupWorkaroundStore();
watch(
  () => props.shown,
  (shown) => {
    if (!shown) {
      return;
    }
    store.onNewPopupShown(props.hide);
  }
);
</script>
