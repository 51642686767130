import type { ApplicabilityStatus, SwimmResourceUserStatus } from '@swimm/shared';

export enum PlaylistSequenceStepTypes {
  SWIMM = 'swimm',
  EXTERNAL_LINK = 'external_link',
  PLAYLIST = 'playlist',
  UNIT = 'unit',
  DOC = 'doc',
}

interface BasicPlaylistSequenceStep {
  id: string;
  name: string;
  repoId: string;
  link?: string;
}

export interface PlaylistSequenceStepSwimm extends BasicPlaylistSequenceStep {
  type: PlaylistSequenceStepTypes.SWIMM;
  isDraftLink?: boolean;
}

export interface PlaylistSequenceStepUnit extends BasicPlaylistSequenceStep {
  type: PlaylistSequenceStepTypes.UNIT;
  isDraftLink?: boolean;
}

export interface PlaylistSequenceStepExternalLink extends BasicPlaylistSequenceStep {
  type: PlaylistSequenceStepTypes.EXTERNAL_LINK;
  url: string;
  isDraftLink?: boolean;
}

export interface PlaylistSequenceStepNestedPlaylist extends BasicPlaylistSequenceStep {
  type: PlaylistSequenceStepTypes.PLAYLIST;
  isDraftLink?: boolean;
}

export type PlaylistSequenceStep =
  | PlaylistSequenceStepUnit
  | PlaylistSequenceStepSwimm
  | PlaylistSequenceStepExternalLink
  | PlaylistSequenceStepNestedPlaylist;

export interface PlaylistProgressBarStep {
  resource: PlaylistSequenceStep;
  status?: SwimmResourceUserStatus | ApplicabilityStatus.Unavailable | ApplicabilityStatus.Invalid;
  repoId: string;
  link: string;
  iconName: string;
  isAvailable: boolean;
}
