import { schema } from '@/swmd/extensions';
import type { JSONContent } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

export function isDocEmpty(content: JSONContent) {
  const defaultContent = schema.topNodeType.createAndFill()?.toJSON();
  return JSON.stringify(defaultContent) === JSON.stringify(content);
}

export function isContentEmptyOrWhitespace(content: JSONContent[] | JSONContent | undefined): boolean {
  if (!content) {
    return true;
  }
  if (Array.isArray(content)) {
    return content.every(isContentEmptyOrWhitespace);
  }
  if (content.type === 'text') {
    return !content.text || content.text.trim() === '';
  }
  if (content.type !== 'paragraph') {
    return false;
  }
  return isContentEmptyOrWhitespace(content.content);
}

export function isParentOfType(doc: ProseMirrorNode, pos: number, types: string[]): boolean {
  const $pos = doc.resolve(pos);
  return types.includes($pos.parent.type.name);
}

export function isGrandParentOfType(doc: ProseMirrorNode, pos: number, types: string[]): boolean {
  const $pos = doc.resolve(pos);
  return types.includes($pos.node(-1)?.type.name);
}

// Taken from https://github.com/ueberdosis/tiptap/blob/develop/packages/core/src/helpers/findParentNodeClosestToPos.ts#L14
// Can be replaced with https://tiptap.dev/docs/editor/api/node-positions#closest when we upgrade to Tiptap version > 2.2.0
export function isDescendantOfType(doc: ProseMirrorNode, pos: number, type: string): boolean {
  const $pos = doc.resolve(pos);
  for (let i = $pos.depth; i > 0; i -= 1) {
    if ($pos.node(i)?.type?.name === type) {
      return true;
    }
  }
  return false;
}

/**
 * Helper for iterating through the nodes in a document that changed
 * compared to the given previous document. Useful for avoiding
 * duplicate work on each transaction.
 *
 * @see https://github.com/ProseMirror/prosemirror-tables/blob/b569c2f9f63cb27eed6ada0bfd51ff434a03213b/src/fixtables.ts#L24
 */
export function changedDescendants(
  old: ProseMirrorNode,
  cur: ProseMirrorNode,
  offset: number,
  f: (node: ProseMirrorNode, pos: number) => void
): void {
  const oldSize = old.childCount,
    curSize = cur.childCount;
  outer: for (let i = 0, j = 0; i < curSize; i++) {
    const child = cur.child(i);
    for (let scan = j, e = Math.min(oldSize, i + 3); scan < e; scan++) {
      if (old.child(scan) === child) {
        j = scan + 1;
        offset += child.nodeSize;
        continue outer;
      }
    }
    f(child, offset);
    if (j < oldSize && old.child(j).sameMarkup(child)) {
      changedDescendants(old.child(j), child, offset + 1, f);
    } else {
      child.nodesBetween(0, child.content.size, f, offset + 1);
    }
    offset += child.nodeSize;
  }
}
