<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    query?: string;
  }>(),
  {
    query: null,
  }
);

const label = computed(() => {
  return props.query ? `New branch: ${props.query}` : 'New branch';
});
</script>

<template>
  <div class="branch-selector-new-branch">
    <SwText variant="subtitle-S" weight="bold"
      ><Icon class="branch-selector-new-branch__icon" name="add" />{{ label }}</SwText
    >
  </div>
</template>

<style scoped lang="postcss">
.branch-selector-new-branch {
  padding: var(--space-xs);
  width: 100%;
  color: var(--text-color-secondary);
  display: flex;
  box-sizing: border-box;

  &:hover {
    color: var(--color-brand);
  }

  &__icon {
    position: relative;
    top: -1px;
  }
}
</style>
