<script setup lang="ts">
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';

defineProps<{
  open: boolean;
}>();

const emit = defineEmits<{
  toggle: [];
}>();

function onToggle() {
  emit('toggle');
}
</script>

<template>
  <BaseIcon
    v-if="open"
    class="swm-selection-content-token-advanced-header-prefix"
    name="main-nav-expand"
    @click="onToggle"
  />
  <BaseIcon
    v-else
    class="swm-selection-content-token-advanced-header-prefix"
    name="collapse-main-nav"
    @click="onToggle"
  />
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-selection-content-token-advanced-header-prefix {
  $self: &;

  @include basic-resets;

  cursor: pointer;
  font-size: var(--font-size-medium);
}
</style>
