<script setup>
import { getLoggerNew } from '@swimm/shared';
import { SwText } from '@swimm/ui';
import { ref } from 'vue';

const logger = getLoggerNew(__modulename);

const props = defineProps({
  name: { type: String, required: true },
  icon: { type: String, default: '' },
  url: { type: String, default: null },
  handler: { type: Function, default: () => ({}) },
  closeAfterAction: { type: Boolean, default: true },
});
const emit = defineEmits(['close-ellipsis']);

const loading = ref(false);

const preformAction = async () => {
  try {
    loading.value = true;
    await props.handler();
  } catch (err) {
    logger.error({ err }, `action ${props.name} failed with ${err}`);
  }
  loading.value = false;
  if (props.closeAfterAction) {
    emit('close-ellipsis');
  }
};
</script>

<template>
  <component
    :is="url ? 'a' : 'li'"
    :href="url"
    target="_blank"
    data-testid="ellipsis-option"
    class="body-L option ellipsis-option clickable"
    @click="preformAction"
  >
    <Icon v-if="loading" name="lifebuoy-outline" class="loading" />
    <div v-else class="resource-container">
      <Icon no-padding :name="icon" class="icon" />
      <SwText variant="body-S" class="resource-text">{{ name }}</SwText>
      <Icon no-padding name="outbound-link" class="outbound-link-icon" />
    </div>
  </component>
</template>

<style scoped lang="postcss">
.loading {
  animation: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: inline-block;
}

.option {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  white-space: nowrap;
  color: var(--text-color-primary);
  list-style: none;

  &:hover {
    background: var(--color-hover);
  }

  .resource-container {
    display: flex;
    gap: var(--space-base);

    .icon {
      font-size: var(--body-L);
    }

    .outbound-link-icon {
      align-self: center;
      color: var(--text-color-secondary);
      font-size: var(--body-S);
    }
  }

  .resource-text {
    align-self: center;
  }
}
</style>
