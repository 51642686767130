<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useBatchImportStore } from '../stores/batch-import';

const { waitingForFiles } = storeToRefs(useBatchImportStore());
</script>

<template>
  <div>
    <Loader v-if="waitingForFiles" />
    <div v-else>Something went wrong</div>
  </div>
</template>
