import { sha256 } from 'js-sha256';

const KeyLength = 48;
const KeySalt = 'this-is-swimms-api-key-salt-733bf577d8-';

export function getRandomKey(): string {
  const bytes = crypto.getRandomValues(new Uint8Array(KeyLength));
  const array = Array.from(bytes);
  const hexPairs = array.map((b) => b.toString(16).padStart(2, '0'));
  return hexPairs.join('');
}

export function hashKey(key: string): string {
  const hash = sha256.create();
  hash.update(KeySalt);
  hash.update(key);
  return hash.hex();
}
