<template>
  <div class="base-select">
    <BaseLabel v-if="label" :html-for="id" :required="required">{{ label }}</BaseLabel>
    <v-select
      v-bind="$attrs"
      :id="id"
      :model-value="modelValue"
      :disabled="disabled"
      :options="options"
      :label="optionsLabelProp"
      :class="['select', { 'no-border': noBorder, 'body-appended': appendToBody, error: error }]"
      :clearable="false"
      :append-to-body="appendToBody || top"
      :taggable="taggable"
      :multiple="multiple"
      :placeholder="placeholder"
      :selectable="selectableValidator"
      @update:model-value="onModelChange"
      @option:selected="onInput"
    >
      <template #open-indicator="{ attributes }">
        <Icon v-if="!disabled" v-bind="attributes" class="icon" name="arrow-down" />
        <span v-else />
      </template>
    </v-select>
    <ErrorBox v-if="error" class="error-message">{{ error }}</ErrorBox>
  </div>
</template>

<script lang="ts">
import { shortUuid } from '@swimm/shared';
import BaseLabel from './BaseLabel.vue';
import ErrorBox from './ErrorBox.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { BaseLabel, ErrorBox },
  props: {
    modelValue: { type: [String, Object], default: null },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    options: { type: Array, required: true },
    optionsLabelProp: { type: String, required: false, default: undefined },
    top: { type: Boolean, default: false },
    noBorder: { type: Boolean, default: false },
    appendToBody: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    error: { type: String, default: '' },
    taggable: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    placeholder: { type: String, default: null },
    selectableValidator: { type: Function, default: () => true },
  },
  emits: ['input', 'update:modelValue'],
  computed: {
    id() {
      // Replace label spaces with dashes and add uuid to make it unique
      if (this.label) {
        return `${this.label.replace(/[ ]/g, '-').toLowerCase()}-${shortUuid()}`;
      }

      return '';
    },
  },
  methods: {
    onModelChange(value: string) {
      this.$emit('update:modelValue', value);
    },
    onInput(newValue: string) {
      this.$emit('input', newValue);
    },
  },
});
</script>

<style scoped lang="postcss">
.icon {
  padding: 0;
  font-size: var(--fontsize-m);
}

.base-select {
  width: 100%;
  text-align: left;
}
</style>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="postcss">
.v-select {
  input {
    font-family: var(--fontfamily-main);

    &.vs__search {
      color: var(--text-color-disable);
    }
  }
}

.vs__selected {
  color: var(--text-color-primary);
  background-color: var(--color-hover);
}

.ellipsis .vs__selected {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis .vs__selected-options {
  flex-wrap: nowrap;
  align-items: center;
  max-width: calc(100% - 25px);
}

.base-select .vs__deselect {
  fill: var(--text-color-secondary);
}

.vs__dropdown-menu.vs__dropdown-menu {
  background-color: var(--color-bg);
  box-shadow: var(--shadow-m);
  margin-top: 1px;
  border: 1px solid var(--color-border-default-subtle);
  z-index: 10001; /* Force fix overlap bug due to parent element being set to 10000. */
}

.vs__dropdown-menu {
  .vs__dropdown-option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--text-color-primary);
  }
}

.body-appended .vs__dropdown-menu {
  z-index: 1001;
}

:root {
  --border-radius: 8px;
}

.v-select.no-border .vs__dropdown-menu {
  padding: 0 0 2px;
  border-radius: 8px;
}

.vs--searchable .vs__dropdown-toggle {
  cursor: pointer;
}

.v-select.no-border .vs__dropdown-toggle,
.v-select.no-border .vs__dropdown-menu {
  border: none;
}

.v-select .vs__open-indicator {
  transform: scale(0.75);
}

.vs--open .vs__open-indicator {
  transform: rotate(180deg);
}

.v-select.vs--disabled .vs__clear,
.v-select.vs--disabled .vs__dropdown-toggle,
.v-select.vs--disabled .vs__open-indicator,
.v-select.vs--disabled .vs__search,
.v-select.vs--disabled .vs__selected .v-select:focus-within .vs__open-indicator {
  cursor: not-allowed;
  background-color: inherit;
}

.v-select.vs--open .vs__search {
  cursor: text;
}

.v-select.vs--searching:not(.vs--disabled) .vs__search {
  color: var(--text-color-primary);
  cursor: pointer;
}

.error.v-select .vs__dropdown-toggle {
  border-color: var(--color-border-danger);
}

.v-select.v-select:focus-within .vs__dropdown-toggle {
  border-color: var(--color-border-brand);
}

.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-top-color: transparent;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.v-select.v-select.no-border:focus-within .vs__dropdown-toggle {
  border: none;
  /* this overrides the border given by us in common.css, should be removed after fixing on a border color */
  border-color: transparent;
}

.vs__dropdown-option--selected,
.vs__dropdown-option--highlight {
  color: var(--text-color-primary);
  background: var(--color-hover);
}

.error-message {
  margin-top: 8px;
  text-align: left;
}

.vs__dropdown-menu[data-popper-placement='top'] {
  border-top-style: solid;
  border-bottom-style: none;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
