<template>
  <div data-testid="doc-general">
    <div v-if="createdDate" class="row" data-testid="doc-general-created">
      <div class="key body-S">
        <Icon name="file-created" class="row-icon headline3" />
        {{ 'Created' }}
      </div>
      <DateDisplay class="body-S" format="short" :date="createdDate" data-testid="doc-general-created-value" />
    </div>
    <div v-if="modifiedDate" class="row" data-testid="doc-general-modified">
      <div class="key body-S">
        <Icon name="clock" class="row-icon headline3" />
        {{ 'Last updated' }}
      </div>
      <DateDisplay class="body-S" format="short" :date="modifiedDate" data-testid="doc-general-modified-value" />
    </div>
  </div>
</template>

<script>
import DateDisplay from '@/common/components/atoms/DateDisplay.vue';

export default {
  components: {
    DateDisplay,
  },
  props: {
    doc: { type: Object, default: null },
  },
  computed: {
    createdDate() {
      if (this.doc && this.doc.id) {
        return this.doc.created;
      }
      return null;
    },
    modifiedDate() {
      if (this.doc && this.doc.id) {
        return this.doc.modified || this.doc.created;
      }
      return null;
    },
  },
};
</script>
<style scoped lang="postcss">
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;

  .key {
    display: flex;
    align-items: center;
  }
}
</style>
