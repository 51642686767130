<template>
  <Icon
    v-if="shouldShowIntegratedIcons"
    v-tooltip="{
      disabled,
      content: repo.metadata.isGithubAppInstalled
        ? gitIntegratedContents.gitIntegrated
        : gitIntegratedContents.gitNotIntegrated,
    }"
    :class="[
      'icon',
      {
        disabled,
        'git-integrated': repo.metadata.isGithubAppInstalled,
        'git-not-integrated': !repo.metadata.isGithubAppInstalled,
      },
    ]"
    :name="repo.metadata.isGithubAppInstalled ? 'git-integrated' : 'git-not-integrated'"
    @click.stop.prevent="handleRepoIntegrationClick"
  />
</template>

<script>
import { useAnalytics } from '@/common/composables/useAnalytics';
import { GitProviderName, productEvents } from '@swimm/shared';
import { mapGetters } from 'vuex';
import { UserRole } from '@/common/consts';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';

const gitIntegratedContents = {
  gitIntegrated: "Swimm's GitHub App is installed on this repo",
  gitNotIntegrated: 'Install the Swimm GitHub App on this repo to automatically sync your docs',
};

export default {
  props: {
    repo: { type: Object, required: true },
    workspaceId: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    clickable: { type: Boolean, default: true },
  },
  emits: ['click'],
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const { githubAppUrl } = storeToRefs(useWorkspaceStore());

    return { user, analytics, gitIntegratedContents, githubAppUrl };
  },
  computed: {
    ...mapGetters('database', ['db_isWorkspaceAdmin']),
    isWorkspaceAdmin() {
      return this.db_isWorkspaceAdmin(this.workspaceId, this.user.uid);
    },
    shouldShowIntegratedIcons() {
      return [GitProviderName.GitHub, GitProviderName.GitHubEnterprise].includes(this.repo.metadata.provider);
    },
  },
  methods: {
    handleRepoIntegrationClick() {
      if (!this.clickable) {
        return;
      }
      if (this.disabled) {
        return;
      }

      if (!this.isWorkspaceAdmin) {
        if (this.repo.metadata.isGithubAppInstalled) {
          return;
        }
        window.open(this.githubAppUrl, '_blank');
        return;
      }
      const userRole = this.isWorkspaceAdmin ? UserRole.ADMIN : UserRole.MEMBER;
      const origin = this.$route.params.repoId ? 'Repo' : 'Workspace';
      this.toggleIntegrationSettingsModal();
      this.analytics.track(productEvents.CLICKED_GITHUB_APP_YELLOW_SHIELD, {
        Origin: `View ${origin}`,
        Context: 'Install GitHub App Popup',
        'Workspace ID': this.workspaceId,
        'Repo ID': this.repo.metadata.id,
        'User Role': userRole,
      });
    },
    toggleIntegrationSettingsModal() {
      this.$emit('click', { origin: 'Repo Sidebar Yellow Shield' });
    },
  },
};
</script>

<style scoped lang="postcss">
.icon {
  cursor: pointer;
  padding: 0;
  font-weight: normal;

  &.disabled {
    cursor: not-allowed;
  }
}

.git-integrated {
  -webkit-text-fill-color: transparent;
  background: var(--swimm-shades);
  -webkit-background-clip: text;
  background-clip: text;
}

.git-not-integrated {
  color: var(--text-color-warning-strong);
}
</style>
