<template>
  <div class="base-button-bar">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.base-button-bar {
  $self: &;

  @include basic-resets;

  display: flex;
}
</style>
