import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { DBRepo, DocCacheStructure, DocDbItemInterface, GitProviderName } from '../types';
import { CrossRepoSnippet, CrossRepoSnippetDbItem } from '../utils/cross-repo';
import { getLogger } from '../logger/legacy-shim';

const logger = getLogger("packages/shared/src/api/api.ts");

export interface SwimmApi {
  baseUrl(): string;
  pjsonVersion(): string;

  validate(): Promise<boolean>;
  getWorkspace(): Promise<GetWorkspaceResponseV1>;
  getRepositoryData(params: Omit<GetRepositoryDataRequestV1, 'requestName'>): Promise<GetRepositoryDataResponseV1>;
  getSwimmFromDb(params: Omit<GetSwimmFromDbRequestV1, 'requestName'>): Promise<GetSwimmFromDbResponseV1>;
  updateSharedDoc(params: Omit<UpdateSharedDocRequestV1, 'requestName'>): Promise<UpdateSharedDocResponseV1>;
  deleteSharedDoc(params: Omit<DeleteSharedDocRequestV1, 'requestName'>): Promise<DeleteSharedDocResponseV1>;
  getRepoCrossSnippets(
    params: Omit<GetRepoCrossSnippetsRequestV1, 'requestName'>
  ): Promise<GetRepoCrossSnippetsResponseV1>;
  notifyOnDocsUpdate(params: Omit<SendDocUpdateNotificationsRequestV1, 'requestName'>): Promise<void>;
  notifyOnNewDoc(params: Omit<NewDocNotifyRequestV1, 'requestName'>): Promise<void>;
  notifyOnBrokenDocs(params: Omit<NotifyOnBrokenDocsRequestV1, 'requestName'>): Promise<void>;
  reportEvent(params: Omit<ReportEventRequestV1, 'requestName'>): Promise<void>;
  updateCrossRepoDbItems(params: Omit<UpdateCrossRepoDbItemsRequestV1, 'requestName'>): Promise<void>;
  getAutoSyncQuotaUsage(
    params: Omit<GetAutoSyncQuotaUsageRequestV1, 'requestName'>
  ): Promise<GetAutoSyncQuotaUsageResponseV1>;
  setInAutosyncCache(params: Omit<SetInAutosyncCacheRequestV1, 'requestName'>): Promise<void>;
  getFromAutosyncCache(params: GetFromAutosyncCacheRequestV1): Promise<GetFromAutosyncCacheResponseV1>;
  notifySharedQuestionsAnswered(params: Omit<NotifySharedQuestionsAnsweredRequestV1, 'requestName'>): Promise<void>;
}

export interface GitPlatformUser {
  gitPlatform: GitProviderName;
  userName: string;
}

export interface SendDocUpdateNotificationsRequestV1 {
  requestName: 'SendDocUpdateNotificationsRequestV1';
  repoId: string;
  docsToNotify: string[];
  defaultBranch: string;
}

export interface NotifyOnBrokenDocsRequestV1 {
  requestName: 'NotifyOnBrokenDocsRequestV1';
  repoId: string;
  brokenSnippets: CrossRepoSnippet[];
  userToUpdate: GitPlatformUser;
  prNumber: string;
  prURL: string;
  prTerminology?: string;
  prTitle?: string;
}

export interface NewDocNotifyRequestV1 {
  requestName: 'NewDocNotifyRequestV1';
  repoId: string;
  prId: string;
  newDocs: string[];
  gitUser: GitPlatformUser;
  defaultBranch?: string;
  branch?: string;
  base: string;
}
export interface ReportEventRequestV1 {
  requestName: 'ReportEventRequestV1';
  event: string;
  timestamp: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: Record<string, any>;
  gitUserIdForReporting?: GitPlatformUser;
}

export interface UpdateCrossRepoDbItemsRequestV1 {
  requestName: 'UpdateCrossRepoDbItemsRequestV1';
  repoId: string;
  dbItems: CrossRepoSnippetDbItem[];
  mergeSha: string;
}

export interface GetWorkspaceRequestV1 {
  requestName: 'GetWorkspaceRequestV1';
}

export interface GetWorkspaceResponseV1 {
  workspaceId: string;
  workspaceName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings?: Record<string, any>;
  repoIds: string[];
}

export interface AddRepositoryToWorkspaceRequestV1 {
  requestName: 'AddRepositoryToWorkspaceRequestV1';
  workspaceId: string;
  gitUrl: string;
  providerName?: GitProviderName;
  isPrivate?: boolean;
}

export interface AddRepositoryToWorkspaceResponseV1 {
  repoId: string;
  swimmJson: string;
}

export interface GetRepositoryDataRequestV1 {
  requestName: 'GetRepositoryDataRequestV1';
  repoId: string;
}

export type GetRepositoryDataResponseV1 = DBRepo;

export interface UpdateSharedDocRequestV1 {
  requestName: 'UpdateSharedDocRequestV1';
  sharedDocId: string;
  docId: string;
  repoId: string;
  workspaceId: string;
  swmd: string;
  autosyncOutput: string;
  docTitle: string;
  gitUser: GitPlatformUser;
  branch: string;
}

export type UpdateSharedDocResponseV1 = void;

export interface DeleteSharedDocRequestV1 {
  requestName: 'DeleteSharedDocRequestV1';
  sharedDocId: string;
  docId: string;
  repoId: string;
  workspaceId: string;
}

export type DeleteSharedDocResponseV1 = void;

export interface GetSwimmFromDbRequestV1 {
  requestName: 'GetSwimmFromDbRequestV1';
  repoId: string;
  docId: string;
}

type GetSwimmFromDbResponseV1 = DocDbItemInterface;

export interface GetAutoSyncQuotaUsageRequestV1 {
  requestName: 'GetAutoSyncQuotaUsageRequestV1';
}

export interface GetAutoSyncQuotaUsageResponseV1 {
  currentAutoSyncCount: number;
  isAutosyncLimitExceeded: boolean;
  isEightyPercentLimitExceeded: boolean;
  numOfAutosyncsLeft: number;
}

export interface GetRepoCrossSnippetsRequestV1 {
  requestName: 'GetRepoCrossSnippetsRequestV1';
  repoId: string;
}

export type GetRepoCrossSnippetsResponseV1 = CrossRepoSnippet[];

export type ValidateV1 = {
  requestName: 'ValidateV1';
};

export interface SetInAutosyncCacheRequestV1 {
  requestName: 'SetInAutosyncCacheRequestV1';
  workspaceId: string;
  cacheId: string;
  objectToSet: DocCacheStructure;
}

export interface GetFromAutosyncCacheRequestV1 {
  requestName: 'GetFromAutosyncCacheRequestV1';
  workspaceId: string;
  cacheId: string;
}

export type GetFromAutosyncCacheResponseV1 = DocCacheStructure | null;

export type NotifySharedQuestionsAnsweredRequestV1 = {
  requestName: 'NotifySharedQuestionsAnsweredRequestV1';
  repoId: string;
  docsToNotifyPaths: string[];
  branch: string;
};

export type ApiRequest =
  | SendDocUpdateNotificationsRequestV1
  | NewDocNotifyRequestV1
  | ReportEventRequestV1
  | GetWorkspaceRequestV1
  | GetSwimmFromDbRequestV1
  | GetRepositoryDataRequestV1
  | GetRepoCrossSnippetsRequestV1
  | NotifyOnBrokenDocsRequestV1
  | UpdateCrossRepoDbItemsRequestV1
  | GetAutoSyncQuotaUsageRequestV1
  | UpdateSharedDocRequestV1
  | DeleteSharedDocRequestV1
  | ValidateV1
  | SetInAutosyncCacheRequestV1
  | GetFromAutosyncCacheRequestV1
  | AddRepositoryToWorkspaceRequestV1
  | NotifySharedQuestionsAnsweredRequestV1;

export type RequestToResponse = {
  GetRepositoryDataRequestV1: GetRepositoryDataResponseV1;
  GetSwimmFromDbRequestV1: GetSwimmFromDbResponseV1;
  GetRepoCrossSnippetsRequestV1: GetRepoCrossSnippetsResponseV1;
  GetWorkspaceRequestV1: GetWorkspaceResponseV1;
  GetAutoSyncQuotaUsageRequestV1: GetAutoSyncQuotaUsageResponseV1;
  UpdateSharedDocRequestV1: UpdateSharedDocResponseV1;
  DeleteSharedDocRequestV1: DeleteSharedDocResponseV1;
  GetFromAutosyncCacheRequestV1: GetFromAutosyncCacheResponseV1;
  AddRepositoryToWorkspaceRequestV1: AddRepositoryToWorkspaceResponseV1;
};

export type InferResponse<T extends ApiRequest> = T['requestName'] extends keyof RequestToResponse
  ? RequestToResponse[T['requestName']]
  : never;

export type ApiRequestNames = ApiRequest['requestName'];

const ApiSuffix = 'api';

export class SwimmRemoteApi implements SwimmApi {
  _baseUrl: string;
  _pjsonVersion: string;

  apiUrl: string;
  apiKey: string;

  constructor(baseUrl: string, apiKey: string, pjsonVersion: string) {
    if (!baseUrl.endsWith('/')) {
      baseUrl += '/';
    }

    this._baseUrl = baseUrl;
    this._pjsonVersion = pjsonVersion;
    this.apiUrl = `${baseUrl}${ApiSuffix}`;
    this.apiKey = apiKey;
  }

  baseUrl(): string {
    return this._baseUrl;
  }

  pjsonVersion(): string {
    return this._pjsonVersion;
  }

  async validate(): Promise<boolean> {
    return await this.api({ requestName: 'ValidateV1' });
  }

  async getWorkspace(): Promise<GetWorkspaceResponseV1> {
    return await this.api({ requestName: 'GetWorkspaceRequestV1' });
  }

  async getAutoSyncQuotaUsage(
    params: Omit<GetAutoSyncQuotaUsageRequestV1, 'requestName'>
  ): Promise<GetAutoSyncQuotaUsageResponseV1> {
    return await this.api({ requestName: 'GetAutoSyncQuotaUsageRequestV1', ...params });
  }

  async notifyOnDocsUpdate(params: Omit<SendDocUpdateNotificationsRequestV1, 'requestName'>): Promise<void> {
    return await this.api({ requestName: 'SendDocUpdateNotificationsRequestV1', ...params });
  }
  async notifyOnNewDoc(params: Omit<NewDocNotifyRequestV1, 'requestName'>): Promise<void> {
    return await this.api({ requestName: 'NewDocNotifyRequestV1', ...params });
  }
  async updateCrossRepoDbItems(params: Omit<UpdateCrossRepoDbItemsRequestV1, 'requestName'>): Promise<void> {
    return await this.api({ requestName: 'UpdateCrossRepoDbItemsRequestV1', ...params });
  }
  async notifyOnBrokenDocs(params: Omit<NotifyOnBrokenDocsRequestV1, 'requestName'>): Promise<void> {
    return await this.api({ requestName: 'NotifyOnBrokenDocsRequestV1', ...params });
  }
  async reportEvent(params: Omit<ReportEventRequestV1, 'requestName'>): Promise<void> {
    return await this.api({ requestName: 'ReportEventRequestV1', ...params });
  }

  async updateSharedDoc(params: Omit<UpdateSharedDocRequestV1, 'requestName'>): Promise<void> {
    return await this.api({ requestName: 'UpdateSharedDocRequestV1', ...params });
  }
  async deleteSharedDoc(params: Omit<DeleteSharedDocRequestV1, 'requestName'>): Promise<void> {
    return await this.api({ requestName: 'DeleteSharedDocRequestV1', ...params });
  }

  async api<T extends ApiRequest>(params: T): Promise<InferResponse<T>> {
    const result = await axios.post(this.apiUrl, params, {
      headers: { 'Content-Type': 'application/json', 'x-swimm-api-key': this.apiKey },
    });

    if (result.status !== StatusCodes.OK) {
      throw new Error(`API request returned status=${result.status}`);
    }

    return await result.data;
  }

  async getRepositoryData(params: Omit<GetRepositoryDataRequestV1, 'requestName'>): Promise<DBRepo> {
    return await this.api({ requestName: 'GetRepositoryDataRequestV1', ...params });
  }

  async getSwimmFromDb(params: Omit<GetSwimmFromDbRequestV1, 'requestName'>): Promise<DocDbItemInterface> {
    return await this.api({ requestName: 'GetSwimmFromDbRequestV1', ...params });
  }

  async getRepoCrossSnippets(params: Omit<GetRepoCrossSnippetsRequestV1, 'requestName'>): Promise<CrossRepoSnippet[]> {
    return await this.api({ requestName: 'GetRepoCrossSnippetsRequestV1', ...params });
  }

  async setInAutosyncCache(params: Omit<SetInAutosyncCacheRequestV1, 'requestName'>): Promise<void> {
    try {
      return await this.api({ requestName: 'SetInAutosyncCacheRequestV1', ...params });
    } catch (err) {
      logger.warn(`Failed to set in autosync cache in swimmRemoteApi: ${err}`);
      return null;
    }
  }

  async getFromAutosyncCache(
    params: Omit<GetFromAutosyncCacheRequestV1, 'requestName'>
  ): Promise<GetFromAutosyncCacheResponseV1> {
    try {
      return await this.api({ requestName: 'GetFromAutosyncCacheRequestV1', ...params });
    } catch (err) {
      logger.warn(`Failed to get from autosync cache in swimmRemoteApi: ${err}`);
      return null;
    }
  }

  async notifySharedQuestionsAnswered(
    params: Omit<NotifySharedQuestionsAnsweredRequestV1, 'requestName'>
  ): Promise<void> {
    try {
      return await this.api({ requestName: 'NotifySharedQuestionsAnsweredRequestV1', ...params });
    } catch (err) {
      logger.warn(`Failed to notify shared questions answered in swimmRemoteApi: ${err}`);
      return null;
    }
  }
}
