// Based on https://github.com/microsoft/DevSkim/blob/2df6c2e541377a55e9cdbe805a8acfd42ec929a8/DevSkim-VSCode-Plugin/server/src/utility_classes/sourceContext.ts

import { lineRange } from './doc-hooks';
import { VSCodeLanguageIdentifier } from './language-identifier';

/**
 * Checks to see if the finding is within code that is commented out.
 * @param {VSCodeLanguageIdentifier} langID VSCode ID for the language
 * @param {string} documentContents the documentContents up to, but not including the finding
 * @param {number} newlineIndex the index of the most recent newline, for checking line comments
 * @returns {boolean} true iff in a comment
 *
 * @memberOf SourceComments
 */
export function isPatternMatchInComment(
  langID: VSCodeLanguageIdentifier,
  documentContents: string,
  newlineIndex: number,
  patternRange: lineRange
): boolean {
  if (documentContents.length < 1) {
    return false;
  }

  if (isCommentedInline(langID, documentContents, newlineIndex, patternRange)) {
    return true;
  }

  return isLineBlockCommented(langID, documentContents);
}

/**
 * Checks to see if the whole line is in a Block comment
 *
 * @param {VSCodeLanguageIdentifier} langID VSCode ID for the language
 * @param {string} documentContents the documentContents up to, but not including the next line
 * @returns {boolean} true iff in a comment
 *
 */
export function isLineBlockCommented(langID: VSCodeLanguageIdentifier, documentContents: string): boolean {
  const blockMarkers = getBlockCommentMarkers(langID);
  if (!blockMarkers) {
    return false;
  }
  const { blockCommentStart, blockCommentEnd } = blockMarkers;
  return documentContents.lastIndexOf(blockCommentStart) > documentContents.lastIndexOf(blockCommentEnd);
}

/**
 * Checks to see if the whole line is in a comment
 *
 * @static
 * @param {VSCodeLanguageIdentifier} langID VSCode ID for the language (should be lower case)
 * @param {string} documentContents the documentContents up to, but not including the next line
 * @param {number} newlineIndex the index of the most recent newline, for checking line comments
 * @returns {boolean} true if in a comment, false if active code
 *
 * @memberOf SourceComments
 */
export function isCommentedInline(
  langID: VSCodeLanguageIdentifier,
  documentContents: string,
  newlineIndex = -1,
  patternRange: lineRange
): boolean {
  if (documentContents.length < 1) {
    return false;
  }

  const startComment = getLineCommentPrefix(langID);
  if (!startComment) {
    // This language doesn't have inline comments
    return false;
  }

  const commentText: string = newlineIndex > -1 ? documentContents.substr(newlineIndex) : documentContents;
  if (
    commentText.indexOf(startComment) > -1 && // This line has inline comments //FIXME: This should account for cases like '// something'
    commentText.indexOf(startComment) < patternRange.columnStart // The pattern is inside the commented part
  ) {
    return true;
  }
  return false;
}

/**
 * Retrieve the characters to start a comment in the given language (ex. "//" for C/C++/C#/Etc. )
 *
 * @private
 * @param {VSCodeLanguageIdentifier} langID VSCode language identifier
 * @note we don't account for HTML-style comments in Javascript/Typescript
 * @returns {string | null} the characters to start a line comment, or empty string if the language doesn't have line comments. Null in case unknown.
 *
 */
function getLineCommentPrefix(langID: VSCodeLanguageIdentifier): string | null {
  switch (langID) {
    case 'vb':
      return "'";

    case 'lua':
    case 'sql':
    case 'tsql':
      return '--';

    case 'clojure':
      return ';;';

    case 'yaml':
    case 'shellscript':
    case 'ruby':
    case 'powershell':
    case 'coffeescript':
    case 'python':
    case 'r':
    case 'perl':
    case 'perl6':
      return '#';

    case 'jade':
      return '//-';

    case 'c':
    case 'cpp':
    case 'csharp':
    case 'fsharp':
    case 'groovy':
    case 'php':
    case 'javascript':
    case 'javascriptreact':
    case 'typescript':
    case 'typescriptreact':
    case 'java':
    case 'objective-c':
    case 'swift':
    case 'go':
    case 'rust':
      return '//';

    default:
      return null;
  }
}

interface BlockCommentMarkers {
  blockCommentStart: string;
  blockCommentEnd: string;
}

/**
 * Retrieves the opening characters for a block comment for the given language
 *
 * @static
 * @param {VSCodeLanguageIdentifier} langID  VSCode ID for the language (should be lower case)
 * @returns {BlockCommentMarkers | null}  starting and closing comment characters, if any (Null if not)
 */
function getBlockCommentMarkers(langID: VSCodeLanguageIdentifier): BlockCommentMarkers | null {
  switch (langID) {
    case 'c':
    case 'cpp':
    case 'csharp':
    case 'groovy':
    case 'php':
    case 'javascript':
    case 'javascriptreact':
    case 'typescript':
    case 'typescriptreact':
    case 'java':
    case 'objective-c':
    case 'swift':
    case 'go':
    case 'rust':
      return { blockCommentStart: '/*', blockCommentEnd: '*/' };

    case 'fsharp':
      return { blockCommentStart: '(*', blockCommentEnd: '*)' };

    case 'html':
    case 'xml':
      return { blockCommentStart: '<!--', blockCommentEnd: '-->' };

    default:
      return null;
  }
}
