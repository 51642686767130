<script setup lang="ts">
import { type PropType, computed } from 'vue';
import { DraftSwmFile } from '@swimm/shared';

const props = defineProps({
  dependantDrafts: { type: Array as PropType<{ title: string; type: DraftSwmFile['type'] }[]>, required: true },
});

defineEmits([
  'select-drafts-that-depend-on-selected-drafts-clicked',
  'unselect-drafts-that-depend-on-unselected-drafts-clicked',
]);

const unselectedDraftsActionText = computed(() => {
  if (props.dependantDrafts.length === 1) {
    const { type } = props.dependantDrafts[0];
    if (type === 'playlist') {
      return 'Add containing playlist to selection';
    }
    return 'Add linked doc to selection';
  }
  return 'Add linked docs to selection';
});

const unselectedDraftsDependencyText = computed(() => {
  if (props.dependantDrafts.length === 1) {
    const { type, title } = props.dependantDrafts[0];
    if (type === 'playlist') {
      return `Unselected playlist '${title}' contains selected doc(s) - either select it too or discard it.`;
    }
    return `Unselected doc '${title}' contains links to selected doc(s) - either select it too or discard it.`;
  }
  return 'Some unselected docs contain links to the selected doc(s) - either select them too or discard them.';
});
</script>

<template>
  <SwText variant="body-S">
    <div class="commit-blocking-message">
      <div>{{ unselectedDraftsDependencyText }}</div>
      <div>
        <span class="commit-blocking-cta" @click="$emit('select-drafts-that-depend-on-selected-drafts-clicked')">{{
          unselectedDraftsActionText
        }}</span>
        |
        <span class="commit-blocking-cta" @click="$emit('unselect-drafts-that-depend-on-unselected-drafts-clicked')"
          >Unselect</span
        >
      </div>
    </div>
  </SwText>
</template>

<style scoped>
.commit-blocking-message {
  margin: 16px 16px 0px 16px;
  padding: 12px 16px;
  color: var(--text-color-primary);
  background-color: var(--color-status-warning);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-base);
}

.commit-blocking-cta {
  color: var(--text-color-link);
  cursor: pointer;
}
</style>
