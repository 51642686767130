<script setup lang="ts">
import { Icon } from '@swimm/ui';
import type { ApplicabilityStatusPropsAndActions, TokenData } from './consts';
import { computed } from 'vue';

const props = defineProps<{
  symbolId: string;
  displayData: TokenData;
  symbolApplicability: string;
  isTokenCorrupted: boolean;
  hideApplicability: boolean;
  noAccessToRepo: boolean;
  clickable: boolean;
  computedApplicabilityStatus: ApplicabilityStatusPropsAndActions | undefined;
  shouldAnimateNode: () => boolean;
}>();

const emit = defineEmits<{
  (e: 'click'): void;
}>();

const shouldShowAcceptAutosyncableClass = computed(() => {
  return props.shouldAnimateNode();
});
</script>

<template>
  <span class="tooltip-target container" :class="{ symbolApplicability, clickable }" @click="emit('click')">
    <span
      class="text-node"
      :data-symbol-occurence="`symbol-${symbolId}`"
      :class="[symbolApplicability, { corrupted: isTokenCorrupted }, { accepting: shouldShowAcceptAutosyncableClass }]"
      :data-testid="`text-${symbolApplicability}`"
    >
      <span class="sym-name">{{ displayData.text }} </span>
      <Icon
        v-if="!hideApplicability && computedApplicabilityStatus && !noAccessToRepo && !isTokenCorrupted"
        :name="computedApplicabilityStatus.icon"
        class="applicability"
        data-testid="applicability-icon"
        :class="symbolApplicability"
      />
      <Icon v-else-if="noAccessToRepo" name="not-allowed" class="applicability" />
      <Icon
        v-else-if="isTokenCorrupted"
        name="unavailable-fill"
        class="applicability"
        :class="{
          corrupted: isTokenCorrupted,
        }"
      />
    </span>
  </span>
</template>

<style scoped lang="postcss">
.container {
  .text-node {
    font-family: var(--fontfamily-secondary);
    border-radius: 4px;
    background-color: var(--color-surface);
    padding-right: 3px !important;
    padding-left: 3px !important;
  }

  &.clickable {
    cursor: pointer;

    > .text-node {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .text-node.verified {
    &.accepting {
      animation-name: autosyncable-smart-element-accepted;
      animation-duration: 1s;
      animation-delay: 250ms;
    }
  }

  .text-node.outdated {
    background: var(--color-status-error);
  }

  .text-node.autosyncable {
    background: var(--color-status-magic);
  }

  .text-node.corrupted {
    background: var(--color-status-error);
  }

  .sym-name {
    padding-right: 2px;
  }

  .applicability {
    position: relative;
    bottom: 1px;
    padding: 0;
  }

  .applicability.verified {
    color: var(--text-color-success-strong);
  }

  .applicability.autosyncable {
    color: var(--text-color-magic-strong);
  }

  .applicability.outdated {
    color: var(--text-color-error-strong);
  }

  .applicability.corrupted {
    color: var(--text-color-error-strong);
  }

  .applicability.unknown {
    animation: spin;
    animation-duration: 1s;
    animation-direction: reverse;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    display: inline-block;
    transform-origin: center;
  }
}
</style>
