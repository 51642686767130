export const NOTIFICATION_TYPES = {
  // user is invited to join a workspace
  JOIN_WORKSPACE: 'JOIN_WORKSPACE',
  ASSIGNMENT: 'ASSIGNMENT',
  SLACK_INSTALLED: 'SLACK_INSTALLED',
  THANKS: 'THANKS',
  DOC_REQUEST_CREATED: 'DOC_REQUEST_CREATED', // TODO: add back when the feature won't be hidden
  DOC_REQUEST_COMPLETED: 'DOC_REQUEST_COMPLETED', // TODO: add back when the feature won't be hidden
  // user requests to join a workspace
  REQUESTED_JOIN_WORKSPACE: 'REQUESTED_JOIN_WORKSPACE',
  DOC_UPDATED: 'DOC_UPDATED',
  USER_JOINED_WORKSPACE: 'USER_JOINED_WORKSPACE',
  USER_LEFT_WORKSPACE: 'USER_LEFT_WORKSPACE',
  USER_ACCEPTED_INVITE: 'USER_ACCEPTED_INVITE',
  PR_BREAK_CROSS_REPO: 'PR_BREAK_CROSS_REPO',
  GITHUB_APP_SUSPENDED: 'GITHUB_APP_SUSPENDED',
  FIRST_DOC_VIEW: 'FIRST_DOC_VIEW',
  MANY_DOC_VIEWS: 'MANY_DOC_VIEWS',
  STIGG_CAP_REACHED: 'STIGG_CAP_REACHED',
  STIGG_CAP_90_REACHED: 'STIGG_CAP_90_REACHED',
  SHARE_ASK_SWIMM_QUESTION: 'SHARE_ASK_SWIMM_QUESTION',
  NOTIFY_SHARED_QUESTION_ANSWERED: 'NOTIFY_SHARED_QUESTION_ANSWERED',
  SIGNUP_EMAIL_PASSWORD_FAILED: 'SIGNUP_EMAIL_PASSWORD_FAILED',
} as const;

export type NotificationType = keyof typeof NOTIFICATION_TYPES;

export const NOTIFICATION_TARGETS = {
  EMAIL: 'EMAIL',
  IN_APP: 'IN_APP',
  SLACK: 'SLACK',
};

export type TargetsType = (typeof NOTIFICATION_TARGETS)[keyof typeof NOTIFICATION_TARGETS];

export type NotificationSettingsDataType = {
  type: NotificationType;
  title: string;
  initials: TargetsType[];
  noUserSettings: boolean;
  excludeTargets?: TargetsType[];
};
export const notificationsSettingsData: NotificationSettingsDataType[] = [
  {
    type: NOTIFICATION_TYPES.JOIN_WORKSPACE,
    title: 'Joined a workspace',
    initials: [NOTIFICATION_TARGETS.EMAIL, NOTIFICATION_TARGETS.IN_APP],
    noUserSettings: true,
  },
  {
    type: NOTIFICATION_TYPES.ASSIGNMENT,
    title: 'Doc assigned to me',
    initials: [NOTIFICATION_TARGETS.EMAIL, NOTIFICATION_TARGETS.IN_APP, NOTIFICATION_TARGETS.SLACK],
    noUserSettings: false,
  },
  {
    type: NOTIFICATION_TYPES.SLACK_INSTALLED,
    title: 'Admin installed Slack',
    initials: [NOTIFICATION_TARGETS.IN_APP],
    noUserSettings: true,
  },
  {
    type: NOTIFICATION_TYPES.THANKS,
    title: 'Reaction on a doc I contributed to',
    initials: [NOTIFICATION_TARGETS.EMAIL, NOTIFICATION_TARGETS.IN_APP, NOTIFICATION_TARGETS.SLACK],
    noUserSettings: false,
  },
  // {
  //   type: NOTIFICATION_TYPES.DOC_REQUEST_CREATED,
  //   title: "Doc I'm requested to document",
  //   initials: [NOTIFICATION_TARGETS.EMAIL, NOTIFICATION_TARGETS.IN_APP, NOTIFICATION_TARGETS.SLACK],
  //   noUserSettings: false,
  // },
  // {
  //   type: NOTIFICATION_TYPES.DOC_REQUEST_COMPLETED,
  //   title: 'Doc Request I created was marked as completed',
  //   initials: [NOTIFICATION_TARGETS.EMAIL, NOTIFICATION_TARGETS.IN_APP, NOTIFICATION_TARGETS.SLACK],
  //   noUserSettings: false,
  // },
  {
    type: NOTIFICATION_TYPES.REQUESTED_JOIN_WORKSPACE,
    title: 'User requested to join your workspace',
    initials: [NOTIFICATION_TARGETS.IN_APP],
    noUserSettings: true,
  },
  {
    type: NOTIFICATION_TYPES.DOC_UPDATED,
    title: 'Watched doc updated in default branch',
    initials: [NOTIFICATION_TARGETS.EMAIL, NOTIFICATION_TARGETS.IN_APP, NOTIFICATION_TARGETS.SLACK],
    noUserSettings: false,
  },
  {
    type: NOTIFICATION_TYPES.USER_JOINED_WORKSPACE,
    title: 'User joined workspace',
    initials: [NOTIFICATION_TARGETS.IN_APP],
    noUserSettings: true,
  },
  {
    type: NOTIFICATION_TYPES.USER_LEFT_WORKSPACE,
    title: 'User left workspace',
    initials: [NOTIFICATION_TARGETS.IN_APP],
    noUserSettings: true,
  },
  {
    type: NOTIFICATION_TYPES.USER_ACCEPTED_INVITE,
    title: 'User has accepted an invite to the workspace',
    initials: [NOTIFICATION_TARGETS.EMAIL],
    noUserSettings: true,
  },
  {
    type: NOTIFICATION_TYPES.PR_BREAK_CROSS_REPO,
    title: 'My PR broke a cross-repo content upon merge',
    initials: [NOTIFICATION_TARGETS.EMAIL, NOTIFICATION_TARGETS.SLACK],
    noUserSettings: false,
    excludeTargets: [NOTIFICATION_TARGETS.IN_APP],
  },
  {
    type: NOTIFICATION_TYPES.GITHUB_APP_SUSPENDED,
    title: 'My PR broke a cross-repo content upon merge',
    initials: [NOTIFICATION_TARGETS.EMAIL, NOTIFICATION_TARGETS.IN_APP],
    noUserSettings: true,
  },
  {
    type: NOTIFICATION_TYPES.FIRST_DOC_VIEW,
    title: 'Someone reads my doc for the first time',
    initials: [NOTIFICATION_TARGETS.EMAIL, NOTIFICATION_TARGETS.IN_APP, NOTIFICATION_TARGETS.SLACK],
    noUserSettings: false,
  },
  {
    type: NOTIFICATION_TYPES.MANY_DOC_VIEWS,
    title: 'My doc was read X times',
    initials: [NOTIFICATION_TARGETS.EMAIL, NOTIFICATION_TARGETS.IN_APP, NOTIFICATION_TARGETS.SLACK],
    noUserSettings: false,
  },
  {
    type: NOTIFICATION_TYPES.STIGG_CAP_REACHED,
    title: 'Limit reached',
    initials: [NOTIFICATION_TARGETS.EMAIL],
    noUserSettings: true,
  },
  {
    type: NOTIFICATION_TYPES.STIGG_CAP_90_REACHED,
    title: 'Limit reached',
    initials: [NOTIFICATION_TARGETS.EMAIL],
    noUserSettings: true,
  },
  {
    type: NOTIFICATION_TYPES.SHARE_ASK_SWIMM_QUESTION,
    title: 'Shared a question in Ask-Swimm',
    initials: [NOTIFICATION_TARGETS.EMAIL, NOTIFICATION_TARGETS.SLACK],
    noUserSettings: true,
  },
  {
    type: NOTIFICATION_TYPES.NOTIFY_SHARED_QUESTION_ANSWERED,
    title: 'Shared question was answered as doc',
    initials: [NOTIFICATION_TARGETS.EMAIL, NOTIFICATION_TARGETS.SLACK, NOTIFICATION_TARGETS.IN_APP],
    noUserSettings: true,
  },
  {
    type: NOTIFICATION_TYPES.SIGNUP_EMAIL_PASSWORD_FAILED,
    title: 'Signup failed with email/password',
    initials: [NOTIFICATION_TARGETS.EMAIL],
    noUserSettings: true,
  },
];

export const defaultNotificationValues = {
  dismissed: false,
  seen: false,
  emailed_at: null,
  slacked_at: null,
  routed_at: null,
  targets: [],
  slack_extra_data: null,
};

export const MAX_NOTIFICATIONS_TO_DISPLAY = 200;
