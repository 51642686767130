import Youtube from '@tiptap/extension-youtube';
import { isParentOfType } from '../utils';
import { getSwimmEditorServices } from './Swimm';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    youtube2: {
      selectAndInsertYoutubeVideo: () => ReturnType;
    };
  }
}

export default Youtube.extend({
  addCommands() {
    return {
      ...this.parent?.(),

      selectAndInsertYoutubeVideo:
        () =>
        ({ dispatch, editor, state }) => {
          if (!isParentOfType(state.doc, state.selection.head, ['paragraph'])) {
            return false;
          }

          if (dispatch) {
            const swimmEditorServices = getSwimmEditorServices(editor);

            setTimeout(async () => {
              const src = await swimmEditorServices.selectYouTube();

              if (src != null) {
                editor.chain().focus().setYoutubeVideo({ src }).run();
              }
            }, 0);
          }

          return true;
        },
    };
  },
});
