<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import Accordion from '@/modules/core/creation-hub/Accordion.vue';
import AccordionItem from '@/modules/core/creation-hub/AccordionItem.vue';
import CreationHubItem from '@/modules/core/creation-hub/CreationHubItem.vue';
import ImportSkeleton from '@/modules/core/creation-hub/ImportSkeleton.vue';
import DocSkeleton from '@/modules/core/creation-hub/DocSkeleton.vue';
import PlaylistSkeleton from '@/modules/core/creation-hub/PlaylistSkeleton.vue';
import PrToDocSkeleton from '@/modules/core/creation-hub/PrToDocSkeleton.vue';
import SnippetsToDocSkeleton from '@/modules/core/creation-hub/SnippetsToDocSkeleton.vue';
import DocPreview from '@/common/components/organisms/DocPreview.vue';
import { gitProviderUtils } from '@swimm/shared';
import PreviewContainer from './PreviewContainer.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { getLoggerNew, isRepoIdDummyRepo, productEvents } from '@swimm/shared';
import { SwModal } from '@swimm/ui';
import { DOCS_SITE_URL } from '@/config';
import BatchImportModal from '@/modules/batch-import/components/BatchImportModal.vue';
import { useBatchImportStore } from '@/modules/batch-import/stores/batch-import';
import { CreationHubSections, ItemIds } from './CreationHubConsts';
import { IMPORT_OPTIONS } from '@/modules/batch-import/constants';
import { useTemplatesStore } from '@/modules/core/stores/templates-store';
import { useRouting } from '@/common/composables/routing';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import CreationHubBanner from './CreationHubBanner.vue';
import { useStore } from 'vuex';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
const logger = getLoggerNew(__modulename);

const MIN_NAME_FOR_TOOLTIP = 35;
const { RUN_SMART_IMPORT, UPLOAD_CONFLUENCE_ZIP_FOLDER, UPLOAD_FOLDER, UPLOAD_NOTION_ZIP_FOLDER } = IMPORT_OPTIONS;

const props = defineProps({
  show: { type: Boolean, default: false },
  showRepoDropdown: { type: Boolean, default: false },
  preventSgdInNonDefaultBranch: { type: Boolean, default: false },
  limitedShownItems: { type: Array, default: () => null },
  showImportSection: { type: Boolean, default: true },
  folderId: { type: String, default: null },
});

defineExpose({
  onSectionChange,
  onSelectItem,
});

const emit = defineEmits(['close', 'new-doc-click', 'new-playlist-click']);
const { getCurrentOrDefaultBranch } = useRouting();
const store = useStore();
const route = useRoute();
const analytics = useAnalytics();
const selected = ref<string | number>(0);
const selectedPreview = ref();
const currentSection = ref(CreationHubSections.GENERAL);
const isLoadingTemplate = ref(false);
const batchImportStore = useBatchImportStore();
const { toggleBatchImportModal } = batchImportStore;
const { conversionType } = storeToRefs(batchImportStore);

const { isGenAIDisabledForWorkspace } = storeToRefs(useWorkspaceStore());

const selectedRepo = ref(null);
const { reposStateData } = storeToRefs(useReposStore());
const reposList = ref([]);

const workspaceRepos = computed(() =>
  store.getters['database/db_getWorkspaceRepos'](route.params.workspaceId).filter((repo) => !!repo)
);
const workspaceProvider = computed(() => store.getters['database/db_getWorkspace'](route.params.workspaceId)?.provider);

async function setReposList() {
  // in repo context - we take only the current repo
  const relevantRepos = route.params.repoId
    ? workspaceRepos.value.filter((item) => item.metadata.id === route.params.repoId)
    : workspaceRepos.value;
  const repos = relevantRepos
    .map((item) => ({
      key: item.metadata.id,
      label: item.metadata.name,
      provider: item.metadata.provider,
      api_url: item.metadata.api_url,
    }))
    .filter((item) => item.key);
  let filteredRepos = [];

  // identify repos the user isn't authorized to access
  for (const repo of repos) {
    if (repo.key) {
      if (isRepoIdDummyRepo(repo.key)) {
        filteredRepos.push({ ...repo, hasAccess: true });
        continue;
      }
      const defaultBranch = await getCurrentOrDefaultBranch(repo.key);
      if (defaultBranch) {
        filteredRepos.push({ ...repo, hasAccess: true });
      } else {
        filteredRepos.push({ ...repo, hasAccess: false });
      }
    }
  }

  // If the dummy repo is in the repoList, add it last
  const dummyRepo = filteredRepos.find((repo) => isRepoIdDummyRepo(repo.key));
  if (dummyRepo) {
    filteredRepos = [...filteredRepos.filter((repo) => !isRepoIdDummyRepo(repo.key)), dummyRepo];
  }
  reposList.value = filteredRepos;
}

const selectedRepoId = computed(() => selectedRepo.value?.key ?? route.params.repoId ?? '');

const items = computed(() =>
  [
    {
      id: ItemIds.BLANK_DOC,
      title: 'Blank doc',
      subtitle: 'Create a doc from scratch',
      icon: 'doc',
      previewComponent: DocSkeleton,
      handler: (branch) =>
        emit('new-doc-click', { query: { ...getFolderQuery() }, repoId: selectedRepoId.value, branch }),
      bannerTitle: 'Create a blank doc',
      bannerDescription:
        'Create a live code-coupled doc using snippets, Smart Tokens, and diagrams. Jot down anything you want to explain and remember. ',
    },
    {
      id: ItemIds.PULL_REQUEST,
      title: 'PR to doc',
      subtitle: 'Create doc from a PR',
      icon: 'branch-open-pr',
      previewComponent: PrToDocSkeleton,
      handler: (branch) =>
        emit('new-doc-click', {
          query: { action: 'pr', ...getFolderQuery() },
          repoId: selectedRepoId.value,
          branch,
          folderId: props.folderId,
        }),
      ai: true,
      bannerTitle: 'Create a doc from a pull request',
      bannerDescription: 'Import your PR changes as code snippets in just 1 click.',
    },
    {
      id: ItemIds.SNIPPETS,
      title: 'Code files to doc',
      subtitle: 'Create from multiple code snippets/files',
      icon: 'terminal',
      previewComponent: SnippetsToDocSkeleton,
      handler: (branch) =>
        emit('new-doc-click', {
          query: { action: 'snippets', ...getFolderQuery() },
          repoId: selectedRepoId.value,
          branch,
          folderId: props.folderId,
        }),
      ai: true,
      bannerTitle: 'Create a doc from code snippets or files using AI',
      bannerDescription: 'Add code snippets related to the topic you want AI to generate.',
    },
    {
      id: ItemIds.PLAYLIST,
      title: 'Playlist',
      subtitle: 'Create a collection of docs',
      icon: 'playlist',
      previewComponent: PlaylistSkeleton,
      handler: (branch) => {
        emit('new-playlist-click', {
          query: { action: 'playlist', ...getFolderQuery() },
          repoId: selectedRepoId.value,
          branch,
        });
      },
      bannerTitle: 'Create a playlist of docs',
      bannerDescription: 'Create a step-by-step walkthrough by organizing similar docs, links, and videos.',
    },
  ].filter(({ id }) => {
    if (isGenAIDisabledForWorkspace.value && id === ItemIds.SNIPPETS) {
      return false;
    }
    if (!props.limitedShownItems || props.limitedShownItems.includes(id)) {
      if (id !== ItemIds.PULL_REQUEST) {
        return true;
      } else {
        const provider = getRepoProvider() ?? workspaceProvider.value;
        return gitProviderUtils.doesProviderSupportPRToDoc(provider);
      }
    }
    return false;
  })
);
const selectedItem = computed(() => items.value[selected.value] ?? items.value[0]);

const importTypes = computed(() => [
  {
    id: ItemIds.IMPORT_MD_FILES,
    title: 'Upload .md files',
    subtitle: 'Manually upload your .md files',
    buttonText: 'Upload',
    learnMoreLink: `${DOCS_SITE_URL}/Import-and-Export/import-data-into-Swimm/#upload-md-files`,
    previewComponent: ImportSkeleton,
    previewTitle: 'From your computer',
    previewImage: 'import-computer',
    handler: () => handleImportMDClick(UPLOAD_FOLDER),
    bannerTitle: 'Upload',
    bannerDescription: 'Manually upload multiple Markdown files and “Swimmify” them.',
  },
  {
    title: 'Repo import',
    subtitle: 'Import .md files from your repo',
    buttonText: 'Import',
    learnMoreLink: `${DOCS_SITE_URL}/Import-and-Export/import-data-into-Swimm/#repo-import`,
    previewComponent: ImportSkeleton,
    previewTitle: 'Repo Markdown import',
    previewImage: 'import-markdown',
    handler: () => handleImportMDClick(RUN_SMART_IMPORT),
    bannerTitle: 'Batch import from your repo',
    bannerDescription:
      'Import and “Swimmify” Markdown files from your repo.<br/>**Docs will be copied to the *.swm* folder** - originals will not be affected.',
  },
  {
    title: 'Notion import',
    subtitle: 'Start with your Notion docs',
    buttonText: 'Import',
    learnMoreLink: `${DOCS_SITE_URL}/Import-and-Export/import-data-into-Swimm/#notion-import`,
    previewComponent: ImportSkeleton,
    previewTitle: 'From Notion',
    previewImage: 'import-notion',
    handler: () => handleImportMDClick(UPLOAD_NOTION_ZIP_FOLDER),
    bannerTitle: 'Import from Notion',
    bannerDescription: 'Batch import docs from a Notion-exported zip file, and “Swimmify” them.',
  },
  {
    title: 'Confluence import',
    subtitle: 'Start with your Confluence docs',
    buttonText: 'Import',
    learnMoreLink: `${DOCS_SITE_URL}/Import-and-Export/import-data-into-Swimm/#confluence-import`,
    previewComponent: ImportSkeleton,
    previewTitle: 'From Confluence',
    previewImage: 'import-confluence',
    handler: () => handleImportMDClick(UPLOAD_CONFLUENCE_ZIP_FOLDER),
    bannerTitle: 'Import from Confluence',
    bannerDescription: 'Batch import docs from a Confluence-exported zip file, and “Swimmify” them.',
  },
]);

const templatesStore = useTemplatesStore();
const { docTemplates: templates } = storeToRefs(templatesStore);
const { listDocTemplatesIfNeeded, loadDocTemplate } = templatesStore;

watch(
  () => props.show,
  async (newValue) => {
    if (newValue) {
      onShowModal();
    }
  },
  {
    immediate: true,
  }
);

async function onShowModal() {
  await setReposList();
  if (route.params.repoId) {
    selectedRepo.value = reposList.value.find((repo) => repo.key === route.params.repoId);
  } else if (selectedRepo.value?.key) {
    selectedRepo.value = reposList.value.find((repo) => repo.key === selectedRepo.value.key);
  } else if (reposList.value.length === 1) {
    selectedRepo.value = reposList.value[0];
  }
  if (!items.value[selected.value]) {
    selected.value = 0;
  }
  isLoadingTemplate.value = true;
  await listDocTemplatesIfNeeded();
  isLoadingTemplate.value = false;
  analytics.track(productEvents.VIEW_CREATION_HUB, { Type: 'Modal' });
}

async function onSectionChange(value) {
  currentSection.value = value;

  if (currentSection.value === CreationHubSections.GENERAL) {
    selected.value = 0;
  } else if (currentSection.value === CreationHubSections.IMPORT) {
    selected.value = 0;
  } else if (currentSection.value === CreationHubSections.TEMPLATES) {
    isLoadingTemplate.value = true;
    await listDocTemplatesIfNeeded();
    isLoadingTemplate.value = false;
    selected.value = Object.keys(templates.value)[0];
    onSelectTemplate(selected.value);
    loadAllTemplates(selected.value); // Load other templates without waiting on the background
  }
}
async function loadAllTemplates(templateToSkip) {
  for (const template of Object.values(templates.value)) {
    try {
      if (template !== templateToSkip) {
        await loadDocTemplate(template);
      }
    } catch (err) {
      logger.error({ err }, `could not fetch template: ${err}`);
    }
  }
}
function onClose(attrs?) {
  selected.value = 0;
  currentSection.value = CreationHubSections.GENERAL;
  emit('close', attrs);
}

function onSelectItem(index, title) {
  selected.value = index;
  analytics.track(productEvents.CLICK_CREATION_HUB_OPTION, { 'Option Clicked': title });
}

const handleImportMDClick = async (option) => {
  // Keep creation hub open for import type not smart import
  if (option === RUN_SMART_IMPORT) {
    onClose();
  }
  conversionType.value = option;
  toggleBatchImportModal(true, { repoId: selectedRepoId.value });
};

async function onSelectTemplate(templateId: string | number) {
  isLoadingTemplate.value = true;
  selected.value = templateId;
  await loadDocTemplate(templates.value[templateId]);
  selectedPreview.value = templates.value[selected.value];
  analytics.track(productEvents.CLICK_CREATION_HUB_TEMPLATE, { 'Template Name': templates.value[selected.value].name });
  isLoadingTemplate.value = false;
}

function getFolderQuery() {
  return props.folderId ? { folderId: props.folderId } : {};
}

async function onTemplateCreate() {
  const templateId = selectedPreview.value.id;
  const name = selectedPreview.value?.name || '';
  analytics.track(productEvents.TEMPLATE_ADDED, {
    'Template Name': name,
    Context: 'Regular',
    TemplateID: templateId,
  });
  emit('new-doc-click', {
    query: { template: templateId, ...getFolderQuery() },
    repoId: selectedRepoId.value,
    branch: await getBranch(),
  });
}

async function getRepoDefaultBranchName() {
  const repoFromState = reposStateData.value[selectedRepoId.value];
  return repoFromState?.defaultBranch || '';
}

function getRepoProvider() {
  const repoFromState = reposStateData.value?.[selectedRepoId.value];
  return repoFromState?.provider;
}

async function getBranch() {
  return route.params.branch || (await getRepoDefaultBranchName());
}

function reportItemClick(title) {
  analytics.track(productEvents.SELECTED_CREATION_HUB_OPTION, { 'Option Selected ': title });
}

function handleRepoChange(repo) {
  selectedRepo.value = repo;
}
</script>

<template>
  <SwModal heading="Create" :show-modal="show" @close="onClose" :padded="false">
    <div class="container">
      <div class="sidebar">
        <Accordion :current="currentSection" @change="onSectionChange">
          <AccordionItem title="GET STARTED" :id="CreationHubSections.GENERAL" data-testid="general-section">
            <CreationHubItem
              v-for="({ title, subtitle, icon, ai }, index) in items"
              :key="index"
              :title="title"
              :subtitle="subtitle"
              :icon="icon"
              :is-selected="selected === index"
              :is-ai="!isGenAIDisabledForWorkspace && ai"
              @select="onSelectItem(index, title)"
            />
          </AccordionItem>
          <AccordionItem title="TEMPLATES" :id="CreationHubSections.TEMPLATES" data-testid="templates-section">
            <Loader v-if="isLoadingTemplate" />
            <template v-else-if="templates">
              <CreationHubItem
                v-for="({ name }, templateId) in templates"
                :key="templateId"
                :is-selected="selected === templateId"
                @select="onSelectTemplate(templateId)"
                data-testid="template-item"
              >
                <span
                  class="item-content"
                  v-tooltip="{
                    content: name.length > MIN_NAME_FOR_TOOLTIP ? name : false,
                    delay: { show: 500, hide: 0 },
                  }"
                >
                  {{ name }}
                </span>
              </CreationHubItem>
            </template>
          </AccordionItem>
          <AccordionItem
            v-if="showImportSection"
            :id="CreationHubSections.IMPORT"
            title="IMPORT"
            data-testid="imports-section"
          >
            <CreationHubItem
              v-for="({ title, subtitle, learnMoreLink }, index) in importTypes"
              :key="index"
              :title="title"
              :subtitle="subtitle"
              :is-selected="selected === index"
              :learn-more-link="learnMoreLink"
              @select="onSelectItem(index, title)"
              data-testid="import-type-item"
            />
          </AccordionItem>
        </Accordion>
      </div>
      <div class="inner">
        <div class="preview-backdrop">
          <PreviewContainer class="preview-container">
            <template v-if="currentSection === CreationHubSections.GENERAL">
              <component v-if="selectedItem.previewComponent" :is="selectedItem.previewComponent" />
              <CreationHubBanner
                @click="
                  async () => {
                    selectedItem.handler(await getBranch());
                    reportItemClick(selectedItem.title);
                  }
                "
                @git-authorized="setReposList"
                :selected-repo="selectedRepo"
                :repos-list="reposList"
                :title="selectedItem.bannerTitle"
                :description="selectedItem.bannerDescription"
                :button-text="selectedItem.buttonText"
                @repo-change="handleRepoChange"
              />
            </template>
            <template v-else-if="currentSection === CreationHubSections.IMPORT">
              <component
                v-if="importTypes[selected].previewComponent"
                :is="importTypes[selected].previewComponent"
                :title="importTypes[selected].previewTitle"
                :image="importTypes[selected].previewImage"
              />
              <CreationHubBanner
                :title="importTypes[selected].bannerTitle"
                :selected-repo="selectedRepo"
                :repos-list="reposList"
                :description="importTypes[selected].bannerDescription"
                :button-text="importTypes[selected].buttonText"
                @click="
                  async () => {
                    importTypes[selected].handler(await getBranch());
                    reportItemClick(importTypes[selected].title);
                  }
                "
                @repo-change="handleRepoChange"
              ></CreationHubBanner>
            </template>
            <template v-else-if="selectedPreview">
              <DocPreview
                :key="selectedPreview.id"
                :content="selectedPreview.rawFileContent"
                :template-id="selectedPreview.id"
                :workspace-id="route.params.workspaceId as string"
                :repo-id="selectedRepoId || route.params.repoId || ''"
                origin="template"
                class="doc-preview"
              />
              <CreationHubBanner
                title="Template"
                :selected-repo="selectedRepo"
                :repos-list="reposList"
                :button-disabled="isLoadingTemplate"
                description="Go over the doc, and then add relevant info under the paragraph, code,or diagram placeholdersShare doc with your team and enjoy collaboration"
                @click="onTemplateCreate"
                @repo-change="handleRepoChange"
              />
            </template>
            <Loader class="loader" v-else />
          </PreviewContainer>
        </div>
      </div>
    </div>
  </SwModal>
  <BatchImportModal @toggle-parent="onClose" />
</template>

<style scoped lang="postcss">
.container {
  --sidebar-width: 300px;
  --modal-width: 1280px;
  --preview-horizontal-padding: 64px;
  display: flex;
  height: 900px;
  width: var(--modal-width);
  border-bottom-left-radius: 8px;
  overflow: hidden;

  .inner {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

@media only screen and (max-width: 1480px) {
  .container {
    width: 80vw;
  }
}

@media only screen and (max-height: 1100px) {
  .container {
    height: 80vh;
  }
}

.preview-backdrop {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: var(--beach-time);
  padding: var(--space-md) var(--preview-horizontal-padding) 0 var(--preview-horizontal-padding);
  overflow: auto;

  [data-theme='dark'] & {
    background: var(--swimm-shades);
  }
}

.preview-container {
  position: relative;
}

.sidebar {
  width: var(--sidebar-width);
  overflow: auto;
  border-right: 1px solid var(--color-border-default-subtle);

  .item-content {
    font-size: var(--body-S);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
    padding: 3px 0;
  }
}

.loader {
  width: 100%;
}

.beta-tag {
  padding: 2px 4px;
  border-radius: 4px;
  color: var(--text-color-magic);
  background-color: var(--color-status-magic);
  margin-left: var(--space-base);
}

.repo-icon {
  padding: 0;
  margin-right: var(--space-xs);
}
</style>
