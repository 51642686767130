<script setup lang="ts">
import { type Ref, computed, ref, toRef, watch } from 'vue';
import type * as _model from '@tiptap/pm/model';
import { NodeViewContent, NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import {
  ApplicabilityStatus,
  type SmartElementWithApplicability,
  type Snippet,
  type SwmCellSnippet,
  SwmCellType,
  type TokenSuggestion,
  getLoggerNew,
  productEvents,
  removePrefix,
} from '@swimm/shared';
import { HunkView, type OpenFileParam, type OverlayToken, SmartItemTypes, reportSmartItemFixed } from '@swimm/editor';
import { useTiptapIsSelected } from '@/composables/tiptapIsSelected';
import { getSwimmNodeExternalId, getSwimmNodeId } from '@/swmd/swimm_node';
import { getSwimmEditorServices } from '../extensions/Swimm';
import { useAnimate } from '@vueuse/core';

const logger = getLoggerNew('SwmSnippetNodeView');

type HunkApplicability = SmartElementWithApplicability<Snippet>['applicability'] | ApplicabilityStatus.Unknown;

const props = defineProps(nodeViewProps);

const el = ref<InstanceType<typeof NodeViewWrapper>>();

const { selected, highlighted } = useTiptapIsSelected(
  toRef(props, 'editor'),
  toRef(props, 'node'),
  toRef(props, 'getPos')
);

const swimmEditorServices = computed(() => getSwimmEditorServices(props.editor));

const customConfiguration = computed(() => swimmEditorServices.value.getCustomConfiguration(props.extension.name));

const workspaceId = computed(() => swimmEditorServices.value.workspaceId.value);

const docRepoId = computed(() => swimmEditorServices.value.repoId.value);

const docId = computed(() => swimmEditorServices.value.unitId.value);

const hunkRepoId = computed<string>(() => {
  return props.node.attrs.repoId;
});

const isAuthorized = computed(() => swimmEditorServices.value.isAuthorized.value);

const isIde = computed(() => swimmEditorServices.value.isIde);

const isDragDropSupported = computed(() => swimmEditorServices.value.isDragDropSupported);

const repoMetadata = computed(() => {
  return swimmEditorServices.value.getRepo(hunkRepoId.value) ?? null;
});

const nodeId = computed<string>(() => {
  return getSwimmNodeId(props.node);
});

const autosyncedElement = computed(() => {
  const element = swimmEditorServices.value.autosyncOutput.value?.smartElements.get(nodeId.value);
  return element ? (element as SmartElementWithApplicability<Snippet>) : null;
});

const hunkApplicability = computed<HunkApplicability>(() => {
  return autosyncedElement.value?.applicability ?? ApplicabilityStatus.Unknown;
});

const editable = computed<boolean>(() => {
  return swimmEditorServices.value.editable.value;
});

const isCommentSectionEmpty = computed<boolean>(() => {
  // when you delete the comment, you left with empty heading/paragraph and the size is 2
  return props.node.content.size <= 2;
});

const hunk = computed<SwmCellSnippet>(() => {
  return {
    type: SwmCellType.Snippet,
    patchType: undefined,
    // must fake this * for now
    lines: props.node.attrs.snippet.split('\n').map((line: string) => `*${line}`),
    firstLineNumber: props.node.attrs.line,
    path: removePrefix(props.node.attrs.path, '/'),
    repoId: props.node.attrs.repoId,
    collapsed: props.node.attrs.collapsed,
    // i think all the below are not required any more
    comments: undefined,
    pos: undefined,
    id: '',
    applicability: undefined,
    originalInfo: undefined,
    tempId: undefined,
  };
});

const verifying = computed(() => hunkApplicability.value === ApplicabilityStatus.Unknown);

// isWorkspaceAdmin as async (like getWorkspaceUsers)
// so usign ref and watch

const isAdmin = ref(false);

watch(
  () => workspaceId.value,
  async () => {
    isAdmin.value = swimmEditorServices.value.external.isWorkspaceAdmin.value;
  },
  {
    immediate: true,
  }
);

// compute branch of hunk repo
const hunkBranch = ref<string | null>(null);

watch(
  () => isAuthorized.value,
  async () => {
    if (docRepoId.value === hunkRepoId.value) {
      hunkBranch.value = swimmEditorServices.value.branch.value;
    } else {
      hunkBranch.value =
        swimmEditorServices.value.repos.value.repos?.find((repo) => repo.id === hunkRepoId.value)?.defaultBranch ??
        null;
    }
  },
  {
    immediate: true,
  }
);

function editHunk() {
  const pos = props.getPos();
  const commentStartPos = pos + 2; // Seems like getPos returns the outer node view, we want to focus on the comment
  const snippetObserver = swimmEditorServices.value.external.editSnippet(hunk.value);
  snippetObserver.subscribe({
    next(selectedSnippet) {
      if (selectedSnippet == null) {
        // snippet was deleted, delete the node
        props.editor
          .chain()
          .focus(commentStartPos)
          .command(({ dispatch, tr }) => {
            if (dispatch) {
              tr.delete(pos, pos + props.node.nodeSize);
            }

            return true;
          })
          .run();
      } else {
        props.editor
          .chain()
          .focus(commentStartPos)
          .command(({ dispatch, tr }) => {
            if (dispatch) {
              tr.setNodeMarkup(pos, null, {
                snippet: selectedSnippet.snippet,
                path: selectedSnippet.path,
                line: selectedSnippet.line,
                repoId: selectedSnippet.repoId,
              });
            }
            return true;
          })
          .run();
        swimmEditorServices.value.external.trackEvent(productEvents.SNIPPET_UPDATED, {
          'Multi Repo': selectedSnippet.repoId !== docRepoId.value,
        });
      }
    },
    complete() {
      props.editor.commands.focus(commentStartPos);
    },
    error(err) {
      logger.info(err, err.message);
    },
  });
}

function onHunkLineRangeChange(newRange: { firstLineNumber: number; lines: string[] }) {
  swimmEditorServices.value.external.trackEvent(productEvents.SNIPPET_LINE_RANGE_EDITED, {
    'Original Line Count': hunk.value.lines.length,
    'New Line Count': newRange.lines.length,
    'Lines Added': Math.max(0, newRange.lines.length - hunk.value.lines.length),
    'Lines Removed': Math.max(0, hunk.value.lines.length - newRange.lines.length),
  });
  const pos = props.getPos();
  props.editor
    .chain()
    .focus(pos)
    .command(({ dispatch, tr }) => {
      if (dispatch) {
        tr.setNodeMarkup(pos, null, {
          snippet: newRange.lines.join('\n'),
          path: hunk.value.path,
          line: newRange.firstLineNumber,
          repoId: hunk.value.repoId,
        });
      }

      return true;
    })
    .run();
}

function splitHunk(splitAfterLineNumber: number) {
  const pos = props.getPos();
  const splitIndex = splitAfterLineNumber - hunk.value.firstLineNumber + 1;
  swimmEditorServices.value.external.trackEvent(productEvents.SPLIT_SNIPPET, {
    'Original Line Count': hunk.value.lines.length,
    'Split Point Index': splitIndex,
  });
  const originalLines = props.node.attrs.snippet.split('\n');
  const snippetALines = originalLines.slice(0, splitIndex).join('\n');
  const snippetBLines = originalLines.slice(splitIndex).join('\n');
  props.editor
    .chain()
    .command(({ dispatch, tr }) => {
      if (dispatch) {
        tr.setNodeMarkup(pos, null, {
          snippet: snippetALines,
          path: hunk.value.path,
          line: hunk.value.firstLineNumber,
          repoId: hunk.value.repoId,
        });
      }
      return true;
    })
    .focus(pos + props.node.nodeSize)
    .insertSwmSnippet(snippetBLines, hunk.value.path, splitAfterLineNumber + 1, hunk.value.repoId)
    .run();
}

function reportSplitHunkModeChanged(opened: boolean) {
  if (opened) {
    swimmEditorServices.value.external.trackEvent(productEvents.STARTED_SPLIT_SNIPPET, {
      'Original Line Count': hunk.value.lines.length,
    });
  } else {
    swimmEditorServices.value.external.trackEvent(productEvents.CANCELED_SPLIT_SNIPPET, {
      'Original Line Count': hunk.value.lines.length,
    });
  }
}

function acceptAutosync() {
  if (autosyncedElement.value != null) {
    props.editor.commands.applyAutosync(new Map([[autosyncedElement.value.id, autosyncedElement.value]]));
  }
}

function setCollapsed(collapsed: boolean) {
  props.updateAttributes({ collapsed });
}

function deleteSnippet() {
  const pos = props.getPos();
  props.editor
    .chain()
    .focus(pos)
    .command(({ dispatch, tr }) => {
      if (dispatch) {
        tr.delete(pos, pos + props.node.nodeSize);
      }

      return true;
    })
    .run();
}

function configureAutosync() {
  // implement me
  // should open the settings modal
}

function reportHover(element: string) {
  if (!isAuthorized.value) {
    swimmEditorServices.value.external.trackEvent(productEvents.HOVERED_SHARED_DOC_CODE_ELEMENT, {
      'Workspace ID': workspaceId.value,
      Context: 'Shared Docs',
      Element: element,
    });
  }
}

function reportFixedHunk({ context, action }: { context: string; action: string }) {
  reportSmartItemFixed(swimmEditorServices.value.external.trackEvent.bind(swimmEditorServices.value.external), {
    docId: docId.value,
    type: SmartItemTypes.SNIPPET,
    context,
    action,
  });
}

function openFile(param: OpenFileParam) {
  swimmEditorServices.value.external.openFile(param);
}

const focusAnimation = useAnimate(el, [{ outline: '1px solid var(--color-border-brand)', offset: 0.5 }], {
  duration: 1500,
  immediate: false,
});

swimmEditorServices.value.animationsBus.on((event) => {
  switch (event.type) {
    case 'focus':
      if (event.id === nodeId.value) {
        focusAnimation.play();
      }
      break;
  }
});

// file contents
const fileContents: Ref<string[] | null> = ref(null);

watch(() => docRepoId.value, fetchFileContents);
watch(() => hunkBranch.value, fetchFileContents);
watch(() => props.node.attrs.path, fetchFileContents);
fetchFileContents();

async function fetchFileContents() {
  const repoId = docRepoId.value;
  const revision = hunkBranch.value;
  const filePath = removePrefix(props.node.attrs.path, '/');
  if (revision && repoId && filePath && isAuthorized.value) {
    try {
      const fileText = await swimmEditorServices.value.external.getFileContent({
        repoId,
        filePath,
        revision,
      });
      fileContents.value = fileText ? fileText.split('\n') : null;
    } catch {
      fileContents.value = null;
    }
  } else {
    fileContents.value = null;
  }
}

async function queryDefinitionsAsync(token: string): Promise<TokenSuggestion[]> {
  return await swimmEditorServices.value.external.tokenSuggestionsService.queryDefinitionsAsync(
    hunkRepoId.value,
    token
  );
}

/* this function gets suggestions and return the 10 lines snippet that the suggestions starts
 * it throws an error if there is any mismatch or if it cannot get tthe file
 */

async function getSuggestionSnippet(suggestion: TokenSuggestion, revision: string): Promise<string[]> {
  let fileLines: string[] | null = null;
  const fileText = await swimmEditorServices.value.external.getFileContent({
    repoId: suggestion.repoId,
    filePath: suggestion.position.path,
    revision,
  });
  if (!fileText) {
    throw new Error('No fileText');
  }
  fileLines = fileText.split('\n');
  const startLineIndex = suggestion.position.line - 1;
  const snippetLength = 10;
  const snippetLines = fileLines.slice(startLineIndex, startLineIndex + snippetLength);
  if (snippetLines.length <= 0) {
    throw new Error('No snippet lines');
  }
  if (snippetLines[0] !== suggestion.lineData) {
    throw new Error("First line doesn't match the lineData");
  }
  return snippetLines;
}

async function tokenInteracted(op: 'hover' | 'click', _tok: OverlayToken) {
  let eventName: string;
  switch (op) {
    case 'click':
      eventName = productEvents.CLICKED_TOKEN_WITH_DEFINITION_IN_SNIPPET;
      break;
    case 'hover':
      eventName = productEvents.HOVER_TOKEN_WITH_DEFINITION_IN_SNIPPET;
      break;
    default: {
      const exhaustiveCheck: never = op;
      throw new Error(`Unhandled case: ${exhaustiveCheck}`);
    }
  }
  swimmEditorServices.value.external.trackEvent(eventName, {});
}

/* function get suggestions, then insert the suggestion snippet
 * to the doc after the curreent snippet
 */
async function addDefSnippet(suggestion: TokenSuggestion): Promise<void> {
  const repoId = suggestion.repoId;
  const revision = hunkBranch.value;
  const filePath = removePrefix(suggestion.position.path, '/');
  try {
    if (!revision || !repoId || !filePath) {
      throw new Error('Missing data for addDefSnippet');
    }
    if (!isAuthorized.value) {
      throw new Error('must be authorized');
    }
    const snippetLines = await getSuggestionSnippet(suggestion, revision);
    const snippetPath = suggestion.position.path.startsWith('/')
      ? suggestion.position.path
      : `/${suggestion.position.path}`;
    const pos = props.getPos();
    const addRes = props.editor
      .chain()
      .focus(pos + props.node.nodeSize)
      .insertSwmSnippet(snippetLines.join('\n'), snippetPath, suggestion.position.line, repoId)
      .run();
    swimmEditorServices.value.external.trackEvent(productEvents.ADDED_TOKEN_DEFINITION_TO_DOCUMENT, {});
    if (!addRes) {
      throw new Error('Got false from insertSwmSnippet');
    }
  } catch (err) {
    logger.error({ err }, `Failed in addDefsnippet for ${repoId}`);
    void swimmEditorServices.value.external.showNotification('Could not add definition snippet because of an error', {
      icon: 'error',
    });
  }
}
</script>

<template>
  <NodeViewWrapper
    :id="nodeId"
    ref="el"
    :data-ext-id="getSwimmNodeExternalId(node)"
    class="hunk-container node-margin"
    :class="[hunkApplicability, { selected, highlighted }]"
    :data-selected="selected"
    data-testid="swm-snippet"
    data-swm-snippet
  >
    <div :class="hunkApplicability">
      <div>
        <NodeViewContent
          :contenteditable="editable"
          data-testid="swm-snippet-comment"
          :data-selected="selected"
          :class="{
            'comment-editor': !customConfiguration?.theme?.['comment-editor'],
            [customConfiguration?.theme?.['comment-editor'] ?? '']: !!customConfiguration?.theme?.['comment-editor'],
            'read-only-mode': !editable,
            'empty-comment': isCommentSectionEmpty && !editable,
          }"
        />
      </div>
      <div>
        <div
          contenteditable="false"
          tabindex="1"
          :class="{
            'hunk-editable-wrapper': !customConfiguration?.theme?.['hunk-editable-wrapper'],
            [customConfiguration?.theme?.['hunk-editable-wrapper'] ?? '']:
              !!customConfiguration?.theme?.['hunk-editable-wrapper'],
          }"
          @dragstart="$event.stopPropagation()"
          @mousedown="$event.stopPropagation()"
        >
          <HunkView
            v-if="hunk"
            :with-comments="!isCommentSectionEmpty || editable"
            contenteditable="false"
            :hunk="hunk"
            :hunk-branch="hunkBranch ?? null"
            :autosynced-snippet-data="autosyncedElement"
            :doc-repo-id="docRepoId ?? null"
            :doc-id="docId ?? null"
            :repo-id="hunkRepoId"
            class="hunk"
            :edit-mode="editable"
            :verifying="verifying"
            :should-animate-hunk-wrapper="swimmEditorServices.animations.shouldAnimateNode(nodeId)"
            :is-drag-in-progress="false"
            :is-admin="isAdmin"
            :is-authorized="isAuthorized"
            :is-ide="isIde"
            :repo-metadata="repoMetadata ?? null"
            :file-contents="fileContents"
            :hide-applicability="customConfiguration?.hideApplicability"
            :hide-show-more="((customConfiguration?.hideShowMore ?? false) as boolean)"
            :show-outbound-link="!!customConfiguration?.showOutboundLink"
            :query-definitions="editable ? queryDefinitionsAsync : null"
            :allow-drag-drop-editing="isDragDropSupported"
            @edit-hunk="editHunk"
            @split-hunk="splitHunk"
            @hunk-line-range-change="onHunkLineRangeChange"
            @accept-auto-synced-hunk="acceptAutosync"
            @set-collapsed-on-file="setCollapsed"
            @discard-hunk="deleteSnippet"
            @configure-autosync="configureAutosync"
            @report-hover="reportHover"
            @report-fixed-hunk="reportFixedHunk"
            @report-split-hunk-mode-changed="reportSplitHunkModeChanged"
            @open-file="openFile"
            @add-def-snippet="addDefSnippet"
            @token-interacted="tokenInteracted"
          />
        </div>
      </div>
    </div>
  </NodeViewWrapper>
</template>

<style scoped lang="scss">
.cell {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.empty-comment {
  display: none;
}

.comment-editor {
  padding: var(--space-small);
  min-height: var(--line-height-default);

  :deep(p:first-of-type) {
    margin-top: 0;
  }

  :deep(p:last-of-type) {
    margin-bottom: 0;
  }
}

.hunk-container {
  outline: 1px solid var(--color-border-default-subtle);
  position: relative;
  border-radius: var(--space-xsmall);

  &.highlighted {
    background-color: var(--color-bg-selected);
  }

  &.selected {
    border: 1px solid var(--color-border-brand);

    &.outdated {
      border: 1px solid var(--color-border-danger);
    }

    &.autosyncable {
      border: 1px solid var(--color-border-magic);
    }
  }
}

.node-margin {
  margin-block-end: var(--node-margin-bottom);
}

.hunk-editable-wrapper {
  outline: none;
}
</style>
