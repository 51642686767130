import { type Playlist } from '@/modules/playlists/types';
import Dexie, { type Table } from 'dexie';
import { type SwimmDocument, decryptMessage, encryptMessage, getLoggerNew } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export interface BaseDraft {
  userId: string;
  workspaceId: string;
  repoId: string;
  branch: string;
  id: string;
  created: number;
  modified: number;
  isNew?: boolean;
  delete?: boolean;
  originalTitle?: string;
  tags?: string[];
  folderId?: string;
  folderIndex?: number;
  path?: string;
  featuresUsed?: string[];
  isAIEnabledForRepo?: boolean;
}

export enum DraftType {
  DOC = 'doc',
  PLAYLIST = 'playlist',
}

export type DraftContent =
  | { type: DraftType.DOC; content: SwimmDocument }
  | { type: DraftType.PLAYLIST; content: Playlist };

export type Draft = BaseDraft & DraftContent;

export interface DraftAttributes {
  tags?: string[];
  folderId?: string;
  folderIndex?: number;
  path?: string;
  featuresUsed?: string[];
  isAIEnabledForRepo?: boolean;
}

export interface DbDraft extends BaseDraft {
  type: DraftType;
  content: Uint8Array;
}

export interface ImageDraft {
  userId: string;
  workspaceId: string;
  repoId: string;
  branch: string;
  draftId: string;
  path: string;
  file: File;
  width: number;
  height: number;
}

// TODO Technically you often use one big IndexedDB with multiple stores to make it easier to query across stores
export class DraftsDb extends Dexie {
  drafts!: Table<DbDraft>;
  images!: Table<ImageDraft>;

  constructor() {
    super('drafts');
    this.version(8).stores({
      drafts:
        '[userId+workspaceId+repoId+branch+id], [userId+workspaceId+repoId+branch], [userId+workspaceId+repoId+folderId]',
      images: '[userId+workspaceId+repoId+branch+draftId+path], [userId+workspaceId+repoId+branch+draftId]',
    });
  }
}

export const db = new DraftsDb();

export interface UseObservableOptions<I> {
  onError?: (err: unknown) => void;
  /**
   * The value that should be set if the observable has not emitted.
   */
  initialValue?: I | undefined;
}

export function encryptDraftContent(content: unknown, key: string): Uint8Array {
  // encryptMessage is not necessarily the best encryption and doesn't do an integrity check
  return new TextEncoder().encode(encryptMessage(JSON.stringify(content), key));
}

export function decryptDraftContent(content: Uint8Array, key: string): unknown {
  // TODO Why logger?! and catching in an inner function...
  const decrypted: string | null = decryptMessage(new TextDecoder().decode(content), key, logger);
  if (decrypted == null) {
    throw new Error('corrupted draft');
  }

  return JSON.parse(decrypted);
}

export function encryptDraft(draft: Draft, key: string): DbDraft {
  return {
    ...draft,
    content: encryptDraftContent(draft.content, key),
  };
}

export function decryptDraft(draft: DbDraft, key: string): Draft {
  return {
    ...draft,
    content: decryptDraftContent(draft.content, key) as Draft['content'],
  } as Draft;
}
