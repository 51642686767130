/**
 * Based on https://github.com/microsoft/vscode/blob/d9faba4a97c8afa56948a7bd5b4fba028116988e/extensions/markdown-language-features/src/slugify.ts
 */
export function githubSlugify(heading: string): string {
  return encodeURI(
    heading
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace whitespace with -
      // allow-any-unicode-next-line
      .replace(
        /[\][!/'"#$%&()*+,./:;<=>?@\\^{|}~`。，、；：？！…—·ˉ¨‘’“”々～‖∶＂＇｀｜〃〔〕〈〉《》「」『』．〖〗【】（）［］｛｝]/g,
        ''
      ) // Remove known punctuators
      .replace(/^-+/, '') // Remove leading -
      .replace(/-+$/, '') // Remove trailing -
  );
}
