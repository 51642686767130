<script setup lang="ts">
import { Action } from '@swimm/ui';
import { ref, watch } from 'vue';

const props = defineProps<{ step: number; buttons: string[] }>();
const animate = ref(false);

watch(
  () => props.step,
  () => {
    setTimeout(() => {
      animate.value = false;
    }, 1000);
    animate.value = true;
  },
  { immediate: true }
);

const emit = defineEmits(['button-action']);
</script>

<template>
  <div class="video-buttons">
    <Action
      v-for="(button, key) in buttons"
      :key="key"
      class="video-button"
      :class="{ 'video-button-animation': animate, 'opacity-full': !animate }"
      @click="emit('button-action', key)"
    >
      {{ button }}
    </Action>
  </div>
</template>

<style scoped lang="postcss">
.video-buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: var(--space-base);

  .video-button.button {
    opacity: 0;
    color: var(--text-color-link);
    border-radius: 50px;
    border: 2px solid var(--color-border-default-subtle);
    background-color: var(--clear-white);
    flex: 1;
    white-space: nowrap;

    &:hover,
    &:focus {
      background: var(--color-hover);
    }

    &:hover {
      border: 1px solid var(--color-border-brand);
    }

    &.opacity-full {
      opacity: 1;
    }
  }

  .video-button-animation {
    animation: bounce 0.5s ease-in forwards;

    &:last-of-type {
      animation-delay: 0.5s;

      &:first-of-type {
        animation-delay: 0s;
      }
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  40% {
    transform: translateY(-10px);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (max-width: 480px) {
  .video-buttons {
    flex-direction: column;
    gap: var(--space-base);

    .video-button.button {
      opacity: 1;

      &.video-button-animation {
        animation: none;
      }
    }
  }
}
</style>
