<template>
  <div class="headline3 integrationTitle"><b>Integrations</b></div>
  <AutomaticIntegration
    class="space"
    image-name="slack"
    title="Slack App"
    description="Get personalized Swimm notifications"
    button-title-app-not-installed="Connect"
    :installed-button-url="slackUrl"
    :not-installed-button-url="slackUrl"
    :is-installed="false"
    :disabled="!slackUrl"
    @install-click="handleSlackAppConnect"
    :tooltip="slackTooltip"
  />
  <AutomaticIntegration
    class="space"
    :image-name="Types.VS_CODE.imageName"
    :title="Types.VS_CODE.name"
    description="Discover documentation in the IDE without switching contexts"
    button-title-app-not-installed="Install"
    :installed-button-url="Types.VS_CODE.url"
    :not-installed-button-url="Types.VS_CODE.url"
    :is-installed="userConfigStore.installedIdePluginVSCode"
    @install-click="handleVSCodePluginInstall"
  />
  <AutomaticIntegration
    class="space"
    :image-name="Types.INTELLIJ.imageName"
    :title="Types.INTELLIJ.name"
    description="Discover documentation in the IDE without switching contexts"
    button-title-app-not-installed="Install"
    :installed-button-url="Types.INTELLIJ.url"
    :not-installed-button-url="Types.INTELLIJ.url"
    :is-installed="userConfigStore.installedIdePluginJetBrains"
    @install-click="handleIntelliJPluginInstall"
  />
</template>

<script>
import AutomaticIntegration from '@/common/components/RepoIntegrations/AutomaticIntegrations/AutomaticIntegration.vue';
import { SLACK_APP_ID } from '@/config';
import { config, productEvents } from '@swimm/shared';
import { mapGetters } from 'vuex';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { GetStartedStepIds } from '@/modules/get-started/consts';
import { useGetStartedMenu } from '@/modules/get-started/composables/useGetStartedMenu';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';
import { storeToRefs } from 'pinia';

const Types = {
  VS_CODE: {
    imageName: 'VSCode',
    name: 'VS Code extension',
    url: config.SWIMM_VSCODE_EXTENSION_MARKETPLACE_ADDRESS,
  },
  INTELLIJ: {
    imageName: 'IntelliJ',
    name: 'JetBrains plugin',
    url: config.SWIMM_JETBRAINS_EXTENSION_MARKETPLACE_ADDRESS,
  },
};

export default {
  components: { AutomaticIntegration },
  props: {
    origin: { type: String, required: true },
  },
  setup() {
    const analytics = useAnalytics();
    const { markStepAsDone } = useGetStartedMenu();
    const { user } = storeToRefs(useAuthStore());
    const userConfigStore = useUserConfigStore();
    userConfigStore.fetchInstalledIdePlugin({ userId: user.value.id });

    return {
      Types,
      analytics,
      markStepAsDone,
      userConfigStore,
    };
  },
  computed: {
    ...mapGetters('database', ['db_getSlackUrl']),
    slackUrl() {
      return this.db_getSlackUrl(this.$route.params.workspaceId, SLACK_APP_ID);
    },
    slackTooltip() {
      return this.slackUrl
        ? null
        : 'To connect to Slack, a Swimm admin must first install the Slack app on at least one repo.';
    },
  },
  methods: {
    handleSlackAppConnect() {
      this.reportEvent(productEvents.SLACK_APP_CONNECT, 'Slack App');
    },
    handleIntelliJPluginInstall() {
      this.reportEvent(productEvents.INTELLIJ_PLUGIN_INSTALL, 'IntelliJ Plugin');
      this.markStepAsDone(GetStartedStepIds.INSTALL_IDE_PLUGIN);
    },
    handleVSCodePluginInstall() {
      this.reportEvent(productEvents.VISUAL_STUDIO_CODE_INSTALL, 'Visual Studio Code Extension');
      this.markStepAsDone(GetStartedStepIds.INSTALL_IDE_PLUGIN);
    },
    reportEvent(event, context) {
      this.analytics.track(event, {
        'Workspace ID': this.workspaceId,
        Origin: this.origin,
        Context: context,
      });
    },
  },
};
</script>

<style scoped>
.integrationTitle {
  margin-bottom: 12px;
}
</style>
