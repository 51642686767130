<template>
  <div v-click-outside="clickHandler" :class="{ 'selection-menu': showBorder }">
    <TextField
      ref="optionSearch"
      v-model="query"
      class="search"
      focus-first
      :placeholder="placeholder"
      :maxlength="maxlength"
      :focused-border="false"
      @key-up="handleKeyUp"
    />
    <div class="option-menu">
      <div v-if="loading" class="item"><Loader secondary /></div>
      <div v-else-if="errorMessage && errorMessage.length" class="viewable-option error">{{ errorMessage }}</div>
      <div v-else>
        <div v-if="(!filteredOptions || !filteredOptions.length) && noMatchingText">
          <div class="empty-line">{{ noMatchingText }}</div>
        </div>
        <div class="list">
          <div
            v-for="(option, index) in filteredOptions"
            :key="option.id"
            ref="viewableOptions"
            class="item"
            :class="{ 'is-selected': currentSelectedIndex === index }"
            @click="handleSelection(option)"
          >
            <div class="line">
              <span class="viewable-option">
                {{ option }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TextField } from '@swimm/ui';

export default {
  name: 'MenuSelection',
  emits: ['on-close', 'option-selected'],
  components: { TextField },
  props: {
    placeholder: { type: String, default: null },
    allOptions: { type: Array, default: () => [] },
    noMatchingText: { type: String, default: null },
    maxlength: { type: Number, default: 524288 },
    showBorder: { type: Boolean, default: true },
  },
  data() {
    return {
      clickHandler: {
        handler: this.close,
        events: ['mousedown', 'mouseup'],
      },
      currentSelectedIndex: 0,
      query: '',
      loading: false,
      errorMessage: '',
    };
  },
  computed: {
    filteredOptions() {
      return this.allOptions.filter((option) => option.toLowerCase().includes(this.query.toLowerCase().trim()));
    },
  },
  methods: {
    async handleKeyUp(event) {
      switch (event.key) {
        case 'ArrowDown': {
          this.scrollToElement(true);
          break;
        }
        case 'ArrowUp': {
          this.scrollToElement(false);
          break;
        }
        case 'Enter': {
          const option = this.filteredOptions[this.currentSelectedIndex] || this.query;
          if (option) {
            this.handleSelection(option);
            this.close(event);
          }
          break;
        }
        case 'Escape': {
          this.close(event);
          break;
        }
      }
    },
    scrollToElement(goToNext) {
      if (!this.$refs) {
        return;
      }

      const options = this.$refs.viewableOptions;
      const listLength = this.filteredOptions.length;
      if (!listLength) {
        return;
      }
      if (goToNext) {
        this.currentSelectedIndex = (this.currentSelectedIndex + 1) % listLength;
      } else {
        this.currentSelectedIndex = (this.currentSelectedIndex + listLength - 1) % listLength;
      }

      const currentOption = options[this.currentSelectedIndex];
      if (currentOption) {
        currentOption.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    },
    handleSelection(selection) {
      this.$emit('option-selected', selection);
      this.query = '';
    },
    close(event) {
      // For some reason we're being sent both a `MouseEvent` and a `PointerEvent`.
      // We want to get rid of the latter (since it's just noise to us), so this is how we filter it out.
      if (event.type === 'click') {
        return;
      }
      // Don't emit the close signal if we just released the mouse outside the component.
      if (event.type === 'mouseup') {
        return;
      }
      this.$emit('on-close');
    },
  },
};
</script>

<style scoped>
.selection-menu {
  width: 250px;
  text-align: left;
  border: 2px solid var(--color-border-default-subtle);
  border-radius: 6px;
  margin-top: 8px;
}

.option-menu {
  border-top: 2px solid var(--color-border-default-subtle);
}

.item {
  cursor: pointer;
  padding: 8px;
}

.item.is-selected,
.item:hover {
  background: var(--color-surface);
}

.item .line {
  display: flex;
  align-items: center;
  height: 24px;
}

.empty-line {
  display: flex;
  align-items: center;
  color: var(--text-color-secondary);
  height: 24px;
  padding: 8px;
}

.viewable-option {
  font-size: var(--body-S);
  color: var(--text-color-primary);
  flex: 1;
}

.list {
  overflow-y: scroll;
  overflow-x: auto;
  max-height: 200px;
}

.error {
  color: var(--color-border-danger);
}

.search {
  padding: 8px;
  font-size: var(--body-S);
}
</style>
