<template>
  <div class="drag-icon-root">
    <div
      class="drag-icon-container"
      data-testid="editor-drag-icon"
      :class="{ 'drag-icon-container-edit': isEditable && shouldAllowSectionIdCopying }"
    >
      <VMenu placement="left" :auto-hide="false" :theme="theme">
        <div class="icon-container">
          <span v-if="shouldAllowSectionIdCopying">
            <Icon
              v-if="!copiedAnchorLink"
              name="link"
              @click="callAnchorLink"
              data-testid="drop-down-anchor"
              :class="{ hidden: !handleAnchorLinkVisibility, clickable: !isPerformingSave }"
              class="cell-link-icon"
            ></Icon>
            <Icon v-else name="check" class="cell-link-icon"></Icon>
          </span>
          <Icon
            @mouseover="showPopover = true"
            @mouseleave="showPopover = false"
            name="drag-handle-options"
            :data-testid="dataTestId"
            class="cell-icon"
            :class="{
              clickable: !isPerformingSave,
              handle: handleDrag && !isPerformingSave,
              hidden: !handleVisible || !isEditable,
            }"
            @click="toggleShowMenu"
          />
        </div>
        <div v-if="showMenu" v-click-outside="dropdownItemClicked" class="menu-container">
          <DraggableDropdownMenuItem
            v-for="option in usableOptions"
            :key="option.name"
            :data-testid="`command-${option.name}-${cellIndex}`"
            :name="option.name"
            :description="option.hint && option.hint"
            :icon="optionsIcons[option.name] && optionsIcons[option.name].icon"
            :handler="option.callback"
            @click="dropdownItemClicked"
          />
        </div>
        <template #popper>
          <div v-if="showPopover" class="actions-icon-tooltip">
            <span><b>Click</b> for options</span>
            <span><b>Hold</b> to drag</span>
          </div>
        </template>
      </VMenu>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { draggableMenuOptions } from '@/consts/commands';
import DraggableDropdownMenuItem from './DraggableDropdownMenuItem.vue';
import { useShouldShowAnchor } from './hyperlink/shouldShowAnchor';

export default defineComponent({
  components: {
    DraggableDropdownMenuItem,
  },
  props: {
    defaultOptions: { type: Array, default: () => [] },
    optionsFromParent: { type: Function, default: null, required: false },
    cellIndex: { type: Number, default: -1 },
    dataTestId: { type: String, default: '' },
    isHeading: { type: Boolean, default: false },
    isSnippet: { type: Boolean, default: false },
    isEditable: { type: Boolean, default: false },
    cellType: { type: String, default: '' },
    isPerformingSave: { type: Boolean, default: false },
    handleDrag: { type: Boolean, default: true },
    visible: { type: Boolean, default: false },
  },
  setup() {
    const { shouldAllowSectionIdCopying } = useShouldShowAnchor();
    return {
      shouldAllowSectionIdCopying,
    };
  },
  emits: ['highlightChosenCell'],
  data() {
    return {
      usableOptions: null,
      optionsIcons: {
        [draggableMenuOptions['Anchor Link']]: { icon: 'link' },
        Delete: { icon: 'trash-outline' },
        Copy: { icon: 'outbound-link' },
        ['Edit snippet']: { icon: 'edit-outline' },
        ['Replace image']: { icon: 'edit-outline' },
        ['Move up']: { icon: 'arrow-next-up' },
        ['Move down']: { icon: 'arrow-next-down' },
        ['Add new line below']: { icon: 'add' },
        Shrink: { icon: 'resize-shrink' },
        Expand: { icon: 'resize-expand' },
      },
      showMenu: false,
      handleVisible: this.visible,
      showPopover: false,
      copiedAnchorLink: false,
    };
  },
  computed: {
    // we change the theme to no arraw
    // if the popper template is not display
    // otherwise, in jetbrains ide there is delay with the arrow
    theme() {
      if (!this.showMenu && this.showPopover) {
        return null;
      }
      return 'menu-no-arrow';
    },
    handleAnchorLinkVisibility() {
      if (this.isHeading || this.isSnippet) {
        return this.handleVisible;
      }
      return false;
    },
  },
  methods: {
    callAnchorLink() {
      const options = this.getOptions();
      const anchorOption = options.find((command) => command.name === draggableMenuOptions['Anchor Link']);
      anchorOption.callback();
      this.copiedAnchorLink = true;
      setTimeout(() => {
        this.copiedAnchorLink = false;
      }, 2000);
    },
    toggleShowMenu() {
      this.showPopover = false;
      this.$emit('highlightChosenCell', { index: this.cellIndex, type: this.cellType });
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        this.usableOptions = this.getOptions();
      }
    },
    getOptions() {
      return this.optionsFromParent ? this.optionsFromParent() : this.defaultOptions;
    },
    dropdownItemClicked() {
      if (this.showMenu) {
        this.showMenu = false;
      }
    },
  },
});
</script>

<style scoped>
.menu-container {
  position: absolute;
  z-index: 3;
  display: flex;
  margin-top: 5px;
  width: 195px;
  flex-direction: column;
  border: 1px solid var(--color-border-default-subtle);
  border-radius: 4px;
  background-color: var(--color-bg);
  box-shadow: var(--box-shadow-small);
  cursor: pointer;
  user-select: none;
}

@media only screen and (min-width: 1470px) {
  .menu-container {
    right: 0px;
    margin-inline: 5px;
  }
}

@media only screen and (max-width: 1750px) {
  .doc-container-sidebar-open .menu-container {
    right: auto;
    margin-inline: 0;
  }
}

@media only screen and (min-width: 1750px) {
  .doc-container-sidebar-open .menu-container {
    right: 0px;
    margin-inline: 5px;
  }
}

@media only screen and (max-width: 1850px) {
  .workspace-sidebar-open .menu-container {
    right: auto;
    margin-inline: 0;
  }
}

@media only screen and (min-width: 1850px) {
  .workspace-sidebar-open .menu-container {
    right: 0px;
    margin-inline: 5px;
  }
}

@media only screen and (max-width: 2100px) {
  .workspace-sidebar-open.doc-container-sidebar-open .menu-container {
    right: auto;
    margin-inline: 0;
  }
}

@media only screen and (min-width: 2100px) {
  .workspace-sidebar-open.doc-container-sidebar-open .menu-container {
    right: 0px;
    margin-inline: 5px;
  }
}

.drag-icon-root {
  position: relative;
}

.drag-icon-container {
  color: var(--text-color-disable);
  position: absolute;
  left: -34px;
}

.drag-icon-container-edit {
  left: -60px;
}

.cell-link-icon,
.cell-icon {
  font-size: var(--fontsize-m);
}

.icon.cell-icon,
.cell-link-icon,
.icon.table-ellipsis-icon {
  padding-right: 0;
}

.drag-icon-container .hidden {
  visibility: hidden;
}

.actions-icon-tooltip {
  display: flex;
  flex-direction: column;
  align-content: left;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-size: var(--subtitle-S);
  border-radius: 8px;
  user-select: none;
}
</style>
