import type {
  DynamicChangeLine,
  DynamicGitHunkMetadata,
  DynamicHunkContainer,
  DynamicPatch,
  FILE_VERSION,
  MultiDynamicPatch,
  Snippet,
  SwmCellSnippet,
  SwmFile,
} from './types';
import { v4 as uuidv4 } from 'uuid';

import { hunkLineMatcherFromGit, hunkLineMatcherFromSwimmDiff } from './line-matcher';
import {
  ADD_MARKER,
  CONTEXT_MARKER,
  DELETE_MARKER,
  FileDiffType,
  HunkChangeLineType,
  SELECT_MARKER,
  SwmCellType,
  generateHeaderInfo,
  generateHunkHeaderInfoLineFromHunkHeaderInfoObject,
} from './types';
import { SUCCESS_RETURN_CODE, SWM_FOLDER_IN_REPO, SWM_SCHEMA_VERSION } from './config';
import {
  createDynamicHunkContainer,
  createRawParsedDiffFilePairings,
  createRawParsedHunksPairings,
  extractGitFileMetadata,
  findSectionsEdgesInFileA,
  generateNewSwimmHunkLineChange,
  parseFileGitDiff,
} from './diff-parser-utils';
import type { Section } from './types/diff-parser';
import { matchHunkLines } from './similarity-line-matcher';
import { findLast } from 'lodash-es';
import { buildHunkIndication } from './utils/hunk-utils';
import { SnippetInfo } from './shared';

export function createDynamicStructureFromDiffString({
  rawDiffString,
  diffType,
  fromGit = true,
}: {
  rawDiffString: string;
  diffType?: FileDiffType;
  fromGit?: boolean;
}): {
  code: typeof SUCCESS_RETURN_CODE;
  swimmPatch: DynamicPatch;
} {
  const fileDiffsPairings = createRawParsedDiffFilePairings(rawDiffString);
  const swimmPatch: DynamicPatch = {};

  for (const fileDiffPairing of fileDiffsPairings) {
    const gitFileMetadata = extractGitFileMetadata(fileDiffPairing.raw, fileDiffPairing.parsed);
    if (diffType) {
      // We need to override the diffType. This is useful for example when generating the diff of a blob sha
      gitFileMetadata.diffType = diffType;
    }
    const rawParsedHunks = createRawParsedHunksPairings(fileDiffPairing.raw, fileDiffPairing.parsed);
    const hunkContainers: DynamicHunkContainer[] = [];
    for (const rawParsedPairing of rawParsedHunks) {
      const dynamicHunk = createDynamicHunkContainer(rawParsedPairing.raw, rawParsedPairing.parsed);
      let lineMatchedHunk: DynamicHunkContainer;
      if (fromGit) {
        lineMatchedHunk = hunkLineMatcherFromGit(dynamicHunk);
      } else {
        lineMatchedHunk = hunkLineMatcherFromSwimmDiff(dynamicHunk);
      }
      hunkContainers.push(lineMatchedHunk);
    }
    swimmPatch[gitFileMetadata.fileNameB] = {
      hunkContainers: hunkContainers,
      fileNameA: gitFileMetadata.fileNameA,
      fileNameB: gitFileMetadata.fileNameB,
      diffType,
    };
  }

  return { code: SUCCESS_RETURN_CODE, swimmPatch: swimmPatch };
}

/**
 * Gets a dynamic hunk container and returns ranges of upperContext, lowerContext and meat
 * @param dynamicHunkContainer
 * @return {{meat: {start: number, startIndex: number, end: number, endIndex: number}, upperContext: ({start: number, startIndex: number, end: number, endIndex: number}|null), lowerContext: ({start: number, startIndex: number, end: number, endIndex: number}|null)}}
 */
export function getSectionsLineNumbersFromPatch(dynamicHunkContainer: DynamicHunkContainer): {
  meat: Section;
  upperContext?: Section;
  lowerContext?: Section;
} {
  const upperSections = findSectionsEdgesInFileA(dynamicHunkContainer.changes);
  const reversedChangesArray = [...dynamicHunkContainer.changes].reverse();
  const lowerSections = findSectionsEdgesInFileA(reversedChangesArray);
  return {
    meat: {
      start: upperSections.meat.meatEdgeLineNumber,
      startIndex: upperSections.meat.meatEdgeChangeIndex,
      end: lowerSections.meat.meatEdgeLineNumber,
      endIndex: dynamicHunkContainer.changes.length - 1 - lowerSections.meat.meatEdgeChangeIndex,
    },
    upperContext: upperSections.context.contextEdgeLineNumber
      ? {
          start: upperSections.context.contextEdgeLineNumber,
          startIndex: upperSections.context.contextEdgeChangeIndex,
          end: upperSections.context.contextMiddleLineNumber,
          endIndex: upperSections.context.contextMiddleChangeIndex,
        }
      : null,
    lowerContext: lowerSections.context.contextEdgeLineNumber
      ? {
          start: lowerSections.context.contextMiddleLineNumber,
          startIndex: dynamicHunkContainer.changes.length - 1 - lowerSections.context.contextMiddleChangeIndex,
          end: lowerSections.context.contextEdgeLineNumber,
          endIndex: dynamicHunkContainer.changes.length - 1 - lowerSections.context.contextEdgeChangeIndex,
        }
      : null,
  };
}

// Functions made specifically for SwmFile v2.0.0 are below this point.

function swmCellSnippetToDynamicGitHunkMetadata(snippet: Snippet): DynamicGitHunkMetadata {
  const headerInfo = generateHeaderInfo(snippet.lines, snippet.startLineNumber);
  return {
    lineNumbers: {
      fileA: { startLine: headerInfo.aLine, linesCount: headerInfo.aCount },
      fileB: { startLine: headerInfo.bLine, linesCount: headerInfo.bCount },
    },
  };
}

function swmCellSnippetToGitHunkDiff(snippet: Snippet): string {
  const hunkHeaderInfo = generateHeaderInfo(snippet.lines, snippet.startLineNumber);
  const hunkHeaderLine = generateHunkHeaderInfoLineFromHunkHeaderInfoObject(hunkHeaderInfo);
  return hunkHeaderLine + '\n' + snippet.lines.join('\n');
}

function swmCellSnippetToDynamicHunkContainer(snippet: Snippet): DynamicHunkContainer {
  const parsedDiff = parseFileGitDiff(
    'diff --git a/tmp b/tmp\nindex\n--- a/tmp\n+++ b/tmp\n' + swmCellSnippetToGitHunkDiff(snippet)
  ).chunks[0]; // TODO: THIS IS A FAKE HEADER
  const changeLines = [];
  for (const parsedLine of parsedDiff.changes) {
    changeLines.push(generateNewSwimmHunkLineChange(parsedLine));
  }

  const dynamicHunkContainerResult: DynamicHunkContainer = {
    changes: changeLines,
    gitHunkMetadata: swmCellSnippetToDynamicGitHunkMetadata(snippet),
    swimmHunkMetadata: {},
    repoId: snippet.gitInfo.repoId,
  };

  return dynamicHunkContainerResult;
}

export async function autosyncSnippetsToDynamicPatch({
  snippets,
  attachId = false,
}: {
  snippets?: Snippet[];
  attachId?: boolean;
}): Promise<MultiDynamicPatch> {
  const dynamicPatchResult: MultiDynamicPatch = {};

  // Load all snippets
  for (const snippet of snippets) {
    const cellRepoId = snippet.gitInfo.repoId;
    const meatLines = snippet.lines.map((thisLine) => {
      if (thisLine.startsWith(SELECT_MARKER)) {
        return thisLine.replace(SELECT_MARKER, DELETE_MARKER);
      }
      return thisLine;
    });

    const hunkContainer: DynamicHunkContainer = hunkLineMatcherFromSwimmDiff(
      swmCellSnippetToDynamicHunkContainer({ ...snippet, lines: meatLines })
    );
    if (attachId) {
      // This means we need to reconstruct the dynamicPatch back into a swmFile later on so we need some kind of ID to help us.
      hunkContainer.id = snippet.id;
    }
    if (typeof dynamicPatchResult[cellRepoId]?.[snippet.filePath] === 'undefined') {
      dynamicPatchResult[cellRepoId] = dynamicPatchResult[cellRepoId] || {};
      dynamicPatchResult[cellRepoId][snippet.filePath] = {
        hunkContainers: [],
        fileNameA: snippet.filePath,
        fileNameB: snippet.filePath,
        diffType: FileDiffType.Modified,
      };
    }
    dynamicPatchResult[cellRepoId][snippet.filePath].hunkContainers.push(hunkContainer);
  }

  return dynamicPatchResult;
}

export function swmCellToDynamicPatch(snippet: Snippet): DynamicPatch {
  const dynamicPatchResult: DynamicPatch = {};

  dynamicPatchResult[snippet.filePath] = { hunkContainers: [] };
  const cell = { ...snippet };
  cell.lines = cell.lines.map((thisLine) => {
    if (thisLine.startsWith(SELECT_MARKER)) {
      thisLine = thisLine.replace(SELECT_MARKER, DELETE_MARKER);
    }
    return thisLine;
  });
  const lineMatchedHunk = hunkLineMatcherFromSwimmDiff(swmCellSnippetToDynamicHunkContainer(cell));
  dynamicPatchResult[cell.filePath].hunkContainers.push(lineMatchedHunk);

  return dynamicPatchResult;
}

export function getLinesFromDynamicHunkContainer(
  dynamicHunkContainer: DynamicHunkContainer,
  hideAdditions?: boolean
): string[] {
  const lines: string[] = [];
  for (const changeLine of dynamicHunkContainer.changes) {
    switch (changeLine.changeType) {
      case HunkChangeLineType.Added: {
        if (!hideAdditions) {
          lines.push(ADD_MARKER + changeLine.fileB.data);
        }
        break;
      }
      case HunkChangeLineType.Deleted: {
        lines.push(SELECT_MARKER + changeLine.fileA.data);
        break;
      }
      case HunkChangeLineType.Context: {
        lines.push(CONTEXT_MARKER + changeLine.fileA.data);
        break;
      }
      case HunkChangeLineType.Update: {
        lines.push(SELECT_MARKER + changeLine.fileA.data);
        if (!hideAdditions) {
          lines.push(ADD_MARKER + changeLine.fileB.data);
        }
        break;
      }
    }
  }

  return lines;
}

export function dynamicHunkContainerToSwmCellSnippet(
  dynamicHunkContainer: DynamicHunkContainer,
  path: string,
  repoId: string,
  patchType?: FileDiffType.Deleted | FileDiffType.Added,
  hideAdditions?: boolean
): SwmCellSnippet {
  const firstLineNumber = dynamicHunkContainer.gitHunkMetadata.lineNumbers.fileA.startLine;
  const lines: string[] = getLinesFromDynamicHunkContainer(dynamicHunkContainer, hideAdditions);
  const applicability =
    dynamicHunkContainer.swimmHunkMetadata && dynamicHunkContainer.swimmHunkMetadata.applicability
      ? dynamicHunkContainer.swimmHunkMetadata.applicability
      : null;
  const constructedSwmCellSnippet: SwmCellSnippet = {
    firstLineNumber,
    path,
    type: SwmCellType.Snippet,
    lines,
    comments: [],
    repoId,
    id: buildHunkIndication({ firstLineNumber, length: lines.length, path }),
  };
  if (applicability) {
    constructedSwmCellSnippet.applicability = applicability;
  }
  if (patchType) {
    constructedSwmCellSnippet.patchType = patchType;
  }
  return constructedSwmCellSnippet;
}

// Converts a dynamicPatch diffType to SWMFile patchType
export function dynamicFileDiffTypeToSwmFilePatchType(
  diffType: FileDiffType
): FileDiffType.Added | FileDiffType.Deleted | undefined {
  if (diffType === FileDiffType.Added || diffType === FileDiffType.Deleted) {
    return diffType;
  }
  /*
  FileDiffType.Modified -> undefined. Default patchType for "MODIFIED" file as it should not be stored later in the SWM File
  FileDiffType.Renamed -> undefined. TODO: RENAME diff types should be handled in https://app.clickup.com/t/6dvxhk
  */
  return undefined;
}

/**
 * Converts a dynamic patch to a swm file, much like `dynamicPathToSwmFile`.
 * Unlike it, this function bases the marked lines on the changes present in fileB (addition files) instead of fileA (deletion lines).
 * @param dynamicPatch - the dynamicPatch to convert
 * @param repoId - the relevant repoId
 * @param appVersion - the app version from the package.json of the project
 */
export function dynamicPatchToSwmFileBSide(dynamicPatch: DynamicPatch, repoId: string, appVersion: string): SwmFile {
  const cells: SwmCellSnippet[] = [];
  for (const filePath in dynamicPatch) {
    const patchType = dynamicFileDiffTypeToSwmFilePatchType(dynamicPatch[filePath].diffType);
    let patchToUse: FileDiffType = patchType;
    if (patchType === FileDiffType.Added) {
      patchToUse = FileDiffType.Modified;
    } else if (patchType === FileDiffType.Deleted) {
      patchToUse = FileDiffType.Added;
    }
    const relevantContainers = dynamicPatch[filePath].hunkContainers.filter(isHunkContainsUpdateOrAddition);
    const convertedCells = relevantContainers.map(
      (dynamicHunkContainer: DynamicHunkContainer): SwmCellSnippet =>
        dynamicHunkContainerToSwmCellSnippetBSide({
          dynamicHunkContainer,
          path: dynamicPatch[filePath].fileNameB || filePath,
          repoId,
          patchType: patchToUse,
        })
    );
    cells.push(...convertedCells);
  }
  return {
    file_version: SWM_SCHEMA_VERSION as FILE_VERSION,
    id: '',
    name: '',
    meta: { app_version: appVersion },
    content: cells,
    path: SWM_FOLDER_IN_REPO,
  };
}

export function dynamicPatchToSnippets(dynamicPatch: DynamicPatch): SnippetInfo[] {
  const snippets: SnippetInfo[] = [];
  for (const filePath in dynamicPatch) {
    for (const hunkContainer of dynamicPatch[filePath].hunkContainers) {
      const { lines, firstLineNumber, lastLineNumber } = getFileBLinesFromHunkContainer(hunkContainer);
      snippets.push({
        filePath,
        content: lines.join('\n'),
        startLine: firstLineNumber,
        endLine: lastLineNumber,
      });
    }
  }
  return snippets;
}

export function isHunkContainsUpdateOrAddition(dynamicHunk: DynamicHunkContainer): boolean {
  return dynamicHunk.changes.some(
    (line) => line.changeType === HunkChangeLineType.Added || line.changeType === HunkChangeLineType.Update
  );
}

/**
 * Converts dynamic hunk to a swm cell using fileB (addition lines), unlike dynamicHunkContainerToSwmCellSnippet which uses fileA (deletion lines)
 * @param dynamicHunkContainer - the hunk container to convert
 * @param path - the file path of the hunk container
 * @param repoId
 * @param patchType - optional special type for the file diff
 * @param includeContextLines - include context lines (inner and wrapping). If not provided inner will be treated as meat and wrapping context will be ignored
 */
export function dynamicHunkContainerToSwmCellSnippetBSide({
  dynamicHunkContainer,
  path,
  repoId,
  patchType,
  includeContextLines = false,
}: {
  dynamicHunkContainer: DynamicHunkContainer;
  path: string;
  repoId: string;
  patchType?: FileDiffType;
  includeContextLines?: boolean;
}): SwmCellSnippet {
  const lines: string[] = [];
  const meatLinesPredicate = (changeLine: DynamicChangeLine) =>
    changeLine.changeType === HunkChangeLineType.Added || changeLine.changeType === HunkChangeLineType.Update;

  const lastMeatLine = findLast(dynamicHunkContainer.changes, meatLinesPredicate)?.fileB?.actualLineNumber;
  const firstMeatLine = dynamicHunkContainer.changes.find(meatLinesPredicate)?.fileB?.actualLineNumber;

  for (const changeLine of dynamicHunkContainer.changes) {
    switch (changeLine.changeType) {
      case HunkChangeLineType.Added: {
        lines.push(SELECT_MARKER + changeLine.fileB.data);
        break;
      }
      case HunkChangeLineType.Deleted: {
        break;
      }
      case HunkChangeLineType.Context: {
        if (includeContextLines) {
          lines.push(CONTEXT_MARKER + changeLine.fileB.data);
        } else if (
          changeLine.fileB.actualLineNumber < lastMeatLine &&
          changeLine.fileB.actualLineNumber > firstMeatLine
        ) {
          lines.push(SELECT_MARKER + changeLine.fileB.data);
        }
        break;
      }
      case HunkChangeLineType.Update: {
        lines.push(SELECT_MARKER + changeLine.fileB.data);
        break;
      }
    }
  }
  const applicability =
    dynamicHunkContainer.swimmHunkMetadata && dynamicHunkContainer.swimmHunkMetadata.applicability
      ? dynamicHunkContainer.swimmHunkMetadata.applicability
      : null;
  const constructedSwmCellSnippet: SwmCellSnippet = {
    firstLineNumber: dynamicHunkContainer.gitHunkMetadata.lineNumbers.fileB.startLine,
    path: path,
    type: SwmCellType.Snippet,
    lines: lines,
    comments: [],
    repoId,
    id: uuidv4(),
  };
  if (applicability) {
    constructedSwmCellSnippet.applicability = applicability;
  }
  if (patchType) {
    constructedSwmCellSnippet.patchType = patchType;
  }
  return constructedSwmCellSnippet;
}

export function lineMatchSnippetCellBySimilarity(snippet: Snippet): DynamicChangeLine[] {
  const dynamicHunk = swmCellSnippetToDynamicHunkContainer(snippet);
  const matchedDynamicHunk = matchHunkLines({ hunkContainer: dynamicHunk });
  return matchedDynamicHunk.changes;
}

// return the file B snippets lines with no selection mark
export function getFileBLinesFromHunkContainer(dynamicHunkContainer: DynamicHunkContainer) {
  const lines: string[] = [];
  const meatLinesPredicate = (changeLine: DynamicChangeLine) =>
    changeLine.changeType === HunkChangeLineType.Added || changeLine.changeType === HunkChangeLineType.Update;

  const lastMeatLine = findLast(dynamicHunkContainer.changes, meatLinesPredicate)?.fileB?.actualLineNumber;
  const firstMeatLine = dynamicHunkContainer.changes.find(meatLinesPredicate)?.fileB?.actualLineNumber;

  for (const changeLine of dynamicHunkContainer.changes) {
    switch (changeLine.changeType) {
      case HunkChangeLineType.Added: {
        lines.push(changeLine.fileB.data);
        break;
      }
      case HunkChangeLineType.Deleted: {
        break;
      }
      case HunkChangeLineType.Context: {
        if (changeLine.fileB.actualLineNumber < lastMeatLine && changeLine.fileB.actualLineNumber > firstMeatLine) {
          lines.push(changeLine.fileB.data);
        }
        break;
      }
      case HunkChangeLineType.Update: {
        lines.push(changeLine.fileB.data);
        break;
      }
    }
  }
  return { lines: lines, firstLineNumber: firstMeatLine, lastLineNumber: lastMeatLine };
}
