<script setup lang="ts">
import { computed, ref } from 'vue';
import type { PathSuggestion } from '@swimm/shared';

import BaseTruncate from '../../components/BaseTruncate/BaseTruncate.vue';
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';
import BaseButton from '../../components/BaseButton/BaseButton.vue';
import BaseHighlight from '../../components/BaseHighlight/BaseHighlight.vue';

import MenuItem from '../MenuItem/MenuItem.vue';

defineProps<{
  path: PathSuggestion;
  query?: string;
}>();

const el = ref<InstanceType<typeof MenuItem>>();
const root = computed(() => el?.value?.root);
const text = computed(() => el?.value?.text);

defineExpose({ root, text });
</script>

<template>
  <MenuItem ref="el" wrapper="div" class="menu-item-path">
    <BaseTruncate align="right"><BaseHighlight :query="query" :string="path.path" /></BaseTruncate>
    <template #additional>
      <BaseButton size="small" class="add-button"
        >Add<template #rightIcon><BaseIcon name="enter" class="keyboard-icon" /></template
      ></BaseButton>
    </template>
  </MenuItem>
</template>

<style scoped lang="postcss">
.menu-item-path {
  &:not(.menu-item--focused):not(:hover) .add-button {
    visibility: hidden;
  }

  &:hover:not(.menu-item--focused) {
    .add-button {
      visibility: visible;
    }

    .keyboard-icon {
      display: none;
    }
  }
}
</style>
