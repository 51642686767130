import { getRepoStateDataToKeep } from '@/adapters-common/user';
import { getRemoteRepoBranch, getRepoDefaultBranch, isBranchExists } from '@/remote-adapters/local_repo';
import { config, getLoggerNew, gitProviderUtils, isRepoIdDummyRepo, parseRepoDataFields, state } from '@swimm/shared';
import { useStore } from 'vuex';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { computed, nextTick } from 'vue';
import { useNotificationsStore } from '@swimm/editor';
import { useReloadAppData } from './reloadAppData';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useStigg } from './useStigg';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import * as firestoreWrapper from '@/adapters-common/firestore-wrapper';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';

const logger = getLoggerNew(__modulename);

export function useRouting() {
  const route = useRoute();
  const router = useRouter();
  const store = useStore();
  const reposStore = useReposStore();
  const { reposStateData } = storeToRefs(reposStore);
  const { setRepoStateData } = reposStore;
  const { addNotification } = useNotificationsStore();
  const { reloadRepoAppData } = useReloadAppData();
  const { isCurrentRepoAuthorized, isCurrentWorkspaceAuthorized } = storeToRefs(useGitAuthorizationStore());
  const { initStiggForCustomer } = useStigg();
  const { user } = storeToRefs(useAuthStore());
  const getPreviousRoute = computed(() => store.getters['getPreviousRoute']);
  const fs_getRepository = computed(() => store.getters['filesystem/fs_getRepository']);
  const db_isPlaylistInRepo = computed(() => store.getters['database/db_isPlaylistInRepo']);
  const db_hasWorkspaces = computed(() => store.getters['database/db_hasWorkspaces']);
  const db_getUserWorkspaces = computed(() => store.getters['database/db_getUserWorkspaces']);
  const db_isWorkspaceUser = computed(() => store.getters['database/db_isWorkspaceUser']);
  const db_getWorkspaceRepoIds = computed(() => store.getters['database/db_getWorkspaceRepoIds']);
  const db_getWorkspaceExpiredRepoIds = computed(() => store.getters['database/db_getWorkspaceExpiredRepoIds']);
  const db_getWorkspaceUsers = computed(() => store.getters['database/db_getWorkspaceUsers']);
  const db_getRepoMetadata = computed(() => store.getters['database/db_getRepoMetadata']);
  const fetchSwimmerWorkspaces = async (args?) => await store.dispatch('database/fetchSwimmerWorkspaces', args);
  const fetchSwimmerWorkspace = async (args?) => await store.dispatch('database/fetchSwimmerWorkspace', args);
  const fetchRepository = async (args?) => await store.dispatch('database/fetchRepository', args);
  const fetchRepoResource = async (args?) => await store.dispatch('database/fetchRepoResource', args);
  const cleanLoadedRepoData = async (args?) => await store.dispatch('filesystem/cleanLoadedRepoData', args);

  const navigateTo404 = async () => {
    logger.error(`Navigating to /404 from ${route.fullPath}`);
    const originalUrl = route.fullPath;
    await router.replace('/404');
    await nextTick();
    logger.debug(`Replacing URL: ${originalUrl}`);
    history.replaceState(null, '', originalUrl);
  };
  const assertRoutingToRepo = async () => {
    await assertWorkspaceInRoute();
    const assertRepoResult = await assertRepoInWorkspace();
    if (!assertRepoResult) {
      return false;
    }
    await assertBranchInRoute();
    const repoId = route.params.repoId as string;
    // Verify that the local repo directory in the local state actually points to the route repo.
    // Different cwd's might cause the FE to try load resources from another repo directory
    if (fs_getRepository.value(repoId) && !route.params.branch) {
      return true;
    }
    if (!isCurrentRepoAuthorized.value && !isRepoIdDummyRepo(repoId)) {
      return false;
    }
    await reloadRepoAppData(repoId); // Reload the store data to check if the repo has loaded
    if (!fs_getRepository.value(repoId)) {
      const isOnboarding = assertOnboardingRoute();
      if (isOnboarding) {
        return false;
      }
      if (!route.params.repoId) {
        return false;
      }
      logger.error(`Workspace has no such repo, workspaceId: ${route.params.workspaceId}, repoId: ${repoId}`);
      if (route.query && route.query.source && route.query.source === 'link') {
        // For links redirect to workspace page
        await router.push(`/workspaces/${route.params.workspaceId}`);
      } else {
        await navigateTo404();
      }
      return false;
    }
    await assertCurrentBranchExists();
    return true;
  };
  /**
   * Try to append a workspace to a url opened in the app if not exist.
   * i.e - if the user ran `swimm solution` and the opened url contained no workspace - find a workspace containing the repo and add it to the path
   */
  const assertWorkspaceInRoute = async () => {
    // Force the user to be in a workspace or create one when using the app
    if (!db_hasWorkspaces.value(user.value.uid)) {
      if (route.params.workspaceId) {
        await fetchSwimmerWorkspace(route.params.workspaceId);
      }
      if (!db_hasWorkspaces.value(user.value.uid)) {
        await fetchSwimmerWorkspaces();
      }
      if (!db_hasWorkspaces.value(user.value.uid)) {
        logger.info(`User is not a member of any workspace`);
        await router.push('/joinWorkspace');
      }
    }
    if (!route.params.workspaceId && !route.fullPath.startsWith(`/workspaces/initRepo`)) {
      const workspaces = db_getUserWorkspaces.value(user.value.uid);
      for (const workspaceId in workspaces) {
        if (db_getWorkspaceRepoIds.value(workspaceId).includes(route.params.repoId)) {
          const currentPath = route.fullPath;
          const isOnboarding = assertOnboardingRoute();
          if (isOnboarding) {
            return;
          }
          await router.replace(`/workspaces/${workspaceId}${currentPath}`);
          window.location.reload();
          return;
        }
      }
      // If a related workspace could not be resolved - redirect to 404
      logger.error(`Could not find a workspace to navigate to for URL: ${route.fullPath}`);
      await navigateTo404();
    }
    saveLatestWorkspace(<string>route.params.workspaceId).then();
  };
  /**
   * If the repo is not in the workspace selected, or if there is no workspace selected redirect to the workspace page
   */
  const assertRepoInWorkspace = async () => {
    const workspaceId = route.params.workspaceId;
    if (!workspaceId || route.fullPath.startsWith(`/workspaces/initRepo`) || !route.params.repoId) {
      return false;
    }
    const repositories = db_getWorkspaceRepoIds.value(workspaceId) || [];
    const expiredRepositories = db_getWorkspaceExpiredRepoIds.value(workspaceId) || [];

    const workspaceUser = (db_getWorkspaceUsers.value(workspaceId) || {})[user.value.uid];
    if (workspaceUser && workspaceUser.expired) {
      logger.info(`User is an expired member of workspace ${workspaceId}`);
      await router.replace(`/workspaces/${workspaceId}/paywall?src=expiredUser`);
      return false;
    }

    if (repositories.includes(route.params.repoId) && !expiredRepositories.includes(route.params.repoId)) {
      return true;
    }

    if (expiredRepositories.includes(route.params.repoId)) {
      await router.replace(`/workspaces/${workspaceId}/paywall?src=expiredRepo`);
      return false;
    }

    await router.replace(`/workspaces/${workspaceId}`);
    return false;
  };
  const assertRouting = async () => {
    if (route.params.repoId) {
      const repoAssertResult = await assertRoutingToRepo();
      if (!repoAssertResult) {
        return false;
      }
    }
    const workspaceId = <string>route.params.workspaceId;
    const playlistId = route.params.playlistId;
    if (playlistId) {
      const isPlaylistExists = db_isPlaylistInRepo.value(route.params.repoId, playlistId);
      if (!isPlaylistExists) {
        // Playlist might not have been fetched yet, try fetching it.
        await fetchRepoResource({
          repoId: route.params.repoId,
          collectionName: firestoreWrapper.collectionNames.PLAYLISTS,
          docId: playlistId,
        });
      }
    }
    if (workspaceId) {
      if (!db_isWorkspaceUser.value(workspaceId, user.value.uid)) {
        await fetchSwimmerWorkspace(workspaceId);
        if (!db_isWorkspaceUser.value(workspaceId, user.value.uid)) {
          logger.info(`User is not a member of workspace ${workspaceId}`);
          await router.push(`/joinWorkspace/${workspaceId}`);
          return false;
        }
      }

      const workspaceUser = (db_getWorkspaceUsers.value(workspaceId) || {})[user.value.uid];
      if (workspaceUser && workspaceUser.expired) {
        logger.info(`User is an expired member of workspace ${workspaceId}`);
        await router.push(`/workspaces/${workspaceId}/paywall?src=expiredUser`);
        return false;
      }

      try {
        await initStiggForCustomer(workspaceId);
      } catch (err) {
        logger.error({ err }, `Failed setting workspace ${workspaceId} in Stigg`);
      }
    } else {
      if (!db_hasWorkspaces.value(user.value.uid)) {
        await fetchSwimmerWorkspaces();
      }
    }

    return true;
  };
  async function assertBranchInRoute() {
    const currentFullPath = route.fullPath;
    if (route.params.repoId && !route.params.branch) {
      const branch = await getCurrentOrDefaultBranch(<string>route.params.repoId);
      if (!branch) {
        return;
      }
      // We make sure the route didn't change right before rerouting.
      if (route.fullPath === currentFullPath) {
        const newPath = route.fullPath.replace(
          `/repos/${route.params.repoId}`,
          `/repos/${route.params.repoId}/branch/${encodeURIComponent(branch)}`
        );

        await router.replace(newPath);
      }
    }
  }

  // this function update the state data in indexed db
  // in very "safe" way, it does not delete anything
  // and it updates only if the key is missing
  // the idea is to set the new data only if it is missing
  // used as workaround to get over the errors when calling isBranchProtected
  // return true if any update was made
  const updateStateRepoDataIfMissing = async ({ repoData, repoId }) => {
    const curStateData = reposStateData.value[repoId];
    if (curStateData) {
      const updatedData = { ...curStateData };
      let anyUpdate = false;
      for (const [key, value] of Object.entries(repoData)) {
        if (!(key in curStateData)) {
          updatedData[key] = value;
          anyUpdate = true;
        }
      }
      if (anyUpdate) {
        await setRepoStateData(repoId, updatedData);
      }
      return anyUpdate;
    } else {
      await setRepoStateData(repoId, { ...repoData });
      return true;
    }
  };

  const setStateRepoDataAndClearStore = async ({ repoData, repoId = <string>route.params.repoId }) => {
    const repoStateDataToKeep = getRepoStateDataToKeep(reposStateData.value[repoId]);
    await setRepoStateData(repoId, { ...repoStateDataToKeep, ...repoData });
    await cleanLoadedRepoData(repoId);
  };
  const assertCurrentBranchExists = async () => {
    if (!route.params.repoId) {
      return;
    }
    const repoId = <string>route.params.repoId;
    let repoData = Object.create({});
    const selectedBranch = await getCurrentOrDefaultBranch(repoId);
    const result: { code: 0 | 1; isBranchExists?: boolean } = await isBranchExists({
      repoId,
      branchName: selectedBranch,
    });
    if (result.code !== config.SUCCESS_RETURN_CODE || !result.isBranchExists) {
      logger.error(`branch "${selectedBranch}" does not exist. Switching to default branch instead`);
      const defaultBranchResult = await getRepoDefaultBranch({ repoId: repoId });
      if (defaultBranchResult.code === config.SUCCESS_RETURN_CODE) {
        repoData.defaultBranch = defaultBranchResult.branch;
        repoData.isProtectedBranch = await isBranchProtected({ repoId, branch: defaultBranchResult.branch });
      }
      const repoDataFromState = reposStateData.value[repoId];
      if (repoDataFromState) {
        repoData = { ...repoDataFromState, ...repoData };
      }
      const newRoute = repoData.defaultBranch
        ? route.fullPath.replace(
            `/branch/${encodeURIComponent(route.params.branch as string)}`,
            `/branch/${encodeURIComponent(repoData.defaultBranch)}`
          )
        : route.fullPath;

      if (newRoute === route.fullPath) {
        logger.warn(
          `Skipped branch-route change as the route already includes the branch: "${repoData.defaultBranch}" in it.`
        );
        return;
      }
      await router.replace(newRoute);
      await setStateRepoDataAndClearStore({ repoData });
      notifyMoveToDefaultBranch(selectedBranch);
    }
  };
  // Saves the last workspace that the user was in, for opening it automatically when there is no workspace in the route (i.e - swimm start out of git repo)
  const saveLatestWorkspace = async (workspaceId: string) => {
    if (workspaceId) {
      await state.set({ key: 'latest_user_workspace_id', value: workspaceId });
    }
  };
  /**
   * Handle Back
   * @desc Extends default router back functionality by navigating to a fallback path if there is no history to use with $router.back().
   * This is usually the case if the page was visited directly via a shared link or opened via the CLI (i.e - `swimm create`)
   **/
  const routeBackFromDoc = async () => {
    const isOnboarding = assertOnboardingRoute();
    if (getPreviousRoute.value !== '/' && !isOnboarding && !route.query.sgdTemplateId) {
      router.back();
      return;
    }

    let newRoute = `/workspaces/${route.params.workspaceId}`;
    if (route.params.repoId) {
      newRoute += `/repos/${route.params.repoId}`;
    }
    if (route.params.branch) {
      newRoute += `/branch/${encodeURIComponent(<string>route.params.branch)}`;
    }
    if (route.params.playlistId) {
      newRoute += `/playlists/${route.params.playlistId}`;
    }
    await router.push(newRoute);
  };
  const assertOnboardingRoute = () => {
    return route.path.includes('ONBOARDING');
  };
  const assertOnRepoPageRoute = () => {
    const repoId = route.params.repoId as string;
    const repoUrl = `repos/${repoId}`;
    const branch = route.params.branch as string;
    if (branch) {
      return route.path.endsWith(`${repoUrl}/branch/${branch}`);
    }
    return route.path.endsWith(`${repoUrl}`);
  };
  const assertOnPlaylistPageRoute = () => {
    return route.path.includes('/playlists');
  };
  const assertOnDocOrPlaylistPageRoute = () => {
    return route.path.includes('/docs') || assertOnPlaylistPageRoute();
  };
  const assertWelcomeRoute = () => {
    return route.path.endsWith('/welcome') || route.path.endsWith('/welcome/');
  };
  const getCurrentOrDefaultBranch = async (repoId: string): Promise<string> => {
    if (!repoId) {
      throw new TypeError(`Expected a valid repoId, but got: ${repoId}`);
    }
    if (route.params.branch && route.params.repoId === repoId) {
      return route.params.branch as string;
    }
    return await getDefaultBranch(repoId);
  };
  const getDefaultBranch = async (repoId: string) => {
    if (!repoId) {
      throw new TypeError(`Expected a valid repoId, but got: ${repoId}`);
    }
    if (!isRepoIdDummyRepo(repoId)) {
      if (!isCurrentWorkspaceAuthorized.value) {
        logger.debug(
          `Cannot find current or default branch for repoId: ${repoId} as the user isn't authorised to their git provider`
        );
        return '';
      }
    }
    const repoDataFromState = reposStateData.value[repoId];
    // since getCurrentOrDefaultBranch we make sure to use the state if possible
    if (repoDataFromState?.defaultBranch) {
      return repoDataFromState.defaultBranch;
    }
    logger.debug(
      `current branch from query and default branch from state are not available for repoId: ${repoId}. Fetching default branch from Git provider...`
    );
    let repoMetadataFromDB = db_getRepoMetadata.value(repoId);
    if (!repoMetadataFromDB) {
      // This might happen for repos that were never fetched before and saved into indexDB (direct link to page without branch flow on first time)
      logger.info(`db information for repoId: ${repoId} is not available. Fetching db data...`);
      try {
        await fetchRepository({ repoId });
        repoMetadataFromDB = db_getRepoMetadata.value(repoId);
      } catch (err) {
        logger.error({ err }, `DB information could not be fetched for repo ${repoId}`);
      }
    }
    if (repoMetadataFromDB && repoMetadataFromDB.name && repoMetadataFromDB.owner && repoMetadataFromDB.provider) {
      const parsedUrl = gitProviderUtils.parseGitProviderURL(repoMetadataFromDB.url);

      const repoDefaultData = parseRepoDataFields({
        repoName: parsedUrl.name,
        owner: parsedUrl.owner,
        provider: repoMetadataFromDB.provider,
        api_url: repoMetadataFromDB.api_url,
        tenant_id: repoMetadataFromDB.tenant_id,
      });
      const defaultBranchResult = await getRepoDefaultBranch({ repoId, ...repoDefaultData });
      if (defaultBranchResult.code === config.SUCCESS_RETURN_CODE) {
        // must update the indexed db here, since otherwise the isBranchProtected may fail
        // since it is called with repoId but we did not set the provider, repoName and owner in the indexed db
        // note that after that we call setStateRepoDataAndClearStore which overrides this
        const anyUpdate = await updateStateRepoDataIfMissing({
          repoData: repoDefaultData,
          repoId,
        });
        if (anyUpdate) {
          logger.warn(`Update repo ${repoId} before calling isBranchProtected to avoid gitwrapper errors`);
        }
        const repoData = {
          ...repoDataFromState,
          repoName: parsedUrl.name,
          owner: parsedUrl.owner,
          provider: repoMetadataFromDB.provider,
          branch: repoDataFromState?.branch ?? defaultBranchResult.branch,
          isProtectedBranch: await isBranchProtected({ repoId, branch: defaultBranchResult.branch }),
          defaultBranch: defaultBranchResult.branch,
        };
        if (repoMetadataFromDB.api_url) {
          repoData.api_url = repoMetadataFromDB.api_url;
        }
        await setStateRepoDataAndClearStore({
          repoData,
          repoId,
        });

        return defaultBranchResult.branch;
      }
    } else {
      logger.warn(`Could not fetch branch since fetch DB data for repo: ${repoId} failed.`);
    }
    // If we got here, we couldn't fetch the default branch from the git provider and this
    // has already been logged, no need to log it here as well
    return '';
  };
  const isBranchProtected = async ({ repoId, branch }: { repoId: string; branch: string }) => {
    const repoState = reposStateData.value[repoId];
    if (
      branch === repoState?.branch &&
      (repoState?.isProtectedBranch === true || repoState?.isProtectedBranch === false)
    ) {
      return repoState?.isProtectedBranch;
    }
    const remoteBranchData: { code: 0 | 1; branchDetails? } = await getRemoteRepoBranch({
      repoId: repoId,
      branchName: branch,
    });
    return !!remoteBranchData.branchDetails && remoteBranchData.branchDetails.protected;
  };
  const notifyMoveToDefaultBranch = (oldBranch: string) => {
    const notificationExtraAttr = { closeButtonText: 'Dismiss' };
    addNotification(`You've been automatically moved to the default branch since "${oldBranch}" does not exist`, {
      autoClose: false,
      ...notificationExtraAttr,
    });
  };

  return {
    navigateTo404,
    assertRoutingToRepo,
    assertWorkspaceInRoute,
    assertRepoInWorkspace,
    assertRouting,
    assertCurrentBranchExists,
    assertOnRepoPageRoute,
    saveLatestWorkspace,
    routeBackFromDoc,
    assertOnboardingRoute,
    assertWelcomeRoute,
    assertOnPlaylistPageRoute,
    assertOnDocOrPlaylistPageRoute,
    getCurrentOrDefaultBranch,
    getDefaultBranch,
    isBranchProtected,
    notifyMoveToDefaultBranch,
  };
}
