import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import {
  type UploadedImage,
  generateImageFilename,
  getImageSize,
  getRepoFilePathForImageFile,
  isImageFileSupported,
  resizeImageFile,
} from '@swimm/swmd';
import { getUploadDirectory } from '@swimm/editor';
import { type ImageDraft } from '../drafts3/db';

export async function uploadImage(workspaceId: string, repoId: string, file: File): Promise<UploadedImage> {
  const storage = getStorage();

  if (!isImageFileSupported(file)) {
    throw new Error(`disallowed file type: ${file.type}`);
  }

  const uploadDir = getUploadDirectory(workspaceId, repoId);
  const name = generateImageFilename(file.name);
  const fullName = `${uploadDir}/${name}`;

  const { width, height } = await getImageSize(file);
  const fileRef = ref(storage, fullName);
  await uploadBytes(fileRef, file, { contentType: file.type });

  return { src: await getDownloadURL(fileRef), width, height };
}

export async function createImageDraft(
  workspaceId: string,
  repoId: string,
  branch: string,
  userId: string,
  unitId: string,
  file: File
): Promise<ImageDraft> {
  if (!isImageFileSupported(file)) {
    throw new Error(`disallowed file type: ${file.type}`);
  }

  const scaledImage = await resizeImageFile(file);

  const repoFilePath = encodeURI(getRepoFilePathForImageFile(scaledImage.file));
  return {
    workspaceId,
    repoId,
    branch,
    userId,
    draftId: unitId,
    path: repoFilePath,
    file: scaledImage.file,
    width: scaledImage.width,
    height: scaledImage.height,
  };
}
