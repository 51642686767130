import { config, state } from '@swimm/shared';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { STORES } from '@/modules/core/stores/store-constants';

export const useGlobalStore = defineStore(STORES.GLOBAL_STORE, () => {
  const dismissedWorkspacesInvites = ref<string[]>([]);
  const fetchedWorkspceInviteDismissals = ref<boolean>(false);
  const shouldOpenSnippetPopUp = ref<boolean>(false);

  function resetState() {
    dismissedWorkspacesInvites.value = [];
    fetchedWorkspceInviteDismissals.value = false;
    shouldOpenSnippetPopUp.value = false;
  }

  function toggleSnippetPopUp(value: boolean) {
    shouldOpenSnippetPopUp.value = value;
  }

  function dismissWorkspaceInvite(workspaceId: string) {
    dismissedWorkspacesInvites.value.push(workspaceId);
  }

  function removeWorkspaceDismissal(workspaceId: string) {
    const inviteIndex = dismissedWorkspacesInvites.value.findIndex((id) => id === workspaceId);
    if (inviteIndex >= 0) {
      dismissedWorkspacesInvites.value.splice(inviteIndex, 1);
    }
  }

  // TODO: check if this function is really needed
  async function fetchDismissedWorkspaceInvites() {
    if (!fetchedWorkspceInviteDismissals.value) {
      const dismissedInvitesResult = await getDismissedInvitesFromLocalState();
      if (dismissedInvitesResult.code === config.SUCCESS_RETURN_CODE) {
        dismissedWorkspacesInvites.value = dismissedInvitesResult.dismissedWorkspaceInvites;
        fetchedWorkspceInviteDismissals.value = true;
      }
    }
  }

  async function getDismissedInvitesFromLocalState() {
    return {
      code: config.SUCCESS_RETURN_CODE,
      dismissedWorkspaceInvites: await state.get({
        key: 'dismissed_workspace_invites',
        defaultValue: [],
      }),
    };
  }

  return {
    shouldOpenSnippetPopUp,
    resetState,
    toggleSnippetPopUp,
    dismissWorkspaceInvite,
    removeWorkspaceDismissal,
    fetchDismissedWorkspaceInvites,
  };
});
