<template>
  <div class="notification" :class="notificationClass" data-testid="notification" @click="onActionClick">
    <div class="dot" data-testid="notification-dot" />
    <SwAvatar :text="notifier" border data-testid="notification-notifier" />
    <div class="notification-text">
      <span class="text title" data-testid="notification-title">{{ title }}</span>
      <br v-if="actionInANewLine" data-testid="notification-new-line" />
      <span v-if="action" class="text action" data-testid="notification-action">{{ action }}</span>
      <span v-if="afterAction" class="text title" data-testid="notification-after-action">{{ afterAction }}</span>
      <div class="time-past" data-testid="notification-time-past">{{ timePastSinceString }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { SwAvatar } from '@swimm/ui';
import { getSecondsPastSince, getUnitAndTitleForTimePast } from '@/modules/notifications-center/services/timestamp';

export default {
  components: { SwAvatar },
  props: {
    action: { type: String, required: false, default: '' },
    title: { type: String, required: true },
    notifier: { type: String, required: true },
    unseen: { type: Boolean, required: true },
    dismissed: { type: Boolean, required: true },
    timestamp: { type: Number, required: true },
    notificationId: { type: String, required: true },
    notificationType: { type: String, required: true },
    actionInANewLine: { type: Boolean, required: false, default: false },
    afterAction: { type: String, required: false, default: '' },
    link: { type: String, required: false, default: '' },
  },
  emits: ['notification-action', 'report-click'],
  data() {
    return {
      secondsPastSince: null,
      markedAsSeen: false,
      localDismissed: false,
    };
  },
  computed: {
    notificationClass() {
      return {
        'notification-clicked': this.dismissed || this.localDismissed,
        'notification-unseen': this.unseen,
      };
    },
    timePastSinceString() {
      if (!this.secondsPastSince) {
        return 'now';
      }

      const { unit, title } = getUnitAndTitleForTimePast(this.secondsPastSince);
      return `${unit} ${title} ago`;
    },
  },
  async mounted() {
    this.secondsPastSince = getSecondsPastSince(this.timestamp);
    if (this.unseen) {
      this.startObserveVisibility();
    }
  },
  beforeUnmount() {
    this.stopObserveVisibility();
  },
  methods: {
    ...mapActions('database', ['markNotificationAsDismissed', 'markNotificationAsSeen']),
    startObserveVisibility() {
      // If the notification is not seen yet, we use the IntersectionObserver API
      // so once it is visible we will mark it as visible
      // the IntersectionObserver observe visibility changes in document
      // and we give it threshold of 0.2, so the callback
      // is invoked in 0 visibility and when visibility >= 0.2
      // once it is visible we mark as read and disconnect to prevent more tracking
      // read here https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          if (!this.markedAsSeen) {
            if (entries[0] && entries[0].intersectionRatio > 0) {
              this.markNotificationAsSeen(this.notificationId);
              this.markedAsSeen = true;
              this.stopObserveVisibility();
            }
          }
        },
        {
          threshold: 0.2,
        }
      );
      this.intersectionObserver.observe(this.$el);
    },
    stopObserveVisibility() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
        this.intersectionObserver = null;
      }
    },
    newTabClicked($event) {
      return $event.metaKey || $event.shiftKey || $event.ctrlKey;
    },
    isSameOrigin(link) {
      // We consider relative urls as same origin
      if (!link.startsWith('http')) {
        return true;
      }
      try {
        return new URL(link).origin === window.location.origin;
      } catch {
        return true;
      }
    },
    async onActionClick($event) {
      // If there is a link it will be opened on new tab
      // if it is from other origin or if the user clicked ctrl/meta/shift
      const openInNewTab = !!this.link && (!this.isSameOrigin(this.link) || this.newTabClicked($event));
      if (openInNewTab) {
        // We must do the open before any other async operation
        window.open(this.link, '_blank');
      }
      this.localDismissed = true; // Mark locally to change the appearance
      await this.markNotificationAsDismissed(this.notificationId);
      this.$emit('report-click');
      if (this.link) {
        if (!openInNewTab) {
          window.location.href = this.link; // I tried using the router but branch switching was an issue
        }
      } else {
        this.$emit('notification-action');
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.notification {
  display: flex;
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 16px;
  font-family: var(--fontfamily-main);
  cursor: pointer;

  &:hover {
    background-color: var(--color-hover);
  }

  &:not(.notification-clicked) {
    background-color: var(--color-selected);

    &:hover {
      background-color: var(--color-hover);
    }
  }

  .notification-text {
    margin-left: 8px;
    max-width: 270px;
  }

  .text {
    font-size: var(--fontsize-xs);
    font-family: var(--fontfamily-main);
    line-height: var(--fontsize-xs);
    vertical-align: top;
  }

  .title {
    color: var(--text-color-primary);
  }

  .action {
    word-break: break-word;
    font-weight: bold;
    color: var(--text-color-link);
  }

  .time-past {
    margin-top: 8px;
    font-size: var(--fontsize-xs);
    color: var(--text-color-secondary);
  }

  .dot {
    margin-top: 8px;
    margin-right: 4px;
    width: 8px;
    height: 8px;
  }

  &.notification-unseen .dot {
    border-radius: 50%;
    background-color: var(--text-color-error);
  }
}
</style>
