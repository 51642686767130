import swal from 'sweetalert';
import {
  SWAL_CONTACT_US_CONTENT,
  SWAL_LIST_CONTENT,
  SWAL_READ_GETTING_STARTED_CONTENT,
} from '@/common/utils/common-definitions';
import { useRoute, useRouter } from 'vue-router';
import { PageRoutesNames } from '../consts';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { pluralize } from '@/common/utils/helpers';
import { config, extractResourceIdFromPath, getLoggerNew } from '@swimm/shared';
import { type Draft, DraftType } from '@/modules/drafts3/db';
import { getSwimmDocumentFiles } from '@/modules/drafts3/docs';

const logger = getLoggerNew(__modulename);

export function useBranchSwitcher3() {
  const route = useRoute();
  const router = useRouter();
  const drafts3Store = useDrafts3Store();

  async function validateNewBranch(newBranch: string, draftIds: string[] | undefined) {
    try {
      const shouldHandleDrafts = !!draftIds?.length;
      if (shouldHandleDrafts) {
        // Move drafts to target branch if possible.
        return await handleMovingDraftsToTargetBranch(newBranch, draftIds);
      }
      if (route.params.unitId || route.params.playlistId) {
        // switch branch in case the resource exists on the traget branch.
        const resourceId = (route.params.unitId || route.params.playlistId) as string;
        const resourceNotExistsOnTargetBranch = await handleResourceNotExistsOnTargetBranch(newBranch, [
          // no need the name only if we check for more than one resource.
          {
            id: resourceId,
            isDoc: !!route.params.unitId,
          },
        ]);
        return !resourceNotExistsOnTargetBranch;
      }
    } catch (err) {
      logger.error({ err }, `Failed to validate branch before switching: ${err}`);
      await swal({
        title: `Failed switching to "${newBranch}" branch`,
        content: { element: SWAL_CONTACT_US_CONTENT() },
      });
      return false;
    }
    return true;
  }

  async function handleResourceNotExistsOnTargetBranch(
    targetBranch: string,
    resources: { name?: string; id: string; isDoc: boolean }[]
  ) {
    const resourcesById = Object.fromEntries(resources.map((resource) => [resource.id, resource]));
    const resourceIds = Object.keys(resourcesById);
    const repoEntriesOnRemote = await getSwimmDocumentFiles({
      repoId: route.params.repoId as string,
      branch: targetBranch,
      fileExtensions: [config.SWMD_FILE_EXTENSION, config.SWMD_PLAYLIST_EXTENSION],
    });
    const resourceIdsOnRemote = repoEntriesOnRemote.map((repoEntry) => extractResourceIdFromPath(repoEntry.path));
    const notOnRemote = resourceIds.filter((resourceId) => !resourceIdsOnRemote.includes(resourceId));
    if (notOnRemote.length > 0) {
      // get the names of resources that are missing on the traget branch.
      const docsNotOnTargetBranchNames = [];
      const playlistsNotOnTargetBranchNames = [];
      notOnRemote.forEach((resourceId) => {
        const resource = resourcesById[resourceId];
        if (resource.isDoc) {
          docsNotOnTargetBranchNames.push(resource.name ?? 'Doc');
        } else {
          playlistsNotOnTargetBranchNames.push(resource.name ?? 'Playlist');
        }
      });

      const content = document.createElement('div');
      if (resourceIds.length > 1) {
        // list resources only if tried to move more than one.
        content.appendChild(SWAL_LIST_CONTENT(docsNotOnTargetBranchNames.concat(playlistsNotOnTargetBranchNames)));
      }
      content.appendChild(SWAL_READ_GETTING_STARTED_CONTENT());

      let messagePrefix = '';
      if (resourceIds.length === 1) {
        messagePrefix = `This ${docsNotOnTargetBranchNames.length > 0 ? 'doc' : 'playlist'}`;
      } else if (notOnRemote.length === 1) {
        messagePrefix = `A ${docsNotOnTargetBranchNames.length > 0 ? 'doc' : 'playlist'}`;
      } else if (!playlistsNotOnTargetBranchNames.length) {
        messagePrefix = 'Docs';
      } else if (!docsNotOnTargetBranchNames.length) {
        messagePrefix = 'Playlists';
      } else {
        messagePrefix = 'Docs/Playlists';
      }
      await swal({
        title: `${messagePrefix} doesn't exist on the "${targetBranch}" branch`,
        text: `Please select a different branch.`,
        content: { element: content },
      });
      return true;
    }
    return false;
  }

  function getDraftsFromIds(draftIds: string[]): Draft[] {
    return draftIds.map((draftId) => drafts3Store.drafts.get(draftId)).filter(Boolean);
  }

  async function handleMovingDraftsToTargetBranch(targetBranch: string, draftIds: string[]) {
    const draftOrDrafts = pluralize({ word: 'draft', count: draftIds.length });
    const draftsToMove = getDraftsFromIds(draftIds);
    const draftNames = draftsToMove.map((draft) => drafts3Store.getDraftTitle(draft) ?? '"Untitled Draft"');
    const shouldMoveDraft = await swal({
      title: `Move ${draftOrDrafts} to another branch?`,
      text: `You are about to switch branches and move ${draftOrDrafts} to branch "${targetBranch}".\n\nWARNING: Any referenced code might be outdated in the new branch.`,
      content: { element: SWAL_LIST_CONTENT(draftNames) },
      buttons: {
        cancel: true,
        confirm: { text: `Move ${draftIds.length > 1 ? `${draftIds.length} drafts` : 'draft'}` },
      },
    });

    if (!shouldMoveDraft) {
      return false;
    }

    const draftsOfExistingResources = draftsToMove.filter((draft) => !draft.isNew);
    if (draftsOfExistingResources.length) {
      const resourceIds = draftsOfExistingResources.map((draft) => draft.id);
      const resourceNotExistsOnTargetBranch = await handleResourceNotExistsOnTargetBranch(
        targetBranch,
        draftsOfExistingResources.map((draft) => ({
          name: drafts3Store.getDraftTitle(draft),
          id: draft.id,
          isDoc: draft.type === DraftType.DOC,
        }))
      );
      if (resourceNotExistsOnTargetBranch) {
        return false;
      }
      const draftExistsForResourceOnTragetBranch = await handleDraftsExistsForResourcesOnTargetBranch(
        targetBranch,
        resourceIds
      );
      if (draftExistsForResourceOnTragetBranch) {
        return false;
      }
    }
    await drafts3Store.moveDraftsToBranch(draftIds, targetBranch);
    return true;
  }

  async function handleDraftsExistsForResourcesOnTargetBranch(targetBranch: string, resourceIds: string[]) {
    const draftsExistOnTargetBranch = await Promise.all(
      resourceIds.map((resourceId) => drafts3Store.doesDraftExistOnBranch(resourceId, targetBranch))
    );
    if (!draftsExistOnTargetBranch.includes(true)) {
      return false;
    }

    const firstExistingDraftIndex = draftsExistOnTargetBranch.findIndex((existResult) => existResult);
    const resourceIdWithDraft = resourceIds[firstExistingDraftIndex];

    const currentDraft = drafts3Store.drafts.get(resourceIdWithDraft);
    const shouldShowTragetBranch = await swal({
      title: `Draft for ${drafts3Store.getDraftTitle(currentDraft)} already exists on target branch`,
      text: `A draft for this ${currentDraft.type} already exists on "${targetBranch}". Discard or commit existing draft on that branch and try again.`,
      buttons: {
        cancel: true,
        confirm: { text: 'Show branch' },
      },
    });

    if (shouldShowTragetBranch) {
      const targetBranchRepoRouteData = router.resolve({
        name: PageRoutesNames.REPO_PAGE,
        params: {
          workspaceId: route.params.workspaceId,
          repoId: route.params.repoId,
          branch: targetBranch,
        },
      });
      window.open(targetBranchRepoRouteData.href, '_blank');
    }
    return true;
  }

  return { validateNewBranch };
}
