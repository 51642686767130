<script setup lang="ts">
import { computed } from 'vue';
import ResourceViews from '@/common/components/organisms/ResourceViews.vue';
import ResourceContributors from '@/modules/doc-contributions/components/ResourceContributors.vue';
import { ContributorDbItemInterface } from '@swimm/shared';

const props = withDefaults(
  defineProps<{
    resourceContributors?: Array<ContributorDbItemInterface>;
    resourceCreator?: string;
    resourceViews?: number;
  }>(),
  {
    resourceViews: -1,
    resourceContributors: () => [],
    resourceCreator: '',
  }
);

const hasContributors = computed(() => props.resourceContributors && props.resourceContributors.length > 0);
const showContributors = computed(() => hasContributors.value);
</script>
<template>
  <div class="info">
    <ResourceContributors v-if="showContributors" :contributors="resourceContributors" />
    <div v-if="!hasContributors && resourceCreator" class="body-L creator" data-testid="author">
      by {{ resourceCreator }}
    </div>
    <ResourceViews v-if="resourceViews >= 0" :views="resourceViews" />
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.info {
  display: flex;
  align-content: center;
  align-items: center;
  margin-top: 24px;

  .creator {
    margin-right: 24px;
  }
}

.info > * {
  margin-right: var(--space-md);
}
</style>
