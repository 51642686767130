<template>
  <div class="centered no-results-container">
    <div class="system-subtitle no-results-title">No results found</div>
    <div class="body-S">Try searching for a different keyword, or modify existing filters.</div>
  </div>
</template>

<style scoped>
.no-results-container .no-results-title {
  font-family: var(--fontfamily-secondary);
  font-weight: 600;
}

.search-results-container {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -1.66%;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
