<template>
  <BaseLayoutGap direction="column" alignment="left" size="small">
    <BaseHeading :level="1">OAuth connection</BaseHeading>

    <BaseProse>
      Create an OAuth application to authorize Swimm for your organization. Note that you will need admin permission on
      your GitHub organization to do so. Follow instructions
      <BaseAnchor
        href="https://docs.swimm.io/create-github-application-for-swimm/#create-github-oauth-app"
        target="_blank"
        >here.</BaseAnchor
      >
    </BaseProse>

    <Ribbon
      v-if="!isPendingManualSetup && !isEditMode"
      ribbon-description="An existing credentials has been saved. Updating will remove the previous one."
      mode="web"
      class="info"
    >
    </Ribbon>

    <BaseInput
      v-model="enterpriseUrl"
      type="text"
      label="Enterprise Server URL"
      :placeholder="!isPendingManualSetup && !isEditMode ? 'https://*****' : 'https://github.acme.com '"
      :invalid="enterpriseUrl.length > 0 && !isEnterpriseUrlValid"
      invalid-message="Please enter a valid URL"
      style="width: 50%"
      :disabled="!isPendingManualSetup && !isEditMode"
      required
    />
    <BaseInput
      v-model="client_id"
      label="Client ID"
      :invalid="client_id.length > 0 && !isValidClientId"
      invalid-message="Please enter a valid Client ID."
      :placeholder="!isPendingManualSetup && !isEditMode ? '*****' : 'Enter Client ID'"
      style="width: 50%"
      :disabled="!isPendingManualSetup && !isEditMode"
      required
    />
    <BaseInput
      v-model="client_secret"
      type="text"
      label="Client Secret"
      :placeholder="!isPendingManualSetup && !isEditMode ? '*****' : 'Enter Client  Secret'"
      :invalid="client_secret.length > 0 && !isValidClientSecret"
      invalid-message="Please enter a valid Client Secret."
      style="width: 50%"
      :disabled="!isPendingManualSetup && !isEditMode"
      required
    />

    <BaseHeading :level="3">Restricted local access</BaseHeading>

    <BaseProse>
      If you have a VPN or Firewall, whitelist Swimm’s IP address or create local OAuth server for Swimm to communicate
      with your Git hosting. Follow instructions
      <BaseAnchor
        href="https://docs.swimm.io/swimm-oauth-local-server/#1-whitelist-our-static-ip-address"
        target="_blank"
        >here.</BaseAnchor
      >
    </BaseProse>

    <BaseLabel no-padding> Swimm IP address </BaseLabel>
    <BaseCode :theme="codeBaseTheme" no-padding :code="'34.71.219.171'" />

    <BaseInput
      v-model="localOAuthServerUrl"
      type="text"
      label="Local OAuth server URL"
      :placeholder="!isPendingManualSetup && !isEditMode ? '*****' : 'https://acme.com'"
      :invalid="localOAuthServerUrl.length > 0 && !isLocalOAuthServerUrlValid"
      invalid-message="Please enter a valid URL."
      style="width: 50%"
      :disabled="!isPendingManualSetup && !isEditMode"
    />

    <BaseLayoutGap>
      <BaseButton v-if="isPendingManualSetup || isEditMode" @click="save" :loading="loading" :disabled="!isFormValid"
        >Save</BaseButton
      >
      <BaseButton v-else @click="switchToEditMode" variant="secondary">Update OAuth credentials</BaseButton>
    </BaseLayoutGap>

    <BaseProse>
      If you have trouble setting up
      <BaseAnchor href="mailto:info@swimm.io" target="_blank">contact support.</BaseAnchor>
    </BaseProse>
  </BaseLayoutGap>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import * as swimm from '@swimm/backend-client';
import { SWIMM_BACKEND_CLOUD_RUN_URL } from '@/config';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { BaseAnchor, BaseButton, BaseCode, BaseInput, BaseLayoutGap, BaseProse, getTheme } from '@swimm/reefui';
import { getLoggerNew } from '@swimm/shared';
import { useRoute } from 'vue-router';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useWorkspaceSettingsModalStore } from './store/workspace-settings';
import { BaseLabel } from '@swimm/ui';
import type { Theme } from 'shiki';
import { useNotificationsStore } from '@swimm/editor';
import Ribbon from '@/common/components/atoms/Ribbon.vue';

const logger = getLoggerNew(__modulename);

const config = new swimm.Configuration({
  basePath: SWIMM_BACKEND_CLOUD_RUN_URL,
  accessToken: () => firebase.auth().currentUser.getIdToken(),
});

const swimmApi = new swimm.SwimmApi(config);

const { fetchReposConfigData } = useGitAuthorizationStore();
const { fetchWorkspace, isPendingManualSetup } = useWorkspaceStore();
const { closeWorkspaceSettingsModal } = useWorkspaceSettingsModalStore();
const { addNotification } = useNotificationsStore();

const codeBaseTheme = ref<Theme>(getTheme() === 'dark' ? 'github-dark' : 'github-light');

const isEditMode = ref(false);
const enterpriseUrl = ref('');
const localOAuthServerUrl = ref('');
const client_secret = ref('');
const client_id = ref('');

watch(isEditMode, (newValue) => {
  logger.info(`isEditMode:${newValue}, isPendingManualSetup:${isPendingManualSetup}`);
});

const route = useRoute();

const loading = ref(false);

const isValidClientId = computed((): boolean => {
  const pattern = /^[a-f0-9]{20}$/;
  return pattern.test(client_id.value);
});

const isValidClientSecret = computed((): boolean => {
  const pattern = /^[a-f0-9]{40}$/;
  return pattern.test(client_secret.value);
});

const isValidUrl = (url): boolean => {
  const pattern = /^(http|https):\/\/[^ "]+\.[a-z]{2,}$/;
  return pattern.test(url);
};

const isEnterpriseUrlValid = computed((): boolean => {
  return isValidUrl(enterpriseUrl.value);
});

const isLocalOAuthServerUrlValid = computed((): boolean => {
  return localOAuthServerUrl.value === '' || isValidUrl(localOAuthServerUrl.value);
});

const isFormValid = computed((): boolean => {
  return (
    isValidClientId.value && isValidClientSecret.value && isEnterpriseUrlValid.value && isLocalOAuthServerUrlValid.value
  );
});

const switchToEditMode = () => {
  isEditMode.value = true;
};

const save = async () => {
  loading.value = true;
  try {
    const workspaceId = route.params.workspaceId as string;
    const body: swimm.AddEnterpriseRequest = {
      workspaceId: workspaceId,
      enterpriseUrl: enterpriseUrl.value,
      client_secret: client_secret.value,
      client_id: client_id.value,
    };
    if (localOAuthServerUrl.value !== '') {
      body.localAuthServer = localOAuthServerUrl.value;
    }
    await swimmApi.addEnterprise(body);

    void fetchReposConfigData();
    void fetchWorkspace();
    void closeWorkspaceSettingsModal();
    void addNotification(`OAuth connections settings saved successfully`, {
      icon: 'check-fill',
    });
  } catch (err) {
    logger.error({ err }, 'Failed to save and authorize enterprise');
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.info {
  width: 95%;
}
</style>
