import { defineStore } from 'pinia';
import type {
  AutosyncOutput,
  AutosyncedStoreData,
  SmartElementWithApplicability,
  SmartSymbol,
  Snippet,
} from '@swimm/shared';
import { isSmartElementWithNewInfo } from '@swimm/shared';
import { ref } from 'vue';

export const useEditorAutosyncData = defineStore('editorAutosyncData', () => {
  const autosyncedStoredData = ref<AutosyncedStoreData>({ hunks: {}, symbols: {} });

  function populateAutosyncedData(autosyncedData: AutosyncOutput) {
    populateSnippets(autosyncedData.snippets);
    populateSymbols(autosyncedData.symbols);
  }

  function resetState() {
    autosyncedStoredData.value.hunks = {};
    autosyncedStoredData.value.symbols = {};
  }

  function populateSnippets(snippets: SmartElementWithApplicability<Snippet>[]) {
    for (const snippet of snippets) {
      const id = snippet.id;
      autosyncedStoredData.value.hunks[id] = snippet;
    }
  }

  function populateSymbols(symbols: SmartElementWithApplicability<SmartSymbol>[]) {
    for (const symbol of symbols) {
      autosyncedStoredData.value.symbols[symbol.id] = symbol;
    }
  }

  function addNewSymbol(symbol: SmartElementWithApplicability<SmartSymbol>) {
    autosyncedStoredData.value.symbols[symbol.id] = symbol;
  }

  function getSymbolById(id: string) {
    return autosyncedStoredData.value.symbols[id];
  }

  function addNewSnippet(snippet: SmartElementWithApplicability<Snippet>) {
    const newSnippet = { ...snippet, newInfo: { ...snippet } };
    autosyncedStoredData.value.hunks[snippet.id] = newSnippet;
  }

  function getAutosyncedSnippetData(id: string) {
    return autosyncedStoredData.value.hunks[id];
  }

  function getAutosyncedSymbol(id: string) {
    return autosyncedStoredData.value.symbols[id];
  }
  function getAutosyncedSymbolNewInfo(id: string) {
    const symbol = getAutosyncedSymbol(id);
    if (symbol && isSmartElementWithNewInfo(symbol)) {
      return symbol.newInfo;
    } else {
      return null;
    }
  }

  return {
    getAutosyncedSymbolNewInfo,
    autosyncedStoredData,
    addNewSnippet,
    getAutosyncedSymbol,
    resetState,
    addNewSymbol,
    getAutosyncedSnippetData,
    getSymbolById,
    populateAutosyncedData,
  };
});
