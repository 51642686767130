/**
 * Migration utility to convert a parsed git-diff object from git-diff-parser parsed object to `parse-diff` object
 * we used a format similar to the one of https://github.com/sergeyt/parse-diff
 * @param originalDiffString the original git diff string
 */
import { parseGitHunkPatch, parseGitPatchFile } from './git-diff-parser/git-diff-parser';
import type { GitHunk, GitLine } from './git-diff-parser/git-diff-parser-types';
import { GitLineTypes } from './git-diff-parser/git-diff-parser-types';
import { HunkChangeLineType } from './types/swimm-patch-common';
import type ParseDiff from './diff-parser-migration-types';

export function parseAndMigratePatch(originalDiffString: string): ParseDiff.File {
  const parsedFile = parseGitPatchFile(originalDiffString);
  return {
    from: parsedFile.fileNameA,
    to: parsedFile.fileNameB,
    chunks: parsedFile.hunks.map((hunk) => migrateHunk(hunk)),
    diffType: parsedFile.patchType,
    additions: parsedFile.linesAdded,
    deletions: parsedFile.linesDeleted,
    index: parsedFile.index, // This property is ignored later
  };
}

/**
 * @attention - callers of this function should make sure their hunk diff is valid, this method is not responsible for validations
 * @param gitHunkPatchString - a VALID git hunk diff string
 */
export function parseAndMigrateSingleHunk(gitHunkPatchString: string) {
  const parsedHunk = parseGitHunkPatch(gitHunkPatchString);
  return migrateHunk(parsedHunk);
}

export function migrateHunk(hunk: GitHunk): ParseDiff.Chunk {
  return {
    content: hunk.header,
    changes: migrateHunkLines(hunk.lines),
    oldStart: hunk.startLineA,
    oldLines: hunk.linesCountA,
    newStart: hunk.startLineB,
    newLines: hunk.linesCountB,
  };
}
function migrateHunkLines(lines: Array<GitLine>) {
  const changes: ParseDiff.TweakedParseDiffChange[] = [];
  for (const [index, line] of lines.entries()) {
    let change: ParseDiff.TweakedParseDiffChange = { type: line.type, content: line.data };

    switch (change.type) {
      case GitLineTypes.Context: {
        change.type = HunkChangeLineType.NormalAsContext;
        change.ln1 = line.lineNumberInA;
        change.ln2 = line.lineNumberInB;
        break;
      }
      case GitLineTypes.Addition: {
        change.type = HunkChangeLineType.Added;
        change.ln = line.lineNumberInB;
        break;
      }
      case GitLineTypes.Deletion: {
        change.type = HunkChangeLineType.Deleted;
        change.ln = line.lineNumberInA;
        break;
      }
      case GitLineTypes.EOF: {
        let ln;
        if (typeof line.lineNumberInA !== 'undefined') {
          ln = line.lineNumberInA;
        } else if (typeof line.lineNumberInB !== 'undefined') {
          ln = line.lineNumberInB;
        }
        change = { ...change, ln1: undefined, ln2: undefined, type: changes[index - 1].type, ln: ln };
        break;
      }
    }
    change[change.type] = true;
    changes.push(change);
  }
  return changes;
}
