import { type SwmCell, SwmCellType } from '@swimm/shared';
import { markedFormattedValue } from '@/components/EditorComponents/markdown/markdown-to-html';
import { generateJSON } from '@tiptap/html';
import type { Extensions, JSONContent } from '@tiptap/vue-3';

export function useSwmContentToTiptap(repoId: string) {
  return function tiptapContentFromSwmContent(swmContent: SwmCell[], tiptapExtensions: Extensions): JSONContent {
    const tiptapContent = [];

    for (const cell of swmContent) {
      if (cell.type === SwmCellType.Text) {
        // Markdown to HTML
        const html = markedFormattedValue(cell.text, repoId);
        // HTML To TiptapJson
        const json = generateJSON(html, tiptapExtensions);
        tiptapContent.push(...json.content);
      } else {
        throw new Error(`type ${cell.type} not supported!`);
      }
    }
    return { type: 'doc', content: tiptapContent };
  };
}
