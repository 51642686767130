import { parseAndMigratePatch } from './diff-parser-migration';
import type {
  DynamicChangeLine,
  DynamicGitFileMetadata,
  DynamicGitHunkMetadata,
  DynamicHunkContainer,
  Snippet,
} from './types';
import {
  ADD_MARKER,
  CONTEXT_MARKER,
  DELETE_MARKER,
  FileDiffType,
  HunkChangeLineType,
} from './types/swimm-patch-common';
import type ParseDiff from './diff-parser-migration-types';
import * as objectUtils from './objectUtils';
import type { Change } from 'diff';
import { diffTrimmedLines } from 'diff';
import { getLogger } from './logger/legacy-shim';

const logger = getLogger("packages/shared/src/diff-parser-utils.ts");

// Returns the a/b file versions of the git diff
function resolveVersionedFilesNames(
  fileStatus: FileDiffType,
  fileFrom: string,
  fileTo: string
): { fileA: string; fileB: string } {
  switch (fileStatus) {
    case FileDiffType.Added: {
      // Added file contains only file "B" (to) in git diff.
      return { fileA: fileTo, fileB: fileTo };
    }
    case FileDiffType.Deleted: {
      // Deleted file contains only file "A" (from) in git diff.
      return { fileA: fileFrom, fileB: fileFrom };
    }
    default: {
      // Modified file contains both file "A" (from) and "B"(to) in git diff
      return { fileA: fileFrom, fileB: fileTo };
    }
  }
}

function determineFileChangeType(file): FileDiffType {
  if (file.diffType) {
    return file.diffType;
  }
  // Backward compatibility for patches generated with `parse-diff`
  let type = FileDiffType.Modified;
  // eslint-disable-next-line no-prototype-builtins
  if (file.hasOwnProperty('new')) {
    type = FileDiffType.Added;
    // eslint-disable-next-line no-prototype-builtins
  } else if (file.hasOwnProperty('deleted')) {
    type = FileDiffType.Deleted;
  } else if (file.from !== file.to) {
    type = FileDiffType.Renamed;
  }
  return type;
}

// Creates a hunk line change in Swimm hunks format
export function generateNewSwimmHunkLineChange(change): DynamicChangeLine {
  const changesTypes = {
    [HunkChangeLineType.NormalAsContext]: (): DynamicChangeLine => {
      // This change refers to a context line
      return {
        changeType: HunkChangeLineType.Context,
        fileA: {
          actualLineNumber: change.ln1,
          data: change.content.substring(1),
        },
        fileB: {
          actualLineNumber: change.ln2,
          data: change.content.substring(1),
        },
      };
    },
    [HunkChangeLineType.Deleted]: (): DynamicChangeLine => {
      // The change is a "deletion" change - line is only in a (+) but not in b (-)
      return {
        changeType: HunkChangeLineType.Deleted,
        fileA: {
          actualLineNumber: change.ln,
          data: change.content.substring(1), // We use `substring` to remove the mark from the beginning
        },
      };
    },
    [HunkChangeLineType.Added]: (): DynamicChangeLine => {
      // The change is an "add" change - line is only in b (+) but not in a (-)
      return {
        changeType: HunkChangeLineType.Added,
        fileB: {
          actualLineNumber: change.ln,
          data: change.content.substring(1), // We use `substring` to remove the mark from the beginning
        },
      };
    },
  };
  return changesTypes[change.type]();
}

// Determines if a parsed changeLine from `parse-diff` lib is an EOF linechange
const isEOFLineChange = (lineChange) =>
  ['No newline at end of file', '\\ No newline at end of file'].includes(lineChange.content.trim());

export function createRawParsedDiffFilePairings(rawDiff: string): { raw: string; parsed: ParseDiff.File }[] {
  // TODO: Document this regex and make sure to update it with a strict one (instead of .*)
  const rawDiffFiles = splitByRegex(rawDiff, '^diff --git .*\n');
  return rawDiffFiles.map((rawFileDiff) => {
    return { raw: rawFileDiff, parsed: parseFileGitDiff(rawFileDiff) };
  });
}

/**
 * Parse a git patch of a file into a JS Object and convert it to parse-diff structure
 * when this file was created we used a format similar to the one of https://github.com/sergeyt/parse-diff
 * @param gitDiffString - a valid git diff (patch) string
 */
export function parseFileGitDiff(gitDiffString: string): ParseDiff.File {
  try {
    return parseAndMigratePatch(gitDiffString);
  } catch (error) {
    logger.error(`Parsing diff string failed. Details: ${error.toString()}`, { service: 'diff-parser' });
    throw error;
  }
}

function extractRawFileDiffHeader(rawFileDiff: string) {
  return rawFileDiff.slice(0, rawFileDiff.indexOf('@@ ')).trim();
}

/**
 * Find all of the extended headers (both the Index Header and others) and fill the gitFileMetadata object with them.
 * @param {*} gitFileMetadata The ref to `gitFileMetadata` object we want to fill with the data.
 * @param {*} diffHeaderLines The ref to diff header split into lines.
 */
function extractDiffExtendedHeaders(gitFileMetadata: DynamicGitFileMetadata, diffHeaderLines: string[]) {
  // The first line is always the full `diff` command comparison while the last two lines are always the markers, so we slice them out.
  diffHeaderLines = diffHeaderLines.slice(1, diffHeaderLines.length - 2);

  // Now we'll look for the "index" header because it holds a special meaning to us (stores info about blobs to be exact).
  // We assume that every header after the "index" header is a part of the index and so we'll splice them out as well.
  const indexLineRegex = /index .*\.\..*/;
  for (const [index, line] of diffHeaderLines.entries()) {
    if (indexLineRegex.test(line)) {
      gitFileMetadata.indexLines = diffHeaderLines.splice(index, diffHeaderLines.length - index);
      break;
    }
  }

  // If any headers were left after this process, they're our "extra headers" (for a full list of those please refer to git's docs).
  if (diffHeaderLines.length > 0) {
    gitFileMetadata.extendedHeaders = diffHeaderLines;
  }
}

export function extractGitFileMetadata(rawFileDiff: string, parsedFileDiff: ParseDiff.File, blobSha?: string) {
  // Use what we can from the parse-diff git metadata fields:
  const diffType = determineFileChangeType(parsedFileDiff);
  const resolvedFilenames = resolveVersionedFilesNames(diffType, parsedFileDiff.from, parsedFileDiff.to);
  const gitFileMetadata: DynamicGitFileMetadata = {
    blob_sha: blobSha ? blobSha : '',
    diffType: diffType,
    fileNameA: resolvedFilenames.fileA,
    fileNameB: resolvedFilenames.fileB,
    numLineDeletions: parsedFileDiff.deletions,
    numLineAdditions: parsedFileDiff.additions,
  };

  // Now let's start to parse the raw file diff by ourselves:
  gitFileMetadata.originalFileDiffHeader = extractRawFileDiffHeader(rawFileDiff);
  const diffHeaderLines = gitFileMetadata.originalFileDiffHeader.split('\n');

  // Since we'll want to iterate over all of the leftover lines after this operation, we have to splice them out.
  gitFileMetadata.comparedFiles = diffHeaderLines[0]; // Always the first line in the header.
  gitFileMetadata.markers = diffHeaderLines.slice(-2); // Always the last two lines in the header.

  extractDiffExtendedHeaders(gitFileMetadata, diffHeaderLines);

  return gitFileMetadata;
}

/**
 * Return the fixed file markers as they were originally in the diff string.
 * Since we're changing every `/dev/null` value during our parsing of raw diffStrings,
 *  we need to the the reverse operation before the patch could be applied.
 * @param {*} gitFileMetadata The base gitFileMEtadata object the reconstruction is applied to.
 */
function getFixedMarkersForReconstruction(gitFileMetadata: DynamicGitFileMetadata) {
  let deletedFileMarker = gitFileMetadata.markers[0];
  let addedFileMarker = gitFileMetadata.markers[1];
  if (gitFileMetadata.diffType === FileDiffType.Deleted) {
    addedFileMarker = `${ADD_MARKER.repeat(3)} /dev/null`;
  } else if (gitFileMetadata.diffType === FileDiffType.Added) {
    deletedFileMarker = `${DELETE_MARKER.repeat(3)} /dev/null`;
  }
  return [deletedFileMarker, addedFileMarker];
}

/**
 * Reconstruct the file's diff header from a gitFileMetadata object.
 * @param {*} gitFileMetadata The base gitFileMetadata object to reconstruct from.
 */
export function dynamicFileHeader2staticFileHeader(gitFileMetadata: DynamicGitFileMetadata) {
  const diffHeaderLines = [];
  diffHeaderLines.push(gitFileMetadata.comparedFiles);
  if (gitFileMetadata.extendedHeaders) {
    diffHeaderLines.push(...gitFileMetadata.extendedHeaders);
  }
  diffHeaderLines.push(...gitFileMetadata.indexLines);
  diffHeaderLines.push(...getFixedMarkersForReconstruction(gitFileMetadata));
  return diffHeaderLines.join('\n');
}

export function splitByRegex(str: string, regex: string) {
  const indexArray: number[] = [];
  for (const match of str.matchAll(RegExp(regex, 'gm'))) {
    indexArray.push(match.index);
  }
  return indexArray.map((stringIndex, arrayIndex) => {
    const nextStringIndex = indexArray[arrayIndex + 1];
    const endStringIndex = typeof nextStringIndex === 'undefined' ? str.length : nextStringIndex - 1;
    return str.slice(stringIndex, endStringIndex);
  });
}

export function createRawParsedHunksPairings(rawFileDiff: string, parsedFileDiff: ParseDiff.File) {
  // TODO: Document this regex and make sure to update it with the strict version of it from our git strings parser
  const rawHunks = splitByRegex(rawFileDiff, '(?:^|\n)@@ .* @@.*\n');
  const parsedHunks = parsedFileDiff.chunks;
  return rawHunks.map((rawHunk, index) => {
    return { raw: rawHunk, parsed: parsedHunks[index] };
  });
}

function createGitHunkMetadata(rawHunk: string, parsedHunk: ParseDiff.Chunk) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const gitHunkMetadata: any = {};
  gitHunkMetadata.header = parsedHunk.content;
  gitHunkMetadata.lineNumbers = {
    fileA: {
      startLine: parsedHunk.oldStart,
      linesCount: parsedHunk.oldLines,
    },
    fileB: {
      startLine: parsedHunk.newStart,
      linesCount: parsedHunk.newLines,
    },
  };
  return gitHunkMetadata;
}

export function createDynamicHunkContainer(rawHunk: string, parsedHunk: ParseDiff.Chunk, swimmHunkMetadata = {}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dynamicHunkContainer: any = {};
  dynamicHunkContainer.gitHunkMetadata = createGitHunkMetadata(rawHunk, parsedHunk);
  dynamicHunkContainer.changes = [];
  parsedHunk.changes.forEach((currentChange) => {
    // Save changes only if not EOF changes
    if (!isEOFLineChange(currentChange)) {
      dynamicHunkContainer.changes.push(generateNewSwimmHunkLineChange(currentChange));
    }
  });
  // @ts-ignore
  const comments = Array.isArray(swimmHunkMetadata.hunkComments) ? swimmHunkMetadata.hunkComments : [];
  dynamicHunkContainer.swimmHunkMetadata = objectUtils.deepClone({
    ...swimmHunkMetadata,
    hunkComments: comments,
  });
  return dynamicHunkContainer;
}

export function dynamicHunk2gitDiffHunkHeader(dynamicHunkContainerGitMetadata: DynamicGitHunkMetadata) {
  const fileA = dynamicHunkContainerGitMetadata.lineNumbers.fileA;
  const fileB = dynamicHunkContainerGitMetadata.lineNumbers.fileB;
  let header = `@@ ${DELETE_MARKER}${fileA.startLine},`;
  header += fileA.linesCount === null ? '1' : `${fileA.linesCount}`;
  header += ` ${ADD_MARKER}${fileB.startLine},`;
  header += fileB.linesCount === null ? '1' : `${fileB.linesCount}`;
  header += ' @@';
  return header;
}

function dynamicChange2gitDiffChangeLine(dynamicChangeLine: DynamicChangeLine): string {
  switch (dynamicChangeLine.changeType) {
    case HunkChangeLineType.Context: {
      return ` ${dynamicChangeLine.fileA.data}`;
    }
    case HunkChangeLineType.Deleted: {
      return `${DELETE_MARKER}${dynamicChangeLine.fileA.data}`;
    }
    case HunkChangeLineType.Added: {
      return `${ADD_MARKER}${dynamicChangeLine.fileB.data}`;
    }
    case HunkChangeLineType.Update: {
      return `${DELETE_MARKER}${dynamicChangeLine.fileA.data}\n${ADD_MARKER}${dynamicChangeLine.fileB.data}`;
    }
    default: {
      return '';
    }
  }
}

/**
 *
 * @param dynamicHunkContainer - a hunk container in dynamic format.
 * @return hunkDiffString - stringed hunk diff changes in git diff format
 * @NOTICE - update lines will be returned as line-by-line changes (del,add) and if there are multiple updates in a row, instead of mayers (del,del,del,add,add,add), the reconstruction will return instead (del,add,del,add,del,add)
 */
export function dynamicHunk2gitDiffHunk(dynamicHunkContainer: DynamicHunkContainer) {
  const hunkHeader = dynamicHunk2gitDiffHunkHeader(dynamicHunkContainer.gitHunkMetadata);
  let diffChangeLines = '';
  dynamicHunkContainer.changes.forEach((dynamicChange) => {
    diffChangeLines += `\n` + dynamicChange2gitDiffChangeLine(dynamicChange);
  });
  return hunkHeader + diffChangeLines;
}

/**
 * Search backwards in the `changes` array, starting from `startIndex`, to the first line whose `changeType` is `context`.
 * If there is no previous context line, return `-1`.
 * @param {changeLine array} changes a dynamic hunk's changes array
 * @param {number} startIndex an index to start the backwards search from
 */
function searchForPreviousContextLine(changes: DynamicChangeLine[], startIndex: number): number {
  if (startIndex === 0 && changes[0].changeType === HunkChangeLineType.Context) {
    return 0;
  }

  let changeIndex = startIndex;
  for (let i = 0; i < 5; i++) {
    if (changes[changeIndex].changeType === HunkChangeLineType.Context) {
      return changeIndex;
    }
    changeIndex--;
  }
  return -1;
}

export function findSectionsEdgesInFileA(changes: DynamicChangeLine[]) {
  const meatEdgeChangeIndex = changes.findIndex((changeLine) => ['update', 'del'].includes(changeLine.changeType));
  if (meatEdgeChangeIndex === -1) {
    throw new Error('We do not support hunks with additions only.');
  }
  const meatEdge = changes[meatEdgeChangeIndex];
  const meatEdgeLineNumber = meatEdge.fileA.actualLineNumber;
  let contextEdgeLineNumber = null;
  let contextEdgeChangeIndex = null;
  let contextMiddleLineNumber = null;
  let contextMiddleChangeIndex = null;
  if (changes[0].changeType === HunkChangeLineType.Context) {
    contextEdgeLineNumber = changes[0].fileA.actualLineNumber;
    contextEdgeChangeIndex = 0;
    contextMiddleChangeIndex = searchForPreviousContextLine(changes, meatEdgeChangeIndex - 1); // Search backwards for the first context line
    contextMiddleLineNumber = changes[contextMiddleChangeIndex].fileA.actualLineNumber;
  }
  return {
    meat: {
      meatEdgeLineNumber,
      meatEdgeChangeIndex,
    },
    context: {
      contextEdgeLineNumber,
      contextEdgeChangeIndex,
      contextMiddleLineNumber,
      contextMiddleChangeIndex,
    },
  };
}

function isContainsPathInRepo(pathsByRepo, repo, path, branch): boolean {
  return !!pathsByRepo.find((match) => match.filePath === path && match.repoId === repo && match.branch === branch);
}
interface RepoIdWithFilePath {
  repoId: string;
  filePath: string;
  branch: string;
}
export function getSwmFileSnippetCellsPathsByRepo(snippets: Snippet[]): RepoIdWithFilePath[] {
  const pathsByRepo: RepoIdWithFilePath[] = [];
  for (const snippet of snippets) {
    if (!isContainsPathInRepo(pathsByRepo, snippet.gitInfo.repoId, snippet.filePath, snippet.gitInfo.branch)) {
      pathsByRepo.push({ repoId: snippet.gitInfo.repoId, filePath: snippet.filePath, branch: snippet.gitInfo.branch });
    }
  }
  return pathsByRepo;
}

export function generateDiffArrayFromTwoFiles({
  fileContentA,
  fileContentB,
}: {
  fileContentA: string;
  fileContentB: string;
}): Change[] {
  // @ts-ignore // NOTE: the exported types weren't updated - maxEditLength exists starting with version 5.1.0.
  const diff = diffTrimmedLines(fileContentA, fileContentB, { maxEditLength: 3000 });
  // @ts-ignore // for some reason it thinks the type is `never`, should be Change[] or undefined
  if (!diff || diff.length === 0) {
    throw new Error('jsDiff returned empty diff array, possible diff exceeded maxEditLength');
  }
  return diff;
}

interface changeCallBackType {
  ({ type, content, ln, ln2 }: { type: HunkChangeLineType; content: string; ln: number; ln2?: number }): void;
}

/**
 * Goes over the diff aray returned from jsDiff diffTrimmedLines function
 * @param fileContentA
 * @param fileContentB
 * @param jsDiff - formatted as an array of changes.
 *                 if there is a sequence of identical changes they are unified into one array cell
 * @param changeCallBack - the action to takes place when a specific change is reached
 */
function goOverJsDiff({
  fileContentA,
  fileContentB,
  jsDiff,
  changeCallBack,
}: {
  fileContentA: string;
  fileContentB: string;
  jsDiff: Change[];
  changeCallBack: changeCallBackType;
}) {
  let fileALineCounter = 0;
  let fileBLineCounter = 0;
  const fileAArr = fileContentA.split('\n');
  const fileBArr = fileContentB.split('\n');
  for (const change of jsDiff) {
    if (!change.added && !change.removed) {
      // Context
      for (let i = 0; i < change.count; i++) {
        changeCallBack({
          type: HunkChangeLineType.NormalAsContext,
          content: CONTEXT_MARKER + fileBArr[fileBLineCounter],
          ln: fileALineCounter + 1,
          ln2: fileBLineCounter + 1,
        });
        fileALineCounter++;
        fileBLineCounter++;
      }
    } else if (change.removed) {
      // Del
      for (let i = 0; i < change.count; i++) {
        changeCallBack({
          type: HunkChangeLineType.Deleted,
          content: DELETE_MARKER + fileAArr[fileALineCounter],
          ln: fileALineCounter + 1,
        });
        fileALineCounter++;
      }
    } else {
      // Add
      for (let i = 0; i < change.count; i++) {
        changeCallBack({
          type: HunkChangeLineType.Added,
          content: ADD_MARKER + fileBArr[fileBLineCounter],
          ln: fileBLineCounter + 1,
        });
        fileBLineCounter++;
      }
    }
  }
}

function convertJsDiffToDynamicHunkContainer({
  fileContentA,
  fileContentB,
  jsDiff,
  repoId,
}: {
  fileContentA: string;
  fileContentB: string;
  jsDiff: Change[];
  repoId: string;
}): DynamicHunkContainer {
  const dynamicHunkContainer: DynamicHunkContainer = {
    changes: [],
    repoId,
    swimmHunkMetadata: {},
    gitHunkMetadata: {
      lineNumbers: {
        fileA: {
          linesCount: fileContentA.split('\n').length - 1,
          startLine: 1,
        },
        fileB: {
          linesCount: fileContentB.split('\n').length - 1,
          startLine: 1,
        },
      },
    },
  };

  /**
   * Converts a change line to be in dynamic container format
   * @param type - del/ add/ context
   * @param content - the content of the line including the marker
   * @param ln - the actual line number in the relevant file
   * @param ln2 - if it's context, the actual line number in the other file
   */
  const callback = ({
    type,
    content,
    ln,
    ln2,
  }: {
    type: HunkChangeLineType;
    content: string;
    ln: number;
    ln2?: number;
  }) => {
    const change = {
      type,
      content,
    };
    if (ln2) {
      change['ln1'] = ln;
      change['ln2'] = ln2;
    } else {
      change['ln'] = ln;
    }
    dynamicHunkContainer.changes.push(generateNewSwimmHunkLineChange(change));
  };
  goOverJsDiff({ fileContentA, fileContentB, jsDiff, changeCallBack: callback });

  return dynamicHunkContainer;
}

export function convertJsDiffToDiffString({
  fileContentA,
  fileContentB,
  jsDiff,
}: {
  fileContentA: string;
  fileContentB: string;
  jsDiff: Change[];
}): string[] {
  const changes: string[] = [];

  const callback = ({ content }: { content: string }) => {
    changes.push(content);
  };
  goOverJsDiff({ fileContentA, fileContentB, jsDiff, changeCallBack: callback });

  return changes;
}

export function getDiffAsDynamicHunkContainer({
  fileContentA,
  fileContentB,
  repoId,
}: {
  fileContentA: string;
  fileContentB: string;
  repoId: string;
}): { dynamicHunk: DynamicHunkContainer; diffType: FileDiffType } {
  const jsDiff = generateDiffArrayFromTwoFiles({
    fileContentA,
    fileContentB,
  });

  const dynamicHunk = convertJsDiffToDynamicHunkContainer({
    fileContentA,
    fileContentB,
    jsDiff,
    repoId,
  });

  const isFileAdded = jsDiff.length === 1 && jsDiff[0].added;
  const isFileDeleted = jsDiff.length === 1 && jsDiff[0].removed;
  const diffType = isFileAdded ? FileDiffType.Added : isFileDeleted ? FileDiffType.Deleted : FileDiffType.Modified;

  return { dynamicHunk, diffType };
}

export function getDiffAsDiffString({
  fileContentA,
  fileContentB,
}: {
  fileContentA: string;
  fileContentB: string;
}): string[] {
  const jsDiff = generateDiffArrayFromTwoFiles({
    fileContentA: fileContentA,
    fileContentB: fileContentB,
  });
  return convertJsDiffToDiffString({
    fileContentA: fileContentA,
    fileContentB: fileContentB,
    jsDiff,
  });
}

/**
 * Return a new Dynamic Hunk where FileB starts with a new line number.
 * NOTE: this function starts changing line numbers starting with the first one, be it Context or Change.
 * @param dynamicHunk dynamic hunk to be based on
 * @param newFirstMeatLineNumber the first line number of FileB
 * @returns a changed dynamic hunk
 */
export function changeDynamicHunkContanierStartingLineNumber(
  dynamicHunk: DynamicHunkContainer,
  newFirstMeatLineNumber: number
): DynamicHunkContainer {
  const clonedDynamicHunk = objectUtils.deepClone(dynamicHunk);
  let currentNewLine = newFirstMeatLineNumber;
  for (const line of clonedDynamicHunk.changes) {
    if (!line.fileB) {
      continue;
    }
    line.fileB.actualLineNumber = currentNewLine;
    currentNewLine += 1;
  }
  return clonedDynamicHunk;
}
