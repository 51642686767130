import { getLoggerNew, gitwrapper } from '@swimm/shared';
import { useTimeoutPoll } from '@vueuse/core';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const logger = getLoggerNew(__modulename);

const POLL_INTERVAL = 60000;

export const useLatestCommitStore = defineStore('latestCommit', () => {
  const repoId = ref<string>();
  const branch = ref<string>();
  const latestCommit = ref<string>();

  const { isActive, pause, resume } = useTimeoutPoll(
    async () => {
      try {
        const branchInfo = await gitwrapper.getBranch({ repoId: repoId.value, branchName: branch.value });
        latestCommit.value = branchInfo.sha;
      } catch (e) {
        logger.error(e, 'Failed to get latest commit');
      }
    },
    POLL_INTERVAL,
    { immediate: false }
  );

  function startPollingLatestCommit(newRepoId: string, newBranch: string) {
    repoId.value = newRepoId;
    branch.value = newBranch;
    latestCommit.value = undefined;
    resume();
  }

  function stopPollingLatestCommit() {
    pause();
  }

  return {
    isActive,
    repoId,
    latestCommit,
    startPollingLatestCommit,
    stopPollingLatestCommit,
  };
});
