<script setup lang="ts">
import { type PropType, computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { NodeViewContent, NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { CellSelection, TableMap } from '@tiptap/pm/tables';
import * as _model from '@tiptap/pm/model';
import type { Editor } from '@tiptap/core';

import { BaseButton, BaseButtonBar, BaseIcon, BasePopover } from '@swimm/reefui';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';

const props = defineProps({ ...nodeViewProps, type: { type: String as PropType<'th' | 'td'>, required: true } });

const isInFirstRow = ref(false);
const isInFirstColumn = ref(false);
const map = ref<TableMap>();
const tableSelected = ref(false);

const swimmEditorServices = getSwimmEditorServices(props.editor);
const isEditable = computed(() => swimmEditorServices.editable.value);

function handleUpdate({ editor }: { editor: Editor }) {
  const pos = props.getPos();
  if (pos == null) {
    return;
  }

  const $pos = editor.state.doc.resolve(pos);

  // If this node's parent is the first child of its parent, as in, the first row in the table
  isInFirstRow.value = $pos.node(-1).firstChild === $pos.parent;

  // If this node is the first child of its parent, as in the first child of the row
  isInFirstColumn.value = $pos.parent.firstChild === $pos.nodeAfter;

  map.value = TableMap.get($pos.node(-1));
}

onMounted(() => handleUpdate({ editor: props.editor }));
props.editor.on('update', handleUpdate);
onBeforeUnmount(() => props.editor.off('update', handleUpdate));

function handleSelectionUpdate({ editor }: { editor: Editor }) {
  const pos = props.getPos();
  if (pos == null) {
    return;
  }

  const $pos = editor.state.doc.resolve(pos);
  const tableStart = $pos.start(-1);
  const tableEnd = $pos.end(-1);

  tableSelected.value = tableStart <= editor.state.selection.head && editor.state.selection.head < tableEnd;
}

onMounted(() => handleSelectionUpdate({ editor: props.editor }));

props.editor.on('selectionUpdate', handleSelectionUpdate);
onBeforeUnmount(() => props.editor.off('selectionUpdate', handleSelectionUpdate));

const align = computed(() => {
  if (props.node.attrs.align) {
    return props.node.attrs.align;
  }

  if (props.type === 'th') {
    return 'center';
  }

  return null;
});

function selectColumn() {
  if (props.getPos()) {
    props.editor.commands.command(({ dispatch, tr }) => {
      if (dispatch) {
        tr.setSelection(CellSelection.colSelection(props.editor.state.doc.resolve(props.getPos())));
      }

      return true;
    });
  }
}

function selectRow() {
  if (props.getPos()) {
    props.editor.commands.command(({ dispatch, tr }) => {
      if (dispatch) {
        tr.setSelection(CellSelection.rowSelection(props.editor.state.doc.resolve(props.getPos())));
      }

      return true;
    });
  }
}

function addColumnBefore() {
  props.editor.chain().focus(props.getPos()).addColumnBefore().run();
}

function deleteColumn() {
  props.editor.chain().focus(props.getPos()).deleteColumn().run();
}

function addColumnAfter() {
  props.editor.chain().focus(props.getPos()).addColumnAfter().run();
}

function alignColumn(align: string) {
  const chain = props.editor.chain().focus(props.getPos());

  CellSelection.colSelection(props.editor.state.doc.resolve(props.getPos())).forEachCell((node, pos) => {
    chain.command(({ dispatch, tr }) => {
      if (dispatch) {
        tr.setNodeAttribute(pos, 'align', props.node.attrs.align !== align ? align : null);
      }

      return true;
    });
  });

  chain.run();
}

function addRowBefore() {
  props.editor.chain().focus(props.getPos()).addRowBefore().run();
}

function deleteRow() {
  props.editor.chain().focus(props.getPos()).deleteRow().run();
}

function addRowAfter() {
  props.editor.chain().focus(props.getPos()).addRowAfter().run();
}
</script>

<template>
  <NodeViewWrapper :as="type" class="table-cell-node-view" :style="{ 'text-align': align }">
    <BasePopover
      v-if="isEditable && tableSelected && isInFirstRow"
      role="menu"
      class="table-cell-node-view__column-menu"
      data-testid="column-menu"
      placement="top"
      @click="selectColumn"
    >
      <div class="table-cell-node-view__handle">
        <BaseIcon name="more" class="table-cell-node-view__handle-icon" />
      </div>

      <template #content>
        <BaseButtonBar>
          <BaseButton
            data-testid="column-menu-add-column-before"
            variant="tertiary"
            size="small"
            @click="addColumnBefore"
          >
            <template #leftIcon>
              <BaseIcon name="insert-left" />
            </template>
          </BaseButton>
          <BaseButton
            variant="tertiary"
            data-testid="column-menu-add-column-after"
            size="small"
            @click="addColumnAfter"
          >
            <template #leftIcon>
              <BaseIcon name="insert-right" />
            </template>
          </BaseButton>
          <BaseButton variant="tertiary" data-testid="column-menu-align-left" size="small" @click="alignColumn('left')">
            <template #leftIcon>
              <BaseIcon name="align-left" />
            </template>
          </BaseButton>
          <BaseButton
            variant="tertiary"
            data-testid="column-menu-align-center"
            size="small"
            @click="alignColumn('center')"
          >
            <template #leftIcon>
              <BaseIcon name="align-center" />
            </template>
          </BaseButton>
          <BaseButton
            variant="tertiary"
            data-testid="column-menu-align-right"
            size="small"
            @click="alignColumn('right')"
          >
            <template #leftIcon>
              <BaseIcon name="align-right" />
            </template>
          </BaseButton>
          <BaseButton
            v-if="map && map.width > 1"
            data-testid="column-menu-delete-column"
            variant="tertiary"
            size="small"
            @click="deleteColumn"
          >
            <template #leftIcon>
              <BaseIcon name="trash-outline" />
            </template>
          </BaseButton>
        </BaseButtonBar>
      </template>
    </BasePopover>
    <BasePopover
      v-if="isEditable && tableSelected && isInFirstColumn"
      role="menu"
      class="table-cell-node-view__row-menu"
      data-testid="row-menu"
      placement="right"
      @click="selectRow"
    >
      <div class="table-cell-node-view__handle">
        <BaseIcon name="more-vertical" class="table-cell-node-view__handle-icon" />
      </div>

      <template #content>
        <BaseButtonBar>
          <BaseButton
            v-if="type !== 'th'"
            data-testid="row-menu-add-row-before"
            variant="tertiary"
            size="small"
            @click="addRowBefore"
          >
            <template #leftIcon>
              <BaseIcon name="insert-up" />
            </template>
          </BaseButton>
          <BaseButton variant="tertiary" data-testid="row-menu-add-row-after" size="small" @click="addRowAfter">
            <template #leftIcon>
              <BaseIcon name="insert-down" />
            </template>
          </BaseButton>
          <BaseButton
            v-if="type !== 'th'"
            data-testid="row-menu-delete-row"
            variant="tertiary"
            size="small"
            @click="deleteRow"
          >
            <template #leftIcon>
              <BaseIcon name="trash-outline" />
            </template>
          </BaseButton>
        </BaseButtonBar>
      </template>
    </BasePopover>
    <NodeViewContent />
  </NodeViewWrapper>
</template>

<style scoped lang="scss">
.table-cell-node-view {
  $self: &;
  word-break: break-word;

  &__row-menu,
  &__column-menu {
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    transition: all 0.25s ease-in-out;
  }

  &__handle {
    background-color: var(--color-bg-default);
    border: 1px solid var(--color-border-default-subtle);
    align-items: center;
    display: flex;
    font-size: var(--font-size-xsmall);
    font-weight: var(--font-weight-regular);
    text-align: center;
    transition: all 0.25s ease-in-out;
    color: var(--text-color-secondary);

    &:hover {
      background-color: var(--color-bg-surface-hover);
      border-color: var(--color-border-default);
      color: var(--color-text-default);
    }
  }

  &__row-menu {
    align-items: stretch;
    display: flex;
    left: calc((var(--space-xsmall) + var(--space-xxsmall) + 2px) * -1);
    top: 50%;
    transform: translateY(-50%);
    width: calc(var(--space-xsmall) + var(--space-xxsmall));

    .table-cell-node-view__handle {
      border-right: 0;
      height: var(--space-lg);
      border-top-left-radius: var(--space-xxsmall);
      border-bottom-left-radius: var(--space-xxsmall);
    }
  }

  &__column-menu {
    top: calc((var(--space-small) + 2px) * -1);
    left: 50%;
    transform: translateX(-50%);

    .table-cell-node-view__handle {
      height: calc(var(--space-small));
      width: var(--space-lg);
      justify-content: center;
      border-bottom: 0;
      border-top-left-radius: var(--space-xxsmall);
      border-top-right-radius: var(--space-xxsmall);
    }
  }
}
</style>
