<script setup lang="ts">
import { Action, SwModal, SwText } from '@swimm/ui';
import { config } from '@swimm/shared';

defineProps({
  isAdmin: { type: Boolean, default: false },
  modalOpened: { type: Boolean, default: false },
  isIDE: { type: Boolean, default: false },
  settingsUrl: { type: String, default: '' },
});

const emit = defineEmits(['close', 'enable']);

const invokeCTA = () => {
  emit('enable');
  emit('close');
};
</script>

<template>
  <SwModal :show-modal="modalOpened" heading="Generative AI is disabled" @close="$emit('close')">
    <div class="content">
      <SwText v-if="!isAdmin" variant="body-L"
        >Generative AI feature is disabled for this repo. Ask an Admin to enable it from the repo settings.
        <a :href="config.SWIMM_DOC_SITE_GENERATIVE_AI" class="external-link" target="_blank">Learn more</a></SwText
      >
      <SwText v-else variant="body-L">
        Generative AI features are disabled for this repo. <br />

        <a :href="config.SWIMM_DOC_SITE_GENERATIVE_AI" class="external-link" target="_blank">Learn more</a>&nbsp;

        <div class="cta-button">
          <a v-if="isIDE && settingsUrl" :href="settingsUrl" target="_blank"
            ><Action primary size="small">Enable in repo settings</Action></a
          >
          <Action v-else primary @click="invokeCTA">Enable in repo settings</Action>
        </div>
      </SwText>
    </div>
  </SwModal>
</template>

<style scoped lang="postcss">
.content {
  color: var(--text-color-primary);
  width: 370px;
  display: flex;
  justify-content: space-between;

  .settings-link {
    color: var(--text-color-link);
    cursor: pointer;
  }

  .external-link {
    color: var(--text-color-secondary);
    text-decoration: underline;
    display: inline-block;

    &:hover {
      color: var(--text-color-link);
    }
  }

  .cta-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
</style>
