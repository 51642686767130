import { Extension } from '@tiptap/core';
import { PluginKey } from '@tiptap/pm/state';
import Suggestion, { type SuggestionOptions } from '@tiptap/suggestion';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';
import { productEvents } from '@swimm/shared';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    slashCommands: {
      openSlashCommandsMenu: () => ReturnType;
    };
  }
}

export interface CommandsOptions {
  suggestion: Omit<SuggestionOptions, 'editor'>;
}

export interface CommandsStorage {
  shown: boolean;
}

export default Extension.create<CommandsOptions, CommandsStorage>({
  name: 'slashCommands',

  addOptions() {
    return {
      suggestion: {
        char: '/',
        command: ({ editor, range, props }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);
          swimmEditorServices.external.trackEvent(productEvents.SLASH_COMMAND_TRIGGERED, {
            'Slash Command Name': props.name,
          });
          editor.commands.command(props.command(range));
        },
      },
    };
  },

  addStorage() {
    return {
      shown: false,
    };
  },

  addCommands() {
    return {
      openSlashCommandsMenu:
        () =>
        ({ commands, state }) => {
          if (state.doc.textBetween(state.selection.from - 1, state.selection.from) !== ' ') {
            return commands.insertContent(' /');
          }

          return commands.insertContent('/');
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        pluginKey: new PluginKey(this.name),
        editor: this.editor,
        ...this.options.suggestion,
        items: async ({ editor, query }) => {
          const filteredItems = (await this.options.suggestion.items?.({ editor, query })) ?? [];
          this.storage.shown = !!filteredItems.length;
          return filteredItems;
        },
        render: () => {
          const originalRender = this.options.suggestion.render?.();
          const originalOnStart = originalRender?.onStart;
          const originalOnExit = originalRender?.onExit;
          const originalOnKeyDown = originalRender?.onKeyDown;

          const newRender = { ...(originalRender ?? {}) };
          const swimmEditorServices = getSwimmEditorServices(this.editor);
          newRender.onStart = (props) => {
            swimmEditorServices.external.trackEvent(productEvents.SLASH_COMMANDS_MENU_TRIGGERED, {});
            this.storage.shown = true;
            originalOnStart?.(props);
          };
          newRender.onExit = (props) => {
            this.storage.shown = false;
            originalOnExit?.(props);
          };
          newRender.onKeyDown = (props) => {
            if (props.event.key === 'Escape') {
              this.storage.shown = false;
            }

            if (originalOnKeyDown) {
              return originalOnKeyDown(props);
            }

            return false;
          };

          return newRender;
        },
      }),
    ];
  },
});
