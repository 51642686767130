<script setup lang="ts">
import type { Editor } from '@tiptap/vue-3';
import { BaseButton, BaseIcon } from '@swimm/reefui';

const props = defineProps<{ editor: Editor }>();

function generateSnippetComment() {
  props.editor.commands.generateSnippetCommentUsingAI();
}
</script>

<template>
  <div>
    <BaseButton
      v-tooltip="'Generate snippet description'"
      size="small"
      variant="tertiary"
      @click="generateSnippetComment"
    >
      <BaseIcon name="magic" />
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
/* eslint-disable vue-scoped-css/no-unused-selector */
.icon.icon-magic {
  outline: none;
  height: 16px;
  width: 16px;
  padding-top: 5px;
  color: var(--text-color-magic-strong);
}
</style>
