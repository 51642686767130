import AiGenerateSnippetCommentPopover from '@/components/AiGenerateSnippetCommentPopover.vue';
import { Extension } from '@tiptap/core';
import { showEditorPopover } from '../popover';
import { isGrandParentOfType } from '../utils';
import { getSwimmEditorServices } from './Swimm';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    AiSnippetCommentGeneration: {
      generateSnippetCommentUsingAI: () => ReturnType;
    };
  }
}

export default Extension.create({
  name: 'aiSnippetCommentGeneration',

  addCommands() {
    return {
      generateSnippetCommentUsingAI:
        () =>
        ({ state, dispatch, editor }) => {
          const range = state.selection;
          const swimmEditorServices = getSwimmEditorServices(editor);
          if (swimmEditorServices.external.isGenAiDisabledInWorkspace.value) {
            return false;
          }

          if (!isGrandParentOfType(state.doc, range.from, ['swmSnippet'])) {
            return false;
          }

          if (dispatch) {
            setTimeout(async () => {
              const isRepoAIGenerationEnabled = swimmEditorServices.external.isAIGenerationEnabledForRepo();
              if (!isRepoAIGenerationEnabled) {
                swimmEditorServices.setShowAiGenerationDisabledModal(true, 'Snippet Comment');
                return;
              }

              let generatedContent = null;
              const { parseSwmdContent } = await import('../../swmd/parser');
              await showEditorPopover(editor, AiGenerateSnippetCommentPopover, {
                onGenerated: (generatedText: string) => {
                  generatedContent = parseSwmdContent(generatedText, {});
                },
              });

              if (generatedContent) {
                editor.chain().focus().insertContentAt(range, generatedContent).run();
              }
            });
          }

          return true;
        },
    };
  },
});
