<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{ stepsAmount: number; currentStep: number }>();

defineEmits(['step-click']);

const currentStepDistance = computed(() => calcDistancePercentageForStep(props.currentStep));

function calcDistancePercentageForStep(index: number): number {
  const stepSize = 100 / (props.stepsAmount - 1);
  return Math.max(0, Math.min(100, stepSize)) * index;
}
</script>

<template>
  <div class="progress-bar">
    <div class="progress" />
    <div
      v-for="milestoneIndex in stepsAmount - 1"
      :key="milestoneIndex"
      :class="['milestone', { show: milestoneIndex !== currentStep }]"
      :style="{ left: `${calcDistancePercentageForStep(milestoneIndex)}%` }"
      @click="$emit('step-click', milestoneIndex - currentStep)"
    />
  </div>
</template>

<style scoped lang="postcss">
.progress-bar {
  --progress-bar-size: 12px;
  position: relative;
  background: var(--color-surface);
  display: flex;
  width: 100%;
  height: var(--progress-bar-size);
  border-radius: 8px;
}

.progress {
  position: absolute;
  height: 100%;
  width: calc(v-bind(currentStepDistance) * 1%);
  border-radius: 8px;
  background: var(--coastal-shore);
  transition: width 0.8s ease;
}

.milestone {
  opacity: 0;
  position: absolute;
  background: var(--color-brand-disable);
  width: var(--progress-bar-size);
  height: var(--progress-bar-size);
  border-radius: 50%;
  transform: translateX(calc(var(--progress-bar-size) * -1));
  transition: opacity 0.5s ease;
  pointer-events: none;

  &.show {
    opacity: 1;
    cursor: pointer;
    pointer-events: auto;
  }
}

@media screen and (max-width: 480px) {
  .progress-bar .milestone {
    display: none;
  }
}
</style>
