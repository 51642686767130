import { LineWords, type TokenSuggestion } from '@swimm/shared';

export function getValidTokenSuggestion(
  suggestion: TokenSuggestion,
  lineWords: LineWords = new LineWords(suggestion.lineData),
  tokenWords: LineWords = new LineWords(suggestion.token)
) {
  if (isTokenValid(suggestion, lineWords, tokenWords)) {
    return suggestion;
  }
  return fixTokenSuggestion(suggestion, lineWords, tokenWords);
}

export function isTokenValid(
  suggestion: TokenSuggestion,
  lineWords: LineWords = new LineWords(suggestion.lineData),
  tokenWords: LineWords = new LineWords(suggestion.token)
): boolean {
  // TL;DR: One of the causes of broken tokens in the application is tokens that contain fractions of words.
  // For example: For the line "this.$backup", the token "$backup" is broken, as the words are ["this", ".$", "backup"].
  // It is partially handled in the static code analysis (global tokens), which returns the beloved wordIndex -1.
  // Currently it can happen by the `getTokenSuggestionStartFallback` logic (that runs when the wordIndex is -1).
  // if (suggestion.token.toLowerCase().startsWith('bd')) { console.log(suggestion, lineWords, tokenWords); }
  // Another cause is token suggestions that span multiple words.
  return (
    tokenWords.words.length === 1 &&
    suggestion.position.wordStart === suggestion.position.wordEnd &&
    suggestion.position.wordStart >= 0 &&
    suggestion.token === lineWords.words[suggestion.position.wordStart]
  );
}

function fixTokenSuggestion(
  suggestion: TokenSuggestion,
  lineWords: LineWords,
  tokenWords: LineWords
): TokenSuggestion | null {
  if (tokenWords.words.length === 0) {
    return null;
  }
  for (let lineWordIndex = 0; lineWordIndex < lineWords.words.length; lineWordIndex++) {
    // TODO: What if we don't find any exact matches?
    if (tokenWords.words.every((word, tokenWordIndex) => word === lineWords.words[lineWordIndex + tokenWordIndex])) {
      return {
        ...suggestion,
        token: tokenWords.words[0],
        position: {
          ...suggestion.position,
          wordStart: lineWordIndex,
          wordEnd: lineWordIndex,
        },
      };
    }
  }
  return null;
}
