<template>
  <div class="permanentNotification">
    <h2>{{ notificationContent.title }}</h2>
    <h3 v-if="notificationContent.subtitle" class="subtitle">
      {{ notificationContent.subtitle }} <Status class="status" status="done" />
    </h3>
    <slot>
      <p>
        {{ notificationContent.body }}
        <router-link v-if="hasNotificationLink" class="edit-link" :to="notificationContent.link">{{
          notificationContent.linkText
        }}</router-link>
      </p>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { PropType } from 'vue';

const props = defineProps({
  notificationContent: {
    type: Object as PropType<{
      title: string;
      body: string;
      subtitle?: string;
      link?: string;
      linkText?: string;
    }>,
    required: true,
  },
});

const hasNotificationLink = computed(() => !!props.notificationContent.link && !!props.notificationContent.linkText);
</script>

<style scoped>
.edit-link {
  color: var(--text-color-link);
}

.permanentNotification {
  margin: auto;
  padding: 42px 42px;
  background-color: var(--color-status-info);
}

.permanentNotification h2 {
  margin-bottom: 3%;
}

.permanentNotification h3 {
  margin-bottom: 2%;
  font-weight: normal;
}

.permanentNotification h3 span {
  margin-left: 1%;
}

.subtitle {
  display: flex;
  align-items: baseline;
}

.status {
  margin-left: 15px;
  padding-top: 10px;
}
</style>
