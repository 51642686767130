import { productEvents } from '@swimm/shared';
import type { SuggestionOptions } from '@tiptap/suggestion';
import { VueRenderer, isMacOS, isiOS } from '@tiptap/vue-3';
import { ALLOWED_PREFIXES } from '@swimm/editor';
import tippy, { type Instance } from 'tippy.js';
import SlashCommandsMenu from '../components/SlashCommandsMenu.vue';
import { isParentOfType } from './utils';
import { getSwimmEditorServices } from './extensions/Swimm';

import type { Command, Range } from '@tiptap/core';
import { type SlashCommand } from '@swimm/reefui';

const isMac = typeof navigator !== 'undefined' && (isiOS() || isMacOS());

const SLASH_COMMANDS: SlashCommand[][] = [
  [
    {
      title: 'Generate description',
      name: 'generate',
      aliases: ['ai'],
      icon: 'magic',
      description: 'Let AI explain your code',
      command:
        (range: Range): Command =>
        ({ chain }) => {
          return chain().deleteRange(range).generateSnippetCommentUsingAI().run();
        },
    },
    {
      icon: 'terminal',
      title: 'Code snippet',
      name: 'snippet',
      description: 'Highlight code from your respository.',
      command:
        (range: Range): Command =>
        ({ chain }) => {
          return chain().deleteRange(range).selectAndInsertSwmSnippets().run();
        },
    },
    {
      icon: 'sync-token',
      title: 'Smart Token',
      name: 'token',
      description: 'Live code tokens from your repository.',
      command:
        (range: Range): Command =>
        ({ chain }) => {
          return chain().deleteRange(range).openSwmTokenSelectionMenu().run();
        },
    },
    {
      icon: 'folder',
      title: 'Path',
      name: 'path',
      description: 'File path relative to codebase.',
      command:
        (range: Range): Command =>
        ({ chain }) => {
          return chain().deleteRange(range).selectAndInsertSwmPath().run();
        },
    },
    {
      icon: 'image',
      title: 'Image',
      name: 'image',
      aliases: ['upload'],
      description: 'Add an image.',
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph', 'tableHeader', 'tableCell'])) {
            return false;
          }

          return chain().deleteRange(range).pickAndInsertImage().run();
        },
    },
    {
      icon: 'image',
      title: 'Block Image',
      name: 'blockimage',
      aliases: ['image'],
      description: 'Add a block image.',
      command:
        (range: Range): Command =>
        ({ chain }) => {
          return chain().deleteRange(range).pickAndInsertBlockImage().run();
        },
    },
    {
      icon: 'doc',
      title: 'Doc',
      name: 'doc',
      aliases: ['link'],
      description: 'Link to a Swimm doc.',
      command:
        (range: Range): Command =>
        ({ chain }) => {
          return chain().deleteRange(range).selectAndInsertSwmLinkToDoc().run();
        },
    },
    {
      icon: 'playlist',
      title: 'Playlist',
      aliases: ['link'],
      name: 'playlist',
      description: 'Link to a Swimm playlist.',
      command:
        (range: Range): Command =>
        ({ chain }) => {
          return chain().deleteRange(range).selectAndInsertSwmLinkToPlaylist().run();
        },
    },
    {
      icon: 'mention',
      title: 'Mention',
      name: 'mention',
      description: 'Mention a Swimm user.',
      command:
        (range: Range): Command =>
        ({ chain }) => {
          return chain().deleteRange(range).openSwmMentionSelectionMenu().run();
        },
    },
    {
      icon: 'table',
      title: 'Table',
      name: 'table',
      description: 'Insert a table.',
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph'])) {
            return false;
          }

          return chain().focus().deleteRange(range).insertTable({ withHeaderRow: true }).run();
        },
    },
    {
      icon: 'giphy',
      title: 'Giphy',
      name: 'giphy',
      description: 'Embed a GIF from Giphy.',
      command:
        (range: Range): Command =>
        ({ dispatch, editor, chain, commands, state }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);

          if (!isParentOfType(state.doc, range.from, ['paragraph'])) {
            return false;
          }

          if (!dispatch) {
            return chain().focus().setBlockImage({ src: '' }).run() && !swimmEditorServices.isAirGap;
          }

          setTimeout(async () => {
            const src = await swimmEditorServices.selectGiphy();
            if (src != null) {
              editor.chain().focus().setBlockImage({ src }).run();
              swimmEditorServices.external.trackEvent(productEvents.GIPHY_ADDED, {});
            }
          }, 0);

          return commands.deleteRange(range);
        },
    },
    {
      icon: 'graph-flow',
      title: 'Diagram (Mermaid)',
      name: 'mermaid',
      aliases: ['chart', 'diagram', 'flow'],
      description: 'A Mermaid diagram.',
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph'])) {
            return false;
          }

          return chain().focus().deleteRange(range).insertMermaid().run();
        },
    },
    {
      icon: 'youtube',
      title: 'YouTube',
      name: 'youtube',
      aliases: ['video'],
      keyCommands: undefined,
      description: 'Embed a YouTube video.',
      command:
        (range: Range): Command =>
        ({ chain }) => {
          return chain().deleteRange(range).selectAndInsertYoutubeVideo().run();
        },
    },
  ],
  [
    {
      icon: 'markdown',
      title: 'Paste as markdown',
      name: 'markdown',
      keyCommands: isMac ? ['⌘', '⌥', 'v'] : ['Ctrl', 'Alt', 'v'],
      description: 'Paste as markdown.',
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph', 'tableHeader', 'tableCell'])) {
            return false;
          }
          return chain().deleteRange(range).pasteAsMarkdown().run();
        },
    },
    {
      icon: 'copy-paste',
      title: 'Paste as plain text',
      name: 'paste plain',
      keyCommands: isMac ? ['⌘', '⇧', 'V'] : ['Ctrl', 'Shift', 'V'],
      description: 'Paste as plain text.',
      command:
        (range: Range): Command =>
        ({ chain }) => {
          return chain().deleteRange(range).pasteAsPlainText().run();
        },
    },
    {
      icon: 'text-headline1',
      title: 'Heading 1',
      name: 'h1',
      keyCommands: ['#'],
      description: 'Insert a heading 1.',
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph', 'heading'])) {
            return false;
          }

          return chain().focus().deleteRange(range).setNode('heading', { level: 1 }).run();
        },
    },
    {
      icon: 'text-headline2',
      title: 'Heading 2',
      name: 'h2',
      keyCommands: ['##'],
      description: 'Insert a heading 2.',
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph', 'heading'])) {
            return false;
          }

          return chain().focus().deleteRange(range).setNode('heading', { level: 2 }).run();
        },
    },
    {
      icon: 'text-headline3',
      title: 'Heading 3',
      name: 'h3',
      keyCommands: ['###'],
      description: 'Insert a heading 3.',
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph', 'heading'])) {
            return false;
          }

          return chain().focus().deleteRange(range).setNode('heading', { level: 3 }).run();
        },
    },
    {
      icon: 'text-headline4',
      title: 'Heading 4',
      name: 'h4',
      keyCommands: ['####'],
      description: 'Insert a heading 4.',
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph', 'heading'])) {
            return false;
          }

          return chain().focus().deleteRange(range).setNode('heading', { level: 4 }).run();
        },
    },
    {
      icon: 'text-headline5',
      title: 'Heading 5',
      name: 'h5',
      keyCommands: ['#####'],
      description: 'Insert a heading 5.',
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph'])) {
            return false;
          }

          return chain().focus().deleteRange(range).setNode('heading', { level: 5 }).run();
        },
    },
    {
      icon: 'text-headline6',
      title: 'Heading 6',
      name: 'h6',
      keyCommands: ['######'],
      description: 'Insert a heading 6.',
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph'])) {
            return false;
          }

          return chain().focus().deleteRange(range).setNode('heading', { level: 6 }).run();
        },
    },
    {
      icon: 'text-bullet',
      title: 'Bullet list',
      name: 'ul',
      keyCommands: ['*'],
      description: 'Insert a bullet list item.',
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph'])) {
            return false;
          }

          return chain().focus().deleteRange(range).toggleBulletList().run();
        },
    },
    {
      icon: 'ol',
      title: 'Ordered list',
      name: 'ol',
      keyCommands: ['1', '.'],
      description: 'Insert a ordered list item.',
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph'])) {
            return false;
          }

          return chain().focus().deleteRange(range).toggleOrderedList().run();
        },
    },
    {
      icon: 'checklist',
      title: 'Task list',
      name: 'tl',
      keyCommands: ['[]'],
      description: 'Insert a task list item.',
      aliases: ['checkbox', 'todo', 'checklist', 'check'],
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph'])) {
            return false;
          }

          return chain().focus().deleteRange(range).toggleList('taskItem', 'taskItem').run();
        },
    },
    {
      icon: 'quote',
      title: 'Quotes',
      name: 'quote',
      keyCommands: ['>'],
      description: 'Insert a blockquote.',
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph'])) {
            return false;
          }

          return chain().focus().deleteRange(range).toggleBlockquote().run();
        },
    },
    {
      icon: 'codeblock',
      title: 'Code block',
      name: 'codeblock',
      keyCommands: ['`', '`', '`'],
      description: 'Insert a code block.',
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph'])) {
            return false;
          }

          return chain().focus().deleteRange(range).toggleCodeBlock().run();
        },
    },
    {
      icon: 'link',
      title: 'Link',
      name: 'link',
      description: 'Insert a link',
      aliases: ['url'],
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph', 'tableHeader', 'tableCell', 'heading'])) {
            return false;
          }

          return chain().deleteRange(range).editOrInsertLink().run();
        },
    },
    {
      icon: 'bold',
      title: 'Bold',
      name: 'bold',
      description: 'Format text as bold',
      keyCommands: ['*', '*'],
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph', 'tableHeader', 'tableCell', 'heading'])) {
            return false;
          }

          return chain().focus().deleteRange(range).setMark('bold').run();
        },
    },
    {
      icon: 'italic',
      title: 'Italic',
      name: 'italic',
      description: 'Format text as italic',
      keyCommands: ['_'],
      command:
        (range: Range): Command =>
        ({ chain, state }) => {
          if (!isParentOfType(state.doc, range.from, ['paragraph', 'tableHeader', 'tableCell', 'heading'])) {
            return false;
          }

          return chain().focus().deleteRange(range).setMark('italic').run();
        },
    },
  ],
];

const commands: Omit<SuggestionOptions, 'editor'> = {
  allowSpaces: true,
  allowedPrefixes: ALLOWED_PREFIXES,
  // TODO: Add an `allow` function that returns false when the token selection prosemirror plugin is active.
  items: ({ editor, query }) => {
    return SLASH_COMMANDS.map((group) =>
      group.filter(
        (item) =>
          [item.title, item.name, ...(item.aliases ?? [])].some((name) =>
            name.toLowerCase().startsWith(query.toLowerCase())
          ) &&
          // TODO Not sure if this is the right range to pass here, the
          // suggestion range is not accessible here directly for some reason
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          editor.can().command(item.command!(editor.state.selection))
      )
    ).filter((group) => group.length > 0);
  },

  render: () => {
    let component: VueRenderer | null = null;
    let popup: Instance | null = null;

    function destroy() {
      if (popup != null) {
        popup.destroy();
        component?.destroy();
        popup = null;
        component = null;
      }
    }

    return {
      onStart: (props) => {
        component = new VueRenderer(SlashCommandsMenu, {
          props: {
            ...props,
            groupHeadings: ['Swimm commands', 'Styling'],
          },
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy(props.editor.view.dom, {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          getReferenceClientRect: () => props.clientRect!()!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          appendTo: props.editor.view.dom.parentElement!,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
          theme: 'none',
          role: 'menu',
          maxWidth: 'none',
        });
      },

      onUpdate(props) {
        component?.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup?.setProps({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          getReferenceClientRect: () => props.clientRect!()!,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          destroy();

          return true;
        }

        return component?.ref?.onKeyDown(props.event);
      },

      onExit() {
        destroy();
      },
    };
  },
};

export default commands;
