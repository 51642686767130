import type {
  CodeAnalysisErrorResponse,
  CodeAnalysisLiveRequest,
  CodeAnalysisLiveResponseMap,
  CodeAnalysisRequest,
  CodeAnalysisResponseMap,
} from '@swimm/shared';
import { CTagLanguage, getLoggerNew } from '@swimm/shared';
import * as _ from 'lodash-es';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import * as websocketEventCodes from 'websocket-event-codes';
import axios from 'axios';

// We want to run for every language if there's enough code written in that language.
// We arbitrarily cap the required number of bytes (provided by GH API) to the estimate of 10 files, times ~300 loc per
// file (calculated on Swimm), times avg ~60 bytes/line (calculated on Swimm).
const MIN_LANGUAGE_BYTES_FOR_SGD = 10 * 300 * 60;
const logger = getLoggerNew(__modulename);

export function getSupportedLanguagesForRepo(languageToBytes: Record<string, number>, sgd: boolean): CTagLanguage[] {
  // GitHub considers 'Vue' a language while CTags doesn't.
  if (languageToBytes['Vue']) {
    languageToBytes['JavaScript'] = (languageToBytes['JavaScript'] ?? 0) + languageToBytes['Vue'];
    delete languageToBytes['Vue'];
  }
  const primaryLanguageAndBytes = _.maxBy(Object.entries(languageToBytes), ([, bytes]) => bytes);
  if (!primaryLanguageAndBytes) {
    // GitHub detected no languages in this repo.
    return [];
  }
  const [primaryLanguage] = primaryLanguageAndBytes;
  const supportedLanguages = Object.entries(languageToBytes)
    .filter(
      ([language, bytes]) =>
        Object.values(CTagLanguage).includes(language as CTagLanguage) &&
        (language === primaryLanguage || !sgd || bytes > MIN_LANGUAGE_BYTES_FOR_SGD)
    )
    .map(([language]) => language);
  if (supportedLanguages.length < Object.entries(languageToBytes).length) {
    logger.debug(
      `Unsupported language(s): ${JSON.stringify(
        Object.keys(languageToBytes).filter((language) => !supportedLanguages.includes(language))
      )}`
    );
  }
  if (supportedLanguages.includes('Python')) {
    return supportedLanguages as CTagLanguage[];
  }
  const jupyterBytes = languageToBytes['Jupyter Notebook'];
  if (sgd) {
    if (jupyterBytes && jupyterBytes > MIN_LANGUAGE_BYTES_FOR_SGD) {
      return [...supportedLanguages, CTagLanguage.PYTHON] as CTagLanguage[];
    }
  }

  return supportedLanguages as CTagLanguage[];
}

async function invokeCloudRun<TArgs extends CodeAnalysisRequest>(
  args: TArgs
): Promise<CodeAnalysisErrorResponse | CodeAnalysisResponseMap[TArgs['action']]> {
  const response = await axios.post<CodeAnalysisResponseMap[TArgs['action']]>(
    process.env.CODE_ANALYSIS_CLOUD_RUN_URL,
    args,
    { headers: { Authorization: `Bearer ${await CloudFunctions.getAuthToken()}` } }
  );
  if (response.status !== 200) {
    throw new Error(`Code analysis cloud run failed with status ${response.status}`);
  }
  return response.data;
}

export async function invokeCodeAnalysis<
  TArgs extends CodeAnalysisRequest,
  TResponseField extends keyof CodeAnalysisResponseMap[TArgs['action']]
>({
  args,
  responseField,
}: {
  args: TArgs;
  responseField: TResponseField;
}): Promise<CodeAnalysisResponseMap[TArgs['action']][TResponseField]> {
  const result = await invokeCloudRun(args);

  if (result.status === 'error') {
    throw new Error(`Bad response from codeAnalysis cloud function, status: error, code: ${result.code}`);
  }
  if (!result[responseField]) {
    throw new Error(
      `Bad response from codeAnalysis cloud function: expected field "${String(responseField)}" is missing`
    );
  }
  return result[responseField];
}

function getWebsocketUrl(): string | undefined {
  return process.env.CODE_ANALYSIS_CLOUD_RUN_WEBSOCKET_URL;
}

export function isLiveCodeAnalysisAvailable(): boolean {
  return !!getWebsocketUrl();
}

export async function* invokeLiveCodeAnalysis<TArgs extends CodeAnalysisLiveRequest>(
  args: TArgs,
  firebaseToken?: string
): AsyncIterable<CodeAnalysisLiveResponseMap[TArgs['action']]> {
  const websocketURL = getWebsocketUrl();
  const authToken = firebaseToken ?? (await CloudFunctions.getAuthToken());
  const websocket = new WebSocket(websocketURL);
  websocket.onopen = () => {
    websocket.send(JSON.stringify({ token: authToken }));
  };
  const promisifyWebsocketEvent = <T extends keyof WebSocketEventMap>(
    eventName: T
  ): Promise<{ eventName: T; event: WebSocketEventMap[T] }> =>
    new Promise((resolve) => {
      // @ts-ignore
      websocket.addEventListener(eventName, function listener(event) {
        resolve({ eventName, event });
        // @ts-ignore
        websocket.removeEventListener(eventName, listener);
      });
    });

  const messageQueue = [];
  websocket.addEventListener('message', (event) => {
    messageQueue.push(event);
  });
  let message = promisifyWebsocketEvent('message');
  const open = promisifyWebsocketEvent('open');
  const error = promisifyWebsocketEvent('error');
  const close = promisifyWebsocketEvent('close');
  const eventList = [open, error, message, close];
  outer: while (true) {
    const { eventName, event } = await Promise.race(eventList);
    switch (eventName) {
      case 'open':
        // Remove open from the eventList so we don't keep waiting on it.
        eventList.splice(eventList.indexOf(open), 1);
        logger.debug('Websocket to Cloud Run opened successfully!');
        websocket.send(JSON.stringify(args));
        break;
      case 'error':
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        logger.error('Websocket to Cloud Run error:', event as any);
        break outer;
      case 'message': {
        // Replace 'message' with a new listener so it can resolve again for the next message.
        const messageIndex = eventList.indexOf(message);
        message = promisifyWebsocketEvent('message');
        eventList.splice(messageIndex, 1, message);
        while (messageQueue.length) {
          const response = JSON.parse(messageQueue.shift().data) as CodeAnalysisLiveResponseMap[TArgs['action']];
          yield response;
        }
        break;
      }
      case 'close': {
        const closeEvent = event as CloseEvent;
        if (closeEvent.code !== websocketEventCodes.NORMAL_CLOSURE || !closeEvent.wasClean) {
          throw new Error(
            `Websocket to Cloud Run closed with error code ${closeEvent.code} (${
              closeEvent.wasClean ? '' : 'not '
            }cleanly): ${closeEvent.reason}`
          );
        }
        break outer;
      }
    }
  }
}
