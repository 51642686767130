<template>
  <div :class="['row', { 'no-data': !title, highlight }]">
    <div class="data" v-if="title">
      <div class="title">
        <Icon v-if="iconName" :name="iconName" class="icon" no-padding /><span>{{ title }}</span>
      </div>
      <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    iconName: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    highlight: { type: Boolean, default: false },
  },
};
</script>

<style scoped lang="postcss">
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-sm) var(--space-md);
  border: 1px solid var(--color-border-default);
  border-radius: 4px;
  background: var(--color-surface);
  text-align: start;

  &.highlight {
    border: 2px solid var(--color-brand);
  }

  &.no-data {
    justify-content: center;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .data {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);

    .title {
      display: flex;
      align-items: center;
      font-size: var(--subtitle-L);
      font-weight: 800;
      color: var(--text-color-primary);

      .icon {
        padding-right: 8px;
      }
    }

    .subtitle {
      font-size: var(--body-XS);
      color: var(--text-color-secondary);
    }
  }
}
</style>
