import { ref } from 'vue';
import type { Ref } from 'vue';
import { defineStore } from 'pinia';
import { STORES } from '@/modules/core/stores/store-constants';

type TooltipItem = {
  tooltipKey: string;
  priority: number;
};

const findItemWithHighestPriority = (tooltipItems: TooltipItem[]): TooltipItem | null =>
  tooltipItems.length === 0
    ? null
    : tooltipItems.reduce(
        (accHighestPriorityTooltipItem, currTooltipItem) =>
          currTooltipItem.priority > accHighestPriorityTooltipItem.priority
            ? currTooltipItem
            : accHighestPriorityTooltipItem,
        tooltipItems[0]
      );

export const useHelpTooltipsStore = defineStore(STORES.HELP_TOOLTIPS, () => {
  const isBranchChanged: Ref<boolean> = ref(false);
  const tooltipsReadyToBeDisplayed: Ref<TooltipItem[]> = ref([]);
  const currentlyDisplayedTooltip: Ref<string | null> = ref(null);

  const setBranchChanged = (isBranchChangedValue: boolean) => {
    isBranchChanged.value = isBranchChangedValue;
  };

  const getNextActiveTooltip = (): string | null =>
    tooltipsReadyToBeDisplayed.value.length > 0
      ? findItemWithHighestPriority(tooltipsReadyToBeDisplayed.value).tooltipKey
      : null;

  const resetActiveTooltipOnScreen = () => {
    tooltipsReadyToBeDisplayed.value = tooltipsReadyToBeDisplayed.value.filter(
      ({ tooltipKey }) => tooltipKey !== currentlyDisplayedTooltip.value
    );

    currentlyDisplayedTooltip.value = getNextActiveTooltip();
  };

  const addTooltipToList = (tooltipItem: TooltipItem) => {
    if (tooltipsReadyToBeDisplayed.value.find(({ tooltipKey }) => tooltipKey === tooltipItem.tooltipKey)) {
      return;
    }

    tooltipsReadyToBeDisplayed.value.push(tooltipItem);

    if (!currentlyDisplayedTooltip.value) {
      currentlyDisplayedTooltip.value = tooltipItem.tooltipKey;
    }
  };

  const removeTooltipFromTooltipsOnTheScreen = (tooltipKey: string) => {
    tooltipsReadyToBeDisplayed.value = tooltipsReadyToBeDisplayed.value.filter(
      ({ tooltipKey }) => tooltipKey !== tooltipKey
    );
    if (currentlyDisplayedTooltip.value !== tooltipKey) {
      return;
    }
    currentlyDisplayedTooltip.value = getNextActiveTooltip();
  };

  return {
    isBranchChanged,
    currentlyDisplayedTooltip,
    setBranchChanged,
    resetActiveTooltipOnScreen,
    addTooltipToList,
    removeTooltipFromTooltipsOnTheScreen,
  };
});
