<script setup lang="ts">
import { type PropType, computed } from 'vue';
import SlashCommandEntry from './SlashCommandEntry.vue';
import type { SlashCommandsCategory } from '@/types';

export type SlashCommandIndex = [category: number, item: number];

const props = defineProps({
  commandCategories: { type: Array as PropType<SlashCommandsCategory[]>, required: true },
  selectedCommand: { type: Array as unknown as PropType<SlashCommandIndex>, required: true },
  shouldScrollToSelected: { type: Boolean, required: true },
  isIde: { type: Boolean, required: true },
});

defineEmits<{
  (event: 'command-selected', selectedCommand: SlashCommandIndex): void;
  (event: 'hover-over-command', selectedCommand: SlashCommandIndex): void;
  (event: 'scrolled-to-command'): void;
}>();

const currentSelectedCommand = computed(() => {
  if (props.commandCategories.length === 0) {
    return null;
  }

  return props.commandCategories[props.selectedCommand[0]].commands[props.selectedCommand[1]];
});

const selectedCommandDescription = computed(() => currentSelectedCommand.value?.description ?? '');
</script>

<template>
  <div class="content slash-command-container" :class="{ ide: isIde }">
    <div class="categories">
      <div v-for="(category, categoryIndex) in commandCategories" :key="category.category" class="category">
        <div v-if="commandCategories.length > 1" class="category-header">{{ category.category }}</div>
        <SlashCommandEntry
          v-for="(command, commandIndex) in category.commands"
          :key="command.title"
          :data-testid="command.title"
          :shortcut="command.shortcut"
          :tag="command.tag"
          :title="command.title"
          :icon="command.icon"
          :is-selected="currentSelectedCommand === command"
          :is-ide="isIde"
          :should-scroll-to="currentSelectedCommand === command && shouldScrollToSelected"
          @select="$emit('command-selected', [categoryIndex, commandIndex])"
          @hover="$emit('hover-over-command', [categoryIndex, commandIndex])"
          @scrolled="$emit('scrolled-to-command')"
        />
      </div>
    </div>

    <div class="menu-footer">
      {{ selectedCommandDescription }}
    </div>
  </div>
</template>

<style scoped lang="postcss">
.content {
  cursor: default;
}

.category:not(:last-child) {
  padding-bottom: 16px;
}

.category-header {
  padding: 5px 15px 5px 15px;
  font-size: var(--subtitle-S);
  font-weight: 600;
  color: var(--text-color-secondary);
}

.menu-footer {
  padding: 7px 15px;
  font-size: var(--fontsize-xxs);
  color: var(--text-color-secondary);
  background-color: var(--color-surface);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.categories {
  display: flex;
  flex-direction: row;

  .ide & {
    flex-direction: column;
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>
