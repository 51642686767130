<script setup lang="ts">
import FolderNameEdit from './FolderNameEdit.vue';
import { computed, inject, ref } from 'vue';

import { IconButton } from '@swimm/ui';
import { ToggleableTreeNode } from '../types';

const props = defineProps<{
  node: ToggleableTreeNode;
  repoId: string;
  isRoot?: boolean;
  forceOpen?: boolean;
  path: string;
  selectedPath?: string;
  expandAll?: boolean;
  depth: number;
}>();

const emit = defineEmits<{
  'node-selected': [node: ToggleableTreeNode];
  'node-toggle': [{ node: ToggleableTreeNode; open: boolean }];
  'new-child-node-created': [nodeId: string];
}>();

const showAddFolder = ref(false);

// @ts-ignore
const { handleNewFolderClick, handleNewFolderCreate } = inject('treeActions');

const iconName = computed(() => {
  if (!sortedChildren.value.length) {
    return 'folder';
  }
  return isOpen.value ? 'folder-open' : 'folder';
});

const arrowIcon = computed(() => {
  return isOpen.value ? 'arrow-down' : 'arrow-right';
});

const isSelected = computed(() => {
  return props.selectedPath === props.path;
});

const isPartialToSelectedPath = computed(() => {
  return props.selectedPath ? props.selectedPath.startsWith(props.path + '/') : false;
});

const isOpen = computed(() => {
  let returnValue = false;

  if (props.expandAll) {
    return true;
  }

  if (props.forceOpen) {
    // If the node was selected and not explicitly open or the current node is part of the selected path
    returnValue = props.isRoot || isPartialToSelectedPath.value || (isSelected.value && props.node.closed === false);
  } else {
    // This will automatically close folders that are open when a new folder path is selected
    returnValue =
      props.isRoot ||
      (isPartialToSelectedPath.value && !(props.node.closed === true)) ||
      (isSelected.value && props.node.closed === false);
  }

  emit('node-toggle', { node: props.node, open: returnValue });
  return returnValue;
});

const sortedChildren = computed<ToggleableTreeNode[]>(() => {
  if (props.node.children) {
    return [...props.node.children].sort((a, b) => {
      if (a.type === b.type) {
        return a.path < b.path ? -1 : 1;
      }
      return a.type === 'directory' ? -1 : 1;
    });
  }
  return [];
});

const nodeHeaderDynamicStyle = computed(() => {
  // Set padding by the tree level
  const padding = 12 * (props.depth - 1);
  return `padding-left: ${padding}px`;
});

function onNodeClicked() {
  emit('node-selected', props.node);
}

// We changed the type here to any, because vue-tsc has problems inferring the emit type due to recursiveness.
// Actual type: `nodeClicked: ToggleableTreeNode`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onNodeSelected(nodeClicked: any) {
  emit('node-selected', nodeClicked);
}

// We changed the type here to any, because vue-tsc has problems inferring the emit type due to recursiveness.
// Actual type: `node: { node: ToggleableTreeNode; open: boolean }`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function nodeToggled(node: any) {
  emit('node-toggle', node);
}

function onNewFolderClick(parentFolderId = props.node.id) {
  showAddFolder.value = true;
  handleNewFolderClick(parentFolderId);
}

function onNewFolderCreate(parentFolderId: string = props.node.id, childFolderId: string) {
  showAddFolder.value = false;
  emitNewChildCreated(childFolderId);
  handleNewFolderCreate(parentFolderId);
}

function emitNewChildCreated(childFolderId: string) {
  emit('new-child-node-created', childFolderId);
}
</script>
<template>
  <div class="node">
    <div
      :style="nodeHeaderDynamicStyle"
      :class="['header', 'selectable', { selected: isSelected }]"
      @click="onNodeClicked"
    >
      <div class="node-container">
        <Icon v-if="!isRoot" :name="sortedChildren.length ? arrowIcon : ''" no-padding class="arrow" />
        <Icon :name="iconName" no-padding />
        <span class="folder-name text-ellipsis">{{ node.name }}</span>
        <IconButton
          v-if="!isRoot"
          @click="onNewFolderClick"
          name="add"
          class="create-subfolder-button"
          v-tooltip="'Create subfolder'"
          no-padding
        />
      </div>
      <FolderNameEdit
        v-if="showAddFolder"
        :parent-id="node.id"
        :repo-id="repoId"
        class="add-input"
        show-icon
        show-error-below
        @change="onNewFolderCreate"
        @cancel="showAddFolder = false"
      />
    </div>
    <template v-if="isOpen">
      <div v-for="childNode in sortedChildren" :id="childNode.path" :key="childNode.path">
        <FolderTreeNode
          :node="childNode"
          :repo-id="repoId"
          :path="childNode.path"
          :selected-path="selectedPath"
          @node-selected="onNodeSelected"
          :force-open="forceOpen"
          @node-toggle="nodeToggled"
          @new-child-node-created="emitNewChildCreated"
          :expand-all="expandAll"
          :depth="depth + 1"
        />
      </div>
    </template>
  </div>
</template>

<style scoped lang="postcss">
.node {
  cursor: pointer;

  .node-container {
    display: flex;
    align-items: center;
    padding: var(--space-xs) var(--space-sm);
    gap: var(--space-xs);
  }

  .add-input {
    margin-left: calc(var(--space-lg) + var(--space-sm));
    margin-right: var(--space-sm);
  }

  .create-subfolder-button {
    font-size: var(--fontsize-xs);
    display: none;
    margin-right: var(--space-xs);
  }

  .header {
    position: relative;
    padding-top: 2px;
    padding-bottom: 2px;

    .node-container {
      padding-left: var(--space-sm);
    }

    &.selected {
      color: var(--text-color-primary);
      background: var(--color-selected);
    }

    &:not(.selectable) {
      pointer-events: none;
    }

    &.selectable:not(.selected):hover {
      background: var(--color-hover);
    }

    &:hover {
      .create-subfolder-button {
        display: block;
      }
    }

    .folder-name {
      display: inline-block;
      font-size: var(--body-L);
      vertical-align: bottom;
      flex: 1;
    }

    .arrow {
      font-size: var(--fontsize-xs);
      min-width: 14px;
    }
  }
}
</style>
