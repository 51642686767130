import { Node } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { MarkdownUtils } from './markdownUtils';
import { parseHTMLThroughProsemirrorConventions } from './HTMLSanitizing';

export const PasteMarkdownExtension = Node.create({
  name: 'PasteMarkdownExtension',

  addOptions() {
    return { repoId: undefined };
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey(this.name),

        props: {
          transformPastedHTML: (html) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return parseHTMLThroughProsemirrorConventions(html, this.editor.schema);
          },
          transformPastedText: (text) => {
            const anchorNode = getSelection()?.anchorNode;
            if (anchorNode) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (anchorNode.nodeName === 'CODE' || anchorNode.parentNode.nodeName === 'CODE') {
                return text;
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.editor.commands.insertContent(MarkdownUtils.marked(text));
            }
            return '';
          },
        },
      }),
    ];
  },
});
