<template>
  <div class="v-flex center git-hosting-bg">
    <div class="content">
      <img v-if="isMobileDevice" width="100" class="logo" src="@/assets/swimm.png" />
      <div class="catchphrase">
        <SwText variant="headline1" class="title">Thanks for swimming by!</SwText>
        <div class="registration-prase">Swimm currently supports GitHub hosting only.</div>
        <div class="registration-prase">
          We will notify you when support for other hosting platforms is widely available.
        </div>
      </div>
      <div class="actions">
        <Action @click="goToDemo">Chat with the Swimm team</Action>
        <Action secondary @click="changeGitHostingPlatform">Change Git hosting platform</Action>
      </div>
      <Action class="action-link link" @click.prevent="logout">Log out <Icon name="arrow-right" /></Action>
    </div>
  </div>
</template>

<script>
import { pageEvents, productEvents } from '@swimm/shared';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useSignout } from '@/common/composables/signout';
import { getDeviceDetails, isMobile } from '@swimm/shared';
import { CALENDLY_DEMO_LINK } from '@/config';

export default {
  props: {
    gitHosting: { type: String, required: true },
  },
  setup() {
    const analytics = useAnalytics();
    const { signout } = useSignout();

    return { analytics, signout };
  },
  computed: {
    isMobileDevice() {
      return isMobile();
    },
  },
  created() {
    const device = getDeviceDetails();
    const pageEvent = this.isMobileDevice
      ? pageEvents.VIEW_GIT_PROVIDER_NOT_SUPPORTED_ON_MOBILE
      : pageEvents.VIEW_GIT_PROVIDER_NOT_SUPPORTED;

    const analyticsContext = {
      Origin: 'Sign Up',
      Type: 'Page',
      Provider: this.gitHosting,
    };
    if (this.isMobileDevice) {
      analyticsContext;
      analyticsContext['Mobile Vendor'] = device.vendor;
      analyticsContext['Mobile Model'] = device.model;
      analyticsContext.OS = `${device.osName} ${device.osVersion}`;
    }
    this.analytics.pageVisit(pageEvent, analyticsContext);
  },
  methods: {
    async logout() {
      await this.signout({ redirectToLogin: false });
      window.location.href = 'https://swimm.io';
    },
    goToDemo(event) {
      const openInNewTab = Boolean(event.metaKey || event.ctrlKey);
      window.open(CALENDLY_DEMO_LINK, openInNewTab ? '_blank' : '_self');
      this.analytics.track(productEvents.REQUEST_A_MEETING_WITH_SALES_OR_CS, {
        Context: 'Version Control Not Supported',
      });
    },
    changeGitHostingPlatform() {
      // GitHosting
      this.analytics.track(productEvents.CLICKED_CHANGE_GIT_PLATFORM, {
        'Existing Git Hosting Platform': this.gitHosting,
      });
      this.$router.push('/workspaces/create').catch(() => {
        return;
      });
    },
  },
};
</script>

<style scoped>
.git-hosting-bg {
  background: var(--color-onboarding-gradient-light);
  height: 100vh;

  @media screen and (max-width: 800px) {
    background: var(--color-bg);
  }

  [data-theme='dark'] & {
    background: var(--color-onboarding-gradient-dark);
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background-color: var(--color-bg);
    flex-direction: column;
    border-radius: 8px;
    box-shadow: var(--box-shadow-small);

    .logo {
      border-radius: 8px;
    }

    .catchphrase {
      margin: 30px 0 16px;
      text-align: center;

      .title {
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
      }

      .registration-prase {
        max-width: 75%;
        margin: var(--space-base) auto 0;
        font-size: var(--body-L);
      }
    }

    .actions {
      margin: 16px 0;

      :last-child {
        margin-left: 8px;
      }
    }

    .action-link {
      margin-top: 20px;
    }

    @media screen and (max-width: 800px) {
      height: 100%;
      padding: 8px;
      border-radius: 0;

      .actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;

        .button {
          width: 100%;
          margin-left: 0;
          margin-top: var(--space-base);
        }
      }
    }
  }
}
</style>
