<template>
  <TopMenuLayout>
    <template #topBar>
      <TopBar :show-route-back="false" />
    </template>
  </TopMenuLayout>
</template>

<script>
import TopBar from '@/common/components/TopBar/TopBar.vue';
import TopMenuLayout from '@/common/layouts/TopMenuLayout.vue';
import swal from 'sweetalert';
import { DOCS_SITE_URL } from '@/config';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';

export default {
  components: { TopBar, TopMenuLayout },
  setup() {
    const analytics = useAnalytics();
    return {
      analytics,
    };
  },
  async created() {
    const swalContent = document.createElement('div');
    swalContent.innerHTML =
      "We cannot access Swimm's servers - this is probably caused by a weak internet connection, a VPN, firewall or some browser extension.\n" +
      `Try to refresh the page - if it doesn't work, please go to our <a target='_blank' href='${DOCS_SITE_URL}/troubleshooting/#connectivity-issues'>troubleshooting</a> page to debug this problem.`;

    this.analytics.track(productEvents.SHOWN_NO_ACCESS_MESSAGE);

    await swal({
      title: 'Your browser cannot access Swimm',
      content: { element: swalContent },
      buttons: {
        confirm: { visible: false },
        cancel: { text: 'Refresh page', visible: true },
      },
      closeOnClickOutside: false,
    });

    await this.$router.replace({ path: '/' }); // route to /
    this.$router.go(); // make sure it refreshes
  },
};
</script>
