import { DUMMY_REPO_ID, config, getLoggerNew, productEvents, state } from '@swimm/shared';
import type { RepoStateData } from '@swimm/shared';
import { reportCustomProductAlert } from '@/common/utils/product-events-reporters';

const logger = getLoggerNew(__modulename);

export async function clearUserFromLocalState(removeDraftsOnLogout, email) {
  try {
    await state.deleteKey({ key: 'uid', isGlobalKey: true });
    await state.deleteKey({ key: 'nickname', isGlobalKey: true });
    await state.deleteKey({ key: 'git_tokens', isGlobalKey: true });
    await state.deleteKey({ key: 'oidc_data', isGlobalKey: true });
    await state.deleteKey({ key: 'latest_user_workspace_id', isGlobalKey: true });
    await state.deleteKey({ key: 'theme', isGlobalKey: true });
    await state.deleteKey({ key: 'salt', isGlobalKey: true });

    if (removeDraftsOnLogout) {
      await clearUserDraftsInRepos();
      await state.deleteKey({ key: 'repos', isGlobalKey: true });
      reportCustomProductAlert(productEvents.DRAFTS_DELETED, { Email: email });
    } else {
      await keepFilteredReposStateKeys();
    }
    return config.SUCCESS_RETURN_CODE;
  } catch (err) {
    logger.error({ err }, `Could not clear user from local state: ${err}`);
    return config.ERROR_RETURN_CODE;
  }
}

async function clearUserDraftsInRepos() {
  const repos = await state.get({ key: 'repos', defaultValue: {} });
  for (const repoKey of Object.keys(repos)) {
    await state.deleteKey({ key: `drafts`, repoId: repoKey });
  }
}

/**
 * If requested, before signing out - make sure to keep specific keys in state.
 */
async function keepFilteredReposStateKeys() {
  const repos = await state.get({ key: 'repos', defaultValue: {} });
  // Filter out repo keys that are not needed to be saved.
  for (const repoId in repos) {
    const repo = repos[repoId];
    for (const repoField in repo) {
      if (config.REPO_STATE_KEYS_TO_KEEP.includes(repoField) || repoId === DUMMY_REPO_ID) {
        continue;
      }
      delete repo[repoField];
    }
  }

  await state.set({ key: `repos`, value: repos });
}

export function getRepoStateDataToKeep(repoFromState: RepoStateData) {
  const dataToKeep = {};
  if (repoFromState) {
    for (const key of config.REPO_STATE_KEYS_TO_KEEP) {
      if (repoFromState[key]) {
        dataToKeep[key] = repoFromState[key];
      }
    }
  }
  return dataToKeep;
}
