export function pathBasename(path: string) {
  return path.split(/[\\/]/g).pop();
}

export const getFilesFromTreeFilteredByExtension = function ({ tree, filterExtension }) {
  const filesFromTree = getFilesFromTree(tree);
  return filesFromTree?.filter((file) => {
    return file.path.toLowerCase().endsWith(filterExtension.toLowerCase());
  });
};

export const getFilesFromTree = function (tree) {
  if (!tree.children) {
    return undefined;
  }
  const files = [];
  tree.children.forEach((item) => {
    if (item.type === 'file') {
      files.push(item);
    } else if (item.type === 'directory') {
      if (item.children) {
        files.push(...getFilesFromTree(item));
      }
    }
  });

  return files;
};
