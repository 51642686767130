// @ts-strict

import {
  ApplicabilityStatus,
  DynamicChangeLine,
  DynamicHunkContainer,
  HunkChangeLineType,
  SmartElementType,
  SmartSymbol,
  SwmSymbolGenericText,
  SwmSymbolType,
  SymbolPlugin,
  config,
  escapeRegExpCharacters,
  getLogger,
  gitwrapper,
} from '@swimm/shared';
import type { SmartElementWithApplicability, SwmSymbol, Token } from '@swimm/shared';
import { isEmpty, maxBy } from 'lodash-es';
import { getMatchingHunkCandidates, scoreCandidate } from '../autosync-without-blob-sha';
import { autosyncWithinLine } from '../code-clips';
import { getSmartElementWithApplicability } from '../swimmagic-common';

const logger = getLogger("packages/swimmagic/src/symbol-plugins/symbol-generic-text.ts");

export class SymbolGenericText implements SymbolPlugin {
  symbols: Record<string, Record<string, Record<string, Token[]>>> = {}; // repoId -> path -> branch -> tokens

  static isSymbolGenericText(symbol: SwmSymbol): symbol is SwmSymbolGenericText {
    return symbol.type === SwmSymbolType.GENERIC_TEXT;
  }

  static isSmartSymbolGenericText(symbol: SmartSymbol): symbol is Token {
    return symbol.type === SmartElementType.TOKEN;
  }

  constructor(symbols: SmartSymbol[]) {
    for (const symbol of symbols) {
      if (SymbolGenericText.isSmartSymbolGenericText(symbol)) {
        if (!this.symbols[symbol.gitInfo.repoId]) {
          this.symbols[symbol.gitInfo.repoId] = {};
        }
        if (!this.symbols[symbol.gitInfo.repoId][symbol.filePath]) {
          this.symbols[symbol.gitInfo.repoId][symbol.filePath] = {};
        }
        if (!this.symbols[symbol.gitInfo.repoId][symbol.filePath][symbol.gitInfo.branch]) {
          this.symbols[symbol.gitInfo.repoId][symbol.filePath][symbol.gitInfo.branch] = [];
        }
        this.symbols[symbol.gitInfo.repoId][symbol.filePath][symbol.gitInfo.branch].push(symbol);
      }
    }
  }

  private getAllSymbolsInPathWithOutdatedApplicability(
    repoId: string,
    path: string
  ): SmartElementWithApplicability<Token>[] {
    const tokensWithApplicability: SmartElementWithApplicability<Token>[] = [];

    for (const branch of Object.keys(this.symbols[repoId][path])) {
      for (const token of this.symbols[repoId][path][branch]) {
        tokensWithApplicability.push(
          getSmartElementWithApplicability<Token>({
            element: token,
            applicabilityStatus: ApplicabilityStatus.Outdated,
          })
        );
      }
    }
    return tokensWithApplicability;
  }

  public async updateSymbols(): Promise<{
    isApplicable: boolean;
    symbolsWithApplicability: SmartElementWithApplicability<SmartSymbol>[];
  }> {
    let isApplicable = true;
    const symbolsWithApplicability: SmartElementWithApplicability<Token>[] = [];

    const updateSymbolPromises: Promise<void>[] = [];

    for (const symbolRepoId of Object.keys(this.symbols)) {
      for (const path of Object.keys(this.symbols[symbolRepoId])) {
        if (!path) {
          symbolsWithApplicability.push(...this.getAllSymbolsInPathWithOutdatedApplicability(symbolRepoId, path));
          isApplicable = false;
          logger.error(`Tried to update ${this.symbols[path].length} symbols that have no path connected to them.`);
          continue;
        }

        for (const branch of Object.keys(this.symbols[symbolRepoId][path])) {
          const syncByPathPromisified = async () => {
            // NOTE: it's currently unspecified whether all symbols with the same `path` should also have the same `fileBlob`...
            //  We can assume that the answer is "yes", although there might be cases where one symbol will update while the other won't (one is autosyncable, the other verified).
            //  This case though is fine for us, as we know that even the symbols that won't update (verified) are still good at the given revision.
            const currentNameResult = await gitwrapper.getCurrentName({
              oldFilePath: path,
              repoId: symbolRepoId,
              destCommit: branch,
            });
            if (currentNameResult.code === config.ERROR_RETURN_CODE || !currentNameResult.exists) {
              // If there was a problem with the current file path, mark all symbols in it as outdated.
              isApplicable = false;
              symbolsWithApplicability.push(...this.getAllSymbolsInPathWithOutdatedApplicability(symbolRepoId, path));
              return;
            }

            const symbolToLinesData: Record<string, DynamicChangeLine | undefined> = {};

            // Generate the best match for every line
            for (const symbol of this.symbols[symbolRepoId][path][branch]) {
              const bestMatch = await getBestMatchedLine(symbol, currentNameResult.currentName);
              symbolToLinesData[symbol.id] = bestMatch ? bestMatch.changes[0] : undefined;
            }

            // In order to use the symbol's line number as in index, we need to filter only the change lines that contain fileA in them.
            for (const symbol of this.symbols[symbolRepoId][path][branch]) {
              try {
                const result = symbolToLinesData[symbol.id];
                if (!result) {
                  symbolsWithApplicability.push(
                    getSmartElementWithApplicability<Token>({
                      element: symbol,
                      applicabilityStatus: ApplicabilityStatus.Outdated,
                    })
                  );
                  isApplicable = false;
                  continue;
                }
                const { fileA, fileB } = result;
                const lineAData = fileA.data;
                const lineBData = fileB ? fileB.data : '';
                const autosyncWithinLineResult = autosyncWithinLine(
                  lineAData,
                  lineBData,
                  symbol.wordIndex.start,
                  symbol.wordIndex.end,
                  fileA.actualLineNumber,
                  fileB.actualLineNumber
                );

                if (autosyncWithinLineResult.verdict === 'outdated') {
                  symbolsWithApplicability.push(
                    getSmartElementWithApplicability<Token>({
                      element: symbol,
                      applicabilityStatus: ApplicabilityStatus.Outdated,
                    })
                  );
                  isApplicable = false;
                  continue;
                }

                symbolsWithApplicability.push(
                  getSmartElementWithApplicability<Token>({
                    element: symbol,
                    applicabilityStatus: autosyncWithinLineResult.verdict,
                    newInfo: {
                      ...symbol,
                      wordIndex: {
                        start: autosyncWithinLineResult.meatStartIndexInB,
                        end: autosyncWithinLineResult.meatEndIndexInB,
                      },
                      symbolText: autosyncWithinLineResult.newWord || symbol.symbolText,
                      lineContent: lineBData,
                      lineNumber: fileB.actualLineNumber,
                      filePath: currentNameResult.currentName,
                    },
                  })
                );
              } catch (error: unknown) {
                logger.error(`Failed update symbol ${symbol.id}: ${error}`);
                symbolsWithApplicability.push(
                  getSmartElementWithApplicability<Token>({
                    element: symbol,
                    applicabilityStatus: ApplicabilityStatus.Outdated,
                  })
                );
                isApplicable = false;
              }
            }
          };
          updateSymbolPromises.push(syncByPathPromisified());
        }
      }
    }
    await Promise.all(updateSymbolPromises);
    return { isApplicable, symbolsWithApplicability };
  }

  public async verifySymbols(): Promise<boolean> {
    const verifySymbolPromises: Promise<boolean>[] = [];

    for (const symbolRepoId of Object.keys(this.symbols)) {
      for (const path of Object.keys(this.symbols[symbolRepoId])) {
        for (const branch of Object.keys(this.symbols[symbolRepoId][path])) {
          const verifyByPathPromisified = async () => {
            // NOTE: it's currently unspecified whether all symbols with the same `path` should also have the same `fileBlob`...
            //  We can assume that the answer is "yes", although there might be cases where one symbol will update while the other won't (one is autosyncable, the other verified).
            //  This case though is fine for us, as we know that even the symbols that won't update (verified) are still good at the given revision.
            const currentNameResult = await gitwrapper.getCurrentName({
              oldFilePath: path,
              repoId: symbolRepoId,
              destCommit: branch,
            });
            if (currentNameResult.code === config.ERROR_RETURN_CODE || !currentNameResult.exists) {
              // If there was a problem with the current file path, symbols are outdated.
              return false;
            }

            if (currentNameResult.isRenamed) {
              return false;
            }

            const currentFileData: string = await gitwrapper.getFileContentFromRevision({
              filePath: currentNameResult.currentName,
              repoId: symbolRepoId,
              revision: branch,
            });

            /**
             * We remove non-newline whitespace characters in order to align the result of `verifySymbols`
             *  with the result of `updateSymbols`, where whitespace-only changes are still considered `verified`.
             */
            const fileDataWithoutWhitespace = currentFileData.replace(/[^\S\r\n]/g, '');

            // Make the symbols array unique by the lineNumber, as we don't want to include the same line in our regex over and over
            const uniqueSymbols = this.symbols[symbolRepoId][path][branch].filter(
              (sym, idx, symArr) => symArr.findIndex((val) => val.lineNumber === sym.lineNumber) === idx
            );
            for (const symbol of uniqueSymbols) {
              // Instead of using `git apply` to fabricate some patch, we can just check using our own regex if all the lines exist as they should.
              const lineDataWithoutWhitespace = symbol.lineContent.replace(/\s+/g, '');
              const escapedRawString = escapeRegExpCharacters(lineDataWithoutWhitespace);
              const lineDataRegex = new RegExp(`^${escapedRawString}$`, 'm');
              const matchingResult = lineDataRegex.test(fileDataWithoutWhitespace);
              if (!matchingResult) {
                return false;
              }
            }
            return true;
          };
          verifySymbolPromises.push(verifyByPathPromisified());
        }
      }
    }

    return (await Promise.all(verifySymbolPromises)).every((result) => result);
  }
}

export async function getBestMatchedLine(token: Token, filePath: string) {
  const dynamicSymbolLineHunk: DynamicHunkContainer = {
    gitHunkMetadata: {
      lineNumbers: {
        fileA: { linesCount: 1, startLine: token.lineNumber },
        fileB: { linesCount: 0, startLine: token.lineNumber },
      },
    },
    changes: [
      {
        changeType: HunkChangeLineType.Deleted,
        fileA: {
          actualLineNumber: token.lineNumber,
          data: token.lineContent,
        },
      },
    ],
    repoId: token.gitInfo.repoId,
  };
  // In case of a single line - this function just returns a hunk containing the single line and its best matching counter-part
  const lineCandidates = await getMatchingHunkCandidates({
    originalDynamicHunk: dynamicSymbolLineHunk,
    filePath: filePath,
    repoId: token.gitInfo.repoId,
    revision: token.gitInfo.branch,
    maxCandidatesWithoutPerfectMatch: 1,
  });
  if (isEmpty(lineCandidates)) {
    return undefined;
  }

  const lineCandidatesWithScores = lineCandidates.map((hunk) => {
    const candidateScore = scoreCandidate(hunk);
    return { ...candidateScore, hunk };
  });
  const chosenCandidate = maxBy(
    lineCandidatesWithScores,
    (hunk: (typeof lineCandidatesWithScores)[number]) => hunk.diffScore
  );

  return chosenCandidate?.hunk;
}
