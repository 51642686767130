<template>
  <TopMenuLayout :loading="loading" :show-get-started="false">
    <template #content>
      <div class="edit-workspace-page">
        <div class="form-wrapper">
          <div class="edit-workspace-form content-padding-wrapper">
            <div class="workspace-logo-section">
              <div class="title-headline">Create new workspace</div>
              <SwAvatar
                :text="shownName || ' '"
                :src="logo"
                square
                size="huge"
                hide-tooltip
                @click="uploadLogo"
                class="avatar"
              />
              <div class="image-button-container">
                <FilePicker ref="imageInput">
                  <Action
                    :loading="uploadingLogo"
                    class="upload-button subtitle-S"
                    secondary
                    size="small"
                    @click="uploadLogo"
                  >
                    Upload image
                  </Action>
                </FilePicker>
              </div>
            </div>
            <TextField
              v-model.trim="name"
              label="Workspace name"
              placeholder="Workspace name"
              focus-first
              :maxlength="100"
              data-testid="workspace-name-input"
              class="workspace-name"
              @blur="shownName = name"
            />
            <BaseLabel>Workspace description</BaseLabel>
            <TextFieldMultiline v-model="description" class="field" data-testid="workspace-description" />
            <Divider class="divider-padding" />
            <div class="button-section">
              <div v-tooltip="saveButtonTooltip">
                <Action
                  class="small create"
                  data-testid="save-button"
                  :loading="saving"
                  :disabled="name === ''"
                  @click="save"
                  >Create workspace</Action
                >
              </div>
              <Action class="small cancel" secondary @click="routeBack">Cancel</Action>
            </div>
          </div>
        </div>
      </div>
    </template>
  </TopMenuLayout>
</template>

<script>
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { FilePicker, uploadFiles } from '@swimm/editor';
import { useAnalytics } from '@/common/composables/useAnalytics';
import TopMenuLayout from '@/common/layouts/TopMenuLayout.vue';
import { GitProviderName, Licenses, eventLogger, gitProviderUtils, pageEvents, productEvents } from '@swimm/shared';
import { mapActions } from 'vuex';
import swal from 'sweetalert';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import {
  updateSalesforceWorkspaceCreation,
  updateWorkspaceUserInSalesforce,
} from '@/common/utils/salesforce/salesforce';
import TextFieldMultiline from '@/common/components/atoms/TextFieldMultiline.vue';
import { BaseLabel, Divider, SwAvatar, TextField } from '@swimm/ui';
import { useStigg } from '@/common/composables/useStigg';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { storageWrapper } from '@/modules/core/services/storage-wrapper';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';

import { isAutomation } from '@/config';

const LOGO_UPLOAD_DIRECTORY_PATH = 'workspaces/logos';

export default {
  components: { FilePicker, TextField, BaseLabel, TextFieldMultiline, TopMenuLayout, Divider, SwAvatar },
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const { logEvent } = useSwimmEventLogs();
    const { initStiggForCustomer } = useStigg();
    const { enterpriseGitUrl, provider } = storeToRefs(useWorkspaceStore());

    return { user, analytics, logEvent, initStiggForCustomer, enterpriseGitUrl, provider };
  },
  data() {
    return {
      name: '',
      shownName: '',
      logo: '',
      description: '',
      uploadingLogo: false,
      loading: false,
      saving: false,
    };
  },
  computed: {
    customLogoText() {
      return this.name ? this.name[0] : 'C';
    },
    saveButtonTooltip() {
      if (!this.name || this.name.length === 0) {
        return `Workspace name is required`;
      }
      return '';
    },
  },
  async created() {
    this.analytics.pageVisit(pageEvents.NEW_WORKSPACE);
  },
  methods: {
    ...mapActions('database', ['saveWorkspace']),
    async save() {
      if (this.saving) {
        return;
      }
      this.$logger.info('Saving new workspace', { module: 'NewWorkspacePage' });
      this.saving = true;

      // Use the provider of the workspace we came from if we know it
      const newWorkspaceProvider = this.provider ?? GitProviderName.GitHub;

      const enterpriseData = {};
      if (!gitProviderUtils.isProviderCloud(newWorkspaceProvider)) {
        if (isAutomation) {
          // For the e2e to be able to create workspaces and work
          enterpriseData.enterprise_url = this.enterpriseGitUrl;
        } else {
          enterpriseData.pending_manual_setup = true;
        }
      }

      const newWorkspace = {
        name: this.name,
        provider: newWorkspaceProvider,
        description: this.description,
        logo: this.logo,
        ...enterpriseData,
      };

      try {
        const workspaceId = await this.saveWorkspace({ resource: newWorkspace, user: this.user });
        const workspaceName = this.name;
        await CloudFunctions.addWorkspaceToPlanManager({ workspaceId, workspaceName, license: Licenses.FREE });
        await this.initStiggForCustomer(workspaceId);
        this.reportWorkspaceCreated(newWorkspace.name, workspaceId);
        await this.$router.push(`/workspaces/${workspaceId}`);
      } catch (err) {
        this.$logger.error(`could not save workspace: ${err}`, { service: 'edit-workspace' });
        await swal({ title: 'Failed to save the workspace.', content: SWAL_CONTACT_US_CONTENT() });
      }
      this.saving = false;
    },
    async uploadLogo() {
      if (this.uploadingLogo) {
        return;
      }
      this.uploadingLogo = true;
      const imageFiles = await this.$refs.imageInput.openPicker();
      if (!imageFiles.length) {
        this.cancelUpload();
        return;
      }

      uploadFiles({
        directory: LOGO_UPLOAD_DIRECTORY_PATH,
        files: imageFiles,
        success: this.successUpload,
        failure: this.failedUpload,
        storageWrapper,
      });
    },
    routeBack() {
      this.$router.back();
    },
    successUpload(url) {
      this.logo = url;
      this.uploadingLogo = false;
    },
    failedUpload() {
      swal(`Failed to upload the image file.`);
      this.uploadingLogo = false;
    },
    cancelUpload() {
      this.uploadingLogo = false;
    },
    async reportWorkspaceCreated(workspaceName, workspaceId) {
      await CloudFunctions.setHostedDomainField({ workspaceId });
      await updateSalesforceWorkspaceCreation({ name: workspaceName, id: workspaceId });
      await updateWorkspaceUserInSalesforce({ workspaceId: workspaceId, uid: this.user.id });

      const traits = {
        Name: workspaceName,
        Plan: Licenses.FREE,
        'Creator User ID': this.user.uid,
        'Creator Email': this.user.email,
        'Creation Date': new Date().toISOString(),
      };
      await this.analytics.cloudWorkspaceGroup({
        workspaceId,
        traits,
        userId: this.user.uid,
      });
      const payload = {
        Origin: 'Create workspace page',
        'Origin URL': this.$route.fullPath,
        Context: 'In-App',
        'Git Provider': 'Not Selected',
        'Workspace ID': workspaceId,
        'Workspace Name': workspaceName,
        'Creator Email': this.user.email,
        'Creator Name': this.user.nickname,
      };
      await this.analytics.cloudTrack({
        identity: this.user.uid,
        event: productEvents.WORKSPACE_CREATED,
        payload,
      });
      this.analytics.track(productEvents.WORKSPACE_CREATED_MARKETING, payload);
      this.analytics.workspaceChange({ workspaceId, workspaceName });

      this.logEvent(eventLogger.SWIMM_EVENTS.WORKSPACE_CREATED, {
        srcId: workspaceId,
        srcName: workspaceName,
        workspaceId,
        workspaceName: workspaceName,
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.edit-workspace-page {
  display: flex;
  overflow: hidden;
  height: 100%;
  flex-direction: column;

  .form-wrapper {
    overflow: auto;
    flex: 1;
    width: 100%;

    .edit-workspace-form {
      margin: auto;
      margin-top: 60px;
      max-width: var(--narrow-content-width);

      .workspace-logo-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title-headline {
          padding-bottom: 37px;
          font-size: var(--headline1);
          font-family: var(--fontfamily-main);
          font-weight: 700;
          color: var(--text-color-primary);
          line-height: 48px;
        }

        .avatar {
          cursor: pointer;
        }

        .image-button-container {
          margin-top: var(--space-md);
          margin-bottom: 64px;
        }
      }

      .workspace-name {
        margin-bottom: var(--space-base);
      }

      .divider-padding {
        margin-top: 32px;
        margin-bottom: 32px;
      }

      .button-section {
        display: flex;
        margin-bottom: 32px;

        .create {
          margin-right: 8px;
          width: 145px;
        }

        .cancel {
          width: 94px;
        }
      }
    }
  }
}
</style>
