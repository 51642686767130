<script setup lang="ts">
import { useFoldersStore } from '@/modules/folders/store/folders';
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { ErrorBox, TextField } from '@swimm/ui';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';
import { useRoute } from 'vue-router';
import type { Folder } from '../types';

const props = defineProps({
  repoId: { type: String, required: true },
  folderId: { type: String, default: '' },
  folderName: { type: String, default: '' },
  parentId: { type: String, default: '' },
  fontClass: { type: String, default: '' },
  showIcon: { type: Boolean, default: false },
  showErrorBelow: { type: Boolean, default: false },
});

const emit = defineEmits(['change', 'cancel']);

const analytics = useAnalytics();
const route = useRoute();
const foldersStore = useFoldersStore();
const { addFolder, isFolderNameExists, updateFolder, getFolderPath } = foldersStore;
const { foldersByRepo, currentFolderIdByRepo } = storeToRefs(foldersStore);
const currentFolder = computed<Folder>(
  () => foldersByRepo.value[props.repoId]?.[currentFolderIdByRepo.value[props.repoId]]
);
const currentFolderName = ref(props.folderName);
const errorMessage = ref('');
const isSaving = ref(false);
const isCancelled = ref(false);

const isNewFolderNameAlreadyExists = computed(
  () =>
    currentFolderName.value?.trim() &&
    isFolderNameExists(currentFolderName.value?.trim(), parentFolderId.value, props.repoId)
);

const isFolderNameChanged = computed(() => currentFolderName.value?.trim() !== props.folderName);

const parentFolderId = computed(() => (props.parentId ? props.parentId : currentFolder.value.id));

// Workaround for showing TextFiled red but without an error message.
const inputErrorWorkaround = computed(() => (errorMessage.value ? ' ' : ''));

watch([isNewFolderNameAlreadyExists, isFolderNameChanged], ([nameAlreadyUsed, nameChanged]) => {
  errorMessage.value = nameAlreadyUsed && nameChanged && !isSaving.value ? 'Folder name exists. Try another name.' : '';
  if (nameAlreadyUsed && nameChanged && !isSaving.value) {
    errorMessage.value = 'Folder name exists. Try another name.';
    analytics.track(
      productEvents.SHOWN_FOLDER_NAME_CONFLICT,
      {
        'Parent Folder ID': parentFolderId.value,
        'Parent Folder Path': getFolderPath(parentFolderId.value, props.repoId),
        'Folder Name': currentFolderName.value,
        'Page Name': route.name,
      },
      { addRouteParams: true }
    );
  } else {
    errorMessage.value = '';
  }
});

async function handleNewFolderNameKeyUp(event: KeyboardEvent) {
  if (event.key === 'Enter') {
    // Don't complete if folder name is already used.
    if (!isFolderNameChanged.value || !isNewFolderNameAlreadyExists.value) {
      await onEditComplete();
    }
  } else if (event.key === 'Escape') {
    cancel();
  }
}

function cancel() {
  if (!props.folderId) {
    analytics.track(
      productEvents.CANCELED_FOLDER_CREATION,
      {
        'Parent Folder ID': parentFolderId.value,
        'Parent Folder Path': getFolderPath(parentFolderId.value, props.repoId),
        'Page Name': route.name,
      },
      { addRouteParams: true }
    );
  }
  isCancelled.value = true;
  emit('cancel');
}

async function onEditComplete() {
  if (isSaving.value || isCancelled.value) {
    // To prevent calling it twice when clicking enter or when canceling with Esc.
    return;
  }
  if (isNewFolderNameAlreadyExists.value && isFolderNameChanged.value) {
    // Typed folder name already used - don't do anything.
    return;
  }
  const folderNameToUse = currentFolderName.value.trim();
  errorMessage.value = '';
  if (!folderNameToUse || !isFolderNameChanged.value || isNewFolderNameAlreadyExists.value) {
    // Empty folder name or no changes or name alreay in use - exit edit mode.
    cancel();
    return;
  }
  try {
    isSaving.value = true;
    let folderID;
    if (!props.folderId) {
      // New folder
      folderID = await addFolder({ name: folderNameToUse, repoId: props.repoId, parentFolderId: parentFolderId.value });
    } else {
      // Rename existing folder
      await updateFolder(props.folderId, props.repoId, { name: folderNameToUse });
      folderID = props.folderId;
    }
    emit('change', parentFolderId.value, folderID);
  } catch (error) {
    errorMessage.value = 'Failed to save folder, please refresh and try again or contact our support team.';
    isSaving.value = false;
  }
}
</script>

<template>
  <div class="folder-name-edit-container" :class="{ column: showErrorBelow }">
    <div class="folder-name-edit">
      <Icon v-if="showIcon" no-padding class="folder-icon" name="folder" />
      <TextField
        v-model="currentFolderName"
        data-testid="folder-name"
        :class="['name-input', fontClass]"
        focus-first
        placeholder="Enter folder name"
        @blur="onEditComplete"
        :error="inputErrorWorkaround"
        @key-up="handleNewFolderNameKeyUp"
      >
        <Loader v-if="isSaving" secondary class="loader" />
      </TextField>
    </div>
    <ErrorBox v-if="errorMessage" class="error-message" data-testid="folder-name-error">{{ errorMessage }}</ErrorBox>
  </div>
</template>

<style scoped lang="postcss">
.folder-name-edit-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  &.column {
    flex-direction: column;
  }

  .folder-name-edit {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 400px;
    flex: 1;

    .loader.loader {
      --loader-size: 24px;
    }

    .folder-icon {
      margin-right: var(--space-xs);
    }
  }

  .error-message {
    margin-left: var(--space-base);
  }
}
</style>
