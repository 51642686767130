export class SwimmParsingError extends Error {}

export class SwmdFileVersionError extends SwimmParsingError {
  problematicVersion: string;

  constructor(msg: string, problematicVersion: string) {
    super(msg);
    this.problematicVersion = problematicVersion;
  }
}

export class GitProviderRateLimitError extends Error {
  constructor(providerDisplayName: string) {
    super(`Your ${providerDisplayName} account has exceeded the ${providerDisplayName} API rate limit`);
  }
}
