import { type LineMatcherOptionalConfig, matchChangeLines } from './similarity-line-matcher';
import { DynamicChangeLine, DynamicHunkContainer, HunkChangeLineType } from './types';

export function createMatchedHunkBasedOnSimilarity({
  fileContentA,
  fileContentB,
  repoId,
  lineMatcherConfig,
}: {
  fileContentA: string;
  fileContentB: string;
  repoId: string;
  lineMatcherConfig?: LineMatcherOptionalConfig;
}): DynamicHunkContainer {
  const changes = createMatchedDiffBasedOnSimilarity({
    stringA: fileContentA,
    stringB: fileContentB,
    lineMatcherConfig,
  });
  const fileALength = fileContentA.split('\n').length;
  const fileBLength = fileContentB.split('\n').length;
  return {
    changes,
    repoId,
    gitHunkMetadata: {
      lineNumbers: {
        fileA: { startLine: 1, linesCount: fileALength },
        fileB: { startLine: 1, linesCount: fileBLength },
      },
    },
  };
}

export function createMatchedDiffBasedOnSimilarity({
  stringA,
  stringB,
  lineMatcherConfig,
}: {
  stringA: string;
  stringB: string;
  lineMatcherConfig?: LineMatcherOptionalConfig;
}): DynamicChangeLine[] {
  const fileALines = stringA.split('\n');
  const fileBLines = stringB.split('\n');
  const fileADynamicLines = turnStringArrayToDynamicLines(fileALines, HunkChangeLineType.Deleted);
  const fileBDynamicLines = turnStringArrayToDynamicLines(fileBLines, HunkChangeLineType.Added);
  return matchChangeLines({ changeLines: fileADynamicLines.concat(fileBDynamicLines), lineMatcherConfig });
}

function turnStringArrayToDynamicLines(
  dataLines: string[],
  changeType: HunkChangeLineType.Added | HunkChangeLineType.Deleted
): DynamicChangeLine[] {
  return dataLines.map((line, i) => {
    const fileData = {
      actualLineNumber: i + 1,
      data: line,
    } as const;
    const changeFile = changeType === HunkChangeLineType.Added ? 'fileB' : 'fileA';
    return { changeType, [changeFile]: fileData };
  });
}
