<template>
  <VDropdown
    class="settings-popper"
    theme="menu-no-arrow"
    placement="bottom-start"
    data-testid="repo-settings-dropdown"
    :triggers="[]"
    :delay="0"
    :shown="shouldShowSettingsMenu"
    @show="$emit('menu-opened')"
    @hide="
      () => {
        $emit('menu-closed');
        shouldShowSettingsMenu = false;
      }
    "
  >
    <IconButton class="icon" name="more" no-padding data-testid="repo-settings-option" @click.stop="showSettingsMenu" />
    <template #popper>
      <EllipsisOption
        v-if="shouldShowEditRepoOpts"
        name="Integrations & settings"
        :handler="handleOpenRepoSettingsClick"
        data-testid="repo-settings-option"
        v-close-popper
      />
      <EllipsisOption
        :name="`${isFavorite ? 'Remove from' : 'Add to'} favorites`"
        :handler="handleToggleFavoriteClick"
        data-testid="repo-settings-option"
        v-close-popper
      />
      <EllipsisOption :name="`Open repo on ${repoProviderDisplayName}`" :handler="handleOpenRepoClick" />
      <EllipsisOption
        v-if="shouldShowEditRepoOpts && !shouldHideDeleteOpt"
        name="Remove from workspace"
        :handler="handleRemoveRepoClick"
      />
    </template>
  </VDropdown>
</template>

<script>
import EllipsisOption from '@/common/components/atoms/EllipsisOption.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { GitProviderName, gitwrapper, isRepoIdDummyRepo, productEvents } from '@swimm/shared';
import { mapGetters } from 'vuex';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { IconButton } from '@swimm/ui';

export default {
  components: { EllipsisOption, IconButton },
  props: {
    repoProvider: { type: String, required: true },
    repoUrl: { type: String, required: true },
    repoId: { type: String, required: true },
    workspaceId: { type: String, required: true },
    repoSelected: { type: Boolean, default: false },
    showOpenRepoSettings: { type: Boolean, default: true },
    isFavorite: { type: Boolean, default: false },
    favoriteAndRecentSection: { type: Boolean, default: false },
  },
  emits: ['open-settings', 'remove', 'menu-opened', 'menu-closed', 'add-to-favorites', 'remove-from-favorites'],
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const shouldShowSettingsMenu = ref(false);

    function showSettingsMenu() {
      shouldShowSettingsMenu.value = true;
    }

    return { user, analytics, GitProviderName, shouldShowSettingsMenu, showSettingsMenu };
  },
  computed: {
    ...mapGetters('database', [
      'db_isWorkspaceAdmin',
      'db_isPublicRepo',
      'db_isRepoLifeguard',
      'db_getWorkspaceRepoIds',
    ]),
    repoProviderDisplayName() {
      return gitwrapper.getDisplayName(this.repoProvider);
    },
    shouldShowEditRepoOpts() {
      return (
        this.isWorkspaceAdmin &&
        (!this.db_isPublicRepo(this.repoId) || this.db_isRepoLifeguard(this.repoId, this.user.uid))
      );
    },
    shouldHideDeleteOpt() {
      if (isRepoIdDummyRepo(this.repoId) && this.db_getWorkspaceRepoIds(this.workspaceId).length === 1) {
        return true;
      }
      return false;
    },
    isWorkspaceAdmin() {
      return this.db_isWorkspaceAdmin(this.workspaceId, this.user.uid);
    },
  },
  methods: {
    handleOpenRepoSettingsClick() {
      this.$emit('open-settings', { repoId: this.repoId, origin: 'Repo Sidebar Settings' });
      this.reportSettingsClicked('Integrations & Settings');
    },
    handleOpenRepoClick() {
      this.navigateToRepoWebsite();
      this.reportSettingsClicked(`Open Repo on ${this.repoProviderDisplayName}`);
    },
    handleRemoveRepoClick() {
      this.$emit('remove');
      this.reportSettingsClicked('Remove Repo from Workspace');
    },
    handleToggleFavoriteClick() {
      this.$emit(this.isFavorite ? 'remove-from-favorites' : 'add-to-favorites');
      this.reportSettingsClicked(this.isFavorite ? 'Remove from favorites' : 'Add to favorites');
    },
    navigateToRepoWebsite() {
      window.open(this.repoUrl);
    },
    reportSettingsClicked(selection) {
      this.analytics.track(productEvents.CLICKED_REPO_MENU_OPTION, {
        'Workspace ID': this.workspaceId,
        'Repo ID': this.repoId,
        Origin: this.$route.params.repoId ? `View Repo` : 'View Workspace',
        Context: 'Repo Sidebar',
        Selection: selection,
      });
      this.analytics.track(productEvents.SELECTED_SIDEBAR_ELLIPSIS_MENU_OPTION, {
        'Entity Type': 'Repo',
        'Entity ID': this.repoId,
        Option: selection,
        'In Favorites Recents Section': this.favoriteAndRecentSection,
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.settings-popper {
  &.repo-settings-icon {
    &.v-popper--shown {
      visibility: visible;
    }
  }

  .icon {
    font-size: var(--body-L);
    border-radius: 2px;
    padding: 2px;
  }
}
</style>
