import path from 'path-browserify';
import { v4 as uuidv4 } from 'uuid';
import * as config from '../config';
import {
  createDynamicStructureFromDiffString,
  dynamicFileDiffTypeToSwmFilePatchType,
  dynamicHunkContainerToSwmCellSnippet,
} from '../diff-parser';
import { getLogger } from '../logger/legacy-shim';
import * as objectUtils from '../objectUtils';
import type {
  FILE_VERSION,
  HunksDictionary,
  SwmCell,
  SwmCellImage,
  SwmCellMermaid,
  SwmCellRule,
  SwmCellSnippet,
  SwmCellSnippetPlaceholder,
  SwmCellTable,
  SwmCellText,
  SwmCellVideo,
  SwmFile,
  SwmMeta,
  SwmSymbol,
  SwmSymbols,
  SwmTask,
  versions,
} from '../types';
import type { SwmResourceFile } from '../types/common-types';
import { SwmResourceState } from '../types/common-types';
import type { FileDiffType } from '../types/swimm-patch-common';
import {
  ApplicabilityStatus,
  DELETE_MARKER,
  SELECT_MARKER,
  SwmCellType,
  SwmSymbolLinkType,
  SwmSymbolType,
} from '../types/swimm-patch-common';
import type { StaticSwmFile } from '../types/versions/swimm-types_1_0_4';
import type { SwmSymbolPath } from '../types/versions/swimm-types_2_0_0';
import * as stringUtils from '../utils/string-utils';
import { slugify } from '../utils/string-utils';
import { removeCRCharactersFromSwmData } from './carriage-return-utils';
import { assertFileVersion } from './file-version-utils';
import { buildHunkIndication, isSmartElementWithNewInfo } from './hunk-utils';

const logger = getLogger("packages/shared/src/utils/swm-utils.ts");

// For backward compatibility, if needed - convert diff from an old SWM file without swimmPatch to dynamicSwimmPatch structure.
export function convertSWMStructure(unitFile, repoId, appVersion) {
  let convertedSWMFile = { ...unitFile };
  if (assertFileVersion({ fileVersion: unitFile.file_version, operator: '<', versionToCompare: '2.0.0' })) {
    // Up to 1.0.4 inclusive - the conversion process relied on this to happen
    // There is no 1.0.5 - after 1.0.4 we had 2.0.0
    convertedSWMFile = preV2prepareLoadedSwmFile(unitFile);

    if (assertFileVersion({ fileVersion: unitFile.file_version, operator: '<', versionToCompare: '1.0.3' })) {
      convertedSWMFile = upgradeSWMFileStructureFrom102OrBelowTo104(convertedSWMFile);
    } else {
      if (assertFileVersion({ fileVersion: unitFile.file_version, operator: '<', versionToCompare: '1.0.4' })) {
        convertedSWMFile.hunksOrder = generateHunksOrderStaticSwimmPatchSwmFile104(convertedSWMFile.swimmPatch);
      }
    }
    // Now convert from 1.0.4 to 2.0.0
    try {
      convertedSWMFile = upgradeSWMFileFrom104to200(convertedSWMFile, unitFile.file_version, repoId, appVersion);
    } catch (err) {
      throw new Error(`could not convert unit to file version 2.0.0, Details: ${err.toString()}`);
    }
  } else {
    convertedSWMFile.content.forEach((cell) => {
      if (cell.type === SwmCellType.Snippet) {
        cell.repoId = repoId;
      }
    });
    if (convertedSWMFile.symbols) {
      for (const symbolId of Object.keys(convertedSWMFile.symbols)) {
        if (convertedSWMFile.symbols[symbolId].type === SwmSymbolType.GENERIC_TEXT) {
          convertedSWMFile.symbols[symbolId].repoId = repoId;
        }
      }
    }
  }

  objectUtils.deleteObjectKeys(convertedSWMFile, config.UNIT_FILE_DEPRECATED_KEYS);
  setMissingDefaultValues(convertedSWMFile);
  removeCRCharactersFromSwmData(convertedSWMFile);
  return convertedSWMFile;
}

function upgradeSWMFileFrom104to200(swmFileInVersion104, orignalFileVersion, repoId, appVersion) {
  const newStructure: {
    id: string;
    name: string;
    file_version: FILE_VERSION;
    meta: SwmMeta;
    content: SwmCell[];
    task: SwmTask;
  } = {
    id: swmFileInVersion104.id,
    name: swmFileInVersion104.name,
    file_version: config.SWM_SCHEMA_VERSION,
    meta: {
      app_version: appVersion,
    },
    content: [],
    task: {
      dod: swmFileInVersion104.dod,
      hints: swmFileInVersion104.hints,
      tests: swmFileInVersion104.tests,
    },
  };

  if (swmFileInVersion104.cr) {
    newStructure.task.crActions = swmFileInVersion104.cr;
  }

  // Populate content
  // First cell is a text that include the intro
  newStructure.content.push({ type: SwmCellType.Text, text: swmFileInVersion104.description });
  // Addind snippets based on the order

  for (const hunkOrderId of swmFileInVersion104.hunksOrder) {
    const hunkOrderIdSplitIndex = hunkOrderId.lastIndexOf('_');
    const hunkFile = hunkOrderId.substring(0, hunkOrderIdSplitIndex);
    const hunkIndexInFile = hunkOrderId.substring(hunkOrderIdSplitIndex + 1, hunkOrderId.length);
    const swimmFilePatch = swmFileInVersion104.swimmPatch[hunkFile];
    const patchType = dynamicFileDiffTypeToSwmFilePatchType(swimmFilePatch.diffType);
    let snippetCell = {};
    if (assertFileVersion({ fileVersion: orignalFileVersion, operator: '<', versionToCompare: '1.0.3' })) {
      const hunkToAdd = swimmFilePatch.hunkContainers[hunkIndexInFile];
      snippetCell = dynamicHunkContainerToSwmCellSnippet(hunkToAdd, hunkFile, repoId, patchType);
      if (hunkToAdd.swimmHunkMetadata && hunkToAdd.swimmHunkMetadata.hunkComments) {
        snippetCell['comments'] = hunkToAdd.swimmHunkMetadata.hunkComments;
      }
    } else {
      // Version 1.0.4
      snippetCell = ConvertOldHunkToNewHunkFormat({
        hunk: swimmFilePatch.hunks[hunkIndexInFile],
        fileName: hunkFile,
        patchType: patchType,
        repoId,
      });
    }

    newStructure.content.push(snippetCell as SwmCellSnippet);
  }

  // Add summary to content
  newStructure.content.push({ type: SwmCellType.Text, text: swmFileInVersion104.summary });

  return newStructure;
}

function ConvertOldHunkToNewHunkFormat({
  hunk,
  fileName,
  patchType,
  repoId,
}: {
  hunk: versions.v1_0_4.StaticSwmHunk;
  fileName: string;
  patchType?: FileDiffType;
  repoId: string;
}): SwmCellSnippet {
  const hunkComments =
    hunk.swimmHunkMetadata && hunk.swimmHunkMetadata.hunkComments ? hunk.swimmHunkMetadata.hunkComments : [];
  // First line in hunkDiffLines is the hunk header - we can get the first line number from it
  const hunkHeader: string = hunk.hunkDiffLines[0];
  const firstLineNumber = parseInt(gitHunkHeaderToParsedHunkHeader(hunkHeader)[1]);
  if (firstLineNumber === null || isNaN(firstLineNumber)) {
    throw Error('could not parse hunk header, first line number is missing');
  }
  const lines = [];
  for (let lineIndex = 1; lineIndex < hunk.hunkDiffLines.length; lineIndex++) {
    if (hunk.hunkDiffLines[lineIndex][0] === DELETE_MARKER) {
      lines.push(SELECT_MARKER + hunk.hunkDiffLines[lineIndex].slice(1));
    } else {
      lines.push(hunk.hunkDiffLines[lineIndex]);
    }
  }

  const snippetCell: SwmCellSnippet = {
    type: SwmCellType.Snippet,
    path: fileName,
    comments: hunkComments,
    firstLineNumber: firstLineNumber,
    lines: lines,
    repoId,
    id: buildHunkIndication({ firstLineNumber, length: lines.length, path: fileName }),
  };
  if (typeof patchType !== 'undefined') {
    snippetCell.patchType = patchType;
  }
  return snippetCell;
}

function generateHunksOrderStaticSwimmPatchSwmFile104(swimmPatch) {
  const hunksOrder = [];
  for (const filePath in swimmPatch) {
    swimmPatch[filePath].hunks.forEach(function (hunk, index) {
      hunksOrder.push(`${filePath}_${index}`);
    });
  }
  return hunksOrder;
}

function gitHunkHeaderToParsedHunkHeader(gitHeader) {
  const gitHunkHeaderRegex = '(?:^|\n)@@\\s+-(\\d+),?(\\d+)?\\s+\\+(\\d+),?(\\d+)?\\s@@(?:(?!\n).)*';
  return gitHeader.match(gitHunkHeaderRegex);
}

// Backward compatibility for file version below 1.0.4
function upgradeSWMFileStructureFrom102OrBelowTo104(oldSwmFile) {
  const convertedSwmFile = decodeEncodedProperties(oldSwmFile);
  const diffToSwimmPatchResult = createDynamicStructureFromDiffString({ rawDiffString: convertedSwmFile.diff });

  if (diffToSwimmPatchResult.code !== config.SUCCESS_RETURN_CODE) {
    throw new Error('could not convert old diff format to swimmPatch');
  }
  convertedSwmFile.swimmPatch = { ...diffToSwimmPatchResult.swimmPatch };
  convertedSwmFile.hunksOrder = generateHunksOrderDynamicSwimmPatch(convertedSwmFile.swimmPatch);
  return convertedSwmFile;
}

function generateHunksOrderDynamicSwimmPatch(swimmPatch) {
  const hunksOrder = [];
  for (const filePath in swimmPatch) {
    swimmPatch[filePath].hunkContainers.forEach(function (hunk, index) {
      hunksOrder.push(`${filePath}_${index}`);
    });
  }
  return hunksOrder;
}

// Decode properties for file version < 1.0.3.
// Starting on file_version v1.0.3 we stopped encoding fields in the SWM file,
function decodeEncodedProperties(unitFileContent) {
  if (assertFileVersion({ fileVersion: unitFileContent.file_version, operator: '>=', versionToCompare: '1.0.3' })) {
    return unitFileContent;
  }
  const decodedSwmFileContent = { ...unitFileContent };

  // The encoding/decoding changed in v1.0.1, different decoding is needed for older file versions
  if (assertFileVersion({ fileVersion: unitFileContent.file_version, operator: '>=', versionToCompare: '1.0.1' })) {
    config.UNIT_FILE_ENCODED_KEYS.forEach((key) => {
      decodedSwmFileContent[key] = unitFileContent[key] ? stringUtils.decodeString(unitFileContent[key]) : '';
    });
  } else {
    decodedSwmFileContent.description = stringUtils.decodeString(decodedSwmFileContent.description);
    decodedSwmFileContent.diff = stringUtils.atob(decodedSwmFileContent.diff);
  }
  return decodedSwmFileContent;
}

function preV2prepareLoadedSwmFile(loadedUnitFile: StaticSwmFile) {
  if (loadedUnitFile.tests && loadedUnitFile.tests.length === 1 && loadedUnitFile.tests[0] === '') {
    // For us, tests of [""] means no tests
    loadedUnitFile.tests = [];
  }
  // Calling `fillEmptyUnitFields` for backward compatibility with file versions who didn't have all relevant fields.
  const preV2UnitSkeleton = {
    name: '',
    tests: [],
    hints: [],
    swimmPatch: {},
    diff: '',
    description: '',
    dod: '',
    summary: '',
  };

  Object.keys(preV2UnitSkeleton).forEach((key) => {
    // If the field is empty string or does not exist - create with default value
    if (!loadedUnitFile[key]) {
      loadedUnitFile[key] = preV2UnitSkeleton[key];
    }
  });
  return loadedUnitFile;
}

function setMissingDefaultValues(convertedSWMFile) {
  if (!convertedSWMFile.symbols) {
    return;
  }
  const linkSymbols = Object.values(convertedSWMFile.symbols).filter((symbol) => symbol['type'] === SwmSymbolType.LINK);
  linkSymbols.forEach((symbol) => {
    if (!symbol['swimmType']) {
      symbol['swimmType'] = SwmSymbolLinkType.Doc;
    }
  });
}

/**
 *  Updates THE hunks inside a unit to be with a specific applicability status.
 *  In a unit that wasn't verified at all the function will override and mark all hunks as outdated as the applicability status provided.
 * @param swmFile - .swm file (with dynamicPatch) to update its hunks
 * @param applicabilityStatus - a selected status from ApplicabilityStatus
 * @param shouldOverrideStatus - when true, the provided status will "force" override any existing status
 * @param repoId
 */
export function markAllUnitHunksAsStatus({
  swmFile,
  applicabilityStatus,
  shouldOverrideStatus = false,
}: {
  swmFile: SwmFile;
  applicabilityStatus: ApplicabilityStatus;
  shouldOverrideStatus?: boolean;
}) {
  for (const cell of swmFile.content) {
    if (cell.type === 'snippet') {
      if (shouldOverrideStatus || !cell.applicability) {
        cell.applicability = applicabilityStatus;
      }
    }
  }
}

/**
 * Extract base64 images from SWM content. Change each image source from inline-base64
 * to a file path, and return an array of `ImageFileInfo`.
 */
export function extractInlineImages(swmFile: SwmFile, originalSwmFile: SwmFile): SwmResourceFile[] {
  // Initially, consider all images in the original SWM file as removed
  const removedImageFilenames: Set<string> = new Set();
  if (originalSwmFile && originalSwmFile.content) {
    for (const cell of filterSwmCells(originalSwmFile, SwmCellType.Image)) {
      if (matchInlineImageSrc(cell.src)) {
        removedImageFilenames.add(cell.src);
      }
    }
  }

  const resourceFiles: SwmResourceFile[] = [];

  // Handle existing and new image
  for (const cell of filterSwmCells(swmFile, SwmCellType.Image)) {
    if (!cell.src.startsWith('data:image/')) {
      // This is a failsafe mechanism, perform a simple check to see if this is an image cell with an
      // Inline image, before trying to match the base64 content with a regex. This way, if the regex
      // Fails to match, we can throw an error, instead of believing there's no inline image there.
      continue;
    }
    const match = cell.src.match(/^data:image\/(?<extension>.+?);base64,(?<base64Data>.+)$/ms);
    if (!match) {
      throw new Error(`Failed to parse image base64 data from src: ${cell.src}`);
    }

    if (cell.originalSrc) {
      // This is an existing image, so set the src back to what it was when the document was loaded
      cell.src = cell.originalSrc;
      // This image was not removed, it still exists:
      removedImageFilenames.delete(cell.originalSrc);
      // Do not save `originalSrc` to the final SWM file
      delete cell.originalSrc;
    } else {
      // This is a new image, compute its path and add it to the returned list of resource files to commit
      const filenameRelativeToSwmDir = `${config.SWM_IMAGES_FOLDER_NAME}/${uuidv4()}.${match.groups.extension}`;
      // Add file to metadata
      resourceFiles.push({
        state: SwmResourceState.Created,
        path: filenameRelativeToSwmDir,
        content: match.groups.base64Data,
        isBase64Encoded: true,
      });
      // Change image source to point to a file, rather than inline base64 data; use a path relative to where SWM files are in the repo
      cell.src = filenameRelativeToSwmDir;
    }
  }

  // At this point `resourceFiles` contains all new images and `removedImageFilenames`
  // Contains all the images to delete, so we can log this information:
  logger.debug(`Extracted inline images, new: ${resourceFiles.length}, deleted: ${removedImageFilenames.size}`, {
    module: 'swm-utils',
  });

  // Handle removed images
  for (const filenameToDelete of removedImageFilenames) {
    resourceFiles.push({ state: SwmResourceState.Deleted, path: filenameToDelete });
  }

  return resourceFiles;
}

/**
 * Match an image `src` attribute against a regular expression, to check if it references an image saved in the repository.
 */
export function matchInlineImageSrc(src: string): RegExpMatchArray {
  return src.match(new RegExp(`^(?<relativePath>${config.SWM_IMAGES_FOLDER_NAME}/.+\\.(?<extension>.+))`));
}

type SwmCellTypeReturnCell = {
  [SwmCellType.Image]: SwmCellImage;
  [SwmCellType.Snippet]: SwmCellSnippet;
  [SwmCellType.SnippetPlaceholder]: SwmCellSnippetPlaceholder;
  [SwmCellType.Text]: SwmCellText;
  [SwmCellType.Table]: SwmCellTable;
  [SwmCellType.Rule]: SwmCellRule;
  [SwmCellType.Video]: SwmCellVideo;
  [SwmCellType.Mermaid]: SwmCellMermaid;
};

/**
 * After having loaded any images that belong to this doc and are stored within the repo (as
 * opposed to saved on the cloud), mark them as new in the SWM content, so they will each be saved
 * as a new file for this doc.
 */
export function markInlineImagesAsNew({ swmFile }: { swmFile: SwmFile }) {
  let count = 0;
  for (const cell of filterSwmCells(swmFile, SwmCellType.Image)) {
    if (cell.originalSrc && cell.src.startsWith('data:image/')) {
      count++;
      delete cell.originalSrc;
    }
  }
  logger.debug(`Marked ${count} inline images as new`, { module: 'swm-utils' });
}

export function filterSwmCells<T extends SwmCellType>(swmFile: { content: SwmCell[] }, cellType: T) {
  return swmFile.content.filter((cell) => cell.type === cellType) as SwmCellTypeReturnCell[T][];
}

/**
 * @param swmFile
 * @param repoId
 * @param onlySnippets if true (default is false) will check only snippets
 * return true if the swm contains snippet from other repo
 */
export function isCrossRepoSwmFile({
  swmFile,
  repoId,
  onlySnippets = false,
}: {
  swmFile: SwmFile;
  repoId: string;
  onlySnippets?: boolean;
}) {
  const snippetCells = filterSwmCells(swmFile, SwmCellType.Snippet);
  const snippetsResult = snippetCells.some((cell) => cell.repoId !== repoId);
  if (snippetsResult || onlySnippets || !swmFile.symbols) {
    return snippetsResult;
  }
  return Object.values(swmFile.symbols).some((symbol) => isCrossRepoSymbol({ repoId, symbol }));
}

function isCrossRepoSymbol({ symbol, repoId }: { symbol: SwmSymbol; repoId: string }): boolean {
  if (symbol.type === SwmSymbolType.GENERIC_TEXT || symbol.type === SwmSymbolType.PATH) {
    return symbol.repoId !== repoId;
  }
  return false;
}

/**
 *
 * @param swmFile
 * return all repo ids that has snippets
 */
export function getRepoIdsFromSwmFile(swmFile: SwmFile) {
  const snippetCells = filterSwmCells(swmFile, SwmCellType.Snippet);
  const repoIds = snippetCells.map((cell) => cell.repoId).filter(Boolean);
  return Array.from(new Set(repoIds));
}

function getPathFromSnippet(currentPath: string, autosyncedSnippets: HunksDictionary, id: string): string | undefined {
  if (autosyncedSnippets) {
    const autosyncedSnippet = autosyncedSnippets[id];
    if (autosyncedSnippet) {
      if (isSmartElementWithNewInfo(autosyncedSnippet)) {
        return autosyncedSnippet.newInfo.filePath;
      }
    }
  }
  return currentPath;
}

/**
 *
 * @param swm: {SwmFile}
 * the function return all the paths used in snippets in the swm per repo (since snippets are cross repo now)
 * the result mapping from repoId (string) -> paths (array of string)
 */
export function getPathsUsedInSwmSnippetsByRepo(
  swm: SwmFile,
  autosyncedSnippets?: HunksDictionary
): Record<string, string[]> {
  const cells = filterSwmCells(swm, SwmCellType.Snippet);
  const byRepoIdObject = {};
  for (const { repoId, path, id } of cells) {
    // Getting updated path from the autosynced snippets array (will return undefined if the snippet is outdated)
    const updatedPath: string | undefined = getPathFromSnippet(path, autosyncedSnippets, id);
    // if the snippet is outdated we want to exit the loop

    if (!byRepoIdObject[repoId]) {
      byRepoIdObject[repoId] = [updatedPath];
    } else {
      if (!byRepoIdObject[repoId].includes(updatedPath)) {
        byRepoIdObject[repoId].push(updatedPath);
      }
    }
  }
  return byRepoIdObject;
}

/**
 *
 * @param swm: {SwmFile}
 * the function return all the paths used in snippets and symbols in the swm
 * the result is all paths (array of string)
 */
export function getPathsUsedInSwm(swm: SwmFile): string[] {
  const cells = filterSwmCells(swm, SwmCellType.Snippet);
  const snippetPaths = cells.map((cell) => `${cell.repoId}-${cell.path}`);
  const pathAndTokenSymbols = Object.values(swm.symbols || {}).filter(
    (symbol) => symbol.type === SwmSymbolType.GENERIC_TEXT || symbol.type === SwmSymbolType.PATH
  );
  const symbolPaths = pathAndTokenSymbols.map((symbol) => {
    const sym = symbol as SwmSymbolPath; // it's also GENERIC_TEXT but they both have path and repoId
    return `${sym?.repoId}-${sym?.path}`;
  });
  return [...new Set(snippetPaths.concat(symbolPaths))];
}

// The swimmFile name will either be the `swimmId` or `slugify-file-name.swimmId`
// The function will return the Id from the string
export function getSwimmIdFromSwimmFileName(swimmFileName: string) {
  const fileNameParts = swimmFileName.split('.');
  return fileNameParts.length > 1 ? fileNameParts[1] : fileNameParts[0];
}

export class SwmFilenameParseResult {
  readonly name: string;
  readonly swmId: string;
  readonly extension: string;
  readonly path: string;
  constructor(match: RegExpMatchArray) {
    this.name = match.groups.name || match.groups.swmId;
    this.swmId = match.groups.swmId;
    this.extension = match.groups.extension;
    this.path = match.groups.path;
  }
  get title(): string {
    return !this.name ? null : this.name.charAt(0).toUpperCase() + this.name.slice(1).replace(/-/g, ' ');
  }
}

/**
 * Parse SWM file name.
 * @param filename SWM base filename, including extension
 * @returns either `SwmFilenameParseResult` or `null`.
 */
export function parseSwmFilename(filename: string): SwmFilenameParseResult | null {
  if (!filename) {
    return null;
  }
  const match =
    !filename.endsWith(config.SWMD_FILE_EXTENSION) && !filename.endsWith(config.SWM_RULE_EXTENSION)
      ? filename.match(/(?<swmId>[a-zA-Z0-9]+?)\.(?<extension>swm)$/)
      : filename.match(
          /(?<path>.*)?\/(?<name>.+?)\.(?<swmId>[a-zA-Z0-9-_]+?)\.(?<extension>tr\.swm|swm|pl\.sw\.md|t\.sw\.md|sw\.md)$/
        );
  return !match ? null : new SwmFilenameParseResult(match);
}

export function swmdFileName(swmFile: SwmFile): string {
  return generateSwmdFileName(swmFile.id, swmFile.name);
}

export function generateSwmdFileName(swmId: string, swmName: string): string {
  return `${slugify(swmName)}.${swmId}`;
}

export function getSwmLinksRepos(swm: SwmFile): string[] {
  const swmContent = [];
  filterSwmCells(swm, SwmCellType.Snippet)
    .filter((snippet) => !!snippet.comments)
    .forEach((snippet) => swmContent.push(...snippet.comments));
  filterSwmCells(swm, SwmCellType.Text).forEach((text) => swmContent.push(text.text));
  filterSwmCells(swm, SwmCellType.Table).forEach((tableData) => {
    swmContent.push(tableData.headers);
    if (tableData.table.length > 0) {
      swmContent.push(tableData.table);
    }
  });
  const swmContentString = JSON.stringify(swmContent);
  let linksSymbols = [];
  if (swm.symbols) {
    linksSymbols = Object.entries(swm.symbols)
      .filter(
        ([key, value]) =>
          value.type === SwmSymbolType.LINK &&
          swmContentString.match(new RegExp(`\\[\\[sym-link:\\(${key}\\).*?\\]\\]`, 'g'))
      )
      .map(([, value]) => value);
  }
  return Array.from(new Set(linksSymbols.map((symbol) => symbol.repoId)));
}

/**
 * Filters a list of SWM symbols by type and optionally by applicability status
 * @param symbols - the list of symbols to filter
 * @param type - the symbol type to filter by
 * @param applicability - optional applicability status to filter by
 */
export function filterSwmSymbols({
  symbols,
  type,
  applicability,
}: {
  symbols: SwmSymbols;
  type: SwmSymbolType;
  applicability?: ApplicabilityStatus;
}) {
  const symbolsByType = {};
  Object.entries(symbols).forEach(([symKey, symObj]) => {
    const applicabilityCheck = applicability ? symObj.applicability === applicability : true;
    if (symObj.type === type && applicabilityCheck) {
      symbolsByType[symKey] = objectUtils.deepClone(symObj);
    }
  });
  return symbolsByType;
}

export function getSwmSymbolsTupleByTypes(swm: SwmFile, types: SwmSymbolType[]) {
  return Object.entries(swm.symbols).filter(([_id, symbol]) => types.includes(symbol.type));
}

export function generateEmptySwm(appVersion: string): SwmFile {
  const swmFile: SwmFile = {
    id: '',
    name: '',
    content: [],
    file_version: config.SWM_SCHEMA_VERSION,
    symbols: {},
    meta: { app_version: appVersion },
    path: config.SWM_FOLDER_IN_REPO,
  };
  return swmFile;
}

/**
 * Returns the list of all snippets with the provided applicability status
 * @param swmContent - the swmContent (cell)
 * @param applicability - the applicability to filter by
 */
export function filterSwmSnippetsByApplicability({
  swmContent,
  applicability,
}: {
  swmContent: SwmCell[];
  applicability: ApplicabilityStatus;
}) {
  return Object.values(swmContent).filter(
    (cell) => cell.type === SwmCellType.Snippet && cell.applicability && cell.applicability === applicability
  );
}

/**
 * Returns if a SWM contains snippets
 * @param swm - a SWM file to check for snippets
 */
export function isSwmWithSnippets(swm: SwmFile) {
  return swm && swm.content && swm.content.some((cell) => cell.type === SwmCellType.Snippet);
}

export function getSwmTypeFromExtension(
  extension
): (typeof config.SWIMM_FILE_TYPES)[keyof typeof config.SWIMM_FILE_TYPES] {
  switch (`.${extension}`) {
    case config.SWMD_PLAYLIST_EXTENSION: {
      return config.SWIMM_FILE_TYPES.PLAYLIST;
    }
    case config.SWMD_TEMPLATE_FILE_EXTENSION: {
      return config.SWIMM_FILE_TYPES.SWMD_TEMPLATE;
    }
    case config.SWMD_FILE_EXTENSION: {
      return config.SWIMM_FILE_TYPES.SWMD;
    }
    case config.SWM_RULE_EXTENSION: {
      return config.SWIMM_FILE_TYPES.SWM_RULE;
    }
    case config.SWM_FILE_EXTENSION: {
      return config.SWIMM_FILE_TYPES.SWM;
    }
    default:
      return null;
  }
}

export function hasElementsWithApplicabilityStatus(swm: SwmFile, statuses: ApplicabilityStatus[]): boolean {
  for (const snippetCell of filterSwmCells(swm, SwmCellType.Snippet)) {
    if (statuses.includes(snippetCell.applicability)) {
      return true;
    }
  }
  for (const symbol of Object.values(swm.symbols)) {
    if (statuses.includes(symbol.applicability)) {
      return true;
    }
  }
  return false;
}

export function hasOutdatedElements(swm: SwmFile): boolean {
  return hasElementsWithApplicabilityStatus(swm, [ApplicabilityStatus.Outdated]);
}

export function hasAutosyncableElements(swm: SwmFile): boolean {
  return hasElementsWithApplicabilityStatus(swm, [ApplicabilityStatus.Autosyncable]);
}

export function fillRepoIdInSwmSnippets(swm: SwmFile, repoId: string) {
  if (swm.content) {
    const cells = filterSwmCells(swm, SwmCellType.Snippet);
    for (const cell of cells) {
      if (!cell.repoId) {
        cell.repoId = repoId;
      }
    }
  }
  return swm;
}

export function getSwimmJsonData(repoId: string) {
  const repoFile = {
    repo_id: repoId,
  };
  const fileContent = JSON.stringify(repoFile, null, 4).concat('\n');
  return {
    path: path.join(config.SWM_FOLDER_IN_REPO, `${config.REPO_JSON}`),
    content: fileContent,
    isBase64Encoded: false,
  };
}
