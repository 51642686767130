<template>
  <div v-if="isLoading">
    <Loader class="view-loader" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    // seems like Okta takes care of it on it's own, making sure to close the window
    const myWindow = window.open('', '_self');
    setTimeout(function () {
      myWindow.close();
    }, 1000);
  },
};
</script>
