<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue';
import BaseInput from '../../components/BaseInput/BaseInput.vue';
import BaseKeyCommand from '../../components/BaseKeyCommand/BaseKeyCommand.vue';

import SwmSelectionBlock from '../SwmSelectionBlock/SwmSelectionBlock.vue';
import SwmSelectionFooter from '../SwmSelectionFooter/SwmSelectionFooter.vue';

const props = withDefaults(
  defineProps<{
    provider?: string;
  }>(),
  {
    provider: 'YouTube',
  }
);

const emit = defineEmits<{
  select: [value: string];
}>();

const input = ref<InstanceType<typeof BaseInput>>();
const link = ref();

const placeholder = computed(() => {
  return `Insert a ${props.provider} link`;
});

function onEnter() {
  emit('select', link.value as string);
}

onMounted(async () => {
  if (input?.value?.field) {
    await nextTick();
    input?.value?.field.focus();
  }
});
</script>

<template>
  <SwmSelectionBlock class="swm-selection-content-video">
    <div class="swm-selection-content-video__content">
      <BaseInput ref="input" v-model="link" :placeholder="placeholder" @keydown.enter="onEnter" />
    </div>
    <SwmSelectionFooter
      ><span>Select <BaseKeyCommand :keys="['↵']" /></span
    ></SwmSelectionFooter>
  </SwmSelectionBlock>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-selection-content-video {
  $self: &;

  min-width: 360px;

  &__content {
    padding: var(--space-xsmall);
  }
}
</style>
