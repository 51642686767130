export const autoFocus = {
  mounted(el: HTMLElement): void {
    el.focus();
  },
};

export const visible = {
  mounted(el: HTMLElement, { value }: { value: string }) {
    el.style.visibility = value ? 'visible' : 'hidden';
  },
  updated(el: HTMLElement, { value }: { value: string }) {
    el.style.visibility = value ? 'visible' : 'hidden';
  },
};
