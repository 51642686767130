<script setup lang="ts">
import SettingsModalSection from '@/modules/core/components/settings-modal/SettingsModalSection.vue';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { TextField } from '@swimm/ui';
import { getDefaultUpdateBranchPrefix, toValidBranchName } from '@swimm/shared';
import { getLoggerNew } from '@swimm/shared';
import { useNotificationsStore } from '@swimm/editor';

const logger = getLoggerNew(__modulename);

const route = useRoute();
const store = useStore();
const { addNotification } = useNotificationsStore();
const originalWorkspaceData = computed(() => store.getters['database/db_getWorkspace'](route.params.workspaceId) || {});
const branchPrefix = ref(originalWorkspaceData.value.branch_prefix ?? '');
const savingWorkspace = ref(false);

async function save() {
  savingWorkspace.value = true;
  const resource = {
    id: route.params.workspaceId,
    branch_prefix: branchPrefix.value ?? '',
  };
  try {
    await store.dispatch('database/saveWorkspace', { resource });
    logger.info(`Saved branch prefix to be ${resource.branch_prefix} workspaceId=${route.params.workspaceId}`);
  } catch (err) {
    logger.error(`could not save workspace ${route.params.workspaceId}: ${err}`);
    branchPrefix.value = originalWorkspaceData.value.branch_prefix ?? '';
    addNotification('Failed to update workspace', { icon: 'warning' });
  } finally {
    savingWorkspace.value = false;
  }
}

const canSave = computed(() => {
  return isPrefixValid.value && (originalWorkspaceData.value.branch_prefix ?? '') !== branchPrefix.value;
});

const isPrefixValid = computed(() => {
  if (!branchPrefix.value) {
    return true;
  }
  return toValidBranchName(branchPrefix.value) === branchPrefix.value;
});
const preview = computed(() => {
  const prefix = branchPrefix.value || getDefaultUpdateBranchPrefix();
  return `${prefix}9v6zk`;
});
</script>

<template>
  <SettingsModalSection title="Advanced">
    <TextField
      class="field"
      v-model.trim="branchPrefix"
      label="Branch name format"
      focus-first
      :maxlength="100"
      :error="!isPrefixValid ? 'Invalid prefix for branch name' : ''"
      data-testid="branch-prefix"
    />
    <SwText variant="body-S">
      Preview: <code>{{ preview }}</code>
    </SwText>
    <Action class="save" :disabled="!canSave" :loading="savingWorkspace" @click="save">Save</Action>
  </SettingsModalSection>
</template>

<style scoped lang="postcss">
.field {
  margin-bottom: var(--space-sm);
}

.save {
  margin-top: var(--space-sm);
}
</style>
