// @ts-strict
import type {
  AutosyncApplicabilityStatus,
  AutosyncInput,
  AutosyncOutput,
  DynamicChangeLine,
  DynamicHunkContainer,
  DynamicPatchFile,
  MultiDynamicPatch,
  ResultWithReturnCode,
  SmartElement,
  SmartElementWithApplicability,
  SmartSymbol,
  Snippet,
} from '@swimm/shared';
import {
  ApplicabilityStatus,
  FileDiffType,
  autosyncSnippetsToDynamicPatch,
  config,
  getLoggerNew,
  getSwmFileSnippetCellsPathsByRepo,
  gitwrapper,
} from '@swimm/shared';
import { autosyncModifiedFileWithoutBlobSha } from './autosync-without-blob-sha';
import {
  getAllSnippetsInFile,
  getSmartElementWithApplicability,
  getSnippetsInFileWithApplicabilityStatus,
  isElementWithoutGitAccess,
  setHunkContainerApplicability,
} from './swimmagic-common';
import { remove } from 'lodash-es';
import { updateSymbols } from './symbol-tracker';

const logger = getLoggerNew("packages/swimmagic/src/autosync.ts");

export type AutosyncUnitReturnType =
  | { autosyncSuccess: false }
  | {
      autosyncSuccess: true;
      autosyncOutput: AutosyncOutput;
    };

/**
 * `autosyncUnit` - running our swimmagical algorithm to autosync a deprecated Unit (= a Unit with an inapplicable patch). The function assumes being called only if the patch is not originally applicable
 * @param originalSwmFile - The the swm to be autosynced
 * @param repoId - the Id of the repo containing the SWM to autosync
 * @param destCommit the revision to use for current repo
 * @return {Promise<boolean>} - determines if the swm fixing process successful
 */
export async function autosyncUnit(autosyncInput: AutosyncInput): Promise<AutosyncUnitReturnType> {
  try {
    const shouldSyncCrossRepo = config.isWebApp;
    logger.info(`Autosyncing SWM (shouldSyncCrossRepo is set to ${shouldSyncCrossRepo})`);

    // We filter out the unavailable elements from autosyncInput before passing it to the main flow.
    const unavailableSnippets = remove(autosyncInput.snippets, isElementWithoutGitAccess);
    const unavailableSymbols = remove(autosyncInput.symbols, isElementWithoutGitAccess);

    const autosyncResult = await autosyncUnitMainFlow({
      autosyncInput,
      shouldSyncCrossRepo,
    });

    // Mark all the unavailable elements as unavailable and then merge them with the main flow's result.
    const unavailableSnippetsWithApplicability = unavailableSnippets.map((item) =>
      getSmartElementWithApplicability({ applicabilityStatus: ApplicabilityStatus.Unavailable, element: item })
    );
    const unavailableSymbolsWithApplicability = unavailableSymbols.map((item) =>
      getSmartElementWithApplicability({ applicabilityStatus: ApplicabilityStatus.Unavailable, element: item })
    );
    autosyncResult.snippets.push(...unavailableSnippetsWithApplicability);
    autosyncResult.symbols.push(...unavailableSymbolsWithApplicability);

    return { autosyncSuccess: true, autosyncOutput: autosyncResult };
  } catch (err) {
    logger.error({ err }, `Failed to autosync the swimm file`);
  }
  return { autosyncSuccess: false };
}

/**
 * Type that specifies the various states the `getCurrentFileStatus` function can return:
 *  * Error
 *  * Success with any value besides `Renamed`
 *  * Success, `Renamed`, and rename specific parameters
 */
type GetCurrentFileStatusResult = ResultWithReturnCode<
  { status: Exclude<FileDiffType, FileDiffType.Renamed> } | { status: FileDiffType.Renamed; newPath: string }
>;

/**
 * Gets the current status of a file described in the Unit.
 * Relevant status can be only:
 *  # Unchanged - the file is still the same (and has the same name).
 *  # Deleted - the file no longer exists.
 *  # Modified - the file exists with the same name, and its contents are changed.
 *  # Renamed - the file has been renamed. In this case:
 *      `sameContent` return value describes whether the contents have changed as well.
 *      `newPath` provides the new file's path
 */
async function getCurrentFileStatus({
  unitFilePath,
  destCommit,
  repoId,
}: {
  unitFilePath: string;
  destCommit: string;
  repoId: string;
}): Promise<GetCurrentFileStatusResult> {
  const failureResult = { code: config.ERROR_RETURN_CODE } as const;
  try {
    const statusResult = await gitwrapper.getCurrentName({ oldFilePath: unitFilePath, destCommit, repoId });
    if (statusResult.code !== config.SUCCESS_RETURN_CODE) {
      return failureResult;
    }

    if (!statusResult.exists) {
      return { code: config.SUCCESS_RETURN_CODE, status: FileDiffType.Deleted };
    }

    if (!statusResult.isRenamed) {
      return { code: config.SUCCESS_RETURN_CODE, status: FileDiffType.Modified };
    }

    return {
      code: config.SUCCESS_RETURN_CODE,
      status: FileDiffType.Renamed,
      newPath: statusResult.currentName,
    };
  } catch (ex) {
    return failureResult;
  }
}

/**
 * Autosync - main flow
 * @param autosyncInput - array of snippets and symbols to sync
 * @param repoId - the repo we are currently on
 * @param shouldSyncCrossRepo - whether we should sync cross repo snippets or ignore them
 */
async function autosyncUnitMainFlow({
  autosyncInput,
  shouldSyncCrossRepo,
}: {
  autosyncInput: AutosyncInput;
  shouldSyncCrossRepo: boolean;
}): Promise<AutosyncOutput> {
  const snippetsWithApplicability: SmartElementWithApplicability<Snippet>[] = [];
  const symbolsWithApplicability: SmartElementWithApplicability<SmartSymbol>[] = [];

  const multiDynamicUnit: MultiDynamicPatch = await autosyncSnippetsToDynamicPatch({
    snippets: autosyncInput.snippets,
    attachId: true,
  });

  const autosyncPromises: Promise<void>[] = [];
  const snippetsPathsByRepo = getSwmFileSnippetCellsPathsByRepo(autosyncInput.snippets);

  for (const { repoId: fileRepoId, filePath: unitFilePath, branch: currentBranch } of snippetsPathsByRepo) {
    const unitFilePathAutoSync = async () => {
      const currentRepoId = fileRepoId;
      const relevantBranch = currentBranch;
      const snippetsFromFile = getAllSnippetsInFile({
        snippets: autosyncInput.snippets,
        repoId: currentRepoId,
        filePath: unitFilePath,
      });

      const currentFileStatusResult = await getCurrentFileStatus({
        unitFilePath,
        destCommit: relevantBranch,
        repoId: currentRepoId,
      });

      if (currentFileStatusResult.code !== config.SUCCESS_RETURN_CODE) {
        // We can't autosync this file.
        logger.error(`Failed to get the file status.`);
        snippetsWithApplicability.push(
          ...getSnippetsInFileWithApplicabilityStatus({
            snippets: snippetsFromFile,
            repoId: currentRepoId,
            filePath: unitFilePath,
            applicabilityStatus: ApplicabilityStatus.Outdated,
          })
        );
        return;
      }

      let fileModifiedFlag = currentFileStatusResult.status === FileDiffType.Modified;

      if (currentFileStatusResult.status === FileDiffType.Deleted) {
        // The unit file was deleted from the repo after the unit was created. Therefore we should mark all the hunks of this file as "outdated"
        snippetsWithApplicability.push(
          ...getSnippetsInFileWithApplicabilityStatus({
            snippets: snippetsFromFile,
            repoId: currentRepoId,
            filePath: unitFilePath,
            applicabilityStatus: ApplicabilityStatus.Outdated,
          })
        );
        return;
      }

      let currentUnitFilePath = unitFilePath;
      if (currentFileStatusResult.status === FileDiffType.Renamed) {
        const newFilePath = currentFileStatusResult.newPath;

        // Update the unit file with the renamed values
        multiDynamicUnit[currentRepoId][newFilePath] = { ...multiDynamicUnit[currentRepoId][unitFilePath] };
        multiDynamicUnit[currentRepoId][newFilePath].fileNameA = newFilePath;
        multiDynamicUnit[currentRepoId][newFilePath].fileNameB = newFilePath;
        delete multiDynamicUnit[currentRepoId][unitFilePath];

        currentUnitFilePath = newFilePath;

        // The file has been renamed -> We should mark everything as AUTOSYNCABLE
        markApplicabilityStatusForAllHunks({
          multiDynamicUnit,
          repoId: currentRepoId,
          filePath: newFilePath,
          applicabilityStatus: ApplicabilityStatus.Autosyncable,
        });

        fileModifiedFlag = true;
      }

      if (fileModifiedFlag) {
        const autosyncModifiedResult = await autosyncModifiedFileWithoutBlobSha({
          multiDynamicUnit,
          snippetsInFile: snippetsFromFile,
          // `docRepoId` is the same as the `fileRepoId` since it's only used to check for cross-repo (which is now treated before autosync),
          // so passing them as the same bypasses the useless check
          docRepoId: currentRepoId,
          fileRepoId: currentRepoId,
          filePath: currentUnitFilePath,
          revision: relevantBranch,
          shouldSyncCrossRepo,
        });
        snippetsWithApplicability.push(...autosyncModifiedResult.snippetsWithApplicability);
      }
      return;
    };
    autosyncPromises.push(unitFilePathAutoSync());
  }

  // Run all of the autosync flows concurrently.
  await Promise.allSettled(autosyncPromises);

  try {
    if (autosyncInput.symbols) {
      const symbolUpdateResult = await updateSymbols(autosyncInput.symbols);
      symbolsWithApplicability.push(...symbolUpdateResult.symbolsWithApplicability);
    }
  } catch (err) {
    logger.error({ err }, 'Failed to update symbols');
  }

  const docApplicability = getSwmApplicabilityFromElements(snippetsWithApplicability, symbolsWithApplicability);

  return { snippets: snippetsWithApplicability, symbols: symbolsWithApplicability, applicability: docApplicability };
}

function checkElementsContainStatus(
  elements: SmartElementWithApplicability<SmartElement>[],
  status: AutosyncApplicabilityStatus
) {
  return elements.some((element) => element.applicability === status);
}

function getSwmApplicabilityFromElements(
  snippets: SmartElementWithApplicability<Snippet>[],
  symbols: SmartElementWithApplicability<SmartSymbol>[]
): AutosyncApplicabilityStatus {
  const allSmartElementsWithApplicability = [...snippets, ...symbols];
  const isOutdated = checkElementsContainStatus(allSmartElementsWithApplicability, ApplicabilityStatus.Outdated);
  if (isOutdated) {
    return ApplicabilityStatus.Outdated;
  }
  const isAutosyncable = checkElementsContainStatus(
    allSmartElementsWithApplicability,
    ApplicabilityStatus.Autosyncable
  );
  if (isAutosyncable) {
    return ApplicabilityStatus.Autosyncable;
  }
  return ApplicabilityStatus.Verified;
}

/**
 * Finds the inner context lines - that is, the first and last line of the hunk's meat
 * Whitespace lines are skipped
 * Note that a line is considered to be meat line only if it's of type update/delete
 * "add" type is not considered since it does not exist in fileA and shouldn't serve for context
 * @param {dynamicHunkContainer} unitHunk
 */
export function findInnerContextLines(unitHunk: DynamicHunkContainer) {
  const unitHunkFirstMeatLineIndex = getNumberOfFirstMeatLineInA(unitHunk).index; // UnitHunk.lineNumbers.fileA.startLine;
  const unitHunkLastMeatLineIndex = getNumberOfLastMeatLineInA(unitHunk).index; // UnitHunkFirstLineNumber + unitHunk.lineNumbers.fileA.linesCount;

  const diffLineChanges = unitHunk.changes;

  let upperInnerContextLineIndex = unitHunkFirstMeatLineIndex;
  let isFound = false;

  while (upperInnerContextLineIndex <= unitHunkLastMeatLineIndex) {
    if (isMeatLineInA(diffLineChanges[upperInnerContextLineIndex])) {
      if (!isWhitespaceLine(diffLineChanges[upperInnerContextLineIndex].fileA.data)) {
        isFound = true;
        break;
      }
    }
    upperInnerContextLineIndex++;
  }
  if (!isFound) {
    // We couldn't find an upper context line number.
    return { result: false };
  }

  // We found the upper context
  // Now it's time to find the lower context

  isFound = false;
  let lowerInnerContextLineIndex = unitHunkLastMeatLineIndex;
  while (lowerInnerContextLineIndex >= unitHunkFirstMeatLineIndex) {
    if (isMeatLineInA(diffLineChanges[lowerInnerContextLineIndex])) {
      if (!isWhitespaceLine(diffLineChanges[lowerInnerContextLineIndex].fileA.data)) {
        isFound = true;
        break;
      }
    }
    lowerInnerContextLineIndex--;
  }
  if (!isFound) {
    // We couldn't find an upper context line number.
    return { result: false };
  }

  const lowerInnerContextLineNumberInA = diffLineChanges[lowerInnerContextLineIndex].fileA.actualLineNumber;
  const upperInnerContextLineNumberInA = diffLineChanges[upperInnerContextLineIndex].fileA.actualLineNumber;

  return {
    result: true,
    lowerInnerContextLineNumberInA: lowerInnerContextLineNumberInA,
    lowerInnerContextLineIndexInUnitHunk: lowerInnerContextLineIndex,
    upperInnerContextLineNumberInA: upperInnerContextLineNumberInA,
    upperInnerContextLineIndexInUnitHunk: upperInnerContextLineIndex,
  };
}

// The characters are the same as in https://en.cppreference.com/w/cpp/string/byte/isspace
const WHITESPACE_CHARACTERS = [' ', '\n', '\t', '\f', '\r', '\v'];

/**
 * Returns true iff all characters of lineData are whitespaces
 * @param {string} lineData
 */
export function isWhitespaceLine(lineData: string): boolean {
  const isWhiteSpaceCharacter = (character: string) => WHITESPACE_CHARACTERS.includes(character);
  return Array.from(lineData).every(isWhiteSpaceCharacter);
}

// "add" doesn't exist in fileA, only in fileB
const isMeatLineInA = (changeLine: DynamicChangeLine) => ['update', 'del'].includes(changeLine.changeType);

export function getNumberOfFirstMeatLineInA(dynamicHunkContainer: DynamicHunkContainer) {
  const lineChanges = dynamicHunkContainer.changes;
  return getIndexAndLineNumberOfMeatLineInA(lineChanges);
}

function getIndexAndLineNumberOfMeatLineInA(lineChanges: DynamicChangeLine[]) {
  const firstMeatLineIndex = lineChanges.findIndex(isMeatLineInA);
  const firstMeatLine = lineChanges[firstMeatLineIndex];
  return { index: firstMeatLineIndex, actualLineNumber: firstMeatLine.fileA.actualLineNumber };
}

export function getNumberOfLastMeatLineInA(dynamicHunkContainer: DynamicHunkContainer) {
  const lineChanges = dynamicHunkContainer.changes.slice().reverse();
  const returnObj = getIndexAndLineNumberOfMeatLineInA(lineChanges);

  // We got the index of the reversed array, so we need to calculate it from the end
  const count = lineChanges.length - 1;
  if (returnObj.index >= 0) {
    returnObj.index = count - returnObj.index;
  }

  return returnObj;
}

function markApplicabilityStatusForAllHunks({
  multiDynamicUnit,
  filePath,
  repoId,
  applicabilityStatus,
}: {
  multiDynamicUnit: MultiDynamicPatch;
  filePath: string;
  repoId: string;
  applicabilityStatus: ApplicabilityStatus;
}): void {
  if (!multiDynamicUnit[repoId]?.[filePath]) {
    return;
  }
  markApplicabilityStatusForAllHunksInFile2(multiDynamicUnit[repoId][filePath], applicabilityStatus);
}

function markApplicabilityStatusForAllHunksInFile2(
  dynamicUnitFile: DynamicPatchFile,
  applicabilityStatus: ApplicabilityStatus
): void {
  for (const hunkContainer of dynamicUnitFile.hunkContainers) {
    setHunkContainerApplicability(hunkContainer, applicabilityStatus);
  }
}
