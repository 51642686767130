import type { Editor as CoreEditor } from '@tiptap/core';
import type { Editor } from '@tiptap/vue-3';
import type { EditorView } from '@tiptap/pm/view';
import { markedFormattedValue } from '../components/EditorComponents/markdown/markdown-to-html';
import { generateJSON } from '@tiptap/html';
import { v4 as uuidv4 } from 'uuid';
import { last } from 'lodash-es';
import { getLogger } from '@swimm/shared';
import * as _ from '@tiptap/pm/model';

const logger = getLogger(__modulename);

export interface editableDomRect {
  x?: number;
  y?: number;
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
  width?: number;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any

export async function dispatchNewClipboardEvent(view: EditorView, items: ClipboardItems) {
  const dataTransfer = new DataTransfer();
  for (const item of items) {
    // if we find any item with an image type we want to store it into a variable
    const imageType = item.types.find((type) => type.includes('image'));
    if (imageType) {
      const htmlType = await item.getType('text/html');
      const htmlContent = await htmlType.text();
      dataTransfer.setData('text/html', htmlContent);

      // fetching the file blob from the item
      const fileBlob = await item.getType(imageType);
      // trying to generate from the item type the image type
      const imageFileEnding = last(fileBlob.type.split('/'));
      // generating a file name that will have a short hash and has to have the image type ending in it
      const generatedName = `${uuidv4().split('-')[0]}.${imageFileEnding ?? 'jpg'}`;
      // creating the new file
      const generatedFile = new File([fileBlob], generatedName, {
        type: fileBlob.type,
      });
      // adding the new file to our fake dataTransfer event
      dataTransfer.items.add(generatedFile);
    } else {
      if (item.types.includes('text/html')) {
        if (item.types.includes('text/plain')) {
          const plainType = await item.getType('text/plain');
          const plainContent = await plainType.text();
          dataTransfer.setData('text/plain', plainContent);
        }
        const htmlType = await item.getType('text/html');
        const htmlContent = await htmlType.text();
        dataTransfer.setData('text/html', htmlContent);
      } else {
        const plainType = await item.getType('text/plain');
        const plainContent = await plainType.text();
        dataTransfer.setData('text/plain', plainContent);
      }
    }

    const pasteEvent = new ClipboardEvent('paste', {
      clipboardData: dataTransfer,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    view.input.eventHandlers['paste'](pasteEvent);
  }
}

export async function pasteMarkdown(editor: Editor | CoreEditor) {
  try {
    if (navigator?.clipboard?.readText) {
      const text = await navigator.clipboard.readText();
      const html = markedFormattedValue(text);
      const content = generateJSON(html, editor.extensionManager.extensions);
      const pos = editor.state.selection.from;
      editor.chain().insertContentAt(pos, content).focus().run();
    } else {
      editor.commands.notifyFailedPaste('markdown');
    }
  } catch (err) {
    logger.error(`Could not paste as Markdown: ${err}`);
    if ((err as Error).name === 'NotAllowedError') {
      editor.commands.notifyFailedPaste('markdown');
    }
  }
}

export function splitTextLines(text: string) {
  return text
    .replace(/\r\n?/g, '\n')
    .split(/\r?\n/)
    .filter((t) => !!t.trim());
}
export function createTextNodes(text: string, editor: CoreEditor) {
  const textLines = splitTextLines(text);
  type TextNodeType = ReturnType<typeof editor.view.state.schema.text>;
  const textNodes: TextNodeType[] = [];
  textLines.forEach((line, i) => {
    textNodes.push(editor.view.state.schema.text(line));
    if (i !== textLines.length - 1) {
      textNodes.push(editor.view.state.schema.nodes.hardBreak.create());
    }
  });
  return textNodes;
}

export async function pastePlainText(editor: Editor | CoreEditor) {
  try {
    if (navigator?.clipboard?.readText) {
      const text = await navigator.clipboard.readText();
      const textNodes = createTextNodes(text, editor);
      const contentJSON: Node[] = [];
      textNodes.forEach((node) => {
        if (node.type.name === 'hardBreak') {
          return;
        }
        contentJSON.push(generateJSON(`<p>${node.text}<p/>`, editor.extensionManager.extensions).content[0]);
      });
      const pos = editor.state.selection.from;
      editor.commands.deleteRange({ to: editor.state.selection.to, from: editor.state.selection.from });
      editor.chain().insertContentAt(pos, contentJSON).focus().run();
    } else {
      editor.commands.notifyFailedPaste('plain text');
    }
  } catch (err) {
    if ((err as Error).name === 'NotAllowedError') {
      editor.commands.notifyFailedPaste('plain text');
    }
    logger.error(`Could not paste as plain text: ${err}`);
  }
}
