<template>
  <Loader v-if="loading" class="view-loader" />
  <ContentErrorState
    v-else-if="!!inactiveBoxContent"
    :code="!playlistFromDb ? '404' : null"
    type="playlist"
    :swimm-status="inactiveBoxContent"
    :repo-id="repoId"
  />
  <div v-else class="playlist-wrapper">
    <SequenceIntroSummary
      class="intro-container"
      nested-playlist
      :playlist="playlist"
      :is-intro="true"
      :repo-id="repoId"
      :is-playlist-draft="playlistIsNewDraft"
    />
  </div>
</template>

<script setup lang="ts">
import ContentErrorState from '@/common/components/organisms/unavailable-doc-states/ContentErrorState.vue';
import SequenceIntroSummary from '@/common/components/organisms/SequenceIntroSummary.vue';
import { useStore } from 'vuex';
import { type SwmFile } from '@swimm/shared';
import { getSwmLinksRepos } from '@swimm/shared';
import { useInitData } from '@/common/composables/initData';
import { useRouting } from '@/common/composables/routing';
import type { Playlist } from '@/modules/playlists/types';
import { ComputedRef, computed, onBeforeMount, ref } from 'vue';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';

const props = defineProps<{
  swimmId: string;
  repoId: string;
  playlist: Playlist;
}>();

const loading = ref(true);
const localSwimm = ref<SwmFile>({} as SwmFile);

const store = useStore();
const { setPlaylistData } = useInitData();
const { assertRouting, getCurrentOrDefaultBranch } = useRouting();
const drafts3Store = useDrafts3Store();
const fs_isUnitFileInRepoPath = computed(() => store.getters['filesystem/fs_isUnitFileInRepoPath']);
const fs_getPlaylistSwmFromLocalRepo = computed(() => store.getters['filesystem/fs_getPlaylistSwmFromLocalRepo']);
const db_getPlaylist = computed(() => store.getters['database/db_getPlaylist']);

const fetchRepoChildren = (args?) => store.dispatch('database/fetchRepoChildren', args);
const fetchRepository = (args?) => store.dispatch('database/fetchRepository', args);
const getRepoSwmsLists = (args?) => store.dispatch('filesystem/getRepoSwmsLists', args);

const playlistFromDb = computed(() => {
  return db_getPlaylist.value(props.repoId, props.swimmId);
});

const swm = computed(() => {
  return { ...localSwimm.value };
});

const playlistHasDraft = computed(() => !!drafts3Store.drafts?.get(props.swimmId));
const playlistIsNewDraft = computed(() => !playlistFromDb.value && playlistHasDraft.value);

const inactiveBoxContent: ComputedRef<'Not found' | null> = computed(() => {
  if (!fs_isUnitFileInRepoPath.value(props.swimmId, props.repoId)) {
    if (playlistHasDraft.value) {
      return null;
    }
    return 'Not found';
  }
  return null;
});

onBeforeMount(async () => {
  const assertResult = await assertRouting();
  if (!assertResult) {
    return;
  }

  if (!playlistFromDb.value) {
    loading.value = false;
    return;
  }
  await setPlaylistData({
    repoId: props.repoId,
    playlistId: props.swimmId,
  });
  localSwimm.value = fs_getPlaylistSwmFromLocalRepo.value(props.swimmId, props.repoId);
  await loadLinksReposLists();
  loading.value = false;
});
async function loadLinksReposLists() {
  if (swm.value.content) {
    getSwmLinksRepos(swm.value).forEach(async (repoId) => {
      getRepoSwmsLists({ repoId, branch: await getCurrentOrDefaultBranch(repoId) });
      fetchRepository({ repoId });
      fetchRepoChildren({ repoId, children: ['swimms', 'playlists'] });
    });
  }
}
</script>
<style scoped>
.playlist-wrapper {
  display: flex;
  align-items: center;
  margin: auto;
  flex-direction: column;
}

.intro-container {
  display: flex;
  margin: 0;
  width: 100%;
  flex-direction: column;
}
</style>
