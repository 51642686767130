<script setup lang="ts">
import { SwText } from '@swimm/ui';

defineProps<{
  mermaidVersion: string | null;
}>();

const emit = defineEmits<{
  (e: 'mermaid-live-link-clicked'): void;
}>();

function liveClicked() {
  emit('mermaid-live-link-clicked');
}
</script>

<template>
  <div class="footer" :contenteditable="false">
    <SwText variant="body-XS"
      >Powered by Mermaid <span data-testid="mermaid-version">{{ mermaidVersion ?? '' }}</span
      >. Visit
      <a href="https://mermaid.live/" target="_blank" @click="liveClicked">mermaid.live</a>
      for the complete list of syntax.</SwText
    >
  </div>
</template>

<style scoped lang="scss">
.footer {
  padding: var(--space-xs) var(--space-base);
  background-color: var(--color-surface);
  color: var(--text-color-secondary);
  font-size: var(--body-XS);
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color-border-default);

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: var(--text-color-primary);
    }
  }
}
</style>
