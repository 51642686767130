import { type Ref, computed, isRef, ref, watch } from 'vue';
import type { SelectedIndex } from '../../types/transitional';
import { getHandlerKey, normalizeAdditionalHandlers } from '../utilties';

export type ItemSelectionKeyHandlersOptions = {
  // Supply additional keydown key handlers i.e. Enter.
  // which will be returned in the `onKeyDown` response.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalHandlers?: Record<string, (payload: any) => boolean>;

  // Think pagination, this option allows us to specify any
  // additional indexes beyond the indexes of the items supplied.
  // The string/record specified are returned in the keyDown handlers
  // events.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  includeAdditionalIndexes?: any[];

  // When items change the selectedIndex is reset to 0. This
  // option allows us to prevent this behaviour. Think pagination,
  // if a user as clicked "show more" we don't want to take them
  // back to the beginning of the list.
  preventIndexReset?: boolean;
};

export function useItemSelectionKeyHandlers<T>(
  items: Ref<T[]>,
  optionsInput?: ItemSelectionKeyHandlersOptions | Ref<ItemSelectionKeyHandlersOptions>
) {
  const options = optionsInput ? (isRef(optionsInput) ? optionsInput : ref(optionsInput)) : undefined;
  const itemIndex = ref(0);
  const selectedIndex = computed<SelectedIndex>(() => [itemIndex.value]);

  // Reset the item index whenever items changes.
  watch(
    () => items.value,
    () => {
      if (!options?.value.preventIndexReset) {
        itemIndex.value = 0;
      }
    }
  );

  function onKeyDown(event: KeyboardEvent) {
    const handlerKey = getHandlerKey(event);
    const normalizedAdditionalHandlers =
      options?.value?.additionalHandlers && normalizeAdditionalHandlers(options?.value?.additionalHandlers);

    // It's common that need to handle other keyboard commands beyond navigation.
    // These can be pased in via additionalHanders and are applied here.
    if (normalizedAdditionalHandlers && normalizedAdditionalHandlers[handlerKey]) {
      event.preventDefault();

      const selectedItem = items.value?.[itemIndex.value];
      const additionalPayload = options.value?.includeAdditionalIndexes?.[itemIndex.value - items.value.length];
      const result = additionalPayload
        ? normalizedAdditionalHandlers[handlerKey](additionalPayload)
        : normalizedAdditionalHandlers[handlerKey](selectedItem);

      if (result) {
        return true;
      }
    }

    switch (event.key) {
      case 'ArrowDown':
        if (!items.value.length) {
          return false;
        }

        if (itemIndex.value === items.value.length - 1 + (options?.value?.includeAdditionalIndexes?.length || 0)) {
          itemIndex.value = 0;
        } else {
          itemIndex.value++;
        }
        return true;
      case 'ArrowUp':
        if (!items.value.length) {
          return false;
        }

        // Apply decremental circular navigation between items.
        if (itemIndex.value === 0) {
          itemIndex.value = items.value.length - 1 + (options?.value?.includeAdditionalIndexes?.length || 0);
        } else {
          itemIndex.value--;
        }
        return true;
    }

    return false;
  }

  return {
    selectedIndex,
    onKeyDown,
  };
}
