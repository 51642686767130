import {
  ApplicabilityStatus,
  type AutosyncOutput,
  type SmartElementWithApplicabilityAndNewInfo,
  type Token,
  config,
  isSmartElementWithNewInfo,
  productEvents,
} from '@swimm/shared';
import { defineStore } from 'pinia';
import { STORES } from '@/modules/core/stores/store-constants';
import { useStore } from 'vuex';
import { DocumentationTypes } from '@/common/consts';
import { type Ref, computed, ref } from 'vue';
import { Timestamp } from 'firebase/firestore';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useRoute } from 'vue-router';
import { useDocsContentStore } from './docs-content';
import { DraftType } from '@/modules/drafts3/db';
import { useAnalytics } from '@/common/composables/useAnalytics';

export interface RepoDocItem {
  name: string;
  created: Timestamp;
  creator: string;
  creator_name: string;
  id: string;
  modified: Timestamp;
  modifier: string;
  modifier_name: string;
  play_mode?: 'walkthrough' | 'handson';
  type: 'unit' | 'external_link';
  views?: number;
  documentationType: 'document';
  path?: string;
  applicabilityStatus?: ApplicabilityStatus;
  applicabilityResult?: AutosyncOutput;
  folderId?: string;
  draftId?: string;
  folderIndex?: number;
  tags?: string[]; // tag IDs
  isNew?: boolean;
  creator_profile_url?: string;
}

export interface RepoPlaylistItem {
  name: string;
  created: Timestamp;
  creator: string;
  creator_name: string;
  id: string;
  modified: Timestamp;
  modifier: string;
  modifier_name: string;
  documentationType: 'playlist';
  type: 'playlist';
  path?: string;
  folderId?: string;
  draftId?: string;
  folderIndex?: number;
  tags?: string[]; // tag IDs
  isNew?: boolean;
  creator_profile_url?: string;
}

function getDocsWithMovedSwmTokens(repoDocItems: RepoDocItem[]) {
  return repoDocItems.filter((doc: RepoDocItem) => {
    if (doc.applicabilityStatus !== ApplicabilityStatus.Verified || !doc.applicabilityResult) {
      return false;
    }
    const symbols = doc.applicabilityResult.symbols;
    return symbols.some((symbol) => {
      return (
        symbol.type === 'token' &&
        isSmartElementWithNewInfo(symbol) &&
        (symbol as SmartElementWithApplicabilityAndNewInfo<Token>).filePath !==
          (symbol as SmartElementWithApplicabilityAndNewInfo<Token>).newInfo.filePath
      );
    });
  });
}

export const useRepoDocsStore = defineStore(STORES.REPO_DOCS, () => {
  const store = useStore();
  const drafts3Store = useDrafts3Store();
  const { user } = storeToRefs(useAuthStore());
  const route = useRoute();
  const docsContentStore = useDocsContentStore();
  const analytics = useAnalytics();

  const fs_getSwmdFileNameInRepo = computed(() => store.getters['filesystem/fs_getSwmdFileNameInRepo']);
  const fs_getSwmdPathInRepo = computed(() => store.getters['filesystem/fs_getSwmdPathInRepo']);

  const isItemInCurrentBranch = computed(() => store.getters['filesystem/fs_isUnitFileInRepoPath']);
  const currentRepoId = computed(() => route.params.repoId as string);

  const getDocsInBranch = (repoId: string): Record<string, RepoDocItem> => {
    // docs in the current branch
    const docsInBranch = getDocsInBranchWithoutApplicability(repoId);

    Object.keys(docsInBranch).forEach((swimmId) => {
      docsInBranch[swimmId].applicabilityStatus = docsContentStore.docsContent[swimmId]?.applicability;
      docsInBranch[swimmId].applicabilityResult = docsContentStore.docsContent[swimmId]?.output;
    });
    return docsInBranch;
  };

  const getDocsInBranchWithoutApplicability = (repoId: string): Record<string, RepoDocItem> => {
    // docs from DB
    const docsFromDB = store.getters['database/db_getUnits'](repoId);

    return Object.keys(docsFromDB)
      .filter((swimmID) => {
        return isSwimmDoc(docsFromDB[swimmID]);
      })
      .filter((swimmId) => isItemInCurrentBranch.value(swimmId, repoId))
      .reduce((cur, swimmId) => {
        const docFromDB = docsFromDB[swimmId];
        return Object.assign(cur, {
          [swimmId]: {
            name: docFromDB.name,
            created: docFromDB.created,
            creator: docFromDB.creator,
            creator_name: docFromDB.creator_name,
            id: docFromDB.id,
            modified: docFromDB.modified,
            modifier: docFromDB.modifier,
            modifier_name: docFromDB.modifier_name,
            play_mode: docFromDB.play_mode,
            type: docFromDB.type,
            views: docFromDB.views,
            documentationType: DocumentationTypes.DOC,
            tags: docFromDB.tags,
            path: getItemPathInRepo(repoId, swimmId),
            creator_profile_url: docFromDB.creator_profile_url ?? '',
            isNew: false,
          },
        });
      }, {} as Record<string, RepoDocItem>);
  };

  const getPlaylistsInBranch = (repoId: string): Record<string, RepoPlaylistItem> => {
    // playlists from DB
    const playlistsFromDB = store.getters['database/db_getPlaylists'](repoId);
    const playlistIds = playlistsFromDB ? Object.keys(playlistsFromDB) : [];
    // filter from filesystem
    return playlistIds
      .filter((playlistId) => isItemInCurrentBranch.value(playlistId, repoId))
      .reduce((cur, playlistId) => {
        const playlistFromDB = playlistsFromDB[playlistId];
        return Object.assign(cur, {
          [playlistId]: {
            ...playlistFromDB[playlistId],
            name: playlistFromDB.name,
            created: playlistFromDB.created,
            creator: playlistFromDB.creator,
            creator_name: playlistFromDB.creator_name,
            id: playlistFromDB.id,
            modified: playlistFromDB.modified,
            modifier: playlistFromDB.modifier,
            modifier_name: playlistFromDB.modifier_name,
            documentationType: DocumentationTypes.PLAYLIST,
            path: getItemPathInRepo(repoId, playlistId),
            tags: playlistFromDB.tags,
            type: 'playlist',
            creator_profile_url: playlistFromDB.creator_profile_url ?? '',
            isNew: false,
          },
        });
      }, {});
  };

  const isSwimmDoc = (swimmDbObject) => {
    return swimmDbObject.type === 'unit' && swimmDbObject.play_mode === config.UNIT_PLAY_MODES.WALKTHROUGH;
  };

  function getItemPathInRepo(repoId: string, itemId: string) {
    const name = fs_getSwmdFileNameInRepo.value(repoId, itemId);
    const path = fs_getSwmdPathInRepo.value(repoId, itemId);
    return `${path}/${name}`;
  }

  const getNeedsReviewDocs = (repoId) => {
    const notApplicableStatus = [ApplicabilityStatus.Outdated, ApplicabilityStatus.Autosyncable];
    const repoDocs = getDocsInBranch(repoId);
    const repoDocItems = Object.values(repoDocs);

    const needReview = repoDocItems.filter((doc: RepoDocItem) => notApplicableStatus.includes(doc.applicabilityStatus));
    // Documents with tokens that have moved can be potentially marked as outdated in the github app (in cases where the rename happened more than 100 commits ago)
    // Here we try to mitigate this by adding such documents to the needs review list
    const docsWithMovedSwmTokens = route.query.source === 'github_app' ? getDocsWithMovedSwmTokens(repoDocItems) : [];

    if (docsWithMovedSwmTokens.length > 0) {
      analytics.track(productEvents.DOCS_WITH_MOVED_TOKENS_ALERTED, {
        count: docsWithMovedSwmTokens.length,
      });
    }

    return [...needReview, ...docsWithMovedSwmTokens];
  };

  const docDraftsInBranchItemsForRepo = (): Record<string, RepoDocItem> => {
    const docsDraftsInBranch: Record<string, RepoDocItem> = {};
    if (drafts3Store.drafts == null) {
      return {};
    }
    for (const docDraft of drafts3Store.drafts.values()) {
      if (docDraft.type === DraftType.DOC) {
        docsDraftsInBranch[docDraft.id] = {
          name: docDraft.content.title,
          created: Timestamp.fromMillis(docDraft.created),
          creator: user.value.uid,
          creator_name: user.value.nickname,
          id: docDraft.id,
          modified: Timestamp.fromMillis(docDraft.modified || docDraft.created),
          type: 'unit',
          documentationType: DocumentationTypes.DOC,
          path: '', // todo - is this needed on drafts?
          folderId: docDraft.folderId,
          draftId: docDraft.id,
          folderIndex: docDraft.folderIndex,
          tags: docDraft.tags ?? [],
          creator_profile_url: user.value.photoURL ?? '',
          isNew: docDraft.isNew,
          modifier: user.value.uid,
          modifier_name: user.value.nickname,
        };
      }
    }
    return docsDraftsInBranch;
  };

  const playlistDraftsInBranchItemsForRepo = (): Record<string, RepoPlaylistItem> => {
    const playlistsDraftsInBranch: Record<string, RepoPlaylistItem> = {};
    if (drafts3Store.drafts == null) {
      return {};
    }
    for (const playlistDraft of drafts3Store.drafts.values()) {
      if (playlistDraft.type === DraftType.PLAYLIST) {
        playlistsDraftsInBranch[playlistDraft.id] = {
          name: playlistDraft.content.name,
          created: Timestamp.fromMillis(playlistDraft.created),
          creator: user.value.uid,
          creator_name: user.value.nickname,
          id: playlistDraft.id,
          modified: Timestamp.fromMillis(playlistDraft.modified || playlistDraft.created),
          type: playlistDraft.type,
          documentationType: DocumentationTypes.PLAYLIST,
          path: '', // todo - is this needed on drafts?
          folderId: playlistDraft.folderId,
          draftId: playlistDraft.id,
          creator_profile_url: user.value.photoURL ?? '',
          folderIndex: playlistDraft.folderIndex,
          isNew: playlistDraft.isNew,
          modifier: user.value.uid,
          modifier_name: user.value.nickname,
        };
      }
    }
    return playlistsDraftsInBranch;
  };

  function getDocApplicability(docId: string): ApplicabilityStatus {
    return currentRepoDocsInBranch.value[docId]?.applicabilityStatus;
  }

  const currentRepoDocsInBranch = computed(() => getDocsInBranch(currentRepoId.value));
  const currentRepogDocsInBranchWithoutApplicability = computed(() =>
    getDocsInBranchWithoutApplicability(currentRepoId.value as string)
  );
  const currentRepoPlaylistsInBranch = computed(() => getPlaylistsInBranch(currentRepoId.value as string));
  const currentRepoNeedsReviewDocs = computed(() => getNeedsReviewDocs(currentRepoId.value));
  const docsLoaded = ref(false);

  // holds the title and id of the current doc for breadcrumbs
  const currentDoc: Ref<{ title?: string; id: string }> = ref();

  return {
    getNeedsReviewDocs,
    getDocsInBranch,
    getPlaylistsInBranch,
    getDocsInBranchWithoutApplicability,
    docDraftsInBranchItemsForRepo,
    playlistDraftsInBranchItemsForRepo,
    getDocApplicability,
    currentRepoPlaylistsInBranch,
    currentRepoDocsInBranch,
    currentRepogDocsInBranchWithoutApplicability,
    currentRepoNeedsReviewDocs,
    docsLoaded,
    currentDoc,
  };
});
