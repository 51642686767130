export function buildDocToc(headingsElements: NodeListOf<Element>) {
  const allHeadings = Array.from(headingsElements)
    .map((element) => toHeading(element))
    .filter((element) => !!element);
  for (let i = 0; i < allHeadings.length; i++) {
    allHeadings[i].id = `toc-node-${i}`;
  }
  return { root: buildTree(allHeadings), allHeadings };
}

function toHeading(element) {
  // Convert the heading js element
  // into object to be used for the tree building
  // we use only level 1, 2, 3;
  const level = parseInt(element.getAttribute('aria-level') ?? '0', 10);

  if (level && level >= 1 && level <= 3) {
    return {
      $element: element,
      level: level,
      text: element.textContent,
      // Children will be set during tree building
      children: [],
      id: '',
    };
  }
  return null;
}

function buildTree(headings) {
  // We get all headings and build tree starting from "fake" root
  const root = {
    children: [],
    level: 0,
    depth: 0,
    id: 'root',
  };
  const stack = [root];
  for (const curHeading of headings) {
    // This can never be false
    // since root is always in the stack
    while (stack.length > 0) {
      // Pop the stack till you find the element
      // that is the parent of the new node
      // the fake root is always such parent
      const leaf = stack.pop();
      if (leaf.level < curHeading.level) {
        stack.push(leaf);
        stack.push(curHeading);
        leaf.children.push(curHeading);
        break;
      }
    }
  }
  return root;
}
