import debounce from 'lodash-es/debounce';
import { Extension, type JSONContent } from '@tiptap/core';
import { Plugin, PluginKey, TextSelection } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { Node as ProseMirrorNode, Slice } from '@tiptap/pm/model';
import { isDescendantOfType, isGrandParentOfType, isParentOfType } from '@/tiptap/utils';
import Code from '@tiptap/extension-code';
import { TEXT_COMPLETION_CAP_MULTIPLIER, type TextCompletionContext, getLoggerNew, productEvents } from '@swimm/shared';
import Link from '@/tiptap/extensions/Link';

import { SOURCE_EXTERNAL, getSwimmEditorServices } from '@/tiptap/extensions/Swimm';
import type { SwimmEditorServices } from '@/tiptap/editorServices';
import type { SwimmParserMeta } from '@/swmd/parser';
import { nodesToMarkdown } from '@/swmd/nodesToMarkdown';

const logger = getLoggerNew(__modulename);

const SHOWN_ANALYTIC_TIMEOUT_MS = 800;

async function generateContext(
  position: number,
  doc: ProseMirrorNode,
  swimmEditorServices: SwimmEditorServices
): Promise<TextCompletionContext | null> {
  const context: TextCompletionContext = { before: '', after: '' };

  const nodes: Slice = doc.slice(0, position, true);

  const contextBefore = await nodesToMarkdown(nodes, {
    repoId: swimmEditorServices.repoId.value,
    workspaceId: swimmEditorServices.workspaceId.value,
    baseUrl: swimmEditorServices.baseUrl,
  });

  let contextAfter = '';
  if (position < doc.nodeSize - 3) {
    const nodes: Slice = doc.slice(position + 1, undefined, true);

    contextAfter = await nodesToMarkdown(nodes, {
      repoId: swimmEditorServices.repoId.value,
      workspaceId: swimmEditorServices.workspaceId.value,
      baseUrl: swimmEditorServices.baseUrl,
    });
  }

  if (contextBefore.replaceAll('\n', '') !== '') {
    context.before = contextBefore;
  }

  if (contextAfter != null && contextAfter.replaceAll('\n', '') !== '') {
    context.after = contextAfter;
  }

  if (!context.before && !context.after) {
    return null;
  }

  return context;
}

async function generateContextForSnippet(
  position: number,
  doc: ProseMirrorNode,
  swimmEditorServices: SwimmEditorServices
): Promise<TextCompletionContext> {
  const context: TextCompletionContext = { before: '', after: '' };
  const $pos = doc.resolve(position);
  const snippet = $pos.node(-1).copy(null);
  const snippetCommentStart = $pos.start(-1);
  const snippetCommentEnd = $pos.end(-1);
  const snippetComment = doc.slice(snippetCommentStart, snippetCommentEnd).content;
  const prefix = new Slice(snippetComment.addToStart(snippet), 0, 0);

  const contextBefore = await nodesToMarkdown(prefix, {
    repoId: swimmEditorServices.repoId.value,
    workspaceId: swimmEditorServices.workspaceId.value,
    baseUrl: swimmEditorServices.baseUrl,
  });

  if (contextBefore.replaceAll('\n', '') !== '') {
    context.before = contextBefore;
  }

  const snippetContentSize = $pos.node(-1).content.size;

  // Slice from the position and up to the end of the snippet comment
  // If $pos.nodeAfter is null - then we are at the end of the comment, start and end of slice are the same
  const suffix = doc.slice(
    $pos.pos,
    !$pos.nodeAfter ? $pos.pos : $pos.pos + snippetContentSize - $pos.parentOffset - 1
  );
  const contextAfter = await nodesToMarkdown(suffix, {
    repoId: swimmEditorServices.repoId.value,
    workspaceId: swimmEditorServices.workspaceId.value,
    baseUrl: swimmEditorServices.baseUrl,
  });

  if (contextAfter.replaceAll('\n', '') !== '') {
    context.after = contextAfter;
  }

  return context;
}

function getAnalyticsPayload(doc: ProseMirrorNode, suggestion: string, position: number, cost?: number) {
  const parentNodeName = isDescendantOfType(doc, position, 'swmSnippet')
    ? 'swmSnippet'
    : doc.resolve(position).parent.type.name;
  return {
    'Autocompletion Word Count': suggestion.split(' ').length,
    'Parent Node Name': parentNodeName,
    ...(cost ? { Cost: cost / TEXT_COMPLETION_CAP_MULTIPLIER } : {}), // We want to show the cost in $, not in the multiplier
  };
}

interface TextCompletionPluginState {
  decorationSet: DecorationSet;
  suggestion: string;
}

export const TEXT_COMPLETION_PLUGIN_KEY = new PluginKey<TextCompletionPluginState>('textCompletion');

interface TextCompletionStorage {
  parseSwmdContent?: (content: string, meta: SwimmParserMeta) => JSONContent;
  preserveLeadingWhitespace?: (text: string) => string;
  debouncedSendShownEvent?: ReturnType<typeof debounce>;
}

export interface TextCompletionOptions {
  className: string;
  delayBeforeShow: number;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textCompletion: {
      applySuggestion: (suggestion: string) => ReturnType;
    };
  }
}

function renderSuggestion(suggestion: string, className: string): Node {
  // Create a span for the suggestion
  const element = document.createElement('span');
  element.setAttribute('data-testid', 'text-completion');
  element.innerText = suggestion;
  element.classList.add(className);

  return element;
}

export default Extension.create<TextCompletionOptions, TextCompletionStorage>({
  name: 'textCompletion',

  addOptions() {
    return {
      className: 'text-completion',
      delayBeforeShow: 800,
    };
  },

  onCreate() {
    (async () => {
      const { parseSwmdContent } = await import('../../swmd/parser');
      const { preserveLeadingWhitespaceInMarkdown } = await import('../../swmd/serializer');
      this.storage.parseSwmdContent = parseSwmdContent;
      this.storage.preserveLeadingWhitespace = preserveLeadingWhitespaceInMarkdown;
      this.storage.debouncedSendShownEvent = debounce(
        (swimmEditorServices, payload) =>
          swimmEditorServices.external.trackEvent(productEvents.DOC_AUTOCOMPLETION_SHOWN, payload),
        SHOWN_ANALYTIC_TIMEOUT_MS
      );
    })()
      .then(() => {
        this.editor.view.dispatch(this.editor.view.state.tr.setMeta(TEXT_COMPLETION_PLUGIN_KEY, { rerun: true }));
      })
      .catch((err) => {
        logger.error({ err }, 'Failed to async load dependencies. TextCompletion will not show results');
      });
  },

  addCommands() {
    return {
      applySuggestion:
        (suggestion) =>
        ({ chain, editor }) => {
          if (!this.storage.parseSwmdContent || !this.storage.preserveLeadingWhitespace) {
            return false;
          }
          const swimmEditorServices = getSwimmEditorServices(editor);
          const content = this.storage.parseSwmdContent(this.storage.preserveLeadingWhitespace(suggestion), {
            repoId: swimmEditorServices.repoId.value,
            repoName: swimmEditorServices.getRepoName(swimmEditorServices.repoId.value),
          });

          if (!content?.content) {
            return false;
          }

          const ch = chain();
          ch.command(({ tr }) => {
            tr.setMeta(TEXT_COMPLETION_PLUGIN_KEY, { apply: true });
            return true;
          });
          ch.focus();
          for (const cell of content.content) {
            if (cell.type === 'paragraph' && cell.content != null) {
              ch.insertContent(cell.content);
            } else {
              ch.insertContent(cell);
            }
          }
          // We send the shown analytic before the 'accepted' to not ruin the funnel.
          // This will happen in the case the suggestion is accepted very quickly, before
          // the shown event had a chance to be sent.
          this.storage.debouncedSendShownEvent?.flush();
          swimmEditorServices.external.trackEvent(
            productEvents.DOC_AUTOCOMPLETION_ACCEPTED,
            getAnalyticsPayload(editor.state.doc, suggestion, editor.state.selection.from)
          );
          return ch.run();
        },
    };
  },

  addProseMirrorPlugins() {
    const editor = this.editor;
    const swimmEditorServices = getSwimmEditorServices(editor);
    const debouncedCompletion = debounce(async (position: number, doc: ProseMirrorNode) => {
      let context: TextCompletionContext | null;

      if (isParentOfType(doc, position, ['swmSnippet']) || isGrandParentOfType(doc, position, ['swmSnippet'])) {
        context = await generateContextForSnippet(position, doc, swimmEditorServices);
      } else {
        context = await generateContext(position, doc, swimmEditorServices);
      }
      if (context != null) {
        try {
          const { generatedText, cost } = await swimmEditorServices.external.completeText(context);
          if (generatedText) {
            editor.view.dispatch(
              editor.state.tr.setMeta(TEXT_COMPLETION_PLUGIN_KEY, { suggestion: generatedText, position, cost })
            );
          }
        } catch (err) {
          editor.view.dispatch(editor.state.tr.setMeta(TEXT_COMPLETION_PLUGIN_KEY, { suggestion: '', position }));
          // Do not log empty replies
          if (err instanceof Error && !err.message.includes('Returned empty completion')) {
            logger.error({ err }, 'Error while generating text completion');
          }
        }
      }
    }, this.options.delayBeforeShow);

    return [
      new Plugin<TextCompletionPluginState>({
        key: TEXT_COMPLETION_PLUGIN_KEY,
        state: {
          init() {
            return { decorationSet: DecorationSet.empty, suggestion: '' };
          },
          apply: (transaction, _state) => {
            let decorationSet = DecorationSet.empty;

            // We will either replace the suggestion or remove it, in both cases we should not send the 'shown'
            // event if it hasn't been sent yet, so we cancel it now.
            this.storage.debouncedSendShownEvent?.cancel();

            if (
              transaction.getMeta(TEXT_COMPLETION_PLUGIN_KEY)?.apply ||
              Object.entries(editor.storage).some(
                ([key, value]) => ['slashCommands', 'swmToken', 'swmMention', 'swimmport'].includes(key) && value.shown
              )
            ) {
              return { decorationSet, suggestion: '' };
            }

            if (
              !swimmEditorServices.editable.value || // No need to autocomplete if the editor is not in an editable state
              !swimmEditorServices.external.isAIGenerationEnabledForRepo()
            ) {
              return { decorationSet, suggestion: '' };
            }

            const selection = transaction.selection;
            if (!(selection instanceof TextSelection)) {
              // Selection is a NodeSelection - containing a single node, such as table cells, gap cursor etc...
              return { decorationSet, suggestion: '' };
            }

            const $cursor = selection.$cursor;

            if (!selection.empty || !$cursor) {
              // If the user has text selected - do not trigger the completion
              return { decorationSet, suggestion: '' };
            }

            if (
              (transaction.docChanged && !transaction.getMeta(SOURCE_EXTERNAL)) ||
              transaction.getMeta(TEXT_COMPLETION_PLUGIN_KEY)?.rerun
            ) {
              // Perhaps we want to first generate context and compare it with the previous context first - and if not changed just `return set.map(transaction.mapping, transaction.doc)`

              if (!$cursor.doc.textContent.match(/\S/gm)) {
                // $cursor.doc.textContent is only whitespaces, do not trigger completion
                return { decorationSet, suggestion: '' };
              }

              // Instead of selection I want to validate:
              // 1. The cursor is in a valid position
              // 2. I have valid context to generate with
              // 3. The cursor is not in an empty paragraph (e.g. when the user presses enter)
              // 4. The cursor is not nested in a table or inside a code block, mermaid block, or a link
              if (
                $cursor.node().marks.some((mark) => [Code.name, Link.name].includes(mark.type.name)) ||
                ($cursor.node()?.type.name === 'paragraph' && $cursor.node().textContent?.trim() === '') ||
                isParentOfType(transaction.doc, $cursor.pos, ['mermaid', 'codeBlock']) ||
                isDescendantOfType(transaction.doc, $cursor.pos, 'table')
              ) {
                return { decorationSet, suggestion: '' };
              }

              if (!$cursor.nodeAfter || $cursor.nodeAfter.isBlock) {
                void debouncedCompletion($cursor.pos, transaction.doc);
                return { decorationSet, suggestion: '' };
              }
            }

            const payload = transaction.getMeta(TEXT_COMPLETION_PLUGIN_KEY);

            if (payload === undefined || transaction.docChanged) {
              return { decorationSet, suggestion: '' };
            }

            const { suggestion, position, cost } = payload;

            if (position !== $cursor.pos) {
              // Does cursor position trigger docChanged?
              // If so - a new debounce has already started... Otherwise - call debouncedCompletion?
              // TODO: No way to stop an ongoing call to complete the text
              return { decorationSet, suggestion: '' };
            }

            if (suggestion === '' || !suggestion.match(/\S/gm)) {
              // If suggestion is empty or only whitespaces - do not display it
              return { decorationSet, suggestion: '' };
            }

            const suggestionDecoration = Decoration.widget(
              position,
              () => renderSuggestion(suggestion, this.options.className),
              { side: 1 }
            );
            // We send the shown analytic only after a delay, to avoid counting suggestions that are shown for a miniscule amount of time and
            // had no chance to be read, as not-accepted.
            this.storage.debouncedSendShownEvent?.(
              swimmEditorServices,
              getAnalyticsPayload(transaction.doc, suggestion, position, cost ?? 0)
            );
            decorationSet = decorationSet.add(transaction.doc, [suggestionDecoration]);

            return { decorationSet, suggestion };
          },
        },
        props: {
          decorations(state) {
            return this.getState(state)?.decorationSet;
          },
        },
      }),
    ];
  },

  addKeyboardShortcuts() {
    return {
      Tab: ({ editor }) => {
        const swimmEditorServices = getSwimmEditorServices(editor);
        if (!swimmEditorServices.external.isAIGenerationEnabledForRepo()) {
          return false;
        }

        if (!editor) {
          return false;
        }

        const suggestion = TEXT_COMPLETION_PLUGIN_KEY.getState(editor.state)?.suggestion;
        if (suggestion) {
          return editor.commands.applySuggestion(suggestion);
        }

        return false;
      },
      Escape: ({ editor }) => {
        if (TEXT_COMPLETION_PLUGIN_KEY.getState(editor.state)?.suggestion) {
          editor.commands.command(({ dispatch, tr }) => {
            if (dispatch) {
              tr.setMeta(TEXT_COMPLETION_PLUGIN_KEY, { suggestion: '' });
              dispatch(tr);
              return true;
            }
            return false;
          });
          return true;
        }
        return false;
      },
    };
  },
});
