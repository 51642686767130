<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';
import type { Editor } from '@tiptap/vue-3';
import { type Link, SwmSelectionContentLink } from '@swimm/reefui';
import { SwmSymbolLinkType, type WorkspaceRepo, getLoggerNew } from '@swimm/shared';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';

const logger = getLoggerNew(__modulename);

const props = withDefaults(
  defineProps<{
    editor: Editor;
    hide: () => void;
    linkType: SwmSymbolLinkType;
  }>(),
  {
    linkType: SwmSymbolLinkType.Doc,
  }
);

const swimmEditorServices = computed(() => getSwimmEditorServices(props.editor));

const selectionContent = ref<InstanceType<typeof SwmSelectionContentLink>>();
const filter = ref('');
const repoId = ref<string | null>(swimmEditorServices.value.repoId.value);
const showReposList = ref(false);
const links = ref<Link[]>();
const loading = ref(true);

const filteredLinks = computed(() => {
  if (showReposList.value) {
    return;
  }

  return links.value?.filter((link) => link.name.toLowerCase().includes(filter.value.toLowerCase()));
});

const filteredWorkspaceRepos = computed<WorkspaceRepo[]>(() => {
  if (swimmEditorServices.value.repos.value.loading) {
    return [];
  }

  if (!showReposList.value) {
    const selectedRepo = swimmEditorServices.value.repos.value.repos.find((repo) => repo.id === repoId.value);
    if (selectedRepo == null) {
      return [];
    }

    return [selectedRepo];
  } else {
    return swimmEditorServices.value.repos.value.repos.filter((repo) =>
      repo.name.toLowerCase().includes(filter.value.toLowerCase())
    );
  }
});

async function fetchLinks() {
  if (repoId.value == null) {
    return;
  }

  loading.value = true;
  try {
    const isCrossRepo = repoId.value !== swimmEditorServices.value.repoId.value;
    const branch = !isCrossRepo
      ? swimmEditorServices.value.branch.value
      : swimmEditorServices.value.repos.value.repos?.find((repo) => repo.id === repoId.value)?.defaultBranch;

    if (branch) {
      links.value = (
        await swimmEditorServices.value.external.getRepoLinks(repoId.value, branch, props.linkType)
      ).filter((link) => link.id !== swimmEditorServices.value.unitId.value);
    } else {
      links.value = [];
    }
  } finally {
    loading.value = false;
  }
}

watch(
  () => [repoId.value, props.linkType],
  async () => {
    try {
      await fetchLinks();
      // TODO I wonder if this can't be a reactive side effect?
      resetItemFocus();
    } catch (err) {
      logger.error({ err }, 'Failed getting links');
    }
  },
  { immediate: true }
);

async function handleSelectRepo(repo: WorkspaceRepo) {
  if (repo.id === repoId.value) {
    if (!swimmEditorServices.value.hasAccessToCrossRepo) {
      return;
    }

    filter.value = '';
    repoId.value = null;
    showReposList.value = true;
  } else {
    filter.value = '';
    repoId.value = repo.id;
    showReposList.value = false;
  }
}

function handleSelectLink(link: Link) {
  if (repoId.value == null) {
    return;
  }

  swimmEditorServices.value.swmLinkSelection.value = { ...link, repoId: repoId.value };
  props.hide();
}

async function resetItemFocus() {
  if (selectionContent.value) {
    await nextTick();
    await selectionContent.value.resetItemFocus();
  }
}
</script>

<template>
  <SwmSelectionContentLink
    ref="selectionContent"
    v-model="filter"
    data-testid="swm-link-selection-popover"
    :workspace-repos="filteredWorkspaceRepos"
    :links="filteredLinks"
    :cross-repo-support="swimmEditorServices.hasAccessToCrossRepo"
    :loading="swimmEditorServices.repos.value.loading || loading"
    :resource-type="props.linkType"
    @select-repo="handleSelectRepo"
    @select-link="handleSelectLink"
    @close="hide()"
  />
</template>
