import { type Ref, computed, isRef, ref, watch } from 'vue';
import type { GroupedSelectedIndex } from '../../types/transitional';
import { getHandlerKey, normalizeAdditionalHandlers } from '../utilties';

export type GroupedItemSelectionKeyHandlersOptions = {
  // Supply additional keydown key handlers i.e. Enter.
  // which will be returned in the `onKeyDown` response.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalHandlers?: Record<string, (payload: any) => boolean>;

  // Think pagination, this option allows us to specify any
  // additional indexes beyond the indexes of the items supplied.
  // The string/record specified are returned in the keyDown handlers
  // events.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  includeAdditionalIndexes?: any[][];

  // When items change the selectedIndex is reset to 0. This
  // option allows us to prevent this behaviour. Think pagination,
  // if a user as clicked "show more" we don't want to take them
  // back to the beginning of the list.
  preventIndexReset?: boolean;

  // Key navigation specific, are the groups visually organised in
  // rows or columns? Defaults to 'rows' and effects is ArrowLeft and ArrowRight
  // are triggered.
  groupOrientation?: 'column' | 'row';
};

export function useGroupedItemSelectionKeyHandlers<T>(
  items: Ref<T[][]>,
  optionsInput?: GroupedItemSelectionKeyHandlersOptions | Ref<GroupedItemSelectionKeyHandlersOptions>
) {
  const options = optionsInput ? (isRef(optionsInput) ? optionsInput : ref(optionsInput)) : undefined;
  const groupOrientation = options?.value.groupOrientation || 'row';
  const groupIndex = ref(0);
  const itemIndex = ref(0);
  const selectedIndex = computed<GroupedSelectedIndex>(() => [groupIndex.value, itemIndex.value]);

  // Reset the group and item indexs whenever items changes.
  watch(
    () => items.value,
    () => {
      if (!options?.value.preventIndexReset) {
        groupIndex.value = 0;
        itemIndex.value = 0;
      }
    }
  );

  function onKeyDown(event: KeyboardEvent) {
    const handlerKey = getHandlerKey(event);
    const normalizedAdditionalHandlers =
      options?.value?.additionalHandlers && normalizeAdditionalHandlers(options?.value?.additionalHandlers);

    // It's common that need to handle other keyboard commands beyond navigation.
    // These can be pased in via additionalHanders and are applied here.
    if (normalizedAdditionalHandlers && normalizedAdditionalHandlers[handlerKey]) {
      event.preventDefault();

      const selectedItem = items.value?.[groupIndex.value]?.[itemIndex.value];
      const result = normalizedAdditionalHandlers[handlerKey](selectedItem);

      if (result) {
        return true;
      }
    }

    const additionalIndexes = computed(() => options?.value?.includeAdditionalIndexes?.[groupIndex.value].length || 0);

    switch (event.key) {
      case 'ArrowDown':
        if (!items.value.length || items.value[groupIndex.value] == null) {
          return false;
        }

        // Apply incremental circular navigation between items.
        if (itemIndex.value === items.value[groupIndex.value].length - 1 + additionalIndexes.value) {
          itemIndex.value = 0;

          if (groupOrientation === 'column') {
            // Apply incremental circular navigation between groups.
            if (groupIndex.value === items.value.length - 1) {
              groupIndex.value = 0;
            } else {
              groupIndex.value++;
            }
          }
        } else {
          itemIndex.value++;
        }
        return true;
      case 'ArrowUp':
        if (!items.value.length) {
          return false;
        }

        // Apply decremental circular navigation between items.
        if (itemIndex.value === 0) {
          if (groupOrientation === 'column') {
            // Apply incremental circular navigation between groups.
            if (groupIndex.value === items.value.length - 1) {
              groupIndex.value = 0;
            } else {
              groupIndex.value = items.value.length - 1;
            }
          }

          if (items.value[groupIndex.value] == null) {
            return false;
          }
          itemIndex.value = items.value[groupIndex.value].length - 1 + additionalIndexes.value;
        } else {
          itemIndex.value--;
        }
        return true;
      case 'ArrowRight':
        // If groups are columned (i.e. in one long listing), we don't
        // want to trigger right/left interactions.
        if (groupOrientation === 'column' || items.value.length < 2) {
          return false;
        }

        // Apply incremental circular navigation between groups.
        if (groupIndex.value === items.value.length - 1) {
          groupIndex.value = 0;
        } else {
          groupIndex.value++;
        }

        if (items.value[groupIndex.value] == null) {
          return false;
        }

        // If the group we're navigating to has fewer items than the group
        // we're currently in we set the itemIndex to the last index of
        // the new group.
        if (itemIndex.value > items.value[groupIndex.value].length - 1 + additionalIndexes.value) {
          itemIndex.value = items.value[groupIndex.value].length - 1 + additionalIndexes.value;
        }
        return true;
      case 'ArrowLeft':
        // If groups are columned (i.e. in one long listing), we don't
        // want to trigger right/left interactions.
        if (groupOrientation === 'column' || items.value.length < 2) {
          return false;
        }

        // Apply decremental circular navigation between groups.
        if (groupIndex.value === 0) {
          groupIndex.value = items.value.length - 1;
        } else {
          groupIndex.value--;
        }

        if (items.value[groupIndex.value] == null) {
          return false;
        }

        // If the group we're navigating to has fewer items than the group
        // we're currently in we set the itemIndex to the last index of
        // the new group.
        if (itemIndex.value > items.value[groupIndex.value].length - 1 + additionalIndexes.value) {
          itemIndex.value = items.value[groupIndex.value].length - 1 + additionalIndexes.value;
        }
        return true;
    }

    return false;
  }

  return {
    selectedIndex,
    onKeyDown,
  };
}
