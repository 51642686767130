<template>
  <AIGenerationCTAModal :modal-opened="showCTAModal" :is-admin="isAdmin" @close="showCTAModal = false">
  </AIGenerationCTAModal>
  <BubbleMenu
    v-if="editor && !showCTAModal"
    ref="menu"
    class="menububble"
    data-testid="bubble-menu"
    :editor="editor"
    :tippy-options="{
      duration: 100,
      offset: menuOffset as [number, number],
      hideOnClick: true,
      theme: 'none',
      onHidden: () => {
        hideLinkMenu();
        hideAiMenu();
      },
    }"
    :style="`visibility: visible`"
    :should-show="shouldShow"
  >
    <div v-if="aiMenuActive" @keydown.esc="hideAiMenu">
      <AISuggestionMenu :repo-id="repoId" :workspace-id="workspaceId" @close-ai-menu="closeAIMenu" :editor="editor" />
    </div>
    <template v-else>
      <form v-if="linkMenuIsActive" class="link-form" @submit.prevent="setLinkUrl(linkUrl)">
        <TextField
          ref="linkInput"
          v-model="linkUrl"
          data-testid="bubble-href-field"
          class="url-field"
          type="text"
          placeholder="https://"
          @keydown.esc="hideLinkMenu"
        />
        <div>
          <IconButton
            name="trash-outline"
            data-testid="bubble-href-unset"
            class="menu-button magic-wand"
            @click="unsetLinkUrl(editor)"
          />
          <IconButton name="check" data-testid="bubble-href-check" class="menu-button" @click="setLinkUrl(linkUrl)" />
        </div>
        <ErrorBox v-if="error">{{ error }}</ErrorBox>
      </form>

      <template v-else>
        <IconButton
          class="menu-button"
          name="bold"
          data-testid="bubble-bold-button"
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="editor?.chain().focus().toggleBold().run()"
          v-tooltip="{ content: `Bold (${getKeyboardShortcut('B')})`, distance: '12' }"
        >
          bold
        </IconButton>
        <IconButton
          class="menu-button"
          name="italic"
          data-testid="bubble-italic-button"
          :class="{ 'is-active': editor.isActive('italic') }"
          @click="editor?.chain().focus().toggleItalic().run()"
          v-tooltip="{ content: `Italic (${getKeyboardShortcut('I')})`, distance: '12' }"
        >
          italic
        </IconButton>
        <IconButton
          class="menu-button"
          name="text-headline1"
          data-testid="bubble-h1-button"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
          @click="editor?.chain().focus().toggleHeading({ level: 1 }).run()"
        >
          H1
        </IconButton>
        <IconButton
          class="menu-button"
          name="text-headline2"
          data-testid="bubble-h2-button"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
          @click="editor?.chain().focus().toggleHeading({ level: 2 }).run()"
        >
          H2
        </IconButton>

        <IconButton
          class="menu-button"
          name="text-headline3"
          data-testid="bubble-h3-button"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          @click="editor?.chain().focus().toggleHeading({ level: 3 }).run()"
        >
          H3
        </IconButton>
        <IconButton
          name="ul"
          class="menu-button"
          data-testid="bubble-ul-button"
          :class="{ 'is-active': editor.isActive('bulletList') }"
          @click="editor?.chain().focus().toggleBulletList().run()"
        />
        <IconButton
          name="ol"
          class="menu-button"
          data-testid="bubble-ol-button"
          :class="{ 'is-active': editor.isActive('orderedList') }"
          @click="editor?.chain().focus().toggleOrderedList().run()"
        />
        <IconButton
          name="dev"
          class="menu-button"
          data-testid="bubble-code-button"
          :class="{ 'is-active': editor.isActive('code') }"
          @click="editor?.chain().focus().toggleCode().run()"
        />
        <IconButton
          name="quote"
          class="menu-button"
          data-testid="bubble-quote-button"
          :class="{ 'is-active': editor.isActive('blockquote') }"
          @click="editor?.chain().focus().toggleBlockquote().run()"
        />
        <IconButton
          name="link"
          class="menu-button"
          data-testid="bubble-link"
          :class="{ 'is-active': editor.isActive('link') }"
          @click="showLinkMenu(editor?.getAttributes('link')?.href)"
        />
        <IconButton
          name="magic"
          class="menu-button magic-wand"
          v-if="shouldShowAIIcon"
          data-testid="bubble-ai"
          :class="{ 'is-active': editor.isActive('ai') }"
          @click="() => showAiMenu()"
          v-tooltip="{ content: `Improve with AI`, distance: '12' }"
        />
      </template>
    </template>
  </BubbleMenu>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia';
import { type Ref, computed, nextTick, ref } from 'vue';
import { useStore } from 'vuex';

import {
  type DBRepo,
  DUMMY_REPO_ID,
  Keyboard,
  firestoreCollectionNames,
  getLoggerNew,
  isSwimmResourceLink,
  keyboardShortcutUtils,
  productEvents,
} from '@swimm/shared';
import { BubbleMenu, Editor } from '@tiptap/vue-3';
import { EditorState, NodeSelection, TextSelection } from '@tiptap/pm/state';
import type { EditorView } from '@tiptap/pm/view';

import { ErrorBox, IconButton, TextField } from '@swimm/ui';
import { AIGenerationCTAModal, AISuggestionMenu, getParentNodeForPosition, useGoToSection } from '@swimm/editor';

import { useAnalytics } from '@/common/composables/useAnalytics';
import * as firestoreWrapper from '@/adapters-common/firestore-wrapper';
import { useAuthStore } from '@/modules/core/stores/auth-store';

export default {
  name: 'EditorBubbleMenu',
  emits: ['insert-swimm-link', 'set-as-link'],
  components: { IconButton, BubbleMenu, ErrorBox, TextField, AISuggestionMenu, AIGenerationCTAModal },
  props: {
    editor: { type: Editor, default: undefined },
    pushBubbleMenu: { type: Boolean, default: false },
    handleBottomBubbleMenu: { type: Boolean, default: false },
    workspaceId: { type: String, required: true },
    repoId: { type: String, required: true, default: null },
  },
  setup(props, { emit }) {
    const linkUrl: Ref<string | null> = ref(null);
    const error = ref('');
    const linkMenuIsActive = ref(false);
    const aiMenuActive = ref(false);
    const linkInput = ref();
    const logger = getLoggerNew(__modulename);
    const showCTAModal = ref(false);
    const analytics = useAnalytics();
    const store = useStore();

    const { user } = storeToRefs(useAuthStore());

    const { linkIncludesHash } = useGoToSection();

    const menuOffset = computed(() => {
      // Because of overflow-x in tables the bubblemenu moves to the bottom in the header when there are data lines,
      // so we move its position to be seen inside the table bounds
      return props.pushBubbleMenu ? (props.handleBottomBubbleMenu ? [100, -20] : [100, 65]) : [50, 65];
    });

    const isAdmin = computed(() => store.getters['database/db_isWorkspaceAdmin'](props.workspaceId, user.value.uid));

    function hideLinkMenu() {
      linkUrl.value = null;
      error.value = '';
      linkMenuIsActive.value = false;
    }
    function hideAiMenu() {
      aiMenuActive.value = false;
    }

    async function showLinkMenu(href: string | undefined) {
      linkUrl.value = href ?? '';
      linkMenuIsActive.value = true;
      await nextTick();
      linkInput.value.focus();
    }
    const isDummyRepo = computed(() => DUMMY_REPO_ID === props.repoId);

    async function isAIGenerationEnabled() {
      if (!props.repoId) {
        return false;
      }
      try {
        const dbItem = await firestoreWrapper.getDocRecursive([firestoreCollectionNames.REPOSITORIES, props.repoId]);
        const repoMetadata = dbItem as never as DBRepo;
        return repoMetadata?.integrations?.generative_ai_enabled ?? false;
      } catch (err) {
        logger.error({ err }, `Failed to get resource ${err}`);
        return false;
      }
    }
    async function showAiMenu() {
      if (!isDummyRepo.value && !(await isAIGenerationEnabled())) {
        showCTAModal.value = true;
        analytics.track(
          productEvents.SHOWN_GEN_AI_DISABLED_POPUP,
          {
            isAdmin: isAdmin.value,
            Platform: 'WebApp',
            Context: 'Improve with AI',
          },
          { addRouteParams: true, shouldSendCloud: true }
        );
      } else {
        analytics.track(productEvents.CLICKED_IMPROVE_WITH_AI, {
          Platform: 'WebApp',
        });
        aiMenuActive.value = true;
        await nextTick();
      }
    }

    async function closeAIMenu(callback: () => void) {
      aiMenuActive.value = false;
      await nextTick();
      callback();
    }

    function shouldShow({ view, state, from, to }: { view: EditorView; state: EditorState; from: number; to: number }) {
      const { doc, selection } = state;
      const { empty } = selection;
      // XXX https://github.com/ueberdosis/tiptap/issues/2979
      // Sometime check for `empty` is not enough.
      // Doubleclick an empty paragraph returns a node size of 2.
      // So we check also for an empty text size.
      const isEmptyTextBlock = !doc.textBetween(from, to).length && state.selection instanceof TextSelection;

      if (!view.hasFocus() || empty || isEmptyTextBlock || state.selection instanceof NodeSelection) {
        aiMenuActive.value = false;
        return false;
      }

      const nodeType = getParentNodeForPosition(from, state)?.type.name;
      const typesExcluded = ['mermaid', 'codeBlock'];
      return !typesExcluded.includes(nodeType);
    }

    const isGenAiDisabledInWorkspace = computed(() => {
      const workspaceData = store.getters['database/db_getWorkspace'](props.workspaceId);
      return workspaceData?.settings?.disable_gen_ai ?? false;
    });

    const shouldShowAIIcon = computed(() => {
      if (isGenAiDisabledInWorkspace.value) {
        return false;
      }
      if (props.editor) {
        const { state } = props.editor;
        const { selection } = state;
        const { from, to } = selection;
        let blockFound = false;
        const typesExcluded = ['mermaid', 'codeBlock', 'image', 'tableCell', 'tableRow'];
        state.doc.nodesBetween(from, to, (node) => {
          if (typesExcluded.includes(node.type?.name)) {
            blockFound = true;
          }
        });
        return !blockFound;
      }
      return false;
    });

    function setLinkUrl(url: string | null) {
      if (!url || url.length === 0) {
        error.value = 'Not a valid link';
        return;
      }

      if (isSwimmResourceLink(url) && !linkIncludesHash(url)) {
        emit('insert-swimm-link', url);
      } else {
        emit('set-as-link', url);
      }
      hideLinkMenu();
    }

    function unsetLinkUrl(editor: Editor | undefined) {
      try {
        editor?.chain().focus().extendMarkRange('link').unsetLink().run();
      } catch (err) {
        logger.error({ err }, `unset link error occurred: ${err}`);
      }
      hideLinkMenu();
    }

    function getKeyboardShortcut(key: string) {
      return keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.CTRL, key);
    }

    return {
      linkUrl,
      error,
      linkMenuIsActive,
      linkInput,
      aiMenuActive,
      menuOffset,
      showCTAModal,
      isAdmin,
      closeAIMenu,
      shouldShowAIIcon,
      hideLinkMenu,
      hideAiMenu,
      showLinkMenu,
      shouldShow,
      setLinkUrl,
      unsetLinkUrl,
      getKeyboardShortcut,
      showAiMenu,
    };
  },
};
</script>

<style scoped lang="postcss">
.magic-wand {
  display: flex;
  cursor: pointer;
  padding: var(--space-xs);
  border-radius: 4px;
  background-color: var(--color-code-autosync-sandwich);

  &:hover {
    background-color: var(--color-code-autosync-meat);
  }
}

.menububble {
  position: absolute;
  z-index: 20;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: var(--space-xs);
  font-size: var(--body-S);
  border: 1px solid var(--color-border-default);
  border-radius: 4px;
  background: var(--color-bg);
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s, visibility 0.2s;
  transform: translateX(-50%);
  gap: var(--space-xs);
  box-shadow: var(--box-shadow-small);

  .link-form {
    width: 272px;
    display: flex;
    align-items: center;
    gap: var(--space-base);
  }

  .url-field {
    :deep(input) {
      padding: 2px;
    }

    :deep(.field-input) {
      padding-left: 2px;
    }
  }
}

.menu-button {
  &.is-active {
    background: var(--color-selected);
  }
}
</style>
