<template>
  <div>
    <h2 class="title">Reset password</h2>
    <template v-if="isResetPasswordSucceeded">
      <p>Your password has been successfully reset!</p>
      <router-link class="login-link" to="/login">Go back to Login</router-link>
    </template>
    <template v-else-if="isPasswordResetVerified">
      <p>
        Reset password for <span class="email">{{ email }}</span>
      </p>
      <form class="reset-password-form" @keydown.enter.prevent="onPasswordReset" @submit.prevent="onPasswordReset">
        <TextField
          v-model.trim="password"
          v-tooltip="passwordRequirementsText"
          type="password"
          placeholder="New password"
          focus-first
          class="text-field"
          @update:model-value="onInputChange"
        />
        <TextField
          v-model.trim="passwordConfirmation"
          type="password"
          placeholder="Confim password"
          @update:model-value="onInputChange"
        />
        <Action class="reset-button" :loading="isLoading">Reset password</Action>
      </form>
    </template>
    <ErrorBox class="error">{{ error }}</ErrorBox>
    <router-link v-if="showLinkToLogin" class="login-link" to="/login">Go back to Login</router-link>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { isPasswordValid } from '@/common/utils/authUtils';
import { getLoggerNew } from '@swimm/shared';
import { ErrorBox, TextField } from '@swimm/ui';

const logger = getLoggerNew(__modulename);

export default {
  components: { TextField, ErrorBox },
  props: {
    verificationCode: { type: String, required: true },
  },
  data() {
    return {
      isPasswordResetVerified: false,
      isResetPasswordSucceeded: false,
      password: '',
      passwordConfirmation: '',
      error: '',
      email: '',
      isLoading: false,
      showLinkToLogin: false,
      passwordRequirementsText:
        'Password must contain at least 8 characters, have 1 uppercase letter, 1 lowercase letter, and 1 number, and not be weak',
    };
  },
  created() {
    this.verifyPasswordResetCode();
  },
  methods: {
    async onPasswordReset() {
      if (this.password !== this.passwordConfirmation) {
        this.error = 'Passwords do not match';
        return;
      }
      if (!(await isPasswordValid(this.password))) {
        this.error = this.passwordRequirementsText;
        return;
      }

      this.isLoading = true;
      try {
        await firebase.auth().confirmPasswordReset(this.verificationCode, this.password);
        this.isResetPasswordSucceeded = true;
      } catch (err) {
        this.isLoading = false;
        this.error = 'Something went wrong';
        logger.error({ err }, `Cannot reset password: ${err.message}`);
      }
    },
    async verifyPasswordResetCode() {
      try {
        this.email = await firebase.auth().verifyPasswordResetCode(this.verificationCode);
        this.isPasswordResetVerified = true;
      } catch (err) {
        this.error = 'Something went wrong';
        this.showLinkToLogin = true;
        logger.error({ err }, `Cannot verify password reset code: ${err.message}`);
      }
    },
    onInputChange() {
      this.error = '';
    },
  },
};
</script>

<style scoped>
.reset-password-form {
  display: flex;
  width: 400px;
  flex-direction: column;
}

.text-field {
  margin-bottom: 8px;
}

.reset-button {
  margin: 20px 0;
  padding: 9px 32px;
}

.email {
  font-weight: 800;
}

.error {
  margin: 16px 0;
  width: 400px;
}

.login-link {
  font-weight: 800;
  color: var(--text-color-link);
}

@media only screen and (max-width: 800px) {
  .error,
  .reset-password-form {
    width: 100%;
  }
}
</style>
