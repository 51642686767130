<script setup lang="ts">
import { computed } from 'vue';
import BaseBlock from '../../components/BaseBlock/BaseBlock.vue';

// TODO: Swmd V3 needs the BaseBlock otherwise it's the legacy editor that
// doesn't need a shadow/border style. props and computedWrapper can be deleted
// when Swmd V3 launches and the template can be just BaseBlock.
const props = defineProps<{
  legacy?: boolean;
}>();

const computedWrapper = computed(() => (props.legacy ? 'div' : BaseBlock));
</script>

<template>
  <component :is="computedWrapper" class="swm-selection-block" padding="none" shadow="medium">
    <slot />
  </component>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-selection-block {
  $self: &;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 400px;
  max-width: 560px;
  width: 100%;
}
</style>
