<script setup lang="ts">
import type { ComponentExposed } from 'vue-component-type-helpers';

import { BaseLayoutGap, BaseProse, SwmSelectionBlock, SwmSelectionContentMention, type User } from '@swimm/reefui';
import type { Editor } from '@tiptap/core';
import { computed, ref } from 'vue';

const props = defineProps<{
  editor: Editor;
  range: Range;
  query: string;
  text: string;
  items: User[];
  command: (props: User) => boolean;
  decorationNode: Element | null;
  clientRect?: (() => DOMRect | null) | null;
}>();

const mentions = ref<ComponentExposed<typeof SwmSelectionContentMention>>();
const onKeyDown = computed(() => mentions.value?.onKeyDown);

function onSelectUser(user: User) {
  props.command(user);

  return true;
}

defineExpose({ onKeyDown });
</script>

<template>
  <div>
    <SwmSelectionContentMention
      v-if="items.length"
      ref="mentions"
      :query="query"
      :users="items"
      :command="command"
      @select-user="onSelectUser"
    />
    <template v-else>
      <SwmSelectionBlock class="no-users-container">
        <BaseLayoutGap>
          <BaseProse class="no-users">
            <template v-if="!query">No users available, you might be offline.</template
            ><template v-else>No users match "{{ query }}"</template>
          </BaseProse>
        </BaseLayoutGap>
      </SwmSelectionBlock>
    </template>
  </div>
</template>

<style scoped lang="scss">
.no-users {
  background: var(--color-bg-default);
}

.no-users-container {
  padding: var(--space-base) !important;
}
</style>
