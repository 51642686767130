<script setup lang="ts">
import { Keyboard, keyboardShortcutUtils } from '@swimm/shared';
import { computed } from 'vue';

const props = defineProps({
  tooltipPosition: { type: String, default: 'right' },
  disabled: { type: Boolean, default: false },
  inline: { type: Boolean, default: true },
  repoScope: { type: Boolean, default: false },
});
const emit = defineEmits(['search']);

const tooltip = computed(() => {
  if (props.disabled) {
    return 'Search is only available on your default branch.';
  }
  if (!props.repoScope) {
    const keys = keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.CTRL, 'K');
    return `Search (${keys})`;
  } else {
    // the CMD-K is relevant only for global search (from sidebar) so we just put tooltip without it
    return 'Search repo content';
  }
});

function onClick() {
  if (props.disabled) {
    return;
  }
  emit('search');
}
</script>

<template>
  <div
    @click="onClick"
    class="global-search"
    :class="{ disabled, inline }"
    v-tooltip="{ content: tooltip, placement: tooltipPosition }"
  >
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.global-search {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  cursor: pointer;
  gap: var(--space-xs);

  &.inline {
    flex-direction: row;
  }

  &.disabled {
    cursor: default;
    color: var(--text-color-disable);
  }
}
</style>
