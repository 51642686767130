import { VueNodeViewRenderer } from '@tiptap/vue-3';
import TableCell from '@tiptap/extension-table-cell';
import TableDataNodeView from '../nodeViews/TableDataNodeView.vue';

export default TableCell.extend({
  content: '(inline | hardBreak)*',

  addAttributes() {
    return {
      ...this.parent?.(),
      align: {
        default: null,
        parseHTML: (element) => {
          return element.style.textAlign;
        },
        renderHTML: (attributes) => {
          return {
            style: `text-align: ${attributes.align}`,
          };
        },
      },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(TableDataNodeView);
  },
});
