import type { Editor } from '@tiptap/vue-3';
import { getPosByCoords } from '../components/common/tiptapUtils';
import { goToAnchor } from '../components/EditorComponents/composable/goToAnchor';

export type FocusCellByGivenParamsArgs = { editor: Editor; identifier: string; type: 'snippet' | 'heading' };

export function useGoToSection() {
  function getHashType(hash: string) {
    if (hash.includes('snippet')) {
      return 'snippet';
    }
    if (hash.includes('heading')) {
      return 'heading';
    }
    return null;
  }

  const sectionDictionary = {
    heading: (editor: Editor, identifier: string) => goToSelectedHeadline({ editor, identifier }),
    snippet: (editor: Editor, identifier: string) => goToSelectedSnippet({ editor, identifier }),
  };

  function linkIncludesHash(text: string) {
    return text.includes('#snippet') || text.includes('#heading');
  }
  function focusCellByGivenParams({ editor, identifier, type }: FocusCellByGivenParamsArgs) {
    const goToSection = sectionDictionary[type];
    goToSection(editor, identifier);
  }
  function extractIdFromSnippetHash(hash: string) {
    if (hash.includes('#snippet')) {
      return hash.replace('#snippet-', '');
    }

    return hash;
  }

  function goToSelectedHeadline({ editor, identifier }: { editor: Editor; identifier: string }) {
    // We want to remove the '#' from the string
    const hash = identifier.slice(1);
    if (!hash) {
      return;
    }
    const selectedHeading = document.getElementById(hash);
    if (!selectedHeading) {
      return;
    }
    let positionToPlaceCursorOn = null;
    // This places the cursor in the hash position.
    // Calling this while the editor is in view mode is redundant
    if (editor.isEditable) {
      const rect = selectedHeading.getBoundingClientRect();
      const pos = getPosByCoords(editor, { y: rect.y, x: rect.x });
      if (!pos) {
        return;
      }
      // because the Id is on the wrapper of the text the coords will give us the previous node pos
      // adding one will give us the actual node we are looking for
      const node = editor.state.doc.nodeAt(pos.pos + 1);
      if (!node) {
        return;
      }
      // we want to focus at the end of the node so we will add the position + the node size
      positionToPlaceCursorOn = pos.pos + node.nodeSize;
    }
    selectedHeading.scrollIntoView({ block: 'start', behavior: 'smooth' });
    selectedHeading.classList.add('focused-cell');
    selectedHeading.setAttribute('data-testid', 'scrolled-anchor');
    setTimeout(() => {
      selectedHeading.classList.remove('focused-cell');
    }, 1500);
    if (positionToPlaceCursorOn) {
      editor.commands.focus(positionToPlaceCursorOn);
    }
  }

  function goToSelectedSnippet({ editor, identifier }: { editor: Editor; identifier: string }) {
    goToAnchor(editor, extractIdFromSnippetHash(identifier));
  }
  return {
    goToSelectedHeadline,
    getHashType,
    goToSelectedSnippet,
    focusCellByGivenParams,
    linkIncludesHash,
    extractIdFromSnippetHash,
    sectionDictionary,
  };
}
