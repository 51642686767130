<script setup lang="ts">
import { computed, useSlots } from 'vue';

withDefaults(
  defineProps<{
    wrapper?: string;
    innerWrappers?: string;
  }>(),
  {
    wrapper: 'span',
    innerWrappers: 'span',
  }
);

const slots = useSlots();

const hasDefault = computed(() => !!slots.default);
const hasLeftIcon = computed(() => !!slots.leftIcon);
const hasRightIcon = computed(() => !!slots.rightIcon);
</script>

<template>
  <component :is="wrapper" class="layout-icons"
    ><component :is="innerWrappers" v-if="hasLeftIcon" class="layout-icons__icon layout-icons__icon--left"
      ><slot name="leftIcon" /></component
    ><component :is="innerWrappers" v-if="hasDefault" class="layout-icons__text"><slot /></component
    ><component :is="innerWrappers" v-if="hasRightIcon" class="layout-icons__icon layout-icons__icon--right"
      ><slot name="rightIcon"
    /></component>
  </component>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.layout-icons {
  $self: &;

  @include defaults;

  align-items: baseline;
  display: inline-flex;
  line-height: inherit;
  overflow: hidden;
  max-width: 100%;

  &__icon {
    display: inline-flex;
    align-self: flex-start;
    font-size: 1.143em; /* stylelint-disable-line scale-unlimited/declaration-strict-value -- Icons scale up to be around 15% larger than the text beside then */
    position: relative;

    &--left + #{$self}__text:not(:empty) {
      margin-left: var(--space-xxsmall);
    }

    &--right {
      margin-left: var(--space-xxsmall);
    }

    &:empty {
      display: none;
    }
  }

  &__text {
    max-width: 100%;
    overflow: hidden;

    &:empty {
      display: none;
    }
  }
}
</style>
