import { Timestamp } from 'firebase/firestore';
import type { SWIMM_FILE_TYPES } from '../../config';
import { RegexModifiers, RegexType } from '../../utils/regex-utils';
import type {
  ApplicabilityStatus,
  DocHookActionType,
  DocHookPathRuleStatus,
  DocHookRuleType,
  FileDiffType,
  HunkChangeLineType,
  PatternScope,
  SwmCellType,
  SwmSymbolLinkType,
  SwmSymbolType,
} from '../swimm-patch-common';
import { DocHookChangesScope, DocHookRuleTypeCode } from '../swimm-patch-common';

// Meta

export type FILE_VERSION = string;

// General

export type Text = string[];

// Swm File

export interface SwmMeta {
  app_version: string;
  cross_repo_file_blobs?: Record<string, Record<string, string>>;
  cross_repo_names?: Record<string, string>;
}

export interface SwmCellText {
  type: SwmCellType.Text;
  text: string;
}
export interface SwmCellImage {
  type: SwmCellType.Image;
  src: string;
  width: number;
  originalSrc?: string; // To keep track of DAC-images - not part of SWM file
}
export interface SwmCellVideo {
  type: SwmCellType.Video;
  src: string;
}

export interface SwmCellMermaid {
  type: SwmCellType.Mermaid;
  src: string;
  width: number;
  content: string;
}

export interface SwmCellSnippet {
  type: SwmCellType.Snippet;
  patchType?: FileDiffType;
  lines: string[];
  firstLineNumber: number;
  path: string;
  repoId: string;
  comments?: string[];
  collapsed?: boolean;
  pos?: number;
  id: string;
  applicability?: ApplicabilityStatus; // Only added during runtime, shouldn't be saved
  originalInfo?: Omit<SwmCellSnippet, 'originalInfo'>;
  tempId?: string;
}

export interface SwmCellSnippetPlaceholder {
  type: SwmCellType.SnippetPlaceholder;
  tempId?: string;
  id?: string;
  comment?: string;
}

type SwmTableRow = string[];

export interface SwmCellTable {
  type: SwmCellType.Table;
  headers?: SwmTableRow;
  table: SwmTableRow[];
}

export interface DocHookPathRule {
  type: DocHookRuleType.Path;
  code: DocHookRuleTypeCode;
  status: DocHookPathRuleStatus;
  eventSymbol: string;
}

export interface DocHookRegexRule {
  type: DocHookRuleType.Regex;
  applicableLanguages?: string[];
  pattern: string;
  regexType: RegexType;
  modifiers?: RegexModifiers[];
  scope?: PatternScope;
  applyOnChanges?: DocHookChangesScope;
  globPatterns?: string[];
}

export interface DocHookFileSystemRule {
  type: DocHookRuleType.FileSystem;
  globPattern: string;
  createdFiles?: boolean;
  modifiedFiles?: boolean;
  deletedFiles?: boolean;
}

export interface DocHookDocModifiedRule {
  type: DocHookRuleType.DocModified;
  eventSymbol: string;
}

export type DocHookRule = DocHookPathRule | DocHookDocModifiedRule | DocHookRegexRule | DocHookFileSystemRule;

export interface DocHookCommentAction {
  type: DocHookActionType.GithubComment;
  url: string;
  text: string;
}

export interface DocHookReviewCommentAction {
  type: DocHookActionType.GithubReview;
  url: string;
  text: string;
}

export interface DocHookSlackAction {
  type: DocHookActionType.SlackNotification;
  url: string;
  text: string;
  conversation_id: string;
  conversation_name: string;
  team_id: string;
}

export interface DocHookRequireNewDocCheckAction {
  type: DocHookActionType.RequireNewDocCheck;
}

export interface DocHookRequireNewDocCommentAction {
  type: DocHookActionType.RequireNewDocComment;
}

export interface DocHookIdeCodelensAction {
  type: DocHookActionType.IdeCodeLens;
  docId?: string;
  description?: string;
  creator?: string;
}

export interface DocHookCopyToPathAction {
  type: DocHookActionType.CopyToPath;
  path?: string;
}

export type DocHookAction =
  | DocHookCommentAction
  | DocHookReviewCommentAction
  | DocHookSlackAction
  | DocHookRequireNewDocCheckAction
  | DocHookRequireNewDocCommentAction
  | DocHookIdeCodelensAction
  | DocHookCopyToPathAction;

export interface SwmCellRule {
  type: SwmCellType.Rule;
  name?: string;
  rule: DocHookRule;
  actions: DocHookAction[];
  isPublic?: boolean;
}

export type SwmCell =
  | SwmCellText
  | SwmCellSnippet
  | SwmCellSnippetPlaceholder
  | SwmCellImage
  | SwmCellTable
  | SwmCellRule
  | SwmCellVideo
  | SwmCellMermaid;

export interface SwmCR {
  action: string;
  value: string;
  message: string;
}

export interface SwmTask {
  dod: string;
  hints?: string[];
  tests?: string[];
  crActions?: SwmCR[];
}

export type SwmSymbols = Record<string, SwmSymbol>;

export type SwmSymbol =
  | SwmSymbolPath
  | SwmSymbolGenericText
  | SwmSymbolLink
  | SwmSymbolTextPlaceholder
  | SwmSymbolMention;

export interface SwmSymbolBase {
  type: SwmSymbolType;
  text: string;
  applicability?: ApplicabilityStatus;
}

export interface SwmSymbolPath extends SwmSymbolBase {
  type: SwmSymbolType.PATH;
  path: string;
  // Runtime only - used in the FE.
  isDirectory?: boolean;
  repoId: string;
  originalInfo?: Omit<SwmSymbolPath, 'originalInfo'>;
}

export interface SwmSymbolGenericText extends SwmSymbolBase {
  type: SwmSymbolType.GENERIC_TEXT;
  lineNumber: number;
  wordIndex: {
    start: number;
    end: number;
  };
  path: string;
  fileBlob?: string;
  lineData: string;
  repoId: string;
  originalInfo?: Omit<SwmSymbolGenericText, 'originalInfo'>;
}

export interface SwmSymbolLink extends SwmSymbolBase {
  type: SwmSymbolType.LINK;
  swimmId: string;
  repoId: string;
  branch?: string;
  isDraftLink?: boolean;
  swimmType: SwmSymbolLinkType;
}

export interface SwmSymbolLinkDependency {
  symbolId: string;
  swimmId: string;
}

export interface SwmSymbolMention extends SwmSymbolBase {
  type: SwmSymbolType.MENTION;
  userId: string;
}

export interface SwmSymbolTextPlaceholder extends SwmSymbolBase {
  type: SwmSymbolType.TEXT_PLACEHOLDER;
  id: string;
  value: string;
}

export interface SwmFile {
  name: string;
  file_version: FILE_VERSION;
  id: string;
  content: SwmCell[];
  task?: SwmTask;
  meta: SwmMeta;
  applicability?: ApplicabilityStatus; // Only added during runtime, shouldn't be saved
  path: string; // Only added during runtime, shouldn't be saved
  symbols?: SwmSymbols;
  isNew?: boolean; // Only added during runtime, shouldn't be saved
  isDeleted?: boolean; // Only added during runtime, shouldn't be saved
  type?: string; // Only added during runtime, shouldn't be saved
  exported_cloud_doc_id?: string;
}

export interface PlaylistStep {
  id: string;
  name: string;
  repoId: string;
  type: string;
  isDraftLink?: boolean;
}

export interface ContentSuggestionsState {
  topicId?: string;
  subject?: string;
  documentSuggestionId?: string;
}

export interface DraftSwmFile extends SwmFile {
  draftId: string;
  draftBranch: string;
  type: (typeof SWIMM_FILE_TYPES)[keyof typeof SWIMM_FILE_TYPES];
  generatedDocumentId?: string;
  templateName?: string;
  createdFromPr?: boolean;
  futureSwmId?: string;
  runningAutosync?: boolean;
  docRequestId?: string;
  tags?: string[];
  sequence?: PlaylistStep[];
  dependencies?: SwmSymbolLinkDependency[];
  twoWayDependencies?: string[];
  contentSuggestionsState?: ContentSuggestionsState;
  rulesSwm?: SwmFile;
  rulesChanged?: boolean;
  isOnboardingSgdDoc?: boolean;
  folderId?: string;
  created: number;
  modified?: Timestamp;
  folderIndex?: number;
  summary?: string;
  description?: string;
}

// Dynamic Patch

export interface DynamicSwimmHunkMetadata {
  applicability?: ApplicabilityStatus;
}

export interface DynamicGitHunkMetadata {
  lineNumbers: {
    fileA: {
      linesCount: number;
      startLine: number;
    };
    fileB: {
      linesCount: number;
      startLine: number;
    };
  };
}

export interface DynamicChangeLineFile {
  actualLineNumber: number;
  data: string;
  isEmpty?: boolean; // ignore actualLineNumber
}

export interface DynamicChangeLine {
  changeType: HunkChangeLineType;
  fileA?: DynamicChangeLineFile;
  fileB?: DynamicChangeLineFile;
}

export interface DynamicHunkContainer {
  swimmHunkMetadata?: DynamicSwimmHunkMetadata;
  gitHunkMetadata: DynamicGitHunkMetadata;
  changes: DynamicChangeLine[];
  repoId: string;
  id?: string; // NOTE: only exists when needing to keep track between the location of a SwmCellSnippet and a DynamicHunkContainer. Never committed to disk.
}
export interface DynamicGitFileMetadata {
  blob_sha: string;
  originalFileDiffHeader?: string;
  indexLines?: string[];
  extendedHeaders?: string[];
  diffType?: FileDiffType;
  markers?: string[];
  comparedFiles?: string;
  fileNameA?: string;
  fileNameB?: string;
  numLineDeletions?: number;
  numLineAdditions?: number;
}

export interface DynamicPatchFile {
  hunkContainers: DynamicHunkContainer[];
  fileNameA?: string;
  fileNameB?: string;
  diffType?: FileDiffType;
}

// Path to DynamicPatchFile
export type DynamicPatch = Record<string, DynamicPatchFile>;

// RepoId -> Path -> DynamicPatchFile
export type MultiDynamicPatch = Record<string, DynamicPatch>;

export const TITLE_MAX_LENGTH = 100;
