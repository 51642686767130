import snakeCase from 'lodash-es/snakeCase';

import {
  type FolderTree,
  type PathSuggestion,
  type SwmSymbolPath,
  type TokenSuggestion,
  dfsTraverse,
} from '@swimm/shared';

import { PathSymbolSuggestionEngine } from '@/services/path-suggestions';

import type { SwimmEditorServices } from '@/tiptap/editorServices/index';
import type { TextSuggestions } from './docTraverse';

// TODO: This still needs a further cleanup (especially parameters-wise).
export function useTextSuggestions(
  swimmEditorServices: SwimmEditorServices,
  triggerSuggestionsRerun: () => void,
  awaitPathSuggestions = false // if true: Make sure you get path suggestions before getting token suggestions
) {
  let dismissedTexts: string[] = [];
  swimmEditorServices.external.getDismissedSwimmportSuggestions().then((value) => {
    dismissedTexts = dismissedTexts.concat(value);
    triggerSuggestionsRerun(); // TODO: This is very costly for just removing some suggestions. Will improve greatly after the refactor.
  });

  const dismissText = (text: string): void => {
    dismissedTexts.push(text);
    triggerSuggestionsRerun(); // TODO: This is very costly for just removing some suggestions. Will improve greatly after the refactor.
    swimmEditorServices.external.dismissSwimmportSuggestion(text);
  };

  let pathSuggestionEngine: PathSymbolSuggestionEngine | undefined = undefined;
  const initPathEnginePromise: Promise<void> = (async () => {
    const folderTree: FolderTree | undefined = await swimmEditorServices.external.getRepoFolderTree(
      swimmEditorServices.repoId.value,
      swimmEditorServices.branch.value,
      false
    );
    if (folderTree == null) {
      return;
    }
    pathSuggestionEngine = initializePathSuggestionEngine(folderTree, swimmEditorServices.repoId.value);
    triggerSuggestionsRerun();
  })();

  const getSuggestionsForText = async (
    text: string,
    { isCode, isLink }: { isCode: boolean; isLink: boolean }
  ): Promise<TextSuggestions> => {
    if (dismissedTexts.includes(text)) {
      return undefined;
    }

    if (awaitPathSuggestions) {
      await initPathEnginePromise;
    }

    const pathSuggestions = await getPathSuggestionsForText(text);
    if (pathSuggestions.length > 0) {
      return { type: 'path', suggestions: pathSuggestions };
    }

    // We only suggest paths in links, since it's a common way to represent paths to other files as Markdown links.
    // Representing tokens this way is less common and we'd want to avoid FPs that may be caused by tokens being
    // suggested in the middle of URLs.
    if (!isLink) {
      const tokenSuggestions = await getTokenSuggestionsForText(text, { isCode });
      if (tokenSuggestions.length > 0) {
        return { type: 'token', suggestions: tokenSuggestions };
      }
    }

    return undefined;
  };

  const cleanSurroundingQuotes = (text: string): string => {
    if ((text.startsWith("'") && text.endsWith("'")) || (text.startsWith('"') && text.endsWith('"'))) {
      return text.slice(1, -1);
    }
    return text;
  };

  const getPathSuggestionsForText = async (text: string): Promise<PathSuggestion[]> => {
    // If user surrounded a path in quotes, support that.
    text = cleanSurroundingQuotes(text);

    // TODO: Clean this after deleting Swimmport's code for the old editor.
    const symbols = pathSuggestionEngine?.suggest(text);
    if (symbols == null) {
      return [];
    }

    return symbols.map((symbol: SwmSymbolPath) => ({
      name: symbol.text,
      path: symbol.path,
      type: symbol.isDirectory ? 'directory' : 'file',
      repoId: symbol.repoId,
    }));
  };

  const getTokenSuggestionsForText = async (
    text: string,
    { isCode }: { isCode: boolean }
  ): Promise<TokenSuggestion[]> => {
    if ((!isCode && isSingleWord(text)) || (text.length === 1 && text.match(/[^a-zA-Z0-9]/))) {
      return [];
    }

    if (SKIP_SUGGESTION_WORDS.includes(text.toLowerCase())) {
      return [];
    }

    // If user surrounded a name in quotes, support that. Note: single words in quotes are not supported here (will be
    // eliminated in the above isSingleWord check, this is by design to avoid FPs that could arise from simple words
    // being quoted).
    text = cleanSurroundingQuotes(text);

    return swimmEditorServices.external.tokenSuggestionsService.queryAsync(
      text,
      [...Object.entries(swimmEditorServices.sourceFiles.sourceFiles.value)]
        .map(([repoId, paths]) => paths.map((path) => ({ repoId, path })))
        .flat(),
      [swimmEditorServices.repoId.value],
      { exactMatch: true }
    );
  };

  return { getSuggestionsForText, dismissText };
}

function initializePathSuggestionEngine(folderTree: FolderTree, repoId: string): PathSymbolSuggestionEngine {
  const nodes = [...dfsTraverse(folderTree, (node) => (node.type === 'file' ? [] : node.children))]
    .map((result) => result.node)
    .filter((node) => node.path !== '');
  const files = nodes.filter((node) => node.type === 'file').map((file) => file.path);
  const directories = nodes.filter((node) => node.type === 'directory').map((directory) => directory.path);
  return new PathSymbolSuggestionEngine(files, directories, repoId);
}

function isSingleWord(text: string): boolean {
  return !snakeCase(text).includes('_');
}

// Generated by running on Python:
// >>> import keyword
// >>> keyword.kwlist
// And then adding a few other words we stumbled upon and found to be annoying (e.g., `list`)
// It is not a complete list and can change over time
const SKIP_SUGGESTION_WORDS = [
  'false',
  'none',
  'true',
  'and',
  'as',
  'assert',
  'async',
  'await',
  'break',
  'class',
  'continue',
  'def',
  'del',
  'elif',
  'else',
  'except',
  'finally',
  'for',
  'from',
  'global',
  'if',
  'import',
  'in',
  'int',
  'is',
  'lambda',
  'list',
  'nonlocal',
  'node',
  'not',
  'or',
  'pass',
  'raise',
  'return',
  'try',
  'while',
  'with',
  'yarn',
  'yield',
  'let',
  'const',
];
