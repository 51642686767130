<template>
  <SwModal :heading="`${isNew ? 'Add' : 'Edit'} Link`" :show-modal="showModal" @close="cancel">
    <div class="modal-content">
      <TextField
        v-model="linkToEdit.url"
        label="External URL / YouTube link / Internal *.md (relative to repo root) / Wistia link"
        placeholder="Enter link URL"
        :on-enter="done"
      />
      <TextField v-model="linkToEdit.name" label="Link title (optional)" placeholder="Link title" :on-enter="done" />
      <div class="actions">
        <Action secondary @click="cancel">Cancel</Action>
        <Action :disabled="!link.url" @click="done">Done</Action>
      </div>
    </div>
  </SwModal>
</template>

<script lang="ts">
import type { PlaylistExternalLink } from '@/modules/playlists/types';
import { SwModal, TextField } from '@swimm/ui';
import { type PropType, defineComponent } from 'vue';

export default defineComponent({
  components: { TextField, SwModal },
  props: {
    link: { type: Object as PropType<PlaylistExternalLink>, required: true },
    showModal: { type: Boolean, required: true },
    isNew: { type: Boolean, default: true },
  },
  data() {
    return {
      linkToEdit: this.link as PlaylistExternalLink,
    };
  },
  watch: {
    link(newLink, _oldLink) {
      this.linkToEdit = newLink;
    },
  },
  emits: ['done-edit-link', 'close'],
  methods: {
    done() {
      const link: PlaylistExternalLink = { url: this.linkToEdit.url, name: this.linkToEdit.name };
      this.$emit('done-edit-link', link);
    },
    cancel() {
      this.$emit('close');
    },
  },
});
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: flex-end;
}

.actions > * {
  margin-left: 10px;
}

.modal-content > * {
  margin-bottom: 20px;
}
</style>
