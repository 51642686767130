<template>
  <div class="custom-logo" :class="`group${colorGroup}`">
    <span>{{ logoText }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  text: { type: String, required: true },
  id: { type: String, required: true },
});
const logoText = computed(() => props.text[0].toUpperCase());
const colorGroup = computed(() => (props.id.charCodeAt(0) % 5) + 1);
</script>

<style scoped>
.custom-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 2px transparent solid;
  flex-direction: column;
}

.group1 {
  color: var(--text-color-on-light);
  background-color: var(--feet-in-the-water);
}

.group2 {
  color: var(--text-color-on-dark);
  background-color: var(--color-brand);
}

.group3 {
  color: var(--text-color-on-light);
  background-color: var(--duck-mode);
}

.group4 {
  color: var(--text-color-on-dark);
  background-color: var(--the-trenches);
}

.group5 {
  color: var(--text-color-on-dark);
  background-color: var(--high-violet);
}
</style>
