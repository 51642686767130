import type { SwmResourceType } from '@swimm/shared';
import Fuse from 'fuse.js';
import Cache from 'lru-cache';

export type FuseSearchDoc = {
  id: string;
  name: string;
  repoName: string;
  type: SwmResourceType;
  link: string;
  content: string[];
};

/**
 * The cache key is made of two elements:
 * 1. A {string} of the repoId.
 * 2. A {string} of the revision - this is the unique key we have for the current state of all the docs.
 */
export type SearchCacheKey = `R${string}-H${string}`;

export const SEARCH_INDEX_CACHE: Cache<SearchCacheKey, Fuse.FuseIndex<FuseSearchDoc>> = new Cache({ max: 50 });

export function invalidateSearchCacheOnRepoChange({ repoId, branch }: { repoId: string; branch: string }) {
  const cacheKey: SearchCacheKey = `R${repoId}-H${branch}`;
  SEARCH_INDEX_CACHE.delete(cacheKey);
}
