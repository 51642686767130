<template>
  <teleport to="body">
    <transition name="fade">
      <div
        v-if="show"
        :class="['overlay', { 'backdrop-blur': blurBackground, 'align-to-top': alignToTop }]"
        data-testid="modal"
        @click.self.stop="onBackdropClick"
        @keydown.esc="onCloseModal"
        v-bind="$attrs"
      >
        <slot />
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    show: { type: Boolean, required: true },
    blurBackground: { type: Boolean, default: false },
    closeOnBackdropClick: { type: Boolean, default: true },
    alignToTop: { type: Boolean, default: false },
  },
  emits: ['close'],
  methods: {
    onBackdropClick() {
      if (this.closeOnBackdropClick) {
        this.onCloseModal();
      }
    },
    onCloseModal() {
      this.$emit('close', false);
    },
  },
};
</script>

<style scoped lang="postcss">
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);

  &.backdrop-blur {
    backdrop-filter: blur(1px);
  }

  &.align-to-top {
    align-items: flex-start;
    padding-top: 80px;
  }
}
</style>
