<script setup lang="ts">
import { ref } from 'vue';

defineProps<{
  src: string;
  muted?: boolean;
  loop?: boolean;
  showControls?: boolean;
}>();

defineEmits(['play']);

const video = ref<HTMLVideoElement>(null);

function resetTime() {
  video.value.currentTime = 0;
}

defineExpose({
  load() {
    resetTime();
    video.value.load();
  },
  play() {
    resetTime();
    video.value.play();
  },
  pause() {
    resetTime();
    video.value.pause();
  },
});
</script>

<template>
  <video
    ref="video"
    class="video"
    :controls="showControls"
    :muted="muted"
    :loop="loop"
    preload="auto"
    @play="$emit('play')"
  >
    <source :src="src" type="video/mp4" />
  </video>
</template>
