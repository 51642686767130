<template>
  <div>
    <PlaylistItem
      v-if="documentation.documentationType === DocumentationTypes.PLAYLIST"
      :playlist="documentation"
      :branch="getBranch(documentation)"
      :repo-id="repoId"
      :has-variations="documentation?.variations?.length > 1"
      :path="documentation.path"
      :show-folder-path="showFolderPath"
      :selectable="selectable"
      @discard="handleSwmDiscard"
      @delete="onDelete"
      @open-commit="$emit('open-commit')"
      @open-share-modal="$emit('open-share-modal', $event)"
      @move="$emit('move')"
      @select="(ev) => $emit('select', ev)"
      @report-click="reportActionClicked"
      @click="$emit('click')"
    />
    <DocItem
      v-else-if="documentation.documentationType === DocumentationTypes.DOC"
      unit-type="doc"
      :data-testid="getDocTestId(documentation)"
      :unit="documentation"
      :repo-id="repoId"
      :available="isDocAvailable(documentation)"
      :branch="getBranch(documentation)"
      :applicable-status="getApplicabilityStatus(documentation)"
      :has-variations="documentation?.variations?.length > 1"
      :path="documentation.path"
      :show-folder-path="showFolderPath"
      :selectable="selectable"
      @discard="handleSwmDiscard"
      @delete="onDelete"
      @open-commit="$emit('open-commit')"
      @open-share-modal="$emit('open-share-modal', $event)"
      @move="$emit('move')"
      @select="(ev) => $emit('select', ev)"
      @report-click="reportActionClicked"
      @click="$emit('click')"
    />
    <DocRequestItem
      v-else-if="documentation.documentationType === DocumentationTypes.DOC_REQUEST"
      :doc-request="documentation"
      :repo-id="repoId"
      @doc-request-action="handleDocRequestAction"
    />
    <FolderItem
      v-if="documentation.documentationType === DocumentationTypes.FOLDER"
      :folder="documentation"
      :repo-id="repoId"
      :selectable="selectable"
      @folder-clicked="$emit('folder-clicked')"
      @add-folder="$emit('add-folder', $event)"
      @navigate-to-item="$emit('navigate-to-item', $event)"
      @toggle-creation-hub="$emit('toggle-creation-hub', $event)"
      @move="$emit('move')"
      @delete="onDelete"
      @select="(ev) => $emit('select', ev)"
      @report-click="reportActionClicked"
      @click="$emit('click')"
    />
    <slot />
  </div>
</template>

<script>
import { RepoPageDocumentationSectionTypes } from '@/common/consts';
import { mapGetters } from 'vuex';
import PlaylistItem from '@/modules/core/components/PlaylistItem.vue';
import FolderItem from '@/modules/core/components/FolderItem.vue';
import DocItem from '@/modules/core/components/DocItem.vue';
import { productEvents, swmdFileName } from '@swimm/shared';
import DocRequestItem from '@/modules/doc-requests/components/DocRequestItem.vue';
import { DocumentationTypes } from '@/common/consts';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useRepoDocsStore } from '@/modules/core/stores/repo-docs';
import { useDrafts3ValidationStore } from '@/modules/drafts3/stores/drafts3Validation';
import { storeToRefs } from 'pinia';

export default {
  components: {
    DocRequestItem,
    DocItem,
    FolderItem,
    PlaylistItem,
  },
  props: {
    repoId: { type: String, required: true },
    documentation: { type: Object, required: true },
    showFolderPath: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
  },
  emits: [
    'discard',
    'doc-request-action',
    'delete',
    'move',
    'select',
    'open-commit',
    'open-share-modal',
    'folder-clicked',
    'add-folder',
    'navigate-to-item',
    'toggle-creation-hub',
    'click',
  ],
  setup() {
    const analytics = useAnalytics();
    const { getDocApplicability } = useRepoDocsStore();
    const { draftsVerification } = storeToRefs(useDrafts3ValidationStore());

    return {
      RepoPageDocumentationSectionTypes,
      DocumentationTypes,
      analytics,
      getDocApplicability,
      draftsVerification,
    };
  },
  computed: {
    ...mapGetters('filesystem', [
      'fs_getUnitApplicabilityState',
      'fs_isUnitFileInRepoPath',
      'fs_getRepoPendingDocumentations',
    ]),
    pendingPRs() {
      return this.fs_getRepoPendingDocumentations(this.repoId) || {};
    },
  },
  methods: {
    getApplicabilityStatus(resource) {
      if (this.draftsVerification.has(resource.id)) {
        return this.draftsVerification.get(resource.id).applicabilityStatus;
      }
      return this.getDocApplicability(resource.id);
    },
    isDocAvailable(doc) {
      if (doc.pending) {
        return !doc.isPendingDeletion;
      }
      return !!doc.draftId || doc.is_example || this.fs_isUnitFileInRepoPath(doc.id, this.repoId);
    },
    getDocTestId(doc) {
      if (doc.pending) {
        return 'pending-doc-card';
      }
      return `doc-card${doc.draftId ? '-draft' : ''}`;
    },
    getBranch(doc) {
      if (!doc.pending) {
        return this.$route.params.branch;
      }
      if (doc.branch) {
        return doc.branch;
      }
      if (doc.id in this.pendingPRs || swmdFileName(doc) in this.pendingPRs) {
        let pendingDoc = this.pendingPRs[doc.id];
        if (!pendingDoc) {
          pendingDoc = this.pendingPRs[swmdFileName(doc)];
        }
        return pendingDoc[0].branch;
      }
      return '';
    },
    handleSwmDiscard(swm, type) {
      this.$emit('discard', swm, type);
    },
    handleDocRequestAction(docRequest, action) {
      this.$emit('doc-request-action', docRequest, action);
    },
    onDelete() {
      this.$emit('delete');
    },
    reportActionClicked({ type, id, action }) {
      this.analytics.track(productEvents.SELECTED_REPO_PAGE_ITEM_ELLIPSIS, {
        Type: type,
        'Entity ID': id,
        'Selected Action': action,
      });
    },
  },
};
</script>
