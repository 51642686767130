<script lang="ts">
export const ResourceHeaderMetaItemPadding = ['none'] as const;
export type ResourceHeaderMetaItemPaddingType = (typeof ResourceHeaderMetaItemPadding)[number];
</script>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  padding?: ResourceHeaderMetaItemPaddingType;
}>();

const computedClasses = computed(() => ({
  [`doc-header-meta-item--${props.padding}`]: !!props.padding,
}));
</script>

<template>
  <li class="doc-header-meta-item" :class="computedClasses">
    <slot />
  </li>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.doc-header-meta-item {
  $self: &;

  @include basic-resets;

  color: var(--color-text-secondary);
  font-family: var(--font-family-default);
  font-size: var(--font-size-small);
  padding: 0 var(--space-xsmall);

  &:first-of-type {
    padding-left: 0;
  }

  &--none {
    padding: 0;
  }
}
</style>
