<script setup>
import { computed, ref } from 'vue';
import FilterButton from '@/modules/core/filters-row/FilterButton.vue';

const props = defineProps({
  name: { type: String, required: true },
  testIdPrefix: { type: String, default: null },
  isSelected: { type: Boolean, required: true },
});

const isOpen = ref(false);

const testId = computed(() => {
  const suffix = isOpen.value ? 'open' : 'close';
  return `${props.testIdPrefix}-${suffix}`;
});
</script>

<template>
  <div class="dropdown-filter">
    <VDropdown
      placement="bottom"
      theme="dropdown-no-arrow"
      :triggers="['click']"
      v-model:shown="isOpen"
      :auto-hide="true"
    >
      <FilterButton :is-selected="isSelected" :is-open="isOpen" :name="name" :data-testid="testId" />
      <template #popper>
        <div class="dropdown-content">
          <slot />
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<style scoped>
.dropdown-content {
  overflow: auto;
  max-height: 400px;
}
</style>
