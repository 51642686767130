import { getLogger } from '../logger/legacy-shim';

const logger = getLogger("packages/shared/src/utils/datetime-utils.ts");

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MINUTE_IN_MILLISECONDS = 60 * 1000;

interface dateable {
  toDate: () => Date;
}

export function formatDate(date: string | Date, format: 'short' | 'long' = 'long') {
  const jsDate = new Date(date);
  let month = monthNames[jsDate.getMonth()];
  if (format === 'short') {
    month = month.substring(0, 3);
  }
  return `${month} ${jsDate.getDate()}, ${jsDate.getFullYear()}`;
}

export function isSameDate(date1: Date, date2: Date) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export function jsDate(date: string | Date | dateable) {
  try {
    if (typeof date === 'string') {
      return new Date(date);
    }
    // Firestore timestamp
    return date instanceof Date ? date : date.toDate();
  } catch (error) {
    logger.error(`Failed casting the date. ${error.toString()}`);
  }
  return null;
}

export function humanizedDate(date, format: 'short' | 'long' = 'long') {
  if (isSameDate(new Date(), jsDate(date))) {
    return 'Today';
  }
  return formatDate(jsDate(date), format);
}

export function extendDateTime(date, format: 'short' | 'long' = 'long') {
  const formattedDate = formatDate(jsDate(date), format);
  return `${formattedDate} ${jsDate(date).toLocaleTimeString()}`;
}

export function toRelativeTimeInMinutes(currentTime: number, baseTime: number, stopAt?: number) {
  const elapsedTime = currentTime - baseTime;
  const elapsedRounded = Math.round(elapsedTime / MINUTE_IN_MILLISECONDS);

  if (elapsedRounded === 0) {
    return 'now';
  }

  if (stopAt && elapsedRounded > stopAt) {
    return null;
  }

  return `${elapsedRounded}m ago`;
}

export function getDateFromSeconds(seconds: number) {
  return new Date(seconds * 1000);
}

export function secondsToRelativeTimeDetailed(secondsAgo: number): string {
  // speced by @nadav
  const minutesAgo = Math.round(secondsAgo / 60);
  const hoursAgo = Math.round(secondsAgo / 3600);
  const daysAgo = Math.round(secondsAgo / (24 * 3600));

  if (secondsAgo < 45) {
    return 'just now';
  }

  if (secondsAgo < 90) {
    return 'a minute ago';
  }

  if (minutesAgo <= 44) {
    return `${minutesAgo} minutes ago`;
  }
  if (hoursAgo <= 1) {
    return 'an hour ago';
  }
  if (hoursAgo <= 21) {
    return `${hoursAgo} hours ago`;
  }

  if (daysAgo <= 1) {
    return 'a day ago';
  }
  return `${daysAgo} days ago`;
}
