<script setup lang="ts">
import type { TokenSuggestion } from '@swimm/shared';
import type { OverlayToken } from '../GenericText/tokenizer';
import CodeSnippetContentLineOverlayToken from './CodeSnippetContentLineOverlayToken.vue';

defineProps<{
  overlayTokens: OverlayToken[];
}>();

const emit = defineEmits<{
  (event: 'add-def-snippet', suggestion: TokenSuggestion): void;
  (event: 'token-interacted', op: 'hover' | 'click', tok: OverlayToken): void;
}>();

function addDefSnippet(suggestion: TokenSuggestion) {
  emit('add-def-snippet', suggestion);
}

function tokenInteracted(op: 'hover' | 'click', tok: OverlayToken) {
  emit('token-interacted', op, tok);
}
</script>
<template>
  <code class="overlay"
    ><template v-for="tok in overlayTokens" :key="tok.key"
      ><span v-if="!tok.isDefinition" class="overlay-placeholder" :data-overlay-length="tok.displayText.length">{{
        tok.displayText
      }}</span
      ><CodeSnippetContentLineOverlayToken
        v-else
        :tok="tok"
        @token-interacted="(op: 'hover' | 'click') => tokenInteracted(op, tok)"
        @add-def-snippet="() => addDefSnippet(tok.definition)" /></template
  ></code>
</template>

<style scoped lang="postcss">
code.overlay {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  background: transparent;
  pointer-events: none;

  .overlay-placeholder {
    pointer-events: none;
  }
}
</style>
