<script lang="ts">
import { computed } from 'vue';
import { type FontSizes, type FontWeights } from '../../types';
export const ProseVariants = [
  'primary',
  'secondary',
  'success',
  'warning',
  'info',
  'danger',
  'magic',
  'placeholder',
] as const;
export type ProseVariantsType = (typeof ProseVariants)[number];

export const ProseSizes = ['xsmall', 'small', 'default'] as const;
export type ProseSizesType = Extract<FontSizes, (typeof ProseSizes)[number]>;
</script>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    variant?: ProseVariantsType;
    size?: ProseSizesType;
    weight?: FontWeights;
    wrapper?: string;
    ellipsis?: boolean;
  }>(),
  {
    variant: undefined,
    size: 'default',
    weight: 'regular',
    wrapper: 'div',
    ellipsis: false,
  }
);

const computedClasses = computed(() => ({
  [`prose--${props.variant}`]: !!props.variant,
  [`prose--${props.size}`]: !!props.size,
  [`prose--${props.weight}`]: !!props.weight,
  [`prose--ellipsis`]: props.ellipsis,
}));
</script>

<template>
  <component :is="wrapper" class="prose" :class="computedClasses">
    <slot />
  </component>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.prose {
  @include defaults;

  > :deep(*:first-child) {
    margin-top: 0;
  }

  > :deep(*:last-child) {
    margin-bottom: 0;
  }

  :deep(p) {
    margin: 0.75rem 0;
  }

  :deep(strong) {
    font-weight: 700;
  }

  :deep(em) {
    font-style: italic;
  }

  :deep(ul),
  :deep(ol) {
    margin: 0.75rem 0;
    padding: 0 0 0 2rem;
  }

  /**
   * Weight modifiers
   */
  &--regular {
    font-weight: var(--font-weight-regular);
  }

  &--bold {
    font-weight: var(--font-weight-bold);
  }

  &--bolder {
    font-weight: var(--font-weight-bolder);
  }

  /**
   * Variant modifiers
   */
  &--secondary {
    color: var(--color-text-secondary);
  }

  &--success {
    color: var(--color-text-success);
  }

  &--warning {
    color: var(--color-text-warning);
  }

  &--info {
    color: var(--color-text-info);
  }

  &--danger {
    color: var(--color-text-danger);
  }

  &--magic {
    color: var(--color-text-magic);
  }

  &--placeholder {
    color: var(--text-color-disable);
  }

  /**
   * Size modifiers
   */
  &--xsmall {
    font-size: var(--font-size-xsmall);
  }

  &--small {
    font-size: var(--font-size-small);
  }

  &--default {
    font-size: var(--font-size-default);
  }

  /**
   * Wrap modifiers
   */
  &--ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
