<script setup lang="ts">
import { computed } from 'vue';
import type { ContributorDbItemInterface } from '@swimm/shared';

import BaseAvatar from '../../components/BaseAvatar/BaseAvatar.vue';
import BaseProse from '../../components/BaseProse/BaseProse.vue';
import BaseLayoutIcons from '../../components/BaseLayoutIcons/BaseLayoutIcons.vue';
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';
import BasePopover from '../../components/BasePopover/BasePopover.vue';

import List from '../List/List.vue';
import ListItem from '../ListItem/ListItem.vue';

const props = withDefaults(
  defineProps<{
    contributors?: ContributorDbItemInterface[];
  }>(),
  {
    contributors: undefined,
  }
);

const creator = computed(() => {
  return props.contributors ? props.contributors.find((contributor) => contributor.is_creator) : undefined;
});

const others = computed(() => {
  return props.contributors
    ? props.contributors
        .filter((contributor) => !contributor.is_creator)
        .sort((a, b) => b.modified.toMillis() - a.modified.toMillis())
    : undefined;
});

const title = computed(() => {
  let title = creator.value ? creator.value.name : 'Unknown creator';

  if (others.value && others.value.length) {
    title += ` & ${others.value.length} ${others.value.length === 1 ? 'other' : 'others'}`;
  }

  return title;
});

const contributorList = computed(() => {
  return creator.value ? [creator.value, ...(others.value || [])] : [...(others.value || [])];
});

const computedWrapper = computed(() => {
  return contributorList.value && contributorList.value.length ? BasePopover : 'span';
});
</script>

<template>
  <component :is="computedWrapper" hover :arrow="false">
    <BaseProse variant="secondary" size="small">
      <BaseLayoutIcons
        ><template #leftIcon><BaseIcon name="community" /></template>{{ title }}</BaseLayoutIcons
      >
    </BaseProse>
    <template v-if="contributorList" #content>
      <List>
        <ListItem v-for="contributor in contributorList" :key="contributor.id"
          ><template #leftIcon><BaseAvatar :name="contributor.name" :url="contributor.creator_profile_url" /></template
          >{{ contributor.name }}
          <BaseProse v-if="contributor.is_creator" wrapper="span" variant="secondary" size="xsmall"
            >(creator)</BaseProse
          ></ListItem
        >
      </List>
    </template>
  </component>
</template>
