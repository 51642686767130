import nodePath from 'path-browserify';

export function pathParents(path: string): string[] {
  if (nodePath.isAbsolute(path)) {
    throw new TypeError('Not implemented');
  }

  if (path === '.') {
    return ['.'];
  }

  const parents = ['.'];
  const splitPath = parts(path);
  for (let i = 0; i < splitPath.length - 1; ++i) {
    parents.push(splitPath.slice(0, i + 1).join(nodePath.sep));
  }
  return parents.reverse();
}

export function isParent(parent: string, dir: string): boolean {
  const relative = nodePath.relative(parent, dir);
  return !!relative && !relative.startsWith('..') && !nodePath.isAbsolute(relative);
}

export function parts(path: string): string[] {
  return path.split(nodePath.sep).filter((part) => part.length !== 0);
}

export function completeExtension(path: string): string {
  const ext = nodePath.basename(path).split('.').slice(1).join('.');
  return ext === '' ? '' : `.${ext}`;
}

export function stem(path: string): string {
  return nodePath.basename(path).split('.')[0];
}
