<template>
  <div class="content">
    <div class="info">
      <Icon class="warning-icon" name="warning-fill" />
      <SwText variant="headline1" class="title">Your trial has expired</SwText>
      <SwText>{{ reason }}</SwText>
      <SwText>{{ details }}</SwText>
      <Action
        v-if="isWorkspaceAdmin"
        size="small"
        class="upgrade-button"
        data-testid="upgrade-button"
        @click="onUpgradePlan"
        >Upgrade plan
      </Action>
    </div>
  </div>
</template>

<script>
import { useStigg } from '@/common/composables/useStigg';
import { PageRoutesNames } from '@/common/consts';
import { billingPlanTypes } from '@swimm/shared';
import { mapGetters } from 'vuex';
import { useBillingStore } from '@/modules/billing/store/billing';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';

export default {
  props: {
    workspaceId: { type: String, required: true },
  },
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const { stiggClient } = useStigg();
    const { setShowBillingPlans } = useBillingStore();

    return {
      user,
      stiggClient,
      setShowBillingPlans,
    };
  },
  computed: {
    ...mapGetters('database', ['db_getWorkspace', 'db_getWorkspaceUsers', 'db_isWorkspaceAdmin']),
    isWorkspaceAdmin() {
      return this.db_isWorkspaceAdmin(this.workspaceId, this.user.uid);
    },
    currentUserWorkspaceUser() {
      const workspaceUsers = this.db_getWorkspaceUsers(this.workspaceId);
      return (workspaceUsers || {})[this.user?.uid];
    },
    isLockedOut() {
      return this.currentUserWorkspaceUser?.expired;
    },
    details() {
      if (this.isLockedOut) {
        if (!this.isWorkspaceAdmin) {
          return 'To continue using Swimm, please contact your workspace admin.';
        }
        return 'To continue using Swimm please:';
      }
      if (!this.isWorkspaceAdmin) {
        return `If you'd like to restore access to these premium features please contact your workspace admin.`;
      }
      return `If you'd like to restore access to these premium features please:`;
    },
    reason() {
      if (this.isLockedOut) {
        return `Your ${this.workspaceName} Workspace membership has expired.`;
      }
      return `The ${this.workspaceName} Workspace trial has expired.`;
    },
    workspaceLicense() {
      const workspace = this.db_getWorkspace(this.workspaceId);
      return workspace.license || billingPlanTypes.FREE;
    },
    workspaceName() {
      const workspace = this.db_getWorkspace(this.workspaceId);
      return workspace.name;
    },
  },
  methods: {
    onUpgradePlan() {
      this.setShowBillingPlans(true);
      this.$router.push({ name: PageRoutesNames.HOME_PAGE });
    },
  },
};
</script>

<style scoped lang="postcss">
.content {
  margin: 200px auto;
  width: fit-content;
}

.upgrade-button {
  margin-top: var(--space-sm);
  width: 100%;
}

.info {
  padding: 16px 32px 24px 32px;
  background-color: var(--color-status-warning);
  display: flex;
  flex-direction: column;
  border-radius: 4px;

  .warning-icon {
    align-self: center;
    font-size: var(--headline1);
    color: var(--text-color-warning);
  }

  .title {
    padding: 0;
    align-self: center;
    margin-bottom: var(--space-base);
  }
}
</style>
