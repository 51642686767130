<script setup lang="ts">
import { ref, watch } from 'vue';
import { escapeRegExpCharacters } from '@swimm/shared';

const props = defineProps({
  fullText: { type: String, required: true },
  highlightedText: { type: String, required: true },
});

const words = ref<string[]>([]);

const splitText = (fullText: string, highlightedText: string): string[] => {
  if (!highlightedText) {
    return [fullText];
  }
  const regex = new RegExp(`(${escapeRegExpCharacters(highlightedText)})`, 'gi');
  return fullText.split(regex);
};

const isSubText = (word: string) => {
  return props.highlightedText.toLowerCase() === word.toLowerCase();
};

watch(
  () => [props.fullText, props.highlightedText],
  ([fullText, highlightedText]) => {
    words.value = splitText(fullText, highlightedText);
  },
  { immediate: true }
);
</script>

<template>
  <span v-for="(word, index) in words" :key="index" :class="{ highlighted: isSubText(word) }">
    {{ word }}
  </span>
</template>

<style scoped>
.highlighted {
  background-color: var(--color-status-warning-hover);
}
</style>
