import UAParser from 'ua-parser-js';

const DeviceType = {
  MOBILE: 'mobile',
};

export function isMobile() {
  return getDeviceDetails().type === DeviceType.MOBILE;
}

export function isMac() {
  return getDeviceDetails().osName?.toLowerCase()?.includes('mac');
}

export function getDeviceDetails() {
  const {
    device: { vendor, model, type },
    os: { name: osName, version: osVersion },
  } = UAParser(navigator.userAgent);
  return { vendor, model, osName, osVersion, type };
}
