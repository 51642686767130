<script setup lang="ts">
import { Loader, SwText } from '@swimm/ui';
import { BaseButton, BaseIcon, BaseLayoutGap } from '@swimm/reefui';
import AiFeedback from '@/components/generativeAI/AiFeedback.vue';
import MermaidAiDebugButton from './MermaidAiDebugButton.vue';
import type { GenerateMermaidWithAiDebugData } from './mermaidTypes';

withDefaults(
  defineProps<{
    status: 'progress' | 'error' | 'feedback';
    progress: number;
    debugData: GenerateMermaidWithAiDebugData | null;
  }>(),
  {
    progress: 0,
  }
);
const emit = defineEmits<{
  (e: 'stop-generation'): void;
  (e: 'stop-and-regenerate'): void;
  (e: 'regenerate'): void;
  (e: 'close-status'): void;
}>();

function stop() {
  emit('stop-generation');
}

function stopAndRegenerate() {
  emit('stop-and-regenerate');
}

function regenerate() {
  emit('regenerate');
}
function closeStatus() {
  emit('close-status');
}
</script>
<template>
  <div class="status-panel">
    <template v-if="status === 'progress'">
      <BaseLayoutGap direction="column" class="progress-section">
        <BaseLayoutGap direction="row" alignment="stretch">
          <BaseLayoutGap direction="row">
            <Loader secondary class="generating-loader" />
            <SwText variant="body-S">Generating Diagram...</SwText>
          </BaseLayoutGap>
          <BaseLayoutGap>
            <BaseButton variant="tertiary" @click="stop"
              ><template #rightIcon><BaseIcon name="stop"></BaseIcon></template>Stop</BaseButton
            >
            <BaseButton variant="tertiary" @click="stopAndRegenerate"
              ><template #rightIcon><BaseIcon name="pencil"></BaseIcon></template>Edit & Regenerate</BaseButton
            >
          </BaseLayoutGap>
        </BaseLayoutGap>
        <progress class="ai-progress" :value="progress" max="100"></progress>
      </BaseLayoutGap>
    </template>
    <template v-else-if="status === 'error'">
      <BaseLayoutGap class="error-section">
        <MermaidAiDebugButton v-if="debugData" :debug-data="debugData" />
        <BaseLayoutGap>
          <BaseIcon name="warning" />
          <SwText variant="body-S">Error Streaming</SwText>
        </BaseLayoutGap>
        <BaseButton variant="tertiary" @click="regenerate" class="regenerate"
          ><template #rightIcon><BaseIcon name="pencil"></BaseIcon></template>Edit & Regenerate</BaseButton
        >
      </BaseLayoutGap>
    </template>
    <template v-else-if="status === 'feedback'">
      <BaseLayoutGap class="feedback-section">
        <MermaidAiDebugButton v-if="debugData" :debug-data="debugData" />
        <AiFeedback
          prompt="How was the result?"
          :properties-for-analytics="{ Context: 'Mermaid' }"
          :show-close="false"
          show-revert-button
          revert-text="Edit & Regenerate"
          revert-icon="pencil"
          @analytic="() => {}"
          class="ai-feedback"
          @close-feedback="closeStatus"
          @revert="regenerate"
        />
      </BaseLayoutGap>
    </template>
  </div>
</template>

<style scoped lang="postcss">
.status-panel {
  width: 100%;

  .progress-section {
    width: 100%;

    progress.ai-progress {
      -webkit-appearance: none;
      appearance: none;
      height: 6px;
      width: 100%;
      margin: var(--space-xs) 0;
    }

    progress.ai-progress::-webkit-progress-bar {
      border-radius: 80px;
      background-color: var(--color-hover);
    }

    progress.ai-progress::-webkit-progress-value {
      transition: width ease 0.5s;
      border-radius: 20px;
      background-color: var(--text-color-magic-strong);
    }

    .generating-loader {
      --loader-size: 24px !important;
    }
  }

  .error-section {
    width: 100%;
    color: var(--color-text-danger);

    .regenerate {
      margin-left: auto;
    }
  }

  .feedback-section {
    width: 100%;

    .ai-feedback {
      width: 100%;
    }
  }
}
</style>
