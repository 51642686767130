import { defineStore } from 'pinia';
import { ref, toRaw } from 'vue';

export const useMultiPopupWorkaroundStore = defineStore('multiPopupWorkaroundStore', () => {
  const currentPopupHideFunction = ref<(() => void) | null>(null);
  const onNewPopupShown = (hideFunction: () => void) => {
    if (toRaw(currentPopupHideFunction.value) === toRaw(hideFunction)) {
      return;
    }
    currentPopupHideFunction.value?.();
    currentPopupHideFunction.value = hideFunction;
  };
  return {
    onNewPopupShown,
  };
});
