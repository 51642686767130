import { type EditorEvents, Extension, type Range } from '@tiptap/core';
import { VueRenderer } from '@tiptap/vue-3';
import { type DelegateInstance, type Instance, delegate } from 'tippy.js';
import DragHandle from '../../components/DragHandle.vue';
import { type WatchStopHandle } from 'vue';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    globalDragHandle: {
      moveNodeUp: (pos: number, depth: number) => ReturnType;
      moveNodeDown: (pos: number, depth: number) => ReturnType;
    };
  }
}

declare module 'tippy.js' {
  interface Instance {
    _component?: VueRenderer;
    _handleUpdate: (props: EditorEvents['update']) => void;
  }
}

export interface GlobalDragHandleStorage {
  tippy?: DelegateInstance;
  childTippyInstances?: Set<Instance>;
  stop?: WatchStopHandle;
}

export default Extension.create<unknown, GlobalDragHandleStorage>({
  name: 'globalDragHandle',

  onCreate() {
    const editor = this.editor;
    const swimmEditorServices = getSwimmEditorServices(editor);
    const customConfiguration = swimmEditorServices?.getCustomConfiguration(this.name);

    this.storage.childTippyInstances = new Set();
    this.storage.tippy = delegate(editor.view.dom, {
      target: '.ProseMirror > *',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      appendTo: editor.view.dom.parentElement!,
      theme: 'none',
      placement: 'left-start',
      trigger: 'mouseenter',
      hideOnClick: false,
      interactive: true,
      zIndex: 2, // Same z-index as editor, so that it plays well with dropdowns inside the editor.
      arrow: false,
      // Adding aria attributes to the ProseMirror DOM causes ProseMirror to
      // immediately recreate the node, unless we add some handling so that
      // ProseMirror correctly ignores them
      aria: {
        content: null,
        expanded: false,
      },
      // Tippy.js doesn't delegate hide/show to the child tippy instances for
      // some reason, probably an oversight, and it doesn't expose the set of
      // child tippy instances it creates, so we maintain our own set of them
      // using onCreate and onDestroy so that we are able to use hide below
      onCreate: (instance) => {
        this.storage.childTippyInstances?.add(instance);
      },
      onDestroy: (instance) => {
        this.storage.childTippyInstances?.delete(instance);
      },
      onShow(instance) {
        if (instance.reference === editor.view.dom || !!customConfiguration?.hide) {
          return;
        }

        instance._component = new VueRenderer(DragHandle, {
          props: {
            editor,
            instance,
            pos: editor.view.posAtDOM(instance.reference, 0),
          },
          editor,
        });
        instance.setContent(instance._component.element);

        instance._handleUpdate = ({ editor }: EditorEvents['update']) => {
          const pos = editor.view.posAtDOM(instance.reference, 0);
          if (pos === -1) {
            instance.hide();
          }

          instance._component?.updateProps({
            ...instance._component.props,
            pos,
          });
        };
        editor.on('update', instance._handleUpdate);
      },
      onHide(instance) {
        if (instance.reference === editor.view.dom) {
          return;
        }

        editor.off('update', instance._handleUpdate);
        instance._component?.destroy();
      },
    });
  },

  onUpdate() {
    // Hide all handles on any change to the editor to avoid any handles being
    // left on nodes that have been removed or moved
    this.storage.childTippyInstances?.forEach((instance) => instance.hide());
  },

  onDestroy() {
    this.storage.stop?.();
    this.storage.tippy?.destroy();
  },

  addCommands() {
    return {
      moveNodeUp:
        (pos, depth) =>
        ({ chain, state }) => {
          const $pos = state.doc.resolve(pos);

          const posBefore = $pos.before(depth) - 1;
          if (posBefore < 1) {
            return false;
          }

          const newPos = state.doc.resolve(posBefore).before(depth);

          let deleteRange: Range;
          if ($pos.depth >= 1) {
            deleteRange = { from: $pos.before(depth), to: $pos.after(depth) };
          } else {
            // When resolved pos depth is 0, it means that the node is considered a top level node
            // This currently only happens for snippet placeholder nodes
            // in that case computing the delete range using $pos.before and $pos.after does not work
            deleteRange = { from: $pos.pos, to: $pos.pos + ($pos.nodeAfter?.nodeSize ?? 0) };
          }

          return chain()
            .deleteRange(deleteRange)
            .command(({ commands, dispatch, tr }) => {
              if (dispatch) {
                commands.insertContentAt(tr.mapping.map(newPos), ($pos.node(depth) ?? $pos.nodeAfter).toJSON());
              }

              return true;
            })
            .run();
        },

      moveNodeDown:
        (pos, depth) =>
        ({ chain, state }) => {
          const $pos = state.doc.resolve(pos);

          let posAfter: number;
          if ($pos.depth >= 1) {
            posAfter = $pos.after(depth) + 1;
          } else {
            // When resolved pos depth is 0, it means that the node is considered a top level node
            // This currently only happens for snippet placeholder nodes
            // and they receive themselves as the nodeAfter
            // To get the correct insert position, we need to base ourselves 1 position after that
            posAfter = $pos.pos + ($pos.nodeAfter?.nodeSize ?? 0) + 1;
          }

          if (posAfter > state.doc.content.size) {
            return false;
          }

          const newPos = state.doc.resolve(posAfter).after(depth);

          let deleteRange: Range;
          if ($pos.depth >= 1) {
            deleteRange = { from: $pos.before(depth), to: $pos.after(depth) };
          } else {
            // When resolved pos depth is 0, it means that the node is considered a top level node
            // This currently only happens for snippet placeholder nodes
            // in that case computing the delete range using $pos.before and $pos.after does not work
            deleteRange = { from: $pos.pos, to: $pos.pos + ($pos.nodeAfter?.nodeSize ?? 0) };
          }

          return chain()
            .deleteRange(deleteRange)
            .command(({ commands, dispatch, tr }) => {
              if (dispatch) {
                commands.insertContentAt(tr.mapping.map(newPos), ($pos.node(depth) ?? $pos.nodeAfter).toJSON());
              }

              return true;
            })
            .run();
        },
    };
  },
});
