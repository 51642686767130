import type { Draft } from '@/modules/drafts3/db';

export const BlobFileExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.svg', '.tiff', '.ico', '.mov', '.mp4', '.webp'];

export type BatchImportScreenKeys =
  | 'IMPORT_OPTIONS_SELECTION'
  | 'FILES_SELECTION'
  | 'IMPORT_PROCESS'
  | 'IMPORT_REPORT'
  | 'IMPORT_SUMMARY';

export interface BatchImportScreen {
  key: BatchImportScreenKeys;
  name: string;
}

export interface FileConversionReport {
  reason: string;
  type: 'WARNING' | 'ERROR';
}

export interface BatchImportFile {
  id: string;
  name: string;
  path: string;
  file: string;
  issues: FileConversionReport[];
  swm?: Draft;
}

export const BATCH_IMPORT_SCREENS: { [key: string]: BatchImportScreen } = {
  IMPORT_OPTIONS_SELECTION: { key: 'IMPORT_OPTIONS_SELECTION', name: 'Choose source' },
  FILES_SELECTION: { key: 'FILES_SELECTION', name: 'Select files' },
  IMPORT_PROCESS: { key: 'IMPORT_PROCESS', name: 'Import loading' },
  IMPORT_REPORT: { key: 'IMPORT_REPORT', name: 'Errors status' },
  IMPORT_SUMMARY: { key: 'IMPORT_SUMMARY', name: 'Summary' },
};

export const IMPORT_OPTIONS = {
  UPLOAD_FOLDER: 'upload-folder',
  UPLOAD_NOTION_ZIP_FOLDER: 'upload-notion-zip-folder',
  UPLOAD_CONFLUENCE_ZIP_FOLDER: 'upload-confluence-zip-folder',
  RUN_SMART_IMPORT: 'run-smart-import',
} as const;

export const MD_FILE_KINDS = {
  MD_FILES: { id: 'md', title: 'Markdown' },
  README_FILES: { id: 'readme', title: 'README.md' },
} as const;
