<script setup lang="ts">
import { computed, ref } from 'vue';

import BaseHeading from '../../components/BaseHeading/BaseHeading.vue';
import UtilFocusItems from '../../components/UtilFocusItems/UtilFocusItems.vue';

import NodeTree, { type SelectableFolderTree } from '../NodeTree/NodeTree.vue';
import Menu from '../Menu/Menu.vue';

const props = defineProps<{
  selectedNodes?: SelectableFolderTree[];
}>();

const emit = defineEmits<{
  'selectedNodes:remove': [node: SelectableFolderTree];
}>();

const focusItems = ref<InstanceType<typeof UtilFocusItems>>();
const node = computed<SelectableFolderTree>(() => ({
  name: '',
  path: '',
  children: props.selectedNodes && props.selectedNodes.length ? props.selectedNodes : [],
  type: 'directory',
}));

const selectedFilesCount = computed(() => {
  return props.selectedNodes ? props.selectedNodes.length : 0;
});

function onSelectedNodesRemove(node: SelectableFolderTree) {
  emit('selectedNodes:remove', node);
}

defineExpose({
  focusItems,
});
</script>

<template>
  <div class="swm-selection-content-token-selected-files">
    <header class="swm-selection-content-token-selected-files__header">
      <BaseHeading :level="6" :semantic-level="3">{{ selectedFilesCount }} files added</BaseHeading>
    </header>
    <UtilFocusItems ref="focusItems" disable-auto-focus>
      <Menu>
        <NodeTree
          :node="node!"
          :selected-nodes="selectedNodes"
          selectable
          @selected-nodes:remove="onSelectedNodesRemove"
        >
          <template #item-tooltip="{ item }"
            ><span v-if="item.repo && item.isCrossRepo"> {{ item.repo.name }} > </span> {{ item.path }}</template
          >
        </NodeTree>
      </Menu>
    </UtilFocusItems>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-selection-content-token-selected-files {
  $self: &;
  flex: 30;

  @include basic-resets;

  border-top: 1px solid var(--color-border-default-subtle);
  flex-shrink: 0;
  padding: 0 var(--space-xsmall) var(--space-xsmall);

  &__header {
    padding: var(--space-xsmall) var(--space-xsmall) var(--space-xxsmall);
  }
}
</style>
