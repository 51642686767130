<template>
  <div v-tooltip="tooltipOptions" @click="$emit('click')">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    length: { type: Number, required: true },
    content: { type: String, default: '' },
    long: { type: Boolean, default: false },
    placement: { type: String, default: 'top' },
  },
  emits: ['click'],
  computed: {
    tooltipOptions() {
      return {
        disabled: this.content.length <= this.length,
        content: this.content,
        popperClass: this.long ? 'long-tooltip' : '',
        placement: this.placement,
      };
    },
  },
};
</script>
