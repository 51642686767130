import { type ThemeOption } from '../theme';

export interface User {
  uid: string;
  displayName: string;
  nickname?: string;
  email?: string;
  photoURL?: string;
}

export const UserOnboardingFields = {
  ONBOARDING: 'onboarding',
  ONBOARDING_CUSTOM_STEPS: 'onboarding_custom_steps',
  GET_STARTED_STEPS: 'get_started_steps',
  IS_FINISHED_GET_STARTED: 'is_finished_get_started',
  FIRST_WORKSPACE: 'first_workspace',
  CREATED: 'created',
  SHOWN_HELP_TOOLTIPS: 'shown_help_tooltips',
  SHOWN_EXISTING_USERS_HELP_TOOLTIPS: 'shown_existing_users_help_tooltips',
  LAST_NPS_SHOWN_DATE: 'last_nps_shown_date',
  LAST_NPS_SHOWN_RESULT: 'last_nps_shown_result',
};

export interface UserOnboardingFieldsList {
  onboarding?: string;
  onboarding_custom_steps?: string;
  get_started_steps?: string;
  is_finished_get_started?: boolean;
  first_workspace?: string;
  shown_help_tooltips?: string;
  shown_existing_users_help_tooltips?: string;
  last_nps_shown_date?: string;
  last_nps_shown_result?: string;
}

interface UserGitProviderData {
  azure_devops_id?: string;
  azure_devops_login?: string;
  bitbucket_dc_id?: string;
  bitbucket_dc_login?: string;
  bitbucket_id?: string;
  bitbucket_login?: string;
  github_enterprise_id?: string;
  github_enterprise_login?: string;
  github_id?: string;
  github_login?: string;
  gitlab_enterprise_id?: string;
  gitlab_enterprise_login?: string;
  gitlab_id?: string;
  gitlab_login?: string;
}

interface UserCustomSettings {
  state?: string; // random state for oauth
  hasGeneratedAi?: string;
  install_ide_plugin_banner_dismissed_at?: string;
  redirect?: string;
  salt?: string; // the drafts encoding salt
  swimmport_enabled?: string;
  is_autocomplete_enabled?: boolean;
  theme?: ThemeOption;
  updated?: string;
  created?: string;
  user_profile?: {
    profile_name: string;
    profile_url: string;
    profile_update: string;
    profile_uid: string;
  };
}

export type UserInUsersDBCollection = UserOnboardingFieldsList & UserGitProviderData & UserCustomSettings;
