import { UrlUtils, state } from '@swimm/shared';
import { type Auth, type User, getAuth } from 'firebase/auth';
import { type AuthInternal, UserImpl } from '@firebase/auth/internal';

class _SwimmDevApi {
  private auth: Auth;

  constructor() {
    this.auth = getAuth();
  }

  async setLoginToken(token: string) {
    // XXX Implementation detail... Not public..., https://github.com/firebase/firebase-js-sdk/issues/1874
    const user = UserImpl._fromJSON(this.auth as unknown as AuthInternal, JSON.parse(token)) as User;
    await this.auth.updateCurrentUser(user);
  }

  async setGitHostingToken(token: string | null, gitUrl: string) {
    if (token != null) {
      const hostname = UrlUtils.getUrlHostname(gitUrl);
      const sluggedUrl = UrlUtils.slugUrlForConfig(hostname);
      await state.set({ key: 'git_tokens', value: { [sluggedUrl]: { token } } });
    } else {
      await state.deleteKey({ key: 'git_tokens' });
    }
  }
}

export type SwimmDevApi = InstanceType<typeof _SwimmDevApi>;

declare global {
  interface Window {
    swimm?: SwimmDevApi;
    FIREBASE_USER?: string;
    GIT_TOKEN?: string;
    GIT_URL?: string;
    E2E_TEST_ENV?: string;
  }
}

export async function initializeDevApi() {
  if (['development', 'emulated'].includes(import.meta.env.MODE)) {
    window.swimm = new _SwimmDevApi();

    if (window.FIREBASE_USER != null) {
      await window.swimm.setLoginToken(JSON.stringify(window.FIREBASE_USER));
    }

    if (window.GIT_TOKEN != null && window.GIT_URL != null) {
      await window.swimm.setGitHostingToken(window.GIT_TOKEN, window.GIT_URL);
    }
  }
}
