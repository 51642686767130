import type { JSONContent } from '@tiptap/vue-3';
import { isContentEmptyOrWhitespace, isDocEmpty } from '../utils';

const isContentBeginingOfSlashCommand = (content: JSONContent, command: string) => {
  if (content.type !== 'paragraph' || !content.content || content.content.length !== 1) {
    return false;
  }
  const childContent = content.content[0];
  if (!childContent || childContent.type !== 'text' || !childContent.text) {
    return false;
  }
  return command.startsWith(childContent.text.trim());
};

export const containsTextOutsideSnippets = (content: JSONContent) => {
  if (isDocEmpty(content)) {
    return false;
  }
  return (
    content.content?.some(
      (content: JSONContent) =>
        content.type !== 'swmSnippet' &&
        // If the user just created a newline to type '/snippet' or '/code' we don't want to consider it as text outside snippets.
        !isContentBeginingOfSlashCommand(content, '/snippet') &&
        !isContentBeginingOfSlashCommand(content, '/code') &&
        // An empty paragraph is also not considered text for this matter.
        !isContentEmptyOrWhitespace(content)
    ) ?? false
  );
};

export const containsSnippetComment = (content: JSONContent) => {
  return (
    content.content?.some(
      (content: JSONContent) => content.type === 'swmSnippet' && !isContentEmptyOrWhitespace(content.content)
    ) ?? false
  );
};
