<script setup>
import { computed, inject } from 'vue';
import { Icon } from '@swimm/ui';

const props = defineProps({
  title: { type: String, default: '' },
  id: { type: Number, required: true },
});

const itemClick = inject('itemClick');
const current = inject('current');

const isOpen = computed(() => {
  return current.value === props.id;
});

function onClick() {
  itemClick(props.id);
}
</script>

<template>
  <div class="accordion-item">
    <div class="header" @click="onClick">
      <Icon :name="isOpen ? 'arrow-down' : 'arrow-right'" />
      <div class="title">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
    </div>
    <slot v-if="isOpen" />
  </div>
</template>

<style scoped lang="postcss">
.accordion-item {
  border-bottom: 1px solid var(--color-border-default-subtle);
  cursor: pointer;

  .header {
    display: flex;
    align-items: center;
    padding: 8px 0;

    .title {
      font-family: var(--fontfamily-secondary);
      font-size: var(--fontsize-xs);
      color: var(--text-color-secondary);
      display: flex;
      align-items: center;
    }
  }
}
</style>
