// @ts-strict
import { StatusCodes } from 'http-status-codes';
import type { GitProviderName, RemoteBranchInfo, RepoIdOwnerName, ResultWithReturnCode } from '@swimm/shared';
import {
  GitHubPullRequestState,
  type PullRequestState,
  type RemoteRepository,
  type RepoIdOrRepoData,
  config,
  getLoggerNew,
  gitwrapper,
} from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export async function local_repo() {
  return { code: config.ERROR_RETURN_CODE, errorMessage: 'Implement Me!!!', status: StatusCodes.INTERNAL_SERVER_ERROR };
}

export async function* getRepoBranches({
  repoId,
  onlyOpenBranches = false,
}: {
  repoId: string;
  onlyOpenBranches?: boolean;
}): AsyncIterable<{ hasNextPage: boolean; branches: RemoteBranchInfo[] }> {
  const prStates = (onlyOpenBranches ? [GitHubPullRequestState.OPEN] : []) as unknown as PullRequestState[];
  yield* gitwrapper.getBranches({ repoId, prStates });
}
const _getRepoDefaultBranchCache = new Map<
  string,
  Promise<{ shouldCache: boolean; result: ResultWithReturnCode<{ branch: string }> }>
>();

/* we use cache of promises for this call,
but if we get an error which is not 404 
we remove from the cache - to allow recomputation
this is since we don't want to cache calls before the git authorization is done
*/
export async function getRepoDefaultBranch({
  repoId,
  owner,
  provider,
  repoName,
  api_url,
  tenant_id,
}: RepoIdOrRepoData): Promise<ResultWithReturnCode<{ branch: string }>> {
  if (repoId) {
    if (!_getRepoDefaultBranchCache.has(repoId)) {
      _getRepoDefaultBranchCache.set(
        repoId,
        getRepoDefaultBranchImpl({ repoId, owner, provider, repoName, api_url, tenant_id })
      );
    }
    const result = await _getRepoDefaultBranchCache.get(repoId);
    // the result should not be cached, so we remove it from the cache
    // in this case, the cache was used only for "parallel" calls which is ok
    if (!result.shouldCache) {
      _getRepoDefaultBranchCache.delete(repoId);
    }
    return result.result;
  }
  const result = await getRepoDefaultBranchImpl({ repoId, owner, provider, repoName, api_url, tenant_id });
  return result.result;
}

async function getRepoDefaultBranchImpl({
  repoId,
  owner,
  provider,
  repoName,
  api_url,
  tenant_id,
}: RepoIdOrRepoData): Promise<{ shouldCache: boolean; result: ResultWithReturnCode<{ branch: string }> }> {
  try {
    // @ts-ignore // NOTE: since we're a wrapper, we need to pass all of the args. The type system doesn't allow us to pass all of them, so we need to ignore it for now.
    const repo = await gitwrapper.getRepoRemoteData({ repoId, owner, provider, repoName, api_url, tenant_id });
    return { shouldCache: true, result: { code: config.SUCCESS_RETURN_CODE, branch: repo.defaultBranch } };
  } catch (err) {
    logger.error(
      { err },
      `Could not get default repo branch for ${repoId}. Details: ${err.message} status=${err.status}`
    );
    // we cache if the error was 404, since it is not auth error
    return { shouldCache: err?.status === 404, result: { code: config.ERROR_RETURN_CODE } };
  }
}

export async function getRepoRemoteDataBatch({
  provider,
  repos,
}: {
  provider: GitProviderName;
  repos: RepoIdOwnerName[];
}) {
  try {
    return await gitwrapper.getRepoRemoteDataBatch({ provider, repos });
  } catch (err) {
    logger.error({ err }, `Failed in getRepoRemoteDataBatch ${err.message} status=${err.status}`);
    return {};
  }
}

export async function getRemoteRepo({ repoId, provider, repoName, owner, api_url, tenant_id }: RepoIdOrRepoData) {
  try {
    // @ts-ignore // NOTE: since we're a wrapper, we need to pass all of the args. The type system doesn't allow us to pass all of them, so we need to ignore it for now.
    const repo = await gitwrapper.getRepoRemoteData({ repoId, provider, repoName, owner, api_url, tenant_id });
    return { code: config.SUCCESS_RETURN_CODE, repo };
  } catch (err) {
    logger.error({ err }, `Could not get default repo data. Details: ${err.message}`);
    return { code: config.ERROR_RETURN_CODE };
  }
}

export async function getRemoteRepoBranch({ repoId, branchName }: { repoId?: string; branchName?: string }) {
  try {
    const branchDetails = await gitwrapper.getBranch({ repoId: repoId, branchName: branchName });
    if (branchDetails) {
      return { code: config.SUCCESS_RETURN_CODE, branchDetails: branchDetails };
    }
  } catch (err) {
    logger.error({ err }, `Could not get repo branch. Details: ${err.message}`);
  }
  return { code: config.ERROR_RETURN_CODE };
}

export async function isBranchExists({ repoId, branchName }: { repoId?: string; branchName?: string }) {
  try {
    const isBranchExists = await gitwrapper.isBranchExists({ repoId: repoId, branchName: branchName });
    if (isBranchExists) {
      return { code: config.SUCCESS_RETURN_CODE, isBranchExists };
    }
  } catch (err) {
    logger.error({ err }, `Could not get repo branch. Details: ${err.message}`);
  }
  return { code: config.ERROR_RETURN_CODE };
}

export async function isRepoPrivate({
  repoId,
  provider,
  owner,
  repoName,
  api_url,
}: {
  repoId: string;
  provider?: GitProviderName;
  owner?: string;
  repoName?: string;
  api_url?: string;
}) {
  try {
    const getRepoRemoteDataArgs: RepoIdOrRepoData =
      provider && repoName && owner ? { provider, repoName, owner, api_url } : { repoId };
    const repo = await gitwrapper.getRepoRemoteData(getRepoRemoteDataArgs);
    return { code: config.SUCCESS_RETURN_CODE, repoId: repoId, isPrivate: repo.isPrivate };
  } catch (err) {
    logger.error({ err }, `Could not get whether repo ${repoId} is private or public. Details: ${err.message}`);
  }
  return { code: config.ERROR_RETURN_CODE };
}

export async function* getUserAvailableRepositories(
  provider: GitProviderName,
  driverOptions?: { baseUrl?: string; tenantId?: string }
): AsyncIterable<RemoteRepository> {
  try {
    yield* gitwrapper.getUserRemoteRepositories(provider, driverOptions);
  } catch (err) {
    logger.error({ err }, `Could not get available repos data. Details: ${err.message}`);
    throw err;
  }
}

export async function getChangeFilesForBranch({
  repoId,
  defaultBranch,
  branch,
}: {
  repoId: string;
  defaultBranch: string;
  branch: string;
}) {
  try {
    const changedFiles = await gitwrapper.getDiffFiles({
      repoId: repoId,
      compareFrom: defaultBranch,
      compareTo: branch,
    });
    return changedFiles
      .map((file) => ({
        path: file.newFilePath,
        status: file.status,
      }))
      .filter((file) => file.path.startsWith(config.SWM_FOLDER_IN_REPO));
  } catch (err) {
    logger.error({ err }, `Could not get changed files for branch. Details: ${err.message}`);
    return [];
  }
}
