<script setup lang="ts">
import { computed, nextTick, onMounted, onUpdated, ref, toRef, watch } from 'vue';
import { until } from '@vueuse/core';
import type { TokenSuggestion } from '@swimm/shared';
import type { Theme } from 'shiki';
import type { SelectedIndex } from '../../types/transitional';
import type { CachedHighlighters } from '../../types';

import BaseProse from '../../components/BaseProse/BaseProse.vue';
import BaseButton from '../../components/BaseButton/BaseButton.vue';
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';
import BaseCode from '../../components/BaseCode/BaseCode.vue';
import BaseLoading from '../../components/BaseLoading/BaseLoading.vue';
import BaseTruncate from '../../components/BaseTruncate/BaseTruncate.vue';

import Menu from '../Menu/Menu.vue';
import MenuItem from '../MenuItem/MenuItem.vue';

import { getTheme } from '../../lib/theme';
import { getLanguageFromPath } from '../../lib/languages';
import { useShiki } from '../../lib/shiki';
import { useItemSelectionScrollHandler } from '../../composables/useItemSelectionScrollHandler';

const props = withDefaults(
  defineProps<{
    selectedIndex?: SelectedIndex;
    tokenSuggestions: TokenSuggestion[];
    query?: string;
    canShowMore?: boolean;
    showMoreLimitReached?: boolean;
  }>(),
  {
    selectedIndex: undefined,
    query: undefined,
  }
);

const emit = defineEmits<{
  updated: [];
  caching: [status: boolean];
  highlightersCached: [cachedHighlighters: CachedHighlighters];
  selectToken: [token: TokenSuggestion];
  focusedToken: [token: TokenSuggestion];
  hoveredToken: [token: TokenSuggestion];
  showMore: [index: number];
}>();

const { shiki, shikiIsReady } = useShiki();
const { scrollContainerRef, setItemRef } = useItemSelectionScrollHandler(toRef(props, 'selectedIndex'));
const textRefs = ref<(HTMLElement | null)[]>([]);
const useTheme = ref<Theme>();
const cachedHighlighters = ref<CachedHighlighters>({});
const cachedItemStyles = ref<{
  [key: string]: {
    transform: string;
    maxWidth: string;
  };
}>({});
const cachedTokenSuggestions = ref<TokenSuggestion[] | undefined>();
const isCaching = ref(false);
const isCachingMore = ref(false);
const lastHoveredToken = ref<TokenSuggestion | undefined>();
const lastFocusedToken = ref<TokenSuggestion | undefined>();

function setTheme() {
  useTheme.value = getTheme() === 'dark' ? 'github-dark' : 'github-light';
}

async function preloadCachedHighlighters() {
  setTheme();

  if (props.tokenSuggestions && props.tokenSuggestions.length) {
    await until(shikiIsReady).toBeTruthy();

    for (const token of props.tokenSuggestions) {
      const language = getLanguageFromPath(token.position.path);
      const theme = useTheme.value;
      const key = `${language}-${theme}`;

      if (!cachedHighlighters.value[key] && language && theme) {
        cachedHighlighters.value[key] = await shiki.value!.getHighlighter({ theme: theme, langs: [language] });
      }
    }

    cachedTokenSuggestions.value = props.tokenSuggestions;

    emit('highlightersCached', cachedHighlighters.value);
  }
}

function getOffset(refIndex: number) {
  const textElement = textRefs.value?.[refIndex];
  const highlightSpan = textElement?.querySelector('.code__highlight');

  if (!highlightSpan || !textElement) {
    return;
  }

  const codeRect = textElement.getBoundingClientRect();
  const spanRect = highlightSpan.getBoundingClientRect();

  // Position of highlight span relative to the code container
  const relativeLeft = spanRect.left - codeRect.left;

  // Desired position in percentage (e.g., 75% of code width)
  const desiredLeft = 0.75 * codeRect.width;

  // Calculate the required offset
  const offset = desiredLeft - relativeLeft;

  return offset < 0 ? Math.floor(offset) : undefined;
}

function setTextRef(el: HTMLElement, itemIndex: number) {
  textRefs.value[itemIndex] = el;
}

async function setItemStyle(refIndex: number, token: TokenSuggestion) {
  await nextTick();

  const offset = getOffset(refIndex);
  const textElement = textRefs.value?.[refIndex];

  if (textElement) {
    const cacheKey = `${refIndex}-${token.token}-${token.position.wordStart}`;

    if (cachedItemStyles.value[cacheKey]) {
      const style = cachedItemStyles.value[cacheKey];
      textElement.style.transform = style.transform;
      textElement.style.maxWidth = style.maxWidth;
    } else if (textElement && offset && offset < 0) {
      const currentTransform = textElement.style.transform;
      const currentMaxWidth = textElement.style.maxWidth;
      const newTransform = `translateX(${offset}px)`;
      const newMaxWidth = `calc(100% + ${Math.abs(offset)}px)`;

      if (currentTransform !== newTransform) {
        textElement.style.transform = newTransform;
      }

      if (currentMaxWidth !== newMaxWidth) {
        textElement.style.maxWidth = newMaxWidth;
      }

      cachedItemStyles.value[cacheKey] = {
        transform: newTransform,
        maxWidth: newMaxWidth,
      };
    }
  }

  return;
}

function isFocused(itemIndex: number) {
  if (!props.selectedIndex) {
    return false;
  }

  const selectedItemIndex = props.selectedIndex[0] || 0;
  const token = props.tokenSuggestions[selectedItemIndex];

  if (itemIndex === selectedItemIndex && lastFocusedToken.value !== token) {
    emit('focusedToken', token);
    lastFocusedToken.value = token;
  }

  return itemIndex === selectedItemIndex;
}

function onSelectToken(token: TokenSuggestion) {
  emit('selectToken', token);
}

function onFocusToken(token: TokenSuggestion) {
  if (lastFocusedToken.value !== token) {
    emit('focusedToken', token);
    lastFocusedToken.value = token;
  }
}

function onHoverToken(token: TokenSuggestion) {
  if (lastHoveredToken.value !== token) {
    emit('hoveredToken', token);
    lastHoveredToken.value = token;
  }
}

function showMore(index: number) {
  emit('showMore', index);
}

onMounted(async () => {
  await preloadCachedHighlighters();
});

onUpdated(() => {
  emit('updated');
});

// Trigger preloading of cached highlighters and
// dermine if we're caching or caching more (because show more has been clicked).
// The differentiation here effects the loading state.
watch(
  [() => props.query, () => props.tokenSuggestions],
  async ([newQuery, newTokenSuggestions], [oldQuery, oldTokenSuggestions]) => {
    // If tokenSuggestions hasn't previously had a value and
    // it now does we're caching for the first time.
    if (newTokenSuggestions?.length && !oldTokenSuggestions) {
      isCaching.value = true;
      await preloadCachedHighlighters();
      isCaching.value = false;

      // If the query hasn't changed but the tokenSuggestions are
      // more than they were previously we're caching more.
    } else if (
      newQuery === oldQuery &&
      oldTokenSuggestions &&
      newTokenSuggestions.length > oldTokenSuggestions?.length
    ) {
      isCachingMore.value = true;
      await preloadCachedHighlighters();
      isCachingMore.value = false;
    }
  },
  {
    immediate: true,
  }
);

defineExpose({
  caching: isCaching,
  cachingMore: isCachingMore,
});
</script>

<template>
  <div ref="scrollContainerRef" class="menu-tokens">
    <BaseLoading v-if="isCaching" variant="secondary" class="menu-tokens__loading" />
    <Menu v-else class="menu-tokens__content" wrapper="div">
      <MenuItem
        v-for="(item, index) in cachedTokenSuggestions"
        :key="`${item.position.path}-${item.token}-${item.position.wordStart}-${index}`"
        :ref="(el: any) => {
          if (el?.root && el?.text) {
            setItemRef(el.root, index);
            setTextRef(el.text, index);
          }
        }"
        wrapper="div"
        class="menu-tokens__item"
        :class="{
          'menu-tokens__item--realigned': cachedItemStyles[`${index}-${item.token}-${item.position.wordStart}`],
        }"
        :data-index="index"
        :focused="isFocused(index)"
        @click="onSelectToken(item)"
        @keydown.enter="onSelectToken(item)"
        @focusin="onFocusToken(item)"
        @mouseenter="onHoverToken(item)"
      >
        <BaseCode
          :code="item.lineData"
          :highlight-tokens="[item]"
          :query="query"
          :highlighter="
            cachedHighlighters && cachedHighlighters[`${getLanguageFromPath(item.position.path)}-${useTheme}`]
          "
          @highlighted="setItemStyle(index, item)"
        />

        <template #additional>
          <BaseProse class="menu-tokens__path" variant="secondary" size="small">
            <BaseTruncate align="right">{{ item.position.path }}:{{ item.position.line }}</BaseTruncate>
          </BaseProse>
          <!-- block-content here is required to make the underlying element not be an HTML button element - because
               those are focusable and ruin the arrow navigation between items (and it is impossible to make them
               not-focusable https://stackoverflow.com/a/54450857/804576) -->
          <BaseButton class="add-button" size="small" block-content>
            Add
            <template #rightIcon>
              <BaseIcon name="enter" class="keyboard-icon" />
            </template>
          </BaseButton>
        </template>
      </MenuItem>
      <BaseProse v-if="showMoreLimitReached" class="menu-tokens__limit-reached" wrapper="li" size="small"
        >Please refine your search parameters to see more tokens…</BaseProse
      >

      <MenuItem
        v-if="cachedTokenSuggestions?.length && (canShowMore || isCachingMore)"
        wrapper="li"
        class="menu-tokens__load-more"
        :class="{ 'menu-tokens__load-more--caching': isCachingMore }"
        :focused="isFocused(tokenSuggestions.length)"
        @click="showMore"
        @keydown.enter="showMore"
        ><BaseLoading v-if="isCachingMore" size="xsmall" variant="secondary" class="menu-tokens__loading-more" />
        <template v-else>Load more…</template></MenuItem
      >
    </Menu>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.menu-tokens {
  $self: &;

  @include basic-resets;

  overflow: auto;

  &__item {
    font-size: var(--font-size-xsmall);
    position: relative;

    &--realigned {
      &:before {
        background-color: var(--color-bg-default);
        box-shadow: 4px 0 8px 4px var(--color-bg-default);
        color: var(--color-text-disabled);
        display: block;
        content: '…';
        left: var(--space-xsmall);
        position: absolute;
        z-index: var(--layer-overlap);
      }

      &.menu-item--focused,
      &:hover,
      &:focus {
        &:before {
          background-color: var(--color-bg-surface-hover);
          box-shadow: 4px 0 8px 4px var(--color-bg-surface-hover);
        }
      }
    }

    &:not(.menu-item--focused):not(:focus):not(:hover) .add-button {
      display: none;
    }

    &:hover:not(.menu-item--focused):not(:focus) {
      .add-button {
        display: initial;
      }

      .keyboard-icon {
        display: none;
      }
    }
  }

  &__load-more {
    &--caching {
      &:hover,
      &:focus,
      &--focused {
        cursor: default;
        background-color: transparent;
      }
    }
  }

  &__loading-more {
    height: 21px; // Align perfectly with text
  }

  &__path {
    text-align: right;
    width: 30%;
    flex-grow: 1;
  }

  &__limit-reached {
    color: var(--color-text-secondary);
    list-style: none;
    padding: var(--space-xsmall) var(--space-xsmall) 0;
    text-align: center;
  }
}
</style>
