<script setup lang="ts">
import { productEvents } from '@swimm/shared';
import { Action } from '@swimm/ui';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';
import { storeToRefs } from 'pinia';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';

const props = defineProps({
  repoId: { type: String, required: true },
  workspaceId: { type: String, required: true },
  origin: { type: String, required: true },
  description: { type: String, required: true },
});

const route = useRoute();
const { theme } = storeToRefs(useUserConfigStore());
const analytics = useAnalytics();
const { githubAppUrl } = storeToRefs(useWorkspaceStore());

const imageUrl = computed(() => {
  return new URL(`./assets/install-gh-${theme.value}.png`, import.meta.url).href;
});

function reportInstallationClicked() {
  analytics.track(productEvents.INSTALL_GITHUB_APP, {
    'Workspace ID': props.workspaceId,
    'Repo ID': props.repoId,
    Origin: route.params.repoId ? 'View Repo' : 'View Workspace',
    Context: 'Install GitHub App Popup',
  });
}
</script>

<template>
  <div class="content-wrapper">
    <div class="img-wrapper">
      <img class="gh-img" :src="imageUrl" alt="Install GitHub App" />
    </div>
    <div class="wrap-text">{{ description }}</div>
    <a :href="githubAppUrl" target="_blank" class="btn-wrapper">
      <Action @click="reportInstallationClicked">Install GitHub App</Action>
    </a>
  </div>
</template>

<style scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-wrapper {
  padding-bottom: 24px;
}

.gh-img {
  height: 90px;
}

.wrap-text {
  max-width: 320px;
  font-size: var(--fontsize-xs);
  text-align: center;
  line-height: 16px;
}

.btn-wrapper {
  padding-top: 24px;
  flex-shrink: 0;
}
</style>
