/**
 * Regexps and functions parse/match html elements.
 *
 * Based on https://github.com/markdown-it/markdown-it/blob/2b6cac25823af011ff3bc7628bc9b06e483c5a08/lib/common/html_re.js
 *
 * @module
 */

import { escape, unescape } from 'lodash-es';

/** Matches an HTML attribute name.*/
const ATTR_NAME = '[a-zA-Z_:][a-zA-Z0-9:._-]*';

// Matches the various possible syntaxes of an HTML attribute value
const UNQUOTED = '[^"\'=<>`\\x00-\\x20]+';
const SINGLE_QUOTED = "'[^']*'";
const DOUBLE_QUOTED = '"[^"]*"';

/** Matches an HTML attribute value. */
const ATTR_VALUE = '(?:' + UNQUOTED + '|' + SINGLE_QUOTED + '|' + DOUBLE_QUOTED + ')';

/** Match a single HTML attribute. */
export const ATTRIBUTE = '(?:\\s+' + ATTR_NAME + '(?:\\s*=\\s*' + ATTR_VALUE + ')?)';

const OPEN_TAG = '<[A-Za-z][A-Za-z0-9\\-]*' + ATTRIBUTE + '*\\s*\\/?>';
const CLOSE_TAG = '<\\/[A-Za-z][A-Za-z0-9\\-]*\\s*>';
const COMMENT = '<!---->|<!--(?:-?[^>-])(?:-?[^-])*-->';
const PROCESSING = '<[?][\\s\\S]*?[?]>';
const DECLARATION = '<![A-Z]+\\s+[^>]*>';
const CDATA = '<!\\[CDATA\\[[\\s\\S]*?\\]\\]>';

/** Matches an HTML tag */
export const HTML_TAG_RE = new RegExp(
  '^(?:' + OPEN_TAG + '|' + CLOSE_TAG + '|' + COMMENT + '|' + PROCESSING + '|' + DECLARATION + '|' + CDATA + ')'
);

/** Matches an HTML open tag, with attributes. */
export const HTML_OPEN_TAG_RE = new RegExp('^<([A-Za-z][A-Za-z0-9-]*)(' + ATTRIBUTE + '*)\\s*\\/?>');

/** Matches an HTML close tag */
export const HTML_CLOSE_TAG_RE = new RegExp('<\\/([A-Za-z][A-Za-z0-9\\-]*)\\s*>');

/** Matches a single HTML attribute (name & value). */
export const HTML_ATTRIBUTE_RE = new RegExp('\\s+(' + ATTR_NAME + ')(?:\\s*=\\s*(' + ATTR_VALUE + '))?', 'g');

export const SINGLE_QUOTED_VALUE_RE = new RegExp("'([^']*)'");
export const DOUBLE_QUOTED_VALUE_RE = new RegExp('"([^"]*)"');

/**
 * Parse a single HTML attribute value.
 */
function parseAttrValue(value: string): string {
  let match = value.match(SINGLE_QUOTED_VALUE_RE);
  if (match != null) {
    value = match[1];
  } else {
    match = value.match(DOUBLE_QUOTED_VALUE_RE);
    if (match != null) {
      value = match[1];
    }
  }

  return unescape(value);
}

/**
 * Parse the HTML attributes part of an HTML tag.
 */
export function parseHtmlAttrs(text: string | null): [string, string][] {
  const attrs: [string, string][] = [];

  for (const match of (text ?? '').matchAll(HTML_ATTRIBUTE_RE)) {
    attrs.push([match[1], match[2] != null ? parseAttrValue(match[2]) : '']);
  }

  return attrs;
}

/**
 * Render the HTML attributes part of an HTML tag.
 */
export function renderHtmlAttrs(attrs: [string, string][]): string {
  let result = '';

  for (const [name, value] of attrs) {
    result += ` ${name}`;

    if (value) {
      result += `="${escape(value)}"`;
    }
  }

  return result;
}

/**
 * Parse an HTML open tag with its attributes.
 */
export function parseHtmlOpenTag(text: string): { tag: string; attrs: [string, string][] } | null {
  const openTag = text.match(HTML_OPEN_TAG_RE);
  if (openTag == null) {
    return null;
  }

  const tag = openTag[1];
  const attrs = parseHtmlAttrs(openTag[2]);

  return {
    tag,
    attrs,
  };
}

/**
 * Parse an HTML close tag.
 */
export function parseHtmlCloseTag(text: string): { tag: string } | null {
  const closeTag = text.match(HTML_CLOSE_TAG_RE);
  if (closeTag == null) {
    return null;
  }

  const tag = closeTag[1];

  return {
    tag,
  };
}
