import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export function useRouteData() {
  const store = useStore();
  const route = useRoute();
  const { workspaceId, repoId, branch, unitId, playlistId } = route.params;

  const repoName = computed(() => store.getters['database/db_getRepoName'](repoId));
  const playlist = computed(() => store.getters['database/db_getPlaylist'](repoId, playlistId));
  const workspaceName = computed(() => store.getters['database/db_getWorkspaceName'](workspaceId));

  return {
    topRouteName: route.matched[0]?.name,
    routeName: route.name,
    ...(workspaceId && {
      workspaceId,
      workspaceName: workspaceName.value || '',
    }),
    ...(repoId && {
      repoId,
      repoName: repoName.value || '',
    }),
    ...(branch && { branch }),
    ...(unitId && { unitId }),
    ...(playlistId && {
      playlistId,
      playlistName: playlist.value?.name || '',
    }),
  };
}
