<template>
  <div class="integration">
    <div class="leftContainer">
      <Icon v-if="icon" no-padding class="appIcon" :name="icon" />
      <img v-else-if="imageName" :src="getImageUrl(imageName)" class="appImg" />
      <div class="textSection">
        <div class="appTitle">{{ title }}</div>
        <div class="appText">{{ description }}</div>
      </div>
    </div>
    <VTooltip popper-class="tooltip-on-modal" :disabled="!tooltip" placement="top">
      <a :href="buttonUrl" target="_blank" class="buttonUrl">
        <Action class="secondary button" :variant="buttonVariant" :disabled="disabled" @click="onInstallClick">
          <Icon v-if="isInstalled" no-padding class="buttonIcon" name="check" />
          {{ buttonString }}
        </Action>
      </a>
      <template #popper>
        <div class="actionPopup">
          <div class="body-XS">
            {{ tooltip }}
          </div>
        </div>
      </template>
    </VTooltip>
  </div>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: undefined },
    imageName: { type: String, default: undefined },
    title: { type: String, required: true },
    description: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    buttonTitleAppNotInstalled: { type: String, required: true },
    installedButtonUrl: { type: String, default: '' },
    notInstalledButtonUrl: { type: String, default: '' },
    isInstalled: { type: Boolean, default: false },
    tooltip: { type: String, default: '' },
  },
  emits: ['install-click'],
  computed: {
    buttonString() {
      return this.isInstalled ? 'Installed' : this.buttonTitleAppNotInstalled;
    },
    buttonVariant() {
      return this.isInstalled ? 'success' : null;
    },
    buttonUrl() {
      return this.isInstalled ? this.installedButtonUrl : this.notInstalledButtonUrl;
    },
  },
  methods: {
    onInstallClick() {
      !this.isInstalled && this.$emit('install-click');
    },
    getImageUrl(name) {
      return new URL(`../assets/${name}.png`, import.meta.url).href;
    },
  },
};
</script>

<style scoped>
.integration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

.leftContainer {
  display: flex;
  align-items: center;
}

.appIcon {
  font-size: var(--fontsize-xxl);
}

.appImg {
  height: 48px;
}

.textSection {
  margin: 0 24px;
}

.appTitle {
  margin-bottom: 4px;
  font-size: var(--subtitle-XL);
  font-weight: 800;
  color: var(--text-color-primary);
}

.appText {
  font-size: var(--body-S);
  color: var(--text-color-secondary);
}

.button {
  width: 148px;
}

.buttonUrl {
  flex-shrink: 0;
}

.buttonIcon {
  margin-right: 6px;
}

.actionPopup {
  width: 210px;
  padding: 8px;
}
</style>
