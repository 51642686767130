<template>
  <InstanceNotification :action="action" :after-action="afterAction" :title="title" :notification="notification" />
</template>

<script>
import InstanceNotification from '@/modules/notifications-center/components/InstanceNotification.vue';
export default {
  components: { InstanceNotification },
  props: {
    notification: { type: Object, default: () => ({}) },
  },
  computed: {
    action() {
      return ' Connect your account to Slack';
    },
    afterAction() {
      return ' to set up personalized notifications.';
    },
    title() {
      return 'Swimm’s Slack app is now available.';
    },
  },
};
</script>
