import { isSwmDoc, isSwmExercise } from '@swimm/shared';
import { sortByProp } from '@/common/utils/helpers';
import { encodeString } from '@swimm/shared';
import { REPO_FILTERS_SORT_OPTIONS_PROPS } from './common-definitions';
import { PlaylistSequenceStepTypes } from '@/modules/playlists/types';
import type { Playlist } from '@/modules/playlists/types';

export function getPlaylistsSorted({ playlistsValues, sortByProperty }) {
  let sortedPlaylists = playlistsValues;

  if (sortByProperty) {
    // Sorting playlists by users selected prop
    const order = sortByProperty === REPO_FILTERS_SORT_OPTIONS_PROPS.Name ? 'asc' : 'desc';
    sortedPlaylists = sortByProp(playlistsValues, sortByProperty, order);
  }

  return sortedPlaylists;
}

export const ResourceHeadlineTypeEnum = {
  doc: 'DOC',
  exercise: 'EXERCISE',
  link: 'LINK',
  playlist: 'PLAYLIST',
  intro: 'INTRO',
  summary: 'SUMMARY',
} as const;

export function getResourceHeadlineType({
  isSwimmStep,
  activeStep,
  isSummary,
}): '' | (typeof ResourceHeadlineTypeEnum)[keyof typeof ResourceHeadlineTypeEnum] {
  if (isSwimmStep) {
    const type = activeStep.type;
    if (type === PlaylistSequenceStepTypes.EXTERNAL_LINK) {
      return ResourceHeadlineTypeEnum.link;
    }
    if (type === PlaylistSequenceStepTypes.PLAYLIST) {
      return ResourceHeadlineTypeEnum.playlist;
    }
    if (isSwmDoc({ swm: activeStep })) {
      return ResourceHeadlineTypeEnum.doc;
    }
    if (isSwmExercise({ swm: activeStep })) {
      return ResourceHeadlineTypeEnum.exercise;
    }

    return '';
  } else {
    return isSummary ? ResourceHeadlineTypeEnum.summary : ResourceHeadlineTypeEnum.intro;
  }
}

export function parsePlaylistContentFromSwm(playlistSwm, repoId) {
  const playlist: Playlist = {
    id: playlistSwm.id,
    name: playlistSwm.name,
    sequence: [],
  };
  let inIntro = false;
  let inSummary = false;
  playlistSwm.content.forEach((textBlock) => {
    const text = textBlock.text;
    if (text.startsWith(PLAYLIST_INTRO_COMMENT)) {
      playlist.description = text.replace(PLAYLIST_INTRO_COMMENT + '\n', '');
      inIntro = true;
      inSummary = false;
    } else if (text.startsWith(PLAYLIST_SUMMARY_COMMENT)) {
      inIntro = false;
      inSummary = true;
      playlist.summary = text.replace(PLAYLIST_SUMMARY_COMMENT + '\n', '');
    } else if (text.includes(PLAYLIST_STEPS_COMMENT)) {
      inIntro = false;
      inSummary = false;
      const playlistStepsCommentEndIndex = text.indexOf(PLAYLIST_STEPS_COMMENT) + PLAYLIST_STEPS_COMMENT.length;

      const steps = text
        .substring(playlistStepsCommentEndIndex)
        .split('\n')
        .filter((step) => !!step.trim())
        .map((step) => {
          if (step.includes('[[sym-link:')) {
            const swimmLink = step.match(/\[\[sym-link:(.*?)\]\]/g)[0];
            const strippedSwimmLink = swimmLink.replace('[[sym-link:(', '').replace(']]', '');
            const swimmLinkParts = strippedSwimmLink.split(')');
            const symbolId = swimmLinkParts[0];
            const name = swimmLinkParts[1];
            return {
              id: playlistSwm.symbols[symbolId].swimmId,
              repoId: playlistSwm.symbols[symbolId].repoId,
              name,
              type:
                playlistSwm.symbols[symbolId].swimmType === PlaylistSequenceStepTypes.PLAYLIST
                  ? PlaylistSequenceStepTypes.PLAYLIST
                  : PlaylistSequenceStepTypes.SWIMM,
            };
          } else {
            const markdownLinkRegex = /\[(.+)\]\((.+)\)/;
            const [, text, url] = step.match(markdownLinkRegex);

            const linkStep = {
              name: text,
              url,
            };

            return {
              id: encodeString(linkStep.url),
              url: linkStep.url,
              name: linkStep.name,
              type: PlaylistSequenceStepTypes.EXTERNAL_LINK,
              repoId,
            };
          }
        });

      playlist.sequence = steps;
    } else {
      // check if intro or summary were splitted to several cells
      // then join them back
      if (inIntro) {
        playlist.description += text + '\n';
      } else if (inSummary) {
        playlist.summary += text + '\n';
      }
    }
  });
  if (!playlist.sequence) {
    playlist.sequence = [];
  }
  return playlist;
}

export const PLAYLIST_INTRO_COMMENT = '<!-- Intro - Do not remove this comment -->';
export const PLAYLIST_SUMMARY_COMMENT = '<!-- Summary - Do not remove this comment -->';
export const PLAYLIST_STEPS_COMMENT = '<!-- Steps - Do not remove this comment -->';
