import { pageEvents } from '@swimm/shared';
import { isProduction } from '@/config';

export interface OnboardingStep {
  title: string;
  initialVideoIndex: number;
  buttons: string[];
}

export const OnboardingSteps: OnboardingStep[] = [
  {
    title: 'Learn Swimm in 1 min',
    initialVideoIndex: 0,
    buttons: ['Play'],
  },
  {
    title: 'Reference real code from your repo, powered by AI ✨',
    initialVideoIndex: 1,
    buttons: ['👉🏽 Create an AI-assisted doc', 'Now, find it in your IDE!'],
  },
  {
    title: 'Fix at ease when your code evolves',
    initialVideoIndex: 4,
    buttons: ['👉🏽 Break the doc', 'Now, fix it'],
  },
  {
    title: 'Convert your pull requests into code-coupled docs in an instant',
    initialVideoIndex: 7,
    buttons: ['👉🏽 Create a PR', 'Now, generate a doc with AI 🪄'],
  },
];

export const GOOGLE_STORAGE_BASE_URL = 'https://storage.googleapis.com';
export const WELCOME_VIDEO_ANALYTICS_STEP_NAME = 'Swimm Welcome Video';
export const ONBOARDING_VIDEOS_BASE_BUCKET_URL = isProduction
  ? GOOGLE_STORAGE_BASE_URL + '/swimm-onboarding-content'
  : GOOGLE_STORAGE_BASE_URL + '/swimm-onboarding-assets-staging';

export const WELCOME_VIDEO_FILE_NAME = 'meet-swimm.mp4';

export enum OnboardingOrigin {
  IDE = 'ide',
  WEBAPP = 'webapp',
}

export enum ProductTourOrigin {
  ONBOARDING = 'onboarding',
  WORKSPACE = 'workspace',
}

export const ONBOARDING_STEP_NAMES = {
  CREATE_WORKSPACE: 'Create workspace',
  EXPLORE_SWIMM: 'Explore Swimm',
  REPO: 'Connect a Repo',
  READY_TO_JUMP: 'Ready to Jump',
};

export const ONBOARDING_STEPS = {
  CREATE_WORKSPACE: {
    key: 'CREATE_WORKSPACE',
    name: ONBOARDING_STEP_NAMES.CREATE_WORKSPACE,
    eventName: pageEvents.VIEW_TUNNEL_ONBOARDING_CREATE_WORKSPACE,
  },
  EXPLORE_SWIMM: {
    key: 'EXPLORE_SWIMM',
    name: ONBOARDING_STEP_NAMES.EXPLORE_SWIMM,
    eventName: pageEvents.VIEW_TUNNEL_ONBOARDING_EXPLORE_SWIMM,
  },
  REPO: { key: 'REPO', name: ONBOARDING_STEP_NAMES.REPO, eventName: pageEvents.VIEW_TUNNEL_ONBOARDING_CONNECT_REPO },
  READY_TO_JUMP: {
    key: 'READY_TO_JUMP',
    name: ONBOARDING_STEP_NAMES.READY_TO_JUMP,
    eventName: pageEvents.VIEW_TUNNEL_ONBOARDING_FINISH,
  },
};

export const ONBOARDING_STEPS_LIST = [
  ONBOARDING_STEPS.CREATE_WORKSPACE,
  ONBOARDING_STEPS.EXPLORE_SWIMM,
  ONBOARDING_STEPS.REPO,
  ONBOARDING_STEPS.READY_TO_JUMP,
];

export enum BackgroundTheme {
  WAVE = 'wave',
  BLURRED_APP = 'blurred_app',
}
