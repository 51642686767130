<template>
  <textarea
    ref="textarea"
    class="field-input"
    :class="{ headline: headline, small }"
    :readonly="readonly"
    :value="modelValue"
    :placeholder="placeholder"
    @input="$emit('update:model-value', ($event.target as HTMLInputElement).value)"
    @keydown="$emit('keydown', $event)"
  ></textarea>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue';

const textarea = ref<HTMLElement>(null);

const props = defineProps({
  modelValue: { type: String, required: true },
  placeholder: { type: String, default: '' },
  readonly: { type: Boolean, default: false },
  headline: { type: Boolean, default: false },
  focusFirst: { type: Boolean, required: false },
  small: { type: Boolean, default: false },
});
defineEmits(['update:model-value', 'keydown']);

onMounted(() => {
  if (props.focusFirst) {
    nextTick(() => textarea.value?.focus());
  }
});
</script>

<style scoped>
textarea {
  padding: 10px 12px;
  width: 100%;
  height: 10rem;
  font-size: var(--body-L);
  font-family: var(--fontfamily-main);
  border: 1px solid var(--color-border-default);
  border-radius: 4px;
  text-align: left;
  color: var(--text-color-primary);
  background-color: var(--color-bg);
  outline: none;
  resize: none;
  box-sizing: border-box;
}

textarea.small {
  height: 5rem;
}

textarea[readonly] {
  color: var(--text-color-disable);
}

textarea::placeholder {
  font-style: normal;
  color: var(--text-color-primary);
  opacity: 0.3;
}

textarea:focus {
  border-color: var(--color-border-brand);
}
</style>
