<script setup lang="ts">
import { type Ref, ref, watch } from 'vue';
import { SwText } from '@swimm/ui';
import { useRepoIntegrations } from '@/modules/repo/composables/repo-integrations';
import { type RadioBoxOption, RadioBoxes } from '@swimm/ui';

const props = defineProps<{
  repoId: string;
}>();

const { integrations, updateIntegrations } = useRepoIntegrations(props.repoId);
const selectedStorage: Ref<'repo' | 'cloud'> = ref(integrations.value.commit_images_to_repo ? 'repo' : 'cloud');
const updating = ref(false);

watch(
  () => integrations.value.commit_images_to_repo,
  (v) => {
    selectedStorage.value = v ? 'repo' : 'cloud';
  }
);

watch(
  () => selectedStorage.value,
  async (v) => {
    if (v !== integrations.value.commit_images_to_repo) {
      updating.value = true;
      await updateIntegrations({ commit_images_to_repo: v === 'repo' });
      updating.value = false;
    }
  }
);

const options = ref<RadioBoxOption[]>([
  {
    value: 'repo',
    title: 'Repository',
    description: 'More secure but may take up Git storage space and increase repo size.',
    recommended: true,
  },
  {
    value: 'cloud',
    title: 'Cloud server',
    description: "Images will be stored in Swimm's cloud servers.",
  },
]);
</script>

<template>
  <div class="image-storage-settings" :class="{ disabled: updating }">
    <SwText variant="headline2" class="title"><strong>Image storage</strong></SwText>
    <SwText variant="body-L">Images uploaded to docs will be stored in:</SwText>
    <RadioBoxes :options="options" :disabled="updating" v-model="selectedStorage" />
  </div>
</template>

<style scoped lang="postcss">
.image-storage-settings {
  &.disabled {
    opacity: 0.4;
  }

  .title {
    margin-bottom: var(--space-lg);
  }
}
</style>
