import { computed, onMounted, onUnmounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useHelpTooltipsStore } from '@/modules/core/stores/useHelpTooltipsStore';
import LocalStorage from '@/local-storage';
import { productEvents } from '@swimm/shared';
import { useAuthStore } from '../stores/auth-store';
import { useUserConfigStore } from '../stores/user-config-store';

const { VIEW_HOTSPOT, OPENED_TOOLTIP, CLOSED_TOOLTIP } = productEvents;
const HELP_TOOLTIPS_SHOWN_TO_ANONYMOUS_USER = 'help-tooltips-shown-to-anonymous-user';

export function useHelpTooltips({ title, tooltipKey, priority, showNewUsersOnly, showHotSpotOnceForUser, type }) {
  const analytics = useAnalytics();
  const helpTooltipsStore = useHelpTooltipsStore();
  const { user } = storeToRefs(useAuthStore());
  const userId = computed(() => user.value.uid);
  const { currentlyDisplayedTooltip } = storeToRefs(helpTooltipsStore);
  const userConfigStore = useUserConfigStore();
  const { shownHelpTooltips, shownExistingUsersHelpTooltips } = storeToRefs(userConfigStore);
  const { setShownHelpTooltips, setShownExistingUsersHelpTooltips } = userConfigStore;
  const { addTooltipToList, removeTooltipFromTooltipsOnTheScreen, resetActiveTooltipOnScreen } = helpTooltipsStore;
  const isAnonymousUser = computed(() => Boolean(!userId.value));
  const isVisible = computed(() => tooltipKey.value === currentlyDisplayedTooltip.value);

  const ANALYTICS_PAYLOAD = { name: title.value, type };

  function onHotspotClicked() {
    markTooltipShown();
    analytics.track(OPENED_TOOLTIP, ANALYTICS_PAYLOAD);
  }

  function onTooltipClosed() {
    resetActiveTooltipOnScreen();
    analytics.track(CLOSED_TOOLTIP, ANALYTICS_PAYLOAD);
  }

  function getShownTooltipsToAnonymousUser() {
    const tooltipsShown = LocalStorage.get(HELP_TOOLTIPS_SHOWN_TO_ANONYMOUS_USER);
    return tooltipsShown ? new Set(Object.keys(tooltipsShown)) : new Set();
  }

  function setShownTooltipForAnonymousUser() {
    const oldContent = LocalStorage.get(HELP_TOOLTIPS_SHOWN_TO_ANONYMOUS_USER);

    LocalStorage.set(HELP_TOOLTIPS_SHOWN_TO_ANONYMOUS_USER, { ...oldContent, [tooltipKey.value]: true });
  }

  function markTooltipShown() {
    if (isAnonymousUser.value) {
      setShownTooltipForAnonymousUser();
    } else {
      if (showNewUsersOnly.value) {
        setShownHelpTooltips({ tooltipKey: tooltipKey.value, userId: userId.value });
      } else {
        setShownExistingUsersHelpTooltips({ tooltipKey: tooltipKey.value, userId: userId.value });
      }
    }
  }

  function getRelevantShownTooltipsSet() {
    if (isAnonymousUser.value) {
      return getShownTooltipsToAnonymousUser();
    }
    if (showNewUsersOnly.value) {
      return shownHelpTooltips.value;
    }

    return shownExistingUsersHelpTooltips.value;
  }

  watch(isVisible, (newValue, oldValue) => {
    if (newValue && !oldValue) {
      analytics.track(VIEW_HOTSPOT, ANALYTICS_PAYLOAD);
      if (showHotSpotOnceForUser.value) {
        markTooltipShown();
      }
    }
  });

  onMounted(() => {
    const newUserShownHelpTooltips = shownHelpTooltips.value;
    const shownHelpTooltipsRelevantSet = getRelevantShownTooltipsSet();
    const isNewUser = Boolean(newUserShownHelpTooltips);

    if (!isNewUser && showNewUsersOnly.value) {
      return;
    }

    const canBeShown = !shownHelpTooltipsRelevantSet.has(tooltipKey.value);
    if (canBeShown) {
      addTooltipToList({ tooltipKey: tooltipKey.value, priority: priority.value });
    }
  });

  onUnmounted(() => {
    removeTooltipFromTooltipsOnTheScreen(tooltipKey.value);
  });

  return { isVisible, onHotspotClicked, onTooltipClosed };
}
