import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  ApplicabilityStatus,
  type AutosyncOutput,
  type Repo,
  config,
  getLoggerNew,
  parseSwmFilename,
} from '@swimm/shared';
import { verifyDocWorker } from '@/workers';
import { useDocsStore } from '@/modules/drafts3/stores/docs';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { convertSwimmDocumentToAutosyncInput, parseSwmd } from '@swimm/swmd';
import { useFolderTreeStore } from './folder-tree-store';

export interface SwimmDocumentContentEntry {
  id: string;
  name?: string;
  applicability?: ApplicabilityStatus;
  output?: AutosyncOutput;
}

const logger = getLoggerNew(__modulename);

export const useDocsContentStore = defineStore('docs-content', () => {
  const store = useStore();
  const { docs } = storeToRefs(useDocsStore());
  const { repos } = storeToRefs(useReposStore());
  const { getAllSwmContent } = useFolderTreeStore();
  const workspaceId = ref<string>();
  const repoId = ref<string>();
  const branch = ref<string>();
  const docsContent = ref<Record<string, SwimmDocumentContentEntry>>({});

  const db_getWorkspaceRepos = computed(() => store.getters['database/db_getWorkspaceRepoIds'](workspaceId.value));

  const reposInWorkspace = computed(() =>
    repos.value.filter((repo) => db_getWorkspaceRepos.value.some((id) => id === repo.id))
  );

  async function fetchDocsContent(newWorkspaceId: string, newRepoId: string, newBranch: string) {
    if (newWorkspaceId === workspaceId.value && newRepoId === repoId.value && newBranch === branch.value) {
      return;
    }
    workspaceId.value = newWorkspaceId;
    repoId.value = newRepoId;
    branch.value = newBranch;
    docsContent.value = {};

    if (!docs.value) {
      return;
    }

    const repo = repos.value.find((repo) => repo.id === newRepoId);
    if (!repo) {
      logger.error(`Failed fetching docs content for repo ${newRepoId}: repo is not in the state.`);
      return;
    }

    const allContentInSwmFolder = await getAllSwmContent({
      branch: newBranch,
      treeRequestMetadata: {
        repoId: newRepoId,
        workspaceId: newWorkspaceId,
        cloneUrl: store.getters['database/db_getRepoMetadata'](newRepoId)?.url,
        localAuthEndpoints: store.getters['database/db_getWorkspace'](newWorkspaceId)?.settings?.local_auth_endpoints,
      },
    });
    const docIdToContent = {};
    allContentInSwmFolder.forEach((item) => {
      if (item.path.endsWith(config.SWMD_FILE_EXTENSION) && !item.path.endsWith(config.SWIMM_FILE_TYPES.PLAYLIST)) {
        const parsedSwmFileName = parseSwmFilename(item.path);
        if (parsedSwmFileName?.swmId) {
          docIdToContent[parsedSwmFileName.swmId] = item.content;
        } else {
          logger.error(
            `Failed to parse an unsupported file in the ${config.SWM_FOLDER_IN_REPO} folder for the repo: ${repoId.value}.`
          );
        }
      }
    });

    const docIds = Object.keys(docs.value);
    Promise.allSettled(
      docIds.map(async (docId) => {
        return doVerifyDoc({
          docId,
          docIdToContent,
          currentRepo: repo,
          currentworkspaceId: newWorkspaceId,
          currentBranch: newBranch,
        });
      })
    );
  }

  async function doVerifyDoc({
    docId,
    docIdToContent,
    currentRepo,
    currentworkspaceId,
    currentBranch,
  }: {
    docId: string;
    docIdToContent: { [key: string]: string };
    currentRepo: Repo;
    currentworkspaceId: string;
    currentBranch: string;
  }) {
    try {
      if (docsContent.value[docId]) {
        return;
      }

      if (!docIdToContent[docId]) {
        docsContent.value[docId] = {
          id: docId,
          applicability: ApplicabilityStatus.Unavailable,
        };
        return;
      }

      const swimmDocument = parseSwmd(docIdToContent[docId], {
        legacy: {
          baseUrl: config.BASE_URL,
          workspaceId: currentworkspaceId,
          repoId: currentRepo.id,
          repoName: currentRepo.name,
          repos: reposInWorkspace.value,
        },
      });

      docsContent.value[docId] = {
        id: docId,
        name: swimmDocument.title,
      };

      const autosyncInput = convertSwimmDocumentToAutosyncInput(
        swimmDocument,
        currentRepo.id,
        currentBranch,
        reposInWorkspace.value
      );

      const result = await verifyDocWorker?.verifyDoc({
        id: docId,
        repoId: currentRepo.id,
        workspaceId: currentworkspaceId,
        branch: currentBranch,
        autosyncInput,
      });

      docsContent.value[result.id].applicability = result.applicabilityStatus;
      docsContent.value[result.id].output = result.output;
    } catch (err) {
      logger.error(
        { err },
        `failed to verify doc content: ${docId} on branch: ${currentRepo} Details: ${err.toString()}`
      );
      docsContent.value[docId] = {
        id: docId,
        applicability: ApplicabilityStatus.Invalid,
      };
    }
  }

  function updateDocsContent(docsIdName: Record<string, string>) {
    for (const docId of Object.keys(docsIdName)) {
      if (!docsContent.value[docId]) {
        docsContent.value[docId] = {
          id: docId,
        };
      }
      docsContent.value[docId].name = docsIdName[docId];
      docsContent.value[docId].applicability = ApplicabilityStatus.Verified;
    }
  }

  return {
    fetchDocsContent,
    docsContent,
    updateDocsContent,
  };
});
