import he from 'he';
import { marked as markedPackage } from 'marked';
import { encoder } from 'node-esapi';
import { sanitizeUrl } from '@braintree/sanitize-url';

import { config, getLogger } from '@swimm/shared';

const logger = getLogger(__modulename);

interface MarkedOptions extends markedPackage.MarkedOptions {
  spaces?: boolean;
  showImageText?: boolean;
}

function replaceHtmlImageWithGracefulFail(text: string): string {
  return text.replace(/<img[^>]+src="([^">]+)"[^>]+>/g, '{ Failed to load "$1" }');
}

export class MarkdownUtils {
  static marked(data: string, repoId: string | undefined) {
    const basePathToReplace = `REPLACE_BASE_RELATIVE_PATH/`;
    markedPackage.setOptions({
      // Put a replaceable string before all relative paths, the 'baseUrl' option must end with '/' so we can't set the original path value as query param otherwise.
      baseUrl: basePathToReplace,
    });
    const customRenderer = new markedPackage.Renderer();

    customRenderer.image = function (href, title, text) {
      const serializedText = encoder().encodeForHTMLAttribute(text);
      const serializedHref = sanitizeUrl(href ?? undefined);
      return `<div align="center"><img alt="${serializedText}" src="${serializedHref}" style={{ width:'50%' }}/></div>`;
    };

    customRenderer.html = function (html) {
      // Before the introduction of 'node-esapi', we used the original implementation of 'marked' lib to render HTML.
      // That implementation filters out HTML comments. Since we now use 'node-esapi' to render HTML securely, and since
      // this implementation does not filter out HTML comments, we need to filter them out manually here.
      // See discussion: https://github.com/swimmio/swimm/pull/6640#discussion_r769889489
      html = html.replace(/<!--.+?-->/g, '');
      if (!html.trim()) {
        return '';
      }

      // We encode empty paragraphs like so:
      // Initial HTML: ----
      // <p>aaaa</p>
      // <p></p>
      // <p>bbbb</p>
      // <p></p>
      // ------------------
      // Markdown: --------
      // aaaa
      //
      //
      // <br/>
      //
      //
      // aaaa
      //
      // <br/>
      // ------------------
      if (/^<br\/>\n*$/.test(html)) {
        return '<p></p>\n';
      }

      // We use this magic only to make sure encodeForHTML doesn't escape our line breaks into '&lt;br/&gt;' - we want
      // to keep them as HTML elements so that we get actual line breaks displayed.
      // We replace this magic right after, so it never leaves this function.
      const newlineMagic = 'NEWLINE9f68e85ef41f03602e5be0a0cf632292';
      html = html.replaceAll('<br/>', newlineMagic);

      // We don't support <img> as part of text, replace it with failure message.
      html = replaceHtmlImageWithGracefulFail(html);

      const serializedHtml = encoder().encodeForHTML(html);
      return serializedHtml.replaceAll(newlineMagic, '<br/>');
    };

    customRenderer.paragraph = function (text: string) {
      // WORKAROUND:
      // If we have '<p>aaaaa<br/>bbbb</p>', then the html renderer above will be called for the <br/>, and replace it
      // to '<p></p>\n' because it thinks it's an empty paragraph, and then we'll get to this function with
      // text='aaaa<p></p>\nbbbb'. so here we revert the empty paragraph replacement and restore the original <br/>...
      // Hacky, I know, but that's what we have to work with here since marked won't tell us in the html renderer
      // whether it's giving us a <br/> inside a paragraph or just on its own.
      return `<p>${text.replaceAll('<p></p>\n', '<br/>\n')}</p>\n`;
    };

    customRenderer.link = function (href, _, text) {
      // Text placeholders string will look like: `[${placeholder-text}](#text-placeholder-id-${id})`
      // In this function we receive the href and text of the text placeholder separately
      const textPlaceholderRegex = new RegExp('#text-placeholder-id-(?<id>.*)', 'g');
      const foundTextPlaceholder = textPlaceholderRegex.exec(href ?? '');
      if (foundTextPlaceholder && foundTextPlaceholder.length > 0) {
        // TODO: Make sure this change is right with "!"
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const serializedPlaceholderId = encoder().encodeForHTMLAttribute(foundTextPlaceholder.groups!.id);
        text = MarkdownUtils.htmlDecode(text);
        const serializedPlaceholderText = encoder().encodeForHTMLAttribute(text);
        return `<input text-placeholder-id="${serializedPlaceholderId}" :placeholder="${serializedPlaceholderText}" />`;
      }
      // All the < > characters should be converted to &lt &gt instead, but we still make sure none remain as they might create unwanted tags.
      // Later on, the conversion of the HTML to JSON removes most tags and so keep us safe, except for <a href>, so we have the following sanitiziations.
      const tagsRemovedText = text.replace('<', '').replace('>', '');
      const sanitizedHref = sanitizeUrl(href ?? undefined);
      return `<a href="${sanitizedHref}" target="_blank" rel="noopener noreferrer nofollow">${tagsRemovedText}</a>`;
    };

    /**
     * @param {string} src input text
     */
    customRenderer.text = function (src) {
      // Path symbol string will look like: "[[sym:src/this/is/path.js(this-is-symbol-id)]]"
      const foundSymbols = src.match(/\[\[sym:(.*?)\]\]/g);
      if (foundSymbols && foundSymbols.length > 0) {
        for (const symbol of foundSymbols) {
          const stripedSymbol = symbol.replace('[[sym:', '').replace(')]]', '');
          const symbolParts = stripedSymbol.split('(');
          const originalvalue = symbolParts[0];
          const symbolId = symbolParts[1];
          const serializedSymbolId = encoder().encodeForHTMLAttribute(symbolId);
          const serializedValue = encoder().encodeForHTML(originalvalue);
          const value = `<span sym-path-id="${serializedSymbolId}">${serializedValue}</span>`;
          src = src.replace(symbol, value);
        }
      }
      // Generic text symbol string will look like: "[[sym-text:something(this-is-symbol-id)]]"
      const foundTextSymbols = src.match(/\[\[sym-text:(.*?)\]\]/g);
      if (foundTextSymbols && foundTextSymbols.length > 0) {
        for (const textSymbol of foundTextSymbols) {
          const stripedTextSymbol = textSymbol.replace('[[sym-text:', '').replace(/\)\]\]$/, '');
          const textSymbolIdIndex = stripedTextSymbol.lastIndexOf('(');
          const originalText = stripedTextSymbol.substring(0, textSymbolIdIndex);
          const textSymbolId = stripedTextSymbol.substring(textSymbolIdIndex + 1, stripedTextSymbol.length);
          const serializedSymbolId = encoder().encodeForHTMLAttribute(textSymbolId);
          const serializedValue = encoder().encodeForHTML(originalText);
          const textValue = `<span sym-text-id="${serializedSymbolId}">${serializedValue}</span>`;
          src = src.replace(textSymbol, textValue);
        }
      }

      // Mentions will look like: `[[sym-mention:(${symbolId}|${userId})${userName}]]`
      const foundMentionSymbols = src.matchAll(/\[\[sym-mention:\((?<symId>\S+?)\|(?<userId>\S+?)\)(?<name>.+?)\]\]/gm);
      for (const matchResult of foundMentionSymbols) {
        // TODO: Make sure this change is right with "!"
        const symId = matchResult.groups!.symId; // eslint-disable-line @typescript-eslint/no-non-null-assertion
        const userId = matchResult.groups!.userId; // eslint-disable-line @typescript-eslint/no-non-null-assertion
        const name = matchResult.groups!.name; // eslint-disable-line @typescript-eslint/no-non-null-assertion
        const serializedSymId = encoder().encodeForHTMLAttribute(symId);
        const serializedUserId = encoder().encodeForHTMLAttribute(userId);
        const serializedName = encoder().encodeForHTML(name);
        const textValue = `<span sym-mention-id="${serializedSymId}" external-uid="${serializedUserId}">${serializedName}</span>`;
        src = src.replace(matchResult[0], textValue);
      }

      // Swimm links string will look like: `[[sym-link:(${swimmId})${name}]]`
      const foundSwimmLinks = src.match(/\[\[sym-link:(.*?)\]\]/g);
      if (foundSwimmLinks && foundSwimmLinks.length > 0) {
        for (const swimmLink of foundSwimmLinks) {
          const stripedSwimmLink = swimmLink.replace('[[sym-link:(', '').replace(']]', '');
          const swimmLinkParts = stripedSwimmLink.split(')');
          const swimmId = swimmLinkParts[0];
          const originalSwimmName = stripedSwimmLink.slice(swimmId.length + 1);
          const serializedSwimmId = encoder().encodeForHTMLAttribute(swimmId);
          const serializedSwimmName = encoder().encodeForHTML(originalSwimmName);
          const textValue = `<span sym-link-id="${serializedSwimmId}">${serializedSwimmName}</span>`;
          src = src.replace(swimmLink, textValue);
        }
      }

      return src;
    };

    customRenderer.code = function (code, infostring) {
      let encodedCode = encoder().encodeForHTML(code);
      if (!encodedCode) {
        encodedCode = '';
      }
      if (infostring) {
        return `<pre><code class="code-span" language="${infostring}">${encodedCode}</code></pre>`;
      }

      return `<pre><code class="code-span">${encodedCode}</code></pre>`;
    };

    customRenderer.codespan = function (text) {
      return `<code class="code-span">${text}</code>`;
    };

    customRenderer.listitem = function (text) {
      // We don't support images inside lists, replace it with failure message.
      return `<li>${replaceHtmlImageWithGracefulFail(text)}</li>\n`;
    };

    customRenderer.blockquote = function (quote) {
      // We don't support images inside blockquote, replace it with failure message.
      return `<blockquote>\n${replaceHtmlImageWithGracefulFail(quote)}</blockquote>\n`;
    };

    customRenderer.tablerow = function (content) {
      // We don't support images inside tables, replace it with failure message.
      return `<tr>\n${replaceHtmlImageWithGracefulFail(content)}</tr>\n`;
    };

    let result = markedPackage(data || '', { renderer: customRenderer });
    if (repoId) {
      // Open relative file paths in a new electron window and send the original relative path as query param
      result = result
        .split(` href="${basePathToReplace}`)
        .join(` href="${config.OPEN_NEW_SWIMM_WINDOW_PREFIX}repos/${repoId}/file?filePath=`);
    }
    // TODO: support relative path images
    // for now keep the images with the original src value
    return result.split(` src="${basePathToReplace}`).join(` src="`);
  }
  static markedPlainText(data: string) {
    try {
      const plainTextRendererClass = MarkdownUtils.plainTextRendererClass();
      const plainTextRenderer = new plainTextRendererClass();
      const plainText = markedPackage(data || '', { renderer: plainTextRenderer });
      // We use htmlDecode to decode special characters such as "&amp;" (should be rendered "&")
      return MarkdownUtils.htmlDecode(plainText);
    } catch (e) {
      logger.error(`The marked data could not be rendered: ${e}`, { service: 'markdown' });
      return data;
    }
  }

  static plainTextRendererClass() {
    // Copy-pasted from https://github.com/etler/marked-plaintext
    // see issue #1315 in github for more details
    class Renderer implements markedPackage.Renderer {
      options: MarkedOptions;
      whitespaceDelimiter: string;
      showImageText: boolean;

      constructor(options?: MarkedOptions) {
        this.options = options ?? {};
        this.whitespaceDelimiter = this.options.spaces ? ' ' : '\n';
        this.showImageText = typeof this.options !== 'undefined' ? !!this.options.showImageText : true;
      }

      code(code: string) {
        const serializedCode = encoder().encodeForHTML(code);
        return (
          this.whitespaceDelimiter +
          this.whitespaceDelimiter +
          serializedCode +
          this.whitespaceDelimiter +
          this.whitespaceDelimiter
        );
      }

      blockquote(quote: string) {
        const serializedQuote = encoder().encodeForHTML(quote);
        return '\t' + serializedQuote + this.whitespaceDelimiter;
      }

      html(html: string) {
        const serializedHtml = encoder().encodeForHTML(html);
        return serializedHtml;
      }

      heading(text: string) {
        const serializedText = encoder().encodeForHTML(text);
        return serializedText;
      }

      hr() {
        return this.whitespaceDelimiter + this.whitespaceDelimiter;
      }

      list(body: string) {
        const serializedList = encoder().encodeForHTML(body);
        return serializedList;
      }

      listitem(text: string) {
        const serializedText = encoder().encodeForHTML(text);
        return '\t' + serializedText + this.whitespaceDelimiter;
      }
      paragraph(text: string) {
        const serializedText = encoder().encodeForHTML(text);
        return this.whitespaceDelimiter + serializedText + this.whitespaceDelimiter;
      }
      table(header: string, body: string) {
        const serializedHeader = encoder().encodeForHTML(header);
        const serializedBody = encoder().encodeForHTML(body);
        return (
          this.whitespaceDelimiter +
          serializedHeader +
          this.whitespaceDelimiter +
          serializedBody +
          this.whitespaceDelimiter
        );
      }
      tablerow(content: string) {
        const serializedContent = encoder().encodeForHTML(content);
        return serializedContent + this.whitespaceDelimiter;
      }
      tablecell(content: string) {
        const serializedContent = encoder().encodeForHTML(content);
        return serializedContent + '\t';
      }
      strong(text: string) {
        const serializedText = encoder().encodeForHTML(text);
        return serializedText;
      }
      em(text: string) {
        const serializedText = encoder().encodeForHTML(text);
        return serializedText;
      }
      codespan(text: string) {
        const serializedText = encoder().encodeForHTML(text);
        return serializedText;
      }
      br() {
        return this.whitespaceDelimiter + this.whitespaceDelimiter;
      }
      del(text: string) {
        const serializedText = encoder().encodeForHTML(text);
        return serializedText;
      }
      link(_href: string, _title: string, text: string) {
        const serializedText = encoder().encodeForHTML(text);
        return serializedText;
      }
      image(_href: string, _title: string, text: string) {
        const serializedText = encoder().encodeForHTML(text);
        return this.showImageText ? serializedText : '';
      }
      text(text: string) {
        const serializedText = encoder().encodeForHTML(text);
        return serializedText;
      }
      checkbox(checked: boolean) {
        // TODO: verify why is this change (and encoding) needed
        const serializedText = encoder().encodeForHTML(`${checked}`);
        return serializedText;
      }
    }

    return Renderer;
  }
  static htmlDecode(html: string) {
    return he.unescape(html);
  }
}
