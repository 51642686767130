import { v4 as uuidv4 } from 'uuid';
import { getLoggerNew } from '@swimm/shared';
import type { ErrorWithCustomerMessage } from '@swimm/shared';
import type { FileToUpload, StorageWrapperInterface, UploadFilesParams } from './const';

const logger = getLoggerNew(__modulename);

export class FileUploadError extends Error implements ErrorWithCustomerMessage {
  customerMessage: string;

  constructor(message: string, customerMessage: string) {
    super(message);
    this.customerMessage = customerMessage;
  }
}

const maxImageSizeMB = 20;

const fileTypes = {
  MD: '.md',
};

export function getUploadDirectory(workspaceId: string, repoId?: string) {
  if (!workspaceId) {
    throw new Error('No workspaceId in route');
  }
  let url = `/workspaces/${workspaceId}`;
  if (repoId) {
    url = `/repositories/${repoId}`;
  }
  return url;
}

export async function uploadFiles({
  directory,
  files,
  success,
  failure,
  storageWrapper,
  autogenerateName = true,
}: UploadFilesParams) {
  if (!directory) {
    throw new Error('Must have directory, but got falsy value');
  }
  await Promise.all(
    files.map(async (targetFile) => {
      try {
        if (targetFile.type === fileTypes.MD) {
          const url = await uploadFile({ file: targetFile, directory, autogenerateName: true, storageWrapper });
          success(url);
        } else {
          const url = await uploadImage({
            file: targetFile,
            directory,
            autogenerateName: autogenerateName,
            storageWrapper,
          });
          success(url);
        }
      } catch (e) {
        failure(e as string);
      }
    })
  );
}

/**
 * Upload a file to Firestore.
 * @returns uploaded file URL.
 */
async function uploadFile({
  file,
  directory,
  autogenerateName = true,
  storageWrapper,
}: {
  file: FileToUpload;
  directory: string;
  autogenerateName?: boolean;
  storageWrapper: StorageWrapperInterface;
}): Promise<string> {
  if (!directory) {
    throw new Error('Must have directory, but got falsy value');
  }
  const filename = autogenerateName ? generateFilename(file) : file.name;
  const path = `${directory}/${filename}`;
  try {
    let url;
    if ('message' in file) {
      url = await storageWrapper.uploadString({ path, content: file.message });
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      url = await storageWrapper.uploadContent({ path, content: file.data, contentType: file.type });
    }
    return url;
  } catch (err) {
    logger.error({ err });
    throw new FileUploadError(err as string, 'Upload failed, please try again');
  }
}

function generateFilename(file: FileToUpload): string {
  const uuid = uuidv4();
  // Firebase storage upload fails for uppercase extensions(JPG instead of jpg)
  const ext = file.name.split('.').pop()?.toLowerCase() ?? '';
  return `${uuid}.${ext}`;
}

/**
 * Upload an image to Firestore.
 * @returns uploaded image URL.
 */
export async function uploadImage({
  file,
  directory,
  autogenerateName,
  storageWrapper,
}: {
  file: FileToUpload;
  directory: string;
  autogenerateName?: boolean;
  storageWrapper: StorageWrapperInterface;
}) {
  if (file.size > maxImageSizeMB * 1024 * 1024) {
    throw new FileUploadError('Image file is too big', `Image file must not exceed ${maxImageSizeMB} MB`);
  }

  if (
    !['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].includes(file.type) ||
    ['.htm', '.html', '.js', '.php', '.jsx', '.jsp', '.asp', '.aspx'].some((ending) => file.name.endsWith(ending))
  ) {
    throw new FileUploadError('Unsupported image file type', 'Unsupported image file type');
  }
  // Eran: when you get file.message the message disappears
  // but in uploadFile you check for message
  const fileToUpload: FileToUpload = {
    name: file.name,
    size: file.size,
    type: file.type,
    data: file,
  };

  // Force insert message to FileToUpload if exists
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (file['message']) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fileToUpload['message'] = file['message'];
  }

  return await uploadFile({ file: fileToUpload, directory, autogenerateName, storageWrapper });
}
