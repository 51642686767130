<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { BackgroundTheme } from '@/modules/onboarding/consts';
import BaseBackgroundLayout from '@/modules/onboarding/layouts/BaseBackgroundLayout.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/modules/core/stores/user';
import { Action, Loader, SwText } from '@swimm/ui';
import { MOBILE_MAX_WIDTH, PageRoutesNames } from '@/common/consts';
import { useRoute, useRouter } from 'vue-router';
import { useJoinWorkspace } from '@/modules/workspace/composables/joinWorkspace';
import { BaseLayoutGap } from '@swimm/reefui';

const route = useRoute();
const router = useRouter();
const { initializeJoinWorkspace, hasWorkspacesToJoin } = useJoinWorkspace();
const { displayName: userName } = storeToRefs(useUserStore());
const isMobile = screen.width <= MOBILE_MAX_WIDTH;
const loading = ref(true);

onMounted(async () => {
  await initializeJoinWorkspace();
  if (hasWorkspacesToJoin.value) {
    redirectToOnboarding();
  }
  loading.value = false;
});

function redirectToOnboarding() {
  router.push({ name: PageRoutesNames.ONBOARDING, query: route.query });
}

function openRequestDemoLink(): void {
  window.open('https://swimm.io/request-demo?referral__c=app_start', '_blank');
}
</script>

<template>
  <Loader v-if="loading" class="app-loader" />
  <BaseBackgroundLayout v-else :theme="BackgroundTheme.WAVE">
    <div class="container">
      <div>
        <SwText variant="headline1" class="title">Welcome, {{ userName }}!</SwText>
        <SwText variant="body-L" class="subtitle">Let's get started! Choose your path:</SwText>
      </div>
      <div class="content">
        <BaseLayoutGap :direction="isMobile ? 'column' : 'row'" size="large">
          <div class="card">
            <BaseLayoutGap direction="column" size="small">
              <BaseLayoutGap direction="column" size="xsmall">
                <img class="logo logo-swimm" src="@/assets/swimm-wave.svg" />
                <SwText variant="headline2" class="card-title">Swimm</SwText>
              </BaseLayoutGap>
              <SwText variant="body-L" class="description">
                Includes:
                <ul>
                  <li>Generate docs from PRs or code</li>
                  <li>Discover, update & create docs in the IDE</li>
                  <li>Keep docs up to date automatically</li>
                </ul>
              </SwText>
              <Action class="start-action" @click="redirectToOnboarding">Get started</Action>
            </BaseLayoutGap>
          </div>
          <div class="card">
            <BaseLayoutGap direction="column" alignment="left" size="small">
              <BaseLayoutGap direction="column" size="xsmall">
                <img class="logo logo-swimm" src="@/assets/ai-avatar-colored-square.svg" />
                <SwText variant="headline2" class="card-title">Swimm for Enterprise</SwText>
              </BaseLayoutGap>
              <SwText variant="body-L" class="description"
                >Also includes:
                <ul>
                  <li>Auto generate legacy code docs</li>
                  <li>AI assistant that knows your code</li>
                  <li>Custom integrations</li>
                  <li>Local mode</li>
                </ul></SwText
              >
              <Action class="start-action" @click="openRequestDemoLink" secondary>Contact us</Action>
            </BaseLayoutGap>
          </div>
        </BaseLayoutGap>
      </div>
    </div>
  </BaseBackgroundLayout>
</template>

<style scoped lang="postcss">
.container {
  display: flex;
  flex-direction: column;
  gap: var(--space-lg);
  margin: auto;
  text-align: center;
  width: 55vw;
  max-width: 1440px;

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .subtitle {
    color: var(--text-color-secondary);
  }

  .logo-swimm {
    height: 56px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--space-md);

    .graph-img {
      max-width: 100%;
    }

    .card {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      padding: var(--space-lg);
      border: 4px solid var(--color-status-info);
      border-radius: 16px;
      background: var(--color-bg);
      width: 350px;
      align-self: stretch;

      .description {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--space-xs);
        text-align: left;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--space-xs);

    .start-action {
      display: flex;
      align-items: center;
      gap: var(--space-xs);
    }

    .skip-button {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1500px) {
  .container {
    width: 95vw;

    .content .card {
      .card-title {
        font-size: var(--body-L);
      }

      .description {
        font-size: var(--body-L);
      }
    }
  }
}

.app-loader {
  height: 100vh;
}
</style>
