<script setup>
import InstallSlackAppModal from '@/modules/slack-app/modals/InstallSlackAppModal.vue';
import { getSlackChannelList } from '@/modules/slack-app/services/slack-app-utils';
import { BaseLabel, Icon, SwText, TextSkeleton } from '@swimm/ui';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  modelValue: { type: String, default: '' },
  repoId: { type: String, required: true },
  workspaceId: { type: String, required: true },
  disabled: { type: Boolean, default: false },
  label: { type: String, default: '' },
  origin: { type: String, default: '' },
});
const emit = defineEmits(['update:model-value', 'install']);

const store = useStore();
const repoMetadata = computed(() => store.getters['database/db_getRepoMetadata'](props.repoId));
const teamId = computed(() => repoMetadata.value?.slack_config?.team_id);
const isSlackInstalled = computed(() => repoMetadata.value?.slack_config?.is_slack_installed);

const loading = ref(true);
const error = ref('');

const channels = ref([]);

const showInstallModal = ref(false);

onMounted(async () => {
  await fetchChannels();
});

const shouldDisableSelect = computed(() => props.disabled || !!error.value || !isSlackInstalled.value);

async function fetchChannels() {
  error.value = '';
  if (isSlackInstalled.value) {
    loading.value = true;
    const fetchedChannels = await getSlackChannelList({
      repoId: props.repoId,
      workspaceId: props.workspaceId,
    });
    if (fetchedChannels) {
      channels.value = fetchedChannels.map((channel) => ({ ...channel, label: channel.name }));
      loading.value = false;
    } else {
      error.value = 'There was an issue connecting to Slack. Please try again later.';
    }
  }
}

function onChannelSelect(channelName) {
  error.value = '';
  const channelDetails = channels.value.find((c) => c.name === channelName);
  emit('update:model-value', { ...channelDetails, teamId: teamId.value });
}

function onInstallClick() {
  showInstallModal.value = true;
  emit('install');
}

async function onCloseInstallModal() {
  showInstallModal.value = false;
  await fetchChannels();
}
</script>

<template>
  <BaseLabel required v-if="label">{{ label }}</BaseLabel>
  <v-select
    :model-value="modelValue"
    placeholder="Select a channel"
    class="channel-select"
    :options="channels"
    :reduce="(val) => val.name"
    :disabled="shouldDisableSelect"
    :clearable="false"
    :append-to-body="false"
    :searchable="!loading"
    @update:model-value="onChannelSelect"
  >
    <template #open-indicator="{ attributes }">
      <Icon v-bind="attributes" class="icon" name="arrow-down" />
    </template>
    <template #selected-option="{ name, is_private }">
      <SwText v-if="name" variant="body-L" class="option-line">
        <Icon v-if="is_private" no-padding name="locked" />
        <span v-else class="name">#</span>
        <span>{{ name }}</span>
      </SwText>
      <TextSkeleton v-else-if="loading" variant="body-S" class="selected-skeleton" />
    </template>
    <template v-if="loading" #no-options>
      <template v-for="index in 3" :key="index">
        <TextSkeleton variant="body-L" class="option-skeleton long" />
        <TextSkeleton variant="body-L" class="option-skeleton" />
      </template>
    </template>
    <template #option="{ name, is_private }">
      <SwText v-if="name" variant="body-L" class="option-line">
        <Icon v-if="is_private" no-padding name="locked" />
        <div v-else class="name">#</div>
        <span>{{ name }}</span>
      </SwText>
    </template>
  </v-select>
  <div class="footer">
    <SwText v-if="!isSlackInstalled" variant="body-XS"
      >Make sure the <span class="link" @click="onInstallClick">Slack App</span> is installed to enable this
      feature.</SwText
    >
    <SwText v-else-if="error" class="error" variant="body-XS">{{ error }}</SwText>
    <SwText v-else-if="channels.length" variant="body-XS"
      >To see a private channel, you must first add the Swimm App to it. Open the Slack channel, enter
      <span class="bold">/add app</span> and select <span class="bold">Swimm</span></SwText
    >
  </div>
  <InstallSlackAppModal
    v-if="!isSlackInstalled"
    :show="showInstallModal"
    :repo-id="repoId"
    :workspace-id="workspaceId"
    description="Notify your team to discover and create documentation when your code changes."
    :origin="origin"
    @close="onCloseInstallModal"
  />
</template>

<style scoped lang="postcss">
.channel-select.v-select {
  &.vs--disabled {
    color: var(--text-color-disable);
    background: var(--color-disable);
  }

  :deep(.vs__dropdown-menu) {
    max-height: 150px;
  }

  .option-line {
    display: flex;
    align-items: center;
    gap: var(--space-xs);

    .name {
      margin: 0 var(--space-xs);
    }
  }

  .selected-skeleton {
    width: 150px;
  }

  .option-skeleton {
    width: 100px;
    margin: var(--space-base) var(--space-sm);

    &.long {
      width: 200px;
    }
  }
}

.footer {
  margin-top: var(--space-base);

  .link {
    cursor: pointer;
    color: var(--text-color-link);
  }

  .error {
    color: var(--text-color-error);
  }
}
</style>
