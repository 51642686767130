import { STIGG_KEY } from '@/config';
import Stigg, { type Customer } from '@stigg/js-client-sdk';
import { isEmulated, isTest } from '@/config';
import { StiggFeatures, type StiggFeaturesType, config, getLoggerNew } from '@swimm/shared';
import * as webConfig from '@/config';

const logger = getLoggerNew(__modulename);

const stiggClient =
  !isTest || isEmulated
    ? Stigg.initialize({
        apiKey: STIGG_KEY,
        baseUri: `${config.getApiUrl(webConfig.SWIMM_ENV)}/stigg`,
        baseEdgeUri: `${config.getApiUrl(webConfig.SWIMM_ENV)}/stigg-edge`,
      })
    : null;

export function useStigg() {
  const initStiggForCustomer = async function (workspaceId: string) {
    const startTime = Date.now();
    try {
      if (stiggClient && workspaceId) {
        await stiggClient.waitForInitialization();
        await stiggClient.setCustomerId(workspaceId);
      }
    } finally {
      const delta = Date.now() - startTime;
      if (delta > 2000) {
        logger.warn(`initStiggForCustomer took ${delta} ms`);
      }
    }
  };

  const getTrialDaysLeft = async function () {
    const customer = await stiggClient.getCustomer();
    const currentTrials = customer.getActiveTrials();
    if (!currentTrials.length) {
      return null;
    }

    const trialEndDatetime = currentTrials[0].trialEndDate.getTime();
    const dateDiffInMs = trialEndDatetime - Date.now();
    const dateDiffInDays = Math.ceil(dateDiffInMs / (1000 * 60 * 60 * 24));
    return dateDiffInDays;
  };

  const getCurrentSeats = async function () {
    const currentSeats = await getMeteredEntitlement(StiggFeatures.SEAT_COUNT);
    return currentSeats.value;
  };

  const isOnTrial = async function () {
    const customer = await stiggClient.getCustomer();
    return customer.getActiveTrials().length > 0;
  };

  const isTrialExpired = async function () {
    const customer = await stiggClient.getCustomer();
    return await isTrialExpiredForCustomer(customer);
  };

  async function isTrialExpiredForCustomer(customer: Customer) {
    const activeTrials = customer.getActiveTrials();
    const hasActiveNonFreeSubscription = customer.subscriptions.some(
      (subscription) => subscription.status === 'ACTIVE' && subscription.pricingType !== 'FREE'
    );
    return !hasActiveNonFreeSubscription && !activeTrials.length && !!customer.trialedPlans.length;
  }

  const isEligibleForTrial = async function () {
    const customer = await stiggClient.getCustomer();
    const hasOnlyFreeActiveSubscriptions = customer.subscriptions.every(
      (subscription) => subscription.status !== 'ACTIVE' || subscription.pricingType === 'FREE'
    );
    return hasOnlyFreeActiveSubscriptions && !(await isTrialExpiredForCustomer(customer));
  };

  const getActivePlans = async function () {
    const customer = await stiggClient.getCustomer();
    if (!customer?.subscriptions) {
      return [];
    }
    return customer.subscriptions
      .filter((subscription) => subscription.status === 'ACTIVE')
      .map((subscription) => subscription.plan.id.replace('plan-swimm-', ''));
  };

  const getMeteredEntitlement = async function (featureId: StiggFeaturesType) {
    return stiggClient.getNumericEntitlement({ featureId });
  };

  const meteredFeatureAllowed = function (featureId: StiggFeaturesType) {
    try {
      const meteredEntitlement = stiggClient.getMeteredEntitlement({ featureId, options: { requestedUsage: 1 } });
      return meteredEntitlement.hasAccess;
    } catch (err) {
      logger.error({ err }, `Error checking feature ${featureId} access in Stigg`);
      return false;
    }
  };

  return {
    stiggClient,
    initStiggForCustomer,
    isOnTrial,
    isTrialExpired,
    getActivePlans,
    isEligibleForTrial,
    getTrialDaysLeft,
    getCurrentSeats,
    getMeteredEntitlement,
    meteredFeatureAllowed,
  };
}
