import { StatusCodes } from 'http-status-codes';
import { getLoggerNew } from '#logger';
import { computeAiModelConsumedUsage } from '../billing/utils';
import {
  GenerativeAiRequestType,
  type LLMTextCompletionRawResponse,
  StiggFeatures,
  TextCompletionRequest,
} from '../types';
import { parseTextCompletionLLMResponse } from './text-completions';
import { QuotaExceededError, RequestFailedError } from './utils';
import { OpenAI } from 'openai';
import { removeSuffix } from '../utils/string-utils';

const logger = getLoggerNew("packages/shared/src/genAi/AiClient.ts");

interface CompletionResponseLLM {
  generatedText: string;
  usage?: OpenAI.Completions.CompletionUsage;
}

export class AiClient {
  private readonly host: string;
  private _allowanceCache = new Map<string, { time: number; hasAccess: boolean; usage?: number; cap?: number }>();
  private _ttl = 1000 * 60 * 5; // cache allowance for 5 minutes

  constructor(protected readonly getDBAuthToken: () => Promise<string>, host: string) {
    this.host = removeSuffix(host, '/');
  }

  private constructAllowanceKey(workspaceId: string, featureId: StiggFeatures) {
    return `${workspaceId}-${featureId}`;
  }

  protected async _completeText(request: TextCompletionRequest): Promise<CompletionResponseLLM & { cost: number }> {
    try {
      const authToken = await this.getDBAuthToken();
      // For testing with local server, replace the host with: http://localhost:24605
      const response = await fetch(
        `${this.host}/ask-swimm-backend/text-completion/create/workspaces/${request.workspaceId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            openAIParameters: request.openAIParameters,
            askSwimmRequestId: request.requestId,
            requestType: GenerativeAiRequestType.GENERATE_TEXT_COMPLETION,
          }),
        }
      );

      if (!response.ok) {
        if (response.status === StatusCodes.TOO_MANY_REQUESTS) {
          logger.warn('Text completion feature is not allowed, skipping call to AI');
          throw new QuotaExceededError('Quota exceeded for text completion request');
        }
        const result = await response.json();
        throw new RequestFailedError('Request failed', response.status, result);
      }

      const result = (await response.json()) as { response: LLMTextCompletionRawResponse };
      const processedTextCompletion = parseTextCompletionLLMResponse({
        textToParse: result.response.completionData,
        shouldRetry: true,
        requestId: request.requestId,
        originalParams: request.textCompletionParams,
      });
      const cost = computeAiModelConsumedUsage(
        StiggFeatures.TEXT_COMPLETION_CAP,
        request.openAIParameters.model,
        result.response?.usage?.prompt_tokens,
        result.response?.usage?.completion_tokens
      );
      return { generatedText: processedTextCompletion, usage: result.response.usage, cost };
    } catch (err) {
      logger.error({ err }, 'Failed to call AskSwimmBackend');
      throw err;
    }
  }

  async completeText(request: TextCompletionRequest): Promise<CompletionResponseLLM & { cost: number }> {
    const cachedAccess = this._allowanceCache.get(
      this.constructAllowanceKey(request.workspaceId, StiggFeatures.TEXT_COMPLETION_CAP)
    );
    if (cachedAccess && Date.now() - cachedAccess.time <= this._ttl && !cachedAccess.hasAccess) {
      logger.debug('Text completion feature is not allowed, skipping call to AI');
      throw new QuotaExceededError('Quota exceeded for text completion request');
    }

    this._allowanceCache.delete(this.constructAllowanceKey(request.workspaceId, StiggFeatures.TEXT_COMPLETION_CAP));

    try {
      return this._completeText(request);
    } catch (e) {
      if (e instanceof QuotaExceededError) {
        this._allowanceCache.set(this.constructAllowanceKey(request.workspaceId, StiggFeatures.TEXT_COMPLETION_CAP), {
          time: Date.now(),
          hasAccess: false,
        });
      }
      throw e;
    }
  }
}
