<template>
  <div class="list-container">
    <div class="header left">
      <h3>{{ computedListTitle }}</h3>
    </div>
    <Divider class="line" />
    <ol>
      <li v-for="(step, index) in steps" :key="`s-${index}`">
        <Icon :name="step.iconName" :tooltip="getTooltip(step)" />
        <a
          v-if="step.isAvailable && step.link"
          class="swimm-link"
          :href="step.link"
          target="_blank"
          @click="(event) => handleLinkClick(event, step.link)"
        >
          {{ step.resource.name }}
        </a>
        <span v-else-if="!step.link" v-tooltip="`step is unavailable.`" class="inactive">{{ step.resource.name }}</span>
        <span v-else v-tooltip="`step '${step.resource.name}' is unavailable.`" class="inactive">{{
          step.resource.name
        }}</span>
      </li>
    </ol>
  </div>
</template>

<script setup lang="ts">
import { useNavigate } from '@/common/composables/navigate';
import type { PlaylistProgressBarStep } from '@/modules/playlists/types';
import { Divider } from '@swimm/ui';
import { type PropType, computed } from 'vue';

const { navigateToPageAndTerminateWorker } = useNavigate();

const props = defineProps({
  nestedPlaylist: { type: Boolean, default: false },
  steps: { type: Array as PropType<PlaylistProgressBarStep[]>, required: true },
  listTitle: { type: String, required: false, default: '' },
  showStepsCount: { type: Boolean, default: true },
});

const computedListTitle = computed(() => {
  if (props.listTitle) {
    return props.listTitle;
  }

  if (props.showStepsCount) {
    return `${props.steps.length} Steps`;
  }

  return 'Steps';
});

function getTooltip(step: PlaylistProgressBarStep) {
  if (!step.isAvailable) {
    return 'Either you don’t have the updated repo locally or this swimm file hasn’t been pushed by the author.\nPull the latest repo version or ask the repo admin to verify its status.';
  }
  return null;
}

function handleLinkClick(event: Event, link: string) {
  const isOpenInNewTab =
    event.type === 'click' && (event['metaKey'] || event['shiftKey'] || event['ctrlKey'] || event['which'] === '2');
  if (props.nestedPlaylist || isOpenInNewTab) {
    // nested playlist items are opens in a new tab AND Whenever the user wants to open item in a new tab -> don't proceed further (return means do default behavior)
    return;
  } else {
    // Otherwise -> prevent the users action (don't use the href, but proceed this function)
    event.preventDefault();
  }

  navigateToPageAndTerminateWorker({ newRoute: link });
}
</script>

<style scoped>
.list-container {
  margin-top: 75px;
}

.swimm-link {
  color: var(--text-color-secondary);
  cursor: pointer;
}

.swimm-link:not(.inactive) {
  font-weight: bold;
  color: var(--text-color-link);
}

.swimm-link:not(.inactive):hover {
  border-bottom: 1px var(--text-color-link) solid;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  background-color: var(--color-bg);
}

.line {
  margin: 20px 0;
}

p {
  margin: 0;
  padding-bottom: 45px;
}

ol {
  padding-inline-start: 1rem;
}

ol li {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  padding-inline-start: 0.5rem;
}

.inactive {
  color: var(--text-color-secondary);
  cursor: default;
}
</style>
