import * as firestore from '@/adapters-common/firestore-wrapper';
import { config, getRandomKey, hashKey } from '@swimm/shared';
import { Timestamp } from 'firebase/firestore';

interface ApiKeyDetails {
  generated_on: Date;
  key_hash: string;
}

export async function revokeCurrentApiKey(workspaceId: string): Promise<void> {
  const { code } = await firestore.deleteDocFromCollection(firestore.collectionNames.WORKSPACE_API_KEYS, workspaceId);

  if (code !== config.SUCCESS_RETURN_CODE) {
    throw new Error("Couldn't delete current key");
  }
}

export async function generateNewApiKey(workspaceId: string): Promise<string> {
  const currentKey = await getApiKeyDetails(workspaceId);
  if (currentKey !== undefined) {
    throw new Error('Current key already exists');
  }

  const key = getRandomKey();

  const keyData: ApiKeyDetails = {
    generated_on: new Date(),
    key_hash: hashKey(key),
  };

  const { code } = await firestore.setValuesInDoc(firestore.collectionNames.WORKSPACE_API_KEYS, workspaceId, keyData);

  if (code !== config.SUCCESS_RETURN_CODE) {
    throw new Error("Couldn't set value in doc");
  }

  return key;
}

export async function getApiKeyDetails(workspaceId: string): Promise<ApiKeyDetails | undefined> {
  const result = await firestore.getDocFromCollection(firestore.collectionNames.WORKSPACE_API_KEYS, workspaceId);

  if (result.code !== config.SUCCESS_RETURN_CODE) {
    return undefined;
  }

  const { data } = result;

  return {
    generated_on: new Timestamp(data.generated_on.seconds, data.generated_on.nanoseconds).toDate(),
    key_hash: data.key_hash,
  };
}
