<script lang="ts">
export type HighlighterCreated = {
  instance: Highlighter;
  language: Lang;
  theme: Theme;
};
</script>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { until } from '@vueuse/core';
import type { Highlighter, IThemedToken, Lang, Theme } from 'shiki';
import { useShiki } from '../../lib/shiki';
import { getLanguageFromPath } from '../../lib/languages';
import { getTheme } from '../../lib/theme';
import { type TokenSuggestion, splitLineByWords } from '@swimm/shared';
import { escapeRegExp } from 'lodash-es';

import BaseHighlight from '../../components/BaseHighlight/BaseHighlight.vue';

const props = withDefaults(
  defineProps<{
    /**
     * A single or multi-line block of escaped code.
     */
    code: string;
    /**
     * Starting line number.
     */
    lineNumber?: number;
    /**
     * Set the language syntax for highlighting. <br/><em>Note: If highlightTokens are supplied and this
     * prop isn't set, the component will attempt to auto-detect the language from the token.</em>
     */
    language?: Lang;
    /**
     * Set the highlighter theme. <br/><em>Node: It's recommended to leave the component to handle the
     * theme itself. It will automatically switching between `github-light` and `github-dark` based on the global
     * `data-theme` attribute.
     */
    theme?: Theme;
    /**
     * Any query string i.e. from a search, that should be highlighted within the code.
     */
    query?: string;
    /**
     * The component can accept an array of token suggestions and highlights them within the code.
     */
    highlightTokens?: TokenSuggestion[];
    /**
     * The component will create a highlighter on mount or use a highlighter that is supplied via this prop.
     * This is for caching at the parent level.
     */
    highlighter?: Highlighter;
  }>(),
  {
    lineNumber: undefined,
    language: 'javascript',
    theme: undefined,
    query: undefined,
    highlightTokens: undefined,
    highlighter: undefined,
  }
);

const { shiki, shikiIsReady } = useShiki();

let cacheableHighlighter: Highlighter | undefined = props.highlighter || undefined;

const emit = defineEmits<{
  highlighterCreated: [value: HighlighterCreated];
  highlighted: [];
}>();

type StructuredToken = { content: string; color: string | undefined };
const structuredTokens = ref<StructuredToken[][]>();

const fallbackCode = computed(() => {
  return isMultipleLines.value ? props.code.match(/.*?\r\n|[^]*$/g)?.filter(Boolean) : [props.code.trim()];
});

const isMultipleLines = computed(() => (props.code ? props.code.trim().split('\n').length > 1 : false));

const computedClasses = computed(() => ({
  [`code--single-line`]: !isMultipleLines.value,
  [`code--line-numbers`]: !!props.lineNumber,
}));

const computedStyles = computed(() => {
  return `--step: ${props.lineNumber};`;
});

async function highlight() {
  await until(shikiIsReady).toBeTruthy();

  if (props.highlighter && shiki.value) {
    applyHighlighting(props.highlighter);
  } else {
    const useTheme = ref<Theme>(
      props.theme !== undefined ? props.theme : getTheme() === 'dark' ? 'github-dark' : 'github-light'
    );

    const selectedLanguage = selectLanaguage();

    try {
      cacheableHighlighter = await shiki.value?.getHighlighter({ theme: useTheme.value, langs: [selectedLanguage] });
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (cacheableHighlighter && !props.highlighter) {
      emit('highlighterCreated', { instance: cacheableHighlighter, language: selectedLanguage, theme: useTheme.value });
      applyHighlighting(cacheableHighlighter);
    }
  }

  if (structuredTokens.value) {
    emit('highlighted');
  }
}

function selectLanaguage() {
  let selectedLanguage = props.language;

  if (props.highlightTokens && props.highlightTokens.length) {
    // We assume we support a single language, taking it from the first token
    const tokenLanguage = getLanguageFromPath(props.highlightTokens[0].position.path);

    if (tokenLanguage) {
      selectedLanguage = tokenLanguage;
    }
  } else {
    selectedLanguage = props.language;
  }

  return selectedLanguage;
}

function applyHighlighting(highlighter: Highlighter) {
  try {
    const tokens = highlighter.codeToThemedTokens(props.code, highlighter.getLoadedLanguages()[0]);

    structuredTokens.value = tokens.map((line) => {
      return line.map((token, index) => {
        return {
          content: applyAdditionalSyntax(line, token.content, index),
          color: token.color,
        };
      });
    });

    if (!isMultipleLines.value && structuredTokens.value[0]?.[0]?.content != null) {
      if (/^[\s\t]+$/.test(structuredTokens.value[0][0].content)) {
        structuredTokens.value[0].shift();
      }

      if (structuredTokens.value[0][0] && structuredTokens.value[0][0].content) {
        structuredTokens.value[0][0].content = structuredTokens.value[0][0].content.replace(/^[\s\t ]+/, '');
      }
    }
  } catch (e) {
    structuredTokens.value = undefined;
  }
}

function highlightQueryMatch(token: string) {
  if (!props.query) {
    return token;
  }

  const queryRegExp = new RegExp(escapeRegExp(props.query), 'i');

  return token.replace(queryRegExp, '<strong class="code__found">$&</strong>');
}

function highlightAtPosition(
  styleToken: string,
  styleTokenStart: number,
  replacementStart: number,
  replacementToken: string
) {
  const offsetReplacementStart = replacementStart - styleTokenStart;

  return (
    escapeHtml(styleToken.slice(0, offsetReplacementStart)) +
    `<span class="code__highlight">${highlightQueryMatch(replacementToken)}</span>` +
    escapeHtml(styleToken.slice(offsetReplacementStart + replacementToken.length))
  );
}

function applyAdditionalSyntax(line: IThemedToken[], styleToken: string, index: number) {
  let newToken = escapeHtml(styleToken);
  const completeLine = line.map((styleToken: IThemedToken) => styleToken.content).join('');

  if (props.highlightTokens === undefined) {
    newToken = highlightQueryMatch(newToken);
  }

  if (props.highlightTokens) {
    props.highlightTokens.forEach((tokenSuggestion) => {
      if (tokenSuggestion.lineData === completeLine) {
        const words = splitLineByWords(tokenSuggestion.lineData);

        let tokenCharacterStart = 0;
        words.forEach((token, index) => {
          if (index < tokenSuggestion.position.wordStart) {
            tokenCharacterStart = tokenCharacterStart + token.length;
          }
        });
        const tokenCharacterEnd = tokenCharacterStart + tokenSuggestion.token.length - 1;

        // Find the style token start based on it's index and adding up the token lengths prior to it
        const styleTokenStart = line.slice(0, index).reduce((acc, item) => acc + item.content.length, 0);
        const styleTokenEnd = styleTokenStart + styleToken.length - 1;

        let replacementToken;
        let directReplacement = false;

        // Token suggestion matches the style token
        if (tokenCharacterStart === styleTokenStart && tokenCharacterEnd === styleTokenEnd) {
          replacementToken = escapeRegExp(tokenSuggestion.token);
          directReplacement = true;

          // Token suggestion starts within the style token
        } else if (
          tokenCharacterStart >= styleTokenStart &&
          tokenCharacterStart < styleTokenEnd &&
          tokenCharacterEnd > styleTokenEnd &&
          styleToken.trim() &&
          tokenSuggestion.token.includes(styleToken.slice(tokenCharacterStart - styleTokenStart))
        ) {
          replacementToken = escapeRegExp(styleToken.slice(tokenCharacterStart - styleTokenStart));

          // Token suggestion spans the style token
        } else if (styleTokenStart > tokenCharacterStart && styleTokenEnd < tokenCharacterEnd) {
          replacementToken = escapeRegExp(styleToken);

          // Token suggestion starts and ends within the styleToken
        } else if (tokenCharacterStart >= styleTokenStart && tokenCharacterEnd <= styleTokenEnd) {
          replacementToken = tokenSuggestion.token;
          directReplacement = true;

          // Token suggestion ends within the style token
        } else if (tokenCharacterEnd >= styleTokenStart && tokenCharacterEnd <= styleTokenEnd) {
          replacementToken = escapeRegExp(styleToken.slice(0, tokenCharacterEnd - styleTokenStart + 1));

          // If the tokenSuggestion token doesn't end with a space
          // trim the replacement token so any spaces don't get highlighted
          if (!tokenSuggestion.token.endsWith(' ')) {
            replacementToken = replacementToken.trim();
          }
        }

        // If match found.
        if (replacementToken) {
          const escapedQuery = escapeRegExp(props.query || '');

          // Wrap the matching token in a highlight element
          if (directReplacement) {
            newToken = highlightAtPosition(styleToken, styleTokenStart, tokenCharacterStart, replacementToken);
          } else {
            newToken = newToken.replace(
              new RegExp(`(${replacementToken})`, 'g'),
              '<span class="code__highlight">$1</span>'
            );
          }

          // If the highlight element has a maching query string wrap the matching query string in a found element
          newToken = newToken.replace(
            new RegExp(`(<span class="code__highlight">[^<]*?)(${escapedQuery})([^<]*?</span>)`, 'g'),
            '$1<strong class="code__found">$2</strong>$3'
          );
        }
      }
    });
  }

  return newToken;
}

function escapeHtml(unsafe: string): string {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}

onMounted(async () => {
  highlight();
});

watch(
  () => [props.code, props.language, props.theme, props.query, props.highlightTokens],
  () => {
    highlight();
  },
  { deep: true }
);
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <pre
    v-if="structuredTokens"
    class="code"
    :class="computedClasses"
    :style="computedStyles"
  ><code class="code__wrapper"><span v-for="(line, lineIndex) in structuredTokens" :key="`line-${lineIndex}`" class="code__line"><template v-for="(token, tokenIndex) in line" :key="`token-${tokenIndex}`"><span :style="{ color: token.color }" v-html="token.content" /></template>
</span></code></pre>
  <pre
    v-else
    class="code"
    :class="computedClasses"
    :style="computedStyles"
  ><code class="code__wrapper"><span v-for="(line, lineIndex) in fallbackCode" :key="`line-${lineIndex}`" class="code__line"><BaseHighlight class="code__highlight-wrapper" wrapper="code" :query="query" :string="line" /></span></code></pre>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.code {
  $self: &;

  @include basic-resets;

  font-size: var(--font-size-small);
  max-width: 100%;
  overflow: auto;
  width: auto;

  &__wrapper {
    line-height: var(--line-height-mono);
    padding: 0;
  }

  &__highlight-wrapper {
    font-size: inherit;
    line-height: var(--line-height-mono);
  }

  // Due to the way this class is being applied via shiki
  // Vue's scoped data attribute isn't applied, so we have
  // to use :deep to apply the mixin.
  :deep(#{$self}__highlight) {
    @include highlight;
  }

  :deep(#{$self}__found) {
    @include highlight;

    font-weight: 700; // var(--font-weight-bolder);
  }

  &--single-line {
    display: block;

    #{$self} {
      &__wrapper {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }

  &--line-numbers {
    counter-reset: step;
    counter-increment: step calc(var(--step) - 1);

    #{$self}__line::before {
      content: counter(step);
      counter-increment: step;
      width: 1rem;
      margin-right: 1.5rem;
      display: inline-block;
      text-align: right;
      color: var(--color-text-disabled);
    }
  }
}
</style>
