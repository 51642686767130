<template>
  <DropdownMenu
    v-if="shouldShowMenu"
    ref="suggestions"
    :open-above="shouldOpenAbove()"
    class="commands-list"
    :cursor-y="cursorLoc.y"
    :cursor-x="cursorLoc.x"
    :options-length="filteredCommandsByCategory.length"
    @menu-mounted="setMenuHeight"
  >
    <template v-if="filteredCommandsByCategory.length > 0">
      <SlashCommandsMenuContents
        data-testid="suggestion-menu"
        :command-categories="filteredCommandsByCategory"
        :selected-command="navigatedCommandIndex"
        :should-scroll-to-selected="shouldScrollToSelected"
        :is-ide="false"
        @command-selected="selectCommand"
        @hover-over-command="suggestionOnHover"
        @scrolled-to-command="shouldScrollToSelected = false"
      />
    </template>
  </DropdownMenu>
</template>

<script lang="ts">
import { computed, defineComponent, inject, ref } from 'vue';
import DropdownMenu from '@/components/common/DropdownMenu.vue';
import { useCommandsSuggestions } from './commandsSuggestions';
import { CommandSuggestion } from './commandSuggestion.js';
import SlashCommandsMenuContents from './SlashCommandsMenuContents.vue';
import { useWindowHeight } from '@/components/common/window-height';
import type { AnalyticsInterface, AnalyticsTrackProperties } from '@/types';

export default defineComponent({
  name: 'SuggestionsMenu',

  components: { SlashCommandsMenuContents, DropdownMenu },
  setup() {
    const menuHeight = ref(0);
    const analytics: AnalyticsInterface | null = inject('analytics', null) as AnalyticsInterface | null;
    const {
      flatCommands,
      flatFilteredCommands,
      query,
      cursorLoc,
      suggestionOnEnter,
      suggestionOnChange,
      suggestionOnExit,
      suggestionOnHover,
      filteredCommandsByCategory,
      navigatedCommandIndex,
      selectCommand,
      insertCommand,
      suggestionOnKeyDown,
    } = useCommandsSuggestions({
      tEvent: (eventName: string, eventProps: AnalyticsTrackProperties) => analytics?.track(eventName, eventProps),
    });
    const shouldScrollToSelected = ref(false);
    const commandsSuggestions = CommandSuggestion.configure({
      commands: flatCommands.value.map(({ title, aliases }) => ({ title, aliases })),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onStart: (e: any) => {
        suggestionOnEnter(e);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onUpdate: (e: any) => {
        suggestionOnChange(e);
      },
      onExit: suggestionOnExit,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onKeyDown: (e: any) => {
        return suggestionOnKeyDown(e);
      },
      insertCommand: insertCommand,
    });

    return {
      shouldShowMenu: computed(() => query.value || flatFilteredCommands.value.length > 0),
      shouldOpenAbove() {
        // EditDoc footer has fixed height of 51px
        // cursorRatio is the percentage of the screen that our previous fixed size used to push the command div below the cursor
        const cursorRatio = 0.0072;
        const windowHeight = useWindowHeight();
        const windowTrigger = windowHeight.value - (cursorLoc.value.y + window.innerHeight * cursorRatio);
        return windowTrigger - windowHeight.value * cursorRatio < menuHeight.value;
      },
      setMenuHeight(height: number) {
        menuHeight.value = height;
      },
      flatFilteredCommands,
      filteredCommandsByCategory,
      cursorLoc,
      navigatedCommandIndex,
      selectCommand,
      commandsSuggestions,
      suggestionOnHover,
      shouldScrollToSelected,
    };
  },
});
</script>
