import { Base64 } from 'js-base64';
import sh from 'shorthash2';

export function btoa(str: string): string {
  return Buffer.from(str, 'binary').toString('base64');
}

export function atob(str: string): string {
  return Buffer.from(str, `base64`).toString(`binary`);
}

export function decodeString(str: string): string {
  return decodeURIComponent(atob(str));
}

export function decodeStringSafely(encodedString: string | null): string | null {
  // like decodeString but works ok if encodedString is falsy
  if (encodedString == null) {
    return encodedString;
  }
  return decodeString(encodedString);
}

export function encodeString(str: string): string {
  return btoa(encodeURIComponent(str));
}

/**
 * Strips a string with CRLF ("Carriage Return, Line Feed") from a string.
 * Sometimes in our tests, a string's result on a windows machine might include (\r) where the mock includes no such special character.
 * if it was introduced in OSX/Linux developer workstation
 * @param {string} string - a string including the CRLF characters to remove.
 * @return {string} the same string without carriage return (\r).
 */
export function removeCRLFCharacters(str: string): string {
  return str?.replace(/\r/g, '');
}

/**
 * Escape the given string to be used dynamically in a regular expression.
 * Taken directly from MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping.
 * @param {string} str string to escape.
 * @returns {string} escaped string for use in a regex.
 */
export function escapeRegExpCharacters(str: string): string {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function slugify(text: string, replacement = '-', options?: { replace?: RegExp }): string {
  let modifiedText = text.toLowerCase();
  if (options?.replace) {
    modifiedText = modifiedText.replace(options.replace, replacement);
  }
  modifiedText = modifiedText
    .replace(/\s+/g, replacement) // Replace spaces with replacement
    .replace(/[^\w-]+/g, ''); // Remove all non-word chars
  return modifiedText
    .replace(new RegExp(`${replacement}+`, 'g'), replacement) // Replace multiple occurrences of replacement with single
    .replace(new RegExp(`^${replacement}+|${replacement}+$`, 'g'), ''); // Trim single leading/trailing 'replacement'
}

export function shortHash(text: string): string {
  return sh(text);
}

/**
 * Decode a given base 64 encoded string.
 * @param b64EncodedString base 64 encoded string.
 * @returns the given string, decoded.
 */
export function b64decodeString(b64EncodedString: string): string {
  return Base64.decode(b64EncodedString);
}

/**
 * Decode a given base 64 encoded string and remove every \r characters in it.
 * @param b64EncodedString base 64 encoded string.
 * @returns the given string, decoded, and without any \r characters.
 */
export function b64decodeAndRemoveCR(b64EncodedString: string): string {
  return b64EncodedString ? removeCRLFCharacters(Base64.decode(b64EncodedString)) : b64EncodedString;
}

/**
 * Encode a given string to base 64.
 * @param decodedString the string to encode
 * @returns the given string ecnoded to base 64.
 */
export function b64encodeString(decodedString: string): string {
  return Base64.encode(decodedString);
}

/**
 * If a text is longer than length, it would be truncated to
 * length (removing from the start) and replaced with clamp
 * @param {string} text: the string to be truncated
 * @param {number} length: the length to truncate to - default DEFAULT_ELLIPSIS_LENGTH = 50
 * @param {string} clamp: the string to be added to the beginning - default ellipsis
 * @returns the truncated string or the original one.
 */
export function reversedEllipsisFunction(text: string | undefined, length?: number, clamp?: string): string {
  if (!text) {
    return '';
  }
  const DEFAULT_ELLIPSIS_LENGTH = 50;
  clamp = clamp || '...';
  length = length || DEFAULT_ELLIPSIS_LENGTH;
  return text.length > length ? clamp + text.slice(text.length - length) : text;
}

export function convertTabsToSpaces(str: string, numOfSpaces = 4): string {
  return str.replace(/\t/g, ' '.repeat(numOfSpaces));
}

export function trimStartSpacesFromString(str: string, spacesAmountToTrim: number): string {
  // trims the specified number of leading spaces from string while preserving the original string length
  const originalStringLength = convertTabsToSpaces(str).length;
  return str.trimStart().padStart(originalStringLength - spacesAmountToTrim);
}

/* remove suffix if exists, otherwise return string unmodified */

export function removeSuffix(text: string, suffix: string): string {
  if (suffix && text.endsWith(suffix)) {
    return text.slice(0, -suffix.length);
  }
  return text;
}

/* remove prefix if exists, otherwise return string unmodified */

export function removePrefix(text: string, prefix: string): string {
  if (prefix && text.startsWith(prefix)) {
    return text.slice(prefix.length);
  }
  return text;
}

export function pluralize({ word, count, ending = 's' }) {
  if (count === 1) {
    return word;
  }
  return `${word}${ending}`;
}

export function trimBoth(str: string): { trimmed: string; fromStart: number; fromEnd: number } {
  const s1 = str.trimStart();
  const fromStart = str.length - s1.length;
  const s2 = s1.trimEnd();
  const fromEnd = s1.length - s2.length;
  return { trimmed: s2, fromStart, fromEnd };
}

/**
 * Match all occurrences of a string in another string without using regex.
 * This is needed because sometimes we try to match huge snippet parts and the V8 regex engine can't handle it. (limit is 65536 characters in a regex)
 * @param str the string to search in
 * @param searchStr the string to search for
 */
export function matchAllWithoutRegex(str: string, searchStr: string): RegExpMatchArray[] {
  if (!str || !searchStr) {
    return [];
  }
  const matches: RegExpMatchArray[] = [];
  let startIndex = 0;

  while (startIndex <= str.length - searchStr.length) {
    const matchIndex = str.indexOf(searchStr, startIndex);

    if (matchIndex === -1) {
      break;
    }

    // Create a RegExpMatchArray manually
    const matchArray = [searchStr] as RegExpMatchArray;
    matchArray.index = matchIndex;
    matchArray.input = str;

    matches.push(matchArray);

    startIndex = matchIndex + searchStr.length;
  }

  return matches;
}

/*
 * Returns the Jaccard distance between two strings. Information about the algorithm can be found here:
 * https://en.wikipedia.org/wiki/Jaccard_index
 * The Jaccard distance is a measure of how dissimilar two sets are.
 * It is defined as the size of the intersection divided by the size of the union of the sets.
 * @param contentToSearch The content to search for
 * @param content The content to search in
 * @returns The Jaccard distance between the two strings
 */
export function jaccardDistance(contentToSearch: string, content: string): number {
  const set1 = new Set(contentToSearch.split(' '));
  const set2 = new Set(content.split(' '));

  const intersection = new Set([...set1].filter((x) => set2.has(x))).size;
  const union = new Set([...set1, ...set2]).size;

  return intersection / union;
}
