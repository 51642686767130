<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';
import Icon from './Icon.vue';

const props = defineProps({
  size: {
    type: String as PropType<ActionSizes>,
    default: 'big',
    validator: (value: ActionSizes) => Object.values(ActionSizesValues).includes(value),
  },
  secondary: { type: Boolean, default: false },
  magic: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  noPadding: { type: Boolean, default: false },
  /* The html element used for the button. */
  type: {
    type: String as PropType<ActionTypes>,
    default: 'button',
    validator: (value: ActionTypes) => Object.values(ActionTypesValues).includes(value),
  },
  /* When setting the button’s type to a link (), use this option to give a href. */
  href: { type: String, default: null },
  variant: {
    type: String as PropType<ActionVariants>,
    default: null,
    validator: (value: ActionVariants) => Object.values(ActionVariantsValues).includes(value),
  },
  /* Any icon from Fontaweswimm library */
  trailingIcon: { type: String, default: null },
  buttonType: { type: String, default: 'submit' },
});
defineEmits(['click']);

const classes = computed(() => {
  return [
    'button',
    props.size,
    props.variant,
    {
      'no-padding': props.noPadding,
      disabled: props.disabled,
      loading: props.loading,
      secondary: props.secondary,
      link: props.type === 'a',
      magic: props.magic,
    },
  ];
});
</script>

<template>
  <component
    :is="type"
    :type="buttonType"
    :class="classes"
    :disabled="disabled || loading ? true : undefined"
    :href="href"
    @click="$emit('click', $event)"
  >
    <slot>Button text</slot>
    <Icon v-if="trailingIcon" :name="trailingIcon" class="trailing-icon no-padding" />
  </component>
</template>

<script lang="ts">
const ActionSizesValues = ['big', 'small'] as const;
export type ActionSizes = (typeof ActionSizesValues)[number];

const ActionTypesValues = ['button', 'a'] as const;
export type ActionTypes = (typeof ActionTypesValues)[number];

const ActionVariantsValues = ['danger', 'success'] as const;
export type ActionVariants = (typeof ActionVariantsValues)[number];
</script>

<style scoped>
.button {
  position: relative;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 4px 12px;
  height: 32px;
  background: var(--color-brand);
  color: var(--text-color-on-dark);
  font-family: var(--fontfamily-main);
  font-size: var(--subtitle-L);
  font-weight: 800;
  outline: none;
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.button.disabled,
.button:disabled {
  color: var(--text-color-on-dark);
  background: var(--color-brand-disable);
  cursor: not-allowed;
}

.button:not([disabled]) {
  cursor: pointer;
}

.button:not([disabled]):focus-visible,
.button:not([disabled]):hover {
  background: var(--color-brand-hover);
}

.button.secondary {
  color: var(--text-color-secondary);
  background: transparent;
  border-color: var(--color-border-default-strong);
}

.button.secondary.disabled,
.button.secondary:disabled {
  color: var(--text-color-disable);
  background: var(--color-bg);
  cursor: not-allowed;
}

.button.secondary:not([disabled]):focus-visible,
.button.secondary:not([disabled]):hover {
  color: var(--text-color-secondary);
  background: var(--color-hover);
  border-color: var(--color-border-default-strong);
}

.button.link {
  display: unset;
  background: transparent;
  border-color: transparent;
  color: var(--text-color-disable);
}

.button.link:not([disabled]) {
  color: var(--text-color-secondary);
  cursor: pointer;
}

.button.link:not([disabled]):focus-visible,
.button.link:not([disabled]):hover {
  background: var(--color-hover);
}

.button:active:not([disabled]) {
  transform: scale(0.95);
}

.button:not(.secondary):not(.link).disabled,
.button:not(.secondary):not(.link):disabled {
  background-color: var(--color-brand-disable);
}

.button.magic {
  background-color: var(--color-bg-magic);
}

.button.magic:hover {
  background-color: var(--color-bg-magic-hover);
}

.button:not(.secondary):not(.link).magic.disabled,
.button:not(.secondary):not(.link).magic:disabled {
  background-color: var(--color-bg-magic-subtle);
}

.button.secondary:not(.link).disabled,
.button.secondary:not(.link):disabled {
  border-color: var(--text-color-disable);
}

.button.small {
  padding: 4px 16px;
  font-size: var(--subtitle-S);
  height: 24px;
}

.button.danger {
  background: var(--color-status-error);
  border-color: transparent;
  color: var(--text-color-error);
}

.button.danger:not([disabled]):focus-visible,
.button.danger:not([disabled]):hover {
  background: var(--color-status-error-hover);
  border-color: transparent;
}

.button.success {
  background: var(--color-status-success);
  border-color: transparent;
  color: var(--text-color-success);
}

.button.success:not([disabled]):focus-visible,
.button.success:not([disabled]):hover {
  background: var(--color-status-success-hover);
  border-color: transparent;
}

.button.loading {
  background-image: url(@/assets/secondary-loading.gif);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  color: transparent;
}

.button.no-padding {
  padding: 0;
}

.trailing-icon {
  margin-left: 10px;
}
</style>
