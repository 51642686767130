/**
 * swmSnippet node.
 *
 * Based on https://github.com/ueberdosis/tiptap/blob/75f0418f03fb040dc4c12c104dfe457342b4e4b4/packages/extension-code-block/src/code-block.ts
 *
 * @module
 */

import { type JSONContent, Node, VueNodeViewRenderer, mergeAttributes } from '@tiptap/vue-3';
// import { getSwimmEditorServices } from './SwimmEditorServices';
import SwmSnippetNodeView from '../nodeViews/SwmSnippetNodeView.vue';
import { getLanguageForFile } from '@/swmd/langmap';
import { isParentOfType } from '../utils';
import { applySwmSnippetAutosync, convertSwmSnippetToSmartElement } from '../../swmd/autosync';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { getSwimmEditorServices } from './Swimm';
import {
  ApplicabilityStatus,
  type SmartElementWithApplicability,
  type Snippet,
  getLoggerNew,
  isSmartElementWithNewInfo,
  productEvents,
  removePrefix,
} from '@swimm/shared';
import { GapCursor } from '@tiptap/pm/gapcursor';

const logger = getLoggerNew(__modulename);

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    swmSnippet: {
      insertSwmSnippet: (snippet: string, path: string, line: number, repoId: string) => ReturnType;
      insertSnippetOrCodeBlock: (snippet: string, path: string, line: number, repoId: string) => ReturnType;
      selectAndInsertSwmSnippets: (allowInsertAtEnd?: boolean) => ReturnType;
      applySwmSnippetAutosync: (
        pos: number,
        snippet: SmartElementWithApplicability<Snippet>,
        userRequested?: boolean
      ) => ReturnType;
    };
  }
}

export interface SwmSnippetOptions {
  /**
   * Adds a prefix to language classes that are applied to code tags.
   * Defaults to `'language-'`.
   */
  languageClassPrefix: string;

  /**
   * Custom HTML attributes that should be added to the rendered HTML tag.
   */
  HTMLAttributes: Record<string, unknown>;
}

export default Node.create<SwmSnippetOptions>({
  name: 'swmSnippet',

  group: 'topBlock',

  content: 'block+',

  isolating: true,

  addOptions() {
    return {
      languageClassPrefix: 'language-',
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      snippet: {
        isRequired: true,
        parseHTML(element) {
          return element.querySelector('pre:last-child > code')?.textContent;
        },
        rendered: false,
      },
      path: {
        isRequired: true,
        parseHTML: (element) => {
          return element.getAttribute('data-path');
        },
        renderHTML: (attributes) => {
          return { 'data-path': attributes.path };
        },
      },
      line: {
        isRequired: true,
        parseHTML: (element) => {
          return parseInt(element.getAttribute('data-line') ?? '', 10);
        },
        renderHTML: (attributes) => {
          return { 'data-line': attributes.line.toString() };
        },
      },
      collapsed: {
        parseHTML: (element) => {
          return element.hasAttribute('data-collapsed');
        },
        renderHTML: (attributes) => {
          return attributes.collapsed ? { 'data-collapsed': '' } : null;
        },
      },
      language: {
        default: null,
        parseHTML: (element) => {
          const { languageClassPrefix } = this.options;
          const classNames = [...(element.querySelector('pre:last-child > code')?.classList || [])];
          const languages = classNames
            .filter((className) => className.startsWith(languageClassPrefix))
            .map((className) => className.replace(languageClassPrefix, ''));
          const language = languages[0];

          if (!language) {
            return null;
          }

          return language;
        },
        rendered: false,
      },
      repoId: {
        isRequired: true,
        parseHTML: (element) => {
          return element.getAttribute('data-repo-id');
        },
        renderHTML: (attributes) => {
          return { 'data-repo-id': attributes.repoId };
        },
      },
      repoName: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute('data-repo-name');
        },
        renderHTML: (attributes) => {
          return { 'data-repo-name': attributes.repoName };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (node) => (node as HTMLElement).hasAttribute('data-swm-snippet') && null,
        contentElement: 'div[data-content]',
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ 'data-swm-snippet': '' }, this.options.HTMLAttributes, HTMLAttributes),
      ['div', { 'data-content': '' }, 0],
      [
        'pre',
        { contenteditable: 'false' },
        [
          'code',
          {
            class: node.attrs.language ? this.options.languageClassPrefix + node.attrs.language : null,
            // Without this you can't use the mouse to select the snippet's code which is contenteditable="false" inside ProseMIrror
            tabindex: '-1',
          },
          node.attrs.snippet,
        ],
      ],
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(SwmSnippetNodeView);
  },

  addCommands() {
    return {
      insertSwmSnippet:
        (snippet, path, line, repoId) =>
        ({ commands, dispatch, editor }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);

          const node: JSONContent = {
            type: this.name,
            attrs: {
              snippet,
              path: `/${removePrefix(path, '/')}`,
              line,
              repoId,
              language: getLanguageForFile(path),
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          };

          if (dispatch) {
            const isCrossRepo = repoId !== swimmEditorServices.repoId.value;
            const isCrossRepoInIde: boolean = swimmEditorServices.isIde && isCrossRepo;
            const smartElementInfo = convertSwmSnippetToSmartElement(
              ProseMirrorNode.fromJSON(editor.schema, node),
              swimmEditorServices.repoId.value,
              swimmEditorServices.branch.value,
              swimmEditorServices.repos.value.repos
            );

            // if the snippet is selected from cross repo in the IDE
            // we still show it as not-found
            // since this is the way we handle cross snippets in the IDE
            const smartElement: SmartElementWithApplicability<Snippet> = !isCrossRepoInIde
              ? {
                  ...smartElementInfo,
                  applicability: ApplicabilityStatus.Verified,
                  newInfo: smartElementInfo,
                }
              : {
                  ...smartElementInfo,
                  applicability: ApplicabilityStatus.Unavailable,
                };
            swimmEditorServices.autosyncOutput.value.smartElements.set(smartElement.id, smartElement);
            try {
              swimmEditorServices.external.trackEvent(productEvents.SNIPPET_ADDED_TO_DOC, {
                'Multi Repo': isCrossRepo,
              });
              void swimmEditorServices.external.setSmartElementCountInDB(
                swimmEditorServices.unitId.value,
                swimmEditorServices.repoId.value,
                swimmEditorServices.autosyncOutput.value
              );
            } catch (err) {
              logger.error(err, 'Failed to track event');
            }
          }

          return commands.insertContent(node);
        },
      insertSnippetOrCodeBlock:
        (snippet, path, line, repoId) =>
        ({ state, chain }) => {
          // insert the content as snippet if there is repo id, otherwise as code block
          const insertAtEnd =
            !(state.selection instanceof GapCursor) && !isParentOfType(state.doc, state.selection.head, ['paragraph']);

          if (repoId) {
            chain()
              .focus(insertAtEnd ? 'end' : undefined)
              .insertSwmSnippet(snippet, path, line, repoId)
              .run();
          } else {
            // no valid repo id means it was selected from non workspace repo or non repo
            // in the IDE and we display it as code block
            chain()
              .focus(insertAtEnd ? 'end' : undefined)
              .setCodeBlock()
              .insertContent(snippet)
              .run();
          }
          return true;
        },
      selectAndInsertSwmSnippets:
        (allowInsertAtEnd = false) =>
        ({ chain, dispatch, editor, state }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);

          if (!allowInsertAtEnd && !isParentOfType(state.doc, state.selection.head, ['paragraph'])) {
            return false;
          }

          if (!dispatch) {
            return chain().focus().insertSwmSnippet('', '', 1, swimmEditorServices.repoId.value).run();
          }

          setTimeout(() => {
            const snippetObserver = swimmEditorServices.external.selectSnippets();
            snippetObserver.subscribe({
              next(selectedSnippet) {
                if (selectedSnippet) {
                  const { snippet, path, line, repoId } = selectedSnippet;
                  editor.commands.insertSnippetOrCodeBlock(snippet, path, line, repoId);
                }
              },
              complete() {
                editor.chain().focus().run();
              },
            });
          }, 0);

          return true;
        },

      applySwmSnippetAutosync:
        (pos, snippet, userRequested = true) =>
        ({ chain, editor }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);

          if (!isSmartElementWithNewInfo(snippet)) {
            return false;
          }

          const node = editor.state.doc.nodeAt(pos);
          if (!node) {
            return false;
          }

          if (node.type !== this.type) {
            return false;
          }

          return (
            chain()
              .command(({ dispatch, tr }) => {
                if (dispatch) {
                  applySwmSnippetAutosync(tr, node, pos, snippet);
                }

                return true;
              })
              // The state is the state after the previous command has ran when actually running the commandss
              .command(({ dispatch, state }) => {
                if (dispatch) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  const newNode = state.doc.nodeAt(pos)!;
                  const smartElementInfo = convertSwmSnippetToSmartElement(
                    newNode,
                    swimmEditorServices.repoId.value,
                    swimmEditorServices.branch.value,
                    swimmEditorServices.repos.value.repos
                  );

                  const smartElement: SmartElementWithApplicability<Snippet> = {
                    ...smartElementInfo,
                    applicability: ApplicabilityStatus.Verified,
                    newInfo: smartElementInfo,
                  };
                  swimmEditorServices.autosyncOutput.value.smartElements.set(smartElement.id, smartElement);

                  if (userRequested) {
                    swimmEditorServices.animations.animateNodeById(smartElement.id);
                  }
                }

                return true;
              })
              .run()
          );
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      // Remove snippet when snippet comment is empty
      Backspace: () => {
        const { empty, $anchor } = this.editor.state.selection;
        const grandParent = $anchor.node($anchor.depth - 1);

        if (!empty || $anchor.parent.type.name !== 'paragraph' || grandParent.type.name !== this.name) {
          return false;
        }

        if (grandParent.content.size === 2) {
          return this.editor.commands.deleteNode(this.type);
        }

        return false;
      },
    };
  },
});
