import { firestoreCollectionNames } from '@swimm/shared';
import { type Ref, computed } from 'vue';
import { useStore } from 'vuex';

// store to get and fetch doc sub collections

export function useCurrentDocResources({
  repoId,
  docId,
}: {
  docId: Readonly<Ref<string>>;
  repoId: Readonly<Ref<string>>;
}) {
  const store = useStore();
  const swimmFromDb = computed(() => store.getters['database/db_getSwimm'](repoId.value, docId.value));
  const isDocInDb = computed(() => !!swimmFromDb.value);
  const docCreationDate = computed(() => swimmFromDb.value?.created);
  const docModifiedDate = computed(() => swimmFromDb.value?.modified);

  async function refreshDbData() {
    // refresh DB required for the doc
    // might need to refresh also assignments, watchers, repos and workspace users and repos
    if (isDocInDb.value) {
      await store.dispatch('database/refreshDocSubCollections', {
        repoId: repoId.value,
        unitId: docId.value,
        collectionsToRefresh: [
          firestoreCollectionNames.THANKS,
          firestoreCollectionNames.CONTRIBUTORS,
          firestoreCollectionNames.WATCHERS,
        ],
      });
    }
  }

  const resourceContributors = computed(() => {
    if (isDocInDb.value) {
      return store.getters['database/db_getContributors'](repoId.value, docId.value) || [];
    }
    return [];
  });

  async function incrementViewCount() {
    if (isDocInDb.value) {
      await store.dispatch('database/incrementResourceViews', {
        repoId: repoId.value,
        resourceId: docId.value,
        type: firestoreCollectionNames.SWIMMS,
      });
    }
  }

  const resourceViews = computed(() => {
    if (isDocInDb.value) {
      return (
        store.getters['database/db_getResourceViews'](repoId.value, docId.value, firestoreCollectionNames.SWIMMS) || 0
      );
    }
    return 0;
  });

  return {
    refreshDbData,
    resourceContributors,
    incrementViewCount,
    resourceViews,
    docCreationDate,
    docModifiedDate,
  };
}
