// @ts-strict

import {
  ApplicabilityStatus,
  Path,
  SmartElementType,
  SmartElementWithApplicability,
  SmartSymbol,
  SwmSymbol,
  SwmSymbolPath,
  SwmSymbolType,
  SymbolPlugin,
  config,
  gitwrapper,
} from '@swimm/shared';
import { getSmartElementWithApplicability } from '../swimmagic-common';

export class SymbolPath implements SymbolPlugin {
  symbols: Path[] = [];

  static isSymbolPath(symbol: SwmSymbol): symbol is SwmSymbolPath {
    return symbol.type === SwmSymbolType.PATH;
  }

  static isSmartSymbolPath(symbol: SmartSymbol): symbol is Path {
    return symbol.type === SmartElementType.PATH;
  }

  constructor(symbols: SmartSymbol[]) {
    for (const symbol of Object.values(symbols)) {
      if (SymbolPath.isSmartSymbolPath(symbol)) {
        this.symbols.push(symbol);
      }
    }
  }

  static async updatePathSymbol(symbol: Path): Promise<SmartElementWithApplicability<Path>> {
    const currentNameResult = await gitwrapper.getCurrentName({
      oldFilePath: symbol.filePath,
      repoId: symbol.gitInfo.repoId,
      destCommit: symbol.gitInfo.branch,
    });

    if (currentNameResult.code === config.ERROR_RETURN_CODE || !currentNameResult.exists) {
      return getSmartElementWithApplicability<Path>({
        element: symbol,
        applicabilityStatus: ApplicabilityStatus.Outdated,
      });
    }

    return getSmartElementWithApplicability<Path>({
      element: symbol,
      applicabilityStatus: currentNameResult.isRenamed
        ? ApplicabilityStatus.Autosyncable
        : ApplicabilityStatus.Verified,
      newInfo: {
        ...symbol,
        filePath: currentNameResult.currentName,
        symbolText: currentNameResult.currentName,
        isDirectory: currentNameResult.isDirectory,
      },
    });
  }

  public async updateSymbols(): Promise<{
    isApplicable: boolean;
    symbolsWithApplicability: SmartElementWithApplicability<SmartSymbol>[];
  }> {
    let noSymbolOutdated = true;
    const pathsWithApplicability: SmartElementWithApplicability<Path>[] = [];
    const updateSymbolPromises: Promise<void>[] = [];
    for (const smartPath of this.symbols) {
      const updatePathSymbolPromise = async () => {
        const pathWithApplicability = await SymbolPath.updatePathSymbol(smartPath);
        if (pathWithApplicability.applicability === ApplicabilityStatus.Outdated) {
          noSymbolOutdated = false;
        }
        pathsWithApplicability.push(pathWithApplicability);
      };
      updateSymbolPromises.push(updatePathSymbolPromise());
    }
    await Promise.allSettled(updateSymbolPromises);
    return { isApplicable: noSymbolOutdated, symbolsWithApplicability: pathsWithApplicability };
  }

  public async verifySymbols(): Promise<boolean> {
    const symbolsVerificationResults = await Promise.all(
      this.symbols.map(
        async (symbol) =>
          await gitwrapper.isFileExistsOnRevision({
            filePath: symbol.filePath,
            repoId: symbol.gitInfo.repoId,
            revision: symbol.gitInfo.branch,
          })
      )
    );
    return symbolsVerificationResults.every((verificationResult) => verificationResult);
  }
}
