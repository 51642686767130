export enum ReleaseNotesItemType {
  NEW = 'new',
  IMPROVEMENTS = 'improvements',
}

export enum ReleaseNotesVideoProvider {
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
}

interface Item {
  title: string;
  description: string;
  image?: string;
  videoId?: string;
  videoProvider?: ReleaseNotesVideoProvider;
  type: string;
  learnMore?: string;
}

// Limit number of release items by defining the array type as a tuple. Wrong number of items is caught during compilation.
type ReleaseNotesType = readonly [Item, Item?, Item?, Item?, Item?, Item?, Item?, Item?];
// FIFO
export const releaseNotesItems: ReleaseNotesType = [
  {
    title: 'Generate diagrams with AI',
    description: 'Add code to the document and generate a diagram from it in one click!',
    image: 'ai-mermaid.png',
    type: ReleaseNotesItemType.NEW,
  },
  {
    title: 'Add Definition as Snippet',
    description:
      'Quickly add code definitions from existing snippets. Simply hover over a function name and insert its definition directly into your doc with just another click.',
    image: 'add-def-as-snippet.png',
    type: ReleaseNotesItemType.NEW,
  },
  {
    title: 'Autocompletion for Docs',
    description:
      'Speed up your documentation workflow with our new Autocompletion feature! Get suggestions as you type, making your editing more efficient than ever.',
    image: 'autocompletion-for-docs.png',
    type: ReleaseNotesItemType.NEW,
  },
  {
    title: 'Create doc with AI, now in the IDE',
    description:
      'Beat the blank canvas with AI doc generation from your current branch changes, or from code snippets, all within your IDE.',
    image: 'ai-ide-snippets2doc.png',
    type: ReleaseNotesItemType.NEW,
  },
  {
    title: 'Drag to add/remove code lines',
    description:
      'Missing a code line or two in your added snippet? Simply drag the edge of the added snippets to reveal them or remove some lines.',
    image: 'drag-edit-snippets.png',
    type: ReleaseNotesItemType.IMPROVEMENTS,
  },
  {
    title: 'Ask your team for help with code questions!',
    description:
      'Share Ask Swimm results with your teammates - they’ll be able to fill in any missing information by writing a document.',
    image: 'ask-team.png',
    type: ReleaseNotesItemType.NEW,
  },
  {
    title: '/ask Swimm - contextual code chat',
    description:
      '/ask Swimm uses your context from Swimm docs and static analysis of your code to accurately answer your questions. Available on our Enterprise plans.',
    image: 'ask-ai.png',
    type: ReleaseNotesItemType.NEW,
    learnMore: 'https://swimm.io/pricing',
  },
] as const;
