import { isProduction, isStage } from '@/config';

export const UserRole = {
  ADMIN: 'Admin',
  MEMBER: 'Member',
};

export const NoWorkspaceRoutePrefix = 'no-workspace ';

export const NoBranchRoutePrefix = 'no-branch ';

export const RepoPageRouteNames = {
  DOCUMENTATIONS: 'documentations',
  PULL_REQUEST: 'pulls',
  NEEDS_REVIEW: 'status',
  DOC_REQUESTS: 'doc-requests',
  RULES: 'rules',
};

export const PageRoutesNames = {
  PLAYLIST_NEW: 'New Playlist',
  PLAYLIST_EDIT: 'Edit Playlist',
  PLAYLIST_VIEW: 'View Playlist',
  DOC_NEW: 'New Document',
  DOC_EDIT: 'Edit Document',
  DOC_VIEW: 'View Document',
  DOC_ONBOARDING: 'OnboardingDoc',
  CLOUD_DOC_EDIT: 'Edit Cloud Doc',
  CLOUD_DOC_VIEW: 'View Cloud Doc',
  REPO_PAGE: 'Repo Page',
  EVERYTHING_PAGE: 'Everything Page',
  HOME_PAGE: 'Home Page',
  CLOUD_DOCS_PAGE: 'Cloud Docs Page',
  SHARED_DOCS_PAGE: 'Shared Docs Page',
  SHARED_DOC_PAGE: 'Shared Doc Page',
  REQUEST_INVITE_PAGE: 'Request Invite Page',
  ONBOARDING_WELCOME: 'Onboarding Welcome',
  ONBOARDING: 'Onboarding',
  CREATE_WORKSPACE: 'Create Workspace',
  JOIN_WORKSPACE: 'Join Workspace',
  ACCOUNT_READY: 'Account Ready',
  SSO: 'SSO Login',
  SetOktaToken: 'Set Okta Token',
  SetOIDCToken: 'Set OIDC Token',
  NO_ACCESS: 'No access',
};

export const RepoPageDocumentationSectionTypes = {
  DOCS: 'docs',
  PLAYLISTS: 'playlists',
  EXERCISES: 'exercises',
  DOC_REQUESTS: 'doc_requests',
};

export const HomePageRouteNames = {
  SUMMARY: 'summary',
  STATISTICS: 'statistics',
  PAYWALL: 'paywall',
};

export const SwimmContentStatuses = {
  ALL: 'all',
  COMMITTED: 'committed',
};

export const MOBILE_MAX_WIDTH = 800;

export const GITHUB_APP_SETTINGS_LINK = isProduction
  ? 'https://github.com/apps/swimm-io'
  : isStage
  ? 'https://github.com/apps/swimm-io-staging'
  : 'https://github.com/apps/swimm-io-dev';

export const UNKNOWN_USER_NAME = 'Unknown user';

export const localStorageKeys = {
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_MEDIUM: 'utm_medium',
  UTM_SOURCE: 'utm_source',
  REFERRAL: 'referral',
  SHOULD_SHOW_DOC_SIDEBAR: 'should_show_doc_sidebar',
  SHOULD_OPEN_GET_STARTED: 'should_open_get_started',
  SLACK_TOAST_UNSEEN: 'slack_toast_unseen',
  USER_CREATED_FIRST_DRAFT: 'user_created_first_draft',
  PR_CREATION_TIP_SHOWN: 'pr_creation_tip_shown',
  PR_OUTDATED_TIP_SHOWN: 'pr_outdated_tip_shown',
  USER_CLICKED_IN_APP_GUIDANCE: 'user_clicked_in_app_guidance',
  DISMISSED_HOME_SUGGESTION: 'dismissed_home_suggestions',
} as const;

export const DocumentationTypes = {
  PLAYLIST: 'playlist',
  EXERCISE: 'exercise',
  DOC: 'document',
  DOC_REQUEST: 'doc_request',
  FOLDER: 'folder',
} as const;

export const GOOGLE_PROVIDER_NAME = 'google.com';
export const SSO_PROVIDER_SUFFIX = 'oidc.sso-';
export const GOOGLE_DOMAIN_NAME = 'gmail.com';

export const PlaylistMenuOptions = {
  blank_doc: 'Blank doc',
  existing_doc: 'Existing docs or playlists',
  external_link: 'External links',
  other_options: 'Other options',
} as const;

export const recommendationCategories = {
  LOW_CONTRIBUTOR_PERCENTAGE: 'low percentage of contributors',
};
