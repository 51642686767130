import { type TokenSuggestion, iter } from '@swimm/shared';
import type { Ref } from 'vue';

export type TokenSuggestionsMap = Map<string, TokenSuggestion[]>;

export interface StaticAnalysisIndex {
  load: (repoId: string, options?: { force?: boolean }) => Promise<TokenSuggestion[]>;
  get: (repoId: string) => { loading: boolean; suggestions: TokenSuggestion[] };
  getAsyncMap: (repoId: string) => Promise<TokenSuggestionsMap>;
  clear: () => void;
  suggestionsBuildTimestamp: Ref<number>;
}

export function toTokenSuggestionsMap(suggestions: TokenSuggestion[]): TokenSuggestionsMap {
  return iter.groupBy(suggestions, (suggestion) => suggestion.token);
}
