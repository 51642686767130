import CryptoJS from 'crypto-js';

export function encryptMessage(messageToEncrypt, secretKey) {
  if (!secretKey) {
    throw new TypeError('secretKey must be a non-empty string');
  }
  const encryptedMessage = CryptoJS.AES.encrypt(JSON.stringify(messageToEncrypt), secretKey);
  return encryptedMessage.toString();
}

export function decryptMessage(encryptedMessage, secretKey, logger) {
  if (!secretKey) {
    throw new TypeError('secretKey must be a non-empty string');
  }
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedMessage, secretKey);
  try {
    const decryptedMessage = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedMessage);
  } catch (error) {
    logger.warn(`Failed to parse JSON from decrypted message: ${error}`, { service: 'decrypt-message' });
    return null;
  }
}
