<template>
  <div class="doc-wrapper">
    <template v-if="!!swimmDocument">
      <ResourceHeadline
        v-if="docTitle"
        :resource-type="resourceType"
        :resource-name="docTitle"
        :resource-creator="''"
      />
    </template>
    <Loader v-if="!swimmDocument" class="content-loader info-container-diff content-padding-wrapper" />
    <div v-else class="info-container-diff content-padding-wrapper">
      <SwmdEditor
        :editable="false"
        :env-kind="EditorEnvKind.WEBAPP"
        :external-services="new WebPreviewSwimmExternalServices(serviceOptions)"
        :base-url="baseUrl"
        :repos="{ loading: false, repos: [] }"
        :is-authorized="true"
        :workspace-id="workspaceId"
        :repo-id="repoId"
        :branch="branch"
        :unit-id="templateId"
        v-model="swimmDocument.content"
        no-padding
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { type Ref, computed, ref, watch } from 'vue';
import { EditorEnvKind } from '@swimm/editor';
import { type SwimmDocument, ThemeOptions, config } from '@swimm/shared';
import { useRoute } from 'vue-router';
import { provide } from 'vue';
import { storeToRefs } from 'pinia';
import { SwmdEditor, parseSwmd } from '@swimm/swmd';
import { WebPreviewSwimmExternalServices } from '@/modules/editor3/previewExternalServices';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';

export default {
  components: { SwmdEditor },
  props: {
    content: { type: String, required: true },
    templateId: { type: String, required: true },
    origin: {
      type: String,
      default: '',
      validator: (value) => !value || ['template'].includes(value as string),
    },
    workspaceId: { type: String, default: '' },
    repoId: { type: String, required: false, default: null },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const branch = computed(() => route.params.branch as string);
    provide('isPreview', true);
    const { theme } = storeToRefs(useUserConfigStore());
    const repo = computed(() => (props.repoId ? store.getters['database/db_getRepoMetadata'](props.repoId) : null));
    const swimmDocument: Ref<SwimmDocument> = ref();
    const baseUrl = config.BASE_URL;
    const isDarkMode = computed(() => theme.value === ThemeOptions.DARK);
    const serviceOptions = computed(() => ({
      isDarkMode: isDarkMode,
    }));
    watch(
      () => props.content,
      (content) => {
        swimmDocument.value = parseSwmd(content, {
          legacy: {
            baseUrl,
            workspaceId: props.workspaceId /* ... */,
            repoId: props.repoId,
            repoName: repo.value?.name,
            repos: repo.value
              ? [
                  {
                    ...repo.value,
                    branch: branch.value,
                  },
                ]
              : [],
          },
        });
      },
      { immediate: true }
    );

    return {
      branch,
      baseUrl,
      EditorEnvKind,
      WebPreviewSwimmExternalServices,
      swimmDocument,
      serviceOptions,
    };
  },
  computed: {
    resourceType() {
      return this.origin ? this.origin.toUpperCase() : 'DOC';
    },
    docTitle() {
      return this.swimmDocument?.title;
    },
  },
};
</script>
<style scoped>
.content-text {
  margin: auto;
  max-width: var(--narrow-content-width);
}

.info-container-diff .hunk:not(:last-of-type),
.info-container-diff .image-block {
  margin-bottom: 40px;
}

.doc-wrapper {
  display: flex;
  align-items: center;
  overflow: auto;
  flex-direction: column;
  flex: 1;
  padding: var(--space-sm);
}

.info-container-diff {
  align-items: center;
  width: 100%;
  max-width: var(--narrow-content-width);
  font-size: var(--body-L);
  line-height: var(--fontsize-ml);
}

.hunk-placeholder {
  margin-bottom: 40px;
}

.loader.content-loader {
  min-height: 50vh;
}

.table-block {
  margin-left: -20px;
}
</style>
