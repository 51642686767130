<template>
  <li class="item"><Icon :name="getIcon" :class="['icon', isValid ? 'valid' : 'invalid']" /><slot /></li>
</template>

<script>
export default {
  props: {
    isValid: { type: Boolean, required: true },
  },
  computed: {
    getIcon() {
      return this.isValid ? 'check' : 'close';
    },
  },
};
</script>

<style scoped>
.icon {
  padding: 0 4px 0 0;
}

.valid {
  color: var(--text-color-success);
}

.invalid {
  color: var(--oh-no-red-500);
}
</style>
