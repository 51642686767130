import JoinWorkspaceNotification from '@/modules/notifications-center/components/JoinWorkspaceNotification.vue';
import AssignmentNotification from '@/modules/notifications-center/components/AssignmentNotification.vue';
import ThanksNotification from '@/modules/notifications-center/components/ThanksNotification.vue';
import SlackInstalledNotification from '@/modules/notifications-center/components/SlackInstalledNotification.vue';
import DocRequestCreatedNotification from '@/modules/notifications-center/components/DocRequestCreatedNotification.vue';
import DocRequestCompletedNotification from '@/modules/notifications-center/components/DocRequestCompletedNotification.vue';
import RequestedJoinWorkspaceNotification from '@/modules/notifications-center/components/RequestedJoinWorkspaceNotification.vue';
import DocUpdatedNotification from '@/modules/notifications-center/components/DocUpdatedNotification.vue';
import UserJoinedWorkspaceNotification from '@/modules/notifications-center/components/UserJoinedWorkspaceNotification.vue';
import UserLeftWorkspaceNotification from '@/modules/notifications-center/components/UserLeftWorkspaceNotification.vue';
import UserAcceptedInviteNotification from '@/modules/notifications-center/components/UserAcceptedInviteNotification.vue';
import PrBreaksCrossRepoNotification from '@/modules/notifications-center/components/PrBreaksCrossRepoNotification.vue';
import GitHubAppSuspensionNotification from '@/modules/notifications-center/components/GitHubAppSuspensionNotification.vue';
import FirstDocViewNotification from '@/modules/notifications-center/components/FirstDocViewNotification.vue';
import MultipleDocViewsNotification from '@/modules/notifications-center/components/MultipleDocViewsNotification.vue';
import StiggQuota90Reached from '@/modules/notifications-center/components/StiggQuota90Reached.vue';
import StiggQuotaReached from '@/modules/notifications-center/components/StiggQuotaReached.vue';
import ShareQuestiontNotification from '../components/ShareQuestiontNotification.vue';
import ShareQuestiontAnsweredNotification from '../components/ShareQuestiontAnsweredNotification.vue';
import SignupWithEmailPasswordFailedNotification from '../components/SignupWithEmailPasswordFailedNotification.vue';

export const NOTIFICATION_COMPONENTS = {
  JoinWorkspaceNotification,
  AssignmentNotification,
  ThanksNotification,
  SlackInstalledNotification,
  DocRequestCreatedNotification,
  DocRequestCompletedNotification,
  RequestedJoinWorkspaceNotification,
  DocUpdatedNotification,
  UserJoinedWorkspaceNotification,
  UserLeftWorkspaceNotification,
  UserAcceptedInviteNotification,
  PrBreaksCrossRepoNotification,
  GitHubAppSuspensionNotification,
  FirstDocViewNotification,
  MultipleDocViewsNotification,
  StiggQuota90Reached,
  StiggQuotaReached,
  ShareQuestiontNotification,
  ShareQuestiontAnsweredNotification,
  SignupWithEmailPasswordFailedNotification,
};
