<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.75 5.75C5.75 4.50736 6.75736 3.5 8 3.5H13.2V8.48993C13.2 8.90414 13.5358 9.23993 13.95 9.23993H18.4489C18.4496 9.26303 18.45 9.28616 18.45 9.30933V13.75C18.45 14.1642 18.7858 14.5 19.2 14.5C19.6142 14.5 19.95 14.1642 19.95 13.75V9.30933C19.95 8.45248 19.6566 7.62147 19.1185 6.95461L16.2468 3.39528C15.5349 2.51292 14.462 2 13.3283 2H8C5.92893 2 4.25 3.67893 4.25 5.75V18.0071C4.25 20.0782 5.92893 21.7571 8 21.7571H13.5C13.9142 21.7571 14.25 21.4214 14.25 21.0071C14.25 20.5929 13.9142 20.2571 13.5 20.2571H8C6.75736 20.2571 5.75 19.2498 5.75 18.0071V5.75ZM17.8248 7.73993L15.0794 4.33717C14.9669 4.1977 14.8393 4.07361 14.7 3.96648V7.73993H17.8248ZM11.15 14.9999C11.15 15.4142 11.4858 15.7499 11.9 15.7499C12.3142 15.7499 12.65 15.4142 12.65 14.9999V13.2499H14.4C14.8142 13.2499 15.15 12.9142 15.15 12.4999C15.15 12.0857 14.8142 11.7499 14.4 11.7499H12.65V9.99994C12.65 9.58573 12.3142 9.24994 11.9 9.24994C11.4858 9.24994 11.15 9.58573 11.15 9.99994V11.7499H9.39999C8.98578 11.7499 8.64999 12.0857 8.64999 12.4999C8.64999 12.9142 8.98578 13.2499 9.39999 13.2499H11.15V14.9999ZM8.64999 17.4999C8.64999 17.0857 8.98578 16.7499 9.39999 16.7499H14.4C14.8142 16.7499 15.15 17.0857 15.15 17.4999C15.15 17.9142 14.8142 18.2499 14.4 18.2499H9.39999C8.98578 18.2499 8.64999 17.9142 8.64999 17.4999Z"
      fill="var(--text-color-secondary)"
    />
    <ellipse cx="19.6" cy="19.6" rx="3.6" ry="3.6" fill="var(--text-color-warning-strong)" />
  </svg>
</template>
