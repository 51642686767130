<template>
  <VDropdown
    :shown="tooltipShown"
    class="passwordValidatorTooltip"
    :triggers="[]"
    :placement="placement"
    offset="8"
    delay="100"
    :auto-hide="false"
  >
    <slot :show="onFocus" :hide="onBlur" />
    <template #popper>
      <ul class="list">
        <PasswordFieldValidatorItem :is-valid="isMinChars">Minimum 8 characters</PasswordFieldValidatorItem>
        <PasswordFieldValidatorItem :is-valid="isUppercase">1 Uppercase</PasswordFieldValidatorItem>
        <PasswordFieldValidatorItem :is-valid="isLowercase">1 Lowercase</PasswordFieldValidatorItem>
        <PasswordFieldValidatorItem :is-valid="isDigit">1 Digit</PasswordFieldValidatorItem>
      </ul>
    </template>
  </VDropdown>
</template>

<script>
import { MOBILE_MAX_WIDTH } from '@/common/consts';
import PasswordFieldValidatorItem from '@/common/components/Auth/PasswordFieldValidatorItem.vue';

const MIN_PASSWORD_CHARACTERS = 8;

export default {
  components: { PasswordFieldValidatorItem },
  props: {
    password: { type: String, required: true },
  },
  data() {
    return {
      tooltipShown: false,
    };
  },
  computed: {
    placement() {
      const isMobile = screen.width <= MOBILE_MAX_WIDTH;
      return isMobile ? 'top' : 'right';
    },
    isMinChars() {
      return this.password.length >= MIN_PASSWORD_CHARACTERS;
    },
    isLowercase() {
      return /[a-z]/.test(this.password);
    },
    isUppercase() {
      return /[A-Z]/.test(this.password);
    },
    isDigit() {
      return /\d/.test(this.password);
    },
  },
  methods: {
    onFocus() {
      this.tooltipShown = true;
    },
    onBlur() {
      this.tooltipShown = false;
    },
  },
};
</script>

<style scoped>
.list {
  margin: 0;
  list-style-type: none;
  padding: 10px;
  border-radius: 8px;
  background-color: var(--color-surface);
}

.passwordValidatorTooltip :deep(.v-popper .trigger) {
  /* override inline style of v-popper */
  display: block !important;
}
</style>
