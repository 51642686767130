<template>
  <div
    class="resource-headline content-padding-wrapper"
    :class="{ 'resource-headline-big': !isSmall, 'resource-headline-small': isSmall }"
  >
    <ResourceHeadlineStatus
      :resource-repo="resourceRepo"
      :resource-type="resourceType"
      :resource-status="resourceStatus"
    />
    <SwText variant="headline1" v-if="!isEdit" class="resource-title headline1" data-testid="title">{{
      resourceName
    }}</SwText>
    <TextField
      v-else
      class="unit-name"
      :border="false"
      placeholder="Untitled"
      headline
      required
      :model-value="resourceName"
      @update:model-value="onResourceNameChanged"
    />
    <ResourceHeadlineInfo :resource-contributors="resourceContributors" :resource-views="resourceViews">
      <slot />
    </ResourceHeadlineInfo>
  </div>
</template>

<script setup>
import ResourceHeadlineInfo from '@/common/components/organisms/ResourceHeadlineInfo.vue';
import ResourceHeadlineStatus from '@/common/components/organisms/ResourceHeadlineStatus.vue';
import { SwText, TextField } from '@swimm/ui';

defineProps({
  isEdit: { type: Boolean, default: false, required: false },
  resourceName: { type: String, default: '' },
  resourceType: { type: String, required: false, default: '' },
  resourceStatus: { type: String, default: '' },
  resourceCreator: { type: String, required: false, default: '' },
  resourceContributors: { type: Array, required: false, default: () => [] },
  resourceInactive: { type: Boolean, required: false, default: false },
  resourceRepo: {
    type: Object,
    name: { type: String },
    icon: { type: String },
    imgSrc: { type: String },
    default: null,
  },
  repoId: { type: String, default: '' },
  resourceId: { type: String, default: '' },
  isSmall: { type: Boolean, default: false },
  branch: { type: String, required: false, default: '' },
  resourceViews: { type: Number, required: false, default: -1 },
});

const emit = defineEmits(['resource-name-changed']);

function onResourceNameChanged(newName) {
  emit('resource-name-changed', newName);
}
</script>

<style scoped>
.resource-headline {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}

.resource-headline-big {
  max-width: var(--narrow-content-width);
}

.resource-headline-small {
  /* put 650 since sidebar is 288 and left panel can be 350) */
  max-width: min(var(--narrow-content-width), 100vw - 650px);
}

.resource-title {
  padding: 0px;
}
</style>
