import {
  FirestoreFieldValue,
  collectionNames,
  getDocFromCollection,
  getDocFromSubCollection,
  setValuesInDoc,
  updateDocInSubCollection,
} from '@/adapters-common/firestore-wrapper';
import { config, getLoggerNew } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export interface WorkspaceUser {
  external_uid: string;
  uid?: string;
  email: string;
  favoriteRepoIds?: Array<string>;
  recentRepoIds?: Array<string>;
}

export async function getWorkspaceFromDB(workspaceId: string) {
  const response = await getDocFromCollection(collectionNames.WORKSPACES, workspaceId);
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error getting workspace ${workspaceId} from DB, error: ${response.errorMessage}`);
    return {};
  }
  return response.data;
}

export async function getWorkspaceUserFromDB(workspaceId: string, userId: string): Promise<WorkspaceUser> {
  const response = await getDocFromSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.WORKSPACE_USERS,
    userId
  );
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error getting user ${userId} on workspace ${workspaceId} from DB, error: ${response.errorMessage}`);
    return null;
  }

  return response.data as WorkspaceUser;
}

export async function addRepoToWorkspaceUserFavorites(workspaceId: string, userId: string, repoId: string) {
  const response = await updateDocInSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.WORKSPACE_USERS,
    userId,
    { favoriteRepoIds: FirestoreFieldValue.arrayUnion(repoId) }
  );

  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(
      `Error adding repo ${repoId} to favorites for user ${userId} workspace ${workspaceId} in DB, error: ${response.errorMessage}`
    );
  }
}

export async function removeRepoFromWorkspaceUserFavorites(workspaceId: string, userId: string, repoId: string) {
  const response = await updateDocInSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.WORKSPACE_USERS,
    userId,
    { favoriteRepoIds: FirestoreFieldValue.arrayRemove(repoId) }
  );

  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(
      `Error removing repo ${repoId} from favorites for user ${userId} workspace ${workspaceId} in DB, error: ${response.errorMessage}`
    );
  }
}

export async function updateWorkspaceUserRecentRepos(workspaceId: string, userId: string, recentRepoIds: string[]) {
  const response = await updateDocInSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.WORKSPACE_USERS,
    userId,
    { recentRepoIds }
  );

  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(
      `Error updating recent repos for user ${userId} on workspace ${workspaceId} in DB, error: ${response.errorMessage}`
    );
  }
}

export async function updateWorkspaceOnFirestore(
  workspaceId: string,
  data: { [key: string]: string | boolean | number | object }
): Promise<boolean> {
  const response = await setValuesInDoc(collectionNames.WORKSPACES, workspaceId, data, { merge: true });

  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error updating workspace ${workspaceId} in DB, error: ${response.errorMessage}`);
    return false;
  }
  return true;
}
