<template>
  <div class="wrapper">
    <div class="authorization-wrapper">
      <div class="connection-wrapper">
        <img v-if="isVscode" class="logo-vscode" src="@/assets/vscode-icon.svg" />
        <img v-else-if="isJetbrains" class="logo-intellij" src="@/assets/Jetbrains-logo.svg" />
        <img v-if="isVscode || isJetbrains" class="logo connection-logo" src="@/assets/connection-icon.svg" />
        <img class="logo logo-swimm" src="@/assets/swimm-wave.svg" />
      </div>
      <div class="authorize-title">Authorize {{ getIdeName }} to access Swimm</div>
      <div class="authorize-info">
        If you initiated this authorization from {{ getIdeName }}, click 'Continue' to authorize access to Swimm
      </div>
      <Action button-type="button" :disabled="clicked" @click="redirectToIDE">Continue</Action>
      <div class="cancel-link" @click="closeWindow">Do not authorize</div>
    </div>
  </div>
</template>

<script>
import { hostUrl } from '@/config';
import { getLoggerNew } from '@swimm/shared';
import { pageEvents, productEvents } from '@swimm/shared';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { GetStartedStepIds } from '@/modules/get-started/consts';
import { useGetStartedMenu } from '@/modules/get-started/composables/useGetStartedMenu';
import { useAnalytics } from '@/common/composables/useAnalytics';

const logger = getLoggerNew(__modulename);
const vscodeName = 'Visual Studio Code';
const jetbrainsName = 'JetBrains';
const ideName = 'your IDE';

export default {
  props: {
    ide: { type: String, default: 'vscode' },
  },
  setup(props) {
    const route = useRoute();

    const isVscode = computed(() => {
      return props.ide === 'vscode';
    });

    const { markStepAsDone } = useGetStartedMenu();

    // we mark here the installed ide plugin step as done (in firestore: user.get_started_steps.insatllIdePlugin: true)
    // this is used in the backoffice, so don't delete or change this
    markStepAsDone(GetStartedStepIds.INSTALL_IDE_PLUGIN);

    const isJetbrains = computed(() => {
      return props.ide === 'intelliJ';
    });

    const getIdeName = computed(() => {
      return isVscode.value ? vscodeName : isJetbrains.value ? jetbrainsName : ideName;
    });

    const clicked = ref(false);

    const analytics = useAnalytics();

    analytics.pageVisit(pageEvents.VIEW_IDE_LOGIN_PAGE, {
      IDE: getIdeName.value,
    });

    function redirectToIDE() {
      if (clicked.value) {
        return;
      }
      clicked.value = true;
      analytics.track(productEvents.IDE_LOGIN_CLICKED_CONTINUE, {
        IDE: getIdeName.value,
      });
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          const searchParams = new URLSearchParams();
          searchParams.set('token', idToken);
          searchParams.set('state', route.query.state);
          searchParams.set('redirectUrl', route.query.redirectUrl);
          const ideRedirectUrl = `${hostUrl}/ide-authenticate`;
          const cloudUrl = `${ideRedirectUrl}?${searchParams.toString()}`;
          window.location.href = cloudUrl;
        })
        .catch((err) => {
          logger.error({ err }, `Failed to getIdToken for IDE redirecting. ${err}`);
        });
    }

    function closeWindow() {
      analytics.track(productEvents.IDE_LOGIN_CLICKED_DO_NOT_AUTHORIZED, {
        IDE: getIdeName.value,
      });
      window.close();
    }

    return {
      redirectToIDE,
      closeWindow,
      isVscode,
      isJetbrains,
      vscodeName,
      jetbrainsName,
      getIdeName,
      clicked,
    };
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: var(--color-bg);
}

.authorization-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 25vh;
  height: 50vh;
  text-align: center;
  background-color: var(--color-bg);
}

.authorize-title {
  font-size: var(--headline1);
  color: var(--text-color-primary);
}

.authorize-info {
  font-size: var(--body-L);
  color: var(--text-color-primary);
}

.cancel-link {
  color: var(--text-color-link);
  cursor: pointer;
}

.connection-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.connection-logo {
  margin: 0 20px;
}

.logo-swimm {
  height: 56px;
}

.logo-intellij {
  height: 72px;
  width: 72px;
}

.logo-vscode {
  height: 64px;
  width: 64px;
}
</style>
