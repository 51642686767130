<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  date: { type: Date, required: true },
});

const dateString = computed<string>(() => {
  if (!props.date) {
    return '';
  }
  return props.date.toLocaleDateString([], {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
});

const dateTooltip = computed<string>(() => {
  if (!props.date) {
    return '';
  }
  return `${props.date.toLocaleDateString([], {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  })} ${props.date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
});
</script>

<template>
  <span v-tooltip="dateTooltip">{{ dateString }}</span>
</template>
