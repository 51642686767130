<script setup>
import { Icon, Tag } from '@swimm/ui';

const props = defineProps({
  title: { type: String, default: '' },
  subtitle: { type: String, default: '' },
  icon: { type: String, default: '' },
  isSelected: { type: Boolean, default: false },
  learnMoreLink: { type: String, default: '' },
  isBeta: { type: Boolean, default: false },
  isAi: { type: Boolean, default: false },
});

defineEmits(['select']);

function goToLearnMoreLink() {
  window.open(props.learnMoreLink, '_blank');
}
</script>

<template>
  <div :class="['creation-hub-item', { selected: isSelected }]" @click="$emit('select')">
    <Icon v-if="icon" :name="icon" class="icon" />
    <slot>
      <div class="text-container">
        <div class="title subtitle-L">
          {{ title }}
          <Tag v-if="isAi" text="AI" class="ai-tag" tag-style="beta" />
          <Icon
            v-if="learnMoreLink"
            class="info-link"
            name="info-outline"
            no-padding
            v-tooltip="'Learn how to import'"
            @click.stop="goToLearnMoreLink"
          />
        </div>
        <div class="subtitle body-XS">{{ subtitle }}</div>
      </div>
    </slot>
    <Tag v-if="!isSelected && isBeta" text="BETA" class="beta-tag" tag-style="beta" />
  </div>
</template>

<style scoped lang="postcss">
.creation-hub-item {
  padding: 6px 16px;
  display: flex;
  align-items: center;
  background-color: var(--color-bg);
  position: relative;

  &:hover {
    background-color: var(--color-hover);
  }

  &.selected {
    background-color: var(--color-selected);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      background-color: var(--color-brand);
      width: 4px;
      height: 100%;
    }
  }

  .icon {
    font-size: var(--headline2);
    padding: 0;
    margin-right: 8px;
  }

  .text-container {
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 0;

    .title {
      color: var(--text-color-primary);
      font-weight: bold;
      display: flex;
      align-items: center;

      .info-link {
        margin-left: 4px;
        font-size: var(--body-S);
        font-weight: 400;

        &:hover {
          color: var(--color-brand);
        }
      }
    }

    .subtitle {
      color: var(--text-color-secondary);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .ai-tag {
    margin-left: var(--space-base);
  }

  .beta-tag {
    border-radius: 4px;
    color: var(--text-color-link);
    background-color: var(--color-status-info);
  }
}
</style>
