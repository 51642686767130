import {
  MARKDOWN_PR_SUFFIX,
  SWAL_BITBUCKET_DC_DELETE,
  SWAL_CONTACT_US_CONTENT,
} from '@/common/utils/common-definitions';
import swal from 'sweetalert';
import { DocumentationTypes, RepoPageRouteNames } from '../consts';
import { useStore } from 'vuex';
import { type SwmResourceFile, SwmResourceState, config, gitwrapper, productEvents, slugify } from '@swimm/shared';
import { useRoute, useRouter } from 'vue-router';
import { useAnalytics } from './useAnalytics';
import { clearDismissedSuggestionsForTempDocument } from '@/modules/swimmport/services/swimmport-local-db';
import { getLoggerNew, shortUuid, toValidBranchName } from '@swimm/shared';
import { getRepoPath } from '@/router/router-utils';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useGlobalModalStore } from '@/modules/core/modals/stores/modals-store';
import { type PushData } from '@/modules/batch-commit/store/batch-commit';
import { deleteSharedDoc } from '@/modules/cloud-docs/cloud-doc-utils';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { type Draft } from '@/modules/drafts3/db';
import { useAppLinks } from '@/modules/core/compositions/app-links';
import { useNotificationsStore } from '@swimm/editor';

interface Documentation {
  name: string;
  id: string;
  path: string;
  documentationType: string;
}

export function useDeleteItem() {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const { addNotification } = useNotificationsStore();

  const { closeModal, openModal } = useGlobalModalStore();

  const drafts3Store = useDrafts3Store();
  const analytics = useAnalytics();
  const logger = getLoggerNew(__modulename);

  const deleteLocalSwimm = (params) => store.dispatch('filesystem/deleteLocalSwimm', params);
  const { user } = storeToRefs(useAuthStore());
  const { getAppLink } = useAppLinks();
  function sendDeletionCloudTrack(documentation, pushData: PushData) {
    // todo: Multi Repo was removed
    const productAnalyticsData = {
      'Document ID': documentation.id,
      'Document Name': documentation.name,
      'Save Date': new Date().toISOString(),
      'Save Type': 'delete',
      'From Branch': pushData.branch,
      'To Branch': pushData.prBranch || pushData.branch,
      workspaceId: route.params.workspaceId,
      Context: 'Repo',
    };
    analytics.cloudTrack({
      identity: user.value.uid,
      event: productEvents.DOCUMENT_SAVED,
      payload: productAnalyticsData,
    });
  }

  async function discardDocumentationDraft(swmId: string) {
    const repoId = route.params.repoId as string;
    const draftToRemove: Draft = drafts3Store.drafts?.get(swmId);

    if (draftToRemove != null) {
      await clearDismissedSuggestionsForTempDocument(repoId, swmId);
      await drafts3Store.discardDraft(swmId);
    }
  }

  async function deleteDocFromBranch(
    itemsToDelete: Documentation[],
    pushData: PushData,
    { createPR }: { createPR: boolean },
    callback
  ) {
    const filesToDelete = itemsToDelete.map((documentation) => {
      return {
        name: documentation.name,
        id: documentation.id,
        path: documentation.path,
        type: documentation.documentationType,
      };
    });
    await commitDelete({
      pushData,
      shouldOpenPR: createPR,
      shouldCreateBranch: true,
      filesToDelete,
      onDone: callback,
    });
  }

  async function createDeletionBranch(
    itemsToDelete: Documentation[],
    pushData: PushData,
    { createPR }: { createPR: boolean },
    callback: () => void
  ) {
    const repoId = route.params.repoId as string;
    const isDeletingOneItem = itemsToDelete.length === 1;
    try {
      const deleteDocFromBranchCallback = async function (response) {
        try {
          if (response.code !== config.SUCCESS_RETURN_CODE) {
            throw new Error(response.errorMessage);
          }

          itemsToDelete.forEach((item) => {
            sendDeletionCloudTrack(item, pushData);
            // Remove unit drafts if exists
            discardDocumentationDraft(item.id).then();
          });
          closeModal();
          if (createPR) {
            router.push(
              `${getRepoPath(route.params.workspaceId, repoId, pushData.branch)}/${RepoPageRouteNames.PULL_REQUEST}`
            );
          } else {
            router.push(`${getRepoPath(route.params.workspaceId, repoId, pushData.prBranch)}`);
          }
        } catch (err) {
          const errorMessage = `Failed to delete ${
            isDeletingOneItem
              ? `${itemsToDelete[0].documentationType} ${itemsToDelete[0].name}`
              : `${itemsToDelete.length} items`
          }`;
          logger.error({ err }, `${errorMessage}: ${err}`);
          await swal({
            title: errorMessage,
            content: { element: SWAL_CONTACT_US_CONTENT() },
          });
        } finally {
          callback();
        }
      };
      await deleteDocFromBranch(itemsToDelete, pushData, { createPR }, deleteDocFromBranchCallback);
    } catch (err) {
      const errorMessage = `Failed to delete ${
        isDeletingOneItem
          ? `${itemsToDelete[0].documentationType} ${itemsToDelete[0].name}`
          : `${itemsToDelete.length} items`
      }`;
      logger.error({ err }, `${errorMessage}: ${err}`);
      await swal({
        title: errorMessage,
        content: { element: SWAL_CONTACT_US_CONTENT() },
      });
      throw new Error(errorMessage);
    }
  }

  async function pushDeleteChanges(itemsToDelete, pushData, _, callback) {
    const isDeletingOneItem = itemsToDelete.length === 1;
    try {
      const batchSaveCallback = async function (response) {
        try {
          if (response.code !== config.SUCCESS_RETURN_CODE) {
            throw new Error(response.errorMessage);
          }

          itemsToDelete.forEach((item) => {
            deleteLocalSwimm({
              swmId: item.id,
              repoId: route.params.repoId,
              createPR: false,
              isPlaylist: item.documentationType === DocumentationTypes.PLAYLIST,
            });

            // Remove doc drafts if exist
            discardDocumentationDraft(item.id);
          });

          closeModal();
        } catch (err) {
          const errorMessage = `Failed to delete ${
            isDeletingOneItem
              ? `${itemsToDelete[0].documentationType} ${itemsToDelete[0].name}`
              : `${itemsToDelete.length} items`
          }`;
          logger.error({ err }, `${errorMessage}: ${err}`);
          await swal({
            title: errorMessage,
            content: { element: SWAL_CONTACT_US_CONTENT() },
          });
        } finally {
          callback();
        }
      };

      const filesToDelete = itemsToDelete.map((documentation) => {
        return {
          name: documentation.name,
          id: documentation.id,
          path: documentation.path,
          type: documentation.documentationType,
        };
      });

      await commitDelete({
        pushData,
        shouldOpenPR: false,
        shouldCreateBranch: false,
        filesToDelete,
        onDone: batchSaveCallback,
      });
    } catch (err) {
      const errorMessage = `Failed to delete ${
        isDeletingOneItem
          ? `${itemsToDelete[0].documentationType} ${itemsToDelete[0].name}`
          : `${itemsToDelete.length} items`
      }`;
      logger.error({ err }, `${errorMessage}: ${err}`);
      await swal({
        title: errorMessage,
        content: { element: SWAL_CONTACT_US_CONTENT() },
      });
    }
  }

  function getDeleteBranchName(itemsToDelete) {
    let branchName: string;
    if (itemsToDelete.length === 1) {
      const slugifiedName = slugify(itemsToDelete[0].name).substring(0, 100);
      branchName = `delete-swimm-${itemsToDelete[0].documentationType}-${slugifiedName}-`;
    } else {
      branchName = `delete-${itemsToDelete.length}-swimm-documentation-`;
    }
    const workspace = store.getters['database/db_getWorkspace'](route.params.workspaceId);

    // in delete, we prefix the branch name with our prefix (instead of replacing)
    // since it contains important information
    const workspacePrefix = `${workspace?.branch_prefix ?? ''}`;

    return toValidBranchName(`${workspacePrefix}${branchName}${shortUuid()}`);
  }

  function getDeleteCommitMessage(itemsToDelete) {
    const commitMessagePrefix = 'docs(swimm):';
    if (itemsToDelete.length === 1) {
      return `${commitMessagePrefix} delete ${itemsToDelete[0].documentationType} ${itemsToDelete[0].name} (id: ${itemsToDelete[0].id})`;
    } else {
      return `${commitMessagePrefix} delete ${itemsToDelete.length} items`;
    }
  }

  async function cannotDeleteOnBitbucketDCSwal(resourceNames: string[]): Promise<void> {
    analytics.cloudTrack({
      identity: user.value.uid,
      event: productEvents.DELETE_NOT_SUPPORTED_POPUP,
      payload: { 'Document Names': resourceNames },
    });
    await swal({
      title: `Deleting docs is not supported in Bitbucket DataCenter`,
      content: { element: SWAL_BITBUCKET_DC_DELETE(resourceNames) },
      buttons: {
        confirm: { text: 'Understood', visible: true },
      },
    });
  }

  function openDeleteModal(itemsToDelete, deleteCallback?) {
    const branchName = getDeleteBranchName(itemsToDelete);
    const commitMessage = getDeleteCommitMessage(itemsToDelete);
    const heading = `Delete ${
      itemsToDelete.length === 1 ? `${itemsToDelete[0].documentationType}` : `${itemsToDelete.length} items`
    }`;
    openModal({
      name: 'PushChangesModalContent',
      options: {
        repoId: route.params.repoId as string,
        commitMessage,
        branchName,
        createPR: async (pushData, modalCallback) => {
          await deleteSharedDocsIfNeeded(itemsToDelete);
          await createDeletionBranch(itemsToDelete, pushData, { createPR: true }, () =>
            callFunctions([deleteCallback, modalCallback])
          );
        },
        createBranch: async (pushData, modalCallback) => {
          await deleteSharedDocsIfNeeded(itemsToDelete);
          await createDeletionBranch(itemsToDelete, pushData, { createPR: false }, () =>
            callFunctions([deleteCallback, modalCallback])
          );
        },
        commitToBranch: async (pushData, modalCallback) => {
          await deleteSharedDocsIfNeeded(itemsToDelete);
          await pushDeleteChanges(itemsToDelete, pushData, {}, () => callFunctions([deleteCallback, modalCallback]));
        },
        heading,
      },
    });
  }

  // Helper function for building a callback function that will call both the provided callback for the delete action and the callback fromt he modal.
  async function callFunctions(functions) {
    for (let i = 0; i < functions.length; i++) {
      const func = functions[i];
      if (func) {
        await func();
      }
    }
  }

  async function deleteSharedDocsIfNeeded(itemsToDelete) {
    const itemsWithSharedDoc = itemsToDelete.filter((item) => item.exported_cloud_doc_id);
    if (!itemsWithSharedDoc.length) {
      return;
    }

    const isDeletingOneItem = itemsToDelete.length === 1;
    const shouldDelete = await swal({
      title: `${
        isDeletingOneItem ? 'This document has' : 'Some documents have'
      }  been shared with members without code access. Would you like to disable sharing and delete ${
        isDeletingOneItem ? 'this document' : 'these documents'
      }?`,
      buttons: {
        cancel: true,
        confirm: { text: 'Disable sharing & delete', visible: true },
      },
    });
    if (!shouldDelete) {
      return;
    }

    const promises = itemsWithSharedDoc.map((item) =>
      deleteSharedDoc({
        workspaceId: route.params.workspaceId as string,
        repoId: route.params.repoId as string,
        sharedDocId: item.exported_cloud_doc_id,
        originalDocId: item.id,
      })
    );
    Promise.allSettled(promises).then();
  }

  function getPrTitleAndMessage({
    workspaceId,
    repoId,
    curBranch,
    filesToDelete,
  }: {
    workspaceId: string;
    repoId: string;
    curBranch: string;
    filesToDelete: { id: string; name: string; path: string; type: string }[];
  }): {
    prTitle: string;
    prMessage: string;
  } {
    const isDeletingOneItem = filesToDelete.length === 1;
    let prMessage = `:put_litter_in_its_place: Delete ${
      isDeletingOneItem ? `Swimm ${filesToDelete[0].type}` : `${filesToDelete.length} Swimm items`
    }: \n`;
    for (const fileToDelete of filesToDelete) {
      const isPlaylist = fileToDelete.type === 'playlist';
      // there is no point to put link to the new branch since this is deleted
      // so we put link to the old branch
      // This is change of behavior from legacy - are we ok with this?
      const linkToUnit = getAppLink(
        `${getRepoPath(workspaceId, repoId, curBranch)}/${isPlaylist ? 'playlists' : 'docs'}/${fileToDelete.id}`,
        false
      );
      prMessage = prMessage.concat(
        `:link: ${isPlaylist ? 'playlist' : 'doc'}: ` + `[${fileToDelete.name}](${linkToUnit}).` + '\n'
      );
    }
    prMessage = prMessage.concat(`\n${MARKDOWN_PR_SUFFIX}`);
    const prTitle = isDeletingOneItem ? filesToDelete[0].name : `Swimm: batch commit ${filesToDelete.length} files`;
    return { prTitle, prMessage };
  }

  /* this function does the actual commit using gitwrapper functions
  TODO: remove the onDone and just call it directy after this from in the calling point
   */
  async function commitDelete({
    pushData,
    shouldOpenPR,
    shouldCreateBranch,
    filesToDelete,
    onDone,
  }: {
    pushData: PushData;
    shouldOpenPR: boolean;
    shouldCreateBranch: boolean;
    filesToDelete: { id: string; name: string; path: string; type: string }[];
    onDone?: ({ code, errorMessage }: { code: number; errorMessage?: string }) => void;
  }) {
    logger.info(
      `In commitDelete with ${JSON.stringify({ pushData, shouldOpenPR, shouldCreateBranch, filesToDelete })}`
    );
    const repoId = route.params.repoId as string;
    const workspaceId = route.params.workspaceId as string;
    try {
      const curBranch = pushData.branch;
      const newBranch = shouldCreateBranch ? toValidBranchName(pushData.prBranch) : pushData.branch;
      // create branch
      if (shouldCreateBranch) {
        const originBranchRefResponse = await gitwrapper.getBranch({ repoId, branchName: curBranch });
        await gitwrapper.createBranch({
          repoId,
          branchName: newBranch,
          sourceSha: originBranchRefResponse.sha,
        });
      }
      // commit
      // I have no idea why the paths here are without the suffix
      const getRealPath = (path: string, type: string): string => {
        if (path.endsWith('.sw.md')) {
          return path;
        }
        if (type === 'playlist') {
          return `${path}.pl.sw.md`;
        }
        return `${path}.sw.md`;
      };

      const swmResourceFiles: SwmResourceFile[] = filesToDelete.map((fileToDelete) => ({
        path: getRealPath(fileToDelete.path, fileToDelete.type),
        state: SwmResourceState.Deleted,
      }));
      await gitwrapper.pushMultipleFilesToBranch({
        files: swmResourceFiles,
        repoId,
        branch: newBranch,
        commitMessage: pushData.commitMessage,
      });
      // create pr
      if (shouldOpenPR) {
        const { prMessage, prTitle } = getPrTitleAndMessage({ workspaceId, repoId, curBranch, filesToDelete });
        const createPrResult = await gitwrapper.createPullRequest({
          repoId,
          fromBranch: newBranch,
          toBranch: curBranch,
          title: prTitle,
          body: prMessage,
        });
        const prUrl = createPrResult.url;
        if (!window.open(prUrl, '_blank')) {
          addNotification(
            'Your browser blocked this request from opening in a new tab. For a better experience, please allow popups for this site.',
            {
              autoClose: false,
              actionButtonText: 'Go to request',
              onActionClick: () => {
                window.open(prUrl, '_blank');
              },
            }
          );
        }
      }
      onDone({ code: config.SUCCESS_RETURN_CODE });
    } catch (err) {
      onDone({ code: config.ERROR_RETURN_CODE, errorMessage: err.toString() });
    }
  }
  return { openDeleteModal, cannotDeleteOnBitbucketDCSwal };
}
