// Need to check if before version 1.0.2 when parsing smartText tokens/foot-notes because the format changed.
import config from '../config';
import { SwmdMetadata } from '../types';
import { assertFileVersion } from '../utils/file-version-utils';
import semver from 'semver';

export function isSWMDVersionBefore102(metadata: SwmdMetadata): boolean {
  return assertFileVersion({ fileVersion: metadata.file_version, operator: '<', versionToCompare: '1.0.2' });
}

export function isSWMDVersionBefore110(metadata: SwmdMetadata): boolean {
  return assertFileVersion({ fileVersion: metadata.file_version, operator: '<', versionToCompare: '1.1.0' });
}

export function isSWMDVersionBefore111(metadata: SwmdMetadata): boolean {
  return assertFileVersion({ fileVersion: metadata.file_version, operator: '<', versionToCompare: '1.1.1' });
}

export function isSWMDVersionNewerThanSupported(metadata: SwmdMetadata): boolean {
  const versionSatisfiesSemver = semver.satisfies(
    metadata.file_version,
    `<=${config.SWMD_SCHEMA_VERSION} || ~${config.SWMD_SCHEMA_VERSION}`
  );
  return !versionSatisfiesSemver;
}
