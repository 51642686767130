<script setup lang="ts">
import ApexChart from 'vue3-apexcharts';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import PopularDocs from '@/modules/core/workspace/home/admin-section/HomeInsights/PopularDocs.vue';
import TopUsers from '@/modules/core/workspace/home/admin-section/HomeInsights/TopUsers.vue';
import RecentCreatedDocs from '@/modules/core/workspace/home/admin-section/HomeInsights/RecentCreatedDocs.vue';
import { eventLogger } from '@swimm/shared';
import { computed } from 'vue';
import OverviewCard from '@/modules/core/workspace/home/components/OverviewCard.vue';
import { useStore } from 'vuex';
import { useNavigate } from '@/common/composables/navigate';
import { useEventsStore } from '@/modules/core/stores/event-log-store';
import { datetimeUtils } from '@swimm/shared';
import { storeToRefs } from 'pinia';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';

const props = defineProps({
  workspaceId: { type: String, required: true },
});

const { getEvents } = useSwimmEventLogs();
const store = useStore();
const { theme: colorTheme } = storeToRefs(useUserConfigStore());
const eventStore = useEventsStore();

const autoSyncEvents = computed(() =>
  getEvents(props.workspaceId, [
    eventLogger.SWIMM_EVENTS.GITHUB_APP_AUTOSYNCED_FILES_AUTOMATICALLY,
    eventLogger.SWIMM_EVENTS.GITHUB_APP_AUTOSYNCED_FILES_MANUALLY,
    eventLogger.SWIMM_EVENTS.WEB_APP_AUTOSYNCED_FILES,
  ])
);

const currentDate = new Date();
const twoWeeksAgo = new Date();
twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

const workspaceDocs = computed(() =>
  store.getters['database/db_getWorkspaceResources'](props.workspaceId)
    .filter((resource) => resource.type === 'unit' && resource.play_mode && resource.play_mode === 'walkthrough')
    .filter((doc) => store.getters['filesystem/fs_isUnitFileInRepoPath'](doc.id, doc.repoId))
);
const { getRepoPath } = useNavigate();

const allRecentDocs = computed(() =>
  workspaceDocs.value
    .filter((doc) => doc.created?.seconds * 1000 > twoWeeksAgo.getTime())
    .sort((a, b) => (a.created < b.created ? 1 : a.created > b.created ? -1 : 0))
);

const recentDocs = computed(() =>
  allRecentDocs.value.slice(0, 20).map((doc) => {
    const repoPath = getRepoPath(doc.repoId);
    const docLink = `${repoPath}/docs/${doc.id}`;
    return { ...doc, link: docLink };
  })
);

const popularDocs = computed(() =>
  (workspaceDocs.value as Array<{ created: Date; repoId: string; id: string }>)
    .sort((a, b) => (a.created < b.created ? 1 : a.created > b.created ? -1 : 0))
    .map((doc) => {
      return { ...doc, views: store.getters['database/db_getResourceViews'](doc.repoId, doc.id, 'swimms') || 0 };
    })
    .sort((a, b) => b.views - a.views)
    .slice(0, 5)
    .map((doc) => {
      const repoPath = getRepoPath(doc.repoId);
      const docLink = `${repoPath}/docs/${doc.id}`;
      return { ...doc, link: docLink };
    })
);

const workspacePlaylistsResources = computed(() =>
  store.getters['database/db_getWorkspaceRepoIds'](props.workspaceId).reduce((playlists, repoId) => {
    const currentPlaylists = store.getters['database/db_getPlaylists'](repoId);
    const playlistsValues = currentPlaylists || {};

    const workspacePlaylists = Object.values(playlistsValues).filter(
      (playlist) =>
        !(playlist as { is_example: boolean }).is_example &&
        (!(playlist as { app_version: string }).app_version ||
          store.getters['filesystem/fs_isUnitFileInRepoPath']((playlist as { id: string }).id, repoId))
    );
    return [...playlists, ...workspacePlaylists];
  }, [])
);

const numOfDocs = computed(() => workspaceDocs.value.length || 0);
const numOfPlaylists = computed(() => workspacePlaylistsResources.value.length || 0);

await eventStore.fetchAllRecentEvents({ workspaceId: props.workspaceId });

const eventsByUsers = eventStore.allRecentEvents.reduce((prev, curr) => {
  if (Object.keys(prev).includes(curr.userName)) {
    prev[curr.userName] = [...prev[curr.userName], curr];
  } else {
    prev[curr.userName] = [curr];
  }
  return prev;
}, {});

const topUsers = Object.keys(eventsByUsers)
  .sort((a, b) => {
    return eventsByUsers[a].length > eventsByUsers[b].length
      ? -1
      : eventsByUsers[a].length < eventsByUsers[b].length
      ? 1
      : 0;
  })
  .slice(0, 5);

const topUsersDigest = topUsers.map((userName) => {
  const sortedEvents = eventsByUsers[userName].sort((a, b) => {
    a.created > b.created.length ? -1 : a.created < b.created.length ? 1 : 0;
  });

  const lastEvent = sortedEvents[0];
  return {
    name: userName,
    lastActivity: lastEvent.created.toDate(),
  };
});

const uniqueAutosyncEvents = autoSyncEvents.value.reduce((prev, curr) => {
  const parsedValue = JSON.parse(curr.value || {});

  if (parsedValue.docId && !Object.keys(prev).includes(parsedValue.docId)) {
    prev[parsedValue.docId] = curr;
  }
  return prev;
}, {});

const numOfUniqueAutosyncs = Object.keys(uniqueAutosyncEvents).length;

const docViewedEvents = eventStore.allRecentEvents.filter(
  (event) => event.swimmEventCode === eventLogger.SWIMM_EVENTS.DOC_VIEWED.code
);

const aggregatedViewEvents = {};
const aggregatedDateEvents = {};
const xAxisDates: string[] = [];

for (let index = 0; index <= 14; index++) {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 14 + index);

  // Create the graph labels
  xAxisDates.push(`${currentDate.getDate()}/${currentDate.getMonth() + 1}`);

  const currDateString = currentDate.toISOString().split('T')[0];
  const dovVieweDateEvents = docViewedEvents.filter(
    (event) => event.created.toDate().toISOString().split('T')[0] === currDateString
  );

  const allDateUsersIds = eventStore.allRecentEvents
    .filter((event) => event.created.toDate().toISOString().split('T')[0] === currDateString)
    .map((event) => event.userId);

  aggregatedDateEvents[currDateString] = [...new Set(allDateUsersIds)];
  aggregatedViewEvents[currDateString] = dovVieweDateEvents;
}

const BAR_CHART_MAIN_COLORS = ['var(--color-brand)', 'var(--high-violet)', 'var(--warning-yellow-500)'];

const platforms = {
  'source-doc': 'Web App',
  'source-github_app': 'Github',
  'source-ide': 'IDE (IntelliJ / VS Code)',
};

// This aggregates into an obj like {'source-doc': [1,123,33,66,22...]} platform to an array of views per day in the platform
const docViewedData = Object.keys(platforms).reduce((prev, curr) => {
  const values = Object.values(aggregatedViewEvents).map((event) => {
    return (event as { value: string }[]).filter((e) => e.value === curr);
  });
  prev[curr] = values.map((v) => v.length);
  return prev;
}, {});

// Apex chart format is {name: PLATFORM, data: DATA}
const series = Object.keys(docViewedData).reduce((prev, curr) => {
  return [...prev, { name: platforms[curr], data: docViewedData[curr] }];
}, []);

const sessionsDataSeriesData = Object.keys(aggregatedDateEvents).reduce((prev, curr) => {
  prev[curr] = aggregatedDateEvents[curr].length;
  return prev;
}, {});

const sessionsDataSeries = [
  {
    data: Object.values(sessionsDataSeriesData),
  },
];
const shouldPresentViewGraphsEmptyState = series.every((entry) => entry.data.every((counter) => counter === 0));
const shouldPresentSessionsGraphsEmptyState = Object.values(sessionsDataSeriesData).every((counter) => counter === 0);

const docViewedOptions = computed(() => {
  return {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: { show: false },
      fontFamily: 'var(--font-family-main)',
      foreColor: 'var(--text-color-secondary)',
    },
    colors: BAR_CHART_MAIN_COLORS,
    xaxis: {
      type: 'string',
      categories: xAxisDates,
    },

    legend: {
      fontSize: 'var(--body-S)',
      horizontalAlign: 'left',
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: colorTheme.value,
    },
  };
});

const CHART_MAIN_COLORS = ['var(--color-brand)', 'var(--high-violet)'];

function CustomTooltip({ series, seriesIndex, dataPointIndex }) {
  return `<span class="custom-tooltip">${series[seriesIndex][dataPointIndex]}</span>`;
}

const sessionChartOptions = {
  chart: {
    background: 'transparent',
    foreColor: 'var(--text-color-primary)',
    fontFamily: 'var(--font-family-main)',
    toolbar: { show: false },
    zoom: { enabled: false },
    selected: { enabled: false },
  },
  legend: {
    show: false,
  },
  fill: {
    type: 'solid',
    colors: ['var(--color-selected)'],
  },
  colors: CHART_MAIN_COLORS,
  tooltip: {
    onDatasetHover: {
      highlightDataSeries: true,
    },
    shared: false,
    intersect: true,
    custom: CustomTooltip,
  },
  xaxis: {
    type: 'string',
    categories: xAxisDates,
  },
  yaxis: {
    labels: {
      formatter: (val) => val.toFixed(0),
    },
  },
  dataLabels: { enabled: false },
  markers: {
    size: 3,
    hover: { sizeOffset: 2 },
    strokeWidth: 1,
  },
  grid: {
    padding: { right: 20 },
  },
  states: {
    active: {
      filter: {
        type: 'none',
      },
    },
  },
};
</script>

<template>
  <section>
    <div class="name-container">
      <SwText variant="headline3">Bi-weekly Stats</SwText><Icon no-padding class="calendar-icon" name="calendar" />
      <SwText class="dates"
        >{{ datetimeUtils.formatDate(twoWeeksAgo, 'short') }} -
        {{ datetimeUtils.formatDate(currentDate, 'short') }}
      </SwText>
    </div>
    <div class="cards">
      <OverviewCard
        icon="sync"
        :number="numOfUniqueAutosyncs"
        title="Bi-weekly Auto-synced Docs"
        :is-statistics="true"
      />
      <OverviewCard icon="doc" :number="numOfDocs" title="Total docs" :is-statistics="true" />
      <OverviewCard icon="playlist" :number="numOfPlaylists" title="Total playlists" :is-statistics="true" />
    </div>
  </section>
  <section>
    <div v-if="!shouldPresentViewGraphsEmptyState">
      <SwText variant="headline3">Doc consumption</SwText>
      <SwText variant="body-L" class="description"
        >This graph describes the source and number of docs accessed by your team.</SwText
      >
      <ApexChart type="bar" :options="docViewedOptions" :series="series" height="300" />
    </div>
    <EmptyState v-else title="You don't have recent doc views" description="" class="views-chart-empty-state" />
  </section>
  <section class="sessions">
    <PopularDocs :popular-docs="popularDocs" />
    <TopUsers :top-users="topUsersDigest" :workspace-id="workspaceId" />
  </section>
  <section>
    <div v-if="!shouldPresentSessionsGraphsEmptyState" data-testid="sessionActivity">
      <SwText variant="headline3">Daily sessions</SwText>
      <SwText variant="body-L" class="description">Overall engagement across your team.</SwText>
      <ApexChart type="area" :options="sessionChartOptions" :series="sessionsDataSeries" height="300" />
    </div>
    <EmptyState
      v-else
      title="You don't have daily sessions"
      description="Invite members to your workspace and start collaborating"
      class="views-chart-empty-state"
    />
  </section>
  <section class="recently-created-wrapper">
    <RecentCreatedDocs :recent-docs="recentDocs" />
  </section>
</template>

<style scoped lang="postcss">
section {
  margin: var(--space-lg) 0;

  &.sessions {
    display: flex;
    gap: var(--space-lg);
  }
}

.description {
  margin-top: var(--space-base);
  margin-bottom: var(--space-sm);
  color: var(--text-color-secondary);
}

.name-container {
  display: inline-flex;
  gap: var(--space-base);
  align-items: center;
}

.calendar-icon {
  margin-left: var(--space-base);
  color: var(--text-color-secondary);
  font-size: var(--headline3);
}

.dates {
  color: var(--text-color-secondary);
}

.cards {
  display: flex;
  align-items: center;
  margin-top: var(--space-md);
  border: 1px solid var(--color-border-default);
  border-radius: 8px;
}

.views-chart-empty-state {
  height: 300px;
  display: flex;
  justify-content: center;
}
</style>
