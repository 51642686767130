<template>
  <TopMenuLayout>
    <template #topBar>
      <TopBar />
    </template>
    <template #content>
      <ContentErrorState code="404">
        <router-link to="/" class="back-link"><SwText variant="body-S">Back to home</SwText> </router-link>
      </ContentErrorState>
    </template>
  </TopMenuLayout>
</template>

<script>
import TopBar from '@/common/components/TopBar/TopBar.vue';
import TopMenuLayout from '@/common/layouts/TopMenuLayout.vue';
import ContentErrorState from '@/common/components/organisms/unavailable-doc-states/ContentErrorState.vue';

export default {
  components: { TopBar, TopMenuLayout, ContentErrorState },
};
</script>

<style scoped lang="postcss">
.back-link {
  color: var(--text-color-link);
}
</style>
