<script setup lang="ts">
import { useNavigate } from '@/common/composables/navigate';
import { useRouting } from '@/common/composables/routing';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { PageRoutesNames, RepoPageRouteNames } from '@/common/consts';
import { useHelpTooltipsStore } from '@/modules/core/stores/useHelpTooltipsStore';
import { confirmDraftDeletion } from '@/modules/drafts3/discard-draft-confirmations';
import { type Draft, type DraftContent, DraftType } from '@/modules/drafts3/db';
import { type CommitResult, type PostCommitCallback, useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { getRepoPath } from '@/router/router-utils';
import { useNotificationsStore } from '@swimm/editor';
import { FolderItemType, config, getLoggerNew, productEvents } from '@swimm/shared';
import { storeToRefs } from 'pinia';
import { computed, nextTick, ref, toRaw, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { type BatchSaveStep, SaveOptions, VerifyError, useBatchCommitStore } from '../store/batch-commit';
import BatchCommitModalInner from './BatchCommitModalInner.vue';
import { useAppLinks } from '@/modules/core/compositions/app-links';
import { MARKDOWN_PR_SUFFIX } from '@/common/utils/common-definitions';
import {
  composeCommitFinalizedMessage,
  composeRepoPagePath,
  docAndPlaylistOrigin,
  playlistOrigin,
} from '@/modules/batch-commit/utils';
import useSharingInternally from '@/modules/cloud-docs/composables/sharing-internally';
import type { ModalDraft } from '@/modules/batch-commit/draftTypes';
import { computeDraftDisplayName } from '@/common/utils/draft-utils';
import { useDrafts3ValidationStore } from '@/modules/drafts3/stores/drafts3Validation';
import { useBranchSwitcher3 } from '@/common/composables/branchSwitcher3';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useDocsContentStore } from '@/modules/core/stores/docs-content';
import { useInitData } from '@/common/composables/initData';
import { watchTriggerable } from '@vueuse/core';
import { invalidateSearchCacheOnRepoChange } from '@/remote-adapters/search-defs';
import { useStore } from 'vuex';

const logger = getLoggerNew(__modulename);

const props = defineProps({
  repoId: {
    type: String,
    required: true,
  },
  workspaceId: {
    type: String,
    required: true,
  },
  branch: {
    type: String,
    default: null,
  },
});

const drafts3Store = useDrafts3Store();
const drafts3Validation = useDrafts3ValidationStore();
const { commitMessage, saveOption, startPR, newRemoteBranchName } = storeToRefs(useBatchCommitStore());
const { addNotification } = useNotificationsStore();
const { assertOnDocOrPlaylistPageRoute, routeBackFromDoc } = useRouting();
const route = useRoute();
const router = useRouter();
const store = useStore();
const analytics = useAnalytics();
const { setBranchChanged } = useHelpTooltipsStore();
const { navigateToPageAndTerminateWorker } = useNavigate();
const { getAppLink } = useAppLinks();
const { validateNewBranch } = useBranchSwitcher3();
const foldersStore = useFoldersStore();
const { repos } = storeToRefs(useReposStore());
const { publishDocsOnCommit } = useSharingInternally();
const { updateDocsContent } = useDocsContentStore();
const { setPlaylistData } = useInitData();

// TODO This should be in a store. Can we retrofit the existing store?
const currentDraftId = computed(() => (route.params.unitId as string) ?? (route.params.playlistId as string) ?? null);
const draftSelection = ref(new Set<string>());

const { trigger: calculateDraftSelection } = watchTriggerable(
  () => currentDraftId.value,
  (_1, _2, onCleanup) => {
    if (currentDraftId.value != null) {
      const currentDraft = drafts3Store.drafts?.get(currentDraftId.value);
      if (currentDraft) {
        draftSelection.value.add(currentDraftId.value);
        selectAllRelatedDrafts(currentDraft);
      }
    } else {
      draftSelection.value = new Set<string>([...drafts3Store.drafts.keys()]);
    }
    onCleanup(() => {
      draftSelection.value.clear();
    });
  }
);

watch(
  () => [drafts3Store.loading, drafts3Store.drafts],
  async ([loading]) => {
    if (!loading) {
      await drafts3Validation.verifyDrafts();
      calculateDraftSelection();
    }
  },
  { immediate: true, deep: true }
);

const drafts = computed<Map<string, ModalDraft>>(() => {
  const drafts = new Map<string, ModalDraft>();

  for (const [draftId, draft] of drafts3Store.drafts ?? []) {
    drafts.set(draftId, {
      draftId: draft.id,
      type: draft.type === 'doc' ? config.SWIMM_FILE_TYPES.SWMD : config.SWIMM_FILE_TYPES.PLAYLIST,
      title: computeDraftDisplayName(drafts3Store.getDraftTitle(draft)),
      isNew: draft.isNew,
      branch: draft.branch,
      created: draft.created,
      location: calcLocation(draft),
    });
  }

  return drafts;
});

const repoBranches = computed(() => store.getters['filesystem/fs_getRepoBranches'](props.repoId));

function calcLocation(draft) {
  if (draft.type === 'doc') {
    return {
      name: PageRoutesNames.DOC_EDIT,
      params: {
        workspaceId: props.workspaceId,
        repoId: props.repoId,
        branch: draft.branch,
        unitId: draft.id,
      },
    };
  } else {
    return {
      name: PageRoutesNames.PLAYLIST_EDIT,
      params: {
        workspaceId: props.workspaceId,
        repoId: props.repoId,
        branch: draft.branch,
        playlistId: draft.id,
      },
    };
  }
}

async function calcNextRoute(repoRoute: string, docRoute: string, prNumber: number) {
  const shouldNavigateToStatusTab =
    route.query.origin === RepoPageRouteNames.NEEDS_REVIEW || route.query.source === 'github_app';
  const repoPageRoute = composeRepoPagePath(
    repoRoute,
    shouldNavigateToStatusTab,
    saveOption.value === SaveOptions.NEW_BRANCH && startPR.value // user chose the new branch option and wanted to start a PR
  );

  let newBranch = null;
  if (saveOption.value === SaveOptions.NEW_BRANCH && newRemoteBranchName.value && !startPR.value) {
    newBranch = newRemoteBranchName.value;
  }

  const notificationMessage = composeCommitFinalizedMessage(newBranch);
  let newRoute = repoPageRoute;
  let query = null;

  if (saveOption.value === SaveOptions.NEW_BRANCH && newRemoteBranchName.value) {
    if (startPR.value) {
      newRoute = repoPageRoute;
      if (prNumber && !shouldNavigateToStatusTab) {
        query = { prNumber };
      }
    } else {
      setBranchChanged(true);
      newRoute = docRoute ? docRoute : repoPageRoute;
      newBranch = newRemoteBranchName.value;
    }
  } else if (shouldNavigateToStatusTab) {
    newRoute = repoPageRoute;
  } else if (docRoute) {
    newRoute = docRoute;
  }

  await navigateToPageAndTerminateWorker({ newRoute, query, newBranch });
  addNotification(notificationMessage);
}

const selectedDrafts = computed(() => [...draftSelection.value].map((id) => drafts.value.get(id)));
const swimmNames = computed(() => selectedDrafts.value.map((draft) => draft.title).join(', '));
const hasDocs = computed(() => selectedDrafts.value.some((draft) => draft.title !== config.SWIMM_FILE_TYPES.PLAYLIST));
const hasPlaylists = computed(() =>
  selectedDrafts.value.some((draft) => draft.title === config.SWIMM_FILE_TYPES.PLAYLIST)
);

function getDefaultCommitMessage(): string {
  let subject: string;
  if (hasDocs.value && hasPlaylists.value) {
    subject = 'docs & playlists';
  } else if (hasDocs.value) {
    subject = selectedDrafts.value.length === 1 ? 'doc' : 'docs';
  } else {
    subject = selectedDrafts.value.length === 1 ? 'playlist' : 'playlists';
  }

  let verb: string;
  const hasNew = selectedDrafts.value.some((swimm) => swimm.isNew);
  // const hasDeleted = selectedDrafs.some((swimm) => swimm.isDeleted);
  const hasModified = selectedDrafts.value.some((swimm) => !swimm.isNew);
  if (hasNew) {
    if (hasModified) {
      verb = 'update';
    } else {
      verb = 'create';
    }
  } else {
    verb = 'update';
  }

  return `docs(swimm): ${verb} ${subject}: ${swimmNames.value}`;
}

function getDefaultPRMessage(prBranch: string): string {
  const pluralSuffix = selectedDrafts.value.length > 1 ? 's' : '';
  let prMessage = `Link${pluralSuffix} to ${
    hasDocs.value && hasPlaylists ? 'docs/playlists' : hasDocs.value ? `doc${pluralSuffix}` : `playlist${pluralSuffix}`
  }: \n`;

  selectedDrafts.value.forEach((draft) => {
    const isPlaylist = draft.type === config.SWIMM_FILE_TYPES.PLAYLIST;
    const linkToUnit = getAppLink(
      `${getRepoPath(props.workspaceId, props.repoId, prBranch)}/${isPlaylist ? 'playlists' : 'docs'}/${draft.draftId}`,
      false
    );

    const splitLink = linkToUnit.split('/branch/');
    const linkSuffix = splitLink[1]
      ? `/branch/${encodeURIComponent(prBranch)}${splitLink[1].substring(splitLink[1].indexOf('/'))}`
      : '';
    const linkToDoc = splitLink[0] + linkSuffix;
    prMessage = prMessage.concat(
      `:link: ${isPlaylist ? 'playlist' : 'doc'}: ` + `[${draft.title}](${linkToDoc}).` + '\n'
    );
  });

  return prMessage.concat(`\n${MARKDOWN_PR_SUFFIX}`);
}

function getDefaultPRTitle(): string {
  return selectedDrafts.value.length > 1
    ? `Swimm: batch commit ${selectedDrafts.value.length} files`
    : selectedDrafts.value[0].title;
}

function notifyNewPrPopupBlocker(prUrl) {
  addNotification(
    'Your browser blocked this request from opening in a new tab. For a better experience, please allow popups for this site.',
    {
      autoClose: false,
      actionButtonText: 'Go to request',
      onActionClick: () => {
        window.open(prUrl, '_blank');
      },
    }
  );
}

async function commitDrafts(progress: (progress: BatchSaveStep) => void) {
  if (!(await drafts3Validation.isVerifyUpToDate())) {
    progress('verifying');
    await drafts3Validation.verifyDrafts();
    await nextTick();

    for (const draft of draftSelection.value) {
      if (drafts3Validation.invalidDrafts.get(draft) != null) {
        throw new VerifyError();
      }
    }
  }

  // TODO: Replace this by adding the uncommitted docs to their folders from the beginning.
  // Another option is to move the commit function out of the drafts store.
  await addToFolders([...draftSelection.value]);

  const createPr = saveOption.value === SaveOptions.NEW_BRANCH && startPR.value;
  const draftIds = [...draftSelection.value];
  const docDraftIds = draftIds.filter((draftId) => drafts3Store.drafts.get(draftId)?.type === DraftType.DOC);
  const docsNamesToUpdate = {};
  const savedPlaylists = draftIds.filter((draftId) => drafts3Store.drafts.get(draftId)?.type === DraftType.PLAYLIST);
  docDraftIds.forEach((draftId) => {
    const draft = drafts3Store.drafts.get(draftId);
    if (draft && draft.type === DraftType.DOC) {
      docsNamesToUpdate[draft.id] = draft.content.title;
    }
  });
  const publishDocsAfterCommit: PostCommitCallback = async (commitResult: CommitResult): Promise<void> => {
    await publishDocsOnCommit({
      branch: commitResult.branch,
      repoId: props.repoId,
      docIds: docDraftIds,
      workspaceId: props.workspaceId,
      repos: repos.value,
    });
  };
  const result = await drafts3Store.commit(
    draftIds,
    {
      createBranch: saveOption.value === SaveOptions.NEW_BRANCH ? newRemoteBranchName.value : null,
      commitMessage: commitMessage.value || getDefaultCommitMessage(),
      createPr,
      prTitle: createPr && getDefaultPRTitle(),
      prMessage: createPr && getDefaultPRMessage(newRemoteBranchName.value),
    },
    progress,
    [publishDocsAfterCommit]
  );

  if (result.prUrl) {
    // Important: open the new PR window after the save process had already finished.
    // Otherwise, we steal focus from the application tab, and the browser will throttle it
    // mercilessly, making the save operation take many seconds, or even minutes.
    if (!window.open(result.prUrl, '_blank')) {
      notifyNewPrPopupBlocker(result.prUrl);
    }
  }

  invalidateSearchCacheOnRepoChange({ repoId: props.repoId, branch: result.branch });

  // add new docs and updated edited docs to docsContent store
  updateDocsContent(docsNamesToUpdate);
  // update the vuex store playlists with content from the new/last commit
  const setPlaylistDataPromises = savedPlaylists.map((playlistId) =>
    setPlaylistData({
      repoId: props.repoId,
      playlistId,
      reload: true,
    })
  );

  await Promise.allSettled(setPlaylistDataPromises);

  if (draftIds.includes(currentDraftId.value)) {
    const repoPath = getRepoPath(props.workspaceId, props.repoId, props.branch);
    const isDocOrPlaylistOrigin = docAndPlaylistOrigin.includes(route.name as string);
    if (route.query.source === 'github_app' && window.history.length > 1) {
      window.history.back();
    } else if (isDocOrPlaylistOrigin) {
      await calcNextRoute(repoPath, getDocOrPlaylistRoute(result.branch).href, result.prNumber);
    } else {
      await calcNextRoute(repoPath, null, result.prNumber);
    }
  }
}

async function addToFolders(selectedDrafts: string[]): Promise<void> {
  await Promise.allSettled(
    selectedDrafts
      .map((draftId) => drafts3Store.drafts?.get(draftId))
      .filter((draft): draft is Draft => draft?.isNew === true)
      .map((draft) =>
        foldersStore.addItemToFolder(
          draft.folderId,
          draft.repoId,
          draft.id,
          draft.type === 'playlist' ? FolderItemType.PLAYLIST : FolderItemType.DOC
        )
      )
  );
}

function getDocOrPlaylistRoute(branch) {
  if (playlistOrigin.includes(route.name as string)) {
    return router.resolve({
      name: PageRoutesNames.PLAYLIST_VIEW,
      params: {
        workspaceId: props.workspaceId,
        repoId: props.repoId,
        branch: branch,
        playlistId: route.params.playlistId,
      },
    });
  } else {
    return router.resolve({
      name: PageRoutesNames.DOC_VIEW,
      params: {
        workspaceId: props.workspaceId,
        repoId: props.repoId,
        branch: branch,
        unitId: route.params.unitId,
      },
    });
  }
}

function undoDiscardDraft(draft: Draft) {
  drafts3Store.saveDraft(draft.id, { type: draft.type, content: draft.content } as DraftContent);

  const analyticsResourceIdLabel = 'Document ID'; // TODO: Add playlist
  analytics.track(productEvents.UNDO_DISCARD_DRAFT, {
    'Workspace ID': props.workspaceId,
    'Repo ID': props.repoId,
    [analyticsResourceIdLabel]: draft.isNew ? 'New' : draft.id,
    Context: 'Batch Commit',
  });
}

async function discardDraft(id: string) {
  const isCurrentDraftDiscarded = currentDraftId.value === id;
  if (isCurrentDraftDiscarded) {
    if (!(await confirmDraftDeletion())) {
      return;
    }
  }
  const discardedDraft: Draft = { ...toRaw(drafts3Store.drafts.get(id)) };
  await drafts3Store.discardDraft(id);
  analytics.track(productEvents.DISCARD_DOC_DRAFT, {
    'Document ID': discardedDraft.isNew ? 'No Committed Version' : id,
    Context: 'Batch Commit',
  });

  if (isCurrentDraftDiscarded) {
    routeBackFromDoc();
  } else {
    addNotification(`"${computeDraftDisplayName(drafts3Store.getDraftTitle(discardedDraft))}" changes discarded`, {
      duration: 10000,
      closeButtonText: 'Undo',
      onCloseClick: () => undoDiscardDraft(discardedDraft),
    });
  }
}

async function discardAllDrafts() {
  try {
    for (const id of drafts3Store.drafts.keys()) {
      await drafts3Store.discardDraft(id);
    }
  } catch (err) {
    addNotification('Failed to discard drafts');
    logger.error({ err }, `Failed to discard drafts for repo, id: ${props.repoId}. Details: ${err.message}`);
  } finally {
    if (assertOnDocOrPlaylistPageRoute()) {
      const repoPath = getRepoPath(props.workspaceId, props.repoId, props.branch);
      await navigateToPageAndTerminateWorker({ newRoute: repoPath });
    }
  }
}

async function fixDraft(id: string) {
  analytics.track(productEvents.CLICKED_BATCH_COMMIT_FIX_ACTION, {
    'Workspace ID': props.workspaceId,
    'Repo ID': props.repoId,
    Problem: drafts3Validation.invalidDrafts.get(id),
    Context: route.path.includes('/docs') ? 'Doc' : route.path.includes('/playlists') ? 'Playlist' : 'Repo',
  });

  if (route.params?.unitId === id) {
    await router.replace({ query: { ...route.query, fix: 'true' } });
    // this.draftsStore.setDraftFixRequest(draft.draftId);
    // this.close();
  } else if (route.params?.playlistId === id) {
    await router.replace({ query: { ...route.query, fix: 'true' } });
  } else {
    window.open(router.resolve({ ...drafts.value.get(id).location, query: { fix: 'true' } }).href, '_blank');
  }
}

function selectAllRelatedDrafts(draft: Draft) {
  if (draft.type === DraftType.PLAYLIST) {
    // add drafts steps link to the selected playlist
    draft.content.sequence.forEach((step) => {
      const draftStep = drafts3Store.drafts.get(step.id);
      if (draftStep) {
        draftSelection.value.add(draftStep.id);
      }
    });
  }
}

const hasUnselectedDependedDrafts = computed(() => {
  return selectedDrafts.value.some((selectedDraft: ModalDraft) => {
    if (selectedDraft?.type === config.SWIMM_FILE_TYPES.PLAYLIST) {
      const draft: Draft = drafts3Store.drafts.get(selectedDraft.draftId);
      if (draft.type === DraftType.PLAYLIST) {
        return draft.content.sequence.some((step) => {
          const draftStep: Draft = drafts3Store.drafts.get(step.id);
          if (draftStep) {
            return !draftSelection.value.has(draftStep.id);
          }
          return false;
        });
      }
    }
    return false;
  });
});

// TODO: Implement once we have draft dependency
function unselectAllRelatedDrafts() {
  return;
}
</script>

<template>
  <!-- TODO Implement dependant-drafts -->
  <BatchCommitModalInner
    :repo-id="repoId"
    :workspace-id="workspaceId"
    :current-draft-id="currentDraftId"
    :loading="drafts3Store.loading"
    :drafts="drafts"
    :draft-selection="draftSelection"
    :dependant-drafts="new Set()"
    :invalid-drafts="drafts3Validation.invalidDrafts"
    :commit-drafts="commitDrafts"
    :verifying="drafts3Validation.verifying"
    :validate-new-branch="validateNewBranch"
    :show-dependant-drafts-notice="hasUnselectedDependedDrafts"
    :repo-branches="repoBranches"
    @select-draft="(id) => draftSelection.add(id)"
    @deselect-draft="(id) => draftSelection.delete(id)"
    @deselect-all-dependant-drafts="unselectAllRelatedDrafts"
    @discard-draft="discardDraft"
    @discard-all-drafts="discardAllDrafts"
    @fix-draft="fixDraft"
    v-bind="$attrs"
  />
</template>
