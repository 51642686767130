<template>
  <div>
    <div v-if="!parentControl" :class="['ellipsis-wrapper', { fixed, border }]">
      <Icon :name="icon" class="ellipsis" data-testid="open-ellipsis" @click.prevent="openEllipsis" />
      <div
        ref="options"
        class="options-wrapper"
        :class="[openDirection, { visible: showOptions }, { narrow: isNarrow }]"
        @mouseleave="handleMouseLeave"
      >
        <ul
          v-if="showOptions"
          class="options"
          v-click-outside="handleClickOutside"
          ref="ellipsisOptions"
          tabindex="0"
          @keydown.esc="showOptions = false"
        >
          <slot><!-- slot for EllipsisOption(s)  --></slot>
        </ul>
      </div>
    </div>
    <div v-else class="parent-controlled-ellipsis" :class="{ fixed, inverted }">
      <div
        ref="optionsWrapper"
        class="options-wrapper"
        :class="[openDirection, { visible: parentShowOptions }, { narrow: isNarrow }]"
      >
        <ul
          v-if="parentShowOptions"
          class="options"
          @close="closeEllipsis"
          ref="ellipsisOptions"
          tabindex="0"
          @keydown.esc="() => parentShowOptionsController"
        >
          <slot><!-- slot for EllipsisOption(s)  --></slot>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { provide, ref } from 'vue';

export default {
  props: {
    openDirection: { type: String, default: 'side' },
    parentShowOptionsController: { type: Function, default: () => ({}) },
    parentShowOptions: { type: Boolean, default: false },
    parentControl: { type: Boolean, default: false },
    disableMouseLeave: { type: Boolean, default: false },
    fixed: { type: Boolean, default: false },
    inverted: { type: Boolean, default: false },
    icon: { type: String, default: 'more' },
    border: { type: Boolean, default: true },
    isNarrow: { type: Boolean, default: false },
  },
  emits: ['close-ellipsis', 'open-ellipsis'],
  setup(props, { emit }) {
    const showOptions = ref(false);
    const ellipsisOptions = ref();
    function closeEllipsis() {
      if (props.parentControl) {
        emit('close-ellipsis');
        return;
      }
      showOptions.value = false;
    }
    provide('closeEllipsis', closeEllipsis);

    function handleMouseLeave() {
      if (props.disableMouseLeave) {
        return;
      }
      showOptions.value = false;
    }

    function handleClickOutside() {
      if (!props.disableMouseLeave) {
        return;
      }
      showOptions.value = false;
    }

    return {
      showOptions,
      closeEllipsis,
      handleMouseLeave,
      handleClickOutside,
      ellipsisOptions,
    };
  },

  watch: {
    ellipsisOptions() {
      if (this.ellipsisOptions) {
        this.$nextTick(() => {
          this.ellipsisOptions.focus();
        });
      }
    },
    parentShowOptions() {
      if (this.parentShowOptions) {
        if (!this.disableMouseLeave) {
          (this.$refs.optionsWrapper as HTMLElement).addEventListener('mouseleave', () =>
            this.parentShowOptionsController()
          );
        }
        this.$emit('open-ellipsis');
      } else {
        if (!this.disableMouseLeave) {
          (this.$refs.optionsWrapper as HTMLElement).removeEventListener('mouseleave', () =>
            this.parentShowOptionsController()
          );
        }
      }
    },
  },

  methods: {
    openEllipsis(event) {
      if (this.fixed) {
        const options = this.$refs.options as HTMLElement;
        if (options) {
          options.style.top = `${event.y}px`;
          options.style.left = `${event.x - 30}px`;
        }
      }
      this.$emit('open-ellipsis');
      this.showOptions = true;
    },
  },
};
</script>

<style scoped>
.ellipsis-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.ellipsis-wrapper.border {
  border: 1px solid var(--color-border-default);
  border-radius: 2px;
  background: var(--color-bg);
}

.ellipsis-wrapper.border:hover {
  background: var(--color-hover);
}

.ellipsis-wrapper.border.ellipsis,
.parent-controlled-ellipsis.ellipsis {
  position: relative;
  color: var(--text-color-primary);
}

.ellipsis-wrapper.border .ellipsis {
  color: var(--text-color-primary);
}

.options-wrapper {
  position: absolute;
  z-index: 2;
  display: none;
  padding: 15px; /* so the mouseleave event is a little larger than the options size */
  visibility: hidden;
  transition: display 0.3s, visibility 0.3s;
}

.options-wrapper.visible {
  display: block;
  visibility: visible;
}

.ellipsis-wrapper.fixed {
  z-index: unset;
}

.ellipsis-wrapper.fixed .options-wrapper {
  position: fixed;
}

.options {
  overflow: hidden;
  margin: 0;
  outline: none;
  padding: 0;
  font-size: var(--body-S);
  border: 1px solid var(--color-border-default-subtle);
  border-radius: 4px;
  background: var(--color-bg);
  box-shadow: var(--box-shadow-small);
}

.top > .options,
.right-aligned-top > .options {
  font-size: var(--fontsize-m);
  font-family: var(--fontfamily-main);
}

.parent-controlled-ellipsis.fixed {
  position: fixed;
  z-index: 1;
}

.parent-controlled-ellipsis.inverted .options {
  border: 1px solid var(--color-border-default);
  background: var(--color-bg);
}

.side {
  top: 0;
  left: -15px;
}

.bottom {
  top: 0;
  left: 40px;
}

.top {
  right: 20px;
  bottom: 20px;
}

.left {
  top: -15px;
  left: -120px;
}

.stick-right {
  top: 0;
  right: -15px;
}

.right {
  bottom: 0px;
  left: -25px;
}

.middle-right {
  bottom: 30px;
  left: 40px;
}

.bottom-left {
  top: -1px;
  left: -190px;
}

.below {
  top: 30px;
}

.top-right {
  top: -40px;
  left: 30px;
}

.right-aligned-top {
  right: -8px;
  bottom: 45px;
}

.narrow {
  min-width: auto;
}
</style>
