<script setup lang="ts">
import { Icon, SwText } from '@swimm/ui';
import { computed } from 'vue';
import { BaseLayoutGap } from '@swimm/reefui';
import { ApplicabilityStatus } from '@swimm/shared';

const props = defineProps<{
  isEditMode: boolean;
  editorShown: boolean;
  error: string | null;
  applicability: ApplicabilityStatus.Outdated | ApplicabilityStatus.Verified | ApplicabilityStatus.Autosyncable;
  hasTokens: boolean;
  showTokensUnsupportedTip: boolean;
  empty: boolean;
}>();

const ApplicabilityStyle = {
  [ApplicabilityStatus.Verified]: {
    icon: 'updated',
    color: 'var(--text-color-success-strong)',
  },
  [ApplicabilityStatus.Autosyncable]: {
    icon: 'sync',
    color: 'var(--text-color-magic-strong)',
  },
  [ApplicabilityStatus.Outdated]: {
    icon: 'warning',
    color: 'var(--text-color-error-strong)',
  },
};

const errorTitle = computed(() => {
  if (props.showTokensUnsupportedTip) {
    return 'Sorry, Smart Tokens are not supported for this diagram type yet';
  }
  if (props.error) {
    return 'Invalid mermaid syntax';
  }
  return undefined;
});
</script>

<template>
  <BaseLayoutGap class="headline">
    <template v-if="!editorShown">
      <Icon name="graph-flow" class="headline-icon" no-padding />
      <template v-if="!empty">
        <SwText variant="body-XS">Diagram</SwText>
      </template>
      <template v-else>
        <SwText variant="body-XS" v-if="empty" data-testid="mermaid-empty-message"
          >Diagram is empty.<span v-if="isEditMode && !editorShown"> Click to edit</span></SwText
        >
      </template>
    </template>
    <template v-if="errorTitle">
      <VMenu theme="menu-no-arrow" :disabled="!error">
        <BaseLayoutGap class="invalid">
          <Icon data-testid="mermaid-error-icon" name="warning" class="headline-icon" no-padding />
          <SwText variant="body-XS" data-testid="mermaid-error">{{ errorTitle }}</SwText>
        </BaseLayoutGap>
        <template #popper>
          <pre data-testid="mermaid-error-details" class="error-text">{{ error }}</pre>
        </template>
      </VMenu>
    </template>
    <template v-else>
      <Icon
        v-if="hasTokens"
        :name="ApplicabilityStyle[applicability]?.icon"
        :style="{ color: ApplicabilityStyle[applicability]?.color }"
        class="headline-icon"
        no-padding
      />
    </template>
  </BaseLayoutGap>
</template>

<style scoped lang="postcss">
.headline {
  white-space: nowrap;

  .headline-icon {
    font-size: var(--fontsize-sm);
  }

  .invalid {
    color: var(--color-text-danger);
  }
}

.error-text {
  color: var(--color-text-danger);
  max-width: 400px;
  padding: var(--space-base);
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
