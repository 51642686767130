<script setup>
import { TextField } from '@swimm/ui';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { Action, SwText, TextSkeleton } from '@swimm/ui';
import { UrlUtils } from '@swimm/shared';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const ERROR_STRING = 'Something went wrong. Please try again';

const route = useRoute();

const loading = ref(true);

const email = ref('');
const newEmail = ref('');

const editMode = ref(false);
const valid = computed(() => !!email.value);
const error = ref('');

const shouldDisableSaveAction = computed(() => {
  return newEmail.value === email.value || !UrlUtils.isValidEmail(newEmail.value);
});

onMounted(async () => {
  const customer = await CloudFunctions.fetchSubscriptionCustomerDetails({ workspaceId: route.params.workspaceId });
  const initialEmail = customer.data?.email;
  email.value = initialEmail;
  newEmail.value = initialEmail;
  loading.value = false;
});

function onEditEmailClick() {
  newEmail.value = email.value;
  editMode.value = true;
}

async function onSaveEmailClick(newEmailAddress) {
  error.value = '';
  loading.value = true;
  const customer = await CloudFunctions.updateSubscriptionCustomerDetails({
    workspaceId: route.params.workspaceId,
    updateParams: {
      email: newEmailAddress,
    },
  });
  if (customer.data) {
    email.value = newEmailAddress;
    editMode.value = false;
  } else {
    error.value = ERROR_STRING;
  }
  loading.value = false;
}

function closeEditMode() {
  editMode.value = false;
  error.value = '';
  newEmail.value = email.value;
}
</script>

<template>
  <template v-if="loading">
    <TextSkeleton class="skeleton" variant="body-L" />
    <TextSkeleton class="skeleton short" variant="body-L" />
  </template>
  <template v-else-if="editMode">
    <TextField v-model="newEmail" :error="error" />
    <div class="actions">
      <Action
        class="save-email-action"
        size="small"
        :disabled="shouldDisableSaveAction"
        :loading="loading"
        @click="onSaveEmailClick(newEmail)"
        >Save</Action
      >
      <SwText variant="subtitle-L" class="cancel" @click="closeEditMode">Cancel</SwText>
    </div>
  </template>
  <template v-else-if="valid">
    <SwText variant="body-L">Invoices are sent to</SwText>
    <SwText variant="body-L" weight="bold">{{ email }}</SwText>
    <a class="link bold" @click="onEditEmailClick">
      <SwText variant="subtitle-S">Edit email</SwText>
    </a>
  </template>
  <SwText v-else variant="body-S" class="error">Something went wrong while processing billing contact.</SwText>
</template>

<style scoped lang="postcss">
.skeleton {
  margin-bottom: var(--space-xs);
  width: 70%;

  &.short {
    width: 50%;
  }
}

.link {
  cursor: pointer;
  display: inline-block;
  color: var(--text-color-link);
}

.bold {
  font-weight: bold;
}

.error {
  color: var(--text-color-error);
}

.actions {
  display: flex;
  align-items: center;
  gap: var(--space-base);
  margin-top: var(--space-base);

  .cancel {
    cursor: pointer;
  }
}
</style>
