<template>
  <EmptyState
    v-if="!loading && showBannerMessage"
    title="All docs are synced!"
    description="Docs in this repository are up to date. Check back again later."
    transparent
    class="empty-state"
  >
    <template #icon>
      <SwText variant="headline1" class="empty-state-emoji">🎉</SwText>
    </template>
  </EmptyState>
  <div v-else class="status-container">
    <section v-if="loading || statusRibbonContent.shouldShow">
      <Ribbon
        class="ribbon ribbon-narrow-width"
        :icon-name="statusRibbonContent.icon"
        :mode="statusRibbonContent.mode"
        :spinning-icon="statusRibbonContent.spinningIcon"
      >
        <template #description>
          <div class="ribbon-content">
            <SwText component="span" variant="body-L"
              ><SwText component="span" weight="bold">{{ statusRibbonContent.title }}</SwText
              >: {{ statusRibbonContent.description
              }}<span v-if="!loading">&nbsp;({{ loadedSwmCount }} / {{ totalSwmsToLoadCount }})</span></SwText
            >
            <ProgressBar class="progress-bar" :progress="loadedSwmPercentage * 100" />
          </div>
        </template>
      </Ribbon>
    </section>
    <section v-if="updatedUnitRibbon.shouldShow">
      <Ribbon
        class="ribbon ribbon-narrow-width"
        :icon-name="updatedUnitRibbon.icon"
        :ribbon-description="updatedUnitRibbon.description"
        :mode="updatedUnitRibbon.mode"
      ></Ribbon>
    </section>
    <section v-if="unapplicableSwms.length">
      <Ribbon v-if="!statusRibbonContent.shouldShow" class="ribbon outdated-ribbon" hide-icon mode="warning">
        <template #description> Fix outdated docs & <b> commit your changes </b> to save them to your repo. </template>
      </Ribbon>
      <ListWrapper show-titles status-mode>
        <DocStatusItem
          v-for="unit in unapplicableSwms"
          :key="unit.id"
          :unit="unit"
          :source="source"
          @commit-click="shouldOpenCommitModal = true"
        />
      </ListWrapper>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DocStatusItem from '@/modules/core/components/DocStatusItem.vue';
import ListWrapper from '@/modules/core/components/ListWrapper.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { docLoaderWorker } from '@/workers';
import { useGetStartedMenu } from '@/modules/get-started/composables/useGetStartedMenu';
import { useRepoDocsStore } from '@/modules/core/stores/repo-docs';
import ProgressBar from '@/common/components/atoms/ProgressBar.vue';

export default {
  components: {
    DocStatusItem,
    ListWrapper,
    ProgressBar,
  },
  beforeRouteEnter(to, from, next) {
    next((component) => {
      component.prevRoute = from;
    });
  },
  props: {
    repo: { type: Object, required: true },
    unapplicableSwms: { type: Array, required: true },
    loading: { type: Boolean, required: true },
    source: { type: String, default: null },
  },
  emits: ['finished-verifying'],
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const { shouldOpenCommitModal } = storeToRefs(useGetStartedMenu());
    const repoDocsStore = useRepoDocsStore();
    const { currentRepoNeedsReviewDocs, currentRepoDocsInBranch } = storeToRefs(repoDocsStore);

    return { user, shouldOpenCommitModal, currentRepoNeedsReviewDocs, currentRepoDocsInBranch };
  },
  data() {
    return {
      prevRoute: '',
    };
  },
  computed: {
    ...mapGetters('database', ['db_getUnits']),
    ...mapGetters('filesystem', ['fs_getRepository', 'fs_getSwmsIdsFromRepoFolder']),
    repoNotApplicableUnitsIds() {
      return Object.keys(this.currentRepoNeedsReviewDocs);
    },
    showBannerMessage() {
      if (this.isStillLoadingSwms) {
        return false;
      }
      return this.unapplicableSwms.length === 0;
    },
    totalSwmsToLoadCount() {
      return this.currentRepoDocsInBranch ? Object.keys(this.currentRepoDocsInBranch).length : 0;
    },
    loadedSwmCount() {
      const allLocalDocs = this.currentRepoDocsInBranch ? Object.values(this.currentRepoDocsInBranch) : [];
      const docsWithApplicability = allLocalDocs.filter((doc) => !!doc.applicabilityStatus);
      return docsWithApplicability.length;
    },
    loadedSwmPercentage() {
      if (this.loading) {
        return 0;
      }
      return this.loadedSwmCount / this.totalSwmsToLoadCount;
    },
    isStillLoadingSwms() {
      return this.loadedSwmCount < this.totalSwmsToLoadCount;
    },
    statusRibbonContent() {
      return {
        shouldShow: this.isStillLoadingSwms,
        title: 'Checking your documentation',
        description: 'Swimm is verifying that your documentation is up to date.',
        mode: 'close',
        icon: 'sync',
        spinningIcon: true,
      };
    },
    repoUnits() {
      return this.db_getUnits(this.$route.params.repoId);
    },
    updatedUnitRibbon() {
      // Check if previous route came from unit edit
      if (this.prevRoute.fullPath && this.prevRoute.fullPath.endsWith('/edit')) {
        // Get the unitId in question
        const unitId = this.prevRoute.params.unitId;
        if (unitId) {
          // Check if the unit is not `not applicable` anymore - means it is been update
          if (!this.repoNotApplicableUnitsIds.includes(unitId)) {
            // Show a good job ribbon for the user
            const ribbon = { shouldShow: true, mode: 'info', icon: 'check' };
            ribbon.description = `${this.repoUnits[unitId].name} is now up to date.`;
            return ribbon;
          }
        }
      }
      return { shouldShow: false };
    },
  },
  watch: {
    isStillLoadingSwms(val) {
      if (!val) {
        this.$emit('finished-verifying');
      }
    },
  },
  async created() {
    // Subscribe a callback to the webworker load messages that will be queued in setLocalRepoData
    docLoaderWorker.addListener(this.loadResponseCallback);
  },
  beforeUnmount() {
    docLoaderWorker.removeListener(this.loadResponseCallback);
  },
  methods: {
    ...mapActions('filesystem', ['handleLoadResponse']),
    async loadResponseCallback(event) {
      await this.handleLoadResponse(event);
    },
  },
};
</script>

<style scoped lang="postcss">
.section-header {
  display: flex;
  align-items: center;
  margin: 16px 0;
}

.header-icon {
  font-size: var(--headline2);
}

.header-title {
  margin: 0 15px 0 5px;
  font-size: var(--body-S);
  font-family: var(--fontfamily-secondary);
  font-weight: 600;
}

.swims {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-right: 80px;
}

.intro {
  padding-bottom: 15px;
  border-bottom: 1px solid lightgray;
}

.status-container {
  padding-top: var(--space-base);
}

.empty-state {
  margin-top: var(--space-lg);
  color: var(--text-color-secondary);

  .empty-state-emoji {
    padding: 5px;
  }
}

.ribbon-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-base);
}

.progress-bar {
  align-self: stretch;
}

.ribbon {
  margin-bottom: var(--space-md);

  &.outdated-ribbon {
    :deep(.message) {
      display: inline-block;
    }
  }
}
</style>
