<script setup>
import { WorkspaceOnboardingSteps } from '@/modules/core/services/workspace-onboarding-steps';
import { useBookDemo } from '@/modules/core/composables/book-demo';
import { useUserStore } from '@/modules/core/stores/user';
import BookDemoBanner from '@/modules/core/components/banners/BookDemoBanner.vue';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const { isUserWorkspaceAdmin } = storeToRefs(useUserStore());
const { openCalendlyDemoLink, reportBookDemoClick, reportRejectDemoClick } = useBookDemo();
const workspaceStore = useWorkspaceStore();
const { hasDummyRepo, hasRepos, onboardingStepsState } = storeToRefs(workspaceStore);
const { dismissOnboardingStep } = workspaceStore;

const workspaceNoRepoOrHasDummyRepo = computed(() => hasDummyRepo.value || !hasRepos.value);
const isWorkspaceDismissedDemo = computed(
  () => onboardingStepsState.value?.[WorkspaceOnboardingSteps.BOOK_DEMO_BANNER]?.dismiss
);
const showDemoWorkspaceBanner = computed(
  () => isUserWorkspaceAdmin.value && !isWorkspaceDismissedDemo.value && workspaceNoRepoOrHasDummyRepo.value
);

function onDismissDemo() {
  reportRejectDemoClick('Banner');
  dismissOnboardingStep(WorkspaceOnboardingSteps.BOOK_DEMO_BANNER);
}

function onBookDemo() {
  openCalendlyDemoLink();
  reportBookDemoClick('Banner');
  dismissOnboardingStep(WorkspaceOnboardingSteps.BOOK_DEMO_BANNER);
}
</script>

<template>
  <section class="workspace-banners">
    <BookDemoBanner v-if="showDemoWorkspaceBanner" @dismiss="onDismissDemo" @book-demo="onBookDemo" />
  </section>
</template>

<style scoped lang="postcss">
.workspace-banners {
  margin: var(--space-base) 0;
}
</style>
