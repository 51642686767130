import AiTextImprovementsPopover from '@/components/AiTextImprovementsPopover.vue';
import { Extension } from '@tiptap/core';
import { showEditorPopover } from '../popover';
import { productEvents } from '@swimm/shared';
import { getSwimmEditorServices } from './Swimm';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    aiTextImprovements: {
      improveTextUsingAI: () => ReturnType;
    };
  }
}

const EXCLUDED_BLOCK_TYPES = ['mermaid', 'codeBlock', 'image', 'blockImage', 'tableCell', 'tableRow'];

export default Extension.create({
  name: 'aiTextImprovements',

  addCommands() {
    return {
      improveTextUsingAI:
        () =>
        ({ state, dispatch, editor }) => {
          const range = state.selection;
          const swimmEditorServices = getSwimmEditorServices(editor);
          if (swimmEditorServices.external.isGenAiDisabledInWorkspace.value) {
            return false;
          }

          // Check if the selection contains a block.
          let blockFound = false;
          editor.state.doc.nodesBetween(range.from, range.to, (node) => {
            if (EXCLUDED_BLOCK_TYPES.includes(node.type?.name)) {
              blockFound = true;
              return false;
            }
          });
          if (blockFound) {
            return false;
          }

          if (dispatch) {
            setTimeout(async () => {
              swimmEditorServices.external.trackEvent(productEvents.CLICKED_IMPROVE_WITH_AI, {
                Platform: swimmEditorServices.editorEnvKind,
              });

              const isRepoAIGenerationEnabled = swimmEditorServices.external.isAIGenerationEnabledForRepo();
              if (!isRepoAIGenerationEnabled) {
                swimmEditorServices.setShowAiGenerationDisabledModal(true, 'Improve with AI');
                return;
              }

              let newText = '';
              await showEditorPopover(editor, AiTextImprovementsPopover, {
                initialText: state.doc.textBetween(range.from, range.to),
                onGenerated: (generatedText: string) => {
                  newText = generatedText;
                },
              });

              if (newText) {
                editor
                  .chain()
                  .focus()
                  .insertContentAt(range, {
                    type: 'text',
                    text: newText,
                  })
                  .setTextSelection({ from: range.from, to: range.from + newText.length })
                  .run();
              }
            }, 0);
          }

          return true;
        },
    };
  },
});
