import Joi from 'joi';

export const eventSchema = Joi.object().keys({
  // If a field is not `.required` - then it's optional
  swimmEventCode: Joi.string().required(),
  message: Joi.string(),
  value: Joi.string(),
  category: Joi.string().default('USER_ACTION'),
  userId: Joi.string().required(),
  userName: Joi.string().required(),
  userEmail: Joi.string().allow(null), // Optional: the email of the user
  actionName: Joi.string().required(), // I.e. "clicked help", "created a unit" ,"initialized a repo"
  app_version: Joi.string().allow(null),
  workspaceId: Joi.string(),
  workspaceName: Joi.string().allow(''),
  repoId: Joi.string(),
  repoName: Joi.string().allow(''),
  branch: Joi.string(),
  unitId: Joi.string(),
  playlistId: Joi.string(),
  playlistName: Joi.string().allow(''),
  platform: Joi.string(),
  ciVendor: Joi.string(),
  // Src and target: i.e added a unit to playlist unitId is srcId and playlist id is targetId
  srcId: Joi.string(), // Optional: the id of the resource in action
  srcName: Joi.string(), // Optional: the name of the resource in action
  srcType: Joi.string(), // Optional: the type of the resource in action
  targetId: Joi.string(), // Optional: the id of the resource the action targets
  targetName: Joi.string(), // Optional: the name of the resource the action targets
  isJoining: Joi.boolean(), // Optional: For signup events, if the user is about to join an existing workspace
  cost: Joi.number(), // Optional: Ask Swimm, the cost of the question
  analyticsData: Joi.object(), // Optional: for when we send the backoffice event through the analytics
});

// A fork of the basic log message schema, allowing events without user details
export const eventSchemaWithoutUser = eventSchema.fork(['userName', 'userId'], (schema) => schema.optional());
