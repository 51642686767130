// @ts-ignore
import { getLoggerNew } from '@swimm/shared';
import { useStore } from 'vuex';
import { type LocationQuery, useRoute, useRouter } from 'vue-router';
import { getRepoPath as getRepoPathUtil } from '@/router/router-utils';
import { docLoaderWorker } from '@/workers';
import { PageRoutesNames } from '../consts';

const logger = getLoggerNew(__modulename);

/**
 * The below methods is used for Programmatic Navigation
 * Always prioritize Declarative (<router-link :to="...">) over Programmatic navigation
 *  Use this method when router-link is not a good fit
 */
export function useNavigate() {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const cleanLoadedRepoData = (args) => store.dispatch('filesystem/cleanLoadedRepoData', args);

  function terminateWorker() {
    // Terminates the worker and creates a new worker instance to be used instead.
    // The restart "from outside" is needed to allow stopping the worker as it runs tasks synchronously (cannot get a signal for internal restart)
    docLoaderWorker.restartWorker();
  }

  async function navigateToPageAndTerminateWorker({
    newRoute,
    query,
    newBranch,
    replace = false,
  }: {
    newRoute?;
    query?;
    newBranch?;
    replace?;
  }) {
    terminateWorker();
    if (!newRoute) {
      logger.error(`Navigating to root as the new route path was not provided. Current route: ${route.fullPath}`, {
        module: 'helpers',
      });
      await router.push('/');
      return;
    }
    const newRouteObject: { path: string; query? } = { path: newRoute };
    newRouteObject.query = query ? query : {};
    const branchForURL: string = shouldPreserveBranch(newRouteObject, newBranch) ? route.params.branch : newBranch;
    if (branchForURL && newRouteObject.path.includes(`/repos`)) {
      if (!route.params.branch) {
        newRouteObject.path = newRouteObject.path.replace(
          `/repos/${route.params.repoId}`,
          `/repos/${route.params.repoId}/branch/${encodeURIComponent(branchForURL)}`
        );
      } else {
        const newBranchSection = `/branch/${encodeURIComponent(branchForURL)}`;
        const oldBranchSection = `/branch/${encodeURIComponent(<string>route.params.branch)}`;
        // replace only if this is the real branch name and not prefix of it
        // otherwise when switched from main to main-2 we got main-2-2
        // when called from navigateToBranch
        if (newRouteObject.path.endsWith(oldBranchSection) || newRouteObject.path.includes(oldBranchSection + '/')) {
          newRouteObject.path = newRouteObject.path.replace(oldBranchSection, newBranchSection);
        }
      }
      if (
        !newRouteObject.path.includes(encodeURIComponent(<string>route.params.branch)) &&
        newRouteObject.path.includes(<string>route.params.repoId)
      ) {
        cleanLoadedRepoData(<string>route.params.repoId);
      }
    }

    // Allow navigation only to a new route
    if (route.path !== newRouteObject.path || route.query !== newRouteObject.query) {
      const routerCall = replace ? router.replace : router.push;
      await routerCall.call(router, newRouteObject).catch((err) => {
        // Suppress NavigationDuplicated error: when switching between multiple routes fast we might still get NavigationDuplicated.
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    } else {
      logger.debug(`navigation to the same route skipped`, { module: 'helpers' });
    }
  }
  async function navigateToRepoPage(query, newBranch) {
    await navigateToPageAndTerminateWorker({ newRoute: getRepoPath(), query, newBranch });
  }
  function getRepoPath(repoId: string = <string>route.params.repoId, branch: string = <string>route.params.branch) {
    return getRepoPathUtil(route.params.workspaceId, repoId, branch);
  }
  /**
   * Keep the branch parameter only if the following are true:
   * Navigating to another place in the **same** repo
   * Navigating with a different branch param when there is no explicit new branch
   */
  function shouldPreserveBranch(newRouteObject, newBranch) {
    return (
      !newBranch &&
      route.params.branch &&
      route.path !== newRouteObject.path &&
      newRouteObject.path.includes(route.params.repoId)
    );
  }

  async function navigateToBranch(newBranch: string, toRepoPage = false) {
    // added so we can debug the double navigation bug
    logger.info(`navigateToBranch to branch newBranch=${newBranch} toRepoPage=${toRepoPage}`);
    cleanLoadedRepoData(route.params.repoId);
    if (newBranch !== route.params.branch) {
      const query: LocationQuery = {};
      let targetPath = route.fullPath;
      if (toRepoPage) {
        const targetBranchRepoRouteData = router.resolve({
          name: PageRoutesNames.REPO_PAGE,
          params: {
            workspaceId: route.params.workspaceId,
            repoId: route.params.repoId,
            branch: newBranch,
          },
        });
        targetPath = targetBranchRepoRouteData.fullPath;
      }
      await navigateToPageAndTerminateWorker({
        newRoute: targetPath,
        newBranch: newBranch,
        query,
      });
    }
  }

  return {
    terminateWorker,
    navigateToPageAndTerminateWorker,
    navigateToRepoPage,
    getRepoPath,
    shouldPreserveBranch,
    navigateToBranch,
  };
}
