<template>
  <article :class="['resource-wrapper', 'clickable', { 'selected-action': isSelected }]" @click="toggleSelection">
    <div class="resource-data">
      <div v-if="isSelected" class="top-actions added">
        <Icon class="action-icon" name="minimize" />
        <div class="action-text">{{ resourceIndexInSequence }}</div>
      </div>
      <div v-else class="top-actions clickable">
        <Icon name="add" />
      </div>
      <Icon :name="iconName" class="resource-icon" />
      <div class="resource-name" ref="resourceName">
        <SwText class="resource-name-text" variant="body-L" v-tooltip="optionalResourceTooltip">
          {{ resource.name }}<span class="draft-label">{{ optionalDraftSuffix(resource) }}</span>
        </SwText>
      </div>
    </div>
    <div class="resource-repo">
      <ResourceChip
        v-if="repo"
        :resource-name="repo.metadata.name"
        :resource-icon="repoIcon"
        :padded="false"
        class="resource-chip"
      >
        <template #custom-chip-text="{ resourceName }">
          <SwText class="chip-text" variant="body-S" v-tooltip="resourceName">{{ resourceName }}</SwText>
        </template>
      </ResourceChip>
    </div>
  </article>
</template>

<script>
import { getGitProviderIconName } from '@swimm/shared';
import { useSwimmResource } from '@/common/composables/swimmResource';

export default {
  props: {
    resource: { type: Object, required: true },
    repo: { type: Object, default: null },
    sequence: { type: Array, required: true },
    editSequence: {
      type: Object,
      add: { type: Function, required: true },
      remove: { type: Function, required: true },
      required: true,
    },
  },
  data() {
    return { optionalResourceTooltip: null };
  },
  setup() {
    const { getResourceIconByState } = useSwimmResource();
    return { getResourceIconByState };
  },
  mounted() {
    if (this.$refs.resourceName.children[0].clientWidth === this.$refs.resourceName.clientWidth) {
      this.optionalResourceTooltip = this.resource.name;
    }
  },
  computed: {
    isSelected() {
      return this.sequence.some((id) => this.resource.id === id);
    },
    resourceIndexInSequence() {
      return this.sequence.findIndex((id) => id === this.resource.id) + 1;
    },
    iconName() {
      return this.getResourceIconByState({ resource: this.resource, repoId: this.resource.repoId });
    },
    repoIcon() {
      return getGitProviderIconName(this.repo.metadata.provider);
    },
  },
  methods: {
    toggleSelection() {
      if (this.isSelected) {
        this.editSequence.remove(this.resource.id);
      } else {
        this.editSequence.add(this.resource.id);
      }
    },
    optionalDraftSuffix(resource) {
      return resource.isDraftLink ? ' (Draft)' : '';
    },
  },
};
</script>

<style scoped>
.resource-wrapper {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}

.resource-wrapper:hover:not(.selected-action) {
  background: var(--color-hover);
}

.selected-action {
  background: var(--color-status-info);
}

.resource-data {
  display: flex;
}

.resource-chip {
  width: 108px;
}

.chip-text {
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.resource-repo {
  margin-right: 16px;
}

.resource-icon {
  font-size: var(--headline2);
  align-items: center;
  display: flex;
}

.resource-name {
  width: 232px;
  display: flex;
  align-items: center;
}

.resource-name-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.draft-label {
  color: var(--text-color-secondary);
}

.top-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin: 8px 8px 8px 16px;
  border-radius: 50%;
  color: var(--text-color-secondary);
}

.top-actions i {
  font-weight: bold;
}

.top-actions:hover {
  color: var(--text-color-on-dark);
  background: var(--color-brand);
  opacity: 1;
  cursor: pointer;
}

.added {
  color: var(--text-color-on-dark);
  background: var(--color-brand);
}

.action-icon {
  display: none;
}

.top-actions:hover .action-icon {
  display: block;
}

.top-actions:hover .action-text {
  display: none;
}
</style>
