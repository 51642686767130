<script setup lang="ts">
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';
import { AIGenerationDisabledModalInner } from '@swimm/editor';
import type { Editor } from '@tiptap/vue-3';
import { computed } from 'vue';

const props = defineProps<{
  editor: Editor;
}>();

const swimmEditorServices = computed(() => getSwimmEditorServices(props.editor));
const isModalShown = computed(() => swimmEditorServices.value.showAiGenerationDisabledModal.value);
const isAdmin = computed(() => swimmEditorServices.value.external.isWorkspaceAdmin.value);
</script>

<template>
  <AIGenerationDisabledModalInner
    :modal-opened="isModalShown"
    :is-admin="isAdmin"
    @enable="swimmEditorServices.external.openGenerativeAiRepoSettings()"
    @close="swimmEditorServices.setShowAiGenerationDisabledModal(false)"
  />
</template>
