<script setup lang="ts">
import { useAnalytics } from '@/common/composables/useAnalytics';
import WorkspaceSettingsBilling from '@/modules/workspace/modals/settings/billing/WorkspaceSettingsBilling.vue';
import WorkspaceSettingsGeneral from '@/modules/workspace/modals/settings/WorkspaceSettingsGeneral.vue';
import WorkspaceSettingsAdvanced from '@/modules/workspace/modals/settings/WorkspaceSettingsAdvanced.vue';
import WorkspaceSettingsAiAndFeatureAccess from '@/modules/workspace/modals/settings/WorkspaceSettingsAiAndFeatureAccess.vue';
import WorkspaceSettingsOauth from './settings/WorkspaceSettingsOAuth.vue';

import WorkspaceSettingsUsers from '@/modules/workspace/modals/settings/WorkspaceSettingsUsers.vue';
import WorkspaceSettingsApiKeys from '@/modules/workspace/modals/settings/WorkspaceSettingsApiKeys.vue';
import { productEvents, workspaceSettingsTabs } from '@swimm/shared';
import SettingsModalBase from '@/modules/core/components/settings-modal/SettingsModalBase.vue';
import { useBillingStore } from '@/modules/billing/store/billing';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useWorkspaceSettingsModalStore } from './settings/store/workspace-settings';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';

const billing = useBillingStore();
const store = useStore();
const route = useRoute();
const router = useRouter();

const props = defineProps({
  show: { type: Boolean, required: true },
  origin: { type: String, default: '' },
  initialTabCode: { type: String, default: '' },
  initialTabPhase: { type: String, default: '' },
});
const emit = defineEmits(['close']);

const analytics = useAnalytics();

const changeToTabCode = ref('');

const { user } = storeToRefs(useAuthStore());
const { providerLongDisplayName } = storeToRefs(useWorkspaceStore());
const { closeWorkspaceSettingsModal } = useWorkspaceSettingsModalStore();
const isWorkspaceAdmin = computed(() =>
  store.getters['database/db_isWorkspaceAdmin'](route.params.workspaceId, user.value.uid)
);

const workspaceData = computed(() => store.getters['database/db_getWorkspace'](route.params.workspaceId));

const isApiKeyEnabledInWorkspace = computed(() => {
  return workspaceData.value?.settings?.is_api_key_enabled ?? false;
});

const hideAskSwimm = computed(() => {
  return workspaceData.value?.hide_ask_swimm ?? false;
});

const disableGenAi = computed(() => {
  return workspaceData.value?.settings?.disable_gen_ai ?? false;
});

const WorkspaceTabs = computed(() => {
  const tabs = {};
  if (isWorkspaceAdmin.value) {
    tabs[workspaceSettingsTabs.GENERAL_SETTINGS] = {
      code: workspaceSettingsTabs.GENERAL_SETTINGS,
      title: 'General',
      event: productEvents.VIEW_GENERAL_SETTINGS_TAB,
    };
  }
  tabs[workspaceSettingsTabs.MEMBERS] = {
    code: workspaceSettingsTabs.MEMBERS,
    title: 'Members',
    event: productEvents.VIEW_USERS_TAB,
  };
  if (isWorkspaceAdmin.value) {
    tabs[workspaceSettingsTabs.BILLING] = {
      code: workspaceSettingsTabs.BILLING,
      title: 'Billing & plans',
      event: productEvents.VIEW_BILLING_TAB,
    };
  }

  if (isWorkspaceAdmin.value && !(hideAskSwimm.value && disableGenAi.value)) {
    tabs[workspaceSettingsTabs.AI_AND_FEATURE_ACCESS] = {
      code: workspaceSettingsTabs.AI_AND_FEATURE_ACCESS,
      title: 'AI & feature access',
      event: productEvents.VIEW_AI_AND_FEATURE_ACCESS_TAB,
    };
  }

  if (isWorkspaceAdmin.value && isApiKeyEnabledInWorkspace.value) {
    tabs[workspaceSettingsTabs.API_KEYS] = {
      code: workspaceSettingsTabs.API_KEYS,
      title: 'API Keys',
      event: productEvents.VIEW_API_KEYS_TAB,
    };
  }

  if (isWorkspaceAdmin.value) {
    tabs[workspaceSettingsTabs.ADVANCED] = {
      code: workspaceSettingsTabs.ADVANCED,
      title: 'Advanced',
      event: productEvents.VIEW_WORKSPACE_ADVANCED_TAB,
    };
    if (providerLongDisplayName.value === 'GitHub Enterprise') {
      tabs[workspaceSettingsTabs.OAUTH] = {
        code: workspaceSettingsTabs.OAUTH,
        title: 'OAuth connections',
        event: productEvents.VIEW_OAUTH_SETUP_TAB,
      };
    }
  }
  return tabs;
});

function reportTabSelected(tabSelectedCode) {
  const tab = WorkspaceTabs.value[tabSelectedCode];
  if (tab) {
    analytics.track(tab.event, {
      Origin: props.origin,
      Type: 'Popup',
    });
  }
}

function closeModal() {
  closeWorkspaceSettingsModal();
  emit('close');
  const query = {
    ...(route.query || {}),
    modal: undefined,
    tab: undefined,
    plan: undefined,
    period: undefined,
    code: undefined,
    quantity: undefined,
    country: undefined,
  };
  router.replace({ query });
}

watch(
  () => billing.showBillingPlans,
  (newValue) => {
    if (newValue) {
      changeToTabCode.value = workspaceSettingsTabs.BILLING;
    } else {
      changeToTabCode.value = '';
    }
  }
);
</script>

<template>
  <SettingsModalBase
    :show="show"
    :tabs="Object.values(WorkspaceTabs)"
    :initial-tab-code="initialTabCode"
    :change-to-tab-code="changeToTabCode"
    header="Workspace settings"
    @tab-selected="reportTabSelected"
    @close="closeModal"
  >
    <template #content="{ selectedTabCode }">
      <WorkspaceSettingsGeneral v-if="selectedTabCode === workspaceSettingsTabs.GENERAL_SETTINGS" />
      <WorkspaceSettingsApiKeys v-if="selectedTabCode === workspaceSettingsTabs.API_KEYS" />
      <WorkspaceSettingsUsers v-if="selectedTabCode === workspaceSettingsTabs.MEMBERS" />
      <WorkspaceSettingsBilling
        v-if="selectedTabCode === workspaceSettingsTabs.BILLING"
        :initial-tab-phase="initialTabPhase"
      />
      <WorkspaceSettingsAiAndFeatureAccess v-if="selectedTabCode === workspaceSettingsTabs.AI_AND_FEATURE_ACCESS" />
      <WorkspaceSettingsAdvanced v-if="selectedTabCode === workspaceSettingsTabs.ADVANCED" />
      <WorkspaceSettingsOauth v-if="selectedTabCode === workspaceSettingsTabs.OAUTH" />
    </template>
  </SettingsModalBase>
</template>
