<template>
  <div v-if="loading" class="view-loader">
    <Loader />
  </div>
  <div v-else class="playlist-step">
    <Ribbon
      v-if="isEditMode && !isDocEditable"
      class="remote-repo-doc-ribbon"
      mode="close"
      icon-name="eye"
      ribbon-title="View Mode Only"
      :ribbon-description="
        isDocFromAnotherRepo
          ? 'This doc is saved in another repo and therefore is view only. Go to the main doc in the repo to make changes.'
          : 'This doc cannot be edited inside playlist.'
      "
    >
      <router-link :to="`${getRepoPath(repoId)}/docs/${swimmId}/edit`">
        <Action secondary size="small"><Icon name="edit-outline" /> Edit doc</Action>
      </router-link>
    </Ribbon>
    <LinkResolver
      v-if="shouldShowExternalLink"
      :key="swimmId"
      :link="swimm"
      @toggle-mark="handleLinkStepStatusChange"
    />
    <PlaylistComponent
      v-else-if="shouldShowPlaylist"
      :key="`{swimmId}-playlist`"
      :repo-id="repoId"
      :swimm-id="swimmId"
      :playlist="swimm"
    />
    <template v-else-if="shouldShowDoc">
      <EditorPane
        :editable="isDocEditable"
        :repo-id="repoId"
        :workspace-id="workspaceId"
        :unit-id="swimmId"
        :branch="currentBranch"
        :should-increment-view-count="!isEditMode"
        :should-show-toggle-done="!isDocEditable"
        :committed="isDocFromAnotherRepo"
        :is-cross-repo="isDocFromAnotherRepo"
        :should-force-close-side-bar="true"
        @navigate-to-edit="editDocClicked"
        @toggle-mark="handleDocStepStatusChange"
      >
        <template #playlist-status v-if="!isDocEditable">
          <div class="resource-headline-status-wrapper">
            <ResourceHeadlineStatus
              resource-type="DOC"
              :resource-status="computedStepStatus"
              :resource-repo="computedStepRepoDetails"
            />
          </div>
        </template>
      </EditorPane>
    </template>
    <Exercise v-else-if="shouldShowExercise" :key="`${swimmId}-exercise`" :repo-id="repoId" :exercise-id="swimmId" />
    <Loader v-else />
  </div>
</template>

<script lang="ts">
import Exercise from '@/common/components/organisms/Exercise.vue';
import EditorPane from '@/modules/editor3/components/EditorPane.vue';
import LinkResolver from '@/common/components/Resolver/LinkResolver.vue';
import PlaylistComponent from '@/common/components/organisms/Playlist.vue';
import {
  ApplicabilityStatus,
  SwimmResourceUserStatus,
  UrlUtils,
  config,
  eventLogger,
  getGitProviderIconName,
  getLoggerNew,
  isSwmDoc,
  isSwmExercise,
  objectUtils,
  productEvents,
} from '@swimm/shared';
import { mapActions, mapGetters } from 'vuex';
import { useSwimmResource } from '@/common/composables/swimmResource';
import { useNavigate } from '@/common/composables/navigate';
import { useRouting } from '@/common/composables/routing';
import Ribbon from '@/common/components/atoms/Ribbon.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { type Playlist, type PlaylistSequenceStep, PlaylistSequenceStepTypes } from '@/modules/playlists/types';
import { defineComponent } from 'vue';
import ResourceHeadlineStatus from '../organisms/ResourceHeadlineStatus.vue';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';

const logger = getLoggerNew(__modulename);

export default defineComponent({
  components: { EditorPane, LinkResolver, Exercise, PlaylistComponent, Ribbon, ResourceHeadlineStatus },
  beforeRouteLeave(to, from, next) {
    if (this.computedStep.type !== 'playlist' || to.path.includes('/playlist/')) {
      // If the navigation was not triggered from a playlist page
      // or the naviagation is inside the playlist
      // or if navigate to another playlist route to that playlist
      // continue to the set route
      next();
    } else {
      // Link is clicked inside a playlist step:
      // get the clicked link from the `to` value (navigate route to the doc/exercise that was clicked from the playlist)
      // navigate to the playlist itself (instead to the resource) and set the step index of the requiered resource.
      const swimmInRoute = to.params.unitId;
      if (!swimmInRoute) {
        // If no swimm in route continue
        next();
        return;
      }
      const stepIndex = this.swimm.sequence.findIndex((swimm) => swimm.id === swimmInRoute);
      const routeTo = `${this.getRepoPath(this.repoId)}/playlists/${this.swimmId}${
        stepIndex !== -1 ? '/steps/' + stepIndex : ''
      }`;
      this.navigateToPageAndTerminateWorker({ newRoute: routeTo });
    }
  },
  props: {
    stepIndex: { type: String, required: true },
    step: { type: Object, default: null },
    isEditMode: { type: Boolean, default: false },
  },
  emits: ['handle-step-saved'],
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const { isSwimmAvailable } = useSwimmResource();
    const { navigateToPageAndTerminateWorker, getRepoPath } = useNavigate();
    const { getCurrentOrDefaultBranch } = useRouting();
    const { logEvent } = useSwimmEventLogs();
    const analytics = useAnalytics();
    const drafts3Store = useDrafts3Store();
    return {
      user,
      analytics,
      isSwimmAvailable,
      navigateToPageAndTerminateWorker,
      getRepoPath,
      getCurrentOrDefaultBranch,
      logEvent,
      drafts3Store,
    };
  },
  data() {
    return {
      currentStep: {} as PlaylistSequenceStep,
      fs_playlist: {} as Playlist,
      loading: true,
      currentBranch: '',
    };
  },
  computed: {
    ...mapGetters('database', ['db_getSwimm', 'db_getSwimmStatus', 'db_getRepoMetadata']),
    ...mapGetters('filesystem', [
      'fs_getUnitFromLocalRepo',
      'fs_getUnitApplicabilityState',
      'fs_getPlaylistFromLocalRepo',
    ]),
    playlistId() {
      return this.$route.params.playlistId as string;
    },
    playlistRepoId() {
      return this.$route.params.repoId as string;
    },
    workspaceId() {
      return this.$route.params.workspaceId as string;
    },
    unitStatus() {
      return this.fs_getUnitApplicabilityState(this.swimmId, this.repoId);
    },
    isUnitAvailable() {
      return this.isSwimmAvailable(this.repoId, this.swimmId);
    },
    shouldShowExercise() {
      if (!this.swimmFromDb || !isSwmExercise({ swm: this.swimmFromDb, swimmTypeFromDb: this.swimmFromDb.type })) {
        return false;
      }
      return this.isExerciseValidForDisplaying;
    },
    isExerciseValidForDisplaying() {
      // TODO: remove this logic when the exercise component will handle fetching the data by itself
      return this.fs_getUnitApplicabilityState(this.swimmId, this.repoId) === ApplicabilityStatus.Invalid;
    },
    shouldShowDoc() {
      return (
        !this.shouldShowExternalLink &&
        !this.shouldShowPlaylist &&
        (!this.swimmFromDb || isSwmDoc({ swm: this.swimmFromDb, swimmTypeFromDb: this.swimmFromDb.type }))
      );
    },
    shouldShowExternalLink() {
      return !!this.swimm && PlaylistSequenceStepTypes.EXTERNAL_LINK === this.swimm.type;
    },
    shouldShowPlaylist() {
      return PlaylistSequenceStepTypes.PLAYLIST === this.computedStep.type;
    },
    swimmFromDb() {
      return this.db_getSwimm(this.repoId, this.swimmId);
    },
    swimmName() {
      return this.swimm.name;
    },
    swimm() {
      if (PlaylistSequenceStepTypes.EXTERNAL_LINK === this.computedStep.type) {
        return this.computedStep;
      }
      if (this.swimmId) {
        if (this.computedStep.type === PlaylistSequenceStepTypes.PLAYLIST) {
          if (this.isEditMode) {
            const stepPlaylistDraft = this.drafts3Store.drafts.get(this.swimmId);
            if (stepPlaylistDraft) {
              return { ...stepPlaylistDraft.content, id: this.swimmId };
            }
          }
          return this.fs_getPlaylistFromLocalRepo(this.swimmId, this.repoId);
        }
        if (this.isUnitAvailable) {
          return this.fs_getUnitFromLocalRepo(this.swimmId, this.repoId);
        }
      }
      return null;
    },
    repoId() {
      return !objectUtils.isEmpty(this.computedStep) ? this.computedStep.repoId : '';
    },
    swimmId() {
      return !objectUtils.isEmpty(this.computedStep) ? this.computedStep.id : '';
    },
    computedStep() {
      if (this.step) {
        return this.step;
      }

      return this.currentStep;
    },
    computedStepStatus() {
      const step = this.computedStep;
      if (step) {
        return this.db_getSwimmStatus(step.repoId, this.user.uid, step.id) ?? config.SWIMMER_STATUSES.NOT_STARTED;
      }
      return config.SWIMMER_STATUSES.NOT_STARTED;
    },
    computedStepRepoDetails() {
      const step = this.computedStep;
      if (step) {
        const stepRepoMetadata = this.db_getRepoMetadata(step.repoId);
        if (stepRepoMetadata) {
          return {
            id: step.repoId,
            name: stepRepoMetadata.name,
            imgSrc: stepRepoMetadata.logo,
            icon: stepRepoMetadata.provider && getGitProviderIconName(stepRepoMetadata.provider),
          };
        } else {
          logger.error(`no repo data for ${step.repoId}`, {
            service: 'doc',
          });
        }
      }
      return null;
    },
    isDocFromAnotherRepo() {
      return this.shouldShowDoc && this.repoId !== this.playlistRepoId;
    },
    isDocEditable() {
      return this.isEditMode && !this.isDocFromAnotherRepo;
    },
  },
  async created() {
    if (!this.step) {
      await this.setCurrentStep();
    }
    await this.setCurrentBranch();
    this.loading = false;

    this.$watch('$route.params.stepIndex', async () => {
      this.loading = true;
      this.currentStep = {} as PlaylistSequenceStep;
      await this.setCurrentStep();
      await this.setCurrentBranch();
      this.loading = false;
    });
  },
  methods: {
    ...mapActions('database', ['updateSwimmerStatus']),
    editDocClicked() {
      this.navigateToPageAndTerminateWorker({
        newRoute: `${this.getRepoPath()}/playlists/${this.playlistId}/edit`,
        query: { step: this.stepIndex },
      });
    },
    handleDraftSaved() {
      this.$emit('handle-step-saved');
    },
    async setCurrentBranch() {
      // this function is called also when you unmount the page
      // so we guard against no repoId
      if (this.repoId) {
        this.currentBranch = await this.getCurrentOrDefaultBranch(this.repoId);
      }
    },
    async setCurrentStep() {
      this.fs_playlist = this.fs_getPlaylistFromLocalRepo(this.playlistId, this.playlistRepoId);
      if (!objectUtils.isEmpty(this.fs_playlist)) {
        const step = this.fs_playlist.sequence[this.stepIndex];
        this.currentStep = step || {};
      }
    },
    async handleDocStepStatusChange(stepStatus: SwimmResourceUserStatus) {
      if (!this.swimmFromDb) {
        return;
      }
      const docName = this.swimmFromDb.name;
      const logMessage = {
        value: stepStatus,
        srcId: this.swimmId,
        srcName: docName,
        srcType: 'unit',
        playlistId: this.playlistId,
      };
      this.logEvent(eventLogger.SWIMM_EVENTS.DOC_SWIMMER_STATUS_UPDATED, logMessage);
      this.analytics.track(productEvents.CLICKED_MARK_AS_READ, {
        Context: 'Footer',
        Origin: 'View Document',
        'Repo ID': this.repoId,
        'Document ID': this.swimmId,
        Action: stepStatus === config.SWIMMER_STATUSES.DONE ? 'Read' : 'Unread',
      });
      await this.handleStepStatusChange(stepStatus);
    },
    async handleLinkStepStatusChange(stepStatus: SwimmResourceUserStatus) {
      this.logEvent(eventLogger.SWIMM_EVENTS.EXTERNAL_LINK_SWIMMER_STATUS_UPDATED, {
        value: stepStatus,
        srcId: this.swimm.id,
        srcName: this.swimm.url,
        srcType: UrlUtils.getLinkType(this.swimm.url),
      });
      await this.handleStepStatusChange(stepStatus);
    },
    async handleStepStatusChange(stepStatus: SwimmResourceUserStatus) {
      // we update only if mark as done on step
      if (stepStatus === config.SWIMMER_STATUSES.DONE) {
        const isPlaylistDone =
          this.fs_playlist.sequence &&
          this.fs_playlist.sequence.every(
            (step) => this.db_getSwimmStatus(step.repoId, this.user.uid, step.id) === config.SWIMMER_STATUSES.DONE
          );
        const playlistStatus = isPlaylistDone ? config.SWIMMER_STATUSES.DONE : config.SWIMMER_STATUSES.STARTED;
        await this.updateSwimmerStatus({
          repoId: this.playlistRepoId,
          userId: this.user.uid,
          resourceId: this.playlistId,
          status: playlistStatus,
        });
      }
    },
  },
});
</script>

<style scoped>
.swimm {
  margin: auto;
  max-width: var(--narrow-content-width);
}

.playlist-step {
  height: 100%;
  overflow-y: auto;
}

.remote-repo-doc-ribbon {
  max-width: min(var(--narrow-content-width), 100vw - 650px);
  margin: var(--space-sm) auto;
}

.resource-headline-status-wrapper {
  width: 100%;
  margin: var(--space-large) auto 0;
  padding: 0 calc(var(--space-large) + var(--space-small));
  max-width: 1040px;
  box-sizing: border-box;
}
</style>
