<script setup lang="ts">
import type { ComponentExposed } from 'vue-component-type-helpers';

import type { User } from '../../types/transitional';

import SwmInlineSelectionContent from '../SwmInlineSelectionContent/SwmInlineSelectionContent.vue';
import SwmSelectionFooter from '../SwmSelectionFooter/SwmSelectionFooter.vue';
import MenuItemUser from '../MenuItemUser/MenuItemUser.vue';

import BaseKeyCommand from '../../components/BaseKeyCommand/BaseKeyCommand.vue';
import { computed, ref } from 'vue';

withDefaults(
  defineProps<{
    query?: string;
    users?: User[];
    loading?: boolean;
    loadingMore?: boolean;
    command: (user: User) => boolean;
  }>(),
  {
    query: '',
    users: undefined,
    error: undefined,
  }
);

const emit = defineEmits<{
  selectUser: [user: User];
  focusedUser: [user: User];
  hoveredUser: [user: User];
}>();

const selectionInlineContent = ref<ComponentExposed<typeof SwmInlineSelectionContent>>();
const onKeyDown = computed(() => selectionInlineContent.value?.onKeyDown);

function onSelectUser(user: User) {
  emit('selectUser', user);
}

function onFocusedUser(user: User) {
  emit('focusedUser', user);
}

function onHoveredUser(user: User) {
  emit('hoveredUser', user);
}

defineExpose({ onKeyDown });
</script>

<template>
  <SwmInlineSelectionContent
    ref="selectionInlineContent"
    :query="query"
    :items="(users as User[])"
    :command="command"
    :loading="loading"
    :loading-more="loadingMore"
    @focused-item="onFocusedUser"
  >
    <template #items="{ setItemRef, paginatedItems, isFocused }">
      <MenuItemUser
        v-for="(item, index) in paginatedItems"
        :key="item.id"
        :ref="(el: any) => { 
          if (el?.root) {
            setItemRef(el.root, index) 
          } 
        }"
        :query="query"
        :user="item"
        :focused="isFocused(index)"
        @click="onSelectUser(item)"
        @keydown.enter="onSelectUser(item)"
        @mouseenter="onHoveredUser(item)"
      />
    </template>

    <template #footer>
      <SwmSelectionFooter>
        <span>Navigate <BaseKeyCommand :keys="['↑']" /> <BaseKeyCommand :keys="['↓']" /></span>
        <span>Close <BaseKeyCommand :keys="['Escape']" /></span>
      </SwmSelectionFooter>
    </template>
  </SwmInlineSelectionContent>
</template>

<style lang="scss" scoped>
.swm-selection-content-mention {
  $self: &;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 400px;
  max-width: 560px;
  width: 100%;

  &__list {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
  }
}
</style>
