export const STORES = {
  USER: 'user',
  WORKSPACE: 'workspace',
  DEMO: 'demo',
  BATCH_IMPORT: 'batch-import',
  EVENTS: 'events',
  CONTENT_SUGGESTIONS: 'content-suggestions',
  CLOUD_DOCS_FILTERS: 'cloud-docs-filters',
  DOCUMENTATION_FILTERS: 'documentation-filters',
  NOTIFICATIONS: 'notifications',
  HELP_TOOLTIPS: 'help-tooltips',
  DOC_SIDEBAR: 'doc-sidebar',
  DRAFTS: 'drafts',
  GET_STARTED: 'get-started',
  HELP_CENTER: 'help-center',
  ONBOARDING_SGD: 'onboarding-sgd',
  AUTH: 'auth',
  GIT_AUTHORIZATION: 'git-authorization',
  GLOBAL_STORE: 'global-store',
  TEMPLATES_STORE: 'templates-store',
  GLOBAL_MODAL_STORE: 'global-modal-store',
  REPOS: 'repos',
  REPO_DOCS: 'repo-docs',
  PLAYLIST: 'playlist',
  EDIT_PLAYLIST: 'edit-playlist',
  GLOBAL_SEARCH: 'globalSearchStore',
  BILLING: 'billing',
  APP_MODAL_STORE: 'app-modal-store',
  BATCH_COMMIT: 'batch-commit-store',
  USER_CONFIG: 'user-config',
  FOLDERS: 'folders',
  WORKSPACE_SETTINGS: 'workspace-settings',
  CREATION_HUB: 'creation-hub',
  WORKSPACE_SIDEBAR: 'workspace-sidebar',
} as const;
