import type { NodeViewRenderer } from '@tiptap/core';
import { Node } from '@tiptap/core';
import { Fragment } from '@tiptap/pm/model';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import EditorPlaceholderText from '@/components/EditorComponents/EditorPlaceholderText.vue';
import type { SwmSymbolTextPlaceholder } from '@swimm/shared';
import { useTiptapEditorStateStore } from '@/stores/nodeViewsStore';
import { SwmSymbolType } from '@swimm/shared';

type TextPlaceholderAttributes = {
  id: string;
  ['text-placeholder-id']: string;
  value: string;
} & Omit<SwmSymbolTextPlaceholder, 'type' | 'applicability'>;

type SerializedTextPlaceholderHTMLAttributes = Record<keyof Omit<TextPlaceholderAttributes, 'id'>, string | undefined>;

const textPlaceholderExtension = Node.create({
  name: 'text-placeholder',
  group: 'inline',
  inline: true,
  selectable: false,

  addNodeView(): NodeViewRenderer {
    return VueNodeViewRenderer(EditorPlaceholderText);
  },
  addAttributes(): { [K in keyof TextPlaceholderAttributes]: { default: TextPlaceholderAttributes[K] } } {
    return {
      id: { default: '' },
      ['text-placeholder-id']: { default: '' },
      text: { default: '' },
      value: { default: '' },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'input[text-placeholder-id]',
        getAttrs: (dom: string | HTMLElement): TextPlaceholderAttributes => {
          const element = dom as Element;
          const id = element.getAttribute('text-placeholder-id') ?? '';

          const DEFAULT_TEXT = '';
          const DEFAULT_VALUE = '';

          const aggregatedSymbols = useTiptapEditorStateStore().aggregatedSymbols;
          let symbol = aggregatedSymbols[id] as SwmSymbolTextPlaceholder;
          if (!symbol) {
            // This happens when the token is copy-pasted from another document.
            const rawValue = element.getAttribute('value');
            const rawText = element.getAttribute(':placeholder');
            symbol = {
              id,
              type: SwmSymbolType.TEXT_PLACEHOLDER,
              text: rawText ?? DEFAULT_TEXT,
              value: rawValue ?? DEFAULT_VALUE,
            };
            // Import this symbol as it is new - was copied from another document.
            aggregatedSymbols[id] = symbol;
          }
          return {
            id,
            'text-placeholder-id': id,
            text: symbol.text ?? DEFAULT_TEXT,
            value: symbol.value ?? DEFAULT_VALUE,
          };
        },
        getContent: (dom, schema) => {
          return Fragment.fromJSON(schema, [
            { type: 'text', text: `${(dom as HTMLElement).getAttribute(':placeholder')}` },
          ]);
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }): [string, SerializedTextPlaceholderHTMLAttributes, string] {
    const { id, text, value } = HTMLAttributes as TextPlaceholderAttributes;
    return [
      'input',
      {
        'text-placeholder-id': id,
        value,
        text,
      },
      text,
    ];
  },
});

export default textPlaceholderExtension;
