import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { getLoggerNew } from '@swimm/shared';
import { StatusCodes } from 'http-status-codes';
import type firebase from 'firebase/compat/app';
import type {
  createWorkspaceInSalesforceTypes,
  updateLoginInSalesforceTypes,
  updateRepoInSalesforceTypes,
  updateSalesforceLeadWithGitHostingTypes,
  updateSignupInSalesforceTypes,
  updateWorkspaceInSalesforceTypes,
  updateWorkspaceUserInSalesforceTypes,
} from '@/common/utils/salesforce/salesforce-types';
type HttpsCallableResult = firebase.functions.HttpsCallableResult;

const logger = getLoggerNew(__modulename);

async function activateSalesforceFunction<T extends (data: Parameters<T>[0]) => Promise<HttpsCallableResult>>(
  salesforceFunction: T,
  args: Parameters<T>[0]
): Promise<null | string> {
  logger.info(`Calling cloud function ${salesforceFunction.name} with args: ${JSON.stringify(args)}`);
  try {
    const result = await salesforceFunction({ ...args });
    if (!result?.data || result.data.code !== StatusCodes.OK) {
      logger.error(
        `Failed when calling cloud function ${salesforceFunction.name} with args: ${JSON.stringify(
          args
        )}. Error: ${JSON.stringify(result)}`
      );
      return null;
    }
    logger.info(`Cloud function ${salesforceFunction.name} finished successfully. args: ${JSON.stringify(args)} `);
    return result.data.message;
  } catch (err) {
    logger.error(
      { err },
      `Failed when calling cloud function ${salesforceFunction.name} with args: ${JSON.stringify(args)}. Error: ${err}`
    );
    return null;
  }
}

export async function updateLoginInSalesforce({ email }: updateLoginInSalesforceTypes) {
  return await activateSalesforceFunction(CloudFunctions.updateLoginInSalesforce, { email });
}

export async function updateSignupInSalesforce({
  email,
  name,
  companyName,
  ...sourceProperties
}: updateSignupInSalesforceTypes) {
  return await activateSalesforceFunction(CloudFunctions.updateSignupInSalesforce, {
    email,
    name,
    companyName,
    ...sourceProperties,
  });
}

let lastReportedWorkspaceId = null;
export async function updateWorkspaceUserInSalesforce({ workspaceId, uid }: updateWorkspaceUserInSalesforceTypes) {
  if (workspaceId !== lastReportedWorkspaceId) {
    lastReportedWorkspaceId = workspaceId;
    return await activateSalesforceFunction(CloudFunctions.updateWorkspaceUserInSalesforce, { workspaceId, uid });
  }
  return null;
}

export async function updateSalesforceWorkspaceCreation({
  name,
  id,
  gitHosting = null,
  companyName = '',
}: createWorkspaceInSalesforceTypes) {
  return await activateSalesforceFunction(CloudFunctions.createWorkspaceInSalesforce, {
    name,
    id,
    gitHosting,
    companyName,
  });
}

export async function updateSalesforceWorkspace({ name, workspaceId }: updateWorkspaceInSalesforceTypes) {
  return await activateSalesforceFunction(CloudFunctions.updateWorkspaceInSalesforce, { name, workspaceId });
}

export async function updateSalesforceLeadWithGitHosting({
  companyName,
  gitHosting,
  developersAmount,
}: updateSalesforceLeadWithGitHostingTypes) {
  return await activateSalesforceFunction(CloudFunctions.updateSalesforceLeadWithGitHosting, {
    gitHosting,
    companyName,
    developersAmount,
  });
}

export async function updateSalesforceRepoCreation({ workspaceId, repoId }: updateRepoInSalesforceTypes) {
  return await activateSalesforceFunction(CloudFunctions.updateRepoInSalesforce, { workspaceId, repoId });
}
