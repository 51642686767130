import { isEqual, snakeCase } from 'lodash-es';
import { splitLineByWords } from '@swimm/shared';
// trie-search has no @types/ package :(
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TrieSearch from 'trie-search';
import type { IToken } from '@/stores/tokenSuggestionStore';

export class TokenSuggestionEngine {
  private readonly tokenIndex = new TrieSearch('token', { ignoreCase: true });

  constructor(...tokenBanks: Record<string, IToken>[]) {
    for (const tokenBank of tokenBanks) {
      for (const tokenId in tokenBank) {
        if (!Object.prototype.hasOwnProperty.call(tokenBank, tokenId)) {
          continue;
        }
        this.indexToken(tokenBank[tokenId]);
      }
    }
  }

  private static *getTokenIndexTerms(text: string) {
    // Say we have the token LegacySwimmLoggerShim, we want the user to be able to search using the following terms:
    // - LegacySwimmLoggerShim
    // - SwimmLoggerShim
    // - LoggerShim
    // - Shim
    yield text;
    const subtokens = snakeCase(text).split('_');
    for (let i = 1; i < subtokens.length; ++i) {
      yield subtokens.slice(i).join('');
    }
  }

  private indexToken(token: IToken): void {
    for (const term of TokenSuggestionEngine.getTokenIndexTerms(token.text)) {
      this.tokenIndex.map(term, token);
    }
  }

  /* Given a starting token and the input of the user, split into 'words', find whether there is a sequence of tokens
     starting from the given token that matches the input, in order to create smart tokens that span multiple words. */
  static findTokenSequenceMatch(
    startingToken: IToken,
    inputWords: string[],
    isPartialInput: boolean
  ): string | undefined {
    // This happens in some global tokens, e.g. when the name of the declaration is 'a.b' - the code there splits the
    // line into words, and cannot find a word called 'a.b' because it spans multiple words.
    if (startingToken.wordIndex.start < 0) {
      return undefined;
    }
    const wordsFromLine = splitLineByWords(startingToken.lineData).slice(
      startingToken.wordIndex.start,
      startingToken.wordIndex.start + inputWords.length
    );
    if (wordsFromLine.length !== inputWords.length) {
      return undefined;
    }
    // Input is complete - just make sure the input matches the lineData starting from the token position.
    if (!isPartialInput) {
      return isEqual(inputWords, wordsFromLine) ? wordsFromLine.join('') : undefined;
    }
    // Input is partial - make sure all but the last pair of words match.
    for (let i = 0; i < inputWords.length; ++i) {
      const inputWord = inputWords[i];
      const wordFromLine = wordsFromLine[i];
      if (i === inputWords.length - 1) {
        // Make sure that the last input word is a prefix of the actual word in the code.
        if (!wordFromLine.toLowerCase().startsWith(inputWord.toLowerCase())) {
          return undefined;
        }
      } else if (wordFromLine.localeCompare(inputWord, undefined, { sensitivity: 'accent' }) !== 0) {
        return undefined;
      }
    }
    return wordsFromLine.join('');
  }

  *findSingleTokenMatches(inputWord: string, isPartialWord = false): Iterable<IToken> {
    for (const match of this.tokenIndex.search(inputWord) as Iterable<IToken>) {
      if (!isPartialWord && match.text !== inputWord) {
        continue;
      }
      yield match;
    }
  }

  *suggest(input: string, isPartialInput: boolean): Iterable<IToken> {
    const inputWords = splitLineByWords(input);
    if (inputWords.length === 0) {
      return;
    }
    for (const startingToken of this.findSingleTokenMatches(inputWords[0], isPartialInput && inputWords.length === 1)) {
      // In partial input mode, we can get matches that match a word in the middle of a token - in this case, just yield
      // them and don't attempt to match the rest of the line.
      if (isPartialInput && !startingToken.text.startsWith(inputWords[0])) {
        yield startingToken;
        continue;
      }
      const tokenSequenceMatch = TokenSuggestionEngine.findTokenSequenceMatch(
        startingToken,
        inputWords,
        isPartialInput
      );
      if (!tokenSequenceMatch) {
        continue;
      }
      yield {
        ...startingToken,
        text: tokenSequenceMatch,
        wordIndex: {
          start: startingToken.wordIndex.start,
          end: startingToken.wordIndex.start + inputWords.length - 1, // We handle the end word index of a suggestion inclusively in the code.
        },
      };
    }
  }
}
