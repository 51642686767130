<template>
  <div class="notification">
    <slot />
  </div>
</template>

<style scoped>
.notification {
  padding: 5px 15px;
  border-radius: 8px;
  background: var(--color-status-info);
}

.notification-label {
  font-family: var(--fontfamily-secondary);
}
</style>
