/**
 * Clean special runtime keys from the SwmFile and return the cleaned SwmFile.
 * There are certain keys (eg. `applicability`) that shouldn't be saved to the disk as they're computed dynamically in runtime.
 * This function cleans all those runtime keys and returns a cleaned SwmFile.
 * NOTE: this function also changes the original passed in function.
 * @param {import('../types').SwmFile} swmFile swm file to clean up
 * @returns cleaned swm file.
 */
export function cleanSwmRuntimeKeys(swmFile) {
  const afterSymbolClean = cleanSymbolsRuntimeKey(swmFile);
  const afterContentClean = filterKeysFromContent(afterSymbolClean);
  return afterContentClean;
}

export function cleanSymbolsRuntimeKey(swmFile) {
  const filteredUnit = { ...swmFile };
  if (filteredUnit.symbols) {
    for (const symbol of Object.values(filteredUnit.symbols)) {
      delete symbol.applicability; // Appears on every symbol type
      if (symbol.type === 'generic_text') {
        delete symbol.originalInfo;
      }
    }
  }
  return filteredUnit;
}

export function filterKeysFromContent(swmDict) {
  const filteredSwmDict = { ...swmDict };

  if (!filteredSwmDict.content) {
    return filteredSwmDict;
  }

  for (const cell of filteredSwmDict.content) {
    if (cell.type === 'snippet') {
      delete cell['applicability'];
      delete cell['id'];
      delete cell['index'];
    }
  }
  return filteredSwmDict;
}
