<script setup></script>

<template>
  <div class="content-container">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.content-container {
  display: flex;
  flex-direction: column;
  width: 360px;
  padding: var(--space-xl);
  background: var(--color-bg);
  border: 4px solid var(--color-status-info);
  border-radius: 32px;
  box-shadow: var(--box-shadow-medium);
  text-align: center;
}

@media screen and (max-width: 480px) {
  .content-container {
    width: 280px;
    padding: var(--space-lg);
  }
}
</style>
