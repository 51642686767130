<template>
  <div class="wrapper" data-testid="sso-auth-button">
    <Action
      secondary
      button-type="button"
      class="button"
      :disabled="!isEmailValid"
      :loading="isLoading"
      @click="onButtonClick"
    >
      <Icon name="private" />
      <slot />
    </Action>
    <ErrorBox v-if="error" class="error">{{ error }}</ErrorBox>
  </div>
</template>

<script>
import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';
import { computed } from 'vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { connectUserToSlack } from '@/modules/slack-app/services/slack-app-utils';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { startCase } from 'lodash-es';
import { mapActions } from 'vuex';
import { UrlUtils, config, eventLogger, getLoggerNew, productEvents, state, stringUtils } from '@swimm/shared';
import { MOBILE_MAX_WIDTH, PageRoutesNames } from '@/common/consts';
import { getSystemTheme } from '@/common/utils/theme-utils';
import { getSourcePropertiesFromLocalStorage } from '@/common/utils/helpers';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { storeToRefs } from 'pinia';
import { ErrorBox } from '@swimm/ui';
import { fetchSSOSetup, setSSOProvider } from '@/modules/core/composables/sso';
import { OktaAuth } from '@okta/okta-auth-js';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import { v4 as uuidv4 } from 'uuid';
import { sha256 } from 'js-sha256';
import { openAsyncPopup } from '@/common/utils/popup-utils';
import Cookies from 'js-cookie';
import LocalStorage from '@/local-storage';

const logger = getLoggerNew(__modulename);

export default {
  components: { ErrorBox },
  emits: ['click'],
  props: {
    email: { type: String, default: '' },
  },
  setup(props) {
    const analytics = useAnalytics();
    const { logEvent } = useSwimmEventLogs();
    const authStore = useAuthStore();
    const { user } = storeToRefs(authStore);
    const { setUser } = authStore;
    const { authorizeProviderWithGit } = useGitAuthorizationStore();

    const isEmailValid = computed(() => props && UrlUtils.isValidEmail(props.email));

    return { analytics, logEvent, user, setUser, authorizeProviderWithGit, isEmailValid };
  },
  data() {
    return {
      isLoading: false,
      error: '',
    };
  },
  computed: {
    isIdeLogin() {
      const foundIndex = this.$route.query?.redirect?.indexOf('ide-login') ?? -1;
      return foundIndex !== -1;
    },
    isFromGHMarketplace() {
      return this.$route.query?.source === config.GH_MARKETPLACE_INDICATOR;
    },
    ghEmail() {
      return this.$route.query?.email ?? null;
    },
  },
  methods: {
    ...mapActions('database', ['fetchWorkspaceInvites']),
    async onButtonClick() {
      this.isLoading = true;
      this.error = '';

      const ssoResponse = await fetchSSOSetup(this.email);
      if (!ssoResponse || !ssoResponse.isSSOEnabled) {
        this.isLoading = false;
        this.error = 'Unable to get account information about your SSO provider, please try again';
        return;
      }
      const clientId = ssoResponse.clientId;
      const issuer = ssoResponse.issuer;

      let idToken = null;
      let accessToken = null;
      let uid = null;
      let loggedInEmail = null;
      const ssoProvider = ssoResponse.type;
      const rawNonce = uuidv4();
      try {
        if (ssoProvider === 'azure') {
          const azureClient = await PublicClientApplication.createPublicClientApplication({
            auth: {
              clientId,
              authority: issuer,
            },
            cache: {
              cacheLocation: BrowserCacheLocation.LocalStorage,
            },
          });

          const popupRequest = {
            scopes: ['User.Read', 'email', 'openid', 'offline_access'],
            authority: issuer,
            nonce: sha256(rawNonce),
            loginHint: this.email,
          };
          const tokens = await azureClient.acquireTokenPopup(popupRequest);
          idToken = tokens.idToken;
          accessToken = tokens.accessToken;
          uid = tokens.idTokenClaims.sub;
          loggedInEmail = tokens.idTokenClaims.email;
        } else if (ssoProvider === 'jumpcloud') {
          const redirectURI = `${config.BASE_URL}/setOIDCToken`;
          const codeVerifier = stringUtils.generateCodeVerifier(43);
          const codeChallenge = stringUtils.generateCodeChallenge(codeVerifier);
          Cookies.set('swimm_code_verifier', codeVerifier);
          Cookies.set('swimm_client_id', clientId);

          const popupUrl = `https://oauth.id.jumpcloud.com/oauth2/auth?response_type=code&client_id=${clientId}&code_challenge=${codeChallenge}&code_challenge_method=S256&state=${uuidv4()}&scope=${encodeURIComponent(
            'openid email offline_access'
          )}&redirect_uri=${encodeURIComponent(redirectURI)}`;

          // Removing previous Jumpcloud credentials, they could be stale
          await state.deleteKey({ key: 'oidc_data', isGlobalKey: true });

          const hasAccess = await openAsyncPopup({
            url: popupUrl,
            displayName: 'JumpCloud',
            checkSuccessCB: async function () {
              const testOidcData = await state.get({ key: 'oidc_data', defaultValue: null });
              return !!testOidcData;
            },
          });

          if (hasAccess) {
            logger.info('Got access to JumpCloud');
          } else {
            throw new Error('Could not get access to JumpCloud');
          }

          const oidcData = await state.get({ key: 'oidc_data', defaultValue: null });

          idToken = oidcData.id_token;
          uid = oidcData.sub;
          loggedInEmail = oidcData.user_email;
          Cookies.set('swimm_jumpcloud_email', encodeURIComponent(loggedInEmail), { expires: 365 * 10 /* 10 year */ });
        } else {
          const requireSession = async (oktaAuth, authState) => {
            if (!authState.isAuthenticated) {
              return authState;
            }
            let user;
            try {
              user = await oktaAuth.token.getUserInfo(); // extra requirement: user must have valid Okta SSO session
            } catch (err) {
              logger.error({ err }, `Failed fetching user information from your SSO provider, ${err.message}`);
            }

            authState.isAuthenticated = !!user; // convert to boolean
            authState.users = user; // also store user object on authState
            return authState;
          };

          const authClient = new OktaAuth({
            issuer,
            clientId,
            redirectUri: config.BASE_URL + '/setOktaToken',
            transformAuthState: requireSession,
            storageManager: {
              token: {
                storageTypes: ['localStorage', 'sessionStorage', 'memory'],
              },
            },
            tokenManager: {
              autoRenew: true,
            },
          });

          const tokenResponse = await authClient.token.getWithPopup({
            loginHint: this.email,
            responseType: 'code',
            scopes: ['openid', 'profile', 'email', 'offline_access'],
            nonce: sha256(rawNonce),
          });
          accessToken = tokenResponse.tokens.accessToken.accessToken;
          idToken = tokenResponse.tokens.idToken.idToken;
          uid = tokenResponse.tokens.accessToken.claims.uid;
          loggedInEmail = tokenResponse.tokens.idToken.claims.email;
          await authClient.tokenManager.setTokens(tokenResponse.tokens);
          await authClient.authStateManager.updateAuthState();
        }
      } catch (err) {
        this.isLoading = false;
        this.error = 'Unable to get connect to your SSO provider, please try again';
        logger.error(
          { err },
          `Failed connecting to SSO provider ${ssoProvider} for email ${this.email.split('@')?.[1]}, ${err.message}`
        );
        return;
      }

      logger.info(`Setting sso provider if needed: ${ssoProvider} for email: ${loggedInEmail}`);
      await setSSOProvider(loggedInEmail, uid);

      logger.info(`Getting provider from firebase SDK: ${ssoProvider} for email: ${loggedInEmail}`);
      const provider = new firebase.auth.OAuthProvider(ssoResponse.firebaseAuthName);
      if (ssoProvider === 'azure') {
        provider.addScope('User.Read');
      }
      provider.addScope('email');
      provider.addScope('openid');
      provider.addScope('offline_access');

      const credential = provider.credential({ accessToken, idToken, rawNonce });

      // I am saving the last login time and reading it for Jumpcloud only because Provide asked to log them out automatically after 24 hours.
      // I couldn't use firebase.auth().currentUser.metadata.lastSignInTime because it doesn't update when the a login happens with a valid session
      // and then the user gets kicked after much faster then they are supposed to.
      const lastSignInTime = Date.now();
      LocalStorage.set('lastSignInTime', lastSignInTime);

      // Temporary log entry to debug a problem we can't reproduce and happens only to Provide
      if (ssoProvider === 'jumpcloud') {
        logger.info(`lastSignInTime: ${lastSignInTime}`);
      }

      await firebase
        .auth()
        .signInWithCredential(credential)
        .then(async (userDetails) => {
          if (!userDetails.user.email) {
            throw new Error(`Failed SSO connection, user ${this.email.split('@')?.[1]} has no email set in provider`);
          }

          const isNewUser = userDetails.additionalUserInfo.isNewUser;
          if (isNewUser) {
            await this.handeUserSignup();
            return this.$router.push({ name: PageRoutesNames.ONBOARDING, query: this.$route.query.redirect });
          } else {
            const user = await this.handleUserLogin();
            if (this.$route.query.source === config.SLACK_INDICATOR) {
              const success = await connectUserToSlack({
                userUid: user.uid,
                slackTeamId: this.$route.query.team,
                slackUserId: this.$route.query.user,
              });
              if (!success) {
                this.$route.query['error'] = config.SLACK_INDICATOR;
              }
            }
            let routeParams;
            if (this.isIdeLogin) {
              routeParams = this.$route.query.redirect;
            } else {
              const { redirect, ...rest } = this.$route.query;
              routeParams = { path: redirect || '/', query: rest };
              logger.info(`Login successful, redirecting to workspace for provider ${ssoProvider}`);
            }
            return this.$router.push(routeParams).catch(() => {
              return;
            });
          }
        })
        .then(() => {
          if (this.isFromGHMarketplace) {
            this.authorizeProviderWithGit({ provider: GitProviderName.GitHub, origin: 'SSO login' });
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.error = 'Unable to get account information from your SSO provider, please try again';
          logger.error(
            { err },
            `Failed connecting to SSO provider ${ssoProvider} for email ${this.email.split('@')?.[1]}, ${err.message}`
          );
        });
      this.$emit('click');
    },
    async handeUserSignup() {
      const user = firebase.auth().currentUser;
      const userInvites = await this.fetchWorkspaceInvites();
      this.setUser({
        ...user,
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        isRegistrationEvent: true,
        query: this.$route.query,
        isInvited: !!userInvites.length,
        isSSO: true,
      });
      this.analytics.initAnalytics({
        uid: user.uid,
        nickname: user.displayName,
        email: user.email,
        creationTime: user.metadata.creationTime,
        lastSignInTime: user.metadata.lastSignInTime,
        browserSystemTheme: getSystemTheme(),
        sourceProps: getSourcePropertiesFromLocalStorage({ keyFormatCallback: startCase, valueFallback: '' }),
      });
      const payload = {
        Origin: 'Signup page',
        'Origin URL': this.$route.fullPath,
        'Signup Method': 'SSO',
        'Signup Type': 'Direct', // User cannot sign up with SSO when registering via invite link
        'Is Invited': userInvites.length ? 'True' : 'False',
        'Is From GitHub Marketplace': this.isFromGHMarketplace,
        'GitHub Email': this.ghEmail,
        'User Email': user.email,
        ...getSourcePropertiesFromLocalStorage({ keyFormatCallback: startCase, valueFallback: '' }),
      };
      this.analytics.cloudTrack({
        identity: user.uid,
        event: productEvents.USER_SIGNED_UP,
        payload,
      });
      this.analytics.track(productEvents.USER_SIGNED_UP_MARKETING, { ...payload, user_id: user.uid });
      this.analytics.reportHubspotSignUp({
        email: user.email,
        name: user.displayName,
      });

      this.sendWelcomeEmail();
    },
    async sendWelcomeEmail() {
      try {
        const isMobile = screen.width <= MOBILE_MAX_WIDTH;
        logger.debug(`Sending welcome email, uid: ${this.user.uid}`);
        await CloudFunctions.sendWelcomeEmail({
          isWeb: true,
          isMobile,
        });
      } catch (err) {
        logger.error({ err }, `Failed to send welcome email, uid: ${this.user.uid}, error: ${err}`);
      }
    },
    async handleUserLogin() {
      const user = firebase.auth().currentUser;
      this.logEvent(
        eventLogger.SWIMM_EVENTS.USER_SIGN_IN,
        { srcId: user.uid, srcName: user.displayName },
        { uid: user.uid, nickname: user.displayName }
      );
      this.analytics.initAnalytics({
        uid: user.uid,
        nickname: user.displayName,
        email: user.email,
        creationTime: user.metadata.creationTime,
        lastSignInTime: user.metadata.lastSignInTime,
        browserSystemTheme: getSystemTheme(),
      });
      this.analytics.cloudTrack({
        identity: user.uid,
        event: productEvents.USER_LOGGED_IN,
        payload: {
          'Login Method': 'SSO',
        },
      });

      this.analytics.reportHubspotSignIn({
        email: user.email,
      });

      return user;
    },
  },
};
</script>

<style scoped>
.wrapper .button {
  padding: 8px 0;
  width: 100%;
}

.icon {
  margin-right: 8px;
}

.error {
  margin-top: 8px;
  text-align: left;
}
</style>
