<template>
  <li class="option body-S ellipsis-option" @click.prevent="preformAction">
    <div>
      <Icon no-padding :name="icon" class="icon" />
    </div>
    <div>
      <div class="option-title">
        <div>{{ name }}</div>
        <div class="title-beta-tag-container">
          <Tag v-if="showBetaTag" class="option-tag" text="BETA" tag-style="new-doc-sgd" />
        </div>
      </div>
      <div v-if="description" class="option-description">{{ description }}</div>
    </div>
  </li>
</template>

<script>
import { getLogger } from '@swimm/shared';
import { Tag } from '@swimm/ui';

const logger = getLogger(__modulename);

export default {
  components: { Tag },
  props: {
    name: { type: String, required: true },
    icon: { type: String, default: '' },
    handler: { type: Function, default: () => ({}) },
    description: { type: String, default: undefined },
    showBetaTag: { type: Boolean, default: false },
  },
  emits: ['click'],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async preformAction() {
      try {
        this.loading = true;
        await this.handler(this.name);
      } catch (e) {
        logger.error(`action ${this.name} failed: ${e}`);
      }
      this.loading = false;
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
.loading {
  animation: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: inline-block;
}

.option {
  display: flex;
  align-items: center;
  padding: 14px;
  border-radius: inherit;
  white-space: nowrap;
  color: var(--text-color-primary);
  list-style: none;
  cursor: pointer;
  font-weight: initial;
}

.option:hover {
  background: var(--color-hover);
}

.option .icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  font-size: var(--fontsize-m);
}

.option-title {
  display: flex;
  align-items: flex-end;
  margin-bottom: 3px;
  font-size: var(--subtitle-L);
  color: var(--text-color-primary);
  flex-direction: row;
}

.option-tag {
  padding-inline-start: 12px;
}

.option-description {
  font-size: var(--subtitle-S);
  color: var(--text-color-secondary);
}

.title-beta-tag-container {
  padding-inline-start: 8px;
}
</style>
