<script setup lang="ts">
import ListViewItem from '@/modules/core/components/ListViewItem.vue';
import { DocumentationTypes } from '@/common/consts';
import { clickedOnInternalCardElement } from '@/common/utils/card-utils';
import { computed, ref } from 'vue';
import { SwDate } from '@swimm/ui';
import { useFoldersStore } from '@/modules/folders/store/folders';
import FolderNameEdit from '@/modules/folders/components/FolderNameEdit.vue';
import { CreationHubSections } from '@/modules/core/creation-hub/CreationHubConsts';
import FolderAdditionOptions from '@/modules/folders/components/FolderAdditionOptions.vue';

const props = defineProps({
  folder: { type: Object, required: true },
  repoId: { type: String, required: true },
  selectable: { type: Boolean, default: false },
});

const emit = defineEmits([
  'add-folder',
  'move',
  'select',
  'folder-clicked',
  'navigate-to-item',
  'toggle-creation-hub',
  'delete',
  'report-click',
]);

const isInRenameMode = ref(false);

const { getFolderLastModifiedDate } = useFoldersStore();

const lastModifiedDate = computed(() => {
  return getFolderLastModifiedDate(props.folder.id, props.repoId);
});

function clickedFolderItem(event) {
  if (clickedOnInternalCardElement(event) || isInRenameMode.value) {
    return;
  }

  emit('folder-clicked');
}

function onRename() {
  isInRenameMode.value = true;
}

function reportClick(actionName) {
  emit('report-click', { type: 'Folder', id: props.folder.id, action: actionName });
}
</script>

<template>
  <ListViewItem
    data-testid="folder-item"
    icon="folder"
    :title="folder.name"
    :creator="folder.creator_name"
    :creator-profile-img="folder.creator_profile_url"
    :type="DocumentationTypes.FOLDER"
    :selected="folder.selected"
    @select="(ev) => $emit('select', ev)"
    :folder-id="folder.id"
    @click="clickedFolderItem"
    :selectable="selectable"
  >
    <template v-if="isInRenameMode" #name>
      <FolderNameEdit
        :folder-id="folder.id"
        :folder-name="folder.name"
        :repo-id="repoId"
        @change="isInRenameMode = false"
        @cancel="isInRenameMode = false"
      />
    </template>
    <template #activity>
      <SwDate v-if="lastModifiedDate" :date="lastModifiedDate" />
    </template>
    <template #options>
      <EllipsisOption name="Rename" icon="edit-outline" :handler="onRename" @click="reportClick('Rename')" />
      <EllipsisOption
        name="Delete"
        icon="trash-outline"
        :handler="() => $emit('delete')"
        @click="reportClick('Delete')"
      />
      <EllipsisOption
        name="Move to folder..."
        icon="move"
        :handler="() => $emit('move')"
        :close-after-action="false"
        @click="reportClick('Move')"
      />
    </template>
    <template #create>
      <FolderAdditionOptions
        :folder-id="props.folder.id"
        :repo-id="repoId"
        @add-folder="emit('add-folder', props.folder.id)"
        @add-doc="$emit('navigate-to-item', { query: { folderId: props.folder.id }, isPlaylist: false })"
        @add-playlist="$emit('navigate-to-item', { query: { folderId: props.folder.id }, isPlaylist: true })"
        @add-template="
          $emit('toggle-creation-hub', { creationHubSection: CreationHubSections.TEMPLATES, folderId: props.folder.id })
        "
      />
    </template>
  </ListViewItem>
</template>
