import { debounce } from 'lodash-es';
import { type Ref, computed, ref, watch } from 'vue';

import { type SnippetInfo, type TokenSuggestionsService, sortSuggestionsByFamiliarity } from '@swimm/editor';
import type { TokenSuggestion } from '@swimm/shared';
import type { SmartElement, SmartElementWithApplicability, Snippet } from '@swimm/shared';

export interface TokenSuggestionsState {
  tokenSuggestions: TokenSuggestion[];
  loading: boolean;
  noResults: boolean;
}

export function useTokenSuggestionsState(
  query: Ref<string>,
  active: Ref<boolean>,
  service: TokenSuggestionsService,
  sourceFiles: Ref<{ [repoId: string]: string[] }>,
  sourceRepo: Ref<string>,
  docRepoId: Ref<string | undefined>,
  documentSmartElements: Ref<(SmartElementWithApplicability<SmartElement> | SmartElementWithApplicability<Snippet>)[]>,
  focusedSnippet: SnippetInfo | undefined
) {
  const debouncingQuery = ref(false);
  const debouncedQuery = ref(query.value);

  const setDebouncedQuery = debounce((query: string) => {
    debouncedQuery.value = query;
    debouncingQuery.value = false;
  }, 300);

  watch(
    () => query.value,
    (query) => {
      debouncingQuery.value = true;
      setDebouncedQuery(query);
    },
    { immediate: true }
  );

  const suggestions = computed(() => {
    if (!active.value) {
      return null;
    }

    const result = service.query(
      debouncedQuery.value,
      Object.entries(sourceFiles.value)
        .map(([repoId, paths]) => paths.map((path) => ({ repoId, path })))
        .flat(),
      [sourceRepo.value]
    );

    const sortedSuggestions = sortSuggestionsByFamiliarity({
      suggestions: result.suggestions,
      docSmartElements: documentSmartElements.value,
      docRepoId: docRepoId.value,
      parentSnippetInfo: focusedSnippet,
    });
    return { suggestions: sortedSuggestions, loading: result.loading };
  });

  const latestSuggestions = ref<{ suggestions: TokenSuggestion[]; loading: boolean }>({
    suggestions: [],
    loading: false,
  });

  watch(
    () => suggestions.value,
    (value) => {
      if (value != null) {
        latestSuggestions.value = value;
      }
    },
    { immediate: true }
  );

  const state = computed<TokenSuggestionsState>(() => {
    if (query.value.length === 0) {
      return {
        tokenSuggestions: [],
        loading: false,
        noResults: false,
      };
    }

    const loading = debouncingQuery.value || latestSuggestions.value.loading;
    return {
      tokenSuggestions: debouncingQuery.value ? [] : latestSuggestions.value.suggestions,
      loading,
      noResults: !loading && latestSuggestions.value.suggestions.length === 0,
    };
  });

  return state;
}
