import { DUMMY_REPO_ID, Licenses } from '@swimm/shared';
import { isRepoPrivate } from '@/remote-adapters/local_repo';
import * as firestoreWrapper from '@/adapters-common/firestore-wrapper';
import { GitProviderName, config, objectUtils } from '@swimm/shared';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { getLoggerNew, gitProviderUtils, shortHash } from '@swimm/shared';
import { isAutomation } from '@/config';

const logger = getLoggerNew(__modulename);
const automation_expired_resources = [
  firestoreWrapper.collectionNames.SWIMMS,
  firestoreWrapper.collectionNames.PLAYLISTS,
  firestoreWrapper.collectionNames.WORKSPACES,
  firestoreWrapper.collectionNames.WORKSPACE_USERS,
  firestoreWrapper.collectionNames.WORKSPACE_ADMINS,
];

export async function getCreationDetails(userAuth) {
  return {
    created: firestoreWrapper.firestoreTimestamp(),
    creator: userAuth.uid,
    creator_name: userAuth.nickname,
  };
}

export async function getModifiedDetails(userAuth) {
  return {
    modified: firestoreWrapper.firestoreTimestamp(),
    modifier: userAuth.uid,
    modifier_name: userAuth.nickname,
  };
}

export function getTestResourceDetails(resourceName) {
  if (isAutomation && automation_expired_resources.includes(resourceName)) {
    return {
      // If test data - remove in 4h
      expired_at: firestoreWrapper.firestoreTimestampInSeconds(4 * 3600),
    };
  }
  return {};
}

export function filterWorkspaceKeys(workspaceToFilter) {
  // The only fields that should be saved on the workspace document itself.
  const workspacePropertiesToSave = [
    'branch_prefix',
    'id',
    'name',
    'description',
    'logo',
    'invite_requests',
    'invites',
    'repositories',
    'counter_plans',
    'counter_workspace_users',
    'counter_workspace_admins',
    'created',
    'creator',
    'creator_name',
    'modified',
    'modifier',
    'modifier_name',
    'license',
    'is_desktop',
    'hosted_domains',
    'is_auto_join_enabled',
    'settings',
  ];
  const filteredWorkspace = objectUtils.deepClone(workspaceToFilter);
  for (const key of Object.keys(filteredWorkspace)) {
    if (!workspacePropertiesToSave.includes(key)) {
      delete filteredWorkspace[key];
    }
  }
  return filteredWorkspace;
}

export async function saveWorkspaceToFirestore(resource, userAuth) {
  let workspaceId = resource.id;
  const shouldUpdateWorkspace = 'id' in resource;
  const toRet = {};

  if (shouldUpdateWorkspace) {
    const response = await updateWorkspace(resource, userAuth);
    if (response.code !== config.SUCCESS_RETURN_CODE) {
      return { code: config.ERROR_RETURN_CODE, errorMessage: 'Got error updating workspace' };
    }
    toRet.wasUpdated = true;
  } else {
    const response = await createWorkspace(resource, userAuth);
    workspaceId = response.workspaceId;
    if (response.code !== config.SUCCESS_RETURN_CODE) {
      return { code: config.ERROR_RETURN_CODE, errorMessage: 'Got error creating workspace' };
    }

    const getUserResponse = await firestoreWrapper.getDocFromSubCollection(
      firestoreWrapper.collectionNames.WORKSPACES,
      workspaceId,
      firestoreWrapper.collectionNames.WORKSPACE_USERS,
      userAuth.uid
    );
    if (getUserResponse.code === config.SUCCESS_RETURN_CODE) {
      toRet.user = getUserResponse.data;
    } else {
      logger.error(`Error fetching user ${userAuth.uid} from workspace ${workspaceId}: ${response.errorMessage}`);
    }
  }

  const getWorkspaceResponse = await firestoreWrapper.getDocFromCollection(
    firestoreWrapper.collectionNames.WORKSPACES,
    workspaceId
  );
  if (getWorkspaceResponse.code === config.SUCCESS_RETURN_CODE) {
    toRet.workspace = filterWorkspaceKeys(getWorkspaceResponse.data);
  } else {
    logger.error(`Error fetching workspace ${workspaceId}: ${getWorkspaceResponse.errorMessage}`);
  }

  return { ...toRet, workspaceId, code: config.SUCCESS_RETURN_CODE };
}

export async function updateWorkspace(workspace, userAuth) {
  const workspaceToSave = { ...workspace };
  // Fields as 'plans' shouldn't be created on the document by the update workspace query
  const filteredWorkspace = filterWorkspaceKeys(workspaceToSave);
  // We use mergeFields to not update fields that we don't want to override if they already exist in the DB
  const fieldsToMerge = [
    'counter_plans',
    'counter_workspace_users',
    'counter_workspace_admins',
    'created',
    'creator',
    'creator_name',
  ];
  const mergeFields = Object.keys(filteredWorkspace).filter((key) => !fieldsToMerge.includes(key));

  const response = await firestoreWrapper.setValuesInDoc(
    firestoreWrapper.collectionNames.WORKSPACES,
    workspaceToSave.id,
    { ...filteredWorkspace, ...(await getModifiedDetails(userAuth)) },
    {
      mergeFields,
    }
  );
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error editing data in workspace ${workspaceToSave.id}: ${response.errorMessage}`);
    return { code: config.ERROR_RETURN_CODE };
  }

  return { code: config.SUCCESS_RETURN_CODE };
}

export async function createWorkspace(workspace, userAuth) {
  const workspaceToSave = {
    counter_workspace_users: 0,
    counter_workspace_admins: 0,
    repositories: [DUMMY_REPO_ID],
    invites: [],
    invite_requests: [],
    license: Licenses.FREE,
    settings: {},
    ...workspace,
    ...(await getCreationDetails(userAuth)),
    ...(await getModifiedDetails(userAuth)),
    ...getTestResourceDetails('workspaces'),
  };

  const response = await firestoreWrapper.addDocToCollection(
    firestoreWrapper.collectionNames.WORKSPACES,
    workspaceToSave
  );
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error creating new workspace: ${response.errorMessage}`);
    return { code: config.ERROR_RETURN_CODE };
  }

  const { code } = await addUserToWorkspace(userAuth, response.data.id, true);
  return { code, workspaceId: response.data.id };
}

export async function addUserToWorkspace(userAuth, workspaceId, isAdmin) {
  const userInDB = {
    id: userAuth.uid,
    uid: userAuth.uid,
    name: userAuth.nickname,
    email: userAuth.email,
    external_uid: shortHash(userAuth.uid),
    ...(await getCreationDetails(userAuth)),
    ...(await getModifiedDetails(userAuth)),
  };

  let response = await addUserToCollection(firestoreWrapper.collectionNames.WORKSPACE_USERS, workspaceId, userInDB);
  if (response.code === config.SUCCESS_RETURN_CODE && isAdmin) {
    response = await addUserToCollection(firestoreWrapper.collectionNames.WORKSPACE_ADMINS, workspaceId, userInDB);
  }

  return response;
}

export async function addUserToCollection(collectionName, workspaceId, user) {
  const userData = {
    ...user,
    ...getTestResourceDetails(collectionName),
  };
  const response = await firestoreWrapper.setValuesInDocInSubCollection(
    firestoreWrapper.collectionNames.WORKSPACES,
    workspaceId,
    collectionName,
    user.id,
    userData
  );
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Got error adding user ${user.id} to workspace ${workspaceId}: ${response.errorMessage}`);
  }

  return { code: response.code };
}

export async function getRepoIsPrivate(args) {
  const { repoId, repoData } = args;
  let isPrivate = repoData.is_private;
  if (
    isPrivate === undefined &&
    repoData.provider &&
    (repoData.provider === GitProviderName.GitHub ||
      repoData.provider === GitProviderName.GitHubEnterprise ||
      repoData.provider === GitProviderName.GitLab)
  ) {
    // Use the repo's URL info since the check for "is repo private" might run BEFORE saving the repo info to the local state
    const parsedUrl = gitProviderUtils.parseGitProviderURL(repoData.url);
    const res = await isRepoPrivate({
      repoId,
      provider: repoData.provider,
      repoName: parsedUrl.name,
      owner: parsedUrl.owner,
      ...(repoData.api_url ? { api_url: repoData.api_url } : {}),
    });
    if (res.code === 0) {
      isPrivate = res.isPrivate;
      try {
        const result = await CloudFunctions.updateRepoDbEntry({ isPrivate, repoId });
        if (result.data.status !== 'success') {
          logger.error(`Failed to set is_private in DB for repo ${repoId}: ${result.data.error}`, {
            module: 'database',
          });
          isPrivate = undefined;
        }
      } catch (err) {
        logger.error({ err }, `Exception while setting is_private in DB for repo ${repoId}: ${err}`);
      }
    }
  }
  return isPrivate;
}
