<script setup lang="ts">
import { nextTick, onMounted, onUnmounted, onUpdated, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    disableAutoFocus?: boolean;
  }>(),
  {
    disableAutoFocus: false,
  }
);

const emit = defineEmits<{
  close: [];
}>();

const focusElement = ref<HTMLDivElement | null>(null);
const focusItems = ref<HTMLElement[]>([]);
const selectedMenuItem = ref(-1);
let focusInHandler: ((event: Event) => void) | null = null;

async function updateFocusItems() {
  await nextTick();

  if (!focusElement.value) {
    return;
  }

  const subitems = focusElement.value.querySelectorAll(
    `[role="menuitem"][tabindex="0"],
     [type="search"],
     button:not([aria-label="Clear input"])`
  );

  focusItems.value = subitems ? (Array.from(subitems) as HTMLElement[]) : [];
}

async function focusItem(index: number) {
  if (focusItems.value.length) {
    if (index >= focusItems.value.length) {
      index = 0;
    } else if (index < 0) {
      index = focusItems.value.length - 1;
    }

    selectedMenuItem.value = index;

    await nextTick();

    focusItems.value[selectedMenuItem.value].focus();
  }
}

function focusNext() {
  focusItem(selectedMenuItem.value + 1);
}

function focusPrev() {
  focusItem(selectedMenuItem.value - 1);
}

function focusNextType() {
  // There there's only 1 focusable item then return.
  if (focusItems.value.length <= 1) {
    return;
  }

  const currentElement = focusItems.value[selectedMenuItem.value];
  const currentIndex = selectedMenuItem.value;
  const currentType = currentElement.tagName;

  // We're looking for the next focusable element of a different
  // type to the current element. e.g. input -> li -> button
  for (let i = currentIndex + 1; i < focusItems.value.length; i++) {
    if (focusItems.value[i].tagName !== currentType) {
      focusItems.value[i].focus();
      selectedMenuItem.value = i;
      break;
    }
  }
}

function focusPrevType() {
  // There there's only 1 focusable item then return.
  if (focusItems.value.length <= 1) {
    return;
  }

  const currentElement = focusItems.value[selectedMenuItem.value];
  const currentIndex = selectedMenuItem.value;
  const currentType = currentElement.tagName;

  let prevType = null;
  let prevIndex = null;

  // We're looking for the first instance of a previously focusable
  // element of a different type to the current element. e.g. button -> li -> input
  for (let i = currentIndex - 1; i >= 0; i--) {
    // If prevType has been set at the current loop element changes
    // break as we've found the first instance of hte previously focusable
    // elements.
    if (prevType && prevType !== focusItems.value[i].tagName) {
      break;
    }

    // If the current loop element type matches the stored element type
    // keep decrementing the loop.
    if (focusItems.value[i].tagName === prevType) {
      prevIndex = i;

      // If current loop element type is different to the currnet type we
      // store the new type and decrement the loop.
    } else if (focusItems.value[i].tagName !== currentType) {
      prevType = focusItems.value[i].tagName;
      prevIndex = i;
    }
  }

  // If we found a suitable element focus it.
  if (prevIndex !== null) {
    focusItems.value[prevIndex].focus();
    selectedMenuItem.value = prevIndex;
  }
}

function close() {
  emit('close');
}

function reset() {
  selectedMenuItem.value = -1;

  if (!props.disableAutoFocus) {
    focusItem(0);
  }
}

async function forceUpdateFocusItems() {
  await updateFocusItems();
}

function handleKeydown(e: KeyboardEvent) {
  if (e.key === 'Tab') {
    e.preventDefault();
    if (e.shiftKey) {
      focusPrevType();
    } else {
      focusNextType();
    }
  } else if (e.key === 'Escape') {
    e.preventDefault();
    close();
  } else if (e.key === 'ArrowDown') {
    e.preventDefault();
    focusNext();
  } else if (e.key === 'ArrowUp') {
    e.preventDefault();
    focusPrev();
  } else if (e.shiftKey) {
    e.preventDefault;
  } else if (e.key !== 'Enter' && !e.ctrlKey && !e.altKey) {
    focusItem(0);
  }
}

async function handleFocusOut() {
  await nextTick();

  if (focusElement.value && !focusElement.value.contains(document.activeElement)) {
    selectedMenuItem.value = 0;
  }
}

onMounted(async () => {
  await updateFocusItems();

  // It's possible for the focus of an element within UtilFocusItems to be set
  // externally from UtilFocus. In this case we want to detect using focusin and
  // set the selectedMenuItem accordingly.
  focusInHandler = (event) => {
    const newFocusTarget = event.target as HTMLElement;
    const newFocusIndex = focusItems.value.findIndex((item) => item === newFocusTarget);

    if (newFocusIndex !== -1) {
      selectedMenuItem.value = newFocusIndex;
    }
  };
  window.addEventListener('focusin', focusInHandler);

  // Auto-focus the first element available.
  if (!props.disableAutoFocus && selectedMenuItem.value === -1) {
    focusItem(0);
  }
});

onUnmounted(() => {
  // Remove the focusin listener we added to watch for external
  // focus occurances.
  if (focusInHandler) {
    window.removeEventListener('focusin', focusInHandler);
    focusInHandler = null;
  }
});

onUpdated(async () => {
  await updateFocusItems();
});

defineExpose({
  reset,
  focusItem,
  forceUpdateFocusItems,
});
</script>

<template>
  <div ref="focusElement" class="focus-items" @keydown="handleKeydown" @focusout="handleFocusOut">
    <slot />
  </div>
</template>
