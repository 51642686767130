<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { PageRoutesNames } from '@/common/consts';
import useSharingInternally from '@/modules/cloud-docs/composables/sharing-internally';
import { SwText } from '@swimm/ui';
import { SWIMM_DOC_SITE_SHARING_INTERNALLY } from '@/config';

defineEmits(['click']);

const store = useStore();
const route = useRoute();
const router = useRouter();
const { isSharingInternallyEnabledInWorkspace } = useSharingInternally();

const workspaceId = computed(() => {
  return route.params.workspaceId as string;
});

const isOnSharedDocsPage = computed(() => {
  const sharedDocsRoute = `/workspaces/${workspaceId.value}/shared-docs`;
  return sharedDocsRoute === route.path || `${sharedDocsRoute}/` === route.path;
});

const isOnSharedDocPage = (sharedDocId) => {
  const sharedDocRoute = `/workspaces/${workspaceId.value}/shared-docs/${sharedDocId}`;
  return sharedDocRoute === route.path || `${sharedDocRoute}/` === route.path;
};

const shouldShowSharedDocsSection = computed(() => {
  return isSharingInternallyEnabledInWorkspace(workspaceId.value);
});

const sharedDocs = computed(() => {
  return store.getters['database/db_getSharedDocs'](workspaceId.value);
});

function getSharedDocLink(sharedDocId: string) {
  return router.resolve({
    name: PageRoutesNames.SHARED_DOC_PAGE,
    params: { workspaceId: workspaceId.value, sharedDocId },
  }).fullPath;
}
</script>

<template>
  <div v-if="shouldShowSharedDocsSection" class="shared-docs-section">
    <router-link
      class="submenu-button clickable"
      :to="`/workspaces/${workspaceId}/shared-docs`"
      @click="$emit('click')"
    >
      <SwText component="span" variant="subtitle-L" :class="['button-text', { selected: isOnSharedDocsPage }]">
        Shared
      </SwText>
    </router-link>
    <div class="shared-docs-items">
      <router-link
        v-for="sharedDoc in sharedDocs"
        :key="sharedDoc.id"
        :class="['shared-doc-item', { selected: isOnSharedDocPage(sharedDoc.id) }]"
        :to="getSharedDocLink(sharedDoc.id)"
        v-tooltip="{ content: sharedDoc.name, placement: 'right' }"
      >
        <Icon class="doc-icon" name="doc-shared" no-padding />
        <SwText variant="body-S" class="shared-doc-name text-ellipsis">{{ sharedDoc.name }}</SwText>
      </router-link>
      <div v-if="sharedDocs.length === 0" class="empty-state">
        No docs are shared at the moment.
        <a :href="SWIMM_DOC_SITE_SHARING_INTERNALLY" target="_blank"><u>Learn more</u></a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.shared-docs-section {
  display: flex;
  flex-direction: column;
  padding: var(--space-sm) 0;

  .submenu-button {
    padding: 0 var(--space-sm);

    .button-text {
      cursor: pointer;
      padding: 2px var(--space-xs);
      border-radius: 2px;

      &.selected {
        background-color: var(--color-selected);
        color: var(--text-color-link);
      }

      &:hover {
        background-color: var(--color-hover);
      }
    }
  }

  .shared-docs-items {
    .empty-state {
      padding: var(--space-base) var(--space-md);
      color: var(--text-color-secondary);
      font-size: var(--body-S);

      a {
        color: var(--text-color-link);
        white-space: nowrap;
      }
    }

    .shared-doc-item {
      display: flex;
      align-items: center;
      gap: var(--space-xs);
      padding: var(--space-xs) calc(var(--space-md) - var(--space-xs));
      height: var(--sidebar-item-height);
      box-sizing: border-box;

      &:hover {
        background: var(--color-hover);
      }

      .doc-icon {
        font-size: var(--body-L);
      }

      .shared-doc-name:hover {
        color: var(--text-color-link);
      }

      &.selected {
        background: var(--color-selected);

        .shared-doc-name {
          color: var(--text-color-link);
        }
      }
    }
  }
}
</style>
