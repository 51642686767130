<script setup lang="ts">
import HorizontalSpacer from '@/common/components/atoms/HorizontalSpacer.vue';
import { SwText } from '@swimm/ui';
import { useRoute, useRouter } from 'vue-router';

defineEmits(['show-plugins']);

const router = useRouter();
const route = useRoute();

function navigateToWorkspace() {
  router.push(`/workspaces/${route.params.workspaceId}`);
}
</script>

<template>
  <div>
    <SwText variant="headline2">Your account is ready!</SwText>
    <SwText variant="body-L" class="subtitle">Start knowledge sharing with teams</SwText>
  </div>
  <div>
    <SwText variant="body-L" class="subtitle">What’s next?</SwText>
    <SwText variant="subtitle-L"
      >To proceed, go back to your IDE<br />
      and login to your account</SwText
    >
  </div>
  <HorizontalSpacer class="spacer" />
  <Action @click="navigateToWorkspace" secondary>Explore Swimm on the web</Action>
</template>

<style scoped lang="postcss">
.subtitle {
  color: var(--text-color-secondary);
}

.spacer {
  align-self: stretch;
}
</style>
