import { clearUndefineds } from '../objectUtils';
import type { RepoStateData } from '../types/repo-types';
import * as config from '../config';
import { sortBy } from 'lodash-es';
import { WorkspaceRepo } from '../types';

export type FolderTreeNodeBase = {
  name: string;
  path: string;
};

export type FolderTreeDirectory = FolderTreeNodeBase & {
  type: 'directory';
  children: FolderTree[];
  comment?: string;
};

export type FolderTreeFile = FolderTreeNodeBase & {
  type: 'file';
};

export type FolderTree = FolderTreeFile | FolderTreeDirectory;

export function folderTreeSortFunction(left: FolderTree, right: FolderTree): number {
  if (left.type === 'directory') {
    left.children.sort(folderTreeSortFunction);
  }
  if (right.type === 'directory') {
    right.children.sort(folderTreeSortFunction);
  }
  if (left.type !== right.type) {
    if (left.type === 'directory') {
      return -1;
    } else if (right.type === 'directory') {
      return 1;
    }
  }
  return left.name.localeCompare(right.name);
}

export const UNSUPPORTED_FILE_EXTENSIONS = [
  '.gif',
  '.jpg',
  '.jpeg',
  '.png',
  '.svg',
  '.tiff',
  '.woff',
  '.woff2',
  '.eot',
  '.ttf',
  '.otf',
  '.exe',
  '.pdf',
  '.lock',
  'package-lock.json', // npm lock file is actually a json
  'yarn.lock',
  'pnpm-lock.yaml', // pnpm lock file is actually a yaml
  'bundle.js',
  '.ico',
  '.icns',
  '.tgz',
  '.gz',
  '.zip',
  '.tar',
  '.min.js',
  '.min.css',
  '.mov',
  '.mp4',
  '.webp',
  '.pem',
  '.DS_Store',
  '.snap',
  '.lwo',
  '.mp3',
  '.wav',
  '.mpg',
  '.mpeg',
  '.avi',
  '.flv',
  '.wmv',
  '.mid',
];

const SWM_RELATED_FILE_EXTENSIONS = ['.swm', '.sw.md', 'swimm.json'];

// File extensions that we would like to ignore in some of our operations (e.g - treeView displaying)
const fileTypesToExclude = [...SWM_RELATED_FILE_EXTENSIONS, ...UNSUPPORTED_FILE_EXTENSIONS];

export function isFileExtensionSupported(filePath: string): boolean {
  const lowerCasedFile = filePath.toLowerCase();
  return fileTypesToExclude.every((fileType) => !lowerCasedFile.endsWith(fileType));
}

export function hasExtension(path: string): boolean {
  const pathFolders = path.split('/');
  const lastPath = pathFolders[pathFolders.length - 1];
  return lastPath.includes('.');
}

export function filterIrrelevantRepoFiles(files: string[]): string[] {
  // Exclude .swm directory and irrelevant files
  return files.filter(isFileSupportedFilter);
}

// Filter predicate for code files that Swimm support creating snippets over
export const isFileSupportedFilter = (file) =>
  !file.startsWith(`${config.SWM_FOLDER_IN_REPO}/`) && isFileExtensionSupported(file);

export function parseRepoDataFields(repoStateData: RepoStateData): RepoStateData {
  const repoData: RepoStateData = { ...repoStateData };
  clearUndefineds(repoData);
  return repoData;
}

export function generateFolderTree(files: string[]): FolderTreeDirectory {
  const root: FolderTreeDirectory = {
    name: '',
    path: '',
    children: [],
    type: 'directory',
  };
  files.forEach((filePath) => addFileToTree(root, filePath));
  return root;
}

function addFileToTree(root: FolderTreeDirectory, filePath: string) {
  const fileSplitPath = filePath.split('/'); // 'src/common/store/filesystem.js' => ['src', 'common', 'store', 'filesystem.js']
  let currentFolder = root;
  fileSplitPath.forEach((pathPart, pathPartIndex) => {
    if (pathPartIndex === fileSplitPath.length - 1) {
      // Last path part => actual file
      const file: FolderTreeFile = {
        name: pathPart,
        path: filePath,
        type: 'file',
      };
      currentFolder.children.push(file);
    } else {
      // Not last path part => directory
      currentFolder = addDirectoryIfNeeded(currentFolder, fileSplitPath, pathPartIndex);
    }
  });
}

function addDirectoryIfNeeded(
  currentFolder: FolderTreeDirectory,
  fileSplitPath: string[],
  pathPartIndex: number
): FolderTreeDirectory {
  // Find if directory already added
  let directory = currentFolder.children.find((dir) => dir.name === fileSplitPath[pathPartIndex]) as
    | FolderTreeDirectory
    | undefined;
  if (!directory) {
    // Directory not added yet
    const dirPath = fileSplitPath.slice(0, pathPartIndex + 1).join('/');
    directory = {
      name: fileSplitPath[pathPartIndex],
      path: dirPath,
      children: [],
      type: 'directory',
    };
    currentFolder.children.push(directory);
  }
  return directory;
}

export function sortRepos(repos: WorkspaceRepo[], firstRepoId?: string | undefined) {
  return sortBy(repos, [
    (repo) => repo.id !== firstRepoId,
    (repo) => !repo.isFavourite,
    (repo) => !repo.isRecent,
    'name',
  ]);
}
