export const SYMBOLS_META = 'symbols';
export const NODES_WITH_RELATED_SYMBOLS = [
  'path',
  'genericText',
  'swimm-doc',
  'playlist',
  'text-placeholder',
  'hunkPlaceholder',
];

export const NODES_WITH_APPLICABILITY = [
  'path',
  'hunk',
  'genericText',
  'swimm-doc',
  'playlist',
  'text-placeholder',
  'hunkPlaceholder',
];
