<script setup lang="ts">
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import BatchCommitButtonInner from './BatchCommitButtonInner.vue';
import BatchCommitModalNew from './BatchCommitModalNew.vue';
import { useAppModalsStore } from '@/modules/core/stores/modals-store';
import { computed } from 'vue';
import { useDrafts3ValidationStore } from '@/modules/drafts3/stores/drafts3Validation';
import { storeToRefs } from 'pinia';

defineProps({
  repoId: { type: String, required: true },
  workspaceId: { type: String, required: true },
  branch: { type: String, default: null },
});

const { openAddReposModal } = useAppModalsStore();

const drafts3Store = useDrafts3Store();
const drafts3Validation = useDrafts3ValidationStore();
const { invalidDrafts } = storeToRefs(drafts3Validation);

const draftsCount = computed(() => {
  return drafts3Store.drafts?.size ?? 0;
});

const savableDraftCount = computed(() => {
  return draftsCount.value - invalidDrafts.value.size;
});

const verifyDrafts = async () => {
  await drafts3Validation.verifyDrafts();
};
</script>

<template>
  <BatchCommitButtonInner
    :repo-id="repoId"
    :draft-count="draftsCount"
    :savable-draft-count="savableDraftCount"
    @show-batch-modal="verifyDrafts"
    v-slot="{ hide }"
  >
    <BatchCommitModalNew
      :repo-id="repoId"
      :workspace-id="workspaceId"
      :branch="branch"
      @close="hide({ skipDelay: true })"
      @add-repo="openAddReposModal"
    />
  </BatchCommitButtonInner>
</template>
