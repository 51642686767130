<script setup>
import { Icon } from '@swimm/ui';

defineProps({
  repoId: { type: String, required: true },
  loading: { type: Boolean, required: true },
  addShowRepos: { type: Boolean, required: false, default: false },
  shouldShowRepos: { type: Boolean, required: true }, // if true, in repo list mode
});
const emit = defineEmits(['click', 'refresh', 'show-repos-clicked']);

const showReposClicked = () => {
  emit('show-repos-clicked');
};
</script>

<template>
  <div class="tree-header">
    <div class="header-bottom clickable" @click="emit('click')">
      <div class="header-bottom-repos">
        <div v-if="addShowRepos" :class="{ 'item-logo': addShowRepos, unhighlighted: repoId }">
          <Icon name="branch" class="show-repos-button" @click="showReposClicked" />
          <div class="item-name" data-testid="all-repos" @click="showReposClicked">All repos</div>
        </div>
      </div>
      <div v-if="!shouldShowRepos" class="clickable refresh-tree" @click.stop.prevent="emit('refresh')">
        <Icon name="refresh" :class="{ loading }" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.tree-header {
  background-color: var(--color-bg);
}

.header-bottom {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  color: var(--text-color-primary);
  align-items: center;

  .refresh-tree {
    opacity: 1;
    transition: opacity 0.2s;
    pointer-events: initial;

    .loading {
      animation: spin 0.5s linear infinite;
      display: inline-block;
    }
  }
}

.header-bottom-repos {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}

.background {
  background: var(--color-surface);
}

.item-logo {
  display: flex;
  align-items: center;
}

.unhighlighted {
  color: var(--text-color-secondary);
}

.item-name {
  margin: auto;
  font-size: var(--body-S);
  font-weight: 700;
}

.show-repos-button {
  padding-right: var(--space-base);
  font-size: var(--fontsize-m);
}
</style>
