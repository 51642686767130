<template>
  <AuthBaseLayout :title="title" :subtitle="subtitle" class="card-container">
    <div class="content">
      <form class="form" @keydown.enter.prevent="login" @submit.prevent="login">
        <TextField
          class="input"
          data-testid="email-field"
          v-model.trim="email"
          :error="emailError"
          label="Work Email"
          type="email"
          autocomplete="email"
          focus-first
          required
        />
        <TextField
          v-model="password"
          :error="passwordError"
          label="Password"
          type="password"
          autocomplete="current-password"
          class="input"
          data-testid="password-field"
          required
        />
        <Action :loading="isLoading" data-testid="login-button">Sign in</Action>
        <ErrorBox v-if="error" class="error" data-testid="login-form-error">{{ error }}</ErrorBox>
      </form>
      <HorizontalSpacer />
      <GoogleAuthButton class="google-login" @click="onGoogleLoginClick">Log in with Google</GoogleAuthButton>
      <Action type="a" class="sso-login" data-testid="sso-auth-button" @click.prevent="onSSOLoginClick"
        >Continue with SSO</Action
      >
    </div>
    <template #footer>
      <SwText variant="body-S" class="help-text" data-testid="signup-button"
        >Don't have an account? <router-link to="/register">Sign up</router-link></SwText
      >
      <SwText variant="body-S" class="help-text" data-testid="forgot-button"
        ><router-link to="/forgot">Forgot Password?</router-link></SwText
      >
    </template>
  </AuthBaseLayout>
</template>

<script>
import { loginUserWithPassword } from '@/common/utils/authUtils';
import AuthBaseLayout from '@/modules/core/components/AuthBaseLayout.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { getSystemTheme } from '@/common/utils/theme-utils';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { connectUserToSlack } from '@/modules/slack-app/services/slack-app-utils';
import { UrlUtils, config, eventLogger, pageEvents, productEvents } from '@swimm/shared';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import GoogleAuthButton from '@/common/components/Auth/GoogleAuthButton.vue';
import HorizontalSpacer from '@/common/components/atoms/HorizontalSpacer.vue';
import { ErrorBox, TextField } from '@swimm/ui';
import { v4 as uuidv4 } from 'uuid';
import { PageRoutesNames } from '@/common/consts';
import { fetchSSOSetup } from '@/modules/core/composables/sso';

export default {
  components: { AuthBaseLayout, TextField, GoogleAuthButton, HorizontalSpacer, ErrorBox },
  props: {
    isFromSlack: { type: Boolean, required: true },
  },
  setup() {
    const analytics = useAnalytics();
    const { logEvent } = useSwimmEventLogs();

    reportPageViewed();

    function reportPageViewed() {
      const payload = {
        'Page Name': 'SignIn Page',
      };
      analytics.cloudPageViewed({
        identity: uuidv4(),
        event: pageEvents.LOADED_A_PAGE,
        payload,
      });
      analytics.track(pageEvents.LOADED_A_PAGE_MARKETING, payload);
    }

    return { analytics, logEvent };
  },
  data() {
    return {
      password: '',
      email: '',
      error: '',
      emailError: null,
      passwordError: null,
      isLoading: false,
      emailIsValid: false,
    };
  },
  computed: {
    isEmailValid() {
      return UrlUtils.isValidEmail(this.email);
    },
    isRedirect() {
      return !!(this.$route.query && this.$route.query.redirect);
    },
    isIdeLogin() {
      return this.isRedirect && this.$route.query.redirect.indexOf('ide-login') !== -1;
    },
    title() {
      if (this.isFromSlack) {
        return 'Login to authorize Slack';
      } else {
        return 'Welcome back!';
      }
    },
    subtitle() {
      if (!this.isFromSlack && this.isRedirect && !this.isIdeLogin) {
        return 'Move knowledge fast. Develop faster.';
      }
      return '';
    },
  },
  created() {
    const args = this.isFromSlack ? { origin: 'Slack App' } : {};
    this.analytics.pageVisit(pageEvents.VIEW_LOGIN, args);
  },
  methods: {
    async login() {
      if (this.showErrors()) {
        return;
      }
      this.analytics.track(productEvents.LOGIN_CLICK, {
        Origin: 'Login page',
        'Origin URL': this.$route.fullPath,
        Context: 'Email Form',
      });
      try {
        this.isLoading = true;
        if (await fetchSSOSetup(this.email)) {
          await firebase.auth().signOut();
          return this.$router.push({
            name: PageRoutesNames.SSO,
            query: { ...(this.$route.query || {}), email: this.email },
          });
        }
        const userRequest = await loginUserWithPassword({
          email: this.email,
          password: this.password,
        });
        this.logEvent(
          eventLogger.SWIMM_EVENTS.USER_SIGN_IN,
          { srcId: userRequest.user.uid, srcName: userRequest.user.displayName },
          { uid: userRequest.user.uid, nickname: userRequest.user.displayName }
        );
        this.analytics.initAnalytics({
          uid: userRequest.user.uid,
          nickname: userRequest.user.displayName,
          email: this.email,
          creationTime: userRequest.user.metadata.creationTime,
          lastSignInTime: userRequest.user.metadata.lastSignInTime,
          browserSystemTheme: getSystemTheme(),
        });
        this.analytics.cloudTrack({ identity: userRequest.user.uid, event: productEvents.USER_LOGGED_IN });
        if (this.$route.query.source === config.SLACK_INDICATOR) {
          const success = await connectUserToSlack({
            userUid: userRequest.user.uid,
            slackTeamId: this.$route.query.team,
            slackUserId: this.$route.query.user,
          });
          if (!success) {
            this.$route.query['error'] = config.SLACK_INDICATOR;
          }
        }
        let routeParams;
        if (this.isIdeLogin && this.$route.query.redirect) {
          routeParams = this.$route.query.redirect;
        } else {
          routeParams = { path: this.$route.query.redirect || '/', query: this.$route.query };
        }
        this.analytics.reportHubspotSignIn({
          email: this.email,
        });
        await this.$router.replace(routeParams);
      } catch (error) {
        this.handleLoginError();
      }
      return false; // To prevent default form behavior
    },
    handleLoginError() {
      this.error = 'Some of the details you provided were incorrect, please try again.';

      this.isLoading = false;
    },
    showErrors() {
      this.passwordError = null;
      this.emailError = null;

      if (!this.password) {
        this.passwordError = 'This is a required field';
      }
      if (!this.email) {
        this.emailError = 'This is a required field';
      } else if (!this.isEmailValid) {
        this.emailError = 'Invalid email';
      }

      return this.nicknameError || this.passwordError || this.emailError;
    },
    onGoogleLoginClick() {
      this.analytics.track(productEvents.GOOGLE_LOGIN_CLICK, {
        Origin: 'Login page',
        'Origin URL': this.$route.fullPath,
      });
    },
    onSSOLoginClick() {
      this.analytics.track(productEvents.SSO_LOGIN_CLICK, {
        Origin: 'Login page',
        'Origin URL': this.$route.fullPath,
      });
      return this.$router.push({ name: PageRoutesNames.SSO, query: this.$route.query });
    },
  },
};
</script>

<style scoped>
.card-container {
  margin: auto;
  gap: var(--space-md);

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);
  }

  .help-text {
    a {
      color: var(--text-color-link);
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);
  }

  .error {
    margin: var(--space-md) 0;
  }

  .sso-login {
    cursor: pointer;
    color: var(--text-color-link) !important;
  }
}
</style>
