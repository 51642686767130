<template>
  <div class="node">
    <div
      v-if="!isRoot"
      :style="nodeHeaderDynamicStyle"
      class="node-container"
      :class="['header', 'selectable', { selected: isSelected, disabled: isDisabled }]"
      @click="onNodeClicked"
    >
      <Icon v-if="isFolder" :name="arrowIcon" class="arrow" />
      <div class="folder-name-container" :class="{ 'node-indent': !isFolder }">
        <Icon :name="iconName" />
        <span class="folder-name text-ellipsis">{{ node.name }}</span>
      </div>
      <Icon v-if="showFileLevelAction && !isFolder" class="file-node-action-icon show-on-parent-hover" name="add" />
    </div>
    <div v-if="isFolder && isOpen">
      <div
        v-for="childNode in sortedChildren"
        :id="childNode.path"
        :key="childNode.path"
        :class="{ 'root-doc-node': isFileOnRoot(childNode.type) }"
        data-testid="root-tree-node"
      >
        <TreeNode
          :node="childNode"
          :isRepoNodeSelected="isRepoNodeSelected"
          :path="childNode.path"
          :mark-nodes-with-units="markNodesWithUnits"
          :repo-id="repoId"
          :selected-path="selectedPath"
          @node-selected="onNodeSelected"
          :force-open="forceOpen"
          @node-toggle="nodeToggled"
          :show-file-level-action="showFileLevelAction"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@swimm/ui';
export default {
  name: 'TreeNode',
  components: { Icon },
  props: {
    node: { type: Object, required: true },
    isRoot: { type: Boolean, default: false },
    markNodesWithUnits: { type: Boolean, default: true },
    isRepoNodeSelected: { type: Boolean, default: false },
    forceOpen: { type: Boolean, default: false },
    repoId: { type: String, required: true },
    path: { type: String, required: true },
    selectedPath: { type: String, default: '' },
    showFileLevelAction: { type: Boolean, default: false },
  },
  emits: ['node-selected', 'node-toggle'],
  computed: {
    isFolder() {
      return this.node.type === 'directory';
    },
    iconName() {
      return this.isFolder ? this.folderIcon : 'file';
    },
    folderIcon() {
      return this.isOpen ? 'folder-open' : 'folder';
    },
    arrowIcon() {
      return this.isOpen ? 'arrow-down' : 'arrow-right';
    },
    isSelected() {
      if (this.isRepoNodeSelected) {
        return false;
      }
      return this.selectedPath === this.path;
    },
    isInSelectedPath() {
      return this.isSelected || this.isPartialToSelectedPath;
    },
    isPartialToSelectedPath() {
      return this.selectedPath ? this.selectedPath.startsWith(this.path + '/') : false;
    },
    isOpen() {
      let returnValue = false;
      if (this.forceOpen) {
        // If the node was selected and not explicitly open or the current node is part of the selected path
        returnValue = this.isRoot || this.isPartialToSelectedPath || (this.isSelected && this.node.closed === false);
      } else {
        // This will automatically close folders that are open when a new folder path is selected
        returnValue =
          this.isRoot ||
          (this.isPartialToSelectedPath && !(this.node.closed === true)) ||
          (this.isSelected && this.node.closed === false);
      }
      this.$emit('node-toggle', { node: this.node, open: returnValue });
      return returnValue;
    },
    sortedChildren() {
      if (this.node.children) {
        return [...this.node.children].sort((a, b) => {
          if (a.type === b.type) {
            return a.path < b.path ? -1 : 1;
          }
          return a.type === 'directory' ? -1 : 1;
        });
      }
      return [];
    },
    nodeHeaderDynamicStyle() {
      // Set padding by the tree level
      const length = this.path.split('/').length;
      const padding = 5 + 20 * (length - 1);
      return `padding-left: ${padding}px`;
    },
    isDisabled() {
      // we removed coverage access to check if there are units from the component
      // this is not used any more, but if we want to put it back
      // then we need to put the data on the node itself, on the fileld hasUnits
      return this.markNodesWithUnits && !this.node.hasUnits;
    },
  },
  methods: {
    isFileOnRoot(nodeType) {
      return this.isRoot && nodeType !== 'directory';
    },
    onNodeClicked() {
      this.$emit('node-selected', this.node);
    },
    onNodeSelected(nodeClicked) {
      this.$emit('node-selected', nodeClicked);
    },
    nodeToggled(node) {
      this.$emit('node-toggle', node);
    },
  },
};
</script>

<style scoped lang="postcss">
.file-node-action-icon {
  right: 10px;
}

.show-on-parent-hover {
  visibility: hidden;
}

.node-container:hover .show-on-parent-hover {
  visibility: visible;
}

.root-node-doc {
  padding-left: 20px;
  color: var(--text-color-primary);
}

.node {
  cursor: pointer;
  color: var(--text-color-primary);

  .node-container {
    display: flex;
    align-items: center;
  }

  .node-indent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    overflow: hidden;
  }

  .header {
    position: relative;
    padding-top: 2px;
    padding-bottom: 2px;

    &.selected {
      color: var(--text-color-primary);
      background: var(--color-selected);

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 4px;
        height: 100%;
        background-color: var(--color-brand);
      }
    }

    &:not(.selectable) {
      pointer-events: none;
    }

    &.selectable:not(.selected):hover {
      background: var(--color-hover);
    }

    &.disabled {
      color: var(--text-color-disable);
    }

    .folder-name-container {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      flex: 1;
    }

    .folder-name {
      display: inline-block;
      font-size: var(--body-L);
      vertical-align: bottom;
      flex: 1;
    }

    .arrow {
      font-size: var(--fontsize-xxs);
    }
  }
}
</style>
