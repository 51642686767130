<script setup lang="ts">
import { useNavigate } from '@/common/composables/navigate';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { storeToRefs } from 'pinia';
import { ComputedRef, computed, nextTick, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BranchSelector from '@/common/components/organisms/BranchSelector.vue';
import EllipsisTooltip from '@/common/components/organisms/EllipsisTooltip.vue';
import { getGitProviderIconName } from '@swimm/shared';
import { useRepoDocsStore } from '@/modules/core/stores/repo-docs';
import { usePlaylistStore } from '@/modules/playlists/store';
import { useEditPlaylistStore } from '@/modules/playlists/store/edit-playlist';
import { usePlaylist } from '@/modules/playlists/composables/playlist';
import { useBranchSwitcher3 } from '@/common/composables/branchSwitcher3';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { useCurrentRoute } from '@/common/composables/currentRoute';

const { navigateToBranch, navigateToPageAndTerminateWorker } = useNavigate();

const route = useRoute();
const router = useRouter();
const { user } = storeToRefs(useAuthStore());
const { currentDoc } = storeToRefs(useRepoDocsStore());
const { id: workspaceId, name: workspaceName } = storeToRefs(useWorkspaceStore());
const { repoId, repos } = storeToRefs(useReposStore());
const { currentPlaylist: currentViewedPlaylist } = storeToRefs(usePlaylistStore());
const { playlist: currentEditedPlaylist } = storeToRefs(useEditPlaylistStore());
const { getStepBreadcrumbIcon, playlistId } = usePlaylist();
const { validateNewBranch } = useBranchSwitcher3();
const drafts3Store = useDrafts3Store();
const {
  isDocPage,
  isEditDocPage,
  isEditPlaylistPage,
  isSharedDocPage,
  isStatusPage,
  isSharedDocsPage,
  isCreateWorkspacePage,
  isJoinWorkspacePage,
  isRequestToJoinPage,
} = useCurrentRoute();
const ELLIPSIS_TEXT_LENGTH = 20;

type Crumb = {
  name: string;
  link?: string;
  icon?: string;
  type: BreadcrumbType;
  logo?: string;
  skip?: boolean;
  showBranchSelector?: boolean;
};

type BreadcrumbType = 'workspace' | 'repo' | 'playlist' | 'doc' | 'step';

const props = withDefaults(
  defineProps<{
    hidden?: boolean;
    appendixIcon?: string;
    appendixLabel?: string;
    isAppendixLabelError?: boolean;
    showWorkspaceBreadcrumb?: boolean;
    showRouteBack?: boolean;
  }>(),
  {
    appendixIcon: '',
    appendixLabel: '',
    isAppendixLabelError: false,
    showWorkspaceBreadcrumb: true,
    showRouteBack: true,
  }
);

const emit = defineEmits<{
  (e: 'branch-changed'): void;
}>();
function back() {
  if (crumbs.value.length && window.history.length > 2) {
    // If this is not a new tab
    router.go(-1); // Go back to the page we came from on back
  } else {
    let link = '/';
    if (crumbs.value.length > 1) {
      let backTo: Crumb = crumbs[crumbs.value.length - 2];
      if (backTo && backTo.skip) {
        backTo = crumbs[crumbs.value.length - 3];
      }
      if (backTo) {
        link = backTo.link;
      }
    }
    navigateToPageAndTerminateWorker({ newRoute: link });
  }
}

function isLastCrumb(crumbIndex: number) {
  return crumbIndex === crumbs.value.length - 1;
}

const loadingBranch = ref(false);

const shouldDisableBranchSelector = computed(() => {
  return isEditPlaylistPage.value;
});
async function verifyTargetBranch(targetBranch: string) {
  const draftIds = [];
  // draft can be only to edited doc
  // so we check if we are in edit and if it is in draft store
  if (isDocIdInDrafts.value && isEditDocPage.value) {
    draftIds.push(docId.value);
  }
  return validateNewBranch(targetBranch, draftIds);
}

function handleBranchChange(branchData: { newBranchName: string }) {
  loadingBranch.value = true;
  emit('branch-changed');
  // Call nextTick so the 'branch-changed' emit could finish before cleaning the repo data.
  nextTick(async () => {
    await navigateToBranch(branchData.newBranchName);
  });
  // Show the loader for a bit longer than needed
  setTimeout(() => (loadingBranch.value = false), 1000);
}

function isCrumbClickable(crumb: Crumb, index: number) {
  return !crumb.skip && (!isLastCrumb(index) || crumb.type === 'repo');
}

const repo = computed(() => {
  return repoId.value ? repos.value.find((repo) => repo.id === repoId.value) : undefined;
});

const branch = computed<string>(() => route.params.branch as string);
const docId = computed(() => route.params.unitId as string);
const isDocIdInDrafts = computed(() => drafts3Store.drafts?.has(docId.value) ?? false);

const sharedDocId = computed(() => route.params.sharedDocId as string);

const playlistStepIndex = computed(() =>
  playlistId.value && route.params.stepIndex ? parseInt(route.params.stepIndex as string, 10) : null
);

const playlistStep = computed(() => {
  if (playlistStepIndex.value != null) {
    return currentViewedPlaylist.value?.sequence[playlistStepIndex.value] ?? null;
  }
  return null;
});

const crumbs: ComputedRef<Crumb[]> = computed(() => {
  const result: Crumb[] = [];
  if (workspaceId.value && props.showWorkspaceBreadcrumb) {
    result.push({
      name: workspaceName.value,
      link: `/workspaces/${workspaceId.value}`,
      icon: 'home',
      type: 'workspace',
    });
  } else if (isCreateWorkspacePage.value) {
    result.push({ name: 'Create a workspace', link: '/create', skip: false, type: 'workspace' });
  } else if (isRequestToJoinPage.value) {
    result.push({ name: 'Request to join', link: '/request', skip: false, type: 'workspace' });
  } else if (isJoinWorkspacePage.value) {
    result.push({ name: 'Join a workspace', link: '/joinWorkspace', skip: false, type: 'workspace' });
  }

  if (isSharedDocsPage.value || isSharedDocPage.value) {
    result.push({
      name: 'Shared docs',
      link: `/workspaces/${workspaceId.value}/shared-docs`,
      icon: 'globe',
      skip: false,
      type: 'workspace',
    });
    if (isSharedDocPage.value && currentDoc.value?.title) {
      const name = currentDoc.value?.title;
      result.push({
        name,
        link: `/workspaces/${workspaceId.value}/shared-docs/${sharedDocId.value}`,
        icon: 'doc',
        type: 'doc',
      });
    }
  }

  if (workspaceId.value && repo.value) {
    let repoLink = `/workspaces/${workspaceId.value}/repos/${repo.value.id}`;
    if (branch.value) {
      repoLink += `/branch/${encodeURIComponent(branch.value)}`;
    }
    result.push({
      link: repoLink,
      name: repo.value.name,
      type: 'repo',
      icon: getGitProviderIconName(repo.value.provider),
    });
    if (branch.value) {
      result.push({
        link: repoLink,
        name: branch.value,
        type: 'repo',
        showBranchSelector: true,
      });
    }
    if (isStatusPage.value) {
      result.push({
        name: 'status',
        icon: 'check',
        skip: true,
        type: 'repo',
      });
    }
    if (isDocPage.value && currentDoc.value?.id === docId.value) {
      const docLink = `${repoLink}/docs/${currentDoc.value.id}}`;
      // this is is to prevent any breadcrumbs when you switch from doc to doc
      // and the title was not yet computed
      if (currentDoc.value.title || isEditDocPage.value) {
        result.push({
          link: docLink,
          name: currentDoc.value.title ?? 'Untitled',
          type: 'doc',
          icon: 'doc',
        });
      }
    } else if (playlistId.value) {
      const playlistLink = `${repoLink}/playlists/${playlistId.value}`;
      const playlistName = isEditPlaylistPage.value
        ? currentEditedPlaylist.value?.name || 'Untitled Playlist'
        : currentViewedPlaylist.value?.name;
      result.push({
        link: playlistLink,
        name: playlistName ?? 'Untitled Playlist',
        type: 'playlist',
        icon: 'playlist',
      });
      // no playlist step breadcrumbs in edit
      // not sure why, but this is how it is done today
      if (!isEditPlaylistPage.value && !!playlistStep.value) {
        const icon = getStepBreadcrumbIcon(playlistStep.value);
        result.push({
          link: `${playlistLink}/steps/${playlistStepIndex.value}`,
          name: playlistStep.value.name,
          type: 'step',
          icon,
        });
      }
    }
  }
  return result;
});
</script>

<template>
  <template v-if="user">
    <Icon
      v-if="!hidden && showRouteBack"
      :name="crumbs.length ? 'back-browsing' : 'home'"
      data-testid="back-browsing"
      :class="['headline3', 'crumb', 'back', 'clickable']"
      @click="back"
    />
    <div
      v-show="!hidden"
      v-for="(crumb, i) in crumbs"
      :key="`crumb${i}`"
      class="crumb-wrapper"
      :class="{ last: isLastCrumb(i), 'branch-selector': crumb.showBranchSelector }"
    >
      <span v-if="i > 0" class="slash">/</span>
      <BranchSelector
        v-if="!!crumb.showBranchSelector"
        :key="repoId"
        :repo-id="repoId"
        :is-readonly="shouldDisableBranchSelector"
        :loading="loadingBranch"
        :validate="verifyTargetBranch"
        @branch-changed="handleBranchChange"
      />
      <div
        v-else
        :class="['crumb', { clickable: isCrumbClickable(crumb, i) }]"
        data-testid="breadcrumb"
        :data-crumb-type="crumb.type"
      >
        <div v-if="crumb.skip" class="crumb-inner">
          <img v-if="crumb.logo" :src="crumb.logo" class="crumb-logo" alt="A crumb logo" />
          <Icon v-else-if="crumb.icon" :name="crumb.icon" no-padding class="headline3 crumb-icon" />
          <div class="crumb-name data-hj-suppress">{{ crumb.name }}</div>
        </div>
        <router-link v-else :to="crumb.link" class="crumb-inner">
          <img v-if="crumb.logo" :src="crumb.logo" class="crumb-logo" alt="A crumb logo" />
          <Icon v-else-if="crumb.icon" :name="crumb.icon" no-padding class="headline3 crumb-icon" />
          <div v-if="isLastCrumb(i)" class="crumb-name data-hj-suppress">{{ crumb.name }}</div>
          <EllipsisTooltip
            v-else
            class="crumb-name data-hj-suppress"
            :content="crumb.name"
            :length="ELLIPSIS_TEXT_LENGTH"
          >
            {{ crumb.name }}
          </EllipsisTooltip>
        </router-link>
      </div>
    </div>
    <slot v-if="!hidden" />
    <transition v-if="!hidden" name="fade">
      <span
        v-if="appendixLabel"
        class="body-S appendix-label"
        :class="{ 'appendix-label-error': isAppendixLabelError }"
        data-testid="appendix-label"
      >
        {{ appendixLabel }}
        <Icon v-if="appendixIcon" :name="appendixIcon" class="save-icon" />
      </span>
    </transition>
    <div v-if="!hidden" class="final-space" />
  </template>
</template>

<style scoped>
.crumbs {
  display: flex;
  align-items: center;
}

.crumb.clickable {
  pointer-events: auto;
}

.crumb-wrapper:not(.last) .crumb.clickable:hover {
  background-color: var(--color-hover);
}

.slash {
  margin: 0px 5px;
}

.crumb {
  display: flex;
  align-items: center;
  padding: 3px;
  border-radius: 5px;
  white-space: nowrap;
  pointer-events: none;
}

.crumb:not(:last-child):not(.branch-selector) {
  overflow: hidden;
  max-width: 20ch;
  text-overflow: ellipsis;
}

.crumb.back {
  margin-left: var(--space-sm);
  flex-shrink: 0;
}

.crumb .crumb-icon.icon-home {
  font-size: var(--body-L);
}

.crumb-wrapper {
  display: flex;
  align-items: center;
  flex-shrink: 100;
}

.crumb-wrapper a {
  overflow: hidden;
  padding: 0 var(--space-xs);
  text-overflow: ellipsis;
}

.crumb-wrapper:first-child {
  margin-left: var(--space-sm);
}

.crumb-wrapper.last:not(.branch-selector) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .crumb {
    overflow: hidden;
  }

  a {
    padding: 5px;
    min-width: 100%;
  }
}

.crumb-inner {
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: var(--space-xs);
  flex-shrink: 1;
}

.crumb-name {
  --crumb-name-margin-left: 3px;
  overflow: hidden;
  margin-left: var(--crumb-name-margin-left);
  text-overflow: ellipsis;
}

.crumb-logo {
  --crumb-logo-beam-size: 22px;
  height: var(--crumb-logo-beam-size);
}

.appendix-label {
  margin-inline-start: 5px;
  color: var(--text-color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
}

.appendix-label.appendix-label-error {
  color: var(--text-color-error);
}

.save-icon {
  color: var(--text-color-secondary);
  font-size: var(--fontsize-sm);
}

.final-space {
  flex-grow: 1;
}
</style>
