export enum SmartItemTypes {
  TOKEN = 'Token',
  SNIPPET = 'Snippet',
  PATH = 'Path',
}

export enum SmartItemActions {
  ACCEPT = 'accept',
  DELETE = 'delete',
  RESELECT = 'reselect',
}
