<script lang="ts">
import { Scales } from '../../types';
import md5 from 'md5';
import { take, words } from 'lodash-es';

export const AvatarSizes = ['large', 'xlarge', 'xxlarge', 'xxxlarge', 'mega'] as const;
export type AvatarSizesType = Extract<Scales, (typeof AvatarSizes)[number]>;
</script>

<script setup lang="ts">
import { computed, ref } from 'vue';

const COLOR_INDEX_COUNT = 12;

const props = withDefaults(
  defineProps<{
    size?: AvatarSizesType;
    email?: string;
    name: string;
    url?: string;
    initials?: number;
    forceColorIndex?: number;
  }>(),
  {
    size: 'large',
    email: undefined,
    url: undefined,
    initials: 2,
    forceColorIndex: undefined,
  }
);

const error = ref(false);

const initials = computed(() => {
  if (props.url) {
    return undefined;
  }
  const nameWords = words(props.name);
  const limitedWords = take(nameWords, props.initials);
  const initials = limitedWords.map((word) => word[0].toUpperCase()).join('');

  return initials;
});

const colorIndex = computed(
  () =>
    props.forceColorIndex ||
    ([...(props.name || '')].reduce((sum, letter) => sum + letter.charCodeAt(0), 0) % COLOR_INDEX_COUNT) + 1
);

const src = computed(() => {
  if (error.value) {
    return undefined;
  }

  if (props.url) {
    return props.url;
  }

  if (props.email) {
    const size = {
      medium: 64,
      large: 96,
      xlarge: 128,
      xxlarge: 192,
      xxxlarge: 288,
      mega: 448,
    };

    return getGravatar({ size: size[props.size], email: props.email });
  }

  return undefined;
});

const computedClasses = computed(() => ({
  [`avatar--${props.size}`]: true,
  [`avatar--color-${colorIndex.value}`]: true,
}));

function getGravatar(options: {
  email: string;
  size?: number;
  default?: '404' | 'mp' | 'identicon' | 'monsterid' | 'wavatar' | 'retro' | 'robohash' | 'blank';
}): string {
  /**
   * @see https://en.gravatar.com/site/implement/hash/
   */
  const hash = md5(options.email.trim().toLowerCase());
  const url = new URL(hash, 'https://www.gravatar.com/avatar/');
  url.searchParams.set('s', `${options.size || 128}`);
  url.searchParams.set('default', options.default || '404');
  return url.toString();
}

function onImgError() {
  error.value = true;
}
</script>

<template>
  <span class="avatar" :class="computedClasses">
    <img v-if="src" :src="src" :alt="`${name}'s avatar image`" class="avatar__img" @error="onImgError" />
    <span v-else class="avatar__initials" role="img" :aria-label="name" v-text="initials" />
  </span>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.avatar {
  @include defaults;

  border-radius: 50%;
  color: var(--clear-white);
  display: block;
  flex-shrink: 0;
  text-align: center;
  text-transform: uppercase;

  &__img {
    display: block;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  /**
   * Size modifiers.
   */
  &--large {
    height: var(--scale-large);
    font-size: var(--font-size-xxsmall);
    line-height: var(--scale-large);
    width: var(--scale-large);
  }

  &--xlarge {
    height: var(--scale-xlarge);
    font-size: var(--font-size-small);
    line-height: var(--scale-xlarge);
    width: var(--scale-xlarge);
  }

  &--xxlarge {
    height: var(--scale-xxlarge);
    font-size: var(--font-size-large);
    line-height: var(--scale-xxlarge);
    width: var(--scale-xxlarge);
  }

  &--xxxlarge {
    height: var(--scale-xxxlarge);
    font-size: var(--font-size-xxlarge);
    line-height: var(--scale-xxxlarge);
    width: var(--scale-xxxlarge);
  }

  &--mega {
    height: var(--scale-mega);
    font-size: var(--font-size-mega);
    line-height: var(--scale-mega);
    width: var(--scale-mega);
  }

  /**
   * Color modifiers.
   */
  &--color-1 {
    color: var(--quick-dive);
    background-color: var(--floating-glacier);
  }

  &--color-2 {
    color: var(--clear-white);
    background-color: var(--shallow-waters);
  }

  &--color-3 {
    color: var(--floating-glacier);
    background-color: var(--swimm-blue);
  }

  &--color-4 {
    color: var(--floating-glacier);
    background-color: var(--quick-dive);
  }

  &--color-5 {
    color: var(--purple-aura);
    background-color: var(--jelly-shake);
  }

  &--color-6 {
    color: var(--purple-aura);
    background-color: var(--pixie-dust);
  }

  &--color-7 {
    color: var(--clear-white);
    background-color: var(--high-violet);
  }

  &--color-8 {
    color: var(--safe-zone);
    background-color: var(--purple-aura);
  }

  &--color-9 {
    color: var(--army-duck);
    background-color: var(--just-hatched);
  }

  &--color-10 {
    color: var(--sand-castle);
    background-color: var(--duck-mode);
  }

  &--color-11 {
    color: var(--gravel-rocks);
    background-color: var(--fifty-shades);
  }

  &--color-12 {
    color: var(--clear-white);
    background-color: var(--high-tide);
  }
}
</style>
