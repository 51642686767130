export interface StaticSwmHunkMetadata {
  hunkComments: string[];
}

export interface StaticSwmHunk {
  swimmHunkMetadata: StaticSwmHunkMetadata;
  hunkDiffLines: string[];
}

export interface StaticSwmPatch {
  diffType: string;
  fileDiffHeader: string;
  hunks: StaticSwmHunk[];
}

export interface StaticSwmFile {
  id: string;
  name: string;
  dod?: string;
  description?: string;
  summary?: string;
  hunksOrder: string[];
  tests?: string[];
  hints?: string[];
  play_mode?: string;
  swimmPatch: Record<string, StaticSwmPatch>;
  app_version: string;
  file_version: string;
  last_commit_sha_for_swimm_patch?: string;
}
