<script setup lang="ts">
import SuggestionCard from '@/modules/core/workspace/home/components/SuggestionCard.vue';
import VideoTutorialModal from '@/modules/help-center/modals/VideoTutorialModal.vue';
import { SwText } from '@swimm/ui';
import { computed, onBeforeMount, ref } from 'vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';
import { localStorageKeys } from '@/common/consts';
import LocalStorage from '@/local-storage';
import type { RouteLocationRaw } from 'vue-router';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { storeToRefs } from 'pinia';
import ProductTourModal from '@/modules/core/workspace/home/modals/ProductTourModal.vue';
import {
  HIGHLIGHTED_SUGGESTIONS,
  SUGGESTIONS,
  SUGGESTIONS_FOR_DUMMY_REPO,
  SuggestionType,
  suggestions,
} from '@/modules/core/workspace/home/consts';

const analytics = useAnalytics();
const { hasDummyRepo } = storeToRefs(useWorkspaceStore());

const showVideoModal = ref(false);
const videoUrl = ref('');
const showProductTourModal = ref(false);

const dismissedSuggestions = ref([]);

function showVideo(url: string) {
  videoUrl.value = url;
  showVideoModal.value = true;
}

function reportClick(cardName: string) {
  analytics.track(productEvents.SUGGESTION_CARD_CLICKED, {
    'Card Name': cardName,
  });
}

function onLinkSuggestionClick(suggestionKey: SUGGESTIONS) {
  reportClick(suggestionKey);
}

function onSuggestionClick(suggestionType: SuggestionType, suggestionKey: SUGGESTIONS) {
  if (suggestionType === SuggestionType.VIDEO) {
    onVideoSuggestionClick(suggestionKey);
  } else {
    onProductTourSuggestionClick(suggestionKey);
  }
}

function onVideoSuggestionClick(suggestionKey: SUGGESTIONS) {
  const url = suggestions.find((item) => item.key === suggestionKey).videoUrl;
  showVideo(url);
  reportClick(suggestionKey);
}

function onProductTourSuggestionClick(suggestionKey: SUGGESTIONS) {
  showProductTourModal.value = true;
  reportClick(suggestionKey);
}

function onDismiss(suggestionKey: SUGGESTIONS) {
  dismissedSuggestions.value.push(suggestionKey);
  LocalStorage.set(localStorageKeys.DISMISSED_HOME_SUGGESTION, dismissedSuggestions.value);
}

const shownSuggestions = computed(() => {
  return suggestions.filter((item) => {
    const isDismissed = dismissedSuggestions.value.includes(item.key);
    const isDummyRepoSuggestion = SUGGESTIONS_FOR_DUMMY_REPO.includes(item.key);
    if (!hasDummyRepo.value && isDummyRepoSuggestion) {
      return false;
    }
    return !isDismissed;
  });
});

function getLinkSuggestionProps(
  suggestionType: SuggestionType,
  suggestionLink: string | RouteLocationRaw
): { href: string; target: string } | { to: RouteLocationRaw } {
  if (suggestionType === SuggestionType.EXTERNAL_URL) {
    return {
      href: suggestionLink as string,
      target: '_blank',
    };
  }
  return { to: suggestionLink };
}

onBeforeMount(() => {
  dismissedSuggestions.value = LocalStorage.get(localStorageKeys.DISMISSED_HOME_SUGGESTION) || [];
});
</script>

<template>
  <section class="suggestions-section" v-if="shownSuggestions.length">
    <header class="header">
      <SwText variant="subtitle-L">Recommended links & clips</SwText>
    </header>
    <TransitionGroup class="suggestion-cards" name="list" tag="div">
      <template v-for="suggestion in shownSuggestions" :key="suggestion.key">
        <component
          v-if="suggestion.link"
          :is="suggestion.type === SuggestionType.EXTERNAL_URL ? 'a' : 'router-link'"
          v-bind="getLinkSuggestionProps(suggestion.type, suggestion.link)"
          class="link"
        >
          <SuggestionCard
            :title="suggestion.title"
            :image="suggestion.image"
            :highlight="HIGHLIGHTED_SUGGESTIONS.includes(suggestion.key)"
            @click="onLinkSuggestionClick(suggestion.key)"
            @dismiss="onDismiss(suggestion.key)"
          />
        </component>
        <SuggestionCard
          v-else
          :title="suggestion.title"
          :image="suggestion.image"
          :highlight="HIGHLIGHTED_SUGGESTIONS.includes(suggestion.key)"
          @click="onSuggestionClick(suggestion.type, suggestion.key)"
          @dismiss="onDismiss(suggestion.key)"
        />
      </template>
    </TransitionGroup>
  </section>
  <VideoTutorialModal :url="videoUrl" :show="showVideoModal" @close="showVideoModal = false" />
  <ProductTourModal :show="showProductTourModal" @close="showProductTourModal = false" />
</template>

<style scoped lang="postcss">
.suggestions-section {
  margin: var(--space-lg) 0;

  .header {
    display: flex;
    justify-content: space-between;
  }

  .suggestion-cards {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    margin: var(--space-base) 0;
    gap: var(--space-md);

    .link {
      display: flex;
    }
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.2s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
