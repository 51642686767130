<script setup>
import { computed, ref } from 'vue';
import { TAG_COLORS, compareTagNames } from '@/modules/doc-sidebar/services/tag-utils';
import DocTag from '@/modules/doc-sidebar/components/DocTag.vue';
import DocTags from '@/modules/doc-sidebar/components/DocTags.vue';
import TagHandler from '@/common/components/atoms/TagHandler.vue';
import { useStore } from 'vuex';
import { useRouteData } from '@/common/composables/useRouteData';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';

const routeData = useRouteData();
const store = useStore();
const drafts3Store = useDrafts3Store();
const getAllWorkspaceTags = store.getters['database/db_getAllWorkspaceTags'];
const getDocTagsFromDB = store.getters['database/db_getDocTags'];

const draft = computed(() => {
  return drafts3Store.drafts?.get(props.docId);
});
const draftLoadedTags = computed(() => {
  const tagIds = draft.value?.tags ?? [];
  return allTags.value.filter((tag) => tagIds.includes(tag.id));
});

const allTags = computed(() => {
  return getAllWorkspaceTags(routeData.workspaceId).sort(compareTagNames);
});

const allTagsNames = computed(() => {
  return allTags.value.map((tag) => tag.name);
});

const props = defineProps({
  isBranchShown: { type: Boolean, default: false },
  docId: { type: String, default: null },
  repoId: { type: String, default: null },
});

const docTags = computed(() => {
  let tags;
  if (draft.value?.isNew) {
    tags = draftLoadedTags.value;
  } else {
    tags = getDocTagsFromDB(routeData.workspaceId, props.repoId, props.docId);
  }
  return tags.sort(compareTagNames);
});

const maxLength = {
  cardWithBranch: 6,
  cardWithBranchAndMore: 3,
  cardWithoutBranchAndWithMore: 16,
};

const TagColorClass = (tag) => (TAG_COLORS.includes(tag.color) ? `doc_tag doc_tag_${tag.color}` : 'doc_tag');

const firstTag = computed(() => docTags.value[0]);
const firstTagName = computed(() => firstTag.value.name);

const showTagToolTip = ref(false);
const renamingMainTag = ref(false);
const isMenuOpen = ref(false);

const onRename = () => {
  renamingMainTag.value = true;
};

const onCloseRename = () => {
  renamingMainTag.value = false;
};

const getTagShortenedName = (tag, charNum) => {
  if (tag.length > charNum) {
    showTagToolTip.value = true;
    return tag.slice(0, charNum) + '...';
  }
  return tag;
};

const firstTagEllipsisName = () => {
  const tag = docTags.value[0].name;
  if (props.isBranchShown) {
    if (docTags.value.length > 1) {
      return getTagShortenedName(tag, maxLength.cardWithBranchAndMore);
    }

    return getTagShortenedName(tag, maxLength.cardWithBranch);
  }

  if (docTags.value.length > 1) {
    return getTagShortenedName(tag, maxLength.cardWithoutBranchAndWithMore);
  }

  return tag;
};
</script>

<template>
  <div class="container" @click.prevent.stop>
    <div
      v-if="docTags.length > 0"
      v-tooltip="{ disabled: !showTagToolTip, content: firstTagName }"
      class="tag-container"
    >
      <DocTag
        v-if="docTags.length > 0"
        :text="docTags[0].name"
        :display-text="firstTagEllipsisName()"
        :tag-style="TagColorClass(firstTag)"
        :tag-id="firstTag.id"
        :doc-id="docId"
        :repo-id="repoId"
        :workspace-id="routeData.workspaceId"
        :all-tags-names="allTagsNames"
        @on-rename="onRename"
        @done-rename="onCloseRename"
      />
    </div>
    <VMenu
      theme="menu-no-arrow"
      placement="right-start"
      :triggers="['click']"
      :delay="0"
      :popper-triggers="['hover']"
      @show="isMenuOpen = true"
      @hide="isMenuOpen = false"
    >
      <TagHandler
        :class="{ hidden: docTags.length < 2 }"
        :tag="{ text: `+${docTags.length - 1}` }"
        tag-style="doc_tag"
        class="tag-chip"
      />
      <TagHandler
        :class="['doc-tags-icon', { hidden: docTags.length > 1 || !isMenuOpen || renamingMainTag }]"
        data-testid="doc-tags-icon"
        :tag="{ text: 'tag', isIcon: true, tooltip: 'Manage tags' }"
      />
      <template #popper>
        <DocTags
          :doc-id="docId"
          :should-show-dropdown="true"
          :should-use-default-text="false"
          :is-popup="true"
          :show-border="false"
          :repo-id="repoId"
        />
      </template>
    </VMenu>
  </div>
</template>

<style scoped lang="postcss">
.container {
  display: flex;

  .tag-chip {
    color: var(--text-color-primary);
    cursor: pointer;

    &:hover {
      color: var(--text-color-link);
    }
  }

  .hidden {
    display: none;
  }

  .tag-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
</style>
