import { useStore } from 'vuex';

import type { TokenSuggestion } from '@swimm/shared';
import {
  CodeAnalysisAction,
  GitProviderName,
  type SwmSymbolGenericText,
  UrlUtils,
  getLoggerNew,
  gitProviderUtils,
  gitwrapper,
} from '@swimm/shared';

import { getSupportedLanguagesForRepo, invokeCodeAnalysis } from '@/common/utils/code-analysis';

const logger = getLoggerNew(__modulename);

export function useCodeAnalysis() {
  const store = useStore();
  const call = async (repoId: string, branch: string, commitSha: string): Promise<TokenSuggestion[]> => {
    const repoMetadata = store.getters['database/db_getRepoMetadata'](repoId) as {
      url: string;
      provider: GitProviderName;
      api_url?: string;
    };

    const repoGitHostingUrl = repoMetadata.api_url
      ? UrlUtils.getUrlHostname(repoMetadata.api_url)
      : UrlUtils.providerToGitCloudHostingUrl(repoMetadata.provider);
    const token = await gitProviderUtils.getGitProviderToken({
      gitHostingUrl: repoGitHostingUrl,
      isClient: false,
      callerName: 'sgd-discover',
      repoId,
    });

    const languageMap = (await gitwrapper.getRepositoryLanguages(repoId)) ?? {};
    if (!languageMap) {
      logger.warn(`Failed to get languages for repository ${repoId} from gitwrapper`);
    }
    const supportedLanguages = languageMap ? getSupportedLanguagesForRepo(languageMap, false) : [];

    const resultTokens: SwmSymbolGenericText[] = await invokeCodeAnalysis({
      args: {
        action: CodeAnalysisAction.FIND_DEFINITION_TOKENS,
        repoTarget: {
          cloneUrl: repoMetadata.url,
          languages: supportedLanguages,
          branchOrTag: branch,
          commitSha,
        },
        cloneToken: token ?? '',
        repoId,
      },
      responseField: 'tokens',
    });

    return resultTokens.map<TokenSuggestion>((resultToken) =>
      swmSymbolGenericTextToTokenSuggestion(resultToken, { isStatic: true })
    );
  };

  return { call };
}

export function swmSymbolGenericTextToTokenSuggestion(
  symbol: SwmSymbolGenericText,
  { isStatic }: { isStatic: boolean }
): TokenSuggestion {
  return {
    token: symbol.text,
    position: {
      path: symbol.path,
      line: symbol.lineNumber,
      wordStart: symbol.wordIndex.start,
      wordEnd: symbol.wordIndex.end,
    },
    lineData: symbol.lineData,
    repoId: symbol.repoId,
    static: isStatic,
  };
}
