<script setup>
import { MD_FILE_KINDS } from '@/modules/batch-import/constants';
import { computed } from 'vue';
import { Checkbox } from '@swimm/ui';

const props = defineProps({
  id: {
    type: String,
    required: true,
    validator: (value) => Object.values(MD_FILE_KINDS).find((kind) => kind.id === value),
  },
  kind: {
    type: String,
    required: true,
    validator: (value) => Object.values(MD_FILE_KINDS).find((kind) => kind.title === value),
  },
  files: { type: Array, required: true },
  filesSelected: { type: Object, required: true },
});

const emit = defineEmits(['select-all', 'file-selected']);

const allSelected = computed({
  get() {
    return Object.values(props.filesSelected).every((v) => v);
  },

  set(value) {
    emit('select-all', value);
  },
});

const handleFileSelection = ({ fileId, checked }) => {
  emit('file-selected', { fileId, checked });
};
</script>

<template>
  <div class="files-selector">
    <Checkbox
      :disabled="files.length <= 0"
      class="all-checkbox"
      size="small"
      v-model="allSelected"
      :data-testid="`${id}-files-all`"
    >
      <SwText class="all-checkbox-text" variant="subtitle-L" weight="bold">All {{ kind }} files</SwText>
    </Checkbox>
    <SwText v-if="files.length <= 0" variant="body-S">{{ kind }} files were not detected</SwText>
    <div v-else class="files-selection-container">
      <div v-for="(file, index) in files" :key="`${file.id}-${index}`" class="file-selection">
        <Checkbox
          class="file-checkbox"
          size="small"
          :model-value="filesSelected[file.id]"
          :title="file.path"
          @update:model-value="(checked) => handleFileSelection({ fileId: file.id, checked })"
          :data-testid="`${id}-file-selection`"
        >
          <SwText class="checkbox-text" variant="body-S">{{ file.path }}</SwText>
        </Checkbox>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.files-selector {
  .all-checkbox {
    margin-bottom: var(--space-sm);
  }

  .files-selection-container {
    overflow-y: auto;
    min-height: 50px;
    max-height: 150px;

    .file-selection {
      margin: var(--space-sm) 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .checkbox-text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
</style>
