import pLimit from 'p-limit';

export function filter<T, U extends T>(iterable: Iterable<T>, filter: (val: T, index: number) => val is U): Iterable<U>;
export function filter<T>(iterable: Iterable<T>, filter: (val: T, index: number) => boolean): Iterable<T>;
export function* filter<T>(iterable: Iterable<T>, filter: (val: T, index: number) => boolean): Iterable<T> {
  let i = 0;
  for (const val of iterable) {
    if (filter(val, i++)) {
      yield val;
    }
  }
}

export function* map<T, U>(iterable: Iterable<T>, callback: (val: T, index: number) => U): Generator<U> {
  let i = 0;
  for (const val of iterable) {
    yield callback(val, i++);
  }
}

// Map an iterable with an async mapping function, mapping all the elements in parallel for efficiency.
export async function* mapInParallel<T, U>(
  iterable: Iterable<T>,
  callback: (val: T, index: number) => Promise<U>,
  concurrency?: number
): AsyncIterable<U> {
  let promises: Promise<U>[];
  if (concurrency === undefined) {
    promises = [...map(iterable, (val, i) => callback(val, i))];
  } else {
    const limit = pLimit(concurrency);
    promises = [...map(iterable, (val, i) => limit(callback, val, i))];
  }

  for (const promise of promises) {
    yield await promise;
  }
}

export function* flatMap<T, U>(iterable: Iterable<T>, callback: (val: T, index: number) => Iterable<U>): Generator<U> {
  let i = 0;
  for (const val of iterable) {
    for (const subVal of callback(val, i++)) {
      yield subVal;
    }
  }
}

export function reduce<T, U>(iterable: Iterable<T>, initial: U, func: (accumulator: U, val: T, index: number) => U): U {
  let i = 0;
  let accumulator = initial;
  for (const val of iterable) {
    accumulator = func(accumulator, val, i++);
  }
  return accumulator;
}

export function groupBy<T, U>(values: Iterable<T>, keySelector: (t: T) => U): Map<U, T[]> {
  return groupByMultiple(values, (t) => [keySelector(t)]);
}

export function groupByAndTransform<T, T2, U>(
  values: Iterable<T>,
  keySelector: (t: T) => U,
  transformer: (t: T) => T2
): Map<U, T2[]> {
  return groupByMultipleAndTransform(values, (t) => [keySelector(t)], transformer);
}

export function mapBy<T, U>(values: Iterable<T>, keySelector: (t: T) => U): Map<U, T> {
  return reduce(values, new Map<U, T>(), (map, value) => map.set(keySelector(value), value));
}

export function mapByMultiple<T, U>(values: Iterable<T>, keySelector: (t: T) => Iterable<U>): Map<U, T> {
  return reduce(values, new Map<U, T>(), (map, value) => {
    for (const key of keySelector(value)) {
      map.set(key, value);
    }
    return map;
  });
}

export function groupByMultiple<T, U>(values: Iterable<T>, multiKeySelector: (t: T) => Iterable<U>): Map<U, T[]> {
  return groupByMultipleAndTransform(values, multiKeySelector, (t) => t);
}

export function groupByMultipleAndTransform<T, T2, U>(
  values: Iterable<T>,
  multiKeySelector: (t: T) => Iterable<U>,
  transformer: (t: T) => T2
): Map<U, T2[]> {
  const groups: Map<U, T2[]> = new Map();
  for (const value of values) {
    for (const key of multiKeySelector(value)) {
      let listForKey = groups.get(key);
      if (listForKey === undefined) {
        groups.set(key, (listForKey = []));
      }
      listForKey.push(transformer(value));
    }
  }
  return groups;
}

export function countBy<T, K>(values: Iterable<T>, keySelector: (t: T) => K): Map<K, number> {
  const counters = new Map<K, number>();
  for (const value of values) {
    const key = keySelector(value);
    let count = counters.get(key);
    if (count === undefined) {
      count = 0;
    }
    counters.set(key, count + 1);
  }
  return counters;
}

export function* chain<T>(...iters: Iterable<T>[]): Generator<T> {
  for (const iter of iters) {
    for (const value of iter) {
      yield value;
    }
  }
}

export function count(iter: Iterable<unknown>): number {
  return reduce(iter, 0, (acc) => acc + 1);
}

export function some<T>(iter: Iterable<T>, predicate: (t: T) => boolean): boolean {
  for (const value of iter) {
    if (predicate(value)) {
      return true;
    }
  }
  return false;
}

export function every<T>(iterable: Iterable<T>, predicate: (value: T) => boolean): boolean {
  for (const value of iterable) {
    if (!predicate(value)) {
      return false;
    }
  }
  return true;
}

export function find<T>(iter: Iterable<T>, predicate: (t: T) => boolean): T | undefined {
  for (const value of iter) {
    if (predicate(value)) {
      return value;
    }
  }
  return undefined;
}

export function* groupConsecutive<T>(iter: Iterable<T>, sameGroup: (t1: T, t2: T) => boolean): Iterable<T[]> {
  let prevValue: T | undefined = undefined;
  let group: T[] | undefined = undefined;
  for (const value of iter) {
    if (prevValue === undefined || !sameGroup(prevValue, value)) {
      if (group !== undefined) {
        yield group;
      }
      group = [value];
    } else {
      group.push(value);
    }
    prevValue = value;
  }
  if (group !== undefined) {
    yield group;
  }
}

export function* findConsecutive<T>(
  iter: Iterable<T>,
  predicate: (t: T) => boolean
): Iterable<{ items: T[]; startIndex: number }> {
  let index = 0;
  for (const group of groupConsecutive(iter, (t1, t2) => predicate(t1) === predicate(t2))) {
    if (predicate(group[0])) {
      yield { items: group, startIndex: index };
    }
    index += group.length;
  }
}

export function* enumerate<T>(iterable: Iterable<T>): Iterable<[T, number]> {
  let index = 0;
  for (const item of iterable) {
    yield [item, index++];
  }
}

export function join<T>(iterable: Iterable<T>, separator: string): string {
  let result = '';
  for (const item of iterable) {
    if (result.length > 0) {
      result += separator;
    }
    result += item;
  }
  return result;
}
export function* interleave<T>(iterables: IterableIterator<Iterable<T>>): Iterable<T> {
  let iteratorArray = [...map(iterables, (i) => i[Symbol.iterator]())];
  while (iteratorArray.length) {
    const nextIteratorArray: typeof iteratorArray = [];
    for (const it of iteratorArray) {
      const result = it.next();
      if (result.done) {
        continue;
      }
      yield result.value;
      nextIteratorArray.push(it);
    }
    iteratorArray = nextIteratorArray;
  }
}

export function* take<T>(iterable: Iterable<T>, count: number): Iterable<T> {
  for (const item of iterable) {
    if (count-- <= 0) {
      return;
    }
    yield item;
  }
}

export function* drop<T>(iterable: Iterable<T>, count: number): Iterable<T> {
  for (const item of iterable) {
    if (count-- > 0) {
      continue;
    }
    yield item;
  }
}

// Return an array that contains the elements of iterable such that:
//  - any elements that is contained (according to the contains function) in another will have been combined into it
//    using the combineContained function.
//  - the combined elements will be left at the index of the earliest combined sub-element.
export function deduplicate<T>(
  iterable: Iterable<T>,
  contains: (t1: T, t2: T) => boolean,
  combineContained: (container: T, contained: T) => T = (container, _contained) => container
): T[] {
  let deduplicated: T[] = [];
  for (const item of iterable) {
    let result: { status: 'contains' | 'contained'; index: number } | undefined = undefined;
    for (let i = 0; i < deduplicated.length; i++) {
      if (contains(deduplicated[i], item)) {
        result = { status: 'contained', index: i };
        break;
      }
      if (contains(item, deduplicated[i])) {
        result = { status: 'contains', index: i };
        break;
      }
    }
    // The case is this:
    //            v
    // [a/b, b/c, d/e]
    // Item does not contain any of the elements in the deduplicated array, and none of them contain it - just add it to
    // the end.
    if (!result) {
      deduplicated.push(item);
      continue;
    }
    const newArray = deduplicated.slice(0, result.index);
    const combinedItem =
      result.status === 'contains'
        ? combineContained(item, deduplicated[result.index])
        : combineContained(deduplicated[result.index], item);
    newArray.push(combinedItem);
    if (result.status === 'contained') {
      // The case is this:
      // [apps/web, apps/cli, apps/web/src]
      //  1. [] < apps/web
      //  2. [apps/web] < apps/cli
      // >3. [apps/web, apps/cli] < apps/web/src
      //  4. [apps/web, apps/cli]
      // We just got a new element that is contained in an existing element. It can't contain any other elements because
      // those are guaranteed not to be contained in the existing element, so they can't be contained in a subelement of
      // it.
      newArray.push(...deduplicated.slice(result.index + 1));
    } else {
      // The case is this:
      // [packages, apps/web, apps/cli, apps]
      //  1. [] < packages
      //  2. [packages] < apps/web
      //  3. [packages, apps/web] < apps/cli
      // >4. [packages, apps/web, apps/cli] < apps
      //  3. [packages, apps]
      // We just got a new element that contains at least one element. We need to check the elements that follow it if it
      // contains them as well (it cannot contain elements that precede it because we already checked them).
      for (let i = result.index + 1; i < deduplicated.length; i++) {
        if (!contains(newArray[result.index], deduplicated[i])) {
          newArray.push(deduplicated[i]);
          continue;
        }
        newArray[result.index] = combineContained(newArray[result.index], deduplicated[i]);
      }
    }
    deduplicated = newArray;
  }
  return deduplicated;
}

// return the first item for each duplicate
export function getDuplicates<T, K>(iterable: Iterable<T>, keyFunc: (t1: T) => K = (t1) => t1 as unknown as K): T[] {
  const groups = groupBy(iterable, keyFunc);
  return [...groups.values()].filter((values) => values.length > 1).map((values) => values[0]);
}
