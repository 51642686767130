<script setup lang="ts">
import HorizontalSpacer from '@/common/components/atoms/HorizontalSpacer.vue';
import { SwText } from '@swimm/ui';
import AccountReadyFeatures from '@/modules/onboarding/components/account-ready/AccountReadyFeatures.vue';
import { useRoute, useRouter } from 'vue-router';
import AccountReadyDownloadPluginsContent from '@/modules/onboarding/components/account-ready/AccountReadyDownloadPluginsContent.vue';
import { ref } from 'vue';

const router = useRouter();
const route = useRoute();

const showPluginsCard = ref(false);

function navigateToWorkspace() {
  router.push(`/workspaces/${route.params.workspaceId}`);
}
</script>

<template>
  <AccountReadyDownloadPluginsContent v-if="showPluginsCard" @back="showPluginsCard = false" />
  <template v-else>
    <div>
      <SwText variant="headline2">You're ready to join your team</SwText>
      <SwText variant="body-L" class="subtitle">Enjoy collaboration with others</SwText>
    </div>
    <AccountReadyFeatures :features="['Read team docs', 'Contribute to repo docs', 'Invite other member']" />
    <Action @click="navigateToWorkspace">Jump in</Action>
    <HorizontalSpacer class="spacer" />
    <div class="footer">
      <SwText variant="body-S">Finding hassle leaving your IDE to read docs?</SwText>
      <SwText variant="body-S" class="link" @click="showPluginsCard = true">Download Swimm plugins</SwText>
    </div>
  </template>
</template>

<style scoped lang="postcss">
.subtitle {
  color: var(--text-color-secondary);
}

.spacer {
  align-self: stretch;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: var(--space-base);

  .link {
    color: var(--text-color-link);
    cursor: pointer;
    align-self: center;
  }
}
</style>
