export function useScroll() {
  function scrollToElement(
    elementId: string,
    opts: ScrollIntoViewOptions = { behavior: 'smooth', block: 'start', inline: 'nearest' }
  ): void {
    const element: HTMLElement | null = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView(opts);
    }
  }

  function scrollToElementTop(elementId: string): void {
    const element: HTMLElement | null = document.getElementById(elementId);
    if (element) {
      element.scrollTop = 0;
    }
  }

  function getScrollParent(element: Element): Element | null {
    if (element == null) {
      return null;
    }
    if (element.scrollHeight > element.clientHeight || !element.parentElement) {
      return element;
    }
    return getScrollParent(element.parentElement);
  }

  function isScrolledIntoView(el: Element) {
    const { top: elemTop, bottom: elemBottom } = el.getBoundingClientRect();

    const scrollParent = getScrollParent(el);
    if (scrollParent === null) {
      return false;
    }
    const { top: parentTop, bottom: parentBottom } = scrollParent.getBoundingClientRect();

    // Only completely visible elements return true:
    return elemTop >= parentTop && elemBottom <= parentBottom;
  }

  return {
    scrollToElement,
    scrollToElementTop,
    isScrolledIntoView,
  };
}
