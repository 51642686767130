import { MarkdownUtils } from './markdownUtils';
import type { Schema } from '@tiptap/pm/model';
import { DOMSerializer, Node as ProseMirrorNode, DOMParser as ProseMirrorParser } from '@tiptap/pm/model';

export function parseHTMLThroughProsemirrorConventions(html: string, schema: Schema) {
  // This is mainly used for copy pasting and sanitizing the html
  const dom = new DOMParser().parseFromString(html, 'text/html').body;
  Object.values(dom.childNodes).forEach((node) => {
    if (node.nodeName === 'PRE') {
      const newNode = `<span>${MarkdownUtils.marked(node.textContent ?? '', undefined)}</span>`;
      const parsedNewNode = new DOMParser().parseFromString(newNode, 'text/html');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      node.replaceWith(parsedNewNode.body.firstChild);
    }
  });
  // Creating JSON structure that will fit ProseMirrorNode structure
  const parsedJson = ProseMirrorParser.fromSchema(schema)
    .parse(dom, {
      preserveWhitespace: true,
    })
    .toJSON();
  // Converting the JSON to a ProseMirrorNode
  const newProsemirrorNode = ProseMirrorNode.fromJSON(schema, parsedJson);
  let htmlAsString = '';
  newProsemirrorNode.content.forEach((node) => {
    /* In our ProseMirrorNode theres a fragment which contains all the inside elements. we iterate over them and turn them
      into an html string for our editor.vue to render */
    const serializedDomAsHTMLElement = DOMSerializer.fromSchema(newProsemirrorNode.type.schema).serializeNode(
      node
    ) as HTMLElement;
    htmlAsString = htmlAsString + `${serializedDomAsHTMLElement.outerHTML} `;
  });
  return htmlAsString;
}

export function parseDataIntoHTMLDOM(schema: Schema, content: Node) {
  const contentNode = ProseMirrorNode.fromJSON(schema, content);
  const htmlNode = DOMSerializer.fromSchema(schema).serializeNode(contentNode);
  htmlNode as HTMLElement;
  return htmlNode as HTMLElement;
}
