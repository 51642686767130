<template>
  <div class="container">
    <span
      v-for="step in total"
      :key="step"
      :class="['dot', step - 1 === current ? 'current' : '']"
      @click="onClick(step - 1)"
    />
  </div>
</template>

<script>
export default {
  props: {
    total: { type: Number, required: true },
    current: { type: Number, required: true },
  },
  emits: ['click'],
  methods: {
    onClick(step) {
      this.$emit('click', step);
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
}

.dot {
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--color-border-default);
  cursor: pointer;
}

.current {
  background: var(--color-border-brand);
}
</style>
