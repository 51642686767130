<script setup lang="ts">
import { Checkbox, SwText, SwToggle } from '@swimm/ui';
import { computed, nextTick, ref } from 'vue';
import { useRepoIntegrations } from '@/modules/repo/composables/repo-integrations';
import { exceptionAlert } from '@/common/utils/alert';
import { useStore } from 'vuex';
import { useStigg } from '@/common/composables/useStigg';
import PaywallModal, { PaywallEntity } from '@/common/components/modals/PaywallModal.vue';
import EnableInWorkspaceSettingsBanner from '@/modules/repo/settings/EnableInWorkspaceSettingsBanner.vue';
import { StiggFeatures, eventLogger, productEvents } from '@swimm/shared';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { SWIMM_DOC_SITE_GENERATIVE_AI } from '@/config';
import { useAuthStore } from '@/modules/core/stores/auth-store';

const props = defineProps({
  repoId: { type: String, required: true },
  workspaceId: { type: String, required: true },
});
const emit = defineEmits(['close', 'change-tab', 'open-workspace-settings']);

const store = useStore();
const { stiggClient } = useStigg();
const analytics = useAnalytics();
const auth = useAuthStore();

const repoMetadata = computed(() => store.getters['database/db_getRepoMetadata'](props.repoId));

const toggledOn = ref(false);

const showWorkspaceSettingsBanner = computed(() => {
  return toggledOn.value;
});
const { integrations, updateIntegrations } = useRepoIntegrations(props.repoId);

const FIELD_AI = 'generative_ai_enabled';
const FIELD_LOGS = 'generative_ai_share_logs_enabled';

const isGenerativeAIEnabled = ref((integrations.value?.[FIELD_AI] as boolean) ?? false);
const shareAiLogsWithSwimm = ref((integrations.value?.[FIELD_LOGS] as boolean) ?? false);
const showPaywall = ref(false);
const loading = ref(false);

const isGenAiDisabledInWorkspace = computed(() => {
  const workspaceData = store.getters['database/db_getWorkspace'](props.workspaceId);
  return workspaceData?.settings?.disable_gen_ai ?? false;
});

function canEnableGenerativeAI() {
  return stiggClient && stiggClient.getBooleanEntitlement
    ? stiggClient.getBooleanEntitlement({ featureId: StiggFeatures.GENERATIVE_AI }).hasAccess
    : false;
}

async function updateToggles({
  enableAi,
  enableLogs,
  startedTrial = false,
  isDefaultState = false,
}: {
  enableAi?: boolean;
  enableLogs?: boolean;
  // Whether the logs parameter is set here because of its default value (false if the user triggered the change).
  isDefaultState?: boolean;
  startedTrial?: boolean;
}) {
  loading.value = true;
  try {
    const dbFields = {};
    if (enableAi != null) {
      isGenerativeAIEnabled.value = enableAi;
      dbFields[FIELD_AI] = enableAi;
      reportEvent(productEvents.TOGGLE_REPO_GENERATIVE_AI, {
        Enabled: enableAi,
        backofficeCode: eventLogger.SWIMM_EVENTS.TOGGLE_REPO_GENERATIVE_AI.code,
        'Started Trial': startedTrial,
      });
    }
    if (enableLogs != null) {
      shareAiLogsWithSwimm.value = enableLogs;
      dbFields[FIELD_LOGS] = enableLogs;
      reportEvent(productEvents.TOGGLE_REPO_GENERATIVE_AI_ANALYTICS, {
        Status: enableLogs ? 'On' : 'Off',
        'Is Default State': isDefaultState,
        'Started Trial': startedTrial,
      });
    }
    await updateIntegrations(dbFields);
  } catch (error) {
    await exceptionAlert({ title: 'Failed to update repo settings', error });
    if (enableAi != null) {
      isGenerativeAIEnabled.value = !enableAi;
    }
    if (enableLogs != null) {
      shareAiLogsWithSwimm.value = !enableLogs;
    }
  } finally {
    loading.value = false;
  }
}

async function dontChangeToggle(oldValue, newValue) {
  isGenerativeAIEnabled.value = newValue;
  await nextTick(() => {
    isGenerativeAIEnabled.value = oldValue;
  });
}

async function toggleGenerativeAIOption(enableAi: boolean) {
  // check if user can turn it on
  if (enableAi && !canEnableGenerativeAI()) {
    showPaywall.value = true;
    dontChangeToggle(!enableAi, enableAi);
    return;
  }
  toggledOn.value = enableAi;
  // update setting in DB
  // when we change the enableAi, we always set the logs to false
  // then you need to enable it explicitly
  await updateToggles({ enableAi, enableLogs: false, isDefaultState: true });
}

async function toggleShareAiLogsWithSwimm(enableLogs: boolean) {
  await updateToggles({ enableLogs, isDefaultState: false });
}

function reportEvent(event: string, payload: object) {
  analytics.cloudTrack({
    identity: auth.user.uid,
    event,
    payload: {
      'Workspace ID': props.workspaceId,
      'Repo ID': props.repoId,
      'Repo Name': repoMetadata.value.name,
      ...payload,
    },
  });
}

function closePaywallModal({ isRedirecting, isTrialStarted }: { isRedirecting: boolean; isTrialStarted: boolean }) {
  showPaywall.value = false;
  if (isRedirecting) {
    emit('close');
  }
  if (isTrialStarted) {
    activateGenerativeAiAfterTrial();
  }
}

async function activateGenerativeAiAfterTrial() {
  await updateToggles({ enableAi: true, enableLogs: false, startedTrial: true, isDefaultState: true });
}

function getImageUrl(image) {
  return new URL(`../assets/${image}`, import.meta.url).href;
}
</script>

<template>
  <div>
    <div class="feature" :class="{ disabled: loading }">
      <div class="toggle-container">
        <VTooltip :disabled="!isGenAiDisabledInWorkspace">
          <SwToggle
            class="toggle-button"
            :disabled="loading || isGenAiDisabledInWorkspace"
            :value="isGenerativeAIEnabled"
            size="small"
            @change="toggleGenerativeAIOption"
          />
          <template #popper>
            Generative AI has been disabled for your workspace for security reasons. Please contact Swimm to enable it
            if necessary.
          </template>
        </VTooltip>
        <SwText variant="subtitle-XL"><strong>Generative AI</strong></SwText>
      </div>
      <EnableInWorkspaceSettingsBanner
        v-if="showWorkspaceSettingsBanner"
        :disabled="loading"
        @open-workspace-settings="$emit('open-workspace-settings')"
      />
      <img class="image" :src="getImageUrl('generative-ai-settings.svg')" />
      <div class="description">
        <SwText variant="body-L">
          If enabled, users will be able to use AI to generate contents when writing docs as well as create rules for
          their doc appearances within the IDE. Code snippets and documents
          <b>might be sent to Azure Open AI</b> when these features are used.
          <a :href="SWIMM_DOC_SITE_GENERATIVE_AI" target="_blank"><u>Learn more</u></a>
        </SwText>
      </div>
      <Checkbox
        :model-value="shareAiLogsWithSwimm"
        :disabled="loading || !isGenerativeAIEnabled || isGenAiDisabledInWorkspace"
        class="analytics-checkbox"
        @update:model-value="toggleShareAiLogsWithSwimm"
        align="flex-start"
      >
        <SwText variant="body-L" class="analytics-description">
          If checked, AI generated results will be temporarily saved for analytics purposes. These results, sent
          anonymously, may contain occasional code references, all with the purpose of improving quality. Rest assured,
          your data is highly safeguarded. Data is retained for 30 days, after which it's automatically deleted. By
          sharing this with Swimm, you're helping us make the product even better for you and all our users.
        </SwText>
      </Checkbox>
    </div>
    <PaywallModal :show="showPaywall" :entity="PaywallEntity.GENERATIVE_AI" @close="closePaywallModal" />
  </div>
</template>

<style scoped lang="postcss">
.description {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  color: var(--text-color-secondary);
}

.analytics-description {
  color: var(--text-color-secondary);
}

.toggle-container {
  display: flex;
  gap: var(--space-base);
  align-items: center;
}

.disabled {
  opacity: 0.4;
}

.image {
  align-self: stretch;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--space-md);
}
</style>
