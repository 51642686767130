<script setup>
import UserDialog from '@/common/components/modals/UserDialog.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { UserRole } from '@/common/consts';
import { Licenses, pageEvents } from '@swimm/shared';
import { Action, Icon, IconButton, Loader, SwText } from '@swimm/ui';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useBillingStore } from '@/modules/billing/store/billing';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import EllipsisOption from '@/common/components/atoms/EllipsisOption.vue';
import { BaseAvatar } from '@swimm/reefui';

const props = defineProps({
  loading: { type: Boolean, default: false },
  uid: { type: String, default: '' },
  displayName: { type: String, default: '' },
  creatorProfileUrl: { type: String, default: '' },
  email: { type: String, default: '' },
  role: { type: String, default: UserRole.MEMBER },
  invited: { type: Boolean, default: false },
  requestInvite: { type: Boolean, default: false },
  deletedUser: { type: Boolean, default: false },
  expired: { type: Boolean, default: false },
});
const emit = defineEmits(['accept', 'deny', 'role-change', 'remove', 'resend-invite']);

const store = useStore();
const route = useRoute();
const analytics = useAnalytics();
const { isOnTrialPlan, setShowBillingPlans } = useBillingStore();

const confirmationDialog = ref(null);
const confirmationDialogBody = ref('');
const confirmationDialogConfirmText = ref('Remove');
const confirmationDialogDangerButton = ref(false);
const confirmationDialogTitle = computed(() => {
  if (props.displayName) {
    return `Remove ${props.displayName} (${props.email})?`;
  }
  return `Remove ${props.email}?`;
});

const { user: currentUser } = storeToRefs(useAuthStore());
const isCurrentUserWorkspaceAdmin = computed(() =>
  store.getters['database/db_isWorkspaceAdmin'](route.params.workspaceId, currentUser.value.uid)
);

const rolesOptions = computed(() => Object.values(UserRole));

const roleSelectDisabled = computed(() => {
  const isCurrentUser = currentUser.value.uid === props.uid;
  return isCurrentUser || !isCurrentUserWorkspaceAdmin.value;
});

const showRemoveButton = computed(() => {
  const isCurrentUser = currentUser.value.uid === props.uid;
  return (
    !isCurrentUser &&
    isCurrentUserWorkspaceAdmin.value &&
    !props.requestInvite &&
    !props.deletedUser &&
    !props.loading &&
    !props.expired
  );
});

const removeButtonText = computed(() => (props.invited ? 'Remove invite' : 'Remove user'));

const isFreeWorkspace = computed(
  () => store.getters['database/db_getWorkspaceLicense'](route.params.workspaceId) === Licenses.FREE
);

async function removeUser() {
  if (props.invited) {
    if (isFreeWorkspace.value) {
      confirmationDialogBody.value = 'Are you sure you want to remove this invite?';
    } else {
      confirmationDialogBody.value = 'They will not be able to join your workspace.';
      confirmationDialogDangerButton.value = true;
      confirmationDialogConfirmText.value = 'Remove user';
      if (!isOnTrialPlan) {
        confirmationDialogBody.value =
          confirmationDialogBody.value +
          ' Note that their seat is non-refundable - you will receive an empty seat until the next billing cycle.';
      }
    }
    analytics.pageVisit(pageEvents.REMOVE_INVITE, {
      'Invite Email': props.displayName,
      'Workspace ID': route.params.workspaceId,
      Type: 'Popup',
    });
  } else {
    confirmationDialogDangerButton.value = true;
    confirmationDialogConfirmText.value = 'Remove user';
    confirmationDialogBody.value = 'They will lose all access to your workspace.';
    if (isFreeWorkspace.value) {
      confirmationDialogBody.value =
        confirmationDialogBody.value +
        ' Note that if you remove users from Swimm Free plan, you cannot replace them with new users.';
    } else if (!isOnTrialPlan) {
      confirmationDialogBody.value =
        confirmationDialogBody.value +
        ' Note that their seat is non-refundable - you will receive an empty seat until the next billing cycle.';
    }
    analytics.pageVisit(pageEvents.REMOVE_USER, {
      'Full Name': props.displayName,
      'Workspace ID': route.params.workspaceId,
      'User ID': props.uid,
      Type: 'Popup',
    });
  }

  const userSelection = await confirmationDialog.value.showDialog();
  if (userSelection.confirm) {
    emit('remove');
  }
}
</script>

<template>
  <tr :class="{ inactive: loading, disabled: deletedUser, 'request-invite': requestInvite }">
    <td>
      <div class="user-column">
        <BaseAvatar
          :name="displayName || email"
          :alt="displayName || email"
          :url="creatorProfileUrl !== '' ? creatorProfileUrl : null"
        />
        <div class="info">
          <SwText component="span" variant="body-S" class="ellipsis">{{ displayName || email }}</SwText>
          <SwText v-if="requestInvite" component="span" variant="body-S" weight="light" class="request"
            >requests to join</SwText
          >
          <SwText v-if="displayName" variant="body-XS" weight="light" class="email ellipsis">{{ email }}</SwText>
        </div>
      </div>
    </td>
    <td>
      <div class="access-column">
        <div v-if="expired" class="invite-tag">
          <VMenu :disabled="!expired" placement="right">
            <SwText variant="body-S">Expired</SwText>
            <template #popper>
              <div class="paywall-popup" v-if="expired">
                <div class="subtitle-L bold">Upgrade to re-enable this user</div>
                <div class="body-XS">
                  Your free trial has expired, upgrade your subscription now to re-enable this user.
                </div>
                <a class="popover-button">
                  <Action size="small" class="paywall-popup-action" @click="setShowBillingPlans(true)">Upgrade</Action>
                </a>
              </div>
            </template>
          </VMenu>
        </div>
        <div v-else-if="invited">
          <Action
            v-if="isCurrentUserWorkspaceAdmin"
            size="small"
            secondary
            :disabled="loading"
            @click="$emit('resend-invite')"
            >Resend invite</Action
          >
          <div v-else class="invite-tag">
            <SwText variant="body-S">Pending</SwText>
          </div>
        </div>
        <template v-else-if="requestInvite">
          <div v-if="!isCurrentUserWorkspaceAdmin" class="invite-tag">
            <SwText variant="body-S">Requested invite</SwText>
          </div>
        </template>
        <template v-else-if="deletedUser">
          <div class="invite-tag">
            <SwText variant="body-S">Removed access</SwText>
          </div>
        </template>
        <template v-else>
          <v-select
            :model-value="role"
            :class="['roles-select', { disabled: roleSelectDisabled }]"
            :options="rolesOptions"
            :disabled="loading || roleSelectDisabled"
            :clearable="false"
            :searchable="false"
            @option:selected="$emit('role-change')"
          >
            <template #open-indicator>
              <Icon no-padding name="arrow-down" class="arrow-icon" />
            </template>
          </v-select>
        </template>
      </div>
    </td>
    <td>
      <div class="actions-column">
        <VDropdown theme="menu-no-arrow" placement="bottom-start" :triggers="['click']" v-if="showRemoveButton">
          <IconButton class="icon" name="more" no-padding :class="['remove', { disabled: loading }]" />
          <template #popper>
            <EllipsisOption
              :name="removeButtonText"
              :handler="
                () => {
                  removeUser();
                }
              "
              v-close-popper
            />
          </template>
        </VDropdown>
        <div v-if="requestInvite && isCurrentUserWorkspaceAdmin" class="request-action-section">
          <SwText variant="body-S" class="approve-request" @click="$emit('accept')">Approve</SwText>
          <SwText variant="body-S" class="deny-request" @click="$emit('deny')">Deny</SwText>
        </div>
        <Loader v-if="loading" secondary class="loader" />
      </div>
    </td>
  </tr>
  <UserDialog
    ref="confirmationDialog"
    :title="confirmationDialogTitle"
    :body="confirmationDialogBody"
    :danger="confirmationDialogDangerButton"
    :confirm-text="confirmationDialogConfirmText"
  />
</template>

<style scoped lang="postcss">
tr {
  &.inactive {
    opacity: 0.3;
    pointer-events: none;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.request-invite + :not(.request-invite) {
    border-top: 1px solid var(--color-border-default-subtle);
  }

  td {
    padding: var(--space-base);
    vertical-align: middle;

    .user-column {
      display: flex;
      align-items: center;
      gap: var(--space-base);

      .info {
        max-width: 80%;

        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .request {
          margin-left: var(--space-xs);
          word-break: keep-all;
        }

        .email {
          color: var(--text-color-secondary);
        }
      }
    }

    .access-column {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--space-base);

      .invite-tag {
        padding: var(--space-base);
        background: var(--color-surface);
        border-radius: 4px;
        color: var(--text-color-secondary);
      }

      .action:hover {
        background: var(--color-hover);
      }
    }

    .actions-column {
      display: flex;
      align-items: center;

      .loader.secondary {
        flex: 1;
        min-width: 0;
        --loader-size: 24px;
      }

      .remove {
        flex: 1;
        display: flex;
        justify-content: center;

        .icon {
          padding: var(--space-base);
        }
      }

      .request-action-section {
        display: flex;
        gap: var(--space-sm);
        cursor: pointer;

        .approve-request {
          color: var(--text-color-link);
        }

        .deny-request {
          color: var(--text-color-link);
        }

        & :hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.roles-select {
  width: 100px;
  font-size: var(--body-S);

  &.disabled {
    .arrow-icon {
      display: none;
    }

    :deep(.vs__selected),
    :deep(.vs__selected-options),
    :deep(.vs__search),
    :deep(.vs__actions),
    :deep(.vs__dropdown-toggle),
    :deep(.vs__clear-options) {
      cursor: default;
    }
  }

  :deep(.vs__search) {
    padding: 0;
  }

  :deep(.vs__dropdown-toggle) {
    cursor: pointer;
    border: 0;
  }

  :deep(&.v-select.v-select:focus-within .vs__dropdown-toggle) {
    border-color: var(--color-border-default);
  }

  :deep(.vs__dropdown-option--selected) {
    background-color: var(--color-selected);
  }

  :deep(.vs__dropdown-menu) {
    border: 2px solid var(--color-border-default-subtle);
  }
}

.paywall-popup {
  display: flex;
  padding: var(--space-base);
  width: 250px;
  flex-direction: column;
}

.paywall-popup-action {
  margin-top: var(--space-base);
}
</style>
