<template>
  <div class="top-row">
    <div class="assign-section">
      <SwAvatar :text="assignedByLabel" data-testid="assign-assigner-icon" />
      <Icon name="arrow-right" />
      <SwAvatar :text="assigneeLabel" data-testid="assign-assignee-icon" />
    </div>
    <div class="assign-section">
      <Icon
        name="check"
        v-if="isComplete"
        v-tooltip="'This assignment was completed'"
        class="completed-icon clickable"
        data-testid="assign-completed"
      />
      <Icon
        name="checklist"
        v-else-if="shouldShowComplete"
        v-tooltip="'Mark this assignment as completed'"
        class="action-icon clickable"
        data-testid="assign-complete"
        @click="complete"
      />
      <Icon
        name="trash-outline"
        v-if="shouldShowRemove"
        v-tooltip="'Remove this assignment'"
        class="action-icon clickable"
        data-testid="assign-remove"
        @click="removeAssignment"
      />
    </div>
  </div>
  <div class="header" data-testid="assign-header">{{ assignment.type }}</div>
  <div class="description" data-testid="assign-description">{{ assignment.description }}</div>
</template>

<script>
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';
import { mapActions, mapGetters } from 'vuex';
import { deleteDocAssignment, markDocAssignmentAsComplete } from '../services/doc-assignments-utils';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { SwAvatar } from '@swimm/ui';

export default {
  components: {
    SwAvatar,
  },
  props: {
    assignment: { type: Object, required: true },
    repoId: { type: String, required: true },
    docId: { type: String, required: true },
    origin: { type: String, required: true },
  },
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    return { user, analytics };
  },
  data() {
    return {
      defaultBranch: '',
      completing: false,
    };
  },
  computed: {
    ...mapGetters('database', ['db_isWorkspaceAdmin']),
    isAssignee() {
      return this.assignment.assignee === this.user.uid || this.assignment.assignee_email === this.user.email;
    },
    isAssigner() {
      return this.assignment.assigned_by === this.user.uid || this.assignment.assigned_by_email === this.user.email;
    },
    isComplete() {
      return this.assignment.completed;
    },
    assignedByLabel() {
      return this.assignment.assigned_by_name || this.assignment.assigned_by_email;
    },
    assigneeLabel() {
      return this.assignment.assignee_name || this.assignment.assignee_email;
    },
    shouldShowRemove() {
      return this.isAssignee || this.isAssigner;
    },
    shouldShowComplete() {
      return this.isAssignee || this.isAssigner;
    },
  },
  methods: {
    ...mapActions('database', ['refreshAssignments']),
    async removeAssignment() {
      this.shouldShowModal = false; // Verify modal will be closed
      await deleteDocAssignment({ repoId: this.repoId, docId: this.docId, assignmentId: this.assignment.id });
      await this.refreshAssignments({ repoId: this.repoId, unitId: this.docId });
      this.analytics.track(productEvents.CLICKED_REMOVE_ASSIGNMENT, {
        Origin: this.origin,
        Context: 'Header',
        'Workspace ID': this.$route.params.workspaceId,
        'Repo ID': this.repoId,
        'Document ID': this.docId,
      });
    },
    async complete() {
      await markDocAssignmentAsComplete({ repoId: this.repoId, docId: this.docId, assignmentId: this.assignment.id });
      await this.refreshAssignments({ repoId: this.repoId, unitId: this.docId });
    },
  },
};
</script>

<style scoped>
.top-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.assign-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.assignee-name {
  margin-left: 4px;
  max-width: 128px;
  color: var(--text-color-secondary);
}

.dismiss-assignment {
  margin: 3px 0 0 16px;
  cursor: pointer;
  color: var(--text-color-link);
}

.header {
  font-size: var(--subtitle-L);
  font-weight: 800;
}

.description {
  padding: 4px 0 8px 0;
  font-size: var(--body-XS);
}

.action-section {
  display: flex;
}

.complete-button {
  flex-grow: 1;
}

.complete-button-completed {
  pointer-events: none;
}

.action-icon:hover {
  background-color: var(--color-hover);
  border-radius: 8px;
}

.completed-icon {
  color: var(--success-green);
}
</style>
