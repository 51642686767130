<script setup lang="ts">
import { Keyboard, isMac, keyboardShortcutUtils } from '@swimm/shared';
import { Action, Divider, SwText } from '@swimm/ui';
import { onMounted, onUnmounted } from 'vue';

const onOpen = (event: KeyboardEvent) => {
  if (event.key === 'd' && ((isMac() && event.metaKey) || (!isMac() && event.ctrlKey))) {
    event.preventDefault();
    emit('delete');
  }
};
const keys = keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.CTRL, 'D');

onMounted(async () => {
  document.addEventListener('keydown', onOpen);
});

onUnmounted(() => document.removeEventListener('keydown', onOpen));

const emit = defineEmits(['delete']);
</script>
<template>
  <div class="container">
    <div class="text-line">
      <SwText variant="subtitle-S" class="pre-hunk">Code is Unavailable</SwText>
    </div>
    <Divider />
    <div class="line-hunk">
      <SwText variant="body-XS">Token is no longer available.</SwText>
      <div class="action-container">
        <Action size="small" class="delete-button" @click="emit('delete')" type="a"
          ><SwText variant="body-XS">Delete {{ keys }}</SwText></Action
        >
      </div>
    </div>
  </div>
</template>
<style lang="postcss" scoped>
.container {
  padding: var(--space-base);
}

.text-line {
  padding: var(--space-base);

  .pre-hunk {
    padding: var(--space-base) var(--side-padding);
    font-weight: 800;
    color: var(--text-color-secondary);
    background-color: var(--color-bg);
    line-height: 150%;
  }
}

.line-hunk {
  padding: var(--space-sm) 0px var(--space-base) var(--space-base);
  font-size: var(--body-S);
  font-family: var(--fontfamily-secondary);
  font-weight: 600;
  color: var(--text-color-primary);
  display: flex;
  align-items: center;
  line-height: 150%;
}

.action-container {
  padding-left: var(--space-sm);
  display: flex;

  .delete-button {
    padding: var(--space-xs) var(--space-base) var(--space-xs) 0px;
    cursor: pointer;
    color: var(--text-color-secondary);
  }
}
</style>
