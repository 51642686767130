<script setup lang="ts">
import { BaseLabel, ErrorBox } from '@swimm/ui';

const props = defineProps<{
  options: string[];
  label: string;
  error?: string;
  selectedOption: string;
  disabled?: boolean;
}>();

const emit = defineEmits(['update-selection']);
function handleOptionSelection(size) {
  if (!props.disabled) {
    emit('update-selection', size);
  }
}
</script>

<template>
  <div class="section">
    <BaseLabel :html-for="label" required>{{ label }}</BaseLabel>
    <div class="options">
      <div
        class="option"
        :class="{ selected: selectedOption === option, disabled }"
        v-for="option in options"
        :key="option"
        :tabindex="0"
        @click="handleOptionSelection(option)"
        @keydown.enter.prevent="handleOptionSelection(option)"
      >
        {{ option }}
      </div>
    </div>
    <ErrorBox v-if="error" class="error-message">{{ error }}</ErrorBox>
  </div>
</template>

<style scoped lang="postcss">
.section {
  .label {
    text-align: left;
  }

  .options {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-base);
    width: 100%;
  }

  .option {
    cursor: pointer;
    padding: var(--space-base);
    border: 1px solid var(--color-border-default);
    border-radius: var(--space-xs);
    flex: 1;

    &.selected {
      border: 1px solid var(--color-border-brand);
    }

    &.disabled {
      cursor: not-allowed;
    }

    &:not(.disabled):hover {
      background: var(--color-surface);
    }
  }
}

@media screen and (max-width: 480px) {
  .section .option {
    font-size: var(--body-XS);
  }
}
</style>
