<template>
  <BaseSelect
    v-model="selection"
    class="users-dropdown-selector"
    taggable
    multiple
    :required="required"
    :label="label"
    :options-label-prop="'name'"
    :placeholder="placeholder"
    :options="workspaceUsers"
    :disabled="disabled"
    :selectable-validator="isValidEmail"
    @update:model-value="onInput"
  />
</template>

<script>
import { UrlUtils } from '@swimm/shared';
import { mapGetters } from 'vuex';
import { UNKNOWN_USER_NAME } from '@/common/consts';
import { BaseSelect } from '@swimm/ui';

export default {
  components: { BaseSelect },
  emits: ['input'],
  props: {
    placeholder: { type: String, default: 'Select users from your workspace or insert an email address' },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: '' },
    required: { type: Boolean, default: false },
  },
  data() {
    return {
      selection: [],
    };
  },
  computed: {
    ...mapGetters('database', ['db_getWorkspaceUsers']),
    workspaceUsers() {
      const users = Object.values(this.db_getWorkspaceUsers(this.workspaceId));
      return users
        .filter((user) => !!user.email)
        .map((user) => ({
          name: user.nickname || user.name || user.email || UNKNOWN_USER_NAME,
          email: user.email,
          id: user.uid,
          externalUid: user.external_uid,
        }));
    },
    workspaceId() {
      return this.$route.params.workspaceId;
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    isValidEmail(user) {
      return UrlUtils.isValidEmail(user.email ?? user.name);
    },
  },
};
</script>

<style scoped>
:global(.users-dropdown-selector .v-select .vs__selected) {
  margin: 5px 5px 0 0;
  color: var(--text-color-primary);
  background-color: var(--color-hover);
}
</style>
