<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  title: { type: String, required: true },
  image: { type: String, required: true },
  highlight: { type: Boolean, default: false },
});
defineEmits(['click', 'dismiss']);

const imageUrl = computed(() => {
  return new URL(`../assets/${props.image}`, import.meta.url).href;
});
const computedClasses = computed(() => {
  return { 'suggestion-card--highlight': props.highlight };
});
</script>

<template>
  <div class="suggestion-card" :class="computedClasses" @click="$emit('click')">
    <header class="suggestion-card__header">
      <img class="suggestion-card__image" :src="imageUrl" :alt="title" />
      <Icon name="close" class="suggestion-card__close" @click.prevent.stop="$emit('dismiss')" />
    </header>
    <div class="suggestion-card__body">
      <SwText variant="body-S" class="suggestion-card__text">{{ title }}</SwText>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.suggestion-card {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;
  width: 248px;
  transition: 0.2s opacity ease-out;
  position: relative;

  &__header {
    display: flex;
    position: relative;
    z-index: 2;
  }

  &__body {
    --border-size: 2px;
    --border-radius-size: 10px;
    border: var(--border-size) solid var(--color-border-default-subtle);
    border-radius: 0 0 var(--border-radius-size) var(--border-radius-size);
    box-sizing: border-box;
    padding: calc(var(--space-sm) * 2) var(--space-sm) var(--space-sm);
    position: relative;
    top: calc(var(--space-sm) * -1);
    width: 100%;
    z-index: 1;
  }

  &__close {
    display: none;
    position: absolute;
    top: var(--space-base);
    right: var(--space-base);
    border-radius: 50%;
    color: var(--text-color-invert);
    background-color: var(--color-invert);
    font-weight: 800;
  }

  &:hover {
    .suggestion-card__close {
      display: block;
    }

    :is(.suggestion-card__image, .suggestion-card__text) {
      opacity: 0.9;
    }
  }

  &__image {
    max-width: 100%;
  }

  &--highlight {
    .suggestion-card__body {
      border-color: transparent;
      z-index: 0;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
      }

      &::before {
        margin: calc(var(--border-size) * -1);
        background: var(--beach-time);
        border-radius: var(--border-radius-size);
      }

      &::after {
        background: var(--color-bg);
        border-radius: calc(var(--border-radius-size) - var(--border-size));
      }
    }
  }
}
</style>
