import type { AnalyticsTrackOptions, AnalyticsTrackProperties, SuggestedHeader } from '@swimm/editor';
import type { AiContentSuggestionsService } from '@swimm/editor';
import type { JSONContent } from '@tiptap/core';
import type { Editor } from '@tiptap/vue-3';
import { ref } from 'vue';

export class EmptyAiContentSuggestionsService implements AiContentSuggestionsService {
  readonly docTitle = ref(undefined);
  headers = ref([]);
  headersLoaded = ref(false);
  selectedHeader = ref(null);
  snippets = ref([]);
  isContentSuggestionShown = ref(false);
  contentSuggestionsGenerationFailed = ref(false);
  keepContentSuggestionsOpenOnNextUnload = ref(false);

  setContentSuggestionShown(_show: boolean, _byClick: boolean): void {
    // do nothing
  }

  clearContentSuggestions(): void {
    // do nothing
  }

  regenerateContentSuggestions(): void {
    // do nothing
  }

  updateContentSuggestionsSnippets(_newSnippets: { file: string; lines: string[] }[]): void {
    // do nothing
  }

  reportSuggestionsToggle(_opened: boolean, _clicked: boolean): void {
    // do nothing
  }

  selectHeader(_header: SuggestedHeader): void {
    // do nothing
  }

  dismissHeader(_header: SuggestedHeader): void {
    // do nothing
  }

  markHeaderAsAdded(_header: SuggestedHeader): void {
    // do nothing
  }

  async isAIGenerationEnabledForRepo(): Promise<boolean> {
    return false;
  }

  trackEvent(
    _eventName: string,
    _properties: AnalyticsTrackProperties,
    _options?: AnalyticsTrackOptions | undefined
  ): void {
    // do nothing
  }

  getGeneratedSuggestionContent(_header: string, _content: string, _editor: Editor): JSONContent {
    return {};
  }
}
