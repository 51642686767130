<script setup>
import { useAnalytics } from '@/common/composables/useAnalytics';
import UserSettingsIntegrations from '@/modules/user-settings/components/UserSettingsIntegrations.vue';
import UserSettingsPreferences from '@/modules/user-settings/components/UserSettingsPreferences.vue';
import UserSettingsNotifications from '@/modules/user-settings/components/UserSettingsNotifications.vue';
import UserSettingsProfile from '@/modules/user-settings/components/UserSettingsProfile.vue';
import { productEvents, userSettingsTabs } from '@swimm/shared';
import SettingsModalBase from '@/modules/core/components/settings-modal/SettingsModalBase.vue';

const USER_TABS = [
  {
    code: userSettingsTabs.PROFILE,
    title: 'Profile',
    event: productEvents.VIEW_PROFILE_TAB,
  },
  {
    code: userSettingsTabs.INTEGRATIONS,
    title: 'Integrations',
    event: productEvents.VIEW_INTEGRATIONS_TAB,
  },
  {
    code: userSettingsTabs.NOTIFICATIONS,
    title: 'Notifications',
    event: productEvents.VIEW_NOTIFICATIONS_TAB,
  },
  {
    code: userSettingsTabs.PREFERENCES,
    title: 'Preferences',
    event: productEvents.VIEW_PREFERENCES_TAB,
  },
];

const props = defineProps({
  show: { type: Boolean, required: true },
  origin: { type: String, default: '' },
  initialTabCode: { type: String, default: '' },
});
defineEmits(['close']);

const analytics = useAnalytics();

function reportTabSelected(tabSelectedCode) {
  const tab = USER_TABS.find((tab) => tab.code === tabSelectedCode);
  if (tab) {
    analytics.track(tab.event, {
      Origin: props.origin,
      Type: 'Popup',
    });
  }
}
</script>

<template>
  <SettingsModalBase
    :show="show"
    :tabs="USER_TABS"
    :initial-tab-code="initialTabCode"
    header="User settings"
    @tab-selected="reportTabSelected"
    @close="$emit('close')"
  >
    <template #content="{ selectedTabCode }">
      <UserSettingsProfile v-if="selectedTabCode === userSettingsTabs.PROFILE" />
      <UserSettingsIntegrations v-if="selectedTabCode === userSettingsTabs.INTEGRATIONS" :origin="origin" />
      <UserSettingsNotifications v-if="selectedTabCode === userSettingsTabs.NOTIFICATIONS" />
      <UserSettingsPreferences v-if="selectedTabCode === userSettingsTabs.PREFERENCES" />
    </template>
  </SettingsModalBase>
</template>
