import { SmartElementWithApplicability, SmartSymbol } from './autosync/autosync-types';

export const ADD_MARKER = '+';
export const DELETE_MARKER = '-';
export const CONTEXT_MARKER = ' ';
export const SELECT_MARKER = '*';

export const noEOFLine = `\\ No newline at end of file`;

export enum FileDiffType {
  // First, the five basic types we're supporting in our algorithms.
  Added = 'ADDED',
  Copied = 'COPIED',
  Deleted = 'DELETED',
  Modified = 'MODIFIED',
  Renamed = 'RENAMED',
  // Below this point are "odd" types we do not support. They are here for the sake of completeness.
  TypeChanged = 'TYPE CHANGED',
  Unmerged = 'UNMERGED',
  Unknown = 'UNKNOWN',
  BrokenPairing = 'BROKEN PAIRING',
  // This is not a Git type. In the case that something goes horribly wrong this is an internal type designed to signify that git made a goof and all hell breaks loose.
  Unsupported = 'UNSUPPORTED STATUS',
  // This is not a Git type - but may be helpful for us - meaning there is no diff between revisions
  Unchanged = 'UNCHANGED',
}

export interface DiffResult {
  fileName: string;
  oldFileName?: string;
  status: FileDiffType;
  additions: number;
  deletions: number;
}

export enum SwmSymbolType {
  PATH = 'path',
  GENERIC_TEXT = 'generic_text',
  LINK = 'link',
  MENTION = 'mention',
  TEXT_PLACEHOLDER = 'text_placeholder',
}

export enum HunkChangeLineType {
  Deleted = 'del',
  Added = 'add',
  Context = 'context', // Currently use only after parsing the basic git diff
  Update = 'update',
  NormalAsContext = 'normal', // Currently the name used for context lines by our git-diff-parser is "normal"
  EOF = 'eof', // This is a type used only while runtime parsing, it is later saved and exported as one of the standard line change types
}

export interface HunkHeaderInfo {
  aLine: number;
  aCount: number;
  bLine: number;
  bCount: number;
}

export enum ApplicabilityStatus {
  Outdated = 'outdated',
  Verified = 'verified',
  Autosyncable = 'autosyncable',
  Invalid = 'invalid',
  IncompatibleVersion = 'incompatible-version',
  Unavailable = 'not-found',
  DisconnectedFromRepo = 'disconnected-from-repo',
  // Used when the item was copied over from another doc and its applicability status is not yet known.
  Unknown = 'unknown',
}

export enum SwmCellType {
  Text = 'text',
  Snippet = 'snippet',
  SnippetPlaceholder = 'snippet-placeholder',
  Image = 'image',
  Table = 'table',
  Rule = 'rule',
  Video = 'video',
  Mermaid = 'mermaid',
}

export enum SwmSymbolLinkType {
  Doc = 'doc',
  Exercise = 'exercise',
  Playlist = 'playlist',
  Rule = 'rule',
}

export enum SwmResourceType {
  Doc = 'doc',
  Playlist = 'playlist',
  CloudDoc = 'cloud',
}

export enum SwmResourceURLPath {
  Docs = 'docs',
  Playlists = 'playlists',
}

export const pathDocHookSetting = ['added', 'modified', 'deleted'];
export type DocHookPathRuleStatus = (typeof pathDocHookSetting)[number];
export enum DocHookRuleType {
  Path = 'path',
  DocModified = 'doc-modified',
  Regex = 'regex',
  FileSystem = 'file-system',
}

export enum DocHookRuleTypeCode {
  FileIsModified = 'file-modified',
  FolderIsModified = 'folder-modified-added',
}

export enum DocHookActionType {
  GithubComment = 'ghcomment',
  GithubReview = 'ghreview',
  RequireNewDocComment = 'requireNewDocComment',
  RequireNewDocCheck = 'requireNewDocCheck',
  SlackNotification = 'slackNotification',
  IdeCodeLens = 'ideCodeLens',
  CopyToPath = 'copyToPath',
}

export enum DocHookChangesScope {
  fileChanged = 'fileChanged',
  lineChanged = 'lineChanged',
}

export enum PatternScope {
  code = 'code',
  comment = 'comment',
  all = 'all',
}

export interface SymbolPlugin {
  updateSymbols(): Promise<{
    isApplicable: boolean;
    symbolsWithApplicability: SmartElementWithApplicability<SmartSymbol>[];
  }>;
  verifySymbols(): Promise<boolean>;
}

/**
 * Generate a hunk header line by putting the relevant values inside a string template and return it.
 * @param {*} hunkHeaderInfo A header info object returned by `generateHeaderInfo`.
 */
export function generateHunkHeaderInfoLineFromHunkHeaderInfoObject(hunkHeaderInfo: HunkHeaderInfo): string {
  const aCountStr = hunkHeaderInfo.aCount !== undefined ? `,${hunkHeaderInfo.aCount}` : '';
  const bCountStr = hunkHeaderInfo.bCount !== undefined ? `,${hunkHeaderInfo.bCount}` : '';
  return `@@ ${DELETE_MARKER}${hunkHeaderInfo.aLine}${aCountStr} ${ADD_MARKER}${hunkHeaderInfo.bLine}${bCountStr} @@`;
}

/**
 * Generate the diff header of a hunk described by its hunk diff lines.
 * We generate the numbers in relation to the base: aLine, the starting line of fileA in the hunk.
 * @param {string[]} hunkDiffLines Lines contained in the diff of a hunk, WITHOUT the first line (hunk header).
 * @param {number} aLine The starting line of fileA.
 * @param {HunkHeaderInfo} previousHunkHeaderInfo A previous hunk header info to consider when calculating the current hunk's header.
 */
export function generateHeaderInfo(
  hunkDiffLines: string[],
  aLine: number,
  previousHunkHeaderInfo: HunkHeaderInfo = null
): HunkHeaderInfo {
  let beforeLines = 0;
  let afterLines = 0;
  for (const line of hunkDiffLines) {
    const lineType = getLineTypeByMarker(line);
    if (lineType === HunkChangeLineType.Deleted) {
      beforeLines++;
    } else if (lineType === HunkChangeLineType.Added) {
      afterLines++;
    } else {
      // It's a context line
      beforeLines++;
      afterLines++;
    }
  }

  let bLineOffset = 0;
  if (previousHunkHeaderInfo !== null) {
    // We need the previous hunk's aCount and bCount values for the next part, but we aren't guaranteed to have them (hence the need for a normalization).
    const normalizedPreviousACount = previousHunkHeaderInfo.aCount !== undefined ? previousHunkHeaderInfo.aCount : 1;
    const normalizedPreviousBCount = previousHunkHeaderInfo.bCount !== undefined ? previousHunkHeaderInfo.bCount : 1;
    // Consecutive hunks are affected by eachother's offsets (if one hunk removed X lines from the file, the one after that will start X lines before where it thinks it will).
    //  Here we're calculating that offset based on the previous hunk's header info (if provided).
    bLineOffset =
      previousHunkHeaderInfo.bLine -
      previousHunkHeaderInfo.aLine +
      (normalizedPreviousBCount - normalizedPreviousACount);
  }

  // NOTE: if the hunk is made out entirely from deletion lines (no context) then the base `bLine` should `aLine - 1`.
  return {
    aLine: aLine,
    aCount: beforeLines,
    bLine: (afterLines > 0 ? aLine : aLine - 1) + bLineOffset,
    bCount: afterLines,
  };
}

/**
 * Get a diff line's type.
 * @param {string} line A line in a hunk.
 * @returns {string} a constant describing a valid state a line can be at.
 */
export function getLineTypeByMarker(line: string): HunkChangeLineType {
  return {
    [CONTEXT_MARKER]: HunkChangeLineType.Context,
    [ADD_MARKER]: HunkChangeLineType.Added,
    [DELETE_MARKER]: HunkChangeLineType.Deleted,
  }[line[0]];
}
