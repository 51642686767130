<script lang="ts" setup>
import { Action, Icon, SwText } from '@swimm/ui';
import { computed } from 'vue';
import { InfoComponent } from '@swimm/editor';

const props = defineProps<{ nodesCount: number; isAutosyncable: boolean }>();
defineEmits<{
  (event: 'accept-all'): void;
}>();
const infoText = computed(() => {
  if (props.isAutosyncable) {
    return 'Code changed and we auto-synced it to the updated version';
  }
  return 'Code may no longer be relevant as it changed drastically';
});
const dataTestIdValue = computed(() => `${props.isAutosyncable ? 'autosyncable' : 'outdated'}-review-count`);
</script>
<template>
  <div class="wrapper">
    <div class="container">
      <Icon
        :name="isAutosyncable ? 'sync' : 'warning-fill'"
        class="headline-icon"
        :class="isAutosyncable ? 'autosyncable ' : 'outdated'"
      />
      <SwText variant="subtitle-L" class="headline nodes-count" :data-testid="dataTestIdValue">{{ nodesCount }}</SwText>
      <SwText variant="subtitle-L" class="headline">
        {{ isAutosyncable ? 'Autosynced' : 'Outdated' }}
      </SwText>
      <InfoComponent class="info" trigger="hover" :fill="true">
        <SwText class="info-container" variant="body-XS"> {{ infoText }}</SwText>
      </InfoComponent>
    </div>
    <Action type="a" v-if="isAutosyncable" @click="$emit('accept-all')" class="accept-all-button"
      ><span class="accept-all-text">Accept all</span> <Icon name="check" no-padding class="action-icon" />
    </Action>
  </div>
</template>
<style scoped lang="postcss">
.info {
  color: var(--text-color-secondary);
}

.info-container {
  text-align: center;
  width: 170px;
  padding: var(--space-xs) var(--space-base);
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .link.accept-all-button {
    color: var(--text-color-link);
    display: flex;
    font-weight: 400;
    padding: 0;
    cursor: pointer;
  }

  .link .accept-all-text:hover {
    text-decoration: underline;
  }

  .container {
    display: flex;
    align-items: center;

    .autosyncable {
      color: var(--text-color-magic-strong);
    }

    .headline-icon {
      padding: 0px var(--space-xs);
    }

    .outdated {
      color: var(--oh-no-red);
    }

    .headline {
      color: var(--text-color-secondary);
      gap: var(--space-xs);
    }

    .nodes-count {
      padding: 0 var(--space-xs) 0 0;
    }
  }
}
</style>
