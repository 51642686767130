<script setup lang="ts">
import { computed } from 'vue';
import EllipsisTooltip from '@/common/components/organisms/EllipsisTooltip.vue';

const props = withDefaults(
  defineProps<{
    name: string;
    isSelected: boolean;
    custom?: boolean;
  }>(),
  {
    custom: false,
  }
);

const ELLIPSIS_LENGTH = 28;

const wrapperConditional = computed(() => {
  return props.name.length > ELLIPSIS_LENGTH;
});
const wrapperElement = computed(() => {
  return wrapperConditional.value ? EllipsisTooltip : 'span';
});
const wrapperProps = computed(() => {
  return wrapperConditional.value
    ? {
        long: true,
        content: props.name,
        placement: 'right',
        length: ELLIPSIS_LENGTH,
        class: {
          'text-ellipsis': true,
          'data-hj-suppress': true,
        },
      }
    : null;
});
const computedClasses = computed(() => {
  return {
    'branch-selector-item--selected': props.isSelected,
    'branch-selector-item--custom': props.custom,
  };
});
</script>

<template>
  <component :is="wrapperElement" v-bind="wrapperProps" class="branch-selector-item" :class="computedClasses">
    <Icon v-if="isSelected" class="branch-selector-item__icon" name="check" />
    {{ name }}
    <slot />
  </component>
</template>

<style scoped lang="postcss">
.branch-selector-item {
  display: block;
  padding-left: var(--space-sm);
  position: relative;

  &__icon {
    color: var(--color-brand);
    font-size: var(--fontsize-s);
    position: absolute;
    left: -8px;
    top: -2px;
  }

  &--custom {
    padding: var(--space-xs) 0 var(--space-xs) calc(var(--space-sm) + 12px);
    line-height: 1.25rem;

    &:hover {
      background: var(--color-hover);
    }

    .branch-selector-item__icon {
      left: 4px;
      top: 2px;
    }
  }
}
</style>
