import { DynamicChangeLine, calculateStringSimilarity, objectUtils } from '@swimm/shared';
import {
  ApplicabilityStatus,
  getDiffAsDynamicHunkContainer,
  similarityLineMatcher,
  splitLineByWords,
} from '@swimm/shared';
import { v4 as uuidv4 } from 'uuid';

const VERIFIED_SIMILARITY_THRESHHOLD = 0.9;
const SAME_MEAT_SAME_LINE_NUMBER_SIMILARITY_THRESHHOLD = 0.5;
const AUTOSYNCABLE_HARD_SIMILARITY_THRESHOLD = 0.8;
const AUTOSYNCABLE_SIMILARITY_THRESHHOLD = 0.4;
/**
 * The entire tokenizing section is based on `jsdiff`'s tokenizer. (https://github.com/kpdecker/jsdiff/blob/master/src/diff/word.js).
 */

/**
 * Preform the line matching algorithm on two strings (treating word boundaries as newlines), and return the dynamic changes.
 * NOTE: this function fails if there's no diff between the strings.
 * @param stringA first string to compare
 * @param stringB second string to compare to
 * @returns list of changes between the two strings, separated by words
 */
export function lineMatchWords(stringA: string, stringB: string): DynamicChangeLine[] {
  const splitA = splitLineByWords(stringA);
  const splitB = splitLineByWords(stringB);

  const { newTokens: newTokensA, meaninglessTokensMap } = replaceMeaninglessTokensWithId(splitA);

  const fileAData = newTokensA.join('\n');
  const fileBData = splitB.join('\n');

  /**
   * Diff between lineA and lineB where the similar parts are marked as context.
   * This is done in order to create correct "sub-hunks" for our similarity-based matching in the next step.
   */
  const contextMatchedLines = getDiffAsDynamicHunkContainer({
    fileContentA: fileAData,
    fileContentB: fileBData,
    repoId: 'cross-repo-tokens-non-supported',
  }).dynamicHunk.changes;
  const forcedSimilarityMatchedLines = matchAndSquashSubHunkChangeLines(contextMatchedLines);
  const matchedLinesWithRestoredTokens = recoverOriginalMeaninglessTokensInChangeLines({
    changeLines: forcedSimilarityMatchedLines,
    meaninglessTokensMap,
  });
  return matchedLinesWithRestoredTokens;
}

/**
 * Replace meaningless tokens with a random uuid and return the newly created list of tokens.
 * This function is used to work around how various line matchers will match meaningless (eg. whitespace) and then skew our results.
 * By replacing every meaningless instance with some random ID, we can guarantee that these lines won't be matched.
 * @param tokens list of tokens to go over.
 * @param initialMap an optional initialized Map to add the values to. Will change the given map!
 * @returns list of replaced tokens and a mapping between their new and old forms.
 */
function replaceMeaninglessTokensWithId(
  tokens: string[],
  initialMap?: Map<string, string>
): {
  newTokens: string[];
  meaninglessTokensMap: Map<string, string>;
} {
  const newTokens: string[] = [];
  const meaninglessTokensMap: Map<string, string> = initialMap ? initialMap : new Map();

  for (const token of tokens) {
    if (!isTokenMeaningless(token)) {
      newTokens.push(token);
      continue;
    }
    const newToken = `ID:${uuidv4()}`;
    meaninglessTokensMap.set(newToken, token);
    newTokens.push(newToken);
  }
  return { newTokens, meaninglessTokensMap };
}

function recoverOriginalMeaninglessTokensInChangeLines({
  changeLines,
  meaninglessTokensMap,
}: {
  changeLines: DynamicChangeLine[];
  meaninglessTokensMap: Map<string, string>;
}): DynamicChangeLine[] {
  const clonedChanges = objectUtils.deepClone(changeLines);
  for (const line of clonedChanges) {
    if (line.fileA?.data && meaninglessTokensMap.has(line.fileA.data)) {
      line.fileA.data = meaninglessTokensMap.get(line.fileA.data);
    }
  }
  return clonedChanges;
}

function isTokenMeaningless(token: string): boolean {
  const meaninglessRegex = /^\s*$/;
  return meaninglessRegex.test(token);
}

/**
 * Match change lines using a similarity index, then force-match every applicable `del`+`add` pairs (the same way Myers does).
 * Example: we start with "-c -c -1 -2 -3 -4 +c +c +1 +8 +4 +9" (spaces are line breaks, similar numbers are similar enough to be paired).
 * After matching by similarity we'll get: "0c 0c -1+1 -2 -3 +8 -4+4 +9" ("0" is context; "-X+X" is update).
 * After forcing matches: "0c 0c -1+1 -2+8 -3 -4+4 +9". Note how "2" and "8" got matched even though they aren't alike.
 * @param changeLines list of change lines to squash.
 * @returns array of change lines where every possible line-matching was made.
 */
function matchAndSquashSubHunkChangeLines(changeLines: DynamicChangeLine[]): DynamicChangeLine[] {
  /**
   * Diff between lineA and lineB where similar enough lines are marked as update.
   * This is the phase where actual matching occurs - putting similar tokens next to eachother.
   */
  const similarityMatchedLines = similarityLineMatcher.matchChangeLines({
    changeLines,
    lineMatcherConfig: { MIN_LINE_DISTANCE: 0.35 },
  });
  /**
   * Diff between lineA and lineB where every possible match is made.
   * Here we want to do use the naive pairing that Myers uses as a fallback:
   *  If there are any "add"+"del" pairs that weren't matched by the similarity algorithm, we'd rather pair them together than just having them marked as "delted".
   */
  return similarityLineMatcher.matchChangeLines({
    changeLines: similarityMatchedLines,
    lineMatcherConfig: { MIN_LINE_DISTANCE: 0 },
  });
}

interface MatchedPair {
  index: number;
  match: DynamicChangeLine;
}

/**
 * Make a list of pairs in the form of: (fileA change, overall index in lines array).
 * @param matchedLines array of lines after they've been matched by our line matching algorithm
 * @returns a list of only the changes that include `fileA`, paired with their overall index
 */
function makeIndexValuePairs(matchedLines: DynamicChangeLine[]): MatchedPair[] {
  const retVal: MatchedPair[] = [];
  for (const [matchIndex, match] of matchedLines.entries()) {
    if (match.fileA) {
      retVal.push({ index: matchIndex, match: match });
    }
  }
  return retVal;
}

/**
 * Return the "meat" cooresponding to the one selected in fileA.
 * @param meatStartIndex word index of the meat starting point
 * @param meatEndIndex word index of the meat ending point
 * @param matchedLines array of lines after they've beenmatched by our line matching algorithm
 * @returns the cooresponding string of the meat from fileA in fileB
 */
export function getMatchingLineMeat(
  meatStartIndex: number,
  meatEndIndex: number,
  matchedLines: DynamicChangeLine[]
): string {
  const matchingPairs = makeIndexValuePairs(matchedLines).slice(meatStartIndex, meatEndIndex + 1);
  const fileBLines = matchedLines.slice(matchingPairs[0].index, matchingPairs[matchingPairs.length - 1].index + 1);
  const newMeatArr = [];
  for (const pair of fileBLines) {
    if (pair.fileB) {
      newMeatArr.push(pair.fileB.data);
    }
  }
  return newMeatArr.join('');
}

type LineSection = { indices: { start: number; end: number }; words: string[] };

/**
 * Convert an array of split words to a `LineSection` object.
 *  The `LineSection` object conversion allows us to easily access the relevant info from a `DynamicChangeLine`, mainly the start and end indices of the given file in the array.
 * @param dynamicChangeLines change lines array of split words.
 * @param file either `fileA` or `fileB`, to indicate which file we want to take into account.
 * @returns A `LineSection` object with the relevant indices and line data.
 */
function dynamicChangeLineArrayToLineSection(
  dynamicChangeLines: DynamicChangeLine[],
  file: 'fileB' | 'fileA' = 'fileB'
): LineSection {
  const data: string[] = [];
  for (const changeLine of dynamicChangeLines) {
    if (file === 'fileB' && changeLine.fileB) {
      data.push(changeLine.fileB.data);
    } else if (file === 'fileA' && changeLine.fileA) {
      data.push(changeLine.fileA.data);
    }
  }
  // We want the first line that contains info on the given file in order to set it as the starting index for the given section.
  const startIndexLine = dynamicChangeLines.find((line) => line[file]);
  const startIndex = startIndexLine ? startIndexLine[file].actualLineNumber - 1 : -1;
  // We want the last line that contains info on the given file in order to set it as the final index for the given section.
  //  There is no "find from the end" function in JS so instead we're copying and reversing our changes array, then running the same "find" function.
  const endIndexLine = dynamicChangeLines
    .slice()
    .reverse()
    .find((line) => line[file]);
  const endIndex = endIndexLine ? endIndexLine[file].actualLineNumber - 1 : -1;
  return { indices: { start: startIndex, end: endIndex }, words: data };
}

type ExtractSectionsFromLineReturn = {
  fileA: { prefix: LineSection; meat: LineSection; suffix: LineSection };
  fileB: { prefix: LineSection; meat: LineSection; suffix: LineSection };
};
/**
 * Extract the various LineSections of the provided line and return it for file A and B.
 * @param meatStartIndex word index of the meat in the split line.
 * @param meatEndIndex word index of the meat's end in the split line.
 * @param matchedWordsAsLines words data from splitted lines.
 * @returns data of all the various sections (prefix, meat, suffix) of both file A and B.
 */
export function extractSectionsFromMatchedLines(
  meatStartIndex: number,
  meatEndIndex: number,
  matchedWordsAsLines: DynamicChangeLine[]
): ExtractSectionsFromLineReturn {
  // We want to get the indices of the new meat corresponding the those of the old meat.
  //  For this we'll get the matched words of the two lines, filtering only fileA, and slice them according to the indices given to us.
  //  This will allow us to get the corresponding lines of the section bound to be the new meat.
  const matchingPairs = makeIndexValuePairs(matchedWordsAsLines).slice(meatStartIndex, meatEndIndex + 1);
  // Slicing the prefix, the part before the meat ("upper context" in snippet terms). From there we'll extract the prefix in fileA (old) and fileB (new).
  const fileBPrefixLines = matchedWordsAsLines.slice(0, matchingPairs[0].index);
  const prefixNew: LineSection = dynamicChangeLineArrayToLineSection(fileBPrefixLines);
  const prefixOld: LineSection = dynamicChangeLineArrayToLineSection(fileBPrefixLines, 'fileA');
  // Slicing the meat, the selected part of the line.
  const fileBMeatLines = matchedWordsAsLines.slice(
    matchingPairs[0].index,
    matchingPairs[matchingPairs.length - 1].index + 1
  );
  const meatNew: LineSection = dynamicChangeLineArrayToLineSection(fileBMeatLines);
  const meatOld: LineSection = dynamicChangeLineArrayToLineSection(fileBMeatLines, 'fileA');
  // Slicing the suffix, the part after the meat ("lower context" in snippet terms).
  const fileBSuffixLines = matchedWordsAsLines.slice(matchingPairs[matchingPairs.length - 1].index + 1);
  const suffixNew: LineSection = dynamicChangeLineArrayToLineSection(fileBSuffixLines);
  const suffixOld: LineSection = dynamicChangeLineArrayToLineSection(fileBSuffixLines, 'fileA');
  return {
    fileA: { prefix: prefixOld, meat: meatOld, suffix: suffixOld },
    fileB: { prefix: prefixNew, meat: meatNew, suffix: suffixNew },
  };
}

/**
 * For a given processed line diff, get the similarity of the meat and context.
 * @param linesSections the output of `extractSectionsFromMatchedLines`.
 * @returns similarity index for both the meat and the combined prefix + suffix (context).
 */
export function getSectionSimilarity(linesSections: ExtractSectionsFromLineReturn): {
  meatSimilarity: number;
  contextSimilarity: number;
} {
  const fileAMeat = linesSections.fileA.meat.words.join('');
  const fileBMeat = linesSections.fileB.meat.words.join('');
  const fileACombinedContext = linesSections.fileA.prefix.words.concat(linesSections.fileA.suffix.words).join('');
  const fileBCombinedContext = linesSections.fileB.prefix.words.concat(linesSections.fileB.suffix.words).join('');
  const meatSimilarity = calculateStringSimilarity(fileAMeat, fileBMeat);
  const contextSimilarity = calculateStringSimilarity(fileACombinedContext, fileBCombinedContext);
  return { meatSimilarity, contextSimilarity };
}

type AutoSyncWithinLineReturn = {
  verdict: ApplicabilityStatus;
  meatStartIndexInB: number;
  meatEndIndexInB: number;
  newWord: string;
};
/**
 * Given two matched lines A and B, and the indices of the meat in line A - return the indices of a corresponding meat in line B and the applicability verdict.
 * @param lineA line to be compared.
 * @param lineB line to compare to.
 * @param meatStartIndex word index of the meat's start in lineA.
 * @param meatEndIndex word index of the meat's end in lineA.
 * @returns the new indices of the meat in lineB and the applicability verdict.
 */
export function autosyncWithinLine(
  lineA: string,
  lineB: string,
  meatStartIndex: number,
  meatEndIndex: number,
  lineANumber: number,
  lineBNumber: number
): AutoSyncWithinLineReturn {
  const initialWord = splitLineByWords(lineA)
    .slice(meatStartIndex, meatEndIndex + 1)
    .join('');
  if (!lineA || !lineB) {
    return {
      verdict: ApplicabilityStatus.Outdated,
      meatStartIndexInB: meatStartIndex,
      meatEndIndexInB: meatEndIndex,
      newWord: initialWord,
    };
  }
  if (lineA === lineB) {
    return {
      verdict: ApplicabilityStatus.Verified,
      meatStartIndexInB: meatStartIndex,
      meatEndIndexInB: meatEndIndex,
      newWord: initialWord,
    };
  }
  const matchedWords = lineMatchWords(lineA, lineB);
  const lineSections = extractSectionsFromMatchedLines(meatStartIndex, meatEndIndex, matchedWords);
  let verdict: ApplicabilityStatus;
  // If the meat is empty or consists only of whitespace, we set it as outdated.
  if (!lineSections.fileB.meat.words.length || lineSections.fileB.meat.words.join('').trim() === '') {
    verdict = ApplicabilityStatus.Outdated;
  } else {
    const similarities = getSectionSimilarity(lineSections);
    if (similarities.meatSimilarity === 1) {
      if (similarities.contextSimilarity >= VERIFIED_SIMILARITY_THRESHHOLD) {
        verdict = ApplicabilityStatus.Verified;
      } else if (
        lineANumber === lineBNumber &&
        similarities.contextSimilarity >= SAME_MEAT_SAME_LINE_NUMBER_SIMILARITY_THRESHHOLD
      ) {
        verdict = ApplicabilityStatus.Verified;
      } else {
        verdict = ApplicabilityStatus.Autosyncable;
      }
    } else if (similarities.meatSimilarity === 0) {
      // The meat changed completely, we should be extra cautious
      verdict =
        similarities.contextSimilarity >= AUTOSYNCABLE_HARD_SIMILARITY_THRESHOLD
          ? ApplicabilityStatus.Autosyncable
          : ApplicabilityStatus.Outdated;
    } else {
      verdict =
        similarities.contextSimilarity >= AUTOSYNCABLE_SIMILARITY_THRESHHOLD
          ? ApplicabilityStatus.Autosyncable
          : ApplicabilityStatus.Outdated;
    }
  }
  return {
    verdict: verdict,
    meatStartIndexInB: lineSections.fileB.meat.indices.start,
    meatEndIndexInB: lineSections.fileB.meat.indices.end,
    newWord: lineSections.fileB.meat.words.join(''),
  };
}
