import swal from 'sweetalert';

export async function confirmDraftDeletion() {
  return await swal({
    title: 'Are you sure you want to delete this draft?',
    dangerMode: true,
    buttons: {
      cancel: true,
      confirm: { text: 'Delete', visible: true },
    },
  });
}
