import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { PageRoutesNames } from '../consts';

// composable to answer what is the current page
// for components that depend on this.
// so if we change name or similar all be encapsulated here

export function useCurrentRoute() {
  const route = useRoute();

  const isStatusPage = computed(() => route.path.endsWith('/status'));
  // note: isDocPage is true in view and edit
  const isDocPage = computed(() => !!route.params.unitId);
  const isEditDocPage = computed(() => route.name === PageRoutesNames.DOC_EDIT);
  // note: isPlaylistPage is true in view and edit
  const isPlaylistPage = computed(() => route.path.includes('/playlists'));
  const isEditPlaylistPage = computed(() => route.name === PageRoutesNames.PLAYLIST_EDIT);
  const isSharedDocsPage = computed(() => route.name === 'Shared Docs');
  const isSharedDocPage = computed(() => route.name === 'Shared Doc');
  const isJoinWorkspacePage = computed(() => route.path.endsWith('/joinWorkspace'));
  const isCreateWorkspacePage = computed(() => route.path.endsWith('/workspaces/create'));
  const isRequestToJoinPage = computed(() => route.path.endsWith('/request'));

  return {
    isStatusPage,
    isDocPage,
    isEditDocPage,
    isPlaylistPage,
    isEditPlaylistPage,
    isSharedDocsPage,
    isSharedDocPage,
    isJoinWorkspacePage,
    isCreateWorkspacePage,
    isRequestToJoinPage,
  };
}
