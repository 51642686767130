<script setup>
import ChangePaymentMethodModal from '@/modules/billing/components/ChangePaymentMethodModal.vue';
import { TextSkeleton } from '@swimm/ui';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { capitalize } from 'lodash-es';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const loading = ref(true);

const type = ref('');
const lastDigits = ref('');
const expiryDate = ref('');

onMounted(async () => {
  await setCreditCardInfo();
});

const showChangePaymentModal = ref(false);
const paymentValid = computed(() => type.value && lastDigits.value && expiryDate.value);

async function setCreditCardInfo() {
  loading.value = true;
  const { data } = await CloudFunctions.fetchSubscriptionCreditCard({ workspaceId: route.params.workspaceId });
  const { last4, brand, expMonth, expYear } = data;
  const month = String(expMonth).padStart(2, '0');
  const year = String(expYear).slice(-2);

  type.value = capitalize(brand);
  lastDigits.value = last4;
  expiryDate.value = `${month}/${year}`;

  loading.value = false;
}

function onChangePaymentMethodClick() {
  showChangePaymentModal.value = true;
}
</script>

<template>
  <template v-if="loading">
    <TextSkeleton class="skeleton" variant="body-L" />
    <TextSkeleton class="skeleton short" variant="body-L" />
  </template>
  <template v-else-if="paymentValid">
    <SwText variant="body-L"
      ><strong>{{ type }}</strong> ending in <strong>{{ lastDigits }}</strong></SwText
    >
    <SwText variant="body-L"
      >Expires <strong>{{ expiryDate }}</strong></SwText
    >
    <a class="link bold" @click="onChangePaymentMethodClick"
      ><SwText variant="subtitle-S">Change payment method</SwText></a
    >
  </template>
  <SwText v-else variant="body-S" class="error">Something went wrong while processing payment information.</SwText>
  <ChangePaymentMethodModal
    :show="showChangePaymentModal"
    :workspace-id="route.params.workspaceId"
    @close="showChangePaymentModal = false"
    @save="setCreditCardInfo"
  />
</template>

<style scoped lang="postcss">
.skeleton {
  margin-bottom: var(--space-xs);
  width: 70%;

  &.short {
    width: 50%;
  }
}

.link {
  cursor: pointer;
  display: inline-block;
  color: var(--text-color-link);
}

.error {
  color: var(--text-color-error);
}
</style>
