import { replace, trim } from 'lodash-es';

export function pathToId(path: string) {
  // Remove leading and trailing slashes
  path = trim(path, '/');

  // Replace slashes with double hyphens
  path = replace(path, /\//g, '--');

  // Replace dots with __dot__
  path = replace(path, /\./g, '__dot__');

  // Replace hyphens with double underscores
  path = replace(path, /-/g, '__');

  // Replace any other non-valid characters with double underscores
  path = replace(path, /[^a-zA-Z0-9-_]/g, '__');

  // Ensure it starts with a letter (prefix with 'id-' if not)
  if (!/^[a-zA-Z]/.test(path)) {
    path = 'id-' + path;
  }

  return path;
}
