<template>
  <AuthBaseLayout
    :title="isRegister ? 'Sign up to Swimm' : 'Welcome back!'"
    :subtitle="subtitle"
    class="card-container"
  >
    <TextField
      v-model.trim="email"
      :error="emailError"
      type="email"
      label="Work email"
      placeholder="daniel.smith@acme.com"
      class="input"
      data-testid="sso-email-field"
      required
      @update:model-value="onEmailFieldBlur"
      @blur="onEmailFieldBlur"
    />
    <SSOAuthButton class="sso-login" @click="onSSOSignupClick" :email="email">Continue with SSO</SSOAuthButton>
    <template #footer>
      <SwText variant="body-S">
        Back to the
        <router-link @click="reportClick" :to="isRegister ? '/register' : '/login'" class="link">
          {{ isRegister ? 'Register' : 'Login' }}
        </router-link>
        page
      </SwText>
      <SwText v-if="isRegister" variant="body-S" data-testid="sign-in-button"
        >Already have an account?
        <router-link @click="reportClick" to="/login" class="link">Sign in</router-link>
      </SwText>
    </template>
  </AuthBaseLayout>
</template>

<script>
import { useAnalytics } from '@/common/composables/useAnalytics';
import AuthBaseLayout from '@/modules/core/components/AuthBaseLayout.vue';
import { pageEvents, productEvents } from '@swimm/shared';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { startCase } from 'lodash-es';
import { getSourcePropertiesFromLocalStorage } from '@/common/utils/helpers';
import { UrlUtils, getLoggerNew } from '@swimm/shared';
import SSOAuthButton from '@/common/components/Auth/SSOAuthButton.vue';
import { SwText, TextField } from '@swimm/ui';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { storeToRefs } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

const logger = getLoggerNew(__modulename);

const ErrorMessages = {
  EMAIL_REQUIRED: 'Work email is required',
  EMAIL_INVALID: 'Invalid email',
};

export default {
  components: {
    AuthBaseLayout,
    SwText,
    TextField,
    SSOAuthButton,
  },
  setup() {
    const route = useRoute();
    const analytics = useAnalytics();
    const authStore = useAuthStore();
    const { user } = storeToRefs(authStore);
    const { setUser } = authStore;

    reportPageViewed();

    function reportPageViewed() {
      const payload = {
        'Page Name': 'SSO Page',
        ...(route.query?.source && { Source: route.query.source }),
      };

      analytics
        .cloudPageViewed({
          identity: uuidv4(),
          anonymousId: uuidv4(),
          event: pageEvents.LOADED_A_PAGE,
          payload,
        })
        .then(() => analytics.track(pageEvents.LOADED_A_PAGE_MARKETING, payload));
    }

    const subtitle = computed(() =>
      route.query.email || route.query.workspaceName
        ? `Join ${route.query.workspaceName || 'the Workspace'} on Swimm`
        : 'Move knowledge fast. Develop faster.'
    );

    const isRegister = computed(() => route?.query?.register && route?.query?.register === 'true');

    return {
      user,
      setUser,
      analytics,
      subtitle,
      isRegister,
    };
  },
  data() {
    return {
      email: '',
      error: '',
      emailError: '',
      isLoading: false,
    };
  },
  async created() {
    this.prefillEmail();
    const props = getSourcePropertiesFromLocalStorage({ keyFormatCallback: startCase, valueFallback: '' });
    if (this.$route.query.platform) {
      props.Platform = this.$route.query.platform;
    }

    const payload = {
      ...props,
      'Is From GitHub Marketplace': false,
      'GitHub Email': null,
    };
    this.analytics.track(this.isRegister ? productEvents.VIEW_SSO_SIGNUP : productEvents.VIEW_SSO_LOGIN, payload);
  },
  methods: {
    prefillEmail() {
      const { email } = this.$route.query;
      if (email) {
        this.email = email;
        return;
      }

      const emailFromCookie = Cookies.get('swimm_jumpcloud_email');
      if (emailFromCookie) {
        this.email = decodeURIComponent(emailFromCookie);
      }
    },
    onEmailChange() {
      this.emailError = '';
    },
    onEmailFieldBlur() {
      this.emailError = '';
      this.validateEmail();
    },
    validateEmail() {
      if (!this.email) {
        this.emailError = ErrorMessages.EMAIL_REQUIRED;
      } else if (!UrlUtils.isValidEmail(this.email)) {
        this.emailError = ErrorMessages.EMAIL_INVALID;
      }
    },
    async onSSOSignupClick() {
      logger.info(`SSO Signup was clicked`);
      this.analytics.track(productEvents.SSO_BUTTON_CLICK, {
        Origin: this.isRegister ? 'Signup page' : 'Login page',
        'Origin URL': this.$route.fullPath,
      });
    },
    reportClick() {
      this.analytics.track(productEvents.CLICKED_SIGN_IN, {
        'Is From GitHub Marketplace': false,
        'GitHub Email': null,
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.card-container {
  margin: auto;
  gap: var(--space-md);

  .link {
    color: var(--text-color-link);
  }
}
</style>
