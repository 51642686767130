<template>
  <div id="page">
    <div data-testid="automation-check" v-if="isAutomation" />
    <Suspense>
      <router-view v-if="!loading" />
      <TopMenuLayout v-else :show-knowledge-icon="false" :show-get-started="false">
        <template #topBar>
          <TopBar :loading="true" />
        </template>
        <template #content>
          <Loader class="app-loader" />
        </template>
      </TopMenuLayout>
      <template #fallback>
        <Loader class="fallback-loader" />
      </template>
    </Suspense>
    <GlobalModal />
    <AppModals />
    <ToastNotifications />
  </div>
</template>

<script>
import TopBar from '@/common/components/TopBar/TopBar.vue';
import TopMenuLayout from '@/common/layouts/TopMenuLayout.vue';
import AppModals from '@/modules/core/components/AppModals.vue';
import { mapActions } from 'vuex';
import GlobalModal from '@/common/components/modals/GlobalModal.vue';
import { ToastNotifications } from '@swimm/editor';
import { config, getLoggerNew, gitProviderUtils, state as localState, logRejectedPromise } from '@swimm/shared';
import { storeToRefs } from 'pinia';
import { isAutomation, isDevelopment } from '@/config';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useCheckSwimmVersion } from '@/modules/core/composables/checkSwimmVersion';
import { useUserConfigStore } from './modules/core/stores/user-config-store';
import { collectionNames, getDocFromCollection } from '@/adapters-common/firestore-wrapper';
import { PageRoutesNames } from '@/common/consts';

const logger = getLoggerNew(__modulename);

export default {
  components: { AppModals, TopBar, TopMenuLayout, GlobalModal, ToastNotifications },
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const {
      fetchTheme,
      fetchUserSalt,
      fetchThemeByUser,
      fetchShownHelpTooltips,
      fetchShownExistingUsersHelpTooltips,
      getAutocompletePreference,
    } = useUserConfigStore();
    const reposStore = useReposStore();
    const { reposStateData } = storeToRefs(reposStore);
    const { setRepoStateData } = reposStore;
    const { checkSwimmVersion } = useCheckSwimmVersion();

    if (!isDevelopment) {
      checkSwimmVersion();
    }

    return {
      reposStateData,
      user,
      setRepoStateData,
      fetchTheme,
      fetchUserSalt,
      fetchThemeByUser,
      fetchShownHelpTooltips,
      fetchShownExistingUsersHelpTooltips,
      getAutocompletePreference,
      isAutomation,
    };
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    user: {
      handler: async function ({ uid: newUserUid } = {}, { uid: oldUserUid } = {}) {
        const promises = [];

        if (!oldUserUid && newUserUid && this.$route.fullPath !== '/404') {
          this.loading = true;
          promises.push(this.setDefaultAppData());
        } else if (oldUserUid && !newUserUid) {
          // TODO: unsubscribe all open subscriptions
        }

        if (newUserUid) {
          promises.push(this.fetchThemeByUser({ userId: newUserUid }), this.fetchUserSalt({ userId: newUserUid }));
          this.fetchShownHelpTooltips({ userId: newUserUid }).catch(
            logRejectedPromise(logger, 'fetchShownHelpTooltips')
          );
          this.fetchShownExistingUsersHelpTooltips({ userId: newUserUid }).catch(
            logRejectedPromise(logger, 'fetchShownExistingUsersHelpTooltips')
          );
          this.initializeSwimmportStore({ userId: newUserUid }).catch(
            logRejectedPromise(logger, 'initializeSwimmportStore')
          );
          this.getAutocompletePreference(newUserUid).catch(logRejectedPromise(logger, 'getAutocompletePreference'));
        } else {
          promises.push(this.fetchTheme());
        }
        await Promise.all(promises);
        this.loading = false;
      },
      immediate: true,
    },
  },
  async created() {
    getDocFromCollection(collectionNames.USERS, 'userId').then(async (res) => {
      if (res.code !== config.SUCCESS_RETURN_CODE && res.errorMessage.toString().includes('client is offline')) {
        this.loading = false;
        await this.$router.replace({ name: PageRoutesNames.NO_ACCESS });
      }
    });
  },
  methods: {
    ...mapActions('swimmport', ['initializeSwimmportStore']),
    async setDefaultAppData() {
      if (!this.user.uid) {
        return;
      }
      await this.assertDataInIndexedDb();
    },
    async assertDataInIndexedDb() {
      const gitTokenInCurrentFormat = await gitProviderUtils.getAllAuthorizedGitHostings();
      if (gitTokenInCurrentFormat.length > 0) {
        await localState.deleteKey({ key: 'token', isGlobalKey: true });
        await localState.deleteKey({ key: 'gh_token', isGlobalKey: true });
        await localState.deleteKey({ key: 'ghe_token', isGlobalKey: true });
        await localState.deleteKey({ key: 'gh_orgs', isGlobalKey: true });
        await localState.deleteKey({ key: 'gh_enterprise_orgs', isGlobalKey: true });
        await localState.deleteKey({ key: 'gl_token', isGlobalKey: true });
        await localState.deleteKey({ key: 'gp_url', isGlobalKey: true });
        await localState.deleteKey({ key: 'refresh_token', isGlobalKey: true });
      }

      const repos = this.reposStateData;
      for (const [repoId, repo] of Object.entries(repos)) {
        // Repos with no provider should be removed from state
        if (!repo?.provider) {
          logger.debug(`About to remove ${repoId} from local state as it was missing the "provider" value`);
          await localState.deleteKey({ key: `repos.${repoId}`, isGlobalKey: true });
          this.setRepoStateData(repoId, {});
        }
      }
    },
  },
};
</script>

<style scoped>
:global(body) {
  background-color: var(--color-bg);
}

#page {
  height: 100%;
}

.app-loader {
  height: 100%;
}

.fallback-loader {
  height: 100vh;
}

:global(.grecaptcha-badge) {
  visibility: hidden;
}
</style>
