<script lang="ts">
export const TruncateAlign = ['left', 'right'];
export type TruncateAlignType = (typeof TruncateAlign)[number];
</script>

<script setup lang="ts">
import { computed } from 'vue';
const props = withDefaults(
  defineProps<{
    /**
     * Text alignment is left by default with the ellipsis appearing on the right. Right align is the inverse behaviour.
     */
    align?: TruncateAlignType;
  }>(),
  {
    align: 'left',
  }
);

const computedClasses = computed(() => ({
  [`truncate--${props.align}`]: true,
}));
</script>

<template>
  <div class="truncate" :class="computedClasses">{{ align === 'right' ? '&lrm;' : '' }}<slot /></div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.truncate {
  $self: &;

  @include defaults;

  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: auto;

  &--left {
    direction: ltr;
  }

  &--right {
    direction: rtl;
  }
}
</style>
