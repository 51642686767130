<template>
  <div v-if="isIntro" class="intro-container content-padding-wrapper">
    <MarkdownEditor
      v-if="resource.description"
      :markdown="[{ type: SwmCellType.Text, text: resource.description }]"
      :read-only="true"
      :show-border="false"
      :is-workspace="false"
      title-type="swimmEditDescription"
      data-testid="workspace-description"
      :repo-id="$route.params.repoId as string"
      :workspace-id="$route.params.workspaceId as string"
      :branch="$route.params.branch as string"
      :playlist-id="playlist.id ?? playlist.draftId"
    />
    <ResourcesLinksList
      v-if="resource.sequence && resource.sequence.length > 0"
      :nested-playlist="nestedPlaylist"
      :steps="linksList"
      :show-steps-count="resource.showStepsCount"
    />
    <Card v-else class="card">
      <div class="empty-resource-card-header">
        <Icon name="unavailable" class="unavailable-icon" />
        <h2>This playlist has no steps yet.</h2>
      </div>
      <span v-if="!canEdit">Ask the repo Admin to add some docs and links to this playlist</span>
      <span v-else class="edit-text-prefix">
        Add links and docs to the playlist by &nbsp;
        <span
          class="edit-link clickable"
          @click="navigateToPageAndTerminateWorker({ newRoute: `${getRepoPath()}/playlists/${resource.id}/edit` })"
        >
          editing it here
        </span>
      </span>
    </Card>
  </div>
  <div v-else class="summary-container content-padding-wrapper">
    <div>
      <Notification v-if="isDone" :notification-content="summaryNotificationContent" class="override-background" />
      <MarkdownEditor
        v-if="resource.summary"
        :markdown="[{ type: SwmCellType.Text, text: resource.summary }]"
        :read-only="true"
        :show-border="false"
        :is-workspace="false"
        title-type="swimmEditSummary"
        data-testid="workspace-description"
        :repo-id="$route.params.repoId as string"
        :workspace-id="$route.params.workspaceId as string"
        :branch="$route.params.branch as string"
        :playlist-id="playlist.id ?? playlist.draftId"
      />
      <ResourcesLinksList
        :nested-playlist="nestedPlaylist"
        :steps="linksList"
        :list-title="isDone ? 'Completed steps' : ''"
        :show-steps-count="false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ResourcesLinksList from '@/common/components/organisms/ResourcesLinksList.vue';
import { useStore } from 'vuex';
import Notification from '@/common/components/atoms/Notification.vue';
import MarkdownEditor from '@/modules/editor/components/MarkdownEditor.vue';
import Card from '@/common/components/atoms/Card.vue';
import { SwmCellType, config } from '@swimm/shared';
import { useSwimmResource } from '@/common/composables/swimmResource';
import { useNavigate } from '@/common/composables/navigate';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import type { Playlist } from '@/modules/playlists/types';
import { ComputedRef, type PropType, computed } from 'vue';
import { usePlaylist } from '@/modules/playlists/composables/playlist';

interface SequenceIntroSummaryResource extends Playlist {
  showStepsCount: boolean;
}

const { user } = storeToRefs(useAuthStore());
const { isResourceEditable } = useSwimmResource();
const { navigateToPageAndTerminateWorker, getRepoPath } = useNavigate();
const { parseSequenceStepsForList } = usePlaylist();
const store = useStore();

const props = defineProps({
  repoId: { type: String, default: null },
  isIntro: { type: Boolean, default: false },
  nestedPlaylist: { type: Boolean, default: false },
  playlist: { type: Object as PropType<Playlist>, required: true },
  isPlaylistDraft: { type: Boolean, default: false },
});

const resource: ComputedRef<SequenceIntroSummaryResource> = computed(() => ({
  ...props.playlist,
  showStepsCount: true,
}));
const canEdit = computed(() => isResourceEditable(props.repoId, resource.value.id, 'playlist'));
const resourceStatus = computed(() =>
  store.getters['database/db_getSwimmStatus'](
    props.repoId,
    user.value.uid,
    resource.value.id || config.SWIMMER_STATUSES.NOT_STARTED
  )
);
const isDone = computed(() => resourceStatus.value === config.SWIMMER_STATUSES.DONE);
const linksList = computed(() =>
  parseSequenceStepsForList({
    playlist: resource.value,
    isEdit: props.isPlaylistDraft,
    repoId: props.repoId,
  })
);
const summaryNotificationContent = computed(() => {
  const normalNotificationTitle = `🏆 Done. You’re a '${resource.value.name}' Pro.`;
  return {
    title: normalNotificationTitle,
    body: `Feel like contributing? Talk to your Admin to add new content!`,
  };
});
</script>

<style scoped>
.intro-container,
.summary-container {
  margin: auto;
  max-width: var(--narrow-content-width);
}

.restart {
  margin-bottom: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  background-color: var(--color-bg);
}

.line {
  margin: 20px 0;
  color: var(--text-color-primary); /* old IE */
  background-color: var(--color-selected); /* Modern Browsers */
}

.card {
  margin-top: 48px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 44px;
  border-radius: 4px;
  background: none;
  box-shadow: 1px 1px 4px rgba(66, 66, 66, 0.2);
}

.empty-resource-card-header h2,
.card span.edit-text-prefix {
  margin-left: 60px;
}

.empty-resource-card-header {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}

.unavailable-icon {
  position: absolute;
  padding: 0;
  width: 38px;
  height: 38px;
  font-size: var(--headline1);
  text-align: center;
  color: var(--text-color-primary);
  line-height: 38px;
}

.edit-link {
  color: var(--text-color-link);
}

.override-background {
  background-color: var(--color-bg);
}
</style>
