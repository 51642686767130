import type { Editor } from '@tiptap/core';
import { ref } from 'vue';

import type { TokenSuggestion } from '@swimm/shared';

import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';

export type TokenPreviewState = { show: false } | { show: true; code: string; token: TokenSuggestion };

export function useTokenSelectionPreview(editor: Editor) {
  const swimmEditorServices = getSwimmEditorServices(editor);

  const state = ref<TokenPreviewState>({ show: false });

  const reset = (): void => {
    state.value = { show: false };
  };

  const set = async (token: TokenSuggestion): Promise<void> => {
    const branch =
      token.repoId === swimmEditorServices.repoId.value
        ? swimmEditorServices.branch.value
        : swimmEditorServices.getRepo(token.repoId)?.defaultBranch;
    if (branch == null) {
      state.value = { show: false };
      return;
    }

    const code = await swimmEditorServices.external.getFileContent({
      repoId: token.repoId,
      filePath: token.position.path,
      revision: branch,
    });
    if (code == null) {
      state.value = { show: false };
      return;
    }

    state.value = { show: true, code, token };
  };

  return { state, reset, set };
}
