import { useFileFormatter } from '@swimm/editor';
import { config } from '@swimm/shared';
import swal from 'sweetalert';

export function useClientFilesUpload() {
  const { prepareFiles } = useFileFormatter(true);
  async function getFilesFromClientInput(filePickerRef, { encode = false } = {}) {
    try {
      const { files, canceled }: { files; canceled?: boolean } = await getClientFiles(filePickerRef, { encode });
      if (canceled) {
        return { code: config.ERROR_RETURN_CODE, canceled: true };
      }
      return { code: config.SUCCESS_RETURN_CODE, files };
    } catch (err) {
      return { code: config.ERROR_RETURN_CODE };
    }
  }

  function getClientFiles(filePickerRef, { encode = false }) {
    return new Promise<{ files; canceled?: boolean }>((resolve, reject) => {
      filePickerRef
        .openPicker()
        .then(async (filesPicked: File[]) => {
          if (!filesPicked.length) {
            resolve({ files: [], canceled: true });
          }
          try {
            const files = await prepareFiles({
              files: filesPicked,
              encode,
            });
            resolve({ files });
          } catch (e) {
            swal(`Failed to upload files.`);
            reject(new Error('Failed to upload files'));
          }
        })
        .catch(() => {
          swal(`Failed to upload files.`);
          reject(new Error('Failed to upload files'));
        });
    });
  }

  return {
    getFilesFromClientInput,
  };
}
