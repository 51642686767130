import { onUnmounted, ref } from 'vue';
import type { NodeViewProps } from '@tiptap/core';
import type { Transaction } from '@tiptap/pm/state';

export const useSelectionHighlighting = (props: NodeViewProps) => {
  const isSelected = ref(false);

  const callback = ({ transaction }: { transaction: Transaction }) => {
    isSelected.value =
      transaction.selection &&
      // A node is copied if its base position is inside the selection; if a selection starts before a snippet and
      // continues into a part of the snippet - the snippet will be copied, but if the selection starts from inside the
      // snippet and continues until after it, the snippet itself will not be copied. We therefore highlight the node if
      // the base position is part of the selection.
      props.getPos() >= transaction.selection.from &&
      // Note - selection.to is exclusive.
      props.getPos() < transaction.selection.to;
  };

  props.editor.on('selectionUpdate', callback);

  onUnmounted(() => {
    props.editor.off('selectionUpdate', callback);
  });

  return { isSelected };
};
