<script setup lang="ts">
import { computed } from 'vue';
import { Icon, SwText } from '@swimm/ui';

const props = withDefaults(
  defineProps<{
    invert?: boolean;
    disableShowMore?: boolean;
    disableShowLess?: boolean;
    isAuthorized?: boolean;
  }>(),
  {
    invert: false,
    disableShowMore: false,
    disableShowLess: false,
    isAuthorized: true,
  }
);

const emit = defineEmits<{
  (e: 'show-more'): void;
  (e: 'show-less'): void;
  (e: 'report-hover', element: string): void;
}>();

const showMoreIcon = computed(() => (props.invert ? 'expand-up' : 'expand-down'));
const showLessIcon = computed(() => (props.invert ? 'expand-down' : 'expand-up'));

const unauthorizedTooltip = computed(() => {
  return props.isAuthorized ? '' : 'To be able to see more code, authorize GitHub';
});

function onShowMoreClick() {
  if (!props.disableShowMore) {
    emit('show-more');
  }
}

function onShowLessClick() {
  if (!props.disableShowLess) {
    emit('show-less');
  }
}

function reportHover(element: string) {
  if (unauthorizedTooltip.value !== '') {
    emit('report-hover', element);
  }
}
</script>

<template>
  <div class="bar" :class="{ invert }">
    <SwText
      variant="body-XS"
      :class="['item', { disabled: disableShowMore, unauthorized: !isAuthorized }]"
      @click="onShowMoreClick"
      @mouseenter="reportHover('Show More')"
      v-tooltip="unauthorizedTooltip"
    >
      <Icon :name="showMoreIcon" class="icon" no-padding />
      Show more
    </SwText>
    <SwText
      variant="body-XS"
      :class="['item', { disabled: disableShowLess, unauthorized: !isAuthorized }]"
      @click="onShowLessClick"
      @mouseenter="reportHover('Show Less')"
      v-tooltip="unauthorizedTooltip"
    >
      <Icon :name="showLessIcon" class="icon" no-padding />
      Show less
    </SwText>
  </div>
</template>

<style scoped lang="postcss">
.bar {
  display: flex;
  background: var(--color-surface);
  gap: 1px;
  overflow: hidden;
  user-select: none;
  position: sticky;
  left: 0;
  opacity: 0;

  &.invert {
    border-bottom: 2px solid var(--color-border-default-subtle);
  }

  &:not(.invert) {
    border-top: 2px solid var(--color-border-default-subtle);
  }

  .item {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--space-xs);
    padding: var(--space-xs) var(--space-base);
    cursor: default;

    &.disabled {
      color: var(--text-color-disable);
    }

    &:not(.disabled):hover {
      cursor: pointer;
      background: var(--color-hover);
    }

    &.unauthorized {
      cursor: not-allowed;
    }

    .icon {
      font-size: var(--body-S);
    }

    &:first-of-type::after {
      content: '';
      position: absolute;
      height: 75%;
      width: 1px;
      background: var(--color-border-default);
      right: -1px;
    }
  }
}
</style>
