<script setup>
import BlockSkeleton from '@/modules/core/components/BlockSkeleton.vue';
import { TextSkeleton } from '@swimm/ui';

const MIN_WIDTH = 30;
const MAX_WIDTH = 80;
const width = `${Math.floor(Math.random() * (MAX_WIDTH - MIN_WIDTH)) + MIN_WIDTH}%`;
</script>

<template>
  <div class="line-skeleton">
    <BlockSkeleton class="radio-button-skeleton" />
    <BlockSkeleton class="icon-skeleton" />
    <TextSkeleton variant="body-L" class="text-skeleton" />
  </div>
</template>

<style scoped lang="postcss">
.line-skeleton {
  padding: var(--space-base) 0;
  display: flex;
  align-items: center;
  gap: var(--space-base);
  width: v-bind(width);

  .radio-button-skeleton {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  .icon-skeleton {
    width: var(--body-L);
    height: var(--body-L);
  }

  .text-skeleton {
    flex: 1;
  }
}
</style>
