import { config, getSwmTypeFromExtension, gitwrapper, parseSwmFilename } from '@swimm/shared';

// TODO Move me?

// TODO This is the type returned by getRepoTree, maybe extract there?
// note that it specifies that the props are optional when they are in fact required
export interface RepoEntry {
  path: string;
  mode: string;
  type: string;
  sha: string;
  size: number;
  url: string;
}

// TODO enum variant of SWIMM_FILES_TYPES that is badly defined in shared/config.ts
export enum SwimmFileType {
  SWMD = 'swmd',
  PLAYLIST = 'playlist',
  SWM = 'swm',
  SWMD_TEMPLATE = 'template',
  SWM_RULE = 'rule',
}

export async function getSwimmDocumentFiles({
  repoId,
  branch,
  fileExtensions = [config.SWMD_FILE_EXTENSION],
}: {
  repoId: string;
  branch: string;
  fileExtensions?: string[];
}): Promise<RepoEntry[]> {
  const path = config.SWM_FOLDER_IN_REPO;
  const swmFolderTree = (await gitwrapper.getRepoTree({
    repoId,
    treeSha: branch,
    path,
    recursive: true,
    cacheBuster: true, // for renaming and then refreshing
  })) as RepoEntry[];

  return swmFolderTree
    .filter((entry) => entry.path.startsWith(path))
    .filter((entry) => fileExtensions.some((extension) => entry.path.endsWith(extension)))
    .map((entry) => {
      entry.path = `${entry.path}`;
      return entry;
    });
}

export interface SwimmDocumentEntry {
  name: string;
  type: SwimmFileType;
  path: string;
  rule?: {
    name: string;
    type: string;
  };
}

export async function listSwimmDocuments(repoId: string, branch: string): Promise<Record<string, SwimmDocumentEntry>> {
  const swimmFiles = await getSwimmDocumentFiles({
    repoId,
    branch,
    fileExtensions: [config.SWMD_TEMPLATE_FILE_EXTENSION, config.SWMD_FILE_EXTENSION],
  });

  const swimmDocs: Record<string, SwimmDocumentEntry> = {};
  for (const swimmFile of swimmFiles) {
    const parsedSwmFileName = parseSwmFilename(swimmFile.path);
    if (parsedSwmFileName) {
      let record: SwimmDocumentEntry = {
        name: `${parsedSwmFileName.name}.${parsedSwmFileName.swmId}`,
        type: getSwmTypeFromExtension(parsedSwmFileName.extension) as SwimmFileType,
        path: swimmFile.path,
      };
      record = handleSwmWithTheSameSwmId(swimmDocs[parsedSwmFileName.swmId], record);

      swimmDocs[parsedSwmFileName.swmId] = record;
    }
  }
  return swimmDocs;
}

// TODO This can probably be done in a simpler way
/** Handle cases of swm files with the same id, e.g a doc file and its rule file */
function handleSwmWithTheSameSwmId(
  currentSwm: SwimmDocumentEntry | undefined,
  incomingSwm: SwimmDocumentEntry
): SwimmDocumentEntry {
  if (currentSwm != null && incomingSwm.type !== currentSwm.type) {
    // In case one of the swms is a rule file - set it in a rule prop of the other
    if (incomingSwm.type === config.SWIMM_FILE_TYPES.SWM_RULE) {
      return { ...currentSwm, rule: incomingSwm };
    } else if (currentSwm.type === config.SWIMM_FILE_TYPES.SWM_RULE) {
      return { ...incomingSwm, rule: currentSwm };
    }
  }

  return incomingSwm;
}
