import path from 'path-browserify';
import { Mark, Node as ProseMirrorNode } from '@tiptap/pm/model';
import { Transform } from '@tiptap/pm/transform';
import type { Repos } from '@/tiptap/editorServices';
import { UrlUtils } from '@swimm/shared';
import {
  SwmResourceURLPath,
  SwmSymbolLinkType,
  config,
  generateSwmdFileName,
  getSwimmUrlGroups,
  isSwimmDoc,
  isSwimmPlaylist,
} from '@swimm/shared';
import type { Link } from '@swimm/reefui';
import { schema } from './extensions';

export async function swmLinkify(
  filePath: string,
  doc: ProseMirrorNode,
  currentRepoId: string,
  currentBranch: string,
  services: {
    repos: Repos;
    getRepoLinks(repoId: string, branch: string, linkType: SwmSymbolLinkType): Promise<Link[]>;
    getRepoName(repoId: string): string | undefined;
  },
  originalPathToDocId: Map<string, string>
): Promise<ProseMirrorNode> {
  const linkNodes: { mark: Mark; pos: number; nodeSize: number }[] = [];
  const pathNodes: { mark: Mark; pos: number; nodeSize: number }[] = [];

  doc.descendants((node, pos) => {
    for (const mark of node.marks) {
      if (mark.type.name === 'link') {
        if (UrlUtils.isValidURL(mark.attrs.href)) {
          if (isSwimmDoc(mark.attrs.href) || isSwimmPlaylist(mark.attrs.href)) {
            linkNodes.push({ mark, pos, nodeSize: node.nodeSize });
          }
        } else {
          pathNodes.push({ mark, pos, nodeSize: node.nodeSize });
        }
      }
    }
  });

  const tr = new Transform(doc);
  for (const { mark, pos, nodeSize } of linkNodes) {
    const { repoId, resourceId, resourceType } = getSwimmUrlGroups(mark.attrs.href);

    const isCrossRepo = repoId !== currentRepoId;
    const branch = !isCrossRepo
      ? currentBranch
      : services.repos.repos?.find((repo) => repo.id === repoId)?.defaultBranch;

    let linkType: SwmSymbolLinkType;
    switch (resourceType) {
      case SwmResourceURLPath.Docs:
      default:
        linkType = SwmSymbolLinkType.Doc;
        break;
      case SwmResourceURLPath.Playlists:
        linkType = SwmSymbolLinkType.Playlist;
        break;
    }

    let links: Link[];
    if (branch) {
      links = await services.getRepoLinks(repoId, branch, linkType);
    } else {
      links = [];
    }

    const link = links.find((doc) => doc.id === resourceId);
    const repoName = services.getRepoName(repoId);

    if (link == null || repoName == null) {
      continue;
    }

    tr.replaceWith(
      tr.mapping.map(pos),
      tr.mapping.map(pos) + nodeSize,
      schema.node('swmLink', {
        path: `/${config.SWM_FOLDER_IN_REPO}/${generateSwmdFileName(link.id, link.name)}${config.SWMD_FILE_EXTENSION}`,
        docTitle: link.name,
        repoId,
        repoName,
      })
    );
  }

  for (const { mark, pos, nodeSize } of pathNodes) {
    let links: Link[];
    if (currentBranch) {
      links = await services.getRepoLinks(currentRepoId, currentBranch, SwmSymbolLinkType.Doc);
    } else {
      links = [];
    }

    const href = mark.attrs.href;
    const normalizedHref = path.resolve(path.dirname(`/${filePath}`), href);

    const relatedDoc = [...originalPathToDocId.entries()].find(([path]) => normalizedHref === `/${path}`);
    if (relatedDoc == null) {
      continue;
    }

    const link = links.find((doc) => doc.id === relatedDoc[1]);
    const repoName = services.getRepoName(currentRepoId);

    if (link == null || repoName == null) {
      continue;
    }

    tr.replaceWith(
      tr.mapping.map(pos),
      tr.mapping.map(pos) + nodeSize,
      schema.node('swmLink', {
        path: `/${config.SWM_FOLDER_IN_REPO}/${generateSwmdFileName(link.id, link.name)}${config.SWMD_FILE_EXTENSION}`,
        docTitle: link.name,
        repoId: currentRepoId,
        repoName,
      })
    );
  }

  return tr.doc;
}
