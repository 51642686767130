export enum settingsTypes {
  USER = 'user-settings',
  INTEGRATION = 'integrations-settings',
  WORKSPACE = 'workspace-settings',
}

export enum userSettingsTabs {
  INTEGRATIONS = 'integrations',
  NOTIFICATIONS = 'notifications',
  PREFERENCES = 'preferences',
  PROFILE = 'profile',
}

export enum integrationSettingsTabs {
  GITHUB_APP = 'github-app',
  SLACK = 'slack',
  STORAGE = 'storage',
  GENERATIVE_AI = 'generative-ai',
  ASK_SWIMM = 'ask-swimm',
  IMAGE_STORAGE = 'image-storage',
}

export enum workspaceSettingsTabs {
  GENERAL_SETTINGS = 'general-settings',
  MEMBERS = 'users',
  BILLING = 'billing',
  API_KEYS = 'api-keys',
  ADVANCED = 'advanced',
  AI_AND_FEATURE_ACCESS = 'ai-and-feature-access',
  OAUTH = 'oauth',
}

export enum workspaceSettingsBillingTabPhases {
  PLAN_DETAILS = 'plan-details',
  CHANGE_PLAN = 'change-plan',
  PAYMENT = 'payment',
  CHANGE_SUCCESS = 'change-success',
}
