import type { RouteLocationRaw } from 'vue-router';
import { PageRoutesNames } from '@/common/consts';
import { DUMMY_REPO_DEFAULT_BRANCH, DUMMY_REPO_ID, DUMMY_REPO_START_PLAYLIST_ID } from '@swimm/shared';

export enum SuggestionType {
  EXTERNAL_URL = 'external_url',
  INTERNAL_URL = 'internal_url',
  VIDEO = 'video',
  TOUR = 'tour',
}

interface Suggestion {
  key: SUGGESTIONS;
  type: SuggestionType;
  title: string;
  image: string;
  link?: string | RouteLocationRaw;
  videoUrl?: string;
}

export enum SUGGESTIONS {
  Examples = 'Examples',
  NewDoc = 'New Doc',
  PR2Doc = 'PR2Doc',
}

export const SUGGESTIONS_FOR_DUMMY_REPO = [SUGGESTIONS.Examples];
export const HIGHLIGHTED_SUGGESTIONS = [SUGGESTIONS.Examples];

export const suggestions: Array<Suggestion> = [
  {
    key: SUGGESTIONS.Examples,
    type: SuggestionType.INTERNAL_URL,
    title: 'Learn by example',
    image: 'suggestion-learn-by-example.png',
    link: {
      name: PageRoutesNames.PLAYLIST_VIEW,
      params: { repoId: DUMMY_REPO_ID, branch: DUMMY_REPO_DEFAULT_BRANCH, playlistId: DUMMY_REPO_START_PLAYLIST_ID },
    },
  },
  // {
  //   key: SUGGESTIONS.NewDoc,
  //   type: SuggestionType.INTERNAL_URL,
  //   title: 'Generate & improve docs with AI',
  //   image: 'suggestion-generate-with-ai.png',
  //   link: {
  //     name: PageRoutesNames.DOC_NEW,
  //     params: { repoId: DUMMY_REPO_ID, branch: DUMMY_REPO_DEFAULT_BRANCH },
  //   },
  // },
  {
    key: SUGGESTIONS.PR2Doc,
    type: SuggestionType.INTERNAL_URL,
    title: 'Turn your PRs into docs',
    image: 'suggestion-prs-to-docs.png',
    link: {
      name: PageRoutesNames.DOC_NEW,
      params: { repoId: DUMMY_REPO_ID, branch: DUMMY_REPO_DEFAULT_BRANCH },
      query: { action: 'pr', folderId: null },
    },
  },
];
