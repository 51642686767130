<script setup lang="ts">
import { computed, inject, nextTick, onMounted, onUnmounted, ref } from 'vue';
import { SwText } from '@swimm/ui';
import { BaseIcon } from '@swimm/reefui';
import {
  GenerativeAiRequestType,
  type OpenAICallRequest,
  type OpenAICallRequestType,
  productEvents,
} from '@swimm/shared';
import { useNotificationsStore } from '@/modules/notifications';
import { type Editor } from '@tiptap/vue-3';
import { type AnalyticsInterface, getGenerateTextModifiersRequestParams } from '@/index';

const { addNotification } = useNotificationsStore();

const props = defineProps<{
  editor: Editor | undefined;
  workspaceId: string;
  repoId: string;
}>();
const emit = defineEmits(['close-ai-menu']);

const analytics: AnalyticsInterface = inject('analytics') as AnalyticsInterface;

const showMenu = ref(true);
const loading = ref(false);
const inputRef = ref<HTMLInputElement | null>(null);
const currentTask = ref<string | null>(null);
const handleTaskChange = async (value: string) => {
  await nextTick();
  currentTask.value = value;
  generateResponse();
};

const generateTextModifier: OpenAICallRequestType = inject(
  'generateTextModifier',
  async (_requestParams: OpenAICallRequest) => {
    // Call the injected function with the modified parameters
    return { code: 400, status: 'error' };
  }
);

const handleFreeTextChange = (e: Event) => {
  if (e.target) {
    currentTask.value = (e.target as HTMLInputElement).value;
  }
};

onMounted(() => {
  inputRef.value?.focus();
});

const suggestionToPrompt = {
  'Make better': 'Make the text better',
  'Make shorter': 'Make the text shorter',
  'Make longer': 'Make the text longer',
  'Fix spelling & grammar': 'Fix spelling and grammar in the text',
};
const tasksSuggestions = Object.keys(suggestionToPrompt);

const showErrorToast = () => {
  addNotification(`Failed to modify text. Try again.`, {
    icon: 'warning',
  });
};

const getSelectedText = (): { from: number; selectedText: string } | null => {
  if (props.editor) {
    const { from, to, empty } = props.editor.state.selection;
    if (empty) {
      return null;
    }

    return { selectedText: props.editor.state.doc.textBetween(from, to, ' '), from: from };
  }
  return null;
};

const selectedOption = computed(() => {
  return currentTask.value && tasksSuggestions.includes(currentTask.value) ? currentTask.value : null;
});

const isCustom = computed(() => {
  return selectedOption.value === null;
});

const customPrompt = computed(() => {
  return isCustom.value ? currentTask.value : null;
});

const prompt = computed(() => {
  return isCustom.value ? customPrompt.value : suggestionToPrompt[currentTask.value as keyof typeof suggestionToPrompt];
});

async function generateResponse() {
  if (!props.workspaceId) {
    showErrorToast();
    return;
  }

  const response = getSelectedText();
  if (response) {
    const { selectedText, from } = response;
    analytics.track(productEvents.SENT_IMPROVE_WITH_AI_REQUEST, {
      'Custom Prompt': customPrompt.value,
      'Selected Auto-Complete Option': selectedOption.value,
      'Text Selection Length': selectedText.length,
    });

    loading.value = true;
    const start = performance.now();
    const callParams = getGenerateTextModifiersRequestParams({
      repoId: props.repoId,
      workspaceId: props.workspaceId,
      textInput: selectedText,
      modifier: prompt.value || '',
      type: GenerativeAiRequestType.GENERATE_TEXT_MODIFIER,
    });
    const { generatedText } = await generateTextModifier(callParams);

    const timeTaken = performance.now() - start;

    if (generatedText) {
      if (loading.value) {
        analytics.track(productEvents.GENERATED_IMPROVE_WITH_AI_RESULT, {
          'Total Runtime MS': timeTaken,
          'Result Length': generatedText.length,
          'Text Selection Length': selectedText.length,
          'Custom Prompt': customPrompt.value,
          'Selected Auto-Complete Option': selectedOption.value,
        });
        emit('close-ai-menu', () => {
          props.editor?.chain().focus().insertContent(generatedText).run();
          props.editor?.commands.setTextSelection({ from: from, to: from + generatedText.length });
          loading.value = false;
        });
      }
    } else {
      loading.value = false;
      showErrorToast();
    }
  }
}
async function onOpen(value: string) {
  await nextTick();
  currentTask.value = value;
}

const showTitle = computed(() => {
  return (
    tasksSuggestions.filter((task) => {
      return currentTask.value ? task.toLowerCase().includes(currentTask.value.toLowerCase()) : true;
    }).length > 0
  );
});

const stop = () => {
  analytics.track(productEvents.ABORTED_GEN_AI_CONTENT_REQUEST, { Context: 'Improve with AI' });
  showMenu.value = false;
  loading.value = false;
};

const onKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Escape' && showMenu.value) {
    stop();
  }
};
document.addEventListener('keydown', onKeyDown);
onUnmounted(() => document.removeEventListener('keydown', onKeyDown));
</script>
<template>
  <div class="ai-container" @keyup.enter="generateResponse">
    <BaseIcon name="magic" />
    <div class="title-container">
      <SwText class="generating-title" variant="subtitle-S">Improve with AI</SwText>
    </div>
    <v-select
      v-if="!loading"
      class="suggestions"
      :clearable="false"
      :model-value="currentTask"
      @search="onOpen"
      :options="tasksSuggestions"
      uid="ai-tasks-suggestions-dropdown"
      placeholder="Make it..."
      @update:model-value="handleTaskChange"
    >
      <template #search="{ attributes, events }">
        <input v-bind="attributes" class="vs__search" ref="inputRef" v-on="events" @keydown="handleFreeTextChange" />
        <Icon name="enter" class="key" />
      </template>
      <template #list-header v-if="showTitle">
        <header class="list-header">
          <SwText variant="subtitle-S" weight="bold">Suggestions</SwText>
        </header>
      </template>
      <template #no-options>
        <span>Enter to improve with custom prompt</span>
      </template>
      <template #option="{ label }">
        <div class="option">
          {{ label }}
        </div>
      </template>
    </v-select>
    <div v-else class="skeleton-container">
      <div class="key-label-pair">
        <div class="stop-link" @click="stop">Stop suggestion</div>
        <span class="key">esc</span>
      </div>
      <Loader secondary small />
    </div>
  </div>
</template>
<style scoped lang="postcss">
.loader {
  --loader-size: var(--loader-size);
}

.generate-menu {
  margin-top: var(--space-sm);
  border-radius: var(--space-base);
  box-sizing: border-box;
  visibility: visible;
}

.generating-title {
  color: var(--text-color-secondary);
  padding: 0px;
  line-height: 1rem;
  font-size: var(--body-XXS);
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.ai-container {
  width: 400px;
  display: flex;
  flex-direction: row;
  gap: 2px;
  flex-shrink: 0;
  align-items: center;
}

/* eslint-disable vue-scoped-css/no-unused-selector */
.icon.icon-magic {
  font-size: var(--body-L);
  color: var(--text-color-magic-strong);
}

.beta-tag {
  border-radius: 80px;
  font-weight: 400;
  color: var(--high-violet);
  background-color: var(--color-status-magic);
  font-size: var(--body-XXS);
  padding: 0 var(--space-xs);
}

.suggestions {
  margin-left: var(--space-base);
  flex-grow: 1;
}

.suggestions :deep(.vs__open-indicator) {
  fill: var(--text-color-secondary);
}

.suggestions :deep(.vs__dropdown-menu) {
  cursor: pointer;
}

.suggestions :deep(.vs__open) {
  cursor: text;
}

.key {
  cursor: default;
  background-color: var(--color-surface);
  border-radius: 2px;
  font-size: var(--body-S);
  align-self: self-end;
}

.list-header {
  margin-left: 12px;
}

.text-skeleton {
  margin-top: var(--space-base);
  width: 380px;
}

.skeleton-container {
  align-items: center;
  background-color: var(--color-surface);
  padding: 0 var(--space-base);
  color: var(--text-color-secondary);
  gap: var(--space-base);
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin-left: var(--space-base);
}

.key-label-pair {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-grow: 1;
  gap: var(--space-xs);

  .key {
    background-color: var(--color-hover);
    border-radius: 5px;
    font-size: var(--body-XS);
    padding: 2px var(--space-xs);
  }
}

.stop-link {
  cursor: pointer;
  color: var(--text-color-link);
}
</style>
