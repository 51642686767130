<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserConfigStore } from '../stores/user-config-store';

const { theme } = storeToRefs(useUserConfigStore());

const props = defineProps({
  title: { type: String, required: true },
  image: { type: String, required: true },
});

const imageUrl = computed(() => {
  return new URL(`./assets/${props.image}-${theme.value}.png`, import.meta.url).href;
});
</script>

<template>
  <div class="wrapper">
    <div class="header">{{ title }}</div>
    <img class="image" :src="imageUrl" />
  </div>
</template>

<style scoped lang="postcss">
.wrapper {
  padding: 56px;
  flex: 1;

  .header {
    margin-bottom: 8px;
    font-size: var(--fontsize-xl);
    font-weight: 600;
  }

  .image {
    width: 100%;
  }
}
</style>
