<script lang="ts">
export const HeadingLevels = ['mega', 'title', 1, 2, 3, 4, 5, 6] as const;
export type HeadingLevelsType = (typeof HeadingLevels)[number];
</script>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    level?: HeadingLevelsType;
    semanticLevel?: HeadingLevelsType;
  }>(),
  {
    level: 2,
    semanticLevel: undefined,
  }
);

const computedComponent = computed(() => {
  if (['mega', 'title'].includes(props.level as string)) {
    if (props.semanticLevel) {
      return `h${props.semanticLevel}`;
    }

    return 'h1';
  }

  const level = props.semanticLevel || props.level;

  return `h${Math.min(level as number, 6)}`;
});

const computedClasses = computed(() => ({
  [`heading--${props.level}`]: true,
}));
</script>

<template>
  <component :is="computedComponent" class="heading" :class="computedClasses">
    <slot />
  </component>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.heading {
  $self: &;

  @include defaults;

  line-height: var(--line-height-heading);

  &--mega {
    font-size: var(--font-size-mega);
    font-weight: var(--font-weight-bolder);
  }

  &--title {
    font-size: var(--font-size-xxlarge);
    font-weight: var(--font-weight-bolder);
  }

  &--1 {
    font-size: var(--font-size-xlarge);
    font-weight: var(--font-weight-bolder);
  }

  &--2 {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bolder);
  }

  &--3 {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bolder);
  }

  &--4 {
    font-size: var(--font-size-default);
    font-weight: var(--font-weight-bolder);
  }

  &--5 {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-bolder);
  }

  &--6 {
    font-size: var(--font-size-xsmall);
    font-weight: var(--font-weight-bolder);
  }
}
</style>
