<script setup>
import { SwModal } from '@swimm/ui';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useBillingStore } from '@/modules/billing/store/billing';
import { CALENDLY_SALES_LINK } from '@/config';
import { productEvents } from '@swimm/shared';
import { useAnalytics } from '@/common/composables/useAnalytics';

const store = useStore();
const route = useRoute();
const { setShowBillingPlans } = useBillingStore();
const analytics = useAnalytics();

const emit = defineEmits(['upgrade', 'close']);

defineProps({
  show: { type: Boolean, default: false },
});

const expiredUsers = computed(() => {
  const users = store.getters['database/db_getWorkspaceUsers'](route.params.workspaceId);
  return Object.values(users)
    .filter((user) => !!user.expired)
    .map((user) => {
      return {
        name: user.nickname || user.name,
        email: user.email,
      };
    });
});

const expiredRepos = computed(() => {
  const expiredRepoIds = store.getters['database/db_getWorkspaceExpiredRepoIds'](route.params.workspaceId);
  const allRepos = store.state.database.repositories;
  return Object.keys(allRepos)
    .filter((repoId) => expiredRepoIds.includes(repoId))
    .map((repoId) => allRepos[repoId].metadata);
});

function onClose() {
  emit('close');
}

function moveToUpgrade() {
  setShowBillingPlans(true);
  onClose();
}

function openSalesCalendly() {
  analytics.track(productEvents.CLICKED_CONTACT_SALES, {
    Context: 'Trial Ended',
    'Workspace ID': route.params.workspaceId,
  });
  window.open(CALENDLY_SALES_LINK, '_blank');
  onClose();
}
</script>

<template>
  <SwModal :show-modal="show" heading="Your free trial has ended" @close="onClose">
    <div class="content">
      <SwText>Your 14-day trial period has ended, and you have been moved to Swimm's Free plan.</SwText>
      <template v-if="expiredUsers.length || expiredRepos.length">
        <SwText class="changes-title">The following changes have also been made to your workspace:</SwText>
        <template v-if="expiredUsers.length">
          <SwText variant="subtitle-XL">Disabled users</SwText>
          <ul>
            <li v-for="user in expiredUsers" :key="user.email">
              <SwText>{{ user.name }} ({{ user.email }})</SwText>
            </li>
          </ul>
        </template>
        <template v-if="expiredRepos.length">
          <SwText variant="subtitle-XL">Disabled private repos</SwText>
          <ul>
            <li v-for="repo in expiredRepos" :key="repo.id">
              <SwText>{{ repo.name }}</SwText>
            </li>
          </ul>
        </template>
      </template>
      <SwText>To continue with Swimm's Small teams plan, upgrade now by clicking the button below.</SwText>
      <div class="actions">
        <Action @click="moveToUpgrade">Upgrade</Action>
        <Action secondary @click="openSalesCalendly">Contact sales</Action>
      </div>
    </div>
  </SwModal>
</template>

<style scoped lang="postcss">
.content {
  max-width: 480px;

  .actions {
    margin-top: var(--space-md);
  }

  .actions > * {
    margin-right: var(--space-base);
  }

  .changes-title {
    margin-top: var(--space-base);
  }
}
</style>
