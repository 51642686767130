<script setup lang="ts">
import { useScroll } from '@swimm/editor';
import FolderNameEdit from './FolderNameEdit.vue';
import FolderTreeNode from './FolderTreeNode.vue';
import { Icon, SwText } from '@swimm/ui';
import { computed, nextTick, onMounted, provide, ref } from 'vue';
import FolderTreeActions from './FolderTreeActions.vue';
// @ts-ignore
import path from 'path-browserify';
import { DocumentationTypes } from '@/common/consts';
import { isRepoIdDummyRepo } from '@swimm/shared';
import { useRoute } from 'vue-router';
import type { Folder, ToggleableTreeNode, TreeNode } from '../types';
import { TextField } from '@swimm/ui';
import { useFoldersStore } from '../store/folders';

const props = defineProps<{
  selectedPath: string;
  tree: TreeNode;
  items: { name?: string; documentationType: (typeof DocumentationTypes)[keyof typeof DocumentationTypes] }[];
  rootFolder?: Folder;
}>();

const foldersStore = useFoldersStore();

const { getFolder, getFolderPath } = foldersStore;

const emit = defineEmits<{
  'node-select': [selectedNode: string, query: string];
  'new-folder-create': [parentFolderId: string];
  'new-folder-click': [parentFolderId: string];
  filter: [query: string];
}>();
provide('treeActions', { handleNewFolderClick, handleNewFolderCreate });

const { scrollToElement } = useScroll();
const route = useRoute();

const showAddFolder = ref(false);
const currentSelectedNode = ref<ToggleableTreeNode | null>(null);
const currentChildIndex = ref<number | null>(null);
const currentRoot = ref<TreeNode | null>(null);
const forceOpen = ref(false);
const filterQuery = ref('');

const repoId = computed(() => route.params.repoId as string);
const isDummyRepo = computed(() => isRepoIdDummyRepo(repoId.value));

onMounted(() => {
  resetSelection();
  if (props.selectedPath) {
    currentSelectedNode.value = getNodeFromPath(props.selectedPath) as ToggleableTreeNode;
    currentRoot.value = getCurrentNodeParent(props.selectedPath);
    currentChildIndex.value =
      currentSelectedNode.value && currentRoot.value && getNodeIndex(currentSelectedNode.value, currentRoot.value);
    forceOpen.value = true;
  }
});

function resetSelection() {
  if (props.tree) {
    currentSelectedNode.value = createSortedTreeNode(props.tree)?.children?.[0] as ToggleableTreeNode;
    currentChildIndex.value = 0;
    currentRoot.value = createSortedTreeNode(props.tree);
  }
}

function createSortedTreeNode(node: TreeNode): TreeNode {
  if (node?.children) {
    const newChildren = [...node.children].sort((a, b) => {
      if (a.type === b.type) {
        return a.path < b.path ? -1 : 1;
      }
      return a.type === 'directory' ? -1 : 1;
    });
    node.children = newChildren;
  }
  return node;
}

// TODO: Why is this recursive? We have the path, just use the path without the last part...
function getCurrentNodeParent(pathToFind: string, currentNode: TreeNode | null = null): TreeNode | null {
  if (!currentNode) {
    currentNode = props.tree;
  }

  if (currentNode.children.find((node) => node.path === pathToFind)) {
    return createSortedTreeNode(currentNode);
  }

  // recursively search for the parent in the current node's children
  for (const child of currentNode.children) {
    const parent = getCurrentNodeParent(pathToFind, child);
    if (parent) {
      return parent;
    }
  }

  return null;
}

// TODO: Why is this recursive? We have the path...
function getNodeFromPath(currentPath: string, currentNode: TreeNode | null = null): TreeNode | null {
  if (!currentNode) {
    currentNode = props.tree;
  }
  if (currentNode.path === currentPath) {
    return currentNode;
  }

  if (currentNode.type === 'directory') {
    for (const child of currentNode.children) {
      const node = getNodeFromPath(currentPath, child);
      if (node) {
        return node;
      }
    }
  }

  return null;
}

function getNodeIndex(node: TreeNode, root: TreeNode): number {
  return root.children.findIndex((currentNode) => currentNode.path === node.path);
}

function onSelect() {
  if (currentSelectedNode.value == null) {
    return;
  }
  emit('node-select', currentSelectedNode.value.id, filterQuery.value);
}

function nodeToggle({ node, open }: { node: ToggleableTreeNode; open: boolean }) {
  node.closed = !open;
}

function nodeSelected(nodeClicked: ToggleableTreeNode) {
  currentSelectedNode.value = nodeClicked;
  currentSelectedNode.value.closed = !nodeClicked.closed;
  currentRoot.value = getCurrentNodeParent(currentSelectedNode.value.path);
  currentChildIndex.value = currentRoot.value && getNodeIndex(currentSelectedNode.value, currentRoot.value);
  forceOpen.value = !forceOpen.value;
  scrollToSelectedNode();
}

function scrollToSelectedNode() {
  if (currentSelectedNode.value) {
    const node = currentSelectedNode.value;
    nextTick(() => scrollToElement(node.path));
  }
}

function handleNewFolderClick(parentFolderId: string, childFolderId?: string) {
  if (isDummyRepo.value) {
    return;
  }

  if (childFolderId) {
    createAndSelectNodeFolder(childFolderId);
  }

  emit('new-folder-click', parentFolderId);
}

function createAndSelectNodeFolder(childFolderId: string) {
  const getFolderNode = getFolder(childFolderId, repoId.value);
  const childPath = getFolderPath(childFolderId, repoId.value);
  const node: ToggleableTreeNode = {
    id: getFolderNode.id,
    name: getFolderNode.name,
    children: [],
    type: 'directory',
    path: childPath,
    closed: true,
  };
  forceOpen.value = true;
  nodeSelected(node);
  scrollToSelectedNode();
}

function handleRootNewFolderClick() {
  if (isDummyRepo.value) {
    return;
  }
  showAddFolder.value = true;
  handleNewFolderClick(props.rootFolder?.id ?? '');
}

function handleRootNewFolderCreate(parentFolderId: string, childFolderId: string) {
  showAddFolder.value = false;
  handleNewFolderClick(parentFolderId, childFolderId);
}

function handleNewFolderCreate(parentFolderId: string) {
  showAddFolder.value = false;
  emit('new-folder-create', parentFolderId);
}

const disableReason = computed(() => {
  if (currentSelectedNode.value?.path === props.selectedPath) {
    return 'Selected the current folder, please choose a different folder.';
  }

  const isMovingToAnySubfolder = props.items.some((item) => {
    const fullItemPath = path.join(props.selectedPath, item.name ?? 'Untitled');
    const fullItemPathWithExtraPathDivider = path.join(fullItemPath, path.sep);
    const isMovingFolder = item.documentationType === DocumentationTypes.FOLDER;
    return (
      (isMovingFolder && currentSelectedNode.value?.path === fullItemPath) ||
      currentSelectedNode.value?.path.includes(fullItemPathWithExtraPathDivider)
    );
  });

  if (isMovingToAnySubfolder) {
    return 'Cannot move folder into itself. Please select another destination.';
  }
  return undefined;
});

const filteredTree = computed<ToggleableTreeNode | null>(() => {
  return filterTree(props.tree, filterQuery.value) as ToggleableTreeNode | null;
});

function filterTree(tree: TreeNode, query: string): TreeNode | null {
  function filterNode(node: TreeNode) {
    const filteredNode: TreeNode = {
      ...node,
      children: [],
    };

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        const filteredChild = filterNode(child);
        if (filteredChild) {
          filteredNode.children.push(filteredChild);
        }
      });
    }

    if (filteredNode.name.toLowerCase().includes(query.toLowerCase()) || filteredNode.children.length > 0) {
      return filteredNode;
    }

    return null;
  }

  return filterNode(tree);
}
</script>

<template>
  <div class="folder-tree" :tabindex="0">
    <div class="tree-container data-hj-suppress">
      <div class="nodes">
        <TextField
          class="search-field"
          v-model="filterQuery"
          placeholder="Search..."
          @update:model-value="$emit('filter', filterQuery)"
        />
        <div class="nodes-container">
          <FolderTreeNode
            v-if="filteredTree"
            :node="filteredTree"
            :repo-id="repoId"
            :path="filteredTree.path"
            :is-root="true"
            :selected-path="currentSelectedNode?.path"
            @node-selected="nodeSelected"
            @node-toggle="nodeToggle"
            @new-child-node-created="createAndSelectNodeFolder"
            :force-open="forceOpen"
            :expand-all="Boolean(filterQuery)"
            :depth="0"
          />
          <div v-else class="filter-empty-state">No results</div>
        </div>
        <div class="add-section">
          <VTooltip v-if="!showAddFolder" popper-class="tooltip-on-modal" :disabled="!isDummyRepo" placement="top">
            <span class="new-folder-button" :class="{ disabled: isDummyRepo }" @click="handleRootNewFolderClick">
              <Icon name="add" class="icon"></Icon>New folder
            </span>
            <template #popper>
              <SwText :variant="'body-S'"
                >Folders are disabled in the demo repo. To create folders, connect your own repository.</SwText
              >
            </template>
          </VTooltip>
          <FolderNameEdit
            v-else
            show-icon
            show-error-below
            :repo-id="repoId"
            @change="handleRootNewFolderCreate"
            @cancel="showAddFolder = false"
            :parent-id="rootFolder?.id"
          />
        </div>
      </div>
    </div>
    <FolderTreeActions @select="onSelect" :is-disabled="!!disableReason" :disabled-reason="disableReason" />
  </div>
</template>

<style scoped lang="postcss">
.folder-tree {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: none;

  &:focus {
    outline: none;
  }

  .search-field {
    margin: var(--space-base);
  }
}

.tree-container {
  overflow: hidden;
  height: 100%;
  flex: 1;
}

.nodes {
  overflow-x: auto;
  width: 100%;
  min-width: 0;
  height: 100%;
  white-space: nowrap;

  .nodes-container {
    overflow-y: hidden;
    margin-bottom: var(--space-xs);
  }
}

.new-folder-button {
  margin-left: var(--space-base);
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  padding: var(--space-xs) var(--space-base);
  cursor: pointer;

  &:hover {
    background-color: var(--color-hover);
  }

  .icon {
    padding: 0;
    margin-right: var(--space-xs);
    font-size: var(--body-S);
  }
}

.disabled {
  color: var(--text-color-disable);
  background: var(--color-bg);
  cursor: not-allowed;
}

.add-section {
  padding: var(--space-base);
  background: linear-gradient(180deg, transparent 0%, var(--color-bg) 40%);
  position: sticky;
  bottom: 0;
}

.filter-empty-state {
  margin: var(--space-base) var(--space-md);
}
</style>
