import * as firestore from '@/adapters-common/firestore-wrapper';
import * as firestoreWrapper from '@/adapters-common/firestore-wrapper';
import { UNKNOWN_USER_NAME } from '@/common/consts';
import { REPO_FILTERS_SORT_OPTIONS } from '@/common/utils/common-definitions';
import type { NotificationDbItemInterface } from '@swimm/shared';
import { NOTIFICATION_TYPES, config, defaultNotificationValues, getLoggerNew } from '@swimm/shared';
import type { FieldValue, Timestamp } from 'firebase/firestore';
import * as _ from 'lodash-es';

const logger = getLoggerNew(__modulename);

export interface DocRequestInterface {
  id?: string;
  is_deleted?: boolean;
  created?: FieldValue | Timestamp;
  creator?: string;
  creator_name?: string;
  modified: FieldValue | Timestamp;
  modifier: string;
  modifier_name: string;
  requested_from_email: string;
  requested_from: string | undefined | null;
  requested_from_name: string | undefined | null;
  title: string;
  description: string;

  completed?: Timestamp | null;
  completer?: string | null;
  completer_name?: string | null;
}

function getSortKeyOrder(sortBy: string): { key; order: 'asc' | 'desc' } {
  if (sortBy === REPO_FILTERS_SORT_OPTIONS.NAME) {
    return { key: (u) => u.title, order: 'asc' };
  }
  return { key: (u) => u.created.seconds, order: 'desc' };
}

export function getValidDocRequestsSorted(docRequestsObject: object, { sortBy }: { sortBy: string | null }) {
  sortBy = sortBy || REPO_FILTERS_SORT_OPTIONS.LAST_MODIFIED;
  const docRequests = Object.values(docRequestsObject).filter((d) => !d.is_deleted);
  const { key, order } = getSortKeyOrder(sortBy);
  return _.orderBy(docRequests, [key], [order]);
}

interface UserInterface {
  uid: string;
  name?: string;
  nickname?: string;
  email: string;
}

export async function markDocRequestAsDeleted({
  repoId,
  byUser,
  docRequestId,
}: {
  repoId: string;
  byUser: UserInterface;
  docRequestId: string;
}) {
  const updateData = {
    modified: firestore.firestoreTimestamp(),
    modifier: byUser.uid,
    modifier_name: byUser.nickname || byUser.name || UNKNOWN_USER_NAME,
    is_deleted: true,
  };
  return await updateOrCreateInDb({ repoId, docRequestId, isUpdate: true, data: updateData });
}

export async function markDocRequestAsCompleted({
  repoId,
  byUser,
  docRequestId,
}: {
  repoId: string;
  byUser: UserInterface;
  docRequestId: string;
}) {
  const updateData = {
    completed: firestore.firestoreTimestamp(),
    completer: byUser.uid,
    completer_name: byUser.nickname || byUser.name || UNKNOWN_USER_NAME,
  };
  return await updateOrCreateInDb({ repoId, docRequestId, isUpdate: true, data: updateData });
}

export async function createOrUpdateDocRequest({
  docRequestId,
  isUpdate,
  repoId,
  requestedFrom,
  title,
  byUser,
  description,
}: {
  docRequestId: string;
  isUpdate: boolean;
  repoId: string;
  requestedFrom: {
    email: string;
    uid: string | null;
    name: string;
  };
  byUser: UserInterface;
  title: string;
  description: string;
}): Promise<DocRequestInterface> {
  const timestamp = firestore.firestoreTimestamp();
  const createFields = isUpdate
    ? {}
    : {
        created: timestamp,
        creator: byUser.uid,
        creator_name: byUser.nickname || byUser.name || UNKNOWN_USER_NAME,
        completed: null,
        completer: null,
        completer_name: null,
        is_deleted: false,
        id: docRequestId,
      };
  const docRequest: DocRequestInterface = {
    modified: timestamp,
    modifier: byUser.uid,
    modifier_name: byUser.nickname || byUser.name || UNKNOWN_USER_NAME,
    requested_from_email: requestedFrom.email,
    requested_from: requestedFrom.uid,
    requested_from_name: requestedFrom.name,
    title: title,
    description: description,
    ...createFields,
  };
  return updateOrCreateInDb({ repoId, docRequestId, data: docRequest, isUpdate: isUpdate });
}

async function updateOrCreateInDb({
  repoId,
  docRequestId,
  data,
  isUpdate,
}: {
  repoId: string;
  docRequestId: string;
  data: object;
  isUpdate: boolean;
}): Promise<DocRequestInterface> {
  const collectionRef = firestore.getSubCollectionRefRecursive(
    [firestore.collectionNames.REPOSITORIES, firestore.collectionNames.DOC_REQUESTS],
    [repoId]
  );
  if (isUpdate) {
    await collectionRef.doc(docRequestId).update(data);
  } else {
    await collectionRef.doc(docRequestId).set(data);
  }
  const docRef = await collectionRef.doc(docRequestId).get();
  return docRef.data() as DocRequestInterface;
}

export async function createDocRequestNotification({
  docRequest,
  workspaceId,
  repoId,
  type,
  url,
}: {
  docRequest: DocRequestInterface;
  repoId: string;
  workspaceId: string;
  type: string;
  url: string;
}) {
  if (type !== NOTIFICATION_TYPES.DOC_REQUEST_CREATED && type !== NOTIFICATION_TYPES.DOC_REQUEST_COMPLETED) {
    throw new Error(`Illegal type ${type}`);
  }
  logger.info(`Creating doc request notification for ${docRequest.id} of type ${type} ${repoId} ${workspaceId}`);
  const isCompletion = type === NOTIFICATION_TYPES.DOC_REQUEST_COMPLETED;
  const templateData = {
    nickname: isCompletion ? docRequest.completer_name : docRequest.creator_name,
    btn_url: url,
    doc_request_name: docRequest.title,
    doc_request_description: docRequest.description,
    workspace_id: workspaceId,
  };
  const notification: NotificationDbItemInterface = {
    ...defaultNotificationValues,
    created_at: firestoreWrapper.firestoreTimestamp(),
    action_url: url,
    notifier_id: isCompletion ? docRequest.completer : docRequest.creator,
    notifier_name: isCompletion ? docRequest.completer_name : docRequest.creator_name,
    notifier_type: 'user',
    recipient_email: '',
    recipient_id: isCompletion ? docRequest.creator : docRequest.requested_from,
    topic_id: docRequest.id,
    topic_name: docRequest.title,
    topic_type: 'doc',
    type: type,
    reason: '',
    note: '',
    email_template_data: templateData,
  };
  const notificationRef = await firestoreWrapper.addDocToCollection(
    firestoreWrapper.collectionNames.NOTIFICATIONS,
    notification
  );
  if (notificationRef.code === config.SUCCESS_RETURN_CODE) {
    logger.debug(`Notification ${notificationRef.data.id} created`);
  }
}
