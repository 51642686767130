<script setup lang="ts">
import { computed } from 'vue';
import type { Editor } from '@tiptap/vue-3';
import { SwmSelectionContentVideo } from '@swimm/reefui';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';

const props = defineProps<{
  editor: Editor;
  hide: () => void;
}>();

const swimmEditorServices = computed(() => getSwimmEditorServices(props.editor));

function onSelect(link: string) {
  swimmEditorServices.value.swmYouTubeSelection.value = link;
  props.hide();
}
</script>

<template>
  <SwmSelectionContentVideo data-testid="youtube-popover" @select="onSelect" />
</template>
