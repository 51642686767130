<script setup lang="ts">
import { computed } from 'vue';
import { BaseButton } from '@swimm/reefui';
import { ApplicabilityStatus, type SmartElementWithApplicability, type Snippet } from '@swimm/shared';
import { Action } from '@swimm/ui';

type HunkApplicability = SmartElementWithApplicability<Snippet>['applicability'] | ApplicabilityStatus.Unknown;

const props = defineProps<{
  isAutoSyncable: boolean;
  isAdmin: boolean;
  hunkApplicability: HunkApplicability;
  isIde: boolean;
  autosyncedIncludesCodeChanges: boolean;
  splitSnippetMode: boolean;
}>();

const emit = defineEmits<{
  (e: 'configure-autosync'): void;
  (e: 'edit-hunk', context: string): void;
  (e: 'discard-hunk'): void;
  (e: 'accept-hunk'): void;
  (e: 'close-split-mode'): void;
}>();

function editHunk(context: string) {
  emit('edit-hunk', context);
}

function discardHunk() {
  emit('discard-hunk');
}

function acceptHunk() {
  emit('accept-hunk');
}

function closeSplitMode() {
  emit('close-split-mode');
}

const replaceHunkBoxText = computed(() => {
  if (props.isIde) {
    return '';
  }
  const suffix = props.autosyncedIncludesCodeChanges ? 'Please select one of the following options.' : '';
  return `${
    props.hunkApplicability === ApplicabilityStatus.Autosyncable
      ? 'Committing is disabled because this snippet was Auto-synced by Swimm.'
      : 'Committing is disabled because this snippet is outdated.'
  } ${suffix}`;
});
</script>

<template>
  <div class="replace-hunk-box" :class="{ 'autosynced-box': isAutoSyncable }">
    <div class="box">
      <div class="box-label body-XS">
        <span v-if="splitSnippetMode" class="subtitle-S bold"> Pick split point above </span>
        <span v-else-if="isAutoSyncable" class="subtitle-S">
          <span class="bold">Auto-synced by Swimm</span>
        </span>
        <span v-else>
          <span class="subtitle-S bold">Outdated </span>
          <span>Select an action to continue</span>
        </span>
      </div>
      <div v-if="splitSnippetMode">
        <div class="replace-hunk-box-actions">
          <BaseButton size="small" variant="tertiary" data-testid="close-split-mode-button" @click="closeSplitMode()"
            >Cancel</BaseButton
          >
        </div>
      </div>
      <div v-else-if="!isAutoSyncable">
        <div class="replace-hunk-box-actions">
          <Action size="small" data-testid="outdated-reselect" @click="editHunk(hunkApplicability)">Reselect</Action>
          <Action size="small" data-testid="outdated-remove" secondary @click="discardHunk">Remove</Action>
        </div>
      </div>
      <div v-else>
        <div v-tooltip="replaceHunkBoxText" class="replace-hunk-autosync-actions">
          <Action
            v-if="autosyncedIncludesCodeChanges"
            data-testid="autosync-reselect"
            size="small"
            secondary
            @click="editHunk(hunkApplicability)"
            >Reselect</Action
          >
          <Action data-testid="autosync-accept" size="small" @click="acceptHunk">Accept</Action>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  width: 100%;
  border-radius: 0 0 8px 8px;
  background-color: var(--color-surface);
}

.replace-hunk-box {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 8px 8px;
  background-color: rgba(0, 0, 0, 0.3);
}

.replace-hunk-box-actions {
  display: flex;
  justify-content: space-between;
}

.replace-hunk-autosync-actions {
  display: flex;
  justify-content: space-between;
}

.replace-hunk-box-actions .button:first-of-type,
.replace-hunk-autosync-actions .button:first-of-type {
  margin-right: 10px;
}

.configure-autosync-link {
  color: var(--text-color-link);
  cursor: pointer;
}
</style>
