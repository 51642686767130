// https://en.wikipedia.org/wiki/Depth-first_search
export function* dfsTraverse<T>(
  root: T,
  traversal: (node: T) => T[]
): Iterable<{ node: T; predecessor?: T; path: T[]; skipChildren: () => void }> {
  const stack: { node: T; path: T[] }[] = [{ node: root, path: [] }];
  while (stack.length > 0) {
    const { node, path } = stack.pop();
    let skipChildren = false;
    yield { node, path, predecessor: path[path.length - 1], skipChildren: () => (skipChildren = true) };
    if (skipChildren) {
      continue;
    }
    const children = traversal(node);
    if (children) {
      stack.push(
        ...children
          .filter((child) => !path.includes(child))
          .map((child) => ({ node: child, path: [...path, node] }))
          .reverse() // Insert them in reverse order so that first returned child is traversed first
      );
    }
  }
}
