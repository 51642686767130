<template>
  <div class="repo-content" data-testid="repo-content">
    <section class="ribbons" v-if="!loading && ribbonContents.length">
      <Ribbon
        v-for="ribbonContent in ribbonContents"
        :key="ribbonContent.title"
        :icon-name="ribbonContent.icon"
        :emoji-name="ribbonContent.emoji"
        :ribbon-title="ribbonContent.title"
        :ribbon-description="ribbonContent.description"
        :mode="ribbonContent.mode"
        :spinning-icon="ribbonContent.spinningIcon"
      >
        <Action v-if="ribbonContent.callToAction" size="small" @click="ribbonContent.action()">{{
          ribbonContent.callToAction
        }}</Action>
      </Ribbon>
    </section>
    <DocumentationSection
      v-if="showDocumentationList"
      :repo="repo"
      :filtered-documentation="filteredDocumentationWithKey"
      :all-documentation="allDocumentationWithKey"
      data-testid="repo-docs"
      :loading="loading"
      :changed-files="changedFiles"
      :sort-option="sortOption"
      :is-sort-desc="isSortDesc"
      @navigate-to-item="$emit('navigate-to-item', $event)"
      @toggle-creation-hub="$emit('toggle-creation-hub', $event)"
      @sort-changed="$emit('sort-changed', $event)"
    >
      <template v-if="currentFolder && !currentFolder.is_root" #empty-state>
        <SwText variant="body-S" class="empty-folder-state" data-testid="empty-folder-message">{{
          emptyFolderMessage
        }}</SwText>
      </template>
    </DocumentationSection>
    <template v-else-if="!loading && canModifyResources">
      <EmptyState
        data-testid="repo-empty-state"
        title="No docs & playlists"
        description="Start creating docs and playlists in Swimm"
        transparent
      >
        <div class="empty-state-actions">
          <Action class="create-button" secondary @click="onCreateDocClick"> Get started </Action>
          <Action class="create-button" @click="onImportClick">
            <Icon name="import" no-padding />
            Import
          </Action>
        </div>
      </EmptyState>
    </template>
  </div>
</template>

<script>
import { useAppLinks } from '@/modules/core/compositions/app-links';
import { useFiltersStore } from '@/modules/core/filters-row/useFiltersStore';
import { DocumentationTypes, RepoPageDocumentationSectionTypes } from '@/common/consts';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';
import { mapGetters } from 'vuex';
import DocumentationSection from '@/common/components/organisms/DocumentationSection.vue';
import { storeToRefs } from 'pinia';
import { useNavigate } from '@/common/composables/navigate';
import { CreationHubSections } from '@/modules/core/creation-hub/CreationHubConsts';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { computed } from 'vue';

const Entity = {
  DOC: 'doc',
  PLAYLIST: 'playlist',
};

export default {
  components: {
    DocumentationSection,
  },
  props: {
    workspaceId: { type: String, required: true },
    repo: { type: Object, required: true },
    allItems: { type: Array, required: true },
    filteredItems: { type: Array, required: true },
    ribbonContents: {
      type: Array,
      default: () => [],
    },
    loading: { type: Boolean, required: true },
    changedFiles: { type: Array, default: () => [] },
    pendingDocs: { type: Array, required: true },
    pendingPlaylists: { type: Array, required: true },
    sortOption: { type: String, default: null },
    isSortDesc: { type: Boolean, default: true },
  },
  emits: ['toggle-creation-hub', 'navigate-to-item', 'sort-changed'],
  setup(props) {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const { openSwimmApp } = useAppLinks();
    const filtersStore = useFiltersStore();
    const { navigateToPageAndTerminateWorker, getRepoPath } = useNavigate();
    const { currentFolderIdByRepo, foldersByRepo, isAddingNewFolderState } = storeToRefs(useFoldersStore());
    const currentFolder = computed(
      () => foldersByRepo.value[props.repo.metadata.id]?.[currentFolderIdByRepo.value[props.repo.metadata.id]]
    );

    const emptyFolderMessage = computed(() => {
      if (filtersStore.isFiltersActive) {
        return 'No items match your filters.';
      }
      if (!currentFolder.value?.children?.length) {
        return 'This folder is empty.';
      }
      const anyPendingItemsInCurrentFolder = currentFolder.value.children.some(
        (item) =>
          props.pendingDocs.some((doc) => doc.id === item.id) ||
          props.pendingPlaylists.some((playlist) => playlist.id === item.id)
      );
      return `This folder only contains docs/playlists in another branch${
        anyPendingItemsInCurrentFolder ? ' - they will be listed here upon merge.' : '.'
      }`;
    });

    return {
      user,
      analytics,
      RepoPageDocumentationSectionTypes,
      openSwimmApp,
      navigateToPageAndTerminateWorker,
      getRepoPath,
      DocumentationTypes,
      currentFolder,
      emptyFolderMessage,
      isAddingNewFolderState,
    };
  },
  computed: {
    ...mapGetters('database', ['db_isPublicRepo', 'db_isRepoLifeguard', 'db_getWorkspace']),
    ...mapGetters('filesystem', ['fs_getSwmdPathInRepo', 'fs_getSwmdFileNameInRepo']),
    repoId() {
      return this.repo.metadata.id;
    },
    canModifyResources() {
      return !this.db_isPublicRepo(this.repoId) || this.db_isRepoLifeguard(this.repoId, this.user.uid);
    },
    workspace() {
      return this.db_getWorkspace(this.workspaceId);
    },
    filteredDocumentationWithKey() {
      // Seems like having two RecycleScroller on the same page with the same keys causes rendering issues.
      return this.enrichItems({ items: this.filteredItems, keyPostFix: '-filtered' });
    },
    allDocumentationWithKey() {
      return this.enrichItems({ items: this.allItems, addDiffStateByChangedFiles: true });
    },
    showDocumentationList() {
      return (
        this.allDocumentationWithKey.length ||
        this.filteredDocumentationWithKey.length ||
        this.isAddingNewFolderState ||
        (this.currentFolder && !this.currentFolder.is_root) ||
        this.loading
      );
    },
  },
  methods: {
    goToPage(path) {
      this.navigateToPageAndTerminateWorker({ newRoute: path });
    },
    onCreateDocClick() {
      this.reportEmptyStateClick(Entity.DOC);
      this.$emit('toggle-creation-hub');
    },
    onImportClick() {
      this.$emit('toggle-creation-hub', { creationHubSection: CreationHubSections.IMPORT });
    },
    goToPageOrPromptSignup(path) {
      this.goToPage(path);
    },
    enrichItems({ items, addDiffStateByChangedFiles = false, keyPostFix = '' }) {
      return items.map((item) => {
        const name = this.fs_getSwmdFileNameInRepo(this.repoId, item.id);
        const path = this.fs_getSwmdPathInRepo(this.repoId, item.id);
        const fullPath = `${path}/${name}`;

        let diffState = null;
        if (item.draftId) {
          diffState = item.id && !item.isNew ? 'modified' : 'added';
        }
        if (addDiffStateByChangedFiles) {
          const changedFile = this.changedFiles.find((file) => file.path.startsWith(fullPath));
          if (changedFile) {
            diffState = changedFile.status;
          }
        }
        return {
          ...item,
          path: fullPath,
          key: `${item.id || item.draftId}${keyPostFix}`,
          ...(diffState ? { diffState } : {}),
        };
      });
    },
    reportEmptyStateClick(entity) {
      const emptyStateProductEvents = {
        [Entity.DOC]: {
          name: productEvents.CLICKED_CREATE_DOC,
          context: 'Docs empty state',
        },
        [Entity.PLAYLIST]: {
          name: productEvents.CLICKED_CREATE_PLAYLIST,
          context: 'Playlists empty state',
        },
      };
      this.analytics.track(emptyStateProductEvents[entity].name, {
        'Workspace ID': this.workspaceId,
        'Repo ID': this.repoId,
        'Branch name': this.$route.params.branch,
        Context: emptyStateProductEvents[entity].context,
        Origin: 'Repo page',
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.web-warning {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  height: 48px;
  border-radius: 6px;
  background: var(--color-status-info);
  flex-direction: row;
}

.repo-content {
  margin-bottom: 100px;
}

.ribbons {
  margin-bottom: var(--space-sm);
}

.ribbons > * {
  margin-bottom: var(--space-base);
}

.empty-state-actions {
  display: flex;
  align-items: center;

  .create-button {
    .icon {
      margin-right: 4px;
    }

    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}

.empty-folder-state {
  color: var(--text-color-secondary);
  margin-left: calc(var(--space-xl) - var(--space-sm));
}
</style>
