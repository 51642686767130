import { useAnalytics } from '@/common/composables/useAnalytics';
import { localStorageKeys } from '@/common/consts';
import LocalStorage from '@/local-storage';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { type DocDbItemInterface, eventLogger, getLoggerNew, pageEvents, productEvents } from '@swimm/shared';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';

const logger = getLoggerNew(__modulename);
export function useDocReport() {
  const analytics = useAnalytics();
  const authStore = useAuthStore();
  const { logEvent } = useSwimmEventLogs();

  async function sendFirstDraftToBackoffice({
    repoId,
    repoName,
    workspaceId,
    docId,
    docName,
    allRepoDocs,
  }: {
    repoId: string;
    repoName: string;
    workspaceId: string;
    docId: string;
    docName: string;
    allRepoDocs: DocDbItemInterface[];
  }) {
    try {
      const isFirstDoc = !Object.values(allRepoDocs).some(
        (doc) => doc.creator === authStore.user.uid || doc.modifier === authStore.user.uid
      );
      if (isFirstDoc && !LocalStorage.get(localStorageKeys.USER_CREATED_FIRST_DRAFT)) {
        LocalStorage.set(localStorageKeys.USER_CREATED_FIRST_DRAFT, true);
        await logEvent(eventLogger.SWIMM_EVENTS.FIRST_DRAFT_CREATED, {
          repoId,
          workspaceId,
          repoName,
          srcId: docId,
          srcName: docName,
        });
      }
    } catch (err) {
      logger.error({ err }, `Error reporting first draft: ${err}`);
      LocalStorage.set(localStorageKeys.USER_CREATED_FIRST_DRAFT, false);
    }
  }

  async function sendEvent({
    event,
    editable,
    embedded,
    workspaceId,
    repoId,
    id,
    branch,
    sharedDocId,
    isNewDraft,
    title,
  }: {
    event: string;
    editable: boolean;
    embedded: boolean;
    workspaceId: string;
    repoId: string;
    id: string;
    branch: string;
    sharedDocId: string;
    isNewDraft: boolean;
    title: string;
  }) {
    try {
      if (editable) {
        const analyticsData = {
          'Workspace ID': workspaceId,
          'Repo ID': repoId,
          Context: 'Repo',
          Title: title ?? 'Untitled',
          'Document ID': id,
        };
        if (isNewDraft) {
          analytics.cloudTrack({
            identity: authStore.user.uid,
            event: pageEvents.EDIT_DRAFT,
            payload: analyticsData,
          });
        } else {
          analyticsData['Document ID'] = id;
          analytics.cloudTrack({
            identity: authStore.user.uid,
            event: pageEvents.EDIT_DOCUMENT,
            payload: analyticsData,
          });
        }
        return;
      } else {
        switch (event) {
          case productEvents.VISITED_DOCUMENT_NOT_IN_BRANCH:
            analytics.track(productEvents.VISITED_DOCUMENT_NOT_IN_BRANCH, {
              'Doc ID': id,
              'Branch Name': branch,
              'Repo ID': repoId,
            });
            break;
          case pageEvents.VIEW_DOCUMENT: {
            let backofficeCode: string;
            if (embedded) {
              backofficeCode = eventLogger.SWIMM_EVENTS.EMBED_DOC_VIEWED.code;
            } else if (sharedDocId) {
              backofficeCode = eventLogger.SWIMM_EVENTS.SHARED_DOC_VIEWED.code;
            } else {
              backofficeCode = eventLogger.SWIMM_EVENTS.DOC_VIEWED.code;
            }
            await analytics.cloudPageViewed({
              identity: authStore.user.uid,
              event: pageEvents.VIEW_DOCUMENT,
              backofficeCode,
              payload: {
                context: 'Document',
                Name: title,
                'Document ID': id,
                'Workspace ID': workspaceId,
                'Repo ID': repoId,
                'Draft Creator User ID': authStore.user.uid,
                'Is Shared': !!sharedDocId,
                'Is Embedded': embedded,
                Branch: branch,
              },
            });
            break;
          }
          default:
            logger.error(`Unknown analytics event: ${event}`);
        }
      }
    } catch (err) {
      logger.error({ err }, `Error reporting doc ${err}`);
    }
  }

  async function sendNewDocEvent({ workspaceId, repoId, id }: { workspaceId: string; repoId: string; id: string }) {
    try {
      const analyticsData = {
        'Workspace ID': workspaceId,
        'Repo ID': repoId,
        Context: 'Repo',
        Title: 'Untitled',
        'Document ID': id,
      };

      analytics.cloudTrack({
        identity: authStore.user.uid,
        event: pageEvents.NEW_DOCUMENT,
        payload: analyticsData,
      });

      return;
    } catch (err) {
      logger.error({ err }, `Error reporting doc ${err}`);
    }
  }

  return { sendEvent, sendFirstDraftToBackoffice, sendNewDocEvent };
}
