/// <reference lib="DOM" />
import type {
  ResultWithReturnCode,
  SwmCell,
  SwmCellImage,
  SwmCellMermaid,
  SwmCellSnippet,
  SwmCellSnippetPlaceholder,
  SwmCellTable,
  SwmCellVideo,
  SwmFile,
  SwmSymbols,
  SwmdMetadata,
} from '../types';
import { CONTEXT_MARKER, SELECT_MARKER, SwmCellType, SwmMeta, SwmSymbolLinkType, SwmSymbolType } from '../types';
import { LangExtensionUtils } from '../utils/lang-extension-utils';
import {
  ERROR_RETURN_CODE,
  SUCCESS_RETURN_CODE,
  SWIMM_FILE_TYPES,
  SWMD_FILE_EXTENSION,
  SWMD_PLAYLIST_EXTENSION,
  SWMD_SCHEMA_VERSION,
  SWM_FILE_EXTENSION,
  SWM_FOLDER_IN_REPO,
  SWM_SCHEMA_VERSION,
  swimmLinkGroupsRegex,
  swimmLinkMatcher,
  swimmURLLinkResourceGroupsRegex,
  swimmURLLinkResourceMatcher,
} from '../config';
import { removeCRCharactersFromSwmData } from '../utils/carriage-return-utils';
import YAML from 'yaml';
import { v4 as uuidv4 } from 'uuid';
import { decodeLink } from '../utils/links-utils';
import { convertSWMStructure, getSwimmIdFromSwimmFileName } from '../utils/swm-utils';
import arrayUtils from '../arrayUtils';
import { markdownTable } from 'markdown-table';
import { splitStringByRegex } from '../git-diff-parser/git-diff-parser-common';
import { splitLineByWords } from '../split-line-by-words';
import { getLogger } from '../logger/legacy-shim';
import { SwimmParsingError, SwmdFileVersionError, UrlUtils } from '../shared';
import { parseSWMDtextSmartTextSymbols, stringifyTextSymbols } from './swmd-smart-text-parser';
import {
  isSWMDVersionBefore102,
  isSWMDVersionBefore110,
  isSWMDVersionBefore111,
  isSWMDVersionNewerThanSupported,
} from './swmd-version-manager';
import { removeNewLineAfterHtmlLineBreakTag } from '../string-utils';

const logger = getLogger("packages/shared/src/swmd/swmd-parser.ts");

enum LineMarkerString {
  context = '⬜',
  selected = '🟩',
}
const SWMD_YOUTUBE_REGEX = '<--VIDEO-->';
const SWMD_MERMAID_REGEX = '<!--MERMAID';
const FILE_PATH_MARK = '📄';
const SWMD_METADATA_START = '---\n';
const SWMD_METADATA_END = `\n---\n`;
const SEPARATOR = '\n\n<br/>\n\n';
const SWMD_IMAGE_REGEX = new RegExp(`<div+.*?<img+.*?</div>`);
const SWMD_SNIPPET_NOTE_IDENTIFIER = 'NOTE-swimm-snippet';
const SWMD_CELL_REPO_IDENTIFIER = 'NOTE-swimm-repo';
const SWMD_REPO_IDENTIFIER_MARKER = '::';
const SWMD_SNIPPET_NOTE = `<!-- ${SWMD_SNIPPET_NOTE_IDENTIFIER}: the lines below link your snippet to Swimm -->`;
const MAX_LINE_NUMBER_DIGITS = 5;
const GENERATED_BY_SWIMM = 'This file was generated by Swimm.';
const SWMD_SNIPPET_PLACEHOLDER_IDENTIFIER = 'swimm-snippet-placeholder';
const SWIMM_NOTE = '### Swimm Note';
// Regex101: https://regex101.com/r/CYVZck/2
const SWMD_TABLE_REGEX =
  /^\|(?:([^\r\n|]*)\|)+\r?\n ?\|(?:( *:?-+:? *)\|)+(\r?\n|$)( ?\|(?:([^\r\n|]*)\|)*(\r?\n|$))*/gm;
const ESCAPED_PIPE_PLACEHOLDER = '{swimm-pipe-placeholder}';
const ESCAPED_PIPE_PLACEHOLDER_REGEX = /{swimm-pipe-placeholder}/g;
const ESCAPED_PIPE_REGEX = /\\\|/g;
const ESCAPED_PIPE = '\\|';
const NEW_LINE_REGEX = /((\r)?\n)/g;
const HTML_NEW_LINE = '<br>';
const HTML_NEW_LINE_REGEX = /<br>/g;
const NEW_LINE_TEXT = '\n';
const SPACE = ' ';
const singleSeparatorCellRegex = /\|--?\|/gm;
const threeSeparatorsCell = '|---|';
const SWMD_PATH_REGEX = /`📄(?:\((.*?)\))? ([^\s`]+)`/gm;

export function stringifySwmCell(
  cell: SwmCell,
  swmFile: SwmFile,
  repoId: string,
  metaData: SwmMeta,
  isExported = false
): string {
  let returnedString = '';
  if (cell.type === SwmCellType.Snippet) {
    returnedString = stringifySnippetCell(cell, swmFile, repoId, metaData, isExported);
  }
  if (cell.type === SwmCellType.Text) {
    returnedString = stringifyTextSymbols(cell.text, swmFile.symbols, repoId, metaData, isExported);
  }
  if (cell.type === SwmCellType.Image) {
    if (isExported) {
      returnedString = `![](${cell.src})`;
    } else {
      returnedString = stringifyImageCell(cell);
    }
  }
  if (cell.type === SwmCellType.Table) {
    returnedString = stringifyTableCell(cell, swmFile.symbols, repoId, metaData, isExported);
  }
  if (cell.type === SwmCellType.Video) {
    returnedString = stringifyEmbededYoutube(cell);
  }
  if (cell.type === SwmCellType.Mermaid) {
    returnedString = stringifyEmbededMermaid(cell, swmFile.symbols, repoId, metaData);
  }
  return returnedString;
}
function stringifyEmbededYoutube(cell) {
  return `[${SWMD_YOUTUBE_REGEX}](${getVideoLink(cell)})`;
}

function getVideoLink(cell) {
  return cell.src;
}

function stringifyEmbededMermaid(cell: SwmCellMermaid, symbols: SwmSymbols, repoId: string, metaData: SwmMeta) {
  // Escape the close html comment so the md will look nicer
  const mermaidParsedContent = stringifyTextSymbols(cell.content, symbols, repoId, metaData).replaceAll(
    '-->',
    '\\-\\-\\>'
  );

  const graphString = getMermaidGraphString(cell, symbols, repoId, metaData);
  return `${SWMD_MERMAID_REGEX} {width:${getMermaidGraphWidth(
    cell
  )}}-->\n\`\`\`mermaid\n${graphString}\n\`\`\`\n<!--MCONTENT {content: ${fixEmptyLinesInMermaidContent(
    mermaidParsedContent
  )}} --->`;
}

// Empty lines in Mermaid content might match the SEPARATOR and break the content to different cells.
// Calling 'JSON.stringify' is escaping the \n (\n => \\n) so the SEPARATOR won't match them.
// On read we JSON.parse the content so it's unescaped.
function fixEmptyLinesInMermaidContent(content: string) {
  return JSON.stringify(content);
}

function getMermaidGraphString(cell: SwmCellMermaid, symbols: SwmSymbols, repoId: string, metaData: SwmMeta) {
  // Replace smart tokens with their display text
  let mermaidContentWithDumbSymbols = stringifyTextSymbols(cell.content, symbols, repoId, metaData, true);

  mermaidContentWithDumbSymbols = mermaidContentWithDumbSymbols.replaceAll('\\[', '[').replaceAll('\\]', ']');

  // Remove <br/> from the end of every line
  return removeHtmlLineBreaksFromLineEnds(mermaidContentWithDumbSymbols);
}

function removeHtmlLineBreaksFromLineEnds(text: string) {
  return text
    .split('\n')
    .map((line) => line.replace(/(?:<br\/>)+$/gm, ''))
    .join('\n');
}

function getMermaidGraphWidth(cell) {
  return cell.width || 100;
}

function stringifyImageCell(cell: SwmCellImage): string {
  return `<div align="center"><img src="${
    cell.originalSrc && cell.src.startsWith('data:image/') ? cell.originalSrc : cell.src
  }" style="width:'${cell.width}%'"/></div>`;
}

function buildMultiRepoIdentifier(repoId) {
  return `<!-- ${SWMD_CELL_REPO_IDENTIFIER} ${SWMD_REPO_IDENTIFIER_MARKER}${repoId}${SWMD_REPO_IDENTIFIER_MARKER} -->\n`;
}

function stringifySnippetCell(
  cell: SwmCellSnippet,
  swmFile: SwmFile,
  repoId: string,
  metaData: SwmMeta,
  isExported = false
): string {
  let snippetMD = '';
  if (cell.comments && cell.comments.length > 0) {
    cell.comments.forEach((comment) => {
      comment = fixEmptyLinesInSnippetComment(comment); // Patch that prevents empty lines from breaking the snippet comment.
      snippetMD += `${stringifyTextSymbols(comment, swmFile.symbols, repoId, metaData, isExported)}\n`;
    });
  }

  if (!isExported) {
    snippetMD += SWMD_SNIPPET_NOTE + '\n';
  }

  if (cell.repoId && cell.repoId !== repoId && !isExported) {
    snippetMD += buildMultiRepoIdentifier(cell.repoId);
  }

  return snippetMD + innerStringifyCell(cell, isExported);
}

function innerStringifyCell(cell: SwmCellSnippet, isExported = false) {
  let snippetMD = `### 📄 ${cell.path}\n`;
  if (cell.collapsed) {
    if (isExported) {
      return '';
    }
    snippetMD += '<!-- collapsed -->\n\n';
  }

  const programmingLanguage = getProgrammingLanguageForFile(cell.path);
  let lineNumber = cell.firstLineNumber;
  snippetMD += `\`\`\`${programmingLanguage}\n`;
  cell.lines.forEach((line) => {
    if (!isExported) {
      // Discard legacy context lines
      if (!line.startsWith(CONTEXT_MARKER)) {
        snippetMD += `${getformattedLineNumber(lineNumber)} ${
          line.startsWith(SELECT_MARKER) ? ' ' + line.substring(1) : line
        }\n`;
      }
      lineNumber++;
    } else {
      snippetMD += ` ${line.substring(1)}\n`;
    }
  });
  snippetMD += '```';
  return snippetMD;
}

// This is a patch that fixes 2 problems caused by empty paragraphs in snippet comments:
// 1. Empty paragraph in the middle of a snippet comment causes the text before it to be separated from the snippet comment on load.
//    This happens because we split the SWMD file into cells by `SEPARATOR` when loading the document.
// 2. Empty paragraph in the end of a snippet comment causes MD viewers to view the snippet in one line against our will.
//    This also happens for empty snippet comments, as they are represnted by a single empty paragraph.
// In both cases, a part of the cause is a line of <br/> with empty lines (\n) before/after it in the SWMD file.
// Solution: Adding an html comment to a line of <br/> with an empty line before it.
// TODO: This is a patch. We need to find a better solution for this, but it will probably require reorganizing the SWMD file format.
function fixEmptyLinesInSnippetComment(comment: string): string {
  if (comment === '<br/>') {
    // If the comment is <br/>, it is actually an empty comment, remove it.
    return '';
  }
  return comment.replace(/^\n<br\/>$/gm, '\n<!-- empty line --><br/>');
}

function stringifyTableCell(
  cell: SwmCellTable,
  symbols: SwmSymbols,
  repoId: string,
  metaData: SwmMeta,
  isExported = false
): string {
  const unifiedTable: string[][] = [];
  const stringifiedHeaders = cell.headers
    .map((data) => stringifyTextSymbols(data, symbols, repoId, metaData, isExported))
    .map(replaceNewLineWithBr);
  const stringifiedTable = cell.table.map((row) => {
    return row
      .map((data) => stringifyTextSymbols(data, symbols, repoId, metaData, isExported))
      .map(replaceNewLineWithBr);
  });

  unifiedTable.push(stringifiedHeaders, ...stringifiedTable);

  let mdTable = markdownTable(unifiedTable, { padding: false });

  // Jetbrains IDE markdown reader only accepts tables with at least 3 separators `---` in each separator cell
  // While for overlapping matches
  while (mdTable.match(singleSeparatorCellRegex)) {
    mdTable = mdTable.replace(singleSeparatorCellRegex, threeSeparatorsCell);
  }
  return mdTable;
}

function replaceNewLineWithBr(text: string) {
  return text.replace(NEW_LINE_REGEX, HTML_NEW_LINE);
}

// Add spaces to line numbers to length of 5 so the line number string will have the same length on all rows.
function getformattedLineNumber(lineNumber: number): string {
  let lineNumberString = lineNumber.toString();
  const lineNumberDigits = lineNumberString.length;
  for (let i = lineNumberDigits; i < MAX_LINE_NUMBER_DIGITS; i++) {
    lineNumberString += ' ';
  }
  return lineNumberString;
}

function getFooter({
  swmFile,
  repoId,
  swimmType,
}: {
  swmFile: SwmFile;
  repoId: string;
  swimmType: SwmSymbolLinkType;
}): string {
  return `${GENERATED_BY_SWIMM} [Click here to view it in the app](${UrlUtils.getSwimmLink({
    swimmId: swmFile.id,
    repoId: repoId,
    swimmType,
  })}).`;
}

function getProgrammingLanguageForFile(filePath: string): string {
  if (!filePath.includes('.')) {
    return '';
  }
  const fileExtension = filePath.split('.').pop();
  return LangExtensionUtils.getProgrammingLanguageByExtension(fileExtension);
}

function getSwimmTypeFromResourceUrlCollection(urlCollection): SwmSymbolLinkType {
  if (urlCollection === 'playlists') {
    return SwmSymbolLinkType.Playlist;
  }
  if (urlCollection === 'units') {
    return SwmSymbolLinkType.Exercise;
  }
  return SwmSymbolLinkType.Doc;
}

function generateSWMDMetadata(swmFile: SwmFile) {
  const metadata: SwmdMetadata = {
    id: swmFile.id,
    title: swmFile.name,
    file_version: SWMD_SCHEMA_VERSION,
    app_version: swmFile.meta.app_version,
  };
  if (swmFile.meta.cross_repo_names && Object.keys(swmFile.meta.cross_repo_names).length > 0) {
    metadata.cross_repo_names = swmFile.meta.cross_repo_names;
  }

  return `---\n${YAML.stringify(metadata)}---\n\n`;
}

function parseSWMDMetadata(swmd: string): SwmdMetadata {
  const metadataEndIndex = swmd.indexOf(SWMD_METADATA_END);
  if (metadataEndIndex > -1) {
    let metadataString = swmd.substring(0, metadataEndIndex);
    metadataString = metadataString.replace(SWMD_METADATA_START, '').trim();
    const result = YAML.parse(metadataString);

    // Replace legacy name with title
    if (result.name !== undefined) {
      result.title = result.name;
      delete result.name;
    }

    return result;
  } else {
    throw new Error('Missing metadata');
  }
}

function convertSWMDCellToSWMCellAndFillSymbols({
  swmdCell,
  symbols,
  metadata,
  symbolIndexToSymbolIdMap,
  repoId,
}: {
  swmdCell: string;
  symbols: SwmSymbols;
  metadata: SwmdMetadata;
  symbolIndexToSymbolIdMap: Record<string, string>;
  repoId: string;
}): SwmCell {
  if (swmdCell.includes(SWMD_SNIPPET_NOTE_IDENTIFIER)) {
    // Leave snippet cell check as the first of these checks, snippets' code should not be parsed as anything else
    return convertSWMDSnippetCellToSWMCell({ swmdCell, symbols, symbolIndexToSymbolIdMap, metadata, repoId });
  } else if (swmdCell.includes(SWMD_SNIPPET_PLACEHOLDER_IDENTIFIER)) {
    return convertSWMDSnippetPlaceholderCellToSWMCell({ swmdCell });
  } else if (swmdCell.includes(SWMD_YOUTUBE_REGEX)) {
    return convertSWMDVideoToSWMCell(swmdCell);
  } else if (swmdCell.includes(SWMD_MERMAID_REGEX)) {
    return convertSWMDMermaidToSWMCell(swmdCell, symbols, symbolIndexToSymbolIdMap, metadata, repoId);
  } else if (SWMD_IMAGE_REGEX.test(swmdCell.trim())) {
    return convertSWMDImageCellToSWMCell(swmdCell);
  } else if (isTableCell(swmdCell)) {
    return convertSWMDTableCellToSWMCell(swmdCell, symbols, metadata, symbolIndexToSymbolIdMap, repoId);
  } else if (swmdCell.includes(SWIMM_NOTE)) {
    if (isSWMDVersionBefore110(metadata)) {
      fillSymbolsFromSWMDFootnotes(swmdCell, symbols, metadata, symbolIndexToSymbolIdMap, repoId);
    }
    return null;
  } else if (swmdCell.includes(GENERATED_BY_SWIMM)) {
    // Ignore the swimm footer
    return null;
  }
  // Assume the cell is text block
  return {
    type: SwmCellType.Text,
    text: parseSWMDtextSymbols(swmdCell, symbols, symbolIndexToSymbolIdMap, metadata, repoId),
  };
}

function isTableCell(swmdCell: string): boolean {
  const tableMatches = swmdCell.trim().match(SWMD_TABLE_REGEX);
  if (tableMatches) {
    return swmdCell.trim().indexOf(tableMatches[0]) === 0;
  }
  return false;
}

function isFootNoteBefore102Valid(footNoteGroups) {
  return footNoteGroups.length === 6 && !footNoteGroups.some((value) => !value);
}

function isFootNoteAfter102Valid(footNoteGroups) {
  const undefinedCount = footNoteGroups.filter((value) => !value).length;
  return footNoteGroups.length === 8 && (undefinedCount === 0 || undefinedCount === 2);
}

function extractDataFromFootNoteBefore102(footNoteGroups, defaultRepoId) {
  const footNoteNumber = footNoteGroups[1];
  const symbolText = footNoteGroups[2];
  const path = footNoteGroups[3];
  const lineNumber = parseInt(footNoteGroups[4]);
  const lineData = footNoteGroups[5];
  return { footNoteNumber, symbolText, path, lineNumber, lineData, symbolRepoId: defaultRepoId };
}

function extractDataFromFootNoteAfter102(footNoteGroups, defaultRepoId) {
  let footNoteNumber,
    symbolText,
    path,
    lineNumber,
    lineData,
    symbolRepoId = defaultRepoId;
  if (!footNoteGroups[1]) {
    footNoteNumber = footNoteGroups[4];
    symbolText = footNoteGroups[3];
    path = footNoteGroups[5];
    lineNumber = parseInt(footNoteGroups[6]);
    lineData = footNoteGroups[7];
  } else {
    // Token from another repo
    footNoteNumber = footNoteGroups[4];
    symbolText = footNoteGroups[3];
    path = footNoteGroups[5];
    lineNumber = parseInt(footNoteGroups[6]);
    lineData = footNoteGroups[7];
    symbolRepoId = footNoteGroups[2];
  }

  return { footNoteNumber, symbolText, path, lineNumber, lineData, symbolRepoId };
}

function fillSymbolsFromSWMDFootnotes(
  swmdNote: string,
  symbols: SwmSymbols,
  metadata: SwmdMetadata,
  symbolIndexToSymbolIdMap: Record<string, string>,
  repoId: string
) {
  const notesRegexBefore102 = '<a id="(\\d+)">\\(\\d+\\)<\\/a> (.*?) - "(.*?)" L(\\d+)\\n```\\S*\\n(.*?)\\n```$';
  const notesRegexAfter102 =
    '(<!-- NOTE-swimm-repo ::(.*):: -->\\n)?<span id="\\S+">(\\S+)<\\/span>\\[\\^\\]\\(#(\\S+)\\) - "(.*?)" L(\\d+)\\n\\n?```\\S*\\n(.*?)\\n```$';

  const usingSWMDbefore102 = isSWMDVersionBefore102(metadata);
  const swimmNoteLines = splitStringByRegex({
    text: swmdNote,
    pattern: usingSWMDbefore102 ? notesRegexBefore102 : notesRegexAfter102,
  });

  for (const swimmNoteLine of swimmNoteLines) {
    // Break the footnote to data values. line example:
    // <span id="f-Z1Nph73">Setup</span>[^](#Z1Nph73) - "Day-004-100DaysOfAWS.md" L12
    // ```markdown
    //  Setup = 5;
    // ```
    //
    // Or (linted):
    // <span id="f-Z1Nph73">Setup</span>[^](#Z1Nph73) - "Day-004-100DaysOfAWS.md" L12
    //
    // ```markdown
    //  Setup = 5;
    // ```
    //
    // Multi Repo:
    // <!-- NOTE-swimm-repo ::some_id:: -->
    // <span id="f-Z1Nph73">Setup</span>[^](#Z1Nph73) - "Day-004-100DaysOfAWS.md" L12
    // ```markdown
    //  Setup = 5;
    // ```
    //
    // Before 1.0.2:
    // <a id="2">(2)</a> Setup - "Day-004-100DaysOfAWS.md" L12
    // ```markdown
    //  Setup = 5;
    // ```
    const footNoteGroupsRegex = usingSWMDbefore102
      ? new RegExp(notesRegexBefore102, 'm')
      : new RegExp(notesRegexAfter102, 'm');
    const footNoteGroups = swimmNoteLine.match(footNoteGroupsRegex);
    // Verify that all symbol data found on the footnot line
    const isValid = usingSWMDbefore102
      ? isFootNoteBefore102Valid(footNoteGroups)
      : isFootNoteAfter102Valid(footNoteGroups);
    if (!isValid) {
      continue;
    }

    const { footNoteNumber, symbolText, path, lineNumber, lineData, symbolRepoId } = usingSWMDbefore102
      ? extractDataFromFootNoteBefore102(footNoteGroups, repoId)
      : extractDataFromFootNoteAfter102(footNoteGroups, repoId);

    if (!symbolIndexToSymbolIdMap[footNoteNumber]) {
      symbolIndexToSymbolIdMap[footNoteNumber] = uuidv4();
    }
    const symbolId = symbolIndexToSymbolIdMap[footNoteNumber];

    // Calculating the word index
    const wordsinSymbol = splitLineByWords(symbolText);
    const wordsinLine = splitLineByWords(lineData);
    const symbolIndexInLineWords = arrayUtils.findSubarray(wordsinLine, wordsinSymbol);

    // Finding the path file blob
    const fileBlob =
      repoId === symbolRepoId ? metadata?.['file_blobs']?.[path] : metadata.cross_repo_file_blobs[symbolRepoId][path];

    symbols[symbolId] = {
      type: SwmSymbolType.GENERIC_TEXT,
      text: symbolText,
      lineNumber,
      wordIndex: {
        start: symbolIndexInLineWords,
        end: symbolIndexInLineWords + wordsinSymbol.length - 1,
      },
      fileBlob,
      path,
      lineData,
      repoId: symbolRepoId,
    };
  }
}

function convertSWMDVideoToSWMCell(swmdCell: string): SwmCellVideo {
  // <--VIDEO--> is const SWMD_YOUTUBE_REGEX
  const videoRegex = new RegExp(/\[<--VIDEO-->\]\((?<link>.*)\)/gm);
  const src = videoRegex.exec(swmdCell);
  return { type: SwmCellType.Video, src: src.groups.link };
}

function convertSWMDMermaidToSWMCell(
  swmdCell: string,
  symbols: SwmSymbols,
  symbolIndexToSymbolIdMap: Record<string, string>,
  metadata: SwmdMetadata,
  repoId: string
): SwmCellMermaid {
  const mermaidRegex = new RegExp(
    /<!--MERMAID\s\{width:(\d+)\}-->\n\s*```mermaid\n(?!\[)([^]*\n)```(\n\s*<!--MCONTENT\s\{content:\s([^]*)\}\s--->)?/
  );

  const src = mermaidRegex.exec(swmdCell);
  let content = src[4].replaceAll('\\-\\-\\>', '-->');

  // BACKWARDS COMPATIBILITY - we used to store the content without JSON.stringify, so we need to deal with cases where
  // it isn't saved like that.
  if (content.startsWith('"') && content.endsWith('"')) {
    content = JSON.parse(content);
  }

  content = parseSWMDtextSymbols(content || src[2], symbols, symbolIndexToSymbolIdMap, metadata, repoId);

  return {
    type: SwmCellType.Mermaid,
    width: parseInt(src[1]),
    src: src[2],
    content: content,
  };
}

function convertSWMDImageCellToSWMCell(swmdCell: string): SwmCellImage {
  const stringStartsWithImageSrc = swmdCell.split('src="')[1];
  const imageSrc = stringStartsWithImageSrc.substring(0, stringStartsWithImageSrc.indexOf('"'));
  const isFileBlob = imageSrc.indexOf('data:') === 0;
  const securedSRC = isFileBlob ? 'https://' + imageSrc : imageSrc;
  const stringStartsWithImageWidth = swmdCell.split("width:'")[1];
  const imageWidth = stringStartsWithImageWidth.substring(0, stringStartsWithImageWidth.indexOf('%'));
  return {
    type: SwmCellType.Image,
    src: securedSRC,
    width: parseInt(imageWidth),
  };
}
function convertSWMDTableCellToSWMCell(
  swmdCell: string,
  symbols: SwmSymbols,
  metadata: SwmdMetadata,
  symbolIndexToSymbolIdMap: Record<string, string>,
  repoId: string
): SwmCellTable {
  const splitTable = swmdCell.trim().split('\n');
  // First row (0) is table headers
  const tableHeaders = splitTableMarkdownRowToCells(splitTable[0], symbols, metadata, symbolIndexToSymbolIdMap, repoId);

  // Next row (1) is the header separator - that's why we slice from 2
  // Table header separator looks like: |---:|---| ...|
  const tableData = splitTable.slice(2);
  const tableRows = tableData.map((row) => {
    return splitTableMarkdownRowToCells(row, symbols, metadata, symbolIndexToSymbolIdMap, repoId);
  });
  return {
    type: SwmCellType.Table,
    table: tableRows,
    headers: tableHeaders,
  };
}
function splitTableMarkdownRowToCells(
  row: string,
  symbols: SwmSymbols,
  metadata: SwmdMetadata,
  symbolIndexToSymbolIdMap: Record<string, string>,
  repoId: string
): string[] {
  // Table row looks like: `| cell1 | cell2 | ...|` - and can contain escaped pipe `\|`
  row = row.replace(ESCAPED_PIPE_REGEX, ESCAPED_PIPE_PLACEHOLDER);
  const splitCells = row.split('|');
  const cells = splitCells.map((cell) =>
    cell.replace(ESCAPED_PIPE_PLACEHOLDER_REGEX, ESCAPED_PIPE).replace(HTML_NEW_LINE_REGEX, NEW_LINE_TEXT)
  );
  const parsedCell = [];
  cells.forEach((cell) => {
    parsedCell.push(parseSWMDtextSymbols(cell, symbols, symbolIndexToSymbolIdMap, metadata, repoId));
  });
  // Remove first and last empty string because of split result
  // If last | was escaped then there won't be an empty result in the end
  parsedCell.shift();
  if (!parsedCell[parsedCell.length - 1]) {
    parsedCell.pop();
  }
  return parsedCell;
}

function convertSWMDSnippetCellToSWMCell({
  swmdCell,
  symbols,
  symbolIndexToSymbolIdMap,
  metadata,
  repoId,
}: {
  swmdCell: string;
  symbols: SwmSymbols;
  symbolIndexToSymbolIdMap: Record<string, string>;
  metadata: SwmdMetadata;
  repoId: string;
}): SwmCellSnippet {
  const swmdCellLines = swmdCell.trim().split('\n');
  let comment = '';
  let commentEnded = false;
  let path = '';
  let firstLineNumber = -1;
  let collapsed = false;
  let snippetRepoId = null;
  const lines = [];
  for (const snippetCellLine of swmdCellLines) {
    // Read all lines before the snippet note as one comment.
    if (!commentEnded) {
      if (!snippetCellLine.includes(SWMD_SNIPPET_NOTE_IDENTIFIER)) {
        comment += snippetCellLine + '\n';
      } else {
        commentEnded = true;
        comment = comment.trim();
      }
      continue;
    }

    // Check for cross repo snippet
    if (!snippetRepoId && commentEnded) {
      if (!snippetCellLine.includes(SWMD_CELL_REPO_IDENTIFIER)) {
        snippetRepoId = repoId;
      } else {
        const commentSections = snippetCellLine.split(SWMD_REPO_IDENTIFIER_MARKER);
        if (commentSections.length === 3) {
          snippetRepoId = commentSections[1];
        } else {
          logger.error(
            `Swimm repo identifier note was compramized, using current repoId. Should be: <!-- NOTE-swimm-repo ::repo_id:: --> , but got ${snippetCellLine}`
          );
          snippetRepoId = repoId;
        }
        continue;
      }
    }

    if (snippetCellLine.includes('<!-- collapsed -->')) {
      collapsed = true;
      continue;
    }

    // Get the path from the path line, e.g "📄 app/src/index.js" => "app/src/index.js"
    if (!path && snippetCellLine.includes(FILE_PATH_MARK)) {
      path = snippetCellLine.split(FILE_PATH_MARK)[1].trim();
      continue;
    }

    // Handle the code snippet lines
    if (isSWMDVersionBefore111(metadata)) {
      if (
        snippetCellLine.startsWith(LineMarkerString.context) ||
        snippetCellLine.startsWith(LineMarkerString.selected)
      ) {
        const { snippetLine, newFirstLineNumber } = parseLegacySnippet(snippetCellLine, firstLineNumber);
        lines.push(snippetLine);
        if (newFirstLineNumber > 0) {
          firstLineNumber = newFirstLineNumber;
        }
      }
    } else {
      // The snippet code starts and ends with three backticks, so we ignore them.
      if (snippetCellLine.startsWith('```')) {
        continue;
      }
      const lineDataPrefix = `${'0'.repeat(MAX_LINE_NUMBER_DIGITS)}${SPACE}${SPACE}`;
      if (snippetCellLine) {
        let snippetLine = snippetCellLine.substring(lineDataPrefix.length);

        // Todo: remove select marker after refactor
        snippetLine = SELECT_MARKER + snippetLine;
        lines.push(snippetLine);
        if (firstLineNumber < 0) {
          const firstLineNumberString = snippetCellLine.substring(0, MAX_LINE_NUMBER_DIGITS).trim();
          firstLineNumber = parseInt(firstLineNumberString);
        }
      }
    }
  }

  const comments = comment ? [parseSWMDtextSymbols(comment, symbols, symbolIndexToSymbolIdMap, metadata, repoId)] : [];

  const snippet: SwmCellSnippet = {
    type: SwmCellType.Snippet,
    lines,
    id: uuidv4(),
    firstLineNumber,
    path,
    comments,
    collapsed,
    repoId: snippetRepoId,
  };
  return snippet;
}

function parseLegacySnippet(snippetCellLine, firstLineNumber) {
  const isContext = snippetCellLine.startsWith(LineMarkerString.context);
  const lineDataPrefix = `${isContext ? LineMarkerString.context : LineMarkerString.selected} ${'0'.repeat(
    MAX_LINE_NUMBER_DIGITS
  )} `;
  // Remove the line marker and number e.g "⬜ 10     ## Topic Covered" => "  ## Topic Covered"
  let snippetLine = snippetCellLine.substring(lineDataPrefix.length);
  if (isContext) {
    snippetLine = CONTEXT_MARKER + snippetLine.substring(1);
  } else {
    snippetLine = SELECT_MARKER + snippetLine.substring(1);
  }
  let newFirstLineNumber = -1;
  if (firstLineNumber < 0) {
    const lineStartsWithLineNumber = snippetCellLine.substring(`${LineMarkerString.context} `.length);
    const firstLineNumberString = lineStartsWithLineNumber.substring(0, MAX_LINE_NUMBER_DIGITS).trim();
    newFirstLineNumber = parseInt(firstLineNumberString);
  }
  return {
    snippetLine,
    newFirstLineNumber,
  };
}

function convertSWMDSnippetPlaceholderCellToSWMCell({ swmdCell }: { swmdCell: string }): SwmCellSnippetPlaceholder {
  const swmdCellLines = swmdCell.trim().split('\n');

  const comment = swmdCellLines
    .filter((snippetCellLine) => !snippetCellLine.includes(SWMD_SNIPPET_PLACEHOLDER_IDENTIFIER))
    .join('\n');

  return {
    type: SwmCellType.SnippetPlaceholder,
    id: uuidv4(),
    comment,
  };
}

/**
 * Convert the symbols in the text from SWMD format to SWM format
 * e.g smartText - `fumctionName (3)` => [[sym-text:functionName(bad9bfa3-67e1-4e95-ad50-5a66d913b7be)]]
 * e.g path - `📄 Day-004-100DaysOfAWS.md` => [[sym:./Day-004-100DaysOfAWS.md(5f686720-c447-4079-852d-6685cf48bbc5)]]
 * @param text - text from swmd.
 * @param symbols - swm symbols map, will add path symbols from the given text(if needed).
 * @param symbolIndexToSymbolIdMap - map of symbol index(e.g 3 in `fumctionName (3)`) to generated symbol-Id,
 * The map is used for reusing between texts and for the footnotes parsing.
 * @param metadata - holds file level data
 * @param repoId - used to indicate cross repo symbols
 * @returns
 */
function parseSWMDtextSymbols(
  text: string,
  symbols: SwmSymbols,
  symbolIndexToSymbolIdMap: Record<string, string>,
  metadata: SwmdMetadata,
  repoId: string
): string {
  text = removeNewLineAfterHtmlLineBreakTag(text);
  text = parseSWMDtextSmartTextSymbols(text, symbols, symbolIndexToSymbolIdMap, metadata, repoId);
  text = parseSWMDtemplatePlaceholderSymbols(text, symbols);
  text = parseSWMDtextLinkSymbols(text, symbols, repoId);
  text = parseSWMDTextMentionSymbols(text, symbols);
  return parseSWMDtextPathSymbols(text, symbols, repoId, metadata);
}

function parseSWMDtemplatePlaceholderSymbols(text: string, symbols: SwmSymbols) {
  return parseSWMDTextPlaceholderSymbols(text, symbols);
}

function parseSWMDTextPlaceholderSymbols(text: string, symbols: SwmSymbols) {
  const textPlaceholderRegex = new RegExp('\\[(?<text>.*?)\\]\\(#text-placeholder-id-(?<id>[a-z0-9]*?)\\)', 'g');
  const foundTextPlaceholders = text.matchAll(textPlaceholderRegex);
  for (const textPlaceholder of foundTextPlaceholders) {
    const isTextPlaceholderInSymbolData = Object.values(symbols).find(
      (symbol) => symbol.type === SwmSymbolType.TEXT_PLACEHOLDER && symbol.id === textPlaceholder.groups.id
    );
    if (!isTextPlaceholderInSymbolData) {
      symbols[textPlaceholder.groups.id] = {
        type: SwmSymbolType.TEXT_PLACEHOLDER,
        id: textPlaceholder.groups.id,
        text: textPlaceholder.groups.text,
        value: '',
      };
    }
  }
  return text;
}

function parseSWMDtextPathSymbols(text: string, symbols: SwmSymbols, repoId: string, metadata: SwmdMetadata) {
  const pathSymbols = text.matchAll(SWMD_PATH_REGEX);
  for (const pathSymbol of pathSymbols) {
    // Paths look like: `📄 a/path/here`
    // Cross repo paths look like: `📄(the-repo-name) a/path/here`
    // The regex extracts 2 groups: the repo name and the path
    // pathSymbol will be:
    // not cross repo: ['`📄(the-repo-name) a/path/here`', undefined, 'a/path/here']
    // cross repo: ['`📄(the-repo-name) a/path/here`', 'the-repo-name', 'a/path/here']
    if (pathSymbol.length !== 3) {
      continue;
    }
    const path = pathSymbol[2];
    const pathRepoId = pathSymbol[1] ? metadata.cross_repo_names[pathSymbol[1]] : repoId;
    // existing path: check for path + repoId
    const existingPath = Object.entries(symbols).find(
      (symbol) => symbol[1].type === SwmSymbolType.PATH && symbol[1].path === path && symbol[1].repoId === pathRepoId
    );

    let pathSymbolId;
    if (existingPath) {
      // Path symbol already declared in the swm symbols list
      pathSymbolId = existingPath[0];
    } else {
      pathSymbolId = uuidv4();
      symbols[pathSymbolId] = {
        type: SwmSymbolType.PATH,
        text: path,
        path,
        repoId: pathRepoId,
      };
    }

    text = text.replace(pathSymbol[0], `[[sym:./${path}(${pathSymbolId})]]`);
  }
  return text;
}

function parseSWMDTextMentionSymbols(text: string, symbols: SwmSymbols): string {
  const foundMentionSymbols = text.matchAll(/`👤 (?<name>.+?)\[(?<userId>\S+?)\]`/gm);
  for (const matchResult of foundMentionSymbols) {
    const userId = matchResult.groups.userId;
    const name = matchResult.groups.name;
    const swmMentionSymbolData = Object.entries(symbols).find(
      (symbol) => symbol[1].type === SwmSymbolType.MENTION && symbol[1].userId === userId
    );
    let mentionSymbolId: string;
    if (swmMentionSymbolData) {
      mentionSymbolId = swmMentionSymbolData[0];
    } else {
      mentionSymbolId = uuidv4();
      symbols[mentionSymbolId] = {
        type: SwmSymbolType.MENTION,
        text: name,
        userId,
      };
    }
    text = text.replace(matchResult[0], `[[sym-mention:(${mentionSymbolId}|${userId})${name}]]`);
  }
  return text;
}

function parseSWMDtextLinkSymbols(text: string, symbols: SwmSymbols, repoId: string) {
  // Find swimm local app links, e.g [How to add config](https://swimm.io/link?c3dpbW0lM0ElMkYlMkZyZXBvc)
  const foundSwimmLinks = text.match(swimmLinkMatcher);
  // From the link get the swimm name and encoded link
  if (foundSwimmLinks && foundSwimmLinks.length > 0) {
    for (const swimmLink of foundSwimmLinks) {
      const linkGroups = swimmLink.match(swimmLinkGroupsRegex);
      const swimmName = linkGroups[1];
      const encodedLink = linkGroups[2];
      const splittedUrl = decodeLink(encodedLink).split('/');
      const reposIndex = splittedUrl.findIndex((pathPart) => pathPart === 'repos');
      const resourceIndex = splittedUrl.findIndex((pathPart) => ['docs', 'units', 'playlists'].includes(pathPart));
      // Process only doc/exercise and playlist links as symbols
      if (reposIndex > -1 && resourceIndex > -1) {
        const repoId = splittedUrl[reposIndex + 1]; // Get repoId after repos (/repos/:repoId)
        const swimmId = splittedUrl[resourceIndex + 1]; // Get swmId after docs (/docs/:swmId)
        const linkSymbolId = addLinkSymbolIfNeeded({
          swimmId,
          swimmName,
          repoId,
          symbols,
          swimmType: getSwimmTypeFromResourceUrlCollection(splittedUrl[resourceIndex]),
        });
        text = replaceSWMDswimmLinkWithSymbol(text, swimmLink, linkSymbolId, swimmName);
      }
    }
  }

  // Find swimm web links, e.g [How to add config](https://app.swimm.io/workspaces/ncjkNHJKnj/repos/cndjksBhBhkLbHJ/branch/main/docs/bHJKBbjkjkHJK)
  const foundSwimmWebLinks = text.match(swimmURLLinkResourceMatcher);

  // From the link get the swimm name, repoId and docId
  if (foundSwimmWebLinks && foundSwimmWebLinks.length > 0) {
    for (const swimmLink of foundSwimmWebLinks) {
      const linkGroups = swimmLink.match(swimmURLLinkResourceGroupsRegex);

      let swimmName = linkGroups[1];
      const symbolRepoId = linkGroups[2];
      const swimmTypeCollection = linkGroups[3];
      const swimmId = linkGroups[4];
      const hash = linkGroups[5];

      if (hash) {
        // if we have an # in the url we do not want to parse the url as a swimmdoc but as an hyperlink
        continue;
      }
      if (UrlUtils.isURL(swimmName)) {
        // If the text is also a link then chnage it to normal text, the editor will replace the text with the real doc name.
        // We need override it here because the MD parsing(MarkdownUtils) fails to parse this case correctly.
        swimmName = 'Swimm Doc';
      }
      const resolvedSwimmResourceLinkName = getSwimmResourceLinkName(swimmName, repoId, symbolRepoId, swimmId);
      const linkSymbolId = addLinkSymbolIfNeeded({
        swimmId,
        swimmName: resolvedSwimmResourceLinkName,
        repoId: symbolRepoId,
        symbols,
        swimmType: getSwimmTypeFromResourceUrlCollection(swimmTypeCollection),
      });
      text = replaceSWMDswimmLinkWithSymbol(text, swimmLink, linkSymbolId, resolvedSwimmResourceLinkName);
    }
  }

  // Find swimm relative links, e.g [How to add config](how_to_add_config.fhdjkvbHJKBHJ.sw.md)
  const relativeFileLinksRegex = new RegExp(
    `\\[[^\\]]+\\]\\(\\S+(${SWM_FILE_EXTENSION}|${SWMD_FILE_EXTENSION})\\)`,
    'gm'
  );
  const foundRelativeFileLinks = text.match(relativeFileLinksRegex);
  // From the link get the swimm name, file name
  const relativeFileLinksGroupsRegex = new RegExp(
    `\\[(.*?)\\]\\((\\S+)(${SWM_FILE_EXTENSION}|${SWMD_FILE_EXTENSION})\\)`
  );
  if (foundRelativeFileLinks && foundRelativeFileLinks.length > 0) {
    for (const swimmLink of foundRelativeFileLinks) {
      const linkGroups = swimmLink.match(relativeFileLinksGroupsRegex);
      const swimmName = linkGroups[1];
      const fileName = linkGroups[2];
      const swimmId = getSwimmIdFromSwimmFileName(fileName);
      let swimmType;
      if (swimmLink.includes(SWM_FILE_EXTENSION)) {
        swimmType = SwmSymbolLinkType.Exercise;
      } else if (swimmLink.includes(SWMD_PLAYLIST_EXTENSION)) {
        swimmType = SwmSymbolLinkType.Playlist;
      } else {
        swimmType = SwmSymbolLinkType.Doc;
      }
      const linkSymbolId = addLinkSymbolIfNeeded({
        swimmId,
        swimmName,
        repoId,
        symbols,
        swimmType,
      });
      text = replaceSWMDswimmLinkWithSymbol(text, swimmLink, linkSymbolId, swimmName);
    }
  }
  return text;
}

function getSwimmResourceLinkName(swimmName: string, repoId: string, symbolRepoId: string, swimmId: string): string {
  // Attempt to fix only if the link is for a doc in another repo
  if (!swimmName || !repoId || !symbolRepoId || symbolRepoId === repoId) {
    return swimmName;
  }

  const lastSlashIndex = swimmName.lastIndexOf('/');

  // If a '/' is found, return the substring after the last '/'
  if (lastSlashIndex !== -1) {
    const newSwimmName = swimmName.substring(lastSlashIndex + 1);
    logger.warn(
      `Swmd Parsing: fixing cross-repo link with slash. Original Doc Details: ${JSON.stringify(
        {
          swimmName,
          repoId,
          symbolRepoId,
          swimmId,
          newSwimmName,
        },
        null,
        2
      )}`
    );
    return newSwimmName;
  } else {
    return swimmName;
  }
}

function replaceSWMDswimmLinkWithSymbol(
  text: string,
  swimmLink: string,
  linkSymbolId: string,
  swimmName: string
): string {
  return text.replace(swimmLink, `[[sym-link:(${linkSymbolId})${swimmName}]]`);
}

function addLinkSymbolIfNeeded({
  swimmId,
  swimmName,
  repoId,
  symbols,
  swimmType,
}: {
  swimmId: string;
  swimmName: string;
  repoId: string;
  symbols: SwmSymbols;
  swimmType: SwmSymbolLinkType;
}): string {
  const linkSymbolData = Object.entries(symbols).find(
    (symbol) => symbol[1].type === SwmSymbolType.LINK && symbol[1].swimmId === swimmId
  );
  let linkSymbolId;
  if (linkSymbolData) {
    // Link symbol already declared in the swm symbols list
    linkSymbolId = linkSymbolData[0];
  } else {
    linkSymbolId = uuidv4();
    symbols[linkSymbolId] = {
      type: SwmSymbolType.LINK,
      text: swimmName,
      repoId,
      swimmId,
      swimmType,
    };
  }
  return linkSymbolId;
}

function getSwmCellsAndSymbolsFromSWMD(
  swmd: string,
  metadata: SwmdMetadata,
  repoId: string
): { cells: SwmCell[]; symbols: SwmSymbols } {
  // Get swmd content without metadata
  const metadataEndIndex = swmd.indexOf(SWMD_METADATA_END);
  const swmdContentAfterMetadata = swmd.substring(metadataEndIndex + SWMD_METADATA_END.length).trim();

  const swmdCellsString = swmdContentAfterMetadata.split(SEPARATOR);
  const symbols = {};
  const symbolIndexToSymbolIdMap = {};
  const swmCells = swmdCellsString
    .map((swmdCell) =>
      convertSWMDCellToSWMCellAndFillSymbols({ swmdCell, symbols, metadata, symbolIndexToSymbolIdMap, repoId })
    )
    .filter((cell) => !!cell);
  return { cells: swmCells, symbols };
}

export function convertSWMJsonToSWMD({
  swmFile,
  repoId,
  isPlaylist = false,
}: {
  swmFile: SwmFile;
  repoId: string;
  isPlaylist?: boolean;
}): ResultWithReturnCode<{ swmd: string }, { errorMessage: string }> {
  removeCRCharactersFromSwmData(swmFile);
  try {
    let swmd = generateSWMDMetadata(swmFile);
    swmFile.content.forEach((cell) => {
      swmd += stringifySwmCell(cell, swmFile, repoId, swmFile.meta);
      swmd += SEPARATOR;
    });

    swmd += getFooter({
      swmFile,
      repoId,
      swimmType: isPlaylist ? SwmSymbolLinkType.Playlist : SwmSymbolLinkType.Doc,
    });

    // It's customary to end a file with a new line.
    swmd += '\n';
    return { code: SUCCESS_RETURN_CODE, swmd: swmd };
  } catch (error) {
    logger.error(`Failed to convert SWM JSON to SWMD: ${error}`);
    return { code: ERROR_RETURN_CODE, errorMessage: error.toString() };
  }
}

export function convertSWMDtoSWM(
  swmd: string,
  repoId: string
): ResultWithReturnCode<{ swm: SwmFile }, { errorMessage: string; errorObj: Error }> {
  try {
    const swm = parseSWMD(swmd, repoId);
    return { code: SUCCESS_RETURN_CODE, swm: swm };
  } catch (error: unknown) {
    return { code: ERROR_RETURN_CODE, errorMessage: `Invalid .sw.md file: ${error}`, errorObj: error as Error };
  }
}

export function getSWMdataFromRawContent({
  fileContent,
  repoId,
  type,
  appVersion,
}: {
  fileContent: string;
  repoId: string;
  type: (typeof SWIMM_FILE_TYPES)[keyof typeof SWIMM_FILE_TYPES];
  appVersion: string;
}): SwmFile {
  if (type === SWIMM_FILE_TYPES.PLAYLIST || type === SWIMM_FILE_TYPES.SWMD) {
    const swmResult = convertSWMDtoSWM(fileContent, repoId);
    if (swmResult.code !== SUCCESS_RETURN_CODE) {
      throw new Error(swmResult.errorMessage);
    }
    return swmResult.swm;
  } else {
    return convertSWMStructure(JSON.parse(fileContent), repoId, appVersion);
  }
}

export function parseSWMD(swmd: string, repoId: string): SwmFile {
  try {
    const metadata = parseSWMDMetadata(swmd);
    if (isSWMDVersionNewerThanSupported(metadata)) {
      throw new SwmdFileVersionError(
        `Got version ${metadata.file_version}, but supported version is ${SWMD_SCHEMA_VERSION}.`,
        metadata.file_version
      );
    }
    const { cells: content, symbols } = getSwmCellsAndSymbolsFromSWMD(swmd, metadata, repoId);
    return {
      name: metadata.title,
      file_version: SWM_SCHEMA_VERSION,
      id: metadata.id,
      content,
      meta: {
        app_version: metadata.app_version,
        ...(metadata.cross_repo_names && { cross_repo_names: metadata.cross_repo_names }),
      },
      symbols,
      path: SWM_FOLDER_IN_REPO,
    };
  } catch (error: unknown) {
    if (error instanceof SwimmParsingError) {
      throw error;
    }
    const msg = `Failed to parse SWM file: ${error}`;
    const stack = error instanceof Error ? error.stack : '';
    const newError = new SwimmParsingError(msg);
    newError.stack = stack;
    throw new SwimmParsingError(msg);
  }
}
