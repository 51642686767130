<script setup>
import InteractiveOnboardingContent from '@/modules/onboarding/components/InteractiveOnboardingContent.vue';
import { ONBOARDING_VIDEOS_BASE_BUCKET_URL, OnboardingOrigin, ProductTourOrigin } from '@/modules/onboarding/consts';
import { SwModalWrapper } from '@swimm/ui';
import { ref } from 'vue';

defineProps({
  show: { type: Boolean, required: true },
});
const emit = defineEmits(['close']);

const stepIndex = ref(0);

function onNextStep(step) {
  stepIndex.value = step;
}

function closeModal() {
  emit('close');
  stepIndex.value = 0;
}
</script>

<template>
  <SwModalWrapper :show="show" blur-background @close="closeModal">
    <div class="container-onboarding-steps">
      <InteractiveOnboardingContent
        :step-index="stepIndex"
        :videos-source="`${ONBOARDING_VIDEOS_BASE_BUCKET_URL}/${OnboardingOrigin.WEBAPP}`"
        :context="ProductTourOrigin.WORKSPACE"
        @next-step="onNextStep"
        @next-screen="closeModal"
      />
    </div>
  </SwModalWrapper>
</template>

<style scoped>
.container-onboarding-steps {
  width: 55vw;
  max-width: 1440px;
}
</style>
