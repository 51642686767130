import path from 'path-browserify';

export type LanguageIdentifier = VSCodeLanguageIdentifier;

export type VSCodeLanguageIdentifier =
  | 'bat'
  | 'cmd'
  | 'clojure'
  | 'coffeescript'
  | 'c'
  | 'cpp'
  | 'csharp'
  | 'css'
  | 'dockerfile'
  | 'fsharp'
  | 'go'
  | 'groovy'
  | 'handlebars'
  | 'hlsl'
  | 'html'
  | 'ini'
  | 'java'
  | 'jade'
  | 'javascriptreact'
  | 'javascript'
  | 'json'
  | 'less'
  | 'lua'
  | 'makefile'
  | 'markdown'
  | 'objective-c'
  | 'php'
  | 'perl'
  | 'perl6'
  | 'powershell'
  | 'python'
  | 'r'
  | 'razor'
  | 'ruby'
  | 'rust'
  | 'scala'
  | 'scss'
  | 'shaderlab'
  | 'shellscript'
  | 'sql'
  | 'swift'
  | 'tsql'
  | 'typescript'
  | 'typescriptreact'
  | 'vb'
  | 'xml'
  | 'yaml'
  | 'plaintext';

// Based on DevSkim's pathOperations.ts
export function getLanguageIdentifierFromPath(filePath: string): LanguageIdentifier {
  const extension: string = path.extname(filePath);
  switch (extension.toLowerCase()) {
    case '.bat':
      return 'bat';
    case '.cmd':
      return 'cmd';

    case '.clj':
    case '.cljs':
    case '.cljc':
    case '.cljx':
    case '.clojure':
    case 'edn':
      return 'clojure';

    case '.coffee':
    case '.cson':
      return 'coffeescript';

    case '.c':
      return 'c';

    case '.cpp':
    case '.cc':
    case '.cxx':
    case '.c++':
    case '.h':
    case '.hh':
    case '.hxx':
    case '.hpp':
    case '.h++':
      return 'cpp';

    case '.cs':
    case '.csx':
    case '.cake':
      return 'csharp';

    case '.css':
      return 'css';

    case '.dockerfile':
      return 'dockerfile';

    case '.fs':
    case '.fsi':
    case '.fsx':
    case '.fsscript':
      return 'fsharp';

    case '.go':
      return 'go';

    case '.groovy':
    case '.gvy':
    case '.gradle':
      return 'groovy';

    case '.handlebars':
    case '.hbs':
      return 'handlebars';

    case '.hlsl':
    case '.fx':
    case '.fxh':
    case '.vsh':
    case '.psh':
    case '.cfinc':
    case '.hlsli':
      return 'hlsl';

    case '.html':
    case '.htm':
    case '.shtml':
    case '.xhtml':
    case '.mdoc':
    case '.jsp':
    case '.asp':
    case '.aspx':
    case '.jshtm':
    case '.volt':
    case '.ejs':
    case '.rhtml':
      return 'html';

    case '.ini':
      return 'ini';

    case '.jade':
    case '.pug':
      return 'jade';

    case '.java':
    case '.jav':
      return 'java';

    case '.jsx':
      return 'javascriptreact';

    case '.js':
    case '.es6':
    case 'mjs':
    case '.pac':
      return 'javascript';

    case '.json':
    case '.bowerrc':
    case '.jshintrc':
    case '.jscsrc':
    case '.eslintrc':
    case '.babelrc':
    case '.webmanifest':
    case '.code-workspace':
      return 'json';

    case '.less':
      return 'less';

    case '.lua':
      return 'lua';

    case '.mk':
      return 'makefile';

    case '.md':
    case '.mdown':
    case '.markdown':
    case 'markdn':
      return 'markdown';

    case '.m':
    case '.mm':
      return 'objective-c';

    case '.php':
    case '.php3':
    case '.php4':
    case '.php5':
    case '.phtml':
    case '.ph3':
    case '.ph4':
    case '.ctp':
      return 'php';

    case '.pl':
    case '.pm':
    case '.t':
    case 'p6':
    case 'pl6':
    case 'pm6':
    case 'nqp':
    case '.pod':
      return 'perl';

    case '.ps1':
    case '.psm1':
    case '.psd1':
    case '.pssc':
    case '.psrc':
      return 'powershell';

    case '.py':
    case '.rpy':
    case '.pyw':
    case '.cpy':
    case '.gyp':
    case '.gypi':
      return 'python';

    case '.r':
    case '.rhistory':
    case '.rprofile':
    case '.rt':
      return 'r';

    case '.cshtml':
      return 'razor';

    case '.rb':
    case '.rbx':
    case '.rjs':
    case '.gemspec':
    case '.rake':
    case '.ru':
    case '.erb':
      return 'ruby';

    case '.rs':
      return 'rust';

    case '.scala':
    case '.sc':
      return 'scala';

    case '.scss':
      return 'scss';

    case '.shadder':
      return 'shaderlab';

    case '.sh':
    case '.bash':
    case '.bashrc':
    case '.bash_aliases':
    case '.bash_profile':
    case '.bash_login':
    case '.ebuild':
    case '.install':
    case '.profile':
    case '.bash_logout':
    case '.zsh':
    case '.zshrc':
    case '.zprofile':
    case '.zlogin':
    case '.zlogout':
    case '.zshenv':
    case '.zsh-theme':
      return 'shellscript';

    case '.sql':
    case '.dsql':
      return 'sql';

    case '.swift':
      return 'swift';

    case '.ts':
      return 'typescript';

    case '.tsx':
      return 'typescriptreact';

    case '.vb':
    case '.vba':
    case 'brs':
    case '.bas':
    case '.vbs':
      return 'vb';

    case '.xml':
    case '.xsd':
    case '.ascx':
    case '.atom':
    case '.axml':
    case '.bpmn':
    case '.config':
    case '.cpt':
    case '.csl':
    case '.csproj':
    case '.csproj.user':
    case '.dita':
    case '.ditamap':
    case '.dtd':
    case '.dtml':
    case '.fsproj':
    case '.fxml':
    case '.iml':
    case '.isml':
    case '.jmx':
    case '.launch':
    case '.menu':
    case '.mxml':
    case '.nuspec':
    case '.opml':
    case '.owl':
    case '.proj':
    case '.props':
    case '.pt':
    case '.publishsettings':
    case '.pubxml':
    case '.pubxml.user':
    case '.rdf':
    case '.rng':
    case '.rss':
    case '.shproj':
    case '.storyboard':
    case '.svg':
    case '.targets':
    case '.tld':
    case '.tmx':
    case '.vbproj':
    case '.vbproj.user':
    case '.vcxproj':
    case '.vcxproj.filters':
    case '.wsdl':
    case '.wxi':
    case '.wxl':
    case '.wxs':
    case '.xaml':
    case '.xbl':
    case '.xib':
    case '.xlf':
    case '.xliff':
    case '.xpdl':
    case '.xul':
    case '.xoml':
      return 'xml';

    case '.yaml':
    case 'eyaml':
    case 'eyml':
    case '.yml':
      return 'yaml';
    default:
      return 'plaintext';
  }
}
