<template>
  <div class="exercise-wrapper">
    <div class="exercise-content content-padding-wrapper">
      <ResourceHeadline
        v-if="isEditPlaylist"
        :resource-name="swimmFromDb.name"
        resource-type="EXERCISE"
        :resource-creator="swimmFromDb.creator_name"
      />
      <Ribbon
        :icon-name="ribbonContent.icon"
        :ribbon-title="ribbonContent.title"
        :ribbon-description="ribbonContent.description"
        :mode="ribbonContent.mode"
      >
        <Action v-if="ribbonContent.callToAction" size="small" @click="ribbonContent.callToActionFunction">{{
          ribbonContent.callToAction
        }}</Action>
      </Ribbon>
    </div>
  </div>
</template>

<script>
import { useAppLinks } from '@/modules/core/compositions/app-links';
import { mapGetters } from 'vuex';
import { ApplicabilityStatus } from '@swimm/shared';

export default {
  props: {
    repoId: { type: String, required: true },
    exerciseId: { type: String, required: true },
  },
  data() {
    return {
      ribbonValues: {
        [ApplicabilityStatus.Outdated]: {
          title: 'Outdated Exercise',
          description: 'This repo was updated and this exercise needs to be reviewed and updated.',
          mode: 'error',
          icon: 'warning',
        },
        [ApplicabilityStatus.Autosyncable]: {
          title: 'Autosyncable Exercise',
          description: 'The repo was updated and this exercise was autosynced by Swimm.',
          mode: 'autosync',
          icon: 'sync',
        },
        [ApplicabilityStatus.Unavailable]: {
          title: 'Unavailable Exercise',
          description: 'The exercise could not be found',
          mode: 'error',
          icon: 'unavailable',
          unitStatus: ApplicabilityStatus.Unavailable, // Used in the inner banner
        },
        localAppNeeded: {
          title: 'Exercises are available only in the local app',
          description: '',
          mode: 'web',
          icon: 'task',
        },
      },
    };
  },
  setup() {
    const { openSwimmApp } = useAppLinks();
    return { openSwimmApp };
  },
  computed: {
    ...mapGetters('database', ['db_getSwimm']),
    ribbonContent() {
      let ribbon = {};
      ribbon = this.ribbonValues.localAppNeeded;
      ribbon.callToActionFunction = () => this.openSwimmApp();
      ribbon.callToAction = 'Open Swimm App';
      return ribbon;
    },
    swimmFromDb() {
      return this.db_getSwimm(this.repoId, this.exerciseId);
    },
    isEditPlaylist() {
      return this.$route.path.endsWith('/edit') || this.$route.path.endsWith('/new');
    },
  },
};
</script>

<style scoped>
.exercise-wrapper {
  display: flex;
  align-items: center;
  margin: auto;
  flex-direction: column;
}

.exercise-content {
  width: 100%;
  max-width: var(--narrow-content-width);
}
</style>
