<script setup lang="ts">
import { Action, Icon, SwText } from '@swimm/ui';

defineProps<{
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: 'open-workspace-settings'): void;
}>();

function goToWorkspaceSettings() {
  emit('open-workspace-settings');
}
</script>

<template>
  <div class="banner">
    <div class="col-1">
      <Icon name="info-fill" />
      <SwText variant="body-S">Enable for all repos?</SwText>
    </div>
    <div class="col-2">
      <Action :disabled="disabled" @click="goToWorkspaceSettings">
        <SwText variant="body-S">Go to workspace settings</SwText>
      </Action>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.banner {
  background: var(--color-bg-info-subtle);
  padding: var(--space-base) var(--space-sm);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .col-1 {
    display: flex;
    align-items: center;
    color: var(--color-text-info);
  }
}
</style>
