<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import type { FolderTree, TokenSuggestion, WorkspaceRepo } from '@swimm/shared';

import BaseKeyCommand from '../../components/BaseKeyCommand/BaseKeyCommand.vue';

import SwmSelectionContentTokenAdvancedHeaderAdditional from '../SwmSelectionContentTokenAdvancedHeaderAdditional/SwmSelectionContentTokenAdvancedHeaderAdditional.vue';
import SwmSelectionContentTokenAdvancedHeaderPrefix from '../SwmSelectionContentTokenAdvancedHeaderPrefix/SwmSelectionContentTokenAdvancedHeaderPrefix.vue';
import SwmSelectionContentTokenAdvancedEmptyState from '../SwmSelectionContentTokenAdvancedEmptyState/SwmSelectionContentTokenAdvancedEmptyState.vue';
import SwmSelectionContentTokenSidebar from '../SwmSelectionContentTokenSidebar/SwmSelectionContentTokenSidebar.vue';
import SwmSelectionContentTokenSelectedFiles from '../SwmSelectionContentTokenSelectedFiles/SwmSelectionContentTokenSelectedFiles.vue';
import SwmSelectionContentTokenList from '../SwmSelectionContentTokenList/SwmSelectionContentTokenList.vue';
import SwmSelectionFooter from '../SwmSelectionFooter/SwmSelectionFooter.vue';
import type { SelectableFolderTree } from '../NodeTree/NodeTree.vue';

const props = withDefaults(
  defineProps<{
    modelValue: string;
    workspaceRepos?: WorkspaceRepo[];
    tokenSuggestions?: TokenSuggestion[];
    selectedNodes?: SelectableFolderTree[];
    node: FolderTree;
    loadingNode?: boolean;
    loadingTokenSuggestions?: boolean;
    noResults?: boolean;
  }>(),
  {
    modelValue: '',
    workspaceRepos: undefined,
    tokenSuggestions: undefined,
    selectedNodes: undefined,
    node: undefined,
    loading: undefined,
  }
);

const emit = defineEmits<{
  'update:modelValue': [value: string];
  selectToken: [token: TokenSuggestion];
  focusedToken: [token: TokenSuggestion];
  hoveredToken: [token: TokenSuggestion];
  'selectedNodes:add': [node: FolderTree];
  'selectedNodes:remove': [node: SelectableFolderTree];
  selectRepo: [repo: WorkspaceRepo];
  showSimple: [];
  close: [];
}>();

const sidebar = ref<InstanceType<typeof SwmSelectionContentTokenSidebar>>();
const selectedFiles = ref<InstanceType<typeof SwmSelectionContentTokenSelectedFiles>>();
const list = ref<InstanceType<typeof SwmSelectionContentTokenList>>();
const queryNode = ref('');
const collapsedSidebar = ref(false);

const computedClasses = computed(() => ({
  'swm-selection-content-token-advanced--collapsed': collapsedSidebar.value,
}));

const hasSelectedNodes = computed(() => {
  return props.selectedNodes && props.selectedNodes.length;
});

function onUpdateModelValue(value: string) {
  emit('update:modelValue', value);
}

function onSelectToken(token: TokenSuggestion) {
  emit('selectToken', token);
}

function onFocusedToken(token: TokenSuggestion) {
  emit('focusedToken', token);
}

function onHoveredToken(token: TokenSuggestion) {
  emit('hoveredToken', token);
}

async function onSelectedNodesAdd(node: FolderTree) {
  emit('selectedNodes:add', node);
  await selectedFiles.value?.focusItems?.forceUpdateFocusItems();
}

async function onSelectedNodesRemove(node: SelectableFolderTree) {
  emit('selectedNodes:remove', node);
  await selectedFiles.value?.focusItems?.forceUpdateFocusItems();
}

function onSelectRepo(repo: WorkspaceRepo) {
  emit('selectRepo', repo);
}

function onShowSimple() {
  emit('showSimple');
}

function onClose() {
  emit('close');
}

function onToggleSidebar() {
  collapsedSidebar.value = !collapsedSidebar.value;
}

const currentTabFocus = ref<'list' | 'sidebar' | 'selectedFiles'>('list');

function setListFocus() {
  list.value?.setSearchFocus();
  currentTabFocus.value = 'list';
}
function setSidebarFocus() {
  sidebar.value?.setSearchFocus();
  currentTabFocus.value = 'sidebar';
}
function setSelectedFilesFocus() {
  selectedFiles.value?.focusItems?.focusItem(0);
  currentTabFocus.value = 'selectedFiles';
}

function handleKeydown(e: KeyboardEvent) {
  if (e.shiftKey && e.key === 'Tab') {
    e.preventDefault();
    switch (currentTabFocus.value) {
      case 'list':
        setSidebarFocus();
        break;
      case 'sidebar':
        if (hasSelectedNodes.value) {
          setSelectedFilesFocus();
        } else {
          setListFocus();
        }
        break;
      case 'selectedFiles':
        setListFocus();
        break;
    }
  } else if (e.key === 'Tab') {
    e.preventDefault();
    switch (currentTabFocus.value) {
      case 'list':
        if (hasSelectedNodes.value) {
          setSelectedFilesFocus();
        } else {
          setSidebarFocus();
        }
        break;
      case 'sidebar':
        setListFocus();
        break;
      case 'selectedFiles':
        setSidebarFocus();
        break;
    }
  } else if (e.ctrlKey && e.shiftKey && e.key === 'K') {
    e.preventDefault();
    onShowSimple();
  } else if (e.key === 'Escape') {
    e.stopPropagation();
    e.preventDefault();
    onClose();
  }
}

onMounted(() => {
  // Ensure the sidebar is closed when viewport is less than 600px.
  if (window.innerWidth < 600 && !collapsedSidebar.value) {
    collapsedSidebar.value = true;
  }
});
</script>

<template>
  <div class="swm-selection-content-token-advanced" :class="computedClasses" @keydown="handleKeydown">
    <div class="swm-selection-content-token-advanced__container">
      <div class="swm-selection-content-token-advanced__content">
        <div class="swm-selection-content-token-advanced__sidebar-container">
          <SwmSelectionContentTokenSidebar
            ref="sidebar"
            :model-value="queryNode"
            :workspace-repos="workspaceRepos"
            :selected-nodes="selectedNodes"
            :node="node"
            :loading="loadingNode"
            disable-auto-focus
            class="swm-selection-content-token-advanced__sidebar"
            @selected-nodes:add="onSelectedNodesAdd"
            @selected-nodes:remove="onSelectedNodesRemove"
            @select-repo="onSelectRepo"
          />
          <SwmSelectionContentTokenSelectedFiles
            v-if="hasSelectedNodes"
            ref="selectedFiles"
            class="swm-selection-content-token-advanced__selected-nodes"
            :selected-nodes="selectedNodes"
            @selected-nodes:remove="
              (node) => {
                onSelectedNodesRemove(node);
                setSelectedFilesFocus();
              }
            "
          />
        </div>
        <div class="swm-selection-content-token-advanced__list">
          <SwmSelectionContentTokenList
            ref="list"
            :model-value="modelValue"
            :token-suggestions="tokenSuggestions"
            :loading="loadingTokenSuggestions"
            :no-results="noResults"
            @update:model-value="onUpdateModelValue"
            @select-token="onSelectToken"
            @focused-token="onFocusedToken"
            @hovered-token="onHoveredToken"
          >
            <template #header-prefix>
              <SwmSelectionContentTokenAdvancedHeaderPrefix
                class="swm-selection-content-token-advanced__toggle-sidebar"
                :open="collapsedSidebar"
                @toggle="onToggleSidebar"
              />
            </template>
            <template #header-additional>
              <SwmSelectionContentTokenAdvancedHeaderAdditional @simple="onShowSimple" />
            </template>
            <template #prompt>
              <SwmSelectionContentTokenAdvancedEmptyState class="swm-selection-content-token-advanced__empty-state" />
            </template>
            <template #preview="{ cachedHighlighters }"
              ><slot name="preview" :cached-highlighters="cachedHighlighters"
            /></template>
          </SwmSelectionContentTokenList>
        </div>
      </div>
      <SwmSelectionFooter>
        <span>Navigate <BaseKeyCommand :keys="['↑']" /><BaseKeyCommand :keys="['↓']" /></span>
        <span>Accept <BaseKeyCommand :keys="['↵']" /></span>
        <span>Simple search <BaseKeyCommand :keys="['Ctrl', 'Shift', 'K']" /></span>
        <span>Close <BaseKeyCommand :keys="['Escape']" /></span>
      </SwmSelectionFooter>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-selection-content-token-advanced {
  $self: &;

  @include basic-resets;

  overflow: hidden;
  height: 100%;
  width: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__content {
    flex-grow: 1;
    flex-shrink: 1; /* allow it to shrink if needed */
    overflow: auto; /* add scroll if content exceeds its container's height */
    display: flex;
  }

  &__empty-state {
    margin-top: var(--space-large);
  }

  &__sidebar-container {
    border-right: 1px solid var(--color-border-default-subtle);
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    width: 280px;
    transition: width 0.25s ease-in-out;
  }

  &__sidebar {
    width: 280px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
    width: 1200px;
  }

  &__selected-nodes {
    height: auto;
    overflow: auto;
  }

  &--collapsed {
    #{$self} {
      &__sidebar-container {
        width: 0;
      }
    }
  }
}
</style>
