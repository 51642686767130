<script setup lang="ts">
import BaseCard from '@/modules/core/components/BaseCard.vue';
import { computed } from 'vue';
import { PageRoutesNames } from '@/common/consts';
import { useRoute, useRouter } from 'vue-router';
import BaseBackgroundLayout from '@/modules/onboarding/layouts/BaseBackgroundLayout.vue';
import { BackgroundTheme } from '@/modules/onboarding/consts';
import AccountReadyJoinContent from '@/modules/onboarding/components/account-ready/AccountReadyJoinContent.vue';
import AccountReadyCreateContent from '@/modules/onboarding/components/account-ready/AccountReadyCreateContent.vue';
import AccountReadyRegisterFromIDEContent from '@/modules/onboarding/components/account-ready/AccountReadyRegisterFromIDEContent.vue';
import { IdeOptions } from '@swimm/shared';

const route = useRoute();
const router = useRouter();

const prevRoute = computed(() => {
  const prevRouteUrl = router.options.history.state.back as string;
  return prevRouteUrl ? router.resolve({ path: prevRouteUrl })?.name : PageRoutesNames.CREATE_WORKSPACE;
});

const isRegisterOriginFromIDE = computed(() => Object.values(IdeOptions).includes(route.query?.platform as string));
const isGitEnterpriseHosting = computed(() => route.query.isEnterprise === 'true');
const workspaceSelectedProvider = computed(() => route.query.provider as string);
</script>

<template>
  <BaseBackgroundLayout :theme="BackgroundTheme.BLURRED_APP">
    <BaseCard class="card-container">
      <AccountReadyRegisterFromIDEContent v-if="isRegisterOriginFromIDE" />
      <AccountReadyCreateContent
        v-else-if="prevRoute === PageRoutesNames.CREATE_WORKSPACE"
        :is-git-enterprise-hosting="isGitEnterpriseHosting"
        :selected-provider="workspaceSelectedProvider"
      />
      <AccountReadyJoinContent v-else-if="prevRoute === PageRoutesNames.JOIN_WORKSPACE" />
    </BaseCard>
  </BaseBackgroundLayout>
</template>

<style scoped lang="postcss">
.card-container {
  margin: auto;
  gap: var(--space-md);
  align-items: center;
}
</style>
