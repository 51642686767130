import { getDraftsData } from '@/adapters-common/load_common';
import { saveDraftsToState } from '@/adapters-common/save_common';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { PJSON_VERSION } from '@/config';
import { useLogBatchSaveEvent } from '@/modules/batch-commit/compositions/log-batch-save-event';
import { useSaveDocContributions } from '@/modules/batch-commit/compositions/save-doc-contribution';
import { type BatchSaveStep, CreateBranchError } from '@/modules/batch-commit/store/batch-commit';
import { useUnitIdGenerator } from '@/modules/core/compositions/unit-id-generator';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';
import { EMPTY_PLAYLIST_CONTENT, PlaylistSequenceStepTypes } from '@/modules/playlists/types';
import {
  DocSubCollectionsArray,
  SWMD_VERSION,
  type SwimmDocument,
  type SwmResourceFile,
  SwmResourceState,
  config,
  convertSWMJsonToSWMD,
  firestoreCollectionNames,
  generateSwmdFileName,
  getLoggerNew,
  getSwimmJsonData,
  gitwrapper,
  productEvents,
  removePrefix,
  repoConfig,
  toValidBranchName,
} from '@swimm/shared';
import {
  EMPTY_DOC_CONTENT,
  getDocElementsStats,
  imageFileToBase64,
  isDocCrossRepo,
  listImageNodes,
  parseSwmd,
  schema,
  serializeSwmd,
} from '@swimm/swmd';
import pathlib from 'path-browserify';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { usePlaylistToSwmd } from '../composables/playlist-to-markdown';
import {
  type DbDraft,
  type Draft,
  type DraftAttributes,
  type DraftContent,
  DraftType,
  type ImageDraft,
  db,
  decryptDraft,
  encryptDraftContent,
} from '../db';
import { createImageDraft } from '@/modules/editor3/imageUpload';
import { Transform } from '@tiptap/pm/transform';

const logger = getLoggerNew(__modulename);

export interface CommitOptions {
  createBranch?: string;
  commitMessage: string;
  createPr?: boolean;
  prTitle?: string;
  prMessage?: string;
}

export interface CommitResult {
  prNumber?: number;
  prUrl?: string;
  branch: string;
}

export type PostCommitCallback = (commitResult: CommitResult) => Promise<void>;

interface DocumentSavedAnalyticsPayloadCreatedOrUpdated {
  'Document ID': string;
  'Document Name': string;
  'Save Date': string;
  'From Branch': string;
  'To Branch': string;
  workspaceId: string;
  'Multi-repo': boolean;
  Context: 'Generated Docs' | 'Repo';
  'Save Type': 'create' | 'update';
  'Total Snippets': number;
  'Total Tokens': number;
  'Total Paths': number;
  Features: string;
  'Is AI Enabled in Repo': boolean;
}

export const useDrafts3Store = defineStore('drafts3', () => {
  const analytics = useAnalytics();
  const store = useStore();
  const authStore = useAuthStore();
  const userConfigStore = useUserConfigStore();
  const { generateNewUnitId } = useUnitIdGenerator();
  const { saveContributions } = useSaveDocContributions();
  const { logBatchSaveEvent } = useLogBatchSaveEvent();
  const { serializePlaylistToSwmd } = usePlaylistToSwmd();

  const workspaceId = ref<string>();
  const repoId = ref<string>();
  const branch = ref<string>();

  const committing = ref<boolean>(false);
  const committedToBranchName = ref<string>(null);

  const saveStartTime = ref<number>();
  const saveEndTime = ref<number>();

  const loading = ref(false);
  const drafts = ref<Map<string, Draft>>();
  const draftSavingPromise = ref<Promise<void> | null>(null);
  let pendingSave: DraftContent | null = null;

  const userId = computed(() => authStore.user.uid);

  async function fetchDrafts(newWorkspaceId: string, newRepoId: string, newBranch: string) {
    logger.info('Fetching drafts');
    loading.value = true;
    let migrationSucceded = true;

    try {
      if (newWorkspaceId !== workspaceId.value || newRepoId !== repoId.value || newBranch !== branch.value) {
        drafts.value = new Map<string, Draft>();
      }
      workspaceId.value = newWorkspaceId;
      repoId.value = newRepoId;
      branch.value = newBranch;

      migrationSucceded = await migrateOldDrafts(newRepoId);

      drafts.value = new Map(
        (
          await db.drafts
            .where({
              userId: userId.value,
              workspaceId: workspaceId.value,
              repoId: repoId.value,
              branch: newBranch,
            })
            .toArray()
        ).map((draft) => [draft.id, decryptDraft(draft, userConfigStore.salt)])
      );
    } catch (err) {
      logger.error({ err }, `Unexpected error on fetching drafts: ${err}`);
    } finally {
      loading.value = false;
    }
    return { migrationSucceded };
  }

  async function migrateOldDrafts(repoId: string) {
    const oldDrafts = await getDraftsData({ repoId });

    // For drafts links we need to generate a new id for the draft
    // keep a dictionary of draftid to the new generate id
    const draftIdToSwimmId = new Map<string, string>();
    oldDrafts.forEach((oldDraft) => {
      if (!oldDraft.id) {
        draftIdToSwimmId.set(oldDraft.draftId, oldDraft.futureSwmId || generateNewUnitId({ repoId }));
      }
    });

    if (oldDrafts.length !== 0) {
      try {
        await db.transaction('rw', [db.drafts, db.images], async () => {
          for (const oldDraft of oldDrafts) {
            switch (oldDraft.type) {
              case 'swmd': {
                // update the links symbols with an id to support drafts links
                if (oldDraft.symbols) {
                  const updateSymbols = {};

                  Object.keys(oldDraft.symbols).forEach((symbolId) => {
                    const symbolValue = { ...oldDraft.symbols[symbolId] };
                    if (symbolValue.type === 'link') {
                      updateSymbols[symbolId] = {
                        ...symbolValue,
                        swimmId: draftIdToSwimmId.get(symbolValue.swimmId) || symbolValue.swimmId,
                      };
                    }
                  });
                  oldDraft.symbols = updateSymbols;
                }
                const swmd = convertSWMJsonToSWMD({ swmFile: oldDraft, repoId });
                if (swmd.code !== config.SUCCESS_RETURN_CODE) {
                  continue;
                }

                const newSwmd = parseSwmd(swmd.swmd, {
                  legacy: {
                    baseUrl: config.BASE_URL,
                    workspaceId: workspaceId.value,
                    repoId: repoId,
                    repoName: '' /* TODO */,
                    repos: [] /* TODO */,
                  },
                });

                const dbDraftId = oldDraft.id || draftIdToSwimmId.get(oldDraft.draftId);

                const doc = ProseMirrorNode.fromJSON(schema, newSwmd.content);
                const imageNodes = listImageNodes(doc);
                if (imageNodes.length > 0) {
                  const tr = new Transform(doc);
                  for (const imageNode of imageNodes) {
                    const match = imageNode.node.attrs.src.match(
                      /^https:\/\/data:image\/(?<extension>.+?);base64,(?<base64Data>.+)$/ms
                    );

                    if (!match) {
                      continue;
                    }

                    const buff = Buffer.from(match.groups.base64Data, 'base64');
                    const imageFile = new File([buff], 'src', { type: `image/${match.groups.extension}` });
                    const imageDraft = await createImageDraft(
                      workspaceId.value,
                      repoId,
                      oldDraft.draftBranch,
                      userId.value,
                      dbDraftId,
                      imageFile
                    );

                    await db.images.put(imageDraft);

                    tr.setNodeAttribute(imageNode.pos, 'src', imageDraft.path);
                  }
                  newSwmd.content = tr.doc.toJSON();
                }

                const dbDraft: DbDraft = {
                  type: DraftType.DOC,
                  content: encryptDraftContent(newSwmd, userConfigStore.salt),
                  userId: userId.value,
                  workspaceId: workspaceId.value,
                  repoId: repoId,
                  id: dbDraftId,
                  branch: oldDraft.draftBranch,
                  created: oldDraft.created,
                  modified: oldDraft.modified?.toMillis() ?? oldDraft.created,
                  isNew: !oldDraft.id,
                  originalTitle:
                    oldDraft.id && store.getters['filesystem/fs_getSwmdFileNameInRepo'](repoId, oldDraft.id),
                  tags: oldDraft.tags,
                  folderId: oldDraft.folderId,
                  folderIndex: oldDraft.folderIndex,
                };

                await db.drafts.add(dbDraft);

                break;
              }

              case 'playlist': {
                const updateSequenceLinks = oldDraft?.sequence.length > 0 ? oldDraft.sequence : [];
                updateSequenceLinks.forEach((step) => {
                  if (step.type === 'unit' || step.type === 'playlist') {
                    step.id = draftIdToSwimmId.get(step.id) || step.id;
                  }
                });
                const playlistContent = {
                  type: DraftType.PLAYLIST,
                  name: oldDraft.name,
                  id: oldDraft.id || draftIdToSwimmId.get(oldDraft.draftId),
                  description: oldDraft?.description,
                  summary: oldDraft?.summary,
                  sequence: updateSequenceLinks,
                };

                await db.drafts.add({
                  type: DraftType.PLAYLIST,
                  content: encryptDraftContent(playlistContent, userConfigStore.salt),
                  userId: userId.value,
                  workspaceId: workspaceId.value,
                  repoId: repoId,
                  id:
                    oldDraft.futureSwmId ||
                    oldDraft.draftId ||
                    generateNewUnitId({
                      repoId,
                    }),
                  branch: oldDraft.draftBranch,
                  created: oldDraft.created,
                  modified: oldDraft.modified?.toMillis() ?? oldDraft.created,
                  isNew: !oldDraft.id,
                  originalTitle:
                    oldDraft.id && store.getters['filesystem/fs_getSwmdFileNameInRepo'](repoId, oldDraft.id),
                  tags: oldDraft.tags,
                  folderId: oldDraft.folderId,
                  folderIndex: oldDraft.folderIndex,
                });

                break;
              }
            }
          }
        });
        await saveDraftsToState({ repoId, drafts: [] });
        return true;
      } catch (err) {
        logger.error({ err }, `Unexpected error on migrating old drafts: ${err}`);
        return false;
      }
    }
    return true;
  }

  async function getDraftsByFolder(folderId: string): Promise<DbDraft[]> {
    return db.drafts
      .where({
        userId: userId.value,
        workspaceId: workspaceId.value,
        repoId: repoId.value,
        folderId,
      })
      .toArray();
  }

  async function hasAnyDrafts(): Promise<boolean> {
    return (await db.drafts.where({ userId: userId.value }).limit(1).count()) > 0;
  }

  function saveDraft(id: string, draftContent: DraftContent, options?: { originalTitle: string }): void {
    if (draftSavingPromise.value != null) {
      pendingSave = draftContent;
      return;
    }

    saveStartTime.value = Date.now();
    draftSavingPromise.value = doSaveDraft(id, draftContent, options).then(() => {
      if (pendingSave != null) {
        draftSavingPromise.value = doSaveDraft(id, pendingSave, options).then(() => {
          draftSavingPromise.value = null;
        });
        pendingSave = null;
      } else {
        draftSavingPromise.value = null;
        saveEndTime.value = Date.now();
      }
    });
  }
  async function saveDraftImmediately(
    id: string,
    draftContent: DraftContent,
    options?: { originalTitle: string }
  ): Promise<void> {
    return doSaveDraft(id, draftContent, options);
  }

  async function saveImageDraft(imageDraft: ImageDraft) {
    return db.images.put(imageDraft);
  }

  async function getImageDraft(unitId: string, path: string): Promise<ImageDraft | undefined> {
    return db.images
      .where({
        workspaceId: workspaceId.value,
        repoId: repoId.value,
        branch: branch.value,
        userId: userId.value,
        draftId: unitId,
        path,
      })
      .first();
  }

  async function moveDraftsToBranch(ids: string[], newBranch: string): Promise<void> {
    if (ids.length === 0) {
      return;
    }
    await db.transaction('rw', [db.drafts, db.images], async () => {
      for (const id of ids) {
        await db.drafts.update([userId.value, workspaceId.value, repoId.value, branch.value, id], {
          branch: newBranch,
        });

        await db.images
          .where({
            workspaceId: workspaceId.value,
            repoId: repoId.value,
            branch: branch.value,
            userId: userId.value,
            draftId: id,
          })
          .modify({ branch: newBranch });
      }
    });
  }

  async function doesDraftExistOnBranch(id: string, branch: string): Promise<boolean> {
    return (await db.drafts.get([userId.value, workspaceId.value, repoId.value, branch, id])) != null;
  }

  async function discardDraft(id: string): Promise<void> {
    await db.transaction('rw', [db.drafts, db.images], async () => {
      await db.drafts.delete([userId.value, workspaceId.value, repoId.value, branch.value, id]);

      await db.images
        .where({
          workspaceId: workspaceId.value,
          repoId: repoId.value,
          branch: branch.value,
          userId: userId.value,
          draftId: id,
        })
        .delete();

      drafts.value.delete(id);
    });
  }

  async function deleteDrafts(ids: string[]): Promise<void> {
    await db.transaction('rw', [db.drafts, db.images], async () => {
      await db.drafts.bulkDelete(ids.map((id) => [userId.value, workspaceId.value, repoId.value, branch.value, id]));

      for (const id of ids) {
        await db.images
          .where({
            workspaceId: workspaceId.value,
            repoId: repoId.value,
            branch: branch.value,
            userId: userId.value,
            draftId: id,
          })
          .delete();
      }

      for (const id of ids) {
        drafts.value.delete(id);
      }
    });
  }

  async function deleteDraftsByFolder(folderId: string): Promise<void> {
    const draftsToDelete = await getDraftsByFolder(folderId);
    await db.transaction('rw', [db.drafts, db.images], async () => {
      await db.drafts.bulkDelete(
        draftsToDelete.map((draftToDelete) => [
          userId.value,
          workspaceId.value,
          repoId.value,
          branch.value,
          draftToDelete.id,
        ])
      );

      for (const draftToDelete of draftsToDelete) {
        await db.images
          .where({
            workspaceId: workspaceId.value,
            repoId: repoId.value,
            branch: branch.value,
            userId: userId.value,
            draftId: draftToDelete.id,
          })
          .delete();
      }

      if (drafts.value != null) {
        drafts.value = new Map([...drafts.value].filter(([, draft]) => draft.folderId !== folderId));
      }
    });
  }

  async function deleteAllDrafts(): Promise<void> {
    await db.transaction('rw', [db.drafts, db.images], async () => {
      await db.drafts.where({ userId: userId.value }).delete();
      await db.images.where({ userId: userId.value }).delete();
    });
  }

  function swimmFromDb(swimmId) {
    return store.getters['database/db_getSwimm'](repoId.value, swimmId);
  }

  function playlistFromDb(playlistId) {
    return store.getters['database/db_getPlaylist'](repoId.value, playlistId);
  }

  const getRepoSwmsLists = (args?) => store.dispatch('filesystem/getRepoSwmsLists', args);

  const saveResourceInFirebaseDocument = (args) => store.dispatch('database/saveResourceInFirebaseDocument', args);

  function clearSubCollectionsProperties(newUnit) {
    // Remove any property from the SWM DB object before sending it to the DB to be saved
    for (const subCollectionName of DocSubCollectionsArray) {
      delete newUnit[subCollectionName];
    }
  }

  async function saveDocToDb(draft: Draft): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let unitToSave: any;
    if (!draft.isNew) {
      const unitFromDB = swimmFromDb(draft.id);
      unitToSave = { ...unitFromDB, id: draft.id };
    } else {
      unitToSave = {
        id: draft.id,
      };
    }

    const tagsToSave = new Set([...(draft.tags ?? []), ...(unitToSave.tags ?? [])]);

    clearSubCollectionsProperties(unitToSave);
    // TODO: update statistics
    unitToSave = {
      ...unitToSave,
      name: getDraftTitle(draft),
      type: 'unit',
      tags: Array.from(tagsToSave),
      play_mode: config.UNIT_PLAY_MODES.WALKTHROUGH,
      file_version: (draft.content as SwimmDocument).version ?? SWMD_VERSION,
      app_version: PJSON_VERSION,
      hunks_count: 0,
      tokens_count: 0,
      paths_count: 0,
      files_referenced: 0,
    };
    await saveResourceInFirebaseDocument({
      resourceName: firestoreCollectionNames.SWIMMS,
      resource: unitToSave,
      containerDocId: repoId.value,
      shouldSaveCreationDetails: draft.isNew,
    });
  }

  async function savePlaylistToDb(draft: Draft): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let playlistToSave: any;
    if (!draft.isNew) {
      const playlistValueFromDb = playlistFromDb(draft.id);
      playlistToSave = { ...playlistValueFromDb, id: draft.id };
    } else {
      playlistToSave = {
        id: draft.id,
      };
    }
    playlistToSave = {
      ...playlistToSave,
      name: getDraftTitle(draft),
    };
    await saveResourceInFirebaseDocument({
      resourceName: firestoreCollectionNames.PLAYLISTS,
      resource: playlistToSave,
      containerDocId: repoId.value,
      shouldSaveCreationDetails: draft.isNew,
    });
  }

  async function saveResourcesToDb(drafts: Draft[]): Promise<void> {
    await Promise.all(
      drafts.map((draft) => {
        switch (draft.type) {
          case 'doc':
            return saveDocToDb(draft);
          case 'playlist':
            return savePlaylistToDb(draft);
          default:
            throw Error(`No save to DB function is defined to type ${draft.type}`);
        }
      })
    );
  }

  async function createBranch(newBranch: string): Promise<void> {
    const originBranchRefResponse = await gitwrapper.getBranch({ repoId: repoId.value, branchName: branch.value });
    try {
      await gitwrapper.createBranch({
        repoId: repoId.value,
        branchName: newBranch,
        sourceSha: originBranchRefResponse.sha,
      });
    } catch (err) {
      throw new CreateBranchError(err, newBranch);
    }
  }

  // TODO It's nice to call commit directly on the draft store, but we should extract some or all of the logic out to somewhere else, and refactor this function
  async function commit(
    draftIds: string[],
    options: CommitOptions,
    progress?: (progress: BatchSaveStep) => void,
    postCommits?: PostCommitCallback[]
  ): Promise<CommitResult> {
    const result: Partial<CommitResult> = {};
    try {
      committing.value = true;
      committedToBranchName.value = null;

      const draftsToSave: Draft[] = [];
      const files: SwmResourceFile[] = [];

      const docsAnalyticsData: DocumentSavedAnalyticsPayloadCreatedOrUpdated[] = [];
      for (const draftId of draftIds) {
        const draft = drafts.value.get(draftId);
        if (draft == null) {
          throw new Error(`Invalid draft ID ${draftId}`);
        }

        draftsToSave.push(draft);
        switch (draft.type) {
          case DraftType.DOC: {
            const pathFolder = draft.path ? pathlib.dirname(draft.path) : config.SWM_FOLDER_IN_REPO;
            const path = `${pathFolder}/${generateSwmdFileName(draftId, draft.content.title)}${
              config.SWMD_FILE_EXTENSION
            }`;
            const newFileName = generateSwmdFileName(draftId, draft.content.title);
            const oldFileName = !draft.isNew && generateSwmdFileName(draftId, draft.originalTitle);

            const doc = ProseMirrorNode.fromJSON(schema, draft.content.content);
            const isMultiRepo = isDocCrossRepo(doc, repoId.value);

            const docStats = getDocElementsStats(doc);
            let analyticsState: 'create' | 'update';

            if (!draft.isNew && oldFileName !== newFileName) {
              files.push({
                state: SwmResourceState.Renamed,
                path,
                content: serializeSwmd(draft.content, { baseUrl: config.BASE_URL, workspaceId: workspaceId.value }),
                isBase64Encoded: false,
                oldPath: `${config.SWM_FOLDER_IN_REPO}/${oldFileName}${config.SWMD_FILE_EXTENSION}`,
              });
              analyticsState = 'update';
            } else if (!draft.delete) {
              files.push({
                state: draft.isNew ? SwmResourceState.Created : SwmResourceState.Updated,
                path,
                content: serializeSwmd(draft.content, { baseUrl: config.BASE_URL, workspaceId: workspaceId.value }),
                isBase64Encoded: false,
              });
              analyticsState = draft.isNew ? 'create' : 'update';
            } else {
              files.push({
                state: SwmResourceState.Deleted,
                path,
              });
            }

            const imageDrafts = await db.images
              .where({
                workspaceId: workspaceId.value,
                repoId: repoId.value,
                branch: branch.value,
                userId: userId.value,
                draftId,
              })
              .toArray();

            if (imageDrafts.length > 0) {
              const imagesInDocument = listImageNodes(ProseMirrorNode.fromJSON(schema, draft.content.content)).map(
                (imageNode) => imageNode.node.attrs.src
              );

              for (const imageDraft of imageDrafts) {
                // Images may be added and removed in the document
                // Do not commit image drafts that were not ultimately used in the doc
                if (imagesInDocument.includes(imageDraft.path)) {
                  const content = await imageFileToBase64(imageDraft.file);
                  files.push({
                    state: SwmResourceState.Created,
                    path: removePrefix(decodeURI(imageDraft.path), '/'),
                    content,
                    isBase64Encoded: true,
                  });
                }
              }
            }

            const analyticsData: DocumentSavedAnalyticsPayloadCreatedOrUpdated = {
              'Document ID': draftId,
              'Document Name': draft.content.title,
              'From Branch': branch.value,
              'To Branch': options.createBranch ? options.createBranch : branch.value,
              'Save Date': new Date().toISOString(),
              Context: 'Repo',
              workspaceId: workspaceId.value,
              'Multi-repo': isMultiRepo,
              'Save Type': analyticsState,
              'Total Snippets': docStats.snippets,
              'Total Tokens': docStats.tokens,
              'Total Paths': docStats.paths,
              Features: draft.featuresUsed?.join(';'),
              'Is AI Enabled in Repo': draft.isAIEnabledForRepo,
            };

            docsAnalyticsData.push(analyticsData);
            break;
          }

          case DraftType.PLAYLIST: {
            const path = `${config.SWM_FOLDER_IN_REPO}/${generateSwmdFileName(draftId, draft.content.name)}${
              config.SWMD_PLAYLIST_EXTENSION
            }`;
            const draftsNamesToUpdate: Record<string, string> = getPlaylistStepsDraftsNames(draft);

            const newFileName = generateSwmdFileName(draftId, draft.content.name);
            const oldFileName = !draft.isNew && generateSwmdFileName(draftId, draft.originalTitle);

            if (!draft.isNew && oldFileName !== newFileName) {
              const playlistSWMD = serializePlaylistToSwmd({
                draftId,
                playlistDraft: draft,
                path,
                repoId: repoId.value,
                linksNamesToUpdate: draftsNamesToUpdate,
              });
              files.push({
                state: SwmResourceState.Renamed,
                path,
                content: playlistSWMD,
                isBase64Encoded: false,
                oldPath: `${config.SWM_FOLDER_IN_REPO}/${generateSwmdFileName(draftId, draft.originalTitle)}${
                  config.SWMD_PLAYLIST_EXTENSION
                }`,
              });
            } else if (!draft.delete) {
              const playlistSWMD = serializePlaylistToSwmd({
                draftId,
                playlistDraft: draft,
                path,
                repoId: repoId.value,
                linksNamesToUpdate: draftsNamesToUpdate,
              });
              files.push({
                state: draft.isNew ? SwmResourceState.Created : SwmResourceState.Updated,
                path,
                content: playlistSWMD,
                isBase64Encoded: false,
              });
            } else {
              files.push({
                state: SwmResourceState.Deleted,
                path,
              });
            }

            break;
          }
        }
      }

      progress?.('save-to-db');
      await saveResourcesToDb(draftsToSave);

      let commitBranch = branch.value;
      if (options.createBranch != null) {
        commitBranch = toValidBranchName(options.createBranch);
        progress?.('create-branch');
        await createBranch(commitBranch);
      }
      result.branch = commitBranch;

      let swmJson: SwmResourceFile | undefined;
      if (!(await repoConfig.isSwimmJsonInRepo({ repoId: repoId.value, revision: commitBranch }))) {
        // If there is not swimm.jsom in this current revision - we create and push it to the branch
        logger.info(`Repo ${repoId.value} doesn't have a swimm.json file, adding to batch commit`);
        swmJson = { state: SwmResourceState.Created, ...getSwimmJsonData(repoId.value) };
        files.push(swmJson);
      }

      progress?.('commit');
      await gitwrapper.pushMultipleFilesToBranch({
        files,
        repoId: repoId.value,
        branch: commitBranch,
        commitMessage: options.commitMessage,
      });

      for (const productAnalyticsData of docsAnalyticsData) {
        analytics.cloudTrack({
          identity: userId.value,
          event: productEvents.DOCUMENT_SAVED,
          payload: productAnalyticsData,
        });
      }

      if (options.createPr) {
        progress?.('create-pr');
        const createPrResponse = await gitwrapper.createPullRequest({
          repoId: repoId.value,
          fromBranch: commitBranch,
          toBranch: branch.value,
          title: options.prTitle,
          body: options.prMessage,
        });

        result.prNumber = parseInt(createPrResponse.url.substring(createPrResponse.url.lastIndexOf('/') + 1));
        result.prUrl = createPrResponse.url;
      }

      progress?.('finalizing');
      await Promise.all([
        saveContributions(draftsToSave, commitBranch, options.createPr, options.createBranch != null),
        deleteDrafts(draftIds),
        // TODO: find another way to refresh playlists state,
        // currently the new committed playlist need to be in the filesystem store
        getRepoSwmsLists({ repoId: repoId.value, branch: branch.value, forceRefresh: true }),
      ]);

      // PERFORMANCE: We're OK if these operations don't succeed (if the user closes the tab, for example)
      // TODO once SWMDV3 migration is complete, change the logBatchSaveEvent to fit to draft original input
      const draftsForEvents = draftsToSave.map((draft) => ({ ...draft, title: getDraftTitle(draft) }));
      void Promise.allSettled([logBatchSaveEvent(draftsForEvents, commitBranch)]);
      if (postCommits) {
        await Promise.allSettled(postCommits.map((func) => func(result as CommitResult)));
      }
      return result as CommitResult;
    } finally {
      committing.value = false;
      committedToBranchName.value = result.branch;
    }
  }

  async function doSaveDraft(
    id: string,
    draftContent: DraftContent,
    options?: { isNew?: boolean; originalTitle?: string }
  ) {
    await db.transaction('rw', db.drafts, async () => {
      const saveTime = Date.now();
      const updatedDraft: Pick<DbDraft, 'type' | 'content' | 'isNew' | 'modified'> = {
        ...draftContent,
        content: encryptDraftContent(draftContent.content, userConfigStore.salt),
        modified: saveTime,
      };
      if (options?.isNew != null) {
        updatedDraft.isNew = options.isNew;
      }

      const updated = await db.drafts.update(
        [userId.value, workspaceId.value, repoId.value, branch.value, id],
        updatedDraft
      );
      if (updated > 0) {
        Object.assign(drafts.value.get(id), { ...updatedDraft, content: draftContent.content });
      } else {
        const dbDraft: DbDraft = {
          ...updatedDraft,
          created: saveTime,
          userId: userId.value,
          workspaceId: workspaceId.value,
          repoId: repoId.value,
          branch: branch.value,
          originalTitle: options?.originalTitle,
          id,
        };
        const draft = { ...dbDraft, content: draftContent.content } as Draft;

        await db.drafts.put(dbDraft);
        if (drafts.value != null) {
          drafts.value.set(id, draft);
        }
      }
    });
  }

  async function updateAttrs(id: string, attrs: DraftAttributes): Promise<void> {
    await db.drafts.update([userId.value, workspaceId.value, repoId.value, branch.value, id], attrs);
    Object.assign(drafts.value.get(id), attrs);
  }

  async function newDoc(doc?: SwimmDocument): Promise<string> {
    const id = generateNewUnitId({ repoId: repoId.value });
    const repoName: string = store.getters['database/db_getRepoMetadata'](repoId.value).name;
    const docDraftContent: DraftContent = {
      type: DraftType.DOC,
      content: doc ?? {
        version: SWMD_VERSION,
        repoId: repoId.value,
        repoName,
        frontmatter: {},
        content: EMPTY_DOC_CONTENT,
      },
    };

    await doSaveDraft(id, docDraftContent, { isNew: true });

    return id;
  }

  async function newPlaylist(): Promise<string> {
    const id = generateNewUnitId({ repoId: repoId.value });
    const playlistDraftContent: DraftContent = {
      type: DraftType.PLAYLIST,
      content: EMPTY_PLAYLIST_CONTENT,
    };

    await doSaveDraft(id, playlistDraftContent, { isNew: true });

    return id;
  }

  const savingDraft = computed(() => {
    return draftSavingPromise.value != null;
  });

  function getDraftTitle(draft: Draft): string {
    if (draft.type === DraftType.DOC) {
      return draft.content.title;
    }
    return draft.content.name;
  }

  function getPlaylistStepsDraftsNames(playlistDraft: Draft): Record<string, string> {
    if (playlistDraft.type !== DraftType.PLAYLIST) {
      return {};
    }
    const draftsNamesToUpdate: Record<string, string> = {};
    playlistDraft.content.sequence.forEach((step) => {
      if (step.type === PlaylistSequenceStepTypes.UNIT || step.type === PlaylistSequenceStepTypes.PLAYLIST) {
        const stepDraft = drafts.value.get(step.id);
        if (stepDraft) {
          draftsNamesToUpdate[step.id] = getDraftTitle(stepDraft);
        }
      }
    });
    return draftsNamesToUpdate;
  }

  return {
    workspaceId,
    repoId,
    branch,
    userId,
    loading,
    drafts,
    saveStartTime,
    saveEndTime,
    fetchDrafts,
    getDraftsByFolder,
    hasAnyDrafts,
    saveDraft,
    saveDraftImmediately,
    saveImageDraft,
    getImageDraft,
    updateAttrs,
    moveDraftsToBranch,
    doesDraftExistOnBranch,
    discardDraft,
    deleteDraftsByFolder,
    deleteAllDrafts,
    newDoc,
    commit,
    committing,
    savingDraft,
    committedToBranchName,
    newPlaylist,
    getDraftTitle,
  };
});
