<script lang="ts">
import { Scales, Statuses } from '../../types';

export const BadgeVariants = [
  'primary',
  'secondary',
  'tertiary',
  ...Statuses.filter((status) => status !== 'none'),
] as const;
export type BadgeVariantsType = (typeof BadgeVariants)[number];

export const BadgeSizes = ['medium', 'large'] as const;
export type BadgeSizesType = Extract<Scales, (typeof BadgeSizes)[number]>;
</script>

<script setup lang="ts">
import { computed, useSlots } from 'vue';

import BaseLayoutIcons from '../BaseLayoutIcons/BaseLayoutIcons.vue';

const props = withDefaults(
  defineProps<{
    text?: string;
    variant?: BadgeVariantsType;
    size?: BadgeSizesType;
  }>(),
  {
    text: undefined,
    variant: 'secondary',
    size: 'medium',
  }
);

const slots = useSlots();

const hasLeftIcon = computed(() => !!slots.leftIcon);
const hasRightIcon = computed(() => !!slots.rightIcon);
const hasText = computed(() => !!slots.default || props.text);

const computedClasses = computed(() => ({
  [`badge--${props.variant}`]: true,
  [`badge--${props.size}`]: true,
}));
</script>

<template>
  <BaseLayoutIcons class="badge" :class="computedClasses">
    <template v-if="hasLeftIcon" #leftIcon>
      <slot name="leftIcon" />
    </template>
    <template #default>
      <span v-if="hasText" class="badge__text">
        <slot name="default">{{ text }}</slot>
      </span>
    </template>
    <template v-if="hasRightIcon" #rightIcon>
      <slot name="rightIcon" />
    </template>
  </BaseLayoutIcons>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.badge {
  $self: &;

  @include defaults;

  flex-shrink: 0;
  border-radius: var(--space-xxsmall);
  display: inline-block;

  &--primary {
    background-color: var(--color-bg-info-subtle);
    color: var(--color-text-brand);
  }

  &--secondary {
    background-color: var(--color-bg-default-subtle);
  }

  &--tertiary {
    background-color: var(--color-bg-default);
    box-shadow: inset 0 0 0 1px var(--color-border-default-subtle);
  }

  &--success {
    background-color: var(--color-bg-success-subtle);
    color: var(--color-text-success);
  }

  &--warning {
    background-color: var(--color-bg-warning-subtle);
    color: var(--color-text-warning);
  }

  &--info {
    background-color: var(--color-bg-info-subtle);
    color: var(--color-text-info);
  }

  &--danger {
    background-color: var(--color-bg-danger-subtle);
    color: var(--color-text-danger);
  }

  &--magic {
    background-color: var(--color-bg-magic-subtle);
    color: var(--color-text-magic);
  }

  &--medium {
    font-size: var(--font-size-xsmall);
    line-height: var(--line-height-default);
    padding: 0 var(--space-xxsmall);
  }

  &--large {
    font-size: var(--font-size-small);
    line-height: var(--line-height-default);
    padding: 0 var(--space-xsmall);
  }
}
</style>
