<script setup lang="ts">
import { type Ref, ref, toRef, watch } from 'vue';
import hash from 'object-hash';
import { merge } from 'lodash-es';
import { type TokenSuggestion } from '@swimm/shared';

import MenuItemToken, { type CachedTokenOffsetStyles } from '../MenuItemToken/MenuItemToken.vue';

import { useTokenSuggestionCachedHighlighters } from '../../composables/useTokenSuggestionCachedHighlighters';
import { type CachedHighlighters } from '../../types';

const props = withDefaults(
  defineProps<{
    query?: string;
    tokenSuggestions?: TokenSuggestion[];
    loading?: boolean;
    isFocused: (index: number) => boolean;
    setItemRef: (el: HTMLElement, index: number) => void;
  }>(),
  {
    query: '',
    tokenSuggestions: undefined,
  }
);

const emit = defineEmits<{
  selectSuggestion: [suggestion: TokenSuggestion];
  focusedSuggestion: [suggestion: TokenSuggestion];
  hoveredSuggestion: [suggestion: TokenSuggestion];
  caching: [caching: boolean];
  cachingMore: [cachingMore: boolean];
  highlightersCreated: [higlighters: CachedHighlighters];
}>();

const cachedOffsetTokenStyles = ref<CachedTokenOffsetStyles>({});

let cachedHighlighters = ref<CachedHighlighters>({});
let cachedTokenSuggestions = ref<TokenSuggestion[]>([]);
let isCaching = ref(false);
let isCachingMore = ref(false);

({ cachedHighlighters, cachedTokenSuggestions, isCaching, isCachingMore } = useTokenSuggestionCachedHighlighters(
  toRef(props, 'tokenSuggestions') as Ref<TokenSuggestion[]>
));

function cacheTokenOffsetStyles(styles: CachedTokenOffsetStyles) {
  cachedOffsetTokenStyles.value = merge(cachedOffsetTokenStyles.value, styles);
}

function onSelectSuggestion(suggestion: TokenSuggestion) {
  emit('selectSuggestion', suggestion);
}

function onHoveredSuggestion(suggestion: TokenSuggestion) {
  emit('hoveredSuggestion', suggestion);
}

watch(
  () => isCaching.value,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      emit('caching', newValue);
    }
  },
  { immediate: true }
);

watch(
  () => isCachingMore.value,
  (newValue, oldValue) => {
    if (newValue !== oldValue && !props.loading && !isCaching.value) {
      emit('cachingMore', newValue);
    }
  },
  { immediate: true }
);

watch(
  () => cachedHighlighters.value,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      emit('highlightersCreated', newValue);
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="menu-items-token">
    <MenuItemToken
      v-for="(item, index) in cachedTokenSuggestions"
      :key="hash(item)"
      :ref="(el: any) => { 
          if (el?.root) {
            setItemRef(el.root, index) 
          } 
        }"
      :query="query"
      :token="(item as TokenSuggestion)"
      :focused="isFocused(index)"
      :cached-highlighters="cachedHighlighters"
      :cached-offset-styles="cachedOffsetTokenStyles"
      @click="onSelectSuggestion(item)"
      @keydown.enter="onSelectSuggestion(item)"
      @mouseenter="onHoveredSuggestion(item)"
      @offset-styles-applied="cacheTokenOffsetStyles"
    />
  </div>
</template>
