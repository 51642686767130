import type { Plugin } from 'vue';
import { setupDevtoolsPlugin } from '@vue/devtools-api';
import { Editor } from '@tiptap/core';

declare const __VUE_PROD_DEVTOOLS__: boolean | undefined;

const STATE_TYPE = 'Tiptap';

interface TiptapVueDevtoolsMarker {
  __TIPTAP_VUE_DEVTOOLS_REGISTERED__?: boolean;
}

// TODO We can probably do more if we hook the creation of `Editor` somehow,
// e.g. A plugin or monekypatch, e.g. A custom inspector and timeline instead of
// only custom component state
const plugin: Plugin = {
  install(app) {
    if (process.env.NODE_ENV === 'development' || __VUE_PROD_DEVTOOLS__) {
      setupDevtoolsPlugin(
        {
          id: 'tiptap-plugin',
          label: 'Tiptap',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          app: app as any,
          componentStateTypes: [STATE_TYPE],
        },
        (api) => {
          api.on.inspectComponent((payload, _context) => {
            for (const state of payload.instanceData.state) {
              if (state.value instanceof Editor) {
                if (!(state.value as TiptapVueDevtoolsMarker).__TIPTAP_VUE_DEVTOOLS_REGISTERED__) {
                  state.value.on('update', () => {
                    api.notifyComponentUpdate(payload.componentInstance);
                  });
                  state.value.on('selectionUpdate', () => {
                    api.notifyComponentUpdate(payload.componentInstance);
                  });

                  (state.value as TiptapVueDevtoolsMarker).__TIPTAP_VUE_DEVTOOLS_REGISTERED__ = true;
                }

                payload.instanceData.state.push({
                  type: STATE_TYPE,
                  key: state.key,
                  value: {
                    _custom: {
                      type: 'Editor',
                      tooltip: 'Test',
                      display: 'Editor',
                      value: {
                        'state.doc': state.value.state.doc.toJSON(),
                        'state.doc.content.size': state.value.state.doc.content.size,
                        'state.selection.anchor': state.value.state.selection.anchor,
                        'state.selection.head': state.value.state.selection.head,
                      },
                    },
                  },
                });
              }
            }
          });
        }
      );
    }
  },
};

export default plugin;
