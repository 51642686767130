import { ATTRIBUTE, parseHtmlAttrs, renderHtmlAttrs } from '@/markdownit/plugins/html';

const SWM_META_RE = new RegExp('<SwmMeta(' + ATTRIBUTE + '*)\\s*\\>.*<\\/SwmMeta>\\s*$');

/**
 * Parse the SwmMeta tag at the end of the document.
 */
export function parseSwmMeta(text: string): { meta: Record<string, string>; content: string } | null {
  const match = text.match(SWM_META_RE);
  if (match == null) {
    return null;
  }

  const attrs = parseHtmlAttrs(match[1]);
  const meta = Object.fromEntries(attrs);

  const content = text.slice(0, match.index);

  return {
    meta,
    content,
  };
}

/**
 * Serialize the SwmMeta tag to be appended at the end of the document.
 */
export function serializeSwmMeta(meta: Record<string, string>, baseUrl: string, noBranding?: boolean): string {
  return (
    '<SwmMeta' +
    renderHtmlAttrs(Object.entries(meta)) +
    `>${noBranding ? '' : `<sup>Powered by [Swimm](${baseUrl}/)</sup>`}</SwmMeta>`
  );
}
