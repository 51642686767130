<script setup lang="ts">
import { SwModal } from '@swimm/ui';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useFoldersStore } from '@/modules/folders/store/folders';
import FolderTree from '@/modules/folders/components/FolderTree.vue';
// @ts-ignore
import path from 'path-browserify';
import { DocumentationTypeToFolderItemType, type Folder } from '@/modules/folders/types';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';
import { useRoute } from 'vue-router';
import UserDialog from '@/common/components/modals/UserDialog.vue';
import { debounce } from 'lodash-es';
import { TreeNode } from '../types.js';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';

const props = defineProps<{
  show: boolean;
  repoId: string;
  items: {
    id?: string;
    draftId?: string;
    isNew?: boolean;
    folderId?: string;
    documentationType: keyof typeof DocumentationTypeToFolderItemType;
  }[];
}>();

const emit = defineEmits(['close', 'change']);

defineExpose({ onMoveFolder });
const foldersStore = useFoldersStore();

const {
  getRepoRootFolder,
  getFolderPath,
  getFolder,
  moveItemsToFolder,
  isSubfolderHasNameConflict,
  getItemParentFolder3,
} = foldersStore;
const { currentFolderIdByRepo, foldersByRepo } = storeToRefs(foldersStore);
const currentFolder = computed<Folder>(
  () => foldersByRepo.value[props.repoId]?.[currentFolderIdByRepo.value[props.repoId]]
);

const drafts3Store = useDrafts3Store();

const analytics = useAnalytics();
const route = useRoute();
const isCreatedNewFolder = ref(false);
const confirmationDialog = ref<InstanceType<typeof UserDialog> | null>(null);
const folderWithSameName = ref('');

function generateFolderTree(baseFolders: Folder[]): TreeNode {
  const root = {
    id: '',
    name: '',
    children: [],
    path: '',
    type: 'directory',
    isRoot: true,
  };
  baseFolders.forEach((folder) => addFolderToTree(root, folder.id));
  return root;
}

function addFolderToTree(node: TreeNode, folderId: string) {
  const folderInfo = foldersByRepo.value[props.repoId][folderId];
  if (!folderInfo) {
    return;
  }
  const newNode: TreeNode = {
    id: folderInfo.id,
    name: folderInfo.name,
    children: [],
    type: 'directory',
    path: path.join(node.path, folderInfo.name),
  };
  node.children.push(newNode);
  folderInfo.children.forEach((item) => {
    addFolderToTree(newNode, item.id);
  });
}

const folderTree = computed(() => {
  const rootFolder = getRepoRootFolder(route.params.repoId as string);
  return generateFolderTree(rootFolder ? [rootFolder] : []);
});

const selectedFolderPath = computed(() => {
  let folder = getItemParentFolder3(props.items[0]?.id, props.repoId);
  if (folder == null) {
    folder = getRepoRootFolder(props.repoId);
  }

  if (folder) {
    return getFolderPath(folder.id, props.repoId);
  }
  return '';
});

watch(
  () => props.show,
  (newValue) => {
    if (newValue) {
      analytics.track(productEvents.SHOWN_SELECT_FOLDER_MODAL, {
        'Page Name': route.name,
        'Initial Folder ID': currentFolder.value.id,
        'Initial Folder Path': selectedFolderPath.value,
      });
    }
    isCreatedNewFolder.value = false;
  }
);

async function onMoveFolder(folderId: string, query: string) {
  const itemsToMove = props.items.map((item) => ({
    ...item,
    folderType: DocumentationTypeToFolderItemType[item.documentationType],
  }));
  const { hasNameConflicts, folders: foldersWithSameName } = isSubfolderHasNameConflict(
    folderId,
    props.repoId,
    itemsToMove
  );

  if (hasNameConflicts) {
    folderWithSameName.value = foldersWithSameName[0];
    await confirmationDialog.value?.showDialog();
    emit('close');
    return;
  }

  const folder = getFolder(folderId, props.repoId);
  for (const [index, item] of props.items.entries()) {
    if (item.isNew) {
      await drafts3Store.updateAttrs(item.id, { folderId, folderIndex: folder.children.length + index });
    }
  }

  await moveItemsToFolder(folderId, props.repoId, itemsToMove);

  emit('change', folderId);
  emit('close');

  analytics.track(productEvents.SELECTED_FOLDER_IN_SELECT_FOLDER_MODAL, {
    'Selected Folder ID': folderId,
    'Initial Folder ID': selectedFolderPath.value,
    'Is Newly Created': isCreatedNewFolder.value,
    ...(query ? { Query: query } : {}),
  });
}

function onClose() {
  emit('close');
  analytics.track(productEvents.CLICKED_CANCEL_IN_SELECT_FOLDER_MODAL);
}

function onNewFolderClick(folderId: string) {
  const folderPath = getFolderPath(folderId, props.repoId);
  analytics.track(productEvents.CLICKED_CREATE_FOLDER_IN_SELECT_FOLDER_MODAL, {
    'Folder ID': folderId,
    'Folder Path': folderPath,
  });
}

function onNewFolderCreate(parentFolderId: string) {
  isCreatedNewFolder.value = true;
  const parentFolderPath = getFolderPath(parentFolderId, props.repoId);
  analytics.track(productEvents.CLICKED_FOLDER_IN_SELECT_FOLDER_MODAL, {
    'Parent Path': parentFolderPath,
  });
}

const onFilter = debounce((value) => {
  analytics.track(productEvents.SEARCHED_IN_SELECT_FOLDER_MODAl, {
    Query: value,
  });
}, 400);
</script>

<template>
  <SwModal :padded="false" :show-modal="show" heading="Move to folder" @close="onClose">
    <div class="move-folder-modal">
      <FolderTree
        v-if="folderTree.children[0]"
        :items="items"
        :tree="folderTree.children[0]"
        :selected-path="selectedFolderPath"
        :root-folder="getRepoRootFolder(route.params.repoId as string) ?? undefined"
        @node-select="onMoveFolder"
        @new-folder-create="onNewFolderCreate"
        @new-folder-click="onNewFolderClick"
        @filter="onFilter"
      />
    </div>
  </SwModal>
  <UserDialog
    ref="confirmationDialog"
    title="Cannot move items"
    :body="`Cannot move &quot;${folderWithSameName}&quot;, destination already contains a folder with that name.`"
    confirm-text="Ok"
    cancel-text=""
  />
</template>

<style scoped lang="postcss">
.move-folder-modal {
  width: 360px;
  height: 480px;
}
</style>
