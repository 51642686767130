<template>
  <InstallGitHubApp
    class="align-content"
    v-if="showInstallGitHubApp"
    :origin="origin"
    :repo-id="repoId"
    :workspace-id="workspaceId"
    description="The Swimm GitHub App automatically keeps your docs synced with your code by verifying them on every PR"
  />
  <GitHubAppSettingsSection v-else :repo-id="repoId" :workspace-id="workspaceId" @close="$emit('close')" />
</template>

<script>
import { mapGetters } from 'vuex';
import InstallGitHubApp from './InstallGitHubApp.vue';
import GitHubAppSettingsSection from '@/common/components/CISettings/CISettingsSection.vue';
import { GitProviderName } from '@swimm/shared';
import { storeToRefs } from 'pinia';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';

export default {
  components: { GitHubAppSettingsSection, InstallGitHubApp },
  props: {
    origin: { type: String, required: true },
    repoId: { type: String, default: null },
    workspaceId: { type: String, required: true },
  },
  emits: ['close'],
  setup() {
    const { workspaceFeatureFlags } = storeToRefs(useWorkspaceStore());
    return { workspaceFeatureFlags };
  },
  computed: {
    ...mapGetters('database', ['db_getRepoMetadata']),
    repoMetadata() {
      return this.repoId && this.db_getRepoMetadata(this.repoId);
    },
    isGitHubAppInstalled() {
      return this.repoMetadata?.isGithubAppInstalled;
    },
    isGitHubCloud() {
      return this.repoMetadata?.provider === GitProviderName.GitHub;
    },
    showInstallGitHubApp() {
      if (this.workspaceFeatureFlags.force_use_cli) {
        return false;
      }
      return this.isGitHubCloud && !this.isGitHubAppInstalled;
    },
  },
};
</script>

<style scoped>
.align-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
