<script setup>
import BillingPlans from '@/common/components/Billing/BillingPlans.vue';
import SettingsModalSection from '@/modules/core/components/settings-modal/SettingsModalSection.vue';
import { useRoute } from 'vue-router';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { pageEvents, productEvents } from '@swimm/shared';
import { computed, ref } from 'vue';
import { capitalize } from 'lodash-es';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { useStore } from 'vuex';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import swal from 'sweetalert';
import { useBillingStore } from '@/modules/billing/store/billing';
import { useWorkspaceSettingsModalStore } from '@/modules/workspace/modals/settings/store/workspace-settings';
import { storeToRefs } from 'pinia';
import { useNotificationsStore } from '@swimm/editor';

const { showWorkspaceSettingsModal } = storeToRefs(useWorkspaceSettingsModalStore());

const route = useRoute();
const store = useStore();
const analytics = useAnalytics();
const { trialSuccess, trialFailure } = useBillingStore();
const { isOnTrialPlan } = storeToRefs(useBillingStore());
const { addNotification } = useNotificationsStore();

const emit = defineEmits(['upgrade', 'back', 'started-trial']);

const workspaceId = ref(route.params.workspaceId);
const processingTrial = ref(false);

reportPlansPageVisit();

const workspace = computed(() => store.getters['database/db_getWorkspace'](workspaceId.value));

function onPlanSelected({
  productSKU,
  selectedBillingPeriod,
  selectedQuantity,
  isUpgrade,
  isTrial,
  isDowngrade,
  isPeriodChange,
  isEnterprise,
  allowUpgrade,
}) {
  if (isPeriodChange || isDowngrade || (isUpgrade && !allowUpgrade)) {
    window.open('mailto:info@swimm.io', '_blank');
    return;
  }

  analytics.track(productEvents.CLICKED_UPGRADE, { Plan: capitalize(productSKU) });

  if (isEnterprise) {
    window.open('https://calendly.com/swimm-demo-team/enterprise-plan', '_blank');
    return;
  }

  if (isTrial) {
    addNotification('Starting your trial...', { loader: true });
    startTrial(productSKU, selectedQuantity);
    showWorkspaceSettingsModal.value = false;

    return;
  }

  emit('upgrade', {
    productSKU,
    billingPeriod: selectedBillingPeriod,
    hasPaymentData: isUpgrade && !isOnTrialPlan.value,
    selectedQuantity,
  });
}

async function startTrial(planName, quantity) {
  try {
    processingTrial.value = true;
    const subscribeResult = await CloudFunctions.subscribeToTrial({
      workspaceId: workspaceId.value,
      productSKU: planName,
      billingPeriod: 'ANNUALLY', // since we are removing monthly pricing
      quantity,
    });
    if (subscribeResult?.data?.status !== 'success') {
      throw new Error(`Failed starting trial subscription to workspace ${workspaceId.value} (productSKU: ${planName})`);
    }
    await trialSuccess(planName, workspace.value.name);
    emit('started-trial');
    addNotification('Your 14-day Team plan trial has started!', { icon: 'check-fill' });
  } catch (error) {
    trialFailure(error);
    await swal({
      title: 'Something happened - could not start a trial',
      content: { element: SWAL_CONTACT_US_CONTENT() },
    });
  } finally {
    processingTrial.value = false;
  }
}

function reportPlansPageVisit() {
  analytics.pageVisit(pageEvents.VIEW_BILLING_AND_PLANS, {
    Type: 'Available Plans',
    'Workspace ID': workspaceId,
  });
}
</script>

<template>
  <SettingsModalSection title="Change plan" show-back @back="emit('back')">
    <div class="plans">
      <BillingPlans
        :workspace-id="workspaceId"
        :workspace-license="workspace.license"
        :is-in-trial="isOnTrialPlan"
        @plan-click="onPlanSelected"
      />
    </div>
  </SettingsModalSection>
</template>

<style scoped lang="postcss">
.plans {
  margin: auto 0;
}
</style>
