<template>
  <div class="branch clickable" :class="{ disabled: isReadonly }">
    <div
      v-if="!isChoosingBranch"
      class="selected-branch-select-closed"
      @click.prevent="openBranchSelection"
      data-testid="repo-branch-selector"
    >
      <div class="selected-branch">
        <Icon class="body-S" name="branch" />
        <span class="selected-branch-name" data-testid="branch-name">
          <EllipsisTooltip
            long
            :content="selectedBranch.name"
            :length="16"
            class="text-ellipsis body-S data-hj-suppress"
          >
            {{ selectedBranch.name
            }}<span v-if="showProtected && selectedBranch.protected"> (protected)</span></EllipsisTooltip
          >
        </span>
      </div>
      <Icon v-if="!loading" name="arrow-down" />
      <div v-else class="loader"></div>
    </div>
    <div v-else>
      <v-select
        ref="branchSelect"
        v-model="selectedBranch"
        label="name"
        :disabled="isReadonly"
        :filterable="false"
        :autoscroll="false"
        :append-to-body="appendToBody"
        @search="onSearch"
        @open="onOpen"
        :options="renderedBranches"
        :get-option-key="(option) => option.name"
        :clearable="false"
        placeholder="Select branch..."
        class="branch-selector"
        @option:selected="handleBranchChange"
        @close="branchSelectionClosed"
        :uid="uid"
      >
        <template #search="{ attributes, events }">
          <input v-autoFocus v-bind="attributes" class="vs__search" v-on="events" />
        </template>
        <template #no-options>
          <div v-if="loadingBranches || loadingActiveBranches" class="loading-branches">
            <Loader secondary class="branch-loader" /><span>Loading branches...</span>
          </div>
          <div v-else-if="getRepoBranchesErrorMessage" class="error">{{ getRepoBranchesErrorMessage }}</div>
          <div v-else-if="suggestNewBranch"><!-- Hide the default no results message --></div>
          <div v-else-if="branches.length == 0">Sorry, no branches available.</div>
          <div v-else>Sorry, no matching branches.</div>
        </template>
        <template #list-header>
          <header class="branch-selector__header">
            <SwText variant="subtitle-S" weight="bold">{{ title }}</SwText>
            <div v-if="!branchesAreLoading" class="branch-selector__refresh" @click.stop="refreshBranches">
              <Icon v-tooltip="'Refresh branches'" name="refresh" />
            </div>
            <Loader v-else secondary class="branch-loader" />
          </header>
          <template v-if="!searching">
            <BranchSelectorNewBranch v-if="suggestNewBranch" @click="addNewBranch" />
            <Divider class="divider" />
            <template v-if="!loadingActiveBranches && activeBranches.length > 0">
              <div class="active-branches">
                <SwText variant="subtitle-S" weight="regular" class="branch-selector__title">{{
                  getActiveBranchesText()
                }}</SwText>
                <BranchSelectorItem
                  v-for="activeBranch in activeBranches"
                  :key="activeBranch.name"
                  :name="activeBranch.name"
                  :is-selected="isBranchSelected(activeBranch.name)"
                  custom
                  @click="selectActiveBranch(activeBranch)"
                />
              </div>
              <Divider class="divider" />
            </template>
          </template>
        </template>
        <template #list-footer>
          <div v-show="hasMoreBranchesToRender" ref="load">
            <Loader secondary class="branch-scroll-loader" />
          </div>
          <BranchSelectorNewBranch v-if="suggestNewBranch && query" :query="query" @click="addNewBranch" />
        </template>
        <template #option="branch">
          <BranchSelectorItem
            :name="branch.name"
            :is-selected="isBranchSelected(branch.name)"
            :data-testid="branch.name === defaultBranchName ? 'default-branch' : undefined"
            @click="() => (branch.name === defaultBranchName ? goToDefaultBranch() : selectActiveBranch(branch))"
          >
            <strong v-if="defaultBranchName === branch.name">(default)</strong>
          </BranchSelectorItem>
        </template>
      </v-select>
    </div>
  </div>
  <div v-if="shouldShowBranchHelpTooltip">
    <HelpTooltip tooltip-key="branch-navigation" placement="right" title="Branch navigation">
      <template #subtitle>
        {{
          'Write docs in a side branch before merging to the default branch.\n\nWe recommend reading docs in your default branch.'
        }}
      </template>
    </HelpTooltip>
  </div>
</template>

<script lang="ts">
import _ from 'lodash-es';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { mapActions, mapGetters } from 'vuex';
import swal from 'sweetalert';
import Fuse from 'fuse.js';

import { getRepoDefaultBranch } from '@/remote-adapters/local_repo';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import { exceptionAlert } from '@/common/utils/alert';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { PageRoutesNames } from '@/common/consts';
import { useRouting } from '@/common/composables/routing';
import EllipsisTooltip from '@/common/components/organisms/EllipsisTooltip.vue';
import BranchSelectorItem from '@/common/components/organisms/BranchSelectorItem.vue';
import BranchSelectorNewBranch from '@/common/components/organisms/BranchSelectorNewBranch.vue';

import { useFiltersStore } from '@/modules/core/filters-row/useFiltersStore';
import HelpTooltip from '@/modules/core/components/HelpTooltip.vue';
import { useHelpTooltipsStore } from '@/modules/core/stores/useHelpTooltipsStore';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useDemoStore } from '@/modules/demo/demo';

import {
  Branch,
  GitProviderName,
  GitProviderRateLimitError,
  config,
  getLoggerNew,
  gitwrapper,
  productEvents,
} from '@swimm/shared';
import { autoFocus } from '@swimm/editor';

import { Divider } from '@swimm/ui';
import { useLocalStateStatusStore } from '@/common/store/localStateStatus';

const logger = getLoggerNew(__modulename);
const RENDER_BATCH_LIMIT = 100;

export default defineComponent({
  components: {
    EllipsisTooltip,
    Divider,
    HelpTooltip,
    BranchSelectorItem,
    BranchSelectorNewBranch,
  },
  directives: {
    autoFocus,
  },
  props: {
    loading: { type: Boolean, default: false },
    isReadonly: { type: Boolean, default: false },
    repoId: { type: String, required: true },
    showProtected: { type: Boolean, default: false },
    appendToBody: { type: Boolean, default: false },
    uid: { type: String, default: '' },
    validate: { type: Function, default: null },
    inBatchCommit: { type: Boolean, default: false },
    batchCommitDraftCount: { type: Number, default: null },
    suggestNewBranch: { type: Boolean, default: false },
  },
  emits: ['add-new-branch', 'branch-changed', 'closed', 'opened'],
  setup() {
    const analytics = useAnalytics();
    const reposStore = useReposStore();
    const localStateStatusStore = useLocalStateStatusStore();
    const { setIsRepoLocalStateDataReady } = localStateStatusStore;
    const { localStateDataReady } = storeToRefs(localStateStatusStore);
    const filtersStore = useFiltersStore();
    const { getCurrentOrDefaultBranch, isBranchProtected } = useRouting();
    const helpTooltipsStore = useHelpTooltipsStore();
    const { isOnDummyRepoPage } = storeToRefs(useDemoStore());

    const shouldShowBranchHelpTooltip = ref(false);
    const { isBranchChanged } = storeToRefs(helpTooltipsStore);
    const { setBranchChanged } = helpTooltipsStore;

    onMounted(() => {
      if (isBranchChanged.value) {
        shouldShowBranchHelpTooltip.value = true;
        setBranchChanged(false);
      }
    });
    const { isChoosingBranch, reposStateData } = storeToRefs(reposStore);
    const { setRepoStateBranchData } = reposStore;

    function getFuseOptions(query) {
      return {
        keys: ['name'],
        minMatchCharLength: Math.max(Math.min(query.length, 5), 2),
      };
    }

    const activeBranches = ref([]);
    const defaultBranchName = ref('');
    const selectedBranch = ref({ name: '', isDefaultBranch: false, protected: false });
    const showSelectedBranch = computed(
      () => !activeBranches.value.some((activeBranch) => activeBranch.name === selectedBranch.value.name)
    );

    function filterBranches(options: Branch[], query: string) {
      if (!query.trim()) {
        options = _.orderBy(options, ({ name }) => name === defaultBranchName.value, 'desc');
        if (showSelectedBranch.value) {
          options = _.orderBy(options, ({ name }) => name === selectedBranch.value.name, 'desc');
        }
        return options;
      }
      const fuse = new Fuse(options, getFuseOptions(query));
      return fuse.search(query).map((result) => result.item);
    }

    return {
      analytics,
      shouldShowBranchHelpTooltip,
      filtersStore,
      reposStateData,
      localStateDataReady,
      setIsRepoLocalStateDataReady,
      setRepoStateBranchData,
      getCurrentOrDefaultBranch,
      isBranchProtected,
      isOnDummyRepoPage,
      filterBranches,
      isChoosingBranch,
      selectedBranch,
      defaultBranchName,
      activeBranches,
    };
  },
  data() {
    return {
      originalBranch: '',
      loadingBranches: false,
      searching: false,
      observer: null,
      loadingActiveBranches: false,
      renderLimit: RENDER_BATCH_LIMIT,
      query: '',
    };
  },
  computed: {
    ...mapGetters('filesystem', ['fs_getRepoBranches', 'fs_getRepoBranchesAreLoading', 'fs_getRepoBranchesError']),
    isRepoLocalStateDataReady() {
      if (!this.localStateDataReady) {
        return false;
      }
      return this.localStateDataReady.has(this.repoId);
    },
    getRepoBranchesErrorMessage() {
      const error = this.fs_getRepoBranchesError(this.repoId);
      if (!error) {
        return null;
      }
      if (error instanceof GitProviderRateLimitError) {
        return error.message;
      }
      return 'Failed to list branches in repository.';
    },
    branches(): Branch[] {
      return this.fs_getRepoBranches(this.repoId);
    },
    branchesAreLoading(): boolean {
      return this.fs_getRepoBranchesAreLoading(this.repoId);
    },
    filteredBranches() {
      return this.filterBranches(this.branches, this.query);
    },
    renderedBranches() {
      return this.filteredBranches.slice(0, this.renderLimit);
    },
    hasMoreBranchesToRender() {
      return this.renderedBranches.length < this.filteredBranches.length;
    },
    isCurrentBranchDefault() {
      return this.defaultBranchName === this.selectedBranch.name;
    },
    isDefaultBranchLoaded() {
      // Check if the default branch loaded to the branches list - can't switch to it if doesn't.
      return this.branches.some((branch) => branch.name === this.defaultBranchName);
    },
    isEditPage() {
      return [
        PageRoutesNames.DOC_NEW,
        PageRoutesNames.DOC_EDIT,
        PageRoutesNames.PLAYLIST_NEW,
        PageRoutesNames.PLAYLIST_EDIT,
      ].includes(this.$route.name as string);
    },
    title() {
      return this.searching ? 'Search results' : 'Select branch';
    },
  },
  watch: {
    async $route() {
      await this.updateDisplayedBranch();
    },
    isRepoLocalStateDataReady: {
      immediate: true,
      async handler(value) {
        if (value) {
          await this.fetchActiveBranches();
        }
      },
    },
  },
  async created() {
    await this.updateDisplayedBranch();
    await this.setIsRepoLocalStateDataReady(this.repoId);
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll, { rootMargin: '500px 0px 0px 0px' });
  },
  unmounted() {
    this.observer?.disconnect();
  },
  methods: {
    ...mapActions('filesystem', ['fetchRepoBranches']),
    async reFetchBranches() {
      if (this.loading || this.loadingBranches) {
        return;
      }
      this.loadingBranches = true;
      try {
        await this.fetchRepoBranches(this.repoId);
      } catch (err) {
        logger.error({ err }, `Failed to list branches in repository, error: ${err}`);
        return;
      } finally {
        this.loadingBranches = false;
      }
    },
    async fetchActiveBranches() {
      if (this.loading) {
        return;
      }
      this.activeBranches = [];
      this.loadingActiveBranches = true;
      try {
        if (!this.isReadonly && !this.isOnDummyRepoPage) {
          this.activeBranches = (await this.getActiveBranchesFromProvider())
            ?.filter((branchName) => branchName !== this.defaultBranchName)
            ?.map((branchName) => ({ name: branchName }));
        }
      } catch (err) {
        logger.error({ err }, `Failed to list user active branches in repository, error: ${err}`);
        await exceptionAlert({
          title: 'Failed to list user active branches in repository.',
          error: err,
          logError: false,
        });
        return;
      } finally {
        this.loadingActiveBranches = false;
      }
    },
    async getActiveBranchesFromProvider(): Promise<string[]> {
      try {
        const MAX_ACTIVE_BRANCHES = 3; // show up to 3 active branches in the branch selector
        return await gitwrapper.getUserActiveBranches(this.repoId, MAX_ACTIVE_BRANCHES);
      } catch (err) {
        logger.error({ err }, `Could not get active branches. Details: ${err.message}`);
        return [];
      }
    },
    async openBranchSelection() {
      if (this.isReadonly) {
        return;
      }
      this.isChoosingBranch = true;
      this.$nextTick(() => {
        this.$refs.branchSelect['open'] = true;
      });
      this.analytics.track(productEvents.OPENED_BRANCH_SELECTOR, {
        'From Branch': this.originalBranch,
        'Is Batch Commit': this.inBatchCommit,
        ...(this.batchCommitDraftCount ? { 'Batch Commit Draft Count': this.batchCommitDraftCount } : {}),
        'Page Name': this.$route.name,
      });
    },
    async updateDisplayedBranch() {
      this.originalBranch = await this.getCurrentOrDefaultBranch(this.repoId);
      if (this.originalBranch) {
        const repoFromState = this.reposStateData[this.repoId];
        const isProtectedBranch = await this.isBranchProtected({
          repoId: this.repoId,
          branch: this.originalBranch,
        });
        this.selectedBranch = {
          name: this.originalBranch,
          isDefaultBranch: repoFromState.defaultBranch === this.originalBranch,
          protected: isProtectedBranch,
        };
        this.defaultBranchName = repoFromState ? repoFromState.defaultBranch : this.originalBranch;
      }
    },
    goToDefaultBranch() {
      if (this.isCurrentBranchDefault || !this.isDefaultBranchLoaded) {
        return;
      }
      this.handleBranchChange({ isDefaultBranch: true });
    },
    getExistingBranch(name) {
      return this.branches.find((branch) => branch.name === name);
    },
    reportBranchChange({ cancelReason = null } = {}) {
      this.analytics.track(cancelReason ? productEvents.CANCELED_BRANCH_SWITCH : productEvents.SWITCHED_BRANCH, {
        'From Branch': this.originalBranch,
        'To Branch': this.selectedBranch.name,
        'Recently Worked On': this.activeBranches.some(
          (activeBranch) => activeBranch.name === this.selectedBranch.name
        ),
        'Used Filter': this.searching,
        'Repo ID': this.repoId,
        'Is Batch Commit': this.inBatchCommit,
        ...(this.batchCommitDraftCount ? { 'Batch Commit Draft Count': this.batchCommitDraftCount } : {}),
        ...(cancelReason ? { 'Cancel Reason': cancelReason } : {}),
        'Page Name': this.$route.name,
      });
    },
    async handleBranchChange({ isDefaultBranch = false }) {
      if (!isDefaultBranch && this.originalBranch === this.selectedBranch.name) {
        this.reportBranchChange({ cancelReason: 'Same Branch' });
        this.isChoosingBranch = false;
        return;
      }
      const repoDataFromState = this.reposStateData[this.repoId];
      if (isDefaultBranch) {
        let defaultBranch = this.getExistingBranch(repoDataFromState.defaultBranch);
        if (!defaultBranch) {
          const defaultBranchResponse = await getRepoDefaultBranch({ repoId: this.repoId });
          if (defaultBranchResponse.code !== config.SUCCESS_RETURN_CODE) {
            await swal({ title: 'Failed to get default branch.', content: { element: SWAL_CONTACT_US_CONTENT() } });
            this.reportBranchChange({ cancelReason: 'Failed' });
            return;
          }
          defaultBranch = this.getExistingBranch(defaultBranchResponse.branch);
          logger.warn(
            `Default branch ${repoDataFromState.defaultBranch} no longer exists, setting new default branch: ${defaultBranch}`,
            { service: 'branch-selector' }
          );
          await this.setRepoStateBranchData(this.repoId, { defaultBranch: defaultBranch.name });
        }
        this.selectedBranch = { ...defaultBranch, isDefaultBranch: true };
      }

      if (this.validate && !(await this.validate(this.selectedBranch.name))) {
        this.reportBranchChange({ cancelReason: 'Canceled By User' });
        await this.updateDisplayedBranch(); // reset selected branch
        this.isChoosingBranch = false;
        return;
      } else {
        await this.setRepoStateBranchData(this.repoId, {
          branch: this.selectedBranch.name,
          isProtectedBranch: this.selectedBranch.protected,
        });
      }

      this.$emit('branch-changed', { newBranchName: this.selectedBranch.name, isDefaultBranch });
      this.reportBranchChange();
      this.isChoosingBranch = false;
      this.filtersStore.resetFilters();
    },
    branchSelectionClosed() {
      this.observer?.disconnect();
      this.isChoosingBranch = false;
      this.clean();
      // Workaround to pop the `close` event after v-click-outside is called in BatchCommitModal.
      setTimeout(() => this.$emit('closed'), 50);
    },
    clean() {
      this.searching = false;
      this.query = '';
      this.renderLimit = RENDER_BATCH_LIMIT;
    },
    async refreshBranches() {
      this.clean();
      await this.fetchActiveBranches();
      await this.reFetchBranches();
    },
    onSearch(query) {
      this.searching = Boolean(query);
      this.query = query;
    },
    async onOpen() {
      await this.$nextTick();
      this.observer.observe(this.$refs.load);
      this.$emit('opened');
    },
    async infiniteScroll([{ isIntersecting, target }]: IntersectionObserverEntry[]) {
      // copied from https://vue-select.org/guide/infinite-scroll.html
      if (isIntersecting) {
        const branchesList = (target as HTMLSelectElement).offsetParent;
        const scrollTop = (target as HTMLSelectElement).offsetParent.scrollTop;
        this.renderLimit += RENDER_BATCH_LIMIT;
        await this.$nextTick();
        branchesList.scrollTop = scrollTop;
      }
    },
    async selectActiveBranch(activeBranch) {
      this.selectedBranch = activeBranch;
      await this.handleBranchChange(this.selectedBranch);
    },
    addNewBranch() {
      this.analytics.track(productEvents.CLICKED_NEW_BRANCH_IN_BATCH_COMMIT_BRANCH_SELECTOR, {
        Query: this.query ? this.query : null,
        'Is On Default Branch': this.isCurrentBranchDefault,
        'Page Name': this.$route.name,
      });
      this.$emit('add-new-branch', this.query);
      this.branchSelectionClosed();
    },
    isBranchSelected(name: string) {
      if (!this.selectedBranch) {
        return false;
      }

      return this.selectedBranch.name === name;
    },
    getActiveBranchesText() {
      return this.reposStateData?.[this.repoId]?.provider === GitProviderName.BitbucketDc
        ? 'Recently updated branches...'
        : 'You recently worked on...';
    },
  },
});
</script>

<style scoped lang="postcss">
.branch-selector__header {
  align-items: center;
  cursor: default;
  border-bottom: 1px solid var(--color-border-default);
  justify-content: space-between;
  display: flex;
  padding: 0 var(--space-base) var(--space-xs);
}

.branch-selector__refresh {
  font-size: var(--fontsize-s);
  position: relative;
  right: 0;
  transition: ease;
  top: -1px;
  cursor: pointer;

  &:hover {
    color: var(--color-brand-hover);
  }
}

.branch-selector__new-branch {
  padding: var(--space-xs);
  width: 100%;
  color: var(--text-color-secondary);
  display: flex;
  box-sizing: border-box;

  &:hover {
    color: var(--color-brand-hover);
  }
}

.branch-selector__new-branch-icon {
  position: relative;
  top: -1px;
}

.branch-selector__title {
  color: var(--text-color-secondary);
  padding: 0 var(--space-base);
}

.selected-branch-name,
.branch-selector :deep(.vs__selected) {
  width: 150px;
}

.branch {
  width: max-content;
  border-radius: 4px;

  &.disabled {
    cursor: not-allowed;
  }
}

.branch-selector {
  width: 210px;

  :deep(.vs__dropdown-menu) {
    width: 350px;
    border-top-style: solid;
    border-radius: var(--space-xs);
    max-height: 316px;
  }
}

.branch-selector :deep(.vs__selected) {
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.branch-selector :deep(.vs__actions) {
  cursor: pointer;
}

.selected-branch-select-closed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  width: 210px;
  border: 1px solid var(--color-border-default);
  border-radius: 4px;
  background: var(--color-bg);
  box-sizing: border-box;

  .selected-branch {
    display: flex;
    align-items: center;
  }

  &:hover {
    border: 1px solid var(--color-border-default-strong);
  }

  .disabled & {
    background-color: var(--color-disable);
    border: none;
    color: var(--text-color-primary);
  }
}

.branch-container-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.default-branch {
  padding-top: var(--space-xs);
  padding-right: 0;
  padding-bottom: 6px;
  padding-left: var(--space-sm);
  width: 100%;
  font-family: var(--fontfamily-main);
  font-weight: 600;
  color: var(--text-color-secondary);
  transition: ease;
  display: flex;
}

.default-branch:not(.disabled):hover {
  font-weight: 700;
  color: var(--text-color-primary);
}

.active-branches {
  padding-bottom: 6px;

  .title {
    cursor: default;
  }

  .branch-line {
    padding: 3px 0px 3px 20px;
    line-height: 1.25rem;
  }

  .branch-line:hover {
    background: var(--color-hover);
  }
}

.loading-branches {
  display: flex;
  justify-content: center;
  align-items: center;
}

.branch-loader {
  --loader-size: 24px !important;
}

.branch-scroll-loader {
  --loader-size: 24px !important;
}

.divider {
  padding-bottom: 8px;
}

.disabled {
  cursor: default;
}

.popover {
  padding: 8px;
}

.more {
  display: flex;
  justify-content: center;
  width: 100%;
}

.error {
  color: var(--text-color-error-strong);
}
</style>
