<script setup lang="ts">
import TextFieldMultiline from '@/common/components/atoms/TextFieldMultiline.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { updateSalesforceWorkspace } from '@/common/utils/salesforce/salesforce';
import SettingsModalSection from '@/modules/core/components/settings-modal/SettingsModalSection.vue';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import DeleteWorkspaceSection from '@/modules/workspace/modals/settings/general/DeleteWorkspaceSection.vue';
import LogoSection from '@/modules/workspace/modals/settings/general/LogoSection.vue';
import { useNotificationsStore } from '@swimm/editor';
import { eventLogger, getLoggerNew } from '@swimm/shared';
import { Action, BaseLabel, Divider, TextField } from '@swimm/ui';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const logger = getLoggerNew(__modulename);

const store = useStore();
const route = useRoute();
const { addNotification } = useNotificationsStore();
const analytics = useAnalytics();
const { logEvent } = useSwimmEventLogs();

const { user } = storeToRefs(useAuthStore());
const isWorkspaceAdmin = computed(() =>
  store.getters['database/db_isWorkspaceAdmin'](route.params.workspaceId, user.value.uid)
);

const savingWorkspace = ref(false);

const originalWorkspaceData = computed(() => store.getters['database/db_getWorkspace'](route.params.workspaceId) || {});
const workspaceLogo = ref(originalWorkspaceData.value.logo);
const workspaceName = ref(originalWorkspaceData.value.name);
const shownWorkspaceName = ref(workspaceName.value);
const workspaceDescription = ref(originalWorkspaceData.value.description);

const detailsChanged = computed(
  () =>
    workspaceName.value !== originalWorkspaceData.value.name ||
    workspaceDescription.value !== originalWorkspaceData.value.description ||
    workspaceLogo.value !== originalWorkspaceData.value.logo
);
const workspaceNameValid = computed(() => !!workspaceName.value);
const disableSaveAction = computed(() => !workspaceNameValid.value || !detailsChanged.value);

function onLogoUpload(newLogoUrl) {
  workspaceLogo.value = newLogoUrl;
}

async function save() {
  savingWorkspace.value = true;
  logger.info(`Saving workspace ${route.params.workspaceId}`);

  const resource = {
    id: route.params.workspaceId,
    name: workspaceName.value,
    description: workspaceDescription.value,
    logo: workspaceLogo.value,
  };

  try {
    const workspaceId = await store.dispatch('database/saveWorkspace', { resource, user: user.value });
    updateSalesforceWorkspace({ name: resource.name, workspaceId });
    logAndReportWorkspaceSave(workspaceId);
  } catch (err) {
    addNotification('Failed to update workspace', { icon: 'warning' });
    logger.error({ err }, `could not save workspace ${route.params.workspaceId}: ${err}`);
  }
  savingWorkspace.value = false;
}

function logAndReportWorkspaceSave(workspaceId) {
  if (originalWorkspaceData.value.name !== workspaceName.value) {
    analytics.workspaceRenamed({
      workspaceId,
      previousWorkspaceName: originalWorkspaceData.value.name,
      workspaceName: workspaceName.value,
    });
  }

  logEvent(eventLogger.SWIMM_EVENTS.WORKSPACE_UPDATED, {
    srcId: workspaceId,
    srcName: workspaceName.value,
    workspaceId,
    workspaceName: workspaceName.value,
  });
}
</script>

<template>
  <SettingsModalSection title="General">
    <LogoSection :workspace-name="shownWorkspaceName" :logo="workspaceLogo" @upload-logo="onLogoUpload" />
    <TextField
      class="field"
      required
      v-model.trim="workspaceName"
      label="Workspace name"
      focus-first
      :maxlength="100"
      :error="!workspaceNameValid ? 'Workspace name is required' : ''"
      data-testid="workspace-name"
      @blur="shownWorkspaceName = workspaceName"
    />
    <BaseLabel>Workspace description</BaseLabel>
    <TextFieldMultiline v-model="workspaceDescription" class="field" />
    <Action :disabled="disableSaveAction" :loading="savingWorkspace" @click="save">Save</Action>
  </SettingsModalSection>
  <template v-if="isWorkspaceAdmin">
    <Divider class="divider" />
    <DeleteWorkspaceSection :workspace-name="originalWorkspaceData.name" />
  </template>
</template>

<style scoped lang="postcss">
.field {
  margin-bottom: var(--space-sm);
}

.divider {
  margin: var(--space-lg) 0;
}
</style>
