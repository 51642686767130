import { Extension } from '@tiptap/core';
import { getSwimmEditorServices } from './Swimm';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { isGrandParentOfType, isParentOfType } from '../utils';

interface SpecialPastePluginState {
  nextPasteIsPlain: boolean;
}

const SPECIAL_PASTE_PLUGIN_KEY = new PluginKey('specialPaste');

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    specialPaste: {
      parseAndInsertMarkdown: (text: string) => ReturnType;
      pasteAsPlainText: () => ReturnType;
      pasteAsMarkdown: () => ReturnType;
    };
  }
}

export default Extension.create({
  name: 'specialPaste',

  addCommands() {
    return {
      parseAndInsertMarkdown:
        (text) =>
        ({ dispatch, editor }) => {
          if (!dispatch) {
            return false;
          }
          const swimmEditorServices = getSwimmEditorServices(this.editor);
          (async () => {
            try {
              // Dynamic import to avoid an import cycle between the creation of the schema in extensions.ts and importing this module from it
              const { parseSwmMeta } = await import('../../swmd/swm_meta');
              const { parseSwmdContent } = await import('../../swmd/parser');
              const meta = parseSwmMeta(text);

              const content = parseSwmdContent(text, {
                repoId: meta?.meta['repo-id'] ?? swimmEditorServices.repoId.value,
                repoName: meta?.meta['repo-name'] ?? swimmEditorServices.getRepoName(swimmEditorServices.repoId.value),
              });
              editor.commands.insertContent(content);
            } catch (err) {
              swimmEditorServices.external.showNotification('Error. Please try again.', {
                icon: 'warning',
                autoClose: false,
                closeButtonText: 'Dismiss',
              });
            }
          })();
          return true;
        },
      pasteAsPlainText:
        () =>
        ({ view, dispatch }) => {
          const swimmEditorServices = getSwimmEditorServices(this.editor);
          if (!swimmEditorServices.isClipboardAccessAvailable) {
            return false;
          }
          if (dispatch) {
            (async () => {
              if (typeof navigator.clipboard.readText !== 'function') {
                swimmEditorServices.external.showNotification('Your browser does not support this capability.', {
                  icon: 'warning',
                  autoClose: false,
                  closeButtonText: 'Dismiss',
                });
              }

              try {
                const text = await navigator.clipboard.readText();
                if (text) {
                  this.editor.commands.command(({ tr }) => {
                    tr.setMeta(SPECIAL_PASTE_PLUGIN_KEY, true);
                    return true;
                  });
                  view.pasteText(text);
                }
              } catch (e) {
                // The user can deny permission
                if (e instanceof DOMException && e.name === 'NotAllowedError') {
                  swimmEditorServices.external.showNotification(
                    `Unable to paste plain text due to browser settings. Please enable this feature.`,
                    {
                      icon: 'browser-settings',
                      autoClose: false,
                      closeButtonText: 'Dismiss',
                      link: {
                        url: 'https://docs.swimm.io/support',
                        text: 'Learn more',
                      },
                    }
                  );
                } else {
                  swimmEditorServices.external.showNotification('Error. Please try again.', {
                    icon: 'warning',
                    autoClose: false,
                    closeButtonText: 'Dismiss',
                  });
                }
              }
            })();
          }

          return true;
        },

      pasteAsMarkdown:
        () =>
        ({ editor, dispatch }) => {
          const swimmEditorServices = getSwimmEditorServices(this.editor);
          if (!swimmEditorServices.isClipboardAccessAvailable) {
            return false;
          }
          if (dispatch) {
            (async () => {
              if (typeof navigator.clipboard.readText !== 'function') {
                swimmEditorServices.external.showNotification('Your browser does not support this capability.', {
                  icon: 'warning',
                  autoClose: false,
                  closeButtonText: 'Dismiss',
                });
              }

              try {
                const text = await navigator.clipboard.readText();
                if (text) {
                  editor.commands.parseAndInsertMarkdown(text);
                }
              } catch (e) {
                // The user can deny permission
                if (e instanceof DOMException && e.name === 'NotAllowedError') {
                  swimmEditorServices.external.showNotification(
                    `Unable to paste Markdown due to browser settings. Please enable this feature.`,
                    {
                      icon: 'browser-settings',
                      autoClose: false,
                      closeButtonText: 'Dismiss',
                      link: {
                        url: 'https://docs.swimm.io/support',
                        text: 'Learn more',
                      },
                    }
                  );
                } else {
                  swimmEditorServices.external.showNotification('Error. Please try again.', {
                    icon: 'warning',
                    autoClose: false,
                    closeButtonText: 'Dismiss',
                  });
                }
              }
            })();
          }

          return true;
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Alt-v': () => this.editor.commands.pasteAsMarkdown(),
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin<SpecialPastePluginState>({
        key: SPECIAL_PASTE_PLUGIN_KEY,
        props: {
          handlePaste: (view, event, slice) => {
            if (!event.clipboardData) {
              return false;
            }

            const text = event.clipboardData.getData('text/plain');
            if (!text) {
              return false;
            }
            const vscode = event.clipboardData.getData('vscode-editor-data');
            const vscodeData = vscode ? JSON.parse(vscode) : undefined;
            const language = vscodeData?.mode;

            // give warning on first time possible code block is pasted
            // ignore vscode mermaid and markdown since we handle them specially
            const isCodeBlock =
              (!!language && !['markdown', 'mermaid'].includes(language)) ||
              (slice.content.childCount === 1 && slice.content.firstChild?.type.name === 'codeBlock');
            if (isCodeBlock) {
              const swimmEditorServices = getSwimmEditorServices(this.editor);
              if (swimmEditorServices.external.isPasteCodeWarningApplicable()) {
                swimmEditorServices.showPasteCodeWarningModal.value = true;
              }
            }
            // handle paste of markdown code
            if (!language) {
              return false;
            }
            const state = view.state;
            if (language === 'markdown') {
              // we support this only at top level
              if (
                (isParentOfType(state.doc, state.selection.head, ['paragraph']) &&
                  isGrandParentOfType(state.doc, state.selection.head, ['doc'])) ||
                isParentOfType(state.doc, state.selection.head, ['doc'])
              ) {
                return this.editor.commands.parseAndInsertMarkdown(text);
              }
            }
            return false;
          },
        },
        state: {
          init() {
            return {
              nextPasteIsPlain: false,
            };
          },

          apply(tr, value) {
            if (tr.getMeta(SPECIAL_PASTE_PLUGIN_KEY)) {
              return { nextPasteIsPlain: true };
            }

            if (value.nextPasteIsPlain) {
              tr.setMeta('uiEvent', 'pastePlain');
              return { nextPasteIsPlain: false };
            }

            return value;
          },
        },
      }),
    ];
  },
});
