<template>
  <NodeViewWrapper
    class="draggable-item editor-row"
    :class="{ 'user-selection-off': userSelectionOff }"
    @mouseover="onMouseover"
    :data-selected="isSelected"
    @mouseleave="onMouseleave"
    @dragstart="shouldAllowDragPropagation"
  >
    <div v-if="showDropDown" class="cell">
      <DraggableDropdownMenu
        ref="dropdownMenu"
        :isEditable="editor.options.editable"
        :should-allow-section-id-copying="false"
        :options-from-parent="getCommands"
        :handle-drag="false"
        :data-test-id="`drag-icon-${extension.options.contentIndex}`"
        :cell-index="extension.options.contentIndex"
        :is-heading="isHeading"
        contenteditable="false"
        draggable="true"
        data-drag-handle
      />
    </div>
    <NodeViewContent :id="cellId" :as="getHtmlAS" class="content" :class="classes" />
  </NodeViewWrapper>
</template>

<script lang="ts">
import * as _ from '@tiptap/pm/model';
import { NodeViewContent, NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { type Ref, computed, onMounted, provide, ref, toRefs } from 'vue';
import DraggableDropdownMenu from '../DraggableDropdownMenu.vue';
import { useSelectionHighlighting } from '@/components/EditorComponents/composable/selectionHighlighting';
import { shortHash } from '@swimm/shared';

export default {
  name: 'EditorDraggableItem',
  components: {
    NodeViewWrapper,
    NodeViewContent,
    DraggableDropdownMenu,
  },
  props: nodeViewProps,
  setup(props) {
    const { editor, extension, node, getPos, deleteNode } = toRefs(props);
    const dropdownMenu: Ref<typeof DraggableDropdownMenu | null> = ref(null);
    const { isSelected } = useSelectionHighlighting(props);

    const isHeading = computed(() => {
      return extension.value.name === 'heading';
    });

    const cellId = computed(() => {
      if (isHeading.value) {
        const stringToHash = `h${node.value.attrs.level}${node.value.textContent}`;
        const hashedValue = `${shortHash(stringToHash)}`;
        return `heading-${hashedValue}`;
      } else {
        return '';
      }
    });
    const getHtmlAS = computed(() => {
      const extensionName = extension.value.name;
      if (extensionName === 'orderedList') {
        return 'ol';
      } else if (extensionName === 'bulletList') {
        return 'ul';
      } else if (extensionName === 'paragraph') {
        return 'p';
      } else if (extensionName === 'listItem') {
        return 'li';
      } else if (extensionName === 'codeBlock') {
        return 'code';
      } else if (extensionName === 'blockquote') {
        return 'blockquote';
      }
      return 'div';
    });
    const classes = computed(function () {
      const extensionName = extension.value.name;
      const classes = [extensionName];

      if (extensionName === 'heading') {
        classes.push('heading-' + node.value.attrs.level);
      }

      return classes;
    });

    provide('decorationClasses', () => classes.value);
    const showDropDown = ref(false);
    onMounted(() => {
      shouldShowDropdownMenu();
    });

    function shouldShowDropdownMenu() {
      if (getPos.value() == null || editor.value.state.doc.nodeSize - 1 <= getPos.value()) {
        showDropDown.value = false;
        return;
      }
      showDropDown.value = editor.value.state.doc.resolve(getPos.value()).depth === 0;
    }
    const userSelectionOff = ref(false);

    function onMouseover() {
      if (dropdownMenu.value) {
        dropdownMenu.value.handleVisible = true;
      }
    }

    function onMouseleave() {
      if (dropdownMenu.value) {
        dropdownMenu.value.handleVisible = false;
      }
    }

    const baseCommandsList = [
      {
        name: 'Delete',
        callback: deleteNode.value,
      },
    ];

    function getCommands() {
      const commandsList = [];
      commandsList.push(...baseCommandsList);

      if (editor.value.state.doc.firstChild !== editor.value.state.doc.nodeAt(getPos.value())) {
        commandsList.push({
          name: 'Move up',
          callback: () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (editor.value.options as any).moveNodeUp(getPos.value(), node.value);
          },
        });
      }
      if (editor.value.state.doc.lastChild !== editor.value.state.doc.nodeAt(getPos.value())) {
        commandsList.push({
          name: 'Move down',
          callback: () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (editor.value.options as any).moveNodeDown(getPos.value(), node.value);
          },
        });
      }
      return commandsList;
    }

    return {
      classes,
      getCommands,
      onMouseover,
      getHtmlAS,
      showDropDown,
      userSelectionOff,
      cellId,
      onMouseleave,
      isHeading,
      isSelected,
      dropdownMenu,
      shouldAllowDragPropagation(event: DragEvent) {
        if ((event.target as Element)?.matches && (event.target as Element)?.matches('[data-drag-handle]')) {
          return;
        }
        event.stopImmediatePropagation();
        event.preventDefault();
      },
    };
  },
};
</script>

<style scoped lang="postcss">
.draggable-item {
  position: relative;
  display: flex;
  user-select: text;
}

.draggable-item:before {
  position: absolute;
  top: 0;
  right: 100%;
  content: ' ';
  display: block;
  width: 30px;
  height: 100%;
}

.cell {
  position: absolute;
  right: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-selection-off {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.empty-placeholder {
  position: absolute;
  left: 2px;
  z-index: 0;
  display: none;
  user-select: none;
  color: var(--text-color-disable);

  &.same-repo-hunk-placeholder {
    /* The placeholder is otherwise rendered below the paragraph and is not clickable */
    z-index: 2;
  }
}

.paragraph::after {
  content: attr(data-completion);
  display: contents;
  position: absolute;
  left: 2px;
  z-index: 0;
  user-select: none;
  color: var(--text-color-disable);
  overflow: hidden;
  border-right: 0.04em solid gray;
  white-space: normal; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
}

.content {
  z-index: 1;
  margin-top: 0px;
  flex: 1 1 auto;
}

.line-empty .empty-placeholder {
  display: inline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: -webkit-fill-available;
}

.in-table {
  font-size: var(--fontsize-xs);
}

/* stylelint-disable */
.ProseMirror .orderedList {
  list-style: decimal;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6,
.ProseMirror .heading {
  margin: revert;
  margin-bottom: 16px;
}

@keyframes focusCell {
  50% {
    border-radius: 4px;
    background-color: var(--color-hover);
  }
  100% {
    border-radius: unset;
    background-color: unset;
  }
}
.focused-cell {
  animation-name: focusCell;
  animation-duration: 1.5s;
}

.ProseMirror h1,
.ProseMirror .heading-1 {
  padding: unset;
  font-size: var(--headline1);
  line-height: 40px;
  font-weight: 700;
}

.ProseMirror h2,
.ProseMirror .heading-2 {
  font-size: var(--headline2);
  font-weight: 700;
  line-height: 32px;
}

.ProseMirror h3,
.ProseMirror .heading-3 {
  font-size: var(--headline3);
  font-weight: 700;
  line-height: 32px;
}

.ProseMirror p {
  font-size: var(--body-L);
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 16px;
}

.ProseMirror blockquote {
  padding-left: 1.5rem;
  border-left: 4px solid var(--color-border-default-strong);
}

:deep(strong) {
  font-weight: 800;
}

:deep(a.tiptap-link) {
  color: var(--text-color-link);

  &:hover {
    text-decoration: underline;
  }
}

:deep(code) {
  margin: 0;
  line-height: initial;
  font-size: 0.9em;
  padding: 0px 3px;
  display: inline-block;
  font-family: var(--fontfamily-secondary);
}

:deep(code:not(.codeBlock)) {
  margin: 0;
  line-height: initial;
  font-size: 0.9em;
  padding: 0px 3px;
  display: inline-block;
  font-family: var(--fontfamily-secondary);
}
/* stylelint-enable */
</style>
