<script setup lang="ts">
import { type PropType, computed, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { DocumentationTypes, PageRoutesNames } from '@/common/consts';
import SidebarRepoLineFolderContent from './SidebarRepoLineFolderContent.vue';
import type { Folder } from '@/modules/folders/types';
import RepoOrFolderCreateOptions from './RepoOrFolderCreateOptions.vue';
import { HighlightedText, SwText } from '@swimm/ui';
import SidebarRepoLineFolderTreeItemActions from './SidebarRepoLineFolderTreeItemActions.vue';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useResourceActions } from '@/modules/resources/composables/resource-actions';
import { FolderFilterResult, useFoldersStore } from '@/modules/folders/store/folders';
import FolderNameEdit from '@/modules/folders/components/FolderNameEdit.vue';
import { decodeString, productEvents } from '@swimm/shared';
import { useRouting } from '@/common/composables/routing';
import { useNavigate } from '@/common/composables/navigate';
import ChangedDocumentationIcon from '@/modules/core/components/ChangedDocumentationIcon.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { computeDraftDisplayName } from '@/common/utils/draft-utils';
import { useNotificationsStore, useScroll } from '@swimm/editor';

export interface SidebarFolderTreeItem {
  id: string;
  draftId?: string;
  isNew?: boolean;
  name: string;
  documentationType: (typeof DocumentationTypes)[keyof typeof DocumentationTypes];
  diffState?: 'added' | 'modified';
}

const props = defineProps({
  treeItem: { type: Object as PropType<SidebarFolderTreeItem>, required: true },
  repoId: { type: String, required: true },
  path: { type: String, required: true },
  depth: { type: Number, required: true },
  filter: { type: String, default: '' },
  filteredFolders: { type: Array<FolderFilterResult>, default: null },
});

const route = useRoute();
const router = useRouter();
const analytics = useAnalytics();
const { getRepoPath } = useNavigate();
const { assertOnRepoPageRoute } = useRouting();
const { reposStateData } = storeToRefs(useReposStore());
const { getResourceLink } = useResourceActions();
const foldersStore = useFoldersStore();
const { renameFolderId, foldersByRepo, currentFolderIdByRepo } = storeToRefs(foldersStore);
const { selectFolder } = foldersStore;
const { isScrolledIntoView } = useScroll();
const expanded = ref(false);
const expandedInFilter = computed(() => {
  if (!props.filteredFolders || !props.filteredFolders.length) {
    return false;
  }
  const filteredFolder = props.filteredFolders.find((folder) => props.treeItem.id === folder.folderId);
  return filteredFolder.expanded;
});
const mountActions = ref(false);
const elementRef = ref<HTMLDivElement>(null);
const currentFolder = computed<Folder>(
  () => foldersByRepo.value[props.repoId]?.[currentFolderIdByRepo.value[props.repoId]]
);
const isFolder = computed(() => props.treeItem.documentationType === DocumentationTypes.FOLDER);
const isDoc = computed(() => props.treeItem.documentationType === DocumentationTypes.DOC);
const isPlaylist = computed(() => props.treeItem.documentationType === DocumentationTypes.PLAYLIST);
const treeItemName = computed(() => computeDraftDisplayName(props.treeItem.name));
const folderIcon = computed(() => {
  if (isFolder.value) {
    return expanded.value ? 'folder-open' : 'folder';
  }

  return '';
});

const isSelected = computed(() => {
  if (route.params.repoId !== props.repoId) {
    return false;
  }
  if (isFolder.value) {
    return assertOnRepoPageRoute() && currentFolder.value?.id === props.treeItem.id;
  } else if (isDoc.value) {
    if (route.name === PageRoutesNames.DOC_NEW && route.query.draft) {
      const draftId = decodeString(route.query.draft as string);
      return draftId === props.treeItem.draftId;
    }
    return route.params.unitId === (props.treeItem.id || props.treeItem.draftId);
  } else if (isPlaylist.value) {
    if (route.name === PageRoutesNames.PLAYLIST_NEW && route.query.playlistDraft) {
      const draftId = decodeString(route.query.playlistDraft as string);
      return draftId === props.treeItem.draftId;
    }
    return route.params.playlistId === (props.treeItem.id || props.treeItem.draftId);
  }

  return false;
});

const shouldRenameFolder = computed(
  () => isFolder.value && renameFolderId.value !== null && renameFolderId.value === props.treeItem.id
);

const treeNodeStyle = computed(() => {
  const baseTreeStartPadding = 20;
  const nodePadding = 20 * props.depth;
  return `padding-left: ${baseTreeStartPadding + nodePadding}px`;
});

function getRepoDefaultBranchName(repoId): string {
  const repoFromState = reposStateData.value[repoId];
  return repoFromState?.defaultBranch || '';
}

function getBranch(repoId: string): string {
  return (route.params.branch as string) || getRepoDefaultBranchName(repoId);
}

function expandFolderIfNeeded() {
  if (!isFolder.value) {
    return;
  }
  if (props.path.includes(props.treeItem.name)) {
    expanded.value = true;
  }
}

onMounted(() => {
  expandFolderIfNeeded();
});

// This handles the following scenario:
// 1. User enters filter
// 2. Filter finds a doc that is inside a collapsed folder.
// 3. Users enters that doc
// 4. User closes Quick search
// Without this, the containing folder would remain collapsed and the user wouldn't know where they are.
watch(
  () => props.filter,
  (filter) => {
    if (!filter) {
      expandFolderIfNeeded();
    }
  }
);

async function handleFolderClick() {
  const isCurrentRepo = route.params.repoId === props.repoId;
  if (!(isCurrentRepo && assertOnRepoPageRoute())) {
    const repoRoute = getRepoPath(props.repoId, getBranch(props.repoId));
    await router.push(`${repoRoute}`);
  }
  selectFolder(props.repoId, props.treeItem.id);
  expanded.value = true;

  analytics.track(productEvents.CLICKED_ITEM_NAME, {
    'Is Current Repo': props.repoId === route.params.repoId,
    'Entity Type': 'Folder',
    'Entity ID': props.treeItem.id,
    'Entity Path': props.path,
  });
}

const itemLink = computed(() => {
  const branch = getBranch(props.repoId);
  return getResourceLink({
    resource: props.treeItem,
    repoId: props.repoId,
    branch,
    type: props.treeItem.documentationType,
    editMode: Boolean(props.treeItem.draftId),
  });
});

function onToggleExpand() {
  expanded.value = !expanded.value;
  analytics.track(productEvents.EXPAND_COLLAPSE_SIDEBAR_ITEM, {
    'Is Current Repo': props.repoId === route.params.repoId,
    'Entity Type': 'Folder',
    'Entity ID': props.treeItem.id,
    'Entity Path': props.path,
    Expanded: expanded.value,
    'Is Quick Search Active': !!props.filter,
  });
}

function onOpenOptions() {
  analytics.track(productEvents.OPENED_SIDEBAR_ELLIPSIS_MENU, {
    'Entity Type': 'Folder',
    'Entity ID': props.treeItem.id,
    'Entity Path': props.path,
  });
}

function getItemType() {
  if (isFolder.value) {
    return 'Folder';
  }
  return isPlaylist.value ? 'Playlist' : 'Doc';
}

const handleNameClick = function () {
  if (isFolder.value) {
    handleFolderClick();
  }
  analytics.track(productEvents.CLICKED_SIDEBAR_ITEM, {
    'Entity Type': getItemType(),
    'Entity ID': props.treeItem.id,
  });
};

const handleLineClick = function () {
  if (isFolder.value) {
    onToggleExpand();
  }
  analytics.track(productEvents.CLICKED_SIDEBAR_ITEM, {
    'Entity Type': getItemType(),
    'Entity ID': props.treeItem.id,
  });
};

watch(
  [isSelected, elementRef],
  ([isSelected, elementRef]) => {
    if (isSelected && elementRef && !isScrolledIntoView(elementRef)) {
      // We do this because there is a bug in browsers where if some event happens concurrently in the event loop with
      //  scrollIntoView, the scrollIntoView will not work.
      // We therefore defer the scrollIntoView to the next event loop. (:shrug:)
      setTimeout(() => elementRef.scrollIntoView({ behavior: 'smooth', block: 'center' }));
    }
  },
  { immediate: true }
);

const { addNotification } = useNotificationsStore();

const onDragStart = (event: DragEvent) => {
  analytics.track(productEvents.STARTED_DRAGGING_SIDEBAR_ITEM, {
    'Entity Type': getItemType(),
    'Entity ID': props.treeItem.id,
  });
  addNotification('Drag & drop is not yet supported for sidebar items - you can drag and drop on the repo page.', {
    icon: 'warning',
  });
  event.preventDefault();
};
</script>

<template>
  <div
    :class="['repo-tree-item', { selected: isSelected }]"
    ref="elementRef"
    draggable="true"
    @dragstart="onDragStart"
    @mouseover="mountActions = true"
    v-tooltip="{ content: treeItemName, placement: 'right' }"
  >
    <Icon
      v-if="isFolder"
      no-padding
      :style="treeNodeStyle"
      class="expand-toggle"
      :name="expanded ? 'arrow-down' : 'arrow-right'"
    />
    <component
      :is="isFolder ? 'div' : 'router-link'"
      :to="isFolder ? null : itemLink"
      :style="treeNodeStyle"
      @click="handleLineClick"
      class="tree-item"
      data-testid="tree-item"
    >
      <FolderNameEdit
        v-if="shouldRenameFolder"
        class="edit-folder-input"
        :repo-id="repoId"
        :folder-id="treeItem.id"
        :folder-name="treeItem.name"
        show-icon
        show-error-below
        font-class="body-S"
        @change="renameFolderId = null"
        @cancel="renameFolderId = null"
      />
      <div v-else class="tree-item-name-container">
        <Icon
          no-padding
          v-if="treeItem.documentationType === DocumentationTypes.FOLDER"
          :name="folderIcon"
          class="item-icon"
          :class="[{ draft: Boolean(treeItem.draftId) }]"
        />
        <ChangedDocumentationIcon
          v-else
          class="item-icon"
          size="18"
          :type="treeItem.documentationType"
          :state="treeItem?.diffState"
        />
        <component
          :is="isFolder ? 'span' : 'router-link'"
          :to="isFolder ? null : itemLink"
          @click.stop="handleNameClick"
          class="text-ellipsis"
        >
          <SwText variant="body-S" class="text-ellipsis tree-item-name">
            <HighlightedText :full-text="treeItemName" :highlighted-text="filter"
          /></SwText>
        </component>
      </div>
    </component>
    <div v-if="!shouldRenameFolder && mountActions" class="actions">
      <SidebarRepoLineFolderTreeItemActions
        :repo-id="repoId"
        :tree-item="treeItem"
        :type="treeItem.documentationType"
        @click="onOpenOptions"
      />
      <RepoOrFolderCreateOptions
        v-if="isFolder"
        class="creation-options"
        :folder-id="treeItem.id"
        :repo-id="repoId"
        entity="FOLDER"
        @expand-parent="() => (expanded = true)"
      />
    </div>
  </div>
  <template v-if="isFolder && (filter ? expandedInFilter : expanded)">
    <SidebarRepoLineFolderContent
      :repo-id="repoId"
      :folder="(treeItem as unknown as Folder)"
      :depth="depth + 1"
      :path="path"
      :filter="filter"
      :filtered-folders="filteredFolders"
    />
  </template>
</template>

<style scoped lang="postcss">
.repo-tree-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--sidebar-item-height);
  box-sizing: border-box;
  font-size: var(--body-S);
  padding-right: var(--space-base);
  overflow: hidden;

  .expand-toggle {
    position: absolute;
    left: calc(-1 * var(--space-sm));
    visibility: hidden;
    color: var(--text-color-disable);
    pointer-events: none; /* Clicks go through to the item itself which toggle expansion. */
  }

  .tree-item {
    flex: 1;
    max-width: 90%;
    align-self: stretch;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-right: var(--space-base);

    .tree-item-name-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
      position: relative;
      overflow: hidden;

      .tree-item-name {
        &:hover {
          color: var(--text-color-link);
        }
      }

      .item-icon {
        font-size: var(--body-L);
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: var(--space-xs);

    :deep(.creation-options),
    :deep(.sidebar-item-actions) {
      display: flex;

      > .icon {
        transition: opacity 0.3s;
        opacity: 0;
      }
    }
  }

  &:hover {
    background: var(--color-hover);

    .actions {
      :deep(.sidebar-item-actions),
      :deep(.creation-options) {
        > .icon {
          opacity: 1;
        }
      }
    }

    .expand-toggle {
      visibility: visible;
    }
  }

  &.selected,
  &.selected :deep(a) {
    background: var(--color-selected);
    color: var(--text-color-link);
  }

  :deep(.documentation-icon) {
    margin-right: 0;
  }
}
</style>
