import { getDraftsData } from '@/adapters-common/load_common';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { setUserFieldInDB } from '@/common/utils/user-utils';
import {
  CREATE_A_DOC_STEPS,
  CREATE_A_DOC_SUB_STEPS,
  GetStartedStepIds,
  GetStartedSteps,
  WORKSPACE_INDEPENDENT_STEPS,
} from '@/modules/get-started/consts';
import type { GetStartedMenuItem, GetStartedStepId } from '@/modules/get-started/types';
import { UserOnboardingFields, config, encodeString } from '@swimm/shared';
import assertNever from 'assert-never';
import { defineStore, storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useNavigate } from '@/common/composables/navigate';
import { useStore } from 'vuex';
import { pageEvents, productEvents } from '@swimm/shared';
import { STORES } from '@/modules/core/stores/store-constants';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';

const { connectARepo, authorizeGitHub, createADoc, addACodeSnippet, commitADoc, installIdePlugin, installGitHubApp } =
  GetStartedSteps;

export const useGetStartedMenu = defineStore(STORES.GET_STARTED, () => {
  const route = useRoute();
  const analytics = useAnalytics();
  const router = useRouter();
  const store = useStore();
  const { user } = storeToRefs(useAuthStore());
  const userConfigStore = useUserConfigStore();
  const { firstWorkspace } = storeToRefs(userConfigStore);
  const { setIsFinishedGetStarted, setShowAnimationforStep } = userConfigStore;
  const userUid = computed(() => user.value.uid);
  const workspaceRepos = computed(() => store.getters['database/db_getWorkspaceRepos'](route.params.workspaceId));
  const shouldOpenSnippetStudio = ref(false);
  const shouldOpenCommitModal = ref(false);
  const isAdmin = computed(() => {
    return store.getters['database/db_isWorkspaceAdmin'](route.params.workspaceId, userUid.value);
  });
  const { authorizeProviderWithGit } = useGitAuthorizationStore();
  const { provider, enterpriseGitUrl } = storeToRefs(useWorkspaceStore());

  const { getRepoPath } = useNavigate();

  const finishedOnboarding = ref(false);
  const showMenu = ref(false);
  const dismissedGetStarted = ref(false);
  const showAddRepoModal = ref(false);
  const showRepoSettingsModal = ref(false);
  const showUserSettingsModal = ref(false);

  const reactiveMenuItems: Ref<GetStartedMenuItem[]> = ref([
    // Destructing the menu items so isDone and other props won't be part of the constants
    { ...connectARepo },
    { ...authorizeGitHub },
    { ...createADoc },
    { ...addACodeSnippet },
    { ...commitADoc },
    { ...installIdePlugin },
    { ...installGitHubApp },
  ]);

  const getMenuStepById = (stepId: GetStartedStepId) => {
    return reactiveMenuItems.value.find(({ id }) => id === stepId);
  };

  const updateCreateADocStep = async () => {
    const createADocStep = getMenuStepById(GetStartedStepIds.CREATE_A_DOC);

    const createADocSubStep1 = getMenuStepById(GetStartedStepIds.ADD_A_CODE_SNIPPET);
    const createADocSubStep2 = getMenuStepById(GetStartedStepIds.COMMIT_A_DOC);

    if (createADocSubStep1.isDone && createADocSubStep2.isDone) {
      createADocStep.isDone = true;
      await setUserFieldInDB(userUid.value, UserOnboardingFields.GET_STARTED_STEPS, {
        [GetStartedStepIds.CREATE_A_DOC]: true,
      });
    }
  };

  const isUserDoneAllSteps = () =>
    reactiveMenuItems.value.every(
      ({ isDone, isAdminsOnlyStep, isMembersOnlyStep }) =>
        isDone || (isAdminsOnlyStep && !isAdmin.value) || (isMembersOnlyStep && isAdmin.value)
    );

  const isWorkspaceIndependentStep = (stepId) => WORKSPACE_INDEPENDENT_STEPS.includes(stepId);

  const isMarkingStepAsDoneNeeded = (stepId) => {
    if (isWorkspaceIndependentStep(stepId)) {
      return !getMenuStepById(stepId).isDone;
    }
    // If the user is not in his 1st workspace or if the step is already done - no need to mark the step as done
    if (route.params.workspaceId !== firstWorkspace.value) {
      return false;
    }
    return !getMenuStepById(stepId).isDone;
  };

  const markGetStartedAsDone = async ({ isDoneByDismiss } = { isDoneByDismiss: false }) => {
    finishedOnboarding.value = true;

    if (isDoneByDismiss) {
      dismissedGetStarted.value = true;
      analytics.track(productEvents.DISMISSED_ONBOARDING_CHECKLIST);
    } else {
      showMenu.value = true;
      analytics.pageVisit(pageEvents.VIEW_GET_STARTED_CONGRATS_STEP);
    }

    await setUserFieldInDB(userUid.value, UserOnboardingFields.IS_FINISHED_GET_STARTED, true);
    setIsFinishedGetStarted();
  };

  const markStepAsDone = async (stepId: GetStartedStepId, { isNaturalComplete } = { isNaturalComplete: true }) => {
    if (!isMarkingStepAsDoneNeeded(stepId)) {
      return;
    }
    await setUserFieldInDB(userUid.value, UserOnboardingFields.GET_STARTED_STEPS, { [stepId]: true });
    getMenuStepById(stepId).isDone = true;

    if (isNaturalComplete) {
      showMenu.value = true;
      analytics.track(productEvents.GET_STARTED_STEP_COMPLETED, { Step: stepId });
      setShowAnimationforStep({ showAnimationforStep: stepId });
    }

    if (CREATE_A_DOC_SUB_STEPS.includes(stepId)) {
      await updateCreateADocStep();
    }

    if (isUserDoneAllSteps()) {
      markGetStartedAsDone();
    }
  };

  const isInEditDocRoute = () => {
    return route.fullPath.includes(`/docs/new`) || (route.params.unitId && route.fullPath.includes(`/edit`));
  };

  const openEditDraftOrNewDoc = async () => {
    if (!route.params.repoId) {
      return;
    }
    const repoPath = getRepoPath(<string>route.params.repoId, <string>route.params.branch);
    let drafts = await getDraftsData({ repoId: route.params.repoId });
    drafts = drafts
      .filter((draft) => draft.draftBranch === route.params.branch)
      .filter((draft) => !draft.type || draft.type !== config.SWIMM_FILE_TYPES.PLAYLIST);
    if (drafts.length > 0) {
      const draftToOpen = drafts[0];
      if (draftToOpen.id) {
        // Edit of a doc draft
        await router.push(`${repoPath}/docs/${draftToOpen.id}/edit?draft=${encodeString(draftToOpen.draftId)}`);
      } else {
        // Draft of a new doc
        await router.push(`${repoPath}/docs/new?draft=${encodeString(draftToOpen.draftId)}`);
      }
    } else {
      // If no docs drafts match to current repo branch - open a blank doc
      await router.push(`${repoPath}/docs/new`);
    }
  };

  const handleStepClicked = async (stepId: GetStartedStepId) => {
    showMenu.value = false;

    analytics.track(productEvents.GET_STARTED_STEP_CLICKED, { Step: stepId });
    switch (stepId) {
      case GetStartedStepIds.CONNECT_A_REPO:
        showAddRepoModal.value = true;
        break;
      case GetStartedStepIds.AUTHORIZE_GITHUB:
        await authorizeProviderWithGit({
          provider: provider.value,
          gitUrl: enterpriseGitUrl.value,
          origin: 'Get started',
        });
        break;
      case GetStartedStepIds.CREATE_A_DOC:
        if (!isInEditDocRoute()) {
          openEditDraftOrNewDoc();
        }
        break;
      case GetStartedStepIds.ADD_A_CODE_SNIPPET:
        shouldOpenSnippetStudio.value = true;
        if (!isInEditDocRoute()) {
          openEditDraftOrNewDoc();
        }
        break;
      case GetStartedStepIds.COMMIT_A_DOC:
        if (!isInEditDocRoute()) {
          await openEditDraftOrNewDoc();
        }
        shouldOpenCommitModal.value = true;
        break;
      case GetStartedStepIds.INSTALL_IDE_PLUGIN:
        showUserSettingsModal.value = true;
        break;
      case GetStartedStepIds.INSTALL_GIT_HUB_APP:
        showRepoSettingsModal.value = true;
        break;

      default:
        assertNever(stepId);
    }
  };

  const clickedDismiss = () => {
    markGetStartedAsDone({ isDoneByDismiss: true });
  };

  const isInRepoRelatedPage = () => route.params.repoId;

  const isStepAvailable = (stepId: GetStartedStepId) => {
    if (stepId === GetStartedStepIds.CONNECT_A_REPO) {
      return true;
    }
    if (!getMenuStepById(GetStartedStepIds.CONNECT_A_REPO).isDone) {
      return false;
    }
    if (!CREATE_A_DOC_STEPS.includes(stepId)) {
      return true;
    }
    return isInRepoRelatedPage();
  };

  return {
    reactiveMenuItems,
    isStepAvailable,
    getMenuStepById,
    handleStepClicked,
    isUserDoneAllSteps,
    markStepAsDone,
    markGetStartedAsDone,
    showMenu,
    dismissedGetStarted,
    showAddRepoModal,
    showRepoSettingsModal,
    showUserSettingsModal,
    finishedOnboarding,
    isAdmin,
    workspaceRepos,
    shouldOpenCommitModal,
    shouldOpenSnippetStudio,
    clickedDismiss,
    isInRepoRelatedPage,
  };
});
