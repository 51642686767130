import { useStore } from 'vuex';
import { useAuthStore } from './auth-store';
import { useGlobalStore } from './global-store';
import { useTemplatesStore } from './templates-store';
import { useUserConfigStore } from './user-config-store';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useFoldersStore } from '@/modules/folders/store/folders';

export function useSwimmStores() {
  const store = useStore();
  const authStore = useAuthStore();
  const globalStore = useGlobalStore();
  const templatesStore = useTemplatesStore();
  const userConfigStore = useUserConfigStore();
  const reposStore = useReposStore();
  const foldersStore = useFoldersStore();
  const resetAllStores = () => store.dispatch('resetAllStores');

  function resetAllStoresState() {
    authStore.resetState();
    globalStore.resetState();
    templatesStore.resetState();
    userConfigStore.resetState();
    reposStore.resetState();
    foldersStore.resetState();
    resetAllStores();
  }
  return {
    resetAllStoresState,
  };
}
