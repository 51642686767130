import semver from 'semver';

// Makes sure the file version is higher/lower than an input version.
// No file version as input is considered as a swm file that was created before file_version introduced to the swm file, and therefore has no version at all (lower than even 1.0.0).
export function assertFileVersion({ fileVersion = '0.0.9', operator, versionToCompare }) {
  return compareSemanticVersion({
    currentVersion: fileVersion,
    operator: operator,
    versionToCompare: versionToCompare,
  });
}

function compareSemanticVersion({ currentVersion, operator, versionToCompare }) {
  const compareOperators = {
    '>=': (currentVersion, versionToCompare) => semver.gte(currentVersion, versionToCompare),
    '<': (currentVersion, versionToCompare) => semver.lt(currentVersion, versionToCompare),
    '<=': (currentVersion, versionToCompare) => semver.lte(currentVersion, versionToCompare),
    '=': (currentVersion, versionToCompare) => semver.eq(currentVersion, versionToCompare),
    compare: (currentVersion, versionToCompare) => semver.compare(currentVersion, versionToCompare),
  };
  return compareOperators[operator](currentVersion, versionToCompare);
}
