<script setup lang="ts">
import findLastIndex from 'lodash-es/findLastIndex.js';
import type { DynamicChangeLine, DynamicChangeLineFile, TokenSuggestion } from '@swimm/shared';
import { ApplicabilityStatus, HunkChangeLineType } from '@swimm/shared';
import { computed, ref, watch } from 'vue';
import CodeSnippetContentLine from '@/components/EditorComponents/Hunk/CodeSnippetContentLine.vue';
import { calculateLineWithLeastPrefixSpaces } from '@/components/EditorComponents/code-snippet-utils';
import type { OverlayToken } from '../GenericText/tokenizer';
import { useScroll } from '@/composables/scroll';

const props = defineProps<{
  linesContent: Array<DynamicChangeLine>;
  editMode: boolean;
  applicability: ApplicabilityStatus;
  origin: 'fileA' | 'fileB';
  language: string;
  forceDisconnected: boolean;
  trimPrefixSpaces: boolean;
  diffMode: boolean;
  splitSnippetMode: boolean;
  queryDefinitions?: ((token: string) => Promise<TokenSuggestion[]>) | null;
  allowDragDropEditing?: boolean;
}>();

const emit = defineEmits<{
  (event: 'line-range-change', newRange: { firstLineNumber: number; lastLineNumber: number }): void;
  (event: 'split-snippet', splitAfterLineNumber: number): void;
  (event: 'add-def-snippet', suggestion: TokenSuggestion): void;
  (event: 'token-interacted', op: 'hover' | 'click', tok: OverlayToken): void;
}>();

const minPrefixSpaces = computed<number>(() =>
  calculateLineWithLeastPrefixSpaces(
    props.linesContent.map((codeLine) => (codeLine[props.origin] as DynamicChangeLineFile).data)
  )
);

const lastLineNumberChatAmount = computed<number>(() => {
  const lastLineChange: DynamicChangeLine = props.linesContent[props.linesContent.length - 1];
  return getLineNumberDisplay(lastLineChange).length;
});

function getLineNumberDisplay(change: DynamicChangeLine): string {
  // return empty string or the line number as string
  const changeLineFile = change[props.origin] as DynamicChangeLineFile;
  return changeLineFile.isEmpty ? '' : changeLineFile.actualLineNumber.toString();
}

function getLineContent(change: DynamicChangeLine): string {
  return (change[props.origin] as DynamicChangeLineFile).data;
}

const showDragHandles = computed(
  () =>
    !props.splitSnippetMode &&
    props.editMode &&
    props.applicability === ApplicabilityStatus.Verified &&
    // If there's only one line, there's nowhere to drag & drop - so don't show the handles to avoid confusing the user.
    props.linesContent.length > 1
);

const firstMeatLineIndex = computed(() =>
  props.linesContent.findIndex((line) => line.changeType !== HunkChangeLineType.Context)
);

const lastMeatLineIndex = computed(() =>
  findLastIndex(props.linesContent, (line) => line.changeType !== HunkChangeLineType.Context)
);

const draggedHandle = ref<'top-handle' | 'bottom-handle' | null>(null);
const indexOfLineBeingDraggedOver = ref<number | null>(null);

const EMPTY_IMAGE = document.createElement('img');
EMPTY_IMAGE.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

function onDragStart(event: DragEvent, index: number, handle: 'top-handle' | 'bottom-handle') {
  draggedHandle.value = handle;
  // We set the image to an empty element to avoid it being half of the code line.
  event.dataTransfer?.setDragImage(EMPTY_IMAGE, 0, 0);
}

function isIndexOutsideDragRange(index: number) {
  return (
    (draggedHandle.value === 'top-handle' && index > lastMeatLineIndex.value) ||
    (draggedHandle.value === 'bottom-handle' && index <= firstMeatLineIndex.value)
  );
}

function onDragEnter(index: number) {
  if (isIndexOutsideDragRange(index)) {
    return;
  }
  indexOfLineBeingDraggedOver.value = index;
}

function onDragOver(event: DragEvent, index: number) {
  if (isIndexOutsideDragRange(index)) {
    return;
  }

  if (!draggedHandle.value) {
    return;
  }

  // Calling event.preventDefault is what tells the browser that the user is allowed to drop on this particular element.
  // When they do, the browser will fire a drop event on this element (handled by onDrop below).
  event.preventDefault();
  event.stopPropagation();
}

function onDragEnd() {
  draggedHandle.value = null;
  indexOfLineBeingDraggedOver.value = null;
}

function isMeat(line: DynamicChangeLine, index: number) {
  // When user is not dragging, just show meat lines as meat and context lines as context.
  if (draggedHandle.value == null || indexOfLineBeingDraggedOver.value == null) {
    return line.changeType !== HunkChangeLineType.Context;
  }
  // When user is dragging, show meat/context based on the current dragged handle position to simulate the new, edited
  // state of the snippet while dragging.
  if (draggedHandle.value === 'top-handle') {
    if (index < indexOfLineBeingDraggedOver.value) {
      return false;
    }
    return index <= lastMeatLineIndex.value;
  }
  if (index >= indexOfLineBeingDraggedOver.value) {
    return false;
  }
  return index >= firstMeatLineIndex.value;
}

function onDrop() {
  if (draggedHandle.value == null || indexOfLineBeingDraggedOver.value == null) {
    return;
  }
  let firstLineIndex: number;
  let lastLineIndex: number;
  if (draggedHandle.value === 'top-handle') {
    firstLineIndex = indexOfLineBeingDraggedOver.value;
    lastLineIndex = lastMeatLineIndex.value;
  } else {
    firstLineIndex = firstMeatLineIndex.value;
    lastLineIndex = indexOfLineBeingDraggedOver.value - 1;
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const firstLineNumber = props.linesContent[firstLineIndex][props.origin]!.actualLineNumber;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const lastLineNumber = props.linesContent[lastLineIndex][props.origin]!.actualLineNumber;
  emit('line-range-change', { firstLineNumber, lastLineNumber });
  onDragEnd();
}

// Scroll to the meat area if it is not in view.
const { isScrolledIntoView } = useScroll();

const changeLines = ref<HTMLDivElement[]>([]);
watch(
  () => [props.splitSnippetMode, changeLines.value] as const,
  ([splitSnippetMode, changeLines]) => {
    if (
      splitSnippetMode &&
      changeLines.length > 0 &&
      changeLines
        .slice(firstMeatLineIndex.value, lastMeatLineIndex.value - firstMeatLineIndex.value + 1)
        .every((el) => !isScrolledIntoView(el))
    ) {
      changeLines[firstMeatLineIndex.value].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
);
</script>

<template>
  <div
    v-for="(change, index) in linesContent"
    :key="`change-${index}`"
    data-testid="change-line"
    class="change-line"
    ref="changeLines"
    :class="[
      applicability,
      {
        [change.changeType]: diffMode && applicability !== ApplicabilityStatus.Verified,
        a: origin === 'fileA',
        b: origin === 'fileB',
        meat: isMeat(change, index),
        editable: diffMode,
        'disconnected-from-repo': forceDisconnected,
        'diff-mode': diffMode,
        'split-mode': splitSnippetMode,
      },
    ]"
    @dragover="(ev) => onDragOver(ev, index)"
  >
    <CodeSnippetContentLine
      :origin="origin"
      :language="language"
      :line-display="getLineNumberDisplay(change)"
      :content="getLineContent(change)"
      :amount-of-spaces-to-trim="trimPrefixSpaces ? minPrefixSpaces : 0"
      :query-definitions="queryDefinitions"
      @add-def-snippet="emit('add-def-snippet', $event)"
      @token-interacted="(op: 'hover' | 'click', tok: OverlayToken) => emit('token-interacted', op, tok)"
    />
    <div
      v-if="splitSnippetMode && isMeat(change, index) && index != lastMeatLineIndex"
      class="split-line"
      data-testid="split-line"
      @click="emit('split-snippet', change[props.origin]!.actualLineNumber)"
      v-tooltip="'Split snippet here'"
    />
    <template v-if="showDragHandles">
      <div
        v-if="draggedHandle"
        class="drag-target-before-current-line"
        @dragenter="onDragEnter(index)"
        @dragover="(ev) => onDragOver(ev, index)"
        @drop="onDrop"
        :class="{ 'dragged-over': indexOfLineBeingDraggedOver === index }"
      />
      <!-- We use index + 1 here since this drop target is 'after' the current line, so when dropping over it we set the
           end of the meat to be at index + 1. -->
      <div
        v-if="draggedHandle"
        class="drag-target-after-current-line"
        @dragenter="onDragEnter(index + 1)"
        @dragover="(ev) => onDragOver(ev, index + 1)"
        @drop="onDrop"
        :class="{ 'dragged-over': indexOfLineBeingDraggedOver === index + 1 }"
      />
      <!-- We show the top drag handle only on the first meat line which is the top of the meat area, so that the user can
           drag it to shrink/expand the meat area. -->
      <div
        v-if="index == firstMeatLineIndex && allowDragDropEditing"
        class="top-drag-handle"
        data-testid="top-drag-handle"
        draggable="true"
        @dragstart="(ev) => onDragStart(ev, index, 'top-handle')"
        @dragend="onDragEnd()"
        v-tooltip="'Drag to add/remove lines. Use \'Show more\' to see more lines to add.'"
      />
      <!-- We show the bottom drag handle on the the bottom of the last meat line, which is the bottom of the meat area,
           so that the user can drag it to shrink/expand the meat area. -->
      <div
        v-if="index == lastMeatLineIndex && allowDragDropEditing"
        class="bottom-drag-handle"
        data-testid="bottom-drag-handle"
        draggable="true"
        @dragstart="(ev) => onDragStart(ev, index + 1, 'bottom-handle')"
        @dragend="onDragEnd()"
        v-tooltip="'Drag to add/remove lines. Use \'Show more\' to see more lines to add.'"
      />
    </template>
  </div>
</template>

<style scoped lang="postcss">
.change-line {
  position: relative;
  --min-char-amount: v-bind(lastLineNumberChatAmount);
  background: var(--color-surface);
  --drag-handle-height: var(--scale-xsmall);

  .split-line {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    transform: translateY(50%);
    cursor: row-resize;
    z-index: var(--layer-dropdown);

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      height: 0;
      border-bottom: 1px dashed var(--color-brand-disable);
    }

    &:hover::after {
      border-bottom: 1px solid var(--color-brand);
    }
  }

  &.meat.split-mode .split-line:after {
    animation: 3s flash-split ease;
  }

  &:deep(.data-highlight) {
    opacity: 0.75;
  }

  &.meat {
    background: transparent;

    &:deep(.data-highlight) {
      opacity: 1;
    }

    &.autosyncable {
      background-color: var(--color-code-autosync-meat);

      &.diff-mode {
        background-color: var(--color-code-autosync-sandwich);
      }

      &.autosync {
        background-color: var(--color-code-autosync-meat);
      }
    }

    &.outdated {
      background-color: var(--color-code-error-meat);

      &.diff-mode {
        background-color: var(--color-code-error-sandwich);
      }

      &.autosync {
        background-color: var(--color-code-error-meat);
      }
    }

    &.disconnected-from-repo {
      background-color: var(--color-surface);
    }
  }

  .top-drag-handle,
  .bottom-drag-handle {
    cursor: row-resize;
  }

  .drag-target-before-current-line,
  .top-drag-handle {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 51%; /* some overlap with drag-target-after-current-line to avoid jumpiness */

    &.dragged-over::before,
    &.top-drag-handle:hover::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: var(--drag-handle-height);
      background-color: var(--color-brand-disable);
      transform: translateY(-50%);
    }
  }

  .drag-target-after-current-line,
  .bottom-drag-handle {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;

    &.dragged-over::before,
    &.bottom-drag-handle:hover:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: var(--drag-handle-height);
      background-color: var(--color-brand-disable);
      transform: translateY(50%);
    }
  }
}

@keyframes flash-split {
  0% {
    border-bottom: 1px dashed var(--color-brand-disable);
  }

  25% {
    border-bottom: 3px dashed var(--color-brand);
  }

  75% {
    border-bottom: 3px dashed var(--color-brand);
  }

  100% {
    border-bottom: 1px dashed var(--color-brand-disable);
  }
}
</style>
