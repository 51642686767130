<script setup lang="ts">
import { SwModal } from '@swimm/ui';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { gitwrapper, productEvents } from '@swimm/shared';
import { computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useRoute } from 'vue-router';
import GitAuthorizeBanner from '@/common/components/Auth/GitAuthorizeBanner.vue';
import { useReposStore } from '@/modules/repo/stores/repos-store';

const { track } = useAnalytics();
const workspaceStore = useWorkspaceStore();
const { user } = storeToRefs(useAuthStore());
const route = useRoute();
const { reposStateData, repoId } = storeToRefs(useReposStore());

onMounted(() => {
  track(productEvents.SHOWN_REAUTHORIZE_POPUP, {
    'User Email': user.value.email,
    Context: 'Reauthorize Popup',
    'Page Name': route.name,
  });
});

const provider = computed(() => reposStateData.value?.[repoId.value]?.provider ?? null);

const providerDisplayName = computed(
  () => provider.value && (gitwrapper.getTerminology(provider.value).displayName as string)
);

function onBackClick() {
  track(productEvents.CLICKED_BACK_HOME, {
    Context: 'Reauthorize Popup',
  });
}
</script>
<template>
  <SwModal :show-modal="true" :header-border="false" :padded="false">
    <div class="authorize-modal">
      <SwText variant="headline2">Re-authorize Swimm</SwText>
      To see and edit Swimm docs in your repository, please authorize {{ providerDisplayName }} access.
      <a
        href="https://docs.swimm.io/getting-started-guide/connecting-repositories-to-Swimm/"
        class="external-link"
        target="_blank"
      >
        Learn more
      </a>
      <div class="actions">
        <GitAuthorizeBanner
          class="button"
          :provider="provider"
          context="Reauthorize Popup"
          button-size="big"
          use-provider-icon
        />
        <router-link :to="`/workspaces/${workspaceStore.id}`" class="link" @click="onBackClick">
          <Action class="button" secondary> Back to home </Action>
        </router-link>
      </div>
    </div>
  </SwModal>
</template>

<style scoped lang="postcss">
.authorize-modal {
  padding: var(--space-md);
  width: 480px;
  text-align: center;

  .external-link {
    text-decoration: underline;
  }

  .actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--space-xs);
    margin-top: var(--space-md);

    .link {
      display: block;
      width: 100%;
    }

    .button {
      width: 100%;
      padding: var(--space-xs) var(--space-lg);
    }
  }
}
</style>
