import * as _ from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import {
  SwmMeta,
  SwmSymbolGenericText,
  SwmSymbolLink,
  SwmSymbolLinkType,
  SwmSymbolType,
  SwmSymbols,
  SwmdMetadata,
} from '../types';
import { SWMD_FILE_EXTENSION, SWMD_PLAYLIST_EXTENSION, SWM_FILE_EXTENSION } from '../config';
import { generateSwmdFileName } from '../utils/swm-utils';
import { UrlUtils, objectUtils, shortHash } from '../shared';
import {
  getMentionRegex,
  getSymbolPathRegex,
  getSymbolTextRegex,
  getTextPlaceholderRegex,
} from '../utils/symbols-utils';
import { getLinksTextRegex } from '../utils/links-utils';
import { isSWMDVersionBefore102, isSWMDVersionBefore110 } from './swmd-version-manager';
import { SwimmParsingError } from '../types/swimm-errors';
import { removeNewLineAfterHtmlLineBreakTag } from '../string-utils';

const SWMD_SMART_TEXT_OPEN_TAG = '<swm-token data-swm-token="';
const SWMD_SMART_TEXT_CLOSE_TAG = '"/>';

interface ParitalGenericText {
  text: string;
  wordIndex: { start: number; end: number };
  lineNumber: number;
  path: string;
}
function composeSmartTextAddress(symObj: SwmSymbolGenericText) {
  return `${symObj.path}:${symObj.lineNumber}:${symObj.wordIndex.start ?? -1}:${symObj.wordIndex.end ?? -1}`;
}

function composeSmartTextCodeLookup(symObj: SwmSymbolGenericText) {
  return `\`${_.escape(symObj.lineData)}\``;
}

function composeMultiRepoIdentifier(currentRepoId: string, symbolRepoId: string): string {
  if (currentRepoId === symbolRepoId) {
    return '';
  }
  return symbolRepoId;
}

function getDisplayTextAsInlineCode(text: string) {
  return `\`${_.escape(text)}\``;
}

export function composeSmartTextString(isExported: boolean, symObj: SwmSymbolGenericText, repoId: string) {
  const displayText = getDisplayTextAsInlineCode(symObj.text);
  if (isExported) {
    return displayText;
  } else {
    // `displayText`<swm-token data-swm-token="repo_id:path_to_file:line_number:word_start_index:word_end_index:`code_lookup`"/>
    let smartTextString = `${displayText}${SWMD_SMART_TEXT_OPEN_TAG}`;
    smartTextString += `${composeMultiRepoIdentifier(repoId, symObj.repoId)}`;
    smartTextString += `:${composeSmartTextAddress(symObj)}`;
    smartTextString += `:${composeSmartTextCodeLookup(symObj)}`;
    smartTextString += SWMD_SMART_TEXT_CLOSE_TAG;
    return smartTextString;
  }
}

function isValidSmartTextSymbol(symbol: SwmSymbolGenericText): boolean {
  if (!symbol.repoId) {
    return false;
  }
  if (!symbol.path) {
    return false;
  }
  return !!symbol.lineData;
}

export function stringifyTextSymbols(
  text: string,
  symbols: SwmSymbols,
  repoId: string,
  metaData: SwmMeta,
  isExported = false
): string {
  if (symbols) {
    Object.entries(symbols).forEach(([symKey, symObj]) => {
      let symbolRegex: RegExp = null;
      let newValue = '';
      switch (symObj.type) {
        case SwmSymbolType.PATH: {
          symbolRegex = getSymbolPathRegex(symKey);
          let repoName;
          if (symObj.repoId !== repoId && metaData.cross_repo_names) {
            repoName = Object.keys(metaData.cross_repo_names).find(
              (name) => metaData.cross_repo_names[name] === symObj.repoId
            );
          }
          newValue = `\`📄${repoName ? `(${repoName})` : ''} ${symObj.path}\``;
          break;
        }
        case SwmSymbolType.GENERIC_TEXT: {
          symbolRegex = getSymbolTextRegex(symKey, symObj.text);
          if (isValidSmartTextSymbol(symObj)) {
            newValue = composeSmartTextString(isExported, symObj, repoId);
          } else {
            newValue = getDisplayTextAsInlineCode(symObj.text);
          }
          break;
        }
        case SwmSymbolType.LINK: {
          symbolRegex = getLinksTextRegex(symKey);
          newValue = `[${symObj.text}](${getSymbolLink(symObj, repoId)})`;
          break;
        }
        case SwmSymbolType.MENTION: {
          symbolRegex = getMentionRegex(symKey);
          newValue = `\`👤 ${symObj.text}[${symObj.userId}]\``;
          break;
        }
        case SwmSymbolType.TEXT_PLACEHOLDER: {
          symbolRegex = getTextPlaceholderRegex(symObj.text, symKey);
          newValue = symObj.value ? `${symObj.value}` : `{${symObj.text}}`;
          break;
        }
        default: {
          // Here only to make sure our type check is exhaustive
          const exhaustiveCheck: never = symObj;
          throw new Error(`Unhandled SwmSymbol case: ${JSON.stringify(exhaustiveCheck)}`);
        }
      }
      if (symbolRegex) {
        /**
         * So, apparently `String.replace` supports [special replacement patterns](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#specifying_a_string_as_the_replacement)
         *  that can be naturally found in our desired {@link newValue}, causing the end result of the replacement process to become invalid.
         *
         * To solve that, we send a [replacer function](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#specifying_a_function_as_the_replacement) as the second parameter,
         *  which bypasses all the replacement patterns process and allows us to simply return an unmodified value.
         */
        const replacerDummyFunc = (): string => newValue;
        text = text.replace(symbolRegex, replacerDummyFunc);
      }
    });

    text = removeNewLineAfterHtmlLineBreakTag(text);
  }
  return text;
}

function getSymbolLink(symbol: SwmSymbolLink, currentRepoId: string): string {
  if (currentRepoId === symbol.repoId) {
    // For links on the same repo - return relative path to the file(just the file name)
    return symbol.swimmType === SwmSymbolLinkType.Exercise
      ? `${symbol.swimmId}${SWM_FILE_EXTENSION}`
      : `${generateSwmdFileName(symbol.swimmId, symbol.text)}${
          symbol.swimmType === SwmSymbolLinkType.Playlist ? SWMD_PLAYLIST_EXTENSION : SWMD_FILE_EXTENSION
        }`;
  }
  // For links across repos - return a full browser link
  return UrlUtils.getSwimmLink({
    swimmId: symbol.swimmId,
    repoId: symbol.repoId,
    isEdit: false,
    swimmType: symbol.swimmType,
  });
}

type SymbolData = {
  symbolText: string;
  repoId?: string;
  path: string;
  lineNumber: string;
  wordStartIndex: string;
  wordEndIndex: string;
  codeLookup?: string;
};

function parseAsSwmSymbol(metadata: SwmdMetadata, fileRepoId: string, symbolGroups: object = {}): SwmSymbolGenericText {
  if (objectUtils.isEmpty(symbolGroups)) {
    throw new SwimmParsingError('Empty symbol');
  }

  const symbolData = symbolGroups as SymbolData;

  const path = symbolData.path;
  if (!path) {
    throw new SwimmParsingError('path is missing');
  }
  const fileBlob: string = symbolData.repoId
    ? metadata.cross_repo_file_blobs?.[symbolData.repoId]?.[path]
    : metadata?.['file_blobs']?.[path]; // token from file repo do not save their repoId in their metadata

  const text = _.unescape(symbolData.symbolText);
  if (!text) {
    throw new SwimmParsingError('symbolText is missing');
  }
  const lineNumber = parseInt(symbolData.lineNumber, 10);
  if (isNaN(lineNumber)) {
    throw new SwimmParsingError('lineNumber is missing');
  }
  const start = parseInt(symbolData.wordStartIndex, 10);
  const end = parseInt(symbolData.wordEndIndex, 10);
  if (isNaN(start) || isNaN(end)) {
    throw new SwimmParsingError('wordStartIndex or wordEndIndex are invalid');
  }
  return {
    type: SwmSymbolType.GENERIC_TEXT,
    text,
    lineNumber,
    path,
    fileBlob,
    repoId: symbolData.repoId || fileRepoId,
    wordIndex: { start, end },
    lineData: _.unescape(symbolData.codeLookup),
  } as SwmSymbolGenericText;
}

// Negative wordIndexes are allowed to protect against a smart token bug.
const SWMD_SMART_TEXT_REGEX_V_110 =
  /`(?<symbolText>(?!`)\S*?[^\s`])`<swm-token data-swm-token="(?<repoId>.[^:]*)?:(?<path>.[^:]+):(?<lineNumber>\d+):(?<wordStartIndex>-?\d+):(?<wordEndIndex>-?\d+):`(?<codeLookup>.*?)`"\/>/;

function getTextSymbolsRegexByVersion(metadata: SwmdMetadata) {
  if (isSWMDVersionBefore102(metadata)) {
    return new RegExp(/`\S+`\[<sup>\d+<\/sup>\]\(#\d+\)/gm);
  }
  if (isSWMDVersionBefore110(metadata)) {
    return new RegExp(/`\S+`\[<sup id="\S+">↓<\/sup>\]\(#\S+\)/gm);
  }
  return new RegExp(SWMD_SMART_TEXT_REGEX_V_110, 'gm');
}

function getTextSymbolsGroupRegexByVersion(metadata: SwmdMetadata) {
  if (isSWMDVersionBefore102(metadata)) {
    return new RegExp(/`(\S+)`\[<sup>(\d+)<\/sup>\]\(#(\d+)\)/);
  }
  if (isSWMDVersionBefore110(metadata)) {
    return new RegExp(/`(\S+)`\[<sup id="(\S+)">↓<\/sup>\]\(#\S+\)/);
  }
  return new RegExp(SWMD_SMART_TEXT_REGEX_V_110);
}

export function getSymbolHash(symbol: ParitalGenericText): string {
  return shortHash(`${symbol.text}${symbol.wordIndex.start}${symbol.wordIndex.end}${symbol.lineNumber}${symbol.path}`);
}

export function parseSWMDtextSmartTextSymbols(
  text: string,
  symbols: SwmSymbols,
  symbolIndexToSymbolIdMap: Record<string, string>,
  metadata: SwmdMetadata,
  fileRepoId: string
) {
  // Find smartText symbols
  // On 1.0.2 and up: "bla bla `Adapters`[<sup id="gr67">^</sup>](#f-gr67)  bla" => "`Adapters`[<sup id="gr67">↓</sup>](#f-gr67)"
  // Before 1.0.2:  "bla bla `Setup`[<sup>1</sup>](#1)  bla" => "`Setup`[<sup>1</sup>](#1)`"
  const foundTextSymbols = text.match(getTextSymbolsRegexByVersion(metadata));

  const textSymbolGroupsRegex = getTextSymbolsGroupRegexByVersion(metadata);
  let formattedText = text;
  if (foundTextSymbols && foundTextSymbols.length > 0) {
    for (const textSymbol of foundTextSymbols) {
      const symbolGroups = textSymbol.match(textSymbolGroupsRegex);
      let symbolText = '';
      let symbolId = '';
      if (isSWMDVersionBefore110(metadata)) {
        // Before 1.1.0 inline smart text did not contain metadata
        symbolText = symbolGroups[1];
        const symbolHash = symbolGroups[2];
        if (!symbolIndexToSymbolIdMap[symbolHash]) {
          symbolIndexToSymbolIdMap[symbolHash] = uuidv4();
        }
        symbolId = symbolIndexToSymbolIdMap[symbolHash];
      } else {
        try {
          const symbol = parseAsSwmSymbol(metadata, fileRepoId, symbolGroups.groups);
          const symbolHash = getSymbolHash(symbol);
          symbolId = symbolHash;
          symbols[symbolHash] = symbol;
          symbolText = symbols[symbolHash].text;
        } catch (e) {
          continue;
        }
      }
      formattedText = formattedText.replace(textSymbol, `[[sym-text:${symbolText}(${symbolId})]]`);
    }
  }
  return formattedText;
}
