<script lang="ts">
import type { IconsType } from '../../components/BaseIcon/BaseIcon.vue';

export const SwmNodeViewItemLinkTypes = ['doc', 'playlist', 'task'] as const;
export type SwmNodeViewItemLinkTypesType = Extract<IconsType, (typeof SwmNodeViewItemLinkTypes)[number]>;
</script>

<script setup lang="ts">
import { computed } from 'vue';

import BaseButton from '../../components/BaseButton/BaseButton.vue';
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';
import BaseLayoutGap from '../../components/BaseLayoutGap/BaseLayoutGap.vue';
import BaseProse from '../../components/BaseProse/BaseProse.vue';
import BaseLoading from '../../components/BaseLoading/BaseLoading.vue';
import BaseTooltip from '../../components/BaseTooltip/BaseTooltip.vue';

const props = defineProps<{
  variant?: string;
  tooltip?: string;
  type: SwmNodeViewItemLinkTypesType;
  name: string;
  draft?: boolean;
  loading?: boolean;
  unavailable?: boolean;
  selected?: boolean;
  inactive?: boolean;
}>();

const emit = defineEmits<{
  (e: 'click'): void;
  (e: 'cmd-click'): void;
}>();

const computedClasses = computed(() => ({
  [`swm-node-view-item-link--${props.variant}`]: !!props.variant,
  [`swm-node-view-item-link--selected`]: props.selected,
}));

function onClick(e: MouseEvent) {
  e.preventDefault();
  e.stopPropagation();
  if (!props.inactive && e.button === 0 && !e.ctrlKey && !e.metaKey) {
    emit('click');
  }
}

function onMouseUp(e: MouseEvent) {
  e.preventDefault();
  e.stopPropagation();
  if (!props.inactive && (e.button === 1 || (e.button === 0 && e.ctrlKey) || (e.button === 0 && e.metaKey))) {
    emit('cmd-click');
  }
}
</script>

<template>
  <div class="swm-node-view-item-link" :class="computedClasses">
    <BaseTooltip :content="tooltip">
      <BaseLayoutGap direction="row" alignment="center" size="xxsmall">
        <BaseButton
          class="swm-node-view-item-link__button"
          size="large"
          variant="link"
          :disabled="inactive"
          data-testid="swimm-link"
          :data-applicability="variant"
          @click="onClick"
          @mouseup="onMouseUp"
          ><template #leftIcon><BaseIcon :name="type" /></template>{{ name }}</BaseButton
        >

        <BaseProse v-if="draft" variant="secondary" size="small">(Draft)</BaseProse>

        <BaseLoading v-if="loading" variant="secondary" size="xsmall" />
        <BaseProse v-else-if="unavailable" class="swm-node-view-item-link__unavailable" variant="danger">
          <BaseIcon name="unavailable" />
        </BaseProse>
      </BaseLayoutGap>
    </BaseTooltip>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-node-view-item-link {
  $self: &;

  display: inline-block;

  &--selected {
    background-color: var(--color-bg-selected);
  }

  &__unavailable {
    max-height: var(--line-height-default);
  }

  /**
   * TODO: EditLink has a "inactive" state which makes it look
   * like it's clickable when it isn't. It's a bit odd but
   * for now I've added the styling for this here. Basically
   * just overriding the default state for backwards compatability.
   */
  &__button {
    &[disabled] {
      cursor: default;
      color: var(--color-text-brand);
      text-decoration: none;

      &:hover {
        color: var(--color-text-brand);
        text-decoration: none;
      }
    }
  }

  /**
   * TODO: We have a couple of states that are poorly considered and
   * handled. These styles are here for legacy purposes but we should
   * revisit the logic and purpose of these states.
   */
  &--outdated {
    border-radius: 3px;
    box-shadow: 0 0 0 2px var(--color-border-danger);
    padding: 0 var(--space-xs);
  }

  &--autosyncable {
    border-radius: 3px;
    box-shadow: 0 0 0 2px var(--color-border-magic);
    padding: 0 var(--space-xs);
  }
}
</style>
