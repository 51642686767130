/* tslint:disable */
/* eslint-disable */
/**
 * Swimm\'s Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.34.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from './base';
/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function (configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.healthGet(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['HealthApi.healthGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration, basePath, axios) {
    const localVarFp = HealthApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet(options) {
            return localVarFp.healthGet(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    healthGet(options) {
        return HealthApiFp(this.configuration).healthGet(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * SwimmApi - axios parameter creator
 * @export
 */
export const SwimmApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {AddEnterpriseRequest} addEnterpriseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEnterprise: (addEnterpriseRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'addEnterpriseRequest' is not null or undefined
            assertParamExists('addEnterprise', 'addEnterpriseRequest', addEnterpriseRequest);
            const localVarPath = `/api/add-enterprise`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(addEnterpriseRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * SwimmApi - functional programming interface
 * @export
 */
export const SwimmApiFp = function (configuration) {
    const localVarAxiosParamCreator = SwimmApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {AddEnterpriseRequest} addEnterpriseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEnterprise(addEnterpriseRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addEnterprise(addEnterpriseRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.addEnterprise']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * SwimmApi - factory interface
 * @export
 */
export const SwimmApiFactory = function (configuration, basePath, axios) {
    const localVarFp = SwimmApiFp(configuration);
    return {
        /**
         *
         * @param {AddEnterpriseRequest} addEnterpriseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEnterprise(addEnterpriseRequest, options) {
            return localVarFp.addEnterprise(addEnterpriseRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * SwimmApi - object-oriented interface
 * @export
 * @class SwimmApi
 * @extends {BaseAPI}
 */
export class SwimmApi extends BaseAPI {
    /**
     *
     * @param {AddEnterpriseRequest} addEnterpriseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    addEnterprise(addEnterpriseRequest, options) {
        return SwimmApiFp(this.configuration).addEnterprise(addEnterpriseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
