<script setup lang="ts">
import { SwText, SwToggle } from '@swimm/ui';
import { computed, nextTick, ref } from 'vue';
import { useRepoIntegrations } from '@/modules/repo/composables/repo-integrations';
import { exceptionAlert } from '@/common/utils/alert';
import { useStore } from 'vuex';
import { useStigg } from '@/common/composables/useStigg';
import PaywallModal, { PaywallEntity } from '@/common/components/modals/PaywallModal.vue';
import { StiggFeatures, eventLogger, productEvents } from '@swimm/shared';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { SWIMM_DOC_SITE_ASK_SWIMM } from '@/config';
import EnableInWorkspaceSettingsBanner from '@/modules/repo/settings/EnableInWorkspaceSettingsBanner.vue';

const props = defineProps({
  repoId: { type: String, required: true },
  workspaceId: { type: String, required: true },
});
const emit = defineEmits(['close', 'change-tab', 'open-workspace-settings']);

const store = useStore();
const { stiggClient } = useStigg();
const analytics = useAnalytics();
const auth = useAuthStore();

const repoMetadata = computed(() => store.getters['database/db_getRepoMetadata'](props.repoId));

const { integrations, updateIntegrations } = useRepoIntegrations(props.repoId);

const FIELD_ASK_SWIMM = 'ask_swimm_enabled';

const askSwimmEnabled = ref((integrations.value?.[FIELD_ASK_SWIMM] as boolean) ?? false);
const showPaywall = ref(false);
const loading = ref(false);

const showWorkspaceSettingsBanner = computed(() => {
  return toggledOn.value;
});

function canEnableAskSwimm() {
  return stiggClient && stiggClient.getBooleanEntitlement
    ? stiggClient.getBooleanEntitlement({ featureId: StiggFeatures.ASK_SWIMM }).hasAccess
    : false;
}

const toggledOn = ref(false);

async function updateToggles(enableAskSwimm: boolean) {
  loading.value = true;
  toggledOn.value = enableAskSwimm;
  try {
    const dbFields = {};
    askSwimmEnabled.value = enableAskSwimm;
    dbFields[FIELD_ASK_SWIMM] = enableAskSwimm;
    reportEvent(productEvents.TOGGLE_REPO_ASK_SWIMM, {
      Enabled: enableAskSwimm,
      backofficeCode: eventLogger.SWIMM_EVENTS.TOGGLE_REPO_ASK_SWIMM.code,
    });

    await updateIntegrations(dbFields);
  } catch (error) {
    await exceptionAlert({ title: 'Failed to update repo settings', error });
    if (enableAskSwimm != null) {
      askSwimmEnabled.value = !enableAskSwimm;
    }
  } finally {
    loading.value = false;
  }
}

async function dontChangeToggle(oldValue, newValue) {
  if (newValue) {
    askSwimmEnabled.value = newValue;
    await nextTick(() => {
      askSwimmEnabled.value = oldValue;
    });
  }
}

async function toggleAskSwimmOption(enableAskSwimm: boolean) {
  // check if user can turn it on
  if (!canEnableAskSwimm()) {
    showPaywall.value = true;
    dontChangeToggle(!enableAskSwimm, enableAskSwimm);
    return;
  }

  // update setting in DB
  await updateToggles(enableAskSwimm);
}

function reportEvent(event: string, payload: object) {
  analytics.cloudTrack({
    identity: auth.user.uid,
    event,
    payload: {
      'Workspace ID': props.workspaceId,
      'Repo ID': props.repoId,
      'Repo Name': repoMetadata.value.name,
      ...payload,
    },
  });
}

function closePaywallModal({ isRedirecting }) {
  showPaywall.value = false;
  if (isRedirecting) {
    emit('close');
  }
}

function getImageUrl(image) {
  return new URL(`../assets/${image}`, import.meta.url).href;
}
</script>

<template>
  <div>
    <div class="feature" :class="{ disabled: loading }">
      <div class="toggle-container">
        <SwToggle
          class="toggle-button"
          :disabled="loading"
          :value="askSwimmEnabled"
          size="small"
          @change="toggleAskSwimmOption"
        />
        <SwText variant="subtitle-XL"><strong>Ask Swimm</strong></SwText>
      </div>
      <EnableInWorkspaceSettingsBanner
        v-if="showWorkspaceSettingsBanner"
        :disabled="loading"
        @open-workspace-settings="$emit('open-workspace-settings')"
      />

      <img class="image" :src="getImageUrl('ask-swimm-settings.svg')" />
      <div class="description">
        <SwText variant="body-L">
          Ask Swimm allows you to ask questions and get personalized answers from based on context from your docs and
          codebase. By enabling, you agree that snippets from code and documents in your repo might be sent to Azure
          Open AI.
        </SwText>
        <a :href="SWIMM_DOC_SITE_ASK_SWIMM" target="_blank"><u>Learn more</u></a>
      </div>
    </div>
    <PaywallModal :show="showPaywall" :entity="PaywallEntity.ASK_SWIMM" @close="closePaywallModal" />
  </div>
</template>

<style scoped lang="postcss">
.description {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  color: var(--text-color-secondary);
}

.toggle-container {
  display: flex;
  gap: var(--space-base);
  align-items: center;
}

.disabled {
  opacity: 0.4;
}

.image {
  align-self: stretch;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--space-md);
}
</style>
