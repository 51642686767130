import type MarkdownIt from 'markdown-it';
import type StateBlock from 'markdown-it/lib/rules_block/state_block';
import { ATTRIBUTE, parseHtmlOpenTag } from './html';

const BLOCK_IMAGE_REGEXP = new RegExp('^(<p' + ATTRIBUTE + '*\\s*>)\\s*(<img' + ATTRIBUTE + '*\\s*/?>)\\s*</p>', 'i');

function parseBlockImage(state: StateBlock, startLine: number, endLine: number, silent: boolean): boolean {
  const pos = state.bMarks[startLine] + state.tShift[startLine];
  const max = state.eMarks[startLine];

  // If it's indented more than 3 spaces, it should be a code block
  if (state.sCount[startLine] - state.blkIndent >= 4) {
    return false;
  }

  if (state.src.charCodeAt(pos) !== 0x3c /* < */) {
    return false;
  }

  const lineText = state.src.slice(pos, max);

  const match = lineText.match(BLOCK_IMAGE_REGEXP);
  if (!match) {
    return false;
  }

  if (silent) {
    // true if this sequence can be a terminator, false otherwise
    return true;
  }

  state.line = startLine + 1;

  const _pTag = parseHtmlOpenTag(match[1]);
  const imgTag = parseHtmlOpenTag(match[2]);

  const token = state.push('block_image', '', 0);
  token.map = [startLine, startLine + 1];
  token.content = state.getLines(startLine, startLine + 1, state.blkIndent, true);
  token.attrs = [...(imgTag?.attrs ?? [])];

  return true;
}

export default function block_image(md: MarkdownIt): void {
  md.block.ruler.before('html_block', 'block_image', parseBlockImage);
}
