<script setup lang="ts">
import { type PropType, computed } from 'vue';
import { useRoute } from 'vue-router';
import { FolderFilterResult, useFoldersStore } from '@/modules/folders/store/folders';
import type { Folder } from '@/modules/folders/types';
import SidebarRepoLineFolderTreeItem from './SidebarRepoLineFolderTreeItem.vue';
import { storeToRefs } from 'pinia';
import FolderNameEdit from '@/modules/folders/components/FolderNameEdit.vue';
import { useWorkspaceSidebarStore } from '@/modules/core/workspace/sidebar/store/workspace-sidebar';
import { useStore } from 'vuex';
import { DocumentationTypes } from '@/common/consts';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';

const props = defineProps({
  folder: { type: Object as PropType<Folder>, required: true },
  repoId: { type: String, required: true },
  path: { type: String, default: null },
  depth: { type: Number, required: true },
  filter: { type: String, default: '' },
  filteredFolders: { type: Array<FolderFilterResult>, default: null },
});

const route = useRoute();
const { addFolderInFolderId } = storeToRefs(useWorkspaceSidebarStore());
const { getFolderItems } = useFoldersStore();
const drafts3Store = useDrafts3Store();
const store = useStore();

const changedFiles = computed(() => store.getters['filesystem/fs_getChangedFilesForBranch'](props.repoId));

const currentFolderItems = computed(() => {
  const items = getFolderItems({
    repoId: props.repoId,
    folderId: props.folder.id,
    includeDrafts: props.repoId === route.params.repoId,
  });

  const itemsIncludingDrafts = items.map((item) => {
    let diffState;
    const changedFile = changedFiles.value.find((file) => 'path' in item && file.path.startsWith(item.path));

    if (changedFile) {
      diffState = changedFile.status;
    } else if ('isNew' in item && item.isNew) {
      diffState = 'added';
    } else if (drafts3Store.drafts?.get(item.id) != null) {
      diffState = 'modified';
    }
    return { ...item, ...(diffState ? { diffState } : {}) };
  });
  const filteredItems = itemsIncludingDrafts.filter((item) => 'draftId' in item && !item.id && item.draftId);

  const uniqueItemsById: Record<string, (typeof itemsIncludingDrafts)[0]> = {};
  itemsIncludingDrafts.forEach((item) => {
    if (!item.id) {
      return;
    }
    // Above, we grouped drafts next to their original document. So here we only keep the draft if it exists to show it
    // to the user and not the original document (so that they can discard the draft, for example).
    if ('draftId' in item) {
      uniqueItemsById[item.id] = item;
    } else if (!uniqueItemsById[item.id]) {
      uniqueItemsById[item.id] = item;
    }
  });

  // TODO: When deleting the old drafts logic, replace with `const folderItems = itemsIncludingDrafts;`.
  const folderItems = [...Object.values(uniqueItemsById), ...filteredItems];
  if (!props.filter) {
    return folderItems;
  }
  return folderItems.filter(
    (item) =>
      (item.documentationType === DocumentationTypes.FOLDER &&
        props.filteredFolders &&
        props.filteredFolders.some((folder) => item.id === folder.folderId)) ||
      item.name?.toLowerCase().includes(props.filter.toLowerCase())
  );
});

const treeNodeStyle = computed(() => {
  const baseTreeStartPadding = 20;
  const nodePadding = 20 * props.depth;
  return `padding-left: ${baseTreeStartPadding + nodePadding}px`;
});

const emptyFolderMessage = computed(() => {
  const isRoot = props.folder?.is_root;
  if (!props.folder?.children?.length) {
    if (isRoot) {
      return 'No content in this repo.';
    }
    return 'This folder is empty.';
  }
  if (props.filter) {
    return 'No items match your search.';
  }
  return `This ${isRoot ? 'repo' : 'folder'} only contains docs/playlists in another branch`;
});

const folderPath = computed(() => {
  return props.path || props.folder.name;
});
</script>

<template>
  <div class="folder-tree" data-testid="folder-content">
    <FolderNameEdit
      v-if="addFolderInFolderId === folder.id"
      class="new-folder-input"
      :repo-id="repoId"
      show-icon
      show-error-below
      font-class="body-XS"
      :style="treeNodeStyle"
      @change="() => (addFolderInFolderId = null)"
      @cancel="() => (addFolderInFolderId = null)"
    />
    <template v-for="folderItem in currentFolderItems" :key="folderItem.id">
      <SidebarRepoLineFolderTreeItem
        :tree-item="folderItem"
        :repo-id="repoId"
        :depth="depth"
        :path="folderPath"
        :filter="filter"
        :filtered-folders="filteredFolders"
      />
    </template>
    <div
      v-if="currentFolderItems.length === 0 && addFolderInFolderId !== folder.id"
      class="empty-message"
      :style="treeNodeStyle"
    >
      <SwText variant="body-S">
        {{ emptyFolderMessage }}
      </SwText>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.new-folder-input {
  height: var(--sidebar-item-height);
  box-sizing: border-box;
}

.folder-tree {
  display: flex;
  flex-direction: column;
}

.empty-message {
  height: var(--sidebar-item-height);
  color: var(--text-color-secondary);
  display: flex;
  align-items: center;
}
</style>
