import type { Editor } from '@tiptap/core';
import { VueRenderer } from '@tiptap/vue-3';
import type { Instance } from 'tippy.js';
import tippy from 'tippy.js';

import type { SelectedSuggestion } from '@/components/SwimmportPopover.vue';
import SwimmportPopover from '@/components/SwimmportPopover.vue';

export function showHoverSwimmportPopover(
  editor: Editor,
  element: HTMLElement,
  onSelect: (selectedItem: SelectedSuggestion) => void,
  props: Record<string, unknown> = {},
  popoverOptions: { onShow?: () => void; onHide?: () => void; onUntrigger?: () => void }
): { open: () => void; close: () => void; onKeyDown: (event: KeyboardEvent) => boolean } {
  let component: VueRenderer | null = null;
  let popup: Instance | null = null;

  component = new VueRenderer(SwimmportPopover, {
    props: {
      ...props,
      editor,
      hide: () => popup?.hide(),
      onMouseLeave: () => popup?.hide(),
      onSelected: (newSelected: SelectedSuggestion) => {
        onSelect(newSelected);
        popup?.hide();
      },
      onPreviewShown: () => popup?.popperInstance?.update(),
    },
    editor,
  });

  const handleUpdate = () => {
    popup?.hide();
  };

  popup = tippy(editor.view.dom, {
    getReferenceClientRect: () => {
      return element.getBoundingClientRect();
    },
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    appendTo: editor.view.dom.parentElement!,
    triggerTarget: [component.element], // Makes the component itself the trigger for the popover. Meaningful in existing the popover.
    content: component.element,
    showOnCreate: false,
    interactive: true,
    placement: 'bottom-start',
    theme: 'none',
    role: 'menu',
    maxWidth: 'none',
    offset: [0, 0],
    onHidden: (instance) => {
      component?.destroy();
      instance.destroy();
      component = null;
      popup = null;
      editor.off('update', handleUpdate);
    },
    ...popoverOptions,
  });

  editor.on('update', handleUpdate);

  const close = () => {
    if (popup == null || popup.state.isDestroyed) {
      return;
    }
    popup.hide();
  };

  const open = () => {
    if (popup == null || popup.state.isDestroyed) {
      return;
    }
    popup.show();
  };

  const onKeyDown = (event: KeyboardEvent) => component?.ref?.onKeyDown(event) ?? false;

  return { open, close, onKeyDown };
}
