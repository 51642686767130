<template>
  <transition name="fade">
    <div
      v-if="activeModal"
      :class="['modal-wrapper', { padded: activeModal.padded, 'top-layer': activeModal.topLayer }]"
      @click.self="closeModalFromBackground"
    >
      <div ref="modal" class="modal" tabindex="0" @keydown.esc="close">
        <header v-if="activeModal.heading" class="modal-header">
          <div class="text-reverse-ellipsis text-ellipsis">
            <span class="title text-reverse-ellipsis-inner">{{ activeModal.heading }}</span>
          </div>
          <IconButton name="close" @click="close" />
        </header>
        <div :class="{ 'modal-content': !dontUseMinSize }">
          <component :is="activeModal.name" :options="activeModal.options"></component>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import MarkdownEditorModalContent from '@/common/components/modals/MarkdownEditorModalContent.vue';
import PushChangesModalContent from '@/common/components/modals/PushChangesModalContent.vue';
import KeyboardShortcutsModalContent from '@/modules/help-center/modals/KeyboardShortcutsModalContent.vue';
import AssignDocModal from '@/modules/doc-assignments/modals/AssignDocModal.vue';
import { storeToRefs } from 'pinia';
import { useGlobalModalStore } from '@/modules/core/modals/stores/modals-store';
import { IconButton } from '@swimm/ui';

/**
 * @deprecated Please use Modal instead of GlobalModal
  For more info check out https://app.swimm.io/repos/veezvxCuzpPrRLLXWD2E/docs/pSecbbz9rqXfPi7AssQ9).
 */

export default {
  components: {
    MarkdownEditorModalContent,
    PushChangesModalContent,
    KeyboardShortcutsModalContent,
    AssignDocModal,
    IconButton,
  },
  setup() {
    const modalsStore = useGlobalModalStore();
    const { activeModal } = storeToRefs(modalsStore);
    const { closeModal } = modalsStore;
    return { activeModal, closeModal };
  },
  computed: {
    dontUseMinSize() {
      return this.activeModal.dontUseMinSize ? this.activeModal.dontUseMinSize : false;
    },
  },
  updated() {
    if (this.activeModal) {
      this.$refs.modal.focus();
    }
  },
  methods: {
    close() {
      if (this.activeModal.options && this.activeModal.options.close) {
        this.activeModal.options.close();
      }
      this.closeModal();
    },
    closeModalFromBackground() {
      if (!this.activeModal?.options?.permanentModal) {
        this.closeModal();
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);

  &.top-layer {
    z-index: 1001;
  }
}

.modal {
  z-index: 1000;
  margin: 2%;
  width: auto;
  max-width: 75vw;
  border-radius: 8px;
  background: var(--color-bg);
  box-shadow: var(--box-shadow-big);
}

.modal:focus {
  outline: none;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 24px;
  border-bottom: 1px solid var(--color-border-default);
}

.modal-content {
  overflow: auto;
  padding: 5vh;
  min-height: 300px;
  max-height: 70vh;
  box-sizing: border-box;
  word-break: break-word;
}

.modal-wrapper:not(.padded) .modal-content {
  padding: 0;
}

.title {
  font-weight: bold;
  flex-grow: 1;
}
</style>
