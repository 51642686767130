import { Extension } from '@tiptap/core';

export interface TopArrowUpOptions {
  topArrowUp: () => void;
}

export default Extension.create<TopArrowUpOptions>({
  name: 'topArrowUp',

  addOptions() {
    return {
      topArrowUp: () => void 0,
    };
  },

  addKeyboardShortcuts() {
    return {
      ArrowUp: ({ editor }) => {
        const { state } = editor;

        if (!state.selection.empty) {
          return false;
        }

        const isAtTop = state.selection.from <= 1;
        if (!isAtTop) {
          return false;
        }

        this.options.topArrowUp();
        return true;
      },
    };
  },
});
