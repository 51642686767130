import { type Editor, type ExtendedRegExpMatchArray, PasteRule, type PasteRuleFinder } from '@tiptap/core';
import type { NodeType } from '@tiptap/pm/model';

/**
 * Build an paste rule that adds a node when the matched text is pasted into it.
 * Asynchronously retrieving attributes.
 *
 * This isn't perfect and has no indication while the async operation is still
 * running and will add an additional transaction to the undo stack.
 */
export function asyncNodePasteRule(
  editor: Editor,
  config: {
    find: PasteRuleFinder;
    type: NodeType;
    getAttributes: (match: ExtendedRegExpMatchArray) => Promise<Record<string, unknown> | false | null>;
  }
) {
  return new PasteRule({
    find: config.find,
    handler({ chain, match, range, state }) {
      const id = {};

      if (match.input) {
        const original = state.doc.slice(range.from, range.to);
        chain().deleteRange(range).addInsertionPlaceholder(id, range.from).run();

        config.getAttributes(match).then((attributes) => {
          editor
            .chain()
            .replaceInsertionPlaceholder(
              id,
              attributes
                ? {
                    type: config.type.name,
                    attrs: attributes,
                  }
                : original.content.toJSON()
            )
            .run();
        });
      }
    },
  });
}
