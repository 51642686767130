export interface Workspace {
  id: string;
  name: string;
  repositories: Array<string>;
  openai_key?: string;
  settings?: {
    disable_gen_ai: boolean;
    is_workspace_local_mode: boolean;
  };
}

export interface WorkspaceUser {
  external_uid: string;
  uid?: string;
  id: string;
  email: string;
  name: string;
}

export enum SwimmEnvKind {
  DEVELOPMENT = 'development',
  TEST = 'test',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum EditorEnvKind {
  VSCODE = 'VSCode',
  JETBRAINS = 'JetBrains',
  WEBAPP = 'WebApp',
}
