import type { EventBusKey } from '@vueuse/core';

export interface AnimationFocusMessage {
  type: 'focus';
  id: string;
}

export type AnimationMessage = AnimationFocusMessage;

export const ANIMATIONS_BUS_KEY = Symbol('animationsBus') as EventBusKey<AnimationMessage>;
