import { defineStore } from 'pinia';
import { STORES } from '@/modules/core/stores/store-constants';
import { ref } from 'vue';
import { useWorkspaceSettingsModalStore } from '@/modules/workspace/modals/settings/store/workspace-settings';
import { useCreationHubStore } from '../creation-hub/store/creation-hub';

export interface AddReposModalOptions {
  multiSelect?: boolean;
}

export const useAppModalsStore = defineStore(STORES.APP_MODAL_STORE, () => {
  const { closeWorkspaceSettingsModal } = useWorkspaceSettingsModalStore();
  const { closeCreationHubModal } = useCreationHubStore();
  const modalOrigin = ref<string>('');
  const modalCallback = ref<{ [emitKey: string]: () => void }>({});
  const addReposModalOptions = ref<AddReposModalOptions>({});
  const showRepoSettingsModal = ref<boolean>(false);

  const showAddReposModal = ref<boolean>(false);
  function openAddReposModal(options?: AddReposModalOptions) {
    addReposModalOptions.value = options ?? {};
    showAddReposModal.value = true;
  }
  function closeAddReposModal() {
    showAddReposModal.value = false;
    modalOrigin.value = '';
    modalCallback.value = {};
  }

  function closeModals({ fromRouter }: { fromRouter: boolean }) {
    closeAddReposModal();
    closeCreationHubModal({ fromRouter });
    closeWorkspaceSettingsModal({ fromRouter });
    showRepoSettingsModal.value = false;
  }

  return {
    addReposModalOptions,
    showAddReposModal,
    showRepoSettingsModal,
    modalOrigin,
    modalCallback,
    openAddReposModal,
    closeAddReposModal,
    closeModals,
  };
});
