<script setup>
import { SwText } from '@swimm/ui';
import TopMenuLayout from '@/common/layouts/TopMenuLayout.vue';
import TopBar from '@/common/components/TopBar/TopBar.vue';
import { capitalize } from 'lodash-es';
import { computed } from 'vue';

const props = defineProps({
  workspaceId: { type: String, required: true },
  productSKU: { type: String, required: true },
});

const planName = computed(() => capitalize(props.productSKU));
</script>
<template>
  <TopMenuLayout>
    <template #topBar>
      <TopBar />
    </template>
    <template #content>
      <div class="container">
        <Icon name="check" class="circle item" />
        <SwText variant="headline1" class="item">Upgrade successful</SwText>
        <div class="item">
          Your workspace has been upgraded to the
          {{ planName }} plan.
        </div>
        <router-link :to="`/workspaces/${workspaceId}/`">
          <Action>Back to my workspace</Action>
        </router-link>
      </div>
    </template>
  </TopMenuLayout>
</template>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 360px;
  height: 100%;
  text-align: center;
  flex-direction: column;
}

.item {
  margin-bottom: 20px;
  padding: 0;
}

.circle {
  width: 57px;
  height: 57px;
  font-size: var(--fontsize-xl);
  border-radius: 50%;
  text-align: center;
  background: var(--color-status-success);
  line-height: 57px;
}
</style>
