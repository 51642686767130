<script setup>
import { computed } from 'vue';
import { Icon } from '@swimm/ui';
const props = defineProps({
  isOpen: { type: Boolean, required: true },
  isSelected: { type: Boolean, required: true },
  name: { type: String, required: true },
  expandable: { type: Boolean, default: false },
});
const iconName = computed(() => (props.isOpen ? 'arrow-up' : 'arrow-down'));
</script>

<template>
  <button class="filter-button body-S" :class="{ 'filter-button-selected': isSelected, expandable: expandable }">
    <slot name="left" />
    {{ name }}
    <Icon v-if="!expandable" :name="iconName" />
  </button>
</template>

<style scoped lang="postcss">
.filter-button {
  font-family: var(--fontfamily-main);
  border: 1px solid var(--color-border-default);
  color: var(--text-color-primary);
  border-radius: 4px;
  height: 32px;
  cursor: pointer;
  background: none;

  &.expandable {
    display: flex;
    align-items: center;
    cursor: default;

    &:hover {
      border: 1px solid var(--color-border-default);
    }
  }

  &:hover {
    border: 1px solid var(--color-border-default-strong);
  }

  &.filter-button-selected {
    border: 1px solid var(--color-border-brand);
    background: var(--color-selected);

    &:hover {
      border: 1px solid var(--color-border-default-strong);
    }
  }
}
</style>
