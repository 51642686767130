<script setup lang="ts">
import { computed } from 'vue';
import { type TokenSuggestion } from '@swimm/shared';
import type { CachedHighlighters } from '../../types';

import { getLanguageFromPath } from '../../lib/languages';
import { getTheme } from '../../lib/theme';

import BaseLayoutGap from '../../components/BaseLayoutGap/BaseLayoutGap.vue';
import BaseBadge from '../../components/BaseBadge/BaseBadge.vue';
import BaseCode from '../../components/BaseCode/BaseCode.vue';
import BaseProse from '../../components/BaseProse/BaseProse.vue';

const props = withDefaults(
  defineProps<{
    code: string;
    lineNumber?: number;
    tokenSuggestion: TokenSuggestion;
    maxRows?: number;
    cachedHighlighters?: CachedHighlighters;
  }>(),
  {
    lineNumber: 1,
    maxRows: 5,
    cachedHighlighters: undefined,
  }
);

const highlighter = computed(() => {
  return (
    props.cachedHighlighters &&
    props.cachedHighlighters[`${getLanguageFromPath(props.tokenSuggestion.position.path)}-${highlighterTheme}`]
  );
});
const highlighterTheme = getTheme() === 'dark' ? 'github-dark' : 'github-light';

const codeLines = computed(() => {
  return props.code.replaceAll(/\r\n/g, '\n').split('\n');
});

const startLineNumber = computed(() => {
  return Math.max(1, props.tokenSuggestion.position.line - Math.floor((props.maxRows - 1) / 2));
});

const endLineNumber = computed(() => {
  return Math.min(codeLines.value.length, props.tokenSuggestion.position.line + Math.ceil((props.maxRows - 1) / 2));
});

const codeSnippet = computed(() => {
  const snippet = codeLines.value.slice(startLineNumber.value - 1, endLineNumber.value).join('\r');
  return snippet;
});
</script>

<template>
  <div v-if="highlighter" class="swm-selection-content-token-preview">
    <BaseLayoutGap wrapper="header" class="swm-selection-content-token-preview__header">
      <BaseBadge variant="tertiary" size="medium">Preview</BaseBadge>

      <BaseProse v-if="tokenSuggestion != null" variant="secondary" size="small" class="ellipsize">{{
        tokenSuggestion.position.path
      }}</BaseProse>
    </BaseLayoutGap>
    <div class="swm-selection-content-token-preview__content">
      <BaseCode
        :code="codeSnippet"
        :line-number="startLineNumber"
        :highlight-tokens="[tokenSuggestion]"
        :highlighter="highlighter"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-selection-content-token-preview {
  $self: &;

  @include basic-resets;

  background-color: var(--color-bg-surface);
  border-top: 1px solid var(--color-border-default-subtle);
  flex-shrink: 0;
  padding: 0 var(--space-xsmall) var(--space-xxsmall);
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;

  &__header {
    padding: var(--space-xxsmall) 0;
  }

  &__content {
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    height: calc(var(--line-height-mono) * v-bind(maxRows));
  }

  .ellipsize {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
