<template>
  <div class="sidebar-part">
    <div class="main-row">
      <div class="sidebar-part-title" :class="{ clickable: isCollapsable }" @click="collapseOrExpand">
        <SwText variant="subtitle-XL" weight="bolder">{{ title }}</SwText>
        <slot name="title-suffix"></slot>
        <Icon
          v-if="isCollapsable"
          class="clickable collapse-toggle"
          :name="arrowIconName"
          :tooltip="arrowIconTooltip"
        />
      </div>
      <slot name="header" />
    </div>
    <div v-show="expandedInternally || expandedExternally" class="sidebar-part-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    expandedExternally: { type: Boolean, default: false },
    isCollapsable: { type: Boolean, default: true },
    collapsedByDefault: { type: Boolean, default: false },
  },
  data() {
    return {
      expandedInternally: !this.collapsedByDefault,
    };
  },
  emits: ['collapse-tag-bar'],
  computed: {
    arrowIconName() {
      return this.expanded ? 'expand-arrow' : 'collapse-arrow';
    },
    arrowIconTooltip() {
      return this.expanded ? 'Collapse' : 'Expand';
    },
    expanded() {
      if (!this.isCollapsable) {
        return false;
      }
      return this.expandedInternally || this.expandedExternally;
    },
  },
  methods: {
    collapseOrExpand() {
      if (!this.isCollapsable) {
        return;
      }
      if (this.expandedExternally) {
        this.$emit('collapse-tag-bar');
        this.expandedInternally = false;
      } else {
        this.expandedInternally = !this.expandedInternally;
      }
      return this.expandedInternally;
    },
  },
};
</script>

<style scoped lang="postcss">
.sidebar-part {
  margin-top: 8px;
  margin-bottom: var(--space-md);

  .sidebar-part-title {
    display: flex;
  }

  .sidebar-part-title:not(.clickable) {
    cursor: default;
  }

  .sidebar-part-content {
    margin-top: 16px;
  }

  .collapse-toggle {
    font-size: var(--fontsize-xxs);
    margin-top: 5px;
  }
}

.main-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
</style>
