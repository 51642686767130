<script setup>
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { Action, BaseLabel, BaseSelect, Divider, SwModal, SwText, TextField } from '@swimm/ui';
import { countryCodeToCountry, getLoggerNew } from '@swimm/shared';
import { computed, defineAsyncComponent, ref, watch } from 'vue';
import { stripePublishableKey } from '@/config';

const ERROR_STRING = 'Something went wrong. Please try again';

const logger = getLoggerNew(__modulename);

const props = defineProps({
  show: { type: Boolean, required: true },
  workspaceId: { type: String, required: true },
});
const emit = defineEmits(['close', 'save']);

const loading = ref(true);

let customerInfo;

const cardFieldRef = ref(null);

const publishableKey = ref(stripePublishableKey);
const countryList = Object.entries(countryCodeToCountry)
  .map(([countryCode, country]) => ({ code: countryCode, label: country }))
  .sort((countryA, countryB) => (countryA.label > countryB.label ? 1 : -1));
const countries = ref(countryList);

const saving = ref(false);
const error = ref('');

const name = ref('');
const creditCardState = ref({});

const expandAddress = ref(false);

const address = ref('');
const city = ref('');
const country = ref({});
const state = ref('');

async function setInitialState() {
  saving.value = false;
  expandAddress.value = false;

  customerInfo = await CloudFunctions.fetchSubscriptionCustomerDetails({ workspaceId: props.workspaceId });
  loading.value = false;

  name.value = customerInfo.data.name;
  creditCardState.value = { id: '', valid: false, empty: true, error: '' };

  const currentAddress = customerInfo.data.address;
  address.value = currentAddress.line1;
  city.value = currentAddress.city;
  country.value = countries.value?.find((c) => c.code === currentAddress.country);
  state.value = currentAddress.state;
}

const addressSummary = computed(() => {
  let summary = '';
  summary += address.value ? `${address.value}, ` : '';
  summary += city.value ? `${city.value}, ` : '';
  summary += country.value.label ? `${country.value.label}` : '';
  return summary;
});

const cardDetailsChanged = computed(() => !creditCardState.value.empty || name.value !== customerInfo?.data?.name);

const cardDetailsValid = computed(
  () => !cardDetailsChanged.value || (name.value && creditCardState.value.valid && !creditCardState.value.empty)
);

const addressDetailsChanged = computed(() => {
  const currentAddress = customerInfo?.data?.address;
  const addressChanged = address.value !== currentAddress.line1;
  const cityChanged = city.value !== currentAddress.city;
  const countryChanged = country.value.code !== currentAddress.country;
  const stateChanged = state.value !== currentAddress.state;
  return expandAddress.value && (addressChanged || cityChanged || countryChanged || stateChanged);
});

const addressDetailsValid = computed(() => {
  const hasState = country.value.states?.length > 0;
  return !addressDetailsChanged.value || (country.value.code && (hasState ? state.value : true));
});

const shouldDisableSave = computed(() => {
  return (
    (!cardDetailsChanged.value && !addressDetailsChanged.value) || !cardDetailsValid.value || !addressDetailsValid.value
  );
});

function onCreditCardTokenCreationFailure(error) {
  logger.error(`Failed on creating token from credit card. Details: ${error}`);
  creditCardState.value = { ...creditCardState.value, error: ERROR_STRING };
}

function onCreditCardNumberChange({ completed, empty }) {
  creditCardState.value = { ...creditCardState.value, valid: completed, empty, error: '' };
}

async function onSaveClick() {
  saving.value = true;

  let paymentMethod = '';
  if (cardDetailsChanged.value && creditCardState.value.valid) {
    const token = await cardFieldRef.value.submitCard();
    paymentMethod = token.id;
    if (!paymentMethod) {
      creditCardState.value = { ...creditCardState.value, error: ERROR_STRING };
      saving.value = false;
      return;
    }
  }

  const updateParams = {};
  if (cardDetailsChanged.value && cardDetailsValid.value) {
    updateParams.name = name.value;
    updateParams.source = paymentMethod;
  }
  if (addressDetailsChanged.value && addressDetailsValid.value) {
    updateParams.address = {
      city: city.value,
      line1: address.value,
      country: country.value.code,
      ...(state.value && { state: state.value }),
    };
  }

  const customer = await CloudFunctions.updateSubscriptionCustomerDetails({
    workspaceId: props.workspaceId,
    updateParams,
  });

  saving.value = false;
  if (customer.data) {
    emit('close');
    emit('save');
  } else {
    error.value = ERROR_STRING;
  }
}

watch(
  () => props.show,
  async (newValue) => {
    if (newValue) {
      await setInitialState();
    }
  }
);

const CreditCardTextFieldAsync = defineAsyncComponent(() =>
  import('@/common/components/Billing/CreditCardTextField.vue')
);
</script>

<template>
  <SwModal
    :padded="false"
    :show-modal="show"
    :close-on-backdrop-click="false"
    heading="Change payment method"
    @close="emit('close')"
  >
    <div class="container">
      <Loader v-if="loading" />
      <template v-else>
        <SwText variant="subtitle-XL" class="section-title">Card details</SwText>
        <TextField class="high-section" v-model="name" label="Company name" />
        <div class="high-section">
          <BaseLabel>Card number</BaseLabel>
          <template v-if="publishableKey">
            <CreditCardTextFieldAsync
              ref="cardFieldRef"
              :publishable-key="publishableKey"
              @token-create-failure="onCreditCardTokenCreationFailure"
              @payment-card-updated="onCreditCardNumberChange"
            />
            <SwText v-if="creditCardState.error" variant="body-S" class="error">{{ creditCardState.error }}</SwText>
          </template>
          <TextField v-else disabled />
        </div>
        <Divider class="sections-divider" />
        <SwText variant="subtitle-XL" class="section-title">Billing address</SwText>
        <template v-if="!expandAddress">
          <SwText variant="body-L" class="address-summary">{{ addressSummary }}</SwText>
          <SwText variant="body-XS" class="link" @click="expandAddress = true">Edit address</SwText>
        </template>
        <template v-else>
          <TextField class="short-section" v-model="address" label="Address" />
          <TextField class="short-section" v-model="city" label="City" />
          <BaseSelect class="short-section" disabled v-model="country" label="Country" :options="countries" />
          <BaseSelect
            v-if="country.states?.length > 0"
            class="short-section"
            disabled
            v-model="state"
            label="State"
            :options="country.states"
          />
        </template>
        <div class="actions">
          <Action secondary @click="emit('close')">Cancel</Action>
          <Action :loading="saving" :disabled="shouldDisableSave" @click="onSaveClick">Save</Action>
        </div>
      </template>
      <SwText v-if="error" variant="body-XS" class="save-error">{{ error }}</SwText>
    </div>
  </SwModal>
</template>

<style scoped lang="postcss">
.container {
  min-width: 450px;
  padding: var(--space-md);

  .section-title {
    margin-bottom: var(--space-base);
  }

  .high-section {
    flex: 1;
    margin-bottom: var(--space-sm);
  }

  .short-section {
    flex: 1;
    margin-bottom: var(--space-base);
  }

  .sections-divider {
    margin: var(--space-md) 0;
  }

  .address-summary {
    margin-bottom: var(--space-base);
  }

  .link {
    color: var(--text-color-link);
    cursor: pointer;
    display: inline-block;
  }

  .error {
    display: inline-block;
    color: var(--text-color-error);
  }

  .save-error {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--space-base);
    color: var(--text-color-error);
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--space-base);
    margin-top: var(--space-md);
  }
}
</style>
