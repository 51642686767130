// @ts-strict
import type {
  GitCommit,
  GitCommitDiffs,
  GitCommitRef,
  GitItem,
  GitPullRequest,
  GitPush,
  GitRepository,
} from 'azure-devops-extension-api/Git/Git';
import Cache from 'lru-cache';
import {
  GitProviderName,
  PathType,
  RenamedSwmResourceFile,
  ResultWithReturnCode,
  SwmResourceFile,
  SwmResourceState,
} from '../../types';
import UrlUtils from '../../utils/url-utils';
import {
  Branch,
  CreatedPR,
  DiffFileMetadata,
  DriverOptions,
  GitDriverBase,
  PartialRemoteRepository,
  PendingPR,
  PrData,
  ProviderSpecificTerms,
  PullRequestState,
  RemoteRepository,
  RepoIdOrRepoData,
  RepoIdOwnerName,
  RepoTree,
  RepoTreePrams,
  githubChangeStatus,
} from './git-provider-base';
import { AzureTransport } from '../transports/azure-transport';
import gitProviderUtils from '../git-provider-utils';
import { getLogger } from '../../logger/legacy-shim';
import { promiseAllLimit } from '../../utils/promise-utils';
import { StatusCodes } from 'http-status-codes';
import { ERROR_RETURN_CODE, SUCCESS_RETURN_CODE, SWM_FOLDER_IN_REPO } from '../../config';
import { removeCRLFCharacters } from '../../utils/string-utils';
import { ChangeRequestData, changeRequestsPendingDocsReducer, isSwmImageInRepo } from '../git-utils-common';
import {
  AzureListBranchesParams,
  AzureListBranchesPolicyParams,
  AzureListTreeParams,
  AzurePullRequestStatus,
  AzureRequestContext,
  AzureVersion,
  AzureVersionOptions,
  AzureVersionType,
} from '../transports/azure-transport-types';

const logger = getLogger("packages/shared/src/git-utils/gitdrivers/azure-driver.ts");

const FETCH_FILE_CACHE_TTL_MS = 20_000;
const CACHE_TTL_MS = 5_000;
const PROMISE_ALL_LIMIT = 8;
const EMPTY_SHA = '0000000000000000000000000000000000000000';
const WRONG_OBJECT_ID_ERROR = 'An object ID must be 40 characters long and only have hex digits. Passed in object ID:';

const azureStatus2GitHubStatus: Record<string, string> = {
  add: 'ADDED',
  edit: 'MODIFIED',
  rename: 'MODIFIED',
  'delete, sourceRename': 'MODIFIED',
  delete: 'DELETED',
} as const;

const stateToChangeType: Record<SwmResourceState, string> = {
  [SwmResourceState.Created]: 'add',
  [SwmResourceState.Updated]: 'edit',
  [SwmResourceState.Deleted]: 'delete',
  [SwmResourceState.Renamed]: 'add',
} as const;

interface Organization {
  name: string;
  url: string;
}

type GetGitRepositoryKey = `ORGN:${string}-REPON:${string}-PROJECTN:${string}`;
type GetFileDataCacheKey = `RID:${string}-BSHA:${string}-PATH:${string}-CONTENT:${boolean}`;
type GetBranchCacheKey = `RID:${string}-BNAME${string}`;

export class AzureDriver extends ProviderSpecificTerms implements GitDriverBase {
  private transport: AzureTransport;
  private protectedBranches: Record<string, Record<string, boolean>> = {}; // {azureRepoId: {branchName: isProtected}}

  private repoInformationMap: Map<string, AzureRequestContext> = new Map();

  private fetchCommitCache = new Cache<string, GitCommit>({
    ttl: FETCH_FILE_CACHE_TTL_MS,
    ttlResolution: FETCH_FILE_CACHE_TTL_MS / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });

  private fileDataCache = new Cache<GetFileDataCacheKey, ReturnType<typeof this.getFileDataFromRevision>>({
    ttl: FETCH_FILE_CACHE_TTL_MS,
    ttlResolution: FETCH_FILE_CACHE_TTL_MS / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });

  public constructor({ authToken = '', baseUrl, tenantId }: DriverOptions = {}) {
    super();
    this.transport = new AzureTransport({ authToken, baseUrl, tenantId });
  }

  private getGitRepositoryCache = new Cache<GetGitRepositoryKey, ReturnType<typeof this.getGitRepository>>({
    ttl: 300_000,
    ttlResolution: 300_000 / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });

  private async getGitRepository({
    orgName,
    projectName,
    repoName,
  }: {
    orgName: string;
    projectName: string | null;
    repoName: string;
  }): Promise<GitRepository | null> {
    const cacheKey: GetGitRepositoryKey = `ORGN:${orgName}-REPON:${repoName}-PROJECTN:${projectName ?? 'unknown'}`;
    const cacheHit = this.getGitRepositoryCache.get(cacheKey);
    if (cacheHit) {
      return cacheHit;
    }

    const getGitRepositoryLogic = async () => {
      let gitRepoInformation: GitRepository | null = null;
      let isLastProjectPage = false;
      let skipProjects = 0;
      do {
        let projects;
        try {
          projects = await this.transport.listProjects({
            orgName,
            params: { $skip: skipProjects },
          });
        } catch (err) {
          logger.warn(
            `Can't access organizations ${orgName} projects. This may be because the organizations policy is not set to allow "Third part application access via OAuth". Error: ${err}`
          );
          this.getGitRepositoryCache.delete(cacheKey);
          break;
        }

        isLastProjectPage = projects.count === 0;
        skipProjects += projects.count;
        for await (const project of projects.value) {
          if (!projectName || project.name === projectName) {
            const repositoriesInProject = await this.transport.listRepositories({ orgName, projectName: project.name });
            for await (const repository of repositoriesInProject.value) {
              if (repository.name !== repoName) {
                continue;
              }
              gitRepoInformation = repository;
              isLastProjectPage = true;
              break;
            }
          }
        }
      } while (!isLastProjectPage);
      return gitRepoInformation;
    };
    const operation = getGitRepositoryLogic();
    this.getGitRepositoryCache.set(cacheKey, operation);
    return operation;
  }

  private async getRepoInformation(repoId: string): Promise<AzureRequestContext> {
    const { owner, repoName } = await gitProviderUtils.getRepoStateData(repoId);

    if (!this.repoInformationMap.has(repoId)) {
      const [orgName, projectName] = gitProviderUtils.splitOwner(owner);
      const gitRepo = await this.getGitRepository({ orgName, projectName, repoName });
      if (!gitRepo) {
        throw new Error(`Could not find repo ${repoName}`);
      }
      const information: AzureRequestContext = {
        repo: { id: gitRepo.id, name: repoName },
        project: { id: gitRepo.project.id, name: gitRepo.project.name },
        organization: { name: orgName },
      };
      this.repoInformationMap.set(repoId, information);
    }
    return this.repoInformationMap.get(repoId);
  }

  private async getBranchSha(repoId: string, branchName: string): Promise<string> {
    const repoInfo = await this.getRepoInformation(repoId);
    const branch = await this.transport.getBranch({
      context: repoInfo,
      branchName,
    });
    return branch?.objectId;
  }

  deleteTokenFromMemory(): void {
    this.transport.deleteTokenFromMemory();
  }

  async getUserData(
    _provider: GitProviderName,
    _driverOptions?: DriverOptions
  ): Promise<{ login: string; id: number | string }> {
    const result = await this.transport.getUserProfile();
    return { login: result.coreAttributes.DisplayName.value, id: result.id };
  }

  /**
   * NOTE: this function isn't used right now (used for GitHub Marketplace).
   */
  async getOrganizationData(
    _provider: GitProviderName,
    _driverOptions?: DriverOptions
  ): Promise<{ name: string; numOfDevelopers: number }> {
    throw new Error('getOrganizationData is not implemented for Azure');
  }

  async isBranchExists({ repoId, branchName }: { repoId: string; branchName: string }): Promise<boolean> {
    const repoInfo = await this.getRepoInformation(repoId);

    const result = await this.transport.getBranch({ context: repoInfo, branchName });
    return result !== undefined;
  }

  async getChangeRequestName(_props: { repoId: string }): Promise<string> {
    return this.getTerminology(GitProviderName.AzureDevops).pullRequest;
  }

  private async getCommit({
    repoInfo,
    commitSha,
  }: {
    repoInfo: AzureRequestContext;
    commitSha: string;
  }): Promise<GitCommit> {
    const cachedCommit = this.fetchCommitCache.get(commitSha);
    if (cachedCommit) {
      return cachedCommit;
    }
    const commit = await this.transport.getCommit({
      context: repoInfo,
      commitId: commitSha,
    });
    this.fetchCommitCache.set(commitSha, commit);
    return commit;
  }

  private async isBranchProtected({
    repoInfo,
    branchName,
  }: {
    repoInfo: AzureRequestContext;
    branchName: string;
  }): Promise<boolean> {
    if (!this.protectedBranches[repoInfo.repo.id]) {
      this.protectedBranches[repoInfo.repo.id] = {};
    }

    if (this.protectedBranches[repoInfo.repo.id][branchName]) {
      return this.protectedBranches[repoInfo.repo.id][branchName];
    }

    this.protectedBranches[repoInfo.repo.id] = {};
    let continuationToken: string | undefined;
    const refName = `refs/heads/${branchName}`;
    do {
      const branchDetails = await this.transport.getBranch({ context: repoInfo, branchName });

      const branchPolicies = await this.transport.listBranchPolicies({
        context: repoInfo,
        params: {
          repositoryId: repoInfo.repo.id,
          refName,
          continuationToken,
        } as AzureListBranchesPolicyParams,
      });

      const isProtected = branchPolicies.value.some(
        (policy) => policy.isEnabled && policy.isBlocking && !policy.isDeleted
      );
      this.protectedBranches[repoInfo.repo.id][branchName] = isProtected || !!branchDetails.isLocked;

      continuationToken = branchPolicies.continuationToken;
    } while (continuationToken);

    return this.protectedBranches[repoInfo.repo.id][branchName];
  }

  private getBranchCache = new Cache<GetBranchCacheKey, ReturnType<typeof this.getBranch>>({
    ttl: CACHE_TTL_MS,
    ttlResolution: CACHE_TTL_MS / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });

  async getBranch({
    repoId,
    branchName,
  }: {
    repoId: string;
    branchName: string;
    cacheBuster?: boolean;
  }): Promise<Branch> {
    const cacheKey: GetBranchCacheKey = `RID:${repoId}-BNAME:${branchName}`;
    const cacheHit = this.getBranchCache.get(cacheKey);
    if (cacheHit) {
      return cacheHit;
    }
    const getBranchLogic = async () => {
      const repoInfo = await this.getRepoInformation(repoId);
      const branch = await this.transport.getBranch({ context: repoInfo, branchName });
      if (!branch) {
        throw new Error(`Branch ${branchName} not found in repo ${repoInfo.repo.name}`);
      }
      return this.fillBranchObject({ repoInfo, sha: branch.objectId, branch: branchName });
    };
    const operation = getBranchLogic();
    this.getBranchCache.set(cacheKey, operation);
    return operation;
  }

  private async fillBranchObject({
    repoInfo,
    sha,
    branch,
  }: {
    repoInfo: AzureRequestContext;
    sha: string;
    branch: string;
  }) {
    const commit = await this.getCommit({ repoInfo, commitSha: sha });
    return {
      id: sha,
      name: branch,
      sha,
      protected: await this.isBranchProtected({ repoInfo, branchName: branch }),
      lastUpdated: commit.committer.date as unknown as string,
    };
  }

  private async listBranchesWithStatus({
    repoInfo,
    prState,
    skip,
  }: {
    repoInfo: AzureRequestContext;
    prState: PullRequestState;
    skip: number;
  }): Promise<{ branches: Branch[]; hasNextPage: boolean }> {
    let branches: Branch[] = [];
    let hasNextPage = false;
    const result = await this.transport.listPullRequests({
      context: repoInfo,
      params: {
        $skip: skip,
        searchCriteria: {
          status: prState as AzurePullRequestStatus,
        },
      },
    });
    hasNextPage = result.count > 0;
    branches = await promiseAllLimit<Branch>(
      PROMISE_ALL_LIMIT,
      result.value.map(
        (pr) => async () =>
          this.fillBranchObject({
            repoInfo,
            sha: pr.lastMergeSourceCommit.commitId,
            branch: pr.sourceRefName.replace('refs/heads/', ''),
          })
      )
    );

    return { branches, hasNextPage };
  }

  async *getBranches({
    repoId,
    prStates,
  }: {
    repoId: string;
    prStates?: PullRequestState[];
  }): AsyncIterable<{ hasNextPage: boolean; branches: Branch[] }> {
    const repoInfo = await this.getRepoInformation(repoId);
    let continuationToken = '';
    let hasNextPage = false;

    let currentPrStateIndex = 0;
    let currentPrStatus = prStates?.[currentPrStateIndex] ?? '';
    let skip = 0;

    do {
      let branches: Branch[] = [];
      if (!prStates || prStates?.length === 0) {
        const result = await this.transport.listBranches({
          context: repoInfo,
          params: { continuationToken, filter: 'heads/' } as AzureListBranchesParams,
        });
        continuationToken = result.continuationToken ?? '';
        branches = await promiseAllLimit<Branch>(
          PROMISE_ALL_LIMIT,
          result.value.map(
            (branch) => async () =>
              this.fillBranchObject({
                repoInfo,
                sha: branch.objectId,
                branch: branch.name.replace('refs/heads/', ''),
              })
          )
        );
        hasNextPage = !!continuationToken;
      } else {
        ({ branches, hasNextPage } = await this.listBranchesWithStatus({
          repoInfo,
          prState: currentPrStatus,
          skip,
        }));
        skip += branches.length;
        if (!hasNextPage) {
          currentPrStateIndex++;
          currentPrStatus = prStates[currentPrStateIndex];
          skip = 0;
          hasNextPage = !!currentPrStatus;
        }
      }
      yield { hasNextPage, branches };
    } while (hasNextPage);
  }

  private async populatePRDataWithChangedFiles({
    repoInfo,
    pr,
  }: {
    repoInfo: AzureRequestContext;
    pr: GitPullRequest;
  }): Promise<PrData> {
    let filesWithAdditions = 0;
    let skip = 0;
    let isLastPage = false;
    const files: PrData['files'] = [];
    const commit = await this.getCommit({
      repoInfo,
      commitSha: pr.lastMergeSourceCommit.commitId,
    });
    try {
      do {
        const diff = await this.transport.getDiff({
          context: repoInfo,
          params: {
            targetVersion: pr.sourceRefName.replace('refs/heads/', ''),
            baseVersion: pr.targetRefName.replace('refs/heads/', ''),
            $skip: skip,
          },
        });

        files.push(
          ...diff.changes.reduce((acc, change) => {
            if (
              (change.item.gitObjectType as unknown as string) === 'blob' &&
              (change.changeType as unknown as string).split(',').length === 1
            ) {
              acc.push({ additionsInFile: 1, path: change.item.path });
            }
            return acc;
          }, [] as PrData['files'])
        );

        isLastPage = diff.allChangesIncluded ?? false;
        if (isLastPage) {
          filesWithAdditions = diff.changeCounts['Add'] ?? 0;
        }
        skip += diff.changes.length;
      } while (!isLastPage);
    } catch (e) {
      if (e.response.status !== StatusCodes.NOT_FOUND) {
        logger.error(`Error while getting PR changed files for PR:${pr} in the ${repoInfo.repo.name} repo`);
        throw e;
      }
    }

    return {
      state: pr.status as unknown as string,
      prId: pr.pullRequestId,
      createdAt: pr.creationDate as unknown as string,
      updatedAt: commit.committer.date as unknown as string,
      url: pr.url,
      title: pr.title,
      description: pr.description,
      prHeadSha: pr.lastMergeSourceCommit.commitId,
      prBaseSha: pr.lastMergeTargetCommit.commitId,
      sourceBranchName: pr.sourceRefName.replace('refs/heads/', ''),
      author: { username: pr.createdBy.displayName },
      filesWithAdditions,
      files,
    };
  }

  async getPr({ repoId, prId }: { repoId: string; prId: string }): Promise<PrData> {
    const repoInfo = await this.getRepoInformation(repoId);
    const pr = await this.transport.getPullRequest({
      context: repoInfo,
      pullRequestId: prId,
      params: {
        includeCommits: true,
      },
    });
    return this.populatePRDataWithChangedFiles({ repoInfo, pr });
  }

  async getPrs({ repoId, prState }: { repoId: string; prState?: string }): Promise<PrData[]> {
    const repoInfo = await this.getRepoInformation(repoId);
    let skip = 0;
    let isLastPage = false;
    const prs: PrData[] = [];
    do {
      const result = await this.transport.listPullRequests({
        context: repoInfo,
        params: {
          $skip: skip,
          searchCriteria: {
            status: prState as AzurePullRequestStatus,
          },
        },
      });
      prs.push(
        ...(await promiseAllLimit<PrData>(
          PROMISE_ALL_LIMIT,
          result.value.map((pr) => () => this.populatePRDataWithChangedFiles({ repoInfo, pr }))
        ))
      );
      isLastPage = result.count === 0;
      skip += result.count;
    } while (!isLastPage);

    return prs;
  }

  private async populatePRDataChangesRequestData({
    repoInfo,
    branch,
    targetBrach,
  }: {
    repoInfo: AzureRequestContext;
    branch: string;
    targetBrach: string;
  }): Promise<ChangeRequestData['files']> {
    const files: ChangeRequestData['files'] = [];
    let skip = 0;
    let isLastPage = false;
    do {
      const diff = await this.transport.getDiff({
        context: repoInfo,
        params: {
          targetVersion: targetBrach,
          baseVersion: branch,
          $skip: skip,
        },
      });
      files.push(
        ...diff.changes.reduce((acc, change) => {
          if (change.item.isFolder) {
            return acc;
          }
          return acc.concat({
            additionsInFile: 1,
            changeType: azureStatus2GitHubStatus[change.changeType],
            path: change.item.path,
            additions: (change.changeType as unknown as string) === 'add' ? 1 : 0,
            deletions: (change.changeType as unknown as string) === 'delete' ? 1 : 0,
          });
        }, [] as ChangeRequestData['files'])
      );
      isLastPage = diff.allChangesIncluded ?? false;
      skip += diff.changes.length;
    } while (!isLastPage);
    return files;
  }

  async getPendingDocsForBranch({
    repoId,
    branchName,
  }: {
    repoId: string;
    branchName: string;
  }): Promise<Record<string, PendingPR[]>> {
    const prsData: ChangeRequestData[] = [];
    const repoInfo = await this.getRepoInformation(repoId);
    let skip = 0;
    let isLastPage = false;
    do {
      const branches = await this.transport.listPullRequests({
        context: repoInfo,
        params: {
          $skip: skip,
          searchCriteria: {
            status: 'active',
            targetRefName: `refs/heads/${branchName}`,
          },
        },
      });

      const result = await promiseAllLimit<ChangeRequestData | null>(
        PROMISE_ALL_LIMIT,
        branches.value.map((pr) => async () => {
          try {
            const commit = await this.getCommit({
              repoInfo,
              commitSha: pr.lastMergeSourceCommit.commitId,
            });
            const files = await this.populatePRDataChangesRequestData({
              repoInfo,
              branch: branchName,
              targetBrach: pr.sourceRefName.replace('refs/heads/', ''),
            });
            return {
              files,
              sourceBranchName: pr.sourceRefName.replace('refs/heads/', ''),
              prId: pr.pullRequestId,
              prTitle: pr.title,
              createdAt: pr.creationDate as unknown as string,
              updatedAt: commit.committer.date as unknown as string,
            };
          } catch (err) {
            logger.warn(`Got error fetching pr data, Error: ${err}`);
            return null;
          }
        })
      );
      prsData.push(...result.filter((result) => result));
      isLastPage = branches.count === 0;
      skip += branches.count;
    } while (!isLastPage);

    return changeRequestsPendingDocsReducer(prsData);
  }

  /**
   * NOTE: the Azure version can only deal with branch names, not commit SHAs. If we want to restore that functionality, look at the file history and official documentation.
   */
  async getFileContentFromRevision({
    filePath,
    repoId,
    revision,
    raw = false,
    safe = false,
  }: {
    filePath: string;
    repoId: string;
    revision: string;
    raw?: boolean;
    safe?: boolean;
  }): Promise<string> {
    try {
      const isImage = isSwmImageInRepo(filePath);
      if (!isImage) {
        const fileData = await this.getFileDataFromRevision({ repoId, path: filePath, revision, includeContent: true });
        const fileContent = fileData.content;
        if (typeof fileContent !== 'string') {
          return null;
        }
        return raw ? fileContent : removeCRLFCharacters(fileContent);
      } else {
        return await this.getImageFromRevision({ repoId, path: filePath, revision });
      }
    } catch (error) {
      if (safe && error.response.status === StatusCodes.NOT_FOUND) {
        return null;
      }
      throw error;
    }
  }

  private async getImageFromRevision({
    repoId,
    path,
    revision,
  }: {
    repoId: string;
    path: string;
    revision: string;
  }): Promise<string> {
    const versionDescriptor = this.getAzureVersionForRevision(revision);
    const repoInfo = await this.getRepoInformation(repoId);
    return await this.transport.getBinaryContentAsBase64({
      context: repoInfo,
      params: {
        path,
        'versionDescriptor.version': versionDescriptor.version,
        'versionDescriptor.versionType': versionDescriptor.versionType,
        'versionDescriptor.versionOptions': versionDescriptor.versionOptions,
      },
    });
  }

  private async getFileDataFromRevision<T extends boolean>({
    repoId,
    path,
    revision,
    includeContent,
  }: {
    repoId: string;
    path: string;
    revision: string;
    includeContent?: T;
  }): Promise<GitItem> {
    let sha: string;
    if (revision.endsWith('~1')) {
      sha = revision;
    } else {
      const branch = await this.getBranch({ repoId, branchName: revision });
      sha = branch.sha;
    }
    const cacheKey: GetFileDataCacheKey = `RID:${repoId}-BSHA:${sha}-PATH:${path}-CONTENT:${!!includeContent}`;
    const cacheHit = this.fileDataCache.get(cacheKey);
    if (cacheHit) {
      return cacheHit;
    }

    const getFileDataLogic = async () => {
      const repoInfo = await this.getRepoInformation(repoId);
      const versionDescriptor = this.getAzureVersionForRevision(revision);
      const fileData = await this.transport.getFileContent({
        context: repoInfo,
        params: {
          path,
          'versionDescriptor.version': versionDescriptor.version,
          'versionDescriptor.versionType': versionDescriptor.versionType,
          'versionDescriptor.versionOptions': versionDescriptor.versionOptions,
          format: 'json',
          includeContent,
        },
      });
      return fileData as GitItem;
    };
    const operation = getFileDataLogic();
    this.fileDataCache.set(cacheKey, operation);
    return operation;
  }

  private getAzureVersionForRevision(revision: string): AzureVersion {
    const versionDescriptor: AzureVersion = {
      version: revision,
      versionType: 'branch',
      versionOptions: 'none',
    };
    if (revision.endsWith('~1')) {
      versionDescriptor.version = revision.replace(/~1$/, '');
      versionDescriptor.versionType = 'commit';
      versionDescriptor.versionOptions = 'previousChange';
    }
    return versionDescriptor;
  }

  async isFileExistsOnRevision({
    repoId,
    filePath,
    revision,
  }: {
    repoId: string;
    filePath: string;
    revision?: string;
  }): Promise<boolean> {
    try {
      const data = await this.getFileDataFromRevision({ repoId, path: filePath, revision });
      return data !== null;
    } catch {
      return false;
    }
  }

  async createBranch({
    repoId,
    branchName,
    sourceSha,
  }: {
    repoId: string;
    branchName: string;
    sourceSha: string;
  }): Promise<void> {
    const repoInfo = await this.getRepoInformation(repoId);
    try {
      const res = await this.transport.updateCreateOrDeleteBranch({
        context: repoInfo,
        data: [
          {
            name: `refs/heads/${branchName}`,
            newObjectId: sourceSha,
            oldObjectId: EMPTY_SHA,
            repositoryId: repoInfo.repo.id,
            isLocked: false,
          },
        ],
      });
      if (res?.value?.[0]?.success === false) {
        throw new Error('The branch already exists');
      }
    } catch (error) {
      /**
       * The situation is the same a little to the getRepoTree.
       * We can send the branch name to the function, but the original API can take only the SHA for creating the new branch.
       * If we catch this error, it's not a guarantee that the sourceSha is a branch name.
       * But if the sourceSha is a name of a branch, we are getting the SHA for this branch and call this function once else time with SHA instead of the branch name.
       * If the sourceSha is short SHA we will get an error when we tried to get information about branches by the short SHA.
       */
      const msg = error.response?.data?.message ?? '';
      if (msg.startsWith(WRONG_OBJECT_ID_ERROR)) {
        const branchSha = await this.getBranchSha(repoId, sourceSha);
        if (!branchSha) {
          throw new Error(`Could not find branch ${sourceSha} in repo ${repoInfo.repo.name}`);
        }
        await this.createBranch({ repoId, branchName, sourceSha: branchSha });
        return;
      }
      logger.error(`Error while creating branch ${branchName} from the ${sourceSha} repo`);
      throw error;
    }
  }

  async createPullRequest({
    repoId,
    fromBranch,
    toBranch,
    title,
    body,
  }: {
    repoId: string;
    fromBranch: string;
    toBranch: string;
    title?: string;
    body?: string;
  }): Promise<CreatedPR> {
    const repoInfo = await this.getRepoInformation(repoId);
    const res = await this.transport.createPullRequest({
      context: repoInfo,
      data: {
        sourceRefName: `refs/heads/${fromBranch}`,
        targetRefName: `refs/heads/${toBranch}`,
        title: title ?? '',
        description: body ?? '',
      } as unknown as GitPullRequest,
    });
    return {
      url: UrlUtils.generatePrUrl(res.repository?.webUrl, GitProviderName.AzureDevops, res.codeReviewId?.toString()),
    };
  }

  async deleteFileIfExists({
    filePath,
    branch,
    commitMessage,
    repoId,
  }: {
    repoId: string;
    branch: string;
    filePath: string;
    commitMessage: string;
  }): Promise<void> {
    const isFileExists = await this.isFileExistsOnRevision({ repoId, filePath, revision: branch });
    if (isFileExists) {
      const repoInfo = await this.getRepoInformation(repoId);
      const branchSha = await this.getBranchSha(repoId, branch);
      if (!branchSha) {
        throw new Error(`Could not find branch ${branch} in repo ${repoInfo.repo.name}`);
      }

      await this.transport.pushChanges({
        context: repoInfo,
        data: {
          refUpdates: [
            {
              name: `refs/heads/${branch}`,
              oldObjectId: branchSha,
            },
          ],
          commits: [
            {
              comment: commitMessage,
              changes: [
                {
                  changeType: 'delete',
                  item: {
                    path: filePath,
                  },
                },
              ],
            },
          ],
        } as unknown as GitPush,
      });
    }
  }

  private async getLastCommitShaFile({
    repoId,
    destCommit,
    relativeFilePath,
    versionOptions = 'none',
    versionType = 'branch',
  }: {
    repoId: string;
    destCommit: string;
    relativeFilePath: string;
    versionOptions?: AzureVersionOptions;
    versionType?: AzureVersionType;
  }): Promise<GitCommitRef | null> {
    try {
      const repoInfo = await this.getRepoInformation(repoId);
      const commits = await this.transport.listCommits({
        context: repoInfo,
        params: {
          searchCriteria: {
            itemPath: relativeFilePath,
            itemVersion: { version: destCommit, versionOptions, versionType },
            $top: 1,
          },
        },
      });
      return commits.value[0] || null;
    } catch (error) {
      const msg = error.response?.data?.message ?? '';
      if (msg.startsWith('TF401174:')) {
        // this error if we are trying to get information by sha1
        return this.getLastCommitShaFile({
          repoId,
          destCommit,
          relativeFilePath,
          versionOptions: 'previousChange',
          versionType,
        });
      } else if (msg.startsWith('TF401175:')) {
        // if the file was deleted in the destCommit, we need to get the last commit sha where the file existed
        return this.getLastCommitShaFile({
          repoId,
          destCommit,
          relativeFilePath,
          versionOptions,
          versionType: 'commit',
        });
      } else if (msg.startsWith('TF401176:')) {
        // fine not found
        return null;
      }
      throw error;
    }
  }

  async getLastCommitShaWhereFileExisted(
    relativeFilePath: string,
    repoId: string,
    destCommit: string
  ): Promise<string> {
    try {
      const commit = await this.getLastCommitShaFile({
        repoId,
        destCommit,
        relativeFilePath,
      });
      return commit?.commitId ?? '';
    } catch (error) {
      if (error.response?.status === StatusCodes.NOT_FOUND) {
        return '';
      }
      throw error;
    }
  }

  async getPathType({
    path,
    repoId,
    revision,
  }: {
    path: string;
    repoId: string;
    revision: string;
  }): Promise<ResultWithReturnCode<{ pathType: PathType }, { errorMessage: string }>> {
    try {
      const fileData = await this.getFileDataFromRevision({ repoId, path, revision });
      return { code: SUCCESS_RETURN_CODE, pathType: fileData.isFolder ? PathType.Folder : PathType.File };
    } catch {
      return { code: ERROR_RETURN_CODE, errorMessage: 'Not found' };
    }
  }

  private projectUrlToOrganizationName(url: string): string {
    const orgNameRegex = new RegExp(`${gitProviderUtils.AZURE_BASE_URLS.CODE}/(.*)/_apis/projects`);
    const regexResult = orgNameRegex.exec(url);
    return regexResult[1]; // The capturing group
  }

  private gitRepositoryToRemoteRepository(gitRepository: GitRepository): RemoteRepository {
    const orgName = this.projectUrlToOrganizationName(gitRepository.project.url);
    const remoteUrl = gitRepository.remoteUrl.replace(/https:\/\/.*@/, 'https://');
    return {
      owner: `${orgName}/${gitRepository.project.name}`,
      name: gitRepository.name,
      isPrivate: (gitRepository.project.visibility as unknown as string) === 'private',
      htmlUrl: gitRepository.webUrl,
      fork: gitRepository.isFork ?? false,
      cloneUrl: remoteUrl,
      defaultBranch: gitRepository.defaultBranch.replace('refs/heads/', ''),
      writeAccess: !gitRepository.isDisabled,
    };
  }

  private async getOrganizations(): Promise<Organization[]> {
    const accounts = await this.transport.getAccounts();
    return accounts.map((account) => ({
      name: account.AccountName,
      url: account.AccountId,
    }));
  }

  /**
   * Fetch repo metadata from provider
   * @param repoId the repoId, if availabe
   * @param repoName name of the repo (must if no repoId)
   * @param owner name of the organization / user who owns the repo (must if no repoId)
   * @param provider the git provider (must if no repoId)
   */
  async getRepoRemoteData({ repoId, repoName, owner }: RepoIdOrRepoData): Promise<RemoteRepository> {
    if (!owner && !repoName && !repoId) {
      logger.error('Either repoId or repoName and owner must be provided');
      throw new Error('Either repoId or repoName and owner must be provided');
    }
    if (repoId) {
      const repoInfo = await this.getRepoInformation(repoId);
      owner = repoInfo.organization.name;
      repoName = repoInfo.repo.name;
    }
    const [orgName, projectName] = gitProviderUtils.splitOwner(owner);
    const gitRepoObject = await this.getGitRepository({ orgName, projectName, repoName });
    if (!gitRepoObject) {
      throw new Error(`Could not find repo ${repoName}`);
    }
    return this.gitRepositoryToRemoteRepository(gitRepoObject);
  }

  getRepoRemoteDataBatch(_params: {
    provider: GitProviderName;
    repos: RepoIdOwnerName[];
    driverOptions?: DriverOptions;
  }): Promise<Record<string, PartialRemoteRepository>> {
    throw new Error('Method getRepoRemoteDataBatch not implemented.');
  }

  async pushMultipleFilesToBranch({
    files,
    repoId,
    branch,
    commitMessage,
  }: {
    files: SwmResourceFile[];
    repoId: string;
    branch: string;
    commitMessage: string;
  }) {
    const branchSha = await this.getBranchSha(repoId, branch); // TODO: handle the case where this is undefined
    const repoInfo = await this.getRepoInformation(repoId);

    // For renamed files, we delete the old one, and create the new one
    const renamedFiles: RenamedSwmResourceFile[] = files.filter(
      (file) => file.state === SwmResourceState.Renamed
    ) as RenamedSwmResourceFile[];
    const filesToGoOver = [
      ...files,
      ...renamedFiles.map((file) => ({
        ...file,
        path: file.oldPath,
        state: SwmResourceState.Deleted,
      })),
    ];

    const data = {
      refUpdates: [
        {
          name: `refs/heads/${branch}`,
          oldObjectId: branchSha,
        },
      ],
      commits: [
        {
          comment: commitMessage,
          changes: filesToGoOver.map((file) => {
            return {
              changeType: stateToChangeType[file.state],
              item: {
                path: file.path,
              },
              ...(file.state !== SwmResourceState.Deleted && {
                newContent: file.isBase64Encoded
                  ? {
                      content: file.content,
                      contentType: 'base64encoded',
                    }
                  : {
                      content: file.content,
                      contentType: 'rawtext',
                    },
              }),
            };
          }),
        },
      ],
    } as unknown as GitPush;
    await this.transport.pushChanges({ context: repoInfo, data });
  }

  // TODO: should be changed for a general `gitDiff` function
  getGitDiffRemote(_data: { repoId: string; base: string; head: string }): Promise<string> {
    throw new Error('Method getGitDiffRemote not implemented.');
  }

  /** * *
   *  NOTE: the original API can read the tree only by the treeSha, it's not the same as the commitSha
   * So we need to get the commitSha first and then get the treeSha from it
   * But we can send to the function the name of the branches.
   * For the case with the branches, we need to get the commitSha from the branch name
   * The process is built by getting errors from the API and then getting the treeSha from the commitSha and commitSha from the branch name, depending on the message of the error.
   * The next call of the function should return the correct result.
   *  */
  async getRepoTree({ repoId, recursive = false, treeSha }: RepoTreePrams): Promise<RepoTree[]> {
    const repoInfo = await this.getRepoInformation(repoId);
    try {
      const branchSha = await this.getBranchSha(repoId, treeSha);
      if (!branchSha) {
        throw new Error(`Could not find branch ${treeSha} in repo ${repoInfo?.repo?.name}`);
      }
      const commit = await this.getCommit({ repoInfo, commitSha: branchSha });
      const params: AzureListTreeParams = { recursive } as AzureListTreeParams;
      const result = await this.transport.listTree({ context: repoInfo, sha: commit.treeId, params });
      return result.treeEntries.map((entry) => ({
        path: entry.relativePath,
        mode: entry.mode,
        type: entry.gitObjectType as unknown as string,
        sha: entry.objectId,
        size: entry.size,
        url: entry.url,
      }));
    } catch (error) {
      logger.error(`Failed to get tree for repo ${repoInfo?.repo?.name} with sha ${treeSha}`);
      throw error;
    }
  }

  async getDiffFiles({
    repoId,
    compareFrom,
    compareTo,
    includeShas,
  }: {
    repoId: string;
    compareFrom: string;
    compareTo: string;
    includeShas?: boolean;
  }): Promise<DiffFileMetadata[]> {
    const repoInfo = await this.getRepoInformation(repoId);
    const files: DiffFileMetadata[] = [];
    const isGetThePreviousCommit = compareFrom.endsWith('~1');
    let skip = 0;
    let isLastPage = false;
    do {
      const diff = isGetThePreviousCommit
        ? await this.transport.getChangesCommit({
            context: repoInfo,
            commitId: compareTo,
            params: { skip },
          })
        : await this.transport.getDiff({
            context: repoInfo,
            params: {
              targetVersion: compareTo,
              baseVersion: compareFrom,
              $skip: skip,
            },
          });
      skip += diff.changes.length;
      isLastPage = isGetThePreviousCommit ? diff.changes.length === 0 : (diff as GitCommitDiffs).allChangesIncluded;
      files.push(
        ...diff.changes.reduce((acc, change) => {
          if ((change.changeType as unknown as string).split(',').length === 1) {
            const status = azureStatus2GitHubStatus[change.changeType as unknown as string];
            acc.push({
              oldFilePath: (change.sourceServerItem ?? change.item.path).replace(/^\/+/, ''),
              newFilePath: (status === githubChangeStatus.Deleted ? '' : change.item.path).replace(/^\/+/, ''),
              sha: includeShas ? change.item.objectId ?? change.item.originalObjectId : undefined,
              status,
            });
          }
          return acc;
        }, [] as DiffFileMetadata[])
      );
    } while (!isLastPage);
    return files;
  }

  async *getUserRemoteRepositories(
    _provider: GitProviderName,
    _driverOptions?: DriverOptions
  ): AsyncIterable<RemoteRepository> {
    const organizations = await this.getOrganizations();
    for await (const { name } of organizations) {
      let skip = 0;
      let isLastPage = false;
      do {
        let projects;
        try {
          projects = await this.transport.listProjects({
            orgName: name,
            params: { $skip: skip },
          });
        } catch (err) {
          logger.warn(
            `Can't access organizations ${name} projects. This may be because the organizations policy is not set to allow "Third part application access via OAuth". Error: ${err}`
          );
          break;
        }

        skip += projects.count;
        isLastPage = projects.count === 0;

        for (const project of projects.value) {
          const repos = await this.transport.listRepositories({ orgName: name, projectName: project.name });
          for (const repo of repos.value) {
            if (repo.defaultBranch) {
              yield { ...this.gitRepositoryToRemoteRepository(repo) };
            } else {
              logger.warn(`Repo ${repo.name} in ${name}/${project.name} has no default branch, skipping it`);
            }
          }
        }
      } while (!isLastPage);
    }
  }

  getRepositoryLanguages(_repoId: string): Promise<{ [language: string]: number } | undefined> {
    // there is no API for getting languages
    return undefined;
  }

  async getAllSwmFilesContent({
    repoId,
    revision,
    path = SWM_FOLDER_IN_REPO,
  }: {
    repoId: string;
    revision: string;
    path?: string;
  }): Promise<{ path: string; content: string }[]> {
    const repoInfo = await this.getRepoInformation(repoId);
    const files = await this.transport.getFileContent({
      context: repoInfo,
      params: {
        format: 'json',
        recursionLevel: 'full',
        scopePath: path,
        'versionDescriptor.version': revision,
      },
    });
    const result = await promiseAllLimit<{ path: string; content: string }>(
      PROMISE_ALL_LIMIT,
      files.value
        .filter(({ gitObjectType }) => (gitObjectType as unknown as string) === 'blob')
        .filter(({ path }) => !isSwmImageInRepo(path))
        .map((file) => async () => {
          const content = await this.getFileContentFromRevision({ repoId, filePath: file.path, revision });
          return { path: file.path, content };
        })
    );
    return result;
  }

  async getUserActiveBranches(repoId: string, maxActiveBranchesToFetch: number): Promise<string[]> {
    const repoInfo = await this.getRepoInformation(repoId);
    const params: AzureListBranchesParams = { includeMyBranches: true } as AzureListBranchesParams;
    const branches = await this.transport.listBranches({ context: repoInfo, params });
    return branches.value.slice(0, maxActiveBranchesToFetch).map((branch) => branch.name.replace('refs/heads/', ''));
  }

  async getUserOrganizations(_provider: GitProviderName, _driverOptions?: DriverOptions): Promise<string[]> {
    return (await this.getOrganizations()).map(({ name }) => name);
  }

  override async deleteBranch(repoId: string, branchName: string): Promise<void> {
    const repoInfo = await this.getRepoInformation(repoId);
    const branch = await this.transport.getBranch({ context: repoInfo, branchName });
    const branchSha = branch?.objectId;
    if (!branchSha) {
      throw new Error(`Could not find branch ${branchName} in repo ${repoInfo.repo.name}`);
    }
    await this.transport.updateCreateOrDeleteBranch({
      context: repoInfo,
      data: [
        {
          name: `refs/heads/${branchName}`,
          newObjectId: EMPTY_SHA,
          oldObjectId: branchSha,
          isLocked: false,
          repositoryId: repoInfo.repo.id,
        },
      ],
    });
  }

  override async closePullRequest(repoId: string, prId: number): Promise<void> {
    const repoInfo = await this.getRepoInformation(repoId);
    await this.transport.updatePullRequest({
      context: repoInfo,
      pullRequestId: prId,
      data: {
        status: 'abandoned',
      } as unknown as GitPullRequest,
    });
  }
}
