<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { IconButton, SwText } from '@swimm/ui';
import TextFieldMultiline from '@/common/components/atoms/TextFieldMultiline.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';
import { useNPSStore } from '@/modules/nps-survey/stores/npsStore';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';

type NPSType = 'promoter' | 'passive' | 'detractor';

const npsStore = useNPSStore();
const analytics = useAnalytics();
const { user } = storeToRefs(useAuthStore());
const { shownNPSFormType } = storeToRefs(npsStore);

watch(shownNPSFormType, (formType) => {
  if (!formType) {
    return;
  }
  trackEvent(productEvents.NPS_SHOW);
  selectedScore.value = null;
  explanation.value = null;
  submittedScore.value = false;
});

const trackEvent = (eventName: string, extraProperties?: Record<string, string | number>) => {
  const payload: Record<string, string | number> = {
    Context: 'NPS',
    'Form Type': shownNPSFormType.value,
    ...extraProperties,
  };
  if (selectedScore.value !== null) {
    payload['NPS Type'] = npsType.value;
    payload.Score = selectedScore.value;
  }
  if (explanation.value) {
    payload.Explanation = explanation.value;
  }
  analytics.cloudTrack({ identity: user.value.uid, event: eventName, payload });
};

const scoreOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const selectedScore = ref<number | null>(null);
const npsType = computed<NPSType>(() => {
  if (selectedScore.value === null) {
    return null;
  }
  if (selectedScore.value <= 6) {
    return 'detractor';
  }
  if (selectedScore.value <= 8) {
    return 'passive';
  }
  return 'promoter';
});
const submittedScore = ref(false);

const onScoreOptionClick = (scoreOption: number) => {
  if (selectedScore.value === scoreOption) {
    trackEvent(productEvents.NPS_DESELECT_SCORE_OPTION);
    selectedScore.value = null;
  } else {
    const previousOption = selectedScore.value;
    selectedScore.value = scoreOption;
    trackEvent(
      productEvents.NPS_SELECT_SCORE_OPTION,
      previousOption !== null ? { 'Previous Score': previousOption } : {}
    );
  }
};

const submitScore = () => {
  trackEvent(productEvents.NPS_SUBMIT_SCORE);
  submittedScore.value = true;
};

const explanation = ref<string | null>(null);
const updateExplanation = (newExplanation: string) => {
  explanation.value = newExplanation;
};
const blurExplanation = () => {
  trackEvent(productEvents.NPS_ENTER_EXPLANATION);
};

const shouldShowScoreSelection = computed(() => {
  switch (shownNPSFormType.value) {
    case 'periodical':
      return !submittedScore.value;
    default:
    case 'user-requested':
      return true;
  }
});

const shouldShowExplanationField = computed(() => {
  switch (shownNPSFormType.value) {
    case 'periodical':
      return submittedScore.value;
    default:
    case 'user-requested':
      return true;
  }
});

const submitExplanation = () => {
  trackEvent(productEvents.NPS_SUBMIT_EXPLANATION);
  npsStore.onNPSResolved('answer');
};

const askMeLater = () => {
  trackEvent(productEvents.NPS_ASK_LATER);
  npsStore.onNPSResolved('ask-later');
};

const onClickClose = () => {
  trackEvent(productEvents.NPS_DISMISS);
  npsStore.onNPSResolved('dismiss');
};

const shouldDisableSubmit = computed(() => {
  return (
    (isScoreRequired.value && selectedScore.value === null) ||
    (isExplanationRequired.value && explanation.value === null)
  );
});

const isScoreRequired = computed(() => {
  switch (shownNPSFormType.value) {
    case 'periodical':
      return true;
    default:
    case 'user-requested':
      return false;
  }
});

const isExplanationRequired = computed(() => {
  switch (shownNPSFormType.value) {
    case 'periodical':
      return false;
    default:
    case 'user-requested':
      return true;
  }
});

const onClickSubmit = () => {
  switch (shownNPSFormType.value) {
    case 'periodical':
      if (!submittedScore.value) {
        submitScore();
      } else {
        submitExplanation();
      }
      break;
    case 'user-requested':
      submitExplanation();
      break;
  }
};

// eslint-disable-next-line vue/return-in-computed-property
const explanationPrompt = computed(() => {
  // If score selection is shown, use a constant message otherwise it looks weird that the message changes when you
  // select a score.
  if (shouldShowScoreSelection.value) {
    return 'Please share any feedback you have about Swimm';
  }
  switch (npsType.value) {
    case 'detractor':
      return 'We’re sorry to hear. What is one thing we could do differently to make you happier?';
    case 'passive':
      return 'Thank you! What should we do to WOW you?';
    case 'promoter':
      return 'Amazing! Would you like to tell us why you feel that way?';
  }
});

const explanationPlaceholder = computed(() => {
  if (isScoreRequired.value) {
    return 'Help us by explaining your score';
  }
  return 'We look into every piece of feedback';
});
</script>
<template>
  <Teleport to="body">
    <transition name="nps-slide-in">
      <div class="nps-container" v-if="shownNPSFormType">
        <div class="header">
          <SwText variant="subtitle-L">We'd love your feedback!</SwText>
          <IconButton name="close" @click="onClickClose" />
        </div>
        <div class="body">
          <template v-if="shouldShowScoreSelection">
            <SwText variant="subtitle-XL">
              How likely are you to recommend Swimm to a friend or colleague?
              <span class="required" v-if="isScoreRequired">*</span>
            </SwText>
            <div class="nps-options">
              <div
                v-for="option in scoreOptions"
                :key="option"
                class="nps-option"
                :class="{ selected: selectedScore === option }"
                @click="onScoreOptionClick(option)"
              >
                {{ option }}
              </div>
            </div>
            <div class="nps-labels">
              <SwText variant="body-XS">Not at all likely</SwText>
              <SwText variant="body-XS">Very likely</SwText>
            </div>
          </template>
          <template v-if="shouldShowExplanationField">
            <SwText variant="subtitle-XL">
              {{ explanationPrompt }} <span class="required" v-if="isExplanationRequired">*</span>
            </SwText>
            <TextFieldMultiline
              :placeholder="explanationPlaceholder"
              :model-value="explanation"
              @update:model-value="updateExplanation"
              small
              @blur="blurExplanation"
            />
          </template>
          <div class="form-buttons">
            <Action
              secondary
              class="ask-later-button"
              v-if="shownNPSFormType === 'periodical' && !submittedScore"
              @click="askMeLater"
              >Ask me later</Action
            >
            <Action :disabled="shouldDisableSubmit" @click="onClickSubmit"> Submit </Action>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
<style lang="postcss" scoped>
.nps-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  position: absolute;
  box-shadow: var(--box-shadow-big);
  border-radius: var(--border-radius);
  width: 640px;
  z-index: 999999999;
  bottom: var(--space-xl);
  right: 50%;
  transform: translateX(50%);
  background-color: var(--color-disable);
  border: 1px solid var(--color-border-default-subtle);
}

.nps-slide-in-enter-active {
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.5, 1.25, 0.61, 0.99);
  transition-duration: 0.7s;
  transition-delay: 0.5s;
}

.nps-slide-in-enter-from {
  opacity: 0;
  transform: translateY(100%) translateX(50%);
}

.nps-slide-in-enter-to {
  opacity: 1;
  transform: translateY(0) translateX(50%);
}

.nps-slide-in-leave-active {
  transition: opacity ease-out 0.3s;
}

.nps-slide-in-leave-from {
  opacity: 1;
}

.nps-slide-in-leave-to {
  opacity: 0;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--space-base);
  padding: var(--space-sm) var(--space-md);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-base) var(--space-md);
  padding-right: var(--space-base);
  border-bottom: 1px solid var(--color-border-default-subtle);
}

.nps-options {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 14px;
  margin-top: var(--space-sm);

  .nps-option {
    background-color: var(--color-status-info);
    color: var(--text-color-primary);
    cursor: pointer;
    padding: var(--space-base);
    border-radius: 24px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: var(--color-brand-disable);
    }

    &.selected {
      background-color: var(--color-brand);
      color: var(--text-color-on-dark);
      cursor: normal;
    }
  }
}

.nps-labels {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.required {
  color: var(--oh-no-red-500);
}

.form-buttons {
  margin-top: var(--space-sm);
  gap: var(--space-base);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

button.ask-later-button.secondary {
  color: var(--text-color-secondary);
  border: none;
}
</style>
