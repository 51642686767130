import { getLoggerNew, productEvents, shortUuid, slugify } from '@swimm/shared';
import { CLOUD_FUNCTIONS_HOST, getFriendlyVersion, isAutomation, isProduction, isStage, isStaging } from '@/config';
import * as Sentry from '@sentry/vue';
import { reportHubspotCreateWorkspace, reportHubspotSignIn, reportHubspotSignUp } from '@/common/utils/hubspot';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useRoute } from 'vue-router';
import { type AnalyticsTrackOptions, type AnalyticsTrackProperties } from '@swimm/editor';
import { AnalyticsBrowser } from '@segment/analytics-next';

const TrackMethods = {
  TRACK: 'track',
  PAGE: 'page',
  GROUP: 'group',
  IDENTIFY: 'identify',
  RESET: 'reset',
};

const CloudEndpoints = {
  GROUP_EVENT: 'groupEvent',
  T_EVENT: 'tEvent',
  IDENTIFY: 'identifyEvent',
  PAGE: 'pageViewedEvent',
};

const RouteKeyToSegmentKey = {
  workspaceId: 'Workspace ID',
  workspaceName: 'Workspace Name',
  repoId: 'Repo ID',
  repoName: 'Repo Name',
  unitId: 'Document ID',
  playlistId: 'Playlist ID',
  playlistName: 'Playlist Name',
};

const logger = getLoggerNew(__modulename);

const isReportingEnvironment = (isStaging || isProduction) && !isAutomation && !isStage;
logger.debug(`Product events reporting is ${isReportingEnvironment ? 'enabled' : 'disabled'}`);

let analytics: AnalyticsBrowser | null = null;
export function initAnalytics() {
  if (isReportingEnvironment) {
    if (isStaging) {
      analytics = AnalyticsBrowser.load(
        { writeKey: 'kfP5fqJOrQdptnSAB2NCaH1uiN8d8zqW', cdnURL: 'https://staging.swimm.cloud/segment-cdn' },
        {
          integrations: {
            'Segment.io': {
              apiHost: 'staging.swimm.cloud/segment-api/v1',
            },
          },
          updateCDNSettings(settings) {
            settings.metrics.host = 'staging.swimm.cloud/segment-api/v1';
            return settings;
          },
        }
      );
    } else if (isProduction) {
      analytics = AnalyticsBrowser.load(
        { writeKey: 'LBQV6wfcN95n0DaGshjfAEwW6pfDFIUJ', cdnURL: 'https://app.swimm.io/segment-cdn' },
        {
          integrations: {
            'Segment.io': {
              apiHost: 'app.swimm.io/segment-api/v1',
            },
          },
          updateCDNSettings(settings) {
            settings.metrics.host = 'app.swimm.io/segment-api/v1';
            return settings;
          },
        }
      );
    }
  }
}

export function useAnalytics() {
  const route = useRoute();
  const { user } = storeToRefs(useAuthStore());

  function initAnalytics(options) {
    initSegment(options);
  }

  function initSegment({ uid, nickname, email, creationTime, lastSignInTime, browserSystemTheme, sourceProps }) {
    logger.debug(`Initializing Segment, uid: ${uid}`);
    userIdentify(uid, {
      email,
      'Full Name': nickname,
      'Browser System Theme': browserSystemTheme,
      'Signup Date': dateToISO(creationTime),
      'Last Login Date': dateToISO(lastSignInTime),
      ...sourceProps,
    });
  }

  function track(
    eventName: string,
    payload: AnalyticsTrackProperties = {},
    options: AnalyticsTrackOptions = { addRouteParams: true, shouldSendCloud: false }
  ) {
    if (options.shouldSendCloud) {
      delete options.shouldSendCloud;
      cloudTrack({ identity: user.value.uid, payload, event: eventName, options })
        .then()
        .catch((error) => {
          logger.error(`Could not send cloudTrack event to Segment ${eventName}. Details: ${error}`);
        });
    } else {
      const properties = generatePropertiesForEvent({
        shouldAddRouteParams: options.addRouteParams,
        route,
        eventName,
        email: user.value.email,
        payload,
      });
      reportToSegment('track', TrackMethods.TRACK, eventName, properties, options);
    }
  }

  function pageVisit(eventName, payload = {}, options = { addRouteParams: true }) {
    const properties = generatePropertiesForEvent({
      shouldAddRouteParams: options.addRouteParams,
      route,
      eventName,
      email: user.value.email,
      payload,
    });
    reportToSegment('pageVisit', TrackMethods.PAGE, eventName, properties, options);
  }

  function userGroup(identity, payload) {
    const { groupType, groupValue } = payload;
    const traits = { ...payload, groupType: groupType || 'Workspace', groupValue: groupValue || identity };
    reportToSegment('userGroup', TrackMethods.GROUP, identity, traits);
  }

  async function userIdentify(uid, properties, anonymousId = null) {
    const options = anonymousId ? { anonymousId } : {};
    reportToSegment('userIdentify', TrackMethods.IDENTIFY, uid, properties, options);
    await cloudIdentify({ identity: uid, properties, anonymousId });
  }

  function logout() {
    resetIdentity();
  }

  function resetIdentity() {
    reportToSegment('resetIdentity', TrackMethods.RESET);
  }

  function workspaceChange({ workspaceId, workspaceName }) {
    userGroup(workspaceId, { groupType: 'Workspace', groupValue: workspaceId, Name: workspaceName });
  }

  function leaveWorkspace({ newWorkspaceId, workspaceIdToRemove, uid }) {
    userGroup(newWorkspaceId, { groupType: 'Workspace', groupValue: newWorkspaceId });
    cloudTrack({
      identity: uid,
      event: productEvents.LEFT_WORKSPACE,
      payload: { 'Workspace ID': `${workspaceIdToRemove}`, 'User ID': `${uid}` },
    });
  }

  function noWorkspaces(uid) {
    userIdentify(uid, { groupValue: `null_${shortUuid()}` });
    resetIdentity();
  }

  function workspaceRenamed({ workspaceId, previousWorkspaceName, workspaceName }) {
    userGroup(workspaceId, {
      Name: workspaceName,
      'Previous Name': previousWorkspaceName,
      groupType: 'Workspace',
      groupValue: workspaceId,
    });
    track(productEvents.WORKSPACE_NAME_MODIFIED, {
      'Previous Workspace Name': previousWorkspaceName,
      'Workspace Name': workspaceName,
    });
  }

  // Cloud
  async function cloudWorkspaceGroup({ workspaceId, traits = {}, userId }) {
    // @ts-ignore
    const { groupType, groupValue } = traits;
    traits = { ...traits, groupType: groupType || 'Workspace', groupValue: groupValue || workspaceId };
    await reportToCloud('cloudWorkspaceGroup', CloudEndpoints.GROUP_EVENT, {
      identity: workspaceId,
      traits,
      userId,
    });
  }

  async function cloudTrack({ identity, event, payload = {}, options = { addRouteParams: true } }) {
    // @ts-ignore
    payload.app_version = getFriendlyVersion();
    const properties = generatePropertiesForEvent({
      shouldAddRouteParams: options.addRouteParams,
      route,
      eventName: event,
      email: user.value.email,
      payload,
    });
    reportToCloud('cloudTrack', CloudEndpoints.T_EVENT, {
      identity,
      timestamp: dateToISO(),
      event,
      properties,
    });
  }

  async function cloudPageViewed({
    identity,
    anonymousId = null,
    event,
    payload = {},
    options = { addRouteParams: true },
    backofficeCode = undefined,
  }) {
    // @ts-ignore
    payload.app_version = getFriendlyVersion();
    const properties = generatePropertiesForEvent({
      shouldAddRouteParams: options.addRouteParams,
      route,
      eventName: event,
      email: user.value.email,
      payload,
      backofficeCode,
    });
    await reportToCloud('cloudPageViewed', CloudEndpoints.PAGE, {
      identity,
      timestamp: dateToISO(),
      name: event,
      properties,
      ...(anonymousId ? { anonymousId } : {}),
    });
  }

  async function cloudIdentify({ identity, properties, anonymousId }) {
    await reportToCloud('cloudIdentify', CloudEndpoints.IDENTIFY, {
      identity,
      properties,
      anonymousId,
    });
  }

  return {
    initAnalytics,
    track,
    pageVisit,
    userGroup,
    userIdentify,
    logout,
    workspaceChange,
    leaveWorkspace,
    noWorkspaces,
    workspaceRenamed,
    cloudWorkspaceGroup,
    cloudTrack,
    cloudPageViewed,
    cloudIdentify,
    reportHubspotSignIn,
    reportHubspotSignUp,
    reportHubspotCreateWorkspace,
  };
}

function generatePropertiesForEvent({
  shouldAddRouteParams,
  route,
  eventName,
  email,
  payload,
  backofficeCode = undefined,
}) {
  const routeDataProperties = shouldAddRouteParams ? generateRouteDataProperties(route) : {};
  const hubspotProperties = generateHubSpotProperties(eventName, email);
  const properties = { ...routeDataProperties, ...hubspotProperties, URL: route.fullPath, ...payload };
  if (backofficeCode) {
    properties['Backoffice Code'] = backofficeCode;
  }
  return properties;
}

function generateRouteDataProperties(route) {
  return {
    ...(route.params.workspaceId && { [RouteKeyToSegmentKey['workspaceId']]: route.params.workspaceId }),
    ...(route.params.repoId && { [RouteKeyToSegmentKey['repoId']]: route.params.repoId }),
    ...(route.params.unitId && { [RouteKeyToSegmentKey['unitId']]: route.params.unitId }),
    ...(route.params.playlistId && { [RouteKeyToSegmentKey['playlistId']]: route.params.playlistId }),
  };
}

function generateHubSpotProperties(eventName, email) {
  return { Slug: slugify(eventName, '_', { replace: /-+/g }), Email: email };
}

function dateToISO(date?: Date) {
  if (date) {
    return new Date(date).toISOString();
  }
  return new Date().toISOString();
}

function reportToSegment(reportMethodName, trackMethod, eventName = '', payload = {}, options = {}) {
  // @ts-ignore
  payload.app_version = getFriendlyVersion();
  if (trackMethod === TrackMethods.PAGE || trackMethod === TrackMethods.TRACK) {
    // don't set platform if already set,
    // for example, for registration from ide
    // @ts-ignore
    payload.Platform ||= 'WebApp';
  }
  if (!analytics) {
    logger.info(
      `[NO SEGMENT] ${reportMethodName}: ${eventName} | with payload: ${JSON.stringify(
        payload
      )} | with options: ${JSON.stringify(options)}`
    );
    return;
  }
  try {
    const track = analytics[trackMethod];
    if (payload) {
      track(eventName, payload, options);
    } else {
      track(eventName);
    }
  } catch (error) {
    logger.error(`Could not send ${trackMethod} event to Segment ${eventName}. Details: ${error}`, {
      service: 'product-reporter',
    });
  }
}

async function reportToCloud(reportMethodName, cloudEndpoint, body = {}) {
  if (cloudEndpoint === CloudEndpoints.PAGE || cloudEndpoint === CloudEndpoints.T_EVENT) {
    // @ts-ignore
    if (body.properties) {
      // @ts-ignore
      body.properties.Platform ||= 'WebApp';
    }
  }
  if (!isReportingEnvironment) {
    logger.info(`[NO CLOUD] ${reportMethodName}: Endpoint /${cloudEndpoint} | with payload: ${JSON.stringify(body)}`);
    return;
  }

  try {
    await fetch(`${CLOUD_FUNCTIONS_HOST}/${cloudEndpoint}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...body }),
    });

    if (JSON.stringify({ ...body }) === '{}') {
      logger.warn('Empty body!');
      Sentry.captureException(new Error(`Empty body for ${reportMethodName} event to Cloud ${cloudEndpoint}`));
    }
  } catch (error) {
    logger.error(
      { method: reportMethodName, cloudFunction: cloudEndpoint, error, body },
      `could not send ${reportMethodName} event to Cloud ${cloudEndpoint}. Details: ${error}`,
      { service: 'product-reporter' }
    );
    Sentry.captureException(error);
  }
}
