<script setup lang="ts">
import { computed, ref, watch, watchEffect } from 'vue';
import { Icon, SwText, SwToggle } from '@swimm/ui';
import { BaseHeading } from '@swimm/reefui';
import FeatureAccessConfirmModal from './FeatureAccessConfirmModal.vue';

const props = defineProps<{
  isEnabled: boolean;
  loading: boolean;
  featureTitle: string;
  featureDescription: string;
  featureIcon: string;
  featureLink: string;
  enabledCount: number;
  repoCount: number;
  beforeEnable: () => Promise<boolean>; // callback (via prop) to be called before enabling
}>();

const emit = defineEmits<{ (e: 'update-enabled', newValue: boolean): void; (e: 'show-paywall'): true }>();

const toggleValue = ref(props.isEnabled);

watchEffect(() => {
  toggleValue.value = props.isEnabled;
});

watch(
  () => props.loading,
  (newValue) => {
    if (!newValue) {
      // after loading, update the toggleValue to force re-render if there is mismatch
      toggleValue.value = props.isEnabled;
    }
  }
);

const showConfirm = ref(false);
const confirmValue = ref(false);

async function toggleChanged(newValue: boolean) {
  toggleValue.value = newValue;
  if (newValue) {
    const isOk = await props.beforeEnable();
    if (!isOk) {
      toggleValue.value = props.isEnabled; // undo
      return;
    }
  }
  confirmValue.value = newValue;
  showConfirm.value = true;
}

function confirmClosed(accpeted: boolean) {
  showConfirm.value = false;
  if (!accpeted) {
    toggleValue.value = props.isEnabled; // undo
  } else {
    emit('update-enabled', toggleValue.value);
  }
}

const tooltipText = computed(() => {
  if (props.isEnabled) {
    return 'Disable for all repos. To disable a specific repo, overwrite it in the repo settings.';
  }
  return 'Enable for all repos';
});
</script>
<template>
  <FeatureAccessConfirmModal
    :show-modal="showConfirm"
    :confirm-value="confirmValue"
    :feature-name="featureTitle"
    @close="confirmClosed"
  />
  <div class="feature-row">
    <div class="col-1">
      <div class="title">
        <Icon :name="featureIcon" class="feature-icon" />
        <BaseHeading :level="5">{{ featureTitle }}</BaseHeading>
      </div>
      <div class="description">
        <SwText variant="body-XS" class="description-text">{{ featureDescription }}</SwText>
      </div>
      <div class="learn-more">
        <a :href="featureLink" target="_blank" class="link">
          <SwText variant="body-XS" class="learn-more-text">Learn more</SwText>
        </a>
      </div>
    </div>
    <div class="col-2">
      <SwText variant="body-XS" class="enabled-count"
        ><template v-if="toggleValue">Enabled in {{ enabledCount }}/{{ repoCount }} repos & all new repos</template
        ><template v-else>Enabled in {{ enabledCount }}/{{ repoCount }} repos, disabled in new repos</template></SwText
      >
      <SwToggle
        class="toggle-button"
        :disabled="loading"
        :value="toggleValue"
        size="small"
        @change="toggleChanged"
        v-tooltip="tooltipText"
      />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.feature-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px var(--color-border-default-subtle) solid;
  height: 88px;
  box-sizing: border-box;
  padding: var(--space-sm);
  margin-bottom: -1px; /* So that the border overlaps with the next one's border */

  &:nth-child(2) {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  .col-1 {
    .title {
      display: flex;
      align-items: center;

      .feature-icon {
        padding-left: 0;
      }
    }

    .link {
      text-decoration: underline;
      cursor: pointer;
    }

    .learn-more-text {
      color: var(--text-color-secondary);
      text-decoration: none;
    }

    .description-text {
      color: var(--text-color-default);
    }
  }

  .col-2 {
    display: flex;
    gap: var(--space-base);

    .enabled-count {
      color: var(--text-color-secondary);
    }
  }
}
</style>
