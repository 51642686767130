<script setup>
import { useScroll } from '@/composables/scroll';
import { Icon } from '@swimm/ui';
import { computed, nextTick, ref } from 'vue';

const DEFAULT_NODE_ICON = 'file';
const NODE_TYPE_TO_ICON = {
  file: DEFAULT_NODE_ICON,
  directory: 'folder',
};

const props = defineProps({
  results: { type: Array, required: true },
  limit: { type: Number, required: true },
  showFileLevelAction: { type: Boolean, default: false },
});
const emit = defineEmits(['clear-search', 'result-select']);

const selectedResultIndex = ref(-1);
const scroll = useScroll();

const hasResults = computed(() => props.results.length > 0);
const tooManyResults = computed(() => props.results.length > props.limit);

function getNodeShortPath(node) {
  return node.path.substring(0, node.path.lastIndexOf(node.name));
}

function selectResult(node, clear = true) {
  if (clear) {
    clearSearch();
    nextTick(() => scroll.scrollToElement(node.path));
  }
  emit('result-select', node);
}

function clearSearch(clearIfResultSelected = false) {
  if (!clearIfResultSelected || selectedResultIndex.value >= 0) {
    emit('clear-search');
  }
}

defineExpose({
  moveUp: function () {
    if (hasResults.value && selectedResultIndex.value > 0) {
      selectedResultIndex.value--;
      selectResult(props.results[selectedResultIndex.value], false);
    }
  },
  moveDown: function () {
    if (hasResults.value && selectedResultIndex.value < props.results.length - 1 > 0) {
      selectedResultIndex.value++;
      selectResult(props.results[selectedResultIndex.value], false);
    }
  },
  selectWithEnter: function () {
    // If we select folder on arrow clicks then we just need to clear the search and scroll
    // otherwise actually select the search option.
    if (selectedResultIndex.value >= 0) {
      selectResult(props.results[selectedResultIndex.value], false);
    } else {
      clearSearch(true);
    }
  },
});
</script>

<template>
  <div class="search-results" data-testid="tree-search-results">
    <div
      v-for="(node, index) in results"
      :key="node.path"
      :class="['search-result', 'clickable', { selected: index === selectedResultIndex }]"
      data-testid="tree-search-result"
      @click="selectResult(node)"
    >
      <div>
        <Icon class="search-file-icon" :name="NODE_TYPE_TO_ICON[node.type] || DEFAULT_NODE_ICON" />
        <span class="search-file-name" data-testid="search-file-name">{{ node.name }}</span>
        <span class="search-file-path">{{ getNodeShortPath(node) }}</span>
      </div>
      <Icon
        v-if="showFileLevelAction && node.type === 'file'"
        class="file-node-action-icon show-on-parent-hover"
        name="add"
      />
    </div>
    <div v-if="tooManyResults" class="search-result">
      <span class="too-many-results">Too many search results...</span>
    </div>
    <div v-if="!hasResults" class="empty-search-result">No Matching Files</div>
  </div>
</template>

<style scoped>
.search-results {
  height: 100%;
}

.search-result {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--body-L);
  text-overflow: ellipsis;
  white-space: nowrap;
  gap: var(--space-xs);
  padding: 2px var(--space-sm);
}

.search-result.selected,
.search-result:hover {
  background: var(--color-surface);
}

.empty-search-result {
  padding-left: 10px;
  color: var(--text-color-secondary);
}

.search-file-path {
  font-size: var(--body-XS);
  color: var(--text-color-secondary);
  padding-left: var(--space-base);
}

.search-file-name {
  margin-top: auto;
  margin-bottom: auto;
}

.file-node-action-icon {
  right: 10px;
}

.show-on-parent-hover {
  visibility: hidden;
}

.search-result:hover .show-on-parent-hover {
  visibility: visible;
}

.too-many-results {
  padding-bottom: 5px;
  padding-left: 10px;
  color: var(--text-color-secondary);
}

.search-file-icon {
  padding: 2px 0 0 0;
}
</style>
