<script setup lang="ts">
import { ref, watch } from 'vue';
import { productEvents } from '@swimm/shared';
import { TextField } from '@swimm/ui';
import { BaseButton, BaseIcon, BaseLayoutGap, BaseTooltip, type IconsType } from '@swimm/reefui';
import { useNotificationsStore } from '@/modules/notifications/notifications';

const { addNotification } = useNotificationsStore();

const props = withDefaults(
  defineProps<{
    showClose?: boolean;
    prompt?: string;
    propertiesForAnalytics: Record<string, unknown>;
    showTextField?: boolean;
    showThankYouText?: boolean;
    showRevertButton?: boolean;
    revertIcon?: IconsType;
    revertText?: string;
  }>(),
  {
    showClose: false,
    showTextField: true,
    showThankYouText: true,
    revertText: 'Revert and edit prompt',
    revertIcon: 'refresh',
  }
);

const emit = defineEmits<{
  (event: 'close-feedback'): void;
  (event: 'analytic', params: { event: string; properties: Record<string, unknown> }): void;
  (event: 'rate-down'): void;
  (event: 'revert'): void;
}>();

const vote = ref<'up' | 'down' | null>(null);

const onVoteClicked = (selectedVote: 'up' | 'down') => {
  if (vote.value === null) {
    vote.value = selectedVote;
    if (selectedVote === 'down') {
      emit('rate-down');
    }
    reportAnalytic(productEvents.RATED_AI_RESULT);
    return;
  }
  vote.value = null;
  reportAnalytic(productEvents.UNRATED_AI_RESULT, { 'Old Vote': selectedVote });
};

const isFeedbackOpen = ref(false);
const feedback = ref('');

// Open feedback after user votes.
watch(vote, (newVote, prevVote) => {
  if (prevVote === null) {
    isFeedbackOpen.value = true;
  } else {
    isFeedbackOpen.value = false;
  }
});

const closeFeedback = () => {
  reportAnalytic(productEvents.DISMISSED_AI_RESULT_FEEDBACK_TEXT);
  isFeedbackOpen.value = false;
  feedback.value = '';
  emit('close-feedback');
};

const submitFeedback = () => {
  if (!feedback.value) {
    return;
  }
  addNotification('🙏 Thank you so much for your feedback!');
  reportAnalytic(productEvents.SUBMITTED_AI_RESULT_FEEDBACK_TEXT);
  isFeedbackOpen.value = false;
  feedback.value = '';
  emit('close-feedback');
};

const onCloseClick = () => {
  reportAnalytic(productEvents.DISMISSED_AI_FEEDBACK);
  emit('close-feedback');
};

const onFeedbackKeydown = (event: KeyboardEvent) => {
  if (event.keyCode === 0xd || event.code === 'Enter') {
    submitFeedback();
    return;
  }
  if (event.keyCode === 27 || event.code === 'Escape') {
    closeFeedback();
    return;
  }
};

const reportedHover = ref(false);
const onMouseEnter = () => {
  if (reportedHover.value) {
    return;
  }
  reportedHover.value = true;
  reportAnalytic(productEvents.HOVERED_AI_FEEDBACK);
};

const reportAnalytic = (eventName: string, properties: Record<string, unknown> = {}) => {
  if (vote.value) {
    properties['Vote'] = vote.value;
  }
  if (feedback.value) {
    properties['Feedback'] = feedback.value;
  }
  emit('analytic', { event: eventName, properties: { ...props.propertiesForAnalytics, ...properties } });
};
</script>
<template>
  <div class="container" @mouseenter="onMouseEnter">
    <BaseLayoutGap wrap class="layout">
      <span v-if="!vote && prompt">{{ prompt }}</span>
      <span v-if="vote && showThankYouText">Thanks for your feedback!</span>
      <BaseTooltip :content="vote ? 'Click to cancel upvote' : 'Helpful result'">
        <BaseButton v-if="vote !== 'down'" class="upvote-button" variant="tertiary" @click="onVoteClicked('up')">
          <template #leftIcon>
            <BaseIcon :name="vote === 'up' ? 'liked' : 'like'" />
          </template>
        </BaseButton>
      </BaseTooltip>
      <BaseTooltip :content="vote ? 'Click to cancel downvote' : 'Unhelpful result'">
        <BaseButton v-if="vote !== 'up'" class="downvote-button" variant="tertiary" @click="onVoteClicked('down')">
          <template #leftIcon>
            <BaseIcon :name="vote === 'down' ? 'liked' : 'like'" />
          </template>
        </BaseButton>
      </BaseTooltip>
      <TextField
        class="feedback-field"
        v-if="isFeedbackOpen && showTextField"
        v-model="feedback"
        focus-first
        :placeholder="vote === 'up' ? 'How was this helpful?' : 'Why was this unhelpful?'"
        @keydown="onFeedbackKeydown"
      >
        <BaseTooltip content="Submit feedback">
          <BaseButton v-if="feedback" variant="tertiary" @click="submitFeedback">
            <BaseIcon name="send" />
          </BaseButton>
        </BaseTooltip>
        <BaseButton variant="tertiary" @click="closeFeedback">
          <BaseIcon name="close" />
        </BaseButton>
      </TextField>
      <template v-else>
        <div v-if="showTextField" class="buttons-container">
          <BaseButton v-if="showRevertButton" variant="secondary" @click="emit('revert')">
            <template #leftIcon>
              <BaseIcon :name="revertIcon" />
            </template>
            {{ revertText }}
          </BaseButton>
          <BaseButton variant="tertiary" @click="onCloseClick" v-tooltip="'Dismiss'">
            <template #leftIcon>
              <BaseIcon name="close" />
            </template>
          </BaseButton>
        </div>
      </template>
    </BaseLayoutGap>
  </div>
</template>
<style scoped lang="postcss">
.container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--space-base);
  color: var(--text-color-secondary);
  min-height: 32px;
}

.hidden {
  visibility: hidden;
}

.downvote-button {
  transform: scaleY(-1) translateY(-3px);
}

.upvote-button {
  transform: translateY(3px);
}

.feedback-field {
  flex-grow: 1;
  min-width: 14em;
}

:deep(.feedback-field input) {
  margin-right: 0;
}

.buttons-container {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.layout {
  flex-grow: 1;
}
</style>
