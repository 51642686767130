<template>
  <div class="help-container">
    <DemoAvatar />
    <div
      :class="['help-circle', 'clickable', { 'pulse-animation': showHelpCenterAnimation }]"
      data-testid="knowledge-base-help"
      @click.exact="toggleShowOptions"
    >
      <div v-tooltip="{ content: 'Support & resources', placement: 'left' }">
        <Icon class="help-icon headline3" name="help-no-border" />
      </div>
    </div>
    <div>
      <Ellipsis
        v-click-outside="closeAll"
        v-if="showHelpCenter"
        class="ellipsis"
        open-direction="top"
        :parent-show-options="showHelpCenter"
        :parent-show-options-controller="toggleShowOptions"
        disable-mouse-leave
        parent-control
        @close-ellipsis="toggleShowOptions"
      >
        <HelpCenterMenu
          title="Getting started"
          :resources="resourceInfo"
          :videos="videoInfo"
          @item-clicked="handleResourceClick"
          @video-clicked="handleTutorialCLick"
          @close="() => toggleHelpCenter(false)"
        />
      </Ellipsis>
      <VideoTutorialModal :url="currentUrl" :show="showVideoModal" @close="showVideoModal = false" />
      <ReleaseNotesModal :show="showReleaseNotesModal" @close="showReleaseNotesModal = false" />
    </div>
  </div>
</template>

<script>
import { config, productEvents } from '@swimm/shared';
import Ellipsis from '@/common/components/atoms/Ellipsis.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { DOCS_SITE_URL } from '@/config';
import ReleaseNotesModal from '@/common/components/Workspace/Modals/ReleaseNotesModal.vue';
import VideoTutorialModal from '@/modules/help-center/modals/VideoTutorialModal.vue';
import { storeToRefs } from 'pinia';
import HelpCenterMenu from '@/modules/help-center/components/HelpCenterMenu.vue';
import DemoAvatar from '@/modules/help-center/components/DemoAvatar.vue';
import { useHelpCenterStore } from '@/modules/help-center/stores/helpCenter';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { computed, ref } from 'vue';
import LocalStorage from '@/local-storage';
import { localStorageKeys } from '@/common/consts';
import { useNPSStore } from '@/modules/nps-survey/stores/npsStore';

export default {
  components: {
    Ellipsis,
    ReleaseNotesModal,
    VideoTutorialModal,
    HelpCenterMenu,
    DemoAvatar,
  },
  emits: ['menu-toggled'],
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const helpCenterStore = useHelpCenterStore();
    const { showHelpCenter } = storeToRefs(helpCenterStore);
    const { toggleHelpCenter } = helpCenterStore;
    const { showExplicitFeedback } = useNPSStore();

    const { logEvent } = useSwimmEventLogs();

    const firstClickedInAppGuidance = LocalStorage.get(localStorageKeys.USER_CLICKED_IN_APP_GUIDANCE);
    const clickedInAppGuidance = ref(false);

    const showHelpCenterAnimation = computed(() => !firstClickedInAppGuidance && !clickedInAppGuidance.value);

    return {
      user,
      analytics,
      logEvent,
      showHelpCenter,
      toggleHelpCenter,
      showHelpCenterAnimation,
      clickedInAppGuidance,
      showExplicitFeedback,
    };
  },
  data() {
    return {
      showReleaseNotesModal: false,
      showVideoModal: false,
      currentUrl: '',
      videoInfo: [
        {
          key: 'DOC_CREATION',
          name: 'Creating your first Swimm doc',
          thumbnail: 'create-first-doc.png',
          url: 'https://youtu.be/JtJ4sgc-EVg',
        },
        {
          key: 'SNIPPET_STUDIO',
          name: 'Snippet Studio & Smart text',
          thumbnail: 'snippet-studio.png',
          url: 'https://youtu.be/f3v58OhPcVg',
        },
        {
          key: 'SETUP_GITHUB_APP',
          name: `Setting up Swimm’s GitHub app`,
          thumbnail: 'setup-github.png',
          url: 'https://youtu.be/q6pChkL3UQ4',
        },
        {
          key: 'VSCODE',
          name: 'Finding docs in VS Code IDE',
          thumbnail: 'find-docs-vscode.png',
          url: 'https://youtu.be/zN8ZUhGP8dc',
        },
        {
          key: 'JETBARINS',
          name: `Finding docs in JetBrains’ IDE`,
          thumbnail: 'find-docs-jetbrain.png',
          url: 'https://youtu.be/TUY4trvsS2A ',
        },
      ],
      resourceInfo: [
        {
          key: 'PRIVACY_AND_SECURITY',
          name: 'Privacy & Security',
          icon: 'private',
          url: `${DOCS_SITE_URL}/security`,
        },
        {
          key: 'FREQUENTLY_ASKED_QUESTIONS',
          name: 'Frequently asked questions',
          icon: 'doc-request',
          url: `${DOCS_SITE_URL}/faq`,
        },
        {
          key: 'GETTING_STARTED_GUIDE',
          name: 'Getting started guide',
          icon: 'help',
          url: `${DOCS_SITE_URL}/getting-started-guide`,
        },
        {
          key: 'VS_CODE_EXTENSION',
          name: 'VS Code extension',
          icon: 'vscode',
          url: 'https://marketplace.visualstudio.com/items?itemName=Swimm.swimm',
        },
        {
          key: 'JETBRAINS_PLUGIN',
          name: 'JetBrains plugin',
          icon: 'jetbrains',
          url: 'https://plugins.jetbrains.com/plugin/20716-swimm',
        },
        {
          key: 'JOIN_SWIMMS_SLACK_COMMUNITY',
          name: 'Join Swimm’s Slack community',
          icon: 'slack',
          url: config.SLACK_COMMUNITY_INVITE_LINK,
        },
        {
          key: 'GIVE_FEEDBACK',
          name: 'Give feedback',
          icon: 'chat',
        },
        { key: 'WHATS_NEW', name: `What’s new`, icon: 'magic' },
      ],
    };
  },
  computed: {
    isLoggedIn() {
      return this.user.uid;
    },
  },
  methods: {
    resetAllOpened() {
      this.showReleaseNotesModal = false;
    },
    closeAll() {
      this.closeEllipsis();
      this.resetAllOpened();
    },
    closeEllipsis() {
      this.toggleHelpCenter(false);
    },

    toggleShowOptions() {
      if (this.showHelpCenterAnimation) {
        LocalStorage.set(localStorageKeys.USER_CLICKED_IN_APP_GUIDANCE, true);
        this.clickedInAppGuidance = true;
      }
      this.$emit('menu-toggled');
      if (!this.showHelpCenter) {
        this.resetAllOpened();
      }
      this.toggleHelpCenter(!this.showHelpCenter);
      if (this.showHelpCenter) {
        this.analytics.track(productEvents.HELP_CENTER_OPENED, {
          'Origin URL': this.$route.fullPath,
        });
      }
    },
    handleResourceClick(item) {
      const KBItemActions = {
        GIVE_FEEDBACK: this.showExplicitFeedback,
        WHATS_NEW: this.handleReleaseNotesClick,
      };
      if (!item.url) {
        KBItemActions[item.key]();
      }
      this.reportKBOptionSelection(item.name);
    },
    handleReleaseNotesClick() {
      this.showReleaseNotesModal = true;
    },
    handleTutorialCLick(item) {
      this.currentUrl = item.url;
      this.showVideoModal = true;
      this.reportKBOptionSelection(item.name);
    },
    reportKBOptionSelection(optionSelected) {
      this.analytics.track(productEvents.HELP_CENTER_OPTION_CLICKED, {
        'Origin URL': this.$route.fullPath,
        'Resource Name': optionSelected,
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.help-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 12345;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-base);

  .help-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: var(--box-shadow-small);
    cursor: pointer;
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    background-color: var(--color-invert);
    color: var(--text-color-invert);
    position: relative;
  }

  .pulse-animation:before,
  .pulse-animation:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--color-brand);
    border-radius: 50%;
    z-index: -1;
    opacity: 0.5;
    animation: pulse 2s infinite;
  }

  .pulse-animation:before {
    animation: pulse 1.5s ease-out infinite;
  }

  .pulse-animation:after {
    animation: pulse 1.5s 2s ease-out infinite;
  }

  @keyframes pulse {
    100% {
      transform: scale(1.7);
      opacity: 0;
    }
  }

  .ellipsis {
    pointer-events: auto;

    & :deep(.options) {
      border-radius: 8px;
      box-shadow: var(--box-shadow-small);
    }
  }
}
</style>
