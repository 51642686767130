export function getLinksFromText(text: string) {
  const foundSwimmLinks = text.match(/\[\[swm-link:(.*?)\]\]/g);
  const linksIdsInText = {};
  if (foundSwimmLinks && foundSwimmLinks.length > 0) {
    for (const swimmLink of foundSwimmLinks) {
      const stripedSwimmLink = swimmLink.replace('[[swm-link:(', '').replace(']]', '');
      const swimmLinkParts = stripedSwimmLink.split(')');
      const swimmId = swimmLinkParts[0];
      const originalSwimmName = stripedSwimmLink.slice(swimmId.length + 1);
      linksIdsInText[swimmId] = originalSwimmName;
    }
  }
  return linksIdsInText;
}

export function getLinksTextRegex(symbolKey: string): RegExp {
  return new RegExp(`\\[\\[sym-link:\\(${symbolKey}\\)(.*?)\\]\\]`, 'gm');
}

export function replaceLinkText(text: string, linkId: string, newText: string) {
  return text.replace(getLinksTextRegex(linkId), `[[swm-link:(${linkId})${newText}]]`);
}

export function decodeLink(link) {
  const decodedFrom64 = Buffer.from(link, `base64`).toString(`binary`);
  return decodeURIComponent(decodedFrom64);
}
