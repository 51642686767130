import { customAlphabet, nanoid } from 'nanoid';

export function shortUuid({
  useCustomAlphabet = true,
  customAlphabetString = '0123456789abcdefghijklmnopqrstuvwxyz',
  length = 5,
}: { useCustomAlphabet?: boolean; customAlphabetString?: string; length?: number } = {}) {
  if (useCustomAlphabet) {
    const customUuid = customAlphabet(customAlphabetString, length);
    return customUuid();
  }
  return nanoid(length);
}

export function getNewDocId(): string {
  return shortUuid({ length: 8 });
}
