<template>
  <Loader v-if="loading" no-flickering />
  <div v-else>
    <MarkdownViewer v-if="markdown" :marked-string="markdown" />
    <div v-else>Sorry, we couldn't find the file {{ url }}</div>
  </div>
</template>

<script>
import MarkdownViewer from '@/common/components/Markdown/MarkdownViewer.vue';
import { useRouting } from '@/common/composables/routing';
import { mapActions } from 'vuex';

export default {
  components: { MarkdownViewer },
  props: {
    url: { type: String, required: true },
  },
  emits: ['load-failed'],
  setup() {
    const { getCurrentOrDefaultBranch } = useRouting();
    return { getCurrentOrDefaultBranch };
  },
  data() {
    return {
      loading: true,
      markdown: '',
    };
  },
  async created() {
    this.markdown = await this.readAsset({
      filePath: this.url,
      repoId: this.$route.params.repoId,
      revision: await this.getCurrentOrDefaultBranch(this.$route.params.repoId),
    });
    this.loading = false;
    if (!this.markdown) {
      this.$emit('load-failed');
    }
  },
  methods: {
    ...mapActions('filesystem', ['readAsset']),
  },
};
</script>
