export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  region: string;
  measurementId?: string;
}

export const firebaseConfigs: { [key in 'development' | 'stage' | 'production']: FirebaseConfig } = {
  development: {
    apiKey: 'AIzaSyD0Vgp7pQxy0mkzGbc6vRaAuZcMaGj6jPA',
    authDomain: 'auth-staging.swimm.io',
    databaseURL: 'https://swimm-dev.firebaseio.com',
    projectId: 'swimm-dev',
    storageBucket: 'swimm-dev-content',
    messagingSenderId: '446142776839',
    appId: '1:446142776839:web:e460d4771e5669b77e0ca2',
    region: 'us-central1',
  },
  stage: {
    apiKey: 'AIzaSyBxy0psJktzebGIU86aZfxtt73h7AB_MwY',
    authDomain: 'stag.swimm.cloud',
    databaseURL: 'https://swimm-stag.firebaseio.com',
    projectId: 'swimm-stag',
    storageBucket: 'swimm-stag.appspot.com',
    messagingSenderId: '163268741197',
    appId: '1:163268741197:web:10960d0470c8ebc3823404',
    region: 'us-central1',
  },
  production: {
    apiKey: 'AIzaSyBhtsVhXnZ_xzxd0fiR4w77tgZvwMSArLQ',
    authDomain: 'auth.swimm.io',
    databaseURL: 'https://swimmio.firebaseio.com',
    projectId: 'swimmio',
    storageBucket: 'swimmio-content',
    messagingSenderId: '426538876670',
    appId: '1:426538876670:web:8c8ae4546744a09bdd2faf',
    measurementId: 'G-DY9SSYMJVV',
    region: 'us-central1',
  },
};
