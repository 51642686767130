<script setup lang="ts">
import { computed, ref, toRaw } from 'vue';
import { NodeViewContent, NodeViewWrapper, getText, getTextSerializersFromSchema, nodeViewProps } from '@tiptap/vue-3';
import { githubSlugify } from '../../swmd/slugify';
import { useAnimate } from '@vueuse/core';
import { getSwimmEditorServices } from '../extensions/Swimm';
import * as _model from '@tiptap/pm/model'; // Silence TS bug https://github.com/microsoft/TypeScript/issues/42873

const props = defineProps(nodeViewProps);

const swimmEditorServices = getSwimmEditorServices(props.editor);

const el = ref<InstanceType<typeof NodeViewWrapper>>();

const hasLevel = computed<boolean>(() => props.extension.options.levels.includes(props.node.attrs.level));
const level = computed<number>(() => (hasLevel.value ? props.node.attrs.level : props.extension.options.levels[0]));
const id = computed(() =>
  githubSlugify(getText(toRaw(props.node), { textSerializers: getTextSerializersFromSchema(props.editor.schema) }))
);

const focusAnimation = useAnimate(
  el,
  [{ backgroundColor: 'transparent' }, { backgroundColor: 'var(--color-hover)' }, { backgroundColor: 'transparent' }],
  { duration: 1500, immediate: false }
);

swimmEditorServices.animationsBus.on((event) => {
  switch (event.type) {
    case 'focus':
      if (event.id === id.value) {
        focusAnimation.play();
      }
      break;
  }
});
</script>

<template>
  <NodeViewWrapper :id="id" ref="el" class="heading" :class="`heading-${level}`" role="heading" :aria-level="level"
    ><NodeViewContent as="span"
  /></NodeViewWrapper>
</template>

<style scoped lang="scss">
.heading {
  border-radius: 4px;
}
</style>
