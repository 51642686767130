<template>
  <AuthBaseLayout title="Sign up to Swimm" :subtitle="subtitle" class="card-container">
    <template v-if="shouldShowRegisterForm">
      <form
        class="signup-fields"
        data-testid="user-register-form"
        @keydown.enter.prevent="onRegisterClick"
        @submit.prevent="onRegisterClick"
      >
        <TextField
          class="input"
          data-testid="full-name-field"
          v-model.trim="nickname"
          :error="nicknameError"
          label="Full name"
          placeholder="Daniel Smith"
          focus-first
          required
          @update:model-value="onNameChange"
        />
        <TextField
          v-model.trim="email"
          :error="emailError"
          type="email"
          label="Work email"
          placeholder="daniel.smith@acme.com"
          class="input"
          data-testid="email-field"
          :disabled="isInviteEmailPrefilled"
          required
          @update:model-value="onEmailChange"
          @blur="onEmailFieldBlur"
        />
        <PasswordFieldValidator :password="password">
          <template #default="{ show, hide }">
            <TextField
              v-model="password"
              :error="passwordError"
              type="password"
              label="Password"
              placeholder="New password"
              required
              class="input tooltip-target"
              data-testid="password-field"
              @focus="show"
              @update:model-value="onPasswordChange"
              @blur="onPasswordFieldBlur({ callback: hide })"
            />
          </template>
        </PasswordFieldValidator>
        <Action :loading="isLoading" data-testid="signup-button">Sign up</Action>
      </form>
      <ErrorBox v-if="error" class="error">{{ error }}</ErrorBox>
      <SwText
        v-if="showSignupWithOtherOptions"
        variant="body-S"
        class="back-button"
        data-testid="other-signup-options"
        tabindex="0"
        @click="showSignupWithOtherOptions = false"
        @keydown.enter.prevent="showSignupWithOtherOptions = false"
        >Sign up with other options</SwText
      >
    </template>
    <div class="signup-options-buttons" v-else>
      <Action class="email-login" data-testid="email-auth-button" @click.prevent="showSignupWithOtherOptions = true"
        >Continue with work email</Action
      >
      <GoogleAuthButton v-if="!isInviteEmailPrefilled" class="google-login" @click="onGoogleSignupClick"
        >Continue with Google</GoogleAuthButton
      >
      <Action type="a" class="sso-login" data-testid="sso-auth-button" @click.prevent="onSSOSignupClick"
        >Continue with SSO</Action
      >
    </div>
    <template #footer>
      <SwText variant="body-S" class="help-text" data-testid="sign-in-button"
        >Already have an account? <router-link @click="reportClick" to="/login">Sign in</router-link></SwText
      >
    </template>
  </AuthBaseLayout>
</template>

<script>
import { useAnalytics } from '@/common/composables/useAnalytics';
import { MOBILE_MAX_WIDTH, PageRoutesNames } from '@/common/consts';
import AuthBaseLayout from '@/modules/core/components/AuthBaseLayout.vue';
import { getSystemTheme } from '@/common/utils/theme-utils';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import {
  GitProviderName,
  IdeOptions,
  UrlUtils,
  eventLogger,
  getLoggerNew,
  pageEvents,
  productEvents,
} from '@swimm/shared';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { mapActions } from 'vuex';
import PasswordFieldValidator from '@/common/components/Auth/PasswordFieldValidator.vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import { startCase } from 'lodash-es';
import { isPasswordValid, registerUserWithPassword } from '@/common/utils/authUtils';
import { getSourcePropertiesFromLocalStorage } from '@/common/utils/helpers';
import GoogleAuthButton from '@/common/components/Auth/GoogleAuthButton.vue';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { ErrorBox, SwText, TextField } from '@swimm/ui';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { storeToRefs } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { setUserCreatedFieldInDb } from '@/common/utils/user-utils';
import { fetchSSOSetup } from '@/modules/core/composables/sso';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';

const logger = getLoggerNew(__modulename);

const ErrorMessages = {
  PASSWORD_REQUIRED: 'Password is required',
  PASSWORD_INVALID: 'Password does not meet the requirements or is weak',
  EMAIL_REQUIRED: 'Work email is required',
  EMAIL_INVALID: 'Invalid email',
  SOMETHING_WENT_WRONG: 'Registration failed. Please check your details and try again.',
  NAME_REQUIRED: 'Full name is required',
  GENERAL: 'An internal error has occurred, please contact support',
};

export default {
  components: {
    AuthBaseLayout,
    SwText,
    TextField,
    ErrorBox,
    PasswordFieldValidator,
    GoogleAuthButton,
  },
  props: {
    isFromGHMarketplace: { type: Boolean, default: false },
    ghName: { type: String, default: '' },
    ghEmail: { type: String, default: '' },
    ghLogin: { type: String, default: '' },
  },
  setup(props) {
    const route = useRoute();
    const analytics = useAnalytics();
    const authStore = useAuthStore();
    const { logEvent } = useSwimmEventLogs();

    const { user } = storeToRefs(authStore);
    const { setUser } = authStore;
    const { authorizeProviderWithGit } = useGitAuthorizationStore();

    reportPageViewed();

    function reportPageViewed() {
      const payload = {
        'Page Name': 'SignUp Page',
        ...(route.query?.source && { Source: route.query.source }),
      };

      analytics
        .cloudPageViewed({
          identity: props.ghLogin ? null : uuidv4(),
          anonymousId: props.ghLogin ? props.ghLogin : uuidv4(),
          event: pageEvents.LOADED_A_PAGE,
          payload,
        })
        .then(() => analytics.track(pageEvents.LOADED_A_PAGE_MARKETING, payload));
    }

    const subtitle = computed(() =>
      route.query.email || route.query.workspaceName
        ? `Join ${route.query.workspaceName || 'the Workspace'} on Swimm`
        : 'Move knowledge fast. Develop faster.'
    );

    const isOriginFromIDE = computed(() => {
      const { query } = route;
      return (
        Object.values(IdeOptions).includes(query?.platform) || (query?.redirect && query.redirect.includes('ide-login'))
      );
    });

    return {
      user,
      logEvent,
      setUser,
      analytics,
      subtitle,
      authorizeProviderWithGit,
      isOriginFromIDE,
    };
  },
  data() {
    return {
      email: '',
      password: '',
      nickname: '',
      error: '',
      emailError: '',
      passwordError: '',
      nicknameError: '',
      isLoading: false,
      isInviteEmailPrefilled: false,
      isInvited: false,
      showSignupWithOtherOptions: false,
    };
  },
  computed: {
    shouldShowRegisterForm() {
      return this.showSignupWithOtherOptions || this.isInviteEmailPrefilled;
    },
  },
  async created() {
    this.shouldPrefillEmailField();
    this.shouldShowSignupWithOtherOptions();
    this.email = this.ghEmail;
    this.nickname = this.ghName;
    const props = getSourcePropertiesFromLocalStorage({ keyFormatCallback: startCase, valueFallback: '' });
    if (this.$route.query.platform) {
      props.Platform = this.$route.query.platform;
    }

    const payload = {
      ...props,
      'Is From GitHub Marketplace': this.isFromGHMarketplace,
      'GitHub Email': this.ghEmail ? this.ghEmail : null,
    };
    if (!this.ghLogin) {
      this.analytics.track(productEvents.VIEW_SIGNUP, payload);
    } else {
      this.analytics
        .userIdentify(null, {}, this.ghLogin)
        .then(() => this.analytics.track(productEvents.VIEW_SIGNUP, payload));
    }
  },
  methods: {
    ...mapActions('database', ['fetchWorkspaceInvites']),
    shouldPrefillEmailField() {
      if (this.isFromGHMarketplace) {
        return;
      }

      const { email } = this.$route.query;
      if (email) {
        this.isInviteEmailPrefilled = true;
        this.email = email;
      }
    },
    shouldShowSignupWithOtherOptions() {
      if (this.isFromGHMarketplace && (this.ghName || this.ghEmail)) {
        this.showSignupWithOtherOptions = true;
      }
    },
    async onRegisterClick(event) {
      this.analytics.track(productEvents.SIGNUP_CLICK, {
        Origin: 'Signup page',
        'Origin URL': this.$route.fullPath,
        Context: 'Email Form',
      });
      event.stopPropagation();
      if (await this.validateForm()) {
        return;
      }
      try {
        this.isLoading = true;
        logger.debug('User registration process started');
        await this.register();
        logger.debug('User registration process finished successfully');
      } catch (err) {
        logger.error({ err }, `User registration failed, unexpected error: ${err}`);
        this.error = ErrorMessages.GENERAL;
      } finally {
        this.isLoading = false;
      }
      return false; // To prevent default form behavior
    },
    async register() {
      if (await fetchSSOSetup(this.email)) {
        await firebase.auth().signOut();
        return this.$router.push({
          name: PageRoutesNames.SSO,
          query: { ...(this.$route.query || {}), email: this.email },
          register: true,
        });
      }
      if (!(await this.createUser())) {
        return;
      }
      if (this.isFromGHMarketplace) {
        this.authorizeProviderWithGit({ provider: GitProviderName.GitHub, origin: 'Login' });
      }
      this.analytics.reportHubspotSignUp({
        email: this.email,
        name: this.nickname,
      });

      this.sendWelcomeEmail();
      const query = { ...this.$route.query };
      if (this.isOriginFromIDE) {
        delete query.redirect;
      }
      return this.$router.push({ name: PageRoutesNames.ONBOARDING_WELCOME, query });
    },
    async createUser() {
      logger.debug(`Attempting to create user with email: ${this.email}`);
      try {
        await registerUserWithPassword({
          displayName: this.nickname,
          email: this.email,
          password: this.password,
        });
      } catch (err) {
        logger.error({ err }, `User registration failed for email: ${this.email}`);
        this.emailError = ErrorMessages.SOMETHING_WENT_WRONG;
        return false;
      }
      const user = firebase.auth().currentUser;
      await setUserCreatedFieldInDb(user.uid);
      logger.debug(`User created, uid: ${user.uid}`);

      const userInvites = await this.fetchWorkspaceInvites();
      this.isInvited = !!userInvites.length;
      this.setUser({
        ...user,
        uid: user.uid,
        email: user.email,
        displayName: this.nickname,
        isRegistrationEvent: true,
        query: this.$route.query,
        isInvited: this.isInvited,
      });
      this.logEvent(
        eventLogger.SWIMM_EVENTS.USER_SIGN_UP,
        { srcId: user.uid, srcName: this.nickname, isJoining: this.isInvited },
        { uid: user.uid, nickname: this.nickname, email: user.email }
      );
      this.analytics.initAnalytics({
        uid: user.uid,
        nickname: this.nickname,
        email: this.email,
        creationTime: user.metadata.creationTime,
        lastSignInTime: user.metadata.lastSignInTime,
        browserSystemTheme: getSystemTheme(),
        sourceProps: getSourcePropertiesFromLocalStorage({ keyFormatCallback: startCase, valueFallback: '' }),
      });
      const payload = {
        Origin: 'Signup page',
        'Origin URL': this.$route.fullPath,
        'Signup Method': 'Email',
        'Signup Type': this.isInviteEmailPrefilled ? 'Invitation' : 'Direct',
        'Is Invited': userInvites.length ? 'True' : 'False',
        'Is From GitHub Marketplace': this.isFromGHMarketplace,
        'GitHub Email': this.ghEmail ? this.ghEmail : null,
        'User Email': this.email,
        ...getSourcePropertiesFromLocalStorage({ keyFormatCallback: startCase, valueFallback: '' }),
      };
      this.analytics.cloudTrack({
        identity: this.user.uid,
        event: productEvents.USER_SIGNED_UP,
        payload,
      });
      this.analytics.track(productEvents.USER_SIGNED_UP_MARKETING, { ...payload, user_id: this.user.uid });
      return true;
    },
    async sendWelcomeEmail() {
      try {
        const isMobile = screen.width <= MOBILE_MAX_WIDTH;
        logger.debug(`Sending welcome email, uid: ${this.user.uid}`);
        await CloudFunctions.sendWelcomeEmail({
          isWeb: true,
          isMobile,
        });
      } catch (err) {
        logger.error({ err }, `Failed to send welcome email, uid: ${this.user.uid}, error: ${error}`);
        // Do not raise this exception, we do not want it to terminate the registration process
      }
    },
    async validateForm() {
      this.nicknameError = '';
      this.passwordError = '';
      this.emailError = '';
      this.error = '';

      if (!this.nickname) {
        this.nicknameError = ErrorMessages.NAME_REQUIRED;
      }

      await this.validatePassword();
      this.validateEmail();

      const filteredErrors = [this.nicknameError, this.passwordError, this.emailError, this.error].filter(Boolean);
      const shouldShowErrors = !!filteredErrors.length;

      if (shouldShowErrors) {
        this.analytics.track(productEvents.SIGNUP_FORM_ERROR, {
          Message: filteredErrors.join(' | '),
        });
      }

      return shouldShowErrors;
    },
    onEmailChange() {
      this.emailError = '';
    },
    onEmailFieldBlur() {
      this.validateEmail();
    },
    async onPasswordFieldBlur({ callback }) {
      await this.validatePassword();
      callback();
    },
    onNameChange() {
      this.nicknameError = '';
    },
    onPasswordChange() {
      this.passwordError = '';
    },
    validateEmail() {
      if (!this.email) {
        this.emailError = ErrorMessages.EMAIL_REQUIRED;
      } else if (!UrlUtils.isValidEmail(this.email)) {
        this.emailError = ErrorMessages.EMAIL_INVALID;
      }
    },
    async validatePassword() {
      if (!this.password) {
        this.passwordError = ErrorMessages.PASSWORD_REQUIRED;
      } else if (!(await isPasswordValid(this.password))) {
        this.passwordError = ErrorMessages.PASSWORD_INVALID;
      }
    },
    async onGoogleSignupClick() {
      this.analytics.track(productEvents.GOOGLE_SIGNUP_CLICK, {
        Origin: 'Signup page',
        'Origin URL': this.$route.fullPath,
      });
    },
    onSSOSignupClick() {
      this.analytics.track(productEvents.SSO_SIGNUP_CLICK, {
        Origin: 'Signup page',
        'Origin URL': this.$route.fullPath,
      });
      return this.$router.push({ name: PageRoutesNames.SSO, query: { ...this.$route.query, register: true } });
    },
    reportClick() {
      this.analytics.track(productEvents.CLICKED_SIGN_IN, {
        'Is From GitHub Marketplace': this.isFromGHMarketplace,
        'GitHub Email': this.ghEmail ? this.ghEmail : null,
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.card-container {
  margin: auto;
  gap: var(--space-md);

  .signup-options-buttons {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);
  }

  .signup-fields {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);
  }

  .error {
    margin: var(--space-md) 0;
  }

  .back-button {
    cursor: pointer;
    color: var(--text-color-link);
  }

  .help-text {
    a {
      color: var(--text-color-link);
    }
  }

  .sso-login {
    cursor: pointer;
    color: var(--text-color-link) !important;
  }
}
</style>
