import { useStore } from 'vuex';
import { type SwimmResourceUserStatus, UrlUtils, config, isSwmDoc, isSwmExercise } from '@swimm/shared';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { PlaylistSequenceStepTypes } from '@/modules/playlists/types';
import { useRepoDocsStore } from '@/modules/core/stores/repo-docs';

// By "swimm" we mean a unit or an external link

export function useSwimmResource() {
  const store = useStore();
  const { user } = storeToRefs(useAuthStore());
  const repoDocsStore = useRepoDocsStore();
  const fs_isUnitFileInRepoPath = computed(() => store.getters['filesystem/fs_isUnitFileInRepoPath']);
  const db_getPlaylist = computed(() => store.getters['database/db_getPlaylist']);
  const db_getSwimm = computed(() => store.getters['database/db_getSwimm']);
  const db_isPublicRepo = computed(() => store.getters['database/db_isPublicRepo']);
  const db_isRepoLifeguard = computed(() => store.getters['database/db_isRepoLifeguard']);
  const repositories = store.state.database.repositories;

  const isSwimmAvailable = (repoId: string, swimmId: string) => {
    const isLocalUnit = swimmId in repoDocsStore.currentRepoDocsInBranch;
    const isAvailableLink =
      swimmId in repositories[repoId].swimms &&
      repositories[repoId].swimms[swimmId].type === PlaylistSequenceStepTypes.EXTERNAL_LINK;
    return isLocalUnit || isAvailableLink;
  };

  const getResourceIconByState = ({
    resource,
    status,
    repoId,
    isPlaylist = false,
    isExplicitlyUnavailable = false,
  }: {
    // TODO: SwimmResource type (Swm & Playlist)
    resource;
    status?: SwimmResourceUserStatus;
    repoId: string;
    isPlaylist?: boolean;
    isExplicitlyUnavailable?: boolean;
  }) => {
    if (isExplicitlyUnavailable) {
      return 'unavailable';
    }
    if (status && status === config.SWIMMER_STATUSES.DONE) {
      return 'check';
    }
    if (resource.isDraftLink) {
      if (['playlist', 'unit', 'swimm'].includes(resource.type)) {
        return resource.type === 'playlist' ? 'playlist' : 'doc';
      }
    }
    if (resource.type === PlaylistSequenceStepTypes.PLAYLIST) {
      return 'playlist';
    }
    if (resource.type === PlaylistSequenceStepTypes.EXTERNAL_LINK) {
      return resource.url ? UrlUtils.getIconName(resource.url) : 'link';
    }
    if (isPlaylist) {
      if (!fs_isUnitFileInRepoPath.value(resource.id, repoId)) {
        return 'unavailable';
      }
    }
    if (isSwmDoc({ swm: resource })) {
      return 'doc';
    }
    if (isSwmExercise({ swm: resource })) {
      return 'task';
    }
    if (isSwimmAvailable(repoId, resource.id)) {
      return 'lifebuoy-outline';
    }
    return 'unavailable';
  };

  const getRepoResourceByType = (repoId, resourceId, resourceType) => {
    if (resourceType === PlaylistSequenceStepTypes.PLAYLIST) {
      return db_getPlaylist.value(repoId, resourceId);
    }
    return db_getSwimm.value(repoId, resourceId);
  };

  /**
   *  Check if a resource should be available for editing by the user's role and the resource type and kind.
   */
  const isResourceEditable = (repoId, resourceId, resourceType) => {
    const resource = getRepoResourceByType(repoId, resourceId, resourceType);
    if (
      !resource || // If there is no resource with the provided IDs, prevent editing
      resource.is_example || // Example resources are not editable
      (db_isPublicRepo.value(repoId) && !db_isRepoLifeguard.value(repoId, user.value.uid)) || // Public repo resources are editable by lifeguards only
      isSwmExercise({ swm: resource, swimmTypeFromDb: resource.type }) // Editing exercises from the web isn't allowed
    ) {
      return false;
    }

    return true;
  };

  return { isSwimmAvailable, getResourceIconByState, isResourceEditable };
}

export const isResourceSwimmByFilename = (filename: string): boolean => {
  return (
    filename.toLowerCase().endsWith(config.SWMD_PLAYLIST_EXTENSION) ||
    filename.toLowerCase().endsWith(config.SWMD_TEMPLATE_FILE_EXTENSION) ||
    filename.toLowerCase().endsWith(config.SWMD_FILE_EXTENSION) ||
    filename.toLowerCase().endsWith(config.SWM_FILE_EXTENSION)
  );
};
