/**
 * Read the specified file and return its data as inline base64 data with prefix and type.
 * For example: "data:image/png;base64,iVBORw0KG...".
 * @param {file} file file to convert
 * @returns {Promise}
 */
export const fileToBase64Data = (file: File | Blob): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * Read the specified file and return its data as text.
 * @param {file} file file to convert
 * @returns {Promise}
 */
export const readFile = (file: Blob): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
