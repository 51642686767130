<script setup>
import { SwText } from '@swimm/ui';

const props = defineProps({
  name: { type: String, required: true },
  thumbnail: { type: String, default: null },
  url: { type: String, default: null },
  handler: { type: Function, default: () => ({}) },
  closeAfterAction: { type: Boolean, default: true },
});
const emit = defineEmits(['close-ellipsis']);
const handleClick = async () => {
  await props.handler();
  if (props.closeAfterAction) {
    emit('close-ellipsis');
  }
};
function getImageUrl(image) {
  return new URL(`../assets/${image}`, import.meta.url).href;
}
</script>

<template>
  <div class="body-L option ellipsis-option clickable" @click="handleClick">
    <div class="resource-container">
      <img class="thumbnail" v-if="thumbnail" :src="getImageUrl(thumbnail)" />
      <SwText variant="body-S" class="resource-text">{{ name }}</SwText>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.loading {
  animation: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: inline-block;
}

.option {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  white-space: nowrap;
  color: var(--text-color-primary);
  list-style: none;

  &:hover {
    background: var(--color-hover);
  }

  .resource-container {
    display: flex;
    gap: var(--space-base);
    padding-right: var(--space-md);
  }

  .resource-text {
    align-self: center;
  }

  .outbound-link-icon {
    align-self: center;
    color: var(--color-label-tag);
    font-size: var(--body-S);
  }

  .thumbnail {
    height: 40px;
  }
}
</style>
