<template>
  <div class="text-line">
    <SwText variant="subtitle-L" class="pre-hunk" :class="{ 'underline-title': !isAuthorized }">
      {{ type }}
      <span v-if="isAuthorized" class="file-path">
        <span v-if="line.repoPrefix">
          <Icon :name="line.repoPrefix.iconName" no-padding />
          {{ line.repoPrefix.name }}
          <span v-if="line.repoPrefix.branch">({{ line.repoPrefix.branch }})</span>
          <Icon name="arrow-right" no-padding />
        </span>
        <SwText
          variant="body-XS"
          :class="['text-reverse-ellipsis', 'text-ellipsis', 'path', { 'file-name': showFileName }]"
          @click="emit('show-file')"
        >
          {{ line.path }}
        </SwText>
      </span>
    </SwText>
    <div v-if="!isAuthorized">
      <SwText class="line-hunk authorize-text" variant="body-S">
        To view the context of this token, please authorize {{ providerDisplayName }}
        <Action class="authorize-btn" @click="$emit('authorize')">Authorize {{ providerDisplayName }}</Action>
      </SwText>
    </div>
    <div v-else>
      <div v-if="isAutosyncable" class="line-hunk original-line">
        <span class="line-number">{{ originalLine.lineNumber }}</span>
        &nbsp;
        <span
          v-for="(lineText, index) of splitLineByWords(originalLine.lineData)"
          :key="index"
          :class="{ highlighted: index >= originalLine.wordIndex.start && index <= originalLine.wordIndex.end }"
          >{{ lineText }}</span
        >
      </div>
      <div class="line-hunk" :class="[lineClass, { 'line-border': !isAutosyncable }]">
        <span class="line-number">{{ line.lineNumber }}</span>
        &nbsp;
        <span
          v-for="(lineText, index) of splitLineByWords(line.lineData)"
          :key="index"
          :class="[
            { highlighted: index >= line.wordIndex.start && index <= line.wordIndex.end },
            { verified: isVerified },
          ]"
          >{{ lineText }}</span
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { splitLineByWords } from '@swimm/shared';
import { Action, Icon, SwText } from '@swimm/ui';
import { computed } from 'vue';

const autosyncableType = 'Token Auto-synced';
const verifiedType = 'Token up to date';
const props = defineProps({
  type: { type: String, default: '' },
  isAuthorized: { type: Boolean, default: false },
  lineClass: { type: String, default: '' },
  showFileName: { type: Boolean },
  line: {
    type: Object,
    default: () => ({
      path: '',
      lineNumber: '',
      lineData: '',
      wordIndex: { start: 0, end: 0 },
      repoPrefix: null,
    }),
  },
  originalLine: {
    type: Object,
    default: () => ({}),
  },
  applicability: { type: String, default: '' },
  providerDisplayName: { type: String, default: 'Github' },
});

const emit = defineEmits(['show-file', 'authorize']);

const isAutosyncable = computed(() => {
  return props.type === autosyncableType;
});

const isVerified = computed(() => {
  return props.type === verifiedType;
});
</script>

<style scoped lang="postcss">
.text-line {
  .pre-hunk {
    padding: var(--space-base) var(--side-padding);
    font-weight: 800;
    color: var(--text-color-primary);
    background-color: var(--color-bg);
    line-height: 150%;

    &.underline-title {
      padding: 0 var(--side-padding);
      border-bottom: 1px solid var(--color-label-tag);
    }

    .file-path {
      font-size: var(--body-XS);
      font-weight: normal;
      color: var(--text-color-secondary);
      display: inline-flex;
      align-items: center;

      .file-name {
        text-decoration: underline;

        &.file-name:hover {
          color: var(--text-color-link);
          cursor: pointer;
        }
      }

      .path {
        max-width: 250px;
      }
    }
  }

  .line-hunk {
    padding: 0 var(--side-padding);
    font-size: var(--body-S);
    font-family: var(--fontfamily-secondary);
    font-weight: 600;
    color: var(--text-color-primary);
    line-height: 150%;

    .highlighted {
      padding: 0 2px;
      background-color: var(--color-code-error-meat);
      color: var(--text-color-primary);

      &.verified {
        background-color: var(--color-status-success-hover);
      }
    }

    .line-number {
      font-family: var(--fontfamily-main);
      color: var(--color-label-tag);
    }

    &.line-border {
      border-style: solid;
      border-width: 1px 0px;
      border-color: var(--color-label-tag);
    }

    &.original-line {
      background-color: var(--color-status-error);
    }

    &.updated-line {
      background-color: var(--color-status-magic);

      .highlighted {
        background-color: var(--color-code-autosync-meat);
        color: var(--text-color-primary);
      }
    }

    &.verified-line {
      background-color: var(--color-status-success);
    }

    &.no-access-line {
      background: var(--color-surface);
    }

    &.authorize-text {
      padding-top: var(--space-base);

      .authorize-btn {
        height: 28px;
        margin-left: var(--space-base);
      }
    }
  }
}
</style>
