<template>
  <DocumentationSection
    :repo="repo"
    :repo-id="repoId"
    :filtered-documentation="
      documentations.map((item) => ({ ...item, documentationType: DocumentationTypes.DOC_REQUEST }))
    "
    @doc-request-action="handleDocRequestAction"
  >
    <template #empty-state>
      <EmptyState
        title="There are no requested docs"
        description="Can’t find a doc you are looking for? Ask your team to create one."
        transparent
      >
        <Action class="create-button" secondary @click="createNewDocRequest"> Request a doc </Action>
      </EmptyState>
    </template>
  </DocumentationSection>
</template>

<script>
import DocumentationSection from '@/common/components/organisms/DocumentationSection.vue';
import { RepoPageDocumentationSectionTypes } from '@/common/consts';
import { DocumentationTypes } from '@/common/consts';

export default {
  components: { DocumentationSection },
  props: {
    docRequests: {
      type: Array,
      required: true,
    },
    drafts: {
      type: Array,
      required: true,
    },
    repo: {
      type: Object,
      required: true,
    },
  },
  emits: ['create-new-doc-request-clicked', 'doc-request-action'],
  setup() {
    return { RepoPageDocumentationSectionTypes, DocumentationTypes };
  },
  computed: {
    repoId() {
      return this.repo.id;
    },
    documentations() {
      return this.docRequests.map((docRequest) => ({
        ...docRequest,
        draft: this.drafts.find((d) => d.docRequestId === docRequest.id),
      }));
    },
  },
  methods: {
    createNewDocRequest() {
      this.$emit('create-new-doc-request-clicked');
    },
    handleDocRequestAction(docRequest, action) {
      this.$emit('doc-request-action', docRequest, action);
    },
  },
};
</script>
