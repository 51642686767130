<template>
  <div class="action-row" :data-testid="`${testIdPrefix}action-row`">
    <VMenu theme="menu-no-arrow">
      <div class="row">
        <Icon :name="icon" class="icon headline3" :data-testid="`${testIdPrefix}action-icon`" />
        <span class="action-name body-S"> {{ name }}</span>
        <div class="number body-S" :data-testid="`${testIdPrefix}action-number`">{{ number }}</div>
      </div>
      <template #popper><slot /></template>
    </VMenu>
    <span
      v-if="showAction"
      class="button-link body-S"
      :data-testid="`${testIdPrefix}action-button`"
      @click="emitClick"
      >{{ actionName }}</span
    >
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    icon: { required: true, type: String },
    name: { required: true, type: String },
    number: { default: 0, type: Number },
    actionName: { required: true, type: String },
    showAction: { default: true, type: Boolean },
    testIdPrefix: { type: String, required: true },
  },
  emits: ['action-clicked'],
  methods: {
    emitClick() {
      this.$emit('action-clicked');
    },
  },
};
</script>

<style scoped>
.action-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  line-height: 24px;
}

.row {
  display: flex;
  align-items: center;
}

.action-name {
  display: inherit;
}

.number {
  margin-left: 8px;
  padding: 4px 8px;
  width: 12px;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--color-disable);
}

.button-link {
  color: var(--text-color-link);
  cursor: pointer;
}
</style>
