// @ts-strict
import { getLogger } from '../logger/legacy-shim';
import { StateTokenData } from './git-provider-utils';

const logger = getLogger("packages/shared/src/git-utils/refresh-tokens.ts");

let currentRefreshTokensFunctions: RefreshTokensFunctions;

export interface RefreshTokensFunctions {
  refreshToken({
    gitHosting,
    tenantId,
    refreshToken,
  }: {
    provider: string;
    gitHosting: string;
    tenantId?: string;
    refreshToken: string;
  }): Promise<{ data: StateTokenData } | null>;
}

export function setRefreshTokensFunctions(functions: RefreshTokensFunctions) {
  currentRefreshTokensFunctions = functions;
}

export async function refreshToken(
  args: Parameters<RefreshTokensFunctions['refreshToken']>[0]
): ReturnType<RefreshTokensFunctions['refreshToken']> {
  if (!currentRefreshTokensFunctions?.refreshToken) {
    logger.warn(`Attempting to use ${args.provider} refresh token function before it was set`);
    return null;
  }
  return currentRefreshTokensFunctions.refreshToken(args);
}
