import { mergeAttributes } from '@tiptap/core';
import Image from '@tiptap/extension-image';
import { isParentOfType } from '../utils';
import { getSwimmEditorServices } from './Swimm';
import { getUploadImageErrorMessage, pickImage } from '../image';
import BlockImageNodeView from '../nodeViews/BlockImageNodeView.vue';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import type { EditorView } from '@tiptap/pm/view';
import { getLoggerNew } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    blockImage: {
      /**
       * Add a block image
       */
      setBlockImage: (options: { src: string; alt?: string; title?: string }) => ReturnType;

      pickAndInsertBlockImage: () => ReturnType;
      restoreOriginalBlockImageSize: (pos: number) => ReturnType;
      shrinkBlockImage: (pos: number) => ReturnType;
      expandBlockImage: (pos: number) => ReturnType;
    };
  }
}

const MIN_IMAGE_WIDTH = 10;
const MAX_IMAGE_WIDTH = 100;

export default Image.extend({
  name: 'blockImage',

  addOptions() {
    return {
      ...this.parent?.(),
      inline: false,
    };
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        parseHTML: (element) => {
          return (element.firstChild as HTMLImageElement)?.style.width;
        },
        renderHTML: (attributes) => {
          if (attributes.width) {
            return { style: `width: ${attributes.width}` };
          }

          return null;
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        priority: 51,
        tag: this.options.allowBase64 ? 'p > img[src]' : 'p > img[src]:not([src^="data:"])',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', { align: 'center' }, ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]];
  },

  addNodeView() {
    return VueNodeViewRenderer(BlockImageNodeView);
  },

  addCommands() {
    return {
      setBlockImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },

      pickAndInsertBlockImage:
        () =>
        ({ chain, dispatch, editor, state }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);

          if (!isParentOfType(state.doc, state.selection.head, ['paragraph'])) {
            return false;
          }

          if (!dispatch) {
            return chain().focus().setBlockImage({ src: '' }).run();
          }

          setTimeout(async () => {
            try {
              const image = await pickImage();
              editor.commands.focus();

              if (image != null) {
                const id = {};
                editor.commands.addInsertionPlaceholder(id, state.selection.head);
                try {
                  const img = await swimmEditorServices.external.uploadImage(image, swimmEditorServices.unitId.value);
                  editor.commands.replaceInsertionPlaceholder(id, {
                    type: this.name,
                    attrs: { src: img.src },
                  });
                } catch (e) {
                  editor.commands.replaceInsertionPlaceholder(id, null);
                  throw e;
                }
              }
            } catch (err) {
              logger.warn({ err }, `Failed to upload block image`);
              swimmEditorServices.external.showNotification(getUploadImageErrorMessage(err as Error), {
                icon: 'error',
              });
            }
          }, 0);

          return true;
        },

      restoreOriginalBlockImageSize:
        (pos) =>
        ({ dispatch, state, tr }) => {
          const $pos = state.doc.resolve(pos);

          if ($pos.nodeAfter?.type !== this.type) {
            return false;
          }

          if (dispatch) {
            tr.setNodeAttribute(pos, 'width', null);
          }

          return true;
        },

      shrinkBlockImage:
        (pos) =>
        ({ dispatch, state, view, tr }) => {
          const $pos = state.doc.resolve(pos);

          if ($pos.nodeAfter?.type !== this.type) {
            return false;
          }

          const width = parseInt($pos.nodeAfter.attrs.width || getImagePercentSize(view, pos), 10);
          const newWidth = Math.max(width - 20, MIN_IMAGE_WIDTH);

          if (dispatch) {
            tr.setNodeAttribute(pos, 'width', `${newWidth}%`);
          }

          return true;
        },

      expandBlockImage:
        (pos) =>
        ({ dispatch, state, view, tr }) => {
          const $pos = state.doc.resolve(pos);

          if ($pos.nodeAfter?.type !== this.type) {
            return false;
          }

          const width = parseInt($pos.nodeAfter.attrs.width || getImagePercentSize(view, pos), 10);
          const newWidth = Math.min(width + 20, MAX_IMAGE_WIDTH);

          if (dispatch) {
            tr.setNodeAttribute(pos, 'width', `${newWidth}%`);
          }

          return true;
        },
    };
  },

  addInputRules() {
    return [];
  },
});

function getImagePercentSize(view: EditorView, pos: number): string {
  const dom = view.nodeDOM(pos) as HTMLElement | null;
  const imageNode = dom?.querySelector('.block-image__image') as HTMLImageElement | null;
  const containerWidth = (imageNode?.offsetParent as HTMLElement | null)?.offsetParent?.getBoundingClientRect().width;
  if (!imageNode || !containerWidth) {
    return '50%';
  }

  const percent = Math.round((imageNode?.naturalWidth / containerWidth) * 100);
  return `${percent}%`;
}
