<script setup lang="ts">
import { ref } from 'vue';
import { BaseIcon, BaseTooltip } from '@swimm/reefui';

const clicked = ref(false);

const emit = defineEmits<{ click: [event: PointerEvent] }>();

function handleClick(event: PointerEvent) {
  clicked.value = true;
  setTimeout(() => {
    clicked.value = false;
  }, 1000);

  emit('click', event);
}
</script>

<template>
  <BaseTooltip v-if="!clicked" content="Copy link">
    <BaseIcon
      class="clickable link-copy-button__icon"
      data-testid="link-copy-button"
      name="link"
      @click="handleClick"
    />
  </BaseTooltip>
  <BaseTooltip v-else content="Copied">
    <BaseIcon name="check" class="link-copy-button__icon" />
  </BaseTooltip>
</template>

<style scoped lang="scss">
.link-copy-button__icon {
  font-size: var(--font-size-medium);
}
</style>
