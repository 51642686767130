import type { Lang } from 'shiki';

const extensionToLanguage: Record<string, Lang> = {
  '.abap': 'abap',
  '.as': 'actionscript-3',
  '.adb': 'ada',
  '.conf': 'apache',
  '.cls': 'apex',
  '.apl': 'apl',
  '.applescript': 'applescript',
  '.asm': 'asm',
  '.awk': 'awk',
  '.bal': 'ballerina',
  '.bat': 'bat',
  '.bib': 'bibtex',
  '.c': 'c',
  '.cpp': 'cpp',
  '.cs': 'csharp',
  '.css': 'css',
  '.d': 'd',
  '.dart': 'dart',
  '.diff': 'diff',
  '.dockerfile': 'dockerfile',
  '.ex': 'elixir',
  '.elm': 'elm',
  '.erl': 'erlang',
  '.fish': 'fish',
  '.fs': 'fsharp',
  '.go': 'go',
  '.groovy': 'groovy',
  '.hs': 'haskell',
  '.hcl': 'hcl',
  '.html': 'html',
  '.ini': 'ini',
  '.java': 'java',
  '.js': 'javascript',
  '.json': 'json',
  '.jsx': 'javascript',
  '.kt': 'kotlin',
  '.latex': 'latex',
  '.less': 'less',
  '.lisp': 'lisp',
  '.lua': 'lua',
  '.md': 'markdown',
  '.m': 'matlab',
  '.nim': 'nim',
  '.objc': 'objective-c',
  '.ml': 'ocaml',
  '.p': 'pascal',
  '.pl': 'perl',
  '.php': 'php',
  '.ps1': 'powershell',
  '.py': 'python',
  '.pyx': 'python',
  '.pyd': 'python',
  '.r': 'r',
  '.rb': 'ruby',
  '.rs': 'rust',
  '.sass': 'sass',
  '.scala': 'scala',
  '.scss': 'scss',
  '.sh': 'shellscript',
  '.sol': 'solidity',
  '.sql': 'sql',
  '.styl': 'stylus',
  '.svelte': 'svelte',
  '.swift': 'swift',
  '.ts': 'typescript',
  '.tex': 'tex',
  '.tsx': 'tsx',
  '.twig': 'twig',
  '.vb': 'vb',
  '.v': 'verilog',
  '.vhdl': 'vhdl',
  '.vim': 'viml',
  '.vue': 'vue',
  '.xml': 'xml',
  '.yaml': 'yaml',
};

export function getLanguageFromPath(filePath: string): Lang {
  // Extracting the file extension from the file path
  const extension = filePath.slice(filePath.lastIndexOf('.'));

  return extensionToLanguage[extension] ? extensionToLanguage[extension] : 'bash';
}
