<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watchEffect } from 'vue';

import type { WorkspaceRepo } from '@swimm/shared';
import type { FolderTree } from '@swimm/shared';

import BaseProse from '../../components/BaseProse/BaseProse.vue';
import Menu from '../Menu/Menu.vue';
import MenuRepos from '../MenuRepos/MenuRepos.vue';
import NodeTree, { type SelectableFolderTree } from '../NodeTree/NodeTree.vue';

import SwmSelectionContent from '../SwmSelectionContent/SwmSelectionContent.vue';

const props = defineProps<{
  modelValue: string;
  workspaceRepos?: WorkspaceRepo[];
  selectedNodes?: SelectableFolderTree[];
  disableAutoFocus?: boolean;
  node?: FolderTree;
  loading?: boolean;
  error?: string;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: string];
  selectRepo: [repo: WorkspaceRepo];
  'selectedNodes:add': [node: FolderTree];
  'selectedNodes:remove': [node: FolderTree];
  close: [];
}>();

const query = ref(props.modelValue);
const selectionPopover = ref<InstanceType<typeof SwmSelectionContent> | null>(null);
const delayFocus = ref(false);
const focusedNode = ref<FolderTree | null>(null);

// If more than one workspace repo has been supplied we're showing a repo listing.
const isRepoListing = computed(() => props.workspaceRepos && props.workspaceRepos.length > 1);

// Toggle search placeholder based on context.
const searchPlaceholder = computed(() => (isRepoListing.value ? 'Search workspace repositories…' : 'Search files…'));

// If workspace repos have been supplied crossRepoSupport is true.
const crossRepoSupport = computed(() => {
  return !!(props.workspaceRepos && props.workspaceRepos.length);
});

// Filtered workspace repos based on query string.
const filteredWorkspaceRepos = computed(() => {
  return isRepoListing.value && props.workspaceRepos
    ? props.workspaceRepos.filter((repo) => repo.name.toLowerCase().includes(query.value))
    : props.workspaceRepos;
});

// We only show the repos menu if...
const showMenuRepos = computed(() => {
  return (
    // We're viewing the node tree with a single repo and not searching
    (crossRepoSupport.value && !isRepoListing.value && !query.value) ||
    // Or we're showing a repo listing
    isRepoListing.value
  );
});

// If cross repo support is false we don't need to stylistically indent the node tree
const indentNodeTree = computed(() => {
  return crossRepoSupport.value ? 1 : 0;
});

// No results found for workspace repo search.
const noRepoResults = computed(() => {
  return !!(isRepoListing.value && query.value && filteredWorkspaceRepos.value && !filteredWorkspaceRepos.value.length);
});

const currentRepoSelectedNodes = computed(() => {
  if (props.workspaceRepos == null || props.workspaceRepos.length === 0) {
    return props.selectedNodes;
  }
  if (props.workspaceRepos.length === 1) {
    return props.selectedNodes?.filter((node) => node.repo === props.workspaceRepos?.[0]);
  }
  return [];
});

const computedClasses = computed(() => ({
  'swm-selection-content-token-sidebar--cross-repo-support': crossRepoSupport.value,
}));

function onUpdateModelValue(value: string) {
  emit('update:modelValue', value);
  query.value = value;
}

function onSelectRepo(repo: WorkspaceRepo) {
  emit('selectRepo', repo);
  setSearchFocus();
}

function onSelectedNodesAdd(node: FolderTree) {
  emit('selectedNodes:add', node);
}

function onSelectedNodesRemove(node: FolderTree) {
  emit('selectedNodes:remove', node);
}

function onFocusedNode(node: FolderTree | null) {
  focusedNode.value = node;
}

async function handleNodeFocus(index: number) {
  if (selectionPopover.value) {
    await nextTick();

    // For focusable items to update.
    await selectionPopover.value.forceUpdateFocusItems();

    // Set focus to the index supplied.
    selectionPopover.value.setItemFocus(index);
  }
}

async function refreshFocusableItems() {
  if (selectionPopover.value) {
    await nextTick();

    // For focusable items to update.
    await selectionPopover.value.forceUpdateFocusItems();
  }
}

async function resetItemFocus() {
  if (selectionPopover.value) {
    await nextTick();
    selectionPopover.value.resetItemFocus();
  }
}

async function setSearchFocus() {
  if (selectionPopover.value) {
    await nextTick();
    selectionPopover.value.setSearchFocus();
  }
}

onMounted(async () => {
  // We want to focus in the search input on mount but if loading
  // is true we can't focus because the input is disabled, so we
  // set delayFocus to true.
  if (props.loading && !props.disableAutoFocus) {
    delayFocus.value = true;
  } else if (!props.disableAutoFocus) {
    await setSearchFocus();
  }
});

watchEffect(async () => {
  // We watch for loading to become false and if delayFocus was set
  // to true we focus on the search input.
  if (!props.loading && !props.disableAutoFocus && delayFocus.value) {
    await setSearchFocus();

    // We set delayFocus back to false to ensure this watcher
    // has no further effect.
    delayFocus.value = false;
  }
});

watchEffect(() => {
  query.value = props.modelValue;
});

defineExpose({
  setSearchFocus,
  resetItemFocus,
});
</script>

<template>
  <div class="swm-selection-content-token-sidebar">
    <SwmSelectionContent
      ref="selectionPopover"
      v-model="query"
      :placeholder="searchPlaceholder"
      :error="error"
      :loading="loading"
      :no-results="noRepoResults"
      :disable-auto-focus="disableAutoFocus"
      class="swm-selection-content-token-sidebar__node-tree"
      :class="computedClasses"
      @update:model-value="onUpdateModelValue"
      @close="() => emit('close')"
    >
      <Menu class="swm-selection-content-token-sidebar__content" data-testid="SwmSelectionContentTokenSidebar: menu">
        <MenuRepos
          v-if="showMenuRepos"
          :workspace-repos="filteredWorkspaceRepos"
          :searching="!!query"
          @select-repo="onSelectRepo"
        />

        <template v-if="!isRepoListing">
          <BaseProse
            v-if="!node"
            wrapper="li"
            variant="secondary"
            size="small"
            class="swm-selection-content-token-sidebar__no-nodes"
            >There are no files in this repo.</BaseProse
          >
          <template v-else>
            <NodeTree
              :query="query"
              :node="node!"
              :level-offset="indentNodeTree"
              :selected-nodes="currentRepoSelectedNodes"
              selectable
              @focused-node="onFocusedNode"
              @selected-nodes:add="onSelectedNodesAdd"
              @selected-nodes:remove="onSelectedNodesRemove"
              @showing-more="handleNodeFocus"
              @change="refreshFocusableItems"
            />
          </template>
        </template>
      </Menu>
    </SwmSelectionContent>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-selection-content-token-sidebar {
  $self: &;

  @include basic-resets;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  flex: 70;

  &__node-tree {
    max-height: 100%;
    height: 100%;
  }

  &__content {
    overflow: auto;
  }

  &__no-nodes {
    list-style: none;
    padding-left: calc(var(--space-xxsmall) + var(--space-small));
  }

  &__footer {
    padding: 0 var(--space-xsmall) var(--space-xsmall);
  }

  &__path {
    font-weight: var(--font-weight-regular);
  }
}
</style>
