/**
 * swmToken node.
 *
 * Based on https://github.com/ueberdosis/tiptap/blob/164eebf07ced16e29e38f13909322ce301575355/packages/extension-code/src/code.ts but adapted to a Node
 *
 * @module
 */

import { type JSONContent, Node, VueNodeViewRenderer, VueRenderer, mergeAttributes } from '@tiptap/vue-3';
import SwmTokenNodeView from '../nodeViews/SwmTokenNodeView.vue';
import { getSwimmEditorServices } from './Swimm';
import { type TokenPos, parseSwmTokenPos, serializeSwmTokenPos } from '@/markdownit/plugins/swm_token';
import { isParentOfType } from '../utils';
import { applySwmTokenAutosync, convertSwmTokenToSmartElement } from '../../swmd/autosync';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import {
  ApplicabilityStatus,
  type SmartElementWithApplicability,
  type Token,
  type TokenSuggestion,
  isSmartElementWithNewInfo,
  removePrefix,
} from '@swimm/shared';
import Suggestion from '@tiptap/suggestion';
import { ALLOWED_PREFIXES } from '@swimm/editor';
import SwmTokenSelectionSimplePopover from '../../components/SwmTokenSelectionSimplePopover.vue';
import type { Instance } from 'tippy.js';
import tippy from 'tippy.js';
import { PluginKey } from '@tiptap/pm/state';
import type { Editor } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    swmToken: {
      insertSwmToken: (token: string, path: string, pos: TokenPos, lineData: string, repoId: string) => ReturnType;
      openSwmTokenSelectionMenu: (initialQuery?: string) => ReturnType;
      selectAndInsertSwmTokenWithAdvancedMode: (initialQuery: string) => ReturnType;
      applySwmTokenAutosync: (
        pos: number,
        token: SmartElementWithApplicability<Token>,
        userRequested?: boolean
      ) => ReturnType;
      convertSwmTokenToCode: (pos: number) => ReturnType;
    };
  }
}

export interface SwmTokenOptions {
  /**
   * Custom HTML attributes that should be added to the rendered HTML tag.
   */
  HTMLAttributes: Record<string, unknown>;
}

export interface SwmTokenStorage {
  shown: boolean;
}

export default Node.create<SwmTokenOptions, SwmTokenStorage>({
  name: 'swmToken',

  group: 'inline',

  inline: true,

  code: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addStorage() {
    return {
      shown: false,
    };
  },

  addAttributes() {
    return {
      token: {
        isRequired: true,
        parseHTML(element) {
          return element.getAttribute('data-token');
        },
        renderHTML(attributes) {
          return { 'data-token': attributes.token };
        },
      },
      path: {
        isRequired: true,
        parseHTML(element) {
          return element.getAttribute('data-path');
        },
        renderHTML(attributes) {
          return { 'data-path': attributes.path };
        },
      },
      pos: {
        isRequired: true,
        parseHTML(element) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return parseSwmTokenPos(element.getAttribute('data-pos')!);
        },
        renderHTML(attributes) {
          return { 'data-pos': serializeSwmTokenPos(attributes.pos) };
        },
      },
      // TODO Rename to line-content to match autosync?
      lineData: {
        isRequired: true,
        parseHTML(element) {
          return element.getAttribute('data-line-data');
        },
        renderHTML(attributes) {
          return { 'data-line-data': attributes.lineData };
        },
      },
      repoId: {
        isRequired: true,
        parseHTML: (element) => {
          return element.getAttribute('data-repo-id');
        },
        renderHTML: (attributes) => {
          return { 'data-repo-id': attributes.repoId };
        },
      },
      repoName: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute('data-repo-name');
        },
        renderHTML: (attributes) => {
          return { 'data-repo-name': attributes.repoName };
        },
      },
      customDisplayText: {
        default: null,
        parseHTML: (element) => {
          return element.hasAttribute('data-custom') ? element.textContent : null;
        },
        renderHTML: (attributes) => {
          return attributes.customDisplayText ? { 'data-custom': '' } : null;
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        priority: 51,
        tag: 'code',
        getAttrs: (node) => (node as HTMLElement).hasAttribute('data-swm-token') && null,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    let text;
    if (node.attrs.customDisplayText) {
      text = node.attrs.customDisplayText;
    } else {
      text = node.attrs.token;
    }

    return [
      'code',
      mergeAttributes(
        {
          'data-swm-token': '',
        },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      text,
    ];
  },

  renderText({ node }) {
    if (node.attrs.customDisplayText) {
      return node.attrs.customDisplayText;
    } else {
      return node.attrs.token;
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(SwmTokenNodeView);
  },

  addCommands() {
    return {
      insertSwmToken:
        (token, path, pos, lineData, repoId) =>
        ({ commands, dispatch, editor }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);

          const node: JSONContent = {
            type: this.name,
            attrs: {
              token,
              path,
              pos,
              lineData,
              repoId,
              repoName: swimmEditorServices.getRepoName(repoId),
            },
          };

          if (dispatch) {
            const smartElementInfo = convertSwmTokenToSmartElement(
              ProseMirrorNode.fromJSON(editor.schema, node),
              swimmEditorServices.repoId.value,
              swimmEditorServices.branch.value,
              swimmEditorServices.repos.value.repos
            );

            const smartElement: SmartElementWithApplicability<Token> = {
              ...smartElementInfo,
              applicability: ApplicabilityStatus.Verified,
              newInfo: smartElementInfo,
            };
            swimmEditorServices.autosyncOutput.value.smartElements.set(smartElement.id, smartElement);
            void swimmEditorServices.external.setSmartElementCountInDB(
              swimmEditorServices.unitId.value,
              swimmEditorServices.repoId.value,
              swimmEditorServices.autosyncOutput.value
            );
          }

          return commands.insertContent(node);
        },

      openSwmTokenSelectionMenu:
        (initialQuery = '') =>
        ({ commands, state }) => {
          if (
            !isParentOfType(state.doc, state.selection.head, [
              'paragraph',
              'tableHeader',
              'tableCell',
              'mermaid',
              'heading',
            ])
          ) {
            return false;
          }

          const previousChar = state.doc.textBetween(state.selection.from - 1, state.selection.from);
          if (previousChar !== '' && previousChar !== ' ') {
            return commands.insertContent(` \`${initialQuery}`);
          }

          return commands.insertContent(`\`${initialQuery}`);
        },

      selectAndInsertSwmTokenWithAdvancedMode:
        (initialQuery) =>
        ({ dispatch, editor }) => {
          if (dispatch) {
            const swimmEditorServices = getSwimmEditorServices(editor);

            setTimeout(async () => {
              const result = await swimmEditorServices.selectTokenInAdvancedMode(initialQuery);
              if (result == null) {
                return;
              }

              if (result.selectedToken != null) {
                editor
                  .chain()
                  .focus()
                  .insertSwmToken(
                    result.selectedToken.token,
                    `/${removePrefix(result.selectedToken.position.path, '/')}`,
                    result.selectedToken.position,
                    result.selectedToken.lineData,
                    result.selectedToken.repoId
                  )
                  .run();
                return;
              }

              if (result.showSimple) {
                editor.chain().focus().openSwmTokenSelectionMenu(result.lastQuery).run();
                return;
              }

              editor.commands.focus();
            });
          }

          return true;
        },

      applySwmTokenAutosync:
        (pos, token, userRequested = true) =>
        ({ chain, editor }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);

          if (!isSmartElementWithNewInfo(token)) {
            return false;
          }

          const node = editor.state.doc.nodeAt(pos);
          if (!node) {
            return false;
          }

          if (node.type !== this.type) {
            return false;
          }

          return (
            chain()
              .command(({ dispatch, tr }) => {
                if (dispatch) {
                  applySwmTokenAutosync(tr, node, pos, token);
                }

                return true;
              })
              // The state is the state after the previous command has ran when actually running the commandss
              .command(({ dispatch, state }) => {
                if (dispatch) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  const newNode = state.doc.nodeAt(pos)!;
                  const smartElementInfo = convertSwmTokenToSmartElement(
                    newNode,
                    swimmEditorServices.repoId.value,
                    swimmEditorServices.branch.value,
                    swimmEditorServices.repos.value.repos
                  );

                  const smartElement: SmartElementWithApplicability<Token> = {
                    ...smartElementInfo,
                    applicability: ApplicabilityStatus.Verified,
                    newInfo: smartElementInfo,
                  };
                  swimmEditorServices.autosyncOutput.value.smartElements.set(smartElement.id, smartElement);

                  if (userRequested) {
                    swimmEditorServices.animations.animateNodeById(smartElement.id);
                  }
                }

                return true;
              })
              .run()
          );
        },

      convertSwmTokenToCode:
        (pos) =>
        ({ commands, state }) => {
          const $pos = state.doc.resolve(pos);
          const node = $pos.nodeAfter;

          if (node?.type !== this.type) {
            return false;
          }

          return commands.insertContentAt(
            {
              from: pos,
              to: pos + node.nodeSize,
            },
            {
              type: 'text',
              text: node.attrs.token,
              marks: [{ type: 'code' }],
            }
          );
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion<TokenSuggestion>({
        pluginKey: new PluginKey(this.name),
        editor: this.editor,
        char: '`',
        allowSpaces: true,
        allowedPrefixes: ALLOWED_PREFIXES,

        command: ({ editor, range, props }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .insertSwmToken(
              props.token,
              `/${removePrefix(props.position.path, '/')}`,
              props.position,
              props.lineData,
              props.repoId
            )
            .run();
        },

        // TODO It might be possible to deduplicate this part with the same
        // implementation in slashCommands, just need to figure out what
        // parameters the factory function for this should take so its properly
        // reusable
        render: () => {
          const editor = this.editor;
          const storage = this.storage;

          let component: VueRenderer | null = null;
          let popup: Instance | null = null;

          let query: string | null = null;

          function close(editor: Editor) {
            popup?.hide();
            editor.commands.focus();
          }

          return {
            onStart: (props) => {
              query = props.query;

              // FIXME: This should not exist. Replace with the correct way of keyboard handling.
              const focusOnEditor = (event?: KeyboardEvent): void => {
                props.editor.commands.focus();
                if (event && isInsertableContent(event)) {
                  if (event.key === '`' && props.range != null) {
                    props.editor.view.dispatch(
                      props.editor.state.tr
                        .delete(props.range.from, props.range.from + 1)
                        .addMark(props.range.from, props.range.to - 1, props.editor.schema.marks.code.create())
                        .removeStoredMark(props.editor.schema.marks.code)
                    );
                  } else {
                    props.editor.commands.insertContent(event.key);
                  }
                }
              };

              component = new VueRenderer(SwmTokenSelectionSimplePopover, {
                props: {
                  ...props,
                  onReleaseFocus: focusOnEditor,
                  onClose: () => close(props.editor),
                },
                editor: props.editor,
              });

              if (!props.clientRect) {
                return;
              }

              popup = tippy(props.editor.view.dom, {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                getReferenceClientRect: () => props.clientRect!()!,
                appendTo: document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start',
                theme: 'none',
                role: 'menu',
                maxWidth: 'none',
                onShow(_instance) {
                  storage.shown = true;
                },
                onHide(_instance) {
                  storage.shown = false;
                },
              });
            },

            onUpdate(props) {
              query = props.query;

              component?.updateProps(props);

              if (!props.clientRect) {
                return;
              }

              popup?.setProps({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                getReferenceClientRect: () => props.clientRect!()!,
              });
            },

            onKeyDown(props) {
              if (props.event.key === 'Escape') {
                popup?.hide();

                return true;
              }

              if (props.event.ctrlKey && props.event.shiftKey && props.event.key === 'K') {
                return editor
                  .chain()
                  .deleteRange(props.range)
                  .selectAndInsertSwmTokenWithAdvancedMode(query ?? '')
                  .run();
              }

              return component?.ref?.onKeyDown?.(props.event);
            },

            onExit() {
              if (popup != null) {
                popup.destroy();
                component?.destroy();
                popup = null;
                component = null;
              }
            },
          };
        },
      }),
    ];
  },
});

function isInsertableContent(event: KeyboardEvent) {
  return (
    event.key != null && event.key.length === 1 && /[a-zA-Z0-9 `~!@#$%^&*()\-_=+[\]{};:'",.<>?\\/|]/.test(event.key)
  );
}
