<script setup lang="ts">
import { computed } from 'vue';
import { SwModal, SwText } from '@swimm/ui';

const props = defineProps<{ showModal: boolean; confirmValue: boolean; featureName: string }>();
const emit = defineEmits<{
  (e: 'close', accpeted: boolean): void;
}>();
const heading = computed(() => {
  const verb = props.confirmValue ? 'Enable' : 'Disable';
  return `${verb} ${props.featureName} for all repos?`;
});
const actionName = computed(() => (props.confirmValue ? 'Enable all repos' : 'Disable all repos'));
</script>

<template>
  <SwModal :show-modal="showModal" :heading="heading" @close="emit('close', false)">
    <div class="content">
      <template v-if="confirmValue">
        <SwText variant="body-L"
          >This will affect all repos, including ones where the feature was disabled manually. <br />Newly added repos
          will have the feature on by default.
        </SwText>
      </template>
      <template v-else>
        <SwText variant="body-L"
          >This will affect all repos, including ones where the feature was enabled manually.<br />Newly added repos
          will have the feature off by default.</SwText
        >
      </template>
      <div class="actions">
        <Action secondary @click="emit('close', false)">Cancel</Action>
        <Action @click="emit('close', true)">{{ actionName }}</Action>
      </div>
    </div>
  </SwModal>
</template>

<style scoped lang="postcss">
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: var(--space-md);
}
</style>
