<script setup>
import { FilePicker } from '@swimm/editor';
import { getLoggerNew } from '@swimm/shared';
import { Loader, SwAvatar, SwText } from '@swimm/ui';
import { uploadFiles } from '@swimm/editor';
import { ref } from 'vue';
import { storageWrapper } from '@/modules/core/services/storage-wrapper';

const logger = getLoggerNew(__modulename);
const LOGO_UPLOAD_DIRECTORY_PATH = 'workspaces/logos';

defineProps({
  workspaceName: { type: String, required: true },
  logo: { type: String, required: true },
});
const emit = defineEmits(['upload-logo']);
const loading = ref(false);
const error = ref('');
const imageFilePicker = ref(null);

async function upload() {
  if (loading.value) {
    return;
  }
  loading.value = true;
  error.value = '';

  const imageFiles = await imageFilePicker.value.openPicker();

  uploadFiles({
    directory: LOGO_UPLOAD_DIRECTORY_PATH,
    files: imageFiles,
    success: (newLogoUrl) => {
      loading.value = false;
      emit('upload-logo', newLogoUrl);
    },
    failure: (e) => {
      loading.value = false;
      error.value = `Failed to upload file. ${e.customerMessage}`;
      logger.error(`Failed to upload logo to workspace, ${e.message}, ${e.customerMessage}`);
    },
    storageWrapper,
  });
}
</script>

<template>
  <div :class="['header', { disabled: loading }]">
    <Loader v-if="loading" secondary />
    <SwAvatar
      v-else
      :text="workspaceName || ' '"
      :src="logo"
      square
      size="large"
      hide-tooltip
      @click="upload"
      class="avatar"
    />
    <div>
      <SwText variant="body-S" weight="bolder" class="upload" @click="upload">Upload image</SwText>
      <SwText variant="body-XS" weight="light">5mb max</SwText>
      <SwText v-if="error" class="error" variant="body-XS" weight="light">{{ error }}</SwText>
    </div>
  </div>
  <FilePicker ref="imageFilePicker" />
</template>

<style scoped lang="postcss">
.header {
  display: flex;
  align-items: center;
  gap: var(--space-base);
  color: var(--text-color-secondary);
  margin-bottom: var(--space-md);

  .avatar {
    cursor: pointer;
  }

  .upload:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &.disabled {
    .avatar {
      cursor: default;
    }

    .upload:hover {
      cursor: default;
      text-decoration: none;
    }
  }

  .error {
    margin-top: var(--space-xs);
    color: var(--text-color-error);
  }
}
</style>
