// @ts-strict
import type { DocCacheStructure } from '@swimm/shared';
import { getLogger } from '@swimm/shared';

const logger = getLogger("packages/swimmagic/src/cache-utils/autosync-cache.ts");

let currentCacheFunctions: CacheFunctions;

export interface CacheFunctions {
  getFromCache({ workspaceId, cacheId }: { workspaceId: string; cacheId: string }): Promise<DocCacheStructure | null>;
  setInCache({
    workspaceId,
    cacheId,
    objectToSet,
  }: {
    workspaceId: string;
    cacheId: string;
    objectToSet: DocCacheStructure;
  }): Promise<void>;
}

export function setAutosyncCacheFunctions(cacheFunctions: CacheFunctions) {
  currentCacheFunctions = cacheFunctions;
}

export async function getFromCache(
  args: Parameters<CacheFunctions['getFromCache']>[0]
): ReturnType<CacheFunctions['getFromCache']> {
  if (!currentCacheFunctions?.getFromCache) {
    logger.warn('Attempting to get value from cache before cache functions were set.');
    return null;
  }
  return currentCacheFunctions.getFromCache(args);
}

export async function setInCache(
  args: Parameters<CacheFunctions['setInCache']>[0]
): ReturnType<CacheFunctions['setInCache']> {
  if (!currentCacheFunctions?.setInCache) {
    logger.warn('Attempting to set value in cache before cache functions were set.');
    return;
  }
  return currentCacheFunctions.setInCache(args);
}
