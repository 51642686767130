<script setup lang="ts">
import { NodeViewContent, NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import VSelect from 'vue-select';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';
import { BaseIcon, BaseLayoutGap, BaseProse } from '@swimm/reefui';
import { computed, ref } from 'vue';
import * as _model from '@tiptap/pm/model'; // Silence TS bug https://github.com/microsoft/TypeScript/issues/42873

const props = defineProps(nodeViewProps);
const swimmEditorServices = getSwimmEditorServices(props.editor);
const options = computed<{ name: string; label: string }>(() => {
  return props.extension.storage.allowedLanguages.map((allowedLanguage: string) => ({
    name: allowedLanguage,
    label: getDisplayLanguage(allowedLanguage),
  }));
});

const copied = ref(false);

function getDisplayLanguage(language: string) {
  return language || 'default';
}

function handleClick() {
  copied.value = true;
  setTimeout(() => {
    copied.value = false;
  }, 1000);

  props.editor.commands.copyCodeBlock(props.getPos());
}
</script>

<template>
  <NodeViewWrapper>
    <div class="code-block container">
      <NodeViewContent
        as="code"
        data-testid="code-block-content"
        :contenteditable="swimmEditorServices.editable.value"
        class="content"
        spellcheck="false"
      />
      <BaseLayoutGap alignment="top">
        <BaseLayoutGap>
          <VSelect
            :model-value="getDisplayLanguage(props.node.attrs.language)"
            class="language-select"
            data-testid="language-options"
            :options="options"
            :clearable="false"
            :searchable="true"
            @option:selected="
              (selectedLanguage) =>
                props.editor.chain().focus(props.getPos()).replaceLanguage(props.getPos(), selectedLanguage.name).run()
            "
          >
            <template #open-indicator>
              <BaseIcon no-padding name="arrow-down" class="arrow-icon" />
            </template>
            <template #option="{ label }">
              <span class="option-line">
                <BaseProse component="span" variant="primary">{{ label }}</BaseProse>
              </span>
            </template>
          </VSelect>
          <BaseIcon :name="copied ? 'check' : 'copy'" :class="{ clickable: !copied }" @click="handleClick" />
        </BaseLayoutGap>
      </BaseLayoutGap>
    </div>
  </NodeViewWrapper>
</template>

<style scoped lang="scss">
.container {
  $self: &;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .content {
    overflow-wrap: break-word;
    word-break: break-word;
    overflow-x: unset;
    white-space: pre;
  }

  .language-select {
    min-width: 10em;

    :deep(&.v-select.v-select:focus-within .vs__dropdown-toggle) {
      border-color: var(--color-border-default);
    }

    :deep(&.v-select .vs__selected-options) {
      font-family: var(--font-family-default);
      font-size: var(--font-size-default);
      color: var(--color-text-default);
    }

    :deep(&.v-select .vs__dropdown-menu) {
      border: 1px solid var(--color-border-default-subtle);
      top: auto;
      padding: var(--space-xsmall);
    }
  }
}
</style>
