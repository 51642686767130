<script setup lang="ts">
import { computed, watch } from 'vue';
import { SwText } from '@swimm/ui';
import { datetimeUtils } from '@swimm/shared';
import { useDrafts3Store } from '../stores/drafts3';
import { useTimestamp } from '@vueuse/core';

const drafts3Store = useDrafts3Store();

const { timestamp, pause, resume } = useTimestamp({ controls: true, interval: 1000 });

watch(
  () => drafts3Store.savingDraft,
  (value) => {
    if (value) {
      pause();
    } else {
      resume();
    }
  }
);

const relativeSaveTime = computed(() => {
  return datetimeUtils.toRelativeTimeInMinutes(timestamp.value, drafts3Store.saveEndTime);
});

const isNow = computed(() => {
  return relativeSaveTime.value === 'now';
});

const text = computed(() => {
  if (drafts3Store.savingDraft) {
    return 'Auto-saving...';
  }

  if (drafts3Store.saveEndTime) {
    return `Auto-saved ${relativeSaveTime.value}`;
  }

  return '';
});
</script>

<template>
  <SwText
    variant="body-S"
    class="autosave-indicator"
    :class="{ highlighted: !drafts3Store.savingDraft && isNow }"
    data-testid="autosave-indicator"
    v-tooltip="'We auto-save all changes locally. Commit them to share with others.'"
  >
    <div class="dot" />
    {{ text }}
  </SwText>
</template>

<style scoped lang="postcss">
.autosave-indicator {
  color: var(--text-color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  cursor: default;
  margin-left: var(--space-base);
  gap: var(--space-base);
  flex-shrink: 0;
  padding-right: var(--space-sm);

  &.highlighted {
    opacity: 1;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--warning-yellow-500);
  }
}
</style>
