import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core';

function transformSwmSnippetPlaceholder(state: StateCore): void {
  const tokens = state.tokens;

  for (let open = 0; open < tokens.length; open++) {
    if (tokens[open].type !== 'swm_block_open' || tokens[open].tag !== 'SwmSnippetPlaceholder') {
      continue;
    }

    let close;
    for (close = open + 1; close < tokens.length; close++) {
      if (tokens[close].type === 'swm_block_close' && tokens[open].tag === 'SwmSnippetPlaceholder') {
        break;
      }
    }

    if (close === tokens.length) {
      continue;
    }

    if (
      tokens[open + 1]?.type !== 'paragraph_open' ||
      tokens[open + 2]?.type !== 'inline' ||
      tokens[open + 3]?.type !== 'paragraph_close'
    ) {
      continue;
    }

    tokens[open].type = 'swm_snippet_placeholder';
    tokens[open].attrSet('placeholder', tokens[open + 2].content);

    // Collapse the following tokens including the close token, not exact
    for (let i = open + 1; i < close + 1; i++) {
      tokens[open].content = tokens[open].content + tokens[i].content;
    }
    tokens.splice(open + 1, close - open);
  }
}

export default function swm_snippet_placeholder(md: MarkdownIt): void {
  md.core.ruler.after('block', 'swm_snippet_placeholder', transformSwmSnippetPlaceholder);
}
