<template>
  <div class="rating">
    <Icon
      v-for="star in starsCount"
      :key="star"
      :name="currentRate >= star ? iconFilledName : iconName"
      :class="[{ selected: currentRate >= star, small }, 'clickable']"
      @click.prevent="selectRate(star)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const currentRate = ref(0);

defineProps({
  modelValue: { type: Number, required: true },
  starsCount: { type: Number, default: 5 },
  iconName: { type: String, default: 'star-outline' },
  iconFilledName: { type: String, default: 'star-filled' },
  small: { type: Boolean, default: false },
});

const emit = defineEmits(['update:model-value']);

function selectRate(star: number) {
  // Don't emit if the value is already set
  if (currentRate.value === star) {
    return;
  }
  currentRate.value = star;
  emit('update:model-value', star);
}
</script>

<style scoped>
.rating > i {
  font-size: var(--fontsize-xxl);
}

.rating > i.small {
  font-size: var(--fontsize-l);
}

.selected {
  color: var(--text-color-warning);
  text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black;
}
</style>
