<script setup lang="ts">
import type { OverlayToken } from '../GenericText/tokenizer';
import { BaseIcon, MenuItem } from '@swimm/reefui';

defineProps<{
  tok: OverlayToken;
}>();

const emit = defineEmits<{
  (event: 'add-def-snippet'): void;
  (event: 'token-interacted', op: 'hover' | 'click'): void;
}>();

function addDefSnippetClicked() {
  emit('add-def-snippet');
}
function tokenClicked() {
  // If the user clicked really quickly, before we sent the hover event, we also send the hover before the click so that
  // the analytics are consistent (a click without a hover is weird and would hurt the funnel).
  if (tokenHoverTimeout) {
    sendTokenHover();
  }
  emit('token-interacted', 'click');
}

let tokenHoverTimeout: ReturnType<typeof setTimeout> | undefined = undefined;
const TOKEN_HOVER_TIMEOUT_MS = 300;

function sendTokenHover() {
  emit('token-interacted', 'hover');
  clearHoverTimeout();
}

function tokenHovered() {
  clearHoverTimeout();
  tokenHoverTimeout = setTimeout(sendTokenHover, TOKEN_HOVER_TIMEOUT_MS);
}

function clearHoverTimeout() {
  if (!tokenHoverTimeout) {
    return;
  }
  clearTimeout(tokenHoverTimeout);
  tokenHoverTimeout = undefined;
}
</script>

<template>
  <VMenu :triggers="['click']" class="tok-menu" @show="tokenClicked">
    <span
      class="overlay-def"
      :data-overlay-length="tok.displayText.length"
      @mouseover="tokenHovered"
      @mouseleave="clearHoverTimeout"
      v-tooltip="{ content: 'Click for options', delay: TOKEN_HOVER_TIMEOUT_MS }"
      >{{ tok.displayText }}</span
    >
    <template #popper>
      <MenuItem @click="addDefSnippetClicked">
        <template #leftIcon><BaseIcon name="add" /></template>
        Add definition as snippet
      </MenuItem>
    </template>
  </VMenu>
</template>

<style scoped lang="postcss">
.overlay-def {
  cursor: pointer;
  margin-right: -2px;
  margin-left: -2px;
  position: relative;
  top: 1px;
  opacity: 0;
  color: var(--text-color-warning);
  --overlay-def-color: var(--color-bg-warning-subtle);
  background: var(--overlay-def-color);
  border: 2px solid var(--overlay-def-color);
  border-radius: 4px;

  &:hover {
    opacity: 1;
  }
}

.tok-menu {
  display: inline;
  pointer-events: all;
  user-select: none;
}
</style>
