import { CALENDLY_DEMO_LINK, CALENDLY_ENTERPRISE_HOSTING } from '@/config';
import { eventLogger, productEvents } from '@swimm/shared';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { useAnalytics } from '@/common/composables/useAnalytics';

export function useBookDemo() {
  const analytics = useAnalytics();
  const { logEvent } = useSwimmEventLogs();
  function openCalendlyDemoLink({ newPage } = { newPage: true }): void {
    window.open(CALENDLY_DEMO_LINK, newPage ? '_blank' : '_self');
  }

  function openCalendlyEnterpriseSetupLink({ newPage } = { newPage: true }): void {
    window.open(CALENDLY_ENTERPRISE_HOSTING, newPage ? '_blank' : '_self');
  }
  function reportBookDemoClick(context: string): void {
    logEvent(eventLogger.SWIMM_EVENTS.USER_REQUESTED_DEMO);
    analytics.track(productEvents.REQUEST_A_MEETING_WITH_SALES_OR_CS, { Context: context });
  }

  function reportRejectDemoClick(context: string): void {
    analytics.track(productEvents.REJECT_A_MEETING_WITH_SALES_OR_CS, { Context: context });
  }

  return {
    openCalendlyDemoLink,
    openCalendlyEnterpriseSetupLink,
    reportBookDemoClick,
    reportRejectDemoClick,
  };
}
