<script setup lang="ts">
import { useFoldersStore } from '@/modules/folders/store/folders';
import SearchSkeletons from '@/modules/search/components/SearchSkeletons.vue';
import { getRepoPath } from '@/router/router-utils';
import SearchResult from '../components/SearchResult.vue';
import { computed, ref } from 'vue';
import * as _ from 'lodash-es';
import { searchFuseWithBranch } from '@/remote-adapters/search';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { storeToRefs } from 'pinia';
import RecentlyViewedFile from '../components/RecentlyViewedFile.vue';
import { useEventsStore } from '@/modules/core/stores/event-log-store';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { MultiRepoSelector } from '@swimm/editor';
import {
  Keyboard,
  SwmResourceType,
  WorkspaceRepo,
  isRepoIdDummyRepo,
  keyboardShortcutUtils,
  productEvents,
} from '@swimm/shared';
import { useGlobalSearchStore } from '../stores/globalSearchStore';
import { Icon, SwModal, SwText, TextField } from '@swimm/ui';
import { BaseLayoutGap } from '@swimm/reefui';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';

const AMOUNT_OF_RECENT_TO_SHOW = 10;

const htmlInputName = 'input';
const { globalSearchLimitToRepo, initialSelectedRepoIdsForSearch, selectedRepoIdsForSearch, isIndexingSearchData } =
  storeToRefs(useGlobalSearchStore());
const input = ref(null);
const loading = ref(false);
const isInputFocused = ref(true);
const viewableResults = ref(null);
const results = ref([]);
const searchQuery = ref('');
const focusedResultIndex = ref(0);
const resultLocation = ref(0);
const store = useStore();
const eventsStore = useEventsStore();
const analytics = useAnalytics();
const { isCurrentWorkspaceAuthorized } = storeToRefs(useGitAuthorizationStore());
const { providerDisplayName } = storeToRefs(useWorkspaceStore());

const getWorkspace = store.getters['database/db_getWorkspace'];
const getRepoMetadata = store.getters['database/db_getRepoMetadata'];
const route = useRoute();
const router = useRouter();
const { recentlyViewedFiles } = storeToRefs(eventsStore);
const { reposStateData } = storeToRefs(useReposStore());
const { getItemParentFolder } = useFoldersStore();
const shortcut = computed(() => keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.CTRL, 'K'));

const workspace = computed(() => getWorkspace(route.params.workspaceId));
const workspaceRepoIds = computed(() => {
  return workspace.value.repositories;
});
const recentItemsToShow = computed(() => {
  // Remove recently viewed file on repos that are no longer part of the workspace
  const filtered = recentlyViewedFiles.value.filter(
    (file) => file?.repoId && workspaceRepoIds.value.includes(file.repoId)
  );
  return filtered.slice(0, AMOUNT_OF_RECENT_TO_SHOW).map((file) => {
    return {
      ...file,
      folderId: getItemParentFolder(file.srcId, file.repoId)?.id ?? '',
      repoProvider: getRepoMetadata(file.repoId)?.provider,
    };
  });
});

const props = withDefaults(
  defineProps<{
    show: boolean;
    showRepoSelector?: boolean;
    accessibleRepos?: WorkspaceRepo[];
  }>(),
  {
    show: false,
    accessibleRepos: () => [],
  }
);
const emit = defineEmits(['close']);

const contentTitle = computed(() => {
  if (!searchQuery.value) {
    return !globalSearchLimitToRepo.value ? 'Recently viewed' : '';
  }
  return 'Results';
});

const workspaceHasDummyRepo = computed(() => workspaceRepoIds.value.some(isRepoIdDummyRepo));

const eligibleForSearch = computed(() => {
  // allow search if user is authorized
  if (isCurrentWorkspaceAuthorized.value) {
    return true;
  }

  // if not authorized, allow search if on repo scope and repo is dummy repo
  if (globalSearchLimitToRepo.value) {
    return isRepoIdDummyRepo(globalSearchLimitToRepo.value.repoId);
  }
  // else (user is not authorized, and global scope), allow search only if workspace has dummy repo
  return workspaceHasDummyRepo.value;
});

const scrollItems = (event) => {
  if (props.show && searchQuery.value && results.value.length) {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      scrollToElement(true);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      scrollToElement(false);
    } else if (event.key === 'Enter') {
      router.push(results.value[focusedResultIndex.value].item.link);
      emit('close');
    }
  }
};

function scrollToElement(goToNext) {
  if (isInputFocused.value) {
    if (goToNext && focusedResultIndex.value < results.value.length - 1) {
      focusedResultIndex.value += 1;
    } else if (!goToNext && focusedResultIndex.value > 0) {
      focusedResultIndex.value -= 1;
    }
    resultLocation.value = focusedResultIndex.value;
  } else {
    if (goToNext && focusedResultIndex.value < results.value.length - 1) {
      resultLocation.value += 1;
    } else if (!goToNext && focusedResultIndex.value > 0) {
      resultLocation.value -= 1;
    }
  }

  const currentOption = viewableResults.value[resultLocation.value];
  if (currentOption) {
    currentOption.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }
}

function inputFocus(event) {
  if (event.target.localName === htmlInputName) {
    isInputFocused.value = true;
  }
}

function inputDefocus(event) {
  if (event.target.localName === htmlInputName) {
    isInputFocused.value = false;
  }
}
const searchGlobally = _.debounce(async function () {
  const query = searchQuery.value;
  const searches = [];
  if (eligibleForSearch.value) {
    if (!globalSearchLimitToRepo.value) {
      let repoIds = [...selectedRepoIdsForSearch.value];
      // do not search on unauthorized repositories to avoid throwing errors on console
      if (!isCurrentWorkspaceAuthorized.value) {
        repoIds = repoIds.filter(isRepoIdDummyRepo);
      }
      for (const repoId of repoIds) {
        const repo = getRepoMetadata(repoId);
        if (repo && repo.name) {
          if (repoId === route.params.repoId && route.params.branch) {
            searches.push(
              searchFuseWithBranch({
                query,
                repoName: repo.name,
                repoId: repoId,
                workspaceId: route.params.workspaceId as string,
                branch: route.params.branch as string,
                repoProvider: repo.provider,
              })
            );
          } else {
            searches.push(
              searchFuseWithBranch({
                query,
                repoName: repo.name,
                repoId: repoId,
                workspaceId: route.params.workspaceId as string,
                branch: reposStateData.value[repoId].defaultBranch,
                repoProvider: repo.provider,
              })
            );
          }
        }
      }
    } else {
      const repoId = globalSearchLimitToRepo.value.repoId;
      const repo = getRepoMetadata(repoId);
      if (repo && repo.name) {
        searches.push(
          searchFuseWithBranch({
            query,
            repoName: repo.name,
            repoId: repoId,
            workspaceId: route.params.workspaceId as string,
            branch: globalSearchLimitToRepo.value.branch,
            repoProvider: repo.provider,
          })
        );
      }
    }
  }
  const allResults = await Promise.all(searches);
  // skip displaying result if the search query was changed
  if (query !== searchQuery.value) {
    return;
  }
  const flattenedResults = [].concat(...allResults);
  flattenedResults.sort((result1, result2) => result1.score - result2.score);
  // keep the loader until we have results
  loading.value = false;
  results.value = flattenedResults;
  focusedResultIndex.value = 0;
  // don't report empty query
  if (query) {
    analytics.track(productEvents.SEARCHED_FOR_KEYWORD, {
      'Workspace ID': route.params.workspaceId,
      'Repo ID': route.params.repoId,
      Context: 'Search',
      Origin: route.name,
      'Keyword Size': query.length,
      'Total Files Found': results.value.length,
      'Total Docs Found': results.value.filter((result) => result.item.type === SwmResourceType.Doc).length,
      'Total Playlists Found': results.value.filter((result) => result.item.type === SwmResourceType.Playlist).length,
    });
  }
}, 400);

async function handleSearch(value) {
  searchQuery.value = value;
  if (searchQuery.value) {
    loading.value = true;
    await searchGlobally();
  } else {
    loading.value = false;
  }
}

function navigateToRecentlyCreated(recentlyCreatedFile) {
  closeSearchModal();
  const container = recentlyCreatedFile.type === 'playlist' ? 'playlists' : 'docs';
  const link = `${getRepoPath(
    route.params.workspaceId,
    recentlyCreatedFile.repoId,
    recentlyCreatedFile.branch
  )}/${container}/${recentlyCreatedFile.srcId}`;
  router.push(link);
}
function closeSearchModal() {
  resetSearch();
  emit('close');
}

function searchResultSelected(result, index) {
  analytics.track(productEvents.CLICKED_SEARCH_RESULT, {
    'Repo ID': result.repoId,
    'Workspace ID': route.params.workspaceId,
    'Result ID': result.item.id,
    'Result Type': result.item.type,
    'Search Result Index': index,
    'Total Repos Selected': props.showRepoSelector ? selectedRepoIdsForSearch.value.length : 1,
    'Was Repo Selection Changed': props.showRepoSelector ? wasRepoSelectionChanged() : undefined,
  });
  closeSearchModal();
}

function wasRepoSelectionChanged(): boolean {
  const arr1 = [...selectedRepoIdsForSearch.value].sort();
  const arr2 = [...initialSelectedRepoIdsForSearch.value].sort();
  if (arr1.length !== arr2.length) {
    return true;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return true;
    }
  }
  return false;
}

const searchPlaceholder = computed(() => {
  if (globalSearchLimitToRepo.value) {
    return `Search ${repoFilterData.value.repoName} (${repoFilterData.value.repoBranch})`;
  }
  return `Search ${workspace.value.name}`;
});
const repoFilterData = computed(() => {
  if (globalSearchLimitToRepo.value) {
    const repo = getRepoMetadata(globalSearchLimitToRepo.value.repoId);
    return { repoName: repo?.name ?? 'Unknown repo', repoBranch: globalSearchLimitToRepo.value.branch };
  }
  return null;
});

function resetSearch() {
  if (input?.value) {
    input.value.focus();
  }
  searchQuery.value = '';
  results.value = [];
  loading.value = false;
}

function selectedReposChanged(repoIds: string[]) {
  selectedRepoIdsForSearch.value = [...repoIds];
}

const repoSelectorWarning = computed(() => {
  if (selectedRepoIdsForSearch.value.length > 5) {
    return 'Search time might increase with a lot of repos.';
  }
  if (selectedRepoIdsForSearch.value.length === 0) {
    return 'No repos selected. Make sure to select repos to get results';
  }
  return null;
});

function repoSelectorClosed() {
  if (searchQuery.value) {
    handleSearch(searchQuery.value);
  }
}

const showCurrentBranchText = computed(() => {
  const currentRepoId = route.params.repoId as string;
  if (!currentRepoId || !selectedRepoIdsForSearch.value.includes(currentRepoId)) {
    return false;
  }
  const currentRepoDefaultBranch = reposStateData.value[currentRepoId]?.defaultBranch;
  return currentRepoDefaultBranch && currentRepoDefaultBranch !== route.params.branch;
});
</script>

<template>
  <SwModal
    :show-modal="props.show"
    :padded="false"
    :show-close-button="false"
    @close="closeSearchModal"
    :header-border="false"
    align-to-top
  >
    <div>
      <div class="search-modal-wrapper" @keydown="scrollItems" @focusin="inputFocus" @focusout="inputDefocus">
        <div class="search-with-repo-selector">
          <TextField
            ref="input"
            data-testid="search-input"
            class="search-input"
            :model-value="searchQuery"
            focus-first
            :placeholder="searchPlaceholder"
            @update:model-value="handleSearch"
          >
            <template #left>
              <Icon class="search-icon" name="search" no-padding />
            </template>
            <template #right>
              <div class="right-icon">
                <SwText v-if="!searchQuery && !globalSearchLimitToRepo" variant="body-L" class="shortcut-icon">{{
                  shortcut
                }}</SwText>
                <Icon v-if="searchQuery.length" class="reset-icon" name="close" no-padding @click="resetSearch"></Icon>
              </div>
            </template>
          </TextField>
          <div class="repo-selector-wrapper" v-if="showRepoSelector">
            <BaseLayoutGap direction="row" size="small">
              <MultiRepoSelector
                :repos="accessibleRepos"
                :selected-repo-ids="selectedRepoIdsForSearch"
                :first-repo-id="route.params.repoId as string"
                @selection-changed="selectedReposChanged"
                @closed="repoSelectorClosed"
              />
              <SwText variant="body-XS" v-if="showCurrentBranchText" class="branch-text"
                >Branch: <code>{{ route.params.branch }}</code></SwText
              >
            </BaseLayoutGap>
            <SwText variant="body-XS" v-if="!!repoSelectorWarning" class="reop-selector-warning">
              <Icon name="warning" />
              {{ repoSelectorWarning }}
            </SwText>
          </div>
        </div>
        <SwText variant="body-S" class="content-title">{{ contentTitle }}</SwText>
        <template v-if="searchQuery || isIndexingSearchData">
          <div v-if="loading || isIndexingSearchData" class="skeletons">
            <SearchSkeletons />
          </div>
          <template v-else>
            <div v-if="results.length" class="content">
              <div
                v-for="(result, index) in results"
                :key="`${result.item.repoName}:${result.item.id}-${result.refIndex}`"
                ref="viewableResults"
              >
                <SearchResult
                  :result="result"
                  :repo-id="result.repoId"
                  :query="searchQuery"
                  :focused="index === focusedResultIndex"
                  @select="() => searchResultSelected(result, index)"
                />
              </div>
            </div>
            <template v-else>
              <SwText
                v-if="eligibleForSearch"
                variant="body-S"
                class="results-empty-state"
                data-testid="search-results-empty-state"
                >No results found.<br />Try different keywords.</SwText
              >
              <SwText v-else variant="body-S" class="results-empty-state" data-testid="search-results-empty-state"
                >To access code-coupled documentation in Swimm, authorize {{ providerDisplayName }} first.</SwText
              >
            </template>
          </template>
        </template>
        <template v-else-if="!globalSearchLimitToRepo">
          <div class="content" v-if="recentItemsToShow.length">
            <RecentlyViewedFile
              v-for="(result, index) in recentItemsToShow"
              :key="index"
              :viewed-file="result"
              @select="navigateToRecentlyCreated(result)"
            />
          </div>
          <template v-else>
            <SwText
              v-if="eligibleForSearch"
              variant="body-S"
              class="results-empty-state"
              data-testid="search-results-empty-state"
              >No recently viewed docs.</SwText
            >
            <SwText v-else variant="body-S" class="results-empty-state" data-testid="search-results-empty-state"
              >To access code-coupled documentation in Swimm, authorize {{ providerDisplayName }} first.</SwText
            >
          </template>
        </template>
      </div>
      <SwText v-if="searchQuery || isIndexingSearchData" variant="body-XS" class="shortcuts-bar" component="div">
        Navigate
        <Icon name="sort" class="shortcut-icon" /> Open
        <Icon name="enter" class="shortcut-icon" />
      </SwText>
    </div>
  </SwModal>
</template>

<style scoped lang="postcss">
.shortcuts-bar {
  background-color: var(--color-surface);
  padding: var(--space-base) var(--space-sm);

  .shortcut-icon {
    color: var(--text-color-disable);
    font-size: var(--fontsize-sm);
  }
}

.search-modal-wrapper {
  width: 800px;
  padding: var(--space-sm) 0;

  .search-with-repo-selector {
    margin-left: var(--space-sm);
    margin-right: var(--space-sm);
    margin-bottom: var(--space-md);

    .search-input {
      word-break: normal;
      margin-bottom: var(--space-md);

      .search-icon {
        opacity: 0.3;
        margin-right: var(--space-xs);

        left: -8px;
        position: relative;
        top: 1px;
      }

      .right-icon {
        margin-right: var(--space-base);

        .shortcut-icon {
          opacity: 0.3;
        }

        .reset-icon {
          cursor: pointer;
          position: relative;
          top: -1px;
        }
      }
    }

    .branch-text {
      margin-top: var(--space-xs);
    }

    .repo-selector-wrapper {
      display: flex;
      flex-direction: column;
      align-self: stretch;

      .reop-selector-warning {
        margin-top: var(--space-base);
        color: var(--color-text-warning);
      }
    }
  }

  .content-title {
    margin-bottom: var(--space-sm);
    margin-left: var(--space-sm);
    margin-right: var(--space-sm);
  }

  .content {
    display: flex;
    flex-direction: column;
    max-height: 60vh;
    overflow: auto;
  }

  .content,
  .skeletons {
    padding-left: var(--space-sm);
    padding-right: var(--space-sm);
  }

  .results-empty-state {
    line-height: 24px;
    text-align: center;
  }
}
</style>
