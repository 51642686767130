import type { SuggestionSorter } from '@/services/swimmport/SuggestionSorter';
import { sortPathSuggestionsByFamiliarity, sortSuggestionsByFamiliarity } from '@swimm/editor';
import type { SwimmEditorServices } from '@/tiptap/editorServices';
import type { PathSuggestion, TokenSuggestion } from '@swimm/shared';
import type { Editor } from '@tiptap/core';
import { type SnippetInfo, snippetNodeToSnippetInfo } from '@/composables/focusedSnippet';

export class SwimmDocumentSuggestionSorter implements SuggestionSorter {
  constructor(private readonly swimmEditorServices: SwimmEditorServices) {
    this.swimmEditorServices = swimmEditorServices;
  }

  private findParentSnippet(editor: Editor | undefined, pos: number): SnippetInfo | undefined {
    let snippetInfo: SnippetInfo | undefined = undefined;
    if (editor != null) {
      const $pos = editor.state.doc.resolve(pos);
      snippetInfo =
        $pos.node(-1)?.type.name === 'swmSnippet'
          ? snippetNodeToSnippetInfo($pos.node(-1), this.swimmEditorServices)
          : undefined;
    }
    return snippetInfo;
  }
  sortTokens(suggestions: TokenSuggestion[], pos: number): TokenSuggestion[] {
    const editor: Editor | undefined = this.swimmEditorServices.editor?.deref();
    const snippetInfo = this.findParentSnippet(editor, pos);

    return sortSuggestionsByFamiliarity({
      suggestions,
      docSmartElements: this.swimmEditorServices.swimmSmartElements.value,
      docRepoId: this.swimmEditorServices.repoId.value,
      parentSnippetInfo: snippetInfo,
    });
  }

  sortPaths(suggestions: PathSuggestion[], pos: number): PathSuggestion[] {
    const editor: Editor | undefined = this.swimmEditorServices.editor?.deref();
    const snippetInfo = this.findParentSnippet(editor, pos);

    return sortPathSuggestionsByFamiliarity({
      suggestions: suggestions as PathSuggestion[],
      docRepoId: this.swimmEditorServices.repoId.value,
      parentSnippetInfo: snippetInfo,
      docSmartElements: this.swimmEditorServices.swimmSmartElements.value,
    });
  }
}
