import * as Sentry from '@sentry/vue';
import { getLoggerNew } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

function startSentryTransaction(sentryContext) {
  try {
    return Sentry.startTransaction(sentryContext);
  } catch (err) {
    logger.error({ err }, `Failed to start Sentry transaction: ${err}`);
    return null;
  }
}

/**
 * Measure the time segment takes
 * @param {*} {name} - the module name for transaction tracking
 * @param {*} timedFunc - the function we want to track & measure
 */
export async function measurePerformance({ name }, timedFunc) {
  logger.debug(`Starting Sentry transaction for ${name}`);
  const transaction = startSentryTransaction({ op: name, name: `${name}` });
  try {
    await timedFunc();
  } finally {
    finishTransaction(transaction);
  }
}

export function finishTransaction(sentryTransaction) {
  if (!sentryTransaction) {
    return;
  }
  logger.debug('Finalizing Sentry transaction');
  sentryTransaction.finish();
}
