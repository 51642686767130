import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import ky, { HTTPError } from 'ky';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { RecaptchaActionName, getLoggerNew } from '@swimm/shared';
import { CLOUD_FUNCTIONS_HOST, RECAPTCHA_SITE_KEY, isEmulated } from '@/config';
import { uint8ArrayToHex } from 'uint8array-extras';

const logger = getLoggerNew(__modulename);
let lastTokenRefreshCheckTime = 0;
const MAX_TIME_BETWEEN_TOKEN_REFRESH_CHECKS = 5 * 60 * 1000; // 5 minutes
const SIGNUP_ENDPOINT = `${CLOUD_FUNCTIONS_HOST}/signupFirebaseUserWithPassword`;
const SIGNIN_ENDPOINT = `${CLOUD_FUNCTIONS_HOST}/signInFirebaseUserWithPassword`;

export async function isPasswordValid(password: string) {
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  if (!passwordRegex.test(password)) {
    return false;
  }

  if (!(await checkPasswordWithPwnedPasswords(password))) {
    return false;
  }

  return true;
}

async function checkPasswordWithPwnedPasswords(password: string) {
  try {
    const hash = uint8ArrayToHex(
      new Uint8Array(await crypto.subtle.digest('SHA-1', new TextEncoder().encode(password)))
    ).toUpperCase();
    const prefix = hash.slice(0, 5);
    const suffix = hash.slice(5);
    const resp = await ky(`https://api.pwnedpasswords.com/range/${encodeURIComponent(prefix)}`, {
      headers: { 'Add-Padding': 'true' },
    }).text();

    const suffixes = new Map(
      resp.split('\r\n').map((value) => {
        const split = value.split(':', 2);
        return [split[0], parseInt(split[1], 10)];
      })
    );

    const found = suffixes.get(suffix);
    if (found != null && found > 0) {
      return false;
    }

    return true;
  } catch (err) {
    // network error,
    if (err instanceof TypeError && err.message === 'Failed to fetch') {
      logger.warn({ err }, 'Network error when trying to check if the password leaked, not checking');
    } else if (err instanceof HTTPError) {
      logger.warn({ err }, 'Server error when trying to check if the password leaked, not checking');
    } else {
      logger.error(
        { err },
        `A bug trying to check if the password leaked, not checking but this shouldn't happen, need to investigate`
      );
    }

    return true;
  }
}

export function getEmailHostedDomain(email: string) {
  const emailParts = email.split('@');
  if (emailParts.length !== 2) {
    return null;
  }
  return emailParts[1];
}

export async function isTokenRevoked() {
  try {
    if (isEmulated || Date.now() - lastTokenRefreshCheckTime < MAX_TIME_BETWEEN_TOKEN_REFRESH_CHECKS) {
      logger.debug('Skipping token refresh check');
      return false;
    }
    logger.debug('Checking if token is revoked...');
    await CloudFunctions.getAuthToken(true);
    lastTokenRefreshCheckTime = Date.now();
    return false;
  } catch (err) {
    // Check if the error is due to no internet connection. If so, skip the token refresh check.
    // Notice that the token-revoked error should be "auth/user-token-expired", but since we get it from Firebase, they might change it one day.
    // Therefore, we only "allow" the network-request-failed error.
    if (err.message.includes('auth/network-request-failed')) {
      logger.info('Skipping token refresh check due to no internet connection');
      return false;
    }
    return true;
  }
}

export async function loginUserWithPassword({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<firebase.auth.UserCredential> {
  return authenticateUserPasswordThroughBackend({
    action: RecaptchaActionName.SIGN_IN_WITH_PASSWORD,
    email,
    endpoint: SIGNIN_ENDPOINT,
    password,
  });
}

export async function registerUserWithPassword({
  displayName,
  email,
  password,
}: {
  displayName: string;
  email: string;
  password: string;
}): Promise<firebase.auth.UserCredential> {
  return authenticateUserPasswordThroughBackend({
    action: RecaptchaActionName.SIGN_UP_PASSWORD,
    displayName,
    email,
    endpoint: SIGNUP_ENDPOINT,
    password,
  });
}

/**
 * Authenticate a user with email/password using the backend.
 * The function will also use reCAPTCHA explicitly to verify the user is not a bot.
 * @param action for reCAPTCHA. Either sign-in or sign-up
 * @param displayName the user's display name (only for signup)
 * @param email the user's email
 * @param endpoint the endpoint to call for the backend
 * @param password the user's password
 */
async function authenticateUserPasswordThroughBackend({
  action,
  displayName,
  email,
  endpoint,
  password,
}: {
  action: RecaptchaActionName.SIGN_UP_PASSWORD | RecaptchaActionName.SIGN_IN_WITH_PASSWORD;
  displayName?: string;
  email: string;
  endpoint: string;
  password: string;
}): Promise<firebase.auth.UserCredential> {
  const recaptchaToken = isEmulated
    ? 'OVERRIDDEN'
    : await grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, { action });
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      password,
      recaptchaToken,
      ...(displayName ? { displayName } : {}),
    }),
  });
  const parsedResponse = await response.json();
  if (!response.ok) {
    throw new Error(
      `${response.status}: ${response.statusText} - ${parsedResponse.error ?? `failed handling auth request`}`
    );
  }
  const customToken = parsedResponse.customToken;
  return await firebase.auth().signInWithCustomToken(customToken);
}
