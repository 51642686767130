<template>
  <div class="container" @click="$emit('click')">
    <div class="header">
      <SwAvatar :text="workspace.name" :src="workspace.logo" square hide-tooltip />
      <div class="name body-S">{{ workspace.name }}</div>
    </div>
  </div>
</template>

<script>
import { useCopyToClipboard } from '@swimm/editor';
import { SwAvatar } from '@swimm/ui';
import { useAppLinks } from '@/modules/core/compositions/app-links';

export default {
  components: { SwAvatar },
  props: {
    workspace: { type: Object, default: null },
  },
  emits: ['click'],
  setup() {
    const { getAppLink } = useAppLinks();
    const { copyToClipboard } = useCopyToClipboard();

    return { getAppLink, copyToClipboard };
  },
  methods: {
    copyLink() {
      const link = this.getAppLink(`/workspaces/${this.workspace.id}`);
      this.copyToClipboard(link);
    },
    displayCounterText(counterName, counterValue) {
      const displayCounterName = counterValue === 1 ? counterName : `${counterName}s`;
      return `${counterValue} ${displayCounterName}`;
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
}

.container:hover {
  background-color: var(--color-surface);
}

.name {
  overflow: hidden;
  margin-left: 10px;
  max-width: 25ch;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header {
  display: flex;
  align-items: center;
  max-width: 100%;
}
</style>
