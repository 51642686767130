<script setup lang="ts">
import { computed } from 'vue';
import { SwText } from '@swimm/ui';

const props = defineProps<{
  kind: 'VSCode' | 'JetBrains';
}>();
const emit = defineEmits<{
  (e: 'open-settings'): void;
}>();

const imageName = computed(() => (props.kind === 'VSCode' ? 'vscode-icon.svg' : 'IntelliJ-icon.svg'));
const imageUrl = computed(() => new URL(`../../assets/${imageName.value}`, import.meta.url).href);
const name = computed(() => (props.kind === 'VSCode' ? 'VS Code' : 'IntelliJ'));

function openSettings() {
  emit('open-settings');
}
</script>

<template>
  <div class="plugin-link" @click="openSettings">
    <img :src="imageUrl" class="image" />
    <SwText variant="body-L">{{ name }}</SwText>
  </div>
</template>

<style scoped lang="postcss">
.plugin-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: var(--space-base);

  .image {
    height: 24px;
    margin-right: var(--space-base);
  }
}
</style>
