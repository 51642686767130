import { StatusCodes } from 'http-status-codes';
import { OpenAI } from 'openai';
import { OpenAIModelName } from '../../code-analysis';
import { RulesAiSuggestion, RulesAiUsecase } from '../../rules/types';
import { SwimmDocument } from '../swmd/document';
import { StiggFeatures } from '../billing';

export enum GenerativeAiRequestType {
  GENERATE_SNIPPET_COMMENT = 'generate_snippet_comment',
  GENERATE_MERMAID = 'generate_mermaid',
  GENERATE_TEXT_MODIFIER = 'generate_text_modifier',
  GENERATE_TEXT_COMPLETION = 'generate_text_completion',
  GENERATE_DOC_RULE_USECASES = 'generate_doc_rule_usecases',
  GENERATE_USECASE_RULE_SUGGESTIONS = 'generate_usecase_rule_suggestions',
  GENERATE_DOC_SUMMARY = 'generate_doc_summary',
  GENERATE_PR_TO_DOC = 'generate_pr_to_doc',
  IDE_GENERATE_SNIPPETS_TO_DOC = 'ide_generate_snippets_to_doc',
}

export type GenerativeAiRequestTypeValue = `${GenerativeAiRequestType}`;

export type GenerativeAiRequest =
  | GenerateSnippetCommentRequest
  | GenerateMermaidRequest
  | GenerateUsecaseRuleSuggestionsRequest
  | GenerateDocRuleUsecasesRequest
  | OpenAICallRequest
  | GeneratePRToDocRequest
  | GenerateDocSummaryRequest
  | GenerateTextCompletionRequest;
export type GenerativeAiResponse =
  | ({ status: 'success' } & (
      | GenerateSnippetCommentResponse
      | GenerateMermaidResponse
      | GenerateUsecaseRuleSuggestionsResponse
      | GenerateDocRuleUsecasesResponse
      | GenerateTextModifiersResponse
      | GeneratePRToDocResponse
      | GenerateDocSummaryResponse
      | GenerateTextCompletionResponse
    ))
  | { status: 'error'; code?: StatusCodes };

export interface GenerativeAiRequestBase {
  type: GenerativeAiRequestType;
  repoId: string;
  workspaceId: string;
}

export interface GenerateSnippetCommentRequest extends GenerativeAiRequestBase {
  type: GenerativeAiRequestType.GENERATE_SNIPPET_COMMENT;
  snippetContent: string;
}
export interface GenerateMermaidRequest {
  type: GenerativeAiRequestType.GENERATE_MERMAID;
  repoId: string;
  workspaceId: string;
  before: string;
  after: string;
  userPrompt: string;
  selectedSample: { label: string; content: string } | null;
}
export interface GenerateTextCompletionRequest extends GenerativeAiRequestBase {
  type: GenerativeAiRequestType.GENERATE_TEXT_COMPLETION;
  prompt: TextCompletionPrompt | ChatTextCompletionPrompt;
  textCompletionParams: TextCompletionParams | ChatTextCompletionPrompt;
  repoId: string;
}

export interface GenerateTextModifiersRequest extends GenerativeAiRequestBase {
  type: GenerativeAiRequestType.GENERATE_TEXT_MODIFIER;
  modifier: string;
  textInput: string;
}

export interface GenerateDocRuleUsecasesRequest extends GenerativeAiRequestBase {
  type: GenerativeAiRequestType.GENERATE_DOC_RULE_USECASES;
  userId?: string; // For analytics purposes.
  docId: string; // For error logging purposes.
  swmdContent: string;
}

export interface GenerateDocSummaryRequest extends GenerativeAiRequestBase {
  type: GenerativeAiRequestType.GENERATE_DOC_SUMMARY;
  userId?: string;
  docId: string;
  swmdContent: string;
}

export interface GenerateUsecaseRuleSuggestionsRequest extends GenerativeAiRequestBase {
  type: GenerativeAiRequestType.GENERATE_USECASE_RULE_SUGGESTIONS;
  userId?: string; // For analytics purposes.
  docId: string; // For error logging purposes.
  usecaseRawContent: string;
}

export interface SnippetData {
  file: string;
  lines: string[];
}

export interface GeneratePRToDocRequest extends GenerativeAiRequestBase {
  type: GenerativeAiRequestType.GENERATE_PR_TO_DOC;
  swimmDocument: SwimmDocument;
  workspaceId: string;
  repoId: string;
  requestId: string;
  snippetsText: string;
  customPrompt?: string;
  shouldCancel: () => boolean;
}

interface GenerativeAiResponseBase {
  type: GenerativeAiRequestType;
}

export interface GenerateSnippetCommentResponse extends GenerativeAiResponseBase {
  type: GenerativeAiRequestType.GENERATE_SNIPPET_COMMENT;
  generatedText: string;
}

export interface GenerateMermaidResponse extends GenerativeAiResponseBase {
  type: GenerativeAiRequestType.GENERATE_MERMAID;
  generatedMermaid: string;
}

export interface GenerateDocRuleUsecasesResponse extends GenerativeAiResponseBase {
  type: GenerativeAiRequestType.GENERATE_DOC_RULE_USECASES;
  usecases: RulesAiUsecase[];
}

export interface GenerateTextModifiersResponse extends GenerativeAiResponseBase {
  type: GenerativeAiRequestType.GENERATE_TEXT_MODIFIER;
  generatedText: string;
}

export interface GenerateUsecaseRuleSuggestionsResponse extends GenerativeAiResponseBase {
  type: GenerativeAiRequestType.GENERATE_USECASE_RULE_SUGGESTIONS;
  suggestions: RulesAiSuggestion[];
}

export interface GeneratePRToDocResponse extends GenerativeAiResponseBase {
  type: GenerativeAiRequestType.GENERATE_PR_TO_DOC;
  swimmDocument: SwimmDocument;
}

export interface GenerateDocSummaryResponse extends GenerativeAiResponseBase {
  type: GenerativeAiRequestType.GENERATE_DOC_SUMMARY;
  generatedText: string;
}

export interface GenerateTextCompletionResponse extends GenerativeAiResponseBase {
  type: GenerativeAiRequestType.GENERATE_TEXT_COMPLETION;
  cost: number;
  generatedText: string;
}

export enum GenerativeAIStreamingCloseReason {
  FINISHED_STREAMING = 'finished streaming',
  ENTITLEMENT_LIMIT_REACHED = 'limit reached',
  ERROR_FROM_CLIENT = 'Error from client websocket',
  STOPPED_FROM_CLIENT = 'client triggered stop generating',
}

export interface LLMResponseFormat {
  error?: string;
  generatedText?: string;
  usage?: OpenAI.Completions.CompletionUsage;
  code: StatusCodes;
}

export type LLMCodeCompletion = {
  completion?: string;
  tokens?: {
    total: number;
    prompt: number;
    completion: number;
  };
  payload?: object;
};

export type Prompt = {
  messages: OpenAI.Chat.ChatCompletionMessageParam[];
  options: Partial<OpenAI.Chat.ChatCompletionCreateParams>;
  workspaceId: string;
};

export type SnippetsToDocPrompt = {
  messages: Array<OpenAI.Chat.ChatCompletionMessageParam>;
  options: Partial<OpenAI.Chat.ChatCompletionCreateParams> & { max_tokens?: number; top_p?: number };
  workspaceId: string;
  requestId: string;
  shouldCancel?: () => boolean;
};

export interface LlmCallGenericRequest {
  prompt: Prompt;
  type: Exclude<GenerativeAiRequestType, GenerativeAiRequestType.GENERATE_TEXT_COMPLETION>;
  workspaceId: string;
  requestId: string;
}

export interface LlmCallTextCompletionRequest {
  type: GenerativeAiRequestType.GENERATE_TEXT_COMPLETION;
  workspaceId: string;
  requestId: string;
  textCompletionParams: TextCompletionParams;
}

export type OpenAICallRequest = LlmCallGenericRequest | LlmCallTextCompletionRequest;

export type OpenAICallRequestType = (requestParams: OpenAICallRequest) => Promise<LLMResponseFormat>;

export interface TextCompletionPrompt {
  prompt: string;
  suffix?: string;
  max_tokens: number;
  temperature: number;
  top_p?: number;
  n?: number;
  api_version?: string;
  model: OpenAIModelName;
  stop: string | null | Array<string>;
}

export interface TextCompletionParams {
  prompt: TextCompletionPrompt;
  contextBefore: string;
  suffix?: string;
  sentenceToComplete: string;
}

export interface ChatTextCompletionPrompt {
  messages: OpenAI.Chat.ChatCompletionMessageParam[];
  max_tokens: number;
  temperature: number;
  top_p: number;
  n: number;
  model: OpenAIModelName;
}

export interface TextCompletionRequest {
  openAIParameters: TextCompletionPrompt;
  workspaceId: string;
  requestId: string;
  textCompletionParams: TextCompletionParams;
}

// TODO: is this the correct place for this?
export function requestTypeToFeatureId(requestType: GenerativeAiRequestType): StiggFeatures | null {
  switch (requestType) {
    case GenerativeAiRequestType.GENERATE_DOC_RULE_USECASES:
    case GenerativeAiRequestType.IDE_GENERATE_SNIPPETS_TO_DOC:
      return StiggFeatures.GENERATIVE_AI_CAP;
    case GenerativeAiRequestType.GENERATE_TEXT_COMPLETION:
      return StiggFeatures.TEXT_COMPLETION_CAP;
    default:
      return null;
  }
}

export interface AiFeatureAccess {
  isOnPrem?: boolean;
  hasAccess: boolean;
  lastAccessCheckTimestamp?: number;
  lastUsageTimestamp?: number;
}

export interface AiFeaturesAccessData {
  [StiggFeatures.TEXT_COMPLETION_CAP]?: AiFeatureAccess;
  [StiggFeatures.ASK_SWIMM_CAP]?: AiFeatureAccess;
}

export type LLMTextCompletionRawResponse = {
  completionData: string;
  usage?: OpenAI.Completions.CompletionUsage;
};
