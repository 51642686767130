export function getRepoPath(workspaceId, repoId, branch) {
  let url = '';
  if (workspaceId) {
    url = `/workspaces/${workspaceId}`;
  }
  url += `/repos/${repoId}`;
  const branchSuffix = branch ? `/branch/${encodeURIComponent(branch)}` : '';
  return `${url}${branchSuffix}`;
}

export function isHomePage(path) {
  return /^\/workspaces\/[a-zA-Z0-9]*\/?$/.test(path);
}

export function getRouteToNewDocURL({ workspaceId, repoId, branch }) {
  let url = `/workspaces/${workspaceId}`;
  url += `/repos/${repoId}`;
  if (branch) {
    url += `/branch/${encodeURIComponent(branch)}`;
  }
  url += '/docs/new';
  return url;
}

export function getRouteToNewPlaylistURL({ workspaceId, repoId, branch }) {
  let url = `/workspaces/${workspaceId}`;

  url += `/repos/${repoId}`;
  if (branch) {
    url += `/branch/${encodeURIComponent(branch)}`;
  }
  url += '/playlists/new';
  return url;
}
