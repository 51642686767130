<template>
  <VDropdown placement="bottom-end" @show="showShare = true" @hide="showShare = false" :auto-hide="false">
    <div class="button-text">Share</div>
    <template #popper="{ hide, show }">
      <Share3 :show-share="showShare" @hide="hide" @show="show" v-bind="$attrs" />
    </template>
  </VDropdown>
</template>

<script setup lang="ts">
import Share3 from '@/common/components/TopBar/Share3.vue';
import { ref } from 'vue';

const showShare = ref(false);
</script>

<style scoped lang="postcss">
/* Don't change the class name to contain the word "share" due to adblockers hiding the button */
.button-text {
  cursor: pointer;
  padding: var(--space-base);
  border-radius: 4px;

  &:hover {
    background-color: var(--color-hover);
  }
}
</style>
