import type { JSONContent } from '@tiptap/core';

export const EMPTY_DOC_CONTENT: JSONContent = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
    },
  ],
};
