<template>
  <div class="banner-container">
    <SwText variant="body-S" weight="bold">Code in the remote branch has changed.</SwText>
    <SwText variant="body-S">Someone pushed changes to the remote branch while you were working.</SwText>
    <SwText variant="body-S"
      ><Action type="a" @click="emit('sync-doc')" class="sync-doc-button">Sync document</Action> with the new state to
      update it.</SwText
    >
  </div>
</template>

<script lang="ts" setup>
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';
import { Action } from '@swimm/ui';
import { onMounted } from 'vue';

const emit = defineEmits(['sync-doc']);
const analytics = useAnalytics();

onMounted(() => {
  analytics.track(productEvents.SHOWN_REMOTE_CODE_CHANGES_WARNING);
});
</script>

<style scoped lang="postcss">
.banner-container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-status-warning);
  color: var(--text-color-warning);
  border-radius: 4px;
  padding: 12px var(--space-sm);
  gap: var(--space-xs);

  .body-S {
    .sync-doc-button {
      color: var(--swimm-blue);
      padding: 0;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
</style>
