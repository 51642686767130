<script setup lang="ts">
import { inject } from 'vue';
import AIGenerationDisabledModalInner from '@/components/EditorComponents/AIGenerationDisabledModalInner.vue';

withDefaults(
  defineProps<{
    isAdmin?: boolean;
    modalOpened?: boolean;
  }>(),
  {
    isAdmin: false,
    modalOpened: false,
  }
);

defineEmits<{
  (e: 'close'): void;
}>();
const openSettingsCallback = inject('openSettingsCallback', () => {
  return null;
});
</script>

<template>
  <AIGenerationDisabledModalInner
    :modal-opened="modalOpened"
    :is-admin="isAdmin"
    :isIDE="false"
    @close="$emit('close')"
    @enable="openSettingsCallback"
  />
</template>
