<template>
  <div
    ref="menu"
    :class="{ inverse: inverse }"
    class="menu"
    :style="{
      top: YAxisPosition,
      left: `${XAxisPosition}px`,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

const DEFAULT_WIDTH = 0;

export default defineComponent({
  props: {
    cursorX: { type: Number, default: 0 },
    cursorY: { type: Number, default: 0 },
    inverse: { type: Boolean, default: false },
    openAbove: { type: Boolean, default: false },
    optionsLength: { type: Number, required: true },
  },
  emits: ['menu-mounted'],
  data() {
    return {
      height: 0,
      width: DEFAULT_WIDTH,
    };
  },
  computed: {
    YAxisPosition() {
      if (this.openAbove) {
        return `${this.cursorY - this.height - 10}px`;
      } else {
        return `${this.cursorY + 20}px`;
      }
    },
    XAxisPosition() {
      if (this.cursorX + this.width > window.innerWidth) {
        return window.innerWidth - (this.width + 30);
      }
      return this.cursorX;
    },
    menu() {
      return this.$refs.menu;
    },
  },

  mounted() {
    this.$emit('menu-mounted', this.$refs.menu.clientHeight);
    this.setHeight();
    this.setWidth();
    this.$watch('optionsLength', () => {
      this.setHeight();
      this.setWidth();
      this.$emit('menu-mounted', this.$refs.menu.clientHeight);
    });
  },
  methods: {
    setHeight() {
      this.$nextTick(() => {
        if (!this.$refs.menu) {
          return;
        }
        this.height = this.$refs.menu.clientHeight;
      });
    },
    setWidth() {
      this.$nextTick(() => {
        if (!this.$refs.menu) {
          return;
        }
        this.width = this.$refs?.menu?.clientWidth ?? DEFAULT_WIDTH;
      });
    },
  },
});
</script>

<style scoped>
.menu {
  position: fixed;
  z-index: 201;
  font-size: var(--body-S);
  border: 2px solid var(--color-border-default-subtle);
  border-radius: 5px;
  color: var(--text-color-secondary);
  background: var(--color-bg);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4); /* Need to update swimm-ui (--box-shadow-medium) if this is indeed the design */
  transition: opacity 0.2s, visibility 0.2s;
}

.inverse {
  color: var(--text-color-primary);
  background: var(--color-surface);
}
</style>
