<script setup>
import BillingContact from '@/modules/billing/components/BillingContact.vue';
import PaymentInfo from '@/modules/billing/components/PaymentInfo.vue';
import WorkspacePlanCard from '@/modules/billing/components/WorkspacePlanCard.vue';
import SettingsModalSection from '@/modules/core/components/settings-modal/SettingsModalSection.vue';
import { Licenses } from '@swimm/shared';
import { Divider } from '@swimm/ui';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useBillingStore } from '@/modules/billing/store/billing';

const store = useStore();
const route = useRoute();
const { isOnTrialPlan } = useBillingStore();
const emit = defineEmits(['change-plan']);

const isFreeWorkspace = computed(
  () => store.getters['database/db_getWorkspaceLicense'](route.params.workspaceId) === Licenses.FREE
);

function changePlan() {
  emit('change-plan');
}
</script>

<template>
  <SettingsModalSection title="Plan details">
    <WorkspacePlanCard @change-plan="changePlan" />
  </SettingsModalSection>
  <template v-if="!isFreeWorkspace && !isOnTrialPlan">
    <Divider class="divider" />
    <div class="subscription-info-section">
      <div class="credit-card-section">
        <SettingsModalSection title="Payment information">
          <PaymentInfo />
        </SettingsModalSection>
      </div>
      <div class="billing-section">
        <SettingsModalSection title="Billing contact">
          <BillingContact />
        </SettingsModalSection>
      </div>
    </div>
  </template>
</template>

<style scoped lang="postcss">
.divider {
  margin: var(--space-lg) 0;
}

.subscription-info-section {
  display: flex;
  gap: var(--space-lg);

  .credit-card-section {
    flex: 1;
  }

  .billing-section {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>
