<script setup lang="ts">
import InstanceNotification from '@/modules/notifications-center/components/InstanceNotification.vue';
import { computed } from 'vue';

const props = defineProps({
  notification: { type: Object, required: true },
});

const title = computed(
  () => `Your doc ${props.notification.topic_name} was read ${props.notification.email_template_data.viewCount} times!`
);
</script>

<template>
  <InstanceNotification :title="title" :add-notifier-to-title="false" :notification="notification" />
</template>
