import { updateWorkspaceOnFirestore } from '@/modules/core/services/workspace';

export const WORKSPACE_ONBOARDING_STEPS_KEY = 'onboarding_steps';

export const WorkspaceOnboardingSteps = {
  BOOK_DEMO_BANNER: 'demo_banner',
};

export async function updateWorkspaceOnboardingStep(
  workspaceId: string,
  stepId: string,
  value: { dismiss: boolean }
): Promise<void> {
  await updateWorkspaceOnFirestore(workspaceId, { [WORKSPACE_ONBOARDING_STEPS_KEY]: { [stepId]: value } });
}
