<script setup lang="ts">
import { SwText } from '@swimm/ui';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { computed, ref } from 'vue';
import FolderPath from '@/modules/folders/components/FolderPath.vue';
import MoveFolderModal from '@/modules/folders/components/MoveFolderModal.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';
import { useRoute } from 'vue-router';
import { DocumentationTypeToFolderItemType } from '../types';

const props = defineProps<{
  embedded?: boolean;
  resource: { id?: string; draftId?: string; isNew?: boolean; folderId?: string };
  type: keyof typeof DocumentationTypeToFolderItemType;
  repoId: string;
  draftFolderId?: string;
}>();

defineEmits(['folder-updated']);

const analytics = useAnalytics();
const route = useRoute();
const { isFoldersReady, getItemParentFolder3, getFolderPath } = useFoldersStore();

const showMoveFolderModal = ref(false);

const folderIdToUse = computed(() => {
  const folder = getItemParentFolder3(props.resource.id, props.repoId);
  if (folder?.is_root) {
    return null;
  }
  return folder?.id;
});

// TODO: We give precedence to the folderId in resource in case it exists for backward compatabiltiy.
const resourceToMove = computed(() => [
  { folderId: folderIdToUse.value, ...props.resource, documentationType: props.type },
]);

function openFolderSelection() {
  if (props.embedded) {
    return;
  }

  showMoveFolderModal.value = true;
  analytics.track(
    productEvents.CLICKED_RESOURCE_FOLDER_SELECTION,
    {
      'Entity ID': props.resource.id,
      'Current Folder ID': folderIdToUse.value,
      'Current Folder Path': folderIdToUse.value ? getFolderPath(folderIdToUse.value, props.repoId) : null,
      'Page Name': route.name,
    },
    { addRouteParams: true }
  );
}
</script>

<template>
  <div
    :class="['resource-folder', !embedded && 'clickable']"
    @click="openFolderSelection"
    v-if="isFoldersReady(repoId as string)"
  >
    <FolderPath v-if="folderIdToUse" :folder-id="folderIdToUse" :repo-id="repoId" show-folder-icon />
    <div v-else-if="!embedded && (props.resource.id || props.resource.draftId)" class="select-folder">
      <Icon name="folder" />
      <SwText variant="body-XS">Select folder</SwText>
    </div>
  </div>
  <MoveFolderModal
    :show="showMoveFolderModal"
    :repo-id="repoId"
    @change="$emit('folder-updated', $event)"
    @close="showMoveFolderModal = false"
    :items="resourceToMove"
  />
</template>

<style scoped lang="postcss">
.resource-folder {
  padding-right: var(--space-base);
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;

  &:hover {
    background-color: var(--color-hover);
  }

  .select-folder {
    display: flex;
    align-items: center;
  }
}
</style>
