import { setGlobalContext } from '@swimm/shared';
import { setWorkersGlobalContext } from '@/workers';

export interface WebLoggerContext {
  uid?: string;
}

export function setWebGlobalContext(ctx: WebLoggerContext) {
  setGlobalContext(ctx);
  setWorkersGlobalContext(ctx);
}
