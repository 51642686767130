<script setup lang="ts">
import { computed } from 'vue';
import { Icon } from '@swimm/ui';

const props = defineProps<{ views: number }>();

const tooltip = computed(() => `${props.views} views`);
</script>

<template>
  <div v-tooltip="tooltip" class="views-wrapper">
    <Icon name="eye" class="views-icon" />
    <span class="views-count" data-testid="views-count">{{ views }}</span>
  </div>
</template>

<style scoped lang="postcss">
.views-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: default;

  .views-icon {
    padding: 0 4px;
  }
}
</style>
