import type { AutosyncInput, DynamicPatchFile, MultiDynamicPatch, SmartSymbol } from '@swimm/shared';
import * as diffParser from '@swimm/shared';
import { SmartElementType, getLoggerNew, gitwrapper, isFilePatchApplicable } from '@swimm/shared';
import { verifySymbols } from './symbol-tracker';

const logger = getLoggerNew("packages/swimmagic/src/applicability-utils.ts");

/**
 * Checks if a autosyncInput is applicable by reconstructing the patch and checking the applicability
 * @return {boolean} - true if the reconstructed patch is applicable and false if the patch is not applicable or the diff string for the autosyncInput could not be reconstructed at all
 * @param autosyncInput - the autosyncable items to check applicability status
 * @param repoId - the repo ID containing resource
 * @param currentBranch - the current relevant branch
 * @param shouldSyncCrossRepo - whether we should sync cross repo snippets or ignore them
 */
export async function isAutosyncInputApplicable(
  autosyncInput: AutosyncInput,
  resourceId: string,
  repoId: string,
  currentBranch: string,
  shouldSyncCrossRepo: boolean
): Promise<boolean> {
  if (autosyncInput.symbols?.length && !(await areSymbolsApplicable(autosyncInput.symbols))) {
    logger.info(`Resource ${resourceId}'s symbols are not all verified`, { service: 'applicability-service' });
    return false;
  }

  if (!autosyncInput.snippets?.length) {
    return true;
  }

  const multiDynamicPatch: MultiDynamicPatch = await diffParser.autosyncSnippetsToDynamicPatch({
    snippets: autosyncInput.snippets,
  });

  const fileApplicabilityPromises: ReturnType<typeof isDynamicFilePatchApplicable>[] = [];
  for (const currentRepoId of Object.keys(multiDynamicPatch)) {
    for (const file in multiDynamicPatch[currentRepoId]) {
      fileApplicabilityPromises.push(
        isDynamicFilePatchApplicable({
          dynamicFileDiff: multiDynamicPatch[currentRepoId][file],
          fileRepoId: multiDynamicPatch[currentRepoId][file].hunkContainers[0].repoId,
          repoId,
          revision: currentBranch,
          shouldSyncCrossRepo,
          filePath: file,
        })
      );
    }
  }
  const hunkApplicabilityResults = await Promise.all(fileApplicabilityPromises);
  return hunkApplicabilityResults.every((applicable) => applicable);
}

export async function isDynamicFilePatchApplicable({
  dynamicFileDiff,
  revision,
  fileRepoId,
  repoId,
  shouldSyncCrossRepo,
  filePath,
}: {
  dynamicFileDiff: DynamicPatchFile;
  fileRepoId: string;
  repoId: string;
  revision: string;
  shouldSyncCrossRepo: boolean;
  filePath: string;
}) {
  let branch = revision;
  if (repoId !== fileRepoId) {
    if (!shouldSyncCrossRepo) {
      return true;
    }
    // If no access to cross repo, then consider as true
    if (!(await hasAccessToCrossRepo(fileRepoId))) {
      return true;
    }
    branch = undefined; // Explicitly make other repo use its default branch
  }

  return await isFilePatchApplicable({
    dynamicPatchFile: dynamicFileDiff,
    filePath,
    repoId: fileRepoId,
    branch,
  });
}

/**
 * Return whether all the symbols are currently verified or not.
 * I there is an autosynced token that is verified, will return true
 * @param symbols autosync symbols to check for applicability.
 */
async function areSymbolsApplicable(symbols: SmartSymbol[]): Promise<boolean> {
  try {
    return await verifySymbols(symbols);
  } catch (err) {
    logger.error({ err });
    return false;
  }
}

export function isCrossRepoAutosyncInput({ autosyncInput, repoId }: { autosyncInput: AutosyncInput; repoId: string }) {
  const snippetsResult =
    autosyncInput.snippets && autosyncInput.snippets.some((snippet) => snippet.gitInfo?.repoId !== repoId);

  if (snippetsResult) {
    return snippetsResult;
  }

  return autosyncInput.symbols && autosyncInput.symbols.some((symbol) => isCrossRepoSymbol({ repoId, symbol }));
}

function isCrossRepoSymbol({ symbol, repoId }: { symbol: SmartSymbol; repoId: string }): boolean {
  if (symbol.type === SmartElementType.TOKEN || symbol.type === SmartElementType.PATH) {
    return symbol.gitInfo?.repoId !== repoId;
  }
  return false;
}

/**
 *
 * @param crossRepoId {string}
 * check is cross repoId is accessible
 */

export async function hasAccessToCrossRepo(crossRepoId: string) {
  try {
    const remoteData = await gitwrapper.getRepoRemoteData({ repoId: crossRepoId });
    return !!remoteData;
  } catch (err) {
    return false;
  }
}
