<template>
  <BaseCard class="card-container">
    <div>
      <SwText variant="headline2">Join your team on Swimm</SwText>
      <SwText variant="body-S" class="subtitle"
        >Showing workspaces for <strong>{{ user.email }}</strong></SwText
      >
    </div>
    <div v-if="loadingWorkspaces" class="workspaces-rows-container">
      <WorkspaceRow v-for="index in 3" class="workspace-row-skeleton" :key="index" />
    </div>
    <div v-else class="workspaces-rows-container">
      <WorkspaceRow
        v-for="invite of invites"
        :key="invite.id"
        :title="invite.name"
        :subtitle="`${invite.counter_workspace_users} users`"
        :highlight="invite.id === workspaceId"
      >
        <Action
          v-if="isWorkspaceJoined(invite.id)"
          class="action"
          :disabled="isWorkspaceJoiningIsLoading(invite.id)"
          secondary
          size="small"
          @click="onContinueClick(invite.id, invite.name)"
          >Continue</Action
        >
        <Action
          v-else
          class="action"
          :loading="isWorkspaceJoiningIsLoading(invite.id)"
          :disabled="isWorkspaceJoiningIsLoading(invite.id)"
          size="small"
          @click="onJoinClick({ workspaceId: invite.id, workspaceName: invite.name, isAutoJoin: false })"
          >Join</Action
        >
      </WorkspaceRow>
      <WorkspaceRow
        v-for="(workspace, index) in autoJoinableWorkspaces"
        :key="index"
        :title="workspace.name"
        :subtitle="`${workspace.usersCount} users`"
        :highlight="workspace.id === workspaceId"
      >
        <Action
          v-if="isWorkspaceJoined(workspace.id)"
          class="action"
          :disabled="isWorkspaceJoiningIsLoading(workspace.id)"
          secondary
          size="small"
          @click="onContinueClick(workspace.id, workspace.name)"
          >Continue</Action
        >
        <Action
          v-else-if="isExceedingUserLimit(workspace.id)"
          class="action"
          :disabled="isWorkspaceRequestedToJoin(workspace.id) || loading"
          size="small"
          @click="onRequestToJoinClick(workspace.id)"
          >{{ isWorkspaceRequestedToJoin(workspace.id) ? 'Request sent' : 'Request to join' }}</Action
        >
        <Action
          v-else
          class="action"
          :loading="isWorkspaceJoiningIsLoading(workspace.id)"
          :disabled="isWorkspaceJoiningIsLoading(workspace.id)"
          size="small"
          @click="onJoinClick({ workspaceId: workspace.id, workspaceName: workspace.name, isAutoJoin: true })"
          >Join</Action
        >
      </WorkspaceRow>
      <WorkspaceRow
        v-for="(workspace, index) in uniqueRepoWorkspaces"
        :key="index"
        :title="workspace.name"
        :subtitle="`${workspace.counter_workspace_users} users`"
        :highlight="workspace.id === workspaceId"
      >
        <Action
          class="action"
          :disabled="isWorkspaceRequestedToJoin(workspace.id) || loading"
          size="small"
          @click="onRequestToJoinClick(workspace.id)"
          >{{ isWorkspaceRequestedToJoin(workspace.id) ? 'Request sent' : 'Request to join' }}</Action
        >
      </WorkspaceRow>
      <template v-if="!hasInvites">
        <WorkspaceRow
          v-for="workspace in companyWorkspaces"
          :key="workspace.id"
          :title="workspace.name"
          :subtitle="`${workspace.usersCount} users`"
          :highlight="workspace.id === workspaceId"
        >
          <Action
            class="action"
            :disabled="isWorkspaceRequestedToJoin(workspace.id) || loading"
            size="small"
            @click="onRequestToJoinClick(workspace.id)"
            >{{ isWorkspaceRequestedToJoin(workspace.id) ? 'Request sent' : 'Request to join' }}</Action
          >
        </WorkspaceRow>
      </template>
      <HorizontalSpacer />
      <WorkspaceRow title="Create a new workspace" icon-name="add">
        <Action class="action" :disabled="loading" size="small" secondary @click="onCreateNewClick">Create new</Action>
      </WorkspaceRow>
    </div>
    <Action v-if="showContinueButton" @click="$emit('continue-to-workspace')">Continue</Action>
    <LogoutFooter origin="Your Swimm Workspaces" try-different-prefix-string="Not seeing what you are looking for?" />
  </BaseCard>
</template>

<script>
import HorizontalSpacer from '@/common/components/atoms/HorizontalSpacer.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import LogoutFooter from '@/common/pages/JoinWorkspace/LogoutFooter.vue';
import WorkspaceRow from '@/common/pages/JoinWorkspace/WorkspaceRow.vue';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import { acceptInvite, requestInvite } from '@/common/utils/workspace-utils';
import BaseCard from '@/modules/core/components/BaseCard.vue';
import { pageEvents, productEvents } from '@swimm/shared';
import swal from 'sweetalert';
import { mapActions, mapGetters } from 'vuex';
import { setUserFieldInDB } from '@/common/utils/user-utils';
import { PageRoutesNames, localStorageKeys } from '@/common/consts';
import { Action, SwText } from '@swimm/ui';
import LocalStorage from '@/local-storage';
import { UserOnboardingFields, objectUtils, state } from '@swimm/shared';
import { LicenseToPlan, PlansLimits, TieredFeatures } from '@swimm/shared';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';

const WorkspaceRowState = {
  JOINING_IS_LOADING: 'Joining_is_loading',
  JOINED: 'joined',
  REQUESTED_TO_JOIN: 'requested_to_join',
};

export default {
  components: { SwText, BaseCard, WorkspaceRow, LogoutFooter, HorizontalSpacer, Action },
  props: {
    loadingWorkspaces: { type: Boolean, default: false },
    workspaceId: { type: String, default: '' },
    showContinueButton: { type: Boolean, default: false },
    invites: { type: Array, required: true },
    companyWorkspaces: { type: Array, required: true },
    autoJoinableWorkspaces: { type: Array, default: () => [] },
    repoWorkspaces: { type: Array, default: () => [] },
  },
  emits: ['joined', 'continue-to-workspace'],
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const { setFirstWorkspace } = useUserConfigStore();
    const analytics = useAnalytics();

    return { user, analytics, setFirstWorkspace };
  },
  data() {
    return {
      loading: true,
      workspacesStatus: {},
    };
  },
  computed: {
    ...mapGetters('database', ['db_getUserWorkspaces']),
    hasInvites() {
      return this.invites.length > 0;
    },
    workspacesList() {
      return this.db_getUserWorkspaces(this.user.uid);
    },
    uniqueRepoWorkspaces() {
      return this.repoWorkspaces.filter(
        (workspace) =>
          ![...this.invites, ...this.companyWorkspaces, ...this.autoJoinableWorkspaces].some(
            (w) => w.id === workspace.id
          )
      );
    },
    prevRoute() {
      const prevRouteUrl = this.$router.options.history.state.back;
      return prevRouteUrl ? this.$router.resolve({ path: prevRouteUrl })?.name : null;
    },
  },
  async created() {
    if (objectUtils.isEmpty(this.workspacesList)) {
      await state.set({ key: 'should_show_welcome_modal', value: true });
      LocalStorage.set(localStorageKeys.SHOULD_OPEN_GET_STARTED, true);
    }
    this.analytics.pageVisit(pageEvents.VIEW_YOUR_WORKSPACES, {
      'Invited workspaces counter': this.invites.length,
      'Company workspaces counter': this.companyWorkspaces.length,
      Type: 'Page',
    });

    this.markWorkspacesAsRequestedToJoin();
    this.loading = false;
  },
  methods: {
    ...mapActions('database', ['deleteWorkspaceFromInvitedWorkspaces', 'fetchSwimmerWorkspaces', 'fetchWorkspace']),
    markWorkspacesAsRequestedToJoin() {
      this.workspacesStatus = this.companyWorkspaces.reduce(
        (accWorkspacesStatus, currentWorkspace) => ({
          ...accWorkspacesStatus,
          ...(currentWorkspace.isRequestedToJoin && { [currentWorkspace.id]: WorkspaceRowState.REQUESTED_TO_JOIN }),
        }),
        {}
      );
    },
    onCreateNewClick() {
      this.reportActionClick(productEvents.CLICKED_CREATE_NEW);
      this.$router.push('/workspaces/create');
    },
    isWorkspaceJoined(workspaceId) {
      return this.workspacesStatus[workspaceId] === WorkspaceRowState.JOINED;
    },
    isWorkspaceJoiningIsLoading(workspaceId) {
      return this.workspacesStatus[workspaceId] === WorkspaceRowState.JOINING_IS_LOADING;
    },
    isWorkspaceRequestedToJoin(workspaceId) {
      return this.workspacesStatus[workspaceId] === WorkspaceRowState.REQUESTED_TO_JOIN;
    },
    async isFirstWorkspaceForUser() {
      await this.fetchSwimmerWorkspaces();
      return Object.keys(this.workspacesList).length === 1;
    },
    updateWorkspaceUserCount(workspaceId, isAutoJoin) {
      if (isAutoJoin) {
        this.autoJoinableWorkspaces.find((workspace) => workspace.id === workspaceId).usersCount++;
      } else {
        this.invites.find((workspace) => workspace.id === workspaceId).counter_workspace_users++;
      }
    },
    async acceptInvitationToWorkspace({ workspaceId, workspaceName, isAutoJoin }) {
      this.$logger.debug(`Joining workspace ${workspaceId}`, { service: 'join-workspace' });
      this.loading = true;
      this.assignStatusToWorkspace(workspaceId, WorkspaceRowState.JOINING_IS_LOADING);
      const result = await acceptInvite({ workspaceId, user: this.user, workspaceName });

      if (result.data) {
        await this.fetchWorkspace({ workspaceId }); // Get the workspace info after autoJoin
        await this.deleteWorkspaceFromInvitedWorkspaces({ workspaceId });
        this.assignStatusToWorkspace(workspaceId, WorkspaceRowState.JOINED);
        this.updateWorkspaceUserCount(workspaceId, isAutoJoin);
        try {
          if (await this.isFirstWorkspaceForUser()) {
            await setUserFieldInDB(this.user.uid, UserOnboardingFields.FIRST_WORKSPACE, workspaceId);
            await this.setFirstWorkspace({ workspaceId });
          }
        } catch (error) {
          this.$logger.error(`could not save user.firstWorkspace field to user: ${error}`, {
            service: 'join-workspace',
          });
        }
      } else {
        await swal({ title: `Could not join workspace`, content: SWAL_CONTACT_US_CONTENT() });
      }
      this.loading = false;
    },
    async onJoinClick({ workspaceId, workspaceName, isAutoJoin = false }) {
      this.reportActionClick(productEvents.CLICKED_JOIN_WORKSPACE);
      await this.acceptInvitationToWorkspace({
        workspaceId,
        workspaceName,
        isAutoJoin,
      });
      this.$emit('joined', workspaceId);
    },
    async onContinueClick(inviteWorkspaceId, inviteWorkspaceName) {
      this.analytics.workspaceChange({ workspaceId: inviteWorkspaceId, workspaceName: inviteWorkspaceName });
      if ([PageRoutesNames.ONBOARDING, PageRoutesNames.ONBOARDING_WELCOME].includes(this.prevRoute)) {
        this.navigateToAccountReadyPage(inviteWorkspaceId);
      } else {
        this.navigateToWorkspace(inviteWorkspaceId);
      }
    },
    onRequestToJoinClick(workspaceId) {
      requestInvite(workspaceId);
      this.assignStatusToWorkspace(workspaceId, WorkspaceRowState.REQUESTED_TO_JOIN);
    },
    assignStatusToWorkspace(workspaceId, status) {
      this.workspacesStatus = { ...this.workspacesStatus, [workspaceId]: status };
    },
    navigateToAccountReadyPage(workspaceId) {
      this.$router.push({ name: PageRoutesNames.ACCOUNT_READY, params: { workspaceId }, query: this.$route.query });
    },
    navigateToWorkspace(workspaceId) {
      this.$router.push(`/workspaces/${workspaceId}`);
    },
    reportActionClick(eventName) {
      this.analytics.track(eventName, {
        Origin: 'Your Workspaces',
      });
    },
    isExceedingUserLimit(workspaceId) {
      const workspace = this.autoJoinableWorkspaces.find((workspace) => workspace.id === workspaceId);
      if (workspace) {
        // TODO migrate to stigg in a logged out state somehow
        return PlansLimits[LicenseToPlan[workspace.license]][TieredFeatures.USER] <= workspace.usersCount;
      }
      return true;
    },
  },
};
</script>

<style scoped lang="postcss">
.card-container {
  margin: auto;
  gap: var(--space-lg);

  .subtitle {
    color: var(--text-color-secondary);
  }

  .workspaces-rows-container {
    display: flex;
    flex-direction: column;
    gap: var(--space-base);

    .workspace-row-skeleton {
      height: 50px;
      animation: skeleton 0.7s linear infinite alternate;
    }

    .action {
      flex-shrink: 0;
      white-space: nowrap;
    }
  }
}
</style>
