import { ref } from 'vue';
import { fileToBase64Data, readFile } from '../common/file-web-utils';

export interface FilePicked {
  path: string;
  webkitRelativePath: string;
  content: string | ArrayBuffer | null;
}

export interface FileToUpload extends File {
  name: string;
  type: string;
  size: number;
  message: string;
  data: Blob | FileToUpload;
  dataUrl: string;
}

export interface FilePickedParams {
  encode?: boolean;
  files: FileToUpload[] | File[];
}

export function useFileFormatter(shouldParseFiles: boolean) {
  const parsingFiles = ref(false);
  async function prepareFiles({ files, encode = true }: FilePickedParams) {
    if (parsingFiles.value) {
      return [];
    }
    parsingFiles.value = true;
    if (shouldParseFiles) {
      const fileObjects: FilePicked[] = [];
      for (const file of files) {
        const content: string | ArrayBuffer | null = encode ? await fileToBase64Data(file) : await readFile(file);
        fileObjects.push({
          path: file.name,
          webkitRelativePath: file.webkitRelativePath,
          content,
        });
      }
      parsingFiles.value = false;
      return fileObjects;
    } else {
      parsingFiles.value = false;
      return files;
    }
  }

  return {
    prepareFiles,
  };
}
