<template>
  <div
    class="tree"
    @keydown.up.stop="moveUp"
    @keydown.down.stop="moveDown"
    @keydown.left.stop="moveLeft"
    @keydown.right.stop="moveRight"
    @keydown.tab.stop="handleTab"
    @keydown.enter.stop="selectWithEnter"
    :tabindex="0"
  >
    <TreeViewHeader
      v-if="showRepoHeader"
      :repo-id="shouldShowRepos ? '' : repoId"
      :loading="loadingTree"
      :repo-metadata="repoMetadata"
      :add-show-repos="addShowRepos"
      :should-show-repos="shouldShowRepos"
      @refresh="refreshTree"
      @show-repos-clicked="showRepos"
    />

    <div v-else class="search-divider"></div>
    <TreeSearchBar
      :disabled="!repoFolderTree || loadingTree"
      v-if="showSearch && !shouldShowRepos"
      :model-value="search"
      @click="resetSelection"
      @update:model-value="
        (newValue) => {
          forceOpen = true;
          searching = true;
          search = newValue;
          resetSelection();
        }
      "
      :placeholder="searchPlaceholder"
    />
    <div class="tree-files-browsing" v-if="!shouldShowRepos">
      <TreeSearchResults
        v-if="search"
        :key="search"
        ref="searchResults"
        :results="searchResults"
        :limit="RESULTS_LIMIT"
        :show-file-level-action="showFileLevelAction"
        @result-select="nodeSelected"
        @clear-search="clearSearch"
      />
      <div v-else class="tree-container data-hj-suppress">
        <div class="nodes">
          <Loader v-if="loadingTree || loadingSelectedRepo" :no-flickering="true" />
          <div v-else-if="repoFolderTree" data-testid="nodes-container" class="nodes-container">
            <div
              class="repo-node"
              :class="{ 'repo-selected': isRepoNodeSelected }"
              data-testid="repo-node"
              @click="showRepos"
            >
              <Icon name="back-browsing" class="arrow" v-if="addShowRepos" />
              <Icon :name="repoMetadata.provider" class="provider-icon"></Icon>
              <span class="repo-name text-ellipsis">{{ repoName }}</span>
            </div>
            <TreeNode
              :node="repoFolderTree"
              class="root-node"
              path="/"
              :is-root="true"
              :isRepoNodeSelected="isRepoNodeSelected"
              :mark-nodes-with-units="markNodesWithUnits"
              :repo-id="repoId"
              :selected-path="selectedPath || currentSelectedNode?.path"
              @node-selected="nodeSelected"
              @node-toggle="nodeToggle"
              :force-open="forceOpen"
              @dblclick="selectWithEnter"
              :show-file-level-action="showFileLevelAction"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="tree-files-browsing">
      <UtilFocusItems @keydown.right.stop>
        <BaseLayoutGap direction="column" size="xsmall" alignment="stretch">
          <BaseInput
            type="search"
            v-model="filter"
            placeholder="Search repositories…"
            data-testid="swimm-repo-search"
          />
          <div>
            <MenuRepos
              :workspace-repos="filteredWorkspaceRepos"
              :searching="!!filter.length"
              @select-repo="onSelectRepo"
            />
          </div>
        </BaseLayoutGap>
      </UtilFocusItems>
    </div>
  </div>
</template>

<script>
import TreeSearchBar from './TreeSearchBar.vue';
import TreeSearchResults from './TreeSearchResults.vue';
import TreeViewHeader from './TreeHeader.vue';
import TreeNode from './TreeNode.vue';
import { Icon, Loader } from '@swimm/ui';
import { defineComponent, ref, watch } from 'vue';
import { useScroll } from '@/composables/scroll';
import { getGitProviderIconName } from '@swimm/shared';
import { BaseInput, BaseLayoutGap, MenuRepos, UtilFocusItems } from '@swimm/reefui';

const RESULTS_LIMIT = 500;

export default defineComponent({
  components: {
    TreeViewHeader,
    TreeSearchResults,
    TreeSearchBar,
    TreeNode,
    Loader,
    Icon,
    BaseInput,
    BaseLayoutGap,
    MenuRepos,
    UtilFocusItems,
  },
  props: {
    markNodesWithUnits: { type: Boolean, default: true },
    showSearch: { type: Boolean, default: false },
    shouldFocusOnRepo: { type: Boolean, default: true },
    searchIncludeDirs: { type: Boolean, default: false },
    status: { type: String, default: null },
    showRepoHeader: { type: Boolean, default: true },
    isSnippetStudio: { type: Boolean, default: false },
    selectedPath: { type: String, default: '' },
    repoId: { type: String, required: true },
    workspaceRepos: { type: Array, required: false, default: () => [] },
    loadingSelectedRepo: { type: Boolean, required: false, default: false },
    loadingTree: { type: Boolean },
    repoMetadata: { type: Object, required: false, default: null },
    repoFolderTree: { type: Object, required: false, default: null },
    showFileLevelAction: { type: Boolean, default: false },
  },
  emits: [
    'node-selected',
    'repo-selected',
    'repos-list-toggled',
    'repo-node-selected',
    'reset-selection',
    'refresh-tree',
    'apply-node-selection',
  ],
  setup(props, { emit }) {
    const workspaceReactiveRepos = ref([]);
    const search = ref();

    const { scrollToElement } = useScroll();
    const isRepoNodeSelected = ref(props.shouldFocusOnRepo);
    watch(
      isRepoNodeSelected,
      () => {
        emit('repo-node-selected', isRepoNodeSelected.value);
      },
      { immediate: true }
    );

    return { RESULTS_LIMIT, scrollToElement, isRepoNodeSelected, workspaceReactiveRepos, search };
  },
  data: function () {
    return {
      filter: '',
      searching: false,
      shouldShowRepos: false,
      currentSelectedNode: null,
      currentChildIndex: 0,
      currentSelectedRepo: 0,
      currentRoot: null,
      searchResults: [],
      forceOpen: false,
    };
  },
  computed: {
    repoName() {
      return this.repoMetadata?.name || 'Unknown repo';
    },
    addShowRepos() {
      return this.workspaceRepos.length > 1;
    },
    searchPlaceholder() {
      if (this.shouldShowRepos) {
        return 'Search repositories';
      } else {
        return `Search in ${this.repoName}`;
      }
    },
    hasSearchResults() {
      return this.searchResults.length > 0;
    },
    isParentRoot() {
      if (this.currentRoot) {
        return this.currentRoot.path === this.repoFolderTree.path;
      }
      return false;
    },
    filteredWorkspaceRepos() {
      return this.workspaceRepos
        .filter((repo) => repo.name.toLowerCase().includes(this.filter.toLowerCase()))
        .map((repo) => {
          return {
            ...repo,
            icon: this.getRepoIcon(repo),
            isFavourite: !!repo.isFavourite,
            isRecent: !!repo.isRecent,
            disabled: !!repo.disabled,
          };
        });
    },
  },
  watch: {
    repoFolderTree() {
      this.resetSelection();
      this.$emit('node-selected', this.currentSelectedNode);
    },
    selectedPath() {
      if (this.selectedPath && (this.isSnippetStudio || this.searching)) {
        this.currentSelectedNode = this.getNodeFromPath(this.selectedPath);
        if (!this.currentSelectedNode) {
          return;
        }
        this.currentRoot = this.getCurrentNodeParent(this.currentSelectedNode.path);
        this.currentChildIndex = this.getNodeIndex(this.currentSelectedNode, this.currentRoot);
        this.forceOpen = true;
      }
    },
  },
  created() {
    this.$watch('hasSearchResults', (newVal, oldVal) => {
      if (!oldVal) {
        if (oldVal !== newVal) {
          // When hasSearchResult turns from true to fals we want to emit the node-selected on the current selected node
          this.$emit('node-selected', this.currentSelectedNode);
        }
      }
    });
    this.$watch(
      'search',
      (value) => {
        this.performSearch();
        this.searchInReposList(value);
      },
      { immediate: true }
    );
  },
  mounted() {
    this.workspaceReactiveRepos = this.workspaceRepos;
    this.resetSelection();
    if (this.selectedPath && (this.isSnippetStudio || this.searching)) {
      this.currentSelectedNode = this.getNodeFromPath(this.selectedPath);
      this.currentRoot = this.getCurrentNodeParent(this.selectedPath);
      this.currentChildIndex = this.getNodeIndex(this.currentSelectedNode, this.currentRoot);
      this.forceOpen = true;
    } else {
      if (this.currentSelectedNode) {
        this.$emit('node-selected', this.currentSelectedNode);
      }
    }
  },
  methods: {
    searchInReposList(value) {
      if (this.shouldShowRepos) {
        this.workspaceReactiveRepos = [];
        this.workspaceRepos.forEach((repo) => {
          const searchTerm = value?.trim()?.toLowerCase() || '';
          if (!searchTerm) {
            this.workspaceReactiveRepos.push(repo);
          } else {
            if (repo.name.toLowerCase().includes(searchTerm)) {
              this.workspaceReactiveRepos.push(repo);
            }
          }
        });
      }
    },
    handleTab(event) {
      if (!this.isSnippetStudio) {
        event.preventDefault();
      }
    },
    onSelectRepo(repo) {
      this.repoSelected(repo.id);
    },
    selectRepo(index) {
      const repoIndex = index ?? this.currentSelectedRepo;
      const { id, disabled } = this.workspaceReactiveRepos[repoIndex];
      if (disabled) {
        return;
      }
      this.workspaceReactiveRepos = this.workspaceRepos;
      this.repoSelected(id);
    },
    resetSelection() {
      // if there is no folder tree, no need to reset the selection
      if (this.shouldShowRepos) {
        this.currentSelectedRepo = 0;
      }
      if (this.repoFolderTree) {
        this.currentSelectedNode = this.createSortedTreeNode(this.repoFolderTree)?.children?.[0];
        this.currentChildIndex = 0;
        this.currentRoot = this.createSortedTreeNode(this.repoFolderTree);
      }
    },
    showRepos() {
      if (!this.addShowRepos) {
        return;
      }
      // Reset search
      this.search = '';
      this.shouldShowRepos = true;
      this.$emit('repos-list-toggled', true);
    },
    repoSelected(repoId) {
      this.isRepoNodeSelected = true;
      this.currentSelectedRepo = 0;
      this.search = '';
      this.shouldShowRepos = false;
      this.$emit('repo-selected', repoId);
      this.$emit('repos-list-toggled', false);
    },
    getRepoIcon(repo) {
      if (repo.disabled) {
        return 'not-allowed';
      }
      return getGitProviderIconName(repo.provider);
    },
    createSortedTreeNode(node) {
      if (node?.children) {
        const newChildren = [...node.children].sort((a, b) => {
          if (a.type === b.type) {
            return a.path < b.path ? -1 : 1;
          }
          return a.type === 'directory' ? -1 : 1;
        });
        node.children = newChildren;
      }
      return node;
    },
    moveUp() {
      this.forceOpen = false;

      if (this.hasSearchResults) {
        this.$refs.searchResults.moveUp();
      } else if (
        // Allow navigating to the repo node if at least one node exists
        !this.shouldShowRepos &&
        (!this.currentRoot.children.length || (this.isParentRoot && this.currentChildIndex === 0))
      ) {
        this.isRepoNodeSelected = true;
      } else if (this.shouldShowRepos) {
        // When we are on the repo list we are checking whether or not we are on the first index already before trying to move up
        if (this.currentSelectedRepo > 0) {
          this.currentSelectedRepo = this.currentSelectedRepo - 1;
        }
      } else {
        if (this.currentRoot && this.currentChildIndex > 0) {
          this.currentChildIndex--;
          this.currentSelectedNode = this.currentRoot.children[this.currentChildIndex];
          this.$emit('node-selected', this.currentSelectedNode);
          this.scrollToSelectedNode();
          // If the selected node is the first child, move to its parent unless it's the root of the repo's first child.
        } else if (this.currentRoot && this.currentChildIndex === 0 && !this.isParentRoot) {
          this.moveLeft();
        }
      }
    },
    moveDown() {
      this.forceOpen = false;
      if (this.hasSearchResults) {
        this.$refs.searchResults.moveDown();
        this.isRepoNodeSelected = false;
      } else if (this.isRepoNodeSelected) {
        this.isRepoNodeSelected = false;
      } else if (this.shouldShowRepos) {
        if (this.currentSelectedRepo < this.workspaceReactiveRepos.length - 1) {
          this.currentSelectedRepo = this.currentSelectedRepo + 1;
        }
      } else {
        if (this.currentRoot && this.currentChildIndex < this.currentRoot.children.length - 1) {
          this.currentChildIndex++;
          this.currentSelectedNode = this.currentRoot.children[this.currentChildIndex];
          this.$emit('node-selected', this.currentSelectedNode);
          this.scrollToSelectedNode();
        } else if (
          this.currentRoot &&
          this.currentChildIndex === this.currentRoot.children.length - 1 &&
          !this.isParentRoot
        ) {
          // Close the parent folder if exists and move down
          this.moveLeft();
          this.moveDown();
        }
      }
    },
    moveRight() {
      this.forceOpen = false;
      if (this.isRepoNodeSelected) {
        return;
      }
      if (this.shouldShowRepos) {
        this.selectRepo();
      } else if (this.currentSelectedNode && this.currentSelectedNode.type === 'directory') {
        this.currentRoot = this.createSortedTreeNode(this.currentSelectedNode);
        // Select the current node's first child
        this.currentChildIndex = 0;
        this.currentSelectedNode = this.currentRoot.children[this.currentChildIndex];
        this.currentRoot.closed = false;
        this.$emit('node-selected', this.currentSelectedNode);
        this.scrollToSelectedNode();
      }
    },
    moveLeft() {
      this.forceOpen = false;

      // First, set the current node to the parent, close it and update the currentRoot and currentChild index accordingly
      if (this.currentRoot && !this.isParentRoot) {
        this.currentSelectedNode = this.currentRoot;
        this.currentRoot.closed = true;
        this.currentRoot = this.getCurrentNodeParent(this.currentSelectedNode.path);
        this.currentChildIndex = this.getNodeIndex(this.currentSelectedNode, this.currentRoot);
        this.$emit('node-selected', this.currentSelectedNode);
        this.scrollToSelectedNode();
      } else if (this.isRepoNodeSelected && this.addShowRepos) {
        this.isRepoNodeSelected = false;
        this.showRepos();
      }
    },
    getCurrentNodeParent(pathToFind, currentNode = null) {
      if (!currentNode) {
        // Start the BFS search from the repo root
        currentNode = this.repoFolderTree;
      }
      if (currentNode.type === 'directory') {
        // the current node is the parent if one of its children is the current selected node,
        if (currentNode.children.find((node) => node.path === pathToFind)) {
          return this.createSortedTreeNode(currentNode);
        }

        // recursively search for the parent in the current node's children
        for (const child of currentNode.children) {
          const parent = this.getCurrentNodeParent(pathToFind, child);
          if (parent) {
            return parent;
          }
        }
      }
    },
    getNodeFromPath(currentPath, currentNode = null) {
      if (!currentNode) {
        // Start the BFS search from the repo root
        currentNode = this.repoFolderTree;
      }
      if (currentNode.path === currentPath) {
        return currentNode;
      }

      if (currentNode.type === 'directory') {
        // recursively search for the parent in the current node's children
        for (const child of currentNode.children) {
          const node = this.getNodeFromPath(currentPath, child);
          if (node) {
            return node;
          }
        }
      }
    },
    getNodeIndex(node, root) {
      if (root) {
        return root.children.findIndex((currentNode) => currentNode.path === node.path);
      }
    },
    selectWithEnter(event) {
      if (this.isRepoNodeSelected && !this.hasSearchResults) {
        event.stopPropagation();
        this.isRepoNodeSelected = false;
        this.showRepos();
        return;
      }
      if (this.shouldShowRepos) {
        event.stopPropagation();
        this.selectRepo();
        return;
      }
      this.hasSearchResults && this.$refs.searchResults.selectWithEnter(event);
      this.$emit('apply-node-selection', this.currentSelectedNode);
    },
    nodeToggle({ node, open }) {
      node.closed = !open;
    },
    nodeSelected(nodeClicked) {
      this.isRepoNodeSelected = false;
      this.currentSelectedNode = nodeClicked;
      if (nodeClicked.closed === undefined) {
        this.currentSelectedNode.closed = false;
      } else {
        this.currentSelectedNode.closed = !nodeClicked.closed;
      }
      this.currentRoot = this.getCurrentNodeParent(this.currentSelectedNode.path);
      this.currentChildIndex = this.getNodeIndex(this.currentSelectedNode, this.currentRoot);
      if (this.forceOpen === true && !this.searching) {
        this.forceOpen = false;
      }
      if (nodeClicked) {
        this.$emit('node-selected', nodeClicked);
      }
      this.searching = false;
      this.scrollToSelectedNode();
    },
    async refreshTree() {
      this.$emit('refresh-tree');
    },
    performSearch() {
      if (this.shouldShowRepos) {
        return;
      }
      this.searchResults = [];
      if (this.search && this.repoFolderTree) {
        this.searchSubTree(this.repoFolderTree);
        this.$emit('reset-selection');
      }
      this.searchResults.sort(this.sortResults);
      if (this.searchResults.length > RESULTS_LIMIT) {
        this.searchResults.length = RESULTS_LIMIT;
      }
    },
    sortResults(res1, res2) {
      const aExactFileName = res1.name === this.search;
      const bExactFileName = res2.name === this.search;
      if (aExactFileName !== bExactFileName) {
        return aExactFileName ? -1 : 1;
      } else if (aExactFileName) {
        return res1.path.localeCompare(res2.path);
      }
      const aFoundByFileName = res1.name.toLowerCase().includes(this.search.toLowerCase());
      const bFoundByFileName = res2.name.toLowerCase().includes(this.search.toLowerCase());
      if (aFoundByFileName && bFoundByFileName) {
        return res1.name.localeCompare(res2.name);
      }
      if (aFoundByFileName) {
        return -1;
      }
      if (bFoundByFileName) {
        return 1;
      }
      return res1.path.localeCompare(res2.path);
    },
    searchSubTree(node) {
      if (node) {
        const positiveSearch =
          node.name?.toLowerCase().includes(this.search.toLowerCase()) ||
          node.path?.toLowerCase().startsWith(this.search.toLowerCase()) ||
          node.path?.toLowerCase().includes(`/${this.search.toLowerCase()}`);

        if (positiveSearch && (node.type === 'file' || (node.type === 'directory' && this.searchIncludeDirs))) {
          this.searchResults.push(node);
        }

        if (node.children) {
          node.children.forEach((childNode) => this.searchSubTree(childNode));
        }
      }
    },
    scrollToSelectedNode(opts = { behavior: 'smooth', block: 'nearest' }) {
      if (this.currentSelectedNode) {
        this.$nextTick(() => this.scrollToElement(this.currentSelectedNode.path, opts));
      }
    },
    clearSearch() {
      this.search = '';
      this.searchResults = [];
      this.scrollToSelectedNode();
    },
  },
});
</script>

<style scoped lang="postcss">
.tree {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: none;
  color: var(--text-color-primary);
}

.tree:focus {
  outline: none;
}

.tree-container {
  display: flex;
  height: 100%;
  flex: 1;
}

.tree-files-browsing {
  overflow-y: auto;
  padding: 0 var(--space-xsmall);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.nodes {
  overflow-x: auto;
  width: 100%;
  min-width: 0;
  height: 100%;
  white-space: nowrap;

  .nodes-container {
    overflow-y: hidden;
    margin-bottom: var(--space-sm);
  }
}

.repo-node {
  cursor: pointer;
  padding: var(--space-xs) 0px var(--space-xs) var(--space-base);
  margin-top: var(--space-xs);

  .arrow {
    padding: 0;
    font-size: var(--fontsize-xxs);
  }

  .provider-icon {
    padding: 0 var(--space-xs) 0 var(--space-xs);
    font-size: var(--fontsize-s);
  }
}

.root-node {
  padding-left: var(--space-sm);
}

.repo-name {
  display: inline-block;
  font-size: var(--body-L);
  width: 13em;
  vertical-align: bottom;
  color: var(--text-color-primary);
}

.repo-line {
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.repo-line.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.repos-title {
  padding: var(--space-base) var(--space-base) var(--space-base) 10px;
}

.repo-selected {
  background: var(--color-selected);
}

.search-divider {
  height: 10px;
}
</style>
