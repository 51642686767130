<template>
  <div class="player-wrapper">
    <iframe v-if="videoSrc" type="text/html" :src="videoSrc" frameborder="0" allowfullscreen />
    <div v-else>
      Sorry, we couldn't resolve link <a :href="url" target="_blank">{{ url }}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: { type: String, required: true },
    autoplay: { type: Boolean, default: false },
    showNonSwimmRelatedVideos: { type: Boolean, default: false },
  },
  computed: {
    videoSrc() {
      const src = '';
      const videoId = this.parseYoutubeVideoId();
      const showNonSwimmRelatedVideos = this.showNonSwimmRelatedVideos ? 1 : 0;
      return videoId
        ? `https://www.youtube.com/embed/${videoId}?autoplay=${this.autoplay}&rel=${showNonSwimmRelatedVideos}`
        : src;
    },
  },
  methods: {
    parseYoutubeVideoId() {
      let videoId = '';
      const queryParams = ['v=', 'vi=', 'v%3D', 'vi%3D'];
      queryParams.forEach((param) => {
        if (!videoId && this.url.includes(param)) {
          videoId = this.url.split(param)[1].slice(0, 11);
        }
      });
      if (!videoId) {
        const splitPath = this.url.split('/');
        videoId = splitPath[splitPath.length - 1].slice(0, 11);
      }
      return videoId;
    },
  },
};
</script>

<style scoped>
.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

a {
  color: var(--text-color-link);
}

a:hover {
  text-decoration: underline;
}
</style>
