<script setup lang="ts">
import { ref } from 'vue';
import { type FolderTree, GitProviderName, type WorkspaceRepo } from '@swimm/shared';

import BaseBlock from '../../components/BaseBlock/BaseBlock.vue';
import BaseProse from '../../components/BaseProse/BaseProse.vue';

import NodeTree from '../NodeTree/NodeTree.vue';
import Menu from '../Menu/Menu.vue';
import MenuRepos from '../MenuRepos/MenuRepos.vue';

const demoNode = ref<FolderTree>({
  name: '',
  path: '',
  children: [
    {
      name: 'components',
      path: 'components',
      type: 'directory',
      children: [
        {
          name: 'animation.js',
          path: 'components/animation.js',
          type: 'file',
        },
        {
          name: 'index.js',
          path: 'components/index.js',
          type: 'file',
        },
        {
          name: 'header.js',
          path: 'components/header.js',
          type: 'file',
        },
        {
          name: 'datepicker.js',
          path: 'components/datepicker.js',
          type: 'file',
        },
        {
          name: 'dark.ts',
          path: 'components/dark.ts',
          type: 'file',
        },
        {
          name: 'light.ts',
          path: 'components/light.ts',
          type: 'file',
        },
        {
          name: '.gitignore',
          path: 'components/.gitignore',
          type: 'file',
        },
      ],
    },
  ],
  type: 'directory',
});

const demoSelectedPath = ref<string>('components/.gitignore');

const demoWorkspaceRepo = ref<WorkspaceRepo[]>([
  {
    id: '0e54da53-021a-530e-b5a3-4f8728d969c5',
    provider: GitProviderName.GitHub,
    integrations: {},
    name: 'acme-src',
  },
]);

const demoSelectedNodes = ref<FolderTree[]>([
  {
    name: 'animation.js',
    path: 'components/animation.js',
    type: 'file',
  },
  {
    name: 'index.js',
    path: 'components/index.js',
    type: 'file',
  },
  {
    name: 'header.js',
    path: 'components/header.js',
    type: 'file',
  },
]);
</script>

<template>
  <div class="swm-selection-content-token-advanced-empty-state">
    <BaseProse class="swm-selection-content-token-advanced-empty-state__content" variant="secondary" size="small"
      >Couldn't find a token that matches your search?<br />Select files you want us to take tokens from.</BaseProse
    >

    <BaseBlock class="swm-selection-content-token-advanced-empty-state__demo-node-tree">
      <Menu>
        <MenuRepos :workspace-repos="demoWorkspaceRepo" />
        <NodeTree
          :node="demoNode"
          :selected-path="demoSelectedPath"
          :selected-nodes="demoSelectedNodes"
          selectable
          :level-offset="1"
        />
      </Menu>
    </BaseBlock>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-selection-content-token-advanced-empty-state {
  $self: &;

  @include basic-resets;

  align-items: center;
  display: flex;
  flex-direction: column;

  &__content {
    margin-bottom: var(--space-small);
    text-align: center;
  }

  &__demo-node-tree {
    max-width: 280px;
    position: relative;

    &:after {
      background: linear-gradient(0deg, var(--color-bg-default) 20%, rgba(255, 255, 255, 0) 50%);
      border-radius: var(--space-xsmall);
      bottom: -1px;
      display: block;
      content: '';
      left: -1px;
      position: absolute;
      top: 0;
      right: -1px;
    }
  }
}
</style>
