import { v4 as uuidv4 } from 'uuid';
import DocLoader from './doc-loader?worker';
import type { DocLoaderRequest, DocLoaderResponse } from './doc-loader-types';
import VerifyDocWorker from './verify-doc?worker';
import type { VerifyDocWorker as VerifyDocWorkerType } from './verify-doc';
import * as Comlink from 'comlink';
import type { WebLoggerContext } from '@/common/utils/logger-utils';
import type { SetLoggingConfigRequest } from './shared-types';

class WebAppWorker<RequestType, ResponseType> {
  workerConstructor: new () => Worker;
  worker: Worker;

  public constructor(workerConstructor: new () => Worker) {
    this.workerConstructor = workerConstructor;
    this.worker = new this.workerConstructor();
  }

  public restartWorker(listener?: (this: Worker, event: MessageEvent<ResponseType>) => void) {
    if (this.worker?.terminate) {
      this.worker.terminate();
    }
    this.worker = new this.workerConstructor();
    if (listener) {
      this.addListener(listener);
    }
  }

  public addListener(listener: (this: Worker, event: MessageEvent<ResponseType>) => void) {
    if (!this.worker?.addEventListener) {
      return;
    }
    this.worker.addEventListener('message', listener);
  }

  public removeListener(listener: (this: Worker, event: MessageEvent<ResponseType>) => void) {
    if (!this.worker?.removeEventListener) {
      return;
    }
    this.worker.removeEventListener('message', listener);
  }

  public postMessage(message: RequestType) {
    if (!this.worker?.postMessage) {
      return;
    }
    this.worker.postMessage(message);
  }
}

export function generateTransactionId() {
  return uuidv4();
}

export let docLoaderWorker: WebAppWorker<DocLoaderRequest, DocLoaderResponse>;
export let verifyDocWorker: Comlink.Remote<VerifyDocWorkerType>;
export let verifyDraftWorker: Comlink.Remote<VerifyDocWorkerType>;

export function startWorkers() {
  verifyDocWorker = Comlink.wrap(new VerifyDocWorker());
  verifyDraftWorker = Comlink.wrap(new VerifyDocWorker());
  docLoaderWorker = new WebAppWorker<DocLoaderRequest, DocLoaderResponse>(DocLoader);
}

export function setWorkersGlobalContext(context: WebLoggerContext) {
  const message: { transactionId: string } & SetLoggingConfigRequest = {
    transactionId: generateTransactionId(),
    type: 'setLoggingConfig',
    config: { context },
  };
  docLoaderWorker.postMessage(message);
  verifyDocWorker?.setGlobalContext(context);
  verifyDraftWorker?.setGlobalContext(context);
}
