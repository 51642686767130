import { getDraftsData } from '@/adapters-common/load_common';
import { encryptMessage, state } from '@swimm/shared';

export async function saveDraftsToState({ drafts, repoId }) {
  const userSalt = await state.get({ key: 'salt', defaultValue: undefined });

  // Get encrypted drafts before saving to keep them.
  const draftsFromState = await getDraftsData({ repoId, filterEncrypted: false });
  const draftsToSave = [];

  // Merge both drafts encrypted in state and users drafts
  for (const draft of draftsFromState) {
    if (draft['encrypted']) {
      draftsToSave.push(draft['data']);
    }
  }

  for (const draft of drafts) {
    if (draft['encrypted']) {
      draftsToSave.push(draft['data']);
    } else {
      draftsToSave.push(encryptMessage(draft, userSalt));
    }
  }

  await state.set({ repoId: repoId, key: `drafts`, value: draftsToSave });
}
