import { config, gitwrapper } from '@swimm/shared';
import { getLoggerNew } from '@swimm/shared';
import { getSwmTypeFromExtension, parseSwmFilename } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export async function getLocalSwmsList(repoId: string, branch: string) {
  return Object.values(await listSwmFiles(repoId, branch));
}

export async function listSwmFiles(
  repoId: string,
  branch: string
): Promise<{ [key: string]: { name: string; type: string; path: string } }> {
  try {
    const swmFolderTree = await getSwmResources({
      repoId,
      branch,
      fileExtensions: [
        config.SWM_RULE_EXTENSION,
        config.SWM_FILE_EXTENSION,
        config.SWMD_TEMPLATE_FILE_EXTENSION,
        config.SWMD_FILE_EXTENSION,
      ],
    });
    const swmFiles: {
      [key: string]: { name: string; type: string; path: string };
    } = {};
    swmFolderTree.forEach((resource) => {
      const parsedSwmFileName = parseSwmFilename(resource.path);

      if (parsedSwmFileName) {
        const { extension, swmId, name, path } = parsedSwmFileName;
        const type = getSwmTypeFromExtension(extension);
        const lastSlashIndex = name.lastIndexOf('/');
        const fileName = name.slice(lastSlashIndex + 1);
        let record = { name: `${fileName}.${swmId}`, type, path };
        record = handleSwmWithTheSameSwmId(swmFiles[swmId], record);
        swmFiles[swmId] = record;
      }
    });
    return swmFiles;
  } catch (err) {
    logger.error({ err }, `Unexpected error in listSwmFiles: ${err}`);
    return {};
  }
}

// Handle cases of swm files with the same id, e.g a doc file and its rule file
function handleSwmWithTheSameSwmId(
  currentSwm: { name: string; type: string; path: string } | undefined,
  incomingSwm: { name: string; type: string; path: string }
): { name: string; type: string; path: string; rule?: { name: string; type: string } } {
  if (currentSwm && incomingSwm.type !== currentSwm.type) {
    // Incase one of the swms is a rule file - set it in a rule prop of the other
    if (incomingSwm.type === config.SWIMM_FILE_TYPES.SWM_RULE) {
      return { ...currentSwm, rule: incomingSwm };
    } else if (currentSwm.type === config.SWIMM_FILE_TYPES.SWM_RULE) {
      return { ...incomingSwm, rule: currentSwm };
    }
  }
  return incomingSwm;
}

async function getSwmResources({
  repoId,
  branch,
  fileExtensions = [config.SWMD_FILE_EXTENSION],
}: {
  repoId: string;
  branch: string;
  fileExtensions?: string[];
}) {
  const swmFolderTree = await gitwrapper.getRepoTree({
    repoId,
    path: config.SWM_FOLDER_IN_REPO,
    treeSha: branch,
    recursive: true,
    cacheBuster: true, // for renaming and then refreshing
  });

  return swmFolderTree.filter((resource) =>
    fileExtensions.some(
      (extension) => resource.path.endsWith(extension) && resource.path.startsWith(config.SWM_FOLDER_IN_REPO)
    )
  );
}
