<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watchEffect } from 'vue';

import { SwmSymbolLinkType, type WorkspaceRepo } from '@swimm/shared';
import { type Link } from '../../types/transitional';

import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';
import BaseProse from '../../components/BaseProse/BaseProse.vue';
import BaseTruncate from '../../components/BaseTruncate/BaseTruncate.vue';

import Menu from '../Menu/Menu.vue';
import MenuRepos from '../MenuRepos/MenuRepos.vue';
import MenuItem from '../MenuItem/MenuItem.vue';

import SwmSelectionBlock from '../SwmSelectionBlock/SwmSelectionBlock.vue';
import SwmSelectionContent from '../SwmSelectionContent/SwmSelectionContent.vue';
import SwmSelectionFooter from '../SwmSelectionFooter/SwmSelectionFooter.vue';

const props = defineProps<{
  modelValue: string;
  workspaceRepos?: WorkspaceRepo[];
  links?: Link[];
  crossRepoSupport?: boolean;
  loading?: boolean;
  error?: string;
  legacy?: boolean;
  resourceType: SwmSymbolLinkType;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: string];
  selectRepo: [repo: WorkspaceRepo];
  selectLink: [link: Link];
  close: [];
}>();

const q = ref(props.modelValue);
const selectionPopover = ref<{ setSearchFocus: () => void; resetItemFocus: () => void } | null>(null);
const delayFocus = ref(false);

watchEffect(() => {
  q.value = props.modelValue;
});
const resourcesDisplayName = computed(() => `${props.resourceType.toLowerCase()}s`);
const isRepoListing = computed(() => !props.links);

const searchPlaceholder = computed(() =>
  isRepoListing.value ? 'Search workspace repositories…' : `Search Swimm ${resourcesDisplayName.value}…`
);

const computedClasses = computed(() => ({
  'swm-selection-popover-content-link--cross-repo-support': props.crossRepoSupport,
}));

const hasLinks = computed(() => {
  return (
    props.workspaceRepos && props.workspaceRepos.length === 1 && props.links !== undefined && props.links.length === 0
  );
});

const noResults = computed(
  () =>
    !!(props.links !== undefined && !props.links.length && q.value.length) ||
    !!(props.workspaceRepos !== undefined && !props.workspaceRepos.length && q.value.length)
);

const searchingRepos = computed(() => {
  return !!q.value.length && props.links === undefined;
});

function onUpdateModelValue(value: string) {
  emit('update:modelValue', value);
  q.value = value;
}

function onSelectRepo(repo: WorkspaceRepo) {
  emit('selectRepo', repo);
}

function onSelectLink(link: Link) {
  emit('selectLink', link);
}

function goToRepos() {
  if (props.workspaceRepos) {
    emit('selectRepo', props.workspaceRepos[0]);
  }
}

async function resetItemFocus() {
  if (selectionPopover.value) {
    await nextTick();
    selectionPopover.value.resetItemFocus();
  }
}

async function setSearchFocus() {
  if (selectionPopover.value) {
    await nextTick();
    selectionPopover.value.setSearchFocus();
  }
}

onMounted(async () => {
  // We want to focus in the search input on mount but if loading
  // is true we can't focus because the input is disabled, so we
  // set delayFocus to true.
  if (props.loading) {
    delayFocus.value = true;
  } else {
    await setSearchFocus();
  }
});

watchEffect(async () => {
  // We watch for loading to become false and if delayFocus was set
  // to true we focus on the search input.
  if (!props.loading && delayFocus.value) {
    await setSearchFocus();

    // We set delayFocus back to false to ensure this watcher
    // has no further effect.
    delayFocus.value = false;
  }
});

defineExpose({
  setSearchFocus,
  resetItemFocus,
});
</script>

<template>
  <SwmSelectionBlock class="swm-selection-popover-content-link" :legacy="legacy">
    <SwmSelectionContent
      ref="selectionPopover"
      v-model="q"
      :placeholder="searchPlaceholder"
      :error="error"
      :loading="loading"
      :no-results="noResults"
      :class="computedClasses"
      @update:model-value="onUpdateModelValue"
      @close="() => emit('close')"
    >
      <Menu>
        <template v-if="crossRepoSupport">
          <MenuRepos :workspace-repos="workspaceRepos" :searching="searchingRepos" @select-repo="onSelectRepo" />
        </template>

        <BaseProse
          v-if="hasLinks"
          wrapper="li"
          variant="secondary"
          size="small"
          class="swm-selection-popover-content-link__no-links"
          >There are no {{ resourcesDisplayName }} in this repo.</BaseProse
        >
        <template v-else>
          <MenuItem
            v-for="link in links"
            :key="link.id"
            :draft="link.isDraftLink"
            class="swm-selection-popover-content-link__item"
            data-testid="swimm-link-item"
            @click="onSelectLink(link)"
            @keydown.left.stop="goToRepos"
            @keydown.enter="onSelectLink(link)"
          >
            <template #leftIcon>
              <BaseIcon :name="link.icon" />
            </template>
            <BaseTruncate>{{ link.name }}</BaseTruncate>
          </MenuItem>
        </template>
      </Menu>

      <template #footer>
        <SwmSelectionFooter />
      </template>
    </SwmSelectionContent>
  </SwmSelectionBlock>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-selection-popover-content-link {
  $self: &;

  &__no-links {
    list-style: none;
    padding-left: calc(12px + var(--space-small));
  }

  &--cross-repo-support {
    #{$self}__item {
      &:not(#{$self}__item--go-back) {
        padding-left: calc(12px + var(--space-small));
      }
    }
  }
}
</style>
