<script setup lang="ts">
import { useFoldersStore } from '@/modules/folders/store/folders';
import SearchModal from '../modals/SearchModal.vue';
import { populateSearchIndexCache } from '@/remote-adapters/search';
import { eventLogger, getLoggerNew, productEvents, sortRepos } from '@swimm/shared';
import { useEventsStore } from '@/modules/core/stores/event-log-store';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { isMac } from '@swimm/shared';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import SidebarButton from '@/common/components/organisms/SidebarButton.vue';
import { useGlobalSearchStore } from '../stores/globalSearchStore';
import SearchButton from '@/common/components/atoms/SearchButton.vue';
import { TextField } from '@swimm/ui';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
const workspaceStore = useWorkspaceStore();

const logger = getLoggerNew(__modulename);

const { favoriteRepoIds, recentRepoIds } = storeToRefs(workspaceStore);

const props = defineProps({
  // FIXME: Replace the use of the main prop with the separation of the search modal from the buttons
  main: { type: Boolean, default: false }, // There should be only one main GlobalSearch at any given time
  compact: { type: Boolean, default: false },
});
const {
  shouldShowSearchModal,
  initialSelectedRepoIdsForSearch,
  selectedRepoIdsForSearch,
  isIndexingSearchData,
  globalSearchLimitToRepo,
} = storeToRefs(useGlobalSearchStore());

const store = useStore();
const eventsStore = useEventsStore();
const analytics = useAnalytics();
const route = useRoute();
const getWorkspace = store.getters['database/db_getWorkspace'];
const getRepoMetadata = store.getters['database/db_getRepoMetadata'];
const isWorkspaceSidebarSearchDisabledAtFirst = store.getters['database/db_isWorkspaceSidebarSearchDisabledAtFirst'];
const { user } = storeToRefs(useAuthStore());
const { isCurrentWorkspaceAuthorized } = storeToRefs(useGitAuthorizationStore());
const { loadRepoFolders } = useFoldersStore();
const { reposStateData } = storeToRefs(useReposStore());

const currentBranch = computed(() => route.params.branch as string);
const currentRepo = computed(() => route.params.repoId as string);
let refreshWorkspaceSearchPromise = null;
const hasFetchedFolders = ref(false);

const onOpen = (event) => {
  if (props.main && event.key === 'k' && ((isMac() && event.metaKey) || (!isMac() && event.ctrlKey))) {
    event.preventDefault();
    if (shouldShowSearchModal.value) {
      shouldShowSearchModal.value = false;
    } else {
      onSearch();
    }
  }
};

onMounted(async () => {
  if (props.main) {
    document.addEventListener('keydown', onOpen);
  }
});
onUnmounted(() => {
  if (props.main) {
    document.removeEventListener('keydown', onOpen);
  }
});

const onClose = () => {
  shouldShowSearchModal.value = false;
  globalSearchLimitToRepo.value = undefined;
};

function isWorkspaceReady() {
  const workspaceId = route.params.workspaceId as string;
  const workspace = getWorkspace(workspaceId);
  return !!workspace;
}

async function onSearch() {
  shouldShowSearchModal.value = true;
  globalSearchLimitToRepo.value = undefined;
  if (!refreshWorkspaceSearchPromise && isWorkspaceReady()) {
    refreshWorkspaceSearchPromise = refreshWorkspaceSearch();
  }
  analytics.track(productEvents.VIEWED_GLOBAL_SEARCH_POPUP, {
    'Workspace ID': route.params.workspaceId,
    'Repo ID': route.params.repoId,
    Context: 'Search',
    Origin: route.name,
  });
  eventsStore.fetchViewedDocs({
    workspaceId: route.params.workspaceId,
    userId: user.value.uid,
    codes: [eventLogger.SWIMM_EVENTS.DOC_VIEWED.code, eventLogger.SWIMM_EVENTS.PLAYLIST_VIEWED.code],
  });
  if (!hasFetchedFolders.value) {
    const workspace = getWorkspace(route.params.workspaceId);
    if (workspace?.repositories?.length) {
      Promise.allSettled(
        workspace.repositories.map(async (repoId) => {
          try {
            await loadRepoFolders(repoId);
          } catch (err) {
            logger.error({ err }, `Failed to fetch folders for repo ${repoId}`);
          }
        })
      );
      hasFetchedFolders.value = true;
    }
  }
}

function getInitialSelected() {
  const maxResults = 5;
  return sortRepos(workspaceAccessibleRepos.value, currentRepo.value)
    .slice(0, maxResults)
    .map((r) => r.id);
}

async function refreshWorkspaceSearch() {
  const workspaceId = route.params.workspaceId as string;
  const workspace = getWorkspace(workspaceId);
  initialSelectedRepoIdsForSearch.value = getInitialSelected();
  selectedRepoIdsForSearch.value = [...initialSelectedRepoIdsForSearch.value];
  if (!workspace) {
    logger.debug('Workspace did not seem to be initialized yet in the store.');
    return;
  }

  if (!isCurrentWorkspaceAuthorized.value) {
    return;
  }
  isIndexingSearchData.value = true;
  try {
    await populateIndexes(workspaceId);
    await eventsStore.fetchViewedDocs({
      workspaceId,
      userId: user.value.uid,
      codes: [
        eventLogger.SWIMM_EVENTS.DOC_VIEWED.code,
        eventLogger.SWIMM_EVENTS.PLAYLIST_VIEWED.code,
        eventLogger.SWIMM_EVENTS.IDE_DOC_VIEWED.code,
      ],
    });
  } finally {
    if (workspaceId === route.params.workspaceId) {
      isIndexingSearchData.value = false;
    }
  }
}

async function populateIndexes(workspaceId: string) {
  const repoIds = selectedRepoIdsForSearch.value;
  await store.dispatch('database/fetchAllWorkspaceRepos', workspaceId);
  const populatePromises = repoIds.map((repoId) => populateIndexForRepoId(workspaceId, repoId));

  await Promise.allSettled(populatePromises);
}

watch(
  () => JSON.stringify([route.params.workspaceId, isCurrentWorkspaceAuthorized.value]),
  () => {
    workspaceChanged();
  },
  {
    immediate: true,
  }
);

function workspaceChanged() {
  // if there any running promise - null it
  refreshWorkspaceSearchPromise = null;
  selectedRepoIdsForSearch.value = [];
  initialSelectedRepoIdsForSearch.value = [];
}

async function populateIndexForRepoId(workspaceId: string, repoId: string): Promise<void> {
  const repo = getRepoMetadata(repoId);
  if (repoId === currentRepo.value && currentBranch.value) {
    await populateSearchIndexCache({
      workspaceId,
      repoId,
      repoName: repo?.name,
      revision: currentBranch.value,
    });
  } else {
    const defaultBranch = reposStateData.value[repoId]?.defaultBranch;
    if (defaultBranch) {
      await populateSearchIndexCache({
        workspaceId: workspaceId,
        repoId,
        repoName: repo?.name,
        revision: defaultBranch,
      });
    }
  }
}

const showRepoSelector = computed(() => {
  return !globalSearchLimitToRepo.value && workspaceAccessibleRepos.value.length > 1;
});

const workspaceAccessibleRepos = computed(() => {
  const workspace = getWorkspace(route.params.workspaceId);

  if (globalSearchLimitToRepo.value || !workspace) {
    return [];
  }
  return workspace.repositories
    .filter((repoId) => !!reposStateData.value[repoId]?.defaultBranch)
    .map((repoId) => ({
      ...getRepoMetadata(repoId),
      isFavourite: favoriteRepoIds.value.includes(repoId),
      isRecent: recentRepoIds.value.includes(repoId),
    }))
    .filter(Boolean);
});

const shouldCreateSearchModal = computed(() => {
  if (!props.main) {
    // Only the main search button should create the modal to avoid having the modal created twice.
    return false;
  }

  return !isWorkspaceSidebarSearchDisabledAtFirst(route.query.workspaceId) || shouldShowSearchModal.value;
});
</script>

<template>
  <SearchButton data-testid="search" @search="onSearch" :inline="false">
    <SidebarButton v-if="compact" icon="search" />
    <TextField v-else placeholder="Search anything" />
  </SearchButton>
  <SearchModal
    v-if="shouldCreateSearchModal"
    :show="shouldShowSearchModal"
    @close="onClose"
    :show-repo-selector="showRepoSelector"
    :accessible-repos="workspaceAccessibleRepos"
  />
</template>
