/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { debounce } from 'lodash-es';
import { productEvents } from '@swimm/shared';
import type { SlashCommand, SlashCommandsCategory } from '@/types';
import { markdownModeSuggestions, orderedCommands } from '@/consts';
import type { SlashCommandIndex } from './SlashCommandsMenuContents.vue';

export function useCommandsSuggestions({ tEvent = null }: { tEvent?: any } = {}) {
  function flattenCommands(categories: readonly SlashCommandsCategory[]): SlashCommand[] {
    return categories.reduce(
      (flatCommands: SlashCommand[], category: SlashCommandsCategory) => [...flatCommands, ...category.commands],
      []
    );
  }

  const commandsByCategory: Ref<readonly SlashCommandsCategory[]> = ref(orderedCommands);
  const filteredCommandsByCategory: Ref<SlashCommandsCategory[]> = ref([]);
  const query = ref();
  const suggestionRange = ref();
  const navigatedCommandIndex = ref<SlashCommandIndex>([0, 0]);
  const insertCommand = ref();
  const openCommandNamed: Ref<string> = ref('');
  const cursorLoc = ref({ y: 0, x: 0 });

  const flatFilteredCommands = computed(() => flattenCommands(filteredCommandsByCategory.value));
  const hasMatchingCommands = computed(() => !!flatFilteredCommands.value.length);
  const flatCommands = computed(() => flattenCommands(commandsByCategory.value));
  const selectedCategory = computed(() => filteredCommandsByCategory.value[navigatedCommandIndex.value[0]]);

  // Is called when a suggestion is cancelled
  function suggestionOnExit() {
    query.value = null;
    filteredCommandsByCategory.value = [];
    suggestionRange.value = null;
    navigatedCommandIndex.value = [0, 0];
    document.removeEventListener('wheel', suggestionOnExit);
  }

  function handleSuggestionEnterOrChange(args: any) {
    if (args.items.length === 0) {
      suggestionOnExit();
      return;
    }
    let nextIndex = 0;
    query.value = args.query;
    filteredCommandsByCategory.value = commandsByCategory.value
      .map(({ category, commands }: SlashCommandsCategory): SlashCommandsCategory => {
        return {
          category,
          commands: commands
            .filter((command) => args.items.includes(command.title))
            .sort((c1, c2) => args.items.indexOf(c1.title) - args.items.indexOf(c2.title))
            .map((command) => ({ index: nextIndex++, ...command })),
        };
      })
      .filter(({ commands }) => commands.length);
    suggestionRange.value = args.range;
    cursorLoc.value = args.clientRect();
  }

  function getItemsByEditorState(args: any) {
    const newArgs = { ...args };
    newArgs['items'] = args.items.filter((item: string) =>
      markdownModeSuggestions.some((suggestion) => suggestion.title === item)
    );
    return newArgs;
  }
  function suggestionOnChange(args: any) {
    const newArgs = getItemsByEditorState(args);
    handleSuggestionEnterOrChange(newArgs);
    navigatedCommandIndex.value = [0, 0];
  }

  function suggestionOnEnter(args: any) {
    const newArgs = getItemsByEditorState(args);
    handleSuggestionEnterOrChange(newArgs);
    insertCommand.value = newArgs.command;
    document.addEventListener('wheel', suggestionOnExit);
  }

  // Is called on every keyDown event while a suggestion is active
  function suggestionOnKeyDown({ event }: { event: any }) {
    switch (event.key) {
      case 'ArrowUp':
        upHandler();
        return hasMatchingCommands.value;
      case 'ArrowDown':
        downHandler();
        return hasMatchingCommands.value;
      case 'ArrowRight':
        return hasMatchingCommands.value && rightHandler();
      case 'ArrowLeft':
        return hasMatchingCommands.value && leftHandler();
      case 'Enter':
        debounce(() => enterHandler(), 500)();
        return hasMatchingCommands.value; // If no matching commands - enter event will be handled by editor
      default:
        return false;
    }
  }

  function suggestionOnHover(commandIndex: [number, number]) {
    navigatedCommandIndex.value = commandIndex;
  }

  function upHandler() {
    // If we are at the start of the category, "go left" to the end of the previous category
    if (navigatedCommandIndex.value[1] === 0) {
      navigatedCommandIndex.value[0] =
        (navigatedCommandIndex.value[0] + filteredCommandsByCategory.value.length - 1) %
        filteredCommandsByCategory.value.length;
      navigatedCommandIndex.value[1] =
        filteredCommandsByCategory.value[navigatedCommandIndex.value[0]].commands.length - 1;
      return;
    }

    navigatedCommandIndex.value[1] =
      (navigatedCommandIndex.value[1] + selectedCategory.value.commands.length - 1) %
      selectedCategory.value.commands.length;
  }

  function downHandler() {
    // If we are at the start of the category, "go right" to the beginning of the next category
    if (
      navigatedCommandIndex.value[1] ===
      filteredCommandsByCategory.value[navigatedCommandIndex.value[0]].commands.length - 1
    ) {
      navigatedCommandIndex.value[0] =
        (navigatedCommandIndex.value[0] + filteredCommandsByCategory.value.length - 1) %
        filteredCommandsByCategory.value.length;
      navigatedCommandIndex.value[1] = 0;
      return;
    }

    navigatedCommandIndex.value[1] = (navigatedCommandIndex.value[1] + 1) % selectedCategory.value.commands.length;
  }
  function rightHandler() {
    navigatedCommandIndex.value[0] =
      (navigatedCommandIndex.value[0] + filteredCommandsByCategory.value.length - 1) %
      filteredCommandsByCategory.value.length;
    navigatedCommandIndex.value[1] = Math.min(
      navigatedCommandIndex.value[1],
      filteredCommandsByCategory.value[navigatedCommandIndex.value[0]].commands.length - 1
    );
    return true;
  }

  function leftHandler() {
    navigatedCommandIndex.value[0] = (navigatedCommandIndex.value[0] + 1) % filteredCommandsByCategory.value.length;
    navigatedCommandIndex.value[1] = Math.min(
      navigatedCommandIndex.value[1],
      filteredCommandsByCategory.value[navigatedCommandIndex.value[0]].commands.length - 1
    );
    return true;
  }

  function enterHandler() {
    selectCommand(navigatedCommandIndex.value);
  }

  function selectCommand(commandIndex: [number, number]) {
    const command = filteredCommandsByCategory.value[commandIndex[0]].commands[commandIndex[1]];

    if (!command) {
      return;
    }

    openCommandNamed.value = command.title;
    insertCommand.value({
      range: suggestionRange.value,
      props: {
        id: command.name,
        // Need to add space to the suggestion: https://github.com/ueberdosis/tiptap/issues/932
        label: `/${command.name} `,
      },
    });

    suggestionOnExit();

    tEvent(productEvents.SLASH_COMMAND_TRIGGERED, {
      'Slash Command Name': command.title,
      Snippet: false,
    });
  }
  return {
    commandsByCategory,
    flatCommands,
    filteredCommandsByCategory,
    flatFilteredCommands,
    query,
    openCommandNamed,
    suggestionRange,
    navigatedCommandIndex,
    insertCommand,
    cursorLoc,
    suggestionOnEnter,
    selectCommand,
    suggestionOnExit,
    suggestionOnChange,
    suggestionOnKeyDown,
    suggestionOnHover,
  };
}
