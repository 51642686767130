import { VueNodeViewRenderer } from '@tiptap/vue-3';
import TableHeader from '@tiptap/extension-table-header';
import TableHeaderNodeView from '../nodeViews/TableHeaderNodeView.vue';

export default TableHeader.extend({
  content: '(inline | hardBreak)*',

  addAttributes() {
    return {
      ...this.parent?.(),
      align: {
        default: null,
        parseHTML: (element) => {
          return element.style.textAlign;
        },
        renderHTML: (attributes) => {
          return {
            style: `text-align: ${attributes.align}`,
          };
        },
      },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(TableHeaderNodeView);
  },
});
