<template>
  <div class="card-wrapper">
    <ListViewItem
      :title="docRequest.title"
      :description="docRequest.description"
      :stage-tag="tag"
      :creator="docRequest.creator_name"
      class="card-hug"
      @link-click="navigateOnClick"
    >
      <div class="header">
        <div class="body-L request-title" data-testid="request-title">
          <Icon v-if="icon" class="headline2" :name="icon" />
          <span>{{ docRequest.title }}</span>
        </div>
        <div class="body-XS request-description" data-testid="request-description">
          <span>{{ docRequest.description }}</span>
        </div>
      </div>
      <template #user-avatar>
        <div class="assignments">
          <SwAvatar :text="byLabel" />
          <Icon name="arrow-right" />
          <SwAvatar :text="fromLabel" />
        </div>
      </template>
      <template v-if="allowEdit || allowCompleted || allowDelete" #options>
        <EllipsisOption
          v-if="allowEdit"
          name="Edit"
          icon="edit-outline"
          :handler="handleEdit"
          :close-after-action="false"
        />
        <EllipsisOption
          v-if="allowCompleted"
          name="Mark as completed"
          icon="check"
          :handler="handleCompleted"
          :close-after-action="false"
        />
        <EllipsisOption
          v-if="allowDelete"
          name="Delete"
          icon="trash-outline"
          :handler="handleDelete"
          :close-after-action="false"
        />
      </template>
    </ListViewItem>
  </div>
</template>

<script>
import * as firestore from '@/adapters-common/firestore-wrapper';
import { useAppLinks } from '@/modules/core/compositions/app-links';
import { productEvents } from '@swimm/shared';
import { mapActions, mapGetters } from 'vuex';
import { NOTIFICATION_TYPES, encodeString } from '@swimm/shared';
import EllipsisOption from '@/common/components/atoms/EllipsisOption.vue';
import { clickedOnInternalCardElement } from '@/common/utils/card-utils';
import swal from 'sweetalert';
import {
  createDocRequestNotification,
  markDocRequestAsCompleted,
  markDocRequestAsDeleted,
} from '@/modules/doc-requests/services/doc-requests-utils';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import { useAnalytics } from '@/common/composables/useAnalytics';
import ListViewItem from '@/modules/core/components/ListViewItem.vue';
import { useNavigate } from '@/common/composables/navigate';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { SwAvatar } from '@swimm/ui';

export default {
  components: {
    SwAvatar,
    EllipsisOption,
    ListViewItem,
  },
  props: {
    docRequest: { type: Object, default: () => ({}) },
  },
  emits: ['doc-request-action'],
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const { getWebAppLink } = useAppLinks();
    const { navigateToPageAndTerminateWorker, getRepoPath } = useNavigate();

    return {
      user,
      analytics,
      getWebAppLink,
      navigateToPageAndTerminateWorker,
      getRepoPath,
    };
  },
  data() {
    return { icon: 'doc-request' };
  },
  computed: {
    ...mapGetters('filesystem', ['fs_isUnitExist']),
    ...mapGetters('database', ['db_getSwimm', 'db_getContributors']),
    tag() {
      if (this.docRequest.completed) {
        return { text: 'COMPLETED' };
      }
      if (this.fileExists) {
        return { text: 'IN PROGRESS' };
      }
      if (this.docRequest.draft) {
        return { text: 'LOCAL DRAFT' };
      }
      return { text: 'TODO' };
    },
    repoId() {
      return this.$route.params.repoId;
    },
    swimmFromDB() {
      return this.db_getSwimm(this.repoId, this.docRequest.id);
    },
    docContributors() {
      if (!this.swimmFromDB) {
        return [];
      }
      return this.db_getContributors(this.repoId, this.swimmFromDB.id);
    },
    allowDelete() {
      return this.user.uid === this.docRequest.creator;
    },
    allowEdit() {
      return !this.docRequest.completed && this.user.uid === this.docRequest.creator;
    },
    allowCompleted() {
      if (this.docRequest.completed) {
        return false;
      }
      return this.isContributor;
    },
    isContributor() {
      const uid = this.user.uid;
      if (this.docRequest.creator === uid || this.docRequest.modifier === uid) {
        return true;
      }
      return this.docContributors.some((contributor) => contributor.id === uid);
    },
    byLabel() {
      return this.docRequest.creator_name;
    },
    fromLabel() {
      return this.docRequest.requested_from_name || this.docRequest.requested_from_email;
    },
    fileExists() {
      return this.fs_isUnitExist(this.docRequest.id, this.repoId);
    },
  },
  async created() {
    if (this.swimmFromDB) {
      await this.refreshDocSubCollections({
        repoId: this.repoId,
        unitId: this.swimmFromDB.id,
        collectionsToRefresh: [firestore.collectionNames.CONTRIBUTORS],
      });
    }
  },
  methods: {
    ...mapActions('database', ['addDocRequest', 'refreshDocSubCollections', 'fetchUserNotifications']),
    async handleEdit() {
      this.$emit('doc-request-action', this.docRequest, 'edit');
    },
    async handleDelete() {
      const shouldDelete = await swal({
        title: 'Are you sure you want to delete the doc request?',
        dangerMode: true,
        buttons: {
          cancel: true,
          confirm: { text: 'Delete', visible: true },
        },
      });
      if (!shouldDelete) {
        return;
      }
      try {
        const docRequest = await markDocRequestAsDeleted({
          repoId: this.repoId,
          docRequestId: this.docRequest.id,
          byUser: this.user,
        });
        this.cloudReport({ docRequest: docRequest, saveType: 'delete' });
        this.addDocRequest({ repoId: this.repoId, resource: docRequest });
      } catch (e) {
        this.$logger.error(`Failed to delete docRequest ${this.docRequest.id} ${e}`);
        await swal({
          title: `Failed to delete the doc request`,
          content: SWAL_CONTACT_US_CONTENT(),
        });
      }
    },
    async handleCompleted() {
      try {
        const docRequest = await markDocRequestAsCompleted({
          repoId: this.repoId,
          docRequestId: this.docRequest.id,
          byUser: this.user,
        });
        this.cloudReport({ docRequest: docRequest, saveType: 'mark as completed' });
        this.addDocRequest({ repoId: this.repoId, resource: docRequest });
        const url = this.getWebAppLink(`${this.getRepoPath()}/doc-requests?docRequestId=${docRequest.id}`);
        await createDocRequestNotification({
          repoId: this.repoId,
          docRequest,
          workspaceId: this.$route.params.workspaceId,
          url,
          type: NOTIFICATION_TYPES.DOC_REQUEST_COMPLETED,
        });
        await this.fetchUserNotifications();
      } catch (e) {
        this.$logger.error(`Failed to mark docRequest as completed ${this.docRequest.id} ${e}`);
        await swal({
          title: `Failed to update the doc request`,
          content: SWAL_CONTACT_US_CONTENT(),
        });
      }
    },
    cloudReport({ docRequest, saveType }) {
      this.analytics.cloudTrack({
        identity: this.user.uid,
        event: productEvents.DOC_REQUEST_SAVED,
        payload: {
          Branch: this.$route.params.branch,
          'Repo ID': this.repoId,
          'Workspace ID': this.$route.params.workspaceId,
          Origin: this.isEdit ? 'Doc Requests Page' : this.newOrigin,
          'Doc Request ID': docRequest.id,
          'Assignee Email': docRequest.requested_from_email,
          'Doc Request Name': docRequest.title,
          Description: docRequest.description,
          'Save Date': new Date().toISOString(),
          'Save Type': saveType,
        },
      });
    },
    async navigateOnClick(event) {
      if (clickedOnInternalCardElement(event)) {
        return;
      }
      if (!this.fileExists) {
        if (this.docRequest.completed) {
          return;
        }
        const path = `${this.getRepoPath()}/docs/new`;
        let draftIdParam = undefined;
        if (this.docRequest.draft) {
          draftIdParam = encodeString(this.docRequest.draft.draftId);
        }
        await this.navigateToPageAndTerminateWorker({
          newRoute: path,
          query: { docRequestId: this.docRequest.id, draft: draftIdParam },
        });
      } else {
        const path = `${this.getRepoPath()}/docs/${this.docRequest.id}`;
        await this.navigateToPageAndTerminateWorker({ newRoute: path });
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.assignments {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.assignments .user-avatar {
  margin-right: 0;
  min-width: 24px;
}

.header {
  display: flex;
  flex-direction: column;

  .request-title {
    display: -webkit-box;
    overflow: hidden;
    margin-top: 10px;
    height: 3rem;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    word-break: break-word;

    .list-view & {
      height: initial;
    }
  }

  .request-description {
    display: -webkit-box;
    overflow: hidden;
    margin-top: 0;
    height: 2rem;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    word-break: break-word;

    .list-view & {
      height: initial;
    }
  }
}
</style>
