import { buildSwimmLink } from '@/swmd/swimm_node';
import { config } from '@swimm/shared';
import type { JSONContent } from '@tiptap/core';
import { Mark, Node as ProseMirrorNode, Schema } from '@tiptap/pm/model';
import { Transform } from '@tiptap/pm/transform';

/**
 * Strips Swimm-specific nodes from a ProseMirror document and returns a new MD document.
 * @param swimmDocument The Swimm document to strip.
 * @param schema The schema to use for the new document.
 */
export function stripSwmElementsFromMarkdown({
  slicedDocument,
  schema,
}: {
  slicedDocument: ProseMirrorNode;
  schema: Schema;
}): JSONContent {
  const tr = new Transform(slicedDocument);
  convertCustomSwimmNodes({ root: slicedDocument, tr, schema });
  return tr.doc.toJSON();
}

/**
 * Converts custom Swimm nodes to their Markdown equivalent.
 * @param root The root node of the document.
 * @param tr The transform to apply the changes to.
 * @param schema The schema to use for the new document.
 */
function convertCustomSwimmNodes({
  root,
  tr,
  schema,
}: {
  root: ProseMirrorNode;
  tr: Transform;
  schema: Schema;
}): Transform {
  const customNodes: { content: string; pos: number; node: ProseMirrorNode; mark?: Mark }[] = [];

  root.descendants((node, pos) => {
    switch (node.type.name) {
      case 'swmPath':
        customNodes.push({ content: node.attrs.href, pos, node, mark: schema.marks.code.create() });
        break;
      case 'swmToken':
        customNodes.push({ content: node.attrs.token, pos, node, mark: schema.marks.code.create() });
        break;
      case 'swmMention':
        customNodes.push({ content: node.attrs.name ?? node.attrs.email, pos, node, mark: schema.marks.code.create() });
        break;
      case 'swmLink':
        customNodes.push({
          content: node.attrs.docTitle,
          pos,
          node,
          mark: schema.marks.link.create({ href: buildSwimmLink(config.BASE_URL, node.attrs.repoId, node.attrs.path) }),
        });
        break;
      case 'swmSnippet':
        customNodes.push({ content: node.attrs.snippet, pos, node });
        break;
    }
  });

  // Apply the changes to the transform object.
  for (const { content, pos, node, mark } of customNodes) {
    // swmSnippets don't have relevant marks, we need to replace them entirely with a 'codeBlock' node.
    if (mark) {
      tr.replaceWith(tr.mapping.map(pos), tr.mapping.map(pos) + node.nodeSize, schema.text(content, [mark]));
    } else if (node.type.name === 'swmSnippet') {
      tr.replaceWith(
        tr.mapping.map(pos),
        tr.mapping.map(pos) + node.nodeSize,
        schema.node('codeBlock', node.attrs.language ? { language: node.attrs.language } : undefined, [
          schema.text(content),
        ])
      );
    }
  }

  return tr;
}
