import path from 'path-browserify';
import trimStart from 'lodash-es/trimStart';
import { BATCH_IMPORT_SCREENS, IMPORT_OPTIONS } from '@/modules/batch-import/constants';
import type { BatchImportFile, FileConversionReport } from '@/modules/batch-import/constants';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { type ImportOptions, useBatchImportStore } from '@/modules/batch-import/stores/batch-import';
import { type SwimmDocument, config, getLoggerNew, gitwrapper, removePrefix } from '@swimm/shared';
import { useStore } from 'vuex';
import { collectionNames } from '@/adapters-common/firestore-wrapper';
import { addNewTagToWorkspace } from '@/modules/doc-sidebar/services/tag-utils';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useRouting } from '@/common/composables/routing';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { type DraftAttributes, DraftType, type ImageDraft } from '@/modules/drafts3/db';
import {
  type LegacyOptions,
  fileExtensionToImageType,
  isDocEmpty,
  listImageNodes,
  parseSwmd,
  schema,
  swmLinkify,
} from '@swimm/swmd';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useDbWrapper } from '@/modules/editor/tiptapEditor/compositions/dbWrapper';
import { useRepoLinks } from '@/modules/editor3/composables/repo-links';
import { Transform } from '@tiptap/pm/transform';
import { getPathInZip } from '@/modules/batch-import/helpers/zipFileHelpers';
import { createImageDraft, uploadImage } from '@/modules/editor3/imageUpload';
import { useFoldersStore } from '@/modules/folders/store/folders';
import type { Folder } from '@/modules/folders/types';

const logger = getLoggerNew(__modulename);

type MarkdownContent = { content: string | null; error: FileConversionReport | null };
type UploadedImage = { url: string | null; error: FileConversionReport | null; imageDraft?: ImageDraft };
const IMPORTED_TAG_NAME = 'imported';

export const useBatchImportConvertFiles = () => {
  const store = useStore();
  const route = useRoute();
  const { user } = storeToRefs(useAuthStore());
  const { getCurrentOrDefaultBranch } = useRouting();
  const batchImportStore = useBatchImportStore();
  const foldersStore = useFoldersStore();
  const { setCurrentModalScreen } = batchImportStore;
  const {
    aborted,
    filesConverted,
    conversionType,
    rawFilesSelected,
    importRepoId,
    shouldUploadFilesToRepo,
    shouldCreateFolders,
    isCreateFoldersSupported,
  } = storeToRefs(batchImportStore);
  const drafts3Store = useDrafts3Store();

  const { dbWrapper } = useDbWrapper();

  const { repos } = useReposStore();
  const repoLinks = useRepoLinks(dbWrapper);

  const currentlyProccessingMessage = ref<string>('Loading...');
  const getAllWorkspaceTags = computed(() => store.getters['database/db_getAllWorkspaceTags']);

  const getImportTag = async () => {
    const workspaceTags = getAllWorkspaceTags.value(route.params.workspaceId);
    const foundImported = workspaceTags.find((tag) => tag.name === IMPORTED_TAG_NAME);
    if (foundImported) {
      return foundImported;
    }

    const newTag = await addNewTagToWorkspace(route.params.workspaceId as string, IMPORTED_TAG_NAME, user.value.uid);
    await store.dispatch('database/fetchDocumentChildCollections', {
      documentId: route.params.workspaceId,
      children: [collectionNames.TAGS],
      containerCollection: collectionNames.WORKSPACES,
    });

    return newTag;
  };

  const importFiles = async (files: BatchImportFile[]) => {
    const branch = await getCurrentOrDefaultBranch(importRepoId.value);

    const repo = store.getters['database/db_getRepoMetadata'](importRepoId.value);
    const workspaceId = route.params.workspaceId as string;

    const pathToDocId: Map<string, string> = new Map();

    for (const file of files) {
      if (aborted.value) {
        break;
      }

      currentlyProccessingMessage.value = file.name;

      const issues: FileConversionReport[] = [];
      const imageDrafts: ImageDraft[] = [];

      const { content, error } = await fileToMarkdown(file, importRepoId.value, branch);
      if (error) {
        issues.push(error);
        filesConverted.value.push({ ...file, issues });
        continue;
      }

      const legacyOptions: LegacyOptions = {
        baseUrl: config.BASE_URL,
        workspaceId,
        repoId: importRepoId.value,
        repoName: repo?.name ?? '',
        repos: [],
      };
      const swimmDocument: SwimmDocument = parseSwmd(content, { legacy: legacyOptions });
      const doc = ProseMirrorNode.fromJSON(schema, swimmDocument.content);

      // Upload images and update their src on the document
      const tr = new Transform(doc);

      for (const imageNode of listImageNodes(doc)) {
        const filePath = decodeURIComponent(imageNode.node.attrs.src);
        const resolvedPath = path.resolve('/', path.dirname(file.path), filePath); // Make sure the image path is root relative - otherwise the image is not properly imported

        // Make sure the path is not a remote path so we don't fetch ourselves something we do not want to access
        if (!filePath || isRemoteFile(filePath) || !resolvedPath || isRemoteFile(resolvedPath)) {
          continue;
        }

        const { url, error, imageDraft } = await importImage(
          conversionType.value,
          workspaceId,
          importRepoId.value,
          branch,
          resolvedPath,
          rawFilesSelected.value
        );

        if (error != null) {
          issues.push(error);
        } else {
          tr.setNodeAttribute(imageNode.pos, 'src', url);
          if (imageDraft) {
            imageDrafts.push(imageDraft);
          }
        }
      }

      // Strip filename from html and md extension
      const fileExtension = removePrefix(path.extname(file.name), '.');
      const cleanFileName = file.name.replace(`.${fileExtension}`, '');

      if (swimmDocument.title == null) {
        swimmDocument.title = cleanFileName;
      }
      swimmDocument.content = tr.doc.toJSON();

      const id = await drafts3Store.newDoc(swimmDocument);
      for (const imageDraft of imageDrafts) {
        imageDraft.draftId = id;
        await drafts3Store.saveImageDraft(imageDraft);
      }

      // build draft attributes (tag and folder)
      const importTag = await getImportTag();
      const attrs: DraftAttributes = {};
      if (importTag?.id) {
        attrs.tags = [importTag.id];
      }
      let docFolder: Folder | null = null;
      try {
        if (shouldCreateFolders.value && isCreateFoldersSupported.value) {
          docFolder = await createDocFolder(file);
        }
      } catch (err) {
        logger.error({ err }, `Failed to create folders when importing`);
      }
      if (docFolder) {
        logger.info(`For ${file.name} got folder ${docFolder.name}`);
        attrs.folderId = docFolder.id;
        attrs.folderIndex = docFolder.children.length;
      }
      await drafts3Store.updateAttrs(id, attrs);

      pathToDocId.set(file.path, id);
      filesConverted.value.push({ ...file, swm: drafts3Store.drafts?.get(id), issues });
    }

    if (aborted.value) {
      return;
    }

    currentlyProccessingMessage.value = 'Processing...';

    for (const convertedFile of filesConverted.value) {
      if (convertedFile.swm.type === DraftType.DOC && 'content' in convertedFile.swm) {
        const swimmDocument = convertedFile.swm.content;
        if (!isDocEmpty(swimmDocument.content)) {
          const linkifiedContent: ProseMirrorNode = await swmLinkify(
            convertedFile.path,
            ProseMirrorNode.fromJSON(schema, swimmDocument.content),
            importRepoId.value,
            branch,
            { ...repoLinks, repos: computed(() => ({ repos, loading: false })).value },
            pathToDocId
          );

          swimmDocument.content = linkifiedContent.toJSON();

          // Make sure consecutive draft saves do not overwrite each other
          await drafts3Store.saveDraftImmediately(convertedFile.swm.id, {
            type: DraftType.DOC,
            content: swimmDocument,
          });
          convertedFile.swm.content = swimmDocument;
        }
      }
    }

    if (filesConverted.value.filter((file) => file.issues.length).length) {
      setCurrentModalScreen(BATCH_IMPORT_SCREENS.IMPORT_REPORT);
    } else {
      setCurrentModalScreen(BATCH_IMPORT_SCREENS.IMPORT_SUMMARY);
    }
  };

  async function createDocFolder(file: BatchImportFile): Promise<Folder> {
    const pathParts = file.path.split('/');
    pathParts.pop(); // remove the file name
    let parentFolderId = await getRootFolderId();
    for (const pathPart of pathParts) {
      const folderForName = foldersStore.getFolderByName(pathPart, parentFolderId, importRepoId.value);
      if (folderForName == null) {
        parentFolderId = await foldersStore.addFolder({
          name: pathPart,
          repoId: importRepoId.value,
          isRoot: parentFolderId == null,
          parentFolderId,
        });
      } else {
        parentFolderId = folderForName.id;
      }
    }
    const result = foldersStore.getFolder(parentFolderId, importRepoId.value);
    if (result == null) {
      throw new Error(`Failed in createDocFolder - results in null folder`);
    }
    return result;
  }

  async function getRootFolderId(): Promise<string> {
    // get or creates the root folder and returns its id
    // throw an error if failed for some reason
    let rootFolder: Folder = foldersStore.getRepoRootFolder(importRepoId.value);
    if (!rootFolder) {
      await foldersStore.initRootFolder(importRepoId.value);
      rootFolder = foldersStore.getRepoRootFolder(importRepoId.value);
    }
    if (!rootFolder) {
      throw new Error(`Failed to get or create root folder while importing markdowns`);
    }
    return rootFolder.id;
  }

  async function fileToMarkdown(file: BatchImportFile, repoId: string, branch: string): Promise<MarkdownContent> {
    // When the file is retrieved from the tree, we need to get the file content from revision
    // When the file is uploaded from the Client, we have its content.
    if (!file.file) {
      // User has chose to import from repo
      return await fetchMarkdownFromRevision(file.path, branch, repoId);
    }

    // We are importing a md file
    return { content: file.file as string, error: null };
  }

  async function fetchMarkdownFromRevision(
    filePath: string,
    revision: string,
    repoId: string
  ): Promise<MarkdownContent> {
    const content = await gitwrapper.getFileContentFromRevision({
      filePath,
      revision,
      repoId,
    });
    return {
      content,
      error: content == null ? { type: 'ERROR', reason: `File ${filePath} not found in repository` } : null,
    };
  }

  async function importImage(
    conversionType: ImportOptions,
    workspaceId: string,
    repoId: string,
    branch: string,
    filePath: string,
    importedFiles: { file: File; path: string; fileContent: string }[]
  ): Promise<UploadedImage> {
    const fetchImagesFromRepo = conversionType === IMPORT_OPTIONS.RUN_SMART_IMPORT;
    return fetchImagesFromRepo
      ? { url: filePath, error: null } // filePath is represented as a root relative path - which read directly from the repo
      : importLocalImage(workspaceId, repoId, branch, filePath, importedFiles);
  }

  async function importLocalImage(
    workspaceId: string,
    repoId: string,
    branch: string,
    filePath: string,
    importedFiles: { file: File; path: string; fileContent: string }[]
  ): Promise<UploadedImage> {
    const uploadedImage = { url: null, error: null, imageDraft: null };
    try {
      // Some providers provide image src with query params and we use a URL object to safely remove query params
      const fileURI = new URL(filePath, 'http://notreal');
      const importedFile = importedFiles.find(({ path }) => getPathInZip(path) === trimStart(fileURI.pathname, '/'));

      if (importedFile) {
        const fileExtension = removePrefix(path.extname(fileURI.pathname), '.');
        const fileType = fileExtensionToImageType(fileExtension);
        const fileName = path.basename(fileURI.pathname);
        const buff = Buffer.from(importedFile.fileContent, 'base64');
        const imageFile = new File([buff], fileName, { type: fileType });

        if (!shouldUploadFilesToRepo.value) {
          const { src } = await uploadImage(workspaceId, repoId, imageFile);
          uploadedImage.url = src;
        } else {
          // We don't have the draft id yet, will be filled before save
          const imageDraft = await createImageDraft(workspaceId, repoId, branch, user.value.uid, '', imageFile);

          uploadedImage.url = imageDraft.path;
          uploadedImage.imageDraft = imageDraft;
        }
      } else {
        uploadedImage.url = null;
        uploadedImage.error = {
          type: 'WARNING',
          reason: `Failed to upload file ${filePath}, Error: Could not find image on zip`,
        };
      }
    } catch (err) {
      logger.error(err, `error while importing image from md file.`);
      uploadedImage.url = null;
      uploadedImage.error = {
        type: 'WARNING',
        reason: `Failed to upload file ${filePath}, Error: ${err.message}`,
      };
    }
    return uploadedImage;
  }

  function isRemoteFile(filePath: string) {
    return filePath.startsWith('http') || filePath.startsWith('data:image');
  }

  return {
    aborted,
    currentlyProccessingMessage,
    importFiles,
  };
};
