// @ts-strict
import {
  ApplicabilityStatus,
  AutosyncInput,
  AutosyncOutput,
  SmartElement,
  SmartElementType,
  SmartElementWithApplicability,
  SmartSymbol,
  Snippet,
  SwmCellSnippet,
  SwmCellType,
  SwmFile,
  SwmSymbolType,
} from '../types';
import * as objectUtils from '../objectUtils';
import { isSmartElementWithNewInfo } from '../utils/hunk-utils';

export function getElementById<T extends SmartElement>({ elements, id }: { elements: T[]; id: string }): T {
  return elements.filter((snippet) => snippet.id === id)?.[0] ?? null;
}

function setDataInSnippetCell({
  snippetCellToChange,
  id,
  applicability,
  firstLineNumber,
  lines,
  originalInfo,
  path,
}: {
  snippetCellToChange: SwmCellSnippet;
  applicability: ApplicabilityStatus;
  id: string;
  firstLineNumber: number;
  lines: string[];
  originalInfo: SwmCellSnippet;
  path: string;
}) {
  snippetCellToChange.applicability = applicability;
  snippetCellToChange.path = path;
  snippetCellToChange.firstLineNumber = firstLineNumber;
  snippetCellToChange.id = id;
  snippetCellToChange.lines = [...lines];
  snippetCellToChange.originalInfo = { ...originalInfo, lines: [...originalInfo.lines] };
}

function setSnippetsInSwm({
  swmToChange,
  snippets,
  shouldSyncCrossRepo,
  autosyncInputSnippets,
}: {
  swmToChange: SwmFile;
  snippets: SmartElementWithApplicability<Snippet>[];
  shouldSyncCrossRepo: boolean;
  autosyncInputSnippets: Snippet[];
}) {
  for (const snippetCellToChange of swmToChange.content) {
    if (snippetCellToChange.type !== SwmCellType.Snippet) {
      continue;
    }

    const originalCell = {
      ...snippetCellToChange,
      lines: [...snippetCellToChange.lines],
    };

    const correlatingSnippet = getElementById({ elements: snippets, id: snippetCellToChange.id });
    if (!correlatingSnippet) {
      if (getElementById({ elements: autosyncInputSnippets, id: snippetCellToChange.id })) {
        // we failed when processing the snippet, set as outdated
        setDataInSnippetCell({
          snippetCellToChange,
          applicability: ApplicabilityStatus.Outdated,
          firstLineNumber: snippetCellToChange.firstLineNumber,
          lines: snippetCellToChange.lines,
          id: originalCell.id,
          originalInfo: originalCell,
          path: originalCell.path,
        });
      } else if (shouldSyncCrossRepo) {
        // cross repo snippet we don't have access to, marking as verified and handling in editor
        setDataInSnippetCell({
          snippetCellToChange,
          applicability: ApplicabilityStatus.Verified,
          firstLineNumber: originalCell.firstLineNumber,
          id: originalCell.id,
          lines: originalCell.lines,
          originalInfo: originalCell,
          path: originalCell.path,
        });
      } else {
        // ignoring cross repo snippets
        continue;
      }
    } else if (!isSmartElementWithNewInfo(correlatingSnippet)) {
      setDataInSnippetCell({
        snippetCellToChange,
        applicability: correlatingSnippet.applicability,
        firstLineNumber: correlatingSnippet.startLineNumber,
        id: originalCell.id,
        lines: correlatingSnippet.lines,
        originalInfo: originalCell,
        path: originalCell.path,
      });
    } else {
      setDataInSnippetCell({
        snippetCellToChange,
        applicability: correlatingSnippet.applicability,
        firstLineNumber: correlatingSnippet.newInfo.startLineNumber,
        lines: correlatingSnippet.newInfo.lines,
        id: originalCell.id,
        originalInfo: originalCell,
        path: correlatingSnippet.newInfo.filePath,
      });
    }
  }
}

function setSymbolsInSwm({
  swmToChange,
  autosyncSymbols,
  shouldSyncCrossRepo,
  autosyncInputSymbols,
}: {
  swmToChange: SwmFile;
  autosyncSymbols: SmartElementWithApplicability<SmartSymbol>[];
  shouldSyncCrossRepo: boolean;
  autosyncInputSymbols: SmartSymbol[];
}) {
  for (const [id, symbolToChange] of Object.entries(swmToChange.symbols)) {
    if (symbolToChange.type !== SwmSymbolType.GENERIC_TEXT && symbolToChange.type !== SwmSymbolType.PATH) {
      continue;
    }

    const originalSymbol = {
      ...symbolToChange,
      ...(symbolToChange.type === SwmSymbolType.GENERIC_TEXT ? { wordIndex: { ...symbolToChange.wordIndex } } : {}),
    };

    const correlatingSymbol = getElementById({ elements: autosyncSymbols, id });
    if (!correlatingSymbol) {
      if (getElementById({ elements: autosyncInputSymbols, id })) {
        // we failed when processing the symbol, set as outdated
        symbolToChange.applicability = ApplicabilityStatus.Outdated;
      } else if (shouldSyncCrossRepo) {
        // cross repo snippet we don't have access to, marking as verified and handling in editor
        symbolToChange.applicability = ApplicabilityStatus.Verified;
      } else {
        // ignoring cross repo snippets
        continue;
      }
    } else {
      symbolToChange.applicability = correlatingSymbol.applicability;
      if (isSmartElementWithNewInfo(correlatingSymbol)) {
        symbolToChange.originalInfo = originalSymbol;
        symbolToChange.path = correlatingSymbol.newInfo.filePath;
        symbolToChange.text =
          correlatingSymbol.newInfo.type === SmartElementType.LINK
            ? correlatingSymbol.newInfo.docTitle
            : correlatingSymbol.newInfo.symbolText;

        if (
          symbolToChange.type === SwmSymbolType.GENERIC_TEXT &&
          correlatingSymbol.type === SmartElementType.TOKEN &&
          correlatingSymbol.newInfo.type === SmartElementType.TOKEN
        ) {
          symbolToChange.lineData = correlatingSymbol.newInfo.lineContent;
          symbolToChange.lineNumber = correlatingSymbol.newInfo.lineNumber;
          symbolToChange.wordIndex = { ...correlatingSymbol.newInfo.wordIndex };
        }
      }
    }
  }
}

export function convertAutosyncOutputToSwmObject({
  originalSwm,
  autosyncOutput,
  autosyncInput,
  shouldSyncCrossRepo,
}: {
  originalSwm: SwmFile;
  autosyncOutput: AutosyncOutput;
  autosyncInput: AutosyncInput;
  shouldSyncCrossRepo: boolean;
}): SwmFile {
  const newSwm = objectUtils.deepClone(originalSwm);
  setSnippetsInSwm({
    swmToChange: newSwm,
    snippets: autosyncOutput.snippets,
    shouldSyncCrossRepo,
    autosyncInputSnippets: autosyncInput.snippets,
  });
  setSymbolsInSwm({
    swmToChange: newSwm,
    autosyncSymbols: autosyncOutput.symbols,
    shouldSyncCrossRepo,
    autosyncInputSymbols: autosyncInput.symbols,
  });
  newSwm.applicability = autosyncOutput.applicability;
  return newSwm;
}
