<script setup lang="ts">
import { ref } from 'vue';
import { Loader } from '@swimm/ui';

const props = defineProps({
  isDisabled: { type: Boolean, default: false },
  disabledReason: { type: String, default: null },
});

const emit = defineEmits(['select']);

const isLoading = ref(false);

function onSelect() {
  if (props.isDisabled) {
    return;
  }
  isLoading.value = true;
  emit('select');
}
</script>

<template>
  <div class="shortcuts">
    <Loader class="loader" v-if="isLoading" />
    <div
      v-else
      :class="['key-label-pair', 'confirm', { disabled: isDisabled }]"
      @click="onSelect"
      data-testid="confirm-move"
      v-tooltip="isDisabled ? disabledReason : null"
    >
      Confirm move
    </div>
  </div>
</template>

<style scoped lang="postcss">
.shortcuts {
  background-color: var(--color-surface);
  padding: var(--space-base) var(--space-sm);
  color: var(--text-color-secondary);

  .key-label-pair {
    gap: var(--space-base);
    display: inline-flex;
    margin-right: var(--space-sm);

    &.confirm {
      color: var(--text-color-link);
      cursor: pointer;
    }

    &.disabled {
      color: var(--text-color-disable);
      cursor: not-allowed;
    }
  }

  .loader {
    --loader-size: 24px;
  }
}
</style>
