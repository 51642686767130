import { Decoration } from '@tiptap/pm/view';

import type { NodeSuggestion, TextSuggestions } from './docTraverse';

export interface SwimmportDecorationSpec {
  suggestions: TextSuggestions;
}

export const SWIMMPORT_SUGGESTION_CLASS = 'swimmport-suggestion';

const SWIMMPORT_DECORATION_STYLE =
  '--suggestion-line-width: 2px; box-sizing: border-box; margin-bottom: calc(-1 * var(--suggestion-line-width)); border-bottom: dotted var(--suggestion-line-width) var(--high-violet);';

export function swimmportSuggestionToDecoration(suggestion: NodeSuggestion) {
  const spec: SwimmportDecorationSpec = { suggestions: suggestion.result };
  return Decoration.inline(
    suggestion.from,
    suggestion.to,
    {
      nodeName: 'span',
      class: SWIMMPORT_SUGGESTION_CLASS,
      style: SWIMMPORT_DECORATION_STYLE,
      id: `swimmport-suggestion-${suggestion.text}-${suggestion.from}`,
      spellcheck: 'false',
      'data-testid': 'swimmport-suggestion',
      'data-from': suggestion.from.toString(),
      'data-to': suggestion.to.toString(),
      'data-suggestions': JSON.stringify(suggestion.result), // TODO: We might want to avoid putting all the suggestions in the decoration.
    },
    spec
  );
}
