<script setup lang="ts">
import FilesSelection from '@/modules/batch-import/components/FilesSelection.vue';
import { useBatchImportStore } from '@/modules/batch-import/stores/batch-import';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { BATCH_IMPORT_SCREENS, MD_FILE_KINDS } from '@/modules/batch-import/constants';
import { BATCH_IMPORT_EVENTS, BATCH_IMPORT_ORIGINS } from '../constants/analytics-events';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { Checkbox, Divider } from '@swimm/ui';
import { CreationHubSections } from '@/modules/core/creation-hub/CreationHubConsts';

const emit = defineEmits(['show-creation-hub']);

const analytics = useAnalytics();
const batchImportStore = useBatchImportStore();
const { setCurrentModalScreen } = batchImportStore;
const {
  conversionType,
  mdFiles,
  readmeMDFiles,
  mdFilesSelected,
  readmeFilesSelected,
  shouldUploadFilesToRepo,
  shouldCreateFolders,
  isCreateFoldersSupported,
} = storeToRefs(batchImportStore);

/**
  Initialize the filesSelected entries with the files ids
  - Set all MD to true
  - Set all readme to false
*/
mdFiles.value.forEach((file) => {
  mdFilesSelected.value[file.id] = true;
});
readmeMDFiles.value.forEach((file) => {
  readmeFilesSelected.value[file.id] = false;
});

// reset the should create folders to false
shouldCreateFolders.value = false;

// Show both sections when no files exists
const showMDFilesSection = computed(() => Boolean(mdFiles.value.length) || Boolean(!readmeMDFiles.value.length));
const showReadmeFilesSection = computed(() => Boolean(readmeMDFiles.value.length) || Boolean(!mdFiles.value.length));
const isBothSectionsEmpty = computed(() => Boolean(!readmeMDFiles.value.length) && Boolean(!mdFiles.value.length));
const showDivider = computed(() => showMDFilesSection.value && showReadmeFilesSection.value);

const handleNextClicked = async () => {
  if (isBothSectionsEmpty.value) {
    // Go to creation hub (emit from Modal)
    emit('show-creation-hub', CreationHubSections.IMPORT);
  } else {
    const mdFilesSelectedFiles = mdFiles.value.filter((file) => mdFilesSelected.value[file.id]);
    const readmeFilesSelectedFiles = readmeMDFiles.value.filter((file) => readmeFilesSelected.value[file.id]);
    analytics.track(BATCH_IMPORT_EVENTS.FILES_SELECTED, {
      Origin: BATCH_IMPORT_ORIGINS.CREATION_HUB,
      'Import Type': conversionType.value,
      'MD Files Count': mdFilesSelectedFiles.length,
      'Readme Files Count': readmeFilesSelectedFiles.length,
      'Files names': [...mdFilesSelectedFiles, ...readmeFilesSelectedFiles].map((file) => file.name).join(', '),
    });
    setCurrentModalScreen(BATCH_IMPORT_SCREENS.IMPORT_PROCESS);
  }
};

const fileKinds = [
  {
    id: MD_FILE_KINDS.MD_FILES.id,
    kind: MD_FILE_KINDS.MD_FILES.title,
    show: showMDFilesSection.value,
    files: mdFiles.value,
    filesSelected: mdFilesSelected.value,
    allSelected: (value) => {
      for (const key of Object.keys(mdFilesSelected.value)) {
        mdFilesSelected.value[key] = value;
      }
    },
    fileSelected: ({ fileId, checked }) => {
      mdFilesSelected.value[fileId] = checked;
    },
  },
  {
    id: MD_FILE_KINDS.README_FILES.id,
    kind: MD_FILE_KINDS.README_FILES.title,
    show: showReadmeFilesSection.value,
    files: readmeMDFiles.value,
    filesSelected: readmeFilesSelected.value,
    allSelected: (value) => {
      for (const key of Object.keys(readmeFilesSelected.value)) {
        readmeFilesSelected.value[key] = value;
      }
    },
    fileSelected: ({ fileId, checked }) => {
      readmeFilesSelected.value[fileId] = checked;
    },
  },
];
</script>

<template>
  <div class="files-selection-container">
    <SwText class="files-description" variant="body-S">
      Select the Markdown files you wish to import. They will be imported as local drafts.
    </SwText>
    <div class="files-selection">
      <template v-for="(fileKind, index) in fileKinds" :key="`${fileKind.id}-${index}`">
        <div v-if="fileKind.show" class="file-selection">
          <FilesSelection
            :id="fileKind.id"
            :kind="fileKind.kind"
            :files="fileKind.files"
            :files-selected="fileKind.filesSelected"
            @select-all="fileKind.allSelected"
            @file-selected="fileKind.fileSelected"
          />
        </div>
        <Divider v-if="showDivider && index !== fileKinds.length - 1" />
      </template>
      <Divider />
      <Checkbox
        v-if="isCreateFoldersSupported"
        size="small"
        class="create-folders-checkbox"
        :model-value="shouldCreateFolders"
        @update:model-value="(v) => (shouldCreateFolders = v)"
      >
        <SwText variant="body-S">Create Swimm folders according to imported files paths</SwText></Checkbox
      >
    </div>
    <div class="actions-container">
      <SwText variant="body-XS" class="images-disclaimer">
        <span v-if="shouldUploadFilesToRepo">Images imported to Swimm will be stored on Swimm's servers.</span>
      </SwText>
      <div class="actions">
        <Action :disabled="false" data-testid="import-files-next-button" @click="handleNextClicked">
          {{ isBothSectionsEmpty ? 'Try other options' : 'Start import' }}
        </Action>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.files-selection-container {
  .files-description {
    line-height: 1.5rem;
  }

  .file-selection {
    padding: var(--space-sm) 0 var(--space-md);
  }

  .create-folders-checkbox {
    padding-top: var(--space-sm);
  }

  .actions-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: var(--space-sm);

    .images-disclaimer {
      flex: 1;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & .button:first-of-type {
        margin-right: var(--space-base);
      }
    }
  }
}
</style>
