<template>
  <VMenu
    v-if="isVisible"
    theme="help-tooltip"
    :popper-class="popperClass"
    :auto-hide="false"
    :placement="placement"
    :distance="16"
    :show-triggers="['click']"
    :hide-triggers="[]"
    :popper-hide-triggers="[]"
    @show="onOpenClicked"
  >
    <Hotspot />
    <template #popper>
      <div class="container">
        <div class="title">
          <span class="title-text subtitle-L">{{ title }}</span>
          <IconButton name="close" class="close-button" @click="onCloseClicked" />
        </div>
        <div class="subtitle-text body-S">
          <slot name="subtitle" />
        </div>
      </div>
    </template>
  </VMenu>
</template>

<script>
import { computed, toRefs } from 'vue';
import { useHelpTooltips } from '../composables/useHelpTooltips';
import { HelpTooltipPriorities, HelpTooltipTypes } from '@swimm/editor';
import Hotspot from '@/modules/core/components/Hotspot.vue';
import { IconButton } from '@swimm/ui';

const PLACEMENTS = ['top', 'right', 'bottom', 'left'];
const HELP_TOOLTIP_KEYS = [
  'branch-navigation',
  'snippet-is-up-to-date',
  'your-docs-need-updating',
  'demo-we-keep-your-docs',
  'demo-add-smart-code-paths',
  'demo-add-smart-code-tokens',
  'pr-files-tips',
];

export default {
  components: { Hotspot, IconButton },
  props: {
    title: { type: String, required: true },
    placement: {
      type: String,
      default: 'top',
      validator: (value) => PLACEMENTS.includes(value),
    },
    tooltipKey: {
      type: String,
      required: true,
      validator: (value) => HELP_TOOLTIP_KEYS.includes(value),
    },
    priority: { type: Number, default: HelpTooltipPriorities.PRIORITY_LEVEL_1 },
    showNewUsersOnly: { type: Boolean, default: true },
    showHotSpotOnceForUser: { type: Boolean, default: false },
  },
  setup(props) {
    const { title, tooltipKey, priority, showNewUsersOnly, showHotSpotOnceForUser } = toRefs(props);

    const { isVisible, onHotspotClicked, onTooltipClosed } = useHelpTooltips({
      title,
      tooltipKey,
      priority,
      showNewUsersOnly,
      showHotSpotOnceForUser,
      type: HelpTooltipTypes.TOOLTIP,
    });

    const popperClass = computed(() => `${props.placement}-placement`);

    function onOpenClicked() {
      onHotspotClicked();
    }

    function onCloseClicked() {
      onTooltipClosed();
    }

    return { isVisible, popperClass, onOpenClicked, onCloseClicked };
  },
};
</script>

<style scoped lang="postcss">
.container {
  background: var(--color-bg);
  border-radius: 6px;
  padding: var(--space-base);
  gap: var(--space-base);
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  justify-content: space-between;

  .title-text {
    font-weight: 800;
  }
}

.subtitle-text {
  white-space: pre-line;
  line-height: 1.33em;
}

.close-button {
  align-self: flex-start;
}
</style>
