import {
  collectionNames,
  firestoreTimestamp,
  firestoreTimestampInSeconds,
  getDocFromSubCollection,
  setValuesInDocInSubCollection,
} from '@/adapters-common/firestore-wrapper';
import { config, getLoggerNew } from '@swimm/shared';
import type { DocCacheStructure } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export async function setInCache({
  workspaceId,
  cacheId,
  objectToSet,
}: {
  workspaceId: string;
  cacheId: string;
  objectToSet: DocCacheStructure;
}) {
  const response = await setValuesInDocInSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.AUTOSYNC_CACHE,
    cacheId,
    {
      cached_data: JSON.stringify(objectToSet),
      expired_at: firestoreTimestampInSeconds(4 * 24 * 3600),
      created: firestoreTimestamp(),
    }
  );

  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(
      `Couldn't set ${cacheId} in autosync cache in workspace ${workspaceId}. error: ${response.errorMessage}`
    );
  }
}

export async function getFromCache({
  workspaceId,
  cacheId,
}: {
  workspaceId: string;
  cacheId: string;
}): Promise<DocCacheStructure | null> {
  const response = await getDocFromSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.AUTOSYNC_CACHE,
    cacheId
  );

  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.debug(
      `Cache document ID ${cacheId} doesn't exist in workspace ${workspaceId}. error: ${response.errorMessage}`
    );
    return null;
  }

  logger.debug(`Cache document ID ${cacheId} exists in workspace ${workspaceId}`);
  return JSON.parse(response.data.cached_data) as DocCacheStructure;
}
