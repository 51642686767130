// all samples are taken from mermaid.live
const MermaidSamples = {
  SEQUENCE: `sequenceDiagram
    Alice->>+John: Hello John, how are you?
    Alice->>+John: John, can you hear me?
    John-->>-Alice: Hi Alice, I can hear you!
    John-->>-Alice: I feel great!`,
  FLOW: `graph TD
    A[Christmas] -->|Get money| B(Go shopping)
    B --> C{Let me think}
    C -->|One| D[Laptop]
    C -->|Two| E[iPhone]
    C -->|Three| F[fa:fa-car Car]`,
  CLASS: `classDiagram
    Animal <|-- Duck
    Animal <|-- Fish
    Animal <|-- Zebra
    Animal : +int age
    Animal : +String gender
    Animal: +isMammal()
    Animal: +mate()
    class Duck{
      +String beakColor
      +swim()
      +quack()
    }
    class Fish{
      -int sizeInFeet
      -canEat()
    }
    class Zebra{
      +bool is_wild
      +run()
    }`,
  STATE: `stateDiagram-v2
    [*] --> Still
    Still --> [*]
    Still --> Moving
    Moving --> Still
    Moving --> Crash
    Crash --> [*]`,
  ER: `erDiagram
    CUSTOMER }|..|{ DELIVERY-ADDRESS : has
    CUSTOMER ||--o{ ORDER : places
    CUSTOMER ||--o{ INVOICE : "liable for"
    DELIVERY-ADDRESS ||--o{ ORDER : receives
    INVOICE ||--|{ ORDER : covers
    ORDER ||--|{ ORDER-ITEM : includes
    PRODUCT-CATEGORY ||--|{ PRODUCT : contains
    PRODUCT ||--o{ ORDER-ITEM : "ordered in"`,
  GANTT: `gantt
    title A Gantt Diagram
    dateFormat  YYYY-MM-DD
    section Section
    A task           :a1, 2014-01-01, 30d
    Another task     :after a1  , 20d
    section Another
    Task in sec      :2014-01-12  , 12d
    another task      : 24d`,
  USER_JOURNEY: `journey
    title My working day
    section Go to work
      Make tea: 5: Me
      Go upstairs: 3: Me
      Do work: 1: Me, Cat
    section Go home
      Go downstairs: 5: Me
      Sit down: 3: Me`,
  GIT: `gitGraph
    commit
    commit
    branch develop
    checkout develop
    commit
    commit
    checkout main
    merge develop
    commit
    commit`,
  PIE: `pie title Pets adopted by volunteers
    "Dogs" : 386
    "Cats" : 85
    "Rats" : 15`,
  BLOCK: `block-beta
  columns 1
    db(("DB"))
    blockArrowId6<["&nbsp;&nbsp;&nbsp;"]>(down)
    block:ID
      A
      B["A wide one in the middle"]
      C
    end
    space
    D
    ID --> D
    C --> D
    style B fill:#969,stroke:#333,stroke-width:4px`,
};

export type MermaidSampleOption = {
  label: string;
  content: string;
  icon: string;
};

export const mermaidSamplesOptions: MermaidSampleOption[] = [
  { label: 'Sequence', content: MermaidSamples.SEQUENCE, icon: 'graph-sequence' },
  { label: 'Flow chart', content: MermaidSamples.FLOW, icon: 'graph-flow' },
  { label: 'Class', content: MermaidSamples.CLASS, icon: 'graph-class' },
  { label: 'State', content: MermaidSamples.STATE, icon: 'graph-state' },
  { label: 'Relation', content: MermaidSamples.ER, icon: 'graph-relation' },
  { label: 'Gantt', content: MermaidSamples.GANTT, icon: 'graph-gantt' },
  { label: 'User journey', content: MermaidSamples.USER_JOURNEY, icon: 'graph-user-journey' },
  { label: 'Git', content: MermaidSamples.GIT, icon: 'branch-merged-pr' },
  { label: 'Pie', content: MermaidSamples.PIE, icon: 'graph-pie' },
  { label: 'Block (beta)', content: MermaidSamples.BLOCK, icon: 'graph-block' },
];
