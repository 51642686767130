import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';
import { type NeedsReviewNode, useEditorStore } from '@swimm/editor';
import { inject } from 'vue';
import type { Editor } from '@tiptap/vue-3';
import type { ReviewAutosyncFunctions } from '@swimm/editor';

export function useReviewAutosyncFunctions(): ReviewAutosyncFunctions {
  const analyticsMeta = inject('analyticsMeta', null);
  const editorStore = useEditorStore();
  const analytics = useAnalytics();
  function reportNodeScrolled() {
    if (analyticsMeta) {
      const analyticsData = { 'Document ID': analyticsMeta.unitId.value, 'Document Name': analyticsMeta.name.value };
      analytics.track(productEvents.CLICKED_NEEDS_REVIEW_ITEM, analyticsData);
    }
  }

  const goToEditDoc = inject('navigateToEditDoc', () => {
    return;
  });

  function handleReviewOutDated() {
    goToEditDoc();
  }
  function acceptAll(instancesNeedsReview: NeedsReviewNode) {
    Object.values(instancesNeedsReview).forEach((node) => {
      node[0].accept(editorStore.editorInstance as unknown as Editor);
    });
  }
  return { acceptAll, handleReviewOutDated, reportNodeScrolled };
}
