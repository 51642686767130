<template>
  <PushChangesPopUp
    v-if="Object.values(repoBranchDetails).length"
    v-model="pushCommitMessage"
    :repo-branch-details="repoBranchDetails"
    :repo-id="options.repoId"
    :loading="loading"
    :branch-name="options.branchName"
    :selected-save-option="options.selectedSaveOption"
    :heading="options.heading"
    @create-pr="createPR"
    @create-branch="createBranch"
    @push-to-branch="pushToBranch"
    @close-popup="close"
  />
</template>

<script lang="ts">
import PushChangesPopUp from '@/common/components/organisms/PushChangesPopUp.vue';
import { useRouting } from '@/common/composables/routing';
import { PushData } from '@/modules/batch-commit/store/batch-commit';
import { useGlobalModalStore } from '@/modules/core/modals/stores/modals-store';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { storeToRefs } from 'pinia';

export default {
  components: { PushChangesPopUp },
  props: {
    options: {
      type: Object,
      default: () => ({}),
      repoId: { type: String, required: true },
      commitMessage: { type: String, default: '' },
      createBranch: { type: Function, default: () => ({}) },
      createPR: { type: Function, default: () => ({}) },
      commitToBranch: { type: Function, default: () => ({}) },
      branchName: { type: String, default: '' },
      selectedSaveOption: { type: String, default: '' },
      heading: { type: String, default: '' },
    },
  },
  setup() {
    const { getCurrentOrDefaultBranch, isBranchProtected } = useRouting();
    const { closeModal } = useGlobalModalStore();
    const { reposStateData } = storeToRefs(useReposStore());

    return { reposStateData, isBranchProtected, getCurrentOrDefaultBranch, closeModal };
  },
  data: function () {
    return {
      pushCommitMessage: '',
      repoBranchDetails: {},
      loading: false,
    };
  },
  async created() {
    this.pushCommitMessage = this.options.commitMessage;
    const repoDataFromState = this.reposStateData[this.options.repoId];
    if (repoDataFromState) {
      const branch = await this.getCurrentOrDefaultBranch(this.options.repoId);
      this.repoBranchDetails = {
        branch,
        defaultBranch: repoDataFromState.defaultBranch,
        isProtectedBranch: await this.isBranchProtected({ repoId: this.options.repoId, branch }),
      };
    }
  },
  methods: {
    async createPR(pushData: PushData) {
      this.loading = true;
      await this.options.createPR(pushData, this.stopLoading);
    },
    async createBranch(pushData: PushData) {
      this.loading = true;
      await this.options.createBranch(pushData, this.stopLoading);
    },
    async pushToBranch(pushData: PushData) {
      this.loading = true;
      await this.options.commitToBranch(pushData, this.stopLoading);
    },
    stopLoading() {
      this.loading = false;
    },
    close() {
      this.closeModal();
    },
  },
};
</script>
