<template>
  <InstanceNotification :title="title" :notification="notification" />
</template>

<script>
import InstanceNotification from '@/modules/notifications-center/components/InstanceNotification.vue';

export default {
  components: { InstanceNotification },
  props: {
    notification: { type: Object, default: () => ({}) },
  },
  computed: {
    title() {
      return `You've used up 90% of your ${this.notification.topic_name} quota for this month`;
    },
  },
};
</script>
