import { ApplicabilityStatus } from '../swimm-patch-common';

export enum SmartElementType {
  SNIPPET = 'snippet',
  PATH = 'path',
  TOKEN = 'token',
  LINK = 'link',
}

export interface GitInfo {
  repoId: string;
  repoOwner: string;
  repoName: string;
  apiUrl?: string;
  branch: string;
}

export interface SmartElement {
  id: string;
  type: SmartElementType;
  gitInfo?: GitInfo;
}

export interface Snippet extends SmartElement {
  type: SmartElementType.SNIPPET;
  lines: string[];
  filePath: string;
  startLineNumber: number;
}

export interface Token extends SmartElement {
  type: SmartElementType.TOKEN;
  symbolText: string;
  lineContent: string;
  filePath: string;
  lineNumber: number;
  wordIndex: {
    start: number;
    end: number;
  };
}

export interface Path extends SmartElement {
  type: SmartElementType.PATH;
  symbolText: string;
  filePath: string;
  isDirectory: boolean;
}

export interface Link extends SmartElement {
  type: SmartElementType.LINK;
  filePath: string;
  docTitle: string;
  isDraft?: boolean;
}

export type SmartSymbol = Token | Path | Link;

export type SmartElementWithApplicability<Element extends SmartElement> =
  | ({ applicability: ApplicabilityStatus.Unavailable } & Element)
  | ({ applicability: ApplicabilityStatus.Outdated } & Element)
  | SmartElementWithApplicabilityAndNewInfo<Element>;

export type SmartElementWithApplicabilityAndNewInfo<Element extends SmartElement> =
  | { applicability: ApplicabilityStatus.Verified | ApplicabilityStatus.Autosyncable; newInfo: Element } & Element;

export interface AutosyncInput {
  snippets: Snippet[];
  symbols: SmartSymbol[];
}

export type AutosyncApplicabilityStatus =
  | ApplicabilityStatus.Unavailable
  | ApplicabilityStatus.Outdated
  | ApplicabilityStatus.Autosyncable
  | ApplicabilityStatus.Verified;

export interface AutosyncOutput {
  snippets: SmartElementWithApplicability<Snippet>[];
  symbols: SmartElementWithApplicability<SmartSymbol>[];
  applicability: AutosyncApplicabilityStatus;
}

export type SymbolsDictionary = Record<string, SmartElementWithApplicability<SmartSymbol>>;
export type HunksDictionary = Record<string, SmartElementWithApplicability<Snippet>>;

export interface AutosyncedStoreData {
  symbols: SymbolsDictionary;
  hunks: HunksDictionary;
}

export function filterSmartElementsWithPath(
  smartElements: Iterable<SmartElementWithApplicability<SmartElement>>
): SmartElementWithApplicability<SmartSymbol | Snippet>[] {
  const result: SmartElementWithApplicability<SmartSymbol | Snippet>[] = [];
  for (const el of smartElements) {
    if (isSmartSymboltWithApplicability(el)) {
      result.push(el);
    } else if (isSnippetWithApplicability(el)) {
      result.push(el);
    }
  }
  return result;
}

export function filterSmartElementsSnippets(smartElements: Iterable<SmartElementWithApplicability<SmartElement>>) {
  const result: SmartElementWithApplicability<Snippet>[] = [];
  for (const el of smartElements) {
    if (isSnippetWithApplicability(el)) {
      result.push(el);
    }
  }
  return result;
}

function isSmartSymboltWithApplicability(
  element: SmartElementWithApplicability<SmartElement>
): element is SmartElementWithApplicability<SmartSymbol> {
  return [SmartElementType.PATH, SmartElementType.LINK, SmartElementType.TOKEN].includes(element.type);
}

function isSnippetWithApplicability(
  element: SmartElementWithApplicability<SmartElement>
): element is SmartElementWithApplicability<Snippet> {
  return SmartElementType.SNIPPET === element.type;
}
