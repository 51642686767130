import { useStore } from 'vuex';

export function useCreateRepoUser() {
  const store = useStore();
  const saveResourceInFirebaseDocument = async (args) =>
    store.dispatch('database/saveResourceInFirebaseDocument', args);

  const createRepoSwimmer = async (user, repoId) => {
    const swimmer = { id: user.uid, uid: user.uid, name: user.nickname };
    if (!swimmer.id) {
      return;
    }
    await saveResourceInFirebaseDocument({
      containerDocId: repoId,
      resourceName: 'swimmers',
      resource: swimmer,
      shouldSaveCreationDetails: true,
    });
  };
  const createRepoLifeguard = async (user, repoId) => {
    const userName = user.nickname || user.name || ''; // The username is set on different fields if the user is newly created or if the user was fetched from the DB
    const lifeguard = { id: user.uid, uid: user.uid, name: userName };
    await saveResourceInFirebaseDocument({
      containerDocId: repoId,
      resourceName: 'lifeguards',
      resource: lifeguard,
      shouldSaveCreationDetails: true,
    });
  };

  return {
    createRepoSwimmer,
    createRepoLifeguard,
  };
}
