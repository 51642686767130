import JSZip from 'jszip';
import { getLoggerNew } from '@swimm/shared';
import { BlobFileExtensions, IMPORT_OPTIONS } from '../constants';
import { getFileNameExtension } from './batchImportHelpers';
const logger = getLoggerNew(__modulename);

export async function getZipFilesContent(zipFiles): Promise<{ file; path; fileContent }[]> {
  const files = [];
  await Promise.all(
    zipFiles.map(async (zipFile) => {
      try {
        const content = zipFile.content.split(';base64,')[1];
        const result = await JSZip.loadAsync(content, { base64: true });
        const convertedFiles = result.files;
        await Promise.all(
          Object.keys(convertedFiles)
            .filter((path) => !convertedFiles[path].dir)
            .map(async (path) => {
              let fileContent = null;

              if (BlobFileExtensions.some((extension) => path.endsWith(extension))) {
                fileContent = await convertedFiles[path].async('base64');
              } else {
                fileContent = await convertedFiles[path].async('text');
              }
              files.push({
                file: convertedFiles[path],
                path,
                fileContent,
              });
            })
        );
      } catch (err) {
        logger.error({ err }, 'Failed to import zip file content: ');
      }
    })
  );
  return files;
}

export function getPathInZip(pathString: string) {
  return pathString.split('/').slice(1).join('/');
}

export function getFileFromZipFileName(
  fileName: string,
  variant: typeof IMPORT_OPTIONS.UPLOAD_CONFLUENCE_ZIP_FOLDER | typeof IMPORT_OPTIONS.UPLOAD_NOTION_ZIP_FOLDER
) {
  const parsedFileName = removeOriginFileId(fileName, variant);
  const nameParts = parsedFileName.split('/');
  return nameParts[nameParts.length - 1];
}

export function removeOriginFileId(
  fileName: string,
  variant: typeof IMPORT_OPTIONS.UPLOAD_CONFLUENCE_ZIP_FOLDER | typeof IMPORT_OPTIONS.UPLOAD_NOTION_ZIP_FOLDER
) {
  const fileExtension = getFileNameExtension(fileName);
  let FileUUIDRegex = null;
  // Clear out file IDs from file names according to origin
  if (variant === IMPORT_OPTIONS.UPLOAD_CONFLUENCE_ZIP_FOLDER) {
    FileUUIDRegex = new RegExp(`(_[A-Za-z\\d]+)(\\.${fileExtension})`);
  } else if (variant === IMPORT_OPTIONS.UPLOAD_NOTION_ZIP_FOLDER) {
    FileUUIDRegex = new RegExp(`(\\s[A-Za-z\\d]+)(\\.${fileExtension})`);
  }
  if (FileUUIDRegex) {
    const fileUUIDMatch = fileName.match(FileUUIDRegex);
    if (fileUUIDMatch) {
      return fileName.replace(fileUUIDMatch[1], '');
    }
  }

  return fileName;
}
