import type { GetStartedMenuItem, GetStartedStepId } from './types';

export const getStartedItemsStatuses = {
  LOCKED: 'locked',
  AVAILABLE: 'available',
  COMPLETED: 'completed',
};

export const GetStartedStepIds = {
  CONNECT_A_REPO: 'connectARepo',
  CREATE_A_DOC: 'createADoc',
  COMMIT_A_DOC: 'commitADoc',
  ADD_A_CODE_SNIPPET: 'addACodeSnippet',
  INSTALL_IDE_PLUGIN: 'installIdePlugin',
  INSTALL_GIT_HUB_APP: 'installGitHubApp',
  AUTHORIZE_GITHUB: 'authorizeGitHub',
} as const;

export const GetStartedSteps: { [key: string]: GetStartedMenuItem } = {
  connectARepo: { id: GetStartedStepIds.CONNECT_A_REPO, text: 'Connect a repo', isAdminsOnlyStep: true },
  authorizeGitHub: { id: GetStartedStepIds.AUTHORIZE_GITHUB, text: 'Authorize GitHub', isMembersOnlyStep: true },
  createADoc: { id: GetStartedStepIds.CREATE_A_DOC, text: 'Create a doc' },
  commitADoc: { id: GetStartedStepIds.COMMIT_A_DOC, text: 'Commit a doc', isSubItem: true },
  addACodeSnippet: { id: GetStartedStepIds.ADD_A_CODE_SNIPPET, text: 'Add a code snippet', isSubItem: true },
  installIdePlugin: { id: GetStartedStepIds.INSTALL_IDE_PLUGIN, text: 'Install IDE plugin' },
  installGitHubApp: { id: GetStartedStepIds.INSTALL_GIT_HUB_APP, text: 'Install GitHub app', isAdminsOnlyStep: true },
};

export const CREATE_A_DOC_SUB_STEPS: GetStartedStepId[] = [
  GetStartedStepIds.ADD_A_CODE_SNIPPET,
  GetStartedStepIds.COMMIT_A_DOC,
];

export const CREATE_A_DOC_STEPS: GetStartedStepId[] = [GetStartedStepIds.CREATE_A_DOC, ...CREATE_A_DOC_SUB_STEPS];

export const WORKSPACE_INDEPENDENT_STEPS: GetStartedStepId[] = [GetStartedStepIds.INSTALL_IDE_PLUGIN];

export const GetStartedTexts = {
  CONGRATS_MODE_TEXT_FOR_ADMINS: "Now you're ready to invite your teammates to Swimm with you!",
  CONGRATS_MODE_TEXT_FOR_NON_ADMINS: "You're ready to start Swimming and creating docs!",
  LOCKED_TEXT_DUE_TO_CONNECT_A_REPO: 'You must connect a repo to create code-coupled docs',
  LOCKED_TEXT_DUE_TO_REPO_NOT_PICKED: 'Choose a repo to start',
  LOCKED_TEXT_DUE_TO_UNAUTHORIZED_USER: 'Authorize GitHub to start',
};
