/* eslint-disable @typescript-eslint/ban-ts-comment */
import { VueNodeViewRenderer } from '@tiptap/vue-3';
// @ts-ignore
import EditorDraggableItem from './DraggableItem.vue';
import type { NodeConfig } from '@tiptap/core';

export function getDraggableItemConfig(): Partial<NodeConfig> {
  return {
    draggable: true,
    addNodeView() {
      return VueNodeViewRenderer(EditorDraggableItem);
    },
  };
}
