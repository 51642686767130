/**
 * Based on https://github.com/markdown-it/markdown-it/blob/2b6cac25823af011ff3bc7628bc9b06e483c5a08/lib/rules_inline/html_inline.js
 * & https://github.com/markdown-it/markdown-it/blob/2b6cac25823af011ff3bc7628bc9b06e483c5a08/lib/common/html_re.js
 *
 * @module
 */

import type MarkdownIt from 'markdown-it';
import type StateInline from 'markdown-it/lib/rules_inline/state_inline';
import { ATTRIBUTE, parseHtmlCloseTag, parseHtmlOpenTag } from './html';

const SWM_TAG_RE = new RegExp(
  '^(?:' + '<Swm[A-Za-z0-9\\-]*' + ATTRIBUTE + '*\\s*\\/?>' + '|' + '<\\/Swm[A-Za-z0-9\\-]*\\s*>' + ')'
);

function isLetter(ch: number): boolean {
  const lc = ch | 0x20; // to lower case
  return lc >= 0x61 /* a */ && lc <= 0x7a /* z */;
}

function parseSwmInline(state: StateInline, silent: boolean): boolean {
  const pos = state.pos;

  // Check start
  const max = state.posMax;
  if (state.src.charCodeAt(pos) !== 0x3c /* < */ || pos + 2 >= max) {
    return false;
  }

  // Quick fail on second char
  const ch = state.src.charCodeAt(pos + 1);
  if (ch !== 0x21 /* ! */ && ch !== 0x3f /* ? */ && ch !== 0x2f /* / */ && !isLetter(ch)) {
    return false;
  }

  const match = state.src.slice(pos).match(SWM_TAG_RE);
  if (!match) {
    return false;
  }

  if (!silent) {
    const text = state.src.slice(pos, pos + match[0].length);
    const close = text[1] === '/';

    let tag: string | null = '';
    let attrs: [string, string][] | null = null;
    if (!close) {
      const parsed = parseHtmlOpenTag(text);
      if (parsed == null) {
        return false;
      }

      ({ tag, attrs } = parsed);
    } else {
      const parsed = parseHtmlCloseTag(text);
      if (parsed == null) {
        return false;
      }

      ({ tag } = parsed);
    }

    const token = state.push(close ? 'swm_inline_close' : 'swm_inline_open', tag, 0);
    token.content = text;
    token.attrs = attrs;
  }

  state.pos += match[0].length;

  return true;
}

export default function swm_inline(md: MarkdownIt): void {
  md.inline.ruler.before('html_inline', 'swm_inline', parseSwmInline);
}
