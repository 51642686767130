import * as firestore from '@/adapters-common/firestore-wrapper';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { getRepoIsPrivate, getTestResourceDetails, saveWorkspaceToFirestore } from '@/common/utils/database-utils';
import { incrementResourceDBViews } from '@/common/utils/workspace-utils';
import { getSharedDocs } from '@/modules/cloud-docs/cloud-doc-utils';
import { DUMMY_REPO_ID, NOTIFICATION_TYPES } from '@swimm/shared';
import { fetchDocAssignments } from '@/modules/doc-assignments/services/doc-assignments-utils';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { StatusCodes } from 'http-status-codes';
import {
  MAX_NOTIFICATIONS_TO_DISPLAY,
  NOTIFICATION_TARGETS,
  billingPlanTypes,
  config,
  firestoreCollectionNames,
  getLoggerNew,
  objectUtils,
} from '@swimm/shared';
import { addDocToDb } from '@/modules/core/services/database';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { storeToRefs } from 'pinia';
import { uniqBy } from 'lodash-es';

const logger = getLoggerNew(__modulename);

export const emptyRepo = () => ({
  metadata: {},
  swimms: {},
  playlists: {},
  swimmers: {},
  lifeguards: {},
  doc_requests: {},
  rules: {},
  subscribed: false,
});

export const emptyWorkspace = () => ({
  logo: '',
  name: '',
  repositories: [],
  tags: {},
  workspace_users: {},
  workspace_admins: {},
  archived_workspace_users: {},
  cloud_docs: {},
  invites: [],
  invite_requests: [],
  counter_workspace_users: 0,
  counter_workspace_admins: 0,
  statistics: {
    'github-autosync-monthly': 0,
    'webapp-autosync-edit-doc-monthly': 0,
    'webapp-autosync-view-doc-monthly': 0,
  },
  settings: {},
});

const getDefaultState = () => ({
  repositories: {},
  workspaces: {},
  companyWorkspaces: {},
  invitedWorkspaces: {},
  hasFetchedUserWorkspaces: false,
  hasFetchedCompanyWorkspaces: false,
  hasFetchedWorkspacesInvites: false,
  domainSettings: {},
  notifications: [],
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_REPO_METADATA(state, args) {
      if (!(args.repoId in state.repositories)) {
        state.repositories[args.repoId] = emptyRepo();
      }
      state.repositories[args.repoId]['metadata'] = { ...args.resource, id: args.repoId };
    },
    SET_REPO_RESOURCE(state, args) {
      if (!(args.repoId in state.repositories)) {
        state.repositories[args.repoId] = emptyRepo();
      }
      // For backward compatibility
      if (args.resourceName === 'swimms' && !('type' in args.resource)) {
        args.resource.type = 'unit';
      }
      if ('id' in args.resource) {
        const originalResource = state.repositories[args.repoId][args.resourceName][args.resource.id] || {};
        state.repositories[args.repoId][args.resourceName][args.resource.id] = {
          ...originalResource,
          ...args.resource,
        };
      } else {
        state.repositories[args.repoId][args.resourceName] = args.resource;
      }
    },
    SET_REPO_SWIMMER(state, args) {
      if (!(args.repoId in state.repositories)) {
        state.repositories[args.repoId] = emptyRepo();
      }
      state.repositories[args.repoId]['swimmers'][args.uid] = args.data;
    },
    SET_REPO_LIFEGUARD(state, args) {
      if (!(args.repoId in state.repositories)) {
        state.repositories[args.repoId] = emptyRepo();
      }
      state.repositories[args.repoId]['lifeguards'][args.uid] = args.data;
    },
    SET_REPO_SWIMMER_STATUS(state, args) {
      if (!('swimmers' in state.repositories[args.repoId])) {
        state.repositories[args.repoId]['swimmers'] = {};
      }
      if (!(args.userId in state.repositories[args.repoId].swimmers)) {
        state.repositories[args.repoId].swimmers[args.userId] = {};
      }
      if (!state.repositories[args.repoId].swimmers[args.userId].swimms_status) {
        state.repositories[args.repoId].swimmers[args.userId]['swimms_status'] = {};
      }
      if (!state.repositories[args.repoId].swimmers[args.userId].playlists_status) {
        state.repositories[args.repoId].swimmers[args.userId]['playlists_status'] = {};
      }
      if (args.id) {
        state.repositories[args.repoId].swimmers[args.userId].swimms_status[args.id] = args.status;
      }
      if (args.swimmerName && !state.repositories[args.repoId].swimmers[args.userId].name) {
        state.repositories[args.repoId].swimmers[args.userId]['name'] = args.swimmerName;
      }
    },
    SET_REPO_SUBSCRIBED(state, args) {
      if (!(args.repoId in state.repositories)) {
        state.repositories[args.repoId] = emptyRepo();
      }
      state.repositories[args.repoId]['subscribed'] = true;
    },
    REMOVE_STORE_RESOURCE(state, args) {
      delete state[args.storeType][args.containerDocId][args.resourceName][args.resourceId];
    },
    SET_HAS_FETCHED_COMPANY_WORKSPACES(state, args) {
      state['hasFetchedCompanyWorkspaces'] = args.value;
    },
    SET_HAS_FETCHED_WORKSPACES_INVITES(state, args) {
      state['hasFetchedWorkspacesInvites'] = args.value;
    },
    SET_WORKSPACE(state, args) {
      state.workspaces[args.workspaceId] = { ...emptyWorkspace(), ...args.workspace };
    },
    SET_REPOSITORY(state, args) {
      state.workspaces[args.workspaceId].repositories.push(args.repoId);
    },
    UPDATE_WORKSPACE(state, args) {
      state.workspaces[args.workspaceId] = { ...state.workspaces[args.workspaceId], ...args.workspace };
    },
    DECREMENT_WORKSPACE_USER_COUNT(state, args) {
      const workspace = state.workspaces[args.workspaceId];
      state.workspaces[args.workspaceId] = {
        ...workspace,
        counter_workspace_users: workspace.counter_workspace_users - args.count,
      };
    },
    SET_WORKSPACE_USER_COUNT(state, args) {
      const workspace = state.workspaces[args.workspaceId];
      state.workspaces[args.workspaceId] = { ...workspace, counter_workspace_users: args.count };
    },
    SET_COMPANY_WORKSPACE(state, args) {
      state.companyWorkspaces[args.workspaceId] = { ...args.workspace };
    },
    SET_INVITED_WORKSPACE(state, args) {
      state.invitedWorkspaces[args.workspaceId] = { ...args.workspace };
    },
    REMOVE_INVITED_WORKSPACE(state, args) {
      delete state.invitedWorkspaces[args.workspaceId];
    },
    INCREMENT_RESOURCE_VIEWS(state, args) {
      const { repoId, resourceId, type } = args;
      const views = state.repositories[repoId][type][resourceId].views || 0;
      state.repositories[repoId][type][resourceId]['views'] = views + 1;
    },
    REMOVE_WORKSPACE_INVITE(state, args) {
      const index = state.workspaces[args.workspaceId].invites.findIndex((invite) => invite === args.email);
      if (index > -1) {
        state.workspaces[args.workspaceId].invites.splice(index, 1);
      }
    },
    REMOVE_WORKSPACE_INVITE_REQUEST(state, args) {
      const index = state.workspaces[args.workspaceId]['invite_requests'].findIndex((invite) => invite === args.email);
      if (index > -1) {
        state.workspaces[args.workspaceId]['invite_requests'].splice(index, 1);
      }
    },
    ADD_WORKSPACE_INVITE(state, args) {
      state.workspaces[args.workspaceId].invites.push(args.email);
    },
    REMOVE_WORKSPACE(state, args) {
      delete state.workspaces[args.workspaceId];
    },
    SET_DOC_RESOURCE(state, args) {
      const { repoId, unitId, resourceName, resource } = args;
      if (!state?.repositories?.[repoId]?.swimms?.[unitId]) {
        return;
      }
      state.repositories[repoId].swimms[unitId][resourceName] = resource;
    },
    SET_DOC_SUB_COLLECTION(state, args) {
      const { repoId, unitId, resource, subCollection } = args;
      if (
        !state.repositories ||
        !(repoId in state.repositories) ||
        !state.repositories[repoId].swimms ||
        !(unitId in state.repositories[repoId].swimms)
      ) {
        return;
      }
      if (!state.repositories[repoId].swimms[unitId][subCollection]) {
        state.repositories[repoId].swimms[unitId][subCollection] = {};
      }
      state.repositories[repoId].swimms[unitId][subCollection][resource.id] = resource;
    },
    REMOVE_ITEM_DOC_SUB_COLLECTION(state, args) {
      const { repoId, unitId, id, subCollection } = args;
      if (
        !state.repositories ||
        !(repoId in state.repositories) ||
        !state.repositories[repoId].swimms ||
        !(unitId in state.repositories[repoId].swimms) ||
        !state.repositories[repoId].swimms[unitId][subCollection]
      ) {
        return;
      }
      delete state.repositories[repoId].swimms[unitId][subCollection][id];
    },
    SET_WORKSPACE_RESOURCE(state, args) {
      const { workspaceId, resourceName, resource } = args;
      if (!(workspaceId in state.workspaces)) {
        state.workspaces[workspaceId] = emptyWorkspace();
      }
      if ('id' in args.resource) {
        state.workspaces[workspaceId][resourceName][resource.id] = resource;
      } else {
        state.workspaces[workspaceId][resourceName] = resource;
      }
    },
    DELETE_WORKSPACE_RESOURCE(state, args) {
      const { workspaceId, resourceName, resourceId } = args;
      if (!(workspaceId in state.workspaces)) {
        return;
      }
      delete state.workspaces[workspaceId][resourceName][resourceId];
    },
    REFRESH_WORKSPACE_LICENSE(state, args) {
      const { workspaceId, license } = args;
      state.workspaces[workspaceId].license = license;
    },
    SET_USER_NOTIFICATIONS(state, args) {
      const { notifications } = args;

      state['notifications'] = notifications;
    },
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    removeWorkspace({ commit }, workspaceId) {
      commit('REMOVE_WORKSPACE', { workspaceId: workspaceId });
    },
    incrementResourceViews({ commit, state }, args) {
      const resource = state.repositories[args.repoId][args.type][args.resourceId];
      const { user } = storeToRefs(useAuthStore());
      if (user.value.uid === resource.creator) {
        return;
      }
      incrementResourceDBViews({
        containerType: firestoreCollectionNames.REPOSITORIES,
        containerId: args.repoId,
        resourceType: args.type,
        resourceId: args.resourceId,
      });
      commit('INCREMENT_RESOURCE_VIEWS', args);
    },
    async fetchSwimmerWorkspaces({ commit, dispatch, state }) {
      if (state.hasFetchedUserWorkspaces) {
        return;
      }
      const { user } = storeToRefs(useAuthStore());
      const response = await firestore.getCollectionGroupRefWithWhereClause(firestore.collectionNames.WORKSPACE_USERS, [
        'uid',
        '==',
        user.value.uid,
      ]);
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Got error while getting user workspaces: ${response.errorMessage}`);
        return;
      }

      const workspacesQuerySnapshot = response.data;
      const noDuplicatedWorkdspaces = uniqBy(workspacesQuerySnapshot.docs, (doc) => doc.ref.parent.parent.id);
      const workspacePromises = noDuplicatedWorkdspaces
        .filter((doc) => !!doc.ref.parent.parent.id)
        .map(async (doc) => {
          const workspaceRef = await doc.ref.parent.parent.get();
          if (!workspaceRef.exists) {
            logger.error(`Failed to fetch a swimmer workspace. Details: workspace ${workspaceRef.id} doesn't exist`);
          } else {
            if (!state.workspaces[workspaceRef.id]) {
              commit('SET_WORKSPACE', {
                workspaceId: workspaceRef.id,
                workspace: { ...workspaceRef.data(), id: workspaceRef.id },
              });
            }
            const workspaceUsers = state.workspaces[workspaceRef.id]?.[firestore.collectionNames.WORKSPACE_USERS];
            if (!workspaceUsers || !Object.keys(workspaceUsers).length) {
              await dispatch('fetchDocumentChildCollections', {
                documentId: workspaceRef.id,
                children: [firestore.collectionNames.WORKSPACE_USERS],
                containerCollection: firestore.collectionNames.WORKSPACES,
              });
            }
          }
        });

      const fetchWorkspacesResults = await Promise.allSettled(workspacePromises);
      fetchWorkspacesResults.forEach((result) => {
        if (result.status === 'rejected') {
          logger.error(`Failed to fetch a swimmer workspace. Details: ${result.reason}`);
        }
      });
    },
    async fetchSwimmerWorkspace({ dispatch }, workspaceId) {
      const fetchWorkspacesResults = await Promise.allSettled([
        dispatch('fetchWorkspace', { workspaceId: workspaceId }),
        dispatch('fetchDocumentChildCollections', {
          documentId: workspaceId,
          children: [
            firestore.collectionNames.WORKSPACE_USERS,
            firestore.collectionNames.WORKSPACE_ADMINS,
            firestore.collectionNames.ARCHIVED_WORKSPACE_USERS,
          ],
          containerCollection: firestore.collectionNames.WORKSPACES,
        }),
      ]);

      fetchWorkspacesResults.forEach((result) => {
        if (result.status === 'rejected') {
          logger.error(`Failed to fetch a swimmer workspace. Details: ${result.reason}`);
        }
      });
    },
    async fetchAllWorkspaceRepos({ dispatch, state, getters }, workspaceId) {
      // Fetch all the repositories of a workspace (if not already in the state)
      await Promise.all(
        getters.db_getWorkspaceRepoIds(workspaceId).map(async (repoId) => {
          if (!(repoId in state.repositories)) {
            await dispatch('fetchRepository', { repoId: repoId });
          }
        })
      );
    },
    async fetchWorkspaceReposAndUsers({ dispatch }, workspaceId) {
      // This is helper function to fetch the workspace repos, users and admins in parallel
      await Promise.all([
        dispatch('fetchAllWorkspaceRepos', workspaceId),
        dispatch('fetchWorkspaceUsers', workspaceId),
      ]);
    },
    async fetchWorkspaceUsers({ dispatch }, workspaceId) {
      await dispatch('fetchDocumentChildCollections', {
        documentId: workspaceId,
        children: [firestore.collectionNames.WORKSPACE_USERS, firestore.collectionNames.WORKSPACE_ADMINS],
        containerCollection: firestore.collectionNames.WORKSPACES,
      });
    },
    async fetchWorkspace({ commit, state }, args) {
      if (!args.force && state.workspaces[args.workspaceId]) {
        return;
      }
      const response = await firestore.getDocFromCollection(firestore.collectionNames.WORKSPACES, args.workspaceId);
      if (response.code === config.SUCCESS_RETURN_CODE) {
        commit('SET_WORKSPACE', {
          workspaceId: args.workspaceId,
          workspace: { ...response.data, id: args.workspaceId },
        });
      } else {
        logger.error(`Error getting workspace data: ${response.errorMessage}`);
        throw response.errorMessage;
      }
    },
    async updateWorkspaceMetadata({ commit }, args) {
      const response = await firestore.getDocFromCollection(firestore.collectionNames.WORKSPACES, args.workspaceId);
      if (response.code === config.SUCCESS_RETURN_CODE) {
        commit('UPDATE_WORKSPACE', {
          workspaceId: args.workspaceId,
          workspace: { ...response.data, id: args.workspaceId },
        });
      } else {
        logger.error(`Error getting workspace data: ${response.errorMessage}`);
        throw response.errorMessage;
      }
    },
    async fetchWorkspaceEvents({ commit, state }, args) {
      if (!state.workspaces[args.workspaceId]) {
        return;
      }
      const response = await firestore.getDocsRefWithWhereClause(
        firestore.collectionNames.EVENT_LOGS,
        ['workspaceId', '==', args.workspaceId],
        ['created', 'desc'],
        300
      );
      if (response.code === config.SUCCESS_RETURN_CODE) {
        const events = response.data.docs.map((event) => ({
          id: event.id,
          ...event.data(),
        }));
        commit('UPDATE_WORKSPACE', {
          workspaceId: args.workspaceId,
          workspace: { events, id: args.workspaceId },
        });
      } else {
        logger.error(`Error getting workspace events data: ${response.errorMessage}`);
        // We do not throw here because there might be permission errors when it's not an admin
      }
    },
    async fetchWorkspaceStatistics({ commit, getters }, args) {
      const quotaCounters = [
        'webapp-autosync-edit-doc-monthly',
        'webapp-autosync-view-doc-monthly',
        'github-autosync-monthly',
      ];

      for (const counter of quotaCounters) {
        const response = await firestore.getDocFromSubCollection(
          firestore.collectionNames.WORKSPACES,
          args.workspaceId,
          firestore.collectionNames.STATISTICS,
          counter
        );
        if (response.code === config.SUCCESS_RETURN_CODE) {
          const current = getters.db_getWorkspaceStatistics(args.workspaceId);
          commit('SET_WORKSPACE_RESOURCE', {
            resourceName: firestore.collectionNames.STATISTICS,
            workspaceId: args.workspaceId,
            resource: { ...current, [counter]: response.data?.[args.docName]?.docs },
          });
        } else {
          logger.debug(`Error getting workspace statistics data: ${response.errorMessage}`);
        }
      }

      return getters.db_getWorkspaceStatistics(args.workspaceId);
    },
    async fetchCompanyWorkspaces({ commit, state }) {
      if (state.hasFetchedCompanyWorkspaces) {
        return Object.values(state.companyWorkspaces);
      }

      try {
        const companyWorkspacesResult = await CloudFunctions.getWorkspacesForUserDomain();
        if (companyWorkspacesResult.data.status !== 'success') {
          logger.error(`Error fetching company workspaces: ${companyWorkspacesResult.data.message}`, {
            module: 'database',
          });
        }
        companyWorkspacesResult.data.workspacesForUserDomain.forEach((workspace) => {
          const workspaceId = workspace.id;
          if (!state.companyWorkspaces[workspaceId]) {
            commit('SET_COMPANY_WORKSPACE', { workspaceId, workspace });
          }
        });
        commit('SET_HAS_FETCHED_COMPANY_WORKSPACES', { value: true });
        return Object.values(state.companyWorkspaces);
      } catch (err) {
        logger.error(`Error fetching company workspaces: ${err}`, {
          module: 'database',
        });
        return [];
      }
    },
    async fetchWorkspaceInvites({ commit, state }) {
      if (state.hasFetchedWorkspacesInvites) {
        return Object.values(state.invitedWorkspaces);
      }

      const { user } = storeToRefs(useAuthStore());
      if (!user.value.uid) {
        return [];
      }
      const response = await firestore.getDocsRefWithWhereClause(firestore.collectionNames.WORKSPACES, [
        'invites',
        'array-contains',
        user.value.email,
      ]);

      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Error fetching workspaces invites: ${response.errorMessage}`);
        return [];
      }
      const workspacesQuerySnapshot = response.data;
      for (const doc of workspacesQuerySnapshot.docs) {
        if (!state.invitedWorkspaces[doc.id]) {
          commit('SET_INVITED_WORKSPACE', { workspaceId: doc.id, workspace: { ...doc.data(), id: doc.id } });
        }
      }
      commit('SET_HAS_FETCHED_WORKSPACES_INVITES', { value: true });
      return Object.values(state.invitedWorkspaces);
    },
    /**
     * Gets a specific document id and child collection names and fetches the list of child collections from firebase
     * @param args.documentId - the firebase document id to fetch from
     * @param args.children - array of child collections names to fetch
     * @param args.containerCollection - name of the collection containing the document to fetch from
     * @return {Promise<void>}
     */
    async fetchDocumentChildCollections({ commit }, args) {
      const { documentId, containerCollection, children = [] } = args;

      if (children.length < 1) {
        return;
      }

      await Promise.all(
        children.map(async (child) => {
          const response = await firestore.getSubCollection(containerCollection, documentId, child);
          if (response.code !== config.SUCCESS_RETURN_CODE) {
            logger.error(
              `Got error while getting collection ${containerCollection}/${documentId}/${child}: ${response.errorMessage}`
            );
          } else {
            response.data.forEach((doc) => {
              if (containerCollection === firestore.collectionNames.WORKSPACES) {
                return commit('SET_WORKSPACE_RESOURCE', {
                  resourceName: child,
                  workspaceId: documentId,
                  resource: { ...doc.data(), id: doc.id },
                });
              }
            });
          }
        })
      );
    },
    async removeTagFromSwimm({ dispatch }, args) {
      const { tagId, repoId, swimmId } = args;
      await firestore.updateDocInSubCollection(
        firestore.collectionNames.REPOSITORIES,
        repoId,
        firestore.collectionNames.SWIMMS,
        swimmId,
        { tags: await firestore.firestoreArrayRemove(tagId) }
      );
      await dispatch('fetchRepoResource', { repoId, collectionName: firestore.collectionNames.SWIMMS, docId: swimmId });
    },
    async deleteTagFromWorkspace({ commit }, args) {
      const { tagId, workspaceId } = args;
      const response = await firestore.deleteDocFromSubCollection(
        firestore.collectionNames.WORKSPACES,
        workspaceId,
        firestore.collectionNames.TAGS,
        tagId
      );
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Could not delete tag: ${tagId} from workspace: ${workspaceId}. ${response.errorMessage}`, {
          module: 'database',
        });
        throw new Error('`Could not delete tag: ${tagId} from workspace: ${workspaceId}. ${response.errorMessage}');
      }
      commit('DELETE_WORKSPACE_RESOURCE', {
        workspaceId: workspaceId,
        resourceName: firestore.collectionNames.TAGS,
        resourceId: tagId,
      });
    },
    async fetchRepository({ commit, state }, args) {
      const { repoId } = args;
      if (!(repoId in state.repositories && !objectUtils.isEmpty(state.repositories[repoId]))) {
        const response = await firestore.getDocFromCollection(firestore.collectionNames.REPOSITORIES, repoId);
        if (response.code !== config.SUCCESS_RETURN_CODE) {
          logger.error(`Got error while getting repo: ${response.errorMessage}`);
          return null;
        }
        const repoData = response.data;
        const is_private = await getRepoIsPrivate({ repoId, repoData });
        commit('SET_REPO_METADATA', {
          repoId,
          resource: { ...repoData, ...(is_private !== undefined ? { is_private } : {}) },
        });
      }
      return state.repositories[repoId];
    },
    async subscribeToRepository({ commit, dispatch, state }, args) {
      const { repoId, updateChildren = [] } = args;
      if (!state.repositories[repoId] || !state.repositories[repoId].subscribed) {
        await new Promise((resolve, reject) => {
          const unsubscribe = firebase
            .firestore()
            .collection('repositories')
            .doc(repoId)
            .onSnapshot(
              function (repoDocRef) {
                // TODO - unused var - should be removed?
                // const oldRepo = { ...state.repositories[repoDocRef.id] }; // spread object so it does not get updated when committing newRepo
                const newRepo = repoDocRef.data();
                commit('SET_REPO_METADATA', { repoId: repoDocRef.id, resource: newRepo });
                commit('SET_REPO_SUBSCRIBED', { repoId: repoDocRef.id });
                dispatch('fetchRepoChildren', { repoId, children: updateChildren }).then(() => resolve(unsubscribe));
              },
              function (err) {
                logger.error({ err }, `Error getting documents: ${err}`);
                reject(err);
              }
            );
        });
      }
    },
    async fetchRepoChildren({ commit, state }, args) {
      const { repoId, children = [] } = args;
      logger.info({ repoId, children }, `Fetching children for repo: ${repoId}`);
      await Promise.all(
        children.map(async (child) => {
          const response = await firestore.getSubCollection(firestore.collectionNames.REPOSITORIES, repoId, child);
          if (response.code !== config.SUCCESS_RETURN_CODE) {
            logger.error(`Got error while getting collection ${child}: ${response.errorMessage}`);
          } else {
            const childSnapshot = response.data;
            childSnapshot.forEach((doc) => {
              if (state.repositories[repoId]) {
                if (state.repositories[repoId][child][doc.id]) {
                  return;
                }
                commit('SET_REPO_RESOURCE', {
                  resourceName: child,
                  repoId,
                  resource: { ...doc.data(), id: doc.id },
                });
              }
            });
          }
        })
      );
      logger.info(`Finished fetching children for repo: ${repoId}`);
    },
    async fetchSwimmerStatus({ commit }, args) {
      const { repoId, userId } = args;
      if (!userId || repoId === DUMMY_REPO_ID) {
        return;
      }
      const response = await firestore.getSubCollectionRecursive(
        [
          firestore.collectionNames.REPOSITORIES,
          firestore.collectionNames.SWIMMERS,
          firestore.collectionNames.SWIMMS_STATUS,
        ],
        [repoId, userId]
      );
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Error fetching swimmer status: ${response.errorMessage}`);
        return;
      }
      const orderedUnits = response.data;
      if (orderedUnits.empty) {
        commit('SET_REPO_SWIMMER_STATUS', { repoId, userId: userId });
      } else {
        orderedUnits.forEach((doc) => {
          commit('SET_REPO_SWIMMER_STATUS', { repoId, userId, id: doc.id, status: doc.data() });
        });
      }
    },
    async fetchRepoResource({ commit }, args) {
      const { repoId, collectionName, docId } = args;
      if (!docId) {
        return;
      }
      const response = await firestore.getDocFromSubCollection(
        firestore.collectionNames.REPOSITORIES,
        repoId,
        collectionName,
        docId
      );
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        return;
      }
      const docSnapshot = response.data;
      if (docSnapshot) {
        commit('SET_REPO_RESOURCE', {
          repoId,
          resourceName: collectionName,
          resource: { ...docSnapshot, id: docId },
        });
      }
    },
    async fetchRepoSwimmer({ commit, state }, args) {
      const { repoId, userId } = args;
      if (!userId || repoId === DUMMY_REPO_ID) {
        return;
      }
      const response = await firestore.getDocFromSubCollection(
        firestore.collectionNames.REPOSITORIES,
        repoId,
        firestore.collectionNames.SWIMMERS,
        userId
      );
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Error fetching swimmer: ${response.errorMessage}`);
        return;
      }
      const swimmerSnapshot = response.data;
      if (swimmerSnapshot) {
        // If some swimmer data has already been loaded before, e.g. swimms_status / playlists_status
        const existingSwimmer = state.repositories[repoId] && state.repositories[repoId].swimmers[userId];
        commit('SET_REPO_SWIMMER', { uid: userId, repoId, data: { ...swimmerSnapshot, ...existingSwimmer } });
      }
    },
    async isRepoExistOnDB(_, args) {
      const { repoId } = args;
      const response = await firestore.getDocFromCollection(firestore.collectionNames.REPOSITORIES, repoId);
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.debug(`Could not fetch repo with repoId: ${repoId}. Details: ${response.errorMessage}`, {
          module: 'database',
        });
        return;
      }

      return !!response.data;
    },
    async fetchRepoLifeguard({ commit, state }, args) {
      const { repoId, userId } = args;
      if (
        state.repositories[repoId] &&
        (!state.repositories[repoId]['lifeguards'] || !state.repositories[repoId]['lifeguards'][userId])
      ) {
        const response = await firestore.getDocFromSubCollection(
          firestore.collectionNames.REPOSITORIES,
          repoId,
          firestore.collectionNames.LIFEGUARDS,
          userId
        );

        if (response.code === config.SUCCESS_RETURN_CODE) {
          commit('SET_REPO_LIFEGUARD', { uid: userId, repoId, data: response.data });
        }
      }
    },
    /**
     * Creates or updates a provided resource in a specific collection under a specific document in firestore.
     * All params are provided inside args
     * NOTICE! : using serverTimestamp inside the set/add call (to override the values before) is mandatory! otherwise they won't be parsed as timestamp when inserted to firestore.
     * @param args.containerDocId - The containing document id (i.e - the repoId)
     * @param args.containerCollectionType - the type of the collection containing the document to save under ( i.e - workspaces)
     * @param args.resourceName - the name of the collection to save (playlists, swimms, etc.)
     * @param args.resource - the JSON object of the resource to save (i.e - a playlist data object)
     * @param args.updateState - determines if it's an update or creation
     * @param args.shouldSaveCreationDetails - use for saving a new documents with a resource id (i.e users in workspace)
     * @return {Promise<*>}
     */
    async saveResourceInFirebaseDocument({ commit }, args) {
      const {
        containerDocId,
        resourceName,
        resource,
        updateState = true,
        containerCollectionType = 'repositories',
        shouldSaveCreationDetails = false,
      } = args;
      const { user } = storeToRefs(useAuthStore());
      const creation = {
        created: firestore.firestoreTimestamp(),
        creator: user.value.uid,
        creator_name: user.value.nickname,
        ...getTestResourceDetails(resourceName),
        creator_profile_url: user.value.photoURL ?? '',
      };
      const update = {
        modified: firestore.firestoreTimestamp(),
        modifier: user.value.uid,
        modifier_name: user.value.nickname,
      };
      const savedResource = { ...resource, ...creation, ...update };
      const getResponse = firestore.getSubCollectionRef(containerCollectionType, containerDocId, resourceName);
      if (getResponse.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Got error getting resource: ${getResponse.errorMessage}`);
        throw getResponse.errorMessage;
      }
      const collectionRef = getResponse.data;
      let savedDocId;
      if ('id' in resource) {
        // We use mergeFields to not update creation values if they already exist in the DB
        const mergeFields = [];
        const keysToIgnore = ['counter_upvotes', 'views'];
        if (shouldSaveCreationDetails) {
          // In some cases we are saving a new document with a pre-set id and want to keep the creation details on set
          // e.g swimmers in repo / users in workspace - both have the document id set to the user id
          // the argument shouldSaveCreationDetails should be set to true only in the creation call of the document
          mergeFields.push(...Object.keys(savedResource).filter((key) => !keysToIgnore.includes(key)));
        } else {
          // Merge fields should not include creation fields
          mergeFields.push(
            ...Object.keys(savedResource).filter((key) => !(key in creation) && !keysToIgnore.includes(key))
          );
        }
        const fieldsToSet = { ...savedResource, modified: firestore.firestoreTimestamp() };
        const options = { mergeFields: mergeFields };
        const setResponse = await firestore.setValuesInDocInSubCollection(
          containerCollectionType,
          containerDocId,
          resourceName,
          resource.id,
          fieldsToSet,
          options
        );
        if (setResponse.code !== config.SUCCESS_RETURN_CODE) {
          logger.error(`Got error updating resource: ${setResponse.errorMessage}`);
          throw setResponse.errorMessage;
        }
        savedDocId = resource.id; // Use the resource ID because `.set` doesn't send back the document ID
      } else {
        const docToAdd = {
          ...savedResource,
          created: firestore.firestoreTimestamp(),
          modified: firestore.firestoreTimestamp(),
        };
        const addResponse = await firestore.addDocToSubCollection(
          containerCollectionType,
          containerDocId,
          resourceName,
          docToAdd
        );
        if (addResponse.code !== config.SUCCESS_RETURN_CODE) {
          logger.error(`Got error adding resource: ${addResponse.errorMessage}`);
          throw addResponse.errorMessage;
        }
        savedDocId = addResponse.data.id;
      }
      if (updateState && savedDocId) {
        // Fetch the saved doc so we get the date(created, modified) fields in a json friendly format.
        const getDocResponse = await firestore.getDocFromRef(collectionRef, savedDocId);
        if (getDocResponse.code !== config.SUCCESS_RETURN_CODE) {
          logger.error(`Got error getting resource: ${getDocResponse.errorMessage}`);
          throw getDocResponse.errorMessage;
        }
        const savedDocData = getDocResponse.data;
        const commitMethods = {
          repositories: () =>
            commit('SET_REPO_RESOURCE', {
              resourceName,
              repoId: containerDocId,
              resource: { ...savedDocData, id: savedDocId },
            }),
          workspaces: () =>
            commit('SET_WORKSPACE_RESOURCE', {
              resourceName,
              workspaceId: containerDocId,
              resource: { ...savedDocData, id: savedDocId },
            }),
        };
        commitMethods[containerCollectionType]();
      }
      return savedDocId;
    },
    async saveRepository({ commit }, args) {
      if (!args.repoId) {
        logger.error(`Error adding repository, missing repository id`);
        return;
      }
      const cleanResource = args.resource;
      objectUtils.clearUndefineds(cleanResource);
      const response = await firestore.setValuesInDoc(firestore.collectionNames.REPOSITORIES, args.repoId, {
        ...cleanResource,
        created: firestore.firestoreTimestamp(),
      });
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Error adding document: ${response.errorMessage}`);
        return;
      }
      commit('SET_REPO_METADATA', { repoId: args.repoId, resource: cleanResource });
    },
    /**
     * Removes a resource in a specific collection under a specific document in firestore.
     * @param args.containerDocId - The containing document id (i.e - the repoId)
     * @param args.containerCollectionType - the type of the collection containing the document to delete ( i.e - repositories)
     * @param args.resourceName - the name of the collection to delete (playlists, swimms, etc.)
     * @param args.resourceId - the id of the resource (firebase document id) to delete (i.e - a playlist id)
     * @return {Promise<*>}
     */
    async removeResourceInFirebaseDocument({ commit }, args) {
      const { containerDocId, resourceName, resourceId, containerCollectionType } = args;
      const response = await firestore.deleteDocFromSubCollection(
        containerCollectionType,
        containerDocId,
        resourceName,
        resourceId
      );
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Error deleting resource: ${response.errorMessage}`);
        return;
      }

      commit('REMOVE_STORE_RESOURCE', {
        resourceName,
        containerDocId,
        resourceId,
        storeType: containerCollectionType,
      });
    },
    /**
     * Archives a resource (document) in a specific collection in firestore.
     * in example, gets a playlist and copies it under archived_playlists before deleting it from firestore
     * @param args.containerDocId - The containing document id (i.e - the repoId)
     * @param args.containerCollectionType - the type of the collection containing the document to archive ( i.e - repositories)
     * @param args.resourceName - the name of the collection to archive (playlists, swimms, etc.)
     * @param args.resourceId - the id of the resource (firebase document id) to archive (i.e - a playlist id)
     * @return {Promise<*>}
     */
    async archiveResource({ dispatch }, args) {
      const {
        containerDocId,
        resourceName,
        resourceId,
        containerCollectionType = firestore.collectionNames.REPOSITORIES,
      } = args;
      const response = await firestore.getDocFromSubCollection(
        containerCollectionType,
        containerDocId,
        resourceName,
        resourceId
      );

      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Error fetching resource: ${response.errorMessage}`);
        return;
      }

      const resource = response.data;
      const savingResult = await dispatch('saveResourceInFirebaseDocument', {
        resourceName: `archived_${resourceName}`,
        resource: { ...resource, id: resourceId },
        containerDocId,
        containerCollectionType,
        updateState: false,
      });
      // TODO: handle error better
      if (savingResult.error) {
        logger.error(savingResult.error);
      } else {
        await dispatch('removeResourceInFirebaseDocument', {
          resourceName,
          resourceId,
          containerDocId,
          containerCollectionType,
        });
      }
    },
    async updateSwimmerStatus({ commit }, args) {
      const { user } = storeToRefs(useAuthStore());
      if (!user.value.uid) {
        return;
      }
      const { status, repoId, userId, resourceId } = args;
      if (!Object.values(config.SWIMMER_STATUSES).includes(status)) {
        logger.error(`${status} is an invalid swimmer status.`);
        return;
      }
      const newStatus = { status: status };
      newStatus[`${status}_date`] = firestore.firestoreTimestamp();
      const response = await firestore.setValuesInDocSubCollectionRecursive(
        [
          firestore.collectionNames.REPOSITORIES,
          firestore.collectionNames.SWIMMERS,
          firestore.collectionNames.SWIMMS_STATUS,
        ],
        [repoId, userId, resourceId],
        newStatus,
        { merge: true }
      );
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Error updating swimmer status: ${response.errorMessage}`);
        return;
      }
      // Override the firestore serverTimestamp value because it fails on json.stringify(this issue started when we moved to electron)
      newStatus[`${status}_date`] = new Date();
      commit('SET_REPO_SWIMMER_STATUS', { repoId, userId, id: resourceId, status: newStatus });
    },
    async unsubscribeUserFromRepo({ dispatch }, args) {
      const { user } = storeToRefs(useAuthStore());
      const currentUser = args.user ? args.user : user.value;
      const roleListToUnsubscribe = args.roleToRemove === 'swimmer' ? 'swimmers' : 'lifeguards';
      await dispatch('removeResourceInFirebaseDocument', {
        resourceName: roleListToUnsubscribe,
        resourceId: currentUser.uid,
        containerDocId: args.repoId,
        containerCollectionType: 'repositories',
      });
    },
    async saveWorkspace({ commit }, args) {
      const { user } = storeToRefs(useAuthStore());
      const response = await saveWorkspaceToFirestore(args.resource, user.value);
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        throw response.errorMessage;
      }

      if (response.user) {
        commit('SET_WORKSPACE_RESOURCE', {
          resourceName: firestore.collectionNames.WORKSPACE_USERS,
          workspaceId: response.workspaceId,
          resource: { ...response.user, id: user.value.uid },
        });
        commit('SET_WORKSPACE_RESOURCE', {
          resourceName: firestore.collectionNames.WORKSPACE_ADMINS,
          workspaceId: response.workspaceId,
          resource: { ...response.user, id: user.value.uid },
        });
      }

      if (response.workspace) {
        const savedWorkspace = response.workspace;
        const counters = {
          counter_workspace_users: savedWorkspace.counter_workspace_users || 1,
          counter_workspace_admins: savedWorkspace.counter_workspace_admins || 1,
        };
        commit(response.wasUpdated ? 'UPDATE_WORKSPACE' : 'SET_WORKSPACE', {
          workspaceId: response.workspaceId,
          workspace: {
            ...savedWorkspace,
            ...counters,
            id: response.workspaceId,
          },
        });
      }
      return response.workspaceId;
    },
    async addRepoToWorkspace({ commit }, args) {
      try {
        const { workspaceId, repoId, isPrivate, gitUrl } = args;
        const addRepoResult = await CloudFunctions.addRepoToWorkspace({ workspaceId, repoId, isPrivate, gitUrl });
        if (addRepoResult.data.code === StatusCodes.OK) {
          commit('SET_REPOSITORY', { workspaceId, repoId });
        }
        return addRepoResult;
      } catch (err) {
        logger.error({ err }, `Error add adding a new repository to workspace: ${err}`);
        throw err;
      }
    },
    async updateRepoMetadata({ commit }, args) {
      const response = await firestore.getDocFromCollection(firestore.collectionNames.REPOSITORIES, args.repoId);
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Error fetching repo ${args.repoId}: ${response.errorMessage}`);
        return;
      }

      commit('SET_REPO_METADATA', { repoId: args.repoId, resource: { ...response.data, id: args.repoId } });
    },
    async archiveWorkspace({ commit, dispatch }, workspaceId) {
      const response = await firestore.updateDocInCollection(firestore.collectionNames.WORKSPACES, workspaceId, {
        deleted: true,
        deleted_timestamp: firestore.firestoreTimestamp(),
      });
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Error deleting workspace ${workspaceId}: ${response.errorMessage}`);
        return;
      }
      // Archive all workspace_users
      await dispatch('archiveFirebaseChildCollections', {
        collectionsList: [firestore.collectionNames.WORKSPACE_USERS],
        containerCollection: firestore.collectionNames.WORKSPACES,
        documentId: workspaceId,
      });
      commit('REMOVE_WORKSPACE', { workspaceId });
    },
    async archiveFirebaseChildCollections({ dispatch }, args) {
      const { collectionsList, containerCollection, documentId } = args;
      for (const collectionName of collectionsList) {
        const response = await firestore.getSubCollection(containerCollection, documentId, collectionName);
        if (response.code !== config.SUCCESS_RETURN_CODE) {
          logger.error(`Got error while getting collection ${collectionName}: ${response.errorMessage}`);
          continue;
        }
        const querySnapshot = response.data;
        await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            await dispatch('archiveResource', {
              resourceName: collectionName,
              containerDocId: documentId,
              containerCollectionType: containerCollection,
              resourceId: doc.id,
            });
            const response = await firestore.deleteDocFromSubCollection(
              containerCollection,
              documentId,
              collectionName,
              doc.id
            );
            if (response.code !== config.SUCCESS_RETURN_CODE) {
              logger.error(`Error deleting resource: ${response.errorMessage}`);
              throw response.errorMessage;
            }
          })
        );
      }
    },
    async deleteWorkspaceFromInvitedWorkspaces({ commit, state }, args) {
      if (state.invitedWorkspaces[args.workspaceId]) {
        commit('REMOVE_INVITED_WORKSPACE', { workspaceId: args.workspaceId });
      }
    },
    async refreshWorkspaceLicense({ commit }, args) {
      const response = await firestore.getDocFromCollection(firestore.collectionNames.WORKSPACES, args.workspaceId);
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Error fetching workspace ${args.workspaceId}: ${response.errorMessage}`);
        return;
      }
      const license = response.data.license;
      commit('REFRESH_WORKSPACE_LICENSE', { workspaceId: args.workspaceId, license });
    },
    async removeInviteFromWorkspaceState({ commit, getters }, args) {
      const { workspaceId, email } = args;
      const workspace = getters.db_getWorkspace(workspaceId);

      if (workspace.invites) {
        const isInviteExist = workspace.invites.some((invite) => invite === email);
        if (isInviteExist) {
          commit('REMOVE_WORKSPACE_INVITE', { workspaceId: workspace.id, email });
        }
      }
    },
    async removeWorkspaceInviteRequest({ commit, state }, args) {
      const { workspaceId, email } = args;
      const workspace = state.workspaces[workspaceId];
      try {
        if (workspace['invite_requests']) {
          const isInviteRequestExist = workspace['invite_requests'].find((request) => request === email);
          if (isInviteRequestExist) {
            commit('REMOVE_WORKSPACE_INVITE_REQUEST', { workspaceId, email });
          }
        }
      } catch (err) {
        logger.error({ err }, `Error removing workspace invite request: ${err}`);
        throw err;
      }
    },
    addInviteEmailToWorkspace({ commit, state }, args) {
      try {
        const workspace = state.workspaces[args.workspaceId];
        const emailExists = workspace.invites.some((invite) => invite === args.email);
        if (!emailExists) {
          commit('ADD_WORKSPACE_INVITE', { workspaceId: args.workspaceId, email: args.email });
        }
      } catch (err) {
        logger.error({ err }, `Error adding invite email to workspace: ${err} `);
      }
    },
    async saveUserAuthState(_, args) {
      const { authData, userId } = args;

      const response = await firestore.setValuesInDoc(firestore.collectionNames.USERS, userId, authData, {
        merge: true,
      });
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Failed generating github state to user: ${response.errorMessage}`);
        throw response.errorMessage;
      }
    },
    removeUserFromWorkspaceState({ commit, getters }, args) {
      const user = getters.db_getClonedResource(`workspaces.${args.workspaceId}.workspace_users.${args.userId}`);
      commit('REMOVE_STORE_RESOURCE', {
        resourceName: firestore.collectionNames.WORKSPACE_USERS,
        containerDocId: args.workspaceId,
        resourceId: args.userId,
        storeType: 'workspaces',
      });

      commit('DECREMENT_WORKSPACE_USER_COUNT', { workspaceId: args.workspaceId, count: 1 });

      commit('SET_WORKSPACE_RESOURCE', {
        resourceName: firestore.collectionNames.ARCHIVED_WORKSPACE_USERS,
        workspaceId: args.workspaceId,
        resource: user,
      });
      if (args.isAdmin) {
        commit('REMOVE_STORE_RESOURCE', {
          resourceName: firestore.collectionNames.WORKSPACE_ADMINS,
          containerDocId: args.workspaceId,
          resourceId: args.userId,
          storeType: 'workspaces',
        });
      }
    },
    async refreshAssignments({ commit }, { repoId, unitId }) {
      const { user } = storeToRefs(useAuthStore());
      if (!user.value.uid) {
        return;
      }
      const assignments = await fetchDocAssignments({ docId: unitId, repoId });
      commit('SET_DOC_RESOURCE', {
        repoId: repoId,
        unitId: unitId,
        resourceName: 'assignments',
        resource: assignments,
      });
    },
    async refreshDocSubCollection({ commit }, { repoId, unitId, collection }) {
      try {
        const response = await firestore.getSubCollectionRecursive(
          [firestore.collectionNames.REPOSITORIES, firestore.collectionNames.SWIMMS, collection],
          [repoId, unitId]
        );
        if (response.code !== config.SUCCESS_RETURN_CODE) {
          logger.error(
            `Error fetching doc sub collection: ${collection}, repo: ${repoId}, doc: ${unitId}, with error: ${response.errorMessage}`
          );
          return;
        }
        response.data.forEach((doc) => {
          commit('SET_DOC_SUB_COLLECTION', {
            repoId: repoId,
            unitId: unitId,
            resource: { id: doc.id, ...doc.data() },
            subCollection: collection,
          });
        });
      } catch (err) {
        logger.error(
          { err },
          `Error fetching doc sub collection ${collection}, repo: ${repoId}, doc: ${unitId}, with error: ${err}`
        );
      }
    },
    async refreshDocSubCollections({ dispatch }, { repoId, unitId, collectionsToRefresh }) {
      const { user } = storeToRefs(useAuthStore());
      if (!user.value.uid) {
        return;
      }
      const subCollectionPromiseArr = [];
      for (const collection of collectionsToRefresh) {
        dispatch('refreshDocSubCollection', { repoId, unitId, collection });
      }
      await Promise.allSettled(subCollectionPromiseArr);
    },
    setDocSubCollectionData({ commit }, { repoId, unitId, data, collection }) {
      const { user } = storeToRefs(useAuthStore());
      commit('SET_DOC_SUB_COLLECTION', {
        repoId,
        unitId,
        resource: { id: user.value.uid, ...data },
        subCollection: collection,
      });
    },
    removeItemFromDocSubCollection({ commit }, { repoId, unitId, collection }) {
      const { user } = storeToRefs(useAuthStore());
      commit('REMOVE_ITEM_DOC_SUB_COLLECTION', {
        repoId,
        unitId,
        id: user.value.uid,
        subCollection: collection,
      });
    },
    async refreshWorkspaceSharedDocs({ commit }, args) {
      const { workspaceId } = args;
      const sharedDocs = await getSharedDocs({ workspaceId });
      commit('SET_WORKSPACE_RESOURCE', {
        resourceName: 'shared_docs',
        workspaceId,
        resource: sharedDocs,
      });
    },
    async refreshWorkspaceUsersAndInvites({ commit, dispatch }, args) {
      await dispatch('fetchDocumentChildCollections', {
        documentId: args.workspaceId,
        children: [
          firestore.collectionNames.WORKSPACE_USERS,
          firestore.collectionNames.WORKSPACE_ADMINS,
          firestore.collectionNames.ARCHIVED_WORKSPACE_USERS,
        ],
        containerCollection: firestore.collectionNames.WORKSPACES,
      });
      const response = await firestore.getDocFromCollection(firestore.collectionNames.WORKSPACES, args.workspaceId);
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Error fetching workspace ${args.workspaceId}: ${response.errorMessage}`);
        return;
      }
      commit('SET_WORKSPACE_USER_COUNT', {
        workspaceId: args.workspaceId,
        count: response.data.counter_workspace_users,
      });
      commit('SET_WORKSPACE_RESOURCE', {
        resourceName: 'invites',
        workspaceId: args.workspaceId,
        resource: response.data.invites || [],
      });
      commit('SET_WORKSPACE_RESOURCE', {
        resourceName: 'invite_requests',
        workspaceId: args.workspaceId,
        resource: response.data.invite_requests || [],
      });
    },
    async fetchUserNotifications({ commit }) {
      const { user } = storeToRefs(useAuthStore());
      if (!user.value.uid) {
        return;
      }
      const byUidResponse = await firestore.getDocsRefWithWhereClauses(
        firestore.collectionNames.NOTIFICATIONS,
        [
          ['recipient_id', '==', user.value.uid],
          ['targets', 'array-contains', NOTIFICATION_TARGETS.IN_APP],
        ],
        ['created_at', 'desc'],
        MAX_NOTIFICATIONS_TO_DISPLAY
      );

      if (byUidResponse.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Error fetching notifications by uid for user ${user.value.uid}: ${byUidResponse.errorMessage}`, {
          module: 'database',
        });
        return;
      }
      const uidNotifications = byUidResponse.data.docs.map((notification) => ({
        id: notification.id,
        ...notification.data(),
      }));
      const idsFromUidSet = new Set(uidNotifications.map((n) => n.id));
      let emailNotifications = [];
      if (user.value.email) {
        const byEmailResponse = await firestore.getDocsRefWithWhereClauses(
          firestore.collectionNames.NOTIFICATIONS,
          [
            ['recipient_email', '==', user.value.email],
            ['targets', 'array-contains', NOTIFICATION_TARGETS.IN_APP],
          ],
          ['created_at', 'desc'],
          MAX_NOTIFICATIONS_TO_DISPLAY
        );
        if (byEmailResponse.code !== config.SUCCESS_RETURN_CODE) {
          logger.error(
            `Error fetching notifications by email for email ${user.value.email}: ${byEmailResponse.errorMessage}`,
            {
              module: 'database',
            }
          );
        } else {
          // If notification is sent to user is supposed to have empty email
          // but we add extra check for the case that both fields populated
          emailNotifications = byEmailResponse.data.docs
            .filter((notification) => !notification.recipient_id && !idsFromUidSet.has(notification.id))
            .map((notification) => ({
              id: notification.id,
              ...notification.data(),
            }));
        }
      }
      const combinedNotifications = uidNotifications.concat(emailNotifications);
      const notifications = combinedNotifications.filter((notification) => {
        if (!notification) {
          return false;
        } else if (!NOTIFICATION_TYPES[notification.type]) {
          logger.warn(
            `Filtering unknown notification for user: ${user.value.uid}. NotificationId: ${notification.id} NotificationType: ${notification.type}`
          );
          return false;
        }
        return true;
      });
      // Sort new to old and limit
      notifications.sort((n1, n2) => (n1.created_at > n2.created_at ? -1 : 1));
      // Leave only recent MAX_NOTIFICATIONS_TO_DISPLAY
      notifications.splice(MAX_NOTIFICATIONS_TO_DISPLAY);
      commit('SET_USER_NOTIFICATIONS', { notifications });
    },
    async markNotificationAsSeen(_, notificationId) {
      const response = await firestore.updateDocInCollection(firestore.collectionNames.NOTIFICATIONS, notificationId, {
        seen: true,
        seen_at: firestore.firestoreTimestamp(),
      });
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Error marking notification ${notificationId} as seen: ${response.errorMessage}`);
      }
    },
    async markNotificationAsDismissed(context, notificationId) {
      const response = await firestore.updateDocInCollection(firestore.collectionNames.NOTIFICATIONS, notificationId, {
        dismissed: true,
        dismissed_at: firestore.firestoreTimestamp(),
      });
      if (response.code !== config.SUCCESS_RETURN_CODE) {
        logger.error(`Error marking notification ${notificationId} as dismissed: ${response.errorMessage}`);
      }
    },
    addDocRequest({ commit }, args) {
      const { repoId, resource } = args;
      commit('SET_REPO_RESOURCE', {
        resourceName: firestore.collectionNames.DOC_REQUESTS,
        repoId,
        resource: resource,
      });
    },
    addRule({ commit, _dispatch }, args) {
      const { repoId, resource } = args;
      commit('SET_REPO_RESOURCE', {
        resourceName: firestore.collectionNames.RULES,
        repoId,
        resource,
      });
    },
    async addDocsToDBIfNeeded({ getters, rootGetters, commit }, args) {
      const docsFromDB = getters.db_getSwimms(args.repoId);
      const docsFromRepo = rootGetters['filesystem/fs_getRepoLocalFilesLists'](args.repoId);
      const { user } = storeToRefs(useAuthStore());

      logger.info(
        { countInDB: Object.keys(docsFromDB).length, countInRepo: Object.keys(docsFromRepo).length },
        'addDocsToDBIfNeeded'
      );

      return Promise.allSettled(
        Object.entries(docsFromRepo).map(async ([docId, docMetadata]) => {
          if (docMetadata.type !== config.SWIMM_FILE_TYPES.SWMD) {
            return;
          }

          if (docId && !docsFromDB[docId]) {
            logger.info(`Found a doc without a DB record, docId: ${docId}`);
            const docName = docMetadata.name.split('.')[0].replaceAll('-', ' ');
            try {
              const savedDocument = await addDocToDb(docId, docName, args.repoId, user.value);

              // Update doc in state
              commit('SET_REPO_RESOURCE', {
                resourceName: firestoreCollectionNames.SWIMMS,
                repoId: args.repoId,
                resource: { ...savedDocument.data },
              });
            } catch (err) {
              logger.error({ err }, `Could not add docId: ${docId} to database. Details: ${err}`);
            }
          }
        })
      );
    },
  },
  getters: {
    db_getUserWorkspaces: (state, getters) => (userId) => {
      const userWorkspaces = {};
      Object.keys(state.workspaces).forEach((workspaceId) => {
        if (getters.db_isWorkspaceUser(workspaceId, userId)) {
          userWorkspaces[workspaceId] = state.workspaces[workspaceId];
        }
      });
      return userWorkspaces;
    },
    db_getWorkspaceByRepo: (state, getters) => (repoId) => {
      for (const workspaceId of Object.keys(state.workspaces)) {
        if (getters.db_getWorkspaceRepoIds(workspaceId).includes(repoId)) {
          return objectUtils.deepClone(state.workspaces[workspaceId]);
        }
      }
    },
    db_getWorkspaceExpiredRepoIds: (state) => (workspaceId) => {
      return state.workspaces[workspaceId] ? [...(state.workspaces[workspaceId].expired_repositories || [])] : [];
    },
    db_getWorkspaceRepoIds: (state) => (workspaceId) => {
      return state.workspaces[workspaceId] ? [...state.workspaces[workspaceId].repositories] : [];
    },
    db_getWorkspaceRepos: (state, getters) => (workspaceId) => {
      return getters.db_getWorkspaceRepoIds(workspaceId).map((repoId) => getters.db_getRepository(repoId));
    },
    db_getWorkspaceLicense: (state) => (workspaceId) => {
      return state.workspaces[workspaceId]?.license || billingPlanTypes.FREE;
    },
    db_getRepoWithSlack: (state, getters) => (workspaceId) => {
      return (
        getters
          .db_getWorkspaceRepos(workspaceId)
          // @ts-ignore database is not typed yet
          .find((repo) => {
            return repo && repo.metadata && repo.metadata.slack_config && repo.metadata.slack_config.team_id;
          })
      );
    },
    db_getSlackUrl: (state, getters) => (workspaceId, slackAppId) => {
      if (!workspaceId) {
        return null;
      }
      const repoWithSlack = getters.db_getRepoWithSlack(workspaceId);
      if (repoWithSlack) {
        const appId = slackAppId;
        // @ts-ignore database is not typed yet
        const teamId = repoWithSlack.metadata.slack_config.team_id;
        return `https://slack.com/app_redirect?app=${appId}&team=${teamId}`;
      }
      return null;
    },
    db_hasExercises: (_, getters) => (repoId) => {
      const swimms = getters.db_getSwimms(repoId) || {};
      // This might return extra exercises if there are "old" units without dod (considered as docs)
      return Object.values(swimms).some(
        (swimm) => swimm.type === 'unit' && swimm.play_mode !== config.UNIT_PLAY_MODES.WALKTHROUGH
      );
    },
    db_getResource: (state) => (resourcePath) => {
      // Examples for resourcePath: workspaces.WORKSPACE_ID.plans.PLAN_ID, repositories.REPO_ID.playlists
      const splittedPath = resourcePath.split('.');
      let resource;
      if (splittedPath.length > 0) {
        resource = state;
        for (const pathPart of splittedPath) {
          if (pathPart in resource) {
            resource = resource[pathPart];
          } else {
            return undefined;
          }
        }
      }
      return resource;
    },
    db_getClonedResource: (_, getters) => (resourcePath) => {
      const resource = getters.db_getResource(resourcePath);
      return resource ? objectUtils.deepClone(resource) : undefined;
    },
    db_hasWorkspaces: (_, getters) => (userId) => Object.keys(getters.db_getUserWorkspaces(userId)).length > 0,
    db_getWorkspace: (_, getters) => (workspaceId) => getters.db_getClonedResource(`workspaces.${workspaceId}`),
    db_getWorkspaceName: (state) => (workspaceId) => {
      return state.workspaces[workspaceId]?.name;
    },
    db_getWorkspaceUsers: (_, getters) => (workspaceId) =>
      getters.db_getClonedResource(`workspaces.${workspaceId}.workspace_users`),
    db_getWorkspaceDeletedUsers: (_, getters) => (workspaceId) =>
      getters.db_getClonedResource(`workspaces.${workspaceId}.archived_workspace_users`),
    db_getSharedDocs: (state) => (workspaceId) => {
      return Object.values(state.workspaces[workspaceId].shared_docs || {});
    },
    db_getRepoSharedDocs: (state) => (workspaceId, repoId) => {
      return Object.values(state.workspaces[workspaceId].shared_docs || {}).filter(
        (doc) => doc.export_info.original_repo_id === repoId
      );
    },
    db_getSharedDoc: (state) => (workspaceId, sharedDocId) => {
      return state.workspaces[workspaceId]?.shared_docs[sharedDocId] || {};
    },
    db_getWorkspaceAdmins: (_, getters) => (workspaceId) =>
      getters.db_getClonedResource(`workspaces.${workspaceId}.workspace_admins`),
    db_getWorkspaceResources: (_, getters) => (workspaceId) => {
      const workspaceRepos = getters.db_getWorkspaceRepos(workspaceId).filter((repo) => !!repo);
      const workspaceResources = [];
      for (const repo of Object.values(workspaceRepos)) {
        if (objectUtils.isEmpty(repo.metadata)) {
          continue;
        }
        const repoId = repo.metadata.id;
        const swimms = [];
        for (const swimm of Object.values(getters.db_getSwimms(repoId))) {
          const swimmResource = {
            id: swimm.id,
            name: swimm.name,
            repoId: repoId,
            type: swimm.type ? swimm.type : 'unit',
            creator_name: swimm.creator_name,
            created: swimm.created,
            creator: swimm.creator,
            creator_profile_url: swimm.creator_profile_url,
          };
          if (swimm.play_mode) {
            swimmResource.play_mode = swimm.play_mode;
          }
          if (swimm.counter_upvotes) {
            swimmResource.counter_upvotes = swimm.counter_upvotes;
          }
          swimms.push(swimmResource);
        }
        const playlists = Object.values(getters.db_getPlaylists(repoId)).map((playlist) => ({
          id: playlist.id,
          name: playlist.name,
          repoId: repo.metadata.id,
          type: 'playlist',
          created: playlist.created,
        }));
        workspaceResources.push(...swimms, ...playlists);
      }
      return workspaceResources;
    },
    db_getWorkspaceEvents: (_, getters) => (workspaceId) => {
      const workspace = getters.db_getClonedResource(`workspaces.${workspaceId}`);
      return workspace ? workspace.events : undefined;
    },
    db_getWorkspaceStatistics: (_, getters) => (workspaceId) => {
      return getters.db_getClonedResource(`workspaces.${workspaceId}.statistics`);
    },
    db_isWorkspaceUser: (state) => (workspacesId, userId) =>
      !!state.workspaces[workspacesId] && userId in state.workspaces[workspacesId].workspace_users,
    db_getWorkspaceUser: (state, getters) => (workspaceId, userId) => {
      return getters.db_isWorkspaceUser(workspaceId, userId)
        ? state.workspaces[workspaceId].workspace_users[userId]
        : undefined;
    },
    db_getWorkspaceOpenAIKey: (state) => (workspaceId) => state.workspaces[workspaceId]?.openai_key,
    db_isWorkspaceSidebarCollapsedByDefault: (state) => (workspaceId) =>
      state.workspaces[workspaceId]?.sidebar_collapsed_by_default ?? false,
    db_isWorkspaceSidebarSearchDisabledAtFirst: (state) => (workspaceId) =>
      state.workspaces[workspaceId]?.sidebar_search_disabled_at_first ?? false,
    db_isWorkspaceAdmin: (state) => (workspacesId, user) => {
      if (!user) {
        return false;
      }
      return !!state.workspaces[workspacesId] && user in state.workspaces[workspacesId].workspace_admins;
    },
    db_getAdminWorkspaces: (state, getters) => (user) =>
      objectUtils.filter(state.workspaces, (workspace) => getters.db_isWorkspaceAdmin(workspace.id, user)),
    db_getRepoMetadata: (_, getters) => (repoId) => getters.db_getClonedResource(`repositories.${repoId}.metadata`),
    db_getRepoName: (state) => (repoId) => state.repositories[repoId]?.metadata?.name,
    db_getSwimms: (_, getters) => (repoId) => getters.db_getResource(`repositories.${repoId}.swimms`),
    db_getDocRequests: (_, getters) => (repoId) => getters.db_getResource(`repositories.${repoId}.doc_requests`),
    // db_getRules: (_, getters) => (repoId) => getters.db_getResource(`repositories.${repoId}.rules`),
    db_getPlaylists: (_, getters) => (repoId) => getters.db_getResource(`repositories.${repoId}.playlists`),
    db_isUnitInRepo: (state) => (repoId, unitId) =>
      !!state.repositories[repoId] && unitId in state.repositories[repoId].swimms,
    db_isPlaylistInRepo: (state) => (repoId, playlistId) =>
      !!state.repositories[repoId] && playlistId in state.repositories[repoId].playlists,
    db_getUnits: (state) => (repoId) => {
      const allDocs = {};
      if (state.repositories[repoId]?.swimms) {
        Object.keys(state.repositories[repoId].swimms).forEach((swimmId) => {
          const doc = state.repositories[repoId].swimms[swimmId];
          if (doc.type === 'unit') {
            allDocs[swimmId] = doc;
          }
        });
      }
      return allDocs;
    },
    db_getSwimm: (_, getters) => (repoId, swimmId) =>
      getters.db_getResource(`repositories.${repoId}.swimms.${swimmId}`),
    db_getDocRequest: (_, getters) => (repoId, docRequestId) =>
      getters.db_getResource(`repositories.${repoId}.doc_requests.${docRequestId}`),
    db_getRule: (_, getters) => (repoId, ruleId) => getters.db_getResource(`repositories.${repoId}.rules?.${ruleId}`),
    db_getAssignments: (_, getters) => (repoId, swimmId) =>
      Object.values(getters.db_getResource(`repositories.${repoId}.swimms.${swimmId}.assignments`) || {}),
    db_getContributors: (_, getters) => (repoId, swimmId) =>
      Object.values(getters.db_getResource(`repositories.${repoId}.swimms.${swimmId}.contributors`) || {}),
    db_getThanks: (_, getters) => (repoId, swimmId) =>
      Object.values(getters.db_getResource(`repositories.${repoId}.swimms.${swimmId}.thanks`) || {}),
    db_getWatchers: (_, getters) => (repoId, swimmId) =>
      Object.values(getters.db_getResource(`repositories.${repoId}.swimms.${swimmId}.watchers`) || {}),
    db_getResourceViews: (state, getters) => (repoId, resourceId, type) => {
      const resource = getters.db_getResource(`repositories.${repoId}.${type}.${resourceId}`);
      return resource && resource.views ? resource.views : null;
    },
    db_getDocTags: (state, getters) => (workspaceId, repoId, docId) => {
      const tags = [];
      const tagIds = getters.db_getSwimm(repoId, docId)?.tags;
      const workspaceTags = state.workspaces[workspaceId] && state.workspaces[workspaceId].tags;
      if (!workspaceTags || !tagIds) {
        return tags;
      }
      tagIds.forEach((tagId) => {
        if (workspaceTags[tagId]) {
          tags.push(workspaceTags[tagId]);
        } else {
          logger.debug(`tagId ${tagId} doesn't exist on workspace ${workspaceId}`);
        }
      });
      return tags;
    },
    db_getAllWorkspaceTags: (state, getters) => (workspaceId) =>
      Object.values(getters.db_getResource(`workspaces.${workspaceId}.tags`) || {}),
    db_getPlaylist: (_, getters) => (repoId, playlistId) =>
      getters.db_getResource(`repositories.${repoId}.playlists.${playlistId}`),
    db_getRepository: (_, getters) => (repoId) => getters.db_getResource(`repositories.${repoId}`),
    db_getSwimmStatus: (state) => (repoId, swimmerId, swimmId) => {
      if (!(repoId in state.repositories)) {
        return null;
      }
      const swimmer = state.repositories[repoId].swimmers[swimmerId];
      if (
        !!swimmer &&
        Object.keys(swimmer).includes('swimms_status') &&
        Object.keys(swimmer.swimms_status).includes(swimmId)
      ) {
        return swimmer.swimms_status[swimmId].status;
      }
      return config.SWIMMER_STATUSES.NOT_STARTED;
    },
    db_getSwimmerRepos: (state, getters) => (swimmerId) => {
      if (!swimmerId) {
        return state.repositories;
      }
      return objectUtils.filter(state.repositories, (repo) => getters.db_isRepoSwimmer(repo.metadata.id, swimmerId));
    },
    db_isRepoSwimmer: (state) => (repoId, user) =>
      state.repositories[repoId] && user in state.repositories[repoId].swimmers,
    db_isRepoLifeguard: (state) => (repoId, user) =>
      state.repositories[repoId] && user in state.repositories[repoId].lifeguards,
    db_isPublicRepo: (state) => (repoId) => state.repositories[repoId]?.metadata?.is_public === true, // For opensource
    db_isPrivateRepo: (state) => (repoId) => state.repositories[repoId].metadata.is_private !== false,
    db_isRepoFetched: (state) => (repoId) => !!state.repositories[repoId],
    db_isRepoSwimmersFetched: (state) => (repoId) =>
      !!state.repositories[repoId] && Object.keys(state.repositories[repoId].swimmers).length > 0,
    db_isRepoSubscribed: (state) => (repoId) => !!state.repositories[repoId] && state.repositories[repoId].subscribed,
    db_getUnitsOrderedByStatus: (state, getters) => (repoId, swimmerId) => {
      if (!(repoId in state['repositories']) || !('swimms' in state['repositories'][repoId])) {
        return [];
      }
      const statusOrder = {
        [config.SWIMMER_STATUSES.NOT_STARTED]: 1,
        [config.SWIMMER_STATUSES.STARTED]: 2,
        [config.SWIMMER_STATUSES.DONE]: 3,
      };
      const status = (swimm) => getters.db_getSwimmStatus(repoId, swimmerId, swimm.id);
      const sortByStatus = (first, second) => statusOrder[status(first)] - statusOrder[status(second)];
      return Object.values(getters.db_getUnits(repoId)).sort(sortByStatus);
    },

    db_getOpenSourceRepoIds: (state) => () =>
      Object.keys(objectUtils.filter(state.repositories, (repo) => repo.metadata.is_open_source)),
    db_isUpvoted: (state) => (containerType, containerId, resourceType, resourceId) =>
      state.upvotes[containerType][containerId] &&
      state.upvotes[containerType][containerId][resourceType][resourceId] &&
      state.upvotes[containerType][containerId][resourceType][resourceId].upvote,
    db_isOriginallyUpvoted: (state) => (containerType, containerId, resourceType, resourceId) =>
      state.upvotes[containerType][containerId] &&
      state.upvotes[containerType][containerId][resourceType][resourceId] &&
      state.upvotes[containerType][containerId][resourceType][resourceId].originalValue,
    db_getNotifications: (state) => () => state.notifications || [],
  },
};
