import { MarkdownUtils } from './markdownUtils';
import { encoder } from 'node-esapi';

const TempDoubleUnderscore = 'TempDoubleUnderscore';

function escapeSmartTextFormat(value: string) {
  return (
    value
      // Marked is converting __TEXT__ to bold text (<strong>TEXT</strong>) and it's breaking the smart path in case
      // the path in cases like __tests__/testFile.js so we replace the "__" with some temp string.
      .replace(/(\[\[(sym|sym-text):).*?(\(.*?\)\]\])/g, (match) => {
        return match.split('__').join(TempDoubleUnderscore);
      })
      // Marked is splitting text that has square brackets in it - it's breaking smart tokens
      // By HTML escaping the characters they pass as part of the text type to the marked renderer
      .replaceAll(
        /(?<smartTextPrefix>\[\[(sym-text):)(?<displayText>.*?)(?<smartTextSuffix>\(.*?\)\]\])/g,
        (match, smartTextPrefix, _, displayText, smartTextSuffix) => {
          return `${smartTextPrefix}${encoder().encodeForHTML(displayText)}${smartTextSuffix}`;
        }
      )
  );
}

function unescapeSmartTextFormat(markedText: string) {
  return (
    markedText
      // Added 'i' flag, tempDoubleUnderscore is converted to lower case in marked function
      .replace(new RegExp(TempDoubleUnderscore, 'ig'), '__')
  );
}

export function markedFormattedValue(value: string, repoId?: string): string {
  const formattedValue = escapeSmartTextFormat(value);

  const markedText = MarkdownUtils.marked(formattedValue, repoId);

  const result = unescapeSmartTextFormat(markedText);

  // This sorts out some HTML irregularities for example extracting <img> tags from within <p> tags... We create these as
  // a consequence of how marked works.
  // FIXME: We probably shouldn't be creating such invalid HTML in the first place...
  const el = document.createElement('template');
  el.innerHTML = result;

  return el.innerHTML.trim();
}
