import stringSimilarity from 'string-similarity';

/**
 * Calculate the similarity between two strings using the `string-similarity` library.
 * @param str1 The first string.
 * @param str2 The second string.
 * @returns A number between 0 and 1, where 1 means the strings are identical.
 */
export function calculateStringSimilarity(str1: string, str2: string): number {
  return stringSimilarity.compareTwoStrings(str1, str2);
}
