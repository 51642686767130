import * as _ from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

import type { SwmSymbolGenericText } from '@swimm/shared';
import type { Range } from '@tiptap/core';
import { TokenSuggestionEngine } from '@/components/EditorComponents/composable/tokenSuggestionEngine';
import type { GlobalTokensLoadingState } from '@/components/EditorComponents/GenericText/globalTokensConsts';

export interface IToken extends SwmSymbolGenericText {
  id: string;
  global?: boolean; // Global tokens are tokens that come from files in the repository and not from snippets in the document.
  prefixLines?: string[];
  suffixLines?: string[];
}
export interface AutocompleteToken {
  range: Range;
  token: string;
}

function generateTokenId(token: SwmSymbolGenericText, isGlobal: boolean): string {
  const prefix = isGlobal ? 'global:' : '';
  return `${prefix}${token.repoId}-${token.path}-${token.lineNumber}-${token.wordIndex.start}`;
}

function generateTokenBank(tokens: SwmSymbolGenericText[], isGlobal: boolean): Record<string, IToken> {
  return tokens.reduce((tokenBank, token) => {
    const tokenId = generateTokenId(token, isGlobal);
    tokenBank[tokenId] = { ...token, id: tokenId, global: isGlobal };
    return tokenBank;
  }, {} as Record<string, IToken>);
}

export const useTokenSuggestionStore = defineStore('tokenSuggestion', () => {
  const globalTokens = ref<Record<string, SwmSymbolGenericText[]>>({});
  const snippetTokens = ref<Record<string, IToken>>({});
  const liveQueryTokens = ref<IToken[]>([]);

  const refreshLiveQueryTokens = ref<((query: string) => void) | null>(null);
  const currentQuery = ref<string>('');
  const liveQueryTokensLoadingState = ref<GlobalTokensLoadingState>();

  const tokenSuggestionState = computed(() => [globalTokens.value, snippetTokens.value, liveQueryTokens.value]);

  const engine = computed<TokenSuggestionEngine>(
    () =>
      new TokenSuggestionEngine(
        generateTokenBank(Object.values(globalTokens.value).flat(), true),
        generateTokenBank(Object.values(snippetTokens.value), false)
      )
  );

  watch(currentQuery, (currentQuery) => {
    if (refreshLiveQueryTokens.value) {
      liveQueryTokensLoadingState.value = 'loading';
      refreshLiveQueryTokens.value(currentQuery);
    }
  });

  const init = (newRefreshLiveQueryTokens: ((query: string) => void) | null): void => {
    globalTokens.value = {};
    snippetTokens.value = {};
    liveQueryTokens.value = [];
    refreshLiveQueryTokens.value = newRefreshLiveQueryTokens;
    currentQuery.value = '';
    liveQueryTokensLoadingState.value = undefined;
  };
  const getAutocompleteSuggestions = (query: string): IToken[] => {
    return [...engine.value.suggest(query, true)].concat(liveQueryTokens.value);
  };
  const getSuggestions = (query: string): IToken[] => {
    return [...engine.value.suggest(query, false)];
  };
  const addSnippetTokens = (tokens: IToken[]): void => {
    for (const token of tokens) {
      snippetTokens.value[generateTokenId(token, false)] = token;
    }
  };
  const clearSnippetTokens = (): void => {
    snippetTokens.value = {};
  };
  const removeFileSnippetTokens = (repoId: string, filePath: string): void => {
    Object.entries(snippetTokens.value).forEach(([tokenId, token]) => {
      if (token.repoId === repoId && token.path === filePath) {
        delete snippetTokens.value[tokenId];
      }
    });
  };
  const setRepoGlobalTokens = (repoId: string, tokens: SwmSymbolGenericText[]): void => {
    globalTokens.value = { ...globalTokens.value, [repoId]: tokens };
  };
  const areGlobalTokensEmpty = computed(() => _.isEmpty(globalTokens.value));
  const setLiveQueryTokens = (query: string, tokens: SwmSymbolGenericText[]) => {
    if (query === currentQuery.value) {
      liveQueryTokens.value = tokens.map((token) => ({ ...token, id: generateTokenId(token, true), global: true }));
      liveQueryTokensLoadingState.value = 'loaded';
    }
  };
  const setCurrentQuery = (query: string): void => {
    currentQuery.value = query;
  };

  return {
    tokenSuggestionState,
    liveQueryTokensLoadingState,
    init,
    getAutocompleteSuggestions,
    getSuggestions,
    addSnippetTokens,
    clearSnippetTokens,
    removeFileSnippetTokens,
    setRepoGlobalTokens,
    areGlobalTokensEmpty,
    setLiveQueryTokens,
    setCurrentQuery,
  };
});
