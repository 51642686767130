<template>
  <BaseCard class="card-container">
    <img src="@/assets/invalid-duck.png" class="duck" />
    <SwText variant="headline3">You're temporarily logged out.</SwText>
    <SwText variant="body-S">
      We're sorry, it looks like you were logged out because you did not use the correct sign in option required by your
      workspace.
    </SwText>
    <SwText variant="body-S">
      For security reasons, <a :href="redirectUrl" class="link">login with SSO</a> to continue accessing your account,
      you'll be redirected in a few seconds.
    </SwText>
  </BaseCard>
</template>
<script>
import { getLoggerNew } from '@swimm/shared';
import { useSignout } from '../composables/signout';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useRoute } from 'vue-router';
import BaseCard from '@/modules/core/components/BaseCard.vue';

const logger = getLoggerNew(__modulename);

export default {
  components: { BaseCard },
  setup() {
    const route = useRoute();
    const { user } = useAuthStore();
    const { signout } = useSignout();

    const emailObject = user ? { email: user.email } : {};
    const query = { ...route.query, ...emailObject };
    const redirectUrl = '/sso' + (query ? '?' + new URLSearchParams(query).toString() : '');

    return { user, signout, redirectUrl, query };
  },
  async mounted() {
    logger.debug('Starting to log out');
    const logout = async () => {
      if (this.user) {
        await this.signout({
          redirectToLogin: true,
          redirectRoute: this.redirectUrl,
          redirectQuery: this.query,
        });
        logger.debug('Logout complete');
      } else {
        logger.debug('User already logged out. Redirecting to login page...');
        await this.$router.push(this.redirectUrl);
      }
    };
    setTimeout(logout, 3000);
  },
};
</script>
<style scoped>
.card-container {
  margin: auto;
  gap: var(--space-md);
  align-items: center;
}

.link {
  color: var(--text-color-link);
}

.duck {
  height: 56px;
  width: 56px;
}
</style>
