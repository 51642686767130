import swal from 'sweetalert';
import { config, getLoggerNew } from '@swimm/shared';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import { useStore } from 'vuex';
import { computed } from 'vue';

const logger = getLoggerNew(__modulename);

export function useRepoSwitcher() {
  const store = useStore();
  const fs_isLocalRepoExist = computed(() => store.getters['filesystem/fs_isLocalRepoExist']);
  const db_getRepository = computed(() => store.getters['database/db_getRepository']);
  const setRepoInRepositories = async (args) => await store.dispatch('filesystem/setRepoInRepositories', args);

  const connectToRepo = async ({
    repoId,
    alertError = true,
  }): Promise<
    | void
    | { code: typeof config.SUCCESS_RETURN_CODE; repoDetails: unknown }
    | { code: typeof config.ERROR_RETURN_CODE; errorMessage: string }
  > => {
    try {
      const repo = db_getRepository.value(repoId);
      const parsedRepoMetadata = {
        id: repo.metadata.id,
        url: repo.metadata.url,
        name: repo.metadata.name,
        provider: repo.metadata.provider,
        owner: repo.metadata.owner,
        is_new_swimm_repo: false,
      };
      if (!fs_isLocalRepoExist.value(repo.metadata.id)) {
        await setRepoInRepositories(parsedRepoMetadata);
      }
      return {
        code: config.SUCCESS_RETURN_CODE,
        repoDetails: { ...parsedRepoMetadata, provider: parsedRepoMetadata.provider.replace('.com', '') },
      };
    } catch (err) {
      logger.error({ err }, `could not connect to remote repo. Details: ${err.toString()}`);
      if (alertError) {
        await swal({
          title: 'Cannot connect to the repository.',
          text: `Oops! Seems like we can't connect to your repository. Please select the repo from the Home Page and try again.`,
          content: { element: SWAL_CONTACT_US_CONTENT() },
        });
      }
      return { code: config.ERROR_RETURN_CODE, errorMessage: err.toString() };
    }
  };

  return { connectToRepo };
}
