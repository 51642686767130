import { defineStore, storeToRefs } from 'pinia';
import { STORES } from '@/modules/core/stores/store-constants';
import { useRoute } from 'vue-router';
import { computed, ref, watch } from 'vue';
import {
  type DraftSwmFile,
  FolderItemType,
  FolderItemTypeToCollection,
  config,
  datetimeUtils,
  firestoreCollectionNames,
  getLoggerNew,
  isSwmDoc,
  iter,
  objectUtils,
  productEvents,
} from '@swimm/shared';
import {
  addDocToSubCollection,
  deleteDocFromSubCollection,
  firestoreTimestamp,
  getDocFromSubCollection,
  getDocRefRecursive,
  getDocsRefFromSubCollectionWithWhereClauses,
  getSubCollection,
  getSubCollectionRefRecursive,
  updateDocInSubCollection,
} from '@/adapters-common/firestore-wrapper';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import type firebase from 'firebase/compat/app';
import { useStore } from 'vuex';
import { DocumentationTypes } from '@/common/consts';
import { DocumentationTypeToFolderItemType, type Folder } from '../types';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useDeleteItem } from '@/common/composables/delete-item';
import swal from 'sweetalert';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import { countDocumentationTypes, pluralize } from '@/common/utils/helpers';
import { type RepoDocItem, type RepoPlaylistItem, useRepoDocsStore } from '@/modules/core/stores/repo-docs';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import type { DbDraft } from '@/modules/drafts3/db';
import { Timestamp } from 'firebase/firestore';

const logger = getLoggerNew(__modulename);

interface RepoFolders {
  [repoId: string]: { [folderId: string]: Folder };
}

export interface FolderFilterResult {
  folderId: string;
  expanded: boolean;
}

export const useFoldersStore = defineStore(STORES.FOLDERS, () => {
  const analytics = useAnalytics();
  const route = useRoute();
  const store = useStore();
  const { user } = storeToRefs(useAuthStore());
  const drafts3Store = useDrafts3Store();
  const { openDeleteModal } = useDeleteItem();
  const currentFolderIdByRepo = ref<Record<string, string>>({});
  const foldersByRepo = ref<RepoFolders>({});
  const isAddingNewFolderState = ref(false);
  const renameFolderId = ref<string>(null);
  const folderCollectionsUnsubscribes = ref([]);
  const repoDocsStore = useRepoDocsStore();
  const { getDocsInBranchWithoutApplicability, getPlaylistsInBranch } = repoDocsStore;

  const db_getPlaylists = computed(() => store.getters['database/db_getPlaylists']);
  const db_getDocs = computed(() => store.getters['database/db_getUnits']);
  const db_getRepoName = computed(() => store.getters['database/db_getRepoName']);

  function getFolderBreadCrumbs(folderId: string, requestedRepoId: string): Folder[] {
    let folderCrumb = getFolder(folderId, requestedRepoId);
    const crumbs = [];
    while (folderCrumb) {
      crumbs.unshift(folderCrumb);
      folderCrumb = folderCrumb.is_root ? null : getParentFolder(folderCrumb.id, requestedRepoId);
    }
    return crumbs;
  }

  function getFolderPath(folderId: string, requestedRepoId: string): string {
    const crumbs = getFolderBreadCrumbs(folderId, requestedRepoId);
    return crumbs.map((folder) => folder.name).join('/');
  }

  function getParentFolder(folderId: string, requestedRepoId: string): Folder {
    if (!foldersByRepo.value[requestedRepoId]) {
      return null;
    }
    const allFolders = Object.values(foldersByRepo.value[requestedRepoId]);
    return allFolders.find((folder) => folder.children.some((item) => item.id === folderId));
  }

  function getFolderItemType(item: firebase.firestore.DocumentReference): FolderItemType {
    switch (item.parent.id) {
      case firestoreCollectionNames.SWIMMS: {
        return FolderItemType.DOC;
      }
      case firestoreCollectionNames.PLAYLISTS: {
        return FolderItemType.PLAYLIST;
      }
      case firestoreCollectionNames.FOLDERS: {
        return FolderItemType.FOLDER;
      }
      default: {
        return FolderItemType.UNKNOWN;
      }
    }
  }

  function getFolderDrafts3(folderId: string, repoId: string): (RepoDocItem | RepoPlaylistItem)[] {
    const folder = getFolder(folderId, repoId);
    if (!folder) {
      return [];
    }

    const docDrafts = Object.values(repoDocsStore.docDraftsInBranchItemsForRepo());
    const playlistDrafts = Object.values(repoDocsStore.playlistDraftsInBranchItemsForRepo());

    return [...docDrafts, ...playlistDrafts].filter((draft) => {
      if (!draft.isNew) {
        // Check if the original doc/playlist in folder.
        return folder.children.some((item) => item.id === draft.id);
      }
      if (!draft.folderId) {
        // Root folder should contain all drafts without folderId or with folderId of a folder that doesn't exist (deleted by other user).
        return folder.is_root;
      }
      const draftFolder = getFolder(draft.folderId, repoId);
      return (!draftFolder && folder.is_root) || (draftFolder && draft.folderId === folderId);
    });
  }

  function getFolderItems({
    repoId,
    folderId,
    includeDrafts = true,
  }: {
    repoId: string;
    folderId: string;
    includeDrafts: boolean;
  }): (
    | RepoDocItem
    | RepoPlaylistItem
    | (Folder & { documentationType: 'folder' })
    | (DraftSwmFile & { creator_name: string; documentationType: 'document' | 'playlist' })
  )[] {
    const folder = getFolder(folderId, repoId);
    if (!folder) {
      return [];
    }

    const folderDrafts = includeDrafts ? getFolderDrafts3(folderId, repoId) : [];

    if (!folder.children.length && !folderDrafts.length) {
      return [];
    }

    const items: (
      | RepoDocItem
      | RepoPlaylistItem
      | (Folder & { documentationType: 'folder' })
      | (DraftSwmFile & { creator_name: string; documentationType: 'document' | 'playlist' })
      | null
    )[] = [];
    const playlistsInBranch = getPlaylistsInBranch(repoId);
    const docsInBranch = getDocsInBranchWithoutApplicability(repoId);
    for (const item of folder.children) {
      const documentationItem = convertItemRefToDocumentationItem(repoId, item, playlistsInBranch, docsInBranch);
      items.push(documentationItem);
    }
    // Add drafts to the items list - place them in the right index
    for (const draft of folderDrafts) {
      let draftIndex = null;
      if (draft.isNew || !draft.id) {
        // New draft - place it by the folderIndex on the draft
        if ('folderIndex' in draft && draft.folderIndex >= 0) {
          draftIndex = draft.folderIndex;
        }
      } else {
        // Draft of commited doc - place it next to the doc.
        const originalDocIndex = items.findIndex((item) => item && item.id === draft.id);
        if (originalDocIndex >= 0) {
          draftIndex = originalDocIndex;
        }
      }

      if (draftIndex !== null) {
        items.splice(draftIndex, 0, draft);
      } else {
        items.push(draft);
      }
    }
    return items.filter((item) => item);
  }

  function convertItemRefToDocumentationItem(
    repoId: string,
    itemRef: firebase.firestore.DocumentReference,
    allPlaylists: Record<string, RepoPlaylistItem>,
    allDocs: Record<string, RepoDocItem>
  ): RepoDocItem | RepoPlaylistItem | (Folder & { documentationType: 'folder' }) | null {
    const itemType = getFolderItemType(itemRef);
    const itemId = itemRef.id;
    switch (itemType) {
      case FolderItemType.DOC: {
        const doc = allDocs?.[itemId];
        if (doc) {
          return { ...doc };
        }
        break;
      }
      case FolderItemType.PLAYLIST: {
        const playlist = allPlaylists?.[itemId];
        if (playlist) {
          return { ...playlist };
        }
        break;
      }
      case FolderItemType.FOLDER: {
        const folderItem = getFolder(itemId, repoId);
        if (folderItem) {
          return {
            ...folderItem,
            documentationType: DocumentationTypes.FOLDER,
          };
        }
        break;
      }
    }
    return null;
  }

  function getRepoRootFolder(requestedRepoId: string): Folder | null {
    if (requestedRepoId in foldersByRepo.value) {
      const repoFolders = Object.values(foldersByRepo.value[requestedRepoId]);
      return repoFolders.find((folder) => folder.is_root);
    }
    return null;
  }

  async function hasRootFolder(requestedRepoId: string): Promise<boolean> {
    const rootDocs = await getDocsRefFromSubCollectionWithWhereClauses(
      firestoreCollectionNames.REPOSITORIES,
      requestedRepoId,
      firestoreCollectionNames.FOLDERS,
      [['is_root', '==', true]],
      { source: 'server' }
    );
    return rootDocs.size > 0;
  }

  async function fetchFolders(requestedRepoId: string) {
    const response = await getSubCollection(
      firestoreCollectionNames.REPOSITORIES,
      requestedRepoId,
      firestoreCollectionNames.FOLDERS,
      { source: 'server' }
    );

    if (response.code !== config.SUCCESS_RETURN_CODE) {
      logger.error(`Failed to fetch repo ${requestedRepoId} folders: ${response.errorMessage}`);
      return false;
    } else {
      const folders = {};
      response.data.forEach((doc) => {
        folders[doc.id] = { ...doc.data(), id: doc.id };
      });
      foldersByRepo.value[requestedRepoId] = folders;
    }
    return true;
  }

  function handleFoldersRemoteUpdate(
    requestedRepoId,
    querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  ) {
    if (!foldersByRepo.value[requestedRepoId]) {
      return;
    }
    querySnapshot.docChanges().forEach((change) => {
      const folderId = change.doc.id;
      switch (change.type) {
        case 'added':
        case 'modified':
          foldersByRepo.value[requestedRepoId][folderId] = { ...change.doc.data(), id: folderId } as Folder;
          break;
        case 'removed':
          if (foldersByRepo.value[requestedRepoId][folderId]) {
            delete foldersByRepo.value[requestedRepoId][folderId];
          }
          break;
      }
    });
  }

  function subscribeToRepoFolders(requestedRepoId) {
    const collectionRef = getSubCollectionRefRecursive(
      [firestoreCollectionNames.REPOSITORIES, firestoreCollectionNames.FOLDERS],
      [requestedRepoId]
    );
    const unsubscribe = collectionRef.onSnapshot((querySnapshot) =>
      handleFoldersRemoteUpdate(requestedRepoId, querySnapshot)
    );
    folderCollectionsUnsubscribes.value.push(unsubscribe);
  }

  async function loadRepoFolders(requestedRepoId: string) {
    if (!foldersByRepo.value[requestedRepoId]) {
      const fetchedFolders = await fetchFolders(requestedRepoId);
      if (!fetchedFolders) {
        cleanCurrentData(requestedRepoId);
        return;
      }
      subscribeToRepoFolders(requestedRepoId);
    }
    const rootFolder = getRepoRootFolder(requestedRepoId);
    if (rootFolder) {
      // Override the root folder name with the repo name to keep it synced.
      rootFolder.name = db_getRepoName.value(requestedRepoId) ?? rootFolder.name;
      const currentFolder = currentFolderIdByRepo.value[requestedRepoId]
        ? getFolder(currentFolderIdByRepo.value[requestedRepoId], requestedRepoId)
        : null;
      if (!currentFolder) {
        currentFolderIdByRepo.value[requestedRepoId] = rootFolder.id;
      }
    }
  }

  function cleanCurrentData(repoId: string | null) {
    if (repoId) {
      currentFolderIdByRepo.value[repoId] = null;
    } else {
      currentFolderIdByRepo.value = {};
    }
  }

  async function updateFolder(
    folderId: string,
    repoId: string,
    update: { name?: string; children?: firebase.firestore.DocumentReference[] }
  ) {
    const folder = getFolder(folderId, repoId);
    if (!folder) {
      return;
    }
    const updateData = {
      ...update,
      modified: firestoreTimestamp(),
      modifier: user.value.uid,
      modifier_name: user.value.nickname,
      creator_profile_url: user.value.photoURL ?? '',
    };
    const response = await updateDocInSubCollection(
      firestoreCollectionNames.REPOSITORIES,
      repoId,
      firestoreCollectionNames.FOLDERS,
      folderId,
      updateData
    );
    if (response.code !== config.SUCCESS_RETURN_CODE) {
      logger.error(`Failed update folder ${folderId}: ${response.errorMessage}`);
      throw response.errorMessage;
    }
    const getFolderResponse = await getDocFromSubCollection(
      firestoreCollectionNames.REPOSITORIES,
      repoId,
      firestoreCollectionNames.FOLDERS,
      folderId
    );
    if (getFolderResponse.code !== config.SUCCESS_RETURN_CODE) {
      logger.error(`Failed fetching folder ${folderId}: ${getFolderResponse.errorMessage}`);
      throw getFolderResponse.errorMessage;
    }
    const originalName = getFolder(folderId, repoId)?.name;
    foldersByRepo.value[repoId][folderId] = { ...getFolderResponse.data, id: folderId } as Folder;

    if (update.name) {
      analytics.track(
        productEvents.RENAMED_FOLDER,
        {
          'Folder ID': folderId,
          'Folder Path': getFolderPath(folderId, repoId),
          'Old Name': originalName,
          'New Name': update.name,
        },
        { addRouteParams: true }
      );
    }
  }

  async function addFolder({
    name,
    repoId,
    isRoot = false,
    parentFolderId,
  }: {
    name: string;
    repoId: string;
    isRoot?: boolean;
    parentFolderId?: string;
  }) {
    const newFolder = {
      name,
      children: [],
      is_root: isRoot,
      created: firestoreTimestamp(),
      creator: user.value.uid,
      creator_name: user.value.nickname,
      modified: firestoreTimestamp(),
      modifier: user.value.uid,
      modifier_name: user.value.nickname,
      creator_profile_url: user.value.photoURL ?? '',
    };
    // check for existing root - isRoot
    if (isRoot) {
      // before creating root folder, verify that indeed there is no such folder
      if (await hasRootFolder(repoId)) {
        logger.error(
          { repoId },
          `[ALERT_TO_SLACK] adding root folder, but root folder already exists for repoId=${repoId}`
        );
        throw new Error('Root folder already exists');
      }
    }
    // Save the new folder to the DB
    const addResponse = await addDocToSubCollection(
      firestoreCollectionNames.REPOSITORIES,
      repoId,
      firestoreCollectionNames.FOLDERS,
      newFolder
    );

    if (addResponse.code !== config.SUCCESS_RETURN_CODE) {
      logger.error(`Failed to create folder "${name}": ${addResponse.errorMessage}`);
      throw addResponse.errorMessage;
    }

    // Fetch the folder and add to store
    const savedFolderId = addResponse.data.id;
    const getFolderResponse = await getDocFromSubCollection(
      firestoreCollectionNames.REPOSITORIES,
      repoId,
      firestoreCollectionNames.FOLDERS,
      savedFolderId
    );
    if (getFolderResponse.code !== config.SUCCESS_RETURN_CODE) {
      logger.error(`Failed fetching new folder ${savedFolderId}: ${getFolderResponse.errorMessage}`);
      throw getFolderResponse.errorMessage;
    }
    foldersByRepo.value[repoId][savedFolderId] = { ...getFolderResponse.data, id: savedFolderId } as Folder;

    // add to parent folder
    if (parentFolderId) {
      const parentFolder = getFolder(parentFolderId, repoId);
      parentFolder.children.unshift(addResponse.data);
      updateFolder(parentFolderId, repoId, { children: parentFolder.children }).catch(function (err) {
        logger.error({ err }, `Error adding folder "${name}" in parent folder ${parentFolderId}: ${err}`);
      });

      analytics.track(
        productEvents.CREATED_NEW_FOLDER,
        {
          'Parent Folder ID': parentFolderId,
          'Parent Folder Path': getFolderPath(parentFolderId, repoId),
          'Folder Name': name,
        },
        { addRouteParams: true }
      );
    }
    return savedFolderId;
  }

  function getFolder(folderId: string, requestedRepoId: string): Folder {
    return foldersByRepo.value[requestedRepoId]?.[folderId];
  }

  async function deleteFolder(folderId: string, repoId: string) {
    // List all items in all sub folders.
    const allSubChildren = getAllFolderSubItems(folderId, repoId);

    const itemsByType = iter.groupBy(allSubChildren, (item) => getFolderItemType(item));

    const allSubDocAndPlaylistRefs = [
      ...(itemsByType.has(FolderItemType.DOC) ? itemsByType.get(FolderItemType.DOC) : []),
      ...(itemsByType.has(FolderItemType.PLAYLIST) ? itemsByType.get(FolderItemType.PLAYLIST) : []),
    ];

    const playlistInBranch = getPlaylistsInBranch(repoId);
    const docsInBranch = getDocsInBranchWithoutApplicability(repoId);
    const docsAndPlaylistsToDelete = allSubDocAndPlaylistRefs
      .map((item) => convertItemRefToDocumentationItem(repoId, item, playlistInBranch, docsInBranch))
      .filter((item) => !!item);

    const folderDrafts = await drafts3Store.getDraftsByFolder(folderId);

    const shouldDelete = await confirmDeletion(docsAndPlaylistsToDelete, folderDrafts, itemsByType);
    if (!shouldDelete) {
      return;
    }

    const folderName = getFolder(folderId, repoId)?.name;

    async function deleteFolderFromDBAndDrafts() {
      await deleteDbFoldersAndDraftsAndMoveItemsToRootFolder([folderId], repoId, allSubDocAndPlaylistRefs, itemsByType);
    }

    if (docsAndPlaylistsToDelete.length) {
      openDeleteModal(docsAndPlaylistsToDelete, deleteFolderFromDBAndDrafts);
    } else {
      await deleteFolderFromDBAndDrafts();
    }

    analytics.track(productEvents.DELETED_FOLDER_RECURSIVELY, {
      'Folder ID': folderId,
      'Folder Name': folderName,
      'Total Docs Deleted': docsAndPlaylistsToDelete.length,
      // don't count the requested folder.
      'Total Folders Deleted': itemsByType.has(FolderItemType.FOLDER)
        ? itemsByType.get(FolderItemType.FOLDER).length
        : 0,
    });
  }

  async function deleteDbFoldersAndDraftsAndMoveItemsToRootFolder(
    folderIds: string[],
    repoId: string,
    allSubDocAndPlaylistRefs: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>[],
    itemRefsByType: Map<FolderItemType, firebase.firestore.DocumentReference<firebase.firestore.DocumentData>[]>
  ) {
    const allFolderIdsToDeleteSet = new Set(folderIds);
    const subFolders = itemRefsByType.has(FolderItemType.FOLDER) ? itemRefsByType.get(FolderItemType.FOLDER) : [];
    subFolders.forEach((folder) => allFolderIdsToDeleteSet.add(folder.id));
    const allFolderIdsToDelete = [...allFolderIdsToDeleteSet];
    const deleteFolderFromDbPromises = allFolderIdsToDelete.map((folderId) =>
      removeFolderFromDBAndStore(folderId, repoId)
    );
    const deleteFolderResults = await Promise.allSettled(deleteFolderFromDbPromises);

    let failedDelete = false;
    deleteFolderResults.forEach((result) => {
      if (result.status === 'rejected') {
        logger.error(`Failed to delete folder. Details: ${result.reason}`);
        failedDelete = true;
      } else if (result.value?.code === config.ERROR_RETURN_CODE) {
        logger.error(`Failed to delete folder. Details: ${result.value.errorMessage}`);
        failedDelete = true;
      }
    });

    if (failedDelete) {
      await swal({
        title: `Failed to delete ${allFolderIdsToDelete.length > 1 ? 'folders' : 'folder'}`,
        content: { element: SWAL_CONTACT_US_CONTENT() },
      });
    }

    // Move all the sub items to the root folder
    if (allSubDocAndPlaylistRefs.length) {
      const rootFolder = getRepoRootFolder(repoId);
      await updateFolder(rootFolder.id, repoId, { children: [...rootFolder.children, ...allSubDocAndPlaylistRefs] });
    }

    // Remove all folder drafts
    Promise.allSettled(allFolderIdsToDelete.map((folderId) => drafts3Store.deleteDraftsByFolder(folderId))).then();
  }

  async function confirmDeletion(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    docsAndPlaylistsToDelete: any[],
    folderDrafts: (DraftSwmFile | DbDraft)[],
    subItemsByType: Map<FolderItemType, firebase.firestore.DocumentReference<firebase.firestore.DocumentData>[]>
  ): Promise<boolean> {
    if (
      (subItemsByType.has(FolderItemType.DOC) || subItemsByType.has(FolderItemType.PLAYLIST)) &&
      docsAndPlaylistsToDelete.length === 0 &&
      folderDrafts.length === 0
    ) {
      // Folder has items in it but not on the current branch.
      let itemsString = 'docs';
      if (subItemsByType.has(FolderItemType.DOC) && subItemsByType.has(FolderItemType.PLAYLIST)) {
        itemsString = 'docs/playlists';
      } else if (subItemsByType.has(FolderItemType.PLAYLIST)) {
        itemsString = 'playlists';
      }
      const shouldDelete = await swal({
        title: 'Folder contains items in another branch, delete anyway?',
        text: `Folder contains ${itemsString} in another branch. Are you sure you want to delete it? The ${itemsString} will be moved to the root folder.`,
        dangerMode: true,
        buttons: {
          cancel: true,
          confirm: { text: 'Delete recursively', visible: true },
        },
      });
      return shouldDelete;
    } else if (docsAndPlaylistsToDelete.length || folderDrafts.length || subItemsByType.has(FolderItemType.FOLDER)) {
      // Folder has items (commited docs/playlists or drafts) on the current branch.
      const docsCount = docsAndPlaylistsToDelete.filter(
        (item) => item.documentationType === DocumentationTypes.DOC
      ).length;
      const playlistsCount = docsAndPlaylistsToDelete.filter(
        (item) => item.documentationType === DocumentationTypes.PLAYLIST
      ).length;
      let itemsString = pluralize({ word: 'doc', count: docsCount });
      if (docsCount && playlistsCount) {
        itemsString = 'docs/playlists';
      } else if (playlistsCount) {
        itemsString = pluralize({ word: 'playlist', count: playlistsCount });
      }
      const subFoldersCount = subItemsByType.has(FolderItemType.FOLDER)
        ? subItemsByType.get(FolderItemType.FOLDER).length
        : 0;
      const shouldDelete = await swal({
        title: 'Delete folder & its contents?',
        text: `Are you sure you want to delete this folder? This will delete the ${
          docsAndPlaylistsToDelete.length ? `${docsAndPlaylistsToDelete.length} ${itemsString}` : ''
        }${docsAndPlaylistsToDelete.length && folderDrafts.length ? ' and ' : ''}${
          folderDrafts.length
            ? `${folderDrafts.length} ${pluralize({ word: 'draft', count: folderDrafts.length })}`
            : ''
        }${(docsAndPlaylistsToDelete.length || folderDrafts.length) && subFoldersCount ? ' and ' : ''}${
          subFoldersCount
            ? `${subFoldersCount} ${pluralize({ word: 'folder', count: subFoldersCount })}(and ${
                subFoldersCount > 1 ? 'their' : 'its'
              } content)`
            : ''
        } inside. If you wish to continue, commit your changes for them to reflect on the remote branch.`,
        dangerMode: true,
        buttons: {
          cancel: true,
          confirm: { text: 'Delete recursively', visible: true },
        },
      });
      return shouldDelete;
    }
    return true;
  }

  async function removeFolderFromDBAndStore(folderId: string, repoId: string) {
    const parentFolder = getItemParentFolder(folderId, repoId);
    if (parentFolder) {
      // if the deleted folder is selected - select the parent folder.
      const currentFolderId = currentFolderIdByRepo.value[repoId];
      if (currentFolderId === folderId) {
        currentFolderIdByRepo.value[repoId] = parentFolder.id;
      }
    }

    const result = await deleteDocFromSubCollection(
      firestoreCollectionNames.REPOSITORIES,
      repoId,
      firestoreCollectionNames.FOLDERS,
      folderId
    );
    if (result.code !== config.SUCCESS_RETURN_CODE) {
      return result;
    }

    // Remove folder from the parent folder.
    if (parentFolder) {
      const index = parentFolder.children.findIndex((child) => child.id === folderId);
      parentFolder.children.splice(index, 1);
      updateFolder(parentFolder.id, repoId, { children: parentFolder.children }).then();
    }

    if (foldersByRepo.value[repoId]?.[folderId]) {
      delete foldersByRepo.value[repoId][folderId];
    }

    return result;
  }

  function getAllFolderSubItems(folderId: string, repoId: string) {
    const folder = getFolder(folderId, repoId);
    const items = folder ? [...folder.children] : [];
    folder?.children.forEach((item) => {
      if (getFolderItemType(item) === FolderItemType.FOLDER) {
        const subFolderItems = getAllFolderSubItems(item.id, repoId);
        items.push(...subFolderItems);
      }
    });
    return items;
  }

  function selectFolder(repoId: string, folderId: string) {
    currentFolderIdByRepo.value[repoId] = folderId;
  }

  function isFolderNameExists(folderName: string, parentFolderId: string, repoId: string) {
    return getFolderByName(folderName, parentFolderId, repoId) != null;
  }

  function getFolderByName(folderName: string, parentFolderId: string, repoId: string): Folder | null {
    const parentFolder = getFolder(parentFolderId, repoId);
    const item = parentFolder.children.find(
      (item) => getFolderItemType(item) === FolderItemType.FOLDER && getFolder(item.id, repoId)?.name === folderName
    );
    return item ? getFolder(item.id, repoId) : null;
  }

  function getFolderLastModifiedDate(folderId: string, repoId: string) {
    const folder = getFolder(folderId, repoId);
    if (!folder) {
      return null;
    }

    const folderItems = getFolderItems({ repoId, folderId, includeDrafts: true });

    // find the max modified date from the folder items - only one level in the folder.
    const maxModificationDate = folderItems.reduce((accumulator, currentItem) => {
      const currentItemModificationDate = currentItem?.modified;
      if (currentItemModificationDate == null) {
        return accumulator;
      }
      return accumulator.seconds > currentItemModificationDate.seconds ? accumulator : currentItemModificationDate;
    }, folder.modified ?? new Timestamp(0, 0));

    if (maxModificationDate) {
      return datetimeUtils.getDateFromSeconds(maxModificationDate.seconds);
    }
    return null;
  }

  const initRootFolderPromises = {};

  async function initRootFolder(repoId: string) {
    if (!repoId || !(repoId in foldersByRepo.value)) {
      return;
    }
    // prevent parallel calls to initRootFolderImpl for the same repo id
    if (initRootFolderPromises[repoId] == null) {
      initRootFolderPromises[repoId] = initRootFolderImpl(repoId);
    } else {
      logger.warn({ repoId }, `double call to initRootFolderForRepoId ${repoId}`);
    }
    await initRootFolderPromises[repoId];
    initRootFolderPromises[repoId] = null;
  }

  async function initRootFolderImpl(repoId: string) {
    try {
      if (!objectUtils.isEmpty(foldersByRepo.value[repoId])) {
        await backfillMissingItemsInRootFolder(repoId);
        return;
      }
      await createRootFolder(repoId);
    } catch (err) {
      logger.error({ err }, `Failed to init root folder for repo ${repoId}: ${err}`);
    }
  }

  async function backfillMissingItemsInRootFolder(repoId: string) {
    try {
      const allPlaylists = db_getPlaylists.value(repoId);
      const allDocs = db_getDocs.value(repoId);

      const repoFolders = foldersByRepo.value[repoId];
      const allItemIdsInFolders = [];
      Object.values(repoFolders).forEach((folder) => {
        folder.children?.forEach((child) => {
          allItemIdsInFolders.push(child.id);
        });
      });

      const playlistsNotInAnyFolder = Object.keys(allPlaylists)
        .filter((playlistId) => !allItemIdsInFolders.includes(playlistId))
        .map((playlistId) =>
          getDocRefRecursive(
            [firestoreCollectionNames.REPOSITORIES, firestoreCollectionNames.PLAYLISTS],
            [repoId, playlistId]
          )
        );

      const docsNotInAnyFolder = Object.keys(allDocs)
        .filter((docId) => !allItemIdsInFolders.includes(docId))
        .map((docId) =>
          getDocRefRecursive([firestoreCollectionNames.REPOSITORIES, firestoreCollectionNames.SWIMMS], [repoId, docId])
        );

      if (!playlistsNotInAnyFolder.length && !docsNotInAnyFolder.length) {
        return;
      }

      const rootFolder = getRepoRootFolder(repoId);
      const updtaedRootFolderChildren = [...rootFolder.children, ...playlistsNotInAnyFolder, ...docsNotInAnyFolder];
      await updateFolder(rootFolder.id, repoId, { children: updtaedRootFolderChildren });
    } catch (err) {
      logger.error({ err }, `Failed to backfill missing items in root folder for repo ${repoId}: ${err}`);
    }
  }

  async function createRootFolder(repoId: string) {
    try {
      if (!foldersByRepo.value[repoId]) {
        foldersByRepo.value[repoId] = {};
      }
      logger.info({ repoId }, `Creating root folder for repo ${repoId}`);
      await addFolder({ name: db_getRepoName.value(repoId), repoId, isRoot: true });
      const rootFolder = getRepoRootFolder(repoId);

      const allPlaylists = db_getPlaylists.value(repoId);
      const allDocs = db_getDocs.value(repoId);

      const children = Object.keys(allPlaylists).map((playlistId) =>
        getDocRefRecursive(
          [firestoreCollectionNames.REPOSITORIES, firestoreCollectionNames.PLAYLISTS],
          [repoId, playlistId]
        )
      );

      Object.keys(allDocs).forEach((docId) => {
        // filter out exercises and external links.
        if (isSwmDoc({ swm: allDocs[docId] })) {
          children.push(
            getDocRefRecursive(
              [firestoreCollectionNames.REPOSITORIES, firestoreCollectionNames.SWIMMS],
              [repoId, docId]
            )
          );
        }
      });
      if (children.length > 0) {
        await updateFolder(rootFolder.id, repoId, { children });
      }
      currentFolderIdByRepo.value[repoId] = rootFolder.id;
    } catch (err) {
      logger.error({ err }, `Failed to create root folder for repo ${repoId}: ${err}`);
    }
  }

  async function addItemToFolder(
    folderId: string | undefined,
    repoId: string,
    itemId: string,
    itemType: FolderItemType
  ) {
    const parentFolderId = folderId ?? getRepoRootFolder(repoId)?.id;
    if (!parentFolderId) {
      logger.warn(
        `No folder Id provided or root folder existing in repo "${repoId}". Skipping item addition to folder.`
      );
      return;
    }
    const child = getDocRefRecursive(
      [firestoreCollectionNames.REPOSITORIES, FolderItemTypeToCollection[itemType]],
      [repoId, itemId]
    );

    let parentFolder = getFolder(parentFolderId, repoId);
    if (!parentFolder) {
      // Fallback: add to root folder - so the item won't get lost if the folder doesn't exist anymore.
      parentFolder = getRepoRootFolder(repoId);
    }
    parentFolder.children.push(child);
    await updateFolder(parentFolderId, repoId, { children: parentFolder.children });
  }

  async function moveItemsToRootFolder(repoId: string, items) {
    const rootFolder = getRepoRootFolder(repoId);
    if (rootFolder) {
      await moveItemsToFolder(rootFolder.id, repoId, items, false);
    }
  }

  async function moveItemsToFolder(folderId: string, repoId: string, items, trackMove = true) {
    const itemWithId = items.find((item) => item.id);
    if (!itemWithId) {
      // there are only new drafts
      return;
    }
    const oldParentFolder = getItemParentFolder(itemWithId.id, repoId);

    const newParentFolder = getFolder(folderId, repoId);

    items.forEach((item) => {
      if (item.isNew) {
        // don't save new drafts folder in DB
        return;
      }

      const folderItemType = item.folderType
        ? item.folderType
        : DocumentationTypeToFolderItemType[item.documentationType];
      const itemDoc = getDocRefRecursive(
        [firestoreCollectionNames.REPOSITORIES, FolderItemTypeToCollection[folderItemType]],
        [repoId, item.id]
      );

      // Add to new folder
      newParentFolder.children.push(itemDoc);

      // Remove from old folder
      if (oldParentFolder != null) {
        const index = oldParentFolder.children.findIndex((child) => child.id === item.id);
        oldParentFolder.children.splice(index, 1);
      }
    });

    if (oldParentFolder != null) {
      await updateFolder(oldParentFolder.id, repoId, { children: oldParentFolder.children });
    }
    await updateFolder(folderId, repoId, { children: newParentFolder.children });

    if (trackMove) {
      const counters = countDocumentationTypes(items);
      const oldParentFolderId = oldParentFolder?.id ?? getRepoRootFolder(repoId)?.id;
      analytics.track(productEvents.MOVED_ITEMS_TO_FOLDER, {
        'Source Folder ID': oldParentFolderId,
        'Source Folder Path': oldParentFolderId && getFolderPath(oldParentFolderId, repoId),
        'Item Count': items.length,
        'Selected Folder Count': counters.folder,
        'Selected Doc Count': counters.document,
        'Selected Playlist Count': counters.playlist,
        'Selected Draft Count': counters.draft,
        'Target Folder ID': folderId,
        'Target Folder Path': getFolderPath(folderId, repoId),
      });
    }
  }

  function isSubfolderHasNameConflict(folderId: string, repoId: string, itemsToMove) {
    const newParentFolder = getFolder(folderId, repoId);

    const nameConflicts = itemsToMove
      .filter((item) => item.documentationType === DocumentationTypes.FOLDER)
      .filter((item) => {
        return newParentFolder.children.some((folder) => {
          const childFolder = getFolder(folder.id, repoId);
          return childFolder?.name === item.name;
        });
      })
      .map((item) => item.name);

    const hasNameConflicts = Boolean(nameConflicts.length);
    if (hasNameConflicts) {
      analytics.track(productEvents.MOVE_FOLDERS_HAS_NAME_CONFLICTS);
    }
    return { hasNameConflicts, folders: nameConflicts };
  }

  function getItemParentFolder(itemId: string, currentRepoId: string): Folder | null {
    const repoFolders = foldersByRepo.value[currentRepoId];
    if (!repoFolders) {
      return null;
    }
    return Object.values(repoFolders).find((folder) => folder.children.some((item) => item.id === itemId));
  }

  // Can be used for both committed and uncommitted items.
  function getItemParentFolder3(id: string, repoId: string): Folder | null {
    const draftFolderId = drafts3Store.drafts?.get(id)?.folderId;
    if (draftFolderId != null) {
      return getFolder(draftFolderId, repoId);
    }

    return getItemParentFolder(id, repoId);
  }

  async function updateItemIndexInFolder({
    folderId,
    repoId,
    itemId,
    draftId,
    isNew,
    itemIdToPlaceAfter,
    itemDraftIdToPlaceAfter,
  }: {
    folderId: string;
    repoId: string;
    itemId: string;
    draftId: string;
    isNew?: boolean;
    itemIdToPlaceAfter: string;
    itemDraftIdToPlaceAfter: string;
  }) {
    const folder = getFolder(folderId, repoId);
    if (!folder) {
      return;
    }
    const children = folder.children;

    let toIndex = 0;

    const idToPlaceAfter = itemIdToPlaceAfter || itemDraftIdToPlaceAfter;
    if (idToPlaceAfter) {
      const itemToPlaceAfterIndex = children.findIndex((child) => child.id === idToPlaceAfter);
      if (itemToPlaceAfterIndex >= 0) {
        toIndex = itemToPlaceAfterIndex + 1;
      } else {
        const draftToPlaceAfter = drafts3Store.drafts.get(idToPlaceAfter);
        if (draftToPlaceAfter != null && draftToPlaceAfter.folderIndex != null && draftToPlaceAfter.folderIndex >= 0) {
          toIndex = draftToPlaceAfter.folderIndex + 1;
        }
      }
    }

    // New Draft - update the folderIndex on the draft
    if (isNew) {
      await drafts3Store.updateAttrs(itemId || draftId, { folderIndex: toIndex });
      return;
    }

    // Commited item / folder - update the location in the parent folder children array.
    const itemIndex = children.findIndex((child) => child.id === itemId);
    if (itemIndex < 0) {
      return;
    }
    const item = children[itemIndex];
    children.splice(itemIndex, 1);
    if (itemIndex < toIndex) {
      // if the destination index is after the origion index then we need to
      // decrease it by one because we removed the item from the list above.
      toIndex = toIndex - 1;
    }
    children.splice(toIndex, 0, item);
    await updateFolder(folderId, repoId, { children });
  }

  watch(
    route,
    (to) => {
      const repoIdParam = to ? (to.params.repoId as string) : null;
      if (!repoIdParam) {
        cleanCurrentData(null);
      } else {
        loadRepoFolders(repoIdParam);
      }
    },
    { immediate: true }
  );

  function isFoldersReady(repoId: string) {
    return Boolean(getRepoRootFolder(repoId));
  }

  function resetState() {
    cleanCurrentData(null);
    foldersByRepo.value = {};
    for (const unsubscribe of folderCollectionsUnsubscribes.value) {
      try {
        unsubscribe();
      } catch (ex) {
        logger.warn(`Failed to unsubscribe from folders subscription ${ex}`);
      }
    }
    folderCollectionsUnsubscribes.value = [];
  }

  function isMatchFilter(text: string, filter: string) {
    return text?.toLowerCase().includes(filter.toLowerCase());
  }

  function getFilteredFolders(filter: string, repoId: string): FolderFilterResult[] {
    if (!filter) {
      return [];
    }
    function filterFolders(folder: Folder) {
      const filteredFolders = [];
      if (!folder) {
        return filteredFolders;
      }

      const folderItems = getFolderItems({ repoId, folderId: folder.id, includeDrafts: true });
      let shouldAddCurrentFolder = isMatchFilter(folder.name, filter);
      let shouldExpandFolder = false;

      folderItems.forEach((item) => {
        if (item.documentationType === DocumentationTypes.FOLDER) {
          const subFilteredFolder = filterFolders(item);
          if (subFilteredFolder.length) {
            shouldAddCurrentFolder = true;
            shouldExpandFolder = true;
            filteredFolders.push(...subFilteredFolder);
          }
        }
        if (isMatchFilter(item.name, filter)) {
          shouldAddCurrentFolder = true;
          shouldExpandFolder = true;
        }
      });

      if (shouldAddCurrentFolder) {
        filteredFolders.push({ folderId: folder.id, expanded: shouldExpandFolder });
      }
      return filteredFolders;
    }

    const rootFolder = getRepoRootFolder(repoId);
    return rootFolder ? filterFolders(rootFolder) : [];
  }

  return {
    currentFolderIdByRepo,
    foldersByRepo,
    isAddingNewFolderState,
    renameFolderId,
    loadRepoFolders,
    getFolder,
    addFolder,
    deleteFolder,
    selectFolder,
    updateFolder,
    isFolderNameExists,
    initRootFolder,
    addItemToFolder,
    getFolderLastModifiedDate,
    getFolderBreadCrumbs,
    getFolderPath,
    moveItemsToFolder,
    moveItemsToRootFolder,
    getItemParentFolder,
    getItemParentFolder3,
    isSubfolderHasNameConflict,
    getFolderItems,
    getAllFolderSubItems,
    getFolderItemType,
    deleteDbFoldersAndDraftsAndMoveItemsToRootFolder,
    getRepoRootFolder,
    updateItemIndexInFolder,
    isFoldersReady,
    resetState,
    getFilteredFolders,
    createRootFolder,
    getFolderByName,
  };
});
