/**
 * Timing out a promise by using "Promise.race" with a promisified setTimeout.
 * If the promise fails to fulfill in that time,an error will be thrown
 * @param promiseToFulfill - the actual promise to run
 * @param timeoutInMS - the timeout (in ms) to "wait" for the promise to fulfill
 */
export async function promiseWithTimeout(promiseToFulfill: Promise<unknown>, timeoutInMS: number) {
  const timedOutPromise = new Promise((_, reject) =>
    setTimeout(() => reject(new Error(`Response did not arrive after ${timeoutInMS} ms. Aborting.`)), timeoutInMS)
  );
  return await Promise.race([timedOutPromise, promiseToFulfill]);
}

/**
 *
 * @param n - number of promises to run at a time
 * @param list  - list of promises to run: array.map.((item) => () => Promise.resolve(item))
 * @returns - array of results T
 */
export const promiseAllLimit = async <T>(n: number, list: (() => Promise<T>)[]) => {
  const head = list.slice(0, n);
  const tail = list.slice(n);
  const result: T[] = [];

  const execute = async (promise: () => Promise<T>, i: number, runNext: () => Promise<void>) => {
    result[i] = await promise();
    await runNext();
  };

  const runNext = async () => {
    const i = list.length - tail.length;
    const promise = tail.shift();
    if (promise !== undefined) {
      await execute(promise, i, runNext);
    }
  };

  await Promise.all(head.map((promise, i) => execute(promise, i, runNext)));

  return result;
};
