import { type Ref, nextTick, ref, watch } from 'vue';
import type { SelectedIndex } from '../../types/transitional';
import { scrollToItem } from '../utilties';

export function useItemSelectionScrollHandler(selectedIndex: Ref<SelectedIndex | undefined>) {
  const itemRefs = ref<(HTMLElement | null)[]>([]);
  const scrollContainerRef = ref<HTMLElement | null>(null);

  function setItemRef(el: HTMLElement, itemIndex: number) {
    itemRefs.value[itemIndex] = el;
  }

  if (selectedIndex.value) {
    watch(
      () => selectedIndex.value,
      async () => {
        await nextTick();

        const item = itemRefs.value[selectedIndex.value?.[0] || 0];
        if (item && scrollContainerRef.value) {
          scrollToItem(item, scrollContainerRef.value);
        }
      },
      { immediate: true }
    );
  }

  return {
    itemRefs,
    scrollContainerRef,
    setItemRef,
  };
}
