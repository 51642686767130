<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';

const COLOR_GROUP_COUNT = 12;

const props = defineProps({
  text: { type: String, required: true },
  src: { type: String, default: '' },
  size: {
    type: String as PropType<SwAvatarSize>,
    default: 'small',
    validator: (value: SwAvatarSize) => Object.values(SwAvatarSizeValues).includes(value),
  },
  tooltip: { type: String, default: '' },
  hideTooltip: { type: Boolean, default: false },
  square: { type: Boolean, default: false },
  border: { type: Boolean, default: false },
});
const letter = computed(() => (props.text || ' ').charAt(0).toUpperCase());
const colorGroup = computed(
  () => ([...(props.text || '')].reduce((sum, letter) => sum + letter.charCodeAt(0), 0) % COLOR_GROUP_COUNT) + 1
);
const tooltipText = computed(() => (props.hideTooltip ? '' : props.tooltip || props.text));
</script>

<template>
  <div
    :class="[
      'avatar',
      size,
      { [`color-group-${colorGroup}`]: !src && letter !== ' ', loading: letter === ' ', square, border },
    ]"
    v-tooltip="tooltipText"
  >
    <img v-if="src" :src="src" :alt="text" class="image" />
    <span v-else :data-name="text">{{ letter }}</span>
  </div>
</template>

<script lang="ts">
export const SwAvatarSizeValues = ['xsmall', 'small', 'medium', 'large', 'xlarge', 'huge'] as const;
export type SwAvatarSize = (typeof SwAvatarSizeValues)[number];
</script>

<style scoped>
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: default;
  flex-shrink: 0; /* Make sure avatars aren't squashed when placed next to long text. */

  &.border {
    border: 1px solid var(--color-border-default-subtle);
  }

  &.square {
    border-radius: 15%;
  }

  &.xsmall {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    font-size: 12px;
    width: 16px;
    height: 16px;
  }

  &.small {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    font-size: 14px;
    width: 24px;
    height: 24px;
  }

  &.medium {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    font-size: 24px;
    width: 32px;
    height: 32px;
  }

  &.large {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    font-size: 48px;
    width: 64px;
    height: 64px;
  }

  &.xlarge {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    font-size: 48px;
    width: 72px;
    height: 72px;
  }

  &.huge {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    font-size: 64px;
    width: 112px;
    height: 112px;
  }

  &.color-group-1 {
    color: var(--quick-dive);
    background-color: var(--floating-glacier);
  }

  &.color-group-2 {
    color: var(--clear-white);
    background-color: var(--shallow-waters);
  }

  &.color-group-3 {
    color: var(--floating-glacier);
    background-color: var(--swimm-blue);
  }

  &.color-group-4 {
    color: var(--floating-glacier);
    background-color: var(--quick-dive);
  }

  &.color-group-5 {
    color: var(--purple-aura);
    background-color: var(--jelly-shake);
  }

  &.color-group-6 {
    color: var(--purple-aura);
    background-color: var(--pixie-dust);
  }

  &.color-group-7 {
    color: var(--clear-white);
    background-color: var(--high-violet);
  }

  &.color-group-8 {
    color: var(--safe-zone);
    background-color: var(--purple-aura);
  }

  &.color-group-9 {
    color: var(--army-duck);
    background-color: var(--just-hatched);
  }

  &.color-group-10 {
    color: var(--sand-castle);
    background-color: var(--duck-mode);
  }

  &.color-group-11 {
    color: var(--gravel-rocks);
    background-color: var(--fifty-shades);
  }

  &.color-group-12 {
    color: var(--clear-white);
    background-color: var(--high-tide);
  }

  &.loading {
    background-color: var(--color-surface);
  }
}

.image {
  max-height: 100%;
  object-fit: contain;
}
</style>
