<script setup lang="ts">
import { PageRoutesNames } from '@/common/consts';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { Icon, SwText, TextField } from '@swimm/ui';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';
import { debounce } from 'lodash-es';

const props = defineProps({
  workspaceId: { type: String, required: true },
  searchTerm: { type: String, default: '' },
  isQuickSearchOpen: { type: Boolean, default: false },
});

const emit = defineEmits<{
  (e: 'home-link-clicked'): void;
  (e: 'search-opened'): void;
  (e: 'search-changed', search: string): void;
  (e: 'search-closed'): void;
}>();

const analytics = useAnalytics();
const route = useRoute();

const toggleQuickSearch = () => {
  if (props.isQuickSearchOpen) {
    emit('search-closed');
  } else {
    emit('search-opened');
    analytics.track(productEvents.CLICKED_QUICK_SEARCH_BUTTON);
  }
};

const onBlur = () => {
  if (props.searchTerm || !props.isQuickSearchOpen) {
    return;
  }
  toggleQuickSearch();
};

const isHomePage = computed(() => {
  return route.matched.some((match) => match.name === PageRoutesNames.HOME_PAGE);
});

function searchChanged(search: string) {
  emit('search-changed', search);
  debouncedReportEnteredSearchTerm();
}

const debouncedReportEnteredSearchTerm = debounce(() => {
  reportEnteredSearchTerm();
}, 200);

function reportEnteredSearchTerm() {
  analytics.track(productEvents.ENTERED_QUICK_SEARCH_TERM, {
    Term: props.searchTerm,
  });
}
</script>

<template>
  <router-link
    class="home-line"
    :class="{ selected: isHomePage }"
    @click="emit('home-link-clicked')"
    :to="workspaceId ? `/workspaces/${workspaceId}` : '/'"
  >
    <template v-if="isQuickSearchOpen">
      <TextField
        class="search-input"
        @update:model-value="searchChanged"
        :model-value="searchTerm"
        focus-first
        placeholder="Filter repos, folders, docs"
        @click.stop.prevent
        @blur="onBlur"
        ><Icon name="close" class="clickable" data-testid="quick-search-close" @click.stop.prevent="toggleQuickSearch"
      /></TextField>
    </template>
    <template v-else>
      <div class="submenu-button home-text">
        <SwText component="span" variant="subtitle-L" class="button-text">Home</SwText>
      </div>
      <div
        class="submenu-button search"
        v-tooltip="'Quick search'"
        data-testid="quick-search-button"
        @click.stop.prevent="toggleQuickSearch"
      >
        <Icon name="search" class="button-text" />
      </div>
    </template>
  </router-link>
</template>

<style scoped lang="postcss">
.home-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--sidebar-item-height);
  padding: 0 calc(var(--space-sm) - 4px) 0 var(--space-sm); /* 4px - account for the scrollbar */
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--color-bg);

  .home-text .button-text {
    padding: 2px var(--space-xs);
  }

  .submenu-button {
    .button-text {
      cursor: pointer;
      padding: var(--space-xs);
      border-radius: 4px;

      &:hover {
        background-color: var(--color-hover);
      }
    }
  }

  &.selected {
    cursor: default;
  }

  &:not(.selected):hover {
    cursor: pointer;
    background-color: var(--color-hover);
  }

  .search-input {
    height: 100%;
  }

  &.selected .home-text .button-text {
    background-color: var(--color-selected);
    color: var(--text-color-link);
    cursor: default;
  }
}
</style>
