import { LineWords, type TokenSuggestion, splitLineByWords } from '@swimm/shared';

export function getSuggestionsForQuery(
  query: string,
  suggestions: TokenSuggestion[],
  options?: { exactMatch?: boolean }
): TokenSuggestion[] {
  const fullOptions = Object.assign({}, { exactMatch: false }, options);

  query = query.toLowerCase();
  const queryWords = splitLineByWords(query);
  if (queryWords.length === 0) {
    return [];
  }

  if (queryWords.length === 1) {
    return suggestions.filter((suggestion) =>
      matchSuggetion(suggestion.token.toLowerCase(), queryWords[0], fullOptions)
    );
  } else {
    return suggestions
      .filter((suggestion) => suggestion.token.toLowerCase() === queryWords[0])
      .map((suggestion) => getFullSuggestionForQuery(query, suggestion, fullOptions))
      .filter((suggestion): suggestion is TokenSuggestion => suggestion != null);
  }
}

function getFullSuggestionForQuery(
  query: string,
  suggestion: TokenSuggestion,
  { exactMatch }: { exactMatch: boolean },
  lineWords: LineWords = new LineWords(suggestion.lineData)
): TokenSuggestion | null {
  const tokenStartCharacterIndex = lineWords.wordIndexToCharacterRange(suggestion.position.wordStart)?.start;
  if (tokenStartCharacterIndex == null) {
    return null;
  }
  let suggestionLastWordIndex: number | null = null;
  if (suggestion.token.match(/['"`]/)) {
    // If the suggestion is a string literal, we'll extend the suggestion until the end of the string.
    suggestionLastWordIndex = lineWords.words.indexOf(suggestion.token, suggestion.position.wordEnd + 1);
    if (suggestionLastWordIndex === -1) {
      suggestionLastWordIndex = null;
    }
  }
  if (suggestionLastWordIndex == null) {
    const queryLastCharacter = tokenStartCharacterIndex + query.length - 1;
    suggestionLastWordIndex = lineWords.characterIndexToWordIndex(queryLastCharacter);
    if (suggestionLastWordIndex == null) {
      return null;
    }
  }
  const suggestionLastCharacterIndex = lineWords.wordIndexToCharacterRange(suggestionLastWordIndex)?.end;
  if (suggestionLastCharacterIndex == null) {
    return null;
  }
  const fullToken = suggestion.lineData.slice(tokenStartCharacterIndex, suggestionLastCharacterIndex + 1);
  if (!matchSuggetion(fullToken.toLowerCase(), query, { exactMatch })) {
    return null;
  }
  return {
    ...suggestion,
    token: fullToken,
    position: {
      ...suggestion.position,
      wordEnd: suggestionLastWordIndex,
    },
  };
}

// Note: This function is case-sensitive. Use `.toLowerCase()` on the paramters for case-insensitive.
function matchSuggetion(token: string, query: string, { exactMatch }: { exactMatch: boolean }) {
  if (exactMatch) {
    return token === query;
  }
  return token.startsWith(query);
}
