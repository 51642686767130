import type { SmartElementWithApplicability, SmartSymbol } from '@swimm/shared';
import { SymbolPath } from './symbol-plugins/symbol-path';
import { SymbolGenericText } from './symbol-plugins/symbol-generic-text';
import { SymbolLink } from './symbol-plugins/symbol-link';

const SYMBOL_PLUGINS = [SymbolPath, SymbolGenericText, SymbolLink];

/**
 * Update the given symbols in-place, adding an applicability status to them.
 * @param symbols - the symbols we'd like to update as autosync symbols.
 */
export async function updateSymbols(
  symbols: SmartSymbol[]
): Promise<{ isApplicable: boolean; symbolsWithApplicability: SmartElementWithApplicability<SmartSymbol>[] }> {
  let noSymbolsOutdated = true;
  const symbolsWithApplicability: SmartElementWithApplicability<SmartSymbol>[] = [];
  // We're running through all the plugins, calling their update function.
  //  If even one of the symbols is outdated, the entire result of this verification process will be false.
  //  However, even if we found an outdated symbol, we still want to update all the others as well in order to get their applicability status.
  for (const pluginClass of SYMBOL_PLUGINS) {
    const plugin = new pluginClass(symbols);
    const pluginResult = await plugin.updateSymbols();
    if (!pluginResult.isApplicable) {
      noSymbolsOutdated = false;
    }
    symbolsWithApplicability.push(...pluginResult.symbolsWithApplicability);
  }
  return { isApplicable: noSymbolsOutdated, symbolsWithApplicability };
}

/**
 * Verify the given symbols.
 * @param symbols - autosync symbols we'd like to verify.
 */
export async function verifySymbols(symbols: SmartSymbol[]): Promise<boolean> {
  for (const pluginClass of SYMBOL_PLUGINS) {
    const plugin = new pluginClass(symbols);
    if (!(await plugin.verifySymbols())) {
      return false;
    }
  }
  return true;
}
