// @ts-strict
import {
  ApplicabilityStatus,
  AutosyncInput,
  AutosyncOutput,
  DocCacheStructure,
  config,
  getLogger,
  gitwrapper,
} from '@swimm/shared';
import { isAutosyncInputApplicable, isCrossRepoAutosyncInput } from './applicability-utils';
import { AutosyncUnitReturnType, autosyncUnit } from './autosync';
import { getFromCache, setInCache } from './cache-utils/autosync-cache';
import { cloneDeep, remove } from 'lodash-es';
import { isElementWithoutGitAccess } from './swimmagic-common';
import { sha256 } from 'js-sha256';

const logger = getLogger("packages/swimmagic/src/applicability.ts");

interface GetResourceApplicabilityStateReturn {
  id: string;
  unitStatus: ApplicabilityStatus;
  autosyncResult:
    | null
    | { autosyncSuccess: false }
    | {
        autosyncSuccess: true;
        autosyncOutput: AutosyncOutput;
      };
}

export async function getResourceApplicabilityState({
  autosyncInput,
  resourceId,
  repoId,
  currentBranch,
  workspaceId,
  isDraft,
}: {
  autosyncInput: AutosyncInput;
  resourceId: string;
  repoId: string;
  currentBranch: string;
  workspaceId?: string;
  isDraft?: boolean;
}): Promise<GetResourceApplicabilityStateReturn> {
  let unitStatus: ApplicabilityStatus | null = null;
  let autosyncResult: null | AutosyncUnitReturnType = null;
  let autosyncOutput: null | AutosyncOutput = null;
  try {
    let cacheHit: DocCacheStructure | null = null;
    let commitSha: string | undefined;
    // We cannot cache cross repo docs
    // since each user can get different applicability status
    // depend on the cross repos he has access to
    const isCrossRepo = isCrossRepoAutosyncInput({ autosyncInput, repoId });
    const shouldGetFromCache = !!workspaceId && !isCrossRepo && !isDraft;
    try {
      if (shouldGetFromCache) {
        commitSha = (await gitwrapper.getBranch({ repoId, branchName: currentBranch })).sha;
        cacheHit = await getFromCache({
          cacheId: getAutosyncChaceIdChecksum(autosyncInput, repoId, commitSha),
          workspaceId,
        });
        // If the cache verdict is 'autosyncable' or 'outdated' then we don't use the cache result,
        // we will run autosync on the next step because we need to return the autosync output.
        if (cacheHit && cacheHit.meta.verdict === ApplicabilityStatus.Verified) {
          unitStatus = cacheHit.meta.verdict;
        }
      }
    } catch (error) {
      logger.error(
        `Get value from cache (repo=${repoId},branch=${currentBranch}) failed with error: ${error.toString()}`
      );
    }

    if (!unitStatus) {
      unitStatus = ApplicabilityStatus.Outdated;
      // save before we remove the no access elements
      const originalAutosyncInput = cloneDeep(autosyncInput);

      // if there is no gitInfo or not gitInfo.branch
      // this means that this is cross repo with no access or cross repo to repo removed from workspace
      remove(autosyncInput.snippets, isElementWithoutGitAccess);
      remove(autosyncInput.symbols, isElementWithoutGitAccess);

      if (await isAutosyncInputApplicable(autosyncInput, resourceId, repoId, currentBranch, config.isWebApp)) {
        unitStatus = ApplicabilityStatus.Verified;
      } else {
        // use the original input here with all the snippets
        autosyncResult = await autosyncUnit(originalAutosyncInput);
        if (autosyncResult.autosyncSuccess) {
          autosyncOutput = autosyncResult.autosyncOutput;
          /**
           * As far as we are concerned, this should never return {@link ApplicabilityStatus.Verified}.
           * _However_, we do not assume so here since we want to check if there's a discrepency between `verify` and `autosync`.
           * If there is one, we log it. Let's hope we won't see any of these errors.
           */
          const syncedSwmFileApplicability = autosyncOutput.applicability;
          if (syncedSwmFileApplicability === ApplicabilityStatus.Verified) {
            logger.error(
              `Found discrepency between verify (Outdated) and autosync (${syncedSwmFileApplicability}). Details: repo=${repoId}, branch=${currentBranch}, sha=${commitSha}`
            );
          }
          unitStatus = syncedSwmFileApplicability;
        }
      }
    }

    const isUnitStatusValid = [
      ApplicabilityStatus.Verified,
      ApplicabilityStatus.Autosyncable,
      ApplicabilityStatus.Outdated,
    ].includes(unitStatus);
    // Explicitly check for `null` since if there was a fatal error in the cache it'll be `undefined`.
    if (shouldGetFromCache && cacheHit === null && isUnitStatusValid && !!commitSha) {
      try {
        const valueToCache: DocCacheStructure = {
          meta: {
            // In this flow, we're not using the result of autosync
            autosync: false,
            // Since technically `unitStatus` can be anything
            verdict: unitStatus as
              | ApplicabilityStatus.Verified
              | ApplicabilityStatus.Autosyncable
              | ApplicabilityStatus.Outdated,
            origin: config.isWebApp ? 'webapp' : 'ci',
          },
        };
        setInCache({
          workspaceId,
          cacheId: getAutosyncChaceIdChecksum(autosyncInput, repoId, commitSha),
          objectToSet: valueToCache,
        });
      } catch (error) {
        logger.error(
          `Set value to cache (repo=${repoId},branch=${currentBranch}) failed with error: ${error.toString}`
        );
      }
    }
  } catch (err) {
    unitStatus = ApplicabilityStatus.Outdated;
    if (err.name === 'SyntaxError') {
      unitStatus = ApplicabilityStatus.Invalid;
    }
    if (err.code === 'ENOENT') {
      unitStatus = ApplicabilityStatus.Unavailable;
    }
  }
  return {
    id: resourceId,
    unitStatus: unitStatus,
    autosyncResult: autosyncResult
      ? {
          autosyncSuccess: autosyncResult.autosyncSuccess,
          autosyncOutput: autosyncOutput,
        }
      : null,
  };
}

function getAutosyncChaceIdChecksum(autosyncInput: AutosyncInput, repoId: string, commitSha: string): string {
  const snippetsIds = autosyncInput.snippets.map((snippet) => snippet.id).sort();
  const symbolsIds = autosyncInput.symbols.map((symbol) => symbol.id).sort();

  return sha256(`${JSON.stringify(snippetsIds)}${JSON.stringify(symbolsIds)}${repoId}${commitSha}`);
}
