import type { Ref } from 'vue';
import { debounce } from 'lodash-es';

export function useEditorAnimation(nodeAnimationsSet: Ref<Set<string>>) {
  const debounceResetNodeAnimationSet = debounce(() => {
    const onAnimationEnd = () => {
      nodeAnimationsSet.value.clear();
      document.removeEventListener('transitionend', onAnimationEnd);
    };
    document.addEventListener('transitionend', onAnimationEnd);
  }, 1000);

  function pushIdToNodeAnimationSet(id: string) {
    nodeAnimationsSet.value.add(id);
    debounceResetNodeAnimationSet.cancel();
    debounceResetNodeAnimationSet();
  }

  function shouldAnimateNode(id: string) {
    return nodeAnimationsSet.value.has(id);
  }

  return { pushIdToNodeAnimationSet, shouldAnimateNode };
}
