<script setup>
import FolderPath from '@/modules/folders/components/FolderPath.vue';
import { computed } from 'vue';
import { Icon, SwText } from '@swimm/ui';
import { GitProviderIcons, SwmResourceType, eventLogger, productEvents } from '@swimm/shared';
import { PageRoutesNames } from '@/common/consts';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useRoute } from 'vue-router';

const props = defineProps({
  viewedFile: { type: Object, required: true },
});
const emit = defineEmits(['select']);

const analytics = useAnalytics();
const route = useRoute();

const srcType = computed(() => props.viewedFile.srcType);
const repoName = computed(() => {
  if (props.viewedFile.repoName) {
    return props.viewedFile.repoName;
  }
  return null;
});
const repoId = computed(() => props.viewedFile.repoId);
const branch = computed(() => props.viewedFile.branch);
const srcName = computed(() => props.viewedFile.srcName);
const srcId = computed(() => props.viewedFile.srcId);
const folderId = computed(() => props.viewedFile.folderId);
const repoProviderIcon = computed(() => GitProviderIcons[props.viewedFile.repoProvider]);
const iconType = computed(() => {
  switch (srcType.value) {
    case eventLogger.EVENTS_SOURCE_TYPES.DOC:
      return SwmResourceType.Doc;
    case eventLogger.EVENTS_SOURCE_TYPES.PLAYLIST:
      return SwmResourceType.Playlist;
    default:
      return SwmResourceType.Doc;
  }
});

const link = computed(() => {
  if (srcType.value === eventLogger.EVENTS_SOURCE_TYPES.DOC) {
    return {
      name: PageRoutesNames.DOC_VIEW,
      params: { repoId: repoId.value, branch: branch.value, unitId: srcId.value },
    };
  } else if (srcType.value === eventLogger.EVENTS_SOURCE_TYPES.PLAYLIST) {
    return {
      name: PageRoutesNames.PLAYLIST_VIEW,
      params: { repoId: repoId.value, branch: branch.value, playlistId: srcId.value },
    };
  }
  return '';
});

function onRecentFileClick() {
  sendAnalytics();
  emit('select');
}

const sendAnalytics = () => {
  analytics.track(productEvents.CLICKED_RECENTLY_VIEWED_ITEM, {
    'Workspace ID': route.params.workspaceId,
    'Item Type': srcType.value,
    'Item Name': srcName.value,
    'Repo ID': repoId.value,
    Context: 'Search',
  });
};
</script>

<template>
  <!-- in old events we don't have branch, this should not happen but we protect anyway -->
  <router-link v-if="branch" @click="onRecentFileClick" :to="link">
    <div class="result">
      <Icon :name="iconType" class="type-icon" no-padding />
      <SwText variant="body-L" class="title">{{ srcName }}</SwText>
      <FolderPath
        class="repo"
        v-if="folderId"
        :folder-id="folderId"
        :repo-name="repoName"
        :repo-id="repoId"
        :repo-icon="repoProviderIcon"
      />
    </div>
  </router-link>
</template>

<style scoped lang="postcss">
.result {
  display: grid;
  grid-template-areas:
    'icon title'
    '. repo';
  grid-template-columns: auto 1fr;
  grid-gap: var(--space-base) var(--space-base);
  padding: var(--space-base) var(--space-xs);
  border-radius: 8px;

  &:hover {
    background-color: var(--color-surface);
  }

  .type-icon {
    grid-area: icon;
    font-size: var(--fontsize-s);
    color: var(--text-color-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    grid-area: title;
  }

  .repo {
    grid-area: repo;
    color: var(--text-color-secondary);
  }

  .repo-icon {
    color: var(--text-color-primary);
    font-size: var(--fontsize-sm);
  }
}
</style>
