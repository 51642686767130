<script setup lang="ts">
import { type Ref, computed, onMounted, ref, toRef } from 'vue';
import type { Editor } from '@tiptap/vue-3';

import { type SlashCommand, SwmSelectionContentSlashCommands, useGroupedItemSelectionKeyHandlers } from '@swimm/reefui';
import { useElementSize } from '@vueuse/core';

const props = defineProps<{
  editor: Editor;
  range: Range;
  query: string;
  text: string;
  items: SlashCommand[][];
  groupHeadings: string[];
  command: (props: SlashCommand) => void;
  decorationNode: Element | null;
  clientRect?: (() => DOMRect | null) | null;
}>();

const { selectedIndex, onKeyDown } = useGroupedItemSelectionKeyHandlers(toRef(props, 'items'), {
  additionalHandlers: {
    Enter: (selectedItem: SlashCommand) => {
      return executeCommand(selectedItem);
    },
  },
  groupOrientation: 'row',
});

function executeCommand(slashCommand: SlashCommand) {
  if (slashCommand && slashCommand.command) {
    props.command(slashCommand);

    return true;
  }

  return false;
}

// hide shortcuts based on swmd width
const swmdEditorElement: Ref<HTMLElement | null> = ref(null);

onMounted(() => {
  swmdEditorElement.value = props.editor.view.dom as HTMLElement;
});

const { width: swmdEditorWidth } = useElementSize(swmdEditorElement);

const hideShortcuts = computed(() => swmdEditorWidth.value <= 750);

defineExpose({ onKeyDown });
</script>

<template>
  <!-- render a fixed div: this renders within tippy which requires at least 1 rendered component -->
  <div>
    <!-- Don't show content when no available results -->
    <SwmSelectionContentSlashCommands
      v-if="items.length"
      data-testid="slash-commands-menu"
      :group-headings="groupHeadings"
      :items="items"
      :selected-index="selectedIndex"
      :hide-shortcuts="hideShortcuts"
      @click="executeCommand"
    />
  </div>
</template>
