<template>
  <div class="plans">
    <StiggPlansPage
      :workspace-id="workspaceId"
      :workspace-license="workspaceLicense"
      :is-in-trial="isInTrial"
      @plan-selected="onPlanSelected"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineAsyncComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { LICENSES_SUPPORTING_SELF_SERVE_UPGRADE, productSKUs } from '@swimm/shared';
import swal from 'sweetalert';

const StiggPlansPage = defineAsyncComponent(() => import('@/modules/billing/components/StiggPlansPage.vue'));

export default {
  components: { StiggPlansPage },
  props: {
    workspaceId: { type: String, required: true },
    workspaceLicense: { type: String, required: true },
    isInTrial: { type: Boolean, required: true },
  },
  setup() {
    const { user } = storeToRefs(useAuthStore());

    return { user };
  },
  emits: ['plan-click'],
  computed: {
    ...mapGetters('database', ['db_getWorkspace']),
  },
  methods: {
    onPlanSelected({ billableFeatures, customer, intentionType, plan, selectedBillingPeriod }) {
      const productSKU = plan.id.replace('plan-swimm-', '');

      let selectedQuantity = 1;
      if (productSKU === productSKUs.SMALL_TEAM) {
        selectedQuantity = billableFeatures?.[0].quantity ?? plan?.pricePoints?.[0]?.tiers?.[0]?.upTo ?? 1;

        if (this.workspaceLicense === productSKUs.STANDARD || this.workspaceLicense === productSKUs.PRO) {
          const workspace = this.db_getWorkspace(this.workspaceId);
          if (workspace.counter_workspace_users > selectedQuantity) {
            swal({
              title: `You have ${workspace.counter_workspace_users} users in your workspace - please select a seat count higher than that amount.`,
            });
            return;
          }
        }
      }

      this.$emit('plan-click', {
        selectedBillingPeriod,
        productSKU,
        selectedQuantity,
        isUpgrade: intentionType === 'UPGRADE_PLAN' && customer.hasPaymentMethod,
        isTrial: intentionType === 'START_TRIAL',
        isDowngrade: intentionType === 'DOWNGRADE_PLAN',
        isPeriodChange: intentionType === 'CHANGE_BILLING_PERIOD',
        isEnterprise: intentionType === 'REQUEST_CUSTOM_PLAN_ACCESS',
        allowUpgrade: LICENSES_SUPPORTING_SELF_SERVE_UPGRADE.includes(productSKU),
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.plans {
  min-height: 480px;
}
</style>
