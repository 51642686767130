<template>
  <SwModal :show-modal="show" :padded="false" heading="Assign doc to teammates" @close="close">
    <div class="assign-modal" data-testid="assign-modal">
      <div class="assign-explanation">Ask your teammates to review or update this doc, or just share as an FYI.</div>
      <MultipleWorkspaceUsersDropdown
        label="Assign to user(s)"
        required
        @input="onUsersSelected"
        placeholder="Select users or enter emails of assignees"
        data-testid="assign-user-selector"
      />
      <div class="assign-option-selection">
        <BaseSelect
          :model-value="category"
          label="Reason"
          placeholder="FYI / Update / Review / Other"
          data-testid="assign-select-reason"
          :append-to-body="false"
          required
          :options="categoryOptions"
          :searchable="false"
          @input="onCategoryChange"
        />
      </div>
      <div class="assign-notes-selection">
        <TextField
          placeholder="Share any additional notes here"
          label="Notes"
          data-testid="assign-note"
          :required="requireNote"
          :model-value="notes"
          @update:model-value="onNotesChanged"
        />
      </div>
      <div class="assign-button">
        <Action
          data-testid="assign-submit"
          :loading="isCreatingAssignment"
          :disabled="submitDisabled"
          @click="createAssignment"
        >
          Assign
        </Action>
      </div>
    </div>
  </SwModal>
</template>

<script>
import { useAppLinks } from '@/modules/core/compositions/app-links';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { getLoggerNew } from '@swimm/shared';
import { productEvents } from '@swimm/shared';
const logger = getLoggerNew(__modulename);
import { mapActions, mapGetters } from 'vuex';
import {
  createAssignmentNotification,
  createDocAssignment,
} from '@/modules/doc-assignments/services/doc-assignments-utils';
import * as firestore from '@/adapters-common/firestore-wrapper';
import { UNKNOWN_USER_NAME } from '@/common/consts';
import { eventLogger } from '@swimm/shared';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { BaseSelect, SwModal, TextField } from '@swimm/ui';
import { useRouteData } from '@/common/composables/useRouteData';
import MultipleWorkspaceUsersDropdown from '@/common/components/organisms/MultipleWorkspaceUsersDropdown.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';

export default {
  components: {
    SwModal,
    TextField,
    MultipleWorkspaceUsersDropdown,
    BaseSelect,
  },
  props: {
    show: { type: Boolean, required: true },
    repoId: {
      type: String,
      required: true,
    },
    docId: {
      type: String,
      required: true,
    },
    branch: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const { workspaceId } = useRouteData();
    const { logEvent } = useSwimmEventLogs();
    const { getWebAppLink } = useAppLinks();

    return { user, analytics, logEvent, workspaceId, getWebAppLink };
  },
  data() {
    const categoryOptions = ['FYI', 'Needs updating', 'Needs your review', 'Other'];
    return {
      categoryOptions,
      category: '',
      notes: '',
      selectedUsersEmail: [],
      isCreatingAssignment: false,
    };
  },
  computed: {
    ...mapGetters('database', ['db_getWorkspaceUsers', 'db_getSwimm']),
    workspaceUsers() {
      const users = Object.values(this.db_getWorkspaceUsers(this.workspaceId));
      return users
        .filter((user) => !!user.email)
        .map((user) => ({
          label: user.nickname || user.name || user.email || UNKNOWN_USER_NAME,
          name: user.nickname || user.name || user.email || UNKNOWN_USER_NAME,
          email: user.email,
          uid: user.uid,
        }));
    },
    requireNote() {
      return this.category === 'Other';
    },
    submitDisabled() {
      return this.selectedUsersEmail.length === 0 || !this.category || (this.category === 'Other' && !this.notes);
    },
    swimmFromDB() {
      return this.db_getSwimm(this.repoId, this.docId);
    },
  },
  async mounted() {
    const workspaceId = this.$route.params.workspaceId;
    await this.refreshWorkspaceUsersAndInvites({ workspaceId });
  },
  methods: {
    ...mapActions('database', ['refreshAssignments', 'refreshWorkspaceUsersAndInvites']),
    close() {
      this.$emit('close');
    },
    onCategoryChange(value) {
      this.category = value;
    },
    async createAssignment() {
      if (this.isCreatingAssignment) {
        return;
      }
      this.isCreatingAssignment = true;
      const workspaceId = this.$route.params.workspaceId;
      const repoId = this.repoId;
      const docId = this.docId;
      const branch = this.branch;

      for (const userEmail of this.selectedUsersEmail) {
        const workspaceUser = this.workspaceUsers.find((user) => user.email === userEmail);
        try {
          const assignment = {
            branch,
            assigned_by: this.user.uid,
            assigned_by_email: this.user.email,
            assigned_by_name: this.user.nickname,
            assignee: (workspaceUser && workspaceUser.uid) || '',
            assignee_email: userEmail,
            assignee_name: (workspaceUser && workspaceUser.name) || '',
            description: this.notes,
            type: this.category,
            completed: false,
            created: firestore.firestoreTimestamp(),
          };
          await createDocAssignment({ repoId, docId, assignment });

          const docBaseUrl = this.getWebAppLink(
            `/workspaces/${workspaceId}/repos/${repoId}/branch/${branch}/docs/${docId}`
          );
          const docUrl = assignment.type === 'Needs updating' ? docBaseUrl + '/edit' : docBaseUrl;
          const docTitle = this.swimmFromDB.name;
          await createAssignmentNotification({ assignment, docId, docTitle, docUrl, workspaceId });
          await this.refreshAssignments({ repoId, unitId: docId });

          this.analytics.track(productEvents.DOCUMENT_ASSIGNED_TO_TEAMMATE, {
            Origin: this.$route.path.endsWith('edit') ? 'Edit Document' : 'View Document',
            Context: 'Assign Doc Popup',
            Assignee: userEmail,
            Reason: this.category,
            Notes: this.notes,
            'Workspace ID': this.$route.params.workspaceId,
            'Repo ID': this.repoId,
            'Document ID': this.docId,
          });
          this.logEvent(eventLogger.SWIMM_EVENTS.DOC_ASSIGNED, {
            srcId: docId,
            srcName: this.swimmFromDB?.name || '',
            srcType: this.category,
            targetName: workspaceUser?.name || `assignee-email-${userEmail}`,
            targetId: workspaceUser?.uid || `assignee-email-${userEmail}`,
            value: userEmail,
          });
        } catch (err) {
          logger.error({ err }, `Could create assignment. Details: ${err}`);
        }
      }
      this.close();
      this.isCreatingAssignment = false;
    },
    onNotesChanged(notes) {
      this.notes = notes;
    },
    onUsersSelected(selectedUsers) {
      this.selectedUsersEmail = selectedUsers.map((user) => (user.email ? user.email : user.name));
    },
  },
};
</script>
<style scoped>
.assign-modal {
  margin: 0px 24px;
  width: 560px;
}

.assign-explanation {
  margin: 24px 0px;
}

.assign-option-selection {
  margin: 16px 0px;
}

.assign-button {
  display: flex;
  justify-content: flex-end;
  margin: 24px 0px;
}
</style>
