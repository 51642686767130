<script setup lang="ts">
import { computed, toRef } from 'vue';
import type { GroupedSelectedIndex, SlashCommand } from '../../types/transitional';

import BaseBlock from '../../components/BaseBlock/BaseBlock.vue';
import BaseBadge from '../../components/BaseBadge/BaseBadge.vue';
import BaseKeyCommand from '../../components/BaseKeyCommand/BaseKeyCommand.vue';
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';
import BaseProse from '../../components/BaseProse/BaseProse.vue';

import Menu from '../Menu/Menu.vue';
import MenuItem from '../MenuItem/MenuItem.vue';
import MenuHeading from '../MenuHeading/MenuHeading.vue';
import SwmSelectionFooter from '../SwmSelectionFooter/SwmSelectionFooter.vue';

import { useGroupedItemSelectionScrollHandler } from '../../composables/useGroupedItemSelectionScrollHandler';
import { BaseTooltip } from '../..';

const props = withDefaults(
  defineProps<{
    selectedIndex: GroupedSelectedIndex;
    groupHeadings: string[];
    items: SlashCommand[][];
    hideShortcuts?: boolean;
  }>(),
  {
    selectedIndex: () => [0, 0],
  }
);

const emit = defineEmits<{
  click: [SlashCommand];
}>();

const { itemRefs, scrollContainerRef, setItemRef } = useGroupedItemSelectionScrollHandler(
  toRef(props, 'selectedIndex')
);

const focusedItemDescription = computed(() => {
  if (!props.items.length) {
    return;
  }

  return (
    props.items[props.selectedIndex[0]][props.selectedIndex[1]] &&
    props.items[props.selectedIndex[0]][props.selectedIndex[1]].description
  );
});

function isFocused(itemIndex: number, groupIndex = 0) {
  const selectedGroupIndex = props.selectedIndex[0] || 0;
  const selectedItemIndex = props.selectedIndex[1] || 0;

  return groupIndex === selectedGroupIndex && itemIndex === selectedItemIndex;
}

function onClick(slashCommand: SlashCommand) {
  emit('click', slashCommand);
}

defineExpose({
  itemRefs,
});
</script>

<template>
  <BaseBlock
    class="swm-selection-content-slash-commands"
    :style="`--columns: ${items.length || 1}`"
    padding="none"
    shadow="medium"
  >
    <div ref="scrollContainerRef" class="swm-selection-content-slash-commands__wrapper">
      <Menu
        v-for="(group, groupIndex) in items"
        :key="groupHeadings[groupIndex]"
        class="swm-selection-content-slash-commands__column"
      >
        <MenuHeading>{{ groupHeadings[groupIndex] }}</MenuHeading>

        <MenuItem
          v-for="(item, itemIndex) in group"
          :ref="(el: any) => el && el.root && setItemRef(el.root, groupIndex, itemIndex)"
          :key="item.name"
          :focused="isFocused(itemIndex, groupIndex)"
          @click="onClick(item)"
        >
          <template #leftIcon>
            <BaseIcon :name="item.icon" />
          </template>
          <template v-if="hideShortcuts">
            <BaseTooltip>
              <template v-if="item.keyCommands || item.badge" #content>
                <BaseBadge v-if="item.badge" v-bind="item.badge" />
                <BaseKeyCommand v-if="item.keyCommands" :keys="item.keyCommands" />
              </template>
              {{ item.title }}
            </BaseTooltip>
          </template>
          <template v-else>
            {{ item.title }}
          </template>
          <template v-if="!hideShortcuts && (item.keyCommands || item.badge)" #additional>
            <BaseBadge v-if="item.badge" v-bind="item.badge" />
            <BaseKeyCommand v-if="item.keyCommands" :keys="item.keyCommands" />
          </template>
        </MenuItem>
      </Menu>
    </div>
    <SwmSelectionFooter>
      <BaseProse variant="secondary" size="small">{{ focusedItemDescription }}</BaseProse>
    </SwmSelectionFooter>
  </BaseBlock>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-selection-content-slash-commands {
  $self: &;

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
    grid-column-gap: 0;
    grid-row-gap: 0;
    overflow: auto;
  }

  /* eslint-disable vue-scoped-css/no-unused-selector */
  .icon.icon-magic {
    color: var(--text-color-magic-strong);
  }
}
</style>
