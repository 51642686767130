<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  text: string;
  filterText: string;
}>();

const filteredText = computed(() => {
  const filterTextLength = props.filterText.trim().length;
  const titlePartsWithoutFilterText = props.text.toLowerCase().split(props.filterText.trim().toLowerCase());

  let currentIndex = 0;
  return titlePartsWithoutFilterText.reduce((accParts, currentPart, index) => {
    const originalTextPart = props.text.slice(currentIndex, currentIndex + currentPart.length);
    if (index === titlePartsWithoutFilterText.length - 1) {
      return [...accParts, originalTextPart];
    }

    currentIndex += currentPart.length;
    const originalFilterTextPart = props.text.slice(currentIndex, currentIndex + filterTextLength);
    currentIndex += filterTextLength;

    return [...accParts, originalTextPart, originalFilterTextPart];
  }, []);
});
</script>

<template>
  <div>
    <span v-for="(titlePart, index) in filteredText" :key="index" :class="['text', { 'marked-text': index % 2 === 1 }]">
      {{ titlePart }}
    </span>
  </div>
</template>

<style scoped lang="postcss">
.text {
  font-size: var(--fontsize-xs);
}

.marked-text {
  font-weight: bold;
  color: var(--text-color-primary);
  background: var(--color-status-info);
}
</style>
