import {
  addDocToSubCollection,
  collectionNames,
  firestoreIncrement,
  firestoreTimestamp,
  firestoreTimestampInSeconds,
  getDocRefFromSubCollection,
  getDocsRefFromSubCollectionWithWhereClauses,
  updateDocInSubCollection,
} from '@/adapters-common/firestore-wrapper';
import { type CloudDocData, type User, config, getLoggerNew } from '@swimm/shared';

export const FAILED_TO_SAVE_NON_RECENT_VERSION = 'Failed to save non recent version';

const logger = getLoggerNew(__modulename);

export async function cloudDocExists(workspaceId: string, cloudDocId: string): Promise<boolean> {
  const result = await getDocRefFromSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.CLOUD_DOCS,
    cloudDocId
  );
  if (result.code !== config.SUCCESS_RETURN_CODE) {
    throw new Error(
      `Failed to check whether cloud doc exists, workspaceId: ${workspaceId}, cloudDocId: ${cloudDocId}, error: ${result.errorMessage}`
    );
  }
  return result.data.exists;
}

export async function getSharedDocs({ workspaceId }): Promise<Record<string, CloudDocData>> {
  try {
    const querySnapshot = await getDocsRefFromSubCollectionWithWhereClauses(
      collectionNames.WORKSPACES,
      workspaceId,
      collectionNames.CLOUD_DOCS,
      [
        ['is_shared_doc', '==', true],
        ['deleted', '==', false],
      ]
    );
    const docs = {};
    querySnapshot.forEach((docRef) => {
      docs[docRef.id] = { id: docRef.id, ...docRef.data() };
    });
    return docs;
  } catch (err) {
    logger.error({ err }, `Failed in getSharedDocs workspaceId=${workspaceId}: ${err}`);
    return {};
  }
}

// swmd-v3
export async function createSharedDoc({
  workspaceId,
  user,
  swmd,
  autosyncOutput,
  docTitle,
  branch,
  docId,
  repoId,
}: {
  workspaceId: string;
  user: User;
  swmd: string;
  autosyncOutput: string;
  docTitle: string;
  branch: string;
  docId: string;
  repoId: string;
}): Promise<string> {
  const userName = user.displayName || user.nickname || user.email || 'Unknown user';
  const data: CloudDocData = {
    is_cloud_doc: false,
    is_shared_doc: true,
    created: firestoreTimestamp(),
    creator: user.uid,
    creator_name: userName,
    repos: [],
    visibility: 'public',
    modified: firestoreTimestamp(),
    modifier: user.uid,
    modifier_name: userName,
    name: docTitle,
    version: 0,
    views: 0,
    deleted: false,
    is_empty: false,
    swmd,
    autosync_output: autosyncOutput,
    export_info: {
      original_repo_id: repoId,
      original_branch: branch,
      original_doc_id: docId,
    },
  };
  const response = await addDocToSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.CLOUD_DOCS,
    data
  );
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    throw new Error(`Failed to create shared doc in workspace ${workspaceId}: ${response.errorMessage}`);
  }
  logger.info(`Created shared doc workspaceId=${workspaceId} id=${response.data.id}`);
  return response.data.id;
}

// swmd-v3
export async function updateSharedDoc({
  sharedDocId,
  workspaceId,
  user,
  swmd,
  autosyncOutput,
  docTitle,
  branch,
  repoId,
  docId,
}: {
  sharedDocId: string;
  workspaceId: string;
  user: User;
  swmd: string;
  autosyncOutput: string;
  docTitle: string;
  branch: string;
  docId: string;
  repoId: string;
}): Promise<void> {
  const userName = user.displayName || user.nickname || user.email || 'Unknown user';
  const dataToUpdate: Partial<CloudDocData> = {
    modified: firestoreTimestamp(),
    modifier: user.uid,
    modifier_name: userName,
    name: docTitle,
    version: firestoreIncrement(1),
    swmd,
    autosync_output: autosyncOutput,
    export_info: {
      original_branch: branch,
      original_repo_id: repoId,
      original_doc_id: docId,
    },
  };
  const response = await updateDocInSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.CLOUD_DOCS,
    sharedDocId,
    dataToUpdate
  );
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    throw new Error(`Failed to update shared doc ${sharedDocId} in workspace ${workspaceId}: ${response.errorMessage}`);
  }
}

export async function deleteAllSharedDocsInRepo({ workspaceId, repoId }: { workspaceId: string; repoId: string }) {
  const querySnapshot = await getDocsRefFromSubCollectionWithWhereClauses(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.CLOUD_DOCS,
    [
      ['is_shared_doc', '==', true],
      ['deleted', '==', false],
      ['export_info.original_repo_id', '==', repoId],
    ]
  );

  const promiseArr = [];
  querySnapshot.forEach((docRef) => {
    promiseArr.push(docRef.ref.update({ ...getDeletionFields() }));
    const sharedDoc = docRef.data();
    promiseArr.push(
      updateDocInSubCollection(
        collectionNames.REPOSITORIES,
        repoId,
        collectionNames.SWIMMS,
        sharedDoc.export_info.original_doc_id,
        { exported_cloud_doc_id: null }
      )
    );
  });

  await Promise.allSettled(promiseArr);
}

export async function getSharedDocByOriginalDocId({
  workspaceId,
  repoId,
  docId,
}: {
  workspaceId: string;
  repoId: string;
  docId: string;
}) {
  const querySnapshot = await getDocsRefFromSubCollectionWithWhereClauses(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.CLOUD_DOCS,
    [
      ['is_shared_doc', '==', true],
      ['deleted', '==', false],
      ['export_info.original_repo_id', '==', repoId],
      ['export_info.original_doc_id', '==', docId],
    ]
  );

  if (querySnapshot.size === 1) {
    return querySnapshot.docs[0].id;
  }

  return null;
}

export async function deleteSharedDoc({
  workspaceId,
  repoId,
  sharedDocId,
  originalDocId,
}: {
  workspaceId: string;
  repoId: string;
  sharedDocId: string;
  originalDocId: string;
}) {
  await Promise.allSettled([
    updateDocInSubCollection(collectionNames.WORKSPACES, workspaceId, collectionNames.CLOUD_DOCS, sharedDocId, {
      ...getDeletionFields(),
    }).then((result) => {
      if (result.code !== config.SUCCESS_RETURN_CODE) {
        logger.warn(
          `Got error updating shared doc ${sharedDocId} in workspace ${workspaceId}. Error: ${result.errorMessage}`
        );
      }
    }),
    updateDocInSubCollection(collectionNames.REPOSITORIES, repoId, collectionNames.SWIMMS, originalDocId, {
      exported_cloud_doc_id: null,
    }).then((result) => {
      if (result.code !== config.SUCCESS_RETURN_CODE) {
        logger.warn(`Got error updating doc ${originalDocId} in repo ${repoId}. Error: ${result.errorMessage}`);
      }
    }),
  ]);
}

function getDeletionFields() {
  return { deleted: true, expired_at: firestoreTimestampInSeconds(30 * 24 * 3600) };
}
