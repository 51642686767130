<script setup lang="ts">
import { computed } from 'vue';
import { type Spacing } from '../../types';

const props = withDefaults(
  defineProps<{
    size?: Spacing;
  }>(),
  {
    size: undefined,
  }
);

const computedClasses = computed(() => ({
  [`divider--${props.size}`]: !!props.size,
}));
</script>

<template>
  <hr class="divider" :class="computedClasses" />
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.divider {
  @include defaults;

  background: none; /* Override global background-color applied to hr elements. */
  height: 1px;

  &::after {
    background-color: var(--color-border-default-subtle);
    display: block;
    height: 1px;
    content: '';
    width: 100%;
  }

  &--xxsmall {
    padding: var(--space-xxsmall) 0;
  }

  &--xsmall {
    padding: var(--space-xsmall) 0;
  }

  &--small {
    padding: var(--space-small) 0;
  }

  &--mediun {
    padding: var(--space-medium) 0;
  }

  &--large {
    padding: var(--space-large) 0;
  }

  &--xlarge {
    padding: var(--space-xlarge) 0;
  }
}
</style>
