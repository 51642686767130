import type {
  DynamicChangeLine,
  DynamicHunkContainer,
  SmartElement,
  SmartElementWithApplicability,
  Snippet,
} from '@swimm/shared';
import { ApplicabilityStatus, getLogger } from '@swimm/shared';
import { diffWords } from 'diff';

const logger = getLogger("packages/swimmagic/src/swimmagic-common.ts");

export function findChangeLineIndexByActualLineNumber(
  diffLineChanges: DynamicChangeLine[],
  lineNumberInFileA: number
): number {
  const hasTheRightLineNumber = (diffLine: DynamicChangeLine) =>
    diffLine.fileA && diffLine.fileA.actualLineNumber === lineNumberInFileA;
  return diffLineChanges.findIndex(hasTheRightLineNumber);
}

// `diffWords` gets stuck for strings with a lot of words (like minified file) so we limit the string length.
const MAX_STRING_SIZE = 5000;

/**
 * Computes the "similarity" between two strings
 * Roughly, the similarity is how many "words" are common between the strings
 * @param {str} firstString
 * @param {str} secondString
 */
export function getWordSimilarity(firstString: string, secondString: string) {
  let result = 0;
  try {
    if (firstString.length > MAX_STRING_SIZE || secondString.length > MAX_STRING_SIZE) {
      return 0;
    }
    const ANY_ALPHANUMERIC_REGEX = /^.*[0-9A-Z]+.*/i;
    const changeObjects = diffWords(firstString, secondString);
    for (const changeObject of changeObjects) {
      if (!changeObject.added && !changeObject.removed && changeObject.value !== ' ') {
        // A part of this line overlaps
        result += changeObject.value.split(' ').filter((x: string) => ANY_ALPHANUMERIC_REGEX.test(x)).length;
      }
    }
  } catch (e) {
    // We want to ignore all errors here and just return the result
    logger.error(`Error ${e.toString()} while getting word similarity.`);
  }
  return result;
}

export function setHunkContainerApplicability(
  hunkContainer: DynamicHunkContainer,
  applicabilityStatus: ApplicabilityStatus
) {
  if (typeof hunkContainer.swimmHunkMetadata === 'undefined') {
    hunkContainer.swimmHunkMetadata = {};
  }
  hunkContainer.swimmHunkMetadata.applicability = applicabilityStatus;
}

export function getSnippetById({ snippets, id }: { snippets: Snippet[]; id: string }): Snippet {
  return snippets.filter((snippet) => snippet.id === id)?.[0] ?? null;
}

export function getAllSnippetsInFile({
  snippets,
  repoId,
  filePath,
}: {
  snippets: Snippet[];
  repoId: string;
  filePath: string;
}): Snippet[] {
  return snippets.filter((snippet) => snippet.gitInfo.repoId === repoId && snippet.filePath === filePath);
}

export function getSnippetsInFileWithApplicabilityStatus({
  snippets,
  repoId,
  filePath,
  applicabilityStatus,
  newInfo,
}: {
  snippets: Snippet[];
  repoId: string;
  filePath: string;
  applicabilityStatus: ApplicabilityStatus;
  newInfo?: Snippet;
}): SmartElementWithApplicability<Snippet>[] {
  const snippetsWithApplicability: SmartElementWithApplicability<Snippet>[] = [];
  const snippetsInFile = getAllSnippetsInFile({ snippets, repoId, filePath });

  for (const snippet of snippetsInFile) {
    snippetsWithApplicability.push(
      getSmartElementWithApplicability<Snippet>({ element: snippet, applicabilityStatus, newInfo })
    );
  }

  return snippetsWithApplicability;
}

export function getSmartElementWithApplicability<T extends SmartElement>({
  element,
  applicabilityStatus,
  newInfo,
}: {
  element: T;
  applicabilityStatus: ApplicabilityStatus;
  newInfo?: T;
}): SmartElementWithApplicability<T> {
  // @ts-ignore Conditional types don't really work in TS
  return {
    ...element,
    applicability: applicabilityStatus,
    ...(newInfo ? { newInfo } : {}),
  };
}

export function isElementWithoutGitAccess(element: SmartElement): boolean {
  // if no gitInfo
  return element.gitInfo == null;
}
