import swal from 'sweetalert';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import { isAutomation, isDevelopment } from '@/config';
import { getLoggerNew } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

const showInternalErrors = isDevelopment || isAutomation;

/**
 * Show an alert message to the user when an unexpected error is caught. On development and staging, the error is
 * shown to the user, who is assumed to be a Swimm employee. On production, the error is hidden from the user, and
 * instead a "contact us" message appears.
 * @param title Alert modal title
 * @param error Error text to display (hidden in production environment)
 */
export async function exceptionAlert({
  title,
  error,
  logError = false,
}: {
  title: string;
  error: string | Error;
  logError?: boolean;
}) {
  if (logError) {
    logger.error(`Showing user alert due to exception: ${error}`, {
      error: error instanceof Error ? error : undefined,
    });
  }
  await swal({
    title,
    content: showInternalErrors ? undefined : { element: SWAL_CONTACT_US_CONTENT() },
    text: showInternalErrors ? `${error}` : undefined,
  });
}
