import { defineStore } from 'pinia';
import { STORES } from '@/modules/core/stores/store-constants';
import { isRepoIdDummyRepo } from '@swimm/shared';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useDemoStore = defineStore(STORES.DEMO, () => {
  const route = useRoute();
  const repoId = computed(() => route.params.repoId);

  const isOnDummyRepoPage = computed(() => isRepoIdDummyRepo(repoId.value as string));

  return {
    isOnDummyRepoPage,
  };
});
