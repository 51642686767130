import { config, eventLogger } from '@swimm/shared';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from 'vue';

export function useLogBatchSaveEvent() {
  const store = useStore();
  const { logEvent } = useSwimmEventLogs();
  const route = useRoute();
  const repoId = computed(() => route.params.repoId);

  const db_getRepoMetadata = computed(() => store.getters['database/db_getRepoMetadata'](repoId.value));

  async function logBatchSaveEvent(
    savedSwimms: {
      id: string;
      name?: string;
      title?: string;
      type: (typeof config.SWIMM_FILE_TYPES)[keyof typeof config.SWIMM_FILE_TYPES];
      isNew?: boolean;
    }[],
    prBranch?: string
  ) {
    let eventCode: eventLogger.SwimmEvent;
    await Promise.all(
      savedSwimms.map((swimm) => {
        eventCode = getLogEvent(swimm.type, swimm.isNew);
        const event: {
          repoId: string;
          repoName: string;
          srcId: string;
          srcName: string;
          branch?: string;
        } = {
          repoId: repoId.value as string,
          repoName: db_getRepoMetadata.value.name || '',
          srcId: swimm.id,
          srcName: swimm.name || swimm.title,
        };
        if (prBranch) {
          event.branch = prBranch;
        }
        return logEvent(eventCode, event);
      })
    );
  }

  function getLogEvent(swimmType, isNew) {
    if (swimmType !== 'playlist') {
      return isNew ? eventLogger.SWIMM_EVENTS.DOC_CREATED : eventLogger.SWIMM_EVENTS.DOC_UPDATED;
    } else {
      return isNew ? eventLogger.SWIMM_EVENTS.PLAYLIST_CREATED : eventLogger.SWIMM_EVENTS.PLAYLIST_UPDATED;
    }
  }

  return { logBatchSaveEvent };
}
