import { productEvents } from '@swimm/shared';

export function copyToClipboardImpl(str: string) {
  const el = document.createElement('textarea'); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selection = document?.getSelection();
  if (selection === null) {
    return;
  }
  const selected =
    selection.rangeCount > 0 // Check if there is any content selected previously
      ? document?.getSelection()?.getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document?.getSelection()?.removeAllRanges(); // Unselect everything on the HTML document
    document?.getSelection()?.addRange(selected); // Restore the original selection
  }
}

export function SWAL_CONTACT_US_CONTENT(): HTMLDivElement {
  const el = document.createElement('div');
  el.innerHTML = "Refer to our <a target='_blank' href='https://docs.swimm.io/faq'>FAQs</a> for further help";
  return el;
}

export async function asyncSleep(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function reportSmartItemFixed(
  reportCallback: (
    event: string,
    payload: { ['Document ID']: string; Type: string; Context: string; Action: string }
  ) => void,
  { docId, type, context, action }: { docId: string; type: string; context: string; action: string }
) {
  reportCallback(productEvents.FIXED_NEEDS_REVIEW_ITEM, {
    'Document ID': docId,
    Type: type,
    Context: context,
    Action: action,
  });
}

export function reportSmartTokenClicked(
  reportCallback: (
    event: string,
    payload: {
      ['Workspace ID']: string;
      ['Repo ID']: string;
      ['Document ID']: string;
      Status: string;
      Context: string;
      ['Not GitHub Authorized']: boolean;
    }
  ) => void,
  {
    workspaceId,
    repoId,
    docId,
    status,
    context,
    isAuthorized,
  }: { workspaceId: string; repoId: string; docId: string; status: string; context: string; isAuthorized: boolean }
) {
  reportCallback(productEvents.CLICKED_SMART_TOKEN, {
    'Workspace ID': workspaceId,
    'Repo ID': repoId,
    'Document ID': docId,
    Status: status,
    Context: context,
    'Not GitHub Authorized': !isAuthorized,
  });
}

export function reportAuthorizeClicked(
  reportCallback: (
    event: string,
    payload: {
      ['Workspace ID']: string;
      ['Shared Doc ID']: string;
      Status: string;
      Context: string;
    }
  ) => void,
  { workspaceId, sharedDocId, status }: { workspaceId: string; sharedDocId: string; status: string }
) {
  reportCallback(productEvents.CLICKED_EDIT_DOC_AUTHORIZE_IN_SHARED_DOC, {
    'Workspace ID': workspaceId,
    'Shared Doc ID': sharedDocId,
    Status: status,
    Context: 'Shared Docs',
  });
}

export function handleScrollThreshold(event: DragEvent) {
  const scrollDiff = 150;
  const container: HTMLElement | null = document.querySelector('.form-content');
  if (container !== null) {
    const containerHeight = container.getBoundingClientRect().height;

    const threshold = containerHeight / 10;

    // Offset the top banner which is not a part of the container
    if (event.clientY <= threshold + 70) {
      container.scrollBy({ top: -1 * scrollDiff, behavior: 'smooth' });
    } else if (event.clientY > containerHeight - threshold) {
      container.scrollBy({ top: scrollDiff, behavior: 'smooth' });
    }
  }
}
