import * as config from '../config';
import { SwmResourceFile, SwmResourceState } from '../types';
import { b64encodeString, removePrefix } from '../utils/string-utils';
import { PendingPR } from './gitdrivers/git-provider-base';

export interface ChangeRequestData {
  files: {
    additionsInFile: number;
    changeType: string;
    path: string;
    additions: number;
    deletions: number;
  }[];
  sourceBranchName: string;
  prId: number;
  prTitle: string;
  createdAt: string;
  updatedAt: string;
}

export function changeRequestsPendingDocsReducer(changeRequests: ChangeRequestData[]): Record<string, PendingPR[]> {
  return changeRequests.reduce((agg, currentChangeRequest) => {
    if (!currentChangeRequest.files) {
      return agg;
    }
    const files = currentChangeRequest.files.filter(
      (file) => file.path.lastIndexOf(config.SWM_FILE_EXTENSION) > 0 || file.path.includes(config.SWMD_FILE_EXTENSION)
    );

    files.forEach((file) => {
      const unitId = file.path
        .split(config.SWMD_PLAYLIST_EXTENSION)
        .join('')
        .split(config.SWMD_FILE_EXTENSION)
        .join('')
        .split(config.SWM_RULE_EXTENSION)
        .join('')
        .split(config.SWM_FILE_EXTENSION)
        .join('')
        .split('/')
        .join('');
      agg[unitId] = agg[unitId] ? agg[unitId] : [];

      // in gitlab we don't get a changeType so we assume a doc with only additions is a new file
      const isAdded = file.changeType ? file.changeType === 'ADDED' : file.additionsInFile > 0 && file.deletions === 0;
      agg[unitId].push({
        path: file.path,
        created: currentChangeRequest.createdAt,
        modified: currentChangeRequest.updatedAt,
        branch: currentChangeRequest.sourceBranchName,
        pr: currentChangeRequest.prId,
        prTitle: currentChangeRequest.prTitle,
        isAdded: isAdded,
        optionallyRemoved: file.additionsInFile === 0 && !!file.deletions && file.deletions > 0,
        ...(file.changeType === 'DELETED' && { isDeleted: true }),
      });
    });

    return agg;
  }, {} as Record<string, PendingPR[]>);
}

/*
  GITHUB only!
  converts array of SwmResourceFile to object with additions and deletions to be used by GitHub GraphQL
  */
export function swmResourceFileToGitHubFileChanges(files: SwmResourceFile[]): {
  additions: { path: string; contents: string }[];
  deletions: { path: string }[];
} {
  //  we sort the files, such the renames are first
  //  so for each rename, the addition entry and the deletion entry
  //  are on the same index, hopefully it will have in the "rename resolution" of GH
  const sortedFilesByState = [...files];
  sortedFilesByState.sort((f1, f2) => {
    const stateToOrder = (state: SwmResourceState): number => {
      switch (state) {
        case SwmResourceState.Renamed:
          return 1;
        case SwmResourceState.Updated:
          return 2;
        case SwmResourceState.Created:
          return 3;
        default:
          return 4;
      }
    };
    return stateToOrder(f1.state) - stateToOrder(f2.state);
  });
  const additions = [];
  const deletions = [];
  for (const file of sortedFilesByState) {
    const fileState = file.state;
    switch (fileState) {
      case SwmResourceState.Renamed:
        deletions.push({
          path: file.oldPath,
        });
        additions.push({
          path: file.path,
          contents: file.isBase64Encoded ? file.content : b64encodeString(file.content),
        });
        break;
      case SwmResourceState.Updated:
      case SwmResourceState.Created:
        additions.push({
          path: file.path,
          contents: file.isBase64Encoded ? file.content : b64encodeString(file.content),
        });
        break;
      case SwmResourceState.Deleted:
        deletions.push({
          path: file.path,
        });
        break;

      default: {
        const _exhaustiveCheck: never = fileState;
      }
    }
  }
  return {
    additions,
    deletions,
  };
}

// return true if this is path to swm image
export function isSwmImageInRepo(filepath: string): boolean {
  const pathNoSlash = removePrefix(filepath, '/');
  const prefix = `${config.SWM_FOLDER_IN_REPO}/${config.SWM_IMAGES_FOLDER_NAME}`;
  return pathNoSlash.startsWith(prefix);
}
