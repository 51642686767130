<script setup lang="ts">
import { type ComputedRef, computed, onMounted } from 'vue';
import { SwText } from '@swimm/ui';
import { gitwrapper, productEvents } from '@swimm/shared';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useRoute } from 'vue-router';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { storeToRefs } from 'pinia';

interface Description {
  header: string;
  description?: string;
}
const props = withDefaults(
  defineProps<{
    repoName?: string;
    repoId?: string;
    code?: string;
    type?: 'document' | 'playlist';
    swimmStatus?: 'Not found' | 'Invalid' | 'Deleted draft' | 'Shared doc error';
  }>(),
  {
    repoName: '',
    repoId: null,
    code: null,
    type: 'document',
    swimmStatus: null,
  }
);

const route = useRoute();
const analytics = useAnalytics();
const { reposStateData } = storeToRefs(useReposStore());

const repoProviderName = computed(() => {
  const repoData = reposStateData.value[props.repoId];
  if (repoData) {
    return gitwrapper.getTerminology(repoData.provider).displayName;
  }
  return 'GitHub';
});

function reportAnalytics() {
  analytics.track(productEvents.CONTENT_ERROR, {
    Message: {
      path: route.fullPath,
      type: props.type,
      swimmStatus: props.swimmStatus,
      header: failureContentByFailureType.value.header,
      description: failureContentByFailureType.value.description,
    },
  });
}

onMounted(reportAnalytics);

const failureContentByFailureType: ComputedRef<Description> = computed(() => {
  if (props.code === '404') {
    return { header: 'Content not found.', description: 'The page you were looking for does not exist.' };
  } else if (props.code === '403') {
    return {
      header: 'Content not found',
      description: `Make sure you have ${repoProviderName.value} permissions to access the repo ${props.repoName}.`,
    };
  } else {
    switch (props.swimmStatus) {
      case 'Not found':
        return {
          header: `This ${props.type} can not be found`,
          description: `The ${props.type} either has not been merged to this branch or has been deleted`,
        };
      case 'Invalid':
        return {
          header: 'File is invalid',
          description:
            'This file is invalid, corrupt, or cannot be located.  We recommend pulling the latest repo version or asking an Admin to verify its status.',
        };
      case 'Deleted draft':
        return {
          header: 'Draft has been deleted',
          description: '',
        };
      case 'Shared doc error':
        return {
          header: 'Could not load shared doc',
          description: "Either the doc is no longer shared or it doesn't exist anymore.",
        };
      default: {
        return {
          header: '',
          description: '',
        };
      }
    }
  }
});
</script>

<template>
  <div class="container" :class="{ playlist: type === 'playlist' }">
    <img src="./asset/invalid-duck.png" class="duck" />
    <SwText variant="headline3">{{ failureContentByFailureType.header }}</SwText>
    <SwText
      v-if="failureContentByFailureType.description"
      :class="{ invalid: swimmStatus === 'Invalid' }"
      variant="body-S"
      >{{ failureContentByFailureType.description }}</SwText
    >
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - var(--top-header-height));
  justify-content: center;
  gap: var(--space-base);

  &.playlist {
    --playlist-header-padding: 6vh;
    --playlist-header-height: 200px;
    height: calc(100vh - var(--top-header-height) - var(--playlist-header-height) - var(--playlist-header-padding));
  }

  .duck {
    height: 56px;
    width: 56px;
  }

  .invalid {
    max-width: 310px;
    text-align: center;
  }

  .button {
    &.link {
      color: var(--color-brand);
      cursor: pointer;
    }
  }
}
</style>
