import { type TokenSuggestion, splitLineByWords } from '@swimm/shared';

export function tokenize(value: string, minTokenLength = 1): { text: string; index: number; untrimmedText: string }[] {
  return splitLineByWords(value)
    .map((token: string, index: number) => ({
      text: token.trim(),
      untrimmedText: token,
      index: index,
    }))
    .filter((token) => token.text.length >= minTokenLength);
}

type OverlayTokenDef = {
  isDefinition: true;
  displayText: string;
  token: ReturnType<typeof tokenize>[number];
  definition: TokenSuggestion;
  key: string;
};

type OverlayTokenPlaceholder = {
  isDefinition: false;
  displayText: string;
  key: string;
};

export type OverlayToken = OverlayTokenDef | OverlayTokenPlaceholder;

export async function getOverlayTokens(
  queryDefinitions: (token: string) => Promise<TokenSuggestion[]>,
  content: string
): Promise<OverlayToken[]> {
  const tokens = tokenize(content, 0);
  const result: OverlayToken[] = [];
  for (const token of tokens) {
    const defsSuggestions = token.text.length > 1 ? await queryDefinitions(token.text) : [];
    // we consider that it it token of  definition if it has single defintion, and this is not the current line
    const isDefinition = defsSuggestions.length === 1 && defsSuggestions[0].lineData !== content;
    if (isDefinition) {
      result.push({
        isDefinition: true,
        displayText: token.untrimmedText,
        token,
        key: token.index.toString(),
        definition: defsSuggestions[0],
      });
    } else {
      result.push({
        isDefinition: false,
        displayText: ' '.repeat(token.untrimmedText.length),
        key: token.index.toString(),
      });
    }
  }
  return unifyNonDefs(result);
}

function unifyNonDefs(toks: OverlayToken[]): OverlayToken[] {
  const newToks = [];
  for (let i = 0; i < toks.length; i++) {
    const lastTok = newToks.length > 0 ? newToks[newToks.length - 1] : null;
    const newTok = toks[i];
    if (lastTok && !lastTok?.isDefinition && !newTok.isDefinition) {
      lastTok.displayText += toks[i].displayText;
    } else {
      newToks.push(toks[i]);
    }
  }
  return newToks;
}
