import { isMac } from './user-agent-utils';
import { ModifierToMacSymbol, ModifierToWindowsSymbol, Modifiers } from '../keyboard-consts';

const MAC_SPACER = '';
const WINDOWS_SPACER = '+';

export function getKeyboardCombinationString(modifier: Modifiers, ...keys: string[]): string {
  const modifierSymbol = isMac() ? ModifierToMacSymbol[modifier] : ModifierToWindowsSymbol[modifier];
  if (isMac()) {
    return `${modifierSymbol}${MAC_SPACER}${keys.join(MAC_SPACER)}`;
  }
  return `${modifierSymbol}${WINDOWS_SPACER}${keys.join(WINDOWS_SPACER)}`;
}
