import * as languageMap from 'lang-map';
import path from 'path-browserify';

export class LangExtensionUtils {
  static getProgrammingLanguageByExtension(extension: string): string {
    return languageMap.languages(extension)[0];
  }

  static getFileProgrammingLanguageByPath(filePath: string): string {
    const fileExtension = path.extname(filePath);
    return LangExtensionUtils.getProgrammingLanguageByExtension(fileExtension);
  }
}
