import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { getUserFromDB, setUserFieldInDB } from '@/common/utils/user-utils';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useNotificationsStore } from '@swimm/editor';
import { UserOnboardingFields } from '@swimm/shared';

const DAY_IN_MS = 24 * 60 * 60 * 1000;
const MIN_USER_AGE_FOR_NPS = 30 * DAY_IN_MS;

type NPSResult = 'ask-later' | 'answer' | 'dismiss';
export type NPSFormType = 'periodical' | 'user-requested';

const NPS_TIMEOUTS: Record<NPSResult, number> = {
  answer: 60 * DAY_IN_MS,
  dismiss: 30 * DAY_IN_MS,
  'ask-later': 7 * DAY_IN_MS,
};

export const useNPSStore = defineStore('npsStore', () => {
  const shownNPSFormType = ref<NPSFormType | null>(null);
  const notificationsStore = useNotificationsStore();

  const { user } = storeToRefs(useAuthStore());

  const showPeriodicalNPSIfAppropriate = async () => {
    const userCreationTime = user.value.metadata?.creationTime;
    if (!userCreationTime || new Date().getTime() - new Date(userCreationTime).getTime() < MIN_USER_AGE_FOR_NPS) {
      return;
    }
    const dbUser = await getUserFromDB(user.value.uid);
    const lastNPSShownResult: NPSResult | null = dbUser[UserOnboardingFields.LAST_NPS_SHOWN_RESULT] || null;
    const lastNPSShownDate = new Date(dbUser[UserOnboardingFields.LAST_NPS_SHOWN_DATE] || null);
    if (lastNPSShownDate) {
      const timeout = NPS_TIMEOUTS[lastNPSShownResult];
      if (timeout && new Date().getTime() - lastNPSShownDate.getTime() < timeout) {
        return;
      }
    }
    await setUserFieldInDB(user.value.uid, UserOnboardingFields.LAST_NPS_SHOWN_DATE, new Date().toISOString());
    shownNPSFormType.value = 'periodical';
  };

  const showExplicitFeedback = () => {
    shownNPSFormType.value = 'user-requested';
  };

  const onNPSResolved = (result: NPSResult) => {
    if (result === 'answer') {
      notificationsStore.addNotification('🙏 Thank you so much for your feedback!');
    }
    // Only track last shown time for periodical NPS, not for explicit user requests.
    if (shownNPSFormType.value === 'periodical') {
      setUserFieldInDB(user.value.uid, UserOnboardingFields.LAST_NPS_SHOWN_RESULT, result).then();
    }
    shownNPSFormType.value = null;
  };

  return {
    showPeriodicalNPSIfAppropriate,
    showExplicitFeedback,
    shownNPSFormType,
    onNPSResolved,
  };
});
