<script setup lang="ts">
import { BaseBlock, BaseIcon, type IconsType, Menu, MenuItem } from '@swimm/reefui';
import { computed } from 'vue';
import type { Command, Editor } from '@tiptap/vue-3';
import type { Slice } from '@tiptap/pm/model';
import { NodeSelection } from '@tiptap/pm/state';
import { type EditorView, __serializeForClipboard } from '@tiptap/pm/view';
import type { Instance } from 'tippy.js';
import { Tippy } from 'vue-tippy';
import { getSwimmEditorServices } from '../tiptap/extensions/Swimm';
import LinkCopyButton from './LinkCopyButton.vue';

const props = defineProps<{
  editor: Editor;
  instance: Instance;
  pos: number;
}>();

const swimmEditorServices = getSwimmEditorServices(props.editor);

const hasId = computed(() => {
  return props.instance.reference.hasAttribute('id');
});

const isDragDropSupported = computed(() => swimmEditorServices.isDragDropSupported);

async function copyLink() {
  const url = new URL(getWebLink());
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  url.hash = props.instance.reference.getAttribute('id')!;
  await navigator.clipboard.writeText(url.toString());
}

function getWebLink() {
  if (location.href.includes(swimmEditorServices.baseUrl)) {
    // Running on the web
    return location.href;
  }

  // Running in the IDE
  return `${swimmEditorServices.baseUrl}/workspaces/${swimmEditorServices.workspaceId.value}/repos/${swimmEditorServices.repoId.value}/docs/${swimmEditorServices.unitId.value}`;
}

interface DragHandleMenuItem {
  name: string;
  text: string;
  icon: IconsType;
  command: (pos: number) => Command;
}

const DRAG_HANDLE_MENU_ITEMS: DragHandleMenuItem[] = [
  {
    name: 'copy-link',
    text: 'Copy link',
    icon: 'link',
    command:
      (pos: number) =>
      ({ commands, dispatch, view }) => {
        if (!hasId.value) {
          return false;
        }

        if (dispatch) {
          copyLink();
        }

        return true;
      },
  },
  {
    name: 'move-up',
    text: 'Move up',
    icon: 'arrow-next-up',
    command:
      (pos: number) =>
      ({ commands }) => {
        return commands.moveNodeUp(pos, 1);
      },
  },
  {
    name: 'delete',
    text: 'Delete',
    icon: 'trash-outline',
    command:
      (pos: number) =>
      ({ commands, dispatch, state }) => {
        const $pos = state.doc.resolve(pos);
        if ($pos.depth >= 1) {
          return commands.deleteRange({ from: $pos.before(1), to: $pos.after(1) });
        } else {
          return commands.deleteRange({ from: $pos.pos, to: $pos.pos + ($pos.nodeAfter?.nodeSize ?? 0) });
        }
      },
  },
  {
    name: 'move-down',
    text: 'Move down',
    icon: 'arrow-next-down',
    command:
      (pos: number) =>
      ({ commands }) => {
        return commands.moveNodeDown(pos, 1);
      },
  },
];

// Based on https://github.com/ProseMirror/prosemirror-view/blob/9a152c5bdf92be137a5df63d61d0d9aa94a12935/src/input.ts#L624 &
// https://github.com/ueberdosis/tiptap/blob/develop/demos/src/Experiments/GlobalDragHandle/Vue/DragHandle.js
function dragstart(e: DragEvent) {
  if (e.dataTransfer == null) {
    return;
  }

  const { view, state } = props.editor;

  const $pos = state.doc.resolve(props.pos);
  const selection = NodeSelection.create(view.state.doc, $pos.before(1));
  const transaction = view.state.tr.setSelection(selection);
  view.dispatch(transaction);

  const slice = view.state.selection.content();
  // TODO Argh, this is not exported... Is there any public way of doing this?
  const { dom, text } = __serializeForClipboard(view, slice);
  e.dataTransfer.clearData();
  e.dataTransfer.effectAllowed = 'copyMove';
  e.dataTransfer.setData('text/html', dom.innerHTML);
  e.dataTransfer.setData('text/plain', text);
  e.dataTransfer?.setDragImage(props.instance.reference, 0, 0);
  view.dragging = { slice, move: true };
}

function adjustScroll(e: DragEvent) {
  if (e.dataTransfer == null) {
    return;
  }

  const scrollDiff = 150;

  // This means this internal component needs to know the scroll container of the editor - not ideal
  // TODO: pass it as editor configuration through the services?
  const container: HTMLElement | null = document.querySelector('.editor-pane__container');
  if (container !== null) {
    const containerHeight = container.getBoundingClientRect().height;

    const threshold = containerHeight / 10;

    // Offset the top banner which is not a part of the container
    if (e.clientY <= threshold + 100) {
      container.scrollBy({ top: -1 * scrollDiff, behavior: 'smooth' });
    } else if (e.clientY > containerHeight - (threshold + 100)) {
      container.scrollBy({ top: scrollDiff, behavior: 'smooth' });
    }
  }
}
</script>

<script lang="ts">
// TODO Argh, this is not exported... Is there any public way of doing this?
declare module '@tiptap/pm/view' {
  export function __serializeForClipboard(view: EditorView, slice: Slice): { dom: HTMLElement; text: string };
}
</script>

<template>
  <div class="drag-handle">
    <LinkCopyButton v-if="hasId" @click="copyLink()" />
    <Tippy
      v-if="swimmEditorServices.editable.value"
      trigger="click"
      :interactive="true"
      placement="bottom-start"
      :duration="100"
      theme="none"
    >
      <BaseIcon
        v-tooltip="'Click for options'"
        data-testid="drag-handle-options"
        :name="isDragDropSupported ? 'drag-handle-options' : 'more-vertical-centered'"
        class="drag-handle__handle"
        :class="{ grabbable: isDragDropSupported }"
        :draggable="isDragDropSupported"
        @dragstart="dragstart"
        @drag="adjustScroll"
      />

      <template #content="{ hide }">
        <BaseBlock padding="none" shadow="medium">
          <Menu class="drag-handle__menu">
            <template v-for="item of DRAG_HANDLE_MENU_ITEMS" :key="item.name">
              <MenuItem
                v-if="editor.can().command(item.command(pos))"
                :data-testid="`drag-handle-menu-item-${item.name}`"
                @click="
                  editor.commands.command(item.command(pos));
                  hide();
                "
              >
                <template #leftIcon><BaseIcon :name="item.icon" /></template>

                {{ item.text }}
              </MenuItem>
            </template>
          </Menu>
        </BaseBlock>
      </template>
    </Tippy>
  </div>
</template>

<style scoped lang="scss">
.drag-handle {
  color: var(--text-color-disable);
  display: flex;
  flex-direction: row;
  gap: var(--space-base);
  align-items: stretch;

  &__handle {
    cursor: pointer;
    font-size: var(--font-size-medium);
    transform: translateY(-4px);

    &.grabbable {
      cursor: grab;
    }
  }
}
</style>
