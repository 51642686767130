import { Node } from '@tiptap/pm/model';

// Adjusted from https://github.com/ProseMirror/prosemirror-tables/blob/master/src/fixtables.ts
export function changedDescendants(
  old: Node,
  cur: Node,
  offset: number,
  oldOffset: number,
  f: (node: Node, pos: number) => boolean // Added a boolean return value.
): void {
  const oldSize = old.childCount,
    curSize = cur.childCount;
  outer: for (let i = 0, j = 0; i < curSize; i++) {
    const child = cur.child(i);
    for (let scan = j, e = Math.min(oldSize, i + 3); scan < e; scan++) {
      // Changed `==` to `===`.
      // A node can retain its identity but have an altered position
      // In that case we want to trigger its update as well - so the insertion position on the decoration is correct
      if (old.child(scan) === child && oldOffset === offset) {
        j = scan + 1;
        offset += child.nodeSize;
        oldOffset += old.child(scan).nodeSize;
        continue outer;
      }
    }
    // f will return whether we should descend the node.
    if (f(child, offset)) {
      if (j < oldSize && old.child(j).sameMarkup(child)) {
        changedDescendants(old.child(j), child, offset + 1, oldOffset + 1, f);
      } else {
        child.nodesBetween(0, child.content.size, f, offset + 1);
      }
    }
    offset += child.nodeSize;
  }
}
