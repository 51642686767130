import { defineStore } from 'pinia';
import { type Ref, ref } from 'vue';
import { STORES } from '@/modules/core/stores/store-constants';

type LimitToRepoType = {
  repoId: string;
  branch: string;
};

export const useGlobalSearchStore = defineStore(STORES.GLOBAL_SEARCH, () => {
  const shouldShowSearchModal = ref(false);
  const isIndexingSearchData = ref(false);
  const globalSearchLimitToRepo: Ref<LimitToRepoType> = ref(undefined);
  const selectedRepoIdsForSearch: Ref<string[]> = ref([]);
  const initialSelectedRepoIdsForSearch: Ref<string[]> = ref([]);

  function showRepoSearch({ repoId, branch }: { repoId: string; branch: string }) {
    globalSearchLimitToRepo.value = { repoId, branch };
    shouldShowSearchModal.value = true;
  }
  return {
    shouldShowSearchModal,
    isIndexingSearchData,
    showRepoSearch,
    globalSearchLimitToRepo,
    selectedRepoIdsForSearch,
    initialSelectedRepoIdsForSearch,
  };
});
