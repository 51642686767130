export const billingPlanTypes = {
  FREE: 'free',
  PRO: 'pro',
  STANDARD: 'standard',
  TEAM: 'team',
  TEAM10: 'team10',
  SMALL_TEAM: 'small-teams',
  ENTERPRISE_STARTER: 'enterprise-starter',
  ENTERPRISE: 'enterprise',
};

export const productSKUs = {
  PRO: billingPlanTypes.PRO,
  STANDARD: billingPlanTypes.STANDARD,
  TEAM: billingPlanTypes.TEAM,
  TEAM10: billingPlanTypes.TEAM10,
  SMALL_TEAM: billingPlanTypes.SMALL_TEAM,
};

export const stiggPlanIds = {
  SMALL_TEAM: 'plan-swimm-small-teams',
  TEAM: 'plan-swimm-team',
  TEAM10: 'plan-swimm-team10',
};

export enum salesforcePlans {
  FREE = 'Free tier',
  TEAM = 'Team',
  TEAM10 = 'Team10',
  TRIAL = 'Team/Trial',
  ENTERPRISE_STARTER = 'Enterprise Starter',
  ENTERPRISE = 'Enterprise',
}
export type salesforcePlanType = `${salesforcePlans}`;

export enum StiggFeatures {
  SEAT_COUNT = 'feature-users-charge', // holds the number of seats in stigg for seat based plans
  MANUAL_SEAT_COUNT = 'feature-seats-visual-only', // holds the number of seats in stigg for enterprises we don't really want to limit adding users. Do not update quantity.
  USER_COUNT = 'feature-users', // holds the number of users in the stigg for user count based plans
  REPOS = 'feature-repos',
  AUTOMATED_AUTOSYNC = 'feature-automated-auto-sync',
  GENERATIVE_AI = 'feature-ai-generated-comments',
  GENERATIVE_AI_CAP = 'feature-generative-ai-cap',
  SHARING_INTERNALLY = 'feature-sharing-internally',
  ASK_SWIMM = 'feature-ask-swimm',
  ASK_SWIMM_CAP = 'feature-ask-swimm-cap',
  IDE_RULES_CAP = 'feature-ide-rules',
  SLACK_INTEGRATION = 'feature-slack-integration',
  TEXT_COMPLETION_CAP = 'feature-ai-text-auto-completion-cap',
}

export type StiggFeaturesType = `${StiggFeatures}`;

export enum StiggAddons {
  GENERATIVE_AI_CAP = 'addon-swimm-generative-ai-cap',
  TEXT_COMPLETION_CAP = 'addon-swimm-ai-text-auto-completion-cap',
}
