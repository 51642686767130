<script setup>
import { billingPlanTypes } from '@swimm/shared';
import PlanDetails from '@/modules/workspace/modals/settings/billing/PlanDetails.vue';
import ChangePlan from '@/modules/workspace/modals/settings/billing/ChangePlan.vue';
import Payment from '@/modules/workspace/modals/settings/billing/Payment.vue';
import UpgradeSuccess from '@/modules/workspace/modals/settings/billing/UpgradeSuccess.vue';
import { workspaceSettingsBillingTabPhases } from '@swimm/shared';
import { computed, onMounted, ref, watch } from 'vue';
import { useBillingStore } from '@/modules/billing/store/billing';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

const billingStore = useBillingStore();
const { isOnTrialPlan } = storeToRefs(billingStore);
const { showBillingPlans, setShowBillingPlans } = billingStore;

const props = defineProps({
  initialTabPhase: { type: String, default: '' },
});

const route = useRoute();
const store = useStore();

const billingPeriod = ref('');
const productSKU = ref('');
const hasPaymentData = ref(false);
const selectedQuantity = ref(0);
const currentBillingPhase = ref(props.initialTabPhase || workspaceSettingsBillingTabPhases.PLAN_DETAILS);
const discountDeepLinkPlan = ref(route.query?.plan);
const discountDeepLinkPeriod = ref(route.query?.period);
const workspaceId = ref(route.params.workspaceId);

const workspace = computed(() => store.getters['database/db_getWorkspace'](workspaceId.value));
const license = computed(() => workspace.value?.license);

function upgrade(upgradeRequest) {
  productSKU.value = upgradeRequest.productSKU;
  hasPaymentData.value = upgradeRequest.hasPaymentData;
  billingPeriod.value = upgradeRequest.billingPeriod;
  selectedQuantity.value = upgradeRequest.selectedQuantity;
  goToPhase(workspaceSettingsBillingTabPhases.PAYMENT, false);
}
function goToPhase(phase, reset = true) {
  if (reset) {
    resetData();
  }
  currentBillingPhase.value = phase;
}
function upgradePlanSuccess(plan) {
  productSKU.value = plan;
  goToPhase(workspaceSettingsBillingTabPhases.CHANGE_SUCCESS, false);
}
function resetData() {
  productSKU.value = '';
  billingPeriod.value = '';
}

function upgradeFromDiscountLink() {
  upgrade({
    productSKU: discountDeepLinkPlan.value,
    billingPeriod: discountDeepLinkPeriod.value.toUpperCase(),
    hasPaymentData: false,
  });
}

onMounted(async () => {
  if (showBillingPlans) {
    goToPhase(workspaceSettingsBillingTabPhases.CHANGE_PLAN);
    setShowBillingPlans(false);
  }

  if (discountDeepLinkPlan.value && discountDeepLinkPeriod.value) {
    if (!license.value || license.value === billingPlanTypes.FREE) {
      upgradeFromDiscountLink();
    } else {
      watch(
        isOnTrialPlan,
        (newValue) => {
          if (newValue) {
            upgradeFromDiscountLink();
          }
        },
        { immediate: true }
      );
    }
  }
});
</script>

<template>
  <PlanDetails
    v-if="currentBillingPhase === workspaceSettingsBillingTabPhases.PLAN_DETAILS"
    @change-plan="goToPhase(workspaceSettingsBillingTabPhases.CHANGE_PLAN)"
  />
  <ChangePlan
    v-else-if="currentBillingPhase === workspaceSettingsBillingTabPhases.CHANGE_PLAN"
    @upgrade="upgrade"
    @started-trial="goToPhase(workspaceSettingsBillingTabPhases.PLAN_DETAILS)"
    @back="goToPhase(workspaceSettingsBillingTabPhases.PLAN_DETAILS)"
  />
  <!-- eslint-disable vue/attribute-hyphenation -->
  <Payment
    v-else-if="currentBillingPhase === workspaceSettingsBillingTabPhases.PAYMENT"
    :productSKU="productSKU"
    :billing-period="billingPeriod"
    :has-payment-data="hasPaymentData"
    :billing-quantity="selectedQuantity"
    @upgrade-success="upgradePlanSuccess"
    @back="goToPhase(workspaceSettingsBillingTabPhases.CHANGE_PLAN)"
  />
  <UpgradeSuccess
    v-else-if="currentBillingPhase === workspaceSettingsBillingTabPhases.CHANGE_SUCCESS"
    :productSKU="productSKU"
    @back="goToPhase(workspaceSettingsBillingTabPhases.PLAN_DETAILS)"
  />
</template>

<style scoped lang="postcss">
.divider {
  margin: var(--space-lg) 0;
}
</style>
