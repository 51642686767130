import type { JSONContent } from '@tiptap/core';

export const SWMD_VERSION = '3.0.0';

/**
 * A Swimm document.
 */
export interface SwimmDocument {
  /** Version, the current version is {@link SWMD_VERSION} */
  version?: string;

  /** Document title (optional) */
  title?: string;

  /** The document's repository ID */
  repoId?: string;

  /** The document's repository name */
  repoName?: string;

  /** Optional front matter elements besides the title, used to preserve them if there were any */
  frontmatter: Record<string, unknown>;

  /**
   * The content of the document in the form of an uninstantiated (Not a prosemirror-model `Node` instance) prosemirror-model/Tiptap JSON.
   */
  content: JSONContent;
}
