import firebase from 'firebase/compat/app';
import { firestoreCollectionNames } from '../../firestore-consts';

export interface DbFolder {
  name: string;
  is_root?: boolean;
  children: firebase.firestore.DocumentReference[];
  creator: string;
  creator_name: string;
  created: firebase.firestore.Timestamp;
  modifier: string;
  modifier_name: string;
  modified: firebase.firestore.Timestamp;
  creator_profile_url: string;
}

export enum FolderItemType {
  DOC,
  PLAYLIST,
  FOLDER,
  UNKNOWN,
}

export const FolderItemTypeToCollection: Record<FolderItemType, string> = {
  [FolderItemType.DOC]: firestoreCollectionNames.SWIMMS,
  [FolderItemType.PLAYLIST]: firestoreCollectionNames.PLAYLISTS,
  [FolderItemType.FOLDER]: firestoreCollectionNames.FOLDERS,
  [FolderItemType.UNKNOWN]: '',
};
