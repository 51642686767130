import CodeBlockLowlight, { type CodeBlockLowlightOptions } from '@tiptap/extension-code-block-lowlight';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import CodeBlockNodeView from '@/tiptap/nodeViews/CodeBlockNodeView.vue';
import { SwimmEditorServices } from '@/tiptap/editorServices';
import { Selection } from '@tiptap/pm/state';
import { lowlight } from '@swimm/editor';

// List of languages taken from https://highlightjs.readthedocs.io/en/latest/supported-languages.html
// since lowlight.listLanguages does not return all the language aliases supported by highlight.js (such as HTML, tsx,
// etc.). We cannot query highlight.js directly since we don't want it as a dependency.
const languageList = [
  '1c',
  '4d',
  'abap',
  'abnf',
  'accesslog',
  'actionscript',
  'ada',
  'adoc',
  'alan',
  'angelscript',
  'apache',
  'apacheconf',
  'apex',
  'applescript',
  'arcade',
  'arduino',
  'arm',
  'armasm',
  'as',
  'asc',
  'asciidoc',
  'aspectj',
  'atom',
  'autohotkey',
  'autoit',
  'avrasm',
  'awk',
  'axapta',
  'bal',
  'ballerina',
  'bash',
  'basic',
  'bat',
  'bbcode',
  'bf',
  'bind',
  'blade',
  'bnf',
  'bqn',
  'brainfuck',
  'c',
  'c++',
  'c3',
  'cal',
  'candid',
  'capnp',
  'capnproto',
  'cc',
  'chaos',
  'chapel',
  'chpl',
  'cisco',
  'clj',
  'clojure',
  'cls',
  'cmake',
  'cmake.in',
  'cmd',
  'cobol',
  'coffee',
  'coffeescript',
  'console',
  'coq',
  'cos',
  'cpc',
  'cpp',
  'cr',
  'craftcms',
  'crm',
  'crmsh',
  'crystal',
  'cs',
  'csharp',
  'cshtml',
  'cson',
  'csp',
  'css',
  'cts',
  'curl',
  'cxx',
  'cypher',
  'd',
  'dafny',
  'dart',
  'dfm',
  'did',
  'diff',
  'django',
  'dns',
  'docker',
  'dockerfile',
  'dos',
  'dpr',
  'dsconfig',
  'dst',
  'dts',
  'dust',
  'dylan',
  'ebnf',
  'elixir',
  'elm',
  'erl',
  'erlang',
  'excel',
  'extempore',
  'f90',
  'f95',
  'fix',
  'flix',
  'fortran',
  'fs',
  'fsharp',
  'fsi',
  'fsscript',
  'fsx',
  'func',
  'gams',
  'gauss',
  'gawk',
  'gcode',
  'gdscript',
  'gemspec',
  'gf',
  'gherkin',
  'glimmer',
  'glsl',
  'gms',
  'gn',
  'gni',
  'go',
  'godot',
  'golang',
  'golo',
  'gololang',
  'gradle',
  'graph',
  'graphql',
  'groovy',
  'gsql',
  'gss',
  'gyp',
  'h',
  'h++',
  'haml',
  'handlebars',
  'haskell',
  'haxe',
  'hbs',
  'hcl',
  'hh',
  'hlsl',
  'hpp',
  'hs',
  'html',
  'html.handlebars',
  'html.hbs',
  'htmlbars',
  'http',
  'https',
  'hx',
  'hxx',
  'hy',
  'hylang',
  'i',
  'i7',
  'iced',
  'iecst',
  'inform7',
  'ini',
  'ino',
  'instances',
  'iol',
  'iptables',
  'irb',
  'irpf90',
  'java',
  'javascript',
  'jinja',
  'jl',
  'jolie',
  'js',
  'json',
  'jsp',
  'jsx',
  'julia',
  'julia-repl',
  'k',
  'kaos',
  'kdb',
  'kotlin',
  'kt',
  'lasso',
  'lassoscript',
  'ldif',
  'leaf',
  'lean',
  'less',
  'lisp',
  'livecodeserver',
  'livescript',
  'ln',
  'lookml',
  'ls',
  'lua',
  'macaulay2',
  'mak',
  'make',
  'makefile',
  'markdown',
  'mathematica',
  'matlab',
  'mawk',
  'maxima',
  'md',
  'mel',
  'mercury',
  'mint',
  'mips',
  'mipsasm',
  'mirc',
  'mizar',
  'mk',
  'mkb',
  'mkd',
  'mkdown',
  'ml',
  'mlir',
  'mm',
  'mma',
  'mo',
  'mojolicious',
  'monkey',
  'moon',
  'moonscript',
  'motoko',
  'mrc',
  'mts',
  'n1ql',
  'nawk',
  'nc',
  'never',
  'nginx',
  'nginxconf',
  'nim',
  'nimrod',
  'nix',
  'nsis',
  'oak',
  'obj-c',
  'obj-c++',
  'objc',
  'objective-c++',
  'objectivec',
  'ocaml',
  'ocl',
  'ol',
  'openscad',
  'osascript',
  'oxygene',
  'p21',
  'papyrus',
  'parser3',
  'pas',
  'pascal',
  'patch',
  'pcmk',
  'perl',
  'pf',
  'pf.conf',
  'pgsql',
  'php',
  'pine',
  'pinescript',
  'pl',
  'plaintext',
  'plist',
  'pm',
  'podspec',
  'pony',
  'postgres',
  'postgresql',
  'powershell',
  'pp',
  'prg',
  'processing',
  'profile',
  'prolog',
  'properties',
  'proto',
  'protobuf',
  'ps',
  'ps1',
  'psc',
  'puppet',
  'py',
  'pycon',
  'python',
  'python-repl',
  'qml',
  'qsharp',
  'r',
  'razor',
  'razor-cshtml',
  'rb',
  're',
  'reasonml',
  'rebol',
  'red',
  'red-system',
  'redbol',
  'rf',
  'rib',
  'risc',
  'riscript',
  'riscv',
  'riscvasm',
  'robot',
  'rpm',
  'rpm-spec',
  'rpm-specfile',
  'rs',
  'rsl',
  'rss',
  'ruby',
  'ruleslanguage',
  'rust',
  'rvt',
  'rvt-script',
  'sap-abap',
  'SAS',
  'sas',
  'sc',
  'scad',
  'scala',
  'scheme',
  'sci',
  'scilab',
  'scl',
  'scss',
  'sfz',
  'sh',
  'shell',
  'shexc',
  'smali',
  'smalltalk',
  'sml',
  'sol',
  'solidity',
  'spec',
  'specfile',
  'spl',
  'sql',
  'st',
  'stan',
  'standard-cobol',
  'stanfuncs',
  'stata',
  'step',
  'stl',
  'stp',
  'structured-text',
  'styl',
  'stylus',
  'subunit',
  'supercollider',
  'svelte',
  'svg',
  'swift',
  'tao',
  'tap',
  'tcl',
  'terraform',
  'tex',
  'text',
  'tf',
  'thor',
  'thrift',
  'tk',
  'toit',
  'toml',
  'tp',
  'ts',
  'tsql',
  'tsx',
  'twig',
  'txt',
  'typescript',
  'unicorn-rails-log',
  'v',
  'vala',
  'vb',
  'vba',
  'vbnet',
  'vbs',
  'vbscript',
  'verilog',
  'vhdl',
  'vim',
  'wl',
  'x++',
  'x86asm',
  'x86asmatt',
  'xhtml',
  'xjb',
  'xl',
  'xls',
  'xlsx',
  'xml',
  'xpath',
  'xq',
  'xqm',
  'xquery',
  'xs',
  'xsd',
  'xsharp',
  'xsl',
  'xtlang',
  'xtm',
  'yaml',
  'yml',
  'zenscript',
  'zep',
  'zephir',
  'zone',
  'zs',
  'zsh',
];
type AllowedLanguages = (typeof languageList)[number];

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    codeBlock2: {
      replaceLanguage: (pos: number, language: AllowedLanguages) => ReturnType;
      copyCodeBlock: (pos: number) => ReturnType;
    };
  }
}

interface CodeBlockStorage {
  editorServices: SwimmEditorServices;
}

export default CodeBlockLowlight.extend<CodeBlockLowlightOptions, CodeBlockStorage>({
  addStorage() {
    return {
      ...this.parent?.(),

      allowedLanguages: ['', ...languageList] as (AllowedLanguages | '')[],
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(CodeBlockNodeView);
  },

  addCommands() {
    return {
      ...this.parent?.(),

      replaceLanguage:
        (pos, language) =>
        ({ dispatch, editor, tr }) => {
          if (dispatch) {
            const node = editor.state.doc.nodeAt(pos);
            if (!node) {
              return false;
            }

            if (node.type !== this.type) {
              return false;
            }

            tr.setNodeAttribute(pos, 'language', language);
          }
          return true;
        },

      copyCodeBlock:
        (pos) =>
        ({ dispatch, editor }) => {
          if (dispatch) {
            (async () => {
              const node = editor.state.doc.nodeAt(pos);
              if (!node) {
                return false;
              }
              await navigator.clipboard.writeText(node.textContent);
            })();
          }
          return true;
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      ...this.parent?.(),
      // exit node on arrow down
      ArrowDown: ({ editor }) => {
        if (!this.options.exitOnArrowDown) {
          return false;
        }

        const { state } = editor;
        const { selection, doc } = state;
        const { $from, empty } = selection;

        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;

        if (!isAtEnd) {
          return false;
        }

        const after = $from.after();

        if (after === undefined) {
          return false;
        }

        const nodeAfter = doc.nodeAt(after);

        if (nodeAfter) {
          // This used to "return false", but that's not the desired behavior, we want to move the cursor to the next node
          //  https://github.com/ueberdosis/tiptap/blob/a706df78c2df8235b6b1825a71fccf8539fe9700/packages/extension-code-block/src/code-block.ts#L208
          return editor.commands.command(({ tr }) => {
            tr.setSelection(Selection.near(doc.resolve(after)));
            return true;
          });
        }

        return editor.commands.exitCode();
      },
    };
  },
}).configure({
  lowlight,
  defaultLanguage: 'plaintext',
  HTMLAttributes: {
    class: 'code-block',
  },
});
