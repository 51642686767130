import type { Editor } from '@tiptap/core';
import type { Node } from '@tiptap/pm/model';

import {
  SmartElementType,
  type SmartElementWithApplicabilityAndNewInfo,
  type Snippet,
  isSmartElementWithNewInfo,
  removePrefix,
} from '@swimm/shared';

import { getSwimmNodeId } from '@/swmd/swimm_node';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';
import SwmSnippet from '@/tiptap/extensions/SwmSnippet';
import type { SwimmEditorServices } from '@/tiptap/editorServices';

export interface SnippetInfo {
  repoId: string;
  filePath: string;
  lineRange: {
    start: number;
    end: number;
  };
}

export function snippetNodeToSnippetInfo(
  snippetNode: Node,
  swimmEditorServices: SwimmEditorServices
): SnippetInfo | undefined {
  const snippetNodeId = getSwimmNodeId(snippetNode);
  const autosyncedElement = swimmEditorServices.autosyncOutput.value.smartElements.get(snippetNodeId);

  if (
    autosyncedElement == null ||
    !isSmartElementWithNewInfo(autosyncedElement) ||
    autosyncedElement.type !== SmartElementType.SNIPPET
  ) {
    return undefined;
  }

  const autosyncedSnippet = autosyncedElement as SmartElementWithApplicabilityAndNewInfo<Snippet>;
  if (autosyncedSnippet.newInfo.gitInfo == null) {
    return undefined;
  }

  return {
    repoId: autosyncedSnippet.newInfo.gitInfo.repoId,
    filePath: removePrefix(autosyncedSnippet.newInfo.filePath, '/'),
    lineRange: {
      start: autosyncedSnippet.newInfo.startLineNumber,
      end: autosyncedSnippet.newInfo.startLineNumber + autosyncedSnippet.newInfo.lines.length - 1,
    },
  };
}

export function getFocsuedSnippetInfo(editor: Editor): SnippetInfo | undefined {
  const swimmEditorServices = getSwimmEditorServices(editor);
  const snippetNode = getFocusedSnippetNode(editor);
  if (snippetNode == null) {
    return undefined;
  }

  return snippetNodeToSnippetInfo(snippetNode, swimmEditorServices);
}

function getFocusedSnippetNode(editor: Editor): Node | undefined {
  const selectionHead = editor.view.state.selection.$head;
  if (selectionHead == null) {
    return undefined;
  }

  for (let i = selectionHead.depth; i >= 0; i--) {
    const parentNode = selectionHead.node(i);
    if (parentNode.type.name === SwmSnippet.name) {
      return parentNode;
    }
  }

  return undefined;
}
