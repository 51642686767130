<script setup>
import { Divider } from '@swimm/ui';
import { pluralize } from '@/common/utils/helpers';
import { Action, Icon, SwText } from '@swimm/ui';
import {
  EXPIRING_TRIAL_LENGTH,
  Licenses,
  PlansDescriptions,
  StiggFeatures,
  billingPlanTypes,
  getUserEntitlementByLicense,
} from '@swimm/shared';
import { capitalize } from 'lodash-es';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useStigg } from '@/common/composables/useStigg';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useBillingStore } from '@/modules/billing/store/billing';

const UNLIMITED = '∞';

const store = useStore();
const route = useRoute();
const { stiggClient } = useStigg();
const { isOnTrialPlan, trialDaysLeft } = useBillingStore();

const emit = defineEmits(['change-plan']);

const loading = ref(true);

onMounted(async () => {
  await store.dispatch('database/fetchAllWorkspaceRepos', route.params.workspaceId);
  // refresh in case trial expired
  await store.dispatch('database/refreshWorkspaceLicense', { workspaceId: route.params.workspaceId });
  loading.value = false;
});

const workspace = computed(() => store.getters['database/db_getWorkspace'](route.params.workspaceId));
const { user } = storeToRefs(useAuthStore());
const isWorkspaceAdmin = computed(() =>
  store.getters['database/db_isWorkspaceAdmin'](route.params.workspaceId, user.value.uid)
);

const isFreePlan = computed(() => workspace.value.license === Licenses.FREE);
const plan = computed(() => capitalize(workspace.value.license));
const planDescription = computed(() => PlansDescriptions[plan.value.toUpperCase()]);

const stiggRepoEntitlement = computed(() => {
  return stiggClient.getMeteredEntitlement({
    featureId: StiggFeatures.REPOS,
  });
});

const stiggUserEntitlementLimit = computed(() => {
  const entitlement = stiggClient.getMeteredEntitlement({
    featureId: getUserEntitlementByLicense(workspace.value.license),
  });
  return entitlement.isUnlimited ? UNLIMITED : entitlement.usageLimit ?? 0;
});

const stiggGenAICapEntitlement = computed(() => {
  return stiggClient.getMeteredEntitlement({
    featureId: StiggFeatures.GENERATIVE_AI_CAP,
  });
});

const stiggAskSwimmCapIEntitlement = computed(() => {
  return stiggClient.getMeteredEntitlement({
    featureId: StiggFeatures.ASK_SWIMM_CAP,
  });
});

const stiggTextCompletionCapIEntitlement = computed(() => {
  return stiggClient.getMeteredEntitlement({
    featureId: StiggFeatures.TEXT_COMPLETION_CAP,
  });
});

const repoCap = computed(() =>
  stiggRepoEntitlement.value.isUnlimited ? UNLIMITED : stiggRepoEntitlement.value.usageLimit ?? 0
);
const repoUsage = computed(() => stiggRepoEntitlement.value.currentUsage);

const privateReposString = computed(() => {
  if (repoCap.value === UNLIMITED) {
    return `${repoUsage.value} private repos`;
  }
  return `${repoUsage.value} / ${repoCap.value} private ${pluralize({ word: 'repo', count: repoUsage.value })}`;
});

const deletedUsers = computed(() => store.getters['database/db_getWorkspaceDeletedUsers'](route.params.workspaceId));
const usersAmount = computed(() => {
  const totalWithoutDeleted = (workspace.value?.invites?.length || 0) + (workspace.value?.counter_workspace_users || 0);
  if (!workspace.value?.license || workspace.value?.license === billingPlanTypes.FREE) {
    return totalWithoutDeleted + Object.keys(deletedUsers.value || {}).length;
  }

  return totalWithoutDeleted;
});

const usersString = computed(() => {
  if (stiggUserEntitlementLimit.value === UNLIMITED) {
    return `${usersAmount.value} users`;
  }
  return `${usersAmount.value} / ${stiggUserEntitlementLimit.value} ${pluralize({
    word: 'user',
    count: usersAmount.value,
  })}`;
});

const genAICap = computed(() =>
  !stiggGenAICapEntitlement.value.hasAccess
    ? 0
    : stiggGenAICapEntitlement.value.isUnlimited
    ? UNLIMITED
    : stiggGenAICapEntitlement.value.usageLimit
);
const genAIUsage = computed(() =>
  stiggGenAICapEntitlement.value.hasAccess ? stiggGenAICapEntitlement.value.currentUsage : 0
);
const genAIResetDate = computed(() =>
  stiggGenAICapEntitlement.value.hasAccess ? stiggGenAICapEntitlement.value.resetSettings?.nextResetDate : null
);

const genAICapString = computed(() => {
  if (genAICap.value === UNLIMITED) {
    return `${genAIUsage.value} Generative AI queries`;
  }

  return `${genAIUsage.value} / ${genAICap.value} Generative AI ${genAIUsage.value === 1 ? 'query' : 'queries'}`;
});

const askSwimmCap = computed(() =>
  !stiggAskSwimmCapIEntitlement.value.hasAccess
    ? 0
    : stiggAskSwimmCapIEntitlement.value.isUnlimited
    ? UNLIMITED
    : stiggAskSwimmCapIEntitlement.value.usageLimit
);
const askSwimmUsage = computed(() =>
  stiggAskSwimmCapIEntitlement.value.hasAccess ? stiggAskSwimmCapIEntitlement.value.currentUsage : 0
);
const askSwimmResetDate = computed(() =>
  stiggAskSwimmCapIEntitlement.value.hasAccess ? stiggAskSwimmCapIEntitlement.value.resetSettings?.nextResetDate : null
);

const textCompletionCapText = computed(() => {
  if (
    (!stiggTextCompletionCapIEntitlement.value.hasAccess && !stiggTextCompletionCapIEntitlement.value.currentUsage) || // for obsolete plans (no access and no usage at all)
    isFreePlan.value
  ) {
    return null;
  } else if (stiggTextCompletionCapIEntitlement.value.isUnlimited) {
    return 'Unlimited AI Text Autocomplete';
  }
  const percentage = Math.round(
    (stiggTextCompletionCapIEntitlement.value.currentUsage / stiggTextCompletionCapIEntitlement.value.usageLimit) * 100
  );
  // Maximize to 100 in case of over usage
  return `${percentage > 100 ? 100 : percentage}% of usage cap for AI Text Autocomplete`;
});

const textCompletionCapResetDate = computed(() =>
  stiggTextCompletionCapIEntitlement.value.hasAccess || stiggTextCompletionCapIEntitlement.value.currentUsage
    ? stiggTextCompletionCapIEntitlement.value.resetSettings?.nextResetDate
    : null
);

const askSwimmCapString = computed(() => {
  if (askSwimmCap.value === UNLIMITED) {
    return `${askSwimmUsage.value} Ask Swimm questions`;
  }

  return `${askSwimmUsage.value} / ${askSwimmCap.value} Ask Swimm ${pluralize({
    word: 'question',
    count: usersAmount.value,
  })} `;
});

const entitlements = computed(() => [
  { icon: 'private', text: privateReposString.value },
  { icon: 'team', text: usersString.value },
]);

const entitlementUsages = computed(() => {
  const usages = [];
  if (genAICap.value) {
    usages.push({
      icon: 'magic',
      text: genAICapString.value,
      class: 'magic',
      resetDate: genAIResetDate.value,
    });
  }
  if (askSwimmCap.value) {
    usages.push({
      icon: 'magic',
      text: askSwimmCapString.value,
      class: 'magic',
      resetDate: askSwimmResetDate.value,
    });
  }
  if (textCompletionCapText.value) {
    usages.push({
      icon: 'magic',
      text: textCompletionCapText.value,
      class: 'magic',
      resetDate: textCompletionCapResetDate.value,
    });
  }
  return usages;
});

const changePlan = function () {
  emit('change-plan');
};

function dateToPrint(date) {
  return date
    ? new Date(date).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })
    : null;
}
</script>

<template>
  <div class="plan-card">
    <div class="card-header">
      <div class="plan-details">
        <SwText variant="headline1" class="type"
          >{{ plan }}
          <span v-if="isOnTrialPlan"> (Trial) </span>
          <SwText v-if="isOnTrialPlan" component="span" :class="[{ expiring: trialDaysLeft <= EXPIRING_TRIAL_LENGTH }]"
            >{{ trialDaysLeft }} days left</SwText
          >
        </SwText>
        <SwText variant="body-S" class="description">{{ planDescription }}</SwText>
      </div>
      <Action v-if="isWorkspaceAdmin" data-testid="change-plan" @click="changePlan">{{
        isOnTrialPlan ? 'Upgrade Now' : 'Change Plan'
      }}</Action>
    </div>
    <Divider class="card-divider" />
    <div class="card-footer">
      <div class="footer-column">
        <SwText v-for="item in entitlements" :key="item.text" variant="body-L">
          <Icon :name="item.icon" no-padding /> {{ item.text }}
        </SwText>
      </div>
      <div class="footer-column">
        <div v-for="item in entitlementUsages" :key="item.text" class="footer-item">
          <Icon :name="item.icon" :class="item.class" />
          <span>
            <SwText variant="body-L">{{ item.text }}</SwText>
            <SwText variant="body-S" v-if="item.resetDate" class="reset-section"
              >resets {{ dateToPrint(item.resetDate) }}</SwText
            >
          </span>
        </div>
      </div>
    </div>
  </div>
  <template v-if="!isFreePlan">
    <SwText variant="body-S"
      ><a class="link" href="mailto:info@swimm.io">Contact us</a> if you wish to cancel or downgrade your subscription
      plan.</SwText
    >
  </template>
</template>

<style scoped lang="postcss">
.plan-card {
  padding: var(--space-md);
  border: 1px solid var(--color-border-default);
  border-radius: 4px;
  margin-bottom: var(--space-sm);

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .plan-details {
      flex: 1;

      .type {
        margin-bottom: var(--space-sm);
        padding: 0;
      }

      .description {
        color: var(--text-color-secondary);
      }
    }
  }

  .card-divider {
    margin: var(--space-md) 0;
  }

  .card-footer {
    display: flex;

    .footer-column {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: var(--space-xs);

      .footer-item {
        display: flex;
        gap: var(--space-xs);

        .reset-section {
          color: var(--text-color-secondary);
        }
      }

      .magic {
        color: var(--text-color-magic);
      }
    }
  }
}

.link {
  cursor: pointer;
  display: inline-block;
  color: var(--text-color-link);
  font-weight: bold;
}

.expiring {
  color: var(--text-color-error);
}
</style>
