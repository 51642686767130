// @ts-strict
import type { SmartElementWithApplicability, Snippet } from '@swimm/shared';
import {
  ApplicabilityStatus,
  DynamicChangeLine,
  DynamicHunkContainer,
  HunkChangeLineType,
  MultiDynamicPatch,
  changeDynamicHunkContanierStartingLineNumber,
  getLinesFromDynamicHunkContainer,
  getLoggerNew,
  getMeatMatchesInFile,
  gitwrapper,
  objectUtils,
  similarityDiff,
  stringUtils,
} from '@swimm/shared';
import { findLastIndex, has, isEmpty, maxBy } from 'lodash-es';
import { isDynamicFilePatchApplicable } from './applicability-utils';
import { getSmartElementWithApplicability, getSnippetById } from './swimmagic-common';

const logger = getLoggerNew("packages/swimmagic/src/autosync-without-blob-sha.ts");

/** This object holds the configuration for Autosyncing without a blobSha. */
export const autosyncWithoutBlobShaConfig = {
  /** The maximum number of "best first line candidates" we'll operate on. */
  MAX_FIRST_LINE_CANDIDATES: 3,
  /** The base score used when calculating the diff score we should give deleted lines (since we cannot compare against them). */
  DELETED_LINE_SCORE_RANK: 0.05,
  /** The base score used when calculating the diff score we should give added lines (since we cannot compare against them). */
  ADDED_LINE_SCORE_RANK: 0.1,
  /** The diff score given to context lines (since they are equal). */
  CONTEXT_LINE_DIFF_SCORE: 1.0,
  /** The threshold from which we'll consider a hunk as autosyncable (instead of outdated). */
  HUNK_DIFF_AUTOSYNC_THRESHOLD: 0.6,
  /** Minimum diff score that a line can have in order to be considered a BFL candidate. */
  BEST_FIRST_LINE_MIN_DIFF_SCORE_FOR_MULTIPLE_LINES: 0.4,
  /** Be more conservative when the meat is only one line. */
  BEST_FIRST_LINE_MIN_DIFF_SCORE_FOR_SINGLE_LINE: 0.6,
  /**
   * The lookahead range as a multiplication of the number of lines in the original hunk.
   * We do that in order to limit the number of the optional candidates each change line might encounter.
   * It makes sense that snippets won't increase in size too much, so this logic stands.
   */
  LOOKAHEAD_RANGE_MULTIPLIER: 3,
  /**
   * The weight of the score we give the first line in order to increase its importance.
   * We do that since BFL decides on the candidates based on the first line,
   * so we're giving the first line extra weight in the final scoring as well.
   */
  FIRST_LINE_SCORE_WEIGHT: 1.5,
  /**
   * For single line hunks and smart text we want to prefer matches that are closer linearly
   * to the original line number.
   * The max weight we give is ONE_LINE_DIFF_WEIGHT (a candidate in the same line number will
   * get an additional 0.15 to the score). The line will get 0 if the candidate is more than
   * ONE_LINE_DIFF_MAX_LINES lines from the original line.
   */
  ONE_LINE_DIFF_WEIGHT: 0.15,
  ONE_LINE_DIFF_MAX_LINES: 300,
} as const;

export async function autosyncModifiedFileWithoutBlobSha({
  multiDynamicUnit,
  snippetsInFile,
  docRepoId,
  fileRepoId,
  filePath,
  revision,
  shouldSyncCrossRepo,
}: {
  multiDynamicUnit: MultiDynamicPatch;
  snippetsInFile: Snippet[];
  docRepoId: string;
  fileRepoId: string;
  filePath: string;
  revision: string;
  shouldSyncCrossRepo: boolean;
}): Promise<{
  isApplicable: boolean;
  snippetsWithApplicability: SmartElementWithApplicability<Snippet>[];
}> {
  const dynamicUnitFile = multiDynamicUnit[fileRepoId][filePath];
  const hunksToMarkAsVerified: DynamicHunkContainer[] = [];
  const snippetsWithApplicability: SmartElementWithApplicability<Snippet>[] = [];

  for (const hunk of dynamicUnitFile.hunkContainers) {
    // @ts-ignore Hunk has ID at this point
    const correlatingSnippet = getSnippetById({ snippets: snippetsInFile, id: hunk.id });

    // If the hunk is already applicable, we'll just mark it as verified and continue on.
    if (
      await isDynamicFilePatchApplicable({
        dynamicFileDiff: { ...dynamicUnitFile, hunkContainers: [hunk] },
        fileRepoId: hunk.repoId,
        repoId: docRepoId,
        revision,
        shouldSyncCrossRepo,
        filePath,
      })
    ) {
      // In case of a rename - this may have been set previously to AUTOSYNCABLE, and we wouldn't want to override it.
      if (hunk.swimmHunkMetadata?.applicability === ApplicabilityStatus.Autosyncable) {
        snippetsWithApplicability.push(
          getSmartElementWithApplicability<Snippet>({
            element: correlatingSnippet,
            applicabilityStatus: ApplicabilityStatus.Autosyncable,
            newInfo: {
              ...correlatingSnippet,
              filePath: filePath,
            },
          })
        );
      } else {
        hunksToMarkAsVerified.push(hunk);
      }

      continue;
    }

    snippetsWithApplicability.push(
      await autosyncHunkWithoutBlobSha({
        dynamicHunk: hunk,
        snippet: correlatingSnippet,
        // NOTE: We know for certain there's a `fileB` since we're talking about a _modified_ file.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        filePath: dynamicUnitFile.fileNameB!,
        repoId: fileRepoId,
        revision,
      })
    );
  }

  await updatedVerifiedHunksLineNumbers({
    // FileNameA should always have a value
    filePath: dynamicUnitFile.fileNameA ?? '',
    hunkContainers: hunksToMarkAsVerified,
    repoId: fileRepoId,
    revision,
  });

  snippetsWithApplicability.push(
    ...getVerifiedSnippetsFromDynamicHunks({
      snippets: snippetsInFile,
      dynamicHunks: hunksToMarkAsVerified,
    })
  );

  const isApplicable = !dynamicUnitFile.hunkContainers.find(
    (hunk) => hunk.swimmHunkMetadata?.applicability === ApplicabilityStatus.Outdated
  );
  return { isApplicable: isApplicable, snippetsWithApplicability };
}

interface LineCandidate {
  data: string;
  lineNumber: number;
  comparison: number;
}

async function autosyncHunkWithoutBlobSha({
  dynamicHunk,
  snippet,
  filePath,
  repoId,
  revision,
}: {
  dynamicHunk: DynamicHunkContainer;
  snippet: Snippet;
  filePath: string;
  repoId: string;
  revision: string;
}): Promise<SmartElementWithApplicability<Snippet>> {
  const clonedDynamicHunk = objectUtils.deepClone(dynamicHunk);
  try {
    const hunkCandidates = await getMatchingHunkCandidates({
      originalDynamicHunk: clonedDynamicHunk,
      filePath,
      repoId,
      revision,
    });

    // If there are no candidates for the hunk, mark it as outdated.
    if (isEmpty(hunkCandidates)) {
      return getSmartElementWithApplicability<Snippet>({
        element: snippet,
        applicabilityStatus: ApplicabilityStatus.Outdated,
      });
    }

    const hunkCandidatesWithScores = hunkCandidates.map((hunk) => {
      const candidateScore = scoreCandidate(hunk);
      return { ...candidateScore, hunk };
    });
    const chosenCandidate = maxBy(
      hunkCandidatesWithScores,
      (hunk: (typeof hunkCandidatesWithScores)[number]) => hunk.diffScore
    );

    if (!chosenCandidate) {
      /**
       * This error is here since {@link maxBy} supposedly declares it might return `undefined`.
       * However, it should always operate on a non-empty array in our flow, so we're throwing an explicit error if there's an unexpected problem there.
       */
      throw new Error(
        `Failed to get candidate with maximum diffScore. Candidates list length: ${hunkCandidatesWithScores.length}.`
      );
    }

    if (chosenCandidate.applicability !== ApplicabilityStatus.Outdated) {
      const syncedHunk = diffHunkToUnitHunk(chosenCandidate.hunk);
      return getSmartElementWithApplicability<Snippet>({
        element: snippet,
        applicabilityStatus: chosenCandidate.applicability,
        newInfo: {
          ...snippet,
          filePath,
          startLineNumber: syncedHunk.gitHunkMetadata.lineNumbers.fileB.startLine,
          lines: getLinesFromDynamicHunkContainer(syncedHunk),
          gitInfo: snippet.gitInfo ? { ...snippet.gitInfo } : null,
        },
      });
    }
  } catch (err) {
    logger.error({ err }, `Failed to sync hunk`);
  }

  return getSmartElementWithApplicability<Snippet>({
    element: snippet,
    applicabilityStatus: ApplicabilityStatus.Outdated,
  });
}

/**
 * Return the first non-context, non-empty deletion line, or `undefined` otherwise.
 * @param changes array of change lines.
 * @returns the found change line (or `undefined`).
 */
function findFirstChangedLineFromFileA(changes: DynamicChangeLine[]): DynamicChangeLine | undefined {
  return changes.find((line) => line.changeType !== HunkChangeLineType.Context && line.fileA?.data);
}

function getBFLSimilarityThreshold(changes: DynamicChangeLine[]): number {
  const meatLines = changes.filter((line) => line.changeType !== HunkChangeLineType.Context) ?? [];
  return meatLines.length === 1
    ? autosyncWithoutBlobShaConfig.BEST_FIRST_LINE_MIN_DIFF_SCORE_FOR_SINGLE_LINE
    : autosyncWithoutBlobShaConfig.BEST_FIRST_LINE_MIN_DIFF_SCORE_FOR_MULTIPLE_LINES;
}

// The inner logic is in a separate function so we can test it
export async function getMatchingHunkCandidatesForContent({
  clonedDynamicHunk,
  fileBDataLines,
  repoId,
  maxCandidatesWithoutPerfectMatch,
}: {
  clonedDynamicHunk: DynamicHunkContainer;
  fileBDataLines: string[];
  repoId: string;
  maxCandidatesWithoutPerfectMatch?: number;
}) {
  const similarityThreshold = getBFLSimilarityThreshold(clonedDynamicHunk.changes);

  const firstLine = findFirstChangedLineFromFileA(clonedDynamicHunk.changes);
  if (!firstLine) {
    throw new Error('Hunk is made entirely of context lines');
  }
  const fileBDataLinesWithComparison = [...fileBDataLines].map((line, index) => ({
    data: line,
    lineNumber: index + 1,
    comparison: stringUtils.compareDelintedStrings(firstLine.fileA?.data || '', line),
  }));
  // We gather our "first-line candidates" by filtering our lines based on diff-scores and taking the N best ones.
  let fileBFirstLineCandidates: LineCandidate[] = [...fileBDataLinesWithComparison]
    .filter((line) => line.comparison > similarityThreshold)
    .sort((a, b) => b.comparison - a.comparison)
    .slice(0, maxCandidatesWithoutPerfectMatch);
  if (
    fileBFirstLineCandidates.length === maxCandidatesWithoutPerfectMatch &&
    fileBFirstLineCandidates[fileBFirstLineCandidates.length - 1].comparison === 1.0
  ) {
    // All "first lines" are equal, so the first line is probably not an interesting candidate. We should therefore extrapolate to additional candidates.
    fileBFirstLineCandidates = [...fileBDataLinesWithComparison].filter((line) => line.comparison === 1.0);
  }
  // If there are no appropriate line candidates, return an empty array.
  if (isEmpty(fileBFirstLineCandidates)) {
    return [];
  }
  // We're going to clean the context lines for the line matching stage to work
  const cleanChanges = removeSurroundingContextAndEmptyLinesFromChangeLines(clonedDynamicHunk.changes);
  const fileAChanges = cleanChanges.filter((changeLine) => changeLine.fileA);
  // NOTE: Our types aren't configured in a way that will make this `filter` "infer" there'll always be a `fileA` in the next `map`.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

  // After we have some line candidates, it's time to turn them into entire hunks so we'll be able to score them as a whole.
  const lineMatchedHunksCandidates = fileBFirstLineCandidates.map((candidate) =>
    getLineMatcherHunksFromLineCandidates({
      fileAChanges,
      fileBLines: fileBDataLines,
      fileBLineCandidate: candidate,
      repoId,
    })
  );
  return lineMatchedHunksCandidates;
}

export async function getMatchingHunkCandidates({
  originalDynamicHunk,
  filePath,
  repoId,
  revision,
  maxCandidatesWithoutPerfectMatch = autosyncWithoutBlobShaConfig.MAX_FIRST_LINE_CANDIDATES,
}: {
  originalDynamicHunk: DynamicHunkContainer;
  filePath: string;
  repoId: string;
  revision: string;
  maxCandidatesWithoutPerfectMatch?: number;
}): Promise<DynamicHunkContainer[]> {
  const clonedDynamicHunk = objectUtils.deepClone(originalDynamicHunk);

  // For each of the files in `fileB` (our target), we're going to rank all the lines according to how closely they match our first original meat line.
  const fileBDataLines = (
    await gitwrapper.getFileContentFromRevision({ filePath, repoId: clonedDynamicHunk.repoId, revision })
  ).split('\n');
  return await getMatchingHunkCandidatesForContent({
    clonedDynamicHunk,
    fileBDataLines,
    repoId,
    maxCandidatesWithoutPerfectMatch,
  });
}

/**
 * Return whether or not the line indicates a change (not "context") and it has actual data (not an empty string).
 * @param line a dynamic change line.
 * @returns boolean indicator.
 */
function isLineNonEmptyChange(line: DynamicChangeLine): boolean {
  const lineNotContext: boolean = line.changeType !== HunkChangeLineType.Context;
  const lineDataNotEmpty: boolean = (line.fileA?.data || line.fileB?.data) !== undefined;
  return lineNotContext && lineDataNotEmpty;
}

function removeSurroundingContextAndEmptyLinesFromChangeLines(changes: DynamicChangeLine[]): DynamicChangeLine[] {
  const firstNonContextLineIndex = changes.findIndex(isLineNonEmptyChange);
  const lastNonContextLineIndex = findLastIndex(changes, isLineNonEmptyChange);
  return changes.slice(firstNonContextLineIndex, lastNonContextLineIndex + 1);
}

function getLineMatcherHunksFromLineCandidates({
  fileAChanges,
  fileBLines,
  fileBLineCandidate,
  repoId,
}: {
  fileAChanges: DynamicChangeLine[];
  fileBLines: string[];
  fileBLineCandidate: LineCandidate;
  repoId: string;
}): DynamicHunkContainer {
  // We limit the range of lines we search in by X times the length of the original content.
  const relevantFileBLines = fileBLines.slice(
    fileBLineCandidate.lineNumber - 1,
    fileBLineCandidate.lineNumber - 1 + fileAChanges.length * autosyncWithoutBlobShaConfig.LOOKAHEAD_RANGE_MULTIPLIER
  );
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const contentA = fileAChanges.map((changeLine) => changeLine.fileA!.data).join('\n');
  const contentB = relevantFileBLines.join('\n');
  /**
   * NOTE: this function uses JSDiff which _doesn't_ use a similarity-index in order to preform line matching when creating the diff,
   *  but rather is biased towards exact matches.
   * For this reason, we cut the number of lines we're searching in (see above).
   */
  const dynamicHunkResult = similarityDiff.createMatchedHunkBasedOnSimilarity({
    fileContentA: contentA,
    fileContentB: contentB,
    repoId,
  });
  const dynamicHunkWithFixedLines = changeDynamicHunkContanierStartingLineNumber(
    dynamicHunkResult,
    fileBLineCandidate.lineNumber
  );
  const extractedChanges = extractFileAMatchedLines(dynamicHunkWithFixedLines.changes);
  // @ts-ignore there should always be a `fileA` after we extract the changes above.
  extractedChanges[0].fileA.actualLineNumber = fileAChanges[0].fileA.actualLineNumber;
  return { ...dynamicHunkWithFixedLines, changes: extractedChanges };
}

function extractFileAMatchedLines(changes: DynamicChangeLine[]): DynamicChangeLine[] {
  const indexOfFirstChange = changes.findIndex((change) => !!change.fileA);
  if (indexOfFirstChange === -1) {
    return changes.slice();
  }
  const indexOfLastChange = findLastIndex(changes, (change: DynamicChangeLine) => !!change.fileA);
  if (indexOfLastChange === -1) {
    return changes.slice();
  }
  return changes.slice(indexOfFirstChange, indexOfLastChange + 1);
}

enum ScoreType {
  Constant,
  Similarity,
}

interface ScoreBase {
  type: ScoreType;
  weight?: number;
}

interface ScoreConstant extends ScoreBase {
  type: ScoreType.Constant;
  score: number;
}

interface ScoreSimilarity extends ScoreBase {
  type: ScoreType.Similarity;
  delinted: number;
  raw: number;
}

type Score = ScoreConstant | ScoreSimilarity;

export function scoreCandidate(dynamicHunkCandidate: DynamicHunkContainer): {
  applicability: ApplicabilityStatus;
  diffScore: number;
} {
  let hunkChanged = false;
  const filteredChanges = dynamicHunkCandidate.changes.filter((line) => {
    switch (line.changeType) {
      case HunkChangeLineType.Added: {
        hunkChanged = true;
        return !shouldIgnoreLineContentForScore(line.fileB.data);
      }
      case HunkChangeLineType.Deleted: {
        hunkChanged = true;
        return !shouldIgnoreLineContentForScore(line.fileA.data);
      }
      case HunkChangeLineType.Context: {
        return !shouldIgnoreLineContentForScore(line.fileA.data);
      }
      default: {
        hunkChanged = true;
        return !(shouldIgnoreLineContentForScore(line.fileA.data) && shouldIgnoreLineContentForScore(line.fileB.data));
      }
    }
  });
  const { additionScore, deletionScore, firstLineWeight } = getScoreParametersPerHunk(filteredChanges);
  const scoreArray: Score[] = filteredChanges.map((line) => {
    switch (line.changeType) {
      case HunkChangeLineType.Added: {
        return { type: ScoreType.Constant, score: additionScore };
      }
      case HunkChangeLineType.Deleted: {
        return { type: ScoreType.Constant, score: deletionScore };
      }
      case HunkChangeLineType.Context: {
        return { type: ScoreType.Constant, score: autosyncWithoutBlobShaConfig.CONTEXT_LINE_DIFF_SCORE };
      }
      default: {
        // Since we know the file wasn't added or deleted, this means both `fileA` and `fileB` will exist.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const lineA = line.fileA!.data;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const lineB = line.fileB!.data;
        // We need both a `delinted` and `raw` scores because of the way our delinting works. Consider the following state:
        //  We compare the strings after delinting them and then the similarity index is 1 (indicating an exact match).
        //  Will this be an exact match of the non-delinted version as well? We cannot know for sure!
        //  For this reason we need scores for both cases: delinted and raw, in order to differentiate between "no-change" and "delint".
        const raw = stringUtils.normalizedLevenshteinDistance(lineA, lineB);
        const delinted = stringUtils.compareDelintedStrings(lineA, lineB);
        return { type: ScoreType.Similarity, delinted, raw };
      }
    }
  });
  /** We increase the weight of the first line, for more info look at {@link autosyncWithoutBlobShaConfig.FIRST_LINE_SCORE_WEIGHT} */
  scoreArray[0].weight = firstLineWeight;
  // Get the weighted averages of both the delinted and raw scores.
  const diffScoreAverages = getAverageScores(scoreArray);
  const scoreToReturn = getScoreForSingleLineIfNeeded({
    dynamicHunkCandidate,
    delintedScore: diffScoreAverages.delintedAvg,
  });
  if (diffScoreAverages.delintedAvg === 1) {
    // If all lines have a raw similarity of 1 and nothing changed, we can simply return `verified`.
    if (diffScoreAverages.rawAvg === 1 && !hunkChanged) {
      return { applicability: ApplicabilityStatus.Verified, diffScore: scoreToReturn };
    }
    // If the raw similarity isn't equal to 1 it means that delinting made a difference, hence it's `autosyncable`.
    return { applicability: ApplicabilityStatus.Autosyncable, diffScore: scoreToReturn };
  } else if (diffScoreAverages.delintedAvg > autosyncWithoutBlobShaConfig.HUNK_DIFF_AUTOSYNC_THRESHOLD) {
    return { applicability: ApplicabilityStatus.Autosyncable, diffScore: scoreToReturn };
  } else {
    return { applicability: ApplicabilityStatus.Outdated, diffScore: scoreToReturn };
  }
}

export function diffHunkToUnitHunk(hunk: DynamicHunkContainer): DynamicHunkContainer {
  const startLine = hunk.changes[0].fileB.actualLineNumber;
  hunk.changes = hunk.changes
    .filter((change: DynamicChangeLine) => change.changeType !== HunkChangeLineType.Deleted)
    .map((change: DynamicChangeLine) => {
      return {
        changeType: HunkChangeLineType.Deleted,
        fileA: { actualLineNumber: change.fileB.actualLineNumber, data: change.fileB.data },
      };
    });
  hunk.gitHunkMetadata.lineNumbers.fileA.startLine = startLine;
  hunk.gitHunkMetadata.lineNumbers.fileB.startLine = startLine;
  return hunk;
}

export function shouldIgnoreLineContentForScore(lineData: string): boolean {
  const replaced = lineData.replace(/[\s;(){}[\]]/g, '');
  return replaced === '';
}

/**
 * Get the average scores for both the raw and delinted values of the score objects (with optional weights considered).
 * NOTE: the default weight is 1.
 * @param scores score objects to be averaged out.
 * @returns the averages of both the raw and delinted values.
 */
function getAverageScores(scores: Score[]): { rawAvg: number; delintedAvg: number } {
  const { rawSum, delintedSum, weightSum } = scores.reduce(
    (accumulator: { rawSum: number; delintedSum: number; weightSum: number }, score) => {
      const currentWeight = score.weight ?? 1;
      const rawSum = accumulator.rawSum + (score.type === ScoreType.Constant ? score.score : score.raw) * currentWeight;
      const delintedSum =
        accumulator.delintedSum + (score.type === ScoreType.Constant ? score.score : score.delinted) * currentWeight;
      const weightSum = accumulator.weightSum + currentWeight;
      return { rawSum, delintedSum, weightSum };
    },
    {
      rawSum: 0,
      delintedSum: 0,
      weightSum: 0,
    }
  );
  if (weightSum === 0) {
    // This case **shouldn't** happen, but just in case it does, we'll avoid dividing by 0.
    logger.warn(`Weighted sum of all ${scores.length} scores is 0.`);
    return { rawAvg: 0, delintedAvg: 0 };
  }
  return { rawAvg: rawSum / weightSum, delintedAvg: delintedSum / weightSum };
}

/**
 * Only when it's a single line hunk
 * Give a higher score to the closest candidate
 * @param dynamicHunkCandidate the hunk container we are currently scoring
 * @param delintedScore the delinted score we have till this point
 */
function getScoreForSingleLineIfNeeded({
  dynamicHunkCandidate,
  delintedScore,
}: {
  dynamicHunkCandidate: DynamicHunkContainer;
  delintedScore: number;
}) {
  if (dynamicHunkCandidate.changes.length !== 1) {
    return delintedScore;
  }

  const change = dynamicHunkCandidate.changes[0];
  if (!change.fileA || !change.fileB) {
    return delintedScore;
  }

  const diff = Math.min(
    autosyncWithoutBlobShaConfig.ONE_LINE_DIFF_MAX_LINES,
    Math.abs(change.fileB.actualLineNumber - change.fileA.actualLineNumber)
  );

  const diffScore = 1 - diff / autosyncWithoutBlobShaConfig.ONE_LINE_DIFF_MAX_LINES;

  return (
    delintedScore * (1 - autosyncWithoutBlobShaConfig.ONE_LINE_DIFF_WEIGHT) +
    diffScore * autosyncWithoutBlobShaConfig.ONE_LINE_DIFF_WEIGHT
  );
}

function getScoreParametersPerHunk(changes: DynamicChangeLine[]): {
  additionScore: number;
  deletionScore: number;
  firstLineWeight: number;
} {
  const numFileALines = changes.filter((line) => has(line, 'fileA')).length;
  function getRank(rank: number): number {
    return Math.min(autosyncWithoutBlobShaConfig.HUNK_DIFF_AUTOSYNC_THRESHOLD - rank, rank * numFileALines);
  }
  const additionScore = getRank(autosyncWithoutBlobShaConfig.ADDED_LINE_SCORE_RANK);
  const deletionScore = getRank(autosyncWithoutBlobShaConfig.DELETED_LINE_SCORE_RANK);
  const firstLineWeight = numFileALines > 2 ? autosyncWithoutBlobShaConfig.FIRST_LINE_SCORE_WEIGHT : 1;
  return { additionScore, deletionScore, firstLineWeight };
}

export async function updatedVerifiedHunksLineNumbers({
  filePath,
  hunkContainers,
  repoId,
  revision,
}: {
  filePath: string;
  hunkContainers: DynamicHunkContainer[];
  repoId: string;
  revision: string;
}) {
  if (hunkContainers.length === 0) {
    return;
  }

  // file data has to exist because hunk was ruled as verified
  const fileData = await gitwrapper.getFileContentFromRevision({ filePath, repoId, revision });
  for (const hunk of hunkContainers) {
    try {
      // The hunk is verified so there needs to be at least one match
      const match = getMeatMatchesInFile({ fileData, hunk, filePath, repoId }).matches[0];
      const meatStartLine = fileData.slice(0, match.index).split('\n').length;
      const meatStartIndex = hunk.changes.findIndex((change) => change.changeType === HunkChangeLineType.Deleted);
      const contextStartLine = meatStartLine - meatStartIndex;
      // Using this we later update the line numbers in the SWM object
      // Change this when deprecating gitHunkMetadata
      hunk.gitHunkMetadata.lineNumbers.fileB.startLine = contextStartLine;
    } catch (err) {
      logger.warn({ err }, `Failed to run regex for verified hunk`);
    }
  }
}

function getVerifiedSnippetsFromDynamicHunks({
  snippets,
  dynamicHunks,
}: {
  snippets: Snippet[];
  dynamicHunks: DynamicHunkContainer[];
}): SmartElementWithApplicability<Snippet>[] {
  const snippetsWithApplicability: SmartElementWithApplicability<Snippet>[] = [];
  for (const hunk of dynamicHunks) {
    // @ts-ignore DynamicHunkContainer always has ID at this point
    const correlatedSnippet = getSnippetById({ snippets, id: hunk.id });
    snippetsWithApplicability.push(
      getSmartElementWithApplicability<Snippet>({
        element: correlatedSnippet,
        applicabilityStatus: ApplicabilityStatus.Verified,
        newInfo: { ...correlatedSnippet, startLineNumber: hunk.gitHunkMetadata.lineNumbers.fileB.startLine },
      })
    );
  }

  return snippetsWithApplicability;
}
