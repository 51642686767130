<script setup lang="ts">
import BaseTooltip from '../../components/BaseTooltip/BaseTooltip.vue';
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';

const emit = defineEmits<{
  simple: [];
}>();

function onSimple() {
  emit('simple');
}
</script>

<template>
  <BaseTooltip class="swm-selection-content-token-advanced-header-additional" content="Simple mode">
    <BaseIcon class="swm-selection-content-token-advanced-header-additional__simple" name="shrink" @click="onSimple" />
  </BaseTooltip>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-selection-content-token-advanced-header-additional {
  $self: &;

  @include basic-resets;

  &__simple {
    cursor: pointer;
    font-size: var(--font-size-default);
  }
}
</style>
