<script setup lang="ts">
import type { Editor } from '@tiptap/vue-3';
import { onMounted } from 'vue';
import { eventLogger, getLoggerNew, productEvents } from '@swimm/shared';
import type { SwText } from '@swimm/ui';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';

const logger = getLoggerNew(__modulename);

const SNIPPET_LENGTH_LIMIT = 2000;

const props = withDefaults(
  defineProps<{
    editor: Editor;
    hide: () => void;
  }>(),
  {
    hide: undefined,
  }
);

const emit = defineEmits<{
  generated: [content: string];
}>();

const swimmEditorServices = getSwimmEditorServices(props.editor);

function showErrorToast() {
  swimmEditorServices.external.showNotification(`Failed to generate comment. Try again.`, {
    icon: 'warning',
  });
}

function getSnippet(): { file: string; lines: string[] } | null {
  const $pos = props.editor.state.doc.resolve(props.editor.state.selection.from);
  const snippetNode = $pos.node(-1);
  if (!snippetNode || snippetNode.type.name !== 'swmSnippet') {
    return null;
  }

  return { file: snippetNode.attrs.path, lines: snippetNode.attrs.snippet.split('\n') };
}

async function generateComment() {
  try {
    if (!props.editor || !swimmEditorServices.workspaceId.value) {
      showErrorToast();
      return;
    }

    const snippetData = getSnippet();
    if (!snippetData) {
      showErrorToast();
      return;
    }

    if (snippetData.file.includes('swimmagic') || snippetData.file.includes('code-analysis')) {
      emit('generated', 'This request is forbidden by an admin. This incident will be reported 👮🏻');
      return;
    }

    const snippetContent = snippetData.lines
      .filter((line) => !!line?.trim())
      .join('\n')
      .slice(0, SNIPPET_LENGTH_LIMIT);

    const start = performance.now();

    const response = await swimmEditorServices.external.generateSnippetComment({
      repoId: swimmEditorServices.repoId.value || '',
      workspaceId: swimmEditorServices.workspaceId.value,
      snippetContent,
    });

    const timeTaken = performance.now() - start;

    if (response.status !== 'success' || !response.generatedText?.trim()) {
      showErrorToast();
      return;
    }

    emit('generated', response.generatedText.trim());

    swimmEditorServices.external.trackEvent(productEvents.ADDED_GEN_AI_CONTENT, {
      'Generation Time Duration (ms)': `${timeTaken}`,
      'Output Length (Chars)': `${length}`,
      Context: 'Snippet Comment',
      backofficeCode: eventLogger.SWIMM_EVENTS.ADDED_GEN_AI_CONTENT.code,
    });
  } catch (err) {
    logger.error({ err }, 'Failed generating snippet comment');
    showErrorToast();
  }
}

onMounted(async () => {
  await generateComment();
  props.hide?.();
});

function stop() {
  swimmEditorServices.external.trackEvent(productEvents.ABORTED_GEN_AI_CONTENT_REQUEST, { Context: 'Snippet Comment' });
  props.hide?.();
}
</script>

<template>
  <div class="ai-generate-snippet-comment-popover" @keydown="stop">
    <SwText class="generating-title" variant="subtitle-S">Generating...</SwText>
    <div class="shortcuts">
      <div class="key-label-pair">
        <div>Type anything to cancel</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.ai-generate-snippet-comment-popover {
  border: 1px solid var(--color-border-default);
  border-radius: var(--space-xsmall);
  background: var(--color-bg-default);

  .generating-title {
    padding: var(--space-base);
    margin-left: var(--space-base);
    color: var(--text-color-secondary);
  }

  .shortcuts {
    background-color: var(--color-surface);
    padding: var(--space-base) var(--space-sm);
    color: var(--text-color-secondary);

    .key-label-pair {
      gap: var(--space-base);
      display: inline-flex;
      margin-right: var(--space-sm);
    }
  }
}
</style>
