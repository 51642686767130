import XRegExp from 'xregexp';

export enum RegexType {
  regex = 'regex',
  wordRegex = 'word-regex',
}

export enum RegexModifiers {
  caseInsensitive = 'i',
  multilineSearch = 'm',
}

export function makeRegex(regexType: RegexType, pattern: string, modifiers?: RegexModifiers[]): RegExp {
  const regexModifier = modifiers === undefined ? '' : modifiers.join('');

  switch (regexType) {
    case RegexType.wordRegex:
      return XRegExp('\\b' + pattern + '\\b', regexModifier);
    default:
      return XRegExp(pattern, regexModifier);
  }
}

export const newlinePattern = /(\r\n|\n|\r)/gm;
