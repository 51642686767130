import { ApplicabilityStatus, SwmCellType, SwmFile } from '../types';
import { getLogger } from '../logger/legacy-shim';

type SingleWordResult = {
  $or: [{ name: string }, { content: string }];
};

type MultipleWordsResult = {
  $and?: Array<SingleWordResult | MultipleWordsResult>;
  $or?: Array<SingleWordResult | MultipleWordsResult>;
};

const logger = getLogger("packages/shared/src/utils/swm-search.ts");

const linkRegex = /\[\[sym(?:-([^:]+))?:(?:(.+?)\(([-0-9a-zA-Z|]+)\)|\(([-0-9a-zA-Z|]+)\)(.+?))\]\]/g;
const markdownCommentRegex = /<!--.*-->\n/g;

export function getFuseOptions(options?: { query?: string }) {
  return {
    keys: [
      { name: 'name', weight: 100 },
      { name: 'content', weight: 1 },
    ],
    includeScore: true,
    includeMatches: true,
    minMatchCharLength: getFuseMinMatchCharLength(options?.query ?? ''),
    ignoreLocation: true,
    threshold: 0.05,
  };
}

export function getFuseMinMatchCharLength(query: string): number {
  const minLength = Math.min(
    ...query
      .trim()
      .split(/\s+/)
      .map((s) => s.length)
  );
  return Math.max(Math.min(minLength, 5), 2);
}

export function getFuseQuery(query: string): {
  $and?: Array<SingleWordResult>;
  $or?: Array<MultipleWordsResult>;
} {
  const words = query
    .trim()
    .split(/\s+/)
    .filter((w) => w.length > 1);

  // Documents where:
  // The word must be present in the name OR content fields
  if (words.length === 1) {
    return {
      $and: [wordToOr(words[0])],
    };
  } else {
    return {
      $or: [
        {
          // Documents where:
          // Each word in the 'words' array must be present in the name OR content fields.
          // This means the name or content contains all the words separately.
          $and: words.map(wordToOr),
        },
        {
          // Documents where:
          // The name or content contains the exact phrase formed by joining all words in the 'words' array.
          $or: [wordToOr(words.join(' '))],
        },
      ],
    };
  }
}

function wordToOr(s: string): SingleWordResult {
  return { $or: [{ name: s }, { content: s }] };
}

export type IdeSearchResult = {
  id: string;
  path: string;
  name: string;
  score: number;
  applicability: ApplicabilityStatus | undefined;
  matches: ReadonlyArray<{
    key: string;
    value: string;
    indices: ReadonlyArray<[number, number]>;
  }>;
};

export function textualizeSwmContent(content: SwmFile['content']) {
  const textualSwm: string[] = [];
  for (const cell of content) {
    try {
      switch (cell.type) {
        case SwmCellType.Text: {
          textualSwm.push(transformText(cell.text));
          break;
        }
        case SwmCellType.Snippet: {
          if (cell.comments && cell.comments.length > 0) {
            textualSwm.push(transformText(cell.comments[0]));
          }
          break;
        }
      }
    } catch (error) {
      logger.debug(`Could not parse cell during indexing of search: ${error}`);
    }
  }
  return textualSwm;
}

function transformText(text: string): string {
  const transformers = [transformLinks, transformMarkdownComments];
  let transformedText = text;
  for (const transformer of transformers) {
    try {
      transformedText = transformer(transformedText);
    } catch (error: unknown) {
      logger.error(`Failed to transform using function "${transformer.name}": ${error}`);
    }
  }
  return transformedText;
}

function transformLinks(text: string): string {
  return text.replaceAll(linkRegex, (matchStr, linkType, linkText, symbolId, symbolIdAlt, linkTextAlt) => {
    const resolvedLinkText: string = linkText || linkTextAlt || '';
    return resolvedLinkText;
  });
}

function transformMarkdownComments(text: string): string {
  return text.replaceAll(markdownCommentRegex, '');
}

function squeezeSpaces(text: string) {
  return text.replace(/\s+/g, ' ');
}

export function unifyMatchesByValueAndSplit(
  matches: Array<{ value: string; indices: ReadonlyArray<[number, number]> }>,
  options: { truncate: boolean }
): Array<Array<{ text: string; highlighted: boolean }>> {
  return unifyMatchesByValue(matches).map((match) => splitMatchToHighlights(match, options));
}

function unifyMatchesByValue(
  matches: Array<{ value: string; indices: ReadonlyArray<[number, number]> }>
): Array<{ value: string; indices: ReadonlyArray<[number, number]> }> {
  const valuesSoFar = {};
  const unifiedMatches = [];
  for (const match of matches) {
    if (!valuesSoFar[match.value]) {
      const m = { value: match.value, indices: [...match.indices] };
      unifiedMatches.push(m);
      valuesSoFar[match.value] = m;
    } else {
      valuesSoFar[match.value].indices.push(...match.indices);
    }
  }
  return unifiedMatches;
}

function fixIndices(indices: ReadonlyArray<[number, number]>): ReadonlyArray<[number, number]> {
  // Sort the array of pairs by the first element of each pair
  const sortedIndices = [...indices].sort((a, b) => a[0] - b[0]);

  // Initialize a new array to store the unified pairs
  const unifiedIndices: [number, number][] = [];

  // Start with the first pair in the sorted array
  unifiedIndices.push(sortedIndices[0]);

  // For each subsequent pair in the sorted array
  for (let i = 1; i < sortedIndices.length; i++) {
    const lastUnifiedPair = unifiedIndices[unifiedIndices.length - 1];
    const currentPair = sortedIndices[i];

    // If the start of the current pair is less than or equal to the end of the last pair in the new array
    if (currentPair[0] <= lastUnifiedPair[1]) {
      // Update the end of the last pair in the new array to be the maximum of its current end and the end of the current pair
      lastUnifiedPair[1] = Math.max(lastUnifiedPair[1], currentPair[1]);
    } else {
      // Otherwise, add the current pair to the new array
      unifiedIndices.push(currentPair);
    }
  }

  return unifiedIndices;
}

/* 
  the function get a string and list of indices
  indices are the parts to highligh (start index to last index)
  return list of {text, highlighted }
*/
function splitMatchToHighlights(
  {
    value,
    indices,
  }: {
    value: string;
    indices: ReadonlyArray<[number, number]>;
  },
  options: { truncate: boolean }
): Array<{ text: string; highlighted: boolean }> {
  const fixedIndices = fixIndices(indices);
  const result: ReturnType<typeof splitMatchToHighlights> = [];
  let lastEnd = 0;
  for (const [start, end] of fixedIndices) {
    result.push({ text: squeezeSpaces(value.slice(lastEnd, start)), highlighted: false });
    result.push({ text: squeezeSpaces(value.slice(start, end + 1)), highlighted: true });
    lastEnd = end + 1;
  }
  result.push({ text: squeezeSpaces(value.slice(lastEnd, value.length)), highlighted: false });
  if (options.truncate) {
    for (let i = 0; i < result.length; i++) {
      const curResult = result[i];
      const tokensContextLength = result.length <= 3 ? 10 : 5;
      if (!curResult.highlighted) {
        const curResultTokens = curResult.text.split(/\s+/);
        let prefixText = '';
        let suffixText = '';
        let sep = '';
        if (i === 0 && curResultTokens.length > tokensContextLength) {
          prefixText = '';
          sep = '...';
          suffixText = curResultTokens.slice(-tokensContextLength).join(' ');
        }
        if (i === result.length - 1 && curResultTokens.length > tokensContextLength) {
          prefixText = curResultTokens.slice(0, tokensContextLength).join(' ');
          suffixText = '';
          sep = '';
        }
        if (i > 0 && i < result.length - 1 && curResultTokens.length > 2 * tokensContextLength + 1) {
          prefixText = curResultTokens.slice(0, tokensContextLength).join(' ');
          suffixText = curResultTokens.slice(-tokensContextLength).join(' ');
          sep = ' ... ';
        }
        if (prefixText || suffixText) {
          curResult.text = prefixText + sep + suffixText;
        }
      }
    }
  }
  return result;
}
