<script setup lang="ts">
import { SwText } from '@swimm/ui';
import { useSlots } from 'vue';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { storeToRefs } from 'pinia';

const { isSidebarFavoritesExpanded } = storeToRefs(useWorkspaceStore());

const slots = useSlots();

const toggleCollapsed = () => {
  isSidebarFavoritesExpanded.value = !isSidebarFavoritesExpanded.value;
};
</script>

<template>
  <div class="favorites-section" :class="{ expanded: isSidebarFavoritesExpanded }">
    <div class="favorites-title" @click="toggleCollapsed">
      <SwText variant="subtitle-L" class="home-text">Favorites & recents</SwText>
      <Icon name="arrow-right" class="collapse-icon" no-padding />
    </div>
    <div class="content">
      <slot name="favorites" />
      <slot name="recents" />
      <div v-if="!slots.favorites && !slots.recents" class="empty-state">
        <SwText variant="body-S" class="empty-message"
          >No favorites yet. Add repos to your favorites to access them quickly.</SwText
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.favorites-section {
  margin-bottom: var(--space-base);

  .favorites-title {
    padding: 0 var(--space-sm);
    height: var(--sidebar-item-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .home-text {
      padding: 0 var(--space-xs);
    }
  }

  .empty-state {
    .empty-message {
      padding: var(--space-base) var(--space-md);
      color: var(--text-color-secondary);
      line-height: 1.5rem;
    }
  }

  .collapse-icon {
    color: var(--text-color-secondary);
    font-size: var(--body-S);
  }

  &.expanded .collapse-icon {
    transform: rotate(90deg);
  }

  &:not(.expanded) .content {
    display: none;
  }
}
</style>
