<template>
  <BaseBlock variant="danger" class="banner-container" data-testid="review-autosync-banner">
    <BaseLayoutGap size="xsmall" direction="column" alignment="left">
      <BaseHeading :level="5" class="title" data-testid="review-autosync-title">
        <span data-testid="review-counter-amount">{{ reviewNumber > 1 ? reviewNumber : 'A' }}</span>
        {{ title }}</BaseHeading
      ><BaseProse size="small" data-testid="review-autosync-description">{{ description }}</BaseProse
      ><BaseButton
        v-if="!isEditMode"
        variant="link"
        data-testid="review-outdated-button"
        @click="handleActionClicked"
        class="review-outdated-button"
        >Review Outdated</BaseButton
      ></BaseLayoutGap
    >
  </BaseBlock>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue';
import { BaseBlock, BaseButton, BaseHeading, BaseLayoutGap, BaseProse } from '@swimm/reefui';
const props = defineProps<{ reviewNumber: number; isEditMode: boolean }>();

const title = computed(() => {
  if (props.reviewNumber > 1) {
    return 'code elements have changed.';
  } else {
    return 'code element has changed.';
  }
});

const description = computed(() => {
  if (props.reviewNumber > 1) {
    return 'Fix & commit relevant instances to keep the doc up to date';
  } else {
    return 'Fix & commit the relevant instance to keep the doc up to date';
  }
});

const goToEditDoc = inject('navigateToEditDoc', () => {
  return;
});
function handleActionClicked() {
  goToEditDoc();
}
</script>
