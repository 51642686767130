import * as firestore from '@/adapters-common/firestore-wrapper';
import { config } from '@swimm/shared';

export enum OptionNames {
  RENAME = 'Rename',
  REMOVE = 'Remove from doc',
  DELETE = 'Delete',
}

export interface DocTagItem {
  color: string;
  created: string;
  creator: string;
  id: string;
  last_modifier: string;
  last_updated: string;
  name: string;
}

export const options = [
  { name: OptionNames.RENAME, icon: 'edit-outline', identifier: 'tag-opt-rename' },
  { name: OptionNames.REMOVE, icon: 'close', identifier: 'tag-opt-remove' },
  { name: OptionNames.DELETE, icon: 'trash-outline', identifier: 'tag-opt-delete' },
] as const;

export const TAG_COLORS = ['purple', 'green', 'yellow', 'orange', 'blue'];

export function compareTagNames(firstTag: DocTagItem, secondTag: DocTagItem) {
  if (firstTag.name.toLowerCase() < secondTag.name.toLowerCase()) {
    return -1;
  }
  if (firstTag.name.toLowerCase() > secondTag.name.toLowerCase()) {
    return 1;
  }
  return 0;
}

export async function renameTag(workspaceId: string, tagId: string, text: string, uid: string): Promise<void> {
  await firestore.updateDocInSubCollection(
    firestore.collectionNames.WORKSPACES,
    workspaceId,
    firestore.collectionNames.TAGS,
    tagId,
    { name: text, last_updated: firestore.firestoreTimestampNow(), last_modifier: uid }
  );
}

export async function addTagToDoc({
  repoId,
  docId,
  tagId,
}: {
  repoId: string;
  docId: string;
  tagId: string;
}): Promise<void> {
  const response = await firestore.updateDocInSubCollection(
    firestore.collectionNames.REPOSITORIES,
    repoId,
    firestore.collectionNames.SWIMMS,
    docId,
    { tags: await firestore.firestoreArrayUnion(tagId) }
  );
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    throw new Error(
      `Could not add existing tag: ${tagId} to doc: ${docId} in repo: ${repoId}. ${response.errorMessage}`
    );
  }
}

export async function addNewTagToWorkspace(workspaceId: string, tagName: string, uid: string) {
  const colorIndex = Math.floor(Math.random() * TAG_COLORS.length);
  const response = await firestore.addDocToSubCollection(
    firestore.collectionNames.WORKSPACES,
    workspaceId,
    firestore.collectionNames.TAGS,
    {
      name: tagName,
      last_updated: firestore.firestoreTimestampNow(),
      created: firestore.firestoreTimestampNow(),
      creator: uid,
      last_modifier: uid,
      color: TAG_COLORS[colorIndex],
    }
  );
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    throw new Error(`Could not add new tag named: ${tagName} to workspace: ${workspaceId}. ${response.errorMessage}`);
  }
  return response.data;
}
