<script setup lang="ts">
import { Icon } from '@swimm/ui';

defineEmits(['back']);

const PLUGINS = [
  {
    name: 'VS code',
    thumbnail: 'VSCode.png',
    downloadLink: 'https://marketplace.visualstudio.com/items?itemName=Swimm.swimm',
  },
  {
    name: 'IntelliJ',
    thumbnail: 'IntelliJ.png',
    downloadLink: 'https://plugins.jetbrains.com/plugin/20716-swimm',
  },
];

function getThumbnailImgSrc(thumbnailName: string): string {
  return new URL(`../../assets/${thumbnailName}`, import.meta.url).href;
}
</script>

<template>
  <div>
    <SwText variant="headline2" class="title"
      ><Icon name="back-browsing" no-padding class="back-icon" @click="$emit('back')" />Download Swimm plugins</SwText
    >
    <SwText variant="body-L" class="subtitle">Enjoy reading docs without leaving your IDE</SwText>
  </div>
  <div>
    <SwText variant="body-S" class="plugins-title">Which text editor are you downloading plugin for?</SwText>
    <div class="plugins">
      <div v-for="plugin in PLUGINS" :key="plugin.name">
        <a :href="plugin.downloadLink" target="_blank">
          <div class="plugin-thumbnail">
            <img :src="getThumbnailImgSrc(plugin.thumbnail)" :alt="plugin.name" />
          </div>
        </a>
        <SwText variant="body-S" class="subtitle">{{ plugin.name }}</SwText>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.title {
  display: flex;
  align-items: center;
  gap: var(--space-md);

  .back-icon {
    cursor: pointer;
  }
}

.subtitle {
  color: var(--text-color-secondary);
}

.plugins-title {
  margin-bottom: var(--space-sm);
}

.plugins {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-base);

  .plugin-thumbnail {
    width: 80px;
    height: 80px;
    border: 2px solid var(--color-border-default-subtle);
    border-radius: 16px;
    margin-bottom: var(--space-xs);
    padding: var(--space-sm);
    box-sizing: border-box;

    &:hover {
      border-color: var(--color-border-brand);
      background-color: var(--color-hover);
    }

    img {
      max-width: 100%;
    }
  }
}
</style>
