import type { Timestamp } from 'firebase/firestore';
import { type GitProviderName } from './gitwrapper-types';

// upgrade this number if you want to force update
// of all cross repo refs on the first merge to main
// even if this pr contains no swimm docs
export const CROSS_REPO_REFS_VERSION = 2;

export interface DBRepo {
  name: string;
  id: string;
  provider: GitProviderName;
  integrations: Record<string, string | boolean | string[]>;
  url: string;
  api_url?: string;
  tenant_id?: string;
  last_cross_repo_refs_updated_at?: Timestamp;
  last_cross_repo_refs_version?: number;
  owner?: string;
}

export interface Repo extends Omit<DBRepo, 'url'> {
  url?: string; // Always in the DB, but marked as optional because the type sometimes used for the "local-state" repo that doesn't include the url
  disabled?: boolean;
  defaultBranch?: string;
  is_private?: boolean;
}

export interface WorkspaceRepo extends Omit<DBRepo, 'url'> {
  isFavourite?: boolean;
  isRecent?: boolean;
}

export interface WorkspaceRepoWithDisabled extends WorkspaceRepo {
  disabled?: boolean;
}

export interface RepoIntegrations extends RepoCIIntegrations {
  ai_snippet_comments_enabled?: boolean;
  generative_ai_enabled?: boolean;
  generative_ai_share_logs_enabled?: boolean;
  share_internally_enabled?: boolean;
  commit_images_to_repo?: boolean;
}

export interface RepoCIIntegrations {
  automatic_autosync_approval?: boolean;
  no_draft_automatic_autosync_approval?: boolean;
  verify_only_modified?: boolean;
  delete_comments_disabled?: boolean;
  github_app_comments_disabled?: boolean;
  github_app_recommended_comments_disabled?: boolean;
  custom_ci_commit_msg?: string | null;
}

export const RepoCIIntegrationsToDefaultValues = {
  automatic_autosync_approval: false,
  no_draft_automatic_autosync_approval: false,
  verify_only_modified: true,
  delete_comments_disabled: false,
  github_app_comments_disabled: false,
  github_app_recommended_comments_disabled: false,
  custom_ci_commit_msg: null,
};
