<script setup lang="ts">
import { ref, useSlots } from 'vue';
import { Icon } from '@swimm/ui';
import { REPO_FILTERS_SORT_OPTIONS } from '@/common/utils/common-definitions';
import SortableTableHeader from '@/modules/core/components/SortableTableHeader.vue';
import SelectedListHeader from '@/modules/core/components/SelectedListHeader.vue';

defineProps({
  showTitles: { type: Boolean, default: true },
  showStatus: { type: Boolean, default: true },
  showActivity: { type: Boolean, default: true },
  statusMode: { type: Boolean, default: false },
  border: { type: Boolean, default: false },
  sortOption: { type: String, default: null },
  isSortDesc: { type: Boolean, default: true },
  isAnySelected: { type: Boolean, default: false },
  isAllSelected: { type: Boolean, default: false },
  numSelectedItems: { type: Number, default: 0 },
  isDummyRepo: { type: Boolean, default: false },
  collapsible: { type: Boolean, default: false },
});

defineEmits(['sort-changed', 'select-all-toggle', 'move-click', 'delete-click']);

const collapsed = ref(false);

const toggleCollapsed = () => {
  collapsed.value = !collapsed.value;
};

const slots = useSlots();
</script>

<template>
  <div class="view-wrapper" :class="{ border, collapsed }">
    <div class="header" v-if="!!slots.header">
      <Icon name="collapse-arrow" class="collapse-arrow" v-if="collapsible" @click="toggleCollapsed" />
      <slot name="header" />
    </div>
    <div v-if="showTitles" :class="['list-titles', { selected: isAnySelected }]">
      <SelectedListHeader
        v-if="isAnySelected"
        :is-any-selected="isAnySelected"
        :is-all-selected="isAllSelected"
        :num-selected-items="numSelectedItems"
        :is-dummy-repo="isDummyRepo"
        @select-all-toggle="$emit('select-all-toggle', isAnySelected, isAllSelected)"
        @move-click="$emit('move-click')"
        @delete-click="$emit('delete-click')"
      />
      <template v-else>
        <SortableTableHeader
          class="name-column-title"
          :sort-option="REPO_FILTERS_SORT_OPTIONS.NAME"
          :is-sort-enabled="sortOption === REPO_FILTERS_SORT_OPTIONS.NAME"
          :is-sort-desc="isSortDesc"
          @sort-changed="$emit('sort-changed', $event)"
        >
          NAME
        </SortableTableHeader>
        <span class="status-column-title">
          <SortableTableHeader
            v-if="showStatus"
            :sort-option="REPO_FILTERS_SORT_OPTIONS.STATUS"
            :is-sort-enabled="sortOption === REPO_FILTERS_SORT_OPTIONS.STATUS"
            :is-sort-desc="isSortDesc"
            @sort-changed="$emit('sort-changed', $event)"
          >
            STATUS
          </SortableTableHeader>
        </span>
        <SortableTableHeader
          class="user-column-title"
          :sort-option="REPO_FILTERS_SORT_OPTIONS.AUTHOR"
          :is-sort-enabled="sortOption === REPO_FILTERS_SORT_OPTIONS.AUTHOR"
          :is-sort-desc="isSortDesc"
          @sort-changed="$emit('sort-changed', $event)"
        >
          AUTHOR
        </SortableTableHeader>
        <span v-if="showActivity" class="activity-column-title" :class="{ 'status-mode': statusMode }">
          <span v-if="statusMode">ACTIONS</span>
          <SortableTableHeader
            v-else
            class="activity-column-title"
            :sort-option="REPO_FILTERS_SORT_OPTIONS.LAST_MODIFIED"
            :is-sort-enabled="sortOption === REPO_FILTERS_SORT_OPTIONS.LAST_MODIFIED"
            :is-sort-desc="isSortDesc"
            @sort-changed="$emit('sort-changed', $event)"
          >
            LAST MODIFIED
          </SortableTableHeader>
        </span>
        <span class="menu-column-title"></span>
      </template>
    </div>
    <div class="view">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.view-wrapper {
  box-sizing: border-box;

  &.border {
    border: 1px solid var(--color-border-default-subtle);
    border-radius: 5px;
  }

  .view {
    position: relative;

    > :nth-child(2) :deep(.committed-line) {
      transform: translate(1.8rem, -0.8rem);
      position: absolute;
      height: 2rem;
      width: 0.5rem;
      align-self: flex-start;
    }
  }

  .header {
    background-color: var(--color-surface);
    padding: var(--space-sm);
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .list-titles {
    display: flex;
    font-size: var(--system-body);
    font-family: var(--fontfamily-secondary);
    text-align: center;
    align-items: center;
    color: var(--text-color-secondary);
    border-bottom: 2px solid var(--color-border-default-subtle);
    border-top: 2px solid transparent; /* to keep same height when selecting */
    padding-right: var(--space-base);

    &.selected {
      border-top: 2px solid var(--color-border-default-subtle);
      position: sticky;
      top: 0;
      z-index: 100;
      background-color: var(--color-bg);
    }

    .name-column-title {
      flex: 1;
      text-align: left;
      justify-content: flex-start;
      padding: 10px var(--space-base) 10px 64px;
    }

    .status-column-title {
      width: 84px;
    }

    .user-column-title {
      width: 92px;
    }

    .activity-column-title {
      width: 160px;
    }

    .menu-column-title {
      width: 56px;
    }

    .checkbox {
      visibility: hidden;
      margin: 0 var(--space-base) 0 var(--space-sm);

      &.visible {
        visibility: visible;
      }
    }
  }

  .collapse-arrow {
    color: var(--text-color-secondary);
    font-size: var(--body-XXS);
    margin-bottom: 4px;
    margin-right: var(--space-xs);
    transform: rotate(180deg);
    cursor: pointer;
  }

  &.collapsed {
    .view,
    .list-titles {
      display: none;
    }

    .collapse-arrow {
      margin-bottom: 1px;
      transform: rotate(90deg);
    }
  }
}

.padded {
  padding: 0 var(--space-sm);
}
</style>
