import { type Content, Extension, createNodeFromContent } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import type { Command } from '@tiptap/pm/state';
import { type Fragment, type ParseOptions, Node as ProseMirrorNode } from '@tiptap/pm/model';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    insertionPlaceholder: {
      addInsertionPlaceholder: (id: object, pos: number) => ReturnType;
      replaceInsertionPlaceholder: (
        id: object,
        content: Content,
        options?: {
          parseOptions?: ParseOptions;
        }
      ) => ReturnType;
    };
  }
}

export const INSERTION_PLACEHOLDER_PLUGIN_KEY = new PluginKey<DecorationSet>('insertionPlaceholder');

export interface AddInsertionPlaceholder {
  type: 'add';
  id: object;
  pos: number;
}

export interface ReplaceInsertionPlaceholder {
  type: 'remove';
  id: object;
}

type InsertionPlaceholderCommand = AddInsertionPlaceholder | ReplaceInsertionPlaceholder;

function renderInsertionPlaceholder(): Node {
  const elem = document.createElement('span');
  elem.className = 'icon-refresh insertion-placeholder';
  elem.role = 'progressbar';
  return elem;
}

export default Extension.create({
  name: 'insertionPlaceholder',

  addProseMirrorPlugins() {
    return [
      new Plugin<DecorationSet>({
        key: INSERTION_PLACEHOLDER_PLUGIN_KEY,
        state: {
          init: (_) => {
            return DecorationSet.empty;
          },

          apply: (transaction, decorationSet) => {
            decorationSet = decorationSet.map(transaction.mapping, transaction.doc);

            const meta = transaction.getMeta(INSERTION_PLACEHOLDER_PLUGIN_KEY) as InsertionPlaceholderCommand;
            if (meta != null) {
              switch (meta.type) {
                case 'add':
                  decorationSet = decorationSet.add(transaction.doc, [
                    Decoration.widget(meta.pos, renderInsertionPlaceholder, { id: meta.id }),
                  ]);
                  break;
                case 'remove': {
                  const decorations = decorationSet.find(undefined, undefined, (spec) => spec.id === meta.id);
                  decorationSet = decorationSet.remove(decorations);
                  break;
                }
              }
            }

            return decorationSet;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },

  addCommands() {
    return {
      addInsertionPlaceholder:
        (id, pos) =>
        ({ state, dispatch, view }) => {
          return addInsertionPlaceholder(id, pos)(state, dispatch, view);
        },

      replaceInsertionPlaceholder:
        (id, content, options) =>
        ({ editor, state, dispatch, view }) => {
          options = {
            parseOptions: {},
            ...options,
          };

          const node = createNodeFromContent(content, editor.schema, {
            parseOptions: {
              preserveWhitespace: 'full',
              ...options.parseOptions,
            },
          });

          return replaceInsertionPlaceholder(id, node)(state, dispatch, view);
        },
    };
  },
});

export function addInsertionPlaceholder(id: object, pos: number): Command {
  return (state, dispatch) => {
    if (dispatch) {
      const tr = state.tr.setMeta(INSERTION_PLACEHOLDER_PLUGIN_KEY, { type: 'add', id, pos });
      dispatch(tr);
    }

    return true;
  };
}

export function replaceInsertionPlaceholder(
  id: object,
  content: Fragment | ProseMirrorNode | readonly ProseMirrorNode[]
): Command {
  return (state, dispatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const decorationSet = INSERTION_PLACEHOLDER_PLUGIN_KEY.getState(state)!;
    const decorations = decorationSet.find(undefined, undefined, (spec) => spec.id === id);

    if (decorations[0] == null) {
      return false;
    }

    if (dispatch) {
      const tr = state.tr
        .setMeta(INSERTION_PLACEHOLDER_PLUGIN_KEY, { type: 'remove', id })
        .insert(decorations[0].from, content);
      dispatch(tr);
    }

    return true;
  };
}
