// @ts-strict
import * as objectUtils from './objectUtils';
import { getLogger } from './logger/legacy-shim';
import type { DynamicChangeLine, DynamicHunkContainer } from './types';
import { HunkChangeLineType } from './types/swimm-patch-common';
import { compareDelintedStrings } from './string-utils';

const logger = getLogger("packages/shared/src/similarity-line-matcher.ts");

/** This object holds the configuration for line matching based on similarity. */
const defaultLineMatcherConfig = {
  /**
   * The threshold from which we'll consider a pair of lines as homologous. Taken as the opposite `delta`'s config value.
   * Taken from Delta's config: https://github.com/dandavison/delta/blob/afa7a1a38dc13ea480653938e6c54c933396515c/src/cli.rs#L695.
   * Note that since Delta's similarity scale is inverse to ours (0 means an exact match), we use the opposite value.
   */
  MIN_LINE_DISTANCE: 0.4,
  adaptiveLineConfig: {
    MAX_LINE_DISTANCE_THRESHOLD: 0.85,
    PENALTY_FOR_DISTANCE_LINE: 0.015,
  },
  MAX_LOOKAHEAD_FOR_LINE_MATCH: 15,
  /**
   * For each line that successfully matches (passes the threshhold), we'll look _this_ amount of lines
   *  ahead to test if we can find an **exact match** for it.
   * This will better classify added lines in cases where they are similar to their surrounding lines.
   */
  EXACT_MATCH_LOOKAHEAD: 2,
};

type LineMatcherConfig = typeof defaultLineMatcherConfig;
export type LineMatcherOptionalConfig = Partial<LineMatcherConfig>;

/**
 * A wrapper around {@link matchChangeLines} that takes a hunk container instead of change lines.
 *
 * @param hunkContainer the hunk container to use as the base for line matching.
 * @returns a hunk with lines matched by similarity index.
 */
export function matchHunkLines({
  hunkContainer,
  lineMatcherConfig = defaultLineMatcherConfig,
}: {
  hunkContainer: DynamicHunkContainer;
  lineMatcherConfig?: LineMatcherOptionalConfig;
}): DynamicHunkContainer {
  const clonedHunk = objectUtils.deepClone(hunkContainer);
  const matchedLines = matchChangeLines({ changeLines: clonedHunk.changes, lineMatcherConfig });
  clonedHunk.changes = matchedLines;
  return clonedHunk;
}

/**
 * Match between addition and deletion lines based on their similarity index and returned a matched container.
 *
 * **NOTE**: this matching function won't work on hunks that have no deletion lines (as was the case in exercises).
 * We do not treat this case inside the function so make sure the given diffs aren't comprised entirely of additions.
 *
 * Algorithm:
 * 1. Process each hunk in "sub-hunks" (consecutive change lines without context).
 * 2. For each sub-hunk, separate the "addition" and "deletion" lines to two different lists.
 * 3. For each deletion, go through each of the remaining unassigned additions and compute the distance between the lines (until a match is found - see next bullet).
 * 4. When a line with a diff that passes the threshold is encountered, merge those two (mark as "update").
 * 5. Continue until all the deletion lines are assigned or until there are no more candidate additions. Concatenate the remaining lines.
 *
 * @param changeLines dynamic change lines that need to be matched.
 * @returns lines matched by similarity index.
 */
export function matchChangeLines({
  changeLines,
  lineMatcherConfig = defaultLineMatcherConfig,
}: {
  changeLines: DynamicChangeLine[];
  lineMatcherConfig?: LineMatcherOptionalConfig;
}): DynamicChangeLine[] {
  /** The config object, filled with all the default values and then updated with the provided config. */
  const filledLineMatcherConfig: LineMatcherConfig = { ...defaultLineMatcherConfig, ...lineMatcherConfig };
  const clonedLines = objectUtils.deepClone(changeLines);
  /** The final array of matched lines. */
  const matchedChanges: DynamicChangeLine[] = [];
  /** An intermediate buffer for change lines awaiting to be matched. */
  const intermediateLines: DynamicChangeLine[] = [];

  /** Process all the data in {@link intermediateLines}, clear it, and push the processed data into {@link matchedChanges}. */
  function flushIntermediateChangeLines(): void {
    const matchedLines = matchSubHunkLines({
      changes: intermediateLines.splice(0, intermediateLines.length),
      lineMatcherConfig: filledLineMatcherConfig,
    });
    matchedChanges.push(...matchedLines);
  }

  for (const line of clonedLines) {
    if (line.changeType === HunkChangeLineType.Context || line.changeType === HunkChangeLineType.Update) {
      if (intermediateLines.length) {
        // When we encounter a line that doesn't need matching we flush the entire intermediate buffer by matching the lines inside it and pushing them.
        flushIntermediateChangeLines();
      }
      matchedChanges.push(line);
    } else if (line.changeType === HunkChangeLineType.Added || line.changeType === HunkChangeLineType.Deleted) {
      // Save the line for later processing.
      intermediateLines.push(line);
    } else {
      // Any other type of `changeType` don't really matter to us, and ideally shouldn't even exist in this stage.
      logger.warn(`Unexpected change line of type ${line.changeType} in line matcher.`);
    }
  }
  // Making sure we processed all of the lines.
  if (intermediateLines.length) {
    flushIntermediateChangeLines();
  }
  return matchedChanges;
}

/**
 * Split the given change lines into an array of additions and an array of deletions.
 * @param changeLines the list of change lines to separate by change type.
 * @returns a list of addition lines and a list of deletion lines.
 */
function splitChangesToAdditionDeletionLines(changeLines: DynamicChangeLine[]): {
  additionsList: DynamicChangeLine[];
  deletionsList: DynamicChangeLine[];
} {
  const additionsList: DynamicChangeLine[] = [];
  const deletionsList: DynamicChangeLine[] = [];
  // Fill the `additions`/`deletions` lists.
  for (const line of changeLines) {
    if (line.changeType === HunkChangeLineType.Added) {
      additionsList.push(line);
    } else if (line.changeType === HunkChangeLineType.Deleted) {
      deletionsList.push(line);
    } else {
      logger.error(`Unexpected change line of type ${line.changeType} in subhunk line matcher.`);
    }
  }
  return { additionsList, deletionsList };
}

/**
 * Return whether the deletion and addition lines can be considered homologous.
 * @param deletionLine line with deleted data in `fileA`.
 * @param additionLine line with added data in `fileB`.
 * @returns two strings are considered homologous.
 */
function areStringsHomologous({
  deletionLine,
  additionLine,
  lineMatcherConfig,
  consideredAdditionLines,
}: {
  deletionLine: DynamicChangeLine;
  additionLine: DynamicChangeLine;
  lineMatcherConfig: LineMatcherConfig;
  consideredAdditionLines: number;
}): boolean {
  return (
    compareDelintedStrings(deletionLine.fileA.data, additionLine.fileB.data, false) >=
    getMinLineDistance(lineMatcherConfig, consideredAdditionLines)
  );
}

function getMinLineDistance(lineMatcherConfig: LineMatcherConfig, consideredAdditionLines: number) {
  const configMinLineDistance = lineMatcherConfig.MIN_LINE_DISTANCE;
  if (!consideredAdditionLines) {
    return configMinLineDistance;
  }
  return Math.min(
    configMinLineDistance + consideredAdditionLines * lineMatcherConfig.adaptiveLineConfig.PENALTY_FOR_DISTANCE_LINE,
    lineMatcherConfig.adaptiveLineConfig.MAX_LINE_DISTANCE_THRESHOLD
  );
}

/**
 * Check whether there's an exact (perfect) match in one of the future addition lines.
 * If the current addition line is a perfect match, return `false`.
 * This function is used to determine if we should match the current line or wait with the match for one of the next lines.
 */
function isPerfectMatchAhead({
  deletionLine,
  additionLine,
  futureAdditionLines,
}: {
  deletionLine: DynamicChangeLine;
  additionLine: DynamicChangeLine;
  futureAdditionLines: DynamicChangeLine[];
}): boolean {
  // If the current match is already perfect, return.
  if (compareDelintedStrings(deletionLine.fileA.data, additionLine.fileB.data, false) === 1) {
    return false;
  }
  for (const additionLine of futureAdditionLines) {
    // If there's an exact match...
    if (compareDelintedStrings(deletionLine.fileA.data, additionLine.fileB.data, false) === 1) {
      return true;
    }
  }
  return false;
}

/**
 * Match consecutive changed (non-context) lines using a similarity index.
 *
 * NOTE: SubHunk is a made-up term for multiple consecutive change lines uninterrupted by context lines.
 *
 * @param changes a list of change lines to match.
 * @returns change lines matched by a similarity index.
 */
function matchSubHunkLines({
  changes,
  lineMatcherConfig,
}: {
  changes: DynamicChangeLine[];
  lineMatcherConfig: LineMatcherConfig;
}): DynamicChangeLine[] {
  const clonedChanges = objectUtils.deepClone(changes);
  const { additionsList, deletionsList } = splitChangesToAdditionDeletionLines(clonedChanges);

  /** The matched lines to be returned. */
  const matchedLines: DynamicChangeLine[] = [];
  /** Tracks which additions lines finished processing and moved to our final `matchedLines`. */
  let additionIndex = 0;
  /** Buffer for addition lines that were considered for in the current loop. */
  const consideredAdditionLines: DynamicChangeLine[] = [];
  for (const deletionLine of deletionsList) {
    // Since we're starting a new loop we need to empty any addition lines we considered in our past runs.
    // This cleaning is done at the beginning rather than the end since we don't want to clear the values of the _last_ loop.
    consideredAdditionLines.splice(0, consideredAdditionLines.length);
    const slicedAdditions = additionsList.slice(additionIndex);
    let foundHomologousLine = false;
    for (const [index, additionLine] of slicedAdditions.entries()) {
      const futureAdditionLines = slicedAdditions.slice(index + 1, index + 1 + lineMatcherConfig.EXACT_MATCH_LOOKAHEAD);
      if (
        areStringsHomologous({
          deletionLine,
          additionLine,
          lineMatcherConfig,
          consideredAdditionLines: consideredAdditionLines.length,
        }) &&
        !isPerfectMatchAhead({ deletionLine, additionLine, futureAdditionLines })
      ) {
        // Add to the addition index by the amount of addition lines we're about to flush.
        additionIndex += consideredAdditionLines.length;
        // Push all unpaired addition lines and clear the intermediate buffer.
        matchedLines.push(...consideredAdditionLines.splice(0, consideredAdditionLines.length));
        // Merge the paired deletion+addition lines and push them next.
        const mergedLine = mergeHomologousDynamicLines(deletionLine, additionLine);
        matchedLines.push(mergedLine);
        additionIndex += 1;
        foundHomologousLine = true;
        // After we found a matching addition line, we stop looking (so we won't match the same deletion line twice).
        break;
      } else {
        consideredAdditionLines.push(additionLine);
        if (consideredAdditionLines.length > lineMatcherConfig.MAX_LOOKAHEAD_FOR_LINE_MATCH) {
          break;
        }
      }
    }
    // If there are considered addition lines that means we didn't `break` out of the inner loop (as they are only flushed there).
    if (!foundHomologousLine) {
      matchedLines.push(deletionLine);
    }
  }
  // If there are any addition lines left, append them to our matched lines.
  if (additionIndex < additionsList.length) {
    matchedLines.push(...additionsList.splice(additionIndex));
  }
  return matchedLines;
}

function mergeHomologousDynamicLines(
  deletionLine: DynamicChangeLine,
  additionLine: DynamicChangeLine
): DynamicChangeLine {
  // If the two lines are exactly the same, we'll register them as `context`.
  return {
    changeType:
      deletionLine.fileA.data === additionLine.fileB.data ? HunkChangeLineType.Context : HunkChangeLineType.Update,
    fileA: deletionLine.fileA,
    fileB: additionLine.fileB,
  };
}
