<template>
  <SwText variant="system-body" class="branch"><slot /></SwText>
</template>

<style scoped lang="postcss">
.branch {
  background: var(--color-bg);
  padding: 0 var(--space-xs);
  color: var(--text-color-secondary);
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
