<template>
  <div class="content centered">
    <form @submit.prevent="$emit('insert-markdown')">
      <TextFieldMultiline
        v-model="markdownText"
        class="text-field"
        placeholder="Insert markdown"
        :focus-first="true"
        tabindex="1"
        @keydown="onKeyDown"
      />
      <Action class="submit-btn" tabindex="2" @click="insertMarkdown">Apply</Action>
    </form>
  </div>
</template>

<script>
import TextFieldMultiline from '@/common/components/atoms/TextFieldMultiline.vue';

export default {
  name: 'MarkdownEditorModal',
  components: { TextFieldMultiline },
  props: {
    options: { type: Object, default: null },
  },
  emits: ['insert-markdown'],
  data: function () {
    return {
      markdownText: this.options ? this.options.text : '',
    };
  },
  methods: {
    markdownSubmit() {
      this.$emit('insert-markdown', this.markdownText);
    },
    onKeyDown(e) {
      if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
        this.insertMarkdown();
      }
    },
    insertMarkdown() {
      this.options.insertMarkdown(this.markdownText);
    },
  },
};
</script>

<style scoped>
.content {
  margin-right: 65px;
  margin-left: 65px;
  width: 500px;
}

.text-field {
  margin: 40px 0;
  height: 300px;
}

.submit-btn {
  margin-bottom: 50px;
  width: 230px;
  align-self: center;
}

.submit-btn:focus {
  color: var(--text-color-primary);
}
</style>
