import {
  SmartElement,
  SmartElementWithApplicability,
  SmartElementWithApplicabilityAndNewInfo,
  Snippet,
  SwmCell,
  SwmCellSnippet,
  SwmCellType,
} from '../types';
import { shortHash } from './string-utils';

interface PartialSnippet {
  firstLineNumber: number;
  lines: string[];
  path: string;
}

export function getHunkIndex(content: Array<SwmCell>, hunk: SwmCellSnippet): number | null {
  const hunks = content.filter((item) => item.type === SwmCellType.Snippet);
  const index = hunks.indexOf(hunk);
  return index >= 0 ? index : null;
}

export function getHunkAtIndex(content: Array<SwmCell>, index: number): SwmCell | null {
  const hunks = content.filter((item) => item.type === SwmCellType.Snippet);
  return hunks.length > index ? hunks[index] : null;
}

export function getHunkIndication(hunk: PartialSnippet) {
  // Hunk id is regenerated when we load the doc.
  // we will create a different indecation from the hunk data here
  return buildHunkIndication({
    firstLineNumber: hunk.firstLineNumber,
    length: hunk.lines.length,
    path: hunk.path,
  });
}

export function buildHunkIndication({
  firstLineNumber,
  length,
  path,
}: {
  firstLineNumber: number;
  length: number;
  path: string;
}) {
  // Hunk id is regenerated when we load the doc.
  // we will create a different indecation from the hunk data here
  return shortHash(`${firstLineNumber}-${length}-${path}`);
}

export function isHunkEqualToQuery(query: string, hunk: SwmCellSnippet) {
  const suspectHunk = getHunkIndication(hunk);
  return query === suspectHunk;
}

/**
 * This function is a type predicate that checks whether or not a SmartElement has the `newInfo` field.
 */
export function isSmartElementWithNewInfo<T extends SmartElement>(
  element: SmartElementWithApplicability<T>
): element is SmartElementWithApplicabilityAndNewInfo<T> {
  // @ts-ignore - this is fine since we just want to know if it exists, and don't want to test it against every possible status.
  return !!element.newInfo;
}

export function applyAutosyncDataToHunk(
  hunk: SwmCellSnippet,
  autosyncedData: SmartElementWithApplicability<Snippet>
): SwmCellSnippet {
  if (!autosyncedData || !isSmartElementWithNewInfo(autosyncedData)) {
    return hunk;
  } else {
    const newInfo = autosyncedData.newInfo;
    return {
      ...hunk,
      applicability: autosyncedData.applicability,
      lines: newInfo.lines,
      firstLineNumber: newInfo.startLineNumber,
      path: newInfo.filePath,
      repoId: hunk.repoId,
    };
  }
}
