<template>
  <BaseCard class="card-container">
    <template v-if="!emailSent">
      <div>
        <SwText variant="headline2">Forgot password?</SwText>
        <SwText variant="body-S" class="subtitle"
          >Enter the email address associated with your account and we’ll send a link to reset it.</SwText
        >
      </div>
      <form
        class="reset-password-fields"
        @keydown.enter.prevent="onResetPasswordClick"
        @submit.prevent="onResetPasswordClick"
      >
        <TextField v-model.trim="email" label="Email" focus-first data-testid="email-field" />
        <Action class="reset-button" data-testid="reset-button" :disabled="!email" :loading="isLoading"
          >Reset password</Action
        >
        <ErrorBox v-if="error">{{ error }}</ErrorBox>
      </form>
      <Divider />
      <SwText variant="body-S" class="help-text" data-testid="sign-in-button"
        >Remembered your password? <router-link to="/login">Sign in</router-link></SwText
      >
    </template>
    <div v-else>
      <SwText variant="headline2" data-testid="reset-sent-title">Reset link sent</SwText>
      <SwText variant="body-S" class="description" data-testid="reset-description"
        >We just sent an email to <strong data-testid="email">{{ email }}</strong
        >.<br />Please check instructions on how to reset your password</SwText
      >
    </div>
  </BaseCard>
</template>

<script>
import { useAnalytics } from '@/common/composables/useAnalytics';
import BaseCard from '@/modules/core/components/BaseCard.vue';
import { getLoggerNew, pageEvents } from '@swimm/shared';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Divider, ErrorBox, SwText, TextField } from '@swimm/ui';

const logger = getLoggerNew(__modulename);

export default {
  components: { SwText, BaseCard, TextField, ErrorBox, Divider },
  setup() {
    const analytics = useAnalytics();
    return { analytics };
  },
  data() {
    return {
      email: '',
      error: '',
      emailSent: false,
      isLoading: false,
    };
  },
  created() {
    this.analytics.pageVisit(pageEvents.VIEW_FORGOT_PASSWORD);
  },
  methods: {
    async onResetPasswordClick() {
      this.isLoading = true;
      await firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.emailSent = true;
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.code === 'auth/user-not-found') {
            this.emailSent = true;
          } else {
            this.error = 'Cannot reset password';
          }
          logger.error({ err }, `User reset password error: ${err.message}`);
        });
      return false;
    },
  },
};
</script>

<style scoped>
.card-container {
  margin: auto;
  gap: var(--space-md);

  .subtitle {
    color: var(--text-color-secondary);
  }

  .help-text {
    a {
      color: var(--text-color-link);
    }
  }

  .reset-password-fields {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);
  }

  .description {
    color: var(--text-color-secondary);
  }
}
</style>
