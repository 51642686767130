import { tEventCloudFunction } from '@/common/utils/product-events-reporters';
import 'firebase/compat/auth';
import { updateWorkspaceUserInSalesforce } from '@/common/utils/salesforce/salesforce';
import { FirestoreCollectionNamesType, getLoggerNew } from '@swimm/shared';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { productEvents } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export async function acceptInvite({ workspaceId, user, workspaceName }) {
  try {
    const inviteResponse = await CloudFunctions.acceptInvitation({ workspaceId, user });
    if (inviteResponse.data) {
      void CloudFunctions.postJoinWorkspace({ workspaceId, user });
      void updateWorkspaceUserInSalesforce({ workspaceId, uid: user.uid });
      void tEventCloudFunction({
        identity: user.uid,
        event: productEvents.JOINED_BY_INVITE,
        payload: {
          'Workspace ID': workspaceId,
          'Workspace Name': workspaceName,
          Email: user.email ?? '',
        },
      });
    }
    return inviteResponse;
  } catch (err) {
    logger.error({ err }, err);
    return { data: false };
  }
}

export async function incrementResourceDBViews({
  containerType,
  containerId,
  resourceType,
  resourceId,
}: {
  containerType: FirestoreCollectionNamesType;
  containerId: string;
  resourceType: FirestoreCollectionNamesType;
  resourceId: string;
}) {
  try {
    await CloudFunctions.incrementResourceViews({ containerType, containerId, resourceType, resourceId });
  } catch (err) {
    logger.error(
      { err },
      `Failed to increment view count for ${containerType}/${containerId}/${resourceType}/${resourceId}: ${err}`
    );
  }
}

export function trimedWorkspaceName(workspaceName: string) {
  // Remove the word "workspace" from the name (if it exists at the end)
  if (workspaceName.trim().toLowerCase().endsWith('workspace')) {
    return workspaceName
      .trim()
      .replace(/workspace$/i, '')
      .trim();
  }
  return workspaceName;
}

export async function requestInvite(workspaceId: string) {
  try {
    await CloudFunctions.requestInvite({ workspaceId });
  } catch (err) {
    logger.error({ err }, `Could not request invite for workspace ${workspaceId}`);
  }
}
