import { state as localState } from '@swimm/shared';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { STORES } from '@/modules/core/stores/store-constants';
import type { SwimmModal } from '../modal-constants';

export const useGlobalModalStore = defineStore(STORES.GLOBAL_MODAL_STORE, () => {
  const activeModal = ref<SwimmModal>(null);
  const snippetDrawerHeight = ref(0);

  function openModal(modalToShow: SwimmModal) {
    activeModal.value = modalToShow;
  }

  function closeModal() {
    activeModal.value = null;
  }

  async function setSnippetDrawerHeight({ drawerHeight }) {
    await localState.set({ key: 'snippet_drawer_height', value: drawerHeight });
    snippetDrawerHeight.value = drawerHeight;
  }

  return {
    activeModal,
    snippetDrawerHeight,
    openModal,
    closeModal,
    setSnippetDrawerHeight,
  };
});
