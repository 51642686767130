import { REPO_JSON, SWM_FOLDER_IN_REPO } from './config';
import gitwrapper from 'GitUtils/gitwrapper';
import { getLogger } from './logger/legacy-shim';

const logger = getLogger("packages/shared/src/repo-config.ts");

const DEFAULT_VALUES = {
  always_mark_done_when_tests_succeed: false,
  cr_prompt_push_solution: false,
  store_solution_upon_done: false,
  show_swimm_files_automatically: false,
  reset_on_done_without_asking: true,
  avoid_pre_commit_hooks_on_storing_swimmer_solution: false,
  docker_mode: false,
  show_warning_for_running_tests_not_from_repo_base_dir: true,
  commit_message_suffix: '',
  swmd: true,
};

export async function get<T extends keyof typeof DEFAULT_VALUES>({
  key,
  defaultValue,
}: {
  key: T;
  defaultValue?: (typeof DEFAULT_VALUES)[T];
}) {
  if (!defaultValue && defaultValue !== false) {
    defaultValue = DEFAULT_VALUES[key];
  }
  try {
    const repoConfigJson = await gitwrapper.getSwimmJsonContentsWrapper();

    return getRepoConfigValue({ key, defaultValue, repoConfigJson });
  } catch (error) {
    logger.error(`Failed to get repo config value: ${error.toString()}`, { service: 'repo-config' });
    return defaultValue;
  }
}

// Gets the repo config value from remote git vendor (for when running in the web)
export async function getFromRemoteConfigJson<T extends keyof typeof DEFAULT_VALUES>({
  key,
  defaultValue,
  repoId,
  revision,
}: {
  key: T;
  defaultValue?: (typeof DEFAULT_VALUES)[T];
  repoId?: string;
  revision: string;
}) {
  if (!defaultValue && defaultValue !== false) {
    defaultValue = DEFAULT_VALUES[key];
  }
  try {
    const repoConfigString = await gitwrapper.getFileContentFromRevision({
      filePath: `${SWM_FOLDER_IN_REPO}/${REPO_JSON}`,
      repoId: repoId,
      revision: revision,
    });
    const repoConfigJson = JSON.parse(repoConfigString);
    return getRepoConfigValue({ key, defaultValue, repoConfigJson });
  } catch (error) {
    if (!(await isSwimmJsonInRepo({ repoId, revision }))) {
      logger.info(`Repo config file not exist in revesion ${revision}`, { service: 'repo-config' });
    } else {
      logger.error(`Failed to get repo config value: ${error.toString()}`, { service: 'repo-config' });
    }
    return defaultValue;
  }
}

export async function isSwimmJsonInRepo({ repoId, revision }: { repoId?: string; revision: string }) {
  return await gitwrapper.isFileExistsOnRevision({
    filePath: `${SWM_FOLDER_IN_REPO}/${REPO_JSON}`,
    repoId: repoId,
    revision: revision,
  });
}

function getRepoConfigValue<T extends keyof typeof DEFAULT_VALUES>({
  key,
  defaultValue = DEFAULT_VALUES[key],
  repoConfigJson,
}: {
  key: T;
  defaultValue?: (typeof DEFAULT_VALUES)[T];
  repoConfigJson?: unknown;
}) {
  if (!defaultValue && defaultValue !== false) {
    defaultValue = DEFAULT_VALUES[key];
  }
  try {
    const configuration = repoConfigJson['configuration'] as Record<T, (typeof DEFAULT_VALUES)[T]>;
    if (!configuration) {
      return defaultValue;
    }

    if (key in configuration) {
      return configuration[key];
    }
  } catch (error) {
    logger.error(`Failed to get repo config for key: ${key}. Details: ${error.toString()}`, {
      service: 'repo-config',
    });
    return defaultValue;
  }
  // If the `try` block didn't fail but we couldn't find the value in the configuration
  return defaultValue;
}
