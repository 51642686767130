<template>
  <CollapsibleList title="Keyboard shortcuts" @close="close" @back-clicked="handleBackClick" v-click-outside="close">
    <template #items>
      <CollapsibleListItem v-for="shortcutGroup in keyboardShortcuts" :key="shortcutGroup.title" :expandable="false">
        <template #title>
          <span class="flexed">
            {{ shortcutGroup.title }}
            <VTooltip v-if="shortcutGroup.tooltip" placement="right">
              <Icon name="info-outline" />
              <template #popper>
                <div class="group-tooltip body-XS">
                  {{ shortcutGroup.tooltip }}
                </div>
              </template>
            </VTooltip>
          </span>
          <template v-if="shortcutGroup.keystroke">
            <code class="body-XS code-span custom-code">
              {{ shortcutGroup.keystroke }}
            </code>
          </template>
        </template>
        <template #content>
          <div v-for="shortcut in shortcutGroup.shortcuts" :key="shortcut.text" class="group-shortcut">
            <div class="shortcut-name">
              <Icon v-if="shortcut.icon" :name="shortcut.icon" />
              <span class="body-S shortcut-text">{{ shortcut.text }}</span>
            </div>
            <div class="shortcut-command">
              <span>
                <code class="body-XS code-span">{{ shortcut.keystroke }}</code>
                <template v-if="shortcut.additionalKeystrokes">
                  <span v-for="(additionalKeystroke, index) in shortcut.additionalKeystrokes" :key="index">
                    + <code class="body-XS code-span">{{ additionalKeystroke }}</code>
                  </span>
                </template>
              </span>
            </div>
          </div>
        </template>
      </CollapsibleListItem>
    </template>
  </CollapsibleList>
</template>

<script>
import CollapsibleList from '@/modules/help-center/components/CollapsibleList.vue';
import CollapsibleListItem from '@/modules/help-center/components/CollapsibleListItem.vue';
import { KEYBOARD_SHORTCUTS, KEYSTROKES } from '@/modules/editor/components/constants/keysboard-shortcuts.ts';
import { useHelpCenterStore } from '@/modules/help-center/stores/helpCenter';

export default {
  name: 'KeyboardShortcutsModalContent',
  components: { CollapsibleList, CollapsibleListItem },
  emits: ['close'],
  setup() {
    const { toggleHelpCenter } = useHelpCenterStore();
    return { toggleHelpCenter };
  },
  computed: {
    keyboardShortcuts() {
      return [
        {
          title: 'Slash commands',
          keystroke: KEYSTROKES.SLASH_COMMAND,
        },
        {
          title: 'Snippet Studio',
          shortcuts: KEYBOARD_SHORTCUTS.SNIPPET_STUDIO,
        },
        {
          title: 'Smart Token',
          shortcuts: KEYBOARD_SHORTCUTS.SMART_TOKENS,
          tooltip: 'Reference parts of the code that will automatically update when the code changes.',
        },
        {
          title: 'Editor',
          shortcuts: KEYBOARD_SHORTCUTS.EDITOR,
        },
      ];
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleBackClick() {
      this.close();
      this.toggleHelpCenter(true);
    },
  },
};
</script>

<style scoped>
.content {
  overflow-y: auto;
  padding: 10px;
  width: 304px;
  max-height: 60vh;
}

.content::-webkit-scrollbar-track {
  background: var(--color-border-default-subtle);
}

.content::-webkit-scrollbar-thumb {
  background: var(--color-border-default);
}

.shortcut-text {
  font-family: var(--fontfamily-main);
  font-weight: 400;
  color: var(--text-color-primary);
}

.group-shortcut {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.shortcut-command {
  color: var(--text-color-secondary);
}

.custom-code,
.shortcut-command code {
  margin: 0;
  padding: 0 4px;
  border: 1px solid var(--text-color-secondary);
  border-radius: 4px;
  background: var(--color-surface);
  color: var(--text-color-secondary);
}

.flexed {
  display: flex;
  align-items: center;
}

.group-tooltip {
  max-width: 176px;
}
</style>
