<template>
  <div>
    <p class="text" v-if="emailPrefixString && userEmail">
      {{ emailPrefixString }} <span class="email">{{ userEmail }}.</span>
    </p>
    <p class="text">
      {{ tryDifferentPrefixString }} <span class="link" @click="onTryDifferentEmailClick">Try a different email</span>
    </p>
  </div>
</template>

<script>
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useSignout } from '@/common/composables/signout';
import { productEvents } from '@swimm/shared';

export default {
  props: {
    origin: { type: String, required: true },
    emailPrefixString: { type: String, default: null },
    userEmail: { type: String, default: null },
    tryDifferentPrefixString: { type: String, required: true },
  },
  setup() {
    const analytics = useAnalytics();
    const { signout } = useSignout();
    return { analytics, signout };
  },
  methods: {
    async onTryDifferentEmailClick({ origin }) {
      this.analytics.track(productEvents.CLICKED_TRY_DIFFERENT_EMAIL, {
        Origin: origin,
      });
      await this.signout();
      this.$router.push('/register');
    },
  },
};
</script>

<style scoped lang="postcss">
.text {
  margin: 0 0 8px 0;
  font-size: var(--body-S);

  .email {
    font-weight: 800;
  }

  .link {
    font-weight: 800;
    color: var(--text-color-link);
    cursor: pointer;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 800px) {
  .text {
    text-align: center;

    span {
      display: inline-block;
    }
  }
}
</style>
