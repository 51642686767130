/**
 * Based on https://github.com/markdown-it/markdown-it/blob/2b6cac25823af011ff3bc7628bc9b06e483c5a08/lib/rules_block/html_block.js
 * & https://github.com/markdown-it/markdown-it/blob/2b6cac25823af011ff3bc7628bc9b06e483c5a08/lib/common/html_re.js
 *
 * @module
 */

import type MarkdownIt from 'markdown-it';
import type StateBlock from 'markdown-it/lib/rules_block/state_block';
import { ATTRIBUTE, parseHtmlCloseTag, parseHtmlOpenTag } from './html';

/**
 * An array of opening and corresponding closing sequences for html tags, last
 * argument defines whether it can terminate a paragraph or not
 *
 * This is done according to the rules of the CommonMark spec of where an HTML
 * block should begin and end.
 */
const SWM_SEQUENCES = [
  [
    // Based on this case of HTML in Markdown
    // https://github.com/markdown-it/markdown-it/blob/2b6cac25823af011ff3bc7628bc9b06e483c5a08/lib/rules_block/html_block.js#L19
    // (There are varying rules depending on the type of tag)
    new RegExp(
      '^(?:' + '<(Swm[A-Za-z0-9-]*)' + ATTRIBUTE + '*\\s*>' + '|' + '<\\/Swm[A-Za-z0-9\\-]*\\s*>' + ')' + '\\s*$'
    ),
    /^$/,
    false,
  ],
] as const;

function parseSwmBlock(state: StateBlock, startLine: number, endLine: number, silent: boolean): boolean {
  let pos = state.bMarks[startLine] + state.tShift[startLine];
  let max = state.eMarks[startLine];

  // if it's indented more than 3 spaces, it should be a code block
  if (state.sCount[startLine] - state.blkIndent >= 4) {
    return false;
  }

  if (state.src.charCodeAt(pos) !== 0x3c /* < */) {
    return false;
  }

  let lineText = state.src.slice(pos, max);

  let i: number;
  for (i = 0; i < SWM_SEQUENCES.length; i++) {
    if (SWM_SEQUENCES[i][0].test(lineText)) {
      break;
    }
  }

  if (i === SWM_SEQUENCES.length) {
    return false;
  }

  if (silent) {
    // true if this sequence can be a terminator, false otherwise
    return SWM_SEQUENCES[i][2];
  }

  let nextLine = startLine + 1;

  // If we are here - we detected a swm tag block.
  // Let's roll down till block end.
  if (!SWM_SEQUENCES[i][1].test(lineText)) {
    for (; nextLine < endLine; nextLine++) {
      if (state.sCount[nextLine] < state.blkIndent) {
        break;
      }

      pos = state.bMarks[nextLine] + state.tShift[nextLine];
      max = state.eMarks[nextLine];
      lineText = state.src.slice(pos, max);

      if (SWM_SEQUENCES[i][1].test(lineText)) {
        if (lineText.length !== 0) {
          nextLine++;
        }

        break;
      }
    }
  }

  state.line = nextLine;

  const text = state.getLines(startLine, nextLine, state.blkIndent, true);
  const close = text[1] === '/';

  let tag: string | null = '';
  let attrs: [string, string][] | null = null;
  if (!close) {
    const parsed = parseHtmlOpenTag(text);
    if (parsed == null) {
      return false;
    }

    ({ tag, attrs } = parsed);
  } else {
    const parsed = parseHtmlCloseTag(text);
    if (parsed == null) {
      return false;
    }

    ({ tag } = parsed);
  }

  const token = state.push(!close ? 'swm_block_open' : 'swm_block_close', tag, 0);
  token.map = [startLine, nextLine];
  token.content = text;
  token.attrs = attrs;

  return true;
}

export default function swm_block(md: MarkdownIt): void {
  md.block.ruler.before('html_block', 'swm_block', parseSwmBlock);
}
