<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 5.66667L0.00564884 5.66354C0.0926675 4.36021 1.17426 3.33333 2.5 3.33333H10.8333C12.1591 3.33333 13.2407 4.36021 13.3277 5.66354L13.3333 5.66667V7.5C13.3333 7.77614 13.1095 8 12.8333 8V8C12.5572 8 12.3333 7.77614 12.3333 7.5V5.83333C12.3333 5.00281 11.6639 4.33333 10.8333 4.33333H2.5C1.66948 4.33333 1 5.00281 1 5.83333V10.8333C1 11.6639 1.66948 12.3333 2.5 12.3333H8.22712C8.50327 12.3333 8.72712 12.5572 8.72712 12.8333V12.8333C8.72712 13.1095 8.50327 13.3333 8.22712 13.3333H2.5C1.11719 13.3333 0 12.2161 0 10.8333V5.66667Z"
      fill="var(--text-color-secondary)"
    />
    <path
      d="M5.18689 7.75756C5.38215 7.55776 5.38215 7.23382 5.18689 7.03402C4.99162 6.83422 4.67504 6.83422 4.47978 7.03402L3.47978 8.05726C3.28452 8.25707 3.28452 8.58101 3.47978 8.78081L4.47978 9.80406C4.67504 10.0039 4.99162 10.0039 5.18689 9.80406C5.38215 9.60426 5.38215 9.28031 5.18689 9.08051L4.54044 8.41904L5.18689 7.75756Z"
      fill="var(--text-color-secondary)"
    />
    <path
      d="M8.14645 9.80406C7.95118 9.60426 7.95118 9.28031 8.14645 9.08051L8.79289 8.41904L8.14645 7.75756C7.95118 7.55776 7.95118 7.23382 8.14645 7.03402C8.34171 6.83422 8.65829 6.83422 8.85355 7.03402L9.85355 8.05727C10.0488 8.25707 10.0488 8.58101 9.85355 8.78081L8.85355 9.80406C8.65829 10.0039 8.34171 10.0039 8.14645 9.80406Z"
      fill="var(--text-color-secondary)"
    />
    <path
      d="M7.45478 6.89281C7.51468 6.61697 7.34472 6.34367 7.07515 6.28238C6.80558 6.22108 6.53849 6.39499 6.47859 6.67083L5.81191 9.74053C5.75201 10.0164 5.92197 10.2897 6.19154 10.351C6.46111 10.4123 6.72819 10.2383 6.7881 9.9625L7.45478 6.89281Z"
      fill="var(--text-color-secondary)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5204 10.4401C13.6338 10.6888 13.987 10.6888 14.1004 10.4401C14.1323 10.3703 14.1883 10.3143 14.2581 10.2825C14.5067 10.169 14.5067 9.81582 14.2581 9.70238C14.1883 9.67052 14.1323 9.61452 14.1004 9.54469C13.987 9.29608 13.6338 9.29608 13.5204 9.54469C13.4885 9.61452 13.4325 9.67052 13.3627 9.70238C13.1141 9.81582 13.1141 10.169 13.3627 10.2825C13.4325 10.3143 13.4885 10.3703 13.5204 10.4401ZM10.9874 13.4754C11.106 13.7353 11.4753 13.7353 11.5939 13.4754L11.9307 12.7373C11.964 12.6643 12.0226 12.6057 12.0956 12.5724L12.8336 12.2356C13.0936 12.117 13.0936 11.7477 12.8336 11.6291L12.0956 11.2923C12.0226 11.259 11.964 11.2005 11.9307 11.1275L11.5939 10.3894C11.4753 10.1295 11.106 10.1295 10.9874 10.3894L10.6506 11.1275C10.6173 11.2005 10.5588 11.259 10.4857 11.2923L9.74769 11.6291C9.48775 11.7477 9.48775 12.117 9.74769 12.2356L10.4857 12.5724C10.5588 12.6057 10.6173 12.6643 10.6506 12.7373L10.9874 13.4754ZM14.3083 13.4841C14.4217 13.2355 14.7749 13.2355 14.8883 13.4841C14.9202 13.5539 14.9762 13.6099 15.046 13.6418C15.2946 13.7552 15.2946 14.1084 15.046 14.2218C14.9762 14.2537 14.9202 14.3097 14.8883 14.3795C14.7749 14.6281 14.4217 14.6281 14.3083 14.3795C14.2764 14.3097 14.2204 14.2537 14.1506 14.2218C13.902 14.1084 13.902 13.7552 14.1506 13.6418C14.2204 13.6099 14.2764 13.5539 14.3083 13.4841Z"
      fill="var(--text-color-magic-strong)"
    />
  </svg>
</template>
