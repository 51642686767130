import { useAsyncState } from '@vueuse/core';
import mermaidPackageJson from 'mermaid/package.json';
import { getLoggerNew } from '@swimm/shared';
import type { Ref } from 'vue';

const logger = getLoggerNew(__modulename);

let mermaid: typeof import('mermaid')['default'] | null = null;

export function useMermaid({ isDarkMode }: { isDarkMode: Ref<boolean> }) {
  const { state, isReady, isLoading } = useAsyncState(async () => {
    if (mermaid != null) {
      return mermaid;
    }
    try {
      mermaid = (await import('mermaid')).default;
      mermaid.initialize({
        startOnLoad: false,
        theme: isDarkMode.value ? 'dark' : 'default',
      });
      return mermaid;
    } catch (err) {
      logger.error(`Failed to import mermaid ${err}`);
      throw err;
    }
  }, null);

  return {
    mermaid: state,
    mermaidIsReady: isReady,
    mermaidIsLoading: isLoading,
    mermaidPackageVersion: mermaidPackageJson?.version ?? null,
  };
}
