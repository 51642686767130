<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  name: { type: String, required: true },
  tooltip: { type: String, default: null },
  noPadding: { type: Boolean, required: false, default: false },
});
defineEmits(['click']);

const computedIconNameClass = computed(() => `icon-${props.name}`);
const computedNoPaddingClass = computed(() => ({
  'no-padding': props.noPadding,
}));
</script>

<template>
  <i v-tooltip="tooltip" :class="[computedIconNameClass, computedNoPaddingClass]" @click="$emit('click', $event)"></i>
</template>

<style scoped>
/* TODO This is a workaround for a CSS specificity issue with users of this
    component trying to override padding via CSS rather than the prop */
i {
  padding: 5px;
  vertical-align: middle;
}

.no-padding {
  padding: 0;
}
</style>
