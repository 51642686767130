<script setup lang="ts">
import { useVModel } from '@vueuse/core';

import UtilInlineEditable from '../../components/UtilInlineEditable/UtilInlineEditable.vue';
import BaseHeading from '../../components/BaseHeading/BaseHeading.vue';
import { ref } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    placeholder?: string;
    edit?: boolean;
  }>(),
  {
    modelValue: undefined,
    placeholder: 'Untitled',
  }
);

const editableInput = ref<InstanceType<typeof UtilInlineEditable>>();
const title = useVModel(props, 'modelValue', undefined, {
  shouldEmit: () => {
    // Additional safety check to ensure modelValue isn't updated
    // unless we're in edit mode.
    return props.edit;
  },
});

defineExpose({
  focus() {
    editableInput.value?.focus();
  },
  select() {
    editableInput.value?.select();
  },
});
</script>

<template>
  <BaseHeading class="resource-header__title" level="title">
    <UtilInlineEditable
      ref="editableInput"
      v-model="title"
      auto-focus
      placeholder="Untitled"
      :disabled="!edit"
      data-testid="doc-title"
    />
  </BaseHeading>
</template>
