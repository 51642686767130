import { ref } from 'vue';
import { defineStore } from 'pinia';
import { STORES } from '@/modules/core/stores/store-constants';

export interface WorkspaceSettingsOptions {
  initialTabCode?: string;
  initialTabPhase?: string;
  openedFromQuery?: boolean;
}

export const useWorkspaceSettingsModalStore = defineStore(STORES.WORKSPACE_SETTINGS, () => {
  const showWorkspaceSettingsModal = ref(false);
  const workspaceSettingsOptions = ref<WorkspaceSettingsOptions>({});

  function openWorkspaceSettingsModal(options: WorkspaceSettingsOptions) {
    workspaceSettingsOptions.value = options;
    showWorkspaceSettingsModal.value = true;
  }

  const closeWorkspaceSettingsModal = ({ fromRouter }: { fromRouter?: boolean } = {}) => {
    // Keep the modal open when it was opened from the router query
    // because there's a re-route when removing the query
    if (workspaceSettingsOptions.value.openedFromQuery && fromRouter) {
      return;
    }
    showWorkspaceSettingsModal.value = false;
    workspaceSettingsOptions.value = {};
  };

  return {
    showWorkspaceSettingsModal,
    workspaceSettingsOptions,
    openWorkspaceSettingsModal,
    closeWorkspaceSettingsModal,
  };
});
