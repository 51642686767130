<script setup lang="ts">
import CodeSnippetContent from '@/components/EditorComponents/Hunk/CodeSnippetContent.vue';
import { computed, nextTick, ref, watch } from 'vue';
import { ApplicabilityStatus, type DynamicChangeLine, HunkChangeLineType, type TokenSuggestion } from '@swimm/shared';
import ShowMoreLines from './ShowMoreLines.vue';
import type { OverlayToken } from '../GenericText/tokenizer';

const DEFAULT_CONTEXT_MODIFICATION_SIZE = 6;

const props = withDefaults(
  defineProps<{
    editMode?: boolean;
    diffMode?: boolean;
    changes?: DynamicChangeLine[];
    repoId?: string | null;
    branch?: string | null;
    fileName?: string | null;
    fileType?: string;
    applicability?: ApplicabilityStatus;
    forceDisconnected?: boolean;
    fileToShow?: 'fileA' | 'fileB';
    isAuthorized?: boolean;
    fileContents?: string[] | null;
    hideShowMore?: boolean;
    splitSnippetMode?: boolean;
    queryDefinitions?: ((token: string) => Promise<TokenSuggestion[]>) | null;
    allowDragDropEditing?: boolean;
  }>(),
  {
    editMode: false,
    diffMode: false,
    changes: () => [],
    repoId: null,
    branch: null,
    fileName: null,
    fileType: '',
    applicability: ApplicabilityStatus.Verified,
    forceDisconnected: false,
    fileToShow: 'fileA',
    isAuthorized: true,
    fileContents: null,
    hideShowMore: false,
    splitSnippetMode: false,
    queryDefinitions: null,
  }
);

const emit = defineEmits<{
  (e: 'report-hover', element: string): void;
  (event: 'line-range-change', newRange: { firstLineNumber: number; lines: string[] }): void;
  (event: 'split-snippet', splitAfterLineNumber: number): void;
  (event: 'add-def-snippet', suggestion: TokenSuggestion): void;
  (event: 'token-interacted', op: 'hover' | 'click', tok: OverlayToken): void;
}>();

const contextSizeAbove = ref(0);
const contextSizeBelow = ref(0);
const scrollableHunk = ref<{ scrollTop: number; scrollHeight: number } | null>(null);

const contentToShow = computed(() => {
  if (canAddContextToSnippet.value) {
    return fileContentsWithChangeTypes.value?.slice(
      contentToShowFirstLineNumber.value - 1,
      contentToShowLastLineNumber.value
    );
  } else {
    return snippetMeat.value;
  }
});
const isFirstFileLineShown = computed(() => contentToShowFirstLineNumber.value === 1);
const isLastFileLineShown = computed(
  () => !!props.fileContents && contentToShowLastLineNumber.value === props.fileContents.length
);

const canAddContextToSnippet = computed(() => {
  return (
    (props.applicability === ApplicabilityStatus.Verified ||
      props.applicability === ApplicabilityStatus.Autosyncable) &&
    props.fileContents &&
    isMeatInFile.value
  );
});

const isLoadingContentsOrApplicability = computed(
  () => !props.fileContents || props.applicability === ApplicabilityStatus.Unknown
);
const isMeatInFile = computed(() =>
  snippetMeat.value.every(
    (line) =>
      !!props.fileContents && line.fileA && line.fileA.data === props.fileContents[line.fileA.actualLineNumber - 1]
  )
);
const contentToShowFirstLineNumber = computed(() => Math.max(meatFirstLineNumber.value - contextSizeAbove.value, 1));
const contentToShowLastLineNumber = computed(() =>
  Math.min(meatLastLineNumber.value + contextSizeBelow.value, props.fileContents?.length ?? Infinity)
);
const meatFirstLineNumber = computed(() =>
  Math.min(...snippetMeat.value.map((change) => change.fileA?.actualLineNumber ?? Infinity))
);
const meatLastLineNumber = computed(() =>
  Math.max(...snippetMeat.value.map((change) => change.fileA?.actualLineNumber ?? 0))
);
const fileContentsWithChangeTypes = computed(() =>
  props.fileContents?.map((lineContent, lineIndex) => ({
    changeType: getLineChangeType(snippetMeat.value, lineIndex + 1),
    fileA: { actualLineNumber: lineIndex + 1, data: lineContent },
  }))
);
const snippetMeat = computed(() => props.changes.filter((line) => line.changeType !== HunkChangeLineType.Context));

const shouldShowShowMoreSection = computed(() => {
  return (
    !props.hideShowMore &&
    (canAddContextToSnippet.value || !props.isAuthorized || isLoadingContentsOrApplicability.value)
  );
});

function getLineChangeType(meat: Array<DynamicChangeLine>, lineNumber: number) {
  const meatLine = meat.find((meatLine) => meatLine.fileA && meatLine.fileA.actualLineNumber === lineNumber);
  if (!meatLine || !meatLine.changeType) {
    return HunkChangeLineType.Context;
  }
  return meatLine.changeType;
}

function modifyAmountOfTopContext(amount: number) {
  contextSizeAbove.value = Math.max(contextSizeAbove.value + amount, 0);
  if (scrollableHunk.value) {
    scrollableHunk.value.scrollTop = 0;
  }
}

function modifyAmountOfBottomContext(amount: number) {
  contextSizeBelow.value = Math.max(contextSizeBelow.value + amount, 0);
  if (scrollableHunk.value) {
    nextTick(() => {
      if (scrollableHunk.value) {
        scrollableHunk.value.scrollTop = scrollableHunk.value.scrollHeight;
      }
    });
  }
}

function reportHover(element: string) {
  emit('report-hover', element);
}

function onLineRangeChange(newRange: { firstLineNumber: number; lastLineNumber: number }) {
  const shownLines = contentToShow.value;
  if (!shownLines) {
    return;
  }
  const firstLineNumberIndex = shownLines.findIndex(
    (line) => line.fileA?.actualLineNumber === newRange.firstLineNumber
  );
  const lastLineNumberIndex = shownLines.findIndex((line) => line.fileA?.actualLineNumber === newRange.lastLineNumber);
  emit('line-range-change', {
    firstLineNumber: newRange.firstLineNumber,
    // Snippets are only editable in verified mode.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    lines: shownLines.slice(firstLineNumberIndex, lastLineNumberIndex + 1).map((line) => line.fileA!.data),
  });
}

watch(
  () => ({
    repoId: props.repoId,
    branch: props.branch,
    fileName: props.fileName,
    snippetMeat: snippetMeat.value,
    applicability: props.applicability,
  }),
  (_value) => {
    contextSizeAbove.value = 0;
    contextSizeBelow.value = 0;
  }
);
</script>

<template>
  <div class="code-snippet">
    <div ref="scrollableHunk" class="scrollable-hunk" :class="{ 'diff-mode': diffMode }">
      <div class="show-more-lines-bar" :class="[applicability, { 'context-background': contextSizeAbove > 0 }]">
        <!-- We hide the show more line if still loading but keep it in DOM to avoid a jump when it is loaded -->
        <ShowMoreLines
          v-if="contentToShow && contentToShow.length > 0 && shouldShowShowMoreSection"
          :class="{ hidden: isLoadingContentsOrApplicability }"
          :disable-show-more="!isAuthorized || isFirstFileLineShown"
          :disable-show-less="!isAuthorized || contextSizeAbove === 0"
          :is-authorized="isAuthorized"
          invert
          @show-more="modifyAmountOfTopContext(DEFAULT_CONTEXT_MODIFICATION_SIZE)"
          @show-less="modifyAmountOfTopContext(-DEFAULT_CONTEXT_MODIFICATION_SIZE)"
          @report-hover="reportHover"
        />
      </div>
      <!-- This is pre so that when the user copies the text, they get a code block when they paste it into the doc. -->
      <pre class="scrollable-hunk-code">
        <CodeSnippetContent
          :lines-content="contentToShow ?? []"
          :edit-mode="editMode"
          :applicability="applicability"
          :allow-drag-drop-editing="allowDragDropEditing"
          :origin="fileToShow"
          :language="fileType"
          :force-disconnected="forceDisconnected || applicability === ApplicabilityStatus.DisconnectedFromRepo"
          :trim-prefix-spaces="!diffMode"
          :diff-mode="diffMode"
          :split-snippet-mode="splitSnippetMode"
          :query-definitions="queryDefinitions"
          @line-range-change="onLineRangeChange"
          @split-snippet="emit('split-snippet', $event)"
          @add-def-snippet="emit('add-def-snippet', $event)"
          @token-interacted="(op: 'hover' | 'click', tok: OverlayToken) => emit('token-interacted', op, tok)"
        />
      </pre>
      <div class="show-more-lines-bar" :class="[applicability, { 'context-background': contextSizeBelow > 0 }]">
        <!-- We hide the show more line if still loading but keep it in DOM to avoid a jump when it is loaded -->
        <ShowMoreLines
          v-if="contentToShow && contentToShow.length > 0 && shouldShowShowMoreSection"
          :class="{ hidden: isLoadingContentsOrApplicability }"
          :disable-show-more="!isAuthorized || isLastFileLineShown"
          :disable-show-less="!isAuthorized || contextSizeBelow === 0"
          :is-authorized="isAuthorized"
          @show-more="modifyAmountOfBottomContext(DEFAULT_CONTEXT_MODIFICATION_SIZE)"
          @show-less="modifyAmountOfBottomContext(-DEFAULT_CONTEXT_MODIFICATION_SIZE)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.scrollable-hunk {
  overflow-x: auto;

  .show-more-lines-bar {
    position: sticky;
    left: 0;

    &.autosyncable {
      background: var(--color-code-autosync-sandwich);
    }

    &.context-background {
      background-color: var(--color-surface);
    }
  }

  &:hover .show-more-lines-bar :deep(.bar) {
    opacity: 1;
  }

  &:not(.diff-mode) {
    max-height: 550px; /* Don't let long snippets overflow screen */

    .show-more-lines-bar.autosyncable {
      background: var(--color-code-autosync-meat);
    }
  }
}

.hidden {
  visibility: hidden;
  pointer-events: none;
}

.scrollable-hunk-code {
  margin: 0;
  display: flex;
  flex-direction: column;
  width: max-content;
  min-width: 100%;
}
</style>
