import {
  type GenerateSnippetCommentRequest,
  GenerativeAiRequestType,
  type LlmCallGenericRequest,
  type Prompt,
} from '@swimm/shared';
import { v4 as uuidv4 } from 'uuid';

export function getGenerateSnippetCommentPrompt(request: GenerateSnippetCommentRequest): Prompt {
  return {
    messages: [
      {
        role: 'system',
        content: `You are a helpful assistant that explains what does code snippets do.

Reply in JSON format:
{error?: string; generatedText?: string}

Example
Context: "  if (result.status === 'error' || !apiResponse?.text) {\n    logger.error({ cloudRun, result }, \`Calling cloudrun {cloudRun} returned an error\`);\n    throw new Error('Calling cloudrun returned an error');..."

Bad response: "This code snippet checks if the \`result\` object has a \`status\` property with the value "error" or if the \`apiResponse\` object does not have a truthy \`text\` property. If either condition is true, it logs an error message and throws an error."
Why response is bad: The response did not follow the rules for generating a snippet comment. The response should be a JSON object with the keys "error" and "generatedText". The "generatedText" key should contain the generated text.

Good response: { error: null, generatedText: "This code snippet checks if the \`result\` object has a \`status\` property with the value "error" or if the \`apiResponse\` object does not have a truthy \`text\` property. If either condition is true, it logs an error message and throws an error." }

`,
      },
      {
        role: 'user',
        content: `${request.snippetContent}

Please provide a VERY short description of the code above, describing its main functionality. Never relate to error statuses or codes. Never list function arguments. Start your response with the word "This". When relevant, include elements from the code in your response and wrap them with backticks.
`,
      },
    ],
    options: {
      max_tokens: 1000,
      temperature: 0.7,
      top_p: 1,
      n: 1,
    },
    workspaceId: request.workspaceId,
  };
}

export function getGenerateSnippetCommentRequestParams(request: GenerateSnippetCommentRequest): LlmCallGenericRequest {
  return {
    prompt: getGenerateSnippetCommentPrompt(request),
    type: GenerativeAiRequestType.GENERATE_SNIPPET_COMMENT,
    workspaceId: request.workspaceId,
    requestId: `${GenerativeAiRequestType.GENERATE_SNIPPET_COMMENT}-${uuidv4()}`,
  };
}
