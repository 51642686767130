import MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core';

const linkParser = MarkdownIt();
linkParser.core.ruler.enableOnly(['normalize', 'block', 'inline', 'text_join']);
linkParser.inline.ruler.enableOnly(['link']);

export default function swm_path(md: MarkdownIt, options?: { inMermaid?: boolean }): void {
  function transformSwmPath(state: StateCore): void {
    const blockTokens = state.tokens;

    for (let j = 0, l = blockTokens.length; j < l; j++) {
      if (blockTokens[j].type !== 'inline') {
        continue;
      }

      const tokens = blockTokens[j].children ?? [];

      for (let open = 0; open < tokens.length; open++) {
        if (tokens[open].type !== 'swm_inline_open' || tokens[open].tag !== 'SwmPath') {
          continue;
        }

        let close;
        for (close = open + 1; close < tokens.length; close++) {
          if (tokens[close].type === 'swm_inline_close' && tokens[close].tag === 'SwmPath') {
            break;
          }
        }

        if (close === tokens.length) {
          continue;
        }

        // Same repo elements have no repoId
        if (!tokens[open].attrGet('repo-id')) {
          if (!options?.inMermaid) {
            // The element should contain a link
            if (tokens[open + 1].type !== 'link_open' || tokens[open + 2].type !== 'text') {
              continue;
            }

            tokens[open].type = 'swm_path';
            tokens[open].attrs = [...(tokens[open].attrs ?? []), ...(tokens[open + 1].attrs ?? [])];
            tokens[open].attrSet('text', tokens[open + 2].content);
            tokens[open].attrSet('repo-id', state.env?.swimm?.repoId);
            tokens[open].attrSet('repo-name', state.env?.swimm?.repoName);
          } else {
            // Since we don't run the link rule while parsing mermaid we need
            // specialized code to handle matching the link syntax
            // Based on https://github.com/markdown-it/markdown-it/blob/2b6cac25823af011ff3bc7628bc9b06e483c5a08/lib/rules_inline/link.js
            if (tokens[open + 1].type !== 'text') {
              continue;
            }

            const linkTokens = linkParser.parseInline(tokens[open + 1].content, state.env)[0].children;

            if (!linkTokens || linkTokens[0].type !== 'link_open' || linkTokens[1].type !== 'text') {
              continue;
            }

            tokens[open].type = 'swm_path';
            tokens[open].attrs = [...(tokens[open].attrs ?? []), ...(linkTokens[0].attrs ?? [])];
            tokens[open].attrSet('text', linkTokens[1].content);
            tokens[open].attrSet('repo-id', state.env?.swimm?.repoId);
            tokens[open].attrSet('repo-name', state.env?.swimm?.repoName);
          }

          // Cross repo element
        } else {
          // The element should contain an inline code span.
          if (tokens[open].attrGet('path') == null) {
            continue;
          }

          if (!options?.inMermaid && tokens[open + 1].type !== 'code_inline') {
            continue;
          }

          if (options?.inMermaid && tokens[open + 1].type !== 'text') {
            continue;
          }

          let text = tokens[open + 1].content;

          if (options?.inMermaid) {
            // Since we don't run the backticks rule while parsing mermaid we need
            // a specialized parsing rule that will handle matching the backticks/code_inline
            // by itself
            // Based on https://github.com/markdown-it/markdown-it/blob/2b6cac25823af011ff3bc7628bc9b06e483c5a08/lib/rules_inline/backticks.js
            const ch = tokens[open + 1].content.charCodeAt(0);

            if (ch !== 0x60 /* ` */) {
              continue;
            }

            // scan marker length
            let openerLength = 1;
            while (
              openerLength < tokens[open + 1].content.length &&
              tokens[open + 1].content.charCodeAt(openerLength) === 0x60 /* ` */
            ) {
              openerLength++;
            }

            const matchStart = tokens[open + 1].content.length - openerLength;
            let matchEnd = matchStart;
            while (
              matchEnd < tokens[open + 1].content.length &&
              tokens[open + 1].content.charCodeAt(matchEnd) === 0x60 /* ` */
            ) {
              matchEnd++;
            }

            const closerLength = matchEnd - matchStart;

            if (closerLength !== openerLength) {
              continue;
            }

            text = tokens[open + 1].content
              .slice(openerLength, tokens[open + 1].content.length - closerLength)
              .replace(/\n/g, ' ')
              .replace(/^ (.+) $/, '$1');
          }

          tokens[open].type = 'swm_path';
          tokens[open].attrs ??= [];
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          tokens[open].attrs = tokens[open].attrs!.map(([name, value]) => {
            // Rename path to href
            if (name === 'path') {
              return ['href', value];
            }

            return [name, value];
          });
          tokens[open].attrSet('text', text);
        }

        // Collapse the following tokens including the close token, not exact, as
        // link already strips stuff, but this is just for debugging really
        for (let i = open + 1; i < close + 1; i++) {
          tokens[open].content = tokens[open].content + tokens[i].content;
        }
        tokens.splice(open + 1, close - open);
      }
    }
  }

  md.core.ruler.after('inline', 'swm_path', transformSwmPath);
}
