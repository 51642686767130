import { useAuthStore } from '@/modules/core/stores/auth-store';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { getEmailHostedDomain } from '@/common/utils/authUtils';
import { GOOGLE_DOMAIN_NAME, GOOGLE_PROVIDER_NAME, SSO_PROVIDER_SUFFIX } from '@/common/consts';

export const useJoinWorkspace = () => {
  const store = useStore();
  const { user } = storeToRefs(useAuthStore());
  const fetchCompanyWorkspaces = async () => await store.dispatch('database/fetchCompanyWorkspaces');
  const fetchWorkspaceInvites = async () => await store.dispatch('database/fetchWorkspaceInvites');
  const db_getUserWorkspaces = store.getters['database/db_getUserWorkspaces'];

  const companyDomain = computed<string | null>(() => getEmailHostedDomain(user.value.email));
  const shouldFetchCompanyWorkspaces = computed(
    () =>
      (user.value.provider === GOOGLE_PROVIDER_NAME || user.value.provider?.includes(SSO_PROVIDER_SUFFIX)) &&
      companyDomain.value &&
      companyDomain.value !== GOOGLE_DOMAIN_NAME
  );
  // Data we need to return once initialized
  const invites = ref([]);
  const hasWorkspaces = ref(false);
  const companyWorkspaces = ref([]);
  const repoWorkspaces = ref([]);
  const shouldShowRequestInvitePage = ref(false);
  const hasWorkspacesToJoin = ref(false);
  const autoJoinableWorkspaces = ref([]);
  const isWorkspaceAutoJoinable = ref(false);
  const userWorkspaces = computed(() => db_getUserWorkspaces(user.value.uid));

  function fetchCompanyWorkspaceIfNeeded() {
    if (shouldFetchCompanyWorkspaces.value) {
      return fetchCompanyWorkspaces();
    }
    return [];
  }
  async function initializeJoinWorkspace(workspaceId?: string, repoId?: string) {
    if (repoId) {
      const result = await CloudFunctions.getAllWorkspacesContainingRepo({ repoId: repoId });
      repoWorkspaces.value = result.data?.workspaces ?? [];
    }

    hasWorkspaces.value = Object.keys(userWorkspaces.value).length > 0;

    const [workspaces, workspaceInvites] = await Promise.all([
      fetchCompanyWorkspaceIfNeeded(),
      fetchWorkspaceInvites(),
    ]);

    invites.value = workspaceInvites.sort((ws1, ws2) => ws2.counter_workspace_users - ws1.counter_workspace_users);

    const isUserWasInvited = Boolean(invites.value.find((workspace) => workspace.id === workspaceId));

    shouldShowRequestInvitePage.value = Boolean(workspaceId) && !isUserWasInvited;

    const eligibleWorkspaces = filterEligibleWorkspaceInvites({
      userWorkspaces: userWorkspaces.value,
      workspaces,
    });

    companyWorkspaces.value = eligibleWorkspaces.filter((workspace) => !workspace.isAutoJoinEnabled);

    autoJoinableWorkspaces.value = workspaces
      .filter((workspace) => workspace.isAutoJoinEnabled)
      .filter((workspace) => !workspaceInvites.some((invitedWorkspace) => invitedWorkspace.id === workspace.id));

    const hasInvites = invites.value.length > 0;

    const hasWorkspaceToAutoJoin = autoJoinableWorkspaces.value.length > 0;

    isWorkspaceAutoJoinable.value = autoJoinableWorkspaces.value.find(
      (workspace) => workspace.id === workspaceId
    )?.isAutoJoinEnabled;

    const hasCompanyWorkspaces = companyWorkspaces.value.length > 0;

    const hasWorkspacesContainingRepo = repoWorkspaces.value.length;

    hasWorkspacesToJoin.value =
      hasInvites || hasCompanyWorkspaces || hasWorkspaceToAutoJoin || !!hasWorkspacesContainingRepo;
  }

  return {
    hasWorkspacesToJoin,
    autoJoinableWorkspaces,
    isWorkspaceAutoJoinable,
    repoWorkspaces,
    userWorkspaces,
    user,
    shouldShowRequestInvitePage,
    companyWorkspaces,
    hasWorkspaces,
    invites,
    initializeJoinWorkspace,
  };
};

function filterEligibleWorkspaceInvites({ userWorkspaces, workspaces }) {
  const workspacesHaveUpToOneUser = workspaces.every((workspace) => workspace.usersCount <= 1);
  return workspaces
    .filter((workspace) => !userWorkspaces[workspace.id])
    .filter((workspace) => (workspacesHaveUpToOneUser ? workspace.usersCount === 1 : workspace.usersCount > 1))
    .sort((ws1, ws2) => ws2.usersCount - ws1.usersCount);
}
