<script setup lang="ts">
import { type Ref, onUpdated, ref } from 'vue';
import { Icon, SwText, Tag } from '@swimm/ui';

const props = defineProps<{
  title: string;
  icon: string;
  shortcut?: string;
  tag?: string;
  isSelected: boolean;
  isIde: boolean;
  shouldScrollTo: boolean;
}>();

const emit = defineEmits(['select', 'hover', 'scrolled']);

const element: Ref<HTMLElement | null> = ref(null);

onUpdated(() => {
  if (props.isIde && props.shouldScrollTo) {
    element.value?.scrollIntoView(false);
    emit('scrolled');
  }
});
</script>
<template>
  <div
    ref="element"
    class="item cell-option"
    :class="{ selected: isSelected }"
    @click="$emit('select')"
    @mouseenter="$emit('hover')"
  >
    <div class="text-container">
      <Icon :name="icon" class="command-icon" />
      <SwText variant="body-S" weight="light">
        {{ title }}
      </SwText>
    </div>

    <div v-if="shortcut" class="item-shortcut">{{ shortcut }}</div>
    <Tag v-if="!!tag" class="beta-tag" :text="tag" tag-style="beta" />
  </div>
</template>

<style scoped lang="postcss">
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  min-width: 15em;
  cursor: pointer;
  font-weight: 200;
  color: var(--text-color-primary);

  &.selected {
    background: var(--color-hover);
  }

  .command-icon {
    margin-right: 2px;
    font-size: var(--fontsize-s);
    color: var(--text-color-secondary);
  }

  .item-shortcut {
    color: var(--text-color-link);
  }

  .text-container {
    display: flex;
    padding-right: 5px;
    justify-content: center;
    align-items: center;
  }

  .beta-tag {
    border-radius: 4px;
    font-weight: normal;
    color: var(--high-violet);
    background-color: var(--color-code-autosync-sandwich);
  }
}
</style>
