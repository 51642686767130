import * as _ from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import type {
  SwmCell,
  SwmCellSnippet,
  SwmCellSnippetPlaceholder,
  SwmCellText,
  SwmFile,
  SwmSymbol,
  SwmSymbolGenericText,
  SwmSymbolPath,
  SwmSymbolTextPlaceholder,
} from './types';
import { SwmCellType, SwmSymbolType } from './types';
import { generateEmptySwm } from './utils/swm-utils';
import * as nodePath from 'path-browserify';
import md5 from 'md5';
import { splitLineByWords } from './split-line-by-words';
import { getHunkIndication } from './shared';

export type PartialSnippet = Pick<SwmCellSnippet, 'path' | 'firstLineNumber' | 'lines'>;

/**
 * Takes a collection of snippets and merges snippets that overlap in meat or in context
 */
export function* aggregateSnippets(snippets: PartialSnippet[]): Generator<PartialSnippet> {
  if (snippets.length === 0) {
    return;
  }
  type LineWithNumber = { lineNumber: number; line: string };
  const path = snippets[0].path;
  const allLines: LineWithNumber[] = _.sortBy(
    snippets.flatMap((snippet) =>
      snippet.lines.map((line, i) => ({
        lineNumber: snippet.firstLineNumber + i,
        line,
      }))
    ),
    ['lineNumber']
  );
  let currentSnippetLines: LineWithNumber[] | null = null;
  for (const { lineNumber, line } of allLines) {
    if (currentSnippetLines === null) {
      currentSnippetLines = [{ lineNumber, line }];
      continue;
    }
    const previousLine = currentSnippetLines[currentSnippetLines.length - 1];
    if (previousLine.lineNumber === lineNumber - 1) {
      currentSnippetLines.push({ line, lineNumber });
      continue;
    }
    if (previousLine.lineNumber === lineNumber) {
      // TODO: Constant instead of explicit '*'
      if (line[0] === '*') {
        currentSnippetLines[currentSnippetLines.length - 1] = { lineNumber, line };
      }
      continue;
    }
    // Line goes outside the current snippet - yield the previous one and start a new snippet
    yield {
      path,
      firstLineNumber: currentSnippetLines[0].lineNumber,
      lines: currentSnippetLines.map(({ line }) => line),
    } as PartialSnippet;
    currentSnippetLines = [{ lineNumber, line }];
  }

  if (currentSnippetLines != null) {
    yield {
      path,
      firstLineNumber: currentSnippetLines[0].lineNumber,
      lines: currentSnippetLines.map(({ line }) => line),
    } as PartialSnippet;
  }
}

export function getGenericTextSymbolId({ path, lineNumber, token }) {
  return md5(`${path}:${lineNumber}:${token}`);
}

// FIXME: the current (2022-02-14) implementation of `.sw.md` has a bug where we don't preserve the `wordIndex` of a token.
//  We build on that to simplify this implementation here, but when it'll change we'll probably want to revisit it.
export function createGenericTextSymbol({
  path,
  lineData,
  lineNumber,
  token,
  repoId,
}: {
  path: string;
  lineNumber: number;
  token: string;
  lineData: string;
  repoId: string | undefined;
}): SwmSymbolGenericText {
  // FIXME: When the name of the declaration is 'a.b' - we split the line into words, and cannot find a word called
  // 'a.b' because it spans multiple words. This leads to start and end being -1.
  const splitLine = splitLineByWords(lineData);
  const tokenIndexInLine = splitLine.indexOf(token);
  return {
    type: SwmSymbolType.GENERIC_TEXT,
    path,
    lineData,
    lineNumber,
    wordIndex: {
      start: tokenIndexInLine,
      end: tokenIndexInLine,
    },
    text: token,
    repoId,
    fileBlob: '', // NOTE: We count on the fact that `save` will calculate the correct blob shas.
  };
}

export class SwmBuilder {
  swmFile: SwmFile;
  basePath: string;
  listener?: {
    onSymbol?: (symbolId: string, symbol: SwmSymbol) => void;
    onCell?: (cell: SwmCell) => void;
  };

  /**
   * @param basePath A base path, all subsequently provided paths will be considered relative to this one.
   */
  constructor(basePath: string = undefined) {
    this.swmFile = generateEmptySwm(process.env.PJSON_VERSION);
    this.basePath = basePath;
  }

  resolvePath(path: string): string {
    if (!this.basePath) {
      return path;
    }
    return nodePath.resolve(this.basePath, path);
  }

  setSwmName(title: string): void {
    this.swmFile.name = title;
  }

  addCell(cell: SwmCell): void {
    this.swmFile.content.push(cell);
    this.listener?.onCell?.(cell);
    // TODO: Search the cell for used symbols.
  }

  addSymbol(symbolId: string, symbol: SwmSymbol): void {
    if (!this.swmFile.symbols[symbolId]) {
      this.swmFile.symbols[symbolId] = symbol;
    }
    this.listener?.onSymbol(symbolId, symbol);
  }

  addTextCell(text: string): SwmCellText {
    const textCell: SwmCellText = { type: SwmCellType.Text, text };
    this.addCell(textCell);
    return textCell;
  }

  addSnippetCell({
    comment = '',
    snippet,
    repoId = '',
  }: {
    comment?: string;
    snippet: PartialSnippet;
    repoId?: string;
  }): SwmCellSnippet {
    const snippetCell: SwmCellSnippet = {
      type: SwmCellType.Snippet,
      ...snippet,
      comments: [comment],
      repoId,
      id: getHunkIndication(snippet),
    };
    this.addCell(snippetCell);
    return snippetCell;
  }

  createPathSymbol(path: string, repoId?: string): string {
    path = nodePath.normalize(path); // Swimm doesn't like paths with ./ etc.
    const pathSymbol: SwmSymbolPath = { type: SwmSymbolType.PATH, text: path, path: path, repoId: repoId ?? '' };
    const symbolId = md5(path);
    this.addSymbol(symbolId, pathSymbol);
    return `[[sym:./${path}(${symbolId})]]`;
  }

  createPlaceholder(prompt: string): string {
    const symbolId = uuidv4();
    const textPlaceholderSymbol: SwmSymbolTextPlaceholder = {
      type: SwmSymbolType.TEXT_PLACEHOLDER,
      id: symbolId,
      text: prompt,
      value: '',
    };
    this.addSymbol(symbolId, textPlaceholderSymbol);
    return `[${prompt}](#text-placeholder-id-${symbolId})`;
  }

  addSnippetPlaceholderCell(prompt: string): SwmCellSnippetPlaceholder {
    const snippetPlaceholderCell: SwmCellSnippetPlaceholder = {
      type: SwmCellType.SnippetPlaceholder,
      comment: prompt,
    };
    this.addCell(snippetPlaceholderCell);
    return snippetPlaceholderCell;
  }

  getOrCreateGenericTextSymbol({
    path,
    lineData,
    lineNumber,
    token,
    repoId,
  }: {
    path: string;
    lineData: string;
    lineNumber: number;
    token: string;
    repoId: string | undefined;
  }): string {
    path = nodePath.normalize(path); // Swimm doesn't like paths with ./ etc.
    const symbolId = getGenericTextSymbolId({ path, lineNumber, token });
    if (!this.swmFile.symbols[symbolId]) {
      this.addSymbol(
        symbolId,
        createGenericTextSymbol({
          path,
          lineNumber,
          token,
          lineData,
          repoId,
        })
      );
    }
    return `[[sym-text:${token}(${symbolId})]]`;
  }
}
