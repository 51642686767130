import { SwmCellSnippet, SwmCellType, SwmFile, SwmSymbolGenericText, SwmSymbolPath, SwmSymbolType } from '../types';
import type { GitInfo, Snippet } from '../types/autosync/autosync-types';
import { AutosyncInput, SmartElementType, SmartSymbol } from '../types/autosync/autosync-types';
import gitwrapper from 'GitUtils/gitwrapper';
import { filterSwmCells, getSwmSymbolsTupleByTypes } from '../utils/swm-utils';

// this is the symbols as: [symbol_id, symbol_data]
type SwmSmartSymbol = [string, SwmSymbolGenericText | SwmSymbolPath];

async function getRepoInfo({
  repoId,
  branch,
  useDefaultBranch,
}: {
  repoId: string;
  branch: string;
  useDefaultBranch: boolean;
}): Promise<GitInfo> {
  try {
    const remoteData = await gitwrapper.getRepoRemoteData({ repoId });
    return {
      repoId,
      repoOwner: remoteData.owner,
      repoName: remoteData.name,
      ...(useDefaultBranch ? { branch: remoteData.defaultBranch } : { branch }),
    };
  } catch (err) {
    return undefined;
  }
}

async function getRepoIdsToGitInfoMap({
  snippets,
  symbols,
  docRepoId,
  branch,
  isIde,
}: {
  snippets: SwmCellSnippet[];
  symbols: SwmSmartSymbol[];
  docRepoId: string;
  branch: string;
  isIde: boolean;
}): Promise<Record<string, GitInfo>> {
  const repoIds: Set<string> = new Set();

  snippets.forEach((snippet) => repoIds.add(snippet.repoId));
  symbols.forEach(([_id, symbol]) => repoIds.add(symbol.repoId));

  const repoIdToDefaultBranchMap = {};
  for (const id of repoIds) {
    const repoInfo: GitInfo = isIde
      ? buildFakeRepoInfo(docRepoId, branch)
      : await getRepoInfo({ repoId: id, branch, useDefaultBranch: id !== docRepoId });
    repoIdToDefaultBranchMap[id] = repoInfo;
  }

  return repoIdToDefaultBranchMap;
}

function buildFakeRepoInfo(repoId: string, branch: string): GitInfo {
  return { branch, repoId, repoName: '', repoOwner: '' };
}

function convertSwmContentToAutosyncSnippet({
  swmSnippets,
  docRepoId,
  shouldSyncCrossRepo,
  repoIdToGitInfoMap,
}: {
  swmSnippets: SwmCellSnippet[];
  docRepoId: string;
  shouldSyncCrossRepo: boolean;
  repoIdToGitInfoMap: Record<string, GitInfo>;
}): Snippet[] {
  const autosyncSnippets: Snippet[] = [];
  for (const snippet of swmSnippets) {
    if (snippet.repoId !== docRepoId && !shouldSyncCrossRepo) {
      continue;
    }

    const gitInfo = repoIdToGitInfoMap[snippet.repoId];
    if (!gitInfo) {
      // this is a cross repo snippet in a repo we don't have access to
      continue;
    }

    autosyncSnippets.push({
      id: snippet.id,
      type: SmartElementType.SNIPPET,
      lines: snippet.lines,
      filePath: snippet.path,
      startLineNumber: snippet.firstLineNumber,
      gitInfo: {
        ...gitInfo,
      },
    });
  }

  return autosyncSnippets;
}

function convertSwmSymbolsToAutosyncSymbols({
  swmSymbols,
  docRepoId,
  shouldSyncCrossRepo,
  repoIdToGitInfoMap,
}: {
  swmSymbols: SwmSmartSymbol[];
  docRepoId: string;
  shouldSyncCrossRepo: boolean;
  repoIdToGitInfoMap: Record<string, GitInfo>;
}): SmartSymbol[] {
  const autosyncSymbols: SmartSymbol[] = [];
  for (const [id, symbol] of swmSymbols) {
    if (SwmSymbolType.GENERIC_TEXT !== symbol.type && SwmSymbolType.PATH !== symbol.type) {
      continue;
    }

    if (symbol.repoId !== docRepoId && !shouldSyncCrossRepo) {
      continue;
    }

    const gitInfo = repoIdToGitInfoMap[symbol.repoId];
    if (!gitInfo) {
      // this is a cross repo symbol in a repo we don't have access to
      continue;
    }

    switch (symbol.type) {
      case SwmSymbolType.GENERIC_TEXT: {
        autosyncSymbols.push({
          id,
          type: SmartElementType.TOKEN,
          symbolText: symbol.text,
          lineContent: symbol.lineData,
          filePath: symbol.path,
          lineNumber: symbol.lineNumber,
          wordIndex: { ...symbol.wordIndex },
          gitInfo: {
            ...gitInfo,
          },
        });
        break;
      }
      case SwmSymbolType.PATH: {
        autosyncSymbols.push({
          id,
          type: SmartElementType.PATH,
          symbolText: symbol.text,
          filePath: symbol.path,
          isDirectory: false,
          gitInfo: {
            ...gitInfo,
          },
        });
        break;
      }
    }
  }

  return autosyncSymbols;
}

export async function convertSwmObjectToAutosyncInput({
  swm,
  docRepoId,
  branch,
  shouldSyncCrossRepo,
  isIde,
}: {
  swm: SwmFile;
  docRepoId: string;
  branch: string;
  shouldSyncCrossRepo: boolean;
  isIde?: boolean;
}): Promise<AutosyncInput> {
  const swmSnippets = filterSwmCells(swm, SwmCellType.Snippet);
  const swmSymbols = getSwmSymbolsTupleByTypes(swm, [
    SwmSymbolType.PATH,
    SwmSymbolType.GENERIC_TEXT,
  ]) as SwmSmartSymbol[];

  const repoIdToGitInfoMap: Record<string, GitInfo> = await getRepoIdsToGitInfoMap({
    snippets: swmSnippets,
    symbols: swmSymbols,
    docRepoId,
    branch,
    isIde,
  });

  const snippets = convertSwmContentToAutosyncSnippet({
    swmSnippets,
    docRepoId,
    shouldSyncCrossRepo,
    repoIdToGitInfoMap,
  });

  let symbols: SmartSymbol[] = [];
  if (swm.symbols) {
    symbols = convertSwmSymbolsToAutosyncSymbols({
      swmSymbols,
      docRepoId,
      shouldSyncCrossRepo,
      repoIdToGitInfoMap,
    });
  }

  return { snippets, symbols };
}

export function convertSwmSnippetToAutosyncSnippet({
  swmSnippet,
  docRepoId,
  shouldSyncCrossRepo = true,
  gitInfo,
}: {
  swmSnippet: SwmCellSnippet;
  docRepoId: string;
  branch?: string;
  shouldSyncCrossRepo?: boolean;
  gitInfo: GitInfo;
}): Snippet {
  return convertSwmContentToAutosyncSnippet({
    swmSnippets: [swmSnippet],
    docRepoId,
    shouldSyncCrossRepo,
    repoIdToGitInfoMap: { [swmSnippet.repoId]: gitInfo },
  })[0];
}
