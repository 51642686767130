<script setup lang="ts">
import BaseLayoutGap from '../../components/BaseLayoutGap/BaseLayoutGap.vue';
import BaseHeading from '../../components/BaseHeading/BaseHeading.vue';
import BaseProse from '../../components/BaseProse/BaseProse.vue';
import BaseTooltip from '../../components/BaseTooltip/BaseTooltip.vue';
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';
import BaseDivider from '../../components/BaseDivider/BaseDivider.vue';

const emit = defineEmits<{
  advanced: [];
}>();

function onAdvanced() {
  emit('advanced');
}
</script>

<template>
  <header class="swm-selection-content-token-simple-header">
    <BaseLayoutGap alignment="stretch">
      <BaseHeading :level="6" :semantic-level="3">Suggested tokens</BaseHeading>
      <BaseLayoutGap>
        <BaseProse variant="secondary" size="xsmall">Ordered by relevance</BaseProse>
        <BaseTooltip>
          <template #content>Advanced mode</template>
          <BaseIcon
            name="expand"
            class="swm-selection-content-token-simple-header__advanced"
            @click.stop="onAdvanced()"
          />
        </BaseTooltip>
      </BaseLayoutGap>
    </BaseLayoutGap>
  </header>
  <BaseDivider />
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-selection-content-token-simple-header {
  $self: &;

  @include basic-resets;

  padding: var(--space-xsmall);
  user-select: none;

  &__advanced {
    cursor: pointer;
    font-size: var(--font-size-default);
  }
}
</style>
