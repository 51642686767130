import { watch } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useRouting } from '@/common/composables/routing';
import { gitwrapper } from '@swimm/shared';

export function useRepoStoreUtils() {
  const reposStore = useReposStore();
  const route = useRoute();
  const { reposStateData } = storeToRefs(reposStore);
  const { setRepoStateBranchData } = reposStore;
  const { getCurrentOrDefaultBranch, getDefaultBranch, isBranchProtected } = useRouting();

  watch(
    () => route.params,
    async ({ repoId, branch }, oldValue) => {
      if (repoId && (repoId !== oldValue.repoId || branch !== oldValue.branch)) {
        await assertRepoState(repoId);
      }
    }
  );

  async function assertRepoState(repoId) {
    if (!reposStateData.value[repoId]?.defaultBranch) {
      await getDefaultBranch(repoId);
    }

    const currentBranch = await getCurrentOrDefaultBranch(repoId);
    // avoid the save if there is no update
    if (currentBranch === reposStateData.value[repoId].branch) {
      return;
    }
    const isProtectedBranch = await isBranchProtected({ repoId: repoId, branch: currentBranch });
    await setRepoStateBranchData(repoId, { branch: currentBranch, isProtectedBranch });
  }

  async function assertAccessToRepo(repoId) {
    try {
      await gitwrapper.getRepoRemoteData({ repoId });
    } catch (err) {
      return false;
    }
    return true;
  }

  return {
    assertRepoState,
    assertAccessToRepo,
  };
}
