import { isAutomation, isProduction } from '@/config';
import { getLoggerNew } from '@swimm/shared';
import Cookies from 'js-cookie';

const logger = getLoggerNew(__modulename);

const HUBSPOT_API_URL = 'https://api.hsforms.com/submissions/v3/integration/submit';
const PORTAL_ID = '25477114';
const SIGNUP_FORM_ID = '2f9c8503-fcfb-45a1-9485-45e1f4a291e8';
const SIGNIN_FORM_ID = '938f1fe9-6f1f-4655-b170-ed1b149bd3f7';
const WORKSPACE_CREATED_FORM_ID = 'fecb9214-5532-40f9-ad58-9e89e6278522';
const WORKSPACE_CREATED_REQUEST_URL = `${HUBSPOT_API_URL}/${PORTAL_ID}/${WORKSPACE_CREATED_FORM_ID}`;
const SIGNUP_REQUEST_URL = `${HUBSPOT_API_URL}/${PORTAL_ID}/${SIGNUP_FORM_ID}`;
const SIGNIN_REQUEST_URL = `${HUBSPOT_API_URL}/${PORTAL_ID}/${SIGNIN_FORM_ID}`;

const isReportingEnvironment = isProduction && !isAutomation;
logger.debug(`HubSpot reporting is ${isReportingEnvironment ? 'enabled' : 'disabled'}`);

interface HubSpotField {
  // See https://developers.hubspot.com/docs/api/crm/crm-custom-objects
  objectTypeId: '0-1' | '0-2' | '0-3' | '0-5';
  name: string;
  value: string;
}

export async function reportHubspotSignUp({ email, name }: { email: string; name: string }) {
  if (!isReportingEnvironment) {
    logger.info(`[NO HUBSPOT] call reportHubspotSignUp event`);
    return null;
  }

  const firstName = name.split(' ')[0];
  const lastName = name.split(' ')[1] ?? '';
  try {
    await callHubSpotRequest(SIGNUP_REQUEST_URL, [
      generateField({
        name: 'firstname',
        value: firstName,
      }),
      generateField({
        name: 'lastname',
        value: lastName,
      }),
      generateField({
        name: 'email',
        value: email,
      }),
    ]);
  } catch (err) {
    logger.error({ err }, `Failed to call reportHubspotSignUp. Details: ${err}`);
  }
}

export async function reportHubspotCreateWorkspace({
  email,
  company,
  gitHosting,
  numEmployees,
}: {
  email: string;
  company: string;
  gitHosting: string;
  numEmployees: string;
}) {
  if (!isReportingEnvironment) {
    logger.info(`[NO HUBSPOT] call reportHubspotCreateWorkspace event`);
    return null;
  }
  try {
    await callHubSpotRequest(WORKSPACE_CREATED_REQUEST_URL, [
      generateField({
        name: 'email',
        value: email,
      }),
      generateField({
        name: 'company',
        value: company,
      }),
      generateField({
        name: 'git_hosting',
        value: gitHosting,
      }),
      generateField({
        name: 'numemployees',
        value: numEmployees,
      }),
    ]);
  } catch (err) {
    logger.error({ err }, `Failed to call reportHubspotCreateWorkspace. Details: ${err}`);
  }
}

export async function reportHubspotSignIn({ email }: { email: string; company: string; gitHosting: string }) {
  if (!isReportingEnvironment) {
    logger.info(`[NO HUBSPOT] call reportHubspotSignIn event`);
    return null;
  }

  try {
    await callHubSpotRequest(SIGNIN_REQUEST_URL, [
      generateField({
        name: 'email',
        value: email,
      }),
    ]);
  } catch (err) {
    logger.error({ err }, `Failed to call reportHubspotSignIn. Details: ${err}`);
  }
}

function generateField({ name, value }: { name: string; value: string }): HubSpotField {
  return {
    objectTypeId: '0-1',
    name,
    value,
  };
}

async function callHubSpotRequest(url: string, fields: Array<HubSpotField>) {
  await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      submittedAt: Date.now(),
      fields,
      ...generateContext(),
    }),
  });
}

function generateContext() {
  const hubspotCookie = Cookies.get('hubspotutk');

  if (hubspotCookie) {
    return {
      context: { hutk: hubspotCookie },
    };
  }

  const pageUrl = document.location.href;
  const utmQueryCookie = Cookies.get('utmQuery');

  if (utmQueryCookie) {
    const queryParams = new URLSearchParams(JSON.parse(utmQueryCookie)).toString();
    return {
      context: {
        pageUri: `${pageUrl}?${queryParams}`,
      },
    };
  }

  return {};
}
