<script setup lang="ts">
import EllipsisTooltip from '@/common/components/organisms/EllipsisTooltip.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import MarkdownViewer from '@/common/components/Markdown/MarkdownViewer.vue';
import { GitProviderIcons, GitProviderName, gitwrapper, productEvents } from '@swimm/shared';
import { Action, SwText } from '@swimm/ui';
import { type PropType, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';

export interface SelectionRepo {
  key: string;
  label: string;
  hasAccess: boolean;
  provider: GitProviderName;
  api_url?: string;
}

const props = defineProps({
  title: { type: String, required: true },
  selectedRepo: { type: Object as PropType<SelectionRepo>, default: null },
  reposList: { type: Array as PropType<SelectionRepo[]>, required: true },
  description: { type: String, required: true },
  buttonText: { type: String, default: 'Create' },
  buttonDisabled: { type: Boolean, default: false },
});

const emit = defineEmits(['click', 'repo-change', 'git-authorized']);
const analytics = useAnalytics();
const { authorizeProviderWithGit } = useGitAuthorizationStore();

const route = useRoute();
const isRepoSelected = computed(() => !!props.selectedRepo);
const disabledRepoSelection = computed(() => Boolean(route.params.repoId));
const selectedRepoHasAccess = computed(() => props.selectedRepo?.hasAccess);
const selectedRepoProviderDisplayName = computed(
  () => gitwrapper.getTerminology(props.selectedRepo.provider).displayName
);

const disableButton = computed(() => props.buttonDisabled || !isRepoSelected.value || !selectedRepoHasAccess.value);
function handleRepoChange(value: SelectionRepo) {
  if (disabledRepoSelection.value) {
    return;
  }
  emit('repo-change', value);
}

async function unauthorizedRepoClicked() {
  analytics.track(productEvents.CLICKED_AUTHORIZE_GITHUB, {
    Context: 'Creation Hub',
    'Workspace ID': route.params.workspaceId,
    'Git Provider': props.selectedRepo.provider,
  });

  await authorizeProviderWithGit({
    provider: props.selectedRepo.provider,
    gitUrl: props.selectedRepo.api_url,
    origin: 'Creation hub',
  });
  emit('git-authorized');
}
</script>

<template>
  <div class="banner">
    <div class="text-section">
      <div class="title-container">
        <SwText variant="subtitle-L">{{ title }}</SwText>
        <SwText variant="subtitle-L" weight="regular">in</SwText>
        <v-select
          :class="['repo-selector', { 'disabled-border': !selectedRepoHasAccess && isRepoSelected }]"
          :disabled="disabledRepoSelection || reposList.length <= 1"
          :clearable="false"
          :loading="!reposList.length"
          :model-value="selectedRepo"
          :options="reposList"
          append-to-body
          uid="creation-hub-repo-dropdown"
          placeholder="Select repository"
          @update:model-value="handleRepoChange"
        >
          <template #option="{ label, provider }">
            <div class="option">
              <Icon class="repo-icon" :name="GitProviderIcons[provider]" />

              <EllipsisTooltip long :content="label" :length="10" class="text-ellipsis body-S data-hj-suppress">
                {{ label }}
              </EllipsisTooltip>
            </div>
          </template>
          <template #spinner="{ loading }">
            <Loader v-if="loading" class="loader" />
          </template>
          <template #selected-option="{ label, provider }">
            <div :class="['option', { 'disabled-option': !selectedRepoHasAccess }]">
              <EllipsisTooltip long :content="label" :length="1" class="text-ellipsis body-S data-hj-suppress">
                <Icon class="repo-icon" :name="GitProviderIcons[provider]" />
                {{ label }}
              </EllipsisTooltip>
            </div>
          </template>
        </v-select>
      </div>
      <SwText variant="body-XS"><MarkdownViewer :marked-string="description" /></SwText>
    </div>
    <VMenu placement="top" :triggers="[]" :shown="disableButton" :auto-hide="false">
      <div class="button">
        <Action :disabled="disableButton" @click="$emit('click')" data-testid="create-button">{{ buttonText }}</Action>
      </div>
      <template #popper>
        <SwText v-if="!isRepoSelected" variant="body-S" class="padding">Select a repository to continue.</SwText>
        <div v-else-if="!selectedRepoHasAccess" class="authorize-container">
          <SwText variant="body-XS">
            {{ selectedRepoProviderDisplayName }} authorization is required to create code-coupled contents.</SwText
          >
          <SwText variant="body-XS" class="authorize-link" @click="unauthorizedRepoClicked"
            >Authorize {{ selectedRepoProviderDisplayName }}</SwText
          >
        </div>
      </template>
    </VMenu>
  </div>
</template>

<style scoped lang="postcss">
.authorize-link {
  cursor: pointer;
  color: var(--text-color-link);
}

.authorize-container {
  flex-direction: column;
  width: 140px;
  padding: var(--space-base);
  text-align: center;
}

.padding {
  padding: var(--space-base);
}

.banner {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  background-color: var(--color-selected);
  border: 1px solid #acb4ff;
  border-radius: 8px;
  margin: var(--space-md);
  padding: var(--space-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-base);
  height: 92px;
  box-sizing: border-box;

  .text-section {
    color: var(--text-color-secondary);
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
    flex: 1;

    .title-container {
      display: flex;
      align-items: center;
      gap: 4px;

      .repo-selector {
        width: 200px;
        margin-left: var(--space-base);

        &:not(.vs--disabled) :deep(.vs__dropdown-toggle) {
          cursor: pointer;
        }

        &.disabled-border :deep(.vs__dropdown-toggle) {
          border-color: var(--color-border-danger);
        }

        &.vs--disabled :deep(.vs__dropdown-toggle) {
          cursor: not-allowed;
        }

        :global(#vscreation-hub-repo-dropdown__listbox) {
          max-height: 140px;
        }

        :global(.v-select .vs__selected-options) {
          flex-wrap: nowrap;
          max-width: 90%;
        }
      }

      :global(.vs__dropdown-menu .vs__dropdown-option) {
        padding: 4px 12px;
      }

      :global(.vs__dropdown-menu .vs__dropdown-option .option) {
        display: flex;
        align-items: center;
      }

      :deep(.v-select) .vs__selected-options {
        & .vs__selected {
          max-width: 100%;

          & .option {
            max-width: 100%;

            &.disabled-option {
              color: var(--text-color-disable);
            }
          }
        }
      }
    }
  }

  .loader {
    --loader-size: 24px;
  }

  .button {
    display: flex;
    flex-shrink: 0;
  }
}
</style>
