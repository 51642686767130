<template>
  <div v-if="canEdit" @click="showEditTooltip" class="card-wrapper">
    <ListViewItem
      :title="unit.name"
      :stage-tag="{ text: applicabilityMessage.text }"
      :applicability-status="applicabilityStatus"
      type="document"
      :show-doc-tags="true"
      icon="doc"
      :creator="unit.creator_name"
      :doc-link="getUnitEditLink()"
    >
      <template #activity v-if="canEdit">
        <span v-if="unit.isDocFixedInDraft" @click="$emit('commit-click')" class="action-link">Commit</span>
        <router-link v-else-if="unit.isPending" :to="pendingTabUrl" class="action-link">Merge PR</router-link>
        <router-link v-else :to="getUnitEditLink()">
          <span class="action-link">Fix outdated</span>
        </router-link>
      </template>
    </ListViewItem>
  </div>
  <div v-else>
    <div class="card-wrapper" @click="showEditTooltip">
      <ListViewItem
        :title="unit.name"
        :stage-tag="{ text: applicabilityMessage.text }"
        :applicability-status="{ text: 'warning', isIcon: true, tooltip: 'Doc is outdated' }"
        :creator="unit.creator_name"
        :show-doc-tags="true"
        icon="doc"
        :inactive="true"
        type="document"
      >
        <template #info>
          <span class="left-side"> - - - - - </span>
        </template>
      </ListViewItem>
    </div>
  </div>
</template>

<script>
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import { useAppLinks } from '@/modules/core/compositions/app-links';
import swal from 'sweetalert';
import { ApplicabilityStatus } from '@swimm/shared';
import { useSwimmResource } from '@/common/composables/swimmResource';
import { useNavigate } from '@/common/composables/navigate';
import ListViewItem from '@/modules/core/components/ListViewItem.vue';
import { RepoPageRouteNames } from '@/common/consts';

export default {
  components: { ListViewItem },
  props: {
    unit: { type: Object, default: () => ({}) },
    source: { type: String, default: null },
  },
  emits: ['commit-click'],
  setup() {
    const { getAppLink } = useAppLinks();
    const { isResourceEditable } = useSwimmResource();
    const { getRepoPath } = useNavigate();

    return { getAppLink, isResourceEditable, getRepoPath, RepoPageRouteNames };
  },
  data() {
    return {
      loading: false,
      deleting: false,
      applicabilityMessages: {
        [ApplicabilityStatus.Outdated]: {
          text: 'Doc is outdated',
        },
        [ApplicabilityStatus.Autosyncable]: {
          text: 'Auto-synced',
        },
        [ApplicabilityStatus.Verified]: {
          text: 'Auto-synced',
        },
      },
    };
  },
  computed: {
    applicabilityMessage() {
      return this.applicabilityMessages[this.unit.applicabilityStatus];
    },
    canEdit() {
      return this.isResourceEditable(this.$route.params.repoId, this.unit.id, 'unit');
    },
    isTask() {
      return this.unit.task && this.unit.task.dod && this.unit.task.dod.length > 0;
    },
    applicabilityStatus() {
      if (this.unit.isDocFixedInDraft) {
        return { text: 'updated', isIcon: true, tooltip: 'Waiting to be committed' };
      }
      if (this.unit.isPending) {
        return { text: 'updated', isIcon: true, tooltip: 'Waiting to be merged' };
      }
      return { text: 'warning', isIcon: true, tooltip: 'Doc is outdated' };
    },
    pendingTabUrl() {
      return `${this.getRepoPath()}/${RepoPageRouteNames.PULL_REQUEST}`;
    },
  },
  methods: {
    getUnitEditLink() {
      return `${this.getRepoPath()}/docs/${this.unit.id}/edit?origin=${RepoPageRouteNames.NEEDS_REVIEW}${
        this.source ? `&source=${this.source}` : ''
      }`;
    },
    async showEditTooltip() {
      if (this.canEdit) {
        return;
      }
      if (this.isTask) {
        await swal({
          title: `Editing exercises is available on the Swimm desktop app`,
          text: `The Swimm Desktop App end of life was in August 2024`,
        });
      } else {
        await swal({
          title: `Cannot edit doc`,
          text: `You don't have permission to update this Swimm file.\nPlease ask the repo Admin to verify the status.`,
          content: SWAL_CONTACT_US_CONTENT(),
        });
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.action-link {
  cursor: pointer;
  color: var(--color-brand-hover);
}
</style>
