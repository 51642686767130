<template>
  <div class="github-app-settings-section">
    <div class="sub-header subtitle-XL">Email me when new docs are created</div>
    <NotifyNewDocs :repo-id="repoId" :workspace-id="workspaceId" :disabled="loading" />
    <Divider class="divider" />
    <div class="sub-header subtitle-XL">Configure automatic Auto-sync approval</div>
    <VMenu placement="left" popper-class="tooltip-on-modal">
      <CheckBoxSection
        text="By enabling this setting, you allow Swimm to automatically update and commit minor, out of sync code snippet
      inconsistencies in your docs."
        :value="autosyncEnabled"
        :disabled="disableAutomaticAutosyncConfiguration"
        :name="CheckBoxNames.AUTOSYNC"
        @click="onToggle"
      />
      <template #popper>
        <div class="autosync-approval-popup" v-if="disableAutomaticAutosyncConfiguration">
          <div class="subtitle-L bold">Upgrade to Enterprise to use this feature</div>
          <div class="body-XS">
            Automatic Auto-sync approval is only available to our Enterprise tier users. Please upgrade to enable this
            feature.
          </div>
          <a class="popover-button">
            <Action size="small" class="autosync-approval-popup-action" @click="handleUpgradeToProClick"
              >Upgrade to Enterprise</Action
            >
          </a>
        </div>
      </template>
    </VMenu>
    <CheckBoxSection
      :text="`Skip automatic Auto-sync approval on draft ${crTerminology}s.`"
      :value="noAutosyncDraftEnabled"
      :disabled="!autosyncEnabled"
      :name="CheckBoxNames.DRAFT_AUTOSYNC"
      @click="onToggle"
    />
    <Divider class="divider" />
    <div class="sub-header subtitle-XL">Configure Swimm {{ crTerminology }} check</div>
    <CheckBoxSection
      :text="`Allow Swimm to fail only when outdated/out of sync docs are caused by your current ${crTerminology}. When disabled, Swimm
      fails with every outdated/out of sync doc.`"
      :value="verifyModified"
      :disabled="loading"
      :name="CheckBoxNames.VERIFY_MODIFIED"
      @click="onToggle"
    />
    <Divider class="divider" />
    <div class="sub-header subtitle-XL">Customize {{ crTerminology }} comments</div>
    <CheckBoxSection
      :text="`Allow Swimm to add comments when your docs are outdated or out of sync. When disabled, you will only receive these updates in the ${
        isGitHubCloud ? 'Checks section' : 'CI summary'
      } of the ${crTerminology}.`"
      :value="prCommentsConfigs[0].enabled"
      :disabled="loading"
      :name="prCommentsConfigs[0].name"
      @click="onToggle"
    />
    <CheckBoxSection
      :text="`Allow Swimm to add a comment recommending to document suitable ${crTerminology}s.`"
      :value="prCommentsConfigs[1].enabled"
      :disabled="loading"
      :name="prCommentsConfigs[1].name"
      @click="onToggle"
    />
    <CheckBoxSection
      v-if="!doesProviderSupportMinimizeComments"
      :text="`Allow Swimm to delete old Swimm comments from ${crTerminology}s when new Swimm comments are added.`"
      :value="!disableDeleteComments"
      :disabled="loading"
      :name="CheckBoxNames.DISABLE_DELETE_COMMENTS"
      @click="onToggle"
    />
    <Divider class="divider" />
    <div class="sub-header subtitle-XL">Customize Auto-sync commit messages</div>
    <CheckBoxSection
      :text="`Customize the Auto-sync message to your ${crTerminology}s when using Auto-sync in ${gitHostingName}.`"
      :value="customCommitMsgEnabled"
      :disabled="loading"
      :name="CheckBoxNames.ENABLE_CUSTOM_COMMIT_MSG"
      @click="updateCheckBoxState(!customCommitMsgEnabled, CheckBoxNames.ENABLE_CUSTOM_COMMIT_MSG)"
    />
    <TextField
      class="custom-commit-msg"
      v-model="customCommitMsg"
      placeholder="docs(swimm): Auto-synced Swimm documents"
      :disabled="loading || !customCommitMsgEnabled"
      :maxlength="100"
      @update:model-value="onCommitMsgChange"
    />
  </div>
</template>

<script lang="ts">
import NotifyNewDocs from '@/common/components/CISettings/NotifyNewDocs.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { mapActions, mapGetters, useStore } from 'vuex';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import {
  GitProviderName,
  RepoCIIntegrationsToDefaultValues,
  StiggFeatures,
  billingPlanTypes,
  getLoggerNew,
  gitProviderUtils,
  gitwrapper,
  isRepoIdDummyRepo,
  productEvents,
} from '@swimm/shared';
import CheckBoxSection from '@/common/components/atoms/CheckBoxSection.vue';
import { useNotificationsStore } from '@swimm/editor';
import { Divider, TextField } from '@swimm/ui';
import { useStigg } from '@/common/composables/useStigg';
import { useBillingStore } from '@/modules/billing/store/billing';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { computed, ref } from 'vue';

const logger = getLoggerNew(__modulename);

const CheckBoxNames = {
  AUTOSYNC: 'autosync',
  DRAFT_AUTOSYNC: 'draft_autosync',
  VERIFY_MODIFIED: 'verify_modified',
  DISABLE_DELETE_COMMENTS: 'disable_delete_comments',
  VERIFY_COMMENTS: 'verify comments',
  RECOMMENDED_COMMENTS: 'recommended comments',
  ENABLE_CUSTOM_COMMIT_MSG: 'enable_custom_commit_msg',
};

const EventsOnToggle = {
  [CheckBoxNames.AUTOSYNC]: {
    nameForLog: 'automatic autosync approval',
    nameForUser: 'Automatic Auto-sync Approval',
    product: {
      event: productEvents.GITHUB_APP_AUTOSYNC_TOGGLE,
      context: 'Automatic Auto-sync Approval',
    },
  },
  [CheckBoxNames.DRAFT_AUTOSYNC]: {
    nameForLog: 'disable automatic autosync on Draft PRs',
    nameForUser: 'Disable automatic Auto-sync on Draft PRs',
    product: {
      event: productEvents.GITHUB_APP_AUTOSYNC_TOGGLE,
      context: 'Disable Automatic Auto-sync on Draft PRs',
    },
  },
  [CheckBoxNames.VERIFY_COMMENTS]: {
    nameForLog: 'comments on prs when verify fails',
    nameForUser: 'Comment Swimm Verify results',
    product: {
      event: productEvents.GITHUB_APP_COMMENTS_TOGGLE,
      context: 'Outdated',
    },
  },
  [CheckBoxNames.RECOMMENDED_COMMENTS]: {
    nameForLog: 'comments on prs when there are recommended docs',
    nameForUser: 'Comment recommended Swimm Docs',
    product: {
      event: productEvents.GITHUB_APP_COMMENTS_TOGGLE,
      context: 'Recommendations',
    },
  },
  [CheckBoxNames.VERIFY_MODIFIED]: {
    nameForLog: 'doc verification only on modification of related files',
    nameForUser: 'Swimm Verify files modified in PR',
    product: {
      event: productEvents.GITHUB_APP_VERIFY_MODIFIED_TOGGLE,
      context: 'PR Check',
    },
  },
  [CheckBoxNames.DISABLE_DELETE_COMMENTS]: {
    nameForLog: "don't delete comments in PRs",
    nameForUser: 'Allow Swimm to delete obsolete Swimm comments from Pull Requests when new Swimm comments are added',
    product: {
      event: productEvents.GITHUB_APP_DISABLE_DELETE_COMMENTS_TOGGLE,
      context: 'PR Check',
    },
  },
  [CheckBoxNames.ENABLE_CUSTOM_COMMIT_MSG]: {
    nameForLog: 'custom GitHub App commit message for PRs',
    nameForUser: 'Customize the commit message when autosyncing with the GitHub app',
    product: {
      event: productEvents.GITHUB_APP_ENABLE_CUSTOM_PR_COMMENTS,
      context: 'PR Check',
    },
  },
};

export default {
  components: {
    CheckBoxSection,
    NotifyNewDocs,
    Divider,
    TextField,
  },
  props: {
    repoId: { type: String, required: true },
    workspaceId: { type: String, required: true },
  },
  emits: ['close'],
  async setup(props, { emit }) {
    const store = useStore();
    const analytics = useAnalytics();
    const { addNotification } = useNotificationsStore();
    const { user } = storeToRefs(useAuthStore());
    const { stiggClient } = useStigg();
    const { setShowBillingPlans } = useBillingStore();

    const db_getRepoMetadata = computed(() => store.getters['database/db_getRepoMetadata']);
    const repoMetadata = computed(() => db_getRepoMetadata.value(props.repoId));
    const crTerminology = ref(gitwrapper.getTerminology(repoMetadata.value.provider).pullRequestShort);

    function handleUpgradeToProClick() {
      setShowBillingPlans(true);
      emit('close');
    }

    return {
      user,
      analytics,
      GitProviderName,
      CheckBoxNames,
      addNotification,
      stiggClient,
      handleUpgradeToProClick,
      repoMetadata,
      crTerminology,
    };
  },
  data() {
    return {
      autosyncEnabled: false,
      noAutosyncDraftEnabled: false,
      verifyModified: false,
      disableDeleteComments: false,
      updatingValueTimer: null,
      customCommitMsgEnabled: false,
      customCommitMsg: '',
      prCommentsConfigs: [
        {
          name: CheckBoxNames.VERIFY_COMMENTS,
          enabled: true,
          defaultValue: RepoCIIntegrationsToDefaultValues.github_app_comments_disabled,
          nameInDB: 'github_app_comments_disabled',
        },
        {
          name: CheckBoxNames.RECOMMENDED_COMMENTS,
          enabled: true,
          // Default false since it needs to be turned off for existing repos
          // New repos have this value in the db set to true when the repo is initialized
          defaultValue: RepoCIIntegrationsToDefaultValues.github_app_recommended_comments_disabled,
          nameInDB: 'github_app_recommended_comments_disabled',
        },
      ],
      loading: false,
      fieldsToUpdate: {},
    };
  },
  computed: {
    ...mapGetters('database', ['db_getRepoMetadata', 'db_getWorkspace']),
    integrations() {
      return this.repoMetadata && this.repoMetadata.integrations;
    },
    isGitHubCloud() {
      return this.repoMetadata?.provider === GitProviderName.GitHub;
    },
    gitHostingName() {
      return gitProviderUtils.getHostingNameByProvider(this.repoMetadata?.provider);
    },
    disableAutomaticAutosyncConfiguration() {
      if (this.loading) {
        return true;
      }

      const workspace = this.db_getWorkspace(this.$route.params.workspaceId);
      const workspaceLicense = workspace.license || billingPlanTypes.FREE;
      const canUserTurnOn =
        this.stiggClient && this.stiggClient.getBooleanEntitlement
          ? this.stiggClient.getBooleanEntitlement({ featureId: StiggFeatures.AUTOMATED_AUTOSYNC }).hasAccess
          : false;

      if (
        canUserTurnOn ||
        [billingPlanTypes.PRO, billingPlanTypes.ENTERPRISE].includes(workspaceLicense) || // Fallback for old customers
        this.autosyncEnabled // If the feature is turned on, do not disable the checkbox in order to allow users to turn it off
      ) {
        return false;
      }
      return true;
    },
    doesProviderSupportMinimizeComments() {
      return gitProviderUtils.doesProviderSupportMinimizeComments(this.repoMetadata?.provider);
    },
  },
  watch: {
    repoId: {
      immediate: true,
      handler() {
        this.updateCheckBoxStates();
      },
    },
  },
  methods: {
    ...mapActions('database', ['updateRepoMetadata']),
    updateCheckBoxStates() {
      if (this.integrations) {
        this.autosyncEnabled =
          this.integrations.automatic_autosync_approval ??
          RepoCIIntegrationsToDefaultValues.automatic_autosync_approval;
        this.noAutosyncDraftEnabled =
          this.integrations.no_draft_automatic_autosync_approval ??
          RepoCIIntegrationsToDefaultValues.no_draft_automatic_autosync_approval;
        this.verifyModified =
          this.integrations.verify_only_modified ?? RepoCIIntegrationsToDefaultValues.verify_only_modified;
        this.disableDeleteComments =
          this.integrations.delete_comments_disabled ?? RepoCIIntegrationsToDefaultValues.delete_comments_disabled;
        this.prCommentsConfigs.forEach(
          (config) => (config.enabled = !(this.integrations[config.nameInDB] ?? config.defaultValue))
        );
        if (this.integrations.custom_ci_commit_msg) {
          this.customCommitMsg = this.integrations.custom_ci_commit_msg;
          this.customCommitMsgEnabled = true;
        }
      }
    },
    async onToggle({ newValue, checkBoxName }) {
      this.loading = true;
      this.updateRepoFields(newValue, checkBoxName);
      // Call this function with a debounce since we don't want it sent immediately
      if (this.updatingValueTimer !== null) {
        clearTimeout(this.updatingValueTimer);
      }
      this.updatingValueTimer = setTimeout(this.updateRepoConfig, 3000);
      // Call this function in the "synchronous" part of the flow because we want to update the UI in real time.
      this.updateCheckBoxState(newValue, checkBoxName);
      this.loading = false;
    },
    async updateRepoConfig() {
      if (isRepoIdDummyRepo(this.repoId)) {
        return;
      }
      try {
        const updateRepoResponse = await CloudFunctions.updateRepositoryDocumentFields({
          repoId: this.repoId,
          workspaceId: this.workspaceId,
          fieldsToUpdate: {
            modifier_name: this.user.nickname,
            integrations: {
              ...this.integrations,
              ...this.fieldsToUpdate,
            },
          },
        });
        if (updateRepoResponse.data.status !== 'success') {
          throw new Error(`Failed to update integration values: ${JSON.stringify(this.fieldsToUpdate)}`);
        }
        await this.updateMetadata();
      } catch (err) {
        logger.error({ err }, `Failed to update settings ${JSON.stringify(this.fieldsToUpdate)}: ${err}`);
        this.addNotification('Failed to change GitHub App settings. Please try again or contact our support team.', {
          icon: 'warning',
        });
      }
    },
    reportToggleEvent(toggleEnabled, name) {
      const productEvent = EventsOnToggle[name].product.event;
      this.analytics.track(productEvent, {
        Enabled: toggleEnabled,
        'Workspace ID': this.workspaceId,
        'Repo ID': this.repoId,
        Origin: 'GitHub App Settings',
        Context: EventsOnToggle[name].product.context,
      });
    },
    logToggle(toggleEnabled, type) {
      logger.info(
        `${toggleEnabled ? 'Enabled' : 'Disabled'} ${EventsOnToggle[type].nameForLog} for repo ${this.repoId}`
      );
    },
    updateRepoFields(toggleEnabled, name) {
      switch (name) {
        case CheckBoxNames.AUTOSYNC: {
          this.fieldsToUpdate['automatic_autosync_approval'] = toggleEnabled;
          if (!toggleEnabled) {
            this.fieldsToUpdate['no_draft_automatic_autosync_approval'] = false;
          }
          break;
        }
        case CheckBoxNames.DRAFT_AUTOSYNC: {
          this.fieldsToUpdate['no_draft_automatic_autosync_approval'] = toggleEnabled;
          break;
        }
        case CheckBoxNames.VERIFY_COMMENTS:
        case CheckBoxNames.RECOMMENDED_COMMENTS: {
          const currentConfig = this.prCommentsConfigs.find((config) => config.name === name);
          if (!currentConfig) {
            logger.error(`Couldn't find config with name ${name}`);
          }
          this.fieldsToUpdate[currentConfig.nameInDB] = !toggleEnabled;
          break;
        }
        case CheckBoxNames.VERIFY_MODIFIED: {
          this.fieldsToUpdate['verify_only_modified'] = toggleEnabled;
          break;
        }
        case CheckBoxNames.DISABLE_DELETE_COMMENTS: {
          this.fieldsToUpdate['delete_comments_disabled'] = !toggleEnabled;
          break;
        }
      }
    },
    updateCheckBoxState(newValue, name) {
      switch (name) {
        case CheckBoxNames.AUTOSYNC: {
          this.autosyncEnabled = newValue;
          if (!newValue) {
            this.noAutosyncDraftEnabled = false;
          }
          break;
        }
        case CheckBoxNames.DRAFT_AUTOSYNC: {
          this.noAutosyncDraftEnabled = newValue;
          break;
        }
        case CheckBoxNames.VERIFY_COMMENTS:
        case CheckBoxNames.RECOMMENDED_COMMENTS: {
          const currentConfig = this.prCommentsConfigs.find((config) => config.name === name);
          if (!currentConfig) {
            logger.error(`Couldn't find config with name ${name}`);
          }
          currentConfig.enabled = newValue;
          break;
        }
        case CheckBoxNames.VERIFY_MODIFIED: {
          this.verifyModified = newValue;
          break;
        }
        case CheckBoxNames.DISABLE_DELETE_COMMENTS: {
          this.disableDeleteComments = !newValue;
          break;
        }
        case CheckBoxNames.ENABLE_CUSTOM_COMMIT_MSG: {
          if (!newValue) {
            this.customCommitMsg = null;
            this.onCommitMsgChange(null);
          }
          this.customCommitMsgEnabled = newValue;
          break;
        }
      }
      this.reportToggleEvent(newValue, name);
      this.logToggle(newValue, name);
    },
    async updateMetadata() {
      await this.updateRepoMetadata({ repoId: this.repoId });
    },
    onCommitMsgChange(value: string) {
      if (this.updatingValueTimer !== null) {
        clearTimeout(this.updatingValueTimer);
      }
      this.updatingValueTimer = setTimeout(() => {
        this.fieldsToUpdate['custom_ci_commit_msg'] = value;
        this.updateRepoConfig();
      }, 3000);
    },
  },
};
</script>

<style scoped>
.divider {
  margin: 16px 0;
}

.sub-header {
  margin-bottom: 16px;
  font-weight: 800;
}

.github-app-settings-section {
  padding-bottom: 24px;
}

.autosync-approval-popup {
  display: flex;
  padding: var(--space-base);
  width: 250px;
  flex-direction: column;
}

.autosync-approval-popup-action {
  margin-top: var(--space-base);
}

.popover-button {
  display: flex;
  justify-content: center;
}

.custom-commit-msg {
  max-width: 80%;
  margin: 16px 0 0 16px;
}
</style>
