import { type SwmCell, SwmCellType, type SwmSymbols } from '../types';
import { SwmBuilder } from '../swm-builder';

export interface TitleSuggestion {
  prefix?: string;
  suffix?: string;
}

export interface SwmFragment {
  cells: SwmCell[];
  symbols?: SwmSymbols;
}

export interface StaticContentSuggestion {
  kind: 'static';
  content: SwmFragment;
}

const buildContentSuggestion = (buildFn: (builder: SwmBuilder) => void): SwmFragment => {
  const builder = new SwmBuilder();
  buildFn(builder);
  return {
    cells: builder.swmFile.content,
    symbols: builder.swmFile.symbols,
  };
};

export type ContentSuggestion = {
  id: string;
  displayName: string;
  iconName: string;
  description: string;
  // Whether the content suggestion is expected to be used once in a document, as opposed to multiple times.
  expectedOnce: boolean;
} & StaticContentSuggestion;

export interface ContentSuggestionsTopic {
  id: string;
  displayName: string;
  icon: string;
  subjectName: string;
  // The text placeholder to show in the title selection when this topic is selected.
  subjectNamePlaceholder: string;
  subjectNameInstruction: string;
  titleSuggestions: TitleSuggestion[];
  contentSuggestions: ContentSuggestion[];
}

const generateDesignDecisions = () =>
  buildContentSuggestion((builder: SwmBuilder) => {
    builder.addTextCell(`## Design Decisions
We made some key design decisions when implementing {{subject}}, namely:`);
    for (let i = 0; i < 2; ++i) {
      builder.addTextCell(`### ${builder.createPlaceholder('design decision')}
${builder.createPlaceholder('explain design decision')}`);
      builder.addSnippetPlaceholderCell('Show design decision in code');
    }
  });

export const OverviewTopic: ContentSuggestionsTopic = {
  id: 'overview',
  displayName: 'Architecture/high-level overview',
  icon: 'topic-overview',
  subjectName: 'component',
  subjectNamePlaceholder: '<Enter component name>',
  subjectNameInstruction: 'Please enter the component name',
  titleSuggestions: [
    { suffix: ' Overview' },
    { suffix: ' Folder Structure' },
    { prefix: 'Intro to ' },
    { suffix: ' Architecture' },
  ],
  contentSuggestions: [
    {
      id: 'introduction',
      displayName: 'Introduction',
      iconName: 'Text',
      description: `Describe the component in a short sentence.

For example, What is its role?
What technologies are used?`,
      expectedOnce: true,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        builder.addTextCell(`# Introduction
This doc gives a high level overview of {{subject}}.
We use ${builder.createPlaceholder('technologies')}.

{{subject}} is located under ${builder.createPlaceholder('component path')}`);
        builder.addSnippetPlaceholderCell(
          "Show {{subject}}'s entrypoint (e.g. UI root, CLI command, Rest API endpoint)"
        );
      }),
    },
    {
      id: 'features',
      displayName: 'Features',
      iconName: 'Text',
      description: `Describe the features of {{subject}}`,
      expectedOnce: true,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        builder.addTextCell(`### Features

{{subject}} supports the following features:
- ${builder.createPlaceholder('feature 1')}
- ${builder.createPlaceholder('feature 2')}`);
      }),
    },
    {
      id: 'interface',
      displayName: 'System Interface',
      iconName: 'Text',
      description: `Describe the interfaces of this component.
For example, CLI, REST API, Cloud Functions...
Show screenshots if applicable.`,
      expectedOnce: false,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        const name = builder.createPlaceholder('Interface Name [e.g. CLI, REST API, etc.]');
        builder.addTextCell(`### ${name}

Our ${name} can be accessed via ${builder.createPlaceholder('URL/command/etc.')}.
<image>`);
      }),
    },
    {
      id: 'subcomponent',
      displayName: 'Subcomponent',
      iconName: 'Text',
      description: `Describe a subcomponent`,
      expectedOnce: false,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        const name = builder.createPlaceholder('Subcomponent Name');
        builder.addTextCell(`### ${name}

${name} is located under \`path/to/subcomponent\`.
It is responsible for ${builder.createPlaceholder('responsibilities')}.
It interacts with ${builder.createPlaceholder('interactions')}.`);
        builder.addSnippetPlaceholderCell(`Subcomponent entrypoint`);
      }),
    },
    {
      id: 'folder-structure',
      displayName: 'Folder Structure',
      iconName: 'folder',
      description: `Where is it in the code, subdirectories.
For example, CLI, REST API, Cloud Functions...
Show screenshots if applicable.`,
      expectedOnce: true,
      kind: 'static',
      content: {
        cells: [
          {
            type: SwmCellType.Text,
            text: `## Folder Structure
### <use /path>
Contains ...
### <use /path>
Contains ...`,
          },
        ],
      },
    },
    {
      id: 'design-decisions',
      displayName: 'Design Decisions',
      iconName: 'Text',
      description: `Explain key design decisions.`,
      expectedOnce: true,
      kind: 'static',
      content: generateDesignDecisions(),
    },
    {
      id: 'glossary',
      displayName: 'Glossary',
      iconName: 'Text',
      description: `Describe common terms in {{subject}}.

Which terms do readers need to know?`,
      expectedOnce: false,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        builder.addTextCell(`## Glossary
Here are some important terms to know when first entering {{subject}}:
 - **<term>**: ${builder.createPlaceholder('definition')}
 - **<term>**: ${builder.createPlaceholder('definition')}`);
      }),
    },
    {
      id: 'design-decisions',
      displayName: 'Design Decisions',
      iconName: 'Text',
      description: `Explain key design decisions.`,
      expectedOnce: true,
      kind: 'static',
      content: generateDesignDecisions(),
    },
    {
      id: 'types',
      displayName: 'Important Types',
      iconName: 'codeblock',
      description: `Describe types you need to know.

Show the type definition using code snippets so that the reader can see the type and find your doc in their IDE.`,
      expectedOnce: false,
      kind: 'static',
      content: {
        cells: [
          {
            type: SwmCellType.Text,
            text: '### `<type name>`',
          },
          {
            type: SwmCellType.SnippetPlaceholder,
            comment: 'Select type definition',
          },
        ],
      },
    },
    {
      id: 'testing',
      displayName: 'Testing',
      iconName: 'Text',
      description: `Explain the testing approach.`,
      expectedOnce: true,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        builder.addTextCell(`## Testing
We use ${builder.createPlaceholder('testing framework')} to test {{subject}}.
See this example test:`);
        builder.addSnippetPlaceholderCell(`Select example test`);
      }),
    },
  ],
};

export const FlowTopic: ContentSuggestionsTopic = {
  id: 'flow',
  displayName: 'A flow in the code',
  icon: 'topic-flow',
  subjectName: 'flow',
  subjectNamePlaceholder: '<Enter flow name>',
  subjectNameInstruction: 'Please enter the flow name',
  titleSuggestions: [
    { suffix: ' Flow' },
    { prefix: 'How ', suffix: ' Works' },
    { suffix: ' Walkthrough' },
    { suffix: ' - Main Flow' },
  ],
  contentSuggestions: [
    {
      id: 'introduction-start',
      displayName: 'Introduction & Flow Start',
      iconName: 'codeblock',
      description: `Describe the flow, show first step.`,
      expectedOnce: true,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        builder.addTextCell(`## Introduction
This doc describes the {{subject}} flow in our system.

We'll follow ${builder.createPlaceholder('flow name')}`);
        builder.addSnippetPlaceholderCell('Select first step');
      }),
    },
    {
      id: 'step',
      displayName: 'A step in the flow',
      iconName: 'codeblock',
      description: `Describe the step, show code snippet.`,
      expectedOnce: false,
      kind: 'static',
      content: {
        cells: [
          // HACK: For some reason, pasting just a snippet placeholder doesn't work (pastes the text).
          {
            type: SwmCellType.Text,
            text: '',
          },
          {
            type: SwmCellType.SnippetPlaceholder,
            comment: 'Select step',
          },
        ],
      },
    },
  ],
};

export const InternalAPITopic: ContentSuggestionsTopic = {
  id: 'api',
  displayName: 'An internal API',
  icon: 'topic-api',
  subjectName: 'API',
  subjectNamePlaceholder: '<Enter API name>',
  subjectNameInstruction: 'Please enter the API name',
  titleSuggestions: [
    { prefix: 'How to Use ' },
    { suffix: ' and How to Use It' },
    { prefix: 'Using the ', suffix: ' API' },
    { suffix: ' API Overview' },
  ],
  contentSuggestions: [
    {
      id: 'introduction-definition',
      displayName: 'Introduction & API Definition',
      iconName: 'codeblock',
      description: `Show where the API is defined.`,
      expectedOnce: true,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        builder.addTextCell(`## Introduction
This doc describes the {{subject}} API, which is useful for ${builder.createPlaceholder('explain')}.`);
        builder.addSnippetPlaceholderCell('Select API definition');
      }),
    },
    {
      id: 'simple-usage',
      displayName: 'Simple Usage',
      iconName: 'codeblock',
      description: `Show the most typical API usage.`,
      expectedOnce: true,
      kind: 'static',
      content: {
        cells: [
          {
            type: SwmCellType.Text,
            text: `## Simple Usage Example`,
          },
          {
            type: SwmCellType.SnippetPlaceholder,
            comment: 'Select most typical API usage',
          },
        ],
      },
    },
    {
      id: 'advanced-usage',
      displayName: 'Advanced Usages',
      iconName: 'codeblock',
      description: `Show more advanced use cases.`,
      expectedOnce: true,
      kind: 'static',
      content: {
        cells: [
          {
            type: SwmCellType.Text,
            text: `## Advanced Usage Examples
Here we'll show some more advanced usage examples:`,
          },
          {
            type: SwmCellType.SnippetPlaceholder,
            comment: 'Select special API usage example',
          },
          {
            type: SwmCellType.Text,
            text: ``,
          },
          {
            type: SwmCellType.SnippetPlaceholder,
            comment: 'Select special API usage example',
          },
        ],
      },
    },
  ],
};

export const HowToAddTopic: ContentSuggestionsTopic = {
  id: 'add',
  displayName: 'How to add something',
  icon: 'topic-add',
  subjectName: 'added thing',
  subjectNamePlaceholder: '<Enter added thing name>',
  subjectNameInstruction: 'Please enter the name of the thing being added',
  titleSuggestions: [
    { prefix: 'How to Add {{subject-indefinite}} ' },
    { prefix: 'Creating {{subject-indefinite}} ' },
    { prefix: 'Adding {{subject-indefinite}} ' },
    { prefix: 'How to Add a New ' },
  ],
  contentSuggestions: [
    {
      id: 'introduction',
      displayName: 'Introduction',
      iconName: 'Text',
      description: `Introduce the added thing, show examples`,
      expectedOnce: true,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        builder.addTextCell(`In this document, we will learn how to add a new {{subject}} to the system.

{{Subject-indefinite}} {{subject}} is ${builder.createPlaceholder('explain its role in the system')}.

When we add a new {{subject}}, we create a class that inherits from \`ClassName\`.

Some examples of {{subject}}s are \`Example1\`, \`Example2\`, and \`Example3\`.`);
      }),
    },
    {
      id: 'tldr',
      displayName: 'TL;DR',
      iconName: 'Text',
      description: `List the steps once sentence each`,
      expectedOnce: true,
      kind: 'static',
      content: {
        cells: [
          {
            type: SwmCellType.Text,
            text: `## TL;DR 
1. Create a new class inheriting from \`BaseClassName\`. 
  - Place the file under <path>
1. Implement \`methodNameHere\`
1. Define  \`variableNameHere\`
1. Update \`filePathHere\`.
1. Profit 💰`,
          },
        ],
      },
    },
    {
      id: 'example',
      displayName: 'Example',
      iconName: 'codeblock',
      description: `Show an example of a {{subject}}`,
      expectedOnce: false,
      kind: 'static',
      content: {
        cells: [
          {
            type: SwmCellType.Text,
            text: `## Example {{subject}}: \`exampleName\`
We'll follow the implementation of  \`exampleName\` for this example.`,
          },
          {
            type: SwmCellType.SnippetPlaceholder,
            comment: 'Select example',
          },
        ],
      },
    },
    {
      id: 'step',
      displayName: 'A step in the flow',
      iconName: 'codeblock',
      description: `Show an example of a {{subject}}`,
      expectedOnce: false,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        builder.addTextCell(`## Step #: <step>
Every time we add a {{subject}}, we ${builder.createPlaceholder('explain the step')}.

For example, in our example {{subject}} we ${builder.createPlaceholder('explain the step in this example')}.`);
        builder.addSnippetPlaceholderCell('Show how this step is done in the example {{subject}}');
      }),
    },
  ],
};

export const RecentlyAddedFeatureTopic: ContentSuggestionsTopic = {
  id: 'feature',
  displayName: 'A recently added feature',
  icon: 'github',
  subjectName: 'feature',
  subjectNamePlaceholder: '<Enter feature name>',
  subjectNameInstruction: 'Please enter the feature name',
  titleSuggestions: [
    { prefix: 'How ', suffix: ' Works' },
    { suffix: ' Overview' },
    { prefix: 'Our ', suffix: ' Implementation' },
    { prefix: 'How We Built ' },
  ],
  contentSuggestions: [
    {
      id: 'introduction',
      displayName: 'Introduction',
      iconName: 'Text',
      description: `Introduce the feature, show entrypoint`,
      expectedOnce: true,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        builder.addTextCell(`We have a {{subject}} feature, allowing ${builder.createPlaceholder(
          'explain the feature'
        )}.
          
<insert picture of feature>`);
        builder.addSnippetPlaceholderCell('Select feature entrypoint (where it interacts with the rest of the system)');
      }),
    },
    {
      id: 'design-decisions',
      displayName: 'Design Decisions',
      iconName: 'Text',
      description: `Explain key design decisions.`,
      expectedOnce: true,
      kind: 'static',
      content: generateDesignDecisions(),
    },
    {
      id: 'implementation',
      displayName: 'Point of interest',
      iconName: 'codeblock',
      description: `Show an interesting point in the implementation.`,
      expectedOnce: false,
      kind: 'static',
      content: {
        cells: [
          // HACK: For some reason, pasting just a snippet placeholder doesn't work (pastes the text).
          {
            type: SwmCellType.Text,
            text: ' ',
          },
          {
            type: SwmCellType.SnippetPlaceholder,
            comment: 'Select interesting point in the implementation',
          },
        ],
      },
    },
  ],
};

export const IntegrationTopic: ContentSuggestionsTopic = {
  id: 'integration',
  displayName: 'An integration with a 3rd-party',
  icon: 'topic-integration',
  subjectName: 'integration',
  subjectNamePlaceholder: '<Enter 3rd-party name>',
  subjectNameInstruction: 'Please enter the 3rd-party name',
  titleSuggestions: [{ suffix: ' Integration Overview' }, { prefix: 'How We Use ' }, { prefix: 'Intro to ' }],
  contentSuggestions: [
    {
      id: 'introduction',
      displayName: 'Introduction',
      iconName: 'Text',
      description: `Introduce the feature, show entrypoint`,
      expectedOnce: true,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        builder.addTextCell(`We use {{subject}} for ${builder.createPlaceholder('explain the purpose')}.
We chose to use {{subject}} because ${builder.createPlaceholder('explain why')}.

The documentation for {{subject}} can be found [here](<link>).`);
        builder.createPlaceholder('Select integration entrypoint (e.g. wrapper function)');
      }),
    },
    {
      id: 'usage-example',
      displayName: 'Usage Example',
      iconName: 'codeblock',
      description: `Show an example of using the integration.`,
      expectedOnce: false,
      kind: 'static',
      content: {
        cells: [
          {
            type: SwmCellType.Text,
            text: `## Usage Example
Here's a simple example of using our {{subject}} integration:`,
          },
          {
            type: SwmCellType.SnippetPlaceholder,
            comment: 'Select example of using the integration',
          },
        ],
      },
    },
    {
      id: 'design-decisions',
      displayName: 'Design Decisions',
      iconName: 'Text',
      description: `Explain key design decisions.`,
      expectedOnce: true,
      kind: 'static',
      content: generateDesignDecisions(),
    },
    {
      id: 'implementation',
      displayName: 'Point of interest',
      iconName: 'codeblock',
      description: `Show an interesting point in the integration.`,
      expectedOnce: false,
      kind: 'static',
      content: {
        cells: [
          // HACK: For some reason, pasting just a snippet placeholder doesn't work (pastes the text).
          {
            type: SwmCellType.Text,
            text: ' ',
          },
          {
            type: SwmCellType.SnippetPlaceholder,
            comment: 'Select interesting point in the integration',
          },
        ],
      },
    },
  ],
};

export const GeneralTopic: ContentSuggestionsTopic = {
  id: 'general',
  displayName: 'General',
  icon: 'topic-general',
  subjectName: 'subject',
  subjectNamePlaceholder: 'Enter document title',
  subjectNameInstruction: 'Please enter the document title',
  titleSuggestions: [{ prefix: 'How to ' }, { prefix: 'Intro to ' }],
  contentSuggestions: [
    {
      id: 'introduction',
      displayName: 'Introduction',
      iconName: 'Text',
      description: `What will the reader learn?`,
      expectedOnce: true,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        builder.addTextCell(`## Introduction

This document covers ${builder.createPlaceholder('explain the scope of the document')}.`);
        builder.addSnippetPlaceholderCell('Select relevant code snippet from your repo');
      }),
    },
    {
      id: 'requirements',
      displayName: 'Requirements',
      iconName: 'Text',
      description: `Explain the requirements for this doc.`,
      expectedOnce: true,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        builder.addTextCell(`### Requirements
Before reading this document, you should be familiar with:
- <explain> (use /doc to link to other docs)
- <explain> (use /doc to link to other docs)`);
        builder.addSnippetPlaceholderCell('Select relevant code snippet from your repo');
      }),
    },
    {
      id: 'code-snippet',
      displayName: 'Code Snippet',
      iconName: 'codeblock',
      description: `Show what you are talking about
      
Swimm will keep these up to date automatically!`,
      expectedOnce: true,
      kind: 'static',
      content: {
        cells: [
          // HACK: For some reason, pasting just a snippet placeholder doesn't work (pastes the text).
          {
            type: SwmCellType.Text,
            text: '',
          },
          {
            type: SwmCellType.SnippetPlaceholder,
            comment: 'Select step',
          },
        ],
      },
    },
    {
      id: 'summary',
      displayName: 'Summary',
      iconName: 'Text',
      description: `Recap the document.`,
      expectedOnce: true,
      kind: 'static',
      content: buildContentSuggestion((builder: SwmBuilder) => {
        builder.addTextCell(`## Summary

In conclusion, ${builder.createPlaceholder('explain why the topic is important')}.

Now, ${builder.createPlaceholder('explain what the reader should do next')}`);
      }),
    },
  ],
};

export const ContentSuggestionsTopics = [
  GeneralTopic,
  OverviewTopic,
  FlowTopic,
  InternalAPITopic,
  HowToAddTopic,
  RecentlyAddedFeatureTopic,
  IntegrationTopic,
] as const;

export const guessTopicAndSubjectFromTitle = (title: string): { topic: ContentSuggestionsTopic; subject: string } => {
  if (!title) {
    return { topic: GeneralTopic, subject: '' };
  }
  const titleLowercase = title.toLowerCase();
  const [topic, titleSuggestion] =
    ContentSuggestionsTopics.map<[ContentSuggestionsTopic, TitleSuggestion]>((topic) => {
      const matchingTitleSuggestion = topic.titleSuggestions.find(
        ({ prefix, suffix }) =>
          titleLowercase.startsWith(prefix?.toLowerCase() ?? '') && titleLowercase.endsWith(suffix?.toLowerCase() ?? '')
      );
      return [topic, matchingTitleSuggestion];
    }).find(([, titleSuggestion]) => titleSuggestion) ?? [];
  if (!topic) {
    return { topic: GeneralTopic, subject: title };
  }
  return {
    topic,
    subject: title.substring(
      (titleSuggestion?.prefix ?? '').length,
      title.length - (titleSuggestion?.suffix ?? '').length
    ),
  };
};
