class PopoverHandler {
  currentPopover: { open: () => void; close: () => void; onKeyDown: (event: KeyboardEvent) => boolean } | null = null;
  popoverHandler: Map<string, ReturnType<typeof setTimeout>[]>; // map decoration key to timeoutId

  constructor() {
    this.popoverHandler = new Map<string, ReturnType<typeof setTimeout>[]>();
  }

  openPopover(key: string) {
    this.clearPendingPopovers();
    if (!this.popoverHandler.has(key)) {
      this.popoverHandler.set(key, []);
    }
    this.popoverHandler.get(key)?.push(
      setTimeout(() => {
        this.currentPopover?.open();
      }, 150)
    );
  }

  clearPendingPopovers(key?: string) {
    // cancel opening the popover if it hasn't been opened yet
    if (key) {
      for (const timeout of this.popoverHandler.get(key) ?? []) {
        clearTimeout(timeout);
      }
      this.popoverHandler.delete(key);
    } else {
      for (const timeouts of this.popoverHandler.values()) {
        for (const timeout of timeouts) {
          clearTimeout(timeout);
        }
      }
      this.popoverHandler.clear();
    }
  }

  closePopover(key?: string) {
    this.clearPendingPopovers(key);

    this.currentPopover?.close();
    this.currentPopover = null;
  }
}

export default PopoverHandler;
