import { collectionNames, getDocFromCollection, setValuesInDoc } from '@/adapters-common/firestore-wrapper';
import { UserOnboardingFields, config, getLoggerNew, state } from '@swimm/shared';
import swal from 'sweetalert';
import { asyncSleep } from '@swimm/editor';
import { SWAL_CONTACT_US_CONTENT } from './common-definitions';
import { v4 as uuidv4 } from 'uuid';
import * as firestore from '@/adapters-common/firestore-wrapper';

const logger = getLoggerNew(__modulename);

export const UserOnboardingCustomSteps = {
  FIRST_COMMIT: 'firstCommit',
};
export async function getUserFromDB(userId: string) {
  const result = await getDocFromCollection(collectionNames.USERS, userId);

  if (result.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error getting user from DB for user ${userId}, error: ${result.errorMessage}`);
    return {};
  }
  return result.data;
}

export async function setUserFieldInDB(userId: string, field: string, value: unknown) {
  const result = await setValuesInDoc(
    collectionNames.USERS,
    userId,
    { [field]: value, updated: firestore.firestoreTimestamp() },
    { merge: true }
  );

  if (result.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error setting user field in the DB for user ${userId}, error: ${result.errorMessage}`);
  }
}

export async function setUserCreatedFieldInDb(userId: string) {
  await setUserFieldInDB(userId, UserOnboardingFields.CREATED, firestore.firestoreTimestamp());
}
export async function getGetStartedProgressFromDB(userId: string) {
  const user = await getUserFromDB(userId);
  return user[UserOnboardingFields.GET_STARTED_STEPS] || {};
}

export async function generateAndSaveSaltInDB(userId: string, retry = true) {
  const salt = uuidv4();
  const saveResponse = await setValuesInDoc(collectionNames.USERS, userId, { salt }, { merge: true });
  if (saveResponse.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error setting salt in DB for user ${userId}, error: ${saveResponse.errorMessage}`);
    if (retry) {
      await asyncSleep(4000);
      return await generateAndSaveSaltInDB(userId, false);
    }

    await swal({
      title: 'Failed to set user configuration, please refresh the page',
      content: { element: SWAL_CONTACT_US_CONTENT() },
    });
    return;
  } else {
    return salt;
  }
}

export async function setSaltInLocalState(salt: string) {
  await state.set({ key: 'salt', value: salt });
}

export function getProviderDataInfo({ providerData, delegate = null }) {
  if (providerData) {
    return providerData[0].providerId;
  } else {
    return delegate ? delegate.providerData[0]?.providerId : '';
  }
}

export function getProviderUidDataInfo({ providerData, delegate = null }) {
  if (providerData) {
    return providerData[0]?.uid;
  } else {
    return delegate ? delegate.providerData[0]?.uid : '';
  }
}
