import { identity, orderBy } from 'lodash-es';
import { SOURCE_PROPERTIES_PARAMS } from './common-definitions';
import Cookies from 'js-cookie';

export interface UTMSourceProperties {
  utmCampaign: string;
  utmMedium: string;
  utmSource: string;
  referral: string;
}

import { asyncSleep } from '@swimm/editor';

class TimeoutError extends Error {}

export async function waitUntil({
  name,
  func,
  predicate = (result) => !!result,
  timeout = 60000,
  interval = 1000,
  whenUnready = null,
  whenReady = null,
}) {
  const startTime = new Date();
  let result;
  for (;;) {
    result = await func();
    if (predicate(result)) {
      if (whenReady) {
        whenReady();
      }
      return result;
    }
    if (whenUnready) {
      whenUnready();
    }
    await asyncSleep(interval);
    if (new Date().getTime() - startTime.getTime() >= timeout) {
      throw new TimeoutError(`Timed out waiting for "${name}"`);
    }
  }
}

export function throttle(callback, wait) {
  let time = Date.now();
  return function (...rest) {
    if (time + wait - Date.now() < 0) {
      // @ts-ignore
      callback.apply(this, rest);
      time = Date.now();
    }
  };
}

export function pluralize({ word, count, ending = 's' }) {
  if (count !== 1) {
    return `${word}${ending}`;
  }
  return word;
}

export function sortByProp(array, prop, order: 'desc' | 'asc' = 'desc') {
  if (!Array.isArray(array)) {
    return [];
  }
  // Using lodash orderBy to be able to sort sub-sub props (obj.some.prop)
  return orderBy(array, prop, [order]);
}

/**
 * Returns a key-value object of the source properties with their values from the Local Storage
 *
 * @param {Object} config - Configuration for the returned source properties object
 * @param {string} config.valueFallback - Default value to use for each source property value if it isn't stored in the Local Storage
 * @param {function(string):string} config.keyFormatCallback - A callback to format the keys of the returned object's keys. Default - identity function (returns first received argument as is)
 * @returns {Object} A key-value object of the source properties with their values from the Local Storage
 */
export function getSourcePropertiesFromLocalStorage({
  valueFallback,
  keyFormatCallback = identity,
}: {
  valueFallback?: string;
  keyFormatCallback: (key: string) => string;
}): UTMSourceProperties {
  const utmQueryFromStoreCookie = Cookies.get('utmQuery');
  let referrerFromCookie = Cookies.get('referrer');
  if (!referrerFromCookie) {
    referrerFromCookie = undefined;
  }
  let utmQueryFromStore = '';
  if (utmQueryFromStoreCookie) {
    utmQueryFromStore = JSON.parse(utmQueryFromStoreCookie);
  }
  // The following params are stored in localStorage from query params by the router storeQueryParamsOnLocalStorage function, if exist, after changing routing in the web app
  const utmParams = SOURCE_PROPERTIES_PARAMS.reduce((accumulatedProps, queryKey) => {
    const queryValue = utmQueryFromStore[queryKey];
    const objtoreturn = {
      ...accumulatedProps,
      [keyFormatCallback(queryKey)]: queryValue ? queryValue.toString() : valueFallback,
    };
    return objtoreturn;
  }, {});

  return { ...utmParams, referral: referrerFromCookie } as UTMSourceProperties;
}

export function countDocumentationTypes(items): { folder: number; playlist: number; document: number; draft: number } {
  return items.reduce(
    (acc, item) => {
      if (item.draftId) {
        return {
          ...acc,
          [item.documentationType]: acc[item.documentationType] + 1,
          draft: acc.draft + 1,
        };
      }
      return {
        ...acc,
        [item.documentationType]: acc[item.documentationType] + 1,
      };
    },
    { folder: 0, playlist: 0, document: 0, draft: 0 }
  );
}
