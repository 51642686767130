export class AsyncCache<TCached, TKey = undefined, TActualKey = TKey> {
  private readonly cache: Map<TActualKey, TCached | Promise<TCached>>;
  private readonly actualKeySelector?: (key: TKey) => TActualKey;

  constructor(keySelector?: (key: TKey) => TActualKey) {
    this.actualKeySelector = keySelector;
    this.cache = new Map();
  }

  getActualKey(key: TKey): TActualKey {
    return this.actualKeySelector ? this.actualKeySelector(key) : (key as unknown as TActualKey);
  }

  async get(func: () => Promise<TCached>): Promise<TCached>;
  async get(func: (key: TKey) => Promise<TCached>, key: TKey): Promise<TCached>;
  async get(func: (key: TKey) => Promise<TCached>, key?: TKey): Promise<TCached> {
    // OPTIMIZATION: get() might be executed many times in parallel for the same value, and if we just call func here we
    // Get many repeated calls with the same arguments, as all the concurrent calls get a cache miss then compute the
    // Value. We therefore store the promise in the cache so that subsequent concurrent calls wait for the first one
    // And not re-invoke func().
    const actualKey = this.getActualKey(key);
    const cacheHit = this.cache.get(actualKey);
    if (cacheHit) {
      return cacheHit;
    }
    const promise = func(key);
    this.cache.set(actualKey, promise);
    const res = await promise;
    this.cache.set(actualKey, res);
    return promise;
  }

  isCachedOrPending(key?: TKey): boolean {
    const actualKey = this.getActualKey(key);
    return this.cache.has(actualKey);
  }

  getCached(key?: TKey): Promise<TCached> | TCached {
    const actualKey = this.getActualKey(key);
    return this.cache.get(actualKey);
  }

  *iterCached(): Iterable<[TActualKey, TCached]> {
    for (const [actualKey, valueOrPromise] of this.cache.entries()) {
      if (valueOrPromise instanceof Promise) {
        continue;
      }
      yield [actualKey, valueOrPromise];
    }
  }

  clear() {
    this.cache.clear();
  }

  clearKey(key: TKey) {
    const actualKey = this.getActualKey(key);
    this.cache.delete(actualKey);
  }
}
