import * as config from '../config';
import { SwmResourceURLPath } from '../types';
import { decodeStringSafely } from './string-utils';

export function isSwimmResourceLink(path) {
  const regex = new RegExp(config.swimmWebResourceMatcher);
  return regex.test(path);
}

export function isSwimmDoc(path) {
  if (!isSwimmResourceLink(path)) {
    return false;
  }
  const linkGroups = getSwimmUrlGroups(path);
  // Index 2 points to link collection type
  return linkGroups.resourceType === SwmResourceURLPath.Docs;
}

export function isSwimmPlaylist(path) {
  if (!isSwimmResourceLink(path)) {
    return false;
  }
  const linkGroups = getSwimmUrlGroups(path);
  // Index 2 points to link collection type
  return linkGroups.resourceType === SwmResourceURLPath.Playlists;
}
export function getSwimmUrlGroups(path) {
  const regex = new RegExp(config.swimmWebResourceGroupsRegex);
  const groups = path.match(regex).groups;
  if (groups.resourceId === 'new' && groups.draftId) {
    return {
      ...groups,
      resourceId: decodeStringSafely(groups.draftId ?? null),
    };
  }
  return groups;
}
