import { schema } from './extensions';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { forAllSwimmNodes } from './swimm_node';
import { type SwimmDocument, removePrefix } from '@swimm/shared';
import { uniqBy } from 'lodash-es';

/**
 * Returns an array of unique paths in a Swimm document references from symbols paths and snippets.
 * @param {SwimmDocument} swimmDoc - The Swimm document to extract paths from.
 * @returns {Array<{ path: string; repoId: string }>} An array of unique paths in the Swimm document.
 */
export function getPathsInSwimmDoc(swimmDoc: SwimmDocument): { path: string; repoId: string }[] {
  const rootNode = ProseMirrorNode.fromJSON(schema, swimmDoc.content);
  const paths: { path: string; repoId: string }[] = [];
  forAllSwimmNodes(rootNode, (node) => {
    switch (node.type.name) {
      case 'swmSnippet':
      case 'swmToken':
        paths.push({ repoId: node.attrs.repoId, path: removePrefix(node.attrs.path, '/') });
        break;
      case 'swmPath':
        paths.push({ repoId: node.attrs.repoId, path: removePrefix(node.attrs.href, '/') });
        break;
    }
    return true;
  });
  return uniqBy(paths, ({ repoId, path }) => `${repoId}###${path}`);
}

export function isDocCrossRepo(rootNode: ProseMirrorNode, repoId: string): boolean {
  let foundCrossRepo = false;
  forAllSwimmNodes(rootNode, (node) => {
    if (['swmToken', 'swmSnippet', 'swmPath'].includes(node.type.name) && node.attrs.repoId !== repoId) {
      foundCrossRepo = true;
      return false;
    }
    return true;
  });
  return foundCrossRepo;
}

export function getDocElementsStats(rootNode: ProseMirrorNode): { snippets: number; paths: number; tokens: number } {
  let snippets = 0;
  let paths = 0;
  let tokens = 0;
  forAllSwimmNodes(rootNode, (node) => {
    if (node.type.name === 'swmSnippet') {
      snippets++;
    } else if (node.type.name === 'swmPath') {
      paths++;
    } else if (node.type.name === 'swmToken') {
      tokens++;
    }
    return true;
  });
  return { snippets, paths, tokens };
}
