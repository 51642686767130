<script setup lang="ts">
import { ref, toRef } from 'vue';
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { useTiptapIsSelected } from '@/composables/tiptapIsSelected';
import type * as _model from '@tiptap/pm/model';
import ImageNotFound from '@/components/ImageNotFound.vue';
import ImageView from '@/components/ImageView.vue';

const props = defineProps(nodeViewProps);

const error = ref(false);

const { selected } = useTiptapIsSelected(toRef(props, 'editor'), toRef(props, 'node'), toRef(props, 'getPos'));
</script>

<template>
  <NodeViewWrapper
    as="span"
    draggable="true"
    data-drag-handle
    :class="{ image: true, 'image--selected': selected }"
    data-testid="image-node-view"
  >
    <span
      class="image__container"
      :style="{
        width: node.attrs.width,
      }"
    >
      <ImageView
        v-if="!error"
        data-testid="image"
        class="image__image"
        :src="node.attrs.src"
        :alt="node.attrs.alt"
        :title="node.attrs.title"
        :editor="editor"
        @error="error = true"
      />
      <ImageNotFound v-else :src="node.attrs.src" data-testid="error-image" />
    </span>
  </NodeViewWrapper>
</template>

<style scoped lang="scss">
.image {
  text-align: center;
  position: relative;

  &__container {
    display: inline-block;
    position: relative;
    line-height: 0;
  }

  &--selected &__container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-bg-selected);
    opacity: 0.3;
  }

  &__image {
    width: 100%;
  }
}
</style>
