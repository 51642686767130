import {
  getSystemTheme,
  saveThemeInDB,
  setThemeForAppInBrowser,
  setThemeInLocalState,
} from '@/common/utils/theme-utils';
import {
  type ThemeOption,
  ThemeOptions,
  type UserInUsersDBCollection,
  UserOnboardingFields,
  config,
  getLoggerNew,
  state as localState,
} from '@swimm/shared';
import {
  collectionNames,
  firestoreTimestamp,
  getDocFromCollection,
  getDocsRefWithWhereClauses,
} from '@/adapters-common/firestore-wrapper';
import { generateAndSaveSaltInDB, setSaltInLocalState, setUserFieldInDB } from '@/common/utils/user-utils';
import { STORES } from './store-constants';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

const logger = getLoggerNew(__modulename);

export const useUserConfigStore = defineStore(STORES.USER_CONFIG, () => {
  const userConfig = ref(null);
  const userConfigPromise = ref(null);
  const theme = ref<ThemeOption>(ThemeOptions.LIGHT);
  const salt = ref(undefined);
  const installedIdePluginVSCode = ref(false);
  const installedIdePluginJetBrains = ref(false);
  const installedIdePlugin = computed(() => installedIdePluginVSCode.value || installedIdePluginJetBrains.value);
  let installedIdePluginPromise: Promise<unknown> | null = null;
  const firstWorkspace = ref<string>(null);
  const isFinishedGetStarted = ref(true);
  const shownHelpTooltips = ref(new Set());
  const shownExistingUsersHelpTooltips = ref(new Set());
  const showAnimationforSteps = ref([]);
  const isAutoCompleteEnabled = ref(null);

  function resetState() {
    setThemeForAppInBrowser(getSystemTheme());
    theme.value = 'system';
    userConfig.value = null;
    userConfigPromise.value = null;
    installedIdePluginVSCode.value = false;
    installedIdePluginJetBrains.value = false;
    installedIdePluginPromise = null;
    isAutoCompleteEnabled.value = false;
  }

  async function fetchTheme() {
    let userTheme: ThemeOption;
    const persistentTheme = await localState.get({ key: 'theme' });
    if (Object.values(ThemeOptions).includes(persistentTheme)) {
      userTheme = persistentTheme;
    } else {
      userTheme = getSystemTheme();
      setThemeInLocalState('system');
      setThemeForAppInBrowser(userTheme);
    }
    theme.value = userTheme;
  }

  async function fetchUserSalt({ userId }) {
    let userSalt: string;
    const saltFromLocalState = await localState.get({ key: 'salt' });
    if (saltFromLocalState) {
      userSalt = saltFromLocalState;
    } else {
      const userConfig = await getOrFetchUserConfig(userId, true);
      if (userConfig && userConfig.salt) {
        userSalt = userConfig.salt;
      } else {
        userSalt = await generateAndSaveSaltInDB(userId);
      }
      await setSaltInLocalState(userSalt);
    }
    salt.value = userSalt;
  }

  async function fetchFirstWorkspace({ userId }) {
    const userConfig = await getOrFetchUserConfig(userId);
    const userFirstWorkspace = (userConfig && userConfig.first_workspace) || null;
    firstWorkspace.value = userFirstWorkspace;
  }

  async function setFirstWorkspace({ workspaceId }) {
    firstWorkspace.value = workspaceId;
  }

  async function fetchIsFinishedGetStarted({ userId }) {
    const userConfig = await getOrFetchUserConfig(userId);
    const userIsFinishedGetStarted = (userConfig && userConfig.is_finished_get_started) || false;
    isFinishedGetStarted.value = userIsFinishedGetStarted;
  }

  async function setIsFinishedGetStarted() {
    isFinishedGetStarted.value = true;
  }

  async function fetchShownHelpTooltips({ userId }) {
    const userConfig = await getOrFetchUserConfig(userId);
    const userShownHelpTooltips =
      userConfig?.shown_help_tooltips && new Set(Object.keys(userConfig.shown_help_tooltips));
    shownHelpTooltips.value = userShownHelpTooltips;
  }

  async function setShownHelpTooltips({ tooltipKey, userId }) {
    await setUserFieldInDB(userId, UserOnboardingFields.SHOWN_HELP_TOOLTIPS, { [tooltipKey]: true });
    shownHelpTooltips.value = new Set([...Array.from(shownHelpTooltips.value), tooltipKey]);
  }

  async function fetchShownExistingUsersHelpTooltips({ userId }) {
    const userConfig = await getOrFetchUserConfig(userId);
    const userShownExistingUsersHelpTooltips = userConfig?.shown_existing_users_help_tooltips
      ? new Set(Object.keys(userConfig.shown_existing_users_help_tooltips))
      : new Set();
    shownExistingUsersHelpTooltips.value = userShownExistingUsersHelpTooltips;
  }

  async function setShownExistingUsersHelpTooltips({ tooltipKey, userId }) {
    await setUserFieldInDB(userId, UserOnboardingFields.SHOWN_EXISTING_USERS_HELP_TOOLTIPS, { [tooltipKey]: true });
    shownExistingUsersHelpTooltips.value = new Set([...Array.from(shownExistingUsersHelpTooltips.value), tooltipKey]);
  }

  async function setShowAnimationforStep({ showAnimationforStep }) {
    showAnimationforSteps.value = [...showAnimationforSteps.value, showAnimationforStep];
  }

  async function unsetShowAnimationforStep({ showAnimationforStep }) {
    showAnimationforSteps.value = showAnimationforSteps.value.filter((step) => step !== showAnimationforStep);
  }

  async function setTheme({ theme: userTheme, userId }: { theme: ThemeOption; userId: string }) {
    const selectedTheme = userTheme === 'system' ? getSystemTheme() : userTheme;
    setThemeInLocalState(userTheme);
    setThemeForAppInBrowser(userTheme);
    await saveThemeInDB(userTheme, userId);
    theme.value = selectedTheme;
  }

  async function getOrFetchUserConfig(userId: string, forceFetch = false): Promise<UserInUsersDBCollection> {
    if (!userConfig.value || forceFetch) {
      try {
        if (!userConfigPromise.value || forceFetch) {
          userConfigPromise.value = fetchUserConfigObject(userId);
        }
        userConfig.value = await userConfigPromise.value;
      } catch (error) {
        userConfig.value = null;
        throw error;
      } finally {
        userConfigPromise.value = null;
      }
    }
    return userConfig.value;
  }

  async function fetchUserConfigObject(userId: string) {
    const response = await getDocFromCollection(collectionNames.USERS, userId);
    if (response.code !== config.SUCCESS_RETURN_CODE) {
      return null;
    }
    return response.data;
  }

  async function fetchThemeByUser({ userId }) {
    let userTheme: ThemeOption;
    const userConfig = await getOrFetchUserConfig(userId);
    if (userConfig?.theme) {
      userTheme = userConfig.theme;
      setThemeInLocalState(userTheme);
      setThemeForAppInBrowser(userTheme);
      if (userTheme === 'system') {
        userTheme = getSystemTheme();
      }
      theme.value = userTheme;
    } else {
      const defaultTheme = 'system';
      setThemeInLocalState(defaultTheme);
      setThemeForAppInBrowser(defaultTheme);
      await saveThemeInDB(defaultTheme, userId);
      theme.value = defaultTheme;
    }
  }

  async function fetchInstalledIdePlugin({ userId }: { userId: string }) {
    if (!userId) {
      return;
    }
    // we have 3 promises here, two to compute the installedIdePlugin from the ide-usages for 2 kinds
    // and we also get the user config, since we need to have the value of install_ide_plugin_banner_dismissed_at
    if (installedIdePluginPromise === null) {
      installedIdePluginPromise = Promise.allSettled([
        updateInstalledIdePluginFromDb({ userId, ideKind: 'VSCode' }),
        updateInstalledIdePluginFromDb({ userId, ideKind: 'JetBrains' }),
        getOrFetchUserConfig(userId),
      ]);
    }
    await installedIdePluginPromise;
  }

  async function updateInstalledIdePluginFromDb({
    userId,
    ideKind,
  }: {
    userId: string;
    ideKind: 'JetBrains' | 'VSCode';
  }): Promise<void> {
    const resp = await getDocsRefWithWhereClauses(
      collectionNames.IDE_USAGES,
      [
        ['user_id', '==', userId],
        ['ide_kind', '==', ideKind],
      ],
      null,
      1
    );
    const storeField = ideKind === 'VSCode' ? installedIdePluginVSCode : installedIdePluginJetBrains;
    if (resp.code === config.SUCCESS_RETURN_CODE) {
      storeField.value = resp.data.docs.length > 0;
    } else {
      logger.error(`Failed to fetch ide-usages for userId=${userId} ideKind=${ideKind}: ${resp.errorMessage}`);
      storeField.value = false;
    }
  }

  // sets the install_ide_plugin_banner_dismissed_at to timestamp (if dismissed) else null
  async function markDismissedInstallIdePluginBanner({ userId, isDismiss }: { userId: string; isDismiss: boolean }) {
    // we save timestamp and not boolean so we have an option to nudge after X days
    await setUserFieldInDB(userId, 'install_ide_plugin_banner_dismissed_at', isDismiss ? firestoreTimestamp() : null);
    // refresh the userConfig - so the install_ide_plugin_banner_dismissed_at will be up-to-date
    await getOrFetchUserConfig(userId, true);
  }

  async function getAutocompletePreference(userId: string) {
    if (isAutoCompleteEnabled.value !== null) {
      return isAutoCompleteEnabled.value;
    }
    const userConfig = await getOrFetchUserConfig(userId);
    isAutoCompleteEnabled.value = userConfig?.is_autocomplete_enabled ?? true;
    return isAutoCompleteEnabled.value;
  }

  async function setAutocompletePreferenceInDB(userId: string, isEnabled: boolean) {
    await setUserFieldInDB(userId, 'is_autocomplete_enabled', isEnabled);
    isAutoCompleteEnabled.value = isEnabled;
  }

  return {
    userConfig,
    userConfigPromise,
    theme,
    isAutoCompleteEnabled,
    salt,
    installedIdePlugin,
    installedIdePluginVSCode,
    installedIdePluginJetBrains,
    firstWorkspace,
    isFinishedGetStarted,
    shownHelpTooltips,
    shownExistingUsersHelpTooltips,
    showAnimationforSteps,
    resetState,
    fetchTheme,
    setTheme,
    fetchUserSalt,
    fetchFirstWorkspace,
    setFirstWorkspace,
    fetchIsFinishedGetStarted,
    fetchInstalledIdePlugin,
    setIsFinishedGetStarted,
    fetchShownHelpTooltips,
    setShownHelpTooltips,
    fetchShownExistingUsersHelpTooltips,
    setShownExistingUsersHelpTooltips,
    setShowAnimationforStep,
    unsetShowAnimationforStep,
    fetchThemeByUser,
    markDismissedInstallIdePluginBanner,
    getAutocompletePreference,
    setAutocompletePreferenceInDB,
  };
});
