import { getLoggerNew } from '@swimm/shared';
import * as firestoreWrapper from '@/adapters-common/firestore-wrapper';
import * as firestore from '@/adapters-common/firestore-wrapper';
import { config } from '@swimm/shared';
import { NOTIFICATION_TYPES } from '@swimm/shared';
import type { AssignmentDbItemInterface, NotificationDbItemInterface } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

/**
 * Return collection ref for assignments
 */
function getAssignmentsCollectionRef(repoId, docId) {
  return firestoreWrapper.getSubCollectionRefRecursive(
    [firestore.collectionNames.REPOSITORIES, firestore.collectionNames.SWIMMS, firestore.collectionNames.ASSIGNMENTS],
    [repoId, docId]
  );
}

/**
 * Return entire collection for assignments
 */
function getAssignmentsCollection(repoId, docId) {
  return firestoreWrapper.getSubCollectionRecursive(
    [firestore.collectionNames.REPOSITORIES, firestore.collectionNames.SWIMMS, firestore.collectionNames.ASSIGNMENTS],
    [repoId, docId]
  );
}

/**
 *
 * Fetch the assignments from the sub collection
 */
export async function fetchDocAssignments({
  repoId,
  docId,
}: {
  repoId: string;
  docId: string;
}): Promise<Array<AssignmentDbItemInterface>> {
  try {
    const assignmentsCollectionSnapshot = await getAssignmentsCollection(repoId, docId);
    if (assignmentsCollectionSnapshot.code === config.SUCCESS_RETURN_CODE) {
      const result = [];
      assignmentsCollectionSnapshot.data.forEach(function (d) {
        result.push({ ...d.data(), id: d.id });
      });
      return result;
    }
    logger.error(
      `Failed in fetchDocAssignments for repoId=${repoId} docId=${docId} with ${assignmentsCollectionSnapshot.errorMessage}`
    );
    return [];
  } catch (err) {
    logger.error({ err }, `Failed in fetchDocAssignments for repoId=${repoId} docId=${docId} with ${err}`);
    return [];
  }
}

export async function deleteDocAssignment({
  repoId,
  docId,
  assignmentId,
}: {
  repoId: string;
  docId: string;
  assignmentId: string;
}): Promise<boolean> {
  try {
    if (!repoId || !docId || !assignmentId) {
      logger.error(
        `Missing param in deleteDocAssignment for repoId=${repoId} docId=${docId} assignmentId=${assignmentId}`
      );
      return false;
    }

    const deleteResult = await firestoreWrapper.deleteSubCollectionItemRecursive(
      [firestore.collectionNames.REPOSITORIES, firestore.collectionNames.SWIMMS, firestore.collectionNames.ASSIGNMENTS],
      [repoId, docId],
      assignmentId
    );
    if (deleteResult.code !== config.SUCCESS_RETURN_CODE) {
      logger.error(
        `Failed in deleteDocAssignment for repoId=${repoId} docId=${docId} assignmentId=${assignmentId} with ${deleteResult.errorMessage}`
      );
    }
    return deleteResult.code === config.SUCCESS_RETURN_CODE;
  } catch (err) {
    logger.error(
      { err },
      `Failed in deleteDocAssignment for repoId=${repoId} docId=${docId} assignmentId=${assignmentId} with ${err}`
    );
    return false;
  }
}

/**
 * Creates doc assignment for document id in
 * repositories/<repo-id>/swimms/<doc-id>/assignments/
 * errors are ignored silently
 */
export async function createDocAssignment({
  repoId,
  docId,
  assignment,
}: {
  repoId: string;
  docId: string;
  assignment: AssignmentDbItemInterface;
}) {
  try {
    const assignmentCollectionRef = getAssignmentsCollectionRef(repoId, docId);
    await assignmentCollectionRef.add(assignment);
  } catch (err) {
    logger.error(
      { err },
      `Failed to createDocAssignment for repoId=${repoId} docId=${docId} assignment=${JSON.stringify(
        assignment
      )} with ${err}`
    );
  }
}

export async function createAssignmentNotification({
  assignment,
  docId,
  docTitle,
  docUrl,
  workspaceId,
}: {
  assignment: AssignmentDbItemInterface;
  docId: string;
  docTitle: string;
  docUrl: string;
  workspaceId: string;
}) {
  try {
    const notification: NotificationDbItemInterface = {
      action_url: docUrl,
      created_at: firestoreWrapper.firestoreTimestamp(),
      emailed_at: null,
      dismissed: false,
      notifier_id: assignment.assigned_by,
      notifier_name: assignment.assigned_by_name || assignment.assigned_by_email,
      notifier_type: 'user',
      recipient_email: assignment.assignee ? '' : assignment.assignee_email,
      recipient_id: assignment.assignee,
      seen: false,
      slacked_at: null,
      topic_id: docId,
      topic_name: docTitle,
      topic_type: 'doc',
      type: NOTIFICATION_TYPES.ASSIGNMENT,
      reason: assignment.type,
      note: assignment.description,
      targets: [],
      routed_at: null,
      email_template_data: {
        nickname: assignment.assigned_by_name || assignment.assigned_by_email,
        btn_url: docUrl,
        assign_type: assignment.type,
        assign_notes: assignment.description ? assignment.description : 'N/A',
        doc_title: docTitle,
        workspace_id: workspaceId,
      },
    };
    const notificationRef = await firestoreWrapper.addDocToCollection(
      firestoreWrapper.collectionNames.NOTIFICATIONS,
      notification
    );
    if (notificationRef.code === config.SUCCESS_RETURN_CODE) {
      logger.debug(`Notification ${notificationRef.data.id} created`);
    } else {
      throw new Error(`Failed to add notification ${notificationRef.errorMessage}`);
    }
  } catch (err) {
    logger.error(
      { err },
      `Failed to notify about assignment docId=${docId} assignment=${JSON.stringify(assignment)} with ${err}`
    );
  }
}

export async function markDocAssignmentAsComplete({
  repoId,
  docId,
  assignmentId,
}: {
  repoId: string;
  docId: string;
  assignmentId: string;
}): Promise<boolean> {
  try {
    if (!repoId || !docId || !assignmentId) {
      logger.error(
        `Missing param in deleteDocAssignment for repoId=${repoId} docId=${docId} assignmentId=${assignmentId}`
      );
      return false;
    }

    const assignmentRef = getAssignmentsCollectionRef(repoId, docId);
    await assignmentRef.doc(assignmentId).set({ completed: true }, { merge: true });

    return true;
  } catch (err) {
    logger.error(
      { err },
      `Failed in markDocAssignmentAsComplete for repoId=${repoId} docId=${docId} assignmentId=${assignmentId} with ${err}`
    );
    return false;
  }
}
