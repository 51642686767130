import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { GitProviderName } from '../../types';
import UrlUtils from '../../utils/url-utils';
import gitProviderUtils, { getGitHostingToken } from '../git-provider-utils';
import { DriverOptions } from '../index';
import {
  BitbucketDcActivityResult,
  BitbucketDcBranchMetadataResult,
  BitbucketDcBranchResult,
  BitbucketDcBranchesParams,
  BitbucketDcCommentResult,
  BitbucketDcCommitResult,
  BitbucketDcCommitsDiffParams,
  BitbucketDcCommitsParams,
  BitbucketDcCompareCommitsParams,
  BitbucketDcCreateBranchBody,
  BitbucketDcCreateCommentBody,
  BitbucketDcCreatePullRequestBody,
  BitbucketDcDeleteBranchParameter,
  BitbucketDcDeleteCommentBody,
  BitbucketDcDeletePullRequestParameter,
  BitbucketDcFileContentParams,
  BitbucketDcFileContentResult,
  BitbucketDcFilesDirectory,
  BitbucketDcPagedResult,
  BitbucketDcPagesParams,
  BitbucketDcPermissionParams,
  BitbucketDcProjectParams,
  BitbucketDcProjectResult,
  BitbucketDcPullRequestChangeParams,
  BitbucketDcPullRequestChangeResult,
  BitbucketDcPullRequestDiffParams,
  BitbucketDcPullRequestDiffResult,
  BitbucketDcPullRequestParams,
  BitbucketDcPullRequestResult,
  BitbucketDcPullRequestWithPropertiesResult,
  BitbucketDcPullRequestsForCommitResult,
  BitbucketDcRawFileContentParams,
  BitbucketDcRepoResult,
  BitbucketDcReposParams,
  BitbucketDcUpdateCommentBody,
  BitbucketDcUserData,
  BitbucketDcUserResult,
  BitbucketDcUsersParams,
  BitbucketEditFileForm,
} from './bitbucket-dc-transport-types';

const TOKEN_EXPIRY_TIME_IN_SECONDS = 55 * 60; // The token expires after 1 hour, we will refresh it after 55 minutes
const SWIMM_BITBUCKET_PLUGIN_ROUTE = '/swimmapi/latest/forward';
const WHOAMI_PLUGIN_ROUTE = '/swimmapi/latest/whoami';

const transformResponse = [
  (data) => {
    return data;
  },
];

export class BitbucketDcTransport {
  private _refreshTokenRequestCache: Promise<void>;
  private axios: AxiosInstance;
  private config = { baseUrl: '', token: '', hostname: '' };

  public constructor({ baseUrl, authToken = '' }: DriverOptions = {}) {
    this.init({ baseUrl, authToken });
  }

  private async init({ baseUrl, authToken = '' }: DriverOptions = {}): Promise<void> {
    this.config.baseUrl = baseUrl.replace(/\/+$/, '');
    this.config.hostname = UrlUtils.getUrlHostname(this.config.baseUrl);

    if (authToken) {
      this.config.token = authToken;
    } else {
      this.config.token = await getGitHostingToken(this.config.hostname);
    }
    if (!this.config.baseUrl) {
      throw new Error('Bitbucket DC driver is not configured');
    }

    this.axios = Axios.create({
      baseURL: `${this.config.baseUrl}${SWIMM_BITBUCKET_PLUGIN_ROUTE}`,
      headers: { Authorization: `Bearer ${this.config.token}`, 'Content-Type': 'application/json' },
    });
  }

  private async refreshAuthToken() {
    const newToken = await gitProviderUtils.refreshAuthToken({
      provider: GitProviderName.BitbucketDc,
      hostname: this.config.hostname,
      expiryTimeInSeconds: TOKEN_EXPIRY_TIME_IN_SECONDS,
    });

    if (newToken) {
      this.config.token = newToken;
      this.axios.defaults.headers.Authorization = `Bearer ${this.config.token}`;
    }

    this._refreshTokenRequestCache = null;
  }

  private async validateAuthToken(): Promise<void> {
    if (!this.config.token) {
      this.config.token = await getGitHostingToken(this.config.hostname);
      this.axios.defaults.headers.Authorization = `Bearer ${this.config.token}`;
    }

    if (!this._refreshTokenRequestCache) {
      this._refreshTokenRequestCache = this.refreshAuthToken();
    }

    await this._refreshTokenRequestCache;
  }

  private async request<T>(method: string, url: string, options: AxiosRequestConfig): Promise<T> {
    await this.validateAuthToken();
    const response = await this.axios({
      method,
      url,
      ...options,
    });
    return response.data;
  }

  getRepos(
    params: BitbucketDcPagesParams<BitbucketDcReposParams>
  ): Promise<BitbucketDcPagedResult<BitbucketDcRepoResult>> {
    return this.request<BitbucketDcPagedResult<BitbucketDcRepoResult>>('GET', `/repos`, {
      params,
    });
  }

  getRepoInfo(projectKey: string, repoSlug: string): Promise<BitbucketDcRepoResult> {
    return this.request('GET', `/projects/${projectKey}/repos/${repoSlug}`, {});
  }

  getBranches<T extends BitbucketDcPagesParams<BitbucketDcBranchesParams>>(
    projectKey: string,
    repoSlug: string,
    options?: T
  ): Promise<
    BitbucketDcPagedResult<T['details'] extends true ? BitbucketDcBranchMetadataResult : BitbucketDcBranchResult>
  > {
    return this.request('GET', `/projects/${projectKey}/repos/${repoSlug}/branches`, {
      params: options,
    });
  }

  getProjects(
    params?: BitbucketDcPagesParams<BitbucketDcProjectParams>
  ): Promise<BitbucketDcPagedResult<BitbucketDcProjectResult>> {
    return this.request('GET', `/projects`, { params });
  }

  deleteTokenFromMemory(): void {
    this.config.token = null;
  }

  async getUserData(): Promise<BitbucketDcUserData> {
    const userSlug = await this.request('GET', WHOAMI_PLUGIN_ROUTE, { baseURL: this.config.baseUrl });
    return this.request('GET', `/users/${userSlug}`, null);
  }

  getUsers(
    data?: BitbucketDcPagesParams<BitbucketDcUsersParams>
  ): Promise<BitbucketDcPagedResult<Omit<Required<BitbucketDcUserResult>, 'avatar'>>> {
    const { permission, ...params } = data || {};
    if (permission && Array.isArray(permission)) {
      permission.forEach((p, i) => (params[`permission.${i + 1}`] = p));
    } else if (permission) {
      params['permission'] = permission;
    }
    return this.request('GET', `/users`, { params });
  }

  getUserRepos(
    params: BitbucketDcPagesParams<BitbucketDcPermissionParams>
  ): Promise<BitbucketDcPagedResult<BitbucketDcRepoResult>> {
    return this.request('GET', `/profile/recent/repos`, { params });
  }

  getDefaultBranch(projectKey: string, repoSlug: string): Promise<BitbucketDcBranchResult> {
    return this.request('GET', `/projects/${projectKey}/repos/${repoSlug}/default-branch`, {});
  }

  getPullRequest(
    projectKey: string,
    repoSlug: string,
    pullRequestId: number | string
  ): Promise<BitbucketDcPullRequestResult> {
    return this.request('GET', `/projects/${projectKey}/repos/${repoSlug}/pull-requests/${pullRequestId}`, {});
  }

  getPullRequestChanges(
    projectKey: string,
    repoSlug: string,
    pullRequestId: number | string,
    params?: BitbucketDcPagesParams<BitbucketDcPullRequestChangeParams>
  ): Promise<BitbucketDcPullRequestChangeResult> {
    return this.request('GET', `/projects/${projectKey}/repos/${repoSlug}/pull-requests/${pullRequestId}/changes`, {
      params,
    });
  }

  getPullRequestDiff(
    projectKey: string,
    repoSlug: string,
    pullRequestId: number | string,
    params?: BitbucketDcPullRequestDiffParams
  ): Promise<BitbucketDcPullRequestDiffResult> {
    return this.request('GET', `/projects/${projectKey}/repos/${repoSlug}/pull-requests/${pullRequestId}/diff`, {
      params,
    });
  }

  getPullRequestsForCommit(
    projectKey: string,
    repoSlug: string,
    commitId: string,
    params?: BitbucketDcPagesParams<unknown>
  ): Promise<BitbucketDcPullRequestsForCommitResult> {
    return this.request('GET', `/projects/${projectKey}/repos/${repoSlug}/commits/${commitId}/pull-requests`, {
      params,
    });
  }

  findPullRequests<T extends BitbucketDcPagesParams<BitbucketDcPullRequestParams>>(
    projectKey: string,
    repoSlug: string,
    params?: T
  ): Promise<
    BitbucketDcPagedResult<
      T['withProperties'] extends false ? BitbucketDcPullRequestResult : BitbucketDcPullRequestWithPropertiesResult
    >
  > {
    return this.request('GET', `/projects/${projectKey}/repos/${repoSlug}/pull-requests`, { params });
  }

  async createBranch(
    projectKey: string,
    repoSlug: string,
    data: BitbucketDcCreateBranchBody
  ): Promise<BitbucketDcBranchResult> {
    return this.request('POST', `/projects/${projectKey}/repos/${repoSlug}/branches`, {
      data,
    });
  }

  deleteBranch(projectKey: string, repoSlug: string, data: BitbucketDcDeleteBranchParameter): Promise<void> {
    return this.request('DELETE', `/projects/${projectKey}/repos/${repoSlug}/branches`, {
      data,
    });
  }

  createPullRequest(
    projectKey: string,
    repoSlug: string,
    data: BitbucketDcCreatePullRequestBody
  ): Promise<BitbucketDcPullRequestResult> {
    return this.request('POST', `/projects/${projectKey}/repos/${repoSlug}/pull-requests`, { data });
  }

  createComment(
    projectKey: string,
    repoSlug: string,
    prId: string,
    data: BitbucketDcCreateCommentBody
  ): Promise<BitbucketDcPullRequestResult> {
    return this.request('POST', `/projects/${projectKey}/repos/${repoSlug}/pull-requests/${prId}/comments`, {
      data,
    });
  }

  updateComment(
    projectKey: string,
    repoSlug: string,
    prId: string,
    commentId: string,
    data: BitbucketDcUpdateCommentBody
  ): Promise<BitbucketDcPullRequestResult> {
    return this.request(
      'PUT',
      `/projects/${projectKey}/repos/${repoSlug}/pull-requests/${prId}/comments/${commentId}`,
      {
        data,
      }
    );
  }

  deleteComment(
    projectKey: string,
    repoSlug: string,
    prId: string,
    commentId: string,
    data: BitbucketDcDeleteCommentBody
  ): Promise<BitbucketDcPullRequestResult> {
    return this.request(
      'DELETE',
      `/projects/${projectKey}/repos/${repoSlug}/pull-requests/${prId}/comments/${commentId}?version=${data.version}`,
      {}
    );
  }

  getComment(projectKey: string, repoSlug: string, prId: string, commentId: string): Promise<BitbucketDcCommentResult> {
    return this.request(
      'GET',
      `/projects/${projectKey}/repos/${repoSlug}/pull-requests/${prId}/comments/${commentId}`,
      {}
    );
  }

  getPrActivities(
    projectKey: string,
    repoSlug: string,
    prId: string,
    data: BitbucketDcPagesParams<unknown>
  ): Promise<BitbucketDcPagedResult<BitbucketDcActivityResult>> {
    return this.request(
      'GET',
      `/projects/${projectKey}/repos/${repoSlug}/pull-requests/${prId}/activities?${new URLSearchParams(
        data as URLSearchParams
      ).toString()}`,
      {}
    );
  }

  deletePullRequest(
    projectKey: string,
    repoSlug: string,
    prId: string | number,
    data: BitbucketDcDeletePullRequestParameter
  ): Promise<void> {
    return this.request('DELETE', `/projects/${projectKey}/repos/${repoSlug}/pull-requests/${prId}`, {
      data,
    });
  }

  compareCommits(
    projectKey: string,
    repoSlug: string,
    params: BitbucketDcPagesParams<BitbucketDcCompareCommitsParams>
  ): Promise<BitbucketDcPullRequestChangeResult> {
    return this.request('GET', `/projects/${projectKey}/repos/${repoSlug}/compare/changes`, { params });
  }

  async getRawFileContent(
    projectKey: string,
    repoSlug: string,
    filePath: string,
    { safe, ...params }: BitbucketDcRawFileContentParams & { safe?: boolean }
  ): Promise<string | null> {
    const url = `/projects/${projectKey}/repos/${repoSlug}/raw/${encodeURI(filePath)}`;
    if (safe) {
      try {
        return await this.request('GET', url, { params, transformResponse });
      } catch (error) {
        return null;
      }
    }
    return await this.request('GET', url, { params, transformResponse });
  }

  getCommits(
    projectKey: string,
    repoSlug: string,
    params?: BitbucketDcPagesParams<BitbucketDcCommitsParams>
  ): Promise<BitbucketDcPagedResult<BitbucketDcCommitResult>> {
    return this.request('GET', `/projects/${projectKey}/repos/${repoSlug}/commits`, {
      params,
    });
  }

  getCommitsDiff(
    projectKey: string,
    repoSlug: string,
    { head, ...params }: BitbucketDcCommitsDiffParams & { head: string }
  ): Promise<string> {
    return this.request('GET', `/projects/${projectKey}/repos/${repoSlug}/commits/${head}/diff`, {
      params,
      // Bitbucket DC returns a text/plain content type for this endpoint. In another case, we'll get an object.
      headers: { Accept: 'text/plain; qs=0.1' },
    });
  }

  async pushFile(
    projectKey: string,
    repoSlug: string,
    filePath: string,
    data: BitbucketEditFileForm
  ): Promise<BitbucketDcCommitResult | null> {
    await this.validateAuthToken();
    const formData = new FormData();
    formData.append('branch', data.branch);
    formData.append('message', data.message);
    formData.append('content', new Blob([data.content], { type: 'text/plain' }), filePath);

    // Add additional formData params if exists
    if (data.sourceBranch) {
      formData.append('sourceBranch', data.sourceBranch);
    }
    if (data.sourceCommitId) {
      formData.append('sourceCommitId', data.sourceCommitId);
    }

    // This request is using multipart/form-data which is not working with Axios properly.
    // When using Axios, it throws "the request was rejected because no multipart boundary was found"
    const response = await fetch(
      `${
        this.config.baseUrl
      }${SWIMM_BITBUCKET_PLUGIN_ROUTE}/projects/${projectKey}/repos/${repoSlug}/browse/${encodeURI(filePath)}`,
      {
        method: 'PUT',
        body: formData,
        headers: {
          Authorization: `Bearer ${this.config.token}`,
        },
      }
    );
    if (!response.ok) {
      const responseText = await response.text();
      throw new Error(responseText);
    }
    return await response.json();
  }

  async getCommitById(
    projectKey: string,
    repoSlug: string,
    commitId: string,
    params?: { path?: string }
  ): Promise<BitbucketDcCommitResult> {
    // If the commitId is a branch with a slash, we need to get the last commit of this branch in another way as Bitbucket DC doesn't support it in the API.
    if (commitId?.includes('/')) {
      const result: BitbucketDcPagedResult<BitbucketDcCommitResult> = await this.request(
        'GET',
        `/projects/${projectKey}/repos/${repoSlug}/commits`,
        {
          params: { until: commitId, limit: 1 },
        }
      );
      if (!result.values?.[0]) {
        throw new Error(`Commit with id ${commitId} not found`);
      }
      return result.values[0];
    }
    return this.request('GET', `/projects/${projectKey}/repos/${repoSlug}/commits/${encodeURI(commitId)}`, {
      params,
    });
  }

  getFilesDirectory(
    projectKey: string,
    repoSlug: string,
    { path, ...params }: BitbucketDcPagesParams<BitbucketDcFilesDirectory> & { path?: string }
  ): Promise<BitbucketDcPagedResult<string>> {
    return this.request('GET', `/projects/${projectKey}/repos/${repoSlug}/files${path ? `/${path}` : ''}`, {
      params,
    });
  }

  getFilesDirectoryContent(
    projectKey: string,
    repoSlug: string,
    { path, ...params }: BitbucketDcPagesParams<BitbucketDcFileContentParams> & { path?: string }
  ): Promise<BitbucketDcFileContentResult> {
    // The children values return only 500 items. I can't find how exactly to get all items.!!!
    return this.request('GET', `/projects/${projectKey}/repos/${repoSlug}/browse${path ? `/${path}` : ''}`, {
      params,
    });
  }
}
