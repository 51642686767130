import { type ComputedRef, type Ref, computed, ref, watch } from 'vue';
import { assignInWith } from 'lodash-es';

export interface UserSelectedFilesStorage {
  get: () => Promise<Map<string, Set<string>>>;
  set: (paths: Map<string, Set<string>>) => Promise<void>;
}

export interface SourceFilesIndex {
  addUserSelectedFile: (repoId: string, path: string) => void;
  removeUserSelectedFile: (repoId: string, path: string) => void;
  sourceFiles: ComputedRef<{ [repoId: string]: string[] }>;
}

export function useSourceFilesIndex(
  referencedFiles: Ref<Map<string, string[]>>,
  storage?: UserSelectedFilesStorage
): SourceFilesIndex {
  // TODO: We need to run autosync on the selected files at some point
  const userSelectedFiles = ref<Map<string, Set<string>>>(new Map());

  const sourceFiles = computed(() =>
    assignInWith<{ [repoId: string]: string[] }>(
      {},
      Object.fromEntries(userSelectedFiles.value),
      Object.fromEntries(referencedFiles.value),
      (userSelectedFilesForRepo?: Set<string>, referencedFilesForRepo?: string[]) => [
        ...new Set([...(userSelectedFilesForRepo ?? []), ...(referencedFilesForRepo ?? [])]),
      ]
    )
  );

  const addUserSelectedFile = (repoId: string, path: string): void => {
    if (referencedFiles.value.get(repoId)?.includes(path)) {
      return;
    }
    if (!userSelectedFiles.value.has(repoId)) {
      userSelectedFiles.value.set(repoId, new Set());
    }
    userSelectedFiles.value.get(repoId)?.add(path);
  };

  const removeUserSelectedFile = (repoId: string, path: string): void => {
    userSelectedFiles.value.get(repoId)?.delete(path);
  };

  watch(
    () => userSelectedFiles.value,
    (newValue) => {
      storage?.set(newValue);
    }
  );

  const getUserSelectedFilesFromStorage = async (): Promise<void> => {
    // FIXME: We override files the user already selected, but this is very unlikely to happen.
    if (storage == null) {
      return;
    }
    const storedFiles = await storage.get();
    userSelectedFiles.value = storedFiles;
  };

  void getUserSelectedFilesFromStorage();

  return {
    addUserSelectedFile,
    removeUserSelectedFile,
    sourceFiles,
  };
}
