import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core';

function dropSwmBlock(state: StateCore): void {
  const blockTokens = state.tokens;

  for (let j = 0; j < state.tokens.length; j++) {
    if (blockTokens[j].type === 'swm_block_open' || blockTokens[j].type === 'swm_block_close') {
      blockTokens[j].type = 'inline';
      blockTokens[j].tag = '';
      blockTokens[j].attrs = null;
      blockTokens[j].level = 1;
      blockTokens[j].children = [];
      // To match https://github.com/markdown-it/markdown-it/blob/2b6cac25823af011ff3bc7628bc9b06e483c5a08/lib/rules_block/paragraph.js#L34
      blockTokens[j].content = blockTokens[j].content.trim();

      const open = new state.Token('paragraph_open', 'p', 1);
      open.block = true;
      open.map = blockTokens[j].map;

      const close = new state.Token('paragraph_close', 'p', -1);
      close.block = true;

      blockTokens.splice(j, 1, open, blockTokens[j], close);
    }
  }
}

function dropSwmInline(state: StateCore): void {
  const blockTokens = state.tokens;

  for (let j = 0; j < blockTokens.length; j++) {
    if (blockTokens[j].type !== 'inline') {
      continue;
    }

    const tokens = blockTokens[j].children ?? [];

    for (let i = 0; i < tokens.length; i++) {
      if (tokens[i].type === 'swm_inline_open' || tokens[i].type === 'swm_inline_close') {
        tokens[i].type = 'text';
        tokens[i].tag = '';
        tokens[i].attrs = null;
      }
    }
  }
}

/**
 * markdown-it plugin that "drops" (converts to plain text) unrecognized swm
 * tags.
 */
export default function swm_drop(md: MarkdownIt) {
  md.core.ruler.before('inline', 'drop_swm_block', dropSwmBlock);
  md.core.ruler.before('linkify', 'drop_swm_inline', dropSwmInline);
}
