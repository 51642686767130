import { inject, provide } from 'vue';
import { useStore } from 'vuex';
import * as firestoreWrapper from '@/adapters-common/firestore-wrapper';
import { firestoreCollectionNames } from '@swimm/shared';
import type { DbWrapperInterface } from '@swimm/editor';
export function useDbWrapper() {
  const injectedDbWrapper = inject('dbWrapper', null); // enable refining dbWrapper functionality by dependency injection into the editor
  const store = useStore();

  const dbWrapper = Object.assign(
    {
      async getDoc(pathParts) {
        return await firestoreWrapper.getDocRecursive(pathParts, { addId: true });
      },
      async updateDoc(pathParts: string[], values: Record<string, unknown>) {
        return await firestoreWrapper.updateDocRecursive(pathParts, values);
      },
      async getCollectionDocs(pathParts, filters = []) {
        // For repo children we must go via the store
        // otherwise we have inconsistency when checking for validity of
        // links in EditDoc and maybe in other places
        if (pathParts.length === 3 && pathParts[0] === firestoreCollectionNames.REPOSITORIES) {
          const repoId = pathParts[1];
          await store.dispatch('database/fetchRepoChildren', {
            repoId,
            children: [pathParts[2]],
          });
          const resultObject = store.getters['database/db_getResource'](`repositories.${repoId}.${pathParts[2]}`);
          return Object.values(resultObject ?? {});
        } else {
          return await firestoreWrapper.getCollectionDocsRecursive(pathParts, { addId: true, filters });
        }
      },
    },
    injectedDbWrapper || {}
  );
  provide('dbWrapper', dbWrapper);
  return { dbWrapper: dbWrapper as DbWrapperInterface };
}
