import { eventSchema } from './logger-schema';
import { SWIMM_EVENTS } from './swimm-events';
import { ERROR_RETURN_CODE, SUCCESS_RETURN_CODE } from '../config';
import { getLogger } from '../logger/legacy-shim';
import type { FieldValue, Firestore } from '@google-cloud/firestore';
import { User } from '../types';

const logger = getLogger("packages/shared/src/event-logger/event-logger.ts");

/**
 Validate event log message by schema
 */
export function validateEventMessage(eventMessage) {
  try {
    const validationResult = eventSchema.validate(eventMessage);
    if (!validationResult.error) {
      return {
        code: SUCCESS_RETURN_CODE,
        eventMessage: eventMessage,
      };
    }
    return {
      code: ERROR_RETURN_CODE,
      error: validationResult.error,
    };
  } catch (error) {
    return { code: ERROR_RETURN_CODE, error: error };
  }
}

export interface EventLogMessage {
  swimmEventCode: string;

  [x: string]: unknown;
}

interface PrepareEventLog {
  logMessage: EventLogMessage;
  userData: User;
  version?: string;
}

/**
 Builds and validates the event message from basic user log message, app_version and user data
 */
export function prepareEventLogMessage({ logMessage, userData, version }: PrepareEventLog) {
  try {
    const event = SWIMM_EVENTS[logMessage.swimmEventCode]
      ? SWIMM_EVENTS[logMessage.swimmEventCode]
      : SWIMM_EVENTS.UNKNOWN;

    const eventMessage = {
      ...logMessage,
      swimmEventCode: event.code,
      actionName: event.description,
      app_version: version ?? null,
      userId: userData.uid,
      userName: userData.nickname,
      userEmail: userData.email ?? null,
      platform: 'Web',
    };
    return validateEventMessage(eventMessage);
  } catch (error) {
    return { code: ERROR_RETURN_CODE, error: error };
  }
}

export async function logEvent(
  db: Firestore,
  adminFieldValue: typeof FieldValue,
  logMessage: {
    swimmEventCode: string;
    repoId: string;
    actionName: string;
    value: string;
  },
  userData: User
) {
  const eventCode = logMessage.swimmEventCode || '';
  try {
    const createLogMessage = prepareEventLogMessage({ logMessage: logMessage, userData });

    if (createLogMessage.code === SUCCESS_RETURN_CODE) {
      await sendEventLog(db, adminFieldValue, createLogMessage.eventMessage);
      return SUCCESS_RETURN_CODE;
    }
    logger.error(
      `could not prepare event message for sending event log ${eventCode}. Details: ${createLogMessage.error}`
    );
    return ERROR_RETURN_CODE;
  } catch (error) {
    logger.error(`could not send event log ${eventCode}. Details: ${error.toString()}`);
    return ERROR_RETURN_CODE;
  }
}

async function sendEventLog(
  db: Firestore,
  adminFieldValue: typeof FieldValue,
  eventMessage: {
    swimmEventCode: string;
    repoId: string;
    actionName: string;
    value: string;
  }
) {
  await db.collection('event_logs').add({ ...eventMessage, created: adminFieldValue.serverTimestamp() });
}
