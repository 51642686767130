<script setup>
import { SwModal } from '@swimm/ui';
import { computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import FilesImportOptionsSelection from '@/modules/batch-import/screens/FilesImportOptionsSelection.vue';
import ImportFilesSelection from '@/modules/batch-import/screens/ImportFilesSelection.vue';
import ImportProcess from '@/modules/batch-import/screens/ImportProcess.vue';
import ImportReport from '@/modules/batch-import/screens/ImportReport.vue';
import { useBatchImportStore } from '@/modules/batch-import/stores/batch-import';
import { FilePicker } from '@swimm/editor';
import ImportSummary from '@/modules/batch-import/screens/ImportSummary.vue';
import { BATCH_IMPORT_SCREENS, IMPORT_OPTIONS } from '../constants';

const emit = defineEmits(['toggle-parent']);

const SCREENS = {
  IMPORT_OPTIONS_SELECTION: FilesImportOptionsSelection,
  FILES_SELECTION: ImportFilesSelection,
  IMPORT_PROCESS: ImportProcess,
  IMPORT_REPORT: ImportReport,
  IMPORT_SUMMARY: ImportSummary,
};

const batchImportStore = useBatchImportStore();
const { toggleBatchImportModal } = batchImportStore;
const { showBatchImportModal, currentModalScreen, mdFilesInputRef, zipFilesInputRef, conversionType } =
  storeToRefs(batchImportStore);

watch(currentModalScreen, (_, previousScreen) => {
  if (
    previousScreen &&
    conversionType.value !== IMPORT_OPTIONS.RUN_SMART_IMPORT &&
    previousScreen.key === BATCH_IMPORT_SCREENS.IMPORT_OPTIONS_SELECTION.key
  ) {
    emit('toggle-parent');
  }
});

const currentComponent = computed(() => SCREENS[currentModalScreen.value.key]);

function onClose() {
  toggleBatchImportModal(false);
}

function toggleCreationHub(creationHubSection) {
  onClose();
  emit('toggle-parent', creationHubSection);
}
</script>

<template>
  <SwModal heading="Import Markdown files" :show-modal="showBatchImportModal" @close="onClose" :padded="false">
    <div class="inner-container">
      <component :is="currentComponent" :key="currentModalScreen.key" @show-creation-hub="toggleCreationHub" />
    </div>
  </SwModal>
  <FilePicker ref="mdFilesInputRef" accept=".md" data-testid="md-files-uploader" multiple />
  <FilePicker ref="zipFilesInputRef" accept=".zip" data-testid="zip-files-uploader" multiple />
</template>

<style scoped lang="postcss">
.inner-container {
  width: 576px;
  padding: var(--space-base) var(--space-md) var(--space-sm);
}
</style>
