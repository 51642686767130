<script lang="ts">
export const Drivers = ['caret', 'cursor'] as const;
export type DriverType = (typeof Drivers)[number];
</script>

<script setup lang="ts">
import { computed } from 'vue';
import { isMac } from '@swimm/shared';

import BaseAnchor from '../../components/BaseAnchor/BaseAnchor.vue';
import BaseButton from '../../components/BaseButton/BaseButton.vue';
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';
import BaseKeyCommand from '../../components/BaseKeyCommand/BaseKeyCommand.vue';
import BaseLayoutGap from '../../components/BaseLayoutGap/BaseLayoutGap.vue';

import SwmSelectionFooter from '../SwmSelectionFooter/SwmSelectionFooter.vue';

const props = withDefaults(
  defineProps<{
    driver?: DriverType;
  }>(),
  {
    driver: 'caret',
  }
);

const emit = defineEmits<{
  dismiss: [];
}>();

const dismissSuggestionKeys = computed(() => {
  return isMac() ? ['⌥', 'D'] : ['Alt', 'D'];
});

const isKeyboardInteraction = computed(() => {
  return props.driver === 'caret';
});

function onDismiss() {
  emit('dismiss');
}
</script>

<template>
  <SwmSelectionFooter>
    <BaseLayoutGap alignment="stretch">
      <BaseLayoutGap v-if="isKeyboardInteraction" size="xsmall">
        <span>Navigate <BaseKeyCommand :keys="['↑']" /> <BaseKeyCommand :keys="['↓']" /></span>
        <span>Accept <BaseKeyCommand :keys="['↵']" /></span>
        <span>Dismiss <BaseKeyCommand :keys="dismissSuggestionKeys" /></span>
        <span>Close <BaseKeyCommand :keys="['Escape']" /></span>
      </BaseLayoutGap>
      <BaseButton v-else variant="link" size="small" @click="onDismiss"
        ><template #leftIcon><BaseIcon name="trash-outline" /></template>Dismiss</BaseButton
      >

      <BaseAnchor
        href="https://docs.swimm.io/Features/editor-commands/#smart-tokens"
        target="_blank"
        rel="nofollow noreferrer"
        >Learn more <template #rightIcon><BaseIcon name="outbound-link" /></template
      ></BaseAnchor>
    </BaseLayoutGap>
  </SwmSelectionFooter>
</template>
