<script setup>
import UserDialog from '@/common/components/modals/UserDialog.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import SettingsModalSection from '@/modules/core/components/settings-modal/SettingsModalSection.vue';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { eventLogger, getLoggerNew, productEvents } from '@swimm/shared';
import { Action, SwText } from '@swimm/ui';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const logger = getLoggerNew(__modulename);

const props = defineProps({
  workspaceName: { type: String, required: true },
});

const store = useStore();
const route = useRoute();
const router = useRouter();
const analytics = useAnalytics();
const { logEvent } = useSwimmEventLogs();

const { user } = storeToRefs(useAuthStore());

const deleteWorkspaceDialog = ref(null);
const loading = ref(false);
const showError = ref(false);

async function deleteWorkspace() {
  const userSelection = await deleteWorkspaceDialog.value.showDialog();
  if (!userSelection.confirm) {
    return;
  }

  loading.value = true;
  showError.value = false;

  try {
    logAndReportWorkspaceDeletion();
    await store.dispatch('database/archiveWorkspace', route.params.workspaceId);
    const userWorkspaces = store.getters['database/db_getUserWorkspaces'](user.value.uid);
    if (!Object.values(userWorkspaces).length) {
      analytics.noWorkspaces(user.value.uid);
    }
    router.push(`/`);
  } catch (err) {
    showError.value = true;
    logger.error({ err }, `could not delete workspace ${route.params.workspaceId}: ${err}`);
  }

  loading.value = false;
}

function logAndReportWorkspaceDeletion() {
  analytics.userGroup(route.params.workspaceId, {
    Deleted: true,
    'Deletion Date': new Date().toISOString(),
    'Deleted By': user.value.uid,
  });

  analytics.track(productEvents.WORKSPACE_DELETED, {
    'Workspace ID': route.params.workspaceId,
    'Workspace Name': props.workspaceName,
  });

  logEvent(eventLogger.SWIMM_EVENTS.WORKSPACE_DELETED, {
    srcId: route.params.workspaceId,
    srcName: props.workspaceName,
    workspaceName: props.workspaceName,
  });
}
</script>

<template>
  <SettingsModalSection title="Danger zone">
    <SwText variant="body-S" class="danger-body"
      >You are the owner of this workspace. Deleting it will remove all associated data and users. This action cannot be
      undone.</SwText
    >
    <Action variant="danger" :loading="loading" @click="deleteWorkspace" data-testid="delete">Delete</Action>
    <SwText v-if="showError" variant="body-XS" class="error">Could not delete workspace. Please try again</SwText>
  </SettingsModalSection>
  <UserDialog
    danger
    ref="deleteWorkspaceDialog"
    title="Delete workspace"
    body="Are you sure you want to delete this workspace?"
    confirm-text="Delete"
  />
</template>

<style scoped lang="postcss">
.danger-body {
  margin-bottom: var(--space-sm);
}

.error {
  margin-top: var(--space-xs);
  color: var(--text-color-error);
}
</style>
