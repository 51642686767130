// @ts-strict

import {
  ApplicabilityStatus,
  Link,
  Path,
  SmartElementType,
  SmartElementWithApplicability,
  SmartSymbol,
  gitwrapper,
  parseSwmdTitleOnly,
} from '@swimm/shared';
import { getSmartElementWithApplicability } from '../swimmagic-common';
import { SymbolPath } from './symbol-path';

export class SymbolLink extends SymbolPath {
  linksMap: Map<string, Link> = new Map();
  draftLinks: Link[] = [];

  static isSmartSymbolLink(symbol: SmartSymbol): symbol is Link {
    return symbol.type === SmartElementType.LINK;
  }

  static linkToPath(link: Link): Path {
    const path: Path = {
      type: SmartElementType.PATH,
      filePath: link.filePath,
      id: link.id,
      gitInfo: link.gitInfo,
      symbolText: link.docTitle,
      isDirectory: false,
    };
    return path;
  }

  constructor(symbols: SmartSymbol[]) {
    const linksMap: Map<string, Link> = new Map();
    const draftLinks: Link[] = [];
    const convertedPaths: Path[] = [];
    for (const symbol of symbols) {
      if (SymbolLink.isSmartSymbolLink(symbol)) {
        if (symbol.isDraft) {
          draftLinks.push(symbol);
        } else {
          linksMap.set(symbol.id, symbol);
          convertedPaths.push(SymbolLink.linkToPath(symbol));
        }
      }
    }
    super(convertedPaths);
    this.linksMap = linksMap;
    this.draftLinks = draftLinks;
  }

  public override async updateSymbols(): Promise<{
    isApplicable: boolean;
    symbolsWithApplicability: SmartElementWithApplicability<SmartSymbol>[];
  }> {
    const draftLinksWithApplicability = this.draftLinks.map((link) =>
      getSmartElementWithApplicability<Link>({
        element: link,
        applicabilityStatus: ApplicabilityStatus.Verified,
        newInfo: link,
      })
    );

    const updatePathsResult = await super.updateSymbols();

    const newLinks = [];
    for (const path of updatePathsResult.symbolsWithApplicability as SmartElementWithApplicability<Link>[]) {
      const originalLink = this.linksMap.get(path.id);
      if (!originalLink) {
        // Not able to find link with id ${path.id}, this should never happen.
        return null;
      }
      let newLink: Link | undefined;
      if (
        path.applicability === ApplicabilityStatus.Verified ||
        path.applicability === ApplicabilityStatus.Autosyncable
      ) {
        const content = await gitwrapper.getFileContentFromRevision({
          filePath: path.newInfo.filePath,
          repoId: originalLink.gitInfo.repoId,
          revision: originalLink.gitInfo.branch,
        });
        const newTitle = parseSwmdTitleOnly(content);

        newLink = { ...originalLink, filePath: path.newInfo.filePath, docTitle: newTitle };
      }

      // This means that if the underlying path is verified or autosyncable, the link itself will be verified.
      const newApplicability = [ApplicabilityStatus.Verified, ApplicabilityStatus.Autosyncable].includes(
        path.applicability
      )
        ? ApplicabilityStatus.Verified
        : path.applicability;
      newLinks.push(
        getSmartElementWithApplicability<Link>({
          element: originalLink,
          newInfo: newLink,
          applicabilityStatus: newApplicability,
        })
      );
    }

    const symbolsWithApplicability = [...draftLinksWithApplicability, ...newLinks];
    return { isApplicable: updatePathsResult.isApplicable, symbolsWithApplicability };
  }
}
