import { ref } from 'vue';
import { defineStore } from 'pinia';
import { STORES } from '@/modules/core/stores/store-constants';

export const useHelpCenterStore = defineStore(STORES.HELP_CENTER, () => {
  const showHelpCenter = ref(false);
  const showEditorBasics = ref(false);
  const showKeyboardCheatsheet = ref(false);

  const toggleHelpCenter = (showHelpCenterValue) => {
    showHelpCenter.value = showHelpCenterValue;
  };

  const toggleEditorBasics = (showEditorBasicsValue) => {
    showEditorBasics.value = showEditorBasicsValue;
  };

  const toggleCheatsheet = (showCheatsheetValue) => {
    showKeyboardCheatsheet.value = showCheatsheetValue;
  };

  return {
    showHelpCenter,
    showEditorBasics,
    showKeyboardCheatsheet,
    toggleHelpCenter,
    toggleEditorBasics,
    toggleCheatsheet,
  };
});
