import type { StaticAnalysisIndex, TokenSuggestionsMap } from '@/services/tokenSuggestions/staticAnalysisIndex';
import { type TokenSuggestion } from '@swimm/shared';
import { ref } from 'vue';

export function useMockedStaticAnalysisIndex(): StaticAnalysisIndex {
  const load = async (): Promise<TokenSuggestion[]> => [];

  const get = (): { loading: boolean; suggestions: TokenSuggestion[] } => ({ loading: false, suggestions: [] });

  const getAsyncMap = async (): Promise<TokenSuggestionsMap> => {
    return new Map<string, TokenSuggestion[]>();
  };

  const clear = () => void 0;

  const suggestionsBuildTimestamp = ref(0);

  return { load, get, getAsyncMap, clear, suggestionsBuildTimestamp };
}
