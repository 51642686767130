interface mention {
  name: string;
  externalUID: string;
}

export function computeMentionList() {
  const mentions: mention[] = [];
  const mentionedList = document.querySelectorAll('.sym-mention');
  mentionedList.forEach((mentionNode) => {
    const externalUID = mentionNode.getAttribute('id');
    const name = mentionNode['innerText'];
    const mentionObj: mention = { name, externalUID };
    if (!mentions.find((item) => item.externalUID === externalUID)) {
      mentions.push(mentionObj);
    }
  });
  return mentions;
}
