import { getLoggerNew } from '#logger';
import { OpenAIModelName } from '../code-analysis';
import { TextCompletionParams } from '../types';
import { calculateStringSimilarity } from '../utils/similarity';

const MAX_CHARACTERS = 1500;
const TEXT_COMPLETION_MODEL: OpenAIModelName = 'GPT_3_5_TI';
const MAX_COMPLETION_CONTEXT_SIMILARITY_SCORE = 0.6;

const logger = getLoggerNew("packages/shared/src/genAi/text-completions.ts");

const RESPONSE_TEMPLATE = `{
  "completionSuggestion": string;
  "startCompletionWithSpace": boolean;
  "isRepeatingContextLine"?: boolean;
  "whyCompletionBreakRules": string;
  "howToFixSuggestion": string;
  "fixedCompletion": string;
}`;

export interface TextCompletionResponse {
  completionSuggestion: string;
  startCompletionWithSpace: boolean;
  isRepeatingContextLine?: boolean;
  whyCompletionBreakRules: string;
  howToFixSuggestion: string;
  fixedCompletion: string;
}

interface ExampleStructure extends TextCompletionResponse {
  docTitle: string;
  contextBefore: string;
  sentenceToComplete: string;
}

function createJsonStringifiedExample(examples: ExampleStructure[]): string {
  let result = '';
  let count = 1;
  for (const example of examples) {
    const input =
      `- Input: ` +
      JSON.stringify(
        {
          context: `${example.docTitle ? `Doc Title:${example.docTitle}\n` : ''}${example.contextBefore}`,
          sentenceToComplete: example.sentenceToComplete,
        },
        null,
        2
      );
    const output =
      `- Output: ` +
      JSON.stringify(
        {
          completionSuggestion: example.completionSuggestion,
          startCompletionWithSpace: example.startCompletionWithSpace,
          ...(example.isRepeatingContextLine ? { isRepeatingContextLine: example.isRepeatingContextLine } : {}),
          whyCompletionBreakRules: example.whyCompletionBreakRules,
          howToFixSuggestion: example.howToFixSuggestion,
          fixedCompletion: example.fixedCompletion,
        },
        null,
        2
      );
    result += ` Example ${count}:\n${input}\n${output}\n`;
    count++;
  }
  return result;
}

const TEXT_COMPLETION_PROMPT_INSTRUCTIONS = `Given the following context, please generate a text completion and strictly reply in a JSON format of ${JSON.stringify(
  RESPONSE_TEMPLATE,
  null,
  2
)}.
Follow the rules below:
- Ensure that the "completionSuggestion" maintains the same level of technical detail and clarity as the surrounding context.
- The completion must be concise, a single line of text.
- The completion should seamlessly connect the prefix and optional suffix contexts with the "sentenceToComplete".
- "fixedCompletion" should be identical to "completionSuggestion" if it follows the rules and "whyCompletionBreakRules" is empty.
- File paths should be inside backticks.
- "startCompletionWithSpace" should be true only when:
 - "sentenceToComplete" does not end with a middle of a word
 - "sentenceToComplete" does not end with a space already
${createJsonStringifiedExample([
  {
    docTitle: 'Popups',
    contextBefore:
      'we have an infrastructure for popups. It includes computed props: `isOpened`,`closePopup`,`getPosition`.',
    sentenceToComplete: 'To determine if the popup is opened',
    completionSuggestion: 'check the value of the variable `showPopup`',
    startCompletionWithSpace: true,
    whyCompletionBreakRules:
      'sentenceToComplete does not end with a middle of a word and does not end with a space and completionSuggestion does not start with a space',
    howToFixSuggestion: 'add a space at the beginning of the completionSuggestion',
    fixedCompletion: ' check the value of the variable `showPopup`',
  },
  {
    docTitle: 'Sum Function',
    contextBefore: '',
    sentenceToComplete: 'This document covers the function ',
    completionSuggestion: ' summing two numbers',
    startCompletionWithSpace: false,
    whyCompletionBreakRules:
      'sentenceToComplete already ends with a space and completionSuggestion is starting with a space, making too many spaces',
    howToFixSuggestion: 'remove the space at the beginning of the completionSuggestion',
    fixedCompletion: 'summing two numbers',
  },
  {
    docTitle: 'Sum Function',
    contextBefore: '',
    sentenceToComplete: 'This document covers the function',
    completionSuggestion: 'summing two numbers',
    startCompletionWithSpace: true,
    whyCompletionBreakRules:
      'sentenceToComplete does not end with a space and does not end with a middle of a word and completionSuggestion does not start with a space',
    howToFixSuggestion: 'add a space at the beginning of the completionSuggestion',
    fixedCompletion: ' summing two numbers',
  },
  {
    docTitle: 'Sum Function',
    contextBefore: '',
    sentenceToComplete: 'This document covers the funct',
    completionSuggestion: 'ion that sums two numbers',
    startCompletionWithSpace: false,
    whyCompletionBreakRules:
      'sentenceToComplete ends with a fragment of a word and completionSuggestion starts with non-needed space',
    howToFixSuggestion: 'remove the space at the beginning of the completionSuggestion',
    fixedCompletion: 'ion that sums two numbers',
  },
  {
    docTitle: 'Search Component',
    contextBefore: '',
    sentenceToComplete: 'The `src/components/Search.vue` compone',
    completionSuggestion: ' The `src/components/Search.vue` component is used for displaying the search feature',
    startCompletionWithSpace: false,
    whyCompletionBreakRules:
      'sentenceToComplete was a partial word and completionSuggestion is a completely new sentence starting with a space',
    howToFixSuggestion:
      'complete the word in sentenceToComplete and finish the original sentence in completionSuggestion',
    fixedCompletion: 'nt is used to display the search feature',
  },
  {
    docTitle: 'JFROG Wrapper',
    contextBefore: '```\n    <JfrogWrapper />\n```',
    sentenceToComplete: 'The `/apps/web/src/common/components/JfogWrapper.vue` modal ',
    completionSuggestion: 'is used to encapsulate the jfrog logic',
    startCompletionWithSpace: false,
    whyCompletionBreakRules:
      'sentenceToComplete already ends with a space and completionSuggestion is starting with a space, making too many spaces',
    howToFixSuggestion: 'remove the space at the beginning of the completionSuggestion',
    fixedCompletion: 'is used to display a global modal',
  },
])}
- Important: When describing a function, state its purpose and omit any mention of parameters or arguments.
${createJsonStringifiedExample([
  {
    docTitle: '',
    contextBefore: '```\nfunction calculateSum(a, b) {\n  return a + b;\n}\n```',
    sentenceToComplete: 'In this function, ',
    completionSuggestion: 'the logic that calculates the sum is implemented',
    startCompletionWithSpace: false,
    whyCompletionBreakRules: '',
    howToFixSuggestion: '',
    fixedCompletion: 'the logic that calculates the sum is implemented',
  },
  {
    docTitle: '',
    contextBefore:
      "```\ntriggerFunctionWithLog('onAddUser', onAddUserUtil, {\n  id,\n  key,\n  user: { userName, platformName },\n  env,\n  diff,\n  groupId,\n  defaultLogLevel,\n}),\n```",
    sentenceToComplete: 'In this snippet, we ',
    completionSuggestion: 'trigger the onAddUser function with the required params to add a user',
    startCompletionWithSpace: false,
    whyCompletionBreakRules: '',
    howToFixSuggestion: '',
    fixedCompletion: 'trigger the onAddUser function with the required params to add a user',
  },
  {
    docTitle: '',
    contextBefore:
      '```\nfunction fetchDataFromAPI(endpoint, headers) {\n  // fetch data from the given API endpoint\n}\n```',
    sentenceToComplete: 'In this example, we',
    completionSuggestion: ' are using the fetchDataFromAPI function to retrieve data from the API endpoint',
    startCompletionWithSpace: true,
    whyCompletionBreakRules: '',
    howToFixSuggestion: '',
    fixedCompletion: ' are using the fetchDataFromAPI function to retrieve data from the API endpoint',
  },
])}
- Use ONLY code symbols, paths and technologies that exist in the context.
${createJsonStringifiedExample([
  {
    docTitle: 'How to clear the cache',
    contextBefore: '',
    sentenceToComplete: 'In order to clear the cache',
    completionSuggestion: ' you need to run the function `clearCache()` from the folder `services/cache`',
    startCompletionWithSpace: true,
    whyCompletionBreakRules: 'The context is missing the symbols `clearCache()` and `services/cache`',
    howToFixSuggestion: 'Remove the symbols `clearCache()` and `services/cache` from the completionSuggestion',
    fixedCompletion: '',
  },
  {
    docTitle: 'How to invite a user?',
    contextBefore: '',
    sentenceToComplete: 'For inviting a user ',
    completionSuggestion: 'you need to use the function `inviteUser()`',
    startCompletionWithSpace: false,
    whyCompletionBreakRules: 'The context has no function `inviteUser()`!',
    howToFixSuggestion: 'remove function that does not exist in context from the suggestion',
    fixedCompletion: '',
  },
  {
    docTitle: 'Database Migration',
    contextBefore: '##Technical Details\n',
    sentenceToComplete: 'This document is about',
    completionSuggestion: ' migrating MongoDB databases to MySQL',
    startCompletionWithSpace: true,
    whyCompletionBreakRules: 'The context does not mention technologies like `Mongo`, `MySQL`',
    howToFixSuggestion: 'remove specific technologies that does not exist in context',
    fixedCompletion: '',
  },
  {
    docTitle: 'Billing System',
    contextBefore: '##Technical Details\nThe folder `services/general/billing` is',
    sentenceToComplete: 'used for',
    completionSuggestion: ' wrapping the Stripe API',
    startCompletionWithSpace: true,
    whyCompletionBreakRules:
      'The context does not mention Stripe API. This context is relevant only for a non-technical completion',
    howToFixSuggestion: 'create a non-technical completion without non-existing technology',
    fixedCompletion: ' our billing system infrastructure',
  },
])}
- Do not repeat lines from the context in the response.
${createJsonStringifiedExample([
  {
    docTitle: 'User Authentication',
    contextBefore: 'This document covers auth flow',
    sentenceToComplete: 'This',
    completionSuggestion: ' document covers auth flow',
    startCompletionWithSpace: true,
    isRepeatingContextLine: true,
    whyCompletionBreakRules: 'completionSuggestion repeats lines from context',
    howToFixSuggestion: 'do not repeat another line from the context',
    fixedCompletion: '',
  },
  {
    docTitle: 'User Authentication',
    contextBefore: 'This document covers auth flow',
    sentenceToComplete: 'Thi',
    completionSuggestion: 's document covers auth flow',
    startCompletionWithSpace: false,
    isRepeatingContextLine: true,
    whyCompletionBreakRules: 'completionSuggestion repeats lines from context',
    howToFixSuggestion: 'do not repeat context line',
    fixedCompletion: '',
  },
])}
- Important: When "whyCompletionBreakRules" is not empty "howToFixSuggestion" is mandatory and MUST address the specific issue mentioned in "whyCompletionBreakRules".
  Learn from the following examples to understand "howToFixSuggestion":
    - Input:
      - "context": "Doc Title:How to clear the cache\n", "sentenceToComplete": "In order to clear the cache"
      - "startCompletionWithSpace": true
      - "completionSuggestion": " you need to run the function \`clearCache()\` from the folder \`services/cache\`"
      - "whyCompletionBreakRules": "The context is missing the symbols \`clearCache()\` and \`services/cache\`"
    - Good "howToFixSuggestion": "Remove the symbols \`clearCache()\` and \`services/cache\` from the completionSuggestion"
      - Good because: Addresses the issue mentioned in "whyCompletionBreakRules", removing the non-existing symbols.
      - Good "fixedCompletion": ""
        - Good because: The context does not have the symbols mentioned in the completionSuggestion, so the fixedCompletion is empty.
      - Bad "fixedCompletion": " you need to run the function \`clearCache()\` from the folder \`services/cache\`"
        - Bad because: Does not follow the fix instructions in "howToFixSuggestion".
    - Bad "howToFixSuggestion": "Add the missing symbols to the completionSuggestion"
      - Bad because: Adds symbols that do not exist in the context, which is not allowed under under any circumstances!
    - Bad "howToFixSuggestion": "Add the missing function and folder to the completion"
      - Bad because: Adds function and folder that do not exist in the context, which is not allowed under under any circumstances!
    - Bad "howToFixSuggestion": "Add a space to the completion"
        - Bad because: the fix is not relevant to the issue mentioned in "whyCompletionBreakRules".
  - Input:
    - "context": "Doc Title:How to clear the cache\n", "sentenceToComplete": "In order to clear the cache"
    - "startCompletionWithSpace": true
    - "completionSuggestion": "you need to run the function \`clearCache()\` from the folder \`services/cache\`"
    - "whyCompletionBreakRules": "The context is missing the symbols \`clearCache()\` and \`services/cache\` and the completionSuggestion is not starting with a space"
    - "howToFixSuggestion": "1.Remove the symbols \`clearCache()\` and \`services/cache\`. 2.Add a space at the beginning of the completionSuggestion"
    - "fixedCompletion": ""
      - Good because: Addresses both of the issues mentioned in "whyCompletionBreakRules". The fixedCompletion is empty because the context does not have the symbols mentioned in the completionSuggestion.
    - Bad "howToFixSuggestion": "Add a space at the beginning of the completionSuggestion"
      - Bad because: Addresses only one of the issues mentioned in "whyCompletionBreakRules".
    - Bad "howToFixSuggestion": "add the missing functions to the completion"
      - Bad because: Adds functions that do not exist in the context, which is not allowed under the rules.

- Important! Do NOT return the examples or the rules in the completion.
- Reminder: you MUST follow the rules and reply in required JSON format.`;

export type TextCompletionContext = {
  before: string;
  after?: string;
};

function getContextParts(contextBeforeWithSentence: string): {
  promptPrefix: string;
  sentenceToComplete: string;
  contextBefore: string;
} {
  const parts = contextBeforeWithSentence.split('\n');
  const sentenceToComplete = parts.pop();
  const contextBefore = parts.join('\n');
  return {
    promptPrefix: `${TEXT_COMPLETION_PROMPT_INSTRUCTIONS}\n\n${JSON.stringify(
      {
        context: contextBefore,
        sentenceToComplete,
      },
      null,
      2
    )}`,
    sentenceToComplete,
    contextBefore,
  };
}

export const COMPLETE_TEXT_ENDPOINT: 'text-completion/create' | 'generative-ai/generate-text' =
  'text-completion/create';

export function getTextCompletionPrompt(docTitle: string, context: TextCompletionContext): TextCompletionParams {
  const contextWithTitle =
    docTitle && context.before ? { ...context, before: `Doc Title:${docTitle}\n${context.before}` } : context;

  const suffix = contextWithTitle?.after?.substring(0, MAX_CHARACTERS) ?? '';
  const { promptPrefix, sentenceToComplete, contextBefore } = getContextParts(contextWithTitle.before);
  const prompt = promptPrefix.substring(contextWithTitle.before.length - MAX_CHARACTERS);
  return {
    contextBefore,
    suffix,
    sentenceToComplete,
    prompt: {
      prompt,
      ...(suffix ? { suffix } : {}),
      model: TEXT_COMPLETION_MODEL,
      temperature: 0.2,
      max_tokens: 1000,
      stop: null, // Can be also "stop": ["\n"]}
    },
  };
}

export function parseTextCompletionLLMResponse({
  textToParse,
  shouldRetry,
  requestId,
  originalParams,
}: {
  textToParse?: string;
  shouldRetry: boolean;
  requestId: string;
  originalParams: TextCompletionParams;
}): string {
  if (!textToParse) {
    logger.info(`Received an empty text to parse ${shouldRetry ? 'from LLM' : 'in retry'} (requestId: ${requestId}...`);
    return '';
  }
  let generatedText = '';
  try {
    const parsedText = JSON.parse(textToParse) as TextCompletionResponse;

    if (parsedText.fixedCompletion) {
      generatedText = parsedText.fixedCompletion ?? '';
    } else {
      logger.debug(
        `Completion ${requestId} was not accepted due to: ${parsedText.whyCompletionBreakRules}. Original suggestion: ${parsedText.completionSuggestion}`
      );
    }
    if (
      !!generatedText &&
      parsedText.startCompletionWithSpace &&
      !generatedText.startsWith(' ') &&
      !originalParams.sentenceToComplete.endsWith(' ')
    ) {
      logger.info({ requestId }, `Completion ${requestId} should start with space. Adding space to completion.`);
      generatedText = ` ${generatedText}`;
    }
    generatedText = handleSimilarity(requestId, originalParams, generatedText, parsedText.isRepeatingContextLine);
    return generatedText;
  } catch (err) {
    try {
      // Sometimes the LLM returns the JSON with extra trailing characters or even the prompt suffix attached, so we need to get just the JSON part and try again.
      if (shouldRetry) {
        logger.info(
          `Parsing completion ${requestId} response from LLM failed. Trying to extract JSON from response and retry parsing...`
        );
        const jsonStart = textToParse.indexOf('{');
        const jsonEnd = textToParse.indexOf('}');
        const jsonString = textToParse.substring(jsonStart, jsonEnd + 1); // If no JSON at all, it returns an empty string
        return parseTextCompletionLLMResponse({
          textToParse: jsonString,
          shouldRetry: false,
          requestId,
          originalParams,
        });
      }
      logger.error(`Error parsing LLM response during retry. RequestId: ${requestId}`);
    } catch (err) {
      logger.error(`Error parsing response from LLM. RequestId: ${requestId}`);
    }
    return generatedText;
  }
}

function handleSimilarity(
  requestId: string,
  originalParams: TextCompletionParams,
  generatedText: string,
  isRepeatingContextLine?: boolean
): string {
  const { contextBefore, sentenceToComplete, suffix } = originalParams;
  if (!generatedText) {
    return '';
  }
  if (isRepeatingContextLine) {
    logger.info(
      { requestId },
      `The LLM marked their completion "${requestId}" as repeating a context line. Ignoring completion.`
    );
    return '';
  } else if (contextBefore.includes(generatedText) || suffix?.includes(generatedText)) {
    logger.info({ requestId }, `Completion ${requestId} exists in context. Ignoring completion.`);
    return '';
  } else {
    const similarity = calculateStringSimilarity(generatedText, sentenceToComplete);
    if (similarity >= MAX_COMPLETION_CONTEXT_SIMILARITY_SCORE) {
      logger.info(
        { requestId },
        `Completion ${requestId} is too similar to the sentence to complete (similarity ${Math.round(
          similarity * 100
        )}%). Ignoring completion.`
      );
      return '';
    }
  }
  return generatedText;
}
