<template>
  <ListViewItem
    data-testid="playlist-item"
    :stage-tag="tag"
    :title="playlist.name || 'Untitled playlist'"
    :filter-text="filterText"
    :branch="isPending && !isWorkspacePage ? branch : ''"
    :creator="playlist.creator_name"
    :creator-profile-img="playlist.creator_profile_url"
    :is-highlighted="isHighlightedItem"
    :doc-link="
      getResourceLink({
        resource: playlist,
        repoId,
        branch,
        type: DocumentationTypes.PLAYLIST,
        editMode: isDraft,
      })
    "
    :has-variations="hasVariations"
    :diff-state="playlist.diffState"
    :type="DocumentationTypes.PLAYLIST"
    :selected="playlist.selected"
    :show-folder-path="showFolderPath"
    :folder-id="playlistFolderId"
    :playlist-id="playlist.id"
    :selectable="selectable"
    :repo-id="repoId"
    @variations-click="onVariationsClick"
    @link-click="clearNewBranchData"
    @select="(ev) => $emit('select', ev)"
  >
    <template #options>
      <EllipsisOption
        name="Get shareable link"
        icon="link"
        :close-after-action="true"
        :handler="openShareModal"
        @click="reportClick('Share')"
      />
      <EllipsisOption
        v-if="canEdit"
        name="Edit"
        icon="edit-outline"
        :handler="navigateToEdit"
        :close-after-action="false"
        @click="reportClick('Edit')"
      />
      <EllipsisOption
        v-if="isDraft"
        name="Discard draft"
        icon="discard"
        :handler="shouldDiscardDraft"
        :close-after-action="false"
        @click="reportClick('Discard')"
      />
      <EllipsisOption
        v-if="canDeleteResource"
        name="Delete"
        icon="trash-outline"
        :handler="() => $emit('delete')"
        :close-after-action="false"
        @click="reportClick('Delete')"
      />
      <EllipsisOption
        name="Move to folder..."
        icon="move"
        :handler="() => $emit('move')"
        :close-after-action="false"
        @click="reportClick('Move')"
      />
    </template>
    <template #activity>
      <SwDate v-if="lastModifiedDate" :date="lastModifiedDate" />
    </template>
  </ListViewItem>
</template>

<script>
import EllipsisOption from '@/common/components/atoms/EllipsisOption.vue';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { useDemoStore } from '@/modules/demo/demo';
import { confirmDraftDeletion } from '@/modules/drafts3/discard-draft-confirmations';
import { mapActions } from 'vuex';
import { clickedOnInternalCardElement } from '@/common/utils/card-utils';
import { storeToRefs } from 'pinia';
import { useSwimmResource } from '@/common/composables/swimmResource';
import { useNavigate } from '@/common/composables/navigate';
import ListViewItem from '@/modules/core/components/ListViewItem.vue';
import { DocumentationTypes } from '@/common/consts';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { computed } from 'vue';
import { SwDate } from '@swimm/ui';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { useResourceActions } from '@/modules/resources/composables/resource-actions';

const tagsTypes = {
  Example: 'Example',
  Draft: 'Local draft',
  Pending: 'PENDING',
  Committed: 'COMMITTED',
};
export default {
  components: { EllipsisOption, ListViewItem, SwDate },
  props: {
    playlist: { type: Object, required: true },
    repoId: { type: String, required: true },
    branch: { type: String, required: false, default: '' },
    filterText: { type: String, required: false, default: '' },
    hasVariations: { type: Boolean, default: false },
    showFolderPath: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
  },
  emits: ['discard', 'delete', 'move', 'select', 'open-commit', 'open-share-modal', 'report-click', 'click'],
  setup(props) {
    const { logEvent } = useSwimmEventLogs();
    const { getResourceLink } = useResourceActions();
    const { isResourceEditable } = useSwimmResource();
    const { navigateToPageAndTerminateWorker, getRepoPath } = useNavigate();
    const { reposStateData } = storeToRefs(useReposStore());
    const { isOnDummyRepoPage } = storeToRefs(useDemoStore());
    const { getItemParentFolder } = useFoldersStore();

    const isDraft = computed(() => Boolean(props.playlist.draftId));

    const lastModifiedDate = computed(() => {
      return props.playlist?.modified?.toDate();
    });

    const playlistFolderId = computed(() => {
      if (props.playlist.folderId) {
        return props.playlist.folderId;
      }
      if (props.playlist.id) {
        const folder = getItemParentFolder(props.playlist.id, props.repoId);
        return folder?.id;
      }
      return null;
    });

    return {
      reposStateData,
      DocumentationTypes,
      isResourceEditable,
      logEvent,
      navigateToPageAndTerminateWorker,
      getRepoPath,
      isOnDummyRepoPage,
      isDraft,
      lastModifiedDate,
      playlistFolderId,
      getResourceLink,
    };
  },
  computed: {
    canEdit() {
      return this.isDraft || this.isResourceEditable(this.repoId, this.playlist.id, 'playlist');
    },
    draftTagTooltip() {
      const stateText = this.playlist.id ? 'changed' : 'created';
      return `This file has been ${stateText} locally, but not yet committed to the branch ${this.branch}`;
    },
    tag() {
      if (this.isDraft) {
        return {
          text: tagsTypes.Draft,
          isWarning: true,
          tooltip: this.draftTagTooltip,
          handler: (event) => {
            event.preventDefault();
            this.$emit('open-commit');
          },
        };
      } else if (this.isPending) {
        return { text: tagsTypes.Pending };
      } else {
        return { text: tagsTypes.Committed };
      }
    },
    isPending() {
      return this.playlist.pending;
    },
    isWorkspacePage() {
      return !this.$route.params.repoId;
    },
    canDeleteResource() {
      // If card is in workspacePage- don't allow to remove it
      if (this.isWorkspacePage) {
        return false;
      }
      if (this.isOnDummyRepoPage) {
        return false;
      }
      // If the card is of another branch (received as a prop) - Don't allow to remove it.
      // TODO: remove this after supporting removal of playlists from another branch
      if (this.isPending) {
        return false;
      }

      return this.isResourceEditable(this.repoId, this.playlist.id, 'playlist');
    },
    isHighlightedItem() {
      if (this.isDraft) {
        return false;
      }
      return this.$route.query.highlightedPlaylistId === this.playlist.id;
    },
  },
  methods: {
    ...mapActions('filesystem', ['cleanLoadedRepoData']),
    openShareModal() {
      this.$emit('open-share-modal', {
        entity: 'PLAYLIST',
        entityId: this.playlist.id,
        entityName: this.playlist.name,
        entityLink: `${window.location.origin}${this.getResourceLink({
          resource: this.playlist,
          repoId: this.repoId,
          branch: this.branch,
          type: DocumentationTypes.PLAYLIST,
        })}`,
        repoId: this.repoId,
      });
    },
    async shouldDiscardDraft() {
      if (await confirmDraftDeletion()) {
        this.$emit('discard');
      }
    },
    clearNewBranchData(event) {
      if (clickedOnInternalCardElement(event)) {
        return;
      }
      if (this.branch !== this.$route.params.branch) {
        this.cleanLoadedRepoData(this.repoId);
      }
      this.$emit('click');
    },
    navigateToEdit() {
      this.$router.push(
        this.getResourceLink({
          resource: this.playlist,
          repoId: this.repoId,
          branch: this.branch,
          type: DocumentationTypes.PLAYLIST,
          editMode: true,
        })
      );
    },
    onVariationsClick() {
      const repoPath = this.getRepoPath(this.repoId, this.branch);
      const playlistUrl = `${repoPath}/playlists/${this.playlist.id}`;
      this.$router.push(playlistUrl);
    },
    reportClick(actionName) {
      this.$emit('report-click', { type: 'Playlist', id: this.playlist.id, action: actionName });
    },
  },
};
</script>
