import { debounce } from 'lodash-es';
import { ref } from 'vue';

/** @deprecated */
export class EditorAnimations {
  readonly nodeAnimationsSet = ref(new Set<string>());

  readonly debounceResetNodeAnimationSet = debounce(() => {
    const onAnimationEnd = () => {
      this.nodeAnimationsSet.value.clear();
      document.removeEventListener('transitionend', onAnimationEnd);
    };
    document.addEventListener('transitionend', onAnimationEnd);
  }, 1000);

  animateNodeById(id: string): void {
    this.nodeAnimationsSet.value.add(id);
    this.debounceResetNodeAnimationSet.cancel();
    this.debounceResetNodeAnimationSet();
  }

  shouldAnimateNode(id: string): boolean {
    return this.nodeAnimationsSet.value.has(id);
  }
}
