import type { GitFile, GitHunk, GitLine } from './git-diff-parser-types';
import { GitFilePatchTypes, GitLineMarks, GitLineTypes } from './git-diff-parser-types';
import * as gitDiffParserCommon from './git-diff-parser-common';

export function parseGitPatch(gitPatch: string): Array<GitFile> {
  if (!gitPatch || gitPatch === '') {
    throw new Error('Input git patch cannot be empty');
  }
  const patchFiles = splitPatchIntoFiles(gitPatch);
  return patchFiles.map((gitPatchFile: string) => parseGitPatchFile(gitPatchFile));
}

export function parseGitPatchFile(gitPatchFile: string): GitFile {
  assertNonBinaryFilesDiff(gitPatchFile);
  const hunksStartIndex = gitPatchFile.search(gitDiffParserCommon.GIT_HUNK_HEADER_REGEX);
  const patchFileHeaders = parsePatchFileHeaders(gitPatchFile, hunksStartIndex);
  const hunks = parseGitPatchHunks(gitPatchFile, hunksStartIndex);
  const changesCount = getLineCounters(hunks);
  return {
    ...patchFileHeaders,
    hunks: hunks,
    linesAdded: changesCount.linesAdded,
    linesDeleted: changesCount.linesDeleted,
  };
}
function getLineCounters(hunks: Array<GitHunk>) {
  let linesAdded = 0;
  let linesDeleted = 0;

  for (const hunk of hunks) {
    linesAdded += hunk.lines.filter((line) => line.type === GitLineTypes.Addition).length;
    linesDeleted += hunk.lines.filter((line) => line.type === GitLineTypes.Deletion).length;
  }
  return { linesDeleted, linesAdded };
}

function parseGitHunkHeader(gitHunk: string) {
  const regexedHunkHeader = gitHunk.match(gitDiffParserCommon.GIT_HUNK_HEADER_REGEX);
  const hunkHeader = regexedHunkHeader[0];

  const startLineA = gitDiffParserCommon.parseIntOrNull(regexedHunkHeader[1]);
  const startLineB = gitDiffParserCommon.parseIntOrNull(regexedHunkHeader[3]);

  // Both revisions start lines are mandatory for a valid git hunk. If one of them is missing - parsing should stop
  if (startLineA === null) {
    throw new Error(`could not get the revision A line number of git-hunk: ${startLineA}. Parsing stopped.`);
  } else if (startLineB === null) {
    throw new Error(`could not get the revision A line number of git-hunk: ${startLineB}. Parsing stopped.`);
  }
  const linesCountA = gitDiffParserCommon.parseIntOrNull(regexedHunkHeader[2]);
  const linesCountB = gitDiffParserCommon.parseIntOrNull(regexedHunkHeader[4]);
  return { header: hunkHeader, startLineA, linesCountA, linesCountB, startLineB };
}
export function parseGitHunkPatch(gitHunk: string): GitHunk {
  const parsedHunkHeader = parseGitHunkHeader(gitHunk);
  const lines = parseGitHunkLines(gitHunk, parsedHunkHeader);
  return { ...parsedHunkHeader, lines };
}
function parseGitHunkLines(gitHunk, parsedHunkHeader): Array<GitLine> {
  const hunkLines = gitHunk.split('\n').slice(1);
  let fileALinesCount = 0;
  let fileBLinesCount = 0;
  const parsedLines: Array<GitLine> = [];
  for (const line of hunkLines) {
    const lineType = getLineChangeType(line);
    const lineNumbers =
      lineType !== GitLineTypes.EOF
        ? getLineNumbers({ lineType: lineType, parsedHunkHeader: parsedHunkHeader, fileALinesCount, fileBLinesCount })
        : copyLineNumbers(parsedLines[parsedLines.length - 1]);

    // Avoid adding irrelevant lines (newlines) that could be included in the input patch
    if (shouldSkipIrrelevantLines(lineNumbers, parsedHunkHeader)) {
      break;
    }
    // Update counters
    if ([GitLineTypes.Context, GitLineTypes.Deletion].includes(lineType)) {
      fileALinesCount++;
    }
    if ([GitLineTypes.Context, GitLineTypes.Addition].includes(lineType)) {
      fileBLinesCount++;
    }

    parsedLines.push({ data: line, type: lineType, ...lineNumbers });
  }
  return parsedLines;
}

// Remove additional "\n" (that were added after the patch was created) and should not be part of the changes array as they are not part of the diff
function shouldSkipIrrelevantLines(lineNumbers, parsedHunkHeader) {
  const aCount = parsedHunkHeader.linesCountA === null ? 1 : parsedHunkHeader.linesCountA;
  const bCount = parsedHunkHeader.linesCountB === null ? 1 : parsedHunkHeader.linesCountB;
  const deletionsAndContextLength = parsedHunkHeader.startLineA + aCount;
  const additionsAndContextLength = parsedHunkHeader.startLineB + bCount;
  return (
    lineNumbers.lineNumberInA >= deletionsAndContextLength || lineNumbers.lineNumberInB >= additionsAndContextLength
  );
}

function copyLineNumbers(gitLine: GitLine) {
  if (!gitLine) {
    return { lineNumberInA: 0, lineNumberInB: 0 };
  }
  const lineNumbers = {};
  if (typeof gitLine.lineNumberInA !== 'undefined') {
    lineNumbers['lineNumberInA'] = gitLine.lineNumberInA;
  }
  if (typeof gitLine.lineNumberInB !== 'undefined') {
    lineNumbers['lineNumberInB'] = gitLine.lineNumberInB;
  }
  return lineNumbers;
}
function parseGitPatchHunks(gitPatchFile: string, hunksStartIndex: number): Array<GitHunk> {
  if (hunksStartIndex === -1) {
    return [];
  }
  const gitHunks = gitPatchFile.substring(hunksStartIndex, gitPatchFile.length);
  const hunks = gitHunks.split(new RegExp(gitDiffParserCommon.GIT_HUNK_UNGROUPED_HEADER_REGEX, 'gm'));
  return hunks.map((hunkPatch) => parseGitHunkPatch(hunkPatch));
}

function parsePatchFileHeaders(
  gitPatchFile: string,
  hunksStartIndex: number
): { patchType: GitFilePatchTypes; fileNameA: string; fileNameB: string; index: Array<string> } {
  const headersEndIndex = hunksStartIndex === -1 ? gitPatchFile.length : hunksStartIndex - 1;
  const fileHeaders = gitPatchFile.substring(0, headersEndIndex);
  const fileHeadersLines = fileHeaders.split('\n');
  const fileNames = fileHeadersLines[0].match(new RegExp(gitDiffParserCommon.FILE_NAMES_REGEX, 'gm'));
  const parsedFileNamesRevision = {
    fileNameA: fileNames[0].replace(/^a\//, ''),
    markerFileNameA: fileHeaders.match(gitDiffParserCommon.fileNameRegexFromMarkersLine(GitLineMarks.Deletion)),
    fileNameB: fileNames[1].replace(/^b\//, ''),
    markerFileNameB: fileHeaders.match(gitDiffParserCommon.fileNameRegexFromMarkersLine(GitLineMarks.Addition)),
  };
  const fileNameA = parsedFileNamesRevision.markerFileNameA
    ? parsedFileNamesRevision.markerFileNameA[0].split(GitLineMarks.Deletion).join('').trim()
    : parsedFileNamesRevision.fileNameA;
  const fileNameB = parsedFileNamesRevision.markerFileNameB
    ? parsedFileNamesRevision.markerFileNameB[0].split(GitLineMarks.Addition).join('').trim()
    : parsedFileNamesRevision.fileNameB;
  const patchType = getFileDiffType(fileNameA, fileNameB);
  const index = fileHeadersLines.slice(1).filter((indexLine) => indexLine !== '');
  return { fileNameA, fileNameB, patchType, index };
}
function splitPatchIntoFiles(gitPatch: string): Array<string> {
  return gitPatch.split(new RegExp(gitDiffParserCommon.GIT_FILE_REGEX, 'gm'));
}

/**
 * Returns the git diff type of the file
 * @note - This function does NOT handle diffs of type "Copied". These kind of diffs will be marked as "RENAMED" instead
 * @param fileAName - The file A path
 * @param fileBName - The file B path
 * @returns "ADDED" | "MODIFIED" | "DELETED" | "RENAMED"
 */
function getFileDiffType(fileAName: string, fileBName: string): GitFilePatchTypes {
  const isFileExistInGitRevision = (fileName) => fileName !== '/dev/null';
  return (
    (!isFileExistInGitRevision(fileAName) && GitFilePatchTypes.Added) ||
    (!isFileExistInGitRevision(fileBName) && GitFilePatchTypes.Deleted) ||
    (fileAName !== fileBName && GitFilePatchTypes.Renamed) ||
    GitFilePatchTypes.Modified
  );
}

// Assert that the original diff does not contain binary files
// Throw error to stop the processing in case of a binary file input
function assertNonBinaryFilesDiff(gitFilePatch: string): void {
  const isContainingBinaryFiles = gitFilePatch.match(new RegExp(/(?=Binary files).+differ\n/));
  if (isContainingBinaryFiles) {
    throw new Error('Diff containing binary files! parsing stopped');
  }
}

function getLineChangeType(line: string) {
  return (
    (line.startsWith(GitLineMarks.Deletion) && GitLineTypes.Deletion) ||
    (line.startsWith(GitLineMarks.Addition) && GitLineTypes.Addition) ||
    (gitDiffParserCommon.isEOFLineChange(line) && GitLineTypes.EOF) ||
    GitLineTypes.Context
  );
}

function getLineNumbers({
  lineType,
  parsedHunkHeader,
  fileALinesCount,
  fileBLinesCount,
}: {
  lineType: GitLineTypes;
  parsedHunkHeader: GitHunk;
  fileALinesCount: number;
  fileBLinesCount: number;
}) {
  const lineNumbers: GitLine = {} as GitLine;
  const lineNumbersByType = {
    [GitLineTypes.Context]: () => {
      lineNumbers.lineNumberInA = fileALinesCount + parsedHunkHeader.startLineA;
      lineNumbers.lineNumberInB = fileBLinesCount + parsedHunkHeader.startLineB;
    },
    [GitLineTypes.Addition]: () => {
      lineNumbers.lineNumberInB = fileBLinesCount + parsedHunkHeader.startLineB;
    },
    [GitLineTypes.Deletion]: () => {
      lineNumbers.lineNumberInA = fileALinesCount + parsedHunkHeader.startLineA;
    },
  };
  lineNumbersByType[lineType]();
  return lineNumbers;
}
