<script setup lang="ts">
import { Ref, computed, ref } from 'vue';
import { useStore } from 'vuex';
import FeatureAccess from './ai-feature-access/FeatureAccess.vue';
import { useRoute } from 'vue-router';
import { DUMMY_REPO_ID, StiggFeatures, getLoggerNew } from '@swimm/shared';
import swal from 'sweetalert';
import { SWAL_CONTACT_US_CONTENT } from '@swimm/editor';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import SettingsModalSection from '@/modules/core/components/settings-modal/SettingsModalSection.vue';
import { useStigg } from '@/common/composables/useStigg';
import PaywallModal, { PaywallEntity } from '@/common/components/modals/PaywallModal.vue';

const logger = getLoggerNew(__modulename);

const store = useStore();
const route = useRoute();
const busy = ref(false);
const { stiggClient } = useStigg();

const workspaceId = computed(() => route.params.workspaceId);

const workspace = computed(() => store.getters['database/db_getWorkspace'](workspaceId.value));

const enableAskForNewRepos = computed(() => workspace.value?.settings?.ask_swimm_enabled_for_new_repos ?? false);
const enableGenAiForNewRepos = computed(() => workspace.value?.settings?.generative_ai_enabled_for_new_repos ?? false);

const realRepoIds = computed<string[]>(() =>
  (workspace.value?.repositories ?? []).filter((repoId: string) => repoId !== DUMMY_REPO_ID)
);
const repoCount = computed(() => realRepoIds.value.length);

const workspaceStore = useWorkspaceStore(); // not using this store, only for update after the cloud function call

const enabledCountAskSwimm = computed(
  () =>
    realRepoIds.value.filter(
      (repoId) => store.getters['database/db_getRepoMetadata'](repoId)?.integrations?.ask_swimm_enabled ?? false
    ).length
);

const enabledCountGenAi = computed(
  () =>
    realRepoIds.value.filter(
      (repoId) => store.getters['database/db_getRepoMetadata'](repoId)?.integrations?.generative_ai_enabled ?? false
    ).length
);

async function updateSettings(
  workspaceSettingsField: string,
  repoIntegrationsField: string,
  newValue: boolean
): Promise<void> {
  busy.value = true;
  logger.info(
    `Updating ${workspaceSettingsField} and ${repoIntegrationsField} to ${newValue} for workspace ${workspaceId.value}`
  );
  let anyError = false;
  try {
    const result = await CloudFunctions.updateWorkspaceAndReposFeature({
      workspaceId: workspaceId.value as string,
      workspaceSettingsField,
      repoIntegrationsField,
      newValue,
    });
    if (result.data.status !== 'success') {
      throw new Error(`Cloud function return with error, something went wrong`);
    }
  } catch (err) {
    anyError = true;
    logger.error({ err }, `Error updating settings for ${workspaceId.value}`);
  }
  try {
    // update the workspace
    await store.dispatch('database/updateWorkspaceMetadata', { workspaceId: workspaceId.value });
    // update also the workspace store
    await workspaceStore.fetchWorkspace();
    // update all the repos metadatas
    // do in limited batches to avoid stucking the browser
    const batchSize = 10;
    for (let i = 0; i < realRepoIds.value.length; i += batchSize) {
      await Promise.all(
        realRepoIds.value
          .slice(i, i + batchSize)
          .map((repoId) => store.dispatch('database/updateRepoMetadata', { repoId, force: true }))
      );
    }
  } catch (err) {
    anyError = true;
    logger.error({ err }, 'Failed to fetch some data after updateSettings');
  }
  if (anyError) {
    showError();
  }
  busy.value = false;
}

async function showError() {
  await swal({
    title: 'Errors occurred in update process. Try to reload the page',
    content: { element: SWAL_CONTACT_US_CONTENT() },
  });
}

const showPaywall = ref(false);
const paywallEntity: Ref<string | null> = ref(null);

let showPaywallResolve: ((v: boolean) => void) | null = null;

function onPaywallClose({ isTrialStarted }: { isTrialStarted: boolean }) {
  showPaywall.value = false;
  paywallEntity.value = null;
  showPaywallResolve?.(isTrialStarted);
}

async function showPaywallIfNeeded(featureId: StiggFeatures, featurePaywallEntity: string): Promise<boolean> {
  const canEnable = stiggClient?.getBooleanEntitlement?.({ featureId }).hasAccess ?? false;
  if (canEnable) {
    return true;
  }
  showPaywall.value = true;
  paywallEntity.value = featurePaywallEntity;
  return new Promise((resolve) => {
    showPaywallResolve = resolve;
  });
}
</script>
<template>
  <SettingsModalSection title="AI & feature access">
    <FeatureAccess
      v-if="!workspace?.hide_ask_swimm"
      :is-enabled="enableAskForNewRepos"
      :loading="busy"
      feature-title="Ask Swimm"
      feature-description="Ask code questions directly from your IDE"
      feature-link="https://docs.swimm.io/swimm-ai/ask-swimm/"
      feature-icon="ask"
      :enabled-count="enabledCountAskSwimm"
      :repo-count="repoCount"
      :before-enable="() => showPaywallIfNeeded(StiggFeatures.ASK_SWIMM, PaywallEntity.ASK_SWIMM)"
      @update-enabled="(newValue) => updateSettings('ask_swimm_enabled_for_new_repos', 'ask_swimm_enabled', newValue)"
    />
    <FeatureAccess
      v-if="!workspace?.settings?.disable_gen_ai"
      :is-enabled="enableGenAiForNewRepos"
      :loading="busy"
      feature-title="Generative AI"
      feature-description="Generate docs from PR/snippets and Doc Rules with AI"
      feature-link="https://docs.swimm.io/swimm-ai/"
      feature-icon="generate"
      :enabled-count="enabledCountGenAi"
      :repo-count="repoCount"
      :before-enable="() => showPaywallIfNeeded(StiggFeatures.GENERATIVE_AI, PaywallEntity.GENERATIVE_AI)"
      @update-enabled="
        (newValue) => updateSettings('generative_ai_enabled_for_new_repos', 'generative_ai_enabled', newValue)
      "
    />
    <PaywallModal
      v-if="paywallEntity"
      :show="showPaywall && !!paywallEntity"
      :entity="paywallEntity"
      @close="onPaywallClose"
    />
  </SettingsModalSection>
</template>
