<script setup lang="ts">
import { type WorkspaceRepo, getGitProviderIconName } from '@swimm/shared';

import BaseLayoutGap from '../../components/BaseLayoutGap/BaseLayoutGap.vue';
import BaseLayoutIcons from '../../components/BaseLayoutIcons/BaseLayoutIcons.vue';
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';

import MenuItem from '../MenuItem/MenuItem.vue';
import { computed } from 'vue';

const props = defineProps<{
  repo: WorkspaceRepo;
  asRepoListing?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  selectRepo: [repo: WorkspaceRepo];
}>();

const repoNavigationDirection = computed(() => (props.asRepoListing ? 'forward' : 'back'));

const repoNavigationIcon = computed(() =>
  repoNavigationDirection.value === 'forward' ? 'arrow-next' : 'back-browsing'
);

const computedClasses = computed(() => ({
  'menu-repos-item--disabled': props.disabled,
}));

function onSelectRepo(e: Event, repo: WorkspaceRepo) {
  if (props.disabled) {
    return;
  }

  if (e instanceof KeyboardEvent) {
    if (repoNavigationDirection.value === 'back' && e.key === 'ArrowLeft') {
      emit('selectRepo', repo);
    } else if (repoNavigationDirection.value === 'forward' && e.key === 'ArrowRight') {
      emit('selectRepo', repo);
    } else if (e.key === 'Enter') {
      emit('selectRepo', repo);
    }
  } else if (e instanceof MouseEvent) {
    emit('selectRepo', repo);
  }
}

function getIcon(repo: WorkspaceRepo) {
  return getGitProviderIconName(repo.provider);
}

const menuItemTooltip = computed(() => {
  if (props.repo.owner) {
    return `${props.repo.owner}/${props.repo.name}`;
  }
  return props.repo.name;
});
</script>

<template>
  <MenuItem
    v-tooltip="menuItemTooltip"
    class="menu-repos-item"
    :class="computedClasses"
    data-testid="swimm-repo-link-item"
    @click="onSelectRepo($event, repo)"
    @keydown.enter.stop="onSelectRepo($event, repo)"
    @keydown.left.stop="onSelectRepo($event, repo)"
    @keydown.right.stop="onSelectRepo($event, repo)"
  >
    <template #leftIcon>
      <BaseLayoutGap wrapper="span" size="xxsmall" alignment="center">
        <BaseIcon :name="repoNavigationIcon" />
        <BaseIcon :name="getIcon(repo)" />
      </BaseLayoutGap>
    </template>
    <BaseLayoutIcons>
      <strong v-text="repo.name" />
    </BaseLayoutIcons>
    <template #additional>
      <slot />
    </template>
  </MenuItem>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.menu-repos-item {
  &__disabled-icon {
    margin-left: var(--spacing-xsmall);
  }

  &--disabled {
    color: var(--color-text-disabled);

    &:hover,
    &:focus {
      background: none;
      cursor: default;
    }
  }
}
</style>
