import { useStore } from 'vuex';
import { eventLogger } from '@swimm/shared';
import { getRepoStateDataToKeep } from '@/adapters-common/user';
import { measurePerformance } from '@/common/utils/sentry-measurements';
import { useCreateRepoUser } from './createRepoUser';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useRouting } from '@/common/composables/routing';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { computed } from 'vue';

export function useInitRepository() {
  const store = useStore();
  const { setRepoStateData } = useReposStore();
  const { logEvent } = useSwimmEventLogs();
  const { createRepoSwimmer, createRepoLifeguard } = useCreateRepoUser();
  const { user } = storeToRefs(useAuthStore());
  const { isBranchProtected } = useRouting();
  const { createRootFolder } = useFoldersStore();
  const fs_getLocalRepoMetadata = computed(() => store.getters['filesystem/fs_getLocalRepoMetadata']);
  const loadLocalRepo = async (args) => store.dispatch('filesystem/loadLocalRepo', args);
  const saveRepository = async (args) => store.dispatch('database/saveRepository', args);
  const subscribeToRepository = async (args) => store.dispatch('database/subscribeToRepository', args);
  const isRepoExistOnDB = async (args) => store.dispatch('database/isRepoExistOnDB', args);

  const initRepository = async (repoId, defaultBranch, workspaceId: string) => {
    await measurePerformance({ name: 'initialize-repository' }, async () => {
      const workspace = store.getters['database/db_getWorkspace'](workspaceId);
      const ask_swimm_enabled = workspace?.settings?.ask_swimm_enabled_for_new_repos ?? false;
      const generative_ai_enabled = workspace?.settings?.generative_ai_enabled_for_new_repos ?? false;
      const repoMetadata = fs_getLocalRepoMetadata.value(repoId);
      const newRepo = {
        url: repoMetadata.url,
        creator: user.value.uid,
        creator_name: user.value.displayName || user.value.nickname || 'user',
        modifier: user.value.uid,
        modifier_name: user.value.displayName || user.value.nickname || 'user',
        name: repoMetadata.name,
        provider: repoMetadata.provider,
        owner: repoMetadata.owner,
        integrations: {
          md_export_upon_save: false,
          commit_images_to_repo: true,
          swmd: true,
          github_app_recommended_comments_disabled: false,
          ask_swimm_enabled,
          generative_ai_enabled,
        },
        is_private: repoMetadata.isPrivate,
        counter_swimms: 0,
        counter_playlists: 0,
        counter_swimmers: 0,
        counter_lifeguards: 0,
        api_url: repoMetadata.api_url || undefined,
        tenant_id: repoMetadata.tenant_id || undefined,
      };

      const repoExistsInDB = await isRepoExistOnDB({ repoId: repoId });

      if (!repoExistsInDB) {
        await saveRepository({ resourceName: 'repositories', resource: newRepo, repoId: repoId });
      }

      const repoStateDataToKeep = getRepoStateDataToKeep(null);
      await setRepoStateData(repoId, {
        ...repoStateDataToKeep,
        repoName: repoMetadata.name,
        owner: repoMetadata.owner,
        provider: repoMetadata.provider,
        api_url: repoMetadata.api_url,
        tenant_id: repoMetadata.tenant_id,
        branch: defaultBranch,
        isProtectedBranch: await isBranchProtected({ repoId, branch: defaultBranch }),
        defaultBranch: defaultBranch,
      });

      if (!repoExistsInDB) {
        await createRepoLifeguard(user.value, repoId);
      }
      await createRepoSwimmer(user.value, repoId);
      if (!repoExistsInDB) {
        await createRootFolder(repoId);
      }
      await loadLocalRepo(null);
      logEvent(
        eventLogger.SWIMM_EVENTS.REPOSITORY_CREATED,
        {
          repoId: repoId,
          repoName: newRepo.name,
          srcId: repoId,
          srcName: newRepo.name,
        },
        user.value
      );
      await subscribeToRepository({ repoId: repoId, updateChildren: ['swimms', 'playlists'] });
    });
    return repoId;
  };

  return {
    initRepository,
  };
}
