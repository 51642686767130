<script setup lang="ts">
import { type PropType, computed, nextTick, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { Action, Checkbox, Divider, Icon, IconButton, RadioButton, SwText } from '@swimm/ui';
import {
  GitProviderInfo,
  config,
  getLoggerNew,
  isRepoIdDummyRepo,
  productEvents,
  workspaceSettingsTabs,
} from '@swimm/shared';
import { BaseButton, BaseIcon, BaseTextarea } from '@swimm/reefui';
import { useNotificationsStore } from '@swimm/editor';
import type { DraftInvalidType, ModalDraft } from '../draftTypes';
import ProgressBar from '@/common/components/atoms/ProgressBar.vue';
import BatchCommitDependenciesNotice from './BatchCommitDependenciesNotice.vue';
import { useRouting } from '@/common/composables/routing';
import { useBookDemo } from '@/modules/core/composables/book-demo';
import { useAnalytics } from '@/common/composables/useAnalytics';
import ChangedDocumentationIcon from '@/modules/core/components/ChangedDocumentationIcon.vue';
import { capitalize } from 'lodash-es';
import { useNavigate } from '@/common/composables/navigate';
import { storeToRefs } from 'pinia';
import {
  type BatchSaveStep,
  SaveOptions,
  VerifyError,
  getCommitNotificationErrorMesssage,
  useBatchCommitStore,
} from '../store/batch-commit';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import BranchSelector from '@/common/components/organisms/BranchSelector.vue';
import TextFieldMultiline from '@/common/components/atoms/TextFieldMultiline.vue';
import { getRemoteRepo } from '@/remote-adapters/local_repo';
import { useContinuousProgress } from '@/common/composables/useContinuousProgress';
import ConfirmationDialog from '@/common/components/modals/ConfirmationDialog.vue';
import { useNPSStore } from '@/modules/nps-survey/stores/npsStore';
import { computeDraftDisplayName } from '@/common/utils/draft-utils';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useWorkspaceSettingsModalStore } from '@/modules/workspace/modals/settings/store/workspace-settings';

const logger = getLoggerNew(__modulename);

const DUMMY_REPO_SAVE_STEPS: BatchSaveStep[] = ['save-to-db', 'prepare-swms', 'create-branch', 'commit'];

const props = defineProps({
  /* TODO Maybe this two should be in a store? */
  workspaceId: { type: String, required: true },
  repoId: { type: String, required: true },
  currentDraftId: { type: String, default: '' },
  loading: { type: Boolean },
  drafts: { type: Map as PropType<Map<string, ModalDraft>>, required: true },
  draftSelection: { type: Set as PropType<Set<string>>, required: true },
  dependantDrafts: { type: Set as PropType<Set<string>>, required: true },
  showDependantDraftsNotice: { type: Boolean, default: false },
  invalidDrafts: { type: Map as PropType<Map<string, DraftInvalidType>>, required: true },
  disableCommit: { type: Boolean },
  commitDrafts: {
    type: Function as PropType<(progress: (progress: BatchSaveStep) => void) => Promise<void>>,
    required: true,
  },
  verifying: { type: Boolean, required: true },
  validateNewBranch: {
    type: Function as PropType<(targetBranch: string, draftIds: string[]) => Promise<boolean>>,
    required: true,
  },
  repoBranches: {
    type: Array as PropType<{ name: string }[]>,
    required: true,
  },
});

const emit = defineEmits<{
  (event: 'close'): void;
  (event: 'add-repo'): void;
  (event: 'select-all-dependant-drafts'): void;
  (event: 'deselect-all-dependant-drafts'): void;
  (event: 'select-draft', id: string): void;
  (event: 'deselect-draft', id: string): void;
  (event: 'discard-all-drafts'): void;
  (event: 'discard-draft', id: string): void;
  (event: 'fix-draft', id: string): void;
}>();

const { openCalendlyDemoLink, reportBookDemoClick } = useBookDemo();
const { assertOnboardingRoute } = useRouting();
const analytics = useAnalytics();
const { navigateToBranch } = useNavigate();
const npsStore = useNPSStore();
const { reposStateData } = storeToRefs(useReposStore());
const { commitMessage, saveOption, startPR, newRemoteBranchName } = storeToRefs(useBatchCommitStore());
const { selectCreatingNewBranch, cleanBatchCommitState } = useBatchCommitStore();
const { addNotification } = useNotificationsStore();
const { user } = storeToRefs(useAuthStore());
const store = useStore();
const getRepoMetadata = store.getters['database/db_getRepoMetadata'];
const { openWorkspaceSettingsModal } = useWorkspaceSettingsModalStore();
const isWorkspaceAdmin = computed(() =>
  store.getters['database/db_isWorkspaceAdmin'](props.workspaceId, user.value.uid)
);

const saving = ref(false);
const commitProcessStarted = ref(false);
const commitStatus = ref('');
const highlightUnsavableDrafts = ref(false);
const showDiscardAllDraftsAlert = ref(false);
const loadingBranch = ref(false);
const isBranchSelectorOpened = ref(false);

const { progress: commitProgress, onStepStarted } = useContinuousProgress<BatchSaveStep>(
  (step) =>
    ({
      verifying: { postPercentage: 0, expectedLengthSeconds: 0.5 },
      'save-to-db': { postPercentage: 5, expectedLengthSeconds: 0.5 },
      'prepare-swms': { postPercentage: 10, expectedLengthSeconds: 0.5 },
      'create-branch': { postPercentage: 25, expectedLengthSeconds: 1.25 },
      commit: { postPercentage: 75, expectedLengthSeconds: 4.2 },
      'create-pr': { postPercentage: 95, expectedLengthSeconds: 1.5 },
      finalizing: { postPercentage: 100, expectedLengthSeconds: 0.1 },
    }[step])
);

const onBatchSaveProgress = (nextStep: BatchSaveStep) => {
  commitStatus.value = {
    verifying: 'Verifying...',
    'save-to-db': 'Saving...',
    'prepare-swms': 'Preparing markdown files...',
    'create-branch': 'Creating branch...',
    commit: 'Committing...',
    'create-pr': 'Opening Pull Request...',
    finalizing: 'Finalizing...',
  }[nextStep];
  onStepStarted(nextStep);
};

// TODO: Move up to controller
// const newDocOrPlaylist = [PageRoutesNames.DOC_NEW, PageRoutesNames.PLAYLIST_NEW];
// const docAndPlaylistOrigin = [PageRoutesNames.DOC_EDIT, PageRoutesNames.PLAYLIST_EDIT, ...newDocOrPlaylist];

const isDummyRepo = computed(() => isRepoIdDummyRepo(props.repoId));
const isOnboarding = computed(() => assertOnboardingRoute());
const repoDataFromState = computed(() => reposStateData.value[props.repoId]);
const isProtectedBranch = computed(() => !!repoDataFromState.value?.isProtectedBranch);

const sortedDrafts = computed(() => {
  return [...props.drafts.values()].sort((draftA, draftB) => {
    // Sort the current draft first
    if (draftA.draftId === props.currentDraftId) {
      return -1;
    }

    return draftA.created - draftB.created;
  });
});

const selectedDrafts = computed(() => {
  // TODO Can use some iterator filter instead of converting to array?
  return [...props.drafts.values()].filter((draft) => !!props.draftSelection.has(draft.draftId));
});

const invalidDraftsSelected = computed(() => {
  // TODO Can use some iterator filter instead of converting to array?
  return new Set([...props.draftSelection].filter((value) => props.invalidDrafts.has(value)));
});

const invalidDraftsMessage = computed(() => {
  // TODO Can use some iterator filter instead of converting to array?
  const selectedPlaylists = Array.from(invalidDraftsSelected.value.values()).reduce((result, draftId) => {
    if (props.drafts.get(draftId).type === config.SWIMM_FILE_TYPES.PLAYLIST) {
      return result + 1;
    }

    return result;
  }, 0);
  const selectedOnlyPlaylists = selectedPlaylists === invalidDraftsSelected.value.size;
  const selectedOnlyDocs = selectedPlaylists === 0;

  let messageStart: string;
  if (invalidDraftsSelected.value.size > 1) {
    messageStart = `${invalidDraftsSelected.value.size} selected ${
      selectedOnlyDocs ? 'docs' : selectedOnlyPlaylists ? 'playlists' : 'docs / playlists'
    }`;
  } else {
    messageStart = `${selectedDrafts.value.length > 1 ? 'A' : 'The'} selected ${selectedOnlyDocs ? 'doc' : 'playlist'}`;
  }

  return `${messageStart} need to be fixed before committing.`;
});

const dependentDraftNames = computed(() => {
  // TODO Can use some iterator filter instead of converting to array?
  return [...props.drafts.values()]
    .filter((draft) => props.dependantDrafts.has(draft.draftId))
    .map(({ title, type }) => ({ title: computeDraftDisplayName(title), type }));
});

const disableBranchSelector = computed((): boolean => {
  return !anySelected.value || props.dependantDrafts.size > 0;
});

const discardAllDraftConfirmationMessage = computed(() => {
  return `Are you sure you want to delete ${
    props.drafts.size > 1 ? `all ${props.drafts.size} drafts` : 'the draft'
  } on branch ${repoDataFromState.value.branch}? This action can not be undone.`;
});

function locateUnsavableDrafts() {
  highlightUnsavableDrafts.value = true;
  nextTick(() => {
    setTimeout(() => {
      highlightUnsavableDrafts.value = false;
    }, 2000);
  });
}

const allSelected = computed<boolean>({
  get() {
    return props.draftSelection.size > 0 && props.draftSelection.size === props.drafts.size;
  },

  set(value) {
    // TODO Should we emit select-all/unselect-all instead?
    for (const draft of props.drafts.values()) {
      updateDraftSelection(draft.draftId, value);
    }
  },
});

const anySelected = computed(() => {
  return [...props.draftSelection].some((v) => v);
});

watch(
  () => props.repoId,
  async () => {
    if (isOnboarding.value) {
      return;
    }
    const repoDataResponse = await getRemoteRepo({ repoId: props.repoId });
    hasRepoWritePermission.value = !!repoDataResponse.repo?.writeAccess;
  },
  { immediate: true }
);

const hasRepoWritePermission = ref(false);

const commitDisabled = computed(
  () =>
    (!hasRepoWritePermission.value && !isDummyRepo.value) ||
    !anySelected.value ||
    invalidDraftsSelected.value.size > 0 ||
    props.dependantDrafts.size > 0 ||
    (saveOption.value === SaveOptions.NEW_BRANCH && newRemoteBranchName.value.length === 0) ||
    newRemoteBranchExists.value ||
    props.disableCommit
);

const newRemoteBranchExists = computed(() => {
  return (
    saveOption.value === SaveOptions.NEW_BRANCH &&
    props.repoBranches.some((br) => br.name === newRemoteBranchName.value)
  );
});

function updateDraftSelection(draftId: string, value: boolean) {
  if (value) {
    emit('select-draft', draftId);
  } else {
    emit('deselect-draft', draftId);
  }
}

function handleConnectRepoClick() {
  emit('add-repo');
  emit('close');
}

function handleBookDemoClick() {
  openCalendlyDemoLink();
  reportBookDemoClick('Batch Commit Modal');
  emit('close');
}

function openDiscardAllDraftsConfirmation() {
  analytics.track(productEvents.CLICKED_DISCARD_ALL_DRAFTS, {
    'Workspace ID': props.workspaceId,
    'Repo ID': props.repoId,
    'Draft Count': props.drafts.size,
    Context: 'Batch Commit',
  });
  showDiscardAllDraftsAlert.value = true;
}

async function verifyTargetBranch(targetBranch: string) {
  const selectedDraftIds = selectedDrafts.value.map((draft) => draft.draftId);
  return await props.validateNewBranch(targetBranch, selectedDraftIds);
}

async function handleBranchChange(branchData) {
  loadingBranch.value = true;
  // Navigate to repo page if on draft page and the current draft was not moved to the new branch).
  const moveToRepoPage =
    props.currentDraftId && [...props.drafts.values()].every((draft) => draft.draftId !== props.currentDraftId);
  await navigateToBranch(branchData.newBranchName, moveToRepoPage);
}

function isInvalidSelectedDraft(draftId: string) {
  return invalidDraftsSelected.value.has(draftId);
}

// TODO Move this
function isPlaylist(draft: ModalDraft) {
  return draft?.type === config.SWIMM_FILE_TYPES.PLAYLIST;
}

const branchSelectorDisabledTooltipMessage = computed(() => {
  if (!anySelected.value) {
    return 'No changes selected - select changes and switch a branch.';
  }
  if (props.dependantDrafts.size > 0) {
    return 'The selected changes depend on unselected docs.\nSelect the dependent docs and switch a branch.';
  }
  return '';
});

function calculateDraftIcon(draft) {
  return isPlaylist(draft) ? 'playlist' : 'document';
}

function currentDraftState(newDraft: boolean) {
  return newDraft ? 'added' : 'modified';
}

function isInvalidDraft(draftId: string) {
  return props.invalidDrafts.has(draftId);
}

function openWorkspaceAdvancedSettings() {
  emit('close');
  openWorkspaceSettingsModal({
    initialTabCode: workspaceSettingsTabs.ADVANCED,
  });
}

const providerName = computed(
  () => GitProviderInfo[getRepoMetadata(props.repoId)?.provider]?.displayName ?? 'Git Provider'
);

const commitDisabledTooltipMessage = computed(() => {
  if (!hasRepoWritePermission.value && !isDummyRepo.value) {
    return `You don't have write permissions to the repository "${getRepoMetadata(props.repoId).name}" on ${
      providerName.value
    }.\nPlease contact an Admin on your ${providerName.value} account.`;
  }
  if (!anySelected.value) {
    return 'No changes selected - select at least one change to commit.';
  }
  if (invalidDraftsSelected.value.size > 0) {
    return 'The selected changes cannot be committed.\nFix the docs and commit.';
  }
  const dependantDraftsInvalid = new Set([...props.dependantDrafts].filter((value) => props.invalidDrafts.has(value)));
  if (dependantDraftsInvalid.size > 0) {
    return 'The selected changes depend on changes that cannot be committed.\nFix the dependent docs and commit.';
  }
  const dependantDraftsUnselected = new Set(
    [...props.dependantDrafts].filter((value) => !props.invalidDrafts.has(value))
  );
  if (dependantDraftsUnselected.size > 0) {
    return 'The selected changes depend on unselected docs.\nSelect the dependent docs and commit.';
  }
  if (saveOption.value === SaveOptions.NEW_BRANCH && newRemoteBranchName.value.length === 0) {
    return 'Fill in the name of the branch to create.';
  }
  if (newRemoteBranchExists.value) {
    return 'A branch with this name already exists.';
  }
  if (props.disableCommit) {
    return 'Wait for auto-save to complete.';
  }
  return '';
});

function reportCommitClicked() {
  let context = 'Repo Page';
  let isDefaultSelection = allSelected.value;
  if (props.currentDraftId) {
    if (isPlaylist(props.drafts.get(props.currentDraftId))) {
      context = 'Edit Playlist';
    } else {
      context = 'Edit Doc';
    }

    isDefaultSelection = selectedDrafts.value.length === 1 && selectedDrafts.value[0].draftId === props.currentDraftId;
  }

  analytics.track(productEvents.EXECUTED_BATCH_COMMIT, {
    Context: context,
    'Commited Changes': selectedDrafts.value.length,
    'Is Default Selection': isDefaultSelection,
    'Total Change Count': props.drafts.size,
    'Has Custom Commit Message': !!commitMessage.value,
  });
}

async function dummyRepoCommit() {
  // Show fake progress bar.
  for (const step of DUMMY_REPO_SAVE_STEPS) {
    await new Promise((resolve) => setTimeout(() => resolve(onBatchSaveProgress(step)), 900));
  }
  saving.value = false;
  commitProcessStarted.value = true;
}

async function onCommitClicked() {
  if (props.disableCommit) {
    return;
  }
  reportCommitClicked();
  saving.value = true;
  if (isDummyRepo.value) {
    dummyRepoCommit();
    return;
  }
  if (!repoDataFromState.value?.branch) {
    logger.error('Cancelled batch-commit: current branch is not available.');
    addNotification('Unable to commit. Please refresh the page and try again or contact our support team.', {
      icon: 'warning',
    });
    saving.value = false;
    return;
  }
  try {
    await props.commitDrafts(onBatchSaveProgress);
    cleanBatchCommitState();
    saving.value = false;
    npsStore.showPeriodicalNPSIfAppropriate();
    emit('close');
  } catch (err) {
    if (err instanceof VerifyError) {
      logger.warn({ err }, 'Failed batch-commit due to verify');
      addNotification('Commit failed due to remote changes, please fix the docs and try again.', {
        icon: 'warning',
      });
    } else {
      logger.error({ err }, 'Failed batch-commit');
      addNotification(getCommitNotificationErrorMesssage(err), {
        icon: 'warning',
      });
    }
    saving.value = false;
  }
}

async function onDiscardAllDrafts() {
  emit('discard-all-drafts');
  emit('close');
}

function callFixDraft(draftId: string) {
  emit('fix-draft', draftId);
  emit('close');
}
</script>

<template>
  <div class="container" data-testid="push-changes-popup">
    <div class="header">
      <SwText variant="subtitle-L">Commit drafts</SwText>
      <IconButton name="close" @click="$emit('close')" />
    </div>
    <Divider />
    <!-- TODO Extract -->
    <div v-if="loading" class="loading-state">
      <span><Icon class="headline2 spinning-icon" name="sync" /> Loading...</span>
    </div>
    <!-- TODO Extract -->
    <div v-else-if="saving" class="loading-state">
      <span data-testid="commit-loader"><Icon class="headline2 spinning-icon" name="sync" />{{ commitStatus }}</span>
      <ProgressBar class="commit-progress" :progress="commitProgress" />
    </div>
    <!-- TODO Extract -->
    <div v-else-if="verifying" class="loading-state">
      <span><Icon class="headline2 spinning-icon" name="sync" /> Verifying docs...</span>
    </div>
    <!-- TODO Extract -->
    <div
      v-else-if="isDummyRepo && commitProcessStarted"
      class="dummy-repo-container"
      data-testid="dummy-repo-fake-commit"
    >
      <SwText variant="body-S" class="commit-text"
        >You cannot commit draft changes to Swimm’s demo repo. Connect your repo to start working on your own
        docs.</SwText
      >
      <Action @click="handleConnectRepoClick">Connect your repo</Action>
      <Divider />
      <SwText variant="body-S" class="commit-text"
        >Want to learn more about how to integrate<br />
        Swimm with your team? <strong>Book a live onboarding session with our expert team</strong></SwText
      >
      <Action secondary @click="handleBookDemoClick">Book a 1:1 onboarding session</Action>
    </div>
    <template v-else-if="drafts.size > 0">
      <BatchCommitDependenciesNotice
        v-if="dependantDrafts.size > 0"
        :dependant-drafts="dependentDraftNames"
        @select-drafts-that-depend-on-selected-drafts-clicked="$emit('select-all-dependant-drafts')"
        @unselect-drafts-that-depend-on-unselected-drafts-clicked="$emit('deselect-all-dependant-drafts')"
      />
      <!-- TODO Extract to component reflecting invalid drafts -->
      <div v-else-if="invalidDraftsSelected.size > 0" class="unsavable-drafts-message">
        <SwText variant="body-S">
          <div>{{ invalidDraftsMessage }}</div>
          <div class="show-link link" @click="locateUnsavableDrafts">
            Show {{ invalidDraftsSelected.size > 1 ? 'them' : 'it' }}
          </div>
        </SwText>
      </div>
      <div v-else-if="showDependantDraftsNotice" class="unsavable-drafts-message">
        <SwText variant="body-S">
          <div>
            Your playlist contains drafts that are not selected for this commit. Make sure to select all linked drafts
            to keep your playlist intact.
          </div>
        </SwText>
      </div>
      <div class="drafts">
        <div class="all-changes">
          <Checkbox class="all-checkbox" size="small" v-model="allSelected">
            <SwText class="all-checkbox-text" variant="subtitle-L">All changes</SwText>
          </Checkbox>
          <SwText
            v-if="drafts.size"
            data-testid="discard-all-drafts"
            variant="body-XS"
            class="discard-button clickable"
            @click="openDiscardAllDraftsConfirmation"
            >Discard all</SwText
          >
        </div>
        <!-- TODO: should be sorted for display with the current draft first -->
        <div
          v-for="draft in sortedDrafts"
          :key="draft.draftId"
          class="draft"
          :class="{ highlight: highlightUnsavableDrafts && isInvalidSelectedDraft(draft.draftId) }"
        >
          <Checkbox
            class="draft-checkbox"
            size="small"
            :model-value="draftSelection.has(draft.draftId)"
            @update:model-value="(value) => updateDraftSelection(draft.draftId, value)"
            :title="draft.title"
          >
            <div class="draft-checkbox-line" data-testid="draft-item">
              <ChangedDocumentationIcon
                :type="calculateDraftIcon(draft)"
                :state="currentDraftState(draft.isNew)"
                size="16"
                :error="isInvalidDraft(draft.draftId)"
              />
              <SwText :class="{ 'checkbox-text': true }" variant="body-S">
                {{ draft.title }}
              </SwText>
              <SwText
                v-if="draft.draftId === currentDraftId"
                variant="system-body"
                :class="{ 'current-draft-label': true }"
              >
                CURRENT
              </SwText>
            </div>
          </Checkbox>
          <div class="draft-status">
            <SwText
              v-if="isInvalidDraft(draft.draftId)"
              variant="body-XS"
              class="change-type link"
              @click="callFixDraft(draft.draftId)"
              >{{ invalidDrafts.get(draft.draftId) }}</SwText
            >
            <SwText v-else variant="body-XS" class="change-type">
              {{ capitalize(currentDraftState(draft.isNew)) }}
            </SwText>
            <div class="actions">
              <Icon
                name="discard"
                data-testid="discard-draft"
                class="action-icon"
                @click="$emit('discard-draft', draft.draftId)"
                v-tooltip="{ content: 'Discard changes', placement: 'left' }"
              />
              <RouterLink v-if="currentDraftId !== draft.draftId" :to="draft.location" target="_blank">
                <Icon name="outbound-link" class="action-icon open-doc-button" v-tooltip="'Open in new tab'" />
              </RouterLink>
            </div>
          </div>
        </div>
      </div>

      <Divider />

      <!-- TODO Extract, handles commit process (message and branch switching) -->
      <div class="details">
        <TextFieldMultiline placeholder="Commit message" class="commit-message-input" v-model="commitMessage" />
        <div class="push-to-branch-option">
          <RadioButton
            v-model="saveOption"
            :value="SaveOptions.CURRENT_BRANCH"
            :disabled="isProtectedBranch"
            small
            class="branch-option"
            data-testid="push-popup-radio-current-branch"
          >
            <SwText variant="body-S">Push to</SwText>
          </RadioButton>
          <div class="branch">
            <VTooltip :disabled="!disableBranchSelector">
              <BranchSelector
                :repo-id="repoId"
                :loading="loadingBranch"
                :is-readonly="disableBranchSelector"
                append-to-body
                show-protected
                :validate="verifyTargetBranch"
                @branch-changed="handleBranchChange"
                @add-new-branch="selectCreatingNewBranch"
                uid="batch-commit-branch-dropdown"
                in-batch-commit
                suggest-new-branch
                :batch-commit-draft-count="selectedDrafts.length"
                @opened="isBranchSelectorOpened = true"
                @closed="isBranchSelectorOpened = false"
              />
              <template #popper>
                <SwText class="disabled-tooltip-text">{{ branchSelectorDisabledTooltipMessage }}</SwText>
              </template>
            </VTooltip>
          </div>
        </div>
        <RadioButton
          v-model="saveOption"
          :value="SaveOptions.NEW_BRANCH"
          small
          class="branch-option"
          data-testid="push-popup-radio-new-branch"
          ><SwText variant="body-S">Create a <strong>new branch</strong> and start a pull request</SwText>
        </RadioButton>
        <div class="branch-input-container">
          <BaseTextarea
            v-if="saveOption === SaveOptions.NEW_BRANCH"
            v-model="newRemoteBranchName"
            placeholder="Enter branch name"
            class="branch-input"
            :rows="1"
            data-testid="new-branch-name-input"
          >
            <template #leftIcon><BaseIcon name="branch" /></template>
            <template #rightIcon v-if="isWorkspaceAdmin"
              ><BaseButton class="branch-settings" @click="openWorkspaceAdvancedSettings" variant="tertiary"
                ><template #leftIcon><BaseIcon name="settings2" /></template></BaseButton
            ></template>
          </BaseTextarea>
          <Checkbox
            v-if="saveOption === SaveOptions.NEW_BRANCH"
            v-model="startPR"
            size="small"
            data-testid="push-popup-checkbox-create-pr"
          >
            <SwText variant="body-S" data-testid="start-pr">Start a pull request</SwText>
          </Checkbox>
        </div>
        <VTooltip :disabled="!commitDisabled">
          <div>
            <Action
              class="commit-button"
              size="small"
              data-testid="push-popup-save-button"
              :disabled="commitDisabled"
              @click="onCommitClicked"
            >
              Commit {{ selectedDrafts.length }} change{{ selectedDrafts.length !== 1 ? 's' : '' }}
            </Action>
          </div>
          <template #popper>
            <SwText class="disabled-tooltip-text">{{ commitDisabledTooltipMessage }}</SwText>
          </template>
        </VTooltip>
      </div>
    </template>
    <div v-else-if="isOnboarding" class="error-content">
      <SwText variant="body-S">Connect a repository to commit.</SwText>
      <Action class="add-repo-link" @click="$emit('add-repo')">Connect repository</Action>
    </div>
    <div v-else class="error-content">
      <SwText variant="body-S">No drafts to be committed in this branch.</SwText>
    </div>
  </div>
  <ConfirmationDialog
    v-if="showDiscardAllDraftsAlert"
    title="Discard all changes?"
    :text="discardAllDraftConfirmationMessage"
    :show="showDiscardAllDraftsAlert"
    confirm-text="Discard all drafts"
    variant="danger"
    :show-cancel="true"
    :close-on-confirm="true"
    @close="() => (showDiscardAllDraftsAlert = false)"
    @confirm="onDiscardAllDrafts"
  />
</template>

<style scoped lang="postcss">
.container {
  display: flex;
  flex-flow: column nowrap;
  width: 364px;
  max-height: calc(100vh - 60px);
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
}

.commit-progress {
  align-self: stretch;
}

.loading-state {
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: var(--space-md);
  padding-right: var(--space-md);
}

.spinning-icon {
  display: inline-block;
  padding-top: 6px;
  color: var(--text-color-disable);
  animation: spin;
  animation-duration: 1s;
  animation-direction: reverse;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.dummy-repo-container {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  padding: var(--space-sm);
  align-items: center;

  .commit-text {
    text-align: center;
    line-height: 1.5rem; /* 24px */
  }
}

.link,
.drafts .change-type.link {
  cursor: pointer;
  color: var(--text-color-link);
}

.show-link {
  margin-top: var(--space-base);
}

.drafts {
  padding: 16px;
  overflow-y: auto;
}

.all-changes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  border-radius: 4px;

  .discard-button {
    color: var(--text-color-secondary);
    text-decoration: underline;
    width: 125px;
  }
}

.all-changes:hover {
  background-color: var(--color-hover);
}

.all-checkbox {
  width: 190px;
}

.all-checkbox-text {
  font-weight: bold;
  color: var(--text-color-secondary);
}

.draft {
  display: flex;
  align-items: center;
  padding: 3px;
  border-radius: 4px;
  justify-content: space-between;
  border: 1px solid transparent;
  transition: border 0.2s linear;
  margin-bottom: -1px;
}

.draft:hover {
  background-color: var(--color-hover);
}

.draft.highlight {
  border: 1px solid var(--color-border-danger);
}

.draft .draft-status {
  width: 125px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.draft .change-type {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--text-color-secondary);
}

.drafts .checkbox-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.draft .draft-checkbox {
  width: 190px;
}

.draft-checkbox-line {
  display: flex;
  width: inherit;
  align-items: center;

  .current-draft-label {
    color: var(--text-color-secondary);
    background-color: var(--color-surface);
    padding: 0px 4px;
    white-space: nowrap;
    margin-left: var(--space-xs);
    line-height: initial;
  }
}

.unsavable-drafts-message {
  margin: var(--space-sm) var(--space-sm) 0px var(--space-sm);
  padding: 12px 16px;
  background-color: var(--color-status-error);
  border-radius: 4px;
}

.actions {
  display: none;
  margin-top: -5px;
}

.draft:hover .actions {
  display: initial;
  min-width: 40px;
  margin-left: 5px;
}

.action-icon {
  padding: 1px;
  margin: 1px;
  cursor: pointer;
}

.open-doc-button {
  margin-left: 5px;
  cursor: pointer;
}

.error-content {
  color: var(--text-color-secondary);
  padding: 16px 20px;
}

.add-repo-link {
  margin-top: var(--space-md);
  width: 100%;
}

/*** Commit message ***/
.details {
  padding: 16px;
  flex: 0;
}

.commit-message-input {
  margin-bottom: 8px;
  height: 56px;
}

.default-branch-option {
  display: flex;
  align-items: center;
  width: 100%;
}

.push-to-branch-option {
  display: flex;
  align-items: self-end;
  height: 35px;
  margin-bottom: var(--space-base);
}

.branch {
  margin-left: 5px;
}

.protected-branch {
  width: 75px;
  margin: 0 1px;
}

.branch-icon {
  padding-left: 0;
}

.branch-input-container {
  margin-left: 32px;
  margin-bottom: 16px;

  &:not(:hover) {
    .branch-settings {
      display: none;
    }
  }
}

.branch-input-container .branch-input {
  width: 100%;
  margin-bottom: 8px;
}

.branch-option {
  margin-bottom: 8px;
}

.branch-option.disabled {
  cursor: default;
}

.commit-button {
  width: 100%;
}

.disabled-tooltip-text {
  white-space: pre-line;
}
</style>
