<template>
  <div class="hotspot" />
</template>

<style scoped lang="postcss">
.hotspot {
  background: var(--swimm-shades);
  cursor: pointer;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  margin: 8px auto;
  transform: scale(1);
  animation: pulse 1.6s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(12, 104, 255, 1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(12, 104, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(12, 104, 255, 0);
  }
}
</style>
