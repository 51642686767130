import { type Ref, inject, onMounted, onUnmounted, provide, ref } from 'vue';

const WINDOW_HEIGHT_INJECT = 'windowHeight';

export function useWindowHeight(skipInject = false): Ref<number> {
  if (!skipInject) {
    try {
      const providedWindowHeight = inject<Ref<number>>(WINDOW_HEIGHT_INJECT);

      if (providedWindowHeight) {
        return providedWindowHeight;
      }
    } catch {
      //
    }
  }

  const windowHeight = ref(window.innerHeight);

  provide(WINDOW_HEIGHT_INJECT, windowHeight);

  function updateWindowHeight() {
    windowHeight.value = window.innerHeight;
  }

  onMounted(() => {
    window.addEventListener('resize', updateWindowHeight);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateWindowHeight);
  });

  return windowHeight;
}
