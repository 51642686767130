<template>
  <div>
    <div class="content">
      <Checkbox :model-value="value" :disabled="disabled" class="text" @update:model-value="handleClick">
        <span>{{ text }}</span>
      </Checkbox>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Checkbox } from '@swimm/ui';

const props = defineProps({
  value: { type: Boolean, required: true },
  disabled: { type: Boolean, required: true },
  text: { type: String, required: true },
  name: { type: String, required: true },
});
const emit = defineEmits(['click']);

function handleClick(newValue: boolean) {
  const checkBoxName = props.name;
  const data = { newValue, checkBoxName };
  emit('click', data);
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.text {
  margin: 0 0 0 16px;
  font-size: var(--body-S);
}
</style>
