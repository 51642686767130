const arrayUtils = {
  hasEmtpyValue: (array) => array.filter((val) => val === '').length > 0,

  shuffle: (array) => {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  },

  findSubarray: (arr, subarr) => {
    for (var i = 0; i < 1 + (arr.length - subarr.length); i++) {
      var j = 0;
      for (; j < subarr.length; j++) {
        if (arr[i + j] !== subarr[j]) {
          break;
        }
      }
      if (j === subarr.length) {
        return i;
      }
    }
    return -1;
  },
};

export default arrayUtils;
