const iconNameExpression = '[A-Za-z0-9-]+';
const iconModifierExpression = '~[A-Za-z]+';
const iconsRegex = new RegExp(`\\$\\(${iconNameExpression}(?:${iconModifierExpression})?\\)`, 'g');
const escapeIconsRegex = new RegExp(`(\\\\)?${iconsRegex.source}`, 'g');
const tabExpression = /([ \t]+)/g;
const nonBreakingSpace = '&nbsp;';
const endTagExpression = />/gm;
const escapedEndtag = '\\>';
const newlineExpression = /\n/g;
const escapedNewline = '\n\n';
const escapeUnwantedDataExpression = /[\\`*_{}[\]()#+\-!]/g;
const escapedBackRefrence = '\\$&';

// Copied from vscode/src/markdownEscape.ts
export function escapeMarkdownContent(content: string): string {
  return content
    .replace(escapeUnwantedDataExpression, escapedBackRefrence)
    .replace(escapeIconsRegex, (match, escaped: string) => (escaped ? match : `\\${match}`))
    .replace(tabExpression, (_match, g1: string) => nonBreakingSpace.repeat(g1.length))
    .replace(endTagExpression, escapedEndtag)
    .replace(newlineExpression, escapedNewline);
}
