import { CLOUD_FUNCTIONS_HOST, getFriendlyVersion, isAutomation, isProduction, isStage, isStaging } from '@/config';
import { getLoggerNew, slugify } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

const isReportingEnvironment = (isStaging || isProduction) && !isAutomation && !isStage;
logger.debug(`Product events reporting is ${isReportingEnvironment ? 'enabled' : 'disabled'}`);

const appVersion = getFriendlyVersion();

/**
 *
 * @param name the name of the event (should be taken/added to productEvents)
 * @param payload object of to be reported track event to segment with additional data.
 */
export function reportCustomProductAlert(name: string, payload = {}) {
  const segment = getSegment();
  const slug = slugifyEvents(name);
  const properties = { ...payload, app_version: appVersion, Slug: slug };
  if (!segment) {
    logger.info(`[NO SEGMENT] reportCustomProductAlert:  ${name} with ${JSON.stringify(properties)}`);
    return;
  }
  try {
    segment.track(name, properties);
  } catch (err) {
    logger.error({ err }, `could not send event to Segment ${name}. Details:${err}`);
  }
}

export async function tEventCloudFunction({
  identity,
  event,
  payload = {},
}: {
  identity: string;
  event: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}) {
  const slug = slugifyEvents(event);
  const properties = { ...payload, app_version: appVersion, Slug: slug };
  if (!isReportingEnvironment) {
    logger.info(`[NO SEGMENT] tEventCloudFunction:  ${event} with ${JSON.stringify(properties)}`);
    return undefined;
  }
  const date = new Date();

  try {
    await fetch(`${CLOUD_FUNCTIONS_HOST}/tEvent`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        identity,
        timestamp: date.toISOString(),
        event,
        properties,
      }),
    });
  } catch (err) {
    logger.error({ err }, `could not send tEvent. Details: ${err}`);
  }
}

/**
 *
 * @param name of the event/page (should be taken/added to pageEvents)
 * @param payload extra properties for the report
 */
export function reportPageVisit(name, payload = {}) {
  const properties = { ...payload, app_version: appVersion };
  const segment = getSegment();
  if (!segment) {
    logger.info(`[NO SEGMENT] reportPageVisit: ${name} with ${JSON.stringify(properties)}`);
    return;
  }
  try {
    segment.page(name, properties);
  } catch (err) {
    logger.error({ err }, `Couldn't identify page event ${name}. Details: ${err}`);
  }
}

function slugifyEvents(eventName: string) {
  return slugify(eventName, '_', { replace: /-+/g });
}

function getSegment() {
  if (!isReportingEnvironment || !window['analytics']) {
    return null;
  }

  return window['analytics'];
}
