import MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core';
import swm_inline from './swm_inline';
import swm_drop from './swm_drop';
import swm_token from './swm_token';
import swm_path from '@/markdownit/plugins/swm_path';

// This specialized case of only text or swm_token requires a specialized
// Markdown parser that will only parse that, an unfortunate side effect is that
// swm_token depends on the backticks inline rule, and since we don't run it as
// it is not reversible back to the original text, we need a special modified
// swm_token rule that will handle matching that by itself
const mermaidContentParser = MarkdownIt();
mermaidContentParser.use(swm_inline);
mermaidContentParser.use(swm_token, { inMermaid: true });
mermaidContentParser.use(swm_path, { inMermaid: true });
mermaidContentParser.use(swm_drop);
mermaidContentParser.core.ruler.enableOnly([
  'normalize',
  'block',
  'inline',
  'swm_token',
  'swm_path',
  'text_join',
  'drop_swm_inline',
]);
mermaidContentParser.inline.ruler.enableOnly(['text', 'swm_inline']);
mermaidContentParser.inline.ruler2.enableOnly([]);

function transformMermaid(state: StateCore) {
  for (let blkIdx = 0; blkIdx < state.tokens.length; blkIdx++) {
    if (state.tokens[blkIdx].type !== 'fence') {
      continue;
    }

    if (state.tokens[blkIdx].info === 'mermaid') {
      state.tokens[blkIdx].type = 'mermaid_open';
      state.tokens[blkIdx].nesting = 1;

      const originalMermaid = extractSwimmOriginalMermaidText(state.tokens[blkIdx].content);
      if (originalMermaid != null) {
        const tokens = mermaidContentParser.parseInline(originalMermaid, state.env);
        state.tokens.splice(blkIdx + 1, 0, ...tokens, new state.Token('mermaid_close', 'code', -1));
      } else {
        // Just pass it along as a plain text if there is no original Swimm Mermaid content
        const inlineToken = new state.Token('inline', '', 0);
        inlineToken.level = state.tokens[blkIdx].level + 1;
        inlineToken.map = state.tokens[blkIdx].map;
        inlineToken.content = state.tokens[blkIdx].content;
        const textToken = new state.Token('text', '', 0);
        textToken.content = state.tokens[blkIdx].content.replace(/\n$/, '');
        inlineToken.children = [textToken];
        state.tokens.splice(blkIdx + 1, 0, inlineToken, new state.Token('mermaid_close', 'code', -1));
      }
    }
  }
}

export default function mermaid(md: MarkdownIt) {
  md.core.ruler.push('mermaid', transformMermaid);

  md.renderer.rules.mermaid = (tokens, idx, options, env, slf) => {
    const token = tokens[idx];

    return (
      '<pre class="mermaid"' + slf.renderAttrs(token) + '>' + md.utils.escapeHtml(tokens[idx].content) + '</pre>\n'
    );
  };
}

const SWIMM_ORIGINAL_MERMAID_RE = /^%% Swimm:$/m;
const SWIMM_ORIGINAL_MERMAID_LINE_RE = /^%% (.*\n)/gm;

export function extractSwimmOriginalMermaidText(text: string): string | null {
  const lines = [];

  const match = text.match(SWIMM_ORIGINAL_MERMAID_RE);
  if (match != null) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pos = match.index! + match[0].length;

    for (const lineMatch of text.slice(pos).matchAll(SWIMM_ORIGINAL_MERMAID_LINE_RE)) {
      lines.push(lineMatch[1]);
    }

    return lines.join('').replace(/\n$/, '');
  }

  return null;
}
