<script setup lang="ts">
import { computed, useSlots } from 'vue';
import { type PopperPlacements } from '../../types';
import Popper from 'vue3-popper';

/*
 * TODO: Ideally we'll import the PopperProps type from ../../types
 * for props but there's a bug in this version of Vue that prevents
 * this. Should be fixed in version 3.3.
 */
const props = withDefaults(
  defineProps<{
    placement?: PopperPlacements;
    disableClickAway?: boolean;
    offsetSkid?: string;
    offsetDistance?: string;
    hover?: boolean;
    show?: boolean;
    disabled?: boolean;
    openDelay?: number | string;
    closeDelay?: number | string;
    zIndex?: number | string;
    arrow?: boolean;
    arrowPadding?: string;
    interactive?: boolean;
    locked?: boolean;
    content?: string;
    // eslint-disable-next-line vue/prop-name-casing
    'onOpen:popper'?: () => void;
    // eslint-disable-next-line vue/prop-name-casing
    'onClose:popper'?: () => void;
    fallbackWrapper?: string;
  }>(),
  {
    offsetSkid: undefined,
    offsetDistance: undefined,
    openDelay: undefined,
    closeDelay: undefined,
    zIndex: undefined,
    arrowPadding: undefined,
    content: undefined,
    'onOpen:popper': undefined,
    'onClose:popper': undefined,
    placement: 'top',
    arrow: true,
    hover: true,
    show: undefined,
    fallbackWrapper: 'div',
  }
);

const slots = useSlots();

const wrapperComponent = computed(() => {
  return props.content || slots.content ? Popper : props.fallbackWrapper;
});
</script>

<template>
  <component :is="wrapperComponent" class="tooltip" v-bind="props">
    <slot />
    <template #content>
      <div class="tooltip__content">
        <slot name="content">{{ content }}</slot>
      </div>
    </template>
  </component>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.tooltip {
  @include defaults;
}
</style>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
:root {
  --popper-theme-background-color: var(--color-bg-default);
  --popper-theme-background-color-hover: var(--color-bg-default);
  --popper-theme-text-color: var(--color-text-default);
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: var(--color-border-default);
  --popper-theme-border-radius: var(--space-xsmall);
  --popper-theme-padding: var(--space-xxsmall) var(--space-xsmall);
  --popper-theme-box-shadow: var(--shadow-medium);
}

[data-theme='dark'] {
  --popper-theme-background-color: var(--color-bg-default);
  --popper-theme-background-color-hover: var(--color-bg-default);
  --popper-theme-text-color: var(--color-text-default);
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: var(--color-border-default);
  --popper-theme-border-radius: var(--space-xsmall);
  --popper-theme-padding: var(--space-xxsmall) var(--space-xsmall);
  --popper-theme-box-shadow: var(--shadow-medium);
}

[data-theme='light'] {
  --popper-theme-background-color: var(--color-bg-default);
  --popper-theme-background-color-hover: var(--color-bg-default);
  --popper-theme-text-color: var(--color-text-default);
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: var(--color-border-default);
  --popper-theme-border-radius: var(--space-xsmall);
  --popper-theme-padding: var(--space-xxsmall) var(--space-xsmall);
  --popper-theme-box-shadow: var(--shadow-medium);
}

.tooltip {
  &__content {
    font-size: var(--font-size-small);
    user-select: none;
    overflow: hidden;
    white-space: pre-wrap;
  }
}
</style>
