<!--
- UserEmailSelector
- This component is a wrapper around selector
- it receives list of users {email, label}
- then you can select either
- user from list or enter email manually
- and update the v-modal with the selected email
-->

<template>
  <div class="user-email-selector-div">
    <BaseLabel html-for="user-email-selector" :required="required">{{ label }}</BaseLabel>
    <v-select
      id="user-email-selector"
      :placeholder="placeholder"
      :data-testid="`${testIdPrefix}-selector`"
      taggable
      :model-value="value"
      :options="users"
      :reduce="(user) => user.email"
      :clearable="false"
      :append-to-body="appendToBody"
      :create-option="(email) => ({ email, label: email })"
      :selectable="isValidEmail"
      @option:selected="handleInput"
    >
      <slot name="no-options"></slot>
      <template #open-indicator="{ attributes }">
        <Icon v-bind="attributes" class="open-indicator" name="arrow-down" />
      </template>
      <template #option="option">
        <div class="option-line" :data-testid="`${testIdPrefix}-option-${option.email}`">
          <SwAvatar :text="option.label" :tooltip="option.email" class="option-icon" />
          {{ option.label }}
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { UrlUtils } from '@swimm/shared';
import { BaseLabel, SwAvatar } from '@swimm/ui';

export default {
  components: { BaseLabel, SwAvatar },
  props: {
    value: {
      type: String,
      default: '',
    },
    appendToBody: {
      type: Boolean,
      required: false,
      default: true,
    },
    testIdPrefix: {
      type: String,
      default: 'selector-test-id',
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    placeholder: {
      type: String,
      default: 'Select user or enter email',
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    users: {
      type: Array,
      required: true,
      validator(usersArray) {
        for (const user of usersArray) {
          if (!('label' in user && 'email' in user)) {
            return false;
          }
        }
        return true;
      },
    },
  },
  emits: ['input'],
  methods: {
    handleInput(newValue) {
      this.$emit('input', newValue);
    },
    isValidEmail(user) {
      return UrlUtils.isValidEmail(user.email);
    },
  },
};
</script>

<style scoped>
.option-line {
  display: flex;
}

.option-icon {
  margin-right: 4px;
}

.vs__open-indicator {
  padding: 0;
  font-size: var(--fontsize-m);
}
</style>
<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style>
.vs__dropdown-option.vs__dropdown-option--disabled {
  color: var(--vs-state-disabled-color);
}

.v-select input::placeholder {
  font-family: var(--fontfamily-main);
}
</style>
