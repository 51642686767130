<template>
  <div class="container">
    <div class="headline-container">
      <div class="title">
        <Icon v-if="showBackButton" name="back-browsing" class="clickable" @click="$emit('back-clicked')" />
        <div class="headline subtitle-XL">{{ title }}</div>
      </div>
      <IconButton name="close" @click="close" />
    </div>
    <div class="content">
      <slot name="items" />
    </div>
  </div>
</template>

<script>
import { IconButton } from '@swimm/ui';

export default {
  components: { IconButton },
  props: {
    title: { type: String, default: '' },
    showBackButton: { type: Boolean, default: true },
  },
  emits: ['close', 'back-clicked'],
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped lang="postcss">
.content {
  overflow-y: auto;
  padding: 0 8px 8px;
  width: 304px;
  max-height: 454px;
}

.content::-webkit-scrollbar-track {
  background: var(--color-border-default-subtle);
}

.content::-webkit-scrollbar-thumb {
  background: var(--color-border-default);
}

.headline-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid var(--color-border-default-subtle);

  .title {
    display: flex;
    align-items: center;
  }
}

.container {
  position: fixed;
  bottom: 76px;
  right: 31px;
  z-index: 1;
  margin: 24px;
  border: 1px solid var(--color-border-default-subtle);
  border-radius: 8px;
  background-color: var(--color-bg);
  box-shadow: var(--box-shadow-small);
}

.headline {
  font-weight: 600;
}
</style>
