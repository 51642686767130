<script setup>
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { eventLogger, productEvents } from '@swimm/shared';
import { CALENDLY_DEMO_LINK } from '@/config';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { storeToRefs } from 'pinia';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/modules/core/stores/auth-store';

const { user } = storeToRefs(useAuthStore());
const store = useStore();
const route = useRoute();
const analytics = useAnalytics();
const { logEvent } = useSwimmEventLogs();

const shouldShow = computed(() => isWorkspaceAdmin.value);

const userUid = computed(() => user.value.uid);
const workspaceId = computed(() => route.params.workspaceId);

const isWorkspaceAdmin = computed(() => {
  return store.getters['database/db_isWorkspaceAdmin'](workspaceId.value, userUid.value);
});

function onClick() {
  window.open(CALENDLY_DEMO_LINK, '_blank');
  logEvent(eventLogger.SWIMM_EVENTS.USER_REQUESTED_DEMO);
  analytics.track(productEvents.REQUEST_A_MEETING_WITH_SALES_OR_CS, {
    Context: 'Global Support Icon',
  });
}
</script>

<template>
  <div
    v-if="shouldShow"
    @click="onClick"
    class="demo-avatar"
    v-tooltip="{ content: 'Book a 1:1 onboarding session', placement: 'left' }"
  >
    <img src="../assets/ricardo.png" alt="Book a 1:1 onboarding session" :width="32" />
  </div>
</template>

<style scoped lang="postcss">
.demo-avatar {
  border-radius: 50%;
  cursor: pointer;
  box-shadow: var(--box-shadow-small);
  background-color: var(--color-bg);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 50%;
    padding: 2px;
    box-sizing: border-box;
  }
}
</style>
