<script lang="ts" setup>
import ReviewAutosyncBanner from './ReviewAutosyncBanner.vue';
import TriggerAutosyncBanner from './TriggerAutosyncBanner.vue';
import { ref, watch } from 'vue';
const emptyStateText = "🎉 You're all set! All referenced code in this doc is up to date";

const props = defineProps<{
  reviewNumber: number;
  isEditMode: boolean;
  isDraftBroken?: boolean;
}>();

const emit = defineEmits(['sync-doc']);

const showAnimation = ref<boolean>(false);

watch(
  () => props.reviewNumber,
  (value, oldValue) => {
    if (value === 0 && oldValue > 0) {
      showAnimation.value = true;
    }
  }
);
</script>

<template>
  <ReviewAutosyncBanner v-if="reviewNumber > 0" :review-number="reviewNumber" :is-edit-mode="isEditMode" />
  <TriggerAutosyncBanner v-else-if="isDraftBroken" @sync-doc="emit('sync-doc')" />
  <Transition name="fade-in">
    <div v-if="reviewNumber === 0 && !isDraftBroken" data-testid="review-empty-state" class="review-empty-state body-S">
      <Transition appear type="animation" name="confetti">
        <div v-if="showAnimation" id="animation-box" data-testid="animation-box" />
      </Transition>
      {{ emptyStateText }}
    </div>
  </Transition>
</template>
<style scoped lang="postcss">
.review-empty-state {
  color: var(--text-color-secondary);
}

/* These classes are utilized by Transition */
.fade-in-enter-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

/* Based on: https://jsfiddle.net/elin/7m3bL/ */
.confetti-enter-active,
.confetti-leave-active {
  position: absolute;
  margin-left: 35%;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px #ffd16b, 248px -16.66667px #ef3c8a, 190px 16.33333px #813bbe,
    -113px -308.66667px #8c98ff, -109px -287.66667px #ffd16b, -50px -313.66667px #ef3c8a, 226px -31.66667px #813bbe,
    180px -351.66667px #8c98ff, -12px -338.66667px #ffd16b, 220px -388.66667px #ef3c8a, -69px -27.66667px #813bbe,
    -111px -339.66667px #8c98ff, 155px -237.66667px #ffd16b, -152px -380.66667px #ef3c8a, -50px -37.66667px #813bbe,
    -95px -175.66667px #8c98ff, -88px 10.33333px #ffd16b, 112px -309.66667px #ef3c8a, 69px -415.66667px #813bbe,
    168px -100.66667px #8c98ff, -244px 24.33333px #ffd16b, 97px -325.66667px #ef3c8a, -211px -182.66667px #813bbe,
    236px -126.66667px #8c98ff, 140px -196.66667px #ffd16b, 125px -175.66667px #ef3c8a, 118px -381.66667px #813bbe,
    144px -111.66667px #8c98ff, 36px -78.66667px #ffd16b, -63px -196.66667px #ef3c8a, -218px -227.66667px #813bbe,
    -134px -377.66667px #8c98ff, -36px -412.66667px #ffd16b, 209px -106.66667px #ef3c8a, 91px -278.66667px #813bbe,
    -22px -191.66667px #8c98ff, 139px -392.66667px #ffd16b, 56px -2.66667px #ef3c8a, -156px -276.66667px #813bbe,
    -163px -233.66667px #8c98ff, -238px -346.66667px #ffd16b, 62px -363.66667px #ef3c8a, 244px -170.66667px #813bbe,
    224px -142.66667px #8c98ff, 141px -208.66667px #ffd16b, 211px -285.66667px #ef3c8a, 181px -128.66667px #813bbe,
    90px -123.66667px #8c98ff, 189px 70.33333px #ffd16b, -18px -383.66667px #ef3c8a, 100px -6.66667px #813bbe;
  animation: 1.3s bang ease-out 1 backwards, 1.3s gravity ease-in 1 backwards;
}

@-webkit-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white;
  }
}
@-moz-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white;
  }
}
@-o-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white;
  }
}
@-ms-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white;
  }
}
@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
</style>
