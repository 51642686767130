import type { AnalyticsTrackOptions, AnalyticsTrackProperties } from '@/types/AnalyticsInterface';
import { type Repo, type SnippetData, productEvents } from '@swimm/shared';
import { type Ref, ref } from 'vue';
// import { debounce } from 'lodash-es';
// import { until } from '@vueuse/core';

export interface SuggestedHeader {
  header: string;
  added: boolean;
  dismissed: boolean;
  description: string;
  loading: boolean;
}
export interface AiContentSuggestionsResources {
  isAIGenerationEnabledForRepo(repoId: string): Promise<boolean>;
  getFileContent({
    repoId,
    filePath,
    revision,
  }: {
    repoId: string;
    filePath: string;
    revision: string;
  }): Promise<string | undefined>;
  trackEvent(eventName: string, properties: AnalyticsTrackProperties, options?: AnalyticsTrackOptions): void;
  editable: Ref<boolean>;
}

export interface SnippetsForAiContentSuggestions {
  file: string;
  lines: string[];
}

export interface AiContentSuggestionsService {
  docTitle: Ref<string | undefined>;
  headers: Ref<SuggestedHeader[]>;
  headersLoaded: Ref<boolean>;
  selectedHeader: Ref<SuggestedHeader | null>;
  isContentSuggestionShown: Ref<boolean | null>;
  contentSuggestionsGenerationFailed: Ref<boolean>;
  keepContentSuggestionsOpenOnNextUnload: Ref<boolean>;
  setContentSuggestionShown(show: boolean, byClick: boolean): void;
  clearContentSuggestions(): void;
  regenerateContentSuggestions(): void;
  updateContentSuggestionsSnippets(newSnippets: SnippetsForAiContentSuggestions[]): void;
  reportSuggestionsToggle(opened: boolean, clicked: boolean): void;
  selectHeader(header: SuggestedHeader): void;
  dismissHeader(header: SuggestedHeader): void;
  markHeaderAsAdded(header: SuggestedHeader): void;
  trackEvent(eventName: string, properties: AnalyticsTrackProperties, options?: AnalyticsTrackOptions): void;
  snippets: Ref<SnippetsForAiContentSuggestions[]>;
}

const SNIPPET_MARK_IN_HEADER_CONTENT = 'CODESNIPPET';

export function useAiContentSuggestions(
  workspaceId: Ref<string | null>,
  repoId: Ref<string | null>,
  branch: Ref<string | null>,
  docTitle: Ref<string | undefined>,
  repos: Ref<{ loading: boolean; repos: Repo[] }>,
  externalServices: AiContentSuggestionsResources
): AiContentSuggestionsService {
  const isContentSuggestionShown = ref<boolean | null>(null);
  const keepContentSuggestionsOpenOnNextUnload = ref<boolean>(false);
  const headers = ref<SuggestedHeader[]>([]);
  const headersLoaded = ref(false);
  const generatingHeadersAndContent = ref(false);
  const contentSuggestionsGenerationFailed = ref(false);
  const autoOpeningContentSuggestionsReported = ref(false);
  const snippets = ref<SnippetData[]>([]);
  const selectedHeader = ref<SuggestedHeader | null>(null);

  // TODO: Uncomment when bringing back the content suggestions.
  // Unit then this part is comment out so we won't read the readme for no reason.
  // const readmeContents = ref<string | null>(null);
  // const startedEditingWithEmptyTitle = !docTitle.value;

  // watchEffect(async () => {
  //   if (!repoId.value || !branch.value) {
  //     readmeContents.value = null;
  //     return;
  //   }
  //   try {
  //     readmeContents.value =
  //       (await externalServices.getFileContent({
  //         repoId: repoId.value,
  //         filePath: 'README.md',
  //         revision: branch.value,
  //       })) ?? null;
  //   } catch (err) {
  //     // No README.md in repo.
  //   }
  // });

  async function generateContentSuggestions(_reason: string) {
    // TODO: Uncomment when bringing back the content suggestions.
    // Unit then this part is comment out so we won't generate content for no reason.
    // if (!repoId.value || !workspaceId.value || !docTitle.value) {
    //   return;
    // }
    // const isGenerativeAiEnabled = await externalServices.isAIGenerationEnabledForRepo(repoId.value);
    // if (!isGenerativeAiEnabled) {
    //   return;
    // }
    // contentSuggestionsGenerationFailed.value = false;
    // await until(() => repos.value.loading).not.toBeTruthy();
    // const repo = repos.value.repos?.find((repo) => repo.id === repoId.value);
    // const repoName = repo?.name ?? '';
    // generatingHeadersAndContent.value = true;
    // const generationStartTime = Date.now();
    // const response = await externalServices.generateHeaderSuggestions?.({
    //   repoId: repoId.value,
    //   repoName,
    //   workspaceId: workspaceId.value,
    //   title: docTitle.value,
    //   readmeContents: readmeContents.value ?? undefined,
    //   snippets: snippets.value,
    // });
    // const generationTimeTaken = Date.now() - generationStartTime;
    // if (response?.status !== 'success' || !response?.headers) {
    //   contentSuggestionsGenerationFailed.value = true;
    //   generatingHeadersAndContent.value = false;
    //   externalServices.trackEvent(productEvents.ERROR_WHILE_GENERATING_CONTENT_SUGGESTIONS, {
    //     'Error Code': response?.code.toString(),
    //     Context: 'Content Suggestions',
    //   });
    //   return;
    // }
    // if (isContentSuggestionShown.value === null && response.headers.length && startedEditingWithEmptyTitle) {
    //   // Open the content suggestions if feature enabled and user is editing a new doc after headers are ready.
    //   isContentSuggestionShown.value = isGenerativeAiEnabled;
    // }
    // headers.value = response.headers.map((header) => {
    //   return {
    //     header,
    //     added: false,
    //     dismissed: false,
    //     description: '',
    //     expanded: false,
    //     loading: true,
    //   };
    // });
    // headersLoaded.value = true;
    // externalServices.trackEvent(productEvents.FINISHED_GENERATING_HEADERS, {
    //   'Generated Header Count': headers.value.length,
    //   'Generation Time MS': generationTimeTaken,
    //   'Generation Reason': reason,
    //   Context: 'Content Suggestions',
    // });
    // await Promise.allSettled(
    //   headers.value.map(async (header) => {
    //     if (!repoId.value || !workspaceId.value || !docTitle.value) {
    //       return;
    //     }
    //     const headerTemplateRequest = {
    //       repoId: repoId.value,
    //       repoName,
    //       workspaceId: workspaceId.value,
    //       title: docTitle.value,
    //       header: header.header,
    //       docTypeId: response.docTypeId,
    //       readmeContents: readmeContents.value ?? undefined,
    //       snippets: snippets.value,
    //     };
    //     const headerTemplateResponse = await externalServices.generateHeaderContent(headerTemplateRequest);
    //     if (headerTemplateResponse && headerTemplateResponse.status === 'success' && headerTemplateResponse?.content) {
    //       header.description = headerTemplateResponse.content;
    //     }
    //     header.loading = false;
    //   })
    // );
    // generatingHeadersAndContent.value = false;
  }

  function setContentSuggestionShown(show: boolean, byClick: boolean) {
    if (show && !headersLoaded.value && !generatingHeadersAndContent.value) {
      // Opened the content suggestions but the generation didn't start yet - trigger it.
      // Happens when the user enabled the generative ai on the repo while editing the doc.
      generateContentSuggestions('Title Changed');
    }
    isContentSuggestionShown.value = show;
    reportSuggestionsToggle(show, byClick);
  }

  function clearContentSuggestions() {
    docTitle.value = '';
    headers.value = [];
    headersLoaded.value = false;
    isContentSuggestionShown.value = null;
    generatingHeadersAndContent.value = false;
  }

  function regenerateContentSuggestions() {
    headers.value = [];
    headersLoaded.value = false;
    generateContentSuggestions('Regenerate');
  }

  function reportSuggestionsToggle(opened: boolean, clicked: boolean) {
    if (opened) {
      if (!clicked) {
        if (autoOpeningContentSuggestionsReported.value) {
          // Report not clicked suggestion opening only once per editor session.
          return;
        }
        autoOpeningContentSuggestionsReported.value = true;
      }
      externalServices.trackEvent(productEvents.AI_SUGGESTIONS_DROPDOWN_OPENED, {
        Reason: clicked ? 'User Click' : 'Suggestions Generated',
        'Are Headers Generated': headersLoaded.value,
        'Header Count': headers.value.length,
        'Generated Section Count': headers.value.filter((header) => !!header.description).length,
        Context: 'Content Suggestions',
      });
    } else {
      externalServices.trackEvent(productEvents.AI_SUGGESTIONS_DROPDOWN_CLOSED, {
        Context: 'Content Suggestions',
      });
    }
  }

  // TODO: Uncomment when bringing back the content suggestions.
  // Unit then this part is comment out so we won't watch the doc title for no reason.
  // const debouncedGenerateContentSuggestions = debounce(() => {
  //   generateContentSuggestions('Title Changed');
  // }, 500);

  // watch(
  //   () => [docTitle.value, externalServices.editable.value],
  //   async () => {
  //     if (docTitle.value && externalServices.editable.value) {
  //       debouncedGenerateContentSuggestions();
  //     } else if (docTitle.value === null) {
  //       clearContentSuggestions();
  //     }
  //   },
  //   { immediate: true }
  // );

  function updateContentSuggestionsSnippets(newSnippets: SnippetsForAiContentSuggestions[]) {
    const previusSnippetsCount = snippets.value.length;
    snippets.value = newSnippets;
    if (snippets.value.length > previusSnippetsCount) {
      generateContentSuggestions('Added Snippet');
    }
  }

  function selectHeader(header: SuggestedHeader) {
    selectedHeader.value = header;
    externalServices.trackEvent(productEvents.EXPANDED_SUGGESTED_SECTION_HEADER, {
      'Is Section Generated': !!selectedHeader.value?.description,
      Context: 'Content Suggestions',
    });
  }

  function getNumberOfSnippetsInHeader(header: SuggestedHeader) {
    if (header?.description) {
      return header.description.split(SNIPPET_MARK_IN_HEADER_CONTENT).length - 1;
    }
    return 0;
  }

  function dismissHeader(header: SuggestedHeader) {
    header.dismissed = true;
    selectedHeader.value = null;
    externalServices.trackEvent(productEvents.DISMISSED_SUGGESTED_SECTION, {
      'Section Length': header.description.length,
      'Snippet Placeholder Count': getNumberOfSnippetsInHeader(header),
      Context: 'Content Suggestions',
    });
  }

  function markHeaderAsAdded(header: SuggestedHeader) {
    // We set this so that the popup doesn't close when it is unloaded when the content is inserted.
    keepContentSuggestionsOpenOnNextUnload.value = true;
    header.added = true;
    selectedHeader.value = null;
    externalServices.trackEvent(productEvents.ADDED_SUGGESTED_SECTION_TO_DOC, {
      'Section Length': header.description.length,
      'Snippet Placeholder Count': getNumberOfSnippetsInHeader(header),
      Context: 'Content Suggestions',
    });
  }

  function trackEvent(eventName: string, properties: AnalyticsTrackProperties, options?: AnalyticsTrackOptions) {
    externalServices.trackEvent(eventName, properties, options);
  }

  return {
    docTitle,
    headers,
    headersLoaded,
    selectedHeader,
    isContentSuggestionShown,
    setContentSuggestionShown,
    clearContentSuggestions,
    regenerateContentSuggestions,
    contentSuggestionsGenerationFailed,
    updateContentSuggestionsSnippets,
    reportSuggestionsToggle,
    keepContentSuggestionsOpenOnNextUnload,
    selectHeader,
    dismissHeader,
    markHeaderAsAdded,
    trackEvent,
    snippets,
  };
}
