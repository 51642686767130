<template>
  <SwModal heading="Add existing documentation" :padded="false" :show-modal="showModal" @close="handleClose">
    <div class="modal-content">
      <div class="playlist-units">
        <header>
          <span class="search-container">
            <TextField v-model="search" placeholder="Search by name" focus-first><Icon name="search" /> </TextField>
          </span>
        </header>
        <div class="dropdown-filters">
          <div class="filter-heading">
            <Icon name="filter" class="filter-icon" />
            <div class="filter-title">Filters:</div>
          </div>
          <DropdownFilter name="Type" :is-selected="Boolean(selectedTypes.length)">
            <MultiCheckbox
              :show-search="false"
              :options="typeOptions"
              :model-value="selectedTypes"
              :display="(type) => type.label"
              :identity="(type) => type.key"
              @update:model-value="onTypeChange"
            />
          </DropdownFilter>
          <MultiRepoSelector
            :repos="reposMetadatas"
            :selected-repo-ids="selectedRepoIds"
            :first-repo-id="$route.params.repoId"
            @selection-changed="onRepoChange"
          />
        </div>
        <div class="content">
          <ResourceSelectorListView
            v-for="resource in searchedResources"
            :key="resource.id"
            :resource="resource"
            :sequence="sequence"
            :edit-sequence="editSequence"
            :repo="getResourceRepo(resource)"
          />
          <SwText v-if="!searchedResources.length" variant="body-L" class="empty-state">No documents found</SwText>
        </div>
        <div class="footer">
          <SwText variant="body-XS" class="selected-items-text">{{ selectedItemsText }}</SwText>
          <Action :disabled="sequence.length === 0" @click="done">Add to playlist</Action>
        </div>
      </div>
    </div>
  </SwModal>
</template>

<script>
import { useFiltersStore } from '@/modules/core/filters-row/useFiltersStore';
import DropdownFilter from '@/modules/core/filters-row/DropdownFilter.vue';
import { MultiCheckbox, SwModal, TextField } from '@swimm/ui';
import ResourceSelectorListView from '@/common/components/organisms/ResourceSelectorListView.vue';
import { isSwmDoc, isSwmExercise } from '@swimm/shared';
import { MultiRepoSelector } from '@swimm/editor';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { storeToRefs } from 'pinia';

export default {
  components: {
    MultiCheckbox,
    DropdownFilter,
    TextField,
    ResourceSelectorListView,
    SwModal,
    MultiRepoSelector,
  },
  props: {
    showModal: { type: Boolean, default: false },
    swimms: { type: Array, required: true },
    alreadySelectedSwimms: { type: Array, required: true },
    showLinks: { type: Boolean, default: true },
    repos: { type: Array, default: () => null },
  },
  emits: ['done-selection', 'close'],
  setup() {
    const filtersStore = useFiltersStore();
    const workspaceStore = useWorkspaceStore();
    const { favoriteRepoIds, recentRepoIds } = storeToRefs(workspaceStore);
    return { favoriteRepoIds, filtersStore, recentRepoIds };
  },
  data() {
    return {
      search: '',
      sequence: [],
      editSequence: { add: this.addToSequence, remove: this.removeFromSequence },
      repoProviderFilter: '',
      selectedTypes: [],
      selectedRepoIds: [this.$route.params.repoId],
    };
  },
  computed: {
    isPlaylistHasPreviouslySelectedItems() {
      return this.alreadySelectedSwimms.length > 0;
    },
    isNewStepsSelectedOnModal() {
      return this.sequence.length > 0;
    },
    isPlaylistHasSteps() {
      return this.isNewStepsSelectedOnModal || this.isPlaylistHasPreviouslySelectedItems;
    },
    typeOptionsObjects() {
      return this.hasExercises ? this.filtersStore.TypesIncludingExercisesOptions : this.filtersStore.TypeOptions;
    },
    typeOptions() {
      return Object.values(this.typeOptionsObjects);
    },
    alreadySelectedSwimmIds() {
      return this.alreadySelectedSwimms.map((swimm) => swimm.id);
    },
    unselectedSwimms() {
      return this.swimms.filter((swimm) => {
        const itemRepoSelected = this.isResourceInSelectedRepoFilter(swimm.repoId);
        const itemAlreadySelected = this.alreadySelectedSwimmIds.includes(swimm.id);
        return itemRepoSelected && !itemAlreadySelected;
      });
    },
    searchedSwimms() {
      return this.unselectedSwimms.filter(this.filterByText).filter(this.filterByType).filter(this.filterByRepo);
    },
    searchedResources() {
      return this.searchedSwimms.filter(
        (swimm) =>
          swimm.type === 'playlist' ||
          (swimm.type === 'unit' && (isSwmExercise({ swm: swimm }) || isSwmDoc({ swm: swimm })))
      );
    },
    hasExercises() {
      return this.swimms.some((swimm) => swimm.type === 'unit' && isSwmExercise({ swm: swimm }));
    },
    selectedItemsText() {
      if (this.sequence.length === 0) {
        return '';
      }
      return `${this.sequence.length} ${this.sequence.length === 1 ? 'item' : 'items'} selected`;
    },
    reposMetadatas() {
      return this.repos.map((repo) => ({
        ...repo.metadata,
        isFavourite: this.favoriteRepoIds.includes(repo.metadata.id),
        isRecent: this.recentRepoIds.includes(repo.metadata.id),
      }));
    },
  },
  created() {
    this.selectedTypes = Object.entries(this.typeOptionsObjects).map(([_, typeOptionsObject]) => typeOptionsObject);
    if (this.isPlaylistHasPreviouslySelectedItems) {
      this.setRepoProviderFilterFromExistingSteps();
    }
  },
  methods: {
    addToSequence(id) {
      if (!this.isPlaylistHasSteps) {
        this.repoProviderFilter = this.getResource(id).repoProvider;
      }
      this.sequence.push(id);
    },
    removeFromSequence(id) {
      const index = this.sequence.indexOf(id);
      if (index > -1) {
        this.sequence.splice(index, 1);
      }
      if (this.isPlaylistHasSteps) {
        this.repoProviderFilter = '';
      }
    },
    done() {
      const items = this.sequence.map((id) => this.getResource(id));
      this.$emit('done-selection', items);
      this.resetState();
    },
    isResourceInSelectedRepoFilter(resourceRepoId) {
      if (!this.selectedRepoIds.length) {
        return true;
      }
      return this.selectedRepoIds.includes(resourceRepoId);
    },
    getResource(resourceId) {
      return this.swimms.find((swimm) => swimm.id === resourceId);
    },
    getResourceRepo(resource) {
      if (this.repos) {
        return this.repos.find((repo) => repo.metadata.id === resource.repoId);
      }
      return null;
    },
    setRepoProviderFilterFromExistingSteps() {
      const resources = this.alreadySelectedSwimmIds.map(this.getResource).filter(Boolean);
      this.repoProviderFilter = resources[0] ? resources[0].repoProvider : '';
    },
    filterByText(swimm) {
      return swimm.name && swimm.name.toLowerCase().includes(this.search.toLowerCase());
    },
    filterByType(swimm) {
      if (!this.selectedTypes.length) {
        return true;
      }

      const { DOC, EXERCISE, PLAYLIST } = this.filtersStore.TypesIncludingExercisesOptions;
      let type = '';

      if (swimm.type === 'unit') {
        if (isSwmDoc({ swm: swimm })) {
          type = DOC.key;
        }
        if (isSwmExercise({ swm: swimm })) {
          type = EXERCISE.key;
        }
      }
      if (swimm.type === 'playlist') {
        type = PLAYLIST.key;
      }

      return this.selectedTypes.map((type) => type.key).includes(type);
    },
    filterByRepo(swimm) {
      if (!this.selectedRepoIds.length) {
        return true;
      }
      return this.selectedRepoIds.includes(swimm.repoId);
    },
    onTypeChange(types) {
      this.selectedTypes = types;
    },
    onRepoChange(repoIds) {
      this.selectedRepoIds = repoIds;
    },
    resetState() {
      this.search = '';
      this.sequence = [];
      this.repoProviderFilter = '';
      this.selectedTypes = [];
      this.selectedRepoIds = [this.$route.params.repoId];
    },
    handleClose() {
      this.resetState();
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="postcss">
.modal-content {
  width: 472px;
  height: 496px;
}

.playlist-units {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  height: 100%;
  max-height: inherit;
  flex-direction: column;
  box-sizing: border-box;
}

.playlist-units header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-container {
  width: 100%;
  margin-bottom: 16px;
}

.dropdown-filters {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: var(--space-xs);

  .filter-heading {
    display: flex;
    align-items: center;
    color: var(--text-color-secondary);

    .filter-icon {
      font-size: var(--headline2);
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .selected-items-text {
    color: var(--high-tide);
  }
}

.content {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
}

.empty-state {
  margin: 16px 0 0 16px;
  color: var(--high-tide);
}
</style>
