<template>
  <Ribbon
    v-if="isVerifying"
    icon-name="sync"
    :spinning-icon="true"
    data-testid="review-autosync-ribbon"
    ribbon-description="Verifying token status..."
    mode="side-bar"
  />
  <template v-else>
    <ReviewAutosyncBannerWrapper :review-number="needsReviewElements.length" :is-edit-mode="isEditMode" />
    <Transition name="fade-in">
      <div
        class="sections-container"
        data-testid="review-autosync-sections"
        v-if="shouldShowReviewAutosyncNodes && isSidebarOpen"
      >
        <TransitionGroup name="fade">
          <ReviewAutosyncSection3
            v-if="autosyncedItems.length"
            :section-length="autosyncedItems.length"
            :is-autosyncable="true"
            :needs-review-items="autosyncedItems"
            @accept-all="() => emit('accept-all')"
            @accept-autosync="(nodeItem) => emit('accept-autosync', nodeItem)"
            @node-clicked="(pos) => emit('node-clicked', pos)"
          />
          <ReviewAutosyncSection3
            v-if="outdatedItems.length"
            :section-length="outdatedItems.length"
            :is-autosyncable="false"
            :needs-review-items="outdatedItems"
            @delete-outdated="(nodeItem) => emit('delete-outdated', nodeItem)"
            @node-clicked="(pos) => emit('node-clicked', pos)"
          />
        </TransitionGroup>
      </div>
    </Transition>
  </template>
</template>
<script setup lang="ts">
import Ribbon from '@/common/components/atoms/Ribbon.vue';
import ReviewAutosyncSection3 from './ReviewAutosyncSection3.vue';
import ReviewAutosyncBannerWrapper from './ReviewAutosyncBannerWrapper.vue';
import { computed } from 'vue';
import { ApplicabilityStatus } from '@swimm/shared';
import { type NeedReviewItem } from '@/modules/doc-sidebar/types';

interface ReviewAutosyncProps {
  isVerifying: boolean;
  isSidebarOpen: boolean;
  isEditMode: boolean;
  needsReviewElements: NeedReviewItem[];
}

const props = withDefaults(defineProps<ReviewAutosyncProps>(), {
  isEditMode: false,
  isVerifying: false,
});

const emit = defineEmits(['accept-all', 'delete-outdated', 'accept-autosync', 'node-clicked']);

const shouldShowReviewAutosyncNodes = computed(() => {
  if (!props.isEditMode) {
    return false;
  }

  return !!autosyncedItems.value.length || !!outdatedItems.value.length;
});

const autosyncedItems = computed(() => {
  return props.needsReviewElements.filter((item) => item.applicability === ApplicabilityStatus.Autosyncable);
});

const outdatedItems = computed(() => {
  return props.needsReviewElements.filter((item) => item.applicability === ApplicabilityStatus.Outdated);
});
</script>

<style scoped lang="postcss">
.sections-container {
  display: flex;
  flex-direction: column;
  padding-top: var(--space-sm);
  gap: var(--space-base);
}

/* These classes are utilized by Transition */
.fade-in-enter-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
</style>
