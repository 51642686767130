import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core';

const PRESERVE = new Set(['<', '>', '&', '"', "'"]);

function preserveSpecialRule(state: StateCore) {
  const blockTokens = state.tokens;

  for (let j = 0, l = blockTokens.length; j < l; j++) {
    if (blockTokens[j].type !== 'inline') {
      continue;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const tokens = blockTokens[j].children!;
    const max = tokens.length;

    for (let curr = 0; curr < max; curr++) {
      // Convert `text_special` tokens to be preserved to unescaped `text` tokens
      if (tokens[curr].type === 'text_special' && PRESERVE.has(tokens[curr].content)) {
        tokens[curr].type = 'text';
        tokens[curr].content = tokens[curr].markup;
      }
    }
  }
}

export default function preserve_special(md: MarkdownIt): void {
  md.core.ruler.before('text_join', 'preserve_special', preserveSpecialRule);
}
