<template>
  <div v-if="contributors && contributors.length > 0" class="resource-contributors">
    <VMenu theme="menu-no-arrow">
      <Icon name="team" class="views" />
      <span class="resource-contributors-title" data-testid="resource-contributors-title">{{ title }}</span>
      <template #popper><UsersPopup :users="sortedContributors" /></template>
    </VMenu>
  </div>
</template>
<script>
import UsersPopup from '@/common/components/organisms/UsersPopup.vue';
import { getCreatorAndOthers } from '@/modules/doc-contributions/services/doc-contributors-utils';

export default {
  components: {
    UsersPopup,
  },
  props: {
    contributors: {
      type: Array,
      required: true,
    },
  },
  computed: {
    creatorAndOthers() {
      return getCreatorAndOthers(this.contributors);
    },
    sortedContributors() {
      return getCreatorAndOthers(this.contributors).creatorAndOthersArray;
    },
    creator() {
      return this.creatorAndOthers.creator;
    },
    others() {
      return this.creatorAndOthers.others;
    },
    title() {
      return this.creatorAndOthers.title;
    },
  },
};
</script>

<style scoped>
.resource-contributors {
  display: flex;
  align-items: center;
  gap: var(--space-base);
}

.views {
  position: relative;
  bottom: 1px;
  font-size: var(--fontsize-ml);
}
</style>
