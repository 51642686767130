<script setup lang="ts">
import { ref } from 'vue';
import PluginLink from './PluginLink.vue';
import UserSettingsModal from '@/modules/user-settings/modals/UserSettingsModal.vue';
import { productEvents, userSettingsTabs } from '@swimm/shared';
import { useAnalytics } from '@/common/composables/useAnalytics';

const analytics = useAnalytics();

const showUserSettingsModal = ref(false);
function openSettings() {
  showUserSettingsModal.value = true;
  analytics.track(productEvents.CLICKED_IDE_INSTALL_CTA, { Context: 'Doc Footer' });
}
</script>

<template>
  <UserSettingsModal
    :show="showUserSettingsModal"
    origin="Edit Doc Plugins"
    @close="showUserSettingsModal = false"
    :initial-tab-code="userSettingsTabs.INTEGRATIONS"
  />

  <PluginLink kind="VSCode" @open-settings="openSettings" />
  <PluginLink kind="JetBrains" @open-settings="openSettings" />
</template>
