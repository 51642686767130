import { Extension, type NodeWithPos } from '@tiptap/core';
import { getParentNodeForPosition } from '@/components/common/tiptapUtils';
import { useNotificationsStore } from '@/modules/notifications';
import type { Editor as CoreEditor } from '@tiptap/core';
import type { Editor } from '@tiptap/vue-3';
import type { Range } from '@tiptap/core';
import { useEditDocStateStore } from '@/stores/nodeViewsStore';
import { storeToRefs } from 'pinia';
import { NodeTypes } from '@/types/ReviewAutosyncTypes';
import { generateCodeNode } from '@/components/common/tiptapUtils';
import { getLogger } from '@swimm/shared';
import type { Attrs } from '@tiptap/pm/model';

const logger = getLogger(__modulename);

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    swimmCustomCommands: {
      callSlashCommand: (commandName?: string) => ReturnType;
      batchUpdateNodes: (newAttrs: Attrs, nodes: NodeWithPos[]) => ReturnType;
      batchDeleteNodes: (filteredNodesById: NodeWithPos[]) => ReturnType;
      selectCellContent: (depth: number) => ReturnType;
      notifyFailedPaste: (pasteType: string) => void;
      pasteWithLoader: (
        editor: Editor | CoreEditor,
        pasteHandler: (editor: Editor | CoreEditor) => Promise<void>,
        isMarkdown?: boolean
      ) => void;
    };
  }
}

export const SwimmCustomCommands = Extension.create({
  name: 'SwimmCustomCommands',
  addCommands() {
    return {
      callSlashCommand:
        (commandName?) =>
        ({ commands, chain }) => {
          if (commandName) {
            commands.insertContent(`/${commandName} `);
            return true;
          }
          chain().insertContent('/').focus().run();
          return true;
        },

      batchUpdateNodes:
        (newAttrs: Attrs, nodes: NodeWithPos[]) =>
        ({ chain }) => {
          chain()
            .forEach(nodes, (node: NodeWithPos, { tr }) => {
              const mappedPos = tr.mapping.map(node.pos);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              tr.setNodeMarkup(mappedPos, node.node.type, newAttrs); // tiptap's Foreach expect a boolean for some reason.
              return true;
            })
            .run();
          return true;
        },

      batchDeleteNodes:
        (filteredNodesById: NodeWithPos[]) =>
        ({ chain, editor }) => {
          chain()
            .forEach(filteredNodesById, (node: NodeWithPos, { tr }) => {
              const mappedPosition: number = tr.mapping.map(node.pos); // to recalculate the position;

              const range: Range = { from: mappedPosition, to: mappedPosition + node.node.nodeSize };

              if (node.node.type.name === NodeTypes.GENERIC_TEXT) {
                // Creating a inline code node that has the same text as our generic text one
                const textNode = generateCodeNode(node.node.attrs.text, editor);
                if (textNode) {
                  tr.replaceRangeWith(range.from, range.to, textNode);
                }
                // Replacing the new node we created with the generic text node we wanted to delete
              } else {
                tr.deleteRange(range.from, range.to);
              } // removing the pos by asking it to delete the range from the mappedNodePosition to the mapped position with the node size;

              return true;
            })
            .run();
          return true;
        },

      selectCellContent:
        (depth) =>
        ({ view, commands }) => {
          const position = view.state.selection.$head.pos;
          const parentNode = getParentNodeForPosition(position, view.state, depth);

          const contentSize = parentNode.content.size;
          const startPosition = view.state.tr.doc.resolve(position).start(depth);

          /* when the parentNode has this set to false it means the node has 1 more position point
          for the node html itself, meaning selecting all without deducing this position point
          will select the html as well (for example in hunk comments it will select the snippet lines) */
          const inlineContentNumericalHold = view.state.doc.resolve(position).node(depth).inlineContent ? 0 : 1;

          commands.setTextSelection({
            from: startPosition,
            to: startPosition + contentSize - inlineContentNumericalHold,
          });
          return true;
        },
      notifyFailedPaste: (pasteType: string) => () => {
        const { addNotification } = useNotificationsStore();
        addNotification(`Unable to paste ${pasteType} text due to browser settings. Please enable this feature.`, {
          icon: 'browser-settings',
          autoClose: false,
          closeButtonText: 'Dismiss',
          link: {
            url: 'https://docs.swimm.io/support',
            text: 'Learn more',
          },
        });
      },
      pasteWithLoader:
        (
          editor: Editor | CoreEditor,
          pasteHandler: (editor: Editor | CoreEditor) => Promise<void>,
          isMarkdown = false
        ) =>
        async () => {
          const editDocStateStore = useEditDocStateStore();
          const { isPasting } = storeToRefs(editDocStateStore);

          if (navigator?.clipboard?.readText) {
            // Decide if loader should be shown (large paste)
            try {
              const text = await navigator.clipboard.readText();
              isPasting.value = shouldShowPasteLoader(text, isMarkdown);
            } catch (err) {
              logger.warn(`Could not read clipboard text ${err}`);
            } finally {
              // Forcing context switching for the loader to render because the pasteHandler is blocking
              setTimeout(async () => {
                await pasteHandler(editor);
                isPasting.value = false;
              }, 100);
            }
          }
        },
    };
  },
});

function shouldShowPasteLoader(text: string, isMarkdown: boolean) {
  const CHAR_THRESHOLD = 100000;
  const LINE_THRESHOLD = 250;
  const TABLE_THRESHOLD = 150;

  // Take into account new lines and tables since they take longer to paste
  const countNewLines = (text.match(/\n/g) || []).length;
  const countPipes = (text.match(/\|/g) || []).length;

  return (
    text.length > CHAR_THRESHOLD ||
    (isMarkdown && countPipes > TABLE_THRESHOLD) ||
    (!isMarkdown && countNewLines > LINE_THRESHOLD)
  );
}
