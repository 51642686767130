<script setup lang="ts">
import { Ref, computed, onBeforeUnmount, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { CloudDocData, productEvents } from '@swimm/shared';
import { useNavigate } from '@/common/composables/navigate';
import ShareButton from '@/common/components/TopBar/ShareButton.vue';
import TopBar from '@/common/components/TopBar/TopBar.vue';
import TopMenuLayout from '@/common/layouts/TopMenuLayout.vue';
import useSharingInternally from '@/modules/cloud-docs/composables/sharing-internally';
import EditorPane from '@/modules/editor3/components/EditorPane.vue';
import { useInitData } from '@/common/composables/initData';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import UserDialog from '@/common/components/modals/UserDialog.vue';
import ContentErrorState from '@/common/components/organisms/unavailable-doc-states/ContentErrorState.vue';
import { useRepoDocsStore } from '@/modules/core/stores/repo-docs';
import UserProfile from '@/common/components/organisms/UserProfile.vue';

const router = useRouter();
const { getRepoPath } = useNavigate();

const { getSharedDoc, fetchSharedDocOriginalDoc } = useSharingInternally();
const { setWorkspaceData } = useInitData();
const { providerDisplayName, provider, enterpriseGitUrl } = storeToRefs(useWorkspaceStore());
const gitAuthorizationStore = useGitAuthorizationStore();
const { isCurrentWorkspaceAuthorized } = storeToRefs(gitAuthorizationStore);
const { authorizeProviderWithGit } = gitAuthorizationStore;
const { currentDoc } = storeToRefs(useRepoDocsStore());

const analytics = useAnalytics();

const props = defineProps({
  workspaceId: { type: String, required: true },
  sharedDocId: { type: String, required: true },
  embedded: { type: Boolean },
});

const sharedDocData: Ref<CloudDocData> = ref();
const loading = ref(false);

async function initSharedDoc() {
  if (!props.sharedDocId || !props.workspaceId) {
    return;
  }
  loading.value = true;
  // without this, we get race condition on the repos in the EditorPane
  // this also loads all the shared docs
  await setWorkspaceData(props.workspaceId);
  // we need this for the share button
  sharedDocData.value = getSharedDoc(props.workspaceId, props.sharedDocId);
  // set the currentDoc, so we get correct breadcrumbs even if we fail to read the swmd later
  // or has other errors
  currentDoc.value = sharedDocData.value
    ? { title: sharedDocData.value.name, id: props.sharedDocId }
    : { title: 'Unavailable', id: props.sharedDocId };

  // we must fetch the orginal doc for the share button to work correctly
  // this can be removed if we change the share button logic
  await fetchSharedDocOriginalDoc(props.workspaceId, props.sharedDocId);
  loading.value = false;
}

watch(() => props.workspaceId, initSharedDoc);
watch(() => props.sharedDocId, initSharedDoc);

initSharedDoc();

onBeforeUnmount(() => {
  currentDoc.value = null;
});

const repoId = computed(() => sharedDocData.value?.export_info?.original_repo_id);
const unitId = computed(() => sharedDocData.value?.export_info?.original_doc_id);
const branch = computed(() => sharedDocData.value?.export_info?.original_branch);

const authorizeGitDialog = ref(null);

async function editDocClicked() {
  if (!repoId.value || !unitId.value || !branch.value) {
    return;
  }
  analytics.track(productEvents.CLICKED_EDIT_DOC_IN_SHARED_DOC, {
    'Workspace ID': props.workspaceId,
    'Shared Doc ID': props.sharedDocId,
    Context: 'Shared Docs',
    'Is Authorized': isCurrentWorkspaceAuthorized.value,
  });

  const docUrl = `${getRepoPath(repoId.value, branch.value)}/docs/${unitId.value}/edit`;
  if (isCurrentWorkspaceAuthorized.value) {
    await router.push(docUrl);
    return;
  }

  const userSelection = await authorizeGitDialog.value.showDialog();
  if (!userSelection.confirm) {
    return;
  }

  analytics.track(productEvents.CLICKED_EDIT_DOC_AUTHORIZE_IN_SHARED_DOC, {
    'Workspace ID': props.workspaceId,
    'Shared Doc ID': props.sharedDocId,
    Context: 'Shared Docs',
  });

  await authorizeProviderWithGit({
    provider: provider.value,
    gitUrl: enterpriseGitUrl.value,
    origin: 'Shared docs page',
  });
  if (isCurrentWorkspaceAuthorized.value) {
    await router.push(docUrl);
  }
}
</script>

<template>
  <TopMenuLayout
    :key="sharedDocId"
    :should-open-keyboard-shortcut-on-start="false"
    :should-lift-bottom-icons="false"
    :show-knowledge-icon="!embedded"
    :loading="loading"
  >
    <template #topBar>
      <TopBar :show-workspace-breadcrumb="false" :hide-breadcrumbs="embedded">
        <template v-if="embedded">
          <UserProfile :embedded="embedded" />
        </template>
        <template v-else>
          <ShareButton entity="SHARED_DOC" :repo-id="repoId" :entity-id="unitId" />
          <div
            v-if="repoId && unitId && branch"
            class="edit-doc-button"
            data-testid="edit-doc-button"
            @click="editDocClicked"
          >
            Edit doc
          </div>
        </template>
      </TopBar>
    </template>

    <template #content>
      <EditorPane
        v-if="repoId && unitId && branch"
        ref="pane"
        :editable="false"
        :embedded="embedded"
        :workspace-id="workspaceId"
        :unit-id="unitId"
        :repo-id="repoId"
        :branch="branch"
        :should-increment-view-count="false"
        :block-editing="true"
        :shared-doc-id="sharedDocId"
        @navigate-to-edit="editDocClicked"
      />
      <ContentErrorState v-else type="document" swimm-status="Shared doc error" repo-id="">
        <router-link :to="`/workspaces/${workspaceId}/shared-docs`" class="back-button">
          <Action>Back to Shared docs</Action>
        </router-link>
      </ContentErrorState>
    </template>
  </TopMenuLayout>
  <UserDialog
    ref="authorizeGitDialog"
    :title="`Authorize ${providerDisplayName} to continue`"
    :confirm-text="`Authorize ${providerDisplayName}`"
  >
    <SwText component="p" variant="body-L">
      To edit, you’ll need to authorize {{ providerDisplayName }} with Swimm. Make sure you have
      <strong>read and write</strong> permissions to your team’s {{ providerDisplayName }} repo, otherwise ask someone
      with {{ providerDisplayName }} access to make edits.
    </SwText>
  </UserDialog>
</template>

<style scoped lang="postcss">
.edit-doc-button {
  cursor: pointer;
  padding: var(--space-base);
  border-radius: 4px;

  &:hover {
    background-color: var(--color-hover);
  }
}
</style>
