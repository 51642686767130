import {
  ApplicabilityStatus,
  type AutosyncInput,
  type AutosyncOutput,
  type Link,
  type Path,
  type Repo,
  type SmartElement,
  SmartElementType,
  type SmartElementWithApplicability,
  type SmartElementWithApplicabilityAndNewInfo,
  type SmartSymbol,
  type Snippet,
  type SwimmDocument,
  type Token,
  isSmartElementWithNewInfo,
  parseSwmFilename,
} from '@swimm/shared';
import { schema } from './extensions';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { forAllSwimmNodes, getSwimmNodeId } from './swimm_node';
import { Transform } from '@tiptap/pm/transform';

export function convertSwimmDocumentToAutosyncInput(
  swimmDocument: SwimmDocument,
  currentRepoId: string,
  currentBranch: string,
  repos: Repo[]
): AutosyncInput {
  const content = ProseMirrorNode.fromJSON(schema, swimmDocument.content);
  return convertSwimmContentToAutosyncInput(content, currentRepoId, currentBranch, repos);
}

export function convertSwimmContentToAutosyncInput(
  content: ProseMirrorNode,
  currentRepoId: string,
  currentBranch: string,
  repos: Repo[]
): AutosyncInput {
  const snippets = new Map<string, Snippet>();
  const symbols = new Map<string, SmartSymbol>();

  // TODO This could be more efficient if Tiptap allowed taking and returning
  // an instantiated model with a pre-existing schema
  forAllSwimmNodes(content, (node) => {
    let snippet: Snippet;
    let symbol: SmartSymbol;
    switch (node.type.name) {
      case 'swmSnippet':
        snippet = convertSwmSnippetToSmartElement(node, currentRepoId, currentBranch, repos);
        snippets.set(snippet.id, snippet);
        break;
      case 'swmPath':
        symbol = convertSwmPathToSmartElement(node, currentRepoId, currentBranch, repos);
        symbols.set(symbol.id, symbol);
        break;
      case 'swmLink':
        symbol = convertSwmLinkToSmartElement(node, currentRepoId, currentBranch, repos);
        symbols.set(symbol.id, symbol);
        break;
      case 'swmToken':
        symbol = convertSwmTokenToSmartElement(node, currentRepoId, currentBranch, repos);
        symbols.set(symbol.id, symbol);
        break;
    }

    return true;
  });

  return {
    snippets: [...snippets.values()],
    symbols: [...symbols.values()],
  };
}

function composeGitInfo(nodeRepoId: string, currentRepoId: string, currentBranch: string, repos: Repo[]) {
  const repo = repos.find((repo) => repo.id === nodeRepoId);
  const isCrossRepo = currentRepoId !== nodeRepoId;
  // bracnch can be empty only for same repo (this is the case in the IDE)
  const hasAccess = repo != null && (!isCrossRepo || !!repo.defaultBranch);
  if (!hasAccess) {
    return undefined;
  }
  return {
    repoId: nodeRepoId,
    repoName: repo.name,
    repoOwner: repo.owner ?? '',
    branch: !isCrossRepo ? currentBranch : repo.defaultBranch ?? '',
  };
}

export function convertSwmSnippetToSmartElement(
  node: ProseMirrorNode,
  currentRepoId: string,
  currentBranch: string,
  repos: Repo[]
): Snippet {
  return {
    id: getSwimmNodeId(node),
    type: SmartElementType.SNIPPET,
    lines: node.attrs.snippet.split('\n').map((line: string) => `*${line}`),
    filePath: decodeURI(node.attrs.path.replace(/^\//, '')),
    startLineNumber: node.attrs.line,
    gitInfo: composeGitInfo(node.attrs.repoId, currentRepoId, currentBranch, repos),
  };
}

export function convertSwmPathToSmartElement(
  node: ProseMirrorNode,
  currentRepoId: string,
  currentBranch: string,
  repos: Repo[]
): Path {
  return {
    id: getSwimmNodeId(node),
    type: SmartElementType.PATH,
    symbolText: node.attrs.href.replace(/^\//, '').replace(/\/?$/, ''),
    filePath: decodeURI(node.attrs.href.replace(/^\//, '').replace(/\/?$/, '')),
    isDirectory: node.attrs.href.endsWith('/'),
    gitInfo: composeGitInfo(node.attrs.repoId, currentRepoId, currentBranch, repos),
  };
}

export function convertSwmLinkToSmartElement(
  node: ProseMirrorNode,
  currentRepoId: string,
  currentBranch: string,
  repos: Repo[]
): Link {
  return {
    id: getSwimmNodeId(node),
    type: SmartElementType.LINK,
    filePath: decodeURI(node.attrs.path.replace(/^\//, '')),
    docTitle: node.attrs.docTitle,
    isDraft: false,
    gitInfo: composeGitInfo(node.attrs.repoId, currentRepoId, currentBranch, repos),
  };
}

export function convertSwmTokenToSmartElement(
  node: ProseMirrorNode,
  currentRepoId: string,
  currentBranch: string,
  repos: Repo[]
): Token {
  return {
    id: getSwimmNodeId(node),
    type: SmartElementType.TOKEN,
    symbolText: node.attrs.token,
    lineContent: node.attrs.lineData,
    filePath: decodeURI(node.attrs.path.replace(/^\//, '')),
    lineNumber: node.attrs.pos.line,
    wordIndex: {
      start: node.attrs.pos.wordStart,
      end: node.attrs.pos.wordEnd,
    },
    gitInfo: composeGitInfo(node.attrs.repoId, currentRepoId, currentBranch, repos),
  };
}

export interface NormalizedAutosyncOutput {
  applicability: ApplicabilityStatus;
  smartElements: Map<string, SmartElementWithApplicability<SmartElement>>;
}

export function normalizeAutosyncOutput(autosyncOutput: AutosyncOutput): NormalizedAutosyncOutput {
  const smartElements = new Map<string, SmartElementWithApplicability<SmartElement>>();

  for (const snippet of autosyncOutput.snippets) {
    smartElements.set(snippet.id, snippet);
  }

  for (const symbol of autosyncOutput.symbols) {
    smartElements.set(symbol.id, symbol);
  }

  return {
    applicability: autosyncOutput.applicability,
    smartElements,
  };
}

export function applyAutosyncOutputToSwimmDoc(swimmDoc: SwimmDocument, autosyncOutput: AutosyncOutput): void {
  const rootNode = ProseMirrorNode.fromJSON(schema, swimmDoc.content);
  const tr = new Transform(rootNode);
  applyAutosync(tr, [...autosyncOutput.snippets, ...autosyncOutput.symbols]);
  swimmDoc.content = tr.doc.toJSON();
}

export function applyAutosync(tr: Transform, smartElement: SmartElementWithApplicability<SmartElement>): void;
export function applyAutosync(tr: Transform, smartElements: SmartElementWithApplicability<SmartElement>[]): void;
export function applyAutosync(
  tr: Transform,
  smartElements: Map<string, SmartElementWithApplicability<SmartElement>>
): void;
export function applyAutosync(
  tr: Transform,
  smartElements:
    | SmartElementWithApplicability<SmartElement>
    | SmartElementWithApplicability<SmartElement>[]
    | Map<string, SmartElementWithApplicability<SmartElement>>
): void {
  let smartElementsMap: Map<string, SmartElementWithApplicability<SmartElement>>;
  if (smartElements instanceof Map) {
    smartElementsMap = smartElements;
  } else if (Array.isArray(smartElements)) {
    smartElementsMap = new Map();
    for (const smartElement of smartElements) {
      smartElementsMap.set(smartElement.id, smartElement);
    }
  } else {
    smartElementsMap = new Map();
    smartElementsMap.set(smartElements.id, smartElements);
  }

  const doc = tr.doc;
  forAllSwimmNodes(doc, (node, pos) => {
    const swimmId = getSwimmNodeId(node);
    const smartElement = smartElementsMap.get(swimmId);
    if (smartElement != null) {
      switch (node.type.name) {
        case 'swmSnippet':
          applySwmSnippetAutosync(
            tr,
            node,
            tr.mapping.map(pos),
            smartElement as SmartElementWithApplicability<Snippet>
          );
          break;
        case 'swmPath':
          applySwmPathAutosync(tr, node, tr.mapping.map(pos), smartElement as SmartElementWithApplicability<Path>);
          break;
        case 'swmLink':
          applySwmLinkAutosync(tr, node, tr.mapping.map(pos), smartElement as SmartElementWithApplicability<Link>);
          break;
        case 'swmToken':
          applySwmTokenAutosync(tr, node, tr.mapping.map(pos), smartElement as SmartElementWithApplicability<Token>);
          break;
      }
    }

    return true;
  });
}

export function applySwmSnippetAutosync(
  tr: Transform,
  node: ProseMirrorNode,
  pos: number,
  snippet: SmartElementWithApplicability<Snippet>
): void {
  if (!isSmartElementWithNewInfo(snippet)) {
    return;
  }

  tr.setNodeMarkup(pos, null, {
    ...node.attrs,
    snippet: snippet.newInfo.lines.map((line) => line.slice(1)).join('\n'),
    path: `/${snippet.newInfo.filePath}`,
    line: snippet.newInfo.startLineNumber,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    repoName: snippet.newInfo.gitInfo!.repoName,
  });
}

export function applySwmPathAutosync(
  tr: Transform,
  node: ProseMirrorNode,
  pos: number,
  path: SmartElementWithApplicability<Path>
): void {
  if (!isSmartElementWithNewInfo(path)) {
    return;
  }

  tr.setNodeMarkup(pos, null, {
    ...node.attrs,
    href: encodeURI(`/${path.newInfo.filePath}${node.attrs.href.endsWith('/') ? '/' : ''}`),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    repoName: path.newInfo.gitInfo!.repoName,
  });
}

export function applySwmLinkAutosync(
  tr: Transform,
  node: ProseMirrorNode,
  pos: number,
  link: SmartElementWithApplicability<Link>
): void {
  if (!isSmartElementWithNewInfo(link)) {
    return;
  }

  tr.setNodeMarkup(pos, null, {
    ...node.attrs,
    path: encodeURI(`/${link.newInfo.filePath}`),
    docTitle: link.newInfo.docTitle,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    repoName: link.newInfo.gitInfo!.repoName,
  });
}

export function applySwmTokenAutosync(
  tr: Transform,
  node: ProseMirrorNode,
  pos: number,
  token: SmartElementWithApplicability<Token>
): void {
  if (!isSmartElementWithNewInfo(token)) {
    return;
  }

  tr.setNodeMarkup(pos, null, {
    ...node.attrs,
    token: token.newInfo.symbolText,
    path: `/${token.newInfo.filePath}`,
    pos: {
      line: token.newInfo.lineNumber,
      wordStart: token.newInfo.wordIndex.start,
      wordEnd: token.newInfo.wordIndex.end,
    },
    lineData: token.newInfo.lineContent,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    repoName: token.newInfo.gitInfo!.repoName,
  });
}

function isSmartSnippetModified(element: SmartElementWithApplicabilityAndNewInfo<Snippet>): boolean {
  const oldId = `${element.filePath}-${element.lines.join('-')}-${element.startLineNumber}`;
  const newId = `${element.newInfo.filePath}-${element.newInfo.lines.join('-')}-${element.newInfo.startLineNumber}`;
  return oldId !== newId;
}

function isSmartTokenModified(element: SmartElementWithApplicabilityAndNewInfo<Token>): boolean {
  const oldId = `${element.filePath}-${element.symbolText}-${element.lineContent}-${element.lineNumber}-${element.wordIndex.start}-${element.wordIndex.end}`;
  const newId = `${element.newInfo.filePath}-${element.newInfo.symbolText}-${element.newInfo.lineContent}-${element.newInfo.lineNumber}-${element.newInfo.wordIndex.start}-${element.newInfo.wordIndex.end}`;
  return oldId !== newId;
}

function isSmartPathModified(element: SmartElementWithApplicabilityAndNewInfo<Path>): boolean {
  const oldId = `${element.filePath}-${element.symbolText}`;
  const newId = `${element.newInfo.filePath}-${element.newInfo.symbolText}`;
  return oldId !== newId;
}

function isSmartLinkModified(element: SmartElementWithApplicabilityAndNewInfo<Link>): boolean {
  const oldId = `${element.filePath}-${element.docTitle}`;
  const newId = `${element.newInfo.filePath}-${element.newInfo.docTitle}`;
  return oldId !== newId;
}

// TODO This can probably moved to a more shared location, it's really a part of handling autosync output that others might also need...
export function isSmartElementModified<T extends SmartSymbol | Snippet>(
  element: SmartElementWithApplicability<T>
): boolean {
  if (element.applicability !== ApplicabilityStatus.Verified) {
    return false;
  }
  switch (element.type) {
    case SmartElementType.SNIPPET: {
      return isSmartSnippetModified(element as SmartElementWithApplicabilityAndNewInfo<Snippet>);
    }
    case SmartElementType.PATH: {
      return isSmartPathModified(element as SmartElementWithApplicabilityAndNewInfo<Path>);
    }
    case SmartElementType.TOKEN: {
      return isSmartTokenModified(element as SmartElementWithApplicabilityAndNewInfo<Token>);
    }
    case SmartElementType.LINK: {
      return isSmartLinkModified(element as SmartElementWithApplicabilityAndNewInfo<Link>);
    }
  }
}

export function addDraftsInfoToAutosync(autosyncInput: AutosyncInput, isDraft: (id: string) => boolean): void {
  for (const symbol of autosyncInput.symbols) {
    if (symbol.type === SmartElementType.LINK) {
      const parsedSwmFileName = parseSwmFilename(symbol.filePath);
      if (parsedSwmFileName) {
        symbol.isDraft = isDraft(parsedSwmFileName.swmId);
      }
    }
  }
}
