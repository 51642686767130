import type { Attrs, Mark, Node } from '@tiptap/pm/model';
import type { EditorState } from '@tiptap/pm/state';
import { type Editor, findChildren } from '@tiptap/core';
import type { EditorView } from '@tiptap/pm/view';
import { posToDOMRect } from '@tiptap/core';
import { type SwmCellSnippet, getHunkIndication } from '@swimm/shared';
export enum extensionsWithNestedContentTypes {
  TABLE = 'tableItem',
  CODEBLOCK = 'codeBlock',
  HUNK = 'hunk',
  MERMAID = 'mermaid',
}

export interface NodeWithPos {
  node: Node;
  pos: number;
}

export interface Coords {
  x: number;
  y: number;
}

export type extensionsWithNestedContentType = 'tableItem' | 'codeBlock' | 'hunk' | 'mermaid';
export const swimmExtensionWithNestedContent: string[] = ['tableItem', 'codeBlock', 'hunk', 'mermaid'];

export function nodeHasNestedContent(type: string) {
  return swimmExtensionWithNestedContent.includes(type);
}

export function getNodeStartPosition(view: EditorView, position: number) {
  return view.state.tr.doc.resolve(position).start(1);
}

export function liftNestedListItems(editor: Editor) {
  const currentCursorPosition = editor.state.selection.$head.pos;
  const depth = editor.state.selection.$head.depth;
  const depthNodeStartPosition = editor.state.selection.$head.start(depth);

  /* If the first position of the most nested node we are currently interacting with is equal to the current cursor position we will
  lift the li */
  if (depthNodeStartPosition === currentCursorPosition) {
    editor.commands.liftListItem('listItem');
    return true;
  }
  return false;
}
export function getParentNodeForPosition(position: number, state: EditorState, depth = 1): Node {
  return state.doc.resolve(position).node(depth);
}

export function getStartAndEndPos(pos: number, editor: Editor, node: Node) {
  const resolvedPos = editor.state.doc.resolve(pos);
  const startPos = pos - resolvedPos.textOffset;
  const endPos = startPos + node.nodeSize;
  return { start: startPos, end: endPos };
}

export function getDomRectByPos(view: EditorView, from: number, to?: number) {
  return posToDOMRect(view, from, to || from);
}

export function getMarkFromNode(node: Node, markType: string): Mark | null {
  const mark = node.marks.find((mark) => mark.type.name === markType);
  if (mark) {
    return mark;
  }
  return null;
}
export function getPosByCoords(editor: Editor, coords: Coords) {
  const pos = editor.view.posAtCoords({ top: coords.y, left: coords.x });
  if (pos !== null) {
    return pos;
  }
  return null;
}

export function getNodeWithPosByCoords(editor: Editor, coords: Coords): NodeWithPos | undefined {
  const pos = getPosByCoords(editor, coords);
  if (pos !== null) {
    const node = editor.state.doc.nodeAt(pos.pos);
    if (node) {
      return { node, pos: pos.pos };
    }
  }
  return undefined;
}

export function getNodeDepth(type: extensionsWithNestedContentType) {
  /*
   Nodes with nested content has depth for them.
   the depth means how deep their nesting is
   hunks mermaid and code block for example only has one nested layer inside of them
   table has three
   */
  switch (type) {
    case 'tableItem':
      return 3;
    default:
      return 1;
  }
}

export function deleteToken({ deleteNode, node, editor }: { deleteNode: () => void; node: Node; editor: Editor }) {
  deleteNode();
  const tokenAsInlineCode = {
    type: 'text',
    text: node.attrs.originalText || node.attrs.text,
    marks: [{ type: 'code' }],
  };
  editor.commands.insertContent(tokenAsInlineCode);
}

export function filterNodesById(id: string, editor: Editor) {
  return findChildren(editor.state.doc, (node: Node) => node.attrs.id === id);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateNodesById(id: string, editor: Editor, newAttrs: Attrs) {
  const nodesWithTheSameId = filterNodesById(id, editor);

  editor.commands.batchUpdateNodes(newAttrs, nodesWithTheSameId);
}

export function generateCodeNode(text: string, editor: Editor) {
  const schema = editor.view.state.schema;
  const newMark = schema.mark('code');
  const textNode = schema.text(text, [newMark]);

  return textNode;
}

export function filterHunksByIndication(indication: string, editor: Editor) {
  return findChildren(editor.state.doc, (node: Node) => {
    if (node.type.name === 'hunk') {
      const hunk: SwmCellSnippet = JSON.parse(node.attrs.hunk);

      return indication === getHunkIndication(hunk);
    } else {
      return false;
    }
  });
}

export function isCurrentNodeFocused(pos: number, nodeSize: number, editor: Editor) {
  const head = editor.state.selection.head;
  return pos <= head && head <= pos + nodeSize;
}

export function getCurrentSelection(editor: Editor) {
  const to = editor.state.selection.to;
  const from = editor.state.selection.from;
  return {
    to: to,
    from: from,
  };
}
