<script setup lang="ts">
import { computed, ref } from 'vue';
import type { User } from '../../types/transitional';

import BaseAvatar from '../../components/BaseAvatar/BaseAvatar.vue';
import BaseHighlight from '../../components/BaseHighlight/BaseHighlight.vue';

import MenuItem from '../MenuItem/MenuItem.vue';

defineProps<{
  user: User;
  query?: string;
}>();

const el = ref<InstanceType<typeof MenuItem>>();
const root = computed(() => el?.value?.root);
const text = computed(() => el?.value?.text);

defineExpose({ root, text });
</script>

<template>
  <MenuItem ref="el" wrapper="div" data-testid="swm-mention-item">
    <template #leftIcon>
      <BaseAvatar :name="user.name" :email="user.email" :url="user.avatarUrl" size="large" />
    </template>
    <BaseHighlight :query="query" :string="user.name" />
  </MenuItem>
</template>
