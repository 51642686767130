<script lang="ts">
export const Icons = [
  'add',
  'align-left',
  'align-center',
  'align-right',
  'arrow-down',
  'arrow-left',
  'arrow-more',
  'arrow-next-down',
  'arrow-next-up',
  'arrow-next',
  'arrow-right',
  'arrow-up',
  'assign',
  'assignment-complete',
  'azure',
  'back-browsing',
  'bell',
  'binocular-outline',
  'binocular',
  'bitbucket',
  'bold',
  'book-open',
  'bookmark',
  'branch-file-change',
  'branch-file-changed',
  'branch-merged-pr',
  'branch-open-pr',
  'branch',
  'briefcase',
  'broken-image',
  'browser-settings',
  'calendar',
  'card-view',
  'chat',
  'check-fill',
  'check-outline',
  'check',
  'checklist',
  'clock',
  'close',
  'cloud',
  'code-coupled',
  'code-fill',
  'codeblock',
  'collapse-arrow',
  'collapse-main-nav',
  'collapse-side',
  'community',
  'confluence',
  'copy-paste',
  'copy',
  'credit-card',
  'crown',
  'database',
  'delete-pending',
  'demo-avatar',
  'dev',
  'discard',
  'doc-empty',
  'doc-file-change',
  'doc-general',
  'doc-lock',
  'doc-needs-review',
  'doc-read',
  'doc-request',
  'doc-shared',
  'doc',
  'drag-handle-options',
  'drag',
  'duplicate',
  'edit-outline',
  'email',
  'emoji',
  'energy',
  'enter',
  'everything',
  'expand-arrow',
  'expand-down',
  'expand-up',
  'expand',
  'expanded',
  'explore',
  'eye',
  'facebook',
  'file-created',
  'file-empty',
  'file',
  'filter',
  'flag',
  'flash',
  'folder-open',
  'folder',
  'generate',
  'gerrit',
  'giphy',
  'git-integrated',
  'git-not-integrated',
  'github',
  'gitlab',
  'globe-warning',
  'globe',
  'graph-class',
  'graph-flow',
  'graph-gantt',
  'graph-line',
  'graph-pie',
  'graph-relation',
  'graph-sequence',
  'graph-state',
  'graph-user-journey',
  'guided-creation',
  'help-no-border',
  'help',
  'hint',
  'home',
  'hourglass',
  'html',
  'image',
  'import',
  'info-fill',
  'info-outline',
  'insert-down',
  'insert-left',
  'insert-right',
  'insert-top',
  'insert-up',
  'instagram',
  'integrations',
  'interactive',
  'ios',
  'italic',
  'jetbrains',
  'keyboard-shortcut',
  'lifebuoy-outline',
  'lightbulb-filled-yellow',
  'lightbulb',
  'like',
  'liked',
  'link',
  'linkedin',
  'linux',
  'list-view',
  'location',
  'locked',
  'long-arrow-left',
  'long-arrow-right',
  'main-nav-expand',
  'magic',
  'markdown',
  'mention',
  'merged-pr',
  'mermaid',
  'minimize',
  'more-slim',
  'more-vertical-centered',
  'more-vertical',
  'more',
  'move',
  'next-browsing',
  'not-allowed',
  'not-save',
  'notion',
  'ol',
  'outbound-link',
  'overview',
  'pencil',
  'pending',
  'pin',
  'plan',
  'plans',
  'playlist',
  'plugins',
  'pr',
  'private-cloud-doc',
  'private',
  'quote',
  'refresh',
  'regex',
  'repo-folder',
  'repo-project',
  'repo-provider',
  'request-demo',
  'resize-expand',
  'resize-shrink',
  'revert-all',
  'reviewer',
  'robot',
  'save',
  'search',
  'security-shield',
  'send',
  'settings2',
  'share',
  'shortcut',
  'shrink',
  'signout',
  'simplified-code',
  'slack',
  'snippet-highlight',
  'sort',
  'split-view-horizontal',
  'split-view-vertical',
  'split-snippet',
  'star-filled',
  'star-outline',
  'stats',
  'stop',
  'strike',
  'sync-code',
  'sync-token',
  'sync',
  'table',
  'tag',
  'task',
  'team-add',
  'team',
  'template',
  'terminal',
  'text-bullet',
  'text-headline1',
  'text-headline2',
  'text-headline3',
  'text-headline4',
  'text-headline5',
  'text-headline6',
  'text',
  'time',
  'tool',
  'topic-add',
  'topic-api',
  'topic-flow',
  'topic-general',
  'topic-integration',
  'topic-overview',
  'trash-fill',
  'trash-outline',
  'troubleshoot',
  'tutorial',
  'twitter',
  'ul',
  'unavailable-fill',
  'unavailable',
  'underline',
  'union',
  'unlink',
  'update',
  'updated',
  'upload',
  'vertical-expand',
  'video-fill',
  'video',
  'vscode',
  'warning-fill',
  'warning',
  'wave',
  'window-unavailable',
  'windows',
  'workflow',
  'workspace',
  'youtube',
] as const;
export type IconsType = (typeof Icons)[number];
</script>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  /**
   * The name of the icon, available icons can also be reviewed here: https://swimmio.github.io/fontaweswimm/src/fonts/fontaweswimm/demo.html
   */
  name: IconsType;
  /**
   * An aria-label is automatically applied to the icon based on the icon name. You can override this using the label prop.
   * Pass in an empty string to remove the aria-label entirely (aria-labels are not allowed with some 'role's.
   */
  label?: string;
}>();

const computedClasses = computed(() => ({
  [`icon-${props.name}`]: true,
}));

const computedAriaLabel = computed(() => {
  const name = props.name
    .split('-')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return props.label ?? `${name} icon`;
});
</script>

<template>
  <span class="icon" :class="computedClasses" role="img" :aria-label="computedAriaLabel || undefined" />
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.icon {
  color: inherit;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  line-height: inherit;

  &::before {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  /**
   * TODO: .icon-text is mistyled in https://github.com/swimmio/fontaweswimm/blob/main/src/fonts/fontaweswimm/style.css to .icon-Text,
   * this style adds the correct casing for consistency, but we should update the fontaweswimm repo.
   */
  &-text::before {
    content: '\e900';
  }
}
</style>
