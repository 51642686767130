<script setup lang="ts">
import { computed, ref } from 'vue';
import { type DecorationWithType } from '@tiptap/vue-3';
import {
  type AnalyticsTrackProperties,
  type GenerateMermaidWithAiDebugData,
  type GenerateMermaidWithAiResult,
  MermaidAiPanel,
  MermaidAiStatus,
  MermaidDiagramFooter,
  MermaidDiagramHeader,
  MermaidDiagramPanels,
  type MermaidLayoutType,
  type MermaidSampleOption,
} from '@swimm/editor';
import { type ApplicabilityStatus, productEvents } from '@swimm/shared';
import type { DecorationAttrs } from '@tiptap/pm/view';
import { useElementSize } from '@vueuse/core';

const props = defineProps<{
  svg: string | null;
  isEmpty: boolean;
  error: string | null;
  isEditMode: boolean;
  isSelected: boolean;
  isHighlighted: boolean;
  hasTokens: boolean;
  tokensSupported: boolean;
  applicability: ApplicabilityStatus.Outdated | ApplicabilityStatus.Verified | ApplicabilityStatus.Autosyncable;
  mermaidPackageVersion: string | null;
  // Note that the type of the type field is actually wrong -.-""
  decorations: DecorationWithType[];
  aiGenerationHidden: boolean;
  beforeOpenAiPanel: () => boolean;
  checkForQuotaExceeded: () => Promise<boolean>;
  generateWithAi: ({
    userPrompt,
    selectedSample,
    abort,
  }: {
    userPrompt: string;
    selectedSample: MermaidSampleOption | null;
    abort: AbortController;
  }) => Promise<GenerateMermaidWithAiResult>;
  allowAiDebug: boolean;
}>();

const emit = defineEmits<{
  (e: 'sample-select', option: MermaidSampleOption): void;
  (e: 'mermaid-live-link-clicked'): void;
  (e: 'track-event', eventName: string, props: AnalyticsTrackProperties): void;
}>();

const layout = ref<MermaidLayoutType>('side-by-side');

function changeLayout(type: MermaidLayoutType) {
  layout.value = type;
}

const mermaidElem = ref(null);
const { width } = useElementSize(mermaidElem);

const forceTopDownView = computed(() => width.value < 600);

const isSideBySideView = computed(() => layout.value === 'side-by-side' && !forceTopDownView.value);

const showEditor = computed<boolean>(() => props.isEditMode && props.isSelected);

const shouldShowMermaidEmptyState = computed(() => showEditor.value && props.isEmpty);

const placeholder = computed(() => {
  for (const decoration of props.decorations) {
    // Note that the type of the type field is actually wrong -.-""
    const placeholder = (decoration.type as unknown as { attrs: DecorationAttrs }).attrs['data-placeholder'];
    if (placeholder) {
      return placeholder;
    }
  }

  return null;
});

function sampleSelected(option: MermaidSampleOption) {
  // this is not closed when you click on the sample picker again
  shouldShowAiPanel.value = false;
  shouldShowAiStatus.value = false;
  emit('sample-select', option);
}

const shouldShowAiPanel = ref(false);
const shouldShowAiStatus = ref(false);
const aiStatus = ref<'progress' | 'error' | 'feedback'>('progress');
const aiProgress = ref(0);
const aiDebugData = ref<GenerateMermaidWithAiDebugData | null>(null);

function openAiPanel() {
  shouldShowAiStatus.value = false;
  const isOk = !props.aiGenerationHidden && props.beforeOpenAiPanel();
  shouldShowAiPanel.value = isOk;
  if (isOk) {
    emit('track-event', productEvents.OPENED_MERMAID_AI_DIALOG, {
      'Is Empty': props.isEmpty,
    });
    // start function to check if quota exceeded
    // but don't block the panel for this
    void updateQuotaExceeded();
  }
}

const quotaExceeded = ref(false);

async function updateQuotaExceeded() {
  quotaExceeded.value = false;
  quotaExceeded.value = await props.checkForQuotaExceeded();
}

let generateWithAiAbortController: AbortController | null = null;
let aiProgressInterval: ReturnType<typeof setInterval> | undefined;

async function startGenerateWithAi({
  prompt,
  selectedSample,
}: {
  prompt: string;
  selectedSample: MermaidSampleOption | null;
}) {
  if (props.aiGenerationHidden) {
    throw new Error('Should not be called, ai generation is hidden!');
  }

  shouldShowAiStatus.value = true;
  shouldShowAiPanel.value = false;

  aiStatus.value = 'progress';
  generateWithAiAbortController = new AbortController();
  aiProgress.value = 0;
  aiDebugData.value = null;
  startAiProgressInterval();
  const { status, debugData } = await props.generateWithAi({
    userPrompt: prompt,
    selectedSample,
    abort: generateWithAiAbortController,
  });
  aiProgress.value = 100;
  aiDebugData.value = debugData;
  clearInterval(aiProgressInterval);
  aiProgressInterval = undefined;
  generateWithAiAbortController = null;
  shouldShowAiStatus.value = status === 'success' || status === 'error';
  aiStatus.value = status === 'error' ? 'error' : 'feedback';
  shouldShowAiPanel.value = false;
  if (status === 'regenerate') {
    openAiPanel();
  }
}

function startAiProgressInterval() {
  const maxValue = 100;
  const expectedTimeSeconds = 10;
  const step = 100;
  const perStep = (maxValue / expectedTimeSeconds) * (step / 1000);
  clearInterval(aiProgressInterval);
  aiProgressInterval = setInterval(() => {
    aiProgress.value = Math.min(aiProgress.value + perStep, maxValue);
  }, step);
}

function stopGenerateWithAi(reason: 'stop' | 'regenerate') {
  generateWithAiAbortController?.abort(reason);
}

function regenerate() {
  openAiPanel();
}

function closeStatus() {
  shouldShowAiStatus.value = false;
}
</script>

<template>
  <div
    ref="mermaidElem"
    class="mermaid-container"
    :class="{
      selected: isSelected,
      highlighted: isHighlighted,
      'empty-view': isEmpty && !showEditor,
      'show-editor': showEditor,
    }"
    data-testid="mermaid"
  >
    <template v-if="isEditMode && !aiGenerationHidden">
      <div v-show="shouldShowAiPanel && showEditor" class="mermaid-ai-panel-wrapper">
        <MermaidAiPanel
          :quota-exceeded="quotaExceeded"
          :should-show="shouldShowAiPanel"
          @generate-with-ai="startGenerateWithAi"
          @close="() => (shouldShowAiPanel = false)"
        />
      </div>
      <div v-show="shouldShowAiStatus && showEditor" class="mermaid-ai-status-wrapper">
        <MermaidAiStatus
          :status="aiStatus"
          :progress="aiProgress"
          :debug-data="allowAiDebug ? aiDebugData : null"
          @stop-generation="stopGenerateWithAi('stop')"
          @stop-and-regenerate="stopGenerateWithAi('regenerate')"
          @regenerate="regenerate"
          @close-status="closeStatus"
        />
      </div>
    </template>
    <MermaidDiagramHeader
      :is-edit-mode="isEditMode"
      :editor-shown="isEditMode && showEditor"
      :show-tokens-unsupported-tip="!tokensSupported && hasTokens"
      :empty="isEmpty"
      :error="error"
      :applicability="applicability"
      :has-tokens="hasTokens"
      :with-ai-option="isEditMode && !aiGenerationHidden"
      :show-layout-toggle="isEditMode && showEditor && !forceTopDownView"
      @sample-select="sampleSelected"
      @layout-changed="(type:MermaidLayoutType)=>changeLayout(type)"
      @open-ai-panel="openAiPanel"
    />
    <MermaidDiagramPanels
      :is-empty="isEmpty"
      :error="error"
      :is-edit-mode="isEditMode"
      :should-show-mermaid-empty-state="shouldShowMermaidEmptyState"
      :svg="svg"
      :placeholder="placeholder"
      :editor-overlapped="shouldShowAiPanel"
      :is-side-by-side-view="isSideBySideView"
      :show-editor="showEditor"
    />
    <MermaidDiagramFooter
      v-if="!isEmpty || showEditor"
      :mermaid-version="mermaidPackageVersion"
      @mermaid-live-link-clicked="() => emit('mermaid-live-link-clicked')"
    />
  </div>
</template>

<style scoped lang="scss">
.mermaid-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--node-margin-bottom);
  border: 1px solid var(--color-border-default);
  border-radius: 4px;

  &.empty-view {
    border-style: dashed;
    color: var(--text-color-secondary);
  }

  .mermaid-ai-panel-wrapper {
    position: absolute;
    top: 30%;
    left: 25%;
    background: var(--color-bg-default);
    z-index: 1000;
    padding: var(--space-base);
    width: 50%;
    min-width: 400px;
    box-shadow: var(--box-shadow-big);
    border: 1px solid var(--color-border-default-subtle);
  }

  .mermaid-ai-status-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--color-bg-default);
    z-index: 1000;
    padding: var(--space-base);
    width: 100%;
    box-shadow: var(--box-shadow-big);
    border: 1px solid var(--color-border-default-subtle);
    border-radius: 0 0 4px 4px;
  }

  &.selected {
    border-radius: 4px;
    outline: 1px solid var(--color-selection);
  }

  > * {
    box-sizing: border-box;
  }
}

.mermaid-container.selected::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mermaid-container.highlighted::after {
  background-color: var(--color-selection);
  opacity: var(--opacity-medium);
  z-index: var(--layer-overlap);
}
</style>
