<template>
  <div v-if="assignments.length" class="container">
    <div
      v-for="(assignment, index) in sortedAssignments"
      :key="assignment.id"
      class="assignment-row"
      :class="{ 'bottom-border': index !== sortedAssignments.length - 1 }"
    >
      <AssigneePopupEntry :assignment="assignment" :repo-id="repoId" :doc-id="docId" :origin="origin" />
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { storeToRefs } from 'pinia';
import AssigneePopupEntry from './AssigneePopupEntry.vue';

export default {
  components: { AssigneePopupEntry },
  props: {
    assignments: { type: Array, required: true },
    repoId: { type: String, required: true },
    docId: { type: String, required: true },
    origin: { type: String, required: true },
  },
  setup() {
    const { user } = storeToRefs(useAuthStore());
    return { user };
  },
  computed: {
    sortedAssignments() {
      return [...this.assignments].sort((left, right) => {
        const leftAssignedToUser = left.assignee === this.user.id;
        const rightAssignedToUser = right.assignee === this.user.id;
        if (leftAssignedToUser) {
          return -1;
        } else if (rightAssignedToUser) {
          return 1;
        }
        const leftAssignedByUser = left.assigned_by === this.user.id;
        const rightAssignedByUser = right.assigned_by === this.user.id;
        if (leftAssignedByUser) {
          return -1;
        } else if (rightAssignedByUser) {
          return 1;
        }
        return left.assignee_name.localeCompare(right.assignee_name);
      });
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 280px;
  width: 280px;
}

.assignment-row {
  padding: 8px 16px;
}

.bottom-border {
  border-bottom: 1px solid var(--fifty-shades);
}
</style>
