<script setup lang="ts">
import { type Tab, Tabs, TextField } from '@swimm/ui';
import { computed, ref } from 'vue';

const props = defineProps({
  tabs: { type: Object, required: true },
  selectedTabKey: { type: String, required: true },
  searchDisabled: { type: Boolean, default: false },
  tabsDisabled: { type: Boolean, default: false },
});

const emit = defineEmits(['tab-select', 'search']);

const searchQuery = ref('');

const selectedTabIndex = computed(() => {
  return props.tabs.findIndex((tab) => tab.key === props.selectedTabKey) || 0;
});

function handleTabSelection(tabIndex: string) {
  const selectedTab = props.tabs[tabIndex];
  emit('tab-select', selectedTab.key);
}
function onClearSearch() {
  searchQuery.value = '';
  emit('search', searchQuery.value);
}
function onSearch(value: string) {
  emit('search', value);
}
</script>

<template>
  <div class="filters">
    <Tabs
      :tabs="(tabs as Tab[])"
      :model-value="selectedTabIndex"
      :readonly="tabsDisabled"
      @update:model-value="handleTabSelection"
    />
    <TextField
      v-model="searchQuery"
      class="search-box"
      :disabled="searchDisabled"
      placeholder="Search"
      @update:model-value="onSearch"
    >
      <Icon v-if="searchQuery" class="clickable" name="close" @click="onClearSearch" />
      <Icon v-else class="search-icon" name="search" />
    </TextField>
  </div>
</template>

<style scoped lang="postcss">
.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--space-md) 0;
  border-bottom: 1px solid var(--color-border-default-subtle);

  :deep(.tabs) {
    margin-bottom: 5px;
  }

  .search-box {
    width: 280px;
    flex-grow: 0;
    flex-basis: auto;
  }

  .search-icon {
    color: var(--text-color-disable);
  }
}
</style>
