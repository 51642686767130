<template>
  <div class="shortcuts-groups" :class="{ expanded: showContent }">
    <div :class="['group-title-container', { clickable: expandable }]" @click="$emit('item-click')">
      <div class="group-title subtitle-L">
        <slot name="title" />
      </div>
      <Icon v-if="expandable" class="arrow-icon" :name="iconName" />
    </div>
    <div v-if="showContent" class="content-container">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    expanded: { type: Boolean, default: false },
    expandable: { type: Boolean, default: true },
  },
  emits: ['item-click'],
  computed: {
    iconName() {
      return this.expanded ? 'arrow-up' : 'arrow-down';
    },
    showContent() {
      return this.expanded || !this.expandable;
    },
  },
};
</script>
<style scoped>
.shortcuts-groups {
  padding: 12px 8px;
  border-bottom: 1px solid var(--color-border-default);
}

.shortcuts-groups.expanded {
  padding: 12px 8px 8px;
}

.shortcuts-groups:last-child {
  border-bottom: none;
}

.group-title-container {
  display: flex;
  justify-content: space-between;
}

.group-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 800;
  color: var(--text-color-primary);
  width: 100%;
}

.arrow-icon {
  padding: 0;
  font-size: var(--headline2);
}

.content-container {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
</style>
