import { updateSymbols } from '@/adapters-common/update-symbols';
import {
  PLAYLIST_INTRO_COMMENT,
  PLAYLIST_STEPS_COMMENT,
  PLAYLIST_SUMMARY_COMMENT,
} from '@/common/utils/playlist-utils';
import { PlaylistSequenceStepTypes } from '@/modules/playlists/types';
import type { DraftSwmFile } from '@swimm/shared';
import {
  ApplicabilityStatus,
  SwmCellType,
  SwmSymbolType,
  cleanSwmRuntimeKeys,
  cleanSymbolsRuntimeKey,
} from '@swimm/shared';
import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export function usePrepareSwimms() {
  const store = useStore();
  const route = useRoute();

  function db_getPlaylist(repoId, swimmId) {
    return store.getters['database/db_getPlaylist'](repoId, swimmId);
  }

  function db_getSwimm(repoId, swimmId) {
    return store.getters['database/db_getSwimm'](repoId, swimmId);
  }

  function db_getRepoMetadata(repoId) {
    return store.getters['database/db_getRepoMetadata'](repoId);
  }

  function prepareSwimms(filesToSave: DraftSwmFile[]) {
    const swimmsToSave = [];
    for (const draft of filesToSave) {
      if (draft.type === 'swmd') {
        swimmsToSave.push(prepareUnitBeforeSave(draft));
      } else {
        swimmsToSave.push(setPlaylistContent(draft));
      }
    }
    return swimmsToSave;
  }

  function cleanContent(unit) {
    unit.content.forEach((cell) => {
      delete cell.tempId;
      delete cell.originalInfo;
    });
  }

  function updateSwimmLinks(unitToSave) {
    if (!unitToSave.symbols) {
      return;
    }
    for (const currSymbol of Object.values(unitToSave.symbols).filter(
      // eslint-disable-next-line
      (symbol: any) => symbol.type === SwmSymbolType.LINK
    )) {
      // eslint-disable-next-line
      const symbol = currSymbol as unknown as any;
      const swmFromDB =
        symbol.swimmType === 'playlist'
          ? db_getPlaylist(symbol.repoId, symbol.swimmId)
          : db_getSwimm(symbol.repoId, symbol.swimmId);
      let latestDBName = swmFromDB ? swmFromDB.name : symbol.text;
      if (symbol.repoId !== route.params.repoId) {
        // If cross repo link - add repo name to link text
        latestDBName = `${db_getRepoMetadata(symbol.repoId).name}/${latestDBName}`;
      }
      symbol.text = latestDBName;
    }
  }

  function prepareUnitBeforeSave(unitToSave) {
    // Prevent saving of empty text cells for docs
    unitToSave.content = unitToSave.content.filter((cell) => cell.type !== SwmCellType.Text || cell.text.trim() !== '');
    unitToSave = cleanSwmRuntimeKeys(unitToSave);
    cleanContent(unitToSave);
    updateSymbols(unitToSave);
    updateSwimmLinks(unitToSave);
    unitToSave = cleanSymbolsRuntimeKey(unitToSave);
    return unitToSave;
  }

  function setPlaylistContent(savedPlaylist, stepsNameOverride: Record<string, string> = {}) {
    const playlistSwm = {
      name: savedPlaylist.name,
      id: savedPlaylist.id,
      meta: {},
      content: [],
      symbols: {},
      isNew: savedPlaylist.isNew,
      type: savedPlaylist.type,
      path: savedPlaylist.path,
    };
    // Intro
    if (savedPlaylist.description && savedPlaylist.description.length > 0) {
      playlistSwm.content.push({ type: 'text', text: `${PLAYLIST_INTRO_COMMENT}\n${savedPlaylist.description}` });
    }
    // Steps
    if (savedPlaylist.sequence && savedPlaylist.sequence.length > 0) {
      let stepsContent = `${PLAYLIST_STEPS_COMMENT}\n`;
      savedPlaylist.sequence.forEach((step, index) => {
        if (step.type === PlaylistSequenceStepTypes.EXTERNAL_LINK) {
          // External link
          stepsContent += `${index + 1}. [${step.name}](${step.url})\n`;
        } else if (step.type === PlaylistSequenceStepTypes.PLAYLIST) {
          const playlist = db_getPlaylist(step.repoId, step.id);
          const playlistName = stepsNameOverride[step.id] || playlist?.name || step.name;
          if (playlistName) {
            const link = {
              type: SwmSymbolType.LINK,
              swimmId: step.id,
              repoId: step.repoId,
              text: playlistName,
              applicability: ApplicabilityStatus.Verified,
              swimmType: PlaylistSequenceStepTypes.PLAYLIST,
            };
            const symbolId = uuidv4();
            playlistSwm.symbols[symbolId] = link;
            stepsContent += `${index + 1}. [[sym-link:(${symbolId})${playlistName}]]\n`;
          }
        } else {
          const swimm = db_getSwimm(step.repoId, step.id);
          const docName = stepsNameOverride[step.id] || swimm?.name || step.name;
          // Creating a link symbol
          if (docName) {
            const link = {
              type: SwmSymbolType.LINK,
              swimmId: step.id,
              repoId: step.repoId,
              text: docName,
              applicability: ApplicabilityStatus.Verified,
              swimmType: step.type,
            };
            const symbolId = uuidv4();
            playlistSwm.symbols[symbolId] = link;
            stepsContent += `${index + 1}. [[sym-link:(${symbolId})${docName}]]\n`;
          }
        }
      });
      playlistSwm.content.push({ type: 'text', text: stepsContent });
    }
    // Summary
    if (savedPlaylist.summary && savedPlaylist.summary.length > 0) {
      playlistSwm.content.push({ type: 'text', text: `${PLAYLIST_SUMMARY_COMMENT}\n${savedPlaylist.summary}` });
    }
    return { ...playlistSwm };
  }

  return { prepareSwimms, setPlaylistContent };
}
