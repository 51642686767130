<script setup lang="ts">
import { productEvents } from '@swimm/shared';
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { getSwimmEditorServices } from '../extensions/Swimm';
import { toRaw } from 'vue';
import { BaseIcon } from '@swimm/reefui';
import type * as _model from '@tiptap/pm/model';

const props = defineProps(nodeViewProps);

const swimmEditorServices = getSwimmEditorServices(props.editor);

function handleClick() {
  const snippetObserver = swimmEditorServices.external.editSnippet();
  snippetObserver.subscribe({
    next(selectedSnippet) {
      if (selectedSnippet != null) {
        const from = props.getPos();
        // toRaw due to https://github.com/ueberdosis/tiptap/issues/4274
        const to = from + toRaw(props.node).nodeSize;
        props.editor
          .chain()
          .focus(from - 1)
          .deleteRange({ from, to })
          .insertSwmSnippet(selectedSnippet.snippet, selectedSnippet.path, selectedSnippet.line, selectedSnippet.repoId)
          .run();
        swimmEditorServices.external.trackEvent(productEvents.EDITED_PLACEHOLDER, {
          type: 'snippet',
        });
      }
    },
    complete() {
      props.editor.chain().focus().run();
    },
  });
}
</script>

<template>
  <NodeViewWrapper
    class="swm-snippet-placeholder"
    data-swm-snippet-placeholder
    :class="{ clickable: editor.options.editable }"
    data-testid="hunk-placeholder"
    @click="handleClick"
  >
    <BaseIcon name="task" />
    <div contenteditable="false">{{ node.attrs.placeholder }}</div>
  </NodeViewWrapper>
</template>

<style scoped lang="scss">
.swm-snippet-placeholder {
  border-radius: 4px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 16px;
  font-size: var(--font-size-xsmall);
  line-height: 16px;
  color: var(--color-text-secondary);
  border: 1px dashed var(--color-border-default);
  background-color: var(--color-bg-surface);

  &.clickable:hover {
    background-color: var(--color-bg-surface-hover);
  }
}
</style>
../extensions/Swimm
