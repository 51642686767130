import { state } from '@swimm/shared';
import type { Repo, RepoStateBranchData, RepoStateData } from '@swimm/shared';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { STORES } from '@/modules/core/stores/store-constants';
import cloneDeep from 'lodash-es/cloneDeep';

export const useReposStore = defineStore(STORES.REPOS, () => {
  const route = useRoute();
  const repoId = computed(() => route.params.repoId as string);
  const isChoosingBranch = ref(false);
  const reposStateData = ref<{ [repoId: string]: RepoStateData | null }>({});

  watch(
    repoId,
    async (newRepoId) => {
      if (newRepoId) {
        await initRepoStateData();
      }
    },
    { immediate: true }
  );

  const repos = computed<Repo[]>(() =>
    Object.keys(reposStateData.value).map((repoIdFromState) => {
      const repoStateData = reposStateData.value[repoIdFromState];
      return {
        id: repoIdFromState,
        name: repoStateData.repoName,
        owner: repoStateData.owner,
        provider: repoStateData.provider,
        integrations: {},
        api_url: repoStateData.api_url,
        tenant_id: repoStateData.tenant_id,
        defaultBranch: repoStateData.defaultBranch,
        disabled: !repoStateData.defaultBranch,
      };
    })
  );

  async function loadAllReposStateData() {
    reposStateData.value = await state.get({ key: 'repos', defaultValue: {} });
  }

  function resetState() {
    reposStateData.value = {};
  }

  async function initRepoStateData() {
    if (!reposStateData.value[repoId.value]) {
      await loadRepoStateData();
    }
  }

  async function loadRepoStateData(repoIdRequest = null) {
    const repoIdToLoad = repoIdRequest || repoId.value;
    reposStateData.value[repoIdToLoad] = await state.getRepoFromLocalState(repoIdToLoad, true);
    fixDummyRepoBug(reposStateData.value[repoIdToLoad]);
  }

  function fixDummyRepoBug(repoStateData) {
    // Fix for dummy repo bug, we removed the old github enterprise server and didn't take into account that
    // the indexed db is still holding the github.swimm.io instead of github-prod.swimm.io
    if (repoStateData?.api_url === 'https://github.swimm.io/api/v3') {
      repoStateData.api_url = 'https://github-prod.swimm.io/api/v3';
    }
  }

  async function setRepoStateData(repoId: string, newStateData: RepoStateData) {
    // Deep clone is required because some of the data passed with proxy - i.e proxy drafts array
    // Trying to set a value with proxy cause a DataCloneError
    await state.set({ key: `repos.${repoId}`, value: cloneDeep(newStateData) });
    await loadRepoStateData(repoId);
  }

  async function updateReposStateData(updatedReposData: Record<string, RepoStateData>) {
    Object.assign(reposStateData.value, updatedReposData);
    await state.set({ key: `repos`, value: cloneDeep(reposStateData.value) });
  }

  async function setRepoStateBranchData(repoId: string, newStateBranchData: RepoStateBranchData) {
    // we need to first load the new data into reposStateData for our repoId, and only then assign the new state data
    await loadRepoStateData(repoId);
    await setRepoStateData(repoId, { ...reposStateData.value[repoId], ...newStateBranchData });
  }

  return {
    isChoosingBranch,
    reposStateData,
    repos,
    resetState,
    setRepoStateData,
    setRepoStateBranchData,
    loadRepoStateData,
    updateReposStateData,
    loadAllReposStateData,
    repoId,
  };
});
