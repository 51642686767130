import { ref, watch } from 'vue';

export type StepEstimate = { postPercentage: number; expectedLengthSeconds: number };

export const useContinuousProgress = <TSteps extends string>(getStepEstimate: (step: TSteps) => StepEstimate) => {
  const progress = ref(0);
  const stepStartTime = ref(null);
  const stepStartProgress = ref(0);
  const currentStepEstimate = ref<StepEstimate | null>(null);
  const onStepStarted = (step: TSteps) => {
    stepStartTime.value = new Date();
    stepStartProgress.value = progress.value;
    currentStepEstimate.value = getStepEstimate(step);
  };

  watch(currentStepEstimate, ({ postPercentage, expectedLengthSeconds }, _, onCleanup) => {
    const handle = setInterval(() => {
      const timeSinceStepStartSeconds = (new Date().getTime() - stepStartTime.value.getTime()) / 1000;
      const progressInStep = Math.min(1.0, timeSinceStepStartSeconds / expectedLengthSeconds);
      progress.value = stepStartProgress.value * (1 - progressInStep) + postPercentage * progressInStep;
    }, 100);
    onCleanup(() => clearInterval(handle));
  });

  return { progress, onStepStarted };
};
