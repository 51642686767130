<template>
  <VDropdown placement="right-end" @show="beforeShow">
    <SidebarButton v-tooltip="{ content: 'Profile', placement: 'right' }">
      <BaseAvatar
        :name="displayName"
        :alt="displayName"
        class="avatar"
        data-testid="user-profile-menu"
        hide-tooltip
        :url="displayProfileImage"
      />
    </SidebarButton>
    <template #popper>
      <div class="menu">
        <div class="user-info-container">
          <BaseTooltip :content="displayName">
            <BaseAvatar :name="displayName" :alt="displayName" size="xlarge" :url="displayProfileImage" />
          </BaseTooltip>
          <div class="user-info">
            <BaseProse>{{ displayName }}</BaseProse>
            <BaseProse size="small">{{ user.email }}</BaseProse>
          </div>
        </div>
        <div v-close-popper>
          <SwText v-if="!embedded" class="user-action" variant="body-S" @click="openUserSettings">User settings</SwText>
          <SwText
            v-if="!embedded && shouldShowInstallIde"
            class="user-action"
            variant="body-S"
            @click="installIdePlugin"
            >Install IDE Plugin</SwText
          >
          <SwText class="user-action" variant="body-S" data-testid="dropdown-signout" @click="prepareToSignout"
            >Sign out</SwText
          >
        </div>
      </div>
    </template>
  </VDropdown>
  <UserSettingsModal
    :show="showUserSettingsModal"
    origin="User settings"
    @close="showUserSettingsModal = false"
    :initial-tab-code="initialTabCode"
  />
  <ConfirmationDialog
    v-if="showRemoveRepoDraftsAlert"
    title="You have uncommitted drafts"
    :text="draftConfirmationDialog()"
    :show="showRemoveRepoDraftsAlert"
    :show-confirm="true"
    :show-cancel="true"
    @close="() => handleDraftsAlertResult(false)"
    @confirm="() => handleDraftsAlertResult(true)"
  >
    <template #content>
      <Checkbox v-model="removeDraftsOnLogout" data-testid="remove-drafts-on-logout">
        <span><Icon class="warning-icon" name="warning-fill" /> Delete all drafts permanently</span>
      </Checkbox>
    </template>
  </ConfirmationDialog>
</template>

<script>
import UserSettingsModal from '@/modules/user-settings/modals/UserSettingsModal.vue';
import { getLoggerNew, productEvents, settingsTypes, userSettingsTabs } from '@swimm/shared';
import { mapGetters } from 'vuex';
import { Checkbox, Icon, SwText } from '@swimm/ui';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useSignout } from '@/common/composables/signout';
import ConfirmationDialog from '@/common/components/modals/ConfirmationDialog.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import SidebarButton from '@/common/components/organisms/SidebarButton.vue';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import { BaseAvatar, BaseProse, BaseTooltip } from '@swimm/reefui';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';

const logger = getLoggerNew(__modulename);

export default {
  components: {
    Checkbox,
    UserSettingsModal,
    Icon,
    SwText,
    ConfirmationDialog,
    SidebarButton,
    BaseAvatar,
    BaseProse,
    BaseTooltip,
  },
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const { signout } = useSignout();
    const { reposStateData } = storeToRefs(useReposStore());
    const userConfigStore = useUserConfigStore();
    const gitAuthStore = useGitAuthorizationStore();
    const drafts3Store = useDrafts3Store();

    async function hasDrafts() {
      return drafts3Store.hasAnyDrafts();
    }

    function hasDraftsInThisRepo() {
      return drafts3Store.drafts.size > 0;
    }

    return {
      user,
      reposStateData,
      analytics,
      settingsTypes,
      signout,
      userConfigStore,
      gitAuthStore,
      hasDrafts,
      hasDraftsInThisRepo,
    };
  },
  props: {
    embedded: { type: Boolean },
  },
  data() {
    return {
      showUserSettingsModal: false,
      showRemoveRepoDraftsAlert: false,
      removeDraftsOnLogout: false,
      initialTabCode: '',
      shouldShowInstallIde: false,
    };
  },
  computed: {
    ...mapGetters('database', ['db_getWorkspace']),
    workspace() {
      return this.db_getWorkspace(this.$route.params.workspaceId);
    },
    displayName() {
      return this.user.nickname;
    },
    displayProfileImage() {
      return this.user.photoURL;
    },
  },
  methods: {
    async beforeShow() {
      const userId = this.user.uid;
      if (userId) {
        await this.userConfigStore.fetchInstalledIdePlugin({ userId });
        this.shouldShowInstallIde = !this.userConfigStore.installedIdePlugin;
      }
    },
    isInARepo() {
      return this.$route.params.repoId;
    },
    openUserSettings() {
      this.initialTabCode = '';
      this.showUserSettingsModal = true;
    },
    installIdePlugin() {
      this.initialTabCode = userSettingsTabs.INTEGRATIONS;
      this.showUserSettingsModal = true;
    },
    draftConfirmationDialog() {
      return (
        'You are about to sign out. All your drafts will be saved.' +
        (this.isInARepo() && !this.hasDraftsInThisRepo() ? '\n You have uncommitted drafts on other repos.' : '')
      );
    },
    async prepareToSignout() {
      try {
        this.analytics.track(productEvents.SIGNOUT_CLICKED);
        if (await this.hasDrafts()) {
          this.showRemoveRepoDraftsAlert = true;
          return;
        }
      } catch (err) {
        logger.error({ err }, `could not prepare for signout, details: ${err}`);
      }
      await this.signoutHandler();
    },
    async signoutHandler() {
      const workspaceData = {
        id: this.$route.params.workspaceId,
        name: this.workspace.name,
      };
      await this.signout({ removeDraftsOnLogout: this.removeDraftsOnLogout });
      this.gitAuthStore.resetData();
      this.analytics.cloudTrack({
        identity: this.user.uid,
        event: productEvents.USER_LOGGED_OUT,
        payload: {
          'Workspace ID': workspaceData.id,
          'Workspace Name': workspaceData.name,
        },
      });
    },
    async handleDraftsAlertResult(confirm) {
      if (confirm) {
        await this.signoutHandler();
      }

      // Close alert do nothing
      this.showRemoveRepoDraftsAlert = false;
    },
  },
};
</script>

<style scoped lang="postcss">
.menu {
  padding: 8px 0;
  width: 280px;
}

.user-info-container {
  display: flex;
  padding: 8px 16px;
  align-items: center;

  .user-info {
    display: flex;
    justify-content: center;
    padding-left: 8px;
    width: 70%;
    flex-direction: column;
  }
}

.avatar.avatar {
  border: 1px solid var(--clear-white);
  cursor: pointer;
}

.user-action {
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-hover);
  }
}

.username {
  overflow: hidden;
  width: 100%;
  max-width: 145px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon.warning-icon {
  color: var(--warning-yellow-700);
}
</style>
