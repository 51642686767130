<template>
  <teleport to="body">
    <transition name="fade">
      <div v-if="show" class="alert-overlay" @click.self="handleBackgroundClick">
        <div
          ref="alert"
          class="alert"
          data-testid="confirmation-dialog"
          tabindex="0"
          role="dialog"
          @keydown.esc="close"
        >
          <header class="alert-header">
            <IconButton name="close" @click="close" />
          </header>
          <div class="alert-content centered">
            <div class="title headline2">{{ title }}</div>
            <div class="text body-L">{{ text }}</div>
            <div v-if="$slots['content']" class="extra-content">
              <slot name="content" />
            </div>
          </div>
          <div v-if="showButtons" class="actions-container">
            <slot name="actions">
              <Action v-if="showCancel" secondary class="action" data-testid="confirmation-dialog-cancel" @click="close"
                >Cancel</Action
              >
              <Action
                v-if="showConfirm"
                :variant="variant"
                class="action"
                data-testid="confirmation-dialog-confirm"
                @click="handleConfirm"
              >
                {{ confirmText }}
              </Action>
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { IconButton } from '@swimm/ui';
export const CONFIRMATION_VARIANTS = {
  DANGER: 'danger',
  SUCCESS: 'success',
};

export default {
  components: { IconButton },
  props: {
    show: { type: Boolean, default: false },
    title: { type: String, default: '' },
    variant: {
      type: String,
      default: null,
      validator: (value) => !value || Object.values(CONFIRMATION_VARIANTS).includes(value),
    },
    closeOnConfirm: { type: Boolean, default: true },
    text: { type: String, default: '' },
    showConfirm: { type: Boolean, default: true },
    confirmText: { type: String, default: 'OK' },
    showCancel: { type: Boolean, default: false },
  },
  emits: ['close', 'confirm'],
  computed: {
    showButtons() {
      return this.showConfirm || this.showCancel || this.$slots.actions;
    },
  },
  updated() {
    if (this.show) {
      // Due to a known issue that might happen when using vue-portal - https://portal-vue.linusb.org/guide/caveats.html#refs
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.alert.focus();
        });
      });
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleBackgroundClick() {
      this.close();
    },
    handleConfirm() {
      this.$emit('confirm');

      // Close on default (when empty)
      if (!this.closeOnConfirm) {
        return;
      }

      this.close();
    },
  },
};
</script>

<style scoped lang="postcss">
.alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.alert {
  z-index: 1000;
  padding: 10px;
  width: 470px;
  border-radius: 10px;
  background: var(--color-bg);
  box-shadow: var(--box-shadow-big);
  border: 2px solid var(--color-border-default-subtle);
}

.alert:focus {
  outline: none;
}

.alert-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.alert-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  word-break: break-word;
}

.title {
  margin: 10px 0;
  font-weight: bold;
}

.extra-content {
  margin-top: 20px;
}

.extra-content,
.text {
  padding: 0 20px;
}

.actions-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.actions-container .action:not(:first-child) {
  margin-left: 10px;
}
</style>
