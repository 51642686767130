import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { getLoggerNew } from '@swimm/shared';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';

const logger = getLoggerNew(__modulename);

function getQueryParam(route: ReturnType<typeof useRoute>, name: string): string {
  const value = route.params[name];
  return typeof value === 'string' ? value : value[0];
}

export function useRepoIntegrations(repoId?: string) {
  const store = useStore();
  const route = useRoute();
  const { user } = storeToRefs(useAuthStore());
  const workspaceId = getQueryParam(route, 'workspaceId');
  repoId = repoId || getQueryParam(route, 'repoId');
  if (!repoId) {
    throw new Error(`repoId not specified, but route has no repoId query param`);
  }

  const integrations = computed(() => {
    return store.getters['database/db_getClonedResource'](`repositories.${repoId}.metadata.integrations`) || {};
  });

  return {
    integrations,

    async updateIntegrations(integrationFields: Record<string, unknown>) {
      try {
        const integrationsValue = integrations.value || {};
        Object.assign(integrationsValue, integrationFields);
        const updateRepoResponse = await CloudFunctions.updateRepositoryDocumentFields({
          repoId,
          workspaceId,
          fieldsToUpdate: {
            modifier_name: user.value.nickname,
            integrations: integrationsValue,
          },
        });
        if (updateRepoResponse.data?.status !== 'success') {
          throw new Error(`update operation failed: ${JSON.stringify(updateRepoResponse.data)}`);
        } else {
          await store.dispatch('database/updateRepoMetadata', { repoId });
        }
      } catch (err) {
        logger.error(
          { err },
          `Failed to update integrations, workspaceId: ${workspaceId}, repoId: ${repoId}, props: ${JSON.stringify(
            integrationFields
          )}, error: ${err}`
        );
        throw err;
      }
    },
  };
}
