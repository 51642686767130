import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core';

export interface SwmTokenOptions {
  inMermaid?: boolean;
}

export default function swm_token(md: MarkdownIt, options?: { inMermaid?: boolean }): void {
  function transformSwmToken(state: StateCore): void {
    const blockTokens = state.tokens;

    for (let j = 0, l = blockTokens.length; j < l; j++) {
      if (blockTokens[j].type !== 'inline') {
        continue;
      }

      const tokens = blockTokens[j].children ?? [];

      for (let open = 0; open < tokens.length; open++) {
        if (tokens[open].type !== 'swm_inline_open' || tokens[open].tag !== 'SwmToken') {
          continue;
        }

        let close;
        for (close = open + 1; close < tokens.length; close++) {
          if (tokens[close].type === 'swm_inline_close' && tokens[open].tag === 'SwmToken') {
            break;
          }
        }

        if (close === tokens.length) {
          continue;
        }

        if (tokens[open].attrGet('pos') == null) {
          continue;
        }

        if (!options?.inMermaid) {
          if (tokens[open + 1].type !== 'code_inline') {
            continue;
          }

          tokens[open].type = 'swm_token';
          tokens[open].attrs ??= [];
          tokens[open].attrSet('text', tokens[open + 1].content);
          if (!tokens[open].attrGet('repo-id')) {
            tokens[open].attrSet('repo-id', state.env?.swimm?.repoId);
            tokens[open].attrSet('repo-name', state.env?.swimm?.repoName);
          }

          // Collapse the following tokens including the close token, not exact, as
          // link already strips stuff, but this is just for debugging really
          for (let i = open + 1; i < close + 1; i++) {
            tokens[open].content = tokens[open].content + tokens[i].content;
          }
          tokens.splice(open + 1, close - open);
        } else {
          // Since we don't run the backticks rule while parsing mermaid we need
          // a specialized parsing rule that will handle matching the backticks/code_inline
          // by itself
          // Based on https://github.com/markdown-it/markdown-it/blob/2b6cac25823af011ff3bc7628bc9b06e483c5a08/lib/rules_inline/backticks.js

          if (tokens[open + 1].type !== 'text') {
            continue;
          }

          const ch = tokens[open + 1].content.charCodeAt(0);

          if (ch !== 0x60 /* ` */) {
            continue;
          }

          // scan marker length
          let openerLength = 1;
          while (
            openerLength < tokens[open + 1].content.length &&
            tokens[open + 1].content.charCodeAt(openerLength) === 0x60 /* ` */
          ) {
            openerLength++;
          }

          const matchStart = tokens[open + 1].content.length - openerLength;
          let matchEnd = matchStart;
          while (
            matchEnd < tokens[open + 1].content.length &&
            tokens[open + 1].content.charCodeAt(matchEnd) === 0x60 /* ` */
          ) {
            matchEnd++;
          }

          const closerLength = matchEnd - matchStart;

          if (closerLength !== openerLength) {
            continue;
          }

          tokens[open].type = 'swm_token';
          tokens[open].attrs ??= [];
          tokens[open].attrSet(
            'text',
            tokens[open + 1].content
              .slice(openerLength, tokens[open + 1].content.length - closerLength)
              .replace(/\n/g, ' ')
              .replace(/^ (.+) $/, '$1')
          );
          if (!tokens[open].attrGet('repo-id')) {
            tokens[open].attrSet('repo-id', state.env?.swimm?.repoId);
            tokens[open].attrSet('repo-name', state.env?.swimm?.repoName);
          }

          // Collapse the following tokens including the close token, not exact, as
          // link already strips stuff, but this is just for debugging really
          for (let i = open + 1; i < close + 1; i++) {
            tokens[open].content = tokens[open].content + tokens[i].content;
          }
          tokens.splice(open + 1, close - open);
        }
      }
    }
  }

  md.core.ruler.after('inline', 'swm_token', transformSwmToken);
}

export interface TokenPos {
  line: number;
  wordStart: number;
  wordEnd: number;
}

export function parseSwmTokenPos(text: string): TokenPos {
  const splitted = text.split(':', 4);

  return {
    line: parsePosValue(splitted[0]),
    wordStart: parsePosValue(splitted[1]),
    wordEnd: parsePosValue(splitted[2]),
  };
}

export function serializeSwmTokenPos(pos: TokenPos): string {
  return `${pos.line}:${pos.wordStart}:${pos.wordEnd}`;
}

function parsePosValue(text?: string) {
  if (text == null) {
    return -1;
  }

  const int = parseInt(text, 10);
  if (Number.isNaN(int)) {
    return -1;
  }

  return int;
}
