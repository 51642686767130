<template>
  <InstanceNotification
    :title="title"
    action="Review invite requests"
    :add-notifier-to-title="true"
    :action-in-a-new-line="true"
    :notification="notification"
  />
</template>

<script>
import { trimedWorkspaceName } from '@/common/utils/workspace-utils';
import InstanceNotification from '@/modules/notifications-center/components/InstanceNotification.vue';
import { mapGetters } from 'vuex';

export default {
  components: { InstanceNotification },
  props: {
    notification: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters('database', ['db_getWorkspace']),
    title() {
      const workspaceName = trimedWorkspaceName(this.notification.topic_name);
      return `wants to join ${workspaceName}`;
    },
    skipLink() {
      return this.$route.params.workspaceId === this.workspaceId;
    },
    workspaceId() {
      return this.notification.topic_id;
    },
  },
};
</script>
