import { defineStore } from 'pinia';
import { ref } from 'vue';
import { shortUuid } from '@swimm/shared';

const DEFAULT_DURATION = 4000;

export interface Notification {
  id: string;
  duration?: number | null;
  text: string;
  icon?: string;
  image?: string;
  link?: { url: string; text: string };
  loader?: boolean;
  closeButtonText?: string;
  actionButtonText?: string;
  onCloseClick?: () => void;
  onActionClick?: () => void;
}

export interface AddNotificationParams extends Omit<Notification, 'text' | 'id'> {
  autoClose?: boolean;
}

export const useNotificationsStore = defineStore('notifications', () => {
  const notifications = ref<Notification[]>([]);

  function addNotification(
    text: string,
    { autoClose = true, duration = DEFAULT_DURATION, ...rest }: AddNotificationParams = <AddNotificationParams>{}
  ) {
    const notification: Notification = {
      id: shortUuid(),
      duration: autoClose ? duration : null,
      text,
      ...rest,
    };
    notifications.value.push(notification);

    if (notification.duration) {
      setTimeout(() => {
        removeNotification(notification.id as string);
      }, notification.duration);
    }

    return notification;
  }

  function removeNotification(id: string) {
    notifications.value = notifications.value.filter((item) => item.id !== id);
  }

  return { notifications, addNotification, removeNotification };
});
