<template>
  <div class="snippets-tabs">
    <template v-if="tabs.length > 0">
      <span
        v-for="(tab, index) of tabs"
        :key="`tab-${index}`"
        class="snippet-tab"
        data-testid="snippet-tab"
        :class="{ selected: tab.isCurrent }"
        @click="tabClicked(tab)"
      >
        <span>
          <VTooltip>
            <span>{{ shortTitle(tab) }}</span>
            <template #popper>
              <span v-if="tab.isCrossRepo">
                <Icon :name="tab.repoIcon" />{{ tab.repoName }}
                <span v-if="tab.branchName">({{ tab.branchName }})</span>
                <Icon :no-padding="true" name="arrow-right" />
              </span>
              {{ tab.path }}
            </template>
          </VTooltip>
        </span>
      </span>
    </template>
  </div>
</template>

<script>
import { reversedEllipsisFunction } from '@swimm/shared';

export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['tab-clicked'],
  data() {
    return {
      FILE_MAX_LENGTH: 30,
    };
  },
  methods: {
    shortTitle(tab) {
      const pathPart = reversedEllipsisFunction(tab.path, this.FILE_MAX_LENGTH, '...');
      if (tab.isCrossRepo) {
        return `${tab.repoName}/${pathPart}`;
      } else {
        return pathPart;
      }
    },
    tabClicked(tab) {
      if (!tab.isCurrent) {
        this.$emit('tab-clicked', tab);
      }
    },
  },
};
</script>

<style scoped>
.snippets-tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  --webkit-overflow-scrolling: touch;
  --ms-overflow-style: --ms-autohiding-scrollbar;
  margin-right: 10px;
}

.snippets-tabs::-webkit-scrollbar {
  display: none;
}

.snippets-tabs .snippet-tab {
  padding: 10px;
  cursor: pointer;
  color: var(--text-color-disable);
  flex: 0 0 auto;
}

.snippets-tabs .snippet-tab.selected {
  color: var(--text-color-primary);
  background-color: var(--color-bg);
}

.snippets-tabs .snippet-tab:hover {
  border-radius: 8px;
  color: var(--text-color-primary);
  background-color: var(--color-surface);
}
</style>
