<script setup lang="ts">
import HorizontalSpacer from '@/common/components/atoms/HorizontalSpacer.vue';
import { SwText } from '@swimm/ui';
import AccountReadyFeatures from '@/modules/onboarding/components/account-ready/AccountReadyFeatures.vue';
import { useBookDemo } from '@/modules/core/composables/book-demo';
import { useRoute, useRouter } from 'vue-router';
import { DUMMY_REPO_ID } from '@swimm/shared';

const props = defineProps<{
  isGitEnterpriseHosting?: boolean;
  selectedProvider?: string;
}>();

const router = useRouter();
const route = useRoute();
const { openCalendlyDemoLink, reportBookDemoClick, openCalendlyEnterpriseSetupLink } = useBookDemo();

function onBookDemoClick(): void {
  openCalendlyDemoLink();
  reportBookDemoClick('Account Ready page');
}
function onBookEnterpriseSetupClick(): void {
  openCalendlyEnterpriseSetupLink();
  reportBookDemoClick('Account Ready page - Enterprise git hosting');
}

function navigateToWorkspace() {
  let redirectRoute = `/workspaces/${route.params.workspaceId}`;
  if (route.query.source) {
    redirectRoute += `?source=${route.query.source}`;
  }
  if (props.isGitEnterpriseHosting) {
    redirectRoute += `/repos/${DUMMY_REPO_ID}`;
  }
  router.push(redirectRoute);
}
</script>

<template>
  <div>
    <SwText variant="headline2">Your free account is ready!</SwText>
    <SwText variant="body-L" class="subtitle">Start knowledge sharing with teams</SwText>
  </div>
  <AccountReadyFeatures :features="['1 private repository', 'Up to 5 users', `IDE & CI integration`]" />
  <Action data-testid="jump-in-button" @click="navigateToWorkspace">Jump in</Action>
  <HorizontalSpacer class="spacer" />
  <div class="footer">
    <div v-if="isGitEnterpriseHosting">
      <SwText variant="body-S"> Already want to learn about setting up {{ selectedProvider }}?</SwText>
      <SwText variant="body-S" class="link" @click="onBookEnterpriseSetupClick">Talk to an expert</SwText>
    </div>
    <div v-else>
      <SwText variant="body-S">Want a personalized onboarding experience? </SwText>
      <SwText variant="body-S" class="link" @click="onBookDemoClick">Schedule a 1:1 with an expert</SwText>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.subtitle {
  color: var(--text-color-secondary);
}

.spacer {
  align-self: stretch;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: var(--space-base);

  .link {
    color: var(--text-color-link);
    cursor: pointer;
    align-self: center;
  }
}
</style>
