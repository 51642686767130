<script lang="ts">
import { Spacing, Statuses } from '../../types';
import BaseLoading from '../BaseLoading/BaseLoading.vue';

export const ButtonVariants = [
  'primary',
  'secondary',
  'tertiary',
  'link',
  'placeholder',
  ...Statuses.filter((status) => status !== 'none'),
] as const;
export type ButtonVariantsType = (typeof ButtonVariants)[number];

export const ButtonSizes = ['small', 'large'] as const;
export type ButtonSizesType = Extract<Spacing, (typeof ButtonSizes)[number]>;

export const ButtonTypes = ['button', 'submit', 'reset', undefined] as const;
export type ButtonTypesType = (typeof ButtonTypes)[number];
</script>

<script setup lang="ts">
import { computed, useSlots } from 'vue';
import BaseLayoutIcons from '../BaseLayoutIcons/BaseLayoutIcons.vue';

const props = withDefaults(
  defineProps<{
    variant?: ButtonVariantsType;
    size?: ButtonSizesType;
    disabled?: boolean;
    fillWidth?: boolean;
    noMargin?: boolean;
    type?: ButtonTypesType;
    wrapper?: string; // Used to pass complex slots into the button (ex. set to 'div' when passing default slot with BaseProse, BaseLayoutGap etc.)
    blockContent?: boolean; // Create a button element that can contain block elements
    loading?: boolean;
  }>(),
  {
    variant: 'primary',
    size: 'large',
    type: 'button',
    wrapper: 'span',
    blockContent: false,
  }
);

const slots = useSlots();

const hasLeftIcon = computed(() => !!slots.leftIcon);
const hasRightIcon = computed(() => !!slots.rightIcon);
const hasText = computed(() => !!slots.default);

const isIconOnly = computed(() => (hasLeftIcon.value || hasRightIcon.value) && !hasText.value);

const computedClasses = computed(() => ({
  [`button--${props.variant}`]: true,
  [`button--${props.size}`]: true,
  'button--fill-width': props.fillWidth,
  'button--no-margin': props.noMargin,
  'button--disabled': props.disabled || props.loading,
  'button--has-left-icon': hasLeftIcon.value && !isIconOnly.value,
  'button--has-right-icon': hasRightIcon.value && !isIconOnly.value,
  'button--icon-only': isIconOnly.value,
}));

const container = computed(() => {
  return props.blockContent ? 'a' : 'button';
});
const containerProps = computed(() => {
  return props.blockContent ? {} : { type: props.type, disabled: props.disabled };
});
</script>

<template>
  <component :is="container" class="button" :class="computedClasses" v-bind="containerProps">
    <BaseLayoutIcons class="button__container" :wrapper="wrapper" :inner-wrappers="wrapper">
      <template v-if="hasLeftIcon" #leftIcon>
        <slot name="leftIcon" />
      </template>
      <template #default>
        <component :is="wrapper" v-if="hasText && !loading" class="button__text">
          <slot name="default" />
        </component>
        <BaseLoading v-if="loading" />
      </template>
      <template v-if="hasRightIcon" #rightIcon>
        <slot name="rightIcon" />
      </template>
    </BaseLayoutIcons>
  </component>
</template>
<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.button {
  $self: &;

  @include defaults;

  align-items: center;
  appearance: none;
  border: none;
  border-radius: var(--space-xxsmall);
  color: var(--color-text-on-dark);
  cursor: pointer;
  display: inline-flex;
  font-weight: var(--font-weight-bold);
  justify-content: center;
  text-decoration: none;
  user-select: none; /* users probably don’t need to copy button text */
  white-space: nowrap;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
  }

  &[disabled] {
    opacity: 0.7;
  }

  /**
   * This selector is used to space out a row of adjacent buttons by adding a left margin from the second sibling.
   * When this is not required, the `no-margin` modifier can be used to remove the margin.
 */
  &:not(#{$self}--fill-width):not(#{$self}--no-margin) + #{$self} {
    margin-left: var(--space-xsmall);
  }

  &--disabled {
    cursor: no-drop;
    pointer-events: none;
    opacity: 0.7;
  }

  &--fill-width {
    align-items: center;
    width: 100%;

    #{$self}__text {
      margin: 0 auto;
    }
  }

  /**
   * Size modifiers
   */
  &--large {
    font-size: var(--font-size-small);
    padding: calc(var(--space-xxxsmall) + var(--space-xxsmall)) calc(var(--space-xsmall) + var(--space-xxsmall));

    &:not(#{$self}--link) {
      height: var(--scale-xlarge);

      &#{$self}--has-left-icon {
        padding-left: var(--space-xsmall);
      }
      &#{$self}--has-right-icon {
        padding-right: var(--space-xsmall);
      }
      &#{$self}--icon-only {
        width: var(--scale-xlarge);
        position: relative;

        #{$self}__container {
          left: 50%;
          position: absolute;
          transform: translate3d(-50%, 0, 0);
        }
      }
    }
  }

  &--small {
    font-size: var(--font-size-small);
    padding: var(--space-xxsmall) var(--space-xsmall);

    &:not(#{$self}--link) {
      height: var(--scale-large);

      &#{$self}--has-left-icon {
        padding-left: var(--space-xsmall);
      }
      &#{$self}--has-right-icon {
        padding-right: var(--space-xsmall);
      }
      &#{$self}--icon-only {
        width: var(--scale-large);
        position: relative;

        #{$self}__container {
          left: 50%;
          position: absolute;
          transform: translate3d(-50%, 0, 0);
        }
      }
    }
  }

  /**
   * Variant modifiers
   */
  &--primary {
    background: var(--color-bg-brand);

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--color-bg-brand-hover);
    }
  }

  &--secondary {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px var(--color-border-default-strong);
    color: var(--color-text-secondary);

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--color-bg-surface-hover);
    }
  }

  &--placeholder {
    background-color: transparent;
    color: var(--text-color-disable);
    font-weight: var(--font-weight-regular);

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--color-bg-surface-hover);
    }
  }

  &--tertiary {
    background-color: transparent;
    color: var(--color-text-secondary);
    font-weight: var(--font-weight-regular);

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--color-bg-surface-hover);
    }
  }

  &--magic {
    background: var(--color-bg-magic);

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--color-bg-magic-hover);
    }
  }

  &--link {
    background-color: transparent;
    border-radius: 0;
    color: var(--color-text-brand);
    font-size: inherit;
    font-weight: inherit;
    display: inline-flex;
    padding: 0;

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      color: var(--color-text-brand-hover);

      #{$self}__text {
        text-decoration: underline;
      }
    }

    &#{$self}--disabled {
      opacity: 1;
      color: var(--color-text-disabled);

      #{$self}__container {
        color: var(--color-text-disabled);
      }

      #{$self}__text {
        text-decoration: none;
      }
    }

    &#{$self}--icon-only {
      #{$self}__container {
        margin-right: -#{var(--space-small)};
      }
    }
  }

  &--success {
    background-color: var(--color-bg-success-subtle);
    color: var(--color-text-success);

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--color-bg-success-subtle-hover);
    }
  }

  &--warning {
    background-color: var(--color-bg-warning-subtle);
    color: var(--color-text-warning);

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--color-bg-warning-subtle-hover);
    }
  }

  &--danger {
    background-color: var(--color-bg-danger-subtle);
    color: var(--color-text-danger);

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--color-bg-danger-subtle-hover);
    }
  }

  &--info {
    background-color: var(--color-bg-info-subtle);
    color: var(--color-text-info);

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--color-bg-info-subtle-hover);
    }
  }
}
</style>
