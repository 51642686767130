<template>
  <div>
    <v-select
      v-model="notify"
      class="notify-docs-email"
      taggable
      multiple
      no-drop
      :placeholder="PLACEHOLDER"
      :options="[]"
      :disabled="disabled"
    >
      <slot name="open-indicator"></slot>
    </v-select>
  </div>
</template>

<script>
import { useAnalytics } from '@/common/composables/useAnalytics';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { UrlUtils, eventLogger, productEvents } from '@swimm/shared';
import { mapActions, mapGetters } from 'vuex';
import swal from 'sweetalert';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { toRaw } from 'vue';
import { isEqual } from 'lodash-es';

const PLACEHOLDER = 'Enter emails to notify, comma separated, press enter to add';

export default {
  props: {
    repoId: { type: String, required: true },
    workspaceId: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  setup() {
    const analytics = useAnalytics();
    const { logEvent } = useSwimmEventLogs();
    const { user } = storeToRefs(useAuthStore());

    return { user, analytics, logEvent, PLACEHOLDER };
  },
  data() {
    return {
      notify: [],
    };
  },
  watch: {
    notify: {
      async handler(newNotifyValue, oldNotifyValue) {
        if (isEqual(toRaw(newNotifyValue), toRaw(oldNotifyValue))) {
          return;
        }
        await this.updateNotifyList(newNotifyValue);
      },
    },
  },
  computed: {
    ...mapGetters('database', ['db_getRepoMetadata']),
    repoMetadata() {
      return this.db_getRepoMetadata(this.repoId);
    },
  },
  created() {
    if (this.repoMetadata) {
      this.notify = this.repoMetadata.notify || [];
    } else {
      this.$logger.error(
        `repo metadata is not available for ${this.repoId}. Using default integrations values instead`
      );
    }
  },
  methods: {
    ...mapActions('database', ['updateRepoMetadata']),
    async updateNotifyList(unfilteredEmailsToNotify) {
      try {
        const newEmailList = unfilteredEmailsToNotify.filter((email) => UrlUtils.isValidEmail(email));
        const updateRepoResponse = await this.updateNotifyListOnRepo(newEmailList);
        if (updateRepoResponse.data.status !== 'success') {
          throw new Error('Updating Doc Notification failed!');
        }
        this.notify = newEmailList;
        this.reportNotifyListChanged(newEmailList);
        this.logNotifyListChanged(newEmailList);
        await this.updateMetadata();
      } catch (err) {
        const title = 'Failed to update the Doc Notification Recipients List';
        this.$logger.error({ err }, `${title}: ${err}`);
        await swal({ title, content: SWAL_CONTACT_US_CONTENT() });
      }
    },
    async updateNotifyListOnRepo(newList) {
      return await CloudFunctions.updateRepositoryDocumentFields({
        repoId: this.repoId,
        workspaceId: this.workspaceId,
        fieldsToUpdate: {
          modifier_name: this.user.nickname,
          notify: newList,
        },
      });
    },
    reportNotifyListChanged(newList) {
      const newListLength = newList.length;
      const event =
        newListLength > this.notify.length
          ? productEvents.DOC_NOTIFICATION_INTEGRATION_LIST_ADDED
          : productEvents.DOC_NOTIFICATION_INTEGRATION_LIST_REMOVED;
      this.analytics.track(event, {
        'Total Emails': newListLength,
        'Slack Integration Email Available': this.isEmailListContainSlackIntegration(newList),
        'Workspace ID': this.workspaceId,
      });
    },
    logNotifyListChanged(newList) {
      const swimmEvent =
        newList.length > this.notify.length
          ? eventLogger.SWIMM_EVENTS.REPOSITORY_DOC_NOTIFICATION_RECIPIENT_ADDED
          : eventLogger.SWIMM_EVENTS.REPOSITORY_DOC_NOTIFICATION_RECIPIENT_REMOVED;
      this.logEvent(swimmEvent, { srcId: this.repoMetadata.id, srcName: this.repoMetadata.name });
    },
    isEmailSlackIntegration(email) {
      return email.match(/slack.com/);
    },
    isEmailListContainSlackIntegration(emailList) {
      return emailList.some((email) => this.isEmailSlackIntegration(email));
    },
    async updateMetadata() {
      await this.updateRepoMetadata({ repoId: this.repoId });
    },
  },
};
</script>

<style scoped>
.tutorial-line {
  margin-top: 16px;
  font-size: var(--body-XS);
  color: var(--text-color-primary);
}

.tutorial-line a {
  font-weight: 700;
  color: var(--text-color-link);
}

.tutorial-line a:hover,
.tutorial-line a:focus {
  text-decoration: underline;
}
</style>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style>
.notify-docs-email.v-select:not(.vs--searching) .vs__dropdown-menu {
  border: none;
  background-color: transparent;
}

.notify-docs-email.v-select .vs__dropdown-toggle {
  padding: 0;
}

.notify-docs-email.v-select .vs__selected-options {
  padding: 0 5px 5px 5px;
}

.notify-docs-email.v-select .vs__selected {
  margin: 5px 5px 0 0;
  color: var(--text-color-primary);
  background-color: var(--color-hover);

  button {
    fill: var(--text-color-secondary);
  }
}

.notify-docs-email.v-select .vs__search {
  color: var(--text-color-primary);
}
</style>
