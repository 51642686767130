import * as _ from 'lodash-es';
import { parts } from '../utils/path-utils';
import { isPlural, singular } from 'pluralize';

export const getComponentNameFromPath = (path: string): string => {
  const allParts = parts(path)
    .flatMap((part) => part.split('_'))
    .flatMap((part) => part.split('-'))
    .flatMap((part) => part.split('.'))
    .filter(
      (part, i, parts) =>
        parts.length === 1 || !['src', 'lib', 'include', 'static', 'public', 'packages'].includes(part)
    )
    // Omit duplicate names (such as in Infection Monkey - /monkey/monkey_island/...)
    .filter((part, i, parts) => parts[i - 1] !== part)
    .map((part) => _.capitalize(part));

  // 'Packages Swimmagic' -> 'Swimmagic Package'
  if (isPlural(allParts[0]) && allParts.length > 1) {
    const [denom] = allParts.splice(0, 1);
    allParts.splice(1, 0, singular(denom));
  }
  return allParts.join(' ');
};
