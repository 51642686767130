<template>
  <div class="thanks-popup">
    <div v-for="thank in otherThanksSorted" :key="thank.creator" class="thank-line">
      <div class="avatar-with-name">
        <SwAvatar :text="thank.creator_name" />
        {{ thank.creator_name }}
      </div>
      <div class="emoji">
        {{ emoji(thank.code) }}
      </div>
    </div>
  </div>
</template>

<script>
import { getEmoji, sortThanks } from '@/modules/doc-contributions/services/say-thanks-utils';
import { SwAvatar } from '@swimm/ui';

export default {
  components: {
    SwAvatar,
  },
  props: {
    thanks: { type: Array, required: true },
  },
  computed: {
    otherThanksSorted() {
      return sortThanks(this.thanks);
    },
  },
  methods: {
    emoji(code) {
      return getEmoji(code);
    },
  },
};
</script>

<style scoped>
.thanks-popup {
  padding: var(--space-base);
}

.avatar-with-name {
  display: flex;
  align-items: center;
  gap: var(--space-xs);
}

.thank-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--space-xs);
}

.emoji {
  margin-left: var(--space-xs);
}
</style>
