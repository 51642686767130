import type { DbWrapperInterface } from '@swimm/editor';
import { type Repo, SwmSymbolLinkType, config, generateSwmdFileName } from '@swimm/shared';
import type { Link } from '@swimm/reefui';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { fetchAvailableDocs, fetchAvailablePlaylists } from '@/modules/editor3/links';

export function useRepoLinks(dbWrapper: DbWrapperInterface) {
  const drafts3Store = useDrafts3Store();
  const reposStore = useReposStore();

  async function getRepoLinks(repoId: string, branch: string, linkType: SwmSymbolLinkType): Promise<Link[]> {
    switch (linkType) {
      case SwmSymbolLinkType.Doc: {
        const links = await fetchAvailableDocs(dbWrapper, repoId, branch);

        if (drafts3Store.repoId === repoId) {
          for (const draft of drafts3Store.drafts.values()) {
            if (draft.isNew && draft.type === 'doc') {
              links.push({
                id: draft.id,
                icon: 'doc',
                name: draft.content.title ?? 'Untitled',
                isDraftLink: true,
                path: `/${config.SWM_FOLDER_IN_REPO}/${generateSwmdFileName(
                  draft.id,
                  draft.content.title ?? 'Untitled'
                )}${config.SWMD_FILE_EXTENSION}`,
              });
            }
          }
        }

        return links;
      }

      case SwmSymbolLinkType.Playlist: {
        const links = await fetchAvailablePlaylists(dbWrapper, repoId, branch);

        if (drafts3Store.repoId === repoId) {
          for (const draft of drafts3Store.drafts.values()) {
            if (draft.isNew && draft.type === 'playlist') {
              links.push({
                id: draft.id,
                icon: 'playlist',
                name: draft.content.name,
                isDraftLink: true,
                path: `/${config.SWM_FOLDER_IN_REPO}/${generateSwmdFileName(draft.id, draft.content.name)}${
                  config.SWMD_PLAYLIST_EXTENSION
                }`,
              });
            }
          }
        }

        return links;
      }

      default:
        return [];
    }
  }

  function getRepo(repoId: string): Repo | undefined {
    // TODO O(n) not efficient
    return reposStore.repos.find((repo) => repo.id === repoId);
  }

  function getRepoName(repoId: string): string | undefined {
    return getRepo(repoId)?.name;
  }

  return {
    getRepoLinks,
    getRepo,
    getRepoName,
  };
}
