<script setup lang="ts">
import { RepoPageRouteNames } from '@/common/consts';
import HelpTooltip from '@/modules/core/components/HelpTooltip.vue';
import { useGlobalSearchStore } from '@/modules/search/stores/globalSearchStore';
import { useRoute } from 'vue-router';
import SearchButton from '@/common/components/atoms/SearchButton.vue';
import type { Tab } from '@swimm/ui';
import { Icon, SwText, Tabs } from '@swimm/ui';
import RepoFilters from '@/modules/core/filters-row/RepoFilters.vue';
import type { PropType } from 'vue';

const props = defineProps({
  tabs: { type: Array as PropType<Tab[]>, required: true },
  repoId: { type: String, required: true },
  showSearch: { type: Boolean, default: false },
  showFilters: { type: Boolean, default: false },
  tabsDisabled: { type: Boolean, default: false },
  currentTabIndex: { type: Number, required: true },
});

const emit = defineEmits(['tab-selected']);
const route = useRoute();
const globalSearchStore = useGlobalSearchStore();

function openGlobalSearch() {
  globalSearchStore.showRepoSearch({ repoId: route.params.repoId as string, branch: route.params.branch as string });
}

function handleTabSelection(tabIndex) {
  const tabSelected = props.tabs[tabIndex];
  // @ts-ignore
  emit('tab-selected', tabSelected.key);
}

function isNeedsReviewTab(tab) {
  return tab.key === RepoPageRouteNames.NEEDS_REVIEW;
}

function shouldShowReviewChangedHelpTooltip(tab) {
  return isNeedsReviewTab(tab) && tab.notification;
}

function clickedNeedsReviewTabTooltip(event) {
  // Avoiding the response of clicking on the needs-review tab
  event.stopPropagation();
}
</script>

<template>
  <div class="repo-tabs">
    <Tabs
      class="tabs"
      :tabs="tabs"
      :model-value="currentTabIndex"
      :readonly="tabsDisabled"
      @update:model-value="handleTabSelection"
    >
      <template #hotspot-placeholder="{ tab }">
        <HelpTooltip
          v-if="shouldShowReviewChangedHelpTooltip(tab)"
          class="doc-needs-review-changed-hotspot"
          tooltip-key="your-docs-need-updating"
          placement="top"
          title="Your docs need updating"
          @click="clickedNeedsReviewTabTooltip"
        >
          <template #subtitle>
            Some docs need your attention as the code in them has changed. We will help you fix them quickly!
          </template>
        </HelpTooltip>
      </template>
    </Tabs>
    <div class="right-side">
      <RepoFilters v-if="showFilters" :repo-id="repoId" />
      <SearchButton v-if="showSearch" @search="openGlobalSearch" :tooltip-position="'top'" repo-scope>
        <SwText variant="body-S" class="search-button">
          <Icon name="search" no-padding />
          Search</SwText
        >
      </SearchButton>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.search-button {
  display: flex;
  gap: var(--space-xs);
}

.repo-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0 32px 0;
  border-bottom: 2px solid var(--color-border-default-subtle);

  .tabs {
    margin-bottom: 5px;
  }

  .right-side {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--space-base);
  }
}

.doc-needs-review-changed-hotspot {
  position: absolute;
  top: -120%;
  width: 100%;
}
</style>
