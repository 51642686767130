import { defineStore } from 'pinia';
import LocalStorage from '@/local-storage';
import { ref } from 'vue';
import { localStorageKeys } from '@/common/consts';
import { STORES } from '@/modules/core/stores/store-constants';

const { SHOULD_SHOW_DOC_SIDEBAR } = localStorageKeys;

export type DocSidebarTab = 'general' | 'content-suggestions' | 'automation';

export const useDocSidebarStore = defineStore(STORES.DOC_SIDEBAR, () => {
  const isOpen = ref(LocalStorage.get(SHOULD_SHOW_DOC_SIDEBAR) !== false);
  const setOpen = ({ open, remember = false }: { open: boolean; remember?: boolean }) => {
    isOpen.value = open;
    if (remember && LocalStorage.get(SHOULD_SHOW_DOC_SIDEBAR) !== isOpen.value) {
      LocalStorage.set(SHOULD_SHOW_DOC_SIDEBAR, isOpen.value);
    }
  };

  const currentTab = ref<DocSidebarTab>('general');
  const setCurrentTab = (tab: DocSidebarTab) => {
    currentTab.value = tab;
  };

  const userDocSidebarStateValue = () => {
    return LocalStorage.get(SHOULD_SHOW_DOC_SIDEBAR) !== null;
  };

  const reset = () => {
    setOpen({ open: LocalStorage.get(SHOULD_SHOW_DOC_SIDEBAR) || true });
    setCurrentTab('general');
  };

  return {
    isOpen,
    setOpen,
    currentTab,
    setCurrentTab,
    reset,
    userDocSidebarStateValue,
  };
});
