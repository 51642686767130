// @ts-strict
import {
  GitProviderName,
  type SwimmDocument,
  SwmResourceType,
  config,
  convertSWMDtoSWM,
  extractResourceIdFromPath,
  getFuseOptions,
  getFuseQuery,
  getLoggerNew,
  gitwrapper,
} from '@swimm/shared';
import Fuse from 'fuse.js';
import { getRepoPath } from '@/router/router-utils';
import { LegacySwmdError, getNodeTextLines, parseSwmd, schema } from '@swimm/swmd';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { type FuseSearchDoc, SEARCH_INDEX_CACHE, type SearchCacheKey } from './search-defs';
import { parsePlaylistContentFromSwm } from '@/common/utils/playlist-utils';

const logger = getLoggerNew(__modulename);

// parses the swmd (new or old)
// and returns SwimmDocument
// we pass the list of repos as empty list
function parseSwmdOrLegacy({
  workspaceId,
  repoId,
  repoName,
  docText,
}: {
  workspaceId: string;
  repoId: string;
  repoName: string;
  docText: string;
}): SwimmDocument {
  try {
    return parseSwmd(docText);
  } catch (err) {
    if (err instanceof LegacySwmdError) {
      return parseSwmd(docText, {
        legacy: {
          baseUrl: config.BASE_URL,
          workspaceId,
          repoId: repoId,
          repoName: repoName,
          repos: [],
        },
      });
    }
    throw err;
  }
}

/* transform document (v2 or v3) into fuse doc 
  uses the new swmd-v3 parsing with legacy options if required
*/

function docToFuseDoc({
  workspaceId,
  repoId,
  repoName,
  content,
  path,
  revision,
}: {
  workspaceId: string;
  repoId: string;
  repoName: string;
  content: string;
  path: string;
  revision: string;
}): FuseSearchDoc | undefined {
  const unitId = extractResourceIdFromPath(path);
  const swmLink = `${getRepoPath(workspaceId, repoId, revision)}/docs/${unitId}`;
  const swimmDocument = parseSwmdOrLegacy({ workspaceId, repoId, repoName, docText: content });
  const rootNode = ProseMirrorNode.fromJSON(schema, swimmDocument.content);
  const textContent = getNodeTextLines(rootNode);

  return {
    id: unitId,
    name: swimmDocument.title ?? '',
    repoName,
    type: SwmResourceType.Doc,
    link: swmLink,
    content: textContent,
  };
}

/* transform playlist markdown into fuse doc
  uses legacy swm parser
*/
function playlistToFuseDoc({
  workspaceId,
  repoId,
  repoName,
  content,
  revision,
}: {
  workspaceId: string;
  repoId: string;
  repoName: string;
  content: string;
  revision: string;
}): FuseSearchDoc | undefined {
  const convertedSwm = convertSWMDtoSWM(content, repoId);
  if (convertedSwm.code !== config.SUCCESS_RETURN_CODE) {
    throw new Error(convertedSwm.errorMessage);
  }
  const playlist = parsePlaylistContentFromSwm(convertedSwm.swm, repoId);
  if (!playlist?.id) {
    return undefined;
  }
  const swmLink = `${getRepoPath(workspaceId, repoId, revision)}/playlists/${playlist.id}`;
  const lines: string[] = [];
  if (playlist.description) {
    lines.push(...playlist.description.split('\n'));
  }
  if (playlist.summary) {
    lines.push(...playlist.summary.split('\n'));
  }
  return {
    id: playlist.id,
    name: playlist.name,
    repoName,
    type: SwmResourceType.Playlist,
    link: swmLink,
    content: lines,
  };
}

export async function populateSearchIndexCache({
  workspaceId,
  repoId,
  repoName,
  revision,
}: {
  workspaceId: string;
  repoId: string;
  repoName: string;
  revision: string;
}) {
  const cacheKey: SearchCacheKey = `R${repoId}-H${revision}`;
  const cacheHit = SEARCH_INDEX_CACHE.get(cacheKey);
  logger.info(`populateSearchIndexCache for ${workspaceId} ${repoId} ${repoName} ${revision} cacheHit=${!!cacheHit}`);
  if (cacheHit) {
    return cacheHit;
  }
  const allSwmContent = await gitwrapper.getAllSwmFilesContent({ repoId, revision });

  function isFuseSearchDoc(fuseDoc: FuseSearchDoc | undefined): fuseDoc is FuseSearchDoc {
    return !!fuseDoc;
  }

  const textDocs: FuseSearchDoc[] = allSwmContent
    .map((result) => {
      // If it's not a SWMD file (and explicitly _not_ a template / playlist)
      if (
        !result.path.endsWith(config.SWMD_FILE_EXTENSION) ||
        result.path.endsWith(config.SWMD_TEMPLATE_FILE_EXTENSION)
      ) {
        return undefined;
      }
      const isPlaylist = result.path.endsWith(config.SWMD_PLAYLIST_EXTENSION);
      try {
        if (isPlaylist) {
          return playlistToFuseDoc({ content: result.content, repoId, workspaceId, repoName, revision });
        } else {
          return docToFuseDoc({
            path: result.path,
            content: result.content,
            repoId,
            workspaceId,
            repoName,
            revision,
          });
        }
      } catch (err) {
        logger.warn(`Failed to textualize ${result.path}: ${err}`);
        return undefined;
      }
    })
    .filter(isFuseSearchDoc);

  const fuseOptions = getFuseOptions();
  const fuseIndex = Fuse.createIndex(fuseOptions.keys, textDocs);
  SEARCH_INDEX_CACHE.set(cacheKey, fuseIndex);
  return fuseIndex;
}

export async function searchFuse({
  workspaceId,
  repoId,
  repoName,
  query,
  revision,
  repoProvider,
}: {
  workspaceId: string;
  repoId: string;
  repoName: string;
  query: string;
  revision: string;
  repoProvider: GitProviderName;
}) {
  try {
    const fuseOptions = getFuseOptions({ query });
    const fuseIndex = await populateSearchIndexCache({ workspaceId, repoId, repoName: repoName, revision });
    // @ts-ignore // Fuse types don't recognize `docs` as a field of the index.
    const fuse = new Fuse(fuseIndex.docs, fuseOptions, fuseIndex);
    const fuseQuery = getFuseQuery(query);
    const results = fuse.search(fuseQuery);
    return results.map((result) => ({ ...result, repoId, repoProvider }));
  } catch (err) {
    logger.error({ err }, `Failed to complete search index: ${err}`);
    return [];
  }
}

export async function searchFuseWithBranch({
  workspaceId,
  repoId,
  repoName,
  query,
  branch,
  repoProvider,
}: {
  workspaceId: string;
  repoId: string;
  repoName: string;
  query: string;
  branch: string;
  repoProvider: GitProviderName;
}) {
  return await searchFuse({ workspaceId, repoId, repoName, query, revision: branch, repoProvider });
}
