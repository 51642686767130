import { v4 as uuidv4 } from 'uuid';
import { computed, onBeforeUnmount, ref, watch } from 'vue';

class PageTitleHandler {
  private stack = [];
  constructor() {
    this.stack = [];
  }

  updateFragment(id, title) {
    for (const entry of this.stack) {
      if (entry.id === id) {
        entry.title = title;
        this.updateTitle();
        return;
      }
    }
    this.stack.push({ id, title });
    this.updateTitle();
  }

  removeFragment(id) {
    for (const [index, entry] of this.stack.entries()) {
      if (entry.id === id) {
        this.stack.splice(index, 1);
        this.updateTitle();
        return;
      }
    }
  }

  getTitle() {
    if (this.stack.length === 0) {
      return 'Swimm';
    }
    return `${this.stack.map((entry) => entry.title).join(': ')} | Swimm`;
  }

  updateTitle() {
    document.title = this.getTitle();
  }
}

const pageTitleHandler = new PageTitleHandler();

export function usePageTitle() {
  const pageTitle = ref('');
  const pageTitleId = computed(() => uuidv4());
  watch(
    pageTitle,
    () => {
      if (pageTitle.value) {
        pageTitleHandler.updateFragment(pageTitleId.value, pageTitle.value);
      } else {
        pageTitleHandler.removeFragment(pageTitleId.value);
      }
    },
    { immediate: true }
  );
  onBeforeUnmount(() => {
    pageTitleHandler.removeFragment(pageTitleId.value);
  });

  return { pageTitle };
}
