import { LICENSES_WITHOUT_SEATS } from './consts';
import { StiggFeatures } from '../types';
import { OpenAIModelName, countTokens, openAIModelValues } from '../code-analysis';
import { getLoggerNew } from '#logger';

const logger = getLoggerNew("packages/shared/src/billing/utils.ts");

export function getUserEntitlementByLicense(workspaceLicense) {
  return LICENSES_WITHOUT_SEATS.includes(workspaceLicense) ? StiggFeatures.USER_COUNT : StiggFeatures.SEAT_COUNT;
}

export const GENERATIVE_AI_CAP_MULTIPLIER = 20;
export const TEXT_COMPLETION_CAP_MULTIPLIER = 1000000; // meter caps do not allow floating number increments. Multiply usage and cap by 1000000 to get to reasonable natural numbers

export function computeAiModelRequestedUsage(
  featureId: StiggFeatures,
  requestPrompt: string[],
  model: OpenAIModelName,
  maxTokens?: number
): number {
  switch (featureId) {
    case StiggFeatures.TEXT_COMPLETION_CAP: {
      const promptTokens = requestPrompt.reduce((count, prompt) => count + countTokens(prompt, model), 0);
      const completionTokens = maxTokens ?? parseInt(openAIModelValues[model].maxTokenCount);

      return Math.round(
        (promptTokens * (parseFloat(openAIModelValues[model].usdPer1000InputTokens) / 1000) +
          completionTokens * (parseFloat(openAIModelValues[model].usdPer1000OutputTokens) / 1000)) *
          TEXT_COMPLETION_CAP_MULTIPLIER
      );
    }
    case StiggFeatures.GENERATIVE_AI_CAP:
      return 1;
    default:
      throw new Error(`Usage cannot be inferred: calculate usage not implemented for ${featureId}`);
  }
}

export function computeAiModelConsumedUsage(
  featureId: StiggFeatures,
  model: OpenAIModelName,
  requestTokens?: number,
  completionTokens?: number
): number {
  switch (featureId) {
    case StiggFeatures.TEXT_COMPLETION_CAP: {
      if (!requestTokens) {
        logger.error(`Usage cannot be inferred: Request tokens are required to calculate usage for ${featureId}`);
        return 0;
      }
      return Math.round(
        (requestTokens * (parseFloat(openAIModelValues[model].usdPer1000InputTokens) / 1000) +
          (completionTokens ?? 0) * (parseFloat(openAIModelValues[model].usdPer1000OutputTokens) / 1000)) *
          TEXT_COMPLETION_CAP_MULTIPLIER
      );
    }
    case StiggFeatures.GENERATIVE_AI_CAP:
      return 1;
    default:
      throw new Error(`Usage cannot be inferred: calculate usage not implemented for ${featureId}`);
  }
}
