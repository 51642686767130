<template>
  <VDropdown :triggers="[trigger]" placement="top">
    <span class="icon-container" @[mouseEvent]="triggerEvent">
      <Icon :name="icon" class="info-icon" />
    </span>
    <template #popper>
      <slot></slot>
    </template>
  </VDropdown>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { Icon } from '@swimm/ui';

export default defineComponent({
  props: {
    trigger: { type: String, default: 'click' },
    fill: { type: Boolean, default: false },
  },
  components: { Icon },
  setup(props, { emit }) {
    const mouseEvent = computed(() => (props.trigger === 'hover' ? 'mouseover' : props.trigger));
    function triggerEvent() {
      emit('triggerEvent');
    }
    const icon = computed(() => (props.fill ? 'info-fill' : 'info-outline'));
    return { mouseEvent, triggerEvent, icon };
  },
});
</script>

<style scoped>
/* Prevents the icon from having weird padding above it. */
.icon-container {
  display: flex;
}
</style>
