import { Node } from '@tiptap/core';
import type { SuggestionProps } from '@tiptap/suggestion';
import Suggestion from '@tiptap/suggestion';
import { PluginKey } from '@tiptap/pm/state';

export interface CustomCommandOptions {
  slashCommandName: string;
  shouldDeleteRange: boolean;
  commandEntered: (props: SuggestionProps) => void;
}

export const CustomCommandExtension = Node.create<CustomCommandOptions>({
  name: 'CustomCommandExtension',

  addOptions() {
    return {
      slashCommandName: '',
      shouldDeleteRange: true,
      shouldNotAllow: [],
      commandEntered() {
        return;
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pluginKey: new PluginKey(this.name + '::' + this.options.slashCommandName),
        command: () => {
          throw new Error('Not implemented');
        },
        allowSpaces: false,
        startOfLine: false,
        char: `/${this.options.slashCommandName} `,
        render: () => {
          return {
            onStart: (props) => {
              if (this.options.shouldDeleteRange) {
                this.editor.commands.deleteRange(props.range);
              }
              return this.options.commandEntered(props);
            },
          };
        },
        items: () => {
          return [this.options.slashCommandName];
        },
      }),
    ];
  },
});
