import { localStorageKeys } from '@/common/consts';
import { escape } from 'lodash-es';
import { SWIMM_DOC_SITE_GETTING_STARTED, SWIMM_DOC_SITE_GIT_PROVIDERS_FAQ } from '@/config';

export enum REPO_FILTERS_SORT_OPTIONS {
  LAST_MODIFIED = 'Last modified',
  NAME = 'Name',
  STATUS = 'Status',
  AUTHOR = 'Author',
}

export const REPO_FILTERS_SORT_OPTIONS_PROPS = {
  [REPO_FILTERS_SORT_OPTIONS.LAST_MODIFIED]: 'modified.seconds',
  [REPO_FILTERS_SORT_OPTIONS.NAME]: 'name',
  [REPO_FILTERS_SORT_OPTIONS.AUTHOR]: 'creator_name',
  [REPO_FILTERS_SORT_OPTIONS.STATUS]: 'applicability',
};

// Creates the support text + url for SWAL
export function SWAL_CONTACT_US_CONTENT() {
  const el = document.createElement('div');
  el.innerHTML = "Refer to our <a target='_blank' href='https://docs.swimm.io/faq'>FAQs</a> for further help";
  return el;
}

export function SWAL_READ_GETTING_STARTED_CONTENT() {
  const el = document.createElement('div');
  el.innerHTML = `Read our <a target='_blank' href='${SWIMM_DOC_SITE_GETTING_STARTED}'>getting started guide</a> to learn more about docs and branches.`;
  return el;
}

export function SWAL_LIST_CONTENT(items: string[]) {
  const el = document.createElement('div');
  el.innerHTML = `<ul style="text-align: start">${items.map((item) => `<li>${escape(item)}</li>`).join('')}</ul>`;
  return el;
}

export function SWAL_BITBUCKET_DC_DELETE(resourceNames: string[]): HTMLDivElement {
  const el = document.createElement('div');
  const isDeletingOneItem = resourceNames.length === 1;
  const content = `Unfortunately, Bitbucket DataCenter doesn't support deleting docs.
      Please delete the ${isDeletingOneItem ? 'file' : 'files'} "${escape(
    isDeletingOneItem ? resourceNames[0] : resourceNames.join(', ')
  )}" in your IDE and commit to the relevant branch. <a target='_blank' href='${SWIMM_DOC_SITE_GIT_PROVIDERS_FAQ}'>Learn more.</a>`;
  el.innerHTML = content;
  return el;
}

export const REDIRECT_MARKUP = `
  <html>
  <head>
      <link rel="stylesheet" type="text/css"
          href="https://fonts.googleapis.com/css2?family=Mulish&display=swap">
  </head>
  <body style="font-family: Muli, sans-serif">
      <div style='
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 100%;
              margin-top: 20px;
      '>
          <div style='
              --loader-size: 150px;
              background-image: url(https://swimm.io/img/loading.gif);
              background-size: var(--loader-size);
              background-repeat: no-repeat;
              background-position: center;
              z-index: 1;
              min-height: var(--loader-size);
              min-width: var(--loader-size);
          '>
          </div>
          <br />
          Redirecting to GitHub
      </div>
  </body>
  </html>
`;

export const MARKDOWN_PR_SUFFIX = `---
  \n\n
  Have any questions? Check out [Swimm docs](https://docs.swimm.io/) and [join our Slack Community](https://swimm.io/slack).`;

export const SOURCE_PROPERTIES_PARAMS = [
  localStorageKeys.UTM_CAMPAIGN,
  localStorageKeys.UTM_MEDIUM,
  localStorageKeys.UTM_SOURCE,
];
