import type { DbItem, DbWrapperInterface } from '@swimm/editor';
import type { Link } from '@swimm/reefui';
import { firestoreCollectionNames } from '@swimm/shared';
import { type SwimmDocumentEntry, SwimmFileType, listSwimmDocuments } from '../drafts3/docs';

type AllowedCollectionTypes = typeof firestoreCollectionNames.SWIMMS | typeof firestoreCollectionNames.PLAYLISTS;

async function getCollectionByType(
  dbWrapper: DbWrapperInterface,
  collectionType: AllowedCollectionTypes,
  repoId: string
) {
  return dbWrapper.getCollectionDocs([firestoreCollectionNames.REPOSITORIES, repoId, collectionType]);
}

function calculateIcon(repoItem: SwimmDocumentEntry): string {
  switch (repoItem.type) {
    case SwimmFileType.PLAYLIST:
      return 'playlist';
    case SwimmFileType.SWMD:
    default:
      return 'doc';
  }
}

function joinRepoAndDbItems(repoItems: Record<string, SwimmDocumentEntry>, dbItems: DbItem[]): Link[] {
  const result = [];

  for (const dbItem of dbItems) {
    const repoItem = repoItems[dbItem.id];

    // If the doc doesn't actually exist in the repo
    if (repoItem == null) {
      continue;
    }

    result.push({
      id: dbItem.id,
      icon: calculateIcon(repoItem),
      name: dbItem.name,
      path: `/${repoItem.path}`,
    });
  }

  return result;
}

export async function fetchAvailableDocs(
  dbWrapper: DbWrapperInterface,
  repoId: string,
  branch: string
): Promise<Link[]> {
  const [repoItems, dbItems] = await Promise.all([
    listSwimmDocuments(repoId, branch),
    getCollectionByType(dbWrapper, firestoreCollectionNames.SWIMMS, repoId).then((dbItems) =>
      // We have some other types of docs in the swimms collections
      dbItems.filter((dbItem: DbItem) => dbItem.type === 'unit')
    ),
  ]);

  return joinRepoAndDbItems(repoItems, dbItems);
}

export async function fetchAvailablePlaylists(
  dbWrapper: DbWrapperInterface,
  repoId: string,
  branch: string
): Promise<Link[]> {
  const [repoItems, dbItems] = await Promise.all([
    listSwimmDocuments(repoId, branch),
    getCollectionByType(dbWrapper, firestoreCollectionNames.PLAYLISTS, repoId),
  ]);

  return joinRepoAndDbItems(repoItems, dbItems);
}
