// @ts-ignore
import { isMac } from '@swimm/shared';

export const KEYSTROKES = {
  CTRL: 'ctrl',
  COMMAND: 'cmd',
  SLASH_COMMAND: '/',
  SHIFT: 'shift',
  ENTER: '⏎',
  ESC: 'esc',
  ARROW_KEYS: 'arrow keys',
  BACKTICK: 'backtick `',
  ANY_TOKEN: '`any-token`',
  SPACE: 'space',
  PASTE: 'v',
  H1: '#',
  H2: '##',
  H3: '###',
  ASTERISK: '*',
  ORDERED_LIST: '1.',
  QUOTE: '>',
  CODE_BLOCK: '```',
  MENTION: '@',
};

const commandOrCtrl = isMac() ? KEYSTROKES.COMMAND : KEYSTROKES.CTRL;

export const KEYBOARD_SHORTCUTS = {
  SNIPPET_STUDIO: [
    { text: 'Highlight', keystroke: KEYSTROKES.SHIFT, additionalKeystrokes: [KEYSTROKES.ARROW_KEYS] },
    { text: 'Add references', keystroke: KEYSTROKES.SHIFT, additionalKeystrokes: [KEYSTROKES.ENTER] },
    { text: 'Add reference and close', keystroke: KEYSTROKES.ENTER },
    { text: 'Clear or close panel', keystroke: KEYSTROKES.ESC },
  ],
  SMART_TOKENS: [
    { text: 'Show token suggestions', keystroke: KEYSTROKES.BACKTICK },
    { text: 'Clear suggestions', keystroke: KEYSTROKES.ESC },
    { text: 'Use basic code token', keystroke: KEYSTROKES.ANY_TOKEN },
  ],
  EDITOR: [
    { text: 'Paste Markdown', keystroke: commandOrCtrl, additionalKeystrokes: [KEYSTROKES.SHIFT, KEYSTROKES.PASTE] },
    { text: 'Paste HTML', keystroke: commandOrCtrl, additionalKeystrokes: [KEYSTROKES.PASTE] },
    { text: 'Heading 1', keystroke: KEYSTROKES.H1, additionalKeystrokes: [KEYSTROKES.SPACE] },
    { text: 'Heading 2', keystroke: KEYSTROKES.H2, additionalKeystrokes: [KEYSTROKES.SPACE] },
    { text: 'Heading 3', keystroke: KEYSTROKES.H3, additionalKeystrokes: [KEYSTROKES.SPACE] },
    { text: 'Bullet list', keystroke: KEYSTROKES.ASTERISK, additionalKeystrokes: [KEYSTROKES.SPACE] },
    { text: 'Ordered list', keystroke: KEYSTROKES.ORDERED_LIST, additionalKeystrokes: [KEYSTROKES.SPACE] },
    { text: 'Quotes', keystroke: KEYSTROKES.QUOTE, additionalKeystrokes: [KEYSTROKES.SPACE] },
    { text: 'Codeblock', keystroke: KEYSTROKES.CODE_BLOCK, additionalKeystrokes: [KEYSTROKES.SPACE] },
    { text: 'Mention', keystroke: KEYSTROKES.MENTION },
  ],
};
