import type { DismissedSuggestion } from '@/modules/swimmport/services/swimmport-local-db';
import {
  getDismissedSuggestionsForDocument,
  saveDismissedSuggestion as saveDismissedSuggestionInDB,
} from '@/modules/swimmport/services/swimmport-local-db';

import * as firestore from '@/adapters-common/firestore-wrapper';
import { config } from '@swimm/shared';

async function getUserFromDb(userId) {
  const res = await firestore.getDocFromCollection(firestore.collectionNames.USERS, userId);
  if (res.code !== config.SUCCESS_RETURN_CODE) {
    return null;
  }
  return res.data;
}

export default {
  async initializeSwimmportStore({ commit }, { userId }) {
    const user = await getUserFromDb(userId);
    commit('SET_ENABLED', user?.swimmport_enabled ?? true);
  },
  async dismissSuggestionForDoc(state, { repoId, docId, isTempDocId, text }: DismissedSuggestion) {
    await saveDismissedSuggestionInDB({ repoId, docId, isTempDocId, text });
  },
  async dismissedSuggestionsForDoc(state, { repoId, docId, isTempDocId }) {
    if (repoId == null || docId == null || isTempDocId == null) {
      return new Set();
    }
    return getDismissedSuggestionsForDocument(repoId, docId, isTempDocId);
  },
  async setSwimmportEnabled({ commit }, { userId, enabled }) {
    const res = await firestore.setValuesInDoc(
      firestore.collectionNames.USERS,
      userId,
      { swimmport_enabled: enabled },
      { merge: true }
    );
    if (res.code !== config.SUCCESS_RETURN_CODE) {
      throw new Error(res.errorMessage);
    }
    commit('SET_ENABLED', enabled);
  },
  resetState({ commit }) {
    commit('SET_ENABLED', undefined);
  },
};
