<template>
  <BaseLayoutGap class="profile">
    <BaseLayoutGap class="profile-container" direction="column" alignment="stretch" size="medium">
      <BaseHeading :level="3">Profile information</BaseHeading>
      <BaseLayoutGap class="upload-image">
        <BaseLoading v-if="loading" variant="secondary" />
        <BaseAvatar
          v-else
          hide-tooltip
          size="mega"
          :name="displayName"
          :alt="displayName"
          :url="photoUrl !== '' ? photoUrl : null"
          @click="upload"
        />
        <BaseLayoutGap class="upload-image-text" direction="column" size="xxsmall">
          <BaseProse class="upload-image-text" variant="link" weight="bold" @click="upload">Upload image</BaseProse>
          <BaseProse size="small" variant="secondary" @click="upload"> 5mb max </BaseProse>
          <BaseProse v-if="error" variant="danger"> {{ error }}</BaseProse>
        </BaseLayoutGap>
      </BaseLayoutGap>
      <BaseInput type="text" label="Full Name" v-model="editName" />
      <BaseInput type="text" label="Email" v-model="displayEmail" disabled />
      <div class="save-profile">
        <BaseButton v-if="!saveInProccess" @click="saveUserProfileData()" :disabled="disableBtn">Save</BaseButton>
        <BaseButton v-else><BaseLoading size="small" variant="secondary" /></BaseButton>
      </div>
    </BaseLayoutGap>
  </BaseLayoutGap>
  <BaseLayoutGap class="save-confirm-container" v-if="success">
    <BaseLayoutGap class="save-confirm">
      <div class="circle-check">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.3535 5.02022C13.5488 4.82496 13.5488 4.50837 13.3535 4.31311C13.1582 4.11785 12.8417 4.11785 12.6464 4.31311L6.33328 10.6262L4.04038 8.33333C3.84512 8.13807 3.52854 8.13807 3.33328 8.33333C3.13801 8.52859 3.13801 8.84517 3.33328 9.04044L5.97972 11.6869C6.00413 11.7113 6.03043 11.7327 6.05816 11.751C6.25223 11.8791 6.51597 11.8577 6.68683 11.6869C6.68702 11.6867 6.68722 11.6865 6.68741 11.6863L13.3535 5.02022Z"
            fill="#161721"
          />
        </svg>
      </div>
      <BaseProse class="confirm-text">Profile updated successfully</BaseProse>
    </BaseLayoutGap>
  </BaseLayoutGap>
  <FilePicker ref="imageFilePicker" />
</template>

<script>
import { FilePicker, uploadFiles } from '@swimm/editor';
import { ref } from 'vue';
import { mapGetters } from 'vuex';
import { BaseAvatar, BaseButton, BaseHeading, BaseInput, BaseLayoutGap, BaseLoading, BaseProse } from '@swimm/reefui';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { storageWrapper } from '@/modules/core/services/storage-wrapper';
import { getLoggerNew, productEvents } from '@swimm/shared';
import { useRoute } from 'vue-router';
import { StatusCodes } from 'http-status-codes';
import { useAnalytics } from '@/common/composables/useAnalytics';

const LOGO_UPLOAD_DIRECTORY_PATH = 'workspaces/avatar/';
const logger = getLoggerNew(__modulename);

export default {
  components: {
    FilePicker,
    BaseInput,
    BaseButton,
    BaseHeading,
    BaseAvatar,
    BaseProse,
    BaseLoading,
    BaseLayoutGap,
  },
  setup() {
    const authStore = useAuthStore();
    const { user } = storeToRefs(authStore);
    const { setUser } = authStore;
    const imageFilePicker = ref(null);
    const loading = ref(false);
    const error = ref('');
    const route = useRoute();
    const analytics = useAnalytics();

    return { user, imageFilePicker, loading, error, route, setUser, analytics };
  },
  data() {
    return {
      email: '',
      photoUrl: '',
      editName: '',
      success: false,
      disableBtn: true,
      saveInProccess: false,
      newName: null,
    };
  },
  computed: {
    ...mapGetters('database', ['db_getSlackUrl']),
    displayName() {
      return this.user.nickname;
    },
    displayEmail() {
      return this.user.email;
    },
    workspaceID() {
      return this.route.params.workspaceId;
    },
  },
  methods: {
    async saveUserProfileData() {
      this.saveInProccess = true;
      const saveData = {
        uid: this.user.uid,
        displayName: this.newName,
        photoURL: this.photoUrl,
      };
      try {
        const updateProfile = await CloudFunctions.updateUserProfile(saveData);
        if (updateProfile.data.code === StatusCodes.OK) {
          const userData = updateProfile.data.data;
          this.setUser({
            ...this.user,
            displayName: userData.displayName,
            photoURL: userData.photoURL,
          });
          this.success = true;
          this.saveInProccess = false;
          this.disableBtn = true;
          setTimeout(() => {
            this.success = false;
          }, 10000);
        }
        this.analytics.track(productEvents.SAVE_USER_PROFILE, {
          'user ID': this.user.uid,
          'Name Changed': !!saveData.displayName,
          'Uploaded Image': !!saveData.photoURL,
        });
      } catch (err) {
        this.saveInProccess = false;
        logger.error({ err }, `Unable to save user profile for user: ${this.user.uid}: ${err.message}`);
      }
    },
    async upload() {
      if (this.loading) {
        return;
      }
      this.error = '';
      const imageFiles = await this.imageFilePicker.openPicker();
      if (!imageFiles.length) {
        this.loading = false;
        return;
      }
      this.loading = true;
      const newFileToUpload = new File(imageFiles, `${this.user.uid}.${imageFiles[0].name.split('.')[1]}`, {
        type: imageFiles[0].type,
      });
      uploadFiles({
        directory: `${LOGO_UPLOAD_DIRECTORY_PATH}/${this.workspaceID}`,
        files: [newFileToUpload],
        success: (newLogoUrl) => {
          this.loading = false;
          this.photoUrl = newLogoUrl;
          this.disableBtn = false;
        },
        failure: (e) => {
          this.loading = false;
          this.error = `Failed to upload file. ${e.customerMessage}`;
          logger.error(`Failed to upload logo to workspace, ${e.message}, ${e.customerMessage}`);
        },
        storageWrapper,
        autogenerateName: false,
      });
    },
  },
  created() {
    this.editName = this.user.nickname;
    this.photoUrl = this.user.photoURL;
  },
  watch: {
    editName: function (newName) {
      this.newName = newName;
      this.disableBtn = this.newName === this.user.displayName;
    },
  },
};
</script>

<style scoped>
.profile {
  display: flex;
}

.profile-container {
  max-width: 50%;
}

.upload-image-text,
.avatar {
  cursor: pointer !important;
}

.save-confirm-container {
  align-self: flex-end;
  margin-left: auto !important;
  right: 24px;
  bottom: 24px;
  position: absolute;
}

.save-confirm {
  padding: 6px 8px !important;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--color-border-default-strong);
  background: var(--dark-charcoal);
  width: 288px;
}

.circle-check {
  border-radius: 40px;
  background: var(--success-green-500);
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.save-confirm .confirm-text {
  color: var(--clear-white);
}
</style>
