<template>
  <div v-if="isLoading">
    <Loader class="view-loader" />
  </div>
</template>
<script>
import Cookies from 'js-cookie';
import { getLoggerNew } from '@swimm/shared';
import { gitProviderUtils } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    if (this.$route.query.token) {
      const gitHosting = this.$route.query.git_hosting;
      const gitTokenData = {
        token: this.$route.query.token,
        ...(this.$route.query.refresh_token && { refresh_token: this.$route.query.refresh_token }),
        ...(this.$route.query.created_at && { created_at: parseInt(this.$route.query.created_at) }),
      };
      await gitProviderUtils.setGitHostingTokenData(gitHosting, gitTokenData);
    }

    const keepPopupOpen = Cookies.get('swimm_keep_popup_open');
    const myWindow = window.open('', '_self');

    if (keepPopupOpen) {
      logger.debug('Keeping SetGitToken popup open to allow looking at the console and network tabs for debugging');
    } else {
      setTimeout(function () {
        myWindow.close();
      }, 1000);
    }
  },
};
</script>
