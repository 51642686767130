import { computed } from 'vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { getLoggerNew, productEvents } from '@swimm/shared';
import assertNever from 'assert-never';
import { useCreationHubStore } from '@/modules/core/creation-hub/store/creation-hub';
import { getRouteToNewDocURL, getRouteToNewPlaylistURL } from '@/router/router-utils';
import { useRoute } from 'vue-router';
import { useNavigate } from '@/common/composables/navigate';
import { storeToRefs } from 'pinia';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { CreationHubSections } from '@/modules/core/creation-hub/CreationHubConsts';
import { useWorkspaceSidebarStore } from '@/modules/core/workspace/sidebar/store/workspace-sidebar';
import { useFoldersStore } from '@/modules/folders/store/folders';

const logger = getLoggerNew(__modulename);

export const CreationOptions = {
  REPO: { label: 'Repo', icon: 'branch' },
  DOC: { label: 'Doc (blank)', icon: 'doc' },
  PR2Doc: { label: 'Doc from PR', icon: 'pr', ai: true },
  Snippets2Doc: { label: 'Doc from code files', icon: 'terminal', ai: true },
  Playlist: { label: 'Playlist', icon: 'playlist' },
  Templates: { label: 'Templates', icon: 'template' },
} as const;

export const RepoCreationOptions = {
  FOLDER: { label: 'Folder', icon: 'folder' },
  DOC: { label: 'Doc (blank)', icon: 'doc' },
  PR2Doc: { label: 'Doc from PR', icon: 'pr', ai: true },
  Snippets2Doc: { label: 'Doc from code files', icon: 'terminal', ai: true },
  Playlist: { label: 'Playlist', icon: 'playlist' },
  Templates: { label: 'Templates', icon: 'template' },
} as const;

export type CreationOption = (typeof CreationOptions)[keyof typeof CreationOptions];
export type RepoCreationOption = (typeof RepoCreationOptions)[keyof typeof RepoCreationOptions];
const PLAYLIST_INDEX_IN_CREATION_HUB_GENERAL = 3;
const PR2DOC_INDEX_IN_CREATION_HUB_GENERAL = 1;
const SNIPPETS2DOC_INDEX_IN_CREATION_HUB_GENERAL = 2;

export function useCreationOptions() {
  const analytics = useAnalytics();
  const route = useRoute();
  const { navigateToPageAndTerminateWorker } = useNavigate();
  const { reposStateData } = storeToRefs(useReposStore());
  const { selectFolder } = useFoldersStore();
  const { showAddRepoModal, addFolderInFolderId } = storeToRefs(useWorkspaceSidebarStore());
  const workspaceId = computed(() => route.params.workspaceId as string);
  const creationHubStore = useCreationHubStore();
  const { creationHubModalRef } = storeToRefs(creationHubStore);
  const { openCreationHubModal } = creationHubStore;
  const { repoId: currentRepoId } = storeToRefs(useReposStore());

  async function getRepoDefaultBranchName(repoId) {
    const repoFromState = reposStateData.value[repoId];
    return repoFromState?.defaultBranch || '';
  }
  async function getBranch(repoId: string) {
    return route.params.branch || (await getRepoDefaultBranchName(repoId));
  }

  const routeToNewDoc = (options, folderId = null) => {
    const url = getRouteToNewDocURL({ ...options, workspaceId: workspaceId.value });
    navigateToPageAndTerminateWorker({ newRoute: url, query: { ...options.query, ...(folderId ? { folderId } : {}) } });
  };

  const routeToNewPlaylist = (options, folderId = null) => {
    const url = getRouteToNewPlaylistURL({ ...options, workspaceId: workspaceId.value });
    navigateToPageAndTerminateWorker({ newRoute: url, query: { folderId } });
  };

  function reportOptionSelected(option: CreationOption) {
    analytics.track(productEvents.SELECTED_CREATE_ELLIPSIS_MENU_OPTION, {
      Option: option.label,
    });
  }

  const handleAddNewClicked = ({ sidebarExpanded }: { sidebarExpanded: boolean }) => {
    analytics.track(productEvents.OPENED_CREATE_ELLIPSIS_MENU, {
      'Sidebar Expanded': sidebarExpanded,
    });
  };

  const handleOptionClick = async ({ option, repoId }: { option: CreationOption; repoId: string | null }) => {
    reportOptionSelected(option);
    switch (option.label) {
      case CreationOptions.REPO.label:
        showAddRepoModal.value = true;
        break;
      case CreationOptions.DOC.label:
        if (repoId) {
          // TODO Fishman: Add to / folder (end of folder)
          routeToNewDoc({ repoId, branch: await getBranch(repoId) });
        } else {
          openCreationHubModal({
            showRepoDropdown: true,
            newDocClick: routeToNewDoc,
            newPlaylistClick: routeToNewPlaylist,
          });
        }
        break;
      case CreationOptions.Playlist.label:
        if (repoId) {
          // TODO Fishman: Add to / folder (end of folder)
          routeToNewPlaylist({ repoId, branch: await getBranch(repoId) });
        } else {
          openCreationHubModal({
            showRepoDropdown: true,
            newDocClick: routeToNewDoc,
            newPlaylistClick: routeToNewPlaylist,
          });
          creationHubModalRef.value.onSelectItem(PLAYLIST_INDEX_IN_CREATION_HUB_GENERAL, 'Playlist');
        }
        break;
      case CreationOptions.PR2Doc.label:
        if (repoId) {
          routeToNewDoc({ repoId, branch: await getBranch(repoId), query: { action: 'pr' } });
        } else {
          openCreationHubModal({
            showRepoDropdown: true,
            newDocClick: routeToNewDoc,
            newPlaylistClick: routeToNewPlaylist,
          });
          creationHubModalRef.value.onSelectItem(PR2DOC_INDEX_IN_CREATION_HUB_GENERAL, 'PR2Doc');
        }
        break;
      case CreationOptions.Snippets2Doc.label:
        if (repoId) {
          routeToNewDoc({ repoId, branch: await getBranch(repoId), query: { action: 'snippets' } });
        } else {
          openCreationHubModal({
            showRepoDropdown: true,
            newDocClick: routeToNewDoc,
            newPlaylistClick: routeToNewPlaylist,
          });
          creationHubModalRef.value.onSelectItem(SNIPPETS2DOC_INDEX_IN_CREATION_HUB_GENERAL, 'AI - Code snippets');
        }
        break;
      case CreationOptions.Templates.label:
        // TODO Fishman: Add to / folder (end of folder)
        openCreationHubModal({
          showRepoDropdown: Boolean(repoId),
          newDocClick: routeToNewDoc,
          newPlaylistClick: routeToNewPlaylist,
        });
        creationHubModalRef.value.onSectionChange(CreationHubSections.TEMPLATES);
        break;
      default:
        assertNever(option);
    }
  };

  const handleRepoOptionClick = async ({
    option,
    repoId,
    folderId,
  }: {
    option: RepoCreationOption;
    repoId: string;
    folderId: string | null;
  }) => {
    switch (option.label) {
      case RepoCreationOptions.FOLDER.label:
        if (folderId) {
          addFolderInFolderId.value = folderId;
          selectFolder(repoId, folderId);
        } else {
          logger.warn(`Folder add called with no folder id in Repo ${repoId} `);
        }
        break;
      case RepoCreationOptions.DOC.label:
        if (currentRepoId.value && currentRepoId.value !== repoId) {
          routeToNewDoc({ repoId, branch: await getRepoDefaultBranchName(repoId) }, folderId);
        } else if (repoId) {
          routeToNewDoc({ repoId, branch: await getBranch(repoId) }, folderId);
        } else {
          openCreationHubModal({
            showRepoDropdown: true,
            newDocClick: (options) => routeToNewDoc(options, folderId),
            newPlaylistClick: ($event) => routeToNewPlaylist($event, folderId),
          });
        }
        break;
      case RepoCreationOptions.Playlist.label:
        if (currentRepoId.value && currentRepoId.value !== repoId) {
          routeToNewPlaylist({ repoId, branch: await getRepoDefaultBranchName(repoId) }, folderId);
        } else if (repoId) {
          routeToNewPlaylist({ repoId, branch: await getBranch(repoId) }, folderId);
        } else {
          openCreationHubModal({
            showRepoDropdown: true,
            newDocClick: (options) => routeToNewDoc(options, folderId),
            newPlaylistClick: ($event) => routeToNewPlaylist($event, folderId),
          });
          creationHubModalRef.value.onSelectItem(PLAYLIST_INDEX_IN_CREATION_HUB_GENERAL, 'Playlist');
        }
        break;
      case RepoCreationOptions.PR2Doc.label:
        if (currentRepoId.value && currentRepoId.value !== repoId) {
          routeToNewDoc({ repoId, branch: await getRepoDefaultBranchName(repoId), query: { action: 'pr' } }, folderId);
        } else if (repoId) {
          routeToNewDoc({ repoId, branch: await getBranch(repoId), query: { action: 'pr' } }, folderId);
        } else {
          openCreationHubModal({
            showRepoDropdown: true,
            newDocClick: (options) => routeToNewDoc(options, folderId),
            newPlaylistClick: ($event) => routeToNewPlaylist($event, folderId),
          });
          creationHubModalRef.value.onSelectItem(PR2DOC_INDEX_IN_CREATION_HUB_GENERAL, 'PR2Doc');
        }
        break;
      case RepoCreationOptions.Snippets2Doc.label:
        if (currentRepoId.value && currentRepoId.value !== repoId) {
          routeToNewDoc(
            { repoId, branch: await getRepoDefaultBranchName(repoId), query: { action: 'snippets' } },
            folderId
          );
        } else if (repoId) {
          routeToNewDoc({ repoId, branch: await getBranch(repoId), query: { action: 'snippets' } }, folderId);
        } else {
          openCreationHubModal({
            showRepoDropdown: true,
            newDocClick: (options) => routeToNewDoc(options, folderId),
            newPlaylistClick: ($event) => routeToNewPlaylist($event, folderId),
          });
          creationHubModalRef.value.onSelectItem(SNIPPETS2DOC_INDEX_IN_CREATION_HUB_GENERAL, 'AI - Code snippets');
        }
        break;
      case RepoCreationOptions.Templates.label:
        openCreationHubModal({
          showRepoDropdown: Boolean(repoId),
          newDocClick: (options) => routeToNewDoc(options, folderId),
          newPlaylistClick: ($event) => routeToNewPlaylist($event, folderId),
        });
        creationHubModalRef.value.onSectionChange(CreationHubSections.TEMPLATES);
        break;
      default:
        assertNever(option);
    }
  };

  return { handleAddNewClicked, handleOptionClick, handleRepoOptionClick };
}
