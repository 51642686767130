<script setup lang="ts">
import { BaseLayoutGap } from '@swimm/reefui';
import { Action, SwText } from '@swimm/ui';
import { type MermaidSampleOption, mermaidSamplesOptions } from './mermaidSamples';
import MermaidAiPanelSelect from './MermaidAiPanelSelect.vue';
import { computed, ref } from 'vue';

const props = defineProps<{
  quotaExceeded: boolean;
  shouldShow: boolean;
}>();

const emit = defineEmits<{
  (
    e: 'generate-with-ai',
    { prompt, selectedSample }: { prompt: string; selectedSample: MermaidSampleOption | null }
  ): void;
  (e: 'close'): void;
}>();

const AUTO_DETECT = 'Auto Detect';
const CURRENT_DOC = 'Current Doc';

const prompt = ref('');

const typeOptions = ref([AUTO_DETECT, ...mermaidSamplesOptions.map((option) => option.label)]);
const selectedType = ref(typeOptions.value[0]);

const contextOptions = ref([CURRENT_DOC]);
const selectedContext = ref(contextOptions.value[0]);

function close() {
  emit('close');
}

function generateWithAi() {
  const selectedSample = mermaidSamplesOptions.find((option) => option.label === selectedType.value) ?? null;
  const promptText = prompt.value;
  emit('generate-with-ai', {
    prompt: promptText,
    selectedSample,
  });
}

// prompt must must at length 10
const isTooShort = computed(() => prompt.value.trim().length < 10);
const isDisabled = computed(() => isTooShort.value || props.quotaExceeded);

const quotaErrorMessage = ref('Gen AI quota exceed. Wait for your monthly quota to renew or contact support.');
</script>

<template>
  <BaseLayoutGap class="mermaid-panel" direction="column" v-if="shouldShow" v-click-outside="close">
    <div class="prompt-with-selectors">
      <textarea
        autofocus
        v-model="prompt"
        class="prompt-input"
        placeholder="Generate a flow chart for function X"
      ></textarea>
      <div class="selectors" ref="selectorsElement">
        <VTooltip>
          <MermaidAiPanelSelect v-model="selectedContext" :options="contextOptions" options-title="Context" disabled />
          <template #popper>
            <div class="context-tooltip">
              <SwText variant="body-XS">
                We'll look at all the text and code snippets in the current doc for generating the diagram. Whole repo
                search coming soon!
              </SwText>
            </div>
          </template>
        </VTooltip>
        <MermaidAiPanelSelect v-model="selectedType" :options="typeOptions" options-title="Type" />
      </div>
    </div>
    <div class="quota-error" v-if="quotaExceeded">
      <SwText variant="body-S">{{ quotaErrorMessage }}</SwText>
    </div>
    <div class="footer">
      <SwText variant="body-XS">More context provides better generation.</SwText>
      <VTooltip :disabled="!isDisabled" class="disabled-tooltip">
        <Action :disabled="isDisabled" @click="generateWithAi" magic>Generate</Action>
        <template #popper>
          <template v-if="quotaExceeded">
            <SwText variant="body-XS">{{ quotaErrorMessage }}</SwText>
          </template>
          <template v-else>
            <SwText variant="body-XS">Please enter longer prompt</SwText>
          </template>
        </template>
      </VTooltip>
    </div>
  </BaseLayoutGap>
</template>
<style lang="postcss" scoped>
.mermaid-panel {
  .prompt-with-selectors {
    width: 100%;
    border: 1px var(--color-border-default) solid;

    .prompt-input {
      min-height: 56px;
      width: calc(100% - 4px - 2 * var(--space-base));
      border: none;
      resize: none;
      outline: none;
      overflow: auto;
      padding: var(--space-base);
      font-family: var(--font-family-main);
      font-size: var(--font-size-default);
      background-color: var(--color-bg-default);
      color: var(--color-text-default);
    }

    .selectors {
      display: flex;
    }
  }

  .quota-error {
    color: var(--color-text-warning);
    background-color: var(--color-bg-warning-subtle);
    padding: var(--space-xs) var(--space-sm);
    border-radius: var(--space-xs);
  }

  .footer {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .disabled-tooltip {
      flex-shrink: 0;
    }
  }
}

.context-tooltip {
  max-width: 180px;
}
</style>
