import { encodeString } from './utils/string-utils';
import { getProviderFromResource, parseGitProviderURL } from './git-utils/git-provider-utils';
import { GitProviderName } from './types';
import { getLoggerNew } from '#logger';

const logger = getLoggerNew("packages/shared/src/repo.ts");

export interface RepoMetadata {
  provider: string;
  owner: string;
  name: string;
}

class UnknownProviderError extends Error {}

/**
 * Generate a unique ID from the provider, owner & repo name.
 */
function generateUniqueRepoId(repoMetadata: RepoMetadata): string {
  return encodeString(`${repoMetadata.provider}::${repoMetadata.name}::${repoMetadata.owner}`);
}

export function generateUniqueRepoIdForRemote(remote: string): string {
  try {
    const repoMetadata = computeRepoMetadataForProvider(remote);
    return generateUniqueRepoId(repoMetadata);
  } catch (err) {
    if (!(err instanceof UnknownProviderError)) {
      throw err;
    }
    const gitUrl = parseGitProviderURL(remote);
    if (!gitUrl.resource) {
      throw new Error(`No resource found after parsing`);
    }
    const repoMetadata = {
      provider: gitUrl.resource,
      owner: gitUrl.owner,
      name: gitUrl.name,
    };
    logger.info(`Generating unique repoId with non provider, using ${JSON.stringify(repoMetadata)}`);
    return generateUniqueRepoId(repoMetadata);
  }
}

export function computeRepoMetadataForProvider(remote: string, provider?: GitProviderName): RepoMetadata {
  const gitUrl = parseGitProviderURL(remote);

  const providerToUse: string | undefined = getProviderFromResource(gitUrl.resource) ?? provider;
  if (!providerToUse) {
    throw new UnknownProviderError(`Unknown source: ${gitUrl.source}`);
  }

  return {
    provider: providerToUse,
    owner: gitUrl.owner,
    name: gitUrl.name,
  };
}
