import type { Editor } from '@tiptap/core';
import { Node } from '@tiptap/core';
import { Suggestion } from '@tiptap/suggestion';
import { PluginKey } from '@tiptap/pm/state';
import { getParentNodeForPosition } from '@/components/common/tiptapUtils';

const typesToExclude: string[] = ['hunk', 'tableItem', 'orderedList', 'bulletList'];

export default Node.create({
  name: 'snippet',
  addOptions() {
    return {
      matcher: {
        char: '/snippet ',
      },

      addSnippet(editor: Editor, range: { to: number; from: number }) {
        editor
          .chain()
          .command(({ tr }) => {
            tr.insertText('', range.from, range.to);
            return true;
          })
          .run();
      },
    };
  },
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        pluginKey: new PluginKey('snippetExtension'),
        command: ({ range }) => {
          this.options.addSnippet(this.editor, range);
        },
        char: this.options.matcher.char,
        allowSpaces: false,
        startOfLine: false,
        allow: ({ state, range }) => {
          const nodeType = getParentNodeForPosition(range.from, state)?.type.name;
          return !typesToExclude.includes(nodeType);
        },
        render: () => {
          return {
            onStart: (props) => {
              return this.options.onStart(props);
            },
          };
        },
        items: (): string[] => {
          return ['snippet'];
        },
      }),
    ];
  },
});
