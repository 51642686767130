<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';

const { isSidebarExpanded } = storeToRefs(useWorkspaceStore());
</script>
<template>
  <div class="container" :class="{ 'sidebar-open': isSidebarExpanded }">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.container {
  width: calc(80% - var(--sidebar-width));
  margin: 0 auto;
  transition: width 0.2s ease;
}

@media only screen and (max-width: 1680px) {
  .container.sidebar-open {
    width: calc(95% - var(--sidebar-width));
  }
}

@media only screen and (max-width: 1480px) {
  .container:not(.sidebar-open) {
    width: calc(95% - var(--sidebar-width));
  }
}
</style>
