import type { Editor, EditorEvents } from '@tiptap/core';
import { type Ref, nextTick, onBeforeUnmount, onMounted, ref, toRaw } from 'vue';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

export function useTiptapIsSelected(
  editor: Ref<Editor>,
  node: Ref<ProseMirrorNode>,
  getPos: Ref<() => number>,
  options?: { inclusiveEnd?: boolean }
) {
  const selected = ref(false);
  const highlighted = ref(false);

  // Make sure selected is properly set on first load
  onMounted(() => {
    const selection = editor.value.state.selection;
    handleSelectionUpdate({ transaction: { selection } } as EditorEvents['selectionUpdate']);
  });

  const handleSelectionUpdate = async ({ transaction }: EditorEvents['selectionUpdate']) => {
    await nextTick();
    const pos = getPos.value();
    // Note https://github.com/ueberdosis/tiptap/issues/4274
    const endPos = pos + toRaw(node.value).nodeSize;
    // https://theworld.com/~swmcd/steven/tech/interval.html + a special case for an empty selection
    selected.value =
      editor.value.isFocused &&
      ((pos < transaction.selection.to && transaction.selection.from < endPos) ||
        (transaction.selection.empty && transaction.selection.from === pos) ||
        (!!options?.inclusiveEnd && transaction.selection.empty && transaction.selection.from === endPos));
  };

  const handleHighlightUpdate = async ({ transaction }: EditorEvents['selectionUpdate']) => {
    await nextTick();
    const pos = getPos.value();
    // A node is copied if its base position is inside the selection; if a selection starts before a snippet and
    // continues into a part of the snippet - the snippet will be copied, but if the selection starts from inside the
    // snippet and continues until after it, the snippet itself will not be copied. We therefore highlight the node if
    // the base position is part of the selection.
    highlighted.value = pos < transaction.selection.to && transaction.selection.from <= pos;
  };

  editor.value.on('selectionUpdate', handleSelectionUpdate);
  editor.value.on('selectionUpdate', handleHighlightUpdate);
  editor.value.on('focus', handleSelectionUpdate); // if only focus changes and selection doesn't - react

  onBeforeUnmount(() => {
    editor.value.off('selectionUpdate', handleSelectionUpdate);
    editor.value.off('selectionUpdate', handleHighlightUpdate);
    editor.value.off('focus', handleSelectionUpdate);
  });

  return { selected, highlighted };
}
