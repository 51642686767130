<script lang="ts">
import { Statuses } from '../../types';
export const BlockVariants = ['secondary', ...Statuses.filter((status) => status !== 'none')] as const;
export type BlockVariantsType = (typeof BlockVariants)[number];

export const BlockPadding = ['tight', 'none'] as const;
export type BlockPaddingType = (typeof BlockPadding)[number];

export const BlockShadow = ['small', 'medium', 'large'] as const;
export type BlockShadowType = (typeof BlockShadow)[number];
</script>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  /**
   * The style variant for the block.
   */
  variant?: BlockVariantsType;
  padding?: BlockPaddingType;
  shadow?: BlockShadowType;
  clickable?: boolean;
}>();

const computedClasses = computed(() => ({
  [`block--${props.variant}`]: !!props.variant,
  [`block--${props.padding}`]: !!props.padding,
  [`block--shadow-${props.shadow}`]: !!props.shadow,
  'block--clickable': props.clickable,
}));
</script>

<template>
  <div class="block" :class="computedClasses">
    <!-- @slot Any content or elements. -->
    <slot />
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.block {
  $self: &;

  @include defaults;

  background-color: var(--color-bg-default);
  border: 1px solid var(--color-border-default-subtle);
  border-radius: var(--space-xsmall);
  box-sizing: border-box;
  padding: var(--space-xsmall) var(--space-small);
  width: 100%;

  &--clickable {
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(--color-bg-default-hover);
    }
  }

  &--secondary {
    background-color: var(--color-bg-surface);

    &#{$self}--clickable {
      &:hover,
      &:focus {
        background-color: var(--color-bg-surface-hover);
      }
    }
  }

  &--info {
    background-color: var(--color-bg-info-subtle);
    border-color: transparent;
    color: var(--color-text-info);

    &#{$self}--clickable {
      &:hover,
      &:focus {
        background-color: var(--color-bg-info-subtle-hover);
      }
    }
  }

  &--success {
    background-color: var(--color-bg-success-subtle);
    border-color: transparent;
    color: var(--color-text-success);

    &#{$self}--clickable {
      &:hover,
      &:focus {
        background-color: var(--color-bg-success-subtle-hover);
      }
    }
  }

  &--danger {
    background-color: var(--color-bg-danger-subtle);
    border-color: transparent;
    color: var(--color-text-danger);

    &#{$self}--clickable {
      &:hover,
      &:focus {
        background-color: var(--color-bg-danger-subtle-hover);
      }
    }
  }

  &--warning {
    background-color: var(--color-bg-warning-subtle);
    border-color: transparent;
    color: var(--color-text-warning);

    &#{$self}--clickable {
      &:hover,
      &:focus {
        background-color: var(--color-bg-warning-subtle-hover);
      }
    }
  }

  &--magic {
    background-color: var(--color-bg-magic-subtle);
    border-color: transparent;
    color: var(--color-text-magic);

    &#{$self}--clickable {
      &:hover,
      &:focus {
        background-color: var(--color-bg-magic-subtle-hover);
      }
    }
  }

  &--tight {
    padding: var(--space-xxsmall) var(--space-xsmall);
  }

  &--none {
    padding: 0;
  }

  &--shadow {
    &-small {
      box-shadow: var(--shadow-small);
    }

    &-medium {
      box-shadow: var(--shadow-medium);
    }

    &-large {
      box-shadow: var(--shadow-large);
    }
  }
}
</style>
