<template>
  <div class="toc-node">
    <div
      class="toc-link clickable body-S data-hj-suppress"
      :class="levelClass"
      :style="levelStyle"
      :data-testid="`toc-link-${testPath}-${heading.level}`"
      @click="headingClicked"
    >
      {{ heading.text }}
    </div>
    <TocNode
      v-for="(child, index) in heading.children"
      :key="child.id"
      :heading="child"
      :top-level="topLevel"
      :doc="doc"
      :test-path="`${testPath}.${index}`"
      :visible-id="visibleId"
      @heading-clicked="childHeadingClicked"
    />
  </div>
</template>

<script>
export default {
  name: 'TocNode',
  props: {
    heading: { type: Object, required: true },
    topLevel: { type: Number, required: true },
    doc: { type: Object, required: true },
    testPath: { type: String, required: true },
    visibleId: { type: String, required: true },
  },
  computed: {
    levelStyle() {
      let padding = (this.heading.level - this.topLevel) * 16 + 8;
      // Current will have border of 4
      // this is done so there are "no jumps" when you show the default
      if (!this.isCurrent) {
        padding += 4;
      }
      return {
        'padding-left': `${padding}px`,
      };
    },
    levelClass() {
      const isTop = this.heading.level === this.topLevel;
      return { 'top-node': isTop, 'current-node': this.isCurrent };
    },
    isCurrent() {
      return this.heading.id === this.visibleId;
    },
  },
  emits: ['heading-clicked'],
  methods: {
    childHeadingClicked(heading) {
      this.$emit('heading-clicked', heading);
    },
    headingClicked() {
      this.$emit('heading-clicked', this.heading);
    },
  },
};
</script>

<style scoped lang="postcss">
.toc-node {
  padding-top: 16px;

  .current-node {
    border-left: 2px var(--text-color-link) solid;
    color: var(--text-color-link);
  }

  .toc-link:hover {
    color: var(--text-color-link);
  }
}
</style>
