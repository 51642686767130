import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { isEmulated } from '@/config';
import firebase from 'firebase/compat/app';
import { clearUserFromLocalState } from '@/adapters-common/user';
import { eventLogger } from '@swimm/shared';
import { getLoggerNew } from '@swimm/shared';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { useNavigate } from './navigate';
import { useAnalytics } from './useAnalytics';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useSwimmStores } from '@/modules/core/stores';
import { useStigg } from '@/common/composables/useStigg';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import Dexie from 'dexie';
import { useNotificationsStore } from '../../../../../packages/editor';

const logger = getLoggerNew(__modulename);

export function useSignout() {
  const { user } = storeToRefs(useAuthStore());
  const analytics = useAnalytics();
  const { navigateToPageAndTerminateWorker } = useNavigate();
  const { resetAllStoresState } = useSwimmStores();
  const { logEvent } = useSwimmEventLogs();
  const { stiggClient } = useStigg();
  const reposStore = useReposStore();
  const drafts3Store = useDrafts3Store();
  const { addNotification, removeNotification } = useNotificationsStore();

  async function signout({
    removeDraftsOnLogout = false,
    redirectToLogin = true,
    redirectRoute = null,
    redirectQuery = null,
    skipRevoke = false,
  } = {}) {
    const notification = addNotification('Signing out, this can take a few seconds...', {
      autoClose: false,
      loader: true,
    });
    try {
      await logEvent(
        eventLogger.SWIMM_EVENTS.USER_SIGN_OUT,
        { srcId: user.value.uid, srcName: user.value.nickname },
        user.value
      );
      logger.debug(`Signing out the user: ${user.value.uid}`);
      try {
        if (!isEmulated && !skipRevoke) {
          await CloudFunctions.revokeAllUserSessions({
            signoutSource: 'WebApp',
            uid: user.value.uid,
          });
        }
      } catch (err) {
        logger.error({ err }, 'Error revoking other user sessions');
      }
      await firebase.auth().signOut();
      if (removeDraftsOnLogout) {
        await drafts3Store.deleteAllDrafts();
      }
      await deleteDexieDatabases();
      await clearUserFromLocalState(removeDraftsOnLogout, user.value.email ?? '');
      stiggClient.clearCustomer();
      analytics.logout();
      if (redirectToLogin) {
        await navigateToPageAndTerminateWorker({ newRoute: redirectRoute || '/login', query: redirectQuery });
      }
      resetAllStoresState();
      // load to the store the empty state of the indexDB
      reposStore.loadAllReposStateData();
    } catch (err) {
      logger.error({ err }, `There has been an error signing the user: ${user.value.uid} out. Details: ${err}`);
      if (redirectToLogin) {
        await navigateToPageAndTerminateWorker({ newRoute: redirectRoute || '/login', query: redirectQuery });
      }
    } finally {
      removeNotification(notification.id);
    }
  }

  async function deleteDexieDb(dbName: string) {
    try {
      await Dexie.delete(dbName);
    } catch (err) {
      logger.error({ err }, `Error deleting Dexie database: ${dbName}`);
    }
  }

  async function deleteDexieDatabases() {
    // global-tokens is pre swmd3 - but it might still exists
    const databases = ['staticAnalysis', 'global-tokens'];
    await Promise.all(databases.map(deleteDexieDb));
  }

  return {
    signout,
  };
}
