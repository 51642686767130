<script setup>
import { SwText, SwToggle } from '@swimm/ui';
import { computed, nextTick, ref } from 'vue';
import { useRepoIntegrations } from '@/modules/repo/composables/repo-integrations';
import { exceptionAlert } from '@/common/utils/alert';
import { useStore } from 'vuex';
import { useStigg } from '@/common/composables/useStigg';
import PaywallModal, { PaywallEntity } from '@/common/components/modals/PaywallModal.vue';
import UserDialog from '@/common/components/modals/UserDialog.vue';
import { deleteAllSharedDocsInRepo } from '@/modules/cloud-docs/cloud-doc-utils';
import { GitProviderName, StiggFeatures, integrationSettingsTabs, productEvents } from '@swimm/shared';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { SWIMM_DOC_SITE_SHARING_INTERNALLY } from '@/config';
import { BaseAnchor, BaseBlock, BaseHeading, BaseProse } from '@swimm/reefui';

const props = defineProps({
  repoId: { type: String, required: true },
  workspaceId: { type: String, required: true },
});
const emit = defineEmits(['close', 'change-tab']);

const store = useStore();
const { stiggClient } = useStigg();
const analytics = useAnalytics();

const refreshWorkspaceSharedDocs = (args) => store.dispatch('database/refreshWorkspaceSharedDocs', args);
const sharedDocs = computed(() => store.getters['database/db_getRepoSharedDocs'](props.workspaceId, props.repoId));
const sharedDocsAmount = computed(() => sharedDocs.value.length);

const repoMetadata = computed(() => store.getters['database/db_getRepoMetadata'](props.repoId));
const isGitHubAppInstalled = computed(() => repoMetadata.value?.isGithubAppInstalled ?? false);
const gitProviderShowWarning = computed(() => {
  const workspaceData = store.getters['database/db_getWorkspace'](props.workspaceId);
  return ![GitProviderName.GitHub].includes(workspaceData.provider);
});
const isSharingInternallyDisabledInWorkspace = computed(() => {
  const workspaceData = store.getters['database/db_getWorkspace'](props.workspaceId);
  return workspaceData?.settings?.is_workspace_local_mode ?? false;
});

const { integrations, updateIntegrations } = useRepoIntegrations(props.repoId);

const showPaywall = ref(false);
const deleteSharedDocsConfirmation = ref(null);
const loading = ref(false);
const isShareInternallyEnabled = ref(integrations.value?.share_internally_enabled ?? false);

function canEnableSharing() {
  return stiggClient && stiggClient.getBooleanEntitlement
    ? stiggClient.getBooleanEntitlement({ featureId: StiggFeatures.SHARING_INTERNALLY }).hasAccess
    : false;
}

async function wrapUpdate(func) {
  loading.value = true;
  try {
    await func();
  } catch (error) {
    exceptionAlert({ title: 'Failed to update repo settings', error });
  } finally {
    loading.value = false;
  }
}

async function turnOffSharing() {
  const userSelection = await deleteSharedDocsConfirmation.value.showDialog();
  if (!userSelection.confirm) {
    return false;
  }

  loading.value = true;
  await deleteAllSharedDocsInRepo({ workspaceId: props.workspaceId, repoId: props.repoId });
  refreshWorkspaceSharedDocs({ workspaceId: props.workspaceId });
  loading.value = false;
  return true;
}

async function dontChangeToggle(oldValue, newValue) {
  isShareInternallyEnabled.value = newValue;
  await nextTick(() => {
    isShareInternallyEnabled.value = oldValue;
  });
}

async function toggleShareOption(isEnable) {
  // check if user can turn it on
  if (isEnable && !canEnableSharing()) {
    showPaywall.value = true;
    dontChangeToggle(!isEnable, isEnable);
    return;
  }

  // handle turning it off
  const previousSharedDocsAmount = sharedDocsAmount.value;
  if (!isEnable && sharedDocsAmount.value) {
    const isContinue = await turnOffSharing();
    if (!isContinue) {
      dontChangeToggle(!isEnable, isEnable);
      return;
    }
  }

  // update setting in DB
  await wrapUpdate(async () => {
    try {
      isShareInternallyEnabled.value = isEnable;
      await updateIntegrations({ share_internally_enabled: isShareInternallyEnabled.value });
    } catch (error) {
      isShareInternallyEnabled.value = !isEnable;
      throw error;
    }
  });

  reportEvent(productEvents.TOGGLE_REPO_DOCUMENT_SHARING, {
    Enabled: isEnable,
    'Already Shared Documents': previousSharedDocsAmount ?? 0,
  });
}

function reportEvent(event, payload) {
  analytics.track(event, {
    'Workspace ID': props.workspaceId,
    'Repo ID': props.repoId,
    'Repo Name': repoMetadata.value.name,
    ...payload,
  });
}

function closePaywallModal({ isRedirecting }) {
  showPaywall.value = false;
  if (isRedirecting) {
    emit('close');
  }
}

function onHoverToggle() {
  if (!isGitHubAppInstalled.value) {
    reportEvent(productEvents.HOVERED_DISABLED_DOCUMENT_SHARING_TOGGLE, { 'Disable Reason': 'No GitHub App' });
  }
}

function getImageUrl(image) {
  return new URL(`../assets/${image}`, import.meta.url).href;
}
</script>

<template>
  <div>
    <div class="feature" :class="{ disabled: loading }">
      <div class="toggle-container">
        <VTooltip :disabled="!isSharingInternallyDisabledInWorkspace">
          <SwToggle
            class="toggle-button"
            :disabled="
              loading ||
              (!isGitHubAppInstalled && isSharingInternallyDisabledInWorkspace) ||
              isSharingInternallyDisabledInWorkspace
            "
            :value="isShareInternallyEnabled"
            size="small"
            @change="toggleShareOption"
            @mouseenter="onHoverToggle"
          />
          <template #popper>
            Sharing has been disabled for your workspace for security reasons. Please contact Swimm to enable it if
            necessary.
          </template>
        </VTooltip>
        <SwText variant="subtitle-XL"><strong>Doc sharing with members who don’t have code access</strong></SwText>
      </div>
      <SwText
        v-if="!isGitHubAppInstalled && !isSharingInternallyDisabledInWorkspace && !gitProviderShowWarning"
        class="banner"
        variant="body-S"
      >
        To enable doc publishing, make sure our
        <span class="installation-link" @click="emit('change-tab', integrationSettingsTabs.GITHUB_APP)"
          >GitHub App</span
        >
        is installed this repository
      </SwText>
      <BaseBlock v-if="gitProviderShowWarning" id="cli-warnning" variant="warning">
        <BaseHeading variant="heading-2" :level="5">Sharing docs requires using the CI integration</BaseHeading>
        <BaseProse>
          Make sure your CI integration is set up properly, otherwise your docs will not be properly shared.
          <BaseAnchor href="https://docs.swimm.io/Features/sharing-without-code-access/">Learn more</BaseAnchor>
        </BaseProse>
      </BaseBlock>
      <img class="image" :src="getImageUrl('sharing-settings.svg')" />
      <div class="description">
        <SwText variant="body-L">
          Allow members to share docs within this repo so that members without code access can
          <strong>view</strong> them.
        </SwText>
        <SwText variant="body-L">
          Shared documents will be stored in Swimm’s Cloud, and will only be accessible to workspace members under
          ‘Shared docs’. The shared version of the doc will be automatically updated whenever the doc changes on the
          default branch. <a :href="SWIMM_DOC_SITE_SHARING_INTERNALLY" target="_blank"><u>Learn more</u></a>
        </SwText>
        <SwText variant="body-L" v-if="sharedDocsAmount">
          Repo currently has <strong> {{ sharedDocsAmount }} shared doc{{ sharedDocsAmount > 1 ? 's' : '' }} </strong>
        </SwText>
      </div>
    </div>
    <PaywallModal :show="showPaywall" :entity="PaywallEntity.SHARING" @close="closePaywallModal" />
    <UserDialog
      danger
      ref="deleteSharedDocsConfirmation"
      title="Disable document sharing?"
      :body="`${sharedDocsAmount} document${
        sharedDocsAmount > 1 ? 's are' : ' is'
      }  already being shared in your workspace; this will turn off sharing for ${
        sharedDocsAmount > 1 ? 'these documents.' : ' this document'
      }`"
      confirm-text="Disable sharing"
    />
  </div>
</template>

<style scoped lang="postcss">
.description {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  color: var(--text-color-secondary);
}

.toggle-container {
  display: flex;
  gap: var(--space-base);
  align-items: center;
}

.disabled {
  opacity: 0.4;
}

.banner {
  top: 0;
  display: flex;
  margin: 0;
  padding: 12px var(--space-sm);
  background: var(--color-surface);
  border-radius: 4px;

  .installation-link {
    margin: 0 4px;
    color: var(--text-color-link);
    cursor: pointer;
  }
}

.image {
  align-self: stretch;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--space-sm);
}
</style>
