import { getLoggerNew } from '@swimm/shared';
import { useInitData } from './initData';
import { useRepoSwitcher } from './repoSwitcher';

const logger = getLoggerNew(__modulename);

export function useReloadAppData() {
  const { setRepoData } = useInitData();
  const { connectToRepo } = useRepoSwitcher();

  return {
    reloadRepoAppData: async (repoId: string) => {
      try {
        await setRepoData(null);
        await connectToRepo({
          repoId,
          alertError: false,
        });
      } catch (err) {
        logger.error({ err }, `could not reload repo, please reload the page manually ${err}`);
      }
    },
  };
}
