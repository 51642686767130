import '@/load-polyfill';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import semver from 'semver';
import App from './App.vue';
import router from './router/index.js';
import store from './store.js';
import { initializeDevApi } from './dev-api';
import { startWorkers } from './workers';
import { initAnalytics } from './common/composables/useAnalytics';
import { getFromCache, setInCache } from './common/utils/autosync-cache-adapter';
import { config, getLoggerNew, initializeLogger, setRefreshTokensFunctions, state } from '@swimm/shared';
import { setAutosyncCacheFunctions } from '@swimm/swimmagic';
import { Action, EmptyState, Icon, Loader, SwText } from '@swimm/ui';
import { tiptapVueDevtools } from '@swimm/swmd';
import * as webConfig from '@/config';
import loadAllAtoms from '@/load-atoms';
import { firebaseConfigs } from '@/common/firebase-configs';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';
import { getApp } from 'firebase/app';
import { getAuth, initializeRecaptchaConfig } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { initializeFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import FloatingVue from 'floating-vue';
import vSelect from 'vue-select';
import * as Sentry from '@sentry/vue';
import vClickOutside from 'click-outside-vue3';
import VueVirtualScroller from 'vue-virtual-scroller';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';

import 'unfonts.css';
import 'fontaweswimm/src/fonts/fontaweswimm/style.css';
import 'vue-select/dist/vue-select.css';
import '@swimm/ui/style.css';
import '@swimm/ui/theme.css';
import '@swimm/editor/styles/highlight.css';
import '@swimm/editor/style.css';
import '@swimm/swmd/style.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import '@swimm/reefui/style.css';

declare global {
  // NOTE: Global must use `var` explicitly
  // eslint-disable-next-line no-var
  var vue: {
    router: typeof router;
  };
}

// Redirect to the new domain if needed
if (webConfig.isStaging && location.hostname === 'swimm-web-app.web.app') {
  location.hostname = 'staging.swimm.cloud';
} else if (webConfig.isStage && location.hostname === 'swimm-stag.web.app') {
  location.hostname = 'stag.swimm.cloud';
}

const firebaseConfig = firebaseConfigs[webConfig.SWIMM_ENV];

config.setupSharedConfig({ baseUrl: location.origin });
setAutosyncCacheFunctions({ setInCache, getFromCache });
setRefreshTokensFunctions({ refreshToken: CloudFunctions.refreshOAuthToken });
initializeLogger({
  console: import.meta.env.MODE !== 'production' || webConfig.ENABLE_CONSOLE_LOGGER,
  datadogBrowser: webConfig.isRemoteLoggingEnabled && {
    clientToken: webConfig.DATADOG_CLIENT_TOKEN,
    site: webConfig.DATADOG_SITE,
    service: webConfig.DATADOG_SERVICE,
    version: webConfig.PJSON_VERSION,
    env: webConfig.DATADOG_ENV,
    forwardErrorsToLogs: false,
    proxy: config.getDatadogProxy(webConfig.SWIMM_ENV),
  },
});
initAnalytics();

const logger = getLoggerNew(__modulename);

// TODO We should really just extract this init to a separate file and use it in both main and the workers...
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
if (import.meta.env.MODE === 'emulated') {
  // @ts-ignore
  firebase.auth().useEmulator('http://127.0.0.1:9099', { disableWarnings: true });
  firebase.functions().useEmulator('127.0.0.1', 6001);
  firebase.firestore().useEmulator('127.0.0.1', 8081);
  firebase.storage().useEmulator('127.0.0.1', 9199);
} else {
  // ReCaptcha available only in non-emulated mode
  void initializeRecaptchaConfig(getAuth());

  // Set custom domains
  // Note that you would normally do this once and export this objects to use through your app
  const app = getApp();
  const auth = getAuth(app);
  auth.config.apiHost = `${config.getApiHost(webConfig.SWIMM_ENV)}/identitytoolkit`;
  auth.config.tokenApiHost = `${config.getApiHost(webConfig.SWIMM_ENV)}/securetoken`;
  const functions = getFunctions(app, 'us-central1');
  functions.customDomain = config.getCloudFunctionsDomain(webConfig.SWIMM_ENV);
  firebase.firestore().settings({ host: `${config.getApiHost(webConfig.SWIMM_ENV)}/firestore` });
  const _firestore = initializeFirestore(app, { host: `${config.getApiHost(webConfig.SWIMM_ENV)}/firestore` });
  const storage = getStorage(app);
  // @ts-expect-error Internal API
  storage.host = `${config.getApiHost(webConfig.SWIMM_ENV)}/storage`;
}

(async () => {
  await initializeDevApi();
  startWorkers();

  const app = createApp(App);

  app.use(vClickOutside);
  app.use(FloatingVue, {
    boundary: 'window',
    themes: {
      'menu-no-arrow': {
        $extend: 'menu',
      },
      'dropdown-no-arrow': {
        $extend: 'dropdown',
      },
      'dropdown-limited-width': {
        $extend: 'dropdown',
      },
      'help-tooltip': {
        $extend: 'menu',
      },
      'menu-delayed': {
        $extend: 'menu',
        delay: { show: 500 },
      },
      'tooltip-delayed': {
        $extend: 'tooltip',
        delay: { show: 500 },
      },
    },
  });
  app.use(VueDOMPurifyHTML);
  app.use(VueVirtualScroller);
  app.component('VSelect', vSelect);

  // Swimm UI
  app.component('Action', Action);
  app.component('Icon', Icon);
  app.component('EmptyState', EmptyState);
  app.component('Loader', Loader);
  app.component('SwText', SwText);

  loadAllAtoms(app);

  app.config.globalProperties.$logger = logger; // Accessible only from components, not from store files
  app.config.performance = true;

  state.get({ key: 'swimm_version' }).then(async (swimmVersion) => {
    if (
      swimmVersion &&
      (!semver.satisfies(webConfig.PJSON_VERSION, `~${swimmVersion}`) ||
        // FIXME: Remove after version 1.5.0 is deployed. This is a workaround to force What's New to show up despite the
        // previous bug in the check above.
        swimmVersion === '1.4.0' ||
        swimmVersion === '1.4.1')
    ) {
      await state.set({ key: 'should_show_version_modal', value: true });
    }
    await state.set({ key: 'swimm_version', value: webConfig.PJSON_VERSION });
  });

  if (process.env.SENTRY_DSN && webConfig.isProduction) {
    // TODO Could have just set this in .env if we had per env .env files for
    // the browser... AKA properly split shared and per project .env
    const sentryUrl = new URL(process.env.SENTRY_DSN);
    const sentryProjectId = sentryUrl.pathname.substring(sentryUrl.pathname.lastIndexOf('/') + 1);

    try {
      Sentry.init({
        app,
        dsn: process.env.SENTRY_DSN,
        tunnel: `${config.getApiUrl(webConfig.SWIMM_ENV)}/sentry-ingest/${sentryProjectId}`,
        environment: webConfig.isProduction ? 'production' : 'staging',
        integrations: [
          Sentry.browserTracingIntegration({
            router,
            tracingOrigins: ['app.swimm.io', /^\//],
          }),
          Sentry.replayIntegration(),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: [
          // Ignore ResizeObserver error - raised by the `DrawerModal` component.
          'ResizeObserver loop limit exceeded',
        ],
        release: `swimm@${webConfig.PJSON_VERSION}`,
      });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }
  }

  const pinia = createPinia();
  app.use(pinia);
  app.use(store);
  app.use(router);
  app.use(tiptapVueDevtools);

  window.vue = { router };
  app.mount('#app');
})();
