<script setup>
import { computed } from 'vue';
import { BaseIcon } from '@swimm/reefui';

const emit = defineEmits(['stop-generating']);
const props = defineProps({
  generationTime: { type: Number, required: true },
});

const aiGenerationProgress = computed(() => {
  if (!props.generationTime) {
    return null;
  }
  if (props.generationTime < 2000) {
    return 1;
  } else if (props.generationTime < 5000) {
    return 10;
  } else if (props.generationTime < 15000) {
    return 30;
  } else if (props.generationTime < 45000) {
    return 60;
  } else if (props.generationTime < 65000) {
    return 80;
  } else {
    return 95;
  }
});

const aiGenerationText = computed(() => {
  if (!aiGenerationProgress.value) {
    return '';
  }
  switch (aiGenerationProgress.value) {
    case 1: {
      return 'Fetching code snippets';
    }
    case 10: {
      return 'Sending your request';
    }
    case 30: {
      return 'Analyzing code';
    }
    case 60: {
      return 'Drafting your doc';
    }
    case 80: {
      return 'This may take a little longer ☕';
    }
    case 95: {
      return 'Almost there';
    }
    default: {
      return 'Generating a doc from your code';
    }
  }
});

function stopGenerating() {
  emit('stop-generating');
}
</script>

<template>
  <div class="container">
    <div class="header">
      <BaseIcon name="magic" />
      <SwText class="ai-loading-text" variant="subtitle-L" v-if="aiGenerationProgress">{{ aiGenerationText }}</SwText>
    </div>
    <div class="body">
      <progress class="ai-progress" :value="aiGenerationProgress" max="100">{{ aiGenerationProgress }}%</progress>
      <SwText variant="body-S" component="a" class="stop-generating" @click="stopGenerating">Stop generating</SwText>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.container {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;

    .beta-tag {
      margin-left: 4px;
    }

    @keyframes dots {
      0% {
        content: '.   ';
      }

      20% {
        content: '..  ';
      }

      50% {
        content: '... ';
      }

      80% {
        content: '....';
      }
    }

    .ai-loading-text::after {
      display: contents;
      animation: dots 2s ease infinite forwards;
      content: '.   ';
      position: absolute;
      left: 2px;
      z-index: 0;
      user-select: none;
      overflow: hidden;
      white-space: pre; /* Keeps the content with a constant width so that the 'beta' tag doesn't jump. */
      margin: 0 auto; /* Gives that scrolling effect as the typing happens */
      font-family: ui-monospace; /* Keep width constant. */
    }

    .ai-loading-text {
      color: var(--text-color-secondary);
    }
  }

  .body {
    progress.ai-progress {
      -webkit-appearance: none;
      appearance: none;
      height: 6px;
      width: 100%;
      margin: var(--space-xs) 0;
    }

    progress.ai-progress::-webkit-progress-bar {
      border-radius: 80px;
      background-color: var(--color-hover);
    }

    progress.ai-progress::-webkit-progress-value {
      transition: width ease 30s;
      border-radius: 20px;
      background-color: var(--text-color-magic-strong);
    }

    .stop-generating {
      cursor: pointer;
      color: var(--text-color-link);
    }
  }
}

/* eslint-disable vue-scoped-css/no-unused-selector */
.icon.icon-magic {
  color: var(--text-color-magic-strong);
}
</style>
