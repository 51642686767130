<script>
import { Icon, SwText } from '@swimm/ui';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { SwText, Icon },
});
</script>

<template>
  <div class="empty">
    <Icon name="file-empty" no-padding class="icon" />
    <SwText variant="headline2">This is an empty file.</SwText>
    <SwText variant="body-L">No code to highlight here. Choose another file to add snippet to your doc.</SwText>
  </div>
</template>

<style scoped lang="postcss">
.empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--space-xs);
  color: var(--text-color-primary);
  cursor: default;

  .icon {
    font-size: var(--fontsize-xxl);
  }
}
</style>
