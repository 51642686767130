<template>
  <div>
    <div
      v-if="showShare && workspaceId"
      class="wide-share"
      :class="{ dropdown: !containedView, shadowed: !containedView }"
      v-click-outside="clickedOutside"
    >
      <div v-if="!containedView" class="header">
        <SwText class="headline2"><strong>Share</strong></SwText>
        <IconButton name="close" @click="close" />
      </div>
      <Divider v-if="!containedView" class="options-border" />
      <div v-if="showShareInternallySection" class="sharable-container">
        <div class="basic-share">
          <div class="label">
            <Icon name="link" class="link-icon" />
            <div>
              <SwText variant="body-L">Members without code access can <strong>view</strong></SwText>
              <SwText variant="body-S" class="sub-label">
                User required, no Git access required.
                <a :href="SWIMM_DOC_SITE_SHARING_INTERNALLY" target="_blank"><u>Learn more</u></a>
              </SwText>
            </div>
          </div>
          <VMenu v-if="!isSharedDoc" popper-class="tooltip-on-modal" :disabled="!disableShareToggle" placement="left">
            <SwToggle
              :value="isShareInternallyEnabled"
              size="small"
              :disabled="disableShareToggle"
              @change="shareToggleChanged"
              @mouseenter="onHoverToggle"
            />
            <template #popper>
              <div class="disable-share-tooltip">
                <SwText>
                  {{ disableShareTooltipText }}
                  <span v-if="showIntegrationSettingText" class="link" @click="openStorageSettingsModal">
                    Integrations & settings
                  </span>
                </SwText>
              </div>
            </template>
          </VMenu>
        </div>
        <Action
          v-if="isShareInternallyEnabled"
          :disabled="!isSharableLinkGenerated || copiedSharableLink"
          class="sharable-link-btn"
          @click="copyLinkClicked(sharableLink, 'Publish')"
        >
          <template v-if="!isSharableLinkGenerated">
            <Loader class="loader" secondary />Generating sharable link...
          </template>
          <template v-else>
            <Icon :name="copiedSharableLink ? 'check' : 'copy'" />
            <span>{{ copiedSharableLink ? 'Copied' : 'Copy link' }}</span>
          </template>
        </Action>
        <div v-if="isShareInternallyEnabled && sharedDoc && isSharableLinkGenerated" class="publish-details">
          <Icon name="clock" />
          <SwText variant="body-S" class="updated-time-block">
            Auto-updated from branch {{ sharedDocUpdateBranch }}
            <span class="time-display" v-tooltip="sharedDocUpdateTimeDisplay">{{ sharedTimeToShow }}</span>
          </SwText>
        </div>
      </div>
      <Divider v-if="showShareInternallySection" class="sharing-border" />
      <div v-if="!isSharedDoc" class="basic-share">
        <div class="label">
          <SwAvatar
            :text="workspaceName ?? 'Workspace'"
            :src="workspaceLogo"
            square
            hide-tooltip
            class="workspace-logo"
          />
          <div>
            <SwText variant="body-L">Members can <strong>edit</strong></SwText>
            <SwText variant="body-S" class="sub-label"> Git access required. </SwText>
          </div>
        </div>
        <VTooltip popper-class="tooltip-on-modal" :disabled="!isNewResource" placement="left">
          <div>
            <Action
              secondary
              class="member-copy-btn"
              :disabled="isNewResource || copiedLink"
              @click="copyLinkClicked(regularLink, 'Normal')"
            >
              <Icon :name="copiedLink ? `check` : `copy`" />
              <span>{{ copiedLink ? 'Copied' : 'Copy private link' }}</span>
            </Action>
          </div>
          <template #popper>
            <SwText class="disable-btn-tooltip">{{ disableNormalCopyToolTipText }}</SwText>
          </template>
        </VTooltip>
      </div>
    </div>
    <UserDialog ref="shareCommittedVersionRef" :title="userDialogTitle" :confirm-text="userDialogConfirmButton">
      <div class="space-dialog">
        <SwText v-if="'committedVersionMessage' === userDialogDescriptionOption" variant="body-L">
          Your uncommitted changes will be reflected <strong>once you commit</strong> them to {{ currentBranch }}.
          <a :href="SWIMM_DOC_SITE_SHARING_INTERNALLY" target="_blank"><u>Learn more</u></a>
        </SwText>
        <SwText v-else-if="'defaultVersionMessage' === userDialogDescriptionOption" variant="body-L">
          The version on <strong>{{ defaultBranch }}</strong> will be shared. Your uncommitted changes will be reflected
          <strong>once you commit and merge</strong> them to {{ defaultBranch }}.
          <a :href="SWIMM_DOC_SITE_SHARING_INTERNALLY" target="_blank"><u>Learn more</u></a>
        </SwText>
        <SwText v-else-if="'committedDefaultVersionMessage' === userDialogDescriptionOption" variant="body-L">
          Your uncommitted changes will be reflected <strong>once you commit and merge</strong> them to
          {{ defaultBranch }}.
          <a :href="SWIMM_DOC_SITE_SHARING_INTERNALLY" target="_blank"><u>Learn more</u></a>
        </SwText>
      </div>
    </UserDialog>
    <UserDialog ref="playlistComingSoonRef" title="Sharing playlists coming soon!" confirm-text="" cancel-text="OK">
      <div class="space-dialog">
        <SwText variant="body-L">
          We're still working on this feature. In the meantime, you can share individual docs through the ellipsis menu
          in the repo page or using the Share button when viewing/editing a doc.
        </SwText>
      </div>
    </UserDialog>
  </div>
  <RepoSettingsModal
    :show="showRepoSettingsModal"
    :repo-id="repoId"
    origin="Sharing Internally"
    :initial-tab-code="integrationSettingsTabs.STORAGE"
    @close="showRepoSettingsModal = false"
  />
</template>

<script setup lang="ts">
import { useCopyToClipboard, useNotificationsStore } from '@swimm/editor';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useAppLinks } from '@/modules/core/compositions/app-links';
import {
  config,
  datetimeUtils,
  getLoggerNew,
  gitProviderUtils,
  integrationSettingsTabs,
  isRepoIdDummyRepo,
  productEvents,
} from '@swimm/shared';
import { Divider, Icon, IconButton, SwAvatar, SwText, SwToggle } from '@swimm/ui';
import useSharingInternally from '@/modules/cloud-docs/composables/sharing-internally';
import UserDialog from '@/common/components/modals/UserDialog.vue';
import { useRouting } from '@/common/composables/routing';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useRepoIntegrations } from '@/modules/repo/composables/repo-integrations';
import { computed, nextTick, ref, watch, watchEffect } from 'vue';
import RepoSettingsModal from '@/modules/repo/settings/RepoSettingsModal.vue';
import { SWIMM_DOC_SITE_SHARING_INTERNALLY } from '@/config';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useCurrentRoute } from '@/common/composables/currentRoute';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { getSwimmDocumentFiles } from '@/modules/drafts3/docs';
import { extractResourceIdFromPath } from '@swimm/shared';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

type ShareType = 'Normal' | 'Publish';

type UserDialogOptions = 'committedVersionMessage' | 'defaultVersionMessage' | 'committedDefaultVersionMessage';

const logger = getLoggerNew(__modulename);

const props = defineProps({
  entity: { type: String, default: '' },
  showShare: { type: Boolean, default: false },
  link: { type: String, default: '' },
  repoId: { type: String, default: null },
  entityId: { type: String, default: null },
  containedView: { type: Boolean, default: false },
});

const emit = defineEmits<{
  (e: 'show'): void;
  (e: 'hide'): void;
}>();

const analytics = useAnalytics();
const { getAppLink } = useAppLinks();
const { publishDocOnShare, unpublishSharedDoc } = useSharingInternally();
const { copyToClipboard } = useCopyToClipboard();
const { getDefaultBranch } = useRouting();
const { user } = storeToRefs(useAuthStore());
const { integrations } = useRepoIntegrations(props.repoId);
const { repos } = storeToRefs(useReposStore());
const { addNotification } = useNotificationsStore();
const defaultBranch = ref('');
const workspaceStore = useWorkspaceStore();
const { isEditDocPage, isEditPlaylistPage } = useCurrentRoute();
const drafts3Store = useDrafts3Store();
const store = useStore();
const route = useRoute();

const workspaceName = computed(() => workspaceStore.name);
const workspaceLogo = computed(() => workspaceStore.logo);

const copiedLink = ref(false);
const isShareInternallyEnabled = ref(false);
const copiedSharableLink = ref(false);
const isSharableLinkGenerated = ref(false);
const userDialogTitle = ref('');
const userDialogDescriptionOption = ref<UserDialogOptions | null>(null);
const userDialogConfirmButton = ref('');
const branchForPublish = ref('');
const showRepoSettingsModal = ref(false);
const insideDialog = ref(false);

const playlistComingSoonRef = ref<typeof UserDialog>();

const shareCommittedVersionRef = ref<typeof UserDialog>();

const currentBranch = computed(() => route.params.branch as string);
const workspaceId = computed(() => route.params.workspaceId as string);

const docFromDb = computed(() => {
  if (!props.repoId || !props.entityId) {
    return null;
  }
  return store.getters['database/db_getSwimm'](props.repoId, props.entityId);
});

const playlistFromDb = computed(() => {
  if (!props.repoId || !props.entityId) {
    return null;
  }
  return store.getters['database/db_getPlaylist'](props.repoId, props.entityId);
});

const sharedDocId = computed(() => {
  return docFromDb.value?.exported_cloud_doc_id ?? null;
});

const sharedDoc = computed(() => {
  const sharedDoc = store.getters['database/db_getSharedDoc'](workspaceId.value, sharedDocId.value);
  if (Object.keys(sharedDoc).length === 0) {
    return null;
  }
  return sharedDoc;
});

const isAdmin = computed(() => {
  return store.getters['database/db_isWorkspaceAdmin'](workspaceId.value, user.value.uid);
});

const isDoc = computed(() => {
  return props.entity === 'DOC';
});
const isSharedDoc = computed(() => {
  return props.entity === 'SHARED_DOC';
});
const isPlaylist = computed(() => {
  return props.entity === 'PLAYLIST';
});
const isEdit = computed(() => {
  return isEditDocPage.value || isEditPlaylistPage.value;
});
const isEntityIdInDraft = computed(() => {
  return !!props.entityId && !!drafts3Store.drafts?.has(props.entityId);
});
const isDraft = computed(() => {
  return isEntityIdInDraft.value;
});
const isNewResource = computed(() => {
  return isDraft.value && !!drafts3Store?.drafts.get(props.entityId)?.isNew;
});

const repo = computed(() => {
  return repos.value.find((r) => r.id === props.repoId);
});

const showShareInternallySection = computed(() => {
  if (!gitProviderUtils.doesProviderSupportSharingInternally(repo.value?.provider)) {
    return false;
  }
  return (isDoc.value || isSharedDoc.value || isPlaylist.value) && !!props.repoId;
});

const regularLink = computed(() => {
  if (props.link) {
    return props.link;
  }
  return getAppLink(route.path);
});

const sharableLink = computed(() => {
  return getAppLink(`/workspaces/${workspaceId.value}/shared-docs/${sharedDocId.value}`);
});

const disableShareToggle = computed(() => {
  return isRepoIdDummyRepo(props.repoId) || isNewResource.value || !isSharingSettingEnabled.value;
});
const isSharingSettingEnabled = computed(() => {
  return integrations.value?.share_internally_enabled;
});
const sharedDocUpdateBranch = computed(() => {
  return sharedDoc.value?.export_info?.original_branch ?? defaultBranch.value;
});
const sharedDocUpdateTime = computed(() => {
  return sharedDoc.value?.modified?.toDate();
});
const sharedDocUpdateTimeDisplay = computed(() => {
  return `${sharedDocUpdateTime.value?.toLocaleDateString([], {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  })} ${sharedDocUpdateTime.value?.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
});
const showIntegrationSettingText = computed(() => {
  return !isNewResource.value && !isShareInternallyEnabled.value && isAdmin.value;
});
const disableNormalCopyToolTipText = computed(() => {
  return 'Sharing is not allowed on new drafts. Please commit your changes first.';
});
const disableShareTooltipText = computed(() => {
  if (isNewResource.value) {
    return disableNormalCopyToolTipText.value;
  }
  if (isRepoIdDummyRepo(props.repoId)) {
    return 'Sharing is disabled for Swimm’s demo repo.';
  }
  if (!isShareInternallyEnabled.value) {
    return isAdmin.value
      ? 'To enable sharing for this repo, go to'
      : 'Sharing is disabled for this repo. Ask an Admin to enable it from the repo settings.';
  }

  return 'A problem occurred, please refresh';
});

watchEffect(async () => {
  defaultBranch.value = await getDefaultBranch(props.repoId);
});

// calls when the share internally is toggled
async function shareToggleChanged(isEnable: boolean) {
  isShareInternallyEnabled.value = isEnable;
  if (!isEnable) {
    // stop sharing doc
    try {
      await unpublishSharedDoc({ sharedDocId: sharedDocId.value, originalDocId: props.entityId });
    } catch (err) {
      logger.error({ err }, `Failed to unshare doc ${props.repoId} ${props.entityId}`);
      isShareInternallyEnabled.value = !isEnable; // revert
      addNotification('Unexpected error occurred while deleting shared doc, please try again later', {
        icon: 'warning',
        autoClose: false,
      });
      return;
    }
    isSharableLinkGenerated.value = false;
    copiedSharableLink.value = false;
    reportEvent(productEvents.CHANGED_DOC_SHARED_STATE, { Shared: false });
    return;
  }
  insideDialog.value = true;
  const userResponse = await showDialogIfNeeded();
  // without the timeout, this was too earlt for the click outside, not sure why.
  window.setTimeout(() => {
    insideDialog.value = false;
  }, 1000);
  emit('show');
  if (!userResponse || isPlaylist.value) {
    nextTick(async () => {
      isShareInternallyEnabled.value = !isEnable;
      emit('show');
    });
    return;
  }
  try {
    await publishDocOnShare({
      branch: branchForPublish.value,
      docId: props.entityId,
      repoId: props.repoId,
      workspaceId: workspaceId.value,
      repos: repos.value,
    });
  } catch (err) {
    logger.error({ err }, `Failed to publish doc ${props.repoId} ${props.entityId}`);
    addNotification('Unexpected error occurred while sharing your doc, please try again later', {
      icon: 'warning',
      autoClose: false,
    });
    isShareInternallyEnabled.value = !isEnable; // revert
  }
  isSharableLinkGenerated.value = true;
  reportEvent(productEvents.CHANGED_DOC_SHARED_STATE, { Shared: true });
}

watch(
  () => props.showShare,
  (newValue) => {
    if (newValue) {
      isShareInternallyEnabled.value = !!sharedDocId.value && !!sharedDoc.value;
      isSharableLinkGenerated.value = isShareInternallyEnabled.value;
      reportEvent(productEvents.OPEN_SHARE_MODAL, {
        Context: 'Shared Docs',
        'Is New Draft': isNewResource.value,
        'Page Name': route.name,
        'Is Playlist': !isDoc.value,
      });
    }
  },
  {
    immediate: true,
  }
);

async function checkIfExistsOnDefaultBranch() {
  const files = await getSwimmDocumentFiles({
    repoId: props.repoId,
    branch: defaultBranch.value,
    fileExtensions: [config.SWMD_FILE_EXTENSION, config.SWMD_PLAYLIST_EXTENSION],
  });
  return files.some((f) => extractResourceIdFromPath(f.path) === props.entityId);
}
function clickedOutside() {
  // if we are inside dialog (e.g. opened from the share) then we don't close
  // since this can be click on the other dialog
  if (props.showShare && !showRepoSettingsModal.value && !insideDialog.value) {
    close();
  }
}
function close() {
  showRepoSettingsModal.value = false;
  emit('hide');
}
function copyLinkClicked(txt: string, type: ShareType) {
  type === 'Normal' ? (copiedLink.value = true) : (copiedSharableLink.value = true);
  copyToClipboard(txt);
  reportEvent(productEvents.CLICKED_COPY_LINK, { 'Link Type': type });
  setTimeout(() => {
    type === 'Normal' ? (copiedLink.value = false) : (copiedSharableLink.value = false);
  }, 2000);
}
function onHoverToggle() {
  let toggleStatus = 'Enabled';
  if (disableShareToggle.value) {
    toggleStatus = isNewResource.value
      ? `Disabled (new ${isDoc.value ? 'doc' : 'playlist'})`
      : 'Disabled (repo settings)';
  }
  reportEvent(productEvents.HOVERED_SHARE_TOGGLE, {
    'Is Admin': isAdmin.value,
    'Toggle Status': toggleStatus,
  });
}
function reportEvent(eventName: string, payload = {}) {
  const resourceIdPropName = `${isDoc.value ? 'Document' : 'Playlist'} ID`;
  const entity = isDoc.value ? 'Doc' : isSharedDoc.value ? 'Shared Doc' : 'Playlist';
  const mode = isEdit.value ? 'Edit' : 'View';
  const context = `${mode} ${entity}`;

  analytics.track(eventName, {
    Context: context,
    'Repo ID': props.repoId ?? '',
    [resourceIdPropName]: props.entityId ?? '',
    ...payload,
  });
}

// dialog

function getUserDialogDetails(branch: string): Record<UserDialogOptions, { title: string; confirmButton: string }> {
  return {
    committedVersionMessage: {
      title: 'Share committed version?',
      confirmButton: 'Share version without my changes',
    },
    defaultVersionMessage: {
      title: 'Share default branch version?',
      confirmButton: `Share version from ${branch}`,
    },
    committedDefaultVersionMessage: {
      title: 'Share committed version?',
      confirmButton: 'Share version without my changes',
    },
  };
}

function setDialogProperties(option: UserDialogOptions) {
  const dialogVersion = getUserDialogDetails(defaultBranch.value)[option];
  userDialogTitle.value = dialogVersion.title;
  userDialogDescriptionOption.value = option;
  userDialogConfirmButton.value = dialogVersion.confirmButton;
}

async function showDialogIfNeeded() {
  if (isPlaylist.value) {
    playlistComingSoonRef.value.showDialog();
    reportEvent(productEvents.SHOWN_SHARING_PLAYLIST_COMING_SOON, {
      'Playlist Name': playlistFromDb.value?.name ?? '',
      'Sharing Enabled In Repo': !!integrations.value?.share_internally_enabled,
    });
    return false;
  }
  if (currentBranch.value === defaultBranch.value) {
    branchForPublish.value = defaultBranch.value;
    if (isDraft.value) {
      setDialogProperties('committedDefaultVersionMessage');
    } else {
      return true;
    }
  } else {
    const existsOnDefaultBranch = await checkIfExistsOnDefaultBranch();
    if (existsOnDefaultBranch) {
      branchForPublish.value = defaultBranch.value;
      setDialogProperties('defaultVersionMessage');
    } else {
      branchForPublish.value = currentBranch.value;
      if (isDraft.value) {
        setDialogProperties('committedVersionMessage');
      } else {
        return true;
      }
    }
  }

  if (isDraft.value) {
    reportEvent(productEvents.SHOWN_SHARE_IN_EDIT_MODE_POPUP);
  }
  const userResponse = await shareCommittedVersionRef.value.showDialog();
  return userResponse.confirm;
}

const sharedTimeToShow = computed(() => {
  if (!sharedDocUpdateTime.value) {
    return '';
  }
  const minutesAgo = datetimeUtils.toRelativeTimeInMinutes(Date.now(), sharedDocUpdateTime.value.getTime(), 60);
  if (!minutesAgo) {
    return `on ${sharedDocUpdateTimeDisplay.value}`;
  }
  if (minutesAgo === 'now') {
    return 'just now';
  }
  return minutesAgo;
});
function openStorageSettingsModal() {
  showRepoSettingsModal.value = true;
}
</script>

<style scoped lang="postcss">
.dropdown {
  z-index: 12345678;
  display: flex;
  padding: var(--space-base) var(--space-sm);
  border-radius: 5px;
  background-color: var(--color-bg);
  flex-direction: column;
}

.wide-share {
  width: 472px;
}

.options-border.options-border {
  position: relative;
  right: var(--space-md);
  margin: 0;
  width: 512px;
  border-color: var(--color-surface);
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.sub-label {
  padding-top: var(--space-xs);
  color: var(--text-color-secondary);

  a {
    color: var(--text-color-secondary);
  }
}

.sharing-border {
  margin-top: var(--space-xs);
}

.basic-share {
  padding: var(--space-base) 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: var(--space-md);

  .label {
    display: flex;
    align-items: center;
    flex-direction: row;

    .link-icon {
      font-size: var(--fontsize-m);
    }
  }

  .member-copy-btn {
    height: 32px;
    padding: var(--space-xs) var(--space-sm);
    gap: var(--space-xs);
    font-size: var(--fontsize-xs);
    border: none;
  }

  .workspace-logo.workspace-logo {
    padding: 1px;
    border: 1px solid var(--color-border-default-subtle);
    margin-right: var(--space-base);
  }
}

.sharable-container {
  display: flex;
  flex-direction: column;

  .sharable-link-btn {
    height: 32px;
    margin: var(--space-base) 0 var(--space-xs) 0;
  }

  .loader.loader {
    --loader-size: 24px;
  }
}

.space-dialog {
  margin: var(--space-base) 0 var(--space-sm) 0;
}

.publish-details {
  display: flex;

  .time-display {
    cursor: pointer;
  }
}

.disable-btn-tooltip {
  width: 180px;
}

.disable-share-tooltip {
  width: 180px;
  padding: var(--space-base);

  .link {
    color: var(--text-color-link);
    cursor: pointer;
  }
}

.updated-time-block {
  margin-top: var(--space-xs);
}
</style>
