import swal from 'sweetalert';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import { useNotificationsStore } from '@swimm/editor';
import { getLoggerNew } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export async function openAsyncPopup({
  url,
  width = 500,
  height = 700,
  displayName,
  checkSuccessCB,
}: {
  url: string;
  width?: number;
  height?: number;
  displayName: string;
  checkSuccessCB: () => Promise<boolean>;
}): Promise<boolean> {
  const OPERATION_TIMEOUT_IN_MILLISECONDS = 5 * 60 * 1000; // 5 minutes
  const POLLING_INTERVAL = 300;

  const { addNotification } = useNotificationsStore();

  const left = Math.max((window.screen.availWidth - width) / 2, 0).toString();
  const top = Math.max((window.screen.availHeight - height) / 2, 0).toString();
  return await new Promise((resolve) => {
    const popup = window.open(url, '', `width=${width},height=${height},top=${top},left=${left}`);

    if (!popup) {
      addNotification(
        `It seems like you're using a popup blocker. Please allow it to continue authorizing ${displayName}`
      );
      logger.warn('Failed to open authorization popup, possibly blocked by an ad-blocker');
    } else {
      logger.debug('Authorization popup opened, waiting for token to be set');
    }

    const timeoutTimestamp = Date.now() + OPERATION_TIMEOUT_IN_MILLISECONDS;
    const tokenPollingInterval = setInterval(async () => {
      if (popup?.closed) {
        clearInterval(tokenPollingInterval);
        return resolve(false);
      }

      if (!(await checkSuccessCB())) {
        if (Date.now() >= timeoutTimestamp) {
          logger.error('Authorization failed: timed out waiting for token to be set');
          clearInterval(tokenPollingInterval);
          swal({
            title: 'Timed out waiting for authorization.',
            content: { element: SWAL_CONTACT_US_CONTENT() },
          });
          return resolve(false);
        }
        return;
      }

      logger.debug(`New token set for ${displayName}`);
      clearInterval(tokenPollingInterval);
      return resolve(true);
    }, POLLING_INTERVAL);
  });
}
