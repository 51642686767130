import type { DynamicChangeLine, DynamicHunkContainer } from './types';
import { HunkChangeLineType } from './types/swimm-patch-common';

/**
 * Gets an array of changes (generated from swimmPatch, not from git) and iterates them like reading a file "line-by-line" to create a transformed side-by-side list.
 * The method is detecting consecutive updates in the list, zipping them with with the matching type (del+add or add+del) creating a side-by-side pair.
 * @example
 * For the array [c,c,c, a1,a2,b2,a3,b3,b4,b5,b6,c,a4,a5,c,c,c]
 * The method will return [(c,c),(c,c),(c,c),(a1,''),(a2,b2),(a3,b3),(b4,''),(b5,''),(b6,''),(c,c),(a4,''),(a5,''),(c,c),(c,c),(c,c)]
 * @param hunkContainer generated out of swimmPatch
 */
export function hunkLineMatcherFromSwimmDiff(hunkContainer: DynamicHunkContainer): DynamicHunkContainer {
  const orderedHunk: DynamicHunkContainer = { ...hunkContainer, changes: [] };
  const transformedChanges: DynamicChangeLine[] = [];

  // The last change stored in the array
  let previousChangeType = HunkChangeLineType.Context;
  hunkContainer.changes.forEach((lineChange) => {
    // If the current change is of type context, reset previous change holder, push the change as a new line in the hunk changes, and move to the next iteration
    if (isContext(lineChange.changeType)) {
      previousChangeType = HunkChangeLineType.Context;
      transformedChanges.push({ ...lineChange });
      return;
    }

    // Checks if the current sequence continues i.e. the change has the same type as the prior iteration line type,
    // That means that the change should remain a single "add" or "del".
    // If current change is "context" - should not try to merge it with another changeline.
    // If the last change was an update - we should never override it as well.
    if (previousChangeType === lineChange.changeType || isContext(previousChangeType) || isUpdate(previousChangeType)) {
      previousChangeType = lineChange.changeType;
    }
    // The current line change is of type "update" (i.e. both a "del" an "add" on the same hunk line), joins current line change with the matching previous change.
    else {
      const previousChangeIndex = transformedChanges.length - 1; // When matching lines of swimmPatch hunk - it's always the previous index
      const side = lineChange.changeType === HunkChangeLineType.Deleted ? 'fileA' : 'fileB';
      transformedChanges[previousChangeIndex].changeType = HunkChangeLineType.Update;
      transformedChanges[previousChangeIndex][side] = { ...lineChange[side] };
      previousChangeType = HunkChangeLineType.Update;
      return;
    }
    transformedChanges.push({ ...lineChange });
  });
  orderedHunk.changes = transformedChanges;
  return orderedHunk;
}

interface Sequence {
  changeType: HunkChangeLineType;
  count: number;
}

/**
 * Gets an array of changes (generated by git-diff-parse) and iterates them like reading a file "line-by-line" to create a transformed side-by-side list.
 * The method is detecting sequences in the list, zipping them with with the matching type (del+add or add+del) creating a side-by-side pair.
 * @example
 * For the array [c,c,c, a1,a2,a3,a4,b1,b2,c,b3,b4,a5,b6,c,c,c]
 * The method will return [(c,c),(c,c),(c,c),(a1,b1),(a2,b2),(a3,''),(a4,''),(c,c),(a5,b3),(a6,b4),(c,c),(c,c),(c,c)]
 * @param {*} hunkContainer the dynamic-patch hunkContainer object containing gitHunkMetadata, swimmHunkMetadata and most importantly - the line changes.
 */
export function hunkLineMatcherFromGit(hunkContainer: DynamicHunkContainer): DynamicHunkContainer {
  const orderedHunk: DynamicHunkContainer = { ...hunkContainer, changes: [] };
  const transformedChanges: DynamicChangeLine[] = [];

  // Sequence is a series of consecutive hunk changes with the same type
  let previousSequence: Sequence = { count: 0, changeType: HunkChangeLineType.Context };
  let currentSequence: Sequence = { count: 0, changeType: HunkChangeLineType.Context };

  resetSequences(previousSequence, currentSequence);

  hunkContainer.changes.forEach((lineChange) => {
    // If the current change is of type context, reset both current and previous sequences count
    // Push the change as a new line in the hunk changes, and move to the next iteration
    if (isContext(lineChange.changeType)) {
      resetSequences(previousSequence, currentSequence);
      transformedChanges.push({ ...lineChange });
      return;
    }

    // If the current sequence continues (i.e. the change has the same type as the prior iteration line type)

    if (currentSequence.changeType === lineChange.changeType) {
      currentSequence.count++;
    } else {
      // If the current sequence "broke" (i.e. the change has other type then the prior iteration line type)
      // Consider the "broken" sequence as the previous sequence, and re-initialized the current sequence
      previousSequence = { ...currentSequence };
      currentSequence = { changeType: lineChange.changeType, count: 1 };
    }

    // If the current change is not an update (i.e. it is of type context, or it is a single "add" or "del"),
    // Then push the change as a new line in the hunk changes and we move on to the next iteration
    if (!isUpdateSequence(previousSequence)) {
      transformedChanges.push({ ...lineChange });
      return;
    }

    // As the current line change is of type "update" (i.e. both a "del" an "add" on the same hunk line),
    // Joins current line change with an existing change from the previous sequence in transformedChanges.
    generateUpdateLineChange({
      transformedChanges: transformedChanges,
      lineChange: lineChange,
      previousSequence: previousSequence,
    });
  });

  orderedHunk.changes = transformedChanges;
  return orderedHunk;
}

/**
 * Updates a hunk changed line that have del/add to be of type update and aggregates the other lineChange side data to it
 * @param transformedChanges - ref to the transformed hunk changes
 * @param lineChange - current lineChange data
 * @param previousSequence - ref to the previous sequence of changes
 */
function generateUpdateLineChange({
  transformedChanges,
  lineChange,
  previousSequence,
}: {
  transformedChanges: DynamicChangeLine[];
  lineChange: DynamicChangeLine;
  previousSequence: Sequence;
}): void {
  const changeUpdateIndex = transformedChanges.length - previousSequence.count;
  transformedChanges[changeUpdateIndex].changeType = HunkChangeLineType.Update;
  const side = lineChange.changeType === HunkChangeLineType.Deleted ? 'fileA' : 'fileB';
  transformedChanges[changeUpdateIndex][side] = { ...lineChange[side] };
  previousSequence.count--;
}

// Determines if an input line change is of context type
const isContext = (changeType: HunkChangeLineType) => changeType === HunkChangeLineType.Context;
// Determines if an input line change is of update type - was merged from a del/add
const isUpdate = (changeType: HunkChangeLineType) => changeType === HunkChangeLineType.Update;

// Determines if the current change should be joined to a prior change and make it of type "update"
// I.e. this line was both of type "del" and "add"
const isUpdateSequence = (previousSequence: Sequence) => previousSequence.count > 0;

function resetSequences(previousSequence: Sequence, currentSequence: Sequence): void {
  previousSequence.count = 0;
  previousSequence.changeType = HunkChangeLineType.Context;
  currentSequence.count = 0;
  currentSequence.changeType = HunkChangeLineType.Context;
}
