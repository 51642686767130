export const HelpTooltipTypes = {
  TOOLTIP: 'Tooltip',
  MODAL: 'Modal',
};

export const HelpTooltipPriorities = {
  PRIORITY_LEVEL_1: 1,
  PRIORITY_LEVEL_2: 2,
  PRIORITY_LEVEL_3: 3,
  PRIORITY_LEVEL_4: 4,
  PRIORITY_LEVEL_5: 5,
};
