<template>
  <span v-if="hasValidDate" v-tooltip="extendDateTimeComputed">{{ humanizedDateComputed }}</span>
</template>

<script setup lang="ts">
import { datetimeUtils } from '@swimm/shared';
import { computed } from 'vue';
import type { PropType } from 'vue';

const props = defineProps({
  date: { type: [String, Object] as PropType<string | Date>, default: null },
  format: { type: String as PropType<'short' | 'long'>, required: false, default: 'short' },
});

const extendDateTimeComputed = computed(() => {
  return datetimeUtils.extendDateTime(props.date, props.format);
});

const hasValidDate = computed(() => {
  return Boolean(datetimeUtils.jsDate(props.date));
});

const humanizedDateComputed = computed(() => {
  return datetimeUtils.humanizedDate(props.date, props.format);
});
</script>
