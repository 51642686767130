<script setup lang="ts">
import { ref, watch } from 'vue';
import type { Editor } from '@tiptap/core';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';

const FAKE_URL_BASE = 'http://swmfakeurl.test';

const props = withDefaults(
  defineProps<{
    src: string;
    alt?: string;
    title?: string;
    className?: string;
    editor: Editor;
  }>(),
  {
    alt: '',
    title: '',
    className: '',
  }
);

const emit = defineEmits<{
  error: [];
}>();

const srcFile = ref<File | undefined>(undefined);
const runtimeSrc = ref<string | undefined>(undefined);
const isRelative = ref<boolean>(false);

watch(
  () => props.src,
  async (_value, _oldValue, onCleanup) => {
    const resolvedUrl = new URL(props.src, FAKE_URL_BASE);
    const swimmEditorServices = getSwimmEditorServices(props.editor);
    if (resolvedUrl.origin === FAKE_URL_BASE) {
      try {
        srcFile.value = await swimmEditorServices.external.getImageFile(props.src, swimmEditorServices.unitId.value);
        runtimeSrc.value = URL.createObjectURL(srcFile.value);
        isRelative.value = true;
      } catch (_e) {
        emit('error');
      }
    } else {
      if (swimmEditorServices.isAirGap) {
        emit('error');
      } else {
        runtimeSrc.value = props.src;
      }
    }

    onCleanup(() => {
      if (runtimeSrc.value && isRelative.value) {
        URL.revokeObjectURL(runtimeSrc.value);
      }
    });
  },
  { immediate: true }
);
</script>

<template>
  <img v-if="runtimeSrc" :class="className" :src="runtimeSrc" :alt="alt" :title="title" @error="emit('error')" />
</template>
