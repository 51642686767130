<template>
  <div class="v-flex center">
    <img width="100" class="logo" src="@/assets/swimm.png" />
    <div class="catchphrase">
      <div class="registration-prase">
        Thanks for stopping by Swimm, our product only works on desktop at this time.
      </div>
      <div v-if="!user" class="registration-prase">You can proceed to sign up using your mobile.</div>
      <div v-else class="registration-prase">We sent you an email on how to get started from desktop.</div>
    </div>
    <div class="actions">
      <router-link v-if="!user" to="/register"><Action>Proceed to sign up</Action></router-link>
      <a class="swim-to-shore" href="https://swimm.io/product">
        <Action :secondary="!user">Take me back to Swimm</Action>
      </a>
    </div>
  </div>
</template>

<script>
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { getDeviceDetails } from '@swimm/shared';
import { pageEvents } from '@swimm/shared';
import { storeToRefs } from 'pinia';
// Remove Question mark on mobile in registration and here

export default {
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();

    return { user, analytics };
  },
  mounted() {
    const device = getDeviceDetails();
    this.analytics.pageVisit(pageEvents.VIEW_MOBILE, {
      Type: 'Page',
      'Mobile Vendor': device.vendor,
      'Mobile Model': device.model,
      OS: `${device.osName} ${device.osVersion}`,
    });
  },
};
</script>

<style scoped>
h3 {
  margin-bottom: 10px;
  text-align: center;
}

.v-flex {
  height: 100vh;
  background: var(--color-bg);
}

.catchphrase {
  margin: 30px 0 50px;
  text-align: center;
}

.registration-prase {
  margin-top: 10px;
  padding: 0 5%;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.swim-to-shore {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.swim-to-shore :deep(.button) {
  width: 100%;
}

.logo {
  border-radius: 8px;
}
</style>
