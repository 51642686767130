import { useGitHostingAuthorization } from '@/modules/core/composables/git-hosting-authorization';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { defineStore, storeToRefs } from 'pinia';
import {
  type DBRepo,
  GitProviderName,
  type RepoStateData,
  type StateTokenData,
  UrlUtils,
  getLoggerNew,
  gitProviderUtils,
  isRepoIdDummyRepo,
  logRejectedPromise,
} from '@swimm/shared';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { STORES } from '@/modules/core/stores/store-constants';
import { useRoute } from 'vue-router';
import { AUTH_URL } from '@/config';

const logger = getLoggerNew(__modulename);

export const useGitAuthorizationStore = defineStore(STORES.GIT_AUTHORIZATION, () => {
  const store = useStore();
  const route = useRoute();
  const { reposStateData, repoId } = storeToRefs(useReposStore());
  const gitHostingAuthorizationStore = useGitHostingAuthorization();
  const {
    fetchGitHostingsConfigData,
    authorizeIfNeeded,
    loadAuthorizedGitHostings,
    validateGitHostingToken,
    isGitHostingUrlAuthorized,
    authorizedGitHostings,
  } = gitHostingAuthorizationStore;

  void loadAuthorizedGitHostings();

  window.addEventListener(
    'message',
    (
      event: MessageEvent<{
        type: 'git-auth';
        gitHosting: string;
        tenantId?: string;
        tokenData: StateTokenData;
      }>
    ) => {
      const authUrl = new URL(AUTH_URL);
      if (event.origin !== authUrl.origin || event.data.type !== 'git-auth') {
        return;
      }

      const tenantSuffix = event.data.tenantId ? `_${event.data.tenantId}` : '';
      const gitHostingKey = UrlUtils.slugUrlForConfig(event.data.gitHosting);

      const sluggedUrl = `${gitHostingKey}${tenantSuffix}`;

      void gitProviderUtils
        .setGitHostingTokenData(sluggedUrl, event.data.tokenData)
        .catch(logRejectedPromise(logger, 'git-auth-handler'));
    }
  );

  const db_getWorkspaceRepos = computed(() => store.getters['database/db_getWorkspaceRepos']);
  const db_getRepo = computed(() => store.getters['database/db_getRepository']);

  const workspaceId = computed(() => route.params.workspaceId as string);
  const isWorkspaceWithAccessToken = computed(
    () => store.getters['database/db_getWorkspace'](workspaceId.value)?.settings?.is_access_token || false
  );

  const workspaceRepos = computed(() =>
    db_getWorkspaceRepos
      .value(workspaceId.value)
      .filter((repo) => !!repo)
      .filter((repo) => !isRepoIdDummyRepo(repo.metadata.id))
  );

  const workspaceGitHosting = computed(() => {
    if (workspaceRepos.value.length === 0) {
      return null;
    }
    // We assume all repos in the workspace have the same provider (excluding dummy repo)
    const repoData = workspaceRepos.value[0].metadata;
    return getRepoHostingKey(repoData);
  });

  const isCurrentWorkspaceAuthorized = computed(() => {
    const isAuthorized = !!(workspaceGitHosting.value && authorizedGitHostings.value[workspaceGitHosting.value]);
    return isAuthorized;
  });

  const currentRepoData = computed(() => {
    if (!repoId.value) {
      return null;
    }

    return db_getRepo.value(repoId.value);
  });

  const repoGitHosting = computed(() => {
    if (!currentRepoData.value) {
      return null;
    }

    return getRepoHostingKey(currentRepoData.value.metadata);
  });

  const isCurrentRepoAuthorized = computed(() => {
    logger.info(
      `isCurrentRepoAuthorized: Current repo: ${JSON.stringify(repoId.value)}, looking for gitHostingKey: ${
        repoGitHosting.value
      } in authorizedGitHostings: ${JSON.stringify(Object.keys(authorizedGitHostings.value))}`
    );
    const isAuthorized = !!(repoGitHosting.value && authorizedGitHostings.value[repoGitHosting.value]);
    logger.info(`isCurrentRepoAuthorized? ${isAuthorized}`);
    return isAuthorized;
  });

  const userRepos = computed<Record<string, RepoStateData>>(() => {
    return Object.keys(reposStateData.value).reduce((obj, repoId) => {
      if (!isRepoIdDummyRepo(repoId) && reposStateData.value[repoId]) {
        obj[repoId] = reposStateData.value[repoId];
      }
      return obj;
    }, {});
  });

  async function fetchReposConfigData(): Promise<void> {
    const repoHostings: string[] = Object.values(userRepos.value)
      .map((repo: RepoStateData) =>
        UrlUtils.getGitHostingKey({ provider: repo.provider, gitUrl: repo.api_url, tenantId: repo.tenant_id })
      )
      .filter((hostNameKey) => !!hostNameKey);
    const uniqueHostingKeys = new Set(repoHostings);
    await fetchGitHostingsConfigData(Array.from(uniqueHostingKeys));
  }

  function getRepoHostingKey(repoData: DBRepo): string {
    return UrlUtils.getGitHostingKey({
      provider: repoData.provider,
      gitUrl: repoData.api_url,
      tenantId: repoData.tenant_id,
    });
  }

  /**
   * Authorizes a named `provider`
   * If the `provider` is an enterprise/server it needs to get `gitUrl` as well
   * @param provider - the provider to authorize against
   * @param shouldAllowAccessToPrivateRepos - what scope the token should get
   * @param origin - for analytics
   * @param gitUrl - the git hosting url for enterprise/server provider
   * @param tenantId - the tenant id for tenant based providers (ex. azure)
   */
  async function authorizeProviderWithGit({
    provider,
    shouldAllowAccessToPrivateRepos,
    origin,
    gitUrl,
    redirect,
    tenantId,
  }: {
    provider: GitProviderName;
    origin: string;
    gitUrl?: string;
    shouldAllowAccessToPrivateRepos?: boolean;
    redirect?: string;
    tenantId?: string;
  }): Promise<void> {
    const enterpriseHost = UrlUtils.getGitHosting(provider, gitUrl);
    if (!enterpriseHost) {
      logger.error(`Couldn't get git hosting url from ${provider || gitUrl}, origin ${origin}`);
    }

    await authorizeIfNeeded({
      gitHostingUrl: enterpriseHost,
      tenantId,
      provider,
      shouldAllowAccessToPrivateRepos,
      origin,
      redirect,
    });
  }

  /**
   * checks if a given `provider` is authorized
   * If the `provider` is an enterprise/server it needs to get `gitUrl` as well
   * @param provider - the provider to authorize against
   * @param gitUrl - the git hosting url for enterprise/server provider
   * @param tenantId - the tenant id for tenant based providers (ex. azure)
   */
  function isProviderAuthorized({
    provider,
    gitUrl,
    tenantId,
  }: {
    provider: GitProviderName;
    gitUrl?: string;
    tenantId?: string;
  }): boolean {
    const hosting = UrlUtils.getGitHostingKey({ provider, gitUrl, tenantId });
    logger.info(`isProviderAuthorized: ${hosting}`);
    const isAuthorized = isGitHostingUrlAuthorized(hosting);
    logger.info(`isProviderAuthorized? ${isAuthorized}`);
    return isAuthorized;
  }

  async function validateGitAuthorizationForRepo(repoId: string): Promise<void> {
    logger.info(`validateGitAuthorizationForRepo: ${repoId}`);
    const repoData = db_getRepo.value(repoId);
    if (!repoData) {
      return;
    }

    const repoHosting = UrlUtils.getGitHosting(repoData.metadata.provider, repoData.metadata.api_url);
    logger.info(`Repo hosting: ${repoHosting}`);

    // This check happens due to workaround for clients that wants to use the access token for bitbucket instead of OAuth
    // Remove this check once the POC with them is done
    if (!isWorkspaceWithAccessToken.value) {
      await validateGitHostingToken(repoData.metadata.provider, repoHosting, repoData.metadata.tenant_id);
    }
  }

  function resetData(): void {
    authorizedGitHostings.value = {};
  }
  function setGitHostingUrlAuthorized(gitHostingUrl: string): void {
    authorizedGitHostings.value[gitHostingUrl] = true;
  }

  return {
    repoGitHosting,
    isCurrentWorkspaceAuthorized,
    isCurrentRepoAuthorized,
    fetchReposConfigData,
    authorizeProviderWithGit,
    validateGitAuthorizationForRepo,
    isProviderAuthorized,
    resetData,
    setGitHostingUrlAuthorized,
  };
});
