<script setup>
import { RepoPageRouteNames } from '@/common/consts';
import { Icon, SwText } from '@swimm/ui';
import { computed } from 'vue';
import { useCopyToClipboard } from '@swimm/editor';
import { GitProviderTerminology, UrlUtils } from '@swimm/shared';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { storeToRefs } from 'pinia';
import BranchTag from '@/modules/core/components/BranchTag.vue';

const props = defineProps({
  repoUrl: { type: String, required: true },
  prId: { type: Number, required: true },
  prTitle: { type: String, required: true },
  baseBranch: { type: String, required: true },
  headBranch: { type: String, required: true },
});

const { reposStateData, repoId } = storeToRefs(useReposStore());
const repoData = computed(() => reposStateData.value[repoId.value]);

const prUrl = computed(() => UrlUtils.generatePrUrl(props.repoUrl, repoData.value?.provider, props.prId));

const { copiedToClipboard, copyToClipboard } = useCopyToClipboard();
const gitProviderName = computed(() => GitProviderTerminology[repoData.value?.provider].displayName);
</script>

<template>
  <div class="header">
    <div class="info">
      <div class="pr">
        <SwText variant="subtitle-XL" component="span" class="title">{{ prTitle }}</SwText>
        <a :href="prUrl" class="id"
          ><SwText variant="body-XS" component="span">#{{ prId }}</SwText></a
        >
      </div>
      <SwText variant="system-body" class="branches">
        <BranchTag>{{ baseBranch }}</BranchTag>
        <Icon name="arrow-next-up" class="arrow-icon" />
        <BranchTag data-testId="head-branch">{{ headBranch }}</BranchTag>
        <Icon
          class="copy-icon"
          :class="{ checked: copiedToClipboard }"
          :name="copiedToClipboard ? 'check' : 'copy'"
          @click="copyToClipboard(headBranch)"
        />
      </SwText>
    </div>
    <div class="actions">
      <router-link :to="{ name: RepoPageRouteNames.DOCUMENTATIONS, params: { branch: props.headBranch } }" class="link"
        >Go to branch</router-link
      >
      <a class="link" :href="prUrl" target="_blank"
        >Open on {{ gitProviderName }}<Icon name="outbound-link" class="link-icon" no-padding
      /></a>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--color-surface);
  padding: var(--space-base) var(--space-sm);
  border-bottom: var(--table-border);
  color: var(--text-color-secondary);

  .info {
    max-width: 75%;

    .pr {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: var(--space-xs);

      .title {
        color: var(--text-color-primary);
      }

      .id {
        font-weight: normal;
        color: var(--text-color-secondary);
        margin-left: var(--space-base);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .branches {
      display: flex;
      align-items: center;

      .arrow-icon {
        margin: 0 var(--space-xs);
        padding: 0;
        font-size: var(--fontsize-xxs);
        font-weight: bold;
        transform: rotateZ(-90deg);
      }
    }
  }

  .actions {
    display: flex;
    gap: var(--space-sm);
    font-size: var(--body-S);

    .link {
      display: flex;
      align-items: center;
      gap: var(--space-xs);
      color: var(--text-color-link);

      &:hover {
        color: var(--color-brand-hover);
      }

      .link-icon {
        color: var(--text-color-primary);
      }
    }
  }
}

.copy-icon {
  font-size: var(--body-L);
  cursor: pointer;

  &:not(.checked):hover {
    color: var(--text-color-link);
  }

  &.checked {
    color: var(--text-color-success);
    cursor: default;
  }
}
</style>
