<script setup lang="ts">
import { ref, toRef, watch } from 'vue';
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { getSwimmEditorServices } from '../extensions/Swimm';
import { BaseButton, BaseIcon } from '@swimm/reefui';
import { directive as vTippy } from 'vue-tippy';
import { getUploadImageErrorMessage, pickImage } from '../image';
import { useTiptapIsSelected } from '@/composables/tiptapIsSelected';
import type * as _model from '@tiptap/pm/model';
import ImageNotFound from '@/components/ImageNotFound.vue';
import ImageView from '@/components/ImageView.vue';
import { getLoggerNew } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

const props = defineProps(nodeViewProps);

const uploading = ref(false);
const error = ref(false);

const swimmEditorServices = getSwimmEditorServices(props.editor);

const { selected } = useTiptapIsSelected(toRef(props, 'editor'), toRef(props, 'node'), toRef(props, 'getPos'));

function restoreOriginalSize() {
  props.editor.commands.restoreOriginalBlockImageSize(props.getPos());
}

function shrink() {
  props.editor.commands.shrinkBlockImage(props.getPos());
}

function expand() {
  props.editor.commands.expandBlockImage(props.getPos());
}

async function replaceImage() {
  try {
    const image = await pickImage();
    props.editor.commands.focus();

    if (image != null) {
      uploading.value = true;
      try {
        const src = await swimmEditorServices.external.uploadImage(image, swimmEditorServices.unitId.value);
        props.updateAttributes({ src });
      } finally {
        uploading.value = false;
      }
    }
  } catch (err) {
    logger.error({ err }, `Failed to upload image or update attributes`);
    swimmEditorServices.external.showNotification(getUploadImageErrorMessage(err as Error), {
      icon: 'error',
    });
  }
}

watch(
  () => props.node.attrs.src,
  () => {
    error.value = false;
  }
);
</script>

<template>
  <NodeViewWrapper
    draggable="true"
    data-drag-handle
    :class="{ 'block-image': true, 'block-image--selected': selected }"
  >
    <span
      class="block-image__container"
      :style="{
        width: !error ? node.attrs.width : null,
      }"
    >
      <ImageView
        v-if="!error"
        data-testid="block-image"
        class="block-image__image"
        :src="node.attrs.src"
        :alt="node.attrs.alt"
        :title="node.attrs.title"
        :editor="editor"
        @error="error = true"
      />
      <ImageNotFound
        v-else
        data-testid="error-block-image"
        class="block-image__not-found"
        :src="node.attrs.src"
        @click="replaceImage"
      />
      <BaseIcon v-if="uploading" class="block-image__uploading-loader" name="refresh" />
      <span v-if="swimmEditorServices.editable" contenteditable="false" class="block-image__actions">
        <span v-if="!error">
          <BaseButton
            v-tippy="'Original Size'"
            variant="tertiary"
            data-testid="block-image-original-size"
            @click="restoreOriginalSize"
          >
            <template #leftIcon>
              <BaseIcon name="revert-all" />
            </template>
          </BaseButton>
        </span>
        <span v-if="!error">
          <BaseButton v-tippy="'Shrink'" variant="tertiary" data-testid="block-image-shrink" @click="shrink">
            <template #leftIcon>
              <BaseIcon name="resize-shrink" />
            </template>
          </BaseButton>
        </span>
        <span v-if="!error">
          <BaseButton v-tippy="'Expand'" variant="tertiary" data-testid="block-image-expand" @click="expand">
            <template #leftIcon>
              <BaseIcon name="resize-expand" />
            </template>
          </BaseButton>
        </span>
        <span v-if="!error">
          <BaseButton
            v-tippy="'Replace'"
            variant="tertiary"
            name="image"
            data-testid="block-image-replace"
            @click="replaceImage"
          >
            <template #leftIcon>
              <BaseIcon name="image" />
            </template>
          </BaseButton>
        </span>
      </span>
    </span>
  </NodeViewWrapper>
</template>

<style scoped lang="scss">
.block-image {
  text-align: center;
  position: relative;

  &__container {
    display: inline-block;
    position: relative;
    line-height: 0;
    max-width: 100%;
  }

  &--selected &__container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-bg-selected);
    opacity: 0.6;
  }

  &__image {
    width: 100%;
  }

  &__not-found {
    cursor: pointer;
    width: 100%;
  }

  // Needed to win in specificity against `.ProseMirror [contenteditable="false"]`
  @at-root & &__actions {
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translate(-50%, 0);
    white-space: nowrap;

    border-radius: 6px;
    background-color: var(--color-bg-default);
    line-height: 0.5rem;

    display: none;
  }

  &--selected &__actions,
  &:hover &__actions {
    display: inline;
  }

  // TODO Might need better styling
  &__uploading-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 50%);
    display: inline-block;
    // TODO The animation might be off-center
    animation: spin 0.5s linear infinite;
  }
}
</style>
