<script setup lang="ts">
import { nodeViewProps } from '@tiptap/vue-3';
import { type OpenFileParam, SwmTokenNodeViewInner } from '@swimm/editor';
import { computed, nextTick, ref, toRef, watchEffect } from 'vue';
import { getSwimmEditorServices } from '../extensions/Swimm';
import { getSwimmNodeId } from '../../swmd/swimm_node';
import {
  ApplicabilityStatus,
  type SmartElementWithApplicability,
  type Token,
  isSmartElementWithNewInfo,
  state,
} from '@swimm/shared';
import { useTiptapIsSelected } from '../../composables/tiptapIsSelected';
import * as _model from '@tiptap/pm/model'; // Silence TS bug https://github.com/microsoft/TypeScript/issues/42873

const props = defineProps(nodeViewProps);

const swimmEditorServices = getSwimmEditorServices(props.editor);

const { selected } = useTiptapIsSelected(toRef(props, 'editor'), toRef(props, 'node'), toRef(props, 'getPos'));

const customConfiguration = computed(() => swimmEditorServices.getCustomConfiguration(props.extension.name));

const swimmNodeId = computed(() => {
  return getSwimmNodeId(props.node);
});

const crossRepo = computed(() => props.node.attrs.repoId !== swimmEditorServices.repoId.value);

const autosyncedElement = computed(() => {
  return swimmEditorServices.autosyncOutput.value?.smartElements.get(swimmNodeId.value) as
    | SmartElementWithApplicability<Token>
    | undefined;
});

const branch = ref<string>();
watchEffect(async () => {
  if (!swimmEditorServices.isAuthorized.value) {
    branch.value = undefined;
    return;
  }

  if (!crossRepo.value) {
    branch.value = swimmEditorServices.branch.value;
    return;
  }

  branch.value = swimmEditorServices.getRepo(props.node.attrs.repoId)?.defaultBranch;
});

const shouldHideAutoSyncSameTextMessage = ref(false);
const HIDE_AUTOSYNC_SAME_TEXT_MESSAGE_LOCAL_STATE_KEY = 'hide-autosync-same-text-message';
const hasAutosyncedSameText = computed(() => {
  if (!autosyncedElement.value) {
    return false;
  }
  return (
    autosyncedElement.value.applicability === ApplicabilityStatus.Autosyncable &&
    autosyncedElement.value?.symbolText === props.node.attrs.token
  );
});
watchEffect(async () => {
  if (hasAutosyncedSameText.value && !shouldHideAutoSyncSameTextMessage.value) {
    shouldHideAutoSyncSameTextMessage.value = await state.get({
      key: HIDE_AUTOSYNC_SAME_TEXT_MESSAGE_LOCAL_STATE_KEY,
      defaultValue: false,
    });
  }
});

const repo = computed(() => {
  return swimmEditorServices.getRepo(props.node.attrs.repoId);
});

const repoName = computed(() => {
  return props.node.attrs.repoName ?? 'Unknown repo';
});

const originalData = computed(() => {
  return {
    path: props.node.attrs.path.replace(/^\//, ''),
    lineData: props.node.attrs.lineData,
    lineNumber: props.node.attrs.pos.line,
    text: props.node.attrs.token,
    wordIndex: { start: props.node.attrs.pos.wordStart, end: props.node.attrs.pos.wordEnd },
  };
});

const displayData = computed(() => {
  if (autosyncedElement.value != null && isSmartElementWithNewInfo(autosyncedElement.value)) {
    return {
      path: autosyncedElement.value.newInfo.filePath,
      lineData: autosyncedElement.value.newInfo.lineContent,
      lineNumber: autosyncedElement.value.newInfo.lineNumber,
      text: autosyncedElement.value.newInfo.symbolText,
      wordIndex: {
        start: autosyncedElement.value.newInfo.wordIndex.start,
        end: autosyncedElement.value.newInfo.wordIndex.end,
      },
    };
  }

  return originalData.value;
});

const shouldAnimateNode = computed(() => {
  return swimmEditorServices.animations.shouldAnimateNode(swimmNodeId.value);
});

function editToken() {
  const pos = props.getPos();
  props.editor
    .chain()
    .focus()
    .insertContentAt({ from: pos, to: pos + props.node.nodeSize - 1 }, `\`${props.node.attrs.token}`)
    .run();
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function deleteToken({ context }: { context: string }) {
  props.editor.chain().focus().convertSwmTokenToCode(props.getPos()).run();
  // TODO analytics
}

function openFile(param: OpenFileParam) {
  swimmEditorServices.external.openFile(param);
}

async function hideAutoSyncSameTextMessage() {
  shouldHideAutoSyncSameTextMessage.value = true;
  await state.set({
    key: HIDE_AUTOSYNC_SAME_TEXT_MESSAGE_LOCAL_STATE_KEY,
    value: true,
  });
}
</script>

<template>
  <SwmTokenNodeViewInner
    :repo-id="node.attrs.repoId"
    :original-data="originalData"
    :display-data="displayData"
    :symbol-id="swimmNodeId"
    :is-selected="selected"
    :symbol-applicability="autosyncedElement?.applicability ?? ApplicabilityStatus.Unknown"
    :is-authorized="swimmEditorServices.isAuthorized.value"
    :is-cross-repo="crossRepo"
    :should-hide-auto-sync-same-text-message="shouldHideAutoSyncSameTextMessage"
    :is-editable="swimmEditorServices.editable.value"
    :branch="branch ?? '' /* TODO */"
    :repo-name="repoName"
    :is-repo-in-workspace="!!repo"
    :mark-as-verified="
      () =>
        autosyncedElement != null && editor.commands.applyAutosync(new Map([[autosyncedElement.id, autosyncedElement]]))
      /* TODO Why not an emit? */
    "
    :edit-token="editToken /* TODO Why not an emit? */"
    :delete-token="deleteToken /* TODO Why not an emit? */"
    :repo-icon="swimmEditorServices.getRepoIconName(node.attrs.repoId)"
    :popupshown="() => {} /* TODO analytics */ /* TODO Why not an emit? */"
    :should-animate-node="() => shouldAnimateNode /* TODO Why a function? */"
    :hide-applicability="customConfiguration?.hideApplicability"
    :skip-preview="!!customConfiguration?.skipPreview"
    @focus-before-node="
      async () => {
        // focus-before-node is emitted every time the 'hide' is called on the vDropdown popper on the inner view.
        // This causes the component to rerender outside the component life cycle and throw an error
        // Using nextTick assures this doesn't happen
        await nextTick();
        editor.commands.focus(getPos());
      }
    "
    @hide-auto-sync-same-text-message="hideAutoSyncSameTextMessage"
    @open-file="openFile"
  />
</template>
