<script setup lang="ts">
import { computed, useAttrs, useSlots } from 'vue';
import BaseLayoutIcons from '../BaseLayoutIcons/BaseLayoutIcons.vue';

const props = withDefaults(
  defineProps<{
    /**
     * The `href` value for the anchor.
     */
    href?: string;
    /**
     * Open the link in a new tab? Note that `noopener noreferrer` is applied automatically.
     */
    blank?: boolean;
    /**
     * Diable the anchor.
     */
    disabled?: boolean;
    /**
     * If `href` is blank the anchor renders as a `span` by default. You can override this element using this prop.
     */
    fallbackWrapper?: string;

    /**
     * If true, the anchor will not have any text decoration.
     */
    noDecoration?: boolean;
  }>(),
  {
    href: undefined,
    blank: false,
    disabled: false,
    fallbackWrapper: 'span',
    noDecoration: false,
  }
);

const slots = useSlots();
const attrs = useAttrs();

const computedTarget = computed(() => {
  if (attrs.target) {
    return attrs.target;
  }

  return props.blank ? '_blank' : undefined;
});

const computedWrapper = computed(() => {
  return props.href ? 'a' : props.fallbackWrapper;
});

const computedRel = computed(() => {
  if (attrs.rel) {
    return attrs.rel;
  }

  // https://web.dev/external-anchors-use-rel-noopener/
  return props.blank ? 'noopener noreferrer' : undefined;
});

const computedClasses = computed(() => {
  return {
    'anchor--fallback': !props.href,
    'anchor--no-decoration': props.noDecoration,
  };
});

const computedRole = computed(() => {
  return computedWrapper.value !== 'a' ? 'link' : undefined;
});

const hasLeftIcon = computed(() => !!slots.leftIcon);
const hasRightIcon = computed(() => !!slots.rightIcon);

function handleClick(evt: Event): void {
  if (props.disabled) {
    evt.preventDefault();
    return;
  }
}
</script>

<template>
  <component
    :is="computedWrapper"
    :rel="computedRel"
    class="anchor"
    :href="href"
    :role="computedRole"
    :aria-disabled="disabled || undefined"
    :class="computedClasses"
    :tabindex="disabled ? -1 : undefined"
    :target="computedTarget"
    @click="handleClick"
  >
    <BaseLayoutIcons>
      <template v-if="hasLeftIcon" #leftIcon>
        <slot name="leftIcon" />
      </template>
      <template #default>
        <span class="anchor__text">
          <slot name="default" />
        </span>
      </template>
      <template v-if="hasRightIcon" #rightIcon>
        <slot name="rightIcon" />
      </template>
    </BaseLayoutIcons>
  </component>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.anchor {
  $self: &;

  @include defaults;

  color: var(--color-text-brand);
  display: inline-block;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--color-text-default);

    #{$self}__text {
      text-decoration: underline;
    }
  }

  &:visited {
    color: var(--color-text-brand);
  }

  &--no-decoration {
    text-decoration: none;
  }

  &[aria-disabled] {
    cursor: no-drop;
    color: var(--color-text-disabled);
    text-decoration: underline;
  }
}
</style>
