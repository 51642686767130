<script setup lang="ts">
import { ref } from 'vue';
import { BaseInput, BaseLoading, BaseProse } from '@swimm/reefui';
import { SwModal } from '@swimm/ui';
import type { GiphyFetch } from '@giphy/js-fetch-api';
import type { IGif } from '@giphy/js-types';
import { computedAsync, debouncedRef } from '@vueuse/core';

export type GiphyClient = Pick<GiphyFetch, 'trending' | 'search'>;

const props = defineProps<{
  modelValue: boolean;
  giphyClient: GiphyClient;
}>();

const emit = defineEmits<{
  'update:modelValue': [modelValue: boolean];
  selected: [url: string];
}>();

const searchQuery = ref('');
const debouncedSearchQuery = debouncedRef(searchQuery, 500);
const loading = ref(false);
const error = ref(false);

const GIPHY_OPTIONS = {
  limit: 30,
  rating: 'g',
} as const;

const gifs = computedAsync(
  async () => {
    if (!props.modelValue) {
      return;
    }

    if (debouncedSearchQuery.value.length === 0) {
      return (await props.giphyClient.trending(GIPHY_OPTIONS)).data;
    }

    return (await props.giphyClient.search(debouncedSearchQuery.value, GIPHY_OPTIONS)).data;
  },
  null,
  {
    evaluating: loading,
    onError() {
      error.value = true;
    },
  }
);

function handleClose() {
  emit('update:modelValue', false);
}

function handleSelected(gif: IGif) {
  emit('selected', gif.images.original.url);
  emit('update:modelValue', false);
}
</script>

<template>
  <!-- TODO SwModal doesn't propertly propagate attrs, so we can't put a class
            on it, we probably want to replace it with a reefui equivalent anyhow -->
  <SwModal :show-modal="modelValue" :show-border="true" heading="Giphy" @close="handleClose">
    <div class="giphy-selection-modal__container">
      <BaseInput
        v-model="searchQuery"
        type="search"
        placeholder="Search GIPHY"
        data-testid="giphy-search-box"
      ></BaseInput>
      <div class="giphy-modal-content">
        <BaseLoading v-if="loading" variant="secondary" />
        <BaseProse v-else-if="error">Failed to load from GIPHY</BaseProse>
        <div v-else-if="gifs && gifs.length > 0" class="columns">
          <img
            v-for="gif of gifs"
            :key="gif.id"
            class="gif"
            data-testid="giphy-search-box-gif"
            :src="gif.images.fixed_width_small.url"
            @click="handleSelected(gif)"
          />
        </div>
        <BaseProse v-else variant="secondary">No results</BaseProse>
      </div>
      <div class="giphy-logo-wrapper">
        <!-- TODO Dark mode, is there a clean way to achieve this with CSS? -->
        <img v-if="true" class="giphy-logo" src="./assets/giphy-light.png" alt="Powered by GIPHY" />
        <img v-else class="giphy-logo" src="./assets/giphy-dark.png" alt="Powered by GIPHY" />
      </div>
    </div>
  </SwModal>
</template>

<style scoped lang="scss">
.giphy-modal-content {
  overflow-y: auto;
  width: 300px;
  height: 300px;
}

.columns {
  column-count: 3;
}

.gif {
  cursor: pointer;
  transition: opacity 0.3s;
}

.gif:hover {
  opacity: 0.6;
}

.giphy-logo-wrapper {
  margin-top: 8px;
  text-align: right;
}

.giphy-logo {
  height: 12px;
}
</style>
