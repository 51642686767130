import { LanguageIdentifier } from './language-identifier';
import { makeRegex, newlinePattern } from './utils/regex-utils';
import XRegExp from 'xregexp';
import GlobToRegExp from 'glob-to-regexp';
import { isPatternMatchInComment } from './source-context';
import {
  DocHookActionType,
  DocHookChangesScope,
  DocHookFileSystemRule,
  DocHookIdeCodelensAction,
  DocHookRegexRule,
  DocHookRuleType,
  PatternScope,
  SwmCellRule,
} from './types';
import { SWM_RULE_EXTENSION } from './config';
export interface PopulatedSwmCellRule extends SwmCellRule {
  docPath: string;
  hoverTitle: string;
}

export function isHookApplicable(
  ruleCell: SwmCellRule,
  langId: LanguageIdentifier,
  filePath: string,
  changeRanges?: lineRange[]
): boolean {
  if (ruleCell.rule.type === DocHookRuleType.Regex) {
    if (ruleCell.rule.globPatterns) {
      if (!ruleCell.rule.globPatterns.some((pattern) => GlobToRegExp(pattern).test(filePath))) {
        return false;
      }
    }
    if (ruleCell.rule.applyOnChanges) {
      if (!changeRanges || changeRanges.length === 0) {
        // There are no code changes
        return false;
      }
    }
    return !ruleCell.rule.applicableLanguages || ruleCell.rule.applicableLanguages.includes(langId);
  }
  return true;
}

export function getHookDescription(ruleCell: SwmCellRule): string {
  switch (ruleCell.rule.type) {
    case DocHookRuleType.Regex:
      return `regex pattern ${ruleCell.rule.pattern}`;
    case DocHookRuleType.FileSystem:
      return `Filesystem pattern looking at ${getTextualPattern(ruleCell.rule)}.`;
  }
  return '';
}

function getTextualPattern(fileSystemRule: DocHookFileSystemRule): string {
  const patternTypes: string[] = [];
  if (fileSystemRule.createdFiles) {
    patternTypes.push('created files');
  }
  if (fileSystemRule.modifiedFiles) {
    patternTypes.push('modified files');
  }
  if (fileSystemRule.deletedFiles) {
    patternTypes.push('deleted files');
  }
  return `${patternTypes.join(' / ')} matching ${fileSystemRule.globPattern}`;
}

export function findRegexHookInFile(
  regexRule: DocHookRegexRule,
  fileContents: string,
  languageId: LanguageIdentifier,
  changeRanges?: lineRange[]
): lineRange[] {
  if (fileContents === '') {
    return [];
  }

  const matchedRanges: lineRange[] = [];

  const matchPattern: RegExp = makeRegex(regexRule.regexType, regexRule.pattern, regexRule.modifiers);
  let matchPosition = 0;
  let match = XRegExp.exec(fileContents, matchPattern, matchPosition);
  while (match) {
    const lineStart: number = getLineNumber(fileContents, match.index);
    const newlineIndex: number = lineStart === 0 ? -1 : fileContents.substr(0, match.index).lastIndexOf('\n');
    const columnStart: number = match.index - newlineIndex - 1;

    // Since a match may span lines (someone who broke a long function invocation into multiple lines for example)
    // it's necessary to see if there are any newlines WITHIN the match so that we get the line the match ends on,
    // not just the line it starts on.
    const replacementSource: string = fileContents.substr(match.index, match[0].length);
    const lineEnd: number = getLineNumber(replacementSource, replacementSource.length) + lineStart;

    const columnEnd =
      lineStart === lineEnd
        ? columnStart + match[0].length
        : match[0].length - fileContents.substring(match.index).lastIndexOf('\n') - 1;

    const range: lineRange = { lineStart, columnStart, lineEnd, columnEnd, lineStartOffset: match.index };

    if (
      isMatchedInScope(
        languageId,
        fileContents.substr(0, match.index),
        newlineIndex,
        regexRule.scope || PatternScope.all,
        range,
        regexRule.applyOnChanges,
        changeRanges
      )
    ) {
      matchedRanges.push(range);
    }

    // Advance the location we are searching in the line
    matchPosition = match.index + match[0].length;
    match = XRegExp.exec(fileContents, matchPattern, matchPosition);
  }
  return matchedRanges;
}

function getLineNumber(documentContents: string, currentPosition: number): number {
  const subDocument: string = documentContents.substr(0, currentPosition);
  const linebreaks: RegExpMatchArray | null = subDocument.match(newlinePattern);
  return linebreaks !== undefined && linebreaks !== null ? linebreaks.length : 0;
}

export interface lineRange {
  lineStart: number;
  columnStart: number;
  lineEnd: number;
  columnEnd: number;
  lineStartOffset: number;
}

function isMatchedInScope(
  langID: LanguageIdentifier,
  docContentsToFinding: string,
  newlineIndex: number,
  scope: PatternScope,
  patternRange: lineRange,
  changesScope?: DocHookChangesScope,
  changesRanges?: lineRange[]
): boolean {
  if (changesScope) {
    if (!changesRanges || changesRanges.length === 0) {
      return false;
    }
    if (changesScope === DocHookChangesScope.lineChanged) {
      const isInChangesRange = changesRanges.some(
        (range) => range.lineStart <= patternRange.lineStart && range.lineEnd >= patternRange.lineStart
      );
      if (!isInChangesRange) {
        return false;
      }
    }
  }
  switch (scope) {
    case PatternScope.all:
      return true;
    case PatternScope.code:
      return !isPatternMatchInComment(langID, docContentsToFinding, newlineIndex, patternRange);
    case PatternScope.comment:
      return isPatternMatchInComment(langID, docContentsToFinding, newlineIndex, patternRange);
  }
}

export function isTriggersSwmFile(name: string): boolean {
  return name.endsWith(SWM_RULE_EXTENSION);
}

export function getIdeDocHookActionFromRule(ruleCell: SwmCellRule): DocHookIdeCodelensAction {
  return ruleCell.actions.find(
    (action): action is DocHookIdeCodelensAction => action.type === DocHookActionType.IdeCodeLens
  );
}

export function getDocHookComment({
  action,
  isPublic,
  docName = '',
  tagCreator = true,
}: {
  action: DocHookIdeCodelensAction;
  isPublic: boolean;
  docName?: string;
  tagCreator?: boolean;
}): string {
  const creatorMessage = action.creator ? `🙋🏻‍♂️ ${tagCreator ? `@` : ``}${action.creator} ` : '';
  const preamble = isPublic ? '(Swimm):' : '(Doc Hook):';
  let comment = `${creatorMessage}${preamble} `;
  if (action.description) {
    comment = comment + action.description;
  }
  if (action.docId) {
    if (docName) {
      if (!action.description) {
        comment = comment + docName;
      }
    }
  }
  return comment;
}
