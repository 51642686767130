import { BASE_URL } from '../config';
import { GitProviderName, SwmSymbolLinkType } from '../types';
import { getLoggerNew } from '#logger';
import { gitUrlParseWrapper } from '../git-utils/git-provider-utils';

const logger = getLoggerNew("packages/shared/src/utils/url-utils.ts");

export class UrlUtils {
  static isValidEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static isYoutubeLink(url: string) {
    return /^(http(s)??:\/\/)?(www\.)?(m.)?((youtube(-nocookie)?\.com\/)|(youtu.be\/))+/.test(url);
  }

  static isWistiaLink(url: string) {
    return /https?:\/\/(.+)?(wistia\.com|wi\.st)\/(medias|embed)\/.*/.test(url);
  }
  static generatePrUrl(repoUrl: string, provider: GitProviderName, prId: string) {
    const url = this.normalizeRepositoryLink(repoUrl, provider).replace(/\.git$/, '');
    if (provider === GitProviderName.GitLab || provider === GitProviderName.GitLabEnterprise) {
      return `${url}/merge_requests/${prId}`;
    } else if (provider === GitProviderName.Bitbucket || provider === GitProviderName.BitbucketDc) {
      return `${url}/pull-requests/${prId}`;
    } else if (provider === GitProviderName.AzureDevops) {
      return `${url}/pullrequest/${prId}`;
    } else {
      // Currently only tested and verified on GitHub
      return `${url}/pull/${prId}`;
    }
  }

  static normalizeRepositoryLink(repoUrl: string, provider: GitProviderName): string {
    let url: string = repoUrl;
    if (!url.startsWith('http')) {
      // handle the case that the url is ssh://
      try {
        url = gitUrlParseWrapper(url).toString('https');
      } catch (err) {
        logger.error({ err }, `Failed to parse non http url ${url}`);
      }
    }
    if (provider === GitProviderName.BitbucketDc) {
      url = url.replace(/\/scm\/(.*)\/(.*)\.git/, '/projects/$1/repos/$2');
    } else if (provider === GitProviderName.Bitbucket) {
      url = url.replace(/\.git/, '');
    }
    return url;
  }

  static getBranchComparisonUrl(
    htmlUrl: string,
    provider: GitProviderName,
    branch: string,
    repoData: { fork: boolean; defaultBranch: string }
  ): string {
    let url: string = htmlUrl;
    if (provider === GitProviderName.Bitbucket) {
      url = `${htmlUrl}/branches/compare/${branch}%0D${repoData.defaultBranch}`;
    } else {
      // Confirmed on GitHub.
      url = repoData.fork ? `${htmlUrl}/pulls` : `${htmlUrl}/compare/${branch}?expand=1`;
    }
    return url;
  }

  static getLinkType(url: string) {
    return (
      (UrlUtils.isYoutubeLink(url) && 'youtube') ||
      (UrlUtils.isWistiaLink(url) && 'wistia') ||
      (UrlUtils.isRepoMarkdownFile(url) && 'markdown') ||
      'default'
    );
  }

  static getIconName(url: string) {
    return (
      ((UrlUtils.isYoutubeLink(url) || UrlUtils.isWistiaLink(url)) && 'video') ||
      (UrlUtils.isRepoMarkdownFile(url) && 'doc') ||
      'link'
    );
  }

  static isRepoMarkdownFile(url: string) {
    return url.endsWith('.md');
  }

  static isValidURL(string: string) {
    // Taken from https://www.freecodecamp.org/news/check-if-a-javascript-string-is-a-url/
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // Validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // Validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // Validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // Validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // Validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // Validate fragment locator
    return !!urlPattern.test(string);
  }

  /**
   * E.g., gitUrlToRepoName("https://github.com/sindresorhus/ora.git") --> ora
   */
  static gitUrlToRepoName(gitUrl: string) {
    return gitUrl.split('/').slice(-1)[0].split('.')[0];
  }
  static trimHttps(link: string) {
    return link.replace(/^(https?):\/\//, '');
  }
  static getURLProtocol(url: string): string | null {
    try {
      const urlObject = new URL(url);
      return urlObject.protocol.slice(0, -1);
    } catch (e) {
      return null;
    }
  }
  static getSwimmLink({
    swimmId,
    repoId,
    branch,
    isEdit = false,
    swimmType = SwmSymbolLinkType.Doc,
  }: {
    swimmId: string;
    repoId: string;
    branch?: string;
    isEdit?: boolean;
    swimmType?: SwmSymbolLinkType;
  }): string {
    let resourceURL = `repos/${repoId}`;

    if (branch) {
      resourceURL += `/branch/${branch}`;
    }

    resourceURL += `/${UrlUtils.getResourceUrlCollection(swimmType)}/${swimmId}`;

    if (isEdit) {
      resourceURL += '/edit';
    }

    return `${BASE_URL}/${resourceURL}`;
  }

  static getResourceUrlCollection(swimmType: string): string {
    if (swimmType === SwmSymbolLinkType.Playlist) {
      return 'playlists';
    }
    return swimmType === SwmSymbolLinkType.Exercise ? 'units' : 'docs';
  }

  static isURL(url: string) {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }

  static providerToGitCloudHostingUrl(provider: GitProviderName): string {
    switch (provider) {
      case GitProviderName.GitHub:
        return 'github.com';
      case GitProviderName.GitLab:
        return 'gitlab.com';
      case GitProviderName.Bitbucket:
        return 'bitbucket.org';
      case GitProviderName.AzureDevops:
        return 'dev.azure.com';
      default:
        return null;
    }
  }

  static getGitHostingKey({
    provider,
    gitUrl,
    tenantId,
  }: {
    provider: GitProviderName;
    gitUrl?: string;
    tenantId?: string;
  }) {
    const hostName = UrlUtils.isURL(gitUrl) ? UrlUtils.getUrlHostname(gitUrl) : gitUrl;
    // Multitenant Azure Devops
    if (gitUrl && tenantId) {
      return `${UrlUtils.slugUrlForConfig(hostName)}_${tenantId}`;
    }

    // Enterprise customer
    if (gitUrl) {
      return UrlUtils.slugUrlForConfig(hostName);
    }

    // Cloud provider
    const defaultHostingUrl = UrlUtils.providerToGitCloudHostingUrl(provider);
    return defaultHostingUrl ? UrlUtils.slugUrlForConfig(defaultHostingUrl) : null;
  }

  static getGitHosting(provider: GitProviderName, apiUrl?: string) {
    if (apiUrl) {
      return UrlUtils.isURL(apiUrl) ? UrlUtils.getUrlHostname(apiUrl) : apiUrl;
    }
    return UrlUtils.providerToGitCloudHostingUrl(provider);
  }

  static getUrlHostname<T extends string>(url: T): T extends string ? string : null {
    if (!url) {
      return null as T extends string ? string : null;
    }
    const urlObj = new URL(url);
    return urlObj.hostname as T extends string ? string : null;
  }

  static slugUrlForConfig(url) {
    return url.replaceAll('.', '_');
  }

  static unslugUrlFromConfig(url) {
    return url.replaceAll('_', '.');
  }
}

export default UrlUtils;
