<script setup lang="ts">
import type { Editor } from '@tiptap/vue-3';
import { computed, nextTick, onMounted, ref } from 'vue';
import { Loader, SwText } from '@swimm/ui';
import { getLoggerNew, productEvents } from '@swimm/shared';
import { BaseIcon } from '@swimm/reefui';
import VSelect from 'vue-select';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';

const logger = getLoggerNew(__modulename);

const props = withDefaults(
  defineProps<{
    editor: Editor;
    initialText?: string;
    hide: () => void;
  }>(),
  {
    initialText: undefined,
    hide: undefined,
  }
);

const emit = defineEmits<{
  generated: [text: string];
}>();

const loading = ref(false);
const currentTask = ref<string | null>(null);
const suggestionToPrompt = {
  'Make better': 'Make the text better',
  'Make shorter': 'Make the text shorter',
  'Make longer': 'Make the text longer',
  'Fix spelling & grammar': 'Fix spelling and grammar in the text',
};
const tasksSuggestions = Object.keys(suggestionToPrompt);
const swimmEditorServices = getSwimmEditorServices(props.editor);
const inputRef = ref<HTMLInputElement | null>(null);

onMounted(() => {
  nextTick(() => {
    inputRef.value?.focus();
  });
});

const handleTaskChange = async (value: string) => {
  currentTask.value = value;
  generateResponse();
};

const showTitle = computed(() => {
  return (
    tasksSuggestions.filter((task) => {
      return currentTask.value ? task.toLowerCase().includes(currentTask.value.toLowerCase()) : true;
    }).length > 0
  );
});

const selectedOption = computed(() => {
  return currentTask.value && tasksSuggestions.includes(currentTask.value) ? currentTask.value : null;
});

const isCustom = computed(() => {
  return selectedOption.value === null;
});

const customPrompt = computed(() => {
  return isCustom.value ? currentTask.value : null;
});

const prompt = computed(() => {
  return isCustom.value ? customPrompt.value : suggestionToPrompt[currentTask.value as keyof typeof suggestionToPrompt];
});

function showErrorToast() {
  swimmEditorServices.external.showNotification(`Failed to modify text. Try again.`, {
    icon: 'warning',
  });
}

async function generateResponse() {
  if (swimmEditorServices.workspaceId.value == null) {
    showErrorToast();
    return;
  }

  if (!props.initialText || !currentTask.value?.trim()) {
    return;
  }

  swimmEditorServices.external.trackEvent(productEvents.SENT_IMPROVE_WITH_AI_REQUEST, {
    'Custom Prompt': customPrompt.value,
    'Selected Auto-Complete Option': selectedOption.value,
    'Text Selection Length': props.initialText.length,
  });

  loading.value = true;
  const start = performance.now();

  let response = null;
  try {
    response = await swimmEditorServices.external.generateTextModifier({
      repoId: swimmEditorServices.repoId.value || '',
      workspaceId: swimmEditorServices.workspaceId.value,
      textInput: props.initialText,
      modifier: prompt.value || '',
    });
  } catch (err) {
    logger.error({ err }, 'Failed improving text');
    loading.value = false;
    showErrorToast();
    return;
  }

  const timeTaken = performance.now() - start;

  if (response.status !== 'success' || !response.generatedText) {
    loading.value = false;
    showErrorToast();
    return;
  }

  if (loading.value) {
    swimmEditorServices.external.trackEvent(productEvents.GENERATED_IMPROVE_WITH_AI_RESULT, {
      'Total Runtime MS': timeTaken,
      'Result Length': response.generatedText.length,
      'Text Selection Length': props.initialText.length,
      'Custom Prompt': customPrompt.value,
      'Selected Auto-Complete Option': selectedOption.value,
    });
    emit('generated', response.generatedText);
    loading.value = false;
    props.hide?.();
  }
}

const handleFreeTextChange = (e: Event) => {
  if (e.target) {
    currentTask.value = (e.target as HTMLInputElement).value;
  }
};

function stop() {
  swimmEditorServices.external.trackEvent(productEvents.ABORTED_GEN_AI_CONTENT_REQUEST, { Context: 'Improve with AI' });
  loading.value = false;
  props.hide?.();
}

async function onOpen(value: string) {
  currentTask.value = value;
}

function onEscKeyDown() {
  if (loading.value) {
    stop();
  } else {
    props.hide?.();
  }
}
</script>

<template>
  <div class="ai-text-improvement-popover" @keydown.esc="onEscKeyDown">
    <div class="ai-container" @keyup.enter="generateResponse">
      <BaseIcon name="magic" />
      <div class="title-container">
        <SwText class="generating-title">Improve with AI</SwText>
      </div>
      <VSelect
        v-if="!loading"
        class="suggestions"
        :clearable="false"
        :model-value="currentTask"
        :options="tasksSuggestions"
        placeholder="Make it..."
        @search="onOpen"
        @update:model-value="handleTaskChange"
      >
        <template #search="{ attributes, events }">
          <input v-bind="attributes" ref="inputRef" class="vs__search" v-on="events" @keydown="handleFreeTextChange" />
          <BaseIcon name="enter" class="key" />
        </template>
        <template v-if="showTitle" #list-header>
          <header class="list-header">
            <SwText variant="subtitle-S" weight="bold">Suggestions</SwText>
          </header>
        </template>
        <template #no-options>
          <SwText>Enter to improve with custom prompt</SwText>
        </template>
        <template #option="{ label }">
          <div class="option">
            {{ label }}
          </div>
        </template>
      </VSelect>
      <div v-else class="skeleton-container">
        <div class="key-label-pair">
          <SwText class="stop-link" @click="stop">Stop suggestion</SwText>
          <span class="key">esc</span>
        </div>
        <Loader secondary small />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.ai-text-improvement-popover {
  padding: var(--space-xsmall);
  border: 1px solid var(--color-border-default);
  border-radius: var(--space-xsmall);
  background: var(--color-bg-default);
}

.loader {
  --loader-size: var(--loader-size);
}

.generate-menu {
  margin-top: var(--space-sm);
  border-radius: var(--space-base);
  box-sizing: border-box;
  visibility: visible;
}

.generating-title {
  color: var(--text-color-secondary);
  padding: 0px;
  line-height: 1rem;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.ai-container {
  width: 400px;
  display: flex;
  flex-direction: row;
  gap: 2px;
  flex-shrink: 0;
  align-items: center;
}

/* eslint-disable vue-scoped-css/no-unused-selector */
.icon.icon-magic {
  font-size: var(--body-L);
  padding: 0 var(--space-xs);
  color: var(--text-color-magic-strong);
  transform: translateY(1px);
}

.beta-tag {
  border-radius: 80px;
  font-weight: 400;
  color: var(--high-violet);
  background-color: var(--color-status-magic);
  font-size: var(--body-XXS);
  padding: 0 var(--space-xs);
}

.suggestions {
  margin-left: var(--space-base);
  flex-grow: 1;
}

.suggestions :deep(.vs__open-indicator) {
  fill: var(--text-color-secondary);
}

.suggestions :deep(.vs__dropdown-menu) {
  cursor: pointer;
}

.suggestions :deep(.vs__open) {
  cursor: text;
}

.key {
  cursor: default;
  background-color: var(--color-surface);
  border-radius: 2px;
  font-size: var(--body-S);
  align-self: self-end;
}

.list-header {
  margin-left: 12px;
}

.text-skeleton {
  margin-top: var(--space-base);
  width: 380px;
}

.skeleton-container {
  align-items: center;
  background-color: var(--color-surface);
  padding: 0 var(--space-base);
  color: var(--text-color-secondary);
  gap: var(--space-base);
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin-left: var(--space-base);
}

.key-label-pair {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-grow: 1;
  gap: var(--space-xs);

  .key {
    background-color: var(--color-hover);
    border-radius: 5px;
    font-size: var(--body-XS);
    padding: 2px var(--space-xs);
  }
}

.stop-link {
  cursor: pointer;
  color: var(--text-color-link);
}
</style>
