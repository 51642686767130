<template>
  <section class="repo-documentation-section">
    <div class="section-header-container">
      <slot name="section-header" />
    </div>
    <slot name="unavailable-warning" />
    <ListWrapper
      v-if="shouldShowChangedFilesSection"
      border
      collapsible
      data-testid="changed-files-list"
      class="changed-files"
      :sort-option="sortOption"
      :is-sort-desc="isSortDesc"
      @sort-changed="$emit('sort-changed', $event)"
    >
      <template #header>
        <SwText variant="body-S" class="changed-files-header"
          >Changed in current branch <BranchTag class="branch-tag">{{ currentBranch }}</BranchTag>
          <SwText weight="regular">({{ draftsAndChangedFiles.length }})</SwText></SwText
        >
      </template>
      <RecycleScroller page-mode :items="draftsAndChangedFiles" :item-size="ROW_HEIGHT" key-field="key">
        <template #default="{ item }">
          <DocumentationItem
            v-bind="$attrs"
            :documentation="item"
            :repo-id="repoId"
            @delete="onDeleteItem({ item, repoId })"
            @discard="onDiscard(item)"
            @click="onItemClick({ changedFilesSection: true, item })"
            @open-commit="openBatchCommitModal"
            @open-share-modal="openShareModal($event)"
            @move="onMove(item)"
            show-folder-path
          />
        </template>
      </RecycleScroller>
    </ListWrapper>
    <ListWrapper
      border
      :key="currentFolder?.id"
      :sort-option="sortOption"
      :is-sort-desc="isSortDesc"
      :is-any-selected="isAnySelected"
      :is-all-selected="isAllSelected"
      :num-selected-items="numSelectedItems"
      :is-dummy-repo="isDummyRepo"
      @sort-changed="$emit('sort-changed', $event)"
      @select-all-toggle="onSelectAllToggle"
      @move-click="onMultipleMoveClick"
      @delete-click="onMultipleDeleteClick"
    >
      <template #header>
        <FolderBreadCrumbs
          :class="{ 'folder-bread-crumbs': true, hidden: !showFolderBreadCrumbs }"
          :folders="currentFolderBreadCrumbs"
          :repo-id="repoId"
          @crumb-selected="selectFolder(repoId, $event)"
          @add-folder="handleAddFolder"
          @navigate-to-item="$emit('navigate-to-item', $event)"
          @toggle-creation-hub="$emit('toggle-creation-hub', $event)"
        />
      </template>
      <template v-if="loading">
        <ListViewItemSkeleton v-for="index in SKELETON_LENGTH" :key="index" />
      </template>
      <template v-else>
        <ListViewItem v-if="isAddingNewFolderState" icon="folder" :type="DocumentationTypes.FOLDER">
          <template #name>
            <FolderNameEdit
              id="new-folder-name"
              :repo-id="repoId"
              @change="isAddingNewFolderState = false"
              @cancel="isAddingNewFolderState = false"
            />
          </template>
        </ListViewItem>
        <div v-if="documentationItems.length" @drop="onDrop" @dragenter.prevent @dragover.prevent>
          <RecycleScroller
            data-testid="docs-list"
            :items="documentationItems"
            page-mode
            :item-size="ROW_HEIGHT"
            :buffer="LIST_BUFFER"
            key-field="key"
          >
            <!-- When adding a new folder, this dropzone adds 'padding' below the new folder input field -->
            <template #before v-if="!isAddingNewFolderState">
              <div
                class="drop-zone upper"
                @dragenter="
                  (event) => {
                    !isDraggingMultipleItems && onDragEnter(event, null, { isOrderDropZone: true, isTop: true });
                  }
                "
                @dragleave="onDragLeave"
              />
            </template>
            <template #default="{ item: documentation }">
              <DocumentationItem
                draggable="true"
                :documentation="documentation"
                :repo-id="repoId"
                selectable
                @doc-request-action="handleDocRequestAction"
                @delete="onDeleteItem({ item: documentation, repoId })"
                @discard="onDiscard(documentation)"
                @click="onItemClick({ changedFilesSection: false, item: documentation })"
                @folder-clicked="onFolderClicked(documentation)"
                @open-commit="openBatchCommitModal"
                @open-share-modal="openShareModal($event)"
                @add-folder="handleAddFolder"
                @navigate-to-item="$emit('navigate-to-item', $event)"
                @toggle-creation-hub="$emit('toggle-creation-hub', $event)"
                @move="onMove(documentation)"
                @select="(ev) => onSelect(ev, documentation)"
                @dragstart="onDragStart($event, documentation)"
                @dragenter="onDragEnter($event, documentation)"
                @dragleave="onDragLeave"
              >
                <div
                  class="drop-zone"
                  v-if="isDragging && !isDraggingMultipleItems"
                  @dragenter="onDragEnter($event, documentation, { isOrderDropZone: true })"
                  @dragleave="onDragLeave"
                  :data-id-before="documentation.id"
                />
              </DocumentationItem>
            </template>
          </RecycleScroller>
        </div>
        <div v-else-if="$slots['empty-state']" class="help-box">
          <slot name="empty-state" />
        </div>
      </template>
    </ListWrapper>
    <ShareModal
      :show-share-modal="shareModalProps.showShareModal"
      @close="closeShareModal"
      :entity-id="shareModalProps.entityId"
      :entity-name="shareModalProps.entityName"
      :entity="shareModalProps.entity"
      :entity-link="shareModalProps.entityLink"
      :repo-id="shareModalProps.repoId"
    />
    <MoveFolderModal
      :show="showMoveFolderModal"
      @close="showMoveFolderModal = false"
      :items="itemsToMove"
      :repo-id="repoId"
      ref="moveFolderModalRef"
    />
    <UserDialog
      ref="batchDeleteConfirmationDialog"
      cancel-text="Cancel"
      :confirm-text="batchDeleteConfirmButtonText"
      :title="batchDeleteConfirmQuestion"
      :body="batchDeleteConfirmMessage"
      danger
    >
    </UserDialog>
  </section>
</template>

<script setup lang="ts">
import UserDialog from '@/common/components/modals/UserDialog.vue';
import type { ShareModalProps } from '@/common/components/modals/ShareModal.vue';
import ShareModal from '@/common/components/modals/ShareModal.vue';
import { DocumentationTypes } from '@/common/consts';
import MoveFolderModal from '@/modules/folders/components/MoveFolderModal.vue';
import ListWrapper from '@/modules/core/components/ListWrapper.vue';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import DocumentationItem from './DocumentationItem.vue';
import { FolderItemType, GitProviderName, isRepoIdDummyRepo, iter, productEvents } from '@swimm/shared';
import { clearDismissedSuggestionsForTempDocument } from '@/modules/swimmport/services/swimmport-local-db';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { storeToRefs } from 'pinia';
import { useDeleteItem } from '@/common/composables/delete-item';
import ListViewItemSkeleton from '@/modules/core/components/ListViewItemSkeleton.vue';
import BranchTag from '@/modules/core/components/BranchTag.vue';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useGetStartedMenu } from '@/modules/get-started/composables/useGetStartedMenu';
import { useFoldersStore } from '@/modules/folders/store/folders';
import FolderBreadCrumbs from '@/modules/folders/components/FolderBreadCrumbs.vue';
import FolderNameEdit from '@/modules/folders/components/FolderNameEdit.vue';
import ListViewItem from '@/modules/core/components/ListViewItem.vue';
import { countDocumentationTypes } from '@/common/utils/helpers';
import { type Folder } from '@/modules/folders/types';
import { useNotificationsStore, useScroll } from '@swimm/editor';
import { useFiltersStore } from '@/modules/core/filters-row/useFiltersStore';
import { useResourceActions } from '@/modules/resources/composables/resource-actions';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';

const SKELETON_LENGTH = 20;
const ROW_HEIGHT = 43;
const LIST_BUFFER = 1200;
const DRAGGED_OVER_CLASS = 'dragged-over';

const props = defineProps({
  repo: { type: Object, required: true },
  filteredDocumentation: { type: Array, required: true },
  allDocumentation: { type: Array, required: true },
  loading: { type: Boolean, default: false },
  changedFiles: { type: Array, default: () => [] },
  sortOption: { type: String, default: null },
  isSortDesc: { type: Boolean, default: true },
});

const emit = defineEmits([
  'discard',
  'doc-request-action',
  'open-commit',
  'open-share-modal',
  'navigate-to-item',
  'toggle-creation-hub',
  'sort-changed',
]);
const deleteItemUtils = useDeleteItem();
const route = useRoute();
const drafts3Store = useDrafts3Store();
const analytics = useAnalytics();
const { reposStateData } = storeToRefs(useReposStore());
const { shouldOpenCommitModal } = storeToRefs(useGetStartedMenu());
const { addNotification } = useNotificationsStore();
const foldersStore = useFoldersStore();
const {
  selectFolder,
  getFolderPath,
  getFolderItems,
  getFolderBreadCrumbs,
  getAllFolderSubItems,
  getFolderItemType,
  deleteDbFoldersAndDraftsAndMoveItemsToRootFolder,
  moveItemsToRootFolder,
  updateItemIndexInFolder,
} = foldersStore;
const { currentFolderIdByRepo, foldersByRepo, isAddingNewFolderState } = storeToRefs(foldersStore);
const { scrollToElement } = useScroll();
const { isFiltersActive } = storeToRefs(useFiltersStore());
const { onDeleteItem } = useResourceActions();
const { provider } = storeToRefs(useWorkspaceStore());

const itemsToMove = ref([]);

const ShareModalDefaultProps: ShareModalProps = {
  showShareModal: false,
  entityId: '',
  entity: 'DOC',
  entityName: 'Resource',
  entityLink: '',
  repoId: '',
};

const shareModalProps = ref<ShareModalProps>(ShareModalDefaultProps);

const isDragging = ref(false);
const documentationItems = ref([]);
const showMoveFolderModal = ref(false);
const currentFolder = computed<Folder>(
  () => foldersByRepo.value[repoId.value]?.[currentFolderIdByRepo.value[repoId.value]]
);
const currentFolderBreadCrumbs = computed<Folder[]>(() =>
  getFolderBreadCrumbs(currentFolderIdByRepo.value[repoId.value], repoId.value)
);
const showFolderBreadCrumbs = computed(() => currentFolder.value);

const moveFolderModalRef = ref(null);
const moveDestinationId = ref('');
const itemToMoveAfter = ref('');
const moveToTop = ref(false);
const batchDeleteConfirmationDialog = ref(null);
const batchDeleteConfirmButtonText = ref('Delete');
const batchDeleteConfirmQuestion = ref('');
const batchDeleteConfirmMessage = ref('');

const repoId = computed(() => {
  return props.repo.metadata.id;
});
const isDummyRepo = computed(() => isRepoIdDummyRepo(repoId.value));

const currentBranch = computed(() => {
  return route.params.branch;
});

const draftsAndChangedFiles = computed(() => {
  // @ts-ignore
  return props.allDocumentation.filter((item) => item.diffState);
});

const shouldShowChangedFilesSection = computed(() => {
  return draftsAndChangedFiles.value.length > 0;
});

const isDefaultBranch = computed(() => {
  const defaultBranch = reposStateData.value[repoId.value].defaultBranch;
  return defaultBranch === currentBranch.value;
});

const isDraggingMultipleItems = computed(() => {
  return itemsToMove.value.length > 1;
});

watch(
  () => props.filteredDocumentation,
  (newValue) => {
    documentationItems.value = newValue.map((item) => {
      const currentItem = documentationItems.value.find(
        // @ts-ignore
        (doc) => doc.id === item.id
      );
      // @ts-ignore
      return { ...item, selected: !!currentItem?.selected };
    });
  },
  { immediate: true }
);

onMounted(() => {
  watch(
    () => isAddingNewFolderState,
    (value) => {
      if (value) {
        // Scroll to the new folder name input.
        // Need the double nextTick when also switching to the documentation tab (Clicking 'New folder' on status page).
        nextTick(() =>
          nextTick(() => {
            scrollToElement('new-folder-name');
          })
        );
      }
    },
    { immediate: true }
  );
});

function openBatchCommitModal() {
  shouldOpenCommitModal.value = true;
}

function openShareModal(sharedEntityOpts: ShareModalProps) {
  shareModalProps.value = { showShareModal: true, ...sharedEntityOpts };
}

function closeShareModal() {
  // Show flag is in the options and default is false
  shareModalProps.value = ShareModalDefaultProps;
}

function handleAddFolder(parentFolderId) {
  selectFolder(repoId.value, parentFolderId);
  isAddingNewFolderState.value = true;
}

function onMove(item) {
  itemsToMove.value = [item];
  showMoveFolderModal.value = true;
}

function handleDocRequestAction(docRequest, action) {
  emit('doc-request-action', docRequest, action);
}

async function onDeleteItems(items) {
  // We need to collect all the sub items in the selected folders for creating a unified commit.
  const selectedFolders = items.filter((item) => item.documentationType === DocumentationTypes.FOLDER);
  const selectedFolderIds = selectedFolders.map((folder) => folder.id);
  const allChildrenItems = listFoldersChildren(selectedFolderIds);

  const childrenByType = iter.groupBy(allChildrenItems, (item) => getFolderItemType(item));

  // Get a list of all folders (selected and sub folders)
  const selectedAndSubFolderIds = [...selectedFolderIds];
  if (childrenByType.has(FolderItemType.FOLDER)) {
    const subFolderIds = childrenByType.get(FolderItemType.FOLDER).map((folder) => folder.id);
    selectedAndSubFolderIds.push(...subFolderIds);
  }

  // Get selected drafts and drafts that are inside selected folders
  const selectedDrafts = items.filter((item) => !!item.draftId);

  // List of all docs and playlists inside selected folders for moving into the root folder after delete
  const allSubDocAndPlaylistRefs = [
    ...(childrenByType.has(FolderItemType.DOC) ? childrenByType.get(FolderItemType.DOC) : []),
    ...(childrenByType.has(FolderItemType.PLAYLIST) ? childrenByType.get(FolderItemType.PLAYLIST) : []),
  ];

  // Find the docs and playlists that are inside selected folders and present in the current branch - for adding to the delete commit.
  const allSubDocsAndPlaylistsInBranch = getDocsAndPlaylistsInFoldersOnCurrentBranch(selectedAndSubFolderIds);

  // Get the selected docs and playlists by filtering out folders and drafts
  const selectedDocsAndPlaylists = items.filter(
    (item) => item.documentationType !== DocumentationTypes.FOLDER && !item.isNew
  );

  // This is the full list of docs and playlists we need to commit a delete for.
  const allDocsAndPlaylistsToDelete = [...allSubDocsAndPlaylistsInBranch, ...selectedDocsAndPlaylists];

  if (allDocsAndPlaylistsToDelete.length !== 0 && provider.value === GitProviderName.BitbucketDc) {
    await deleteItemUtils.cannotDeleteOnBitbucketDCSwal(
      allDocsAndPlaylistsToDelete.map((item) => item?.path ?? item?.name ?? item?.id)
    );
    return;
  }

  // Confirm only when deleting drafts or folders - otherwise we just open the commit modal.
  if (selectedDrafts.length || selectedFolders.length) {
    batchDeleteConfirmQuestion.value = `Are you sure you want to delete the selected ${
      items.length > 1 ? `${items.length} items` : 'item'
    }?`;
    batchDeleteConfirmMessage.value = selectedFolders.length
      ? ' You have selected to delete some folders, this will delete the folders and their content.'
      : '';
    batchDeleteConfirmButtonText.value = `Delete${selectedFolders.length ? ' recursively' : ''}`;
    const userSelection = await batchDeleteConfirmationDialog.value.showDialog();
    if (!userSelection.confirm) {
      return;
    }
  }

  async function deleteFolderFromDbDeleteDraftsAndMoveSelectedItemsToRootFolder() {
    // Delete folders from the DB, move their items to the root folder and delete drafts inside the folders.
    if (selectedFolderIds.length) {
      await deleteDbFoldersAndDraftsAndMoveItemsToRootFolder(
        selectedFolderIds,
        repoId.value,
        allSubDocAndPlaylistRefs,
        childrenByType
      );
    }

    // Delete the selected drafts.
    // Can't run multiple remove drafts in parallel because they are changing the drafts list on the state and might override each other.
    // note: this code deletes also drafts of deleted docs which were already deleted, this way we get also the analytics.
    for (let i = 0; i < selectedDrafts.length; i++) {
      await discardDraft(selectedDrafts[i]);
    }

    // Move the selected docs/playlists to the root folder
    await moveItemsToRootFolder(repoId.value, selectedDocsAndPlaylists);
  }

  if (allDocsAndPlaylistsToDelete.length) {
    // Need to commit the deletion - open commit modal.
    deleteItemUtils.openDeleteModal(
      allDocsAndPlaylistsToDelete,
      deleteFolderFromDbDeleteDraftsAndMoveSelectedItemsToRootFolder
    );
  } else {
    // Nothing to commit - just delete the folders and drafts.
    await deleteFolderFromDbDeleteDraftsAndMoveSelectedItemsToRootFolder();
  }
}

async function discardDraft(documenetation) {
  clearDismissedSuggestionsForTempDocument(repoId.value, documenetation.draftId);
  drafts3Store.discardDraft(documenetation.id);
  analytics.track(productEvents.DISCARD_DOC_DRAFT, {
    'Document ID': documenetation.id,
    Context: 'Repo',
  });
}

async function onDiscard(documentation) {
  await discardDraft(documentation);
}

function onItemClick({ changedFilesSection, item }) {
  analytics.track(productEvents.CLICKED_REPO_PAGE_ITEM, {
    'Changed In Current Branch': changedFilesSection,
    'On Default Branch': isDefaultBranch.value,
    'Entity ID': item.id,
    Type: item.documentationType,
  });
}

function onFolderClicked(item) {
  if (item?.documentationType === DocumentationTypes.FOLDER) {
    selectFolder(repoId.value, item.id);
  }
}

const lastSelectedItemIndex = ref<number | null>(null);
watch(documentationItems, () => {
  lastSelectedItemIndex.value = null;
});

function onSelect(event, item) {
  const selectedItemIndex = documentationItems.value.findIndex((doc) => doc.id === item.id);
  if (selectedItemIndex === -1) {
    return;
  }
  const selectedItem = documentationItems.value[selectedItemIndex];
  selectedItem.selected = !selectedItem.selected;
  if (event.shiftKey && lastSelectedItemIndex.value !== null) {
    const start = Math.min(lastSelectedItemIndex.value, selectedItemIndex);
    const end = Math.max(lastSelectedItemIndex.value, selectedItemIndex);
    for (let i = start; i <= end; i++) {
      documentationItems.value[i].selected = selectedItem.selected;
    }
  }
  lastSelectedItemIndex.value = selectedItemIndex;

  const eventName = selectedItem.selected
    ? productEvents.SELECTED_REPO_PAGE_ITEM
    : productEvents.DESELECTED_REPO_PAGE_ITEM;
  analytics.track(eventName, {
    'Folder ID': currentFolder.value.id,
    'Folder Path': getFolderPath(currentFolder.value.id, repoId.value),
    'Item Type': item.documentationType,
    'Item ID': item.id,
  });
}

const isAnySelected = computed(() => {
  return documentationItems.value.some((item) => item.selected);
});

const isAllSelected = computed(() => {
  return documentationItems.value.every((item) => item.selected);
});

const numSelectedItems = computed(() => {
  return documentationItems.value.filter((item) => item.selected).length;
});

function onSelectAllToggle(isAnySelected, isAllSelected) {
  if (isAllSelected) {
    documentationItems.value = documentationItems.value.map((item) => ({ ...item, selected: false }));
  } else if (isAnySelected) {
    documentationItems.value = documentationItems.value.map((item) => ({ ...item, selected: true }));
  }

  analytics.track(productEvents.CLICKED_SELECT_ALL_CHECKBOX_REPO_PAGE, {
    'Folder ID': currentFolder.value.id,
    'Folder Path': getFolderPath(currentFolder.value.id, repoId.value),
    'State When Clicked': isAllSelected ? 'All Selected' : 'Partial',
  });
}

function reportPickedBatchAction(action, items) {
  const counters = countDocumentationTypes(items);
  analytics.track(productEvents.PICKED_BATCH_ACTION, {
    'Folder ID': currentFolder.value.id,
    'Folder Path': getFolderPath(currentFolder.value.id, repoId.value),
    'Selected Item Count': items.length,
    'Selected Folder Count': counters.folder,
    'Selected Doc Count': counters.document,
    'Selected Playlist Count': counters.playlist,
    'Selected Draft Count': counters.draft,
    Action: action,
  });
}

function createGhostElement(event, numItems: number) {
  const ghost = event.target.cloneNode(true);
  ghost.classList.add('dragged-element');

  if (numItems > 1) {
    ghost.classList.add('multiple');
    ghost.dataset.numItems = numItems;
  }

  // Append the ghost element on the body and set it as the drag image
  document.body.appendChild(ghost);
  event.dataTransfer.setDragImage(ghost, 0, 0);

  setTimeout(() => {
    // Clean the ghost element right after drag
    document.body.removeChild(ghost);
  });
}

function onDragStart(event, item) {
  isDragging.value = true;
  event.stopPropagation();
  event.dataTransfer.dropEffect = 'move';
  event.dataTransfer.effectAllowed = 'move';
  itemsToMove.value = [item];

  const selectedItems = getSelectedItems();
  if (selectedItems.length) {
    itemsToMove.value = [...selectedItems];
  }

  createGhostElement(event, selectedItems.length);

  const folderPath = getFolderPath(currentFolder.value.id, repoId.value);
  analytics.track(productEvents.STARTED_DRAGGING_REPO_ITEMS, {
    'Folder ID': currentFolder.value.id,
    'Folder Path': folderPath,
    'Item Type': itemsToMove.value[0].documentationType,
    'Item ID': itemsToMove.value[0].id,
    'Item Count': itemsToMove.value.length,
  });
}

function onDragEnter(event, item, { isOrderDropZone = false, isTop = false } = {}) {
  event.stopPropagation();
  event.preventDefault();
  moveDestinationId.value = null;
  itemToMoveAfter.value = null;
  moveToTop.value = false;
  if (isOrderDropZone) {
    itemToMoveAfter.value = item;
    moveToTop.value = isTop;
  } else if (item.documentationType === DocumentationTypes.FOLDER) {
    moveDestinationId.value = item.id;
  }
  event.target.classList.add(DRAGGED_OVER_CLASS);
}

function onDragLeave(event) {
  event.stopPropagation();
  event.preventDefault();
  event.target.classList.remove(DRAGGED_OVER_CLASS);
}

function onDrop(event) {
  isDragging.value = false;
  event.target.classList.remove(DRAGGED_OVER_CLASS);
  const isDestinationToSelf = itemsToMove.value.some((item) => item.id === moveDestinationId.value);
  if (moveDestinationId.value && !isDestinationToSelf) {
    moveFolderModalRef.value.onMoveFolder(moveDestinationId.value, itemsToMove.value);
    trackDropToFolder();
    moveDestinationId.value = null;
  } else if (itemToMoveAfter.value || moveToTop.value) {
    if (isDummyRepo.value) {
      addNotification(
        'Reordering is disabled in the demo repo. Connect your own repository to create and organize your documentation.',
        {
          duration: 5000,
        }
      );
    } else if (props.sortOption) {
      addNotification('❌ Reordering is disabled while sorting is active. Disable sorting to reorder', {
        duration: 5000,
      });
    } else if (isFiltersActive.value) {
      addNotification('❌ Reordering is disabled while filtering is active. Disable filtering to reorder', {
        duration: 5000,
      });
    } else {
      // We can reorder just one item.
      onOrderChange(itemsToMove.value[0], itemToMoveAfter.value, moveToTop.value);
    }
    itemToMoveAfter.value = null;
    moveToTop.value = false;
  }

  function trackDropToFolder() {
    const sourceFolderPath = getFolderPath(currentFolder.value.id, repoId.value);
    const targetFolderPath = getFolderPath(moveDestinationId.value, repoId.value);

    analytics.track(productEvents.DROPPED_ITEMS_INTO_FOLDER, {
      'Source Folder ID': currentFolder.value.id,
      'Source Folder Path': sourceFolderPath,
      'Item Type': itemsToMove.value[0].documentationType,
      'Item ID': itemsToMove.value[0].id,
      'Item Count': itemsToMove.value.length,
      'Target Folder ID': moveDestinationId.value,
      'Target Folder Path': targetFolderPath,
    });
  }
}

function getSelectedItems() {
  return documentationItems.value.filter((item) => item.selected);
}

function onMultipleMoveClick() {
  itemsToMove.value = getSelectedItems();
  showMoveFolderModal.value = true;
  reportPickedBatchAction('Move', itemsToMove.value);
}

async function onMultipleDeleteClick() {
  const selectedItems = getSelectedItems();
  reportPickedBatchAction('Delete', selectedItems);
  await onDeleteItems(selectedItems);
}

function getDocsAndPlaylistsInFoldersOnCurrentBranch(folderIds: string[]) {
  const allSubDocsAndPlaylistsInBranch = [];
  folderIds.forEach((folderId) => {
    const folderItems = getFolderItems({ repoId: repoId.value, folderId, includeDrafts: false });
    const filteredFolderItems = folderItems.filter((item) => item.documentationType !== DocumentationTypes.FOLDER);
    allSubDocsAndPlaylistsInBranch.push(...filteredFolderItems);
  });
  return allSubDocsAndPlaylistsInBranch;
}

function listFoldersChildren(folderIds: string[]) {
  const children = [];
  folderIds.forEach((folderId) => {
    const subFolderItems = getAllFolderSubItems(folderId, repoId.value);
    children.push(...subFolderItems);
  });
  return children;
}

async function onOrderChange(item, itemBefore, isTop) {
  const oldIndex = documentationItems.value.findIndex(
    (doc) => (item.id && item.id === doc.id) || (item.draftId && item.draftId === doc.draftId)
  );
  const newIndex = isTop
    ? 0
    : documentationItems.value.findIndex(
        (doc) =>
          (itemBefore.id && itemBefore.id === doc.id) || (itemBefore.draftId && itemBefore.draftId === doc.draftId)
      );
  await updateItemIndexInFolder({
    folderId: currentFolder.value.id,
    repoId: repoId.value,
    itemId: item.id,
    draftId: item.draftId,
    isNew: item.isNew,
    itemIdToPlaceAfter: itemBefore?.id,
    itemDraftIdToPlaceAfter: itemBefore?.draftId,
  });

  const folderPath = getFolderPath(currentFolder.value.id, repoId.value);
  analytics.track(productEvents.DROPPED_ITEM_TO_REORDER, {
    'Folder ID': currentFolder.value.id,
    'Folder Path': folderPath,
    'Item Type': item.type,
    'Item ID': item.id,
    'Previous Index': oldIndex,
    'New Index': newIndex,
    'Dragged To Bottom': newIndex === documentationItems.value.length - 1,
  });
}
</script>

<style scoped lang="postcss">
.banner-wrapper {
  overflow: hidden;
}

:global(.item-wrapper[data-folder='true']:has(.dragged-over)) {
  box-shadow: inset 0px 0px 0px 2px var(--color-brand);
  box-sizing: border-box;
  border-radius: 4px;
}

:global(.dragged-over.drop-zone:after) {
  border-bottom: 2px solid var(--color-brand);
}

.drop-zone {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  height: 10px;
  border-radius: 0;
  box-sizing: border-box;
  position: relative;
  top: -10px;
  /* Extend the drop zone to the left so that users can reorder by dragging the drag handle straight down. */
  --extended-edge-length: var(--space-lg);
  width: calc(100% + var(--extended-edge-length));
  left: calc(-1 * var(--extended-edge-length));
  padding-left: var(--extended-edge-length);

  &:after {
    content: '';
  }
}

.drop-zone.upper {
  box-sizing: border-box;
  top: 0px;
}

.help-box {
  margin-top: 20px;
  margin-bottom: var(--space-sm);
  padding: 0;
  width: 100%;
  background: transparent;
}

.repo-documentation-section {
  margin-bottom: 48px;
}

.folder-bread-crumbs {
  &.hidden {
    visibility: hidden;
  }
}

.changed-files {
  margin-bottom: var(--space-md);

  .changed-files-header {
    color: var(--text-color-secondary);
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: var(--space-xs);
  }
}

:global(.repo-documentation-section .vue-recycle-scroller__item-wrapper) {
  overflow: visible;
}

.batch-delete-message-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dragged-element {
  background-color: var(--color-hover);
  width: 70%;
  position: relative;
  border: 1px solid var(--color-brand);
  border-radius: 4px;

  :deep(.drag-handler) {
    display: none;
  }

  :deep(.checkbox.checkbox) {
    visibility: hidden;
  }

  &.multiple {
    &::before {
      content: attr(data-num-items);
      position: absolute;
      left: 8px;
      top: 10px;
      background-color: var(--color-brand);
      color: var(--text-color-on-dark);
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--fontsize-xs);
    }

    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      background-color: var(--color-hover);
      z-index: -1;
      width: 100%;
      height: 100%;
      border: 1px solid var(--color-brand);
      border-radius: 4px;
    }
  }
}
</style>
