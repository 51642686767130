import { type SwmMeta, config, convertSWMJsonToSWMD } from '@swimm/shared';
import type { Draft } from '../db';
import { usePrepareSwimms } from '@/modules/batch-commit/compositions/prepare-swimms';
import { PJSON_VERSION } from '@/config';

export function usePlaylistToSwmd() {
  const { setPlaylistContent } = usePrepareSwimms();

  function serializePlaylistToSwmd({
    draftId,
    playlistDraft,
    path,
    repoId,
    linksNamesToUpdate,
  }: {
    draftId: string;
    playlistDraft: Draft;
    path: string;
    repoId: string;
    linksNamesToUpdate: Record<string, string>;
  }) {
    if (playlistDraft.type === 'playlist') {
      const playlistToSave = {
        ...playlistDraft.content,
        id: draftId,
        isNew: playlistDraft.isNew,
        path,
        type: config.SWIMM_FILE_TYPES.PLAYLIST,
      };
      const playlistContent = setPlaylistContent(playlistToSave, linksNamesToUpdate);
      const meta: SwmMeta = {
        app_version: PJSON_VERSION,
        cross_repo_file_blobs: {},
        cross_repo_names: {},
      };

      const playlistSWMD = convertSWMJsonToSWMD({
        swmFile: { ...playlistContent, meta, file_version: config.SWM_SCHEMA_VERSION },
        repoId: repoId,
        isPlaylist: true,
      });
      if (playlistSWMD.code === config.SUCCESS_RETURN_CODE) {
        return playlistSWMD.swmd;
      } else {
        throw new Error('Error parsing playlist to swmd');
      }
    } else {
      throw new Error('Not a playlist');
    }
  }

  return { serializePlaylistToSwmd };
}
