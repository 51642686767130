<template>
  <PlaylistStepsProgressBarSkeleton v-if="loading" :edit="editMode" />
  <div v-else class="step-progress">
    <div class="step-header">
      <div v-if="!editMode" class="header-top">
        <slot />
        <div class="progress-state system-body">
          <span class="current-step">{{ stepForProgressTitle }}</span> / {{ steps.length }} Steps
        </div>
      </div>
      <div class="header-bottom">
        <TextField
          v-if="editMode"
          :border="false"
          :model-value="stepsContainer.name"
          :disabled="disabled"
          :placeholder="`Untitled ${typeName || stepsContainer.type}`"
          @update:model-value="updateContainerName($event)"
        >
          <template #left>
            <Icon name="playlist" no-padding class="playlist-name-icon" />
          </template>
        </TextField>
        <span v-else class="step-name subtitle-L">{{ stepsContainer.name }}</span>
      </div>
    </div>
    <div :class="['step-wrapper', { selected: sequenceIntroShowing }]" @click="handleIntroSummaryPick('intro')">
      <ProgressBarAnchorStep name="Intro" icon="overview" :selected="sequenceIntroShowing" class="step" />
      <div v-if="editMode" :class="['seperator', { active: editMode }]">
        <div class="add-unit-between clickable" @click="showAddEllipsisIndex = 0">
          <Icon name="add" />
        </div>
      </div>
    </div>
    <div>
      <Ellipsis
        class="add-ellipsis"
        open-direction="bottom"
        parent-control
        :parent-show-options="showAddEllipsisIndex === 0"
        :parent-show-options-controller="() => (showAddEllipsisIndex = -1)"
        inverted
        disable-mouse-leave
        fixed
        @open-ellipsis="ellipsisOpened"
      >
        <OptionsMenu
          v-if="showAddEllipsisIndex === 0"
          v-click-outside="() => (showAddEllipsisIndex = -1)"
          :options="PlaylistMenuOptions"
          @option-selected="(option: NewPlaylistStepOption) => optionSelected(option, 0)"
        />
      </Ellipsis>
    </div>
    <div
      v-if="!loading && editMode && steps.length === 0 && !disabled"
      class="step show-dot"
      @click="showAddEllipsisIndex = 0"
    >
      <div class="progress">
        <div class="progress-dot"></div>
      </div>
      <div class="no-steps">
        <div>No steps in current {{ typeName || stepsContainer.type }}.</div>
        <div class="link">Add docs, links or other playlists</div>
      </div>
    </div>
    <div class="steps">
      <draggable
        v-model="editableSteps"
        handle=".handle"
        ghost-class="draggable-ghost"
        :item-key="(step: PlaylistProgressBarStep) => stepKey(step)"
        @start="startDrag"
        @end="onReorder"
      >
        <template #item="{ element: step, index }">
          <div class="step-wrapper" data-testid="playlist-step">
            <div
              v-tooltip="getStepTooltip(step)"
              :class="[
                'step',
                step.resource.type,
                {
                  selected: isSelected(step),
                  'loading-icon': isStepRepoLoading(step.repoId),
                  inactive: isStepRepoInactive(step.repoId),
                },
                progressClasses[index],
              ]"
            >
              <div class="progress">
                <div class="progress-dot"></div>
              </div>
              <component
                :is="editMode ? 'div' : 'a'"
                :href="step.link"
                class="step-title"
                :class="{ 'edit-mode': editMode }"
                @click="(event: Event) => handleStepPick(event, step, index)"
              >
                <div v-if="editMode" class="handle-container">
                  <Icon v-if="editMode" class="handle" name="drag" />
                </div>
                <div class="step-label" :class="{ highlight: markInvalidSteps && !isStepAvailable(step) }">
                  <transition name="fade">
                    <Icon v-if="isStepRepoLoading(step.repoId)" name="sync" class="step-icon" />
                    <Icon v-else :name="step.iconName" class="step-icon" />
                  </transition>
                  <span class="step-name body-S">
                    {{ stepDisplayName(step) }}
                    <span class="draft-label">{{ optionalDraftSuffix(step) }}</span>
                  </span>
                </div>
              </component>
              <Ellipsis
                v-if="showStepActions()"
                disable-mouse-leave
                class="ellipsis"
                open-direction="left"
                inverted
                fixed
              >
                <EllipsisOption
                  v-if="isAllowedToEditStep(step)"
                  name="Edit step"
                  icon="edit-outline"
                  large-content
                  :handler="() => editStep(step)"
                />
                <EllipsisOption
                  name="Remove from playlist"
                  icon="unavailable-fill"
                  large-content
                  :handler="() => removeStep(step)"
                />
                <EllipsisOption
                  v-if="showDiscardDraft(step)"
                  name="Discard draft"
                  icon="discard"
                  large-content
                  :handler="async () => await discardStep(step)"
                />
              </Ellipsis>
            </div>
            <div v-if="editMode" class="seperator active">
              <div class="add-unit-between clickable" @click="showAddEllipsisIndex = index + 1">
                <Icon name="add" />
              </div>
            </div>
            <Ellipsis
              class="add-ellipsis"
              open-direction="bottom"
              parent-control
              :parent-show-options="showAddEllipsisIndex === index + 1"
              :parent-show-options-controller="() => (showAddEllipsisIndex = -1)"
              inverted
              disable-mouse-leave
              fixed
              @open-ellipsis="ellipsisOpened"
            >
              <OptionsMenu
                v-if="showAddEllipsisIndex === index + 1"
                v-click-outside="() => (showAddEllipsisIndex = -1)"
                :options="PlaylistMenuOptions"
                @option-selected="(option: NewPlaylistStepOption) =>
              optionSelected(option, index + 1)"
              />
            </Ellipsis>
          </div>
        </template>
      </draggable>
    </div>
    <slot name="extra" />
    <ProgressBarAnchorStep
      v-if="steps.length > 0 || editMode"
      name="Summary"
      icon="flag"
      :selected="sequenceSummaryShowing"
      @click="handleIntroSummaryPick('summary')"
      class="step"
    />
  </div>
</template>

<script lang="ts">
import { confirmDraftDeletion } from '@/modules/drafts3/discard-draft-confirmations';
import { type PropType, computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { mapGetters } from 'vuex';
import Ellipsis from '@/common/components/atoms/Ellipsis.vue';
import EllipsisOption from '@/common/components/atoms/EllipsisOption.vue';
import { getLoggerNew, objectUtils, productEvents } from '@swimm/shared';
import draggable from 'vuedraggable';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { PlaylistMenuOptions } from '@/modules/playlists/consts';
import {
  type NewPlaylistStepOption,
  type PlaylistProgressBarStep,
  PlaylistSequenceStepTypes,
} from '@/modules/playlists/types';
import ProgressBarAnchorStep from '@/modules/playlists/components/ProgressBarAnchorStep.vue';
import PlaylistStepsProgressBarSkeleton from '@/modules/playlists/components/PlaylistStepsProgressBarSkeleton.vue';
import { TextField } from '@swimm/ui';
import { computeDraftDisplayName } from '@/common/utils/draft-utils';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';

const logger = getLoggerNew(__modulename);

const EXTRA_STEPS = {
  INTRO: 'intro',
  SUMMARY: 'summary',
} as const;

export default defineComponent({
  components: {
    PlaylistStepsProgressBarSkeleton,
    ProgressBarAnchorStep,
    draggable,
    Ellipsis,
    EllipsisOption,
    TextField,
  },
  props: {
    typeName: { type: String, default: '' },
    steps: { type: Array as PropType<PlaylistProgressBarStep[]>, required: true },
    activeStepId: { type: String, default: '' },
    stepsContainer: { type: Object, required: true },
    reposStatuses: { type: Object, required: true },
    editMode: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    markInvalidSteps: { type: Boolean, default: false },
  },
  emits: [
    'steps-sequence',
    'select-step',
    'add-unit',
    'remove-step',
    'edit-step',
    'container-name-updated',
    'steps-sequence-update',
  ],
  setup() {
    const route = useRoute();
    const analytics = useAnalytics();
    const playlistId = computed(() => route.params.playlistId as string);
    const workspaceId = computed(() => route.params.workspaceId as string);
    const branch = computed(() => route.params.branch as string);
    const drafts3Store = useDrafts3Store();

    return {
      analytics,
      PlaylistMenuOptions,
      playlistId,
      workspaceId,
      branch,
      EXTRA_STEPS,
      drafts3Store,
    };
  },
  data() {
    return {
      showAddEllipsisIndex: -1,
      editableSteps: [] as PlaylistProgressBarStep[],
      showStepEllipsis: false,
      dragging: false,
    };
  },
  computed: {
    ...mapGetters(['shouldPreventNavigation']),
    sequenceIntroShowing() {
      if (!this.editMode) {
        return !this.hasActiveStep && !this.$route.path.endsWith(`/${EXTRA_STEPS.SUMMARY}`);
      }

      return this.activeStepId === 'intro';
    },
    sequenceSummaryShowing() {
      if (!this.editMode) {
        return !this.hasActiveStep && this.$route.path.endsWith(`/${EXTRA_STEPS.SUMMARY}`);
      }

      return this.activeStepId === 'summary';
    },
    stepForProgressTitle() {
      if (this.sequenceIntroShowing) {
        return 'Intro';
      }
      if (this.sequenceSummaryShowing) {
        return 'Summary';
      }
      return (
        this.steps.findIndex((step) => {
          if (this.activeStepId === EXTRA_STEPS.SUMMARY) {
            return step.resource.id === `${this.playlistId}-${EXTRA_STEPS.SUMMARY}`;
          }
          return step.resource.id === this.activeStepId;
        }) + 1
      );
    },
    hasActiveStep() {
      return Boolean(this.activeStepId);
    },
    markedStepsIndex() {
      let i = 0;
      for (; i < this.steps.length; i++) {
        if (this.steps[i].resource.type !== 'playlist') {
          if (this.steps[i].status !== 'done' && this.steps[i].resource.name !== EXTRA_STEPS.SUMMARY) {
            return i;
          }
        } else if (!this.steps[i + 1] || this.steps[i + 1].status !== 'done') {
          return i;
        }
      }
      return i;
    },
    progressClasses() {
      return this.editableSteps.map((step, index) => {
        let progressClass =
          this.markedStepsIndex >= index && this.markedStepsIndex !== 0 && step.iconName !== 'unavailable'
            ? 'blue-progress'
            : 'grey-progress';
        let dotClass = '';
        if (index === 0 && this.markedStepsIndex === 0 && this.isSelected(step)) {
          dotClass = 'show-blue-dot';
        } else if (this.markedStepsIndex === index && step.iconName !== 'unavailable') {
          dotClass = this.isSelected(step) && this.markedStepsIndex !== 0 ? 'show-blue-dot' : 'show-dot';
          if (this.markedStepsIndex !== 0) {
            progressClass = 'blue-half-progress';
          }
        }
        return [progressClass, dotClass];
      });
    },
  },
  created() {
    document.addEventListener('wheel', this.onScroll, { passive: false }); // Passive false is required to disable scroll events
  },
  watch: {
    steps: {
      handler() {
        this.editableSteps = objectUtils.deepClone(this.steps);
      },
      immediate: true,
    },
  },
  beforeUnmount() {
    document.removeEventListener('wheel', this.onScroll); // Passive false is required to disable scroll events
  },
  methods: {
    ellipsisOpened() {
      this.analytics.track(productEvents.PLAYLIST_VIEW_OPTIONS_DROPDOWN);
    },
    onReorder(event) {
      this.dragging = false;
      if (event.newIndex !== event.oldIndex) {
        const steps = this.editableSteps.map((step) => step.resource);
        this.$emit('steps-sequence-update', steps);
      }
    },
    onScroll() {
      // Close ellipsis menu when scrolling
      this.showAddEllipsisIndex = -1;
    },
    startDrag() {
      this.dragging = true;
    },
    isSelected(step: PlaylistProgressBarStep) {
      return this.activeStepId === step.resource.id;
    },
    isStepRepoLoading(stepRepoId: string) {
      return this.reposStatuses[stepRepoId]?.loading === true;
    },
    isStepRepoAvailableLocally(stepRepoId: string) {
      return this.reposStatuses[stepRepoId]?.available === true;
    },
    isStepRepoInactive(stepRepoId: string) {
      return !this.isStepRepoLoading(stepRepoId) && !this.isStepRepoAvailableLocally(stepRepoId);
    },
    stepKey(step: PlaylistProgressBarStep) {
      return step.resource.id;
    },
    async handleStepPick(event: Event, step: PlaylistProgressBarStep, index: number) {
      const isOpenInNewTab =
        event.type === 'click' && (event['metaKey'] || event['shiftKey'] || event['ctrlKey'] || event['which'] === '2');
      if (!this.editMode && isOpenInNewTab) {
        // Whenever the user wants to open in a new tab -> don't proceed further
        return;
      } else {
        // Otherwise -> prevent the users action (don't use the href, but proceed this function)
        event.preventDefault();
      }
      if (this.activeStepId === step.resource.id) {
        return;
      }
      if (this.isSelected(step) || this.isStepRepoInactive(step.repoId) || this.isStepRepoLoading(step.repoId)) {
        return;
      }
      this.$emit('select-step', {
        id: step.resource.id,
        isDraftLink: 'isDraftLink' in step.resource && step.resource.isDraftLink,
        link: step.link,
        index: String(index),
      });
    },
    async handleIntroSummaryPick(selected: (typeof EXTRA_STEPS)[keyof typeof EXTRA_STEPS]) {
      if (this.sequenceSummaryShowing && selected === EXTRA_STEPS.SUMMARY) {
        return;
      }
      if (this.sequenceIntroShowing && selected === EXTRA_STEPS.INTRO) {
        return;
      }
      this.$emit('select-step', { id: selected });
    },
    getResourceType(step: PlaylistProgressBarStep) {
      switch (step.resource.type) {
        case 'unit':
          return 'Doc';
        case 'playlist':
          return 'Playlist';
        default:
          return step.resource.type;
      }
    },
    removeStep(step: PlaylistProgressBarStep) {
      this.$emit('remove-step', step);
      this.analytics.track(productEvents.PLAYLIST_STEP_REMOVED, {
        'Step Type': this.getResourceType(step),
        'Is Draft': 'isDraftLink' in step.resource ? step.resource.isDraftLink : false,
      });
    },
    editStep(step: PlaylistProgressBarStep) {
      const stepIndex = this.editableSteps.findIndex((item) => item.resource.id === step.resource.id);
      this.$emit('edit-step', { step, stepIndex });
    },
    async discardStep(step: PlaylistProgressBarStep) {
      // discard the draft
      // if the draft is new also removes the step
      if (!(await confirmDraftDeletion())) {
        return;
      }
      const draftId = step.resource.id;
      const draft = this.drafts3Store.drafts?.get(draftId);
      if (!draft) {
        return;
      }
      const isNew = draft.isNew;
      try {
        await this.drafts3Store.discardDraft(draftId);
        if (isNew) {
          this.removeStep(step);
        }
        this.analytics.track(productEvents.DISCARD_DOC_DRAFT, {
          'Document ID': step.resource.id || 'No Committed Version',
          Context: 'Playlist',
        });
      } catch (err) {
        logger.error(`Failed to discard draftId ${draftId} ${err}`);
      }
    },
    optionalDraftSuffix(step: PlaylistProgressBarStep) {
      return 'isDraftLink' in step.resource && step.resource.isDraftLink ? ' (Draft)' : '';
    },
    stepDisplayName(step: PlaylistProgressBarStep) {
      return computeDraftDisplayName(step.resource.name);
    },
    getStepTooltip(step: PlaylistProgressBarStep) {
      if (!step.isAvailable) {
        return 'The content in this step could not be found';
      } else if (this.isStepRepoInactive(step.repoId)) {
        return 'This step is unavailable';
      }
      return '';
    },
    showStepActions() {
      const shouldShow = this.editMode;
      this.showStepEllipsis = shouldShow;
      return shouldShow;
    },
    showDiscardDraft(step: PlaylistProgressBarStep) {
      return 'isDraftLink' in step.resource && step.resource.isDraftLink && step.isAvailable;
    },
    isAllowedToEditStep(step: PlaylistProgressBarStep) {
      return step.resource.type === PlaylistSequenceStepTypes.EXTERNAL_LINK;
    },
    updateContainerName(name: string) {
      this.$emit('container-name-updated', name);
    },
    optionSelected(type: NewPlaylistStepOption, position: number) {
      this.$emit('add-unit', { type, position });

      this.showAddEllipsisIndex = -1;
    },
    isStepAvailable(step) {
      return this.isStepRepoLoading(step.repoId) || step.isAvailable;
    },
  },
});
</script>

<style scoped>
.step-progress {
  padding-bottom: 70px;
}

.step {
  position: relative;
  display: flex;
  align-items: center;
}

.step.selected {
  font-weight: bold;
  color: var(--text-color-primary);
  background-color: var(--color-surface);
}

.step:not(.inactive) {
  cursor: pointer;
}

.step:not(.inactive):hover {
  background-color: var(--color-surface);
}

.step .progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 18px;
  width: 4px;
  background-color: var(--color-disable);
}

.step .progress .progress-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-surface);
  transform: translate(-50%, -50%);
  opacity: 0;
}

.step:hover .progress,
.step:hover .progress .progress-dot {
  background-color: var(--color-bg);
}

.step.show-dot .progress .progress-dot {
  opacity: 1;
}

.step.show-blue-dot .progress .progress-dot {
  background-color: var(--color-brand);
  opacity: 1;
}

.step.blue-progress .progress {
  background-color: var(--color-brand);
}

.step.blue-half-progress .progress {
  bottom: 50%;
  background-color: var(--color-brand);
}

.step.blue-half-progress .progress .progress-dot {
  top: 100%;
}

.step:not(.selected).blue-half-progress .progress .progress-dot {
  top: 100%;
  border: 1px solid var(--color-brand);
  opacity: 1;
}

.step.blue-half-progress .progress::before {
  content: '';
  position: absolute;
  top: 100%;
  bottom: -100%;
  width: 4px;
  background-color: var(--color-disable);
}

.step.blue-half-progress.selected .progress::before,
.step.blue-half-progress:hover .progress::before {
  background-color: var(--color-bg);
}

.step-wrapper:first-child .step .progress {
  top: 50%;
  border-top-left-radius: 30%;
  border-top-right-radius: 30%;
}

.step-wrapper:first-child .step .progress .progress-dot {
  top: 0;
}

.step-wrapper:last-child .step .progress {
  bottom: 50%;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.step:not(.blue-progress):not(.blue-half-progress).selected .progress {
  background-color: var(--color-disable);
}

.step-wrapper:last-child .step .progress .progress-dot {
  top: 100%;
}

.step-wrapper:not(:first-child) .step:not(.blue-progress):not(.blue-half-progress).selected .progress .progress-dot {
  background-color: var(--color-disable);
}

.step-icon {
  display: inline-block;
  margin-left: 30px;
  padding: 10px;
  font-size: var(--system-subtitle);
  font-weight: 400;
  vertical-align: bottom;
  border-radius: 50%;
}

.step-header {
  padding: var(--space-sm);
}

.header-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-md);
}

.current-step {
  font-weight: bold;
}

.header-bottom {
  display: flex;
  align-items: center;

  &:deep(.field-input) {
    padding-left: 0;
  }

  .playlist-name-icon {
    margin-right: var(--space-xs);
  }
}

.step-name {
  display: inline-block;
  overflow: hidden;
  margin-left: 10px;
  max-width: 100%;
  font-weight: 300;
  padding-inline-end: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-bottom .step-name {
  font-weight: 800;
}

.step.selected .step-name {
  font-weight: inherit;
}

.draft-label {
  color: var(--text-color-secondary);
}

.edit-mode .step-icon {
  margin-left: 0px;
}

.inactive {
  cursor: auto;
  color: var(--text-color-secondary);
  background: var(--color-disable);
}

.loading-icon .step-icon {
  animation: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: inline-block;
  padding: 10px;
}

.no-steps {
  margin-left: 40px;
  padding: var(--space-md) var(--space-sm);
  text-align: center;
  color: var(--text-color-secondary);
}

.link {
  color: var(--text-color-link);
}

.seperator {
  position: relative;
  margin-left: 30px;
  height: 1px;
}

.seperator.active:hover {
  background-color: var(--color-brand);
}

.step-wrapper:hover .seperator.active {
  background-color: var(--color-brand);
}

.step-title {
  display: flex;
  align-items: center;
  width: 100%;
}

.ellipsis {
  font-weight: normal;
  position: absolute;
  right: 15px;
  z-index: 3;
  visibility: hidden;
}

.step:hover .ellipsis {
  visibility: visible;
}

.handle {
  cursor: grab;
  display: inline-block;
  padding: 10px;
  border-radius: 50%;
  color: var(--text-color-disable);
  visibility: hidden;
}

.step:hover .handle {
  visibility: visible;
}

.add-unit-between {
  position: absolute;
  top: -15px;
  right: 50%;
  z-index: 1;
  display: none;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: var(--body-S);
  border-radius: 50%;
  text-align: center;
  color: var(--text-color-on-dark);
  background: var(--color-brand);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.seperator.active:hover .add-unit-between {
  display: flex;
}

.step-wrapper:hover .seperator.active .add-unit-between {
  display: flex;
}

.draggable-ghost {
  opacity: 0;
}

.handle-container {
  width: 30px;
}

.step .handle-container {
  padding-left: 30px;
  flex-shrink: 0;
}

.step-label {
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px solid transparent;
  transition: border 0.2s linear;
  margin-bottom: -2px;
  border-radius: 4px;
}

.step-label.highlight {
  border: 2px solid var(--color-border-danger);
}
</style>
