<template>
  <SwModal :padded="false" :show-modal="show" :heading="heading" @close="close">
    <div class="wrapper">
      <div class="top-text body-L">
        Your teammate will be notified by email. This request will be visible to all users with access to the Swimm
        repo.
      </div>
      <div class="doc-request-email">
        <UserEmailSelector
          :value="selectedUserEmail"
          @input="(value) => (selectedUserEmail = value.email)"
          :append-to-body="false"
          label="Assign to user"
          placeholder="Select user or enter email of assignee"
          :users="workspaceUsers"
          :required="true"
          test-id-prefix="request-doc"
        ></UserEmailSelector>
      </div>
      <div class="doc-request-title">
        <TextField
          v-model="title"
          placeholder="Untitled"
          label="How shall we call this doc?"
          data-testid="doc-request-title"
          :required="true"
          description="Give it a name that informs your team on what should be documented."
        />
      </div>
      <div class="doc-request-description">
        <BaseLabel required>Description</BaseLabel>
        <TextFieldMultiline
          v-model="description"
          placeholder="Description that will help assignee understand the request"
          :required="true"
        />
      </div>
      <div class="request-button">
        <Action data-testid="doc-request-submit" :loading="isWip" :disabled="!canSubmit" @click="submit">
          {{ actionTitle }}
        </Action>
      </div>
    </div>
  </SwModal>
</template>

<script>
import { useAppLinks } from '@/modules/core/compositions/app-links';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { productEvents } from '@swimm/shared';
import { mapActions, mapGetters } from 'vuex';
import UserEmailSelector from '@/modules/doc-assignments/components/UserEmailSelector.vue';
import { UNKNOWN_USER_NAME } from '@/common/consts';
import TextFieldMultiline from '@/common/components/atoms/TextFieldMultiline.vue';
import { BaseLabel, SwModal, TextField } from '@swimm/ui';
import { getLoggerNew } from '@swimm/shared';
import swal from 'sweetalert';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import {
  createDocRequestNotification,
  createOrUpdateDocRequest,
} from '@/modules/doc-requests/services/doc-requests-utils';
import { eventLogger } from '@swimm/shared';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { NOTIFICATION_TYPES } from '@swimm/shared';
import { useUnitIdGenerator } from '@/modules/core/compositions/unit-id-generator';
import { useNavigate } from '@/common/composables/navigate';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
const logger = getLoggerNew(__modulename);

export default {
  components: {
    BaseLabel,
    TextField,
    TextFieldMultiline,
    UserEmailSelector,
    SwModal,
  },
  props: {
    show: { type: Boolean, required: true },
    repoId: { type: String, required: true },
    editedDocRequest: { type: Object, required: false, default: null },
    newOrigin: { type: String, required: false, default: '' },
  },
  emits: ['close'],
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const { generateNewUnitId } = useUnitIdGenerator();
    const { logEvent } = useSwimmEventLogs();
    const { getWebAppLink } = useAppLinks();
    const { getRepoPath } = useNavigate();

    return {
      user,
      analytics,
      logEvent,
      generateNewUnitId,
      getWebAppLink,
      getRepoPath,
    };
  },
  data: function () {
    return {
      selectedUserEmail: '',
      title: '',
      description: '',
      isWip: false,
    };
  },
  computed: {
    ...mapGetters('database', ['db_getWorkspaceUsers', 'db_getSwimm', 'db_getDocRequest']),
    heading() {
      return this.isEdit ? 'Edit a doc request' : 'Request a doc from a teammate';
    },
    actionTitle() {
      return this.isEdit ? 'Update' : 'Assign';
    },
    isEdit() {
      return !!this.editedDocRequest;
    },
    workspaceUsers() {
      const workspaceId = this.$route.params.workspaceId;
      const users = Object.values(this.db_getWorkspaceUsers(workspaceId));
      return users
        .filter((user) => !!user.email)
        .map((user) => ({
          label: user.nickname || user.name || user.email || UNKNOWN_USER_NAME,
          name: user.nickname || user.name || user.email || UNKNOWN_USER_NAME,
          email: user.email,
          uid: user.uid,
        }));
    },
    canSubmit() {
      if (!this.title.length || !this.description.length || !this.selectedUserEmail) {
        return false;
      }
      if (this.editedDocRequest) {
        // Allow submitting only if there is real change
        return (
          this.editedDocRequest.title !== this.title ||
          this.editedDocRequest.description !== this.description ||
          this.editedDocRequest.requested_from_email !== this.selectedUserEmail
        );
      }
      return true;
    },
  },
  watch: {
    show: {
      handler: function (newValue) {
        if (newValue) {
          if (this.isEdit) {
            this.selectedUserEmail = this.editedDocRequest.requested_from_email;
            this.title = this.editedDocRequest.title;
            this.description = this.editedDocRequest.description;
          } else {
            this.selectedUserEmail = '';
            this.title = '';
            this.description = '';
          }
        }
      },
      immeditate: true,
    },
  },
  methods: {
    ...mapActions('database', ['addDocRequest', 'fetchUserNotifications']),
    close(docRequestId = undefined) {
      this.$emit('close', docRequestId);
    },
    async submit() {
      this.isWip = true;
      try {
        const { repoId, workspaceId, branch } = this.$route.params;
        const workspaceUser = this.workspaceUsers.find((user) => user.email === this.selectedUserEmail);
        const requestedFrom = workspaceUser ?? {
          email: this.selectedUserEmail,
          name: this.selectedUserEmail,
          uid: null,
        };
        const docRequestId = this.isEdit ? this.editedDocRequest.id : this.generateNewUnitId({ repoId });
        const docRequest = await createOrUpdateDocRequest({
          docRequestId,
          isUpdate: this.isEdit,
          repoId,
          title: this.title,
          byUser: this.user,
          requestedFrom,
          description: this.description,
        });
        const swimmEvent = this.isEdit
          ? eventLogger.SWIMM_EVENTS.DOC_REQUEST_UPDATED.code
          : eventLogger.SWIMM_EVENTS.DOC_REQUEST_CREATED.code;
        this.logEvent(swimmEvent, {
          srcId: docRequest.id,
          srcName: docRequest.title,
          targetName: requestedFrom.name,
          targetId: requestedFrom.uid || requestedFrom.email,
          value: this.selectedUserEmail,
        });
        await this.analytics.cloudTrack({
          identity: this.user.uid,
          event: productEvents.DOC_REQUEST_SAVED,
          payload: {
            Branch: branch,
            'Repo ID': repoId,
            'Workspace ID': workspaceId,
            Origin: this.isEdit ? 'Doc Requests Page' : this.newOrigin,
            'Doc Request ID': docRequest.id,
            'Assignee Email': docRequest.requested_from_email,
            'Doc Request Name': docRequest.title,
            Description: docRequest.description,
            'Save Date': new Date().toISOString(),
            'Save Type': this.isEdit ? 'update' : 'create',
          },
        });
        const url = this.getWebAppLink(`${this.getRepoPath()}/doc-requests?docRequestId=${docRequestId}`);
        await createDocRequestNotification({
          repoId,
          docRequest,
          workspaceId,
          url,
          type: NOTIFICATION_TYPES.DOC_REQUEST_CREATED,
        });
        await this.fetchUserNotifications();
        this.addDocRequest({ repoId, resource: docRequest });
        this.close(docRequest.id);
      } catch (err) {
        logger.error({ err }, `Failed to create/update docRequest ${err}`);
        await swal({
          title: `Failed to ${this.isEdit ? 'update' : 'create'} a doc request`,
          content: SWAL_CONTACT_US_CONTENT(),
        });
      } finally {
        this.isWip = false;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 24px;
  width: 560px;
  min-height: 300px;
}

.top-text {
  margin-bottom: 8px;
}

.doc-request-email,
.doc-request-title,
.doc-request-description {
  margin-top: 16px;
}

.request-button {
  display: flex;
  justify-content: flex-end;
  margin: 24px 0;
}
</style>
