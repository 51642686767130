<script setup lang="ts">
import { computed } from 'vue';

import { type WorkspaceRepoWithDisabled } from '@swimm/shared';

import MenuReposItem from '../MenuReposItem/MenuReposItem.vue';
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';
import MenuHeading from '../MenuHeading/MenuHeading.vue';

const props = defineProps<{
  workspaceRepos?: WorkspaceRepoWithDisabled[];
  searching?: boolean;
}>();

const emit = defineEmits<{
  selectRepo: [repo: WorkspaceRepoWithDisabled];
}>();

const isRepoListing = computed(() => (props.workspaceRepos && props.workspaceRepos.length > 1) || props.searching);

const favouriteWorkspaceRepos = computed(() => {
  return (!props.searching && props.workspaceRepos?.filter((repo) => repo.isFavourite)) || [];
});
const hasFavourites = computed(() => {
  return favouriteWorkspaceRepos.value.length && isRepoListing.value;
});

const recentWorkspaceRepos = computed(() => {
  return (!props.searching && props.workspaceRepos?.filter((repo) => repo.isRecent)) || [];
});
const hasRecent = computed(() => {
  return recentWorkspaceRepos.value.length && isRepoListing.value;
});

const allWorkspaceRepos = computed(() => {
  const workspaceRepos = props.workspaceRepos;

  if (isRepoListing.value && !props.searching) {
    return workspaceRepos
      ?.filter((repo) => !repo.isFavourite && !repo.isRecent)
      .sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  return workspaceRepos?.sort((a, b) => (a.name < b.name ? -1 : 1));
});

function onSelectRepo(repo: WorkspaceRepoWithDisabled) {
  emit('selectRepo', repo);
}
</script>

<template>
  <template v-if="hasFavourites || hasRecent">
    <MenuHeading>Favorite &amp; recent repos</MenuHeading>
  </template>

  <template v-if="hasFavourites">
    <MenuReposItem
      v-for="repo in favouriteWorkspaceRepos"
      :key="repo.id"
      :repo="repo"
      :as-repo-listing="isRepoListing"
      :disabled="repo.disabled"
      @select-repo="onSelectRepo"
    >
      <BaseIcon name="star-filled" class="menu-repos__favourite-icon" />
    </MenuReposItem>
  </template>
  <template v-if="hasRecent">
    <MenuReposItem
      v-for="repo in recentWorkspaceRepos"
      :key="repo.id"
      :repo="repo"
      :as-repo-listing="isRepoListing"
      :disabled="repo.disabled"
      @select-repo="onSelectRepo"
    />
  </template>

  <template v-if="hasFavourites || hasRecent">
    <MenuHeading>All repos</MenuHeading>
  </template>

  <MenuReposItem
    v-for="repo in allWorkspaceRepos"
    :key="repo.id"
    :repo="repo"
    :as-repo-listing="isRepoListing"
    :disabled="repo.disabled"
    @select-repo="onSelectRepo"
  />
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.menu-repos {
  $self: &;

  @include basic-resets;

  &__favourite-icon {
    color: var(--warning-yellow-500);
  }
}
</style>
