import type MarkdownIt from 'markdown-it';
import type StateInline from 'markdown-it/lib/rules_inline/state_inline';
import { HTML_TAG_RE } from './html';

function isBr(str: string) {
  return /^<br[/>\s]/i.test(str);
}

function isLetter(ch: number) {
  /* eslint no-bitwise:0*/
  const lc = ch | 0x20; // to lower case
  return lc >= 0x61 /* a */ && lc <= 0x7a /* z */;
}

function convertHtmlInline(state: StateInline, silent: boolean): boolean {
  const pos = state.pos;

  // Check start
  const max = state.posMax;
  if (state.src.charCodeAt(pos) !== 0x3c /* < */ || pos + 2 >= max) {
    return false;
  }

  // Quick fail on second char
  const ch = state.src.charCodeAt(pos + 1);
  if (ch !== 0x21 /* ! */ && ch !== 0x3f /* ? */ && ch !== 0x2f /* / */ && !isLetter(ch)) {
    return false;
  }

  const match = state.src.slice(pos).match(HTML_TAG_RE);
  if (!match) {
    return false;
  }

  const content = state.src.slice(pos, pos + match[0].length);

  if (isBr(content)) {
    if (!silent) {
      const token = state.push('hardbreak', 'br', 0);
      token.content = content;
    }
  } else {
    return false;
  }

  state.pos += match[0].length;
  return true;
}

export default function html_inline_convert(md: MarkdownIt): void {
  md.inline.ruler.before('html_inline', 'html_inline_convert', convertHtmlInline);
}
