<template>
  <Tag v-bind="$attrs" :class="{ wrapper: !renaming, 'hide-tag': renaming }" :text="alias" :tag-style="tagStyle">
    <VMenu
      v-if="!shouldDisableActions"
      :shown="shouldShow"
      placement="bottom-start"
      theme="menu-no-arrow"
      :delay="0"
      :triggers="[]"
    >
      <div class="settings-icon-container">
        <Icon
          @click.prevent.stop="shouldShow = !shouldShow"
          v-click-outside="() => (shouldShow = false)"
          name="more"
          class="settings-icon"
          :data-testid="`${text}-ellipsis`"
        />
      </div>
      <template #popper>
        <div class="popup-menu">
          <div
            v-for="option in options"
            :key="option.identifier"
            :data-testid="`${option.identifier}`"
            class="option"
            v-close-popper
            @click="onOptionClick(option.name)"
          >
            <Icon :name="option.icon" class="options-icon body-L data-hj-suppress" /> {{ option.name }}
          </div>
        </div>
      </template>
    </VMenu>
  </Tag>
  <TextField
    :class="{ 'hide-text-field': !renaming }"
    ref="renameInput"
    v-click-outside="clickHandler"
    v-model="changedText"
    @keyup="textFieldListener"
    data-testid="tag-rename-textfield"
    :maxlength="20"
  />
  <UserDialog
    danger
    ref="confirmationRef"
    title="Delete tag"
    cancel-text="Remove from doc"
    confirm-text="Delete permanently"
    :body="confirmationText"
  />
</template>

<script lang="ts">
import { productEvents } from '@swimm/shared';
import { Icon, Tag, TextField } from '@swimm/ui';
import { mapActions } from 'vuex';
import * as firestore from '@/adapters-common/firestore-wrapper';
import { useRouteData } from '@/common/composables/useRouteData';
import { OptionNames, options, renameTag } from '../services/tag-utils';
import { getLoggerNew } from '@swimm/shared';
import { useNotificationsStore } from '@swimm/editor';
import UserDialog from '@/common/components/modals/UserDialog.vue';

const logger = getLoggerNew(__modulename);
import { useAnalytics } from '@/common/composables/useAnalytics';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { PropType } from 'vue';

export default {
  emits: ['on-rename', 'done-rename'],
  components: {
    Tag,
    Icon,
    TextField,
    UserDialog,
  },
  props: {
    text: { type: String, required: true },
    displayText: { type: String, default: null },
    tagStyle: { type: String, default: 'doc_tag' },
    tagId: { type: String, required: true },
    docId: { type: String, default: null },
    repoId: { type: String, default: null },
    workspaceId: { type: String, default: null },
    allTagsNames: { type: Array as PropType<Array<string>>, required: true },
    shouldDisableActions: { type: Boolean, default: false },
  },
  setup() {
    const { addNotification } = useNotificationsStore();
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();

    return {
      user,
      options,
      logger,
      routeData: useRouteData(),
      drafts3Store: useDrafts3Store(),
      addNotification,
      analytics,
    };
  },
  data() {
    return {
      renaming: false,
      changedText: this.text,
      clickHandler: {
        handler: this.textFieldMouseListener,
        events: ['mousedown', 'mouseup'],
      },
      shouldShow: false,
    };
  },
  computed: {
    /**
     * @return {string[]}
     */
    allTagsLowerCase() {
      return this.allTagsNames.map((name) => name.toLowerCase());
    },
    alias() {
      return this.displayText || this.text;
    },
    confirmationText() {
      return `You are about to permanently delete "${this.text}" tag across all documentation.`;
    },
  },
  methods: {
    ...mapActions('database', ['removeTagFromSwimm', 'fetchDocumentChildCollections', 'deleteTagFromWorkspace']),
    async removeTagFromDoc() {
      const draft = this.drafts3Store.drafts?.get(this.docId);
      if (draft?.isNew) {
        await this.drafts3Store.updateAttrs(this.docId, {
          tags: (draft.tags ?? []).filter((tag) => tag !== this.tagId),
        });
      } else {
        await this.removeTagFromSwimm({ tagId: this.tagId, repoId: this.repoId, swimmId: this.docId });
      }
      this.sendAnalytics(productEvents.TAG_REMOVED_FROM_DOC, this.text);
    },
    async deleteTagPermanently() {
      try {
        const modalRef = this.$refs.confirmationRef as typeof UserDialog;
        const userResponse = await modalRef.showDialog();
        if (!userResponse.confirm && !userResponse.next) {
          return;
        }

        if (!userResponse.confirm) {
          await this.removeTagFromDoc();
          return;
        }

        await this.removeTagFromDoc();
        await this.deleteTagFromWorkspace({ workspaceId: this.routeData.workspaceId, tagId: this.tagId });
        this.sendAnalytics(productEvents.TAG_DELETED_FROM_WORKSPACE, this.text);
        await this.fetchDocumentChildCollections({
          documentId: this.routeData.workspaceId,
          children: [firestore.collectionNames.TAGS],
          containerCollection: firestore.collectionNames.WORKSPACES,
        });
      } catch (err) {
        logger.error({ err }, `Could not delete tag ${this.tagId} permanently ${this.docId} with error: ${err}`);
        this.addNotification(`Failed to delete tag "${this.text}" from workspace.`, {
          icon: 'warning',
        });
      }
    },
    async onOptionClick(option) {
      if (option === OptionNames.RENAME) {
        this.renaming = true;
        this.$nextTick(() => {
          const renameInputRef = this.$refs.renameInput as HTMLInputElement;
          renameInputRef.focus();
        });
        this.$emit('on-rename');
      } else if (option === OptionNames.REMOVE) {
        try {
          await this.removeTagFromDoc();
        } catch (err) {
          logger.error({ err }, `Could not remove tag ${this.tagId} from doc ${this.docId} with error: ${err}`);
          this.addNotification(`Failed to remove tag "${this.text}" from doc.`, {
            icon: 'warning',
          });
        }
      } else if (option === OptionNames.DELETE) {
        await this.deleteTagPermanently();
      }
    },
    textFieldListener(event) {
      if (event.key === 'Escape') {
        this.cancelRename();
      } else if (event.key === 'Enter') {
        this.confirmRename();
      }
    },
    textFieldMouseListener(event) {
      // For some reason we're being sent both a `MouseEvent` and a `PointerEvent`.
      // We want to get rid of the latter (since it's just noise to us), so this is how we filter it out.
      if (event.type === 'click') {
        return;
      }
      // Don't emit the close signal if we just released the mouse outside the component.
      if (event.type === 'mouseup') {
        return;
      }
      this.cancelRename();
    },
    async confirmRename() {
      try {
        const tagName = this.changedText.trim();
        const sameTagDifferentCase = this.text !== tagName && this.text.toLowerCase() === tagName.toLowerCase();
        // Support changing the case of an existing tag, but prevent renaming a different tag to an existing name.
        if (!sameTagDifferentCase && this.allTagsLowerCase.includes(tagName.toLowerCase())) {
          this.addNotification(`A tag named "${tagName}" already exists.`, {
            icon: 'warning',
          });
          return;
        }
        await renameTag(this.routeData.workspaceId as string, this.tagId, tagName, this.user.uid);
        this.sendAnalytics(productEvents.TAG_RENAMED, tagName, this.text);
        await this.fetchDocumentChildCollections({
          documentId: this.routeData.workspaceId,
          children: [firestore.collectionNames.TAGS],
          containerCollection: firestore.collectionNames.WORKSPACES,
        });
        this.renaming = false;
        this.$emit('done-rename');
      } catch (err) {
        logger.error({ err }, `Failed to rename tag ${this.tagId} in workspace ${this.routeData.workspaceId} ${err}`);
        this.addNotification(`Failed to rename tag "${this.text}".`, {
          icon: 'warning',
        });
      }
    },
    cancelRename() {
      this.renaming = false;
      this.changedText = this.text;
      this.$emit('done-rename');
    },
    sendAnalytics(event: string, tagName: string, previousTagName?: string) {
      const payload = {
        'Workspace ID': this.routeData.workspaceId,
        'Repo ID': this.repoId,
        'Document ID': this.docId || 'draft',
        'Tag Name': tagName,
        Context: 'Tags',
        Origin: this.routeData.routeName,
      };
      if (previousTagName) {
        payload['Previous Tag Name'] = previousTagName;
      }
      this.analytics.track(event, payload);
    },
  },
};
</script>

<style scoped lang="postcss">
.option {
  padding: 8px 16px 8px 8px;
  font-size: var(--body-S);
}

.option:hover {
  background: var(--color-hover);
  cursor: pointer;
}

.wrapper {
  position: relative;
}

/* We need the specificity here so that this display: none overcomes the display: flex inside Tag. */
.tag.hide-tag {
  display: none;
}

.hide-text-field {
  display: none;
}

.settings-icon-container {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.wrapper:hover {
  .settings-icon-container {
    display: flex;
    align-items: center;
  }

  .settings-icon {
    opacity: var(--opacity-medium);

    &:hover {
      opacity: var(--opacity-full);
    }
  }
}

.popup-menu {
  overflow: hidden;
}

.swal-button {
  background-color: var(--color-brand);
}
</style>
