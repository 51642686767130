import { encodeString } from '@swimm/shared';
import { config } from '@swimm/shared';
import { useRoute } from 'vue-router';

export function useAppLinks() {
  const route = useRoute();

  function getAppLink(route, forceDesktopLink = false) {
    if (!forceDesktopLink) {
      return getWebAppLink(route);
    } else {
      const encodedLink = encodeString(`swimm:/${route}`);
      return `${config.SWIMM_WEBSITE_ADDRESS}/link?l=${encodedLink}`;
    }
  }

  function openSwimmApp() {
    const link = getAppLink(`${route.path}`, true);
    window.open(link, '_blank');
  }

  function getWebAppLink(route) {
    return `${window.location.origin}${route}`;
  }

  return { getWebAppLink, getAppLink, openSwimmApp };
}
