import path from 'path-browserify';
import { getEnvPaths } from 'MixedLibs/env-paths';

export const isNodeEnv = typeof process !== 'undefined' && !!process.release && process.release.name === 'node';
export const isWebApp = !isNodeEnv; // When in the browser, the process has no nodeJS properties at all

export const LOCAL_AUTHORIZATION_PORT = 24601;

export const MARKDOWN_FILE_EXTENSION = '.md';
export const SWM_FILE_EXTENSION = '.swm';
export const SWM_RULE_EXTENSION = '.tr.swm';
export const SWMD_FILE_EXTENSION = '.sw.md';
export const SWMD_TEMPLATE_FILE_EXTENSION = '.t.sw.md';
export const SWMD_PLAYLIST_EXTENSION = '.pl.sw.md';
export const PLAYLIST_FILE_INDICATION = '.pl';
export const SWM_FOLDER_IN_REPO = '.swm';
export const SWM_IMAGES_FOLDER_NAME = `images`;
export const PROJECT_NAME = 'swimm';
export const REPO_JSON = 'swimm.json';
export const SWIMM_FOLDERS_JSON = 'swimm-folders.json';
export const UNIT_FILE_ENCODED_KEYS = ['diff', 'description', 'summary', 'dod'];
export const SWMD_TEMPLATES_FOLDER = `${SWM_FOLDER_IN_REPO}/doc-templates`;

export const REPO_STATE_KEYS_TO_KEEP = ['drafts', 'provider'];

// The next two fields no longer exist in our SWM files
export const UNIT_FILE_DEPRECATED_KEYS_FROM_104 = [
  'tests',
  'hints',
  'swimmPatch',
  'diff',
  'description',
  'dod',
  'summary',
  'app_version',
  'hunksOrder',
];
export const UNIT_FILE_DEPRECATED_KEYS = ['solution_files', 'files'].concat(UNIT_FILE_DEPRECATED_KEYS_FROM_104);

// State keys to reset in `swimm reset`
export const UNIT_KEYS_TO_RESET = [
  'started',
  'tests_run_already',
  'swimm_files_run_already',
  'swm_file',
  'unit_state',
  'current_hint_index',
  'original_untracked_files',
];

export const SWM_SCHEMA_VERSION = '2.0.3';
export const SWMD_SCHEMA_VERSION = '1.1.3';

const paths = getEnvPaths(PROJECT_NAME);

export let LOCAL_STATE_FILE_NAME = '.swimm-state';

const fileNames = {
  LOCAL_STATE_FILE_NAME,
  LOCAL_CONFIG_FILE_NAME: '.swimm-config',
  LOCAL_WEB_STATE_NAME: 'swimm_state',
};

export const locations = {
  STATE_FILE: path.join(paths.config, `${fileNames.LOCAL_STATE_FILE_NAME}.json`),
  CONFIG_FILE: path.join(paths.config, `${fileNames.LOCAL_CONFIG_FILE_NAME}.json`),
  LOG_FILE: path.join(paths.log, `swimm-local.log`),
};

export const LOCAL_CONFIG_FILE_NAME = fileNames.LOCAL_CONFIG_FILE_NAME;
export const LOCAL_WEB_STATE_NAME = fileNames.LOCAL_WEB_STATE_NAME;

export const MD_FOLDER_IN_REPO = 'swm_md';

export const SWIMM_DOC_SITE_GENERATIVE_AI = 'https://docs.swimm.io/swimm-ai/';
export const SWIMM_WEBSITE_ADDRESS = 'https://swimm.io';
export const SWIMM_DOC_SITE = 'https://docs.swimm.io';
export const SWIMM_VSCODE_EXTENSION_MARKETPLACE_ADDRESS =
  'https://marketplace.visualstudio.com/items?itemName=Swimm.swimm';
export const SWIMM_JETBRAINS_EXTENSION_MARKETPLACE_ADDRESS = 'https://plugins.jetbrains.com/plugin/20716-swimm';
export const SLACK_APP_INSTALLATION_STATE = 'swimm';
export let BASE_URL = '';

export const ERROR_RETURN_CODE = 1;
export const SUCCESS_RETURN_CODE = 0;

export const AXIOS_DEFAULT_TIMEOUT = 3000;
export const ELECTRON_APP_PAGE_PREFIX = 'app://';
export const OPEN_NEW_SWIMM_WINDOW_PREFIX = `${ELECTRON_APP_PAGE_PREFIX}open_new_swimm_window/`;

export const LOCAL_LOG_FILE_CONFIG = {
  filename: locations.LOG_FILE,
  maxLogSize: 10485760, // 10 MB - The maximum log file size in bytes
  maxAdditionalLogFiles: 1, // The number of additional logfiles to create when log-rotating
  isTailable: true, // Makes sure that the original log file will always get the latest logs
  lastLinesCount: 10, // The default last log lines to show with swimmlog before tailing the file
} as const;

export const LOCAL_CONFIGS_PATH = paths.config;

export const SWIMM_GITHUB_ENTERPRISE_REST_URL = 'https://github-prod.swimm.io/api/v3';

export const SWIMM_TEMPLATES_REPO_DATA = {
  repoId: 'TEMPLATES_SWIMM_REPO_ID',
  owner: 'swimmio',
  repoName: 'templates',
  branch: 'main',
  provider: 'github',
  api_url: SWIMM_GITHUB_ENTERPRISE_REST_URL,
};

export const SWIMMER_STATUSES = {
  NOT_STARTED: 'not-started',
  STARTED: 'started',
  DONE: 'done',
} as const;

export const UNIT_PLAY_MODES = {
  HANDS_ON: 'handson',
  WALKTHROUGH: 'walkthrough',
  ALL: 'all',
} as const;

/**
 * Enum for swm types.
 * @readonly
 * @enum {string}
 */
export const SWIMM_FILE_TYPES = {
  /**
   * @type {'swmd'}
   */
  SWMD: 'swmd',
  /**
   * @type {'playlist'}
   */
  PLAYLIST: 'playlist',
  /**
   * @type {'swm'}
   */
  SWM: 'swm',
  /**
   * @type {'template'}
   */
  SWMD_TEMPLATE: 'template',
  /**
   * @type {'rule'}
   */
  SWM_RULE: 'rule',
};

export const SWIMM_DOC_TYPES = [SWIMM_FILE_TYPES.SWM, SWIMM_FILE_TYPES.SWMD, SWIMM_FILE_TYPES.SWMD_TEMPLATE];

// Used by external shell commands that output lots of data
export const EXEC_MAX_BUFFER_SIZE = 1024 * 1024 * 10;

export const FREE_TIER_LIMITS = {
  users: 5,
  privateRepos: 1,
  workspaces: 1,
};

// EXPORTED VARIABLES FROM SWIMMAGIC-COMMON MIGRATION
// Configuration values for git functions
export const DETECT_RENAMES_PERCENTAGE = '22';
// From git's documentation about git diff:	export const DIFF_RENAME_LIMIT = '32767'; // This is the max value for a signed int
// "The -M and -C options require O(n^2) processing time where n is the number
// Of potential rename/copy targets. This option prevents rename/copy detection
// From running if the number of rename/copy targets exceeds the specified number."
//
// Since February 2011, the default value in git is 400 (https://github.com/git/git/commit/92c57e5c1d29db96a927e2a713c5aa4ae99ce749)
// Which means that if we have more than 400 files (renames are 2 - 1 for DELETION and one for ADDITION)
// That have changed since the original commit - we won't be able to find results.
export const DIFF_RENAME_LIMIT = '32767'; // This is the max value for a signed int

export const SLACK_INDICATOR = 'slack_app';
export const GH_MARKETPLACE_INDICATOR = 'gh_marketplace';

const pageSources = ['github_app', 'github_app_docs_in_pr', 'link', SLACK_INDICATOR, GH_MARKETPLACE_INDICATOR] as const;
export type PageSource = (typeof pageSources)[number];

export const SLACK_COMMUNITY_INVITE_LINK =
  'https://swimmcommunity.slack.com/join/shared_invite/zt-pizsz0c0-gL0DPEhuj~Jw1QwqBv8WYw#/shared-invite/email';

export interface SetupSharedConfigOptions {
  baseUrl: string;
  localStateFileName?: string;
}
export function setupSharedConfig(opts: SetupSharedConfigOptions) {
  BASE_URL = opts.baseUrl;
  LOCAL_STATE_FILE_NAME = opts.localStateFileName ?? '.swimm-state';
}

export const swimmLinkMatcher = /\[[^\]]+\]\(https:\/\/swimm.io\/link\?l=\S+\)/gm;
export const swimmLinkGroupsRegex = /\[(.*?)\]\(https:\/\/swimm.io\/link\?l=(\S+)\)/;
export const swimmURLLinkResourceMatcher =
  /\[[^\]]+\]\(\S+:\/\/\S+\/repos\/[^/]+\/(branch\/\S+\/)?(docs|units|playlists)\/\w+\S+\)/gm;
export const swimmURLLinkResourceGroupsRegex =
  /\[(.*?)\]\(.*?:\/\/.*?\/repos\/([^/]+)\/(?:branch\/\S+\/)?(docs|units|playlists)\/([^#]+)(?:#([^/?]+))?\)/;

export const swimmWebResourceMatcher = /\S+:\/\/\S+\/repos\/[^/]+\/(branch\/\S+\/)?(docs|units|playlists)\/\w+\S+/gm;
export const swimmWebResourceGroupsRegex =
  /.*?:\/\/.*?\/repos\/(?<repoId>[^/]+)\/(?:branch\/\S+\/)?(?<resourceType>docs|units|playlists)\/(?<resourceId>\S[^?]+)(?:\??(?:draft|playlistDraft)=(?<draftId>[^\s?]*))?/;

export function getCloudFunctionsDomain(env: string, isEmulated?: boolean): string {
  if (isEmulated) {
    return `http://127.0.0.1:6001/swimm-dev/us-central1`;
  }

  switch (env) {
    case 'production':
      return `https://app.swimm.io/functions`;
    case 'staging':
    case 'development':
      return 'https://staging.swimm.cloud/functions';
    case 'stag':
    case 'stage':
      return 'https://stag.swimm.cloud/functions';
    default:
      throw new Error(`Unknown environment: ${env}`);
  }
}

export function getDatadogProxy(env: string): string {
  switch (env) {
    case 'production':
      return `https://app.swimm.io/datadog`;
    case 'staging':
    case 'development':
      return 'https://staging.swimm.cloud/datadog';
    case 'stag':
    case 'stage':
      return 'https://stag.swimm.cloud/datadog';
    default:
      throw new Error(`Unknown environment: ${env}`);
  }
}

export function getApiUrl(env: string): string {
  switch (env) {
    case 'production':
      return `https://app.swimm.io`;
    case 'staging':
    case 'development':
      return 'https://staging.swimm.cloud';
    case 'stag':
    case 'stage':
      return 'https://stag.swimm.cloud';
    default:
      throw new Error(`Unknown environment: ${env}`);
  }
}

export function getApiHost(projectId: string): string {
  return new URL(getApiUrl(projectId)).host;
}

export default {
  setupSharedConfig,
  isWebApp,
  SWM_FILE_EXTENSION,
  SWM_RULE_EXTENSION,
  SWMD_FILE_EXTENSION,
  SWMD_TEMPLATE_FILE_EXTENSION,
  SWMD_PLAYLIST_EXTENSION,
  PLAYLIST_FILE_INDICATION,
  SWM_FOLDER_IN_REPO,
  SWM_IMAGES_FOLDER_NAME,
  PROJECT_NAME,
  REPO_JSON,
  SWIMM_FOLDERS_JSON,
  UNIT_FILE_ENCODED_KEYS,
  UNIT_FILE_DEPRECATED_KEYS,
  UNIT_FILE_DEPRECATED_KEYS_FROM_104,
  UNIT_KEYS_TO_RESET,
  SWM_SCHEMA_VERSION,
  SWMD_SCHEMA_VERSION,
  locations,
  LOCAL_STATE_FILE_NAME,
  LOCAL_CONFIG_FILE_NAME,
  LOCAL_WEB_STATE_NAME,
  MD_FOLDER_IN_REPO,
  SWIMM_DOC_SITE_GENERATIVE_AI,
  SWIMM_WEBSITE_ADDRESS,
  ERROR_RETURN_CODE,
  SUCCESS_RETURN_CODE,
  AXIOS_DEFAULT_TIMEOUT,
  ELECTRON_APP_PAGE_PREFIX,
  OPEN_NEW_SWIMM_WINDOW_PREFIX,
  LOCAL_LOG_FILE_CONFIG,
  LOCAL_CONFIGS_PATH,
  SWIMMER_STATUSES,
  UNIT_PLAY_MODES,
  EXEC_MAX_BUFFER_SIZE,
  SWIMM_FILE_TYPES,
  SLACK_APP_INSTALLATION_STATE,
  SWIMM_TEMPLATES_REPO_DATA,
  SWMD_TEMPLATES_FOLDER,
  REPO_STATE_KEYS_TO_KEEP,
  BASE_URL,
  FREE_TIER_LIMITS,
  SWIMM_DOC_TYPES,
  SLACK_INDICATOR,
  GH_MARKETPLACE_INDICATOR,
  SLACK_COMMUNITY_INVITE_LINK,
  swimmLinkMatcher,
  swimmLinkGroupsRegex,
  swimmWebResourceMatcher: swimmURLLinkResourceMatcher,
  swimmWebResourceGroupRegex: swimmURLLinkResourceGroupsRegex,
  getCloudFunctionsDomain,
  getDatadogProxy,
  getApiUrl,
  getApiHost,
};
