import { ref } from 'vue';
import { defineStore } from 'pinia';
import { STORES } from '../../stores/store-constants';
import type { CreationHubOptions } from '../CreationHubConsts';

export const useCreationHubStore = defineStore(STORES.CREATION_HUB, () => {
  const creationHubModalRef = ref(null);
  const showCreationHubModal = ref<boolean>(false);
  const creationHubOptions = ref<CreationHubOptions>({});

  function openCreationHubModal(creationOptions: CreationHubOptions) {
    creationHubOptions.value = creationOptions;
    showCreationHubModal.value = true;
  }

  const handleNewDocClick = ({ query, repoId, branch, folderId, sgdTemplateId }) => {
    if (creationHubOptions.value.newDocClick) {
      creationHubOptions.value.newDocClick({ query, repoId, branch, folderId, sgdTemplateId });
    }
  };

  const handleNewPlaylistClick = ($event) => {
    if (creationHubOptions.value.newPlaylistClick) {
      creationHubOptions.value.newPlaylistClick($event);
    }
  };

  function closeCreationHubModal({ fromRouter }: { fromRouter?: boolean } = {}) {
    // Keep the modal open when it was opened from the router query
    // because there's a re-route when removing the query
    if (creationHubOptions.value.keepAfterRouteChange && fromRouter) {
      return;
    }

    showCreationHubModal.value = false;
    creationHubOptions.value = {};
  }

  return {
    showCreationHubModal,
    creationHubOptions,
    creationHubModalRef,
    openCreationHubModal,
    closeCreationHubModal,
    handleNewDocClick,
    handleNewPlaylistClick,
  };
});
