<script setup lang="ts">
import { debounce } from 'lodash-es';
import { storeToRefs } from 'pinia';
import ListWrapper from '@/modules/core/components/ListWrapper.vue';
import { PageRoutesNames, SwimmContentStatuses } from '@/common/consts';
import PageNavigationBar from '@/common/components/organisms/PageNavigationBar.vue';
import EmptySearchResults from '@/modules/core/components/EmptySearchResults.vue';
import { useInitData } from '@/common/composables/initData';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import PageContainer from '@/common/layouts/PageContainer.vue';
import TopMenuLayout from '@/common/layouts/TopMenuLayout.vue';
import TopBar from '@/common/components/TopBar/TopBar.vue';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import ListViewItem from '@/modules/core/components/ListViewItem.vue';
import { useRouter } from 'vue-router';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { pageEvents } from '@swimm/shared';
import { SWIMM_DOC_SITE_SHARING_INTERNALLY } from '@/config';

const props = defineProps({
  workspaceId: { type: String, required: true },
});

const store = useStore();
const { setWorkspaceData } = useInitData();
const { user } = storeToRefs(useAuthStore());
const router = useRouter();
const analytics = useAnalytics();

const sharedDocs = ref([]);
const isLoading = ref(true);
const filterText = ref('');

const tabs = [
  {
    key: SwimmContentStatuses.ALL,
    label: 'All',
  },
];

onMounted(async () => {
  await setWorkspaceData(props.workspaceId);
  sharedDocs.value = store.getters['database/db_getSharedDocs'](props.workspaceId);
  isLoading.value = false;
  reportPageViewed();
});

const handleSearch = debounce((value) => {
  filterText.value = value.trim().toLowerCase();
}, 400);

const filteredDocs = computed(() => {
  return sharedDocs.value.filter((doc) => doc.name.toLowerCase().includes(filterText.value));
});

function getSharedDocLink(sharedDocId) {
  return router.resolve({
    name: PageRoutesNames.SHARED_DOC_PAGE,
    params: { workspaceId: props.workspaceId, sharedDocId },
  }).fullPath;
}

function reportPageViewed() {
  const payload = {
    'Page Name': 'Shared Docs Page',
  };
  analytics.cloudPageViewed({
    identity: user.value.uid,
    event: pageEvents.LOADED_A_PAGE,
    payload,
  });
  analytics.track(pageEvents.LOADED_A_PAGE_MARKETING, payload);
}
</script>
<template>
  <TopMenuLayout>
    <template #topBar>
      <TopBar :show-route-back="false" />
    </template>
    <template #content>
      <PageContainer>
        <div class="shared-docs-page">
          <SwText variant="headline1" class="header">Shared docs</SwText>
          <PageNavigationBar :tabs="tabs" :selected-tab-key="tabs[0].key" @search="handleSearch" />
          <Loader v-if="isLoading" />
          <template v-else>
            <ListWrapper v-if="filteredDocs.length" :show-status="false">
              <ListViewItem
                v-for="doc in sharedDocs"
                :key="doc.id"
                :title="doc.name"
                :filter-text="filterText"
                icon="doc"
                :creator="doc.creator_name"
                :is-highlighted="false"
                :doc-link="getSharedDocLink(doc.id)"
                show-doc-tags
              >
              </ListViewItem>
            </ListWrapper>
            <EmptySearchResults v-else-if="sharedDocs.length" />
            <EmptyState v-else transparent title="No shared docs yet">
              <div class="empty-state-description">
                Share documents to make them accessible by people in your organization without GitHub access.<br />
                Use the <span class="bold">'Share' button</span> on a doc to share it, and it will show up here.
                <a class="empty-state-description" :href="SWIMM_DOC_SITE_SHARING_INTERNALLY" target="_blank">
                  <u>Learn more</u>
                </a>
              </div>
            </EmptyState>
          </template>
        </div>
      </PageContainer>
    </template>
  </TopMenuLayout>
</template>

<style scoped lang="postcss">
.shared-docs-page {
  .header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: var(--space-lg) 0;
    padding: 0;
  }

  .empty-state-description {
    color: var(--text-color-secondary);
    text-align: center;
  }
}
</style>
