<!-- TODO Move me -->
<template>
  <pre class="code-block">
    <code v-if="!!lineNumbers" class="code-span line-numbers">{{lineNumbers}}</code>
    <code class="code-span code" :class="'language-' + language" ref="codeBlock" v-html="highlightedCode"></code>
    <div v-if="highlightLine != null" class="highlight" :class="highlightClass"
         :style="{ height: `${height}px`, top: `${height * (highlightLine - 1)}px` }">
    </div>
  </pre>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUpdated, ref } from 'vue';
import { toHtml } from 'hast-util-to-html';
import { getLogger } from '@swimm/shared';
import { lowlight } from '@/services/lowlight';

const logger = getLogger(__modulename);

export default defineComponent({
  props: {
    code: { type: String, required: true },
    language: { type: String, required: true },
    ignoreIllegals: { type: Boolean, default: true },
    highlightLine: { type: Number },
    highlightClass: { type: String, required: false },
    startLineNumber: { type: Number, required: false },
  },
  setup(props) {
    const codeBlock = ref<HTMLElement>();
    const height = ref(0);

    const highlightedCode = computed(() => {
      try {
        const tree = lowlight.highlight(props.language, props.code);
        return toHtml(tree);
      } catch (error) {
        // Fallback to plaintext
        logger.warn(`Falling back to plain text highlighting for: ${props.language} (${error})`);
        const tree = lowlight.highlight('plaintext', props.code);
        return toHtml(tree);
      }
    });

    const lineNumbers = computed(() => {
      const startLineNumber = props.startLineNumber;

      if (startLineNumber == null) {
        return null;
      }

      return props.code
        .split('\n')
        .map((_, index) => {
          return `${startLineNumber + index}`;
        })
        .join('\n');
    });

    /**
     * Calculate the needed height for the highlight.
     */
    function calculateHeightWidth() {
      // We get the line height by measuring the height of the first character in the code block
      const range = document.createRange();
      const firstChild = codeBlock.value?.firstChild;
      if (firstChild != null) {
        range.setStart(firstChild as Node, 0);
        range.setEnd(firstChild as Node, 1);
      }

      height.value = range.getBoundingClientRect().height;
    }

    onMounted(calculateHeightWidth);
    onUpdated(calculateHeightWidth);

    return { codeBlock, highlightedCode, lineNumbers, height };
  },
});
</script>

<style scoped>
.code-block {
  --vertical-padding: 4px;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 0;
  gap: 8px;
  padding: var(--vertical-padding) 0;
}

.highlight {
  position: absolute;
  background-color: var(--color-status-warning);
  z-index: -1;
  margin-top: var(--vertical-padding);
  width: 100%;
}

.line-numbers {
  color: var(--text-color-secondary);
  text-align: right;
  width: unset;
  margin-left: 8px;
  flex-shrink: 0;
}
</style>
