import { type Ref, watchEffect } from 'vue';
import { useLatestCommitStore } from '../stores/latestCommit';

export function useStartPollLatestCommit(repoId: Ref<string>, branch: Ref<string>) {
  const latestCommitStore = useLatestCommitStore();

  watchEffect((onCleanup) => {
    latestCommitStore.startPollingLatestCommit(repoId.value, branch.value);

    onCleanup(() => {
      latestCommitStore.stopPollingLatestCommit();
    });
  });
}
