import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import type { StorageWrapperInterface } from '@swimm/editor';

async function uploadStringToStorage({ path, content }: { path: string; content: string }): Promise<string> {
  const ref = firebase.storage().ref(path);
  const snapshot = await ref.putString(content, 'data_url');
  return snapshot.ref.getDownloadURL();
}

async function uploadContentToStorage({
  path,
  content,
  contentType,
}: {
  path: string;
  content: Blob | Uint8Array | ArrayBuffer;
  contentType: string;
}): Promise<string> {
  const ref = firebase.storage().ref(path);
  const snapshot = await ref.put(content, { contentType });
  return snapshot.ref.getDownloadURL();
}

export const storageWrapper: StorageWrapperInterface = {
  uploadContent: uploadContentToStorage,
  uploadString: uploadStringToStorage,
};
