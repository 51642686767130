<script setup>
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useBatchImportConvertFiles } from '@/modules/batch-import/compositions/batch-import-convert-files';
import ProgressBar from '@/common/components/atoms/ProgressBar.vue';
import { useBatchImportStore } from '@/modules/batch-import/stores/batch-import';

// Holds a reference to ImportMdExtensionHolder to convert files
const filesToConvert = ref([]);
const batchImportStore = useBatchImportStore();
const { currentlyProccessingMessage, importFiles } = useBatchImportConvertFiles();
const { filesConverted, filesToImport, mdFilesSelected, readmeFilesSelected } = storeToRefs(batchImportStore);

const indexOfFileSaving = computed(() =>
  filesConverted.value.length < filesToConvert.value.length ? filesConverted.value.length : filesToConvert.value.length
);
const progress = computed(() => {
  if (!filesToConvert.value.length) {
    return 0;
  }
  return 100 * (indexOfFileSaving.value / filesToConvert.value.length);
});

onMounted(() => {
  for (const file of filesToImport.value) {
    if (mdFilesSelected.value[file.id]) {
      filesToConvert.value.push(file);
    } else if (readmeFilesSelected.value[file.id]) {
      filesToConvert.value.push(file);
    }
  }

  importFiles(filesToConvert.value);
});
</script>

<template>
  <div class="import-process-container">
    <SwText class="process-description" variant="body-S" weight="light">
      Your import is in progress. Please keep this window open until the import is completed.
    </SwText>
    <div class="process-container">
      <div class="current-file-process">
        <Loader class="file-loader" secondary />
        <SwText variant="body-S">
          <strong>{{ currentlyProccessingMessage }}</strong> ({{ indexOfFileSaving }}/{{ filesToConvert.length }})
        </SwText>
      </div>
      <div class="progress-bar-container">
        <ProgressBar :class="{ completed: progress === 100 }" :progress="progress" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.import-process-container {
  .process-description {
    line-height: 1.5rem;
    margin-bottom: var(--space-base);
  }

  .process-container {
    .current-file-process {
      display: flex;
      align-items: center;

      .file-loader {
        --loader-size: 24px;
        margin-right: var(--space-xs);
      }
    }

    .progress-bar-container {
      margin-top: var(--space-base);

      & :deep(.progress-container) {
        background: var(--color-surface);
        height: 8px;
        border-radius: 0 4px 4px 0;

        .progress-bar {
          background: var(--color-border-brand);
          border-radius: 4px 0 0 4px;
        }

        &.completed {
          .progress-bar {
            border-radius: 4px;
          }
        }
      }
    }
  }
}
</style>
