export const SWMD_SUFFIX = '.sw.md';
export const SWM_SUFFIX = '.swm';
export const SWM_RULE_SUFFIX = '.tr.swm';
export const PLAYLIST_SUFFIX = '.pl' + SWMD_SUFFIX;

export const swmFolder = '.swm';
export const swmConfigFile = 'swimm.json';
export const SWIMM_RULE_CELL_TYPE = 'rule';

export enum GitHubAppFeatures {
  NEW_DOCS_IN_PR = 'new_docs_in_pr',
  NEW_DOC_NOTIFICATION = 'new_doc', // The Slack notification
  RECOMMENDED_DOCS = 'recommended_docs',
  REVIEW_IN_APP = 'review_in_app',
  AUTOMATIC_AUTO_SYNC = 'automatic_auto_sync',
  DOC_FROM_PR = 'doc_from_pr',
}
