const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = 60 * SECONDS_IN_MINUTE;
const SECONDS_IN_DAY = 24 * SECONDS_IN_HOUR;
const SECONDS_IN_MONTH = 31 * SECONDS_IN_DAY;
const SECONDS_IN_YEAR = 365 * SECONDS_IN_DAY;

export const TIME_UNITS = {
  SECONDS_IN_MINUTE,
  SECONDS_IN_HOUR,
  SECONDS_IN_DAY,
  SECONDS_IN_MONTH,
  SECONDS_IN_YEAR,
};

export function getUnitAndTitleForTimePast(secondsPastSince) {
  if (secondsPastSince < TIME_UNITS.SECONDS_IN_MINUTE) {
    const unit = Math.round(secondsPastSince);
    const title = unit > 1 ? 'seconds' : 'second';
    return { unit, title };
  } else if (secondsPastSince < TIME_UNITS.SECONDS_IN_HOUR) {
    const unit = Math.round(secondsPastSince / TIME_UNITS.SECONDS_IN_MINUTE);
    const title = unit > 1 ? 'minutes' : 'minute';
    return { unit, title };
  } else if (secondsPastSince < TIME_UNITS.SECONDS_IN_DAY) {
    const unit = Math.round(secondsPastSince / TIME_UNITS.SECONDS_IN_HOUR);
    const title = unit > 1 ? 'hours' : 'hour';
    return { unit, title };
  } else if (secondsPastSince < TIME_UNITS.SECONDS_IN_MONTH) {
    const unit = Math.round(secondsPastSince / TIME_UNITS.SECONDS_IN_DAY);
    const title = unit > 1 ? 'days' : 'day';
    return { unit, title };
  } else if (secondsPastSince < TIME_UNITS.SECONDS_IN_YEAR) {
    const unit = Math.round(secondsPastSince / TIME_UNITS.SECONDS_IN_MONTH);
    const title = unit > 1 ? 'months' : 'month';
    return { unit, title };
  } else {
    const unit = Math.round(secondsPastSince / TIME_UNITS.SECONDS_IN_YEAR);
    const title = unit > 1 ? 'years' : 'year';
    return { unit, title };
  }
}

export function getSecondsPastSince(dateInSeconds) {
  return Date.now() / MILLISECONDS_IN_SECOND - dateInSeconds;
}
