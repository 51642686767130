<template>
  <div class="workspaces-menu" data-testid="workspaces-menu" v-tooltip="{ content: 'Workspace', placement: 'right' }">
    <Loader class="loader" v-if="loading || !workspaces" />
    <template v-else>
      <VDropdown :placement="compact ? 'right-end' : 'bottom-start'" :skidding="-8">
        <SidebarButton v-if="compact" data-testid="workspace-dropdown-button">
          <SwAvatar
            :text="trimedWorkspaceName"
            :src="selectedWorkspaceContent.logo"
            square
            hide-tooltip
            class="workspace-logo"
          />
        </SidebarButton>
        <div v-else class="workspace-selector clickable" data-testid="workspace-dropdown-button">
          <SwAvatar
            :text="trimedWorkspaceName"
            :src="selectedWorkspaceContent.logo"
            square
            hide-tooltip
            class="workspace-logo"
          />
          <SwText component="span" variant="subtitle-L" class="text-ellipsis">
            {{ trimedWorkspaceName }}
          </SwText>
          <Icon name="arrow-more" no-padding />
        </div>
        <template #popper="{ hide }">
          <div class="options-container" data-testid="workspace-dropdown">
            <div class="section-header system-body">CURRENT</div>
            <div class="workspace-info">
              <SwAvatar :text="trimedWorkspaceName" :src="selectedWorkspaceContent.logo" square hide-tooltip />
              <SwText data-testid="selected-workspace-name" variant="subtitle-S">{{
                selectedWorkspaceContent.name
              }}</SwText>
            </div>
            <div v-if="selectedWorkspaceId">
              <SwText
                v-close-popper
                variant="body-S"
                class="menu-action"
                data-testid="workspace-settings-button"
                @click="onWorkspaceSettingsClick"
                >Workspace settings</SwText
              >
              <SwText
                v-close-popper
                v-if="isSelectedWorkspaceAdmin"
                class="menu-action"
                variant="body-S"
                @click="onInviteClick"
                >Add users</SwText
              >
              <div class="menu-action body-S" @click="unsubscribeFromSelectedWorkspace()">Leave workspace</div>
            </div>
            <template v-if="billing.isOnTrialPlan && billing.trialDaysLeft">
              <Divider class="divider" />
              <div class="trial-header">
                <SwText
                  component="span"
                  variant="body-S"
                  :class="['trial-subtitle', { expiring: billing.trialDaysLeft <= EXPIRING_TRIAL_LENGTH }]"
                  >{{ billing.trialDaysLeft }} days left</SwText
                >
                <SwText component="span" variant="body-S" class="trial-subtitle"> on your trial</SwText>
                <SwText variant="body-S" class="link" @click="openBillingModalPlansPage" v-close-popper
                  >Upgrade now</SwText
                >
              </div>
            </template>
            <template v-if="billing.isEligibleForTrialPlan">
              <Divider class="divider" />
              <div class="trial-header">
                <SwText variant="body-S" class="trial-subtitle"
                  >You’re on free plan with limited private repo and users.</SwText
                >
                <SwText variant="body-S" class="link" @click="openBillingModalPlansPage" v-close-popper
                  >Try {{ FREE_TRIAL_LENGTH }}-day Small teams plan for free</SwText
                >
              </div>
            </template>
            <Divider class="divider" />
            <div class="section-header">
              <SwText variant="system-body">OTHER WORKSPACES</SwText>
            </div>
            <div class="workspace-list" v-if="hasOtherWorkspaces" @click="hide">
              <WorkspaceItem
                v-for="workspaceEntry in unselectedWorkspaces"
                :key="`${workspaceEntry.id}-${workspaceEntry.name}`"
                data-testid="workspace-option"
                :workspace="workspaceEntry"
                @click="onWorkspaceSelect(workspaceEntry)"
              />
            </div>
            <div
              v-if="isAllowedToCreateWorkspace"
              class="menu-action body-S"
              data-testid="create-workspace-button"
              @click="createWorkspace"
            >
              <Icon name="add" /> New workspace
            </div>
          </div>
        </template>
      </VDropdown>
    </template>
    <PaywallModal
      :show="showWorkspaceCreationPaywallModal"
      :entity="PaywallEntity.WORKSPACE"
      @close="showWorkspaceCreationPaywallModal = false"
    />
  </div>
</template>

<script lang="ts">
import PaywallModal, { PaywallEntity } from '@/common/components/modals/PaywallModal.vue';
import { trimedWorkspaceName } from '@/common/utils/workspace-utils';
import { useAnalytics } from '@/common/composables/useAnalytics';
import 'firebase/compat/auth';
import WorkspaceItem from '@/common/components/organisms/WorkspaceItem.vue';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import {
  EXPIRING_TRIAL_LENGTH,
  FREE_TRIAL_LENGTH,
  billingPlanTypes,
  eventLogger,
  productEvents,
  workspaceSettingsBillingTabPhases,
  workspaceSettingsTabs,
} from '@swimm/shared';
import swal from 'sweetalert';
import { mapActions, mapGetters, mapState } from 'vuex';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { storeToRefs } from 'pinia';
import { Divider, SwAvatar, SwText } from '@swimm/ui';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import SidebarButton from '@/common/components/organisms/SidebarButton.vue';
import { useBillingStore } from '@/modules/billing/store/billing';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useWorkspaceSettingsModalStore } from '@/modules/workspace/modals/settings/store/workspace-settings';

export default {
  components: { SwAvatar, PaywallModal, WorkspaceItem, Divider, SwText, SidebarButton },
  props: {
    workspaces: { type: Object, required: true },
    selectedWorkspaceId: { type: String, required: true },
    compact: { type: Boolean, default: false },
  },
  emits: ['workspace-selected'],
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const billing = useBillingStore();
    const { logEvent } = useSwimmEventLogs();
    const { name: workspaceName } = storeToRefs(useWorkspaceStore());
    const { openWorkspaceSettingsModal } = useWorkspaceSettingsModalStore();
    return {
      user,
      analytics,
      logEvent,
      PaywallEntity,
      billing,
      workspaceName,
      openWorkspaceSettingsModal,
    };
  },
  data() {
    return {
      EXPIRING_TRIAL_LENGTH,
      FREE_TRIAL_LENGTH,
      loading: false,
      showWorkspaceCreationPaywallModal: false,
    };
  },
  watch: {
    'billing.showBillingPlans': {
      handler() {
        // The workspace settings modal might be already open from other place.
        // Wait a bit and open it only if needed.
        setTimeout(this.openBillingWorkspaceSettingsIfNeeded, 500);
      },
    },
  },
  computed: {
    ...mapGetters('database', ['db_isWorkspaceAdmin', 'db_getWorkspaceUsers', 'db_getWorkspaceAdmins']),
    ...mapState('database', ['domainSettings']),
    selectedWorkspace() {
      return this.workspaces[this.selectedWorkspaceId];
    },
    isAllowedToCreateWorkspace() {
      return !this.domainSettings.isWorkspaceCreationProhibited;
    },
    isSelectedWorkspaceAdmin() {
      return this.db_isWorkspaceAdmin(this.selectedWorkspaceId, this.user.uid);
    },
    unselectedWorkspaces() {
      const unselected = [];
      for (const [workspaceId, workspaceEntry] of Object.entries(this.workspaces)) {
        if (workspaceId !== this.selectedWorkspaceId) {
          unselected.push(workspaceEntry);
        }
      }

      return unselected.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      });
    },
    selectedWorkspaceContent() {
      if (this.selectedWorkspaceId === 'NEW' && this.isRepoDuringInitialization) {
        return { name: 'No workspace' };
      }

      return this.selectedWorkspace || { name: '' };
    },
    isRepoDuringInitialization() {
      return this.$route.params.repoId === 'new';
    },
    hasOtherWorkspaces() {
      return this.unselectedWorkspaces.length > 0;
    },
    trimedWorkspaceName() {
      // Remove the word "workspace" from the name (if it exists at the end)
      return trimedWorkspaceName(this.workspaceName ? this.workspaceName : '');
    },
    workspaceUsersCount() {
      const users = this.db_getWorkspaceUsers(this.selectedWorkspaceId);
      return Object.keys(users).length;
    },
    countOtherAdminsInWorkspace() {
      const admins = this.db_getWorkspaceAdmins(this.selectedWorkspaceId);
      return Object.keys(admins).filter((adminKey) => adminKey !== this.user.uid).length;
    },
  },
  methods: {
    ...mapActions('database', ['removeWorkspace']),
    onWorkspaceSelect(selectedOption) {
      if (selectedOption) {
        this.$emit('workspace-selected', {
          prevWorkspace: this.selectedWorkspace,
          newWorkspace: selectedOption,
        });
      }
      this.analytics.workspaceChange({ workspaceId: selectedOption.id, workspaceName: selectedOption.name });
    },
    createWorkspace() {
      if (this.selectedWorkspace.license === billingPlanTypes.FREE) {
        this.showWorkspaceCreationPaywallModal = true;
        this.analytics.cloudTrack({
          identity: this.user.uid,
          event: productEvents.WORKSPACE_LIMIT_REACHED_PAYWALL,
          payload: {
            'User ID': this.user.uid,
            'User Email': this.user.email,
            'Workspace ID': this.selectedWorkspaceId,
            'Workspace Name': this.selectedWorkspaceContent.name,
            Context: 'Billing',
          },
        });
      } else {
        this.$router.push(`/workspaces/new`);
      }
    },
    async unsubscribeFromSelectedWorkspace() {
      const workspaceName = this.selectedWorkspaceContent.name || 'the workspace';
      let unsubscribeAlert = `Are you sure you want to leave ${workspaceName}?`;
      let description;
      let dangerMode = false;
      let confirmText = 'OK';
      let lastUserInWorkSpace = false;
      if (this.workspaceUsersCount === 1) {
        lastUserInWorkSpace = true;
        dangerMode = true;
        confirmText = 'Delete workspace';
        description =
          'You are the last user in this workspace. If you decide to leave, the workspace will be deleted, and all associated data will be removed. This action cannot be undone.';
      }

      if (!this.countOtherAdminsInWorkspace && !lastUserInWorkSpace) {
        unsubscribeAlert = 'Promote another member to admin to leave';
        dangerMode = false;
        confirmText = 'Manage members';
        description =
          'There are no other admins in this workspace. To leave this workspace, promote another member to Admin and try again.';
      }
      const shouldUnsubscribe = await swal({
        title: unsubscribeAlert,
        text: description,
        dangerMode,
        buttons: {
          cancel: true,
          confirm: { text: confirmText, visible: true },
        },
      });
      if (!shouldUnsubscribe) {
        return;
      }
      if (shouldUnsubscribe && !this.countOtherAdminsInWorkspace && !lastUserInWorkSpace) {
        this.openWorkspaceSettingsModal({ initialTabCode: workspaceSettingsTabs.MEMBERS });
        return;
      }
      this.loading = true;
      const result = await CloudFunctions.removeUserFromWorkspace({
        workspaceId: this.selectedWorkspaceId,
        userId: this.user.uid,
      });

      if (result.data) {
        const workspaceToRemove = this.selectedWorkspace;
        this.removeWorkspace(workspaceToRemove.id);
        if (this.hasOtherWorkspaces) {
          const newWorkspace = this.unselectedWorkspaces[0];
          this.analytics.leaveWorkspace({
            newWorkspaceId: newWorkspace.id,
            workspaceIdToRemove: workspaceToRemove.id,
            uid: this.user.uid,
          });
          this.$emit('workspace-selected', {
            prevWorkspace: workspaceToRemove,
            newWorkspace: newWorkspace,
          });
        } else {
          this.analytics.noWorkspaces(this.user.uid);
          await this.$router.push('/joinWorkspace');
        }
        await swal({ title: `Successfully unsubscribed from ${workspaceName}`, buttons: { confirm: true } });
        this.logEvent(eventLogger.SWIMM_EVENTS.WORKSPACE_USER_UNSUBSCRIBED_WORKSPACE, {
          workspaceId: workspaceToRemove.id,
          workspaceName: workspaceName,
          srcId: this.user.uid,
        });
      } else {
        await swal({
          title: `Could not unsubscribe from ${workspaceName}`,
          content: { element: SWAL_CONTACT_US_CONTENT() },
        });
      }
      this.loading = false;
    },
    onWorkspaceSettingsClick() {
      this.openWorkspaceSettings(
        this.isSelectedWorkspaceAdmin ? workspaceSettingsTabs.GENERAL_SETTINGS : workspaceSettingsTabs.MEMBERS
      );
    },
    onInviteClick() {
      this.openWorkspaceSettings(workspaceSettingsTabs.MEMBERS);
    },
    openWorkspaceSettings(initialTab: workspaceSettingsTabs, initialPhase: workspaceSettingsBillingTabPhases = null) {
      this.openWorkspaceSettingsModal({ initialTabCode: initialTab, initialTabPhase: initialPhase });
    },
    openBillingModal(initialPhase = null) {
      this.openWorkspaceSettings(workspaceSettingsTabs.BILLING, initialPhase);
    },
    openBillingModalPlansPage() {
      this.openBillingModal(workspaceSettingsBillingTabPhases.CHANGE_PLAN);

      if (this.billing.isOnTrialPlan) {
        this.analytics.track(productEvents.CLICKED_UPGRADE_NOW_LINK, {
          Context: 'Workspace Menu',
          'Workspace ID': this.selectedWorkspaceId,
          'Trial Days Left': this.billing.trialDaysLeft,
        });
      } else {
        this.analytics.track(productEvents.CLICKED_START_FREE_TRIAL_LINK, {
          Context: 'Workspace Menu',
          'Workspace ID': this.selectedWorkspaceId,
        });
      }
    },
    openBillingWorkspaceSettingsIfNeeded() {
      if (this.billing.showBillingPlans) {
        this.openBillingModal();
      }
    },
  },
};
</script>
<style scoped lang="postcss">
.workspaces-menu {
  position: relative;
  align-items: center;
  box-sizing: border-box;
  display: flex;

  .loader {
    --loader-size: 24px;
  }
}

.menu-action {
  cursor: pointer;
  padding: var(--space-base) var(--space-sm);

  &:hover {
    background-color: var(--color-surface);
  }
}

.workspace-list {
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1;
  margin-top: var(--space-base);
  max-height: 218px;
}

.workspace-logo.workspace-logo {
  cursor: pointer;
}

.small-arrows {
  position: absolute;
  right: -5px;
  font-size: var(--body-L);
}

.workspace-info {
  display: flex;
  gap: var(--space-base);
  flex: 1;
  min-width: 0;
  border-radius: 6px;

  .workspace-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.v-popper--theme-dropdown {
  max-width: 100%;

  .workspace-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    gap: 4px;
    padding: 2px;
  }

  .workspace-selector:hover {
    background-color: var(--color-surface);
  }
}

.options-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-width: 360px;
  max-height: 80vh;
  margin: var(--space-base) 0;
  width: 280px;

  .workspace-info {
    margin: var(--space-base) var(--space-sm);
    border: none;
  }
}

.section-header {
  margin-left: var(--space-sm);
  font-family: var(--fontfamily-secondary);
  color: var(--text-color-secondary);
}

.trial-header {
  color: var(--text-color-secondary);
  margin-left: var(--space-sm);
}

.divider {
  margin: var(--space-base) 0;
}

.link {
  color: var(--text-color-link);
  cursor: pointer;
}

.link,
.trial-subtitle {
  line-height: 1.5rem;
}

.expiring {
  color: var(--color-border-danger);
}
</style>
