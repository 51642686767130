<template>
  <div
    v-tooltip="tagTooltip"
    class="tag system-body"
    :class="[tagStyle.toLowerCase(), { 'empty-tag': !text, warning: isWarning }]"
  >
    {{ `${text}` }}
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    text: { type: String, default: '' },
    tagStyle: { type: String, default: 'example' },
    isWarning: { type: Boolean, default: false },
    tooltip: { type: String, default: '' },
  },
  data() {
    return {
      textToTooltip: {
        'Local draft': 'Local drafts are only visible to you - commit them to your repo to share with others.',
        DRAFT: 'Local drafts are only visible to you - commit them to your repo to share with others.',
        'NEEDS REVIEW': 'Needs Review',
        PENDING: 'Pending pull request',
      },
    };
  },
  computed: {
    tagTooltip() {
      if (this.tooltip) {
        return this.tooltip;
      }
      return this.textToTooltip[this.text] || '';
    },
  },
});
</script>

<style scoped lang="postcss">
.tag {
  padding: 3px 6px;
  line-height: 16px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  cursor: default;
  font-family: var(--fontfamily-main);
  white-space: nowrap;
}

.example,
.demo {
  font-family: var(--fontfamily-secondary);
  font-weight: 600;
  color: var(--text-color-on-dark);
  background: var(--swimm-shades);
}

.suggestion {
  color: var(--text-color-primary);
  background: var(--color-status-info);
}

.sgd {
  margin: 0;
  padding: 0px 0px 0px 5px;
  min-width: 44px;
  height: 21px;
  font-size: var(--body-S);
  font-family: var(--fontfamily-secondary);
  font-weight: normal;
  color: var(--text-color-magic-strong);
  background: transparent;
  line-height: 24px;
}

.new-doc-sgd {
  margin: 0;
  padding: 0px 0px 0px 5px;
  min-width: 44px;
  height: 21px;
  font-size: var(--body-S);
  font-family: var(--fontfamily-secondary);
  font-weight: normal;
  border-radius: 24px;
  color: var(--text-color-magic-strong);
  background: var(--color-status-magic);
  line-height: 16px;
}

.pending {
  color: var(--text-color-warning);
  background: var(--color-status-warning);
}

.empty-tag {
  /* Align empty tags to others in line */
  min-height: 24px;
  background: transparent;
}

.free {
  color: var(--text-color-primary);
  background: var(--color-surface);
}

.enterprise-starter,
.enterprise,
.pro {
  color: var(--text-color-info);
  background: var(--color-status-info);
}

.standard,
.team,
.small-teams,
.team10 {
  color: var(--text-color-magic);
  background: var(--color-status-magic);
}

.beta {
  color: var(--text-color-magic);
  background: var(--color-status-magic);
}

.beta-narrow {
  color: var(--text-color-magic);
  background: var(--color-status-magic);
  line-height: 12px;
}

.new {
  padding: 0 10px;
  font-size: var(--body-XS);
  color: var(--text-color-info);
  background: var(--color-status-info);
}

.committed {
  color: var(--text-color-success);
  background: var(--color-status-success);
}

.draft,
.local-draft {
  font-size: var(--body-S);
  font-weight: normal;
  color: var(--text-color-primary);
  background: var(--color-hover);
}

.in-progress {
  font-size: var(--body-S);
  color: var(--text-color-warning);
  background: var(--color-status-warning);
}

.todo {
  font-size: var(--body-S);
  color: var(--text-color-primary);
  background: var(--color-hover);
}

.completed {
  font-size: var(--body-S);
  color: var(--text-color-success);
  background: var(--color-status-success);
}

.doc_tag {
  display: flex;
  font-weight: normal;
  font-family: var(--fontfamily-main);
  background: var(--color-surface);
}

/* For tags only */
.wrapper.doc_tag_purple {
  background-color: var(--doc-tag-magic);
  color: var(--text-color-primary);

  & :deep(.settings-icon-container) {
    background-color: var(--doc-tag-magic);
  }
}

.wrapper.doc_tag_green {
  background-color: var(--doc-tag-neutral-dark);
  color: var(--text-color-on-dark);

  & :deep(.settings-icon-container) {
    background-color: var(--doc-tag-neutral-dark);
  }
}

.wrapper.doc_tag_yellow {
  background-color: var(--doc-tag-accent);
  color: var(--text-color-primary);

  & :deep(.settings-icon-container) {
    background-color: var(--doc-tag-accent);
  }
}

.wrapper.doc_tag_orange {
  background-color: var(--doc-tag-neutral-light);
  color: var(--text-color-primary);

  & :deep(.settings-icon-container) {
    background-color: var(--doc-tag-neutral-light);
  }
}

.wrapper.doc_tag_blue {
  background-color: var(--doc-tag-brand);
  color: var(--text-color-primary);

  & :deep(.settings-icon-container) {
    background-color: var(--doc-tag-brand);
  }
}

.warning {
  color: var(--text-color-warning);
  background: var(--color-status-warning);

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--text-color-warning-strong);
    margin-right: var(--space-xs);
  }
}
</style>
