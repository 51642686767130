<template>
  <div class="thanks-div">
    <div
      v-tooltip="{
        content: tooltip,
        placement: 'right',
      }"
    >
      <VMenu theme="menu-no-arrow" :class="thankClass" data-testid="thanks-popover" popover-arrow-class="display-none">
        <span>
          <Action
            data-testid="thanks-action"
            class="thanks-button"
            :variant="variant"
            :secondary="canThank"
            :loading="isSending"
            :disabled="disableButton"
            size="small"
            :trailing-icon="trailingIcon"
          >
            <span class="thumbs-up body-XS">{{ buttonEmoji }}</span> &nbsp; {{ buttonText }}
          </Action>
        </span>
        <template #popper>
          <template v-if="!disableButton">
            <div
              v-for="option in options"
              :key="option.code"
              v-close-popper
              class="option-line"
              @click="sayThanksClicked(option)"
            >
              {{ option.name }}
            </div>
          </template>
        </template>
      </VMenu>
    </div>
    <SwText variant="body-S" class="sent-title">
      &nbsp;
      <span v-if="thanksCount > 0">
        <VMenu theme="menu-no-arrow" class="thanks-count-with-popover">
          <span class="thanks-count">{{ thanksCountTitle }}</span>
          <template #popper>
            <ThanksPopup v-if="thanksWithName.length" :thanks="thanksWithName" />
          </template>
        </VMenu>
        sent thanks to contributors
      </span>
    </SwText>
  </div>
</template>

<script>
import { collectionNames } from '@/adapters-common/firestore-wrapper';
import { useAppLinks } from '@/modules/core/compositions/app-links';
import { eventLogger, firestoreCollectionNames, getLoggerNew, isRepoIdDummyRepo, productEvents } from '@swimm/shared';
import { mapActions, mapGetters } from 'vuex';
import { useNotificationsStore } from '@swimm/editor';
import ThanksPopup from '@/modules/doc-contributions/components/ThanksPopup.vue';
import {
  SAY_THANKS_OPTIONS,
  createThanksNotification,
  getEmoji,
  sayThanks,
} from '@/modules/doc-contributions/services/say-thanks-utils';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';

const logger = getLoggerNew(__modulename);

export default {
  components: {
    ThanksPopup,
  },
  props: {
    docId: { type: String, required: true },
    repoId: { type: String, required: true },
  },
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const { addNotification } = useNotificationsStore();
    const { getWebAppLink } = useAppLinks();
    const { logEvent } = useSwimmEventLogs();
    return { user, analytics, addNotification, getWebAppLink, logEvent };
  },
  data() {
    return {
      showOptions: false,
      options: [...SAY_THANKS_OPTIONS],
      succeed: false,
      failed: false,
      isSending: false,
    };
  },
  computed: {
    ...mapGetters('database', ['db_getSwimm', 'db_getContributors', 'db_getThanks']),
    userId() {
      return this.user.uid;
    },
    userName() {
      return this.user.displayName || this.user.nickname || 'Unknown user';
    },
    thankClass() {
      return {
        'disable-thank': !this.canThank,
      };
    },
    trailingIcon() {
      if (this.isThanked || this.succeed) {
        return 'check';
      }
      return null;
    },
    isDummyRepo() {
      return isRepoIdDummyRepo(this.repoId);
    },
    disableButton() {
      return this.isCreator || this.isThanked || this.isDummyRepo;
    },
    thanksCountTitle() {
      return this.thanksCount > 1 ? `${this.thanksCount} users` : `1 user`;
    },
    isCreator() {
      return this.contributors.some((contributor) => contributor.user_id === this.userId && contributor.is_creator);
    },
    myThank() {
      return this.thanks.find((thank) => thank.creator === this.userId);
    },
    isThanked() {
      return !!this.myThank;
    },
    thanksCount() {
      return this.thanks.length;
    },
    canThank() {
      return !this.succeed && !this.failed && !this.isThanked && !this.isCreator;
    },
    variant() {
      if (this.failed) {
        return 'danger';
      }
      if (this.succeed || this.isThanked) {
        return 'success';
      }
      return null;
    },
    buttonText() {
      if (this.succeed || this.isThanked) {
        return 'Thanks sent';
      }
      return 'Send thanks';
    },
    buttonEmoji() {
      if (this.isThanked) {
        return getEmoji(this.myThank.code);
      }
      return '👍';
    },
    swimmFromDB() {
      return this.db_getSwimm(this.repoId, this.docId);
    },
    contributors() {
      return this.swimmFromDB ? this.db_getContributors(this.repoId, this.swimmFromDB.id) : [];
    },
    thanks() {
      return this.swimmFromDB ? this.db_getThanks(this.repoId, this.swimmFromDB.id) : [];
    },
    thanksWithName() {
      return this.thanks.filter((thank) => !!thank.creator_name);
    },
    tooltip() {
      if (this.isDummyRepo) {
        return 'Thanking the author is not available in the demo repo.';
      }
      return this.isCreator && !this.isThanked ? 'No need to thank yourself, let others do it for you!' : '';
    },
  },
  methods: {
    ...mapActions('database', ['setDocSubCollectionData', 'refreshDocSubCollections']),
    async sayThanksClicked(option) {
      if (this.canThank && !this.isSending) {
        this.isSending = true;
        this.sendThanks(option);
        this.setDocSubCollectionData({
          repoId: this.repoId,
          unitId: this.docId,
          thanksData: { code: option.code, created: Date.now(), creator: this.userId, creator_name: this.userName },
          collection: collectionNames.THANKS,
        });
        this.succeed = true;
        this.isSending = false;
      }
    },
    async sendThanks(option) {
      try {
        const thanksResult = await sayThanks({
          docId: this.docId,
          repoId: this.repoId,
          userName: this.userName,
          option,
        });
        if (thanksResult === true) {
          await this.afterThanks(option);
        } else {
          this.handleThanksFailed();
        }
      } catch (error) {
        logger.error({ err: error }, `Failed in sendThanks`);
        this.handleThanksFailed();
      }
    },
    handleThanksFailed() {
      this.failed = true;
      this.succeed = false;
      this.addNotification(`Failed to thank the author of the doc`, {
        icon: 'warning',
      });
    },
    async afterThanks(option) {
      try {
        const docUrl = this.getWebAppLink(
          `/workspaces/${this.$route.params.workspaceId}/repos/${this.repoId}/branch/${this.$route.params.branch}/docs/${this.docId}`
        );
        const docTitle = this.swimmFromDB.name;
        const lastContributorsCount = Math.min(5, this.contributors.length);
        Promise.allSettled([
          this.analytics.track(productEvents.THANKS_SENT, {
            Context: option.eventName,
            Origin: 'View Document',
            'Document ID': this.docId,
            'Repo ID': this.repoId,
            'Total Last Contributors': lastContributorsCount,
          }),
          createThanksNotification({
            docId: this.docId,
            contributors: this.contributors,
            docTitle,
            docUrl,
            user: this.user,
          }),
        ]);
        if (this.swimmFromDB) {
          this.logEvent(eventLogger.SWIMM_EVENTS.DOC_READER_THANKED_AUTHOR, {
            srcId: this.docId,
            srcName: this.swimmFromDB.name ?? 'unknown doc',
            srcType: 'unit',
            targetName: this.swimmFromDB.creator_name || 'anonymous-creator',
            targetId: this.swimmFromDB.creator || 'anonymous-creator-id',
            value: option.name,
          });
        }
      } catch (error) {
        logger.error({ err: error }, `Failed in afterThanks`);
      } finally {
        // This will cause reload of the current component
        await this.refreshDocSubCollections({
          repoId: this.repoId,
          unitId: this.docId,
          collectionsToRefresh: [firestoreCollectionNames.THANKS],
        });
      }
    },
  },
};
</script>

<style scoped>
.thanks-div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.disable-thank {
  pointer-events: none;
}

.thanks-count-with-popover {
  display: inline-block;
}

.thanks-count {
  font-weight: bold;
  cursor: pointer;
}

.thanks-button {
  max-width: 200px;
  cursor: default;
  pointer-events: none;
}

.thanks-button:hover {
  background: var(--color-bg);
}

.option-line {
  padding: 10px;
  cursor: pointer;
}

.option-line:hover {
  background: var(--color-hover);
}

.sent-title {
  margin-top: var(--space-base);
  color: var(--text-color-secondary);
}
</style>
