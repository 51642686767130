// @ts-strict
import { getLocalRepoTreeEnterprise } from '@/common/utils/enterpriseRepoTree';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import {
  type FolderTreeDirectory,
  GitProviderName,
  type ResultWithReturnCode,
  config,
  filterIrrelevantRepoFiles,
  folderTreeSortFunction,
  generateFolderTree,
  getLoggerNew,
  gitProviderUtils,
  gitwrapper,
  state,
} from '@swimm/shared';
import { defineStore, storeToRefs } from 'pinia';

const logger = getLoggerNew(__modulename);

export const useFolderTreeStore = defineStore('folder-tree', () => {
  const { user } = storeToRefs(useAuthStore());

  async function getAllSwmContent({
    branch,
    treeRequestMetadata,
  }: {
    treeRequestMetadata: { repoId: string; workspaceId: string; cloneUrl: string; localAuthEndpoints?: string[] };
    branch: string;
  }): Promise<
    {
      path: string;
      content: string;
    }[]
  > {
    const subfolders = await getAllSwmSubfolders({ branch, treeRequestMetadata });
    const allContent = [];

    await Promise.all(
      subfolders
        .filter((item) => item !== config.REPO_JSON)
        .map(async (item) => {
          const content = await gitwrapper.getAllSwmFilesContent({
            repoId: treeRequestMetadata.repoId,
            revision: branch,
            path: item,
          });
          allContent.push(...content);
          return Promise.resolve();
        })
    );
    return allContent;
  }

  async function getAllSwmSubfolders({
    branch,
    treeRequestMetadata,
  }: {
    treeRequestMetadata: { repoId: string; workspaceId: string; cloneUrl: string; localAuthEndpoints?: string[] };
    branch: string;
  }): Promise<string[]> {
    const repoFiles = await listAllFilesRemote({ treeRequestMetadata, branchName: branch });
    if (!repoFiles || !repoFiles.length) {
      return [];
    }
    const folders = new Set<string>();

    repoFiles
      .filter((file) => file.startsWith(config.SWM_FOLDER_IN_REPO))
      .map((item) => {
        const lastSlashIndex = item.lastIndexOf('/');
        const basePath = item.slice(0, lastSlashIndex);
        if (!basePath.endsWith(config.SWMD_FILE_EXTENSION)) {
          folders.add(basePath);
        }
      });
    return Array.from(folders);
  }

  async function getFolderTree({
    treeRequestMetadata,
    branch,
    sort,
    reload,
  }: {
    treeRequestMetadata: { repoId: string; workspaceId: string; cloneUrl: string; localAuthEndpoints?: string[] };
    branch: string;
    sort?: boolean;
    reload?: boolean;
  }): Promise<ResultWithReturnCode<{ folderTree: FolderTreeDirectory }>> {
    try {
      let repoFiles = await listAllFilesRemote({ branchName: branch, reload, treeRequestMetadata });
      if (!repoFiles || !repoFiles.length) {
        return { code: config.ERROR_RETURN_CODE };
      }
      repoFiles = filterIrrelevantRepoFiles(repoFiles);
      const folderTree = generateFolderTree(repoFiles);
      if (sort) {
        folderTree.children.sort(folderTreeSortFunction);
      }
      return { code: config.SUCCESS_RETURN_CODE, folderTree: folderTree };
    } catch (err) {
      logger.error({ err }, `failed to load folder tree for path. Details: ${err.toString()}`);
    }
    return { code: config.ERROR_RETURN_CODE };
  }

  async function listAllFilesRemote({
    branchName,
    reload,
    treeRequestMetadata,
  }: {
    branchName: string;
    reload?: boolean;
    treeRequestMetadata: { repoId: string; workspaceId: string; cloneUrl: string; localAuthEndpoints?: string[] };
  }) {
    let filesList: string[] = [];
    try {
      const { repoId, cloneUrl, localAuthEndpoints, workspaceId } = treeRequestMetadata;
      const repoData = await state.getRepoFromLocalState(repoId);
      const provider: GitProviderName = repoData.provider;
      const branchObject = await gitwrapper.getBranch({ repoId, branchName: branchName });
      if (gitProviderUtils.doesProviderRequireCloudGetRepoTree(provider)) {
        filesList = await getLocalRepoTreeEnterprise({
          provider,
          branchObject,
          repoId,
          cloneUrl,
          workspaceId,
          userId: user.value.uid ?? 'user',
          localAuthEndpoints,
        });
        if (!filesList || filesList.length === 0) {
          logger.warn(
            `listAllFilesRemote - getLocalRepoTreeEnterprise returned empty list for repoId: ${repoId}; branch: ${branchName}. Will attempt to fallback to the git hosting tree API...`
          );
        }
      }
      // Will happen if the repo provider isn't in the designated provider list, or if the call to the enterprise repo tree failed.
      if (filesList.length === 0) {
        const tree = await gitwrapper.getRepoTree({
          repoId,
          treeSha: branchName,
          recursive: true,
          cacheBuster: reload ?? false,
        });
        filesList = Object.values(tree)
          .filter((resource) => resource.type === 'blob')
          .map((a) => a.path as string);
      }
    } catch (err) {
      logger.error({ err }, `listAllFilesRemote ${err} `);
      return [];
    }
    if (filesList[0] && filesList[0].includes(':')) {
      logger.error(`listAllFilesRemote - returned value can't be a file name or relative path - probably an error `);
      return [];
    }
    return filesList;
  }

  return {
    getAllSwmContent,
    getFolderTree,
  };
});
