<script setup lang="ts">
import { useVModel } from '@vueuse/core';

import ResourceHeaderTitle from '../ResourceHeaderTitle/ResourceHeaderTitle.vue';
import ResourceDraftIndicator from '../ResourceDraftIndicator/ResourceDraftIndicator.vue';
import { ref } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    placeholder?: string;
    edit?: boolean;
    draft?: boolean;
  }>(),
  {
    modelValue: undefined,
    placeholder: 'Untitled',
    contributors: undefined,
    views: 0,
  }
);

const title = ref<InstanceType<typeof ResourceHeaderTitle>>();

const value = useVModel(props, 'modelValue', undefined, {
  shouldEmit: () => {
    // Additional safety check to ensure modelValue isn't updated
    // unless we're in edit mode.
    return props.edit;
  },
});

defineExpose({
  title,
});
</script>

<template>
  <header class="resource-header">
    <ResourceDraftIndicator v-if="edit" :draft="draft" />
    <slot name="banner" />
    <div class="resource-header__title-container">
      <ResourceHeaderTitle ref="title" v-model="value" :edit="edit" />
    </div>
    <slot name="meta-items" />
  </header>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.resource-header {
  $self: &;

  @include basic-resets;

  &__title-container {
    margin-bottom: var(--space-xsmall);
  }

  &__meta-item {
    padding: 0 var(--space-xsmall);

    &:first-of-type {
      padding-left: 0;
    }

    &--button {
      padding: 0;
    }
  }
}
</style>
