<template>
  <div v-dompurify-html="wysiwygHTML" class="wysiwyg"></div>
</template>

<script>
import { MarkdownUtils } from '@swimm/editor';

export default {
  props: {
    markedString: { type: String, required: true },
  },
  computed: {
    wysiwygHTML() {
      return `<div class='markdown-wrapper'> ${MarkdownUtils.marked(
        this.markedString,
        this.$route.params.repoId
      )}</div>`;
    },
  },
};
</script>

<style scoped>
.wysiwyg :deep(*) {
  line-height: 150%;
}

.wysiwyg :deep(h1) {
  margin: 3rem 0 1rem;
  padding: 0;
  font-size: var(--headline2);
}

.wysiwyg :deep(h2) {
  margin: 1.5rem 0 0.5rem;
  font-size: var(--fontsize-m);
}

.wysiwyg :deep(h3) {
  margin: 1rem 0 0.5rem;
  font-size: var(--body-L);
}

.wysiwyg :deep(a) {
  font-weight: bold;
  color: var(--text-color-link);
}

.wysiwyg :deep(ul),
.wysiwyg :deep(ol) {
  margin-top: 0;
  margin-bottom: 1rem;
}

.wysiwyg :deep(ul > li) {
  margin-top: 0.3rem;
}

.wysiwyg :deep(ol > li) {
  margin-top: 1rem;
}

.wysiwyg :deep(blockquote) {
  margin-right: 0;
  margin-left: 0;
  padding: 0 20px;
  font-size: var(--body-L);
  font-family: var(--fontfamily-secondary);
  font-weight: bold;
  border-left: 6px var(--fake-caffe-sheva) solid;
  letter-spacing: 0.8px;
}

.wysiwyg :deep(img) {
  max-width: 100%;
}

.wysiwyg :deep(pre) {
  overflow-x: auto;
}

.wysiwyg :deep(.markdown-wrapper > :first-child) {
  margin-top: 0;
}

.wysiwyg :deep(.markdown-wrapper > :last-child) {
  margin-bottom: 0;
}
</style>
