/**
 * swmLink node.
 *
 * Based on https://github.com/ueberdosis/tiptap/blob/164eebf07ced16e29e38f13909322ce301575355/packages/extension-link/src/link.ts
 *
 * @module
 */

import { type JSONContent, Node, VueNodeViewRenderer, mergeAttributes } from '@tiptap/vue-3';
import SwmLinkNodeView from '../nodeViews/SwmLinkNodeView.vue';
import { getSwimmEditorServices } from './Swimm';
import { buildSwimmLink } from '../../swmd/swimm_node';
import { isParentOfType } from '../utils';
import { find } from 'linkifyjs';
import {
  ApplicabilityStatus,
  type Link,
  type SmartElementWithApplicability,
  SwmResourceURLPath,
  SwmSymbolLinkType,
  config,
  generateSwmdFileName,
  getSwimmUrlGroups,
  isSmartElementWithNewInfo,
  isSwimmDoc,
  isSwimmPlaylist,
  productEvents,
} from '@swimm/shared';
import type { Link as UiLink } from '@swimm/reefui';
import { asyncNodePasteRule } from '../asyncNodePasteRule';
import { applySwmLinkAutosync, convertSwmLinkToSmartElement } from '../../swmd/autosync';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    swmLink: {
      insertSwmLink: (path: string, docTitle: string, repoId: string) => ReturnType;
      selectAndInsertSwmLinkToDoc: () => ReturnType;
      selectAndInsertSwmLinkToPlaylist: () => ReturnType;
      applySwmLinkAutosync: (
        pos: number,
        token: SmartElementWithApplicability<Link>,
        userRequested?: boolean
      ) => ReturnType;
    };
  }
}

export interface SwmLinkOptions {
  /**
   * Custom HTML attributes that should be added to the rendered HTML tag.
   */
  HTMLAttributes: Record<string, unknown>;
}

export default Node.create<SwmLinkOptions>({
  name: 'swmLink',

  group: 'inline',

  inline: true,

  addOptions() {
    return {
      HTMLAttributes: {
        target: '_blank',
        class: null,
      },
    };
  },

  addAttributes() {
    return {
      path: {
        isRequired: true,
        parseHTML: (element) => {
          return element.getAttribute('data-path');
        },
        renderHTML: (attributes) => {
          return { 'data-path': attributes.path };
        },
      },
      docTitle: {
        isRequired: true,
        parseHTML: (element) => {
          return element.getAttribute('data-doc-title');
        },
        renderHTML: (attributes) => {
          return { 'data-doc-title': attributes.docTitle };
        },
      },
      title: {
        default: null,
      },
      repoId: {
        isRequired: true,
        parseHTML: (element) => {
          return element.getAttribute('data-repo-id');
        },
        renderHTML: (attributes) => {
          return { 'data-repo-id': attributes.repoId };
        },
      },
      repoName: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute('data-repo-name');
        },
        renderHTML: (attributes) => {
          return { 'data-repo-name': attributes.repoName };
        },
      },
      customDisplayText: {
        default: null,
        parseHTML: (element) => {
          return element.hasAttribute('data-custom') ? element.textContent : null;
        },
        renderHTML: (attributes) => {
          return attributes.customDisplayText ? { 'data-custom': '' } : null;
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        priority: 51,
        // Based on https://github.com/ueberdosis/tiptap/blob/164eebf07ced16e29e38f13909322ce301575355/packages/extension-link/src/link.ts#L117
        tag: 'a[href]:not([href *= "javascript:" i])',
        getAttrs: (node) => (node as HTMLElement).hasAttribute('data-swm-link') && null,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const editorServices = getSwimmEditorServices(this.editor!);

    let text;
    if (node.attrs.customDisplayText) {
      text = node.attrs.customDisplayText;
    } else {
      if (node.attrs.repoId !== editorServices.repoId.value) {
        text = `(${node.attrs.repoName}) ${node.attrs.docTitle}`;
      } else {
        text = node.attrs.docTitle ?? '';
      }
    }

    return [
      'a',
      mergeAttributes(
        {
          'data-swm-link': '',
          href:
            node.attrs.repoId === editorServices.repoId.value
              ? node.attrs.path
              : buildSwimmLink(editorServices.baseUrl, node.attrs.repoId, node.attrs.path),
        },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      text,
    ];
  },

  renderText({ node }) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const editorServices = getSwimmEditorServices(this.editor!);
    return node.attrs.repoId === editorServices.repoId.value
      ? node.attrs.path
      : buildSwimmLink(editorServices.baseUrl, node.attrs.repoId, node.attrs.path);
  },

  addNodeView() {
    return VueNodeViewRenderer(SwmLinkNodeView);
  },

  addCommands() {
    return {
      insertSwmLink:
        (path, docTitle, repoId) =>
        ({ commands, editor }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);

          const node: JSONContent = {
            type: this.name,
            attrs: {
              path: encodeURI(path),
              docTitle: docTitle,
              repoId,
              repoName: swimmEditorServices.getRepoName(repoId),
            },
          };

          return commands.insertContent(node);
        },

      selectAndInsertSwmLinkToDoc:
        () =>
        ({ chain, dispatch, editor, state }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);

          if (!isParentOfType(state.doc, state.selection.head, ['paragraph', 'tableHeader', 'tableCell', 'heading'])) {
            return false;
          }

          if (!dispatch) {
            return chain().focus().insertSwmLink('', '', swimmEditorServices.repoId.value).run();
          }

          setTimeout(async () => {
            const doc = await swimmEditorServices.selectLink(SwmSymbolLinkType.Doc);
            if (doc != null) {
              editor.chain().focus().insertSwmLink(doc.path, doc.name, doc.repoId).run();
              swimmEditorServices.external.trackEvent(productEvents.SWIMM_LINK_ADDED, {});
            } else {
              editor.chain().focus().run();
            }
          }, 0);

          return true;
        },

      selectAndInsertSwmLinkToPlaylist:
        () =>
        ({ chain, dispatch, editor, state }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);

          if (!isParentOfType(state.doc, state.selection.head, ['paragraph', 'tableHeader', 'tableCell', 'heading'])) {
            return false;
          }

          if (!dispatch) {
            return chain().focus().insertSwmLink('', '', swimmEditorServices.repoId.value).run();
          }

          setTimeout(async () => {
            const playlist = await swimmEditorServices.selectLink(SwmSymbolLinkType.Playlist);
            if (playlist != null) {
              editor.chain().focus().insertSwmLink(playlist.path, playlist.name, playlist.repoId).run();
            } else {
              editor.chain().focus().run();
            }
          }, 0);

          return true;
        },

      applySwmLinkAutosync:
        (pos, link, userRequested = true) =>
        ({ chain, editor }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);

          if (!isSmartElementWithNewInfo(link)) {
            return false;
          }

          const node = editor.state.doc.nodeAt(pos);
          if (!node) {
            return false;
          }

          if (node.type !== this.type) {
            return false;
          }

          return (
            chain()
              .command(({ dispatch, tr }) => {
                if (dispatch) {
                  applySwmLinkAutosync(tr, node, pos, link);
                }

                return true;
              })
              // The state is the state after the previous command has ran when actually running the commandss
              .command(({ dispatch, state }) => {
                if (dispatch) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  const newNode = state.doc.nodeAt(pos)!;
                  const smartElementInfo = convertSwmLinkToSmartElement(
                    newNode,
                    swimmEditorServices.repoId.value,
                    swimmEditorServices.branch.value,
                    swimmEditorServices.repos.value.repos
                  );

                  const smartElement: SmartElementWithApplicability<Link> = {
                    ...smartElementInfo,
                    applicability: ApplicabilityStatus.Verified,
                    newInfo: smartElementInfo,
                  };
                  swimmEditorServices.autosyncOutput.value.smartElements.set(smartElement.id, smartElement);

                  if (userRequested) {
                    swimmEditorServices.animations.animateNodeById(smartElement.id);
                  }
                }

                return true;
              })
              .run()
          );
        },
    };
  },

  addPasteRules() {
    const swimmEditorServices = getSwimmEditorServices(this.editor);

    return [
      asyncNodePasteRule(this.editor, {
        type: this.type,
        find: (text) =>
          find(text)
            .filter((link) => {
              return link.isLink && (isSwimmDoc(link.href) || isSwimmPlaylist(link.href));
            })
            .map((link) => ({
              text: link.value,
              index: link.start,
              data: link,
            })),
        getAttributes: async (match) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const { repoId, resourceId, resourceType } = getSwimmUrlGroups(match.data!.href);

          const isCrossRepo = repoId.value !== swimmEditorServices.repoId.value;
          const branch = !isCrossRepo
            ? swimmEditorServices.branch.value
            : swimmEditorServices.repos.value.repos?.find((repo) => repo.id === repoId)?.defaultBranch;

          let linkType: SwmSymbolLinkType;
          switch (resourceType) {
            case SwmResourceURLPath.Docs:
            default:
              linkType = SwmSymbolLinkType.Doc;
              break;
            case SwmResourceURLPath.Playlists:
              linkType = SwmSymbolLinkType.Playlist;
              break;
          }

          let links: UiLink[];
          if (branch) {
            links = await swimmEditorServices.external.getRepoLinks(repoId, branch, linkType);
          } else {
            links = [];
          }

          const link = links.find((doc) => doc.id === resourceId);
          const repoName = swimmEditorServices.getRepoName(repoId);

          if (link == null || repoName == null) {
            return false;
          }

          return {
            path: `/${config.SWM_FOLDER_IN_REPO}/${generateSwmdFileName(link.id, link.name)}${
              config.SWMD_FILE_EXTENSION
            }`,
            docTitle: link.name,
            repoId,
            repoName,
          };
        },
      }),
    ];
  },
});
