<script setup lang="ts">
import type { Editor } from '@tiptap/core';
import { computed, ref, watch } from 'vue';

import { SwmSelectionContentSwimmport, SwmSelectionContentTokenPreview } from '@swimm/reefui';
import type { PathSuggestion, TokenSuggestion } from '@swimm/shared';

import { useTokenSelectionPreview } from '@/composables/tokenSelection/preview';
import type { TextSuggestions } from '@/services/swimmport/docTraverse';
import type { ComponentExposed } from 'vue-component-type-helpers';

export type SelectedSuggestion =
  | { type: 'path'; suggestion: PathSuggestion }
  | { type: 'token'; suggestion: TokenSuggestion };

const props = defineProps<{
  editor: Editor;
  text: string;
  suggestions: TextSuggestions;
  driver: 'caret' | 'cursor';
  hide: () => void;
}>();

const emit = defineEmits<{
  selected: [selected: SelectedSuggestion];
  dismiss: [];
  mouseEnter: [];
  mouseLeave: [];
  previewShown: [];
}>();

const swimmport = ref<ComponentExposed<typeof SwmSelectionContentSwimmport>>();
const onKeyDown = computed(() => swimmport?.value?.onKeyDown);

const tokenSelectionPreview = useTokenSelectionPreview(props.editor);

const options = ref({
  additionalHandlers: {
    Escape: () => {
      props.hide();
      return true;
    },
    Alt_d: () => {
      emit('dismiss');
      props.hide();
      return true;
    },
    'Alt_∂': () => {
      emit('dismiss');
      props.hide();
      return true;
    },
  },
});

// TODO: Use a suggestion type attribute.
function setTokenSuggestionPreview(suggestion: PathSuggestion | TokenSuggestion) {
  if ('token' in suggestion) {
    tokenSelectionPreview.set(suggestion);
  }
}

function onMouseEnter() {
  if (props.driver === 'cursor') {
    emit('mouseEnter');
  }
}

function onMouseLeave() {
  if (props.driver === 'cursor') {
    emit('mouseLeave');
  }
}

function onDismiss() {
  emit('dismiss');
  props.hide();
  return true;
}

function command(payload: TokenSuggestion | PathSuggestion) {
  emit('selected', { type: props.suggestions?.type, suggestion: payload } as SelectedSuggestion);
  props.hide();
  return true;
}

watch(
  () => tokenSelectionPreview.state.value.show,
  () => {
    emit('previewShown');
  }
);

defineExpose({ onKeyDown });
</script>

<template>
  <SwmSelectionContentSwimmport
    ref="swimmport"
    :query="text"
    :suggestions="suggestions?.suggestions"
    :suggestions-type="suggestions?.type"
    :driver="driver"
    :command="command"
    :options="options"
    @select-suggestion="command"
    @focused-suggestion="setTokenSuggestionPreview"
    @hovered-suggestion="setTokenSuggestionPreview"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @dismiss="onDismiss"
  >
    <template #preview="{ cachedHighlighters }">
      <SwmSelectionContentTokenPreview
        v-if="tokenSelectionPreview.state.value.show"
        :code="tokenSelectionPreview.state.value.code"
        :token-suggestion="tokenSelectionPreview.state.value.token"
        :max-rows="5"
        :cached-highlighters="cachedHighlighters"
      />
    </template>
  </SwmSelectionContentSwimmport>
</template>
