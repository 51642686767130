<script setup lang="ts">
import { productEvents } from '@swimm/shared';
import DocTag from './DocTag.vue';
import * as firestore from '@/adapters-common/firestore-wrapper';
import MenuSelection from '@/common/components/organisms/MenuSelection.vue';
import { TAG_COLORS, addNewTagToWorkspace, addTagToDoc, compareTagNames } from '../services/tag-utils';
import { useRouteData } from '@/common/composables/useRouteData';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useNotificationsStore } from '@swimm/editor';
import { getLoggerNew } from '@swimm/shared';
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { DocTagItem } from '../services/tag-utils';

const props = defineProps({
  docId: { type: String, default: null },
  repoId: { type: String, default: null },
  shouldShowDropdown: { default: false, type: Boolean },
  shouldUseDefaultText: { default: true, type: Boolean },
  isPopup: { default: false, type: Boolean },
  showBorder: { type: Boolean, default: true },
  shouldDisableActions: { type: Boolean, default: false },
});

const emit = defineEmits(['close-option-bar', 'doc-tags-changed', 'create-draft-with-tag']);

const routeData = useRouteData();
const store = useStore();
const drafts3Store = useDrafts3Store();
const analytics = useAnalytics();
const { addNotification } = useNotificationsStore();
const logger = getLoggerNew(__modulename);
const { user } = storeToRefs(useAuthStore());

onMounted(async () => {
  await store.dispatch('database/fetchDocumentChildCollections', {
    documentId: routeData.workspaceId,
    children: [firestore.collectionNames.TAGS],
    containerCollection: firestore.collectionNames.WORKSPACES,
  });
});

const getDocTagsFromDB = store.getters['database/db_getDocTags'];
const getAllWorkspaceTags = store.getters['database/db_getAllWorkspaceTags'];

const docTags = computed(() => {
  let tags: DocTagItem[];
  const draft = drafts3Store.drafts?.get(props.docId);
  if (!draft?.isNew) {
    tags = getDocTagsFromDB(routeData.workspaceId, props.repoId, props.docId) as DocTagItem[];
  } else {
    tags = allTags.value.filter((tag) => (draft.tags ?? []).includes(tag.id));
  }
  return tags.sort(compareTagNames);
});

const docTagsNames = computed(() => {
  return docTags.value.map((tag) => tag.name);
});

const allTags = computed(() => {
  return getAllWorkspaceTags(routeData.workspaceId).sort(compareTagNames) as DocTagItem[];
});
const allTagsNames = computed(() => {
  return allTags.value.map((tag) => tag.name);
});
const tagsNamesWithoutExistingTags = computed(() => {
  return allTagsNames.value.filter((tagName) => !docTagsNames.value.includes(tagName));
});

const TagColorClass = (tag: DocTagItem) =>
  TAG_COLORS.includes(tag.color) ? `doc_tag doc_tag_${tag.color}` : 'doc_tag';

const closeSelectionMenu = () => emit('close-option-bar');

async function addTagToSwm({ tagId }) {
  const draft = drafts3Store.drafts.get(props.docId);
  if (!draft?.isNew) {
    return await addTagToDoc({ repoId: props.repoId, docId: props.docId, tagId });
  } else {
    await drafts3Store.updateAttrs(props.docId, { tags: [...(draft.tags ?? []), tagId] });
  }
}

const addTag = async (tagName) => {
  try {
    const trimmedTagName = tagName.trim();
    closeSelectionMenu();
    if (tagExistsOnDoc(trimmedTagName)) {
      addNotification(`A tag named "${trimmedTagName}" already exists.`, {
        icon: 'warning',
      });
      return;
    }
    const existingTagInWorkspace = allTags.value.find((tag) => trimmedTagName.toLowerCase() === tag.name.toLowerCase());
    if (existingTagInWorkspace) {
      await addTagToSwm({
        tagId: existingTagInWorkspace.id,
      });
    } else {
      const newTag = await addNewTagToWorkspace(routeData.workspaceId as string, trimmedTagName, user.value.uid);
      await addTagToSwm({
        tagId: newTag.id,
      });
      await store.dispatch('database/fetchDocumentChildCollections', {
        documentId: routeData.workspaceId,
        children: [firestore.collectionNames.TAGS],
        containerCollection: firestore.collectionNames.WORKSPACES,
      });
    }
    if (props.repoId) {
      await store.dispatch('database/fetchRepoResource', {
        repoId: props.repoId,
        collectionName: firestore.collectionNames.SWIMMS,
        docId: props.docId,
      });
    }
    analytics.track(productEvents.TAG_ADDED_TO_DOC, {
      'Workspace ID': routeData.workspaceId,
      'Repo ID': props.repoId,
      'Document ID': props.docId || 'draft',
      'Tag Name': trimmedTagName,
      Context: 'Tags',
      Origin: routeData.routeName,
    });
  } catch (err) {
    logger.error({ err }, `Could not add tag. ${err}`);
    addNotification(`Failed to add tag "${tagName}".`, {
      icon: 'warning',
    });
  }
};

/**
 * @param {string} tagName
 * @return {boolean}
 */
function tagExistsOnDoc(tagName) {
  const trimmedTagName = tagName.trim();
  return docTagsNames.value.map((name) => name.toLowerCase()).includes(trimmedTagName.toLowerCase());
}
</script>

<template>
  <div :class="{ 'tags-container': isPopup }">
    <div v-if="docTags.length" class="tags" :class="['tags', { popup: isPopup }]">
      <DocTag
        v-for="tag in docTags"
        :data-testid="`tag-${tag.name}`"
        :key="tag.name"
        :tag-id="tag.id"
        :doc-id="docId"
        :repo-id="repoId"
        :workspace-id="(routeData.workspaceId as string)"
        :all-tags-names="allTagsNames"
        :text="tag.name"
        :tag-style="TagColorClass(tag)"
        :should-disable-actions="shouldDisableActions"
      />
    </div>
    <div v-else-if="shouldUseDefaultText" class="body-S empty-tags" data-testid="doc-tags-empty-state">
      Tags will appear here
    </div>
    <MenuSelection
      v-if="shouldShowDropdown"
      data-testid="tag-selector"
      placeholder="Create new or search"
      :all-options="tagsNamesWithoutExistingTags"
      no-matching-text="Press enter to create new tag"
      @option-selected="addTag"
      @on-close="closeSelectionMenu"
      :maxlength="20"
      :show-border="showBorder"
    />
  </div>
</template>

<style scoped>
.tags {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.tags-container {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.popup {
  padding: 8px 8px 0 8px;
}

.tag-selector.v-select :deep(.vs__dropdown-menu) {
  max-height: 60px;
}

.empty-tags {
  color: var(--text-color-secondary);
}
</style>
