import * as firestore from '@/adapters-common/firestore-wrapper';

export function subscribeToUserNotifications({ user, callback }) {
  if (!user || !user.uid) {
    return [];
  }
  const collectionRef = firestore.getSubCollectionRefRecursive([firestore.collectionNames.NOTIFICATIONS], []);
  const unsubscribeById = collectionRef
    .where('recipient_id', '==', user.uid)
    .onSnapshot((_querySnapshot) => callback());
  const result = [unsubscribeById];
  if (user.email) {
    const unsubscribeByEmail = collectionRef
      .where('recipient_email', '==', user.email)
      .onSnapshot((_querySnapshot) => callback());
    result.push(unsubscribeByEmail);
  }
  return result;
}
