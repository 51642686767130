<script setup>
import { SwModal } from '@swimm/ui';
import SettingsModalVerticalTab from '@/modules/core/components/settings-modal/SettingsModalVerticalTab.vue';
import { computed, ref, watch } from 'vue';
import { useBillingStore } from '@/modules/billing/store/billing';

const props = defineProps({
  header: { type: String, default: '' },
  show: { type: Boolean, required: true },
  initialTabCode: { type: String, default: '' },
  tabs: { type: Array, required: true },
  changeToTabCode: { type: String, default: '' },
});

const emit = defineEmits(['close', 'tab-selected']);

const initialTabToShow = computed(() => props.tabs.find((code) => code === props.initialTabCode)?.code);
const selectedTabCode = ref(initialTabToShow.value || props.tabs[0].code);
const { fetchBillingData } = useBillingStore();

function tabSelected(tabCode) {
  if (tabCode === selectedTabCode.value || !props.tabs.some((tab) => tab.code === tabCode)) {
    return;
  }
  selectedTabCode.value = tabCode;
  emit('tab-selected', tabCode);
}

function isTabSelected(tabCode) {
  return tabCode === selectedTabCode.value;
}

watch(
  () => props.show,
  (newValue) => {
    if (newValue) {
      if (props.initialTabCode) {
        selectedTabCode.value = props.initialTabCode;
      }
      emit('tab-selected', selectedTabCode.value);
      fetchBillingData();
    } else {
      selectedTabCode.value = props.tabs[0].code;
    }
  }
);

watch(
  () => props.changeToTabCode,
  (newValue) => {
    if (newValue) {
      selectedTabCode.value = newValue;
      emit('tab-selected', selectedTabCode.value);
    }
  }
);
</script>

<template>
  <SwModal :show-modal="show" :heading="header" :padded="false" @close="emit('close')">
    <template #header>
      <slot name="header" />
    </template>
    <div class="modal-content">
      <div class="sidebar">
        <SettingsModalVerticalTab
          v-for="tab in tabs"
          :key="tab.code"
          :name="tab.title"
          :selected="isTabSelected(tab.code)"
          :data-testid="`tab-${tab.code}`"
          @select="tabSelected(tab.code)"
        />
      </div>
      <div class="main">
        <slot name="banner" />
        <div class="content">
          <slot name="content" :selected-tab-code="selectedTabCode" />
        </div>
      </div>
    </div>
  </SwModal>
</template>

<style scoped lang="postcss">
.modal-content {
  display: flex;
  justify-items: center;
  width: 1280px;
  height: 900px;
  overflow: hidden;

  .sidebar {
    flex: 1;
    border-right: 1px solid var(--color-border-default);
    overflow-y: auto;
    max-width: 210px;
  }

  .main {
    flex: 3;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .content {
    padding: var(--space-md);
    flex-grow: 1;
    box-sizing: border-box;
  }
}
@media only screen and (max-width: 1480px) {
  .modal-content {
    width: 80vw;
  }
}

@media only screen and (max-height: 900px) {
  .modal-content {
    height: 80vh;
  }
}
</style>
