<script setup>
import { Icon, SwText } from '@swimm/ui';
import { computed } from 'vue';

const props = defineProps({
  name: { type: String, required: true },
  icon: { type: String, required: true },
  selected: { type: Boolean, default: false },
});
const weight = computed(() => (props.selected ? 'bold' : 'light'));
const testId = computed(() => `playlist-anchor-${props.name.toLowerCase()}`);
</script>

<template>
  <div :class="['anchor-step', { selected }]" :data-testid="testId">
    <SwText variant="headline3" :weight="weight"><Icon :name="icon" no-padding /></SwText>
    <SwText variant="body-S" :weight="weight">{{ name }}</SwText>
  </div>
</template>

<style scoped lang="postcss">
.anchor-step {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--space-base) 0 var(--space-base) var(--space-base);
  gap: var(--space-base);
}
</style>
