import { PageRoutesNames, RepoPageRouteNames } from '@/common/consts';
import { toValidBranchName } from '@swimm/shared';
import path from 'path-browserify';

export const newDocOrPlaylist = [PageRoutesNames.DOC_NEW, PageRoutesNames.PLAYLIST_NEW];
export const playlistOrigin = [PageRoutesNames.PLAYLIST_EDIT, PageRoutesNames.PLAYLIST_NEW];
export const docOrigin = [PageRoutesNames.DOC_EDIT, PageRoutesNames.DOC_NEW];
export const docAndPlaylistOrigin = [...docOrigin, ...playlistOrigin];

export function composeRepoPagePath(repoRoute: string, shouldNavigateToStatusTab: boolean, withPR: boolean) {
  let repoPageTab = '';
  if (withPR) {
    repoPageTab = RepoPageRouteNames.PULL_REQUEST;
  }

  if (shouldNavigateToStatusTab) {
    repoPageTab = RepoPageRouteNames.NEEDS_REVIEW;
  }

  return path.join(repoRoute, repoPageTab);
}

export function composeCommitFinalizedMessage(newBranch: string) {
  return newBranch != null
    ? `Docs committed successfully. Switched to branch "${toValidBranchName(newBranch)}"`
    : 'Docs committed successfully.';
}
