export enum Modifiers {
  CTRL = 'ctrl',
  ALT = 'alt',
  SHIFT = 'shift',
  META = 'meta',
}

export const ModifierToMacSymbol = {
  [Modifiers.CTRL]: '⌘',
  [Modifiers.ALT]: '⌥',
  [Modifiers.SHIFT]: '⇧',
  [Modifiers.META]: '⌃',
};

export const ModifierToWindowsSymbol = {
  [Modifiers.CTRL]: 'Ctrl',
  [Modifiers.ALT]: 'Alt',
  [Modifiers.SHIFT]: 'Shift',
  [Modifiers.META]: 'Windows',
};
