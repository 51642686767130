<template>
  <NodeViewWrapper class="text-placeholder">
    <div
      :contenteditable="!disabled"
      class="placeholder-text"
      :class="[node.attrs.id, { 'empty-field': !node.attrs.value, 'field-selected': highlighted }]"
      :placeholder="node.attrs.text"
      :data-testid="node.attrs.id"
      @focus="highlightPlaceholder"
      @blur="blurPlaceholder"
      @input.stop="placeholderTextChanged($event)"
      @keypress.enter="placeholderEnterPressed"
      @keydown.delete="backspacePressed"
    >
      {{ placeholderText }}
    </div>
  </NodeViewWrapper>
</template>

<script>
import { inject } from 'vue';
import { productEvents } from '@swimm/shared';
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { useTiptapEditorStateStore } from '@/stores/nodeViewsStore';
import { computed, ref, toRefs, watch } from 'vue';

export default {
  name: 'EditorPlaceholderText',
  components: { NodeViewWrapper },
  props: nodeViewProps,
  setup(props) {
    const analytics = inject('analytics');
    const editorStateStore = useTiptapEditorStateStore();
    const edited = ref(false);

    const symbol = computed(() => {
      if (!props.node?.attrs?.id) {
        return null;
      }
      return editorStateStore.aggregatedSymbols[props.node.attrs.id];
    });

    const disabled = computed(() => !props.editor.isEditable);
    const placeholderText = computed(() => {
      return symbol.value?.value ?? '';
    });
    const placeholderTextChanged = (event) => {
      props.editor.options.onPlacehoderTextChanged({
        placeholderId: props.node.attrs.id,
        inputValue: event.target.innerText,
      });
    };

    const highlighted = computed(() => {
      return symbol.value?.['highlighted'] ?? false;
    });
    const highlightPlaceholder = () => {
      symbol.value['highlighted'] = true;
    };
    const blurPlaceholder = () => {
      symbol.value['highlighted'] = false;
    };
    const backspacePressed = () => {
      if (placeholderText.value !== '') {
        return;
      }
      const from = props.getPos();
      const to = from + props.node.nodeSize - 1;
      props.editor.chain().focus().deleteRange({ from, to }).focus(from).run();
    };

    watch([highlighted, placeholderText], ([highlighted, placeholderText]) => {
      if (disabled.value) {
        return;
      }
      const hasCommand =
        placeholderText.startsWith('/') ||
        placeholderText.startsWith('`') ||
        placeholderText.endsWith(' /') ||
        placeholderText.endsWith(' `');
      if ((!highlighted && placeholderText && !hasCommand) || (highlighted && hasCommand)) {
        const from = props.getPos();
        const to = from + props.node.nodeSize - 1;
        props.editor.chain().focus().insertContentAt({ from, to }, placeholderText).run();
      }
    });

    const placeholderEnterPressed = () => {
      // We blur the placeholder on pressing enter as it will be converted to normal text
      blurPlaceholder();
    };

    const { node } = toRefs(props);
    watch(
      node,
      (_, oldValue) => {
        if (!edited.value) {
          analytics.track(productEvents.EDITED_PLACEHOLDER, {
            type: 'text',
            'Original Placeholder Text': oldValue.attrs.text,
          });
          edited.value = true;
        }
      },
      { deep: true }
    );

    return {
      edited,
      highlighted,
      disabled,
      analytics,
      placeholderText,
      placeholderTextChanged,
      highlightPlaceholder,
      blurPlaceholder,
      placeholderEnterPressed,
      backspacePressed,
    };
  },
};
</script>

<style scoped lang="postcss">
.text-placeholder {
  display: inline-block;
}

.placeholder-text {
  font-size: var(--body-S);
  border: none;
  border-bottom: 2px dashed var(--color-border-default);
  border-radius: 4px;
  color: var(--text-color-secondary);
  background-color: var(--color-bg);
  outline: none;
  width: auto;

  &:empty::before {
    content: attr(placeholder);
    display: block;
    margin: 0 2px;
    pointer-events: none;
  }
}

.placeholder-text.empty-field {
  background: var(--color-surface);
}

.placeholder-text.field-selected {
  background: var(--color-status-warning);
}
</style>
