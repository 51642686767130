<template>
  <TopMenuLayout>
    <template #topBar>
      <TopBar :show-route-back="false">
        <BatchCommitButton v-if="repoId && branch" :repo-id="repoId" :workspace-id="workspaceId" :branch="branch" />
      </TopBar>
    </template>
    <template #content>
      <PageContainer v-if="repo.metadata?.id">
        <DocRequestModal
          :edited-doc-request="editedDocRequest"
          :show="shouldShowDocRequestModal"
          :new-origin="newDocRequestOrigin"
          :repo-id="repoId"
          @close="docRequestModalClosed"
        />
        <RepoSettingsModal
          :show="showRepoSettingsModal"
          :initial-tab-code="modalTabOnMount"
          :repo-id="repoId"
          origin="Repo Page Settings"
          @close="showRepoSettingsModal = false"
        />
        <UserSettingsModal
          :show="showUserSettingsModal"
          :initial-tab-code="modalTabOnMount"
          origin="Repo Page Settings"
          @close="showUserSettingsModal = false"
        />
        <div class="repo-header" data-testid="repo-header">
          <div class="repo-title-container">
            <RepoVisibilityLabel
              big
              :provider="repo.metadata.provider"
              :is-private="repo.metadata.is_private"
              :show-background="isOnDummyRepoPage"
              >{{ isOnDummyRepoPage ? '(Demo)' : '' }}</RepoVisibilityLabel
            >
            <h3 class="headline1">
              <span class="repo-name text-ellipsis" data-testid="repo-title">{{ repo.metadata.name }}</span>
              <GitHubAppIntergrationShield
                class="shield-icon"
                :repo="repo"
                :workspace-id="workspaceId"
                @click="showRepoSettingsModal = true"
              />
              <Icon
                v-if="isWorkspaceAdmin"
                class="clickable settings-cog headline2"
                name="settings2"
                @click="showRepoSettingsModal = true"
              />
            </h3>
          </div>
          <div class="actions">
            <VTooltip popper-class="tooltip-on-modal" :disabled="!isDummyRepo" placement="top">
              <div>
                <Action secondary @click="addFolder" :disabled="isDummyRepo" data-testid="new-folder-button"
                  >New folder</Action
                >
              </div>
              <template #popper>
                <SwText :variant="'body-S'">
                  Folders are disabled in the demo repo. To create folders, connect your own repository.
                </SwText>
              </template>
            </VTooltip>
            <CreateButton
              v-if="canModifyResources"
              @click="() => toggleCreationHub({})"
              @new-doc-click="navigateToNewDocOrPlaylistInCurrentFolder"
              @new-playlist-click="navigateToNewDocOrPlaylistInCurrentFolder({ ...$event, isPlaylist: true })"
            />
          </div>
        </div>
        <RepoPageTabs
          :show-search="shouldShowSearch"
          :show-filters="isDocumentationTab"
          :current-tab-index="currentTabIndex"
          :tabs="tabs"
          :repo-id="repoId"
          @tab-selected="handleTabSelection"
        />
        <template v-if="!loading">
          <RepoDocumentations
            v-if="isDocumentationView"
            :loading="loadingDocumentations"
            :workspace-id="workspaceId"
            :repo="repo"
            :all-items="repoDocumentation"
            :filtered-items="folderDocumentation"
            :ribbon-contents="ribbonContents"
            :changed-files="changedFilesDiff"
            :pending-docs="pendingDocs"
            :pending-playlists="pendingPlaylists"
            :sort-option="sortBy"
            :is-sort-desc="isSortDesc"
            @toggle-creation-hub="toggleCreationHub($event)"
            @navigate-to-item="navigateToNewDocOrPlaylist($event)"
            @sort-changed="handleSortChanged"
          />
          <RepoPullRequestsPage
            v-if="isPullRequestsView"
            :loading="loadingPendingDocuments"
            :repo-url="repo.metadata.url"
            :branch="branch"
            :docs="pendingDocs"
            :playlists="pendingPlaylists"
            :repo-id="repoId"
            :selected-pr-num="selectedPrNum"
          />
          <RepoNeedsReviewPage
            v-if="currentRoute === RepoPageRouteNames.NEEDS_REVIEW"
            :loading="loadingDocumentations"
            :repo="repo"
            :unapplicable-swms="unapplicableSwms"
            :source="source"
            @finished-verifying="reportNeedsReview"
          />
          <DocRequestsTab
            v-if="currentRoute === RepoPageRouteNames.DOC_REQUESTS"
            :repo="repo"
            :doc-requests="docRequests"
            :drafts="repoUnitDrafts"
            @create-new-doc-request-clicked="showNewDocRequestModal('Doc Requests Page')"
            @doc-request-action="handleDocRequestAction"
          />
        </template>
      </PageContainer>
    </template>
  </TopMenuLayout>
</template>

<script>
import { useAnalytics } from '@/common/composables/useAnalytics';
import { DocumentationTypes, RepoPageRouteNames } from '@/common/consts';
import RepoPullRequestsPage from '@/common/pages/RepoPullRequestsPage.vue';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import RepoSettingsModal from '@/modules/repo/settings/RepoSettingsModal.vue';
import UserSettingsModal from '@/modules/user-settings/modals/UserSettingsModal.vue';
import { isRepoInitialized } from '@/remote-adapters/init';
import {
  ApplicabilityStatus,
  UrlUtils,
  eventLogger,
  getLoggerNew,
  getSwimmIdFromSwimmFileName,
  gitProviderUtils,
  gitwrapper,
  isGitIgnoreValid,
  isRepoIdDummyRepo,
  isSwmDoc,
  pageEvents,
  parseRepoDataFields,
  productEvents,
  settingsTypes,
  swmdFileName,
} from '@swimm/shared';
import { mapActions, mapGetters } from 'vuex';
import RepoPageTabs from '@/common/components/organisms/RepoPageTabs.vue';
import { getRemoteRepo, getRepoDefaultBranch } from '@/remote-adapters/local_repo';
import { getPlaylistsSorted } from '@/common/utils/playlist-utils';
import {
  REPO_FILTERS_SORT_OPTIONS,
  REPO_FILTERS_SORT_OPTIONS_PROPS,
  SWAL_CONTACT_US_CONTENT,
} from '@/common/utils/common-definitions';
import { sortByProp } from '@/common/utils/helpers';
import swal from 'sweetalert';
import { usePageTitle } from '@/common/composables/pageTitle';
import RepoDocumentations from '@/common/components/organisms/RepoDocumentations.vue';
import RepoNeedsReviewPage from '@/common/pages/RepoNeedsReviewPage.vue';
import DocRequestModal from '@/modules/doc-requests/modals/DocRequestModal.vue';
import DocRequestsTab from '@/modules/doc-requests/components/DocRequestsTab.vue';
import { useDemoStore } from '@/modules/demo/demo';
import { getValidDocRequestsSorted } from '@/modules/doc-requests/services/doc-requests-utils';
import { storeToRefs } from 'pinia';
import { useFiltersStore } from '@/modules/core/filters-row/useFiltersStore';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { useRouting } from '../composables/routing';
import { useNavigate } from '../composables/navigate';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import GitHubAppIntergrationShield from '@/common/components/RepoIntegrations/GitHubAppIntergrationShield.vue';
import RepoVisibilityLabel from '@/common/components/atoms/RepoVisibilityLabel.vue';
import { getRouteToNewDocURL, getRouteToNewPlaylistURL } from '@/router/router-utils';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { docLoaderWorker } from '@/workers';
import CreateButton from '@/modules/core/creation-hub/CreateButton.vue';
import PageContainer from '@/common/layouts/PageContainer.vue';
import TopMenuLayout from '@/common/layouts/TopMenuLayout.vue';
import TopBar from '@/common/components/TopBar/TopBar.vue';
import BatchCommitButton from '@/modules/batch-commit/components/BatchCommitButton.vue';
import { computed, ref } from 'vue';
import { groupBy } from 'lodash-es';
import { useWorkspaceSettingsModalStore } from '@/modules/workspace/modals/settings/store/workspace-settings';
import { useCreationHubStore } from '@/modules/core/creation-hub/store/creation-hub';
import { useRepoDocsStore } from '@/modules/core/stores/repo-docs';
import { computeDraftDisplayName } from '@/common/utils/draft-utils';
import { useDrafts3ValidationStore } from '@/modules/drafts3/stores/drafts3Validation';

const logger = getLoggerNew(__modulename);

const OrderedUnitsFilters = {
  COMMITTED: 'COMMITTED',
  DRAFTS: 'DRAFTS',
  PENDING: 'PENDING',
  ALL: 'ALL',
};

const RouteNamesToLabels = {
  [RepoPageRouteNames.DOCUMENTATIONS]: 'In this branch',
  [RepoPageRouteNames.PULL_REQUEST]: 'Pull requests',
  [RepoPageRouteNames.DOC_REQUESTS]: 'Doc requests',
  [RepoPageRouteNames.NEEDS_REVIEW]: 'Review outdated',
  [RepoPageRouteNames.RULES]: 'Rules',
};

export default {
  name: 'RepoPage',
  components: {
    UserSettingsModal,
    RepoSettingsModal,
    RepoPullRequestsPage,
    RepoPageTabs,
    RepoDocumentations,
    RepoNeedsReviewPage,
    DocRequestsTab,
    DocRequestModal,
    GitHubAppIntergrationShield,
    RepoVisibilityLabel,
    CreateButton,
    PageContainer,
    TopMenuLayout,
    TopBar,
    BatchCommitButton,
  },
  props: {
    repoId: { type: String, required: true },
    workspaceId: { type: String, required: true },
    branch: { type: String, default: null },
    currentRoute: { type: String, required: true },
    modalToOpenOnMount: { type: String, default: null },
    modalTabOnMount: { type: String, default: null },
    source: { type: String, default: null },
  },
  setup(props) {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const { isOnDummyRepoPage } = storeToRefs(useDemoStore());
    const filtersStore = useFiltersStore();
    const drafts3Store = useDrafts3Store();
    const { logEvent } = useSwimmEventLogs();
    const { getCurrentOrDefaultBranch, isBranchProtected } = useRouting();
    const { navigateToPageAndTerminateWorker, getRepoPath } = useNavigate();
    const { pageTitle } = usePageTitle();
    const reposStore = useReposStore();
    const { reposStateData } = storeToRefs(reposStore);
    const { setRepoStateBranchData } = reposStore;
    const foldersStore = useFoldersStore();
    const { currentFolderIdByRepo, foldersByRepo, isAddingNewFolderState } = storeToRefs(foldersStore);
    const { initRootFolder, getFolderPath, getFolderItems } = foldersStore;
    const { openWorkspaceSettingsModal } = useWorkspaceSettingsModalStore();
    const creationHubStore = useCreationHubStore();
    const { creationHubModalRef, showCreationHubModal } = storeToRefs(creationHubStore);
    const { openCreationHubModal, closeCreationHubModal } = creationHubStore;
    const repoDocsStore = useRepoDocsStore();
    const { docDraftsInBranchItemsForRepo, playlistDraftsInBranchItemsForRepo } = repoDocsStore;

    const { currentRepoNeedsReviewDocs, currentRepoDocsInBranch, currentRepoPlaylistsInBranch } =
      storeToRefs(repoDocsStore);
    const drafts3Validation = useDrafts3ValidationStore();

    const currentFolder = computed(
      () => foldersByRepo.value[props.repoId]?.[currentFolderIdByRepo.value[props.repoId]]
    );
    const currentFolderItems = computed(() =>
      getFolderItems({ repoId: props.repoId, folderId: currentFolderIdByRepo.value[props.repoId], includeDrafts: true })
    );

    const folderIdForCreation = ref(null);

    const isDummyRepo = computed(() => isRepoIdDummyRepo(props.repoId));

    function navigateToNewDocOrPlaylist({ isPlaylist, query }) {
      const url = isPlaylist
        ? getRouteToNewPlaylistURL({ workspaceId: props.workspaceId, repoId: props.repoId, branch: props.branch })
        : getRouteToNewDocURL({ workspaceId: props.workspaceId, repoId: props.repoId, branch: props.branch });
      navigateToPageAndTerminateWorker({ newRoute: url, query });
      folderIdForCreation.value = null;
    }

    function navigateToNewDocOrPlaylistInCurrentFolder({ isPlaylist, query }) {
      if (currentFolder.value && !currentFolder.value.is_root) {
        query.folderId = currentFolder.value.id;
      }
      navigateToNewDocOrPlaylist({ isPlaylist, query });
    }

    return {
      user,
      analytics,
      isOnDummyRepoPage,
      RepoPageRouteNames,
      OrderedUnitsFilters,
      settingsTypes,
      filtersStore,
      drafts3Store,
      reposStateData,
      setRepoStateBranchData,
      logEvent,
      getCurrentOrDefaultBranch,
      navigateToPageAndTerminateWorker,
      getRepoPath,
      pageTitle,
      isBranchProtected,
      currentFolder,
      currentFolderItems,
      isAddingNewFolderState,
      initRootFolder,
      getFolderPath,
      navigateToNewDocOrPlaylist,
      navigateToNewDocOrPlaylistInCurrentFolder,
      folderIdForCreation,
      isDummyRepo,
      openWorkspaceSettingsModal,
      creationHubModalRef,
      showCreationHubModal,
      openCreationHubModal,
      closeCreationHubModal,
      currentRepoNeedsReviewDocs,
      currentRepoDocsInBranch,
      currentRepoPlaylistsInBranch,
      docDraftsInBranchItemsForRepo,
      playlistDraftsInBranchItemsForRepo,
      repoDocsStore,
      drafts3Validation,
    };
  },
  data() {
    return {
      // We want to mark when the component is destroyed to ignore
      // errors that happen since $route.param.repoId is undefined after navigation
      destroyed: false,
      loading: true,
      loadingPendingDocuments: true,
      currentBranch: '',
      sortBy: null,
      isSortDesc: true,
      finishedLoadingSwimms: false,
      loadingBranch: false,
      defaultBranchInitializationData: { isDefaultBranchInitialized: true, defaultBranchName: '' },
      isOnDefaultBranch: true,
      isGitIgnoreValid: true,
      gitIgnoreURL: '',
      changeRequestName: 'Pull Request',
      shouldShowDocRequestModal: false,
      editedDocRequest: null,
      newDocRequestOrigin: '',
      selectedTabIndex: 0,
      showRepoSettingsModal: false,
      showUserSettingsModal: false,
      variations: [],
      selectedPrNum: null,
    };
  },
  computed: {
    ...mapGetters('database', [
      'db_getWorkspace',
      'db_getUnitsOrderedByStatus',
      'db_isWorkspaceAdmin',
      'db_getPlaylists',
      'db_getSwimms',
      'db_isPublicRepo',
      'db_isRepoLifeguard',
      'db_getRepoMetadata',
      'db_getDocRequests',
    ]),
    ...mapGetters('filesystem', [
      'fs_getRepository',
      'fs_getRepoPendingDocumentations',
      'fs_getSwmsIdsFromRepoFolder',
      'fs_getChangedFilesForBranch',
      'fs_getRepoLocalFilesLists',
    ]),
    tabs() {
      const tabs = [];

      const documentation = {
        key: RepoPageRouteNames.DOCUMENTATIONS,
        label: RouteNamesToLabels[RepoPageRouteNames.DOCUMENTATIONS],
      };

      const pullRequestTab = {
        key: RepoPageRouteNames.PULL_REQUEST,
        label: RouteNamesToLabels[RepoPageRouteNames.PULL_REQUEST],
        count: this.pendingPRsCount,
      };
      tabs.push(documentation, pullRequestTab);

      const isRepoStatusOutdated = this.unitsRepoStatus === ApplicabilityStatus.Outdated;
      const isRepoStatusAutosynced = !isRepoStatusOutdated && this.unitsRepoStatus === ApplicabilityStatus.Autosyncable;

      let needsReviewNotificationColor = '';
      if (isRepoStatusOutdated) {
        needsReviewNotificationColor = '--oh-no-red';
      } else if (isRepoStatusAutosynced) {
        needsReviewNotificationColor = '--warning-yellow';
      }

      const isForLaunch = false;
      if (isForLaunch) {
        const docRequestsTab = {
          key: RepoPageRouteNames.DOC_REQUESTS,
          label: `${RouteNamesToLabels[RepoPageRouteNames.DOC_REQUESTS]}`,
        };
        tabs.push(docRequestsTab);
      }

      tabs.push({
        key: RepoPageRouteNames.NEEDS_REVIEW,
        label: RouteNamesToLabels[RepoPageRouteNames.NEEDS_REVIEW],
        count: this.unapplicableSwms.length,
        notification: isRepoStatusOutdated || isRepoStatusAutosynced,
        notificationColor: getComputedStyle(document.body).getPropertyValue(needsReviewNotificationColor),
      });

      return tabs;
    },
    workspace() {
      return this.db_getWorkspace(this.workspaceId);
    },
    currentTabName() {
      return this.tabs.find((tab) => tab.key === this.currentRoute);
    },
    currentTabIndex() {
      if (this.selectedTabIndex) {
        return this.tabs.findIndex((tab) => tab.key === this.selectedTabIndex);
      }
      return Math.max(
        0,
        this.tabs.findIndex((tab) => tab.key === this.currentRoute)
      );
    },
    playlistsFromDB() {
      return this.db_getPlaylists(this.repo.metadata.id) ?? {};
    },
    playlists() {
      const tagFilters = this.filtersStore.tagFilters;
      if (tagFilters.length) {
        // Playlists don't contain tags, so we don't want to show playlists when filtering by tag
        return [];
      }

      return this.playlistsSorted;
    },
    playlistsSorted() {
      let playlistsValues = Object.values(this.currentRepoPlaylistsInBranch);

      // playlists drafts
      const drafts = this.repoPlaylistDrafts.map((draft) => ({
        ...draft,
        name: draft.name || 'Untitled Playlist',
      }));
      playlistsValues = [...playlistsValues, ...drafts];

      return getPlaylistsSorted({
        playlistsValues,
        sortByProperty: this.sortByPropName,
      });
    },
    isDocumentationTab() {
      return this.currentTabIndex === 0;
    },
    committedDocs() {
      return this.currentRepoDocsInBranch;
    },
    docs() {
      const docs = this.getDocs(OrderedUnitsFilters.ALL);

      const tagFilters = this.filtersStore.tagFilters;
      if (!tagFilters.length) {
        return docs;
      }

      const tagFiltersIds = tagFilters.map((tagFilter) => tagFilter.id);
      return docs.filter((doc) => doc.tags?.some((tag) => tagFiltersIds.includes(tag)));
    },
    repoDocumentation() {
      const allDocumentation = [
        ...this.playlists.map((item) => ({ ...item, documentationType: DocumentationTypes.PLAYLIST })),
        ...this.docs.map((item) => ({ ...item, documentationType: DocumentationTypes.DOC })),
      ];
      return this.filterAndSortItems(allDocumentation);
    },
    folderDocumentation() {
      return this.filterAndSortItems(this.currentFolderItems);
    },
    draftsDocs() {
      return this.getDocs(OrderedUnitsFilters.DRAFTS);
    },
    allUnits() {
      return this.db_getUnitsOrderedByStatus(this.repoId, this.user.uid);
    },
    isNewRepo() {
      return this.repoId === 'new';
    },
    canModifyResources() {
      return !this.db_isPublicRepo(this.repoId) || this.db_isRepoLifeguard(this.repoId, this.user.uid);
    },
    repo() {
      // Repo from the FS might be undefined when during initialize
      let repo = this.fs_getRepository(this.repoId) || {};
      if (!this.isNewRepo) {
        // Override metadata from DB repo metadata
        const repoMetadata = this.db_getRepoMetadata(this.repoId);
        repo = { ...repo, metadata: { ...repo.metadata, ...repoMetadata } };
      }
      return repo;
    },
    repoIdFromStore() {
      return this.fs_getRepository(this.repoId)?.metadata?.id;
    },
    ribbonContents() {
      const ribbons = [];

      if (!this.defaultBranchInitializationData.isDefaultBranchInitialized) {
        let pullRequestCTA = {};
        if (this.repo && this.repo.metadata) {
          pullRequestCTA = {
            callToAction: `Compare & ${this.changeRequestName}`,
            action: () => window.open(this.defaultBranchInitializationData.createPRLink, '_blank'),
          };
        }
        ribbons.push({
          shouldShow: true,
          title: `${this.$route.params.branch} is not merged`,
          description: `Open a ${this.changeRequestName.toLowerCase()} to share your documentation with your team.`,
          mode: 'web',
          emoji: '💡',
          ...pullRequestCTA,
        });
      }

      if ([ApplicabilityStatus.Autosyncable, ApplicabilityStatus.Outdated].includes(this.unitsRepoStatus)) {
        const CTA = this.canModifyResources
          ? { callToAction: 'Go to review', action: () => this.routeToPage(RepoPageRouteNames.NEEDS_REVIEW) }
          : {
              description:
                'This repo was updated. Ask the workspace Admin to review and edit docs to keep everything up to date.',
            };
        if (!this.isOnDummyRepoPage) {
          ribbons.push({
            shouldShow: true,
            title: 'Review required',
            description: 'Your code has changed and some docs require your attention.',
            mode: 'error',
            icon: 'warning',
            ...CTA,
          });
        }
      } else if (!this.finishedLoadingSwimms) {
        ribbons.push({
          shouldShow: true,
          title: 'Checking your documentation',
          description: 'Swimm is verifying that your documentation is up to date.',
          mode: 'close',
          icon: 'sync',
          spinningIcon: true,
        });
      }

      if (!this.isGitIgnoreValid) {
        ribbons.push({
          shouldShow: true,
          callToAction: 'Edit .gitignore',
          title: 'Please change your .gitignore file',
          action: () => window.open(this.gitIgnoreURL, '_blank'),
          description: 'Please change your .gitignore  file to not ignore `.swm/` or `*.sw.md` files.',
          mode: 'error',
          icon: 'warning',
        });
      }
      return ribbons;
    },
    unitsRepoStatus() {
      const allCommittedDocs = Object.values(this.committedDocs);

      let status = '';
      for (const doc of allCommittedDocs) {
        if (doc.applicabilityStatus === ApplicabilityStatus.Outdated) {
          return ApplicabilityStatus.Outdated;
        } else if (doc.applicabilityStatus === ApplicabilityStatus.Autosyncable) {
          status = ApplicabilityStatus.Autosyncable;
        } else if (
          doc.applicabilityStatus === ApplicabilityStatus.Unavailable &&
          status !== ApplicabilityStatus.Autosyncable
        ) {
          status = ApplicabilityStatus.Unavailable;
        }
      }
      return status === '' ? ApplicabilityStatus.Verified : status;
    },
    pendingPRsCount() {
      return new Map([...this.pendingDocs, ...this.pendingPlaylists].map((pr) => [pr['branch'], pr])).size;
    },
    pendingPRs() {
      return this.fs_getRepoPendingDocumentations(this.repoId) || {};
    },
    pendingDocs() {
      const pendingDocs = [];
      const allDocs = this.allUnits.filter((unit) => isSwmDoc({ swm: unit }) && !unit.is_example);

      allDocs.forEach((doc) => {
        Object.entries(this.pendingPRs)
          .filter(
            ([unitId, unitPRs]) =>
              (doc.id === unitId || swmdFileName(doc) === unitId || doc.id === getSwimmIdFromSwimmFileName(unitId)) &&
              Object.keys(unitPRs).length
          )
          .map(([, unitPRs]) => unitPRs)
          .forEach((unitPRs) => {
            Object.values(unitPRs).forEach((docInPR) => {
              pendingDocs.push({
                ...doc,
                type: DocumentationTypes.DOC,
                branch: docInPR.branch,
                pending: true,
                prId: docInPR.pr,
                prTitle: docInPR.prTitle,
                prLastModified: docInPR.modified,
                isPendingDeletion: docInPR.isPendingDeletion,
                isPendingAddition: docInPR.isAdded,
              });
            });
          });
      });

      return pendingDocs;
    },
    pendingPlaylists() {
      const pendingPlaylists = [];

      for (const playlist of Object.values(this.playlistsFromDB)) {
        Object.entries(this.pendingPRs)
          .filter(
            ([unitId, unitPRs]) =>
              (playlist.id === unitId || swmdFileName(playlist) === unitId) && Object.keys(unitPRs).length
          )
          .map(([, unitPRs]) => unitPRs)
          .forEach((unitPRs) => {
            Object.values(unitPRs).forEach((unitPR) => {
              pendingPlaylists.push({
                ...playlist,
                type: DocumentationTypes.PLAYLIST,
                branch: unitPR.branch,
                prId: unitPR.pr,
                prTitle: unitPR.prTitle,
                pending: true,
                prLastModified: unitPR.modified,
                isPendingDeletion: unitPR.isPendingDeletion,
                isPendingAddition: unitPR.isAdded,
              });
            });
          });
      }

      return pendingPlaylists;
    },
    unapplicableSwms() {
      return this.currentRepoNeedsReviewDocs.map((doc) => {
        const draftVersion = this.drafts3Store.drafts?.get(doc.id);
        const isDocFixedInDraft = draftVersion && this.drafts3Validation.isVerified(draftVersion);
        const isPending = Boolean(this.pendingDocs.find((pendingDoc) => pendingDoc.id === doc.id));
        return { ...doc, isDocFixedInDraft, isPending };
      });
    },
    docRequests() {
      const repoId = this.repoId;
      const docRequests = this.db_getDocRequests(repoId);
      return docRequests ? getValidDocRequestsSorted(docRequests, { sortBy: this.sortBy }) : [];
    },
    sortByPropName() {
      return this.sortBy ? REPO_FILTERS_SORT_OPTIONS_PROPS[this.sortBy] : '';
    },
    shouldShowSearch() {
      return !this.loading && this.isDocumentationView;
    },
    isDocumentationView() {
      return this.currentRoute === RepoPageRouteNames.DOCUMENTATIONS;
    },
    isPullRequestsView() {
      return this.currentRoute === RepoPageRouteNames.PULL_REQUEST;
    },
    isWorkspaceAdmin() {
      return this.db_isWorkspaceAdmin(this.workspaceId, this.user.uid);
    },
    shouldOpenImportModal() {
      return this.modalToOpenOnMount === 'import';
    },
    shouldOpenRepoSettingsModalOnMount() {
      return this.modalToOpenOnMount === settingsTypes.INTEGRATION;
    },
    shouldOpenUserSettingsModalOnMount() {
      return this.modalToOpenOnMount === settingsTypes.USER;
    },
    shouldOpenWorkspaceSettingsModalOnMount() {
      return this.modalToOpenOnMount === settingsTypes.WORKSPACE;
    },
    repoUnitDrafts() {
      const docDraftsInBranchItemsForRepo = this.docDraftsInBranchItemsForRepo;
      return Object.values(docDraftsInBranchItemsForRepo());
    },
    repoPlaylistDrafts() {
      const playlistDraftsInBranchItemsForRepo = this.playlistDraftsInBranchItemsForRepo;
      return Object.values(playlistDraftsInBranchItemsForRepo());
    },
    changedFilesDiff() {
      return this.fs_getChangedFilesForBranch(this.repoId);
    },
    isAdmin() {
      return this.db_isWorkspaceAdmin(this.workspaceId, this.user.uid);
    },
    loadingDocumentations() {
      return this.loading || !this.fs_getRepoLocalFilesLists(this.repoId) || !this.repoDocsStore.docsLoaded;
    },
  },
  watch: {
    loadingDocumentations: {
      handler(value) {
        if (!value) {
          this.addDocsToDBIfNeeded({ repoId: this.repoId });
          // we check that loaded are not 0 - otherwise it would report on start and not in the right place
          const swmsInSwmFolder = this.fs_getSwmsIdsFromRepoFolder(this.repoId);
          const loadedSwms = this.fs_getRepoLocalFilesLists(this.repoId);
          if (swmsInSwmFolder.length > 0 && Object.values(loadedSwms).length > 0) {
            this.drafts3Validation.verifyDrafts();
          }
        }
      },
      immediate: true,
    },
  },
  async created() {
    try {
      this.currentBranch = await this.getCurrentOrDefaultBranch(this.repoId);
      await this.fetchWorkspace({ workspaceId: this.workspaceId });
      await this.initRepoData();
      this.loading = false;
      gitwrapper.getChangeRequestName({ repoId: this.repoId }).then((result) => (this.changeRequestName = result));
      this.assertGitIgnoreValid();
      this.assertSwimmInitializedOnDefaultBranch();
      this.reportTabSelection(this.currentRoute);
      this.showModalOnMount();
      await this.setCommitModalBranchData(this.branch, this.$route.params.repoId);
      this.selectedPrNum = parseInt(this.$route.query.prNumber) ?? null;
    } catch (err) {
      // Ignore error is the component destroyed
      if (!this.destroyed) {
        throw err;
      }
    }
    // loadPendingDocumenations should be called only after
    // there is repo in the store (and there is branch)
    // otherwise, it is aborted and does nothing
    this.$watch(
      () => [this.repoIdFromStore, this.currentBranch],
      () => {
        if (this.repoIdFromStore && this.currentBranch) {
          this.loadPendingDocumentations();
        }
      },
      {
        immediate: true,
      }
    );
    this.$watch(
      () => ({ repoId: this.repoId, branch: this.branch }),
      async ({ repoId: newRepoId, branch: newBranch }, { repoId: oldRepoId, branch: oldBranch }) => {
        this.loading = true;
        this.loadingPendingDocuments = true;
        try {
          if (newRepoId && (newRepoId !== oldRepoId || newBranch !== oldBranch)) {
            this.currentBranch = await this.getCurrentOrDefaultBranch(newRepoId);
            if (newBranch !== oldBranch) {
              await this.assertSwimmInitializedOnDefaultBranch();
            }
            await this.resetData();
          }

          if (oldRepoId && newRepoId !== oldRepoId) {
            // Clean source repo data - we will refetch the data anyway when going back to it.
            // Cleaning because when moving back to the repo we see old data until
            // this watch is called and cleans the old stored data in the condition above.
            this.cleanLoadedRepoData(oldRepoId);
          }

          if (newBranch !== oldBranch) {
            await this.setCommitModalBranchData(newBranch, newRepoId);
          }
        } catch (err) {
          // Ignore the error if the component is destroyed
          if (!this.destroyed) {
            logger.error({ err }, `Failed to load workspace data, workspaceId: ${this.workspaceId}, error: ${error}`);
            await swal({ title: `Failed to load workspace`, content: SWAL_CONTACT_US_CONTENT() });
          }
        } finally {
          this.loading = false;
        }
      }
    );
    this.$watch('currentRoute', () => {
      if (this.currentRoute !== this.selectedTabIndex) {
        this.selectedTabIndex = this.currentRoute;
      }
    });
    this.$watch('$route', async (to, from) => {
      if (to.query.prNumber && to.query.prNumber !== from.query.prNumber) {
        await this.loadPendingDocumentations();
        this.selectedPrNum = parseInt(to.query.prNumber);
      }
    });
  },
  async mounted() {
    this.reportPageViewed();
    await this.drafts3Store.verifyDrafts;
  },
  beforeUnmount() {
    this.destroyed = true;
  },
  methods: {
    ...mapActions('filesystem', [
      'getRepoSwmsLists',
      'handleLoadResponse',
      'setRepoPendingDocumentations',
      'loadLocalSwmFiles',
      'cleanLoadedRepoData',
      'loadChangedFilesForBranch',
    ]),
    ...mapActions('database', ['fetchWorkspace', 'addDocsToDBIfNeeded', 'fetchRepoChildren']),
    reportPageViewed() {
      const payload = {
        'Page Name': 'Repo Page',
      };
      this.analytics.cloudPageViewed({
        identity: this.user.uid,
        event: pageEvents.LOADED_A_PAGE,
        payload,
      });
      this.analytics.track(pageEvents.LOADED_A_PAGE_MARKETING, payload);
    },
    reportNeedsReview() {
      this.analytics.cloudTrack({
        identity: this.user.uid,
        event: productEvents.REPO_NEEDS_REVIEW,
        payload: {
          'Total Docs to Review': this.unapplicableSwms.length,
          'Repo ID': this.$route.params.repoId,
          'Workspace ID': this.$route.params.workspaceId,
          'Total Docs in branch': Object.keys(this.committedDocs).length,
          backofficeCode: this.isOnDefaultBranch ? eventLogger.SWIMM_EVENTS.VERIFIED_ALL_DOCS.code : null,
        },
      });
    },
    async setCommitModalBranchData(branch, repoId) {
      const repoDataFromState = this.reposStateData[repoId];
      if (repoDataFromState && !!this.branch) {
        const isProtectedBranch = await this.isBranchProtected({ repoId: repoId, branch: this.branch });
        await this.setRepoStateBranchData(repoId, { branch, isProtectedBranch });
      }
    },
    // Check if the default branch is not initialized for Swimm while the sidebranch (where the user is at) is initialized
    async assertSwimmInitializedOnDefaultBranch() {
      if (this.isNewRepo) {
        this.defaultBranchInitializationData = { isDefaultBranchInitialized: true };
        return;
      }
      try {
        const webRepoData = this.reposStateData[this.repoId];
        if (!!webRepoData.defaultBranch && this.currentBranch !== webRepoData.defaultBranch) {
          this.isOnDefaultBranch = false;
          const isInitializedOnDefaultBranch = await isRepoInitialized({
            repoId: this.repoId,
            revision: webRepoData.defaultBranch,
          });

          const repoData = await gitProviderUtils.getRepoStateData(this.repoId);
          const repoPrs = await gitwrapper.getPrs({
            repoId: this.repoId,
            prState: gitwrapper.getTerminology(repoData.provider).prState.open,
          });

          const isPrOpenedForBranch = repoPrs.some((pr) => pr.sourceBranchName === this.currentBranch);

          if (!isInitializedOnDefaultBranch && !isPrOpenedForBranch) {
            const urlToPR = await this.getRemoteRepoPullRequestURL(this.currentBranch);
            this.defaultBranchInitializationData = { createPRLink: urlToPR, isDefaultBranchInitialized: false };
            return;
          }
        } else {
          this.isOnDefaultBranch = true;
        }
      } catch (err) {
        logger.error({ err }, `Could not check for Swimm initialization on default branch. Details: ${err}`);
      }
      this.defaultBranchInitializationData = { isDefaultBranchInitialized: true };
    },
    async getRemoteRepoPullRequestURL(branch) {
      const remoteRepoResult = await getRemoteRepo({
        repoId: this.repoId,
        api_url: this.repo.metadata.api_url,
        tenant_id: this.repo.metadata.tenant_id,
      });
      const provider = this.repo.metadata.provider;
      const htmlUrl = remoteRepoResult.repo.htmlUrl;
      return UrlUtils.getBranchComparisonUrl(htmlUrl, provider, branch, remoteRepoResult.repo);
    },
    async assertGitIgnoreValid() {
      try {
        if (this.isOnDummyRepoPage) {
          return;
        }
        this.gitIgnoreURL = this.repo.metadata.url;
        const gitIgnoreContent = await gitwrapper.getFileContentFromRevision({
          repoId: this.repoId,
          revision: this.currentBranch,
          filePath: '.gitignore',
          safe: true,
        });

        this.isGitIgnoreValid = gitIgnoreContent ? isGitIgnoreValid(gitIgnoreContent) : true;

        if (!this.isGitIgnoreValid) {
          logger.warn(`.gitignore file is invalid for repoId:${this.repoId}`);
          this.gitIgnoreURL = await gitwrapper.getFileHtmlURL({
            repoId: this.repoId,
            revision: this.currentBranch,
            filePath: '.gitignore',
            provider: this.repo.metadata.provider,
          });
        }
      } catch (err) {
        logger.error({ err }, `Failed while checking .gitignore state for repoId:${this.repoId}`);
      }
    },
    async initRepoData() {
      const repoId = this.repoId;
      if (!repoId) {
        return;
      }
      const fetchSwimms = this.fetchRepoChildren({ repoId, children: ['swimms', 'playlists'] });
      // Only wait for this fetch if we don't already have the list of swimms, otherwise let it run in the background.
      if (!this.db_getSwimms() || !this.db_getPlaylists()) {
        await fetchSwimms;
      }
      await this.setDefaultBranch();
      await this.initRootFolder(repoId);
      // Subscribe a callback to the webworker load messages that will be queued in setLocalRepoData
      docLoaderWorker.addListener(this.handleLoadResponse);
      await this.loadChangedFilesDiff();
      this.finishedLoadingSwimms = true;
    },
    /**
     * Set default branch if state doesn't have it
     */
    async setDefaultBranch() {
      const repoMetaData = this.reposStateData[this.repoId];
      if (repoMetaData && !repoMetaData.defaultBranch) {
        this.loadingBranch = true;
        const repoData = parseRepoDataFields({
          repoName: repoMetaData.repoName,
          owner: repoMetaData.owner,
          provider: repoMetaData.provider,
          api_url: repoMetaData.api_url,
        });
        const defaultBranchResult = await getRepoDefaultBranch({ repoId: this.repoId, ...repoData });
        if (defaultBranchResult) {
          await this.setRepoStateBranchData(this.repoId, { defaultBranch: defaultBranchResult.branch });
        }
        this.loadingBranch = false;
      }
    },

    async loadPendingDocumentations() {
      this.loadingPendingDocuments = true;
      await this.setRepoPendingDocumentations({
        repoId: this.repoId,
        branch: this.currentBranch,
      });
      this.loadingPendingDocuments = false;
    },
    getDocs(filter = OrderedUnitsFilters.ALL) {
      // Filter out exercises and use draftId if draft has no name.
      const docDrafts = this.repoUnitDrafts.map((draft) => ({ ...draft, name: computeDraftDisplayName(draft.name) }));
      // We check for docs that has a draft version and link their name into a key inside the doc's object
      const allCommittedDocs = Object.values(this.committedDocs);
      const allDocs = [...allCommittedDocs, ...docDrafts];
      return this.orderAndFilterUnitEntities({ entities: allDocs, filter });
    },
    showCreationHub({ openedFromQuery = false } = {}) {
      this.openCreationHubModal({
        folderId: this.folderIdForCreation,
        keepAfterRouteChange: openedFromQuery,
        newDocClick: this.navigateToNewDocOrPlaylist,
        newPlaylistClick: ($event) => this.navigateToNewDocOrPlaylist({ ...$event, isPlaylist: true }),
      });
    },
    toggleCreationHub({ creationHubSection = null, folderId = null } = {}) {
      if (folderId) {
        this.folderIdForCreation = folderId;
      } else if (this.currentFolder && !this.currentFolder.is_root) {
        this.folderIdForCreation = this.currentFolder.id;
      }
      if (this.showCreationHubModal) {
        this.closeCreationHubModal();
      } else {
        this.showCreationHub();
        // Override current section by ref
        this.creationHubModalRef.onSectionChange(creationHubSection ? creationHubSection : 0);
      }
      const eventName = this.showCreationHub ? productEvents.CLICKED_NEW : productEvents.CLOSED_CREATION_HUB;
      this.analytics.track(eventName, {});
    },
    routeToPage(page) {
      this.navigateToPageAndTerminateWorker({ newRoute: `${this.getRepoPath()}/${page}` });
    },
    orderAndFilterUnitEntities({ entities, filter = OrderedUnitsFilters.ALL } = {}) {
      const filtererdEntities = this.filterDocumentation({ entities, filter });
      if (this.sortByPropName) {
        return this.sortDocumentation(filtererdEntities);
      }
      return filtererdEntities;
    },
    sortDocumentation(entities) {
      // Sorting entities by the users selected prop
      const order = this.isSortDesc ? 'desc' : 'asc';
      return sortByProp(entities, this.sortByPropName, order);
    },
    filterDocumentation({ entities, filter }) {
      return entities.filter((unit) => {
        const isDraft = unit.draftId;
        switch (filter) {
          case OrderedUnitsFilters.COMMITTED:
            return !isDraft;
          case OrderedUnitsFilters.DRAFTS:
            return isDraft;
          case OrderedUnitsFilters.ALL:
          default:
            return true;
        }
      });
    },
    async resetData() {
      this.finishedLoadingSwimms = false;
      this.isGitIgnoreValid = true;
      this.gitIgnoreURL = '';
      docLoaderWorker.removeListener(this.handleLoadResponse);
      await this.initRepoData();
      await this.getRepoSwmsLists({ repoId: this.repoId, branch: this.currentBranch });
      await this.assertGitIgnoreValid();
    },
    handleSortChanged(newSortOption) {
      let cancelSorting = false;
      if (this.sortBy === newSortOption) {
        // Cancel sorting when clicking on sort option for the third time.
        // For Last modified it will be when clicking after asc sort, for the others cliking on desc sort.
        if (
          (this.isSortDesc && this.sortBy !== REPO_FILTERS_SORT_OPTIONS.LAST_MODIFIED) ||
          (!this.isSortDesc && this.sortBy === REPO_FILTERS_SORT_OPTIONS.LAST_MODIFIED)
        ) {
          cancelSorting = true;
          this.sortBy = null;
        } else {
          this.isSortDesc = !this.isSortDesc;
        }
      } else {
        this.sortBy = newSortOption;
        this.isSortDesc = newSortOption === REPO_FILTERS_SORT_OPTIONS.LAST_MODIFIED;
      }

      this.analytics.track(productEvents.REPO_PAGE_SORT_CHANGE, {
        Origin: this.currentTabName.key,
        'Origin URL': this.$route.fullPath,
        Context: 'Documentation lifecycle',
        'Branch ID': this.currentBranch,
        'Is Main Branch': this.isOnDefaultBranch,
        'Sort Type': cancelSorting ? null : newSortOption,
      });
    },
    openPageLink(page) {
      // Do not route if on same page
      const tabPath = `${this.getRepoPath()}/${page}`;
      if (this.$route.path.endsWith(`/${page}`)) {
        return;
      }
      this.$router.push(tabPath);
    },
    openRepoPage() {
      const repoPath = this.getRepoPath();
      if (this.$route.path === repoPath) {
        return;
      }
      this.$router.push(repoPath);
    },
    handleTabSelection(tabSelected) {
      if (tabSelected) {
        this.selectedTabIndex = tabSelected;
        this.reportTabSelection(tabSelected);
        if (tabSelected === RepoPageRouteNames.DOCUMENTATIONS) {
          this.openRepoPage();
        } else if (Object.values(RepoPageRouteNames).includes(tabSelected)) {
          this.openPageLink(tabSelected.toLowerCase());
        }
      }
    },
    reportTabSelection(selectedTabKey) {
      const data = {
        [RepoPageRouteNames.NEEDS_REVIEW]: {
          'Total Docs to Review': this.unapplicableSwms.length,
        },
        [RepoPageRouteNames.DOC_REQUESTS]: {
          'Total Doc Requests': this.docRequests.length,
        },
      };
      let dataToReport = {};
      if (selectedTabKey === RepoPageRouteNames.DOCUMENTATIONS) {
        dataToReport = Object.assign({}, ...Object.values(data), {
          'Multiple Versions Exist': this.repoDocumentation.some(
            (documentation) => documentation.variations?.length > 1
          ),
          'Number of Docs': this.repoDocumentation.length,
          'Number Of Playlists': this.playlists.length,
          'Number Of Drafts': this.draftsDocs.length,
          'Number Of Pull Requests': this.pendingPRsCount,
        });
      } else if (data[selectedTabKey]) {
        dataToReport = data[selectedTabKey];
      }
      this.analytics.track(pageEvents.VIEW_REPO_TAB(RouteNamesToLabels[selectedTabKey]), {
        'Workspace ID': this.workspaceId,
        'Repo ID': this.repoId,
        Branch: this.currentBranch,
        Type: 'Page',
        ...dataToReport,
      });
    },
    showModalOnMount() {
      if (this.shouldOpenImportModal) {
        this.showCreationHub({ openedFromQuery: true });
        this.removeModalQueryFromRoute();
      }
      if (this.shouldOpenRepoSettingsModalOnMount) {
        this.showRepoSettingsModal = true;
        this.removeModalQueryFromRoute();
      }
      if (this.shouldOpenUserSettingsModalOnMount) {
        this.showUserSettingsModal = true;
        this.removeModalQueryFromRoute();
      }
      if (this.shouldOpenWorkspaceSettingsModalOnMount) {
        this.openWorkspaceSettingsModal({ initialTabCode: this.$route.query.tab, openedFromQuery: true });
        this.removeModalQueryFromRoute();
      }
    },
    removeModalQueryFromRoute() {
      // To remove the query `modal` when we get here from the github app
      const query = { ...this.$route.query, modal: undefined, source: undefined, tab: undefined };
      this.$router.replace({ query });
    },
    showNewDocRequestModal(origin) {
      this.analytics.track(productEvents.CLICKED_REQUEST_DOC, {
        Origin: origin,
        Branch: this.$route.params.branch,
      });
      this.newDocRequestOrigin = origin;
      this.editedDocRequest = null;
      this.shouldShowDocRequestModal = true;
    },
    docRequestModalClosed(docRequestId) {
      this.shouldShowDocRequestModal = false;
      if (docRequestId) {
        this.handleTabSelection(RepoPageRouteNames.DOC_REQUESTS);
      }
    },
    async handleDocRequestAction(docRequest, action) {
      if (action === 'edit') {
        this.shouldShowDocRequestModal = true;
        this.editedDocRequest = docRequest;
        this.newDocRequestOrigin = '';
      }
    },
    async loadChangedFilesDiff() {
      const repoDataFromState = this.reposStateData[this.repoId];

      await this.loadChangedFilesForBranch({
        repoId: this.repoId,
        defaultBranch: repoDataFromState.defaultBranch,
        branch: this.currentBranch,
      });
    },
    addFolder() {
      // Move to documentation tab if needed
      if (!this.isDocumentationView) {
        this.openRepoPage();
      }
      this.isAddingNewFolderState = true;
      this.analytics.track(
        productEvents.CLICKED_NEW_FOLDER,
        {
          'Parent Folder ID': this.currentFolder?.id,
          'Parent Folder Path': this.getFolderPath(this.currentFolder?.id, this.repoId),
        },
        { addRouteParams: true }
      );
    },
    filterItems(items) {
      let filteredItems = items;
      // Filter by tags
      const tagFilters = this.filtersStore.tagFilters;
      if (tagFilters.length) {
        const tagFiltersIds = tagFilters.map((tagFilter) => tagFilter.id);
        filteredItems = items.filter(
          (item) =>
            item.documentationType === DocumentationTypes.FOLDER ||
            item.tags?.some((tag) => tagFiltersIds.includes(tag))
        );
      }

      // Filter by type
      if (this.filtersStore.typeFilter.length) {
        filteredItems = filteredItems.filter((item) => {
          if (item.documentationType === DocumentationTypes.FOLDER) {
            return true;
          }
          return this.filtersStore.typeFilter.map((type) => type.key).includes(item.documentationType);
        });
      }
      return filteredItems;
    },
    sortItems(items) {
      const order = this.isSortDesc ? 'desc' : 'asc';
      const docs = items.filter((item) => item.documentationType === DocumentationTypes.DOC);

      if (this.sortBy === REPO_FILTERS_SORT_OPTIONS.STATUS) {
        Object.keys(docs).map((index) => {
          docs[index].applicability = docs[index].draftId
            ? docs[index].applicability
            : this.committedDocs[docs[index].id].applicabilityStatus; // take the value from the computed with applicability status
        });
      }

      if (this.sortByPropName) {
        return [...sortByProp(items, this.sortByPropName, order)];
      }
      return items;
    },
    filterAndSortItems(items) {
      let filteredItems = this.filterItems(items);

      const idToVariations = groupBy(
        filteredItems.filter((currentDoc) => currentDoc.id),
        'id'
      );

      // Filter out commited docs of drafts - add them as variants on the draft (below)
      filteredItems = filteredItems.filter((item) => !!item.draftId || idToVariations[item.id].length === 1);
      filteredItems = filteredItems.map((item) => {
        if (!item.id) {
          // New drafts.
          return item;
        }
        return {
          ...item,
          variations: idToVariations[item.id],
        };
      });
      return this.sortItems(filteredItems);
    },
  },
};
</script>

<style scoped lang="postcss">
.new-doc-menu {
  position: absolute;
  right: 0px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  border: 1px solid var(--color-border-default);
  border-radius: 4px;
  background-color: var(--color-bg);
  box-shadow: var(--box-shadow-small);
  cursor: pointer;
}

.add-ellipsis {
  position: relative;
  top: -10px;
}

.repo-title-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;

  .headline1 {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.repo-name {
  margin-right: 5px;
}

.repo-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 40px 0;

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    gap: var(--space-sm);
    padding-bottom: var(--space-base);

    > h5,
    button {
      white-space: nowrap;
    }
  }
}

.learn-link {
  margin-left: 32px;
  font-weight: bold;
  color: var(--text-color-link);
  cursor: pointer;
}

.banner-content {
  text-align: center;
  white-space: pre-line;
  color: var(--text-color-secondary);
  margin-bottom: 16px;
}

.banner-content > .link {
  text-decoration: underline;
  text-decoration-color: var(--text-color-secondary);
  color: var(--text-color-secondary);
}

.settings-cog {
  font-weight: normal;

  &:hover {
    color: var(--text-color-secondary);
  }
}

.tip-banner {
  margin-bottom: 16px;

  .tip-banner-btn {
    margin-left: 8px;
  }

  .custom-description {
    display: flex;
    align-items: center;

    &__title {
      margin-right: 8px;
    }

    &__body {
      flex: 1;
    }
  }
}

.shield-icon {
  font-size: var(--headline2);
}
</style>
