import dedent from 'dedent';
import {
  type Repo,
  SWMD_VERSION,
  type SwmCellText,
  SwmCellType,
  type SwmFile,
  SwmSymbolLinkType,
  SwmSymbolType,
  config,
  extractMeatFromSnippet,
  generateSwmdFileName,
  parseSWMD,
} from '@swimm/shared';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { swmdSerializer, withEnv as withSwmdSerializerEnv } from './serializer';
import { schema } from '..';
import type { JSONContent } from '@tiptap/core';
import { serializeSwmMeta } from './swm_meta';
import matter from 'gray-matter';
import { markdownTable } from 'markdown-table';
import { getLanguageForFile } from './langmap';
import { escape } from 'lodash-es';
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt();

export class LegacySwmdError extends Error {}

export interface LegacyOptions {
  baseUrl: string;
  workspaceId: string;
  repoId: string;
  repoName: string;
  repos: Repo[];
}

export function convertLegacySwmFileToSwmdV3(text: string, options: LegacyOptions): string {
  const swmFile = parseSWMD(text, options.repoId);
  const output: string[] = [];

  for (const cell of swmFile.content) {
    switch (cell.type) {
      case SwmCellType.Text:
        output.push(...convertTextCell(swmFile, options, cell), '\n\n');
        break;
      case SwmCellType.Snippet:
        output.push(
          serializeSwmdBlockNode(
            {
              type: 'swmSnippet',
              attrs: {
                snippet: extractMeatFromSnippet(cell).lines.join('\n'),
                path: `/${cell.path}`,
                line: cell.firstLineNumber,
                collapsed: cell.collapsed,
                language: getLanguageForFile(cell.path),
                repoId: cell.repoId,
                repoName: options.repos.find((repo) => repo.id === cell.repoId)?.name,
              },
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      type: 'text',
                      text: 'COMMENT-PLACEHOLDER',
                    },
                  ],
                },
              ],
            },
            options
          ).replace('COMMENT-PLACEHOLDER', () =>
            convertTextCell(swmFile, options, {
              type: SwmCellType.Text,
              text: cell.comments?.join('\n')?.replace(/^\n<!-- empty line --><br\/>$/gm, '\n&nbsp;') ?? '',
            }).join('')
          ),
          '\n\n'
        );
        break;
      case SwmCellType.SnippetPlaceholder:
        output.push(
          serializeSwmdBlockNode(
            {
              type: 'swmSnippetPlaceholder',
              attrs: {
                placeholder: cell.comment,
              },
            },
            options
          ),
          '\n\n'
        );
        break;
      case SwmCellType.Image:
        output.push(
          dedent`
            <p align="center"><img src="${escape(cell.src)}" style="width: ${cell.width.toString()}%"></p>\n\n
          `
        );
        break;
      case SwmCellType.Table: {
        const table: string[][] = [];

        const headerRow: string[] = [];
        for (const header of cell.headers ?? []) {
          headerRow.push(
            convertTextCell(swmFile, options, { type: SwmCellType.Text, text: header })
              .map((line) => line.replaceAll('\n', '<br/>'))
              .join('')
          );
        }
        table.push(headerRow);

        for (const tableRow of cell.table) {
          const row: string[] = [];
          for (const tableCell of tableRow) {
            row.push(
              convertTextCell(swmFile, options, { type: SwmCellType.Text, text: tableCell })
                .map((line) => line.replaceAll('\n', '<br/>'))
                .join('')
            );
          }
          table.push(row);
        }

        output.push(markdownTable(table), '\n\n');
        break;
      }
      case SwmCellType.Video:
        output.push(
          serializeSwmdBlockNode(
            {
              type: 'youtube',
              attrs: {
                src: cell.src,
              },
            },
            options
          ),
          '\n\n'
        );
        break;
      case SwmCellType.Mermaid:
        {
          const swimmMermaid = convertTextCell(swmFile, options, {
            type: SwmCellType.Text,
            text: md.utils.unescapeMd(cell.content.replaceAll('\\-\\-\\>', '-->').replaceAll(/<br\/>/gm, '')),
          })
            .join('')
            .split('\n')
            .map((line) => '%% ' + line)
            .join('\n');
          output.push('```mermaid\n', md.utils.unescapeMd(cell.src), '\n%% Swimm:\n', swimmMermaid, '\n```\n\n');
        }
        break;
    }
  }

  output.push(
    '\n\n',
    serializeSwmMeta(
      { version: SWMD_VERSION, 'repo-id': options.repoId, 'repo-name': options.repoName },
      options.baseUrl
    )
  );

  return matter.stringify(output.join(''), { ...(swmFile.name ? { title: swmFile.name } : {}) }, { language: 'yaml' });
}

const TEXT_CELL_RE =
  /\[\[sym:(?<pathSym>.*?)\]\]|\[\[sym-text:(?<textSym>.*?\(.*?\))\]\]|\[\[sym-mention:\((?<mentionSymId>\S+?)\|(?<mentionUserId>\S+?)\)(?<mentionName>.+?)\]\]|\[\[sym-link:(?<linkSym>.*?)\]\]|(?<text>[^[]+)|(?<text2>[[])/g;

function serializeSwmdInlineNode(node: JSONContent, options: LegacyOptions): string {
  return withSwmdSerializerEnv(
    {
      baseUrl: options.baseUrl,
      workspaceId: options.workspaceId,
      repoId: options.repoId,
    },
    () => {
      return swmdSerializer.serialize(
        ProseMirrorNode.fromJSON(schema, {
          type: 'doc',
          content: [
            {
              type: 'paragraph',
              content: [node],
            },
          ],
        })
      );
    }
  );
}

function serializeSwmdBlockNode(node: JSONContent, options: LegacyOptions): string {
  return withSwmdSerializerEnv(
    {
      baseUrl: options.baseUrl,
      workspaceId: options.workspaceId,
      repoId: options.repoId,
    },
    () => {
      return swmdSerializer.serialize(
        ProseMirrorNode.fromJSON(schema, {
          type: 'doc',
          content: [node],
        })
      );
    }
  );
}

function convertTextCell(swmFile: SwmFile, options: LegacyOptions, cell: SwmCellText): string[] {
  const output: string[] = [];

  const text = cell.text.replace(/^<br\/>/, '&nbsp;');
  for (const match of text.matchAll(TEXT_CELL_RE)) {
    if (match.groups?.pathSym != null) {
      const symbol = match.groups?.pathSym;
      const symbolParts = symbol.split('(');
      // const originalvalue = symbolParts[0];
      const symbolId = symbolParts[1].replace(/\)$/, '');

      const symbolData = swmFile?.symbols?.[symbolId];
      if (symbolData == null || symbolData.type !== SwmSymbolType.PATH) {
        continue;
      }

      output.push(
        serializeSwmdInlineNode(
          {
            type: 'swmPath',
            attrs: {
              href: `/${symbolData.path}`,
              repoId: symbolData.repoId,
              repoName: options.repos.find((repo) => repo.id === symbolData.repoId)?.name,
            },
          },
          options
        )
      );
    } else if (match.groups?.textSym) {
      const symbol = match.groups?.textSym;
      const pattern = /\(([^()]+)\)$/;
      const symbolParts = symbol.match(pattern);
      const symbolId = symbolParts?.[1];
      const symbolData = symbolId ? swmFile?.symbols?.[symbolId] : null;
      if (symbolData == null || symbolData.type !== SwmSymbolType.GENERIC_TEXT) {
        continue;
      }

      output.push(
        serializeSwmdInlineNode(
          {
            type: 'swmToken',
            attrs: {
              token: symbolData.text,
              path: `/${symbolData.path}`,
              pos: {
                line: symbolData.lineNumber,
                wordStart: symbolData.wordIndex.start,
                wordEnd: symbolData.wordIndex.end,
              },
              lineData: symbolData.lineData,
              repoId: symbolData.repoId,
              repoName: options.repos.find((repo) => repo.id === symbolData.repoId)?.name,
            },
          },
          options
        )
      );
    } else if (match.groups?.mentionSymId) {
      const userId = match.groups?.mentionUserId;
      const name = match.groups?.mentionName;

      output.push(
        serializeSwmdInlineNode(
          {
            type: 'swmMention',
            attrs: {
              uid: userId,
              name,
              email: '#',
            },
          },
          options
        )
      );
    } else if (match.groups?.linkSym) {
      const symbol = match.groups?.linkSym;
      const symbolParts = symbol.split('(');
      // const originalvalue = symbolParts[0];
      const symbolId = symbolParts[1].replace(/\).*$/, '');

      const symbolData = swmFile?.symbols?.[symbolId];
      if (symbolData == null || symbolData.type !== SwmSymbolType.LINK) {
        continue;
      }

      output.push(
        serializeSwmdInlineNode(
          {
            type: 'swmLink',
            attrs: {
              path: `/${config.SWM_FOLDER_IN_REPO}/${generateSwmdFileName(symbolData.swimmId, symbolData.text)}${
                symbolData.swimmType === SwmSymbolLinkType.Playlist
                  ? config.SWMD_PLAYLIST_EXTENSION
                  : config.SWMD_FILE_EXTENSION
              }`,
              docTitle: symbolData.text,
              repoId: symbolData.repoId,
              repoName: options.repos.find((repo) => repo.id === symbolData.repoId)?.name,
            },
          },
          options
        )
      );
    } else if (match.groups?.text != null) {
      output.push(match.groups.text);
    } else if (match.groups?.text2 != null) {
      output.push(match.groups.text2);
    }
  }

  return output;
}
