import type { PendingPR, PrData } from '@swimm/shared';
import {
  config,
  getLoggerNew,
  gitProviderUtils,
  gitwrapper,
  isFileSupportedFilter,
  promiseWithTimeout,
} from '@swimm/shared';

const logger = getLoggerNew(__modulename);

async function parsePendingPR(id: string, pendingDocumentPR: PendingPR) {
  // If we don't know if the file is deleted used the optionallyRemoved
  return {
    id,
    ...pendingDocumentPR,
    exists:
      pendingDocumentPR.isDeleted !== undefined ? !pendingDocumentPR.isDeleted : !pendingDocumentPR.optionallyRemoved,
  };
}

export async function getPendingDocumentationsForBranch(repoId: string, branchName: string) {
  try {
    const pendingPRsGroupedByDocument = await gitwrapper.getPendingDocsForBranch({
      repoId,
      branchName,
    });

    // each pending document has inner PRs which resolves concurrently
    // all the pendings promises are also need to resolve concurrently
    const pendingPRsPromises = Object.entries(pendingPRsGroupedByDocument).map(async ([id, prs]) => {
      return await Promise.all(prs.map((pr) => parsePendingPR(id, pr)));
    });
    const pendingPRs = await Promise.all(pendingPRsPromises);

    const pendingDocumentations = pendingPRs
      .flat()
      .map((pendingPR) => ({
        ...pendingPR,
        isPendingDeletion: !pendingPR.exists,
      }))
      .reduce((allPendings, currentPending) => {
        const updatedPending = [...(allPendings[currentPending.id] || []), currentPending];
        return { ...allPendings, [currentPending.id]: updatedPending };
      }, {});

    return { code: config.SUCCESS_RETURN_CODE, pendingDocumentations };
  } catch (err) {
    logger.error({ err }, `Could not get pending PR data. Details: ${err.message}`);
    return { code: config.ERROR_RETURN_CODE };
  }
}

export async function getMergedPrs(repoId: string, timeout?: number): Promise<{ code: number; prs?: PrData[] }> {
  try {
    const repoData = await gitProviderUtils.getRepoStateData(repoId);
    const getPrsPromise = gitwrapper.getPrs({
      repoId,
      prState: gitwrapper.getTerminology(repoData.provider).prState.merged,
    });
    const prs = timeout ? ((await promiseWithTimeout(getPrsPromise, timeout)) as PrData[]) : await getPrsPromise;

    return { code: config.SUCCESS_RETURN_CODE, prs };
  } catch (err) {
    logger.error({ err }, `Could not get PRs data. Details: ${err.message}`);
    return { code: config.ERROR_RETURN_CODE };
  }
}

export async function getOpenPrs(repoId: string, timeout?: number): Promise<{ code: number; prs?: PrData[] }> {
  try {
    const repoData = await gitProviderUtils.getRepoStateData(repoId);
    const getPrsPromise = gitwrapper.getPrs({
      repoId,
      prState: gitwrapper.getTerminology(repoData.provider).prState.open,
    });
    const prs = timeout ? ((await promiseWithTimeout(getPrsPromise, timeout)) as PrData[]) : await getPrsPromise;

    return { code: config.SUCCESS_RETURN_CODE, prs };
  } catch (err) {
    logger.error({ err }, `Could not get PRs data. Details: ${err.message}`);
    return { code: config.ERROR_RETURN_CODE };
  }
}

export async function isPrOpen(prState, repoId) {
  const repoData = await gitProviderUtils.getRepoStateData(repoId);
  return (await gitwrapper.getTerminology(repoData.provider).prState.open) === prState;
}

/**
 * Checks if a pull request is OK to be transformed for creating a document
 * @param pr - the content of the PR
 * @param prNum - the relevant PR number
 */
export function isPREligibleForTransformation(pr: PrData, prNum: string) {
  const supportedFiles = pr.files.filter((changedFile) => isFileSupportedFilter(changedFile.path));
  if (!supportedFiles || supportedFiles.length === 0) {
    logger.info(
      `Cannot create valid Swm from PR number ${prNum} since it only has unsupported files. (from isFileSupportedFilter)`
    );
    return {
      code: config.ERROR_RETURN_CODE,
      errorMessage: `The selected PR (#${prNum}) contains only changes to non-code files. Please select another PR.`,
    };
  }
  if (!pr.filesWithAdditions) {
    logger.error(`The selected PR (#${prNum}) contains deletions only. Please select another PR.`);
    return {
      code: config.ERROR_RETURN_CODE,
      errorMessage: `The selected PR (#${prNum}) contains deletions only. Please select another PR.`,
    };
  }
  return { code: config.SUCCESS_RETURN_CODE };
}
