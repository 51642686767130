import type { Ref } from 'vue';
import type { ApplicabilityStatus } from '@swimm/shared';
import type { Editor } from '@tiptap/vue-3';
import type {
  ReviewAutosyncGenericTextNode,
  ReviewAutosyncHunkNode,
  ReviewAutosyncLinkNode,
  ReviewAutosyncPathNode,
} from '@/services/ReviewAutosyncService';

export interface NodesThatNeedsReview {
  autosynced: NeedsReviewNode;
  outdated: NeedsReviewNode;
}

export interface ReviewAutosyncFunctions {
  reportNodeScrolled: () => void;
  handleReviewOutDated: () => void;
  acceptAll: (instancesNeedsReview: NeedsReviewNode) => void;
}

export interface NeedsReviewLength {
  autosyncableLength: number;
  outdatedLength: number;
}

export enum NodeTypes {
  PATH = 'path',
  GENERIC_TEXT = 'genericText',
  HUNK = 'hunk',
  PLAYLIST = 'playlist',
  DOC = 'swimm-doc',
}

export type ReviewAutosyncNode =
  | ReviewAutosyncGenericTextNode
  | ReviewAutosyncHunkNode
  | ReviewAutosyncLinkNode
  | ReviewAutosyncPathNode;

export interface ReviewNode {
  data: {
    id: string;
    type: NodeTypes;
    applicability: ApplicabilityStatus;
  };
  accept?: (editor: Editor, node: ReviewAutosyncNode, id: string) => void;
  scrollToNode: (editor: Editor, pos: number, editorId: string) => void;
  delete: (editor: Editor, id: string) => void;
}

export interface ReviewAutosyncPathNodeInterface {
  applicability: ApplicabilityStatus;
  path: string;
  id: string;
  repoId: string;
  type: NodeTypes.PATH;
  pos: number;
}

export interface ReviewAutosyncGenericTextNodeInterace {
  applicability: ApplicabilityStatus;
  symbolText: string;
  type: NodeTypes.GENERIC_TEXT;
  indication: string;
  id: string;
  repoId: string;
  pos: number;
  lineNumber: number;
  wordIndex: { start: number; end: number };
}

export interface ReviewAutosyncLinkNodeInterface {
  applicability: ApplicabilityStatus;
  type: NodeTypes.DOC | NodeTypes.PLAYLIST;
  name: string;
  pos: number;
  id: string;
}

export interface ReviewAutosyncHunkNodeInterface {
  applicability: ApplicabilityStatus;
  type: NodeTypes.HUNK;
  indication: string;
  pos: number;
  path: string;
  id: string;
  startLineNumber: number;
  endLineNumber: number;
}

export type ReviewNodesArray = (
  | ReviewAutosyncPathNode
  | ReviewAutosyncGenericTextNode
  | ReviewAutosyncLinkNode
  | ReviewAutosyncHunkNode
)[];

export type NeedsReviewNode = Record<string, ReviewNodesArray>;

export interface ReviewAutosyncRefs {
  editor: Ref<Editor | null>;
  nodesThatNeedsReview: Ref<NodesThatNeedsReview | null>;
  editorId: Ref<string | null>;
  length: Ref<NeedsReviewLength>;
  goToEditDoc: () => void;
}
