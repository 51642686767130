<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { MultiCheckbox } from '@swimm/ui';
import { GitProviderIcons, type WorkspaceRepo, sortRepos } from '@swimm/shared';

const props = defineProps<{
  selectedRepoIds: string[];
  repos: WorkspaceRepo[];
  firstRepoId?: string;
}>();

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'selection-changed', selectedRepoIds: string[]): void;
  (event: 'toggle-selector', isOpen: boolean): void;
}>();

const isOpen = ref(false);

watch(isOpen, (isOpen) => {
  if (!isOpen) {
    emit('closed');
  }
  emit('toggle-selector', isOpen);
});

const sortedRepos = computed(() => sortRepos(props.repos, props.firstRepoId));

const repoIds = computed(() => sortedRepos.value.map((repo) => repo.id));

const selectedRepos = computed(() => sortedRepos.value.filter((repo) => props.selectedRepoIds.includes(repo.id)));

const firstSelectedName = computed(() => {
  return selectedRepos.value.length > 0 ? selectedRepos.value[0].name : '';
});

const firstSelectedIcon = computed(() => {
  return selectedRepos.value.length > 0 ? GitProviderIcons[selectedRepos.value[0].provider] : '';
});

function getRepoName(repoId: string) {
  return props.repos.find((repo) => repo.id === repoId)?.name ?? '';
}

const dropdownContent = ref<Element>();
defineExpose({ dropdownContent });
</script>
<template>
  <VDropdown
    placement="bottom"
    theme="dropdown-no-arrow"
    :triggers="['click']"
    v-model:shown="isOpen"
    :auto-hide="true"
  >
    <button class="repo-button" data-testid="repo-button">
      <Icon class="repo-icon" :name="firstSelectedIcon" />
      <span v-if="selectedRepoIds.length > 0" class="selected-text">
        <SwText variant="body-L">{{ firstSelectedName }}</SwText
        ><SwText v-if="selectedRepoIds.length > 1" variant="body-XS" class="n-others-text"
          >, +{{ selectedRepoIds.length - 1 }} others</SwText
        >
      </span>
      <template v-else>
        <SwText class="empty-selection-text" variant="body-L">No repos selected</SwText>
      </template>
      <Icon name="arrow-down" />
    </button>
    <template #popper>
      <div class="dropdown-content" ref="dropdownContent">
        <MultiCheckbox
          :show-search="true"
          search-placeholder="Search repositories"
          search-by-name
          :options="repoIds"
          empty-state-text="No repositories available"
          :display="(repoId: string) => getRepoName(repoId)"
          :identity="(repoId: string) => repoId"
          :model-value="selectedRepoIds"
          @update:model-value="(value: boolean) => $emit('selection-changed', value)"
        />
      </div>
    </template>
  </VDropdown>
</template>

<style scoped lang="postcss">
.dropdown-content {
  overflow: auto;
  max-height: 300px;
  padding: 0 var(--space-base);
}

.repo-button {
  font-family: var(--fontfamily-main);
  border: 1px solid var(--color-border-default);
  color: var(--text-color-primary);
  border-radius: 4px;
  cursor: pointer;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;

  &.expandable {
    display: flex;
    align-items: center;
    cursor: default;

    &:hover {
      border: 1px solid var(--color-border-default);
    }
  }

  &:hover {
    border: 1px solid var(--color-border-default-strong);
  }

  &.repo-button-selected {
    border: 1px solid var(--color-border-brand);
    background: var(--color-selected);

    &:hover {
      border: 1px solid var(--color-border-default-strong);
    }
  }
}

.empty-selection-text,
.n-others-text {
  color: var(--text-color-secondary);
}

.selected-text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.repo-icon {
  padding: var(--space-xs);
  font-size: var(--fontsize-sm);
  padding-top: 6px;
}
</style>
