import Image, { type ImageOptions } from '@tiptap/extension-image';
import { getSwimmEditorServices } from './Swimm';
import { getUploadImageErrorMessage, isImageFileSupported, pickImage } from '../image';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { addInsertionPlaceholder, replaceInsertionPlaceholder } from './InsertionPlaceholder';
import { dropPoint } from '@tiptap/pm/transform';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import ImageNodeView from '../nodeViews/ImageNodeView.vue';
import { getLoggerNew } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    image2: {
      pickAndInsertImage: () => ReturnType;
    };
  }
}

interface ExtendedImageOptions extends ImageOptions {
  blockImageType: string;
  blockImageThreshold: number;
}

function calculateNewImageType(options: ExtendedImageOptions, name: string, width: number, height: number) {
  if (width >= options.blockImageThreshold || height >= options.blockImageThreshold) {
    return options.blockImageType;
  }

  return name;
}

export default Image.extend<ExtendedImageOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      blockImageType: 'blockImage',
      blockImageThreshold: 256,
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),

      pickAndInsertImage:
        () =>
        ({ chain, dispatch, editor, state }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);

          if (!dispatch) {
            return chain().focus().setImage({ src: '' }).run();
          }
          setTimeout(async () => {
            try {
              const image = await pickImage();
              editor.commands.focus();

              if (image != null) {
                const id = {};
                editor.commands.addInsertionPlaceholder(id, state.selection.head);
                try {
                  const img = await swimmEditorServices.external.uploadImage(image, swimmEditorServices.unitId.value);

                  editor.commands.replaceInsertionPlaceholder(id, {
                    type: this.name,
                    attrs: { src: img.src },
                  });
                } catch (e) {
                  editor.commands.replaceInsertionPlaceholder(id, null);
                  throw e;
                }
              }
            } catch (err) {
              logger.warn({ err }, `Failed to upload inline image`);
              swimmEditorServices.external.showNotification(getUploadImageErrorMessage(err as Error), {
                icon: 'error',
              });
            }
          }, 0);

          return true;
        },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(ImageNodeView);
  },

  addProseMirrorPlugins() {
    const swimmEditorServices = getSwimmEditorServices(this.editor);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const extension = this;

    return [
      new Plugin({
        key: new PluginKey(this.name),
        props: {
          // Based on:
          // https://github.com/ProseMirror/prosemirror-view/blob/b3a9f21b475a2a332bd97615abbba6d9a22de095/src/input.ts#L598
          // https://github.com/ueberdosis/tiptap/issues/2912#issuecomment-1169631614
          handlePaste(view, event) {
            let handled = false;

            if (event.clipboardData == null) {
              return;
            }

            if (!event.clipboardData.types.includes('Files')) {
              return;
            }

            for (const file of event.clipboardData.files) {
              if (!isImageFileSupported(file)) {
                continue;
              }

              handled = true;

              const tr = view.state.tr.deleteSelection();
              const pos = tr.mapping.map(view.state.selection.from);
              const $pos = view.state.doc.resolve(pos);
              view.dispatch(tr);

              const id = {};
              addInsertionPlaceholder(id, pos)(view.state, view.dispatch, view);

              swimmEditorServices.external
                .uploadImage(file, swimmEditorServices.unitId.value)
                .then((img) => {
                  replaceInsertionPlaceholder(
                    id,
                    view.state.schema.node(
                      ['tableCell', 'tableHeader'].includes($pos.parent.type.name) // Do not convert to block image within a table
                        ? extension.name
                        : calculateNewImageType(extension.options, extension.name, img.width, img.height),
                      { src: img.src }
                    )
                  )(view.state, view.dispatch, view);
                })
                .catch((err) => {
                  replaceInsertionPlaceholder(id, [])(view.state, view.dispatch, view);
                  logger.warn({ err }, `Failed to paste image`);
                  swimmEditorServices.external.showNotification(getUploadImageErrorMessage(err as Error), {
                    icon: 'error',
                  });
                });
            }

            return handled;
          },

          // Based on:
          // https://github.com/ProseMirror/prosemirror-view/blob/b3a9f21b475a2a332bd97615abbba6d9a22de095/src/input.ts#L667
          // https://github.com/ueberdosis/tiptap/issues/2912#issuecomment-1169631614
          handleDrop(view, event, slice) {
            let handled = false;

            if (event.dataTransfer == null) {
              return;
            }

            if (!event.dataTransfer.types.includes('Files')) {
              return;
            }

            const eventPos = view.posAtCoords({ left: event.clientX, top: event.clientY });
            if (!eventPos) {
              return;
            }

            const $mouse = view.state.doc.resolve(eventPos.pos);
            let insertPos = dropPoint(view.state.doc, $mouse.pos, slice);
            if (insertPos == null) {
              insertPos = $mouse.pos;
            }

            for (const file of event.dataTransfer.files) {
              if (!isImageFileSupported(file)) {
                continue;
              }

              handled = true;

              const id = {};
              addInsertionPlaceholder(id, insertPos)(view.state, view.dispatch, view);

              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const $pos = view.state.doc.resolve(insertPos!);

              swimmEditorServices.external
                .uploadImage(file, swimmEditorServices.unitId.value)
                .then((img) => {
                  replaceInsertionPlaceholder(
                    id,
                    view.state.schema.node(
                      ['tableCell', 'tableHeader'].includes($pos.parent.type.name) // Do not convert to block image within a table
                        ? extension.name
                        : calculateNewImageType(extension.options, extension.name, img.width, img.height),
                      { src: img.src }
                    )
                  )(view.state, view.dispatch, view);
                })
                .catch((err) => {
                  replaceInsertionPlaceholder(id, [])(view.state, view.dispatch, view);
                  logger.warn({ err }, `Failed to drop image`);
                  swimmEditorServices.external.showNotification(getUploadImageErrorMessage(err as Error), {
                    icon: 'error',
                  });
                });
            }

            return handled;
          },
        },
      }),
    ];
  },
});
