<template>
  <div class="list-skeleton">
    <div class="name-wrapper">
      <div class="item name" :style="`width: ${Math.floor(Math.random() * 10) + 40}%;`" />
    </div>
    <div class="item status" />
    <div class="item author" />
    <div class="item activity" />
  </div>
</template>

<style scoped lang="postcss">
.list-skeleton {
  display: flex;
  align-items: center;
  height: 41px;
  box-sizing: border-box;

  .item {
    background-color: var(--color-hover);
    height: 16px;
    border-radius: 4px;
    opacity: 0.5;
  }

  .name-wrapper {
    flex: 1;
  }

  .name {
    width: 300px;
    margin-left: 16px;
  }

  .status {
    width: 32px;
    margin-left: 40px;
  }

  .author {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-left: 59px;
  }

  .activity {
    margin-right: 121px;
    width: 64px;
    margin-left: 73px;
  }
}
</style>
