<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue';
import type { Editor } from '@tiptap/vue-3';
import { BaseButton, BaseIcon, BaseInput, BaseLayoutGap } from '@swimm/reefui';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';

const props = withDefaults(
  defineProps<{
    editor: Editor;
    initialUrl?: string;
    initialText?: string;
    noTextInput?: boolean;
    hide: () => void;
    focus: boolean;
  }>(),
  {
    initialUrl: undefined,
    initialText: undefined,
    hide: undefined,
    focus: true,
  }
);

const emit = defineEmits<{
  selected: [selected: { url: string; text: string; textChanged: boolean }];
  delete: [];
}>();

const swimmEditorServices = getSwimmEditorServices(props.editor);

const urlInput = ref<InstanceType<typeof BaseInput>>();
const url = ref(props.initialUrl ?? '');
const text = ref(props.initialText ?? '');

onMounted(async () => {
  if (props.focus) {
    await nextTick();
    urlInput.value?.field?.focus();
  }
});

function deleteLink() {
  emit('delete');
  props.hide();
}

function openLink() {
  swimmEditorServices.external.openLink(url.value);
  props.hide();
}

function handleSubmit() {
  emit('selected', {
    url: url.value,
    text: text.value,
    textChanged: !props.noTextInput && text.value !== props.initialText,
  });
  props.hide();
}
</script>

<template>
  <form data-testid="link-popover" class="link-popover" @submit.prevent="handleSubmit" @keydown.esc="hide?.()">
    <BaseLayoutGap direction="column" alignment="center">
      <BaseInput ref="urlInput" v-model="url" data-testid="link-popover-url" autocomplete="url" label="URL:" />
      <BaseInput v-if="!noTextInput" v-model="text" data-testid="link-popover-text" label="Text:" />
      <div>
        <BaseButton v-if="initialUrl" data-testid="link-popover-delete" variant="secondary" @click="deleteLink">
          <template #leftIcon>
            <BaseIcon name="trash-outline" />
          </template>

          Delete
        </BaseButton>
        <BaseButton v-if="initialUrl" data-testid="link-popover-open" variant="secondary" @click="openLink">
          <template #leftIcon>
            <BaseIcon name="outbound-link" />
          </template>

          Open
        </BaseButton>
        <BaseButton data-testid="link-popover-submit" type="submit">
          <template #leftIcon>
            <BaseIcon name="check" />
          </template>

          Save
        </BaseButton>
      </div>
    </BaseLayoutGap>
  </form>
</template>

<style scoped lang="scss">
.link-popover {
  padding: var(--space-xsmall);
  border: 1px solid var(--color-border-default);
  border-radius: var(--space-xsmall);
  background: var(--color-bg-default);
  width: 340px;
}
</style>
