import flatten from 'lodash-es/flatten';

import Code from '@tiptap/extension-code';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { Node } from '@tiptap/pm/model';
import { DecorationSet } from '@tiptap/pm/view';

import { type PathSuggestion, type TokenSuggestion, getLoggerNew } from '@swimm/shared';
import Link from '@/tiptap/extensions/Link';
import { changedDescendants } from '../docDiff';
import { swimmportSuggestionToDecoration } from './decoration';
import type { SuggestionSorter } from '@/services/swimmport/SuggestionSorter';

const logger = getLoggerNew(__modulename);
export interface SuggestionsChanges {
  suggestionsToAdd: NodeSuggestions;
  rangesToRemove: { from: number; to: number }[]; // A changed range will always be marked as removed, and might also have added suggestions.
}

export type NodeSuggestions = NodeSuggestion[];

export interface NodeSuggestion {
  text: string;
  from: number;
  to: number;
  result: TextSuggestions;
}

export type TextSuggestions =
  | { type: 'path'; suggestions: PathSuggestion[] }
  | { type: 'token'; suggestions: TokenSuggestion[] }
  | undefined;

export class SwimmportDecorationsHandler {
  readonly getSuggestionsForText: (
    text: string,
    { isCode, isLink }: { isCode: boolean; isLink: boolean }
  ) => Promise<TextSuggestions>;
  readonly suggestionSorter?: SuggestionSorter;
  suggestionsMap: Map<string, TextSuggestions> = new Map<string, TextSuggestions>();

  getSuggestionsMapKey(text: string, isCode: boolean, isLink: boolean) {
    return `${text}-${isCode ? 'code' : 'text'}${isLink ? '-link' : ''}`;
  }

  constructor(
    getSuggestionsForText: (
      text: string,
      { isCode, isLink }: { isCode: boolean; isLink: boolean }
    ) => Promise<TextSuggestions>,
    suggestionSorter?: SuggestionSorter
  ) {
    this.getSuggestionsForText = getSuggestionsForText;
    this.suggestionSorter = suggestionSorter;
  }

  async calculate(doc: Node): Promise<DecorationSet> {
    this.suggestionsMap = new Map();
    const swimmportSuggestions = await this.calculateSuggestions(doc);
    const decorations = swimmportSuggestions.map(swimmportSuggestionToDecoration);
    return DecorationSet.create(doc, decorations);
  }

  async update(doc: Node, oldDoc: Node, decorationSet: DecorationSet): Promise<DecorationSet> {
    const suggestionsChanges = await this.updateSuggestions(doc, oldDoc);

    for (const rangeToRemove of suggestionsChanges.rangesToRemove) {
      // `DecorationSet.find` includes decorations that touch the range as well.
      // Thus, to avoid removing decorations of adjacent unchanged nodes, we reduce the range by 1 from each side.
      decorationSet = decorationSet.remove(decorationSet.find(rangeToRemove.from + 1, rangeToRemove.to - 1));
    }

    const decorationsToAdd = suggestionsChanges.suggestionsToAdd.map(swimmportSuggestionToDecoration);
    decorationSet = decorationSet.add(doc, decorationsToAdd);

    return decorationSet;
  }

  async calculateSuggestions(doc: Node): Promise<NodeSuggestions> {
    const promises: Promise<NodeSuggestions>[] = [];

    doc.descendants((node, pos) => {
      if (shouldGetSuggestionsForNode(node)) {
        promises.push(this.calculateSuggestionsForTextNode(node, pos));
      }
      return shouldDescendNode(node);
    });

    return flatten(await Promise.all(promises));
  }

  private async updateSuggestions(doc: Node, oldDoc: Node): Promise<SuggestionsChanges> {
    const rangesToRemove: { text: string; from: number; to: number }[] = [];
    const suggestionsToAdd: Promise<NodeSuggestions>[] = [];

    // TODO: Should we use `combineTransactionSteps` and `getChangedRanges` instead?
    // At the moment, changedDescendants uses callback with side effects.
    changedDescendants(oldDoc, doc, 0, 0, (node, pos) => {
      if (shouldUpdateSuggestionsForNode(node)) {
        rangesToRemove.push({ text: node.text ?? '', from: pos, to: pos + node.nodeSize });
      }
      if (shouldGetSuggestionsForNode(node)) {
        suggestionsToAdd.push(this.calculateSuggestionsForTextNode(node, pos));
      }
      // FIXME: Right now, if we create a code block around text with swimmport suggestion, the suggestion isn't removed.
      return shouldDescendNode(node);
    });

    return { suggestionsToAdd: flatten(await Promise.all(suggestionsToAdd)), rangesToRemove };
  }

  private async calculateSuggestionsForTextNode(node: Node, pos: number): Promise<NodeSuggestions> {
    if (node.text == null) {
      return [];
    }

    const isCode = node.marks.some((mark) => mark.type.name === Code.name);
    const isLink = node.marks.some((mark) => mark.type.name === Link.name);
    const nodeKey = this.getSuggestionsMapKey(node.text, isCode, isLink);

    if (isCode || isLink) {
      // This caching mechanism helps with performance. But it is not the complete solution.
      // We should revisit the data flow as a whole and refactor it as a map and not arrays
      let result = this.suggestionsMap.get(nodeKey);

      if (result == null) {
        result = await this.getSuggestionsForText(node.text, { isCode, isLink });
      }

      if (result != null && result.suggestions.length > 0) {
        result.suggestions = this.sortSuggestions(result, pos);
        this.suggestionsMap.set(nodeKey, result);
        // If there are suggestions for a whole inline code, don't get suggestions for its words.
        return [{ text: node.text, from: pos, to: pos + node.text.length, result }];
      }
    }

    // We only want to match links that are entirely a path of a file, so we skip partial matches to avoid FPs.
    if (isLink) {
      return [];
    }

    const candidates = [...getSwimmportTextCandidates(node.text)];
    const nodeSuggestions = await Promise.all(
      candidates.map(async (candidate) => {
        const candidateKey = this.getSuggestionsMapKey(candidate.text, isCode, isLink);
        let result = this.suggestionsMap.get(candidateKey);

        if (result == null) {
          result = await this.getSuggestionsForText(candidate.text, { isCode, isLink });
          if (result) {
            result.suggestions = this.sortSuggestions(result, pos);
          }
          this.suggestionsMap.set(candidateKey, result);
        }

        return {
          text: candidate.text,
          from: pos + candidate.from,
          to: pos + candidate.from + candidate.text.length,
          result,
        };
      })
    );
    return nodeSuggestions.filter((nodeSuggestion) => nodeSuggestion.result != null);
  }

  private sortSuggestions(result: TextSuggestions, pos: number) {
    if (this.suggestionSorter) {
      try {
        if (result?.type === 'token') {
          return this.suggestionSorter.sortTokens(result.suggestions, pos) as TokenSuggestion[];
        }
        if (result?.type === 'path') {
          return this.suggestionSorter.sortPaths(result.suggestions, pos) as PathSuggestion[];
        }
      } catch (err) {
        logger.error({ err }, `Failed to sort suggestions for position ${pos}`);
      }
    }
    return result?.suggestions ?? [];
  }
}

function shouldGetSuggestionsForNode(node: Node) {
  return shouldUpdateSuggestionsForNode(node);
}

function shouldUpdateSuggestionsForNode(node: Node) {
  return node.type.name === 'text';
}

function shouldDescendNode(node: Node) {
  return node.type.name !== CodeBlockLowlight.name;
}

// Canditates are words (no whitespace) of at least 2 characters, excluding trailing punctuation.
// Note we don't trim ';' since it is more common in code.
// We do accept () at the end so that things like 'getDate()' get picked up in their entirety.
// This risks not giving a result if the user refers to a function foo as 'foo()' even though
// it does accept parameters, or if it isn't called in the code.
const SWIMMPORT_CANDIDATE_PATTERN = /\S+(?:\(\)|[^\s.,:)!?-])/g;

function* getSwimmportTextCandidates(text: string): Iterable<{ text: string; from: number }> {
  for (const match of text.matchAll(SWIMMPORT_CANDIDATE_PATTERN)) {
    if (match.index == null) {
      continue;
    }
    yield { text: match[0], from: match.index };
  }
}
