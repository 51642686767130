<script setup lang="ts">
import { useBatchImportStore } from '@/modules/batch-import/stores/batch-import';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { BaseBlock } from '@swimm/reefui';

const batchImportStore = useBatchImportStore();
const { toggleBatchImportModal, conversionType } = batchImportStore;
const { filesConverted } = storeToRefs(batchImportStore);

const filesConvertedSuccessfully = computed(() => filesConverted.value.filter((convertedFile) => convertedFile.swm));
const docText = computed(() => (filesConvertedSuccessfully.value.length > 1 ? 'docs' : 'doc'));
const handleDoneClicked = () => {
  toggleBatchImportModal(false);
};
</script>

<template>
  <div class="import-summary-container">
    <SwText class="summary-description" variant="subtitle-L" weight="bold" data-testid="summary-description-label">
      🎉 Congrats! You've imported {{ filesConvertedSuccessfully.length }} new {{ docText }} to Swimm!
    </SwText>
    <div class="summary-container">
      We recommend:
      <div class="step">
        <Icon name="hint" no-padding />
        <SwText variant="body-S">Review docs for accuracy and commit to your repo.</SwText>
      </div>
      <div class="step">
        <Icon name="hint" no-padding />
        <SwText variant="body-S"> Replace code blocks with code snippets to keep your docs up to date. </SwText>
      </div>
      <BaseBlock v-if="conversionType === 'run-smart-import'">
        <b>Docs were copied to <code>.swm/</code> folder</b> - originals were not affected and will not be edited if you
        change the new Swimm documents. <br />
      </BaseBlock>
    </div>
    <div class="actions">
      <Action :disabled="false" data-testid="summary-done-button" @click="handleDoneClicked">Done</Action>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.import-summary-container {
  padding-top: var(--space-base);

  .summary-description {
    line-height: 1.5rem;
    color: var(--text-color-success);
  }

  .summary-container {
    margin-top: var(--space-sm);

    .step {
      display: flex;
      align-items: center;
      margin: 12px 0;

      .icon-hint {
        margin-right: var(--space-xs);
      }

      &:first-child {
        margin-top: var(--space-sm);
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: var(--space-sm);

    & .button:first-of-type {
      margin-right: var(--space-base);
    }
  }
}
</style>
