<template>
  <StepsModal :show="show" header="What's new?" :steps="releaseNotesItems" @close="onClose">
    <template #title="{ currentStep }">
      <ReleaseNotesTag v-if="currentStep.type" :type="currentStep.type" />
    </template>
  </StepsModal>
</template>

<script setup>
import ReleaseNotesTag from '@/common/components/Workspace/Modals/ReleaseNotesTag.vue';
import StepsModal from '@/common/components/Workspace/Modals/StepsModal.vue';
import { releaseNotesItems } from '@/common/components/Workspace/Modals/releaseNotes';

defineProps({
  show: { type: Boolean, required: true },
});

const emit = defineEmits(['close']);

function onClose() {
  emit('close');
}
</script>
