import { flatten } from 'lodash-es';
import { ref } from 'vue';

import type { FileTokensIndex } from '@swimm/editor';
import type { TokenSuggestion } from '@swimm/shared';
import { tokenize } from '@swimm/editor';

import { getRepoDefaultBranch } from '@/remote-adapters/local_repo';
import { getLoggerNew, gitwrapper } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export function useWebAppFileTokensIndex(docRepoId: string, docBranch: string): FileTokensIndex {
  const index = ref(new Map<string, Promise<TokenSuggestion[]> | TokenSuggestion[]>());

  const load = async (repoId: string, path: string, options?: { force?: boolean }): Promise<TokenSuggestion[]> => {
    const { force } = Object.assign({}, { force: false }, options);
    const indexed = index.value.get(composeIndexKey(repoId, path));
    if (!force && indexed) {
      return indexed;
    }
    const promise = innerLoad(repoId, path);
    index.value.set(composeIndexKey(repoId, path), promise);
    promise.then((result) => {
      index.value.set(composeIndexKey(repoId, path), result);
    });
    return promise;
  };

  const get = (repoId: string, path: string): { loading: boolean; suggestions: TokenSuggestion[] } => {
    const indexed = index.value.get(composeIndexKey(repoId, path));
    if (indexed == null) {
      load(repoId, path);
      return { loading: true, suggestions: [] };
    }
    if (indexed instanceof Promise) {
      return { loading: true, suggestions: [] };
    }
    return { loading: false, suggestions: indexed };
  };

  const getAsync = async (repoId: string, path: string): Promise<TokenSuggestion[]> => {
    const indexed = index.value.get(composeIndexKey(repoId, path));
    if (indexed != null) {
      return indexed;
    }
    return load(repoId, path);
  };

  const clear = (): void => {
    index.value.clear();
  };

  const innerLoad = async (repoId: string, path: string): Promise<TokenSuggestion[]> => {
    try {
      const branch = await getBranch(repoId);
      if (branch == null) {
        logger.error({ repoId }, 'Failed to get branch for repo');
        return [];
      }
      const fileContent: string | null = await gitwrapper.getFileContentFromRevision({
        filePath: path,
        revision: branch,
        repoId,
      });
      if (fileContent == null) {
        logger.error({ repoId, branch }, 'Failed to get file content');
        return [];
      }
      return tokenizeFile(repoId, path, fileContent);
    } catch (err) {
      logger.error({ err, repoId }, 'Failed to tokenize file');
      return [];
    }
  };

  const composeIndexKey = (repoId: string, path: string) => `${repoId}-${path}`;

  const getBranch = async (repoId: string): Promise<string | null> => {
    if (repoId === docRepoId) {
      return docBranch;
    }
    const result = await getRepoDefaultBranch({ repoId });
    if (result.code === 0) {
      return result.branch;
    }
    return null;
  };

  const tokenizeFile = (repoId: string, path: string, content: string): TokenSuggestion[] => {
    const contentLines = content.split('\n');
    const tokenSuggestionsPerLine: TokenSuggestion[][] = contentLines.map<TokenSuggestion[]>((line, index) => {
      const tokens = tokenize(line);
      return tokens.map((token) => ({
        token: token.text,
        position: {
          path,
          line: index + 1, // Line numbers are 1-based
          wordStart: token.index,
          wordEnd: token.index,
        },
        lineData: line,
        repoId,
        static: false,
      }));
    });
    return flatten(tokenSuggestionsPerLine);
  };

  return { load, get, getAsync, clear };
}
