<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    delay?: number;
  }>(),
  {
    delay: 500,
  }
);

const isRenderable = ref(false);
let delayTimeout: number | NodeJS.Timeout;

onMounted(() => {
  delayTimeout = setTimeout(() => {
    isRenderable.value = true;
  }, props.delay);
});

onUnmounted(() => {
  clearTimeout(delayTimeout);
});
</script>

<template>
  <slot v-if="isRenderable"></slot>
</template>
