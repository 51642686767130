<script setup lang="ts">
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import {
  GitProviderIcons,
  type GitProviderNameType,
  UrlUtils,
  getLoggerNew,
  gitProviderUtils,
  gitwrapper,
  productEvents,
} from '@swimm/shared';
import { storeToRefs } from 'pinia';
import swal from 'sweetalert';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const logger = getLoggerNew(__modulename);

/**
 * If provider is not provided, use the workspace provider
 */
const props = defineProps({
  context: { type: String, required: true },
  provider: { type: String, default: null },
  buttonText: { type: String, default: null },
  useProviderIcon: { type: Boolean, default: false },
  allowAccessToPrivateRepos: { type: Boolean, default: true },
  buttonSize: { type: String, default: 'small' },
  isSecondary: { type: Boolean, default: false },
});

const emit = defineEmits(['authorized']);

const route = useRoute();
const analytics = useAnalytics();
const { fetchReposConfigData, authorizeProviderWithGit, setGitHostingUrlAuthorized } = useGitAuthorizationStore();
const {
  provider: workspaceProvider,
  providerDisplayName,
  enterpriseGitUrl,
  tenantId,
  workspaceSettings,
} = storeToRefs(useWorkspaceStore());
const { reposStateData } = storeToRefs(useReposStore());

void fetchReposConfigData();

const providerToUse = computed(() => {
  return (props.provider ?? workspaceProvider.value) as GitProviderNameType;
});

const textToUse = computed(() => {
  return (
    props.buttonText ??
    `Authorize ${
      providerToUse.value ? gitwrapper.getTerminology(providerToUse.value).displayName : providerDisplayName.value
    }`
  );
});
const shouldAuthorizeWithAccessToken = computed(() => workspaceSettings.value.is_access_token ?? false);
async function authorizeWithAccessToken() {
  logger.info('Workspace with access token detected, skipping OAuth authorization');

  const accessToken = await getAccessTokenWithDialog(gitwrapper.getDisplayName(providerToUse.value));
  if (accessToken) {
    const url = UrlUtils.getGitHostingKey({
      provider: providerToUse.value,
      gitUrl: enterpriseGitUrl.value,
      tenantId: tenantId.value,
    });

    await gitProviderUtils.setGitHostingTokenData(url, { token: accessToken, orgs: [] });
    logger.info(`Access Token set for ${url} workspaceId: ${route.params.workspaceId}`);
    await setGitHostingUrlAuthorized(url);
    emit('authorized');
    return true;
  }
  return false;
}

function getEnterpriseGitUrl() {
  // Cloud providers don't need apiUrl
  if (gitProviderUtils.isProviderCloud(providerToUse.value) && tenantId.value == null) {
    return null;
  }

  // If were in a specific repo, and the providers match, return the repos gitUrl
  if (route.params.repoId) {
    const repo = reposStateData.value[route.params.repoId as string];
    if (repo.provider === providerToUse.value) {
      return repo.api_url;
    }
  }

  // If the provider matches the workspaces, return the workspaces gitUrl
  if (providerToUse.value === workspaceProvider.value) {
    return enterpriseGitUrl.value;
  }

  logger.info(
    `getEnterpriseGitUrl: Find the first repo matching provider ${
      providerToUse.value
    } and return it's gitUrl. TenantId: ${tenantId.value ?? 'null'}`
  );
  // Find the first repo with a matching provider and return it's gitUrl
  const repoWithProvider = Object.values(reposStateData.value).find((repo) => {
    if (repo.provider !== providerToUse.value) {
      return false;
    }
    if (!repo.api_url) {
      return false;
    }

    if (tenantId.value) {
      return repo.tenant_id === tenantId.value;
    }

    return true;
  });
  logger.info(
    `getEnterpriseGitUrl: Found repo ${repoWithProvider.repoName} with provider: ${repoWithProvider.provider} and api_url: ${repoWithProvider.api_url}`
  );
  return repoWithProvider.api_url;
}

async function connectToGit() {
  analytics.track(productEvents.CLICKED_AUTHORIZE_GITHUB, {
    Context: props.context,
    'Workspace ID': route.params.workspaceId,
    'Page Name': route.name,
    'Git Provider': providerToUse.value,
  });
  if (shouldAuthorizeWithAccessToken.value) {
    await authorizeWithAccessToken();
    return;
  }

  const gitUrl = getEnterpriseGitUrl();

  await authorizeProviderWithGit({
    provider: providerToUse.value,
    gitUrl,
    shouldAllowAccessToPrivateRepos: props.allowAccessToPrivateRepos,
    origin: `Authorize button in ${props.context}`,
    tenantId: tenantId.value,
  });

  emit('authorized');
}
async function getAccessTokenWithDialog(providerDisplayName: string): Promise<string> {
  let accessToken: string;
  let didCancel = false;
  while (!accessToken && !didCancel) {
    accessToken = await swal({
      title: `${providerDisplayName} Access Token required`,
      text: `Please enter the Access Token you generated for ${providerDisplayName}`,
      content: {
        element: 'input',
        attributes: {
          placeholder: 'Project/Repo Access Token',
          type: 'text',
        },
      },
      buttons: ['Cancel', 'Submit'],
      closeOnClickOutside: false,
      closeOnEsc: false,
    });
    // If the user canceled the dialog, we don't want to keep asking for the token
    if (!accessToken && accessToken?.trim() !== '') {
      didCancel = true;
    } else if (accessToken?.trim() === '') {
      swal('Please enter a valid access token');
    }
  }
  return accessToken;
}
</script>

<template>
  <div class="wrapper" data-testid="git-authorize-banner">
    <slot />
    <Action class="button" :size="buttonSize" button-type="button" :secondary="isSecondary" @click="connectToGit()">
      <Icon v-if="useProviderIcon" :name="GitProviderIcons[provider]" class="icon" no-padding />
      {{ textToUse }}
    </Action>
  </div>
</template>

<style scoped lang="postcss">
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--space-sm);
  font-size: var(--body-S);
  text-align: left;
  gap: var(--space-sm);
  color: var(--text-color-secondary);

  .button {
    width: 100%;
    padding: var(--space-xs);

    .icon {
      margin-right: var(--space-xs);
    }
  }
}
</style>
