<script setup lang="ts">
import { BaseDivider } from '@swimm/reefui';
import { SwimmResourceUserStatus, productEvents } from '@swimm/shared';

import SayThanksButton from '@/modules/doc-contributions/components/SayThanksButton.vue';
import ToggleDoneButton from '@/modules/playlists/components/ToggleDoneButton.vue';
import PluginsLinks from '@/modules/ide/PluginsLinks.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';

const analytics = useAnalytics();

const props = defineProps<{
  swimmId: string;
  repoId: string;
  canEditDoc: boolean;
  isEditMode: boolean;
  shouldShowToggleDone?: boolean;
}>();

const emit = defineEmits<{
  (e: 'toggle-mark', status: SwimmResourceUserStatus);
  (e: 'edit-doc-clicked');
}>();

function toggleMark(status: SwimmResourceUserStatus) {
  emit('toggle-mark', status);
}

function navigateToEditDoc() {
  analytics.track(productEvents.CLICKED_EDIT_DOC, {
    Context: 'Footer',
    Origin: 'View Document',
    'Repo ID': props.repoId,
    'Document ID': props.swimmId,
  });
  emit('edit-doc-clicked');
}
</script>
<template>
  <div class="footer">
    <BaseDivider />

    <div class="container">
      <div class="say-thanks-section">
        <SwText variant="subtitle-L" class="footer-title">Helpful doc?</SwText>
        <div class="buttons-container">
          <ToggleDoneButton
            v-if="shouldShowToggleDone"
            @toggle-mark="toggleMark"
            :repo-id="repoId"
            :resource-id="swimmId"
          />
          <SayThanksButton :doc-id="swimmId" :repo-id="repoId" />
        </div>
      </div>
      <div class="wrapper">
        <div v-if="canEditDoc && !isEditMode" class="call-to-edit">
          <SwText variant="subtitle-L" class="footer-title">Anything we missed?</SwText>
          <span class="call-to-edit-text">Add your knowledge to this doc. </span>
          <div class="edit-doc-link" @click="navigateToEditDoc">Edit doc</div>
        </div>
        <div class="read-in-ide">
          <SwText variant="subtitle-L" class="footer-title">Read this in IDE?</SwText>
          <PluginsLinks />
        </div>
      </div>
    </div>

    <BaseDivider />
  </div>
</template>
<style scoped lang="postcss">
.footer {
  .container {
    justify-content: space-between;
    margin: var(--space-lg) 0;
    display: flex;
    gap: var(--space-lg);
  }

  .buttons-container {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: var(--space-base);
  }

  .footer-title {
    margin-bottom: var(--space-base);
  }

  .call-to-edit {
    .call-to-edit-text {
      color: var(--text-color-secondary);
    }

    .edit-doc-link {
      color: var(--text-color-link);
      cursor: pointer;
      font-weight: bold;
      border-radius: 4px;
      margin-top: var(--space-base);

      &:hover {
        background-color: var(--color-hover);
      }
    }
  }

  .wrapper {
    display: flex;
    gap: var(--space-lg);
  }
}
</style>
