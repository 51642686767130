<script setup>
import { Action, SwModal, SwText } from '@swimm/ui';
import { ref } from 'vue';

defineProps({
  danger: { type: Boolean, default: false },
  title: { type: String, required: true },
  body: { type: String, default: '' },
  cancelText: { type: String, default: 'Cancel' },
  confirmText: { type: String, default: 'Confirm' },
});
const emit = defineEmits(['confirm', 'cancel']);

const show = ref(false);
const resolvePromise = ref(null);

function onConfirmClick() {
  show.value = false;
  resolvePromise.value({ confirm: true, next: false });
  emit('confirm');
}

function onCancelClick() {
  show.value = false;
  resolvePromise.value({ confirm: false, next: true });
  emit('cancel');
}

function onClose() {
  resolvePromise.value({ confirm: false, next: false });
  show.value = false;
}

defineExpose({
  showDialog: function () {
    return new Promise((resolve) => {
      show.value = true;
      resolvePromise.value = resolve;
    });
  },
});
</script>

<template>
  <SwModal :header-border="false" :padded="false" :show-modal="show" :close-on-backdrop-click="false" @close="onClose">
    <template #header>
      <SwText class="header" variant="headline3">{{ title }}</SwText>
    </template>
    <div class="container">
      <slot>
        <SwText component="p" variant="body-L" class="content">{{ body }}</SwText>
      </slot>
      <div class="actions">
        <Action v-if="cancelText" secondary @click="onCancelClick" data-testid="cancel">{{ cancelText }}</Action>
        <Action v-if="confirmText" :variant="danger ? 'danger' : null" @click="onConfirmClick" data-testid="confirm">{{
          confirmText
        }}</Action>
      </div>
    </div>
  </SwModal>
</template>

<style scoped lang="postcss">
.header {
  padding: var(--space-base) 0;
}

.container {
  padding: var(--space-md);
  padding-top: 0;
  width: 40vw;

  .content {
    margin-top: var(--space-base);
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: var(--space-base);
  }
}
</style>
