import { Dexie, type Table } from 'dexie';

import type { TokenSuggestion } from '@swimm/shared';

interface StaticAnalysisEntry {
  repoId: string;
  commitSha: string;
  tokens: TokenSuggestion[];
}

class StaticAnalysisDatabase extends Dexie {
  staticAnalysis!: Table<StaticAnalysisEntry>;
  constructor() {
    super('staticAnalysis');
    this.version(1).stores({
      staticAnalysis: '[repoId+commitSha]',
    });
  }
}

const database = new StaticAnalysisDatabase();

async function get(repoId: string, commitSha: string): Promise<TokenSuggestion[] | undefined> {
  const item = await database.staticAnalysis.where({ repoId, commitSha }).first();
  return item?.tokens;
}

async function set(repoId: string, commitSha: string, tokens: TokenSuggestion[]) {
  database.staticAnalysis.put({ repoId, commitSha, tokens });
}

export const staticAnalysisDatabase = { get, set };
