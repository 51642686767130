<script setup lang="ts">
import { useNotificationsStore } from './notifications';
import ToastNotification from './ToastNotification.vue';

const store = useNotificationsStore();
</script>

<template>
  <teleport to="body">
    <div class="toast-nofitications-wrapper">
      <TransitionGroup tag="div" name="list">
        <ToastNotification v-for="item in store.notifications" :key="item.id" v-bind="item" />
      </TransitionGroup>
    </div>
  </teleport>
</template>

<style scoped>
.toast-nofitications-wrapper {
  position: fixed;
  bottom: 4em;
  right: 6em;
  z-index: 2000;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(200px);
}
</style>
