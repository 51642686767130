<script setup>
import RepoPullRequestTable from '@/modules/repo/pull-requests/components/RepoPullRequestTable.vue';
import BlockSkeleton from '@/modules/core/components/BlockSkeleton.vue';
import { computed } from 'vue';
import { EmptyState } from '@swimm/ui';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps({
  loading: { type: Boolean, required: true },
  repoUrl: { type: String, required: true },
  branch: { type: String, required: true },
  docs: { type: Array, required: true },
  playlists: { type: Array, required: true },
  repoId: { type: String, required: true },
  selectedPrNum: { type: Number, default: null },
});

const pendingItemsExists = computed(() => props.docs.length > 0 || props.playlists.length > 0);

const pendingItemsGroupedByBranch = computed(() => reducePendingItems([...props.docs, ...props.playlists]));

function reducePendingItems(items) {
  return items
    ?.sort((item1, item2) => new Date(item2.prLastModified) - new Date(item1.prLastModified))
    ?.reduce((updatedRepoItems, { id, branch, name, type, prId, prTitle, isPendingAddition, isPendingDeletion }) => {
      const newItem = {
        id,
        type,
        name,
        prId,
        prTitle,
        isAdded: isPendingAddition,
        isDelete: isPendingDeletion,
      };
      const updatedBranchItems = [...(updatedRepoItems[branch] || []), newItem];
      return { ...updatedRepoItems, [branch]: updatedBranchItems };
    }, {});
}

const { reposStateData } = storeToRefs(useReposStore());
const repoData = computed(() => reposStateData.value[props.repoId]);

const defaultBranchActionText = computed(() => `Show PRs for ${repoData.value?.defaultBranch || 'the default branch'}`);
const isOnDefaultBranch = computed(() => props.branch === repoData.value?.defaultBranch);
const pullRequestsTitle = computed(
  () => 'No pull requests ' + (isOnDefaultBranch.value ? 'containing docs' : 'into this branch')
);
const pullRequestsSubTitle = computed(() =>
  isOnDefaultBranch.value
    ? 'Create code-coupled docs and commit them to a side branch'
    : `We couldn't find any pull requests into ${props.branch} that contain docs.`
);

const route = useRoute();
const router = useRouter();

function routeToDefaultBranch() {
  const workspaceId = route.params.workspaceId;
  const branchRoute = repoData.value?.defaultBranch ? `/branch/${repoData.value.defaultBranch}` : '';
  router.push(`/workspaces/${workspaceId}/repos/${props.repoId}${branchRoute}/pulls`);
}
</script>

<template>
  <section class="pull-requests" data-testid="pull-requests-tab">
    <template v-if="!pendingItemsExists">
      <template v-if="loading">
        <BlockSkeleton class="skeleton small" />
        <BlockSkeleton class="skeleton medium" />
        <BlockSkeleton class="skeleton small" />
      </template>
      <EmptyState
        v-else
        :title="pullRequestsTitle"
        :description="pullRequestsSubTitle"
        icon-name="code-coupled"
        transparent
        class="empty"
      />
      <Action v-if="!isOnDefaultBranch" @click="routeToDefaultBranch" class="default-branch-action">
        {{ defaultBranchActionText }}
      </Action>
    </template>
    <RepoPullRequestTable
      v-else
      v-for="[headBranch, items] in Object.entries(pendingItemsGroupedByBranch)"
      :key="headBranch"
      :repo-url="repoUrl"
      :head-branch="headBranch"
      :base-branch="branch"
      :items="items"
      :selected-pr-num="selectedPrNum"
    />
  </section>
</template>

<style scoped lang="postcss">
.pull-requests {
  margin-top: var(--space-lg);

  .skeleton {
    margin-bottom: var(--space-md);

    &.small {
      height: 200px;
    }

    &.medium {
      height: 300px;
    }
  }

  .empty {
    color: var(--text-color-secondary);
  }

  .default-branch-action {
    display: flex;
    margin: auto;
  }
}
</style>
