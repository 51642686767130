<template>
  <div class="player-wrapper">
    <iframe
      v-if="src"
      :src="src"
      allow="autoplay; fullscreen"
      allowtransparency="true"
      frameborder="0"
      scrolling="no"
      allowfullscreen
      msallowfullscreen
    ></iframe>
    <div v-else>
      Sorry, we couldn't resolve link <a :href="url" target="_blank">{{ url }}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: { type: String, required: true },
  },
  data() {
    return {
      src: '',
    };
  },
  created() {
    const videoId = this.parseWistiaVideoId();
    if (videoId) {
      this.src = `https://fast.wistia.net/embed/iframe/${videoId}`;
    }
  },
  methods: {
    parseWistiaVideoId() {
      // The video url format https://COMPANY_NAME.wistia.com/medias/VIDEO_ID
      const splitPath = this.url.split('/');
      return splitPath.pop();
    },
  },
};
</script>

<style scoped>
.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

a {
  color: var(--text-color-primary);
}

a:hover {
  text-decoration: underline;
}
</style>
