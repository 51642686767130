<template>
  <div class="status-line system-body" :class="{ inactive: inactive }">
    <div class="status-circle" :class="statusClass"></div>
    <span class="status">{{ status }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  status: { type: String, required: true },
  inactive: { type: Boolean, default: false },
});

const statusClass = computed(() => props.status.split(' ').join('-'));
</script>

<style scoped>
.status {
  font-family: var(--fontfamily-secondary);
  font-weight: 600;
  text-align: center;
  text-transform: lowercase;
}

.status-line {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status.inactive {
  color: var(--text-color-secondary);
  background: var(--color-disable);
}

.status-circle {
  margin-top: 3px;
  margin-right: 6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.not-started {
  background: var(--color-disable);
}

.started {
  background: var(--text-color-warning);
}

.done {
  background: var(--success-green);
}

/* TODO: use for currently swimming status */

.all {
  background: var(--color-brand);
}
</style>
