import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core';

function transformSwmMention(state: StateCore): void {
  const blockTokens = state.tokens;

  for (let j = 0, l = blockTokens.length; j < l; j++) {
    if (blockTokens[j].type !== 'inline') {
      continue;
    }

    const tokens = blockTokens[j].children ?? [];

    for (let open = 0; open < tokens.length; open++) {
      if (tokens[open].type !== 'swm_inline_open' || tokens[open].tag !== 'SwmMention') {
        continue;
      }

      let close;
      for (close = open + 1; close < tokens.length; close++) {
        if (tokens[close].type === 'swm_inline_close' && tokens[open].tag === 'SwmMention') {
          break;
        }
      }

      if (close === tokens.length) {
        continue;
      }

      if (
        tokens[open].attrGet('uid') == null ||
        tokens[open + 1].type !== 'link_open' ||
        tokens[open + 2].type !== 'text'
      ) {
        continue;
      }

      tokens[open].type = 'swm_mention';
      tokens[open].attrSet('name', tokens[open + 2].content);
      const href = tokens[open + 1].attrGet('href');
      if (href != null) {
        tokens[open].attrSet('email', href);
      }

      // Collapse the following tokens including the close token, not exact, as
      // link already strips stuff, but this is just for debugging really
      for (let i = open + 1; i < close + 1; i++) {
        tokens[open].content = tokens[open].content + tokens[i].content;
      }
      tokens.splice(open + 1, close - open);
    }
  }
}

export default function swm_path(md: MarkdownIt): void {
  md.core.ruler.after('inline', 'swm_mention', transformSwmMention);
}
