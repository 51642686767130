<script setup lang="ts">
import { provide, toRef } from 'vue';

const props = defineProps({
  current: { type: Number, required: true },
});

const emit = defineEmits(['change']);

provide('current', toRef(props, 'current'));
provide('itemClick', (itemId) => emit('change', itemId));
</script>

<template>
  <div class="accordion">
    <slot />
  </div>
</template>

<style scoped>
:slotted(.accordion-item:first-child) {
  border-top: 1px solid var(--color-border-default-subtle);
}
</style>
