<script setup lang="ts">
import { computed } from 'vue';

import BaseBadge from '../../components/BaseBadge/BaseBadge.vue';

const props = defineProps<{
  draft?: boolean;
}>();

const computedVariant = computed(() => {
  return props.draft ? 'warning' : 'secondary';
});
</script>

<template>
  <BaseBadge
    v-tooltip="'Local drafts are only visible to you - commit them to your repo to share with others.'"
    class="resource-draft-indicator"
    data-testid="resource-draft-indicator"
    :variant="computedVariant"
    ><template v-if="draft" #leftIcon><span class="resource-draft-indicator__bullet">&bull;</span></template>
    <slot>Local draft</slot></BaseBadge
  >
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.resource-draft-indicator {
  $self: &;
  border-radius: 24px;
  padding: 3px var(--space-xxsmall);
  line-height: 16px;
  letter-spacing: 0.5px;
  cursor: default;
  color: var(--text-color-warning);
  font-size: var(--body-S);

  &__bullet {
    color: var(--color-border-warning);
    line-height: 1;
  }
}
</style>
