import {
  NOTIFICATION_TYPES,
  type NotificationDbItemInterface,
  type NotificationType,
  config,
  getLoggerNew,
} from '@swimm/shared';

import { addDocToCollection, collectionNames } from '@/adapters-common/firestore-wrapper';
import { NOTIFICATION_COMPONENTS } from './notification-components';

const logger = getLoggerNew(__modulename);

type NotificationComponentName = keyof typeof NOTIFICATION_COMPONENTS;

export const NOTIFICATION_TYPE_TO_COMPONENT: Record<NotificationType, NotificationComponentName> = {
  [NOTIFICATION_TYPES.JOIN_WORKSPACE]: 'JoinWorkspaceNotification',
  [NOTIFICATION_TYPES.ASSIGNMENT]: 'AssignmentNotification',
  [NOTIFICATION_TYPES.THANKS]: 'ThanksNotification',
  [NOTIFICATION_TYPES.SLACK_INSTALLED]: 'SlackInstalledNotification',
  [NOTIFICATION_TYPES.DOC_REQUEST_CREATED]: 'DocRequestCreatedNotification',
  [NOTIFICATION_TYPES.DOC_REQUEST_COMPLETED]: 'DocRequestCompletedNotification',
  [NOTIFICATION_TYPES.REQUESTED_JOIN_WORKSPACE]: 'RequestedJoinWorkspaceNotification',
  [NOTIFICATION_TYPES.DOC_UPDATED]: 'DocUpdatedNotification',
  [NOTIFICATION_TYPES.USER_JOINED_WORKSPACE]: 'UserJoinedWorkspaceNotification',
  [NOTIFICATION_TYPES.USER_LEFT_WORKSPACE]: 'UserLeftWorkspaceNotification',
  [NOTIFICATION_TYPES.USER_ACCEPTED_INVITE]: 'UserAcceptedInviteNotification',
  [NOTIFICATION_TYPES.PR_BREAK_CROSS_REPO]: 'PrBreaksCrossRepoNotification',
  [NOTIFICATION_TYPES.GITHUB_APP_SUSPENDED]: 'GitHubAppSuspensionNotification',
  [NOTIFICATION_TYPES.FIRST_DOC_VIEW]: 'FirstDocViewNotification',
  [NOTIFICATION_TYPES.MANY_DOC_VIEWS]: 'MultipleDocViewsNotification',
  [NOTIFICATION_TYPES.STIGG_CAP_REACHED]: 'StiggQuotaReached',
  [NOTIFICATION_TYPES.STIGG_CAP_90_REACHED]: 'StiggQuota90Reached',
  [NOTIFICATION_TYPES.SHARE_ASK_SWIMM_QUESTION]: 'ShareQuestiontNotification',
  [NOTIFICATION_TYPES.NOTIFY_SHARED_QUESTION_ANSWERED]: 'ShareQuestiontAnsweredNotification',
  [NOTIFICATION_TYPES.SIGNUP_EMAIL_PASSWORD_FAILED]: 'SignupWithEmailPasswordFailedNotification',
} as const;

export function getNotificationComponents() {
  return NOTIFICATION_COMPONENTS;
}

export function getComponentFromNotificationType(
  notificationType: NotificationType
): NotificationComponentName | 'div' {
  return NOTIFICATION_TYPE_TO_COMPONENT[notificationType] || 'div'; // Div is a safety net
}

export async function addNotification(notification: NotificationDbItemInterface) {
  const notificationResult = await addDocToCollection(collectionNames.NOTIFICATIONS, notification);
  if (notificationResult.code !== config.SUCCESS_RETURN_CODE) {
    throw new Error(`Failed to add notification ${notificationResult.errorMessage}`);
  }
  logger.debug(`Notification ${notificationResult.data.id} added`);
}
