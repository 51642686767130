<script setup lang="ts">
import { computed, ref } from 'vue';
import Checkbox from './Checkbox.vue';
import TextField from './TextField.vue';

const props = withDefaults(
  defineProps<{
    options: string[];
    display: (value: string) => string;
    modelValue: string[];
    showSearch: boolean;
    searchPlaceholder: string;
    searchByName: boolean;
    emptyStateText: string;
    identity: (option: string) => string;
  }>(),
  {
    display: (value: string) => value,
    showSearch: true,
    searchPlaceholder: '',
    searchByName: false,
    emptyStateText: '',
    identity: (option: string) => option,
  }
);

const emit = defineEmits(['update:model-value']);

const optionsQuery = ref('');
const isEqual = (o1: string, o2: string) => props.identity(o1) === props.identity(o2);
const isSelected = (option: string) => props.modelValue.some((element) => isEqual(option, element));
const updateOption = (option: string, isChecked: boolean) => {
  const selectedValues = props.modelValue.filter((element) => !isEqual(option, element));
  if (isChecked) {
    selectedValues.push(option);
  }
  emit('update:model-value', selectedValues);
};

const shouldShowOption = (option: string) => {
  if (!optionsQuery.value) {
    return true;
  }
  let term;
  if (props.searchByName) {
    term = props.display(option);
  } else {
    term = option;
  }
  return term.toLowerCase().includes(optionsQuery.value.toLocaleLowerCase());
};
const shouldShowSearch = computed(() => props.showSearch && props.options.length > 1);
const isEmpty = computed(() => !props.options || props.options.length === 0);

const shownOptions = computed(() => props.options.filter(shouldShowOption));
</script>

<template>
  <div class="search-options" v-if="shouldShowSearch">
    <TextField v-model="optionsQuery" :placeholder="searchPlaceholder" />
  </div>
  <div v-if="isEmpty" class="empty-state">{{ emptyStateText }}</div>
  <template v-else
    ><div v-for="option in shownOptions" :key="identity(option)" class="option-div">
      <Checkbox
        size="small"
        :model-value="isSelected(option)"
        @update:model-value="(isChecked: boolean) => updateOption(option, isChecked)"
        >{{ display(option) }}</Checkbox
      >
    </div>
  </template>
</template>

<style scoped>
.search-options {
  padding-top: 10px;
  padding-bottom: 10px;
  background: var(--color-bg);
  position: sticky;
  top: 0;
  z-index: 100;
}

.option-div {
  padding: 8px 16px;
}

.empty-state {
  padding: 8px 16px;
}

.option-div:hover {
  background: var(--color-hover);
}
</style>
