<template>
  <div class="link-container content-padding-wrapper">
    <div class="link-content">
      <component
        :is="linkTypes[type].component"
        v-if="linkTypes[type].component"
        :key="link.id"
        :url="linkUrl"
        @load-failed="loadFailed"
      />
      <a v-else :href="linkUrl" target="_blank" @click.prevent="openBrowser">{{ link.url }}</a>
    </div>
    <ToggleDoneButton
      @toggle-mark="toggleMark"
      :repo-id="$route.params.repoId"
      :resource-id="link.id"
      :is-video="linkTypes[type].isVideo"
    />
  </div>
</template>

<script>
import MarkdownFileReader from '@/common/components/Markdown/MarkdownFileReader.vue';
import WistiaPlayer from '@/common/components/Resolver/WistiaPlayer.vue';
import YoutubePlayer from '@/common/components/Resolver/YoutubePlayer.vue';
import { UrlUtils } from '@swimm/shared';
import { sanitizeUrl } from '@braintree/sanitize-url';
import ToggleDoneButton from '@/modules/playlists/components/ToggleDoneButton.vue';

export default {
  components: { YoutubePlayer, WistiaPlayer, MarkdownFileReader, ToggleDoneButton },
  props: {
    link: { type: Object, required: true },
  },
  emits: ['toggle-mark'],
  data() {
    return {
      linkTypes: {
        youtube: {
          component: 'YoutubePlayer',
          isVideo: true,
        },
        wistia: {
          component: 'WistiaPlayer',
          isVideo: true,
        },
        markdown: {
          component: 'MarkdownFileReader',
          isVideo: false,
        },
        default: {
          isVideo: false,
          component: null,
        },
      },
      disableAction: false,
    };
  },
  computed: {
    type() {
      return UrlUtils.getLinkType(this.link.url);
    },
    linkUrl() {
      return sanitizeUrl(this.link.url);
    },
  },
  methods: {
    async toggleMark(status) {
      this.$emit('toggle-mark', status);
    },
    openBrowser() {
      // When external link for website should be shown "as is" - we need to add https:// and open them with the local browser instead of electron
      const outsideLink = this.link.url.match(/^http(s)?:/) ? this.link.url : 'https://' + this.link.url;
      window.open(outsideLink, '_blank');
    },
    loadFailed() {
      this.disableAction = true;
    },
  },
};
</script>

<style scoped>
.link-container {
  margin: 0 auto;
  padding-bottom: 70px;
  max-width: var(--narrow-content-width);
}

.link-content {
  margin: 50px 0;
}

a {
  color: var(--text-color-link);
}

a:hover {
  text-decoration: underline;
}
</style>
