import { addDocContribution } from '@/modules/doc-contributions/services/doc-contributors-utils';
import { useStore } from 'vuex';
import * as firestore from '@/adapters-common/firestore-wrapper';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { config } from '@swimm/shared';

export function useSaveDocContributions() {
  const store = useStore();
  const route = useRoute();

  const { user } = storeToRefs(useAuthStore());
  const setDocSubCollectionData = (args) => store.dispatch('database/setDocSubCollectionData', args);
  const refreshDocSubCollections = (args) => store.dispatch('database/refreshDocSubCollections', args);

  function swimmFromDb(unitId) {
    return store.getters['database/db_getSwimm'](route.params.repoId, unitId);
  }

  async function saveContributions(
    savedSwimms: {
      id: string;
      type: (typeof config.SWIMM_FILE_TYPES)[keyof typeof config.SWIMM_FILE_TYPES];
      isNew?: boolean;
    }[],
    prBranch: string,
    shouldOpenPR: boolean,
    shouldCreateBranch: boolean
  ): Promise<void> {
    await Promise.all(
      savedSwimms.map((swimm) => {
        if (swimm.type !== config.SWIMM_FILE_TYPES.PLAYLIST) {
          addDocContribution({
            user: user.value,
            repoId: route.params.repoId as string,
            docId: swimm.id,
            method: shouldOpenPR ? 'pr' : shouldCreateBranch ? 'remote-branch' : 'commit',
            branch: prBranch,
          });
          setDocSubCollectionData({
            repoId: route.params.repoId,
            unitId: swimm.id,
            collection: firestore.collectionNames.CONTRIBUTORS,
            data: {
              name: user.value.nickname,
              modified: firestore.firestoreTimestampNow(),
              user_id: user.value.uid,
              is_creator: swimm.isNew || swimmFromDb(swimm.id).creator === user.value.uid,
            },
          });
          refreshDocSubCollections({
            repoId: route.params.repoId,
            unitId: swimm.id,
            collectionsToRefresh: [firestore.collectionNames.CONTRIBUTORS],
          });
        }
      })
    );
  }

  return { saveContributions };
}
