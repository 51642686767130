import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core';

function transformYoutubeLinks(state: StateCore): void {
  const blockTokens = state.tokens;

  for (let i = 1; i < blockTokens.length - 1; i++) {
    if (
      blockTokens[i].type !== 'inline' ||
      blockTokens[i - 1].type !== 'paragraph_open' ||
      blockTokens[i + 1].type !== 'paragraph_close'
    ) {
      continue;
    }

    const tokens = blockTokens[i].children ?? [];

    if (
      tokens[0].type !== 'link_open' ||
      tokens[1].type !== 'text' ||
      tokens[2].type !== 'link_close' ||
      !isValidYoutubeUrl(tokens[0].attrGet('href') ?? '')
    ) {
      continue;
    }

    blockTokens[i].type = 'youtube';
    blockTokens[i].attrs = tokens[0].attrs;
    blockTokens[i].children = null;

    // Delete the paragraph_open and paragraph_close tokens to transform the
    // link token to a block level youtube token that lives at the same level as
    // paragraphs
    blockTokens.splice(i + 1, 1);
    blockTokens.splice(i - 1, 1);
    i--;
  }
}

export default function youtube(md: MarkdownIt): void {
  md.core.ruler.push('youtube', transformYoutubeLinks);
}

// From https://github.com/ueberdosis/tiptap/blob/92688f1b16238cdd1081a9e19d9f52909ccec037/packages/extension-youtube/src/utils.ts#L1-L6
export const YOUTUBE_REGEX = /^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)(?!.*\/channel\/)(?!\/@)(.+)?$/;

export const isValidYoutubeUrl = (url: string) => {
  return url.match(YOUTUBE_REGEX);
};
