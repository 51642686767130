/* eslint-disable no-console */

class DebugTimer {
  name: string;
  parent?: DebugTimer;
  children: DebugTimer[] = [];
  startTime: number;
  endTime: number;
  constructor(name: string, parent: DebugTimer = null) {
    this.name = name;
    this.parent = parent;
    this.startTime = new Date().getTime();
    // Console.info(`Created timer "${name}" for parent "${(this.parent && this.parent.name) || '-'}"`);
  }
  get duration() {
    return this.endTime - this.startTime;
  }
  addChild(childName: string) {
    const child = new DebugTimer(childName, this);
    this.children.push(child);
    return child;
  }
  endAndGetParent() {
    this.endTime = new Date().getTime();
    console.info(`Timer "${this.name}" ended: ${this.duration} ms`);
    return this.parent;
  }
  print(indentation = 0, totalDuration?: number) {
    if (!indentation) {
      console.info('Timers summary:');
    }
    if (typeof totalDuration !== 'number') {
      totalDuration = this.duration;
    }
    const percentage = !totalDuration ? 0 : ((this.duration / totalDuration) * 100).toFixed(1);
    console.info(`${'|'.padEnd(indentation + 4)}${this.name}: ${this.duration} ms  --  ${percentage} %`);
    for (const child of this.children) {
      child.print(indentation + 4, totalDuration);
    }
  }
}

export class Perf {
  static activeTimer: DebugTimer = null;
  static time(timerName): void {
    if (this.activeTimer) {
      this.activeTimer = this.activeTimer.addChild(timerName);
    } else {
      this.activeTimer = new DebugTimer(timerName);
    }
  }
  static timeEnd(timerName): void {
    if (!this.activeTimer) {
      console.warn(`Cannot end timer "${timerName}", no timer is currently active`);
      return;
    }
    if (this.activeTimer.name !== timerName) {
      console.warn(`Cannot end timer "${timerName}, it is not the currently active timer`);
      return;
    }
    const endingTimer = this.activeTimer;
    this.activeTimer = this.activeTimer.endAndGetParent();
    if (!this.activeTimer) {
      endingTimer.print();
    }
  }
  static wrap(timerName, func) {
    this.time(timerName);
    try {
      func();
    } finally {
      this.timeEnd(timerName);
    }
  }
  static async wrapAsync(timerName, func) {
    this.time(timerName);
    try {
      await func();
    } finally {
      this.timeEnd(timerName);
    }
  }
}

const DURATION_WARNING_THRESHOLD = 200;
const DURATION_ERROR_THRESHOLD = 500;
let performanceTimestampOffset = 0;

export function setAppStartTimestamp() {
  performanceTimestampOffset = performance.now();
  console.log(`Performance timestamp offset: ${Math.round(performanceTimestampOffset)}`);
}

export function performanceStart(markName: string) {
  console.log(
    `%c############ START: ${markName} at ${Math.round(performance.now() - performanceTimestampOffset)}`,
    'color: blue'
  );
  performance.mark(markName);
  return markName;
}

export function performanceEnd(markName: string) {
  const measure = performance.measure(`${markName}_measure`, markName);
  const durationColor =
    measure.duration < DURATION_WARNING_THRESHOLD
      ? 'color: green'
      : measure.duration < DURATION_ERROR_THRESHOLD
      ? 'color: goldenrod'
      : 'color: red';
  console.log(
    `%c############ END: %c${markName}%c took %c${Math.round(measure.duration)}%c (finished at ${Math.round(
      measure.startTime + measure.duration - performanceTimestampOffset
    )})`,
    'color: green',
    durationColor,
    'color: green',
    durationColor,
    'color: green'
  );
}

export async function performanceWrap(name: string, func: () => Promise<unknown>, silenced?: boolean) {
  const mark = silenced ? null : performanceStart(name);
  try {
    return await func();
  } finally {
    if (!silenced) {
      performanceEnd(mark);
    }
  }
}
