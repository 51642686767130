<script setup lang="ts">
import type { Folder } from '../types';
import { SwText } from '@swimm/ui';
import { computed } from 'vue';
import { CreationHubSections } from '@/modules/core/creation-hub/CreationHubConsts';
import FolderAdditionOptions from '@/modules/folders/components/FolderAdditionOptions.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';
import { useFoldersStore } from '@/modules/folders/store/folders';

const props = defineProps({
  folders: { type: Array<Folder>, required: true },
  repoId: { type: String, required: true },
});

const emit = defineEmits(['crumb-selected', 'add-folder', 'navigate-to-item', 'toggle-creation-hub']);

const analytics = useAnalytics();
const { getFolderPath } = useFoldersStore();

const folderId = computed(() => {
  if (props.folders && props.folders.length > 0) {
    return props.folders[props.folders.length - 1].id;
  }
  return null;
});

function isNotLastFolderIndex(index: number): boolean {
  return index < props.folders.length - 1;
}

function clickedAddToFolder() {
  analytics.track(productEvents.OPENED_FOLDER_ADD_MENU_ITEM, {
    'Folder ID': folderId.value,
    'Folder Path': getFolderPath(folderId.value, props.repoId),
    'In Breadcrumbs': true,
  });
}
</script>

<template>
  <div class="folder-crumbs">
    <div v-for="(folder, index) in folders" :key="folder.id" class="crumb">
      <SwText
        class="name"
        v-tooltip="folder.name"
        data-testid="folder-crumb"
        :class="{ clickable: isNotLastFolderIndex(index) }"
        @click="emit('crumb-selected', folder.id)"
        >{{ folder.is_root ? 'All docs (root)' : folder.name }}</SwText
      >
      <SwText>/</SwText>
    </div>
    <VDropdown class="menu" :triggers="['click']" theme="menu-no-arrow">
      <template #default>
        <Action
          class="add-button"
          secondary
          size="small"
          no-padding
          data-testid="add-in-folder-button"
          @click="clickedAddToFolder"
        >
          <Icon name="add" />
        </Action>
      </template>
      <template #popper>
        <FolderAdditionOptions
          :folder-id="folderId"
          :repo-id="repoId"
          @add-folder="emit('add-folder', folderId)"
          @add-doc="$emit('navigate-to-item', { query: { folderId }, isPlaylist: false })"
          @add-playlist="$emit('navigate-to-item', { query: { folderId }, isPlaylist: true })"
          @add-template="$emit('toggle-creation-hub', { creationHubSection: CreationHubSections.TEMPLATES, folderId })"
        />
      </template>
    </VDropdown>
  </div>
</template>

<style scoped lang="postcss">
.folder-crumbs {
  display: flex;
  flex-direction: row;
  align-items: center;

  .crumb {
    display: flex;
    overflow: hidden;

    .name {
      padding: 0 var(--space-base);
      color: var(--text-color-link);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:nth-last-child(2) .name {
      color: var(--text-color-secondary);
    }
  }

  .add-button {
    margin-left: var(--space-base);
  }
}
</style>
