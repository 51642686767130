import { getStateInstance } from 'MixedLibs/local-state-instance';
import * as config from './config';
import LocalState from './local-state';

class LocalStateApp extends LocalState {
  constructor() {
    let initializedLocalStateApp = config.isWebApp
      ? getStateInstance()
      : getStateInstance(config.PROJECT_NAME, config.LOCAL_STATE_FILE_NAME, config.LOCAL_CONFIGS_PATH);
    super(initializedLocalStateApp);
  }
}

export default new LocalStateApp();
