import { ref } from 'vue';
import { copyToClipboardImpl } from './utils';

export function useCopyToClipboard() {
  const copiedToClipboard = ref(false);
  const copiedTimeout = ref(2000);
  function copyToClipboard(str: string) {
    copyToClipboardImpl(str);
    copiedToClipboard.value = true;
    if (copiedTimeout.value) {
      // Used for showing and hiding tooltip on copy
      setTimeout(() => (copiedToClipboard.value = false), copiedTimeout.value);
    }
  }

  return { copiedToClipboard, copyToClipboard };
}
