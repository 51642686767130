<template>
  <VDropdown
    v-if="repoId"
    placement="bottom-end"
    @show="reportOpenedPopup"
    :shown="shouldOpenCommitModal"
    data-testid="batch-commit-btn"
    role="button"
    :aria-disabled="!isSavable"
    @hide="onHide"
    :class="['batch-button', { savable: isSavable }]"
  >
    <div class="button-wrapper">
      <BatchCommitSVG v-if="draftCount" class="change-icon" data-testid="batch-commit-btn-changes" />
      <Icon v-else name="branch-file-change" class="change-icon" no-padding />
      <SwText class="text" variant="subtitle-L" weight="bolder">
        Commit
        <div class="counter" data-testid="batch-commit-btn-draft-count" :class="{ 'no-changes': !draftCount }">
          {{ draftCount }}
        </div>
        <Icon name="expanded" no-padding />
      </SwText>
    </div>
    <template #popper="popperProps">
      <slot v-bind="popperProps"></slot>
    </template>
  </VDropdown>
</template>

<script setup lang="ts">
import { SwText } from '@swimm/ui';
import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';
import { useGetStartedMenu } from '@/modules/get-started/composables/useGetStartedMenu';
import { storeToRefs } from 'pinia';
import BatchCommitSVG from './BatchCommitSVG.vue';

const props = defineProps({
  repoId: { type: String, required: true },
  draftCount: { type: Number, required: true },
  savableDraftCount: { type: Number, required: true },
});

const emit = defineEmits(['show-batch-modal']);

const analytics = useAnalytics();
const getStartedStore = useGetStartedMenu();
const { shouldOpenCommitModal } = storeToRefs(getStartedStore);

const isBatchCommitModalShown = ref(false);

const isSavable: ComputedRef<boolean> = computed(() => props.draftCount > 0);

function reportOpenedPopup() {
  analytics.track(productEvents.OPENED_BATCH_COMMIT_POPUP, {
    'Savable Changes': props.savableDraftCount,
    'Total Change Count': props.draftCount,
  });
  isBatchCommitModalShown.value = true;
  emit('show-batch-modal');
}

function onHide() {
  isBatchCommitModalShown.value = false;
  shouldOpenCommitModal.value = false;
}
</script>

<style scoped lang="postcss">
.batch-button {
  height: 32px;
  display: flex;
  align-items: center;
  background: var(--color-bg);
  border: 1px solid var(--color-border-default-strong);
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: var(--color-hover);
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    padding: var(--space-xs) var(--space-sm);
  }

  .text {
    display: flex;
    font-size: var(--body-S);
    align-items: center;
    gap: var(--space-xs);
    font-weight: 900;
    color: var(--text-color-secondary);
    justify-content: center;
  }

  .counter {
    font-size: var(--body-S);
    line-height: 16px;
    height: 16px;
    font-weight: 900;
    color: var(--text-color-secondary);
    padding: 2px var(--space-base);
    background: var(--color-surface);
    border-radius: 10px;
  }

  .change-icon {
    font-size: var(--headline2);
    color: var(--text-color-secondary);
    margin-right: var(--space-xs);
    font-weight: normal;
  }

  &.savable .counter {
    background: var(--warning-yellow-400);
    color: var(--text-color-on-light);
  }
}
</style>
