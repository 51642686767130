<script setup lang="ts">
import * as _ from 'lodash-es';
import type { PropType } from 'vue';
import { computed } from 'vue';
import { DocumentationTypes } from '@/common/consts';
import type { DocumentationType, DraftState } from '@/modules/core/types';
import ChangesDocumentationIconSVG from './ChangesDocumentationIconSVG.vue';

const props = defineProps({
  type: { type: String as PropType<DocumentationType>, required: true },
  state: { type: String as PropType<DraftState>, default: '' },
  size: { type: String, default: '24' },
  error: { type: Boolean, default: false },
});

const iconName = computed(() => {
  if (props.state) {
    const stateIconMapping = {
      [DocumentationTypes.DOC]: `doc-${props.state}`,
      [DocumentationTypes.PLAYLIST]: `playlist-${props.state}`,
    };
    return stateIconMapping[props.type];
  }

  const iconMapping = {
    [DocumentationTypes.DOC]: 'doc',
    [DocumentationTypes.PLAYLIST]: 'playlist',
  };
  return iconMapping[props.type];
});

const tooltip = computed(() => {
  const type = props.type === DocumentationTypes.PLAYLIST ? 'playlist' : 'doc';
  if (props.error) {
    return `${_.capitalize(type)} has a problem`;
  }
  if (props.state === 'modified') {
    return `${_.capitalize(type)} was modified`;
  }
  return `New ${type}`;
});
</script>

<template>
  <ChangesDocumentationIconSVG
    v-if="state"
    class="documentation-icon"
    :size="size"
    :state="state"
    :type="type"
    :error="error"
    v-tooltip="tooltip"
  />
  <Icon v-else class="documentation-icon" :name="iconName" no-padding />
</template>

<style scoped lang="postcss">
.documentation-icon {
  position: relative;
  font-size: var(--headline2);
  margin-right: var(--space-xs);
  display: flex;
  align-items: center;
}
</style>
