import { Fragment, Slice } from '@tiptap/pm/model';
import { Transform } from '@tiptap/pm/transform';
import { stripSwmElementsFromMarkdown } from './swmdToMarkdown';

export async function nodesToMarkdown(
  nodes: Slice,
  { repoId, workspaceId, baseUrl }: { repoId: string; workspaceId: string; baseUrl: string }
): Promise<string> {
  const { serializeSwmdContent } = await import('./serializer');
  const { schema } = await import('./extensions');

  const doc = schema.topNodeType.createAndFill();

  if (doc != null) {
    const tr = new Transform(doc);
    tr.replaceWith(0, tr.doc.content.size, Fragment.fromJSON(schema, nodes.content.toJSON()));

    // Remove all Swimm-specific nodes from the document as GPT doesn't handle them well (it tries to auto-complete the textual tags..)
    const cleanMarkdown = stripSwmElementsFromMarkdown({ slicedDocument: tr.doc, schema });

    return serializeSwmdContent(cleanMarkdown, {
      baseUrl,
      workspaceId,
      repoId,
    }).replaceAll('&nbsp;', ' '); // GPT seems to not handle nbsp; correctly (not returning a response), replacing with actual whitespace
  }
  return '';
}
