<template>
  <Loader />
</template>
<script>
import { getLoggerNew } from '@swimm/shared';
import { useSignout } from '../composables/signout';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';

const logger = getLoggerNew(__modulename);

export default {
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const { signout } = useSignout();

    return { user, signout };
  },
  async mounted() {
    if (this.user) {
      await this.signout();
    } else {
      logger.debug('User already logged out. Redirecting to login page...');
      await this.$router.push('/login');
    }
  },
};
</script>
