import { defineStore, storeToRefs } from 'pinia';
import { STORES } from '@/modules/core/stores/store-constants';
import { computed, ref, watch } from 'vue';
import { eventLogger, pageEvents } from '@swimm/shared';
import { useRoute } from 'vue-router';
import { useAnalytics } from '@/common/composables/useAnalytics';
import type { Playlist, PlaylistProgressBarStep, PlaylistSequenceStep } from '../types';
import { useRouting } from '@/common/composables/routing';
import { useInitData } from '@/common/composables/initData';
import { usePlaylist } from '@/modules/playlists/composables/playlist';
import { useStore } from 'vuex';
import { useRepoSwitcher } from '@/common/composables/repoSwitcher';
import { useNotificationsStore } from '@swimm/editor';
import { measurePerformance } from '@/common/utils/sentry-measurements';
import { useAuthStore } from '@/modules/core/stores/auth-store';

export interface RepoLoadingStatus {
  [repoId: string]: { available: boolean; loading: boolean };
}

export const usePlaylistStore = defineStore(STORES.PLAYLIST, () => {
  const store = useStore();
  const route = useRoute();
  const analytics = useAnalytics();
  const { assertRouting, getCurrentOrDefaultBranch } = useRouting();
  const { connectToRepo } = useRepoSwitcher();
  const { setPlaylistData } = useInitData();
  const { addNotification } = useNotificationsStore();
  const { playlistId, parseDBPlaylist, loadStepsInBackground, selectStep, parseSequenceStepsForList } = usePlaylist();

  const { user } = storeToRefs(useAuthStore());
  const repoId = computed(() => route.params.repoId as string);
  const workspaceId = computed(() => route.params.workspaceId as string);
  const branch = computed(() => route.params.branch as string);
  const currentStepIndex = computed(() => route.params.stepIndex as string);
  const isEditMode = computed(() => route.path.includes('/edit'));

  const playlistUnavailable = ref(false);
  const loadingPlaylistData = ref(true);
  const currentPlaylist = ref<Playlist>({} as Playlist);
  const currentStep = ref<PlaylistSequenceStep>(null);
  const reposLoadingStatuses = ref<RepoLoadingStatus>({});

  const fs_isPlaylistExist = computed(() => store.getters['filesystem/fs_isPlaylistExist']);
  const fs_getPlaylistFromLocalRepo = computed(() => store.getters['filesystem/fs_getPlaylistFromLocalRepo']);

  const setOnlyDBPlaylistToFilesystem = (args?) => store.dispatch('filesystem/setOnlyDBPlaylistToFilesystem', args);
  const getRepoSwmsLists = (args?) => store.dispatch('filesystem/getRepoSwmsLists', args);
  const fetchRepository = (args?) => store.dispatch('database/fetchRepository', args);
  const fetchAllWorkspaceRepos = (args?) => store.dispatch('database/fetchAllWorkspaceRepos', args);
  const fetchRepoChildren = (args?) => store.dispatch('database/fetchRepoChildren', args);
  const fetchSwimmerStatus = (args?) => store.dispatch('database/fetchSwimmerStatus', args);

  const progressBarSteps = computed<PlaylistProgressBarStep[]>(() => {
    if (loadingPlaylistData.value || playlistUnavailable.value) {
      return [];
    }

    return parseSequenceStepsForList({ playlist: currentPlaylist.value, isEdit: false });
  });

  watch(
    [playlistId, branch],
    async () => {
      resetStoreState();
      if (playlistId.value != null) {
        await loadPlaylist();
      }
    },
    { immediate: true }
  );

  watch(
    route,
    () => {
      if (currentPlaylist.value && currentPlaylist.value.id === playlistId.value) {
        assertPlaylistStepRouting(currentPlaylist.value);
      }
    },
    {
      immediate: true,
      deep: true,
    }
  );

  watch(isEditMode, async (newVal, oldValue) => {
    if (!newVal && oldValue) {
      resetStoreState();
      if (playlistId.value != null) {
        // reload when moving from edit to view
        await loadPlaylist();
      }
    }
  });

  function resetStoreState() {
    playlistUnavailable.value = false;
    loadingPlaylistData.value = true;
    currentPlaylist.value = {} as Playlist;
    currentStep.value = null;
    reposLoadingStatuses.value = {};
  }

  async function loadPlaylist() {
    await measurePerformance({ name: 'playlist-init' }, async () => {
      await initPlaylistData();
    });
    // When the user routs out of the playlist playlistId becomes undefined and this function is called
    // Do not log events in this case
    if (playlistId.value) {
      analytics.cloudPageViewed({
        identity: user.value.uid,
        event: pageEvents.VIEW_PLAYLIST,
        payload: {
          'Playlist ID': currentPlaylist.value.id,
          'Playlist Name': currentPlaylist.value.name,
          'Workspace ID': workspaceId.value,
          'Repo ID': repoId.value,
          Branch: branch.value,
        },
        backofficeCode: eventLogger.SWIMM_EVENTS.PLAYLIST_VIEWED.code,
      });
    }
  }

  async function initPlaylistData() {
    const assertResult = await assertRouting();
    if (!assertResult) {
      return;
    }
    reposLoadingStatuses.value[repoId.value] = { loading: false, available: true };
    // Get playlist from filesystem
    await setPlaylistData({
      playlistId: playlistId.value,
      repoId: repoId.value,
    });

    if (!fs_isPlaylistExist.value(playlistId.value, repoId.value)) {
      const dbPlaylist = await parseDBPlaylist();
      if (!dbPlaylist) {
        playlistUnavailable.value = true;
        loadingPlaylistData.value = false;
        return;
      }
      setOnlyDBPlaylistToFilesystem({
        playlistId: playlistId.value,
        repoId: repoId.value,
        content: { ...dbPlaylist },
      });
    }

    const fs_playlist = fs_getPlaylistFromLocalRepo.value(playlistId.value, repoId.value);
    currentPlaylist.value = { ...fs_playlist };
    await loadStepsAndRepos(fs_playlist);
    assertPlaylistStepRouting(fs_playlist);
    loadingPlaylistData.value = false;
    loadStepsInBackground(fs_playlist);
  }

  async function loadStepsAndRepos({ sequence }: Playlist) {
    await store.dispatch('database/fetchRepoChildren', { repoId: repoId.value, children: ['swimms', 'playlists'] });
    await fetchAllWorkspaceRepos();
    const repoIds = [];
    for (const step of sequence) {
      if (repoIds.indexOf(step.repoId) === -1 && step.repoId !== repoId.value) {
        repoIds.push(step.repoId);
        reposLoadingStatuses.value[step.repoId] = {
          available: false,
          loading: true,
        };
      }
    }
    repoIds.forEach(async (sequenceRepoId) => {
      // Database
      await fetchRepository({ repoId: sequenceRepoId });
      await fetchRepoChildren({ repoId: sequenceRepoId, children: ['swimms', 'playlists'] });
      await fetchSwimmerStatus({ repoId: sequenceRepoId, userId: user.value.uid });
      // Filesystem
      await getRepoSwmsLists({ repoId: sequenceRepoId, branch: await getCurrentOrDefaultBranch(sequenceRepoId) });
      await connectToRepo({ repoId: sequenceRepoId, alertError: true });
      reposLoadingStatuses.value[sequenceRepoId] = {
        available: true,
        loading: false,
      };
    });
  }

  function assertPlaylistStepRouting({ sequence }: Playlist) {
    if (!sequence) {
      return;
    }
    if (currentStepIndex.value) {
      const step = sequence[currentStepIndex.value];
      if (!step) {
        selectStep({ id: 'intro' });
        addNotification(`This step is not part of this playlist`);
        return;
      }
    }
  }

  return {
    isEditMode,
    currentPlaylist,
    currentStep,
    currentStepIndex,
    loadingPlaylistData,
    playlistUnavailable,
    reposLoadingStatuses,
    progressBarSteps,
    initPlaylistData,
  };
});
