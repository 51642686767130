import { defineStore, storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { computed } from 'vue';
import store from '@/store';
import { STORES } from '@/modules/core/stores/store-constants';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';

export const useUserStore = defineStore(STORES.USER, () => {
  const { user } = storeToRefs(useAuthStore());
  const { id: workspaceId } = storeToRefs(useWorkspaceStore());

  const uid = computed(() => user.value.uid);
  const displayName = computed(() => user.value.displayName);

  const isUserWorkspaceAdmin = computed(() =>
    store.getters['database/db_isWorkspaceAdmin'](workspaceId.value, uid.value)
  );

  return {
    uid,
    displayName,
    isUserWorkspaceAdmin,
  };
});
