import { type Ref, ref, watch } from 'vue';
import { until } from '@vueuse/core';
import { isEqual } from 'lodash-es';

import type { TokenSuggestion } from '@swimm/shared';
import type { CachedHighlighters } from '../../types';
import type { Theme } from 'shiki';

import { getTheme } from '../../lib/theme';
import { getLanguageFromPath } from '../../lib/languages';
import { useShiki } from '../../lib/shiki';

export function useTokenSuggestionCachedHighlighters(tokenSuggestions: Ref<TokenSuggestion[]>) {
  const cachedTokenSuggestions = ref<TokenSuggestion[]>([]);
  const useTheme = ref<Theme>(getTheme() === 'dark' ? 'github-dark' : 'github-light');
  const cachedHighlighters = ref<CachedHighlighters>({});
  const isCaching = ref(false);
  const isCachingMore = ref(false);

  const { shiki, shikiIsReady } = useShiki();

  async function preloadCachedHighlighters() {
    await until(shikiIsReady).toBeTruthy();

    for (const token of tokenSuggestions.value) {
      if (!token?.position?.path) {
        break;
      }

      const language = getLanguageFromPath(token.position.path);
      const theme = useTheme.value;
      const key = `${language}-${theme}`;

      if (!cachedHighlighters.value[key] && language && theme) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        cachedHighlighters.value[key] = await shiki.value!.getHighlighter({ theme: theme, langs: [language] });
      }
    }

    cachedTokenSuggestions.value = tokenSuggestions.value;
  }

  // Trigger preloading of cached highlighters and
  // dermine if we're caching or caching more (because show more has been clicked).
  // The differentiation here effects the loading state.
  watch(
    () => tokenSuggestions.value,
    async (newTokenSuggestions, oldTokenSuggestions) => {
      if (
        oldTokenSuggestions &&
        newTokenSuggestions.length > oldTokenSuggestions.length &&
        isEqual(newTokenSuggestions.slice(0, oldTokenSuggestions.length), oldTokenSuggestions)
      ) {
        isCachingMore.value = true;
        await preloadCachedHighlighters();
        isCachingMore.value = false;
      } else {
        isCaching.value = true;
        await preloadCachedHighlighters();
        isCaching.value = false;
      }
    },
    {
      immediate: true,
    }
  );

  return { cachedHighlighters, cachedTokenSuggestions, isCaching, isCachingMore };
}
