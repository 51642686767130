// TODO Move this from the component? WTF IS SuggestionsMenuFolder?!1
import type { SlashCommand } from '../types';
import { isMac } from '@swimm/shared';

export const draggableMenuOptions = {
  'Anchor Link': 'Copy anchor link',
};
export const SwimmCommands: { [name: string]: SlashCommand } = {};

export const StylingCommands: { [name: string]: SlashCommand } = {
  PASTE_MARKDOWN: {
    title: 'Paste as markdown',
    name: 'paste markdown',
    icon: 'markdown',
    description: 'Paste as markdown',
    shortcut: `${isMac() ? 'Cmd' : 'Ctrl'}+${isMac() ? '⌥' : 'Alt'}+V`,
    category: 'Styling',
  },
  PASTE_PLAIN: {
    title: 'Paste as plain text',
    name: 'paste plain',
    icon: 'copy-paste',
    description: 'Paste as plain text',
    shortcut: `${isMac() ? 'Cmd' : 'Ctrl'}+Shift+V`,
    category: 'Styling',
  },
  HEADING1: {
    title: 'Heading 1',
    name: 'heading 1',
    icon: 'text-headline1',
    description: 'Headline 1',
    shortcut: '#',
    aliases: ['h1'],
    category: 'Styling',
  },
  HEADING2: {
    title: 'Heading 2',
    name: 'heading 2',
    icon: 'text-headline2',
    description: 'Headline 2',
    shortcut: '##',
    aliases: ['h2'],
    category: 'Styling',
  },
  HEADING3: {
    title: 'Heading 3',
    name: 'heading 3',
    icon: 'text-headline3',
    description: 'Headline 3',
    shortcut: '###',
    aliases: ['h3'],
    category: 'Styling',
  },
  BULLET_LIST: {
    title: 'Bullet list',
    name: 'bullet List',
    icon: 'list-view',
    description: 'Creates a bullet list.',
    shortcut: '*',
    category: 'Styling',
  },
  ORDERED_LIST: {
    title: 'Ordered list',
    name: 'ordered List',
    icon: 'ol',
    description: 'Creates an ordered list.',
    shortcut: '1.',
    category: 'Styling',
  },
  QUOTES: {
    title: 'Quotes',
    name: 'quotes',
    icon: 'quote',
    description: 'Creates a block quote.',
    shortcut: '>',
    category: 'Styling',
  },
  CODEBLOCK: {
    title: 'Code block',
    name: 'codeblock',
    icon: 'codeblock',
    description: 'Creates a code block.',
    shortcut: '```',
    category: 'Styling',
  },
};
