<script setup lang="ts">
import ReviewAutosyncNode from './ReviewAutosyncNode.vue';
import {
  ApplicabilityStatus,
  SmartElementWithApplicabilityAndNewInfo,
  Token,
  isSmartElementWithNewInfo,
} from '@swimm/shared';
import { useReviewAutosyncFunctions } from '../../composables/useReviewAutosyncFunctions';
import { SmartElementType } from '@swimm/shared';
import { type NeedReviewItem } from '@/modules/doc-sidebar/types';

interface ReviewAutosyncPartProps {
  nodeItem: NeedReviewItem;
  isAutosyncable: boolean;
}
defineProps<ReviewAutosyncPartProps>();

const { reportNodeScrolled } = useReviewAutosyncFunctions();

const emit = defineEmits(['delete-outdated', 'accept-autosync', 'node-clicked']);

function handleNodeClicked(position: number) {
  emit('node-clicked', position);
  reportNodeScrolled();
}

function getPrefixByNode(node: NeedReviewItem) {
  switch (node.type) {
    case SmartElementType.SNIPPET: {
      return `:${node.startLineNumber}-${node.lines.length + node.startLineNumber}`;
    }

    case SmartElementType.TOKEN: {
      return ` ${node.lineNumber}:${node.wordIndex.start}`;
    }

    case SmartElementType.PATH: {
      return '../../';
    }

    default: {
      return '';
    }
  }
}

function getDisplayText(node: NeedReviewItem) {
  switch (node.type) {
    case SmartElementType.SNIPPET: {
      return node.applicability === ApplicabilityStatus.Autosyncable && isSmartElementWithNewInfo(node)
        ? node.newInfo.filePath
        : node.filePath;
    }

    case SmartElementType.TOKEN:
      return node.applicability === ApplicabilityStatus.Autosyncable && isSmartElementWithNewInfo(node)
        ? (node as SmartElementWithApplicabilityAndNewInfo<Token>).newInfo.symbolText
        : node.symbolText;

    case SmartElementType.PATH:
      return node.applicability === ApplicabilityStatus.Autosyncable && isSmartElementWithNewInfo(node)
        ? node.newInfo.filePath
        : node.filePath;

    case SmartElementType.LINK:
      return node.docTitle;

    default:
      return '';
  }
}

const typeIcons = {
  [SmartElementType.SNIPPET]: 'codeblock',
  [SmartElementType.TOKEN]: 'codeblock',
  [SmartElementType.PATH]: 'file',
  [SmartElementType.LINK]: 'doc',
};

function handleActionClicked(nodeItem: NeedReviewItem) {
  if (nodeItem.applicability === ApplicabilityStatus.Outdated) {
    emit('delete-outdated', nodeItem);
  } else {
    if (nodeItem.type === SmartElementType.LINK) {
      // SwimmLink node doesn't support acceptance, only deletion
      return;
    }
    emit('accept-autosync', nodeItem);
  }
}
</script>

<template>
  <div class="node" v-for="(pos, index) in nodeItem.pos" :key="`${nodeItem.id}-${index}`">
    <ReviewAutosyncNode
      :is-first="index === 0"
      :is-autosyncable="nodeItem.applicability === ApplicabilityStatus.Autosyncable"
      :id="nodeItem.id"
      :icon-name="typeIcons[nodeItem.type]"
      :type="nodeItem.type"
      @action="handleActionClicked(nodeItem)"
      @node-clicked="handleNodeClicked(pos)"
      :name="getDisplayText(nodeItem)"
      :prefix="index < 0 ? '' : getPrefixByNode(nodeItem)"
    ></ReviewAutosyncNode>
  </div>
</template>

<style scoped>
.node {
  flex-direction: column;
  display: flex;
  height: 100%;
}
</style>
