import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { SwmSymbols } from '@swimm/shared';

export interface EditOptsInterface {
  hideEditNonApplicableSnippet: boolean;
  isNewUnit: boolean;
  hasChangesInWorkspace: boolean;
}

export interface NodeState {
  [uid: string]: MermaidNodeState;
}

export interface MermaidNodeState {
  showEditor: boolean;
}
export interface HunkCodeLensData {
  path: string;
  lineNumber: number;
}

export const useEditDocStateStore = defineStore('editDocState', () => {
  const verifying = ref<boolean>(false);
  const editOpts = ref<EditOptsInterface | null>(null);
  const isOnboarding = ref<boolean>(false);
  const isDragInProgress = ref<boolean>(false);
  const isPasting = ref<boolean>(false);
  const branch = ref<string>('');
  const repoId = ref<string>('');

  return {
    verifying,
    editOpts,
    isOnboarding,
    branch,
    isDragInProgress,
    repoId,
    isPasting,
  };
});

export interface TipTapEditorState {
  firstApplicableHunkPos: number;
  symbols: SwmSymbols | undefined;
  nodesMetaData: NodeState;
}

export const useTiptapEditorStateStore = defineStore('tiptapEditorState', () => {
  // We use one global aggregatedSymbols for all editors, to allow copy pasting/dragging between editors.
  const aggregatedSymbols = ref<SwmSymbols>({});
  const nodesMetaData = ref<NodeState>({});
  const editorIdToState = ref<{ [editorId: string]: TipTapEditorState }>({});
  const snippetToFocusOn = ref<HunkCodeLensData | undefined>();

  const getEditorState = (editorId: string): TipTapEditorState => {
    if (!editorIdToState.value[editorId]) {
      editorIdToState.value[editorId] = {
        firstApplicableHunkPos: 0,
        symbols: undefined,
        nodesMetaData: nodesMetaData.value,
      };
    }
    return editorIdToState.value[editorId];
  };

  const clearEditorState = (editorId: string) => {
    delete editorIdToState.value[editorId];
    // All editors are closed - clear aggregatedSymbols.
    if (Object.keys(editorIdToState.value).length === 0) {
      aggregatedSymbols.value = {};
    }
  };
  return {
    getEditorState,
    nodesMetaData,
    snippetToFocusOn,
    clearEditorState,
    aggregatedSymbols,
  };
});
