<template>
  <div class="workspace-sidebar">
    <div class="top-section">
      <div class="logo-section">
        <img width="20" src="@/assets/logo.svg" />
      </div>
      <div class="submenu-notch" />
      <transition name="fade">
        <div v-if="!shouldExpandSidebarMenu">
          <SidebarButton
            icon="main-nav-expand"
            data-testid="sidebar-docs"
            :class="{ 'disabled-sidebar-button': !canExpandSidebarMenu }"
            :highlighted="isSidebarPeek"
            @mouseout="onMouseOutExpandIcon"
            @mouseover="onMouseOverExpandIcon"
            @click="onClickExpandIcon"
            v-tooltip="
              canExpandSidebarMenu
                ? { content: 'Click to dock', placement: 'bottom' }
                : { content: 'Docking in playlists is disabled on small screens', placement: 'bottom' }
            "
          />
          <WorkspaceDropdown
            v-if="workspaceId"
            compact
            :selected-workspace-id="workspaceId"
            :workspaces="workspacesList"
            @workspace-selected="workspaceSelectionChanged($event)"
            @reset-workspace-filters="navigateToSelectedWorkspace"
          />
          <SidebarButton
            v-if="isWorkspaceAdmin"
            icon="team-add"
            @click="openAddUsersModal"
            v-tooltip="{ content: 'Add users', placement: 'right' }"
          />
          <GlobalSearch compact :main="!expandedFirstTime" />
          <VDropdown
            theme="dropdown-no-arrow"
            placement="bottom-end"
            @show="() => handleAddNewClicked({ sidebarExpanded: false })"
          >
            <SidebarButton class="add-button" icon="add" v-tooltip="{ content: 'Add new...', placement: 'right' }" />
            <template #popper>
              <div>
                <EllipsisOption
                  v-for="(option, index) of getCreationOptions()"
                  :key="index"
                  v-close-popper
                  :name="option.label"
                  :icon="option.icon"
                  :handler="async () => await handleOptionClick({ option, repoId: currentRepo && currentRepo.id })"
                >
                  <template #appendix v-if="!isGenAIDisabledForWorkspace && 'ai' in option && option.ai">
                    <Tag text="AI" class="ai-tag" tag-style="beta" />
                  </template>
                </EllipsisOption>
              </div>
            </template>
          </VDropdown>
        </div>
      </transition>
    </div>
    <div class="bottom-section">
      <NotificationsCenter />
      <UserProfile />
    </div>
  </div>

  <div
    class="submenu"
    v-if="expandedFirstTime"
    :class="{ peek: isSidebarPeek, show: shouldExpandSidebarMenu }"
    :style="{ width: resizedWidth }"
    data-testid="repos-list"
    @mouseenter="onMouseOverSubmenu"
    @mouseleave="onMouseOutSubmenu"
  >
    <div class="submenu-top-header">
      <div class="workspace-container">
        <WorkspaceDropdown
          v-if="workspaceId"
          :selected-workspace-id="workspaceId"
          :workspaces="workspacesList"
          @workspace-selected="workspaceSelectionChanged($event)"
          @reset-workspace-filters="navigateToSelectedWorkspace"
        />
      </div>
      <div class="top-icons">
        <Icon
          v-if="isWorkspaceAdmin"
          class="clickable top-icon"
          name="team-add"
          data-testid="add-users-button"
          @click="openAddUsersModal"
          v-tooltip="'Add users'"
        />
        <Icon
          v-if="shouldExpandSidebarMenu"
          class="clickable top-icon"
          name="collapse-main-nav"
          @click="hideSidebar"
          v-tooltip="'Collapse sidebar'"
        />
      </div>
    </div>
    <div class="extra-actions">
      <GlobalSearch main />
      <VDropdown
        :disabled="isPendingManualSetup"
        theme="dropdown-no-arrow"
        placement="bottom-start"
        @show="() => handleAddNewClicked({ sidebarExpanded: true })"
        :distance="0"
      >
        <Action size="big" data-testid="add-repo-button" :disabled="isPendingManualSetup">
          <Icon name="add" />
          Add new...
        </Action>
        <template #popper>
          <div :style="{ width: resizedOptionsWidth }">
            <EllipsisOption
              v-for="(option, index) of getCreationOptions()"
              :key="index"
              v-close-popper
              data-testid="add-repo-button-option"
              :name="option.label"
              :icon="option.icon"
              :handler="async () => await handleOptionClick({ option, repoId: currentRepo && currentRepo.id })"
            >
              <template #appendix v-if="!isGenAIDisabledForWorkspace && 'ai' in option && option.ai">
                <Tag text="AI" class="ai-tag" tag-style="beta" />
              </template>
            </EllipsisOption>
          </div>
        </template>
      </VDropdown>
      <Divider />
    </div>
    <div class="sidebar-content">
      <div class="sidebar-content__container">
        <SidebarFavoriteRepos
          v-if="showFavoriteReposSection"
          :workspace-id="workspaceId"
          :favorite-repos="favoriteRepos"
          :recent-repos="recentRepos"
        >
          <template v-if="favoriteRepos.length" #favorites>
            <RepoSidebarLine
              v-for="repo in favoriteRepos"
              :key="`favorite-repo-${repo.metadata.id}`"
              :repo="repo"
              :active="false"
              :disabled="!isRepoEnabled(repo)"
              :disabled-reason="getRepoDisabledReason(repo)"
              :workspace-id="workspaceId"
              :provider="repo.metadata.provider"
              :is-private="repo.metadata.is_private"
              :dummy-repo="isRepoIdDummyRepo(repo.metadata.id)"
              :is-favorite="true"
              is-in-favorite-section
              :total-repo-count="subscribedRepos.length"
              @open-repo="openRepo"
              @remove-repo="removeRepo"
              @open-repo-settings="openRepoSettingsModal"
              @repo-settings-hover-menu-opened="repoSettingsHoverMenuOpen = true"
              @repo-settings-hover-menu-closed="repoSettingsHoverMenuOpen = false"
              @add-to-favorites="(repoId) => addRepoToFavorites(repoId, 'Favorites Section')"
              @remove-from-favorites="(repoId) => removeRepoFromFavorites(repoId, 'Favorites Section')"
            />
          </template>
          <template v-if="recentRepos.length" #recents>
            <RepoSidebarLine
              v-for="repo in recentRepos"
              :key="`recent-repo-${repo.metadata.id}`"
              :repo="repo"
              :active="false"
              :disabled="!isRepoEnabled(repo)"
              :disabled-reason="getRepoDisabledReason(repo)"
              :workspace-id="workspaceId"
              :provider="repo.metadata.provider"
              :is-private="repo.metadata.is_private"
              :dummy-repo="isRepoIdDummyRepo(repo.metadata.id)"
              :is-favorite="isFavoriteRepo(repo.metadata.id)"
              :total-repo-count="subscribedRepos.length"
              is-in-favorite-section
              @open-repo="openRepo"
              @remove-repo="removeRepo"
              @open-repo-settings="openRepoSettingsModal"
              @repo-settings-hover-menu-opened="repoSettingsHoverMenuOpen = true"
              @repo-settings-hover-menu-closed="repoSettingsHoverMenuOpen = false"
              @add-to-favorites="(repoId) => addRepoToFavorites(repoId, 'Favorites Section')"
              @remove-from-favorites="(repoId) => removeRepoFromFavorites(repoId, 'Favorites Section')"
            />
          </template>
        </SidebarFavoriteRepos>
        <SidebarHomeLine
          v-if="workspaceId"
          :workspace-id="workspaceId"
          @home-link-clicked="hideSubmenu"
          :search-term="quickSearchTerm"
          :is-quick-search-open="isQuickSearchOpen"
          @search-changed="quickSearchChanged"
          @search-closed="closeQuickSearch"
          @search-opened="isQuickSearchOpen = true"
        />
        <GitAuthorizeBanner v-if="shouldShowAuthorizationButton" context="Repo Sidebar">
          Authorize {{ providerDisplayName }} to access documentation in your repositories.
        </GitAuthorizeBanner>
        <div v-if="loadingSidebar" class="section-loader">
          <Loader secondary />
        </div>
        <template v-else>
          <div class="repositories">
            <draggable
              v-model="filteredRepos"
              handle=".handle"
              ghost-class="draggable-ghost"
              :disabled="isInsideFiltering"
              :item-key="(step) => `subscribed-repo-${step.metadata.id}`"
              @start="startDrag"
              @end="onRepoReorder"
            >
              <template #item="{ element: repo }">
                <RepoSidebarLine
                  :repo="repo"
                  :active="shouldShowCurrentRepo(repo)"
                  :disabled="!isRepoEnabled(repo)"
                  :disabled-reason="getRepoDisabledReason(repo)"
                  :workspace-id="workspaceId"
                  :provider="repo.metadata.provider"
                  :is-private="repo.metadata.is_private"
                  :search="quickSearchTerm"
                  :dummy-repo="isRepoIdDummyRepo(repo.metadata.id)"
                  :is-favorite="isFavoriteRepo(repo.metadata.id)"
                  :total-repo-count="subscribedRepos.length"
                  @open-repo="openRepo"
                  @remove-repo="removeRepo"
                  @open-repo-settings="openRepoSettingsModal"
                  @repo-settings-hover-menu-opened="repoSettingsHoverMenuOpen = true"
                  @repo-settings-hover-menu-closed="repoSettingsHoverMenuOpen = false"
                  @add-to-favorites="(repoId) => addRepoToFavorites(repoId, 'All Repos')"
                  @remove-from-favorites="(repoId) => removeRepoFromFavorites(repoId, 'All Repos')"
                />
              </template>
            </draggable>
          </div>
        </template>
        <div
          v-if="
            !shouldShowAuthorizationButton &&
            shouldShowAuthorizeMessage === messageReturnOptions.showAddRepos &&
            !loadingSidebar
          "
          class="add-repositories-message"
          data-testid="add-repositories-message"
        >
          <SwText class="add-repositories-text" variant="body-S">
            <template v-if="shouldShowConnectRepo">
              <span class="link-text" @click="handleOptionClick({ option: CreationOptions.REPO, repoId: null })">
                Add a repo
              </span>
              to start creating documentation.
            </template>
            <template v-else> Ask an admin to add a repo to start creating documentation. </template>
          </SwText>
        </div>
        <div
          v-else-if="shouldShowAuthorizeMessage === messageReturnOptions.showEnterpriseMessage"
          class="enterprise-message"
        >
          <div class="enterprise-message-text">
            <SwText variant="body-XS" class="enterprise-message-line">
              {{ providerLongDisplayName }} requires an extra setup to add your repo(s). Contact our experts to get you
              up and running.
            </SwText>
            <SwText variant="body-XS" class="enterprise-message-line">
              Meanwhile, you can play with our demo repo.
            </SwText>
          </div>
          <Action
            v-if="providerLongDisplayName === 'GitHub Enterprise'"
            secondary
            @click="openOauthWorkspaceSettingsModal"
            size="small"
            class="contact-support-btn"
            >Configure</Action
          >
          <Action v-else secondary @click="contactSupport" size="small" class="contact-support-btn">Contact us</Action>
        </div>
        <SidebarSharedDocs v-if="!loadingSidebar" @click="hideSubmenu" />
      </div>
    </div>
    <RepoSettingsModal
      :show="Boolean(repoSettingsModalRepoId)"
      :origin="repoSettingsOrigin"
      :repo-id="repoSettingsModalRepoId"
      :initial-tab-code="repoSettingsModalInitialTab"
      @close="closeRepoSettingsModal"
    />
  </div>
  <AddReposModal
    :show="showAddRepoModal"
    origin="sidebar"
    :workspace-id="workspaceId"
    multi-select
    @close="toggleAddRepoModal"
    @reopen-modal="showAddRepoModal = true"
    @repos-added="userAddedRepos"
  />
  <TrialEndedModal :show="showTrialEndModal" @close="showTrialEndModal = false" />
</template>

<script lang="ts">
import AddReposModal from '@/common/components/modals/AddReposModal.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import {
  GitProviderName,
  config,
  eventLogger,
  getLoggerNew,
  gitProviderUtils,
  isRepoIdDummyRepo,
  objectUtils,
  productEvents,
  state,
  workspaceSettingsTabs,
} from '@swimm/shared';
import _ from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import swal from 'sweetalert';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import WorkspaceDropdown from '@/common/components/organisms/WorkspaceDropdown.vue';
import RepoSidebarLine from '@/common/components/organisms/RepoSidebarLine.vue';
import { storeToRefs } from 'pinia';
import { useFiltersStore } from '@/modules/core/filters-row/useFiltersStore';
import { RepoPageRouteNames, UserRole } from '@/common/consts';
import draggable from 'vuedraggable';
import { useRepoSwitcher } from '@/common/composables/repoSwitcher';
import { useInitData } from '@/common/composables/initData';
import { useRouting } from '@/common/composables/routing';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import UserProfile from '@/common/components/organisms/UserProfile.vue';
import NotificationsCenter from '@/modules/notifications-center/components/NotificationsCenter.vue';
import { Action, Divider, SwText, Tag } from '@swimm/ui';
import GlobalSearch from '@/modules/search/components/GlobalSearch.vue';
import SidebarButton from './SidebarButton.vue';
import RepoSettingsModal from '@/modules/repo/settings/RepoSettingsModal.vue';
import { computed, ref, watch } from 'vue';
import TrialEndedModal from '@/modules/workspace/modals/settings/billing/TrialEndedModal.vue';
import { useStigg } from '@/common/composables/useStigg';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useWorkspaceSettingsModalStore } from '@/modules/workspace/modals/settings/store/workspace-settings';
import { CreationOptions, useCreationOptions } from '@/modules/core/workspace/home/composables/creationOptions';
import { useWorkspaceSidebarStore } from '@/modules/core/workspace/sidebar/store/workspace-sidebar';
import SidebarSharedDocs from '@/modules/core/workspace/sidebar/components/SidebarSharedDocs.vue';
import SidebarHomeLine from '@/modules/core/workspace/sidebar/components/SidebarHomeLine.vue';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import SidebarFavoriteRepos from '@/modules/core/workspace/sidebar/components/SidebarFavoriteRepos.vue';
import GitAuthorizeBanner from '@/common/components/Auth/GitAuthorizeBanner.vue';
import { until } from '@vueuse/core';

const logger = getLoggerNew(__modulename);

type SidebarRepo = {
  metadata: {
    id: string;
    name: string;
    provider: GitProviderName;
    is_private: boolean;
  };
  branch?: string;
  disabled?: boolean;
};

export default {
  name: 'WorkspaceSidebar',
  components: {
    Tag,
    GitAuthorizeBanner,
    Action,
    draggable,
    AddReposModal,
    WorkspaceDropdown,
    RepoSidebarLine,
    UserProfile,
    NotificationsCenter,
    Divider,
    GlobalSearch,
    SidebarButton,
    RepoSettingsModal,
    TrialEndedModal,
    SwText,
    SidebarSharedDocs,
    SidebarHomeLine,
    SidebarFavoriteRepos,
  },
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const filtersStore = useFiltersStore();
    const { logEvent: logEventComposable } = useSwimmEventLogs();
    const { isCurrentWorkspaceAuthorized } = storeToRefs(useGitAuthorizationStore());
    const { connectToRepo } = useRepoSwitcher();
    const { setRepoData, setWorkspaceData } = useInitData();
    const { getCurrentOrDefaultBranch } = useRouting();
    const { isTrialExpired } = useStigg();
    const { openWorkspaceSettingsModal } = useWorkspaceSettingsModalStore();
    const { showAddRepoModal, sidebarExpandedWidthPx, workspaceReposUpdatedCounterTrigger } = storeToRefs(
      useWorkspaceSidebarStore()
    );

    const workspaceStore = useWorkspaceStore();
    const {
      isSidebarPeek,
      isSidebarExpanded,
      canExpandSidebarMenu,
      shouldExpandSidebarMenu,
      shouldForceOpenSubmenu,
      isPendingManualSetup,
      favoriteRepoIds,
      recentRepoIds,
      providerDisplayName,
      providerLongDisplayName,
      provider,
      isGenAIDisabledForWorkspace,
    } = storeToRefs(workspaceStore);
    const { addRepoToFavorites, removeRepoFromFavorites } = workspaceStore;
    const { handleAddNewClicked, handleOptionClick } = useCreationOptions();
    const originalWorkspaceRepoIds = ref([]);

    const expandedFirstTime = ref(false);

    const workspaceHasDummyRepo = computed(() => {
      return originalWorkspaceRepoIds.value.some(isRepoIdDummyRepo);
    });
    const workspaceHasAuthenticRepos = computed(() => {
      // if workspace has dummy repo, indication to connected repo is more than one repo
      return originalWorkspaceRepoIds.value.length > (workspaceHasDummyRepo.value ? 1 : 0);
    });
    const messageReturnOptions = {
      showAuthorize: 0,
      showAddRepos: 1,
      dontShow: 2,
      showEnterpriseMessage: 3,
    };

    const shouldShowAuthorizeMessage = computed(() => {
      if (isPendingManualSetup.value) {
        return messageReturnOptions.showEnterpriseMessage;
      }

      if (!originalWorkspaceRepoIds.value.length) {
        return messageReturnOptions.showAddRepos;
      }

      if (workspaceHasDummyRepo.value && !workspaceHasAuthenticRepos.value) {
        return messageReturnOptions.showAddRepos;
      }

      if (workspaceHasAuthenticRepos.value && !isCurrentWorkspaceAuthorized.value) {
        return messageReturnOptions.showAuthorize;
      }

      return messageReturnOptions.dontShow;
    });

    const isSubscribedReposLoaded = ref(false);
    const subscribedRepos = ref([]);
    const repoSettingsModalRepoId = ref(null);
    const repoSettingsOrigin = ref('');
    const repoSettingsModalInitialTab = ref(null);
    const openRepoSettingsModal = ({ origin, tab = null, repoId }) => {
      repoSettingsModalInitialTab.value = tab;
      repoSettingsOrigin.value = origin;
      repoSettingsModalRepoId.value = repoId;
    };
    const closeRepoSettingsModal = () => {
      repoSettingsModalRepoId.value = null;
    };

    const repoSettingsHoverMenuOpen = ref(false);
    const disabledRepoTooltipOpen = ref(false);

    const isMouseOverSubmenu = ref(false);
    const isMouseOverRepoIcon = ref(false);

    const onMouseOverSubmenu = () => {
      if (shouldExpandSidebarMenu.value) {
        return;
      }
      isMouseOverSubmenu.value = true;
      analytics.track(productEvents.SIDEBAR_HOVERED, {});
    };

    const onMouseOutSubmenu = () => {
      if (shouldExpandSidebarMenu.value) {
        return;
      }
      isMouseOverSubmenu.value = false;
      shouldForceOpenSubmenu.value = false;
    };

    const onMouseOverExpandIcon = () => {
      if (shouldExpandSidebarMenu.value) {
        return;
      }
      isMouseOverRepoIcon.value = true;
      isSidebarPeek.value = true;
    };

    const onMouseOutExpandIcon = () => {
      if (shouldExpandSidebarMenu.value) {
        return;
      }
      isMouseOverRepoIcon.value = false;
    };

    const onClickExpandIcon = () => {
      if (!canExpandSidebarMenu.value) {
        return;
      }
      isSidebarExpanded.value = true;
      isSidebarPeek.value = false;
      analytics.track(productEvents.SIDEBAR_EXPANDED, {});
    };

    const openAddUsersModal = () => {
      openWorkspaceSettingsModal({
        initialTabCode: workspaceSettingsTabs.MEMBERS,
      });
    };

    const openOauthWorkspaceSettingsModal = () => {
      openWorkspaceSettingsModal({
        initialTabCode: workspaceSettingsTabs.OAUTH,
      });
    };

    const hideSidebar = () => {
      isSidebarExpanded.value = false;
      analytics.track(productEvents.SIDEBAR_COLLAPSED, {});
    };

    const quickSearchTerm = ref('');
    const isQuickSearchOpen = ref(false);
    const quickSearchChanged = (search) => {
      quickSearchTerm.value = search;
    };

    const closeQuickSearch = () => {
      isQuickSearchOpen.value = false;
      quickSearchChanged('');
    };

    const resizedWidth = computed(() => {
      const width = sidebarExpandedWidthPx.value;
      return `${width}px`;
    });

    const resizedOptionsWidth = computed(() => {
      return `calc(${resizedWidth.value} - 2 * var(--space-sm) - var(--space-xs))`;
    });

    watch(
      [isMouseOverRepoIcon, isMouseOverSubmenu, isSidebarPeek, repoSettingsHoverMenuOpen, disabledRepoTooltipOpen],
      ([isMouseOverRepoIcon, isMouseOverSubmenu], _, onCleanup) => {
        if (!isSidebarPeek.value) {
          return;
        }
        if (shouldExpandSidebarMenu.value) {
          return;
        }
        if (
          isMouseOverSubmenu ||
          isMouseOverRepoIcon ||
          repoSettingsHoverMenuOpen.value ||
          disabledRepoTooltipOpen.value ||
          shouldForceOpenSubmenu.value
        ) {
          return;
        }
        // Wait 50ms before hiding the submenu so as to not hide the submenu when the user is moving between the icon
        // and the menu.
        const timeoutHandle = setTimeout(() => (isSidebarPeek.value = false), 50);
        onCleanup(() => clearTimeout(timeoutHandle));
      },
      { deep: true }
    );

    function contactSupport() {
      window.open('mailto:info@swimm.io', '_blank');
    }
    // hold the unwatch for the dynamic $watch of the authorization
    const unwatchIsAuthorized = null;

    function isFavoriteRepo(repoId) {
      return favoriteRepoIds.value.includes(repoId);
    }

    const isWorkspaceEmpty = computed(() => {
      return isSubscribedReposLoaded.value && subscribedRepos.value.length === 0;
    });

    function getCreationOptions() {
      if (isWorkspaceEmpty.value) {
        return _.pick(CreationOptions, 'REPO');
      }
      let options: Partial<typeof CreationOptions> = CreationOptions;
      if (!gitProviderUtils.doesProviderSupportPRToDoc(provider?.value)) {
        options = _.omit(options, 'PR2Doc');
      }
      if (isGenAIDisabledForWorkspace.value) {
        options = _.omit(options, 'Snippets2Doc');
      }
      return options;
    }

    const loadingSidebar = ref(true);

    watch(
      () => [shouldExpandSidebarMenu.value, isSidebarPeek.value],
      async (shouldShow) => {
        if (shouldShow.includes(true) && !expandedFirstTime.value) {
          if (!loadingSidebar.value) {
            setTimeout(() => (loadingSidebar.value = false), 100);
          }
          loadingSidebar.value = true;
          expandedFirstTime.value = true;
        }
      },
      { immediate: true }
    );

    async function waitUntilFirstExpanded() {
      await until(expandedFirstTime).toBeTruthy();
    }

    return {
      user,
      resizedWidth,
      resizedOptionsWidth,
      getCreationOptions,
      isSidebarPeek,
      canExpandSidebarMenu,
      shouldExpandSidebarMenu,
      isSidebarExpanded,
      sidebarExpandedWidthPx,
      showAddRepoModal,
      onMouseOutSubmenu,
      onMouseOverSubmenu,
      onMouseOverExpandIcon,
      onMouseOutExpandIcon,
      onClickExpandIcon,
      hideSidebar,
      handleAddNewClicked,
      handleOptionClick,
      messageReturnOptions,
      originalWorkspaceRepoIds,
      shouldShowAuthorizeMessage,
      analytics,
      filtersStore,
      isCurrentWorkspaceAuthorized,
      logEventComposable,
      connectToRepo,
      setRepoData,
      setWorkspaceData,
      getCurrentOrDefaultBranch,
      repoSettingsModalRepoId,
      repoSettingsOrigin,
      repoSettingsModalInitialTab,
      openOauthWorkspaceSettingsModal,
      openRepoSettingsModal,
      closeRepoSettingsModal,
      repoSettingsHoverMenuOpen,
      disabledRepoTooltipOpen,
      isTrialExpired,
      openAddUsersModal,
      contactSupport,
      isPendingManualSetup,
      quickSearchTerm,
      quickSearchChanged,
      isRepoIdDummyRepo,
      favoriteRepoIds,
      recentRepoIds,
      isFavoriteRepo,
      addRepoToFavorites,
      removeRepoFromFavorites,
      unwatchIsAuthorized,
      providerDisplayName,
      providerLongDisplayName,
      isQuickSearchOpen,
      closeQuickSearch,
      workspaceReposUpdatedCounterTrigger,
      isSubscribedReposLoaded,
      subscribedRepos,
      isWorkspaceEmpty,
      CreationOptions,
      loadingSidebar,
      expandedFirstTime,
      waitUntilFirstExpanded,
      isGenAIDisabledForWorkspace,
      provider,
    };
  },
  data() {
    return {
      currentRepo: null,
      showTrialEndModal: false,
      drag: false,
      settingResourcesForWorkspaceId: '',
      filteredRepos: [],
    };
  },
  watch: {
    '$route.params.repoId': {
      handler(newValue, oldValue) {
        if (oldValue && newValue !== oldValue) {
          this.filtersStore.resetFilters();
        }
      },
      immediate: true,
    },
    workspaceReposUpdatedCounterTrigger() {
      // Watch workspaceReposUpdatedCounterTrigger to trigger reloading sidebar repos,
      // after adding new repos from AddRepoModal in AppModals.
      // The sidebar is currently not reactive to repos changes so we added this is a workaround.
      this.setWorkspaceResources(false, true);
    },
    quickSearchTerm() {
      this.updateFilteredRepos();
    },
    subscribedRepos: {
      handler() {
        this.updateFilteredRepos();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('database', [
      'db_getUserWorkspaces',
      'db_getWorkspaceRepoIds',
      'db_getSwimmerRepos',
      'db_getRepoMetadata',
      'db_getRepository',
      'db_getWorkspace',
      'db_isWorkspaceAdmin',
      'db_isWorkspaceSidebarCollapsedByDefault',
    ]),
    ...mapGetters('filesystem', ['fs_getSelectedFolderTreePath', 'fs_getRepoFolderTree']),
    isEverything() {
      return this.$route.path.endsWith('/everything');
    },
    workspacesList() {
      return this.db_getUserWorkspaces(this.user.uid);
    },
    workspace() {
      return this.db_getWorkspace(this.workspaceId);
    },
    isWorkspaceAdmin() {
      return this.db_isWorkspaceAdmin(this.workspaceId, this.user.uid);
    },
    shouldShowConnectRepo() {
      return this.isWorkspaceAdmin || this.workspace.settings?.allow_non_admin_connect_repos;
    },
    isRepoDuringInitialization() {
      return this.$route.params.repoId === 'new';
    },
    isAuthorized() {
      return this.isCurrentWorkspaceAuthorized;
    },
    workspaceId(): string {
      return this.$route.params.workspaceId as string;
    },
    shouldShowAuthorizationButton() {
      return this.shouldShowAuthorizeMessage === this.messageReturnOptions.showAuthorize;
    },
    favoriteRepos() {
      return this.getReposByRepoIds(this.favoriteRepoIds);
    },
    recentRepos() {
      return this.getReposByRepoIds(this.recentRepoIds);
    },
    showFavoriteReposSection() {
      return this.workspaceId && (this.favoriteRepos.length || this.subscribedRepos.length > 5);
    },
    isInsideFiltering(): boolean {
      return this.quickSearchTerm?.trim().length > 0 ?? false;
    },
  },
  async created() {
    try {
      this.fetchSwimmerWorkspaces();
      // Fetching the workspaces is expensive on performance and not needed immediately
      // so we fetch them after fetching the current workspace data and don't wait for it.
      this.setWorkspaceResources().then(() => {
        this.watchForAuthorizationChange();
      });

      this.$watch('$route.params.workspaceId', async (workspaceId) => {
        if (this.unwatchIsAuthorized) {
          this.unwatchIsAuthorized();
          this.unwatchIsAuthorized = null;
        }
        if (this.db_isWorkspaceSidebarCollapsedByDefault(workspaceId)) {
          this.isSidebarExpanded = false;
          this.expandedFirstTime = false;
        }
        await this.setWorkspaceResources();
        this.watchForAuthorizationChange();
      });

      // Watch repoId route param to trigger reloading sidebar repos, after a new repo.
      // repo. See relevant `router.replace()` call in AddRepoModal. This is horrible
      // and should be refactored in the future. I hope the future comes soon.
      this.$watch('$route.params.repoId', this.setRepoAndWorkspaceResources);

      if (this.$route.query.addRepo || this.$route.query.source === config.GH_MARKETPLACE_INDICATOR) {
        this.toggleAddRepoModal();
      }
    } catch (err) {
      logger.error({ err }, `failed during Workspace Sidebar created hook`);
    }
  },
  methods: {
    ...mapActions('filesystem', ['cleanLoadedRepoData']),
    ...mapActions('database', ['saveWorkspace', 'fetchSwimmerWorkspaces']),
    updateFilteredRepos() {
      if (!this.isInsideFiltering) {
        this.filteredRepos = this.subscribedRepos;
      } else {
        this.filteredRepos = this.subscribedRepos.filter(
          (repo) =>
            this.shouldShowCurrentRepo(repo) ||
            repo.metadata?.name?.toLowerCase().includes(this.quickSearchTerm?.toLowerCase().trim())
        );
      }
    },
    userAddedRepos({ repoIds }) {
      if (repoIds.length > 1) {
        // If only one repo added then the sidebar resources will be updated by the repo route watch.
        // This is not ideal. should be refactored as part of app data loading.
        this.setWorkspaceResources(false, true);
      }
    },
    watchForAuthorizationChange() {
      if (!this.isCurrentWorkspaceAuthorized) {
        this.unwatchIsAuthorized = this.$watch(
          () => this.isCurrentWorkspaceAuthorized,
          () => {
            this.setWorkspaceResources(false, true);
          }
        );
      }
    },
    isRepoAuthorized(repoProvider, repoId) {
      switch (repoProvider) {
        case GitProviderName.Testing:
          return true;
        default:
          return this.isCurrentWorkspaceAuthorized || isRepoIdDummyRepo(repoId);
      }
    },
    async setWorkspaceResources(showLoader = true, force = false) {
      const workspaceId = this.workspaceId;
      if (!workspaceId) {
        return;
      }
      if (!force && this.settingResourcesForWorkspaceId === workspaceId) {
        // No need to fetch all of this data again for the same workspace
        return;
      }
      const startTime = Date.now();
      try {
        if (showLoader) {
          this.loadingSidebar = showLoader;
          await this.waitUntilFirstExpanded();
        }
        this.settingResourcesForWorkspaceId = workspaceId;
        if (workspaceId) {
          await this.setWorkspaceData(workspaceId);
          this.originalWorkspaceRepoIds = this.db_getWorkspaceRepoIds(workspaceId);
          if (this.$route.params.repoId && !this.isRepoDuringInitialization) {
            this.currentRepo = this.db_getRepoMetadata(this.$route.params.repoId);
          }
          await this.setAvailableRepos(workspaceId);
          this.showTrialEndModalIfNeeded();
        }
      } catch (err) {
        logger.error({ err }, `failed during setWorkspaceResources: workspaceId ${workspaceId} : ${err.message}`);
      } finally {
        if (workspaceId === this.workspaceId) {
          this.loadingSidebar = false;
        }
        logger.info(`Done setWorkspaceResources took ${Date.now() - startTime} ms`);
      }
    },
    shouldShowCurrentRepo(repo) {
      return (
        this.currentRepo &&
        !objectUtils.isEmpty(repo.metadata) &&
        this.currentRepo.id === repo.metadata.id &&
        !repo.disabled
      );
    },
    isRepoEnabled(repo) {
      return !this.getRepoDisabledReason(repo);
    },
    getRepoDisabledReason(repo) {
      if (isRepoIdDummyRepo(repo.metadata.id)) {
        return null;
      }
      if (!this.isAuthorized) {
        return 'unauthorized';
      }
      if (this.showNoAccessTooltip(repo)) {
        return 'no-access';
      }
      if (this.showExpiredTooltip(repo)) {
        return 'expired';
      }
      return null;
    },
    workspaceSelectionChanged({ prevWorkspace, newWorkspace }) {
      this.currentRepo = null;
      const traits = {
        Name: newWorkspace.name,
      };
      this.analytics.userGroup(newWorkspace.id, traits);
      this.analytics.track(productEvents.USER_SWITCHED_WORKSPACE, {
        'Previous Workspace ID': prevWorkspace.id,
        'Previous Workspace Name': prevWorkspace.name,
        'Workspace ID': newWorkspace.id,
        'Workspace Name': newWorkspace.name,
      });
      this.$router.push(`/workspaces/${newWorkspace.id}`);
    },
    async navigateToSelectedWorkspace() {
      if (!this.$route.fullPath.endsWith(this.workspaceId)) {
        if (this.currentRepo && this.currentRepo.id) {
          this.cleanLoadedRepoData(this.currentRepo.id);
        }
        this.currentRepo = null;
        await this.$router.push(`/workspaces/${this.workspaceId}`);
      }
    },
    async setAvailableRepos(workspaceId: string) {
      const swimmerRepos = this.db_getSwimmerRepos(this.user.uid) as unknown as SidebarRepo[];
      const swimmerReposInWorkspacePromises = this.originalWorkspaceRepoIds
        .filter((repoId) => isRepoIdDummyRepo(repoId) || swimmerRepos[repoId])
        .map((repoId) => this.db_getRepository(repoId) as SidebarRepo)
        .map(async (repo) => {
          const branch = await this.getCurrentOrDefaultBranch(repo.metadata.id);
          if (branch) {
            repo.branch = branch;
            repo.disabled = false;
          } else {
            // No branch means Not having permissions to repo.
            repo.disabled = true;
          }
          return repo;
        });
      const repos = await Promise.all(swimmerReposInWorkspacePromises);

      if (workspaceId !== this.$route.params.workspaceId) {
        return;
      }
      // Repos we know their cwd
      const validRepos = repos.filter(Boolean);
      const repoOrder = await state.get({ key: `${this.workspaceId}-repo-order` });
      if (repoOrder) {
        validRepos.sort(
          (firstRepo, secondRepo) =>
            repoOrder.indexOf(firstRepo.metadata.id) - repoOrder.indexOf(secondRepo.metadata.id)
        );
      }
      this.subscribedRepos = validRepos;
      this.isSubscribedReposLoaded = true;
    },
    async openRepo(repo) {
      // Is current repo
      if (this.shouldShowCurrentRepo(repo)) {
        this.$router.push({ name: RepoPageRouteNames.DOCUMENTATIONS });
        return;
      }
      if (!this.isRepoAuthorized(repo.metadata.provider, repo.metadata.id)) {
        return;
      }
      if (repo.disabled) {
        repo.loading = true;
      }
      this.isSidebarPeek = false;
      this.$router.push(`/workspaces/${this.workspaceId}/repos/${repo.metadata.id}`);
      this.closeQuickSearch();
    },
    toggleAddRepoModal() {
      const origin = this.$route.params.repoId ? 'Repo' : 'Workspace';
      const shouldShowModal = !this.showAddRepoModal;
      if (shouldShowModal) {
        this.analytics.track(productEvents.CLICKED_CONNECT_A_REPO, {
          Origin: `View ${origin}`,
          'Origin URL': this.$route.fullPath,
          'Workspace ID': this.workspaceId,
          'User Type': this.isWorkspaceAdmin ? UserRole.ADMIN : UserRole.MEMBER,
        });
      }
      this.showAddRepoModal = shouldShowModal;
    },
    async removeRepo(repoId) {
      const repoIndex = this.workspace.repositories.indexOf(repoId);
      if (repoIndex > -1) {
        const repoName = this.db_getRepoMetadata(repoId) ? this.db_getRepoMetadata(repoId).name : '';
        const shouldUnsubscribe = await swal({
          title: `Remove repo ${repoName ? `'${repoName}' ` : ''}from your workspace?`,
          text: `Resources from this repo will become unavailable inside workspace playlists.`,
          buttons: { cancel: true, confirm: { text: 'Remove repo from workspace' } },
        });
        if (shouldUnsubscribe) {
          this.workspace.repositories.splice(repoIndex, 1);
          try {
            await this.saveWorkspace({ resource: this.workspace, user: this.user });
            this.logEventComposable(eventLogger.SWIMM_EVENTS.REPO_REMOVED_FROM_WORKSPACE, {
              srcId: this.workspace.id,
              srcName: this.workspace.name,
              repoId,
              repoName,
            });
            this.analytics.track(productEvents.REPO_REMOVED_SUCCESS, {
              'Workspace ID': this.workspaceId,
              'Repo ID': repoId,
              'Repo Name': repoName,
            });
            const originalRepoIndex = this.originalWorkspaceRepoIds.indexOf(repoId);
            this.originalWorkspaceRepoIds.splice(originalRepoIndex, 1);
            this.subscribedRepos = this.subscribedRepos.filter((repo) => repo.metadata.id !== repoId);
            if (this.$route.params.repoId && this.$route.params.repoId === repoId) {
              await this.navigateToSelectedWorkspace();
            }
          } catch (err) {
            logger.error({ err }, `could not save workspace`);
            await swal({
              title: 'Failed to remove repo from workspace.',
              content: { element: SWAL_CONTACT_US_CONTENT() },
            });
          }
        }
      }
    },
    async setRepoAndWorkspaceResources() {
      this.setCurrentRepo();
      if (this.$route.params.repoId) {
        await this.setWorkspaceResources(false, true);
      }
    },
    setCurrentRepo() {
      this.currentRepo =
        this.$route.params.repoId && this.$route.params.repoId !== 'new'
          ? this.db_getRepoMetadata(this.$route.params.repoId)
          : null;
    },
    showNoAccessTooltip(repo) {
      return this.isAuthorized && (repo.disabled || !this.isRepoAuthorized(repo.metadata.provider, repo.metadata.id));
    },
    showExpiredTooltip(repo) {
      if (!repo.metadata.is_private) {
        return false;
      }
      if (this.workspace.expired_repositories && this.workspace.expired_repositories.includes(repo.metadata.id)) {
        return true;
      }

      return false;
    },
    async onRepoReorder(event) {
      this.drag = false;
      if (event.newIndex !== event.oldIndex) {
        // this should be alwyas true, since drag is enabled only if there is no filter
        if (this.filteredRepos.length === this.subscribedRepos.length) {
          this.subscribedRepos = this.filteredRepos;
          await state.set({
            key: `${this.workspaceId}-repo-order`,
            value: this.subscribedRepos.map((repo) => repo.metadata.id),
          });
        }
      }
    },
    startDrag() {
      this.drag = true;
    },
    hideSubmenu() {
      this.isSidebarPeek = false;
    },
    async showTrialEndModalIfNeeded() {
      if (!this.isWorkspaceAdmin) {
        return;
      }
      const trialExpired = await this.isTrialExpired();
      if (!trialExpired) {
        return;
      }
      const trialEndModalShowKey = `${this.workspaceId}_trial_end_modal_shown`;
      const modalAlreadyShown = await state.get({
        key: trialEndModalShowKey,
        defaultValue: false,
      });
      if (modalAlreadyShown) {
        return;
      }
      this.showTrialEndModal = true;
      await state.set({
        key: trialEndModalShowKey,
        value: true,
      });
    },
    getReposByRepoIds(repoIds) {
      const repos = [];
      repoIds.forEach((repoId) => {
        const repo = this.subscribedRepos.find((repo) => repo.metadata?.id === repoId);
        if (repo) {
          repos.push(repo);
        }
      });
      return repos;
    },
  },
};
</script>

<style scoped lang="postcss">
.draggable-ghost {
  opacity: 0;
}

.workspace-sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-brand);
  position: relative;
  z-index: 203; /* should be over the submenu which is 202 */
  width: 48px;

  .logo-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: var(--color-brand);
    min-width: var(--sidebar-width);
    margin: 16px 0 12px 0;

    img {
      margin-left: 8px;
    }
  }

  .add-button {
    :deep(.button-icon) {
      padding: 4px;
      border-radius: 4px;
      background: var(--text-color-on-dark);
      color: var(--text-color-link);
      font-size: var(--body-L);

      &:hover {
        background: var(--color-hover);
      }
    }
  }

  .bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.submenu {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: var(--sidebar-width);
  bottom: 0;
  z-index: 202; /* z-index of Snippet Studio is 201 for some reason and it hides it */
  background-color: var(--color-bg);
  border-right: 1px solid var(--color-border-default);
  border-top-left-radius: var(--notch-radius);
  transition-property: transform, box-shadow, border-top-right-radius;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  padding-top: var(--space-base);
  border-top-right-radius: 0;

  &:not(.peek):not(.show) {
    transform: translateX(-100%);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.12);
  }

  &.peek {
    border-top-right-radius: var(--notch-radius);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  }

  .submenu-top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--space-sm);

    .workspace-container {
      max-width: 70%;
    }

    .top-icons {
      .top-icon {
        border-radius: 4px;

        &:hover {
          background-color: var(--color-hover);
        }
      }
    }
  }

  .extra-actions {
    display: flex;
    flex-direction: column;
    gap: var(--space-base);
    padding: var(--space-sm) var(--space-sm) var(--space-base);

    .v-popper--theme-dropdown-no-arrow {
      .button {
        width: 100%;
      }
    }
  }

  .sidebar-content {
    overflow: auto;
    height: 100%;
    position: relative;
    /* We rely on the scrollbar's width to align the sidebar's contents - this will keep its padding always there even
       when sidebar has a few items. */
    scrollbar-gutter: stable;

    /**
     * This container ensures that the inner content
     * doesn't break out of the parent container when
     * we use scrollIntoView on new items.
     */
    &__container {
      left: 0;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .add-repositories-message {
    padding: var(--space-sm);

    .add-repositories-text {
      padding: 0 var(--space-base);
      color: var(--text-color-secondary);

      .link-text {
        cursor: pointer;
        color: var(--text-color-link);
      }
    }
  }

  .enterprise-message {
    padding: var(--space-md);
    background: var(--color-status-default);
    margin: var(--space-sm);

    .enterprise-message-text {
      display: flex;
      flex-direction: column;
      gap: var(--space-sm);

      .enterprise-message-line {
        line-height: var(--space-md);
      }
    }

    .contact-support-btn {
      margin-top: var(--space-sm);
    }
  }

  .add-repo-icon {
    background-color: var(--color-surface);
    border-radius: 6px;
    font-size: var(--subtitle-S);
  }

  .add-repo-bottom-action {
    margin: var(--space-sm);
  }
}

.section-loader {
  justify-content: flex-start;
}

.submenu-notch {
  position: absolute;
  top: 0;
  left: 100%;
  border-radius: 16px 16px 16px 0;

  &::after {
    content: '';
    position: absolute;
    background-color: transparent;
    top: 0px;
    left: 0px;
    height: 50px;
    width: 16px;
    border-top-left-radius: 16px;
    box-shadow: 0 -25px 0 0 var(--color-brand); /* black magic for the notch */
  }
}

.disabled-sidebar-button {
  cursor: initial;
}

.ai-tag {
  margin-left: var(--space-base);
  margin-top: -3px;
  margin-bottom: -3px;
}
</style>
