import { type User, config, firestoreCollectionNames, getLoggerNew } from '@swimm/shared';
import { PJSON_VERSION } from '@/config';
import * as firestore from '@/adapters-common/firestore-wrapper';
import firebase from 'firebase/compat/app';

const logger = getLoggerNew(__modulename);

interface DocDbInterface {
  name: string;
  id: string;
  type: 'unit' | 'external_link';
  file_version: string;
  app_version: string;
  play_mode: 'walkthrough' | 'handson';
}

export function createDocDbObject({ name, id }: { name: string; id: string }): DocDbInterface {
  return {
    name,
    id,
    type: 'unit',
    file_version: config.SWM_SCHEMA_VERSION,
    app_version: PJSON_VERSION,
    play_mode: 'walkthrough',
  };
}

function composeNewDocDbObject(docName: string, docId: string, user: User) {
  const now = firestore.firestoreTimestamp();
  const dbDocument: firebase.firestore.DocumentData = createDocDbObject({ name: docName, id: docId });
  dbDocument.created = now;
  dbDocument.creator = user.uid;
  dbDocument.creator_name = user.nickname;
  dbDocument.creator_profile_url = user.photoURL ?? '';
  dbDocument.modified = now;
  dbDocument.modifier = user.uid;
  dbDocument.modifier_name = user.nickname;
  return dbDocument;
}

export async function addDocToDb(
  docId: string,
  docName: string,
  repoId: string,
  user: User
): Promise<firebase.firestore.DocumentData> {
  const swimmsRef = firebase
    .firestore()
    .collection(firestoreCollectionNames.REPOSITORIES)
    .doc(repoId)
    .collection(firestoreCollectionNames.SWIMMS);
  const docRef = swimmsRef.doc(docId);

  await firebase.firestore().runTransaction(async (transaction) => {
    // Validate doc doesn't exist in DB
    const docDbRecord = await transaction.get(docRef);

    if (docDbRecord.exists) {
      throw new Error(`Document with id ${docId} already exists in repository ${repoId}. Skipping DB update.`);
    }

    // Compose document object
    const dbDocument = composeNewDocDbObject(docName, docId, user);

    // add document to DB
    transaction.set(docRef, dbDocument);

    logger.info(`Successfully added docId: ${docId} to database`);
  });

  // Update doc in state
  const savedDocument = await firestore.getDocFromRef(swimmsRef, docId);
  if (savedDocument.code !== config.SUCCESS_RETURN_CODE) {
    throw new Error(`Got error getting document after save: ${savedDocument.errorMessage}`);
  }

  return savedDocument.data;
}
