<template>
  <span v-tooltip="branch" class="branch"> <Icon name="branch" />{{ branch }} </span>
</template>

<script>
export default {
  props: {
    branch: { type: String, required: true },
  },
};
</script>

<style scoped>
.branch {
  display: inline-block;
  overflow: hidden;
  padding: 2px 8px;
  max-width: 120px;
  border: 1px solid var(--color-border-default);
  border-radius: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-color-primary);
}
</style>
