// Based on
// https://github.com/hedgedoc/markdown-it-plugins/blob/main/src/task-lists/index.ts,
// but modified to generate tokens that are friendly to prosemirror-markdown

import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core';
import type Token from 'markdown-it/lib/token';

export interface TaskListsOptions {
  enabled?: boolean;
  // TODO Not implemented from original plugin
  //   label?: boolean;
  //   lineNumber?: boolean;
}

export default function taskList(
  md: MarkdownIt,
  options: TaskListsOptions = {
    enabled: false,
    // TODO Not implemented from original plugin
    // label: false,
    // lineNumber: false,
  }
): void {
  md.core.ruler.after('inline', 'task-lists', (state) => processToken(state));
  md.renderer.rules.task_item_open = (tokens, idx, rendererOptions, env, slf) => {
    // TODO This plugin doesn't generate the best HTML for loose lists as the
    // input is outside the p which will require some CSS to make it look good
    // if we really need to render HTML with this plugin, we might want to
    // consider adding a checkbox token which will be rendered in HTML but
    // ignored by prosemirror-markdown
    //
    // li[data-type="task-item"]:has(p)) {
    //   margin: 1em 0;
    // }
    //
    // li[data-type="task-item"] > p:nth-child(2)) {
    //   display: inline-block;
    //   margin: 0;
    // }
    // TODO The original plugin also adds some CSS classes
    const token = tokens[idx];
    const disabledAttribute = options.enabled ? '' : 'disabled="disabled" ';
    return `<li data-type="task-item"><input type="checkbox"${slf.renderAttrs(token)} ${disabledAttribute}${
      rendererOptions.xhtmlOut ? ' /' : ''
    }>${md.utils.escapeHtml(tokens[idx].content)}\n`;
  };
}

function processToken(state: StateCore): boolean {
  const allTokens = state.tokens;
  for (let i = 2; i < allTokens.length; i++) {
    if (!isTodoItem(allTokens, i)) {
      continue;
    }

    todoify(allTokens, i - 2);
  }

  return false;
}

function todoify(tokens: Token[], idx: number): void {
  tokens[idx].type = 'task_item_open';

  const checkboxRegexResult = checkboxRegex.exec(tokens[idx + 2].content);
  const isChecked = checkboxRegexResult?.[1].toLowerCase() === 'x';
  if (isChecked) {
    tokens[idx].attrSet('checked', 'true');
  }

  // TODO Might be more optimal to not run the regex twice
  if (tokens[idx + 2].children != null) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    tokens[idx + 2].children![0].content = tokens[idx + 2].children![0].content.replace(checkboxRegex, '');
  }

  for (let j = idx + 2; j < tokens.length; j++) {
    if (tokens[j].type === 'list_item_close') {
      tokens[j].type = 'task_item_close';
    }
  }
}

function isTodoItem(tokens: Token[], index: number): boolean {
  return (
    isInline(tokens[index]) &&
    isParagraph(tokens[index - 1]) &&
    isListItem(tokens[index - 2]) &&
    startsWithTodoMarkdown(tokens[index])
  );
}

function isInline(token: Token): boolean {
  return token.type === 'inline';
}

function isParagraph(token: Token): boolean {
  return token.type === 'paragraph_open';
}

function isListItem(token: Token): boolean {
  return token.type === 'list_item_open';
}

const checkboxRegex = /^ *\[([\sx])] /i;

function startsWithTodoMarkdown(token: Token): boolean {
  return checkboxRegex.test(token.content);
}
