<template>
  <div>
    <div class="sub-header subtitle-XL">Slack channel to send notifications to:</div>
    <SlackChannelsSelect
      :model-value="selectedChannel"
      :repo-id="repoId"
      :workspace-id="workspaceId"
      :disabled="savingChannel"
      @update:model-value="handleChannelChange"
    />
    <Divider class="divider" />
    <div class="sub-header subtitle-XL">Send notifications when:</div>
    <CheckBoxSection
      v-for="config in notificationsConfigs"
      :key="config.type"
      :text="config.text"
      :value="config.enabled"
      :disabled="!selectedChannel || savingChannel || savingIntegration"
      :name="config.type"
      @click="onCheckboxClicked"
    />
  </div>
</template>

<script>
import { useAnalytics } from '@/common/composables/useAnalytics';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import SlackChannelsSelect from '@/modules/slack-app/components/SlackChannelsSelect.vue';
import { productEvents } from '@swimm/shared';
import swal from 'sweetalert';
import { mapGetters } from 'vuex';
import CheckBoxSection from '@/common/components/atoms/CheckBoxSection.vue';
import {
  changeSlackChannel,
  changeSlackIntegration,
  doSlackIntegrationsExist,
  slackNotificationTypes,
} from '../services/slack-app-utils';
import { autoFocus, useNotificationsStore } from '@swimm/editor';
import { Divider } from '@swimm/ui';

export default {
  components: { SlackChannelsSelect, CheckBoxSection, Divider },
  directives: {
    autoFocus,
  },
  props: {
    repoId: { type: String, required: true },
    workspaceId: { type: String, required: true },
  },
  setup() {
    const analytics = useAnalytics();
    const { addNotification } = useNotificationsStore();
    return { analytics, addNotification };
  },
  data() {
    return {
      savingIntegration: false,
      savingChannel: false,
      selectedChannel: '',
      notificationsConfigs: [
        {
          text: 'New doc was merged to default branch',
          userFriendlySettingName: 'Notify when doc was merged to default branch',
          enabled: false,
          type: slackNotificationTypes.NEW_DOC,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('database', ['db_getRepoMetadata']),
    repoMetadata() {
      return this.db_getRepoMetadata(this.repoId);
    },
    slackConfig() {
      return this.repoMetadata && this.repoMetadata.slack_config;
    },
    isSlackAppInstalled() {
      return this.slackConfig && this.repoMetadata.slack_config.is_slack_installed;
    },
  },
  watch: {
    repoId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.reloadData();
        }
      },
    },
  },
  methods: {
    async reloadData() {
      this.selectedChannel = this.slackConfig?.conversation_name;
      await Promise.allSettled([this.getCheckboxesState()]);
    },
    async handleChannelChange(channelDetails) {
      this.selectedChannel = channelDetails.name;

      this.savingChannel = true;
      const success = await changeSlackChannel({
        repoId: this.repoId,
        workspaceId: this.workspaceId,
        channelId: channelDetails.id,
        channelName: channelDetails.name,
      });

      if (!success) {
        const title = 'Failed to update';
        this.selectedChannel = this.slackConfig?.conversation_name;
        await swal({ title, content: SWAL_CONTACT_US_CONTENT() });
      }
      this.savingChannel = false;

      this.reportEvent(productEvents.SLACK_CHANNEL_SELECTED, {
        'Channel Type': channelDetails.is_private ? 'private' : 'public',
        Channel: channelDetails.name,
      });
    },
    async getCheckboxesState() {
      const existingIntegrations = await doSlackIntegrationsExist(this.repoId);
      this.notificationsConfigs.forEach(
        (notification) => (notification.enabled = existingIntegrations[notification.type] || false)
      );
    },
    async onCheckboxClicked({ newValue, checkBoxName }) {
      this.savingIntegration = true;
      const currentConfig = this.notificationsConfigs.find((config) => config.type === checkBoxName);
      // Call this function without waiting since we want it done asynchronously.
      this.updateSlackConfig({ newValue, checkBoxName, currentConfig });
      currentConfig.enabled = newValue;
      this.savingIntegration = false;
    },
    async updateSlackConfig({ newValue, checkBoxName, currentConfig }) {
      const success = await changeSlackIntegration({
        repoId: this.repoId,
        slackConfig: this.slackConfig,
        integrationType: checkBoxName,
        addIntegration: newValue,
      });
      if (!success) {
        const notification = this.notificationsConfigs.find((item) => item.type === checkBoxName);
        this.addNotification(
          `Failed to turn "${notification.userFriendlySettingName}" ${
            newValue ? 'ON' : 'OFF'
          }. Please try again or contact our support team.`,
          {
            icon: 'warning',
          }
        );
      } else {
        this.reportEvent(productEvents.SLACK_NOTIFICATION_OPTION_CLICKED, {
          Context: currentConfig.text,
          Enabled: newValue,
        });
      }
    },
    reportEvent(productEvent, extraData) {
      this.analytics.track(productEvent, {
        'Workspace ID': this.workspaceId,
        'Repo ID': this.repoId,
        Origin: 'Slack App Settings',
        ...extraData,
      });
    },
  },
};
</script>

<style scoped>
.sub-header {
  margin-bottom: 16px;
  font-weight: 800;
}

.divider {
  margin: 16px 0;
}

.space {
  padding: 3px;
}

.channel-selector.v-select.vs--disabled {
  border-radius: 16px;
  cursor: not-allowed;
  color: var(--text-color-disable);
  background: var(--color-disable);
}

.channel-selector.v-select :deep(.vs__dropdown-menu) {
  max-height: 110px;
}

.notice-line {
  margin-top: 8px;
  font-size: var(--body-XS);
}

.bold {
  font-weight: 800;
}
</style>
