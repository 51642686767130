import { AnyLoggerType } from './legacy-shim';

export function stringifyLogParams(params: Record<string, string>) {
  return Object.entries(params || {})
    .map(([key, value]) => {
      value = `${value}`;
      if (value.includes(' ')) {
        value = `"${value}"`;
      }
      return `${key}: ${value}`;
    })
    .join(', ');
}

export function logRejectedPromise(logger: AnyLoggerType, name: string) {
  return (error) => logger.error(`${name} promise rejected: ${error}`);
}

export function convertSentryRewriteFramesRoot(p: string) {
  // Extracted from https://github.com/getsentry/sentry-javascript/blob/18557058f5927fdf6c48e54af077d7ce31359cb6/packages/integrations/src/rewriteframes.ts#L75-L82
  // See https://docs.sentry.io/platforms/node/configuration/integrations/pluggable-integrations/#rewriteframes (Sentry... :( )

  const isWindowsFrame = /^[A-Z]:\\/.test(p);
  if (isWindowsFrame) {
    return p
      .replace(/^[A-Z]:/, '') // remove Windows-style prefix
      .replace(/\\/g, '/'); // replace all `\\` instances with `/`
  }

  return p;
}
