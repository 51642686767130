import { defineStore, storeToRefs } from 'pinia';
import { STORES } from '@/modules/core/stores/store-constants';
import { useRoute } from 'vue-router';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { getDefaultUpdateBranchPrefix, shortUuid } from '@swimm/shared';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

export enum SaveOptions {
  CURRENT_BRANCH = 'current-branch',
  NEW_BRANCH = 'new-branch',
}

export type BatchSaveStep =
  | 'verifying'
  | 'save-to-db'
  | 'prepare-swms'
  | 'create-branch'
  | 'commit'
  | 'create-pr'
  | 'finalizing';

export class VerifyError extends Error {}
export class CreateBranchError extends Error {
  constructor(message: string, public branchName: string) {
    super(message);
  }
}

export interface PushData {
  branch?: string;
  prBranch?: string;
  commitMessage?: string;
  prMessage?: string;
}

export function getCommitNotificationErrorMesssage(err: Error) {
  if (err instanceof CreateBranchError) {
    return `Commit failed due to error in creating branch ${err.branchName}. Check if branch already exists.`;
  } else if (err?.name === 'HTTPError') {
    return `Commit failed due to error: ${getHttpErrorMessage(err)}`;
  } else {
    return 'Commit failed. Please try again or contact our support team.';
  }
}

// Since there's a variety of Bitbucket cloud branch restrictions and bitbucket doesn't simply allow to check if a user has permission to commit to the branch
// we let the user commit and show the error message from bitbucket if the branch is protected.
function getHttpErrorMessage(err: unknown) {
  const prettyError = [];
  if (err?.['error']?.['error']?.message) {
    prettyError.push(err['error']['error'].message);
  }

  if (err['message']) {
    prettyError.push(err['message']);
  }

  return prettyError.join(',');
}

export const useBatchCommitStore = defineStore(STORES.BATCH_COMMIT, () => {
  const route = useRoute();
  const store = useStore();
  const { reposStateData } = storeToRefs(useReposStore());

  const repoId = ref<string>(route.params.repoId as string);
  const branch = ref<string>(route.params.branch as string);
  const workspaceId = ref<string>(route.params.workspaceId as string);
  const commitMessage = ref('');
  const saveOption = ref<SaveOptions>(SaveOptions.CURRENT_BRANCH);
  const workspaceBranchPrefix = computed(() => {
    const workspace = store.getters['database/db_getWorkspace'](workspaceId.value);
    return workspace?.branch_prefix ?? '';
  });
  const newRemoteBranchName = ref<string>(getBranchName());
  const startPR = ref(true);
  const isBranchProtected = computed(() => {
    const repoDataFromState = reposStateData.value?.[repoId.value];
    return !!repoDataFromState?.isProtectedBranch;
  });

  function clean() {
    commitMessage.value = '';
    saveOption.value = isBranchProtected.value ? SaveOptions.NEW_BRANCH : SaveOptions.CURRENT_BRANCH;
    startPR.value = true;
    newRemoteBranchName.value = getBranchName();
  }

  function getBranchName() {
    const prefix = workspaceBranchPrefix.value || getDefaultUpdateBranchPrefix();
    return `${prefix}${shortUuid()}`;
  }

  function selectCreatingNewBranch(newBranchName) {
    // Don't override the new branch name if the new branch option already selected or no branch name provided.
    if (saveOption.value !== SaveOptions.NEW_BRANCH || newBranchName?.trim()) {
      newRemoteBranchName.value = newBranchName?.trim() ? newBranchName?.trim() : getBranchName();
    }
    saveOption.value = SaveOptions.NEW_BRANCH;
  }

  watch(
    route,
    (to) => {
      const repoIdParam = to ? (to.params.repoId as string) : null;
      const branchParam = to ? (to.params.branch as string) : null;
      const workspaceParam = to ? (to.params.workspaceId as string) : null;
      if (workspaceParam !== workspaceId.value || repoIdParam !== repoId.value || branchParam !== branch.value) {
        clean();
        repoId.value = repoIdParam;
        branch.value = branchParam;
        workspaceId.value = workspaceParam;
      }
    },
    { immediate: true }
  );
  watch(
    () => workspaceBranchPrefix.value,
    () => {
      clean();
    }
  );
  watch(
    isBranchProtected,
    () => {
      saveOption.value = isBranchProtected.value ? SaveOptions.NEW_BRANCH : SaveOptions.CURRENT_BRANCH;
    },
    { immediate: true }
  );
  return {
    commitMessage,
    saveOption,
    startPR,
    newRemoteBranchName,
    cleanBatchCommitState: clean,
    selectCreatingNewBranch,
  };
});
