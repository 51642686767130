<script lang="ts">
export interface Tab {
  label: string;
  tag?: string;
  count?: number;
  icon?: string;
  iconColor?: string;
  logo?: string;
  notification?: string;
  notificationColor?: string;
  additionalText?: string;
}
</script>

<script setup lang="ts">
import type { PropType } from 'vue';
import Icon from './Icon.vue';

const props = defineProps({
  tabs: { type: Array as PropType<Tab[]>, required: true },
  modelValue: { type: Number, required: true },
  readonly: { type: Boolean, default: false },
});
const emit = defineEmits(['update:model-value']);

function isSelected(index: number) {
  return props.modelValue === index;
}

function getTabId(tab: Tab) {
  return tab.label.replaceAll(' ', '-');
}

function getTabText(tab: Tab) {
  if (tab.additionalText) {
    return `${tab.label} ${tab.additionalText}`;
  }
  return tab.label;
}

function selectTab(tabIndex: number) {
  if (props.readonly || isSelected(tabIndex)) {
    return;
  }
  emit('update:model-value', tabIndex);
}

function getIconStyle(tab: Tab) {
  return { color: tab.iconColor || 'var(--text-color-primary)' };
}
</script>

<template>
  <div class="tabs" data-testid="tabs">
    <div
      v-for="(tab, index) in tabs"
      :data-testid="`tab-${tab.label}`"
      :key="index"
      :class="[
        'tab',
        {
          selected: isSelected(index),
          readonly: readonly,
          clickable: !readonly && !isSelected(index),
          'subtitle-L': isSelected(index),
          'body-S': !isSelected(index),
        },
      ]"
      :data-tag="tab.tag"
      :data-count="tab.count || null"
      @click="selectTab(index)"
    >
      <Icon v-if="tab.icon" :name="tab.icon" class="icon" :style="getIconStyle(tab)" />
      <img v-if="tab.logo" :src="tab.logo" class="logo" />
      <div :id="getTabId(tab)">{{ getTabText(tab) }}</div>
      <span
        v-if="tab.notification"
        :data-testid="`tab-${tab.label}-notification`"
        class="notification"
        :style="{ backgroundColor: tab.notificationColor }"
      />
      <slot name="hotspot-placeholder" :tab="tab" />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.tabs {
  display: flex;
  margin: 10px 0 20px;
}

.tab {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 16px;
  padding-bottom: 5px;
  white-space: nowrap;
  color: var(--text-color-secondary);

  &:not(.clickable) {
    cursor: default;
  }

  &[data-tag]::after,
  &[data-count]::after {
    border-radius: 4px;
    padding: 4px;
    font-size: var(--body-XS);
    font-weight: normal;
    line-height: normal;
    position: relative;
    right: -5px;
    top: -5px;
  }

  &[data-tag]::after {
    content: attr(data-tag);
    color: var(--text-color-magic);
    background: var(--color-status-magic);
  }

  &[data-count]::after {
    content: attr(data-count);
    color: var(--text-color-primary);
    background: var(--color-surface);
  }
}

.tab.selected:not(.readonly) {
  font-weight: 800;
  color: var(--text-color-primary);
}

.tab.selected:not(.readonly)::before {
  position: absolute;
  bottom: -5px;
  width: 100%;
  height: 4px;
  background: var(--color-border-brand);
  content: '';
}

.tab .notification {
  position: absolute;
  top: -5px;
  z-index: 1;
  right: -10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.tab:hover {
  opacity: 0.9;
}

.icon {
  padding: 0;
  font-size: var(--fontsize-sm);
  font-weight: 400;
}

.logo {
  width: 18px;
}
</style>
