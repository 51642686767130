import { type Ref, nextTick, ref, watch } from 'vue';
import type { GroupedSelectedIndex } from '../../types/transitional';
import { scrollToItem } from '../utilties';

export function useGroupedItemSelectionScrollHandler(selectedIndex: Ref<GroupedSelectedIndex | undefined>) {
  const itemRefs = ref<(HTMLElement | null)[][]>([]);
  const scrollContainerRef = ref<HTMLElement | null>(null);

  function setItemRef(el: HTMLElement, itemIndex: number, groupIndex: number) {
    if (!itemRefs.value[groupIndex]) {
      itemRefs.value[groupIndex] = [];
    }
    itemRefs.value[groupIndex][itemIndex] = el;
  }

  if (selectedIndex.value) {
    watch(
      () => selectedIndex.value,
      async () => {
        await nextTick();

        const group = selectedIndex.value?.[0] || 0;
        const itemIndex = selectedIndex.value?.[1] || 0;
        const item = itemRefs.value[group]?.[itemIndex];

        if (item && scrollContainerRef.value) {
          scrollToItem(item, scrollContainerRef.value);
        }
      },
      { immediate: true }
    );
  }

  return {
    itemRefs,
    scrollContainerRef,
    setItemRef,
  };
}
