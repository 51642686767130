import { type SwmSymbolPath, SwmSymbolType } from '@swimm/shared';
import nodePath from 'path-browserify';
import * as _ from 'lodash-es';

// We only allow filename matches to either filenames with an extension or to common known filenames that don't have an
// extension.
const FILENAME_ALLOW_LIST = ['Makefile', 'README', 'Dockerfile', 'OWNERS', 'LICENSE'];

export class PathSymbolSuggestionEngine {
  knownFullFilePaths: Set<string> = new Set();
  knownPartialFilePaths: Map<string, string[]> = new Map();
  knownFileNameMatches: Map<string, string[]> = new Map();
  knownFullDirPaths: Set<string> = new Set();
  knownPartialDirPaths: Map<string, string[]> = new Map();
  repoId: string;

  constructor(knownFilePaths: string[], knownDirPaths: string[], repoId: string) {
    this.repoId = repoId;
    for (const path of knownFilePaths) {
      this.knownFullFilePaths.add(path);
      let isFirst = true;
      for (const partialPath of PathSymbolSuggestionEngine.getPartialPaths(path)) {
        if (isFirst) {
          isFirst = false;
          // A file name match
          // We want to avoid suggesting things like 'config' or other single words that can have files with the same
          // name.
          if (!partialPath.includes('.') && !FILENAME_ALLOW_LIST.includes(partialPath)) {
            continue;
          }
          let fileNameMatches = this.knownFileNameMatches.get(partialPath);
          if (!fileNameMatches) {
            this.knownFileNameMatches.set(partialPath, (fileNameMatches = []));
          }
          fileNameMatches.push(path);
        } else {
          // A longer match.
          let partialPaths = this.knownPartialFilePaths.get(partialPath);
          if (!partialPaths) {
            this.knownPartialFilePaths.set(partialPath, (partialPaths = []));
          }
          partialPaths.push(path);
        }
      }
    }
    for (const path of knownDirPaths) {
      this.knownFullDirPaths.add(path);
      for (const partialPath of PathSymbolSuggestionEngine.getPartialPaths(path)) {
        let partialPaths = this.knownPartialDirPaths.get(partialPath);
        if (!partialPaths) {
          this.knownPartialDirPaths.set(partialPath, (partialPaths = []));
        }
        partialPaths.push(path);
      }
    }
  }

  private static *getPartialPaths(path: string): Iterable<string> {
    const parts = path.split(nodePath.sep);
    for (let i = 0; i < parts.length; i++) {
      const partialPath = parts.slice(-i - 1).join(nodePath.sep);
      yield partialPath;
    }
  }

  suggest(text: string): SwmSymbolPath[] | undefined {
    return this.suggestFileMatch(text) || this.suggestDirMatch(text);
  }

  suggestFileMatch(text: string): SwmSymbolPath[] | undefined {
    // Full path match
    if (this.knownFullFilePaths.has(text)) {
      return [{ type: SwmSymbolType.PATH, path: text, text: text, isDirectory: false, repoId: this.repoId }];
    }
    const noLeadingSlashes = _.trimStart(text, nodePath.sep);
    // Partial match: text="App/App.vue" knownPath="src/components/App/App.vue"
    for (const partialPath of PathSymbolSuggestionEngine.getPartialPaths(noLeadingSlashes)) {
      const partialMatches = this.knownPartialFilePaths.get(partialPath);
      if (!partialMatches) {
        continue;
      }
      return partialMatches.map((path) => ({
        type: SwmSymbolType.PATH,
        path,
        text: path,
        isDirectory: false,
        repoId: this.repoId,
      }));
    }
    // Filename only match:
    const filename = nodePath.basename(text);
    const filenameMatches = this.knownFileNameMatches.get(filename);
    if (filenameMatches) {
      return filenameMatches.map((path) => ({
        type: SwmSymbolType.PATH,
        path,
        text: path,
        isDirectory: false,
        repoId: this.repoId,
      }));
    }
    return undefined;
  }

  suggestDirMatch(text: string): SwmSymbolPath[] | undefined {
    // Only suggest directory matches when text includes a '/' to avoid FPs such as 'tests' which can match a whole
    // bunch of tokens.
    if (!text.includes('/')) {
      return undefined;
    }
    // Trim leading and trailing '/'s - '/components' -> 'components', 'components/' -> 'components'
    const noLeadingTrailingSlashes = _.trim(text, '/');
    if (this.knownFullDirPaths.has(noLeadingTrailingSlashes)) {
      return [
        {
          type: SwmSymbolType.PATH,
          path: noLeadingTrailingSlashes,
          text: noLeadingTrailingSlashes,
          isDirectory: true,
          repoId: this.repoId,
        },
      ];
    }
    // Partial match: text="components/" knownPath="src/components/"
    for (const partialPath of PathSymbolSuggestionEngine.getPartialPaths(noLeadingTrailingSlashes)) {
      const partialDirMatches = this.knownPartialDirPaths.get(partialPath);
      if (!partialDirMatches) {
        continue;
      }
      return partialDirMatches.map((path) => ({
        type: SwmSymbolType.PATH,
        path,
        text: path,
        isDirectory: true,
        repoId: this.repoId,
      }));
    }
    return undefined;
  }
}
