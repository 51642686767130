import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { defineStore, storeToRefs } from 'pinia';
import swal from 'sweetalert';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { STORES } from '@/modules/core/stores/store-constants';
import { SWAL_CONTACT_US_CONTENT } from '@swimm/editor';
import { UrlUtils, config, encodeString, eventLogger, getLoggerNew, productEvents } from '@swimm/shared';
import {
  type NewPlaylistStepOption,
  type Playlist,
  type PlaylistExternalLink,
  type PlaylistProgressBarStep,
  type PlaylistSequenceStep,
  type PlaylistSequenceStepExternalLink,
  PlaylistSequenceStepTypes,
} from '../types';
import { PlaylistMenuOptions } from '@/modules/playlists/consts';
import assertNever from 'assert-never';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { usePlaylist } from '../composables/playlist';
import { useTemplatesStore } from '@/modules/core/stores/templates-store';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useCreationHubStore } from '@/modules/core/creation-hub/store/creation-hub';
import { ItemIds } from '@/modules/core/creation-hub/CreationHubConsts';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { isDocEmpty, parseSwmd } from '@swimm/swmd';
import { DraftType } from '@/modules/drafts3/db';

const logger = getLoggerNew(__modulename);

export const useEditPlaylistStore = defineStore(STORES.EDIT_PLAYLIST, () => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const analytics = useAnalytics();
  const drafts3Store = useDrafts3Store();
  const { logEvent } = useSwimmEventLogs();
  const { playlistId } = usePlaylist();
  const templatesStore = useTemplatesStore();
  const { docTemplates } = storeToRefs(templatesStore);
  const { reposStateData } = storeToRefs(useReposStore());
  const { openCreationHubModal, closeCreationHubModal } = useCreationHubStore();

  const playlist = ref<Playlist>({ sequence: [], name: '', description: '', summary: '' } as Playlist);

  const swmTemplate = ref(null);
  const templateName = ref(null);

  const currentStep = ref('intro');
  const hasChanged = ref(false);
  const addingPosition = ref(0);
  const isAddingDraft = ref(false);
  const isRemovingDraft = ref(false);
  const shouldShowAddItemsModal = ref(false);
  const selectedLink = ref<{ link: PlaylistExternalLink; isNew: boolean }>(null);
  const shouldShowExternalLinkModal = ref(false);

  const repoId = computed(() => route.params.repoId as string);
  const workspaceId = computed(() => route.params.workspaceId as string);

  const updatePreventNavigation = (args?) => store.dispatch('updatePreventNavigation', args);
  const addStepToPlaylist = (args?) => store.dispatch('filesystem/addStepToPlaylist', args);
  const setSelectedFolderTreePath = (args?) => store.dispatch('filesystem/setSelectedFolderTreePath', args);

  async function selectStep(step) {
    if (step.link && route.path !== step.link) {
      updatePreventNavigation(false);
      // Reseting selectedFolderTreePath to prevent the snippet studio from opening when moving between steps
      setSelectedFolderTreePath({ path: '', repoId: repoId.value });
      const {
        draft: _draft,
        action: _action,
        template: _template,
        sgdTemplateId: _sgdTemplateId,
        ...newQuery
      } = route.query;
      await router.replace({ query: newQuery });
    }
    currentStep.value = step.index || step.index === 0 ? step.index : step.id;
  }

  function addStep({ type, position }: { type: NewPlaylistStepOption; position: number }) {
    addingPosition.value = position;
    switch (type) {
      case PlaylistMenuOptions.BLANK_DOC:
        addNewDocStep();
        break;
      case PlaylistMenuOptions.EXISTING_DOC:
        shouldShowAddItemsModal.value = true;
        break;
      case PlaylistMenuOptions.EXTERNAL_LINK:
        addLink();
        break;
      case PlaylistMenuOptions.OTHER_OPTIONS:
        openCreationHubModal({
          preventSgdInNonDefaultBranch: true,
          limitedShownItems: [ItemIds.BLANK_DOC],
          showImportSection: false,
          newDocClick: addDocFromCreationHub,
        });
        break;
      default:
        assertNever(type);
    }
    analytics.track(productEvents.PLAYLIST_OPTION_SELECTED, { Option: PlaylistMenuOptions[type] });
  }

  function addLink() {
    selectedLink.value = { link: { name: '', url: '' }, isNew: true };
    shouldShowExternalLinkModal.value = true;
  }

  async function addDocFromCreationHub({ query }) {
    if (query.template) {
      await templatesStore.loadDocTemplate(docTemplates.value[query.template]);
      swmTemplate.value = docTemplates.value[query.template];
      templateName.value = swmTemplate.value.name;
    }
    closeCreationHubModal();
    addNewDocStep();
  }

  async function addNewDocStep() {
    let newDraftId: string;
    if (swmTemplate.value) {
      const repo = store.getters['database/db_getRepoMetadata'](repoId.value);
      const templateSwmdContent = parseSwmd(swmTemplate.value.rawFileContent, {
        legacy: {
          baseUrl: config.BASE_URL,
          workspaceId: workspaceId.value,
          repoId: repoId.value,
          repoName: repo.name,
          repos: [
            {
              ...repo,
              branch: reposStateData.value[repoId.value].branch,
            },
          ],
        },
      });
      newDraftId = await drafts3Store.newDoc(templateSwmdContent);
    } else {
      newDraftId = await drafts3Store.newDoc();
    }
    const newDraftName = 'Untitled';

    isAddingDraft.value = true;

    const newSequence = playlist.value.sequence.slice();
    newSequence.splice(addingPosition.value, 0, {
      id: newDraftId,
      repoId: repoId.value,
      type: PlaylistSequenceStepTypes.UNIT,
      name: newDraftName,
      isDraftLink: true,
    });
    playlist.value = { ...playlist.value, sequence: newSequence };

    currentStep.value = `${addingPosition.value}`;

    templateName.value = null;
    swmTemplate.value = null;
    isAddingDraft.value = false;
  }

  async function saveLink(link: PlaylistExternalLink) {
    link.url = linkHasHttps(link.url) ? link.url : 'https://' + link.url;
    const linkToSave: PlaylistSequenceStepExternalLink = {
      id: encodeString(link.url),
      type: PlaylistSequenceStepTypes.EXTERNAL_LINK,
      name: link.name || link.url,
      url: link.url,
      repoId: repoId.value,
    };
    try {
      logEvent(eventLogger.SWIMM_EVENTS.EXTERNAL_LINK_UPDATED, {
        srcId: linkToSave.url,
        srcName: linkToSave.name,
      });

      const replacementIndex = selectedLink.value.isNew ? 0 : 1;
      const newSequence = playlist.value.sequence.slice();
      newSequence.splice(addingPosition.value, replacementIndex, linkToSave);
      playlist.value = { ...playlist.value, sequence: newSequence };

      shouldShowExternalLinkModal.value = false;
      hasChanged.value = true;
    } catch (err) {
      logger.error({ err }, `could not save external link: ${err}`);
      await swal({ title: 'Failed to edit link', content: { element: SWAL_CONTACT_US_CONTENT() } });
    }
    shouldShowExternalLinkModal.value = false;
  }

  async function removeStep(step: PlaylistProgressBarStep) {
    const index = playlist.value.sequence.findIndex((swimm) => swimm.id === step.resource.id);
    if (index > -1) {
      const newSequence = playlist.value.sequence.slice();
      newSequence.splice(index, 1);
      playlist.value = { ...playlist.value, sequence: newSequence };
    }
    currentStep.value = 'intro';

    // Delete draft in case it's empty
    if ('isDraftLink' in step.resource && step.resource.isDraftLink) {
      const draft = drafts3Store.drafts.get(step.resource.id);
      if (draft && draft.type === DraftType.DOC && isDocEmpty(draft.content.content)) {
        drafts3Store.discardDraft(step.resource.id);
      }
    }
  }

  function editStep({ step, stepIndex }: { step: PlaylistProgressBarStep; stepIndex: number }) {
    if (step.resource.type === PlaylistSequenceStepTypes.EXTERNAL_LINK) {
      addingPosition.value = stepIndex;
      selectedLink.value = { link: step.resource, isNew: false };
      shouldShowExternalLinkModal.value = true;
      return;
    }
  }

  function addSwimmsToPlaylist(swimmsToAdd) {
    const swimms: PlaylistSequenceStep[] = swimmsToAdd.map(({ id, repoId: swimmRepoId, name, type, isDraftLink }) => ({
      id,
      repoId: swimmRepoId,
      name,
      type,
      isDraftLink,
    }));
    const newSequence = playlist.value.sequence.slice();
    newSequence.splice(addingPosition.value, 0, ...swimms);
    playlist.value = { ...playlist.value, sequence: newSequence };
    addStepToPlaylist({
      repoId: repoId.value,
      playlistId: playlistId.value,
      newSequence: playlist.value.sequence,
    });
    shouldShowAddItemsModal.value = false;

    trackPlaylistExistingStepsAdding(swimmsToAdd);
  }

  function trackPlaylistExistingStepsAdding(swimmsToAdd) {
    const docsAdded = swimmsToAdd.filter(({ type }) => type === 'unit');
    const playlistsAdded = swimmsToAdd.filter(({ type }) => type === 'playlist');
    const isAddingStepsFromOtherRepos = swimmsToAdd.some(({ repoId: swimmRepoId }) => swimmRepoId !== repoId.value);
    const docsTitles = docsAdded.map(({ name }) => name).join(', ');
    const playlistsTitles = playlistsAdded.map(({ name }) => name).join(', ');

    analytics.track(productEvents.PLAYLIST_EXISTING_DOCS_AND_PLAYLISTS_ADDED, {
      'Docs Count': docsAdded.length,
      'Playlists Count': playlistsAdded.length,
      'Docs/Playlists from Other Repos': isAddingStepsFromOtherRepos,
      'Docs Titles': docsTitles,
      'Playlists Titles': playlistsTitles,
    });
  }

  function resetStoreState() {
    playlist.value = { sequence: [], name: '', description: '', summary: '' } as Playlist;
    swmTemplate.value = null;
    templateName.value = null;
    currentStep.value = 'intro';
    hasChanged.value = false;
    addingPosition.value = 0;
    isAddingDraft.value = false;
    isRemovingDraft.value = false;
    closeCreationHubModal();
    shouldShowAddItemsModal.value = false;
    selectedLink.value = null;
    shouldShowExternalLinkModal.value = false;
  }

  return {
    playlist,
    hasChanged,
    currentStep,
    selectedLink,
    isAddingDraft,
    isRemovingDraft,
    shouldShowAddItemsModal,
    shouldShowExternalLinkModal,
    saveLink,
    addStep,
    editStep,
    selectStep,
    removeStep,
    resetStoreState,
    addSwimmsToPlaylist,
    addDocFromCreationHub,
  };
});

function linkHasHttps(url: string) {
  return UrlUtils.getLinkType(url) !== 'default' || url.startsWith('http://') || url.startsWith('https://');
}
