<script setup>
import { computed } from 'vue';
import { Action, EmptyState, SwAvatar, SwText } from '@swimm/ui';
import { datetimeUtils, workspaceSettingsTabs } from '@swimm/shared';
import { useWorkspaceSettingsModalStore } from '@/modules/workspace/modals/settings/store/workspace-settings';

const props = defineProps({
  topUsers: { type: Array, default: null },
  workspaceId: { type: String, default: null },
});

const { openWorkspaceSettingsModal } = useWorkspaceSettingsModalStore();

const showEmptyState = computed(() => {
  return props.topUsers.length <= 1;
});

const openAddUsersModal = () => {
  openWorkspaceSettingsModal({
    initialTabCode: workspaceSettingsTabs.MEMBERS,
  });
};
</script>

<template>
  <div v-if="showEmptyState" class="wrapper">
    <EmptyState
      title="No frequent users in your Swimm workspace"
      description="Invite members to your workspace and start collaborating"
      class="empty-state"
    >
      <Action @click="openAddUsersModal" secondary>Invite</Action>
    </EmptyState>
  </div>
  <div v-else class="wrapper">
    <SwText variant="headline3">Top users</SwText>
    <SwText class="description">Bi-weekly most active users on your team. </SwText>
    <table>
      <tr>
        <th>
          <SwText variant="body-S">NAME</SwText>
        </th>
        <th><SwText variant="body-S">LAST ACTIVITY</SwText></th>
      </tr>
      <tr v-for="(user, index) in topUsers" :key="index">
        <td>
          <div class="name-container">
            <SwAvatar :text="user.name" />
            <SwText variant="body-S" class="user-name"> {{ user.name }} </SwText>
          </div>
        </td>
        <td>
          <SwText variant="body-S" class="last-activity"> {{ datetimeUtils.humanizedDate(user.lastActivity) }} </SwText>
        </td>
      </tr>
    </table>
  </div>
</template>

<style scoped lang="postcss">
.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;

  .description {
    margin-top: var(--space-base);
    margin-bottom: var(--space-sm);
    color: var(--text-color-secondary);
  }
}

table {
  border-collapse: collapse;
}

th {
  text-align: left;
  color: var(--text-color-secondary);
  padding-bottom: var(--space-sm);
}

td {
  padding-bottom: var(--space-base);
  padding-top: var(--space-base);
  padding-left: var(--space-xs);
}

.last-activity {
  padding-right: var(--space-base);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-name {
  padding-right: var(--space-xl);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10vw;
}

.name-container {
  display: flex;
  gap: var(--space-base);
  align-items: center;
}

.empty-state {
  flex: 1;
  justify-content: center;
  text-align: center;
}
</style>
