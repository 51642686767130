import { type Ref, ref } from 'vue';
import { useStore } from 'vuex';
import { LangExtensionUtils, type WorkspaceDB, getLoggerNew, gitwrapper } from '@swimm/shared';
import { useRepoSwitcher } from '@/common/composables/repoSwitcher';

const logger = getLoggerNew(__modulename);

export type FileToShow = {
  loading: boolean;
  path: string;
  content?: string;
  type?: string;
  revealLine?: number;
  showError?: boolean;
  monacoOptions?: object;
};

export function useFsWrapper() {
  const { connectToRepo } = useRepoSwitcher();
  const store = useStore();
  const fileToShow: Ref<FileToShow | null> = ref(null);

  const monacoOptions = {
    readOnly: true,
    contextmenu: false,
    scrollBeyondLastLine: false,
    minimap: { renderCharacters: false },
    automaticLayout: true,
    wordWrap: 'on',
  };

  const fsWrapper = {
    async getFileContent({ repoId, filePath, revision, raw = false }) {
      return await gitwrapper.getFileContentFromRevision({
        repoId,
        filePath,
        revision,
        raw,
      });
    },
    getRepoFolderTree(repoId) {
      if (!repoId) {
        return {};
      }
      return store.getters['filesystem/fs_getRepoFolderTree'](repoId);
    },
    async populateRepoFolderTree({ repoId, branch, isCrossRepo }) {
      if (isCrossRepo) {
        await connectToRepo({
          repoId,
          alertError: false,
        });
      }
      const workspaceFromDB = store.getters['database/db_getWorkspaceByRepo'](repoId) as WorkspaceDB;
      const repoFromDB = store.getters['database/db_getRepository'](repoId);
      await store.dispatch('filesystem/loadFolderTree', {
        requestMetadata: {
          repoId,
          cloneUrl: repoFromDB?.metadata?.url,
          workspaceId: workspaceFromDB?.id,
          localAuthEndpoints: workspaceFromDB?.settings?.local_auth_endpoints,
        },
        branch,
        reload: true,
      });
    },
    async openFile({ repoId, path, revision, revealLines }) {
      try {
        fileToShow.value = {
          loading: true,
          path,
          monacoOptions,
        };
        const content = await gitwrapper.getFileContentFromRevision({
          filePath: path,
          repoId,
          revision,
        });
        const fileType = LangExtensionUtils.getFileProgrammingLanguageByPath(path);
        fileToShow.value = {
          loading: false,
          path,
          content,
          type: fileType,
          revealLine: revealLines ? Math.floor((revealLines.start + revealLines.end) / 2) : 0,
          monacoOptions,
        };
      } catch (err) {
        logger.error({ err }, `Failed to get content for repoId=${repoId} revision=${revision} error=${err}`);
        fileToShow.value = {
          path,
          loading: false,
          showError: true,
          monacoOptions,
        };
      }
    },
  };
  return {
    fileToShow,
    fsWrapper,
  };
}
