import { collectionNames, updateDocInSubCollection } from '@/adapters-common/firestore-wrapper';
import {
  ApplicabilityStatus,
  type AutosyncOutput,
  type CloudDocData,
  type Repo,
  type SwimmDocument,
  config,
  convertSWMJsonToSWMD,
  extractResourceIdFromPath,
  firestoreCollectionNames,
  getLoggerNew,
  gitwrapper,
  isRepoIdDummyRepo,
  objectUtils,
} from '@swimm/shared';
import { cloudDocExists, createSharedDoc, deleteSharedDoc, updateSharedDoc } from '../cloud-doc-utils';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useRouting } from '@/common/composables/routing';
import { autosyncUnit } from '@swimm/swimmagic';
import {
  LegacySwmdError,
  type NormalizedAutosyncOutput,
  convertSwimmContentToAutosyncInput,
  normalizeAutosyncOutput,
  parseSwmd,
  schema,
  serializeSwmd,
} from '@swimm/swmd';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { getSwimmDocumentFiles } from '@/modules/drafts3/docs';

const logger = getLoggerNew(__modulename);

export default function useSharingInternally() {
  const store = useStore();
  const route = useRoute();
  const { user } = useAuthStore();
  const { getDefaultBranch } = useRouting();

  async function unpublishSharedDoc({ sharedDocId, originalDocId }: { sharedDocId: string; originalDocId: string }) {
    const workspaceId = route.params.workspaceId as string;
    const repoId = route.params.repoId as string;

    await deleteSharedDoc({
      workspaceId,
      repoId,
      sharedDocId,
      originalDocId,
    });

    store.dispatch('database/fetchRepoResource', {
      repoId: repoId,
      collectionName: firestoreCollectionNames.SWIMMS,
      docId: originalDocId,
    });
    store.dispatch('database/refreshWorkspaceSharedDocs', { workspaceId });
    logger.info(`Unpublished shared doc workspaceId=${workspaceId} sharedDocId=${sharedDocId}`);
  }

  function shouldPublishOnCommit({
    commitBranch,
    workspaceId,
    repoId,
    docId,
  }: {
    commitBranch: string;
    workspaceId: string;
    repoId: string;
    docId: string;
  }): boolean {
    const dbDoc = store.getters['database/db_getSwimm'](repoId, docId);
    if (!dbDoc || !dbDoc.exported_cloud_doc_id) {
      // don't publish if doc isn't shared
      return false;
    }

    const sharedDoc = getSharedDoc(workspaceId, dbDoc.exported_cloud_doc_id);
    if (!sharedDoc?.export_info?.original_branch || sharedDoc.export_info?.original_branch !== commitBranch) {
      // don't publish on side branch doc wasn't published on
      return false;
    }
    return true;
  }

  async function publishDocsOnCommit({
    branch,
    docIds,
    repoId,
    workspaceId,
    repos,
  }: {
    branch: string;
    docIds: string[];
    repoId: string;
    workspaceId: string;
    repos: Repo[];
  }): Promise<void> {
    try {
      const defaultBranch = await getDefaultBranch(repoId);
      // we don't republish on default branch
      if (defaultBranch === branch) {
        return;
      }
      const docIdsToPublish = docIds.filter((docId) =>
        shouldPublishOnCommit({ commitBranch: branch, workspaceId, repoId, docId })
      );
      if (docIdsToPublish.length === 0) {
        return;
      }
      logger.info(`Start publishing docs ${docIdsToPublish.join(',')} on commit of ${branch} in ${repoId}`);
      const promises = docIdsToPublish.map((docId) =>
        publishOrRepublishDoc({ branch, docId, repoId, workspaceId, repos })
      );
      await Promise.allSettled(promises);
    } catch (err) {
      logger.error(`Failed to publish docs on commit: ${err}`, { err });
    }
  }

  async function publishDocOnShare({
    branch,
    docId,
    repoId,
    workspaceId,
    repos,
  }: {
    branch: string;
    docId: string;
    repoId: string;
    workspaceId: string;
    repos: Repo[];
  }): Promise<void> {
    return publishOrRepublishDoc({
      branch,
      docId,
      repoId,
      workspaceId,
      repos,
    });
  }

  async function publishOrRepublishDoc({
    branch,
    docId,
    repoId,
    workspaceId,
    repos,
  }: {
    branch: string;
    docId: string;
    repoId: string;
    workspaceId: string;
    repos: Repo[];
  }): Promise<void> {
    const allDocs = await getSwimmDocumentFiles({ repoId, branch, fileExtensions: [config.SWMD_FILE_EXTENSION] });
    const repoDoc = allDocs.find((doc) => extractResourceIdFromPath(doc.path) === docId);
    if (!repoDoc) {
      throw new Error(`Could not find doc ${docId} in ${repoId} with branch ${branch}`);
    }
    // get content
    const content = await gitwrapper.getFileContentFromRevision({
      filePath: repoDoc.path,
      repoId,
      revision: branch,
    });
    // parse to swimm document
    const swimmDocument = parseSWmdOrLegacy(content, { repoId, workspaceId, repos });
    // parse & autosync
    const rootNode: ProseMirrorNode = ProseMirrorNode.fromJSON(schema, swimmDocument.content);
    const autosyncInput = convertSwimmContentToAutosyncInput(rootNode, repoId, branch, repos);
    const autosyncResult = await autosyncUnit(autosyncInput);
    if (!autosyncResult.autosyncSuccess) {
      throw new Error('Failed to run verify on doc');
    }
    const autosyncOutput = JSON.stringify(autosyncResult.autosyncOutput);
    const swmd = serializeSwmd(swimmDocument, { baseUrl: config.BASE_URL, workspaceId });

    const dbDoc = store.getters['database/db_getSwimm'](repoId, docId);
    if (!dbDoc) {
      throw new Error('Could not find doc in db');
    }

    const isRepublish =
      !!dbDoc.exported_cloud_doc_id && (await cloudDocExists(workspaceId, dbDoc.exported_cloud_doc_id));

    let sharedDocId!: string;

    // save to cloud
    if (isRepublish) {
      sharedDocId = dbDoc.exported_cloud_doc_id;
      await updateSharedDoc({
        sharedDocId,
        workspaceId,
        user,
        swmd,
        autosyncOutput,
        docTitle: swimmDocument.title ?? 'Untitled',
        branch,
        repoId,
        docId,
      });
    } else {
      sharedDocId = await createSharedDoc({
        workspaceId,
        user,
        swmd,
        autosyncOutput,
        docTitle: swimmDocument.title ?? 'Untitled',
        branch,
        docId,
        repoId,
      });
      try {
        const result = await updateDocInSubCollection(
          collectionNames.REPOSITORIES,
          repoId,
          collectionNames.SWIMMS,
          docId,
          {
            exported_cloud_doc_id: sharedDocId,
          }
        );
        if (result.code !== config.SUCCESS_RETURN_CODE) {
          throw new Error(result.errorMessage);
        }
      } catch (err) {
        logger.error({ err }, `Failed to set exported cloud doc ID on original doc: ${err}`);
      }
    }
    await Promise.allSettled([
      store.dispatch('database/fetchRepoResource', {
        repoId: repoId,
        collectionName: firestoreCollectionNames.SWIMMS,
        docId: docId,
      }),
      store.dispatch('database/refreshWorkspaceSharedDocs', { workspaceId }),
    ]);
    logger.info(
      `Successfully published docId=${docId} repoId=${repoId} sharedDocId=${sharedDocId} workspaceId=${workspaceId} branch=${branch} isRepublish=${isRepublish}`
    );
  }

  function parseSWmdOrLegacy(
    docText: string,
    { workspaceId, repoId, repos }: { workspaceId: string; repoId: string; repos: Repo[] }
  ): SwimmDocument {
    try {
      return parseSwmd(docText);
    } catch (err) {
      if (err instanceof LegacySwmdError) {
        return parseSwmd(docText, {
          legacy: {
            baseUrl: config.BASE_URL,
            workspaceId,
            repoId,
            repoName: repos.find((repo) => repo.id === repoId).name,
            repos,
          },
        });
      }
      throw err;
    }
  }

  function isSharingInternallyEnabledInWorkspace(workspaceId: string) {
    const workspaceRepoId = store.getters['database/db_getWorkspaceRepoIds'](workspaceId);
    return workspaceRepoId.some((repoId: string) => {
      const repoMetadata = store.getters['database/db_getRepoMetadata'](repoId);
      return repoMetadata?.integrations?.share_internally_enabled && !isRepoIdDummyRepo(repoId);
    });
  }

  function getSharedDoc(workspaceId: string, sharedDocId: string): CloudDocData | null {
    const result = store.getters['database/db_getSharedDoc'](workspaceId, sharedDocId);
    return objectUtils.isEmpty(result) ? null : result;
  }

  async function fetchSharedDocOriginalDoc(workspaceId: string, sharedDocId: string): Promise<void> {
    // fetches the original doc of the shared doc into the vuex store
    const sharedDoc = getSharedDoc(workspaceId, sharedDocId);
    const docId = sharedDoc?.export_info?.original_doc_id;
    const repoId = sharedDoc?.export_info?.original_repo_id;
    if (repoId && docId) {
      await store.dispatch('database/fetchRepoResource', {
        repoId,
        collectionName: firestoreCollectionNames.SWIMMS,
        docId,
      });
    }
  }

  function getSharedDocMarkdown(workspaceId: string, sharedDocId: string): string {
    // return markdown from the shared doc
    // for swmd-3 returns the swmd field
    // for swmd-2 serialize the swmFile into markdown
    const sharedDoc = getSharedDoc(workspaceId, sharedDocId);
    if (!sharedDoc) {
      throw new Error(`no shared doc exists for ${workspaceId} ${sharedDocId}`);
    }
    // first check for swmd, since swm_data can be left-over
    if (sharedDoc.swmd) {
      return sharedDoc.swmd;
    } else if (sharedDoc.swm_data) {
      // serialize and return text
      const swmFile = JSON.parse(sharedDoc.swm_data);
      const repoId = sharedDoc.export_info?.original_repo_id;
      const mdResult = convertSWMJsonToSWMD({ swmFile, repoId });
      if (mdResult.code !== config.SUCCESS_RETURN_CODE) {
        throw new Error(mdResult.errorMessage);
      }
      return mdResult.swmd;
    }
    throw new Error(`No markdown in workspaceId=${workspaceId} sharedDocId=${sharedDocId}`);
  }

  function getSharedDocNormalizedAutosyncOutput({
    workspaceId,
    repoId,
    sharedDocId,
    doc,
    repos,
  }: {
    workspaceId: string;
    repoId: string;
    sharedDocId: string;
    doc: SwimmDocument;
    repos: Repo[];
  }): NormalizedAutosyncOutput {
    const sharedDoc = getSharedDoc(workspaceId, sharedDocId);
    if (!sharedDoc) {
      throw new Error(`no shared doc exists for ${workspaceId} ${sharedDocId}`);
    }
    if (sharedDoc.autosync_output) {
      return normalizeAutosyncOutput(JSON.parse(sharedDoc.autosync_output));
    }
    // for legacy docs (not swmd3) we return the doc as verified
    // and ignoring the db data
    const rootNode: ProseMirrorNode = ProseMirrorNode.fromJSON(schema, doc.content);
    const autosyncInput = convertSwimmContentToAutosyncInput(rootNode, repoId, '', repos);
    const autosyncOutput: AutosyncOutput = {
      applicability: ApplicabilityStatus.Verified,
      snippets: [],
      symbols: [],
    };
    for (const symbol of autosyncInput.symbols) {
      autosyncOutput.symbols.push({ ...symbol, applicability: ApplicabilityStatus.Verified, newInfo: symbol });
    }
    for (const snippet of autosyncInput.snippets) {
      autosyncOutput.snippets.push({ ...snippet, applicability: ApplicabilityStatus.Verified, newInfo: snippet });
    }
    return normalizeAutosyncOutput(autosyncOutput);
  }

  return {
    isSharingInternallyEnabledInWorkspace,
    publishDocOnShare,
    publishDocsOnCommit,
    unpublishSharedDoc,
    getSharedDoc,
    getSharedDocMarkdown,
    getSharedDocNormalizedAutosyncOutput,
    fetchSharedDocOriginalDoc,
  };
}
