import actions from '@/modules/swimmport/store/swimmport/actions';

const getDefaultState = () => ({
  enabled: false,
});

export default {
  namespaced: true,
  state: {
    enabled: true,
    ...getDefaultState(),
  },
  mutations: {
    SET_ENABLED(state, enabled) {
      state.enabled = enabled;
    },
  },
  getters: {
    isEnabled(state) {
      // Assume we're enabled if we for some reason haven't loaded it yet.
      return state.enabled ?? true;
    },
  },
  actions,
};
