export function scrollToItem(item: HTMLElement, container: HTMLElement) {
  const containerTop = container.scrollTop;
  const containerHeight = container.clientHeight;
  const elemTop = item.offsetTop - container.offsetTop;
  const elemHeight = item.clientHeight;
  const offset = elemHeight * 2;

  if (elemTop - offset < containerTop || elemTop + elemHeight > containerTop + containerHeight) {
    container.scrollTop = elemTop - offset > 0 ? elemTop - offset : 0;
  }
}

export function getHandlerKey(event: KeyboardEvent): string {
  const key = `${event.metaKey ? 'Meta_' : ''}${event.ctrlKey ? 'Ctrl_' : ''}${event.shiftKey ? 'Shift_' : ''}${
    event.altKey ? 'Alt_' : ''
  }${event.key}`;
  return normalizeHandlerKey(key);
}

export function normalizeAdditionalHandlers(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalHandlers: Record<string, (payload: any) => boolean>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, (payload: any) => boolean> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normalizedHandlers: Record<string, (payload: any) => boolean> = {};

  for (const key in additionalHandlers) {
    const normalizedKey = normalizeHandlerKey(key);
    normalizedHandlers[normalizedKey] = additionalHandlers[key];
  }

  return normalizedHandlers;
}

function normalizeHandlerKey(key: string): string {
  const parts = key.split('_');
  const mainKey = parts.pop();

  parts.sort();

  return [...parts, mainKey].join('_');
}

export function animateElementHeight(element: HTMLElement, expand: boolean) {
  if (expand) {
    expandSection(element);
  } else {
    collapseSection(element);
  }
}

// Inspired by: https://css-tricks.com/using-css-transitions-auto-dimensions/#aa-technique-3-javascript
function collapseSection(element: HTMLElement) {
  // get the height of the element's inner content, regardless of its actual size
  const sectionHeight = element.scrollHeight;

  // temporarily disable all css transitions
  const elementTransition = element.style.transition;
  element.style.transition = '';

  // on the next frame (as soon as the previous style change has taken effect),
  // explicitly set the element's height to its current pixel height, so we
  // aren't transitioning out of 'auto'
  requestAnimationFrame(function () {
    element.style.height = sectionHeight + 'px';
    element.style.transition = elementTransition;
  });
}

// Inspired by: https://css-tricks.com/using-css-transitions-auto-dimensions/#aa-technique-3-javascript
function expandSection(element: HTMLElement) {
  // On the next frame (once the content has rendered)
  // have the element transition to the height of its inner content
  requestAnimationFrame(function () {
    element.style.height = element.scrollHeight + 'px';
  });
}
